import { Form } from '@ant-design/compatible';
import { Button, Select } from 'antd';
import { onChangeBusinessPackageForm } from 'core/forms/addBusinessPackageForm/duck';
import { DecoratedDatePicker, DecoratedInputNumber, DecoratedSelect, DecoratedTextArea } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm } from 'utils';

const { Option } = Select;

@injectIntl
@withReduxForm({
    name: 'addBusinessPackageForm',
    actions: {
        change: onChangeBusinessPackageForm
    }
})
export class AddBusinessPackageForm extends Component {
    render() {
        const { getFieldDecorator, validateFields } = this.props.form;
        const { businessId, businessName } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <Form layout='horizontal'>
                <FormattedMessage id='add-business-package-form.business' />: <b>{businessName}</b>
                <br />
                <DecoratedSelect
                    allowClear
                    field='packageId'
                    filterOption={(input, option) =>
                        Boolean(option.props.children.toLowerCase().indexOf(input.toLowerCase() !== -1))
                    }
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    hasFeedback
                    label={<FormattedMessage id='add-business-package-form.package' />}
                    optionFilterProp='children'
                    showSearch
                >
                    {this.props.rolesPackages.map(({ id, name }) => (
                        <Option key={id} value={id}>
                            {name}
                        </Option>
                    ))}
                </DecoratedSelect>
                <DecoratedDatePicker
                    field='activationDatetime'
                    format='YYYY-MM-DD HH:mm:ss'
                    formatMessage={formatMessage}
                    formItem
                    getCalendarContainer={trigger => trigger.parentNode}
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    label={<FormattedMessage id='add-business-package-form.activation_datetime' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'add-business-package-form.activation_datetime_error'
                            })
                        }
                    ]}
                    showTime
                />
                <DecoratedDatePicker
                    field='expirationDatetime'
                    format='YYYY-MM-DD HH:mm:ss'
                    formatMessage={formatMessage}
                    formItem
                    getCalendarContainer={trigger => trigger.parentNode}
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    label={<FormattedMessage id='add-business-package-form.expiration_datetime' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'add-business-package-form.expiration_datetime_error'
                            })
                        }
                    ]}
                    showTime
                />
                <DecoratedTextArea
                    field='comment'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    label={<FormattedMessage id='add-business-package-form.comment' />}
                    rules={[
                        {
                            max: 2000,
                            message: formatMessage({
                                id: 'field_should_be_below_2000_chars'
                            })
                        }
                    ]}
                />
                <DecoratedInputNumber
                    field='amount'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    label={<FormattedMessage id='add-business-package-form.amount' />}
                    style={{ width: '100%' }}
                />
                <Button
                    onClick={() =>
                        validateFields(
                            (err, values) =>
                                !err &&
                                this.props.createBusinessPackage(
                                    businessId,
                                    values.packageId,
                                    _.omit(values, ['packageId'])
                                )
                        )
                    }
                    style={{ width: '100%' }}
                >
                    <FormattedMessage id='add-business-package-form.create' />
                </Button>
            </Form>
        );
    }
}
