exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__statsMainCont---2CnfW{background-color:#fff;height:110px;width:100%;display:flex}.styles-m__statsCont---2nLsO{display:flex;flex-direction:row;background-color:#fff;justify-content:space-between;align-items:center;box-sizing:border-box;width:100%}.styles-m__statsBlock---3L5iD{display:flex;flex-direction:column;justify-content:space-around;align-items:center;border:1px solid var(--primary);margin:0 5px 0 0;height:70px;width:100%}.styles-m__statsHeader---2olVI{color:#505050;text-align:center;font-size:.9em}.styles-m__statsText---2tt7u{color:#646464;font-size:.8em;text-align:center}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,iCACI,qBAAuB,CACvB,YAAa,CACb,UAAW,CACX,YACJ,CAEA,6BACI,YAAa,CACb,kBAAmB,CACnB,qBAAuB,CACvB,6BAA8B,CAC9B,kBAAmB,CACnB,qBAAsB,CACtB,UACJ,CAEA,8BACI,YAAa,CACb,qBAAsB,CACtB,4BAA6B,CAC7B,kBAAmB,CACnB,+BAAgC,CAChC,gBAAqB,CACrB,WAAY,CACZ,UACJ,CAEA,+BACI,aAAsB,CACtB,iBAAkB,CAClB,cACJ,CAEA,6BACI,aAAyB,CACzB,cAAgB,CAChB,iBACJ","file":"styles.m.css","sourcesContent":[".statsMainCont {\n    background-color: white;\n    height: 110px;\n    width: 100%;\n    display: flex;\n}\n\n.statsCont{\n    display: flex;\n    flex-direction: row;\n    background-color: white;\n    justify-content: space-between;\n    align-items: center;\n    box-sizing: border-box;\n    width: 100%;\n}\n\n.statsBlock{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    border: 1px solid var(--primary);\n    margin: 0px 5px 0px 0;\n    height: 70px;\n    width: 100%;\n}\n\n.statsHeader {\n    color: rgb(80, 80, 80);\n    text-align: center;\n    font-size: 0.9em;\n}\n\n.statsText {\n    color: rgb(100, 100, 100);\n    font-size: 0.8em;\n    text-align: center;\n}"]}]);

// Exports
exports.locals = {
	"statsMainCont": "styles-m__statsMainCont---2CnfW",
	"statsCont": "styles-m__statsCont---2nLsO",
	"statsBlock": "styles-m__statsBlock---3L5iD",
	"statsHeader": "styles-m__statsHeader---2olVI",
	"statsText": "styles-m__statsText---2tt7u"
};