exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__foundEntry---3dyFD{color:#1abb1a}.styles-m__bold---38-Xz{font-weight:700}.styles-m__italic---3jUF6{font-style:italic}.styles-m__iconOk---7sgAL{color:green;font-size:20px}.styles-m__iconCancle---2MIVa{color:red;font-size:20px}.styles-m__optionsDangerIconStyle---3p_gn{font-size:14px;color:#eb8c86}.styles-m__optionsWarningIconStyle---NaG0V{font-size:14px;color:#f0a871}.styles-m__optionsSuccessIconStyle---2aoe3{font-size:14px;color:#83c468}.styles-m__selectStyle---cWcy-{width:100%;max-width:125px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACI,aACJ,CAEA,wBACI,eACJ,CAEA,0BACI,iBACJ,CAEA,0BACI,WAAY,CACZ,cACJ,CAEA,8BACI,SAAU,CACV,cACJ,CAEA,0CACI,cAAe,CACf,aACJ,CAEA,2CACI,cAAe,CACf,aACJ,CAEA,2CACI,cAAe,CACf,aACJ,CAEA,+BACI,UAAW,CACX,eACJ","file":"styles.m.css","sourcesContent":[".foundEntry {\n    color: rgb(26, 187, 26);\n}\n\n.bold {\n    font-weight: 700;\n}\n\n.italic {\n    font-style: italic\n}\n\n.iconOk {\n    color: green;\n    font-size: 20px;\n}\n\n.iconCancle {\n    color: red;\n    font-size: 20px;\n}\n\n.optionsDangerIconStyle {\n    font-size: 14px;\n    color: #eb8c86\n}\n\n.optionsWarningIconStyle {\n    font-size: 14px;\n    color: #f0a871\n}\n\n.optionsSuccessIconStyle {\n    font-size: 14px;\n    color: #83c468\n}\n\n.selectStyle {\n    width: 100%;\n    max-width: 125px;\n}"]}]);

// Exports
exports.locals = {
	"foundEntry": "styles-m__foundEntry---3dyFD",
	"bold": "styles-m__bold---38-Xz",
	"italic": "styles-m__italic---3jUF6",
	"iconOk": "styles-m__iconOk---7sgAL",
	"iconCancle": "styles-m__iconCancle---2MIVa",
	"optionsDangerIconStyle": "styles-m__optionsDangerIconStyle---3p_gn",
	"optionsWarningIconStyle": "styles-m__optionsWarningIconStyle---NaG0V",
	"optionsSuccessIconStyle": "styles-m__optionsSuccessIconStyle---2aoe3",
	"selectStyle": "styles-m__selectStyle---cWcy-"
};