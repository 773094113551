import { Select } from 'antd';
import { DateRangePicker } from 'components';
import {
    DEFAULT_DATE_FORMAT,
    fetchReportCashFlow,
    setCashOrderFromDate,
    setCashOrderToDate,
    setFiltersAnalyticsUniqueIds,
    setFiltersCashbox
} from 'core/reports/reportCashFlow/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    cashOrderFromDate: state.reportCashFlow.filters.createdFromDate,
    cashOrderToDate: state.reportCashFlow.filters.createdToDate,
    analytics: state.reportCashFlow.analytics,
    cashboxes: state.reportCashFlow.cashboxes,
    analyticsIsFetching: state.reportCashFlow.analyticsIsFetching,
    cashboxesIsFetching: state.reportCashFlow.cashboxesIsFetching,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setCashOrderFromDate,
    setCashOrderToDate,
    setFiltersAnalyticsUniqueIds,
    setFiltersCashbox,
    fetchReportCashFlow
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class CashFlowFilter extends React.Component {
    constructor(props) {
        super(props);

        this.onDateChange = this.onDateChange.bind(this);
        this.getDaterange = this.getDateRange.bind(this);
        this.onCashboxSelect = this.onCashboxSelect.bind(this);
        this.onAnalyticsSelect = this.onAnalyticsSelect.bind(this);
    }

    onDateChange(arr) {
        const createdFromDate = _.get(arr, '[0]');
        const createdToDate = _.get(arr, '[1]');

        const { setCashOrderFromDate, setCashOrderToDate, fetchReportCashFlow } = this.props;

        // Call action for specific date
        createdFromDate.isValid() && setCashOrderFromDate(createdFromDate.format(DEFAULT_DATE_FORMAT));
        createdToDate.isValid() && setCashOrderToDate(createdToDate.format(DEFAULT_DATE_FORMAT));

        fetchReportCashFlow();
    }

    /**
     * Returns undefined if canntot reproduce full dateRange(not all fields are present)
     * else return date range
     */
    getDateRange() {
        const { cashOrderFromDate, cashOrderToDate } = this.props;

        if (!cashOrderFromDate || !cashOrderToDate) return undefined;

        const dateRange = [dayjs(cashOrderFromDate, DEFAULT_DATE_FORMAT), dayjs(cashOrderToDate, DEFAULT_DATE_FORMAT)];

        return dateRange;
    }

    onCashboxSelect(cashboxIds) {
        const { setFiltersCashbox, fetchReportCashFlow } = this.props;

        setFiltersCashbox(cashboxIds);
        fetchReportCashFlow();
    }

    onAnalyticsSelect(analyticsIds = []) {
        const { setFiltersAnalyticsUniqueIds, fetchReportCashFlow } = this.props;

        setFiltersAnalyticsUniqueIds(analyticsIds);
        fetchReportCashFlow();
    }

    render() {
        const {
            analytics,
            cashboxes,

            analyticsIsFetching,
            cashboxesIsFetching,
            intl: { formatMessage },
            isMobile
        } = this.props;

        const filteredAnalytics = _.filter(analytics, ans => !ans.analyticsDisabled);

        return (
            <div className={isMobile ? Styles.mainFilterContMobile : Styles.mainFilterCont}>
                <div className={isMobile ? Styles.selectContMobile : Styles.selectCont}>
                    <Select
                        allowClear
                        disabled={cashboxesIsFetching}
                        filterOption={(input, option) => {
                            const inputedText = input ? input.toLowerCase() : '';
                            const children = option.props.children ? String(option.props.children).toLowerCase() : '';

                            return (
                                children.indexOf(inputedText) >= 0 ||
                                String(option.props.value).indexOf(inputedText) >= 0
                            );
                        }}
                        mode='multiple'
                        onChange={this.onCashboxSelect}
                        placeholder={formatMessage({ id: 'report_cash_flow_page.cashbox' })}
                        showSearch
                        style={{ width: '100%' }}
                    >
                        {_.map(cashboxes, obj => (
                            <Select.Option key={obj.id} value={obj.id}>
                                {obj.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div className={isMobile ? Styles.selectContMobile : Styles.selectCont}>
                    <Select
                        allowClear
                        disabled={analyticsIsFetching}
                        filterOption={(input, option) => {
                            const inputedText = input ? input.toLowerCase() : '';
                            const children = option.props.children ? String(option.props.children).toLowerCase() : '';

                            return children.indexOf(inputedText) >= 0;
                        }}
                        mode='multiple' // To enable multiple select
                        onChange={this.onAnalyticsSelect}
                        placeholder={formatMessage({ id: 'report_cash_flow_page.analytics' })}
                        showSearch
                        style={{ width: '100%' }}
                    >
                        {_.map(filteredAnalytics, ans => (
                            <Select.Option key={ans.analyticsUniqueId} value={ans.analyticsUniqueId}>
                                {ans.analyticsName}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div className={isMobile ? Styles.dateRangePickerMobile : Styles.selectCont}>
                    <DateRangePicker dateRange={this.getDateRange()} onDateChange={this.onDateChange} />
                </div>
            </div>
        );
    }
}
