import { Form } from '@ant-design/compatible';
import { Input, Select } from 'antd';
import { DateRangePicker, StatsCountsPanel } from 'components';
import {
    fetchAnalytics,
    fetchCashOrders,
    fetchCashboxes,
    selectCashOrdersFilters,
    selectCashStats,
    setCashOrdersFilters,
    setSearchQuery
} from 'core/cash/duck';
import { onChangeCashOrdersFiltersForm } from 'core/forms/cashOrdersFiltersForm/duck';
import dayjs from 'dayjs';
import { DecoratedSelect } from 'forms/DecoratedFields';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';
import Styles from './styles.m.css';

const { Search } = Input;
const { Option } = Select;

@withReduxForm2({
    name: 'cashOrdersFiltersForm',
    actions: {
        change: onChangeCashOrdersFiltersForm,
        fetchCashboxes,
        fetchCashOrders,
        setCashOrdersFilters,
        setSearchQuery,
        fetchAnalytics
    },
    debouncedFields: ['query'],
    mapStateToProps: state => ({
        cashStats: selectCashStats(state),
        filters: selectCashOrdersFilters(state),
        cashboxes: state.cash.cashboxes,
        analytics: state.cash.analytics
    })
})
@injectIntl
export class CashOrdersFiltersForm extends Component {
    componentDidMount() {
        this.props.fetchCashboxes();
        this.props.fetchAnalytics();
    }

    _onSearch = value => this.props.setSearchQuery(value);

    _onCashboxSelect = value => {
        this.props.setCashOrdersFilters({ cashBoxId: value });
        this.props.fetchCashOrders();
    };

    _onAnalyticsSelect = value => {
        this.props.setCashOrdersFilters({ analyticsUniqueId: value });
        this.props.fetchCashOrders();
    };

    _onDateRangeChange = value => {
        const normalizedValue = value.map(date => date.format('YYYY-MM-DD'));
        const daterange = {
            startDate: normalizedValue[0],
            endDate: normalizedValue[1]
        };
        this.props.setCashOrdersFilters({ ...daterange, page: 1 });
        this.props.fetchCashOrders();
    };

    render() {
        const {
            cashStats,
            cashboxes,
            analytics,
            filters,
            intl: { formatMessage },
            form: { getFieldDecorator }
        } = this.props;

        return (
            <Form>
                <div className={Styles.row}>
                    <Search
                        className={Styles.filter}
                        onChange={({ target: { value } }) => this._onSearch(value)}
                        placeholder={formatMessage({
                            id: 'orders-filter.search_placeholder'
                        })}
                    />
                    <DecoratedSelect
                        allowClear
                        cnStyles={Styles.filter}
                        field='analyticsUniqueId'
                        getFieldDecorator={getFieldDecorator}
                        onChange={this._onAnalyticsSelect}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'orders-filter.analytics'
                        })}
                        showSearch
                    >
                        {analytics
                            .filter(ans => !ans.analyticsDisabled)
                            .map(({ analyticsUniqueId, analyticsName }) => (
                                <Option key={analyticsUniqueId} value={analyticsUniqueId}>
                                    {analyticsName}
                                </Option>
                            ))}
                    </DecoratedSelect>
                    <DecoratedSelect
                        allowClear
                        cnStyles={Styles.filter}
                        field='cashBoxId'
                        getFieldDecorator={getFieldDecorator}
                        initialValue={filters.cashBoxId}
                        onChange={this._onCashboxSelect}
                        placeholder={formatMessage({
                            id: 'cash-order-form.cashbox'
                        })}
                    >
                        {cashboxes
                            ? cashboxes
                                  .filter(({ active }) => active)
                                  .map(({ id, name }) => (
                                      <Option key={id} value={id}>
                                          {name}
                                      </Option>
                                  ))
                            : undefined}
                    </DecoratedSelect>
                    <DateRangePicker
                        dateRange={[dayjs(filters.startDate), dayjs(filters.endDate)]}
                        minimize
                        onDateChange={this._onDateRangeChange}
                    />
                </div>
                <StatsCountsPanel extendedCounts stats={cashStats} />
            </Form>
        );
    }
}
