import { setReportLoadKPIFetching } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchReportLoadKPISuccess, FETCH_REPORT_LOAD_KPI } from './duck';

const selectFilter = ({ reportLoadKPI: { filter } }) => ({
    filter
});

export function* fetchReportLoadKPISaga() {
    while (true) {
        try {
            yield take(FETCH_REPORT_LOAD_KPI);
            yield put(setReportLoadKPIFetching(true));

            const { filter } = yield select(selectFilter);

            const data = yield call(fetchAPI, 'GET', '/report/load_kpi', {
                filters: { ...filter }
            });
            yield put(fetchReportLoadKPISuccess(data));
        } finally {
            yield put(setReportLoadKPIFetching(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchReportLoadKPISaga)]);
}
