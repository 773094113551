import React from 'react';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { getCollapsedState } from 'utils';
import Styles from './styles.m.css';

function checkIsOrderSavedHelper(props) {
    return (
        <React.Fragment>
            <div
                className={Styles.stopRedirectMenu}
                id='stopRedirectMenu'
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    const collapsed = getCollapsedState();
                    props.close(
                        () => props.setCollapsedState(!collapsed),
                        () => {
                            props.resetModal();
                            props.setCollapsedState(!collapsed);
                        },
                        () => {
                            props.resetModal();
                            props.onStatusChange(props.status);
                            props.setCollapsedState(!collapsed);
                        }
                    );
                }}
            />
            <div
                className={Styles.stopRedirectMain}
                id='stopRedirectMain'
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.close(
                        () => props.history.push(book.mainMenuPage),
                        () => props.history.push(book.mainMenuPage),
                        () =>
                            props.onStatusChange(
                                props.status,
                                props.status,
                                void 0,
                                book.mainMenuPage
                            )
                    );
                }}
            />
        </React.Fragment>
    );
}

export const CheckIsOrderSavedHelper = withRouter(checkIsOrderSavedHelper);
