import { Modal } from 'antd';
import { loadModal } from 'core/modals/duck';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchTecdocAPI, tecdocImageFormatter } from 'utils';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    modalProps: state.modals.modalProps
});

const mapDispatchToProps = {
    loadModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class PhotoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            images: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.fetchData();
        } else if (!this.props.visible && prevProps.visible) {
            this.setState({
                attributes: [],
                images: []
            });
        }
    }

    fetchData = async () => {
        console.log(this.props);
        const { partNumber, brandId, code } = get(this.props, 'data', {});
        const { attributes, images } = await fetchTecdocAPI('GET', '/tecdoc/product_attributes', {
            partNumber: partNumber || code,
            brandId
        });
        this.setState({ attributes: attributes || [], images });
    };

    render() {
        const { visible, data: row, hideModal } = this.props;
        const { attributes, images } = this.state;

        const src = get(images, '[0]')
            ? `${__TECDOC_IMAGES_URL__}/${tecdocImageFormatter(
                  get(images, '[0].pictureName'),
                  get(images, '[0].documentType')
              )}`
            : `${__TECDOC_IMAGES_URL__}/not_found.png`;
        const additionalData = (
            <div>
                {attributes.map((attribute, i) => (
                    <div key={i} style={{ border: '1px solid var(--text4)', padding: 8 }}>
                        {attribute.description}: {attribute.value}
                    </div>
                ))}
            </div>
        );

        return (
            <Modal
                footer={null}
                maskClosable={false}
                onCancel={hideModal}
                open={visible}
                title={<FormattedMessage id='photo' />}
                width='45%'
            >
                <div style={{ textAlign: 'center' }}>
                    <img
                        alt='spare_part'
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                        }}
                        src={src}
                        style={{ cursor: 'pointer' }}
                        width='70%'
                    />
                </div>
                {additionalData}
            </Modal>
        );
    }
}

export default PhotoModal;
