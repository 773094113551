import { Col, Input, InputNumber, Modal, notification, Row, Select } from 'antd';
import { MODALS } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import {
    clearVehicleData,
    fetchVehicleMakes,
    fetchVehicleModels,
    fetchVehicleModifications,
    fetchVehicleYears,
    selectColors,
    selectFields,
    selectMakes,
    selectModels,
    selectModifications,
    selectYears,
    setModelDropdownState,
    setSelectType,
    setVehicleMakeId,
    setVehicleModelId,
    setVehicleModificationId,
    setVehicleYear
} from '../VehicleModal/redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    fields: selectFields(state),
    years: selectYears(state),
    makes: selectMakes(state),
    models: selectModels(state),
    modifications: selectModifications(state),
    colors: selectColors(state)
});

const mapDispatchToProps = {
    fetchVehicleYears,
    fetchVehicleMakes,
    fetchVehicleModels,
    fetchVehicleModifications,
    setVehicleYear,
    setVehicleMakeId,
    setVehicleModelId,
    setVehicleModificationId,
    clearVehicleData
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class MyNormativesAddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            labors: [],
            price: 0,
            hours: 0
        };
    }

    fetchLabors = async () => {
        const response = await fetchAPI('GET', 'labors');
        response.labors.sort((a, b) =>
            a.masterLaborId < b.masterLaborId ? -1 : a.masterLaborId > b.masterLaborId ? 1 : 0);
        this.setState({
            labors: response.labors
        });
    };

    handleOk = async () => {
        const {
            modalProps: { edit }
        } = this.props;
        const {
            modificationId,
            laborId,
            storeGroupId,
            name,
            price,
            hours,
            year,
            orderId,
            regionId,
            bodyId
        } = this.state;
        const { region } = await fetchAPI('GET', 'business');
        const id = _.get(edit, 'id');
        if (id) {
            await fetchAPI(
                'PUT',
                `labor_standarts/${id}`,
                null,
                {
                    modificationId,
                    storeGroupId,
                    laborId,
                    regionId: regionId || region || 'UA.00.00.00',
                    name,
                    price,
                    hours,
                    year,
                    orderId,
                    bodyId
                },
                { handleErrorInternally: true }
            );
        } else {
            await fetchAPI(
                'POST',
                'labor_standarts',
                null,
                [
                    {
                        modificationId,
                        storeGroupId,
                        laborId,
                        regionId: regionId || region || 'UA.00.00.00',
                        name,
                        price,
                        hours,
                        year,
                        orderId,
                        bodyId
                    }
                ],
                { handleErrorInternally: true }
            );
        }
        notification.success({
            message: this.props.intl.formatMessage({ id: 'barcode.success' })
        });
        this.props.updateTable();
        this.handleCancel();
    };

    handleCancel = () => {
        const { clearVehicleData } = this.props;
        clearVehicleData();
        this.setState({
            modificationId: undefined,
            storeGroupId: undefined,
            laborId: undefined,
            name: undefined,
            price: 0,
            normPrice: null,
            hours: 0,
            year: undefined,
            orderId: undefined,
            regionId: undefined,
            edit: false
        });
        this.props.edit;
        this.props.resetModal();
    };

    async componentDidUpdate(prevProps) {
        const {
            visible,
            modalProps: { laborStandartCopy, edit },
            makes,
            models,
            modifications
        } = this.props;
        const lb = laborStandartCopy || edit;
        if (
            prevProps.visible !== MODALS.MY_NORMATIVES_ADD_MODAL &&
            visible === MODALS.MY_NORMATIVES_ADD_MODAL
        ) {
            this.props.fetchVehicleYears();
            if (lb) {
                await this.selectVehicle(lb);

                const {
                    name,
                    laborId,
                    storeGroupId,
                    orderId,
                    regionId,
                    year,
                    modificationId,
                    price,
                    hours
                } = lb;
                this.setState({
                    name,
                    laborId,
                    storeGroupId,
                    orderId: orderId || undefined,
                    regionId,
                    year,
                    modificationId,
                    price: Number(price),
                    hours: Number(hours)
                });
            }
        }
        if (
            lb &&
            (prevProps.makes != makes ||
                prevProps.models != models ||
                prevProps.modifications != modifications)
        ) {
            this.selectVehicle(lb);
        }
    }

    selectVehicle = async lb => {
        const {
            fields,
            years,
            makes,
            models,
            modifications,

            fetchVehicleMakes,
            fetchVehicleModels,
            fetchVehicleModifications,

            setVehicleYear,
            setVehicleMakeId,
            setVehicleModelId,
            setVehicleModificationId
        } = this.props;

        const { modificationId, year, make, model } = lb;
        if (!fields.year) {
            await setVehicleYear({ year });
            await fetchVehicleMakes();
        } else if (!fields.makeId) {
            const vehicle = makes.find(mk => mk.name === make);
            if (vehicle) {
                await setVehicleMakeId({ makeId: vehicle.id });
                await fetchVehicleModels();
            }
        } else if (!fields.modelId) {
            await setVehicleModelId({
                modelId: models.find(md => md.name.toLowerCase().includes(model.toLowerCase())).id
            });
            await fetchVehicleModifications();
        } else if (!fields.modificationId) {
            await setVehicleModificationId({ modificationId });
        }
    };

    componentDidMount = async () => {
        this.fetchLabors();
    };

    render() {
        const {
            visible,
            resetModal,
            fields,
            years,
            makes,
            models,
            modifications,

            fetchVehicleMakes,
            fetchVehicleModels,
            fetchVehicleModifications,

            setVehicleYear,
            setVehicleMakeId,
            setVehicleModelId,
            setVehicleModificationId,
            intl: { formatMessage },
            modalProps: { laborStandartCopy, edit }
        } = this.props;

        const {
            labors,
            modificationId,
            laborId,
            storeGroupId,
            regionId,
            name,
            price,
            hours,
            year,
            orderId,
            rewriteExisting,
            forNeighborRegions,
            forNeighborModifications
            /* regions, */
        } = this.state;

        const initValues = {
            year: fields.year,
            makeId: fields.makeId,
            modelId: fields.modelId,
            modificationId: fields.modificationId,
            makeName: fields.makeName,
            modelName: fields.modelName
        };

        return (
            <div>
                <Modal
                    maskClosable={false}
                    okButtonProps={
                        laborStandartCopy
                            ? {
                                  disabled:
                                      modificationId === laborStandartCopy.modificationId &&
                                      laborId === laborStandartCopy.laborId
                              }
                            : {}
                    }
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={
                        <FormattedMessage
                            id={
                                edit
                                    ? 'my_normative_page.modal_edit_normative'
                                    : 'my_normative_page.modal_add_normative'
                            }
                        />
                    }
                    visible={visible === MODALS.MY_NORMATIVES_ADD_MODAL}
                    width='50vw'
                >
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='add_client_form.year' />
                        </Col>
                        <Col span={12}>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    this.setState({
                                        year: value
                                    });
                                    setVehicleYear({ year: value });
                                    fetchVehicleMakes();
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'add_client_form.year_placeholder'
                                })}
                                showSearch
                                value={year}
                            >
                                {years
                                    .sort(function (year1, year2) {
                                        return year2 - year1;
                                    })
                                    .map(year => (
                                        <Option key={year} value={year}>
                                            {year}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='add_client_form.make' />
                        </Col>
                        <Col span={12}>
                            <Select
                                disabled={!_.get(fields, 'year')}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    setVehicleMakeId({ makeId: value });
                                    fetchVehicleModels();
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'add_client_form.make_placeholder'
                                })}
                                showSearch
                                value={initValues.makeId}
                            >
                                {makes.map(({ name, id }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='add_client_form.model' />
                        </Col>
                        <Col span={12}>
                            <Select
                                disabled={!_.get(fields, 'makeId')}
                                dropdownMatchSelectWidth={false}
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={(value, option) => {
                                    const selectedModelId = _.get(
                                        _.filter(
                                            models,
                                            obj =>
                                                String(obj.name).toLowerCase() ==
                                                String(value).toLowerCase()
                                        ),
                                        '[0].id'
                                    );
                                    if (selectedModelId) {
                                        setVehicleModelId({ modelId: selectedModelId });
                                        setSelectType({ selectType: undefined });
                                        setModelDropdownState(false);
                                        fetchVehicleModifications();
                                    }
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'add_client_form.model_placeholder'
                                })}
                                showSearch
                                value={
                                    _.get(
                                        _.filter(models, obj => obj.id == initValues.modelId),
                                        '[0].name'
                                    ) ||
                                    (fields.selectType !== 'NONE' &&
                                        _.get(
                                            String(
                                                initValues.modelName ||
                                                    formatMessage({
                                                        id: 'add_client_form.model_placeholder'
                                                    })
                                            )
                                        ))
                                }
                            >
                                {models.map(({ name, id, bodyId }) => (
                                    <Option key={id} bodyId={bodyId} value={name}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='add_client_form.modification' />
                        </Col>
                        <Col span={12}>
                            <Select
                                disabled={!_.get(fields, 'modelId')}
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={(value, option) => {
                                    this.setState({
                                        modificationId: value,
                                        bodyId: option.bodyId
                                    });
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'add_client_form.modification_placeholder'
                                })}
                                showSearch
                                value={modificationId}
                            >
                                {modifications.map(({ name, id, bodyId }) => (
                                    <Option key={id} bodyId={bodyId} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='services_table.labor' />
                        </Col>
                        <Col span={12}>
                            <Select
                                dropdownMatchSelectWidth
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(laborId, option) => {
                                    this.setState({
                                        laborId,
                                        price: option.props.price,
                                        hours: option.props.hours,
                                        storeGroupId: option.props.storeGroupId,
                                        name:
                                            option.props.name ||
                                            option.props.customName ||
                                            option.props.masterLaborName
                                    });
                                }}
                                optionFilterProp='children'
                                placeholder={<FormattedMessage id='services_table.labor' />}
                                showSearch
                                value={laborId}
                            >
                                {labors.map(
                                    ({
                                        id,
                                        customName,
                                        name,
                                        masterLaborName,
                                        laborPrice,
                                        storeGroupId
                                    }) => (
                                        <Option
                                            key={id}
                                            customName={customName}
                                            hours={laborPrice.normHours}
                                            masterLaborName={masterLaborName}
                                            name={name}
                                            price={laborPrice.price}
                                            storeGroupId={storeGroupId}
                                            value={id}
                                        >
                                            {`${customName || name || masterLaborName} (#${id}) `}
                                        </Option>
                                    )
                                )}
                            </Select>
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='order_form_table.price' />
                        </Col>
                        <Col span={4}>
                            <InputNumber
                                decimalSeparator=','
                                onChange={price => {
                                    this.setState({
                                        price
                                    });
                                }}
                                precision={2}
                                value={price}
                            />
                        </Col>
                        <Col span={4}>
                            <FormattedMessage id='hours' />
                        </Col>
                        <Col span={6}>
                            <InputNumber
                                decimalSeparator=','
                                onChange={hours => {
                                    this.setState({
                                        hours
                                    });
                                }}
                                precision={2}
                                step={0.5}
                                value={hours}
                            />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
