import { Tabs } from 'antd';
import { Catcher } from 'commons';
import { CallsStatistics } from 'components';
import {
    fetchCalls,
    fetchCallsChart,
    fetchRecordingLink,
    selectCallsChartData,
    selectCallsData,
    selectCallsFilter,
    selectCallsLinksCache,
    selectCallsPieData,
    selectCallsStats,
    selectCurrentTab,
    setCallsChartMode,
    setCallsPageFilter,
    setCallsTab,
    setCallsTableMode,
    tabs
} from 'core/calls/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { TabPane } = Tabs;

const mapStateToProps = state => {
    return {
        calls: selectCallsData(state),
        stats: selectCallsStats(state),
        pieStats: [...selectCallsPieData(state)],
        chart: [...selectCallsChartData(state)],
        filter: selectCallsFilter(state),
        currentTab: selectCurrentTab(state),
        callsChartFetching: state.ui.callsChartFetching,
        callsLinksCache: selectCallsLinksCache(state)
    };
};

const mapDispatchToProps = {
    fetchCalls,
    fetchCallsChart,
    setCallsTab,
    setCallsChartMode,
    setCallsTableMode,
    setCallsPageFilter,
    fetchRecordingLink
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class CallsContainer extends Component {
    _handleTab = tab => {
        if (tab === tabs.callsTable) {
            this.props.setCallsTab(tabs.callsTable);
            this.props.fetchCalls();
        }
        if (tab === tabs.callsChart) {
            this.props.setCallsTab(tabs.callsChart);
            this.props.fetchCallsChart();
        }
    };

    /* eslint-enable complexity */
    render() {
        const {
            calls,
            chart,
            stats,
            pieStats,
            filter,
            currentTab,
            intl: { formatMessage },
            fetchCalls,
            setCallsChartMode,
            setCallsTableMode,
            setCallsPageFilter,
            fetchCallsChart,
            callsChartFetching,
            callsFetching,
            fetchRecordingLink,
            callsLinksCache
        } = this.props;

        return (
            <Catcher>
                <Tabs activeKey={currentTab} onTabClick={tab => this._handleTab(tab)}>
                    <TabPane
                        key={tabs.callsChart}
                        tab={formatMessage({
                            id: 'calls-page.statistics'
                        })}
                    >
                        <CallsStatistics
                            callsChartFetching={callsChartFetching}
                            chart={chart}
                            fetchCallsChart={fetchCallsChart}
                            pieStats={pieStats}
                            setCallsChartMode={setCallsChartMode}
                            stats={stats}
                        />
                    </TabPane>
                    {/* <TabPane
                        key={tabs.callsTable}
                        tab={formatMessage({
                            id: 'calls-page.calls'
                        })}
                    >
                        <CallsTable
                            calls={calls}
                            callsFetching={callsFetching}
                            callsLinksCache={callsLinksCache}
                            fetchCalls={fetchCalls}
                            fetchRecordingLink={fetchRecordingLink}
                            filter={filter}
                            setCallsPageFilter={setCallsPageFilter}
                            setCallsTableMode={setCallsTableMode}
                            stats={stats}
                        />
                    </TabPane> */}
                </Tabs>
            </Catcher>
        );
    }
}

export default CallsContainer;
