/*
The purpose of this module is to provide report about all client debts.
Also it provides basic search and print button.
*/
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select } from 'antd';
import { Catcher } from 'commons';
import _, { get } from 'lodash';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { CashOrdersTable } from './components/tables';
import {
    fetchExcelFileReport,
    fetchReport,
    selectFilter,
    setReportBusinessReq,
    setReportOverdueOnly,
    setReportQuery
} from './redux/duck';
import Styles from './styles.m.css';

const { Search } = Input;

const mapStateToProps = state => ({
    report: state.clientsDebtsContainer.report,
    isFetching: state.ui.reportFetching,
    businessRequisites: selectBusinessRequisites(state),
    filter: selectFilter(state),
    user: state.auth
});

const mapDispatchToProps = {
    fetchReport,
    fetchExcelFileReport,
    setReportQuery,
    setReportOverdueOnly,
    fetchBusinessRequisites,
    setReportBusinessReq
};

/**
 * This container is used to show clients' debts, this is named receivables.
 * Дебіторська заборгованість - заборгованість клієнтів перед СТО станцією.
 */
@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientsDebtsContainer extends Component {
    constructor(props) {
        super(props);

        this.handleReportsSearch = _.debounce(value => {
            const { fetchReport, setReportQuery } = this.props;
            setReportQuery(value);
            fetchReport();
        }, 1000);
    }

    componentDidMount() {
        this.props.fetchReport();
        this.props.fetchBusinessRequisites();

        if (this.props.location.state && this.props.location.state.downloadReport) {
            this.props.fetchExcelFileReport();
        }
    }

    render() {
        const { isFetching, fetchExcelFileReport, fetchReport, report, setReportOverdueOnly, filter, user } =
            this.props;

        return (
            <div>
                <section className={Styles.mainContainer}>
                    <Catcher>
                        <div className={Styles.filtersControlsCont}>
                            <div className={Styles.searchBar}>
                                <Search
                                    className={Styles.search}
                                    onChange={({ target: { value } }) => {
                                        this.handleReportsSearch(value);
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'clients_debts_container.filter_placeholder'
                                    })}
                                />
                                <Select
                                    allowClear
                                    className={Styles.search}
                                    onChange={async value => {
                                        await this.props.setReportBusinessReq(value);
                                        this.handleReportsSearch();
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'storage.business_requisites'
                                    })}
                                    showSearch
                                    style={{ width: 280 }}
                                    value={filter.filterBusinessRequisiteId}
                                >
                                    {this.props.businessRequisites
                                        .filter(({ used }) => used)
                                        .map(elem => (
                                            <Select.Option key={elem.id} value={elem.id}>
                                                {elem.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                                <Button
                                    onClick={() => fetchExcelFileReport()}
                                    style={{
                                        fontSize: 16
                                    }}
                                    title={this.props.intl.formatMessage({
                                        id: 'clients_debts_container.get_excel_file'
                                    })}
                                >
                                    <DownloadOutlined
                                        className={Styles.btnStyle}
                                        title={this.props.intl.formatMessage({
                                            id: 'clients_debts_container.get_excel_file'
                                        })}
                                        type='download'
                                    />
                                </Button>
                            </div>

                            <div>
                                <Checkbox
                                    onChange={e => {
                                        setReportOverdueOnly(e.target.checked);
                                        fetchReport();
                                    }}
                                >
                                    <FormattedMessage id='clients_debts_container.overdue_only' />
                                </Checkbox>
                            </div>
                        </div>

                        <div className={Styles.content}>
                            <CashOrdersTable
                                paymentDisabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES_PAYMENT,
                                        accesses.ROWO
                                    )
                                }
                                reportFetching={isFetching}
                                stats={get(report, 'stats')}
                                tableData={get(report, 'tableData')}
                            />
                        </div>
                    </Catcher>
                </section>
            </div>
        );
    }
}
