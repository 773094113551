import { Select, Spin } from 'antd';
import { setBusinessSearchQuery } from 'core/search/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    businesses: state.search.businesses,
    isFetchingBusinesses: state.search.isFetchingBusinesses
});

const mapDispatchToProps = {
    setBusinessSearchQuery
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BusinessSearchField extends Component {
    render() {
        const { onSelect, setBusinessSearchQuery } = this.props;
        const { businesses, isFetchingBusinesses, businessId } = this.props;

        return (
            <Select
                allowClear
                filterOption={false}
                notFoundContent={
                    isFetchingBusinesses ? (
                        <Spin size='small' />
                    ) : (
                        <FormattedMessage id='not_found' />
                    )
                }
                onChange={businessId => onSelect(businessId)}
                onSearch={item => setBusinessSearchQuery(item)}
                placeholder={this.props.intl.formatMessage({
                    id: 'business_placeholder'
                })}
                showSearch
                style={this.props.selectStyles}
                value={businessId}
            >
                {isFetchingBusinesses
                    ? []
                    : businesses.map(({ businessId, name, address }) => (
                          <Option key={businessId} value={businessId}>
                              ({businessId}) {name} [{address}]
                          </Option>
                      ))}
            </Select>
        );
    }
}
