import { Form } from '@ant-design/compatible';
import { Checkbox, Modal, Select } from 'antd';
import { MODALS, selectModalProps } from 'core/modals/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import { createStoreGroup, deleteStoreGroup, updateStoreGroup } from 'core/storage/storeGroups';
import { DecoratedCheckbox, DecoratedInput, DecoratedInputNumber, DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: selectModalProps(state),
    priceGroups: selectPriceGroups(state)
});

const mapDispatchToProps = {
    createStoreGroup,
    updateStoreGroup,
    deleteStoreGroup,
    fetchPriceGroups
};

const StoreGroup = props => {
    const {
        visible,
        resetModal,
        modalProps,
        form: { getFieldDecorator, getFieldValue, setFieldsValue },
        intl: { formatMessage }
    } = props;

    const name = _.get(modalProps, 'storeGroup.name');
    const parentGroupId = _.get(modalProps, 'storeGroup.id');
    const systemWide = _.get(modalProps, 'storeGroup.systemWide');
    const initialIsRound = _.get(modalProps, 'storeGroup.isRound');

    const deleteMode = _.get(modalProps, 'delete');

    const [isRound, setRound] = useState(false);
    const [roundAll, setRoundAll] = useState(false);
    const [decimalPlaces, setDecimalPlaces] = useState('');
    const [markups, setMarkups] = useState([]);

    const fetchMarkups = useCallback(async () => {
        const response = await fetchAPI('GET', '/markups', null, null, {
            handleErrorInternally: true
        });

        setMarkups(response);
    }, []);

    useEffect(() => {
        props.fetchPriceGroups();
        fetchMarkups();
    }, []);

    const submit = () => {
        const { form, resetModal, modalProps } = props;

        form.validateFields((err, values) => {
            if (!err) {
                const id = _.get(modalProps, 'storeGroup.id');

                if (modalProps.create) {
                    props.createStoreGroup(values);
                }
                if (modalProps.edit) {
                    props.updateStoreGroup({ ...values, id });
                    // fetchAPI('PUT', `/store_groups/${id}`, undefined, {
                    //     isRound,
                    //     decimalPlaces,
                    //     roundAll
                    // });
                }
                if (modalProps.delete) {
                    props.deleteStoreGroup(id);
                }
                form.resetFields();
                resetModal();
            }
        });
    };

    const renderSystemWideCheckbox = () => {
        if (isGrantAccessed(props.user, grants.SETTINGS_PRODUCT_GROUPS, accesses.ROWO)) {
            if (!systemWide) {
                return (
                    <DecoratedCheckbox
                        disabled={!modalProps.create}
                        field='systemWide'
                        fields={{}}
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={!systemWide}
                        label={formatMessage({ id: 'storage.system_wide' })}
                    />
                );
            }

            return null;
        }

        return null;
    };

    const defaultMarkup = markups ? markups.find(({ defaultByStoreGroups }) => defaultByStoreGroups) : undefined;

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                type: deleteMode ? 'dashed' : 'primary'
            }}
            okText={deleteMode ? <FormattedMessage id='delete' /> : <FormattedMessage id='save' />}
            onCancel={() => resetModal()}
            onOk={() => submit()}
            visible={visible === MODALS.STORE_GROUP}
            width='50%'
        >
            <Form onSubmit={submit} style={{ padding: 24 }}>
                <DecoratedInput
                    disabled
                    field='name'
                    fields={{}}
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={name}
                    label={formatMessage({ id: 'storage.product_group' })}
                />
                <DecoratedInput
                    field='parentGroupId'
                    fields={{}}
                    getFieldDecorator={getFieldDecorator}
                    hiddeninput='hiddeninput'
                    initialValue={parentGroupId}
                />
                {/* <PriceGroupSelect
                    field='priceGroupNumber'
                    formatMessage={formatMessage}
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={_.get(modalProps, 'storeGroup.priceGroupNumber')}
                    label={formatMessage({ id: 'storage.price_group' })}
                    priceGroups={props.priceGroups}
                /> */}
                <DecoratedSelect
                    field='markupGroupId'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={_.get(modalProps, 'storeGroup.markupGroupId') || _.get(defaultMarkup, 'id')}
                    label={formatMessage({ id: 'new-document-page.item.markup-group' })}
                    showSearch
                >
                    {(markups || [])
                        .filter(({ enabled, using }) => enabled && using !== 'CUSTOMER_GROUP')
                        .map(markup => {
                            return (
                                <Option key={markup.id} value={markup.id}>
                                    {markup.name} {<FormattedMessage id={`product_markups.${markup.using}`} />} (
                                    {markup.markups[0] ? markup.markups[0].lowerLimit : undefined} -{' '}
                                    {markup.markups[0] ? markup.markups[0].markup : undefined};{' '}
                                    {markup.markups[1] ? markup.markups[1].lowerLimit : undefined} -{' '}
                                    {markup.markups[1] ? markup.markups[1].markup : undefined};
                                    {markup.markups[2] ? markup.markups[2].lowerLimit : undefined} -{' '}
                                    {markup.markups[2] ? markup.markups[2].markup : undefined};
                                    {markup.markups[3] ? '...' : ''})
                                </Option>
                            );
                        })}
                </DecoratedSelect>
                {props.brands && props.suppliers ? (
                    <React.Fragment>
                        <DecoratedSelect
                            disabled={_.get(modalProps, 'storeGroup.fixedBrand')}
                            field='defaultBrandId'
                            formItem
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(modalProps, 'storeGroup.brandId')}
                            label={formatMessage({ id: 'storage.default_brand' })}
                            showSearch
                        >
                            {props.brands.map(brand => {
                                return (
                                    <Option key={brand.brandId} value={brand.brandId}>
                                        {brand.brandName}
                                    </Option>
                                );
                            })}
                        </DecoratedSelect>
                        <DecoratedSelect
                            field='businessSupplierId'
                            formItem
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(modalProps, 'storeGroup.businessSupplierId')}
                            label={formatMessage({ id: 'storage.business_supplier' })}
                            showSearch
                        >
                            {props.suppliers.map(supplier => {
                                return (
                                    <Option key={supplier.id} value={supplier.id}>
                                        {supplier.name}
                                    </Option>
                                );
                            })}
                        </DecoratedSelect>

                        <DecoratedCheckbox
                            field='isRound'
                            formItem
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(modalProps, 'storeGroup.isRound')}
                            label={<FormattedMessage id='storage.round' />}
                        />

                        {/* <Switch
                            checked={
                                getFieldValue('isRound')
                                    ? getFieldValue('isRound')
                                    : _.get(modalProps, 'storeGroup.isRound')
                            }
                            disabled={deleteMode}
                            onChange={async () => {
                                await setFieldsValue({ isRound: !getFieldValue('isRound') });
                            }}
                        />
                        <span
                            style={{
                                marginLeft: 4
                            }}
                        >
                            <FormattedMessage id='storage.round' />
                        </span> */}

                        {getFieldValue('isRound') || _.get(modalProps, 'storeGroup.isRound') ? (
                            <DecoratedInputNumber
                                field='decimalPlaces'
                                formItem
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                initialValue={_.get(modalProps, 'storeGroup.decimalPlaces')}
                                label={<FormattedMessage id='product_price.number_characters' />}
                                min={0}
                                style={{ width: '50%' }}
                            />
                        ) : undefined}

                        <DecoratedCheckbox
                            field='roundAll'
                            formItem
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(modalProps, 'storeGroup.roundAll')}
                            label={<FormattedMessage id='storage.round_all' />}
                        />
                        {/* <div>
                            <Switch
                                checked={getFieldValue('roundAll')}
                                disabled={deleteMode}
                                onChange={async () => {
                                    await setFieldsValue({ roundAll: !getFieldValue('roundAll') });
                                }}
                            />
                            
                        </div> */}
                        <DecoratedCheckbox
                            field='assignToChildGroups'
                            formItem
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            hidden
                        />
                        <Checkbox
                            checked={getFieldValue('assignToChildGroups')}
                            onChange={() =>
                                setFieldsValue({ assignToChildGroups: !getFieldValue('assignToChildGroups') })
                            }
                        >
                            <FormattedMessage id='storage.assign_to_child' />
                        </Checkbox>
                    </React.Fragment>
                ) : null}
                {/* systemWide is null if active or businessId if not  */}
                {renderSystemWideCheckbox()}
            </Form>
        </Modal>
    );
};

const StoreProductModal = injectIntl(Form.create()(connect(mapStateToProps, mapDispatchToProps)(StoreGroup)));

export default StoreProductModal;
