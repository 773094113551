// Core
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, Popconfirm, Table, notification } from 'antd';
import { Layout } from 'commons';
import { resetModal, setModal } from 'core/modals/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Panel } = Collapse;

const mapStateToProps = state => ({
    modal: state.modals.modal,
    user: state.auth
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class SourcePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            source: [],
            pageSize: 10,
            page: 1
        };

        this.columns = () => [
            {
                key: 'edit',
                width: '3%',
                align: 'center',
                render: (data, row) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Popconfirm
                                disabled={
                                    !row.deletePermission ||
                                    !isGrantAccessed(this.props.user, grants.SETTINGS_SOURCE, accesses.ROWO)
                                }
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        'business/acquiring/sources',
                                        null,
                                        { sourceId: Number(row.id) },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setTimeout(() => this.fetchData(), 100);
                                }}
                                title={`${props.intl.formatMessage({ id: 'delete' })} ?`}
                            >
                                <Button
                                    disabled={
                                        !row.deletePermission ||
                                        !isGrantAccessed(this.props.user, grants.SETTINGS_SOURCE, accesses.ROWO)
                                    }
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='navigation.source' />,
                key: 'sourceName',
                dataIndex: 'sourceName',
                width: '30%'
            }
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const { page, pageSize } = this.state;
        const source = await fetchAPI('GET', 'business/acquiring/sources', { page, pageSize }, undefined);
        this.setState({
            source: source.query || [],
            stats: source.count
        });
    };

    generalRegulations = async () => {
        await fetchAPI('POST', 'businesses/regulations/copy', undefined, {
            copyOption: 'business'
        });
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'order-status.success' })
        });
        await setTimeout(() => this.fetchData(), 100);
    };

    render() {
        const { user } = this.props;
        const { source, sourceName, pageSize, page, stats } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(stats / pageSize) * pageSize,
            hideOnSinglePage: false,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchData();
            }
        };

        return (
            <React.Fragment>
                <Layout title={<FormattedMessage id='add_client_form.source_directory' />}>
                    <div className={Styles.inputBlockStyle}>
                        <div className={Styles.inputMargin}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    marginBottom: 8
                                }}
                            >
                                <FormattedMessage id='add_client_form.source_name' />
                            </div>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <div>
                                    <Input
                                        onChange={event => {
                                            this.setState({
                                                sourceName: event.target.value
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'add_client_form.source_placeholder'
                                        })}
                                        value={sourceName}
                                    />
                                </div>
                                <div>
                                    <Button
                                        disabled={!sourceName}
                                        icon={<PlusOutlined />}
                                        onClick={async () => {
                                            await fetchAPI(
                                                'POST',
                                                'business/acquiring/sources',
                                                null,
                                                {
                                                    sourceName,
                                                    custom: true
                                                },
                                                {
                                                    handleErrorInternally: true
                                                }
                                            );
                                            this.setState({
                                                sourceName: undefined
                                            });
                                            await setTimeout(() => this.fetchData(), 200);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Table bordered columns={this.columns()} dataSource={source} pagination={pagination} size='small' />
                </Layout>
                {/* <AddSourceModal
                    editSource={this.editSource}
                    fetchData={this.fetchData}
                    hideModal={() => {
                        this.setState({
                            visibleAddSourceModal: false,
                            sourceModalRow: undefined
                        });
                    }}
                    sourceModalRow={sourceModalRow}
                    visible={visibleAddSourceModal}
                /> */}
            </React.Fragment>
        );
    }
}
