import { Button, Modal, Select } from 'antd';
import { DateRangePicker } from 'components';
import { selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from '../styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    suppliers: state.suppliers.suppliers,
    clients: state.clients.clients,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchSuppliers
};
const requiredField = () => <b style={{ color: 'var(--required)' }}> *</b>;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class DownloadSalReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidUpdate(prevProps) {
        const { calculatedSalary, onlyForCurrentEmployee, empId, startD, endD } = this.props;
        const { employeeId } = this.state;
        if (!prevProps.visible && this.props.visible) {
            if (onlyForCurrentEmployee) {
                this.setState({
                    employeeIds: [Number(empId)],
                    startDate: dayjs(startD),
                    endDate: dayjs(endD)
                });
            }
        }
    }

    downloadXlsxReport = async () => {
        const { allEmployees } = this.props; 
        const { employeeIds, startDate, endDate } = this.state;
        const url = '/employee/salaryDoc/report';
        try {
            const response = await fetchAPI(
                'GET',
                url,
                {
                    employeeIds: employeeIds && employeeIds.length ? employeeIds : allEmployees.map(el => el.id),
                    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
                    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined
                },
                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true
                }
            );
            const reportFile = await response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
            await saveAs(reportFile, fileName);
            this.setState({
                loading: false
            });
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    handleOk = async () => {
        this.setState({
            loading: true
        });
        await this.downloadXlsxReport();
        this.handleCancel();
    };

    handleCancel = () => {
        const { hideModal } = this.props;

        hideModal();
    };

    render() {
        const { visible, allEmployees, onlyForCurrentEmployee, empId, handleSearchEmployee, isMobile } = this.props;
        const { employeeIds, startDate, endDate, loading } = this.state;

        return (
            <Modal
                footer={
                    <div>
                        <Button
                            key='back'
                            onClick={() => {
                                this.handleCancel();
                            }}
                        >
                            <FormattedMessage id='stock_table.button.cancel' />
                        </Button>
                        <Button
                            key='submit'
                            disabled={!startDate || !endDate}
                            loading={loading}
                            onClick={async () => {
                                await this.handleOk();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='ok' />
                        </Button>
                    </div>
                }
                maskClosable={false}
                okButtonProps={{
                    disabled: !startDate || !endDate
                }}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='setting-salary.report_title' />}
                visible={visible}
                width={isMobile ? '100%' : '30%'}
            >
                <div className={Styles.blockStyle}>
                    <div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='cash-order-form.counterparty.EMPLOYEE' />

                            <Select
                                allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    mode='multiple'
                                    onChange={value => {
                                        this.setState({
                                            employeeIds: value
                                        });
                                    }}
                                    onSearch={input => handleSearchEmployee(input)}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: '100%' }}
                                    value={employeeIds}
                                    optionFilterProp='children'
                                    // mode='multiple'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                            >
                                {allEmployees
                                    .filter(({ disabled }) => !disabled)
                                    .map(({ id, name, surname }) => (
                                        <Option key={id} value={id}>
                                            {`${name} ${surname}`}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='setting-salary.period' />
                            {requiredField()}
                            <DateRangePicker
                                allowClear
                                dateRange={[
                                    startDate ? dayjs(startDate) : undefined,
                                    endDate ? dayjs(endDate) : undefined
                                ]}
                                onDateChange={async dateRange => {
                                    await this.setState({
                                        startDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                                        endDate: dayjs(dateRange[1]).format('YYYY-MM-DD')
                                    });
                                }}
                                popupStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
