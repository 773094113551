import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DEF_BACK_DATE, DEF_UI_DATE, MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectCashOrderDate,
    selectMode,
    selectSelectedCashbox,
    setCashOrderDate
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    cashOrderDate: selectCashOrderDate(state),
    mode: selectMode(state),
    selectedCashbox: selectSelectedCashbox(state)
});

const mapDispatchToProps = {
    setCashOrderDate
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class DateSelector extends React.Component {
    render() {
        const {
            cashOrderDate,
            setCashOrderDate,
            selectedCashbox,
            mode,
            editable,

            intl: { formatMessage }
        } = this.props;

        const disabled = Boolean(mode !== MODES.ADD || _.get(selectedCashbox, 'rst')); // Disallow changing date for rst cashboxes

        return (
            <div title={formatMessage({ id: 'refactored_cash_order_modal.hint_date' })}>
                <DatePicker
                    allowClear={false}
                    className={Styles.datePicker}
                    data-qa='select_date'
                    disabled={!editable && disabled}
                    format={DEF_UI_DATE}
                    onChange={date => setCashOrderDate(date.format(DEF_BACK_DATE))}
                    popupStyle={{
                        zIndex: 5555
                    }}
                    value={dayjs(cashOrderDate, DEF_BACK_DATE)}
                />
            </div>
        );
    }
}
