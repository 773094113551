/* eslint-disable max-classes-per-file */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Image, Modal, Switch } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

class DetailElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { isMobile, checked, data, num, onRadioDetail, isOpened } = this.props;
        const renderDetails = checked ? [{ ...data }, ...(data.variations || [])] : [data];
        const disabled = data.agreement === 'REJECTED' || (data.agreement === 'AGREED' && renderDetails.length === 1);

        return (
            <div style={{ borderBottom: '1px solid black' }}>
                {renderDetails.map((data, index) => {
                    return isMobile ? (
                        <div
                            key={`dr-${index}`}
                            className={`${Styles.detailElement} ${disabled || !checked ? Styles.disabledRow : null}`}
                            style={{
                                backgroundColor: data.isCritical ? 'rgb(250,175,175)' : null,
                                padding: !index ? 8 : '4px 8px'
                            }}
                        >
                            <div style={{ width: '6%', fontSize: '18px' }}>
                                {index === 0 ? this.props.num : ''}
                                {/* {Boolean((renderDetails[0].variations || []).length) && (
                                    <Radio
                                        checked={data.radio}
                                        disabled={disabled}
                                        onClick={() => onRadioDetail(num - 1, index)}
                                    />
                                )} */}
                            </div>
                            <div style={{ width: '65%', padding: '0 4px' }}>
                                {index === 0 && <p style={{ padding: '4px 0' }}>{data.detailName}</p>}

                                <p style={{ fontStyle: 'italic', padding: '4px 0' }}>
                                    {data.brandEnabled ? data.brandName : undefined}
                                    {_.get(data, 'brandInfo[0].brandId') && data.brandEnabled && (
                                        <Button
                                            icon={<InfoCircleOutlined style={{ fontSize: 18 }} />}
                                            onClick={() => {
                                                Modal.info({
                                                    content: (
                                                        <div>
                                                            <Image
                                                                alt={data.brandInfo[0].brandLogo}
                                                                preview={false}
                                                                src={`data:image/png;base64,${data.brandInfo[0].brandLogo}`}
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'block'
                                                                }}
                                                            />
                                                            <p>{data.brandInfo[0].brandTitleUA}</p>
                                                        </div>
                                                    )
                                                });
                                            }}
                                            type='text'
                                        />
                                    )}{' '}
                                    {data.codeEnabled ? data.oap_code : undefined}
                                </p>
                            </div>
                            <div style={{ width: '15%', fontSize: '16px' }}>{data.measureUnit}</div>
                            <div style={{ width: '15%', fontSize: '16px' }}>
                                {Number(data.count)
                                    .toFixed(1)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            </div>
                            <div style={{ width: '15%' }}>
                                <div
                                    style={{
                                        width: '100%',
                                        padding: '4px 0',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {Number(data.sum)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                </div>
                                <div style={{ width: '100%', padding: !index ? '4px 0' : 0, whiteSpace: 'nowrap' }}>
                                    <Switch
                                        checked={!checked ? false : data.radio}
                                        disabled={!isOpened || disabled}
                                        onClick={() => {
                                            if (!checked) {
                                                onRadioDetail(num - 1, index);
                                                this.props.onSwitchDetail(this.props.num - 1, !checked);

                                                return;
                                            }
                                            if (!data.radio) {
                                                onRadioDetail(num - 1, index);
                                            } else {
                                                this.props.onSwitchDetail(this.props.num - 1, !data.radio);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`${Styles.detailElement} ${disabled || !checked ? Styles.disabledRow : null}`}
                            style={{
                                backgroundColor: data.isCritical ? 'rgb(250,175,175)' : null,
                                padding: !index ? 10 : '0 10px'
                            }}
                        >
                            <div className={Styles.rowKey}>
                                <span>
                                    {index === 0 ? this.props.num : ''}
                                    {/* {Boolean((renderDetails[0].variations || []).length) && (
                                        <Radio
                                            checked={data.radio}
                                            disabled={disabled}
                                            onClick={() => onRadioDetail(num - 1, index)}
                                        />
                                    )} */}
                                </span>
                            </div>
                            <div className={Styles.rowName}>
                                <span>{data.detailName}</span>
                            </div>
                            {data.brandEnabled && (
                                <div
                                    className={
                                        data.brandEnabled && data.codeEnabled
                                            ? Styles.rowBrand
                                            : Styles.rowBrandSeparate
                                    }
                                >
                                    <span>{data.brandName}</span>
                                </div>
                            )}
                            {data.codeEnabled && (
                                <div
                                    className={
                                        data.brandEnabled && data.codeEnabled ? Styles.rowCode : Styles.rowCodeSeparate
                                    }
                                >
                                    <span>{data.oap_code}</span>
                                </div>
                            )}
                            <div className={Styles.rowPrice}>
                                <span>
                                    {Number(data.price)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                </span>
                            </div>

                            <div className={Styles.rowDiscount}>
                                <span>{Number(data.rowDiscount) || 0} %</span>
                            </div>
                            <div className={Styles.rowDiscount}>
                                <span>{data.measureUnit} </span>
                            </div>
                            <div className={Styles.rowCount}>
                                <span>
                                    {Number(data.count)
                                        .toFixed(1)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                                    <FormattedMessage id='pc' />
                                </span>
                            </div>
                            <div className={Styles.rowSum}>
                                <span>
                                    {Number(data.sum)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                </span>
                            </div>
                            <div className={Styles.rowSwitch}>
                                <Switch
                                    checked={!checked ? false : data.radio}
                                    disabled={disabled}
                                    onClick={() => {
                                        if (!checked) {
                                            onRadioDetail(num - 1, index);
                                            this.props.onSwitchDetail(this.props.num - 1, !checked);

                                            return;
                                        }
                                        if (!data.radio) {
                                            onRadioDetail(num - 1, index);
                                        } else {
                                            this.props.onSwitchDetail(this.props.num - 1, !data.radio);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default DetailElement;
