import { PlusOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import { formKeys, formModes } from 'core/forms/reportAnalyticsForm/duck';
import { MODALS, saveModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectAnalytics,
    selectAnalyticsStats,
    selectFetchingAnalytics,
    selectSelectedAnalyticsUniqueId,
    setSelectedAnalyticsUniqueId
} from 'modals/RefactoredCashOrderModal/redux/analytics/duck';
import { selectCashOrderType, selectMode } from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    analytics: selectAnalytics(state),
    analyticsStats: selectAnalyticsStats(state),
    fetchingAnalytics: selectFetchingAnalytics(state),
    cashOrderType: selectCashOrderType(state),
    analyticsUniqueId: selectSelectedAnalyticsUniqueId(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    setSelectedAnalyticsUniqueId,
    saveModal,
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AnalyticsSelector extends React.Component {
    onOpenAnalyticsModal = () => {
        const { saveModal, setModal } = this.props;

        saveModal();
        setModal(MODALS.REPORT_ANALYTICS, {
            mode: formModes.ADD,
            initialTab: formKeys.analyticsForm
        });
    };

    render() {
        const {
            analytics,
            mode,
            fetchingAnalytics,
            analyticsUniqueId,
            setSelectedAnalyticsUniqueId,
            value,
            editable,
            user,

            intl: { formatMessage },
            analyticsUniqueIdForEmployee
        } = this.props;

        const disabled = Boolean(mode !== MODES.ADD);

        return (
            <div className={Styles.mainCont}>
                <div className={Styles.selectCont}>
                    <Tooltip
                        placement='top'
                        title={<FormattedMessage id='refactored_cash_order_modal.hint_analytics' />}
                        zIndex={2001}
                    >
                        <Select
                            allowClear
                            disabled={
                                !editable &&
                                (disabled || fetchingAnalytics || value === 1201 || value === 1202 || value === 1203)
                            }
                            getPopupContainer={trigger => trigger.parentNode}
                            loading={fetchingAnalytics}
                            onChange={analyticsUniqueId => setSelectedAnalyticsUniqueId(analyticsUniqueId)}
                            optionFilterProp='children'
                            showSearch
                            title='undefined'
                            value={value || analyticsUniqueId}
                        >
                            {_.map(
                                _.filter(analytics, ans => !ans.analyticsDisabled),
                                (item, index) => {
                                    const { analyticsName, analyticsUniqueId } = item;

                                    return (
                                        <Option key={index} value={analyticsUniqueId}>
                                            {analyticsName}
                                        </Option>
                                    );
                                }
                            )}
                        </Select>
                    </Tooltip>
                </div>
                <div className={Styles.analyticsBtnCont} title='Create new analytics'>
                    <Button
                        className={Styles.analyticsBtn}
                        data-qa='btn_analitics'
                        disabled={!isGrantAccessed(user, grants.ACCOUNTING_MONEY_MOVEMENTS_CASH_ORDER_ADD_ANALYTICS)}
                        onClick={() => this.onOpenAnalyticsModal()}
                    >
                        <PlusOutlined className={Styles.addAnalyticsIcon} />
                    </Button>
                </div>
            </div>
        );
    }
}
