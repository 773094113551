import { CheckCircleFilled, CloseSquareFilled, WarningFilled } from '@ant-design/icons';
import { Button, Radio, Select, Space, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import DebouncedInput from 'pages/AccountPlanPage/components/DebouncedInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, filterByPartGeneral } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AditionalRolesModal from '../AditionalRolesModal';
import Styles from './styles.m.css';

const fontOptions = {
    1: 'bold',
    2: 'bold',
    4: 'italic'
};

const { Option } = Select;

const allRolesAcees = [
    {
        name: <FormattedMessage id='packages.role.NA' />,
        value: 'NA',
        color: '#eb8c86',
        colorValue: 'red'
    },
    {
        name: <FormattedMessage id='packages.role.AD' />,
        value: 'AD',
        color: '#eb8c86',
        colorValue: 'red'
    },
    {
        name: <FormattedMessage id='packages.role.RO' />,
        value: 'RO',
        color: '#f0a871',
        colorValue: 'orange'
    },
    {
        name: <FormattedMessage id='packages.role.RA' />,
        value: 'RA',
        color: '#f0a871',
        colorValue: 'orange'
    },
    {
        name: <FormattedMessage id='packages.role.ROWO' />,
        value: 'ROWO',
        color: '#83c468',
        colorValue: 'green'
    },
    {
        name: <FormattedMessage id='packages.role.RAWO' />,
        value: 'RAWO',
        color: '#83c468',
        colorValue: 'green'
    },
    {
        name: <FormattedMessage id='packages.role.WA' />,
        value: 'WA',
        color: '#83c468',
        colorValue: 'green'
    }
];

const radioOptions = Array.from(Array(4)).map((_, index) => index + 1);

const BusinessRolesTab = ({ intl, additionalRoles, fetchAdditionalRoles, user }) => {
    const [query, setQuery] = useState({
        dateFrom: dayjs().startOf('month').toISOString(),
        dateTo: dayjs().toISOString()
    });
    const [businessRoles, setBusinessRoles] = useState([]);
    const [flattenBusinessRoles, setFlattenBusinessRoles] = useState([]);
    const [tablePagination, setTablePagination] = useState({ pageSize: 2 });
    const [foundIds, setFoundIds] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [visibleLevel, setVisibleLevel] = useState(3);
    const [filter, setFilter] = useState('');
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedBusinessRole, setBusinessRole] = useState();
    const [defaultRole, setDefaultRole] = useState();
    const [route, setRoute] = useState('');

    const onFlatten = rights => {
        const flattenTree = [];
        const flattenStoreGroups = data => {
            for (let item = 0; item < get(data, 'length'); item++) {
                const node = data[item];

                flattenTree.push({
                    id: node.id,
                    name: node.name,
                    level: node.level,
                    parentId: node.parentId
                });
                if (node.childs) {
                    flattenStoreGroups(node.childs);
                }
            }
        };
        flattenStoreGroups(rights);

        setFlattenBusinessRoles(flattenTree);
        setExpandedRowKeys(flattenTree.map(({ id }) => id));

        return flattenTree;
    };

    const fetchBusinessRoles = useMemo(
        () => async (roleId, standardRoleId) => {
            const res = await fetchAPI(
                'GET',
                '/v2/roles_and_accesses/business_roles',
                { roleId, standardRoleId },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setBusinessRoles(res.list);

            onFlatten(res.list);
        },
        []
    );

    const fetchBusinessRolesUnflatten = useMemo(
        () => async (roleId, standardRoleId) => {
            const res = await fetchAPI(
                'GET',
                '/v2/roles_and_accesses/business_roles',
                { roleId, standardRoleId },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setBusinessRoles(res.list);
        },
        []
    );

    const handleLevelVisibility = useCallback(
        e => {
            setExpandedRowKeys(flattenBusinessRoles.filter(({ level }) => level < e.target.value).map(({ id }) => id));
            setVisibleLevel(Number(e.target.value));
        },
        [flattenBusinessRoles]
    );

    const handleTableRole = useMemo(
        () => async (roleId, access, treeIds) => {
            await fetchAPI(
                'PUT',
                'v2/roles_and_accesses/business_roles/access',
                null,
                {
                    roleId,
                    access,
                    treeIds
                },
                {
                    handleErrorInternally: true
                }
            );

            const res = await fetchAPI(
                'GET',
                '/v2/roles_and_accesses/business_roles',
                { roleId: selectedBusinessRole, standardRoleId: defaultRole },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setBusinessRoles(res.list);
        },

        [defaultRole, selectedBusinessRole]
    );

    const findIcon = useCallback(isInclude => {
        const icon =
            get(isInclude, 'access', 'NA') == 'AD' || get(isInclude, 'access', 'NA') == 'NA' ? (
                <CloseSquareFilled className={Styles.optionsDangerIconStyle} />
            ) : get(isInclude, 'access', 'NA') == 'RO' || get(isInclude, 'access', 'NA') == 'RA' ? (
                <WarningFilled className={Styles.optionsWarningIconStyle} />
            ) : get(isInclude, 'access', 'NA') == 'ROWO' ||
              get(isInclude, 'access', 'NA') == 'RAWO' ||
              get(isInclude, 'access', 'NA') == 'WA' ? (
                <CheckCircleFilled className={Styles.optionsSuccessIconStyle} />
            ) : undefined;

        return icon;
    }, []);

    const debouncedFilterChange = useMemo(
        () => filter => {
            const foundMap = {};
            const found = flattenBusinessRoles.filter(
                entry => filterByPartGeneral(filter, entry.name) || filterByPartGeneral(filter, entry.id)
            );

            const findParents = entry => {
                if (!foundMap[entry.id]) {
                    foundMap[entry.id] = entry;
                    const parent = flattenBusinessRoles.find(genEntry => genEntry.id === entry.parentId);
                    if (parent) findParents(parent);
                }
            };

            found.forEach(foundEntry => {
                findParents(foundEntry);
            });

            const res = Object.values(foundMap);

            if (filter.length > 0) {
                const maxLevel = Math.max(...found.map(({ level }) => level));
                setExpandedRowKeys(res.filter(({ level }) => level < maxLevel).map(({ id }) => id));
                setFoundIds(found.map(({ id }) => id));
            } else {
                setFoundIds([]);
                setExpandedRowKeys(
                    flattenBusinessRoles.filter(({ level }) => level < visibleLevel).map(({ id }) => id)
                );
            }

            const rows = range => {
                const diff = range[1] - range[0];
                if (diff === 0) return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: 0`;

                return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: ${diff + 1}`;
            };

            if (found.length < 30) {
                setTablePagination({ showTotal: (total, range) => rows(range), pageSize: 9999 });
            } else {
                visibleLevel === 1
                    ? setTablePagination({
                          pageSize: 10,
                          showTotal: (total, range) => rows(range)
                      })
                    : setTablePagination({ pageSize: 2, showTotal: (total, range) => rows(range) });
            }
        },
        [flattenBusinessRoles, intl, visibleLevel]
    );

    const columns = useMemo(
        () => [
            {
                title: <FormattedMessage id='packages.id_access' />,
                key: 'id',
                width: '180px',
                render: record => {
                    const slicedId =
                        record.id && record.level == 1
                            ? String(record.id).slice(0, 2)
                            : record.id && record.level == 2
                            ? String(record.id).slice(0, 4)
                            : record.id && record.level == 3
                            ? String(record.id).slice(0, 6)
                            : String(record.id).slice(0, 8);

                    return record.id ? slicedId : record.id;
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                render: record => {
                    const space =
                        record.level === 2 ? '8px' : record.level === 3 ? '24px' : record.level === 4 ? '38px' : 0;

                    return record.name ? (
                        <div
                            style={{
                                marginLeft: space
                            }}
                        >
                            {record.name}
                        </div>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.business_package' />,
                key: 'package',
                align: 'center',
                render: row => {
                    const isInclude = row.businessPackage ? row.businessPackage : undefined;

                    const icon = findIcon(isInclude);

                    return (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    'NA'
                                )
                            }
                        >
                            <div>
                                {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: () => (
                    <div>
                        <div
                            style={{
                                marginBottom: 8
                            }}
                        >
                            <FormattedMessage id='packages.default_role' />
                        </div>
                        <div className={Styles.divGroup}>
                            <Select
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_ROLES,
                                        accesses.ROWO
                                    )
                                }
                                onChange={value => {
                                    localStorage.setItem('selected_default_role', value);
                                    setDefaultRole(value);
                                }}
                                optionFilterProp='children'
                                placeholder={intl.formatMessage({
                                    id: 'packages.default_role'
                                })}
                                showSearch
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={defaultRole}
                            >
                                {additionalRoles
                                    ? additionalRoles
                                          .filter(({ createdFromRoleId }) => !createdFromRoleId)
                                          .map(({ id, name }) => (
                                              <Option key={id} value={id}>
                                                  {name}
                                              </Option>
                                          ))
                                    : undefined}
                            </Select>
                        </div>
                    </div>
                ),
                key: 'def_role',
                render: row => {
                    const isInclude = row.role ? row.role.access : 'NA';

                    return (
                        <Tooltip title={isInclude ? <FormattedMessage id={`packages.role.${isInclude}`} /> : 'NA'}>
                            <Select
                                disabled
                                dropdownStyle={{
                                    width: 275
                                }}
                                onChange={value => {
                                    handleTableRole(selectedBusinessRole, value, [row.id]);
                                }}
                                optionFilterProp='children'
                                placeholder={intl.formatMessage({
                                    id: 'packages.access'
                                })}
                                style={{
                                    width: '100%'
                                }}
                                value={isInclude}
                            >
                                {allRolesAcees.map(elem => (
                                    <Option key={elem.value} value={elem.value}>
                                        {elem.colorValue == 'red' ? (
                                            <CloseSquareFilled className={Styles.optionsDangerIconStyle} />
                                        ) : elem.colorValue == 'orange' ? (
                                            <WarningFilled className={Styles.optionsWarningIconStyle} />
                                        ) : (
                                            <CheckCircleFilled className={Styles.optionsSuccessIconStyle} />
                                        )}{' '}
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </Tooltip>
                    );
                }
            },
            {
                title: () => (
                    <div>
                        <div
                            style={{
                                marginBottom: 8
                            }}
                        >
                            <FormattedMessage id='packages.business_role' />
                        </div>
                        <div className={Styles.divGroup}>
                            <Select
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_ROLES,
                                        accesses.ROWO
                                    )
                                }
                                onChange={async (value, option) => {
                                    setBusinessRole(value);
                                    localStorage.setItem('selected_business_role', value);

                                    setDefaultRole(option.createdFromRoleId);
                                    localStorage.setItem('selected_default_role', option.createdFromRoleId);
                                }}
                                optionFilterProp='children'
                                placeholder={intl.formatMessage({
                                    id: 'packages.business_role'
                                })}
                                showSearch
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={selectedBusinessRole}
                            >
                                {additionalRoles
                                    ? additionalRoles
                                          .filter(({ createdFromRoleId }) => createdFromRoleId)
                                          .map(({ id, name, createdFromRoleId }) => (
                                              <Option
                                                  key={id}
                                                  createdFromRoleId={createdFromRoleId}
                                                  name={name}
                                                  value={id}
                                              >
                                                  {name}
                                              </Option>
                                          ))
                                    : undefined}
                            </Select>
                        </div>
                    </div>
                ),
                key: 'bis_role',
                render: row => {
                    const isInclude = row.businessRole ? row.businessRole.access : undefined;

                    const disabled =
                        row.businessPackage === null ||
                        get(row, ' businessPackage.access', []) === 'NA' ||
                        get(row, ' businessPackage.access', []) === 'AD';

                    return (
                        selectedBusinessRole && (
                            <Tooltip
                                title={
                                    isInclude ? (
                                        <FormattedMessage id={`packages.role.${isInclude}`} />
                                    ) : disabled ? (
                                        <FormattedMessage id='packages.role.NA' />
                                    ) : (
                                        'NA'
                                    )
                                }
                            >
                                <Select
                                    disabled={disabled}
                                    dropdownStyle={{
                                        width: 275
                                    }}
                                    onChange={value => {
                                        handleTableRole(selectedBusinessRole, value, [row.id]);
                                    }}
                                    optionFilterProp='children'
                                    placeholder={intl.formatMessage({
                                        id: 'packages.access'
                                    })}
                                    style={{
                                        width: '100%'
                                    }}
                                    value={!disabled ? isInclude : 'NA'}
                                >
                                    {allRolesAcees.map(elem => (
                                        <Option key={elem.value} value={elem.value}>
                                            {elem.colorValue == 'red' ? (
                                                <CloseSquareFilled className={Styles.optionsDangerIconStyle} />
                                            ) : elem.colorValue == 'orange' ? (
                                                <WarningFilled className={Styles.optionsWarningIconStyle} />
                                            ) : (
                                                <CheckCircleFilled className={Styles.optionsSuccessIconStyle} />
                                            )}{' '}
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Tooltip>
                        )
                    );
                }
            }
        ],
        [additionalRoles, defaultRole, findIcon, handleTableRole, intl, selectedBusinessRole, user]
    );

    const renderNestedTable = useCallback(
        roots => {
            const tableProps = {
                dataSource: roots,
                columns,
                expandable: {
                    childrenColumnName: 'childs',
                    expandedRowKeys,
                    onExpandedRowsChange: expandedRowKeys => setExpandedRowKeys(expandedRowKeys)
                },
                onRow: ({ id, level }) => {
                    const classNames = `${Styles[fontOptions[level]] || ''} ${
                        foundIds.includes(id) ? Styles.foundEntry : ''
                    }`.trim();
                    if (classNames.length) {
                        return {
                            className: classNames
                        };
                    }
                },
                rowKey: 'id'
            };

            return (
                <Table
                    style={{
                        overflow: 'scroll'
                    }}
                    {...tableProps}
                    bordered
                    pagination={tablePagination}
                    rowKey='id'
                />
            );
        },
        [columns, expandedRowKeys, tablePagination, foundIds]
    );

    useEffect(() => {
        const isAddedRole = additionalRoles.find(
            ({ id }) => id === Number(localStorage.getItem('selected_business_role'))
        );

        setBusinessRole(isAddedRole ? Number(localStorage.getItem('selected_business_role')) : undefined);
        setDefaultRole(Number(localStorage.getItem('selected_default_role')) || undefined);

        fetchBusinessRoles(selectedBusinessRole, defaultRole);
    }, [additionalRoles, defaultRole, fetchBusinessRoles, selectedBusinessRole]);

    useEffect(() => {
        debouncedFilterChange(filter);
    }, [flattenBusinessRoles, filter, debouncedFilterChange]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Space>
                <Radio.Group optionType='button' value={visibleLevel}>
                    {radioOptions.map(index => (
                        <Radio key={index} onClick={handleLevelVisibility} value={index}>
                            {index}
                        </Radio>
                    ))}
                </Radio.Group>
                <DebouncedInput
                    allowClear
                    placeholder={intl.formatMessage({ id: 'packages.search_by' })}
                    setState={setFilter}
                    width={300}
                />
            </Space>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end'
                }}
            >
                <Button
                    disabled={
                        !isGrantAccessed(user, grants.SETTINGS_PERMISSIONS_AND_ACCESS_BUSINESS_ROLES, accesses.ROWO)
                    }
                    onClick={() => setVisibleModal(true)}
                    type='primary'
                >
                    <FormattedMessage id='packages.own_roles' />
                </Button>
            </div>
            {renderNestedTable(businessRoles)}
            <AditionalRolesModal
                fetchAdditionalRoles={fetchAdditionalRoles}
                hideModal={() => setVisibleModal(false)}
                open={visibleModal}
                rows={additionalRoles}
                selectedBusinessRole={selectedBusinessRole}
            />
        </div>
    );
};

export default injectIntl(BusinessRolesTab);
