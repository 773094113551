import { Col, Row, Select } from 'antd';
import _ from 'lodash';
import { counterpartValues } from 'modals/EmployeeTaskModal/constants';
import {
    fetchClients,
    fetchDocuments,
    fetchEmployees,
    fetchSuppliers,
    selectFields,
    setFields
} from 'modals/EmployeeTaskModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildSupplierOptions } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    fields: selectFields(state),
    clients: state.clients.clients
    // mode: selectMode(state),
});

const mapDispatchToProps = {
    fetchClients,
    fetchEmployees,
    fetchSuppliers,
    setFields,
    fetchDocuments
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CounterpartSelector extends React.Component {
    render() {
        const {
            fields,
            clients,
            employees,
            suppliers,
            // mode,
            setFields,
            intl: { formatMessage },
            handleSearchEmployees,
            handleSearchSuppliers,
            handleSearchClient,
            counterpartBusinessSupplierId,
            counterpartBusinessSupplierName
        } = this.props;

        let counterpartSelector = null;

        // eslint-disable-next-line default-case
        switch (fields.counterpartyType) {
            case counterpartValues.CLIENT:
                counterpartSelector = (
                    <Select
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            setFields({ counterpartClientId: value });

                            this.props.setFields({
                                counterpartEmployeeId: null,
                                counterpartBusinessSupplierId: null,
                                storeDocId: null,
                                orderId: null,
                                cashOrderId: null
                            });
                            // this.props.fetchDocuments();
                        }}
                        onSearch={handleSearchClient}
                        placeholder={formatMessage({
                            id: `employee_task_modal.${fields.counterpartyType.toLowerCase()}`
                        })}
                        showSearch
                        value={fields.counterpartClientId}
                    >
                        {clients.map(client => (
                            <Option
                                key={client.clientId}
                                phone={_.get(client, 'phones[0]')}
                                value={client.clientId}
                                vehicles={_.get(client, 'vehicles')}
                            >
                                {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                    client,
                                    'phones[0]',
                                    ''
                                )}`}
                            </Option>
                        ))}
                    </Select>
                );
                break;
            case counterpartValues.EMPLOYEE:
                counterpartSelector = (
                    <Select
                        filterOption={(inputValue, option) => {
                            const name = String(option.props.name).toLowerCase();
                            const filter = inputValue.toLowerCase();

                            return name.includes(filter);
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            setFields({ counterpartEmployeeId: value });

                            this.props.setFields({
                                counterpartClientId: null,
                                counterpartBusinessSupplierId: null,
                                storeDocId: null,
                                orderId: null,
                                cashOrderId: null
                            });
                        }}
                        onSearch={input => handleSearchEmployees(input)}
                        placeholder={formatMessage({
                            id: `employee_task_modal.${fields.counterpartyType.toLowerCase()}`
                        })}
                        showSearch
                        value={fields.counterpartEmployeeId}
                    >
                        {_.map(employees, employee => {
                            return (
                                <Option key={employee.id} name={employee.name} value={employee.id}>
                                    {`${_.get(employee, 'name', '')}   ${_.get(employee, 'surname', '')}   ${
                                        employee.jobTitle
                                    }`}
                                </Option>
                            );
                        })}
                    </Select>
                );
                break;
            case counterpartValues.BUSINESS_SUPPLIER:
                counterpartSelector = (
                    <Select
                        filterOption={(inputValue, option) => {
                            const name = String(option.props.name).toLowerCase();
                            const filter = inputValue.toLowerCase();

                            return name.includes(filter);
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            setFields({ counterpartBusinessSupplierId: value });
                            this.props.setFields({
                                counterpartClientId: null,
                                counterpartEmployeeId: null,
                                storeDocId: null,
                                orderId: null,
                                cashOrderId: null
                            });
                            // this.props.fetchDocuments();
                        }}
                        onSearch={input => handleSearchSuppliers(input)}
                        placeholder={formatMessage({
                            id: `employee_task_modal.${fields.counterpartyType.toLowerCase()}`
                        })}
                        showSearch
                        value={fields.counterpartBusinessSupplierId}
                    >
                        {_.map(
                            counterpartBusinessSupplierId
                                ? buildSupplierOptions(suppliers, {
                                      id: fields.counterpartBusinessSupplierId,
                                      name: counterpartBusinessSupplierName
                                  })
                                : suppliers,
                            supplier => {
                                return (
                                    <Option key={supplier.id} name={supplier.name} value={supplier.id}>
                                        <Row>
                                            <Col span={12}>{`${supplier.name || ''}`}</Col>
                                        </Row>
                                    </Option>
                                );
                            }
                        )}
                    </Select>
                );
                break;
        }

        return <div className={Styles.mainContainer}>{counterpartSelector}</div>;
    }
}
