import { Tag } from 'antd';
import { Numeral } from 'commons';
import { ActionIcons, DatetimeFormatter } from 'commons/_uikit';
import React from 'react';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency, goTo } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';

export default props => {
    const id = {
        title: '№',
        dataIndex: 'id',
        width: 'auto',
        render: (key, { id, status }) => (
            <Link
                to={{
                    pathname: `${book.storageIncomeDoc}/${id}`,
                    state: { id, status }
                }}
            >
                {id}
            </Link>
        )
    };
    const supplierDocNumber = {
        title: props.intl.formatMessage({
            id: 'storage.document_number'
        }),
        dataIndex: 'supplierDocNumber',
        width: '20%'
    };

    const createdDatetime = {
        title: props.intl.formatMessage({
            id: 'storage.created_datetime'
        }),
        dataIndex: 'createdDatetime',
        width: '12%',
        render: datetime => <DatetimeFormatter datetime={datetime} />
    };

    const doneDatetime = {
        title: props.intl.formatMessage({
            id: 'storage.done_date'
        }),
        dataIndex: 'doneDatetime',
        width: '12%',
        render: datetime => (datetime ? <DatetimeFormatter datetime={datetime} /> : null)
    };

    const paidDatetime = {
        title: props.intl.formatMessage({
            id: 'storage.payment_date'
        }),
        dataIndex: 'paidDatetime',
        width: '12%',
        render: datetime => (datetime ? <DatetimeFormatter datetime={datetime} /> : null)
    };

    const status = {
        title: props.intl.formatMessage({
            id: 'storage.status'
        }),
        dataIndex: 'status',
        width: '4%',
        render: status => (
            <Tag color={status === 'NEW' ? 'var(--not_complete)' : 'var(--green)'}>
                {props.intl.formatMessage({ id: `storage.status.${status}` })}
            </Tag>
        )
    };

    const supplier = {
        title: props.intl.formatMessage({
            id: 'storage.supplier'
        }),
        dataIndex: 'businessSupplierId',
        width: '15%',
        render: (supplier, { businessSupplier }) => <div>{businessSupplier ? businessSupplier.name : null}</div>
    };

    const manager = {
        title: props.intl.formatMessage({
            id: 'storage.responsible'
        }),
        dataIndex: 'manager',
        width: '15%',
        render: manager =>
            manager && isGrantAccessed(props.user, grants.DIRECTORIES_EMPLOYEES_LIST) ? (
                <Link to={`${book.employeesPage}/${manager.employeeId}`}>
                    {manager.name} {manager.surname}
                </Link>
            ) : (
                <div>
                    {manager.name} {manager.surname}
                </div>
            )
    };

    const sum = {
        title: props.intl.formatMessage({
            id: 'storage.sum'
        }),
        dataIndex: 'sum',
        width: '15%',
        render: sum => <Numeral currency={getCurrency()}>{sum}</Numeral>
    };

    const actions = {
        width: 'auto',
        dataIndex: 'delete',
        render: (key, { id }) => {
            return (
                <ActionIcons
                    delete={() => props.deleteIncomeDoc(id)}
                    edit={() => goTo(`${book.storageIncomeDoc}/${id}`, { id })}
                />
            );
        }
    };

    return [
        id,
        supplierDocNumber,
        createdDatetime,
        doneDatetime,
        paidDatetime,
        status,
        supplier,
        manager,
        sum,
        actions
    ];
};
