import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, List, Tabs } from 'antd';
import classNames from 'classnames/bind';
import { Loader } from 'commons';
import { EmployeeFeedback, EmployeeStatistics } from 'components';
import { fetchEmployees } from 'core/employees/duck';
import {
    fetchEmployeeById,
    fireEmployee,
    resetEmployeeForm,
    saveEmployee,
    selectInitialEmployee
} from 'core/forms/employeeForm/duck';
import dayjs from 'dayjs';
import { EmployeeForm, EmployeeScheduleForm, SettingSalaryForm } from 'forms';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { Layout } from 'tireFitting';
import { isForbidden, linkTo, permissions } from 'utils';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

const cx = classNames.bind(Styles);

const mapStateToProps = state => ({
    employees: state.employees.employees,
    employeeName: state.forms.employeeForm.employeeName,
    initialEmployee: selectInitialEmployee(state),
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    saveEmployee,
    fetchEmployees,
    fetchEmployeeById,
    resetEmployeeForm,
    fireEmployee
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class EditEmployeePage extends Component {
    componentDidMount() {
        this.props.fetchEmployeeById(this.props.match.params.id);
        this.props.fetchEmployees();
    }

    fireEmployee = () => {
        const { form } = this.employeeFormRef.props;
        const employeesData = form.getFieldsValue();
        const { id } = this.props.match.params;
        this.props.fireEmployee(employeesData, id, dayjs());
    };

    saveEmployeeFormRef = formRef => {
        this.employeeFormRef = formRef;
    };

    saveEmployee = () => {
        const { form } = this.employeeFormRef.props;
        form.validateFields((err, values) => {
            if (!err) {
                const { id } = this.props.match.params;
                this.props.saveEmployee(values, id);
            }
        });
    };

    _linkToEmployee = id => {
        linkTo(`${book.employeesPage}/${id}`);
        this.props.fetchEmployeeById(id);
        this.props.fetchEmployees();
    };

    render() {
        const { isMobile } = this.props;
        const employeeTabs = this._renderEmployeeTabs();
        const employeesList = this._renderEmployeesList();

        return this.props.initialEmployee ? (
            <Layout
                controls={
                    <Link to={book.employeesPage}>
                        <Button type='default'>
                            <ArrowLeftOutlined />
                            <FormattedMessage id='back-to-list' />
                        </Button>
                    </Link>
                }
                paper
                title={this.props.employeeName}
            >
                <div className={Styles.employeePage}>
                    <section
                        className={`${Styles.employeeSection} ${Styles.employeeTabs} ${
                            isMobile && Styles.employeeMobileSection
                        }`}
                    >
                        {employeeTabs}
                    </section>
                    {!isMobile && (
                        <section className={`${Styles.employeeSection} ${Styles.employeesList}`}>
                            {employeesList}
                        </section>
                    )}
                </div>
            </Layout>
        ) : (
            <Loader loading={!this.props.initialEmployee} />
        );
    }

    _renderEmployeeTabs = () => {
        const { user, initialEmployee } = this.props;
        const employeeId = this.props.match.params.id;

        return (
            <Tabs>
                <TabPane
                    key='1'
                    tab={this.props.intl.formatMessage({
                        id: 'employee.general_data'
                    })}
                >
                    <EmployeeForm
                        fireEmployee={this.fireEmployee}
                        initialEmployee={initialEmployee}
                        saveEmployee={this.saveEmployee}
                        user={user}
                        wrappedComponentRef={this.saveEmployeeFormRef}
                    />
                </TabPane>
                <TabPane
                    key='2'
                    tab={this.props.intl.formatMessage({
                        id: 'add-employee-page.schedule'
                    })}
                >
                    <EmployeeScheduleForm employeeId={employeeId} />
                </TabPane>
                <TabPane
                    key='3'
                    tab={this.props.intl.formatMessage({
                        id: 'employee-page.statistics'
                    })}
                >
                    <EmployeeStatistics
                        labourHours={_.get(initialEmployee, 'labourHours')}
                        ordersCount={_.get(initialEmployee, 'ordersCount')}
                    />
                </TabPane>
                <TabPane
                    key='4'
                    tab={this.props.intl.formatMessage({
                        id: 'employee-page.feedback'
                    })}
                >
                    <EmployeeFeedback reviews={_.get(initialEmployee, 'reviews')} />
                </TabPane>
                <TabPane
                    key='5'
                    disabled={isForbidden(this.props.user, permissions.EMPLOYEES_SALARIES)}
                    tab={this.props.intl.formatMessage({
                        id: 'employee-page.setting_salary'
                    })}
                >
                    <SettingSalaryForm employeeId={employeeId} />
                </TabPane>
            </Tabs>
        );
    };

    _renderEmployeesList = () => {
        const {
            employees,
            loading,
            history,
            intl: { formatMessage }
        } = this.props;
        const currentEmployeeId = Number(history.location.pathname.split('/')[2]);

        const _listItemStyles = id =>
            cx({
                listItem: true,
                listItemActive: currentEmployeeId === id
            });

        return employees ? (
            <List
                bordered
                className={Styles.switchBusinessList}
                dataSource={employees}
                loading={loading}
                locale={{ emptyText: formatMessage({ id: 'no_data' }) }}
                renderItem={item => (
                    <List.Item
                        className={_listItemStyles(item.id)}
                        onClick={() => this._linkToEmployee(item.id)}
                    >
                        <List.Item.Meta
                            className={Styles.employeeListItem}
                            description={item.jobTitle}
                            title={`${item.name} ${item.surname}`}
                        />
                    </List.Item>
                )}
            />
        ) : (
            <Loader loading={!employees} />
        );
    };
}
