import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildSupplierOptions, getCurrency } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const commentLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 20
    }
};

const counterpartyTypeMap = {
    CLIENTS: 'interactions.counterparty_type.CLIENT',
    EMPLOYEES: 'interactions.counterparty_type.EMPLOYEE',
    SUPPLIERS: 'interactions.counterparty_type.SUPPLIER'
};

const operationTypes = {
    DIRECT: 'entries_tab.DIRECT',
    STORNO: 'entries_tab.STORNO'
};

const dateFormat = 'DD.MM.YYYY';

const AccountingForm = ({
    id,
    docData,
    previousData,
    intl: { formatMessage },
    setData,
    employees,
    suppliers,
    clients,
    form,
    warehouses,
    cashboxes,
    fetchSuppliers,
    fetchEmployees,
    fetchClients,
    fetchBusinessRequisites,
    businessRequisites,
    isMobile,
    handleSearchCounterparty
}) => {
    const [comment, setComment] = useState(' ');
    const { counterpartType } = docData;

    const setValue = useCallback(
        () =>
            form.setFieldsValue({
                documentNumber: docData.documentNumber || undefined,
                dateTime: dayjs(docData.dateTime) || dayjs(),
                documentDatetime: dayjs(docData.documentDatetime) || dayjs(),
                counterpartId: docData.counterpartId || undefined,
                counterpartType: docData.counterpartType || undefined,
                warehouseId: docData.warehouseId || null,
                cashBoxId: docData.cashBoxId || null,
                comment: docData.comment || ' ',
                responsibleId: docData.responsibleId || undefined,
                operationType: docData.operationType || 'DIRECT',
                businessRequisiteId: docData.businessRequisiteId || undefined
            }),

        [docData, form]
    );

    const fetchCounterparty = useCallback(
        value => {
            if (value === 'SUPPLIERS' && !_.get(suppliers, 'length')) {
                fetchSuppliers();
            }
            if (value === 'CLIENTS' && !_.get(clients, 'length')) {
                fetchClients();
            }
            if (value === 'EMPLOYEES' && !_.get(employees, 'length')) {
                fetchEmployees();
            }
        },
        [docData]
    );

    useEffect(() => {
        if (!id) return;
        setValue();
    }, [docData]);

    useEffect(() => {
        fetchCounterparty(docData.counterpartType);
    }, [docData]);

    useEffect(() => {
        if (docData) {
            const name = docData.counterpartName && docData.counterpartName.split(' ');

            if (docData.counterpartType === 'CLIENTS' && name !== null) {
                fetchClients(get(name, '[0]'));
            }
        }
    }, [docData]);

    const normalizeFields = useCallback(
        allFields =>
            Object.fromEntries(allFields.filter(({ touched }) => touched).map(field => [field.name[0], field.value])),
        []
    );

    const disabled = docData ? docData.status === 'DONE' || docData.status === 'CANCEL' : undefined;

    return (
        <React.Fragment>
            <Form
                form={form}
                initialValues={{
                    counterpartType: docData.counterpartType || 'CLIENT'
                }}
                labelAlign='left'
                labelWrap
                layout='vertical'
                name='addInteraction'
                onFieldsChange={(_, allFields) => {
                    setData({ ...docData, ...normalizeFields(allFields) });
                }}
            >
                <Row gutter={24} style={isMobile ? { marginTop: '20px' } : {}}>
                    <Col span={!isMobile ? 16 : 24}>
                        <Row gutter={24}>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    label={formatMessage({ id: 'interactions.counterparty_type' })}
                                    name='counterpartType'
                                >
                                    <Select
                                        disabled={disabled}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onSelect={counterpartType => {
                                            form.setFieldsValue({
                                                counterpartId: undefined,
                                                counterpartySearch: undefined
                                            });
                                            fetchCounterparty(counterpartType);

                                            setData({
                                                ...docData,
                                                counterpartType,
                                                counterpartId: undefined,
                                                counterpartySearch: undefined
                                            });
                                        }}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                    >
                                        {Object.entries(counterpartyTypeMap).map(([key, value]) => (
                                            <Option key={key} value={key}>
                                                <FormattedMessage id={value} />
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    field='counterpartId'
                                    label={formatMessage({
                                        id: 'entries_tab.CONTRAGENT'
                                    })}
                                    name='counterpartId'
                                >
                                    <Select
                                        disabled={disabled || !id}
                                        onSearch={input => handleSearchCounterparty(docData.counterpartType, input)}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 6, width: '100%' }}
                                    >
                                        {counterpartType === 'SUPPLIERS'
                                            ? buildSupplierOptions(suppliers, {
                                                  id: docData.counterpartId,
                                                  name: docData.counterpartName
                                              }).map(supplier => (
                                                  <Option
                                                      key={supplier.businesssupplierid}
                                                      value={supplier.businesssupplierid}
                                                  >
                                                      {`${supplier.businessSupplierName}`}
                                                  </Option>
                                              ))
                                            : counterpartType === 'EMPLOYEES'
                                            ? employees.map(employee => (
                                                  <Option key={employee.id} value={employee.id}>
                                                      {`${employee.name} ${employee.surname}`}
                                                  </Option>
                                              ))
                                            : counterpartType === 'CLIENTS'
                                            ? clients.map(client => (
                                                  <Option
                                                      key={client.clientId}
                                                      phone={_.get(client, 'phones[0]')}
                                                      value={client.clientId}
                                                      vehicles={_.get(client, 'vehicles')}
                                                  >
                                                      {`${client.surname || ''} ${client.name} ${
                                                          client.middleName || ''
                                                      } ${_.get(client, 'phones[0]', '')}`}
                                                  </Option>
                                              ))
                                            : []}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    label={formatMessage({ id: 'entry_table.doc_number' })}
                                    name='documentNumber'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Input
                                        allowClear
                                        disabled={disabled}
                                        placeholder={formatMessage({
                                            id: 'entry_table.doc_number'
                                        })}
                                        style={{
                                            width: '100%'
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    label={formatMessage({ id: 'storage.business_requisites' })}
                                    name='businessRequisiteId'
                                >
                                    <Select
                                        allowClear
                                        disabled={disabled}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'storage.business_requisites'
                                        })}
                                        showSearch
                                        style={{ color: 'var(--text)', width: '100%' }}
                                    >
                                        {businessRequisites
                                            .filter(({ used }) => used)
                                            .map(elem => (
                                                <Select.Option key={elem.id} value={elem.id}>
                                                    {elem.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    label={formatMessage({ id: 'acc_table_page.acc_date' })}
                                    name='dateTime'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <DatePicker
                                        disabled
                                        format={dateFormat}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        placeholder={formatMessage({
                                            id: 'select_date'
                                        })}
                                        style={{
                                            width: '100%'
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    label={formatMessage({ id: 'navigation.storage' })}
                                    name='warehouseId'
                                >
                                    <Select
                                        allowClear
                                        disabled={disabled || !id}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 6, width: '100%' }}
                                    >
                                        {warehouses.map(elem => (
                                            <Option key={elem.id} value={elem.id}>
                                                {elem.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    field='cashBoxId'
                                    label={formatMessage({ id: 'employee_settings.cashbox' })}
                                    name='cashBoxId'
                                >
                                    <Select
                                        allowClear
                                        disabled={disabled}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 6, width: '100%' }}
                                    >
                                        {cashboxes
                                            ? cashboxes
                                                  .filter(({ active }) => active)
                                                  .map(({ name, id }) => (
                                                      <Option key={id} value={id}>
                                                          {name}
                                                      </Option>
                                                  ))
                                            : []}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    label={formatMessage({ id: 'entry_table.operation_type' })}
                                    name='operationType'
                                >
                                    <Select
                                        disabled={disabled}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onSelect={operationType => {
                                            setData({
                                                ...docData,
                                                operationType
                                            });
                                        }}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                    >
                                        {Object.entries(operationTypes).map(([key, value]) => (
                                            <Option key={key} value={key}>
                                                <FormattedMessage id={value} />
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item
                                    disabled={disabled}
                                    label={formatMessage({ id: 'debt_move_page.document_date' })}
                                    name='documentDatetime'
                                >
                                    <DatePicker
                                        allowClear={false}
                                        disabled={disabled}
                                        format={dateFormat}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        placeholder={formatMessage({
                                            id: 'select_date'
                                        })}
                                        style={{
                                            width: '100%'
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={!isMobile ? 6 : 24}>
                                <Form.Item label={formatMessage({ id: 'responsible' })}>
                                    <Select disabled value={docData.managerName}>
                                        <Option>{docData.managerName}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={!isMobile ? 4 : 24}>
                        <Row className={Styles.center}>
                            <span></span>
                        </Row>
                    </Col>
                    <Col span={!isMobile ? 4 : 24}>
                        <Row className={Styles.totalSum}>
                            <FormattedMessage id='acc_page.sum_all_credits' />:
                            <span
                                style={{
                                    marginLeft: 6
                                }}
                            >
                                <Numeral mask='0,0.00'>{Number(docData.sumOfCredit || 0)}</Numeral>
                                {getCurrency()}
                            </span>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label={formatMessage({ id: 'comment' })} name='comment'>
                            <TextArea
                                disabled={docData.status === 'DONE'}
                                onChange={event => {
                                    setComment(event.target.value);
                                }}
                                placeholder={`${formatMessage({ id: 'comment' })}...`}
                                value={comment}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

export default connect(mapStateToProps)(injectIntl(AccountingForm));
