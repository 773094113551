import { Modal, Select, Table } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const { Option } = Select;

@injectIntl
export default class LocationHistoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            locationId: undefined,
            dataSource: [],
            loading: false,
            currentPage: 1,
            fromDatetime: dayjs().startOf('month'),
            toDatetime: dayjs()
        };

        this.columns = [
            {
                key: 'key',
                dataIndex: 'key',
                width: 'min-content',
                render: (data, row) => {
                    return data + 1;
                }
            },
            {
                title: <FormattedMessage id='locations.number' />,
                key: 'number',
                dataIndex: 'clientsVehicle',
                render: ({ number }, row) => {
                    return number || <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='locations.vehicle' />,
                key: 'vehicle',
                dataIndex: 'clientsVehicle',
                render: ({ make, model, modification, year }, row) => {
                    return `${make} ${model} ${modification} (${year})`;
                }
            },
            {
                title: <FormattedMessage id='location' />,
                key: 'location',
                dataIndex: 'businessLocation',
                render: ({ name }, row) => {
                    return name;
                }
            },
            {
                title: <FormattedMessage id='locations.arrival' />,
                key: 'arrival',
                dataIndex: 'incomeDatetime',
                render: (data, row) => {
                    return data ? (
                        dayjs(data).format('DD.MM.YYYY HH:mm')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='locations.departure' />,
                key: 'departure',
                dataIndex: 'expenseDatetime',
                render: (data, row) => {
                    return data ? (
                        dayjs(data).format('DD.MM.YYYY HH:mm')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='locations.duration' />,
                key: 'duration',
                dataIndex: 'duration',
                render: (data, row) => {
                    const days = Math.floor(data / 24);
                    const hours = Math.floor(data % 24);

                    return (
                        <div>
                            {days ? (
                                <span>
                                    {days} <FormattedMessage id='locations.days' />
                                </span>
                            ) : null}{' '}
                            {hours} <FormattedMessage id='locations.hours' />
                        </div>
                    );
                }
            }
        ];
    }

    getLocations() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/business_locations`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((elem, key) => {
                    elem.key = key;
                });
                that.setState({
                    locations: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    fetchData(id, page = 1) {
        const { locationId, fromDatetime, toDatetime } = this.state;
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = `${__API_URL__}/business_locations/movements?fromDatetime=${fromDatetime.format(
            'YYYY-MM-DD'
        )}&toDatetime=${toDatetime.format('YYYY-MM-DD')}&page=${page}&pageSize=10`;
        if (id || locationId) url += `&businessLocationId=${id || locationId}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.list.map((elem, key) => {
                    elem.key = key;
                });
                that.setState({
                    paginationTotal: Number(data.stats.count),
                    dataSource: data.list
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    handleCancel = () => {
        this.setState({
            locationId: undefined,
            dataSource: [],
            loading: false,
            currentPage: 1,
            fromDatetime: dayjs().startOf('month'),
            toDatetime: dayjs()
        });
        this.props.hideModal();
    };

    componentDidMount() {
        this.getLocations();
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.setState({
                locationId: this.props.selectedLocation
            });
            this.fetchData(this.props.selectedLocation);
        }
    }

    render() {
        const {
            loading,
            dataSource,
            locations,
            locationId,
            currentPage,
            paginationTotal,
            fromDatetime,
            toDatetime
        } = this.state;
        const { visible, hideModal } = this.props;

        const pagination = {
            total: paginationTotal,
            current: currentPage,
            onChange: page => {
                this.setState({
                    currentPage: page
                });
                this.fetchData(locationId, page);
            }
        };

        return (
            <Modal
                maskClosable={false}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='locations.vehicles_location_history' />}
                visible={visible}
                width='80%'
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                    <div>
                        <Select
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            onChange={value => {
                                this.setState({
                                    locationId: value,
                                    currentPage: 1
                                });
                                this.fetchData(value);
                            }}
                            placeholder={this.props.intl.formatMessage({ id: 'location' })}
                            showSearch
                            style={{ width: 220 }}
                            value={locationId}
                        >
                            {locations.map(({ id, name }, key) => (
                                <Option key={key} value={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <DateRangePicker
                        dateRange={[fromDatetime, toDatetime]}
                        onDateChange={async ([fromDatetime, toDatetime]) => {
                            await this.setState({
                                fromDatetime,
                                toDatetime
                            });
                            this.fetchData();
                        }}
                    />
                </div>
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={pagination}
                    size='small'
                />
            </Modal>
        );
    }
}
