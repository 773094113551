/* eslint-disable id-denylist */
/* eslint-disable import/extensions */
// Exceptions
import cb from 'theme/images/CB.png';
import carbookLogoBlack from 'theme/images/carbookLogo.png';
import carbookLogo2 from 'theme/images/carbookLogo2.png';
import carbookLogo1 from 'theme/images/logoCarbook.png';
// Colored Icons
import airFilterIconColored from 'theme/images/coloredIcons/air-filter.svg';
import calendarIconColored from 'theme/images/coloredIcons/calendar.svg';
import notesIconColored from 'theme/images/coloredIcons/notes.svg';
import oilIconColored from 'theme/images/coloredIcons/oil.png';
import otherIconColored from 'theme/images/coloredIcons/other.svg';
import paymentIconColored from 'theme/images/coloredIcons/payment.svg';
import rimsIconColored from 'theme/images/coloredIcons/rims.svg';
import toolsIconColored from 'theme/images/coloredIcons/tools.svg';
import userIconColored from 'theme/images/coloredIcons/user.svg';
import exception400 from 'theme/images/exceptions/400.svg';
import exception404 from 'theme/images/exceptions/404.svg';
import exception500 from 'theme/images/exceptions/500.svg';
import favicon from 'theme/images/favicon.png';
// Funel
import funelArrowCurvedLeft from 'theme/images/funel/funel_arrow-curved-left.svg';
import funelArrowCurvedRight from 'theme/images/funel/funel_arrow-curved-right.svg';
// Icons
import bookIcon from 'theme/images/icons/book.svg';
import brakeIcon from 'theme/images/icons/brake.svg';
import carPartsIcon from 'theme/images/icons/car-parts.svg';
import cashboxIcon from 'theme/images/icons/cashbox.svg';
import checkIcon from 'theme/images/icons/check.svg';
import complexesIcon from 'theme/images/icons/complexes.svg';
import craneIcon from 'theme/images/icons/crane.svg';
import crossReferenceIcon from 'theme/images/icons/cross-reference.svg';
import deliveryTruckIcon from 'theme/images/icons/delivery-truck.svg';
import features from 'theme/images/icons/features.svg';
import gridIcon from 'theme/images/icons/grid.svg';
import listIcon from 'theme/images/icons/list.svg';
import closedLockIcon from 'theme/images/icons/lock-closed.svg';
import openLockIcon from 'theme/images/icons/lock-open.svg';
import mechanicIcon from 'theme/images/icons/mechanic.svg';
import oilIcon from 'theme/images/icons/oil.svg';
import partsIcon from 'theme/images/icons/parts.svg';
import pencilIcon from 'theme/images/icons/pencil.svg';
import pistonIcon from 'theme/images/icons/piston.svg';
import priceTagIcon from 'theme/images/icons/price-tag.svg';
import reportIcon from 'theme/images/icons/report.svg';
import stockIcon from 'theme/images/icons/stock.svg';
import wrenchIcon from 'theme/images/icons/wrench.svg';
import carbookLogoWhite from 'theme/images/logoCarbook.png';
// Logos
import carbookLogo from 'theme/images/logoCarbookPrimary.png';
import beruLogo from 'theme/images/logos/beruLogo.png';
import facebook from 'theme/images/logos/facebook.png';
import instagram from 'theme/images/logos/instagram.png';
import telegram from 'theme/images/logos/telegram.png';
import youtube from 'theme/images/logos/youtube.png';
// partners logos
import castrolLogo from 'theme/images/logos/castrolLogo.png';
import championLogo from 'theme/images/logos/championLogo.png';
import ecatLogo from 'theme/images/logos/ecatLogo.png';
import fagLogo from 'theme/images/logos/fagLogo.png';
import ferodoLogo from 'theme/images/logos/ferodoLogo.png';
import gatesLogo from 'theme/images/logos/gatesLogo.png';
import goetzeLogo from 'theme/images/logos/goetzeLogo.png';
import inaLogo from 'theme/images/logos/inaLogo.png';
import kybLogo from 'theme/images/logos/kybLogo.png';
import lemfoerderLogo from 'theme/images/logos/lemfoerderLogo.png';
import lukLogo from 'theme/images/logos/lukLogo.png';
import moogLogo from 'theme/images/logos/moogLogo.png';
import portmoneLogos from 'theme/images/logos/portmoneLogos.svg';
import remsaLogo from 'theme/images/logos/remsaLogo.png';
import roadhouseLogo from 'theme/images/logos/roadhouseLogo.png';
import sachsLogo from 'theme/images/logos/sachsLogo.png';
import sidemLogo from 'theme/images/logos/sidemLogo.png';
import trwLogo from 'theme/images/logos/trwLogo.png';
import vehicleSchemeBack from 'theme/images/vehicleScheme/back.png';
// Vehicle Scheme
import vehicleSchemeFront from 'theme/images/vehicleScheme/front.png';
import vehicleSchemeSide from 'theme/images/vehicleScheme/side.png';

export default {
    // Exceptions
    exception400,
    exception404,
    exception500,

    // Logos
    carbookLogo,
    carbookLogo2,
    carbookLogo1,
    carbookLogoBlack,
    carbookLogoWhite,
    castrolLogo,
    portmoneLogos,
    ecatLogo,
    kybLogo,
    sidemLogo,
    lukLogo,
    inaLogo,
    fagLogo,
    gatesLogo,
    beruLogo,
    championLogo,
    ferodoLogo,
    goetzeLogo,
    lemfoerderLogo,
    roadhouseLogo,
    moogLogo,
    remsaLogo,
    sachsLogo,
    trwLogo,

    // Funel
    funelArrowCurvedLeft,
    funelArrowCurvedRight,

    // Vehicle Scheme
    vehicleSchemeFront,
    vehicleSchemeBack,
    vehicleSchemeSide,

    // Icons
    bookIcon,
    brakeIcon,
    carPartsIcon,
    checkIcon,
    craneIcon,
    crossReferenceIcon,
    mechanicIcon,
    partsIcon,
    pencilIcon,
    stockIcon,
    wrenchIcon,
    pistonIcon,
    deliveryTruckIcon,
    oilIcon,
    gridIcon,
    listIcon,
    complexesIcon,
    cashboxIcon,
    openLockIcon,
    closedLockIcon,
    reportIcon,
    priceTagIcon,
    favicon,
    cb,
    features,

    // Colored Icons
    airFilterIconColored,
    calendarIconColored,
    notesIconColored,
    paymentIconColored,
    toolsIconColored,
    userIconColored,
    otherIconColored,
    oilIconColored,
    rimsIconColored,

    telegram,
    instagram,
    facebook,
    youtube
};
