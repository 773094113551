import { Button } from 'antd';
import { Layout } from 'commons';
import { ImportExportTable } from 'components';
import { SyncImportExportModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@connect(mapStateToProps, void 0)
export default class SyncExportPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            tableData: [],
            intervalId: undefined
        };
    }

    fetchTable = () => {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/sync/history?type=EXPORT`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.setState({
                    tableData: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    componentDidMount() {
        this.fetchTable();
        // const intervalId = setInterval(this.fetchTable, 5000);
        // this.setState({ intervalId });
    }

    componentWillUnmount() {
        // clearInterval(this.state.intervalId);
    }

    render() {
        const { user } = this.props;
        const { modalVisible, tableData } = this.state;

        return (
            <Layout
                controls={
                    <Button
                        onClick={() => {
                            this.setState({
                                modalVisible: true
                            });
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='export_import_pages.export_data' />
                    </Button>
                }
                title={<FormattedMessage id='navigation.sync_export' />}
            >
                <ImportExportTable fetchTable={this.fetchTable} tableData={tableData} type='EXPORT' />
                <SyncImportExportModal
                    hideModal={() => {
                        this.setState({
                            modalVisible: false
                        });
                        this.fetchTable();
                    }}
                    tableData={tableData}
                    type='EXPORT'
                    visible={modalVisible}
                />
            </Layout>
        );
    }
}
