import { CloseOutlined, SaveOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Space, notification } from 'antd';
import { Layout } from 'commons';
import InteractionForm from 'forms/InteractionForm';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { InteractionRowsTable } from 'components';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import { AddClientModal } from 'modals';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modal: state.modals.modal,
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

const AddInteractionPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ intl: { formatMessage }, setModal, resetModal, modal, user, isMobile }) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [docData, setDocData] = useState({
        counterpartyType: 'CLIENT'
    });
    const [addClientVisible, setAddClientVisible] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [clients, setClients] = useState([]);
    const [sources, setSources] = useState([]);
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [stations, setStations] = useState([]);
    const [stationsSearch, setStationsSearch] = useState([]);

    const openAddClient = () => {
        setModal(MODALS.ADD_CLIENT, { initialPhoneNumber: docData.counterpartySearch });
    };
    const onClientAdded = async value => {
        const { clients } = await fetchAPI('GET', '/clients', null, null, {
            handleErrorInternally: true
        });

        setClients(clients);

        const client = clients.find(({ clientId }) => clientId == value) || {};
        form.setFieldsValue({
            counterpartyId: value,
            sourceId: client.sourceId
        });
        setDocData({
            ...docData,
            client: `${client.name} ${client.surname || ''} ${_.get(client.phones, '[0]', ' ')}`,
            counterpartyId: value,
            sourceId: client.sourceId
        });
    };

    const fetchDocData = useCallback(async () => {
        if (id) {
            const response = await fetchAPI('GET', `/crm/interactions_doc/${id}`, null, null, {
                handleErrorInternally: true
            });

            setDocData(response);
            setTableData(response.documentRows);
        }
    }, [id]);

    const fetchStationsSearch = useCallback(async query => {
        const response = await fetchAPI('GET', '/businesses/search', { search: query }, undefined, {
            handleErrorInternally: true
        });
        setStationsSearch(response);
    }, []);

    const fetchStations = useCallback(async () => {
        const response = await fetchAPI('GET', 'managers/businesses/get', { pageSize: 100 }, undefined, {
            handleErrorInternally: true
        });
        setStations(response);
    }, []);

    const fetchEmployees = useCallback(async query => {
        const responseEmps = await fetchAPI('GET', 'employees', { query }, null, {
            handleErrorInternally: true
        });

        setEmployees(responseEmps);

        return responseEmps
            .filter(({ disabled }) => !disabled)
            .map(({ id, name, surname }) => ({ label: `${name} ${surname}`, value: id }));
    }, []);

    const fetchClients = useCallback(async query => {
        const responseClients = await fetchAPI('GET', '/clients', { filters: { query } }, null, {
            handleErrorInternally: true
        });

        setClients(responseClients.clients);

        return responseClients.clients.map(({ clientId, name, surname, phones }) => ({
            label: `${name} ${surname || ''} ${_.get(phones, '[0]', ' ')}`,
            value: clientId
        }));
    }, []);

    const fetchSuppliers = useCallback(async query => {
        const responseSuppliers = await fetchAPI('GET', '/business_suppliers_dept', { filters: { query } }, null, {
            handleErrorInternally: true
        });

        return responseSuppliers.businessSuppliers.map(({ businesssupplierid, businessSupplierName }) => ({
            label: businessSupplierName,
            value: businesssupplierid
        }));
    }, []);
    const fetchSources = useCallback(async () => {
        const responseSorces = await fetchAPI('GET', 'business/acquiring/sources', { pageSize: 50 }, null, {
            handleErrorInternally: true
        });

        setSources(responseSorces.query);
    }, []);

    const fetchTypes = useCallback(async () => {
        const responseTypes = await fetchAPI('GET', '/crm/interations_type', null, null, {
            handleErrorInternally: true
        });

        setTypes(responseTypes);
    }, []);

    const fetchCategories = useCallback(async () => {
        const responseCategories = await fetchAPI('GET', '/crm/interations_categories', null, null, {
            handleErrorInternally: true
        });

        setCategories(responseCategories);
    }, []);

    const fetchStatuses = async () => {
        const response = await fetchAPI('GET', '/crm/status', null, null, { handleErrorInternally: true });
        setStatuses(response);
    };

    const debouncedFilter = (query, type) => {
        const funcMap = {
            CLIENT: fetchClients,
            EMPLOYEE: fetchEmployees,
            SUPPLIER: fetchSuppliers
        };

        return funcMap[type](query);
    };

    const handleFinish = useCallback(async () => {
        const {
            sourceId,
            counterpartyId,
            comment,
            counterpartyType,
            nextDate,
            interactionTypeId,
            interactionCategoryId,
            responsibleId,
            stationId
        } = docData;

        const submitValues = {
            docType: 'IAC',
            statusId: 1,
            sourceId: sourceId || undefined,
            counterpartyId: get(counterpartyId, 'value', counterpartyId),
            responsibleId: responsibleId || undefined,
            comment,
            counterpartyType,
            nextDate: nextDate ? dayjs(nextDate).format('YYYY-MM-DD') : undefined,
            interactionTypeId: interactionTypeId || undefined,
            interactionCategoryId: interactionCategoryId || undefined,
            stationId: stationId || undefined
        };
        const validation = await form.validateFields();
        if (!validation.errorFields) {
            try {
                const response = await fetchAPI('POST', '/crm/interactions_doc', null, submitValues, {
                    handleErrorInternally: true
                });
                notification.success({ message: formatMessage({ id: 'barcode.success' }) });

                goTo(`${book.interactions}/${_.get(response, '[0].iac_id_pk')}`);
            } catch (e) {
                notification.error({ message: formatMessage({ id: 'error' }) });
            }
        }
    }, [docData, formatMessage]);

    const handleSave = useCallback(
        async status => {
            const {
                sourceId,
                counterpartyId,
                comment,
                nextDate,
                statusId,
                interactionTypeId,
                interactionCategoryId,
                responsibleId,
                stationId
            } = docData;
            const submitValues = {
                id,
                statusId: status || statusId || 1,
                counterpartyId,
                sourceId: sourceId || undefined,
                comment: comment || ' ',
                nextDate: nextDate ? dayjs(nextDate).format('YYYY-MM-DD') : undefined,
                interactionTypeId: interactionTypeId || undefined,
                interactionCategoryId: interactionCategoryId || undefined,
                responsibleId,
                stationId: stationId || undefined
            };
            const validation = await form.validateFields();
            if (!validation.errorFields) {
                try {
                    await fetchAPI('PUT', '/crm/interactions_doc', null, submitValues, {
                        handleErrorInternally: true
                    });
                    notification.success({ message: formatMessage({ id: 'barcode.success' }) });

                    window.location.reload();
                } catch (e) {
                    notification.error({ message: formatMessage({ id: 'error' }) });
                }
            }
        },
        [docData, formatMessage, id]
    );

    const changeStatus = statusId => {
        handleSave(statusId);
    };

    const debounceStations = useMemo(() => {
        const loadOptions = value => {
            fetchStationsSearch(value);
        };

        return _.debounce(loadOptions, 500);
    }, []);

    useEffect(() => {
        fetchDocData();
        // fetchEmployees();
        // fetchClients();
        // fetchSuppliers();
        fetchSources();
        fetchStatuses();
        fetchTypes();
        fetchCategories();
        fetchEmployees();
        if (get(user, 'businessId') !== 3333) {
            fetchStations();
        }
        if (get(user, 'businessId') === 3333) {
            fetchStationsSearch();
        }
    }, [id]);

    const items = statuses
        .filter(({ id, enabled }) => id !== docData.statusId && enabled)
        .map(({ name, id }) => ({
            key: id,
            label: <div onClick={() => changeStatus(id)}>{name}</div>
        }));

    return (
        <Layout
            className='addInteractionsPage'
            controls={
                id ? (
                    <Space size='small'>
                        <div className={Styles.headerActionBtns}>
                            <Dropdown menu={{ items }}>
                                <Button
                                    icon={
                                        <SwapOutlined
                                            style={{
                                                fontSize: 24
                                            }}
                                        />
                                    }
                                    type='text'
                                >
                                    {!isMobile && 
                                        <span style={{ verticalAlign: 'text-bottom' }}>
                                            <FormattedMessage id='change_status_dropdown.change_status' />
                                        </span>
                                    }
                                </Button>
                            </Dropdown>
                        </div>
                        {/* <div className={Styles.headerActionBtns}>
                            <Button
                                disabled
                                icon={
                                    <PrinterOutlined
                                        style={{
                                            fontSize: 24
                                        }}
                                    />
                                }
                                onClick={() => {}}
                                type='text'
                            />
                        </div> */}
                        <div className={Styles.headerActionBtns}>
                            <Button
                                icon={
                                    <SaveOutlined
                                        style={{
                                            fontSize: 24
                                        }}
                                    />
                                }
                                onClick={() => handleSave()}
                                type='text'
                            />
                        </div>
                        <div className={Styles.headerActionBtns}>
                            <Button
                                icon={
                                    <CloseOutlined
                                        style={{
                                            fontSize: 24
                                        }}
                                    />
                                }
                                onClick={async () => {
                                    goTo(book.interactions);
                                }}
                                type='text'
                            />
                        </div>
                    </Space>
                ) : (
                    <Space size='small'>
                        <div className={Styles.headerActionBtns}>
                            <Button
                                icon={
                                    <SaveOutlined
                                        style={{
                                            fontSize: 24
                                        }}
                                    />
                                }
                                onClick={handleFinish}
                                type='text'
                            />
                        </div>
                        <div className={Styles.headerActionBtns}>
                            <Button
                                icon={
                                    <CloseOutlined
                                        style={{
                                            fontSize: 24
                                        }}
                                    />
                                }
                                onClick={async () => {
                                    goTo(book.interactions);
                                }}
                                type='text'
                            />
                        </div>
                    </Space>
                )
            }
            title={
                id ? (
                    <div>
                        {docData.status} {docData.document}
                    </div>
                ) : (
                    <FormattedMessage id='add_interaction' />
                )
            }
        >
            <InteractionForm
                categories={categories}
                clients={clients}
                debouncedFilter={debouncedFilter}
                debounceStations={debounceStations}
                docData={docData}
                employees={employees}
                form={form}
                id={id}
                openAddClient={openAddClient}
                setData={setDocData}
                sources={sources}
                stations={stations}
                stationsSearch={stationsSearch}
                suppliers={suppliers}
                types={types}
                user={user}
            />
            {id && (
                <InteractionRowsTable
                    disabled={docData.status === 'DONE'}
                    fetchDocData={fetchDocData}
                    id={id}
                    tableData={tableData}
                />
            )}
            <AddClientModal func={onClientAdded} resetModal={resetModal} visible={modal} />
        </Layout>
    );
});

export default injectIntl(AddInteractionPage);
