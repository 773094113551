/** Constants * */

export const moduleName = 'tasksPage';
const prefix = `cpb/${moduleName}`;

export const FETCH_TASKS = `${prefix}/FETCH_TASKS`;
export const FETCH_TASKS_SUCCESS = `${prefix}/FETCH_TASKS_SUCCESS`;

export const SET_TASKS_FILTERS = `${prefix}/SET_TASKS_FILTERS`;
export const RESET_TASKS_FILTERS = `${prefix}/RESET_TASKS_FILTERS`;

/** Reducer * */
const ReducerState = {
    tasks: [], // All tasks, array of the can be used in a table
    stats: {}, // Tasks stats

    filters: {
        // Filters of tasks
        query: undefined,
        name: undefined,
        onlyMine: true,
        onlyByMe: false,
        statuses: undefined,
        isActive: true, // if true only in statuses (NEW, INWORK),
        overdue: false,
        priorities: undefined,
        scheduledDatetimeFrom: undefined,
        scheduledDatetimeTo: undefined,
        page: 1
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_TASKS_SUCCESS:
            return {
                ...state,
                tasks: payload.tasks,
                stats: payload.stats
            };
        case SET_TASKS_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload
                }
            };

        case RESET_TASKS_FILTERS:
            return {
                ...state,
                filters: {
                    // Filters of tasks
                    query: undefined,
                    name: undefined,
                    onlyMine: false,
                    onlyByMe: false,
                    statuses: undefined,
                    isActive: false, // if true only in statuses (NEW, INWORK),
                    overdue: false,
                    priorities: undefined,
                    scheduledDatetimeFrom: undefined,
                    scheduledDatetimeTo: undefined,
                    page: 1
                }
            };

        default:
            return state;
    }
}

/** Selectors * */

export const stateSelector = state => state[moduleName];

export const selectTasks = state => state[moduleName].tasks;
export const selectTasksStats = state => state[moduleName].stats;
export const selectSort = state => state[moduleName].sort;
export const selectFilters = state => state[moduleName].filters;

/** Action Creators * */

/** Fetch all vehicles */
export const fetchTasks = () => ({
    type: FETCH_TASKS
});

export const fetchTasksSuccess = data => ({
    type: FETCH_TASKS_SUCCESS,
    payload: data
});

export const setTasksFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_TASKS_FILTERS,
            payload: filters
        });
        dispatch(fetchTasks());
    };
};

export const resetTasksFilters = () => {
    return function (dispatch) {
        dispatch({
            type: RESET_TASKS_FILTERS
        });
        dispatch(fetchTasks());
    };
};
