import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { fetchClients, setClientDebt, setFiltersSearchQuery, setOmitStats } from 'core/clientHotOperations/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { AddClientModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import ClientsContainer from './ClientsContainer';

import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    clients: state.clientHotOperations.clients,
    modal: state.modals.modal,
    searchQuery: state.clientHotOperations.filters.query
});

const mapDispatchToProps = {
    fetchClients,
    setOmitStats,
    setFiltersSearchQuery,
    setModal,
    resetModal,
    setClientDebt
};

/**
 * This page is a node page for client hot operations. It was developed to work with Biinotel service.
 * But it is very universal page, so it can be used somewhere else. All components have to be as autonomous as possible.
 * All folders have to structured in feature first order(all subcomponents in the same folder as parent is and each module
 * autonomous as possible).
 *
 * For now this page replaces ordinar clients page.
 *
 * User on this page can select some operations for client:
 *     1. Create a new client - will be redirected to the page where user can create a new client(or open client modal)
 *     2. Open client page - Redirect to a page with client's information(client page)
 *     3. Create order with that client
 *     4. Create a new order with a client and its car
 */
@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class ClientHotOperationsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    componentDidMount() {
        const { location, setClientDebt, fetchClients, setOmitStats } = this.props;
        setClientDebt(true);
        setOmitStats(true);
        fetchClients();
        if (location.state && location.state.showForm) {
            this.onAddClientModal();
        }
    }

    /**
     * When "Create new client" button is pressed we have to open creating modal
     * If there is a value in input we pass this search value as phone number(it will be validated)
     */
    onAddClientModal = () => {
        this.props.setModal(MODALS.ADD_CLIENT, {
            initialPhoneNumber: this.props.searchQuery
        });
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI(
            'GET',
            '/helps',
            { helpId: 'directories_and_settings_client_hot_operations' },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { helperDrawerOpen, allLinks } = this.state;

        return (
            <Layout
                controls={
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Button
                            className={Styles.styledButton}
                            disabled={
                                !isGrantAccessed(this.props.user, grants.DIRECTORIES_CUSTOMERS_LIST, accesses.ROWO)
                            }
                            onClick={this.onAddClientModal}
                            type='primary'
                        >
                            <FormattedMessage id='client_hot_operations_page.create_new_cient' />
                        </Button>
                        <div
                            style={{
                                marginLeft: 8,
                                display: 'flex'
                            }}
                        >
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    const links = await fetchAPI(
                                        'GET',
                                        '/helps',
                                        { helpId: 'directories_and_settings_client_hot_operations' },
                                        undefined,
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    this.setState({
                                        allLinks: links,
                                        helperDrawerOpen: true
                                    });
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </div>
                    </div>
                }
                paper
                title={
                    <div>
                        <FormattedMessage id='navigation.client_hot_operations' />
                    </div>
                }
            >
                <ClientsContainer fetchClients={this.props.fetchClients} />

                <AddClientModal
                    func={clientId => {
                        goTo(`${book.client}/${clientId}`);
                    }}
                    onSubmit={() => this.props.fetchClients()}
                    resetModal={this.props.resetModal}
                    visible={this.props.modal}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
