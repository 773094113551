import { Button, Form, Select } from 'antd';
import { onChangeRoleForm } from 'core/forms/addRoleForm/duck';
import { DecoratedCheckbox, DecoratedInput, DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getGroupsLabels, getPermissionsLabels, groupedPermissions, withReduxForm } from 'utils';

const { OptGroup } = Select;
const { Option } = Select;

@injectIntl
@withReduxForm({
    name: 'addRoleForm',
    actions: {
        change: onChangeRoleForm
    }
})
export class AddRoleForm extends Component {
    render() {
        const { validateFields, getFieldDecorator } = this.props.form;
        const groupsLabels = getGroupsLabels(this.props.intl);
        const permissionsLabels = getPermissionsLabels(this.props.intl);

        return (
            <Form>
                <DecoratedInput
                    field='name'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    label={<FormattedMessage id='add-role-form.name_field' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'add-role-form.name_field_required'
                            })
                        }
                    ]}
                />
                <DecoratedSelect
                    field='grants'
                    filterOption={(input, option) => {
                        return (
                            option.props.children &&
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                    }}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    hasFeedback
                    label={<FormattedMessage id='add-role-form.grants_field' />}
                    mode='multiple'
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'add-role-form.grants_field_required'
                            })
                        }
                    ]}
                >
                    {_.toPairs(groupedPermissions).map(([name, value]) => (
                        <OptGroup label={groupsLabels[name]}>
                            {value.map(value => (
                                <Option key={value} value={value}>
                                    {permissionsLabels[value]}
                                </Option>
                            ))}
                        </OptGroup>
                    ))}
                </DecoratedSelect>
                <DecoratedCheckbox
                    colon={false}
                    field='grantOther'
                    formItem
                    formItemLayout={{
                        labelCol: { span: 14 },
                        wrapperCol: { span: 6 }
                    }}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={false}
                    label={<FormattedMessage id='add-role-form.grant_other_field' />}
                />
                <Button
                    onClick={() => validateFields((err, values) => !err && this.props.createRole(values))}
                    style={{ width: '100%' }}
                    type='primary'
                >
                    <FormattedMessage id='add-role-form.create' />
                </Button>
            </Form>
        );
    }
}
