import { CameraOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Table, Upload, notification } from 'antd';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { statusDisplay } from '../StatusButtons';

const UnitsDrawer = ({
    onClose,
    open,
    units,
    orderUnits,
    isMobile,
    mobileComponent,
    putOrderScheme,
    intl: { formatMessage },
    downloadPhoto,
    orderId,
    disabledPhoto
}) => {
    const columns = useMemo(
        () => [
            { title: formatMessage({ id: 'index' }), key: 'id', dataIndex: 'id' },
            { title: formatMessage({ id: 'name_2' }), key: 'name', dataIndex: 'name' },
            {
                width: 30,
                title: formatMessage({ id: 'status' }),
                align: 'center',
                render: unit => {
                    const orderUnit = orderUnits.find(({ unitId }) => unitId === unit.id) || {};

                    return (
                        <Flex gap={8} justify='center' style={{ width: '100%' }}>
                            <Upload
                                action={elem => downloadPhoto(elem, unit.id, unit.name)}
                                beforeUpload={file => {
                                    const isPNG = file.type === 'image/png';
                                    const isJPG = file.type === 'image/jpeg';
                                    if (!isPNG && !isJPG) {
                                        notification.error({
                                            message: `${file.name} ${formatMessage({
                                                id: 'photo_modal.is_not_an_image'
                                            })}!`
                                        });
                                    }

                                    return isPNG || isJPG;
                                }}
                                disabled={disabledPhoto}
                                showUploadList={false}
                            >
                                <Button disabled={disabledPhoto} icon={<CameraOutlined />} />
                            </Upload>
                            {statusDisplay.map(({ status, color, icon: Icon }) => {
                                return (
                                    <Button
                                        key={status}
                                        icon={<Icon style={{ ...(status === orderUnit.status ? { color } : {}) }} />}
                                        onClick={() =>
                                            Object.keys(orderUnit).length
                                                ? putOrderScheme(orderUnit, { status, id: orderUnit.id }, 'PUT')
                                                : putOrderScheme(unit, { status })
                                        }
                                    />
                                );
                            })}
                        </Flex>
                    );
                }
            }
        ],
        [downloadPhoto, formatMessage, orderUnits, putOrderScheme]
    );

    return (
        <Drawer
            onClose={onClose}
            open={open}
            title={formatMessage({ id: 'order_form_table.chassis_diagnostic' })}
            width={600}
        >
            {isMobile ? (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            marginBottom: 8
                        }}
                    >
                        <Button icon={<RollbackOutlined />} onClick={e => onClose(e)} />
                    </div>
                    {mobileComponent}
                </div>
            ) : (
                <Table bordered columns={columns} dataSource={units} rowKey='id' size='small' />
            )}
        </Drawer>
    );
};

export default injectIntl(UnitsDrawer);
