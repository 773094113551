import { Form } from '@ant-design/compatible';
import { CalculatorOutlined, CreditCardOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tabs, Tooltip } from 'antd';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import {
    DecoratedDatePicker,
    DecoratedInput,
    DecoratedSearch,
    DecoratedSlider
} from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import book from 'routes/book';
import { getCurrency, goTo } from 'utils';
import { paymentRates, paymentTypes } from './config';
import { PromoCodeToast } from './NotificationToasts';
import { SubscribeNotification } from './NotificationToasts/SubscribeNotification';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

const marks = {
    1: {
        style: {
            color: 'rgb(255, 126, 126)'
        },
        label: <strong>1</strong>
    },
    3: {
        style: {
            color: 'rgb(255, 126, 126)'
        },
        label: <strong>3</strong>
    },
    6: {
        style: {
            color: 'rgb(251, 158, 62)'
        },
        label: 6
    },
    9: {
        style: {
            color: 'rgb(251, 158, 62)'
        },
        label: 9
    },
    12: {
        style: {
            color: 'rgb(76, 201, 105)'
        },
        label: <strong>12</strong>
    }
};

@injectIntl
@Form.create()
export class SubscribeForm extends Component {
    state = {
        paymentType: 'CASHLESS',
        promoCodeDiscount: void 0,
        subscribeStatus: void 0
    };

    static getDerivedStateFromProps(props, state) {
        if (props.promoCode !== state.promoCodeDiscount) {
            props.form.setFieldsValue({ promoCodeDiscount: props.promoCode });

            return {
                promoCodeDiscount: props.promoCode
            };
        }

        return null;
    }

    componentWillUnmount() {
        this.props.form.resetFields();
    }

    _subscribeReset = () => {
        this.props.form.resetFields();
        this.props.resetModal();
        goTo(book.subscriptionHistoryPage);
    };

    _subscribeSuccess = type => {
        this.setState({
            subscribeStatus: type
        });

        _.delay(() => this._subscribeReset(), 2000);
    };

    _submit = () => {
        const { form, asyncSubscribe, resetModal } = this.props;

        form.validateFields((err, values) => {
            if (!err) {
                const normalizedValues = {
                    ..._.omit(values, ['period', 'promoCodeDiscount']),
                    startDatetime: dayjs(values.startDatetime).utc().startOf('day').toISOString(),
                    endDatetime: dayjs(values.endDatetime).utc().endOf('day').toISOString()
                };

                asyncSubscribe(normalizedValues).then(
                    () => this._subscribeSuccess(values.paymentType),
                    error => console.error(error)
                );
            }
        });
    };

    _handleSubscriptionStartDate = startDatetime => {
        const startDatetimeClone = startDatetime.clone();
        const period = this.props.form.getFieldValue('period');
        const endDatetime = startDatetimeClone.add(period, 'month');

        this.props.form.setFieldsValue({
            startDatetime,
            endDatetime
        });
    };

    _handleSubscriptionPeriod = period => {
        const startDatetimeClone = this.props.form.getFieldValue('startDatetime').clone();

        this.props.form.setFieldsValue({
            endDatetime: startDatetimeClone.add(period, 'month')
        });
    };

    _setPaymentType = key => {
        this.props.form.setFieldsValue({
            paymentType: key
        });
    };

    _verifyPromoCode = value => {
        this.props.verifyPromoCode(value);
    };

    render() {
        const {
            handleToSuccessModalSubmit,
            resetModal,
            businessName,
            remainPrice,
            cashboxes,
            modalProps,
            user
        } = this.props;

        const { getFieldDecorator, getFieldValue, resetFields } = this.props.form;

        const { formatMessage } = this.props.intl;
        const period = getFieldValue('period') || 3;
        const totalPrice = modalProps.price * period;
        const periodRate = paymentRates[period];
        const totalSum =
            period !== 1 && period !== 3
                ? totalPrice - (totalPrice / 100) * periodRate
                : totalPrice;

        const promoCodeDiscount = getFieldValue('promoCodeDiscount');

        let totalSumWithDiscount = totalSum;
        if (promoCodeDiscount && promoCodeDiscount !== 'error') {
            totalSumWithDiscount = totalSum - (totalSum / 100) * promoCodeDiscount;
        }

        return this.state.subscribeStatus ? (
            <SubscribeNotification type={this.state.subscribeStatus} />
        ) : (
            <Form className={Styles.form} layout='vertical'>
                <div className={Styles.price}>
                    <Numeral currency={getCurrency()}>{modalProps.price}</Numeral> &nbsp;/&nbsp;
                    {formatMessage({ id: 'subscription.monthly' })} &nbsp;
                    <Tooltip
                        // placement="topLeft"
                        getPopupContainer={trigger => trigger.parentNode}
                        title={formatMessage({ id: 'subscription.tooltip' })}
                    >
                        <QuestionCircleOutlined />
                    </Tooltip>
                </div>
                <div className={Styles.paymentRates}>
                    <CalculatorOutlined className={Styles.loyaltyIcon} />
                    &nbsp;
                    {formatMessage({ id: 'subscription.loyalty_program' })}
                    <div>{this._renderLoyaltyProgram()}</div>
                </div>
                <div className={Styles.fieldsBlock}>
                    <DecoratedInput
                        className={Styles.hiddenInput}
                        field='productId'
                        getFieldDecorator={getFieldDecorator}
                        initialValue={modalProps.id}
                    />
                    <DecoratedDatePicker
                        allowClear={false}
                        disabledDate={current => current && current <= dayjs().startOf('day')}
                        field='startDatetime'
                        fields={{}}
                        formatMessage={formatMessage}
                        getCalendarContainer={trigger => trigger.parentNode}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={dayjs()}
                        onChange={startDatetime => this._handleSubscriptionStartDate(startDatetime)}
                    />
                    <DecoratedSlider
                        cnStyles={Styles.slider}
                        field='period'
                        fields={{}}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={modalProps.period || 3}
                        marks={marks}
                        max={12}
                        min={1}
                        onChange={period => this._handleSubscriptionPeriod(period)}
                        step={null}
                    />
                    <DecoratedDatePicker
                        allowClear={false}
                        disabled
                        field='endDatetime'
                        formatMessage={formatMessage}
                        getCalendarContainer={trigger => trigger.parentNode}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={dayjs().add(3, 'month')}
                    />
                </div>

                <Tabs
                    className={Styles.paymentType}
                    defaultActiveKey={paymentTypes.CASHLESS}
                    onChange={this._setPaymentType}
                >
                    <TabPane
                        key={paymentTypes.PORTMONE}
                        disabled
                        tab={
                            <span>
                                <CreditCardOutlined />
                                {formatMessage({
                                    id: 'subscription.paymentTypes.PORTMONE'
                                })}
                            </span>
                        }
                    >
                        <div className={Styles.tabContent}>Portmone</div>
                    </TabPane>
                    <TabPane
                        key={paymentTypes.CASHLESS}
                        tab={formatMessage({
                            id: 'subscription.paymentTypes.CASHLESS'
                        })}
                    >
                        {formatMessage({
                            id: 'subscription.support_will_contact'
                        })}
                    </TabPane>
                </Tabs>
                <DecoratedInput
                    className={Styles.hiddenInput}
                    field='paymentType'
                    getFieldDecorator={getFieldDecorator}
                    initialValue={paymentTypes.CASHLESS}
                />
                <DecoratedSearch
                    className={Styles.promoCode}
                    // validateStatus
                    enterButton={formatMessage({
                        id: 'submit'
                    })}
                    field='promoCode'
                    fields={{}}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    onSearch={value => this._verifyPromoCode(value)}
                    placeholder={formatMessage({
                        id: 'subscription.promo_code'
                    })}
                />
                {this.state.promoCodeDiscount && (
                    <PromoCodeToast
                        promoCodeDiscount={this.props.form.getFieldValue('promoCodeDiscount')}
                    />
                )}
                <DecoratedInput
                    className={Styles.hiddenInput}
                    field='promoCodeDiscount'
                    getFieldDecorator={getFieldDecorator}
                    initialValue={this.state.promoCodeDiscount}
                />
                <div className={Styles.price}>
                    {formatMessage({
                        id: 'subscription.total_sum'
                    })}
                    : &nbsp;
                    <Numeral className={Styles.totalSum} currency={getCurrency()}>
                        {totalSumWithDiscount}
                    </Numeral>
                    &nbsp;
                    {period !== 1 && period !== 3 ? (
                        <span>
                            &nbsp;(
                            <Numeral currency={getCurrency()}>
                                {modalProps.price - (modalProps.price * paymentRates[period]) / 100}
                            </Numeral>
                            &nbsp;/&nbsp;
                            {formatMessage({ id: 'subscription.monthly' })})
                        </span>
                    ) : null}
                    {period === 3 ? (
                        <span>
                            &nbsp;(
                            <Numeral currency={getCurrency()}>{modalProps.price}</Numeral>
                            &nbsp;/&nbsp;
                            {formatMessage({ id: 'subscription.monthly' })})
                        </span>
                    ) : null}
                </div>
                <Button
                    className={Styles.purchaseButton}
                    onClick={() => this._submit()}
                    type='primary'
                >
                    {formatMessage({
                        id: 'subscription.proceed_with_the_payment'
                    })}
                </Button>
            </Form>
        );
    }

    _renderLoyaltyProgram = () =>
        Object.keys(paymentRates).map((key, index) => (
            <span key={`${key}-${index}`} className={Styles.loyaltyRate}>
                {key}&nbsp;
                {this.props.intl.formatMessage({
                    id: 'mo.'
                })}
                &nbsp; - {Object.values(paymentRates)[index]}%
            </span>
        ));
}
