/*
The purpose of this module is to provide report about all orders.
*/
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { Layout } from 'commons';
import { ReportOrdersTable } from 'components';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import {
    fetchExcelFileReport,
    fetchReportOrders,
    fetchReportOrdersFilterOptions,
    selectReportOptions,
    setReportOptions,
    setReportOrdersAllFilters,
    setReportOrdersAppointmentFromDate,
    setReportOrdersAppointmentToDate,
    setReportOrdersCreationFromDate,
    setReportOrdersCreationToDate,
    setReportOrdersDoneFromDate,
    setReportOrdersDoneToDate,
    setReportOrdersExportOptions,
    setReportOrdersIncludeAppurtenanciesDiscount,
    setReportOrdersIncludeServicesDiscount,
    setReportOrdersPage,
    setReportOrdersQuery,
    setReportOrdersStatus
} from 'core/reports/reportOrders/duck';
import { ReportOrdersExportModal, ReportOrdersFilterModal, SalesReportModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';
import Stats from './Stats';
import Styles from './styles.m.css';

const { Search } = Input;

const mapStateToProps = state => ({
    tableData: state.reportOrders.tableData,
    includeServicesDiscount: state.reportOrders.options.includeServicesDiscount,
    includeAppurtenanciesDiscount: state.reportOrders.options.includeAppurtenanciesDiscount,
    filter: state.reportOrders.filter,
    page: state.reportOrders.filter.page,
    pageSize: state.reportOrders.filter.pageSize,
    stats: state.reportOrders.stats,
    filterOptions: state.reportOrders.filterOptions,
    modal: state.modals.modal,
    reportOrdersFetching: state.ui.reportOrdersFetching,
    user: state.auth,
    options: selectReportOptions(state)
});

const mapDispatchToProps = {
    fetchReportOrders,
    fetchReportOrdersFilterOptions,
    fetchExcelFileReport,
    setReportOrdersPage,
    setReportOrdersIncludeServicesDiscount,
    setReportOrdersIncludeAppurtenanciesDiscount,
    setReportOrdersExportOptions,
    setReportOrdersQuery,
    setReportOrdersStatus,
    setReportOrdersCreationFromDate,
    setReportOrdersCreationToDate,
    setReportOrdersAppointmentFromDate,
    setReportOrdersAppointmentToDate,
    setReportOrdersDoneFromDate,
    setReportOrdersDoneToDate,
    setReportOrdersAllFilters,
    setReportOptions,

    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class ReportOrdersPage extends Component {
    constructor(props) {
        super(props);

        this.exportModalFormRef = null;
    }

    componentDidMount() {
        this.props.fetchReportOrders();
        this.props.fetchReportOrdersFilterOptions();
        if (this.props.location.state && this.props.location.state.showModal) {
            this.props.setModal(this.props.location.state.showModal);
        }
        if (this.props.location.state && this.props.location.state.downloadReport) {
            this.onOpenExportModal();
        }
    }

    onOpenFilterModal = () => {
        this.props.setModal(MODALS.REPORT_ORDERS_FILTER);
    };

    onCloseFilterModal = () => {
        this.props.resetModal();
    };

    onOpenExportModal = () => {
        this.props.setModal(MODALS.REPORT_ORDERS_EXPORT);
    };

    onCloseExportModal = () => {
        this.props.resetModal();
    };

    onSubmitExportModal = () => {
        this.props.setReportOrdersExportOptions();
        this.props.fetchExcelFileReport();
        this.onCloseExportModal();
    };

    // This method takes filters generated by modal, overrides them in duck and fetch result
    onSubmitFilterModal = filters => {
        const { setReportOrdersAllFilters, fetchReportOrders, resetModal } = this.props;
        console.log(filters);
        setReportOrdersAllFilters(filters);
        fetchReportOrders();
        resetModal();
    };

    render() {
        const {
            fetchReportOrders,
            tableData,
            setReportOrdersIncludeServicesDiscount,
            setReportOrdersIncludeAppurtenanciesDiscount,
            includeServicesDiscount,
            includeAppurtenanciesDiscount,
            filter,
            stats,
            setReportOrdersPage,
            filterOptions,
            reportOrdersFetching,
            options,

            setReportOrdersQuery,
            setReportOrdersStatus,
            setReportOrdersCreationFromDate,
            setReportOrdersCreationToDate,
            setReportOrdersAppointmentFromDate,
            setReportOrdersAppointmentToDate,
            setReportOrdersDoneFromDate,
            setReportOrdersDoneToDate,
            setReportOptions,

            modal,
            user
        } = this.props;

        // Transfer all filter methods in one object to easily manipulate data
        const filterControls = {
            fetchReportOrders,
            onOpenFilterModal: () => this.onOpenFilterModal(),

            setReportOrdersPage,
            setReportOrdersIncludeServicesDiscount,
            setReportOrdersIncludeAppurtenanciesDiscount,
            includeServicesDiscount,
            includeAppurtenanciesDiscount,
            options,

            setReportOrdersQuery,
            setReportOrdersStatus,
            setReportOrdersCreationFromDate,
            setReportOrdersCreationToDate,
            setReportOrdersAppointmentFromDate,
            setReportOrdersAppointmentToDate,
            setReportOrdersDoneFromDate,
            setReportOrdersDoneToDate,
            setReportOptions
        };

        return (
            <Layout
                controls={
                    <div className={Styles.buttonGroup}>
                        <Button
                            icon={<PrinterOutlined />}
                            onClick={() => {
                                this.props.setModal(MODALS.SALES_REPORT_MODAL);
                            }}
                            style={{
                                fontSize: 18
                            }}
                            title={this.props.intl.formatMessage({ id: 'print' })}
                        />
                        <Button
                            disabled={isForbidden(
                                user,
                                permissions.ACCESS_REPORT_PROFIT_FROM_ORDERS_PRINT
                            )}
                            onClick={this.onOpenExportModal}
                            type='primary'
                        >
                            <FormattedMessage id='report_orders_page.download_report' />
                        </Button>
                    </div>
                }
                paper
                title={
                    <div>
                        <div>
                            <FormattedMessage id='navigation.report_orders' />
                        </div>
                    </div>
                }
            >
                <section>
                    <Stats stats={stats} />
                </section>

                <ReportOrdersTable
                    filter={filter}
                    filterControls={filterControls}
                    includeServicesDiscount={includeServicesDiscount}
                    loading={reportOrdersFetching}
                    setIncludeServicesDiscount={setReportOrdersIncludeServicesDiscount}
                    stats={stats}
                    tableData={tableData}
                />

                <ReportOrdersFilterModal
                    filter={filter}
                    filterOptions={filterOptions}
                    onCloseFilterModal={this.onCloseFilterModal}
                    onSubmit={this.onSubmitFilterModal}
                    visible={modal}
                />

                <ReportOrdersExportModal
                    onCancel={this.onCloseExportModal}
                    onOk={this.onSubmitExportModal}
                    visible={modal}
                />

                <SalesReportModal />
            </Layout>
        );
    }
}
