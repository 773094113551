import { Table } from 'antd';
import {
    fetchStoreBalance,
    selectStoreBalance,
    selectStoreBalanceFilters,
    selectStoreBalanceLoading,
    setStoreBalancePage
} from 'core/storage/storeBalance';
import { redirectToTracking } from 'core/storage/tracking';
import _ from 'lodash';
import React, { memo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { usePrevious } from 'utils';
import columns from './columns';

const StoreBalanceTableComponent = memo(props => {
    const { balance } = props;
    const prevBalance = usePrevious(balance);

    useEffect(() => {
        if (!_.isEqual(balance, prevBalance)) {
            props.fetchStoreBalance();
        }
    }, [balance]);

    const pagination = {
        pageSize: 25,
        size: 'large',
        total: Math.ceil(_.get(balance, 'stats.count', 0) / 25) * 25,
        hideOnSinglePage: true,
        current: props.filters.page,
        position: 'bottom',
        onChange: page => {
            props.setStoreBalancePage(page);
            props.fetchStoreBalance();
        }
    };

    return (
        <StyledTable
            columns={columns(props)}
            dataSource={props.balance.list}
            loading={props.loading}
            locale={{
                emptyText: props.intl.formatMessage({ id: 'no_data' })
            }}
            pagination={pagination}
            rowKey={record => record.id}
            size='small'
        />
    );
});

const StyledTable = styled(Table)``;

const mapStateToProps = state => ({
    balance: selectStoreBalance(state),
    filters: selectStoreBalanceFilters(state),
    loading: selectStoreBalanceLoading(state),
    user: state.auth
});

const mapDispatchToProps = {
    fetchStoreBalance,
    setStoreBalancePage,
    redirectToTracking
};

export const StoreBalanceTable = injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(StoreBalanceTableComponent)
);
