exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__inputWrap---3YnJA{display:flex;justify-content:space-between;padding-bottom:16px;gap:16px}.styles-m__inputWrap---3YnJA>*{width:100%}.styles-m__inputWrapMobile---1PSsw{display:flex;flex-direction:column;gap:6px;position:absolute;top:-228px;left:0;right:0;z-index:1000;background:#f1f0f0;padding:16px;border-radius:8px}.styles-m__blockStyleMobileNone---38q9u{display:none}.styles-m__fixedMenuButton---1GJCx{display:inline-block}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,6BACI,YAAa,CACb,6BAA8B,CAC9B,mBAAoB,CACpB,QAIJ,CAHI,+BACI,UACJ,CAGJ,mCACI,YAAa,CACb,qBAAsB,CACtB,OAAQ,CACR,iBAAkB,CAClB,UAAW,CACX,MAAO,CACP,OAAQ,CACR,YAAa,CACb,kBAA8B,CAC9B,YAAa,CACb,iBACJ,CAEA,wCACC,YACD,CAEA,mCACI,oBACJ","file":"styles.m.css","sourcesContent":[".inputWrap {\n    display: flex;\n    justify-content: space-between;\n    padding-bottom: 16px;\n    gap: 16px;\n    & > * {\n        width: 100%;\n    }\n}\n\n.inputWrapMobile {\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n    position: absolute;\n    top: -228px;\n    left: 0;\n    right: 0;\n    z-index: 1000;\n    background: rgb(241, 240, 240);\n    padding: 16px;\n    border-radius: 8px;\n}\n\n.blockStyleMobileNone {\n\tdisplay: none;\n}\n\n.fixedMenuButton {\n    display: inline-block;\n}"]}]);

// Exports
exports.locals = {
	"inputWrap": "styles-m__inputWrap---3YnJA",
	"inputWrapMobile": "styles-m__inputWrapMobile---1PSsw",
	"blockStyleMobileNone": "styles-m__blockStyleMobileNone---38q9u",
	"fixedMenuButton": "styles-m__fixedMenuButton---1GJCx"
};