exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__MBRDoc---3bAx2{margin-left:2%}.styles-m__payDocsContainer---ad430{height:100%;padding-left:4%}.styles-m__payDoc---3od5h{margin:0 2px 2px 0;display:flex;justify-content:flex-end;height:2em;border-radius:1em}.styles-m__payPDoc---RFE75{background-color:#6f6}.styles-m__payMDoc---25kzP{background-color:#f88}.styles-m__headerContainer---2ENiA{margin-bottom:12px;margin-right:12px;display:flex;justify-content:flex-end}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0BACI,cACJ,CAEA,oCACI,WAAY,CACZ,eACJ,CAEA,0BACI,kBAAmB,CACnB,YAAa,CACb,wBAAyB,CACzB,UAAW,CACX,iBACJ,CAEA,2BACI,qBACJ,CAEA,2BACI,qBACJ,CAEA,mCACI,kBAAmB,CACnB,iBAAkB,CAClB,YAAa,CACb,wBACJ","file":"styles.m.css","sourcesContent":[".MBRDoc {\n    margin-left: 2%;\n}\n\n.payDocsContainer {\n    height: 100%;\n    padding-left: 4%;\n}\n\n.payDoc {\n    margin: 0 2px 2px 0;\n    display: flex;\n    justify-content: flex-end;\n    height: 2em;\n    border-radius: 1em;\n} \n\n.payPDoc {\n    background-color: #6F6;\n}\n\n.payMDoc {\n    background-color: #F88;\n}\n\n.headerContainer {\n    margin-bottom: 12px;\n    margin-right: 12px;\n    display: flex;\n    justify-content: flex-end;\n}"]}]);

// Exports
exports.locals = {
	"MBRDoc": "styles-m__MBRDoc---3bAx2",
	"payDocsContainer": "styles-m__payDocsContainer---ad430",
	"payDoc": "styles-m__payDoc---3od5h",
	"payPDoc": "styles-m__payPDoc---RFE75",
	"payMDoc": "styles-m__payMDoc---25kzP",
	"headerContainer": "styles-m__headerContainer---2ENiA"
};