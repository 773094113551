exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__newTaskRow---1iJ_6{color:var(--text);font-weight:500}.styles-m__completedTaskRow---2NjoO{background:#d1d1d1}.styles-m__taskRow---2mH45:hover{cursor:pointer}.styles-m__buttonFilterGroup---3CieZ{margin-bottom:1em}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACI,iBAAkB,CAClB,eACJ,CAEA,oCACI,kBACJ,CAGI,iCACK,cACJ,CAEL,qCACI,iBACJ","file":"styles.m.css","sourcesContent":[".newTaskRow {\r\n    color: var(--text);\r\n    font-weight: 500;\r\n}\r\n\r\n.completedTaskRow {\r\n    background: #d1d1d1;\r\n}\r\n\r\n.taskRow {\r\n    &:hover {\r\n         cursor: pointer;\r\n     }\r\n}\r\n.buttonFilterGroup {\r\n    margin-bottom: 1em;\r\n}"]}]);

// Exports
exports.locals = {
	"newTaskRow": "styles-m__newTaskRow---1iJ_6",
	"completedTaskRow": "styles-m__completedTaskRow---2NjoO",
	"taskRow": "styles-m__taskRow---2mH45",
	"buttonFilterGroup": "styles-m__buttonFilterGroup---3CieZ"
};