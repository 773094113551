import { Rating } from 'components';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class ReviewRating extends Component {
    render() {
        const { repairQuality, repairDuration, comfort, serviceQuality, color } = this.props;

        return (
            <ul className={Styles.reviewRating}>
                <li>
                    <FormattedMessage id='reviews-table.repair_quality' />:{' '}
                    <Rating color={color} rating={repairQuality} />
                </li>
                <li>
                    <FormattedMessage id='reviews-table.repair_duration' />:{' '}
                    <Rating color={color} rating={repairDuration} />
                </li>
                <li>
                    <FormattedMessage id='reviews-table.comfort' />:
                    <Rating color={color} rating={comfort} />
                </li>
                <li>
                    <FormattedMessage id='reviews-table.service' />:
                    <Rating color={color} rating={serviceQuality} />
                </li>
            </ul>
        );
    }
}
