import { Tabs } from 'antd';
import { Catcher } from 'commons';
import { EmployeesTable, GeneralScheduleTable } from 'components';
import { deleteEmployee, fetchEmployees, setEmployeesStatus } from 'core/employees/duck';
import { initEmployeeForm } from 'core/forms/employeeForm/duck';
import dayjs from 'dayjs';
import { SalaryReportForm } from 'forms';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI, isForbidden, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    salaries: state.forms.settingSalary.salaries,
    entity: state.forms.settingSalary.fields,
    employees: state.employees.employees,
    status: state.employees.status,
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setEmployeesStatus,
    fetchEmployees,
    initEmployeeForm,
    deleteEmployee
};
@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class EmployeesContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generalSchedule: [],
            posts: [],
            count: 0
        };
    }

    componentDidMount() {
        const { status, setEmployeesStatus, fetchEmployees } = this.props;
        setEmployeesStatus({ status, disabled: false });
        fetchEmployees();
        this.fetchGeneralSchedule();
        this.fetchEmployeesPosts();
    }

    fetchGeneralSchedule = async (employeeId, postId, pag, pagSize, startDate, endDate) => {
        const response = await fetchAPI(
            'GET',
            'employees_schedule',
            {
                employeeId,
                postId,
                page: pag || 1,
                pageSize: pagSize || 25,
                startDate: startDate || dayjs().startOf('month').format('YYYY-MM-DD'),
                endDate: endDate || dayjs().endOf('month').format('YYYY-MM-DD')
            },
            null,
            {
                handleErrorInternally: true
            }
        );
        await this.setState({
            generalSchedule: response ? response.result : undefined,
            count: response ? response.totalCount : undefined
        });
    };

    fetchEmployeesPosts = async () => {
        const response = await fetchAPI('GET', 'employee/posts', null, null, {
            handleErrorInternally: true
        });
        await this.setState({
            posts: response
        });
    };

    render() {
        const {
            user,
            employees,
            initEmployeeForm,
            deleteEmployee,
            setEmployeesStatus,
            fetchEmployees,
            status,
            isMobile
        } = this.props;

        const { generalSchedule, posts, count } = this.state;

        return (
            <Catcher>
                {isForbidden(user, permissions.EMPLOYEES_SALARIES) ? (
                    <EmployeesTable
                        deleteEmployee={deleteEmployee}
                        employees={employees}
                        fetchEmployees={fetchEmployees}
                        initEmployeeForm={initEmployeeForm}
                        isMobile={isMobile}
                        setEmployeesStatus={setEmployeesStatus}
                        status={status}
                        user={user}
                    />
                ) : (
                    <Tabs
                        defaultActiveKey={
                            this.props.location.state && this.props.location.state.downloadReport ? '3' : '1'
                        }
                    >
                        <TabPane
                            key='1'
                            tab={this.props.intl.formatMessage({
                                id: 'navigation.employees'
                            })}
                        >
                            <EmployeesTable
                                deleteEmployee={deleteEmployee}
                                employees={employees}
                                fetchEmployees={fetchEmployees}
                                initEmployeeForm={initEmployeeForm}
                                isMobile={isMobile}
                                setEmployeesStatus={setEmployeesStatus}
                                status={status}
                                user={user}
                            />
                        </TabPane>
                        {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST_GENERAL_WORK_SCHEDULE) && (
                            <TabPane
                                key='2'
                                tab={this.props.intl.formatMessage({
                                    id: 'navigation.general_schedule'
                                })}
                            >
                                <GeneralScheduleTable
                                    count={count}
                                    data={generalSchedule}
                                    deleteEmployee={deleteEmployee}
                                    employees={employees}
                                    fetchEmployees={fetchEmployees}
                                    fetchGeneralSchedule={this.fetchGeneralSchedule}
                                    initEmployeeForm={initEmployeeForm}
                                    isMobile={isMobile}
                                    posts={posts}
                                    setEmployeesStatus={setEmployeesStatus}
                                    status={status}
                                    user={user}
                                />
                            </TabPane>
                        )}
                        {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST_SALARIES) && (
                            <TabPane
                                key='3'
                                tab={this.props.intl.formatMessage({
                                    id: 'reports_page.by_salaries'
                                })}
                            >
                                <SalaryReportForm hideSettings />
                            </TabPane>
                        )}
                        {/* <TabPane
                            key='3'
                            tab={this.props.intl.formatMessage({
                                id: 'setting-salary.salary rules'
                            })}
                        >
                            <SalaryRulesTable />
                        </TabPane> */}
                    </Tabs>
                )}
            </Catcher>
        );
    }
}
