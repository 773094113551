import { Col, Input, Modal, notification, Row, Select } from 'antd';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { MODALS } from 'core/modals/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    brands: selectBrands(state)
});

const mapDispatchToProps = {
    fetchBrands
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CrossesAddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    handleOk = async () => {
        const { code, brandId, xCode, xBrandId, dir } = this.state;
        await fetchAPI(
            'POST',
            'product_crosses',
            null,
            [
                {
                    code,
                    brandId,
                    xCode,
                    xBrandId,
                    dir
                }
            ],
            { handleErrorInternally: true }
        );
        notification.success({
            message: this.props.intl.formatMessage({ id: 'barcode.success' })
        });
        this.props.updateTable();
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            code: undefined,
            brandId: null,
            xCode: undefined,
            xBrandId: null,
            dir: undefined
        });
        this.props.resetModal();
    };

    componentDidMount = async () => {
        this.props.fetchBrands();
    };

    render() {
        const {
            visible,
            brands,
            intl: { formatMessage },
            modalProps: { edit }
        } = this.props;

        const { code, brandId, xCode, xBrandId, dir } = this.state;

        const dirM = ['ONE'];

        return (
            <div>
                <Modal
                    maskClosable={false}
                    okButtonProps={{
                        disabled: !code || !xCode || !dir
                    }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={
                        <FormattedMessage
                            id={
                                edit
                                    ? 'my_normative_page.modal_edit_normative'
                                    : 'my_normative_page.modal_add_crosses'
                            }
                        />
                    }
                    visible={visible === MODALS.CROSSES_ADD_MODAL}
                    width='50vw'
                >
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='diagnostic-page.code' />
                        </Col>
                        <Col span={12}>
                            <Input
                                onChange={({ target }) => {
                                    this.setState({
                                        code: target.value
                                    });
                                }}
                                value={code}
                            />
                        </Col>
                        <Col span={6}></Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='order_form_table.brand' />
                        </Col>
                        <Col span={12}>
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    this.setState({
                                        brandId: value
                                    });
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'order_form_table.brand.placeholder'
                                })}
                                showSearch
                                value={brandId}
                            >
                                {brands.map(({ brandId, brandName }) => (
                                    <Option key={brandId} value={brandId}>
                                        {brandName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='directories.x_code' />
                        </Col>
                        <Col span={12}>
                            <Input
                                onChange={({ target }) => {
                                    this.setState({
                                        xCode: target.value
                                    });
                                }}
                                value={xCode}
                            />
                        </Col>
                        <Col span={6}></Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='directories.x_brand' />
                        </Col>
                        <Col span={12}>
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    this.setState({
                                        xBrandId: value
                                    });
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'order_form_table.brand.placeholder'
                                })}
                                showSearch
                                value={xBrandId}
                            >
                                {brands.map(({ brandId, brandName }) => (
                                    <Option key={brandId} value={brandId}>
                                        {brandName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <FormattedMessage id='directories.direcroty' />
                        </Col>
                        <Col span={12}>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => {
                                    this.setState({
                                        dir: value
                                    });
                                }}
                                optionFilterProp='children'
                                showSearch
                                value={dir}
                            >
                                {dirM.map(value => (
                                    <Option key={value} value={value}>
                                        {value}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
