import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Numeral } from 'commons';
import _ from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectFetchingSelectedStoreDoc,
    selectMode,
    selectSelectedStoreDoc,
    selectSelectedStoreDocId,
    setSelectedStoreDocId
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    selectedStoreDocId: selectSelectedStoreDocId(state),
    selectedStoreDoc: selectSelectedStoreDoc(state),
    fetchingSelectedStoreDoc: selectFetchingSelectedStoreDoc(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    setSelectedStoreDocId
};

/**
 * If client is selected this component generates block with its basic
 * information and ability to remove currently selected client(make no one selected)
 *
 * @callback onClientDeselected() - when client was deselected
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocVisualizer extends React.Component {
    render() {
        const {
            mode,
            selectedStoreDocId,
            selectedStoreDoc,
            fetchingSelectedStoreDoc,

            setSelectedStoreDocId,
            editable
        } = this.props;

        if (!selectedStoreDocId) return 'No selected store doc data';
        if (fetchingSelectedStoreDoc) return <LoadingOutlined />;
        const disabled = Boolean(mode != MODES.ADD);

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col span={4}>{selectedStoreDoc.documentNumber}</Col>

                    <Col className={Styles.col} span={8}>
                        <Link to={`${book.client}/${selectedStoreDoc.counterpartClientId}`}>
                            <span>{`${selectedStoreDoc.counterpartClientName || '-'}`}</span>
                        </Link>
                    </Col>

                    <Col className={Styles.col} span={6}></Col>

                    <Col span={2}>
                        <Numeral mask='0,0.00' nullText='0'>
                            {Number(_.get(selectedStoreDoc, 'sellingSum', 0)) || 0}
                        </Numeral>
                    </Col>
                    <Col span={2}>
                        <Numeral mask='0,0.00' nullText='0'>
                            {Number(_.get(selectedStoreDoc, 'remainSum', 0)) || 0}
                        </Numeral>
                    </Col>

                    <Col className={Styles.actionsCol} span={2}>
                        {!disabled || editable ? (
                            <CloseOutlined
                                className={Styles.closeIcon}
                                // Remove currently selected store doc
                                onClick={() => {
                                    setSelectedStoreDocId(undefined);
                                }}
                            />
                        ) : null}
                    </Col>
                </Row>
            </div>
        );
    }
}
