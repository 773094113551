export const ALL_CSH = {
    cashOrders: {
        stats: {
            totalCount: 'загальна кількість',
            increase: 'збільшення',
            decrease: 'зменшення',
            balance: 'баланс'
        },
        list: {
            $id: 'id ідентифікатор',
            $type: 'тип',
            $cashBoxId: 'id ідентифікатор каси',
            $datetime: 'дата та час',
            $createdAt: 'створено',
            $updatedAt: 'оновлено',
            $orderId: 'ідентифікатор замовлення',
            $increase: 'збільшення',
            $decrease: 'зменшення',
            $createdBy: 'ким створено',
            $updatedBy: 'ким оновлено',
            $description: 'опис',
            $clientId: 'id ідентифікатор клієнта',
            $employeeId: 'id ідентифікатор співробітника',
            $businessSupplierId: 'id ідентифікатор постачальника бізнесу',
            $otherCounterparty: 'інший контрагент',
            $tag: 'мітка',
            $externalId: 'id зовнішній ідентифікатор',
            $storeDocId: 'id ідентифікатор документа зберігання',
            $salaryDocId: 'id ідентифікатор документа зарплати',
            $analyticsUniqueId: 'id унікальний ідентифікатор аналітики',
            $isRegisteredWithRst: 'зареєстровано в розрахунку',
            $cashBoxName: 'назва каси',
            $fiscalNumber: 'фіскальний номер',
            $rst: 'РРО',
            $counterPartName: 'назва контрагента',
            $clientName: "ім'я клієнта",
            $clientSurname: 'прізвище клієнта',
            $employeeName: "ім'я співробітника",
            $employeeSurname: 'прізвище співробітника',
            $businessSupplierName: 'назва станції',
            $orderNum: 'номер замовлення',
            $businessItn: 'Код ЄДРПОУ бізнесу',
            $clientItn: 'Код ЄДРПОУ клієнта',
            $analyticsName: 'назва аналітики',
            $documentNumber: 'номер документа',
            $sequentialNumber: 'послідовний номер'
        }
    },
    businessName: 'Назва станції',
    businessItns: 'Код ЄДРПОУ станції'
};
