import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, Input, Modal, Select, Table } from 'antd';
import { AvailabilityIndicator } from 'components';
import { API_URL } from 'core/forms/orderDiagnosticForm/saga';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const { info } = Modal;
const { Option } = Select;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    photo: '10%',
    code: '15%',
    brand: '15%',
    attributes: '15%',
    businessSupplierName: '12%',
    purchasePrice: '10%',
    salePrice: '10%',
    store: '13%'
};

@injectIntl
export default class CatalogTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fetched: false,
            dataSource: [],
            storeOptions: [],
            storeFilter: undefined,
            brandOptions: [],
            brandFilter: [],
            codeFilter: undefined,
            attributesFilters: [],
            inStock: false,
            reserveModalVisible: false,
            reserveModalData: undefined
        };

        this.columns = [
            {
                title: <FormattedMessage id='photo' />,
                key: 'photo',
                width: defWidth.photo,
                render: elem => {
                    const src = elem.images[0]
                        ? `${__TECDOC_IMAGES_URL__}/${elem.images[0].pictureName}`
                        : `${__TECDOC_IMAGES_URL__}/not_found.png`;

                    return <PhotoModal attributes={elem.attributes} src={src} />;
                }
            },
            {
                title: () => {
                    return (
                        <div onClick={e => e.stopPropagation()}>
                            <FormattedMessage id='order_form_table.detail_code' />
                            {this.state.storeOptions.length ? (
                                <Select
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                        zIndex: '9999',
                                        minWidth: 380
                                    }}
                                    filterOption={(input, option) => {
                                        return (
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0 ||
                                            String(option.props.value).indexOf(
                                                input.toLowerCase()
                                            ) >= 0
                                        );
                                    }}
                                    onSelect={(value, option) => {
                                        this.setState({
                                            storeFilter: value
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'order_form_table.store_group'
                                    })}
                                    showSearch
                                    style={{ maxWidth: 280 }}
                                    value={this.state.storeFilter}
                                >
                                    {this.state.storeOptions.map((elem, i) => (
                                        <Option key={i} value={elem.id}>
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            ) : null}
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        codeFilter: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_code'
                                })}
                                style={{ maxWidth: 280 }}
                                value={this.state.codeFilter}
                            />
                        </div>
                    );
                },
                key: 'code',
                dataIndex: 'partNumber',
                width: defWidth.code,
                sorter: (a, b) => a.partNumber.localeCompare(b.partNumber),
                sortDirections: ['descend', 'ascend'],
                render: (data, elem) => {
                    return (
                        <div>
                            <div style={{ fontWeight: 'bold' }}>{data}</div>
                            <div>{elem.description}</div>
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div onClick={e => e.stopPropagation()}>
                            <FormattedMessage id='order_form_table.brand' />
                            <Select
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.props.value).indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                mode='multiple'
                                onDeselect={(value, option) => {
                                    const index = this.state.brandFilter.indexOf(value);
                                    const tmp = this.state.brandFilter.filter((_, i) => i != index);
                                    this.setState({
                                        brandFilter: tmp,
                                        update: true
                                    });
                                }}
                                onSelect={(value, option) => {
                                    this.state.brandFilter.push(value);
                                    this.setState({
                                        update: true
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.brand'
                                })}
                                showSearch
                                style={{ minWidth: 130 }}
                                value={this.state.brandFilter}
                            >
                                {this.state.brandOptions.map((elem, i) => (
                                    <Option key={i} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    );
                },
                key: 'brand',
                dataIndex: 'supplierName',
                width: defWidth.brand,
                sorter: (a, b) => a.supplierName && a.supplierName.localeCompare(b.supplierName),
                sortDirections: ['descend', 'ascend'],
                render: (data, elem) => {
                    // <div>{getSupplier(elem.suplierId, elem.partNumber)}</div>
                    return <div>{data}</div>;
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.info' />
                            <div style={{ display: 'flex' }}>
                                {this.getAttributeFilter(1)}
                                {this.getAttributeFilter(2)}
                            </div>
                            <div style={{ display: 'flex' }}>
                                {this.getAttributeFilter(3)}
                                {this.getAttributeFilter(4)}
                            </div>
                        </div>
                    );
                },
                key: 'attributes',
                dataIndex: 'attributes',
                width: defWidth.attributes,
                render: (attributes, elem) => {
                    let title = '';
                    let data = '';
                    for (let i = 0; i < attributes.length; i++) {
                        const attribute = attributes[i];
                        title += `${attribute.description}: ${attribute.value}\n`;
                        data += `${attribute.value}`;
                        if (i == attributes.length - 1) {
                            data += '. ';
                        } else {
                            data += ', ';
                        }
                    }
                    data += `${elem.productId}.`;

                    return (
                        <div style={{ textTransform: 'capitalize' }} title={title}>
                            {data}
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            {!this.props.stockMode ? (
                                <FormattedMessage id='order_form_table.supplier' />
                            ) : (
                                <React.Fragment>
                                    <FormattedMessage id='storage' /> /{' '}
                                    <FormattedMessage id='wms.cell' />
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                key: 'businessSupplierName',
                dataIndex: 'businessSupplierName',
                width: defWidth.businessSupplierName,
                render: (data, elem) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Input
                                disabled
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.supplier'
                                })}
                                style={{ maxWidth: 180, color: 'black' }}
                                value={elem.cellAddress || data}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                width: defWidth.purchasePrice,
                render: data => {
                    const strVal = String(Math.round(data * 10) / 10);

                    return data ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'salePrice',
                dataIndex: 'salePrice',
                width: defWidth.salePrice,
                sorter: (a, b) => {
                    if (!this.state.inStock) {
                        this.setState({
                            inStock: true
                        });
                    }
                    if (!b.salePrice) return -1;

                    return Number(a.salePrice) - Number(b.salePrice);
                },
                sortDirections: ['descend', 'ascend'],
                render: data => {
                    const strVal = String(Math.round(data * 10) / 10);

                    return data ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            {this.props.stockMode ? (
                                <span>
                                    <FormattedMessage id='storage.in_stock' /> /{' '}
                                    <FormattedMessage id='storage.available' />
                                </span>
                            ) : (
                                <FormattedMessage id='order_form_table.store' />
                            )}
                            <div style={{ fontWeight: '400', fontSize: 12 }}>
                                <FormattedMessage id='in_stock' />
                                <Checkbox
                                    checked={this.state.inStock}
                                    onChange={() => {
                                        this.setState({
                                            inStock: !this.state.inStock
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                },
                key: 'store',
                dataIndex: 'store',
                width: defWidth.store,
                sorter: (a, b) => {
                    const aStore = a.store ? a.store[0] : 0;
                    const bStore = b.store ? b.store[0] : 0;

                    return Number(aStore) - Number(bStore);
                },
                sortDirections: ['descend', 'ascend'],
                render: (store, elem) => {
                    return this.props.stockMode ? (
                        <span
                            onClick={() =>
                                this.setState({
                                    reserveModalVisible: elem.id
                                })
                            }
                            style={{
                                color: 'var(--link)',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                        >
                            {elem.countInWarehouses} / {elem.available}
                        </span>
                    ) : (
                        <AvailabilityIndicator indexArray={store} />
                    );
                }
            }
        ];
    }

    getAttributeFilter(key) {
        if (this.state.attributesFilters[`index${key}`] == undefined) return null;

        return (
            <Select
                allowClear
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                    zIndex: '9999',
                    textTransform: 'capitalize'
                }}
                onChange={value => {
                    this.state.attributesFilters[`index${key}`].current = value;
                    this.setState({
                        update: true
                    });
                }}
                placeholder={this.state.attributesFilters[`index${key}`].description}
                style={{ display: 'block', width: '50%', textTransform: 'capitalize' }}
                value={this.state.attributesFilters[`index${key}`].current}
            >
                {this.state.attributesFilters[`index${key}`].values.map((option, i) => (
                    <Option key={i} value={option}>
                        {option}
                    </Option>
                ))}
            </Select>
        );
    }

    filterDataSourceByAttribute(data, key) {
        const { attributesFilters } = this.state;
        if (attributesFilters[`index${key}`] && attributesFilters[`index${key}`].current) {
            data = data.filter(elem => {
                const dataAttributes = elem.attributes.find(
                    attr => attr.description == attributesFilters[`index${key}`].description
                );
                if (
                    dataAttributes &&
                    dataAttributes.value == attributesFilters[`index${key}`].current
                ) {
                    return true;
                }

                return false;
            });
        }

        return data;
    }

    fetchData(warehouseId) {
        if (this.props.codeSearch) {
            const that = this;
            const token = localStorage.getItem('_my.carbook.pro_token');
            let url = API_URL;
            let params = `/tecdoc/replacements?query=${String(this.props.codeFilter).replace(
                ' ',
                ''
            )}`;
            // if(this.props.storeGroupId) params += `&storeGroupId=${this.props.storeGroupId}`
            if (this.props.brandId) params += `&brandIds=[${this.props.brandId}]`;
            url += params;
            fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        return Promise.reject(new Error(response.statusText));
                    }

                    return Promise.resolve(response);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    let brandOptions = [];
                    const storeOptions = [];
                    const defaultBrand = [];
                    const brandsWithSupplier = [];
                    const otherBrands = [];
                    data.map((elem, i) => {
                        elem.key = i;
                        if (elem.price) {
                            elem.productId = elem.price.id;
                            elem.store = elem.price.store;
                            elem.purchasePrice = elem.price.purchasePrice;
                            elem.businessSupplierId = elem.price.businessSupplierId;
                            elem.businessSupplierName = elem.price.businessSupplierName;
                            elem.salePrice =
                                elem.sellingPrice ||
                                elem.price.purchasePrice *
                                    (elem.price.markup ? elem.price.markup : 1.4);
                        }
                        if (brandOptions.findIndex(brand => brand.id == elem.supplierId) == -1) {
                            if (
                                that.state.brandFilter.length == 0 &&
                                that.props.brandFilter == elem.supplierName
                            ) {
                                that.state.brandFilter.push(elem.supplierId);
                            }
                            brandOptions.push({
                                id: elem.supplierId,
                                name: elem.supplierName
                            });
                            if (elem.supplierName == that.props.defaultBrandName) {
                                defaultBrand.push({
                                    id: elem.supplierId,
                                    name: elem.supplierName
                                });
                            } else if (elem.price) {
                                brandsWithSupplier.push({
                                    id: elem.supplierId,
                                    name: elem.supplierName
                                });
                            } else {
                                otherBrands.push({
                                    id: elem.supplierId,
                                    name: elem.supplierName
                                });
                            }
                        }
                        if (storeOptions.findIndex(store => store.id == elem.storeGroupId) == -1) {
                            storeOptions.push({
                                id: elem.storeGroupId,
                                name: elem.storeGroupName
                            });
                        }
                    });
                    brandsWithSupplier.sort((a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
                    otherBrands.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
                    brandOptions = [...defaultBrand, ...brandsWithSupplier, ...otherBrands];
                    that.setState({
                        fetched: true,
                        dataSource: data,
                        brandOptions,
                        storeOptions
                    });
                })
                .catch(function (error) {
                    console.log('error', error);
                    that.setState({
                        fetched: true
                    });
                });
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { dataSource, storeFilter, brandFilter, codeFilter, inStock, reserveModalVisible } =
            this.state;
        let tblData = [...dataSource];

        /* --------------Filters-----------------*/
        if (storeFilter) {
            tblData = tblData.filter(
                elem =>
                    String(elem.storeGroupId)
                        .toLowerCase()
                        .indexOf(String(storeFilter).toLowerCase()) >= 0
            );
        }
        if (brandFilter.length) {
            tblData = tblData.filter(elem => brandFilter.indexOf(elem.supplierId) != -1);
        }
        if (codeFilter) {
            tblData = tblData.filter(elem => {
                const partNumber = elem.partNumber.replace(' ', '').toLowerCase();
                const barcode = String(elem.barcode).replace(' ', '').toLowerCase();
                const filter = codeFilter.replace(' ', '').toLowerCase();

                return partNumber.includes(filter) || barcode.includes(filter);
            });
        }

        if (inStock) {
            if (this.props.stockMode) tblData = tblData.filter(elem => elem.store[0]);
            else tblData = tblData.filter(elem => elem.store);
        }

        tblData = this.filterDataSourceByAttribute(tblData, 1);
        tblData = this.filterDataSourceByAttribute(tblData, 2);
        tblData = this.filterDataSourceByAttribute(tblData, 3);
        tblData = this.filterDataSourceByAttribute(tblData, 4);

        return (
            <div>
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={tblData}
                    loading={!this.state.fetched}
                    pagination={{ pageSize: 6 }}
                    rowClassName={Styles.tableRow}
                    size='small'
                />
            </div>
        );
    }
}

export class PhotoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        return (
            <div>
                <div
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    style={{ verticalAlign: 'middle' }}
                >
                    <img
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                        }}
                        src={this.props.src}
                        style={{ cursor: 'pointer' }}
                        width={80}
                    />
                </div>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='photo' />}
                    visible={this.state.visible}
                    width='45%'
                >
                    <div style={{ textAlign: 'center' }}>
                        <img
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                            }}
                            src={this.props.src}
                            style={{ cursor: 'pointer' }}
                            width='70%'
                        />
                    </div>
                    <div>
                        {this.props.attributes.map((attribute, i) => (
                            <div key={i} style={{ border: '1px solid', padding: '5pxs' }}>
                                {attribute.description}: {attribute.value}
                            </div>
                        ))}
                    </div>
                </Modal>
            </div>
        );
    }
}
