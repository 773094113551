import { Modal, Select } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import book from 'routes/book';
import { fetchAPI } from 'utils';

const { Option } = Select;

@injectIntl
export default class CopyStorageDocModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            this.setState({
                type: this.props.copyTypes[0]
            });
        }
    }

    createCopy = async () => {
        const { id } = this.props;
        const { type } = this.state;
        const data = await fetchAPI(
            'GET',
            'copy_store_doc',
            {
                id,
                type
            },
            null,
            { handleErrorInternally: true }
        );
        window.location.replace(`${book.storageDocument}/${data.id}`);
    };

    handleCancel = () => {
        this.setState({ type: undefined });
        this.props.hideModal();
    };

    handleOk = () => {
        this.createCopy();
        this.handleCancel();
    };

    render() {
        const { visible, copyTypes } = this.props;
        const { type } = this.state;

        return (
            <div>
                <Modal
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='storage_document.copy' />}
                    visible={visible}
                >
                    <div>
                        <span>
                            <FormattedMessage id='storage_document.what_to_copy' />
                        </span>
                    </div>
                    <div>
                        <Select
                            data-qa='select_search_copy_storage_doc_modal'
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={type => {
                                this.setState({ type });
                            }}
                            value={type}
                        >
                            {copyTypes.map(type => (
                                <Option key={type} data-qa='option_search_client_order_page' value={type}>
                                    {type} - <FormattedMessage id={`storage_document.docType.${type}`} />
                                </Option>
                            ))}
                        </Select>
                    </div>
                </Modal>
            </div>
        );
    }
}
