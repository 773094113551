// External
import util from 'util';
import zlib from 'zlib';

// Internal
import { accessMapping, grantToIndex, grants } from '../../../shared/scope-utils/grants_v2';

const deflate = util.promisify(zlib.deflate);
const inflate = util.promisify(zlib.inflate);

export function compressAsync(input) {
    return deflate(Buffer.from(input));
}

export function convertToHex(buffer) {
    return buffer.toString('hex');
}

export function decompressAsync(compressedBuffer) {
    return inflate(Buffer.from(compressedBuffer, 'hex'));
}

export function readUInt8(buffer, index) {
    return buffer.readUInt8(index);
}

export function hasAdminAccess(scope) {
    return readUInt8(scope, grantToIndex[grants.ADMIN].index) > 0;
}

export function hasAccess({ compressedScope, scope, grant, access }) {
    if (!scope && !compressedScope) {
        throw new Error('Scope not specified');
    }

    if (hasAdminAccess(scope)) {
        return true;
    }

    const grantIndex = grantToIndex[grant].index;
    const managerAccess = readUInt8(scope, grantIndex);
    const mappedManagerAccesses = accessMapping[managerAccess];

    // console.log(grant, access, mappedManagerAccesses.includes(access));

    return mappedManagerAccesses.includes(access);
}
