exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__modal---33Lil.ant-modal{top:40px}.styles-m__modal---33Lil .ant-modal-body{padding:12px 24px}.styles-m__listTitle---2dOm2{font-size:16px;color:var(--primary);font-weight:700}.styles-m__listItem---2SNrc{cursor:pointer}.styles-m__listItem---2SNrc:hover{background-color:rgba(var(--secondaryRGB),.2)}.styles-m__listItemActive---3SdGy{border-radius:4px}.styles-m__listItemActive---3SdGy,.styles-m__listItemActive---3SdGy:hover{background-color:var(--secondary)}.styles-m__listItemActive---3SdGy .styles-m__chartListItem---3TtZ7 .ant-list-item-meta-title{color:var(--paletteColor2);font-weight:700}.styles-m__listItemActive---3SdGy .styles-m__chartListItem---3TtZ7 .ant-list-item-meta-description{color:var(--static)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,mCACI,QACJ,CAEA,yCACI,iBACJ,CAEA,6BACI,cAAe,CACf,oBAAqB,CACrB,eACJ,CAEA,4BACI,cACJ,CAEA,kCACI,6CACJ,CAEA,kCAEI,iBACJ,CAEA,0EAEI,iCACJ,CAEA,6FACI,0BAA2B,CAC3B,eACJ,CACA,mGACI,mBACJ","file":"styles.m.css","sourcesContent":[".modal:global(.ant-modal) {\n    top: 40px;\n}\n\n.modal :global(.ant-modal-body) {\n    padding: 12px 24px 12px;\n}\n\n.listTitle {\n    font-size: 16px;\n    color: var(--primary);\n    font-weight: bold;\n}\n\n.listItem {\n    cursor: pointer;\n}\n\n.listItem:hover {\n    background-color: rgba(var(--secondaryRGB), 0.2);\n}\n\n.listItemActive {\n    /* margin: 2px; */\n    border-radius: 4px;\n}\n\n.listItemActive,\n.listItemActive:hover {\n    background-color: var(--secondary);\n}\n\n.listItemActive .chartListItem :global(.ant-list-item-meta-title) {\n    color: var(--paletteColor2);\n    font-weight: bold;\n}\n.listItemActive .chartListItem :global(.ant-list-item-meta-description) {\n    color: var(--static);\n}\n"]}]);

// Exports
exports.locals = {
	"modal": "styles-m__modal---33Lil",
	"listTitle": "styles-m__listTitle---2dOm2",
	"listItem": "styles-m__listItem---2SNrc",
	"listItemActive": "styles-m__listItemActive---3SdGy",
	"chartListItem": "styles-m__chartListItem---3TtZ7"
};