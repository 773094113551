import { Tabs } from 'antd';
import { CashActivityTable, CashBalanceTable } from 'components';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout } from 'tireFitting';

const { TabPane } = Tabs;

/**
 * This page contains modules needed to work with cash boxes and get information and statistics using them.
 * For example open, close cash boxes with connected RST, see profit, expenses and other.
 */
export default class CashBankPage extends Component {
    render() {
        return (
            <Layout title={<FormattedMessage id='navigation.cash_bank' />}>
                <Tabs defaultActiveKey='1'>
                    <TabPane key='1' tab={<FormattedMessage id='cash-table.leftovers' />}>
                        <CashBalanceTable />
                    </TabPane>

                    <TabPane key='2' tab={<FormattedMessage id='cash-table.trace' />}>
                        <CashActivityTable />
                    </TabPane>
                </Tabs>
            </Layout>
        );
    }
}
