/*
Container used to show clients and perform basic search of them.
*/
import { Table } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    orders: state.clientHotOperations.clientOrdersData.orders,
    clientOrdersFetching: state.clientHotOperations.clientOrdersFetching
});

@connect(mapStateToProps, void 0)
@injectIntl
export default class ClientOrdersContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            orders,
            clientOrdersFetching,
            intl: { formatMessage }
        } = this.props;

        return (
            <div className={Styles.tableCont}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig({ formatMessage })}
                    dataSource={orders}
                    loading={clientOrdersFetching}
                    rowClassName={() => Styles.tableRow}
                    rowKey={() => v4()}
                    scroll={{ x: 1000, y: '30vh' }}
                />
            </div>
        );
    }
}
