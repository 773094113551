import { Button, List, Modal, Table, Tree } from 'antd';
import {
    clearAttributes,
    fetchAttributes,
    fetchCrosses,
    fetchParts,
    fetchSections,
    onChangeTecDocForm
} from 'core/forms/tecDocForm/duck';
import { AddClientVehicleForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';
import { v4 } from 'uuid';

const { TreeNode } = Tree;

@injectIntl
@withReduxForm2({
    name: 'tecDoc',
    actions: {
        change: onChangeTecDocForm,
        fetchSections,
        fetchParts,
        fetchCrosses,
        fetchAttributes,
        clearAttributes
    }
})
export class TecDocForm extends Component {
    constructor(props) {
        super(props);
        this.state = { vehicle: null };
        this.columns = [
            {
                title: <FormattedMessage id='tecdoc.supplierName' />,
                dataIndex: 'supplierName',
                width: '15%'
            },
            {
                title: <FormattedMessage id='tecdoc.partNumber' />,
                dataIndex: 'partNumber',
                width: '15%'
            },
            {
                title: <FormattedMessage id='tecdoc.description' />,
                dataIndex: 'description',
                width: '20%'
            },
            {
                title: <FormattedMessage id='tecdoc.attributes' />,
                key: 'attributes',
                width: '20%',
                render: ({ supplierId, partNumber }) => (
                    <Button
                        onClick={() => {
                            this.props.fetchAttributes(partNumber, supplierId);
                        }}
                    >
                        Attributes
                    </Button>
                )
            }
        ];
    }

    onSelect = (selectedKeys, { node: { props: sectionsProps } }) => {
        const {
            dataRef: { id }
        } = sectionsProps;
        const {
            vehicle: { tecdocId }
        } = this.state;
        this.props.fetchParts(tecdocId, id);
    };

    onCrossesExpand = (expanded, { partNumber, children }) => {
        if (expanded && children.fake) {
            this.props.fetchCrosses(partNumber);
        }
    };

    onExpand = (expandedKey, { node: { props: sectionProps }, expanded }) => {
        const {
            vehicle: { tecdocId }
        } = this.state;

        if (expanded) {
            const {
                isLoaded,
                dataRef: { id }
            } = sectionProps;
            if (!isLoaded) {
                this.props.fetchSections(id, tecdocId);
            }
        }
    };

    renderTreeNodes = sections => {
        const {
            vehicle: { tecdocId }
        } = this.state;

        return sections.map(section => {
            const {
                description,
                hasChildren,
                id,
                children = [{ description: '...', id: v4() }]
            } = section;
            const isLoaded = Boolean(section.children);

            if (hasChildren) {
                return (
                    <TreeNode
                        key={`${tecdocId}-${id}`}
                        dataRef={section}
                        isLoaded={isLoaded}
                        selectable={false}
                        title={description}
                    >
                        {this.renderTreeNodes(children)}
                    </TreeNode>
                );
            }

            return (
                <TreeNode
                    key={`${tecdocId}-${id}`}
                    dataRef={section}
                    onClick={() => this.fetchParts(id)}
                    title={description}
                />
            );
        });
    };

    render() {
        const { sections = [], parts: initParts = [] } = this.props;

        const parts = initParts.map(initPart => ({
            ...initPart,
            key: v4()
        }));

        return (
            <React.Fragment>
                <AddClientVehicleForm
                    addClientVehicle={vehicle => {
                        if (vehicle.tecdocId) {
                            this.setState({ vehicle });
                            this.props.fetchSections(0, vehicle.tecdocId);
                        }
                    }}
                    onlyVehicles
                    tecdoc
                />
                {this.state.vehicle && (
                    <div>
                        <Table
                            bordered
                            columns={this.columns}
                            dataSource={parts}
                            onExpand={this.onCrossesExpand}
                            size='small'
                        />
                        <Tree onExpand={this.onExpand} onSelect={this.onSelect}>
                            {this.renderTreeNodes(sections)}
                        </Tree>
                    </div>
                )}
                {this.props.attributes && (
                    <Modal
                        cancelText={<FormattedMessage id='cancel' />}
                        maskClosable={false}
                        onCancel={() => this.props.clearAttributes()}
                        onOk={() => this.props.clearAttributes()}
                        title='TecDoc'
                        visible={this.props.attributes}
                    >
                        <List
                            bordered
                            dataSource={this.props.attributes}
                            renderItem={item => (
                                <List.Item>{`${item.description}: ${item.value}`}</List.Item>
                            )}
                        />
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
