import { DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Menu, Modal, Table, Tooltip, notification } from 'antd';
import { Catcher } from 'commons';
import HamburgerMenu from 'components/HamburgerMenu';
import dayjs from 'dayjs';
import AddInteractionRowModal from 'pages/InteractionsPage/components/AddInteractionRowModal';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PencilIcon } from 'theme';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});
@injectIntl
@connect(mapStateToProps)
export default class InteractionRowsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            selectedRowKeys: [],
            selectedRows: [],
            rowInteractionModalVisible: false
        };

        this.columns = () => [
            {
                title: () => {
                    const {
                        intl: { formatMessage },
                        id
                    } = this.props;

                    const { selectedRows, data } = this.state;
                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item key='groupSalaryTable.deleted' disabled={!selectedRows.length}>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                await fetchAPI(
                                                    'DELETE',
                                                    '/crm/interactions_doc/rows',
                                                    null,
                                                    {
                                                        documentId: id,
                                                        rowIds: selectedRows.map(({ id }) => id)
                                                    },
                                                    {
                                                        handleErrorInternally: true
                                                    }
                                                );
                                                notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.deleted'
                                                    })
                                                });
                                                this.props.fetchDocData();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} disabled={this.props.disabled} />;
                },
                key: 'actions',
                align: 'center',
                width: this.props.drawerOption ? 100 : '5%',
                render: row => {
                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item key='groupSalaryTable.delete'>
                                <div
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                await fetchAPI(
                                                    'DELETE',
                                                    '/crm/interactions_doc/rows',
                                                    null,
                                                    {
                                                        documentId: this.props.id,
                                                        rowIds: [row.id]
                                                    },
                                                    {
                                                        handleErrorInternally: true
                                                    }
                                                );
                                                notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.deleted'
                                                    })
                                                });
                                                this.props.fetchDocData();
                                            }
                                        });
                                    }}
                                >
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu} disabled={this.props.disabled}>
                            <Button
                                data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },
            {
                title: () => (
                    <div className={Styles.headerActions}>
                        <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                            <Button
                                disabled={this.props.disabled}
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    this.setState({
                                        rowInteractionModalVisible: true
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                ),
                key: 'buttonGroup',
                align: 'center',
                width: this.props.drawerOption ? 100 : '5%',
                render: row => {
                    return (
                        <div className={Styles.rowActions}>
                            <Tooltip placement='top' title={<FormattedMessage id='labors_table.add_edit_button' />}>
                                <Button
                                    data-qa='btn_add_edit_button_service_product_modal_services_table_order_page'
                                    disabled={this.props.disabled}
                                    icon={<PencilIcon />}
                                    onClick={() => {
                                        this.setState({
                                            rowInteractionModalVisible: true,
                                            rowData: row
                                        });
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='date' />,
                dataIndex: 'creationDate',
                key: 'date',
                width: this.props.drawerOption ? 100 : undefined,
                align: 'center',
                render: date => (
                    <div className={Styles.datetime}>
                        {date ? dayjs(date).format('DD.MM.YYYY') : <FormattedMessage id='long_dash' />}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='interactions.interaction_type' />,
                dataIndex: 'interactionType',
                key: 'type',
                width: this.props.drawerOption ? 100 : undefined,
                render: type => <div className={Styles.datetime}>{type || <FormattedMessage id='long_dash' />}</div>
            },
            {
                title: <FormattedMessage id='comment' />,
                dataIndex: 'comment',
                key: 'comment',
                width: this.props.drawerOption ? 150 : '25%',
                render: comment => (
                    <div className={Styles.datetime}>
                        <div>{comment || <FormattedMessage id='long_dash' />}</div>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='interactions.next_date' />,
                dataIndex: 'nextDate',
                key: 'nextDate',
                width: this.props.drawerOption ? 100 : undefined,
                align: 'center',
                render: date => (
                    <div className={Styles.datetime}>
                        {date ? dayjs(date).format('DD.MM.YYYY') : <FormattedMessage id='long_dash' />}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='orders.status' />,
                key: 'subStatus',
                width: this.props.drawerOption ? 100 : undefined,
                dataIndex: 'subStatus'
            },
            {
                title: <FormattedMessage id='sum' />,
                dataIndex: 'sum',
                key: 'sum',
                width: this.props.drawerOption ? 100 : undefined,
                align: 'right',
                render: sum => <div className={Styles.datetime}>{Number(sum || 0).toFixed(2)}</div>
            },
            {
                title: <FormattedMessage id='responsible' />,
                dataIndex: 'responsible',
                key: 'responsible',
                align: 'center',
                width: this.props.drawerOption ? 100 : undefined,
                render: responsible => (
                    <div className={Styles.datetime}>{responsible || <FormattedMessage id='long_dash' />}</div>
                )
            }
        ];

        // this.handleClientsSearch = _.debounce(query => {
        //     this.fetchClientsList(query);
        // }, 1000);
    }

    // componentDidUpdate(prevProps) {
    //     const { searchQuery } = this.props;
    //     if (prevProps.searchQuery != searchQuery && searchQuery.length > 2) {
    //         this.handleClientsSearch(searchQuery);
    //     }
    // }

    render() {
        const { tableData, id, fetchDocData, drawerOption, isMobile } = this.props;
        const { selectedRowKeys, selectedRows, rowInteractionModalVisible, rowData } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        return (
            <Catcher>
                <Table
                    columns={this.columns()}
                    dataSource={tableData}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    rowKey='id'
                    rowSelection={rowSelection}
                    scroll={!isMobile ? { x: 800, y: '60vh' } : drawerOption ? { x: 1200, y: 300 } : { x: 'auto' }}
                />
                <AddInteractionRowModal
                    closeModal={() =>
                        this.setState({
                            rowInteractionModalVisible: false,
                            rowData: undefined
                        })
                    }
                    documentId={id}
                    fetchDocData={fetchDocData}
                    open={rowInteractionModalVisible}
                    rowData={rowData}
                />
            </Catcher>
        );
    }
}
