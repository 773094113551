import { emitError } from 'core/ui/duck';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { FETCH_EMPLOYEES, fetchEmployeesSuccess, setFetchingEmployees } from './duck';

export function* fetchEmployeesSaga() {
    while (true) {
        try {
            yield take(FETCH_EMPLOYEES);

            yield put(setFetchingEmployees(true));

            const response = yield call(fetchAPI, 'GET', 'employees', { disabled: false }, null, {handleErrorInternally: true});

            yield put(fetchEmployeesSuccess({ employees: response }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingEmployees(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchEmployeesSaga)]);
}
