import { Button } from 'antd';
import { Layout } from 'commons';
import { MODALS, setModal } from 'core/modals/duck';
import { BrandsCrossModal, BrandsModal } from 'modals';
import BrandsContainer from 'pages/DirectoryBrandsPage/BrandsContainer';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    user: state.auth
});
const mapDispatchToProps = {
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class DirectoryBrandsPage extends Component {
    constructor(props) {
        super(props);
    }

    onAddBrand = () => {
        this.props.setModal(MODALS.BRANDS_MODAL, {
            mode: 'ADD'
        });
    };

    render() {
        const { user } = this.props;

        return (
            <Layout
                controls={
                    <Button
                        onClick={() => {
                            this.onAddBrand();
                        }}
                        style={{
                            margin: '0 15px 0 0',
                            fontSize: 18
                        }}
                    >
                        <FormattedMessage id='brands_page.add_brand' />
                    </Button>
                }
                description={<FormattedMessage id='brands_page.description' />}
                title={<FormattedMessage id='brands_page.title' />}
            >
                <BrandsContainer />

                <BrandsModal />
                <BrandsCrossModal />
            </Layout>
        );
    }
}
