import { DeleteOutlined, FolderAddOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip, notification } from 'antd';
import { Catcher, Numeral } from 'commons';
import AddAccDocsModal from 'pages/ACCDocumentsPage/components/AddAccDocsModal';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PencilIcon } from 'theme';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AddAccRowModal from '../AddAccRowModal';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AccountingRowsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowInteractionModalVisible: false,
            open: false,

            loading: false
        };

        this.columns = () => [
            {
                title: () => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <div className={Styles.headerActions}>
                                <Tooltip placement='top' title={<FormattedMessage id='add' />}>
                                    <Button
                                        disabled={this.props.disabled}
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                            this.setState({
                                                rowInteractionModalVisible: true
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip placement='top' title={<FormattedMessage id='acc_page.add_with_template' />}>
                                    <Button
                                        disabled={this.props.disabled}
                                        icon={<FolderAddOutlined />}
                                        onClick={() => {
                                            this.setState({
                                                open: true
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    );
                },
                key: 'buttonGroup',
                align: 'center',
                width: '10%',
                render: row => {
                    const { tableData, user } = this.props;
                    const pairedRows = tableData.filter(({ operationId }) => row.operationId === operationId);

                    return (
                        <div className={Styles.rowActions}>
                            <Tooltip placement='top' title={<FormattedMessage id='labors_table.add_edit_button' />}>
                                <Button
                                    disabled={this.props.disabled}
                                    icon={<PencilIcon />}
                                    onClick={() => {
                                        this.setState({
                                            rowInteractionModalVisible: true,
                                            rowData: pairedRows
                                        });
                                    }}
                                />
                            </Tooltip>
                            <Popconfirm
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT,
                                        accesses.ROWO
                                    ) || this.props.disabled
                                }
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        '/general_ledger/acc_document_rows',
                                        null,
                                        {
                                            rows: pairedRows.map(({ id }) => id)
                                        },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    notification.success({
                                        message: this.props.intl.formatMessage({
                                            id: 'details_table.deleted'
                                        })
                                    });
                                    this.props.fetchDocData();
                                }}
                                title={<FormattedMessage id='add_order_form.delete_confirm' />}
                            >
                                <Button
                                    data-qa='btn_show_hamburger_menu_modal_services_table_order_page'
                                    disabled={
                                        !isGrantAccessed(
                                            user,
                                            grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT,
                                            accesses.ROWO
                                        ) || this.props.disabled
                                    }
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='entry_table.account_id' />,
                key: 'accountName',
                width: '25%',
                render: row => <div>{`(#${row.accountId}) ${row.accountName}`}</div>
            },
            {
                title: <FormattedMessage id='entry_table.operation_type' />,
                key: 'type',
                width: '20%',
                render: row => (
                    <div>
                        <FormattedMessage id={`entries_tab.${row.type}`} />
                    </div>
                )
            },
            {
                title: <FormattedMessage id='storage.counterparty' />,
                key: 'counterpart',

                width: '15%',
                render: row => <div>{row.counterpartName}</div>
            },
            {
                title: <FormattedMessage id='sum' />,
                key: 'sum',
                align: 'right',
                width: '10%',
                render: row => (
                    <div>
                        <Numeral mask='0,0.00'>{Number(row.amount)}</Numeral>
                    </div>
                )
            }
        ];

        // this.handleClientsSearch = _.debounce(query => {
        //     this.fetchClientsList(query);
        // }, 1000);
    }

    // componentDidUpdate(prevProps) {
    //     const { searchQuery } = this.props;
    //     if (prevProps.searchQuery != searchQuery && searchQuery.length > 2) {
    //         this.handleClientsSearch(searchQuery);
    //     }
    // }

    render() {
        const { tableData, id, fetchDocData, docData } = this.props;
        const { rowInteractionModalVisible, rowData, open, loading } = this.state;

        return (
            <Catcher>
                <Table
                    columns={this.columns()}
                    dataSource={tableData}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    rowKey='id'
                    scroll={{
                        x: 800,
                        y: '60vh'
                    }}

                    // scroll={ { y: 200 } }
                />
                <AddAccRowModal
                    closeModal={() =>
                        this.setState({
                            rowInteractionModalVisible: false,
                            rowData: undefined
                        })
                    }
                    docData={docData}
                    fetchDocData={fetchDocData}
                    id={id}
                    maskClosable={false}
                    open={rowInteractionModalVisible}
                    rowData={rowData}
                />
                <AddAccDocsModal
                    docData={docData}
                    documentId={id}
                    fetchAccDocs={fetchDocData}
                    hideModal={() => {
                        this.setState({
                            open: false
                        });
                    }}
                    loading={loading}
                    open={open}
                    operationType={docData && docData.operationType}
                />
            </Catcher>
        );
    }
}
