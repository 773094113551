import { BankOutlined } from '@ant-design/icons';
import { Button, Popover, Select } from 'antd';
import { updateUser } from 'core/auth/duck';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const mapStateToProps = state => ({
    user: state.auth,
    businessRequisites: selectBusinessRequisites(state)
});

const mapDispatchToProps = {
    updateUser,
    fetchBusinessRequisites
};

const GlobalRequisitesSelector = ({ businessRequisites, fetchBusinessRequisites, user, updateUser, intl }) => {
    const [open, setOpen] = useState(false);

    const handleRequisiteChange = async (businessRequisitesId = null) => {
        const prevReqisites = user.businessRequisites;
        try {
            updateUser({ ...user, businessRequisitesId });
            await fetchAPI(
                'PUT',
                `employee/post/settings/${user.employeeId}`,
                null,
                { businessRequisitesId },
                { handleErrorInternally: true }
            );
            setTimeout(() => setOpen(false), 1000);
        } catch (e) {
            updateUser({ ...user, businessRequisitesId: prevReqisites });
        }
    };

    useEffect(() => {
        open && !(businessRequisites||[]).length && fetchBusinessRequisites();
    }, [open, businessRequisites, fetchBusinessRequisites]);

    return (
        <Popover
            content={
                <Select
                    allowClear
                    fieldNames={{ label: 'name', value: 'id' }}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={handleRequisiteChange}
                    optionFilterProp='children'
                    options={(businessRequisites || []).filter(({ enabled }) => enabled)}
                    placeholder={intl.formatMessage({ id: 'directory_page.requisites' })}
                    showSearch
                    style={{ minWidth: 280 }}
                    value={user.businessRequisitesId}
                />
            }
            onOpenChange={setOpen}
            open={open}
            placement='right'
            trigger={['click']}
        >
            <Button icon={<BankOutlined />} title={intl.formatMessage({ id: 'directory_page.requisites' })} />
        </Popover>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GlobalRequisitesSelector));
