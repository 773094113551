import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchRequest } from './api';
import { saga as changeMileageModalSaga } from './changeMileageModal/saga';
import {
    fetchAgreementsSuccess,
    fetchAppurtenancesSuccess,
    fetchLaborsSuccess,
    fetchRecommendationsSuccess,
    fetchVehicleSuccess,
    FETCH_AGREEMENTS,
    FETCH_APPURTENANCES,
    FETCH_LABORS,
    FETCH_RECOMMENDATIONS,
    FETCH_VEHICLE,
    selectVehicleId,
    setFetchingAgreements,
    setFetchingAppurtenances,
    setFetchingLabors,
    setFetchingRecommendations,
    setFetchingVehicle,
    setFetchingVehicleClient
} from './duck';
import { saga as inspectionIntervalsSaga } from './inspectionIntervals/saga';
import { saga as ordersSaga } from './orders/saga';
import { saga as registerForServiceModalModuleSaga } from './registerForServiceModal/saga';

export function* fetchVehicleSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE);

            const vehicleId = yield select(selectVehicleId);

            yield put(setFetchingVehicle(true));
            yield put(setFetchingVehicleClient(true));

            const vehicle = yield call(fetchRequest, 'GET', `clients/vehicles/${vehicleId}`);

            if (vehicle.clientId) {
                const client = yield call(fetchRequest, 'GET', `clients/${vehicle.clientId}`);
                yield put(fetchVehicleSuccess({ vehicle, client }));
            } else {
                yield put(fetchVehicleSuccess({ vehicle }));
            }
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingVehicle(false));
            yield put(setFetchingVehicleClient(false));
        }
    }
}
export function* fetchRecommendationsSaga() {
    while (true) {
        try {
            yield take(FETCH_RECOMMENDATIONS);

            yield put(setFetchingRecommendations(true));

            const vehicleId = yield select(selectVehicleId);

            const { recommendations } = yield call(fetchRequest, 'GET', `/orders/recommendations/${vehicleId}`);

            yield put(fetchRecommendationsSuccess({ recommendations }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingRecommendations(false));
        }
    }
}

export function* fetchLaborsSaga() {
    while (true) {
        try {
            yield take(FETCH_LABORS);

            yield put(setFetchingLabors(true));

            const vehicleId = yield select(selectVehicleId);

            const { labors } = yield call(fetchRequest, 'GET', `/orders/labors/${vehicleId}`);

            yield put(fetchLaborsSuccess({ labors }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingLabors(false));
        }
    }
}

export function* fetchAppurtenancesSaga() {
    while (true) {
        try {
            yield take(FETCH_APPURTENANCES);

            yield put(setFetchingAppurtenances(true));

            const vehicleId = yield select(selectVehicleId);

            const { appurtenances } = yield call(fetchRequest, 'GET', `/orders/appurtenances/${vehicleId}`);

            yield put(fetchAppurtenancesSuccess({ appurtenances }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingAppurtenances(false));
        }
    }
}

export function* fetchAgreementsSaga() {
    while (true) {
        try {
            yield take(FETCH_AGREEMENTS);

            yield put(setFetchingAgreements(true));

            const vehicleId = yield select(selectVehicleId);

            const { agreements } = yield call(fetchRequest, 'GET', 'orders/get_agreement_links_by_vehicle_id', {
                vehicleId
            });

            yield put(fetchAgreementsSuccess({ agreements }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingAgreements(false));
        }
    }
}

export function* saga() {
    yield all([
        call(fetchVehicleSaga),
        call(fetchRecommendationsSaga),
        call(fetchAgreementsSaga),
        call(fetchLaborsSaga),
        call(fetchAppurtenancesSaga),

        ordersSaga(),
        inspectionIntervalsSaga(),
        changeMileageModalSaga(),
        registerForServiceModalModuleSaga()
    ]);
}
