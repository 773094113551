/* eslint-disable max-classes-per-file */
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import React, { Component } from 'react';

class AgreementResult extends Component {
    componentDidMount() {
        /* setTimeout(()=>{
            window.close();
        }, 5000); */
    }

    render() {
        return (
            <div
                style={{
                    display: 'inline-block',
                    margin: 'auto',
                    textAlign: 'center'
                }}
            >
                {this.props.status == 'success' ? (
                    <CheckCircleFilled
                        style={{
                            fontSize: '72px',
                            color: '#52c41a',
                            marginBottom: '24px'
                        }}
                    />
                ) : (
                    <WarningFilled
                        style={{
                            fontSize: '72px',
                            color: '#faad14',
                            marginBottom: '24px'
                        }}
                    />
                )}
                <div
                    style={{
                        color: 'rgba(0, 0, 0, 0.85)',
                        fontSize: '24px',
                        lineHeight: 1.8
                    }}
                >
                    {this.props.title}
                </div>
                <div
                    style={{
                        color: 'rgba(0, 0, 0, 0.45)',
                        fontSize: '14px',
                        lineHeight: 1.6
                    }}
                >
                    {this.props.subtitle}
                </div>
            </div>
        );
    }
}

export default AgreementResult;
