import { Table } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

const LogsMainTable = ({ logs, count, page, setPage, setPageSize, intl: { formatMessage }, isMobile }) => {
    const logsColumns = useMemo(
        () => [
            {
                title: formatMessage({ id: 'date' }),
                dataIndex: 'auditDate',
                render: date => dayjs(date).format('DD.MM.YYYY HH:mm')
            },
            {
                title: formatMessage({ id: 'locations.action' }),
                dataIndex: 'type',
                render: type => formatMessage({ id: `logs-table.v2.${type}` })
            },
            {
                title: formatMessage({ id: 'responsible' }),
                dataIndex: 'responsibleName'
            },
            {
                title: formatMessage({ id: 'storage.type' }),
                dataIndex: 'dataKey',
                render: type => formatMessage({ id: `types.${type}` })
            }
        ],
        [formatMessage]
    );

    const expandedRowRender = ({ stateBefore, stateAfter, type, dataKey }, index) => {
        let dataSource = [];
        let columns = [];
        if (type === 'UPDATE' && dataKey !== '') {
            // has both stateBefore and stateAfter
            const keys = Object.keys(stateAfter);

            const states = [
                {
                    title: formatMessage({ id: 'after_change' }),
                    dataIndex: 'after',
                    onCell: () => ({ style: { color: 'var(--green)' } })
                }
            ];
            stateBefore &&
                states.unshift({
                    title: formatMessage({ id: 'before_change' }),
                    dataIndex: 'before',
                    onCell: () => ({ style: { color: 'var(--disabled)' } })
                });

            columns = [
                {
                    title: formatMessage({ id: 'field' }),
                    dataIndex: 'field',
                    render: key => {
                        return key === 'sum' ? (
                            <FormattedMessage id='employee_salary_rules.sal' />
                        ) : (
                            <FormattedMessage id={`logs.${key}`} />
                        );
                    }
                },
                ...states
            ];

            dataSource = keys.map(key => ({
                field: key,
                after: stateAfter[key],
                id: key,
                ...(stateBefore && { before: stateBefore[key] })
            }));
        } else {
            if (dataKey === 'ROWS') {
                columns = [
                    {
                        title: formatMessage({ id: 'locations.type' }),
                        dataIndex: 'type',
                        render: type => (
                            <div>
                                {<FormattedMessage id={`employee_salary_rules.${type}`} /> || (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </div>
                        )
                    },
                    {
                        title: formatMessage({ id: 'employee_salary_rules.sal' }),
                        key: 'salary',
                        dataIndex: 'salary',
                        render: (salary, row) => (
                            <div
                                style={{
                                    color: row.creationType == 'AUTO' ? 'black' : 'red'
                                }}
                            >
                                {Number(salary).toFixed(2)}
                            </div>
                        )
                    },
                    {
                        title: formatMessage({ id: 'employee_salary_rules.creation_type' }),
                        dataIndex: 'creationType'
                    }
                ];
            } else if (dataKey === 'SERVICES') {
                columns = [
                    {
                        title: formatMessage({ id: 'directories.orders' }),
                        dataIndex: 'orderId'
                    },
                    {
                        title: formatMessage({ id: 'name_2' }),
                        dataIndex: 'name'
                    },
                    {
                        title: formatMessage({ id: 'order_docs.quantity' }),
                        dataIndex: 'count'
                    },
                    {
                        title: formatMessage({ id: 'price' }),
                        dataIndex: 'price'
                    },
                    {
                        title: formatMessage({ id: 'sum' }),
                        dataIndex: 'sum'
                    }
                ];
            }
            dataSource = stateAfter;
        }

        return (
            <Table
                bordered
                columns={columns}
                dataSource={dataSource}
                rowKey={({ id, code }) => `${id || code}_${index}`}
                size='small'
            />
        );
    };

    return (
        <Table
            bordered
            columns={logsColumns}
            dataSource={logs}
            expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ['0']
            }}
            pagination={{
                size: 'small',
                total: count,
                hideOnSinglePage: true,
                current: page,
                onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                }
            }}
            rowKey='id'
            scroll={!isMobile ? {} : { x: '1000' }}
            size='small'
        />
    );
};

export default connect(mapStateToProps)(injectIntl(LogsMainTable));
