import { Table } from 'antd';
import {
    fetchProductSuppliers,
    selectProductSuppliersLoading,
    selectStoreProduct,
    selectStoreProductSuppliers
} from 'core/storage/products';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { v4 } from 'uuid';
import { columnsConfig } from './config';

const mapStateToProps = state => ({
    user: state.auth,
    product: selectStoreProduct(state),
    suppliers: selectStoreProductSuppliers(state),
    suppliersLoading: selectProductSuppliersLoading(state)
});

const mapDispatchToProps = {
    fetchProductSuppliers
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SuppliersTable extends React.Component {
    componentDidMount() {
        this.props.fetchProductSuppliers();
    }

    render() {
        const { suppliers, suppliersLoading, supplierTabDisabled } = this.props;

        return (
            <div>
                <Table
                    bordered
                    columns={columnsConfig({
                        supplierTabDisabled
                    })}
                    dataSource={suppliers}
                    loading={suppliersLoading}
                    pagination={false}
                    rowKey={() => v4()}
                    size='small'
                />
            </div>
        );
    }
}
