exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__avgRating---2M0uY{margin-bottom:24px;font-size:24px}.styles-m__avgRatingStars---3ZWzX{margin-left:24px}.styles-m__employeeFeedbackTable---2EwzJ td{vertical-align:middle}.styles-m__link---17xe0{color:var(--link)}.styles-m__comment---75PbF{width:320px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.styles-m__checkBoxMargin---3MOnb{margin-left:8px;min-width:40px}.styles-m__partStyle---3dcO9{margin-bottom:22px}.styles-m__okBtnStyle---3qVE9{margin-top:46px;display:flex;justify-content:end}.styles-m__blockStyle---DBZyb{display:flex;justify-content:space-around;margin-top:28px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,6BACI,kBAAmB,CACnB,cACJ,CAEA,kCACI,gBACJ,CAEA,4CACI,qBACJ,CAEA,wBACI,iBACJ,CAEA,2BACI,WAAY,CACZ,kBAAmB,CACnB,eAAgB,CAChB,sBACJ,CAEA,kCACI,eAAgB,CAChB,cACJ,CAEA,6BACI,kBACJ,CAEA,8BACI,eAAgB,CAChB,YAAa,CACb,mBACJ,CAEA,8BACI,YAAa,CACb,4BAA6B,CAC7B,eACJ","file":"styles.m.css","sourcesContent":[".avgRating {\n    margin-bottom: 24px;\n    font-size: 24px;\n}\n\n.avgRatingStars {\n    margin-left: 24px;\n}\n\n.employeeFeedbackTable td {\n    vertical-align: middle;\n}\n\n.link {\n    color: var(--link);\n}\n\n.comment {\n    width: 320px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.checkBoxMargin {\n    margin-left: 8px;\n    min-width: 40px;\n}\n\n.partStyle {\n    margin-bottom: 22px;\n}\n\n.okBtnStyle {\n    margin-top: 46px;\n    display: flex;\n    justify-content: end;\n}\n\n.blockStyle {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 28px;\n}\n"]}]);

// Exports
exports.locals = {
	"avgRating": "styles-m__avgRating---2M0uY",
	"avgRatingStars": "styles-m__avgRatingStars---3ZWzX",
	"employeeFeedbackTable": "styles-m__employeeFeedbackTable---2EwzJ",
	"link": "styles-m__link---17xe0",
	"comment": "styles-m__comment---75PbF",
	"checkBoxMargin": "styles-m__checkBoxMargin---3MOnb",
	"partStyle": "styles-m__partStyle---3dcO9",
	"okBtnStyle": "styles-m__okBtnStyle---3qVE9",
	"blockStyle": "styles-m__blockStyle---DBZyb"
};