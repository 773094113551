import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Select, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import AddGroupModal from '../AddGroupModal';

const { Option } = Select;

const GroupsDirectoryTab = ({ intl, user }) => {
    const [groups, setGroups] = useState([]);
    const [allPackages, setPackages] = useState([]);
    const [visible, setVisibleModal] = useState(false);
    const [edit, setEdit] = useState();
    const [searchQuery, setSearchQuery] = useState('');
    const [filtredGroup, setFilteredGroups] = useState([]);

    const fetchGroups = useMemo(
        () => async () => {
            const res = await fetchAPI('GET', '/billing/groups', null, null, {
                handleErrorInternally: true
            });

            setGroups(res);
        },
        []
    );

    const fetchAllPackages = async () => {
        const res = await fetchAPI('GET', '/billing/packages', null, null, {
            handleErrorInternally: true
        });

        setPackages(res);
    };

    const editRow = row => {
        setVisibleModal(true);
        setEdit(row);
    };

    const deleteRow = async row => {
        await fetchAPI('DELETE', `/billing/groups/${row.id}`, null, null, {
            handleErrorInternally: true
        });

        fetchGroups();
    };

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='monetization.name' />,
                key: 'name',
                dataIndex: 'name',
                width: '10%'
            },
            {
                title: <FormattedMessage id='monetization.package' />,
                key: 'package',
                dataIndex: 'package_name',
                width: '10%'
            },
            {
                title: <FormattedMessage id='monetization.radius' />,
                key: 'radius',
                dataIndex: 'radius',
                align: 'right',
                width: '10%'
            },
            {
                title: <FormattedMessage id='monetization.orders_amount' />,
                key: 'orders_amount',
                dataIndex: 'max_count_orders',
                align: 'right',
                width: '10%'
            },
            {
                title: <FormattedMessage id='monetization.priority' />,
                dataIndex: 'priority',
                key: 'priority',
                align: 'right',
                width: '10%'
            },
            {
                title: <FormattedMessage id='monetization.calls_amount' />,
                key: 'calls_amount',
                dataIndex: 'max_count_calls',
                align: 'right',
                width: '10%'
            },
            {
                key: 'actions',
                width: '5%',
                render: row => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: ' space-around'
                            }}
                        >
                            <Button icon={<EditOutlined />} onClick={() => editRow(row)} />
                            <Popconfirm
                                onConfirm={() => deleteRow(row)}
                                title={`${intl.formatMessage({ id: 'delete' })} ?`}
                            >
                                <Button icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </div>
                    );
                }
            }
        ];
    }, []);

    const handleSearch = event => {
        const query = event.target.value;
        setSearchQuery(query);

        // Filter the data based on the search query
        const filtered = groups.filter(
            item => item.name !== null && item.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredGroups(filtered);
    };

    const renderNestedTable = useCallback(
        roots => {
            const tableProps = {
                dataSource: roots,
                columns,
                rowKey: 'id'
            };

            return <Table {...tableProps} bordered rowKey='id' />;
        },
        [columns]
    );

    useEffect(() => {
        fetchGroups();
    }, [fetchGroups]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginBottom: 8
                }}
            >
                <Input
                    onChange={event => handleSearch(event)}
                    placeholder={intl.formatMessage({
                        id: 'search'
                    })}
                    style={{
                        width: '100%'
                    }}
                    value={searchQuery}
                />
                <Button icon={<PlusOutlined />} onClick={() => setVisibleModal(true)} />
            </div>
            {renderNestedTable(!searchQuery ? groups : filtredGroup)}
            <AddGroupModal
                allPackages={allPackages}
                businessId={user ? user.businessId : null}
                edit={edit}
                fetchAllPackages={fetchAllPackages}
                fetchGroups={fetchGroups}
                hideModal={() => {
                    setVisibleModal(false);
                    setEdit(undefined);
                }}
                open={visible}
            />
        </div>
    );
};

export default injectIntl(GroupsDirectoryTab);
