import { Select } from 'antd';
import {
    DecoratedAutoComplete,
    // DecoratedInputNumber,
    DecoratedInput,
    DecoratedTreeSelect
} from 'forms/DecoratedFields';
import { MeasureUnitSelect, PriceGroupSelect } from 'forms/_formkit';
import _ from 'lodash';
import React from 'react';
import Styles from './styles.m.css';

const { Option } = Select;

export function columnsConfig(
    dataSource,
    form,
    formatMessage,
    storeGroups,
    priceGroups,
    brands,
    setBrandsSearchQuery
) {
    const { getFieldDecorator, setFieldsValue } = form;

    const code = {
        title: formatMessage({
            id: 'storage.product_code'
        }),
        dataIndex: 'code',
        width: 'auto',
        render: (code, data, index) => (
            <React.Fragment>
                <DecoratedInput
                    cnStyles={data.alreadyExists && Styles.duplicate}
                    disabled={!_.isNil(data.alreadyExists)}
                    field={`${index}.code`}
                    fields={{}}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={code}
                    rules={[
                        {
                            required: true,
                            message: 'required!'
                        }
                    ]}
                />
                <DecoratedInput
                    field={`${index}.alreadyExists`}
                    fields={{}}
                    getFieldDecorator={getFieldDecorator}
                    hiddeninput='hiddeninput'
                    initialValue={data.alreadyExists}
                />
            </React.Fragment>
        )
    };

    const productGroup = {
        title: formatMessage({
            id: 'storage.product_group'
        }),
        dataIndex: 'groupId',
        width: '10%',
        render: (groupId, data, index) => (
            <DecoratedTreeSelect
                allowClear={false}
                cnStyles={data.alreadyExists && Styles.duplicate}
                disabled={!_.isNil(data.alreadyExists)}
                field={`${index}.groupId`}
                fields={{}}
                formItem
                getFieldDecorator={getFieldDecorator}
                initialValue={groupId}
                rules={[
                    {
                        required: true,
                        message: 'required!'
                    }
                ]}
                treeDataNodes={storeGroups}
            />
        )
    };

    const measureUnit = {
        title: formatMessage({
            id: 'storage.measure_unit'
        }),
        dataIndex: 'measureUnit',
        width: '5%',
        render: (measureUnit, data, index) => (
            <MeasureUnitSelect
                cnStyles={data.alreadyExists && Styles.duplicate}
                disabled={!_.isNil(data.alreadyExists)}
                field={`${index}.measureUnit`}
                formatMessage={formatMessage}
                formItem
                getFieldDecorator={getFieldDecorator}
                initialValue={measureUnit}
                rules={[
                    {
                        required: true,
                        message: 'required!'
                    }
                ]}
            />
        )
    };

    const name = {
        title: formatMessage({
            id: 'storage.name'
        }),
        dataIndex: 'name',
        width: '10%',
        render: (name, data, index) => (
            <DecoratedInput
                cnStyles={data.alreadyExists && Styles.duplicate}
                disabled={!_.isNil(data.alreadyExists)}
                field={`${index}.name`}
                fields={{}}
                formItem
                getFieldDecorator={getFieldDecorator}
                initialValue={name}
                rules={[
                    {
                        required: true,
                        message: 'required!'
                    }
                ]}
            />
        )
    };

    const brandId = {
        title: formatMessage({
            id: 'storage.brand'
        }),
        dataIndex: 'brandId',
        width: '10%',
        render: (brandId, data, index) => {
            const field = brandId ? `${index}.brandId` : `${index}.brandName`;
            const hiddenField = brandId ? `${index}.brandName` : `${index}.brandId`;

            return (
                <React.Fragment>
                    <DecoratedAutoComplete
                        cnStyles={data.alreadyExists && Styles.duplicate}
                        defaultGetValueProps
                        // labelInValue
                        disabled={!_.isNil(data.alreadyExists)}
                        dropdownMatchSelectWidth={false}
                        field={field}
                        fields={{}}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={brandId ? String(brandId) : data.brandName}
                        onSearch={value => {
                            setFieldsValue({
                                [`${index}.brandId`]: null,
                                [`${index}.brandName`]: value
                            });

                            setBrandsSearchQuery(value);
                        }}
                        onSelect={value => {
                            setFieldsValue({
                                [`${index}.brandId`]: value,
                                [`${index}.brandName`]: null
                            });
                        }}
                        optionFilterProp='children'
                        optionLabelProp='children'
                        showSearch
                    >
                        {brandId ? (
                            <Option key={brandId} value={String(brandId)}>
                                {data.brandName}
                            </Option>
                        ) : null}
                        {!_.isEmpty(brands)
                            ? brands.map(({ brandName, brandId }) => (
                                  <Option key={brandId} value={String(brandId)}>
                                      {brandName || data.brandName}
                                  </Option>
                              ))
                            : null}
                    </DecoratedAutoComplete>
                    <DecoratedInput
                        field={hiddenField}
                        fields={{}}
                        getFieldDecorator={getFieldDecorator}
                        hiddeninput='hiddeninput'
                        initialValue={brandId ? data.brandName : void 0}
                    />
                </React.Fragment>
            );
        }
    };

    const tradeCode = {
        title: formatMessage({
            id: 'storage.trade_code'
        }),
        dataIndex: 'tradeCode',
        width: '10%',
        render: (tradeCode, data, index) => (
            <DecoratedInput
                cnStyles={data.alreadyExists && Styles.duplicate}
                disabled={!_.isNil(data.alreadyExists)}
                field={`${index}.tradeCode`}
                fields={{}}
                formItem
                getFieldDecorator={getFieldDecorator}
                initialValue={String(tradeCode)}
            />
        )
    };

    const certificate = {
        title: formatMessage({
            id: 'storage.certificate'
        }),
        dataIndex: 'certificate',
        width: '10%',
        render: (certificate, data, index) => (
            <DecoratedInput
                cnStyles={data.alreadyExists && Styles.duplicate}
                disabled={!_.isNil(data.alreadyExists)}
                field={`${index}.certificate`}
                fields={{}}
                formItem
                getFieldDecorator={getFieldDecorator}
                initialValue={String(certificate)}
            />
        )
    };

    const priceGroupNumber = {
        title: formatMessage({
            id: 'storage.price_group'
        }),
        dataIndex: 'priceGroupNumber',
        width: '10%',
        render: (priceGroupNumber, data, index) => {
            return (
                <PriceGroupSelect
                    cnStyles={data.alreadyExists && Styles.duplicate}
                    disabled={!_.isNil(data.alreadyExists)}
                    field={`${index}.priceGroupNumber`}
                    formatMessage={formatMessage}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={priceGroupNumber}
                    priceGroups={priceGroups}
                />
            );
        }
    };

    // const price = {
    //     title: formatMessage({
    //         id: 'storage.price',
    //     }),
    //     dataIndex: 'price',
    //     width:     'auto',
    //     render:    (price, data, index) => (
    //         <DecoratedInputNumber
    //             cnStyles={ data.alreadyExists && Styles.duplicate }
    //             disabled={ !_.isNil(data.alreadyExists) }
    //             fields={ {} }
    //             formItem
    //             getFieldDecorator={ getFieldDecorator }
    //             field={ `${index}.price` }
    //             initialValue={ price }
    //             rules={ [
    //                 {
    //                     required: true,
    //                     message:  formatMessage({
    //                         id: 'required_field',
    //                     }),
    //                 },
    //             ] }
    //         />
    //     ),
    // };

    return [
        code,
        productGroup,
        measureUnit,
        name,
        brandId,
        tradeCode,
        certificate,
        priceGroupNumber
        // price,
    ];
}
