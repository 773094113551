import { Table } from 'antd';
import { Catcher } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

/**
 * Used to display vehicles and its basic controls
 *
 * @property { Array } vehicles - Array of vehicles
 * @property { Function } removeClientVehicle - used to remove vehicle by its index
 * @property { Function({vehicle}) } openEditModal - used to open modal for editing vehicle without vehicleId
 */
@injectIntl
class ClientsVehiclesTable extends Component {
    constructor(props) {
        super(props);

        const { removeClientVehicle, intl, openEditModal } = this.props;

        this.columns = columnsConfig({
            formatMessage: intl.formatMessage,
            removeClientVehicle,
            openEditModal
        });
    }

    render() {
        const { vehicles } = this.props;
        const { columns } = this;

        return (
            <Catcher>
                <Table
                    bordered
                    className={Styles.clientsVehiclesTable}
                    columns={columns}
                    dataSource={vehicles.map((vehicle, index) => ({
                        ...vehicle,
                        index,
                        key: v4()
                    }))}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    size='small'
                />
            </Catcher>
        );
    }
}

export default ClientsVehiclesTable;
