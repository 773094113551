import dayjs from 'dayjs';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'registerForServiceModal';
const prefix = `cpb/${moduleName}`;

export const CREATE_ORDER = `${prefix}/CREATE_ORDER`;

export const SET_BEGIN_DATE = `${prefix}/SET_BEGIN_DATE`;
export const SET_BEGIN_TIME = `${prefix}/SET_BEGIN_TIME`;
export const SET_COMMENT = `${prefix}/SET_COMMENT`;

export const DEF_DATE_FROMAT = 'YYYY-MM-DD';
export const DEF_TIME_FROMAT = 'HH:mm';

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    beginDate: dayjs().format(DEF_DATE_FROMAT),
    beginTime: dayjs('09:00', DEF_TIME_FROMAT).format(DEF_TIME_FROMAT),
    comment: undefined
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_BEGIN_DATE:
            return {
                ...state,
                beginDate: payload.beginDate
            };

        case SET_BEGIN_TIME:
            return {
                ...state,
                beginTime: payload.beginTime
            };

        case SET_COMMENT:
            return {
                ...state,
                comment: payload.comment
            };

        default:
            return state;
    }
}

/** ------------------------------------- Selectors ------------------------------------- * */

export const stateSelector = state => state[moduleName];

export const selectBeginDate = state => state[moduleName].beginDate;
export const selectBeginTime = state => state[moduleName].beginTime;
export const selectComment = state => state[moduleName].comment;
/* ------------------------------------- Action Creators ------------------------------------- */

export const createOrder = ({ clientId, managerId, vehicleId }) => ({
    type: CREATE_ORDER,
    payload: { clientId, managerId, vehicleId }
});

/**
 * @param {*} params.beginDate - dayjs Object
 */
export const setBeginDate = ({ beginDate }) => ({
    type: SET_BEGIN_DATE,
    payload: { beginDate }
});

/**
 * @param {*} params.beginTime - dayjs Object
 */
export const setBeginTime = ({ beginTime }) => ({
    type: SET_BEGIN_TIME,
    payload: { beginTime }
});

export const setComment = ({ comment }) => ({
    type: SET_COMMENT,
    payload: { comment }
});
