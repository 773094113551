/* eslint-disable callback-return */
/* eslint-disable func-names */
import { fetchAPI } from 'utils';

export const getData = async callback => {
    const data = await fetchAPI('GET', 'businesses/requisites', null, null, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(data);
    }

    return data;
};

export const deleteRequisite = async (id, callback) => {
    const data = await fetchAPI('DELETE', `businesses/requisites/${id}`, null, null, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(data);
    }

    return data;
};

export const postRequisite = async (body, callback) => {
    const data = await fetchAPI('POST', 'businesses/requisites', null, body, {
        handleErrorInternally: true
    });
    if (callback) {
        callback();
    }

    return data;
};

export const updateRequisite = async (id, body, callback) => {
    const data = await fetchAPI('PUT', `businesses/requisites/${id}`, null, body, {
        handleErrorInternally: true
    });
    if (callback) {
        callback();
    }

    return data;
};

export const getClientData = async (id, callback) => {
    const { requisites } = await fetchAPI('GET', `clients/${id}`, null, null, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(requisites);
    }

    return requisites;
};

export const deleteClientRequisite = async (id, callback) => {
    const data = await fetchAPI('DELETE', `clients/requisites/${id}`, null, null, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(data);
    }

    return data;
};

export const postClientRequisite = async (body, clientId, callback) => {
    const data = await fetchAPI('POST', `clients/${clientId}/requisites`, null, body, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(data);
    }

    return data;
};

export const updateClientRequisite = async (id, body, callback) => {
    const data = await fetchAPI('PUT', `clients/requisites/${id}`, null, body, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(data);
    }

    return data;
};

export const deleteSupplierRequisite = async (id, callback) => {
    const data = await fetchAPI('DELETE', `business_suppliers/requisites/${id}`, null, null, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(data);
    }

    return data;
};

export const postSupplierRequisite = async (body, id, callback) => {
    const data = await fetchAPI('POST', `business_suppliers/${id}/requisites`, null, body, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(data);
    }

    return data;
};

export const updateSupplierRequisite = async (id, body, callback) => {
    const data = await fetchAPI('PUT', `business_suppliers/requisites/${id}`, null, body, {
        handleErrorInternally: true
    });
    if (callback) {
        callback(data);
    }

    return data;
};
