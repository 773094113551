import { Col, Row, Select, Tooltip } from 'antd';
import { CashboxRSTIcon } from 'commons';
import _ from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    fetchCashboxes,
    selectCashboxes,
    selectFetchingCashboxes,
    selectSelectedCashboxIdSecond,
    setSelectedCashboxIdSecond
} from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import { selectMode } from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    selectedCashboxId: selectSelectedCashboxIdSecond(state),
    cashboxes: selectCashboxes(state),
    fetchingCashboxes: selectFetchingCashboxes(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    fetchCashboxes,
    setSelectedCashboxIdSecond
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CashboxSelectorSecond extends React.Component {
    componentDidMount() {
        this.props.fetchCashboxes();
    }

    render() {
        const {
            cashboxes,
            selectedCashboxId,
            fetchingCashboxes,
            mode,

            setSelectedCashboxIdSecond,

            intl: { formatMessage }
        } = this.props;

        const disabled = Boolean(mode != MODES.ADD);

        const findunactiveSelectedCashbox = cashboxes.find(({ id }) => id === selectedCashboxId);

        return (
            <div>
                <Tooltip
                    placement='bottom'
                    title={<FormattedMessage id='refactored_cash_order_modal.hint_cashbox' />}
                    zIndex={2001}
                >
                    <Select
                        className={Styles.select}
                        data-qa='select_credit_card'
                        disabled={disabled || fetchingCashboxes}
                        getPopupContainer={trigger => trigger.parentNode}
                        loading={fetchingCashboxes}
                        onChange={cashboxId => setSelectedCashboxIdSecond(cashboxId)}
                        optionFilterProp='name'
                        placeholder={formatMessage({
                            id: 'refactored_cash_order_modal.from_cash_box'
                        })}
                        showSearch
                        value={
                            _.get(findunactiveSelectedCashbox, 'active')
                                ? selectedCashboxId
                                : _.get(findunactiveSelectedCashbox, 'name')
                        }
                    >
                        {cashboxes &&
                            _.map(cashboxes, (cashbox, index) => {
                                const { id, name, type, rst, isShiftOpen, onlineCashdeskState } = cashbox;

                                return (
                                    cashbox.active && (
                                        <Option key={index} disabled={rst} name={`${name} ${type}`} value={id}>
                                            <Row>
                                                <Col span={12}>{name}</Col>
                                                <Col className={Styles.cashboxType} span={6}>
                                                    {type}
                                                </Col>
                                                <Col span={6}>
                                                    <div className={Styles.cashboxRST}>
                                                        {rst ? (
                                                            <CashboxRSTIcon
                                                                isShiftOpen={isShiftOpen}
                                                                onlineCashdeskState={onlineCashdeskState}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Option>
                                    )
                                );
                            })}
                    </Select>
                </Tooltip>
            </div>
        );
    }
}
