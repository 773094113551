/* eslint-disable react/sort-comp */
import { AutoComplete, Button, Checkbox, Input, Modal, Select, Table } from 'antd';
import { AvailabilityIndicator } from 'components';
import { DetailSupplierModal } from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
class OilModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            brandOptions: [],
            brandFilter: undefined,
            productNameOptions: [],
            productNameFilter: [],
            codeOptions: [],
            codeFilter: undefined,
            inStock: false,
            typeFilter: undefined,
            saeFilter: [],
            aceaFilter: [],
            apiFilter: [],
            oeCodeFilter: [],
            oemFilter: undefined,
            filters: {
                acea: [],
                api: [],
                oeCode: [],
                oem: [],
                sae: [],
                type: []
            }
        };

        this.columns = [
            {
                title: () => (
                    <div>
                        <p>
                            <FormattedMessage id='order_form_table.detail_code' />
                        </p>
                        <p>
                            <FormattedMessage id='order_form_table.brand' /> / Линейка
                        </p>
                        <div>
                            <AutoComplete
                                allowClear
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={value => {
                                    this.setState({
                                        codeFilter: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_code'
                                })}
                                value={this.props.codeFilter}
                            >
                                {this.state.codeOptions.map((option, i) => (
                                    <Option key={i} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </AutoComplete>
                        </div>
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            filterOption={(input, option) => {
                                return (
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onChange={value => {
                                this.setState({
                                    brandFilter: value
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.brand'
                            })}
                            showSearch
                            style={{
                                minWidth: 100
                            }}
                            value={this.state.brandFilter}
                        >
                            {this.state.brandOptions.map((brand, i) => (
                                <Option key={i} value={brand.id}>
                                    {brand.name}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            mode='multiple'
                            onChange={value => {
                                this.setState({
                                    productNameFilter: value
                                });
                            }}
                            placeholder='Линейка'
                            showSearch
                            style={{
                                minWidth: 100
                            }}
                            value={this.state.productNameFilter}
                        >
                            {this.state.productNameOptions.map((product, i) => (
                                <Option key={i} value={product}>
                                    {product}
                                </Option>
                            ))}
                        </Select>
                    </div>
                ),
                key: 'code',
                dataIndex: 'partNumber',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <div>
                            <div style={{ fontWeight: 'bold' }}>{data}</div>
                            <div>
                                {elem.brandName} {elem.productName}
                            </div>
                        </div>
                    );
                }
            },
            {
                title: () => (
                    <div>
                        Тип
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            onChange={value => {
                                this.setState({
                                    typeFilter: value
                                });
                            }}
                            placeholder='Тип'
                            showSearch
                            style={{
                                minWidth: 100
                            }}
                            value={this.state.typeFilter}
                        >
                            {this.state.filters.type.map((option, i) => (
                                <Option key={i} value={option}>
                                    {option}
                                </Option>
                            ))}
                        </Select>
                    </div>
                ),
                key: 'type',
                dataIndex: 'type',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <div>
                            <div>{data}</div>
                        </div>
                    );
                }
            },
            {
                title: () => (
                    <div>
                        SAE
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            mode='multiple'
                            onChange={value => {
                                this.setState({
                                    saeFilter: value
                                });
                            }}
                            placeholder='SAE'
                            showSearch
                            style={{
                                minWidth: 100
                            }}
                            value={this.state.saeFilter}
                        >
                            {this.state.filters.sae.map((product, i) => (
                                <Option key={i} value={product}>
                                    {product}
                                </Option>
                            ))}
                        </Select>
                    </div>
                ),
                key: 'sae',
                dataIndex: 'sae',
                width: 'auto',
                render: (data, elem) => {
                    let saeCode;
                    elem.sae.map(code => {
                        if (this.state.saeFilter.indexOf(code) >= 0) {
                            saeCode = code;
                        }
                    });

                    return (
                        <div>
                            <div>
                                {this.state.saeFilter.length ? (
                                    saeCode
                                ) : elem.sae && elem.sae.length ? (
                                    elem.sae[0]
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </div>
                        </div>
                    );
                }
            },
            {
                title: () => (
                    <div>
                        ACEA / API
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            mode='multiple'
                            onChange={value => {
                                this.setState({
                                    aceaFilter: value
                                });
                            }}
                            placeholder='ACEA'
                            showSearch
                            style={{
                                minWidth: 100
                            }}
                            value={this.state.aceaFilter}
                        >
                            {this.state.filters.acea.map((product, i) => (
                                <Option key={i} value={product}>
                                    {product}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            mode='multiple'
                            onChange={value => {
                                this.setState({
                                    apiFilter: value
                                });
                            }}
                            placeholder='API'
                            showSearch
                            style={{
                                minWidth: 100
                            }}
                            value={this.state.apiFilter}
                        >
                            {this.state.filters.api.map((product, i) => (
                                <Option key={i} value={product}>
                                    {product}
                                </Option>
                            ))}
                        </Select>
                    </div>
                ),
                key: 'acea',
                width: 'auto',
                render: elem => {
                    let aceaCode;
                    elem.acea.map(code => {
                        if (this.state.aceaFilter.indexOf(code) >= 0) {
                            aceaCode = code;
                        }
                    });
                    let apiCode;
                    elem.api.map(code => {
                        if (this.state.apiFilter.indexOf(code) >= 0) {
                            apiCode = code;
                        }
                    });

                    return (
                        <div>
                            <div>
                                {this.state.aceaFilter.length ? (
                                    aceaCode
                                ) : elem.acea && elem.acea.length ? (
                                    elem.acea[0]
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </div>
                            <div>
                                {this.state.apiFilter.length ? (
                                    apiCode
                                ) : elem.api && elem.api.length ? (
                                    elem.api[0]
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </div>
                        </div>
                    );
                }
            },
            {
                title: () => (
                    <div>
                        OE / Допуск
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            onChange={value => {
                                this.setState({
                                    oemFilter: value
                                });
                            }}
                            placeholder='OE'
                            showSearch
                            style={{
                                minWidth: 100
                            }}
                            value={this.state.oemFilter}
                        >
                            {this.state.filters.oem.map((product, i) => (
                                <Option key={i} value={product}>
                                    {product}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            mode='multiple'
                            onChange={value => {
                                this.setState({
                                    oeCodeFilter: value
                                });
                            }}
                            placeholder='Допуск'
                            showSearch
                            style={{
                                minWidth: 100
                            }}
                            value={this.state.oeCodeFilter}
                        >
                            {this.state.filters.oeCode.map((product, i) => (
                                <Option key={i} value={product}>
                                    {product}
                                </Option>
                            ))}
                        </Select>
                    </div>
                ),
                key: 'oem',
                width: 'auto',
                render: elem => {
                    let oeCode;
                    elem.oeCode.map(code => {
                        if (this.state.oeCodeFilter.indexOf(code) >= 0) {
                            oeCode = code;
                        }
                    });

                    return (
                        <div>
                            <div>
                                {this.state.oemFilter
                                    ? this.state.oemFilter
                                    : elem.oem && elem.oem.length
                                    ? elem.oem[0]
                                    : null}
                            </div>
                            <div>
                                {this.state.oeCodeFilter.length
                                    ? oeCode
                                    : elem.oeCode && elem.oeCode.length
                                    ? elem.oeCode[0]
                                    : null}
                            </div>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.supplier' />,
                key: 'businessSupplierName',
                dataIndex: 'businessSupplierName',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Input
                                disabled
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.supplier'
                                })}
                                style={{ maxWidth: 180, color: 'black' }}
                                value={data}
                            />
                            <DetailSupplierModal
                                brandId={elem.brandId}
                                detailCode={elem.partNumber}
                                keyValue={elem.key}
                                setStoreSupplier={this.setSupplier}
                                storeGroupId={this.props.storeGroupId || elem.storeGroupId}
                                user={this.props.user}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                width: 'auto',
                render: data => {
                    const strVal = String(Math.round(data * 10) / 10);

                    return data ? (
                        <span>{`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'salePrice',
                dataIndex: 'salePrice',
                width: '8%',
                sorter: (a, b) => {
                    if (!this.state.inStock) {
                        this.setState({
                            inStock: true
                        });
                    }
                    if (!b.salePrice) {
                        return -1;
                    }

                    return Number(a.salePrice) - Number(b.salePrice);
                },
                sortDirections: ['descend', 'ascend'],
                render: data => {
                    const strVal = String(Math.round(data * 10) / 10);

                    return data ? (
                        <span>{`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='order_form_table.store' />
                            <div style={{ fontWeight: '400', fontSize: 12 }}>
                                <FormattedMessage id='in_stock' />
                                <Checkbox
                                    checked={this.state.inStock}
                                    onChange={() => {
                                        this.setState({
                                            inStock: !this.state.inStock
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                },
                key: 'store',
                dataIndex: 'store',
                width: '8%',
                sorter: (a, b) => {
                    const aStore = a.store ? a.store[0] : 0;
                    const bStore = b.store ? b.store[0] : 0;

                    return Number(aStore) - Number(bStore);
                },
                sortDirections: ['descend', 'ascend'],
                render: store => {
                    return <AvailabilityIndicator indexArray={store} />;
                }
            },
            {
                key: 'select',
                width: '5%',
                render: elem => {
                    return (
                        <Button
                            onClick={() => {
                                this.props.onSelect(elem);
                                this.props.setSupplier({
                                    ...elem,
                                    key: this.props.tableKey
                                });
                                this.handleCancel();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='select' />
                        </Button>
                    );
                }
            }
        ];
    }

    setSupplier = item => {
        const {
            businessSupplierId,
            businessSupplierName,
            supplierBrandId,
            purchasePrice,
            price,
            store,
            supplierOriginalCode,
            supplierProductNumber,
            supplierPartNumber,
            key,
            isFromStock,
            defaultWarehouseId,
            productId
        } = item;
        console.log(item);
        this.state.dataSource[key].businessSupplierId = businessSupplierId;
        this.state.dataSource[key].businessSupplierName = businessSupplierName;
        this.state.dataSource[key].purchasePrice = purchasePrice;
        this.state.dataSource[key].supplierBrandId = supplierBrandId;
        this.state.dataSource[key].salePrice = price;
        this.state.dataSource[key].store = store;
        this.state.dataSource[key].price.supplierOriginalCode = supplierOriginalCode;
        this.state.dataSource[key].price.supplierProductNumber = supplierProductNumber;
        this.state.dataSource[key].price.supplierPartNumber = supplierPartNumber;
        this.state.dataSource[key].price.isFromStock = isFromStock;
        this.state.dataSource[key].price.defaultWarehouseId = defaultWarehouseId;
        this.state.dataSource[key].productId = productId;
        this.setState({});
    };

    handleCancel = () => {
        this.props.hideModal();
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            if (this.props.oilModalData) {
                const oeCodeTmp = [];
                this.props.oilModalData.oeCode.map(code => {
                    if (this.state.filters.oeCode.findIndex(elem => elem == code) >= 0) {
                        oeCodeTmp.push(code);
                    }
                });
                this.setState({
                    oemFilter: this.props.oilModalData.oem,
                    oeCodeFilter: oeCodeTmp,
                    aceaFilter: this.props.oilModalData.acea,
                    apiFilter: this.props.oilModalData.api,
                    saeFilter: this.props.oilModalData.sae
                });
                this.props.clearOilData();
            }

            this.fetchData();
        }
    }

    fetchData = async () => {
        const data = await fetchAPI('GET', 'tecdoc/oils', null, null, {
            handleErrorInternally: true
        });

        const brandOptions = [];
        const productNameOptions = [];
        const codeOptions = [];
        const dataSource = data.parts.map((itm, key) => {
            let elem = { ...itm, key };
            if (!elem.acea) {
                elem.acea = [];
            }
            if (!elem.api) {
                elem.api = [];
            }
            if (!elem.sae) {
                elem.sae = [];
            }
            if (!elem.oem) {
                elem.oem = [];
            }
            if (!elem.oeCode) {
                elem.oeCode = [];
            }
            if (elem.price) {
                elem = { ...elem, ...elem.price };
                elem.productId = elem.price.id;
                elem.salePrice =
                    elem.price.purchasePrice * (elem.price.markup ? elem.price.markup : 1.4);
            }
            if (brandOptions.findIndex(brand => brand.id === elem.brandId) < 0) {
                brandOptions.push({
                    id: elem.brandId,
                    name: elem.brandName
                });
            }
            if (productNameOptions.findIndex(product => product === elem.productName) < 0) {
                productNameOptions.push(elem.productName);
            }
            if (codeOptions.findIndex(code => code === elem.partNumber) < 0) {
                codeOptions.push(elem.partNumber);
            }

            return elem;
        });
        brandOptions.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
        productNameOptions.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
        codeOptions.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
        this.setState({
            codeOptions,
            brandOptions,
            productNameOptions,
            dataSource,
            filters: data.filters,
            codeFilter: this.props.codeFilter
        });
    };

    render() {
        const {
            dataSource,
            productNameFilter,
            brandFilter,
            codeFilter,
            typeFilter,
            saeFilter,
            aceaFilter,
            apiFilter,
            oemFilter,
            oeCodeFilter,
            inStock
        } = this.state;
        const disabled =
            this.props.disabled ||
            isForbidden(this.props.user, permissions.ACCESS_TECDOC_MODAL_WINDOW);

        let tblData = [...dataSource];

        if (productNameFilter.length) {
            tblData = tblData.filter(elem => productNameFilter.indexOf(elem.productName) >= 0);
        }
        if (brandFilter) {
            tblData = tblData.filter(elem => elem.brandId == brandFilter);
        }
        if (codeFilter) {
            tblData = tblData.filter(
                elem => elem.partNumber.toLowerCase().indexOf(codeFilter.toLowerCase()) >= 0
            );
        }
        if (typeFilter) {
            tblData = tblData.filter(elem => elem.type == typeFilter);
        }
        if (saeFilter.length) {
            tblData = tblData.filter(elem => {
                let inArray = false;
                elem.sae.map(code => {
                    if (this.state.saeFilter.indexOf(code) >= 0) {
                        inArray = true;
                    }
                });

                return inArray;
            });
        }
        if (aceaFilter.length) {
            tblData = tblData.filter(elem => {
                let inArray = false;
                elem.acea.map(code => {
                    if (this.state.aceaFilter.indexOf(code) >= 0) {
                        inArray = true;
                    }
                });

                return inArray;
            });
        }
        if (apiFilter.length) {
            tblData = tblData.filter(elem => {
                let inArray = false;
                elem.api.map(code => {
                    if (this.state.apiFilter.indexOf(code) >= 0) {
                        inArray = true;
                    }
                });

                return inArray;
            });
        }
        if (oemFilter) {
            tblData = tblData.filter(elem => elem.oem.indexOf(oemFilter) >= 0);
        }
        if (oeCodeFilter.length) {
            tblData = tblData.filter(elem => {
                let inArray = false;
                elem.oeCode.map(code => {
                    if (this.state.oeCodeFilter.indexOf(code) >= 0) {
                        inArray = true;
                    }
                });

                return inArray;
            });
        }
        if (inStock) {
            tblData = tblData.filter(elem => elem.store);
        }

        return (
            <div>
                {/* <Button
                    disabled={disabled}
                    onClick={() => {
                        this.fetchData();
                        this.setState({
                            visible: true
                        });
                    }}
                    title={this.props.intl.formatMessage({ id: 'details_table.details_catalogue' })}
                    icon={<OilIcon/>}
                /> */}
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='details_table.oils_and_liquids' />}
                    visible={this.props.visible}
                    width='95%'
                >
                    <Table
                        bordered
                        columns={this.columns}
                        dataSource={tblData || []}
                        pagination={{ pageSize: 6 }}
                        rowClassName={Styles.tableRow}
                    />
                </Modal>
            </div>
        );
    }
}
export default OilModal;
