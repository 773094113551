import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';

const { RangePicker } = DatePicker;

export default class RangePickerField extends Component {
    state = {
        startDate: this.props.startDate,
        endDate: this.props.endDate
    };

    static getDerivedStateFromProps(props, state) {
        if (props.startDate !== state.startDate || props.endDate !== state.endDate) {
            return {
                startDate: props.startDate,
                endDate: props.endDate
            };
        }

        return null;
    }

    render() {
        const { ranges, onChange, loading, startDate, endDate } = this.props;

        // const defaultRanges = {
        //     Today:        [ dayjs(), dayjs() ],
        //     'This Month': [ dayjs(), dayjs().endOf('month') ],
        // };

        return (
            <RangePicker
                // ranges={ ranges || defaultRanges }
                allowClear={false}
                disabled={loading}
                format='YYYY-MM-DD'
                onChange={value => onChange(value)}
                value={[dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')]}
            />
        );
    }
}
