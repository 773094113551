import { GlobalOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { Layout } from 'commons';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, getSessionId } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import {
    AdditionalSettings,
    FeaturesSettings,
    GeneralSettings,
    MediaSettings,
    ServicesSettings,
    SpecializationSettings
} from './components';
import './styles.less';

const { TabPane } = Tabs;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

const formatdata = ({
    address,
    business_partners_networks: businessPartnersNetworks,
    business_vehicle_makes: businessVehicleMakes,
    count_stations: countStations,
    description,
    email,
    facebook_link: facebookLink,
    facebook_widget: facebookWidget,
    features,
    ga_id: googleAnalytics,
    google_link: googleLink,
    google_widget: googleWidget,
    is_car_wash: isCarWash,
    is_garage: isGarage,
    is_tire_station: isTireStation,
    language_id: language,
    latitude: mapLatitude,
    longitude: mapLongitude,
    map_zoom: mapZoom,
    meta_description: metaDescription,
    minimal_time_order: minimalTimeOrder,
    name,
    nonWorkingDays,
    owner_email: ownerEmail,
    owner_name: ownerName,
    owner_phone: ownerPhone,
    phones,
    responsible_email: responsibleEmail,
    responsible_name: responsibleName,
    responsible_phone: responsiblePhone,
    schedule,
    site,
    synonym,
    theme,
    time_to_end_working_day: timeToEndWorkingDay,
    title,
    twitter_link: twitterLink,
    vk_link: vkLink,
    vk_widget: vkWidget,
    youtube
}) => ({
    address,
    businessPartnersNetworks,
    businessVehicleMakes,
    countStations,
    description,
    email,
    facebookLink,
    facebookWidget,
    features,
    googleAnalytics,
    googleLink,
    googleWidget,
    isCarWash,
    isGarage,
    isTireStation,
    language,
    mapLatitude,
    mapLongitude,
    mapZoom,
    metaDescription,
    minimalTimeOrder,
    name,
    nonWorkingDays,
    ownerEmail,
    ownerName,
    ownerPhone,
    phones,
    responsibleEmail,
    responsibleName,
    responsiblePhone,
    schedule,
    site,
    synonym,
    theme,
    timeToEndWorkingDay,
    title,
    twitterLink,
    vkLink,
    vkWidget,
    youtube
});

const WebSettingsPage = injectIntl(({ user, intl: { formatMessage } }) => {
    const sessionId = getSessionId();
    const [data, setData] = useState();
    const [activeKey, setActiveKey] = useState();
    const isDisabledCRUD = !isGrantAccessed(user, grants.SETTINGS_WEB_SETTINGS, accesses.ROWO);
    const isDisabledCRUDOfficial = !isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_NETWORK, accesses.ROWO);

    const generalSettingsRef = useRef();

    const fetchdata = async () => {
        setData(null);
        const data = await fetchAPI('GET', '/business', null, null, { handleErrorInternally: true });
        setData(data);
    };

    const savedata = async values => {
        const payload = {
            ...formatdata(data),
            ...values
        };

        const features = {};

        payload.features.forEach(({ type, lock, name, description }) => {
            features[type] = {
                value: Boolean(lock),
                label: name,
                description
            };
        });

        payload.features = features;

        try {
            await fetchAPI('PUT', 'businesses', null, payload, {
                handleErrorInternally: true
            });
            fetchdata();
        } catch (e) {
            fetchdata();
        }
    };

    useEffect(() => {
        fetchdata();
    }, []);

    return (
        <Layout
            className='settings_page'
            controls={
                <a href={book.oldApp.settings}>
                    <Button icon={<GlobalOutlined />} size='large' />
                </a>
            }
            description={<FormattedMessage id='navigation.web_settings' />}
            title={<FormattedMessage id='navigation.settings' />}
        >
            <Tabs activeKey={activeKey} onChange={setActiveKey} tabPosition='top'>
                <TabPane
                    key='general'
                    className='settings_form_tab'
                    tab={<FormattedMessage id='settings_page.general_information' />}
                >
                    <GeneralSettings
                        ref={generalSettingsRef}
                        data={data}
                        fetchdata={fetchdata}
                        formatMessage={formatMessage}
                        isDisabledCRUD={isDisabledCRUD}
                        saveData={savedata}
                        sessionId={sessionId}
                    />
                </TabPane>
                <TabPane key='features' tab={<FormattedMessage id='settings_page.specifics' />}>
                    <FeaturesSettings
                        data={data}
                        fetchData={fetchdata}
                        formatMessage={formatMessage}
                        isDisabledCRUD={isDisabledCRUD}
                        saveData={savedata}
                        sessionId={sessionId}
                    />
                </TabPane>
                <TabPane key='services' tab={<FormattedMessage id='settings_page.services' />}>
                    <ServicesSettings
                        data={data}
                        fetchData={fetchdata}
                        formatMessage={formatMessage}
                        isDisabledCRUD={isDisabledCRUD}
                        saveData={savedata}
                        sessionId={sessionId}
                    />
                </TabPane>
                <TabPane key='media' tab={<FormattedMessage id='settings_page.media' />}>
                    <MediaSettings
                        data={data}
                        fetchData={fetchdata}
                        formatMessage={formatMessage}
                        isDisabledCRUD={isDisabledCRUD}
                        saveData={savedata}
                        sessionId={sessionId}
                    />
                </TabPane>
                <TabPane key='specialization' tab={<FormattedMessage id='settings_page.specialization' />}>
                    <SpecializationSettings
                        data={data}
                        fetchData={fetchdata}
                        formatMessage={formatMessage}
                        isDisabledCRUD={isDisabledCRUD}
                        isDisabledCRUDOfficial={isDisabledCRUDOfficial}
                        saveData={savedata}
                        sessionId={sessionId}
                    />
                </TabPane>
                <TabPane key='additional' tab={<FormattedMessage id='settings_page.additional' />}>
                    <AdditionalSettings
                        data={data}
                        fetchData={fetchdata}
                        formatMessage={formatMessage}
                        isDisabledCRUD={isDisabledCRUD}
                        saveData={savedata}
                        sessionId={sessionId}
                    />
                </TabPane>
            </Tabs>
        </Layout>
    );
});

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WebSettingsPage);
