import { Layout } from 'commons';
import { QuickRequestsContainer } from 'containers';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const QuickRequestsPage = () => {
    return (
        <Layout title={<FormattedMessage id='navigation.quick_requests' />}>
            <QuickRequestsContainer />
        </Layout>
    );
};
