// Core
import { Button } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { Layout } from 'tireFitting';
import { images } from 'utils';
import Styles from './styles.m.css';

class Exception extends Component {
    _getErrorImage = statusCode => {
        switch (statusCode) {
            case '400':
                return images.exception400;

            case '403':
                return images.exception400;

            case '404':
                return images.exception404;

            case '500':
                return images.exception500;

            default:
                return images.exception404;
        }
    };

    _getErrorText = statusCode => {
        switch (statusCode) {
            case '400':
                return 'Bad Request';

            case '403':
                return 'Forbidden ';

            case '404':
                return 'Not Found';

            case '500':
                return 'Internal Server Error';

            default:
                return 'Error!';
        }
    };

    render() {
        const { statusCode } = this.props.match.params;

        return (
            <Layout paper={false} title={<FormattedMessage id='exception-page.title' />}>
                <div className={Styles.error}>
                    <div className={Styles.errorImage}>
                        <img alt='error' src={this._getErrorImage(statusCode)} />
                    </div>
                    <div className={Styles.errorData}>
                        <h1 className={Styles.errorCode}>{statusCode}</h1>
                        <span className={Styles.errorDesc}>{this._getErrorText(statusCode)}</span>
                        <Link to={book.ordersAppointments}>
                            <Button type='primary'>
                                <FormattedMessage id='exception-page.back_to_main' />
                            </Button>
                        </Link>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Exception;
