import { emitError, setManagerRoleFetchingState } from 'core/ui/duck';
import { all, call, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchManagerRoles,
    fetchManagerRolesError,
    fetchManagerRolesSuccess,
    FETCH_MANAGER_ROLES,
    hideForms,
    setFilters,
    SET_FILTERS,
    SET_MANAGER_SEARCH_FILTER,
    SET_PAGE,
    SET_SORT,
    UPDATE_MANAGER_ROLE
} from './duck';

export function* refetchManagerRolesSaga() {
    yield put(fetchManagerRoles());
}

export function* fetchManagerRolesSaga() {
    while (true) {
        try {
            yield take(FETCH_MANAGER_ROLES);
            const getParams = yield select(state => ({
                sort: {
                    ...state.managerRole.sort,
                    page: state.managerRole.page
                },
                filters: {
                    ...state.managerRole.filters
                }
            }));

            yield put(setManagerRoleFetchingState(true));

            const data = yield call(fetchAPI, 'GET', 'managers/roles/managers', getParams);

            yield put(fetchManagerRolesSuccess(data));
        } catch (error) {
            yield put(emitError(error));
            yield put(fetchManagerRolesError());
        } finally {
            yield put(setManagerRoleFetchingState(false));
        }
    }
}

function* setSearchQuerySaga({ payload }) {
    yield delay(1000);
    yield put(setFilters({ search: payload }));
}

function* updateManagerRoleSaga({ payload: { managerId, roleIds, businessId } }) {
    const entity = { roleIds, managerId, businessId };

    yield call(fetchAPI, 'POST', 'managers/roles/assign', null, entity);

    yield put(hideForms());
    yield put(fetchManagerRoles());
}

export function* saga() {
    yield all([
        takeLatest(SET_MANAGER_SEARCH_FILTER, setSearchQuerySaga),
        call(fetchManagerRolesSaga),
        takeEvery(UPDATE_MANAGER_ROLE, updateManagerRoleSaga),
        takeEvery([SET_FILTERS, SET_SORT, SET_PAGE], refetchManagerRolesSaga)
    ]);
}
