import { AppstoreOutlined, MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { images } from 'utils';
import Styles from './styles.m.css';

@withRouter
export default class ModuleHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showControls: false
        };
    }

    render() {
        const { user, title, description, controls, collapsed, isMobile, onCollapse } = this.props;

        const { showControls } = this.state;

        return isMobile ? (
            <div className={Styles.headerMobile}>
                <div className={Styles.carBookHeaderBlock}>
                    <MenuUnfoldOutlined
                        onClick={() => {
                            onCollapse(!collapsed);
                        }}
                        style={{ color: 'black' }}
                    />
                    />
                    <img alt='logo' className={Styles.logo} src={user.primaryLogoUrl || images.carbookLogo2} />
                    <AppstoreOutlined
                        onClick={() => {
                            this.props.history.push('/');
                        }}
                        style={{ color: 'black', fontSize: 24 }}
                    />
                </div>
                <div className={Styles.headerMobileTitleBlock}>
                    <div className={Styles.headerInfo}>
                        <h1 className={Styles.title}>{title}</h1>
                        {description && <span className={Styles.description}>{description}</span>}
                    </div>
                    <div className={Styles.headerContorlsShowIcon}>
                        <MenuOutlined
                            onClick={() =>
                                this.setState({
                                    showControls: !showControls
                                })
                            }
                        />
                    </div>
                    <div className={Styles.headerContorls}>{controls}</div>
                </div>
                <div
                    className={Styles.hiddenHeaderContorls}
                    style={{
                        display: showControls ? 'flex' : 'none'
                    }}
                >
                    {controls}
                </div>
            </div>
        ) : (
            <div className={`${Styles.header} ${collapsed && Styles.headerCollapsed}`}>
                <div className={Styles.headerInfo}>
                    <h1 className={Styles.title}>{title}</h1>
                    {description && <span className={Styles.description}>{description}</span>}
                </div>
                <div className={Styles.headerContorls}>{controls}</div>
            </div>
        );
    }
}
