import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Calendar, DatePicker } from 'antd';
import React from 'react';
import Styles from './styles.m.css';

const DateCalendar = ({ disableCell, renderScheduleCell, value, changeRange, openDayModal, schedule }) => {
    const onSelect = date => {
        changeRange(date);
        const scheduleDate = schedule.filter(e => e.dayjsDate.isSame(date, 'day'))[0];
        openDayModal(date, scheduleDate);
    };
    const renderHeader = ({ value }) => {
        return (
            <div className={Styles.calendarHeader}>
                <Button icon={<LeftOutlined />} onClick={() => changeRange(value.subtract(1, 'M'))} type='text' />
                <DatePicker format='MMMM, YYYY' onChange={date => changeRange(date)} picker='month' value={value} />
                {/* <div style={{ width: '16px' }}></div>
                {value.format('YYYY')} */}
                <Button icon={<RightOutlined />} onClick={() => changeRange(value.add(1, 'M'))} type='text' />
            </div>
        );
    };

    return (
        <Calendar
            disabledDate={date => disableCell(date, value)}
            fullCellRender={date => renderScheduleCell(date, value)}
            fullscreen={false}
            headerRender={renderHeader}
            onSelect={onSelect}
            value={value}
        />
    );
};
export default DateCalendar;
