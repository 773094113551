import { DollarOutlined, ExportOutlined, ImportOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { CashboxRSTIcon, Numeral } from 'commons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { ClosedLockIcon, OpenLockIcon, ReportIcon } from 'theme';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const NOT_AVAILABLE = 'NOT_AVAILABLE';
const OPEN = 'OPEN';
const CLOSED = 'CLOSED';

/**
 * Takes path to Icon and generates customized Icon component
 * @param {*} icon    file path
 * @param {*} onClick event handler
 * @param {*} user    current user object, is used for access(disables button if access is forbidden)
 * @param {*} hint    button popup hint
 * @param {*} param0  options for button: {disabled}
 * @returns Component (Icon)
 */
function generateIcon(icon, onClick, user, hint, options, popconfirm) {
    const { disabled } = options || {};

    const popcnfrm = children => (
        <Popconfirm onConfirm={onClick} {...popconfirm}>
            {children}
        </Popconfirm>
    );

    const btn = (
        <Button
            disabled={disabled || isForbidden(user, permissions.ACCESS_OTHER_OPERATION_RST)}
            icon={icon}
            onClick={!popconfirm && onClick}
        />
    );

    const res = popconfirm ? popcnfrm(btn) : btn;

    // Use message popup if hint provided
    return hint ? <Tooltip title={hint}>{res}</Tooltip> : res;
}

export function columnsConfig(props) {
    const {
        user,
        onOpenCashOrderModal,
        openShift,
        onOpenServiceInputModal,
        onOpenServiceOutputModal,
        fetchXReport,
        closeShift,
        onOpenServiceTransferModal
    } = props;

    const numberCol = {
        title: '№',
        key: 'numberCol',
        dataIndex: 'id'
    };

    const nameCol = {
        title: <FormattedMessage id='cash-table.name' />,
        dataIndex: 'name',
        key: 'nameCol',
        render: (name, row) => {
            return (
                <Link
                    to={{
                        pathname: book.cashFlowPage,
                        state: {
                            cashboxId: row.id
                        }
                    }}
                >
                    {name}
                </Link>
            );
        }
    };

    const typeCol = {
        title: <FormattedMessage id='cash-table.type' />,
        dataIndex: 'type',
        key: 'typeCol',
        render: type => <FormattedMessage id={`cash-creation-form.type-${type}`} />
    };

    const sumCol = {
        title: <FormattedMessage id='cash-table.sum' />,
        key: 'sumCol',
        dataIndex: 'balance',
        render: key => <Numeral mask='0,0.00'>{key}</Numeral>
    };

    const isCashOrderRSTCol = {
        dataIndex: 'rst',
        key: 'isCashOrderRSTCol',
        render: (rst, obj) => {
            return rst ? (
                <CashboxRSTIcon
                    isShiftOpen={obj.isShiftOpen}
                    onlineCashdeskState={obj.onlineCashdeskState}
                />
            ) : null;
        }
    };

    const addCashOrderCol = {
        dataIndex: 'id',
        key: 'addCashOrderCol',
        render: cashboxId => {
            return (
                <Tooltip title={<FormattedMessage id='cash-table.hint_create_cash_order' />}>
                    <DollarOutlined
                        className={Styles.createCashOrderIcon}
                        onClick={() => onOpenCashOrderModal({ cashboxId })}
                    />
                </Tooltip>
            );
        }
    };

    const openShiftCol = {
        dataIndex: 'rst',
        key: 'openShiftCol',
        render: (rst, obj) => {
            return rst
                ? generateIcon(
                      <OpenLockIcon />,
                      () => openShift(obj.id, obj.type),
                      user,
                      <FormattedMessage id='cash-table.hint_open_shift' />,
                      {
                          disabled:
                              obj.onlineCashdeskState === OPEN ||
                              obj.onlineCashdeskState === NOT_AVAILABLE
                      }
                  )
                : null;
        }
    };

    const serviceInputCol = {
        dataIndex: 'rst',
        key: 'serviceInputCol',
        render: (rst, obj) => {
            return generateIcon(
                <ImportOutlined />,
                () => onOpenServiceInputModal(obj.id),
                user,
                <FormattedMessage id='cash-table.hint_service_input' />,
                {
                    disabled:
                        rst &&
                        (obj.onlineCashdeskState === CLOSED ||
                            obj.onlineCashdeskState === NOT_AVAILABLE)
                }
            );
        }
    };

    const serviceOutputCol = {
        dataIndex: 'rst',
        key: 'serviceOutputCol',
        render: (rst, obj) => {
            return generateIcon(
                <ExportOutlined />,
                () => onOpenServiceOutputModal(obj.id),
                user,
                <FormattedMessage id='cash-table.hint_service_output' />,
                {
                    disabled:
                        rst &&
                        (obj.onlineCashdeskState === CLOSED ||
                            obj.onlineCashdeskState === NOT_AVAILABLE)
                }
            );
        }
    };

    const serviceTransferCol = {
        dataIndex: 'rst',
        key: 'serviceOutputCol',
        render: (rst, obj) => {
            return generateIcon(
                <RetweetOutlined />,
                () => onOpenServiceTransferModal(obj.id),
                user,
                <FormattedMessage id='cash-table.hint_service_transfer' />,
                {
                    disabled:
                        rst &&
                        (obj.onlineCashdeskState === CLOSED ||
                            obj.onlineCashdeskState === NOT_AVAILABLE)
                }
            );
        }
    };

    const xReportCol = {
        dataIndex: 'rst',
        key: 'xReportCol',
        render: (rst, obj) => {
            return rst
                ? generateIcon(
                      <ReportIcon />,
                      () => fetchXReport(obj.id),
                      user,
                      <FormattedMessage id='cash-table.hint_x_report' />,
                      {
                          disabled:
                              obj.onlineCashdeskState === CLOSED ||
                              obj.onlineCashdeskState === NOT_AVAILABLE
                      }
                  )
                : null;
        }
    };

    const zReportCol = {
        dataIndex: 'rst',
        key: 'zReportCol',
        render: (rst, obj) => {
            return rst
                ? generateIcon(
                      <ClosedLockIcon />,
                      () => closeShift(obj.id, obj.balance),
                      user,
                      <FormattedMessage id='cash-table.hint_close_shift' />,
                      {
                          disabled:
                              obj.onlineCashdeskState === CLOSED ||
                              obj.onlineCashdeskState === NOT_AVAILABLE
                      },
                      {
                          title: (
                              <React.Fragment>
                                  <FormattedMessage id='cash-table.hint_close_shift' />?
                              </React.Fragment>
                          ),
                          okText: <FormattedMessage id='yes' />,
                          cancelText: <FormattedMessage id='no' />
                      }
                  )
                : null;
        }
    };

    const mobileNameCol = {
        title: <FormattedMessage id='cash-table.name' />,
        key: 'nameCol',
        render: row => {
            return (
                <div>
                    <Link
                        to={{
                            pathname: book.cashFlowPage,
                            state: {
                                cashboxId: row.id
                            }
                        }}
                    >
                        {row.name}
                    </Link>
                    <p style={{ color: 'var(--text2)' }}>
                        <FormattedMessage id={`cash-creation-form.type-${row.type}`} />
                    </p>
                </div>
            );
        }
    };

    return props.isMobile
        ? [mobileNameCol, sumCol, isCashOrderRSTCol]
        : [
              numberCol,
              nameCol,
              typeCol,
              sumCol,
              isCashOrderRSTCol,
              addCashOrderCol,
              openShiftCol,
              serviceInputCol,
              serviceOutputCol,
              serviceTransferCol,
              xReportCol,
              zReportCol
          ];
}
