import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { answered } from 'core/calls/config';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import book from 'routes/book';
import { goTo } from 'utils';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const defWidth = {
    date: '10%',
    status: '5%',
    order: '10%',
    caller: '10%',
    recipient: '10%',
    client: 'auto',
    waiting: '5%',
    duration: '5%',
    innerRecipient: '5%',
    record: '20%'
};

export function columnsConfig({ fetchRecordingLink, callsLinksCache, onAddClientModal }) {
    const date = {
        title: <FormattedMessage id='calls-table.date' />,
        width: defWidth.date,
        dataIndex: 'datetime',
        key: 'date',
        render: date => (
            <div className={Styles.datetime}>{dayjs(date).format('YYYY-MM-DD HH:mm')}</div>
        )
    };

    const status = {
        title: <FormattedMessage id='calls-table.status' />,
        width: defWidth.status,
        dataIndex: 'status',
        key: 'status',
        render: status => (
            <React.Fragment>
                {answered.includes(status) ? (
                    <CheckCircleOutlined
                        style={{
                            color: 'var(--secondary)',
                            fontSize: 24
                        }}
                    />
                ) : (
                    <CloseCircleOutlined
                        style={{
                            color: 'var(--warning)',
                            fontSize: 24
                        }}
                    />
                )}
            </React.Fragment>
        )
    };

    const caller = {
        title: <FormattedMessage id='calls-table.caller' />,
        width: defWidth.caller,
        dataIndex: 'caller',
        key: 'caller',
        render: caller => (
            <a className={Styles.orderLink} href={`tel:${caller}`}>
                {caller}
            </a>
        )
    };

    const recipient = {
        title: <FormattedMessage id='calls-table.recipient' />,
        width: defWidth.recipient,
        dataIndex: 'recipient',
        key: 'recipient',
        render: recipient => (
            <a className={Styles.orderLink} href={`tel:${recipient}`}>
                {recipient}
            </a>
        )
    };

    const client = {
        title: <FormattedMessage id='calls-table.client' />,
        width: defWidth.client,
        dataIndex: 'clients',
        key: 'clients',
        render: (clients, call) => {
            return !clients ? (
                <Button
                    className={Styles.createClient}
                    onClick={() => onAddClientModal({ initialPhoneNumber: call.caller })}
                    type='primary'
                >
                    <FormattedMessage id='calls-table.create_new_client' />
                </Button>
            ) : (
                clients.map(client => {
                    return (
                        <Button
                            key={v4()}
                            className={Styles.client}
                            onClick={() => goTo(`${book.client}/${client.clientId}`)}
                        >
                            {client.fullName}
                        </Button>
                    );
                })
            );
        }
    };

    const waiting = {
        title: <FormattedMessage id='calls-table.waiting' />,
        width: defWidth.waiting,
        dataIndex: 'waiting',
        key: 'waiting',
        render: waiting => <div>{waiting}</div>
    };

    const duration = {
        title: <FormattedMessage id='calls-table.duration' />,
        width: defWidth.duration,
        dataIndex: 'duration',
        key: 'duration',
        render: duration => <div>{duration}</div>
    };

    const innerRecipient = {
        title: <FormattedMessage id='calls-table.innerRecipient' />,
        width: defWidth.innerRecipient,
        dataIndex: 'innerRecipient',
        key: 'innerRecipient',
        render: innerRecipient => (
            <a className={Styles.orderLink} href={`tel:${innerRecipient}`}>
                {innerRecipient}
            </a>
        )
    };

    const record = {
        title: <FormattedMessage id='calls-table.record' />,
        width: defWidth.record,
        dataIndex: 'recordingLink',
        render: (val, call) => {
            return String(call.id) in callsLinksCache ? ( // Check if that key exists in cash memory
                callsLinksCache[call.id] ? ( // False for empty rows where key exists in the cash memory or if call was not accepted
                    <audio controls>
                        <source src={callsLinksCache[call.id]} />
                    </audio>
                ) : (
                    <FormattedMessage id='calls-table.no_record' />
                )
            ) : answered.includes(call.status) ? (
                <div>
                    <Button onClick={() => fetchRecordingLink({ callId: call.id })} type='primary'>
                        <FormattedMessage id='calls-table.show_record' />
                    </Button>
                </div>
            ) : (
                <FormattedMessage id='calls-table.no_record' />
            );
        }
    };

    return [date, status, caller, recipient, client, waiting, duration, innerRecipient, record];
}
