import { CarFilled, CheckCircleOutlined, InfoCircleOutlined, PlusOutlined, StarFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import { OwnVehicleModal } from 'modals';
import { convertLatinToCyrillic, removeCyrillicLetters } from 'modals/VehicleModal/number_utils';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, goTo, isForbidden, permissions } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import {
    fetchVehicleColors,
    fetchVehicleDataByNumber,
    fetchVehicleDataByVin,
    fetchVehicleMakes,
    fetchVehicleModels,
    fetchVehicleModifications,
    fetchVehicleTypeId,
    fetchVehicleYears,
    selectColorPatter,
    selectColors,
    selectFields,
    selectMakes,
    selectModels,
    selectModifications,
    selectYears,
    setClientId,
    setComment,
    setManagerName,
    setManagerPhone,
    setModelDropdownState,
    setPopular,
    setRegistrationName,
    setSelectType,
    setTypeId,
    setVehicleColor,
    setVehicleColorPattern,
    setVehicleMakeId,
    setVehicleModelId,
    setVehicleModificationId,
    setVehicleNumber,
    setVehicleRadius,
    setVehicleType,
    setVehicleVin,
    setVehicleYear
} from '../../redux/duck';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    fields: selectFields(state),
    years: selectYears(state),
    makes: selectMakes(state),
    models: selectModels(state),
    modifications: selectModifications(state),
    colors: selectColors(state),
    colorPattern: selectColorPatter(state)
});

const mapDispatchToProps = {
    fetchVehicleYears,
    fetchVehicleTypeId,
    fetchVehicleMakes,
    fetchVehicleModels,
    fetchVehicleModifications,
    fetchVehicleColors,
    fetchVehicleDataByVin,
    fetchVehicleDataByNumber,

    setClientId,
    setVehicleNumber,
    setVehicleVin,
    setManagerName,
    setManagerPhone,
    setRegistrationName,
    setVehicleYear,
    setVehicleMakeId,
    setVehicleModelId,
    setVehicleModificationId,
    setSelectType,
    setModelDropdownState,
    setVehicleColor,

    setVehicleType,
    setVehicleRadius,
    setComment,
    setTypeId,
    setPopular,
    setVehicleColorPattern
};

const { Option } = Select;

/**
 * This form is used to create vehicle, it contains all required actions to fetch data and create vehicles
 *
 * @param {Function} getFormRefCB -  callback, takes one argument(form reference)
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AddVehicleFormClass extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = { visibleOwnVehicleModal: false, refUsed: false, colorBadge: undefined };

        // Callback to get form instance (warppedComponentRef does not work)
    }

    componentDidMount() {
        const { clientId } = this.props.modalProps;
        this.props.fetchVehicleYears();
        this.props.fetchVehicleColors();
        this.props.setClientId({ clientId });
    }

    isDisabled() {
        const { user } = this.props;

        if (isForbidden(user, permissions.ACCESS_ORDER_DETAILS_VIN)) return true;

        return !String(_.get(this.props, 'fields.number') || ' ')
            .replace(/\s/g, '')
            .match(/^[ABEIKMHOPCTXАВЕІКМНОРСТХ]{2}[0-9]{4}[ABEIKMHOPCTXАВЕІКМНОРСТХ]{2}$/);
    }

    render() {
        const {
            fields,
            years,
            makes,
            models,
            modifications,
            colors,
            vehicleTypes,

            fetchVehicleMakes,
            fetchVehicleModels,
            fetchVehicleModifications,
            fetchVehicleDataByVin,
            fetchVehicleDataByNumber,

            setVehicleNumber,
            setVehicleVin,
            setManagerName,
            setRegistrationName,
            setComment,
            setManagerPhone,
            setVehicleYear,
            setVehicleMakeId,
            setVehicleModelId,
            setVehicleModificationId,
            setModelDropdownState,
            setVehicleColor,
            setVehicleType,
            setVehicleRadius,
            setVehicleColorPattern,
            form,
            intl: { formatMessage },

            user,
            isMobile,
            setTypeId,
            setPopular,
            setVehicleData,
            mode
        } = this.props;

        const {
            visibleOwnVehicleModal,
            refUsed,
            modelSearch,
            modificationSearch,
            colorBadge,
            colorId,
            makesLabel,
            modelLabel,
            modificationLabel
        } = this.state;

        if (!refUsed && this.formRef.current) {
            const { getFormRefCB } = this.props;
            getFormRefCB && getFormRefCB(this.formRef.current);
            this.setState({ refUsed: true });
        }

        if (this.formRef.current) {
            this.formRef.current.setFieldsValue(fields);
        }

        const addButton = (
            <Button
                className={Styles.addButton}
                disabled={!isGrantAccessed(user, grants.ADMINISTRATION_CAR_MANAGEMENT, accesses.ROWO)}
                icon={<PlusOutlined style={{ fontSize: 18 }} />}
                onClick={() => {
                    this.setState({
                        visibleOwnVehicleModal: true
                    });
                    if (modelSearch) {
                        setVehicleModelId({ modelId: undefined });
                    }
                    if (modificationSearch) {
                        setVehicleModificationId({ modificationId: undefined });
                    }
                }}
                title={formatMessage({
                    id: 'add_client_form.own_vehicle_modal'
                })}
                type='dashed'
            />
        );

        return (
            <div>
                <Form ref={this.formRef} name='addVehicle' shouldUpdate>
                    {!setVehicleData && (
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.vehicle_type' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='type'
                                    rules={[
                                        {
                                            required: true,
                                            message: formatMessage({
                                                id: 'required_field'
                                            })
                                        }
                                    ]}
                                >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onSelect={value => {
                                            setTypeId({ type: value });
                                            this.formRef.current.resetFields();
                                        }}
                                        placeholder={formatMessage({
                                            id: 'add_client_form.type_placeholder'
                                        })}
                                        showSearch
                                    >
                                        <Option key='all' value='All'>
                                            <FormattedMessage id='all' />
                                        </Option>
                                        <Option key='PC' value='PC'>
                                            <FormattedMessage id='add_client_form.lightweight' /> - PC - 2
                                        </Option>
                                        <Option key='E-PC' value='E-PC'>
                                            <FormattedMessage id='add_client_form.lightweight_electric' /> - E-PC - 4
                                        </Option>
                                        <Option key='LCV' value='LCV'>
                                            <FormattedMessage id='add_client_form.minibus' /> - LCV - 999
                                        </Option>
                                        <Option key='E-LCV' value='E-LCV'>
                                            <FormattedMessage id='add_client_form.minibus_electric' /> - E-LCV - 5
                                        </Option>
                                        <Option key='CV' value='CV'>
                                            <FormattedMessage id='add_client_form.truck' /> - CV - 16
                                        </Option>
                                        <Option key='E-CV' value='E-CV'>
                                            <FormattedMessage id='add_client_form.truck_electric' /> - E-CV - 16
                                        </Option>
                                        <Option key='Bus' value='Bus'>
                                            <FormattedMessage id='add_client_form.bus' /> - Bus - 17
                                        </Option>
                                        <Option key='E-Bus' value='E-Bus'>
                                            <FormattedMessage id='add_client_form.bus_electric' /> - E-Bus - 20
                                        </Option>
                                        <Option key='Tractor' value='Tractor'>
                                            <FormattedMessage id='add_client_form.tractor' /> - Tractor - 18
                                        </Option>
                                        <Option key='Motorcycle' value='Motorcycle'>
                                            <FormattedMessage id='add_client_form.motorcycle' /> - Motorcycle - 3
                                        </Option>
                                        <Option key='E-Motorcycle' value='E-Motorcycle'>
                                            <FormattedMessage id='add_client_form.motorcycle_electric' /> - E-Motorcycle
                                            - 6
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    )}
                    {!setVehicleData && (
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.number' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='number'
                                    rules={[
                                        {
                                            required: mode !== 'SEARCH',
                                            message: formatMessage({
                                                id: 'required_field'
                                            })
                                        }
                                    ]}
                                >
                                    <Input
                                        className={Styles.inputRegNumber}
                                        onChange={e =>
                                            setVehicleNumber({
                                                number: convertLatinToCyrillic(String(e.target.value).toUpperCase()) // convert latin symbols to cyrillic
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Button
                                    className={Styles.button}
                                    icon={<CheckCircleOutlined />}
                                    onClick={async () => {
                                        await fetchVehicleDataByNumber();
                                        this.setState({
                                            colorBadge: this.props.colorPattern
                                        });
                                        this.formRef.current.resetFields();
                                    }}
                                    style={{
                                        marginTop: 4
                                    }}
                                    title={formatMessage({ id: 'add_client_form.get_car' })}
                                    type='primary'
                                />
                            </Col>
                        </Row>
                    )}

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='add_order_form.vin' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='vin'>
                                <Input
                                    onBlur={async () => {
                                        const { clientsVehicles } = await fetchAPI(
                                            'GET',
                                            'vehicles',
                                            {
                                                filters: { query: fields.vin }
                                            },
                                            null,
                                            { handleErrorInternally: true }
                                        );

                                        if (
                                            clientsVehicles &&
                                            clientsVehicles.length &&
                                            !setVehicleData &&
                                            clientsVehicles.findIndex(({ vin }) => fields.vin === vin) >= 0
                                        ) {
                                            Modal.confirm({
                                                title: formatMessage({
                                                    id: 'add_client_form.already_exist_vin'
                                                }),
                                                onOk() {
                                                    goTo(`${book.vehicle}/${clientsVehicles[0].clientVehicleId}`);
                                                },
                                                onCancel() {}
                                            });
                                        }
                                    }}
                                    onChange={e => {
                                        // if (
                                        //     vehicles.findIndex(({ vin }) => vin === this.state.vin) !== -1
                                        // ) {
                                        //     notification.warning({
                                        //         message: this.props.intl.formatMessage({
                                        //             id: 'labor_form.notification'
                                        //         })
                                        //     });
                                        // }
                                        setVehicleVin({
                                            vin: removeCyrillicLetters(String(e.target.value).toUpperCase())
                                        });
                                    }}
                                    suffix={
                                        <Tooltip title={<FormattedMessage id='vin.forbidden_symbols' />}>
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'var(--primary)'
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                    value={fields.vin}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Button
                                className={Styles.button}
                                disabled={isForbidden(user, permissions.ACCESS_ORDER_DETAILS_VIN)}
                                icon={<CheckCircleOutlined />}
                                onClick={() => {
                                    fetchVehicleDataByVin();
                                    this.formRef.current.resetFields();
                                }}
                                style={{
                                    marginTop: 4
                                }}
                                title={formatMessage({ id: 'add_client_form.get_car' })}
                                type='primary'
                            />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='add_client_form.year' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='year'
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({
                                            id: 'required_field'
                                        })
                                    }
                                ]}
                            >
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onSelect={value => {
                                        setVehicleYear({ year: value });
                                        fetchVehicleMakes();

                                        setTimeout(
                                            () =>
                                                setRegistrationName({
                                                    registrationName: `${value || ''}`
                                                }),
                                            100
                                        );
                                        this.formRef.current.resetFields();
                                        this.setState({
                                            makesLabel: '',
                                            modelLabel: '',
                                            modificationLabel: ''
                                        });
                                    }}
                                    options={years.map(yr => ({ label: yr, value: yr }))}
                                    placeholder={formatMessage({
                                        id: 'add_client_form.year_placeholder'
                                    })}
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='add_client_form.make' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='makeId'
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({
                                            id: 'required_field'
                                        })
                                    }
                                ]}
                            >
                                <Select
                                    disabled={!_.get(fields, 'year')}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onSelect={(value, option) => {
                                        setVehicleMakeId({ makeId: value });
                                        this.setState({
                                            makesLabel: option.label,
                                            modelLabel: '',
                                            modificationLabel: ''
                                        });
                                        fetchVehicleModels();

                                        const makeName = makes.find(({ id }) => id == value);

                                        setTimeout(
                                            () =>
                                                setRegistrationName({
                                                    registrationName: `${_.get(makeName, 'name') || ''}, ${
                                                        _.get(fields, 'year') || ''
                                                    }`
                                                }),
                                            100
                                        );
                                        this.formRef.current.resetFields();
                                    }}
                                    optionFilterProp='label'
                                    options={(makes || []).map(({ id, name }) => ({
                                        label: name,
                                        value: id
                                    }))}
                                    placeholder={formatMessage({
                                        id: 'add_client_form.make_placeholder'
                                    })}
                                    showSearch
                                />
                            </Form.Item>

                            {!isMobile && (
                                <h1 className={Styles.vehicleDataHint}>{fields.makeId ? null : fields.makeName}</h1>
                            )}
                        </Col>
                        <Col span={6}>
                            <Button
                                className={Styles.button}
                                icon={<StarFilled />}
                                onClick={() => {
                                    setPopular({ popular: !fields.popular });
                                    if (fields.year) {
                                        fetchVehicleMakes();
                                    }
                                }}
                                title={formatMessage({ id: 'add_client_form.popular_all' })}
                                type={!fields.popular ? null : 'primary'}
                            />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='add_client_form.model' />
                            </div>
                        </Col>
                        <Col span={12}>
                            {fields.modelName && (
                                <h1 className={Styles.vehicleDataHintModel}>
                                    <b className={Styles.vehicleDataHintFirst}>
                                        {String(fields.modelName || '').split(' ')[0]}
                                    </b>{' '}
                                    {String(fields.modelName || '')
                                        .split(' ')
                                        .slice(1)
                                        .join(' ')}
                                    <span className={Styles.vehicleDataHintFirst}>
                                        {fields.engineInfo ? String(`( ${fields.engineInfo} )` || '') : undefined}
                                    </span>
                                </h1>
                            )}

                            <Form.Item
                                name='modelId'
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({
                                            id: 'required_field'
                                        })
                                    }
                                ]}
                            >
                                <Select
                                    disabled={!_.get(fields, 'makeId')}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onBlur={() => setTimeout(() => this.setState({ modelSearch: '' }), 500)}
                                    onFocus={() => this.setState({ modelSearch: ' ' })}
                                    onSearch={modelSearch => {
                                        this.setState({ modelSearch: modelSearch || ' ' });
                                    }}
                                    onSelect={(value, option) => {
                                        setVehicleModelId({
                                            modelId: value,
                                            bodyId: option.bodyId
                                        });
                                        this.setState({
                                            modelSearch: '',
                                            modelLabel: option.label,
                                            modificationLabel: ''
                                        });
                                        fetchVehicleModifications();

                                        const makeName = makes.find(({ id }) => id == fields.makeId);
                                        const modelName = models.find(({ id }) => id == value);

                                        setTimeout(
                                            () =>
                                                setRegistrationName({
                                                    registrationName: `${_.get(makeName, 'name') || ''}, ${
                                                        _.get(modelName, 'aliasModel') || ''
                                                    }, ${_.get(fields, 'year') || ''}`
                                                }),
                                            100
                                        );
                                        this.formRef.current.resetFields();
                                    }}
                                    optionFilterProp='label'
                                    options={(models || []).map(({ id, name, bodyId, fuelId }) => ({
                                        label: name,
                                        value: id,
                                        bodyId,
                                        fuelId
                                    }))}
                                    placeholder={formatMessage({
                                        id: 'add_client_form.model_placeholder'
                                    })}
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>{modelSearch && addButton}</Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='add_client_form.modification' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='modificationId'>
                                <Select
                                    disabled={!_.get(fields, 'modelId')}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onBlur={() => {
                                        setTimeout(() => this.setState({ modificationSearch: '' }), 500);
                                    }}
                                    onFocus={() => {
                                        this.setState({ modificationSearch: ' ' });
                                    }}
                                    onSearch={modificationSearch => {
                                        this.setState({
                                            modificationSearch: modificationSearch || ' '
                                        });
                                    }}
                                    onSelect={async (value, option) => {
                                        await setVehicleModificationId({
                                            modificationId: value,
                                            bodyId: option.bodyId || fields.bodyId
                                        });
                                        this.setState({
                                            modificationSearch: '',
                                            modificationLabel: option.label
                                        });

                                        const makeName = makes.find(({ id }) => id == fields.makeId);
                                        const modelName = models.find(({ id }) => id == fields.modelId);
                                        const modName = modifications.find(({ id }) => id == value);

                                        setTimeout(
                                            () =>
                                                setRegistrationName({
                                                    registrationName: `${_.get(makeName, 'name') || ''}, ${
                                                        _.get(modelName, 'aliasModel') || ''
                                                    }, ${_.get(modName, 'aliasModification') || ''}, ${
                                                        _.get(fields, 'year') || ''
                                                    }`
                                                }),
                                            100
                                        );

                                        this.formRef.current.resetFields();
                                    }}
                                    optionFilterProp='label'
                                    options={_.uniqBy(modifications || [], 'id').map(({ id, name, bodyId }) => ({
                                        label: name,
                                        value: id,
                                        bodyId
                                    }))}
                                    placeholder={formatMessage({
                                        id: 'add_client_form.model_placeholder'
                                    })}
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>{modificationSearch && addButton}</Col>
                    </Row>

                    {vehicleTypes && (
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='tire.vehicleTypeAndRadius' />
                                </div>
                            </Col>
                            <Col span={12} style={{ display: 'flex' }}>
                                <Form.Item name='vehicleTypeId'>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onSelect={(value, option) => {
                                            setVehicleType(value, option.props.radius);
                                            this.formRef.current.setFieldsValue({
                                                wheelRadius: option.props.radius
                                            });
                                        }}
                                        optionFilterProp='label'
                                        options={vehicleTypes.map(({ id, name, defaultRadius }) => ({
                                            label: name,
                                            value: id,
                                            defaultRadius
                                        }))}
                                        placeholder={formatMessage({ id: 'tire.vehicleType' })}
                                        showSearch
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item name='wheelRadius'>
                                    <InputNumber
                                        formatter={value => `${Math.round(value)}R`}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        max={100}
                                        min={0}
                                        onChange={value => {
                                            setVehicleRadius(value);
                                        }}
                                        parser={value => value.replace('R', '')}
                                        style={{ display: 'block' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    )}
                    {!setVehicleData && mode !== 'SEARCH' && (
                        <React.Fragment>
                            <Row className={Styles.row}>
                                <Col span={6}>
                                    <div className={Styles.colText}>
                                        <FormattedMessage id='add_client_form.color' />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='colorId'>
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onSelect={(value, option) => {
                                                setVehicleColor({ colorId: value });
                                                setVehicleColorPattern({ colorPattern: option.color });
                                                this.setState({
                                                    colorId: value
                                                });
                                                this.formRef.current.resetFields();
                                            }}
                                            optionFilterProp='label'
                                            options={colors.map(({ colorId, name, colorCode }) => ({
                                                label: name,
                                                value: colorId,
                                                color: colorCode
                                            }))}
                                            placeholder={formatMessage({
                                                id: 'add_client_form.color_placeholder'
                                            })}
                                            showSearch
                                            suffixIcon={
                                                fields.colorId !== 6 ? (
                                                    <Tooltip
                                                        title={
                                                            <FormattedMessage id='add_client_form.color_placeholder' />
                                                        }
                                                    >
                                                        <CarFilled
                                                            style={{
                                                                color: this.props.colorPattern,
                                                                backgroundColor: '#e3e3e3',
                                                                fontSize: 16,
                                                                borderRadius: 16,
                                                                padding: 3
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ) : undefined
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                            <Row className={Styles.row}>
                                <Col span={6}>
                                    <div className={Styles.colText}>
                                        <FormattedMessage id='add_client_form.print_name' />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='registrationName'>
                                        <Input
                                            onChange={e =>
                                                setRegistrationName({ registrationName: e.target.value || null })
                                            }
                                            placeholder={formatMessage({
                                                id: 'add_client_form.placeholder_alias'
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                            <Row className={Styles.row}>
                                <Col span={6}>
                                    <div className={Styles.colText}>
                                        <FormattedMessage id='add_client_form.name_surname' />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='manager'>
                                        <Input
                                            onChange={e => setManagerName({ manager: e.target.value })}
                                            placeholder={formatMessage({
                                                id: 'add_client_form.placeholder_name_surname'
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                            <Row className={Styles.row}>
                                <Col span={6}>
                                    <div className={Styles.colText}>
                                        <FormattedMessage id='add_client_form.phone_number' />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='phone'>
                                        <Input
                                            onChange={e => setManagerPhone({ phone: e.target.value })}
                                            placeholder={formatMessage({
                                                id: 'enter_client_form.phone_number'
                                            })}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                            <Row className={Styles.row}>
                                <Col span={6}>
                                    <div className={Styles.colText}>
                                        <FormattedMessage id='add_client_form.comment' />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name='com'
                                        rules={[
                                            {
                                                max: 2000,
                                                message: formatMessage({
                                                    id: 'field_should_be_below_2000_chars'
                                                })
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                            onChange={e => setComment({ com: e.target.value })}
                                            placeholder={formatMessage({
                                                id: 'edit_vehicle_from.comment_new'
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </React.Fragment>
                    )}
                </Form>
                <OwnVehicleModal
                    fields={fields}
                    hideModal={() => {
                        this.setState({
                            visibleOwnVehicleModal: false
                        });
                    }}
                    makes={makes}
                    models={models}
                    modelSearch={modelSearch}
                    modifications={modifications}
                    modificationSearch={modificationSearch}
                    updateModels={modelId => {
                        fetchVehicleModels();
                        setVehicleModelId({
                            modelId
                        });
                    }}
                    updateModifications={modificationId => {
                        fetchVehicleModifications();
                        setVehicleModificationId({
                            modificationId
                        });
                    }}
                    visible={visibleOwnVehicleModal}
                    years={years}
                />
            </div>
        );
    }
}
