exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__icon---fco2i{font-size:16px}.styles-m__reserve---1UqgU{font-size:18px;color:var(--reserve)}.styles-m__notComplete---Yrx_k,.styles-m__required---3aEwn,.styles-m__stop---1R4nJ{color:var(--not_complete)}.styles-m__approve---dUPZg,.styles-m__processing---9f-YV{color:var(--approve)}.styles-m__progress---19Nvj{color:var(--progress)}.styles-m__success---1YI93{color:var(--success)}.styles-m__cancel---T7LeY{color:var(--cancel)}.styles-m__invite---3xsw-{color:var(--link)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,wBACI,cACJ,CAEA,2BACI,cAAe,CACf,oBACJ,CAEA,mFAGI,yBACJ,CAEA,yDAEI,oBACJ,CAEA,4BACI,qBACJ,CAEA,2BACI,oBACJ,CAEA,0BACI,mBACJ,CAEA,0BACI,iBACJ","file":"styles.m.css","sourcesContent":[".icon {\n    font-size: 16px;\n}\n\n.reserve {\n    font-size: 18px;\n    color: var(--reserve);\n}\n\n.notComplete,\n.required,\n.stop {\n    color: var(--not_complete);\n}\n\n.approve,\n.processing {\n    color: var(--approve);\n}\n\n.progress {\n    color: var(--progress);\n}\n\n.success {\n    color: var(--success);\n}\n\n.cancel {\n    color: var(--cancel);\n}\n\n.invite {\n    color: var(--link);\n}\n"]}]);

// Exports
exports.locals = {
	"icon": "styles-m__icon---fco2i",
	"reserve": "styles-m__reserve---1UqgU",
	"notComplete": "styles-m__notComplete---Yrx_k",
	"required": "styles-m__required---3aEwn",
	"stop": "styles-m__stop---1R4nJ",
	"approve": "styles-m__approve---dUPZg",
	"processing": "styles-m__processing---9f-YV",
	"progress": "styles-m__progress---19Nvj",
	"success": "styles-m__success---1YI93",
	"cancel": "styles-m__cancel---T7LeY",
	"invite": "styles-m__invite---3xsw-"
};