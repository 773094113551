import { SyncOutlined } from '@ant-design/icons';
import { Modal, notification, Select } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description
    });
};

/**
 * This modal is used to transfer car between clients
 */

@injectIntl
export default class ClientVehicleTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            clients: [],
            clientId: undefined,
            newOwnerId: undefined,
            vehicles: [],
            vehicleId: undefined,
            searchValue: '',
            clientList: [],
            currentClient: {}
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            clients: [],
            newOwnerId: undefined,
            vehicles: [],
            vehicleId: undefined
        });
    };

    handleOk = () => {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/clients/vehicles/${that.state.vehicleId}`;

        fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ clientId: that.state.newOwnerId })
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                window.location.reload();
            })
            .catch(function (error) {
                console.log('error', error);
                openNotificationWithIcon(
                    'error',
                    that.props.intl.formatMessage({
                        id: 'vehicle_transfer.error'
                    })
                );
            });
        that.handleCancel();

        // confirm({
        //     title: `${that.props.intl.formatMessage({
        //         id: 'clients-page.vehicle_transfer_confirm'
        //     })}   `,
        //     onOk() {
        //         const token = localStorage.getItem('_my.carbook.pro_token');
        //         const url = `${__API_URL__}/clients/vehicles/${that.state.vehicleId}`;

        //         fetch(url, {
        //             method: 'PUT',
        //             headers: {
        //                 Authorization: token,
        //                 'content-type': 'application/json'
        //             },
        //             body: JSON.stringify({ clientId: that.state.newOwnerId })
        //         })
        //             .then(function (response) {
        //                 if (response.status !== 200) {
        //                     return Promise.reject(new Error(response.statusText));
        //                 }

        //                 return Promise.resolve(response);
        //             })
        //             .then(function (response) {
        //                 return response.json();
        //             })
        //             .then(function (data) {
        //                 window.location.reload();
        //             })
        //             .catch(function (error) {
        //                 console.log('error', error);
        //                 openNotificationWithIcon(
        //                     'error',
        //                     that.props.intl.formatMessage({
        //                         id: 'vehicle_transfer.error'
        //                     })
        //                 );
        //             });
        //         that.handleCancel();
        //     }
        // });
    };

    getClientList = value => {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/clients/simple/search?query=${value}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.setState({
                    clientList: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    // fetchData() {
    //     const that = this;
    //     const token = localStorage.getItem('_my.carbook.pro_token');
    //     const url = `${__API_URL__}/clients`;
    //     fetch(url, {
    //         method: 'GET',
    //         headers: {
    //             Authorization: token
    //         }
    //     })
    //         .then(function (response) {
    //             if (response.status !== 200) {
    //                 return Promise.reject(new Error(response.statusText));
    //             }

    //             return Promise.resolve(response);
    //         })
    //         .then(function (response) {
    //             return response.json();
    //         })
    //         .then(function (data) {
    //             that.setState({
    //                 clients: data.clients
    //             });
    //         })
    //         .catch(function (error) {
    //             console.log('error', error);
    //         });
    // }

    fetchCurrentCLient = async () => {
        const client = await fetchAPI('GET', `clients/${this.props.clientId}`, null, null, {
            handleErrorInternally: true
        });
        this.setState({
            currentClient: client,
            vehicles: client.vehicles
        });
    };

    render() {
        const { visible, clients, vehicles, newOwnerId, clientId, vehicleId, searchValue, clientList, currentClient } =
            this.state;

        return (
            <React.Fragment>
                <SyncOutlined
                    className={Styles.carTransferIcon}
                    onClick={() => {
                        this.setState({
                            visible: true,
                            clientId: Number(this.props.clientId),
                            vehicleId: Number(this.props.vehicleId),
                            vehicles: this.props.vehicles
                        });
                        this.fetchCurrentCLient();
                    }}
                />
                <Modal
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    style={{
                        minWidth: 640
                    }}
                    visible={visible}
                >
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <div style={{ width: '50%' }}>
                            <span style={{ fontWeight: 500 }}>
                                <FormattedMessage id='vehicle_transfer.from' />
                            </span>
                            <Select
                                disabled
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                onChange={(value, option) => {
                                    this.setState({
                                        clientId: value,
                                        vehicleId: option.props.vehicles.length
                                            ? option.props.vehicles[0].id
                                            : undefined
                                    });
                                }}
                                showSearch
                                style={{ color: 'var(--text)' }}
                                value={clientId || <FormattedMessage id='not_owner' />}
                            >
                                {clientId && (
                                    <Option key={currentClient.clientId} value={currentClient.clientId}>
                                        {currentClient.name} {currentClient.surname} {_.get(currentClient, 'phones[0]')}
                                    </Option>
                                )}
                            </Select>
                            <Select
                                disabled
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                onChange={(value, option) => {
                                    this.setState({
                                        vehicleId: value
                                    });
                                }}
                                showSearch
                                style={{ color: 'var(--text)' }}
                                value={vehicleId}
                            >
                                {vehicles
                                    ? vehicles.map(({ id, make, model }, key) => (
                                          <Option key={key} value={id}>
                                              {make} {model}
                                          </Option>
                                      ))
                                    : []}
                            </Select>
                        </div>
                        <div style={{ width: '50%' }}>
                            <span style={{ fontWeight: 500 }}>
                                <FormattedMessage id='vehicle_transfer.to' />
                            </span>
                            <Select
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                filterOption={(input, option) => {
                                    const searchValue = String(option.props.children)
                                        .toLowerCase()
                                        .replace(/[+\-()., ]/g, '');
                                    const inputValue = input.toLowerCase();

                                    return searchValue.indexOf(inputValue) >= 0;
                                }}
                                onBlur={() => {
                                    this.setState({
                                        searchValue: ''
                                    });
                                }}
                                onChange={(value, option) => {
                                    this.setState({
                                        newOwnerId: value
                                    });
                                }}
                                onSearch={input => {
                                    this.setState({
                                        searchValue: input
                                    });
                                    if (input.length > 2) this.getClientList(input);
                                }}
                                showSearch
                                value={newOwnerId}
                            >
                                {searchValue.length > 2
                                    ? clientList.map(({ clientId, name, surname, phones }, key) => (
                                          <Option key={key} value={clientId}>
                                              {name} {surname} {phones ? phones[0] : undefined}
                                          </Option>
                                      ))
                                    : []}
                            </Select>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
