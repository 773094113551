import { PrinterOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Select, Space, Table, notification } from 'antd';
import { DateRangePicker } from 'components';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const IncomeExpenseTab = ({ user, intl: { formatMessage }, brands }) => {
    const [query, setQuery] = useState({
        types: ['INCOME/EXPENSE'],

        dateFrom: dayjs().startOf('year').toISOString(),
        dateTo: dayjs().quarter(dayjs().quarter()).endOf('quarter').toISOString()
    });
    const [isFetched, setFetched] = useState(true);
    const [modalData, setModalData] = useState({});
    const [requisites, setRequisites] = useState();
    const [rowId, setRowId] = useState();
    const [countOfEmployee, setCountOfEmployee] = useState();
    const [contractPhoneNumber, setContractPhoneNumber] = useState();
    const [balanceData, setBalance] = useState([]);
    const [requisiteData, setRequisiteData] = useState();
    const [reportCount, setReportCount] = useState([]);
    const [filters, setFilters] = useState({
        warehouseId: undefined,
        date: dayjs(),
        averageSellPerMonth: 0,
        deliveryTime: 1,
        calculationPeriod: 12,
        limit: 25,
        frequencyOfOrders: 7,
        insuranceReserve: 1
    });

    const groupedData = [
        {
            title: 'I.Необоротні активи',
            filterCondition: code => code > 999 && code < 1099,
            mainTitle: <FormattedMessage id='asset' />
        },
        {
            title: 'II.Оборотні активи',
            filterCondition: code => code > 1099 && code < 1199
        },
        {
            title: 'III.Необоротні активи, утримувані для продажу, та групи вибуття',
            filterCondition: code => code > 1199 && code < 1301
        },
        {
            title: 'I.Власний капітал',
            filterCondition: code => code > 1300 && code < 1499,
            mainTitle: <FormattedMessage id='article' />
        },
        {
            title: 'II.Довгострокові зобовʼязання, цільове фінансування та забезпечення',
            filterCondition: code => code > 1500 && code < 1599
        },
        {
            title: 'III.Поточні зобовʼязання',
            filterCondition: code => code > 1599 && code < 1699
        },
        {
            title: 'III.Зобовʼязання, повʼязані з необоротними активами, утримуваними для продажу, та групами вибуття',
            filterCondition: code => code > 1699,
            mainTitle: <FormattedMessage id='article' />
        }
    ];

    const fetchRequisites = useCallback(async () => {
        const res = await fetchAPI('GET', '/businesses/requisites', null, null, {
            handleErrorInternally: true
        });

        setRequisites(
            res
                .filter(({ enabled }) => enabled)
                .map(({ id, name, address, contractNumber }) => ({ value: id, label: name, address, contractNumber }))
        );
    }, []);

    const getIncomeExpense = useCallback(async () => {
        try {
            const res = await fetchAPI(
                'GET',
                '/account/book',
                {
                    ...query
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setBalance(res);
            setFetched(false);
        } catch (err) {
            notification.error({
                message: formatMessage({
                    id: 'error'
                })
            });
            setFetched(false);
        }
    }, [query, formatMessage]);

    const downloadXlsxReport = async () => {
        const url = '/account/book/xlsx';
        try {
            const response = await fetchAPI(
                'GET',
                url,
                {
                    dateFrom: query && dayjs(query.dateFrom).format('YYYY-MM-DD'),
                    dateTo: query && dayjs(query.dateTo).format('YYYY-MM-DD'),
                    requisiteId: requisiteData && requisiteData.value,
                    countOfEmployee
                },
                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true
                }
            );
            const reportFile = await response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
            await saveAs(reportFile, fileName);
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    const debounceFilters = useMemo(() => {
        const loadOptions = (filters, value) => {
            setQuery(filters, value);
        };

        return _.debounce(loadOptions, 1000);
    }, [setQuery]);

    const columns = (withTitle, titleForEachTable, mainTitle) => [
        {
            title: withTitle ? mainTitle : undefined,
            key: 'asset',
            align: 'center',
            dataIndex: 'name',
            onCell: row => ({ style: { fontWeight: row.firstRow ? 700 : undefined } }),
            // children: [
            //     {
            //         title: titleForEachTable,
            //         align: 'center',
            //         dataIndex: 'name'
            //     }
            // ],
            width: '10%'
        },
        {
            title: withTitle ? <FormattedMessage id='code_row' /> : undefined,
            key: 'code_row',
            dataIndex: 'code',
            align: 'center',
            width: '10%',
            render: data => {
                return data || <FormattedMessage id='long_dash' />;
            }
        },
        {
            title: withTitle ? <FormattedMessage id='stats_counts_panel.openingBalance_by_year' /> : undefined,
            key: 'openingBalance',
            align: 'center',
            dataIndex: 'startBalance',
            width: '15%',
            render: data => {
                return data ? data.toFixed(2) : <FormattedMessage id='long_dash' />;
            }
        },
        {
            title: withTitle ? <FormattedMessage id='stats_counts_panel.openingBalance_by_previous_year' /> : undefined,
            key: 'closingBalance',
            align: 'center',
            dataIndex: 'endBalance',
            width: '15%',
            render: data => {
                return data ? data.toFixed(2) : <FormattedMessage id='long_dash' />;
            }
        }
        // {
        //     key: 'edit',
        //     align: 'center',
        //     width: '5%',
        //     render: row => {
        //         return <Button icon={<EditOutlined />} onClick={() => setOpen(true)} />;
        //     }
        // }
    ];

    useEffect(() => {
        if (query) {
            getIncomeExpense();
        }
    }, [query]);

    useEffect(() => {
        fetchRequisites();
    }, [fetchRequisites]);

    const handleCancel = () => {
        setModalData({});
        setRowId(undefined);
    };

    const handleRequisiteChange = value => setQuery({ ...query, requisiteId: value });

    const editMinMax = useMemo(
        () => async () => {
            try {
                await fetchAPI(
                    'PUT',
                    '/scm_report',
                    null,

                    {
                        data: [
                            {
                                ...modalData,
                                min: modalData.min * modalData.multiplicity,
                                max: modalData.max * modalData.multiplicity,
                                id: rowId
                            }
                        ]
                    },
                    {
                        handleErrorInternally: true
                    }
                );
                await getIncomeExpense();
                handleCancel();
                notification.success({
                    message: formatMessage({ id: 'barcode.success' })
                });
            } catch (err) {
                notification.error({
                    message: formatMessage({
                        id: 'error'
                    })
                });
            }
        },
        [formatMessage, getIncomeExpense, modalData, rowId]
    );

    const handleOk = () => {
        editMinMax();
    };

    const pagination = {
        pageSize: query.pageSize,
        size: 'small',
        total: reportCount,
        hideOnSinglePage: false,
        current: query.page,
        onChange: (page, pageSize) => {
            setQuery({
                ...query,
                page,
                pageSize
            });
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Space>
                <Select
                    allowClear
                    onChange={(value, option) => {
                        handleRequisiteChange(value);

                        setRequisiteData(option);
                    }}
                    options={requisites}
                    placeholder={formatMessage({ id: 'account_plan.filter_by_requisite' })}
                    popupMatchSelectWidth
                    style={{ width: 300 }}
                    value={query.requisite}
                />
                <DateRangePicker
                    dateRange={[dayjs(query.dateFrom), dayjs(query.dateTo)]}
                    minimize
                    onDateChange={([dateFrom, dateTo]) =>
                        setQuery({ ...query, dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString() })
                    }
                />
                <Button icon={<PrinterOutlined />} onClick={() => downloadXlsxReport()} type='text' />
            </Space>
            <Space>
                <Input
                    onChange={event => setRequisiteData({ ...requisiteData, address: event.target.value })}
                    placeholder={formatMessage({ id: 'requisite-setting.address' })}
                    value={requisiteData && requisiteData.address}
                />
                <Input
                    onChange={event => setContractPhoneNumber(event.target.value)}
                    placeholder={formatMessage({ id: 'phone' })}
                    value={contractPhoneNumber}
                />
                <InputNumber
                    onChange={value => setCountOfEmployee(value)}
                    parser={value => value.replace(/[^\d]/g, '')}
                    placeholder={formatMessage({ id: 'number_of_people' })}
                    step={1}
                    style={{
                        width: 192
                    }}
                    value={countOfEmployee}
                />
            </Space>

            <div>
                {balanceData &&
                    groupedData.map(({ title, filterCondition, mainTitle }, index) => {
                        const dataSource = balanceData.filter(({ code }) => filterCondition(code));

                        if (dataSource.length === 0) return null;

                        return (
                            <Table
                                bordered
                                columns={columns(mainTitle, title, mainTitle)}
                                dataSource={dataSource}
                                loading={isFetched}
                                pagination={false}
                                size='small'
                            />
                        );
                    })}
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    brands: selectBrands(state)
});

const mapDispatchToProps = {
    fetchWarehouses,
    fetchBrands
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IncomeExpenseTab));
