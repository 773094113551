import { Button } from 'antd';
import { Catcher } from 'commons';
import { UniversalFiltersTags } from 'components';
import {
    fetchUniversalFiltersForm,
    onChangeUniversalFiltersForm
} from 'core/forms/universalFiltersForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { UniversalFiltersModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';
import Styles from './styles.m.css';

const universalLinkedFields = Object.freeze({
    notVisitRange: ['notVisit', 'notVisitDays']
});

/* eslint-disable array-element-newline */
const tagFields = Object.freeze([
    'createDate',
    'beginDate',
    'ordersGreater',
    'ordersLower',
    'managers',
    'employee',
    'service',
    'models',
    'make',
    'creationReasons',
    'cancelReasons',
    'year',
    'odometerLower',
    'odometerGreater'
]);
/* eslint-enable array-element-newline */

@injectIntl
@withReduxForm2({
    name: 'universalFiltersForm',
    actions: {
        change: onChangeUniversalFiltersForm,
        fetchUniversalFiltersForm,
        setModal,
        resetModal
    },
    mapStateToProps: state => ({
        universalFiltersModal: state.modals.modal,
        user: state.auth
    })
})
export default class UniversalFilters extends Component {
    // Contains: modal, show button, tags
    // Modal contains: form

    _showUniversalFiltersModal = () => {
        this.props.setModal(MODALS.UNIVERSAL_FILTERS);
        this.props.fetchUniversalFiltersForm();
    };

    clearFilters = fieldNames => {
        this.props.form.resetFields(fieldNames);
        this.props.setUniversalFilter(this.props.form.getFieldsValue());
    };

    render() {
        const {
            resetModal,
            universalFiltersModal,
            setUniversalFilter,
            stats,
            form,
            filters,
            areFiltersDisabled,
            style
        } = this.props;

        const formFilters = form.getFieldsValue();

        return (
            <Catcher>
                <section className={Styles.filters} style={style}>
                    <UniversalFiltersTags
                        clearUniversalFilters={this.clearFilters}
                        filter={formFilters}
                        tagFields={tagFields}
                        universalLinkedFields={universalLinkedFields}
                    />
                    <Button
                        disabled={areFiltersDisabled}
                        onClick={this._showUniversalFiltersModal}
                        type='primary'
                    >
                        <FormattedMessage id='universal-filters-container.filter' />
                    </Button>
                </section>
                <UniversalFiltersModal
                    {...filters}
                    form={form}
                    hideModal={() => resetModal()}
                    resetModal={() => {
                        if (_.isEqual(this.props.universalFilter, {})) {
                            this.props.form.resetFields();
                        } else {
                            this.props.form.setFieldsValue(this.props.universalFilter);
                        }
                        resetModal();
                    }}
                    setUniversalFilter={setUniversalFilter}
                    stats={stats}
                    visible={universalFiltersModal}
                />
            </Catcher>
        );
    }
}
