import { RollbackOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { Layout } from 'commons';
import {
    /* -------Fetchers----*/
    fetchVehicle,
    fetchVehicleAppurtenances,
    fetchVehicleLabors,
    fetchVehicleNormHours,
    fetchVehicleOrders,
    fetchVehicleRecommendations,
    selectClient,
    selectGeneralData,
    /* -------Selectors----*/
    selectVehicle,
    selectVehicleAppurtenancesStats,
    selectVehicleLaborsStats,
    selectVehicleNormHoursStats,
    selectVehicleOrdersStats,
    selectVehicleRecommendationsStats,

    /* -------Setters----*/
    setExpandedVehicleId
} from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import history from 'store/history';
import { isForbidden, permissions } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import {
    GeneralInfoTab,
    PreviewTab,
    VehicleLaborsTab,
    VehicleNormHoursTab,
    VehicleOdometrTab,
    VehicleOrdersTab,
    VehicleRecomendationsTab,
    VehicleSparePartsTab,
    VinTab
} from './components/Tabs';
import VehicleRegulationsTab from './components/Tabs/VehicleRegulationsTab';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    user: state.auth,
    vehicle: selectVehicle(state),
    client: selectClient(state),
    generalData: selectGeneralData(state),

    // stats
    ordersStats: selectVehicleOrdersStats(state),
    normHoursStats: selectVehicleNormHoursStats(state),
    laborsStats: selectVehicleLaborsStats(state),
    appurtenancesStats: selectVehicleAppurtenancesStats(state),
    recommendationsStats: selectVehicleRecommendationsStats(state)
});

const mapDispatchToProps = {
    fetchVehicle,
    fetchVehicleOrders,
    fetchVehicleNormHours,
    fetchVehicleLabors,
    fetchVehicleAppurtenances,
    fetchVehicleRecommendations,

    setExpandedVehicleId
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehiclePage extends Component {
    constructor(props) {
        super(props);

        const { user } = props;

        this.tabsPermisions = {
            orderDetailsForbidden: isForbidden(user, permissions.ACCESS_ORDER_DETAILS),
            orderNormHoursForbidden: isForbidden(user, permissions.ACCESS_NORM_HOURS_MODAL_WINDOW),
            orderServicesForbidden: isForbidden(user, permissions.ACCESS_ORDER_SERVICES),
            orderCommentsForbidden: isForbidden(user, permissions.ACCESS_ORDER_COMMENTS),
            vinForbidden: isForbidden(user, permissions.ACCESS_ORDER_DETAILS_VIN)
        };
    }

    componentDidMount() {
        this.fetchSetupVehicleData();
    }

    componentDidUpdate(prevProps) {
        // if vehicle was created then after redirect need to prefetch data
        if (this.props.location !== prevProps.location) {
            this.fetchSetupVehicleData();
        }
    }

    /**
     * Set and fetch initial data for vehicle
     */
    fetchSetupVehicleData() {
        const {
            match: {
                params: { id }
            }
        } = this.props;

        this.props.fetchVehicle({ vehicleId: id });
        this.props.setExpandedVehicleId({ vehicleId: id });

        this.props.fetchVehicleOrders();
        this.props.fetchVehicleNormHours();
        !this.tabsPermisions.orderServicesForbidden && this.props.fetchVehicleLabors();
        !this.tabsPermisions.orderDetailsForbidden && this.props.fetchVehicleAppurtenances();
        !this.tabsPermisions.orderCommentsForbidden && this.props.fetchVehicleRecommendations();
    }

    render() {
        const { ordersStats, normHoursStats, laborsStats, appurtenancesStats, vehicle, user } = this.props;

        return (
            <Layout
                controls={
                    <div>
                        <Button
                            onClick={() => {
                                history.push({
                                    pathname: `${book.vehicles}`
                                });
                            }}
                        >
                            <RollbackOutlined />
                        </Button>
                    </div>
                }
                description={<FormattedMessage id='vehicle_page.description' />}
                title={<FormattedMessage id='vehicle_page.title' />}
            >
                <Tabs size='small' type='card'>
                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD) && (
                        <TabPane key='general_info' tab={<FormattedMessage id='vehicle_page.general_info' />}>
                            <GeneralInfoTab
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD,
                                        accesses.ROWO
                                    )
                                }
                                user={user}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_INSPECTION) && (
                        <TabPane key='preview' tab={<FormattedMessage id='vehicle_page.preview' />}>
                            <PreviewTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_INSPECTION, accesses.ROWO)
                                }
                                vehicleId={vehicle.id}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_STANDARD_HOURS) && (
                        <TabPane
                            key='norm_hours'
                            disabled={this.tabsPermisions.orderNormHoursForbidden}
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.norm_hours' />({normHoursStats.totalRowsCount})
                                </div>
                            }
                        >
                            <VehicleNormHoursTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_STANDARD_HOURS, accesses.ROWO)
                                }
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_VIN) && (
                        <TabPane
                            key='vin'
                            disabled={this.tabsPermisions.vinForbidden}
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.vin' />
                                </div>
                            }
                        >
                            <VinTab
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_VIN, accesses.ROWO)}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_REPAIRS) && (
                        <TabPane
                            key='orders'
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.orders' />({ordersStats.countOrders})
                                </div>
                            }
                        >
                            <VehicleOrdersTab
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_REPAIRS, accesses.ROWO)}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_WORKS) && (
                        <TabPane
                            key='labors'
                            disabled={this.tabsPermisions.orderServicesForbidden}
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.labors' />({laborsStats.totalRowsCount})
                                </div>
                            }
                        >
                            <VehicleLaborsTab
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_WORKS, accesses.ROWO)}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_PARTS) && (
                        <TabPane
                            key='spare_parts'
                            disabled={this.tabsPermisions.orderDetailsForbidden}
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.spare_parts' />(
                                    {appurtenancesStats.totalRowsCount})
                                </div>
                            }
                        >
                            <VehicleSparePartsTab
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_PARTS, accesses.ROWO)}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_RECOMMENDATIONS) && (
                        <TabPane
                            key='recommendations'
                            disabled={this.tabsPermisions.orderCommentsForbidden}
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.recommendations' />
                                </div>
                            }
                        >
                            <VehicleRecomendationsTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_RECOMMENDATIONS, accesses.ROWO)
                                }
                                user={user}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_REGULATIONS) && (
                        <TabPane key='regulations' tab={<FormattedMessage id='navigation.regulations' />}>
                            <VehicleRegulationsTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_REGULATIONS, accesses.ROWO)
                                }
                                vehicleId={vehicle.id}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_MILEAGE) && (
                        <TabPane
                            key='odometr'
                            tab={
                                <div>
                                    <FormattedMessage id='vehicle_page.odometr' />({ordersStats.countOrders})
                                </div>
                            }
                        >
                            <VehicleOdometrTab
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_MILEAGE, accesses.ROWO)}
                                fetchVehicleOrdersUpdate={this.props.fetchVehicleOrders}
                                vehicleId={vehicle.id}
                            />
                        </TabPane>
                    )}
                </Tabs>
            </Layout>
        );
    }
}
