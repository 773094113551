exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__deleteRoleIcon---2HBLQ,.styles-m__editRoleIcon---bcnN-,.styles-m__viewRoleIcon---3CuFW{cursor:pointer;font-size:25px}.styles-m__addRoleButton---swE-c{width:30%;margin:10px auto}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAUA,kGACI,cAAe,CACf,cACJ,CAEA,iCACI,SAAU,CACV,gBACJ","file":"styles.m.css","sourcesContent":[".editRoleIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.deleteRoleIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.viewRoleIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.addRoleButton {\n    width: 30%;\n    margin: 10px auto;\n}\n"]}]);

// Exports
exports.locals = {
	"editRoleIcon": "styles-m__editRoleIcon---bcnN-",
	"deleteRoleIcon": "styles-m__deleteRoleIcon---2HBLQ",
	"viewRoleIcon": "styles-m__viewRoleIcon---3CuFW",
	"addRoleButton": "styles-m__addRoleButton---swE-c"
};