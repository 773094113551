import {
    DeleteOutlined,
    EditOutlined,
    FilterOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Button, Drawer, Image, Input, Popconfirm, Table } from 'antd';
import { Layout } from 'commons';
import { DateRangePicker, FormattedDatetime } from 'components';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { MyNormativesAddModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

const mapStateToProps = state => {
    return {
        user: state.auth,
        modal: state.modals.modal,
        modalProps: state.modals.modalProps
    };
};

const mapDispatchToProps = {
    setModal,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class MyNormativePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            visible: false,
            edit: false,
            laborsStandart: {
                data: []
            },
            filters: {},
            allLinks: [],
            helperDrawerOpen: false
        };
        this.columns = () => [
            {
                title: <FormattedMessage id='client_order_tab.car' />,
                key: 'vehicleName',
                dataIndex: 'vehicleName',
                sorter: (a, b) => String(a.vehicleName).localeCompare(String(b.vehicleName)),
                ...this.getColumnSearchProps('vehicleName')
            },
            {
                title: <FormattedMessage id='my_normative_page.year' />,
                key: 'year',
                dataIndex: 'year',
                sorter: (a, b) => b.year - a.year,
                ...this.getColumnSearchProps('year')
            },
            {
                title: <FormattedMessage id='my_normative_page.work_id' />,
                key: 'laborId',
                dataIndex: 'laborId',
                sorter: (a, b) => b.laborId - a.laborId,
                ...this.getColumnSearchProps('laborId')
            },
            {
                title: <FormattedMessage id='my_normative_page.name' />,
                key: 'nameWithStoreGroup',
                dataIndex: 'nameWithStoreGroup',
                sorter: (a, b) => String(a.nameWithStoreGroup).localeCompare(String(b.nameWithStoreGroup)),
                ...this.getColumnSearchProps('nameWithStoreGroup')
            },
            {
                title: <FormattedMessage id='locations.region' />,
                key: 'regionIdName',
                dataIndex: 'regionIdName',
                sorter: (a, b) => String(a.regionIdName).localeCompare(String(b.regionIdName)),
                ...this.getColumnSearchProps('regionIdName')
            },
            {
                title: <FormattedMessage id='my_normative_page.date' />,
                key: 'datetime',
                dataIndex: 'datetime',
                sorter: (a, b) => String(a.datetime).localeCompare(String(b.datetime)),
                ...this.getColumnDaterangeProps(),
                render: datetime => (
                    <React.Fragment>
                        <FormattedDatetime datetime={datetime} format={DEFAULT_DATETIME} />
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='my_normative_page.norm' />,
                key: 'hours',
                dataIndex: 'hours',
                className: 'columnAlignedRight',
                sorter: (a, b) => b.hours - a.hours,
                render: data => (
                    <React.Fragment>
                        {data} <FormattedMessage id='order_form_table.hours_short' />
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='my_normative_page.price' />,
                key: 'price',
                dataIndex: 'price',
                className: 'columnAlignedRight',
                sorter: (a, b) => b.price - a.price
            },
            {
                title: <FormattedMessage id='my_normative_page.sum' />,
                key: 'sum',
                className: 'columnAlignedRight',
                sorter: (a, b) => b.sum - a.sum,
                render: laborsStandart => Number(laborsStandart.sum).toFixed(2)
            },
            {
                key: 'edit',
                render: row => (
                    <Button
                        onClick={() => {
                            props.setModal(MODALS.MY_NORMATIVES_ADD_MODAL, { edit: row });
                            this.setState({
                                edit: row
                            });
                        }}
                        style={{
                            padding: '0px 8px',
                            fontSize: 18
                        }}
                        title={this.props.intl.formatMessage({ id: 'directory_page.edit' })}
                    >
                        <EditOutlined />
                    </Button>
                )
            },
            {
                key: 'copy',
                render: row => (
                    <Button
                        onClick={() =>
                            props.setModal(MODALS.MY_NORMATIVES_ADD_MODAL, {
                                laborStandartCopy: row
                            })
                        }
                        style={{
                            padding: '0px 8px',
                            fontSize: 18
                        }}
                        title={this.props.intl.formatMessage({ id: 'copy' })}
                    >
                        <PlusOutlined />
                    </Button>
                )
            },
            {
                key: 'delete',
                render: row => {
                    return (
                        <div>
                            <Popconfirm
                                onConfirm={() => this.deleteLaborStandart(row.id)}
                                title={`${props.intl.formatMessage({ id: 'delete' })} ?`}
                            >
                                <Button
                                    style={{
                                        padding: '0px 8px',
                                        fontSize: 18
                                    }}
                                >
                                    <DeleteOutlined
                                        style={{ color: 'red' }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        title={this.props.intl.formatMessage({
                                            id: 'directory_page.delete'
                                        })}
                                    />
                                </Button>
                            </Popconfirm>
                        </div>
                    );
                }
            }
        ];
    }

    editLaborStandart = async () => {
        const { id, modificationId, storeGroupId, laborId, price, hours, year } = this.state;
        if (id) {
            await fetchAPI(
                'POST',
                'labor_standarts',
                null,
                [
                    {
                        modificationId,
                        storeGroupId,
                        laborId,
                        regionId: region || 'UA.00.00.00',
                        name,
                        price,
                        hours,
                        year,
                        orderId,
                        rewriteExisting: true
                    }
                ],
                { handleErrorInternally: true }
            );
            notification.success({
                message: this.props.intl.formatMessage({ id: 'barcode.success' })
            });
        }
    };

    deleteLaborStandart = async id => {
        await fetchAPI('DELETE', `labor_standarts?ids=[${id}]`);
        this.fetchLaborsStandart();
    };

    fetchLaborsStandart = async () => {
        const { query } = this.state;
        const laborsStandart = await fetchAPI('GET', 'labor_standarts', { query }, null, {
            handleErrorInternally: true
        });
        laborsStandart.data.map(lb => {
            lb.vehicleName = `${lb.make || ''} ${lb.model || ''} ${lb.modification || ''}`;
            lb.regionIdName = `${lb.regionName || ''} (#${lb.regionId || ''})`;
            lb.nameWithStoreGroup = `${lb.name || ''} (#${lb.storeGroupId || ''})`;
            lb.sum = Number(lb.hours || 1) * Number(lb.price || 0);
        });
        this.setState({
            laborsStandart
        });
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_mynormative' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    componentDidMount() {
        this.fetchLaborsStandart();
    }

    filterDataSource = dataSource => {
        const { filters, datetimeFrom, datetimeTo } = this.state;
        let result = [...dataSource];
        if (datetimeFrom && datetimeTo) {
            result = result.filter(
                row => dayjs(row.datetime).isAfter(datetimeFrom) && dayjs(row.datetime).isBefore(datetimeTo)
            );
        }
        Object.entries(filters).map(([key, value]) => {
            if (value) {
                result = result.filter(row => {
                    const field = String(_.get(row, key, '')).toLowerCase();

                    return field.includes(value.toLowerCase());
                });
            }
        });

        return result;
    };

    getColumnSearchProps = dataIndexes => {
        return {
            filtered: Boolean(_.get(this.state.filters, dataIndexes, false)),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        placeholder={this.props.intl.formatMessage({ id: 'search' })}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        size='small'
                        style={{ width: 90, marginRight: 8 }}
                        type='primary'
                    >
                        <FormattedMessage id='search' />
                    </Button>
                    <Button
                        onClick={() => this.handleReset(dataIndexes, clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        <FormattedMessage id='reset' />
                    </Button>
                </div>
            ),
            filterIcon: filtered => <FilterOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />,

            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            }
        };
    };

    getColumnDaterangeProps = () => ({
        filtered: _.get(this.state, 'datetime', false),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <DateRangePicker
                    allowClear
                    dateRange={[this.state.datetimeFrom, this.state.datetimeTo]}
                    maximize
                    onDateChange={([startDate, endDate]) => {
                        this.setState({ datetimeFrom: startDate, datetimeTo: endDate });
                    }}
                />
            </div>
        ),
        filterIcon: () => <FilterOutlined style={{ color: this.state.datetime ? 'var(--primary)' : undefined }} />
    });

    handleSearch = (selectedKeys, confirm, dataIndexes) => {
        this.state.filters[`${dataIndexes}`] = selectedKeys[0];
        this.setState({});
    };

    handleReset = (dataIndexes, clearFilters) => {
        clearFilters();
        delete this.state.filters[`${dataIndexes}`];
        this.setState({});
    };

    render() {
        const {
            intl: { formatMessage },
            modal,
            setModal,
            resetModal,
            user
        } = this.props;
        const { laborsStandart, edit, helperDrawerOpen, allLinks } = this.state;
        const columns = this.columns();

        const accessAddButton = isGrantAccessed(user, grants.DIRECTORIES_STANDARDS, accesses.ROWO);

        return (
            <Layout
                controls={
                    <div className={Styles.controlsWrapper}>
                        <Button
                            disabled={!accessAddButton}
                            onClick={() => setModal(MODALS.MY_NORMATIVES_ADD_MODAL)}
                            title={formatMessage({ id: 'my_normative_page.modal_add_normative' })}
                            type='primary'
                        >
                            <FormattedMessage id='add' />
                        </Button>
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                this.setState({
                                    helperDrawerOpen: true
                                });
                                await this.fetchHelperLinks();
                            }}
                            style={{
                                fontSize: 22,
                                marginLeft: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </div>
                }
                title={<FormattedMessage id='my_normative_page.title' />}
            >
                <div className={Styles.filtersCont}>
                    <div className={Styles.inputCont}>
                        <Input.Search
                            allowClear
                            onChange={async ({ target: { value } }) => {
                                await this.setState({
                                    query: value
                                });
                                this.fetchLaborsStandart();
                            }}
                            placeholder={formatMessage({ id: 'vehicles_page.search' })}
                        />
                    </div>
                </div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={this.filterDataSource(laborsStandart.data)}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    rowKey='id'
                    size='small'
                />
                <MyNormativesAddModal
                    resetModal={() => resetModal()}
                    updateTable={this.fetchLaborsStandart}
                    visible={modal}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
