/**
 * Available values used to define priority
 */
export const priorityValues = {
    LOW: 'LOW',
    MIDDLE: 'MIDDLE',
    HIGH: 'HIGH'
};

/**
 * Available values used to define priority
 */
export const statusValues = {
    NEW: 'NEW',
    INWORK: 'INWORK',
    COMPLETED: 'COMPLETED',
    POSTPONED: 'POSTPONED',
    DELEGATED: 'DELEGATED'
};

/**
 * Available type of counterpart
 */
export const counterpartValues = {
    CLIENT: 'CLIENT',
    EMPLOYEE: 'EMPLOYEE',
    BUSINESS_SUPPLIER: 'BUSINESS_SUPPLIER'
};

/**
 * Available type of documents
 */
export const documentTypeValues = {
    ORDER: 'ORDER',
    CASH_ORDER: 'CASH_ORDER',
    STORE_DOC: 'STORE_DOC'
};

export const priorityArrayValues = ['LOW', 'MIDDLE', 'HIGH'];
export const documentTypeArrayValues = ['ORDER', 'CASH_ORDER', 'STORE_DOC'];

export const documentMapTypeValues = {
    CLIENT: ['ORDER', 'CASH_ORDER', 'STORE_DOC'],
    EMPLOYEE: ['CASH_ORDER'],
    BUSINESS_SUPPLIER: ['CASH_ORDER', 'STORE_DOC']
};
