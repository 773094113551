import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, notification, Table } from 'antd';
import { Catcher, Layout } from 'commons';
import _ from 'lodash';
import { WMSCellsModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ProductsWithoutCellPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCell: undefined,
            dataSource: [],
            allLinks: [],
            helperDrawerOpen: false,
            productWarehouseId: [],
            warehouses: []
        };

        this.columns = [
            {
                title: <FormattedMessage id='order_form_table.product_code' />,
                key: 'code',
                dataIndex: 'code',
                sorter: (a, b) => String(a.code).localeCompare(String(b.code)),
                render: (data, row) => {
                    return (
                        <div
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                        >
                            {isGrantAccessed(
                                this.props.user,
                                grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                            ) ? (
                                <Link
                                    to={{
                                        pathname: `${book.product}/${row.id}`,
                                        state: {
                                            specificTab: 'general'
                                        }
                                    }}
                                >
                                    {data}
                                </Link>
                            ) : (
                                data
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='brand' />,
                key: 'brand',
                dataIndex: 'brand',
                sorter: (a, b) => String(a.brandName).localeCompare(String(b.brandName)),
                render: (data, row) => {
                    return _.get(data, 'name', row.brandName);
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'name',
                dataIndex: 'name',
                sorter: (a, b) => String(a.name).localeCompare(String(b.name))
            },
            {
                title: <FormattedMessage id='count' />,
                key: 'count',
                dataIndex: 'count',
                render: (data, row) => {
                    return Number(data - row.wmsCount).toFixed(1);
                }
            },
            {
                key: 'action',
                dataIndex: 'address',
                render: (data, row) => {
                    return (
                        <Button
                            onClick={() => {
                                this.setState({
                                    selectedCell: row
                                });
                                this.fetchProductsInfo(row.id);
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='wms.transfer' />
                        </Button>
                    );
                }
            }
        ];
    }

    _fetchProducts = async () => {
        const dataSource = await fetchAPI('GET', 'store_products', {
            whereWmsCountLessThanCount: true
        });
        this.setState({
            dataSource: dataSource.list
        });
    };

    fetchProductsInfo = async id => {
        const { selectedCell } = this.state;
        const productInfo = await fetchAPI('GET', `store_products/${id}/warehouses`, {
            keeping: true
        });

        this.setState({
            productWarehouseId: productInfo
        });
    };

    fetchWarehouses = async () => {
        const warehouses = await fetchAPI('GET', '/warehouses', null, null);

        this.setState({
            warehouses
        });
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'products_without_cell' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    findCount = count => {
        const { productWarehouseId, warehouses } = this.state;
        const reserveWarehouse = warehouses.find(({ attribute }) => attribute == 'RESERVE');
        const arrayofWarhouses = Object.entries(productWarehouseId)
            .filter(([key, value]) => value >= count && key !== _.get(reserveWarehouse, 'id'))
            .map(([key, value]) => Number(key));

        console.log(arrayofWarhouses.find(el => el >= count));

        return arrayofWarhouses.find(el => el >= count);
    };

    componentDidMount() {
        this._fetchProducts();
        this.fetchWarehouses();
    }

    render() {
        const { user } = this.props;
        const { dataSource, selectedCell, helperDrawerOpen, allLinks, productWarehouseId, warehouses } = this.state;

        return (
            <Layout
                controls={
                    <QuestionCircleOutlined
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            this.setState({
                                helperDrawerOpen: true
                            });
                            await this.fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22,
                            marginLeft: 8,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                }
                title={<FormattedMessage id='navigation.products_without_cell' />}
            >
                <Catcher>
                    <Table bordered columns={this.columns} dataSource={dataSource} size='small' />
                    <WMSCellsModal
                        confirmAction={async (address, modalWarehouseId, count) => {
                            try {
                                await fetchAPI(
                                    'POST',
                                    'wms/cells/products',
                                    null,
                                    {
                                        fromWarehouseId: this.findCount(count),
                                        productId: selectedCell.id,
                                        fromCell: selectedCell.address,
                                        toCell: address,
                                        count,
                                        toWarehouseId: modalWarehouseId
                                    },
                                    {
                                        handleErrorInternally: true
                                    }
                                );
                                await this._fetchProducts();
                            } catch {
                                notification.error({
                                    message: this.props.intl.formatMessage({
                                        id: 'storage_document.error.available_products'
                                    })
                                });
                            }
                        }}
                        hideModal={() => {
                            this.setState({ selectedCell: undefined });
                        }}
                        visible={Boolean(selectedCell)}
                    />
                </Catcher>
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
