// Core
import { intlActions } from 'core/intl/actions';
import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { put } from 'redux-saga/effects';
import { intl } from 'store/intl';
import { setLocale } from 'utils';

// import { locale, messages } from 'store/intl';

export function* initIntlWorker() {
    try {
        yield nprogress.start();

        yield setLocale(intl.locale);

        yield put(intlActions.initIntlSuccess(intl));
    } catch (error) {
        yield put(emitError(error));
    } finally {
        yield nprogress.done();
    }
}
