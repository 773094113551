/*
This file contains functionality to crete cash flow dropdowns.
It creates one header dropdown and its subobjects(children) blocks.
*/

import { Col, Collapse, Row } from 'antd';
import { Numeral } from 'commons';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Style from './styles.m.css';

const DEF_NULL_TEXT = '\u2012';

const { Panel } = Collapse;

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});
@connect(mapStateToProps)
@injectIntl
export default class CashFlowDropdown extends React.Component {
    constructor(props) {
        super(props);
    }

    /**
     * Generates block header using fields from parent object sush as "name".
     * @param {*} analytics Parent object
     */
    genParentHeader(parent) {
        const { isMobile } = this.props;
        return (
            <div style={{width: '100%'}}>
                <Row className={Style.row}>
                    <Col className={Style.colHeader} span={isMobile ? 9 : 12}>
                        {parent.analyticsName}
                    </Col>

                    <Col className={Style.col} span={isMobile ? 5 : 4}>
                        <Numeral mask={isMobile ? '0,0.0' : '0,0.00'}  nullText={DEF_NULL_TEXT}>
                            {parent.totalIncreaseSum}
                        </Numeral>
                    </Col>

                    <Col className={Style.col} span={isMobile ? 6 : 4}>
                        {/* TT requires decreasing sums to be with minus before nuber */}
                        <Numeral mask={isMobile ? '0,0.0' : '0,0.00'}  nullText={DEF_NULL_TEXT}>
                            {-parent.totalDecreaseSum}
                        </Numeral>
                    </Col>

                    <Col className={Style.col} span={4}>
                        <Numeral mask={isMobile ? '0,0.0' : '0,0.00'}  nullText={DEF_NULL_TEXT}>
                            {parent.totalBalanceSum}
                        </Numeral>
                    </Col>
                </Row>
            </div>
        );
    }

    /**
     * Generate children block
     * @param {*} chil children object
     */
    generateChildrenBlock(chil) {
        const { isMobile } = this.props;

        return (
            <div key={chil.analyticsId} className={Style.analyticsCont}>
                <Row className={Style.row}>
                    <Col className={Style.col}  span={isMobile ? 8 : 12}>
                        {chil.analyticsName}
                    </Col>

                    <Col className={Style.col}  span={isMobile ? 6 : 4}>
                        <Numeral mask={isMobile ? '0,0.0' : '0,0.00'} nullText={DEF_NULL_TEXT}>
                            {chil.totalIncreaseSum}
                        </Numeral>
                    </Col>

                    <Col className={Style.col} span={isMobile ? 6 : 4}>
                        {/* TT requires decreasing sums to be with minus before nuber */}
                        <Numeral mask={isMobile ? '0,0.0' : '0,0.00'}  nullText={DEF_NULL_TEXT}>
                            {-chil.totalDecreaseSum}
                        </Numeral>
                    </Col>

                    <Col className={Style.col} span={4}>
                        <Numeral mask={isMobile ? '0,0.0' : '0,0.00'}  nullText={DEF_NULL_TEXT}>
                            {chil.totalBalanceSum}
                        </Numeral>
                    </Col>
                </Row>
            </div>
        );
    }

    /**
     * Generate panel with a header and all its children components
     * @param {*} parent parent component
     * @param {*} children children components
     */
    generatePanel(parent, children) {
        return (
            <Panel key={parent.analyticsId} header={this.genParentHeader(parent)} showArrow={false}>
                {_.map(children, o => this.generateChildrenBlock(o))}
            </Panel>
        );
    }

    render() {
        const { tableData, isMobile } = this.props;

        // Get default active keys, it is used to open all panels as init state
        // let keys = _.reduce(tableData, (arr, obj) => {
        //     const newVal = _.get(obj, 'analyticsId', undefined);
        //     return [...arr, newVal]
        // }, [])

        // keys = _.compact(keys);

        return (
            <div className={isMobile ? Style.mainContMobile : Style.mainCont}>
                <div>
                    <Row className={Style.row}>
                        <Col
                            className={[Style.col.toString(), Style.colHeader.toString()].join(' ')}
                            span={isMobile ? 8 : 12}
                        >
                            <FormattedMessage id='report_cash_flow_page.analytics_name' />
                        </Col>

                        <Col
                            className={[Style.col.toString(), Style.colHeader.toString()].join(' ')}
                            span={isMobile ? 6 : 4}
                        >
                            <FormattedMessage id='report_cash_flow_page.increase' />
                        </Col>

                        <Col
                            className={[Style.col.toString(), Style.colHeader.toString()].join(' ')}
                            span={isMobile ? 6 : 4}
                        >
                            <FormattedMessage id='report_cash_flow_page.decrease' />
                        </Col>

                        <Col
                            className={[Style.col.toString(), Style.colHeader.toString()].join(' ')}
                            span={4}
                        >
                            <FormattedMessage id='report_cash_flow_page.balance' />
                        </Col>
                    </Row>
                </div>

                {/* activeKey={keys} - set this if you want sropdons to be opened by default */}
                <Collapse>
                    {_.map(tableData, obj => {
                        const { children } = obj; // Get children items

                        return this.generatePanel(obj, children);
                    })}
                </Collapse>
            </div>
        );
    }
}
