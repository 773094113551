import { UndoOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const StatusRelationTab = ({ intl, disabled }) => {
    const [subStatuses, setSubStatuses] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const substatuses = await fetchAPI(
            'GET',
            'business_substatuses_relationship',
            { filterByPackage: true },
            null,
            {
                handleErrorInternally: true
            }
        );
        setSubStatuses(substatuses);
        setLoading(false);
    };

    const updateRelation = useCallback(async relation => {
        await fetchAPI('PUT', 'business_substatuses_relationship', null, relation, {
            handleErrorInternally: true
        });
        fetchData();
    }, []);

    const reloadRelations = useCallback(async () => {
        await fetchAPI('DELETE', 'business_substatuses_relationship', null, null, {
            handleErrorInternally: true
        });
        fetchData();
    }, []);

    const columns = useMemo(
        () => [
            {
                title: (
                    <Tooltip title={<FormattedMessage id='diagnostic-page.import_default' />}>
                        <Button icon={<UndoOutlined />} onClick={reloadRelations} type='text' />
                    </Tooltip>
                ),
                dataIndex: 'subStatusShortCut',
                key: 'subStatusShortCut',
                align: 'center',
                rowScope: 'row'
            },
            ...subStatuses.map(({ subStatusShortCut, subStatusId, subStatusIsActive }) => ({
                title: subStatusShortCut,
                key: subStatusShortCut,
                align: 'center',
                onCell: record => {
                    return {
                        onClick: () => {
                            const relation = get(record, `subStatusRelationship[${subStatusId}]`, null);

                            updateRelation({ id: record.id, key: subStatusId, value: Number(!relation) });
                        }
                    };
                },
                render: row => {
                    const relation = get(row, `subStatusRelationship[${subStatusId}]`, null);
                    const available = (relation === 1 || relation === 0) && row.subStatusIsActive && subStatusIsActive;

                    let color = '';
                    switch (relation) {
                        case 0:
                            color = 'var(--db_not_complete)';
                            break;
                        case 1:
                            color = 'var(--db_progress)';
                            break;
                        case 2:
                            color = 'var(--db_success)';
                            break;
                        default:
                            break;
                    }

                    return {
                        props: {
                            style: {
                                backgroundColor:
                                    row.subStatusId === subStatusId || !row.subStatusIsActive || !subStatusIsActive
                                        ? 'var(--cancel)'
                                        : color,
                                cursor: available && !disabled ? 'pointer' : 'not-allowed',
                                pointerEvents: available && !disabled ? 'auto' : 'none'
                            }
                        }
                    };
                }
            }))
        ],
        [reloadRelations, subStatuses, updateRelation, disabled]
    );

    useEffect(() => {
        const setupFetch = async () => {
            await fetchData();
        };
        setupFetch();
    }, []);

    return (
        <Table
            bordered
            className={Styles.transitionsTable}
            columns={columns}
            dataSource={subStatuses}
            loading={loading}
            pagination={false}
            rowKey='id'
            size='small'
        />
    );
};

export default injectIntl(StatusRelationTab);
