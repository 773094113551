exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__filter---26fok{display:flex;justify-content:space-between}.styles-m__search---3FmCW{flex:0 1 40%;margin-bottom:20px}.styles-m__buttonGroup---3wog7{flex:1 1 60%;justify-content:flex-end;display:flex}.styles-m__review---1JPwl{flex:0 1 50%;justify-content:flex-end}.styles-m__cancelReasonSelect---36jey{flex:0 1 40%}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0BACI,YAAa,CACb,6BACJ,CAEA,0BACI,YAAa,CACb,kBACJ,CAEA,+BACI,YAAa,CACb,wBAAyB,CACzB,YACJ,CAEA,0BACI,YAAa,CACb,wBACJ,CAEA,sCACI,YACJ","file":"styles.m.css","sourcesContent":[".filter {\n    display: flex;\n    justify-content: space-between;\n}\n\n.search {\n    flex: 0 1 40%;\n    margin-bottom: 20px;\n}\n\n.buttonGroup {\n    flex: 1 1 60%;\n    justify-content: flex-end;\n    display: flex;\n}\n\n.review {\n    flex: 0 1 50%;\n    justify-content: flex-end;\n}\n\n.cancelReasonSelect {\n    flex: 0 1 40%;\n}\n"]}]);

// Exports
exports.locals = {
	"filter": "styles-m__filter---26fok",
	"search": "styles-m__search---3FmCW",
	"buttonGroup": "styles-m__buttonGroup---3wog7",
	"review": "styles-m__review---1JPwl",
	"cancelReasonSelect": "styles-m__cancelReasonSelect---36jey"
};