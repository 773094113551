/** Constants * */

export const moduleName = 'brandsPage';
const prefix = `cpb/${moduleName}`;

export const FETCH_BRANDS = `${prefix}/FETCH_BRANDS`;
export const FETCH_BRANDS_SUCCESS = `${prefix}/FETCH_BRANDS_SUCCESS`;

export const FETCH_BRANDS_CROSSES = `${prefix}/FETCH_BRANDS_CROSSES`;
export const FETCH_BRANDS_CROSSES_SUCCESS = `${prefix}/FETCH_BRANDS_CROSSES_SUCCESS`;

export const SET_BRANDS_FILTERS = `${prefix}/SET_BRANDS_FILTERS`;
export const SET_BRANDS_CROSSES_FILTERS = `${prefix}/SET_BRANDS_CROSSES_FILTERS`;

export const DELETE_BRAND = `${prefix}/DELETE_BRAND`;
export const DELETE_BRAND_SUCCESS = `${prefix}/DELETE_BRAND_SUCCESS`;

export const DELETE_BRAND_CROSS = `${prefix}/DELETE_BRAND_CROSS`;
export const DELETE_BRAND_CROSS_SUCCESS = `${prefix}/DELETE_BRAND_CROSS_SUCCESS`;

export const SET_EXPANDED_BRAND_ROW_KEY = `${prefix}/SET_EXPANDED_BRAND_ROW_KEY`;

/** Reducer * */
const ReducerState = {
    brands: [], // all brands
    stats: {}, // stats for brands
    filters: {
        // filters for brands
        query: undefined,
        page: 1
    },

    expandedBrandRow: '',
    brandsCrossData: {
        brandsCrosses: [],
        stats: {},
        filters: {
            page: 1
        }
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_BRANDS_SUCCESS:
            const { brands, stats } = payload;

            return {
                ...state,
                brands,
                stats
            };

        case FETCH_BRANDS_CROSSES_SUCCESS:
            const { brandCrosses, brandCrossesStats } = payload;

            return {
                ...state,
                brandsCrossData: {
                    ...state.brandsCrossData,
                    stats: brandCrossesStats,
                    brandsCrosses: brandCrosses
                }
            };

        case SET_BRANDS_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload
                }
            };

        case SET_BRANDS_CROSSES_FILTERS:
            return {
                ...state,
                brandsCrossData: {
                    ...state.brandsCrossData,
                    filters: { ...state.brandsCrossData.filters, ...payload }
                }
            };

        case SET_EXPANDED_BRAND_ROW_KEY:
            return {
                ...state,
                expandedBrandRow: payload
            };

        default:
            return state;
    }
}

/** Selectors * */

export const stateSelector = state => state[moduleName];

export const selectBrands = state => state[moduleName].brands;
export const selectBrandsStats = state => state[moduleName].stats;
export const selectSort = state => state[moduleName].sort;
export const selectFilters = state => state[moduleName].filters;
export const selectExpandedBrandRow = state => state[moduleName].expandedBrandRow;

export const selectBrandCrossData = state => state[moduleName].brandsCrossData;

/** Action Creators * */

/** Fetch all brands */
export const fetchBrands = () => ({
    type: FETCH_BRANDS
});

export const fetchBrandsSuccess = ({ brands, stats }) => ({
    type: FETCH_BRANDS_SUCCESS,
    payload: { brands, stats }
});

export const fetchBrandCrosses = () => ({
    type: FETCH_BRANDS_CROSSES
});

export const fetchBrandsCrossesSuccess = ({ brandCrosses, brandCrossesStats }) => ({
    type: FETCH_BRANDS_CROSSES_SUCCESS,
    payload: { brandCrosses, brandCrossesStats }
});

export const setBrandsFilters = filters => {
    return dispatch => {
        dispatch({
            type: SET_BRANDS_FILTERS,
            payload: filters
        });

        return dispatch(fetchBrands());
    };
};

export const setBrandsCrossesFilters = filters => {
    return dispatch => {
        dispatch({
            type: SET_BRANDS_CROSSES_FILTERS,
            payload: filters
        });

        return dispatch(fetchBrandCrosses());
    };
};

export const deleteBrand = brandId => ({
    type: DELETE_BRAND,
    payload: brandId
});

export const deleteBrandSuccess = () => {
    return function (dispatch) {
        dispatch({
            type: DELETE_BRAND_SUCCESS
        });

        return dispatch(fetchBrands());
    };
};

export const deleteBrandCross = businessSupplierBrandId => ({
    type: DELETE_BRAND_CROSS,
    payload: businessSupplierBrandId
});

export const deleteBrandCrossSuccess = () => {
    return function (dispatch) {
        dispatch({
            type: DELETE_BRAND_CROSS_SUCCESS
        });

        return dispatch(fetchBrandCrosses());
    };
};

export const setBrandRowKey = rowKey => ({
    type: SET_EXPANDED_BRAND_ROW_KEY,
    payload: rowKey
});
