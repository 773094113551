import { Rate } from 'antd';
import React, { Component } from 'react';
// import styled from 'styled-components';

export default class Rating extends Component {
    static defaultProps = {
        formatter: 2,
        allowHalf: true
    };

    render() {
        const { rating, formatter, allowHalf, className, color } = this.props;
        const value = rating / formatter;

        return (
            <Rate
                allowHalf={allowHalf}
                className={className}
                defaultValue={value}
                disabled
                style={{ color: color || 'var(--secondary)' }}
            />
        );
    }
}
