import { Table } from 'antd';
import { MODALS, setModal } from 'core/modals/duck';
import { AddLaborOrDetailToOrderModal } from 'modals';
import {
    selectVehicleAppurtenances,
    selectVehicleAppurtenancesFetching,
    selectVehicleAppurtenancesSort,
    selectVehicleAppurtenancesStats,
    setAppurtenancesBrandSearchQuery,
    setAppurtenancesCodeSearchQuery,
    setAppurtenancesNameSearchQuery,
    setAppurtenancesSort,
    setAppurtenancesSupplierSearchQuery,
    setPageAppurtenances,
    sortValues
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    appurtenances: selectVehicleAppurtenances(state),
    stats: selectVehicleAppurtenancesStats(state),
    sort: selectVehicleAppurtenancesSort(state),
    fetching: selectVehicleAppurtenancesFetching(state)
});

const mapDispatchToProps = {
    setPageAppurtenances,
    setModal,
    setAppurtenancesCodeSearchQuery,
    setAppurtenancesBrandSearchQuery,
    setAppurtenancesNameSearchQuery,
    setAppurtenancesSupplierSearchQuery,
    setAppurtenancesSort
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleAppurtenancesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            selectedRows: []
        };

        const {
            setAppurtenancesCodeSearchQuery,
            setAppurtenancesBrandSearchQuery,
            setAppurtenancesNameSearchQuery,
            setAppurtenancesSupplierSearchQuery
        } = props;

        /** Search appurtenances by code */
        this.handleCodeSearch = _.debounce(value => {
            setAppurtenancesCodeSearchQuery({ codeQuery: value.replace(/[+()]/g, '') });
        }, 1000).bind(this);

        /** Search appurtenances by brand */
        this.handleBrandSearch = _.debounce(value => {
            setAppurtenancesBrandSearchQuery({ brandQuery: value.replace(/[+()]/g, '') });
        }, 1000).bind(this);

        /** Search appurtenances by name */
        this.handleNameSearch = _.debounce(value => {
            setAppurtenancesNameSearchQuery({ nameQuery: value.replace(/[+()]/g, '') });
        }, 1000).bind(this);

        /** Search appurtenances by supplier */
        this.handleSupplierSearch = _.debounce(value => {
            setAppurtenancesSupplierSearchQuery({ supplierQuery: value.replace(/[+()]/g, '') });
        }, 1000).bind(this);
    }

    onAddDetailToOrder = detail => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        this.props.setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
            details: detail,
            mode: 'ADD_DETAIL',
            vehicleId: id
        });
    };

    /** Called when table is changed, used to handle sorting */
    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter) return;

        const { setAppurtenancesSort } = this.props;

        const sortField = _.get(sorter, 'columnKey', undefined);
        const sortOrder = _.get(sorter, 'order', undefined);

        // make sorting object, if no sorting, make all undefined
        const sort = {
            sortField: sortOrder ? sortField : undefined,
            sortOrder: sortOrder == 'ascend' ? sortValues.asc : sortOrder == 'descend' ? sortValues.desc : undefined
        };

        setAppurtenancesSort(sort);
    };

    render() {
        const { appurtenances, stats, sort, setPageAppurtenances, fetching, disabled } = this.props;
        const { selectedRowKeys, selectedRows } = this.state;

        const columns = columnsConfig({
            onAddDetailToOrder: this.onAddDetailToOrder,
            onCodeSearch: this.handleCodeSearch,
            onBrandSearch: this.handleBrandSearch,
            onNameSearch: this.handleNameSearch,
            onSupplierSearch: this.handleSupplierSearch,
            selectedRows,
            selectedRowKeys,
            disabled
        });

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / 25) * 25,
            current: sort.page,
            onChange: page => {
                setPageAppurtenances({ page });
            }
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        return (
            <div className={Styles.tableCont}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columns}
                    dataSource={appurtenances}
                    loading={fetching}
                    onChange={this.handleTableChange}
                    pagination={pagination}
                    rowClassName={() => Styles.tableRow}
                    rowKey='appurtenanceId'
                    rowSelection={rowSelection}
                    scroll={{ x: 'auto', y: '60vh' }}
                />

                <AddLaborOrDetailToOrderModal />
            </div>
        );
    }
}
