/* eslint-disable react/sort-comp */
import { Form, Modal } from 'antd';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { ReportOrdersFilterForm } from 'forms';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    setModal,
    resetModal
};

const DEF_DATE_FORMAT = 'YYYY/MM/DD';

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ReportOrdersFilterModal extends Component {
    onCreationFromDateChanged = date => {
        this.props.setReportOrdersFilterCreationFromDate(
            date ? date.format(DEF_DATE_FORMAT) : undefined
        );
    };

    onCreationToDateChanged = date => {
        this.props.setReportOrdersFilterCreationToDate(
            date ? date.format(DEF_DATE_FORMAT) : undefined
        );
    };

    onAppointmentFromDateChanged = date => {
        this.props.setReportOrdersFilterAppointmentFromDate(
            date ? date.format(DEF_DATE_FORMAT) : undefined
        );
    };

    onAppointmentToDateChanged = date => {
        this.props.setReportOrdersFilterAppointmentToDate(
            date ? date.format(DEF_DATE_FORMAT) : undefined
        );
    };

    onDoneFromDateChanged = date => {
        this.props.setReportOrdersFilterDoneFromDate(
            date ? date.format(DEF_DATE_FORMAT) : undefined
        );
    };

    onDoneToDateChanged = date => {
        this.props.setReportOrdersFilterDoneToDate(date ? date.format(DEF_DATE_FORMAT) : undefined);
    };

    // This method casts data from form to format appropriate for server(and rest of a page components)
    normalizeValuesFromForm = oldValues => {
        const values = { ...oldValues };

        values.creationFromDate = values.creationFromDate
            ? values.creationFromDate.format(DEF_DATE_FORMAT)
            : undefined;
        values.creationToDate = values.creationToDate
            ? values.creationToDate.format(DEF_DATE_FORMAT)
            : undefined;
        values.appointmentFromDate = values.appointmentFromDate
            ? values.appointmentFromDate.format(DEF_DATE_FORMAT)
            : undefined;
        values.appointmentToDate = values.appointmentToDate
            ? values.appointmentToDate.format(DEF_DATE_FORMAT)
            : undefined;
        values.doneFromDate = values.doneFromDate
            ? values.doneFromDate.format(DEF_DATE_FORMAT)
            : undefined;
        values.doneToDate = values.doneToDate
            ? values.doneToDate.format(DEF_DATE_FORMAT)
            : undefined;

        // Some values are redundant, we need to remove them
        values.creationDateRange = undefined;
        values.appointmentDateRange = undefined;
        values.doneDateRange = undefined;

        return values;
    };

    // When submit button was pressed
    // It converts and transfers data from form to state
    onFiltersSubmit = values => {
        this.props.onSubmit(this.normalizeValuesFromForm(values));
    };

    render() {
        return (
            <FuncCompModal
                onFiltersClose={this.onFiltersClose}
                onFiltersSubmit={this.onFiltersSubmit}
                {...this.props}
            />
        );
    }
}

const FuncCompModal = ({
    onCloseFilterModal,
    onFiltersSubmit,
    visible,
    filter,
    filterOptions,
    resetModal
}) => {
    const [form] = Form.useForm();

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            onCancel={() => {
                form.resetFields();
                onCloseFilterModal();
            }}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        resetModal();
                        form.resetFields();
                        onFiltersSubmit(values);
                    })
                    .catch(() => {});
            }}
            visible={visible === MODALS.REPORT_ORDERS_FILTER}
            width='95%'
        >
            <ReportOrdersFilterForm filter={filter} filterOptions={filterOptions} form={form} />
        </Modal>
    );
};
