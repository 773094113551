import { Tooltip } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

const colors = {
    DONE: {
        background: '#C8E1B4',
        color: 'var(--text3)'
    }
};

const OrderMapIndicator = ({ data, style, scrollToId }) => {
    const basicData = get(data, '[0]');

    if (!basicData) return null;

    return (
        <div className={Styles.repairMapIndicator} style={style}>
            <Tooltip
                overlayInnerStyle={{ minWidth: 'min-content' }}
                placement='right'
                title={() => (
                    <div className={Styles.repairMapIndicator}>
                        {(data || []).map(item => {
                            const {
                                shortCut,
                                subStatus,
                                currentSubStatusName,
                                currentColor,
                                previousSubStatusName,
                                previousColor,
                                subStatusTab
                            } = item;

                            return (
                                <Tooltip
                                    key={`RepairMapIndicator-${shortCut}`}
                                    placement='top'
                                    title={<FormattedMessage id={`status_directory.${subStatus}`} />}
                                >
                                    <div
                                        className={`${Styles.indicatorElement}`} // ${Styles[color]}
                                        onClick={() => {
                                            if (scrollToId && subStatusTab) {
                                                scrollToId(subStatusTab);
                                            }
                                        }}
                                        style={{
                                            color: 'var(--text)',
                                            background: 'white',
                                            ...(!item[subStatus] && { backgroundColor: 'white' }),
                                            ...(item[subStatus] === 'DONE' && {
                                                backgroundColor: colors[basicData[subStatus]].background,
                                                color: colors[basicData[subStatus]].color
                                            }),
                                            ...(subStatus === currentSubStatusName && {
                                                backgroundColor: currentColor || 'white'
                                            }),
                                            ...(subStatus === previousSubStatusName && {
                                                backgroundColor: previousColor || 'white'
                                            })
                                        }}
                                    >
                                        {shortCut}
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </div>
                )}
            >
                <div className={Styles.repairMapIndicator} style={style}>
                    {basicData.previousSubStatusName && (
                        <Tooltip
                            key={`indicator-${basicData.previousShortCut}`}
                            placement='top'
                            title={<FormattedMessage id={`status_directory.${basicData.previousSubStatusName}`} />}
                        >
                            <div
                                className={`${Styles.indicatorElement}`}
                                style={{
                                    background: basicData.previousColor || 'white'
                                }}
                            >
                                {basicData.previousShortCut}
                            </div>
                        </Tooltip>
                    )}
                    <Tooltip
                        key={`indicator-${basicData.currentShortCut}`}
                        placement='top'
                        title={<FormattedMessage id={`status_directory.${basicData.currentSubStatusName}`} />}
                    >
                        <div
                            className={`${Styles.indicatorElement}`}
                            style={{
                                background: basicData.currentColor || 'white'
                            }}
                        >
                            {basicData.currentShortCut}
                        </div>
                    </Tooltip>
                </div>
            </Tooltip>
        </div>
    );
};

export default OrderMapIndicator;
