import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, delay, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    DELETE_STORE_DOC_SERVICES,
    fetchStoreDocServicesSuccess,
    FETCH_STORE_DOC_SERVICES,
    selectFilters
} from './duck';

export function* fetchStoreDocServicesSaga() {
    while (true) {
        try {
            yield take(FETCH_STORE_DOC_SERVICES);

            yield nprogress.start();

            yield delay(1000);

            const filters = yield select(selectFilters);

            const { storeDocServices, stats } = yield call(fetchAPI, 'GET', '/store_doc_services', {
                filters
            });

            yield put(fetchStoreDocServicesSuccess({ storeDocServices, stats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* deleteStoreDocServiceSaga() {
    while (true) {
        const { payload } = yield take(DELETE_STORE_DOC_SERVICES);

        try {
            if (_.isArray(payload)) {
                yield payload.map(id =>
                    call(fetchAPI, 'DELETE', `store_doc_service/${id}`, null, null, {
                        handleErrorInternally: true
                    })
                );
            } else {
                yield call(fetchAPI, 'DELETE', `store_doc_service/${payload}`, null, null, {
                    handleErrorInternally: true
                });
            }
        } catch ({ response, status }) {
            yield put(emitError({ response, status }));
        }
    }
}

export function* saga() {
    yield all([call(fetchStoreDocServicesSaga), call(deleteStoreDocServiceSaga)]);
}
