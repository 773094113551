import { Modal, Table } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import './styles.less';

@injectIntl
class SupplierSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: []
        };
        this.columns = [
            {
                title: <FormattedMessage id='order_form_table.choose_suppliers' />,
                key: 'name',
                dataIndex: 'name'
            }
        ];
    }

    componentDidMount = async () => {
        const suppliers = await fetchAPI('GET', 'business_suppliers_pricelists');
        this.setState({
            suppliers
        });
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            const selectedRowKeys = localStorage.getItem('_my.carbook.detail_suppliers');
            if (selectedRowKeys) {
                this.setState({
                    selectedRowKeys: JSON.parse(selectedRowKeys)
                });
            }
        }
    }

    render() {
        const { visible, hideModal } = this.props;
        const { selectedRowKeys, suppliers } = this.state;

        const rowSelection = {
            onChange: selectedRowKeys => {
                this.setState({ selectedRowKeys });
            },
            selectedRowKeys
        };

        return (
            <Modal
                maskClosable={false}
                onCancel={() => {
                    hideModal();
                    this.setState({ selectedRowKeys: undefined });
                }}
                onOk={() => {
                    hideModal();
                    localStorage.setItem(
                        '_my.carbook.detail_suppliers',
                        JSON.stringify(selectedRowKeys)
                    );
                }}
                title={<FormattedMessage id='order_form_table.choose_supplier' />}
                visible={visible}
            >
                {/* <div>
                    <Checkbox style={{ display: 'flex', justifyContent: 'center' }}>
                        <FormattedMessage id='order_form_table.include_all' />
                    </Checkbox>
                </div> */}
                <Table
                    columns={this.columns}
                    dataSource={suppliers}
                    pagination={{ hideOnSinglePage: true }}
                    rowKey='id'
                    rowSelection={rowSelection}
                />
            </Modal>
        );
    }
}

export default SupplierSelectModal;
