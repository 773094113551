import { setView as setReduxView } from 'core/ui/duck';
import { throttle } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { _breakpoints } from 'utils';

const mapStateToProps = state => ({ isMobile: state.ui.views.isMobile });
const mapDispatchToProps = { setReduxView };

const withResponsive = Enhanceable => {
    @connect(mapStateToProps, mapDispatchToProps, null)
    class ResponsiveHOC extends React.Component {
        componentDidMount() {
            this.setView();
            window.addEventListener('resize', throttle(this.setView, 500));
            // window.addEventListener('reload', this.setView);
            // window.addEventListener('orientationchange', debounce(this.setView, 250));
        }

        componentWillUnmount() {
            window.removeEventListener('resize', throttle(this.setView, 500));
        }

        setView = () => {
            this.props.setReduxView({
                isMobile: window.innerWidth < _breakpoints.mobile.max,
                isTablet:
                    _breakpoints.tablet.min <= window.innerWidth &&
                    _breakpoints.tablet.max >= window.innerWidth,
                isDesktop: _breakpoints.desktop.min <= window.innerWidth
            });
        };

        render() {
            return <Enhanceable {...this.props} />;
        }
    }

    return ResponsiveHOC;
};

export default withResponsive;
