import { emitError, setReviewsFetchingState } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchReviewsSuccess, FETCH_REVIEWS, selectReviewsFilter } from './duck';

export function* fetchReviewsSaga() {
    while (true) {
        try {
            yield take(FETCH_REVIEWS);
            yield put(setReviewsFetchingState(true));

            const filter = yield select(selectReviewsFilter);

            const data = yield call(fetchAPI, 'GET', 'reviews', filter);
            yield put(fetchReviewsSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setReviewsFetchingState(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchReviewsSaga)]);
}
