import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI } from 'utils';
import {
    CREATE_ORDER_FOR_CLIENT,
    FETCH_CLIENTS,
    FETCH_CLIENT_ORDERS,
    fetchClientOrdersSuccess,
    fetchClientsSuccess,
    selectClientDebt,
    selectFilters,
    selectOmitStats,
    selectSort,
    setClientOrdersFetching,
    setClientsFetching
} from './duck';

export function* fetchClientsSaga() {
    while (true) {
        try {
            yield take(FETCH_CLIENTS);
            yield put(setClientsFetching(true));

            const filters = yield select(selectFilters);
            const sort = yield select(selectSort);
            const clientDebt = yield select(selectClientDebt);

            const omitStats = yield select(selectOmitStats);

            const { clients, stats } = yield call(fetchAPI, 'GET', '/clients', {
                filters,
                sort,
                options: { clientDebt, omitStats }
            });

            yield put(fetchClientsSuccess({ clients, stats }));
        } finally {
            yield put(setClientsFetching(false));
        }
    }
}

export function* fetchClientOrdersSaga() {
    while (true) {
        try {
            const {
                payload: { clientId }
            } = yield take(FETCH_CLIENT_ORDERS);
            yield put(setClientOrdersFetching(true));

            const { orders, stats } = yield call(fetchAPI, 'GET', `/orders/client/${clientId}`);

            yield put(fetchClientOrdersSuccess({ orders, stats }));
        } finally {
            yield put(setClientOrdersFetching(false));
        }
    }
}

export function* createOrderForClientSaga() {
    while (true) {
        const {
            payload: { clientId, managerId, vehicleId }
        } = yield take(CREATE_ORDER_FOR_CLIENT);
        if (!clientId) {
            continue;
        }

        // Get client
        const client = yield call(fetchAPI, 'GET', `clients/${clientId}`);
        const vehicle = vehicleId
            ? yield call(fetchAPI, 'GET', `clients/vehicles/${vehicleId}`)
            : _.get(client, 'vehicles[0]');

        try {
            const response = yield call(
                fetchAPI,
                'POST',
                'orders',
                null,
                {
                    clientId: client.clientId,
                    clientVehicleId: _.get(vehicle, 'id'),
                    clientVehicleTypeId: _.get(vehicle, 'vehicleTypeId'),
                    clientVehicleRadius: _.get(vehicle, 'wheelRadius'),
                    duration: 0.5,
                    clientPhone: client.phones[0],
                    stationLoads: [
                        {
                            beginDatetime: dayjs().startOf('hour').toISOString(),
                            duration: 0.5,
                            status: 'TO_DO'
                        }
                    ],
                    status: 'not_complete',
                    managerId,
                    beginDatetime: dayjs().startOf('hour').toISOString()
                },
                { handleErrorInternally: true }
            );

            if (response && response.created) {
                // If successfully created new order redirect on its page
                history.push({
                    pathname: `${book.order}/${response.created[0].id}`
                });
            }
        } catch (err) {
            const { response } = err;
            response &&
                notification.error({
                    message: response.message
                });
        }
    }
}

export function* saga() {
    yield all([call(fetchClientsSaga), call(fetchClientOrdersSaga), call(createOrderForClientSaga)]);
}
