import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { answered } from 'core/calls/config';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const defWidth = {
    date: '10%',
    status: '5%',
    order: '10%',
    caller: '10%',
    recipient: '10%',
    waiting: '10%',
    duration: '10%',
    innerRecipient: '10%',
    record: '30%'
};

export function columnsConfig({ fetchRecordingLink, callsLinksCache, user }) {
    const date = {
        title: <FormattedMessage id='calls-table.date' />,
        width: defWidth.date,
        dataIndex: 'datetime',
        key: 'date',
        render: date => <div className={Styles.datetime}>{dayjs(date).format('YYYY-MM-DD HH:mm')}</div>
    };

    const status = {
        title: <FormattedMessage id='calls-table.status' />,
        width: defWidth.status,
        dataIndex: 'status',
        key: 'status',
        render: status => (
            <React.Fragment>
                {answered.includes(status) ? (
                    <CheckCircleOutlined
                        style={{
                            color: 'var(--secondary)',
                            fontSize: 24
                        }}
                    />
                ) : (
                    <CloseCircleOutlined
                        style={{
                            color: 'var(--warning)',
                            fontSize: 24
                        }}
                    />
                )}
            </React.Fragment>
        )
    };

    const caller = {
        title: <FormattedMessage id='calls-table.caller' />,
        width: defWidth.caller,
        dataIndex: 'caller',
        key: 'caller',
        render: (caller, row, index) => (
            <a className={Styles.orderLink} href={`tel:${caller}`}>
                {caller}
            </a>
        )
    };

    const recipient = {
        title: <FormattedMessage id='calls-table.recipient' />,
        width: defWidth.recipient,
        dataIndex: 'recipient',
        key: 'recipient',
        render: recipient => (
            <a className={Styles.orderLink} href={`tel:${recipient}`}>
                {recipient}
            </a>
        )
    };

    const waiting = {
        title: <FormattedMessage id='calls-table.waiting' />,
        width: defWidth.waiting,
        dataIndex: 'waiting',
        key: 'waiting',
        render: waiting => <div>{waiting}</div>
    };

    const duration = {
        title: <FormattedMessage id='calls-table.duration' />,
        width: defWidth.duration,
        dataIndex: 'duration',
        key: 'duration',
        render: duration => <div>{duration}</div>
    };

    const innerRecipient = {
        title: <FormattedMessage id='calls-table.innerRecipient' />,
        width: defWidth.innerRecipient,
        dataIndex: 'innerRecipient',
        key: 'innerRecipient',
        render: innerRecipient => (
            <a className={Styles.orderLink} href={`tel:${innerRecipient}`}>
                {innerRecipient}
            </a>
        )
    };

    const record = {
        title: <FormattedMessage id='calls-table.record' />,
        width: defWidth.record,
        dataIndex: 'recordingLink',
        render: (val, call) => {
            return String(call.id) in callsLinksCache ? ( // Check if that key exists in cash memory
                callsLinksCache[call.id] ? ( // False for empty rows where key exists in the cash memory or if call was not accepted
                    <audio controls>
                        <source src={callsLinksCache[call.id]} />
                    </audio>
                ) : (
                    <FormattedMessage id='calls-table.no_record' />
                )
            ) : answered.includes(call.status) ? (
                <div>
                    <Button
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_CALLS_LISTENING)}
                        onClick={() => fetchRecordingLink({ callId: call.id })}
                        type='primary'
                    >
                        <FormattedMessage id='calls-table.show_record' />
                    </Button>
                </div>
            ) : (
                <FormattedMessage id='calls-table.no_record' />
            );
        }
    };

    return [date, status, caller, recipient, waiting, duration, innerRecipient, record];
}
