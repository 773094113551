import { all } from 'redux-saga/effects';
//
// export function* saveNewOrderTask() {
//     while (true) {
//         try {
//             const { payload, id, taskId, myTasks } = yield take(
//                 SAVE_ORDER_TASK,
//             );
//             let data; // eslint-disable-line
//             let obj = {
//                 comment:       payload.comment,
//                 priority:      payload.priority,
//                 responsibleId: payload.responsible,
//                 status:        payload.status,
//                 stationNum:    payload.stationName,
//             };
//
//             if (payload.deadlineDate) {
//                 obj.deadlineDate = dayjs(
//                     `${dayjs(payload.deadlineDate).format('YYYY-MM-DD')} ${
//                         payload.deadlineTime
//                             ? dayjs(payload.deadlineTime).format('HH:mm')
//                             : '00:00'
//                     }`,
//                 ).format();
//             }
//
//             if (!taskId) {
//                 data = yield call(
//                     fetchAPI,
//                     'POST',
//                     `orders/${id}/tasks`,
//                     null,
//                     obj,
//                 );
//             } else {
//                 data = yield call(
//                     fetchAPI,
//                     'PUT',
//                     `/orders/tasks/${taskId}`,
//                     null,
//                     obj,
//                 );
//             }
//
//             yield put(saveOrderTaskSuccess(data));
//
//             if (myTasks) {
//                 yield put(fetchMyTasks());
//             } else {
//                 yield put(fetchOrderTask(id));
//             }
//         } catch (error) {
//             yield put(emitError(error));
//         }
//     }
// }
export function* saga() {
    yield all([
        // call(saveNewOrderTask)
    ]);
}

export function test() {
    fetch('https://api.example.com/items').then(res => res.json());
}
