import { Layout, Spinner } from 'commons';
import { PackageContainer } from 'containers';
import { fetchPackages } from 'core/package/duck';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        isFetching: state.ui.packageFetching,
        packages: state.packages.packages
    };
};

const mapDispatchToProps = {
    fetchPackages
};

@connect(mapStateToProps, mapDispatchToProps)
export default class PackagePage extends Component {
    componentDidMount() {
        this.props.fetchPackages();
    }

    render() {
        const { isFetching, packages } = this.props;

        return isFetching ? (
            <Spinner spin={isFetching} />
        ) : (
            <Layout title={<FormattedMessage id='packages' />}>
                <PackageContainer packages={packages} />
            </Layout>
        );
    }
}
