import { mainModuleName } from 'pages/OnlineServiceBookPage/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'inspectionIntervals';
const prefix = `cpb/onlineServiceBook/${moduleName}`; // Hardcode, "mainModuleName" must me here

export const FETCH_VEHICLE_INTERVALS = `${prefix}/FETCH_VEHICLE_INTERVALS`;
export const FETCH_VEHICLE_INTERVALS_SUCCESS = `${prefix}/FETCH_VEHICLE_INTERVALS_SUCCESS`;

export const SET_FETCHING_VEHICLE_INTERVALS = `${prefix}/SET_FETCHING_VEHICLE_INTERVALS`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    fetchingVehicleIntervals: false,
    intervals: []
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_VEHICLE_INTERVALS_SUCCESS:
            const { intervals } = payload;

            return {
                ...state,
                intervals
            };

        case SET_FETCHING_VEHICLE_INTERVALS:
            return {
                ...state,
                fetchingVehicleIntervals: payload
            };

        default:
            return state;
    }
}

/** ------------------------------------- Selectors ------------------------------------- * */

export const selectVehicleInspectionIntervals = state =>
    state[mainModuleName][moduleName].intervals;
export const selectVehicleInspectionIntervalsFetching = state =>
    state[mainModuleName][moduleName].fetchingVehicleIntervals;

/* ------------------------------------- Action Creators ------------------------------------- */

/**
 * Fetches orders where vehicle was participating.
 * @param {*} params.vehicleId Vehicle to fetch data for
 */
export const fetchVehicleIntervals = () => ({
    type: FETCH_VEHICLE_INTERVALS
});

export const fetchVehicleIntervalsSuccess = ({ intervals }) => ({
    type: FETCH_VEHICLE_INTERVALS_SUCCESS,
    payload: { intervals }
});

export const setFetchingVehicleIntervals = value => ({
    type: SET_FETCHING_VEHICLE_INTERVALS,
    payload: value
});
