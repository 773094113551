import { Modal } from 'antd';
import { loadModal, MODALS } from 'core/modals/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    modalProps: state.modals.modalProps
});

const mapDispatchToProps = {
    loadModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class PhotoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    handleCancel = () => {
        const {
            resetModal,
            loadModal,
            modalProps: { modalToLoad }
        } = this.props;
        if (modalToLoad) {
            loadModal(modalToLoad);
        } else {
            resetModal();
        }
    };

    render() {
        const {
            modal,
            modalProps: { src, additionalData }
        } = this.props;

        return (
            <Modal
                footer={null}
                maskClosable={false}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='photo' />}
                visible={modal === MODALS.PHOTO_MODAL}
                width='45%'
            >
                <div style={{ textAlign: 'center' }}>
                    <img
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                        }}
                        src={src}
                        style={{ cursor: 'pointer' }}
                        width='70%'
                    />
                </div>
                {additionalData}
            </Modal>
        );
    }
}

export default PhotoModal;
