exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__salaryReportSelect---1_jx-{width:100%}.styles-m__colAlignCenter---3fslA,.styles-m__colCenter---2D9v2{display:flex;align-items:center;justify-content:center}.styles-m__colAlignLeft---XkRw6{display:flex;align-items:flex-start}.styles-m__row---25wZk{margin-top:1em;align-items:center}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,sCACI,UACJ,CAQA,+DACI,YAAa,CACb,kBAAmB,CACnB,sBACJ,CAEA,gCACI,YAAa,CACb,sBAEJ,CAEA,uBACI,cAAe,CACf,kBACJ","file":"styles.m.css","sourcesContent":[".salaryReportSelect {\n    width: 100%;\n}\n\n.colCenter {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.colAlignCenter {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.colAlignLeft {\n    display: flex;\n    align-items: flex-start;\n    /*justify-content: center;*/\n}\n\n.row {\n    margin-top: 1em;\n    align-items: center;\n}"]}]);

// Exports
exports.locals = {
	"salaryReportSelect": "styles-m__salaryReportSelect---1_jx-",
	"colCenter": "styles-m__colCenter---2D9v2",
	"colAlignCenter": "styles-m__colAlignCenter---3fslA",
	"colAlignLeft": "styles-m__colAlignLeft---XkRw6",
	"row": "styles-m__row---25wZk"
};