import { DeleteOutlined, FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Table, Tooltip } from 'antd';
import { Catcher } from 'commons';
import dayjs from 'dayjs';
import { AddOwnCrossModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';

@withRouter
@injectIntl
export default class CrossesTable extends Component {
    constructor(props) {
        super(props);
        this.state = { page: 1, productCrosses: [], filters: {} };
        this.columns = () => [
            {
                title: <FormattedMessage id='diagnostic-page.code' />,
                key: 'code',
                dataIndex: 'code',
                ...this.getColumnSearchProps('code')
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brandId',
                dataIndex: 'brandName',
                ...this.getColumnSearchProps('brandId')
            },
            {
                title: <FormattedMessage id='navigation.crosses' />,
                key: 'xCode',
                dataIndex: 'xCode',
                ...this.getColumnSearchProps('xCode')
            },
            {
                title: <FormattedMessage id='directories.x_brand' />,
                key: 'xBrandId',
                dataIndex: 'xBrandName',
                ...this.getColumnSearchProps('xBrandId')
            },
            {
                title: <FormattedMessage id='directories.direcroty' />,
                key: 'dir',
                dataIndex: 'dir',
                ...this.getColumnSearchProps('dir')
            },
            {
                key: 'delete',
                render: row => {
                    return (
                        <div>
                            <Popconfirm
                                onConfirm={() => this.deleteCrosses(row.id)}
                                title={`${props.intl.formatMessage({ id: 'delete' })} ?`}
                            >
                                <Button
                                    style={{
                                        padding: '0px 8px',
                                        fontSize: 18
                                    }}
                                >
                                    <DeleteOutlined
                                        style={{ color: 'red', cursor: 'pointer' }}
                                        title={this.props.intl.formatMessage({
                                            id: 'directory_page.delete'
                                        })}
                                    />
                                </Button>
                            </Popconfirm>
                        </div>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.fetchProductCrosses();
    }

    filterDataSource = dataSource => {
        const { filters, datetimeFrom, datetimeTo } = this.state;
        let result = [...dataSource];
        if (datetimeFrom && datetimeTo) {
            result = result.filter(
                row =>
                    dayjs(row.datetime).isAfter(datetimeFrom) &&
                    dayjs(row.datetime).isBefore(datetimeTo)
            );
        }
        if (this.props.productCode) {
            result = result.filter(row => row.code === this.props.productCode);
        }
        Object.entries(filters).map(([key, value]) => {
            if (value) {
                result = result.filter(row => {
                    const field = String(_.get(row, key, '')).toLowerCase();

                    return field.includes(value.toLowerCase());
                });
            }
        });

        return result;
    };

    getColumnSearchProps = dataIndexes => {
        return {
            filtered: Boolean(_.get(this.state.filters, dataIndexes, false)),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        placeholder={this.props.intl.formatMessage({ id: 'search' })}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        size='small'
                        style={{ width: 90, marginRight: 8 }}
                        type='primary'
                    >
                        <FormattedMessage id='search' />
                    </Button>
                    <Button
                        onClick={() => this.handleReset(dataIndexes, clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        <FormattedMessage id='reset' />
                    </Button>
                </div>
            ),
            filterIcon: filtered => (
                <FilterOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />
            ),

            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            }
        };
    };

    fetchProductCrosses = async () => {
        const { code, brandId, xCode, xBrandId, dir, page } = this.state;
        const brands = await fetchAPI('GET', 'brands');
        const { list, stats } = await fetchAPI(
            'GET',
            'product_crosses',
            {
                page,
                codeQuery: code,
                brandId,
                xCodeQuery: xCode,
                xBrandId,
                dir,
                pageSize: 15
            },
            null,
            { handleErrorInternally: true }
        );

        this.setState({ productCrosses: list, crossesStats: stats[0] });
    };

    deleteCrosses = async id => {
        await fetchAPI('DELETE', 'product_crosses', null, { ids: [id] });
        this.fetchProductCrosses();
    };

    render() {
        const { brand, productCode } = this.props;
        const { visibleAddOwnCross, productCrosses, crossesStats, page } = this.state;

        return (
            <Catcher>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    <Tooltip title={<FormattedMessage id='product_crosses.add_own_cross' />}>
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => {
                                this.setState({
                                    visibleAddOwnCross: true
                                });
                            }}
                        />
                    </Tooltip>
                </div>
                <Table
                    bordered
                    columns={this.columns()}
                    dataSource={this.filterDataSource(productCrosses)}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={{
                        pageSize: 15,
                        size: 'small',
                        total: Math.ceil(_.get(crossesStats, 'count', 0) / 15) * 15,
                        hideOnSinglePage: true,
                        current: page,
                        onChange: async page => {
                            await this.setState({ page });
                            this.fetchProductCrosses();
                        }
                    }}
                    rowKey='id'
                    size='small'
                    style={{
                        marginTop: 18
                    }}
                />
                <AddOwnCrossModal
                    brand={brand}
                    fetchProductCrosses={this.fetchProductCrosses}
                    hideModal={() => {
                        this.setState({
                            visibleAddOwnCross: false
                        });
                    }}
                    productCode={productCode}
                    visible={visibleAddOwnCross}
                />
            </Catcher>
        );
    }
}
