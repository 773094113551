import { PrinterOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import book from 'routes/book';
import { isForbidden, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const ACT_OF_ACCEPTANCE_REPORT = 'actOfAcceptanceReport'; // actOfAcceptanceReport -> акт приема работ
const REQUEST_ORDER_REPORT = 'requestOrderReport'; // requestOrderReport - Заявка в цех
const BUSINESS_ORDER_REPORT = 'businessOrderReport'; // businessOrderReport -> наряд заказ в цех
const DIAGNOSTICS_BLANK_REPORT = 'diagnosticsBlankReport'; // diagnosticsBlankReport -> діагностичний бланк
const DIAGNOSTICS_RESULT_REPORT = 'diagnosticsResultReport'; // diagnosticsResultReport -> результат діагностики
const CALCULATION_REPORT = 'calculationReport'; // calculationReport - калькуляция
const CLIENT_ORDER_REPORT = 'clientOrderReport'; // clientOrderReport -> наряд заказ
const COMPLETED_WORK_REPORT = 'completedWorkReport'; // completedWorkReport -> акт выполненых работ
const INVOICE_REPORT = 'invoiceReport'; // invoiceReport -> счет-фактура
const WARRANTY_REPORT = 'warrantyReport'; // Гарантийный талон
const TECHNICAL_CONCLUSION_REPORT = 'technicalConclusionReport'; // Техническое заключение
const SALES_INVOICE_REPORT = 'salesInvoiceReport'; // Расходная накладная
const SERVICE_AGREEMENT_REPORT = 'serviceAgreementReport'; // Договор про услуги
const PARTS_AGREEMENT_REPORT = 'partsAgreementReport'; // Договор про продажу автозапчастей
const PACKING_LIST_WITH_ADDRESSES_REPORT = 'packingListWithAddressesReport'; // Збірний список по з/ч
const DIAGNOSTICS_REPORT_XLSX = 'diagnosticsReportXLSX'; // Экспорт н/з в .xlsx
const RETAIL_REPORT = 'retailReport';

class ReportsDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.reports = ReportsDropdown.getReports(props);
    }

    static getReports(props) {
        const { user, orderId, orderStatus, isRetailOrder } = props;

        if (!orderStatus) {
            return [];
        }

        let all = [
            ACT_OF_ACCEPTANCE_REPORT,
            REQUEST_ORDER_REPORT,
            DIAGNOSTICS_BLANK_REPORT,
            DIAGNOSTICS_RESULT_REPORT,
            CALCULATION_REPORT,
            INVOICE_REPORT,

            BUSINESS_ORDER_REPORT,
            CLIENT_ORDER_REPORT,
            PACKING_LIST_WITH_ADDRESSES_REPORT,
            DIAGNOSTICS_REPORT_XLSX,

            WARRANTY_REPORT,
            TECHNICAL_CONCLUSION_REPORT,
            SERVICE_AGREEMENT_REPORT,
            PARTS_AGREEMENT_REPORT,

            SALES_INVOICE_REPORT,
            COMPLETED_WORK_REPORT,
            RETAIL_REPORT
        ];

        if (!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS)) {
            all = all.filter(
                report =>
                    ![DIAGNOSTICS_BLANK_REPORT, DIAGNOSTICS_RESULT_REPORT, DIAGNOSTICS_REPORT_XLSX].includes(report)
            );
        }
        if (!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_REQUEST)) {
            all = all.filter(report => ![REQUEST_ORDER_REPORT].includes(report));
        }
        if (isRetailOrder) {
            all = all.filter(report =>
                [INVOICE_REPORT, PARTS_AGREEMENT_REPORT, SALES_INVOICE_REPORT, RETAIL_REPORT].includes(report));
        }

        const statusToReportsMap = {
            not_complete: all, // eslint-disable-line camelcase
            required: all,
            reserve: all,
            call: all,
            approve: all,
            progress: all,
            success: all,
            invite: all,
            cancel: all,
            stop: all,
            processing: all
        };
        const reports = statusToReportsMap[orderStatus].map(name => {
            return {
                name,
                link: `${book.reports}/${name}/${orderId}`
            };
        });

        return reports;
    }

    render() {
        const { isMobile, orderId, reportBlock } = this.props;
        const rtlMenu = (
            <Menu>
                {this.reports.map((item, index) => (
                    <Menu.Item
                        key={`${index}-${item.name}`}
                        className={`${item.disabled && Styles.itemDisabled}`}
                        onClick={() => this.props.download(item)}
                        style={
                            item.name == BUSINESS_ORDER_REPORT ||
                            item.name == WARRANTY_REPORT ||
                            item.name == SALES_INVOICE_REPORT
                                ? { borderTop: '1px solid gray' }
                                : null
                        }
                    >
                        {item.icon}
                        <FormattedMessage id={item.name} />
                    </Menu.Item>
                ))}
            </Menu>
        );
        const menu = reportBlock ? (
            <Menu>
                {this.reports
                    .filter(item => reportBlock.includes(item.name))
                    .map((item, index) => (
                        <Menu.Item
                            key={`${index}-${item.name}`}
                            className={`${item.disabled && Styles.itemDisabled}`}
                            onClick={() => this.props.download(item)}
                            style={
                                item.name == BUSINESS_ORDER_REPORT ||
                                item.name == WARRANTY_REPORT ||
                                item.name == SALES_INVOICE_REPORT
                                    ? { borderTop: '1px solid gray' }
                                    : null
                            }
                        >
                            {item.icon}
                            <FormattedMessage id={item.name} />
                        </Menu.Item>
                    ))}
            </Menu>
        ) : (
            <Menu>
                {this.reports.map((item, index) => (
                    <Menu.Item
                        key={`${index}-${item.name}`}
                        className={`${item.disabled && Styles.itemDisabled}`}
                        onClick={() => this.props.download(item)}
                        style={
                            item.name == BUSINESS_ORDER_REPORT ||
                            item.name == WARRANTY_REPORT ||
                            item.name == SALES_INVOICE_REPORT
                                ? { borderTop: '1px solid gray' }
                                : null
                        }
                    >
                        {item.icon}
                        <FormattedMessage id={item.name} />
                    </Menu.Item>
                ))}
            </Menu>
        );

        const forbidden = isForbidden(this.props.user, permissions.PRINT_ORDERS);

        return (
            <Dropdown disabled={forbidden} overlay={!this.props.isRetailOrder ? menu : rtlMenu}>
                <Button icon={<PrinterOutlined style={{ fontSize: isMobile ? 14 : 24 }} />} size='large' type='text' />
            </Dropdown>
        );
    }
}

export default ReportsDropdown;
