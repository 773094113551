import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { notification, Popconfirm } from 'antd';
import { Numeral } from 'commons';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

// Choose width for each col, (100% in total or less than 100% if 'auto' is present)
const defWidth = {
    supplierCol: 'auto',
    contactCol: '15%',
    phoneCol: '10%',
    debtCol: '10%',
    debtExpiredCol: '10%',
    debtNotExpiredCol: '10%',
    ordersCountCol: '10%',
    deliveriesCol: '10%',
    displayCol: '6%',
    actionsCol: '6%'
};

const mask = '0,0.00';

export function columnsConfig(props) {
    const { user, formatMessage, fetchBusinessSuppliers } = props;

    const supplierCol = {
        title: <FormattedMessage id='suppliers_page.supplier' />,
        align: 'left',
        dataIndex: 'businessSupplierName',
        key: 'supplierCol',
        render: (name, { businesssupplierid }) => {
            return isForbidden(user, permissions.ACCESS_SUPPLIER) ? (
                name
            ) : (
                <Link
                    to={{
                        pathname: `${book.supplier}/${businesssupplierid}`,
                        state: { tab: 'general' }
                    }}
                >
                    {name}
                </Link>
            );
        }
    };
    const contactCol = {
        title: <FormattedMessage id='suppliers_page.contact' />,
        align: 'left',
        dataIndex: '',
        key: 'contactCol',
        render: row => {
            return (
                <div>{`${row.contactName || ''} ${row.contactMiddlename || ''} ${
                    row.contactSurname || ''
                }`}</div>
            );
        }
    };
    const phoneCol = {
        title: <FormattedMessage id='suppliers_page.phone' />,
        align: 'left',
        dataIndex: 'phones',
        key: 'phoneCol',
        render: phones => {
            return (
                <div>
                    {_.map(phones, (phone, index) => (
                        <div key={index}>{phone}</div>
                    ))}
                </div>
            );
        }
    };
    const debtCol = {
        title: <FormattedMessage id='suppliers_page.debt' />,
        align: 'right',
        dataIndex: 'totalSumWithCashOrders',
        key: 'debtCol',
        render: (debt, { businesssupplierid }) => {
            return isForbidden(user, permissions.ACCESS_SUPPLIER) ? (
                debt
            ) : (
                <Link
                    to={{
                        pathname: `${book.supplier}/${businesssupplierid}`,
                        state: { tab: 'debt' }
                    }}
                >
                    <Numeral mask={mask}>{Number(debt)}</Numeral>
                </Link>
            );
        }
    };
    const debtExpiredCol = {
        title: <FormattedMessage id='suppliers_page.debt_expired' />,
        align: 'right',
        dataIndex: 'totalSumWithCashOrdersExpired',
        key: 'debtExpiredCol',
        render: totalSumWithCashOrdersExpired => {
            return <Numeral mask={mask}>{Number(totalSumWithCashOrdersExpired)}</Numeral>;
        }
    };
    const debtNotExpiredCol = {
        title: <FormattedMessage id='suppliers_page.debt_not_expired' />,
        align: 'right',
        dataIndex: 'totalSumWithCashOrdersNotExpired',
        key: 'debtNotExpiredCol',
        render: totalSumWithCashOrdersNotExpired => {
            return (
                <React.Fragment>
                    <Numeral mask={mask}>{Number(totalSumWithCashOrdersNotExpired)}</Numeral>
                </React.Fragment>
            );
        }
    };
    const ordersCountCol = {
        title: <FormattedMessage id='suppliers_page.orders' />,
        align: 'center',
        dataIndex: 'incomeOrderDocsCount',
        key: 'ordersCountCol',
        render: (ordersCount, { businesssupplierid }) => {
            return isForbidden(user, permissions.ACCESS_SUPPLIER) ? (
                ordersCount
            ) : (
                <Link
                    to={{
                        pathname: `${book.supplier}/${businesssupplierid}`,
                        state: { tab: 'orders' }
                    }}
                >
                    {ordersCount || 0}
                </Link>
            );
        }
    };
    const deliveriesCol = {
        title: <FormattedMessage id='suppliers_page.deliveries' />,
        align: 'center',
        dataIndex: 'expenseOrderDocsCount',
        key: 'deliveriesCol',
        render: (deliveries, { businesssupplierid }) => {
            return isForbidden(user, permissions.ACCESS_SUPPLIER) ? (
                deliveries
            ) : (
                <Link
                    to={{
                        pathname: `${book.supplier}/${businesssupplierid}`,
                        state: { tab: 'supply' }
                    }}
                >
                    {deliveries || 0}
                </Link>
            );
        }
    };
    const displayCol = {
        title: <FormattedMessage id='suppliers_page.display' />,
        align: 'center',
        key: 'displayCol',
        render: ({ hidden }) => {
            return !hidden ? (
                <CheckOutlined style={{ color: 'var(--green)' }} />
            ) : (
                <CloseOutlined style={{ color: 'var(--warning)' }} />
            );
        }
    };
    const actionsCol = {
        width: '3%',
        key: 'actionsCol',
        render: row => {
            const disabled =
                row.totalSumWithCashOrders ||
                row.incomeOrderDocsCount ||
                row.expenseOrderDocsCount ||
                !row.businessId ||
                isForbidden(user, permissions.ACCESS_SUPPLIERS_CRUD);

            return (
                <div className={!disabled ? Styles.actions : Styles.disabledActions}>
                    <Popconfirm
                        onConfirm={async () => {
                            const token = localStorage.getItem('_my.carbook.pro_token');
                            const url = `${__API_URL__}/business_suppliers/${row.businessSupplierId}`;
                            try {
                                await fetchAPI(
                                    'DELETE',
                                    `business_suppliers/${row.businessSupplierId}`,
                                    null,
                                    null,
                                    { handleErrorInternally: true }
                                );

                                fetchBusinessSuppliers();
                            } catch (error) {
                                console.error('ERROR:', error);
                                notification.error({
                                    message: formatMessage({ id: 'error' })
                                });
                            }
                        }}
                        title={`${formatMessage({
                            id: 'delete'
                        })} ?`}
                    >
                        <DeleteOutlined
                            className={
                                !disabled ? Styles.deleteSupplierIcon : Styles.disabledDeleteIcon
                            }
                        />
                    </Popconfirm>
                </div>
            );
        }
    };

    return [
        supplierCol,
        contactCol,
        phoneCol,
        debtCol,
        // debtExpiredCol,
        // debtNotExpiredCol,
        ordersCountCol,
        deliveriesCol,
        displayCol,
        actionsCol
    ];
}
