import { Form } from '@ant-design/compatible';
import { Button, Modal, Select, Table } from 'antd';
import { Catcher } from 'commons';
import { createInvite, fetchClients, setClientsPageSort, setInvite } from 'core/clients/duck';
import { resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { columnsConfig } from './clientsTableConfig';
import Styles from './styles.m.css';

const { Option } = Select;
const FormItem = Form.Item;

const mapStateToProps = state => ({
    clients: state.clients.clients,
    stats: state.clients.stats,
    filter: state.clients.filter,
    sort: state.clients.sort,
    modal: state.modals.modal,
    clientsFetching: state.ui.clientsFetching,
    user: state.auth,
    invite: state.clients.invite
});

const mapDispatchToProps = {
    fetchClients,
    setClientsPageSort,
    setModal,
    resetModal,
    setInvite,
    createInvite
};

function formatVehicleLabel(vehicle, formatMessage) {
    const modelPart = vehicle.model
        ? `${vehicle.make} ${vehicle.model}`
        : formatMessage({ id: 'add_order_form.no_model' });
    const horsePowerLabel = !vehicle.horsePower
        ? null
        : `(${vehicle.horsePower} ${formatMessage({
              id: 'horse_power'
          })})`;
    const modificationPart = [vehicle.modification, horsePowerLabel].filter(Boolean).join(' ');
    const parts = [modelPart, vehicle.year, modificationPart, vehicle.number, vehicle.vin];

    return parts.filter(Boolean).map(String).map(_.trimEnd).join(', ');
}

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientsContainer extends Component {
    componentDidMount() {
        this.props.fetchClients(this.props.filter);
    }

    render() {
        const { clients, invite, user, sort } = this.props;
        const { setInvite, createInvite, isMobile } = this.props;
        const { formatMessage } = this.props.intl;

        const columns = columnsConfig(sort, user, formatMessage, setInvite, isMobile);

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(this.props.stats.countClients / 25) * 25,
            hideOnSinglePage: true,
            current: this.props.sort.page,
            onChange: page => {
                this.props.setClientsPageSort({ page });
                this.props.fetchClients(this.props.filter);
            }
        };

        return (
            <Catcher>
                <div>
                    {!isMobile ? (
                        <Table
                            bordered
                            columns={columns}
                            dataSource={clients}
                            loading={this.props.clientsFetching}
                            // scroll={ scrollConfig() }
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={pagination}
                            scroll={{ x: 1360 }}
                            // onChange={ handleTableChange }
                            size='small'
                        />
                    ) : (
                        <Table
                            bordered
                            className={Styles.mobileTable}
                            columns={columns}
                            dataSource={clients}
                            loading={this.props.clientsFetching}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={pagination}
                        />
                    )}
                </div>
                <Modal
                    footer={[
                        <Button key='back' onClick={() => setInvite(null, null)}>
                            <FormattedMessage id='cancel' />
                        </Button>,
                        <Button
                            key='submit'
                            disabled={!invite.clientVehicleId}
                            onClick={() => {
                                const inviteOrder = {
                                    managerId: user.id,
                                    clientId: invite.client.clientId,
                                    clientVehicleId: invite.clientVehicleId,
                                    clientPhone: invite.client.phones.find(Boolean),
                                    status: 'invite'
                                };
                                setInvite(null, null);
                                createInvite(inviteOrder);
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='orders.invite' />
                        </Button>
                    ]}
                    maskClosable={false}
                    onCancel={() => setInvite(null, null)}
                    title={<FormattedMessage id='orders.invitation' />}
                    visible={invite.client}
                >
                    {invite.client && (
                        <Form>
                            <FormItem label={<FormattedMessage id='client_order_tab.car' />}>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={clientVehicleId => setInvite(invite.client, clientVehicleId)}
                                    value={invite.clientVehicleId}
                                >
                                    {invite.client.vehicles.map(vehicle => (
                                        <Option key={vehicle.id} disabled={vehicle.disabled} value={vehicle.id}>
                                            {formatVehicleLabel(vehicle, formatMessage)}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Form>
                    )}
                </Modal>
            </Catcher>
        );
    }
}
