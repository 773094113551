import { Tag } from 'antd';
import { Close, Layout, Loader } from 'commons';
import { fetchIncomeDoc, selectIncomeDoc } from 'core/storage/incomes';
import { IncomeDocForm } from 'forms';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import styled from 'styled-components';
import { goTo, withErrorMessage } from 'utils';

const ModuleHeaderContent = styled.div`
    display: flex;
    align-items: center;
`;

const IncomeDoc = withErrorMessage()(props => {
    const { id } = props.match.params;
    const { status } = props.incomeDoc;
    // const status = _.get(props, 'location.state.status');

    useEffect(() => {
        if (id) {
            props.fetchIncomeDoc(id);
        }
    }, []);

    const title = (
        <ModuleHeaderContent>
            {status ? (
                <Tag color={status === 'NEW' ? 'var(--not_complete)' : 'var(--green)'}>
                    <FormattedMessage id={`storage.status.${status}`} />
                </Tag>
            ) : status && id ? (
                <FormattedMessage id='add' />
            ) : null}
            &nbsp;
            <FormattedMessage id='storage.income_document' />
            {id ? `: ${id}` : null}
        </ModuleHeaderContent>
    );

    const controls = (
        <ModuleHeaderContent>
            <Close onClick={() => goTo(book.storageIncomes)} />
        </ModuleHeaderContent>
    );

    return (
        <Layout controls={controls} title={title}>
            {props.loading && _.isEmpty(props.incomeDoc) ? (
                <Loader loading={props.loading && _.isEmpty(props.incomeDoc)} />
            ) : (
                <IncomeDocForm incomeDoc={props.incomeDoc} />
            )}
        </Layout>
    );
});

const mapStateToProps = state => ({
    incomeDoc: selectIncomeDoc(state)
});

const mapDispatchToProps = {
    fetchIncomeDoc
};

export const IncomeDocPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(IncomeDoc));
