import { Card } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const GridCard = ({ children, css, height }) => {
    return (
        <StyledGridCard css={css} height={height}>
            {children}
        </StyledGridCard>
    );
};

const StyledGridCard = styled(Card.Grid)`
    text-align: center;
    padding: 0;

    text-align: center;
    max-height: 600px;
    min-height: 560px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin: 14px;
    background: white;

    ${props => props.css}
`;
