import { LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import Link from './link';
import menuConfig from './menuConfig';
import Styles from './styles.m.css';

@withRouter
export default class SiderMenu extends Component {
    render() {
        const { history, collapsed, isMobile, onCollapse, user, hidden, setCollapsedState } =
            this.props;
        const defaultOpenKeys = [];
        const selected = menuConfig.getSelectedByRoute(history.location.pathname);
        if (selected.sectionKey && !defaultOpenKeys.includes(selected.sectionKey)) {
            defaultOpenKeys.push(selected.sectionKey);
        }

        if (!defaultOpenKeys.length && !collapsed) {
            defaultOpenKeys.push('operations');
        }

        return (
            <div>
                <Menu
                    activeKey={selected.itemKey}
                    className={`${Styles.navMenu} ${isMobile && Styles.navMenuMobile}`}
                    defaultOpenKeys={defaultOpenKeys}
                    // defaultSelectedKeys={ [ '1' ] }
                    forceSubMenuRender
                    mode='inline'
                    selectedKeys={[selected.itemKey]}
                    style={{
                        position: !isMobile && 'fixed',
                        width: isMobile && 300,
                        maxWidth: '420px',
                        top: 4,
                        left: collapsed ? -418 : 0,
                        height: !isMobile && 'calc(100% - 64px)',
                        zIndex: 125,
                        transition: '0.5s',
                        overflowX: 'hidden',
                        overflowY: 'auto'
                    }}
                    theme='light'
                >
                    {menuConfig.sections.map(section => {
                        const { key, icon, name, items, link, disabled } = section;

                        if (items) {
                            return (
                                (!disabled || !disabled(user)) && (
                                    <Menu.SubMenu
                                        key={key}
                                        title={
                                            <React.Fragment>
                                                {icon}
                                                <FormattedMessage id={name} />
                                            </React.Fragment>
                                        }
                                    >
                                        {items.map(item => {
                                            const { key, link, name, disabled, visible } = item;

                                            return (
                                                (!visible || visible(user)) && (
                                                    <Menu.Item
                                                        key={key}
                                                        disabled={disabled && disabled(user)}
                                                    >
                                                        <Link
                                                            collapsed={collapsed}
                                                            mobile={isMobile}
                                                            onClick={
                                                                isMobile
                                                                    ? onCollapse
                                                                    : setCollapsedState
                                                            }
                                                            to={link}
                                                        >
                                                            <FormattedMessage id={name} />
                                                        </Link>
                                                    </Menu.Item>
                                                )
                                            );
                                        })}
                                    </Menu.SubMenu>
                                )
                            );
                        }

                        return (
                            <Menu.Item key={key} disabled={disabled && disabled(user)}>
                                <Link to={link}>
                                    {icon}
                                    <FormattedMessage id={name} />
                                </Link>
                            </Menu.Item>
                        );
                    })}
                    {isMobile && (
                        <Menu.Item
                            key={menuConfig.sections.length}
                            onClick={() => {
                                this.props.logout();
                            }}
                        >
                            <LogoutOutlined
                                style={{
                                    paddingRight: 8,
                                    fontSize: 16,
                                }}
                            />
                            <FormattedMessage id='header.logout' />
                        </Menu.Item>
                    )}
                </Menu>
            </div>
        );
    }
}
