import { addError, emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI, toDuckError } from 'utils';
import {
    createBrandSuccess,
    CREATE_BRAND,
    fetchBrandSuccess,
    FETCH_BRAND,
    selectBrandField,
    updateBrandSuccess,
    UPDATE_BRAND
} from './duck';

export function* createBrand() {
    while (true) {
        try {
            yield take(CREATE_BRAND);

            const brandEntity = yield select(selectBrandField);

            yield call(fetchAPI, 'POST', 'clients/brands', null, brandEntity, {
                handleErrorInternally: true
            });

            yield put(createBrandSuccess());
        } catch (error) {
            const duckError = toDuckError(error, 'brandsContainer');
            yield put(addError(duckError));

            // yield put(emitError(error));
        }
    }
}

export function* getBrand() {
    while (true) {
        try {
            const { payload } = yield take(FETCH_BRAND);

            const result = yield call(fetchAPI, 'GET', `clients/brands/${payload}`);

            yield put(fetchBrandSuccess(_.omit(result, ['brandCrosses'])));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* updateBrand() {
    while (true) {
        try {
            yield take(UPDATE_BRAND);

            const brandEntity = yield select(selectBrandField);

            yield call(
                fetchAPI,
                'PUT',
                `clients/brands/${brandEntity.brandId}`,
                null,
                _.omit(brandEntity, ['brandId'])
            );

            yield put(updateBrandSuccess());
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([call(createBrand), call(getBrand), call(updateBrand)]);
}
