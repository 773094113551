const { get } = require('lodash');

// filters: {pathToProperty, substring}[]
const classFilterDataSource = (filters, dataSource) => {
    const entries = Object.entries(filters);
    if (!entries.length) return dataSource;

    let filteredData = [...dataSource];

    entries.forEach(([key, value]) => {
        const conditions = [value].flat();
        const truthyConditions = conditions.filter(condition => condition && condition !== []).map(c => String(c));
        if (!truthyConditions.length) return;

        filteredData = filteredData.filter(row => {
            return truthyConditions
                .map(condition => String(get(row, key)).toLowerCase().includes(condition.toLowerCase()))
                .includes(true);
        });
    });

    return filteredData;
};

export default classFilterDataSource;
