import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import { Input } from 'antd';
import React from 'react';

const FormItem = Form.Item;
const InputGroup = Input.Group;

export const DecoratedInputPhone = props => {
    const {
        // FormItem
        formItem,
        label,
        colon,
        className,
        hasFeedback,
        formItemLayout,
        getFieldDecorator,
        disabled,
        rules,
        type,
        placeholder,
        icon,
        iconType,
        field,
        initialValue,
        onChange
    } = props;
    const input = getFieldDecorator(field, {
        ...(initialValue ? { initialValue } : {}),
        rules
    })(
        <Input
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            prefix={
                icon ? (
                    <LegacyIcon
                        style={{
                            color: 'rgba(0,0,0,.25)'
                        }}
                        type={iconType}
                    />
                ) : null
            }
            style={{ width: '80%' }}
            type={type}
        />
    );

    return formItem ? (
        <FormItem
            className={className}
            colon={colon}
            hasFeedback={hasFeedback}
            label={label}
            {...formItemLayout}
        >
            <InputGroup compact>
                <Input defaultValue='+38' disabled style={{ width: '20%' }} />
                {input}
            </InputGroup>
        </FormItem>
    ) : (
        <InputGroup compact>
            <Input defaultValue='+38' disabled style={{ width: '20%' }} />
            {input}
        </InputGroup>
    );
};
