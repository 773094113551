/* eslint-disable max-classes-per-file */
import {
    AppstoreAddOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    BlockOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    ForkOutlined,
    ImportOutlined,
    LockOutlined,
    MenuOutlined,
    PlusOutlined,
    PlusSquareOutlined,
    QuestionCircleOutlined,
    RedoOutlined,
    SearchOutlined,
    ShoppingOutlined,
    ShrinkOutlined,
    StarFilled,
    StarOutlined,
    ToolFilled,
    ToolOutlined,
    TransactionOutlined,
    UndoOutlined,
    UnlockOutlined,
    VideoCameraOutlined
} from '@ant-design/icons';
import {
    Badge,
    Button,
    Checkbox,
    Drawer,
    Dropdown,
    Image,
    Input,
    Menu,
    Modal,
    Popover,
    Select,
    Tooltip,
    Upload,
    notification
} from 'antd';
import { Catcher } from 'commons';
import { AvailabilityIndicator, Barcode, DraggableTable, HamburgerMenu, ReserveButton } from 'components';
import { MODALS } from 'core/modals/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import _, { debounce, get } from 'lodash';
import {
    DetailSupplierModal,
    DetailWarehousesCountModal,
    FavouriteDetailsModal,
    OrderDetailModal,
    SetBarcodeModal
} from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { BookIcon, PencilIcon, VinIcon } from 'theme';
import {
    fetchAPI,
    fetchTecdocAPI,
    filterStringByPart,
    getStorageToken,
    isDisabledByDetailStatuses,
    showDetailsActionNotification
} from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { v4 } from 'uuid';
import ImportDetailsModal from './ImportDetailsModal';
import SuppliersIncomeModal from './modals/SuppliersIncomeModal';
import UpdatePriceModal from './modals/UpdatePriceModal';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;
const { SubMenu } = Menu;
const AGREED = 'AGREED';
const INSTALLED = 'INSTALLED';
const inputPattern = /[A-Za-z0-9\а-яА-Я]/g;

const mapStateToProps = state => ({
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    fetchWarehouses
};

const complexesTypes = {
    OWN: { name: 'complexes.type.OWN', grant: grants.OPERATIONS_ORDER_DOCUMENT_JOBS_COMPLEXES },
    PRODUCTION: { name: 'complexes.type.PRODUCTION', grant: grants.DIRECTORIES_PRODUCTS_LIST_PRODUCTION_DISASSEMBLY },
    DISASSEMBLY: { name: 'complexes.type.DISASSEMBLY', grant: grants.DIRECTORIES_PRODUCTS_LIST_PRODUCTION_DISASSEMBLY }
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class DetailsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            mainWarehouseId: undefined,
            fetched: false,
            reserveModalData: undefined,
            productBarcode: undefined,
            selectedRowKeys: [],
            selectedRows: [],
            statuses: [],
            setPriceModalValue: 1,
            setPricePurchaseValue: 1,
            showPurchasePrices: true,
            helperDrawerOpen: false,
            allLinks: [],
            search: ''
        };

        this.brands = this.props.allDetails.brands.map(({ supplierId, brandId, brandName }) => (
            <Option key={`allBrands-${brandId}`} value={String(brandId)}>
                {brandName}
            </Option>
        ));

        this.columns = () => [
            {
                title: () => {
                    const { warehouses, user, isRTN } = this.props;
                    const { selectedRowKeys, selectedRows, type } = this.state;

                    const actionsMenu = () =>
                        isRTN ? (
                            <Menu className={Styles.actionMenuDropdown}>
                                {isGrantAccessed(
                                    user,
                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                                ) && (
                                    <Menu.Item
                                        key='groupDetailsMenu.updatePrice'
                                        disabled={!selectedRowKeys.length}
                                        onClick={async () => {
                                            const { status: st } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (st === 'success') {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.closed_order_notific'
                                                    })
                                                });

                                                return;
                                            }
                                            this.setState({ setPriceModal: selectedRows });
                                        }}
                                    >
                                        <TransactionOutlined
                                            className={Styles.actionMenuIcon}
                                            style={{ fontSize: 18 }}
                                        />
                                        {this.props.intl.formatMessage({ id: 'update_price' })}
                                    </Menu.Item>
                                )}
                                <Menu.Item
                                    key='groupDetailsMenu.delete'
                                    disabled={
                                        !selectedRowKeys.length ||
                                        this.state.selectedRows.find(({ reservedCount }) => reservedCount >= 0.01)
                                    }
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                try {
                                                    await fetchAPI(
                                                        'DELETE',
                                                        'orders/details',
                                                        undefined,
                                                        {
                                                            ids: this.state.selectedRows
                                                                .filter(
                                                                    row =>
                                                                        !isDisabledByDetailStatuses(
                                                                            'delete',
                                                                            row,
                                                                            Boolean(Number(row.reservedCount))
                                                                        )
                                                                )
                                                                .map(({ id }) => id)
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    await notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.deleted'
                                                        })
                                                    });
                                                    this.updateDataSource();
                                                } catch (e) {
                                                    notification.warning({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.delete_failed'
                                                        })
                                                    });
                                                }
                                            }
                                        });
                                    }}
                                >
                                    <div>
                                        <DeleteOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'delete' })}
                                    </div>
                                </Menu.Item>
                            </Menu>
                        ) : (
                            <Menu className={Styles.actionMenuDropdown}>
                                <SubMenu
                                    key='groupDetailsMenu.changeStatus'
                                    data-qa='sub_menu_group_details_menu_details_table_order_page'
                                    disabled={
                                        !selectedRowKeys.length ||
                                        this.state.selectedRows.find(
                                            ({ reservedCount }) => Boolean(reservedCount) === true
                                        ) ||
                                        !isGrantAccessed(
                                            this.props.user,
                                            grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE
                                        )
                                    }
                                    title={
                                        <React.Fragment>
                                            <EditOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                            {this.props.intl.formatMessage({
                                                id: 'profile.spare_parts_workplace.change_status'
                                            })}
                                        </React.Fragment>
                                    }
                                >
                                    {this.state.statuses.map(({ status }) => (
                                        <Menu.Item
                                            key={`groupDetailsMenu.changeStatus.${status}`}
                                            onClick={async () => {
                                                const payload = {
                                                    updateMode: true,
                                                    details: [
                                                        ...this.state.selectedRows.map(({ id }) => ({
                                                            id,
                                                            status
                                                        }))
                                                    ]
                                                };

                                                await fetchAPI(
                                                    'PUT',
                                                    `orders/${this.props.orderId}`,
                                                    undefined,
                                                    payload
                                                );
                                                await this.updateDataSource();
                                            }}
                                        >
                                            {this.props.intl.formatMessage({ id: `status.${status}` })}
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                                <SubMenu
                                    key='groupDetailsMenu.changeResponsible'
                                    data-qa='sub_menu_responsible_details_menu_details_table_order_page'
                                    disabled={!selectedRowKeys.length}
                                    title={
                                        <React.Fragment>
                                            <ToolOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                            {this.props.intl.formatMessage({
                                                id: 'order_form_table.appurtenancies_responsible'
                                            })}
                                        </React.Fragment>
                                    }
                                >
                                    {this.props.employees
                                        .filter(({ posts }) =>
                                            posts.find(({ postName }) => postName === 'SPARE_PARTS_SPECIALIST'))
                                        .map(({ id: responsibleId, surname, name }) => (
                                            <Menu.Item
                                                key={`groupDetailsMenu.changeResponsible.${responsibleId}`}
                                                onClick={async () => {
                                                    const payload = {
                                                        updateMode: true,
                                                        details: [
                                                            ...this.state.selectedRows.map(({ id, storeGroupId }) => ({
                                                                id,
                                                                storeGroupId,
                                                                responsibleId
                                                            }))
                                                        ]
                                                    };
                                                    await fetchAPI(
                                                        'PUT',
                                                        `orders/${this.props.orderId}`,
                                                        undefined,
                                                        payload
                                                    );
                                                    await this.updateDataSource();
                                                }}
                                            >
                                                {surname} {name}
                                            </Menu.Item>
                                        ))}
                                </SubMenu>
                                {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE) && (
                                    <Menu.Item key='groupDetailsMenu.updateStage' disabled={!selectedRowKeys.length}>
                                        <div
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: this.props.intl.formatMessage({ id: 'orders.update_stage' }),
                                                    onOk: async () => {
                                                        const { status: st } = await fetchAPI(
                                                            'GET',
                                                            'orders/status',
                                                            { orderId: this.props.orderId },
                                                            null
                                                        );
                                                        if (st === 'success') {
                                                            notification.error({
                                                                message: this.props.intl.formatMessage({
                                                                    id: 'order_form_table.closed_order_notific'
                                                                })
                                                            });

                                                            return;
                                                        }
                                                        const response = await fetchAPI(
                                                            'PUT',
                                                            'orders/details',
                                                            undefined,
                                                            {
                                                                details: selectedRows.map(({ id }) => ({ id }))
                                                            },
                                                            { handleErrorInternally: true }
                                                        );
                                                        await showDetailsActionNotification(
                                                            this.props.intl,
                                                            'update_stage',
                                                            response.all,
                                                            response.success
                                                        );
                                                        await this.updateDataSource();
                                                    }
                                                });
                                            }}
                                        >
                                            <RedoOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({ id: 'orders.update_stage' })}
                                        </div>
                                    </Menu.Item>
                                )}

                                <Menu.Item
                                    key='groupDetailsMenu.changeGroup'
                                    disabled={!selectedRowKeys.length}
                                    onClick={async () => {
                                        const { status: st } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (st === 'success') {
                                            notification.error({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.closed_order_notific'
                                                })
                                            });

                                            return;
                                        }
                                        const groupName = v4();
                                        const payload = {
                                            updateMode: true,
                                            details: [
                                                ...this.state.selectedRows.map(({ id, agreement }, index) => ({
                                                    id,
                                                    groupName,
                                                    agreement: index >= 1 ? 'REJECTED' : agreement
                                                }))
                                            ]
                                        };
                                        await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                        await this.updateDataSource();
                                    }}
                                >
                                    <div>
                                        <BlockOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'orders.to_group' })}
                                    </div>
                                </Menu.Item>

                                <Menu.Item
                                    key='groupDetailsMenu.copy'
                                    disabled={!selectedRowKeys.length}
                                    onClick={async () => {
                                        const { status: st } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (st === 'success') {
                                            notification.error({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.closed_order_notific'
                                                })
                                            });

                                            return;
                                        }
                                        if (selectedRowKeys.length) {
                                            const { status } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (status === 'success') {
                                                window.location.reload();

                                                return;
                                            }
                                            const payload = {
                                                insertMode: true,
                                                details: [
                                                    ...this.state.selectedRows
                                                        .filter(row => !isDisabledByDetailStatuses('copy', row))
                                                        .map(row => ({
                                                            storeGroupId: row.storeGroupId,
                                                            name: row.detailName,
                                                            productCode: row.detailCode,
                                                            supplierId: row.supplierId,
                                                            supplierBrandId: row.supplierBrandId || row.brandId,
                                                            purchasePrice: row.purchasePrice,
                                                            count: row.count,
                                                            price: row.price,
                                                            status: row.status,
                                                            partUnitId: row.partUnitId || 1,
                                                            partRowDiscount: row.partRowDiscount || 0,
                                                            oeCode: row.oeCode || null
                                                        }))
                                                ]
                                            };
                                            const response = await fetchAPI(
                                                'PUT',
                                                `orders/${this.props.orderId}`,
                                                undefined,
                                                payload
                                            );
                                            await this.updateDataSource();
                                        }
                                    }}
                                >
                                    <div>
                                        <CopyOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
                                    </div>
                                </Menu.Item>
                                {isGrantAccessed(
                                    user,
                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                                ) && (
                                    <Menu.Item
                                        key='groupDetailsMenu.updatePrice'
                                        disabled={!selectedRowKeys.length}
                                        onClick={async () => {
                                            const { status: st } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (st === 'success') {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.closed_order_notific'
                                                    })
                                                });

                                                return;
                                            }
                                            this.setState({ setPriceModal: selectedRows });
                                        }}
                                    >
                                        <TransactionOutlined
                                            className={Styles.actionMenuIcon}
                                            style={{ fontSize: 18 }}
                                        />
                                        {this.props.intl.formatMessage({ id: 'update_price' })}
                                    </Menu.Item>
                                )}
                                {isGrantAccessed(
                                    user,
                                    grants.OPERATIONS_ORDER_DOCUMENT_JOBS_COMPLEXES,
                                    accesses.ROWO
                                ) && (
                                    <Menu.Item key='groupDetailsMenu.add_complex'>
                                        <div
                                            onClick={() => {
                                                this.setState({ complexName: undefined, type: 'OWN' });
                                                Modal.confirm({
                                                    title: this.props.intl.formatMessage({
                                                        id: 'complexes.create_complex'
                                                    }),
                                                    content: (
                                                        <div>
                                                            <div style={{ marginBottom: 8 }}>
                                                                <Input
                                                                    onChange={e =>
                                                                        this.setState({ complexName: e.target.value })
                                                                    }
                                                                    placeholder={this.props.intl.formatMessage({
                                                                        id: 'complexes.complex_name'
                                                                    })}
                                                                    value={this.state.complexName}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Select
                                                                    defaultValue='OWN'
                                                                    onChange={value => this.setState({ type: value })}
                                                                    optionFilterProp='children'
                                                                    placeholder={this.props.intl.formatMessage({
                                                                        id: 'complexes.type'
                                                                    })}
                                                                    showSearch
                                                                    style={{ marginBottom: 6, width: '100%' }}
                                                                    value={this.state.type}
                                                                >
                                                                    {Object.entries(complexesTypes).map(
                                                                        ([key, value]) => (
                                                                            <Option
                                                                                key={key}
                                                                                disabled={
                                                                                    !isGrantAccessed(user, value.grant)
                                                                                }
                                                                                value={key}
                                                                            >
                                                                                {this.props.intl.formatMessage({
                                                                                    id: value.name
                                                                                })}
                                                                            </Option>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    ),
                                                    onOk: async () => {
                                                        const complexLaborsData = this.props.orderServices.map(
                                                            ({
                                                                serviceName,
                                                                normHours,
                                                                price,
                                                                count,
                                                                laborUnitId,
                                                                laborId
                                                            }) => ({
                                                                laborName: serviceName,
                                                                laborHours: normHours,
                                                                laborPrice: price || 0,
                                                                laborCount: count,
                                                                ...(laborUnitId ? { laborUnitId } : {}),
                                                                laborLaborId: laborId
                                                            })
                                                        );
                                                        const complexProductsData = this.state.dataSource.map(
                                                            ({
                                                                storeGroupId,
                                                                detailName,
                                                                count,
                                                                price,
                                                                supplierBrandId,
                                                                detailCode
                                                            }) => ({
                                                                productCode: detailCode || '-',
                                                                productStoreGroupId: storeGroupId,
                                                                productName: detailName,
                                                                productCount: count,
                                                                productPrice: price || 0,
                                                                ...(supplierBrandId
                                                                    ? { productBrandId: supplierBrandId }
                                                                    : {})
                                                            })
                                                        );

                                                        const body = {
                                                            complexData: {
                                                                name: this.state.complexName,
                                                                id: get(open, 'id'),
                                                                type: this.state.type || 'OWN'
                                                            },
                                                            complexLaborsData,
                                                            complexProductsData
                                                        };
                                                        const response = await fetchAPI(
                                                            get(open, 'id') ? 'PUT' : 'POST',
                                                            'business/complexes',
                                                            null,
                                                            body,
                                                            {
                                                                handleErrorInternally: true
                                                            }
                                                        );
                                                        if (response.complex) {
                                                            Modal.confirm({
                                                                onOk: async () => {
                                                                    await fetchAPI(
                                                                        get(open, 'id') ? 'PUT' : 'POST',
                                                                        'business/complexes',
                                                                        null,
                                                                        { ...body, isRewrite: true },
                                                                        { handleErrorInternally: true }
                                                                    );
                                                                },
                                                                onCancel: this.handleCancelComplex(),
                                                                title: this.props.intl.formatMessage({
                                                                    id: 'complexes.already_exist'
                                                                })
                                                            });

                                                            return;
                                                        }

                                                        notification.success({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'barcode.success'
                                                            })
                                                        });
                                                    }
                                                });
                                            }}
                                        >
                                            <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({ id: 'complexes.create_complex' })}
                                        </div>
                                    </Menu.Item>
                                )}
                                {(isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO) ||
                                    isGrantAccessed(
                                        user,
                                        grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS,
                                        accesses.ROWO
                                    )) && (
                                    <Menu.Item
                                        key='groupDetailsMenu.order'
                                        disabled={
                                            !selectedRowKeys.length ||
                                            this.state.selectedRows.find(({ detailCode }) =>
                                                detailCode
                                                    ? !detailCode.match(inputPattern)
                                                    : undefined || detailCode === null
                                            )
                                        }
                                        onClick={() => {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'profile.spare_parts_workplace.to_order'
                                                }),
                                                onOk: async () => {
                                                    const { status: st } = await fetchAPI(
                                                        'GET',
                                                        'orders/status',
                                                        { orderId: this.props.orderId },
                                                        null
                                                    );
                                                    if (st === 'success') {
                                                        notification.error({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'order_form_table.closed_order_notific'
                                                            })
                                                        });

                                                        return;
                                                    }
                                                    const response = await fetchAPI(
                                                        'POST',
                                                        'v2/store_docs/order_all_possible',
                                                        undefined,
                                                        {
                                                            ordersAppurtenanciesIds: this.state.selectedRows
                                                                .filter(
                                                                    row => !isDisabledByDetailStatuses('order', row)
                                                                )
                                                                .map(({ id }) => id)
                                                        },
                                                        { handleErrorInternally: true }
                                                    );

                                                    this.checkStatus(
                                                        0,
                                                        { orderId: this.props.orderId, pcode: 'ORDER' },
                                                        'to_order'
                                                    );
                                                    await this.updateDataSource();
                                                }
                                            });
                                        }}
                                    >
                                        <div>
                                            <ShoppingOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({
                                                id: 'profile.spare_parts_workplace.to_order'
                                            })}
                                        </div>
                                    </Menu.Item>
                                )}
                                {isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO) && (
                                    <React.Fragment>
                                        <Menu.Item
                                            key='groupDetailsMenu.accept'
                                            disabled={
                                                !selectedRowKeys.length ||
                                                this.state.selectedRows.find(({ detailCode }) =>
                                                    detailCode
                                                        ? !detailCode.match(inputPattern)
                                                        : undefined || detailCode === null)
                                            }
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: this.props.intl.formatMessage({
                                                        id: 'profile.spare_parts_workplace.accept'
                                                    }),
                                                    onOk: async () => {
                                                        const { status: st } = await fetchAPI(
                                                            'GET',
                                                            'orders/status',
                                                            { orderId: this.props.orderId },
                                                            null
                                                        );
                                                        if (st === 'success') {
                                                            notification.error({
                                                                message: this.props.intl.formatMessage({
                                                                    id: 'order_form_table.closed_order_notific'
                                                                })
                                                            });

                                                            return;
                                                        }
                                                        if (selectedRowKeys.length) {
                                                            const check = selectedRows
                                                                .filter(row => row.supplierId)
                                                                .filter(
                                                                    row => !isDisabledByDetailStatuses('intake', row)
                                                                );
                                                            if (!check.length) {
                                                                notification.warning({
                                                                    message: this.props.intl.formatMessage({
                                                                        id: 'not_found'
                                                                    })
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    visibleSuppliersIncomeModal: true
                                                                });
                                                            }
                                                        }
                                                    }
                                                });
                                            }}
                                        >
                                            <CheckOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({
                                                id: 'profile.spare_parts_workplace.accept'
                                            })}
                                        </Menu.Item>
                                        <Menu.Item key='groupDetailsMenu.return' disabled={!selectedRowKeys.length}>
                                            <div
                                                onClick={() => {
                                                    Modal.confirm({
                                                        title: this.props.intl.formatMessage({
                                                            id: 'profile.spare_parts_workplace.return_to_supplier'
                                                        }),
                                                        onOk: async () => {
                                                            const { status: st } = await fetchAPI(
                                                                'GET',
                                                                'orders/status',
                                                                { orderId: this.props.orderId },
                                                                null
                                                            );
                                                            if (st === 'success') {
                                                                notification.error({
                                                                    message: this.props.intl.formatMessage({
                                                                        id: 'order_form_table.closed_order_notific'
                                                                    })
                                                                });

                                                                return;
                                                            }
                                                            const response = await fetchAPI(
                                                                'POST',
                                                                'store_docs/return_to_supplier_all_possible',
                                                                undefined,
                                                                {
                                                                    ordersAppurtenanciesIds: selectedRows
                                                                        .filter(
                                                                            row =>
                                                                                !isDisabledByDetailStatuses(
                                                                                    'ret_2',
                                                                                    row
                                                                                )
                                                                        )
                                                                        .map(({ id }) => id)
                                                                },
                                                                { handleErrorInternally: true }
                                                            );
                                                            await showDetailsActionNotification(
                                                                this.props.intl,
                                                                'return_to_supplier',
                                                                response.all,
                                                                response.success
                                                            );
                                                            await this.updateDataSource();
                                                        }
                                                    });
                                                }}
                                            >
                                                <UndoOutlined className={Styles.actionMenuIcon} />
                                                {this.props.intl.formatMessage({
                                                    id: 'profile.spare_parts_workplace.return_to_supplier'
                                                })}
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item key='groupDetailsMenu.give' disabled={!selectedRowKeys.length}>
                                            <div
                                                onClick={() => {
                                                    Modal.confirm({
                                                        title: this.props.intl.formatMessage({
                                                            id: 'profile.spare_parts_workplace.give'
                                                        }),
                                                        onOk: async () => {
                                                            const { status: st } = await fetchAPI(
                                                                'GET',
                                                                'orders/status',
                                                                { orderId: this.props.orderId },
                                                                null
                                                            );
                                                            if (st === 'success') {
                                                                notification.error({
                                                                    message: this.props.intl.formatMessage({
                                                                        id: 'order_form_table.closed_order_notific'
                                                                    })
                                                                });

                                                                return;
                                                            }
                                                            const response = await fetchAPI(
                                                                'POST',
                                                                'v2/store_docs/transfer_reserved_all_possible',
                                                                undefined,
                                                                {
                                                                    ordersAppurtenanciesIds: selectedRows
                                                                        .filter(
                                                                            row =>
                                                                                !isDisabledByDetailStatuses('give', row)
                                                                        )
                                                                        .map(({ id }) => id),
                                                                    toWarehouseId: warehouses.find(
                                                                        ({ attribute }) => attribute == 'REPAIR_AREA'
                                                                    ).id
                                                                },
                                                                { handleErrorInternally: true }
                                                            );
                                                            this.checkStatus(
                                                                0,
                                                                { orderId: this.props.orderId, pcode: 'TRANSFER' },
                                                                'transfer_to_repair'
                                                            );
                                                            await this.updateDataSource();
                                                        }
                                                    });
                                                }}
                                            >
                                                <ArrowRightOutlined className={Styles.actionMenuIcon} />
                                                {this.props.intl.formatMessage({
                                                    id: 'profile.spare_parts_workplace.give'
                                                })}
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item key='groupDetailsMenu.toStock' disabled={!selectedRowKeys.length}>
                                            <div
                                                onClick={() => {
                                                    Modal.confirm({
                                                        title: this.props.intl.formatMessage({
                                                            id: 'profile.spare_parts_workplace.return_to_stock'
                                                        }),
                                                        onOk: async () => {
                                                            const { status: st } = await fetchAPI(
                                                                'GET',
                                                                'orders/status',
                                                                { orderId: this.props.orderId },
                                                                null
                                                            );
                                                            if (st === 'success') {
                                                                notification.error({
                                                                    message: this.props.intl.formatMessage({
                                                                        id: 'order_form_table.closed_order_notific'
                                                                    })
                                                                });

                                                                return;
                                                            }
                                                            const response = await fetchAPI(
                                                                'POST',
                                                                'v2/store_docs/transfer_reserved_all_possible',
                                                                undefined,
                                                                {
                                                                    ordersAppurtenanciesIds: selectedRows
                                                                        .filter(
                                                                            row =>
                                                                                !isDisabledByDetailStatuses(
                                                                                    'ret_1',
                                                                                    row
                                                                                )
                                                                        )
                                                                        .map(({ id }) => id),
                                                                    toWarehouseId: warehouses.find(
                                                                        ({ attribute }) => attribute == 'MAIN'
                                                                    ).id
                                                                },
                                                                { handleErrorInternally: true }
                                                            );
                                                            this.checkStatus(
                                                                0,
                                                                { orderId: this.props.orderId, pcode: 'TRANSFER' },
                                                                'transfer_to_stock'
                                                            );
                                                            await this.updateDataSource();
                                                        }
                                                    });
                                                }}
                                            >
                                                <ArrowLeftOutlined className={Styles.actionMenuIcon} />
                                                {this.props.intl.formatMessage({
                                                    id: 'profile.spare_parts_workplace.return_to_stock'
                                                })}
                                            </div>
                                        </Menu.Item>
                                    </React.Fragment>
                                )}

                                <Menu.Item
                                    key='groupDetailsMenu.delete'
                                    disabled={
                                        !selectedRowKeys.length ||
                                        this.state.selectedRows.find(({ reservedCount }) => reservedCount >= 0.01)
                                    }
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'add_order_form.delete_confirm'
                                            }),
                                            onOk: async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                try {
                                                    await fetchAPI(
                                                        'DELETE',
                                                        'orders/details',
                                                        undefined,
                                                        {
                                                            ids: this.state.selectedRows
                                                                .filter(
                                                                    row =>
                                                                        !isDisabledByDetailStatuses(
                                                                            'delete',
                                                                            row,
                                                                            Boolean(Number(row.reservedCount))
                                                                        )
                                                                )
                                                                .map(({ id }) => id)
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    await notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.deleted'
                                                        })
                                                    });
                                                    this.updateDataSource();
                                                } catch (e) {
                                                    notification.warning({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.delete_failed'
                                                        })
                                                    });
                                                }
                                            }
                                        });
                                    }}
                                >
                                    <div>
                                        <DeleteOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'delete' })}
                                    </div>
                                </Menu.Item>
                                <Menu.Item key='groupDetailsMenu.frequent' disabled={!selectedRowKeys.length}>
                                    <div
                                        onClick={() => {
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'add_order_form.favourite_confirm'
                                                }),
                                                onOk: async () => {
                                                    const { status: st } = await fetchAPI(
                                                        'GET',
                                                        'orders/status',
                                                        { orderId: this.props.orderId },
                                                        null
                                                    );
                                                    if (st === 'success') {
                                                        notification.error({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'order_form_table.closed_order_notific'
                                                            })
                                                        });

                                                        return;
                                                    }
                                                    const data = [];
                                                    this.state.selectedRows
                                                        .filter(({ frequentDetailId }) => !frequentDetailId)
                                                        .forEach(row =>
                                                            data.push({
                                                                storeGroupId: row.storeGroupId,
                                                                detailCode: row.detailCode,
                                                                detailName: row.detailName,
                                                                productBrandId:
                                                                    row.productBrandId || row.supplierBrandId,
                                                                supplierId: row.supplierId
                                                            }));

                                                    await fetchAPI('POST', 'orders/frequent/details', null, data, {
                                                        handleErrorInternally: true
                                                    });
                                                    await notification.success({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'details_table.added'
                                                        })
                                                    });
                                                    await this.updateDataSource();
                                                }
                                            });
                                        }}
                                    >
                                        <StarFilled
                                            style={{ color: 'gold', fontSize: 18 }}
                                            title={this.props.intl.formatMessage({
                                                id: 'add_to_favorites'
                                            })}
                                        />
                                        {this.props.intl.formatMessage({ id: 'add_to_favorites' })}
                                    </div>
                                </Menu.Item>

                                <Menu.Item
                                    key='groupDetailsMenu.import_doc'
                                    onClick={async () => {
                                        const { status: st } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (st === 'success') {
                                            notification.error({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.closed_order_notific'
                                                })
                                            });

                                            return;
                                        }

                                        this.props.setModal(MODALS.IMPORT_RECEIPT_DOCUMENT_MODAL, {
                                            visibleParts: true
                                        });
                                    }}
                                >
                                    <ImportOutlined className={Styles.actionMenuIcon} style={{ fontSize: 18 }} />
                                    {this.props.intl.formatMessage({ id: 'directory_page.import_document' })}
                                </Menu.Item>
                                {!this.props.isRetailOrder && (
                                    <Menu.Item
                                        key='groupDetailsMenu.mount'
                                        disabled={!selectedRowKeys}
                                        onClick={async () => {
                                            const { status: st } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (st === 'success') {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.closed_order_notific'
                                                    })
                                                });

                                                return;
                                            }
                                            const payload = {
                                                updateMode: true,
                                                details: [
                                                    ...this.state.selectedRows
                                                        .filter(row => !isDisabledByDetailStatuses('mount', row))
                                                        .map(({ id }) => ({
                                                            id,
                                                            status: 'MOUNTED'
                                                        }))
                                                ]
                                            };
                                            await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                            await this.updateDataSource();
                                        }}
                                    >
                                        <ShrinkOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'profile.mount' })}
                                    </Menu.Item>
                                )}
                            </Menu>
                        );

                    return <HamburgerMenu actionsMenu={actionsMenu} disabled={this.props.disabled} />;
                },
                key: 'actions',
                align: 'center',
                render: row => {
                    const { user, isRTN } = this.props;

                    const actionsMenu = () =>
                        isRTN ? (
                            <Menu>
                                <Menu.Item
                                    key='detailsActionMenu.delete'
                                    disabled={isDisabledByDetailStatuses('delete', row) || row.reservedCount >= 0.01}
                                >
                                    <div
                                        onClick={() => {
                                            if (
                                                isDisabledByDetailStatuses('delete', row) ||
                                                row.reservedCount >= 0.01
                                            ) {
                                                return;
                                            }
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'add_order_form.delete_confirm'
                                                }),
                                                onOk: async () => {
                                                    const { status: st } = await fetchAPI(
                                                        'GET',
                                                        'orders/status',
                                                        { orderId: this.props.orderId },
                                                        null
                                                    );
                                                    if (st === 'success') {
                                                        notification.error({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'order_form_table.closed_order_notific'
                                                            })
                                                        });

                                                        return;
                                                    }
                                                    await fetchAPI('DELETE', 'orders/details', undefined, {
                                                        ids: [row.id]
                                                    });
                                                    await this.updateDataSource();
                                                }
                                            });
                                        }}
                                    >
                                        <DeleteOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'delete' })}
                                    </div>
                                </Menu.Item>
                                {isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_FILE_STORAGE) && (
                                    <Menu.Item key='detailsActionMenu.addPhoto'>
                                        <Upload
                                            action={elem => this.downloadPhoto(elem, row.id, row.detailCode)}
                                            beforeUpload={file => {
                                                const isPNG = file.type === 'image/png';
                                                const isJPG = file.type === 'image/jpeg';
                                                if (!isPNG && !isJPG) {
                                                    notification.error({
                                                        message: `${file.name} ${this.props.intl.formatMessage({
                                                            id: 'photo_modal.is_not_an_image'
                                                        })}!`
                                                    });
                                                }

                                                return isPNG || isJPG;
                                            }}
                                            showUploadList={false}
                                        >
                                            <VideoCameraOutlined className={Styles.actionMenuIcon} />
                                            <FormattedMessage id='gallary.download_file' />
                                        </Upload>
                                    </Menu.Item>
                                )}
                            </Menu>
                        ) : (
                            <Menu>
                                {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE) && (
                                    <Menu.Item key='detailsActionMenu.updateStage'>
                                        <div
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: this.props.intl.formatMessage({ id: 'orders.update_stage' }),
                                                    onOk: async () => {
                                                        const { status: st } = await fetchAPI(
                                                            'GET',
                                                            'orders/status',
                                                            { orderId: this.props.orderId },
                                                            null
                                                        );
                                                        if (st === 'success') {
                                                            notification.error({
                                                                message: this.props.intl.formatMessage({
                                                                    id: 'order_form_table.closed_order_notific'
                                                                })
                                                            });

                                                            return;
                                                        }
                                                        const response = await fetchAPI(
                                                            'PUT',
                                                            'orders/details',
                                                            undefined,
                                                            {
                                                                details: [{ id: row.id }]
                                                            },
                                                            { handleErrorInternally: true }
                                                        );
                                                        await showDetailsActionNotification(
                                                            this.props.intl,
                                                            'update_stage',
                                                            response.all,
                                                            response.success
                                                        );
                                                        await this.updateDataSource();
                                                    }
                                                });
                                            }}
                                        >
                                            <RedoOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({ id: 'orders.update_stage' })}
                                        </div>
                                    </Menu.Item>
                                )}
                                <Menu.Item
                                    key='detailsActionMenu.delete'
                                    disabled={isDisabledByDetailStatuses('delete', row) || row.reservedCount >= 0.01}
                                >
                                    <div
                                        onClick={() => {
                                            if (
                                                isDisabledByDetailStatuses('delete', row) ||
                                                row.reservedCount >= 0.01
                                            ) {
                                                return;
                                            }
                                            Modal.confirm({
                                                title: this.props.intl.formatMessage({
                                                    id: 'add_order_form.delete_confirm'
                                                }),
                                                onOk: async () => {
                                                    const { status: st } = await fetchAPI(
                                                        'GET',
                                                        'orders/status',
                                                        { orderId: this.props.orderId },
                                                        null
                                                    );
                                                    if (st === 'success') {
                                                        notification.error({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'order_form_table.closed_order_notific'
                                                            })
                                                        });

                                                        return;
                                                    }
                                                    await fetchAPI('DELETE', 'orders/details', undefined, {
                                                        ids: [row.id]
                                                    });
                                                    await this.updateDataSource();
                                                }
                                            });
                                        }}
                                    >
                                        <DeleteOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'delete' })}
                                    </div>
                                </Menu.Item>

                                <Menu.Item
                                    key='detailsActionMenu.copy'
                                    disabled={isDisabledByDetailStatuses('copy', row)}
                                    onClick={async () => {
                                        const { status: st } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (st === 'success') {
                                            notification.error({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.closed_order_notific'
                                                })
                                            });

                                            return;
                                        }
                                        if (!isDisabledByDetailStatuses('copy', row)) {
                                            const { status } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (status === 'success') {
                                                window.location.reload();

                                                return;
                                            }
                                            await fetchAPI('POST', 'orders/details', undefined, {
                                                details: [
                                                    {
                                                        storeGroupId: row.storeGroupId,
                                                        name: row.detailName,
                                                        productCode: row.detailCode,
                                                        supplierId: row.supplierId,
                                                        supplierBrandId: row.supplierBrandId || row.brandId,
                                                        purchasePrice: row.purchasePrice,
                                                        count: row.count,
                                                        price: row.price,
                                                        putAfterId: row.id,
                                                        orderId: this.props.orderId,
                                                        status: row.status,
                                                        partUnitId: row.partUnitId || 1,
                                                        partRowDiscount: row.partRowDiscount || 0,
                                                        oeCode: row.oeCode || null
                                                    }
                                                ]
                                            });
                                            await this.updateDataSource();
                                        }
                                    }}
                                >
                                    <CopyOutlined className={Styles.actionMenuIcon} />
                                    {this.props.intl.formatMessage({ id: 'profile.spare_parts_workplace.copy' })}
                                </Menu.Item>
                                {(isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO) ||
                                    isGrantAccessed(
                                        user,
                                        grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS,
                                        accesses.ROWO
                                    )) && (
                                    <Menu.Item
                                        key='detailsActionMenu.order'
                                        disabled={
                                            isDisabledByDetailStatuses('order', row) ||
                                            !row.detailCode.match(inputPattern) ||
                                            row.detailCode === null
                                        }
                                        onClick={async () => {
                                            const { status: st } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (st === 'success') {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.closed_order_notific'
                                                    })
                                                });

                                                return;
                                            }
                                            if (!isDisabledByDetailStatuses('order', row)) {
                                                await fetchAPI(
                                                    'POST',
                                                    'store_docs/order_all_possible',
                                                    undefined,
                                                    {
                                                        ordersAppurtenanciesIds: [row.id]
                                                    },
                                                    { handleErrorInternally: true }
                                                );
                                                await notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'details_table.ordered'
                                                    })
                                                });
                                                this.updateDataSource();
                                            }
                                        }}
                                    >
                                        <ShoppingOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({
                                            id: 'profile.spare_parts_workplace.to_order'
                                        })}
                                    </Menu.Item>
                                )}
                                {isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT, accesses.ROWO) && (
                                    <React.Fragment>
                                        <Menu.Item
                                            key='detailsActionMenu.accept'
                                            disabled={
                                                isDisabledByDetailStatuses('intake', row) ||
                                                !row.detailCode.match(inputPattern) ||
                                                row.detailCode === null
                                            }
                                            onClick={async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                if (!isDisabledByDetailStatuses('intake', row)) {
                                                    await this.setState({
                                                        visibleSuppliersIncomeModal: true,
                                                        suppliersIncomeModalRow: row
                                                    });
                                                }
                                            }}
                                        >
                                            <CheckOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({
                                                id: 'profile.spare_parts_workplace.accept'
                                            })}
                                        </Menu.Item>
                                        <Menu.Item
                                            key='detailsActionMenu.give'
                                            disabled={isDisabledByDetailStatuses('give', row)}
                                            onClick={async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                if (!isDisabledByDetailStatuses('give', row)) {
                                                    const response = await fetchAPI(
                                                        'POST',
                                                        'store_docs/transfer_reserved_all_possible',
                                                        undefined,
                                                        {
                                                            ordersAppurtenanciesIds: [row.id],
                                                            toWarehouseId: this.props.warehouses.find(
                                                                ({ attribute }) => attribute == 'REPAIR_AREA'
                                                            ).id
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    if (response && response.error) {
                                                        notification.error({
                                                            message:
                                                                response.message ||
                                                                this.props.intl.formatMessage({
                                                                    id: 'error'
                                                                })
                                                        });
                                                    } else {
                                                        notification.success({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'barcode.success'
                                                            })
                                                        });
                                                    }
                                                    this.updateDataSource();
                                                }
                                            }}
                                        >
                                            <ArrowRightOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({
                                                id: 'profile.spare_parts_workplace.give'
                                            })}
                                        </Menu.Item>
                                        <Menu.Item
                                            key='detailsActionMenu.toStock'
                                            disabled={isDisabledByDetailStatuses('ret_1', row)}
                                            onClick={async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                if (!isDisabledByDetailStatuses('ret_1', row)) {
                                                    const response = await fetchAPI(
                                                        'POST',
                                                        'store_docs/transfer_reserved_all_possible',
                                                        undefined,
                                                        {
                                                            ordersAppurtenanciesIds: [row.id],
                                                            toWarehouseId: this.props.warehouses.find(
                                                                ({ attribute }) => attribute == 'MAIN'
                                                            ).id
                                                        },
                                                        { handleErrorInternally: true }
                                                    );
                                                    if (response && response.error) {
                                                        notification.error({
                                                            message:
                                                                response.message ||
                                                                this.props.intl.formatMessage({
                                                                    id: 'error'
                                                                })
                                                        });
                                                    } else {
                                                        notification.success({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'barcode.success'
                                                            })
                                                        });
                                                    }
                                                    this.updateDataSource();
                                                }
                                            }}
                                        >
                                            <ArrowLeftOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({
                                                id: 'profile.spare_parts_workplace.return_to_stock'
                                            })}
                                        </Menu.Item>
                                        <Menu.Item
                                            key='detailsActionMenu.return'
                                            disabled={isDisabledByDetailStatuses('ret_2', row)}
                                            onClick={async () => {
                                                const { status: st } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (st === 'success') {
                                                    notification.error({
                                                        message: this.props.intl.formatMessage({
                                                            id: 'order_form_table.closed_order_notific'
                                                        })
                                                    });

                                                    return;
                                                }
                                                if (!isDisabledByDetailStatuses('ret_2', row)) {
                                                    await fetchAPI(
                                                        'POST',
                                                        'store_docs/return_to_supplier_all_possible',
                                                        undefined,
                                                        { ordersAppurtenanciesIds: [row.id] },
                                                        { handleErrorInternally: true }
                                                    );
                                                    this.updateDataSource();
                                                }
                                            }}
                                        >
                                            <UndoOutlined className={Styles.actionMenuIcon} />
                                            {this.props.intl.formatMessage({
                                                id: 'profile.spare_parts_workplace.return_to_supplier'
                                            })}
                                        </Menu.Item>
                                    </React.Fragment>
                                )}
                                {!row.frequentDetailId && (
                                    <Menu.Item key='detailsActionMenu.frequent'>
                                        <div
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: this.props.intl.formatMessage({
                                                        id: row.frequentDetailId
                                                            ? 'add_order_form.favourite_remove'
                                                            : 'add_order_form.favourite_confirm'
                                                    }),
                                                    onOk: async () => {
                                                        const { status: st } = await fetchAPI(
                                                            'GET',
                                                            'orders/status',
                                                            { orderId: this.props.orderId },
                                                            null
                                                        );
                                                        if (st === 'success') {
                                                            notification.error({
                                                                message: this.props.intl.formatMessage({
                                                                    id: 'order_form_table.closed_order_notific'
                                                                })
                                                            });

                                                            return;
                                                        }
                                                        if (row.frequentDetailId) {
                                                            await fetchAPI('DELETE', 'orders/frequent/details', {
                                                                ids: `[${row.frequentDetailId}]`
                                                            });
                                                            this.updateDataSource();
                                                        } else {
                                                            const data = [
                                                                {
                                                                    storeGroupId: row.storeGroupId,
                                                                    detailCode: row.detailCode,
                                                                    detailName: row.detailName,
                                                                    productBrandId:
                                                                        row.productBrandId || row.supplierBrandId,
                                                                    supplierId: row.supplierId
                                                                }
                                                            ];
                                                            await fetchAPI(
                                                                'POST',
                                                                'orders/frequent/details',
                                                                null,
                                                                data,
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            this.updateDataSource();
                                                        }
                                                    }
                                                });
                                            }}
                                        >
                                            {row.frequentDetailId ? (
                                                <StarFilled
                                                    className={Styles.actionMenuIcon}
                                                    style={{ color: 'gold' }}
                                                    title={this.props.intl.formatMessage({
                                                        id: 'delete_from_favorites'
                                                    })}
                                                />
                                            ) : (
                                                <StarOutlined
                                                    className={Styles.actionMenuIcon}
                                                    style={{ color: 'gold' }}
                                                    title={this.props.intl.formatMessage({
                                                        id: 'add_to_favorites'
                                                    })}
                                                />
                                            )}
                                            {this.props.intl.formatMessage({
                                                id: row.frequentDetailId ? 'delete_from_favorites' : 'add_to_favorites'
                                            })}
                                        </div>
                                    </Menu.Item>
                                )}
                                {!this.props.isRetailOrder && (
                                    <Menu.Item
                                        key='detailsActionMenu.mount'
                                        disabled={isDisabledByDetailStatuses('mount', row)}
                                        onClick={async () => {
                                            const { status: st } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (st === 'success') {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.closed_order_notific'
                                                    })
                                                });

                                                return;
                                            }
                                            if (!isDisabledByDetailStatuses('mount', row)) {
                                                const payload = {
                                                    updateMode: true,
                                                    details: [{ id: row.id, status: 'MOUNTED' }]
                                                };
                                                await fetchAPI(
                                                    'PUT',
                                                    `orders/${this.props.orderId}`,
                                                    undefined,
                                                    payload
                                                );
                                                await this.updateDataSource();
                                            }
                                        }}
                                    >
                                        <ShrinkOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'profile.mount' })}
                                    </Menu.Item>
                                )}
                                {Boolean(_.get(row, 'variations.length') || row.leadIndex) && (
                                    <Menu.Item
                                        key='detailsActionMenu.ungroup'
                                        onClick={async () => {
                                            const { status: st } = await fetchAPI(
                                                'GET',
                                                'orders/status',
                                                { orderId: this.props.orderId },
                                                null
                                            );
                                            if (st === 'success') {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.closed_order_notific'
                                                    })
                                                });

                                                return;
                                            }
                                            const payload = {
                                                updateMode: true,
                                                details: [
                                                    ...[...row.variations, row].map(({ id }) => ({
                                                        id,
                                                        groupName: null
                                                    }))
                                                ]
                                            };
                                            await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                            await this.updateDataSource();
                                        }}
                                    >
                                        <ForkOutlined className={Styles.actionMenuIcon} />
                                        {this.props.intl.formatMessage({ id: 'orders.ungroup' })}
                                    </Menu.Item>
                                )}
                                {isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_FILE_STORAGE) && (
                                    <Menu.Item key='detailsActionMenu.addPhoto'>
                                        <Upload
                                            action={elem => this.downloadPhoto(elem, row.id, row.detailCode)}
                                            beforeUpload={file => {
                                                const isPNG = file.type === 'image/png';
                                                const isJPG = file.type === 'image/jpeg';
                                                if (!isPNG && !isJPG) {
                                                    notification.error({
                                                        message: `${file.name} ${this.props.intl.formatMessage({
                                                            id: 'photo_modal.is_not_an_image'
                                                        })}!`
                                                    });
                                                }

                                                return isPNG || isJPG;
                                            }}
                                            showUploadList={false}
                                        >
                                            <VideoCameraOutlined className={Styles.actionMenuIcon} />
                                            <FormattedMessage id='gallary.download_file' />
                                        </Upload>
                                    </Menu.Item>
                                )}
                            </Menu>
                        );

                    return (
                        <HamburgerMenu actionsMenu={actionsMenu} disabled={this.props.disabled}>
                            <Button
                                data-qa='btn_menu_detail_table_order_page'
                                disabled={this.props.disabled}
                                icon={<MenuOutlined />}
                            />
                        </HamburgerMenu>
                    );
                }
            },
            {
                title: () => {
                    const { isRTN, openRTNModal } = this.props;

                    return isRTN ? (
                        <div>
                            <div className={Styles.headerActions}>
                                <Button
                                    data-qa='btn_header_actions_details_table_order_page'
                                    disabled={this.props.disabled}
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        openRTNModal();
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className={Styles.headerActions}>
                                <Tooltip title={<FormattedMessage id='add' />}>
                                    <Button
                                        data-qa='btn_header_actions_details_table_order_page'
                                        disabled={this.props.disabled}
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                            this.setState({
                                                detailModalVisibleMode: 1,
                                                detailModalProduct: {}
                                            });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id='order_form_table.catalog' />}>
                                    <Button
                                        data-qa='btn_header_actions_details_table_order_page'
                                        disabled={
                                            this.props.disabled ||
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.OPERATIONS_ORDER_DOCUMENT_PARTS_CATALOG
                                            )
                                        }
                                        icon={<BookIcon />}
                                        onClick={() => {
                                            this.setState({
                                                detailModalVisibleMode: 2,
                                                detailModalProduct: {}
                                            });
                                        }}
                                    />
                                </Tooltip>

                                <Button
                                    data-qa='btn_add_form_vin_order_detail_modal'
                                    disabled={
                                        !this.props.clientVehicleVin ||
                                        this.props.disabled ||
                                        !isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_VIN)
                                    }
                                    icon={<VinIcon style={{ fontSize: 20 }} />}
                                    onClick={() => {
                                        this.props.setModal(MODALS.VIN_MODAL, {
                                            setVinDetail: ({ oem, storeGroupId, name }) =>
                                                this.setState({
                                                    detailModalVisibleMode: 2,
                                                    detailModalProduct: {
                                                        detailCode: oem,
                                                        detailName: name,
                                                        oeCode: oem,
                                                        storeGroupId
                                                    }
                                                }),
                                            setVinDetails: async codes => {
                                                const { status } = await fetchAPI(
                                                    'GET',
                                                    'orders/status',
                                                    { orderId: this.props.orderId },
                                                    null
                                                );
                                                if (status === 'success') {
                                                    window.location.reload();

                                                    return;
                                                }
                                                const data = {
                                                    insertMode: true,
                                                    details: codes.map(({ oem, name, storeGroupId }) => ({
                                                        productCode: oem,
                                                        name,
                                                        groupId: storeGroupId,
                                                        oeCode: oem
                                                    }))
                                                };
                                                await fetchAPI('PUT', `orders/${this.props.orderId}`, null, data, {
                                                    handleErrorInternally: true
                                                });
                                                this.updateDataSource();
                                            },
                                            modalToLoad: MODALS.ORDER_DETAIL_MODAL
                                        });
                                    }}
                                />
                            </div>
                            <div className={Styles.headerActions}>
                                <Barcode
                                    button
                                    data-qa='barcode_details_table_order_page'
                                    defaultResponsibleId={this.props.defaultResponsibleId}
                                    disabled={this.props.disabled}
                                    multipleMode
                                    onConfirm={async (code, pref, fullCode) => {
                                        const { status } = await fetchAPI(
                                            'GET',
                                            'orders/status',
                                            { orderId: this.props.orderId },
                                            null
                                        );
                                        if (status === 'success') {
                                            window.location.reload();

                                            return;
                                        }
                                        const barcodeData = await fetchAPI('GET', 'barcodes', {
                                            barcode: fullCode
                                        });
                                        const productBarcode = barcodeData.find(
                                            ({ table }) => table == 'STORE_PRODUCTS'
                                        );

                                        if (productBarcode) {
                                            const payload = {
                                                insertMode: true,
                                                details: [],
                                                services: []
                                            };
                                            const product = await fetchAPI(
                                                'GET',
                                                `store_products/${productBarcode.referenceId}`
                                            );
                                            payload.details.push({
                                                productId: product.id,
                                                storeGroupId: product.groupId,
                                                name: product.name,
                                                productCode: product.code,
                                                supplierBrandId: product.brandId,
                                                supplierId: 0,
                                                count: 1,
                                                price: product.sellingPrice || 0,
                                                purchasePrice: product.purchasePrice || 0
                                            });
                                            await fetchAPI('PUT', `orders/${this.props.orderId}`, null, payload);
                                            await this.updateDataSource();
                                        } else if (code.length > 2) {
                                            // 4019064001232
                                            const tecDocProducts = await fetchTecdocAPI(
                                                'GET',
                                                '/tecdoc/products/gtin',
                                                {
                                                    gtin: code
                                                }
                                            );
                                            if (tecDocProducts && tecDocProducts.length) {
                                                const payload = {
                                                    insertMode: true,
                                                    details: [],
                                                    services: []
                                                };
                                                payload.details.push({
                                                    storeGroupId: tecDocProducts[0].storeGroupId,
                                                    name: tecDocProducts[0].description,
                                                    productCode: tecDocProducts[0].partNumber,
                                                    supplierBrandId: tecDocProducts[0].brandId,
                                                    count: 1,
                                                    price: 0,
                                                    purchasePrice: 0
                                                });
                                                await fetchAPI('PUT', `orders/${this.props.orderId}`, null, payload);
                                                await this.updateDataSource();
                                            } else {
                                                this.setState({
                                                    productBarcode: code
                                                });
                                                notification.warning({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order_form_table.code_not_found'
                                                    })
                                                });
                                            }
                                        } else {
                                            this.setState({
                                                productBarcode: code
                                            });
                                            notification.warning({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.code_not_found'
                                                })
                                            });
                                        }
                                    }}
                                    prefix='STP'
                                />
                                <Tooltip title={<FormattedMessage id='details_table.favorite_details' />}>
                                    <Button
                                        disabled={this.props.disabled}
                                        icon={<StarFilled />}
                                        onClick={() => {
                                            this.setState({
                                                favouriteModalVisible: true
                                            });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id='visual_addition' />}>
                                    <Button
                                        disabled={this.props.disabled}
                                        icon={<AppstoreAddOutlined />}
                                        onClick={() => this.props.setModal(MODALS.VISUAL_ADDITION)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    );
                },
                align: 'center',
                key: 'buttonGroup',
                render: row => {
                    const { isRTN } = this.props;

                    return (
                        <div className={Styles.rowActions}>
                            <Tooltip title={<FormattedMessage id='edit' />}>
                                <Button
                                    data-qa='button_quick_edit_modal_details_table_order_page'
                                    disabled={isDisabledByDetailStatuses(
                                        'edit',
                                        row,
                                        this.props.disabled || Number(row.reservedCount)
                                    )}
                                    icon={<PencilIcon />}
                                    onClick={() => {
                                        this.setState({
                                            detailModalVisibleMode: 1,
                                            detailModalProduct: row
                                        });
                                    }}
                                />
                            </Tooltip>
                            {!isRTN && (
                                <Tooltip title={<FormattedMessage id='order_form_table.catalog' />}>
                                    <Button
                                        data-qa='btn_show_detail_modal_details_table_order_page'
                                        disabled={isDisabledByDetailStatuses(
                                            'edit_catalogue',
                                            row,
                                            this.props.disabled || Number(row.reservedCount)
                                        )}
                                        icon={<BookIcon />}
                                        onClick={() => {
                                            this.setState({
                                                detailModalVisibleMode: 2,
                                                detailModalProduct: row
                                            });
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    );
                }
            },
            {
                title: () => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormattedMessage id='order_form_table.detail_code' />
                        <div
                            className={Styles.headerActions}
                            style={{
                                paddingLeft: 6,
                                opacity: this.state.selectedRowKeys.length == 0 && 0,
                                marginTop: this.state.selectedRowKeys.length == 0 && '-20px',
                                transitionDuration: '0.5s',
                                pointerEvents: this.state.selectedRowKeys.length == 0 && 'none',
                                justifyContent: 'left'
                            }}
                        >
                            <CloseCircleOutlined
                                onClick={async () => {
                                    const payload = {
                                        updateMode: true,
                                        details: []
                                    };
                                    this.state.selectedRows.map(elem => {
                                        if (!Number(elem.reservedCount) && elem.agreement == 'UNDEFINED') {
                                            payload.details.push({
                                                id: elem.id,
                                                productCode: null,
                                                productId: null,
                                                cellAddress: null,
                                                warehouseId: null,
                                                supplierBrandId: null,
                                                supplierId: null
                                            });
                                        }
                                    });
                                    await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                    this.updateDataSource();
                                }}
                                style={{
                                    fontSize: 24,
                                    color: 'var(--disabled)'
                                }}
                                title={this.props.intl.formatMessage({
                                    id: 'details_table.remove_code'
                                })}
                            />
                        </div>
                    </div>
                ),
                key: 'code',
                dataIndex: 'detailCode',
                render: (data, row) => {
                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700,
                                    textDecoration: row.detailCode && 'underline'
                                }}
                            >
                                {row.productId ? (
                                    isGrantAccessed(
                                        this.props.user,
                                        grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                                    ) ? (
                                        <Link to={`${book.product}/${row.productId}`}>{row.detailCode}</Link>
                                    ) : (
                                        <div>{row.detailCode}</div>
                                    )
                                ) : (
                                    <Tooltip title={<FormattedMessage id='details_table.product_card' />}>
                                        <span
                                            onClick={() => {
                                                if (
                                                    row.detailCode.match(inputPattern) &&
                                                    row.detailCode !== null &&
                                                    isGrantAccessed(
                                                        this.propsuser,
                                                        grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                                                    )
                                                ) {
                                                    this.props.setModal(MODALS.STORE_PRODUCT, {
                                                        code: row.detailCode,
                                                        brandId: row.brandId,
                                                        brandName: row.brandName,
                                                        name: row.detailName,
                                                        groupId: row.storeGroupId,
                                                        productUnitId: row.partUnitId || 1,
                                                        onSubmit: async (id, code) => {
                                                            if (!(row.detailCode && row.brandId)) {
                                                                const payload = {
                                                                    updateMode: true,
                                                                    details: [],
                                                                    services: []
                                                                };
                                                                const product = await fetchAPI(
                                                                    'GET',
                                                                    `store_products/${id}`
                                                                );
                                                                payload.details.push({
                                                                    id: row.id,
                                                                    productId: product.id,
                                                                    storeGroupId: product.groupId,
                                                                    name: product.name,
                                                                    productCode: product.code,
                                                                    supplierBrandId: product.brandId,
                                                                    supplierId: 0,
                                                                    price: row.price || product.sellingPrice || 0,
                                                                    purchasePrice:
                                                                        row.purchasePrice || product.purchasePrice || 0,

                                                                    partUnitId: row.partUnitId || 1
                                                                });
                                                                await fetchAPI(
                                                                    'PUT',
                                                                    `orders/${this.props.orderId}`,
                                                                    null,
                                                                    payload
                                                                );
                                                            }
                                                            await this.updateDataSource();
                                                        }
                                                    });
                                                } else {
                                                    undefined;
                                                }
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {row.detailCode || <FormattedMessage id='long_dash' />}
                                        </span>
                                    </Tooltip>
                                )}
                                {row.detailCode && (
                                    <Tooltip title={<FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />}>
                                        <CopyOutlined
                                            onClick={() => {
                                                navigator.clipboard.writeText(row.detailCode);
                                                notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'barcode.success'
                                                    })
                                                });
                                            }}
                                            style={{ marginLeft: 2, cursor: 'pointer' }}
                                        />
                                    </Tooltip>
                                )}
                            </div>

                            <div style={{ fontSize: 12, maxWidth: 360 }}>{row.detailName}</div>
                        </div>
                    );
                }
            },
            {
                title: () => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormattedMessage id='order_form_table.og_detail_code' />
                    </div>
                ),
                key: 'oeCode',
                dataIndex: 'oeCode',
                render: (data, row) => {
                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700,
                                    textDecoration: row.oeCode && 'underline'
                                }}
                            >
                                {row.productId ? (
                                    isGrantAccessed(
                                        this.props.user,
                                        grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                                    ) ? (
                                        <Link to={`${book.product}/${row.productId}`}>{row.oeCode}</Link>
                                    ) : (
                                        row.oeCode
                                    )
                                ) : (
                                    <Tooltip title={<FormattedMessage id='details_table.product_card' />}>
                                        <span
                                            onClick={() => {
                                                if (
                                                    isGrantAccessed(
                                                        this.props.user,
                                                        grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                                                    )
                                                ) {
                                                    this.props.setModal(MODALS.STORE_PRODUCT, {
                                                        code: row.detailCode,
                                                        brandId: row.brandId,
                                                        brandName: row.brandName,
                                                        name: row.detailName,
                                                        groupId: row.storeGroupId,
                                                        productUnitId: row.partUnitId || 1,
                                                        onSubmit: async (id, code) => {
                                                            if (!(row.detailCode && row.brandId)) {
                                                                const payload = {
                                                                    updateMode: true,
                                                                    details: [],
                                                                    services: []
                                                                };
                                                                const product = await fetchAPI(
                                                                    'GET',
                                                                    `store_products/${id}`
                                                                );
                                                                payload.details.push({
                                                                    id: row.id,
                                                                    productId: product.id,
                                                                    storeGroupId: product.groupId,
                                                                    name: product.name,
                                                                    productCode: product.code,
                                                                    supplierBrandId: product.brandId,
                                                                    supplierId: 0,
                                                                    price: row.price || product.sellingPrice || 0,
                                                                    purchasePrice:
                                                                        row.purchasePrice || product.purchasePrice || 0,

                                                                    partUnitId: row.partUnitId || 1
                                                                });
                                                                await fetchAPI(
                                                                    'PUT',
                                                                    `orders/${this.props.orderId}`,
                                                                    null,
                                                                    payload
                                                                );
                                                            }
                                                            await this.updateDataSource();
                                                        }
                                                    });
                                                }
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {row.oeCode || <FormattedMessage id='long_dash' />}
                                        </span>
                                    </Tooltip>
                                )}
                                {row.oeCode && (
                                    <Tooltip title={<FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />}>
                                        <CopyOutlined
                                            onClick={() => {
                                                navigator.clipboard.writeText(row.oeCode);
                                                notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'barcode.success'
                                                    })
                                                });
                                            }}
                                            style={{ marginLeft: 2, cursor: 'pointer' }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brand',
                dataIndex: 'brandName',
                render: (data, row) => {
                    return data ? (
                        <Tooltip title={<FormattedMessage id='details_table.catalog_modal_title' />}>
                            <div
                                onClick={() => {
                                    if (!row.reservedCount && !this.props.disabled && row.agreement !== 'AGREED') {
                                        this.setState({
                                            detailModalVisibleMode: 2,
                                            detailModalProduct: row
                                        });
                                    }
                                }}
                                style={{
                                    cursor: row.reservedCount ? 'initial' : 'pointer',
                                    textDecoration: data && 'underline'
                                }}
                            >
                                {data}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            onClick={() => {
                                if (!row.reservedCount && !this.props.disabled && row.agreement !== 'AGREED') {
                                    this.setState({
                                        detailModalVisibleMode: 2,
                                        detailModalProduct: row
                                    });
                                }
                            }}
                            style={{
                                cursor: row.reservedCount ? 'initial' : 'pointer',
                                textDecoration: data && 'underline'
                            }}
                        >
                            <FormattedMessage
                                id='long_dash'
                                // onClick={() => {
                                //     if (!this.props.disabled) {
                                //         this.setState({
                                //             detailModalVisibleMode: 2,
                                //             detailModalProduct: row
                                //         });
                                //     }
                                // }}
                            />
                        </div>
                    );
                }
            },
            {
                title: () => (
                    <div
                        style={{
                            whiteSpace: 'pre',
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <FormattedMessage id='order_form_table.store_supplier_short' />
                        <div
                            className={Styles.headerActions}
                            style={{
                                paddingLeft: 6,
                                opacity: this.state.selectedRowKeys.length == 0 && 0,
                                marginTop: this.state.selectedRowKeys.length == 0 && '-20px',
                                transitionDuration: '0.5s',
                                pointerEvents: this.state.selectedRowKeys.length == 0 && 'none',
                                justifyContent: 'left'
                            }}
                        >
                            <CloseCircleOutlined
                                onClick={async () => {
                                    const payload = {
                                        updateMode: true,
                                        details: []
                                    };
                                    this.state.selectedRows.map(elem => {
                                        if (!Number(elem.reservedCount) && elem.agreement == 'UNDEFINED') {
                                            payload.details.push({
                                                id: elem.id,
                                                productId: null,
                                                cellAddress: null,
                                                warehouseId: null,
                                                supplierId: null
                                            });
                                        }
                                    });
                                    await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                                    this.updateDataSource();
                                }}
                                style={{
                                    fontSize: 24,
                                    color: 'var(--disabled)'
                                }}
                                title={this.props.intl.formatMessage({
                                    id: 'details_table.remove_supplier'
                                })}
                            />
                        </div>
                    </div>
                ),
                key: 'supplierName',
                width: 70,
                render: row => {
                    return (
                        <div>
                            <Tooltip title={<FormattedMessage id='details_table.stock_availability' />}>
                                <span
                                    onClick={() => {
                                        if (
                                            !Number(row.reservedCount) &&
                                            row.productId &&
                                            !this.props.disabled &&
                                            isGrantAccessed(
                                                this.props.user,
                                                grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                            )
                                        ) {
                                            this.setState({
                                                warehousesModalSelectedRow: row
                                            });
                                        }
                                    }}
                                    style={{
                                        cursor: !Number(row.reservedCount) && row.productId ? 'pointer' : 'initial',
                                        textDecoration: row.supplierId === 0 && 'underline'
                                    }}
                                >
                                    {row.supplierId === 0 ? (
                                        row.cellAddress ? (
                                            row.cellAddress
                                        ) : (
                                            row.warehouseName || row.supplierName
                                        )
                                    ) : (
                                        <FormattedMessage id='long_dash' />
                                    )}
                                </span>
                            </Tooltip>
                            {' / '}
                            <Tooltip title={<FormattedMessage id='details_table.suppliers_availability' />}>
                                <span
                                    onClick={() => {
                                        if (
                                            !Number(row.reservedCount) &&
                                            !this.props.disabled &&
                                            isGrantAccessed(
                                                this.props.user,
                                                grants.OPERATIONS_ORDER_DOCUMENT_PARTS,
                                                accesses.ROWO
                                            )
                                        ) {
                                            this.setState({
                                                supplierModalSelectedRow: row
                                            });
                                        }
                                    }}
                                    style={{
                                        cursor: !Number(row.reservedCount) ? 'pointer' : 'initial',
                                        textDecoration: row.supplierId !== 0 && row.supplierName && 'underline'
                                    }}
                                >
                                    {row.supplierId ? row.supplierName : <FormattedMessage id='long_dash' />}
                                </span>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.appurtenancies_responsible_short' />,
                key: 'responsible',
                align: 'center',
                render: ({ responsibleId, id, storeGroupId }) => {
                    const handleSelectChange = async newResponsibleId => {
                        if (newResponsibleId !== responsibleId) {
                            const payload = {
                                updateMode: true,
                                details: [
                                    {
                                        responsibleId: newResponsibleId,
                                        storeGroupId,
                                        id
                                    }
                                ]
                            };
                            await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);
                            this.updateDataSource();
                        }
                    };

                    return (
                        <Popover
                            content={
                                <Select
                                    allowClear
                                    disabled={this.props.disabled}
                                    onChange={handleSelectChange}
                                    onClear={() => handleSelectChange(null)}
                                    value={responsibleId}
                                >
                                    {this.props.employees
                                        .filter(({ posts }) =>
                                            posts.find(({ postName }) => postName === 'SPARE_PARTS_SPECIALIST'))
                                        .map(({ id, surname, name }) => (
                                            <Option key={id} value={id}>
                                                {surname} {name}
                                            </Option>
                                        ))}
                                </Select>
                            }
                            title={<FormattedMessage id='order_form_table.appurtenancies_responsible' />}
                            trigger='click'
                        >
                            <Button
                                icon={responsibleId ? <ToolFilled /> : <ToolOutlined />}
                                style={{ fontSize: 18 }}
                                type='text'
                            />
                        </Popover>
                    );
                }
            },
            ...(this.props.isRTN
                ? []
                : [
                      {
                          title: <FormattedMessage id='order_form_table.AI' />,
                          key: 'AI',
                          align: 'center',
                          render: row => {
                              return <AvailabilityIndicator indexArray={row.store} />;
                          }
                      }
                  ]),
            {
                title: () => (
                    <div className={Styles.numberColumn}>
                        <Button
                            icon={this.props.purchasePrices ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            onClick={() => {
                                this.props.showPurchasePrices();
                            }}
                            type='text'
                        />
                        <FormattedMessage id='order_form_table.purchasePrice' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                render: (data, row) => {
                    if (!this.props.purchasePrices) {
                        return <FormattedMessage id='long_dash' />;
                    }

                    const strVal = Number(data).toFixed(2);
                    const strValTooltip = Number(data).toFixed(4);

                    const discount = _.get(this.props, 'discount') || 0;
                    const marge =
                        row.price || row.purchasePrice ? ((row.price - row.purchasePrice) * 100) / row.price : 100;
                    const markup = row.price && row.purchasePrice ? (row.price / row.purchasePrice - 1) * 100 : 0;
                    const content = (
                        <div>
                            <div>
                                <FormattedMessage id='order_form_table.marge' />: {marge.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.markup' />: {markup.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.discount' />: {discount.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.purchasePrice' />: {strValTooltip}
                            </div>
                        </div>
                    );

                    return (
                        <Tooltip title={content}>
                            <span
                                style={{
                                    cursor: 'pointer',
                                    whiteSpace: 'normal',
                                    color: row.markupCheckFailed ? 'red' : null
                                }}
                            >
                                {row.isPartPurchasePriceUpdated !== null && (
                                    <div>
                                        <Badge color={row.isPartPurchasePriceUpdated ? 'green' : 'red'} />
                                    </div>
                                )}
                                {data ? (
                                    `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </span>
                        </Tooltip>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.price' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'price',
                dataIndex: 'price',
                render: (data, row) => {
                    const strVal = Number(data).toFixed(2);
                    const strValTooltip = Number(data).toFixed(4);

                    const discount = _.get(this.props, 'discount') || 0;
                    const marge =
                        row.price || row.purchasePrice ? ((row.price - row.purchasePrice) * 100) / row.price : 100;
                    const markup = row.price && row.purchasePrice ? (row.price / row.purchasePrice - 1) * 100 : 0;
                    const content = (
                        <div>
                            <div>
                                <FormattedMessage id='order_form_table.marge' />: {marge.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.markup' />: {markup.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.discount' />: {discount.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.price' />: {strValTooltip}
                            </div>
                        </div>
                    );

                    return (
                        <Tooltip title={content}>
                            <span
                                style={{
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    color: row.markupCheckFailed ? 'red' : null
                                }}
                            >
                                {data ? (
                                    `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </span>
                        </Tooltip>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.count' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'count',
                dataIndex: 'count',
                render: data => {
                    return (
                        <Tooltip
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.count' />: {Number(data).toFixed(4)}
                                </div>
                            }
                        >
                            <span>{data ? `${data.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}</span>{' '}
                        </Tooltip>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='services_table.units' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'measureUnit',
                width: 60,
                dataIndex: 'measureUnit',
                render: data => {
                    return <span>{data || undefined}</span>;
                }
            },
            ...(this.props.isRTN
                ? []
                : [
                      {
                          title: () => (
                              <div>
                                  <div>
                                      <FormattedMessage id='storage.RESERVE' />
                                  </div>
                                  <div
                                      className={Styles.headerActions}
                                      style={{
                                          paddingTop: 6,
                                          opacity: this.state.selectedRowKeys.length == 0 && 0,
                                          marginTop: this.state.selectedRowKeys.length == 0 && '-20px',
                                          transitionDuration: '0.5s',
                                          pointerEvents: this.state.selectedRowKeys.length == 0 && 'none'
                                      }}
                                  >
                                      <LockOutlined
                                          className={Styles.icon}
                                          onClick={async () => {
                                              const response = await fetchAPI(
                                                  'POST',
                                                  'v2/store_docs/reserve_all_possible',
                                                  undefined,
                                                  {
                                                      ordersAppurtenanciesIds: this.state.selectedRows.map(
                                                          ({ id }) => id
                                                      )
                                                  },
                                                  { handleErrorInternally: true }
                                              );
                                              this.setState({
                                                  fetched: false
                                              });

                                              this.checkStatus(0, { orderId: this.props.orderId }, 'reserved');
                                              await this.updateDataSource();
                                          }}
                                          title={this.props.intl.formatMessage({
                                              id: 'details_table.reserve'
                                          })}
                                      />
                                      <UnlockOutlined
                                          className={Styles.icon}
                                          onClick={async () => {
                                              const response = await fetchAPI(
                                                  'POST',
                                                  'v2/store_docs/unreserve_all_possible',
                                                  undefined,
                                                  {
                                                      ordersAppurtenanciesIds: this.state.selectedRows.map(
                                                          ({ id }) => id
                                                      )
                                                  },
                                                  { handleErrorInternally: true }
                                              );
                                              this.setState({
                                                  fetched: false
                                              });

                                              this.checkStatus(0, { orderId: this.props.orderId }, 'unreserved');
                                              await this.updateDataSource();
                                          }}
                                          title={this.props.intl.formatMessage({
                                              id: 'details_table.unreserve'
                                          })}
                                      />
                                  </div>
                              </div>
                          ),
                          className: Styles.numberColumn,
                          key: 'reserve',
                          align: 'center',
                          render: elem => {
                              const disabled =
                                  this.props.disabled ||
                                  !elem.id ||
                                  (elem.stage == INSTALLED && elem.agreement != 'REJECTED') ||
                                  !isGrantAccessed(
                                      this.props.user,
                                      grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                  );

                              return (
                                  <ReserveButton
                                      brands={this.props.allDetails.brands}
                                      data-qa='button_fetched_details_table'
                                      detail={elem}
                                      disabled={
                                          disabled ||
                                          elem.detailCode === null ||
                                          !elem.detailCode.match(inputPattern) ||
                                          elem.supplierBrandId === null
                                      }
                                      mainWarehouseId={this.state.mainWarehouseId}
                                      onClick={() => {
                                          this.setState({
                                              fetched: false
                                          });
                                      }}
                                      updateDetail={this.updateDataSource}
                                  />
                              );
                          }
                      }
                  ]),
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <div>
                            <Tooltip
                                placement='topRight'
                                title={this.props.intl.formatMessage({ id: 'client_container.discounts.info' })}
                            >
                                <QuestionCircleOutlined style={{ marginRight: 5, color: 'var(--primary)' }} />
                            </Tooltip>
                            <FormattedMessage id='order_form_table.discount' />
                        </div>
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'partRowDiscount',
                dataIndex: 'partRowDiscount',
                render: (data, row) => {
                    return <span>{`${row.partRowDiscount || 0}%`}</span>;
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'sum',
                dataIndex: 'sum',
                render: (data, row) => {
                    const generalDiscount = _.get(this.props.fetchedOrder, 'order.detailsDiscount');
                    const strVal =
                        generalDiscount !== null || generalDiscount > 0
                            ? data - (data * generalDiscount) / 100
                            : generalDiscount < 0
                            ? data + (data * generalDiscount) / 100
                            : data;

                    const discount = _.get(this.props, 'discount') || 0;
                    const marge =
                        row.price || row.purchasePrice ? ((row.price - row.purchasePrice) * 100) / row.price : 100;
                    const markup = row.price && row.purchasePrice ? (row.price / row.purchasePrice - 1) * 100 : 0;
                    const content = (
                        <div>
                            <div>
                                <FormattedMessage id='order_form_table.marge' />: {marge.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.markup' />: {markup.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.discount' />: {discount.toFixed(0)}%
                            </div>
                            <div>
                                <FormattedMessage id='order_form_table.sum' />: {strVal.toFixed(4)}
                            </div>
                        </div>
                    );

                    return !this.props.isMobile ? (
                        <Tooltip title={content}>
                            <span
                                style={{
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    color: row.markupCheckFailed ? 'red' : null
                                }}
                            >
                                {data ? (
                                    `${Number(strVal).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </span>
                        </Tooltip>
                    ) : (
                        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                            {data ? Number(data).toFixed(2) : <FormattedMessage id='long_dash' />}
                        </span>
                    );
                }
            },
            ...(this.props.isRTN
                ? []
                : [
                      {
                          title: () => {
                              const updateAgreement = async value => {
                                  const payload = {
                                      updateMode: true,
                                      details: []
                                  };
                                  this.state.selectedRows.map(elem => {
                                      payload.details.push({
                                          id: elem.id,
                                          storeGroupId: elem.storeGroupId,
                                          agreement: value.toUpperCase(),
                                          partUnitId: elem.partUnitId
                                      });
                                  });

                                  await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);

                                  if (value === 'rejected') {
                                      await fetchAPI(
                                          'POST',
                                          'store_docs/unreserve_all_possible',
                                          undefined,
                                          {
                                              ordersAppurtenanciesIds: this.state.selectedRows.map(({ id }) => id)
                                          },
                                          { handleErrorInternally: true }
                                      );
                                  }
                                  this.updateDataSource();
                              };
                              const forbiddenPD = this.state.selectedRows.findIndex(
                                  ({ transferedToRepairArea }) => transferedToRepairArea
                              );
                              const menu = (
                                  <Menu onClick={this.handleMenuClick}>
                                      <Menu.Item
                                          key='undefined'
                                          onClick={() => {
                                              updateAgreement('undefined');
                                          }}
                                      >
                                          <QuestionCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  verticalAlign: 'sub',
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.undefined' />
                                      </Menu.Item>
                                      <Menu.Item
                                          key='agreed'
                                          onClick={() => {
                                              updateAgreement('agreed');
                                          }}
                                          style={{ color: 'var(--green)' }}
                                      >
                                          <CheckCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  verticalAlign: 'sub',
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.agreed' />
                                      </Menu.Item>
                                      <Menu.Item
                                          key='rejected'
                                          onClick={() => {
                                              if (forbiddenPD > -1) {
                                                  notification.error({
                                                      message: this.props.intl.formatMessage({
                                                          id: 'order_form_table.pd_error_notific'
                                                      })
                                                  });
                                              } else {
                                                  updateAgreement('rejected');
                                              }
                                          }}
                                          style={{ color: 'rgb(255, 126, 126)' }}
                                      >
                                          <CloseCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.rejected' />
                                      </Menu.Item>
                                  </Menu>
                              );

                              return (
                                  <div>
                                      <FormattedMessage id='order_form_table.PD' />
                                      {isGrantAccessed(
                                          this.props.user,
                                          grants.OPERATIONS_ORDER_DOCUMENT_PARTS_REPAIR_ORDER_STATUS_CHANGE,
                                          accesses.ROWO
                                      ) && (
                                          <div
                                              className={Styles.headerActions}
                                              style={{
                                                  paddingTop: 6,
                                                  opacity: this.state.selectedRowKeys.length == 0 && 0,
                                                  marginTop: this.state.selectedRowKeys.length == 0 && '-20px',
                                                  transitionDuration: '0.5s',
                                                  pointerEvents: this.state.selectedRowKeys.length == 0 && 'none'
                                              }}
                                          >
                                              <Dropdown overlay={menu} trigger={['click']}>
                                                  <QuestionCircleOutlined
                                                      style={{
                                                          fontSize: 24
                                                      }}
                                                  />
                                              </Dropdown>
                                          </div>
                                      )}
                                  </div>
                              );
                          },
                          key: 'agreement',
                          align: 'center',
                          dataIndex: 'agreement',
                          render: (data, row) => {
                              const { key } = row;
                              const confirmed = data.toLowerCase();
                              let color = null;
                              let icon = <QuestionCircleOutlined />;
                              switch (confirmed) {
                                  case 'rejected':
                                      color = 'rgb(255, 126, 126)';
                                      icon = <CloseCircleOutlined />;
                                      break;
                                  case 'agreed':
                                      color = 'var(--green)';
                                      icon = <CheckCircleOutlined />;
                                      break;
                                  default:
                                      color = null;
                                      icon = <QuestionCircleOutlined />;
                              }
                              const updateAgreement = async value => {
                                  const payload = {
                                      updateMode: true,
                                      details: []
                                  };
                                  const variations = _.get(this.state.dataSource, `[${row.leadIndex}].variations`, []);
                                  if (_.get(this.state.dataSource, `[${row.leadIndex}].id`)) {
                                      console.log(payload, 'details');
                                      payload.details.push({
                                          id: _.get(this.state.dataSource, `[${row.leadIndex}].id`),
                                          agreement: 'REJECTED'
                                      });
                                  }
                                  variations.forEach(elem => {
                                      payload.details.push({
                                          id: elem.id,
                                          agreement: 'REJECTED'
                                      });
                                  });

                                  await fetchAPI('PUT', `orders/${this.props.orderId}`, undefined, payload);

                                  row.agreement = value.toUpperCase();
                                  if (value === 'rejected') {
                                      variations.push({ id: row.id });
                                  }
                                  if (variations.length) {
                                      await fetchAPI(
                                          'POST',
                                          'store_docs/unreserve_all_possible',
                                          undefined,
                                          {
                                              ordersAppurtenanciesIds: variations.map(({ id }) => id)
                                          },
                                          { handleErrorInternally: true }
                                      );
                                  }
                                  this.updateDetail(row);
                              };
                              const menu = (
                                  <Menu onClick={this.handleMenuClick}>
                                      <Menu.Item
                                          key='undefined'
                                          onClick={() => {
                                              updateAgreement('undefined');
                                          }}
                                      >
                                          <QuestionCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  verticalAlign: 'sub',
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.undefined' />
                                      </Menu.Item>
                                      <Menu.Item
                                          key='agreed'
                                          onClick={() => {
                                              updateAgreement('agreed');
                                          }}
                                          style={{ color: 'var(--green)' }}
                                      >
                                          <CheckCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  verticalAlign: 'sub',
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.agreed' />
                                      </Menu.Item>
                                      <Menu.Item
                                          key='rejected'
                                          onClick={() => {
                                              if (row.transferedToRepairArea) {
                                                  notification.error({
                                                      message: this.props.intl.formatMessage({
                                                          id: 'order_form_table.pd_error_notific'
                                                      })
                                                  });
                                              } else {
                                                  updateAgreement('rejected');
                                              }
                                          }}
                                          style={{ color: 'rgb(255, 126, 126)' }}
                                      >
                                          <CloseCircleOutlined
                                              style={{
                                                  fontSize: 18,
                                                  marginRight: 8
                                              }}
                                          />
                                          <FormattedMessage id='agreement.rejected' />
                                      </Menu.Item>
                                  </Menu>
                              );

                              return !isGrantAccessed(
                                  this.props.user,
                                  grants.OPERATIONS_ORDER_DOCUMENT_PARTS_REPAIR_ORDER_STATUS_CHANGE,
                                  accesses.ROWO
                              ) ? (
                                  <span
                                      style={{
                                          fontSize: 24,
                                          color
                                      }}
                                  >
                                      {icon}
                                  </span>
                              ) : (
                                  <div>
                                      <Dropdown
                                          data-qa='dropdown_aggrement_details_table'
                                          disabled={this.props.disabled}
                                          overlay={menu}
                                          trigger={['click']}
                                      >
                                          <span
                                              style={{
                                                  fontSize: 24,
                                                  color,
                                                  cursor: 'pointer'
                                              }}
                                          >
                                              {icon}
                                          </span>
                                      </Dropdown>
                                  </div>
                              );
                          }
                      }
                  ]),
            ...(this.props.isRTN
                ? []
                : [
                      {
                          title: <FormattedMessage id='order_form_table.status' />,
                          key: 'status',
                          dataIndex: 'status',
                          render: (data, row) => {
                              const { statuses } = this.state;
                              const currentStatus = (statuses || []).find(({ status }) => status === data);

                              const updateAgreement = value => {
                                  if (
                                      statuses.findIndex(({ status }) => status === data) >
                                      statuses.findIndex(({ status }) => status === value)
                                  ) {
                                      confirm({
                                          title: this.props.intl.formatMessage({
                                              id: 'profile.spare_parts_workplace.downgrade_status'
                                          }),
                                          onOk: () => {
                                              row.status = value;
                                              this.updateDetail(row);
                                          }
                                      });
                                  } else {
                                      row.status = value;
                                      this.updateDetail(row);
                                  }
                              };

                              const menu = (
                                  <Menu>
                                      {statuses.map(({ status, customStatusName }) => (
                                          <Menu.Item
                                              key={status}
                                              data-qa='menu_item_status_details_table'
                                              disabled={
                                                  row.reservedCount > 0 || status === 'ORDERED' || status === 'RETURNED'
                                              }
                                              onClick={() => {
                                                  updateAgreement(status);
                                              }}
                                          >
                                              {customStatusName || <FormattedMessage id={`status.${status}`} />}
                                          </Menu.Item>
                                      ))}
                                  </Menu>
                              );

                              return (
                                  <Dropdown
                                      data-qa='dropdown_current_details_table'
                                      disabled={
                                          this.props.disabled ||
                                          !isGrantAccessed(
                                              this.props.user,
                                              grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE
                                          )
                                      }
                                      overlay={menu}
                                      trigger={['click']}
                                  >
                                      <Tooltip title={<FormattedMessage id={`status.${data}.title`} />}>
                                          <div
                                              style={{
                                                  border: `2px solid ${row.statusColor}`,
                                                  padding: '6px 2px',
                                                  textAlign: 'center',
                                                  fontWeight: 500,
                                                  borderRadius: 6
                                              }}
                                          >
                                              {currentStatus && currentStatus.customStatusName ? (
                                                  currentStatus.customStatusName
                                              ) : (
                                                  <FormattedMessage id={`status.${data}`} />
                                              )}
                                          </div>
                                      </Tooltip>
                                  </Dropdown>
                              );
                          }
                      }
                  ])
            // {
            //     key: 'media',
            //     align: 'center',
            //     width: '5%',
            //     render: (data, row) => (
            //         <Upload
            //             action={elem => {
            //                 this.downloadPhoto(elem, row.id, row.detailCode);
            //                 elem.stopPropagation();
            //             }}
            //             beforeUpload={file => {
            //                 const isPNG = file.type === 'image/png';
            //                 const isJPG = file.type === 'image/jpeg';
            //                 if (!isPNG && !isJPG) {
            //                     notification.error({
            //                         message: `${file.name} ${this.props.intl.formatMessage({
            //                             id: 'photo_modal.is_not_an_image'
            //                         })}!`
            //                     });
            //                 }

            //                 return isPNG || isJPG;
            //             }}
            //             showUploadList={false}
            //         >
            //             <VideoCameraOutlined className={Styles.actionMenuIcon} />
            //         </Upload>
            //     )
            // }
        ];
    }

    checkStatus = async (index = 0, { orderId, pcode, productId } = {}, actionNtf = '') => {
        if (index >= 20) {
            notification.error({ message: 'Invalid' });

            return;
        }
        const resp = await fetchAPI('GET', 'mq_processes', {
            orderId,
            pcode,
            productId
        });
        const status = _.get(resp, 'list[0].state');

        if (status && status !== 'OK') {
            setTimeout(() => this.checkStatus(index + 1, { orderId, pcode, productId }, actionNtf), 1000);

            return;
        }
        if (status === 'OK') {
            await showDetailsActionNotification(this.props.intl, actionNtf, true, true);
        }
        this.updateDataSource();
    };

    formatToMilliseconds = date => {
        return date.valueOf();
    };

    downloadPhoto = async (file, id, text) => {
        const url = `${__GALLARY_IMAGES_URL__}/api/upload_file`;

        const {
            orderId,
            orderNum,
            intl: { formatMessage }
        } = this.props;

        const formData = new FormData();

        formData.append('file', file);
        formData.append('type', 'img');
        formData.append('name', file.name);
        formData.append('det', id);
        formData.append('detTxt', text);
        formData.append('ord', orderId);
        formData.append('ordTxt', orderNum);
        // formData.append('ord', 1);
        // formData.append('det', 2);
        // formData.append('dgn', 3);

        // formData.append('ordTxt', 'MRD-1174-1');
        // formData.append('detTxt', 'product-code-1 brand-1');
        // formData.append('dgnTxt', 'change left wheel');

        await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: getStorageToken()
            },
            body: formData
        }).then(function (response) {
            if (response.status !== 200) {
                return notification.error({
                    message: `${formatMessage({
                        id: 'gallary.not_enought_space'
                    })}!`
                });
            }

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });

            return Promise.resolve(response);
        });

        await this.props.fetchFileList();
        await this.props.bussinessStats();
    };

    fetchData = async () => {
        this.props.fetchWarehouses();
        let statuses = [];
        if (isGrantAccessed(this.props.user, grants.SETTINGS_PART_STATUSES)) {
            statuses = await fetchAPI('GET', 'status_settings');
        }
        this.setState({
            statuses,
            fetched: true
        });
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_add_s_p_data' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    updateDataSource = cb => {
        if (this.state.fetched && !this.props.isMobile) {
            this.setState({
                fetched: false
            });
        }
        if (this.props.isRetailOrder) {
            this.setState({
                fetched: false
            });
        }
        const callback = async data => {
            await this.setState({
                dataSource: this.buildVariations(data.orderDetails),
                selectedRowKeys: [],
                selectedRows: [],
                fetched: true
            });

            if (_.isFunction(cb)) {
                cb();
            }
        };
        this.props.reloadOrderForm(callback, 'details');
    };

    updateDetail = async detail => {
        if (this.props.isRetailOrder) {
            if (this.props.orderStatus === 'success') {
                window.location.reload();

                return;
            }
        } else {
            const { status } = await fetchAPI('GET', 'orders/status', { orderId: this.props.orderId }, null);
            if (status === 'success') {
                window.location.reload();

                return;
            }
        }
        if (this.state.fetched && !this.props.isMobile) {
            this.setState({
                fetched: false
            });
        }
        if (this.props.isRetailOrder) {
            this.setState({
                fetched: false
            });
        }
        // const productCode = (detail.detailCode || detail.productCode)
        //     .replace(/[^A-Za-z0-9\u0400-\u04FF]/gm, '')
        //     .toUpperCase();
        const newDetail = this.parseDetailData(detail);
        const data = {
            updateMode: true,
            details: [newDetail]
        };
        if (
            isGrantAccessed(
                this.props.user,
                grants.OPERATIONS_ORDER_DOCUMENT_PARTS_REPAIR_ORDER_STATUS_CHANGE,
                accesses.ROWO
            )
        ) {
            data.details[0].agreement = detail.agreement;
        }
        if (
            isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PART_STATUS_CHANGE, accesses.ROWO)
        ) {
            data.details[0].status = detail.status;
        }

        const { orderId } = this.props;
        if (this.props.isRetailOrder) {
            await fetchAPI('PUT', `retail/${orderId}`, null, data, { handleErrorInternally: true });
        } else {
            await fetchAPI('PUT', `orders/${orderId}`, null, data, { handleErrorInternally: true });
        }
        this.updateDataSource();
    };

    parseDetailData = detail => {
        return detail.productId
            ? {
                  id: detail.id,
                  parentId: detail.parentId || undefined,
                  storeGroupId: detail.storeGroupId,
                  name: detail.name || detail.detailName,
                  productId: detail.storeId || detail.productId,
                  productCode: detail.detailCode || detail.productCode || null,
                  purchasePrice: detail.purchasePrice || 0,
                  count: detail.count ? detail.count : 1,
                  price: detail.price ? detail.price : 0,
                  supplierBrandId: detail.supplierBrandId || detail.brandId,
                  supplierId: detail.supplierId,
                  supplierOriginalCode: detail.supplierOriginalCode,
                  supplierProductNumber: detail.supplierProductNumber,
                  supplierPartNumber: detail.supplierPartNumber,
                  cellAddress: detail.cellAddress || null,
                  warehouseId: detail.warehouseId,
                  partUnitId: detail.partUnitId || detail.productUnitId,
                  oeCode: detail.oeCode || null,
                  partRowDiscount: detail.partRowDiscount || 0,
                  comment: detail.comment || {
                      comment: undefined,
                      positions: []
                  }
              }
            : {
                  id: detail.id,
                  parentId: detail.parentId || undefined,
                  storeGroupId: detail.storeGroupId,
                  name: detail.name || detail.detailName,
                  productCode: detail.detailCode || detail.productCode || null,
                  supplierId: detail.supplierId,
                  supplierBrandId: detail.supplierBrandId || detail.brandId || null,
                  supplierOriginalCode: detail.supplierOriginalCode,
                  supplierProductNumber: detail.supplierProductNumber,
                  supplierPartNumber: detail.supplierPartNumber,
                  purchasePrice: detail.purchasePrice || 0,
                  count: detail.count,
                  price: detail.price ? detail.price : 0,
                  partUnitId: detail.partUnitId || detail.productUnitId,
                  oeCode: detail.oeCode || null,
                  partRowDiscount: detail.partRowDiscount || 0,
                  comment: detail.comment || {
                      comment: undefined,
                      positions: []
                  }
              };
    };

    componentDidMount() {
        this.fetchData();

        if (this.props.showOilModal) {
            this.setState({
                detailModalVisibleMode: 1,
                detailModalProduct: {}
            });
        }

        this.setState({
            dataSource: this.buildVariations(this.props.orderDetails)
        });
    }

    buildVariations = orderDetails => {
        const tmp = [...orderDetails]
            .map((elem, key) => {
                return { ...elem, variations: [] };
            })
            .filter(({ groupName, agreement, id }) => {
                const res = orderDetails.filter(({ groupName: gpName }) => gpName === groupName);
                if (res.every(({ agreement }) => agreement === 'REJECTED') && id === res[0].id) {
                    return true;
                }

                return (groupName && agreement !== 'REJECTED') || !groupName;
            });

        orderDetails
            .filter(({ id }) => tmp.findIndex(({ id: ID }) => ID === id) === -1)
            .forEach((elem, key) => {
                const index = tmp.findIndex(({ groupName }) => groupName === elem.groupName);
                if (index > -1) {
                    tmp[index].variations.push({
                        ...elem,
                        leadIndex: index,
                        key,
                        brandId: elem.supplierBrandId || undefined,
                        brandName: elem.supplierBrandName
                    });
                }
            });

        return tmp.map((elem, key) => ({
            ...elem,
            key,
            brandId: elem.supplierBrandId || undefined,
            brandName: elem.supplierBrandName
        }));
    };

    async componentDidUpdate(prevProps, prevState) {
        if (!prevProps.showOilModal && this.props.showOilModal) {
            this.setState({
                detailModalVisibleMode: 1,
                detailModalProduct: {}
            });
        }
        if (
            (prevProps.activeKey != 'details' && this.props.activeKey == 'details') ||
            prevProps.orderDetails != this.props.orderDetails
        ) {
            await this.setState({
                dataSource: this.buildVariations(this.props.orderDetails)
            });
        }
        if (prevProps.orderDetails !== this.props.orderDetails) {
            const details = this.state.dataSource
                .filter(row => Number(row.count) === Number(row.reservedCount) && row.supplierId !== 0)
                .map(elem => this.parseDetailData({ ...elem, supplierId: 0 }));
            if (details.length) {
                const data = {
                    updateMode: true,
                    details
                };
                await fetchAPI('PUT', `orders/${this.props.orderId}`, null, data, {
                    handleErrorInternally: true
                });
                this.updateDataSource();
            }
        }
    }

    handleSearchChange = debounce(e => this.setState({ search: e.target.value }), 300);

    filterDetails = dataSource => {
        const search = this.state.search.toLowerCase();

        return dataSource.filter(
            ({ detailName, detailCode, brandName }) =>
                filterStringByPart(search, String(detailName)) ||
                filterStringByPart(search, String(detailCode)) ||
                filterStringByPart(search, String(brandName))
        );
    };

    handleSearchSuppliers = debounce(value => {
        this.props.fetchSuppliers(value, true);
    }, 1000);

    toggleSelectAll = (keys, data) =>
        this.setState({
            selectedRowKeys: keys.length === data.length ? [] : data.map(r => r.key),
            selectedRows: keys.length === data.length ? [] : data
        });

    render() {
        const {
            detailsSuggestionsFetching,
            suggestionsFetching,
            detailsTreeData,
            user,
            tecdocId,
            orderId,
            allDetails,
            clientVehicleVin,
            showOilModal,
            oilModalData,
            clearOilData,
            isMobile,
            modal,
            resetModal,
            setModal,

            disabled,
            selectedVehicle,
            defaultResponsibleId,
            units,
            clientId,
            suppliers,
            isRetailOrder,
            selectedClient,
            isRTN
        } = this.props;
        const {
            fetched,
            dataSource,
            reserveModalData,
            productBarcode,
            selectedRowKeys,
            warehousesModalSelectedRow,
            selectedRows,
            setPriceModal,
            setPriceModalValue,
            setPricePurchaseValue,
            supplierModalSelectedRow,
            detailModalVisibleMode,
            detailModalProduct,
            visibleSuppliersIncomeModal,
            suppliersIncomeModalRow,
            favouriteModalVisible,
            helperDrawerOpen,
            allLinks,
            search
        } = this.state;

        const { detailsDiscount, discount } = selectedClient || {};

        const tblDataSource = search.length ? this.filterDetails(dataSource) : dataSource;

        const columns =
            !isMobile && !isRetailOrder
                ? this.columns()
                : isRetailOrder && !isMobile
                ? this.columns().filter(({ key }) => key !== 'responsible')
                : isRetailOrder && isMobile
                ? this.columns().filter(
                      ({ key }) =>
                          key !== 'responsible' || key === 'code' || key === 'price' || key === 'count' || key === 'sum'
                  )
                : this.columns().filter(
                      ({ key }) => key === 'code' || key === 'price' || key === 'count' || key === 'sum'
                  );

        const headerCheckbox = (
            <Checkbox
                checked={selectedRowKeys.length}
                indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < tblDataSource.length}
                onChange={() => this.toggleSelectAll(selectedRowKeys, tblDataSource)}
            />
        );

        const rowSelection = {
            selectedRowKeys,
            selectedRows,
            columnTitle: headerCheckbox,
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            },
            // onSelectAll: (selected, selectedRows, changeRows) => {
            //     if (selected) {
            //         const selectedRowKeys = tblDataSource.map(({ id }) => id);

            //         this.setState({
            //             selectedRowKeys,
            //             selectedRows: tblDataSource
            //         });

            //         return;
            //     }

            //     this.setState({
            //         selectedRowKeys: [],
            //         selectedRows: []
            //     });
            // },
            onSelect: (record, selected) => {
                if (selected) {
                    this.setState({
                        selectedRowKeys: [...selectedRows, record].map(({ id }) => id),
                        selectedRows: [...selectedRows, record]
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: selectedRowKeys.filter(id => id !== record.id),
                    selectedRows: selectedRows.filter(({ id }) => id !== record.id)
                });
            },
            getCheckboxProps: record => ({
                disabled: !record.id
            })
        };

        const pagination = {
            defaultPageSize: 25,
            size: 'small',
            pageSizeOptions: [15, 25, 50, 100],
            hideOnSinglePage: dataSource.length < 25
        };

        return (
            <Catcher>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 8
                    }}
                >
                    <div style={{ display: 'flex', gap: 8, width: '100%' }}>
                        <Input
                            addonBefore={<SearchOutlined />}
                            allowClear
                            onChange={this.handleSearchChange}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.details_search'
                            })}
                            // value={search}
                        />
                    </div>
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            this.setState({
                                helperDrawerOpen: true
                            });
                            await this.fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22
                        }}
                        type='text'
                    />
                </div>
                <DraggableTable
                    addDragColumn={!isMobile}
                    bordered
                    className={Styles.detailsTable}
                    columns={columns}
                    dataSource={tblDataSource}
                    expandable={{
                        expandedRowRender: record => (
                            <DraggableTable
                                bordered
                                columns={columns}
                                dataSource={record.variations}
                                onDragEnd={async (fromIndex, toIndex) => {
                                    await fetchAPI(
                                        'PUT',
                                        'orders/swap_details',
                                        {
                                            orderId: this.props.orderId,
                                            order1: dataSource[fromIndex].order,
                                            order2: dataSource[toIndex].order
                                        },
                                        undefined,
                                        { handleErrorInternally: true }
                                    );
                                    await this.updateDataSource();
                                }}
                                onRow={product => ({
                                    onClick: () => {
                                        isMobile &&
                                            this.setState({
                                                detailModalVisibleMode: 1,
                                                detailModalProduct: product
                                            });
                                    }
                                })}
                                pagination={false}
                                rowSelection={
                                    !isMobile && {
                                        getCheckboxProps: () => ({
                                            disabled: true
                                        })
                                    }
                                }
                                showHeader={false}
                                size='small'
                            />
                        ),
                        rowExpandable: ({ variations }) => variations && variations.length
                    }}
                    loading={detailsSuggestionsFetching || suggestionsFetching || !fetched}
                    onDragEnd={async (fromIndex, toIndex) => {
                        await fetchAPI(
                            'PUT',
                            'orders/swap_details',
                            {
                                orderId: this.props.orderId,
                                order1: dataSource[fromIndex].order,
                                order2: dataSource[toIndex].order
                            },
                            undefined,
                            { handleErrorInternally: true }
                        );
                        await this.updateDataSource();
                    }}
                    onRow={product => ({
                        onClick: () => {
                            isMobile &&
                                this.setState({
                                    detailModalVisibleMode: 1,
                                    detailModalProduct: product
                                });
                        }
                    })}
                    pagination={pagination}
                    rowSelection={!isMobile && !disabled && rowSelection}
                    size='small'
                />
                {isMobile && (
                    <div
                        style={{
                            margin: '12px 0px 8px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 8
                        }}
                    >
                        <Button
                            data-qa='btn_add_form_vin_order_detail_modal'
                            disabled={!this.props.clientVehicleVin || this.props.disabled}
                            icon={<VinIcon style={{ fontSize: 20 }} />}
                            onClick={() => {
                                this.props.setModal(MODALS.VIN_MODAL, {
                                    setVinDetail: ({ oem, storeGroupId, name }) =>
                                        this.setState({
                                            detailModalVisibleMode: 2,
                                            detailModalProduct: {
                                                detailCode: oem,
                                                detailName: name,
                                                oeCode: oem,
                                                storeGroupId
                                            }
                                        }),
                                    setVinDetails: async codes => {
                                        const data = {
                                            insertMode: true,
                                            details: codes.map(({ oem, name, storeGroupId }) => ({
                                                productCode: oem,
                                                name,
                                                groupId: storeGroupId,
                                                oeCode: oem
                                            }))
                                        };
                                        await fetchAPI('PUT', `orders/${this.props.orderId}`, null, data, {
                                            handleErrorInternally: true
                                        });
                                        this.updateDataSource();
                                    },
                                    modalToLoad: MODALS.ORDER_DETAIL_MODAL
                                });
                            }}
                        />
                        <Button
                            data-qa='btn_header_actions_details_table_order_page'
                            disabled={this.props.disabled}
                            icon={<BookIcon />}
                            onClick={() => {
                                this.setState({
                                    detailModalVisibleMode: 2,
                                    detailModalProduct: {}
                                });
                            }}
                        />

                        <Button
                            data-qa='btn_show_detail_product_modal_details_table_order_page'
                            onClick={() =>
                                this.setState({
                                    detailModalVisibleMode: 1,
                                    detailModalProduct: {}
                                })
                            }
                        >
                            <FormattedMessage id='add' />
                        </Button>
                    </div>
                )}
                <OrderDetailModal
                    allDetails={allDetails.details}
                    brands={allDetails.brands}
                    clearOilData={clearOilData}
                    clientDiscount={discount}
                    clientId={clientId}
                    clientVehicleVin={clientVehicleVin}
                    defaultResponsibleId={defaultResponsibleId}
                    detailsDiscount={detailsDiscount}
                    downloadPhoto={this.downloadPhoto}
                    handleSearchSuppliers={this.handleSearchSuppliers}
                    hideModal={() => {
                        this.setState({
                            detailModalVisibleMode: 0,
                            detailModalProduct: {}
                        });
                    }}
                    isRetailOrder={this.props.isRetailOrder}
                    isRTN={isRTN}
                    modificationId={tecdocId}
                    oilModalData={oilModalData}
                    onFinish={this.updateDetail}
                    orderId={orderId}
                    product={detailModalProduct}
                    resetModal={resetModal}
                    selectedVehicle={selectedVehicle}
                    showOilModal={showOilModal}
                    suppliers={suppliers}
                    treeData={detailsTreeData}
                    units={units}
                    updateDataSource={this.updateDataSource}
                    user={user}
                    visible={detailModalVisibleMode}
                />
                <DetailSupplierModal
                    brandId={_.get(supplierModalSelectedRow, 'brandId')}
                    clientId={clientId}
                    detailCode={_.get(supplierModalSelectedRow, 'detailCode')}
                    hideButton
                    hideModal={() => {
                        this.setState({
                            supplierModalSelectedRow: undefined
                        });
                    }}
                    onSelect={item => {
                        supplierModalSelectedRow.supplierId = item.businessSupplierId;
                        supplierModalSelectedRow.purchasePrice = item.purchasePrice;
                        if (supplierModalSelectedRow.agreement !== AGREED) {
                            supplierModalSelectedRow.price = item.price;
                        }
                        supplierModalSelectedRow.supplierOriginalCode = item.supplierOriginalCode;
                        supplierModalSelectedRow.supplierPartNumber = item.supplierPartNumber;
                        this.updateDetail(supplierModalSelectedRow);
                    }}
                    storeGroupId={_.get(supplierModalSelectedRow, 'storeGroupId')}
                    user={user}
                    visible={Boolean(supplierModalSelectedRow)}
                />
                <UpdatePriceModal
                    handleClose={() => this.setState({ setPriceModal: undefined })}
                    handleOk={this.updateDataSource}
                    isRTN={isRTN}
                    open={setPriceModal}
                    ordersAppurtenanciesIds={
                        setPriceModal && setPriceModal.id ? [setPriceModal.id] : selectedRows.map(({ id }) => id)
                    }
                />

                <SetBarcodeModal
                    barcode={productBarcode}
                    confirmAction={async id => {
                        const payload = {
                            insertMode: true,
                            details: [],
                            services: []
                        };
                        const product = await fetchAPI('GET', `store_products/${id}`);
                        payload.details.push({
                            productId: product.id,
                            storeGroupId: product.groupId,
                            name: product.name,
                            productCode: product.code,
                            supplierBrandId: product.brandId,
                            supplierId: 0,
                            count: 1,
                            price: product.sellingPrice || 0,
                            purchasePrice: product.purchasePrice || 0
                        });
                        await fetchAPI('PUT', `orders/${orderId}`, null, payload);
                        await this.updateDataSource();
                    }}
                    hideModal={() => {
                        this.setState({
                            productBarcode: undefined
                        });
                    }}
                    visible={Boolean(productBarcode)}
                />
                <DetailWarehousesCountModal
                    count={_.get(warehousesModalSelectedRow, 'count')}
                    hideButton
                    hideModal={() => {
                        this.setState({
                            warehousesModalSelectedRow: undefined
                        });
                    }}
                    onSelect={(address, warehouseId) => {
                        warehousesModalSelectedRow.supplierId = 0;
                        warehousesModalSelectedRow.cellAddress = address;
                        warehousesModalSelectedRow.warehouseId = warehouseId;
                        this.updateDetail(warehousesModalSelectedRow);
                    }}
                    orderId={orderId}
                    productId={_.get(warehousesModalSelectedRow, 'productId')}
                    productUnit={_.get(warehousesModalSelectedRow, 'measureUnit')}
                    visible={Boolean(warehousesModalSelectedRow)}
                />
                <ImportDetailsModal
                    modal={modal}
                    orderId={orderId}
                    resetModal={resetModal}
                    suppliers={suppliers}
                    updateDataSource={this.updateDataSource}
                />
                <SuppliersIncomeModal
                    hideModal={() => {
                        this.setState({
                            selectedRowKeys: [],
                            selectedRows: [],
                            visibleSuppliersIncomeModal: false,
                            suppliersIncomeModalRow: undefined
                        });
                    }}
                    orderId={orderId}
                    row={suppliersIncomeModalRow}
                    selectedRows={selectedRows}
                    updateDataSource={this.updateDataSource}
                    visible={visibleSuppliersIncomeModal}
                />
                <FavouriteDetailsModal
                    hideModal={() => this.setState({ favouriteModalVisible: false })}
                    orderId={orderId}
                    updateDataSource={this.updateDataSource}
                    user={user}
                    visible={favouriteModalVisible}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}

export default DetailsTable;
