exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__mainContainer---2rvBP{width:100%;padding:0 .2em 1em;border:.2em solid rgba(0,0,0,.2)}.styles-m__row---3Pv8R{margin-top:1em}.styles-m__actionsCol---2doSV,.styles-m__col---3SdL0{padding:0 .2em}.styles-m__vehicle---1pRVr{display:flex;justify-content:space-between;margin-bottom:.4em}.styles-m__phoneNumber---1nirA{color:var(--link)}.styles-m__phoneNumber---1nirA:hover{color:var(--primary)}.styles-m__actionsCol---2doSV{display:flex;justify-content:center}.styles-m__closeIcon---1p-vE{color:red;font-size:16px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,iCACI,UAAW,CACX,kBAA0B,CAC1B,gCACJ,CAEA,uBACI,cACJ,CAEA,qDACI,cACJ,CAEA,2BACI,YAAa,CACb,6BAA8B,CAC9B,kBACJ,CAEA,+BACI,iBACJ,CAEA,qCACI,oBACJ,CAEA,8BACI,YAAa,CACb,sBACJ,CAEA,6BACI,SAAU,CACV,cACJ","file":"styles.m.css","sourcesContent":[".mainContainer {\n    width: 100%;\n    padding: 0 0.2em 1em 0.2em;\n    border: 0.2em solid rgba(0, 0, 0, 0.2);\n}\n\n.row {\n    margin-top: 1em;\n}\n\n.col, .actionsCol {\n    padding: 0 0.2em 0 0.2em;\n}\n\n.vehicle {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 0.4em;\n}\n\n.phoneNumber {\n    color: var(--link);\n}\n\n.phoneNumber:hover {\n    color: var(--primary);\n}\n\n.actionsCol {\n    display: flex;\n    justify-content: center;\n}\n\n.closeIcon {\n    color: red;\n    font-size: 16px;\n}"]}]);

// Exports
exports.locals = {
	"mainContainer": "styles-m__mainContainer---2rvBP",
	"row": "styles-m__row---3Pv8R",
	"col": "styles-m__col---3SdL0",
	"actionsCol": "styles-m__actionsCol---2doSV",
	"vehicle": "styles-m__vehicle---1pRVr",
	"phoneNumber": "styles-m__phoneNumber---1nirA",
	"closeIcon": "styles-m__closeIcon---1p-vE"
};