/* eslint-disable react/prefer-stateless-function */
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Dropdown, InputNumber, Menu, Modal } from 'antd';
import { saveAs } from 'file-saver';
import * as constants from 'pages/Storage/constants';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { decompressTemplate } from '../../../../../../../shared/utils/utils';

@injectIntl
class ReportsDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copyNumber: undefined
        };
    }

    render() {
        const menu = (
            <Menu>
                <Menu.Item
                    data-qa='menu_item_document_storage_document_page'
                    onClick={async () => {
                        const response = await fetchAPI(
                            'GET',
                            `orders/reports/${this.props.id}`,
                            { type: 'documentReport' },
                            null,
                            { rawResponse: true, handleErrorInternally: true }
                        );
                        const contentDispositionHeader = response.headers.get('content-disposition');
                        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
                        await this.props.setDocumentTitle(fileName);
                        const arrayBuffer = await response.arrayBuffer();
                        const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
                        const reportFile = await decompressTemplate(content);
                        this.props.setPrint(reportFile);
                    }}
                >
                    <FormattedMessage id='storage_document.document' />
                </Menu.Item>
                {(this.props.documentType === constants.SUPPLIER || this.props.operationCode === constants.INC) && (
                    <Menu.Item
                        onClick={async () => {
                            Modal.confirm({
                                title: this.props.intl.formatMessage({
                                    id: 'storage_document.tags_quantity'
                                }),
                                content: (
                                    <div>
                                        <div style={{ marginBottom: 8 }}>
                                            <InputNumber
                                                onChange={value => this.setState({ copyNumber: value })}
                                                placeholder={this.props.intl.formatMessage({
                                                    id: 'storage_document.tags_quantity'
                                                })}
                                                style={{
                                                    width: '75%'
                                                }}
                                                value={this.state.copyNumber}
                                            />
                                        </div>
                                    </div>
                                ),
                                onOk: async () => {
                                    const response = await fetchAPI(
                                        'GET',
                                        `orders/reports/${this.props.id}`,
                                        { type: 'tags', copyNumber: this.state.copyNumber },
                                        null,
                                        { rawResponse: true, handleErrorInternally: true }
                                    );
                                    const contentDispositionHeader = response.headers.get('content-disposition');
                                    const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
                                    await this.props.setDocumentTitle(fileName);
                                    const arrayBuffer = await response.arrayBuffer();
                                    const content = Buffer.isBuffer(arrayBuffer)
                                        ? arrayBuffer
                                        : Buffer.from(arrayBuffer);
                                    const reportFile = await decompressTemplate(content);
                                    this.props.setPrint(reportFile);
                                }
                            });
                        }}
                        // onClick={async () => {
                        //     const response = await fetchAPI(
                        //         'GET',
                        //         `orders/reports/${this.props.id}`,
                        //         { type: 'tags' },
                        //         null,
                        //         { rawResponse: true, handleErrorInternally: true }
                        //     );
                        //     const contentDispositionHeader = response.headers.get('content-disposition');
                        //     const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
                        //     await this.props.setDocumentTitle(fileName);
                        //     const arrayBuffer = await response.arrayBuffer();
                        //     const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
                        //     const reportFile = await decompressTemplate(content);
                        //     this.props.setPrint(reportFile);
                        // }}
                    >
                        <FormattedMessage id='storage_document.tags' />
                    </Menu.Item>
                )}
                {this.props.documentType === constants.PRESERVATION && (
                    <React.Fragment>
                        {' '}
                        <Menu.Item
                            data-qa='menu_item_document_storage_document_page'
                            onClick={async () => {
                                const response = await fetchAPI(
                                    'GET',
                                    `orders/reports/${this.props.id}`,
                                    { type: 'documentAcceptanceReport' },
                                    null,
                                    { rawResponse: true, handleErrorInternally: true }
                                );
                                const contentDispositionHeader = response.headers.get('content-disposition');
                                const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
                                await this.props.setDocumentTitle(fileName);
                                const arrayBuffer = await response.arrayBuffer();
                                const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
                                const reportFile = await decompressTemplate(content);
                                this.props.setPrint(reportFile);
                            }}
                        >
                            <FormattedMessage id='actOfAcceptanceReport' />
                        </Menu.Item>
                        <Menu.Item
                            data-qa='menu_item_document_storage_document_page'
                            onClick={async () => {
                                Modal.confirm({
                                    title: this.props.intl.formatMessage({
                                        id: 'storage_document.tags_quantity'
                                    }),
                                    content: (
                                        <div>
                                            <div style={{ marginBottom: 8 }}>
                                                <InputNumber
                                                    onChange={value => this.setState({ copyNumber: value })}
                                                    placeholder={this.props.intl.formatMessage({
                                                        id: 'storage_document.tags_quantity'
                                                    })}
                                                    style={{
                                                        width: '75%'
                                                    }}
                                                    value={this.state.copyNumber}
                                                />
                                            </div>
                                        </div>
                                    ),
                                    onOk: async () => {
                                        const response = await fetchAPI(
                                            'GET',
                                            `orders/reports/${this.props.id}`,
                                            { type: 'tags', copyNumber: this.state.copyNumber },
                                            null,
                                            { rawResponse: true, handleErrorInternally: true }
                                        );
                                        const contentDispositionHeader = response.headers.get('content-disposition');
                                        const fileName =
                                            contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
                                        await this.props.setDocumentTitle(fileName);
                                        const arrayBuffer = await response.arrayBuffer();
                                        const content = Buffer.isBuffer(arrayBuffer)
                                            ? arrayBuffer
                                            : Buffer.from(arrayBuffer);
                                        const reportFile = await decompressTemplate(content);
                                        this.props.setPrint(reportFile);
                                    }
                                });
                            }}
                        >
                            <FormattedMessage id='storage_document.tags' />
                        </Menu.Item>
                    </React.Fragment>
                )}
                <Menu.Item
                    data-qa='menu_item_xlsx_storage_document_page'
                    onClick={async () => {
                        const response = await fetchAPI('GET', `store_docs/${this.props.id}/xlsx`, null, null, {
                            rawResponse: true,
                            handleErrorInternally: true
                        });
                        const reportFile = await response.blob();

                        const contentDispositionHeader = response.headers.get('content-disposition');
                        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
                        await saveAs(reportFile, fileName);
                    }}
                >
                    XLSX
                </Menu.Item>
            </Menu>
        );

        return (
            isGrantAccessed(this.props.user, grants.WAREHOUSE_DOCUMENT_HEADER_DOCUMENT_PRINTING) && (
                <Dropdown data-qa='dropdown_file_document_storage_document_page' overlay={menu}>
                    <Button
                        icon={<PrinterOutlined style={{ fontSize: 24 }} />}
                        size='large'
                        style={{ marginLeft: 8 }}
                        type='text'
                    />
                </Dropdown>
            )
        );
    }
}

export default ReportsDropdown;
