import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { composeEnhancers } from './enhancers';
import { middleware, sagaMiddleware } from './middleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const store = createStore(rootReducer, composeEnhancers()(applyMiddleware(...middleware)));

export const persistor = persistStore(store);

export default store;

sagaMiddleware.run(rootSaga);
