exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__avgRating---2pvGN{margin-bottom:24px;font-size:24px}.styles-m__avgRatingStars---2rdBd{margin-left:24px}.styles-m__employeeFeedbackTable---32i-B td{vertical-align:middle}.styles-m__link---1PBpH{color:var(--link)}.styles-m__comment---1Lcir{width:320px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,6BACI,kBAAmB,CACnB,cACJ,CAEA,kCACI,gBACJ,CAEA,4CACI,qBACJ,CAEA,wBACI,iBACJ,CAEA,2BACI,WAAY,CACZ,kBAAmB,CACnB,eAAgB,CAChB,sBACJ","file":"styles.m.css","sourcesContent":[".avgRating {\n    margin-bottom: 24px;\n    font-size: 24px;\n}\n\n.avgRatingStars {\n    margin-left: 24px;\n}\n\n.employeeFeedbackTable td {\n    vertical-align: middle;\n}\n\n.link {\n    color: var(--link);\n}\n\n.comment {\n    width: 320px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"]}]);

// Exports
exports.locals = {
	"avgRating": "styles-m__avgRating---2pvGN",
	"avgRatingStars": "styles-m__avgRatingStars---2rdBd",
	"employeeFeedbackTable": "styles-m__employeeFeedbackTable---32i-B",
	"link": "styles-m__link---1PBpH",
	"comment": "styles-m__comment---1Lcir"
};