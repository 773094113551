import {
    DeleteOutlined,
    EditOutlined,
    InboxOutlined,
    LoadingOutlined,
    MenuOutlined,
    ShrinkOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Dropdown,
    Image,
    Input,
    Menu,
    Pagination,
    Popconfirm,
    Radio,
    Spin,
    Table,
    Tooltip,
    Upload,
    notification
} from 'antd';
import { Layout } from 'commons';
import { DateRangePicker, HamburgerMenu } from 'components';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import { EditPhotoFileModal } from 'modals';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { GridIcon } from 'theme';
import { fetchAPI, getStorageToken } from 'utils';
import Styles from './styles.m.css';

const { Dragger } = Upload;

const GallaryPage = ({ user, intl: { formatMessage }, isMobile }) => {
    const [fileList, setFileList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [displayType, setDisplayType] = useState('grid');
    const [query, setQuery] = useState('');
    const [startDate, setStartDate] = useState(dayjs().subtract(7, 'days'));
    const [endDate, setEndDate] = useState(dayjs());
    const [sortBy, setSortBy] = useState('date');
    const [stats, setStats] = useState('date');
    const [edit, setEdit] = useState();
    const [loading, setLoading] = useState(false);
    const [hover, setHover] = useState({
        show: false,
        imgId: undefined
    });
    const [openPreview, setOpenPreview] = useState(false);

    const [selectedPhotos, addSelectedPhotos] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [images, setImages] = useState([]);
    const [imagesSM, setImagesSM] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const formatToMilliseconds = date => {
        return date.valueOf();
    };

    const fetchFileList = useMemo(
        () => async query => {
            const formattedStartDate = formatToMilliseconds(startDate);
            const formattedEndDate = formatToMilliseconds(endDate);
            const res = await fetchAPI(
                'GET',
                '/api/files',
                {
                    query,
                    page,
                    pageSize,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    sortBy,
                    sortDirection: 'ASC'
                },
                null,
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken()
                    }
                }
            );

            setFileList(res && res.list);
            setCount(res && res.count);
        },
        [endDate, page, pageSize, startDate, sortBy]
    );

    const bussinessStats = useMemo(
        () => async () => {
            const res = await fetchAPI('GET', '/api/business/stats', null, null, {
                handleErrorInternally: true,
                url: __GALLARY_IMAGES_URL__,
                noAuth: true,
                headers: {
                    Authorization: getStorageToken()
                }
            });

            setStats(res);
        },
        []
    );

    const resizePhoto = useMemo(
        () => async hash => {
            await fetchAPI(
                'POST',
                '/api/files/async_operations',
                null,
                [
                    {
                        allFiles: false,
                        hash,
                        operation: {
                            type: 'RESIZE',
                            width: 1920,
                            height: 1080
                        },
                        type: 'img'
                    }
                ],
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();
            await bussinessStats();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },
        [bussinessStats, fetchFileList, formatMessage]
    );

    const resizePhotoGroup = useMemo(
        () => async () => {
            await fetchAPI(
                'POST',
                '/api/files/async_operations',
                null,
                [
                    ...(displayType === 'grid' ? selectedPhotos : selectedRows).map(({ hash, path }) => ({
                        allFiles: false,
                        hash: hash || path.split('.')[0],
                        operation: {
                            type: 'RESIZE',
                            width: 1920,
                            height: 1080
                        },
                        type: 'img'
                    }))
                ],
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();
            await bussinessStats();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },
        [bussinessStats, displayType, fetchFileList, formatMessage, selectedPhotos, selectedRows]
    );

    const deletePhoto = useMemo(
        () => async hash => {
            await fetchAPI(
                'POST',
                '/api/files/async_operations',
                null,
                [
                    {
                        operation: {
                            type: 'DELETE'
                        },
                        type: 'img',
                        hash
                    }
                ],
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();
            await bussinessStats();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },

        [bussinessStats, fetchFileList, formatMessage]
    );

    const deletePhotoGroup = useMemo(
        () => async () => {
            await fetchAPI(
                'POST',
                '/api/files/async_operations',
                null,
                [
                    ...(displayType === 'grid' ? selectedPhotos : selectedRows).map(({ hash, path }) => ({
                        hash: hash || path.split('.')[0],
                        operation: {
                            type: 'DELETE'
                        },
                        type: 'img'
                    }))
                ],
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();
            await bussinessStats();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },

        [bussinessStats, displayType, fetchFileList, formatMessage, selectedPhotos, selectedRows]
    );

    const formatMRD = mrd => {
        // Split the string by spaces
        const parts = mrd ? mrd.split(' ') : [];

        // Capitalize the first part and join with the other parts using hyphens
        const formattedMRD = parts
            .map((part, index) => {
                return index === 0 ? part.toUpperCase() : part;
            })
            .join('-');

        return formattedMRD;
    };

    const UnixTimestampToDate = unixTimestamp => {
        const formattedDate = dayjs
            .unix(unixTimestamp / 1000)
            .utc()
            .format('YYYY.MM.DD');

        return formattedDate;
    };

    const columns = useMemo(
        () => [
            {
                title: () => {
                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item
                                onClick={() => {
                                    resizePhotoGroup();
                                    addSelectedPhotos([]);
                                }}
                                style={{
                                    marginBottom: 8
                                }}
                            >
                                <div>
                                    <ShrinkOutlined
                                        style={{
                                            marginRight: 6
                                        }}
                                        type='text'
                                    />
                                    <FormattedMessage id='gallary.shrink_size' />
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                danger
                                onClick={() => {
                                    deletePhotoGroup();
                                    addSelectedPhotos([]);
                                }}
                                style={{
                                    marginBottom: 8
                                }}
                            >
                                <DeleteOutlined
                                    style={{
                                        marginRight: 6
                                    }}
                                    type='text'
                                />

                                <FormattedMessage id='delete' />
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} />;
                },
                key: 'actions',
                align: 'center',
                width: '15%',
                render: row => {
                    const hash = row.path ? row.path.split('.') : undefined;
                    const properName = row.name ? row.name.split('.') : undefined;

                    return (
                        <div className={Styles.actionBtn}>
                            <Tooltip title={<FormattedMessage id='gallary.edit_name' />}>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        setEdit(hash[0], properName[0]);
                                        setOpenEdit(true);
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id='gallary.shrink_size' />}>
                                <Button icon={<ShrinkOutlined />} onClick={() => resizePhoto(hash[0])} />
                            </Tooltip>

                            <Popconfirm
                                onConfirm={() => {
                                    deletePhoto(hash[0]);
                                }}
                                title={<FormattedMessage id='general_settings_delete_question' />}
                            >
                                <Tooltip title={<FormattedMessage id='delete' />}>
                                    <Button danger icon={<DeleteOutlined />} type='primary' />
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='photo' />,
                key: 'image',
                width: isMobile ? 'min-content' : '20%',
                align: 'center',
                render: (row, _, index) => {
                    const hash = row.path ? row.path.split('.') : undefined;

                    const properUrl = imagesSM ? imagesSM.find((elem, indx) => indx == index) : undefined;

                    return (
                        <Image
                            key={hash[0]}
                            height={160}
                            preview={{
                                src: images ? images.url : undefined
                            }}
                            src={properUrl ? properUrl.url : undefined}
                            width='75%'
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='locations.name' />,
                key: 'name',
                width: '15%',
                align: 'center',
                render: row => {
                    const properName = row.name ? row.name.split('.') : undefined;

                    return properName[0];
                }
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'date',
                width: '15%',
                align: 'center',
                render: row => {
                    const properDate = row.date ? UnixTimestampToDate(row.date) : undefined;

                    return properDate;
                }
            },
            {
                title: <FormattedMessage id='storage.product_code' />,
                key: 'code',
                width: '15%',
                align: 'center',
                render: row => {
                    const code = row.prdTxt ? row.prdTxt : undefined;

                    return <Link to={`${book.product}/${row.prd}`}>{code}</Link>;
                }
            },
            {
                title: <FormattedMessage id='directories.orders' />,
                key: 'order',
                width: '15%',
                align: 'center',
                render: row => {
                    const order = formatMRD(row.ordTxt && row.ordTxt);

                    return <Link to={`${book.order}/${row.ord}`}>{order}</Link>;
                }
            }
        ],
        [deletePhoto, deletePhotoGroup, images, imagesSM, isMobile, resizePhoto, resizePhotoGroup]
    );

    const fetchImage = async src => {
        const res = await fetchAPI('GET', src, null, null, {
            handleErrorInternally: true,
            url: __GALLARY_IMAGES_URL__,
            noAuth: true,
            rawResponse: true,
            headers: {
                Authorization: getStorageToken(),
                'Content-Type': 'application/json'
            }
        });

        const blob = await res.blob();

        setImages({
            url: URL.createObjectURL(blob),
            blob
        });

        return {
            blob,
            url: URL.createObjectURL(blob) // Create a temporary URL for the blob
        };
    };

    const handleSearchPhotos = useMemo(() => {
        const loadOptions = value => {
            fetchFileList(value);
        };

        return _.debounce(loadOptions, 500);
    }, [fetchFileList]);

    const downloadPhoto = async file => {
        setLoading(true);
        const url = `${__GALLARY_IMAGES_URL__}/api/upload_file`;

        const formData = new FormData();

        formData.append('file', file);
        formData.append('type', 'img');
        formData.append('name', file.name);
        // formData.append('ord', 1);
        // formData.append('det', 2);
        // formData.append('dgn', 3);
        // formData.append('prd', 4);

        // formData.append('ordTxt', 'MRD-1174-1');
        // formData.append('detTxt', 'product-code-1 brand-1');
        // formData.append('dgnTxt', 'change left wheel');
        // formData.append('prdTxt', 'product-code-2 brand-2');

        await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: getStorageToken()
            },
            body: formData
        }).then(function (response) {
            if (response.status !== 200) {
                return notification.error({
                    message: `${formatMessage({
                        id: 'gallary.not_enought_space'
                    })}!`
                });
            }

            return Promise.resolve(response);
        });

        setStartDate(dayjs().subtract(7, 'days'));
        setEndDate(dayjs());

        await fetchFileList();
        await bussinessStats();

        setLoading(false);
    };

    const formatFilenameSM = filename => {
        const [base, extension] = filename.split('.');

        const chunks = [];
        for (let i = 0; i < base.length; i += 2) {
            chunks.push(base.slice(i, i + 2));
        }

        const formattedBase = chunks.slice(0, 4).join('/');

        const formattedFilename = `${formattedBase}/${base}_sm.${extension}`;

        return formattedFilename;
    };

    const formatFilename = filename => {
        const [base, extension] = filename.split('.');

        const chunks = [];
        for (let i = 0; i < base.length; i += 2) {
            chunks.push(base.slice(i, i + 2));
        }

        const formattedBase = chunks.slice(0, 4).join('/');

        const formattedFilename = `${formattedBase}/${base}.${extension}`;

        return formattedFilename;
    };

    const fetchImagesSM = useMemo(
        () => async () => {
            const results = await Promise.all(
                fileList.map((obj, index) => {
                    const formattedHash = formatFilenameSM(obj.path);

                    return fetchImage(`api/file/${formattedHash}`).then(res => ({
                        url: res.url,
                        blob: res.blob,
                        index
                    }));
                })
            );

            setImagesSM(results);
        },
        [fileList]
    );

    // const fetchImages = useMemo(
    //     () => async () => {
    //         const results = await Promise.all(
    //             fileList.map((obj, index) => {
    //                 const formattedHash = formatFilename(obj.path);

    //                 return fetchImage(`api/file/${formattedHash}`).then(res => ({
    //                     url: res.url,
    //                     blob: res.blob,
    //                     index
    //                 }));
    //             })
    //         );

    //         setImages(results);
    //     },
    //     [fileList]
    // );

    const bytesToMB = bytes => {
        return (bytes / (1024 * 1024)).toFixed(2);
    };

    useEffect(() => {
        fetchImagesSM();
    }, [fetchImagesSM]);

    useEffect(() => {
        fetchFileList();
        bussinessStats();
    }, [bussinessStats, fetchFileList]);

    const rowSelection = useMemo(() => {
        return {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
                setSelectedRowKeys(selectedRowKeys);
            }
        };
        // }, [actionsMenu, selectedRowKeys, selectedRows]);
    }, [selectedRowKeys]);

    const pagination = {
        pageSize,
        size: 'large',
        total: Math.ceil(count / pageSize) * pageSize,
        current: page,
        onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
        }
    };

    const menu = (
        <Menu>
            <Menu.Item
                onClick={async () => {
                    setSortBy('date');
                }}
                style={{
                    border: '1px solid lightgrey',
                    marginBottom: 8,
                    backgroundColor: sortBy == 'date' ? 'rgb(210, 190, 230)' : 'white'
                }}
            >
                <FormattedMessage id='date' />
            </Menu.Item>
            <Menu.Item
                onClick={async () => {
                    setSortBy('name');
                }}
                style={{
                    border: '1px solid lightgrey',
                    marginBottom: 8,
                    backgroundColor: sortBy == 'name' ? 'rgb(210, 190, 230)' : 'white'
                }}
            >
                <FormattedMessage id='detail_order.name' />
            </Menu.Item>
            <Menu.Item
                onClick={async () => {
                    setSortBy('prdTxt');
                }}
                style={{
                    border: '1px solid lightgrey',
                    marginBottom: 8,
                    backgroundColor: sortBy == 'prdTxt' ? 'rgb(210, 190, 230)' : 'white'
                }}
            >
                <FormattedMessage id='storage.product_code' />
            </Menu.Item>
            <Menu.Item
                onClick={async () => {
                    setSortBy('ordTxt');
                }}
                style={{
                    border: '1px solid lightgrey',
                    marginBottom: 8,
                    backgroundColor: sortBy == 'ordTxt' ? 'rgb(210, 190, 230)' : 'white'
                }}
            >
                <FormattedMessage id='client-mrds-table.mrd_number' />
            </Menu.Item>
        </Menu>
    );

    const groupActionMenu = (
        <Menu>
            <Menu.Item
                onClick={() => {
                    resizePhotoGroup();
                    addSelectedPhotos([]);
                }}
                style={{
                    marginBottom: 8
                }}
            >
                <div>
                    <ShrinkOutlined
                        style={{
                            marginRight: 6
                        }}
                        type='text'
                    />
                    <FormattedMessage id='gallary.shrink_size' />
                </div>
            </Menu.Item>
            <Menu.Item
                danger
                onClick={() => {
                    deletePhotoGroup();
                    addSelectedPhotos([]);
                }}
                style={{
                    marginBottom: 8
                }}
            >
                <DeleteOutlined
                    style={{
                        marginRight: 6
                    }}
                    type='text'
                />

                <FormattedMessage id='delete' />
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout
            controls={
                <div>
                    <Dropdown overlay={menu}>
                        <Button style={{ fontSize: 14, marginRight: 10 }} type='primary'>
                            <FormattedMessage id='sort' />
                        </Button>
                    </Dropdown>
                    <DateRangePicker
                        allowClear
                        dateRange={[dayjs(startDate), dayjs(endDate)]}
                        format='YYYY.MM.DD.HH.mm.SS'
                        minimize
                        onDateChange={dateRange => {
                            setStartDate(dateRange[0]);
                            setEndDate(dateRange[1]);
                        }}
                        popupStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                            zIndex: '9999',
                            minWidth: 200
                        }}
                        style={{
                            marginRight: 8
                        }}
                    />
                    <Radio.Group
                        buttonStyle='solid'
                        onChange={event => {
                            setDisplayType(event.target.value);
                        }}
                        value={displayType}
                    >
                        <Radio.Button data-qa='button_view_grid_vin_code_modal' value='grid'>
                            <Tooltip position='bottom' title={<FormattedMessage id='theme_settings.colors.blocks' />}>
                                <GridIcon
                                    style={{
                                        fontSize: 18,
                                        verticalAlign: 'middle'
                                    }}
                                />
                            </Tooltip>
                        </Radio.Button>
                        <Radio.Button data-qa='button_view_unordered_list_vin_code_modal' value='list'>
                            <Tooltip position='bottom' title={<FormattedMessage id='list' />}>
                                <UnorderedListOutlined
                                    style={{
                                        fontSize: 18,
                                        verticalAlign: 'middle'
                                    }}
                                />
                            </Tooltip>
                        </Radio.Button>
                    </Radio.Group>
                    <div className={Styles.usedMBInfo}>
                        <FormattedMessage id='used' />: {stats ? bytesToMB(stats.usedBytes) : 0} MB /{' '}
                        <FormattedMessage id='remain_bites' />: {stats ? bytesToMB(stats.freeBytes) : 0} MB
                    </div>
                </div>
            }
            title={<FormattedMessage id='navigation.gallary' />}
        >
            <div className={Styles.dragger}>
                <Dragger
                    action={downloadPhoto}
                    beforeUpload={file => {
                        const isPNG = file.type === 'image/png';
                        const isJPG = file.type === 'image/jpeg';
                        if (!isPNG && !isJPG) {
                            notification.error({
                                message: `${file.name} ${formatMessage({
                                    id: 'photo_modal.is_not_an_image'
                                })}!`
                            });
                        }

                        return isPNG || isJPG;
                    }}
                    multiple
                    showUploadList={false}
                >
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                        <FormattedMessage id='web_settings.click_or_drag' />
                    </p>
                    <p className='ant-upload-hint'>
                        <FormattedMessage id='web_settings.upload_desc' />
                    </p>
                </Dragger>
            </div>

            <div>
                {displayType === 'grid' && (
                    <span>
                        <FormattedMessage id='all' />
                        <Checkbox
                            checked={
                                get(fileList, 'length') > 0 && get(fileList, 'length') == get(selectedPhotos, 'length')
                            }
                            disabled={get(fileList, 'length') < 1}
                            onChange={() => {
                                if (get(fileList, 'length') !== get(selectedPhotos, 'length')) {
                                    addSelectedPhotos(
                                        fileList.map(({ path, name }) => ({
                                            name: name ? name.split('.')[0] : undefined,
                                            path,
                                            hash: path ? path.split('.')[0] : undefined
                                        }))
                                    );
                                } else {
                                    addSelectedPhotos([]);
                                }
                            }}
                            style={{
                                marginLeft: 6
                            }}
                        />

                        <Dropdown disabled={!selectedPhotos} overlay={groupActionMenu}>
                            <Button
                                disabled={!selectedPhotos}
                                icon={<MenuOutlined />}
                                style={{ fontSize: 14, marginRight: 10 }}
                                type='text'
                            />
                        </Dropdown>
                    </span>
                )}
                <Input
                    allowClear
                    controls={false}
                    onChange={async event => {
                        setQuery(event.target.value);

                        handleSearchPhotos(event.target.value);
                    }}
                    // parser={value => value.replace('-', '')}
                    placeholder={formatMessage({
                        id: 'search'
                    })}
                    style={{
                        width: displayType === 'grid' ? '90%' : '100%'
                    }}
                    value={query}
                />
            </div>

            {get(fileList, 'length') && displayType == 'grid' && !loading ? (
                fileList.map(({ path, name, date, prdTxt, prd, ordTxt, ord, dgnTxt, detTxt, size }, index) => {
                    const properName = name ? name.split('.') : undefined;
                    const formattedName =
                        properName && properName[0].length > 13 ? `${properName[0].slice(0, 13)}...` : properName[0];

                    const hash = path ? path.split('.') : undefined;

                    const properDate = date ? UnixTimestampToDate(date) : undefined;

                    const findSelected = selectedPhotos.findIndex(elem => elem.hash === hash[0]);

                    const properUrl = imagesSM ? imagesSM.find((elem, indx) => indx == index) : undefined;

                    const order = ordTxt && formatMRD(ordTxt);

                    const detail = detTxt && detTxt.toUpperCase();
                    const diagnostics = dgnTxt && dgnTxt.length > 13 ? `${dgnTxt.slice(0, 13)}...` : dgnTxt;
                    const code = prdTxt && prdTxt.length > 13 ? `${prdTxt.slice(0, 13)}...` : prdTxt;

                    const formattedHash = formatFilename(path);

                    return (
                        <div
                            className={displayType == 'grid' ? Styles.displayGridImg : Styles.displayListImg}
                            onMouseEnter={() =>
                                setHover({
                                    show: true,
                                    imgId: hash[0]
                                })
                            }
                            onMouseLeave={() =>
                                setHover({
                                    show: false,
                                    imgId: hash[0]
                                })
                            }
                        >
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <Image
                                    key={hash[0]}
                                    height={250}
                                    loading={loading}
                                    onClick={() => {
                                        //   setOpenPreview(true);
                                        fetchImage(`api/file/${formattedHash}`);
                                    }}
                                    preview={{
                                        //   closeIcon: <CloseOutlined onClick={() => setOpenPreview(false)} />,

                                        //   visible: openPreview,
                                        mask: hover && hover.show && hover.imgId === hash[0] && (
                                            <div className={Styles.actionBtnGrid}>
                                                <Tooltip title={<FormattedMessage id='gallary.edit_name' />}>
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        onClick={e => {
                                                            setEdit({
                                                                hash: hash[0],
                                                                name: properName[0],
                                                                ord,
                                                                prd,
                                                                order,
                                                                prdTxt
                                                            });
                                                            setOpenEdit(true);
                                                            e.stopPropagation();
                                                        }}
                                                        style={{
                                                            marginRight: 6
                                                        }}
                                                    />
                                                </Tooltip>
                                                <Tooltip title={<FormattedMessage id='gallary.shrink_size' />}>
                                                    <Button
                                                        icon={<ShrinkOutlined />}
                                                        onClick={e => {
                                                            resizePhoto(hash[0]);
                                                            e.stopPropagation();
                                                        }}
                                                        style={{
                                                            marginRight: 6
                                                        }}
                                                    />
                                                </Tooltip>
                                                <Popconfirm
                                                    onConfirm={e => {
                                                        deletePhoto(hash[0]);
                                                        e.stopPropagation();
                                                    }}
                                                    title={<FormattedMessage id='general_settings_delete_question' />}
                                                >
                                                    <Tooltip title={<FormattedMessage id='delete' />}>
                                                        <Button
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                            }}
                                                            type='primary'
                                                        />
                                                    </Tooltip>
                                                </Popconfirm>
                                            </div>
                                        ),
                                        src: images ? images.url : undefined
                                    }}
                                    src={properUrl ? properUrl.url : undefined}
                                    width={!isMobile ? 420 : 372}
                                />

                                <Checkbox
                                    checked={findSelected > -1}
                                    onClick={() => {
                                        if (findSelected < 0) {
                                            addSelectedPhotos([
                                                ...selectedPhotos,
                                                {
                                                    name: properName[0],
                                                    path,
                                                    hash: hash[0]
                                                }
                                            ]);
                                        } else {
                                            const newSelectedPhotos = selectedPhotos.filter(
                                                (item, idx) => item.hash !== hash[0]
                                            );
                                            addSelectedPhotos(newSelectedPhotos);
                                        }
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: '2px',
                                        right: displayType == 'grid' ? '10px' : undefined,

                                        padding: '5px',
                                        borderRadius: '3px'
                                    }}
                                />
                            </div>
                            <div>
                                <div className={Styles.textBlock}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <Tooltip title={properName[0]}>
                                                    <FormattedMessage id='locations.name' />:{' '}
                                                    {formattedName || <FormattedMessage id='long_dash' />}
                                                </Tooltip>
                                            </div>

                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <Tooltip title={dgnTxt}>
                                                    <FormattedMessage id='DIAGNOSTICS' />:{' '}
                                                    {diagnostics || <FormattedMessage id='long_dash' />}
                                                </Tooltip>
                                            </div>

                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <FormattedMessage id='date' />:{' '}
                                                {properDate || <FormattedMessage id='long_dash' />}
                                            </div>
                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <FormattedMessage id='size' />:{' '}
                                                {size ? `${bytesToMB(size)} MB` : <FormattedMessage id='long_dash' />}
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <Link to={prd && `${book.product}/${prd}`}>
                                                    <Tooltip title={prdTxt}>
                                                        <FormattedMessage id='diagnostic-page.code' />:{' '}
                                                        {code || <FormattedMessage id='long_dash' />}
                                                    </Tooltip>
                                                </Link>
                                            </div>

                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <Link to={ord && `${book.order}/${ord}`}>
                                                    <FormattedMessage id='task_page.order' />:{' '}
                                                    {order || <FormattedMessage id='long_dash' />}
                                                </Link>
                                            </div>
                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <Link to={ord && `${book.order}/${ord}`}>
                                                    <FormattedMessage id='gallary.order_detail' />:{' '}
                                                    {detail || <FormattedMessage id='long_dash' />}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {isMobile && (
                                        <div className={Styles.actionBtnGrid}>
                                            <Tooltip title={<FormattedMessage id='gallary.edit_name' />}>
                                                <Button
                                                    icon={<EditOutlined />}
                                                    onClick={() => {
                                                        setEdit({
                                                            hash: hash[0],
                                                            name: properName[0],
                                                            ord,
                                                            prd,
                                                            order,
                                                            prdTxt
                                                        });
                                                        setOpenEdit(true);
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title={<FormattedMessage id='gallary.shrink_size' />}>
                                                <Button
                                                    icon={<ShrinkOutlined />}
                                                    onClick={() => resizePhoto(hash[0])}
                                                />
                                            </Tooltip>
                                            <Popconfirm
                                                onConfirm={() => {
                                                    deletePhoto(hash[0]);
                                                }}
                                                title={<FormattedMessage id='general_settings_delete_question' />}
                                            >
                                                <Button danger icon={<DeleteOutlined />} type='primary' />
                                            </Popconfirm>
                                        </div>
                                    )}
                                </div>
                            </div>{' '}
                        </div>
                    );
                })
            ) : loading ? (
                <div
                    style={{
                        padding: 8,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Spin indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />} />
                </div>
            ) : (
                []
            )}
            {displayType === 'list' && (
                <Table
                    bordered
                    columns={columns}
                    dataSource={fileList}
                    loading={loading}
                    pagination={pagination}
                    rowKey='path'
                    rowSelection={rowSelection}
                    scroll={isMobile && { x: 1100 }}
                    size='small'
                    style={{
                        marginTop: 12
                    }}
                />
            )}
            <EditPhotoFileModal
                edit={edit}
                fetchFileList={fetchFileList}
                hideModal={async () => {
                    await setOpenEdit(false);
                    setEdit(undefined);
                }}
                open={openEdit}
                title={<FormattedMessage id='gallary.edit_name' />}
            />

            {displayType === 'grid' && (
                <div className={Styles.pagination}>
                    <Pagination {...pagination} />
                </div>
            )}
        </Layout>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

export default injectIntl(connect(mapStateToProps)(GallaryPage));
