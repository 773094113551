import { Table } from 'antd';
import { setModal } from 'core/modals/duck';
import { fetchTracking, selectTracking, setTrackingPage } from 'core/storage/tracking';
import _ from 'lodash';
import React, { memo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { usePrevious } from 'utils';
import columns from './columns';

const TrackingTableComponent = memo(props => {
    const { tracking } = props;
    const prevTracking = usePrevious(tracking);

    useEffect(() => {
        if (!_.isEqual(tracking, prevTracking)) {
            props.fetchTracking();
        }
    }, [tracking]);

    const pagination = {
        pageSize: 25,
        size: 'small',
        total: Math.ceil(_.get(tracking, 'stats.count', 0) / 25) * 25,
        hideOnSinglePage: true,
        current: props.filters && props.filters.page,
        onChange: page => {
            props.setTrackingPage(page);
            props.fetchTracking();
        }
    };

    return props.rawData ? (
        <Table
            bordered
            columns={columns(props)}
            dataSource={props.dataSource}
            loading={!props.dataSource}
            locale={{
                emptyText: props.intl.formatMessage({ id: 'no_data' })
            }}
            pagination={props.pagination || pagination}
            rowKey={record => record.id}
            scroll={{ x: 800, y: '70vh' }}
            size='small'
        />
    ) : (
        <Table
            bordered
            columns={columns(props)}
            dataSource={props.tracking.list}
            loading={props.loading}
            locale={{
                emptyText: props.intl.formatMessage({ id: 'no_data' })
            }}
            pagination={props.pagination || pagination}
            rowKey={record => record.id}
            scroll={{ x: 800, y: '70vh' }}
            size='small'
        />
    );
});

const StyledTable = styled(Table)`
    background-color: rgb(255, 255, 255);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 10px;

    & th.income,
    & td.income {
        background: rgba(var(--secondaryRGB), 0.2) !important;
    }
    & th.expense,
    & td.expense {
        background: rgba(var(--warningRGB), 0.2) !important;
    }
`;

const mapStateToProps = state => ({
    user: state.auth,
    tracking: selectTracking(state)
});

const mapDispatchToProps = {
    fetchTracking,
    setModal,
    setTrackingPage
};

export const TrackingTable = injectIntl(connect(mapStateToProps, mapDispatchToProps)(TrackingTableComponent));
