import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Drawer, Image, Table } from 'antd';
import { Catcher, Numeral } from 'commons';
import { fetchRecordingLink, selectCallsLinksCache } from 'core/calls/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => {
    return {
        callsLinksCache: selectCallsLinksCache(state)
    };
};

const mapDispatchToProps = {
    fetchRecordingLink
};

const { Panel } = Collapse;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class OrderDocumentsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cashOrdersList: [],
            storeDocsList: [],
            helperDrawerOpen: false,
            allLinks: []
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='order_docs.doc_type' />,
                dataIndex: 'docType',
                key: 'docType',
                render: data => {
                    return data || 'CSH';
                }
            },

            {
                title: <FormattedMessage id='order_docs.number' />,
                key: 'documentNum',
                width: '15%',
                render: data => {
                    const storeDocId = _.get(data, 'id');
                    const documentNumber = _.get(data, 'documentNum');

                    const cashOrderId = _.get(data, 'cashDocumentNum');

                    return data.docType && isGrantAccessed(this.props.user, grants.WAREHOUSE_DOCUMENT) ? (
                        <div>
                            <a
                                href={`${book.storageDocument}/${storeDocId}`}
                                style={{
                                    color: 'var(--link)',
                                    fontWeight: 'bold'
                                }}
                            >
                                {documentNumber}
                            </a>
                        </div>
                    ) : data.docType ? (
                        documentNumber
                    ) : cashOrderId ? (
                        <div
                            onClick={() => this.redirectToCashFlow()}
                            style={{ color: 'var(--link)', fontWeight: 'bold', cursor: 'pointer' }}
                        >
                            {cashOrderId}
                        </div>
                    ) : null;
                }
            },

            {
                title: <FormattedMessage id='order_docs.date' />,
                dataIndex: 'docCreateData',
                key: 'docCreateData',
                render: date => dayjs(date).format('DD.MM.YYYY')
            },

            {
                title: <FormattedMessage id='order_docs.counterparty' />,
                key: 'counterpartName',
                width: '15%',
                render: data => {
                    const documentType = _.get(data, 'documentType');

                    const counterpartClientName = _.get(data, 'clientCtp');

                    const counterpartBusinessSupplierName = _.get(data, 'supplierCtp');

                    const counterpartEmployeeName = _.get(data, 'counterpartEmployeeName');

                    return counterpartClientName || counterpartBusinessSupplierName ? (
                        <Link
                            to={
                                counterpartBusinessSupplierName
                                    ? `${book.supplier}/${_.get(data, 'counterpartId')}`
                                    : counterpartClientName
                                    ? `${book.client}/${_.get(data, 'counterpartId')}`
                                    : `${book.employees}/${_.get(data, 'counterpartId')}`
                            }
                        >
                            {counterpartBusinessSupplierName ||
                                counterpartEmployeeName ||
                                `${counterpartClientName} ${_.get(this.props.selectedClient, 'phones[0]', []) || ''} ${
                                    _.get(this.props.selectedClient, 'vehicles[0].number', []) || undefined
                                }`}
                        </Link>
                    ) : null;
                }
            },

            {
                title: <FormattedMessage id='order_docs.product_info' />,
                key: 'productInfo',
                width: '15%',
                render: data => {
                    const productName = _.get(data, 'productName');
                    const brand = _.get(data, 'productBrandName');
                    const code = _.get(data, 'productCode');
                    const docType = _.get(data, 'docType');
                    const serviceName = _.get(data, 'serviceName');

                    if (!docType) return '';

                    return docType === 'ORD' ||
                        docType === 'INC' ||
                        docType === 'COM' ||
                        docType === 'AUT' ||
                        docType === 'CRA' ||
                        docType === 'KPP' ||
                        docType === 'KPM' ? (
                        <div>{`${code} ${productName} ${brand}`}</div>
                    ) : docType === 'KPM' ? (
                        <div>{`${code}, ${brand}`}</div>
                    ) : docType === 'SRV' || docType === 'VRT' ? (
                        <div>{`${serviceName}`}</div>
                    ) : undefined;
                }
            },

            {
                title: <FormattedMessage id='order_docs.status' />,
                dataIndex: 'docStatus',
                key: 'docStatus',
                render: data => {
                    return data === 'DONE' ? (
                        <FormattedMessage id='order_docs.status_done' />
                    ) : data === 'NEW' ? (
                        <FormattedMessage id='order_docs.status_new' />
                    ) : undefined;
                }
            },

            {
                title: <FormattedMessage id='order_docs.purcahse_price' />,
                key: 'productPurchasePrice',
                render: data => {
                    const productPurchasePrice = _.get(data, 'productPurchasePrice');
                    const servicePrice = _.get(data, 'servicePrice');
                    const docType = _.get(data, 'docType');

                    return (
                        <div>
                            {docType === 'ORD' ||
                            docType === 'INC' ||
                            docType === 'COM' ||
                            docType === 'AUT' ||
                            docType === 'CRA' ||
                            docType === 'KPP' ||
                            docType === 'KPM' ? (
                                <Numeral mask='0,0.00'>{Number(productPurchasePrice)}</Numeral>
                            ) : docType === 'SRV' || docType === 'VRT' ? (
                                <Numeral mask='0,0.00'>{Number(servicePrice)}</Numeral>
                            ) : undefined}
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_docs.sellings_price' />,
                key: 'sellingPrice',
                render: data => {
                    const productSellingPrice = _.get(data, 'productSellingPrice');
                    const servicePrice = _.get(data, 'servicePrice');
                    const docType = _.get(data, 'docType');

                    return (
                        <div>
                            {docType === 'ORD' ||
                            docType === 'INC' ||
                            docType === 'COM' ||
                            docType === 'AUT' ||
                            docType === 'CRA' ||
                            docType === 'KPP' ||
                            docType === 'KPM' ? (
                                <Numeral mask='0,0.00'>{Number(productSellingPrice)}</Numeral>
                            ) : docType === 'SRV' || docType === 'VRT' ? (
                                <Numeral mask='0,0.00'>{Number(servicePrice)}</Numeral>
                            ) : undefined}
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_docs.quantity' />,
                key: 'quantity',
                render: data => {
                    const productQuantity = _.get(data, 'productQuantity');
                    const serviceCount = _.get(data, 'serviceCount');
                    const docType = _.get(data, 'docType');

                    return (
                        <div>
                            {docType === 'ORD' ||
                            docType === 'INC' ||
                            docType === 'COM' ||
                            docType === 'AUT' ||
                            docType === 'CRA' ||
                            docType === 'KPP' ||
                            docType === 'KPM' ? (
                                <Numeral mask='0,0.00'>{Number(productQuantity)}</Numeral>
                            ) : docType === 'SRV' || docType === 'VRT' ? (
                                <Numeral mask='0,0.00'>{Number(serviceCount)}</Numeral>
                            ) : undefined}
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_docs.purcahse_sum' />,
                key: 'purhaceSum',
                render: data => {
                    const productPurchaseSum = _.get(data, 'productPurchaseSum');

                    return (
                        <div>
                            {productPurchaseSum ? (
                                <Numeral mask='0,0.00'>{Number(productPurchaseSum)}</Numeral>
                            ) : undefined}
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_docs.sellings_sum' />,
                key: 'sellingSum',
                render: data => {
                    const sellingSum = _.get(data, 'docSellingSum');

                    return <div>{sellingSum ? <Numeral mask='0,0.00'>{Number(sellingSum)}</Numeral> : undefined}</div>;
                }
            }
        ];
    }

    componentDidMount() {
        this.fetchDocument();
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_documents' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    fetchDocument = async () => {
        const { clientId, orderId } = this.props;
        const { cashOrders, storeDocs } = await fetchAPI(
            'GET',
            'store_docs_cash_orders',
            { clientId, orderId },
            undefined
        );

        cashOrders.list.forEach(el => {
            el.cashDocumentNum = el.id;
            el.docCreateData = el.createdAt;
            el.clientCtp = el.clientName;
        });

        storeDocs.list.forEach(el => {
            el.docType = el.operationCode;
            el.documentNum = el.documentNumber;
            el.docCreateData = el.createdDatetime;
            el.clientCtp = el.counterpartClientName;
            el.supplierCtp = el.counterpartBusinessSupplierName;
            el.docStatus = el.status;
            el.docSellingSum = el.sellingSumTax;
            el.productPurchaseSum = el.sumTax;
            el.productQuantity = el.docProducts.reduce((acc, product) => acc + Number(product.quantity), 0);
            el.productPurchasePrice = el.docProducts.reduce((acc, product) => acc + Number(product.stockPrice), 0);
            el.productSellingPrice = el.docProducts.reduce((acc, product) => acc + Number(product.sellingPrice), 0);
            el.productName = el.docProducts.map(product => product.product.name);
            el.productBrandName = el.docProducts.map(product => product.product.brand.name);
            el.productCode = el.docProducts.map(product => product.product.code);
            el.serviceName = el.docServices.map(service => service.name);
            el.servicePrice = el.docServices.reduce((acc, service) => acc + Number(service.price), 0);
            el.serviceCount = el.docServices.reduce((acc, service) => acc + Number(service.count), 0);
        });
        this.setState({
            cashOrdersList: cashOrders.list,
            storeDocsList: storeDocs.list
        });
    };

    redirectToCashFlow = () => {
        if (isGrantAccessed(this.props.user, grants.ACCOUNTING_SETTLEMENT_JOURNAL)) {
            goTo(book.storageCalculations, {
                documentNumber: this.props.orderId
            });
        }
    };

    render() {
        const {
            intl: { formatMessage }
        } = this.props;
        const { cashOrdersList, storeDocsList, helperDrawerOpen, allLinks } = this.state;

        const cashOrdersLength = cashOrdersList.length;
        const ORDlength = storeDocsList.filter(
            ({ operationCode }) => operationCode === 'ORD' || operationCode === 'COM'
        ).length;
        const INClength = storeDocsList.filter(
            ({ operationCode }) => operationCode === 'INC' || operationCode === 'AUT' || operationCode === 'CRA'
        ).length;
        const SRVlength = storeDocsList.filter(
            ({ operationCode }) => operationCode === 'SRV' || operationCode === 'VRT'
        ).length;
        const WAGlength = storeDocsList.filter(({ operationCode }) => operationCode === 'WAG').length;
        const KPPlength = storeDocsList.filter(
            ({ operationCode }) => operationCode === 'KPP' || operationCode === 'KPM'
        ).length;

        return (
            <Catcher>
                <div
                    style={{
                        marginLeft: 8,
                        marginBottom: 8,
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            this.setState({
                                helperDrawerOpen: true
                            });
                            await this.fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22
                        }}
                        type='text'
                    />
                </div>
                <Collapse>
                    <Panel
                        key={1}
                        header={
                            <div>
                                {`${formatMessage({
                                    id: 'order_docs.payments_section'
                                })} (${cashOrdersLength})`}
                            </div>
                        }
                    >
                        <Table
                            bordered
                            className={Styles.callsTable}
                            columns={this.columns()}
                            dataSource={[...cashOrdersList]}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={false}
                            size='small'
                        />
                    </Panel>
                    <Panel
                        key={2}
                        header={
                            <div>
                                {`${formatMessage({
                                    id: 'order_docs.orders_section'
                                })} (${ORDlength})`}
                            </div>
                        }
                    >
                        <Table
                            bordered
                            className={Styles.callsTable}
                            columns={this.columns()}
                            dataSource={[
                                ...storeDocsList.filter(
                                    ({ operationCode }) => operationCode === 'ORD' || operationCode === 'COM'
                                )
                            ]}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={false}
                            size='small'
                        />
                    </Panel>
                    <Panel
                        key={3}
                        header={
                            <div>
                                {`${formatMessage({
                                    id: 'order_docs.store_docs_section'
                                })} (${INClength})`}
                            </div>
                        }
                    >
                        <Table
                            bordered
                            className={Styles.callsTable}
                            columns={this.columns()}
                            dataSource={[
                                ...storeDocsList.filter(
                                    ({ operationCode }) =>
                                        operationCode === 'INC' || operationCode === 'AUT' || operationCode === 'CRA'
                                )
                            ]}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={false}
                            size='small'
                        />
                    </Panel>
                    <Panel
                        key={4}
                        header={
                            <div>
                                {`${formatMessage({
                                    id: 'order_docs.services_section'
                                })} (${SRVlength})`}
                            </div>
                        }
                    >
                        <Table
                            bordered
                            className={Styles.callsTable}
                            columns={this.columns()}
                            dataSource={[
                                ...storeDocsList.filter(
                                    ({ operationCode }) => operationCode === 'SRV' || operationCode === 'VRT'
                                )
                            ]}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={false}
                            size='small'
                        />
                    </Panel>
                    <Panel
                        key={5}
                        header={
                            <div>
                                {`${formatMessage({
                                    id: 'order_docs.salaries_section'
                                })} (${WAGlength})`}
                            </div>
                        }
                    >
                        <Table
                            bordered
                            className={Styles.callsTable}
                            columns={this.columns()}
                            dataSource={[...storeDocsList.filter(({ operationCode }) => operationCode === 'WAG')]}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={false}
                            size='small'
                        />
                    </Panel>
                    <Panel
                        key={6}
                        header={
                            <div>
                                {`${formatMessage({
                                    id: 'order_docs.VAT_section'
                                })} (${WAGlength})`}
                            </div>
                        }
                    >
                        <Table
                            bordered
                            className={Styles.callsTable}
                            columns={this.columns()}
                            dataSource={[...storeDocsList.filter(({ operationCode }) => operationCode === 'VAT')]}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={false}
                            size='small'
                        />
                    </Panel>
                    <Panel
                        key={7}
                        header={
                            <div>
                                {`${formatMessage({
                                    id: 'order_docs.entry_section'
                                })} (${WAGlength})`}
                            </div>
                        }
                    >
                        <Table
                            bordered
                            className={Styles.callsTable}
                            columns={this.columns()}
                            dataSource={[...storeDocsList.filter(({ operationCode }) => operationCode === 'ACC')]}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={false}
                            size='small'
                        />
                    </Panel>
                    <Panel
                        key={8}
                        header={
                            <div>
                                {`${formatMessage({
                                    id: 'order_docs.other_section'
                                })} (${KPPlength})`}
                            </div>
                        }
                    >
                        <Table
                            bordered
                            className={Styles.callsTable}
                            columns={this.columns()}
                            dataSource={[
                                ...storeDocsList.filter(
                                    ({ operationCode }) => operationCode === 'KPP' || operationCode === 'KPM'
                                )
                            ]}
                            locale={{
                                emptyText: <FormattedMessage id='no_data' />
                            }}
                            pagination={false}
                            size='small'
                        />
                    </Panel>
                </Collapse>
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}

export default OrderDocumentsTable;
