import { Checkbox, InputNumber, Table } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const inputable = [6, 10, 17]; // ids of childs that have input

const OrderCheckTab = ({ intl, disabled }) => {
    const [orderChecks, setOrderChecks] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchOrderChecks = async () => {
        setLoading(true);
        const response = await fetchAPI('GET', 'order_checks', null, null, { handleErrorInternally: true });
        setOrderChecks(response);
        setLoading(false);
    };

    const handleFieldChange = async (record, propName, newValue) => {
        const newObj = _.pick(record, ['id', 'name', 'enable', 'block', 'value']);
        const updateBody = {
            ...newObj,
            [propName]: newValue
        };
        if (updateBody.block && propName !== 'enable') updateBody.enable = true;
        if (!updateBody.enable) updateBody.block = false;
        setLoading(true);
        await fetchAPI('POST', 'order_checks', null, updateBody, { handleErrorInternally: true });
        await fetchOrderChecks();
    };

    const expandedRowRender = parentRecord => {
        const columns = [
            {
                dataIndex: 'name',
                key: 'name'
            },
            {
                dataIndex: 'enable',
                key: 'enable',
                width: '140px',
                align: 'center',
                render: (text, record) => (
                    <Checkbox
                        checked={record.enable}
                        disabled={record.id === 6 || disabled}
                        onChange={e => handleFieldChange(record, 'enable', e.target.checked)}
                    />
                )
            },
            {
                dataIndex: 'block',
                key: 'block',
                width: '140px',
                align: 'center',
                render: (text, record) =>
                    record.id !== 16 ? (
                        <Checkbox
                            checked={record.block}
                            disabled={record.id === 6 || disabled}
                            onChange={e => handleFieldChange(record, 'block', e.target.checked)}
                        />
                    ) : null
            },
            {
                dataIndex: 'value',
                key: 'value',
                width: '200px',
                render: (text, record) =>
                    inputable.includes(record.id) && (
                        <InputNumber
                            addonAfter='%'
                            className={Styles.detailNumberInput}
                            defaultValue={record.value}
                            disabled={record.id === 6 || disabled}
                            onBlur={e => {
                                const newValue = Number(e.target.value);
                                newValue !== record.value && handleFieldChange(record, 'value', newValue);
                            }}
                            style={{ textAlign: 'right' }}
                            type='number'
                        />
                    )
            }
        ];

        return (
            <Table
                className={Styles.innerTable}
                columns={columns}
                dataSource={parentRecord.childs}
                pagination={false}
                rowKey={record => record.name}
                showHeader={false}
            />
        );
    };

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            className: Styles.bold
        },
        {
            title: <FormattedMessage id='to_check' />,
            dataIndex: 'check',
            key: 'check',
            align: 'center',
            width: '140px'
        },
        {
            title: <FormattedMessage id='repair_map_setting.block' />,
            dataIndex: 'block',
            key: 'block',
            align: 'center',
            width: '140px'
        },
        {
            title: <FormattedMessage id='info_modal.value' />,
            dataIndex: 'value',
            key: 'value',
            align: 'center',
            width: '200px'
        }
    ];

    useEffect(() => {
        fetchOrderChecks();
    }, []);

    return orderChecks.length ? (
        <Table
            columns={columns}
            dataSource={orderChecks}
            expandable={{ expandedRowRender, defaultExpandAllRows: true, expandIcon: () => null }}
            loading={loading}
            rowKey={record => record.id}
        />
    ) : null;
};

export default injectIntl(OrderCheckTab);
