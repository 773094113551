import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import { Input } from 'antd';
import React from 'react';

const FormItem = Form.Item;
const { Search } = Input;

export class DecoratedSearch extends React.PureComponent {
    render() {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            ref,
            onSearch,
            onPressEnter,
            enterButton,
            validateStatus,

            cnStyles,
            getFieldDecorator,
            disabled,
            rules,
            type,
            placeholder,
            icon,
            iconType,
            field,
            initialValue,
            style,
            onChange
        } = this.props;

        const search = getFieldDecorator(field, {
            ...(initialValue ? { initialValue } : { initialValue: void 0 }),
            rules
        })(
            <Search
                ref={ref}
                className={cnStyles || className}
                disabled={disabled}
                enterButton={enterButton}
                onChange={onChange}
                onPressEnter={onPressEnter}
                onSearch={onSearch}
                placeholder={placeholder}
                prefix={
                    icon ? (
                        <LegacyIcon
                            style={{
                                color: 'rgba(0,0,0,.25)'
                            }}
                            type={iconType}
                        />
                    ) : null
                }
                style={style}
                type={type}
            />
        );

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                label={label}
                validateStatus={validateStatus}
                {...formItemLayout}
            >
                {search}
            </FormItem>
        ) : (
            search
        );
    }
}
