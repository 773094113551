import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { MODALS } from 'core/modals/duck';
import React from 'react';
import { connect } from 'react-redux';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const mapStateToProps = state => ({
    user: state.auth
});

export const StoreProductsSetting = connect(mapStateToProps)(({ setModal, storeGroup, user }) => {
    const renderEditing = () => {
        return (
            <div style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center'
                }}
            >
                <EditOutlined
                    onClick={() =>
                        setModal(MODALS.STORE_GROUP, {
                            edit: true,
                            storeGroup
                        })
                    }
                    style={{
                        fontSize: '16px',
                        color: 'var(--secondary)',
                        margin: '0 4px'
                    }}
                />
                {isGrantAccessed(user, grants.SETTINGS_PRODUCT_GROUPS, accesses.ROWO) ? (
                    <DeleteOutlined
                        onClick={() =>
                            setModal(MODALS.STORE_GROUP, {
                                delete: true,
                                storeGroup
                            })
                        }
                        style={{
                            fontSize: '16px',
                            color: 'var(--warning)',
                            margin: '0 4px'
                        }}
                    />
                ) : null}
            </div>
        );
    };

    return (
        <React.Fragment>
            {/* <Icon
                    type={ 'plus' }
                    onClick={ () =>
                        setModal(MODALS.STORE_GROUP, {
                            create: true,
                            storeGroup,
                        })
                    }
                    style={ {
                        fontSize: '16px',
                        color:    'var(--primary)',
                    } }
                /> */}
            {isGrantAccessed(user, grants.SETTINGS_PRODUCT_GROUPS, accesses.ROWO)
                ? renderEditing()
                : !storeGroup.systemWide
                ? renderEditing()
                : null}
        </React.Fragment>
    );
});
