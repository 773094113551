/**
 * Object whick contains constants of an order status value
 */
export const statuses = Object.freeze({
    required: 'REQUIRED',
    reserve: 'RESERVE',
    not_complete: 'NOT_COMPLETE',
    approve: 'APPROVE',
    progress: 'PROGRESS',
    success: 'SUCCESS',
    cancel: 'CANCEL',
    call: 'CALL',
    invite: 'INVITE',
    redundant: 'REDUNDANT',
    processing: 'PROCESSING',
    stop: 'STOP'
});
