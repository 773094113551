import { Table } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import book from 'routes/book';
import { getCurrency } from 'utils';

const OrderPanel = ({ orders, intl: { formatMessage } }) => {
    const cashColumns = useMemo(
        () => [
            {
                title: formatMessage({ id: 'order_docs.number' }),
                key: 'num',
                render: row => (
                    <div>
                        <a
                            href={`${book.order}/${row.id}`}
                            style={{
                                color: 'var(--link)',
                                fontWeight: 'bold'
                            }}
                        >
                            {row.document}
                        </a>
                    </div>
                )
            },
            {
                title: formatMessage({ id: 'date' }),
                key: 'dateTime',
                dataIndex: 'documentDate',
                render: date => dayjs(date).format('DD.MM.YYYY')
            },
            {
                title: formatMessage({ id: 'sum' }),
                key: 'code',
                render: row => (
                    <div>
                        {row.sum && Number(row.sum).toFixed(2)} {getCurrency()}{' '}
                    </div>
                )
            }
        ],
        [formatMessage]
    );

    return <Table bordered columns={cashColumns} dataSource={orders} rowKey='id' size='small' />;
};

export default injectIntl(OrderPanel);
