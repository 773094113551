import { Input } from 'antd';
import {
    selectCommentSecond,
    selectMode,
    setCommentSecond
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { TextArea } = Input;

const mapStateToProps = state => ({
    comment: selectCommentSecond(state),
    mode: selectMode(state),
    user: state.auth
});

const mapDispatchToProps = {
    setCommentSecond
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CommentInputSecond extends React.Component {
    render() {
        const {
            comment,
            setCommentSecond,
            mode,
            user,

            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <TextArea
                    data-qa='input_order_comments'
                    onChange={e => setCommentSecond(e.target.value)}
                    placeholder={formatMessage({
                        id: 'refactored_cash_order_modal.placeholder_comment'
                    })}
                    rows={3}
                    value={comment}
                />
            </div>
        );
    }
}
