import { Modal, Select, Tabs } from 'antd';
import { Loader, Spinner } from 'commons';
import { ArrowsDatePicker, ArrowsWeekPicker } from 'components';
import { DashboardContainer } from 'containers';
import {
    dropDashboardOrder,
    initDashboard,
    linkToDashboardEmployees,
    linkToDashboardStations,
    selectDasboardData,
    setDashboardBusinessId,
    setDashboardEmployeesDate,
    setDashboardMode,
    setDashboardStationsDate,
    setDashboardWeekDates,
    transferOutdateRepairs,
    updateDashboardOrder
} from 'core/dashboard/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { ConfirmRescheduleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { TabPane } = Tabs;
const { Option } = Select;

const mapStateToProps = state => ({
    orders: state.dashboard.orders,
    mode: state.dashboard.mode,
    stations: state.dashboard.stations,
    employees: state.dashboard.employees,
    date: state.dashboard.date,
    businessId: state.dashboard.businessId,
    startDate: state.dashboard.startDate,
    endDate: state.dashboard.endDate,
    schedule: state.dashboard.schedule,
    days: state.dashboard.days,
    load: state.dashboard.load,
    daysWithConflicts: state.dashboard.daysWithConflicts,
    stationsWithConflicts: state.dashboard.stationsWithConflicts,
    employeesWithConflicts: state.dashboard.employeesWithConflicts,
    modal: state.modals.modal,

    spinner: state.ui.dashboardInitializing,
    loading: state.ui.dashboardFetching,
    user: state.auth,

    ...selectDasboardData(state)
});

const mapDispatchToProps = {
    initDashboard,
    dropDashboardOrder,
    setDashboardStationsDate,
    setDashboardEmployeesDate,
    setDashboardWeekDates,
    setDashboardMode,
    setDashboardBusinessId,
    linkToDashboardStations,
    linkToDashboardEmployees,
    updateDashboardOrder,
    transferOutdateRepairs,
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
export default class DashboardModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            businesses: []
        };
    }

    componentDidMount() {
        const { initDashboard } = this.props;
        const fetchBusinesses = async () => {
            const result = await fetchAPI('GET', 'managers/businesses/get', null, null, {
                handleErrorInternally: true
            });
            this.setState({
                businesses: result
            });
        };
        fetchBusinesses();
        // initDashboard();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.visible !== MODALS.DASHBOARD && this.props.visible === MODALS.DASHBOARD) {
            const { orderDatetime, initDashboard } = this.props;
            await initDashboard();
            if (orderDatetime) {
                await this.props.setDashboardStationsDate(orderDatetime);
            }
            await this._setDashboardMode('stations');
        }
    }

    _onDayChange = date => {
        if (this.props.mode === 'employees') {
            this.props.setDashboardEmployeesDate(date);
        } else {
            this.props.setDashboardStationsDate(date);
        }
    };

    _prevDay = () => {
        const { setDashboardStationsDate, setDashboardEmployeesDate, date } = this.props;
        if (this.props.mode === 'employees') {
            setDashboardEmployeesDate(date.subtract(1, 'day'));
        } else {
            setDashboardStationsDate(date.subtract(1, 'day'));
        }
        this.setState({});
    };

    _nextDay = () => {
        const { setDashboardStationsDate, setDashboardEmployeesDate, date } = this.props;
        if (this.props.mode === 'employees') {
            setDashboardEmployeesDate(date.add(1, 'day'));
        } else {
            setDashboardStationsDate(date.add(1, 'day'));
        }
        this.setState({});
    };

    _onWeekChange = date =>
        this.props.setDashboardWeekDates({
            startDate: dayjs(date).startOf('week').isoWeekday(1),
            endDate: dayjs(date).endOf('week').isoWeekday(7)
        });

    _prevWeek = () => {
        const { setDashboardWeekDates, startDate, endDate } = this.props;
        setDashboardWeekDates({
            startDate: startDate.subtract(1, 'weeks'),
            endDate: endDate.subtract(1, 'weeks')
        });
    };

    _nextWeek = () => {
        const { setDashboardWeekDates, startDate, endDate } = this.props;

        setDashboardWeekDates({
            startDate: startDate.add(1, 'weeks'),
            endDate: endDate.add(1, 'weeks')
        });
    };

    _setDashboardMode = mode => this.props.setDashboardMode(mode);

    _transferOutdateRepairs = () => this.props.transferOutdateRepairs();

    _handleBusinessChange = async value => {
        this.props.setDashboardBusinessId(value);
        const { date } = this.props;
        await this.props.setDashboardStationsDate(date);
    };

    _renderDashboardContainer = () => {
        const {
            loading,
            orders,
            mode,
            load,
            days,
            stations,
            employees,
            schedule,
            time,
            dashboard,
            linkToDashboardStations,
            linkToDashboardEmployees,
            updateDashboardOrder,
            date,
            user,
            daysWithConflicts,
            stationsWithConflicts,
            employeesWithConflicts,
            onSelect,
            selectedOrderId
        } = this.props;

        return loading ? (
            <Loader loading={loading} />
        ) : (
            <React.Fragment>
                <DashboardContainer
                    dashboard={dashboard}
                    date={date}
                    days={days}
                    daysWithConflicts={daysWithConflicts}
                    employees={employees}
                    employeesWithConflicts={employeesWithConflicts}
                    linkToDashboardEmployees={linkToDashboardEmployees}
                    linkToDashboardStations={linkToDashboardStations}
                    load={load}
                    mode={mode}
                    onStationSelect={onSelect}
                    orders={orders}
                    schedule={schedule}
                    selectedOrderId={selectedOrderId}
                    stations={stations}
                    stationsWithConflicts={stationsWithConflicts}
                    time={time}
                    updateDashboardOrder={updateDashboardOrder}
                    user={user}
                />
                <ConfirmRescheduleModal
                    // wrappedComponentRef={ this.saveFormRef }
                    confirm={this._transferOutdateRepairs}
                    reset={this.props.resetModal}
                    visible={this.props.modal}
                />
            </React.Fragment>
        );
    };

    render() {
        const isMobile = window.innerWidth < 1024;

        const { startDate, endDate, date, mode, spinner, loading, user, visible, resetModal, businessId } = this.props;

        const { businesses } = this.state;

        const dashboardContainer = this._renderDashboardContainer();
        const rescheduleOrdersAllowed = !isForbidden(user, permissions.RESCHEDULE_ORDERS);

        return (
            <Modal
                footer={null}
                maskClosable={false}
                onCancel={() => resetModal()}
                title={
                    <div className={Styles.titleWrap}>
                        <FormattedMessage id='navigation.planner' />
                        <Select
                            className={Styles.businessSelect}
                            onChange={this._handleBusinessChange}
                            value={businessId}
                        >
                            {(businesses || []).map(({ businessName, businessId }) => (
                                <Option key={businessId} value={businessId}>
                                    {businessName}
                                </Option>
                            ))}
                        </Select>
                    </div>
                }
                visible={visible === MODALS.DASHBOARD}
                width={1024}
            >
                {spinner ? (
                    <Spinner spin={spinner} />
                ) : (
                    <section className={Styles.dashboardPage}>
                        <Tabs
                            activeKey={mode}
                            className={isMobile ? Styles.dashboard_mobile_tabs : null}
                            onChange={mode => {
                                this._setDashboardMode(mode);
                            }}
                            tabBarExtraContent={
                                mode === 'calendar' ? (
                                    <ArrowsWeekPicker
                                        endDate={endDate}
                                        loading={loading}
                                        nextWeek={this._nextWeek}
                                        onWeekChange={this._onWeekChange}
                                        prevWeek={this._prevWeek}
                                        startDate={startDate}
                                    />
                                ) : (
                                    <ArrowsDatePicker
                                        date={date}
                                        endDate={endDate}
                                        loading={loading}
                                        nextDay={this._nextDay}
                                        onDayChange={this._onDayChange}
                                        onWeekChange={this._onWeekChange}
                                        prevDay={this._prevDay}
                                        startDate={startDate}
                                    />
                                )
                            }
                        >
                            <TabPane
                                key='calendar'
                                disabled={loading || isForbidden(user, permissions.ACCESS_DASHBOARD_POSTS)}
                                tab={<FormattedMessage id='dashboard-page.calendar' />}
                            >
                                {dashboardContainer}
                            </TabPane>
                            <TabPane
                                key='stations'
                                disabled={loading || isForbidden(user, permissions.ACCESS_DASHBOARD_MECHANICS)}
                                tab={<FormattedMessage id='dashboard-page.stations_load' />}
                            >
                                {dashboardContainer}
                            </TabPane>
                            <TabPane
                                key='employees'
                                disabled={loading}
                                tab={<FormattedMessage id='dashboard-page.employees' />}
                            >
                                {dashboardContainer}
                            </TabPane>
                        </Tabs>
                    </section>
                )}
            </Modal>
        );
    }
}
