import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import CashPanel from './CashPanel';
import OrderPanel from './OrderPanel';

const { Panel } = Collapse;

const DocumentsTab = ({ id, activeKey, intl: { formatMessage } }) => {
    const [documents, setDocuments] = useState({});

    useEffect(() => {
        const fetchCash = async () => {
            const response = await fetchAPI('GET', '/salary/related/docs', { docId: id }, null, {
                handleErrorInternally: true
            });

            setDocuments(response[0]);
        };

        if (activeKey === 'docs') fetchCash();
    }, [id, activeKey]);

    return (
        <Collapse>
            <Panel
                key={1}
                header={
                    <div>
                        {`${formatMessage({
                            id: 'order_shr'
                        })} (${documents.ordersCount})`}
                    </div>
                }
            >
                <OrderPanel orders={documents.orders} />
            </Panel>

            <Panel
                key={2}
                header={
                    <div>
                        {`${formatMessage({
                            id: 'order_docs.payments_section'
                        })} (${documents.cashOrdersCount})`}
                    </div>
                }
            >
                <CashPanel cashOrders={documents.cashOrders} documentId={id} />
            </Panel>
        </Collapse>
    );
};

export default injectIntl(DocumentsTab);
