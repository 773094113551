import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

@injectIntl
class UserAgreementPage extends Component {
    render() {
        const { intl } = this.props;
        const userAgreementText = intl.formatMessage({ id: 'user_agreement_text' });

        return (
            <Layout title={<FormattedMessage id='user_agreement' />}>
                <div className={Styles.license} dangerouslySetInnerHTML={{ __html: userAgreementText }}></div>
            </Layout>
        );
    }
}

export default UserAgreementPage;
