exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__deletePackageIcon---KB2bu,.styles-m__editPackageIcon---3fUhi,.styles-m__viewPackageIcon---3Ty3u{cursor:pointer;font-size:25px}.styles-m__addPackageButton---1VkZ4{width:30%;margin:10px auto}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAUA,2GACI,cAAe,CACf,cACJ,CAEA,oCACI,SAAU,CACV,gBACJ","file":"styles.m.css","sourcesContent":[".editPackageIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.deletePackageIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.viewPackageIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.addPackageButton {\n    width: 30%;\n    margin: 10px auto;\n}\n"]}]);

// Exports
exports.locals = {
	"editPackageIcon": "styles-m__editPackageIcon---3fUhi",
	"deletePackageIcon": "styles-m__deletePackageIcon---KB2bu",
	"viewPackageIcon": "styles-m__viewPackageIcon---3Ty3u",
	"addPackageButton": "styles-m__addPackageButton---1VkZ4"
};