export const paymentTypes = Object.freeze({
    PORTMONE: 'PORTMONE',
    CASHLESS: 'CASHLESS',
    TERMINAL: 'TERMINAL',
    LTD_WITHOUT_VAT: 'LTD_WITHOUT_VAT',
    LTD_WITH_VAT: 'LTD_WITH_VAT',
    INDIVIDUAL_ENTREPRENEUR: 'INDIVIDUAL_ENTREPRENEUR'
});

export const paymentRates = Object.freeze({
    6: 10,
    9: 15,
    12: 20
});
