exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__error---3nkRW{display:flex;justify-content:center;margin-top:100px}.styles-m__errorData---1thj_{display:flex;flex-direction:column;gap:8px;justify-content:center;margin-left:20px;padding-top:10px}.styles-m__errorCode---3cJRH{color:var(--text3);font-size:72px;font-weight:600;line-height:72px}.styles-m__errorDesc---2O4_j{color:var(--text2);text-transform:uppercase}.styles-m__homeButtonWrapper---3ELyS{display:flex;justify-content:center;margin-top:20px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,sBAAuB,CACvB,gBACJ,CAEA,6BACI,YAAa,CACb,qBAAsB,CACtB,OAAQ,CACR,sBAAuB,CACvB,gBAAiB,CACjB,gBACJ,CAEA,6BACI,kBAAmB,CACnB,cAAe,CACf,eAAgB,CAChB,gBACJ,CAEA,6BACI,kBAAmB,CACnB,wBACJ,CAEA,qCACI,YAAa,CACb,sBAAuB,CACvB,eACJ","file":"styles.m.css","sourcesContent":[".error {\n    display: flex;\n    justify-content: center;\n    margin-top: 100px;\n}\n\n.errorData {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    justify-content: center;\n    margin-left: 20px;\n    padding-top: 10px;\n}\n\n.errorCode {\n    color: var(--text3);\n    font-size: 72px;\n    font-weight: 600;\n    line-height: 72px;\n}\n\n.errorDesc {\n    color: var(--text2);\n    text-transform: uppercase;\n}\n\n.homeButtonWrapper {\n    display: flex;\n    justify-content: center;\n    margin-top: 20px;\n}"]}]);

// Exports
exports.locals = {
	"error": "styles-m__error---3nkRW",
	"errorData": "styles-m__errorData---1thj_",
	"errorCode": "styles-m__errorCode---3cJRH",
	"errorDesc": "styles-m__errorDesc---2O4_j",
	"homeButtonWrapper": "styles-m__homeButtonWrapper---3ELyS"
};