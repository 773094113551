import { Button } from 'antd';
import { Numeral } from 'commons';
import { GridCard } from 'components/Grid/GridCard';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getCurrency } from 'utils';

export const SubscriptionProduct = props => {
    const {
        name,
        price,
        description,
        showDetails
        // rolesPackageId,
        // suggestionGroupId,
    } = props;

    const createMarkup = () => ({ __html: description });

    // eslint-disable eslint(react/no-danger)
    return (
        <GridCard>
            <ProductTitleWrapper>
                <ProductTitle>{name}</ProductTitle>
            </ProductTitleWrapper>
            <ProductDescription dangerouslySetInnerHTML={createMarkup()} />
            <ProductFooter>
                <ShowMore onClick={() => showDetails()}>
                    <FormattedMessage id='subscription.details' />
                </ShowMore>
                <ProductPrice>
                    <Numeral currency={getCurrency()}>{price}</Numeral> /{' '}
                    <FormattedMessage id='subscription.monthly' />
                </ProductPrice>
                <PurchaseButton onClick={props.setModal} size='large' type='primary'>
                    <FormattedMessage id='subscription.purchase' />
                </PurchaseButton>
            </ProductFooter>
        </GridCard>
    );
};

const ProductTitleWrapper = styled.div`
    background: var(--primary);
    padding: 8px 4px;
    margin: 8px 3%;
    display: flex;
    justify-content: center;
`;

const ProductTitle = styled.h2`
    color: white;
    font-size: 18px;
    font-weight: bold;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

const ProductDescription = styled.div`
    flex: 1 1 100%;
    max-height: 380px;
    overflow-y: scroll;
    margin: 4px 0;
    padding: 0 3%;
    font-size: 16px;

    h2,
    h3 {
        font-size: 18px;
        font-weight: bold;
    }

    ol,
    ul {
        margin: 4px 32px;
        text-align: left;
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }
`;

const ShowMore = styled.div`
    color: var(--link);
    text-decoration: dashed;
    cursor: pointer;

    &:hover {
        color: var(--primary);
        text-decoration: underline;
    }
`;

const ProductPrice = styled.div`
    font-size: 18px;
    font-weight: 500;
    background: var(--static);
    margin: 8px 3% 0;
    padding: 8px;
`;

const PurchaseButton = styled(Button)`
    width: 94%;
    margin: 8px 3%;
`;

const ProductFooter = styled.div`
    justify-self: flex-end;
`;
