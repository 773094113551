import { Table } from 'antd';
import {
    fetchBusinessSuppliers,
    selectBusinessSuppliers,
    selectBusinessSuppliersFilters,
    selectBusinessSuppliersStats,
    selectFetchingBusinessSuppliers,
    setBusinessSuppliersFilters,
    setStoreDocsFilters
} from 'pages/SuppliersPage/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    businessSuppliers: selectBusinessSuppliers(state),
    businessSuppliersStats: selectBusinessSuppliersStats(state),
    businessSuppliersFilters: selectBusinessSuppliersFilters(state),
    fetchingBusinessSuppliers: selectFetchingBusinessSuppliers(state),
    user: state.auth
});

const mapDispatchToProps = {
    fetchBusinessSuppliers,
    setBusinessSuppliersFilters,
    setStoreDocsFilters
};

/**
 * New suppliers page. Contains basic supplier actions
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SuppliersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedSupplierRow: ''
        };
    }

    render() {
        const {
            businessSuppliers,
            fetchingBusinessSuppliers,

            businessSuppliersStats,
            businessSuppliersFilters,
            setBusinessSuppliersFilters,
            setStoreDocsFilters,
            user,
            intl: { formatMessage },
            fetchBusinessSuppliers
        } = this.props;

        const columns = columnsConfig({
            user,
            formatMessage,
            fetchBusinessSuppliers
        });

        const pagination = {
            pageSizeOptions: [10, 25, 50],
            pageSize: 25,
            size: 'large',
            total: Math.ceil(businessSuppliersStats.totalRowsCount / 25) * 25,
            current: businessSuppliersFilters.page,
            onChange: page => {
                setBusinessSuppliersFilters({ page });
            }
        };

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columns}
                    dataSource={businessSuppliers}
                    loading={fetchingBusinessSuppliers}
                    pagination={pagination}
                    rowKey={supplier => `${supplier.businessSupplierId}`}
                    scroll={{ y: '60vw' }}
                    size='small' // Only one row can be expanded at the time
                />
            </div>
        );
    }
}
