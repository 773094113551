import { emitError, setDashboardFetchingState, setDashboardInitializingState } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI, getDefaultDashbordMode } from 'utils';
import {
    INIT_DASHBOARD,
    LINK_TO_DASHBOARD_EMPLOYEES,
    LINK_TO_DASHBOARD_STATIONS,
    REFRESH_DASHBOARD,
    SET_DASHBOARD_BUSINESS_ID,
    SET_DASHBOARD_EMPLOYEES_DATE,
    SET_DASHBOARD_MODE,
    SET_DASHBOARD_STATIONS_DATE,
    SET_DASHBOARD_WEEK_DATES,
    TRANSFER_OUTDATED_REPAIRS,
    UPDATE_DASHBOARD_ORDER,
    UPDATE_DASHBOARD_SERVICE,
    fetchDashboardCalendarSuccess,
    fetchDashboardEmployeesSuccess,
    fetchDashboardStationsSuccess,
    initDashboard,
    initDashboardSuccess,
    refreshDashboard,
    selectDashboardBusinessId,
    selectDashboardDate,
    selectDashboardEndDate,
    selectDashboardFilters,
    selectDashboardMode,
    selectDashboardStartDate,
    setDashboardEmployeesDate,
    setDashboardMode,
    setDashboardStationsDate,
    setDashboardWeekDates,
    transferOutdateRepairsSuccess,
    updateDashboardOrderSuccess
} from './duck';

function prepareData(data) {
    const clientsForFilter = [];
    const employeesForFilter = [];
    const managersForFilter = [];

    _.get(data, 'orders', []).forEach(order => {
        const {
            clientId,
            clientName,
            clientSurname,
            clientPhones,
            employeeId,
            employeeName,
            employeeSurname,
            managerId,
            managerName,
            managerSurname
        } = order;
        if (clientId && !clientsForFilter.find(cl => cl.clientId === clientId)) {
            clientsForFilter.push({
                clientId,
                clientName,
                clientSurname,
                clientPhones
            });
        }
        if (employeeId && !employeesForFilter.find(em => em.id === employeeId)) {
            employeesForFilter.push({
                id: employeeId,
                name: employeeName,
                surname: employeeSurname
            });
        }
        if (managerId && !managersForFilter.find(mg => mg.id === managerId)) {
            managersForFilter.push({
                id: managerId,
                managerName,
                managerSurname
            });
        }
    });

    return {
        ..._.omit(data, ['startDate', 'endDate']),
        clientsForFilter,
        managersForFilter,
        employeesForFilter
    };
}

export function* initDashboardSaga() {
    while (true) {
        try {
            yield take(INIT_DASHBOARD);
            yield put(setDashboardInitializingState(true));

            const stations = yield call(fetchAPI, 'GET', 'businesses/stations', null, null, {handleErrorInternally: true});
            const employees = yield call(fetchAPI, 'GET', 'employees', {
                disabled: false,
                posts: ['MECHANIC']
            }, null, {handleErrorInternally: true});

            // const data = yield call(
            //     fetchAPI,
            //     'GET',
            //     'dashboard/orders',
            //     {
            //         beginDate: beginDate.format('YYYY-MM-DD'),
            //         stations: true
            //     },
            //     null,
            //     { handleErrorInternally: true }
            // );

            yield put(
                initDashboardSuccess({
                    // ..._.omit(data, ['startDate', 'endDate']),
                    stations,
                    employees
                })
            );
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setDashboardInitializingState(false));
        }
    }
}

export function* setDashboardModeSaga() {
    while (true) {
        try {
            const { payload: mode } = yield take(SET_DASHBOARD_MODE);
            yield put(setDashboardInitializingState(true));

            if (mode === 'calendar') {
                const startDate = yield select(selectDashboardStartDate);
                const endDate = yield select(selectDashboardEndDate);

                yield put(setDashboardWeekDates({ startDate, endDate }));
            } else if (mode === 'stations') {
                const date = yield select(selectDashboardDate);

                yield put(setDashboardStationsDate(date));
            } else if (mode === 'employees') {
                const date = yield select(selectDashboardDate);

                yield put(setDashboardEmployeesDate(date));
            }
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setDashboardInitializingState(false));
        }
    }
}

export function* refreshDashboardSaga() {
    while (true) {
        try {
            yield take(REFRESH_DASHBOARD);
            yield nprogress.start();
            const mode = yield select(selectDashboardMode);
            yield put(initDashboard());

            const user = yield select(state => state.auth.user);

            yield put(setDashboardMode(mode || getDefaultDashbordMode(user)));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchDashboardCalendarSaga() {
    while (true) {
        try {
            yield take(SET_DASHBOARD_WEEK_DATES);
            yield put(setDashboardFetchingState(true));
            yield nprogress.start();

            const beginDate = yield select(selectDashboardStartDate);
            const filters = yield select(selectDashboardFilters);

            const data = yield call(
                fetchAPI,
                'GET',
                'dashboard/orders',
                {
                    stations: false,
                    beginDate: beginDate.format('YYYY-MM-DD'),
                    ...filters
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchDashboardCalendarSuccess(prepareData(data)));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setDashboardFetchingState(false));
        }
    }
}

export function* fetchDashboardStationsSaga() {
    while (true) {
        try {
            yield take(SET_DASHBOARD_STATIONS_DATE);
            yield put(setDashboardFetchingState(true));
            yield nprogress.start();

            const beginDate = yield select(selectDashboardDate);
            const filters = yield select(selectDashboardFilters);
            const businessId = yield select(selectDashboardBusinessId);

            const data = yield call(
                fetchAPI,
                'GET',
                'dashboard/orders',
                {
                    stations: true,
                    beginDate: beginDate.format('YYYY-MM-DD'),
                    ...(businessId && { businessId }),
                    ...filters
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchDashboardStationsSuccess(prepareData(data)));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setDashboardFetchingState(false));
        }
    }
}

export function* fetchDashboardEmployeesSaga() {
    while (true) {
        try {
            yield take(SET_DASHBOARD_EMPLOYEES_DATE);
            yield put(setDashboardFetchingState(true));
            yield nprogress.start();

            const beginDate = yield select(selectDashboardDate);
            const filters = yield select(selectDashboardFilters);

            const data = yield call(
                fetchAPI,
                'GET',
                'dashboard/orders',
                {
                    employees: true,
                    beginDate: beginDate.format('YYYY-MM-DD'),
                    ...filters
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchDashboardEmployeesSuccess(prepareData(data)));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setDashboardFetchingState(false));
        }
    }
}

export function* linkToDashboardStationsSaga() {
    while (true) {
        try {
            const { payload: day } = yield take(LINK_TO_DASHBOARD_STATIONS);
            yield put(setDashboardStationsDate(dayjs(day)));
            yield put(setDashboardMode('stations'));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* linkToDashboardEmployeesSaga() {
    while (true) {
        try {
            const { payload: day } = yield take(LINK_TO_DASHBOARD_EMPLOYEES);
            yield put(setDashboardEmployeesDate(dayjs(day)));
            yield put(setDashboardMode('employees'));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* updateDashboardOrderSaga() {
    while (true) {
        try {
            const { payload: order } = yield take(UPDATE_DASHBOARD_ORDER);
            yield put(setDashboardFetchingState(true));
            yield nprogress.start();

            if (order.mode == 'employees') {
                yield call(fetchAPI, 'PUT', `/orders/${order.orderId}`, {}, _.omit(order, ['orderId', 'mode']), {
                    handleErrorInternally: true
                });
            } else {
                yield call(
                    fetchAPI,
                    'PUT',
                    `/stations/loads/${order.stationLoadId}`,
                    {},
                    _.omit(order, ['stationLoadId', 'employeeId', 'orderId']),
                    { handleErrorInternally: true }
                );
            }

            yield put(updateDashboardOrderSuccess());
            yield put(refreshDashboard());
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setDashboardFetchingState(false));
        }
    }
}

export function* updateDashboardServiceSaga() {
    while (true) {
        try {
            const { payload: order } = yield take(UPDATE_DASHBOARD_SERVICE);
            yield put(setDashboardFetchingState(true));
            yield nprogress.start();

            yield call(
                fetchAPI,
                'PUT',
                `/orders/${order.orderId}`,
                {},
                {
                    updateMode: true,
                    services: [
                        {
                            id: order.laborId,
                            count: _.get(order, 'duration'),
                            employeeId: _.get(order, 'employeeId'),
                            startTime: _.get(order, 'beginDatetime')
                                ? dayjs(order.beginDatetime).format('YYYY-MM-DD HH:mm')
                                : undefined,
                            endTime: _.get(order, 'beginDatetime')
                                ? dayjs(order.beginDatetime)
                                      .add(_.get(order, 'duration'), 'h')
                                      .format('YYYY-MM-DD HH:mm')
                                : undefined
                        }
                    ]
                },
                {
                    handleErrorInternally: true
                }
            );

            yield put(updateDashboardOrderSuccess());
            yield put(refreshDashboard());
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setDashboardFetchingState(false));
        }
    }
}

export function* transferOutdateRepairsSaga() {
    while (true) {
        try {
            yield take(TRANSFER_OUTDATED_REPAIRS);
            yield put(setDashboardFetchingState(true));
            yield nprogress.start();

            yield call(fetchAPI, 'POST', '/reschedule/orders');

            yield put(transferOutdateRepairsSuccess());
            yield put(refreshDashboard());
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setDashboardFetchingState(false));
        }
    }
}

export function* changeDashboardBusinessIdSaga() {
    while (true) {
        try {
            const { payload: businessId } = yield take(SET_DASHBOARD_BUSINESS_ID);
            yield put(setDashboardInitializingState(true));

            const stations = yield call(fetchAPI, 'GET', 'businesses/stations', { businessId });
            const employees = yield call(fetchAPI, 'GET', 'employees', {
                disabled: false,
                posts: ['MECHANIC']
            });

            yield put(
                initDashboardSuccess({
                    // ..._.omit(data, ['startDate', 'endDate']),
                    stations,
                    employees
                })
            );
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setDashboardInitializingState(false));
        }
    }
}

/* eslint-disable array-element-newline */
export function* saga() {
    yield all([
        call(refreshDashboardSaga),
        call(initDashboardSaga),
        call(fetchDashboardCalendarSaga),
        call(fetchDashboardStationsSaga),
        call(fetchDashboardEmployeesSaga),
        call(setDashboardModeSaga),
        call(linkToDashboardStationsSaga),
        call(linkToDashboardEmployeesSaga),
        call(updateDashboardOrderSaga),
        call(updateDashboardServiceSaga),
        call(transferOutdateRepairsSaga),
        call(changeDashboardBusinessIdSaga)
    ]);
}
/* eslint-enable array-element-newline */
