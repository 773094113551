import { Table } from 'antd';
import {
    fetchStoreMovement,
    selectStoreMovement,
    selectStoreMovementFilters,
    selectStoreMovementLoading,
    setStoreMovementPage
} from 'core/storage/storeMovement';
import { redirectToTracking } from 'core/storage/tracking';
import _ from 'lodash';
import React, { memo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { usePrevious } from 'utils';
import columns from './columns';

const StoreMovementTableComponent = memo(props => {
    const { movement } = props;
    const prevMovement = usePrevious(movement);

    useEffect(() => {
        if (!_.isEqual(movement, prevMovement)) {
            props.fetchStoreMovement();
        }
    }, [movement]);

    const pagination = {
        pageSize: 32,
        size: 'large',
        total: Math.ceil(_.get(movement, 'stats.count', 0) / 32) * 32,
        hideOnSinglePage: true,
        current: props.filters.page,
        onChange: page => {
            props.setStoreMovementPage(page);
            props.fetchStoreMovement();
        }
    };

    return (
        <StyledTable
            columns={columns(props)}
            dataSource={props.movement.list}
            loading={props.loading}
            locale={{
                emptyText: props.intl.formatMessage({ id: 'no_data' })
            }}
            pagination={pagination}
            rowKey={record => record.id}
            size='small'
        />
    );
});

const StyledTable = styled(Table)`
    background-color: rgb(255, 255, 255);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    & th.income,
    & td.income {
        background: rgba(var(--secondaryRGB), 0.2) !important;
    }
    & th.expense,
    & td.expense {
        background: rgba(var(--warningRGB), 0.2) !important;
    }
`;

const mapStateToProps = state => ({
    movement: selectStoreMovement(state),
    filters: selectStoreMovementFilters(state),
    loading: selectStoreMovementLoading(state)
});

const mapDispatchToProps = {
    fetchStoreMovement,
    setStoreMovementPage,
    redirectToTracking
};

export const StoreMovementTable = injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(StoreMovementTableComponent)
);
