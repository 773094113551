import { FullscreenOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Image, Modal, Select, Upload, notification } from 'antd';
import imageCompression from 'browser-image-compression';
import { selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getToken } from 'utils';
import Styles from '../styles.m.css';

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    suppliers: state.suppliers.suppliers,
    clients: state.clients.clients,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchSuppliers
};

const { Option } = Select;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class ChangePhotoSchemeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: []
        };
    }

    getPhotoPreview = async (hash, typeId, imgVehicleType, previewHash, previewId) => {
        const photo = await fetchAPI(
            'GET',
            'orders/vehicle/photo',
            { hash, typeId, imgVehicleType, previewId },
            null,
            {
                handleErrorInternally: true,
                rawResponse: true
            }
        );

        const reportFile = await photo.blob();
        const url = URL.createObjectURL(reportFile);

        const preview = await fetchAPI(
            'GET',
            'orders/vehicle/photo',
            { previewHash, typeId, imgVehicleType },
            null,
            {
                handleErrorInternally: true,
                rawResponse: true
            }
        );

        const previewFile = await preview.blob();
        const previewUrl = URL.createObjectURL(previewFile);

        const file = {
            key: hash,
            uid: hash,
            status: 'done',
            previewUrl,
            url,
            name: imgVehicleType,
            id: previewId,
            thumbUrl: url
        };

        return file;
    };

    postPhotoScheme = async file => {
        const { type, imageType } = this.state;

        const formData = new FormData();
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 2
        });
        formData.append('file', compressedFile);
        formData.append('type', type);
        formData.append('imageType', imageType);

        await fetch(`${__API_URL__}/orders/vehicle/photo`, {
            method: 'POST',
            headers: {
                Authorization: getToken()
            },
            body: formData
        })
            .then(rsp => Promise.resolve(rsp))
            .then(rsp => rsp.json())
            .then(data => this.getHash(data))
            .catch(e => {});
    };

    handleCancel = () => {
        const { hideModal } = this.props;

        hideModal();
    };

    componentDidUpdate = async prevProps => {
        const { list } = this.props;
        if (!prevProps.visible && this.props.visible) {
            const fileList = await Promise.all(
                list.map(
                    ({ imgHash, id, imgVehicleType, previewHash, previewId }) =>
                        this.getPhotoPreview(imgHash, id, imgVehicleType, previewHash, previewId)
                    // eslint-disable-next-line function-paren-newline
                )
            );
            this.setState({
                fileList
            });
        }
    };

    updateImageId = async imageId => {
        const { vehicleId, getPhotoList, getVehicleImage } = this.props;
        await fetchAPI('POST', '/vehicle/image', null, { vehicleId, imageId });

        getVehicleImage();
        getPhotoList(imageId);
    };

    render() {
        const {
            visible,
            isMobile,

            hideModal,
            fetchData,
            user,
            intl: { formatMessage }
        } = this.props;
        const { fileList } = this.state;

        const uploadButton = (
            <React.Fragment>
                <UploadOutlined />
                <FormattedMessage id='upload' />
            </React.Fragment>
        );

        return (
            <Modal
                maskClosable={false}
                className={Styles.photoModal}
                footer={null}
                loading={fetchData}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='order_form_table_intake_tab.add_photo_scheme' />}
                visible={visible}
                width={isMobile ? '95%' : '50%'}
                zIndex={1000}
            >
                <div
                    style={{
                        width: '100%',
                        marginBottom: 8
                    }}
                >
                    <Upload
                        action={this.postPhotoScheme}
                        beforeUpload={file => {
                            const isPNG = file.type === 'image/png';
                            const isJPG = file.type === 'image/jpeg';
                            if (!isPNG && !isJPG) {
                                notification.error({
                                    message: `${file.name} ${formatMessage({
                                        id: 'photo_modal.is_not_an_image'
                                    })}!`
                                });
                            }

                            return isPNG || isJPG;
                        }}
                        fileList={fileList}
                        itemRender={(original, file) => {
                            return (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginLeft: isMobile ? 0 : 10
                                        }}
                                    >
                                        <Image
                                            preview={{
                                                src: file.url,
                                                mask: (
                                                    <Button>
                                                        <FullscreenOutlined />
                                                    </Button>
                                                )
                                            }}
                                            src={file.previewUrl}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            width: 200,
                                            display: 'flex',
                                            justifyContent: 'center' 
                                        }}
                                    >
                                        <span
                                            onClick={async () => {
                                                await this.updateImageId(file.id);
                                                hideModal();
                                            }}
                                            className={Styles.fileName}
                                        >{`${file.name}`}</span>
                                    </div>
                                </div>
                            );
                        }}
                        listType='picture-card'
                        onRemove={false}
                    >
                        { uploadButton }
                    </Upload>
                </div>
            </Modal>
        );
    }
}
