import { Button, Col, Input, Modal, Radio, Row, Select } from 'antd';
import { Numeral, OrdersStatusesMapper } from 'commons';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import _ from 'lodash';
import ClientVehicleVisualizer from 'modals/OrderStatsInfoModal/components/ClientVehicleVisualizer';
import StatsOrderCountsPanel from 'modals/OrderStatsInfoModal/components/StatsPanel';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchOrderStats, selectOrderStats } from './redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    orderStats: selectOrderStats(state)
});

const mapDispatchToProps = {
    resetModal,
    fetchOrderStats
    // resetFields,
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrderStatsInfoModal extends Component {
    handleSubmit = e => {
        e.preventDefault();
    };

    componentDidMount() {
        const { orderId, fetchOrderStats } = this.props;

        fetchOrderStats(orderId);
    }

    render() {
        const { orderStats } = this.props;

        const stats = [
            {
                label: 'sum_without_discount',
                laborValue: orderStats.laborsSum,
                appurtenanceValue: orderStats.appurtenanciesSum,
                totalValue: orderStats.totalSum
            },
            {
                label: 'discount',
                laborValue: orderStats.laborsSumDiscount,
                appurtenanceValue: orderStats.appurtenanciesSumDiscount,
                totalValue: orderStats.totalSumDiscount,

                withPercent: true,
                laborDiscount: orderStats.laborsDiscount,
                appurtenanceDiscount: orderStats.appurtenanciesDiscount,
                totalDiscount: orderStats.totalDiscount
            },
            {
                label: 'sum_with_discount',
                laborValue: orderStats.laborsSumWithDiscount,
                appurtenanceValue: orderStats.appurtenancesSumWithDiscount,
                totalValue: orderStats.totalSumWithDiscount
            },
            {
                label: 'cost_price',
                laborValue: orderStats.laborsCostPrice,
                appurtenanceValue: orderStats.appurtenancesCostPrice,
                totalValue: orderStats.totalCostPrice
            },
            {
                label: 'sum_profit',
                laborValue: orderStats.laborsSumProfit,
                appurtenanceValue: orderStats.appurtenanciesSumProfit,
                totalValue: orderStats.totalSumProfit,

                withPercent: true,
                laborDiscount: (
                    (Number(orderStats.laborsSumProfit) /
                        Number(orderStats.laborsSumWithDiscount)) *
                    100.0
                ).toFixed(),
                appurtenanceDiscount: (
                    (Number(orderStats.appurtenanciesSumProfit) /
                        Number(orderStats.appurtenancesSumWithDiscount)) *
                    100.0
                ).toFixed(),
                totalDiscount: (
                    (Number(orderStats.totalSumProfit) / Number(orderStats.totalSumWithDiscount)) *
                    100.0
                ).toFixed()
            },
            {
                label: 'salaries',
                laborValue: orderStats.mehanicsSalary,
                appurtenanceValue: null,
                totalValue:
                    Number(orderStats.mehanicsSalary) +
                    Number(orderStats.managerSalary) +
                    Number(orderStats.appurtenanceResponsibleSalary)
            },
            {
                label: 'operating_profit',
                laborValue: orderStats.laborsOperatingProfit,
                appurtenanceValue: null,
                totalValue: orderStats.totalOperatingProfit,

                withPercent: true,
                laborDiscount: (
                    (Number(orderStats.laborsOperatingProfit) /
                        Number(orderStats.laborsSumProfit)) *
                    100
                ).toFixed(),
                appurtenanceDiscount: null,
                totalDiscount: (
                    (Number(orderStats.totalOperatingProfit) / Number(orderStats.totalSumProfit)) *
                    100
                ).toFixed()
            }
        ];

        const {
            resetModal,
            visible,
            orderId,
            fetchOrderStats,
            isMobile,

            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    footer={null}
                    maskClosable={false}
                    onCancel={() => {
                        resetModal();
                        // resetFields();
                    }}
                    onOk={this.handleSubmit}
                    title={
                        <span>
                            <FormattedMessage id='order_stats_info_modal.stats_by_order' />{' '}
                            {` ${orderStats.orderId} `}
                            <FormattedMessage id='order_stats_info_modal.in_status' /> &nbsp;
                            <OrdersStatusesMapper status={_.get(orderStats, 'status', '')} />
                        </span>
                    }
                    visible={visible === MODALS.ORDER_STATS_INFO_MODAL}
                    width={isMobile ? '95vw' : '70vw'}
                >
                    <ClientVehicleVisualizer />
                    <div className={Styles.mainOrderInfoContainer}>
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col className={Styles.headerOrderInfo} span={6}>
                                <FormattedMessage id='order_stats_info_modal.creation' />
                            </Col>
                            <Col className={Styles.headerOrderInfo} span={6}>
                                <FormattedMessage id='order_stats_info_modal.record' />
                            </Col>
                            <Col className={Styles.headerOrderInfo} span={6}>
                                <FormattedMessage id='order_stats_info_modal.success_datetime' />
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={6} style={{ fontWeight: 'bold' }}>
                                <FormattedMessage id='order_stats_info_modal.datetime' />
                            </Col>
                            <Col className={Styles.orderInfo} span={6}>
                                {orderStats.datetime ? (
                                    orderStats.datetime
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </Col>
                            <Col className={Styles.orderInfo} span={6}>
                                {orderStats.datetime ? (
                                    orderStats.datetime
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </Col>
                            <Col className={Styles.orderInfo} span={6}>
                                {orderStats.datetimeSuccess ? (
                                    orderStats.datetimeSuccess
                                ) : (
                                    <FormattedMessage id='long_dash' />
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Row className={Styles.row} style={{ marginTop: '1em', marginBottom: '-2em' }}>
                        <Col span={6}></Col>
                        <Col className={Styles.headerOrderInfo} span={6}>
                            <FormattedMessage id='order_stats_info_modal.labors' />
                        </Col>
                        <Col className={Styles.headerOrderInfo} span={6}>
                            <FormattedMessage id='order_stats_info_modal.appurtenances' />
                        </Col>
                        <Col className={Styles.headerOrderInfo} span={6}>
                            <FormattedMessage id='order_stats_info_modal.total' />
                        </Col>
                    </Row>
                    <StatsOrderCountsPanel stats={stats} />
                    <Row className={Styles.row} style={{ fontSize: '1.2em', marginTop: '1em' }}>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <FormattedMessage id='order_stats_info_modal.paid' />
                            :&nbsp;
                        </Col>
                        <Col span={6}>
                            <Numeral mask='0,0.00'>{Number(orderStats.totalSumCashOrders)}</Numeral>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <FormattedMessage id='order_stats_info_modal.remainder' />
                            :&nbsp;
                        </Col>
                        <Col span={6}>
                            <Numeral mask='0,0.00'>
                                {Number(orderStats.totalSumWithDiscount) -
                                    Number(orderStats.totalSumCashOrders)}
                            </Numeral>
                        </Col>
                    </Row>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            key='submit'
                            onClick={() => {
                                fetchOrderStats(orderId, true);
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='order_stats_info_modal.recalculate' />
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}
