// Core
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Drawer, Image, Modal, Popconfirm, Switch, Table, notification } from 'antd';
import { Layout, Numeral } from 'commons';
import { resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { AddRegulationsModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PencilIcon } from 'theme';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Panel } = Collapse;

const mapStateToProps = state => ({
    modal: state.modals.modal,
    user: state.auth
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class RegulationsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allRegulations: [],
            regulationsOwnRegulations: [],
            labors: [],
            detailsTreeData: undefined,
            details: [],
            idForOwnRegulations: undefined,
            helperDrawerOpen: false,
            allLinks: []
        };

        this.columns = (withTitle = true, titleForEachTable) => [
            {
                key: 'edit',
                width: '3%',
                align: 'center',
                render: (data, row) => {
                    const accessAddButton = isGrantAccessed(
                        this.props.user,
                        grants.SETTINGS_REGULATIONS,
                        accesses.ROWO
                    );

                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Button
                                disabled={!row.visibility || !accessAddButton}
                                icon={<PencilIcon />}
                                onClick={() => {
                                    this.editRegulations(row);
                                }}
                            />
                            <Popconfirm
                                disabled={!row.visibility || !accessAddButton}
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        'business/regulations',
                                        null,
                                        { id: Number(row.id) },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setTimeout(() => this.fetchData(), 100);
                                }}
                                title={`${props.intl.formatMessage({ id: 'delete' })} ?`}
                            >
                                <Button disabled={!row.visibility || !accessAddButton} icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </div>
                    );
                }
            },
            {
                title: titleForEachTable,
                key: 'type',
                dataIndex: 'regulationName',
                width: '30%'
            },
            {
                title: withTitle ? <FormattedMessage id='regulations.km' /> : undefined,
                key: 'km',
                width: '5%',
                dataIndex: 'km',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: withTitle ? <FormattedMessage id='month' /> : undefined,
                key: 'months',
                width: '5%',
                dataIndex: 'months'
            },
            {
                title: withTitle ? <FormattedMessage id='edit_vehicle.display' /> : undefined,
                key: 'display',
                width: '10%',
                render: (data, row) => {
                    return (
                        <Switch
                            checked={row.visibility}
                            disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_REGULATIONS, accesses.ROWO)}
                            onChange={async () => {
                                await fetchAPI('PUT', 'businesses/regulations', undefined, {
                                    id: row.id,
                                    regulationName: row.regulationName,
                                    regulationKm: row.km,
                                    regulationMonths: row.months,
                                    visibility: !row.visibility
                                });
                                await setTimeout(() => this.fetchData(), 100);
                            }}
                        />
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.fetchData();
        this.fetchLaborsAndDetails();
        this.fetchLabors();
    }

    componentDidUpdate() {
        if (!this.state.detailsTreeData && this.state.details && this.state.details.length) {
            this.buildStoreGroupsTree();
        }
    }

    fetchData = async () => {
        const allRegulations = await fetchAPI('GET', 'business/regulations', undefined, undefined);
        this.setState({
            allRegulations: allRegulations || [],
            idForOwnRegulations:
                _.get(
                    allRegulations.find(({ groupId }) => groupId == 7),
                    'groupId'
                ) || 7
        });
    };

    generalRegulations = async () => {
        await fetchAPI('POST', 'businesses/regulations/copy', undefined, {
            copyOption: 'business'
        });
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'order-status.success' })
        });
        await setTimeout(() => this.fetchData(), 100);
    };

    fetchLabors = async query => {
        const { labors } = await fetchAPI(
            'GET',
            'labors',
            { search: query ? query.replace(/-/g, '') : undefined, page: 1, pageSize: 25 },
            null,
            {
                handleErrorInternally: true
            }
        );
        this.setState({ labors });
    };

    handleSearchLabors = _.debounce(value => {
        this.fetchLabors(value);
    }, 500);

    fetchLaborsAndDetails = async () => {
        const details = await fetchAPI('GET', '/store_groups', undefined, undefined, {
            handleErrorInternally: true
        });
        this.setState({ details });
    };

    editRegulations = row => {
        this.setState({
            visibleAddRegulationsModal: true,
            regulationModalRow: row
        });
    };

    buildStoreGroupsTree() {
        const treeData = [];
        for (let i = 0; i < this.state.details.length; i++) {
            const parentGroup = this.state.details[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id})`,
                name: parentGroup.singleName,
                value: parentGroup.id,
                className: Styles.groupTreeOption,
                key: parentGroup.id,
                // selectable: false,
                children: [],
                multiplier: parentGroup.priceGroupMultiplier
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id})`,
                    name: childGroup.singleName,
                    value: childGroup.id,
                    className: Styles.groupTreeOption,
                    key: childGroup.id,
                    // selectable: false,
                    children: [],
                    multiplier: childGroup.priceGroupMultiplier
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id})`,
                        name: lastNode.singleName,
                        value: lastNode.id,
                        className: Styles.groupTreeOption,
                        key: lastNode.id,
                        children: [],
                        multiplier: lastNode.priceGroupMultiplier
                    });
                    for (let l = 0; l < lastNode.childGroups.length; l++) {
                        const elem = lastNode.childGroups[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id})`,
                            name: elem.singleName,
                            value: elem.id,
                            className: Styles.groupTreeOption,
                            key: elem.id,
                            multiplier: elem.priceGroupMultiplier
                        });
                    }
                }
            }
        }
        this.setState({
            detailsTreeData: treeData
        });
    }

    render() {
        const { user } = this.props;
        const {
            allRegulations,
            regulationsOwnRegulations,
            visibleAddRegulationsModal,
            labors,
            detailsTreeData,
            regulationModalRow,
            idForOwnRegulations,
            allLinks,
            helperDrawerOpen
        } = this.state;

        const accessAddButton = isGrantAccessed(user, grants.SETTINGS_REGULATIONS, accesses.ROWO);

        return (
            <React.Fragment>
                <Layout
                    controls={
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                const links = await fetchAPI(
                                    'GET',
                                    '/helps',
                                    {
                                        helpId: 'order_mrd_add_regulations_data'
                                    },
                                    undefined,
                                    {
                                        handleErrorInternally: true
                                    }
                                );
                                this.setState({
                                    allLinks: links,
                                    helperDrawerOpen: true
                                });
                            }}
                            style={{
                                fontSize: 22,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    }
                    title={<FormattedMessage id='navigation.regulations' />}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: '8px 0'
                        }}
                    >
                        <Button
                            disabled={!accessAddButton}
                            onClick={() => {
                                this.setState({
                                    visibleAddRegulationsModal: true
                                });
                            }}
                            style={{ minWidth: 180, maxWidth: 220, marginLeft: 8 }}
                        >
                            <FormattedMessage id='regulations.add_row' />
                        </Button>
                        <Button
                            disabled={!accessAddButton}
                            onClick={() => {
                                if (_.get(allRegulations, 'length')) {
                                    Modal.confirm({
                                        title: this.props.intl.formatMessage({
                                            id: 'regulations.modal_confirm_for_update_rows'
                                        }),
                                        onOk: () => {
                                            this.generalRegulations();
                                        },
                                        okType: 'primary',
                                        onCancel() {}
                                    });
                                } else {
                                    this.generalRegulations();
                                }
                            }}
                            style={{ minWidth: 180, maxWidth: 220, marginLeft: 8 }}
                            type='primary'
                        >
                            <FormattedMessage id='regulations.default' />
                        </Button>
                    </div>

                    {allRegulations.map(({ groupId, groupName, regulations }, index) => (
                        <Table
                            key={groupId}
                            bordered
                            columns={this.columns(!index, groupName)}
                            dataSource={regulations}
                            pagination={false}
                            size='small'
                        />
                    ))}
                </Layout>
                <AddRegulationsModal
                    detailsTreeData={detailsTreeData}
                    editRegulations={this.editRegulations}
                    fetchData={this.fetchData}
                    handleSearchLabors={this.handleSearchLabors}
                    hideModal={() => {
                        this.setState({
                            visibleAddRegulationsModal: false,
                            regulationModalRow: undefined
                        });
                    }}
                    idForOwnRegulations={idForOwnRegulations}
                    labors={labors}
                    regulationModalRow={regulationModalRow}
                    regulationsOwnRegulations={regulationsOwnRegulations}
                    visible={visibleAddRegulationsModal}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}
