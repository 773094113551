import { Table } from 'antd';
import {
    selectSelectedClientId,
    setSelectedClientId
} from 'modals/RefactoredCashOrderModal/redux/clients/duck';
import {
    fetchClients,
    selectClients,
    selectClientsSort,
    selectClientsStats,
    selectFetchingClients,
    setClientsSort,
    setOrdersFilters
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    clients: selectClients(state),
    clientsStats: selectClientsStats(state),
    clientsSort: selectClientsSort(state),
    fetchingClients: selectFetchingClients(state),
    selectedClientId: selectSelectedClientId(state)
});

const mapDispatchToProps = {
    fetchClients,
    setClientsSort,
    setSelectedClientId,
    setOrdersFilters
};

/**
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientsTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchClients();
    }

    render() {
        const {
            isMobile,
            clients,
            fetchingClients,
            setOrdersFilters,

            clientsStats,
            clientsSort,
            setClientsSort,
            setSelectedClientId,
            selectedClientId,

            onClientSelected
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(clientsStats.countClients / 25) * 25,
            current: clientsSort.page,
            onChange: page => {
                setClientsSort({ page });
            }
        };

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig()}
                    dataSource={clients}
                    loading={fetchingClients}
                    onRow={record => ({
                        onClick: () => {
                            setSelectedClientId(record.clientId);
                            onClientSelected && onClientSelected(record.clientId);
                            setOrdersFilters({ client: record.clientId });
                        }
                    })}
                    pagination={pagination}
                    rowClassName={record =>
                        record.clientId == selectedClientId ? Styles.selectedRow : undefined
                    }
                    rowKey={record => record.clientId}
                    scroll={{ x: isMobile ? '100vw' : '40vw', y: '25vh' }}
                />
            </div>
        );
    }
}
