/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'suppliersDebtsContainer';
const prefix = `cpb/${moduleName}`;

export const FETCH_BUSINESS_SUPPLIERS = `${prefix}/FETCH_BUSINESS_SUPPLIERS`;
export const FETCH_BUSINESS_SUPPLIERS_SUCCESS = `${prefix}/FETCH_BUSINESS_SUPPLIERS_SUCCESS`;
export const SET_BUSINESS_SUPPLIERS_FILTERS = `${prefix}/SET_BUSINESS_SUPPLIERS_FILTERS`;
export const SET_FETCHING_BUSINESS_SUPPLIERS = `${prefix}/SET_FETCHING_BUSINESS_SUPPLIERS`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    businessSuppliers: [],
    businessSuppliersFilters: {
        page: 1,
        query: undefined,
        filterBusinessRequisiteId: undefined,
    },
    businessSuppliersStats: {},
    fetchingBusinessSuppliers: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_BUSINESS_SUPPLIERS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_BUSINESS_SUPPLIERS_FILTERS:
            return {
                ...state,
                businessSuppliersFilters: {
                    ...state.businessSuppliersFilters,
                    ...payload
                }
            };
        case SET_FETCHING_BUSINESS_SUPPLIERS:
            return {
                ...state,
                fetchingBusinessSuppliers: payload
            };

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectBusinessSuppliers = state => state[moduleName].businessSuppliers;
export const selectBusinessSuppliersStats = state => state[moduleName].businessSuppliersStats;
export const selectBusinessSuppliersFilters = state => state[moduleName].businessSuppliersFilters;
export const selectFetchingBusinessSuppliers = state => state[moduleName].fetchingBusinessSuppliers;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchBusinessSuppliers = () => ({
    type: FETCH_BUSINESS_SUPPLIERS
});

export const fetchBusinessSuppliersSuccess = ({ businessSuppliers, businessSuppliersStats }) => ({
    type: FETCH_BUSINESS_SUPPLIERS_SUCCESS,
    payload: { businessSuppliers, businessSuppliersStats }
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setBusinessSuppliersFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_BUSINESS_SUPPLIERS_FILTERS,
            payload: filters
        });
        dispatch(fetchBusinessSuppliers());
    };
};

export const setFetchingBusinessSuppliers = value => ({
    type: SET_FETCHING_BUSINESS_SUPPLIERS,
    payload: value
});
