import { Form } from '@ant-design/compatible';
import { Col, Row, Select } from 'antd';
import { DecoratedInputNumber } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { phoneNumberMasks } from 'utils';
import Styles from './styles.m.css';

const FormItem = Form.Item;
const { Option } = Select;

class PhoneNumberInput extends Component {
    constructor(props) {
        super(props);

        const mask = this.getMask(props.initialPhoneNumber);
        this.state = { mask };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialPhoneNumber !== this.props.initialPhoneNumber) {
            const maskCountry = this.getMask(this.props.initialPhoneNumber);
            this.setState({ mask: maskCountry });
        }
    }

    getPreparedPhoneNumber = initialPhoneNumber => {
        const digitsNumber = String(initialPhoneNumber || '').replace(/[^\d]/g, '');

        return digitsNumber.startsWith('0') ? `38${digitsNumber}` : digitsNumber;
    };

    getMask = initialPhoneNumber => {
        const phoneNumber = this.getPreparedPhoneNumber(initialPhoneNumber);
        /* eslint-disable */
        const maskConfig = _(phoneNumberMasks)
            .toPairs()
            .find(([country, mask]) => phoneNumber.startsWith(mask.replace(/[^\d]/g, "")));
        /* eslint-enable */
        if (maskConfig) {
            return _.first(maskConfig);
        }

        return _.first(_.keys(phoneNumberMasks));
    };

    render() {
        const {
            fieldName,
            initialPhoneNumber,
            form: { getFieldDecorator, setFieldsValue, getFieldValue },
            disabled
        } = this.props;
        const phoneNumber = this.getPreparedPhoneNumber(initialPhoneNumber);

        const { mask } = this.state;

        const selectedMask = phoneNumberMasks[mask];
        const countryDigits = phoneNumberMasks[mask].replace(/[^\d]/g, '');

        const characters = selectedMask.replace(/[^\d_]/g, '').length;

        const formatter = value => {
            const base = selectedMask; // default number state
            const escapedValue = String(value).replace(/[^\d]/g, '');
            const inputNumber = escapedValue.length < countryDigits.length ? countryDigits : escapedValue;
            const digits = inputNumber.replace(new RegExp(`^${countryDigits}`, ''), '');

            const formattedNumber = digits.split('').reduce((prev, cur) => (prev || '').replace('_', cur), base); // replace _ with numbers
            const indexOfPlaceHolder = (formattedNumber || '').indexOf('_'); // get index of first empty number
            const endPosition = indexOfPlaceHolder === -1 ? (formattedNumber || '').length : indexOfPlaceHolder;

            return (formattedNumber || '').slice(0, endPosition).replace(/[^\d]+$/, ''); // slice & trim the end
        };

        const parser = value => {
            const replacedValue = value.replace(/[^\d]/g, '').substring(0, characters);

            return replacedValue.length < countryDigits.length ? countryDigits : replacedValue;
        };

        const options = {
            formatter,
            parser,
            step: null,
            style: { width: '100%' }
        };

        return (
            <Row align='inline' className={Styles.phoneNumberInput} type='flex'>
                <Col className={Styles.phoneNumberCountry} span='6'>
                    <FormItem className={Styles.numberCountrySelect}>
                        <Select
                            disabled={disabled}
                            onChange={mask => {
                                const currentPhoneNumber = getFieldValue(fieldName);
                                const digits = String(currentPhoneNumber).replace(
                                    new RegExp(`^${countryDigits}`, ''),
                                    ''
                                );
                                const newCountryDigits = phoneNumberMasks[mask].replace(/[^\d]/g, '');

                                setFieldsValue({
                                    [fieldName]: Number(newCountryDigits + digits)
                                });
                                this.setState({ mask });
                            }}
                            value={mask}
                        >
                            {[..._.keys(phoneNumberMasks)].map(value => (
                                <Option key={value} value={value}>
                                    {value}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </Col>
                <Col span='18'>
                    <DecoratedInputNumber
                        disabled={disabled}
                        {...options}
                        cnStyles={Styles.phoneNumberInput}
                        field={fieldName}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={Number(phoneNumber)}
                    />
                </Col>
            </Row>
        );
    }
}

export default PhoneNumberInput;
