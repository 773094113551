// eslint-disable-next-line max-classes-per-file
import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Select, Table } from 'antd';
import { MODALS } from 'core/modals/duck';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

@injectIntl
@connect(mapStateToProps)
export default class ReferenceBookAddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visibleEdit: false,
            bookNums: {
                numbers: []
            },
            page: 1
        };
        this.columns = [
            {
                title: <FormattedMessage id='directory_page.type_print_form' />,
                key: 'formName',
                dataIndex: 'formName'
            },
            {
                title: <FormattedMessage id='directory_page.document_number' />,
                key: 'number',
                dataIndex: 'number'
            },
            {
                key: 'edit',
                render: row => (
                    <Button
                        data-qa='button_visible_edit_reference_book_add_modal'
                        disabled={isForbidden(this.props.user, permissions.UPDATE_SUCCESS_ORDER)}
                        onClick={() => {
                            this.setState({
                                visibleEdit: row
                            });
                        }}
                        style={{
                            padding: '0px 8px',
                            fontSize: 18
                        }}
                        title={this.props.intl.formatMessage({ id: 'directory_page.edit' })}
                    >
                        <EditOutlined />
                    </Button>
                )
            }
        ];
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.visible !== MODALS.REFERENCE_BOOK_ADD_MODAL &&
            this.props.visible === MODALS.REFERENCE_BOOK_ADD_MODAL
        ) {
            this.fetchRefBooksNumbers();
        }
    }

    fetchRefBooksNumbers = async () => {
        const { orderId, storeDocId } = this.props;
        const { page } = this.state;
        const bookNums = await fetchAPI(
            'GET',
            'reference_book/numbers',
            {
                orderId,
                storeDocId,
                page,
                pageSize: 25
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            bookNums
        });
    };

    render() {
        const {
            visible,
            resetModal,
            isMobile,
            intl: { formatMessage }
        } = this.props;
        const { bookNums, page, visibleEdit } = this.state;
        const pagination = {
            pageSize: 25,
            size: 'small',
            total: Math.ceil(_.get(bookNums, 'stats.count', 0) / 25) * 25,
            hideOnSinglePage: true,
            current: page,
            onChange: async page => {
                await this.setState({ page });
                this.fetchRefBooksNumbers();
            }
        };

        return (
            <div>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={() => {
                        resetModal();
                        this.setState({
                            page: 1
                        });
                    }}
                    title={<FormattedMessage id='directory_page.document_numbers' />}
                    visible={visible === MODALS.REFERENCE_BOOK_ADD_MODAL}
                    width={!isMobile ? '50vw' : '95%'}
                >
                    <Table
                        bordered
                        columns={this.columns}
                        dataSource={get(bookNums, 'numbers', [])}
                        pagination={pagination}
                        rowKey='id'
                        size='small'
                    ></Table>
                </Modal>
                <ReferenceBookEditModal
                    hideModal={() => {
                        this.setState({ visibleEdit: false });
                    }}
                    updateTable={this.fetchRefBooksNumbers}
                    visibleEdit={visibleEdit}
                />
            </div>
        );
    }
}

@injectIntl
class ReferenceBookEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleEdit: false
        };
    }

    editRefBook = async () => {
        const { id, number } = this.state;
        await fetchAPI('PUT', 'reference_book/numbers', null, [{ id, number }], {
            handleErrorInternally: true
        });
    };

    handleOk = async () => {
        await this.editRefBook();
        this.handleCancel();
        this.props.updateTable();
    };

    handleCancel = () => {
        this.setState({
            number: undefined,
            visibleEdit: false
        });
        this.props.visibleEdit;
        this.props.hideModal();
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.visibleEdit && this.props.visibleEdit) {
            this.setState({
                ...this.props.visibleEdit
            });
        }
    }

    render() {
        const {
            visibleEdit,
            hideModal,
            intl: { formatMessage }
        } = this.props;
        const { number } = this.state;

        return (
            <div>
                <Modal
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='directory_page.title_edit_modal' />}
                    visible={Boolean(visibleEdit)}
                    width='40vw'
                >
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={12}>
                            <FormattedMessage id='directory_page.current_number' />
                        </Col>
                        <Col span={12}>
                            <Input
                                data-qa='input_number_edit_reference_book_add_modal'
                                onChange={e => {
                                    this.setState({
                                        number: e.target.value
                                    });
                                }}
                                value={number}
                            />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

export { ReferenceBookEditModal };
