export const SD_TAGS = {
    doc: {
        id: 'ідентифікатор',
        type: 'тип',
        status: 'статус',
        supplierDocNumber: 'номер документа постачальника',
        createdDatetime: 'дата створення',
        doneDatetime: 'дата завершення',
        recordDatetime: 'дата запису',
        paidDatetime: 'дата оплати',
        businessSupplierId: 'id ідентифікатор постачальника',
        sum: 'сума',
        businessId: 'id ідентифікатор станції',
        managerId: 'id ідентифікатор менеджера',
        warehouseId: 'id ідентифікатор складу',
        documentType: 'тип документа',
        counterpartBusinessSupplierId: 'id ідентифікатор контрагента постачальника',
        counterpartClientId: 'id ідентифікатор контрагента клієнта',
        counterpartWarehouseId: 'id ідентифікатор складу контрагента',
        counterpartEmployeeId: 'id ідентифікатор співробітника контрагента',
        operationCode: 'код операції',
        context: 'контекст',
        payUntilDatetime: 'дата оплати до',
        sellingSum: 'сума продажу',
        orderId: 'id ідентифікатор наряд-замовлення',
        externalId: 'зовнішній id ідентифікатор',
        transactionId: 'id ідентифікатор транзакції',
        comment: 'коментар',
        businessSupplierRequisiteId: 'id ідентифікатор реквізитів бізнес-постачальника',
        clientRequisiteId: 'id ідентифікатор реквізитів клієнта',
        businessRequisiteId: 'id ідентифікатор реквізитів бізнесу',
        externalApiOrderStatus: 'статус замовлення зовнішнього API',
        externalApiDocId: 'id ідентифікатор документа зовнішнього API',
        externalApiExpenseDocId: 'id ідентифікатор витратного документа зовнішнього API',
        counterpartBusinessSupplierName: 'назва контрагента постачальника',
        counterpartClientName: 'назва контрагента клієнта',
        counterpartClientPhones: 'телефони контрагента клієнта',
        counterpartWarehouseName: 'назва складу контрагента',
        counterpartEmployeeName: 'ім\'я співробітника контрагента',
        counterpartEmployeePhone: 'телефон співробітника контрагента',
        documentNumber: 'номер документа',
        barcode: 'штрихкод',
        remainSum: 'залишкова сума',
        sumTax: 'сума податку',
        showTax: 'показати податок',
        sellingSumTax: 'сума податку на продаж',
        wrhAttribute: 'атрибут складу',
        counterpartWhsAttribute: 'атрибут складу контрагента',
        incomeOnlyWithCell: 'прихід лише з коміркою',
        ordNum: 'номер замовлення',
        businessSupplier: {
            id: 'id ідентифікатор',
            name: 'назва',
            businessId: 'ідентифікатор бізнесу',
            contactName: 'ім\'я контакту',
            contactMiddlename: 'по батькові контакту',
            contactSurname: 'прізвище контакту',
            phones: 'телефони',
            emails: 'електронні адреси',
            paymentRespite: 'відстрочка платежу',
            comment: 'коментар',
            type: 'тип',
            accountId: 'ідентифікатор облікового запису'
        },
        manager: {
            id: 'ідентифікатор',
            avatar: 'аватар',
            businessId: 'ідентифікатор бізнесу',
            businessesAccess: 'доступ до бізнесів',
            email: 'електронна адреса',
            enabled: 'активовано',
            isAdmin: 'є адміністратором',
            language: 'мова',
            name: 'ім\'я',
            phone: 'телефон',
            partnerId: 'id ідентифікатор партнера',
            roleId: 'id ідентифікатор ролі',
            surname: 'прізвище',
            employeeId: 'id ідентифікатор співробітника',
            isMechanic: 'є механіком',
            isCashier: 'є касиром',
            taxService: 'РРО',
            phoneCode: {
                code: 'код',
                country: 'країна'
            },
            medocUrl: 'url посилання',
            medocEdrpou: 'Код ЄДРПОУ',
            medocLogin: 'medoc Логін',
            medocDept: 'номер філії'
        },
        docProducts: {
            id: 'id ідентифікатор',
            orderId: 'ідентифікатор замовлення',
            orderNum: 'номер замовлення',
            quantity: 'кількість',
            purchasePrice: 'ціна закупівлі',
            purchaseSum: 'сума закупівлі',
            sellingPrice: 'ціна продажу',
            sellingSum: 'сума продажу',
            expenseFromId: 'ідентифікатор витратного рахунку',
            productId: 'ідентифікатор продукту',
            docId: 'ідентифікатор документа',
            measureUnit: 'одиниця виміру',
            returnQuantity: 'кількість повернення',
            stockPrice: 'ціна складу',
            calculatedFrom: 'розраховано з',
            ordersAppurtenancies: 'доповнення до замовлень',
            supplierPartNumber: 'номер частини постачальника',
            addToAddress: 'додати до адреси',
            getFromAddress: 'отримати з адреси',
            order: 'замовлення',
            uktz: 'Код УКТЗЕД',
            comment: 'коментар',
            docProductUnitId: 'id ідентифікатор документа одиниці продукції',
            product: {
                id: 'id',
                groupId: 'id групи товару',
                name: 'назва',
                code: 'код з/ч',
                brandId: 'id бренда',
                brandName: 'Назва бренда',
                unit: {
                    en: 'en',
                    ua: 'ua'
                },
                measureUnit: 'одиниця виміру',
                tradeCode: 'Код УКТЗЕД',
                certificate: 'сертифікат',
                businessId: 'id ідентифікатор бізнесу',
                defaultBusinessSupplierId: 'id ідентифікатор стандартного постачальника бізнесу',
                saveOnStock: 'зберігати на складі',
                min: 'мінімум',
                max: 'максимум',
                defaultWarehouseId: 'id ідентифікатор стандартного складу',
                multiplicity: 'кратність',
                externalId: 'зовнішній id ідентифікатор',
                height: 'висота',
                width: 'ширина',
                depth: 'глибина',
                volume: 'об\'єм',
                weight: 'вага',
                length: 'довжина',
                internalDiameter: 'внутрішній діаметр',
                externalDiameter: 'зовнішній діаметр',
                colorId: 'id ідентифікатор кольору',
                material: 'матеріал',
                comment: 'коментар',
                condition: 'стан',
                usedInVehicles: 'використовується в транспортних засобах',
                right: 'право',
                left: 'ліво',
                front: 'перед',
                rear: 'зад',
                top: 'верх',
                bottom: 'низ',
                external: 'зовнішній',
                internal: 'внутрішній',
                central: 'центральний',
                cellAddresses: 'адреси комірок',
                priceGroupNumber: 'номер групи цін',
                fixedSellingPrice: 'фіксована ціна продажу',
                minSellingPrice: 'мінімальна ціна продажу',
                fixedPurchasePrice: 'фіксована ціна закупівлі',
                getSellingPriceFrom: 'отримати ціну продажу з',
                getPurchasePriceFrom: 'отримати ціну закупівлі з',
                isRound: 'округлений',
                decimalPlaces: 'десяткові знаки',
                productUnitId: 'ідентифікатор одиниці продукту',
                enabled: 'активний',
                markupGroupId: 'ідентифікатор групи націнок',
                source: 'джерело',
                purchaseCurrencyId: 'ідентифікатор валюти закупівлі',
                sellingCurrencyId: 'ідентифікатор валюти продажу',
                purchasePriceInCurrency: 'ціна закупівлі у валюті',
                sellingPriceInCurrency: 'ціна продажу у валюті',
                storageHashes: 'хеші зберігання',
                brand: {
                    id: 'id',
                    $name: 'Назва бренду',
                    $supplierId: 'id постачальника'
                }
            },
            $status: 'статус',
            $stockSum: 'сума на складі',
            $displayCode: 'код відображення'
        },
        docServices: {
            $storeDocServiceId: 'id ідентифікатор сервісу документа зберігання',
            $name: 'назва',
            $analyticsId: 'id ідентифікатор аналітики',
            $storeDocId: 'id ідентифікатор документа зберігання',
            $orderId: 'id ідентифікатор замовлення',
            $docServiceUnitId: 'id ідентифікатор одиниці сервісного документа',
            $measureUnit: 'одиниця виміру',
            $price: 'ціна',
            $count: 'кількість',
            $sum: 'сума',
            $analyticsName: 'назва аналітики'
        },
        tax: 'податок ПДВ',
        sellingTax: 'податок ПДВ на продаж',
        store: 'Зберегти',
        restore: 'Повернути',
        tags: {
            $code: 'код з/ч',
            $name: 'назва',
            $brandName: 'назва бренду',
            $comment: 'коментар',
            $vehicle: 'транспортний засіб',
            $cell: 'комірка',
            $barcode: 'штрих-код',
            $price: 'ціна'
        },
        showClientNumber: 'показати номер клієнта',
        showRequisites: 'показати реквізити',
        showSupplierNumber: 'показати номер постачальника'
    },
    business: {
        name: 'назва',
        address: 'адреса',
        phones: 'номер телефону',
        logo: {
            original: {
                path: 'посилання',
                width: 'ширина',
                height: 'висота'
            }
        },
        primaryLogoUrl: 'URL посилання основного логотипу',
        secondaryLogoUrl: 'URL посилання додаткового логотипу',
        domain: 'домен',
        normHourPrice: 'ціна нормо-години',
        phoneCode: {
            $$code: 'код',
            $$number: 'номер',
            $$country: 'країна'
        },
        packageStatus: 'статус пакету',
        base64PrimaryLogo: 'base64 основного логотипу',
        currency: 'currency'
    },
    businessRequisite: {
        id: 'ідентифікатор',
        enabled: 'активний',
        name: 'назва',
        address: 'адреса',
        bank: 'банк',
        ifi: 'Код банку',
        ca: 'рахунок IBAN',
        itn: 'Код ЄДРПОУ',
        formType: 'тип форми',
        formName: 'назва форми',
        isTaxPayer: 'платник податків',
        calculationMethod: 'метод розрахунку',
        taxRate: 'ставка податку',
        ipn: 'ІПН',
        certificateNumber: 'номер сертифіката',
        contractNumber: 'номер контракту',
        contractDate: 'дата контракту',
        director: 'директор',
        used: 'використовується',
        disabled: 'неактивний'
    },
    clientRequisite: {
        id: 'ідентифікатор',
        enabled: 'активний',
        name: 'назва',
        address: 'адреса',
        bank: 'банк',
        ifi: 'Код банку',
        ca: 'рахунок IBAN',
        itn: 'Код ЄДРПОУ',
        clientId: 'ідентифікатор клієнта',
        formType: 'тип форми',
        formName: 'назва форми',
        isTaxPayer: 'є платником податків',
        calculationMethod: 'метод розрахунку',
        taxRate: 'Податкова ставка',
        ipn: 'ІПН',
        certificateNumber: 'номер сертифіката',
        contractNumber: 'номер контракту',
        director: 'директор',
        accountId: 'id ідентифікатор рахунку',
        disabled: 'відключений',
        contractDate: 'дата контракту'
    },
    client: {
        clientId: 'ідентифікатор клієнта',
        middleName: 'по батькові',
        status: 'статус',
        birthday: 'дата народження',
        sex: 'стать',
        name: 'ім\'я',
        disableSms: 'відключити SMS',
        disableAutoSms: 'відключити автоматичні SMS',
        surname: 'прізвище',
        phones: 'телефони',
        emails: 'електронні пошти',
        paymentRespite: 'строк відстрочки платежу',
        sourceId: 'id ідентифікатор джерела',
        discount: 'знижка',
        laborsDiscount: 'знижка на роботи',
        detailsDiscount: 'знижка на деталі',
        type: 'тип',
        comment: 'коментар',
        markupGroupId: 'ідентифікатор групи націнки',
        phoneCode: {
            $code: 'код',
            $country: 'країна'
        },
        accountId: 'accountId',
        requisites: {
            $ca: 'IBAN',
            $id: 'id',
            $ifi: 'Код Банку',
            $ipn: 'ІПН',
            $itn: 'Код ЄДРПОУ',
            $bank: 'bank',
            $name: 'name',
            address: 'адреса',
            enabled: 'активний',
            taxRate: 'ставка податку',
            director: 'директор',
            disabled: 'неактивний',
            formName: 'назва форми',
            formType: 'тип форми',
            accountId: 'ідентифікатор рахунку',
            isTaxPayer: 'платник податків',
            accountName: 'назва рахунку',
            contractDate: 'дата контракту',
            contractNumber: 'номер контракту',
            calculationMethod: 'метод розрахунку',
            certificateNumber: 'номер сертифіката'
        },
        disabled: 'неактивний',
        source: 'джерело',
        accountName: 'ім\'я рахунку',
        notifications: 'сповіщення',
        reviews: 'відгуки'
    },
    clientVehicle: {
        enabled: 'активний',
        id: 'id',
        vin: 'VIN',
        number: 'номер',
        registrationName: 'назва реєстрації',
        vehicleMakeName: 'назва марки транспортного засобу',
        vehicleModelName: 'назва моделі транспортного засобу',
        vehicleModificationName: 'назва модифікації транспортного засобу',
        clientVehicleYear: 'рік випуску транспортного засобу',
        vehiclegroupid: 'id групи транспортних засобів',
        clientId: 'id клієнта',
        vehicleMakeId: 'id марки транспортного засобу'
    },
    order: {
        detailsDiscount: 'знижка на деталі',
        beginDatetime: 'початок дати та часу',
        businessComment: 'коментар до бізнесу',
        clientEmail: 'електронна пошта клієнта',
        clientPhone: 'телефон клієнта',
        clientId: 'id клієнта',
        comment: 'коментар',
        courtesyCar: 'автомобіль на безкоштовне користування',
        clientRequisiteId: 'id реквізиту клієнта',
        clientVehicleId: 'id транспортного засобу клієнта',
        clientAggregateId: 'id агрегату клієнта',
        diskMaterial: 'матеріал диска',
        diskRadius: 'радіус диска',
        duration: 'тривалість',
        employeeId: 'id співробітника',
        managerId: 'id менеджера',
        appurtenanciesResponsibleId: 'id відповідального за обладнання',
        odometerValue: 'показник одометра',
        paymentMethod: 'метод оплати',
        recommendation: 'рекомендація',
        businessRequisiteId: 'id реквізиту бізнесу',
        servicesDiscount: 'знижка на послуги',
        stationNum: 'номер станції',
        status: 'статус',
        vehicleCondition: 'стан транспортного засобу',
        id: 'id',
        num: 'номер',
        datetime: 'дата та час',
        servicesSum: 'сума за послуги',
        detailsSum: 'сума за деталі',
        businessId: 'id бізнесу',
        viewDatetime: 'дата та час перегляду',
        deliveryDatetime: 'дата та час доставки',
        successDatetime: 'дата та час завершення',
        invoiceReportPrinted: 'звіт про рахунок-фактуру надруковано',
        actOfAcceptanceReportPrinted: 'акт приймання надруковано',
        completedWorkReportPrinted: 'звіт про виконані роботи надруковано',
        replacementType: 'тип заміни',
        discount: 'знижка',
        orderBloke: 'блок замовлень',
        systemComment: 'системний коментар',
        agreementComment: 'коментар до угоди',
        storageHashes: 'хеші зберігання',
        detailsTotalSum: 'загальна сума за деталі',
        servicesTotalSum: 'загальна сума за послуги',
        servicesSumDiscount: 'знижка на суму послуг',
        detailsSumDiscount: 'знижка на суму деталей',
        servicesPriceSum: 'сума ціни послуг',
        appurtenanciesPriceSum: 'сума ціни обладнання',
        totalSum: 'загальна сума',
        totalSumDiscount: 'знижка на загальну суму',
        onlyAppurtenanciesTotalSum: 'загальна сума тільки за обладнання',
        onlyAppurtenanciesTotalSumDiscount: 'знижка на загальну суму тільки за обладнання',
        printNumber: 'номер друку',
        agreementCompleted: 'угода виконана',
        laborTimeMultiplier: 'множник часу на роботу',
        totalSumWithTax: 'загальна сума з податком',
        tax: 'податок',
        isTaxPayer: 'платник податків',
        clientVehicleVin: 'VIN транспортного засобу клієнта',
        bodyId: 'id кузова',
        businessLocationId: 'id розташування бізнесу',
        businessLocationName: 'назва розташування бізнесу',
        remainingSum: 'залишкова сума',
        orderImgId: 'id зображення замовлення',
        orderImgVehicleType: 'тип транспортного засобу за зображенням замовлення',
        subStatus: 'допоміжний статус',
        subStatusName: 'назва допоміжного статусу',
        vehicleImageId: 'id зображення транспортного засобу',
        clientVehicleTypeId: 'id типу транспортного засобу клієнта',
        clientVehicleRadius: 'радіус транспортного засобу клієнта',
        vehicleTypeName: 'назва типу транспортного засобу',
        normHourPrice: 'ціна за нормо-годину',
        aggregateId: 'id агрегату',
        aggregateCode: 'код агрегату',
        aggregateParentCode: 'код батьківського агрегату',
        aggregateLevel: 'рівень агрегату',
        aggregateName: 'назва агрегату',
        aggregateBrandId: 'id бренду агрегату',
        aggregateBrandName: 'назва бренду агрегату',
        aggregateNumber: 'номер агрегату',
        aggregateCount: 'кількість агрегатів',
        aggregateComment: 'коментар до агрегату',
        aggregateVin: 'VIN агрегату',
        barcode: 'штрих-код'
    },
    businessSupplier: {
        id: 'id',
        name: 'назва',
        businessId: 'id бізнесу',
        contactName: 'ім\'я контакту',
        contactMiddlename: 'по-батькові контакту',
        contactSurname: 'прізвище контакту',
        phones: 'телефони',
        emails: 'електронні пошти',
        paymentRespite: 'термін оплати',
        comment: 'коментар',
        type: 'тип',
        accountId: 'id рахунку',
        incomeOrderDocsCount: 'кількість документів доходу',
        expenseOrderDocsCount: 'кількість документів витрат',
        accountName: 'назва рахунку',
        hide: 'сховати',
        requisites: {
            $id: 'id',
            $businessSupplierId: 'id постачальника бізнесу',
            $enabled: 'активований',
            $name: 'назва',
            $address: 'адреса',
            $bank: 'банк',
            $ifi: 'Код Банку',
            $ca: 'IBAN',
            $itn: 'Код ЄДРПОУ',
            $formType: 'тип форми',
            $formName: 'назва форми',
            $isTaxPayer: 'платник податків',
            $calculationMethod: 'метод розрахунку',
            $taxRate: 'ставка податку',
            $ipn: 'ІПН',
            $certificateNumber: 'номер сертифікату',
            $contractNumber: 'номер контракту',
            $contractDate: 'дата контракту',
            $director: 'директор',
            $accountID: 'ID рахунку',
            $disabled: 'неактивований',
            $accountName: 'назва рахунку'
        }
    },
    servicesSum: 'servicesSum'
};
