/*
This module shows Load KPI report.
*/
import { Layout } from 'commons';
import { ReportLoadKPIFilter, ReportLoadKPITable } from 'components';
import {
    fetchReportLoadKPI,
    setReportLoadKPIDoneFromDate,
    setReportLoadKPIDoneToDate,
    setReportLoadKPIPage,
    setReportLoadKPIQuery
} from 'core/reports/reportLoadKPI/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Stats from './Stats';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    tableData: state.reportLoadKPI.tableData,
    filter: state.reportLoadKPI.filter,
    stats: state.reportLoadKPI.stats,
    reportLoadKPIFetching: state.ui.reportLoadKPIFetching
});

const mapDispatchToProps = {
    fetchReportLoadKPI,
    setReportLoadKPIPage,
    setReportLoadKPIDoneFromDate,
    setReportLoadKPIDoneToDate,
    setReportLoadKPIQuery
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class ReportLoadKPIPage extends Component {
    constructor(props) {
        super(props);

        this.onTablePage = this.onTablePage.bind(this);
    }

    componentDidMount() {
        this.props.fetchReportLoadKPI();
    }

    onTablePage(page) {
        const { fetchReportLoadKPI, setReportLoadKPIPage } = this.props;
        setReportLoadKPIPage(page);
        fetchReportLoadKPI();
    }

    render() {
        const {
            filter,
            tableData,
            stats,
            setReportLoadKPIDoneFromDate,
            setReportLoadKPIDoneToDate,
            fetchReportLoadKPI,
            setReportLoadKPIQuery,
            reportLoadKPIFetching
        } = this.props;

        const filterControls = {
            setReportLoadKPIDoneFromDate,
            setReportLoadKPIDoneToDate,
            fetchReportLoadKPI,
            setReportLoadKPIQuery
        };

        return (
            <Layout paper title={<FormattedMessage id='navigation.report_load_kpi' />}>
                <div className={Styles.mainCont}>
                    <div className={Styles.header}>
                        <div className={Styles.filterCont}>
                            <ReportLoadKPIFilter
                                disabled={reportLoadKPIFetching}
                                filter={filter}
                                filterControls={filterControls}
                            />
                        </div>
                        <div className={Styles.statsCont}>
                            <Stats stats={stats} />
                        </div>
                    </div>
                </div>

                <ReportLoadKPITable
                    filter={filter}
                    isFetching={reportLoadKPIFetching}
                    onPageChange={this.onTablePage}
                    stats={stats}
                    tableData={tableData}
                />
            </Layout>
        );
    }
}
