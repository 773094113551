import { Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import React from 'react';

export default class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        // Use default values if some of them were not provided but are required
        const {
            width = '50vh',
            visible = false,
            onOk,
            onCancel,
            title,

            modalContent
        } = this.props;

        return (
            <Modal
                onCancel={onCancel}
                maskClosable={false}
                onOk={onOk}
                title={title}
                visible={visible === MODALS.CONFIRM}
                width={width}
            >
                {modalContent}
            </Modal>
        );
    }
}
