import { DatePicker } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DEF_BACK_DATE, DEF_UI_DATE } from 'modals/DebtZeroingModal/constants';
import { selectDateRange, setDateRange } from 'modals/DebtZeroingModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    dateRange: selectDateRange(state)
});

const mapDispatchToProps = {
    setDateRange
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class DateRangeSelector extends React.Component {
    /**
     * Format and set values into redux state
     * @param {*} dateRange array of dayjs values
     */
    onDateRangeChange = dateRange => {
        const { setDateRange } = this.props;

        const startDate = _.get(dateRange, '[0]');
        const endDate = _.get(dateRange, '[1]');

        setDateRange({
            startDate: startDate && startDate.format(DEF_BACK_DATE),
            endDate: endDate && endDate.format(DEF_BACK_DATE)
        });
    };

    render() {
        const {
            dateRange,
            intl: { formatMessage }
        } = this.props;

        const dateRangeValue =
            dateRange.startDate && dateRange.endDate
                ? [dayjs(dateRange.startDate), dayjs(dateRange.endDate)]
                : undefined;

        return (
            <div title={formatMessage({ id: 'debt_zeroing_modal.hint_date' })}>
                <DateRangePicker
                    allowClear
                    dateFormat={DEF_UI_DATE}
                    dateRange={dateRangeValue}
                    minimize
                    onDateChange={range => this.onDateRangeChange(range)}
                    overlayStyle={{ zIndex: 5555 }}
                />
            </div>
        );
    }
}
