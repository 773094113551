exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__table---1MYO4{background-color:#fff;min-width:0!important}.styles-m__table---1MYO4 .ant-table-header{text-transform:uppercase;min-width:0!important;font-size:.8em;font-style:bold;padding:0}.styles-m__table---1MYO4 .ant-pagination{float:none;justify-content:center;display:flex}.styles-m__table---1MYO4 th{padding:6px 4px!important}.styles-m__clientPhone---9j5DL{color:var(--primary);padding:4px}.styles-m__filtersCont---3kP3U{display:flex;justify-content:center;margin-bottom:5px}.styles-m__textCont---3UIrv{display:flex;justify-content:flex-end;padding:5px;align-items:center}.styles-m__inputCont---31VYD{width:100%}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACI,qBAAuB,CACvB,qBACJ,CAEA,2CACI,wBAAyB,CACzB,qBAAuB,CACvB,cAAgB,CAChB,eAAgB,CAChB,SACJ,CAEA,yCACI,UAAW,CACX,sBAAuB,CACvB,YACJ,CAEA,4BACI,yBACJ,CAEA,+BACI,oBAAqB,CACrB,WACJ,CAEA,+BACI,YAAa,CACb,sBAAuB,CACvB,iBACJ,CAEA,4BACI,YAAa,CACb,wBAAyB,CACzB,WAAY,CACZ,kBACJ,CAEA,6BACI,UACJ","file":"styles.m.css","sourcesContent":[".table {\n    background-color: white;\n    min-width: 0 !important;\n}\n\n.table :global(.ant-table-header) {\n    text-transform: uppercase;\n    min-width: 0 !important; /*IMPORTANT it solves antd table issue when scrolling is added*/\n    font-size: 0.8em;\n    font-style: bold;\n    padding: 0;\n}\n\n.table :global(.ant-pagination) {\n    float: none;\n    justify-content: center;\n    display: flex;\n}\n\n.table :global(th) {\n    padding: 6px 4px 6px 4px !important; /*Remove bug: when text overflows, header shifts left*/\n}\n\n.clientPhone {\n    color: var(--primary);\n    padding: 4px;\n}\n\n.filtersCont {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 5px;\n}\n\n.textCont {\n    display: flex;\n    justify-content: flex-end;\n    padding: 5px;\n    align-items: center;\n}\n\n.inputCont {\n    width: 100%;\n}"]}]);

// Exports
exports.locals = {
	"table": "styles-m__table---1MYO4",
	"clientPhone": "styles-m__clientPhone---9j5DL",
	"filtersCont": "styles-m__filtersCont---3kP3U",
	"textCont": "styles-m__textCont---3UIrv",
	"inputCont": "styles-m__inputCont---31VYD"
};