import { Form } from '@ant-design/compatible';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Popover, Row, Select, notification } from 'antd';
import { Barcode } from 'components';
import { createOrderForClient } from 'core/client/duck';
import {
    handleError,
    onChangeClientVehicleForm,
    setEditVehicle,
    setEditableItem,
    setSelectedVehicle
} from 'core/forms/editClientVehicleForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { AddClientVehicleForm } from 'forms';
import { DecoratedCheckbox, DecoratedInput, DecoratedInputNumber, DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import { ClientVehicleTransfer } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { businessTypes, getBusinessType, isForbidden, permissions, withReduxForm2 } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description
    });
};

@withRouter
@injectIntl
@withReduxForm2({
    name: 'clientVehicleForm',
    actions: {
        change: onChangeClientVehicleForm,
        setEditableItem,
        setEditVehicle,
        setSelectedVehicle,
        handleError,
        createOrderForClient,
        setModal
    },
    mapStateToProps: state => ({
        user: state.auth,
        isMobile: state.ui.views.isMobile
    })
})
export class EditClientVehicleForm extends Component {
    constructor(props) {
        super(props);

        this.apiErrorsMap = {
            REFERENCE_VIOLATION: props.intl.formatMessage({
                id: 'reference_violation'
            })
        };
    }

    renderSubmitEditIcon = (clientId, item, index, selectedVehicle) => {
        const canBeSaved = (selectedVehicle || item).modelId;

        return canBeSaved ? (
            <SaveOutlined
                className={Styles.saveIcon}
                onClick={() => {
                    this.props.form.validateFields([`clientVehicles[${index}]`], (err, values) => {
                        if (!err) {
                            const vehicleModelId = (selectedVehicle || item).modelId;
                            const vehicleModificationId = (selectedVehicle || item).modificationId;

                            this.props.updateClientVehicle(item.id, clientId, {
                                ...values.clientVehicles[index],
                                vehicleModelId,
                                vehicleModificationId
                            });

                            this.props.setEditableItem(null);
                        }
                    });
                }}
            />
        ) : null;
    };

    /**
     * When we want to create a new order for this client and use specific vehicle
     * @param {*} param0 {vehicle} //Contains vehicle entity which has to be included in a new order
     */
    onCreateOrderForClient = ({ vehicle }) => {
        const { createOrderForClient, clientEntity, user } = this.props;

        createOrderForClient({
            clientId: clientEntity.clientId,
            managerId: user.id,
            vehicleId: vehicle.id,
            vehicleTypeId: vehicle.vehicleTypeId,
            wheelRadius: vehicle.wheelRadius
        });
    };

    render() {
        const {
            clientEntity,
            editableItem,
            editVehicle,
            clientId,
            fetchClient,
            selectedVehicle,
            errors,
            user,
            vehicleTypes,
            disabled,

            history,

            setSelectedVehicle,
            setEditVehicle,
            setModal,
            isMobile,
            clientVehicles
        } = this.props;
        const businessType = getBusinessType();
        const isEditForbidden = disabled;
        const isViewVehicleForbidden = businessType === businessTypes.TIRE_STATION;

        if (errors.length) {
            const currentComponentErrors = errors.filter(({ response }) =>
                _.keys(this.apiErrorsMap).includes(_.get(response, 'message'))
            );

            currentComponentErrors.forEach(componentError => {
                const description = this.apiErrorsMap[componentError.response.message];

                openNotificationWithIcon(
                    'error',
                    this.props.intl.formatMessage({
                        id: 'package-container.error'
                    }),
                    description
                );
                this.props.handleError(componentError.id);
            });
        }

        const vehicleLabel = (item, index) => {
            if (selectedVehicle && index === editableItem) {
                const { makeName, modelName, modificationName } = selectedVehicle;

                return (
                    <label>
                        <s>{`${item.make} ${item.model}`}</s> {`${makeName} ${modelName} ${modificationName}`}
                    </label>
                );
            }

            if (!item.model) {
                return <label>----</label>;
            }

            return <label>{`${item.make} ${item.model}${item.modification ? ` ${item.modification}` : ''}`}</label>;
        };

        return (
            <List
                bordered
                className={Styles.list}
                dataSource={clientVehicles}
                renderItem={(item, index) => (
                    <List.Item className={Styles.listItem}>
                        <Form className={Styles.form}>
                            {!isMobile ? (
                                <Row align='bottom' className={Styles.vehicleItem} gutter={8} type='flex'>
                                    <Col span={4}>
                                        <a
                                            onClick={() => {
                                                !isViewVehicleForbidden && history.push(`${book.vehicle}/${item.id}`);
                                            }}
                                        >
                                            {vehicleLabel(item, index)}{' '}
                                        </a>
                                        {editableItem === index && !editVehicle && (
                                            <Button icon={<SwapOutlined />} onClick={() => setEditVehicle(true)} />
                                        )}
                                        {editableItem === index && editVehicle && (
                                            <Modal
                                                footer={null}
                                                maskClosable={false}
                                                onCancel={() => setEditVehicle(false)}
                                                style={{ minWidth: '950px' }}
                                                visible
                                            >
                                                <AddClientVehicleForm
                                                    addClientVehicle={vehicle => {
                                                        setSelectedVehicle(vehicle);
                                                    }}
                                                    editableVehicle={item}
                                                    onlyVehicles
                                                />
                                            </Modal>
                                        )}
                                    </Col>
                                    {/* --------------------------------------------------------------------------- */}
                                    <Col span={1}>
                                        <DecoratedCheckbox
                                            disabled={editableItem !== index}
                                            field={`clientVehicles[${index}].enabled`}
                                            fields={{}}
                                            getFieldDecorator={this.props.form.getFieldDecorator}
                                            initialValue={!item.disabled}
                                        />
                                    </Col>
                                    {/* --------------------------------------------------------------------------- */}
                                    <Col span={3}>
                                        {editableItem === index ? (
                                            <DecoratedInput
                                                className={Styles.editClientVehicleFormItem}
                                                field={`clientVehicles[${index}].vehicleNumber`}
                                                formItem
                                                getFieldDecorator={this.props.form.getFieldDecorator}
                                                hasFeedback
                                                initialValue={item.number}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: this.props.intl.formatMessage({
                                                            id: 'required_field'
                                                        })
                                                    }
                                                ]}
                                            />
                                        ) : (
                                            item.number
                                        )}
                                    </Col>
                                    {/* --------------------------------------------------------------------------- */}
                                    <Col span={4}>
                                        {editableItem === index ? (
                                            <DecoratedInput
                                                className={Styles.editClientVehicleFormItem}
                                                field={`clientVehicles[${index}].vehicleVin`}
                                                fields={{}}
                                                formItem
                                                getFieldDecorator={this.props.form.getFieldDecorator}
                                                initialValue={item.vin}
                                            />
                                        ) : (
                                            item.vin
                                        )}
                                    </Col>
                                    {/* --------------------------------------------------------------------------- */}
                                    {vehicleTypes && (
                                        <Col span={3}>
                                            {editableItem === index ? (
                                                <DecoratedSelect
                                                    className={Styles.editClientVehicleFormItem}
                                                    field={`clientVehicles[${index}].vehicleTypeId`}
                                                    fields={{}}
                                                    formItem
                                                    getFieldDecorator={this.props.form.getFieldDecorator}
                                                    initialValue={item.vehicleTypeId}
                                                    placeholder={<FormattedMessage id='tire.vehicleType' />}
                                                    showSearch
                                                >
                                                    ({(id, name, defaultRadius)}) => (
                                                    <Option key={id} radius={defaultRadius} value={id}>
                                                        {name}
                                                    </Option>
                                                    ) )}
                                                </DecoratedSelect>
                                            ) : (
                                                item.vehicleTypeName
                                            )}
                                        </Col>
                                    )}
                                    {vehicleTypes && (
                                        <Col span={2}>
                                            {editableItem === index ? (
                                                <DecoratedInputNumber
                                                    className={Styles.editClientVehicleFormItem}
                                                    field={`clientVehicles[${index}].wheelRadius`}
                                                    formatter={value => `${Math.round(value)}R`}
                                                    formItem
                                                    getFieldDecorator={this.props.form.getFieldDecorator}
                                                    initialValue={item.wheelRadius}
                                                    min={0}
                                                    parser={value => value.replace('R', '')}
                                                />
                                            ) : item.wheelRadius ? (
                                                `${item.wheelRadius}R`
                                            ) : null}
                                        </Col>
                                    )}
                                    {/* --------------------------------------------------------------------------- */}
                                    <Col span={3}>
                                        {editableItem === index ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <DecoratedInput
                                                    className={Styles.editClientVehicleFormItem}
                                                    disabled
                                                    field={`clientVehicles[${index}].barcode`}
                                                    getFieldDecorator={this.props.form.getFieldDecorator}
                                                    hasFeedback
                                                    initialValue={item.barcode}
                                                />
                                                <Barcode
                                                    iconStyle={{
                                                        fontSize: 24,
                                                        marginLeft: 4
                                                    }}
                                                    onConfirm={() => {
                                                        fetchClient(clientId);
                                                    }}
                                                    prefix='CVH'
                                                    referenceId={item.id}
                                                    table='CLIENTS_VEHICLES'
                                                    value={item.barcode}
                                                />
                                            </div>
                                        ) : (
                                            item.barcode && (
                                                <Barcode
                                                    iconStyle={{
                                                        fontSize: 24
                                                    }}
                                                    value={item.barcode}
                                                />
                                            )
                                        )}
                                    </Col>
                                    {/* --------------------------------------------------------------------------- */}
                                    <Col span={1}>
                                        {!isEditForbidden ? (
                                            editableItem === index ? (
                                                this.renderSubmitEditIcon(clientId, item, index, selectedVehicle)
                                            ) : (
                                                <EditOutlined
                                                    className={Styles.editIcon}
                                                    onClick={
                                                        () => {
                                                            setModal(MODALS.VEHICLE, {
                                                                mode: 'EDIT',
                                                                vehicleId: item.id,
                                                                onSubmit: () => {
                                                                    fetchClient(clientId);
                                                                }
                                                            });
                                                        }
                                                        // this.props.setEditableItem(
                                                        //     index,
                                                        // )
                                                    }
                                                />
                                            )
                                        ) : null}
                                    </Col>
                                    {/* --------------------------------------------------------------------------- */}
                                    <Col span={1}>
                                        {isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE
                                        ) && !isEditForbidden ? (
                                            <ClientVehicleTransfer
                                                clientId={clientId}
                                                vehicleId={item.id}
                                                vehicles={clientEntity.vehicles}
                                            />
                                        ) : null}
                                    </Col>
                                    {/* --------------------------------------------------------------------------- */}
                                    <Col span={1}>
                                        {!isEditForbidden ? (
                                            <DeleteOutlined
                                                className={Styles.deleteIcon}
                                                onClick={() => this.props.deleteClientVehicle(clientId, item.id)}
                                            />
                                        ) : null}
                                    </Col>
                                    {/* --------------------------------------------------------------------------- */}
                                    <Col span={1}>
                                        <Popover
                                            content={
                                                <FormattedMessage id='client_page.hint_create_order_with_client_and_vehicle' />
                                            }
                                        >
                                            <Button
                                                disabled={isForbidden(user, permissions.CREATE_ORDER)}
                                                onClick={() => this.onCreateOrderForClient({ vehicle: item })} // Call with current vehicle
                                                type='primary'
                                            >
                                                <PlusOutlined className={Styles.newOrderIcon} />
                                            </Button>
                                        </Popover>
                                    </Col>
                                </Row>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div>{vehicleLabel(item, index)} </div>
                                    <div>
                                        <EditOutlined
                                            className={Styles.editIcon}
                                            onClick={
                                                () => {
                                                    setModal(MODALS.VEHICLE, {
                                                        mode: 'EDIT',
                                                        vehicleId: item.id,
                                                        onSubmit: () => {
                                                            fetchClient(clientId);
                                                        }
                                                    });
                                                }
                                                // this.props.setEditableItem(
                                                //     index,
                                                // )
                                            }
                                        />
                                        <Popover
                                            content={
                                                <FormattedMessage id='client_page.hint_create_order_with_client_and_vehicle' />
                                            }
                                        >
                                            <PlusOutlined
                                                // Call with current vehicle
                                                onClick={() => this.onCreateOrderForClient({ vehicle: item })}
                                                style={{
                                                    fontSize: 24,
                                                    marginLeft: 8
                                                }}
                                            />
                                        </Popover>
                                    </div>
                                </div>
                            )}
                        </Form>
                    </List.Item>
                )}
                size='small'
            />
        );
    }
}
