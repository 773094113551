import { Button, Col, Row } from 'antd';
import { Catcher } from 'commons';
import { RequisiteSettingContainer } from 'containers';
import {
    // createClientRequisite,
    // updateClientRequisite,
    // deleteClientRequisite,
    hideForms,
    setCreateClientRequisiteForm,
    setEditClientRequisiteId
} from 'core/clientRequisite/duck';
import {
    deleteClientRequisite,
    getClientData,
    postClientRequisite,
    updateClientRequisite
} from 'core/requisiteSettings/saga';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    editClientRequisiteId: state.clientRequisites.editClientRequisiteId,
    createClientRequisiteForm: state.clientRequisites.createClientRequisiteForm
});

const mapDispatchToProps = {
    setCreateClientRequisiteForm,
    setEditClientRequisiteId,
    // createClientRequisite,
    // updateClientRequisite,
    // deleteClientRequisite,
    hideForms
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientRequisitesContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            requisiteData: undefined,
            dataSource: undefined,
            clientReq: true
        };
        this.hideModal = this.hideModal.bind(this);
    }

    showModal = (requisiteData = undefined) => {
        this.setState({
            modalVisible: true,
            requisiteData
        });
    };

    hideModal(isSubmitted) {
        this.setState({
            modalVisible: false,
            requisiteData: undefined
        });
        if (isSubmitted) this.updateDataSource();
    }

    componentDidMount() {
        this.updateDataSource();
    }

    updateDataSource = async () => {
        await this.setState({
            dataSource: undefined,
            loading: true
        });
        const requisites = await getClientData(this.props.clientId);
        this.setState({
            modalVisible: false,
            requisiteData: undefined,
            dataSource: requisites || [],
            loading: false
        });
    };

    render() {
        const {
            requisites,
            createClientRequisiteForm,
            editClientRequisiteId,
            // updateClientRequisite,
            // createClientRequisite,
            // deleteClientRequisite
            clientId,
            disabled
        } = this.props;
        const { modalVisible, dataSource, requisiteData, loading, clientReq } = this.state;

        return (
            <Catcher>
                <Row align='top' className={Styles.header} justify='space-between' type='flex'>
                    <Col>
                        <h2 className={Styles.title}>
                            <FormattedMessage id='client_requisites_container.requisites_list' />
                        </h2>
                    </Col>
                    <Col>
                        <Button disabled={disabled} onClick={() => this.showModal()} type='primary'>
                            <FormattedMessage id='client_requisites_container.create' />
                        </Button>
                    </Col>
                </Row>

                <RequisiteSettingContainer
                    clientReq={clientReq}
                    dataSource={dataSource}
                    deleteRequisite={deleteClientRequisite}
                    disabled={disabled}
                    hideModal={this.hideModal}
                    id={clientId}
                    loading={loading}
                    modalVisible={modalVisible}
                    postRequisite={postClientRequisite}
                    requisiteData={requisiteData}
                    showModal={this.showModal}
                    updateDataSource={this.updateDataSource}
                    updateRequisite={updateClientRequisite}
                />
            </Catcher>
        );
    }
}
