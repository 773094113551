/* Constants */

export const moduleName = 'orderStatsInfoModal';
const prefix = `cpb/${moduleName}`;

export const FETCH_ORDER_STATS = `${prefix}/FETCH_ORDER_STATS`;
export const FETCH_ORDER_STATS_SUCCESS = `${prefix}/FETCH_ORDER_STATS_SUCCESS`;

export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const RESET_FILTERS = `${prefix}/RESET_FILTERS`;

export const SET_FETCH_SUBTASKS_FETCHING = `${prefix}/SET_FETCH_SUBTASKS_FETCHING`;

/** Date format for backend */
export const DEF_DATE_FORMAT = 'YYYY/MM/DD';

/* Reducer */
const ReducerState = {
    orderStats: {}
    //
    // filters: {
    //     onlyNew: true,
    //     page: 1,
    // },
    // fetching: false,
    // subtasks: [],
    // stats: {}
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_ORDER_STATS_SUCCESS:
            return {
                ...state,
                orderStats: payload
            };

        //
        // case SET_FETCH_SUBTASKS_FETCHING:
        //     return {
        //         ...state,
        //         fetching: payload
        //     };
        //
        // case RESET_FILTERS:
        //     return {
        //         ...state,
        //         filters: {
        //             page: 1,
        //         }
        //     };
        //
        // case FETCH_SUBTASKS_SUCCESS:
        //     return {
        //         ...state,
        //         subtasks: payload.employeeTasks,
        //         stats: payload.stats,
        //     }
        default:
            return state;
    }
}

/* Selectors */

export const selectOrderStats = state => state[moduleName].orderStats;
// export const selectSubtasks = state => state[ moduleName ].subtasks;
// export const selectStats = state => state[ moduleName ].stats;
// export const selectFetching = state => state[ moduleName ].fetching;

/* Actions */

// export const setFilters = filters => ({
//     type:    SET_FILTERS,
//     payload: filters,
// });

// export const setFilters = (filters) => {
//
//     return (dispatch) => {
//         dispatch({
//             type: SET_FILTERS,
//             payload: filters
//         });
//
//         return dispatch(fetchSubtasks());
//     }
// }

export const fetchOrderStats = (id, recalculateStats) => ({
    type: FETCH_ORDER_STATS,
    payload: { id, recalculateStats }
});

export const fetchOrderStatsSuccess = data => ({
    type: FETCH_ORDER_STATS_SUCCESS,
    payload: data
});
//
// export const setFetching = value => ({
//     type:    SET_FETCH_SUBTASKS_FETCHING,
//     payload: value,
// });
//
// export const resetFields = () => ({
//     type:    RESET_FILTERS,
// });
