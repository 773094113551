import { MenuOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, notification } from 'antd';
import { setDocumentTitle, setPrint } from 'core/ui/duck';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getStorageToken } from 'utils';
import { decompressTemplate } from '../../../../../../shared/utils/utils';
import chassisImage from '../../assets/chassis_diagnostics_p_with_watermark.png';
import chassisImageTruck from '../../assets/truck_chassis_diagnostics_p_with_watermark.png';
import ChassisCalculationModal from '../ChassisCalculationModal';
import ChassisDiagnosticTable from './ChassisDiagnosticTable';
import DiagnosticUnitRectangle from './DiagnosticUnitRectangle';
import UnitsDrawer from './UnitsDrawer';
import Styles from './styles.m.css';

const chassisSchemeMapping = {
    1: { image: chassisImage, prefix: 'scheme', mobileImageSizes: { width: 2047, height: 2420, coefficient: 1.07 } },
    2: {
        image: chassisImageTruck,
        prefix: 'schemeTruck',
        mobileImageSizes: { width: 2048, height: 3661, coefficient: 0.99 }
    }
};

const ChassisDiagnosticTab = ({
    orderId,
    isMobile,
    reloadOrderForm,
    intl: { formatMessage },
    fetchFileList,
    bussinessStats,
    orderNum,
    disabledPhoto,
    setPrint,
    schemeId,
    setDocumentTitle
}) => {
    const [intrinsicDimensions, setIntrinsicDimensions] = useState([0, 0]);
    const [aspectRatio, setAspectRatio] = useState(0);
    const [diagnosticSchemes, setDiagnosticSchemes] = useState([]);
    const [selectedDiagnosticScheme, setSelectedDiagnosticScheme] = useState({ units: [] });
    const [orderUnits, setOrderUnits] = useState([]);
    const [unitsChange, setUnitsChange] = useState(true);
    const [calculationModalOpen, setCalculationModalOpen] = useState(false);
    const [unitsDrawerOpen, setUnitsDrawerOpen] = useState(false);
    const [photoLoading, setPhotoLoading] = useState(false);

    const imgRef = useRef();

    const onImgLoad = () => {
        const img = new Image();
        img.src = chassisSchemeMapping[schemeId].image;
        setIntrinsicDimensions([img.naturalWidth, img.naturalHeight]);
    };

    const fetchChassisDefault = useCallback(async () => {
        const response = await fetchAPI('GET', '/chassis_diagnostics', { schemeId }, null, {
            handleErrorInternally: true
        });
        setDiagnosticSchemes(response);
        setSelectedDiagnosticScheme(response[0] || { units: [] });
    }, []);

    const fetchOrderChassis = useCallback(async () => {
        const response = await fetchAPI('GET', '/order_scheme_diagnostics', { orderId, schemeId }, null, {
            handleErrorInternally: true
        });
        setOrderUnits(response);
    }, [orderId]);

    const putOrderScheme = useCallback(
        async (unit, newData = {}, method = 'POST') => {
            try {
                await fetchAPI(
                    method,
                    '/order_scheme_diagnostics',
                    null,
                    {
                        schemes: [
                            {
                                comment:
                                    unit.comment && unit.comment.comment
                                        ? unit.comment
                                        : { comment: unit.comment, positions: unit.commentArray },
                                status: unit.status,
                                ...newData,
                                orderId,
                                schemeId: selectedDiagnosticScheme.id,
                                unitId: unit.unitId || unit.id
                            }
                        ]
                    },
                    {
                        handleErrorInternally: true
                    }
                );
                setUnitsChange(true);
            } catch (e) {
                notification.error({ message: formatMessage({ id: 'error' }) });
            }
        },
        [formatMessage, orderId, selectedDiagnosticScheme.id]
    );

    const deleteOrderUnits = useCallback(
        async ids => {
            const orderIdNumber = Number(orderId);
            await fetchAPI(
                'DELETE',
                '/order_scheme_diagnostics',
                null,
                {
                    ids
                    // orderId: orderIdNumber
                },
                {
                    handleErrorInternally: true
                }
            );
            setUnitsChange(true);
        },
        [orderId]
    );

    const printChassis = useCallback(
        async type => {
            try {
                const response = await fetchAPI('GET', `/orders/reports/${type}/${orderId}`, null, null, {
                    handleErrorInternally: true,
                    rawResponse: true
                });
                const contentDispositionHeader = response.headers.get('content-disposition');
                const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
                setDocumentTitle(fileName);
                const arrayBuffer = await response.arrayBuffer();
                const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
                const reportFile = await decompressTemplate(content);
                await setPrint(reportFile);
            } catch (e) {
                notification.error({ message: formatMessage({ id: 'error' }) });
            }
        },
        [formatMessage, orderId]
    );
    // () => printChassis('schemeDiagnosticsReport')
    const reportItems = useMemo(() => {
        const { prefix } = chassisSchemeMapping[schemeId];

        return [
            {
                key: `${prefix}DiagnosticsMechanicReport`,
                label: formatMessage({ id: 'mechanic_without_names' })
            },
            {
                key: `${prefix}DiagnosticsEducationalReport`,
                label: formatMessage({ id: 'mechanic_with_names' })
            },
            {
                key: `${prefix}DiagnosticsReport`,
                label: formatMessage({ id: 'client' })
            }
        ];
    }, [formatMessage, schemeId]);

    const handleCalculationOpen = useCallback(() => {
        setCalculationModalOpen(true);
    }, []);

    const handleCalculationClose = () => {
        setCalculationModalOpen(false);
    };

    const handleUnitsDrawerClose = e => {
        setUnitsDrawerOpen(false);
        e.stopImmediatePropagation();
    };
    const handleUnitsDrawerOpen = () => {
        setUnitsDrawerOpen(true);
    };

    useLayoutEffect(() => {
        const updateSize = () => {
            setAspectRatio(imgRef.current.width / intrinsicDimensions[0]);
        };
        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, [intrinsicDimensions]);

    const unitsInOrder = useMemo(() => {
        // const { units } = selectedDiagnosticScheme;
        // const orderUnitsMap = Object.fromEntries(orderUnits.map(orderUnit => [orderUnit.unitId, orderUnit]));
        // const unitsInOrder = units.filter(
        //     ({ id }) =>
        //         orderUnitsMap[id] &&
        //         (orderUnitsMap[id].status === 2 || orderUnitsMap[id].status === 3) &&
        //         !orderUnitsMap[id].blocked
        // );
        const { units } = selectedDiagnosticScheme;
        if (units.length) {
            const orderUnitsMap = Object.fromEntries(orderUnits.map(orderUnit => [orderUnit.unitId, orderUnit]));
            const unitsInOrder = orderUnits
                .filter(({ status, blocked }) => (status === 2 || status === 3) && !blocked)
                .map(orderUnit => {
                    const unit = units.find(({ id }) => orderUnit.unitId === id);

                    return {
                        ...orderUnit,
                        labors: unit.labors.map(labor => ({ ...labor, comment: orderUnit.comment })),
                        storeGroups: unit.storeGroups.map(storeGroup => ({ ...storeGroup, comment: orderUnit.comment }))
                    };
                });

            return unitsInOrder;
        }

        return [];
    }, [orderUnits, selectedDiagnosticScheme]);

    const downloadPhoto = async (file, id, text) => {
        setPhotoLoading(true);
        const url = `${__GALLARY_IMAGES_URL__}/api/upload_file`;

        const formData = new FormData();

        formData.append('file', file);
        formData.append('type', 'img');
        formData.append('name', file.name);
        formData.append('dgn', id);
        formData.append('dgnTxt', text);
        formData.append('ord', orderId);
        formData.append('ordTxt', orderNum);
        // formData.append('ord', 1);
        // formData.append('det', 2);
        // formData.append('dgn', 3);

        // formData.append('ordTxt', 'MRD-1174-1');
        // formData.append('detTxt', 'product-code-1 brand-1');
        // formData.append('dgnTxt', 'change left wheel');

        console.log(formData, 'form');

        await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: getStorageToken()
            },
            body: formData
        }).then(function (response) {
            if (response.status !== 200) {
                return notification.error({
                    message: `${formatMessage({
                        id: 'gallary.not_enought_space'
                    })}!`
                });
            }
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });

            return Promise.resolve(response);
        });

        await fetchFileList();
        await bussinessStats();

        setPhotoLoading(false);
    };

    useEffect(() => {
        if (unitsChange) {
            fetchOrderChassis();
            setUnitsChange(false);
        }
    }, [unitsChange, fetchOrderChassis]);

    useEffect(() => {
        fetchChassisDefault();
    }, [fetchChassisDefault]);

    return (
        <React.Fragment>
            <Flex gap={8} vertical>
                <Flex gap={8} justify='flex-end'>
                    <Button onClick={handleCalculationOpen}>
                        <FormattedMessage id='calculation' />
                    </Button>
                    <Button icon={<MenuOutlined />} onClick={handleUnitsDrawerOpen} />
                    <Dropdown menu={{ items: reportItems, onClick: ({ key }) => printChassis(key) }}>
                        <Button icon={<PrinterOutlined />} />
                    </Dropdown>
                </Flex>
                <div style={{ display: 'flex', gap: 8 }}>
                    <div className={Styles.imageContainer} style={isMobile ? { width: '100%' } : {}}>
                        <img
                            ref={imgRef}
                            alt='Chassis diagnostic'
                            className={Styles.image}
                            onLoad={onImgLoad}
                            src={chassisSchemeMapping[schemeId].image}
                        />
                        {selectedDiagnosticScheme.units.map(unit => (
                            <DiagnosticUnitRectangle
                                key={unit.id}
                                aspectRatio={aspectRatio}
                                disabledPhoto={disabledPhoto}
                                downloadPhoto={downloadPhoto}
                                isMobile={isMobile}
                                mobileImageSizes={chassisSchemeMapping[schemeId].mobileImageSizes}
                                orderId={orderId}
                                orderNum={orderNum}
                                orderUnits={orderUnits}
                                photoLoading={photoLoading}
                                putOrderScheme={putOrderScheme}
                                unit={unit}
                            />
                        ))}
                    </div>
                    {!isMobile && (
                        <div style={{ width: '40%' }}>
                            <ChassisDiagnosticTable
                                deleteOrderUnits={deleteOrderUnits}
                                disabledPhoto={disabledPhoto}
                                downloadPhoto={downloadPhoto}
                                orderId={orderId}
                                orderNum={orderNum}
                                orderUnits={orderUnits}
                                photoLoading={photoLoading}
                                putOrderScheme={putOrderScheme}
                            />
                        </div>
                    )}
                </div>
            </Flex>
            <ChassisCalculationModal
                onCancel={handleCalculationClose}
                open={calculationModalOpen}
                orderId={orderId}
                orderUnits={orderUnits}
                reloadOrderForm={reloadOrderForm}
                selectedDiagnosticSchemeId={selectedDiagnosticScheme.id}
                setUnitsChange={setUnitsChange}
                unitsInOrder={unitsInOrder}
            />
            <UnitsDrawer
                disabledPhoto={disabledPhoto}
                downloadPhoto={downloadPhoto}
                isMobile={isMobile}
                mobileComponent={
                    <ChassisDiagnosticTable
                        deleteOrderUnits={deleteOrderUnits}
                        disabledPhoto={disabledPhoto}
                        downloadPhoto={downloadPhoto}
                        orderId={orderId}
                        orderNum={orderNum}
                        orderUnits={orderUnits}
                        photoLoading={photoLoading}
                        putOrderScheme={putOrderScheme}
                    />
                }
                onClose={handleUnitsDrawerClose}
                open={unitsDrawerOpen}
                orderId={orderId}
                orderNum={orderNum}
                orderUnits={orderUnits}
                photoLoading={photoLoading}
                putOrderScheme={putOrderScheme}
                units={selectedDiagnosticScheme.units}
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    // print: state.ui.print
    // user: state.auth,
    // isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setPrint,
    setDocumentTitle
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChassisDiagnosticTab));
