/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'suppliersPage';
const prefix = `cpb/${moduleName}`;

export const FETCH_BUSINESS_SUPPLIERS = `${prefix}/FETCH_BUSINESS_SUPPLIERS`;
export const FETCH_BUSINESS_SUPPLIERS_SUCCESS = `${prefix}/FETCH_BUSINESS_SUPPLIERS_SUCCESS`;
export const SET_BUSINESS_SUPPLIERS_FILTERS = `${prefix}/SET_BUSINESS_SUPPLIERS_FILTERS`;
export const SET_FETCHING_BUSINESS_SUPPLIERS = `${prefix}/SET_FETCHING_BUSINESS_SUPPLIERS`;

export const FETCH_STORE_DOCS = `${prefix}/FETCH_STORE_DOCS`;
export const FETCH_STORE_DOCS_SUCCESS = `${prefix}/FETCH_STORE_DOCS_SUCCESS`;
export const SET_STORE_DOCS_FILTERS = `${prefix}/SET_STORE_DOCS_FILTERS`;
export const SET_FETCHING_STORE_DOCS = `${prefix}/SET_FETCHING_STORE_DOCS`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    businessSuppliers: [],
    businessSuppliersFilters: {
        createdFromDate: undefined,
        createdToDate: undefined,
        page: 1
    },
    businessSuppliersStats: {},
    fetchingBusinessSuppliers: false,

    storeDocs: [],
    storeDocsFilters: {
        page: 1,
        supplierId: undefined
    },
    storeDocsStats: {},
    fetchingStoreDocs: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_BUSINESS_SUPPLIERS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_BUSINESS_SUPPLIERS_FILTERS:
            return {
                ...state,
                businessSuppliersFilters: {
                    ...state.businessSuppliersFilters,
                    ...payload
                }
            };
        case SET_FETCHING_BUSINESS_SUPPLIERS:
            return {
                ...state,
                fetchingBusinessSuppliers: payload
            };

        case FETCH_STORE_DOCS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_STORE_DOCS_FILTERS:
            return {
                ...state,
                storeDocsFilters: {
                    ...state.storeDocsFilters,
                    ...payload
                }
            };
        case SET_FETCHING_STORE_DOCS:
            return {
                ...state,
                fetchingStoreDocs: payload
            };

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
// business suppliers
export const selectBusinessSuppliers = state => state[moduleName].businessSuppliers;
export const selectBusinessSuppliersStats = state => state[moduleName].businessSuppliersStats;
export const selectBusinessSuppliersFilters = state => state[moduleName].businessSuppliersFilters;
export const selectFetchingBusinessSuppliers = state => state[moduleName].fetchingBusinessSuppliers;

// Store docs
export const selectStoreDocs = state => state[moduleName].storeDocs;
export const selectStoreDocsStats = state => state[moduleName].storeDocsStats;
export const selectStoreDocsFilters = state => state[moduleName].storeDocsFilters;
export const selectFetchingStoreDocs = state => state[moduleName].fetchingStoreDocs;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchBusinessSuppliers = () => ({
    type: FETCH_BUSINESS_SUPPLIERS
});

export const fetchBusinessSuppliersSuccess = ({ businessSuppliers, businessSuppliersStats }) => ({
    type: FETCH_BUSINESS_SUPPLIERS_SUCCESS,
    payload: { businessSuppliers, businessSuppliersStats }
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setBusinessSuppliersFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_BUSINESS_SUPPLIERS_FILTERS,
            payload: filters
        });
        dispatch(fetchBusinessSuppliers());
    };
};

export const setFetchingBusinessSuppliers = value => ({
    type: SET_FETCHING_BUSINESS_SUPPLIERS,
    payload: value
});

export const fetchStoreDocs = () => ({
    type: FETCH_STORE_DOCS
});

export const fetchStoreDocsSuccess = ({ storeDocs, storeDocsStats }) => ({
    type: FETCH_STORE_DOCS_SUCCESS,
    payload: { storeDocs, storeDocsStats }
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setStoreDocsFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_STORE_DOCS_FILTERS,
            payload: filters
        });
        dispatch(fetchStoreDocs());
    };
};

export const setFetchingStoreDocs = value => ({
    type: SET_FETCHING_STORE_DOCS,
    payload: value
});
