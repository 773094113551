import { Input, Modal, Select } from 'antd';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from '../styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const mapStateToProps = state => {
    return {
        user: state.auth,
        isMobile: state.ui.views.isMobile
    };
};

@injectIntl
@connect(mapStateToProps, void 0)
export class AddCommetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        const { visible, laborInfo } = this.props;
        if (!prevProps.visible && visible) {
            this.setState({
                comment: get(laborInfo, 'comment', ' ')
            });
        }
    }

    handleCancel = () => {
        const { hideModal } = this.props;
        this.setState({
            comment: undefined
        });
        hideModal();
    };

    handleOk = async () => {
        await this.saveLaborComment();
        this.handleCancel();
        this.props.fetchLabors();
    };

    saveLaborComment = async () => {
        const { laborInfo } = this.props;
        const { comment } = this.state;
        await fetchAPI('PUT', 'labors', null, [
            {
                id: get(laborInfo, 'id', null),
                masterLaborId: get(laborInfo, 'masterLaborId', null),
                storeGroupId: get(laborInfo, 'storeGroupId', null),
                crossId: get(laborInfo, 'crossId', null) || null,
                fixed: get(laborInfo, 'fixed'),
                name: get(laborInfo, 'name', null) || null,
                price: get(laborInfo, 'price', 1) || 1,
                normHours: get(laborInfo, 'normHours', 1) || 1 || 1,
                disabled: Boolean(get(laborInfo, 'disabled', false)),
                barcode: get(laborInfo, 'barcode', 1) || null,
                laborUnitId: get(laborInfo, 'laborUnitId', null) || null,
                comment: comment || null
            }
        ]);
    };

    render() {
        const { visible, laborInfo, isMobile } = this.props;
        const { comment } = this.state;

        const titleName =
            get(laborInfo, 'customName', ' ') || get(laborInfo, 'laborCode', ' ') || get(laborInfo, 'name', ' ');

        console.log(laborInfo, 'laborInfo');

        return (
            <Modal
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                maskClosable={false}
                open={visible}
                title={
                    <div>
                        <FormattedMessage id='labor.add_comment' /> {titleName}
                    </div>
                }
                width={isMobile ? '95%' : '35%'}
            >
                <div className={Styles.rowStyles}>
                    <span
                        style={{
                            marginRight: 22
                        }}
                    >
                        <FormattedMessage id='comment' />
                    </span>
                    <TextArea
                        onChange={e => {
                            this.setState({
                                comment: e.target.value
                            });
                        }}
                        style={{ color: 'var(--text)' }}
                        value={comment}
                    />
                </div>
            </Modal>
        );
    }
}
