import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Select, Table } from 'antd';
import { Catcher } from 'commons';
import { ComplexesModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { WrenchIcon } from 'theme';
import { AddServiceModal } from 'tireFitting';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const INACTIVE = 'INACTIVE';
const IN_PROGRESS = 'IN_PROGRESS';
const STOPPED = 'STOPPED';
const DONE = 'DONE';
const CANCELED = 'CANCELED';

@injectIntl
class ServicesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceModalVisible: false,
            serviceModalKey: 0,
            dataSource: []
        };

        this.updateLabor = this.updateLabor.bind(this);
        this.updateDataSource = this.updateDataSource.bind(this);
        this.masterLabors = [];
        this.laborsTreeData = [];

        this.columns = [
            {
                title: () => (
                    <div className={Styles.headerActions}>
                        {!isForbidden(
                            this.props.user,
                            permissions.ACCESS_ORDER_LABORS_COMPLEXES
                        ) && (
                            <ComplexesModal
                                details={this.props.details}
                                detailsTreeData={this.props.detailsTreeData}
                                disabled={this.props.disabled}
                                labors={this.props.labors}
                                orderId={this.props.orderId}
                                reloadOrderForm={this.props.reloadOrderForm}
                                tecdocId={this.props.tecdocId}
                            />
                        )}
                    </div>
                ),
                key: 'buttonGroup',
                dataIndex: 'key',
                render: (data, elem) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Button
                                disabled={this.props.disabled}
                                onClick={() => {
                                    this.showServiceProductModal(data);
                                }}
                                title={this.props.intl.formatMessage({
                                    id: 'labors_table.add_edit_button'
                                })}
                                icon={<WrenchIcon/>}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'serviceName',
                dataIndex: 'serviceName',
                render: data => {
                    return data || <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.master' />,
                key: 'employeeId',
                dataIndex: 'employeeId',
                render: data => {
                    const employee = this.props.employees.find(elem => elem.id == data);

                    return employee ? (
                        `${employee.surname} ${employee.name}`
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'price',
                dataIndex: 'price',
                render: data => {
                    const strVal = Number(data).toFixed(2);

                    return (
                        <span>
                            {data ? (
                                `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </span>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.count' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'count',
                dataIndex: 'count',
                render: data => {
                    const strVal = Number(data).toFixed(1);

                    return (
                        <span>
                            {data ? strVal : 0}{' '}
                            <FormattedMessage id='order_form_table.hours_short' />
                        </span>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'sum',
                dataIndex: 'sum',
                render: data => {
                    const strVal = Number(data).toFixed(2);

                    return (
                        <span>
                            {data ? (
                                `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </span>
                    );
                }
            },
            {
                key: 'delete',
                render: elem => {
                    return (
                        <Popconfirm
                            disabled={this.props.disabled}
                            onConfirm={async () => {
                                const that = this;
                                const token = localStorage.getItem('_my.carbook.pro_token');
                                let url = __API_URL__;
                                const params = `/orders/${this.props.orderId}/labors?ids=[${elem.id}] `;
                                url += params;
                                try {
                                    const response = await fetch(url, {
                                        method: 'DELETE',
                                        headers: {
                                            Authorization: token,
                                            'Content-Type': 'application/json'
                                        }
                                    });
                                    const result = await response.json();
                                    if (result.success) {
                                        that.updateDataSource();
                                    } else {
                                        console.log('BAD', result);
                                    }
                                } catch (error) {
                                    console.error('ERROR:', error);
                                }
                            }}
                            title={<FormattedMessage id='add_order_form.delete_confirm' />}
                        >
                            <DeleteOutlined
                                className={
                                    this.props.disabled ? Styles.disabledIcon : Styles.deleteIcon
                                }
                            />
                        </Popconfirm>
                    );
                }
            }
        ];
    }

    showServiceProductModal(key) {
        this.setState({
            serviceModalVisible: true,
            serviceModalKey: key
        });
    }

    hideServicelProductModal() {
        this.setState({
            serviceModalVisible: false
        });
    }

    updateDataSource() {
        if (this.state.fetched) {
            this.setState({
                fetched: false
            });
        }
        const callback = data => {
            data.orderServices.map((elem, index) => {
                elem.key = index;
            });
            this.setState({
                dataSource: data.orderServices,
                fetched: true
            });
        };
        this.props.reloadOrderForm(callback, 'labors');
    }

    async updateLabor(key, labor) {
        this.state.dataSource[key] = labor;
        const data = {
            updateMode: true,
            services: [
                {
                    id: labor.id,
                    serviceId: labor.laborId,
                    serviceName: labor.serviceName,
                    employeeId: labor.employeeId || null,
                    count: labor.count,
                    servicePrice: Math.round(labor.price * 10) / 10,
                    tireStationPriceGroupId: labor.tireStationPriceGroupId,
                    comment: labor.comment || {
                        comment: undefined,
                        positions: []
                    }
                }
            ]
        };

        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            this.updateDataSource();
        } catch (error) {
            console.error('ERROR:', error);
            this.updateDataSource();
        }
    }

    fetchLaborsTree() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/labors/master?makeTree=true`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.masterLabors = data.masterLabors;
                that.buildLaborsTree();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    buildLaborsTree() {
        const treeData = [];
        for (let i = 0; i < this.masterLabors.length; i++) {
            const parentGroup = this.masterLabors[i];
            treeData.push({
                title: `${parentGroup.defaultMasterLaborName} (#${parentGroup.masterLaborId})`,
                name: parentGroup.defaultMasterLaborName,
                value: parentGroup.masterLaborId,
                className: Styles.groupTreeOption,
                selectable: false,
                children: []
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.defaultMasterLaborName} (#${childGroup.masterLaborId})`,
                    name: childGroup.defaultMasterLaborName,
                    value: childGroup.masterLaborId,
                    className: Styles.groupTreeOption,
                    selectable: false,
                    children: []
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.defaultMasterLaborName} (#${lastNode.masterLaborId})`,
                        name: lastNode.defaultMasterLaborName,
                        value: lastNode.masterLaborId,
                        className: Styles.groupTreeOption
                    });
                }
            }
        }
        this.laborsTreeData = treeData;
        this.setState({
            update: true
        });
    }

    componentDidMount() {
        this.fetchLaborsTree();
        const tmp = [...this.props.orderServices];
        tmp.map((elem, i) => (elem.key = i));
        this.setState({
            dataSource: tmp
        });
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.activeKey != 'services' && this.props.activeKey == 'services') ||
            prevProps.orderServices != this.props.orderServices
        ) {
            const tmp = [...this.props.orderServices];
            tmp.map((elem, i) => (elem.key = i));
            this.setState({
                dataSource: tmp
            });
        }
    }

    render() {
        const { isMobile, orderFetching, clientVehicleTypeId, clientVehicleRadius } = this.props;
        if (
            !isMobile &&
            (this.state.dataSource.length == 0 ||
                this.state.dataSource[this.state.dataSource.length - 1].serviceName != undefined)
        ) {
            this.state.dataSource.push({
                key: this.state.dataSource.length,
                id: undefined,
                laborId: undefined,
                serviceName: undefined,
                count: 0,
                price: 0,
                sum: 0
            });
        }

        return (
            <Catcher>
                <Table
                    bordered
                    className={Styles.serviceTable}
                    columns={
                        !isMobile
                            ? this.columns
                            : this.columns.filter(
                                  ({ key }) =>
                                      key != 'delete' && key != 'buttonGroup' && key != 'employeeId'
                              )
                    }
                    dataSource={this.state.dataSource}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                isMobile && this.showServiceProductModal(rowIndex);
                            },
                            onDoubleClick: event => {}
                        };
                    }}
                    pagination={false}
                    size='small'
                />
                {isMobile && (
                    <div
                        style={{
                            margin: '12px 0px 8px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <ComplexesModal
                            details={this.props.details}
                            detailsTreeData={this.props.detailsTreeData}
                            disabled={this.props.disabled}
                            isMobile={isMobile}
                            labors={this.props.labors}
                            orderId={this.props.orderId}
                            reloadOrderForm={this.props.reloadOrderForm}
                            tecdocId={this.props.tecdocId}
                        />
                        <Button
                            onClick={() =>
                                this.showServiceProductModal(this.state.dataSource.length)
                            }
                            style={{
                                margin: '0px 0px 0px 8px'
                            }}
                        >
                            <FormattedMessage id='add' />
                        </Button>
                    </div>
                )}
                <AddServiceModal
                    clientVehicleRadius={clientVehicleRadius}
                    clientVehicleTypeId={clientVehicleTypeId}
                    defaultEmployeeId={this.props.defaultEmployeeId}
                    details={this.props.details}
                    detailsTreeData={this.props.detailsTreeData}
                    employees={this.props.employees}
                    hideModal={() => this.hideServicelProductModal()}
                    isMobile={isMobile}
                    labor={this.state.dataSource[this.state.serviceModalKey]}
                    labors={this.props.labors}
                    laborsTreeData={this.laborsTreeData}
                    normHourPrice={this.props.normHourPrice}
                    orderId={this.props.orderId}
                    tableKey={this.state.serviceModalKey}
                    tecdocId={this.props.tecdocId}
                    updateDataSource={this.updateDataSource}
                    updateLabor={this.updateLabor}
                    user={this.props.user}
                    visible={this.state.serviceModalVisible}
                />
            </Catcher>
        );
    }
}

export default ServicesTable;
