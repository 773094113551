// eslint-disable-next-line max-classes-per-file
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Select, Table, notification } from 'antd';
import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, isForbidden, permissions } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;
const WORK_POST = 'WORK_POST';
const INTERNAL_PARKING = 'INTERNAL_PARKING';
const EXTERNAL_PARKING = 'EXTERNAL_PARKING';
const TEST_DRIVE = 'TEST_DRIVE';
const OTHER = 'OTHER';
const TYPES = [WORK_POST, INTERNAL_PARKING, EXTERNAL_PARKING, TEST_DRIVE, OTHER];

const mapStateToProps = state => ({
    user: state.auth
});

@connect(mapStateToProps, void 0)
export default class LocationSettingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            loading: false,
            editMode: false,
            location: undefined
        };

        this.getLocations = this.getLocations.bind(this);

        this.columns = [
            {
                title: '№',
                key: 'key',
                dataIndex: 'key',
                width: '5%',
                render: (data, elem) => {
                    return data + 1;
                }
            },
            {
                title: <FormattedMessage id='locations.name' />,
                key: 'name',
                dataIndex: 'name',
                width: 'auto'
            },
            {
                title: <FormattedMessage id='locations.type' />,
                key: 'type',
                dataIndex: 'type',
                width: 'auto',
                render: (data, elem) => {
                    return data ? <FormattedMessage id={`locations.${data}`} /> : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='locations.volume' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'volume',
                dataIndex: 'volume',
                width: 'auto',
                render: (data, elem) => {
                    const volume = Number(data || 0)
                        .toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                    return volume;
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='locations.price' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'price',
                dataIndex: 'price',
                width: 'auto',
                render: (data, elem) => {
                    const price = Number(data || 0)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                    return price;
                }
            },
            {
                key: 'edit',
                width: '5%',
                render: elem => {
                    return (
                        !isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_LOCATIONS_CRUD) && (
                            <EditOutlined
                                onClick={() => {
                                    this.setState({
                                        editMode: true,
                                        location: elem
                                    });
                                }}
                                style={{ fontSize: 18 }}
                            />
                        )
                    );
                }
            },
            {
                key: 'delete',
                width: '5%',
                render: elem => {
                    return (
                        !isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_LOCATIONS_CRUD) && (
                            <DeleteOutlined
                                onClick={() => {
                                    this.deleteLocation(elem.id);
                                }}
                                style={{ fontSize: 18 }}
                            />
                        )
                    );
                }
            }
        ];
    }

    deleteLocation(id) {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/business_locations/${id}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.getLocations();
            })
            .catch(function (error) {
                console.log('error', error);
                notification.error({
                    message: that.props.intl.formatMessage({ id: 'locations.delete_error' })
                });
            });
    }

    getLocations() {
        this.setState({ loading: true });
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/business_locations`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((elem, key) => {
                    elem.key = key;
                });
                that.setState({
                    locations: data,
                    loading: false,
                    editMode: false,
                    location: undefined
                });
            })
            .catch(function (error) {
                console.log('error', error);
                that.setState({ loading: false });
            });
    }

    componentDidMount() {
        this.getLocations();
    }

    render() {
        const { locations, loading, editMode, location } = this.state;

        return (
            <Layout
                controls={
                    isGrantAccessed(this.props.user, grants.SETTINGS_LOCATIONS, accesses.ROWO) && (
                        <AddLocationModal
                            editMode={editMode}
                            getLocations={this.getLocations}
                            location={location}
                            unsetVisible={() => {
                                this.setState({
                                    editMode: false,
                                    location: undefined
                                });
                            }}
                        />
                    )
                }
                title={<FormattedMessage id='navigation.locations_settings' />}
            >
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={locations}
                    loading={loading}
                    pagination={false}
                    size='small'
                />
            </Layout>
        );
    }
}

@injectIntl
class AddLocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            name: undefined,
            type: undefined,
            volume: 0,
            price: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.editMode && this.props.editMode) {
            const { location } = this.props;
            this.setState({
                visible: true,
                name: location.name,
                type: location.type,
                volume: location.volume,
                price: location.price
            });
        }
    }

    postLocation = async () => {
        const { name, type, volume, price } = this.state;
        const { getLocations } = this.props;
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = 'business_locations';
        const payload = {
            name,
            type: type || null,
            volume,
            price
        };
        const data = await fetchAPI('POST', url, null, payload, {
            handleErrorInternally: true
        });
        await getLocations();

        // fetch(url, {
        //     method: 'POST',
        //     headers: {
        //         Authorization: token
        //     },
        //     body: JSON.stringify({
        //         name,
        //         type: type || null,
        //         volume,
        //         price
        //     })
        // })
        //     .then(function (response) {
        //         if (response.status !== 200) {
        //             return Promise.reject(new Error(response.statusText));
        //         }

        //         return Promise.resolve(response);
        //     })
        //     .then(function (response) {
        //         return response.json();
        //     })
        //     .then(function (data) {
        //         getLocations();
        //     })
        //     .catch(function (error) {
        //         console.log('error', error);
        //     });
    };

    editLocation = async () => {
        const { name, type, volume, price } = this.state;
        const { getLocations, location } = this.props;
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `/business_locations/${location.id}`;
        const payload = {
            name,
            type: type || null,
            volume,
            price
        };
        const data = await fetchAPI('PUT', url, null, payload, {
            handleErrorInternally: true
        });

        await getLocations();

        // fetch(url, {
        //     method: 'PUT',
        //     headers: {
        //         Authorization: token
        //     },
        //     body: JSON.stringify({
        //         name,
        //         type: type || null,
        //         volume,
        //         price
        //     })
        // })
        //     .then(function (response) {
        //         if (response.status !== 200) {
        //             return Promise.reject(new Error(response.statusText));
        //         }

        //         return Promise.resolve(response);
        //     })
        //     .then(function (response) {
        //         return response.json();
        //     })
        //     .then(function (data) {
        //         getLocations();
        //     })
        //     .catch(function (error) {
        //         console.log('error', error);
        //     });
    };

    handleOk = () => {
        if (this.props.editMode) {
            this.editLocation();
        } else {
            this.postLocation();
        }
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            name: undefined,
            type: undefined,
            volume: 0,
            price: 0
        });
        this.props.unsetVisible();
    };

    render() {
        const {
            intl: { formatMessage },
            editMode
        } = this.props;
        const { visible, name, type, volume, price } = this.state;

        return (
            <React.Fragment>
                <Button
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    type='primary'
                >
                    <FormattedMessage id='add' />
                </Button>
                <Modal
                    maskClosable={false}
                    okButtonProps={{
                        disabled: !name || !type
                    }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={`${formatMessage({ id: editMode ? 'edit' : 'add' })} ${formatMessage({
                        id: 'navigation.locations'
                    }).toLowerCase()}`}
                    visible={visible}
                >
                    <div>
                        <div style={{ margin: '0 0 8px 0' }}>
                            <span style={{ color: 'red' }}>*</span> <FormattedMessage id='locations.name' />:
                            <Input
                                onChange={event => {
                                    this.setState({
                                        name: event.target.value
                                    });
                                }}
                                value={name}
                            />
                        </div>
                        <div style={{ margin: '8px 0' }}>
                            <span style={{ color: 'red' }}>*</span> <FormattedMessage id='locations.type' />:
                            <Select
                                allowClear
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                onChange={value => {
                                    this.setState({
                                        type: value
                                    });
                                }}
                                value={type}
                            >
                                {TYPES.map((type, key) => (
                                    <Option key={key} value={type}>
                                        <FormattedMessage id={`locations.${type}`} />
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div
                            style={{
                                margin: '12px 0 0 0',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>
                                <FormattedMessage id='locations.volume' />:
                                <InputNumber
                                    decimalSeparator=','
                                    min={0}
                                    onChange={value => {
                                        this.setState({
                                            volume: value
                                        });
                                    }}
                                    step={1}
                                    style={{ margin: '0 0 0 12px' }}
                                    value={volume}
                                />
                            </div>
                            <div>
                                <FormattedMessage id='storage.price' />:
                                <InputNumber
                                    decimalSeparator=','
                                    min={0}
                                    onChange={value => {
                                        this.setState({
                                            price: value
                                        });
                                    }}
                                    style={{ margin: '0 0 0 12px' }}
                                    value={price}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
