import { Form } from '@ant-design/compatible';
import { Input } from 'antd';
import React from 'react';

const { TextArea } = Input;
const FormItem = Form.Item;

export class DecoratedTextArea extends React.PureComponent {
    render() {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,

            getFieldDecorator,
            fieldDecoratorOptions,
            rules,
            field,
            disabled,
            placeholder,
            autoSize,
            initialValue
        } = this.props;

        const textArea = getFieldDecorator(field, {
            rules,
            ...(initialValue ? { initialValue } : { initialValue: void 0 }),
            ...(fieldDecoratorOptions ? { fieldDecoratorOptions } : {})
        })(
            <TextArea
                autoSize={autoSize}
                disabled={disabled}
                placeholder={placeholder} // { minRows: 2, maxRows: 6 }
            />
        );

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                label={label}
                {...formItemLayout}
            >
                {textArea}
            </FormItem>
        ) : (
            { textArea }
        );
    }
}
