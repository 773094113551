exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__settingsWrapper---3F1Lh{display:flex;justify-content:flex-start;flex-wrap:wrap}.styles-m__settingsElement---2Ftb5{width:33%;border-radius:8px;border:1px solid var(--lightGray);padding:14px 14px 4px;margin:4px 8px}.styles-m__settingsElementHeader---3C3Qm{display:flex;justify-content:space-between;font-size:18px;font-weight:500;padding:0 0 8px}.styles-m__settingsElementRow---3ToRL{display:flex;justify-content:space-between;align-items:center;padding:4px 0}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,mCACI,YAAa,CACb,0BAA2B,CAC3B,cACJ,CAEA,mCACI,SAAU,CACV,iBAAkB,CAClB,iCAAkC,CAClC,qBAA2B,CAC3B,cACJ,CAEA,yCACI,YAAa,CACb,6BAA8B,CAC9B,cAAe,CACf,eAAgB,CAChB,eACJ,CAEA,sCACI,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,aACJ","file":"styles.m.css","sourcesContent":[".settingsWrapper {\n    display: flex;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n}\n\n.settingsElement {\n    width: 33%;\n    border-radius: 8px;\n    border: 1px solid var(--lightGray);\n    padding: 14px 14px 4px 14px;\n    margin: 4px 8px;\n}\n\n.settingsElementHeader {\n    display: flex;\n    justify-content: space-between; \n    font-size: 18px;\n    font-weight: 500;\n    padding: 0 0 8px;\n}\n\n.settingsElementRow {\n    display: flex;\n    justify-content: space-between; \n    align-items: center;\n    padding: 4px 0px;\n}"]}]);

// Exports
exports.locals = {
	"settingsWrapper": "styles-m__settingsWrapper---3F1Lh",
	"settingsElement": "styles-m__settingsElement---2Ftb5",
	"settingsElementHeader": "styles-m__settingsElementHeader---3C3Qm",
	"settingsElementRow": "styles-m__settingsElementRow---3ToRL"
};