import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm, Select, Tooltip } from 'antd';
import { DraggableTable } from 'components';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import EntriesDirectoryAddRows from '../EntriesDirectoryAddRowsModal';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

const EntriesDirectoryRows = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        fetchEntries,
        directoryId,
        tree,
        analytics,
        fetchEntryRows,
        loadingRows,
        rows
    } = props;

    const [id, setId] = useState(undefined);
    const [accountId, setAccountId] = useState(undefined);
    const [analyticId, setAnalyticsId] = useState(undefined);
    const [amountFormula, setFormula] = useState(undefined);
    const [amountSignature, setAmountSignature] = useState(undefined);
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(undefined);
    const [openModal, openAddModal] = useState(false);

    // const setData = row => {
    //     setAccountId(row.accountId || undefined);
    //     setAnalyticsId(row.analyticsId || undefined);
    //     setAmountSignature(row.amountSignature || undefined);
    //     setFormula(row.amountFormula || undefined);
    //     setOperationType(row.operationType || undefined);
    //     setComment(row.comment || undefined);
    //     setId(row.id || undefined);

    //     setEdit(undefined);
    // };

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='№' />,
                key: 'num',
                width: '3%',
                render: (row, number, index) => {
                    return index + 1;
                }
            },
            {
                title: () => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Tooltip title={<FormattedMessage id='entries_tab.add_rows' />}>
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={async () => {
                                        await openAddModal(true);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                },
                key: 'actions',
                width: '8%',
                render: (row, number, index) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Popconfirm
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        '/general_ledger/entries_directory_lines',
                                        null,
                                        { id: row.id },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setTimeout(() => fetchEntryRows(), 100);
                                    await setTimeout(() => fetchEntries(), 100);
                                }}
                                title={`${formatMessage({ id: 'delete' })}?`}
                            >
                                <Button icon={<DeleteOutlined />} />
                            </Popconfirm>

                            <Button
                                icon={<EditOutlined />}
                                onClick={async () => {
                                    await openAddModal(true);
                                    setEdit(row);
                                }}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='requisite-setting.account' />,
                key: 'account',
                width: '15%',
                render: row => {
                    return row.accountName ? (
                        `(#${row.accountId}) ${row.accountName}`
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='entries_tab.acc_type' />,
                key: 'accType',
                width: '10%',
                render: row => {
                    return row.accountType ? (
                        <FormattedMessage id={`entries_tab.${row.accountType}`} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='entries_tab.user_id' />,
                key: 'manager',
                width: '7%',
                render: row => {
                    return row.managerName && row.managerName.length > 2 ? row.managerName : row.managerId;
                }
            },
            {
                title: <FormattedMessage id='entries_tab.modify_date' />,
                key: 'data',
                width: '10%',
                render: row => {
                    return row.modifyDate ? (
                        dayjs(row.modifyDate).format('YYYY-MM-DD')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='entries_tab.operation_id' />,
                key: 'operationId',
                width: '5%',
                render: row => {
                    return row.operationId ? row.operationId : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='navigation.report_analytics' />,
                key: 'analytics',
                width: '10%',
                // ...getColumnSearchProps('startDate'),
                render: row => {
                    return row.analyticName ? row.analyticName : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='storage.operation_type' />,
                key: 'operationType',
                // ...getColumnSearchProps('startDate'),
                width: '10%',
                render: row => {
                    return row.operationType ? (
                        <FormattedMessage id={`entries_tab.${row.operationType}`} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='entries_tab.rows.formula' />,
                key: 'formula',
                // ...getColumnSearchProps('startDate'),
                width: '10%',
                render: row => {
                    return row.amountFormula ? row.amountFormula : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='entry_table.operation_side' />,
                key: 'signature',
                // ...getColumnSearchProps('startDate'),
                width: '10%',
                render: row => {
                    return row.amountSignature ? (
                        <FormattedMessage id={`entries_tab.sign.${row.amountSignature}`} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                // ...getColumnSearchProps('startDate'),
                width: '10%',
                render: row => {
                    return row.comment ? row.comment : <FormattedMessage id='long_dash' />;
                }
            }
        ];
    }, [formatMessage, fetchEntryRows, rows]);

    useEffect(() => {
        if (open && directoryId) {
            fetchEntryRows();
        }
    }, [open, fetchEntryRows]);

    const onClose = () => {
        hideModal();
    };

    return (
        <div>
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                footer={null}
                maskClosable={false}
                onCancel={() => onClose()}
                open={open}
                title={<FormattedMessage id='entries_tab.edit_rows' />}
                width='90%'
            >
                <DraggableTable
                    columns={columns}
                    dataSource={rows}
                    loading={loadingRows}
                    onDragEnd={async (fromIndex, toIndex) => {
                        await fetchAPI(
                            'PUT',
                            '/general_ledger/swap_entries_directory_lines',
                            undefined,
                            {
                                directoryId,
                                order1: Number(rows[fromIndex].order),
                                order2: Number(rows[toIndex].order)
                            },
                            { handleErrorInternally: true }
                        );
                        await fetchEntryRows();
                    }}
                    pagination={false}
                    size='small'
                    scroll={{ x: 'auto' }} 
                />
            </Modal>
            <EntriesDirectoryAddRows
                analytics={analytics}
                directoryId={directoryId}
                edit={edit}
                fetchEntries={fetchEntries}
                fetchEntryRows={fetchEntryRows}
                hideModal={() => {
                    openAddModal(false);
                    setEdit(undefined);
                }}
                open={openModal}
                tree={tree}
            />
        </div>
    );
};

const EntriesDirectoryRowsTable = injectIntl(connect(mapStateToProps, mapDispatchToProps)(EntriesDirectoryRows));

export default EntriesDirectoryRowsTable;
