import { DatePicker, Modal, Radio, Select } from 'antd';
import { DateRangePicker } from 'components';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    clients: state.clients.clients,
    user: state.auth
});

const mapDispatchToProps = {
    resetModal,
    fetchClients,
    setClientsSearchFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SMSReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            detailing: 'dates'
        };

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    handleOk = async () => {
        const { modalProps } = this.props;
        const { type } = modalProps;

        const { typeOfSMS, fromDate, toDate, clientId, detailing } = this.state;

        const getResponse = () => {
            return fetchAPI(
                'GET',
                '/report/notifications/excel/bySMS',
                {
                    createdFromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                    createdToDate: dayjs(toDate).format('YYYY-MM-DD'),
                    clientId,
                    typeOfSMS,
                    detailing
                },
                null,
                { rawResponse: true }
            );
        };
        const response = await getResponse();
        const reportFile = await response.blob();
        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        saveAs(reportFile, fileName);
        this.handleCancel();
    };

    handleCancel = () => {
        this.props.resetModal();
        this.setState({
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            typeOfSMS: undefined,
            clientId: undefined
        });
    };

    componentDidMount() {
        this.props.fetchClients();
    }

    render() {
        const {
            modalProps,
            resetModal,
            visible,
            clients,
            intl: { formatMessage }
        } = this.props;

        const { typeOfSMS, fromDate, toDate, clientId, detailing } = this.state;

        const { type } = modalProps;

        const masType = [
            'client_online',
            'client_order_success',
            'client_reminder',
            'client_review',
            'sms-cash-order-receipt',
            'sms-online-service-book',
            'sms-order-agreement'
        ];

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    maskClosable={false}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='reports_page.sms_report' />}
                    visible={visible === MODALS.SMS_REPORT_MODAL}
                    width='fit-content'
                >
                    <Select
                        allowClear
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={(clientId, option) => {
                            if (option) {
                                this.setState({
                                    clientId
                                });
                            } else {
                                this.setState({
                                    clientId: undefined
                                });
                            }
                        }}
                        onSearch={this.handleSearchClients}
                        placeholder={formatMessage({ id: 'add_order_form.client' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={clientId}
                    >
                        {clients.map(client => (
                            <Option key={client.clientId} phone={_.get(client, 'phones[0]')} value={client.clientId}>
                                {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                    client,
                                    'phones[0]',
                                    ''
                                )}`}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={typeOfSMS => {
                            this.setState({ typeOfSMS });
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({ id: 'type_sms' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={typeOfSMS}
                    >
                        {masType.map(id => (
                            <Option key={id} value={id}>
                                {formatMessage({ id: `sms_settings.type.${id}` })}
                            </Option>
                        ))}
                    </Select>
                    <DateRangePicker
                        dateRange={[fromDate, toDate]}
                        onDateChange={arr => {
                            this.setState({ fromDate: arr[0] });
                            this.setState({ toDate: arr[1] });
                        }}
                    />
                    <div style={{ paddingTop: 8 }}>
                        <Radio.Group onChange={e => this.setState({ detailing: e.target.value })} value={detailing}>
                            <Radio value='dates'>
                                <FormattedMessage id='by_days' />
                            </Radio>
                            <Radio value='universal'>
                                <FormattedMessage id='by_sms' />
                            </Radio>
                            <Radio value='clients'>
                                <FormattedMessage id='by_clients' />
                            </Radio>
                        </Radio.Group>
                    </div>
                </Modal>
            </div>
        );
    }
}
