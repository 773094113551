import { notification } from 'antd';
import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    DOWNLOAD_SALES_REPORT,
    fetchRequisitesSuccess,
    fetchSupplierRequisitesSuccess,
    FETCH_REQUISITES,
    FETCH_SUPPLIER_REQUISITES,
    selectFilters
} from './duck';

export function* downloadSalesReportSaga() {
    while (true) {
        yield take(DOWNLOAD_SALES_REPORT);

        const payloadFilters = yield select(selectFilters);

        const filters = {
            ...payloadFilters,
            createdFromDate: _.get(payloadFilters, 'createdFromDate')
                ? dayjs(payloadFilters.createdFromDate).format('YYYY-MM-DD')
                : undefined,
            createdToDate: _.get(payloadFilters, 'createdToDate')
                ? dayjs(payloadFilters.createdToDate).format('YYYY-MM-DD')
                : undefined
        };

        try {
            const response = yield call(
                fetchAPI,
                'GET',
                '/report/accounts_payable_excel',
                { filters },
                null,
                { handleErrorInternally: true, rawResponse: true }
            );

            const reportFile = yield response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];

            yield saveAs(reportFile, fileName);
        } catch (err) {
            yield put(emitError(err));
            notification.error({ message: 'Error' });
        }
    }
}

export function* fetchSupplierRequisitesSaga() {
    while (true) {
        try {
            const { payload } = yield take(FETCH_SUPPLIER_REQUISITES);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', `business_suppliers/${payload}`);

            yield put(fetchSupplierRequisitesSuccess(data.requisites));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchRequisitesSaga() {
    while (true) {
        try {
            yield take(FETCH_REQUISITES);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'businesses/requisites');

            yield put(fetchRequisitesSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([
        call(downloadSalesReportSaga),
        call(fetchSupplierRequisitesSaga),
        call(fetchRequisitesSaga)
    ]);
}
