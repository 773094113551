exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__stationLoadsTable---3se_o .table-small{padding:0}.styles-m__status---3a0YR{display:flex;align-items:center}.styles-m__deleteIcon---131w8{color:var(--warning);font-size:24px}.styles-m__staticStationLoadsRow---MnLCJ{background:var(--static)}.styles-m__newStationLoadsRow---g5WSx{background-color:rgba(11,127,0,.28)}.styles-m__editedStationLoadsRow---tB6VE{background-color:rgba(255,45,45,.28)}.styles-m__ogTitle---1zr00{font-size:16px;font-weight:700}.styles-m__ogDesc---UN063{margin-bottom:8px;margin-top:8px;opacity:.7}.styles-m__ogImg---1zeG9{margin-bottom:4px;margin-top:4px}.styles-m__linkBlock---1xza9{margin-bottom:12px;background-color:#f5f5f5;border:1px solid #8a8d8a;padding:4px;border-radius:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,kDACI,SACJ,CAEA,0BACI,YAAa,CACb,kBACJ,CAEA,8BACI,oBAAqB,CACrB,cACJ,CAEA,yCACI,wBACJ,CAEA,sCACI,mCACJ,CAEA,yCACI,oCACJ,CAEA,2BACI,cAAe,CACf,eACJ,CAEA,0BACI,iBAAkB,CAClB,cAAe,CACf,UACJ,CAEA,yBACI,iBAAkB,CAClB,cACJ,CAEA,6BACI,kBAAmB,CACnB,wBAA4B,CAC5B,wBAAyB,CACzB,WAAY,CACZ,iBACJ","file":"styles.m.css","sourcesContent":[".stationLoadsTable :global(.table-small) {\n    padding: 0;\n}\n\n.status {\n    display: flex;\n    align-items: center;\n}\n\n.deleteIcon {\n    color: var(--warning);\n    font-size: 24px;\n}\n\n.staticStationLoadsRow {\n    background: var(--static);\n}\n\n.newStationLoadsRow {\n    background-color: rgba(11, 127, 0, 0.28);\n}\n\n.editedStationLoadsRow {\n    background-color: rgba(255, 45, 45, 0.28);\n}\n\n.ogTitle {\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.ogDesc {\n    margin-bottom: 8px;\n    margin-top: 8px;\n    opacity: 0.7;\n}\n\n.ogImg {\n    margin-bottom: 4px;\n    margin-top: 4px;\n}\n\n.linkBlock {\n    margin-bottom: 12px;\n    background-color: whitesmoke;\n    border: 1px solid #8a8d8a;\n    padding: 4px;\n    border-radius: 8px; \n}\n"]}]);

// Exports
exports.locals = {
	"stationLoadsTable": "styles-m__stationLoadsTable---3se_o",
	"status": "styles-m__status---3a0YR",
	"deleteIcon": "styles-m__deleteIcon---131w8",
	"staticStationLoadsRow": "styles-m__staticStationLoadsRow---MnLCJ",
	"newStationLoadsRow": "styles-m__newStationLoadsRow---g5WSx",
	"editedStationLoadsRow": "styles-m__editedStationLoadsRow---tB6VE",
	"ogTitle": "styles-m__ogTitle---1zr00",
	"ogDesc": "styles-m__ogDesc---UN063",
	"ogImg": "styles-m__ogImg---1zeG9",
	"linkBlock": "styles-m__linkBlock---1xza9"
};