import { Numeral } from 'commons';
import React from 'react';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency, isForbidden, numeralFormatter, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

export default props => {
    const name = {
        title: `${props.intl.formatMessage({
            id: 'order_form_table.product_code'
        })}/${props.intl.formatMessage({ id: 'storage.name' })}`,
        dataIndex: 'name',
        width: '10%',
        sorter: (a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0),
        defaultSortOrder: 'ascend',
        sortDirections: ['descend', 'ascend'],
        render: (name, data) =>
            isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD) ? (
                <Link to={`${book.product}/${data.id}`}>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--link)'
                        }}
                    >
                        {data.code}
                    </div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--text3)',
                            fontSize: 12
                        }}
                    >
                        {data.name}
                    </div>
                </Link>
            ) : (
                <div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--link)'
                        }}
                    >
                        {data.code}
                    </div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--text3)',
                            fontSize: 12
                        }}
                    >
                        {data.name}
                    </div>
                </div>
            )
    };

    const remaining = {
        title: props.intl.formatMessage({
            id: 'storage.in_stock'
        }),
        dataIndex: 'remaining',
        width: '10%',
        sorter: (a, b) => a.remaining - b.remaining,
        sortDirections: ['descend', 'ascend'],
        render: (remaining, data) => {
            return (
                <div
                    className={Styles.cellWrapp}
                    onClick={() => {
                        props.redirectToTracking({
                            id: data.id,
                            name: data.name,
                            code: data.code
                        });
                    }}
                >
                    {numeralFormatter(Number(remaining).toFixed(1))}
                </div>
            );
        }
    };

    const units = {
        title: props.intl.formatMessage({
            id: 'services_table.units'
        }),
        dataIndex: 'unit',
        width: '10%',
        sorter: (a, b) => a.remaining - b.remaining,
        sortDirections: ['descend', 'ascend'],
        render: (unit, data) => {
            return <div className={Styles.cellWrapp}>{unit}</div>;
        }
    };

    const reserve = {
        title: props.intl.formatMessage({
            id: 'storage.reserve'
        }),
        dataIndex: 'reserved',
        width: '10%',
        sorter: (a, b) => a.reserved - b.reserved,
        sortDirections: ['descend', 'ascend'],
        render: (reserved, data) => {
            return (
                <div
                    className={Styles.cellWrapp}
                    onClick={() => {
                        if (!isForbidden(props.user, permissions.ACCESS_RESERVES_STORE_DOCS)) {
                            props.redirectToTracking({
                                id: data.id,
                                name: data.name,
                                code: data.code,
                                type: 'reserves'
                            });
                        }
                    }}
                >
                    {numeralFormatter(Number(Math.abs(reserved)).toFixed(1))}
                </div>
            );
        }
    };

    const available = {
        title: props.intl.formatMessage({
            id: 'storage.available'
        }),
        key: 'available',
        width: '10%',
        sorter: (a, b) => a.remaining - a.reserved - (b.remaining - b.reserved),
        sortDirections: ['descend', 'ascend'],
        render: (_, data) => {
            return (
                <div
                    className={Styles.cellWrapp}
                    onClick={() => {
                        props.redirectToTracking({
                            id: data.id,
                            name: data.name,
                            code: data.code
                        });
                    }}
                >
                    {numeralFormatter((Number(data.remaining) - Number(data.reserved)).toFixed(1))}
                </div>
            );
        }
    };

    const ordered = {
        title: props.intl.formatMessage({
            id: 'storage.ordered'
        }),
        key: 'countInStoreOrders',
        dataIndex: 'countInStoreOrders',
        width: '10%',
        sorter: (a, b) => a.countInStoreOrders - b.countInStoreOrders,
        sortDirections: ['descend', 'ascend'],
        render: (ordered, data) => {
            return (
                <div
                    className={Styles.cellWrapp}
                    onClick={() => {
                        if (!isForbidden(props.user, permissions.ACCESS_ORDERS_STORE_DOCS)) {
                            props.redirectToTracking({
                                id: data.id,
                                name: data.name,
                                code: data.code,
                                type: 'orders'
                            });
                        }
                    }}
                >
                    {numeralFormatter(Math.abs(ordered).toFixed(1))}
                </div>
            );
        }
    };

    const sum = {
        title: props.intl.formatMessage({
            id: 'storage.sum'
        }),
        dataIndex: 'sum',
        width: '10%',
        sorter: (a, b) => a.sum - b.sum,
        sortDirections: ['descend', 'ascend'],
        render: sum => <Numeral currency={getCurrency()}>{sum}</Numeral>
    };

    return [
        // id,
        name,
        remaining,
        units,
        reserve,
        available,
        ordered,
        sum
    ];
};
