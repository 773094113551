import { Input, Table } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    selectOrders,
    selectOrdersFetching,
    selectOrdersQuery,
    selectOrdersStats,
    selectSelectedOrderId,
    selectSelectedOrderNum,
    selectSelectedVehicleData,
    setOrdersPage,
    setOrdersSearchQuery,
    setSelectedOrderId,
    setSelectedOrderNum,
    setSelectedVehicleData
} from '../../redux/duck';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    orders: selectOrders(state),
    stats: selectOrdersStats(state),
    query: selectOrdersQuery(state),
    selectedOrderId: selectSelectedOrderId(state),
    selectedOrderNum: selectSelectedOrderNum(state),
    vehicleData: selectSelectedVehicleData(state),
    ordersFetching: selectOrdersFetching(state)
});

const mapDispatchToProps = {
    setOrdersPage,
    setSelectedOrderId,
    setSelectedOrderNum,
    setSelectedVehicleData,
    setOrdersSearchQuery
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleOrdersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rtlDocs: []
        };

        this.handleSearch = debounce(value => {
            this.props.setOrdersSearchQuery({ query: value.replace(/[+()]/g, '') });
            // this.fetchRtlDocs(value.replace(/[+()]/g, ''));
        }, 1000).bind(this);
    }

    // componentDidMount() {
    //     this.fetchRtlDocs();
    // }

    onSearch = e => {
        const { value } = e.target;
        this.handleSearch(value);
    };

    // fetchRtlDocs = async query => {
    //     const res = await fetchAPI('GET', '/retails', { query }, undefined, {
    //         handleErrorInternally: true
    //     });
    //     this.setState({
    //         rtlDocs: res.orders,
    //         rtsStats: res.stats.countOrders
    //     });
    // };

    render() {
        const {
            orders,
            stats,
            query,
            intl: { formatMessage },
            setOrdersPage,
            setSelectedOrderId,
            setSelectedOrderNum,
            setSelectedVehicleData,
            selectedOrderId,
            selectedOrderNum,
            vehicleData,
            ordersFetching
        } = this.props;

        const { rtlDocs, rtsStats } = this.state;

        const statsPag = stats.countOrders + rtsStats;

        const pagination = {
            pageSize: 25,
            size: 'small',
            total: Math.ceil(statsPag / 25) * 25,
            current: query.page,
            showSizeChanger: false,
            onChange: page => {
                setOrdersPage({ page });
            }
        };

        return (
            <div className={Styles.tableCont}>
                <div className={Styles.filtersCont}>
                    <div className={Styles.inputCont}>
                        <Input.Search
                            allowClear
                            defaultValue={query.query}
                            onChange={this.onSearch}
                            placeholder={formatMessage({ id: 'search' })}
                        />
                    </div>
                </div>

                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig({ formatMessage })}
                    dataSource={orders}
                    loading={ordersFetching}
                    onRow={order => {
                        return {
                            onClick: () => {
                                setSelectedOrderId({ orderId: order.id });
                                setSelectedOrderNum({ orderNum: order.num });
                                setSelectedVehicleData({
                                    vehicleMake: order.vehicleMakeName,
                                    vehicleModel: order.vehicleModelName
                                });
                            }
                        };
                    }}
                    pagination={pagination}
                    rowClassName={order => {
                        return order.id == selectedOrderId ? Styles.selectedRow : Styles.tableRow;
                    }}
                    rowKey='id'
                    scroll={{ x: 'auto', y: '60vh' }}
                    size='small'
                />
            </div>
        );
    }
}
