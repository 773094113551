import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Select, Table, notification } from 'antd';
import BalanceManagingModal from 'containers/BalanceSettingsContainer/modals/BalanceManagingModal';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const IncomeExpenseSettingsContainer = ({ user, intl: { formatMessage }, brands }) => {
    const [query, setQuery] = useState({
        types: ['INCOME/EXPENSE'],

        dateFrom: dayjs().startOf('year').toISOString(),
        dateTo: dayjs().toISOString()
    });
    const [isFetched, setFetched] = useState(true);
    const [modalData, setModalData] = useState({});
    const [requisites, setRequisites] = useState();
    const [rowId, setRowId] = useState();
    const [open, setOpen] = useState(false);
    const [balanceSettingsData, setBalanceSettings] = useState([]);
    const [reportCount, setReportCount] = useState([]);
    const [filters, setFilters] = useState({
        warehouseId: undefined,
        date: dayjs(),
        averageSellPerMonth: 0,
        deliveryTime: 1,
        calculationPeriod: 12,
        limit: 25,
        frequencyOfOrders: 7,
        insuranceReserve: 1
    });

    const groupedData = [
        {
            title: 'I.Необоротні активи',
            filterCondition: code => code > 999 && code < 1099,
            mainTitle: <FormattedMessage id='asset' />
        },
        {
            title: 'II.Оборотні активи',
            filterCondition: code => code > 1099 && code < 1199
        },
        {
            title: 'III.Необоротні активи, утримувані для продажу, та групи вибуття',
            filterCondition: code => code > 1199 && code < 1301
        },
        {
            title: 'I.Власний капітал',
            filterCondition: code => code > 1300 && code < 1499,
            mainTitle: <FormattedMessage id='passive' />
        },
        {
            title: 'II.Довгострокові зобовʼязання, цільове фінансування та забезпечення',
            filterCondition: code => code > 1500 && code < 1599
        },
        {
            title: 'III.Поточні зобовʼязання',
            filterCondition: code => code > 1599 && code < 1699
        },
        {
            title: 'III.Зобовʼязання, повʼязані з необоротними активами, утримуваними для продажу, та групами вибуття',
            filterCondition: code => code > 1699,
            mainTitle: <FormattedMessage id='article' />
        }
    ];

    const fetchRequisites = useCallback(async () => {
        const res = await fetchAPI('GET', '/businesses/requisites', null, null, {
            handleErrorInternally: true
        });

        setRequisites(res.map(({ id, name }) => ({ value: id, label: name })));
    }, []);

    const getIncomeExpenseSettings = useCallback(async () => {
        try {
            const res = await fetchAPI(
                'GET',
                '/account/book/settings',
                {
                    types: ['INCOME/EXPENSE']
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setBalanceSettings(res);
            setFetched(false);
        } catch (err) {
            notification.error({
                message: formatMessage({
                    id: 'error'
                })
            });
            setFetched(false);
        }
    }, [formatMessage]);

    const deleteFormula = useCallback(
        async id => {
            try {
                await fetchAPI(
                    'DELETE',
                    '/account/book/settings',

                    null,
                    {
                        accountBookId: id
                    },
                    {
                        handleErrorInternally: true
                    }
                );
                getIncomeExpenseSettings();
                setFetched(false);
            } catch (err) {
                notification.error({
                    message: formatMessage({
                        id: 'error'
                    })
                });
                setFetched(false);
            }
        },
        [formatMessage]
    );

    // const debounceFilters = useMemo(() => {
    //     const loadOptions = (filters, value) => {
    //         setQuery(filters, value);
    //     };

    //     return _.debounce(loadOptions, 1000);
    // }, [setQuery]);

    const columns = (withTitle, titleForEachTable, mainTitle) => [
        {
            title: withTitle ? mainTitle : undefined,
            key: 'asset',
            align: 'center',
            dataIndex: 'name',
            onCell: row => ({ style: { fontWeight: row.firstRow ? 700 : undefined } }),
            // children: [
            //     {
            //         title: titleForEachTable,
            //         align: 'center',
            //         dataIndex: 'name'
            //     }
            // ],
            width: '10%'
        },
        {
            title: withTitle ? <FormattedMessage id='code_row' /> : undefined,
            key: 'code_row',
            dataIndex: 'code',
            align: 'center',
            width: '10%',
            render: data => {
                return data || <FormattedMessage id='long_dash' />;
            }
        },
        {
            title: withTitle ? <FormattedMessage id='entries_tab.rows.formula' /> : undefined,
            key: 'rawExpression',
            dataIndex: 'rawExpression',
            align: 'center',
            width: '30%',
            render: data => {
                return data || <FormattedMessage id='long_dash' />;
            }
        },

        {
            key: 'edit',
            align: 'center',
            width: '5%',
            render: row => {
                return !row.firstRow ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <Button icon={<EditOutlined />} onClick={() => setOpen(row.id)} />
                        <Popconfirm
                            onConfirm={() => deleteFormula(row.id)}
                            title={`${formatMessage({ id: 'entries_tab.rows.reset_formula' })}`}
                        >
                            <Button icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </div>
                ) : undefined;
            }
        }
    ];

    useEffect(() => {
        getIncomeExpenseSettings();
        fetchRequisites();
    }, [fetchRequisites, getIncomeExpenseSettings]);

    return (
        <div>
            <div>
                <div className={Styles.resetAllBtn}>
                    <Popconfirm
                        onConfirm={() => deleteFormula(null)}
                        title={<FormattedMessage id='entries_tab.rows.reset_formula_rows_sure_to_delete' />}
                    >
                        <Button type='primary'>
                            <FormattedMessage id='entries_tab.rows.reset_formula_rows' />
                        </Button>
                    </Popconfirm>
                </div>
                {balanceSettingsData &&
                    groupedData.map(({ title, filterCondition, mainTitle }, index) => {
                        const dataSource = balanceSettingsData.filter(({ code }) => filterCondition(code));

                        if (dataSource.length === 0) return null;

                        return (
                            <Table
                                bordered
                                columns={columns(mainTitle, title, mainTitle)}
                                dataSource={dataSource}
                                loading={isFetched}
                                pagination={false}
                                size='small'
                            />
                        );
                    })}
            </div>
            <BalanceManagingModal getBalance={getIncomeExpenseSettings} hideModal={() => setOpen(false)} open={open} />
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    brands: selectBrands(state)
});

const mapDispatchToProps = {
    fetchWarehouses,
    fetchBrands
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IncomeExpenseSettingsContainer));
