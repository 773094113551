import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SuppliersTable from './SuppliersTable';

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

/**
 * Product by suppliers tab
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SuppliersTab extends Component {
    render() {
        return (
            <div>
                <SuppliersTable supplierTabDisabled={this.props.supplierTabDisabled} />
            </div>
        );
    }
}
