import { Select } from 'antd';
import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

export default class LocationsDocumentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        return <Layout title={<FormattedMessage id='navigation.locations_document' />}></Layout>;
    }
}
