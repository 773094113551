import { Button, DatePicker, Input, Modal, TimePicker } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    createOrder,
    DEF_DATE_FROMAT,
    DEF_TIME_FROMAT,
    selectBeginDate,
    selectBeginTime,
    selectComment,
    setBeginDate,
    setBeginTime,
    setComment
} from './redux/duck';
import Styles from './styles.m.css';

const { TextArea } = Input;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    beginDate: selectBeginDate(state),
    beginTime: selectBeginTime(state),
    comment: selectComment(state)
});

const mapDispatchToProps = {
    resetModal,
    createOrder,

    setBeginDate,
    setBeginTime,
    setComment
};

/**
 * This modal is used to register the user for service.
 * It creates a new order.
 *
 * @property {*} modalProps.clientId - ID of a client to create order for
 * @property {*} [ modalProps.vehicleId ] - ID of a vehicle of a client
 * @property {*} modalProps.managerId - ID of a client to create order for
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RegisterForServiceModal extends Component {
    handleSubmit = e => {
        e.preventDefault();

        const {
            createOrder,

            modalProps: { clientId, vehicleId, managerId }
        } = this.props;

        createOrder({ clientId, vehicleId, managerId });
        this.onClose();
    };

    onClose = () => {
        this.props.resetModal();
    };

    render() {
        const {
            visible,
            beginDate,
            beginTime,
            comment,
            setBeginDate,
            setBeginTime,
            setComment,
            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    footer={
                        <div>
                            <Button onClick={this.handleSubmit} type='primary'>
                                <FormattedMessage id='register_for_service_modal.title' />
                            </Button>
                        </div>
                    }
                    onCancel={this.onClose}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='register_for_service_modal.title' />
                        </div>
                    }
                    visible={visible === MODALS.REGISTER_FOR_SERVICE}
                    width='50%'
                >
                    <div>
                        <DatePicker
                            format='DD-MM-YYYY'
                            onChange={date => setBeginDate({ beginDate: date.format(DEF_DATE_FROMAT) })}
                            popupStyle={{ zIndex: 9999 }}
                            value={dayjs(beginDate, DEF_DATE_FROMAT)}
                        />

                        <TimePicker
                            defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                            format='HH'
                            onChange={time => setBeginTime({ beginTime: time.format(DEF_TIME_FROMAT) })}
                            popupStyle={{ zIndex: 9999 }}
                            value={dayjs(beginTime, DEF_TIME_FROMAT)}
                        />

                        <TextArea
                            onChange={e => setComment({ comment: _.get(e, 'target.value') })}
                            placeholder={formatMessage({
                                id: 'register_for_service_modal.comment'
                            })}
                            rows={4}
                            value={comment}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
