exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__title---1K4cG{font-size:1.5em;padding:0 0 20px}.styles-m__col---3xJz4{display:flex;justify-content:center;font-size:1.2em}.styles-m__row---3chVI{margin-top:10px;border:1px solid hsla(0,0%,50.2%,.274)}.styles-m__radioGroup---PdhvL{width:100%}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACI,eAAgB,CAChB,gBACJ,CAEA,uBACI,YAAa,CACb,sBAAuB,CACvB,eACJ,CAEA,uBACI,eAAgB,CAChB,sCACJ,CAEA,8BACI,UACJ","file":"styles.m.css","sourcesContent":[".title {\n    font-size: 1.5em;\n    padding: 0px 0 20px 0;\n}\n\n.col {\n    display: flex;\n    justify-content: center;\n    font-size: 1.2em;\n}\n\n.row {\n    margin-top: 10px;\n    border: 1px solid rgba(128, 128, 128, 0.274);\n}\n\n.radioGroup {\n    width: 100%;\n}"]}]);

// Exports
exports.locals = {
	"title": "styles-m__title---1K4cG",
	"col": "styles-m__col---3xJz4",
	"row": "styles-m__row---3chVI",
	"radioGroup": "styles-m__radioGroup---PdhvL"
};