import { Form } from '@ant-design/compatible';
import { CarOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { VehicleNumberHistory } from 'components';
import {
    fetchVehiclesInfo,
    MAKE_VEHICLES_INFO_FILTER_TYPE,
    MODEL_VEHICLES_INFO_FILTER_TYPE,
    onChangeAddClientVehicleForm,
    resetAddClientVehicleForm,
    YEAR_VEHICLES_INFO_FILTER_TYPE
} from 'core/forms/addClientVehicleForm/duck';
import { DecoratedInput, DecoratedInputNumber, DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const FormItem = Form.Item;
const { Option } = Select;

const findLabel = (arr, id, keyName) => [keyName, _.get(_.find(arr, { id }), 'name')];

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
    colon: false
};

/**
 * @property {funtion} addClientVehicle - Pass function to add client vehicle
 */
@injectIntl
@withReduxForm2({
    name: 'addClientVehicleForm',
    actions: {
        change: onChangeAddClientVehicleForm,
        resetAddClientVehicleForm,
        fetchVehiclesInfo
    }
})
export class AddClientVehicleForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editModeFetching: props.editMode
        };
    }

    normalizeMainTableData() {
        const { year, makeId, modelId, modificationId, number, vin } = this.props;

        const { setFieldsValue } = this.props.form;

        setFieldsValue({
            year,
            makeId,
            modelId,
            modificationId,
            number,
            vin
        });
    }

    /* eslint-disable complexity */
    render() {
        const {
            year,
            makeId,
            modelId,
            modificationId,
            number,
            makes,
            models,
            modifications,
            lastFilterAction,
            editableVehicle,
            editMode,
            vehicleTypeId,
            wheelRadius,
            vehicleTypes
        } = this.props;

        const years = Array(new Date().getFullYear() - 1900 + 1)
            .fill(1900)
            .map((val, index) => val + index)
            .reverse();

        const { getFieldDecorator, validateFields, getFieldsValue, setFieldsValue } =
            this.props.form;

        const vehicle = getFieldsValue();

        if (this.state.editModeFetching) {
            if (
                ![
                    YEAR_VEHICLES_INFO_FILTER_TYPE,
                    MAKE_VEHICLES_INFO_FILTER_TYPE,
                    MODEL_VEHICLES_INFO_FILTER_TYPE
                ].includes(lastFilterAction)
            ) {
                const yearFilters = { year };
                this.props.fetchVehiclesInfo(YEAR_VEHICLES_INFO_FILTER_TYPE, yearFilters);
            } else if (
                ![MAKE_VEHICLES_INFO_FILTER_TYPE, MODEL_VEHICLES_INFO_FILTER_TYPE].includes(
                    lastFilterAction
                )
            ) {
                const makeFilters = _.pick({ ...vehicle, makeId }, ['year', 'makeId']);
                this.props.fetchVehiclesInfo(MAKE_VEHICLES_INFO_FILTER_TYPE, makeFilters);
            } else if (![MODEL_VEHICLES_INFO_FILTER_TYPE].includes(lastFilterAction)) {
                const modelFilters = _.pick({ ...vehicle, modelId }, ['modelId', 'year', 'makeId']);
                this.props.fetchVehiclesInfo(MODEL_VEHICLES_INFO_FILTER_TYPE, modelFilters);
            } else {
                this.normalizeMainTableData();
                this.setState({
                    editModeFetching: false
                });
            }
        }

        return (
            <Form className={Styles.form}>
                {editableVehicle && (
                    <div className={Styles.editableVehicle}>
                        <CarOutlined className={Styles.carIcon} />
                        <ul>
                            <li className={Styles.listItem}>
                                <FormattedMessage id='add_client_form.year' />:{' '}
                                {editableVehicle.year && editableVehicle.year}
                            </li>
                            <li className={Styles.listItem}>
                                <FormattedMessage id='add_client_form.make' />:{' '}
                                {editableVehicle.make && editableVehicle.make}
                            </li>
                            <li className={Styles.listItem}>
                                <FormattedMessage id='add_client_form.model' />:{' '}
                                {editableVehicle.model && editableVehicle.model}
                            </li>
                            <li className={Styles.listItem}>
                                <FormattedMessage id='add_client_form.modification' />:{' '}
                                {editableVehicle.modification && editableVehicle.modification}{' '}
                                {editableVehicle.horsePower && `(${editableVehicle.horsePower})`}
                            </li>
                        </ul>
                    </div>
                )}
                {years && (
                    <DecoratedSelect
                        field='year'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        hasFeedback
                        initialValue={year}
                        label={<FormattedMessage id='add_client_form.year' />}
                        onSelect={value => {
                            const filters = { year: value };
                            this.props.fetchVehiclesInfo(YEAR_VEHICLES_INFO_FILTER_TYPE, filters);
                        }}
                        optionFilterProp='children'
                        placeholder={<FormattedMessage id='add_client_form.year_placeholder' />}
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                        showSearch
                    >
                        {years
                            .sort((a, b) => b - a)
                            .map(year => (
                                <Option key={v4()} value={year}>
                                    {String(year)}
                                </Option>
                            ))}
                    </DecoratedSelect>
                )}

                {years && (
                    <DecoratedSelect
                        disabled={
                            ![
                                YEAR_VEHICLES_INFO_FILTER_TYPE,
                                MAKE_VEHICLES_INFO_FILTER_TYPE,
                                MODEL_VEHICLES_INFO_FILTER_TYPE
                            ].includes(lastFilterAction)
                        }
                        dropdownMatchSelectWidth={false}
                        field='makeId'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        hasFeedback
                        label={<FormattedMessage id='add_client_form.make' />}
                        onSelect={value => {
                            const filters = _.pick({ ...vehicle, makeId: value }, [
                                'year',
                                'makeId'
                            ]);
                            this.props.fetchVehiclesInfo(MAKE_VEHICLES_INFO_FILTER_TYPE, filters);
                        }}
                        placeholder={<FormattedMessage id='add_client_form.make_placeholder' />}
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                        showSearch
                    >
                        {makes.map(({ id, name }) => (
                            <Option key={v4()} value={id}>
                                {name}
                            </Option>
                        ))}
                    </DecoratedSelect>
                )}

                {years && (
                    <DecoratedSelect
                        disabled={
                            ![
                                MAKE_VEHICLES_INFO_FILTER_TYPE,
                                MODEL_VEHICLES_INFO_FILTER_TYPE
                            ].includes(lastFilterAction)
                        }
                        dropdownMatchSelectWidth={false}
                        field='modelId'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        hasFeedback
                        label={<FormattedMessage id='add_client_form.model' />}
                        onSelect={value => {
                            const filters = _.pick({ ...vehicle, modelId: value }, [
                                'modelId',
                                'year',
                                'makeId'
                            ]);
                            this.props.fetchVehiclesInfo(MODEL_VEHICLES_INFO_FILTER_TYPE, filters);
                        }}
                        placeholder={<FormattedMessage id='add_client_form.model_placeholder' />}
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                        showSearch
                    >
                        {models.map(({ id, name }) => (
                            <Option key={v4()} value={id}>
                                {name}
                            </Option>
                        ))}
                    </DecoratedSelect>
                )}
                {years && (
                    <DecoratedSelect
                        disabled={![MODEL_VEHICLES_INFO_FILTER_TYPE].includes(lastFilterAction)}
                        dropdownMatchSelectWidth={false}
                        field='modificationId'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        hasFeedback
                        label={<FormattedMessage id='add_client_form.modification' />}
                        placeholder={
                            <FormattedMessage id='add_client_form.modification_placeholder' />
                        }
                        showSearch
                    >
                        {modifications.map(({ id, name }) => (
                            <Option key={v4()} value={id}>
                                {name}
                            </Option>
                        ))}
                    </DecoratedSelect>
                )}
                {vehicleTypes && (
                    <DecoratedSelect
                        field='vehicleTypeId'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        hasFeedback
                        initialValue={vehicleTypeId}
                        label={<FormattedMessage id='tire.vehicleType' />}
                        onChange={(value, option) => {
                            setFieldsValue({ wheelRadius: option.props.radius });
                        }}
                        placeholder={<FormattedMessage id='tire.vehicleType' />}
                        showSearch
                    >
                        {vehicleTypes.map(({ id, name, defaultRadius }) => (
                            <Option key={v4()} radius={defaultRadius} value={id}>
                                {name}
                            </Option>
                        ))}
                    </DecoratedSelect>
                )}
                {vehicleTypes && (
                    <DecoratedInputNumber
                        field='wheelRadius'
                        formatter={value => `${Math.round(value)}R`}
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        hasFeedback
                        initialValue={wheelRadius}
                        label={<FormattedMessage id='tire.radius' />}
                        max={100}
                        min={0}
                        parser={value => value.replace('R', '')}
                    />
                )}
                {!this.props.onlyVehicles && (
                    <div className={Styles.numWrapper}>
                        <DecoratedInput
                            field='number'
                            formItem
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            initialValue={number}
                            // formItemLayout={ formItemLayout }
                            label={<FormattedMessage id='add_client_form.number' />}
                            rules={[
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />

                        <VehicleNumberHistory vehicleNumber={vehicle.number} />
                    </div>
                )}
                {!this.props.onlyVehicles && (
                    <DecoratedInput
                        field='vin'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        hasFeedback
                        label={<FormattedMessage id='add_client_form.vin' />}
                    />
                )}
                <div className={Styles.addButtonWrapper}>
                    <Button
                        onClick={() => {
                            validateFields((err, values) => {
                                if (!err) {
                                    const vehicle = values;
                                    const names = _([
                                        findLabel(makes, vehicle.makeId, 'makeName'),
                                        findLabel(models, vehicle.modelId, 'modelName'),
                                        findLabel(
                                            modifications,
                                            vehicle.modificationId,
                                            'modificationName'
                                        )
                                    ])
                                        .fromPairs()
                                        .value();

                                    const filter = {
                                        id: vehicle.modificationId
                                    };

                                    const modif = _.find(modifications, filter);

                                    this.props.resetAddClientVehicleForm();
                                    if (editMode) {
                                        this.props.editClientVehicle({
                                            ...vehicle,
                                            ...names,
                                            ...(this.props.tecdoc
                                                ? {
                                                      tecdocId: _.get(modif, 'tecdocId')
                                                  }
                                                : {})
                                        });
                                    } else {
                                        this.props.addClientVehicle({
                                            ...vehicle,
                                            ...names,
                                            ...(this.props.tecdoc
                                                ? {
                                                      tecdocId: _.get(modif, 'tecdocId')
                                                  }
                                                : {})
                                        });
                                    }
                                }
                            });
                        }}
                        type='primary'
                    >
                        <FormattedMessage id={editMode ? 'edit' : 'add_client_form.add_vehicle'} />
                    </Button>
                </div>
            </Form>
        );
    }
}
