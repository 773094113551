exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__tableWrapper---15OKO{display:flex;flex-direction:column}.styles-m__tableHead---1nwK1{display:flex;justify-content:space-between;margin-bottom:16px}.styles-m__tableHeadText---3gER_{color:var(--primary)}.styles-m__purchaseButton---2aQoT{text-align:center;margin:24px}.styles-m__datePickerField---1fy9n{width:320px}.styles-m__inProgress---3WVhE{color:var(--orange)}.styles-m__paid---2Bb_r{color:var(--green)}@media only screen and (min-width:1600px){.styles-m__tableWrapper---15OKO{flex:0 1 47.5%}}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACI,YAAa,CACb,qBAKJ,CAEA,6BACI,YAAa,CACb,6BAA8B,CAC9B,kBACJ,CAEA,iCACI,oBACJ,CAEA,kCACI,iBAAkB,CAClB,WACJ,CAEA,mCACI,WACJ,CAEA,8BACI,mBACJ,CAEA,wBACI,kBACJ,CA9BI,0CAJJ,gCAKQ,cAER,CADI","file":"styles.m.css","sourcesContent":[".tableWrapper {\n    display: flex;\n    flex-direction: column;\n\n    @media only screen and (min-width: 1600px) {\n        flex: 0 1 47.5%;\n    }\n}\n\n.tableHead {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 16px;\n}\n\n.tableHeadText {\n    color: var(--primary);\n}\n\n.purchaseButton {\n    text-align: center;\n    margin: 24px;\n}\n\n.datePickerField {\n    width: 320px;\n}\n\n.inProgress {\n    color: var(--orange);\n}\n\n.paid {\n    color: var(--green);\n}\n"]}]);

// Exports
exports.locals = {
	"tableWrapper": "styles-m__tableWrapper---15OKO",
	"tableHead": "styles-m__tableHead---1nwK1",
	"tableHeadText": "styles-m__tableHeadText---3gER_",
	"purchaseButton": "styles-m__purchaseButton---2aQoT",
	"datePickerField": "styles-m__datePickerField---1fy9n",
	"inProgress": "styles-m__inProgress---3WVhE",
	"paid": "styles-m__paid---2Bb_r"
};