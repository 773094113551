import { Table } from 'antd';
import { Catcher } from 'commons';
import {
    deleteIncomeDoc,
    fetchIncomes,
    selectIncomes,
    selectIncomesLoading,
    setIncomesPage
} from 'core/storage/incomes';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import columnsConfig from './columns';

const IncomesTableComponent = props => {
    const { incomes } = props;

    useEffect(() => {
        props.fetchIncomes();
    }, []); // add incomes -> recursion

    const pagination = {
        pageSize: 25,
        size: 'large',
        total: Math.ceil(_.get(incomes, 'stats.count', 0) / 25) * 25,
        hideOnSinglePage: true,
        current: props.filters.page,
        onChange: page => {
            props.setIncomesPage(page);
            props.fetchIncomes();
        }
    };

    return (
        <Catcher>
            <StyledTable
                columns={columnsConfig(props)}
                dataSource={props.incomes.list}
                loading={props.loading}
                locale={{
                    emptyText: props.intl.formatMessage({ id: 'no_data' })
                }}
                pagination={pagination}
                rowKey={record => record.id}
                scroll={{ x: 960 }}
                size='small'
            />
        </Catcher>
    );
};

const StyledTable = styled(Table)`
    background: white;
`;

const mapStateToProps = state => ({
    user: state.auth,
    incomes: selectIncomes(state),
    loading: selectIncomesLoading(state)
});

const mapDispatchToProps = {
    fetchIncomes,
    deleteIncomeDoc,
    setIncomesPage
};

export const IncomesTable = injectIntl(connect(mapStateToProps, mapDispatchToProps)(IncomesTableComponent));
