import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import clientsDebtsContainerReducer, {
    moduleName as clientsDebtsContainerModule
} from 'containers/ClientsDebtsContainer/redux/duck';
// containers
import supplierDebtsContainerReducer, {
    moduleName as supplierDebtsContainerModule
} from 'containers/SuppliersDebtsContainer/redux/duck';
import authReducer, { moduleName as authModule } from 'core/auth/duck';
import brandsReducer, { moduleName as brandsModule } from 'core/brands/duck';
import businessPackageReducer, {
    moduleName as businessPackageModule
} from 'core/businessPackage/duck';
import callsReducer, { moduleName as callsModule } from 'core/calls/duck';
import cashReducer, { moduleName as cashModule } from 'core/cash/duck';
import chartReducer, { moduleName as chartModule } from 'core/chart/duck';
import clientReducer, { moduleName as clientModule } from 'core/client/duck';
import clientHotOperationsReducer, {
    moduleName as clientHotOperationsModule
} from 'core/clientHotOperations/duck';
import clientMRDsReducer, { moduleName as clientMRDsModule } from 'core/clientMRDs/duck';
import clientOrdersReducer, { moduleName as clientOrdersModule } from 'core/clientOrders/duck';
import clientRequisiteReducer, {
    moduleName as clientRequisiteModule
} from 'core/clientRequisite/duck';
import clientsReducer, { moduleName as clientsModule } from 'core/clients/duck';
import dashboardReducer, { moduleName as dashboardModule } from 'core/dashboard/duck';
import employeesReducer, { moduleName as employeesModule } from 'core/employees/duck';
import employeeScheduleReducer, {
    moduleName as employeeScheduleModule
} from 'core/employeeSchedule/duck';
import errorMessageReducer, { moduleName as errorMessageModule } from 'core/errorMessage/duck';
import { formsReducer as forms } from 'core/forms';
import intl from 'core/intl/reducer';
import managerRoleReducer, { moduleName as managerRoleModule } from 'core/managerRole/duck';
import modalsReducer, { moduleName as modalsModule } from 'core/modals/duck';
import myTasksReducer, { moduleName as myTasksModule } from 'core/myTasks/duck';
import orderReducer, { moduleName as orderModule } from 'core/order/duck';
import ordersReducer, { moduleName as ordersModule } from 'core/orders/duck';
import packageReducer, { moduleName as packageModule } from 'core/package/duck';
import paymentsReducer, { moduleName as paymentsModule } from 'core/payments/duck';
import reportAnalyticsReducer, {
    moduleName as reportAnalyticsModule
} from 'core/reports/reportAnalytics/duck';
import reportCashFlowReducer, {
    moduleName as reportCashFlowModule
} from 'core/reports/reportCashFlow/duck';
import reportCashOrdersLogsReducer, {
    moduleName as reportCashOrdersLogsModule
} from 'core/reports/reportCashOrdersLogs/duck';
import reportLoadKPIReducer, {
    moduleName as reportLoadKPIModule
} from 'core/reports/reportLoadKPI/duck';
// Reports
import reportOrdersReducer, {
    moduleName as reportOrdersModule
} from 'core/reports/reportOrders/duck';
import reviewReducer, { moduleName as reviewModule } from 'core/review/duck';
import reviewsReducer, { moduleName as reviewsModule } from 'core/reviews/duck';
import roleReducer, { moduleName as roleModule } from 'core/role/duck';
import searchReducer, { moduleName as searchModule } from 'core/search/duck';
import servicesSuggestionsReducer, {
    moduleName as servicesSuggestionsModule
} from 'core/servicesSuggestions/duck';
import { storageReducer as storage } from 'core/storage';
import subscriptionReducer, { moduleName as subscriptionModule } from 'core/subscription/duck';
import suppliersReducer, { moduleName as suppliersModule } from 'core/suppliers/duck';
import tecDocActionsReducer, { moduleName as tecDocActionsModule } from 'core/tecDocActions/duck';
import uiReducer, { moduleName as uiModule } from 'core/ui/duck';
import vehicleNumberHistoryReducer, {
    moduleName as vehicleNumberHistoryModule
} from 'core/vehicleNumberHistory/duck';
import vehicleTypesReducer, { moduleName as vehicleTypesModule } from 'core/vehicleTypes/duck';
import warehousesReducer, { moduleName as warehousesModule } from 'core/warehouses/duck';
import storageDocumentFormReducer, {
    moduleName as storageDocumentFormModule
} from 'forms/StorageForms/StorageDocumentForm/redux/duck';
import _ from 'lodash';
import accountsReceivablesReportModalReducer, {
    moduleName as accountsReceivablesReportModalModule
} from 'modals/AccountsReceivablesReportModal/redux/duck';
//----------------------------------------------------------------------------------------------------------------
/* Reducers of components structured by "feature first structure": https://reactjs.org/docs/faq-structure.html */
import addLaborOrDetailToOrderReducer, {
    moduleName as addLaborOrDetailToOrderModule
} from 'modals/AddLaborOrDetailToOrderModal/redux/duck';
import brandsCrossModalReducer, {
    moduleName as brandsCrossModalModule
} from 'modals/BrandsCrossModal/redux/duck';
import brandsModalReducer, { moduleName as brandsModalModule } from 'modals/BrandsModal/redux/duck';
import changeVehicleMileageModalReducer, {
    moduleName as changeVehicleMileageModalModule
} from 'modals/ChangeVehicleMileageModal/redux/duck';
import debtZeroingModalReducer, {
    mainModuleName as debtZeroingModalModule
} from 'modals/DebtZeroingModal/redux/duck';
import employeeSubtasksModalReducer, {
    moduleName as employeeSubtasksModalModule
} from 'modals/EmployeeSubTasksModal/redux/duck';
import employeeTaskModalReducer, {
    moduleName as employeeTaskModalModule
} from 'modals/EmployeeTaskModal/redux/duck';
import orderStatsInfoModalReducer, {
    moduleName as orderStatsInfoModalModule
} from 'modals/OrderStatsInfoModal/redux/duck';
import refactoredCashOrderModalReducer, {
    mainModuleName as refactoredCashOrderModalModule
} from 'modals/RefactoredCashOrderModal/redux/duck';
import registerForServiceModalReducer, {
    moduleName as registerForServiceModalModule
} from 'modals/RegisterForServiceModal/redux/duck';
import salesReportModalReducer, {
    moduleName as salesReportModalModule
} from 'modals/SalesReportModal/redux/duck';
import selectOrderModalReducer, {
    moduleName as selectOrderModalModule
} from 'modals/SelectOrderModal/redux/duck';
import storeDocServiceModalReducer, {
    moduleName as storeDocServiceModalModule
} from 'modals/StoreDocServiceModal/redux/duck';
import supplierReportModalReducer, {
    moduleName as supplierReportModalModule
} from 'modals/SuppliersReportModal/redux/duck';
import vehicleModalReducer, {
    moduleName as vehicleModalModule
} from 'modals/VehicleModal/redux/duck';
import directoryBrandsReducer, {
    moduleName as directoryBrandsModule
} from 'pages/DirectoryBrandsPage/redux/duck';
import onlineServiceBookReducer, {
    mainModuleName as onlineServiceBookModule
} from 'pages/OnlineServiceBookPage/redux/duck';
import productPageReducer, { moduleName as productPageModule } from 'pages/ProductPage/redux/duck';
import sparePartsWorkplaceReducer, {
    moduleName as sparePartsWorkplaceModule
} from 'pages/SparePartsWorkplacePage/redux/duck';
import supplierPageReducer, {
    moduleName as supplierPageModule
} from 'pages/SupplierPage/redux/duck';
import suppliersPageReducer, {
    moduleName as suppliersPageModule
} from 'pages/SuppliersPage/redux/duck';
import taskPageReducer, { moduleName as taskPageModule } from 'pages/TaskPage/redux/duck';
import tasksPageReducer, { moduleName as tasksPageModule } from 'pages/TasksPage/redux/duck';
import vehicleReducer, { moduleName as vehicleModule } from 'pages/VehiclePage/redux/duck';
import vehiclesPageReducer, {
    moduleName as vehiclesPageModule
} from 'pages/VehiclesPage/redux/duck';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { default as persistStorage } from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import history from './history';

export const persistConfig = {
    key: 'persistedStore',
    storage: persistStorage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['auth', 'subscription', 'reportOrders', 'reportLoadKPI']
};

const persistedState = {
    [authModule]: authReducer,
    [subscriptionModule]: subscriptionReducer,
    [reportOrdersModule]: reportOrdersReducer,
    [reportLoadKPIModule]: reportLoadKPIReducer,
    intl,
    router: connectRouter(history)
};

const appState = {
    forms,
    storage,
    [businessPackageModule]: businessPackageReducer,
    [callsModule]: callsReducer,
    [cashModule]: cashReducer,
    [chartModule]: chartReducer,
    [clientModule]: clientReducer,
    [clientOrdersModule]: clientOrdersReducer,
    [clientMRDsModule]: clientMRDsReducer,
    [reportAnalyticsModule]: reportAnalyticsReducer,
    [reportCashFlowModule]: reportCashFlowReducer,
    [reportCashOrdersLogsModule]: reportCashOrdersLogsReducer,
    [clientRequisiteModule]: clientRequisiteReducer,
    [clientsModule]: clientsReducer,
    [clientHotOperationsModule]: clientHotOperationsReducer,
    [dashboardModule]: dashboardReducer,
    [employeeScheduleModule]: employeeScheduleReducer,
    [employeesModule]: employeesReducer,
    [errorMessageModule]: errorMessageReducer,
    [managerRoleModule]: managerRoleReducer,
    [modalsModule]: modalsReducer,
    [myTasksModule]: myTasksReducer,
    [orderModule]: orderReducer,
    [ordersModule]: ordersReducer,
    [packageModule]: packageReducer,
    [paymentsModule]: paymentsReducer,
    [reviewModule]: reviewReducer,
    [reviewsModule]: reviewsReducer,
    [roleModule]: roleReducer,
    [searchModule]: searchReducer,
    [servicesSuggestionsModule]: servicesSuggestionsReducer,
    [suppliersModule]: suppliersReducer,
    [tecDocActionsModule]: tecDocActionsReducer,
    [uiModule]: uiReducer,
    [vehicleNumberHistoryModule]: vehicleNumberHistoryReducer,
    [warehousesModule]: warehousesReducer,
    [brandsModule]: brandsReducer,
    [vehicleTypesModule]: vehicleTypesReducer,

    [addLaborOrDetailToOrderModule]: addLaborOrDetailToOrderReducer,
    [vehicleModalModule]: vehicleModalReducer,
    [changeVehicleMileageModalModule]: changeVehicleMileageModalReducer,
    [registerForServiceModalModule]: registerForServiceModalReducer,
    [debtZeroingModalModule]: debtZeroingModalReducer,
    [salesReportModalModule]: salesReportModalReducer,
    [supplierReportModalModule]: supplierReportModalReducer,
    [accountsReceivablesReportModalModule]: accountsReceivablesReportModalReducer,
    [vehiclesPageModule]: vehiclesPageReducer,
    [vehicleModule]: vehicleReducer,
    [onlineServiceBookModule]: onlineServiceBookReducer,
    [directoryBrandsModule]: directoryBrandsReducer,
    [brandsModalModule]: brandsModalReducer,
    [brandsCrossModalModule]: brandsCrossModalReducer,
    [sparePartsWorkplaceModule]: sparePartsWorkplaceReducer,
    [supplierDebtsContainerModule]: supplierDebtsContainerReducer,
    [suppliersPageModule]: suppliersPageReducer,
    [supplierPageModule]: supplierPageReducer,
    [productPageModule]: productPageReducer,
    [clientsDebtsContainerModule]: clientsDebtsContainerReducer,
    [storageDocumentFormModule]: storageDocumentFormReducer,
    [storeDocServiceModalModule]: storeDocServiceModalReducer,
    [selectOrderModalModule]: selectOrderModalReducer,
    [refactoredCashOrderModalModule]: refactoredCashOrderModalReducer,
    [employeeTaskModalModule]: employeeTaskModalReducer,
    [employeeSubtasksModalModule]: employeeSubtasksModalReducer,
    [orderStatsInfoModalModule]: orderStatsInfoModalReducer,
    [tasksPageModule]: tasksPageReducer,
    [taskPageModule]: taskPageReducer
};

const appReducer = combineReducers({ ...persistedState, ...appState });

const reducer = (state, action) => {
    const { type, payload } = action;
    let resetedState = null;

    if (
        type === LOCATION_CHANGE &&
        _.get(payload, 'location.pathname') !== _.get(state, 'router.location.pathname')
    ) {
        resetedState = Object.keys(persistedState).reduce((resetedState, moduleName) => {
            resetedState[moduleName] = state[moduleName];

            return resetedState;
        }, {});
    }

    return appReducer(resetedState || state, action);
};

const rootReducer = persistReducer(persistConfig, (state, action) => {
    return reducer(state, action);
});

export default rootReducer;
