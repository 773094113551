exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__excelWrap---1AAR7{display:flex;justify-content:center}.styles-m__download---31ZQ3{margin-right:18px}.styles-m__excel---2isoI{z-index:2}.styles-m__ogTitle---3nYJB{font-size:16px;font-weight:700}.styles-m__ogDesc---3Hzxm{margin-bottom:8px;margin-top:8px;opacity:.7}.styles-m__ogImg---hM47L{margin-bottom:4px;margin-top:4px}.styles-m__linkBlock---AgEJz{margin-bottom:12px;background-color:#f5f5f5;border:1px solid #8a8d8a;padding:4px;border-radius:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,6BACI,YAAa,CACb,sBACJ,CAEA,4BACI,iBACJ,CAEA,yBACI,SACJ,CAMA,2BACI,cAAe,CACf,eACJ,CAEA,0BACI,iBAAkB,CAClB,cAAe,CACf,UACJ,CAEA,yBACI,iBAAkB,CAClB,cACJ,CAEA,6BACI,kBAAmB,CACnB,wBAA4B,CAC5B,wBAAyB,CACzB,WAAY,CACZ,iBACJ","file":"styles.m.css","sourcesContent":[".excelWrap {\n    display: flex;\n    justify-content: center;\n}\n\n.download {\n    margin-right: 18px;\n}\n\n.excel {\n    z-index: 2;\n}\n\n.upload {\n    \n}\n\n.ogTitle {\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.ogDesc {\n    margin-bottom: 8px;\n    margin-top: 8px;\n    opacity: 0.7;\n}\n\n.ogImg {\n    margin-bottom: 4px;\n    margin-top: 4px;\n}\n\n.linkBlock {\n    margin-bottom: 12px;\n    background-color: whitesmoke;\n    border: 1px solid #8a8d8a;\n    padding: 4px;\n    border-radius: 8px;\n}"]}]);

// Exports
exports.locals = {
	"excelWrap": "styles-m__excelWrap---1AAR7",
	"download": "styles-m__download---31ZQ3",
	"excel": "styles-m__excel---2isoI",
	"upload": "styles-m__upload---1Z0-k",
	"ogTitle": "styles-m__ogTitle---3nYJB",
	"ogDesc": "styles-m__ogDesc---3Hzxm",
	"ogImg": "styles-m__ogImg---hM47L",
	"linkBlock": "styles-m__linkBlock---AgEJz"
};