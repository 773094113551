import { all, call, take } from 'redux-saga/effects';
import { setCollapsedState } from 'utils';
import { SET_COLLAPSED_STATE } from './duck';

export function* layoutSaga() {
    while (true) {
        try {
            const { payload: state } = yield take(SET_COLLAPSED_STATE);
            yield setCollapsedState(state);
        } catch (error) {
            throw new Error(error);
        }
    }
}

export function* saga() {
    yield all([call(layoutSaga)]);
}
