// Core
import { Catcher, Loader } from 'commons';
import { Line, MiniPie, Pie } from 'components/Charts';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ChartControls from './ChartControls';
import Styles from './styles.m.css';

export default class CallsStatistics extends Component {
    render() {
        const { chart, pieStats, stats, setCallsChartMode, fetchCallsChart, callsChartFetching } =
            this.props;

        const piesRow = this._renderPiesRow();

        const barConfig = {
            dataKeys: [
                {
                    name: 'total',
                    color: 'rgba(155, 89, 182, 0.85)'
                },
                {
                    name: 'answered',
                    color: 'rgba(81, 205, 102, 0.85)'
                },
                {
                    name: 'notAnswered',
                    color: 'rgba(255, 126, 126, 0.85)'
                },
                {
                    name: 'busy',
                    color: 'rgba(251, 158, 62, 0.85)'
                }
            ]
        };

        return callsChartFetching ? (
            <Loader loading={callsChartFetching} />
        ) : (
            <Catcher>
                <div className={Styles.callsStatistics}>
                    <div className={Styles.column}>
                        <ChartControls
                            fetchCallsChart={fetchCallsChart}
                            setCallsChartMode={setCallsChartMode}
                        />
                        <Line
                            config={barConfig}
                            data={chart}
                            height={360}
                            intlCtx='calls-statistics'
                        />
                    </div>
                    <div className={Styles.column}>
                        {piesRow}
                        <div className={Styles.pieChart}>
                            <Pie
                                data={pieStats}
                                hasLegend
                                height={248}
                                intlCtx='calls-statistics'
                                lineWidth={4}
                                subTitle={<FormattedMessage id='calls-statistics.all' />}
                                total={stats.total}
                            />
                        </div>
                    </div>
                </div>
            </Catcher>
        );
    }

    _renderPiesRow = () => {
        const { stats } = this.props;

        return (
            <div className={Styles.piesRow}>
                <MiniPie
                    color='rgb(81, 205, 102)'
                    label='calls-statistics.answered_calls_efficiency' // secondary
                    percent={stats.answered}
                />
                <MiniPie
                    color='rgb(255, 126, 126)'
                    label='calls-statistics.missed_calls_percent' // warning
                    percent={stats.notAnswered}
                />
                <MiniPie
                    color='rgb(155, 89, 182)'
                    label='calls-statistics.became_your_clients' // primary
                    percent={stats.becameClients}
                />
            </div>
        );
    };
}
