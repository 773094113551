export const filterTreeByActive = nodes => {
    const filteredNodes = nodes.reduce((accumulator, node) => {
        if (node.active === true) {
            const filteredNode = {
                value: node.id,
                title: `(#${node.id}) ${node.name} `,
                level: node.level,
                disabled: node.level !== 4
            };

            if (node.children) {
                const filteredChildren = filterTreeByActive(node.children);
                if (filteredChildren.length > 0) {
                    filteredNode.children = filteredChildren;
                }
            }

            accumulator.push(filteredNode);
        }

        return accumulator;
    }, []);

    return filteredNodes;
};
