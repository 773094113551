import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    FolderAddOutlined,
    MailOutlined,
    MessageOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Pagination, Popover, Row, Spin, notification } from 'antd';
import { Block, DataItem } from 'commons';
import { Barcode } from 'components';
import { MODALS, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { ClientVehicleTransfer, TecDocInfoModal, VehicleModal } from 'modals';
import {
    createOrder,
    deleteVehicle,
    fetchVehicle,
    fetchVehicleAttributes,
    fetchVehicleOrdersLatest,
    selectClient,
    selectFetchingOrdersLatest,
    selectFetchingVehicle,
    selectFetchingVehicleAttributes,
    selectFetchingVehicleClient,
    selectGeneralData,
    /* -------Selectors----*/
    selectVehicle,
    selectVehicleAttributes,
    sendEmailWithServiceBookLink,
    sendSmsWithServiceBookLink
} from 'pages/VehiclePage/redux/duck';
import { generateOnlineServiceBookLink, handleCopyToClipboard } from 'pages/VehiclePage/utils';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import history from 'store/history';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const DATE_FORMATT = 'DD.MM.YYYY';

const mapStateToProps = state => ({
    user: state.auth,
    vehicle: selectVehicle(state),
    client: selectClient(state),
    generalData: selectGeneralData(state),
    vehicleAttributes: selectVehicleAttributes(state),
    fetchingVehicle: selectFetchingVehicle(state),
    fetchingVehicleClient: selectFetchingVehicleClient(state),
    fetchingOrdersLatest: selectFetchingOrdersLatest(state),
    fetchingVehicleAttributes: selectFetchingVehicleAttributes(state)
});

const mapDispatchToProps = {
    setModal,
    fetchVehicle,
    fetchVehicleAttributes,
    fetchVehicleOrdersLatest,
    sendEmailWithServiceBookLink,
    sendSmsWithServiceBookLink,

    createOrder,
    deleteVehicle
};

/**
 * Supposed to represent general vehicle informations and its specific parameters.
 */
@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientVehiclesTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 5
        };
    }

    /**
     * This event handler is used to create an order which will contain specific client and may contain vehicle if id was provided
     * @param {*} param0 Contains clientId which is used to define client in order and vehicleId of this client
     */
    onCreateOrder = ({ clientId, vehicleId }) => {
        const { user } = this.props;

        if (!clientId) {
            notification.error({
                message: this.props.intl.formatMessage({
                    id: 'vehicle_page.add_owner_vehicle'
                })
            });
        } else {
            this.props.createOrder({ clientId, managerId: user.id, vehicleId });
        }
    };

    onEditVehicle = ({ vehicleId }) => {
        this.props.setModal(MODALS.VEHICLE, {
            mode: 'EDIT',
            vehicleId,
            onSubmit: () => window.location.reload()
        });
    };

    onAddVehicle = ({ clientId }) => {
        this.props.setModal(MODALS.VEHICLE, { mode: 'ADD', clientId });
    };

    onViewVehicle = ({ vehicleId }) => {
        this.props.setModal(MODALS.VEHICLE, { mode: 'VIEW', vehicleId });
    };

    render() {
        const {
            user: { businessId },

            clientEntity,

            clientId,
            vehicleAttributes,
            fetchingVehicle,
            fetchingVehicleClient,

            deleteVehicle,
            sendEmailWithServiceBookLink,
            sendSmsWithServiceBookLink,

            intl: { formatMessage },
            user,
            disabled,
            clientVehicles,
            count
        } = this.props;

        const accessGranted = isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_CARS_SERVICE_BOOK, accesses.ROWO);

        const { page, pageSize } = this.state;

        const pagination = {
            pageSizeOptions: [5, 10, 20, 50],
            pageSize,
            size: 'large',
            total: Math.ceil(count / pageSize) * pageSize,
            current: page,
            onChange: (page, pageSize) => {
                this.setState({
                    page,
                    pageSize
                });
                this.props.fetchVehicles(page, pageSize);
            }
        };

        return (
            <div className={Styles.tabContent}>
                <div>
                    {clientVehicles.map(
                        ({
                            id,
                            barcode,
                            modificationId,
                            number,
                            vin,
                            make,
                            model,
                            modification,
                            year,
                            colorName,
                            managerName,
                            managerPhone,
                            comment,
                            driveType,
                            power,
                            bodyType,
                            engineCode,
                            capacity,
                            fuelType
                        }) => (
                            <Block
                                className={Styles.block}
                                controls={
                                    !disabled && (
                                        <div>
                                            <Popover
                                                content={<FormattedMessage id='vehicle_page.hint_barcode_modal' />}
                                            >
                                                <span style={{ width: '2em' }}>
                                                    <Barcode
                                                        barcodeClassName={Styles.barcodeIcon}
                                                        iconStyle={{
                                                            fontSize: 24,
                                                            marginLeft: 4
                                                        }}
                                                        onConfirm={() => {
                                                            window.location.reload();
                                                        }}
                                                        prefix='CVH'
                                                        provideHint={false}
                                                        referenceId={id}
                                                        table='CLIENTS_VEHICLES'
                                                        value={barcode}
                                                    />
                                                </span>
                                            </Popover>

                                            <Popover
                                                content={<FormattedMessage id='vehicle_page.hint_vehicle_info_modal' />}
                                            >
                                                <span>
                                                    <TecDocInfoModal
                                                        modificationId={clientEntity.vehicles && modificationId}
                                                    />
                                                </span>
                                            </Popover>

                                            {/* <Popover
                                content={
                                    <FormattedMessage id='vehicle_page.hint_view_vehicle_modal' />
                                }
                            >
                                <EyeOutlined
                                    className={Styles.editIcon}
                                    onClick={() =>
                                        this.onViewVehicle({ vehicleId: _.get(vehicle, 'id') })
                                    }
                                />
                            </Popover> */}

                                            <Popover
                                                content={<FormattedMessage id='vehicle_page.hint_edit_vehicle_modal' />}
                                            >
                                                <EditOutlined
                                                    className={Styles.editIcon}
                                                    onClick={() =>
                                                        this.onEditVehicle({
                                                            vehicleId: id
                                                        })
                                                    }
                                                />
                                            </Popover>

                                            <Popover
                                                content={<FormattedMessage id='vehicle_page.hint_add_vehicle_modal' />}
                                            >
                                                <FolderAddOutlined
                                                    className={Styles.editIcon}
                                                    onClick={() =>
                                                        this.onAddVehicle({
                                                            clientId
                                                        })
                                                    }
                                                />
                                            </Popover>

                                            <Popover
                                                content={<FormattedMessage id='vehicle_page.hint_delete_vehicle' />}
                                            >
                                                <DeleteOutlined
                                                    className={Styles.deleteIcon}
                                                    onClick={() => {
                                                        deleteVehicle({
                                                            vehicleId: id
                                                        });
                                                        history.push({
                                                            pathname: `${book.vehicles}`
                                                        });
                                                    }}
                                                />
                                            </Popover>

                                            {isGrantAccessed(
                                                user,
                                                grants.DIRECTORIES_CUSTOMERS_LIST_CARS_CAR_OWNER_CHANGE
                                            ) && (
                                                <Popover
                                                    content={
                                                        <FormattedMessage id='vehicle_page.hint_change_vehicle_owner' />
                                                    }
                                                >
                                                    {fetchingVehicleClient ? (
                                                        <Spin />
                                                    ) : (
                                                        <span className={Styles.changeVehicleOwnerIcon}>
                                                            <ClientVehicleTransfer
                                                                clientId={clientId}
                                                                vehicleId={id}
                                                                vehicles={clientVehicles}
                                                            />
                                                        </span>
                                                    )}
                                                </Popover>
                                            )}

                                            <Popover
                                                content={
                                                    <FormattedMessage id='vehicle_page.hint_create_order_for_this_vehicle' />
                                                }
                                            >
                                                <Button
                                                    className={Styles.iconButton}
                                                    onClick={() =>
                                                        this.onCreateOrder({
                                                            clientId,
                                                            vehicleId: id
                                                        })
                                                    }
                                                    type='primary'
                                                >
                                                    <PlusOutlined className={Styles.plusIcon} />
                                                </Button>
                                            </Popover>
                                        </div>
                                    )
                                }
                                loading={fetchingVehicle}
                                title={<FormattedMessage id='orders.vehicle' />}
                            >
                                <div>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='add_client_form.number' />}
                                    >
                                        {number}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='add_order_form.vin' />}
                                    >
                                        {vin}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='add_client_form.make' />}
                                    >
                                        {make}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='add_client_form.model' />}
                                    >
                                        {model}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='add_client_form.modification' />}
                                    >
                                        {modification}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='add_client_form.year' />}
                                    >
                                        {year}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='add_client_form.color' />}
                                    >
                                        {colorName || '-'}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='online_service_book_page.manager_name' />}
                                    >
                                        {managerName}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='online_service_book_page.manager_phone' />}
                                    >
                                        {managerPhone}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='add_client_form.comment' />}
                                    >
                                        {comment || '-'}
                                    </DataItem>
                                </div>

                                <div>
                                    {_.get(vehicleAttributes, 'stateData') && (
                                        <div>
                                            {/* <DataItem className={Styles.dataItem} label={<FormattedMessage id='vehicle_page.color' />}>{vehicleAttributes.stateData.color}</DataItem> */}
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='vehicle_page.body_type' />}
                                            >
                                                {vehicleAttributes.stateData.bodyType}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='vehicle_page.fuel_type' />}
                                            >
                                                {vehicleAttributes.stateData.fuel}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='vehicle_page.kind' />}
                                            >
                                                {vehicleAttributes.stateData.kind}
                                            </DataItem>
                                            {/* <DataItem className={Styles.dataItem} label={<FormattedMessage id='vehicle_page.purpose' />}>{vehicleAttributes.stateData.purpose}</DataItem> */}
                                        </div>
                                    )}
                                    <div>
                                        <DataItem
                                            className={Styles.dataItem}
                                            label={<FormattedMessage id='vehicle_page.engine' />}
                                        >
                                            {engineCode}
                                        </DataItem>
                                        <DataItem
                                            className={Styles.dataItem}
                                            label={<FormattedMessage id='vehicle_page.capacity' />}
                                        >
                                            {capacity}
                                        </DataItem>
                                        <DataItem
                                            className={Styles.dataItem}
                                            label={<FormattedMessage id='vehicle_page.body_type' />}
                                        >
                                            {bodyType}
                                        </DataItem>
                                        {!_.get(vehicleAttributes, 'stateData.fuel') && (
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='vehicle_page.fuel_type' />}
                                            >
                                                {fuelType}
                                            </DataItem>
                                        )}
                                        <DataItem
                                            className={Styles.dataItem}
                                            label={<FormattedMessage id='vehicle_page.power' />}
                                        >
                                            {power}
                                        </DataItem>
                                        <DataItem
                                            className={Styles.dataItem}
                                            label={<FormattedMessage id='vehicle_page.drive_type' />}
                                        >
                                            {driveType}
                                        </DataItem>
                                    </div>
                                </div>

                                <div className={Styles.buttonsContainer}>
                                    <Row className={Styles.row}>
                                        <Col span={6}>
                                            <Button
                                                className={Styles.button}
                                                disabled={disabled || !accessGranted}
                                                onClick={async () => {
                                                    const link = await generateOnlineServiceBookLink({
                                                        vehicleId: id,
                                                        businessId
                                                    });
                                                    window.open(link, '_blank'); // Open in a new tab
                                                }}
                                                type='primary'
                                            >
                                                <FormattedMessage id='vehicle_page.service_book' />
                                            </Button>
                                        </Col>

                                        {accessGranted && (
                                            <Col span={18}>
                                                <Popover
                                                    content={<FormattedMessage id='vehicle_page.hint_send_via_sms' />}
                                                >
                                                    <MessageOutlined
                                                        className={Styles.sendSMSIcon}
                                                        onClick={() => sendSmsWithServiceBookLink()}
                                                    />
                                                </Popover>
                                                <Popover
                                                    content={<FormattedMessage id='vehicle_page.hint_send_via_email' />}
                                                >
                                                    <MailOutlined
                                                        className={Styles.sendMailIcon}
                                                        onClick={() => sendEmailWithServiceBookLink()}
                                                    />
                                                </Popover>
                                                <Popover
                                                    content={
                                                        <FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />
                                                    }
                                                >
                                                    <CopyOutlined
                                                        className={Styles.copyIcon}
                                                        onClick={async () => {
                                                            const link = await generateOnlineServiceBookLink({
                                                                vehicleId: id,
                                                                businessId
                                                            });
                                                            handleCopyToClipboard(link);
                                                            notification.success();
                                                        }}
                                                    />
                                                </Popover>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </Block>
                        )
                    )}

                    <VehicleModal />
                </div>
                <div className={Styles.pagination}>
                    <Pagination {...pagination} />
                </div>
            </div>
        );
    }
}
