import { Input } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const DEF_DATE_FORMAT = 'YYYY/MM/DD';
const DEF_UI_DATE_FORMAT = 'DD/MM/YYYY';

@injectIntl
export default class ReportLoadKPIFilter extends Component {
    constructor(props) {
        super(props);

        this.handleSearch = _.debounce(value => {
            const { setReportLoadKPIQuery, fetchReportLoadKPI } = this.props.filterControls;
            setReportLoadKPIQuery(value);
            fetchReportLoadKPI();
        }, 1000).bind(this);
    }

    setDoneDaterange = daterange => {
        const { setReportLoadKPIDoneFromDate, setReportLoadKPIDoneToDate, fetchReportLoadKPI } =
            this.props.filterControls;
        const [startDate, endDate] = daterange;

        setReportLoadKPIDoneFromDate(startDate ? startDate.format(DEF_DATE_FORMAT) : undefined);
        setReportLoadKPIDoneToDate(endDate ? endDate.format(DEF_DATE_FORMAT) : undefined);
        fetchReportLoadKPI();
    };

    onSearch = e => {
        const value = e.target.value.replace(/[+()]/g, '');
        this.handleSearch(value);
    };

    render() {
        const { filter, disabled } = this.props;

        // If it is needed to disable filters just set this style to main container
        const disabledStyle = {
            pointerEvents: 'none',
            opacity: '0.7'
        };

        return (
            <div className={Styles.mainCont} style={disabled ? disabledStyle : undefined}>
                <div className={Styles.datePickerCont} disabled={disabled}>
                    <DateRangePicker
                        className={Styles.datePicker}
                        dateRange={[dayjs(filter.doneFromDate), dayjs(filter.doneToDate)]}
                        onDateChange={this.setDoneDaterange}
                    />
                </div>
                <div className={Styles.inputCont}>
                    <Input
                        allowClear
                        className={Styles.input}
                        defaultValue={filter.query}
                        onChange={this.onSearch}
                        placeholder={this.props.intl.formatMessage({
                            id: 'report_orders_page.search'
                        })}
                    />
                </div>
            </div>
        );
    }
}
