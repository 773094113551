/* eslint-disable max-classes-per-file */
import { ApiOutlined, CheckCircleFilled, ClockCircleFilled, FilterOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Menu, Radio, Select, notification } from 'antd';
import { DateRangePicker } from 'components';
import { fetchWarehouses } from 'core/warehouses/duck';
import _ from 'lodash';
import * as constants from 'pages/Storage/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { RangePicker } = DatePicker;
const { Option } = Select;
let isOrder;
let isTransfer;
const INCOME = 'INCOME';
const EXPENSE = 'EXPENSE';
const SUPPLIER = 'SUPPLIER';
const RESERVE = 'RESERVE';
const CLIENT = 'CLIENT';
const INVENTORY = 'INVENTORY';
const OWN_CONSUMPTION = 'OWN_CONSUMPTION';
const TRANSFER = 'TRANSFER';
const ADJUSTMENT = 'ADJUSTMENT';
const ORDERINCOME = 'ORDERINCOME';
const ORDER = 'ORDER';
const NEW = 'NEW';
const DONE = 'DONE';
const MAIN = 'MAIN';
const TOOL = 'TOOL';
const REPAIR_AREA = 'REPAIR_AREA';
const STORAGE_ORDERS_EXPENSE = 'STORAGE_ORDERS_EXPENSE';
const PRODUCTION = 'PRODUCTION';
const DISASSEMBLY = 'DISASSEMBLY';

const typeToDocumentType = {
    income: {
        operationCodes: ['INC', 'STP', 'SRV', 'CRT', 'KPP', 'PRI', 'ICI']
    },
    expense: {
        operationCodes: ['SRT', 'OUT', 'STM', 'CST', 'AUT', 'VRT', 'KPM', 'DSO', 'ICO']
    },
    transfer: {
        operationCodes: ['TSF', 'TOL', 'TOR', 'TIC']
    },
    order: {
        operationCodes: ['ORD', 'BOR', 'COM']
    }
};

const mapStateToProps = state => ({
    warehouses: state.warehouses.warehouses,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchWarehouses
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class StorageDocumentsFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: undefined,
            documentType: undefined,
            apiSettings: []
        };

        const { pathname } = props.history.location;
        isOrder = pathname == '/storage-orders';
        isTransfer = pathname == '/storage-transfers';
    }

    componentDidMount() {
        const { history, documentTypeFilter } = this.props;
        if (history.location.state) {
            const operationCode = _.get(history.location, 'state.formData.operationCode', undefined);
            this.setState({
                documentType: operationCode
            });
            documentTypeFilter(operationCode);
        }
        this.fetchData();
    }

    fetchData = async () => {
        const apiSettings = await fetchAPI('POST', 'external_api/get_auth', null, null, {
            handleErrorInternally: true
        });
        this.setState({
            apiSettings
        });
    };

    render() {
        const {
            dateRange,
            dateFormat,
            onDateChange,
            typeFilter,
            documentTypeFilter,
            documentStatusFilter,
            type,
            listType,
            user,
            isMobile
        } = this.props;
        const { documentType, apiSettings } = this.state;

        const allSuppliers = apiSettings.map(({ supplierName }) => supplierName);

        const menu = (
            <Menu>
                {apiSettings
                    .filter(({ token, login, password }) => token || (login && password))
                    .map(({ supplierName }) => (
                        <Menu.Item
                            data-qa='menu_item__api_document_storage_document_page'
                            onClick={async () => {
                                try {
                                    await fetchAPI(
                                        'POST',
                                        'store_docs_api',
                                        null,
                                        {
                                            suppliers: [supplierName],
                                            operationCode: listType === constants.INCOME ? 'INC' : 'COM'
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    await this.props.fetchStorageList();
                                } catch (error) {
                                    if (error) {
                                        notification.error({
                                            message: this.props.intl.formatMessage({
                                                id: 'order_form_table.error_API'
                                            })
                                        });
                                    }
                                }
                            }}
                            style={{
                                border: '1px solid lightgrey',
                                marginBottom: 8
                            }}
                        >
                            {supplierName}
                        </Menu.Item>
                    ))}
                <Menu.Item
                    data-qa='menu_item__api_document_storage_document_page'
                    onClick={async () => {
                        try {
                            await fetchAPI(
                                'POST',
                                'store_docs_api',
                                null,
                                {
                                    suppliers: [...allSuppliers],
                                    operationCode: listType === constants.INCOME ? 'INC' : 'COM'
                                },
                                { handleErrorInternally: true }
                            );
                            await this.props.fetchStorageList();
                        } catch (error) {
                            if (error) {
                                notification.error({
                                    message: this.props.intl.formatMessage({
                                        id: 'order_form_table.error_API'
                                    })
                                });
                            }
                        }
                    }}
                    style={{
                        backgroundColor: 'var(--primary)',
                        border: '1px solid lightgrey'
                    }}
                >
                    <span
                        style={{
                            color: 'white'
                        }}
                    >
                        <FormattedMessage id='storage_document.check_all_API' />
                    </span>
                </Menu.Item>
            </Menu>
        );

        const disabledForRights =
            !isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_SUPPLIER_ORDERS_API) ||
            !isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_RECEIPTS_API) ||
            !isGrantAccessed(user, grants.WAREHOUSE_DOCUMENTS_LIST_WAREHOUSE_ISSUES_API);

        const mobileFilters = (
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item key='document-type'>
                            <Radio.Group
                                buttonStyle='solid'
                                onChange={event => {
                                    const { value } = event.target;
                                    documentTypeFilter(value);
                                    this.setState({
                                        documentType: value
                                    });
                                }}
                                style={{ display: 'grid' }}
                            >
                                <Radio.Button value={null}>
                                    <FormattedMessage id='storage_document.all' />
                                </Radio.Button>
                                {typeToDocumentType[type.toLowerCase()].operationCodes.map((counterpart, i) => (
                                    <Radio.Button key={i} value={counterpart}>
                                        <FormattedMessage id={`storage_document.docType.${counterpart}`} />
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </Menu.Item>
                        <Menu.Item key='document-status'>
                            <Radio.Group
                                buttonStyle='solid'
                                defaultValue={null}
                                onChange={event => {
                                    documentStatusFilter(event.target.value);
                                }}
                            >
                                <Radio.Button value={null}>
                                    <FormattedMessage id='storage_document.all' />
                                </Radio.Button>
                                <Radio.Button value='NEW'>
                                    <FormattedMessage id='storage_document.status_created' />{' '}
                                    <ClockCircleFilled style={{ color: 'var(--orange)' }} />
                                </Radio.Button>
                                <Radio.Button value='DONE'>
                                    <FormattedMessage id='storage_document.status_confirmed' />{' '}
                                    <CheckCircleFilled style={{ color: 'var(--green)' }} />
                                </Radio.Button>
                            </Radio.Group>
                        </Menu.Item>
                    </Menu>
                }
                placement='bottom'
            >
                <FilterOutlined style={{ fontSize: 18, marginRight: 8, color: 'var(--primary)' }} />
            </Dropdown>
        );

        const desktopFilters = (
            <React.Fragment>
                <div className={Styles.filterRadioButtonGroup}>
                    <Dropdown
                        className={Styles.datePickerButton}
                        overlay={
                            <Radio.Group
                                // buttonStyle="solid"
                                onChange={event => {
                                    const { value } = event.target;
                                    documentTypeFilter(value);
                                    this.setState({
                                        documentType: event.target.value
                                    });
                                }}
                            >
                                <Radio.Button value={null}>
                                    <FormattedMessage id='storage_document.all' />
                                </Radio.Button>
                                {typeToDocumentType[type.toLowerCase()].operationCodes.map((counterpart, i) => {
                                    return (
                                        <Radio.Button key={i} value={counterpart}>
                                            <FormattedMessage id={`storage_document.docType.${counterpart}`} />
                                        </Radio.Button>
                                    );
                                })}
                            </Radio.Group>
                        }
                    >
                        <Button>
                            {documentType ? (
                                <FormattedMessage id={`storage_document.docType.${documentType}`} />
                            ) : (
                                <FormattedMessage id='storage_document.all' />
                            )}
                        </Button>
                    </Dropdown>
                </div>
                <div className={Styles.filterRadioButtonGroup}>
                    <Radio.Group
                        // buttonStyle="solid"
                        defaultValue={null}
                        onChange={event => {
                            documentStatusFilter(event.target.value);
                        }}
                    >
                        <Radio.Button value={null}>
                            <FormattedMessage id='storage_document.all' />
                        </Radio.Button>
                        <Radio.Button value='NEW'>
                            <FormattedMessage id='storage_document.status_created' />{' '}
                            <ClockCircleFilled style={{ color: 'var(--orange)' }} />
                        </Radio.Button>
                        <Radio.Button value='DONE'>
                            <FormattedMessage id='storage_document.status_confirmed' />{' '}
                            <CheckCircleFilled style={{ color: 'var(--green)' }} />
                        </Radio.Button>
                    </Radio.Group>
                </div>
            </React.Fragment>
        );

        return (
            <div className={Styles.filtersWrap}>
                {listType != STORAGE_ORDERS_EXPENSE && (isMobile ? mobileFilters : desktopFilters)}
                {(listType === constants.INCOME || listType === constants.ORDER) && (
                    <Dropdown overlay={menu}>
                        {!isMobile ? (
                            <Button
                                disabled={disabledForRights}
                                style={{ fontSize: 14, marginRight: 10 }}
                                type='primary'
                            >
                                API
                            </Button>
                        ) : (
                            <ApiOutlined style={{ fontSize: 18 }} />
                        )}
                    </Dropdown>
                )}
                <DateRangePicker
                    dateFormat={dateFormat}
                    dateRange={dateRange}
                    minimize
                    onDateChange={onDateChange}
                    style={{ fontSize: 20 }}
                />
            </div>
        );
    }
}

export default StorageDocumentsFilters;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class WarehouseSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            warehouses: []
        };
    }

    getWarehouses() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/warehouses`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((warehouse, i) => {
                    warehouse.key = i;
                });
                that.setState({
                    warehouses: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    componentDidMount() {
        this.props.fetchWarehouses();
    }

    render() {
        const {
            intl: { formatMessage },
            style,
            onChange
        } = this.props;
        const { warehouses } = this.state;
        const options = this.props.warehouses.map((warehouse, key) => (
            <Option key={key} value={warehouse.id} warehouse_attribute={warehouse.attribute}>
                {warehouse.name}
            </Option>
        ));

        return (
            <div className={Styles.warehouseSelect} style={style}>
                <Select
                    allowClear
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                        zIndex: '9999',
                        minWidth: 220
                    }}
                    onChange={value => {
                        onChange(value);
                    }}
                    placeholder={formatMessage({ id: 'storage' })}
                    showSearch
                    style={{ minWidth: 220 }}
                >
                    {options}
                </Select>
            </div>
        );
    }
}

@injectIntl
export class BrandSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brands: [],
            searchValue: ''
        };
    }

    getBrands() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/brands`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((brand, i) => {
                    brand.key = i;
                });
                that.setState({
                    brands: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    componentDidMount() {
        this.getBrands();
    }

    render() {
        const {
            intl: { formatMessage },
            style,
            onSelect
        } = this.props;
        const { brands, searchValue } = this.state;
        const options = brands.map((brand, key) => (
            <Option key={key} value={brand.brandId}>
                {brand.brandName}
            </Option>
        ));

        return (
            <div className={Styles.warehouseSelect} style={style}>
                <Select
                    allowClear
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                        zIndex: '9999',
                        minWidth: 220
                    }}
                    filterOption={(input, option) => {
                        return (
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            String(option.value).indexOf(input.toLowerCase()) >= 0
                        );
                    }}
                    onBlur={() => {
                        this.setState({
                            searchValue: ''
                        });
                    }}
                    onSearch={input => {
                        this.setState({
                            searchValue: input
                        });
                    }}
                    onSelect={value => {
                        onSelect(value);
                    }}
                    placeholder={formatMessage({ id: 'order_form_table.brand' })}
                    showSearch
                    style={{ minWidth: 220 }}
                >
                    {searchValue.length > 1 ? options : []}
                </Select>
            </div>
        );
    }
}
