exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__stats---qtJsE{margin-bottom:24px}.styles-m__extendedStats---8vwyM,.styles-m__stats---qtJsE{display:flex;justify-content:space-between}.styles-m__count---oisv2{display:flex;flex-direction:column;border:1px solid var(--primary);text-align:center;padding:8px;flex:0 0 15%}.styles-m__extendedCounts---1wW2t{display:flex;flex-direction:column;border-bottom:1px solid var(--text2);border-top:1px solid var(--text2);text-align:center;padding:8px;flex:0 1 30%}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBAGI,kBACJ,CAEA,0DALI,YAAa,CACb,6BAOJ,CAEA,yBACI,YAAa,CACb,qBAAsB,CACtB,+BAAgC,CAChC,iBAAkB,CAClB,WAAY,CACZ,YACJ,CAEA,kCACI,YAAa,CACb,qBAAsB,CACtB,oCAAqC,CACrC,iCAAkC,CAClC,iBAAkB,CAClB,WAAY,CACZ,YACJ","file":"styles.m.css","sourcesContent":[".stats {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 24px;\n}\n\n.extendedStats {\n    display: flex;\n    justify-content: space-between;\n}\n\n.count {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--primary);\n    text-align: center;\n    padding: 8px;\n    flex: 0 0 15%;\n}\n\n.extendedCounts {\n    display: flex;\n    flex-direction: column;\n    border-bottom: 1px solid var(--text2);\n    border-top: 1px solid var(--text2);\n    text-align: center;\n    padding: 8px;\n    flex: 0 1 30%;\n}\n"]}]);

// Exports
exports.locals = {
	"stats": "styles-m__stats---qtJsE",
	"extendedStats": "styles-m__extendedStats---8vwyM",
	"count": "styles-m__count---oisv2",
	"extendedCounts": "styles-m__extendedCounts---1wW2t"
};