import { Button, Table } from 'antd';
import { Catcher } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

@injectIntl
class ClientsVehiclesTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.model'
                }),
                dataIndex: 'modelName',
                key: 'modelName'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.year'
                }),
                dataIndex: 'year',
                key: 'year'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.modification'
                }),
                dataIndex: 'modificationName',
                key: 'modificationName'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.vin'
                }),
                dataIndex: 'vin',
                key: 'vin'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.number'
                }),
                dataIndex: 'number',
                key: 'number'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.delete_vehicle'
                }),
                key: 'delete-vehicle',
                render: (text, record) => (
                    <Button
                        onClick={() => this.props.removeClientVehicle(record.index)}
                        type='primary'
                    >
                        <FormattedMessage id='add_client_form.delete_vehicle' />
                    </Button>
                )
            }
        ];
    }

    render() {
        const { vehicles } = this.props;
        const { columns } = this;

        return (
            <Catcher>
                <Table
                    bordered
                    className={Styles.clientsVehiclesTable}
                    columns={columns}
                    dataSource={vehicles.map((vehicle, index) => ({
                        ...vehicle,
                        index,
                        key: v4()
                    }))}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                />
            </Catcher>
        );
    }
}

export default ClientsVehiclesTable;
