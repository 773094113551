// New Document
const NEW_DOCUMENT = 'NEW_DOCUMENT';
const NEW_DOCUMENT_ORDERS = 'NEW_DOCUMENT_ORDERS';
const NEW_DOCUMENT_STOCK = 'NEW_DOCUMENT_STOCK';
const NEW_DOCUMENT_ACCOUNTING = 'NEW_DOCUMENT_ACCOUNTING';
const NEW_DOCUMENT_CATALOGUES = 'NEW_DOCUMENT_CATALOGUES';

// Dashboard
const ACCESS_DASHBOARD = 'ACCESS_DASHBOARD';
const ACCESS_DASHBOARD_POSTS = 'ACCESS_DASHBOARD_POSTS';
const ACCESS_DASHBOARD_MECHANICS = 'ACCESS_DASHBOARD_MECHANICS';
const EDIT_DASHBOARD_ORDER = 'EDIT_DASHBOARD_ORDER';
const OPEN_DASHBOARD_ORDER = 'OPEN_DASHBOARD_ORDER';
const CREATE_DASHBOARD_ORDER = 'CREATE_DASHBOARD_ORDER';
const RESCHEDULE_ORDERS = 'RESCHEDULE_ORDERS';

// Locations
const ACCESS_LOCATIONS = 'ACCESS_LOCATIONS';
const ACCESS_LOCATION_HISTORY = 'ACCESS_LOCATION_HISTORY';
const ACCESS_LOCATIONS_VEHICLE_HISTORY = 'ACCESS_LOCATIONS_VEHICLE_HISTORY';

// Orders List
const SHOW_ORDERS = 'SHOW_ORDERS';
const CREATE_ORDER = 'CREATE_ORDER';
const SHOW_FILTERS = 'SHOW_FILTERS';
const CREATE_INVITE_ORDER = 'CREATE_INVITE_ORDER';
const ORDERS_INVITE_MASS_MAILING = 'ORDERS_INVITE_MASS_MAILING';
const ACCESS_FEEDBACK = 'ACCESS_FEEDBACK';

// Barcode
const ACCESS_STORE_PRODUCT_BARCODE = 'ACCESS_STORE_PRODUCT_BARCODE';
const ACCESS_STORE_PRODUCT_BARCODE_FUNCTIONALITY = 'ACCESS_STORE_PRODUCT_BARCODE_FUNCTIONALITY';

// Orders
const ACCESS_ORDER_BODY = 'ACCESS_ORDER_BODY';
const UPDATE_SUCCESS_ORDER = 'UPDATE_SUCCESS_ORDER';
const ACCESS_ORDER_LOCATIONS = 'ACCESS_ORDER_LOCATIONS';
const ACCESS_ORDER_REPAIR_MAP = 'ACCESS_ORDER_REPAIR_MAP';
const ACCESS_ORDER_DIAGNOSTICS = 'ACCESS_ORDER_DIAGNOSTICS';
const ACCESS_ORDER_SERVICES = 'ACCESS_ORDER_SERVICES';
const ACCESS_ORDER_DETAILS = 'ACCESS_ORDER_DETAILS';
const ACCESS_ORDER_WORKSHOP = 'ACCESS_ORDER_WORKSHOP';
const ACCESS_ORDER_STOCK = 'ACCESS_ORDER_STOCK';
const ACCESS_ORDER_COMMENTS = 'ACCESS_ORDER_COMMENTS';
const ACCESS_ORDER_HISTORY = 'ACCESS_ORDER_HISTORY';
const ACCESS_ORDER_CALLS = 'ACCESS_ORDER_CALLS';
const ACCESS_ORDER_POSTS = 'ACCESS_ORDER_POSTS';

// Order Icons
const ACCESS_TECH_AUTO_DATA_MODAL_WINDOW = 'ACCESS_TECH_AUTO_DATA_MODAL_WINDOW';
const ACCESS_AGREEMENT = 'ACCESS_AGREEMENT';
const ACCESS_ORDER_STATUS = 'ACCESS_ORDER_STATUS';
const ACCESS_ORDER_PAY = 'ACCESS_ORDER_PAY';
const PRINT_ORDERS = 'PRINT_ORDERS';
const LIMITED_PRINT = 'LIMITED_PRINT';
const ACCESS_ORDER_COPY = 'ACCESS_ORDER_COPY';
const ACCESS_ORDER_DELETE = 'ACCESS_ORDER_DELETE';

// Diagnostic
const ACCESS_DIAGNOSTICS_ADD_TEMPLATE = 'ACCESS_DIAGNOSTICS_ADD_TEMPLATE';
const ACCESS_DIAGNOSTICS_UPDATE_ELEMENTS = 'ACCESS_DIAGNOSTICS_UPDATE_ELEMENTS';
const ACCESS_DIAGNOSTICS_ADD_ELEMENTS = 'ACCESS_DIAGNOSTICS_ADD_ELEMENTS';
const ACCESS_DIAGNOSTICS_COMPLETE = 'ACCESS_DIAGNOSTICS_COMPLETE';
const ACCESS_ORDER_CREATIONG_OF_DIAGNOSTICS_MODAL_WINDOW = 'ACCESS_ORDER_CREATIONG_OF_DIAGNOSTICS_MODAL_WINDOW';

// Labors
const ACCESS_ORDER_LABORS_CRUD = 'ACCESS_ORDER_LABORS_CRUD';
const ACCESS_ORDER_LABORS_COMPLEXES = 'ACCESS_ORDER_LABORS_COMPLEXES';
const ACCESS_NORM_HOURS_MODAL_WINDOW = 'ACCESS_NORM_HOURS_MODAL_WINDOW';
const ACCESS_ORDER_CHANGE_AGREEMENT_STATUS = 'ACCESS_ORDER_CHANGE_AGREEMENT_STATUS';
const ACCESS_ORDER_LABORS_DISCOUNTS = 'ACCESS_ORDER_LABORS_DISCOUNTS';

// Details
const ACCESS_ORDER_DETAILS_CRUD = 'ACCESS_ORDER_DETAILS_CRUD';
const ACCESS_ORDER_DETAILS_VIN = 'ACCESS_ORDER_DETAILS_VIN';
const ACCESS_TECDOC_MODAL_WINDOW = 'ACCESS_TECDOC_MODAL_WINDOW';
const ACCESS_SUPPLIER_MODAL_WINDOW = 'ACCESS_SUPPLIER_MODAL_WINDOW';
const ACCESS_ORDER_DETAILS_OILS = 'ACCESS_ORDER_DETAILS_OILS';
const ACCESS_ORDER_DETAILS_WHEELS = 'ACCESS_ORDER_DETAILS_WHEELS';
const ACCESS_ORDER_DETAILS_STOCK = 'ACCESS_ORDER_DETAILS_STOCK';
const ACCESS_ORDER_DETAILS_FIND_FROM_VEHICLE = 'ACCESS_ORDER_DETAILS_FIND_FROM_VEHICLE';
const ACCESS_ORDER_DETAILS_FIND_FROM_CODE = 'ACCESS_ORDER_DETAILS_FIND_FROM_CODE';
const ACCESS_ORDER_DETAILS_FIND_OILS = 'ACCESS_ORDER_DETAILS_FIND_OILS';
const ACCESS_ORDER_DETAILS_FIND_WHEELS = 'ACCESS_ORDER_DETAILS_FIND_WHEELS';
const ACCESS_ORDER_DETAILS_FIND_STOCK = 'ACCESS_ORDER_DETAILS_FIND_STOCK';
const ACCESS_ORDER_DETAILS_CHANGE_STATUS = 'ACCESS_ORDER_DETAILS_CHANGE_STATUS';
const ACCESS_ORDER_DETAILS_DISCOUNTS = 'ACCESS_ORDER_DETAILS_DISCOUNTS';

// Other Tabs
const ACCESS_ORDER_TABS_REPAIR_MAP_UPDATE = 'ACCESS_ORDER_TABS_REPAIR_MAP_UPDATE';
const ACCESS_ORDER_TABS_WORKSHOP_BUTTONS = 'ACCESS_ORDER_TABS_WORKSHOP_BUTTONS';
const ACCESS_ORDER_TABS_WORKSHOP_FINISH = 'ACCESS_ORDER_TABS_WORKSHOP_FINISH';
const ACCESS_ORDER_TABS_STOCK_BUTTONS_STOCK = 'ACCESS_ORDER_TABS_STOCK_BUTTONS_STOCK';
const ACCESS_ORDER_TABS_STOCK_BUTTONS_WORKSHOP = 'ACCESS_ORDER_TABS_STOCK_BUTTONS_WORKSHOP';
const ACCESS_ORDER_TABS_COMMENTS_CRUD = 'ACCESS_ORDER_TABS_COMMENTS_CRUD';
const ACCESS_ORDER_TABS_POSTS_CRUD = 'ACCESS_ORDER_TABS_POSTS_CRUD';

// Tasks
const GET_TASKS = 'GET_TASKS';
const GET_ALL_TASKS = 'GET_ALL_TASKS';
const ACCESS_ORDER_TASKS = 'ACCESS_ORDER_TASKS';
const ACCESS_EMPLOYEE_TASKS = 'ACCESS_EMPLOYEE_TASKS';

// Clients
const GET_CLIENTS = 'GET_CLIENTS';
const CREATE_EDIT_DELETE_CLIENTS = 'CREATE_EDIT_DELETE_CLIENTS';
const CREATE_EDIT_DELETE_CLIENT_VEHICLES = 'CREATE_EDIT_DELETE_CLIENT_VEHICLES';
const ACCESS_CLIENTS_VEHICLE_TRANSFER = 'ACCESS_CLIENTS_VEHICLE_TRANSFER';
const FILTER_CLIENTS = 'FILTER_CLIENTS';
const GET_CLIENTS_BASIC_INFORMATION = 'GET_CLIENTS_BASIC_INFORMATION';
const GET_CLIENTS_ADDITIONAL_INFORMATION = 'GET_CLIENTS_ADDITIONAL_INFORMATION';
const ACCESS_CLIENTS_REQUISITES = 'ACCESS_CLIENTS_REQUISITES';
const ACCESS_RECEIVABLES_GET = 'ACCESS_RECEIVABLES_GET';
const ACCESS_RECEIVABLES_PAY = 'ACCESS_RECEIVABLES_PAY';
const ACCESS_CLIENTS_LOCATIONS = 'ACCESS_CLIENTS_LOCATIONS';

// Suppliers
const ACCESS_SUPPLIERS = 'ACCESS_SUPPLIERS';
const ACCESS_SUPPLIERS_CRUD = 'ACCESS_SUPPLIERS_CRUD';
const ACCESS_SUPPLIER = 'ACCESS_SUPPLIER';
const ACCESS_SUPPLIER_STATISTICS = 'ACCESS_SUPPLIER_STATISTICS';
const ACCESS_SUPPLIER_REQUISITES = 'ACCESS_SUPPLIER_REQUISITES';
const ACCESS_SUPPLIER_CREDITOR_PAY = 'ACCESS_SUPPLIER_CREDITOR_PAY';
const ACCESS_SUPPLIER_CREDITOR_GET = 'ACCESS_SUPPLIER_CREDITOR_GET';

// Employees
const GET_EMPLOYEES = 'GET_EMPLOYEES';
const ACCESS_EMPLOYEE = 'ACCESS_EMPLOYEE';
const CREATE_EDIT_DELETE_EMPLOYEES = 'CREATE_EDIT_DELETE_EMPLOYEES';
const EMPLOYEES_SALARIES = 'EMPLOYEES_SALARIES';
const ACCESS_EMPLOYEE_SALARIES_CRUD = 'ACCESS_EMPLOYEE_SALARIES_CRUD';
const ACCESS_EMPLOYEE_SALARIES_CALC = 'ACCESS_EMPLOYEE_SALARIES_CALC';
const ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_CRUD = 'ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_CRUD';
const ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_ANALYTICS = 'ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_ANALYTICS';

// Other Directories
const ACCESS_STORE_PRODUCTS = 'ACCESS_STORE_PRODUCTS';
const EDIT_STORE_PRODUCT_PRICE = 'EDIT_STORE_PRODUCT_PRICE';
const ACCESS_LABOR_CATALOGUE = 'ACCESS_LABOR_CATALOGUE';
const ACCESS_CATALOGUE_LABORS_CRUD = 'ACCESS_CATALOGUE_LABORS_CRUD';
const ACCESS_DIAGNOSTIC_CATALOGUE = 'ACCESS_DIAGNOSTIC_CATALOGUE';
const ACCESS_CATALOGUE_DIAGNOSTICS_CRUD = 'ACCESS_CATALOGUE_DIAGNOSTICS_CRUD';
const ACCESS_CATALOGUE_COMPLEXES = 'ACCESS_CATALOGUE_COMPLEXES';
const ACCESS_CATALOGUE_COMPLEXES_CRUD = 'ACCESS_CATALOGUE_COMPLEXES_CRUD';
const ACCESS_CATALOGUE_STOCK = 'ACCESS_CATALOGUE_STOCK';
const ACCESS_CATALOGUE_STOCK_CRUD = 'ACCESS_CATALOGUE_STOCK_CRUD';
const ACCESS_CATALOGUE_CASH = 'ACCESS_CATALOGUE_CASH';
const ACCESS_CATALOGUE_CASH_CRUD = 'ACCESS_CATALOGUE_CASH_CRUD';
const ACCESS_CATALOGUE_REQUISITES = 'ACCESS_CATALOGUE_REQUISITES';
const ACCESS_CATALOGUE_REQUISITES_CRUD = 'ACCESS_CATALOGUE_REQUISITES_CRUD';
const ACCESS_CATALOGUE_REQUISITES_TAX_CRUD = 'ACCESS_CATALOGUE_REQUISITES_TAX_CRUD';
const ACCESS_STORE_GROUPS = 'ACCESS_STORE_GROUPS';
const ACCESS_CATALOGUE_STORE_GROUPS_CRUD = 'ACCESS_CATALOGUE_STORE_GROUPS_CRUD';
const ACCESS_PRICE_GROUPS = 'ACCESS_PRICE_GROUPS';
const ACCESS_CATALOGUE_PRICE_GROUPS_CRUD = 'ACCESS_CATALOGUE_PRICE_GROUPS_CRUD';
const ACCESS_CATALOGUE_UNITS = 'ACCESS_CATALOGUE_UNITS';
const ACCESS_CATALOGUE_UNITS_CRUD = 'ACCESS_CATALOGUE_UNITS_CRUD';
const ACCESS_CATALOGUE_ANALYTICS = 'ACCESS_CATALOGUE_ANALYTICS';
const ACCESS_CATALOGUE_ANALYTICS_CRUD = 'ACCESS_CATALOGUE_ANALYTICS_CRUD';
const ACCESS_CATALOGUE_WMS = 'ACCESS_CATALOGUE_WMS';
const ACCESS_CATALOGUE_WMS_CRUD = 'ACCESS_CATALOGUE_WMS_CRUD';
const ACCESS_CATALOGUE_LOCATIONS = 'ACCESS_CATALOGUE_LOCATIONS';
const ACCESS_CATALOGUE_LOCATIONS_CRUD = 'ACCESS_CATALOGUE_LOCATIONS_CRUD';

// Stock
const ACCESS_STOCK = 'ACCESS_STOCK';
const ACCESS_SUPPLIER_ORDER_STORE_DOCS = 'ACCESS_SUPPLIER_ORDER_STORE_DOCS';
const ACCESS_SUPPLIER_ORDER_STORE_DOCS_CRUD = 'ACCESS_SUPPLIER_ORDER_STORE_DOCS_CRUD';
const ACCESS_SUPPLIER_ORDER_STORE_DOCS_AUTO = 'ACCESS_SUPPLIER_ORDER_STORE_DOCS_AUTO';
const ACCESS_INCOME_STORE_DOCS = 'ACCESS_INCOME_STORE_DOCS';
const ACCESS_INCOME_STORE_DOCS_CRUD = 'ACCESS_INCOME_STORE_DOCS_CRUD';
const ACCESS_INCOME_STORE_DOCS_AUTO = 'ACCESS_INCOME_STORE_DOCS_AUTO';
const ACCESS_EXPENSE_STORE_DOCS = 'ACCESS_EXPENSE_STORE_DOCS';
const ACCESS_EXPENSE_STORE_DOCS_CRUD = 'ACCESS_EXPENSE_STORE_DOCS_CRUD';
const ACCESS_TRANSFER_STORE_DOCS = 'ACCESS_TRANSFER_STORE_DOCS';
const ACCESS_TRANSFER_STORE_DOCS_CRUD = 'ACCESS_TRANSFER_STORE_DOCS_CRUD';
const ACCESS_TOOL_STORE_DOCS = 'ACCESS_TOOL_STORE_DOCS';
const ACCESS_TOOL_STORE_DOCS_CRUD = 'ACCESS_TOOL_STORE_DOCS_CRUD';
const VIEW_STORE = 'VIEW_STORE';
const SEARCH_STORE_PRODUCT = 'SEARCH_STORE_PRODUCT';
const VIEW_STORE_WITH_PRICE = 'VIEW_STORE_WITH_PRICE';
const SEARCH_STORE_PRODUCT_WITH_PRICE = 'SEARCH_STORE_PRODUCT_WITH_PRICE';
const ACCESS_RESERVES_STORE_DOCS = 'ACCESS_RESERVES_STORE_DOCS';
const ACCESS_ORDERS_STORE_DOCS = 'ACCESS_ORDERS_STORE_DOCS';
const ACCESS_STOCK_INVENTORIZATION = 'ACCESS_STOCK_INVENTORIZATION';
const ACCESS_RESET_STORE_ORDERS = 'ACCESS_RESET_STORE_ORDERS';
const ACCESS_RECALC_FIFO_STORE_DOCS = 'ACCESS_RECALC_FIFO_STORE_DOCS';

// Finances
const ACCESS_ACCOUNTING = 'ACCESS_ACCOUNTING';
const EDIT_CASH_ORDERS = 'EDIT_CASH_ORDERS';
const ACCESS_FINANCE_CASH = 'ACCESS_FINANCE_CASH';
const ACCESS_FINANCE_RECEIVABLE = 'ACCESS_FINANCE_RECEIVABLE';
const ACCESS_FINANCE_CREDITOR = 'ACCESS_FINANCE_CREDITOR';
const ACCESS_UPDATE_COMMENTS_ANALYTICS_CASH_ORDER = 'ACCESS_UPDATE_COMMENTS_ANALYTICS_CASH_ORDER';
const ACCESS_UPDATE_DATE_SUM_CASH_ORDER = 'ACCESS_UPDATE_DATE_SUM_CASH_ORDER';

// Reports
const ACCESS_KPI = 'ACCESS_KPI';
const ACCESS_CALL_STATISTICS = 'ACCESS_CALL_STATISTICS';
const ACCESS_REPORT_PROFIT_FROM_ORDERS = 'ACCESS_REPORT_PROFIT_FROM_ORDERS';
const ACCESS_REPORT_PROFIT_FROM_ORDERS_PRINT = 'ACCESS_REPORT_PROFIT_FROM_ORDERS_PRINT';
const ACCESS_REPORT_TIME_EFFICIENCY = 'ACCESS_REPORT_TIME_EFFICIENCY';
const ACCESS_REPORT_STOCK = 'ACCESS_REPORT_STOCK';
const ACCESS_REPORT_PRODUCTS = 'ACCESS_REPORT_PRODUCTS';
const ACCESS_REPORT_EMPLOYEE_SALARIES = 'ACCESS_REPORT_EMPLOYEE_SALARIES';
const ACCESS_REPORT_PROFIT_FROM_BUSINESSES = 'ACCESS_REPORT_PROFIT_FROM_BUSINESSES';
const ACCESS_REPORT_PROFIT_FROM_BUSINESSES_PRINT = 'ACCESS_REPORT_PROFIT_FROM_BUSINESSES_PRINT';

// Settings and Administration
const ACCESS_TELEGRAM = 'ACCESS_TELEGRAM';
const ACCESS_SETTINGS = 'ACCESS_SETTINGS';
const ACCESS_SETTINGS_WEB = 'ACCESS_SETTINGS_WEB';
const ACCESS_ADMINISTRATION = 'ACCESS_ADMINISTRATION';
const GRANT = 'GRANT';
const ACCESS_DISTRIBUTORS = 'ACCESS_DISTRIBUTORS';
const ACCESS_SMS_TEMPLATES = 'ACCESS_SMS_TEMPLATES';

// Synchronization
const ACCESS_SYNC_CATALOGUES = 'ACCESS_SYNC_CATALOGUES';
const ACCESS_SYNC_ORDERS = 'ACCESS_SYNC_ORDERS';
const ACCESS_SYNC_STOCK = 'ACCESS_SYNC_STOCK';
const ACCESS_SYNC_SETTINGS = 'ACCESS_SYNC_SETTINGS';
const ACCESS_SYNC_EXPORT = 'ACCESS_SYNC_EXPORT';
const ACCESS_SYNC_IMPORT = 'ACCESS_SYNC_IMPORT';

// API
const ACCESS_API_PRICE = 'ACCESS_API_PRICE';
const ACCESS_API_AVAILABILITY = 'ACCESS_API_AVAILABILITY';
const ACCESS_API_EXPENSE_ORDER = 'ACCESS_API_EXPENSE_ORDER';
const ACCESS_API_INCOME_ORDER = 'ACCESS_API_INCOME_ORDER';

// Cash RST
const ACCESS_OTHER_OPERATION_RST = 'ACCESS_OTHER_OPERATION_RST';
const ACCESS_CASHBOX_CRUD = 'ACCESS_CASHBOX_CRUD';
const ACCESS_CASHIER_CRUD = 'ACCESS_CASHIER_CRUD';
const ACCESS_SALE_RST = 'ACCESS_SALE_RST';
const ACCESS_CASHBOX_RST_LOGS = 'ACCESS_CASHBOX_RST_LOGS';

export const permissions = Object.freeze({
    NEW_DOCUMENT,
    NEW_DOCUMENT_ORDERS,
    NEW_DOCUMENT_STOCK,
    NEW_DOCUMENT_ACCOUNTING,
    NEW_DOCUMENT_CATALOGUES,

    ACCESS_DASHBOARD,
    ACCESS_DASHBOARD_POSTS,
    ACCESS_DASHBOARD_MECHANICS,
    EDIT_DASHBOARD_ORDER,
    OPEN_DASHBOARD_ORDER,
    CREATE_DASHBOARD_ORDER,
    RESCHEDULE_ORDERS,

    ACCESS_LOCATIONS,
    ACCESS_LOCATION_HISTORY,
    ACCESS_LOCATIONS_VEHICLE_HISTORY,

    SHOW_ORDERS,
    CREATE_ORDER,
    SHOW_FILTERS,
    CREATE_INVITE_ORDER,
    ORDERS_INVITE_MASS_MAILING,
    ACCESS_FEEDBACK,

    ACCESS_STORE_PRODUCT_BARCODE,
    ACCESS_STORE_PRODUCT_BARCODE_FUNCTIONALITY,

    ACCESS_ORDER_BODY,
    UPDATE_SUCCESS_ORDER,
    ACCESS_ORDER_LOCATIONS,
    ACCESS_ORDER_REPAIR_MAP,
    ACCESS_ORDER_DIAGNOSTICS,
    ACCESS_ORDER_SERVICES,
    ACCESS_ORDER_DETAILS,
    ACCESS_ORDER_WORKSHOP,
    ACCESS_ORDER_STOCK,
    ACCESS_ORDER_COMMENTS,
    ACCESS_ORDER_HISTORY,
    ACCESS_ORDER_CALLS,
    ACCESS_ORDER_POSTS,

    ACCESS_TECH_AUTO_DATA_MODAL_WINDOW,
    ACCESS_AGREEMENT,
    ACCESS_ORDER_STATUS,
    ACCESS_ORDER_PAY,
    PRINT_ORDERS,
    LIMITED_PRINT,
    ACCESS_ORDER_COPY,
    ACCESS_ORDER_DELETE,

    ACCESS_DIAGNOSTICS_ADD_TEMPLATE,
    ACCESS_DIAGNOSTICS_UPDATE_ELEMENTS,
    ACCESS_DIAGNOSTICS_ADD_ELEMENTS,
    ACCESS_DIAGNOSTICS_COMPLETE,
    ACCESS_ORDER_CREATIONG_OF_DIAGNOSTICS_MODAL_WINDOW,

    ACCESS_ORDER_LABORS_CRUD,
    ACCESS_ORDER_LABORS_COMPLEXES,
    ACCESS_NORM_HOURS_MODAL_WINDOW,
    ACCESS_ORDER_CHANGE_AGREEMENT_STATUS,
    ACCESS_ORDER_LABORS_DISCOUNTS,

    ACCESS_ORDER_DETAILS_CRUD,
    ACCESS_ORDER_DETAILS_VIN,
    ACCESS_TECDOC_MODAL_WINDOW,
    ACCESS_SUPPLIER_MODAL_WINDOW,
    ACCESS_ORDER_DETAILS_OILS,
    ACCESS_ORDER_DETAILS_WHEELS,
    ACCESS_ORDER_DETAILS_STOCK,
    ACCESS_ORDER_DETAILS_FIND_FROM_VEHICLE,
    ACCESS_ORDER_DETAILS_FIND_FROM_CODE,
    ACCESS_ORDER_DETAILS_FIND_OILS,
    ACCESS_ORDER_DETAILS_FIND_WHEELS,
    ACCESS_ORDER_DETAILS_FIND_STOCK,
    ACCESS_ORDER_DETAILS_CHANGE_STATUS,
    ACCESS_ORDER_DETAILS_DISCOUNTS,

    ACCESS_ORDER_TABS_REPAIR_MAP_UPDATE,
    ACCESS_ORDER_TABS_WORKSHOP_BUTTONS,
    ACCESS_ORDER_TABS_WORKSHOP_FINISH,
    ACCESS_ORDER_TABS_STOCK_BUTTONS_STOCK,
    ACCESS_ORDER_TABS_STOCK_BUTTONS_WORKSHOP,
    ACCESS_ORDER_TABS_COMMENTS_CRUD,
    ACCESS_ORDER_TABS_POSTS_CRUD,

    GET_TASKS,
    GET_ALL_TASKS,
    ACCESS_ORDER_TASKS,
    ACCESS_EMPLOYEE_TASKS,

    GET_CLIENTS,
    CREATE_EDIT_DELETE_CLIENTS,
    CREATE_EDIT_DELETE_CLIENT_VEHICLES,
    ACCESS_CLIENTS_VEHICLE_TRANSFER,
    FILTER_CLIENTS,
    GET_CLIENTS_BASIC_INFORMATION,
    GET_CLIENTS_ADDITIONAL_INFORMATION,
    ACCESS_CLIENTS_REQUISITES,
    ACCESS_RECEIVABLES_GET,
    ACCESS_RECEIVABLES_PAY,
    ACCESS_CLIENTS_LOCATIONS,

    ACCESS_SUPPLIERS,
    ACCESS_SUPPLIERS_CRUD,
    ACCESS_SUPPLIER,
    ACCESS_SUPPLIER_STATISTICS,
    ACCESS_SUPPLIER_REQUISITES,
    ACCESS_SUPPLIER_CREDITOR_PAY,
    ACCESS_SUPPLIER_CREDITOR_GET,

    GET_EMPLOYEES,
    ACCESS_EMPLOYEE,
    CREATE_EDIT_DELETE_EMPLOYEES,
    EMPLOYEES_SALARIES,
    ACCESS_EMPLOYEE_SALARIES_CRUD,
    ACCESS_EMPLOYEE_SALARIES_CALC,
    ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_CRUD,
    ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_ANALYTICS,

    ACCESS_STORE_PRODUCTS,
    EDIT_STORE_PRODUCT_PRICE,
    ACCESS_LABOR_CATALOGUE,
    ACCESS_CATALOGUE_LABORS_CRUD,
    ACCESS_DIAGNOSTIC_CATALOGUE,
    ACCESS_CATALOGUE_DIAGNOSTICS_CRUD,
    ACCESS_CATALOGUE_COMPLEXES,
    ACCESS_CATALOGUE_COMPLEXES_CRUD,
    ACCESS_CATALOGUE_STOCK,
    ACCESS_CATALOGUE_STOCK_CRUD,
    ACCESS_CATALOGUE_CASH,
    ACCESS_CATALOGUE_CASH_CRUD,
    ACCESS_CATALOGUE_REQUISITES,
    ACCESS_CATALOGUE_REQUISITES_CRUD,
    ACCESS_CATALOGUE_REQUISITES_TAX_CRUD,
    ACCESS_STORE_GROUPS,
    ACCESS_CATALOGUE_STORE_GROUPS_CRUD,
    ACCESS_PRICE_GROUPS,
    ACCESS_CATALOGUE_PRICE_GROUPS_CRUD,
    ACCESS_CATALOGUE_UNITS,
    ACCESS_CATALOGUE_UNITS_CRUD,
    ACCESS_CATALOGUE_ANALYTICS,
    ACCESS_CATALOGUE_ANALYTICS_CRUD,
    ACCESS_CATALOGUE_WMS,
    ACCESS_CATALOGUE_WMS_CRUD,
    ACCESS_CATALOGUE_LOCATIONS,
    ACCESS_CATALOGUE_LOCATIONS_CRUD,

    ACCESS_STOCK,
    ACCESS_SUPPLIER_ORDER_STORE_DOCS,
    ACCESS_SUPPLIER_ORDER_STORE_DOCS_CRUD,
    ACCESS_SUPPLIER_ORDER_STORE_DOCS_AUTO,
    ACCESS_INCOME_STORE_DOCS,
    ACCESS_INCOME_STORE_DOCS_CRUD,
    ACCESS_INCOME_STORE_DOCS_AUTO,
    ACCESS_EXPENSE_STORE_DOCS,
    ACCESS_EXPENSE_STORE_DOCS_CRUD,
    ACCESS_TRANSFER_STORE_DOCS,
    ACCESS_TRANSFER_STORE_DOCS_CRUD,
    ACCESS_TOOL_STORE_DOCS,
    ACCESS_TOOL_STORE_DOCS_CRUD,
    VIEW_STORE,
    SEARCH_STORE_PRODUCT,
    VIEW_STORE_WITH_PRICE,
    SEARCH_STORE_PRODUCT_WITH_PRICE,
    ACCESS_RESERVES_STORE_DOCS,
    ACCESS_ORDERS_STORE_DOCS,
    ACCESS_STOCK_INVENTORIZATION,
    ACCESS_RESET_STORE_ORDERS,
    ACCESS_RECALC_FIFO_STORE_DOCS,

    ACCESS_ACCOUNTING,
    EDIT_CASH_ORDERS,
    ACCESS_FINANCE_CASH,
    ACCESS_FINANCE_RECEIVABLE,
    ACCESS_FINANCE_CREDITOR,
    ACCESS_UPDATE_COMMENTS_ANALYTICS_CASH_ORDER,
    ACCESS_UPDATE_DATE_SUM_CASH_ORDER,

    ACCESS_KPI,
    ACCESS_CALL_STATISTICS,
    ACCESS_REPORT_PROFIT_FROM_ORDERS,
    ACCESS_REPORT_PROFIT_FROM_ORDERS_PRINT,
    ACCESS_REPORT_TIME_EFFICIENCY,
    ACCESS_REPORT_STOCK,
    ACCESS_REPORT_PRODUCTS,
    ACCESS_REPORT_EMPLOYEE_SALARIES,
    ACCESS_REPORT_PROFIT_FROM_BUSINESSES,
    ACCESS_REPORT_PROFIT_FROM_BUSINESSES_PRINT,

    ACCESS_TELEGRAM,
    ACCESS_SETTINGS,
    ACCESS_SETTINGS_WEB,
    ACCESS_ADMINISTRATION,
    GRANT,
    ACCESS_DISTRIBUTORS,
    ACCESS_SMS_TEMPLATES,

    ACCESS_SYNC_CATALOGUES,
    ACCESS_SYNC_ORDERS,
    ACCESS_SYNC_STOCK,
    ACCESS_SYNC_SETTINGS,
    ACCESS_SYNC_EXPORT,
    ACCESS_SYNC_IMPORT,

    ACCESS_API_PRICE,
    ACCESS_API_AVAILABILITY,
    ACCESS_API_EXPENSE_ORDER,
    ACCESS_API_INCOME_ORDER,

    // Cash RST
    ACCESS_OTHER_OPERATION_RST,
    ACCESS_CASHBOX_CRUD,
    ACCESS_CASHIER_CRUD,
    ACCESS_SALE_RST,
    ACCESS_CASHBOX_RST_LOGS
});

export const NEW_DOCUMENT_PERMISSIONS = 'NEW_DOCUMENT_PERMISSIONS';
export const DASHBOARD_PERMISSIONS = 'DASHBOARD_PERMISSIONS';
export const LOCATIONS_PERMISSIONS = 'LOCATIONS_PERMISSIONS';
export const ORDERS_LIST_PERMISSIONS = 'ORDERS_LIST_PERMISSIONS';
export const BARCODE_PERMISSIONS = 'BARCODE_PERMISSIONS';
export const ORDERS_PERMISSIONS = 'ORDERS_PERMISSIONS';
export const ORDER_ICONS_PERMISSIONS = 'ORDER_ICONS_PERMISSIONS';
export const DIAGNOSTIC_PERMISSIONS = 'DIAGNOSTIC_PERMISSIONS';
export const LABORS_PERMISSIONS = 'LABORS_PERMISSIONS';
export const DETAILS_PERMISSIONS = 'DETAILS_PERMISSIONS';
export const OTHER_TABS_PERMISSIONS = 'OTHER_TABS_PERMISSIONS';
export const TASKS_PERMISSIONS = 'TASKS_PERMISSIONS';
export const CLIENTS_PERMISSIONS = 'CLIENTS_PERMISSIONS';
export const SUPPLIERS_PERMISSIONS = 'SUPPLIERS_PERMISSIONS';
export const EMPLOYEES_PERMISSIONS = 'EMPLOYEES_PERMISSIONS';
export const OTHER_DIRECTORIES_PERMISSIONS = 'OTHER_DIRECTORIES_PERMISSIONS';
export const STOCK_PERMISSIONS = 'STOCK_PERMISSIONS';
export const FINANCES_PERMISSIONS = 'FINANCES_PERMISSIONS';
export const REPORTS_PERMISSIONS = 'REPORTS_PERMISSIONS';
export const SETTINGS_AND_ADMINISTRATION_PERMISSIONS = 'SETTINGS_AND_ADMINISTRATION_PERMISSIONS';
export const SYNCHRONIZATION_PERMISSIONS = 'SYNCHRONIZATION_PERMISSIONS';
export const API_PERMISSIONS = 'API_PERMISSIONS';
export const RST_PERMISIONS = 'RST_PERMISIONS';

/**
 * Ech role group appears in a list of rles on roles page,
 * if roles were not proveded here will will not apper there
 */
export const groupedPermissions = {
    [NEW_DOCUMENT_PERMISSIONS]: [
        NEW_DOCUMENT,
        NEW_DOCUMENT_ORDERS,
        NEW_DOCUMENT_STOCK,
        NEW_DOCUMENT_ACCOUNTING,
        NEW_DOCUMENT_CATALOGUES,
        ACCESS_EMPLOYEE_TASKS
    ],
    [DASHBOARD_PERMISSIONS]: [
        ACCESS_DASHBOARD,
        ACCESS_DASHBOARD_POSTS,
        ACCESS_DASHBOARD_MECHANICS,
        EDIT_DASHBOARD_ORDER,
        OPEN_DASHBOARD_ORDER,
        CREATE_DASHBOARD_ORDER,
        RESCHEDULE_ORDERS
    ],
    [LOCATIONS_PERMISSIONS]: [ACCESS_LOCATIONS, ACCESS_LOCATION_HISTORY, ACCESS_LOCATIONS_VEHICLE_HISTORY],
    [ORDERS_LIST_PERMISSIONS]: [
        SHOW_ORDERS,
        CREATE_ORDER,
        SHOW_FILTERS,
        CREATE_INVITE_ORDER,
        ORDERS_INVITE_MASS_MAILING,
        ACCESS_FEEDBACK
    ],
    [BARCODE_PERMISSIONS]: [ACCESS_STORE_PRODUCT_BARCODE, ACCESS_STORE_PRODUCT_BARCODE_FUNCTIONALITY],
    [ORDERS_PERMISSIONS]: [
        ACCESS_ORDER_BODY,
        UPDATE_SUCCESS_ORDER,
        ACCESS_ORDER_LOCATIONS,
        ACCESS_ORDER_REPAIR_MAP,
        ACCESS_ORDER_DIAGNOSTICS,
        ACCESS_ORDER_SERVICES,
        ACCESS_ORDER_DETAILS,
        ACCESS_ORDER_WORKSHOP,
        ACCESS_ORDER_STOCK,
        ACCESS_ORDER_COMMENTS,
        ACCESS_ORDER_HISTORY,
        ACCESS_ORDER_CALLS,
        ACCESS_ORDER_POSTS
    ],
    [ORDER_ICONS_PERMISSIONS]: [
        ACCESS_TECH_AUTO_DATA_MODAL_WINDOW,
        ACCESS_AGREEMENT,
        ACCESS_ORDER_STATUS,
        ACCESS_ORDER_PAY,
        PRINT_ORDERS,
        LIMITED_PRINT,
        ACCESS_ORDER_COPY,
        ACCESS_ORDER_DELETE
    ],
    [DIAGNOSTIC_PERMISSIONS]: [
        ACCESS_DIAGNOSTICS_ADD_TEMPLATE,
        ACCESS_DIAGNOSTICS_UPDATE_ELEMENTS,
        ACCESS_DIAGNOSTICS_ADD_ELEMENTS,
        ACCESS_DIAGNOSTICS_COMPLETE,
        ACCESS_ORDER_CREATIONG_OF_DIAGNOSTICS_MODAL_WINDOW
    ],
    [LABORS_PERMISSIONS]: [
        ACCESS_ORDER_LABORS_CRUD,
        ACCESS_ORDER_LABORS_COMPLEXES,
        ACCESS_NORM_HOURS_MODAL_WINDOW,
        ACCESS_ORDER_CHANGE_AGREEMENT_STATUS,
        ACCESS_ORDER_LABORS_DISCOUNTS
    ],
    [DETAILS_PERMISSIONS]: [
        ACCESS_ORDER_DETAILS_CRUD,
        ACCESS_ORDER_DETAILS_VIN,
        ACCESS_TECDOC_MODAL_WINDOW,
        ACCESS_SUPPLIER_MODAL_WINDOW,
        ACCESS_ORDER_DETAILS_OILS,
        ACCESS_ORDER_DETAILS_WHEELS,
        ACCESS_ORDER_DETAILS_STOCK,
        ACCESS_ORDER_DETAILS_FIND_FROM_VEHICLE,
        ACCESS_ORDER_DETAILS_FIND_FROM_CODE,
        ACCESS_ORDER_DETAILS_FIND_OILS,
        ACCESS_ORDER_DETAILS_FIND_WHEELS,
        ACCESS_ORDER_DETAILS_FIND_STOCK,
        ACCESS_ORDER_DETAILS_CHANGE_STATUS,
        ACCESS_ORDER_DETAILS_DISCOUNTS
    ],
    [OTHER_TABS_PERMISSIONS]: [
        ACCESS_ORDER_TABS_REPAIR_MAP_UPDATE,
        ACCESS_ORDER_TABS_WORKSHOP_BUTTONS,
        ACCESS_ORDER_TABS_WORKSHOP_FINISH,
        ACCESS_ORDER_TABS_STOCK_BUTTONS_STOCK,
        ACCESS_ORDER_TABS_STOCK_BUTTONS_WORKSHOP,
        ACCESS_ORDER_TABS_COMMENTS_CRUD,
        ACCESS_ORDER_TABS_POSTS_CRUD
    ],
    [TASKS_PERMISSIONS]: [GET_TASKS, GET_ALL_TASKS, ACCESS_ORDER_TASKS],
    [CLIENTS_PERMISSIONS]: [
        GET_CLIENTS,
        CREATE_EDIT_DELETE_CLIENTS,
        CREATE_EDIT_DELETE_CLIENT_VEHICLES,
        ACCESS_CLIENTS_VEHICLE_TRANSFER,
        FILTER_CLIENTS,
        GET_CLIENTS_BASIC_INFORMATION,
        GET_CLIENTS_ADDITIONAL_INFORMATION,
        ACCESS_CLIENTS_REQUISITES,
        ACCESS_RECEIVABLES_GET,
        ACCESS_RECEIVABLES_PAY,
        ACCESS_CLIENTS_LOCATIONS
    ],
    [SUPPLIERS_PERMISSIONS]: [
        ACCESS_SUPPLIERS,
        ACCESS_SUPPLIERS_CRUD,
        ACCESS_SUPPLIER,
        ACCESS_SUPPLIER_STATISTICS,
        ACCESS_SUPPLIER_REQUISITES,
        ACCESS_SUPPLIER_CREDITOR_PAY,
        ACCESS_SUPPLIER_CREDITOR_GET
    ],
    [EMPLOYEES_PERMISSIONS]: [
        GET_EMPLOYEES,
        ACCESS_EMPLOYEE,
        CREATE_EDIT_DELETE_EMPLOYEES,
        EMPLOYEES_SALARIES,
        ACCESS_EMPLOYEE_SALARIES_CRUD,
        ACCESS_EMPLOYEE_SALARIES_CALC,
        ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_CRUD,
        ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_ANALYTICS
    ],
    [OTHER_DIRECTORIES_PERMISSIONS]: [
        ACCESS_STORE_PRODUCTS,
        EDIT_STORE_PRODUCT_PRICE,
        ACCESS_LABOR_CATALOGUE,
        ACCESS_CATALOGUE_LABORS_CRUD,
        ACCESS_DIAGNOSTIC_CATALOGUE,
        ACCESS_CATALOGUE_DIAGNOSTICS_CRUD,
        ACCESS_CATALOGUE_COMPLEXES,
        ACCESS_CATALOGUE_COMPLEXES_CRUD,
        ACCESS_CATALOGUE_STOCK,
        ACCESS_CATALOGUE_STOCK_CRUD,
        ACCESS_CATALOGUE_CASH,
        ACCESS_CATALOGUE_CASH_CRUD,
        ACCESS_CATALOGUE_REQUISITES,
        ACCESS_CATALOGUE_REQUISITES_CRUD,
        ACCESS_CATALOGUE_REQUISITES_TAX_CRUD,
        ACCESS_STORE_GROUPS,
        ACCESS_CATALOGUE_STORE_GROUPS_CRUD,
        ACCESS_PRICE_GROUPS,
        ACCESS_CATALOGUE_PRICE_GROUPS_CRUD,
        ACCESS_CATALOGUE_UNITS,
        ACCESS_CATALOGUE_UNITS_CRUD,
        ACCESS_CATALOGUE_ANALYTICS,
        ACCESS_CATALOGUE_ANALYTICS_CRUD,
        ACCESS_CATALOGUE_WMS,
        ACCESS_CATALOGUE_WMS_CRUD,
        ACCESS_CATALOGUE_LOCATIONS,
        ACCESS_CATALOGUE_LOCATIONS_CRUD
    ],
    [STOCK_PERMISSIONS]: [
        ACCESS_STOCK,
        ACCESS_SUPPLIER_ORDER_STORE_DOCS,
        ACCESS_SUPPLIER_ORDER_STORE_DOCS_CRUD,
        ACCESS_SUPPLIER_ORDER_STORE_DOCS_AUTO,
        ACCESS_INCOME_STORE_DOCS,
        ACCESS_INCOME_STORE_DOCS_CRUD,
        ACCESS_INCOME_STORE_DOCS_AUTO,
        ACCESS_EXPENSE_STORE_DOCS,
        ACCESS_EXPENSE_STORE_DOCS_CRUD,
        ACCESS_TRANSFER_STORE_DOCS,
        ACCESS_TRANSFER_STORE_DOCS_CRUD,
        ACCESS_TOOL_STORE_DOCS,
        ACCESS_TOOL_STORE_DOCS_CRUD,
        VIEW_STORE,
        SEARCH_STORE_PRODUCT,
        VIEW_STORE_WITH_PRICE,
        SEARCH_STORE_PRODUCT_WITH_PRICE,
        ACCESS_RESERVES_STORE_DOCS,
        ACCESS_ORDERS_STORE_DOCS,
        ACCESS_STOCK_INVENTORIZATION,
        ACCESS_RESET_STORE_ORDERS,
        ACCESS_RECALC_FIFO_STORE_DOCS
    ],
    [FINANCES_PERMISSIONS]: [
        ACCESS_ACCOUNTING,
        EDIT_CASH_ORDERS,
        ACCESS_FINANCE_CASH,
        ACCESS_FINANCE_RECEIVABLE,
        ACCESS_FINANCE_CREDITOR,
        ACCESS_UPDATE_COMMENTS_ANALYTICS_CASH_ORDER,
        ACCESS_UPDATE_DATE_SUM_CASH_ORDER
    ],
    [REPORTS_PERMISSIONS]: [
        ACCESS_KPI,
        ACCESS_CALL_STATISTICS,
        ACCESS_REPORT_PROFIT_FROM_ORDERS,
        ACCESS_REPORT_PROFIT_FROM_ORDERS_PRINT,
        ACCESS_REPORT_TIME_EFFICIENCY,
        ACCESS_REPORT_STOCK,
        ACCESS_REPORT_PRODUCTS,
        ACCESS_REPORT_EMPLOYEE_SALARIES,
        ACCESS_REPORT_PROFIT_FROM_BUSINESSES,
        ACCESS_REPORT_PROFIT_FROM_BUSINESSES_PRINT
    ],
    [SETTINGS_AND_ADMINISTRATION_PERMISSIONS]: [
        ACCESS_TELEGRAM,
        ACCESS_SETTINGS,
        ACCESS_SETTINGS_WEB,
        ACCESS_ADMINISTRATION,
        GRANT,
        ACCESS_DISTRIBUTORS,
        ACCESS_SMS_TEMPLATES
    ],
    [SYNCHRONIZATION_PERMISSIONS]: [
        ACCESS_SYNC_CATALOGUES,
        ACCESS_SYNC_ORDERS,
        ACCESS_SYNC_STOCK,
        ACCESS_SYNC_SETTINGS,
        ACCESS_SYNC_EXPORT,
        ACCESS_SYNC_IMPORT
    ],
    [API_PERMISSIONS]: [ACCESS_API_PRICE, ACCESS_API_AVAILABILITY, ACCESS_API_EXPENSE_ORDER, ACCESS_API_INCOME_ORDER],
    [RST_PERMISIONS]: [
        ACCESS_OTHER_OPERATION_RST,
        ACCESS_CASHBOX_CRUD,
        ACCESS_CASHIER_CRUD,
        ACCESS_SALE_RST,
        ACCESS_CASHBOX_RST_LOGS
    ]
};

/**
 * Checks if a user is an admin or has appropriate grant, Admins have access anywhere(even if they don't have grants)
 * @param {*} param0 User object can be taken from state.auth
 * @param {*} grant  Constant that represents access
 * @returns Boolean
 */
export const isForbidden = ({ isAdmin, scope }, grant) => {
    return false;
    // return !isAdmin && !(_.isArray(scope) && scope.includes(grant));
};
export const isAdmin = () => false;

/**
 * Mapper, returns object with translations for each role group
 * @param {*} intl
 * @returns
 */
export const getGroupsLabels = intl => ({
    [NEW_DOCUMENT_PERMISSIONS]: intl.formatMessage({ id: 'roles.NEW_DOCUMENT_PERMISSIONS' }),
    [DASHBOARD_PERMISSIONS]: intl.formatMessage({ id: 'roles.DASHBOARD_PERMISSIONS' }),
    [LOCATIONS_PERMISSIONS]: intl.formatMessage({ id: 'roles.LOCATIONS_PERMISSIONS' }),
    [ORDERS_LIST_PERMISSIONS]: intl.formatMessage({ id: 'roles.ORDERS_LIST_PERMISSIONS' }),
    [ORDERS_PERMISSIONS]: intl.formatMessage({ id: 'roles.ORDERS_PERMISSIONS' }),
    [BARCODE_PERMISSIONS]: intl.formatMessage({ id: 'roles.BARCODE_PERMISSIONS' }),
    [ORDER_ICONS_PERMISSIONS]: intl.formatMessage({ id: 'roles.ORDER_ICONS_PERMISSIONS' }),
    [DIAGNOSTIC_PERMISSIONS]: intl.formatMessage({ id: 'roles.DIAGNOSTIC_PERMISSIONS' }),
    [LABORS_PERMISSIONS]: intl.formatMessage({ id: 'roles.LABORS_PERMISSIONS' }),
    [DETAILS_PERMISSIONS]: intl.formatMessage({ id: 'roles.DETAILS_PERMISSIONS' }),
    [OTHER_TABS_PERMISSIONS]: intl.formatMessage({ id: 'roles.OTHER_TABS_PERMISSIONS' }),
    [TASKS_PERMISSIONS]: intl.formatMessage({ id: 'roles.TASKS_PERMISSIONS' }),
    [CLIENTS_PERMISSIONS]: intl.formatMessage({ id: 'roles.CLIENTS_PERMISSIONS' }),
    [SUPPLIERS_PERMISSIONS]: intl.formatMessage({ id: 'roles.SUPPLIERS_PERMISSIONS' }),
    [EMPLOYEES_PERMISSIONS]: intl.formatMessage({ id: 'roles.EMPLOYEES_PERMISSIONS' }),
    [OTHER_DIRECTORIES_PERMISSIONS]: intl.formatMessage({
        id: 'roles.OTHER_DIRECTORIES_PERMISSIONS'
    }),
    [STOCK_PERMISSIONS]: intl.formatMessage({ id: 'roles.STOCK_PERMISSIONS' }),
    [FINANCES_PERMISSIONS]: intl.formatMessage({ id: 'roles.FINANCES_PERMISSIONS' }),
    [REPORTS_PERMISSIONS]: intl.formatMessage({ id: 'roles.REPORTS_PERMISSIONS' }),
    [SETTINGS_AND_ADMINISTRATION_PERMISSIONS]: intl.formatMessage({
        id: 'roles.SETTINGS_AND_ADMINISTRATION_PERMISSIONS'
    }),
    [SYNCHRONIZATION_PERMISSIONS]: intl.formatMessage({ id: 'roles.SYNCHRONIZATION_PERMISSIONS' }),
    [API_PERMISSIONS]: intl.formatMessage({ id: 'roles.API_PERMISSIONS' }),
    [RST_PERMISIONS]: intl.formatMessage({ id: 'roles.RST_PERMISIONS' })
});

export const getPermissionsLabels = intl => ({
    [NEW_DOCUMENT]: intl.formatMessage({ id: 'roles.NEW_DOCUMENT' }),
    [NEW_DOCUMENT_ORDERS]: intl.formatMessage({ id: 'roles.NEW_DOCUMENT_ORDERS' }),
    [NEW_DOCUMENT_STOCK]: intl.formatMessage({ id: 'roles.NEW_DOCUMENT_STOCK' }),
    [NEW_DOCUMENT_ACCOUNTING]: intl.formatMessage({ id: 'roles.NEW_DOCUMENT_ACCOUNTING' }),
    [NEW_DOCUMENT_CATALOGUES]: intl.formatMessage({ id: 'roles.NEW_DOCUMENT_CATALOGUES' }),

    [ACCESS_DASHBOARD]: intl.formatMessage({ id: 'roles.ACCESS_DASHBOARD' }),
    [ACCESS_DASHBOARD_POSTS]: intl.formatMessage({ id: 'roles.ACCESS_DASHBOARD_POSTS' }),
    [ACCESS_DASHBOARD_MECHANICS]: intl.formatMessage({ id: 'roles.ACCESS_DASHBOARD_MECHANICS' }),
    [EDIT_DASHBOARD_ORDER]: intl.formatMessage({ id: 'roles.EDIT_DASHBOARD_ORDER' }),
    [OPEN_DASHBOARD_ORDER]: intl.formatMessage({ id: 'roles.OPEN_DASHBOARD_ORDER' }),
    [CREATE_DASHBOARD_ORDER]: intl.formatMessage({ id: 'roles.CREATE_DASHBOARD_ORDER' }),
    [RESCHEDULE_ORDERS]: intl.formatMessage({ id: 'roles.RESCHEDULE_ORDERS' }),

    [ACCESS_LOCATIONS]: intl.formatMessage({ id: 'roles.ACCESS_LOCATIONS' }),
    [ACCESS_LOCATION_HISTORY]: intl.formatMessage({ id: 'roles.ACCESS_LOCATION_HISTORY' }),
    [ACCESS_LOCATIONS_VEHICLE_HISTORY]: intl.formatMessage({
        id: 'roles.ACCESS_LOCATIONS_VEHICLE_HISTORY'
    }),

    [SHOW_ORDERS]: intl.formatMessage({ id: 'roles.SHOW_ORDERS' }),
    [CREATE_ORDER]: intl.formatMessage({ id: 'roles.CREATE_ORDER' }),
    [SHOW_FILTERS]: intl.formatMessage({ id: 'roles.SHOW_FILTERS' }),
    [CREATE_INVITE_ORDER]: intl.formatMessage({ id: 'roles.CREATE_INVITE_ORDER' }),
    [ORDERS_INVITE_MASS_MAILING]: intl.formatMessage({ id: 'roles.ORDERS_INVITE_MASS_MAILING' }),
    [ACCESS_FEEDBACK]: intl.formatMessage({ id: 'roles.ACCESS_FEEDBACK' }),

    [ACCESS_STORE_PRODUCT_BARCODE]: intl.formatMessage({
        id: 'roles.ACCESS_STORE_PRODUCT_BARCODE'
    }),
    [ACCESS_STORE_PRODUCT_BARCODE_FUNCTIONALITY]: intl.formatMessage({
        id: 'roles.ACCESS_STORE_PRODUCT_BARCODE_FUNCTIONALITY'
    }),

    [ACCESS_ORDER_BODY]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_BODY' }),
    [UPDATE_SUCCESS_ORDER]: intl.formatMessage({ id: 'roles.UPDATE_SUCCESS_ORDER' }),
    [ACCESS_ORDER_LOCATIONS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_LOCATIONS' }),
    [ACCESS_ORDER_REPAIR_MAP]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_REPAIR_MAP' }),
    [ACCESS_ORDER_DIAGNOSTICS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_DIAGNOSTICS' }),
    [ACCESS_ORDER_SERVICES]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_SERVICES' }),
    [ACCESS_ORDER_DETAILS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_DETAILS' }),
    [ACCESS_ORDER_WORKSHOP]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_WORKSHOP' }),
    [ACCESS_ORDER_STOCK]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_STOCK' }),
    [ACCESS_ORDER_COMMENTS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_COMMENTS' }),
    [ACCESS_ORDER_HISTORY]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_HISTORY' }),
    [ACCESS_ORDER_CALLS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_CALLS' }),
    [ACCESS_ORDER_POSTS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_POSTS' }),

    [ACCESS_TECH_AUTO_DATA_MODAL_WINDOW]: intl.formatMessage({
        id: 'roles.ACCESS_TECH_AUTO_DATA_MODAL_WINDOW'
    }),
    [ACCESS_AGREEMENT]: intl.formatMessage({ id: 'roles.ACCESS_AGREEMENT' }),
    [ACCESS_ORDER_STATUS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_STATUS' }),
    [ACCESS_ORDER_PAY]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_PAY' }),
    [PRINT_ORDERS]: intl.formatMessage({ id: 'roles.PRINT_ORDERS' }),
    [LIMITED_PRINT]: intl.formatMessage({ id: 'roles.LIMITED_PRINT' }),
    [ACCESS_ORDER_COPY]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_COPY' }),
    [ACCESS_ORDER_DELETE]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_DELETE' }),

    [ACCESS_DIAGNOSTICS_ADD_TEMPLATE]: intl.formatMessage({
        id: 'roles.ACCESS_DIAGNOSTICS_ADD_TEMPLATE'
    }),
    [ACCESS_DIAGNOSTICS_UPDATE_ELEMENTS]: intl.formatMessage({
        id: 'roles.ACCESS_DIAGNOSTICS_UPDATE_ELEMENTS'
    }),
    [ACCESS_DIAGNOSTICS_ADD_ELEMENTS]: intl.formatMessage({
        id: 'roles.ACCESS_DIAGNOSTICS_ADD_ELEMENTS'
    }),
    [ACCESS_DIAGNOSTICS_COMPLETE]: intl.formatMessage({ id: 'roles.ACCESS_DIAGNOSTICS_COMPLETE' }),
    [ACCESS_ORDER_CREATIONG_OF_DIAGNOSTICS_MODAL_WINDOW]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_CREATIONG_OF_DIAGNOSTICS_MODAL_WINDOW'
    }),

    [ACCESS_ORDER_LABORS_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_LABORS_CRUD' }),
    [ACCESS_ORDER_LABORS_COMPLEXES]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_LABORS_COMPLEXES'
    }),
    [ACCESS_NORM_HOURS_MODAL_WINDOW]: intl.formatMessage({
        id: 'roles.ACCESS_NORM_HOURS_MODAL_WINDOW'
    }),
    [ACCESS_ORDER_CHANGE_AGREEMENT_STATUS]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_CHANGE_AGREEMENT_STATUS'
    }),
    [ACCESS_ORDER_LABORS_DISCOUNTS]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_LABORS_DISCOUNTS'
    }),

    [ACCESS_ORDER_DETAILS_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_DETAILS_CRUD' }),
    [ACCESS_ORDER_DETAILS_VIN]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_DETAILS_VIN' }),
    [ACCESS_TECDOC_MODAL_WINDOW]: intl.formatMessage({ id: 'roles.ACCESS_TECDOC_MODAL_WINDOW' }),
    [ACCESS_SUPPLIER_MODAL_WINDOW]: intl.formatMessage({
        id: 'roles.ACCESS_SUPPLIER_MODAL_WINDOW'
    }),
    [ACCESS_ORDER_DETAILS_OILS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_DETAILS_OILS' }),
    [ACCESS_ORDER_DETAILS_WHEELS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_DETAILS_WHEELS' }),
    [ACCESS_ORDER_DETAILS_STOCK]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_DETAILS_STOCK' }),
    [ACCESS_ORDER_DETAILS_FIND_FROM_VEHICLE]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_DETAILS_FIND_FROM_VEHICLE'
    }),
    [ACCESS_ORDER_DETAILS_FIND_FROM_CODE]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_DETAILS_FIND_FROM_CODE'
    }),
    [ACCESS_ORDER_DETAILS_FIND_OILS]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_DETAILS_FIND_OILS'
    }),
    [ACCESS_ORDER_DETAILS_FIND_WHEELS]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_DETAILS_FIND_WHEELS'
    }),
    [ACCESS_ORDER_DETAILS_FIND_STOCK]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_DETAILS_FIND_STOCK'
    }),
    [ACCESS_ORDER_DETAILS_CHANGE_STATUS]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_DETAILS_CHANGE_STATUS'
    }),
    [ACCESS_ORDER_DETAILS_DISCOUNTS]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_DETAILS_DISCOUNTS'
    }),

    [ACCESS_ORDER_TABS_REPAIR_MAP_UPDATE]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_TABS_REPAIR_MAP_UPDATE'
    }),
    [ACCESS_ORDER_TABS_WORKSHOP_BUTTONS]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_TABS_WORKSHOP_BUTTONS'
    }),
    [ACCESS_ORDER_TABS_WORKSHOP_FINISH]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_TABS_WORKSHOP_FINISH'
    }),
    [ACCESS_ORDER_TABS_STOCK_BUTTONS_STOCK]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_TABS_STOCK_BUTTONS_STOCK'
    }),
    [ACCESS_ORDER_TABS_STOCK_BUTTONS_WORKSHOP]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_TABS_STOCK_BUTTONS_WORKSHOP'
    }),
    [ACCESS_ORDER_TABS_COMMENTS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_TABS_COMMENTS_CRUD'
    }),
    [ACCESS_ORDER_TABS_POSTS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_ORDER_TABS_POSTS_CRUD'
    }),

    [GET_TASKS]: intl.formatMessage({ id: 'roles.GET_TASKS' }),
    [GET_ALL_TASKS]: intl.formatMessage({ id: 'roles.GET_ALL_TASKS' }),
    [ACCESS_ORDER_TASKS]: intl.formatMessage({ id: 'roles.ACCESS_ORDER_TASKS' }),
    [ACCESS_EMPLOYEE_TASKS]: intl.formatMessage({ id: 'roles.ACCESS_EMPLOYEE_TASKS' }),

    [GET_CLIENTS]: intl.formatMessage({ id: 'roles.GET_CLIENTS' }),
    [CREATE_EDIT_DELETE_CLIENTS]: intl.formatMessage({ id: 'roles.CREATE_EDIT_DELETE_CLIENTS' }),
    [CREATE_EDIT_DELETE_CLIENT_VEHICLES]: intl.formatMessage({
        id: 'roles.CREATE_EDIT_DELETE_CLIENT_VEHICLES'
    }),
    [ACCESS_CLIENTS_VEHICLE_TRANSFER]: intl.formatMessage({
        id: 'roles.ACCESS_CLIENTS_VEHICLE_TRANSFER'
    }),
    [FILTER_CLIENTS]: intl.formatMessage({ id: 'roles.FILTER_CLIENTS' }),
    [GET_CLIENTS_BASIC_INFORMATION]: intl.formatMessage({
        id: 'roles.GET_CLIENTS_BASIC_INFORMATION'
    }),
    [GET_CLIENTS_ADDITIONAL_INFORMATION]: intl.formatMessage({
        id: 'roles.GET_CLIENTS_ADDITIONAL_INFORMATION'
    }),
    [ACCESS_CLIENTS_REQUISITES]: intl.formatMessage({ id: 'roles.ACCESS_CLIENTS_REQUISITES' }),
    [ACCESS_RECEIVABLES_GET]: intl.formatMessage({ id: 'roles.ACCESS_RECEIVABLES_GET' }),
    [ACCESS_RECEIVABLES_PAY]: intl.formatMessage({ id: 'roles.ACCESS_RECEIVABLES_PAY' }),
    [ACCESS_CLIENTS_LOCATIONS]: intl.formatMessage({ id: 'roles.ACCESS_CLIENTS_LOCATIONS' }),

    [ACCESS_SUPPLIERS]: intl.formatMessage({ id: 'roles.ACCESS_SUPPLIERS' }),
    [ACCESS_SUPPLIERS_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_SUPPLIERS_CRUD' }),
    [ACCESS_SUPPLIER]: intl.formatMessage({ id: 'roles.ACCESS_SUPPLIER' }),
    [ACCESS_SUPPLIER_STATISTICS]: intl.formatMessage({ id: 'roles.ACCESS_SUPPLIER_STATISTICS' }),
    [ACCESS_SUPPLIER_REQUISITES]: intl.formatMessage({ id: 'roles.ACCESS_SUPPLIER_REQUISITES' }),
    [ACCESS_SUPPLIER_CREDITOR_PAY]: intl.formatMessage({
        id: 'roles.ACCESS_SUPPLIER_CREDITOR_PAY'
    }),
    [ACCESS_SUPPLIER_CREDITOR_GET]: intl.formatMessage({
        id: 'roles.ACCESS_SUPPLIER_CREDITOR_GET'
    }),

    [GET_EMPLOYEES]: intl.formatMessage({ id: 'roles.GET_EMPLOYEES' }),
    [ACCESS_EMPLOYEE]: intl.formatMessage({ id: 'roles.ACCESS_EMPLOYEE' }),
    [CREATE_EDIT_DELETE_EMPLOYEES]: intl.formatMessage({
        id: 'roles.CREATE_EDIT_DELETE_EMPLOYEES'
    }),
    [EMPLOYEES_SALARIES]: intl.formatMessage({ id: 'roles.EMPLOYEES_SALARIES' }),
    [ACCESS_EMPLOYEE_SALARIES_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_EMPLOYEE_SALARIES_CRUD'
    }),
    [ACCESS_EMPLOYEE_SALARIES_CALC]: intl.formatMessage({
        id: 'roles.ACCESS_EMPLOYEE_SALARIES_CALC'
    }),
    [ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_CRUD'
    }),
    [ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_ANALYTICS]: intl.formatMessage({
        id: 'roles.ACCESS_EMPLOYEE_WORKPLACE_ACCOUNTING_ANALYTICS'
    }),

    [ACCESS_STORE_PRODUCTS]: intl.formatMessage({ id: 'roles.ACCESS_STORE_PRODUCTS' }),
    [EDIT_STORE_PRODUCT_PRICE]: intl.formatMessage({ id: 'roles.EDIT_STORE_PRODUCT_PRICE' }),
    [ACCESS_LABOR_CATALOGUE]: intl.formatMessage({ id: 'roles.ACCESS_LABOR_CATALOGUE' }),
    [ACCESS_CATALOGUE_LABORS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_LABORS_CRUD'
    }),
    [ACCESS_DIAGNOSTIC_CATALOGUE]: intl.formatMessage({ id: 'roles.ACCESS_DIAGNOSTIC_CATALOGUE' }),
    [ACCESS_CATALOGUE_DIAGNOSTICS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD'
    }),
    [ACCESS_CATALOGUE_COMPLEXES]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_COMPLEXES' }),
    [ACCESS_CATALOGUE_COMPLEXES_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_COMPLEXES_CRUD'
    }),
    [ACCESS_CATALOGUE_STOCK]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_STOCK' }),
    [ACCESS_CATALOGUE_STOCK_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_STOCK_CRUD' }),
    [ACCESS_CATALOGUE_CASH]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_CASH' }),
    [ACCESS_CATALOGUE_CASH_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_CASH_CRUD' }),
    [ACCESS_CATALOGUE_REQUISITES]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_REQUISITES' }),
    [ACCESS_CATALOGUE_REQUISITES_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_REQUISITES_CRUD'
    }),
    [ACCESS_CATALOGUE_REQUISITES_TAX_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_REQUISITES_TAX_CRUD'
    }),
    [ACCESS_STORE_GROUPS]: intl.formatMessage({ id: 'roles.ACCESS_STORE_GROUPS' }),
    [ACCESS_CATALOGUE_STORE_GROUPS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_STORE_GROUPS_CRUD'
    }),
    [ACCESS_PRICE_GROUPS]: intl.formatMessage({ id: 'roles.ACCESS_PRICE_GROUPS' }),
    [ACCESS_CATALOGUE_PRICE_GROUPS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_PRICE_GROUPS_CRUD'
    }),
    [ACCESS_CATALOGUE_UNITS]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_UNITS' }),
    [ACCESS_CATALOGUE_UNITS_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_UNITS_CRUD' }),
    [ACCESS_CATALOGUE_ANALYTICS]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_ANALYTICS' }),
    [ACCESS_CATALOGUE_ANALYTICS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_ANALYTICS_CRUD'
    }),
    [ACCESS_CATALOGUE_WMS]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_WMS' }),
    [ACCESS_CATALOGUE_WMS_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_WMS_CRUD' }),
    [ACCESS_CATALOGUE_LOCATIONS]: intl.formatMessage({ id: 'roles.ACCESS_CATALOGUE_LOCATIONS' }),
    [ACCESS_CATALOGUE_LOCATIONS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_CATALOGUE_LOCATIONS_CRUD'
    }),

    [ACCESS_STOCK]: intl.formatMessage({ id: 'roles.ACCESS_STOCK' }),
    [ACCESS_SUPPLIER_ORDER_STORE_DOCS]: intl.formatMessage({
        id: 'roles.ACCESS_SUPPLIER_ORDER_STORE_DOCS'
    }),
    [ACCESS_SUPPLIER_ORDER_STORE_DOCS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_SUPPLIER_ORDER_STORE_DOCS_CRUD'
    }),
    [ACCESS_SUPPLIER_ORDER_STORE_DOCS_AUTO]: intl.formatMessage({
        id: 'roles.ACCESS_SUPPLIER_ORDER_STORE_DOCS_AUTO'
    }),
    [ACCESS_INCOME_STORE_DOCS]: intl.formatMessage({ id: 'roles.ACCESS_INCOME_STORE_DOCS' }),
    [ACCESS_INCOME_STORE_DOCS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_INCOME_STORE_DOCS_CRUD'
    }),
    [ACCESS_INCOME_STORE_DOCS_AUTO]: intl.formatMessage({
        id: 'roles.ACCESS_INCOME_STORE_DOCS_AUTO'
    }),
    [ACCESS_EXPENSE_STORE_DOCS]: intl.formatMessage({ id: 'roles.ACCESS_EXPENSE_STORE_DOCS' }),
    [ACCESS_EXPENSE_STORE_DOCS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_EXPENSE_STORE_DOCS_CRUD'
    }),
    [ACCESS_TRANSFER_STORE_DOCS]: intl.formatMessage({ id: 'roles.ACCESS_TRANSFER_STORE_DOCS' }),
    [ACCESS_TRANSFER_STORE_DOCS_CRUD]: intl.formatMessage({
        id: 'roles.ACCESS_TRANSFER_STORE_DOCS_CRUD'
    }),
    [ACCESS_TOOL_STORE_DOCS]: intl.formatMessage({ id: 'roles.ACCESS_TOOL_STORE_DOCS' }),
    [ACCESS_TOOL_STORE_DOCS_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_TOOL_STORE_DOCS_CRUD' }),
    [VIEW_STORE]: intl.formatMessage({ id: 'roles.VIEW_STORE' }),
    [SEARCH_STORE_PRODUCT]: intl.formatMessage({ id: 'roles.SEARCH_STORE_PRODUCT' }),
    [VIEW_STORE_WITH_PRICE]: intl.formatMessage({ id: 'roles.VIEW_STORE_WITH_PRICE' }),
    [SEARCH_STORE_PRODUCT_WITH_PRICE]: intl.formatMessage({
        id: 'roles.SEARCH_STORE_PRODUCT_WITH_PRICE'
    }),
    [ACCESS_RESERVES_STORE_DOCS]: intl.formatMessage({ id: 'roles.ACCESS_RESERVES_STORE_DOCS' }),
    [ACCESS_ORDERS_STORE_DOCS]: intl.formatMessage({ id: 'roles.ACCESS_ORDERS_STORE_DOCS' }),
    [ACCESS_STOCK_INVENTORIZATION]: intl.formatMessage({
        id: 'roles.ACCESS_STOCK_INVENTORIZATION'
    }),
    [ACCESS_RESET_STORE_ORDERS]: intl.formatMessage({ id: 'roles.ACCESS_RESET_STORE_ORDERS' }),
    [ACCESS_RECALC_FIFO_STORE_DOCS]: intl.formatMessage({
        id: 'roles.ACCESS_RECALC_FIFO_STORE_DOCS'
    }),

    [ACCESS_ACCOUNTING]: intl.formatMessage({ id: 'roles.ACCESS_ACCOUNTING' }),
    [EDIT_CASH_ORDERS]: intl.formatMessage({ id: 'roles.EDIT_CASH_ORDERS' }),
    [ACCESS_FINANCE_CASH]: intl.formatMessage({ id: 'roles.ACCESS_FINANCE_CASH' }),
    [ACCESS_FINANCE_RECEIVABLE]: intl.formatMessage({ id: 'roles.ACCESS_FINANCE_RECEIVABLE' }),
    [ACCESS_FINANCE_CREDITOR]: intl.formatMessage({ id: 'roles.ACCESS_FINANCE_CREDITOR' }),
    [ACCESS_UPDATE_COMMENTS_ANALYTICS_CASH_ORDER]: intl.formatMessage({
        id: 'roles.ACCESS_UPDATE_COMMENTS_ANALYTICS_CASH_ORDER'
    }),
    [ACCESS_UPDATE_DATE_SUM_CASH_ORDER]: intl.formatMessage({
        id: 'roles.ACCESS_UPDATE_DATE_SUM_CASH_ORDER'
    }),

    [ACCESS_KPI]: intl.formatMessage({ id: 'roles.ACCESS_KPI' }),
    [ACCESS_CALL_STATISTICS]: intl.formatMessage({ id: 'roles.ACCESS_CALL_STATISTICS' }),
    [ACCESS_REPORT_PROFIT_FROM_ORDERS]: intl.formatMessage({
        id: 'roles.ACCESS_REPORT_PROFIT_FROM_ORDERS'
    }),
    [ACCESS_REPORT_PROFIT_FROM_ORDERS_PRINT]: intl.formatMessage({
        id: 'roles.ACCESS_REPORT_PROFIT_FROM_ORDERS_PRINT'
    }),
    [ACCESS_REPORT_TIME_EFFICIENCY]: intl.formatMessage({
        id: 'roles.ACCESS_REPORT_TIME_EFFICIENCY'
    }),
    [ACCESS_REPORT_STOCK]: intl.formatMessage({ id: 'roles.ACCESS_REPORT_STOCK' }),
    [ACCESS_REPORT_PRODUCTS]: intl.formatMessage({ id: 'roles.ACCESS_REPORT_PRODUCTS' }),
    [ACCESS_REPORT_EMPLOYEE_SALARIES]: intl.formatMessage({
        id: 'roles.ACCESS_REPORT_EMPLOYEE_SALARIES'
    }),
    [ACCESS_REPORT_PROFIT_FROM_BUSINESSES]: intl.formatMessage({
        id: 'roles.ACCESS_REPORT_PROFIT_FROM_BUSINESSES'
    }),
    [ACCESS_REPORT_PROFIT_FROM_BUSINESSES_PRINT]: intl.formatMessage({
        id: 'roles.ACCESS_REPORT_PROFIT_FROM_BUSINESSES_PRINT'
    }),

    [ACCESS_TELEGRAM]: intl.formatMessage({ id: 'roles.ACCESS_TELEGRAM' }),
    [ACCESS_SETTINGS]: intl.formatMessage({ id: 'roles.ACCESS_SETTINGS' }),
    [ACCESS_SETTINGS_WEB]: intl.formatMessage({ id: 'roles.ACCESS_SETTINGS_WEB' }),
    [ACCESS_ADMINISTRATION]: intl.formatMessage({ id: 'roles.ACCESS_ADMINISTRATION' }),
    [GRANT]: intl.formatMessage({ id: 'roles.GRANT' }),
    [ACCESS_DISTRIBUTORS]: intl.formatMessage({ id: 'roles.ACCESS_DISTRIBUTORS' }),
    [ACCESS_SMS_TEMPLATES]: intl.formatMessage({ id: 'roles.ACCESS_SMS_TEMPLATES' }),

    [ACCESS_SYNC_CATALOGUES]: intl.formatMessage({ id: 'roles.ACCESS_SYNC_CATALOGUES' }),
    [ACCESS_SYNC_ORDERS]: intl.formatMessage({ id: 'roles.ACCESS_SYNC_ORDERS' }),
    [ACCESS_SYNC_STOCK]: intl.formatMessage({ id: 'roles.ACCESS_SYNC_STOCK' }),
    [ACCESS_SYNC_SETTINGS]: intl.formatMessage({ id: 'roles.ACCESS_SYNC_SETTINGS' }),
    [ACCESS_SYNC_EXPORT]: intl.formatMessage({ id: 'roles.ACCESS_SYNC_EXPORT' }),
    [ACCESS_SYNC_IMPORT]: intl.formatMessage({ id: 'roles.ACCESS_SYNC_IMPORT' }),

    [ACCESS_API_PRICE]: intl.formatMessage({ id: 'roles.ACCESS_API_PRICE' }),
    [ACCESS_API_AVAILABILITY]: intl.formatMessage({ id: 'roles.ACCESS_API_AVAILABILITY' }),
    [ACCESS_API_EXPENSE_ORDER]: intl.formatMessage({ id: 'roles.ACCESS_API_EXPENSE_ORDER' }),
    [ACCESS_API_INCOME_ORDER]: intl.formatMessage({ id: 'roles.ACCESS_API_INCOME_ORDER' }),

    [ACCESS_OTHER_OPERATION_RST]: intl.formatMessage({ id: 'roles.ACCESS_OTHER_OPERATION_RST' }),
    [ACCESS_CASHBOX_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_CASHBOX_CRUD' }),
    [ACCESS_CASHIER_CRUD]: intl.formatMessage({ id: 'roles.ACCESS_CASHIER_CRUD' }),
    [ACCESS_SALE_RST]: intl.formatMessage({ id: 'roles.ACCESS_SALE_RST' }),
    [ACCESS_CASHBOX_RST_LOGS]: intl.formatMessage({ id: 'roles.ACCESS_CASHBOX_RST_LOGS' })
});
