import React from 'react';
import Styles from './styles.m.css';

const Loader = ({ loading, background = 'white' }) =>
    loading && (
        <div className={Styles.loader} style={{ background }}>
            <div className={Styles.animation} />
        </div>
    );

export default Loader;
