import React, { Component } from 'react';
import { CloseCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Steps } from 'antd';

import Styles from './styles.m.css';
import './arrowNav.css';

const { Step } = Steps;

export default class ArrowsNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0
        };
    }

    _goToStep(step) {
        this.setState({ current: step });
    }

    _setIcon(steps, step) {
        if (step === steps.length - 1) {
            return <CloseCircleOutlined />;
        }
        if (step === steps.length - 2) {
            return <DatabaseOutlined />;
        }

        return null;
    }

    render() {
        const { steps } = this.props;
        const { current } = this.state;

        return (
            <div>
                <Steps className={Styles.steps} current={current}>
                    {steps.map((item, index) => (
                        <Step
                            key={item.title}
                            className={Styles.step}
                            icon={this._setIcon(steps, index)}
                            onClick={() => this._goToStep(index)}
                            title={item.title}
                            // icon={
                            //     index === steps.length - 1 ? (
                            //         <Icon type='close-circle-o' />
                            //     ) : null
                            // }
                        />
                    ))}
                </Steps>
                <div>{steps[this.state.current].content}</div>
            </div>
        );
    }
}
