import { Tabs } from 'antd';
import { Layout } from 'commons';
import { fetchWarehouses } from 'core/warehouses/duck';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import OrderDetailBody from './components/OrderDetailBody';
import SearchByVehicle from './components/SearchByVehicle';

const { TabPane } = Tabs;

const { injectIntl, FormattedMessage } = require('react-intl');

const OrderCataloguePage = ({ warehouses, fetchWarehouses, intl: { formatMessage } }) => {
    const [catalogueData, setCatalogueData] = useState({});
    const [activeKey, setActiveKey] = useState('catalogue');
    const location = useLocation();

    const handleSetCatalogueData = obj => setCatalogueData(prev => ({ ...prev, ...obj }));

    useEffect(() => {
        setActiveKey(get(location, 'state.activeTab', 'catalogue'));
    }, [location]);

    useEffect(() => {
        fetchWarehouses();
    }, []);

    return (
        <Layout
            // className='settings_page'
            // controls={
            //     <a href={book.oldApp.settings}>
            //         <Button icon={<GlobalOutlined />} size='large' />
            //     </a>
            // }
            // description={<FormattedMessage id='navigation.web_settings' />}
            title={<FormattedMessage id='navigation.order_catalogue' />}
        >
            <Tabs activeKey={activeKey} onChange={setActiveKey}>
                <TabPane key='catalogue' tab={<FormattedMessage id='search_by_detail_vin' />}>
                    <OrderDetailBody catalogueData={catalogueData} location={location.state} warehouses={warehouses} />
                </TabPane>
                <TabPane key='vehicle' tab={<FormattedMessage id='search_by_vehicle' />}>
                    <SearchByVehicle
                        initialVehicleData={get(location, 'state.vehicle')}
                        openCatalogue={() => setActiveKey('catalogue')}
                        setCatalogueData={handleSetCatalogueData}
                        warehouses={warehouses}
                    />
                </TabPane>
            </Tabs>
        </Layout>
    );
};

const mapStateToProps = state => ({
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    fetchWarehouses
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderCataloguePage));
