import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    CHANGE_ORDERS_SURPLUSES_DATA_FILTERS,
    CHANGE_SPARE_PARTS_WORKPLACE_DATA_FILTERS,
    CHANGE_SPARE_PARTS_WORKPLACE_TAB,
    FETCH_SPARE_PARTS_WORKPLACE_DATA,
    RETURN_DETAILS_TO_SUPPLIER,
    UPDATE_ORDERS_FOR_SURPLUSES,
    UPDATE_SPARE_PARTS_WORKPLACE_DATA,
    fetchSparePartsWorkplaceData,
    fetchSparePartsWorkplaceDataSuccess,
    selectSparePartsWorkplaceFilters,
    selectSparePartsWorkplaceStorageFilters,
    selectSparePartsWorkplaceTab
} from './duck';

const buildVariations = orderDetails => {
    const tmp = [...orderDetails]
        .map((elem, key) => {
            return { ...elem, variations: [] };
        })
        .filter(({ groupName, agreement, id }) => {
            const res = orderDetails.filter(({ groupName: gpName }) => gpName === groupName);
            if (res.every(({ agreement }) => agreement === 'REJECTED') && id === res[0].id) {
                return true;
            }

            return (groupName && agreement !== 'REJECTED') || !groupName;
        });

    orderDetails
        .filter(({ id }) => tmp.findIndex(({ id: ID }) => ID === id) === -1)
        .forEach((elem, key) => {
            const index = tmp.findIndex(({ groupName }) => groupName === elem.groupName);
            if (index > -1) {
                tmp[index].variations.push({
                    ...elem,
                    leadIndex: index,
                    key
                });
            }
        });

    return tmp.map((elem, key) => ({
        ...elem,
        key
    }));
};

export function* fetchSparePartsWorkplaceDataSaga() {
    while (true) {
        try {
            yield take(FETCH_SPARE_PARTS_WORKPLACE_DATA);
            const tab = yield select(selectSparePartsWorkplaceTab);
            yield nprogress.start();

            const filters = yield select(selectSparePartsWorkplaceFilters);
            const storageFilters = yield select(selectSparePartsWorkplaceStorageFilters);
            const defaultState = {
                details: [],
                statistics: {
                    critical: 0,
                    new: 0,
                    overdue: 0,
                    ending: 0,
                    totalCount: 0
                }
            };
            const requestData = [defaultState, defaultState];
            if (tab == 'fromOrders') {
                const sparePartsData = yield call(fetchAPI, 'GET', 'orders/details', { ...filters }, undefined, {
                    handleErrorInternally: true
                });
                sparePartsData.details = buildVariations(sparePartsData.details);
                requestData[0] = sparePartsData;
            }
            if (tab == 'storageOrders') {
                const ordersToStorageData = yield call(
                    fetchAPI,
                    'GET',
                    'orders/details',
                    {
                        orderStatuses: '["not_completed_supplier_income"]',
                        ...storageFilters
                    },
                    undefined,
                    { handleErrorInternally: true }
                );
                requestData[1] = ordersToStorageData;
            }
            yield put(fetchSparePartsWorkplaceDataSuccess(requestData[0], requestData[1]));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* updateSparePartsWorkplaceDataSaga() {
    while (true) {
        try {
            const {
                payload: { details }
            } = yield take(UPDATE_SPARE_PARTS_WORKPLACE_DATA);
            yield call(fetchAPI, 'PUT', 'orders/details', undefined, { details }, { handleErrorInternally: true });
        } finally {
            yield put(fetchSparePartsWorkplaceData());
        }
    }
}

export function* updateOrdersForSurplusesDataSaga() {
    while (true) {
        try {
            const {
                payload: { details }
            } = yield take(UPDATE_ORDERS_FOR_SURPLUSES);
            yield call(fetchAPI, 'PUT', 'orders/details', undefined, { details }, { handleErrorInternally: true });
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* changeSparePartsWorkplaceDataFilterSaga() {
    while (true) {
        try {
            yield take(CHANGE_SPARE_PARTS_WORKPLACE_DATA_FILTERS);

            yield put(fetchSparePartsWorkplaceData());
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* changeOrdersSurplusesSaga() {
    while (true) {
        try {
            yield take(CHANGE_ORDERS_SURPLUSES_DATA_FILTERS);
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* returnDetailsToSupplierSaga() {
    while (true) {
        try {
            const {
                payload: { ordersAppurtenanciesIds }
            } = yield take(RETURN_DETAILS_TO_SUPPLIER);
            yield call(
                fetchAPI,
                'POST',
                'store_docs/return_to_supplier_all_possible',
                undefined,
                { ordersAppurtenanciesIds: [...ordersAppurtenanciesIds] },
                { handleErrorInternally: true }
            );
        } finally {
            yield put(fetchSparePartsWorkplaceData());
        }
    }
}

export function* changeSparePartsWorkplaceTabSaga() {
    while (true) {
        try {
            yield take(CHANGE_SPARE_PARTS_WORKPLACE_TAB);
            yield put(fetchSparePartsWorkplaceData());
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([
        call(fetchSparePartsWorkplaceDataSaga),
        call(updateSparePartsWorkplaceDataSaga),
        call(updateOrdersForSurplusesDataSaga),
        call(changeSparePartsWorkplaceDataFilterSaga),
        call(returnDetailsToSupplierSaga),
        call(changeSparePartsWorkplaceTabSaga),
        call(changeOrdersSurplusesSaga)
    ]);
}
