import { Checkbox, Form, Input, InputNumber, Modal, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const AddTaxModal = ({ open, onCancel, editObject, fetchSalaryTaxes, intl: { formatMessage } }) => {
    const [form] = useForm();
    const [okButtonLoading, setOkButtonLoading] = useState(false);

    const isDefault = editObject ? editObject.isDefault : false;

    const onClose = () => {
        form.resetFields();
        onCancel();
    };

    const submitSalaryTaxes = async data => {
        try {
            let method = 'POST';
            let defaultId = {};
            let eObject = {};
            if (editObject) {
                method = 'PUT';
                eObject = { id: editObject.id };
                if (editObject.isDefault || editObject.defaultId) {
                    defaultId = { defaultId: editObject.defaultId };
                }
            }
            const dataToSubmit = { ...data, ...eObject, ...defaultId };

            setOkButtonLoading(true);
            await fetchAPI(method, '/salary/taxes', null, dataToSubmit, { handleErrorInternally: true });
            notification.success({ message: formatMessage({ id: 'barcode.success' }) });
            onClose();
            fetchSalaryTaxes();
        } catch (e) {
            notification.error(formatMessage({ id: 'error' }));
        } finally {
            setOkButtonLoading(false);
        }
    };

    const handleSubmit = () => {
        submitSalaryTaxes(form.getFieldsValue());
    };

    const onOk = () => {
        form.submit();
    };

    useEffect(() => {
        if (open) {
            if (editObject) {
                form.setFields(Object.entries(editObject).map(([key, value]) => ({ name: key, value })));
            } else {
                form.resetFields();
            }
        }
    }, [open]);

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{ loading: okButtonLoading }}
            onCancel={onClose}
            onOk={onOk}
            open={open}
            title={formatMessage({ id: 'add_tax' })}
        >
            <Form
                form={form}
                initialValues={{ isPercent: true }}
                layout='vertical'
                name='changeSchedule'
                onFinish={handleSubmit}
            >
                <Form.Item name='taxName'>
                    <Input disabled={isDefault} placeholder={formatMessage({ id: 'name_2' })} />
                    {/* <Select 
                        showSearch
                        mode='multiple' 
                        optionFilterProp='label'
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        options={employeesOptions}
                    /> */}
                </Form.Item>
                <Form.Item
                    name='taxRate'
                    rules={[
                        {
                            required: true,
                            min: 0.1,
                            transform: value => Number(value),
                            message: `${formatMessage({ id: 'please_enter' })} ${formatMessage({ id: 'tax_rate' })}`
                        }
                    ]}
                >
                    <InputNumber
                        decimalSeparator=','
                        disabled={isDefault}
                        min={0.1}
                        placeholder={formatMessage({ id: 'tax_rate' })}
                    />
                </Form.Item>
                {/* currencyId */}
                <Form.Item
                    label={formatMessage({ id: 'locations.percent' })}
                    name='isPercent'
                    rules={[{ required: true }]}
                    valuePropName='checked'
                >
                    <Checkbox disabled={isDefault} />
                </Form.Item>
                {/* comment */}
                <Form.Item name='recipient' rules={[{ required: true }]}>
                    <Input placeholder={formatMessage({ id: 'refactored_cash_order_modal.receiver' })} />
                </Form.Item>
                <Form.Item name='code' rules={[{ required: true }]}>
                    <Input placeholder={formatMessage({ id: 'diagnostic-page.code' })} />
                </Form.Item>
                <Form.Item name='bank' rules={[{ required: true }]}>
                    <Input placeholder={formatMessage({ id: 'requisite-setting.bank' })} />
                </Form.Item>
                <Form.Item name='iban' rules={[{ required: true, len: 29 }]}>
                    <Input maxLength={29} placeholder={formatMessage({ id: 'IBAN' })} />
                </Form.Item>
                <Form.Item name='comment'>
                    <Input.TextArea placeholder={formatMessage({ id: 'comment' })} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default injectIntl(AddTaxModal);
