import { saveAs } from 'file-saver';
import { get } from 'lodash';
import { fetchAPI } from 'utils';

export const URL = window.location.hostname;
export const API_URL = __API_URL__;

export function getDiagnosticsTemplates(getData) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = '/diagnostics';
    url += params;

    fetch(url, {
        method: 'GET',
        headers: {
            Authorization: token
        }
    })
        .then(function (response) {
            if (response.status !== 200) {
                return Promise.reject(new Error(response.statusText));
            }

            return Promise.resolve(response);
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            getData(data);
        })
        .catch(function (error) {
            console.log('error', error);
        });
}

export async function sendDiagnosticAnswer(
    orderId,
    templateId,
    groupId,
    partId,
    index,
    answer,
    comment,
    oeCode,
    photoHashes,
    rectangleId
) {
    await fetchAPI(
        'PUT',
        'orders/diagnostics/answer',
        null,
        {
            orderId,
            templateId,
            groupId,
            partId,
            index,
            oeCode,
            answer: String(answer),
            comment: { ...comment },
            photoHashes: photoHashes || [],
        },
        { handleErrorInternally: true }
    );
}

export async function confirmDiagnostic(orderId, data) {
    await fetchAPI('PUT', `/orders/${orderId}`, null, data, { handleErrorInternally: true });
}

export async function lockDiagnostic(orderId) {
    await fetchAPI('LOCK', `orders/${orderId}/diagnostics`, null, null, {
        handleErrorInternally: true
    });
}

export async function deleteDiagnosticProcess(orderId, templateId, groupId, partId, index) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/orders/diagnostics/part?orderId=${orderId}&templateId=${templateId}&groupId=${groupId}&partId=${partId}&index=${index}`;
    url += params;
    try {
        await fetch(url, {
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        });
    } catch (error) {
        console.error('ERROR:', error);
    }
}

export async function addNewDiagnosticRow(orderId, templateId, groupId, partId, index) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/orders/diagnostics/part?orderId=${orderId}&templateId=${templateId}&groupId=${groupId}&partId=${partId}&index=${
        index || 0
    }`;
    url += params;
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: token
            }
        });
        const result = await response.json();
        if (result.success) {
            // console.log("OK", result);
        } else {
            console.log('BAD', result);
        }
    } catch (error) {
        console.error('ERROR:', error);
    }
}

export async function addNewDiagnosticTemplate(orderId, templateId) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/orders/diagnostics/template?orderId=${orderId}&templateId=${templateId}`;
    url += params;
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: token
            }
        });
        const result = await response.json();
        if (result.success) {
            // console.log("OK", result);
        } else {
            console.log('BAD', result);
        }
    } catch (error) {
        console.error('ERROR:', error);
    }
}

export async function deleteDiagnosticTemplate(orderId, templateId) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/orders/diagnostics/template?orderId=${orderId}&templateId=${templateId}`;
    url += params;
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        });
        const result = await response.json();
        if (result.success) {
            // console.log("OK", result);
        } else {
            console.log('BAD', result);
        }
    } catch (error) {
        console.error('ERROR:', error);
    }
}

export async function createAgreement(orderId, lang, confirmFunc, errorFunc) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/orders/create_agreement?orderId=${orderId}`;
    url += params;
    fetch(url, {
        method: 'POST',
        headers: {
            Authorization: token
        }
    })
        .then(function (response) {
            if (response.status !== 200) {
                return Promise.reject(new Error(response.statusText));
            }

            return Promise.resolve(response);
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            confirmFunc();
        })
        .catch(function (error) {
            errorFunc();
        });
}

export async function getPartProblems(partId, getData) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/diagnostics_problems?partIds=[${partId}]`;
    url += params;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        });
        const result = await response.json();
        getData(get(result, '[0].problems', []));
    } catch (error) {
        console.error('ERROR:', error);
    }
}

export async function sendMessage(orderId) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/orders/${orderId}/send_diagnostics_complete_message`;
    url += params;
    fetch(url, {
        method: 'GET',
        headers: {
            Authorization: token
        }
    })
        .then(function (response) {
            if (response.status !== 200) {
                return Promise.reject(new Error(response.statusText));
            }

            return Promise.resolve(response);
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            console.log('data', data);
        })
        .catch(function (error) {
            console.log('error', error);
        });
}

export async function getDiagnosticsReport(orderId) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/diagnostics/report?orderId=${orderId}`;
    url += params;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        });
        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
    } catch (error) {
        console.error('ERROR:', error);
    }
}

export async function getDiagnosticsAct(orderId) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/orders/reports/diagnosticsReport/${orderId}`;
    url += params;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        });
        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
    } catch (error) {
        console.error('ERROR:', error);
    }
}

export async function getPackingListWithAddressesReport(orderId) {
    const token = localStorage.getItem('_my.carbook.pro_token');
    let url = API_URL;
    const params = `/orders/reports/packingListWithAddressesReport/${orderId}`;
    url += params;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        });
        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
    } catch (error) {
        console.error('ERROR:', error);
    }
}
