/* eslint-disable import/first */
// import './wdyr'; // <--- first import

import React from 'react';
import { render } from 'react-dom';

import './theme/antd/antd.less';
import './theme/init.css'; // only chunk (split-point)

import './store/nprogress'; // whole file

import App from './App';

window.addEventListener('unload', function () {
    document.documentElement.innerHTML = '';
});

render(<App />, document.getElementById('app'));
