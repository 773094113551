exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__brandDeleteIcon---11lbY{font-size:24px;cursor:pointer;color:red}.styles-m__brandsSelect---Rs3L2{width:100%}.styles-m__brandsFormItem---9SJT2{margin-bottom:0}.styles-m__newBrandRow---zjdT9{background-color:#90ee90}.styles-m__editedBrandRow---zCSPl{background-color:#fff1f3}.styles-m__brandSaveIcon---IA7IG{font-size:24px;cursor:pointer;color:green}.styles-m__swapIcon---2RYkD{cursor:pointer}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,mCACI,cAAe,CACf,cAAe,CACf,SACJ,CAEA,gCACI,UACJ,CAEA,kCACI,eACJ,CAEA,+BACI,wBACJ,CAEA,kCACI,wBACJ,CAEA,iCACI,cAAe,CACf,cAAe,CACf,WACJ,CAEA,4BACI,cACJ","file":"styles.m.css","sourcesContent":[".brandDeleteIcon {\n    font-size: 24px;\n    cursor: pointer;\n    color: red;\n}\n\n.brandsSelect {\n    width: 100%;\n}\n\n.brandsFormItem {\n    margin-bottom: 0;\n}\n\n.newBrandRow {\n    background-color: #90EE90;\n}\n\n.editedBrandRow {\n    background-color: #fff1f3;\n}\n\n.brandSaveIcon {\n    font-size: 24px;\n    cursor: pointer;\n    color: green;\n}\n\n.swapIcon {\n    cursor: pointer;\n}"]}]);

// Exports
exports.locals = {
	"brandDeleteIcon": "styles-m__brandDeleteIcon---11lbY",
	"brandsSelect": "styles-m__brandsSelect---Rs3L2",
	"brandsFormItem": "styles-m__brandsFormItem---9SJT2",
	"newBrandRow": "styles-m__newBrandRow---zjdT9",
	"editedBrandRow": "styles-m__editedBrandRow---zCSPl",
	"brandSaveIcon": "styles-m__brandSaveIcon---IA7IG",
	"swapIcon": "styles-m__swapIcon---2RYkD"
};