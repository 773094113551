exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__stats---AjZR-{margin-bottom:24px}.styles-m__extendedStats---3B3T2,.styles-m__stats---AjZR-{display:flex;justify-content:space-between}.styles-m__count---1Orhw{flex-direction:column;flex:0 0 15%}.styles-m__count---1Orhw,.styles-m__extendedCounts---3RNFl{display:flex;border:1px solid var(--primary);text-align:center;padding:8px}.styles-m__extendedCounts---3RNFl{flex-direction:column;flex:0 1 30%}.styles-m__statsHeader---1LFuL{color:grey;margin-bottom:.4em}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBAGI,kBACJ,CAEA,0DALI,YAAa,CACb,6BAOJ,CAEA,yBAEI,qBAAsB,CAItB,YACJ,CAEA,2DARI,YAAa,CAEb,+BAAgC,CAChC,iBAAkB,CAClB,WAWJ,CAPA,kCAEI,qBAAsB,CAItB,YACJ,CAEA,+BACI,UAAW,CACX,kBACJ","file":"styles.m.css","sourcesContent":[".stats {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 24px;\n}\n\n.extendedStats {\n    display: flex;\n    justify-content: space-between;\n}\n\n.count {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--primary);\n    text-align: center;\n    padding: 8px;\n    flex: 0 0 15%;\n}\n\n.extendedCounts {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--primary);\n    text-align: center;\n    padding: 8px;\n    flex: 0 1 30%;\n}\n\n.statsHeader {\n    color: grey;\n    margin-bottom: 0.4em;\n}"]}]);

// Exports
exports.locals = {
	"stats": "styles-m__stats---AjZR-",
	"extendedStats": "styles-m__extendedStats---3B3T2",
	"count": "styles-m__count---1Orhw",
	"extendedCounts": "styles-m__extendedCounts---3RNFl",
	"statsHeader": "styles-m__statsHeader---1LFuL"
};