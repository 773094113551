import { addError, setBrandsFetchingState } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchAPI, toDuckError } from 'utils';
import {
    CREATE_PRIORITY_BRAND,
    DELETE_PRIORITY_BRAND,
    fetchPriorityBrands,
    fetchPriorityBrandsSuccess,
    FETCH_PRIORITY_BRANDS,
    setSearchBusinessesSuccess,
    setSearchProductsSuccess,
    setSearchSuppliersSuccess,
    SET_FILTER,
    SET_SEARCH_BUSINESSES,
    SET_SEARCH_PRODUCTS,
    SET_SEARCH_SUPPLIERS,
    SET_SORT,
    UPDATE_PRIORITY_BRAND
} from './duck';

export function* setSortSaga() {
    yield put(fetchPriorityBrands());
}

export function* fetchPriorityBrandsSaga() {
    while (true) {
        yield take(FETCH_PRIORITY_BRANDS);
        yield put(setBrandsFetchingState(true));

        const sort = yield select(state => state.forms.brandsForm.sort);
        const filter = yield select(state => state.forms.brandsForm.filter);
        const page = _.get(sort, 'page', 1);
        const sortOrder = _.get(sort, 'order', void 0);
        const sortField = _.get(sort, 'field', void 0);

        const data = yield call(fetchAPI, 'GET', '/tecdoc/product/supplier/priorities', {
            page,
            sortOrder,
            sortField,
            ...filter
        });

        yield put(fetchPriorityBrandsSuccess(_.chain(data).values().first().value()));
        yield put(setBrandsFetchingState(false));
    }
}

export function* searchSuppliersSaga({ payload: { id, query } }) {
    yield delay(1000);
    const data = yield call(fetchAPI, 'GET', '/tecdoc/suppliers/search', {
        query
    });

    yield put(setSearchSuppliersSuccess(id, _.get(data, 'suppliers', [])));
}

export function* searchProductsSaga({ payload: { id, query } }) {
    yield delay(1000);
    const data = yield call(fetchAPI, 'GET', '/tecdoc/products/search', {
        query
    });

    yield put(setSearchProductsSuccess(id, _.get(data, 'products', [])));
}

export function* searchBusinessesSaga({ payload: { id, query } }) {
    yield delay(1000);
    const data = yield call(fetchAPI, 'GET', '/businesses/search', {
        search: query
    });

    yield put(setSearchBusinessesSuccess(id, data));
}

export function* deletePriorityBrandSaga({ payload: id }) {
    yield put(setBrandsFetchingState(true));
    yield call(fetchAPI, 'DELETE', `/tecdoc/product/supplier/priorities/${id}`);
    yield put(fetchPriorityBrands());
}

export function* updatePriorityBrandSaga({ payload: { id, entity } }) {
    yield put(setBrandsFetchingState(true));
    try {
        yield call(fetchAPI, 'PUT', `/tecdoc/product/supplier/priorities/${id}`, void 0, entity, {
            handleErrorInternally: true
        });
    } catch (err) {
        const duckError = toDuckError(err, 'brandsForm');
        yield put(addError(duckError));
    }
    yield put(fetchPriorityBrands());
}

export function* createPriorityBrandSaga({ payload: entity }) {
    yield put(setBrandsFetchingState(true));
    try {
        yield call(fetchAPI, 'POST', '/tecdoc/product/supplier/priorities', void 0, entity, {
            handleErrorInternally: true
        });
    } catch (err) {
        const duckError = toDuckError(err, 'brandsForm');
        yield put(addError(duckError));
    }
    yield put(fetchPriorityBrands());
}

export function* saga() {
    yield all([
        call(fetchPriorityBrandsSaga),
        takeEvery([SET_SORT, SET_FILTER], setSortSaga),
        takeEvery(CREATE_PRIORITY_BRAND, createPriorityBrandSaga),
        takeEvery(UPDATE_PRIORITY_BRAND, updatePriorityBrandSaga),
        takeEvery(DELETE_PRIORITY_BRAND, deletePriorityBrandSaga),
        takeLatest(SET_SEARCH_SUPPLIERS, searchSuppliersSaga),
        takeLatest(SET_SEARCH_BUSINESSES, searchBusinessesSaga),
        takeLatest(SET_SEARCH_PRODUCTS, searchProductsSaga)
    ]);
}
