import {
    fetchVehicle,
    fetchVehicleAppurtenances,
    selectClient,
    selectVehicle,
    setExpandedVehicleId
} from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import VehicleAppurtenancesTable from './VehicleAppurtenancesTable';

const mapStateToProps = state => ({
    vehicle: selectVehicle(state),
    client: selectClient(state)
});

const mapDispatchToProps = {
    fetchVehicle,
    setExpandedVehicleId,
    fetchVehicleAppurtenances
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleSparePartsTab extends Component {
    render() {
        const {disabled} = this.props;

        return <VehicleAppurtenancesTable disabled={disabled} />;
    }
}
