import { Radio } from 'antd';
import _ from 'lodash';
import { SEARCH_TYPES } from 'modals/DebtZeroingModal/components/containers/ClientCounterpartyContainer/constants';
import { selectClientSearchType, setClientSearchType } from 'modals/DebtZeroingModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    clientSearchType: selectClientSearchType(state)
});

const mapDispatchToProps = {
    setClientSearchType
};

/**
 * This module is used to select a type of counterparty search (in this case - to search a client by client, order or store doc).
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientCounterpartySearchTypeSelector extends React.Component {
    render() {
        const {
            setClientSearchType,

            clientSearchType
        } = this.props;

        return (
            <RadioGroup
                className={Styles.radioGroup}
                onChange={e => setClientSearchType(e.target.value)}
                value={clientSearchType}
            >
                {_.map(SEARCH_TYPES, (type, index) => (
                    <Radio
                        key={index}
                        style={{
                            display: 'block'
                        }}
                        value={type}
                    >
                        <FormattedMessage id={`debt_zeroing_modal.${type}`} />
                    </Radio>
                ))}
            </RadioGroup>
        );
    }
}
