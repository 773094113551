import { DeleteOutlined, FilterOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Input, Popconfirm, Table } from 'antd';
import { Layout } from 'commons';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { CrossesAddModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

const mapStateToProps = state => {
    return {
        user: state.auth,
        modal: state.modals.modal,
        modalProps: state.modals.modalProps,
        brands: selectBrands(state)
    };
};

const mapDispatchToProps = {
    setModal,
    resetModal,
    fetchBrands
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CrossesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            visible: false,
            page: 1,
            productCrosses: [],
            filters: {},
            allLinks: [],
            helperDrawerOpen: false
        };
        this.columns = () => [
            {
                title: <FormattedMessage id='diagnostic-page.code' />,
                key: 'code',
                dataIndex: 'code',
                ...this.getColumnSearchProps('code')
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brandId',
                dataIndex: 'brandName',
                ...this.getColumnSearchProps('brandId'),
                render: data => data || undefined
            },
            {
                title: <FormattedMessage id='navigation.crosses' />,
                key: 'xCode',
                dataIndex: 'xCode',
                ...this.getColumnSearchProps('xCode')
            },
            {
                title: <FormattedMessage id='directories.x_brand' />,
                key: 'xBrandId',
                dataIndex: 'xBrandName',
                ...this.getColumnSearchProps('xBrandId'),
                render: data => data || undefined
            },
            {
                title: <FormattedMessage id='directories.direcroty' />,
                key: 'dir',
                dataIndex: 'dir',
                ...this.getColumnSearchProps('dir')
            },
            {
                key: 'delete',
                render: row => {
                    const accessAddButton = isGrantAccessed(this.props.user, grants.DIRECTORIES_CROSSES, accesses.ROWO);

                    return (
                        <div>
                            <Popconfirm
                                onConfirm={() => this.deleteCrosses(row.id)}
                                title={`${props.intl.formatMessage({ id: 'delete' })} ?`}
                            >
                                <Button
                                    disabled={!accessAddButton}
                                    style={{
                                        padding: '0px 8px',
                                        fontSize: 18
                                    }}
                                >
                                    <DeleteOutlined
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        title={this.props.intl.formatMessage({
                                            id: 'directory_page.delete'
                                        })}
                                    />
                                </Button>
                            </Popconfirm>
                        </div>
                    );
                }
            }
        ];
    }

    filterDataSource = dataSource => {
        const { filters, datetimeFrom, datetimeTo } = this.state;
        let result = [...dataSource];
        if (datetimeFrom && datetimeTo) {
            result = result.filter(
                row => dayjs(row.datetime).isAfter(datetimeFrom) && dayjs(row.datetime).isBefore(datetimeTo)
            );
        }
        Object.entries(filters).map(([key, value]) => {
            if (value) {
                result = result.filter(row => {
                    const field = String(_.get(row, key, '')).toLowerCase();

                    return field.includes(value.toLowerCase());
                });
            }
        });

        return result;
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_crosses' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    getColumnSearchProps = dataIndexes => {
        return {
            filtered: Boolean(_.get(this.state.filters, dataIndexes, false)),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        placeholder={this.props.intl.formatMessage({ id: 'search' })}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        size='small'
                        style={{ width: 90, marginRight: 8 }}
                        type='primary'
                    >
                        <FormattedMessage id='search' />
                    </Button>
                    <Button
                        onClick={() => this.handleReset(dataIndexes, clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        <FormattedMessage id='reset' />
                    </Button>
                </div>
            ),
            filterIcon: filtered => <FilterOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />,

            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            }
        };
    };

    handleSearch = (selectedKeys, confirm, dataIndexes) => {
        this.state.filters[`${dataIndexes}`] = selectedKeys[0];
        this.setState({});
    };

    handleReset = (dataIndexes, clearFilters) => {
        clearFilters();
        delete this.state.filters[`${dataIndexes}`];
        this.setState({});
    };

    fetchProductCrosses = async () => {
        const { code, brandId, xCode, xBrandId, dir, page } = this.state;
        const brands = await fetchAPI('GET', 'brands');
        const { list, stats } = await fetchAPI(
            'GET',
            'product_crosses',
            {
                page,
                codeQuery: code,
                brandId,
                xCodeQuery: xCode,
                xBrandId,
                dir,
                pageSize: 15
            },
            null,
            { handleErrorInternally: true }
        );

        this.setState({ productCrosses: list, crossesStats: stats[0] });
    };

    deleteCrosses = async id => {
        await fetchAPI('DELETE', 'product_crosses', null, { ids: [id] });
        this.fetchProductCrosses();
    };

    componentDidMount() {
        this.fetchProductCrosses();
    }

    render() {
        const {
            intl: { formatMessage },
            modal,
            setModal,
            resetModal,
            user
        } = this.props;
        const { productCrosses, crossesStats, page, helperDrawerOpen, allLinks } = this.state;
        const columns = this.columns();

        const accessAddButton = isGrantAccessed(user, grants.DIRECTORIES_CROSSES, accesses.ROWO);

        return (
            <Layout
                controls={
                    <div className={Styles.controlsWrapper}>
                        <Button disabled={!accessAddButton} onClick={() => setModal(MODALS.CROSSES_ADD_MODAL)}>
                            <FormattedMessage id='add' />
                        </Button>
                        <div
                            style={{
                                marginLeft: 8
                            }}
                        >
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    this.setState({
                                        helperDrawerOpen: true
                                    });
                                    await this.fetchHelperLinks();
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </div>
                    </div>
                }
                title={<FormattedMessage id='navigation.crosses' />}
            >
                {/* <div className={Styles.filtersCont}>
                    <div className={Styles.inputCont}>
                        <Input.Search 
                            allowClear
                            placeholder={formatMessage({id: 'vehicles_page.search' })}
                            onChange={ async ({target: {value}}) => {
                                await this.setState({
                                    query: value,
                                });
                                this.fetchLaborsStandart();
                            }}
                        />
                    </div>
                </div> */}
                <Table
                    bordered
                    columns={columns}
                    dataSource={this.filterDataSource(productCrosses)}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={{
                        pageSize: 15,
                        size: 'small',
                        total: Math.ceil(_.get(crossesStats, 'count', 0) / 15) * 15,
                        hideOnSinglePage: true,
                        current: page,
                        onChange: async page => {
                            await this.setState({ page });
                            this.fetchProductCrosses();
                        }
                    }}
                    rowKey='id'
                    size='small'
                />
                <CrossesAddModal
                    resetModal={() => resetModal()}
                    updateTable={this.fetchProductCrosses}
                    visible={modal}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
