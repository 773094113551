exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__header---zz3lS{display:flex;margin-bottom:22px;gap:24px;justify-content:center;align-items:center}.styles-m__header---zz3lS>.ant-select{max-width:300px}.styles-m__rectangle---uhCEK{cursor:pointer;position:absolute;border:4px dashed;border-color:var(--db_reserve)}.styles-m__rectangle---uhCEK:hover{border-color:var(--primary)}.styles-m__imageWrap---W3LqF>div:first-child{width:100%}.styles-m__statisticsWrapper---2ft5n{display:flex;flex-direction:column;gap:8px;padding-bottom:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0BACE,YAAa,CACb,kBAAmB,CACnB,QAAS,CACT,sBAAuB,CACvB,kBAKF,CAHE,sCACE,eACF,CAGF,6BACE,cAAe,CACf,iBAAkB,CAGlB,iBAA+B,CAA/B,8BAKF,CAHE,mCACE,2BACF,CAIA,6CACE,UACF,CAGF,qCACE,YAAa,CACb,qBAAsB,CACtB,OAAQ,CACR,kBACF","file":"styles.m.css","sourcesContent":[".header {\r\n  display: flex;\r\n  margin-bottom: 22px;\r\n  gap: 24px;\r\n  justify-content: center;\r\n  align-items: center;\r\n\r\n  &>:global(.ant-select) {\r\n    max-width: 300px;\r\n  }\r\n}\r\n\r\n.rectangle {\r\n  cursor: pointer;\r\n  position: absolute;\r\n\r\n  border: 4px dashed;\r\n  border-color: var(--db_reserve);\r\n\r\n  &:hover {\r\n    border-color: var(--primary);\r\n  }\r\n}\r\n\r\n.imageWrap {\r\n  &>div:first-child {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n.statisticsWrapper {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 8px;\r\n  padding-bottom: 8px;\r\n}"]}]);

// Exports
exports.locals = {
	"header": "styles-m__header---zz3lS",
	"rectangle": "styles-m__rectangle---uhCEK",
	"imageWrap": "styles-m__imageWrap---W3LqF",
	"statisticsWrapper": "styles-m__statisticsWrapper---2ft5n"
};