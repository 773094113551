import { Modal, Select } from 'antd';
import React from 'react';

const RecalculatePriceModal = ({ open, onCancel }) => {
    return (
        <Modal maskClosable={false} onCancel={onCancel} open={open} title='recalculate with new rate'>
            <Select options={[{ label: 'eur', value: 60 }]} />
        </Modal>
    );
};

export default RecalculatePriceModal;
