import { Table } from 'antd';
import { Catcher } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class ClientSearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: []
        };

        this.columns = [
            {
                title: <FormattedMessage id='name' />,
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: <FormattedMessage id='surname' />,
                dataIndex: 'surname',
                key: 'surname'
            },
            {
                title: <FormattedMessage id='add_order_form.phone' />,
                dataIndex: 'phones',
                key: 'phones',
                render: phones => phones.join(', ')
            },
            {
                title: <FormattedMessage id='vehicle' />,
                key: 'vehicle',
                render: row => {
                    return row.model && `${row.make} ${row.model} (${row.year})`;
                }
            },
            {
                title: <FormattedMessage id='add_order_form.vin' />,
                key: 'vin',
                render: row => {
                    return row.number ? `${row.number} ${row.vin}` : row.vin;
                }
            }
        ];

        this.handleClientsSearch = _.debounce(query => {
            this.fetchClientsList(query);
        }, 1000);
    }

    duplicate(clients) {
        return _.flatten(
            _.map(clients, client => {
                const { vehicles } = client;
                const hasVehicles = _.isArray(vehicles) && vehicles.length;
                if (!hasVehicles) {
                    return client;
                }

                return vehicles.map((vehicle, index) => {
                    const duplicatedVehicles = _.cloneDeep(vehicles);
                    duplicatedVehicles.splice(index, 1);

                    return {
                        ...client,
                        vehicles: [vehicles[index], ...duplicatedVehicles]
                    };
                });
            })
        );
    }

    fetchClientsList(query) {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/clients/simple/search?query=${query}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.setState({
                    clients: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    componentDidUpdate(prevProps) {
        const { searchQuery } = this.props;
        if (prevProps.searchQuery != searchQuery && searchQuery.length > 2) {
            this.handleClientsSearch(searchQuery);
        }
    }

    render() {
        const { visible, onSelect, loading } = this.props;
        const { clients } = this.state;
        const { columns } = this;

        return (
            <Catcher>
                <Table
                    bordered
                    className={
                        visible ? Styles.clientsSearchTable : Styles.clientsSearchTableHidden
                    }
                    columns={columns}
                    dataSource={clients}
                    loading={loading}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    onRow={client => {
                        return {
                            onClick: () => {
                                onSelect(client);
                            }
                        };
                    }}
                    pagination={false}
                    // scroll={ { y: 200 } }
                />
            </Catcher>
        );
    }
}
