import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    LockOutlined,
    PhoneOutlined,
    PieChartFilled,
    PlusSquareOutlined,
    QuestionCircleOutlined,
    QuestionOutlined,
    SafetyOutlined,
    StopOutlined,
    WechatOutlined
} from '@ant-design/icons';

import { Tooltip } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class OrderStatusIcon extends Component {
    /* eslint-disable complexity */
    getStatusIcon = status => {
        switch (status) {
            case 'not_complete':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.new' />}
                    >
                        <PlusSquareOutlined className={`${Styles.icon} ${Styles.notComplete}`} />
                    </Tooltip>
                );
            case 'call':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.a_call' />}
                    >
                        <PhoneOutlined className={`${Styles.icon} ${Styles.call}`} />
                    </Tooltip>
                );
            case 'required':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.questionable' />}
                    >
                        <QuestionCircleOutlined className={`${Styles.icon} ${Styles.required}`} />
                    </Tooltip>
                );

            case 'progress':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.repair' />}
                    >
                        <PieChartFilled className={`${Styles.icon} ${Styles.progress}`} />
                    </Tooltip>
                );
            case 'TO_DO':
            case 'approve':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.record' />}
                    >
                        <SafetyOutlined className={`${Styles.icon} ${Styles.approve}`} />
                    </Tooltip>
                );
            case 'reserve':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.reserve' />}
                    >
                        <LockOutlined className={`${Styles.icon} ${Styles.reserve}`} />
                    </Tooltip>
                );
            case 'COMPLETED':
            case 'success':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.done' />}
                    >
                        <CheckOutlined className={`${Styles.icon} ${Styles.success}`} />
                    </Tooltip>
                );
            case 'invite':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.invitation' />}
                    >
                        <WechatOutlined className={`${Styles.icon} ${Styles.invite}`} />
                    </Tooltip>
                );
            case 'cancel':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.cancel' />}
                    >
                        <CloseCircleOutlined className={`${Styles.icon} ${Styles.cancel}`} />
                    </Tooltip>
                );
            case 'stop':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_statuses_mapper.stop' />}
                    >
                        <StopOutlined className={`${Styles.icon} ${Styles.stop}`} />
                    </Tooltip>
                );
            case 'processing':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_statuses_mapper.processing' />}
                    >
                        <ClockCircleOutlined className={`${Styles.icon} ${Styles.processing}`} />
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='order_status_icon.order' />}
                    >
                        <QuestionOutlined />
                    </Tooltip>
                );
        }
    };

    render() {
        return this.getStatusIcon(this.props.status);
    }
}
