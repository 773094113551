import { Table } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

export class ReportLoadKPITable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { filter, onPageChange, tableData, stats, isFetching } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / 25) * 25,
            hideOnSinglePage: true,
            current: filter.page,
            onChange: onPageChange
        };

        return (
            <Table
                bordered
                className={Styles.table}
                columns={columnsConfig()}
                dataSource={tableData}
                loading={isFetching}
                locale={{
                    emptyText: <FormattedMessage id='no_data' />
                }}
                pagination={pagination}
                rowKey={() => v4()}
                scroll={{ x: 1800, y: '50vh' }}
                size='middle'
            />
        );
    }
}
