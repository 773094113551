import ClientVehicleTransfer from 'modals/ClientVehicleTransfer';
import EmployeeSubTasksModal from 'modals/EmployeeSubTasksModal';
import EmployeeTaskModal from 'modals/EmployeeTaskModal';
import OrderStatsInfoModal from 'modals/OrderStatsInfoModal';
import SelectOrderModal from 'modals/SelectOrderModal';
import StockProductsModal from 'modals/StockProductsModal';
import StoreDocServiceModal from 'modals/StoreDocServiceModal';
import AccountsReceivableReportModal from './AccountsReceivablesReportModal';
import AddAggregateModal from './AddAggregateModal';
import AddClientModal from './AddClientModal';
import AddCrossLocationModal from './AddCrossLocationModal';
import AddLaborOrDetailToOrderModal from './AddLaborOrDetailToOrderModal';
import AddOrderFromDocumentModal from './AddOrderFromDocumentModal';
import AddOwnCrossModal from './AddOwnCrossModal';
import AddProductToStoreDocModal from './AddProductToStoreDocModal';
import AddQuickRequestsModal from './AddQuickRequestsModal';
import AddRecommendationsModal from './AddRecommendationsModal';
import AddRegulationsModal from './AddRegulationsModal';
import AddRegulationsOrderModal from './AddRegulationsOrderModal';
import AddRowsReturnSurplusesModal from './AddRowsReturnSurplusesModal';
import AddRuleModal from './AddRuleModal';
import AddServiceModal, { CommentaryButton } from './AddServiceModal';
import AddSourceModal from './AddSourceModal';
import AddVehicleRegulationsModal from './AddVehicleRegulationsModal';
import AggregateTransferModal from './AggregateTransferModal';
import BrandsCrossModal from './BrandsCrossModal';
import BrandsModal from './BrandsModal';
import CallsReportModal from './CallsReportModal';
import CancelReasonModal from './CancelReasonModal';
import CashBoxReportModal from './CashBoxReportModal';
import CashOrderModal from './CashOrderModal';
import AddCashboxModal from './Cashbox/AddCashboxModal';
import ChangeVehicleMileageModal from './ChangeVehicleMileageModal';
import ConfirmModal from './CommonModals/ConfirmModal';
import ComplexesModal from './ComplexesModal';
import ConfirmDiagnosticModal from './ConfirmDiagnosticModal';
import ConfirmOrderExitModal from './ConfirmOrderExitModal';
import ConfirmRescheduleModal from './ConfirmRescheduleModal';
import ConsolidateOrderReportModal from './ConsolidateOrderReportModal';
import CreateAccTemplateModal from './CreateAccTemplateModal';
import CreateIncomeServiceModal from './CreateIncomeServiceModal';
import CrossesAddModal from './CrossesAddModal';
import DashboardModal from './DashboardModal';
import DebtPairResultsModal from './DebtPairResultsModal';
import DebtZeroingModal from './DebtZeroingModal';
import DetailProductModal from './DetailProductModal';
import DetailStorageModal, { DetailWarehousesCountModal } from './DetailStorageModal';
import DetailSupplierModal from './DetailSupplierModal';
import EditPhotoFileModal from './EditPhotoFileModal';
import EntriesDirectoryAddModal from './EntriesDirectoryAddModal';
import FavouriteDetailsModal from './FavouriteDetailsModal';
import FavouriteServicesModal from './FavouriteServicesModal';
import ImportReceiptDocumentModal from './ImportReceiptDocumentModal';
import InviteModal from './InviteModal';
import LaborsNormHourModal from './LaborsNormHourModal';
import LocationHistoryModal from './LocationHistoryModal';
import MyNormativesAddModal from './MyNormativesAddModal';
import NewBarcodePrintModal from './NewBarcodePrintModal';
import OilModal from './OilModal';
import OrderClientChangeModal from './OrderClientChangeModal';
import { DetailCatalogueModal, OrderDetailModal } from './OrderDetailModal';
import OrderRequestModal, { RequestCommentaryButton } from './OrderRequestModal';
import OrderTaskModal from './OrderTaskModal';
import OwnVehicleModal from './OwnVehicleModal';
import PDFViewerModal from './PDFViewerModal';
import PhotoModal from './PhotoModal';
import PreviewMediaFilesModal from './PreviewMediaFilesModal';
import PriceRecalculationModal from './PriceRecalculationModal';
import ProductsMovementModal from './ProductsMovementModal';
import RecommendationsToServicesModal from './RecommendationsToServicesModal';
import RefactoredCashOrderModal from './RefactoredCashOrderModal';
import ReferenceBookAddModal from './ReferenceBookAddModal';
import RegisterForServiceModal from './RegisterForServiceModal';
import RemainsReportModal from './RemainsReportModal';
import ReportAnalyticsModal from './ReportModals/ReportAnalyticsModal';
import ReportOrdersExportModal from './ReportModals/ReportOrdersExportModal';
import ReportOrdersFilterModal from './ReportModals/ReportOrdersFilterModal';
import SMSReportModal from './SMSReportModal';
import SalesReportModal from './SalesReportModal';
import SearchDetailModal from './SearchDetailModal';
import SetBarcodeModal from './SetBarcodeModal';
import StoreGroupModal from './StoreGroupModal';
import StoreProductModal from './StoreProductModal';
import StoreProductTrackingModal from './StoreProductTrackingModal';
import SubscribeModal from './SubscribeModal';
import SupplierModal from './SupplierModal';
import SuppliersReportModal from './SuppliersReportModal';
import SwitchBusinessModal from './SwitchBusinessModal';
import SyncConflictsModal from './SyncConflictsModal';
import SyncImportExportModal from './SyncImportExportModal';
import TecDocInfoModal from './TecDocInfoModal';
import ToSuccessModal from './ToSuccessModal';
import UniversalCashReportModal from './UniversalCashReportModal';
import UniversalChartModal from './UniversalChartModal';
import UniversalFiltersModal from './UniversalFiltersModal';
import UniversalOrderReportModal from './UniversalOrderReportModal';
import UniversalProductReportModal from './UniversalProductReportModal';
import UnpairAllActionsBtnsModal from './UnpairAllActionsBtnsModal';
import VehicleLocationModal from './VehicleLocationModal';
import VehicleModal from './VehicleModal';
import VinCodeModal from './VinCodeModal';
import VinDiagnosticModal from './VinDiagnosticModal';
import VisualAdditionModal from './VisualAdditionModal/index';
import WMSCellsModal from './WMSCellsModal';

export {
    AccountsReceivableReportModal, AddAggregateModal,
    AddCashboxModal,
    AddClientModal,
    AddCrossLocationModal,
    AddLaborOrDetailToOrderModal,
    AddOrderFromDocumentModal,
    AddOwnCrossModal,
    AddProductToStoreDocModal,
    AddQuickRequestsModal,
    AddRecommendationsModal,
    AddRegulationsModal,
    AddRegulationsOrderModal,
    AddRowsReturnSurplusesModal,
    AddRuleModal,
    AddServiceModal,
    AddSourceModal,
    AddVehicleRegulationsModal,
    AggregateTransferModal,
    BrandsCrossModal,
    BrandsModal,
    CallsReportModal,
    CancelReasonModal,
    CashBoxReportModal,
    CashOrderModal,
    ChangeVehicleMileageModal,
    ClientVehicleTransfer,
    CommentaryButton,
    ComplexesModal,
    ConfirmDiagnosticModal,
    ConfirmModal,
    ConfirmOrderExitModal,
    ConfirmRescheduleModal,
    ConsolidateOrderReportModal, CreateAccTemplateModal, CreateIncomeServiceModal,
    CrossesAddModal,
    DashboardModal,
    DebtPairResultsModal,
    DebtZeroingModal,
    DetailCatalogueModal,
    DetailProductModal,
    DetailStorageModal,
    DetailSupplierModal,
    DetailWarehousesCountModal,
    EditPhotoFileModal,
    EmployeeSubTasksModal,
    EmployeeTaskModal,
    EntriesDirectoryAddModal,
    FavouriteDetailsModal,
    FavouriteServicesModal,
    ImportReceiptDocumentModal,
    InviteModal,
    LaborsNormHourModal,
    LocationHistoryModal,
    MyNormativesAddModal,
    NewBarcodePrintModal,
    OilModal,
    OrderClientChangeModal,
    OrderDetailModal,
    OrderRequestModal,
    OrderStatsInfoModal,
    OrderTaskModal,
    OwnVehicleModal,
    PDFViewerModal,
    PhotoModal,
    PreviewMediaFilesModal,
    PriceRecalculationModal,
    ProductsMovementModal,
    RecommendationsToServicesModal,
    RefactoredCashOrderModal,
    ReferenceBookAddModal,
    RegisterForServiceModal,
    RemainsReportModal,
    ReportAnalyticsModal,
    ReportOrdersExportModal,
    ReportOrdersFilterModal,
    RequestCommentaryButton,
    SMSReportModal,
    SalesReportModal,
    SearchDetailModal,
    SelectOrderModal,
    SetBarcodeModal,
    StockProductsModal,
    StoreDocServiceModal,
    StoreGroupModal,
    StoreProductModal,
    StoreProductTrackingModal,
    SubscribeModal,
    SupplierModal,
    SuppliersReportModal,
    SwitchBusinessModal,
    SyncConflictsModal,
    SyncImportExportModal,
    TecDocInfoModal,
    ToSuccessModal,
    UniversalCashReportModal,
    UniversalChartModal,
    UniversalFiltersModal,
    UniversalOrderReportModal,
    UniversalProductReportModal,
    UnpairAllActionsBtnsModal,
    VehicleLocationModal,
    VehicleModal,
    VinCodeModal,
    VinDiagnosticModal,
    VisualAdditionModal,
    WMSCellsModal
};

