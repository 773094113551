import { Numeral } from 'commons';
import { FormattedDatetime } from 'components';
import _ from 'lodash';
import { DEF_UI_DATETIME } from 'modals/RefactoredCashOrderModal/constants';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Choose width for each col, (100% in total or less than 100% if 'auto' is present)
const defWidth = {
    orderCol: 'auto',
    clientCol: '25%',
    appointmentCol: '15%',
    vehicleCol: '25%',
    sumCol: '10%',
    remainingSumCol: '10%'
};

export function columnsConfig() {
    const orderCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.order' />,
        width: defWidth.orderCol,
        align: 'left',
        dataIndex: 'num',
        key: 'orderCol'
    };
    const appointmentCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.appointment' />,
        width: defWidth.appointmentCol,
        align: 'left',
        dataIndex: 'beginDatetime',
        key: 'appointmentCol',
        render: orderDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={orderDatetime} format={DEF_UI_DATETIME} />
            </React.Fragment>
        )
    };
    const clientCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.client' />,
        width: defWidth.clientCol,
        align: 'left',
        key: 'clientCol',
        render: order => {
            const { clientPhones } = order;

            return (
                <div>
                    <div>{`${order.clientName || ''} ${order.clientSurname || ''}`}</div>
                    {clientPhones &&
                        _.map(clientPhones, (phone, index) => <div key={index}>{phone}</div>)}
                </div>
            );
        }
    };
    const vehicleCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.vehicle' />,
        width: defWidth.vehicleCol,
        align: 'left',
        key: 'vehicleCol',
        render: order => {
            return (
                <div>
                    {`${_.get(order, 'vehicleMakeName', '')} ${_.get(
                        order,
                        'vehicleModelName',
                        ''
                    )} ${_.get(order, 'vehicleYear', '')}`}
                </div>
            );
        }
    };

    const sumCol = {
        title: <FormattedMessage id='sum' />,
        dataIndex: 'totalSum',
        key: 'sumCol',
        width: defWidth.sumCol,
        render: (_, order) => {
            const sum = order.isTaxPayer
                ? order.totalSumWithTax
                : order.servicesTotalSum + order.detailsTotalSum;

            return (
                <Numeral mask='0,0.00' nullText='0'>
                    {sum}
                </Numeral>
            );
        }
    };

    const remainingSumCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.remaining_sum' />,
        dataIndex: 'remainingSum',
        key: 'remainingSumCol',
        width: defWidth.remainingSumCol,
        render: (remainingSum, { tax, isTaxPayer }) => (
            <Numeral mask='0,0.00' nullText='0'>
                {isTaxPayer ? remainingSum + tax || 0 : remainingSum}
            </Numeral>
        )
    };

    return [orderCol, appointmentCol, clientCol, vehicleCol, sumCol, remainingSumCol];
}
