import { emitError } from 'core/ui/duck';
import { selectDateRange } from 'modals/DebtZeroingModal/redux/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchSelectedStoreDocSuccess,
    fetchStoreDocsSuccess,
    FETCH_SELECTED_STORE_DOC,
    FETCH_STORE_DOCS,
    selectSelectedStoreDocId,
    selectStoreDocsFilters,
    setFetchingSelectedStoreDoc,
    setFetchingStoreDocs
} from './duck';

export function* fetchStoreDocsSaga() {
    while (true) {
        try {
            yield take(FETCH_STORE_DOCS);

            yield put(setFetchingStoreDocs(true));
            const filters = yield select(selectStoreDocsFilters);
            const dataRange = yield select(selectDateRange);

            const response = yield call(fetchAPI, 'GET', 'store_docs', {
                ...filters,
                startDate: dataRange.startDate,
                endDate: dataRange.endDate
            });
            const { list: storeDocs, stats: storeDocsStats } = response;

            yield put(fetchStoreDocsSuccess({ storeDocs, storeDocsStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingStoreDocs(false));
        }
    }
}

export function* fetchSelectedStoreDocSaga() {
    while (true) {
        try {
            yield take(FETCH_SELECTED_STORE_DOC);

            yield put(setFetchingSelectedStoreDoc(true));
            const selectedStoreDocId = yield select(selectSelectedStoreDocId);

            const response = yield call(fetchAPI, 'GET', `store_docs/${selectedStoreDocId}`);

            yield put(fetchSelectedStoreDocSuccess({ selectedStoreDoc: response }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingSelectedStoreDoc(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchStoreDocsSaga), call(fetchSelectedStoreDocSaga)]);
}
