import { DatePicker, Input, InputNumber, Modal, Select, notification } from 'antd';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const operationTypes = {
    DIRECT: 'entries_tab.DIRECT',
    STORNO: 'entries_tab.STORNO'
};

const mapStateToProps = state => ({
    user: state.auth,
    clients: state.clients.clients,
    businessRequisites: selectBusinessRequisites(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchClients,
    setClientsSearchFilter,
    fetchBusinessRequisites
};

const AddAccDocsModal = ({
    open = false,
    hideModal,
    intl: { formatMessage },
    fetchAccDocs,
    user,
    businessRequisites,
    fetchBusinessRequisites,
    onlyTemplates,
    documentId,
    isMobile,
    docData,
    ...props
}) => {
    const [documentNumber, setDocumentNumber] = useState();
    const [businessRequisiteId, setBusinessRequisiteId] = useState();
    const [counterpartType, setCounterpartyType] = useState();
    const [counterpartId, setCounterpartId] = useState();
    const [warehouseId, setWarehouseId] = useState();
    const [cashBoxId, setCashboxId] = useState();
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState('DIRECT');
    const [documentDatetime, setDocumentDatetime] = useState(dayjs());
    const [cashboxes, setCashboxes] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [templateId, setTemplateId] = useState();
    const [templateData, setTemplateData] = useState();

    const fetchCashboxes = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'cash_boxes',
            {
                query,
                disabled: isGrantAccessed(user, grants.SETTINGS_CASH_REGISTERS_HIDDEN_CASH_BOX, accesses.ROWO)
                    ? true
                    : undefined
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setCashboxes(response);
    }, []);

    const fetchWarehouses = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'warehouses',
            {
                query,
                disabled: isGrantAccessed(user, grants.SETTINGS_STORES_HIDDEN_WAREHOUSE, accesses.ROWO)
                    ? true
                    : undefined
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setWarehouses(response);
    }, []);

    const fetchSuppliers = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                all: true,
                cut: true,
                query
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setSuppliers(response);
    }, []);

    const fetchEmployees = useCallback(async query => {
        const response = await fetchAPI('GET', '/employees', { query, disabled: false }, null, {
            handleErrorInternally: true
        });

        setEmployees(response);
    }, []);

    const fetchTemplates = useCallback(async () => {
        const response = await fetchAPI('GET', '/general_ledger/acc_document_templates', null, null, {
            handleErrorInternally: true
        });

        setTemplates(response);
    }, []);

    const onClose = () => {
        setDocumentNumber(undefined);
        setCounterpartId(undefined);
        setCounterpartyType(undefined);
        setCashboxId(undefined);
        setWarehouseId(undefined);
        setComment(undefined);
        setTemplateData(undefined);
        setTemplateId(undefined);

        hideModal();
        if (fetchAccDocs) {
            setTimeout(() => fetchAccDocs(), 100);
        }
    };

    const addDocumentRows = async () => {
        const data = templateData.rows.map(({ accountName, operationId, ...rest }) => rest);

        try {
            await fetchAPI(
                'PUT',
                '/general_ledger/acc_document',
                null,
                {
                    id: documentId,
                    status: docData.status,
                    documentNumber: docData.documentNumber || undefined,
                    documentDatetime: docData.documentDatetime || null,
                    counterpartId: docData.counterpartId || undefined,
                    counterpartType: docData.counterpartType || undefined,
                    warehouseId: docData.warehouseId || undefined,
                    cashBoxId: docData.cashBoxId || undefined,
                    comment: docData.comment || undefined,
                    operationType: docData.operationType,
                    rows: data
                },
                {
                    handleErrorInternally: true
                }
            );

            notification.success({ message: formatMessage({ id: 'barcode.success' }) });
        } catch (e) {
            notification.error({ message: formatMessage({ id: 'error' }) });
        }

        await onClose();

        await fetchAccDocs();
    };

    const addAccDocFromTemplate = async () => {
        const fieldsToOmit = [
            'key',
            'value',
            'children',
            'id',
            'businessId',
            'name',
            'amount',
            'managerId',
            'managerName',
            'accountName',
            'counterpartName',
            'businessRequisiteName',
            'cashBoxName',
            'warehouseName',
            'warehouseAccountId',
            'counterpartAccountId',
            'cashBoxAccountId'
        ];

        const fieldsToOmitRows = ['accountName', 'operationId', 'counterpartName'];

        const result = _.omit(templateData, fieldsToOmit);

        if (Array.isArray(templateData.rows)) {
            result.rows = templateData.rows.map(row => _.omit(row, fieldsToOmitRows));
        }

        const response = await fetchAPI(
            'POST',
            '/general_ledger/acc_document_rows',
            undefined,
            {
                ...result
            },
            { handleErrorInternally: true }
        );
        await notification.success({
            message: formatMessage({ id: 'barcode.success' })
        });
        if (response.success && isGrantAccessed(user, grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST)) {
            goTo(`${book.accDocs}/${response.success}`);
        }
    };

    const addAccDoc = async () => {
        const response = await fetchAPI(
            'POST',
            '/general_ledger/acc_document',
            undefined,
            {
                documentNumber,
                documentDatetime: documentDatetime ? dayjs(documentDatetime).toISOString() : undefined,
                counterpartId,
                counterpartType: counterpartType || 'DIRECT',
                warehouseId,
                cashBoxId,
                comment,
                operationType,
                businessRequisiteId: businessRequisiteId || undefined
            },
            { handleErrorInternally: true }
        );
        await notification.success({
            message: formatMessage({ id: 'barcode.success' })
        });
        if (response.success && isGrantAccessed(user, grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST)) {
            goTo(`${book.accDocs}/${response.success}`);
        }
    };

    useEffect(() => {
        if ((onlyTemplates && open) || (documentId && open)) {
            fetchTemplates();
        }
        if (!onlyTemplates && !documentId && open) {
            if (!get(cashboxes, 'length')) {
                fetchCashboxes();
            }

            if (!get(warehouses, 'length')) {
                fetchWarehouses();
            }

            if (!get(businessRequisites, 'length')) {
                fetchBusinessRequisites();
            }
        }
    }, [
        fetchBusinessRequisites,
        fetchCashboxes,
        fetchTemplates,
        fetchWarehouses,
        onlyTemplates,
        documentId,
        open,
        cashboxes,
        warehouses,
        businessRequisites
    ]);

    const onFinish = async () => {
        if (onlyTemplates) {
            addAccDocFromTemplate();
        }
        if (documentId) {
            addDocumentRows();
        }

        if (!onlyTemplates && !documentId) {
            addAccDoc();
        }

        await onClose();
    };

    const handleSearchSuppliers = useMemo(() => {
        const loadOptions = value => {
            fetchSuppliers(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [fetchSuppliers]);

    const handleSearchEmployees = useMemo(() => {
        const loadOptions = value => {
            fetchEmployees(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [fetchEmployees]);

    const handleSearchClients = useMemo(() => {
        const loadOptions = value => {
            props.setClientsSearchFilter(value);
            props.fetchClients();
        };

        return _.debounce(loadOptions, 1000);
    }, [props]);

    const disabled =
        onlyTemplates || documentId ? !templateData : !documentNumber || !documentDatetime || !operationType;

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{
                disabled
            }}
            onCancel={hideModal}
            onOk={() => onFinish()}
            open={open}
            title={
                onlyTemplates || documentId ? (
                    <FormattedMessage id='acc_page.create_with_template' />
                ) : (
                    <FormattedMessage id='acc_page.add_acc_doc' />
                )
            }
            width={isMobile ? '95%' : '45% '}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                {onlyTemplates || documentId ? (
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='receipt_document_modal.template' />
                        </div>
                        <Select
                            onChange={(value, option) => {
                                setTemplateId(value);
                                setTemplateData(option);
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'receipt_document_modal.template'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={templateId}
                        >
                            {documentId
                                ? templates
                                      .filter(({ operationType }) => operationType === docData.operationType)
                                      .map(elem => (
                                          <Option key={elem.id} {...elem}>
                                              {elem.name}
                                          </Option>
                                      ))
                                : templates.map(elem => (
                                      <Option key={elem.id} {...elem}>
                                          {elem.name}
                                      </Option>
                                  ))}
                        </Select>
                    </div>
                ) : undefined}

                {!onlyTemplates && !documentId && (
                    <React.Fragment>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='entry_table.doc_number' />
                                <span className={Styles.rules}>*</span>
                            </div>

                            <InputNumber
                                min={0}
                                onChange={async value => {
                                    await setDocumentNumber(value);
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'entry_table.doc_number'
                                })}
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={documentNumber}
                            />
                        </div>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='order_from_table_date_doc' />
                                <span className={Styles.rules}>*</span>
                            </div>

                            <DatePicker
                                format='YYYY-MM-DD'
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={async value => {
                                    await setDocumentDatetime(value);
                                }}
                                optionFilterProp='children'
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={documentDatetime}
                            />
                        </div>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='interactions.counterparty_type' />
                            </div>
                            <Select
                                onChange={value => {
                                    setCounterpartyType(value);
                                    setCounterpartId(undefined);
                                    if (value === 'SUPPLIERS' && !_.get(suppliers, 'length')) {
                                        fetchSuppliers();
                                    }
                                    if (value === 'EMPLOYEES' && !_.get(employees, 'length')) {
                                        fetchEmployees();
                                    }
                                    if (value === 'CLIENTS' && !_.get(props.clients, 'length')) {
                                        props.fetchClients();
                                    }
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'interactions.counterparty_type'
                                })}
                                showSearch
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={counterpartType}
                            >
                                <Option key='SUPPLIERS' value='SUPPLIERS'>
                                    <FormattedMessage id='entries_tab.SUPPLIER' />
                                </Option>
                                <Option key='EMPLOYEES' value='EMPLOYEES'>
                                    <FormattedMessage id='entries_tab.EMPLOYEE' />
                                </Option>
                                <Option key='CLIENTS' value='CLIENTS'>
                                    <FormattedMessage id='entries_tab.CLIENT' />
                                </Option>
                            </Select>
                        </div>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='entries_tab.CONTRAGENT' />
                            </div>
                            <Select
                                disabled={!counterpartType}
                                onChange={value => {
                                    setCounterpartId(value);
                                }}
                                onSearch={input => {
                                    if (counterpartType === 'SUPPLIERS') {
                                        handleSearchSuppliers(input);
                                    }
                                    if (counterpartType === 'EMPLOYEES') {
                                        handleSearchEmployees(input);
                                    }
                                    if (counterpartType === 'CLIENTS') {
                                        handleSearchClients(input);
                                    }
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'entries_tab.CONTRAGENT'
                                })}
                                showSearch
                                style={{ width: '100%' }}
                                value={counterpartId}
                            >
                                {counterpartType === 'SUPPLIERS'
                                    ? suppliers.map(supplier => (
                                          <Option key={supplier.id} value={supplier.id}>
                                              {`${supplier.name}`}
                                          </Option>
                                      ))
                                    : counterpartType === 'EMPLOYEES'
                                    ? employees.map(employee => (
                                          <Option key={employee.id} value={employee.id}>
                                              {`${employee.name} ${employee.surname}`}
                                          </Option>
                                      ))
                                    : counterpartType === 'CLIENTS'
                                    ? props.clients.map(client => (
                                          <Option
                                              key={client.clientId}
                                              phone={_.get(client, 'phones[0]')}
                                              value={client.clientId}
                                              vehicles={_.get(client, 'vehicles')}
                                          >
                                              {`${client.surname || ''} ${client.name} ${
                                                  client.middleName || ''
                                              } ${_.get(client, 'phones[0]', '')}`}
                                          </Option>
                                      ))
                                    : []}
                            </Select>
                        </div>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='entry_table.operation_type' />
                                <span className={Styles.rules}>*</span>
                            </div>
                            <Select
                                onChange={value => {
                                    setOperationType(value);
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'interactions.counterparty_type'
                                })}
                                showSearch
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={operationType}
                            >
                                {Object.entries(operationTypes).map(([key, value]) => (
                                    <Option key={key} value={key}>
                                        <FormattedMessage id={value} />
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='navigation.storage' />
                            </div>
                            <Select
                                onChange={value => {
                                    setWarehouseId(value);
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'navigation.storage'
                                })}
                                showSearch
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={warehouseId}
                            >
                                {warehouses.map(elem => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='supplier.cashbox_id' />
                            </div>
                            <Select
                                onChange={value => {
                                    setCashboxId(value);
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'supplier.cashbox_id'
                                })}
                                showSearch
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={cashBoxId}
                            >
                                {cashboxes.map(({ name, id }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='storage.business_requisites' />
                            </div>
                            <Select
                                allowClear
                                onChange={value => {
                                    setBusinessRequisiteId(value);
                                }}
                                optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'storage.business_requisites'
                                })}
                                showSearch
                                style={{ color: 'var(--text)', width: '100%' }}
                                value={businessRequisiteId}
                            >
                                {businessRequisites
                                    .filter(({ used }) => used)
                                    .map(elem => (
                                        <Select.Option key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </div>
                        <div className={Styles.divGroup}>
                            <div>
                                <FormattedMessage id='comment' />
                            </div>
                            <Input.TextArea
                                autoFocus
                                onChange={event => {
                                    setComment(event.target.value);
                                }}
                                style={{ width: '100%', minHeight: '150px' }}
                                value={comment}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddAccDocsModal));
