import {
    AgreementPage,
    ForgotPasswordPage,
    LoginPage,
    NewPasswordPage,
    OnlineServiceBookPage,
    UserFeedbackPage
} from 'pages';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import book from './book';

/**
 * All those components will be available by users without authentification
 */
export default () => {
    return (
        <Switch>
            <Route exact path={book.login} render={props => <LoginPage {...props} />} />
            {/* <Route
                exact
                path={book.login}
                render={() => {
                    window.location.href = __TS_APP_URL__;

                    return null;
                }}
            /> */}
            <Route exact path={book.forgotPassword} render={props => <ForgotPasswordPage {...props} />} />
            <Route exact path={book.newPassword} render={props => <NewPasswordPage {...props} />} />
            <Route exact path={book.userFeedback} render={props => <UserFeedbackPage {...props} />} />
            <Route exact path={book.agreement} render={props => <AgreementPage {...props} />} />
            <Route exact path={book.onlineServiceBookWithHash} render={props => <OnlineServiceBookPage {...props} />} />
            <Route exact path='/' render={() => <Redirect to={book.login} />} />
            <Route exact path='*' render={() => <Redirect to={book.login} />} />
        </Switch>
    );
};
