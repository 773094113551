import { CheckCircleFilled, CloseSquareFilled, WarningFilled } from '@ant-design/icons';
import { Button, Radio, Select, Space, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import DebouncedInput from 'pages/AccountPlanPage/components/DebouncedInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, filterByPartGeneral } from 'utils';
import AditionalPackagesModal from '../AditionalPackagesModal';
import ChangeMemoryFileModal from '../ChangeMemoryFileModal';
import AccessSettingModal from '../DefaultRolesTab/AccessSettingModal';
import Styles from './styles.m.css';

const fontOptions = {
    1: 'bold',
    2: 'bold',
    4: 'italic'
};

const allRolesAcees = [
    {
        name: <FormattedMessage id='packages.role.NA' />,
        value: 'NA',
        color: '#eb8c86',
        colorValue: 'red'
    },
    {
        name: <FormattedMessage id='packages.role.AD' />,
        value: 'AD',
        color: '#eb8c86',
        colorValue: 'red'
    },
    {
        name: <FormattedMessage id='packages.role.RO' />,
        value: 'RO',
        color: '#f0a871',
        colorValue: 'orange'
    },
    {
        name: <FormattedMessage id='packages.role.RA' />,
        value: 'RA',
        color: '#f0a871',
        colorValue: 'orange'
    },
    {
        name: <FormattedMessage id='packages.role.ROWO' />,
        value: 'ROWO',
        color: '#83c468',
        colorValue: 'green'
    },
    {
        name: <FormattedMessage id='packages.role.RAWO' />,
        value: 'RAWO',
        color: '#83c468',
        colorValue: 'green'
    },
    {
        name: <FormattedMessage id='packages.role.WA' />,
        value: 'WA',
        color: '#83c468',
        colorValue: 'green'
    }
];

const radioOptions = Array.from(Array(4)).map((_, index) => index + 1);

const { Option } = Select;

const DefaultPackagesTab = ({ intl, bytesToMB, packagesMB, fetchFileSMB, setDefaultPackageMB }) => {
    const [query, setQuery] = useState({
        dateFrom: dayjs().startOf('month').toISOString(),
        dateTo: dayjs().toISOString()
    });
    const [defaultRights, setDefaultRights] = useState([]);
    const [additionalRow, setAdditionalRow] = useState([
        {
            name: <FormattedMessage id='packages.memory' />
        }
    ]);
    const [flattenRights, setFlattenRights] = useState([]);
    const [tablePagination, setTablePagination] = useState({ pageSize: 2 });
    const [foundIds, setFoundIds] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [visibleLevel, setVisibleLevel] = useState(3);
    const [filter, setFilter] = useState('');
    const [visibleModal, setVisibleModal] = useState(false);
    const [additionalPackages, setAdditionalPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [edit, setEdit] = useState({});
    const [editMB, setEditMB] = useState({});
    const [open, setModalOpen] = useState(false);
    const [openMB, setModalOpenMB] = useState(false);

    const onFlatten = rights => {
        const flattenTree = [];
        const flattenStoreGroups = data => {
            for (let item = 0; item < get(data, 'length'); item++) {
                const node = data[item];

                flattenTree.push({
                    id: node.id,
                    name: node.name,
                    level: node.level,
                    parentId: node.parentId
                });
                if (node.childs) {
                    flattenStoreGroups(node.childs);
                }
            }
        };
        flattenStoreGroups(rights);

        setFlattenRights(flattenTree);
        setExpandedRowKeys(flattenTree.map(({ id }) => id));

        return flattenTree;
    };

    const fetchRights = useMemo(
        () => async query => {
            const res = await fetchAPI('GET', '/v2/roles_and_accesses/standard_packages', query, null, {
                handleErrorInternally: true
            });

            setDefaultRights(res.list);

            onFlatten(res.list);
        },
        []
    );

    const handleTablePackage = useMemo(
        () => async (packageId, access, treeIds) => {
            await fetchAPI(
                'PUT',
                '/v2/roles_and_accesses/standard_packages/access',
                null,
                {
                    packageId,
                    access,
                    treeIds
                },
                {
                    handleErrorInternally: true
                }
            );
            await fetchRights();
        },
        []
    );

    const fetchAdditionPackages = useMemo(
        () => async () => {
            const res = await fetchAPI(
                'GET',
                '/v2/roles_and_accesses/standard_packages/simple_list',
                { isAdditional: true },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setAdditionalPackages(res);
        },
        []
    );

    const findIcon = useCallback(isInclude => {
        const icon =
            get(isInclude, 'access', 'NA') == 'AD' || get(isInclude, 'access', 'NA') == 'NA' ? (
                <CloseSquareFilled className={Styles.optionsDangerIconStyle} />
            ) : get(isInclude, 'access', 'NA') == 'RO' || get(isInclude, 'access', 'NA') == 'RA' ? (
                <WarningFilled className={Styles.optionsWarningIconStyle} />
            ) : get(isInclude, 'access', 'NA') == 'ROWO' ||
              get(isInclude, 'access', 'NA') == 'RAWO' ||
              get(isInclude, 'access', 'NA') == 'WA' ? (
                <CheckCircleFilled className={Styles.optionsSuccessIconStyle} />
            ) : undefined;

        return icon;
    }, []);

    const columns = useMemo(
        () => [
            {
                title: <FormattedMessage id='packages.id_access' />,
                key: 'id',
                render: record => {
                    const slicedId =
                        record.id && record.level == 1
                            ? String(record.id).slice(0, 2)
                            : record.id && record.level == 2
                            ? String(record.id).slice(0, 4)
                            : record.id && record.level == 3
                            ? String(record.id).slice(0, 6)
                            : String(record.id).slice(0, 8);

                    return record.id ? slicedId : record.id;
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '15%',
                render: record => {
                    const space =
                        record.level === 2 ? '8px' : record.level === 3 ? '24px' : record.level === 4 ? '38px' : 0;

                    return record.name ? (
                        <div
                            style={{
                                marginLeft: space
                            }}
                        >
                            {record.name}
                        </div>
                    ) : (
                        <FormattedMessage id='Файловий простір(МБ)' />
                    );
                }
            },
            {
                title: () => {
                    return <div>FREE</div>;
                },
                key: 'START',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.packages && row.packages.find(({ name }) => name === 'Free');

                    const findPackageMb = packagesMB.find(
                        ({ packageId }) => packageId == get(isInclude, 'packageId', 1)
                    );

                    const icon = findIcon(isInclude);

                    const bitesToMb = bytesToMB(get(findPackageMb, 'numOfBytes', null));

                    return row.id ? (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    'NA'
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        packageId: get(isInclude, 'packageId', 1),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            onClick={() => {
                                setModalOpenMB(true);
                                setEditMB({
                                    packageId: get(findPackageMb, 'packageId', 1),
                                    numOfBytes: bitesToMb
                                });
                            }}
                        >
                            {findPackageMb ? bitesToMb : 0}
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return <div>START</div>;
                },
                key: 'START',
                width: '125px',
                align: 'center',
                render: row => {
                    const isInclude = row.packages && row.packages.find(({ name }) => name === 'Start');
                    const findPackageMb = packagesMB.find(
                        ({ packageId }) => packageId == get(isInclude, 'packageId', 2)
                    );

                    const icon = findIcon(isInclude);
                    const bitesToMb = bytesToMB(get(findPackageMb, 'numOfBytes', null));

                    return row.id ? (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    'NA'
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        packageId: get(isInclude, 'packageId', 2),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            onClick={() => {
                                setModalOpenMB(true);
                                setEditMB({
                                    packageId: get(findPackageMb, 'packageId', 2),
                                    numOfBytes: bitesToMb
                                });
                            }}
                        >
                            {findPackageMb ? bitesToMb : 0}
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return <div>SPECIALIST</div>;
                },
                key: 'SPECIALIST',
                width: '120px',
                align: 'center',
                render: row => {
                    const isInclude = row.packages && row.packages.find(({ name }) => name === 'Specialist');
                    const findPackageMb = packagesMB.find(
                        ({ packageId }) => packageId == get(isInclude, 'packageId', 3)
                    );

                    const icon = findIcon(isInclude);
                    const bitesToMb = bytesToMB(get(findPackageMb, 'numOfBytes', null));

                    return row.id ? (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    'NA'
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        packageId: get(isInclude, 'packageId', 3),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            onClick={() => {
                                setModalOpenMB(true);
                                setEditMB({
                                    packageId: get(findPackageMb, 'packageId', 3),
                                    numOfBytes: bitesToMb
                                });
                            }}
                        >
                            {findPackageMb ? bitesToMb : 0}
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return <div>MASTER</div>;
                },
                key: 'MASTER',
                width: '120px',
                align: 'center',
                render: row => {
                    const isInclude = row.packages && row.packages.find(({ name }) => name === 'Master');
                    const findPackageMb = packagesMB.find(
                        ({ packageId }) => packageId == get(isInclude, 'packageId', 4)
                    );

                    const icon = findIcon(isInclude);
                    const bitesToMb = bytesToMB(get(findPackageMb, 'numOfBytes', null));

                    return row.id ? (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    'NA'
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        packageId: get(isInclude, 'packageId', 4),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            onClick={() => {
                                setModalOpenMB(true);
                                setEditMB({
                                    packageId: get(findPackageMb, 'packageId', 4),
                                    numOfBytes: bitesToMb
                                });
                            }}
                        >
                            {findPackageMb ? bitesToMb : 0}
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return <div>EXPERT</div>;
                },
                key: 'EXPERT',
                width: '120px',
                align: 'center',
                render: row => {
                    const isInclude = row.packages && row.packages.find(({ name }) => name === 'Expert');
                    const findPackageMb = packagesMB.find(
                        ({ packageId }) => packageId === get(isInclude, 'packageId', 5)
                    );

                    const icon = findIcon(isInclude);
                    const bitesToMb = bytesToMB(get(findPackageMb, 'numOfBytes', null));

                    return row.id ? (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    'NA'
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        packageId: get(isInclude, 'packageId', 5),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            onClick={() => {
                                setModalOpenMB(true);
                                setEditMB({
                                    packageId: get(findPackageMb, 'packageId', 5),
                                    numOfBytes: bitesToMb
                                });
                            }}
                        >
                            {findPackageMb ? bitesToMb : 0}
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return <div>CARBOOK</div>;
                },
                key: 'CARBOOK',
                width: '120px',
                align: 'center',
                render: row => {
                    const isInclude = row.packages && row.packages.find(({ name }) => name === 'Carbook');
                    const findPackageMb = packagesMB.find(
                        ({ packageId }) => packageId === get(isInclude, 'packageId', 6)
                    );

                    const icon = findIcon(isInclude);
                    const bitesToMb = bytesToMB(get(findPackageMb, 'numOfBytes', null));

                    return row.id ? (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    'NA'
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        packageId: get(isInclude, 'packageId', 6),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            onClick={() => {
                                setModalOpenMB(true);
                                setEditMB({
                                    packageId: get(findPackageMb, 'packageId', 6),
                                    numOfBytes: bitesToMb
                                });
                            }}
                        >
                            {findPackageMb ? bitesToMb : 0}
                        </div>
                    );
                }
            },
            {
                title: () => (
                    <div>
                        <div>
                            <Select
                                onChange={value => {
                                    setSelectedPackage(value);
                                    localStorage.setItem('selected_additional_package', value);
                                }}
                                optionFilterProp='children'
                                placeholder={intl.formatMessage({
                                    id: 'packages.additional_package'
                                })}
                                showSearch
                                style={{ color: 'var(--text)', minWidth: 180, width: '100%' }}
                                value={selectedPackage}
                            >
                                {additionalPackages
                                    ? additionalPackages.map(({ id, name }) => (
                                          <Option key={id} value={id}>
                                              {name}
                                          </Option>
                                      ))
                                    : undefined}
                            </Select>
                        </div>
                    </div>
                ),
                key: 'selected_package',
                width: '10%',
                render: row => {
                    const isInclude =
                        row.packages && row.packages.find(({ packageId }) => packageId === selectedPackage);

                    const findPackageMb = packagesMB.find(({ packageId }) => packageId === selectedPackage);

                    const icon = findIcon(isInclude);
                    const bitesToMb = bytesToMB(get(findPackageMb, 'numOfBytes', null));

                    return row.id ? (
                        <Tooltip
                            title={
                                isInclude ? (
                                    <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
                                ) : (
                                    'NA'
                                )
                            }
                        >
                            <div
                                onClick={() => {
                                    setModalOpen(true);
                                    setEdit({
                                        access: get(isInclude, 'access', null),
                                        packageId: get(isInclude, 'packageId', selectedPackage),
                                        treeIds: row.id
                                    });
                                }}
                            >
                                {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            onClick={() => {
                                setModalOpenMB(true);
                                setEditMB({
                                    packageId: get(findPackageMb, 'packageId', selectedPackage),
                                    numOfBytes: bitesToMb
                                });
                            }}
                        >
                            {findPackageMb ? bitesToMb : 0}
                        </div>
                    );
                }
            }
        ],
        [additionalPackages, bytesToMB, findIcon, intl, packagesMB, selectedPackage]
    );

    const handleLevelVisibility = useCallback(
        e => {
            setExpandedRowKeys(flattenRights.filter(({ level }) => level < e.target.value).map(({ id }) => id));
            setVisibleLevel(Number(e.target.value));
        },
        [flattenRights]
    );

    const debouncedFilterChange = useMemo(
        () => filter => {
            const foundMap = {};
            const found = flattenRights.filter(
                entry => filterByPartGeneral(filter, entry.name) || filterByPartGeneral(filter, entry.id)
            );

            const findParents = entry => {
                if (!foundMap[entry.id]) {
                    foundMap[entry.id] = entry;
                    const parent = flattenRights.find(genEntry => genEntry.id === entry.parentId);
                    if (parent) findParents(parent);
                }
            };

            found.forEach(foundEntry => {
                findParents(foundEntry);
            });

            const res = Object.values(foundMap);

            if (filter.length > 0) {
                const maxLevel = Math.max(...found.map(({ level }) => level));
                setExpandedRowKeys(res.filter(({ level }) => level < maxLevel).map(({ id }) => id));
                setFoundIds(found.map(({ id }) => id));
            } else {
                setFoundIds([]);
                setExpandedRowKeys(flattenRights.filter(({ level }) => level < visibleLevel).map(({ id }) => id));
            }

            const rows = range => {
                const diff = range[1] - range[0];
                if (diff === 0) return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: 0`;

                return `${intl.formatMessage({ id: 'storage_journal.row_amount' })}: ${diff + 1}`;
            };

            if (found.length < 30) {
                setTablePagination({ showTotal: (total, range) => rows(range), pageSize: 9999 });
            } else {
                visibleLevel === 1
                    ? setTablePagination({
                          pageSize: 10,
                          showTotal: (total, range) => rows(range)
                      })
                    : setTablePagination({ pageSize: 2, showTotal: (total, range) => rows(range) });
            }
        },
        [flattenRights, intl, visibleLevel]
    );

    const renderNestedTable = useCallback(
        roots => {
            //         key: 'id',
            //         render: record => {
            //             const slicedId =
            //                 record.id && record.level == 1
            //                     ? String(record.id).slice(0, 2)
            //                     : record.id && record.level == 2
            //                     ? String(record.id).slice(0, 4)
            //                     : record.id && record.level == 3
            //                     ? String(record.id).slice(0, 6)
            //                     : String(record.id).slice(0, 8);

            //             return record.id ? slicedId : record.id;
            //         }
            //     },
            //     {
            //         title: <FormattedMessage id='description' />,
            //         key: 'name',
            //         width: '15%',
            //         render: record => {
            //             const space =
            //                 record.level === 2 ? '8px' : record.level === 3 ? '24px' : record.level === 4 ? '38px' : 0;

            //             return record.name ? (
            //                 <div
            //                     style={{
            //                         marginLeft: space
            //                     }}
            //                 >
            //                     {record.name}
            //                 </div>
            //             ) : (
            //                 <FormattedMessage id='long_dash' />
            //             );
            //         }
            //     },
            //     {
            //         title: () => {
            //             return <div>FREE</div>;
            //         },
            //         key: 'START',
            //         width: '125px',
            //         align: 'center',
            //         render: row => {
            //             const isInclude = row.packages.find(({ name }) => name === 'Free');

            //             const icon = findIcon(isInclude);

            //             return (
            //                 <Tooltip
            //                     title={
            //                         isInclude ? (
            //                             <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
            //                         ) : (
            //                             'NA'
            //                         )
            //                     }
            //                 >
            //                     <div
            //                         onClick={() => {
            //                             setModalOpen(true);
            //                             setEdit({
            //                                 access: get(isInclude, 'access', null),
            //                                 packageId: get(isInclude, 'packageId', 1),
            //                                 treeIds: row.id
            //                             });
            //                         }}
            //                     >
            //                         {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
            //                     </div>
            //                 </Tooltip>
            //             );
            //         }
            //     },
            //     {
            //         title: () => {
            //             return <div>START</div>;
            //         },
            //         key: 'START',
            //         width: '125px',
            //         align: 'center',
            //         render: row => {
            //             const isInclude = row.packages.find(({ name }) => name === 'Start');

            //             const icon = findIcon(isInclude);

            //             return (
            //                 <Tooltip
            //                     title={
            //                         isInclude ? (
            //                             <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
            //                         ) : (
            //                             'NA'
            //                         )
            //                     }
            //                 >
            //                     <div
            //                         onClick={() => {
            //                             setModalOpen(true);
            //                             setEdit({
            //                                 access: get(isInclude, 'access', null),
            //                                 packageId: get(isInclude, 'packageId', 2),
            //                                 treeIds: row.id
            //                             });
            //                         }}
            //                     >
            //                         {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
            //                     </div>
            //                 </Tooltip>
            //             );
            //         }
            //     },
            //     {
            //         title: () => {
            //             return <div>SPECIALIST</div>;
            //         },
            //         key: 'SPECIALIST',
            //         width: '120px',
            //         align: 'center',
            //         render: row => {
            //             const isInclude = row.packages.find(({ name }) => name === 'Specialist');

            //             const icon = findIcon(isInclude);

            //             return (
            //                 <Tooltip
            //                     title={
            //                         isInclude ? (
            //                             <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
            //                         ) : (
            //                             'NA'
            //                         )
            //                     }
            //                 >
            //                     <div
            //                         onClick={() => {
            //                             setModalOpen(true);
            //                             setEdit({
            //                                 access: get(isInclude, 'access', null),
            //                                 packageId: get(isInclude, 'packageId', 3),
            //                                 treeIds: row.id
            //                             });
            //                         }}
            //                     >
            //                         {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
            //                     </div>
            //                 </Tooltip>
            //             );
            //         }
            //     },
            //     {
            //         title: () => {
            //             return <div>MASTER</div>;
            //         },
            //         key: 'MASTER',
            //         width: '120px',
            //         align: 'center',
            //         render: row => {
            //             const isInclude = row.packages.find(({ name }) => name === 'Master');

            //             const icon = findIcon(isInclude);

            //             return (
            //                 <Tooltip
            //                     title={
            //                         isInclude ? (
            //                             <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
            //                         ) : (
            //                             'NA'
            //                         )
            //                     }
            //                 >
            //                     <div
            //                         onClick={() => {
            //                             setModalOpen(true);
            //                             setEdit({
            //                                 access: get(isInclude, 'access', null),
            //                                 packageId: get(isInclude, 'packageId', 4),
            //                                 treeIds: row.id
            //                             });
            //                         }}
            //                     >
            //                         {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
            //                     </div>
            //                 </Tooltip>
            //             );
            //         }
            //     },
            //     {
            //         title: () => {
            //             return <div>EXPERT</div>;
            //         },
            //         key: 'EXPERT',
            //         width: '120px',
            //         align: 'center',
            //         render: row => {
            //             const isInclude = row.packages.find(({ name }) => name === 'Expert');

            //             const icon = findIcon(isInclude);

            //             return (
            //                 <Tooltip
            //                     title={
            //                         isInclude ? (
            //                             <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
            //                         ) : (
            //                             'NA'
            //                         )
            //                     }
            //                 >
            //                     <div
            //                         onClick={() => {
            //                             setModalOpen(true);
            //                             setEdit({
            //                                 access: get(isInclude, 'access', null),
            //                                 packageId: get(isInclude, 'packageId', 5),
            //                                 treeIds: row.id
            //                             });
            //                         }}
            //                     >
            //                         {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
            //                     </div>
            //                 </Tooltip>
            //             );
            //         }
            //     },
            //     {
            //         title: () => {
            //             return <div>CARBOOK</div>;
            //         },
            //         key: 'CARBOOK',
            //         width: '120px',
            //         align: 'center',
            //         render: row => {
            //             const isInclude = row.packages.find(({ name }) => name === 'Carbook');

            //             const icon = findIcon(isInclude);

            //             return (
            //                 <Tooltip
            //                     title={
            //                         isInclude ? (
            //                             <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
            //                         ) : (
            //                             'NA'
            //                         )
            //                     }
            //                 >
            //                     <div
            //                         onClick={() => {
            //                             setModalOpen(true);
            //                             setEdit({
            //                                 access: get(isInclude, 'access', null),
            //                                 packageId: get(isInclude, 'packageId', 6),
            //                                 treeIds: row.id
            //                             });
            //                         }}
            //                     >
            //                         {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
            //                     </div>
            //                 </Tooltip>
            //             );
            //         }
            //     },
            //     {
            //         title: () => (
            //             <div>
            //                 <div>
            //                     <Select
            //                         onChange={value => {
            //                             setSelectedPackage(value);
            //                             localStorage.setItem('selected_additional_package', value);
            //                         }}
            //                         optionFilterProp='children'
            //                         placeholder={intl.formatMessage({
            //                             id: 'packages.additional_package'
            //                         })}
            //                         showSearch
            //                         style={{ color: 'var(--text)', minWidth: 180, width: '100%' }}
            //                         value={selectedPackage}
            //                     >
            //                         {additionalPackages
            //                             ? additionalPackages.map(({ id, name }) => (
            //                                   <Option key={id} value={id}>
            //                                       {name}
            //                                   </Option>
            //                               ))
            //                             : undefined}
            //                     </Select>
            //                 </div>
            //             </div>
            //         ),
            //         key: 'selected_package',
            //         width: '10%',
            //         render: row => {
            //             const isInclude = row.packages.find(({ packageId }) => packageId === selectedPackage);

            //             const icon = findIcon(isInclude);

            //             return (
            //                 <Tooltip
            //                     title={
            //                         isInclude ? (
            //                             <FormattedMessage id={`packages.role.${get(isInclude, 'access', null)}`} />
            //                         ) : (
            //                             'NA'
            //                         )
            //                     }
            //                 >
            //                     <div
            //                         onClick={() => {
            //                             setModalOpen(true);
            //                             setEdit({
            //                                 access: get(isInclude, 'access', null),
            //                                 packageId: get(isInclude, 'packageId', selectedPackage),
            //                                 treeIds: row.id
            //                             });
            //                         }}
            //                     >
            //                         {icon} {isInclude ? get(isInclude, 'access', null) : 'NA'}
            //                     </div>
            //                 </Tooltip>
            //             );
            //         }
            //     }
            // ];
            const tableProps = {
                dataSource: [...additionalRow, ...roots],
                columns,
                expandable: {
                    childrenColumnName: 'childs',
                    expandedRowKeys,
                    onExpandedRowsChange: expandedRowKeys => setExpandedRowKeys(expandedRowKeys)
                },
                onRow: ({ id, level }) => {
                    const classNames = `${Styles[fontOptions[level]] || ''} ${
                        foundIds.includes(id) ? Styles.foundEntry : ''
                    }`.trim();
                    if (classNames.length) {
                        return {
                            className: classNames
                        };
                    }
                },
                rowKey: 'id'
            };

            return <Table {...tableProps} bordered pagination={tablePagination} rowKey='id' scroll={{ y: 400 }} />;
        },
        [additionalRow, columns, expandedRowKeys, tablePagination, foundIds]
    );

    useEffect(() => {
        setSelectedPackage(Number(localStorage.getItem('selected_additional_package')) || undefined);
        fetchRights();
        fetchAdditionPackages();
        fetchFileSMB();
    }, [fetchRights, fetchAdditionPackages, fetchFileSMB]);

    useEffect(() => {
        debouncedFilterChange(filter);
    }, [flattenRights, filter, debouncedFilterChange]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Space>
                <Radio.Group optionType='button' value={visibleLevel}>
                    {radioOptions.map(index => (
                        <Radio key={index} onClick={handleLevelVisibility} value={index}>
                            {index}
                        </Radio>
                    ))}
                </Radio.Group>
                <DebouncedInput
                    allowClear
                    placeholder={intl.formatMessage({ id: 'packages.search_by' })}
                    setState={setFilter}
                    width={300}
                />
            </Space>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end'
                }}
            >
                <Button onClick={() => setVisibleModal(true)} type='primary'>
                    <FormattedMessage id='packages.aditional_packages' />
                </Button>
            </div>
            {renderNestedTable(defaultRights)}
            <AditionalPackagesModal
                fetchAdditionPackages={fetchAdditionPackages}
                hideModal={() => setVisibleModal(false)}
                open={visibleModal}
                rows={additionalPackages}
            />
            <AccessSettingModal
                allRolesAcees={allRolesAcees}
                edit={edit}
                handleTablePackage={handleTablePackage}
                hideModal={() => {
                    setModalOpen(false);
                    setEdit(undefined);
                }}
                open={open}
            />
            <ChangeMemoryFileModal
                allRolesAcees={allRolesAcees}
                edit={editMB}
                hideModal={() => {
                    setModalOpenMB(false);
                    setEditMB(undefined);
                }}
                open={openMB}
                packagesMB={packagesMB}
                setDefaultPackageMB={setDefaultPackageMB}
            />
        </div>
    );
};

export default injectIntl(DefaultPackagesTab);
