/* eslint-disable max-classes-per-file */
import { CloseOutlined, DeleteOutlined, FormOutlined, LoadingOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Select, Table, Tooltip, TreeSelect, notification } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { TreeNode } = TreeSelect;
const { Option } = Select;
const { confirm } = Modal;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

@injectIntl
class AddServiceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            mainTableSource: []
        };
        this.priceGroups = [];
        this.servicesOptions = [];
        this.employeeOptions = [];
        this.priceGroupsOptions = [];

        this.mainTableColumns = [
            {
                title: (
                    <React.Fragment>
                        <FormattedMessage id='services_table.labor' /> <span style={{ color: 'red' }}>*</span>
                    </React.Fragment>
                ),
                key: 'laborId',
                dataIndex: 'laborId',
                render: (data, elem) => {
                    return (
                        <Select
                            allowClear
                            disabled={this.state.editing}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                maxWidth: '95%'
                            }}
                            filterOption={(input, option) => {
                                return (
                                    String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.props.value).indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.props.cross_id).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onChange={(value, option) => {
                                if (option) {
                                    const price = option.props.price
                                        ? option.props.price
                                        : Number(this.props.normHourPrice);
                                    const count = option.props.norm_hours ? option.props.norm_hours : 1;
                                    elem.laborId = value;
                                    elem.serviceName = option.props.children;
                                    elem.masterLaborId = option.props.master_id;
                                    elem.storeGroupId = option.props.product_id;
                                    elem.count = count;
                                    elem.price = price;
                                    elem.sum = price * count;
                                } else {
                                    elem.laborId = value;
                                    elem.serviceName = value;
                                    elem.masterLaborId = value;
                                    elem.storeGroupId = value;
                                }
                                if (value) {
                                    this.getPrice(value);
                                }
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.labor'
                            })}
                            showSearch
                            style={{ minWidth: 240, color: 'var(--text)' }}
                            value={data}
                        >
                            {this.props.labors.map((elem, index) => (
                                <Option
                                    key={index}
                                    cross_id={elem.crossId}
                                    master_id={elem.masterLaborId}
                                    norm_hours={elem.laborPrice.normHours}
                                    price={elem.laborPrice.price}
                                    product_id={elem.storeGroupId}
                                    value={elem.id}
                                >
                                    {elem.name ? elem.name : elem.defaultName}
                                </Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='tire.priceGroup' />,
                key: 'tireStationPriceGroupId',
                dataIndex: 'tireStationPriceGroupId',
                render: (data, elem) => {
                    return (
                        <Select
                            disabled={!elem.laborId}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterOption={(input, option) => {
                                return (
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onChange={(value, option) => {
                                elem.tireStationPriceGroupId = value;
                                elem.price = option.props.price || elem.price;
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({ id: 'tire.priceGroup' })}
                            showSearch
                            style={{ minWidth: 200 }}
                            value={data || undefined}
                        >
                            {this.priceGroupsOptions}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='services_table.employee' />,
                key: 'employee',
                dataIndex: 'employeeId',
                render: (data, elem) => {
                    return (
                        <Select
                            allowClear
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                maxWidth: '95%'
                            }}
                            onChange={(value, option) => {
                                elem.employeeId = value;
                                this.setState({});
                            }}
                            optionFilterProp='children'
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.employee'
                            })}
                            showSearch
                            style={{ minWidth: 180 }}
                            value={data || undefined}
                        >
                            {this.employeeOptions}
                        </Select>
                    );
                }
            },
            {
                key: 'comment',
                dataIndex: 'comment',
                width: 'auto',
                render: (data, elem) => {
                    let detail = elem.serviceName;
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <CommentaryButton
                            commentary={
                                data || {
                                    comment: undefined,
                                    positions: [],
                                    problems: []
                                }
                            }
                            detail={detail}
                            disabled={elem.laborId == null}
                            setComment={(comment, positions, problems) => {
                                elem.comment = {
                                    comment,
                                    positions,
                                    problems
                                };
                                elem.serviceName = comment || elem.serviceName;
                                this.setState({});
                            }}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                dataIndex: 'price',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            decimalSeparator=','
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={1}
                            onChange={value => {
                                elem.price = value;
                                elem.sum = value * elem.count;
                                this.setState({});
                            }}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            precision={2}
                            value={Math.round(data * 100) / 100 || 1}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'count',
                dataIndex: 'count',
                render: (data, elem) => {
                    const value = data ? Number(data).toFixed(2) : 1;

                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            decimalSeparator=','
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={0.1}
                            onChange={value => {
                                elem.count = value;
                                elem.sum = value * elem.price;
                                this.setState({});
                            }}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            precision={2}
                            step={0.1}
                            value={value}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'sum',
                render: elem => {
                    const sum = elem.price * (elem.count || 1);

                    return (
                        <InputNumber
                            className={Styles.serviceNumberInput}
                            decimalSeparator=','
                            disabled
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            style={{ color: 'black' }}
                            value={Math.round(sum * 10) / 10 || 1}
                        />
                    );
                }
            },
            {
                key: 'delete',
                width: '3%',
                render: elem => {
                    return (
                        <CloseOutlined
                            onClick={() => {
                                elem.storeGroupId = this.state.editing || elem.related ? elem.storeGroupId : undefined;
                                elem.masterLaborId =
                                    this.state.editing || elem.related ? elem.masterLaborId : undefined;
                                elem.serviceName = undefined;
                                elem.price = 1;
                                elem.count = 1;
                                elem.sum = undefined;
                                this.setState({});
                            }}
                        />
                    );
                }
            }
        ];
    }

    getPrice = async laborId => {
        const { clientVehicleTypeId, clientVehicleRadius } = this.props;

        if (clientVehicleTypeId && clientVehicleRadius) {
            this.priceGroups = await fetchAPI('GET', 'labors/price_groups', {
                laborId,
                vehicleTypeId: clientVehicleTypeId,
                radius: Math.round(clientVehicleRadius)
            }, null, {handleErrorInternally: true});
            this.priceGroupsOptions = this.priceGroups.map((elem, i) => (
                <Option key={i} price={elem.price} value={elem.id}>
                    {elem.name}
                </Option>
            ));
            if (this.priceGroups && this.priceGroups.length) {
                this.state.mainTableSource[0].price = this.priceGroups[0].price || this.state.mainTableSource[0].price;
                this.state.mainTableSource[0].tireStationPriceGroupId = this.priceGroups[0].id;
                this.setState({});
            }
        }
    };

    handleOk = () => {
        const { editing, mainTableSource, relatedServices, relatedServicesCheckbox } = this.state;
        if (mainTableSource[0].laborId == undefined) {
            notification.warning({
                message: 'Заполните все необходимые поля!'
            });

            return;
        }
        if (editing) {
            this.props.updateLabor(this.props.tableKey, { ...mainTableSource[0] });
        } else {
            const data = {
                insertMode: true,
                details: [],
                services: []
            };
            mainTableSource.map(element => {
                data.services.push({
                    serviceId: element.laborId,
                    serviceName: element.serviceName,
                    employeeId: element.employeeId || null,
                    purchasePrice: Math.round(element.purchasePrice * 10) / 10 || 0,
                    count: element.count ? element.count : 1,
                    servicePrice: Math.round(element.price * 10) / 10 || 1,
                    tireStationPriceGroupId: element.tireStationPriceGroupId,
                    comment: element.comment || {
                        comment: undefined,
                        positions: []
                    }
                });
            });
            this.addDetailsAndLabors(data);
        }
        this.props.hideModal();
    };

    handleCancel = () => {
        this.setState({
            mainTableSource: [],
            relatedServices: [],
            relatedServicesCheckbox: false
        });
        this.props.hideModal();
    };

    async addDetailsAndLabors(data) {
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/orders/${this.props.orderId}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            this.props.updateDataSource();
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    fetchData = () => {
        this.labors = this.props.labors;
        this.getOptions();
    };

    getOptions = async () => {
        this.servicesOptions = [...this.props.labors];
        this.employeeOptions = this.props.employees.map(elem => (
            <Option key={elem.id} value={elem.id}>
                {elem.name} {elem.surname}
            </Option>
        ));
    };

    deleteService = async () => {
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}/labors?ids=[${this.props.labor.id}]`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            if (result.success) {
                this.props.updateDataSource();
                this.handleCancel();
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    confirmDelete = () => {
        const { formatMessage } = this.props.intl;
        const that = this;
        confirm({
            title: formatMessage({ id: 'add_order_form.delete_confirm' }),
            onOk() {
                that.deleteService();
            },
            okType: 'danger'
        });
    };

    getMobileForm() {
        const { mainTableSource } = this.state;
        const dataSource = mainTableSource[0] || {};
        const columns = [...this.mainTableColumns];
        columns.pop();

        return columns.map(({ title, key, render, dataIndex }) => {
            return (
                <div
                    key={key}
                    className={`${Styles.mobileTable} ${
                        (key == 'price' || key == 'count') && Styles.mobileTableNumber
                    } ${key == 'employee' && Styles.mobileTableEmployee} ${
                        key == 'comment' && Styles.mobileTableComment
                    } ${key == 'sum' && Styles.mobileTableSum} `}
                >
                    {key != 'comment' && title}
                    <div>{dataIndex ? render(dataSource[dataIndex], dataSource) : render(dataSource)}</div>
                </div>
            );
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevState) {
        if (prevState.visible == false && this.props.visible) {
            const editing = Boolean(this.props.labor && this.props.labor.id);
            if (editing) {
                this.getPrice(this.props.labor.laborId);
            }
            this.getOptions();
            this.state.mainTableSource = [{ ...this.props.labor }];

            if (!editing) {
                this.state.mainTableSource[0].employeeId = this.props.defaultEmployeeId;
                const priceGroup = this.priceGroups.find(({ id }) => id == this.props.clientVehicleTypeId);
                if (priceGroup) {
                    this.state.mainTableSource[0].tireStationPriceGroupId = priceGroup.id;
                }
            }

            this.setState({
                editing
            });
        }
    }

    render() {
        const { visible, isMobile } = this.props;
        const { mainTableSource, editing } = this.state;

        return (
            <React.Fragment>
                <Modal
                    footer={
                        isMobile && editing ? (
                            <div>
                                <Button
                                    onClick={() => this.confirmDelete()}
                                    style={{
                                        float: 'left'
                                    }}
                                    type='danger'
                                >
                                    <DeleteOutlined />
                                </Button>
                                <Button onClick={() => this.handleCancel()}>
                                    <FormattedMessage id='cancel' />
                                </Button>
                                <Button onClick={() => this.handleOk()} type='primary'>
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        ) : (
                            void 0
                        )
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    style={
                        !isMobile
                            ? {
                                  minWidth: 560
                              }
                            : {
                                  minWidth: '95%'
                              }
                    }
                    title={null}
                    visible={visible}
                    width='min-content'
                    zIndex={200}
                >
                    <div className={Styles.tableWrap}>
                        <div className={Styles.modalSectionTitle}>
                            <div style={{ display: 'block' }}>
                                <FormattedMessage id='services_table.labor' />
                            </div>
                        </div>
                        {!isMobile ? (
                            <Table
                                bordered
                                columns={this.mainTableColumns}
                                dataSource={mainTableSource}
                                pagination={false}
                                size='small'
                            />
                        ) : (
                            this.getMobileForm()
                        )}
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddServiceModal;

@injectIntl
class CommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: props.detail,
                positions: [],
                problems: []
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({
            currentCommentary: this.props.commentary.comment ? this.props.commentary.comment : this.props.detail,
            visible: true
        });
        if (this.commentaryInput.current != undefined) {
            this.commentaryInput.current.focus();
        }
    };

    handleOk = async () => {
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });
        this.props.setComment(currentCommentary, currentCommentaryProps.positions, currentCommentaryProps.problems);
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 500);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            currentCommentary: this.props.detail,
            currentCommentaryProps: {
                name: this.props.detail,
                positions: [],
                problems: []
            }
        });
    };

    renderHeader = () => {
        return (
            <div>
                <p>{this.props.detail}</p>
            </div>
        );
    };

    getCommentary() {
        const { currentCommentaryProps } = this.state;
        let currentCommentary = this.props.detail;

        if (currentCommentaryProps.positions.length) {
            currentCommentary += ' -';
            currentCommentary += `${currentCommentaryProps.positions.map(
                data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
            )};`;
        }
        this.setState({
            currentCommentary
        });
    }

    setCommentaryPosition(position) {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    }

    componentDidMount() {
        this._isMounted = true;
        const { commentary, detail } = this.props;
        if (this._isMounted) {
            this.setState({
                currentCommentaryProps: {
                    name: detail,
                    positions: commentary.positions || [],
                    problems: commentary.problems || []
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { TextArea } = Input;
        const { visible, loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, commentary } = this.props;
        const { positions } = this;

        return (
            <div>
                {commentary.comment ? (
                    <Button
                        className={Styles.commentaryButton}
                        onClick={this.showModal}
                        title={this.props.intl.formatMessage({ id: 'commentary.edit' })}
                    >
                        <FormOutlined
                            className={Styles.commentaryButtonIcon}
                            style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                        />
                    </Button>
                ) : (
                    <Tooltip title={<FormattedMessage id='commentary.add' />} zIndex={2001}>
                        <Button disabled={disabled} onClick={this.showModal} type='primary'>
                            <MessageOutlined />
                        </Button>
                    </Tooltip>
                )}
                <Modal
                    footer={
                        disabled
                            ? null
                            : [
                                  <Button key='back' onClick={this.handleCancel}>
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button key='submit' loading={loading} onClick={this.handleOk} type='primary'>
                                      <FormattedMessage id='save' />
                                  </Button>
                              ]
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={this.renderHeader()}
                    visible={visible}
                >
                    <React.Fragment>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.where' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {positions.map((position, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            onClick={() => {
                                                this.setCommentaryPosition(position);
                                            }}
                                            type={
                                                currentCommentaryProps.positions.findIndex(elem => position == elem) >
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <FormattedMessage id={position} />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                disabled={disabled}
                                onChange={() => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{ width: '100%', minHeight: '150px', resize: 'none' }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}
