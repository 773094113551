/**
 * This module handles redux operations for storage documents related to a client.
 */

import {
    STORAGE_DOCUMENT_CONTEXTS,
    STORAGE_DOCUMENT_OPERATION_CODES,
    STORAGE_DOCUMENT_TYPES
} from 'modals/RefactoredCashOrderModal/constants';
import { mainModuleName } from 'modals/RefactoredCashOrderModal/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'storeDocs';
const prefix = `cpb/refactoredCashOrderModal/${moduleName}`;

export const FETCH_STORE_DOCS = `${prefix}/FETCH_STORE_DOCS`;
export const FETCH_STORE_DOCS_SUCCESS = `${prefix}/FETCH_STORE_DOCS_SUCCESS`;
export const SET_STORE_DOCS_FILTERS = `${prefix}/SET_STORE_DOCS_FILTERS`;
export const SET_FETCHING_STORE_DOCS = `${prefix}/SET_FETCHING_STORE_DOCS`;

export const SET_SELECTED_STORE_DOC_ID = `${prefix}/SET_SELECTED_STORE_DOC_ID`;

export const FETCH_SELECTED_STORE_DOC = `${prefix}/FETCH_SELECTED_STORE_DOC`;
export const FETCH_SELECTED_STORE_DOC_SUCCESS = `${prefix}/FETCH_SELECTED_STORE_DOC_SUCCESS`;
export const SET_FETCHING_SELECTED_STORE_DOC = `${prefix}/SET_FETCHING_SELECTED_STORE_DOC`;

export const CLEAR_STORE_DOCS_STATE = `${prefix}/CLEAR_STORE_DOCS_STATE`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    storeDocs: [],
    storeDocsFilters: {
        page: 1,
        query: undefined,
        documentType: STORAGE_DOCUMENT_TYPES.CLIENT,
        context: STORAGE_DOCUMENT_CONTEXTS.STOCK,
        statuses: ['DONE', 'NEW'],
        operationCodes: [
            STORAGE_DOCUMENT_OPERATION_CODES.OUT,
            STORAGE_DOCUMENT_OPERATION_CODES.CRT
        ],
        sumRemains: true
    },
    storeDocsStats: {},
    fetchingStoreDocs: false,

    selectedStoreDocId: undefined,
    selectedStoreDoc: {},
    fetchingSelectedStoreDoc: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_STORE_DOCS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_STORE_DOCS_FILTERS:
            return {
                ...state,
                storeDocsFilters: {
                    ...state.storeDocsFilters,
                    ...payload
                }
            };
        case SET_FETCHING_STORE_DOCS:
            return {
                ...state,
                fetchingStoreDocs: payload
            };
        case SET_SELECTED_STORE_DOC_ID:
            return {
                ...state,
                selectedStoreDocId: payload
            };
        case FETCH_SELECTED_STORE_DOC_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_FETCHING_SELECTED_STORE_DOC:
            return {
                ...state,
                fetchingSelectedStoreDoc: payload
            };
        case CLEAR_STORE_DOCS_STATE:
            return ReducerState;

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectStoreDocs = state => state[mainModuleName][moduleName].storeDocs;
export const selectStoreDocsStats = state => state[mainModuleName][moduleName].storeDocsStats;
export const selectStoreDocsFilters = state => state[mainModuleName][moduleName].storeDocsFilters;
export const selectFetchingStoreDocs = state => state[mainModuleName][moduleName].fetchingStoreDocs;

export const selectSelectedStoreDocId = state =>
    state[mainModuleName][moduleName].selectedStoreDocId;
export const selectSelectedStoreDoc = state => state[mainModuleName][moduleName].selectedStoreDoc;
export const selectFetchingSelectedStoreDoc = state =>
    state[mainModuleName][moduleName].fetchingSelectedStoreDoc;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchStoreDocs = () => ({
    type: FETCH_STORE_DOCS
});

/*
 * @param {*} fetchedData.storeDocs
 * @param {*} fetchedData.storeDocsStats
 */
export const fetchStoreDocsSuccess = fetchedData => ({
    type: FETCH_STORE_DOCS_SUCCESS,
    payload: fetchedData
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setStoreDocsFilters = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_STORE_DOCS_FILTERS,
            payload: filters
        });
        dispatch(fetchStoreDocs());
    };
};

export const setStoreDocsFiltersPoor = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_STORE_DOCS_FILTERS,
            payload: filters
        });
    };
};

export const setFetchingStoreDocs = value => ({
    type: SET_FETCHING_STORE_DOCS,
    payload: value
});

export const fetchSelectedStoreDoc = () => ({
    type: FETCH_SELECTED_STORE_DOC
});

/*
 * @param {*} fetchedData.selectedStoreDoc
 */
export const fetchSelectedStoreDocSuccess = fetchedData => ({
    type: FETCH_SELECTED_STORE_DOC_SUCCESS,
    payload: fetchedData
});

export const setFetchingSelectedStoreDoc = value => ({
    type: SET_FETCHING_SELECTED_STORE_DOC,
    payload: value
});

/**
 * Set selected store doc and fetch it automatically.
 * If store doc ID is undefined, then buffer will be cleared (empty object will be set)
 * @param {*} value - store doc ID
 */
export const setSelectedStoreDocId = value => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_SELECTED_STORE_DOC_ID,
            payload: value
        });

        if (value) {
            dispatch(fetchSelectedStoreDoc());
        } else {
            // Clear selected store doc buffer if id was undefined
            dispatch(fetchSelectedStoreDocSuccess({ selectedStoreDoc: {} }));
        }
    };
};

export const clearStoreDocsState = () => ({
    type: CLEAR_STORE_DOCS_STATE
});
