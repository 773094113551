import { Table } from 'antd';
import { MODALS, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { AddLaborOrDetailToOrderModal } from 'modals';
import { setPageOrders } from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setPageOrders,
    setModal
};

/**
 * Table of vehicle orders
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AggregateDetailsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 25,
            details: [],
            stats: 0
        };

        const { user } = props;

        /** Search labors by service name(найменування) */
        this.handleCodeSearch = _.debounce(value => {
            this.setState({ codeQuery: value.replace(/[+()]/g, '') });
            this.fetchAllAggregatesDetails();
            // setAppurtenancesCodeSearchQuery({codeQuery: value.replace(/[+()]/g,'')});
        }, 1000).bind(this);

        /** Search labors by  default name (тип) */
        this.handleBrandSearch = _.debounce(value => {
            this.setState({ brandQuery: value.replace(/[+()]/g, '') });
            this.fetchAllAggregatesDetails();
        }, 1000).bind(this);

        /** Search labors by store group(група товару) */
        this.handleNameSearch = _.debounce(value => {
            this.setState({
                nameQuery: value.replace(/[+()]/g, '')
            });
            this.fetchAllAggregatesDetails();
        }, 1000).bind(this);

        /** Search labors by employee full name */
        this.handleSupplierSearch = _.debounce(value => {
            this.setState({
                supplierQuery: value.replace(/[+()]/g, '')
            });
            this.fetchAllAggregatesDetails();
        }, 1000).bind(this);
    }

    componentDidMount() {
        this.fetchAllAggregatesDetails();
    }

    fetchAllAggregatesDetails = async (field, order) => {
        const { id } = this.props;
        const { page, pageSize, codeQuery, brandQuery, nameQuery, supplierQuery } = this.state;

        this.setState({
            loading: true
        });

        const aggregatesDetails = await fetchAPI(
            'GET',
            `/orders/appurtenances/aggregate/${id}`,
            {
                page,
                codeQuery,
                brandQuery,
                nameQuery,
                supplierQuery,
                sortField: field,
                sortOrder: order
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            details: aggregatesDetails.appurtenances,
            stats: aggregatesDetails.appurtenancesStats.totalRowsCount
        });
        this.setState({
            loading: false
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter) return;

        const sortField = _.get(sorter, 'columnKey', undefined);
        const sortOrder = _.get(sorter, 'order', undefined);

        // make sorting object, if no sorting, make all undefined

        const sort = {
            sortField: sortOrder ? sortField : undefined,
            sortOrder: sortOrder == 'ascend' ? 'asc' : sortOrder == 'descend' ? 'desc' : undefined
        };

        this.fetchAllAggregatesDetails(sort.sortField, sort.sortOrder);
    };

    onAddDetailToOrder = ({ detail }) => {
        const { id } = this.props;
        this.props.setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
            details: [detail],
            mode: 'ADD_DETAIL',
            aggregateId: id
        });
    };

    render() {
        const {
            intl: { formatMessage },
            disabled
        } = this.props;

        const { page, pageSize, stats, details, serviceNameQuery, loading } = this.state;

        const columns = columnsConfig({
            onAddDetailToOrder: this.onAddDetailToOrder,
            onCodeSearch: this.handleCodeSearch,
            onBrandSearch: this.handleBrandSearch,
            onNameSearch: this.handleNameSearch,
            onSupplierSearch: this.handleSupplierSearch,
            disabled
        });

        const pagination = {
            pageSize,
            page,
            size: 'large',
            total: Math.ceil(stats / pageSize) * pageSize,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchAllAggregatesDetails();
            }
        };

        return (
            <div className={Styles.tableCont}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columns}
                    dataSource={details}
                    loading={loading}
                    onChange={this.handleTableChange}
                    pagination={pagination}
                    rowKey={productId => productId.productId}
                    scroll={{ y: '60vh' }}
                    size='small'
                />
                <AddLaborOrDetailToOrderModal />
            </div>
        );
    }
}
