import { Col, DatePicker, Form, Row, Select } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import { DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const DEF_DATE_FORMAT = 'YYYY/MM/DD';
const DEF_UI_DATE_FORMAT = 'YYYY/MM/DD';

@injectIntl
class ReportOrdersFilter extends React.Component {
    render() {
        const { filter, filterOptions } = this.props;

        const {
            creationFromDate,
            creationToDate,
            appointmentFromDate,
            appointmentToDate,
            doneFromDate,
            doneToDate,
            status,

            appurtenanciesResponsibleId,
            mechanicId,
            managerId,
            requisiteId,
            stationNum
        } = filter;

        const { employees, managers, requisites, stations } = filterOptions;

        const statuses = {
            required: 'transfer_required',
            reserve: 'transfer_reserve',
            not_complete: 'transfer_not_complete',
            approve: 'transfer_approve',
            progress: 'transfer_progress',
            success: 'transfer_success'
        };

        const statusesDataList = [
            {
                label: <FormattedMessage id={statuses.required} />,
                value: 'required'
            },
            {
                label: <FormattedMessage id={statuses.reserve} />,
                value: 'reserve'
            },
            {
                label: <FormattedMessage id={statuses.not_complete} />,
                value: 'not_complete'
            },
            {
                label: <FormattedMessage id={statuses.approve} />,
                value: 'approve'
            },
            {
                label: <FormattedMessage id={statuses.progress} />,
                value: 'progress'
            },
            {
                label: <FormattedMessage id={statuses.success} />,
                value: 'success'
            }
        ];

        const initValues = {
            creationFromDate: creationFromDate ? dayjs(creationFromDate, DEF_DATE_FORMAT) : undefined,
            creationToDate: creationToDate ? dayjs(creationToDate, DEF_DATE_FORMAT) : undefined,
            appointmentFromDate: appointmentFromDate ? dayjs(appointmentFromDate, DEF_DATE_FORMAT) : undefined,
            appointmentToDate: appointmentToDate ? dayjs(appointmentToDate, DEF_DATE_FORMAT) : undefined,
            doneFromDate: doneFromDate ? dayjs(doneFromDate, DEF_DATE_FORMAT) : undefined,
            doneToDate: doneToDate ? dayjs(doneToDate, DEF_DATE_FORMAT) : undefined
        };

        // Handlers
        const onCreationRangeChanged = range => {
            this.props.form.setFieldsValue({
                creationFromDate: range[0],
                creationToDate: range[1],
                creationDateRange: range
            });
        };

        const onAppointmentRangeChanged = range => {
            this.props.form.setFieldsValue({
                appointmentFromDate: range[0],
                appointmentToDate: range[1],
                appointmentDateRange: range
            });
        };

        const onDoneRangeChanged = range => {
            this.props.form.setFieldsValue({
                doneFromDate: range[0],
                doneToDate: range[1],
                doneDateRange: range
            });
        };

        const onCreationFromDateChanged = date => {
            const { creationToDate } = this.props.form.getFieldsValue();

            this.props.form.setFieldsValue({
                creationDateRange: [date, creationToDate]
            });
        };

        const onCreationToDateChanged = date => {
            const { creationFromDate } = this.props.form.getFieldsValue();
            this.props.form.setFieldsValue({
                creationDateRange: [creationFromDate, date]
            });
        };

        const onAppointmentFromDateChanged = date => {
            const { appointmentToDate } = this.props.form.getFieldsValue();
            this.props.form.setFieldsValue({
                appointmentDateRange: [date, appointmentToDate]
            });
        };

        const onAppointmentToDateChanged = date => {
            const { appointmentFromDate } = this.props.form.getFieldsValue();
            this.props.form.setFieldsValue({
                appointmentDateRange: [appointmentFromDate, date]
            });
        };

        const onDoneFromDateChanged = date => {
            const { doneToDate } = this.props.form.getFieldsValue();
            this.props.form.setFieldsValue({
                doneDateRange: [date, doneToDate]
            });
        };

        const onDoneToDateChanged = date => {
            const { doneFromDate } = this.props.form.getFieldsValue();
            this.props.form.setFieldsValue({
                doneDateRange: [doneFromDate, date]
            });
        };
        //------------------

        return (
            <Form form={this.props.form} initialvalues={initValues} layout='inline' onFinish={() => {}}>
                <div className={Styles.formContent}>
                    <div className={Styles.filterBlock}>
                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={4}>
                                <FormattedMessage id='report-orders-form.creation' />
                            </Col>
                            <Col className={Styles.col} span={2}>
                                <FormattedMessage id='report-orders-form.from' />
                            </Col>
                            <Col className={Styles.col} span={6}>
                                <Form.Item className={Styles.formItemStyle} name='creationFromDate'>
                                    <DatePicker
                                        format='YYYY/MM/DD'
                                        fromat={DEF_UI_DATE_FORMAT}
                                        onChange={onCreationFromDateChanged}
                                        popupStyle={{ zIndex: 2000 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}>
                                <FormattedMessage id='report-orders-form.to' />
                            </Col>
                            <Col className={Styles.col} span={6}>
                                <Form.Item className={Styles.formItemStyle} name='creationToDate'>
                                    <DatePicker
                                        format='YYYY/MM/DD'
                                        fromat={DEF_UI_DATE_FORMAT}
                                        onChange={onCreationToDateChanged}
                                        popupStyle={{ zIndex: 2000 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={4}>
                                <Form.Item name='creationDateRange'>
                                    <DateRangePicker
                                        minimize
                                        onDateChange={onCreationRangeChanged}
                                        overlayStyle={{ zIndex: '5000' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={4}>
                                <FormattedMessage id='report-orders-form.appointment' />
                            </Col>
                            <Col className={Styles.col} span={2}>
                                <FormattedMessage id='report-orders-form.from' />
                            </Col>
                            <Col className={Styles.col} span={6}>
                                <Form.Item className={Styles.formItemStyle} name='appointmentFromDate'>
                                    <DatePicker
                                        format='YYYY/MM/DD'
                                        fromat={DEF_UI_DATE_FORMAT}
                                        onChange={onAppointmentFromDateChanged}
                                        popupStyle={{ zIndex: 2000 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}>
                                <FormattedMessage id='report-orders-form.to' />
                            </Col>
                            <Col className={Styles.col} span={6}>
                                <Form.Item className={Styles.formItemStyle} name='appointmentToDate'>
                                    <DatePicker
                                        format='YYYY/MM/DD'
                                        fromat={DEF_UI_DATE_FORMAT}
                                        onChange={onAppointmentToDateChanged}
                                        popupStyle={{ zIndex: 2000 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={4}>
                                <Form.Item name='appointmentDateRange'>
                                    <DateRangePicker
                                        minimize
                                        onDateChange={onAppointmentRangeChanged}
                                        overlayStyle={{ zIndex: '5000' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={4}>
                                <FormattedMessage id='report-orders-form.done' />
                            </Col>
                            <Col className={Styles.col} span={2}>
                                <FormattedMessage id='report-orders-form.from' />
                            </Col>
                            <Col className={Styles.col} span={6}>
                                <Form.Item className={Styles.formItemStyle} name='doneFromDate'>
                                    <DatePicker
                                        format='YYYY/MM/DD'
                                        fromat={DEF_UI_DATE_FORMAT}
                                        onChange={onDoneFromDateChanged}
                                        popupStyle={{ zIndex: 2000 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}>
                                <FormattedMessage id='report-orders-form.to' />
                            </Col>
                            <Col className={Styles.col} span={6}>
                                <Form.Item className={Styles.formItemStyle} name='doneToDate'>
                                    <DatePicker
                                        format='YYYY/MM/DD'
                                        fromat={DEF_UI_DATE_FORMAT}
                                        onChange={onDoneToDateChanged}
                                        popupStyle={{ zIndex: 2000 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={4}>
                                <Form.Item name='doneDateRange'>
                                    <DateRangePicker
                                        minimize
                                        onDateChange={onDoneRangeChanged}
                                        overlayStyle={{ zIndex: '5000' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    {/* ------------------------------------------------------------------------- */}
                    <div className={Styles.filterBlock}>
                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={8}>
                                <FormattedMessage id='report-orders-form.status' />
                            </Col>
                            <Col className={Styles.col} span={14}>
                                <Form.Item className={Styles.formItemStyle} name='status'>
                                    <Select
                                        allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionFilterProp='label'
                                        options={statusesDataList}
                                        showSearch
                                    ></Select>
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}></Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={8}>
                                <FormattedMessage id='report-orders-form.station' />
                            </Col>
                            <Col className={Styles.col} span={14}>
                                <Form.Item className={Styles.formItemStyle} name='stationNum'>
                                    <DecoratedSelect
                                        allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionLabel='name'
                                        options={stations} // Will be sent as var
                                        optionValue='num'
                                        showSearch
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}></Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={8}>
                                <FormattedMessage id='report-orders-form.requisites' />
                            </Col>
                            <Col className={Styles.col} span={14}>
                                <Form.Item className={Styles.formItemStyle} name='requisiteId'>
                                    <DecoratedSelect
                                        allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionDisabled='disabled'
                                        optionLabel='name' // Will be sent as var
                                        options={requisites}
                                        optionValue='id'
                                        showSearch
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}></Col>
                        </Row>
                    </div>
                    {/* ------------------------------------------------------------------------- */}
                    <div className={Styles.filterBlock}>
                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={8}>
                                <FormattedMessage id='report-orders-form.responsible' />
                            </Col>
                            <Col className={Styles.col} span={14}>
                                <Form.Item className={Styles.formItemStyle} name='managerId'>
                                    <DecoratedSelect
                                        allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionDisabled='disabled'
                                        optionLabel={option => {
                                            return `${_.get(option, 'managerSurname')} ${_.get(option, 'managerName')}`;
                                        }} // Will be sent as var
                                        options={managers}
                                        optionValue='id'
                                        showSearch
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}></Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={8}>
                                <FormattedMessage id='report-orders-form.mechanic' />
                            </Col>
                            <Col className={Styles.col} span={14}>
                                <Form.Item className={Styles.formItemStyle} name='mechanicId'>
                                    <DecoratedSelect
                                        allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionDisabled='disabled'
                                        optionLabel={option => {
                                            return `${_.get(option, 'surname')} ${_.get(option, 'name')}`;
                                        }} // Will be sent as var
                                        options={employees}
                                        optionValue='id'
                                        showSearch
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}></Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col className={Styles.colText} span={8}>
                                <FormattedMessage id='report-orders-form.appurtenancies_responsible' />
                            </Col>
                            <Col className={Styles.col} span={14}>
                                <Form.Item className={Styles.formItemStyle} name='appurtenanciesResponsibleId'>
                                    <DecoratedSelect
                                        allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionDisabled='disabled'
                                        optionLabel={option => {
                                            return `${_.get(option, 'surname')} ${_.get(option, 'name')}`;
                                        }} // Will be sent as var
                                        options={employees}
                                        optionValue='id'
                                        showSearch
                                    />
                                </Form.Item>
                            </Col>
                            <Col className={Styles.col} span={2}></Col>
                        </Row>
                    </div>
                    {/* ------------------------------------------------------------------------- */}
                </div>
            </Form>
        );
    }
}

export const ReportOrdersFilterForm = ReportOrdersFilter;
