import { Button, Modal, Popconfirm } from 'antd';
import { MODALS, resetModal } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { OperationTypeSelector, PayablesForm, ReceivablesForm } from './components';
import { OPERATION_TYPES } from './constants';
import { clearModalState, coverDebts, selectServiceOperationType } from './redux/duck';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modal: state.modals.modal,

    serviceOperationType: selectServiceOperationType(state)
});

const mapDispatchToProps = {
    resetModal,
    clearModalState,
    coverDebts
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class DebtZeroingModal extends Component {
    componentDidUpdate(prevProps) {
        const prevModal = _.get(prevProps, 'modal');
        const currModal = _.get(this.props, 'modal');
        const currInitialized = _.get(this.props, 'initialized');

        // Initialize this modal when it is opened or reopened after closing(when modal is closed it's state will be cleared)
        if (prevModal != currModal && currModal == MODALS.DEBT_ZEROING && !currInitialized) {
            // this.initializeCashOrderModal();
        }
    }

    onClose = () => {
        const { resetModal, clearModalState } = this.props;

        resetModal();
        clearModalState();
    };

    onSubmit = () => {
        this.props.coverDebts();
        this.onClose();
    };

    render() {
        const {
            isMobile,
            modal,
            serviceOperationType,

            intl: { formatMessage }
        } = this.props;

        // Form is a set of fields which can be entered
        let currentForm = null;
        switch (serviceOperationType) {
            case OPERATION_TYPES.RECEIVABLES:
                currentForm = <ReceivablesForm />;
                break;
            case OPERATION_TYPES.PAYABLES:
                currentForm = <PayablesForm />;
                break;
        }

        return (
            <React.Fragment>
                <Modal
                    className={Styles.mainContainer}
                    destroyOnClose
                    footer={
                        <div className={Styles.buttonGroup}>
                            <Popconfirm
                                content={`${formatMessage({
                                    id: 'debt_zeroing_modal.confirm_content'
                                })}`}
                                onConfirm={this.onSubmit}
                                overlayStyle={{ zIndex: 6666 }}
                                title={`${formatMessage({
                                    id: 'debt_zeroing_modal.confirm_title'
                                })}`}
                            >
                                <Button type='primary'>OK</Button>
                            </Popconfirm>
                        </div>
                    }
                    maskClosable={false}
                    onCancel={this.onClose}
                    title={
                        <div>
                            <span className={Styles.title}>
                                <FormattedMessage id='debt_zeroing_modal.title' />
                            </span>
                            <OperationTypeSelector />
                        </div>
                    }
                    visible={modal === MODALS.DEBT_ZEROING}
                    width={isMobile ? '90vw' : '70vw'}
                >
                    {currentForm}
                </Modal>
            </React.Fragment>
        );
    }
}
