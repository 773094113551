import { FrownOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class Catcher extends Component {
    state = {
        error: null,
        errorInfo: null
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
        console.error("🔥_ERROR_🔥:", error); // eslint-disable-line
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            return (
                <section className={Styles.catcher}>
                    <FormattedMessage id='error' />!
                    <FrownOutlined className={Styles.catcherIcon} />
                </section>
            );
        }

        return children;
    }
}
