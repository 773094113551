import {
    STORAGE_DOCUMENT_CONTEXTS,
    STORAGE_DOCUMENT_TYPES
} from 'modals/RefactoredCashOrderModal/constants';
import { mainModuleName } from 'modals/RefactoredCashOrderModal/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'suppliersStoreDocs';
const prefix = `cpb/refactoredCashOrderModal/${moduleName}`;

export const FETCH_SUPPLIERS_STORE_DOCS = `${prefix}/FETCH_SUPPLIERS_STORE_DOCS`;
export const FETCH_SUPPLIERS_STORE_DOCS_SUCCESS = `${prefix}/FETCH_SUPPLIERS_STORE_DOCS_SUCCESS`;
export const SET_SUPPLIERS_STORE_DOCS_FILTERS = `${prefix}/SET_SUPPLIERS_STORE_DOCS_FILTERS`;
export const SET_FETCHING_SUPPLIERS_STORE_DOCS = `${prefix}/SET_FETCHING_SUPPLIERS_STORE_DOCS`;

export const SET_SELECTED_SUPPLIER_STORE_DOC_ID = `${prefix}/SET_SELECTED_SUPPLIER_STORE_DOC_ID`;

export const FETCH_SELECTED_SUPPLIER_STORE_DOC = `${prefix}/FETCH_SELECTED_SUPPLIER_STORE_DOC`;
export const FETCH_SELECTED_SUPPLIER_STORE_DOC_SUCCESS = `${prefix}/FETCH_SELECTED_SUPPLIER_STORE_DOC_SUCCESS`;
export const SET_FETCHING_SELECTED_SUPPLIER_STORE_DOC = `${prefix}/SET_FETCHING_SELECTED_SUPPLIER_STORE_DOC`;

export const CLEAR_SUPPLIERS_STORE_DOCS_STATE = `${prefix}/CLEAR_SUPPLIERS_STORE_DOCS_STATE`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    suppliersStoreDocs: [],
    suppliersStoreDocsFilters: {
        page: 1,
        counterpartBusinessSupplierId: undefined,
        query: undefined,
        documentTypes: [STORAGE_DOCUMENT_TYPES.SERVICE, STORAGE_DOCUMENT_TYPES.SUPPLIER], // Default types for suppliers
        operationCodes: [], // Will be initialized depending on cash order type
        statuses: ['DONE', 'NEW'],
        context: STORAGE_DOCUMENT_CONTEXTS.STOCK,
        sumRemains: true
    },
    suppliersStoreDocsStats: {},
    fetchingSuppliersStoreDocs: false,
    selectedSupplierStoreDocId: undefined,

    selectedSupplierStoreDoc: {}, // Selected supplier store doc object
    fetchingSelectedSupplierStoreDoc: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_SUPPLIERS_STORE_DOCS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_SUPPLIERS_STORE_DOCS_FILTERS:
            return {
                ...state,
                suppliersStoreDocsFilters: {
                    ...state.suppliersStoreDocsFilters,
                    ...payload
                }
            };
        case SET_FETCHING_SUPPLIERS_STORE_DOCS:
            return {
                ...state,
                fetchingSuppliersStoreDocs: payload
            };

        case SET_SELECTED_SUPPLIER_STORE_DOC_ID:
            return {
                ...state,
                selectedSupplierStoreDocId: payload
            };
        case FETCH_SELECTED_SUPPLIER_STORE_DOC_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_FETCHING_SELECTED_SUPPLIER_STORE_DOC:
            return {
                ...state,
                fetchingSelectedSupplierStoreDoc: payload
            };
        case CLEAR_SUPPLIERS_STORE_DOCS_STATE:
            return ReducerState;

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectSuppliersStoreDocs = state =>
    state[mainModuleName][moduleName].suppliersStoreDocs;
export const selectSuppliersStoreDocsStats = state =>
    state[mainModuleName][moduleName].suppliersStoreDocsStats;
export const selectSuppliersStoreDocsFilters = state =>
    state[mainModuleName][moduleName].suppliersStoreDocsFilters;
export const selectFetchingSuppliersStoreDocs = state =>
    state[mainModuleName][moduleName].fetchingSuppliersStoreDocs;

export const selectSelectedSupplierStoreDocId = state =>
    state[mainModuleName][moduleName].selectedSupplierStoreDocId;

export const selectSelectedSupplierStoreDoc = state =>
    state[mainModuleName][moduleName].selectedSupplierStoreDoc;
export const selectFetchingSelectedSupplierStoreDoc = state =>
    state[mainModuleName][moduleName].fetchingSelectedSupplierStoreDoc;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchSuppliersStoreDocs = () => ({
    type: FETCH_SUPPLIERS_STORE_DOCS
});

/*
 * @param {*} fetchedData.suppliersStoreDocs
 */
export const fetchSuppliersStoreDocsSuccess = fetchedData => ({
    type: FETCH_SUPPLIERS_STORE_DOCS_SUCCESS,
    payload: fetchedData
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setSuppliersStoreDocsFilters = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_SUPPLIERS_STORE_DOCS_FILTERS,
            payload: filters
        });
        dispatch(fetchSuppliersStoreDocs());
    };
};

export const setSuppliersStoreDocsFiltersPoor = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_SUPPLIERS_STORE_DOCS_FILTERS,
            payload: filters
        });
    };
};

export const setFetchingSuppliersStoreDocs = value => ({
    type: SET_FETCHING_SUPPLIERS_STORE_DOCS,
    payload: value
});

export const fetchSelectedSupplierStoreDoc = () => ({
    type: FETCH_SELECTED_SUPPLIER_STORE_DOC
});

/*
 * @param {*} fetchedData.selectedSupplierStoreDoc
 */
export const fetchSelectedSupplierStoreDocSuccess = fetchedData => ({
    type: FETCH_SELECTED_SUPPLIER_STORE_DOC_SUCCESS,
    payload: fetchedData
});

export const setFetchingSelectedSupplierStoreDoc = value => ({
    type: SET_FETCHING_SELECTED_SUPPLIER_STORE_DOC,
    payload: value
});

/**
 * Set selected order and fetch it automatically.
 * Fetching is required if you set order that is not loaded in clients table.
 * If orderID is undefined, then buffer will be cleared (empty object)
 * @param {*} value - selected order ID
 */
export const setSelectedSupplierStoreDocId = value => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_SELECTED_SUPPLIER_STORE_DOC_ID,
            payload: value
        });

        if (value) {
            dispatch(fetchSelectedSupplierStoreDoc());
        } else {
            // Clear selected client buffer if id was undefined
            dispatch(fetchSelectedSupplierStoreDocSuccess({ selectedSupplierStoreDoc: {} }));
        }
    };
};

export const clearSuppliersStoreDocsState = () => ({
    type: CLEAR_SUPPLIERS_STORE_DOCS_STATE
});
