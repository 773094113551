import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, InputNumber, Slider, Switch, Table } from 'antd';
import { DataItem, Numeral } from 'commons';
import { DateRangePicker } from 'components';
import { setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, numeralFormatter, numeralParser } from 'utils';
import Styles from './styles.m.css';

const DATETIME_FORMAT = 'YYYY-MM-DD';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

/**
 * Table of inspection intervals
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BookRecommendationsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 25,
            recommendationsData: [],
            activeRecom: true,
            inactiveRecom: false
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='directories.orders' />,
                key: 'orderNum',
                dataIndex: 'orderNum',
                width: '40%',
                render: (data, row) => (
                    <div>
                        <div
                            className={Styles.orderLink}
                            onClick={() => {
                                this.props.getReport({
                                    link: `/orders/reports/completedWorkReport/${row.orderId}`,
                                    name: 'completedWorkReport',
                                    token: this.props.token
                                });
                            }}
                        >
                            {row.orderNum ? row.orderNum : ''}
                        </div>{' '}
                        <div>
                            {row.recommendationToDate ? dayjs(row.recommendationToDate).format('DD.MM.YYYY HH:mm') : ''}
                            {' -'}
                        </div>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'orderMillage',
                dataIndex: 'orderSuccessDatetime',
                align: 'center',
                width: '10%',
                render: (data, row) => {
                    return (
                        <div>
                            {row.orderSuccessDatetime ? dayjs(row.orderSuccessDatetime).format('DD.MM.YYYY HH:mm') : ''}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='client_order_tab.odometer' />,
                key: 'orderMillage',
                dataIndex: 'orderMillage',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='services_table.labor' />,
                key: 'defaultName',
                dataIndex: 'defaultName',
                align: 'center',
                width: '10%',
                sorter: (a, b) => a.defaultName && a.defaultName.localeCompare(b.defaultName),
                sortDirections: ['descend', 'ascend'],
                render: data => {
                    return <div>{data}</div>;
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'laborName',
                dataIndex: 'laborName',
                align: 'center',
                width: '10%',
                render: data => {
                    return <div>{data}</div>;
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'recommendationComment',
                dataIndex: 'recommendationComment',
                align: 'center',
                width: '10%',
                render: data => {
                    return <div>{data || '-'}</div>;
                }
            },
            {
                title: <FormattedMessage id='status' />,
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                width: '10%',
                render: (data, row) => {
                    return (
                        <div>
                            <Switch checked={row.recommendationStatus} disabled />
                        </div>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.setState({
            recommendationsData: this.props.recommendations,
            stats: this.props.recomStats
        });
    }

    handleSearch = async () => {
        const { recommendations, vehicleId, token } = this.props;
        const { searchInput, pageSize, page } = this.state;
        const filteredRecommendations = await fetchAPI(
            'GET',
            'recommendations/labors',
            { vehicleId, queryFilters: searchInput, page: 1, pageSize: 25 },
            undefined,
            {
                handleErrorInternally: true,
                headers: {
                    Authorization: token
                }
            }
        );

        this.setState({
            recommendationsData: filteredRecommendations.list || [],
            stats: filteredRecommendations.count.count
        });
    };

    handleSearchMobile = async () => {
        const { recommendations, vehicleId, token } = this.props;
        const { searchInput, startDate, endDate, mileageFrom, mileageTo } = this.state;
        const filteredRecommendations = await fetchAPI(
            'GET',
            'recommendations/labors',
            { vehicleId, queryFilters: searchInput, startDate, endDate, mileageFrom, mileageTo },
            undefined,
            {
                handleErrorInternally: true,
                headers: {
                    Authorization: token
                }
            }
        );

        this.setState({
            recommendationsData: filteredRecommendations.list || []
        });
    };

    fetchVehicleRecommendations = async () => {
        const { token, vehicleId } = this.props;
        const { page, pageSize } = this.state;
        const recommendations = await fetchAPI(
            'GET',
            'recommendations/labors',
            { vehicleId, page, pageSize },
            undefined,
            {
                handleErrorInternally: true,
                headers: {
                    Authorization: token
                }
            }
        );

        this.setState({
            recommendationsData: recommendations.list || [],
            stats: recommendations.count.count
        });
    };

    render() {
        const {
            intl: { formatMessage },
            recomStats,
            isMobile
        } = this.props;

        const {
            searchInput,
            pageSize,
            page,
            recommendationsData,
            stats,
            startDate,
            endDate,
            mileageFrom,
            mileageTo,
            activeRecom,
            inactiveRecom
        } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(Number(stats) / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchVehicleRecommendations();
            }
        };

        const activeFilterDataSource = recommendationsData.filter(({ recommendationStatus }) => recommendationStatus);
        const unactiveFilterDataSource = recommendationsData.filter(
            ({ recommendationStatus }) => !recommendationStatus
        );

        const mobileBlocksFilteredData = activeRecom
            ? activeFilterDataSource
            : inactiveRecom
            ? unactiveFilterDataSource
            : recommendationsData;

        return !isMobile ? (
            <div>
                <div
                    style={{
                        marginLeft: 8
                    }}
                >
                    <Input
                        onChange={event => {
                            this.setState({
                                searchInput: event.target.value
                            });
                        }}
                        placeholder={formatMessage({ id: 'online_service_book_page.search_by_order' })}
                        style={{
                            width: '60%'
                        }}
                        value={searchInput}
                    />
                    <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                            this.handleSearch();
                        }}
                    />
                    <Button
                        icon={<UndoOutlined />}
                        onClick={() => {
                            this.setState({
                                searchInput: undefined,
                                recommendationsData: this.props.recommendations,
                                stats: recomStats,
                                activeRecom: true,
                                inactiveRecom: false,
                                page: 1
                            });
                            setTimeout(() => this.handleSearch(), 100);
                        }}
                    />
                    <span
                        style={{
                            marginLeft: 8,
                            marginRight: 8
                        }}
                    >
                        <span
                            style={{
                                marginRight: 8
                            }}
                        >
                            <FormattedMessage id='online_service_book_page.recom_filters_active' />
                        </span>
                        <Checkbox
                            checked={activeRecom}
                            onChange={() => {
                                this.setState({
                                    activeRecom: !activeRecom,
                                    inactiveRecom: false
                                });
                            }}
                        />
                    </span>
                    <span>
                        <span
                            style={{
                                marginRight: 8
                            }}
                        >
                            <FormattedMessage id='online_service_book_page.recom_filters_inactive' />
                        </span>
                        <Checkbox
                            checked={inactiveRecom}
                            onChange={() => {
                                this.setState({
                                    inactiveRecom: !inactiveRecom,
                                    activeRecom: false
                                });
                            }}
                        />
                    </span>
                </div>
                <div className={Styles.tableCont}>
                    <Table
                        bordered
                        columns={this.columns()}
                        dataSource={
                            activeRecom
                                ? activeFilterDataSource
                                : inactiveRecom
                                ? unactiveFilterDataSource
                                : recommendationsData
                        }
                        pagination={pagination}
                        size='small'
                    />
                </div>
            </div>
        ) : (
            <div>
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <span
                        style={{
                            marginLeft: 8,
                            marginRight: 8
                        }}
                    >
                        <span
                            style={{
                                marginRight: 8
                            }}
                        >
                            <FormattedMessage id='online_service_book_page.recom_filters_active' />
                        </span>
                        <Checkbox
                            checked={activeRecom}
                            onChange={() => {
                                this.setState({
                                    activeRecom: !activeRecom,
                                    inactiveRecom: false
                                });
                            }}
                        />
                    </span>
                    <span>
                        <span
                            style={{
                                marginRight: 8
                            }}
                        >
                            <FormattedMessage id='online_service_book_page.recom_filters_inactive' />
                        </span>
                        <Checkbox
                            checked={inactiveRecom}
                            onChange={() => {
                                this.setState({
                                    inactiveRecom: !inactiveRecom,
                                    activeRecom: false
                                });
                            }}
                        />
                    </span>
                </div>
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={[startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined]}
                        onDateChange={async dateRange => {
                            await this.setState({
                                startDate: dayjs(dateRange[0]).format(DATETIME_FORMAT),
                                endDate: dayjs(dateRange[1]).format(DATETIME_FORMAT)
                            });
                        }}
                        popupStyle={{
                            maxHeight: 250,
                            overflow: 'auto',
                            zIndex: '9999',
                            maxWidth: 150
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className={Styles.filteredInput}>
                    <Input
                        onChange={event => {
                            this.setState({
                                searchInput: event.target.value
                            });
                        }}
                        placeholder={formatMessage({ id: 'online_service_book_page.search_by_order_history' })}
                        style={{
                            width: '100%'
                        }}
                        value={searchInput}
                    />
                </div>
                <div className={Styles.odometrBlock}>
                    <div className={Styles.titleOdometr}>
                        <FormattedMessage id='client_order_tab.odometer' />
                    </div>
                    <Slider
                        max={500000}
                        min={1}
                        onChange={([mileageFrom, mileageTo]) => {
                            this.setState({
                                mileageFrom,
                                mileageTo
                            });
                        }}
                        range
                        value={typeof mileageFrom === 'number' ? [mileageFrom, mileageTo] : [0, 1]}
                    />
                    <div className={Styles.inputNumberOdometr}>
                        <InputNumber
                            formatter={value => numeralFormatter(value)}
                            min={1}
                            onChange={mileageFrom => {
                                this.setState({ mileageFrom });
                            }}
                            parser={value => numeralParser(value)}
                            style={{
                                margin: '0 16px'
                            }}
                            value={Math.round(mileageFrom * 100) / 100 || 0}
                        />
                        <InputNumber
                            formatter={value => numeralFormatter(value)}
                            min={1}
                            onChange={mileageTo => {
                                this.setState({ mileageTo });
                            }}
                            parser={value => numeralParser(value)}
                            style={{
                                margin: '0 16px'
                            }}
                            value={Math.round(mileageTo * 100) / 100 || 0}
                        />
                    </div>
                </div>
                <div className={Styles.filterBtns}>
                    <Button
                        disabled={mileageFrom >= mileageTo}
                        onClick={() => {
                            this.handleSearchMobile();
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='search' />
                    </Button>
                    <Button
                        onClick={() => {
                            this.setState({
                                searchInput: undefined,
                                recommendationsData: this.props.recommendations,
                                startDate: undefined,
                                endDate: undefined,
                                mileageFrom: undefined,
                                mileageTo: undefined,
                                activeRecom: true,
                                inactiveRecom: false
                            });
                            setTimeout(() => this.handleSearchMobile(), 100);
                        }}
                    >
                        <FormattedMessage id='storage.reset_all_filters' />
                    </Button>
                </div>

                {mobileBlocksFilteredData.map(
                    ({
                        orderNum,
                        orderId,
                        recommendationStatus,
                        orderMillage,
                        recommendationToDate,
                        defaultName,
                        laborName
                    }) => (
                        <div className={Styles.firtBlockInfoStyle}>
                            <div className={Styles.dataItemBlock}>
                                <DataItem
                                    className={Styles.dataItemMobile}
                                    label={<FormattedMessage id='reports_page.orders' />}
                                >
                                    <div
                                        className={Styles.orderLink}
                                        onClick={() => {
                                            this.props.getReport({
                                                link: `/orders/reports/completedWorkReport/${orderId}`,
                                                name: 'completedWorkReport',
                                                token: this.props.token
                                            });
                                        }}
                                    >
                                        {orderNum}
                                    </div>
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItemMobile}
                                    label={<FormattedMessage id='orders.status' />}
                                >
                                    {recommendationStatus ? (
                                        <span
                                            style={{
                                                color: 'green'
                                            }}
                                        >
                                            <FormattedMessage id='wms.active' />
                                        </span>
                                    ) : (
                                        <span
                                            style={{
                                                color: 'red'
                                            }}
                                        >
                                            <FormattedMessage id='workshop_table.INACTIVE' />{' '}
                                        </span>
                                    )}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItemMobile}
                                    label={<FormattedMessage id='client_order_tab.odometer' />}
                                >
                                    <Numeral mask='0,0'>{Number(orderMillage) || '-'}</Numeral>{' '}
                                    <FormattedMessage id='online_service_book_page.km' />
                                </DataItem>
                            </div>
                            <div className={Styles.dataItemBlock}>
                                <DataItem
                                    className={Styles.dataItemMobile}
                                    label={<FormattedMessage id='online_service_book_page.recom_add_date' />}
                                >
                                    {dayjs(recommendationToDate).format(DATETIME_FORMAT) || '-'}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItemMobile}
                                    label={<FormattedMessage id='services_table.labor' />}
                                >
                                    {defaultName || '-'}
                                </DataItem>
                                <DataItem
                                    className={Styles.dataItemMobile}
                                    label={<FormattedMessage id='description' />}
                                >
                                    {laborName || '-'}
                                </DataItem>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}
