/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'supplierPage';
const prefix = `cpb/${moduleName}`;

export const FETCH_STORE_DOCS = `${prefix}/FETCH_STORE_DOCS`;
export const FETCH_STORE_DOCS_SUCCESS = `${prefix}/FETCH_STORE_DOCS_SUCCESS`;
export const SET_STORE_DOCS_FILTERS = `${prefix}/SET_STORE_DOCS_FILTERS`;
export const SET_FETCHING_STORE_DOCS = `${prefix}/SET_FETCHING_STORE_DOCS`;

export const FETCH_CASH_ORDERS = `${prefix}/FETCH_CASH_ORDERS`;
export const FETCH_CASH_ORDERS_SUCCESS = `${prefix}/FETCH_CASH_ORDERS_SUCCESS`;
export const SET_CASH_ORDERS_FILTERS = `${prefix}/SET_CASH_ORDERS_FILTERS`;
export const SET_FETCHING_CASH_ORDERS = `${prefix}/SET_FETCHING_CASH_ORDERS`;

export const FETCH_STORE_DOCS_DELIVERY = `${prefix}/FETCH_STORE_DOCS_DELIVERY`;
export const FETCH_STORE_DOCS_DELIVERY_SUCCESS = `${prefix}/FETCH_STORE_DOCS_DELIVERY_SUCCESS`;
export const SET_STORE_DOCS_DELIVERY_FILTERS = `${prefix}/SET_STORE_DOCS_DELIVERY_FILTERS`;
export const SET_FETCHING_STORE_DOCS_DELIVERY = `${prefix}/SET_FETCHING_STORE_DOCS_DELIVERY`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    storeDocs: [],
    storeDocsFilters: {
        createdFromDate: undefined,
        createdToDate: undefined,
        page: 1
    },
    storeDocsStats: {},
    fetchingStoreDocs: false,

    cashOrders: undefined,
    cashOrdersFilters: {
        createdFromDate: undefined,
        createdToDate: undefined,
        page: 1
    },
    cashOrdersStats: {},
    fetchingCashOrders: false,

    storeDocsDelivery: undefined,
    storeDocsDeliveryFilters: {
        documentType: 'SUPPLIER',
        context: 'STOCK',
        page: 1
    },
    storeDocsDeliveryStats: {},
    fetchingStoreDocsDelivery: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_STORE_DOCS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_STORE_DOCS_FILTERS:
            return {
                ...state,
                storeDocsFilters: {
                    ...state.storeDocsFilters,
                    ...payload
                }
            };
        case SET_FETCHING_STORE_DOCS:
            return {
                ...state,
                fetchingStoreDocs: payload
            };

        /* cash orders fetch */
        case FETCH_CASH_ORDERS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_CASH_ORDERS_FILTERS:
            return {
                ...state,
                cashOrdersFilters: {
                    ...state.cashOrdersFilters,
                    ...payload
                }
            };
        case SET_FETCHING_CASH_ORDERS:
            return {
                ...state,
                fetchingCashOrders: payload
            };

        /* store docs delivery */
        case FETCH_STORE_DOCS_DELIVERY_SUCCESS:
            return {
                ...state,
                ...payload
            };

        case SET_STORE_DOCS_DELIVERY_FILTERS:
            return {
                ...state,
                storeDocsDeliveryFilters: {
                    ...state.storeDocsDeliveryFilters,
                    ...payload
                }
            };

        case SET_FETCHING_STORE_DOCS_DELIVERY:
            return {
                ...state,
                fetchingStoreDocsDelivery: payload
            };

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectStoreDocs = state => state[moduleName].storeDocs;
export const selectStoreDocsStats = state => state[moduleName].storeDocsStats;
export const selectStoreDocsFilters = state => state[moduleName].storeDocsFilters;
export const selectFetchingStoreDocs = state => state[moduleName].fetchingStoreDocs;

export const selectCashOrders = state => state[moduleName].cashOrders;
export const selectCashOrdersStats = state => state[moduleName].cashOrdersStats;
export const selectCashOrdersFilters = state => state[moduleName].cashOrdersFilters;
export const selectFetchingCashOrders = state => state[moduleName].fetchingCashOrders;

export const selectStoreDocsDelivery = state => state[moduleName].storeDocsDelivery;
export const selectStoreDocsDeliveryStats = state => state[moduleName].storeDocsDeliveryStats;
export const selectStoreDocsDeliveryFilters = state => state[moduleName].storeDocsDeliveryFilters;
export const selectFetchingStoreDocsDelivery = state => state[moduleName].fetchingStoreDocsDelivery;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchStoreDocs = () => ({
    type: FETCH_STORE_DOCS
});

export const fetchStoreDocsSuccess = ({ storeDocs, storeDocsStats }) => ({
    type: FETCH_STORE_DOCS_SUCCESS,
    payload: { storeDocs, storeDocsStats }
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setStoreDocsFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_STORE_DOCS_FILTERS,
            payload: filters
        });
        dispatch(fetchStoreDocs());
    };
};

export const setFetchingStoreDocs = value => ({
    type: SET_FETCHING_STORE_DOCS,
    payload: value
});

export const fetchCashOrders = () => ({
    type: FETCH_CASH_ORDERS
});

export const fetchCashOrdersSuccess = payload => ({
    type: FETCH_CASH_ORDERS_SUCCESS,
    payload
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setCashOrdersFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_CASH_ORDERS_FILTERS,
            payload: filters
        });
        dispatch(fetchCashOrders());
    };
};

export const setFetchingCashOrders = value => ({
    type: SET_FETCHING_CASH_ORDERS,
    payload: value
});

/* store docs delivery */
export const fetchStoreDocsDelivery = () => ({
    type: FETCH_STORE_DOCS_DELIVERY
});

export const fetchStoreDocsDeliverySuccess = payload => ({
    type: FETCH_STORE_DOCS_DELIVERY_SUCCESS,
    payload
});

export const setStoreDocsDeliveryFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_STORE_DOCS_DELIVERY_FILTERS,
            payload: filters
        });
        dispatch(fetchStoreDocsDelivery());
    };
};

export const setFetchingStoreDocsDelivery = value => ({
    type: SET_FETCHING_STORE_DOCS_DELIVERY,
    payload: value
});
