import {
    CameraOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined
} from '@ant-design/icons';
import { Button, Flex, Upload, notification } from 'antd';
import { CommentaryButton } from 'modals';
import React from 'react';

export const statusDisplay = [
    {
        status: 1,
        color: '#44982ba6',
        icon: CheckCircleOutlined
    },
    {
        status: 3,
        color: '#e72c1c78',
        icon: CloseCircleOutlined
    },
    {
        status: 2,
        color: '#fdc50070',
        icon: ExclamationCircleOutlined
    },
    {
        status: 4,
        color: '#cccccc',
        icon: MinusCircleOutlined
    }
];

export const statusDisplayMap = Object.fromEntries(statusDisplay.map(({ status, ...rest }) => [status, rest]));

const StatusButtons = ({
    putOrderScheme,
    isSelected,
    orderUnit,
    unit,
    downloadPhoto,
    formatMessage,
    disabledPhoto
}) => {
    const handleComment = (comment, positions, problems) =>
        isSelected
            ? putOrderScheme(orderUnit, { comment: { comment, positions, problems }, id: orderUnit.id }, 'PUT')
            : putOrderScheme(unit, { comment: { comment, positions, problems }, status: 2 });

    console.log(unit, 'nuit');

    return (
        <Flex gap={8} justify='center' style={{ width: '100%' }}>
            {statusDisplay.map(({ status, color, icon: Icon }) => {
                return (
                    <Button
                        key={status}
                        icon={<Icon style={{ ...(status === orderUnit.status ? { color } : {}) }} />}
                        onClick={() =>
                            isSelected
                                ? putOrderScheme(orderUnit, { status, id: orderUnit.id }, 'PUT')
                                : putOrderScheme(unit, { status })
                        }
                    />
                );
            })}
            <Upload
                action={elem => downloadPhoto(elem, unit.id, unit.name)}
                beforeUpload={file => {
                    const isPNG = file.type === 'image/png';
                    const isJPG = file.type === 'image/jpeg';
                    if (!isPNG && !isJPG) {
                        notification.error({
                            message: `${file.name} ${formatMessage({
                                id: 'photo_modal.is_not_an_image'
                            })}!`
                        });
                    }

                    return isPNG || isJPG;
                }}
                disabled={disabledPhoto}
                showUploadList={false}
            >
                <Button disabled={disabledPhoto} icon={<CameraOutlined />} />
            </Upload>
            <CommentaryButton
                commentary={
                    isSelected
                        ? orderUnit.comment || {
                              comment: undefined,
                              positions: [],
                              problems: []
                          }
                        : { comment: `${unit.name} - ${unit.comment}`, positions: unit.commentArray } || {
                              comment: undefined,
                              positions: [],
                              problems: []
                          }
                }
                detail={isSelected ? orderUnit.unitName : unit.name}
                setComment={handleComment}
            />
        </Flex>
    );
};

export default StatusButtons;
