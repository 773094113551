import _ from 'lodash';
import numeral from 'numeral';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    Bar as BarColumn,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Surface,
    Symbols,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import Styles from './styles.m.css';

@injectIntl
export default class Bar extends Component {
    static defaultProps = {
        width: '100%',
        height: 300
    };

    state = {
        active: ''
    };

    handleClick = e => {
        const dataKey = e.target.innerText;
        this.setState({ active: dataKey });
    };

    render() {
        const { width, height, data, config } = this.props;

        return data ? (
            <ResponsiveContainer height={height} width={width}>
                <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='id' />
                    <YAxis tickFormatter={value => numeral(value).format('0,0[]00')} />
                    <Tooltip />
                    <Legend
                        content={this._renderLegend}
                        iconType='rect'
                        onClick={this.handleClick}
                        // payload={ [{ value: 'item id', type: 'line', id: 'ID01' }] }
                    />
                    {_(config.dataKeys)
                        .map(dataKey => (
                            <BarColumn
                                key={dataKey.name}
                                dataKey={dataKey.name}
                                fill={dataKey.color}
                            />
                        ))
                        .value()}
                </BarChart>
            </ResponsiveContainer>
        ) : null;
    }

    _renderLegend = props => {
        const { payload } = props;
        const { intlCtx } = this.props;
        const { active } = this.state;

        return (
            <ul className={Styles.legend}>
                {payload.map(({ dataKey, value, color }, index) => {
                    return (
                        <li
                            key={`bar-${index}`}
                            style={
                                dataKey === active ? { color: 'black' } : { color }
                                // ? { color: fill }
                                // : { color: 'black' }
                            }
                        >
                            <Surface
                                height={10}
                                onClick={this.handleClick}
                                // viewBox='0 0 10 10'
                                viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
                                width={10}
                            >
                                <Symbols
                                    cx={5}
                                    cy={5}
                                    fill={color}
                                    onClick={this.handleClick}
                                    size={50}
                                    type='square'
                                />
                            </Surface>
                            <FormattedMessage id={`${intlCtx}.${_.snakeCase(value)}`} />
                        </li>
                    );
                })}
            </ul>
        );
    };
}
