import { Button, Form, Input, Select } from 'antd';
import { onChangePackageForm } from 'core/forms/editPackageForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getGroupsLabels, getPermissionsLabels, groupedPermissions, permissions } from 'utils';

const { OptGroup } = Select;
const { Option } = Select;

const mapDispatchToProps = {
    onChangePackageForm
};

@connect(null, mapDispatchToProps)
@injectIntl
export class PackageForm extends Component {
    _submit = values => {
        const { editPackageId, updatePackage } = this.props;
        updatePackage(editPackageId, values);
    };

    render() {
        const { initPackageName, initPackage, roles } = this.props;

        const packageGrants = _.intersection(initPackage.grants, _.values(permissions));
        const groupsLabels = getGroupsLabels(this.props.intl);
        const permissionsLabels = getPermissionsLabels(this.props.intl);
        const roleIds = _.map(initPackage.roles, 'roleId');

        return (
            <Form
                initialValues={{
                    name: initPackageName,
                    grants: packageGrants,
                    roles: roleIds
                }}
                layout='vertical'
                name='packageForm'
                onFinish={this._submit}
            >
                <Form.Item
                    label={<FormattedMessage id='edit-package-form.name_field' />}
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'edit-package-form.name_field_required'
                            })
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='edit-package-form.grants_field' />}
                    name='grants'
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                >
                    <Select getPopupContainer={trigger => trigger.parentNode} mode='multiple'>
                        {_.toPairs(groupedPermissions).map(([name, value]) => (
                            <OptGroup label={groupsLabels[name]}>
                                {value.map(value => (
                                    <Option key={value} value={value}>
                                        {permissionsLabels[value]}
                                    </Option>
                                ))}
                            </OptGroup>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='edit-package-form.roles_field' />}
                    name='roles'
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                >
                    <Select getPopupContainer={trigger => trigger.parentNode} mode='multiple'>
                        {roles.map(({ name, roleId }) => (
                            <Option key={roleId} value={roleId}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' style={{ width: '100%' }} type='primary'>
                        <FormattedMessage id='edit-package-form.edit' />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
