/* eslint-disable no-underscore-dangle */
/* eslint-disable complexity */

import {
    CreditCardOutlined,
    DashboardOutlined,
    RedoOutlined,
    WalletOutlined
} from '@ant-design/icons';
import { DatePicker, Form, Input, Select, Slider, TimePicker } from 'antd';
import classNames from 'classnames/bind';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import book from 'routes/book';
import {
    addDuration,
    getDateTimeConfig,
    goTo,
    isForbidden,
    mergeDateTime,
    permissions
} from 'utils';
import { formHeaderItemLayout } from '../layouts';
import Styles from './styles.m.css';

const FormItem = Form.Item;
const { Option } = Select;

const cx = classNames.bind(Styles);

const getDisabledHours = (startTime = 0, endTime = 23) => {
    const availableHours = [];
    for (let i = Number(startTime); i <= Number(endTime); i++) {
        availableHours.push(i);
    }

    return _.difference(
        Array(24)
            .fill(1)
            .map((value, index) => index),
        availableHours
    );
};

@injectIntl
export default class OrderFormHeader extends Component {
    constructor(props) {
        super(props);

        const {
            intl: { formatMessage }
        } = props;

        // reusable validation rule
        this.requiredRule = [
            {
                required: true,
                message: formatMessage({
                    id: 'required_field'
                })
            }
        ];

        const stationsOptions = this._getStationsOptions();
        const managersOptions = this._getManagersOptions();
        const employeesOptions = this._getEmployeesOptions();

        const paymentMethodOptions = [
            <Option key='cash' value='cash'>
                <WalletOutlined style={{ marginRight: 4 }} />
                <FormattedMessage id='add_order_form.cash' />
            </Option>,
            <Option key='noncash' value='noncash'>
                <CreditCardOutlined style={{ marginRight: 4 }} />
                <FormattedMessage id='add_order_form.non-cash' />
            </Option>,
            <Option key='visa' value='visa'>
                <CreditCardOutlined style={{ marginRight: 4 }} />
                <FormattedMessage id='add_order_form.visa' />
            </Option>
        ];
        // TODO: move into utils
        // <FormatMessage id=''> triggers re-render cuz it is creating new obj
        // use formatMassage({id: }) instead
        this._localizationMap = {};

        const deliveryDatetimeConfig = this._getDeliveryDatetimeConfig();
        const beginDatetimeConfig = this._getBeginDatetimeConfig();
        // we write all data to state to handle updates correctly
        this.state = {
            deliveryDatetimeConfig,
            beginDatetimeConfig,
            stationsOptions,
            employeesOptions,
            managersOptions,
            paymentMethodOptions
        };

        this.stationRef = React.createRef();
        this.employeeRef = React.createRef();
        this.requisitesRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.stations !== this.props.stations) {
            const stationsOptions = this._getStationsOptions();
            this.setState({ stationsOptions });
        }

        if (prevProps.managers !== this.props.managers) {
            const managersOptions = this._getManagersOptions();
            this.setState({ managersOptions });
        }

        if (prevProps.employees !== this.props.employees) {
            const employeesOptions = this._getEmployeesOptions();
            this.setState({ employeesOptions });
        }
        // check all fields related for deliveryDatetime
        const deliveryFields = [
            'schedule',
            'zeroStationLoadBeginDate',
            'zeroStationLoadBeginTime',
            'zeroStationLoadDuration',
            'deliveryDate'
        ];
        // check deliveryDatetime depended properties changes
        // if dayjs -> toISOString to check dayjs objects as strings to prevent re-renders
        const deliveryConfigUpdate = deliveryFields.reduce((prev, cur) => {
            const parsedThisProps = dayjs.isDayjs(this.props[cur])
                ? this.props[cur].toISOString()
                : this.props[cur];
            const parsedPrevProps = dayjs.isDayjs(prevProps[cur])
                ? prevProps[cur].toISOString()
                : prevProps[cur];

            return prev || parsedThisProps !== parsedPrevProps;
        }, false);
        // if deliveryDatetime fields have been updated
        // get new config and set it to local state to trigger componentUpdate with new config
        if (deliveryConfigUpdate) {
            this.setState({
                deliveryDatetimeConfig: this._getDeliveryDatetimeConfig()
            });
        }
        // update check for beginDatetime
        const currentZeroStationLoadBeginDate = this.props.zeroStationLoadBeginDate
            ? this.props.zeroStationLoadBeginDate.toISOString()
            : void 0;
        const prevZeroStationLoadBeginDate = prevProps.zeroStationLoadBeginDate
            ? prevProps.zeroStationLoadBeginDate.toISOString()
            : void 0;

        if (
            this.props.schedule !== prevProps.schedule ||
            currentZeroStationLoadBeginDate !== prevZeroStationLoadBeginDate
        ) {
            this.setState({
                beginDatetimeConfig: this._getBeginDatetimeConfig()
            });
        }
    }

    // TODO: move into utils
    bodyUpdateIsForbidden() {
        return isForbidden(this.props.user, permissions.ACCESS_ORDER_BODY);
    }

    _getBeginDatetimeConfig() {
        const { schedule } = this.props;
        const { disabledDate } = getDateTimeConfig(void 0, schedule);
        const { beginTime } =
            getDateTimeConfig(void 0, schedule) ||
            dayjs()
                .add(30 - (dayjs().minute() % 30), 'minutes')
                .format('YYYY-MM-DD HH:00');

        return {
            disabledDate,
            beginTime
        };
    }

    _getDeliveryDatetimeConfig() {
        const {
            schedule,
            zeroStationLoadBeginDate,
            zeroStationLoadBeginTime,
            zeroStationLoadDuration,
            deliveryDate
        } = this.props;

        const excludeConfig = [
            {
                momentDate: zeroStationLoadBeginDate,
                momentTime: zeroStationLoadBeginTime,
                duration: zeroStationLoadDuration
            }
        ];

        const {
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            disabledDate: dateTimeDisabledDate,
            beginTime
        } = getDateTimeConfig(dayjs(deliveryDate), schedule, excludeConfig);

        const initialBeginDatetime = dayjs(zeroStationLoadBeginDate).set({
            hours: 0,
            minutes: 0,
            milliseconds: 0,
            seconds: 0
        });

        const sameOfBeforeDisabledDate = date =>
            dateTimeDisabledDate(date) || (date && date.isSameOrBefore(initialBeginDatetime));

        const initialDeliveryDatetime =
            zeroStationLoadBeginDate && zeroStationLoadBeginTime && zeroStationLoadDuration
                ? addDuration(
                      mergeDateTime(zeroStationLoadBeginDate, zeroStationLoadBeginTime),
                      zeroStationLoadDuration
                  )
                : void 0;

        return {
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            disabledDate: sameOfBeforeDisabledDate,
            beginTime,
            initialDeliveryDatetime
        };
    }

    // prevent re-renders
    _getLocalization(key) {
        if (!this._localizationMap[key]) {
            this._localizationMap[key] = this.props.intl.formatMessage({
                id: key
            });
        }

        return this._localizationMap[key];
    }

    _getStationsOptions = () => {
        return _.get(this.props, 'stations', []).map(({ name, num }) => {
            return (
                <Option key={String(num)} value={num}>
                    {name || String(num)}
                </Option>
            );
        });
    };

    _getManagersOptions = () => {
        return _.get(this.props, 'managers', []).map(manager => (
            <Option key={`manager-${manager.id}`} disabled={manager.disabled} value={manager.id}>
                {`${manager.managerSurname} ${manager.managerName}`}
            </Option>
        ));
    };

    _getEmployeesOptions = () => {
        return _.get(this.props, 'employees', []).map(employee => {
            if (!employee.disabled) {
                return (
                    <Option
                        key={`employee-${employee.id}`}
                        disabled={employee.disabled}
                        value={employee.id}
                    >
                        {`${employee.surname} ${employee.name}`}
                    </Option>
                );
            }
        });
    };

    _redirectToCashFlow = () => {
        if (!isForbidden(this.props.user, permissions.ACCESS_ACCOUNTING)) {
            goTo(book.cashFlowPage, {
                cashFlowFilters: { ...this.props.cashFlowFilters }
            });
        }
    };

    _totalStyles = disabled =>
        cx({
            totalDisabled: disabled,
            total: true
        });

    render() {
        // TODO: decomposite for separate view components
        const dateBlock = this._renderDateBlock();
        const masterBlock = this._renderMasterBlock();
        const totalBlock = this._renderTotalBlock();

        return (
            <div className={Styles.formHeader} id='OrderFormHeader'>
                <div className={Styles.headerColumns}>
                    {dateBlock} {masterBlock} {totalBlock}
                </div>
            </div>
        );
    }

    _renderDateBlock = () => {
        const { location, fetchedOrder, schedule, fields, user } = this.props;
        const { formatMessage } = this.props.intl;

        const beginDatetime =
            _.get(fetchedOrder, 'order.beginDatetime') ||
            (this.bodyUpdateIsForbidden() ? void 0 : _.get(location, 'state.beginDatetime'));

        const momentBeginDatetime = beginDatetime ? dayjs(beginDatetime) : void 0;

        const dayNumber = dayjs(_.get(fields, 'stationLoads[0].beginDate')).day();
        let disabledHours;
        if (schedule && dayNumber) {
            let index;
            switch (dayNumber) {
                case 6:
                    index = 1;
                    break;
                case 7:
                    index = 2;
                    break;
                default:
                    index = 0;
            }

            disabledHours = getDisabledHours(
                schedule[index] && schedule[index].beginTime
                    ? schedule[index].beginTime.split(/[.:]/)[0]
                    : 9,
                schedule[index] && schedule[index].endTime
                    ? schedule[index].endTime.split(/[.:]/)[0]
                    : 20
            );
        }

        return (
            <div className={Styles.headerCol}>
                <Form.Item
                    initialValue={momentBeginDatetime}
                    label={this._getLocalization('add_order_form.enrollment_date')}
                    name={['stationLoads', 0, 'beginDate']}
                    {...formHeaderItemLayout}
                >
                    <DatePicker
                        allowClear={false}
                        className={Styles.datePanelItem}
                        disabled={this.bodyUpdateIsForbidden()}
                        format='YYYY-MM-DD'
                        placeholder={this._getLocalization('add_order_form.select_date')}
                        showTime={false}
                    />
                </Form.Item>
                <Form.Item
                    initialValue={
                        _.get(fetchedOrder, 'order.stationNum', undefined) ||
                        _.get(location, 'state.stationNum', undefined) ||
                        undefined
                    }
                    label={
                        <span>
                            {this._getLocalization('add_order_form.station')}
                            <DashboardOutlined
                                onClick={() => {
                                    this.props.setModal(this.props.modals.DASHBOARD);
                                }}
                                style={{
                                    padding: '0 0 0 8px',
                                    color: 'var(--primary)',
                                    fontSize: 18
                                }}
                                title={formatMessage({ id: 'navigation.planner' })}
                            />
                        </span>
                    }
                    name={['stationLoads', 0, 'station']}
                    {...formHeaderItemLayout}
                >
                    <Select
                        ref={this.stationRef}
                        className={Styles.datePanelItem}
                        disabled={
                            this.bodyUpdateIsForbidden() ||
                            isForbidden(user, permissions.ACCESS_ORDER_POSTS)
                        }
                        optionFilterProp='children'
                        placeholder={this._getLocalization('add_order_form.select_station')}
                        showAction={['focus', 'click']}
                        showSearch
                    >
                        {this.state.stationsOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    initialValue={momentBeginDatetime}
                    label={this._getLocalization('add_order_form.applied_on')}
                    name={['stationLoads', 0, 'beginTime']}
                    rules={this.requiredRule}
                    {...formHeaderItemLayout}
                >
                    <TimePicker
                        allowClear={false}
                        className={Styles.datePanelItem}
                        disabled={this.bodyUpdateIsForbidden()}
                        disabledHours={() => disabledHours}
                        format='H:mm'
                        hideDisabledOptions
                        minuteStep={30}
                        placeholder={this._getLocalization('add_order_form.provide_time')}
                    />
                </Form.Item>
            </div>
        );
    };

    _renderMasterBlock = () => {
        // _renderDuration
        const {
            fetchedOrder,
            managers,
            authentificatedManager,
            fields,
            errors,
            location,
            totalHours,
            zeroStationLoadDuration,
            user
        } = this.props;

        const isOwnBusiness =
            _.find(managers, {
                id: authentificatedManager
            }) || void 0;

        const { getFieldDecorator } = this.props.form;

        return (
            <div className={Styles.headerCol}>
                <Form.Item
                    initialValue={_.get(fetchedOrder, 'order.managerId') || _.get(user, 'id')}
                    label={this._getLocalization('add_order_form.manager')}
                    name='manager'
                    rules={this.requiredRule}
                    {...formHeaderItemLayout}
                >
                    <Select
                        className={Styles.datePanelItem}
                        disabled={this.bodyUpdateIsForbidden()}
                        optionFilterProp='children'
                        placeholder={this._getLocalization('add_order_form.select_manager')}
                        showSearch
                    >
                        {this.state.managersOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    initialValue={
                        _.get(fetchedOrder, 'order.employeeId') ||
                        (location.state && location.state.employeeId) ||
                        undefined
                    }
                    label={this._getLocalization('order_form_table.master')}
                    name='employee'
                    {...formHeaderItemLayout}
                >
                    <Select
                        ref={this.employeeRef}
                        className={Styles.durationPanelItem}
                        disabled={this.bodyUpdateIsForbidden()}
                        optionFilterProp='children'
                        placeholder={this._getLocalization('order_form_table.select_master')}
                        showAction={['focus', 'click']}
                        showSearch
                    >
                        {this.state.employeesOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    initialValue={
                        _.get(fields, 'stationLoads[0].duration') ||
                        _.get(fetchedOrder, 'order.duration') ||
                        totalHours
                    }
                    label={
                        <React.Fragment>
                            <span>
                                {`${this._getLocalization('time')} (${
                                    zeroStationLoadDuration || 0.5
                                }${this._getLocalization('add_order_form.hours_shortcut')})`}
                            </span>
                            <span style={{ marginLeft: 10 }}>
                                <RedoOutlined
                                    className={Styles.updateDurationIcon}
                                    onClick={() => this.props.updateOrderField('duration')}
                                    title={this.props.intl.formatMessage({
                                        id: 'duration.recalculate'
                                    })}
                                />
                            </span>
                        </React.Fragment>
                    }
                    name={['stationLoads', 0, 'duration']}
                    {...formHeaderItemLayout}
                >
                    <Slider
                        className={Styles.datePanelItem}
                        disabled={this.bodyUpdateIsForbidden()}
                        max={8}
                        min={0}
                        step={0.5}
                    />
                </Form.Item>
                <Form.Item hidden initialValue='TO_DO' name={['stationLoads', 0, 'status']}>
                    <Input />
                </Form.Item>
            </div>
        );
    };

    _renderTotalBlock = () => {
        const { fetchedOrder, fields } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { errors, totalPrice, cashSum, remainPrice, totalSumWithTax, isTaxPayer } =
            this.props;
        const mask = '0,0.00';

        return (
            <div className={Styles.headerCol}>
                <FormItem className={Styles.sumBlock}>
                    <div className={Styles.sum}>
                        <span
                            className={Styles.sumWrapper}
                            data-qa='tire_fitting_numeral_total_sum_order_page'
                        >
                            <FormattedMessage id='sum' />
                            <Numeral
                                className={Styles.sumNumeral}
                                currency={this.props.intl.formatMessage({
                                    id: 'currency'
                                })}
                                mask={mask}
                                nullText='0'
                            >
                                {totalPrice}
                            </Numeral>
                        </span>
                        {isTaxPayer && (
                            <span
                                className={Styles.sumWrapper}
                                data-qa='tire_fitting_numeral_total_sum_with_tax_order_page'
                            >
                                <FormattedMessage id='with' /> <FormattedMessage id='VAT' />
                                <Numeral
                                    className={Styles.sumNumeral}
                                    currency={this.props.intl.formatMessage({
                                        id: 'currency'
                                    })}
                                    mask={mask}
                                    nullText='0'
                                >
                                    {totalSumWithTax}
                                </Numeral>
                            </span>
                        )}
                        <span
                            className={Styles.sumWrapper}
                            data-qa='tire_fitting_numaral_cash_sum_order_page'
                        >
                            <FormattedMessage id='paid' />
                            <Numeral
                                className={Styles.sumNumeral}
                                currency={this.props.intl.formatMessage({
                                    id: 'currency'
                                })}
                                mask={mask}
                                nullText='0'
                            >
                                {cashSum}
                            </Numeral>
                        </span>
                    </div>
                    <div
                        className={this._totalStyles(
                            isForbidden(this.props.user, permissions.ACCESS_ACCOUNTING)
                        )}
                        data-qa='tire_fitting_numeral_remain_price_order_page'
                        onClick={() => this._redirectToCashFlow()}
                    >
                        <FormattedMessage id='remain' />
                        <Numeral
                            className={Styles.totalSum}
                            currency={this.props.intl.formatMessage({
                                id: 'currency'
                            })}
                            mask={mask}
                            nullText='0'
                        >
                            {remainPrice || 0}
                        </Numeral>
                    </div>
                </FormItem>
                <Form.Item
                    initialValue={_.get(fetchedOrder, 'order.paymentMethod')}
                    label={this._getLocalization('add_order_form.payment_method')}
                    name='paymentMethod'
                    placeholder={this._getLocalization('add_order_form.select_payment_method')}
                    {...formHeaderItemLayout}
                >
                    <Select
                        disabled={this.bodyUpdateIsForbidden()}
                        placeholder={this._getLocalization('add_order_form.select_payment_method')}
                    >
                        {this.state.paymentMethodOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    initialValue={_.get(fetchedOrder, 'order.replacementType')}
                    label={this._getLocalization('add_order_form.replacement_type')}
                    name='replacementType'
                    placeholder={this._getLocalization('add_order_form.select_payment_method')}
                    {...formHeaderItemLayout}
                >
                    <Select
                        disabled={this.bodyUpdateIsForbidden()}
                        placeholder={this._getLocalization(
                            'add_order_form.select_replacement_type'
                        )}
                    >
                        <Option value='BOUGHT_IN_AUTODOC'>
                            <FormattedMessage id='add_order_form.replacement_type.BOUGHT_IN_AUTODOC' />
                        </Option>
                        <Option value='BROUGHT_BY_ONESELF'>
                            <FormattedMessage id='add_order_form.replacement_type.BROUGHT_BY_ONESELF' />
                        </Option>
                        <Option value='STORAGE'>
                            <FormattedMessage id='add_order_form.replacement_type.STORAGE' />
                        </Option>
                    </Select>
                </Form.Item>
            </div>
        );
    };
}
