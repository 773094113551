import dayjs from 'dayjs';
import _ from 'lodash';

const mapOrders = (beginHour, maxRows, orders) =>
    _(orders)
        .filter('beginDatetime')
        .map(order => {
            const { duration, beginDatetime } = order;
            const numberOfHalfHours = Math.ceil((duration || 0.5) * 2);

            const momentBeginDatetime = dayjs(beginDatetime);

            const beginHours = momentBeginDatetime.hour();
            const beginMinutes =
                momentBeginDatetime.minute() < 10
                    ? `0${momentBeginDatetime.minute()}`
                    : momentBeginDatetime.minute();

            const openHalfHoursQuantity =
                dayjs
                    .duration({
                        hours: beginHour
                    })
                    .asHours() * 2;
            const orderBeginHalfHoursQuantity =
                dayjs
                    .duration({
                        minutes: beginMinutes,
                        hours: beginHours
                    })
                    .asHours() * 2;

            const startHalfHour = Math.ceil(orderBeginHalfHoursQuantity - openHalfHoursQuantity);

            let quantity = numberOfHalfHours;
            let position = startHalfHour;

            if (startHalfHour < 0) {
                if (startHalfHour + numberOfHalfHours < 1) {
                    return null;
                }

                position = 0;
                quantity = startHalfHour + numberOfHalfHours;
            }

            if (startHalfHour + numberOfHalfHours >= maxRows) {
                if (startHalfHour >= maxRows) {
                    return null;
                }

                quantity = maxRows - startHalfHour;
            }

            return { position, quantity, options: order };
        })
        .filter(Boolean)
        .value();

export default mapOrders;
