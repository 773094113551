import { CloseOutlined, CopyOutlined, DeleteOutlined, DollarOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import { MobileView, ResponsiveView, Spinner } from 'commons';
import { ChangeStatusDropdown, ReportsDropdown } from 'components';
import { fetchAddClientForm } from 'core/forms/addClientForm/duck';
import {
    createInviteOrder,
    createOrderCopy,
    fetchOrderForm,
    returnToOrdersPage,
    // fetchOrderTask,
    selectInviteData,
    updateOrder
} from 'core/forms/orderForm/duck';
import { changeModalStatus, resetOrderTasksForm, saveOrderTask } from 'core/forms/orderTaskForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { fetchReport, getReport } from 'core/order/duck';
import { setCollapsedState } from 'core/ui/duck';
import { fetchVehicleTypes } from 'core/vehicleTypes/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
    CancelReasonModal,
    ConfirmOrderExitModal,
    OrderTaskModal,
    RefactoredCashOrderModal,
    StoreProductModal
} from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { Layout, MobileRecordForm, OrderForm } from 'tireFitting';
import { convertFieldsValuesToDbEntity } from 'tireFitting/forms/OrderForm/extractOrderEntity';
import { BREAKPOINTS, fetchAPI, isForbidden, permissions, roundCurrentTime, withErrorMessage } from 'utils';
import { CheckIsOrderSavedHelper } from './checkIsOrderSavedHelper.js';
import Styles from './styles.m.css';

const { confirm } = Modal;

const compareOrderTasks = (initialOrderTask, orderTask) => {
    if (!initialOrderTask) {
        return true;
    }

    const initialOrderTaskEntity = {
        responsibleId: initialOrderTask.responsibleId,
        priority: initialOrderTask.priority,
        status: initialOrderTask.status,
        comment: initialOrderTask.comment,
        time: initialOrderTask.deadlineDate ? dayjs(initialOrderTask.deadlineDate).format('HH:mm') : void 0,
        date: initialOrderTask.deadlineDate ? dayjs(initialOrderTask.deadlineDate).format('YYYY-MM-DD') : void 0,
        stationNum: initialOrderTask.stationNum
    };

    const orderTaskEntity = {
        responsibleId: orderTask.responsible,
        priority: orderTask.priority,
        status: orderTask.status,
        comment: orderTask.comment,
        time: orderTask.deadlineTime ? dayjs(orderTask.deadlineTime).format('HH:mm') : void 0,
        date: orderTask.deadlineDate ? dayjs(orderTask.deadlineDate).format('YYYY-MM-DD') : void 0,
        stationNum: orderTask.stationName
    };

    return !_.isEqual(_.omitBy(orderTaskEntity, _.isNil), _.omitBy(initialOrderTaskEntity, _.isNil));
};

const mapStateToProps = state => {
    return {
        // orderTaskEntity:       state.forms.orderTaskForm.fields,
        // addClientFormData:     state.forms.addClientForm.data,
        clients: state.forms.orderForm.clients,
        employees: state.forms.orderForm.employees,
        fetchedOrder: state.forms.orderForm.fetchedOrder,
        fields: state.forms.orderForm.fields,
        initialOrderTask: state.forms.orderTaskForm.initialOrderTask,
        initOrderEntity: state.forms.orderForm.initOrderEntity,
        invited: state.forms.orderForm.invited,
        inviteOrderId: state.forms.orderForm.inviteOrderId,
        isMobile: state.ui.views.isMobile,
        managers: state.forms.orderForm.managers,
        modal: state.modals.modal,
        order: state.forms.orderForm.order,
        orderCalls: state.forms.orderForm.calls,
        orderComments: state.forms.orderForm.orderComments,
        orderTaskId: state.forms.orderTaskForm.taskId,
        orderTasks: state.forms.orderForm.orderTasks,
        priorityOptions: state.forms.orderTaskForm.priorityOptions,
        progressStatusOptions: state.forms.orderTaskForm.progressStatusOptions,
        selectedClient: state.forms.orderForm.selectedClient,
        spinner: state.ui.orderFetching,
        orderFetching: state.ui.orderFetching,
        stations: state.forms.orderForm.stations,
        businessLocations: state.forms.orderForm.businessLocations,
        user: state.auth,
        vehicles: state.forms.orderForm.vehicles,
        vehicleTypes: state.vehicleTypes.vehicleTypes,
        ...selectInviteData(state)
    };
};

const mapDispatchToProps = {
    fetchOrderForm,
    // fetchOrderTask,
    getReport,
    fetchReport,
    updateOrder,
    setModal,
    resetModal,
    returnToOrdersPage,
    createInviteOrder,
    createOrderCopy,
    fetchAddClientForm,
    resetOrderTasksForm,
    saveOrderTask,
    changeModalStatus,
    fetchVehicleTypes,
    setCollapsedState
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@withErrorMessage()
@injectIntl
class OrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: void 0,
            showOilModal: false,
            scrollToMapId: undefined,
            repairMapData: [],
            focusedRef: undefined,
            allServices: [],
            allDetails: { brands: [], details: [] },
            selectedClient: {
                requisites: [],
                phones: [],
                emails: [],
                vehicles: []
            },
            requisites: []
        };

        this._isMounted = false;
    }

    componentDidMount() {
        const {
            fetchOrderForm,
            fetchOrderTask,
            match: {
                params: { id }
            },
            user,
            fetchVehicleTypes
        } = this.props;
        fetchOrderForm(id);
        fetchVehicleTypes();

        this._isMounted = true;
    }

    componentDidUpdate = async prevProps => {
        if (this.props.order.clientId && !this.state.selectedClient.clientId && this.props.orderFetching) {
            const client = await fetchAPI('GET', `clients/${this.props.order.clientId}`, {
                cut: true,
                skipNotifications: true,
                skipReviews: true
            });
            this.setState({
                client,
                selectedClient: client
            });
        }
        if (!this.props.orderFetching && prevProps.orderFetching) {
            // const allServices = await fetchAPI('GET', 'labors');
            const brands = await fetchAPI('GET', 'brands');
            const details = await fetchAPI('GET', 'store_groups', { keepFlat: true });
            const requisites = await fetchAPI('GET', 'businesses/requisites');

            await this.setState({
                allServices: [],
                allDetails: {
                    brands,
                    details
                },
                requisites
            });
        }
    };

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    saveOrderFormRef = formRef => {
        this.orderFormRef = formRef;
    };

    saveOrderTaskFormRef = formRef => {
        this.orderTaskFormRef = formRef;
    };

    setAddClientModal = () => {
        this.props.fetchAddClientForm();
        this.props.setModal(MODALS.ADD_CLIENT);
    };

    _focusOnRef = focusedRef => {
        this.setState({ focusedRef });
    };

    _scrollToMap = id => {
        this.setState({
            scrollToMapId: id
        });
    };

    _showOilModal = (oem, oeCode, acea, api, sae) => {
        this.setState({
            showOilModal: true,
            oilModalData: {
                oem,
                oeCode,
                acea,
                api,
                sae
            }
        });
    };

    _clearOilData = () => {
        this.setState({
            showOilModal: false
        });
    };

    _onStatusChange = (status, redirectStatus, options, redirectTo) => {
        const { order } = this.props;
        const { allServices, allDetails } = this.state;
        const selectedClient = order.clientId ? this.state.selectedClient : this.props.selectedClient;
        const form = this.orderFormRef;

        const { id } = this.props.match.params;
        form.validateFields().then(values => {
            this.props.updateOrder({
                id,
                order: convertFieldsValuesToDbEntity(
                    { ...values, selectedClient },
                    allServices,
                    allDetails,
                    status,
                    this.props.user
                ),
                redirectStatus,
                options,
                redirectTo
            });
        });
    };

    _saveNewOrderTask = () => {
        const {
            saveOrderTask,
            resetModal,
            resetOrderTasksForm,
            // orderTaskEntity,
            orderTaskId,
            orderTasks,
            initialOrderTask,
            match: { params }
        } = this.props;

        const { form } = this.orderTaskFormRef.props;

        form.validateFields((errors, values) => {
            if (!errors) {
                if (orderTasks.orderTasks.length >= 1) {
                    if (compareOrderTasks(initialOrderTask, values)) {
                        saveOrderTask(values, params.id, orderTaskId);
                    }
                    resetModal();
                    resetOrderTasksForm();
                } else {
                    saveOrderTask(values, params.id, orderTaskId);
                    resetModal();
                    resetOrderTasksForm();
                }
            }
        });
    };

    /* eslint-disable complexity */
    _close = (onPassCheck, onRefuse, onConfirm) => {
        const { setModal } = this.props;

        const form = this.orderFormRef;

        const ordersAreSame = !form.isFieldsTouched();

        const { canEdit, hideEditButton } = this.getSecurityConfig();

        if (!canEdit || hideEditButton || ordersAreSame) {
            if (onPassCheck) {
                onPassCheck();
            } else {
                this._redirect();
            }
        } else {
            setModal(MODALS.CONFIRM_EXIT, {
                onRefuse,
                onConfirm
            });
        }
    };

    _redirect = () => {
        const {
            resetModal,
            returnToOrdersPage,
            history,
            order: { status }
        } = this.props;

        resetModal();
        _.get(history, 'location.state.fromDashboard') ? history.push(`${book.dashboard}`) : returnToOrdersPage(status);
    };

    _getOrderRemainSum = async callback => {
        const { fetchedOrder } = this.props;
        const { order } = await fetchAPI(
            'GET',
            `orders/${this.props.order.id}`,
            {
                onlyLabors: false,
                onlyDetails: false
            },
            undefined,
            { handleErrorInternally: true }
        );

        let remainSum = order.totalSumWithTax;

        if (fetchedOrder) {
            fetchedOrder.cashOrders.map(elem => {
                remainSum += elem.decrease || 0;
                remainSum -= elem.increase || 0;
            });
        }
        if (callback) {
            return callback(remainSum);
        }
    };

    _checkIsAllReserved = async callback => {
        const order = await fetchAPI('GET', `/orders/${this.props.match.params.id}`);
        let reserveError = false;
        order.orderDetails.map(elem => {
            if (elem.productId && elem.reservedCount != elem.count) {
                reserveError = true;
            }
        });
        if (reserveError) {
            confirm({
                title: this.props.intl.formatMessage({
                    id: 'order-page.status_confirmed_reserve_error'
                }),
                onOk() {
                    callback();
                }
            });
        } else if (!order.clientId) {
            notification.error({
                message: this.props.intl.formatMessage({
                    id: 'order-page.status_confirmed_no_client_error'
                })
            });
        } else {
            return callback();
        }
    };

    _getCurrentOrder = async (isReservedCheck = false, callback) => {
        const order = await fetchAPI('GET', `/orders/${this.props.match.params.id}`);
        let isReserved = false;
        if (!isReservedCheck) {
            this._createCopy(order.orderServices, order.orderDetails);
        } else {
            order.orderDetails.map(elem => {
                if (Number(elem.reservedCount) > 0) {
                    isReserved = true;
                }
            });
            if (isReserved) {
                notification.error({
                    message: this.props.intl.formatMessage({
                        id: 'order-page.reserved_error'
                    })
                });
            } else {
                return callback();
            }
        }
    };

    _createCopy = (services, details) => {
        const { allServices, allDetails, selectedClient } = this.state;
        const form = this.orderFormRef;

        const genData = orderFormValues => {
            const entryStationLoad = _.get(orderFormValues, 'stationLoads[0]');
            const normalizedBeginDateTime = roundCurrentTime();
            entryStationLoad.beginDate = normalizedBeginDateTime;
            entryStationLoad.beginTime = normalizedBeginDateTime;

            const normalizedValues = _.set(orderFormValues, 'stationLoads', [entryStationLoad]);
            const orderFormEntity = { ...normalizedValues, selectedClient };
            const copyData = {
                ...convertFieldsValuesToDbEntity(
                    orderFormEntity,
                    allServices,
                    allDetails,
                    'not_complete',
                    this.props.user
                )
            };

            copyData.businessLocationId = undefined;
            copyData.details = details.map(detail => {
                return detail.productId
                    ? {
                          id: detail.id,
                          storeGroupId: detail.storeGroupId,
                          name: detail.detailName,
                          productId: detail.storeId || detail.productId,
                          productCode: detail.detailCode,
                          purchasePrice: Math.round(detail.purchasePrice * 10) / 10 || 0,
                          count: detail.count ? detail.count : 1,
                          price: detail.price ? Math.round(detail.price * 10) / 10 : 1,
                          supplierBrandId: detail.supplierBrandId || detail.brandId,
                          supplierId: detail.supplierId,
                          supplierOriginalCode: detail.supplierOriginalCode,
                          supplierProductNumber: detail.supplierProductNumber,
                          supplierPartNumber: detail.supplierPartNumber,
                          comment: detail.comment || {
                              comment: undefined,
                              positions: []
                          }
                      }
                    : {
                          id: detail.id,
                          storeGroupId: detail.storeGroupId,
                          name: detail.detailName,
                          productCode: detail.detailCode ? detail.detailCode : null,
                          supplierId: detail.supplierId,
                          supplierBrandId: detail.supplierBrandId || detail.brandId,
                          supplierOriginalCode: detail.supplierOriginalCode,
                          supplierProductNumber: detail.supplierProductNumber,
                          supplierPartNumber: detail.supplierPartNumber,
                          purchasePrice: Math.round(detail.purchasePrice * 10) / 10 || 0,
                          count: detail.count,
                          price: detail.price ? Math.round(detail.price * 10) / 10 : 1,
                          comment: detail.comment || {
                              comment: undefined,
                              positions: []
                          }
                      };
            });
            copyData.services = services.map(labor => ({
                serviceId: labor.laborId,
                serviceName: labor.serviceName,
                employeeId: labor.employeeId,
                serviceHours: labor.hours ? labor.hours : 0,
                purchasePrice: labor.purchasePrice ? Math.round(labor.purchasePrice * 10) / 10 : 0,
                count: labor.count ? labor.count : 1,
                servicePrice: Math.round(labor.price * 10) / 10,
                comment: labor.comment || {
                    comment: undefined,
                    positions: [],
                    problems: []
                }
            }));

            return copyData;
        };

        form.validateFields().then(values => {
            this.props.createOrderCopy(genData(values));
        });
    };

    _invite = () => {
        const { clientVehicleId, clientId, status, clientPhone } = this.props.order;
        const { user, createInviteOrder } = this.props;

        if ((status === 'success' || status === 'cancel') && clientVehicleId && clientId && clientPhone) {
            createInviteOrder({
                status: 'invite',
                clientVehicleId,
                clientId,
                clientPhone,
                managerId: user.id
            });
        }
    };

    getSecurityConfig() {
        const { user } = this.props;
        const { status } = this.props.order;

        const isClosedStatus = ['success', 'cancel', 'redundant'].includes(status);
        const canEditClosedStatus = !isForbidden(user, permissions.UPDATE_SUCCESS_ORDER);
        const canEdit =
            !isForbidden(user, permissions.ACCESS_ORDER_BODY) ||
            !isForbidden(user, permissions.ACCESS_ORDER_DETAILS) ||
            !isForbidden(user, permissions.ACCESS_ORDER_SERVICES) ||
            !isForbidden(user, permissions.ACCESS_ORDER_COMMENTS);

        const hideEditButton = isClosedStatus && !canEditClosedStatus;
        const disabledEditButton = hideEditButton || !canEdit;

        const forbiddenUpdate = isForbidden(user, permissions.ACCESS_ORDER_STATUS);

        return {
            isClosedStatus,
            canEditClosedStatus,
            canEdit,
            hideEditButton,
            disabledEditButton,
            forbiddenUpdate
        };
    }

    reloadOrderPageComponents = () => {
        this.componentDidMount();
    };

    /* eslint-disable complexity */
    render() {
        const {
            showOilModal,
            oilModalData,
            repairMapData,
            focusedRef,
            client,
            allServices,
            allDetails,
            selectedClient: selectedClientFromState,
            requisites
        } = this.state;
        const {
            fetchOrderForm,
            // fetchOrderTask,
            setModal,
            resetModal,
            orderFetching,
            hasInviteStatus,
            isInviteVisible,
            isInviteEnabled,
            inviteOrderId,
            modal,
            isMobile,
            managers,
            stations,
            businessLocations,
            user,
            initialOrderTask,
            fetchedOrder,
            order,
            vehicleTypes,
            selectedClient: selectedClientFromProps,
            setCollapsedState
        } = this.props;
        const selectedClient = selectedClientFromState || selectedClientFromProps;
        const { num, status, datetime, diagnosis } = this.props.order;
        const { clientId } = selectedClient;
        const { id } = this.props.match.params;

        const { isClosedStatus, hideEditButton, disabledEditButton, forbiddenUpdate } = this.getSecurityConfig();

        const copyDisabled = isForbidden(user, permissions.ACCESS_ORDER_COPY);

        const showCahOrderModal = () => {
            this._getOrderRemainSum(remainSum => {
                setModal(MODALS.REFACTORED_CASH_ORDER, {
                    fromOrder: true,
                    initValues: {
                        clientId,
                        orderId: id,
                        sum: Math.round(remainSum * 100) / 100
                    },
                    onCashOrderCreatedCallback: () => {
                        fetchOrderForm(id);
                    }
                });
            });
        };

        return orderFetching || !this._isMounted ? (
            <Spinner spin={orderFetching} />
        ) : (
            <Layout
                className='orderPageLayout hideHeaderControls'
                controls={
                    <React.Fragment>
                        {hasInviteStatus && inviteOrderId && (
                            <Link
                                className={Styles.inviteButton}
                                onClick={() => {
                                    fetchOrderForm(inviteOrderId);
                                    // if (viewTasks) {
                                    //     fetchOrderTask(inviteOrderId);
                                    // }
                                }}
                                to={`${book.order}/${inviteOrderId}`}
                            >
                                {inviteOrderId}
                            </Link>
                        )}
                        {!isMobile && isInviteVisible && !inviteOrderId ? (
                            <Button
                                className={Styles.inviteButton}
                                disabled={
                                    !isInviteEnabled ||
                                    isForbidden(user, permissions.CREATE_ORDER) ||
                                    isForbidden(user, permissions.CREATE_INVITE_ORDER)
                                }
                                onClick={this._invite}
                                type='primary'
                            >
                                <FormattedMessage id='order-page.create_invite_order' />
                            </Button>
                        ) : null}
                        <ChangeStatusDropdown
                            checkReserved={this._checkIsAllReserved}
                            isMobile={isMobile}
                            modals={MODALS}
                            onStatusChange={this._onStatusChange}
                            orderStatus={status}
                            setModal={setModal}
                            user={user}
                        />
                        {!isForbidden(user, permissions.ACCESS_ORDER_PAY) && (
                            <DollarOutlined
                                onClick={showCahOrderModal}
                                style={{
                                    fontSize: isMobile ? 14 : 24,
                                    cursor: 'pointer',
                                    margin: '0 10px'
                                }}
                            />
                        )}
                        {!isMobile && (
                            <ReportsDropdown
                                download={this.props.getReport}
                                isMobile={isMobile}
                                orderId={id}
                                orderStatus={status}
                                user={this.props.user}
                            />
                        )}
                        {!copyDisabled && (
                            <CopyOutlined
                                onClick={() => {
                                    this._getCurrentOrder();
                                }}
                                style={{
                                    fontSize: isMobile ? 14 : 24,
                                    cursor: 'pointer',
                                    margin: '0 10px'
                                }}
                                title={this.props.intl.formatMessage({
                                    id: 'order-page.create_copy'
                                })}
                            />
                        )}
                        {!hideEditButton && (
                            <SaveOutlined
                                onClick={() => !disabledEditButton && this._onStatusChange(status)}
                                style={{
                                    fontSize: isMobile ? 14 : 24,
                                    cursor: 'pointer',
                                    margin: '0 10px',
                                    ...(disabledEditButton ? { color: 'gray' } : {})
                                }}
                            />
                        )}
                        {!isClosedStatus && !forbiddenUpdate && !isForbidden(user, permissions.ACCESS_ORDER_DELETE) && (
                            <DeleteOutlined
                                onClick={() => {
                                    this._getCurrentOrder(true, () => {
                                        setModal(MODALS.CANCEL_REASON);
                                    });
                                }}
                                style={{
                                    fontSize: isMobile ? 14 : 24,
                                    cursor: 'pointer',
                                    margin: '0 10px'
                                }}
                            />
                        )}
                        <CloseOutlined
                            onClick={() => this._close()}
                            style={{
                                fontSize: isMobile ? 14 : 24,
                                cursor: 'pointer'
                            }}
                        />
                    </React.Fragment>
                }
                description={
                    <React.Fragment>
                        <FormattedMessage id='order-page.creation_date' />
                        {`: ${dayjs(datetime).format('DD MMMM YYYY, HH:mm')}`}
                    </React.Fragment>
                }
                menuControls
                title={
                    !status || !num ? (
                        ''
                    ) : (
                        <React.Fragment>
                            <FormattedMessage id={`order-status.${status || 'order'}`} />
                            {` ${num}`}
                        </React.Fragment>
                    )
                }
            >
                <MobileView>
                    <CheckIsOrderSavedHelper
                        close={() => this._close()}
                        onStatusChange={this._onStatusChange}
                        resetModal={resetModal}
                        setCollapsedState={setCollapsedState}
                        status={status}
                    />
                    <MobileRecordForm
                        allDetails={allDetails}
                        allServices={allServices}
                        changeModalStatus={this.props.changeModalStatus}
                        client={client}
                        employees={this.props.employees}
                        errors={this.state.errors}
                        fetchedOrder={fetchedOrder}
                        fetchOrderForm={fetchOrderForm}
                        isMobile={isMobile}
                        location={false}
                        modal={modal}
                        onStatusChange={this._onStatusChange}
                        order={order}
                        orderFetching={orderFetching}
                        orderFormRef={this.saveOrderFormRef}
                        orderId={id}
                        orderStatus={status}
                        reloadOrderPageComponents={this.reloadOrderPageComponents}
                        requisites={requisites}
                        selectedClient={selectedClient}
                        setAddClientModal={this.setAddClientModal}
                        setModal={setModal}
                        user={this.props.user}
                        vehicleTypes={vehicleTypes}
                    />
                </MobileView>
                <ResponsiveView view={{ min: BREAKPOINTS.sm.max, max: BREAKPOINTS.xxl.max }}>
                    <OrderForm
                        allDetails={allDetails}
                        allServices={allServices}
                        businessLocations={businessLocations}
                        changeModalStatus={this.props.changeModalStatus}
                        clearOilData={this._clearOilData}
                        client={client}
                        download={this.props.getReport}
                        employees={this.props.employees}
                        errors={this.state.errors}
                        fetchedOrder={fetchedOrder}
                        fetchOrderForm={fetchOrderForm}
                        filteredDetails={this.props.filteredDetails}
                        focusedRef={focusedRef}
                        focusOnRef={this._focusOnRef}
                        location={false}
                        modal={modal}
                        modals={MODALS}
                        oilModalData={oilModalData}
                        onStatusChange={this._onStatusChange}
                        order={order}
                        // fetchOrderTask={ fetchOrderTask }
                        orderCalls={this.props.orderCalls}
                        orderDiagnostic={diagnosis}
                        orderFetching={orderFetching}
                        orderId={id}
                        orderStatus={status}
                        orderTasks={this.props.orderTasks}
                        reloadOrderPageComponents={this.reloadOrderPageComponents}
                        repairMapData={repairMapData}
                        requisites={requisites}
                        scrollToMap={this._scrollToMap}
                        scrollToMapId={this.state.scrollToMapId}
                        selectedClient={selectedClient}
                        setAddClientModal={this.setAddClientModal}
                        setModal={setModal}
                        showCahOrderModal={showCahOrderModal}
                        showOilModal={showOilModal}
                        user={this.props.user}
                        vehicleTypes={vehicleTypes}
                        wrappedComponentRef={this.saveOrderFormRef}
                    />
                </ResponsiveView>
                <CancelReasonModal
                    handleCancelReasonModalSubmit={this._onStatusChange}
                    orderComments={this.props.orderComments}
                    resetModal={() => resetModal()}
                    visible={modal}
                    wrappedComponentRef={this.saveFormRef}
                />
                <ConfirmOrderExitModal
                    closeModal={() => this._close()}
                    redirect={() => this._redirect()}
                    resetModal={() => resetModal()}
                    returnToOrdersPage={() => this.props.returnToOrdersPage(status)}
                    saveOrder={() => {
                        // if (_.get(history, 'location.state.fromDashboard')) {
                        //     return this._onStatusChange(status, 'dashboard');
                        // }

                        return this._onStatusChange(status, status);
                    }}
                    status={status}
                    visible={modal}
                    wrappedComponentRef={this.saveFormRef}
                />

                <OrderTaskModal
                    initialOrderTask={initialOrderTask}
                    managers={managers}
                    num={num}
                    orderId={id}
                    orderTaskEntity={this.props.orderTaskEntity}
                    orderTaskId={this.props.orderTaskId}
                    orderTasks={this.props.orderTasks}
                    priorityOptions={this.props.priorityOptions}
                    progressStatusOptions={this.props.progressStatusOptions}
                    resetModal={() => resetModal()}
                    resetOrderTasksForm={this.props.resetOrderTasksForm}
                    saveNewOrderTask={this._saveNewOrderTask}
                    stations={stations}
                    visible={modal}
                    wrappedComponentRef={this.saveOrderTaskFormRef}
                />
                <StoreProductModal />
                <RefactoredCashOrderModal />
            </Layout>
        );
    }
}

export default OrderPage;
