import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchBusinessSuppliersSuccess,
    FETCH_BUSINESS_SUPPLIERS,
    selectBusinessSuppliersFilters,
    setFetchingBusinessSuppliers
} from './duck';

export function* fetchBusinessSuppliersSaga() {
    while (true) {
        try {
            yield take(FETCH_BUSINESS_SUPPLIERS);

            yield put(setFetchingBusinessSuppliers(true));
            const filters = yield select(selectBusinessSuppliersFilters);

            const response = yield call(fetchAPI, 'GET', '/business_suppliers_dept', { filters });
            const { businessSuppliers, stats: businessSuppliersStats } = response;

            yield put(
                fetchBusinessSuppliersSuccess({
                    businessSuppliers,
                    businessSuppliersStats
                })
            );
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingBusinessSuppliers(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchBusinessSuppliersSaga)]);
}
