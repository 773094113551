import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { DocumentTypesMapper, Numeral } from 'commons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Choose width for each col, (100% in total or less than 100% if 'auto' is present)
const defWidth = {
    cashOrderIdCol: '5%',
    typeCol: '15%',
    cashboxIdCol: '10%',
    datetimeCol: 'auto',
    sumCol: '10%',
    descriptionCol: '15%'
};

const mask = '0,0.00';

export function columnsConfig() {
    const cashOrderIdCol = {
        title: <FormattedMessage id='supplier.cash_order_id' />,
        width: defWidth.cashOrderIdCol,
        align: 'left',
        dataIndex: 'cashOrderId',
        key: 'cashOrderIdCol'
    };
    const typeCol = {
        title: <FormattedMessage id='supplier.type' />,
        width: defWidth.typeCol,
        align: 'left',
        dataIndex: 'type',
        key: 'typeCol',
        render: type => <DocumentTypesMapper type={type} />
    };
    const cashboxIdCol = {
        title: <FormattedMessage id='supplier.cashbox_id' />,
        width: defWidth.cashboxIdCol,
        align: 'left',
        dataIndex: 'cashboxId',
        key: 'cashboxIdCol'
    };
    const datetimeCol = {
        title: <FormattedMessage id='supplier.datetime' />,
        width: defWidth.datetimeCol,
        align: 'left',
        dataIndex: 'datetime',
        key: 'datetimeCol'
    };
    const sumCol = {
        title: <FormattedMessage id='supplier.sum' />,
        width: defWidth.sumCol,
        align: 'right',
        dataIndex: 'sum',
        key: 'sumCol',
        render: (key, { increase, decrease }) =>
            increase ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}
                >
                    + <Numeral mask={mask}>{increase}</Numeral>
                    <CaretUpOutlined style={{ color: 'var(--enabled)' }} />
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}
                >
                    - <Numeral mask={mask}>{decrease}</Numeral>
                    <CaretDownOutlined style={{ color: 'var(--disabled)' }} />
                </div>
            )
    };
    const descriptionCol = {
        title: <FormattedMessage id='supplier.description' />,
        width: defWidth.descriptionCol,
        align: 'left',
        dataIndex: 'description',
        key: 'descriptionCol',
        render: (key, { descriptionCol }) => {
            return <div>{descriptionCol || <FormattedMessage id='long_dash' />}</div>;
        }
    };

    return [cashOrderIdCol, typeCol, cashboxIdCol, datetimeCol, sumCol, descriptionCol];
}
