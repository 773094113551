import { Checkbox, Input, InputNumber, Modal, Select, notification } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const AddGroupModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        edit,
        fetchGroups,
        fetchAllPackages,
        allPackages,
        businessId
    } = props;

    const [groupName, setName] = useState();
    const [packageId, setPackageId] = useState();
    const [radius, setRadius] = useState();
    const [ordersAmount, setOrdersAmount] = useState();
    const [priority, setPriority] = useState();
    const [callsAmount, setCallsAmount] = useState();
    const [enabled, setEnabled] = useState(false);
    const [servicesOnly, setServicesOnly] = useState(false);

    useEffect(() => {
        if (edit) {
            setName(edit.name);
            setPackageId(edit.package_id || undefined);
            setRadius(edit.radius);
            setOrdersAmount(edit.max_count_orders);
            setPriority(edit.priority);
            setCallsAmount(edit.max_count_calls);
            setEnabled(edit.enabled);
            setServicesOnly(edit.services_only);
        }
    }, [edit]);

    const addGroup = async () => {
        try {
            await fetchAPI(
                'POST',
                '/billing/groups',
                undefined,
                {
                    name: groupName,
                    package_id: packageId,
                    radius,
                    max_count_orders: ordersAmount,
                    priority,
                    max_count_calls: callsAmount,
                    services_only: servicesOnly,
                    enabled
                },
                { handleErrorInternally: true }
            );
            await fetchGroups();
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const editGroup = async () => {
        try {
            if (edit) {
                await fetchAPI(
                    'PUT',
                    `/billing/groups/${edit.id}`,
                    undefined,
                    {
                        name: groupName,
                        package_id: packageId,
                        radius,
                        max_count_orders: ordersAmount,
                        priority,
                        max_count_calls: callsAmount,
                        services_only: servicesOnly,
                        enabled
                    },
                    { handleErrorInternally: true }
                );

                notification.success({
                    message: formatMessage({ id: 'barcode.success' })
                });

                await fetchGroups();
            }
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const onClose = () => {
        setName(undefined);
        setPackageId(undefined);
        setRadius(undefined);
        setOrdersAmount(undefined);
        setPriority(undefined);
        setCallsAmount(undefined);
        setEnabled(undefined);
        setServicesOnly(undefined);
        hideModal();
    };

    useEffect(() => {
        if (open && !get(allPackages, 'length')) {
            fetchAllPackages();
        }
    }, [open, fetchAllPackages, allPackages]);

    const onFinish = async () => {
        if (!edit) {
            addGroup();
        } else {
            editGroup();
        }

        await onClose();
    };

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: !groupName || !radius || !ordersAmount || !priority || !callsAmount
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={
                !edit ? (
                    <FormattedMessage id='monetization.create_group' />
                ) : (
                    <FormattedMessage id='monetization.edit_group' />
                )
            }
            width='30%'
        >
            <div>
                <div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.name' /> <span className={Styles.red}>*</span>
                        </div>
                        <Input
                            onChange={event => setName(event.target.value)}
                            placeholder={formatMessage({
                                id: 'monetization.name'
                            })}
                            style={{
                                width: '100%'
                            }}
                            value={groupName}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.package' />
                        </div>
                        <Select
                            allowClear
                            filterOption={(input, option) =>
                                Boolean(option.props.children.toLowerCase().indexOf(input.toLowerCase() !== -1))
                            }
                            onChange={value => setPackageId(value)}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'monetization.package'
                            })}
                            showSearch
                            style={{ width: '100%' }}
                            value={packageId}
                        >
                            {allPackages
                                ? allPackages.map(({ id, name }) => (
                                      <Option key={id} value={id}>
                                          {name}
                                      </Option>
                                  ))
                                : []}
                        </Select>
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.radius' /> <span className={Styles.red}>*</span>
                        </div>
                        <InputNumber
                            min={0}
                            onChange={value => setRadius(value)}
                            placeholder={formatMessage({
                                id: 'monetization.radius'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={radius}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.orders_amount' /> <span className={Styles.red}>*</span>
                        </div>
                        <InputNumber
                            min={0}
                            onChange={value => setOrdersAmount(value)}
                            placeholder={formatMessage({
                                id: 'monetization.orders_amount'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={ordersAmount}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.priority' /> <span className={Styles.red}>*</span>
                        </div>
                        <InputNumber
                            min={0}
                            onChange={value => setPriority(value)}
                            placeholder={formatMessage({
                                id: 'monetization.priority'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={priority}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.calls_amount' /> <span className={Styles.red}>*</span>
                        </div>
                        <InputNumber
                            min={0}
                            onChange={value => setCallsAmount(value)}
                            placeholder={formatMessage({
                                id: 'monetization.calls_amount'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={callsAmount}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <Checkbox checked={enabled} onChange={() => setEnabled(!enabled)} />{' '}
                        <span>
                            <FormattedMessage id='monetization.active' />
                        </span>
                    </div>
                    <div className={Styles.margin}>
                        <Checkbox checked={servicesOnly} onChange={() => setServicesOnly(!servicesOnly)} />{' '}
                        <span>
                            <FormattedMessage id='monetization.only_for_services' />
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(AddGroupModal);
