import { setClientOrdersFetchingState } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchClientOrdersSuccess, FETCH_CLIENT_ORDERS } from './duck';

const selectFilter = ({ clientOrders: { filter, sort } }) => ({
    sort,
    filter
});

export function* fetchClientOrdersSaga() {
    while (true) {
        try {
            const {
                payload: { clientId }
            } = yield take(FETCH_CLIENT_ORDERS);
            yield put(setClientOrdersFetchingState(true));

            const {
                filter,
                sort: { field: sortField, order: sortOrder }
            } = yield select(selectFilter);
            const filters = { ...filter, sortField, sortOrder };

            const data = yield call(fetchAPI, 'GET', `orders/client/${clientId}`, filters);
            yield put(fetchClientOrdersSuccess(data));
        } finally {
            yield put(setClientOrdersFetchingState(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchClientOrdersSaga)]);
}
