import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CashOrdersTable } from 'components';
import {
    fetchCashOrders,
    printCashOrder,
    selectCashOrdersFilters,
    setCashOrdersFilters,
    setCashOrdersPage
} from 'core/cash/duck';
import { clearCashOrderForm } from 'core/forms/cashOrderForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { CashOrdersFiltersForm } from 'forms';
import _ from 'lodash';
import { CashOrderModal, RefactoredCashOrderModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Layout } from 'tireFitting';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    collapsed: state.ui.collapsed,
    cashOrders: state.cash.cashOrders,
    stats: state.cash.stats,
    user: state.auth,
    modal: state.modals.modal,
    modalProps: state.modals.modalProps,
    filters: selectCashOrdersFilters(state),
    cashFlowFilters: _.get(state, 'router.location.state.cashFlowFilters'),
    isFetching: state.ui.cashOrdersFetching,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    resetModal,
    setCashOrdersPage,
    fetchCashOrders,
    clearCashOrderForm,
    printCashOrder,
    setCashOrdersFilters
};

@connect(mapStateToProps, mapDispatchToProps)
export default class CashFlowPage extends Component {
    state = {
        cashOrderModalMounted: false
    };

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.cashboxId) {
            this.props.setCashOrdersFilters({
                cashBoxId: this.props.location.state.cashboxId
            });
        }
        this.props.fetchCashOrders(this.props.cashFlowFilters);

        if (this.props.location.state && this.props.location.state.showForm) {
            this._onOpenCashOrderModal();
        }
    }

    _onOpenCashOrderModal = () => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                cashBoxId: this.props.filters.cashBoxId
            },
            onCashOrderCreatedCallback: () => this.props.fetchCashOrders(this.props.cashFlowFilters)
        });
        this.setState({ cashOrderModalMounted: true });
    };

    _onCloseCashOrderModal = () => {
        this.props.resetModal();
        this.props.clearCashOrderForm();
        this.setState({ cashOrderModalMounted: false });
    };

    _onOpenPrintCashOrderModal = cashOrderEntity => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            mode: 'PRINT',
            initValues: {
                cashOrderId: cashOrderEntity.id
            }
        });
        this.setState({ cashOrderModalMounted: true });
    };

    _onOpenEditCashOrderModal = cashOrderEntity => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            mode: 'EDIT',
            initValues: {
                cashOrderId: cashOrderEntity.id
            },
            onCashOrderUpdatedCallback: () => this.props.fetchCashOrders(this.props.cashFlowFilters)
        });
        this.setState({ cashOrderModalMounted: true });
    };

    render() {
        const {
            isFetching,
            collapsed,
            stats,
            modal,
            modalProps,
            setCashOrdersPage,
            clearCashOrderForm,
            cashOrders,
            filters,
            user,
            fetchCashOrders,
            printCashOrder,
            isMobile
        } = this.props;

        const canEditCashOrders = !isForbidden(user, permissions.EDIT_CASH_ORDERS);

        return (
            <Layout
                controls={
                    <div className={Styles.buttonGroup}>
                        <PrinterOutlined
                            className={Styles.printIcon}
                            onClick={() => printCashOrder()}
                        />
                        <Button onClick={() => this._onOpenCashOrderModal()} type='primary'>
                            <FormattedMessage id='add' />
                        </Button>
                    </div>
                }
                description=''
                title={<FormattedMessage id='navigation.flow_of_money' />}
            >
                <CashOrdersFiltersForm filters={filters} stats={_.omit(stats, 'totalCount')} />
                <CashOrdersTable
                    cashOrders={cashOrders}
                    cashOrdersFetching={isFetching}
                    fetchCashOrders={fetchCashOrders}
                    filters={filters}
                    isMobile={isMobile}
                    openEdit={canEditCashOrders ? this._onOpenEditCashOrderModal : null}
                    openPrint={this._onOpenPrintCashOrderModal}
                    setCashOrdersPage={setCashOrdersPage}
                    // eslint-disable-next-line no-empty-function
                    totalCount={stats.totalCount}
                />
                {this.state.cashOrderModalMounted ? (
                    <CashOrderModal
                        clearCashOrderForm={clearCashOrderForm}
                        modalProps={modalProps}
                        resetModal={this._onCloseCashOrderModal}
                        visible={modal}
                    />
                ) : null}

                <RefactoredCashOrderModal />
            </Layout>
        );
    }
}
