exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__table---uGYB9 .ant-table-thead>tr>th>span>span{white-space:nowrap}.styles-m__orderLink---3SPVU{color:var(--link)}.styles-m__orderLink---3SPVU:hover{color:var(--primary)}.styles-m__datetime---xHKAW{display:flex;flex-direction:column;width:75px}.styles-m__client---MmlHm{width:100%;margin:2px 0}.styles-m__createClient---7fEd7{width:100%}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0DACI,kBACJ,CAGA,6BACI,iBACJ,CAEA,mCACI,oBACJ,CAEA,4BACI,YAAa,CACb,qBAAsB,CACtB,UACJ,CAEA,0BACI,UAAW,CACX,YACJ,CAEA,gCACI,UACJ","file":"styles.m.css","sourcesContent":[".table :global(.ant-table-thead > tr > th > span > span) {\n    white-space: nowrap;\n}\n\n\n.orderLink {\n    color: var(--link);\n}\n\n.orderLink:hover {\n    color: var(--primary);\n}\n\n.datetime {\n    display: flex;\n    flex-direction: column;\n    width: 75px;\n}\n\n.client {\n    width: 100%;\n    margin: 2px 0 2px 0;\n}\n\n.createClient {\n    width: 100%;\n}"]}]);

// Exports
exports.locals = {
	"table": "styles-m__table---uGYB9",
	"orderLink": "styles-m__orderLink---3SPVU",
	"datetime": "styles-m__datetime---xHKAW",
	"client": "styles-m__client---MmlHm",
	"createClient": "styles-m__createClient---7fEd7"
};