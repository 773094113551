export const ORD = {
    business: {
        name: 'Назва станції',
        address: 'Адреса',
        phones: 'Номер телефону станції',
        logo: {
            original: {
                path: 'Посилання',
                width: 'Ширина',
                height: 'Висота'
            }
        },
        primaryLogoUrl: 'URL посилання основного логотипу',
        secondaryLogoUrl: 'URL посилання додаткового логотипу',
        domain: 'Домен',
        normHourPrice: 'Ціна за годину',
        phoneCode: {
            $code: 'Код номеру телефона',
            $number: 'Номер',
            $country: 'Країна'
        },
        packageStatus: 'Статус пакета',
        currency: 'Валюта',
        base64PrimaryLogo: 'Base64 основного логотипу',
        quantityDecimalPlaces: 'Кількість десяткових знаків для кількості',
        priceDecimalPlaces: 'Кількість десяткових знаків для ціни',
        sumDecimalPlaces: 'Кількість десяткових знаків для суми'
    },
    businessRequisite: {
        name: 'Назва',
        address: 'Адреса',
        bank: 'Банк',
        ifi: 'Код банку',
        ca: 'рахунок IBAN',
        itn: 'Код ЄДРПОУ',
        formType: 'Тип форми',
        formName: 'Назва форми',
        isTaxPayer: 'Є платником податків',
        calculationMethod: 'Метод розрахунку',
        taxRate: 'Податкова ставка',
        ipn: 'ІПН',
        certificateNumber: 'Номер сертифікату',
        contractNumber: 'Номер контракту',
        contractDate: 'Дата контракту',
        director: 'Директор'
    },
    client: {
        middleName: 'По батькові',
        status: 'Статус',
        birthday: 'Дата народження',
        sex: 'Стать',
        name: 'Ім\'я',
        surname: 'Прізвище',
        phones: 'Телефони',
        emails: 'Електронні адреса',
        paymentRespite: 'Відстрочка оплати',
        type: 'Тип клієнта',
        comment: 'Коментар',
        phoneCode: {
            $code: 'Код',
            $country: 'Країна'
        },
        requisites: {
            $ca: 'IBAN',
            $ifi: 'Код Банку',
            $ipn: 'ІПН',
            $itn: 'Код ЄДРПОУ',
            $bank: 'Банк',
            $name: 'Назва',
            $address: 'Адреса',
            $enabled: 'Активний',
            $taxRate: 'Податкова ставка',
            $director: 'Директор',
            $disabled: 'Неактивний',
            $formName: 'Назва форми',
            $formType: 'Тип форми',
            $isTaxPayer: 'Є платником податків',
            $accountName: 'Назва рахунку',
            $contractDate: 'Дата контракту',
            $contractNumber: 'Номер контракту',
            $calculationMethod: 'Метод розрахунку',
            $certificateNumber: 'Номер свідотства'
        },
        $accountName: 'Назва рахунку',
        $notifications: 'Сповіщення',
        $reviews: 'Відгуки'
    },
    clientRequisite: {
        name: 'Назва',
        address: 'Адреса',
        bank: 'Банк',
        ifi: 'Код банку',
        ca: 'рахунок IBAN',
        itn: 'Код ЄДРПОУ',
        formType: 'Тип форми',
        formName: 'Назва форми',
        calculationMethod: 'Метод розрахунку',
        taxRate: 'Податкова ставка',
        ipn: 'ІПН',
        certificateNumber: 'Номер сертифікату',
        contractNumber: 'Номер контракту',
        director: 'Директор',
        accountId: 'Ідентифікатор рахунку',
        contractDate: 'Дата контракту'
    },
    clientVehicle: {
        vin: 'vin',
        number: 'Номер',
        registrationName: 'Ім\'я при реєстрації',
        vehicleMakeName: 'Марка транспортного засобу',
        vehicleModelName: 'Модель транспортного засобу',
        vehicleModificationName: 'Модифікація транспортного засобу',
        clientVehicleYear: 'Рік транспортного засобу клієнта',
        makeModYear: 'Рік виробництва та модифікації',
        numVinOdometer: 'Пробіг автомобіля'
    },
    details: {
        $detailName: 'Назва деталі',
        $productId: 'ID продукту',
        $productBrandId: 'ID бренду продукту',
        $measureUnit: 'Одиниця виміру',
        $tradeCode: 'Код постачальника',
        $brandName: 'Назва бренду',
        $oap_code: 'Оригінальний код',
        $id: 'ID деталі',
        $ids: 'Декілька ID деталей',
        $agreement: 'Угода',
        $status: 'Статус',
        $productKeyGroup: 'Ключ групи продуктів',
        $discount: 'Знижка',
        $rowDiscountPrice: 'Ціна зі знижкою в рядку',
        $discountPrice: 'Ціна зі знижкою',
        $partRowDiscount: 'Знижка на деталь в рядку',
        $serviceRowDiscount: 'Знижка на сервіс в рядку',
        $count: 'Кількість',
        $price: 'Ціна',
        $discountSum: 'Сума знижки',
        $sum: 'Сума'
    },
    employee: {
        email: 'електронна пошта працівника',
        fireDate: 'дата звільнення працівника',
        fireReason: 'причина звільнення працівника',
        gsmCode: 'gsm-код',
        hireDate: 'дата найму',
        jobTitle: 'призвіще та імʼя',
        name: 'ім\'я',
        phone: 'номер телефону',
        surname: 'прізвище',
        accountId: 'ID облікового запису',
        taxService: 'вибір PPO',
        barcode: 'штрих-код',
        posts: {
            $postId: 'ID посади',
            $postName: 'назва посади'
        },
        medoc: {
            url: 'url посилання',
            edrpou: 'Код ЄДРПОУ',
            login: 'Логін',
            dept: 'номер філії'
        },
        accountName: 'назва облікового запису',
        roleName: 'назва ролі'
    },
    appurtenanciesResponsible: {
        email: 'електронна пошта',
        gsmCode: 'gsm код',
        hireDate: 'дата прийому на роботу',
        jobTitle: 'посада',
        name: 'ім\'я',
        phone: 'телефон',
        surname: 'прізвище',
        navigationIcons: ['швидка навігація', 'розклад', 'контакти'],
        ordersIcons: [
            'роботи',
            'запчастини',
            'історія транспортного засобу',
            'замовлення',
            'посади',
            'коментарі',
            'журнали замовлень',
            'дзвінки'
        ],
        accountId: 'ідентифікатор рахунку',
        taxService: 'вибір PPO',
        barcode: 'штрихкод',
        posts: {
            $postName: 'назва посади'
        },
        medoc: {
            url: 'url посилання',
            edrpou: 'Код ЄДРПОУ',
            login: 'Логін',
            dept: 'номер філії'
        },
        accountName: 'назва рахунку',
        roleName: 'назва ролі',
        schedule: 'розклад',
        nonWorkingDays: 'неробочі дні',
        reviews: 'відгуки'
    },
    manager: {
        managerName: 'ім\'я менеджера',
        managerSurname: 'прізвище менеджера',
        phone: 'телефон',
        phoneCode: {
            code: 'код',
            country: 'країна'
        },
        posts: {
            $postName: 'назва посади'
        },
        medoc: {
            url: 'url посилання',
            edrpou: 'Код ЄДРПОУ',
            login: 'Логін',
            dept: 'номер філії'
        },
        postref: {
            $postNum: 'Пост за замовчуванням',
            $dashboardFilter: 'фільтр на планувальнику',
            $ordersView: 'перегляд всіх замовлень',
            $cashierKey: 'ключ ЕЦП',
            $orderType: 'тип ремонту'
        }
    },
    order: {
        detailsDiscount: 'знижка на деталі',
        beginDatetime: 'дата та час початку',
        businessComment: 'коментар в цех',
        clientEmail: 'електронна пошта клієнта',
        clientPhone: 'номер телефона клієнта',
        comment: 'коментар клієнта',
        courtesyCar: 'автомобіль на час ремонту',
        clientAggregateId: 'id ідентифікатор агрегату клієнта',
        diskMaterial: 'матеріал диска',
        diskRadius: 'радіус диска',
        duration: 'тривалість',
        odometerValue: 'пробіг',
        paymentMethod: 'спосіб оплати',
        recommendation: 'рекомендація',
        servicesDiscount: 'знижка на послуги',
        stationNum: 'номер станції',
        status: 'статус',
        vehicleCondition: 'стан автомобіля',
        id: 'id ідентифікатор',
        num: 'номер наряд-замовлення',
        datetime: 'дата та час',
        servicesSum: 'сума за послуги',
        detailsSum: 'сума за деталі',
        businessId: 'ідентифікатор бізнесу',
        viewDatetime: 'дата та час перегляду',
        deliveryDatetime: 'дата та час доставки',
        successDatetime: 'дата та час успішного завершення',
        invoiceReportPrinted: 'запис про рахунок надруковано',
        actOfAcceptanceReportPrinted: 'акт прийому-передачі надруковано',
        completedWorkReportPrinted: 'звіт про виконані роботи надруковано',
        replacementType: 'тип заміни',
        discount: 'знижка',
        orderBloke: 'блок замовлень',
        systemComment: 'системний коментар',
        agreementComment: 'коментар до угоди',
        storageHashes: 'хеші зберігання',
        detailsTotalSum: 'загальна сума за деталі',
        servicesTotalSum: 'загальна сума за послуги',
        servicesSumDiscount: 'знижка на суму послуг',
        detailsSumDiscount: 'знижка на суму деталей',
        totalSum: 'загальна сума',
        totalSumFiscal: 'фіскальна загальна сума',
        imgId: 'id ідентифікатор зображення',
        totalSumDiscount: 'загальна сума зі знижкою',
        printNumber: 'номер друку',
        totalSumWithTax: 'загальна сума з податком',
        totalSumWithTaxFiscal: 'фіскальна загальна сума з податком',
        fiscalTotalSum: 'фіскальна загальна сума',
        tax: 'податок',
        isTaxPayer: 'є платником податків',
        taxRate: 'ставка податку',
        aggregateId: 'id ідентифікатор агрегату, поле Агрегат / Вузол',
        aggregateCode: 'код агрегату',
        aggregateParentCode: 'код батьківського агрегату, поле Агрегат / Вузол',
        aggregateLevel: 'рівень агрегату',
        aggregateName: 'назва агрегату',
        aggregateBrandId: 'id ідентифікатор бренду агрегату',
        aggregateBrandName: 'назва бренду агрегату',
        aggregateNumber: 'номер агрегату',
        aggregateCount: 'кількість агрегатів',
        aggregateComment: 'коментар до агрегату',
        aggregateVin: 'vin агрегату',
        displayVehicle: 'відображати автомобіль',
        stationName: 'назва станції',
        damageTypes: 'типи пошкоджень',
        paid: 'оплачено',
        lastPaymentDatetime: 'дата та час останньої оплати',
        remainder: 'залишок',
        barcode: 'штрих-код',
        totalCount: 'загальна кількість',
        detailsTotalSumWithTax: 'загальна сума за деталі з податком',
        servicesTotalSumWithTax: 'загальна сума за послуги з податком',
        detailsTax: 'податок на деталі',
        servicesTax: 'податок на послуги',
        printDate: 'дата друку',
        roundedFiscalSum: 'округлена фіскальна сума',
        absoluteSum: 'абсолютна сума',
        nextVisitComment: 'коментар до наступного візиту',
        totalSumWithDiscount: 'загальна сума зі знижкою'
    },
    services: {
        $laborId: 'id ідентифікатор роботи',
        $serviceName: 'назва послуги/роботи',
        $measureUnit: 'одиниця виміру',
        $laborKeyGroup: 'група ключових робіт',
        $discount: 'знижка',
        $rowDiscountPrice: 'ціна зі знижкою в рядоку',
        $discountPrice: 'ціна зі знижкою',
        $partRowDiscount: 'знижка на частину рядка',
        $serviceRowDiscount: 'знижка на рядок послуг',
        $count: 'кількість',
        $price: 'ціна',
        $discountSum: 'сума знижки',
        $sum: 'сума'
    },
    recommendations: {
        $orderMillage: 'пробіг замовлення',
        $orderSuccessDatetime: 'дата і час успішного замовлення',
        $orderNum: 'номер замовлення',
        $laborId: 'ідентифікатор роботи',
        $laborName: 'назва роботи',
        $defaultName: 'назва за замовчуванням',
        $recommendationComment: 'коментар до рекомендації',
        $recommendationToDate: 'рекомендація до дати',
        $recommendationToOdometerValue: 'рекомендація до значення одометра',
        $recommendationStatus: 'статус рекомендації',
        $count: 'кількість',
        $servicePrice: 'ціна послуги'
    },
    documentNumber: 'номер документа',
    documentData: 'дані документа',
    invoiceDate: 'дата рахунка',
    invoiceNumber: 'номер рахунка',
    laborsRequest: {
        laborRequestName: {
            $comment: 'коментар',
            $problems: 'проблеми',
            $positions: 'місце'
        },
        $storeGroupId: 'id ідентифікатор групи складів',
        $counterparty: 'контрагент',
        $businessSupplierId: 'id ідентифікатор постачальника бізнесу',
        $businessSupplierName: 'назва постачальника бізнесу',
        employeeComment: {
            $comment: 'коментар',
            $problems: 'проблеми',
            $positions: 'місце'
        },
        $actionType: 'тип дії',
        $serviceId: 'id ідентифікатор послуги',
        $stage: 'етап',
        $order: 'замовлення',
        $groupName: 'назва групи товару',
        $complexId: 'id ідентифікатор комплексу',
        $complexName: 'назва комплексу',
        $customComplexId: 'ідентифікатор власного комплексу',
        $customComplexName: 'назва власного комплексу',
        $count: 'кількість',
        $servicePrice: 'ціна послуги',
        $defaultName: 'значення за замовчуванням',
        $serviceName: 'назва послуги',
        $employeeName: 'ім\'я співробітника'
    },
    diagnosticsV2: {
        $placeId: 'id місця',
        $placeName: 'імʼя місця',
        places: {
            $index: 'індекс місця',
            $leftAndRight: 'ліворуч і праворуч',
            $showFront: 'передня сторона',
            $showReverse: 'задня сторона',
            $status: 'статус',
            $bold: 'жирний',
            $problemFromComment: 'проблема з коментарем',
            $comment: 'коментар',
            $templateId: 'id ідентифікатор шаблону',
            $templateName: 'назва шаблону',
            $groupId: 'ідентифікатор етапу',
            $groupName: 'етап',
            $systemId: 'ідентифікатор системи',
            $systemName: 'назва системи',
            $partId: 'ідентифікатор частини',
            $partName: 'назва частини',
            $coordinateSchemeId: 'ідентифікатор координатної схеми',
            $placeName: 'назва місця',
            $partAction: 'дія з запчастиною',
            $partAnswer: 'статус запчастини',
            $partComment: 'коментар до запчастини',
            $partProblemsMask: 'маска проблем частини',
            $partRCL: 'справа чи зліва запчастина',
            $partFCR: 'попереду чи позаду запчастина'
        }
    },
    schemeDiagnostics: {
        $schemeName: 'назва схеми',
        $unitId: 'ідентифікатор рядка діагностики',
        $unitName: 'назва рядка діагностики',
        $unitTooltip: 'підказка рядка діагностики',
        $unitComment: 'коментар рядка діагностики',
        $commentArray: 'масив коментарів',
        $status: 'статус',
        $comment: 'коментар',
        $photos: 'фото',
        $statusColor: 'колір статусу',
        $index: 'індекс'
    }
};
