import { Table } from 'antd';
import { Catcher } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

export default class ClientSearchTable extends Component {
    constructor(props) {
        super(props);
        this.aggregateColumns = [
            {
                title: <FormattedMessage id='name' />,
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: <FormattedMessage id='surname' />,
                dataIndex: 'surname',
                key: 'surname'
            },
            {
                title: <FormattedMessage id='add_order_form.phone' />,
                dataIndex: 'phones',
                key: 'phones',
                render: phones => phones.join(', ')
            },
            {
                title: <FormattedMessage id='cleint_container_aggregate' />,
                key: 'aggregateName',
                render: row => {
                    return (
                        _.get(row.aggregateName, 'ua', null) &&
                        `${_.get(row.aggregateName, 'ua', null)} (${row.aggregatebrandName}) ${row.aggregateVin} ${
                            row.aggregateNumber
                        }`
                    );
                }
            }
        ];
        this.columns = [
            {
                title: <FormattedMessage id='name' />,
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: <FormattedMessage id='surname' />,
                dataIndex: 'surname',
                key: 'surname'
            },
            {
                title: <FormattedMessage id='add_order_form.phone' />,
                dataIndex: 'phones',
                key: 'phones',
                render: phones => phones.join(', ')
            },
            {
                title: <FormattedMessage id='vehicle' />,
                key: 'vehicle',
                render: row => {
                    return row.model && `${row.make} ${row.model} (${row.year})`;
                }
            },
            {
                title: <FormattedMessage id='add_order_form.vin' />,
                key: 'vin',
                render: row => {
                    return row.number ? `${row.number} ${row.vin}` : row.vin;
                }
            }
        ];
    }

    render() {
        const { clients, visible, setClientSelection, clientsSearching, isSearchAggregates } = this.props;
        const { columns, aggregateColumns } = this;

        return (
            <Catcher>
                <Table
                    bordered
                    className={visible ? Styles.clientsSearchTable : Styles.clientsSearchTableHidden}
                    columns={isSearchAggregates ? aggregateColumns : columns}
                    dataSource={clients}
                    loading={clientsSearching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    onRow={row => {
                        return {
                            onClick: async () => {
                                if (!isSearchAggregates) {
                                    const { vehicles } = await fetchAPI('GET', `clients/${row.clientId}`);
                                    setClientSelection({ ...row, vehicles });
                                } else {
                                    const aggregates = await fetchAPI('GET', 'client/aggregates', {
                                        clientId: row.clientId
                                    });
                                    setClientSelection({ ...row, aggregates });
                                }
                            }
                        };
                    }}
                    pagination={false}
                    size='small'
                    sticky
                />
            </Catcher>
        );
    }
}
