import { DatePicker, Input, InputNumber, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { numeralFormatter, numeralParser } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
export default class AddVehicleRegulationsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            if (this.props.regulationModalRow) {
                this.setState({
                    ...this.props.regulationModalRow,
                    regulationKm: this.props.regulationModalRow.km,
                    regulationMonths: this.props.regulationModalRow.months,
                    lastChangeKm: this.props.regulationModalRow.lastKm
                });
            }
        }
    }

    handleOk = async () => {
        const { regulationName, regulationKm, regulationMonths, lastChangeKm, lastChangeMonth } = this.state;
        const { regulationModalRow } = this.props;

        const { id } = regulationModalRow;

        if (this.props.regulationModalRow) {
            await this.props.editRegulationRow(
                id,
                regulationName,
                regulationKm || undefined,
                regulationMonths || undefined,
                lastChangeKm || undefined,
                dayjs(lastChangeMonth).format('YYYY-MM-DD') || undefined
            );
            await setTimeout(() => this.props.fetchVehicleRegulations(), 100);
            this.handleCancel();
        }
    };

    handleCancel() {
        this.setState({
            regulationName: undefined,
            regulationKm: undefined,
            regulationMonths: undefined,
            lastChangeKm: undefined,
            lastChangeMonth: undefined
        });
        this.props.hideModal();
    }

    // editRegulationRow = async () => {
    //     const { regulationName, regulationKm, regulationMonths, lastChangeKm, lastChangeMonth } = this.state;
    //     const { regulationModalRow } = this.props;
    //     await fetchAPI('PUT', 'vehicle/regulations', undefined, {
    //         id: regulationModalRow.id,
    //         regulationName,
    //         regulationKm,
    //         regulationMonths,
    //         lastKm: lastChangeKm,
    //         lastDate: lastChangeMonth,
    //         visibility: true
    //     });
    //     await setTimeout(() => this.props.fetchVehicleRegulations(), 500);
    // };

    render() {
        const { regulationName, regulationKm, regulationMonths, lastChangeKm, lastChangeMonth } = this.state;

        return (
            <Modal
                destroyOnClose
                maskClosable={false}
                onCancel={() => {
                    this.handleCancel();
                }}
                onOk={() => {
                    this.handleOk();
                }}
                visible={this.props.visible}
                width='fit-content'
                zIndex={200}
            >
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <div
                        style={{
                            width: '30%',
                            marginRight: 12
                        }}
                    >
                        <div>
                            <FormattedMessage id='regulations.name' />
                        </div>
                        <Input
                            onChange={event => {
                                this.setState({
                                    regulationName: event.target.value
                                });
                            }}
                            value={regulationName}
                        />
                    </div>

                    <div className={Styles.inputsStyle}>
                        <div>
                            <FormattedMessage id='regulations.regulation' /> {': '}
                            <FormattedMessage id='regulations.km' />
                        </div>
                        <InputNumber
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            onChange={regulationKm => {
                                this.setState({
                                    regulationKm
                                });
                            }}
                            parser={value => numeralParser(value)}
                            value={Math.round(regulationKm * 100) / 100 || 0}
                            width='100px'
                        />
                    </div>
                    <div className={Styles.inputsStyle}>
                        <div>
                            <FormattedMessage id='regulations.regulation' />
                            {': '}
                            <FormattedMessage id='regulations.months' />
                        </div>

                        <InputNumber
                            onChange={regulationMonths => {
                                this.setState({
                                    regulationMonths
                                });
                            }}
                            value={regulationMonths}
                            width={100}
                        />
                    </div>
                    <div className={Styles.inputsStyle}>
                        <div>
                            <FormattedMessage id='regulations.last_change' />
                            {': '}
                            <FormattedMessage id='regulations.km' />
                        </div>
                        <InputNumber
                            decimalSeparator=','
                            formatter={value => numeralFormatter(value)}
                            min={0}
                            onChange={lastChangeKm => {
                                this.setState({
                                    lastChangeKm
                                });
                            }}
                            parser={value => numeralParser(value)}
                            value={Math.round(lastChangeKm * 100) / 100 || 0}
                            width={100}
                        />
                    </div>
                    <div className={Styles.inputsStyle}>
                        <div>
                            <FormattedMessage id='regulations.last_change' />
                            {': '}
                            <FormattedMessage id='date' />
                        </div>
                        <DatePicker
                            allowClear={false}
                            format='YYYY-MM-DD'
                            onChange={lastChangeMonth =>
                                this.setState({
                                    lastChangeMonth
                                })
                            }
                            value={lastChangeMonth}
                            width={100}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}
