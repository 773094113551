import { Form } from '@ant-design/compatible';
import { CreditCardOutlined, WalletOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import {
    DecoratedDatePicker,
    DecoratedInput,
    DecoratedSelect,
    DecoratedSlider,
    DecoratedTextArea,
    DecoratedTimePicker
} from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import book from 'routes/book';
import {
    addDuration,
    getDateTimeConfig,
    goTo,
    isForbidden,
    mergeDateTime,
    permissions
} from 'utils';
import {
    formCommentLayout,
    formHeaderItemLayout,
    formRecommendationLayout,
    fromExpandedCommentLayout
} from '../layouts';
import Styles from './styles.m.css';

const FormItem = Form.Item;
const { Option } = Select;

const cx = classNames.bind(Styles);

// TODO: move it into utils
// blocks hours for time picker
const getAvailableHoursDisabledHours = propsAvailableHours => () => {
    const availableHours = _.get(propsAvailableHours, '0', []);

    return _.difference(
        Array(24)
            .fill(1)
            .map((value, index) => index),
        availableHours.map(availableHour => Number(dayjs(availableHour).format('HH')))
    );
};

// TODO: move it into utils
// blocks minutes for time picker
const getAvailableHoursDisabledMinutes = propsAvailableHours => hour => {
    const availableHours = _.get(propsAvailableHours, '0', []);

    const availableMinutes = availableHours
        .map(availableHour => dayjs(availableHour))
        .filter(availableHour => Number(availableHour.format('HH')) === hour)
        .map(availableHour => Number(availableHour.format('mm')));

    return _.difference([0, 30], availableMinutes);
};

const getDisabledHours = (startTime = 0, endTime = 23) => {
    const availableHours = [];
    for (let i = Number(startTime); i <= Number(endTime); i++) {
        availableHours.push(i);
    }

    return _.difference(
        Array(24)
            .fill(1)
            .map((value, index) => index),
        availableHours
    );
};

@injectIntl
export default class OrderFormBody extends Component {
    constructor(props) {
        super(props);

        const {
            intl: { formatMessage }
        } = props;

        // reusable validation rule
        this.requiredRule = [
            {
                required: true,
                message: formatMessage({
                    id: 'required_field'
                })
            }
        ];

        const availableHoursDisabledMinutes = getAvailableHoursDisabledMinutes(
            props.availableHours
        );
        const availableHoursDisabledHours = getAvailableHoursDisabledHours(props.availableHours);

        const stationsOptions = this._getStationsOptions();
        const managersOptions = this._getManagersOptions();
        const employeesOptions = this._getEmployeesOptions();

        const recommendationStyles = this._getRecommendationStyles();

        const paymentMethodOptions = [
            <Option key='cash' value='cash'>
                <WalletOutlined />
                <FormattedMessage id='add_order_form.cash' />
            </Option>,
            <Option key='noncash' value='noncash'>
                <CreditCardOutlined />
                <FormattedMessage id='add_order_form.non-cash' />
            </Option>,
            <Option key='visa' value='visa'>
                <CreditCardOutlined />
                <FormattedMessage id='add_order_form.visa' />
            </Option>
        ];
        // TODO: move into utils
        // <FormatMessage id=''> triggers re-render cuz it is creating new obj
        // use formatMassage({id: }) instead
        this._localizationMap = {};

        const deliveryDatetimeConfig = this._getDeliveryDatetimeConfig();
        const beginDatetimeConfig = this._getBeginDatetimeConfig();
        // we write all data to state to handle updates correctly
        this.state = {
            deliveryDatetimeConfig,
            availableHoursDisabledMinutes,
            availableHoursDisabledHours,
            beginDatetimeConfig,
            stationsOptions,
            employeesOptions,
            managersOptions,
            paymentMethodOptions,
            recommendationStyles
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
    }

    componentDidUpdate(prevProps) {
        const oldAvailableHours = _.get(prevProps, ['availableHours', '0']);
        const newAvailableHours = _.get(this.props, ['availableHours', '0']);
        // if availableHours has been changed we need to generate new configs
        if (oldAvailableHours !== newAvailableHours) {
            const availableHoursDisabledMinutes = getAvailableHoursDisabledMinutes(
                this.props.availableHours
            );
            const availableHoursDisabledHours = getAvailableHoursDisabledHours(
                this.props.availableHours
            );

            this.setState({
                availableHoursDisabledHours,
                availableHoursDisabledMinutes
            });
        }

        if (prevProps.stations !== this.props.stations) {
            const stationsOptions = this._getStationsOptions();
            this.setState({ stationsOptions });
        }

        if (prevProps.managers !== this.props.managers) {
            const managersOptions = this._getManagersOptions();
            this.setState({ managersOptions });
        }

        if (prevProps.employees !== this.props.employees) {
            const employeesOptions = this._getEmployeesOptions();
            this.setState({ employeesOptions });
        }
        // check all fields related for deliveryDatetime
        const deliveryFields = [
            'schedule',
            'zeroStationLoadBeginDate',
            'zeroStationLoadBeginTime',
            'zeroStationLoadDuration',
            'deliveryDate'
        ];
        // check deliveryDatetime depended properties changes
        // if dayjs -> toISOString to check dayjs objects as strings to prevent re-renders
        const deliveryConfigUpdate = deliveryFields.reduce((prev, cur) => {
            const parsedThisProps = dayjs.isDayjs(this.props[cur])
                ? this.props[cur].toISOString()
                : this.props[cur];
            const parsedPrevProps = dayjs.isDayjs(prevProps[cur])
                ? prevProps[cur].toISOString()
                : prevProps[cur];

            return prev || parsedThisProps !== parsedPrevProps;
        }, false);
        // if deliveryDatetime fields have been updated
        // get new config and set it to local state to trigger componentUpdate with new config
        if (deliveryConfigUpdate) {
            this.setState({
                deliveryDatetimeConfig: this._getDeliveryDatetimeConfig()
            });
        }
        // update check for beginDatetime
        const currentZeroStationLoadBeginDate = this.props.zeroStationLoadBeginDate
            ? this.props.zeroStationLoadBeginDate.toISOString()
            : void 0;
        const prevZeroStationLoadBeginDate = prevProps.zeroStationLoadBeginDate
            ? prevProps.zeroStationLoadBeginDate.toISOString()
            : void 0;

        if (
            this.props.schedule !== prevProps.schedule ||
            currentZeroStationLoadBeginDate !== prevZeroStationLoadBeginDate
        ) {
            this.setState({
                beginDatetimeConfig: this._getBeginDatetimeConfig()
            });
        }
    }

    _getRecommendationStyles() {
        const { orderId: id, orderHistory } = this.props;
        const orders = _.get(orderHistory, 'orders');
        const orderIndexInHistory = _.findIndex(orders, { id });
        const prevRecommendation =
            orderIndexInHistory !== -1
                ? _.get(orderHistory, ['orders', orderIndexInHistory + 1, 'recommendation'])
                : null;

        const value = cx({
            comment: true,
            commentExtended: !prevRecommendation
        });

        return { value, prevRecommendation };
    }

    // TODO: move into utils
    bodyUpdateIsForbidden() {
        return isForbidden(this.props.user, permissions.ACCESS_ORDER_BODY);
    }

    _getBeginDatetimeConfig() {
        const { schedule } = this.props;
        const { disabledDate, beginTime } = getDateTimeConfig(void 0, schedule);

        return { disabledDate, beginTime };
    }

    _getDeliveryDatetimeConfig() {
        const {
            schedule,
            zeroStationLoadBeginDate,
            zeroStationLoadBeginTime,
            zeroStationLoadDuration,
            deliveryDate
        } = this.props;

        const excludeConfig = [
            {
                momentDate: zeroStationLoadBeginDate,
                momentTime: zeroStationLoadBeginTime,
                duration: zeroStationLoadDuration
            }
        ];

        const {
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            disabledDate: dateTimeDisabledDate,
            beginTime
        } = getDateTimeConfig(dayjs(deliveryDate), schedule, excludeConfig);

        const initialBeginDatetime = dayjs(zeroStationLoadBeginDate).set({
            hours: 0,
            minutes: 0,
            milliseconds: 0,
            seconds: 0
        });

        const sameOfBeforeDisabledDate = date =>
            dateTimeDisabledDate(date) || (date && date.isSameOrBefore(initialBeginDatetime));

        const initialDeliveryDatetime =
            zeroStationLoadBeginDate && zeroStationLoadBeginTime && zeroStationLoadDuration
                ? addDuration(
                      mergeDateTime(zeroStationLoadBeginDate, zeroStationLoadBeginTime),
                      zeroStationLoadDuration
                  )
                : void 0;

        return {
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            disabledDate: sameOfBeforeDisabledDate,
            beginTime,
            initialDeliveryDatetime
        };
    }

    // prevent re-renders
    _getLocalization(key) {
        if (!this._localizationMap[key]) {
            this._localizationMap[key] = this.props.intl.formatMessage({
                id: key
            });
        }

        return this._localizationMap[key];
    }

    _getStationsOptions = () => {
        return _.get(this.props, 'stations', []).map(({ name, num }) => {
            return (
                <Option key={String(num)} value={num}>
                    {name || String(num)}
                </Option>
            );
        });
    };

    _getManagersOptions = () => {
        return _.get(this.props, 'managers', []).map(manager => (
            <Option key={`manager-${manager.id}`} disabled={manager.disabled} value={manager.id}>
                {`${manager.managerSurname} ${manager.managerName}`}
            </Option>
        ));
    };

    _getEmployeesOptions = () => {
        return _.get(this.props, 'employees', []).map(employee => {
            if (!employee.disabled) {
                return (
                    <Option
                        key={`employee-${employee.id}`}
                        disabled={employee.disabled}
                        value={employee.id}
                    >
                        {`${employee.surname} ${employee.name}`}
                    </Option>
                );
            }
        });
    };

    _redirectToCashFlow = () => {
        if (!isForbidden(this.props.user, permissions.ACCESS_ACCOUNTING)) {
            goTo(book.cashFlowPage, {
                cashFlowFilters: { ...this.props.cashFlowFilters }
            });
        }
    };

    _totalStyles = disabled =>
        cx({
            totalDisabled: disabled,
            total: true
        });

    render() {
        // TODO: decomposite for separate view components
        const dateBlock = this._renderDateBlock();
        const masterBlock = this._renderMasterBlock();
        const duration = this._renderDuration();
        const comments = this._renderCommentsBlock();

        return (
            <div className={Styles.formHeader}>
                <div className={Styles.headerColumns}>{dateBlock}</div>
                {duration}
                {masterBlock}
                {comments}
            </div>
        );
    }

    _renderDuration = () => {
        const {
            fetchedOrder,
            totalHours,
            fields,
            zeroStationLoadDuration,
            deliveryDate,
            form: { getFieldDecorator },
            intl: { formatMessage },
            errors
        } = this.props;
        const {
            disabledDate,
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            beginTime,
            initialDeliveryDatetime: calculatedDeliveryDatetime
        } = this.state.deliveryDatetimeConfig;

        const initialDeliveryDatetime = _.get(fetchedOrder, 'order.deliveryDatetime');

        return (
            <div className={Styles.durationBlock}>
                <DecoratedSlider
                    className={`${Styles.duration} ${Styles.deliveryDatetime}`}
                    colon={false}
                    defaultGetValueProps
                    disabled={this.bodyUpdateIsForbidden()}
                    errors={errors}
                    field='stationLoads[0].duration'
                    fieldValue={_.get(fields, 'stationLoads[0].duration')}
                    formItem
                    formItemLayout={formHeaderItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={_.get(fetchedOrder, 'order.duration') || totalHours}
                    label={`${this._getLocalization(
                        'time'
                    )} (${zeroStationLoadDuration}${this._getLocalization(
                        'add_order_form.hours_shortcut'
                    )})`}
                    max={8}
                    min={0}
                    step={0.5}
                />
                <div style={{ display: 'none' }}>
                    <DecoratedInput
                        className={`${Styles.duration} ${Styles.deliveryDatetime}`}
                        colon={false}
                        defaultGetValueProps
                        disabled={this.bodyUpdateIsForbidden()}
                        errors={errors}
                        field='stationLoads[0].status'
                        fieldValue={_.get(fields, 'stationLoads[0].status')}
                        formItem
                        formItemLayout={formHeaderItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        hiddeninput='hiddeninput'
                        initialValue='TO_DO'
                        label={`${this._getLocalization(
                            'time'
                        )} (${zeroStationLoadDuration}${this._getLocalization(
                            'add_order_form.hours_shortcut'
                        )})`}
                    />
                    <DecoratedDatePicker
                        className={Styles.deliveryDatetime}
                        colon={false}
                        defaultGetValueProps
                        disabled={this.bodyUpdateIsForbidden()}
                        disabledDate={disabledDate}
                        errors={errors}
                        field='deliveryDate'
                        fieldValue={
                            _.get(fields, 'deliveryDate')
                                ? _.get(fields, 'deliveryDate').toISOString()
                                : void 0
                        }
                        format='YYYY-MM-DD'
                        formItem
                        formItemLayout={formHeaderItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        hasFeedback
                        initialValue={
                            initialDeliveryDatetime
                                ? dayjs(initialDeliveryDatetime).toISOString()
                                : calculatedDeliveryDatetime
                                ? calculatedDeliveryDatetime.toISOString()
                                : void 0
                        }
                        label={this._getLocalization('add_order_form.delivery_date')}
                        placeholder={this._getLocalization('add_order_form.select_date')} // HH:mm
                        rules={this.requiredRule}
                        showTime={false}
                    />
                    <DecoratedTimePicker
                        className={Styles.deliveryDatetime}
                        defaultGetValueProps
                        defaultOpenValue={dayjs(`${beginTime}:00`, 'HH:mm:ss').toISOString()}
                        disabled={Boolean(this.bodyUpdateIsForbidden() || !deliveryDate)}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        disabledSeconds={disabledSeconds}
                        errors={errors}
                        field='deliveryTime'
                        fieldValue={
                            _.get(fields, 'deliveryTime')
                                ? _.get(fields, 'deliveryTime').toISOString()
                                : void 0
                        }
                        formatMessage={formatMessage}
                        formItem
                        formItemLayout={formHeaderItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        hasFeedback
                        initialValue={
                            initialDeliveryDatetime
                                ? dayjs(initialDeliveryDatetime).toISOString()
                                : calculatedDeliveryDatetime
                                ? calculatedDeliveryDatetime.toISOString()
                                : void 0
                        }
                        label={this._getLocalization('add_order_form.delivery_time')}
                        minuteStep={30}
                        placeholder={this._getLocalization('add_order_form.provide_time')}
                        rules={this.requiredRule}
                    />
                </div>
            </div>
        );
    };

    _renderDateBlock = () => {
        const {
            location,
            fetchedOrder,

            zeroStationLoadBeginDate,
            zeroStationLoadStation,
            fields,
            errors,
            schedule
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { getFieldDecorator } = this.props.form;

        const { disabledDate, beginTime } = this.state.beginDatetimeConfig;

        const beginDatetime =
            _.get(fetchedOrder, 'order.beginDatetime') ||
            (this.bodyUpdateIsForbidden() ? void 0 : _.get(location, 'state.beginDatetime'));

        const momentBeginDatetime = beginDatetime ? dayjs(beginDatetime).toISOString() : void 0;

        const dayNumber = dayjs(_.get(this.props, 'stationLoads[0].beginDate')).day();
        let disabledHours;
        if (schedule && dayNumber) {
            let index;
            switch (dayNumber) {
                case 6:
                    index = 1;
                    break;
                case 7:
                    index = 2;
                    break;
                default:
                    index = 0;
            }

            disabledHours = getDisabledHours(
                schedule[index] && schedule[index].beginTime
                    ? schedule[index].beginTime.split(/[.:]/)[0]
                    : 9,
                schedule[index] && schedule[index].endTime
                    ? schedule[index].endTime.split(/[.:]/)[0]
                    : 20
            );
        }

        return (
            <div className={Styles.headerCol}>
                <DecoratedSelect
                    className={Styles.datePanelItem}
                    colon={false}
                    defaultGetValueProps
                    disabled={this.bodyUpdateIsForbidden()}
                    errors={errors}
                    field='stationLoads[0].station'
                    fieldValue={_.get(fields, 'stationLoads[0].station')}
                    formItem
                    formItemLayout={formHeaderItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={
                        _.get(fetchedOrder, 'order.stationNum') ||
                        (this.bodyUpdateIsForbidden()
                            ? void 0
                            : _.get(location, 'state.stationNum'))
                    }
                    label={this._getLocalization('add_order_form.station')}
                    placeholder={this._getLocalization('add_order_form.select_station')}
                >
                    {this.state.stationsOptions}
                </DecoratedSelect>
                <DecoratedDatePicker
                    allowClear={false}
                    className={Styles.datePanelItem}
                    colon={false}
                    defaultGetValueProps
                    disabled={this.bodyUpdateIsForbidden()}
                    disabledDate={disabledDate}
                    errors={errors}
                    field='stationLoads[0].beginDate'
                    fieldValue={
                        _.get(fields, 'stationLoads[0].beginDate')
                            ? _.get(fields, 'stationLoads[0].beginDate').toISOString()
                            : void 0
                    }
                    // formatMessage={ formatMessage }
                    format='YYYY-MM-DD'
                    formItem
                    formItemLayout={formHeaderItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={momentBeginDatetime}
                    label={this._getLocalization('add_order_form.enrollment_date')}
                    placeholder={this._getLocalization('add_order_form.select_date')} // HH:mm
                    rules={this.requiredRule}
                    showTime={false}
                />
                <DecoratedTimePicker
                    className={Styles.datePanelItem}
                    defaultGetValueProps
                    defaultOpenValue={dayjs(`${beginTime}:00`, 'HH:mm:ss').toISOString()}
                    disabled={this.bodyUpdateIsForbidden() || !zeroStationLoadBeginDate}
                    disabledHours={() => disabledHours}
                    errors={errors}
                    field='stationLoads[0].beginTime'
                    fieldValue={
                        _.get(fields, 'stationLoads[0].beginTime')
                            ? _.get(fields, 'stationLoads[0].beginTime').toISOString()
                            : void 0
                    }
                    formatMessage={formatMessage}
                    formItem
                    formItemLayout={formHeaderItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    hideDisabledOptions
                    initialValue={momentBeginDatetime}
                    label={this._getLocalization('add_order_form.applied_on')}
                    minuteStep={30}
                    placeholder={this._getLocalization('add_order_form.provide_time')}
                    rules={this.requiredRule}
                />
            </div>
        );
    };

    _renderMasterBlock = () => {
        const { fetchedOrder, managers, authentificatedManager, fields, errors, location } =
            this.props;

        const isOwnBusiness =
            _.find(managers, {
                id: authentificatedManager
            }) || void 0;

        const { getFieldDecorator } = this.props.form;

        return (
            <div className={Styles.headerCol}>
                <DecoratedSelect
                    className={Styles.datePanelItem}
                    colon={false}
                    defaultGetValueProps
                    disabled={this.bodyUpdateIsForbidden()}
                    errors={errors}
                    field='manager'
                    fieldValue={_.get(fields, 'manager')}
                    formItem
                    formItemLayout={formHeaderItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={
                        _.get(fetchedOrder, 'order.managerId') ||
                        (this.bodyUpdateIsForbidden()
                            ? void 0
                            : isOwnBusiness && authentificatedManager)
                    }
                    label={this._getLocalization('add_order_form.manager')}
                    placeholder={this._getLocalization('add_order_form.select_manager')}
                    rules={this.requiredRule}
                >
                    {this.state.managersOptions}
                </DecoratedSelect>
                <DecoratedSelect
                    className={Styles.durationPanelItem}
                    defaultGetValueProps
                    disabled={this.bodyUpdateIsForbidden()}
                    errors={errors}
                    field='employee'
                    fieldValue={_.get(fields, 'employee')}
                    formItem
                    formItemLayout={formHeaderItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={
                        _.get(fetchedOrder, 'order.employeeId') ||
                        (location.state ? location.state.employeeId : undefined)
                    }
                    label={this._getLocalization('order_form_table.master')}
                    placeholder={this._getLocalization('order_form_table.select_master')}
                >
                    {this.state.employeesOptions}
                </DecoratedSelect>
            </div>
        );
    };

    _renderCommentsBlock = () => {
        const { fetchedOrder, user, fields, errors } = this.props;
        const { ACCESS_ORDER_COMMENTS } = permissions;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className={Styles.commentsBlock}>
                <DecoratedTextArea
                    autoSize={this._recommendationAutoSize}
                    className={this.state.recommendationStyles.value}
                    colon={false}
                    defaultGetValueProps
                    disabled={isForbidden(user, ACCESS_ORDER_COMMENTS)}
                    errors={errors}
                    field='comment'
                    fieldValue={_.get(fields, 'comment')}
                    formItem
                    formItemLayout={
                        this.state.recommendationStyles.prevRecommendation
                            ? formCommentLayout
                            : fromExpandedCommentLayout
                    }
                    getFieldDecorator={getFieldDecorator}
                    initialValue={_.get(fetchedOrder, 'order.comment')}
                    label={this._getLocalization('add_order_form.client_comments')}
                    placeholder={this._getLocalization('add_order_form.client_comments')}
                    rules={this.recommendationRules}
                />
                {this.state.recommendationStyles.prevRecommendation && (
                    <DecoratedTextArea
                        autoSize={this._prevRecommendationAutoSize}
                        className={Styles.comment}
                        colon={false}
                        disabled
                        errors={errors}
                        field='prevRecommendation'
                        formItem
                        formItemLayout={formRecommendationLayout}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={this.state.recommendationStyles.prevRecommendation}
                        label={this._getLocalization('add_order_form.prev_order_recommendations')}
                        placeholder={this._getLocalization('add_order_form.client_comments')}
                        rules={this.recommendationRules}
                    />
                )}
            </div>
        );
    };
}
