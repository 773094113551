import { DeleteOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table } from 'antd';
import { Layout } from 'commons';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import AddCrewModal from './components/AddCrewModal';

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const { Option } = Select;

const CrewsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ intl, user, isMobile }) => {
    const [filters, setFilters] = useState();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [crews, setCrews] = useState([]);
    const [count, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentCrew, setCurrentCrew] = useState();
    const history = useHistory();

    const resetFilters = () => {
        setFilters(undefined);
    };

    const editCrew = row => {
        setEdit(row);
        setOpen(true);
    };

    const fetchCrews = useCallback(
        async filters => {
            setLoading(true);
            const response = await fetchAPI('GET', 'crews', { page, pageSize, filters }, null, {
                handleErrorInternally: true
            });

            setCrews(response.crews);
            setTotalCount(response.count);
            setLoading(false);
        },
        [page, pageSize]
    );

    const deleteCrew = async row => {
        await fetchAPI(
            'DELETE',
            '/crew',
            null,
            {
                id: row.id
            },
            {
                handleErrorInternally: true
            }
        );

        await fetchCrews(filters);
    };

    useEffect(() => {
        fetchCrews();
    }, [fetchCrews]);

    const handleSetFilters = useMemo(() => {
        const loadOptions = value => {
            fetchCrews(value);
        };

        return _.debounce(loadOptions, 600);
    }, [fetchCrews]);

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='№' />,
                key: 'num',
                width: '3%',
                render: (row, number, index) => {
                    return index + 1;
                }
            },
            {
                title: <FormattedMessage id='crews.crew' />,
                key: 'crew',
                width: '15%',
                render: row => {
                    return row.crewName ? (
                        <div>
                            {row.crewName}
                            <span>
                                {row.crewMembers && row.crewMembers.length
                                    ? `(${row.crewMembers
                                          .slice(0, 3)
                                          .map(({ employee, percent }) => `${employee} - ${percent}%`)}${
                                          get(row.crewMembers, 'length') > 3 ? ', ...' : ''
                                      })`
                                    : undefined}
                            </span>
                        </div>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },

            {
                title: <FormattedMessage id='status' />,
                key: 'status',
                align: 'center',
                width: '10%',
                render: row => {
                    return (
                        <React.Fragment>
                            <FormattedMessage id={`crews.status.${row.crewStatus}`} />
                        </React.Fragment>
                    );
                }
            },
            {
                title: <FormattedMessage id='crews.last_shift' />,
                key: 'shift',
                align: 'center',
                width: '10%',
                render: row => {
                    return row.date ? dayjs(row.date).format('DD.MM.YYYY') : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='responsible' />,
                key: 'responsible',
                align: 'center',
                width: '10%',
                render: row => {
                    return row.crewResponsible ? row.crewResponsible : <FormattedMessage id='long_dash' />;
                }
            },
            {
                key: 'edit',
                align: 'center',
                width: '10%',
                render: row => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button icon={<EditOutlined />} onClick={() => editCrew(row)} />
                            <Button icon={<DeleteOutlined />} onClick={() => deleteCrew(row)} />
                        </div>
                    );
                }
            }
        ];
    }, []);

    const pagination = {
        pageSize,
        total: Math.ceil(count / pageSize) * pageSize,
        hideOnSinglePage: true,
        current: page,
        onChange: async (page, pageSize) => {
            await setPage(page);
            await setPageSize(pageSize);
        }
    };

    return (
        <Layout
            className='interactions_page'
            // description={<FormattedMessage id='navigation.relationships' />}
            controls={
                <Button disabled={!isGrantAccessed(user, grants.CREWS)} onClick={() => setOpen(true)} type='primary'>
                    <FormattedMessage id='crews.create_crew' />
                </Button>
            }
            title={<FormattedMessage id='crews.title_crews' />}
        >
            <div>
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <Input
                        allowClear
                        onChange={event => {
                            handleSetFilters({ crewName: event.target.value || undefined });
                        }}
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'crews.search_by_name'
                        })}
                        style={{ marginBottom: 8, marginRight: 8, width: isMobile ? '95%' : '25%' }}
                        value={filters && filters.crewName}
                    />

                    <Select
                        onChange={value => {
                            handleSetFilters({ crewStatus: value });
                        }}
                        placeholder={intl.formatMessage({
                            id: 'status'
                        })}
                        style={{ color: 'var(--text)', width: '20%' }}
                        value={filters && filters.crewStatus}
                    >
                        <Option key='ACTIVE' value='ACTIVE'>
                            <FormattedMessage id='crews.status.ACTIVE' />
                        </Option>
                        <Option key='CANCELED' value='CANCELED'>
                            <FormattedMessage id='crews.status.CANCELED' />
                        </Option>
                    </Select>

                    <Button
                        disabled={!isGrantAccessed(user, grants.CREWS)}
                        icon={<UndoOutlined />}
                        onClick={resetFilters}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={crews}
                loading={loading}
                pagination={pagination}
                rowKey='id'
                scroll={isMobile ? { x: 1000 } : {}}
            />
            <AddCrewModal
                crews={crews}
                currentCrew={currentCrew}
                edit={edit}
                fetchCrews={fetchCrews}
                hideModal={() => {
                    setOpen(false);
                    setEdit(undefined);
                }}
                open={open}
                setEdit={setEdit}
            />
        </Layout>
    );
});

export default injectIntl(CrewsPage);
