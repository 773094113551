export const formHeaderItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 9 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 15 }
    },
    colon: false
};

export const formCommentLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 7 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 17 }
    },
    colon: false
};

export const formRecommendationLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 10 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 14 }
    },
    colon: false
};
export const fromExpandedCommentLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 3 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 21 }
    },
    colon: false
};

export const formHorizontalItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 11 },
        xxl: { span: 10 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 13 },
        xxl: { span: 14 }
    },
    colon: false
};

export const formVerticalLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 24 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 24 }
    },
    colon: false
};

export const formItemLayout = {
    labelCol: {
        xl: { span: 24 },
        xxl: { span: 4 }
    },
    wrapperCol: {
        xl: { span: 24 },
        xxl: { span: 20 }
    },
    colon: false
};

export const formItemAutoColLayout = {
    labelCol: {
        xl: { span: 24 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xl: { span: 24 },
        xxl: { span: 12 }
    },
    colon: false
};

export const formItemTotalLayout = {
    labelCol: {
        xl: { span: 24 },
        xxl: { span: 10 }
    },
    wrapperCol: {
        xl: { span: 24 },
        xxl: { span: 14 }
    },
    colon: false
};
