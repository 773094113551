import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export function columnsConfig(props) {
    const { formatMessage, removeClientVehicle, openEditModal } = props;

    const makeNameCol = {
        title: formatMessage({
            id: 'add_client_form.make'
        }),
        dataIndex: 'makeName',
        key: 'makeName'
    };

    const modelNameCol = {
        title: formatMessage({
            id: 'add_client_form.model'
        }),
        dataIndex: 'modelName',
        key: 'modelName'
    };

    const yearCol = {
        title: formatMessage({
            id: 'add_client_form.year'
        }),
        dataIndex: 'year',
        key: 'year'
    };

    const modificationNameCol = {
        title: formatMessage({
            id: 'add_client_form.modification'
        }),
        dataIndex: 'modificationName',
        key: 'modificationName'
    };

    const vinCol = {
        title: formatMessage({
            id: 'add_client_form.vin'
        }),
        dataIndex: 'vin',
        key: 'vin'
    };

    const numberCol = {
        title: formatMessage({
            id: 'add_client_form.number'
        }),
        dataIndex: 'number',
        key: 'number'
    };

    const managerName = {
        title: formatMessage({
            id: 'add_client_form.responsible'
        }),
        dataIndex: 'managerName',
        key: 'manager'
    };

    const managerPhone = {
        title: formatMessage({
            id: 'add_client_form.phone_number_responsible'
        }),
        dataIndex: 'managerPhone',
        key: 'phone'
    };

    const comment = {
        title: formatMessage({
            id: 'add_client_form.comment'
        }),
        dataIndex: 'comment',
        key: 'comment'
    };

    const editCol = {
        title: formatMessage({ id: 'edit' }),
        key: 'edit',
        render: (text, record) => (
            <Button onClick={() => openEditModal({ vehicle: record })} type='primary'>
                <FormattedMessage id='edit' />
            </Button>
        )
    };

    const deleteCol = {
        title: formatMessage({
            id: 'add_client_form.delete_vehicle'
        }),
        key: 'delete',
        render: (text, record) => (
            <Button onClick={() => removeClientVehicle(record.index)} type='primary'>
                <FormattedMessage id='add_client_form.delete_vehicle' />
            </Button>
        )
    };

    return [
        makeNameCol,
        modelNameCol,
        yearCol,
        modificationNameCol,
        vinCol,
        numberCol,
        managerName,
        managerPhone,
        comment,
        editCol,
        deleteCol
    ];
}
