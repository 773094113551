import { Button, Checkbox, Form, Input, Select } from 'antd';
import { onChangeRoleForm } from 'core/forms/editRoleForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    getGroupsLabels,
    getPermissionsLabels,
    groupedPermissions,
    permissions
} from 'utils';

const { OptGroup } = Select;
const { Option } = Select;

const mapDispatchToProps = {
    onChangeRoleForm
};

@injectIntl
@connect(null, mapDispatchToProps)
export class RoleForm extends Component {

    _submit = values => {
        const { updateRole, role } = this.props;
        if (!role) {
            return null;  
        }
        updateRole(role.roleId, values);
    };

    render() {
        const { role } = this.props;
        const roleGrants = _.intersection(role.grants, _.values(permissions));
        const groupsLabels = getGroupsLabels(this.props.intl);
        const permissionsLabels = getPermissionsLabels(this.props.intl);

        return (
            <Form
                name='roleForm'
                initialValues={{
                    name: role.name,
                    grants: roleGrants,
                    grantOther: !!role.grantOther
                }}
                onFinish={this._submit}
            >
                <Form.Item
                    name='name'
                    label={<FormattedMessage id='edit-role-form.name_field' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'edit-role-form.name_field_required'
                            })
                        }
                    ]}
                >
                    <Input
                        data-qa='input_name_edit_role'
                    />
                </Form.Item>
                <Form.Item
                    name='grants'
                    label={<FormattedMessage id='edit-role-form.grants_field' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'edit-role-form.grants_field_required'
                            })
                        }
                    ]}
                >
                    <Select
                        data-qa='select_grants_edit_role'
                        filterOption={(input, option) => {
                            return (
                                option.props.children &&
                                String(option.props.children)
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        mode='multiple'
                    >
                        {_.toPairs(groupedPermissions).map(([name, value]) => (
                            <OptGroup label={groupsLabels[name]}>
                                {value.map(value => (
                                    <Option key={value} value={value}>
                                        {permissionsLabels[value]}
                                    </Option>
                                ))}
                            </OptGroup>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name='grantOther'
                    labelCol={ {span: 14} }
                    wrapperCol={ {span: 6} }
                    label={<FormattedMessage id='edit-role-form.grant_other_field' />}
                    valuePropName="checked"
                >
                    <Checkbox
                        colon={false}
                        data-qa='checkbox_edit_role'
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ width: '100%' }}
                        type='primary'
                        htmlType='submit'
                        data-qa='btn_edit_role'
                    >
                        <FormattedMessage id='edit-role-form.edit' />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
