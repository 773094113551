import { IssuesCloseOutlined, PlusSquareOutlined, UndoOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Menu, Modal, Switch, Table, Tooltip } from 'antd';
import { Numeral } from 'commons';
import { HamburgerMenu } from 'components';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { AddLaborOrDetailToOrderModal } from 'modals';
import numeral from 'numeral';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PencilIcon } from 'theme';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

/**
 * Table of inspection intervals
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleRegulationsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            selectedRows: []
        };

        const {disabled} = this.props;

        this.columns = (withTitle = true, titleForEachTable) => [
            {
                title: () => {
                    const {
                        intl: { formatMessage },
                        allVehicleRegulations
                    } = this.props;

                    const { selectedRows } = this.state;

                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item
                                key='copy'
                                disabled={!selectedRows.length || selectedRows.find(({ visibility }) => !visibility)}
                            >
                                <div
                                    onClick={() => {
                                        const { labors, vehicleId, setModal } = this.props;
                                        const defaultUse = _.get(
                                            this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                            '[0].id'
                                        );
                                        setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                            labors: selectedRows.map(({ regulationName, laborId }) => {
                                                const lbr = labors.find(({ id }) => id == laborId);

                                                return {
                                                    ...lbr,
                                                    laborId,
                                                    serviceName: regulationName || lbr.name,
                                                    count: Number(lbr.laborPrice.normHours) || 0,
                                                    servicePrice: Number(lbr.laborPrice.price),
                                                    laborUnitId: Number(lbr.laborUnitId) || defaultUse
                                                };
                                            }),
                                            mode: 'ADD_LABOR',
                                            vehicleId
                                        });
                                    }}
                                >
                                    <div>
                                        <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                        <FormattedMessage id='order_form_table.add_to_order_tooltip' />
                                    </div>
                                </div>
                            </Menu.Item>

                            <Menu.Item key='yellow_red'>
                                <div
                                    onClick={() => {
                                        const data = allVehicleRegulations
                                            .map(({ regulations }) => regulations)
                                            .flat()
                                            .filter(
                                                ({ color, visibility }) =>
                                                    (color === 'RED' && visibility) ||
                                                    (color === 'YELLOW' && visibility)
                                            );
                                        this.setState({
                                            selectedRows: data,
                                            selectedRowKeys: data.map(({ id }) => id)
                                        });
                                    }}
                                >
                                    <div>
                                        <WarningOutlined className={Styles.actionMenuIcon} />
                                        <FormattedMessage id='regulations.choose_yellow_n_red' />
                                    </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key='red'>
                                <div
                                    onClick={() => {
                                        const data = allVehicleRegulations
                                            .map(({ regulations }) => regulations)
                                            .flat()
                                            .filter(({ color, visibility }) => color === 'RED' && visibility);
                                        this.setState({
                                            selectedRows: data,
                                            selectedRowKeys: data.map(({ id }) => id)
                                        });
                                    }}
                                >
                                    <div>
                                        <IssuesCloseOutlined className={Styles.actionMenuIcon} />
                                        <FormattedMessage id='regulations.choose_red' />
                                    </div>
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        withTitle && (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}
                                >
                                    <HamburgerMenu disabled={disabled} actionsMenu={actionsMenu} />
                                </div>
                            </div>
                        )
                    );
                },
                key: 'edit',
                width: '5%',
                align: 'center',
                render: (data, row) => {
                    const actionsMenu = () => (
                        <Menu>
                            <Menu.Item key='copy_order' disabled={!row.visibility || disabled}>
                                <div
                                    onClick={() => {
                                        const { labors, setModal, vehicleId, orderId } = this.props;
                                        const defaultUse = _.get(
                                            this.props.unitDefault.filter(({ defaultUse }) => defaultUse),
                                            '[0].id'
                                        );
                                        const lbr = labors.find(({ id }) => id == row.laborId);
                                        setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                            labors: [
                                                {
                                                    ...lbr,
                                                    laborId: lbr.id,
                                                    serviceName: row.regulationName || lbr.name,
                                                    count: Number(lbr.laborPrice.normHours) || 0,
                                                    servicePrice: Number(lbr.laborPrice.price),
                                                    laborUnitId: Number(lbr.laborUnitId) || defaultUse
                                                }
                                            ],
                                            mode: 'ADD_LABOR',
                                            vehicleId,
                                            onConfirm: () => setTimeout(this.fetchVehicleRegulations(), 200)
                                        });
                                    }}
                                >
                                    <PlusSquareOutlined className={Styles.actionMenuIcon} />
                                    <FormattedMessage id='order_form_table.add_to_order_tooltip' />
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly'
                                }}
                            >
                                <HamburgerMenu actionsMenu={actionsMenu} />
                                <Button
                                    disabled={!row.visibility || disabled}
                                    icon={<PencilIcon />}
                                    onClick={() => {
                                        this.props.editRegulations(row);
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
            },
            {
                title: titleForEachTable,
                key: 'name',
                dataIndex: 'regulationName',
                width: '25%',
                render: (data, row) => {
                    return (
                        <div
                            className={
                                row.color == 'GREEN' && row.nextChangeDate
                                    ? Styles.underlineStatusColorGreen
                                    : row.color == 'YELLOW'
                                    ? Styles.underlineStatusColorYellow
                                    : row.color == 'RED'
                                    ? Styles.underlineStatusColorRed
                                    : undefined
                            }
                        >
                            {data}
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.regulation' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.months' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'typeRegulationMonth',
                dataIndex: 'months',
                align: 'center',
                width: '10%'
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.regulation' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'typeRegulationKm',
                dataIndex: 'km',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.last_change' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'lastChangeKm',
                dataIndex: 'lastKm',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.last_change' />
                            </div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'lastChangeDate',
                dataIndex: 'lastDate',
                align: 'center',
                width: '10%',
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY') : undefined;
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.next_change' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'nextChangeKm',
                dataIndex: 'nextChangeKm',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.next_change' />
                            </div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'nextChangeDate',
                dataIndex: 'nextChangeDate',
                align: 'center',
                width: '10%',
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY') : undefined;
                }
            },
            {
                title: withTitle ? <FormattedMessage id='universal_chart.show' /> : undefined,
                key: 'display',
                width: '7%',
                render: (data, row) => {
                    return (
                        <Switch
                            checked={row.visibility}
                            disabled={disabled}
                            onChange={async () => {
                                await fetchAPI('PUT', 'vehicle/regulations', undefined, {
                                    id: row.id,
                                    visibility: !row.visibility
                                });
                                await setTimeout(() => this.props.fetchVehicleRegulations(), 100);
                            }}
                        />
                    );
                }
            }
        ];
    }

    updateAllRows = async () => {
        await fetchAPI('POST', 'businesses/regulations/copy', undefined, {
            vehicleId: this.props.vehicleId,
            copyOption: 'vehicle'
        });
        setTimeout(() => this.props.fetchVehicleRegulations(), 100);
    };

    updateAllVehicleInfo = async () => {
        await fetchAPI('PUT', `clients/vehicles/${this.props.vehicleId}`, null, undefined);
        setTimeout(() => this.props.fetchVehicleRegulations(), 100);
    };

    render() {
        const { data, vehicleId, user, allVehicleRegulations, disabled } = this.props;

        const { selectedRowKeys, selectedRows } = this.state;

        const pagination = {
            pageSize: 25,
            size: 'large',
            hideOnSinglePage: true
        };

        const rowSelection = (hideSelectAll = true) => ({
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            hideSelectAll,
            onSelectAll: (selected, selectedRows, changeRows) => {
                if (selected) {
                    const allData = allVehicleRegulations.map(({ regulations }) => regulations).flat();

                    const selectedRowKeys = allData.map(({ id }) => id);

                    this.setState({
                        selectedRowKeys,
                        selectedRows: allData
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: [],
                    selectedRows: []
                });
            },
            onSelect: (record, selected) => {
                if (selected) {
                    this.setState({
                        selectedRowKeys: [...selectedRows, record].map(({ id }) => id),
                        selectedRows: [...selectedRows, record]
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: selectedRowKeys.filter(id => id !== record.id),
                    selectedRows: selectedRows.filter(({ id }) => id !== record.id)
                });
            }
        });

        return (
            <div className={Styles.mainCont}>
                <div className={Styles.btnStyle}>
                    <div>
                        <Tooltip title={<FormattedMessage id='regulations.update_tooltip' />}>
                            <Button
                                icon={<UndoOutlined />}
                                disabled={disabled}
                                onClick={async () => {
                                    await fetchAPI(
                                        'POST',
                                        '/mileage/history',
                                        null,
                                        {
                                            vehicleId,
                                            mileage: data.currentVehicleInfo.maxMileage || 1,
                                            date:
                                                dayjs(data.currentVehicleInfo.maxDate).format('YYYY-MM-DD') ||
                                                undefined,
                                            custom: true
                                        },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await this.updateAllVehicleInfo();
                                }}
                            />
                        </Tooltip>

                        <span
                            style={{
                                marginLeft: 8
                            }}
                        >
                            <FormattedMessage id='regulations.current_odometr_and_date' />
                            {': '}

                            <span
                                style={{
                                    fontWeight: 700
                                }}
                            >
                                {data.currentVehicleInfo ? (
                                    <Numeral mask='0,0'>{Number(data.currentVehicleInfo.maxMileage)}</Numeral>
                                ) : (
                                    0
                                )}{' '}
                                <FormattedMessage id='online_service_book_page.km' />
                                {', '}
                                {data.currentVehicleInfo
                                    ? dayjs(data.currentVehicleInfo.maxDate).format('YYYY.MM.DD')
                                    : '-'}
                            </span>
                        </span>
                    </div>
                    <Button
                        onClick={() => {
                            if (data.currentVehicleInfo.orderId) {
                                Modal.confirm({
                                    title: this.props.intl.formatMessage({
                                        id: 'regulations.odometr_found_message'
                                    }),
                                    content: (
                                        <div>
                                            <div>
                                                {this.props.intl.formatMessage({
                                                    id: 'directories.orders'
                                                })}
                                                {': '}
                                                <span
                                                    style={{
                                                        fontWeight: 700
                                                    }}
                                                >
                                                    {`MRD-${user.businessId}-${data.currentVehicleInfo.orderId}`}
                                                </span>
                                            </div>
                                            <div>
                                                {this.props.intl.formatMessage({
                                                    id: 'orders.odometr'
                                                })}
                                                {': '}
                                                <span
                                                    style={{
                                                        fontWeight: 700
                                                    }}
                                                >
                                                    {data.currentVehicleInfo
                                                        ? numeral(data.currentVehicleInfo.maxMileage).format('0,0')
                                                        : undefined}{' '}
                                                    {this.props.intl.formatMessage({
                                                        id: 'online_service_book_page.km'
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    ),
                                    onOk: () => {
                                        this.updateAllVehicleInfo();
                                    },
                                    okType: 'primary',
                                    onCancel() {}
                                });
                            } else {
                                setTimeout(() => this.fetchVehicleRegulations(), 100);
                            }
                        }}
                    >
                        <FormattedMessage id='regulations.update_regulations' />
                    </Button>
                    <Button
                        disabled={disabled}
                        onClick={() => {
                            if (_.get(allVehicleRegulations, 'length')) {
                                Modal.confirm({
                                    title: this.props.intl.formatMessage({
                                        id: 'regulations.confirm_modal'
                                    }),
                                    onOk: () => {
                                        this.updateAllRows();
                                    },
                                    okType: 'primary',
                                    onCancel() {}
                                });
                            } else {
                                this.updateAllRows();
                            }
                        }}
                    >
                        <FormattedMessage id='regulations.add' />
                    </Button>
                </div>

                {allVehicleRegulations.map(({ groupId, groupName, regulations }, index) => (
                    <Table
                        key={groupId}
                        bordered
                        columns={this.columns(!index, groupName)}
                        dataSource={regulations}
                        pagination={false}
                        rowKey='id'
                        rowSelection={rowSelection(!!index)}
                        size='small'
                    />
                ))}
                <AddLaborOrDetailToOrderModal />
            </div>
        );
    }
}
