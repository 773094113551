import { CheckOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Popover } from 'antd';
import { Numeral } from 'commons';
import { FormattedDatetime, HamburgerMenu } from 'components';
import _ from 'lodash';
import { appurtenancesSortFields } from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { foundStringParser } from 'utils';
import { v4 } from 'uuid';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    order: 'auto',
    datetime: '10%',
    odometer: '7%',
    name: '10%',
    brandName: '10%',
    code: '10%',
    supplierName: '7%',
    purchasePrice: '8%',
    price: '8%',
    count: '5%',
    sum: '10%',
    actions: '5%'
};

export function columnsConfig(props) {
    const { onAddDetailToOrder, onCodeSearch, onBrandSearch, onNameSearch, onSupplierSearch, selectedRows } = props;

    const groupActionsCol = {
        title: () => {
            const actionsMenu = (
                <Menu>
                    <Menu.Item disabled={!selectedRows.length}>
                        <div
                            onClick={() => onAddDetailToOrder(selectedRows)}
                            style={{
                                display: 'flex',
                                justifyContent: ' space-between'
                            }}
                        >
                            <CheckOutlined />
                            <FormattedMessage id='vehicle_page.hint_add_labor_to_order' />
                        </div>
                    </Menu.Item>
                </Menu>
            );

            return <HamburgerMenu actionsMenu={actionsMenu} />;
        },
        key: 'actions',
        align: 'center',
        render: detail => {
            const actionsMenu = () => (
                <Menu>
                    <Menu.Item>
                        <div
                            onClick={() => onAddDetailToOrder({ detail })}
                            style={{
                                display: 'flex',
                                justifyContent: ' space-between'
                            }}
                        >
                            <CheckOutlined />
                            <FormattedMessage id='vehicle_page.hint_add_labor_to_order' />
                        </div>
                    </Menu.Item>
                </Menu>
            );

            return (
                <HamburgerMenu actionsMenu={actionsMenu}>
                    <Button data-qa='btn_show_hamburger_menu_modal_services_table_order_page' icon={<MenuOutlined />} />
                </HamburgerMenu>
            );
        }
    };

    const actionsCol = {
        width: defWidth.actions,
        key: v4(),
        render: detail => (
            <Popover content={<FormattedMessage id='vehicle_page.hint_add_detail_to_order' />}>
                <Button
                    disabled={props.disabled}
                    icon={<CheckOutlined />}
                    onClick={() => onAddDetailToOrder({ detail })}
                />
            </Popover>
        )
    };

    const orderCol = {
        title: <FormattedMessage id='orders.order' />,
        width: defWidth.order,
        dataIndex: 'orderNum',
        key: appurtenancesSortFields.orderId,
        sorter: true,
        ender: orderNum => <div>{orderNum}</div>
    };

    const datetimeCol = {
        title: <FormattedMessage id='orders.record_date' />,
        width: defWidth.datetime,
        dataIndex: 'orderDatetime',
        key: appurtenancesSortFields.datetime,
        sorter: true,
        render: orderDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={orderDatetime} format={DEFAULT_DATETIME} />
            </React.Fragment>
        )
    };

    const odometerCol = {
        title: <FormattedMessage id='orders.odometer' />,
        width: defWidth.odometer,
        dataIndex: 'odometer',
        key: 'odometer',
        render: odometer => <div>{odometer || <FormattedMessage id='long_dash' />}</div>
    };

    const codeCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.product_code' />
                    <Input
                        onChange={e => onCodeSearch(foundStringParser(_.get(e, 'target.value')))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.code,
        dataIndex: 'code',
        key: appurtenancesSortFields.code,
        sorter: true
    };

    const brandNameCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.brand' />
                    <Input
                        onChange={e => onBrandSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.brandName,
        dataIndex: 'supplierBrandName',
        key: appurtenancesSortFields.supplierBrandName,
        sorter: true
    };

    const nameCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='storage.product_name' />
                    <Input
                        onChange={e => onNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.name,
        dataIndex: 'name',
        key: appurtenancesSortFields.name,
        sorter: true
    };

    const supplierNameCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='storage.business_supplier' />
                    <Input
                        onChange={e => onSupplierSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.supplierName,
        dataIndex: 'supplierName',
        key: appurtenancesSortFields.supplierName,
        sorter: true
    };

    const purchasePriceCol = {
        title: <FormattedMessage id='storage.purchase_price' />,
        width: defWidth.purchasePrice,
        align: 'right',
        dataIndex: 'purchasePrice',
        key: appurtenancesSortFields.purchasePrice,
        sorter: true,
        render: purchasePrice => {
            return <Numeral mask='0,00.00'>{purchasePrice}</Numeral>;
        }
    };

    const priceCol = {
        title: <FormattedMessage id='storage.selling_price' />,
        width: defWidth.price,
        align: 'right',
        dataIndex: 'price',
        key: appurtenancesSortFields.price,
        sorter: true,
        render: price => {
            return <Numeral mask='0,00.00'>{price}</Numeral>;
        }
    };

    const countCol = {
        title: <FormattedMessage id='order_form_table.count' />,
        width: defWidth.count,
        align: 'right',
        dataIndex: 'count',
        key: appurtenancesSortFields.count,
        sorter: true
    };

    const sumCol = {
        title: <FormattedMessage id='order_form_table.sum' />,
        width: defWidth.sum,
        align: 'right',
        dataIndex: 'sum',
        key: appurtenancesSortFields.sum,
        sorter: true,
        render: sum => {
            return <Numeral mask='0,00.00'>{sum}</Numeral>;
        }
    };

    const units = {
        title: <FormattedMessage id='services_table.units' />,
        key: 'unit',
        dataIndex: 'unit',
        align: 'right',
        render: data => {
            return data ? <span style={{ whiteSpace: 'nowrap' }}>{data}</span> : <FormattedMessage id='long_dash' />;
        }
    };

    return [
        groupActionsCol,
        orderCol,
        datetimeCol,
        odometerCol,
        codeCol,
        brandNameCol,
        nameCol,
        supplierNameCol,
        purchasePriceCol,
        priceCol,
        countCol,
        units,
        sumCol
    ];
}
