exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__filterBlockStyle---2xDra{display:flex;margin-bottom:18px}.styles-m__inputsMargin---2pbLD{margin-right:8px}.styles-m__buttonsMargin---xu8RX{margin-right:8px;margin-top:14px}.styles-m__resetAllBtn---3gHfM{display:flex;justify-content:end;margin-bottom:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,oCACI,YAAa,CACb,kBAEJ,CAEA,gCACI,gBACJ,CAEA,iCACI,gBAAiB,CACjB,eACJ,CAEA,+BACI,YAAa,CACb,mBAAoB,CACpB,iBACJ","file":"styles.m.css","sourcesContent":[".filterBlockStyle {\n    display: flex;\n    margin-bottom: 18px;\n   \n}\n\n.inputsMargin {\n    margin-right: 8px;\n}\n\n.buttonsMargin {\n    margin-right: 8px;\n    margin-top: 14px;\n}\n\n.resetAllBtn {\n    display: flex;\n    justify-content: end;\n    margin-bottom: 8px\n}"]}]);

// Exports
exports.locals = {
	"filterBlockStyle": "styles-m__filterBlockStyle---2xDra",
	"inputsMargin": "styles-m__inputsMargin---2pbLD",
	"buttonsMargin": "styles-m__buttonsMargin---xu8RX",
	"resetAllBtn": "styles-m__resetAllBtn---3gHfM"
};