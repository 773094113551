import { Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import { SubscribeForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { images } from 'utils';
import Styles from './styles.m.css';

export default class SubscribeModal extends Component {
    render() {
        const {
            user,
            visible,
            modalProps,
            resetModal,
            verifyPromoCode,
            promoCode,
            subscribed,
            asyncSubscribe
        } = this.props;

        const footer = this._renderModalFooter();

        return (
            <Modal
                destroyOnClose
                footer={footer}
                // onOk={ () => this._handleToSuccessModalSubmit() }
                maskClosable={false}
                onCancel={() => resetModal()}
                title={modalProps.name}
                visible={visible === MODALS.SUBSCRIBE}
                width={760}
            >
                <SubscribeForm
                    asyncSubscribe={asyncSubscribe}
                    modalProps={modalProps}
                    promoCode={promoCode}
                    resetModal={resetModal}
                    subscribed={subscribed}
                    user={user}
                    verifyPromoCode={verifyPromoCode}
                />
            </Modal>
        );
    }

    _renderModalFooter = () => {
        return (
            <div className={Styles.modalFooter}>
                <div className={Styles.terms}>
                    <img className={Styles.portmoneLogos} src={images.portmoneLogos} />

                    <FormattedMessage id='subscription.terms' />
                </div>
                <div className={Styles.support}>
                    <FormattedMessage id='subscription.support' />:{' '}
                    <FormattedMessage id='subscription.phone' />{' '}
                    <a href='tel:380442000902'>+380(44)200-09-02</a>,{' '}
                    <a href='mailto:support@portmone.com'>support@portmone.com</a>
                </div>
                <div className={Styles.support}>
                    <FormattedMessage id='footer.support' /> Carbook.Pro:&nbsp;
                    <a href='tel:380442994556'>+38(044)299-45-56</a>
                    &nbsp;
                    <FormattedMessage id='or' />
                    &nbsp;
                    <a href='mailto:support@carbook.pro'>support@carbook.pro</a>
                    &nbsp;
                </div>
            </div>
        );
    };
}
