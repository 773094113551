import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import Styles from './styles.m.css';

export default function Result({
    className,
    type,
    title,
    description,
    extra,
    actions,
    ...restProps
}) {
    const iconMap = {
        error: <CloseCircleOutlined className={Styles.error} />,
        success: <CheckCircleOutlined className={Styles.success} />
    };
    const clsString = classNames(Styles.result, className);

    return (
        <div className={clsString} {...restProps}>
            <div className={Styles.icon}>{iconMap[type]}</div>
            <div className={Styles.title}>{title}</div>
            {description && <div className={Styles.description}>{description}</div>}
            {extra && <div className={Styles.extra}>{extra}</div>}
            {actions && <div className={Styles.actions}>{actions}</div>}
        </div>
    );
}
