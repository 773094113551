import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Spin } from 'antd';
import { createClientVehicle, deleteClientVehicle, fetchClient, updateClientVehicle } from 'core/client/duck';
import {
    addClientVehicle,
    createClient,
    handleError,
    onChangeAddClientForm,
    removeClientVehicle
} from 'core/forms/addClientForm/duck';
import { loadModal, MODALS, saveModal, setModal } from 'core/modals/duck';
import { AbstractClientForm, EditClientForm, EditClientVehicleForm } from 'forms';
import _ from 'lodash';
import { AddAggregateModal, VehicleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isForbidden, permissions, withReduxForm2 } from 'utils';
import { ClientsAggregatesTable, ClientsVehiclesTable } from './components';
import Styles from './styles.m.css';

const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

/**
 * This modal is used to add a new client with or without vehicles.
 * @property {*} [props.searchQuery] - Initial client phone number, will be used if provided proper value
 * @property {*} [props.modalProps.initialPhoneNumber] - Initial client phone number, will be used if provided proper value
 * @property {Function()} [onSubmit] - Callbeck when submit button cliekced
 */
@injectIntl
@withReduxForm2({
    name: 'addClientForm',
    actions: {
        change: onChangeAddClientForm,
        addClientVehicle,
        removeClientVehicle,
        createClient,
        handleError,
        setModal,
        saveModal,
        loadModal,

        fetchClient,
        createClientVehicle,
        updateClientVehicle,
        deleteClientVehicle
    },
    mapStateToProps: state => ({
        user: state.auth,
        isMobile: state.ui.views.isMobile,
        modalProps: state.modals.modalProps,
        clientEntity: state.client.clientEntity,
        clientFetching: state.client.clientFetching
    })
})
export default class AddClientModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleAddAggregateModal: false,
            aggregates: [],
            source: [],
            markups: []
        };
    }

    /**
     * Open vehicle modal to crate a new vehicle
     */
    onOpenAddVehicleModal = () => {
        const { setModal, saveModal, addClientVehicle } = this.props;

        saveModal();
        setModal(MODALS.VEHICLE, {
            mode: 'ADD',
            autoSubmit: false,
            onClose: () => this.onCloseVehicleModal(),
            onSubmit: ({ vehicle }) => {
                addClientVehicle(vehicle);
            }
        });
    };

    /**
     * Open vehicle modal to crate a new vehicle (while editing)
     */
    onOpenAddVehicleModalWhileEditing = () => {
        const { setModal, saveModal, modalProps, fetchClient, createClientVehicle } = this.props;

        saveModal();
        setModal(MODALS.VEHICLE, {
            mode: 'ADD',
            autoSubmit: false,
            onClose: () => this.onCloseVehicleModal(),
            onSubmit: ({ vehicle }) => {
                const clientId = _.get(modalProps, 'clientId');
                if (clientId) {
                    createClientVehicle(clientId, vehicle);
                }
            }
        });
    };

    /**
     * Open vehicle modal to edit a vehicle
     */
    onOpenEditVehicleModal = ({ vehicle }) => {
        const { setModal, saveModal, addClientVehicle, removeClientVehicle } = this.props;

        saveModal();
        setModal(MODALS.VEHICLE, {
            mode: 'ADD',
            initValues: vehicle,
            autoSubmit: false,
            onClose: () => this.onCloseVehicleModal(),
            onSubmit: ({ vehicle }) => {
                removeClientVehicle(vehicle.index), addClientVehicle(vehicle);
            }
        });
    };

    /**
     * Reopen client modal after vehicle modal was closed
     */
    onCloseVehicleModal = () => {
        const { loadModal } = this.props;
        loadModal(MODALS.ADD_CLIENT);
    };

    componentDidUpdate(prevProps) {
        const { visible, modalProps, fetchClient } = this.props;
        if (visible === MODALS.ADD_CLIENT && visible != prevProps.visible) {
            const clientId = _.get(modalProps, 'clientId');
            if (clientId) {
                fetchClient(clientId);
            }
        }
    }

    onSubmit = () => {
        const {
            resetModal,
            onSubmit,
            form: { getFieldsValue, validateFields, resetFields },
            getCalls
        } = this.props;

        const { aggregates, source } = this.state;

        validateFields(['name', 'phones'], err => {
            if (!err) {
                const clientFormData = getFieldsValue();
                const vehicles = this.props.vehicles.map(
                    ({
                        modelId,
                        modificationId,
                        vin,
                        number,
                        year,
                        vehicleTypeId,
                        wheelRadius,
                        colorId,
                        managerName,
                        managerPhone,
                        comment,
                        type,
                        bodyId
                    }) => ({
                        vehicleModelId: modelId,
                        vehicleModificationId: modificationId,
                        vehicleVin: vin,
                        vehicleNumber: number,
                        vehicleYear: year,
                        vehicleTypeId,
                        wheelRadius,
                        colorId,
                        managerName,
                        managerPhone,
                        comment,
                        type,
                        bodyId
                    })
                );

                const clientEntity = {
                    birthday: clientFormData.birthday,
                    emails: clientFormData.emails ? clientFormData.emails.filter(Boolean) : clientFormData.emails,
                    middlename: clientFormData.patronymic,
                    name: clientFormData.name,
                    surname: clientFormData.surname,
                    sex: clientFormData.sex,
                    status: clientFormData.status,
                    source: clientFormData.source,
                    sourceId: clientFormData.sourceId,
                    markupGroupId: clientFormData.markupGroupId,
                    comment: clientFormData.comment,
                    type: clientFormData.type,
                    accountId: clientFormData.accountId,
                    paymentRespite: clientFormData.paymentRespite,
                    aggregates: aggregates.map(({ aggregateId, brandId, aggregateNumber, comment, count, vin }) => ({
                        aggregateId,
                        brandId,
                        aggregateNumber,
                        comment,
                        count,
                        vin
                    })),
                    vehicles,
                    phones: clientFormData.phones
                        .filter(phone => phone && phone.number)
                        .map(({ number }) => String(number))
                };

                resetModal();

                _.get(this.props, 'func')
                    ? this.props.createClient(clientEntity, this.props.func)
                    : this.props.createClient(clientEntity);

                if (onSubmit) onSubmit(); // Callback
                if (!clientFormData.phones) {
                    notification.error({
                        message: this.props.intl.formatMessage({
                            id: 'vehicle_page.add_owner_vehicle'
                        })
                    });
                }

                resetFields();
            } else if (err) {
                notification.error({
                    message: this.props.intl.formatMessage({
                        id: 'add-client-form.required_fields'
                    }),
                    duration: 4
                });
            }
        });
    };

    editArr = row => {
        this.setState({
            visibleAddAggregateModal: true,
            addAggregateModalRow: row
        });
    };

    deleteArr = i => {
        const { aggregates } = this.state;
        this.setState({
            aggregates: aggregates.filter((elem, index) => i !== index)
        });
    };

    // fetchSource = async () => {
    //     const source = await fetchAPI('GET', 'business/acquiring/sources', undefined, undefined);
    //     this.setState({
    //         source: source.query || []
    //     });
    // };

    // fetchMarkups = async () => {
    //     const response = await fetchAPI('GET', 'markups', undefined, undefined);
    //     this.setState({
    //         markups: response || []
    //     });
    // };

    render() {
        const {
            visible,
            resetModal,
            addClientFormData,
            searchQuery,
            vehicles,
            isMobile,
            modalProps,
            user,
            form,
            callback,

            clientFetching,
            clientEntity,
            fetchClient,
            updateClientVehicle,
            deleteClientVehicle,
            removeClientVehicle,
            addClientAggregate,
            validateFields,
            getCalls,
            startDate,
            endDate,
            intl: { formatMessage }
        } = this.props;

        const { visibleAddAggregateModal, aggregates, addAggregateModalRow, source, markups } = this.state;

        // Get initial phone from props or modalProps
        const clientSearchQuery = searchQuery || _.get(modalProps, 'initialPhoneNumber');
        const clientId = _.get(modalProps, 'clientId');

        return (
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                className={Styles.addClientModal}
                footer={
                    <div>
                        <div>
                            <Button
                                key='cancel'
                                onClick={() => {
                                    form.resetFields();
                                    vehicles.map(({ index }) => {
                                        removeClientVehicle(index);
                                    });
                                    resetModal();
                                }}
                            >
                                <FormattedMessage id='cancel' />
                            </Button>
                            <Button
                                key='ok'
                                onClick={async () => {
                                    await this.onSubmit();
                                    if (getCalls) {
                                        getCalls(startDate, endDate);
                                    }
                                }}
                                type='primary'
                            >
                                <FormattedMessage id='add' />
                            </Button>
                        </div>
                        <div>{modalProps.mode == 'EDIT' ? null : void 0}</div>
                    </div>
                }
                height='80%'
                maskClosable={false}
                okText={<FormattedMessage id='add' />}
                onCancel={() => {
                    form.resetFields();
                    vehicles.map(({ index }) => {
                        removeClientVehicle(index);
                    });
                    resetModal();
                }}
                onOk={() => this.onSubmit()}
                open={visible === MODALS.ADD_CLIENT}
                style={{ top: 20 }}
                title={
                    modalProps.mode == 'EDIT' ? (
                        formatMessage({ id: 'client_page.title' })
                    ) : (
                        <React.Fragment>
                            {formatMessage({ id: 'add-client-form.add_client' }) +
                                (clientSearchQuery ? ` (${clientSearchQuery})` : '')}
                        </React.Fragment>
                    )
                }
                width={isMobile ? '95%' : '80%'}
                wrapClassName={Styles.addClientModal}
            >
                {modalProps.mode == 'EDIT' ? (
                    <React.Fragment>
                        {clientFetching ? (
                            <Spin indicator={spinIcon} />
                        ) : (
                            <React.Fragment>
                                <EditClientForm
                                    callback={callback}
                                    client={clientEntity}
                                    clientId={clientId}
                                    markups={markups}
                                    source={source}
                                />
                                <EditClientVehicleForm
                                    clientEntity={clientEntity}
                                    clientId={clientId}
                                    deleteClientVehicle={deleteClientVehicle}
                                    fetchClient={fetchClient}
                                    updateClientVehicle={updateClientVehicle}
                                />
                                {!isForbidden(user, permissions.CREATE_EDIT_DELETE_CLIENTS) ? (
                                    // <AddClientVehicleForm
                                    //     vehicleTypes={vehicleTypes}
                                    //     addClientVehicle={this.props.createClientVehicle.bind(
                                    //         null,
                                    //         clientId,
                                    //     )}
                                    // />
                                    <React.Fragment>
                                        <Button
                                            disabled={isForbidden(user, permissions.GET_CLIENTS)}
                                            onClick={this.onOpenAddVehicleModalWhileEditing}
                                            style={{
                                                width: '100%'
                                            }}
                                            type='primary'
                                        >
                                            <FormattedMessage id='add-client-form.add_vehicle' />
                                        </Button>
                                    </React.Fragment>
                                ) : null}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <AbstractClientForm
                            markups={markups}
                            {...this.props}
                            addClientFormData={addClientFormData}
                            searchQuery={clientSearchQuery}
                            source={source}
                            wrappedComponentRef={this.props.wrappedComponentRef}
                        />

                        <div>
                            <ClientsVehiclesTable
                                openEditModal={this.onOpenEditVehicleModal}
                                removeClientVehicle={this.props.removeClientVehicle}
                                vehicles={vehicles}
                            />
                            <div className={Styles.addVehicleButtonCont}>
                                <Button
                                    disabled={isForbidden(user, permissions.GET_CLIENTS)}
                                    onClick={this.onOpenAddVehicleModal}
                                    type='primary'
                                >
                                    <FormattedMessage id='add-client-form.add_vehicle' />
                                </Button>
                            </div>
                        </div>

                        <div
                            style={{
                                marginBottom: 16
                            }}
                        >
                            <ClientsAggregatesTable
                                aggregates={aggregates}
                                deleteArr={this.deleteArr}
                                editArr={this.editArr}
                            />
                            <div className={Styles.addVehicleButtonCont}>
                                <Button
                                    disabled={isForbidden(user, permissions.GET_CLIENTS)}
                                    onClick={() => {
                                        this.setState({
                                            visibleAddAggregateModal: true
                                        });
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='clients-page.add_aggregate' />
                                </Button>
                            </div>{' '}
                        </div>

                        <VehicleModal isMobile={isMobile} />
                        <AddAggregateModal
                            addAggregate={aggregate => {
                                aggregates.push(aggregate);
                                this.setState({
                                    aggregates
                                });
                                this.forceUpdate();
                            }}
                            addAggregateModalRow={addAggregateModalRow}
                            clientId={clientId}
                            editAggregate={aggregate => {
                                aggregates[aggregate.index] = aggregate;
                                this.setState({
                                    aggregates
                                });
                            }}
                            hideModal={() => {
                                this.setState({
                                    visibleAddAggregateModal: false,
                                    addAggregateModalRow: undefined
                                });
                            }}
                            visible={visibleAddAggregateModal}
                        />
                    </React.Fragment>
                )}
            </Modal>
        );
    }
}
