import { Select, Spin } from 'antd';
import { setProductSearchQuery } from 'core/search/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    products: state.search.products,
    isFetchingProducts: state.search.isFetchingProducts
});

const mapDispatchToProps = {
    setProductSearchQuery
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ProductSearchField extends Component {
    render() {
        const { onSelect, setProductSearchQuery } = this.props;
        const { products, isFetchingProducts, productId } = this.props;

        return (
            <Select
                allowClear
                filterOption={false}
                notFoundContent={
                    isFetchingProducts ? <Spin size='small' /> : <FormattedMessage id='not_found' />
                }
                onChange={productId => onSelect(productId)}
                onSearch={item => setProductSearchQuery(item)}
                placeholder={this.props.intl.formatMessage({
                    id: 'product_placeholder'
                })}
                showSearch
                style={this.props.selectStyles}
                value={productId}
            >
                {isFetchingProducts
                    ? []
                    : products.map(({ productId, name }) => (
                          <Option key={productId} value={productId}>
                              {name}
                          </Option>
                      ))}
            </Select>
        );
    }
}
