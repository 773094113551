import { Table } from 'antd';
import { recommendationsColumns } from 'forms/OrderForm/OrderFormTables/RecommendationsTable/columns';
import { pick } from 'lodash';
import {
    fetchVehicleRecommendations,
    selectVehicle,
    selectVehicleRecommendations,
    selectVehicleRecommendationsFetching,
    selectVehicleRecommendationsQuery,
    selectVehicleRecommendationsStats,
    setPageRecommendations
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const mapStateToProps = state => ({
    user: state.auth,
    recomendations: selectVehicleRecommendations(state),
    stats: selectVehicleRecommendationsStats(state),
    query: selectVehicleRecommendationsQuery(state),
    fetching: selectVehicleRecommendationsFetching(state),
    vehicle: selectVehicle(state)
});

const mapDispatchToProps = {
    setPageRecommendations,
    fetchVehicleRecommendations
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleRecommendationsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editableRcmId: undefined
        };
    }

    updateRecommendation = async recommendation => {
        const { vehicle } = this.props;
        await fetchAPI(
            'PUT',
            'recommendations/labors',
            null,
            [
                {
                    vehicleId: vehicle.id,
                    ...pick(recommendation, [
                        'recommendationId',
                        'recommendationName',
                        'recommendationStatus',
                        'recommendationComment',
                        'recommendationLaborId',
                        'recommendationToDate',
                        'recommendationToOdometerValue',
                        'orderId'
                    ]),
                    recommendationComment: recommendation.recommendationComment || undefined,
                    recommendationName: recommendation.laborName,
                    recommendationLaborId: recommendation.laborId
                }
            ],
            { handleErrorInternally: true }
        );
        this.props.fetchVehicleRecommendations();
    };

    render() {
        const { recomendations, fetching, disabled, user } = this.props;
        const { editableRcmId } = this.state;

        return (
            <React.Fragment>
                <Table
                    bordered
                    columns={recommendationsColumns({
                        user,
                        updateRecommendation: (rcm, orderId) => this.updateRecommendation(rcm, orderId),
                        onEdit: ({ recommendationId }) => this.setState({ editableRcmId: recommendationId }),
                        onEndEdit: () => {
                            this.setState({ editableRcmId: undefined });
                        },
                        editableRcmId,
                        disabled
                    })}
                    dataSource={recomendations.list}
                    loading={fetching}
                    pagination={false}
                    rowKey='recommendationId'
                    size='small'
                />
                {/* <AddRecommendationsModal
                    detailsTreeData={[]}
                    getRecommendation={fetchVehicleRecommendations}
                    hideModal={() => {
                        this.setState({
                            editableRcmId: undefined
                        });
                    }}
                    labors={[]}
                    laborsTreeData={this.laborsTreeData}
                    recommendation={editableRcmId}
                    updateRecommendation={this.updateRecommendation}
                    vehicleId={vehicle.id}
                    visible={editableRcmId}
                /> */}
            </React.Fragment>
        );
    }
}
