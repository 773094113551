exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__callsTable---3swzR td{vertical-align:middle}.styles-m__ogTitle---3tuzD{font-size:16px;font-weight:700}.styles-m__ogDesc---1_B_J{margin-bottom:8px;margin-top:8px;opacity:.7}.styles-m__ogImg---3ToX0{margin-bottom:4px;margin-top:4px}.styles-m__linkBlock---2dHbm{margin-bottom:12px;background-color:#f5f5f5;border:1px solid #8a8d8a;padding:4px;border-radius:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,iCACI,qBACJ,CAEA,2BACI,cAAe,CACf,eACJ,CAEA,0BACI,iBAAkB,CAClB,cAAe,CACf,UACJ,CAEA,yBACI,iBAAkB,CAClB,cACJ,CAEA,6BACI,kBAAmB,CACnB,wBAA4B,CAC5B,wBAAyB,CACzB,WAAY,CACZ,iBACJ","file":"styles.m.css","sourcesContent":[".callsTable td {\n    vertical-align: middle;\n}\n\n.ogTitle {\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.ogDesc {\n    margin-bottom: 8px;\n    margin-top: 8px;\n    opacity: 0.7;\n}\n\n.ogImg {\n    margin-bottom: 4px;\n    margin-top: 4px;\n}\n\n.linkBlock {\n    margin-bottom: 12px;\n    background-color: whitesmoke;\n    border: 1px solid #8a8d8a;\n    padding: 4px;\n    border-radius: 8px; \n}\n"]}]);

// Exports
exports.locals = {
	"callsTable": "styles-m__callsTable---3swzR",
	"ogTitle": "styles-m__ogTitle---3tuzD",
	"ogDesc": "styles-m__ogDesc---1_B_J",
	"ogImg": "styles-m__ogImg---3ToX0",
	"linkBlock": "styles-m__linkBlock---2dHbm"
};