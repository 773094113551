import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchSubscriptionPackagesSuccess,
    fetchSubscriptionProductsSuccess,
    fetchSubscriptionSuggestionsSuccess,
    FETCH_SUBSCRIPTION_PACKAGES,
    FETCH_SUBSCRIPTION_PRODUCTS,
    FETCH_SUBSCRIPTION_SUGGESTIONS,
    selectSubscriptionPackages,
    selectSubscriptionSuggestions,
    SUBSCRIPTION_TYPES,
    verifyPromoCodeError,
    verifyPromoCodeSuccess,
    VERIFY_PROMO_CODE
} from './duck';

export function* fetchSubscriptionProductsSaga() {
    while (true) {
        try {
            const { payload: type } = yield take(FETCH_SUBSCRIPTION_PRODUCTS);

            const response = yield call(fetchAPI, 'GET', '/products', { type });

            yield put(fetchSubscriptionProductsSuccess(response));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* fetchSubscriptionPackagesSaga() {
    while (true) {
        try {
            yield take(FETCH_SUBSCRIPTION_PACKAGES);
            const packages = yield select(selectSubscriptionPackages);
            const filters = {
                ...packages.filters
            };

            const response = yield call(fetchAPI, 'GET', '/subscriptions', {
                type: SUBSCRIPTION_TYPES.ROLES_PACKAGE,
                pageSize: 10,
                ...filters
            });

            yield put(fetchSubscriptionPackagesSuccess(response));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* fetchSubscriptionSuggestionsSaga() {
    while (true) {
        try {
            yield take(FETCH_SUBSCRIPTION_SUGGESTIONS);
            const suggestions = yield select(selectSubscriptionSuggestions);
            const filters = {
                ...suggestions.filters
            };
            const response = yield call(fetchAPI, 'GET', '/subscriptions', {
                type: SUBSCRIPTION_TYPES.SUGGESTION_GROUP,
                pageSize: 10,
                ...filters
            });
            yield put(fetchSubscriptionSuggestionsSuccess(response));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* verifyPromoCodeSaga() {
    while (true) {
        try {
            const { payload } = yield take(VERIFY_PROMO_CODE);
            const response = yield call(fetchAPI, 'GET', `/promo_codes/${payload}`, null, null, {
                handleErrorInternally: true
            });
            yield put(verifyPromoCodeSuccess(response.discountPercent));
        } catch (error) {
            yield put(verifyPromoCodeError('error'));
            // yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([
        call(fetchSubscriptionProductsSaga),
        call(fetchSubscriptionPackagesSaga),
        call(fetchSubscriptionSuggestionsSaga),
        call(verifyPromoCodeSaga)
    ]);
}
