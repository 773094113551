exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__blockStyle---17HdM{display:flex;padding:6px;justify-content:space-around}.styles-m__red---1jeS5{color:red}.styles-m__margin---2HhuJ{margin-bottom:6px}.styles-m__marginStatus---1S2hp{margin-bottom:6px;border-bottom:1px solid var(--primary)}.styles-m__marginAddBtn---3uSOo{margin-top:6px;border-top:1px solid var(--primary)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACC,YAAa,CACV,WAAY,CACf,4BACD,CAEA,uBACC,SACD,CAEA,0BACC,iBACD,CAEA,gCACC,iBAAkB,CAClB,sCACD,CAEA,gCACC,cAAe,CACf,mCACD","file":"styles.m.css","sourcesContent":[".blockStyle {\n\tdisplay: flex;\n    padding: 6px;\n\tjustify-content: space-around;\n}\n\n.red {\n\tcolor: red\n}\n\n.margin {\n\tmargin-bottom: 6px;\n}\n\n.marginStatus {\n\tmargin-bottom: 6px;\n\tborder-bottom: 1px solid var(--primary);\n}\n\n.marginAddBtn{\n\tmargin-top: 6px;\n\tborder-top: 1px solid var(--primary);\n}"]}]);

// Exports
exports.locals = {
	"blockStyle": "styles-m__blockStyle---17HdM",
	"red": "styles-m__red---1jeS5",
	"margin": "styles-m__margin---2HhuJ",
	"marginStatus": "styles-m__marginStatus---1S2hp",
	"marginAddBtn": "styles-m__marginAddBtn---3uSOo"
};