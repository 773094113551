import { mainModuleName } from 'modals/RefactoredCashOrderModal/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'orders';
const prefix = `cpb/refactoredCashOrderModal/${moduleName}`;

export const FETCH_ORDERS = `${prefix}/FETCH_ORDERS`;
export const FETCH_ORDERS_SUCCESS = `${prefix}/FETCH_ORDERS_SUCCESS`;
export const SET_ORDERS_FILTERS = `${prefix}/SET_ORDERS_FILTERS`;
export const SET_FETCHING_ORDERS = `${prefix}/SET_FETCHING_ORDERS`;

export const SET_SELECTED_ORDER_ID = `${prefix}/SET_SELECTED_ORDER_ID`;

export const FETCH_SELECTED_ORDER = `${prefix}/FETCH_SELECTED_ORDER`;
export const FETCH_SELECTED_ORDER_SUCCESS = `${prefix}/FETCH_SELECTED_ORDER_SUCCESS`;
export const SET_FETCHING_SELECTED_ORDER = `${prefix}/SET_FETCHING_SELECTED_ORDER`;

export const CLEAR_ORDERS_STATE = `${prefix}/CLEAR_ORDERS_STATE`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    orders: [],
    ordersFilters: {
        page: 1,
        pageSize: 25,
        query: undefined,
        client: undefined, // Same as clientId
        sumRemains: true // Cash orders with remaining sum only(no 0)
    },
    ordersStats: {},
    fetchingOrders: false,

    selectedOrderId: undefined,

    selectedOrder: {},
    fetchingSelectedOrder: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_ORDERS_FILTERS:
            return {
                ...state,
                ordersFilters: {
                    ...state.ordersFilters,
                    ...payload
                }
            };
        case SET_FETCHING_ORDERS:
            return {
                ...state,
                fetchingOrders: payload
            };
        case SET_SELECTED_ORDER_ID:
            return {
                ...state,
                selectedOrderId: payload
            };
        case FETCH_SELECTED_ORDER_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_FETCHING_SELECTED_ORDER:
            return {
                ...state,
                fetchingSelectedOrder: payload
            };
        case CLEAR_ORDERS_STATE:
            return ReducerState;

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectOrders = state => state[mainModuleName][moduleName].orders;
export const selectOrdersStats = state => state[mainModuleName][moduleName].ordersStats;
export const selectOrdersFilters = state => state[mainModuleName][moduleName].ordersFilters;
export const selectFetchingOrders = state => state[mainModuleName][moduleName].fetchingOrders;

export const selectSelectedOrderId = state => state[mainModuleName][moduleName].selectedOrderId;

export const selectSelectedOrder = state => state[mainModuleName][moduleName].selectedOrder;
export const selectFetchingSelectedOrder = state =>
    state[mainModuleName][moduleName].fetchingSelectedOrder;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchOrders = () => ({
    type: FETCH_ORDERS
});

/*
 * @param {*} fetchedData.orders
 * @param {*} fetchedData.ordersStats
 */
export const fetchOrdersSuccess = fetchedData => ({
    type: FETCH_ORDERS_SUCCESS,
    payload: fetchedData
});

// eslint-disable-next-line valid-jsdoc
/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setOrdersFilters = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_ORDERS_FILTERS,
            payload: filters
        });
        dispatch(fetchOrders());
    };
};

export const setOrdersFiltersPoor = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_ORDERS_FILTERS,
            payload: filters
        });
    };
};

export const setFetchingOrders = value => ({
    type: SET_FETCHING_ORDERS,
    payload: value
});

export const fetchSelectedOrder = () => ({
    type: FETCH_SELECTED_ORDER
});

/*
 * @param {*} fetchedData.selectedOrder
 * @param {*} fetchedData.selectedOrderStats
 */
export const fetchSelectedOrderSuccess = fetchedData => ({
    type: FETCH_SELECTED_ORDER_SUCCESS,
    payload: fetchedData
});

export const setFetchingSelectedOrder = value => ({
    type: SET_FETCHING_SELECTED_ORDER,
    payload: value
});

/**
 * Set selected order and fetch it automatically.
 * Fetching is required if you set order that is not loaded in clients table.
 * If orderID is undefined, then buffer will be cleared (empty object)
 * @param {*} value - selected order ID
 */
export const setSelectedOrderId = value => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_SELECTED_ORDER_ID,
            payload: value
        });

        if (value) {
            dispatch(fetchSelectedOrder());
        } else {
            // Clear selected client buffer if id was undefined
            dispatch(fetchSelectedOrderSuccess({ selectedOrder: {} }));
        }
    };
};

export const clearOrdersState = () => ({
    type: CLEAR_ORDERS_STATE
});
