import { Modal } from 'antd';
import { createCashbox, updateCashbox } from 'core/cash/duck';
import { MODALS, resetModal } from 'core/modals/duck';
import { CashCreationForm } from 'forms';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, withReduxForm2 } from 'utils';

@injectIntl
@withReduxForm2({
    name: 'addClientForm',
    actions: {
        resetModal,
        createCashbox,
        updateCashbox
    },
    mapStateToProps: state => ({
        user: state.auth,
        isMobile: state.ui.views.isMobile,
        modal: state.modals.modal,
        modalProps: state.modals.modalProps
    })
})
/**
 * This modal is used to create cash box, it is autonomous so the only thing you have to provide is the name of this module.
 */
export default class AddCashboxModal extends Component {
    constructor(props) {
        super(props);

        this.onOk = this.onOk.bind(this);
        this.saveFormRef = this.saveFormRef.bind(this);

        this.state = {
            accounts: []
        };
    }

    handleCancel = () => {
        const { setTableRow } = this.props;
        if (this.formRef) {
            this.formRef.resetFields();
        }
        this.props.resetModal();

        this.setState({
            accountId: undefined
        });

        setTableRow(undefined);
    };

    saveFormRef(instance) {
        this.formRef = instance;
    }

    getAccountsList = async query => {
        const response = await fetchAPI('GET', '/general_ledger/accounts', { query }, null, {
            handleErrorInternally: true
        });
        this.setState({ accounts: response });
    };

    componentDidUpdate(prevProps) {
        if (this.props.modal === MODALS.ADD_CASHBOX && prevProps.modal !== this.props.modal && this.props.modal) {
            if (this.props.user.cashBoxAccountId && _.isEmpty(this.props.modalProps)) {
                this.formRef.setFieldsValue({ accountId: String(this.props.user.cashBoxAccountId) });
            }
            if (!_.isEmpty(this.props.modalProps) && this.formRef) {
                const { cashbox } = _.get(this.props, 'modalProps');
                this.formRef.setFieldsValue(
                    _.pick(cashbox, [
                        'name',
                        'type',
                        'fiscalNumber',
                        'description',
                        'autoClosePeriod',
                        'businessRequisiteId',
                        'licenseKey',
                        'accountId'
                    ])
                );
                this.setState({
                    editMode: true
                });
            } else {
                this.setState({
                    editMode: false
                });
            }
        }
    }

    componentDidMount() {
        this.getAccountsList();
    }

    onOk(e) {
        e.preventDefault();
        if (!this.formRef) return;

        const { createCashbox, updateCashbox, modalProps } = this.props;

        this.formRef.validateFields((err, values) => {
            if (!err) {
                if (this.state.editMode && modalProps) {
                    const val = {
                        ...values
                    };
                    updateCashbox({
                        id: modalProps.cashbox.id,
                        ...val
                    });
                } else {
                    const val = {
                        ...values
                    };
                    createCashbox(val);
                }
                this.handleCancel();
            }
        });
    }

    render() {
        const { modal, rows } = this.props;
        const { editMode, accounts, accountId } = this.state;

        return (
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                forceRender
                maskClosable={false}
                okButtonProps={{
                    htmlType: 'submit',
                    form: 'cash-creation-form'
                }}
                okText={<FormattedMessage id='save' />}
                onCancel={() => this.handleCancel()}
                onOk={this.onOk}
                styles={{ paddingTop: 56 }}
                title={null}
                visible={modal === MODALS.ADD_CASHBOX}
            >
                <CashCreationForm
                    accounts={accounts}
                    editMode={Boolean(editMode)} // Get form refference
                    getFormRefCB={this.saveFormRef}
                />
            </Modal>
        );
    }
}
