import { Button } from 'antd';
import { Layout } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const struct = [
    {
        blockTitle: 'directories.popular',
        color: 'var(--db_not_complete)',
        items: [
            {
                itemName: 'navigation.storage_journal',
                disabled: user => !isGrantAccessed(user, grants.WAREHOUSE_JOURNAL),
                link: book.storageJournal
            },
            {
                itemName: 'navigation.storage_calculations',
                disabled: user => !isGrantAccessed(user, grants.ACCOUNTING_SETTLEMENT_JOURNAL),
                link: book.storageCalculations
            },
            {
                itemName: 'navigation.journal_entries',
                disabled: user => !isGrantAccessed(user, grants.ACCOUNTING_JOURNAL_ENTRIES),
                link: book.journalEntries
            }
            // {
            //     itemName: 'navigation.storage_salaries',
            //     disabled: true,
            //     link: book.storageFunds
            // }
        ]
    },
    {
        blockTitle: 'directories.counterparties',
        color: 'var(--db_progress)',
        items: [
            {
                itemName: 'navigation.storage_calculations',
                disabled: user => !isGrantAccessed(user, grants.ACCOUNTING_SETTLEMENT_JOURNAL),
                link: book.storageCalculations
            },
            {
                itemName: 'navigation.storage_application',
                disabled: true
            },
            {
                itemName: 'navigation.storage_sms',
                disabled: true
            },
            {
                itemName: 'navigation.calls_journal',
                disabled: user => !isGrantAccessed(user, grants.CRM_CALL_LOG),
                link: book.callsJournal
            }
        ]
    },
    {
        blockTitle: 'navigation.accounting',
        color: 'var(--db_reserve)',
        items: [
            // {
            //     itemName: 'navigation.store_funds',
            //     disabled: true,
            //     link: book.storageFunds
            // },
            {
                itemName: 'navigation.journal_entries',
                disabled: user => !isGrantAccessed(user, grants.ACCOUNTING_JOURNAL_ENTRIES),
                link: book.journalEntries
            },
            {
                itemName: 'navigation.storage_journal_entry',
                disabled: true
            },
            {
                itemName: 'navigation.journal_vat',
                disabled: user => !isGrantAccessed(user, grants.ACCOUNTING_VAT_JOURNAL),
                link: book.journalVAT
            }
            // {
            //     itemName: 'navigation.storage_salaries',
            //     disabled: true,
            //     link: book.storageFunds
            // }
        ]
    },
    {
        blockTitle: 'navigation.products_movement',
        color: 'var(--db_success)',
        items: [
            {
                itemName: 'navigation.storage_journal',
                disabled: user => !isGrantAccessed(user, grants.WAREHOUSE_JOURNAL),
                link: book.storageJournal
            },
            {
                itemName: 'navigation.storage_orders',
                disabled: true
            },
            {
                itemName: 'navigation.storage_reserves',
                disabled: true
            }
        ]
    }
];

const mapStateToProps = state => {
    return {
        user: state.auth,
        isMobile: state.ui.views.isMobile
    };
};

@connect(mapStateToProps, void 0)
export default class DirectoriesJournalPage extends Component {
    constructor(props) {
        super(props);
    }

    _renderBlock = ({ blockTitle, items, color }, key) => {
        const { isMobile } = this.props;

        return (
            <div key={key} className={isMobile ? Styles.blockMobile : Styles.block}>
                <div className={isMobile ? Styles.blockTitleMobile : Styles.blockTitle}>
                    <FormattedMessage id={blockTitle} />
                </div>
                <div className={isMobile ? Styles.blockItemsMobile : Styles.blockItems}>
                    {items.map((item, key) => this._renderItem(blockTitle, item, key, color))}
                </div>
            </div>
        );
    };

    _renderItem = (blockTitle, { itemName, link, disabled, oldApp }, key, color) => {
        const { user, isMobile } = this.props;
        const itemDisabled = _.isFunction(disabled) ? disabled(user) : disabled;

        return (
            <div
                key={key}
                className={`${isMobile ? Styles.itemMobile : ''} ${itemDisabled ? Styles.disabledItem : ''} ${
                    Styles.item
                }`}
            >
                {oldApp ? (
                    <a className={Styles.buttonLink} href={link}>
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </a>
                ) : (
                    <Link className={Styles.buttonLink} to={link}>
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </Link>
                )}
            </div>
        );
    };

    render() {
        return (
            <Layout title={<FormattedMessage id='navigation.journals' />}>
                {struct.map((block, key) => this._renderBlock(block, key))}
            </Layout>
        );
    }
}
