import { Select } from 'antd';
import { allStationsGroups, listGroups } from 'pages/GraphicReportsPage/utils/constants';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isGrantAccessed } from 'utils/grants';

const { Option } = Select;

const TypeSelect = ({ user, list, selectedType, setSelectedType, allStations, intl: { formatMessage } }) => {
    const groupedOptions = useMemo(() => {
        return (allStations ? allStationsGroups : listGroups)
            .filter(({ grant }) => !grant || isGrantAccessed(user, grant))
            .map(({ groupName }) => ({
                label: formatMessage({ id: `graphic_reports.${groupName}` }),
                options: list
                    .filter(({ grp }) => grp === groupName)
                    .map(({ type }) => ({
                        label: formatMessage({ id: `graphic_reports.${type}` }),
                        value: type
                    }))
            }));
    }, [list, allStations]);

    return (
        <Select
            filterOption={(input, option) => {
                const searchValue = option.label.toLowerCase().replace(/[+()-\s]/g, '');
                const inputValue = input.toLowerCase();

                return searchValue.indexOf(inputValue) >= 0;
            }}
            onChange={setSelectedType}
            optionFilterProp='label'
            options={groupedOptions}
            showSearch
            style={{ width: 260 }}
            value={selectedType}
        >
            {list.map(({ type }) => (
                <Option key={type} value={type}>
                    <FormattedMessage id={`graphic_reports.${type}`} />
                </Option>
            ))}
        </Select>
    );
};

export default injectIntl(TypeSelect);
