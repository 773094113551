import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Select, Table } from 'antd';
import { Catcher } from 'commons';
import dayjs from 'dayjs';
import { DecoratedDatePicker, DecoratedInput, DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
class ArrayBreakScheduleInput extends Component {
    constructor(props) {
        super(props);

        const { initialBreakSchedule } = props;
        this.uuid = _.isArray(initialBreakSchedule) ? initialBreakSchedule.length : 0;
        const keys = _.isArray(initialBreakSchedule) ? _.keys(initialBreakSchedule) : [];

        this.state = { keys: [...keys, this.uuid++] };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.initialBreakSchedule, prevProps.initialBreakSchedule)) {
            this.props.form.resetFields();

            const { initialBreakSchedule } = this.props;
            this.uuid = _.isArray(initialBreakSchedule) ? initialBreakSchedule.length : 0;
            const keys = _.isArray(initialBreakSchedule) ? _.keys(initialBreakSchedule) : [];

            this.setState({ keys: [...keys, this.uuid++] });
        }
    }

    getBreakScheduleData(key, callback) {
        this.props.form.validateFields([`schedule[${key}]`], err => {
            if (err) {
                return;
            }

            const schedule = this.props.form.getFieldValue(`schedule[${key}]`);
            const scheduleWithParsedHours = _.mapValues(schedule, value =>
                dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : value
            );

            callback &&
                callback({
                    ...scheduleWithParsedHours,
                    subjectType: 'employee'
                });
        });
    }

    add = () => {
        const { keys } = this.state;
        this.setState({ keys: [...keys, this.uuid++] });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { initialBreakSchedule, forbiddenUpdate, loading } = this.props;
        const { formatMessage } = this.props.intl;

        const getDateTitle = (key, title) => {
            const date = _.get(initialBreakSchedule, [key, title]);

            return date ? dayjs(date) : date;
        };

        const { keys } = this.state;

        const dates = [
            ...['beginDate', 'endDate'].map(name => ({
                title: <FormattedMessage id={`array-break-schedule.${name}`} />,
                width: '20%',
                render: (text, { key }) => (
                    <DecoratedDatePicker
                        className={Styles.breakScheduleFormItem}
                        field={`schedule[${key}][${name}]`}
                        formatMessage={formatMessage}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={getDateTitle(key, name)}
                        minuteStep={30}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                    />
                )
            }))
        ];

        const comment = {
            title: <FormattedMessage id='array-break-schedule.comment' />,
            width: '35%',
            render: (text, { key }) => (
                <DecoratedInput
                    field={`schedule[${key}][note]`}
                    fields={this.props.fields}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={_.get(initialBreakSchedule, [key, 'note'])}
                />
            )
        };

        const breakType = {
            title: <FormattedMessage id='array-break-schedule.break_type' />,
            width: '15%',
            render: (text, { key }) => (
                <DecoratedSelect
                    className={Styles.breakScheduleFormItem}
                    cnStyles={Styles.scheduleType}
                    field={`schedule[${key}][type]`}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={_.get(initialBreakSchedule, [key, 'type'])}
                    rules={[
                        {
                            required: true,
                            message: 'Type is required'
                        }
                    ]}
                >
                    {[
                        'holiday',
                        'vacation',
                        'sick_leave',
                        'legal_holiday',
                        'absenteeism',
                        'cant_work'
                    ].map(item => {
                        return (
                            <Option key={item} value={item}>
                                <FormattedMessage id={item} />
                            </Option>
                        );
                    })}
                </DecoratedSelect>
            )
        };

        const actions = {
            title: '',
            width: '10%',
            render: (text, { key }) =>
                !forbiddenUpdate && (
                    <div>
                        <SaveOutlined
                            className={Styles.scheduleBreakIcon}
                            onClick={() => {
                                const callback = entity => {
                                    const initialEntity = _.get(initialBreakSchedule, [key]);

                                    if (initialEntity) {
                                        const { id } = initialEntity;
                                        this.props.updateBreakSchedule(id, entity);
                                    } else {
                                        this.props.createBreakSchedule(entity);
                                    }
                                    this.props.resetFields();
                                };
                                this.getBreakScheduleData(key, callback);
                            }}
                        />{' '}
                        {_.get(initialBreakSchedule, [key]) && (
                            <DeleteOutlined
                                className={Styles.scheduleBreakIcon}
                                onClick={() => {
                                    const id = _.get(initialBreakSchedule, [key, 'id']);
                                    if (id) {
                                        this.props.deleteBreakSchedule(id);
                                    }
                                    this.props.resetFields();
                                }}
                            />
                        )}
                    </div>
                )
        };

        const columns = [breakType, ...dates, comment, actions];

        return (
            <Catcher>
                <Table
                    bordered
                    columns={columns}
                    dataSource={keys.map(key => ({ key }))}
                    loading={loading}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    rowClassName={({ key }) => {
                        const wasEdited = _.get(this.props.fields, ['schedule', key]);
                        const exists = _.get(initialBreakSchedule, [key]);

                        if (!exists) {
                            return Styles.newBreakScheduleRow;
                        }
                        if (wasEdited) {
                            return Styles.editedBreakScheduleRow;
                        }
                    }}
                    scroll={{ x: 1000 }}
                    size='small'
                />
            </Catcher>
        );
    }
}

export default ArrayBreakScheduleInput;
