exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__reviewsStats---2JOdW{display:flex}.styles-m__column---SJiuY{display:flex;flex-direction:column}.styles-m__column---SJiuY:first-child{margin-right:20%}.styles-m__title---gyOd-{font-size:20px;margin-bottom:10px}.styles-m__titleData---1f9Mk{color:var(--secondary);font-weight:700;font-size:24px}.styles-m__reviewsList---32oEq{display:flex;flex-direction:column;flex:1;justify-content:space-around}.styles-m__listData---36xP7{font-size:20px;font-weight:700;color:var(--primary)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACI,YACJ,CAEA,0BACI,YAAa,CACb,qBACJ,CAEA,sCACI,gBACJ,CAEA,yBACI,cAAe,CACf,kBACJ,CAEA,6BACI,sBAAuB,CACvB,eAAiB,CACjB,cACJ,CAEA,+BACI,YAAa,CACb,qBAAsB,CACtB,MAAO,CACP,4BACJ,CAEA,4BACI,cAAe,CACf,eAAiB,CACjB,oBACJ","file":"styles.m.css","sourcesContent":[".reviewsStats {\n    display: flex;\n}\n\n.column {\n    display: flex;\n    flex-direction: column;\n}\n\n.column:first-child {\n    margin-right: 20%;\n}\n\n.title {\n    font-size: 20px;\n    margin-bottom: 10px;\n}\n\n.titleData {\n    color: var(--secondary);\n    font-weight: bold;\n    font-size: 24px;\n}\n\n.reviewsList {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    justify-content: space-around;\n}\n\n.listData {\n    font-size: 20px;\n    font-weight: bold;\n    color: var(--primary);\n}\n"]}]);

// Exports
exports.locals = {
	"reviewsStats": "styles-m__reviewsStats---2JOdW",
	"column": "styles-m__column---SJiuY",
	"title": "styles-m__title---gyOd-",
	"titleData": "styles-m__titleData---1f9Mk",
	"reviewsList": "styles-m__reviewsList---32oEq",
	"listData": "styles-m__listData---36xP7"
};