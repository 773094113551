// vendro
import Drawer from 'rc-drawer';
import React from 'react';
import 'theme/assets/drawer';
import SiderMenu from './SiderMenu';

const Navigation = props => {
    const { isMobile, collapsed } = props;

    return isMobile ? (
        <Drawer
            getContainer={null}
            handlechild={<i className='drawer-handle-icon' />}
            handler={!collapsed ? void 0 : false}
            level={null}
            maskClosable
            onClose={() => props.onCollapse(true)}
            onHandleClick={() => props.onCollapse(!collapsed)}
            open={!collapsed}
        >
            <SiderMenu {...props} />
        </Drawer>
    ) : (
        <SiderMenu {...props} />
    );
};

export default Navigation;
