import { CheckCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { paymentTypes } from '../config';

export const SubscribeNotification = ({ type }) => {
    return (
        <Toast>
            {type === paymentTypes.CASHLESS && (
                <React.Fragment>
                    <CheckCircleOutlined
                        style={{
                            color: 'var(--secondary)',
                            fontSize: 32
                        }}
                    />
                    <div>
                        <FormattedMessage id='subscription.cashless_success' />
                        &nbsp;
                        <br />
                        <FormattedMessage id='subscription.support_will_contact' />
                    </div>
                </React.Fragment>
            )}
        </Toast>
    );
};

const Toast = styled.div`
    margin-bottom: 8px;
    padding: 12px;
    font-size: 16px;
    border: ${props =>
        props.promocodediscount === 'error'
            ? '1px solid var(--warning)'
            : '1px solid var(--secondary)'};
    border-radius: 2px;
    background-color: ${props =>
        props.promocodediscount === 'error'
            ? 'rgba(var(--warningRGB), 0.2)'
            : 'rgba(var(--secondaryRGB), 0.1)'};
    color: ${props =>
        props.promocodediscount === 'error' ? 'var(--warning)' : 'var(--secondary)'};
    text-align: center;
`;
