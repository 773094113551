exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__settingSalaryTable---1PXa8 .ant-table-thead>tr>th{word-break:break-word}.styles-m__settingSalaryTable---1PXa8 .ant-select{width:100%}.styles-m__settingSalaryTable---1PXa8 td:last-child{text-align:center}.styles-m__saveSalary---1OsER{font-size:26px;color:var(--secondary);cursor:pointer}.styles-m__newSalary---150w5{display:flex;align-items:center;margin:24px auto}.styles-m__newSalaryRow---1M9x2{background-color:rgba(11,127,0,.28)}.styles-m__editedSalaryRow---NJ-Zm{background-color:rgba(255,45,45,.28)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,6DAEI,qBACJ,CAEA,kDACI,UACJ,CAEA,oDACI,iBACJ,CAEA,8BACI,cAAe,CACf,sBAAuB,CACvB,cACJ,CAEA,6BACI,YAAa,CACb,kBAAmB,CACnB,gBACJ,CAEA,gCACI,mCACJ,CAEA,mCACI,oCACJ","file":"styles.m.css","sourcesContent":[".settingSalaryTable :global(.ant-table-thead > tr > th, .ant-table-tbody > tr > td) {\n    /* padding: 4px; */\n    word-break: break-word;\n}\n\n.settingSalaryTable :global(.ant-select) {\n    width: 100%;\n}\n\n.settingSalaryTable td:last-child {\n    text-align: center;\n}\n\n.saveSalary {\n    font-size: 26px;\n    color: var(--secondary);\n    cursor: pointer;\n}\n\n.newSalary {\n    display: flex;\n    align-items: center;\n    margin: 24px auto;\n}\n\n.newSalaryRow {\n    background-color: rgba(11, 127, 0, 0.28);\n}\n\n.editedSalaryRow {\n    background-color: rgba(255, 45, 45, 0.28);\n}"]}]);

// Exports
exports.locals = {
	"settingSalaryTable": "styles-m__settingSalaryTable---1PXa8",
	"saveSalary": "styles-m__saveSalary---1OsER",
	"newSalary": "styles-m__newSalary---150w5",
	"newSalaryRow": "styles-m__newSalaryRow---1M9x2",
	"editedSalaryRow": "styles-m__editedSalaryRow---NJ-Zm"
};