import { DeleteOutlined, LoadingOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, InputNumber, Select, Spin, Tabs, Tooltip, TreeSelect, notification } from 'antd';
import { setDocumentTitle, setPrint } from 'core/ui/duck';
import _, { get } from 'lodash';
import { CommentaryButton } from 'modals/ComplexesModal';
import CreateComplexModal from 'pages/ComplexesPage/components/CreateComplexModal';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildStoreGroupsTree, fetchAPI, filterTreeByPart, filterTreeNodeByPart } from 'utils';
import { decompressTemplate } from '../../../../../../shared/utils/utils';
import DownloadProductReportModal from './DownloadProductReportModal';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = { setPrint, setDocumentTitle };

const { TabPane } = Tabs;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

/**
 * Product by suppliers tab
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ComplexesTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataSource: [],
            selectedComplex: undefined,
            laborsDataSource: [],
            detailsDataSource: [],
            brands: [],
            complexTree: [],
            newSellingPrice: undefined,
            fetchedComplexes: false,
            labors: [],
            details: [],
            createComplexOpen: false,
            complexes: [],
            openReport: false
        };

        this.handleFetchSellingPrice = _.debounce(
            async (productPurchasePrice, storeGroupId, detailCode, productPrice, sellingPrice, index) => {
                await this.getMarkupsSellingPrice(productPurchasePrice, storeGroupId, detailCode, index);
            },
            1000
        ).bind(this);
    }

    componentDidMount() {
        const { dataSource } = this.state;
        if (!this.state.fetchedComplexes) {
            this.fetchData();
            this.fetchLaborsAndDetails();
        }
    }

    fetchData = async () => {
        const { source, id } = this.props;
        let [typedComplexes, brands] = await Promise.all([
            fetchAPI('GET', 'business/complexes', { types: [source], storeProductIds: [id] }, null, {
                handleErrorInternally: true
            }),
            fetchAPI('GET', 'brands', null, null, { handleErrorInternally: true })
        ]);

        typedComplexes = typedComplexes.map(el => ({ ...el, id: Number(el.id) * -1 }));

        const dataSource = [
            {
                name: <FormattedMessage id={`complexes.type.${source}`} />,
                id: 1,
                childs: typedComplexes.filter(({ enabled }) => enabled)
            }
        ];
        this.buildComplexTree(dataSource);
        const complexes = typedComplexes.filter(({ enabled }) => enabled);
        if (complexes.length) {
            const assignProduct = get(complexes, '[0].storeProducts', []).find(({ productId }) => productId === id);
            if (assignProduct) {
                const labors = get(complexes, '[0].labors', []).map(elem => {
                    elem.checked = true;
                    elem.count = elem.laborPrice ? elem.laborPrice.normHours : 1;
                    elem.commentary = {
                        comment: undefined,
                        positions: []
                    };
                    elem.price = elem.laborPrice ? elem.laborPrice.price : null;
                    elem.laborUnitId = elem.laborUnitId || null;

                    return elem;
                });
                const details = get(complexes, '[0].products', []).map(elem => {
                    elem.checked = true;
                    elem.name = elem.singleName || elem.name;
                    elem.count = 1;
                    elem.commentary = {
                        comment: undefined,
                        positions: []
                    };

                    return elem;
                });

                await this.setState({
                    selectedComplex: get(complexes, '[0].id'),
                    laborsDataSource: labors,
                    detailsDataSource: details,
                    complexName: get(complexes, '[0].name'),
                    complexId: String(get(complexes, '[0].id')),
                    assignProductId: assignProduct.productId
                });
            }
        }
        this.setState({
            fetchedComplexes: true,
            dataSource,
            complexes,
            brands
        });
    };

    fetchLaborsAndDetails = async () => {
        const details = await fetchAPI('GET', '/store_groups', undefined, undefined, {
            handleErrorInternally: true
        });
        const { labors } = await fetchAPI('GET', 'labors');
        await this.setState({ details, labors, detailsTreeData: buildStoreGroupsTree(details) });
    };

    submitSave = async (assign, saveMode = false) => {
        const { detailsDataSource, laborsDataSource, complexName, complexId } = this.state;
        const { formatMessage } = this.props.intl;
        const { source } = this.props;

        this.setState({
            loading: true
        });

        const complexLaborsData = laborsDataSource.map(
            ({
                name,
                laborName,
                normHours,
                laborHours,
                price,
                laborPrice,
                count,
                laborCount,
                laborUnitId,
                id,
                laborId
            }) => ({
                laborName: name || laborName,
                laborHours: normHours || laborHours,
                laborPrice: price || laborPrice,
                laborCount: count || laborCount,
                ...(laborUnitId ? { laborUnitId } : {}),
                ...(laborId ? { laborId } : {}),
                laborLaborId: id
            })
        );
        const complexProductsData = detailsDataSource.map(
            ({
                id,
                productStoreGroupId,
                name,
                productName,
                count,
                productCount,
                price,
                productPrice,
                brandId,
                productBrandId,
                productCode,
                productId,
                productPurchasePrice
            }) => ({
                productCode,
                productStoreGroupId: id || productStoreGroupId,
                productName: name || productName,
                productCount: productCount || count,
                productPrice: price || productPrice,
                productPurchasePrice,
                ...(productBrandId || brandId ? { productBrandId: brandId || productBrandId } : {}),
                ...(productId ? { productId } : {})
            })
        );

        const id = complexId ? complexId.slice(1) : undefined;

        const body = {
            complexesData: [
                {
                    name: complexName,
                    type: source,
                    id: Number(id),
                    complexLaborsData,
                    complexProductsData,
                    assignStoreProducts: assign && !saveMode ? [this.props.id] : undefined,
                    removeStoreProducts: !assign && !saveMode ? [this.props.id] : undefined
                }
            ]
        };
        const response = await fetchAPI('PUT', 'business/complexes', null, body, {
            handleErrorInternally: true
        });

        notification.success({
            message: formatMessage({ id: 'barcode.success' })
        });

        await this.fetchData();

        this.setState({
            loading: false
        });
    };

    sumbitDelete = async () => {
        const { detailsDataSource, laborsDataSource, complexName, complexId } = this.state;
        const { formatMessage } = this.props.intl;
        const id = complexId ? complexId.slice(1) : undefined;

        try {
            await fetchAPI(
                'DELETE',
                '/business/complexes',
                null,
                { complexIds: [Number(id)] },
                {
                    handleErrorInternally: true
                }
            );

            this.setState({
                selectedComplex: undefined,
                complexName: undefined,
                complexId: undefined,
                laborsDataSource: [],
                detailsDataSource: []
            });

            await this.fetchData();
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    downloadProductReport = async (id, tp, count, disablePrice = false) => {
        const url = `/reports/store_products/${id}`;
        try {
            const response = await fetchAPI(
                'GET',
                url,
                {
                    type: tp,
                    count,
                    disablePrice
                },
                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true
                }
            );
            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; fileName="(.*)"/)[1];
            console.log(fileName);
            this.props.setDocumentTitle(fileName);
            const arrayBuffer = await response.arrayBuffer();
            const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
            const reportFile = await decompressTemplate(content);
            await this.props.setPrint(reportFile);
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    buildComplexTree(dataSource) {
        const treeData = [];
        for (let i = 0; i < dataSource.length; i++) {
            const parentGroup = dataSource[i];
            treeData.push({
                title: parentGroup.name,
                value: parentGroup.id,
                key: `${i}`,
                selectable: false,
                children: []
            });
            for (let j = 0; j < parentGroup.childs.length; j++) {
                const childGroup = parentGroup.childs[j];
                treeData[i].children.push({
                    title: childGroup.name,
                    value: childGroup.id,
                    key: `${i}-${j}`,
                    labors: childGroup.labors,
                    details: childGroup.storeGroups || childGroup.products,
                    assignProductId: childGroup.storeProducts[0] && childGroup.storeProducts[0].productId
                });
            }
        }
        this.setState({
            complexTree: treeData
        });
    }

    renderLaborsBlock(tabMode) {
        const { laborsDataSource, labors } = this.state;
        const { formatMessage } = this.props.intl;

        const allCheckedTrue = laborsDataSource.filter(elem => elem.checked === true);

        return (
            <div className={Styles.laborsList} style={tabMode && { width: '100%' }}>
                <div className={Styles.listTitle}>
                    <Checkbox
                        checked={
                            get(laborsDataSource, 'length') > 0 &&
                            get(laborsDataSource, 'length') === get(allCheckedTrue, 'length')
                        }
                        className={Styles.selectAll}
                        disabled={get(laborsDataSource, 'length') < 1}
                        onChange={({ target }) => {
                            laborsDataSource.forEach(row => {
                                row.checked = target.checked;
                            });
                            this.setState({});
                        }}
                    />
                    <FormattedMessage id='add_order_form.services' />
                </div>
                {laborsDataSource.map((elem, key) => {
                    return (
                        <div key={key} className={Styles.listRow}>
                            <div>{key + 1}</div>
                            <div>
                                <Checkbox
                                    checked={elem.checked}
                                    data-qa='checkbox_labor_data_source_complexes_modal'
                                    onChange={({ target }) => {
                                        elem.checked = target.checked;
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div className={Styles.nameField}>
                                <Input
                                    data-qa='input_name_labor_complexes_modal'
                                    disabled
                                    style={{ color: 'var(--text)' }}
                                    value={elem.name || elem.laborName}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    data-qa='input_number_count_labor_complexes_modal'
                                    decimalSeparator=','
                                    min={0.01}
                                    onChange={value => {
                                        elem.count = value;
                                        this.setState({});
                                    }}
                                    placeholder={formatMessage({ id: 'clients-page.quantity' })}
                                    step={0.1}
                                    style={{ color: 'var(--text)' }}
                                    value={elem.count || elem.laborCount}
                                />
                            </div>

                            <div>
                                <InputNumber
                                    // addonBefore={getCurrency()}
                                    data-qa='input_number_price_labor_complexes_modal'
                                    decimalSeparator=','
                                    min={0.01}
                                    onChange={value => {
                                        elem.price = value;
                                        this.setState({});
                                    }}
                                    placeholder={formatMessage({ id: 'price' })}
                                    // step={0.1}
                                    style={{ color: 'var(--text)' }}
                                    value={elem.price || elem.laborPrice}
                                />
                            </div>
                            <div>
                                <CommentaryButton
                                    commentary={elem.commentary}
                                    data-qa='commentary_button_labor_complexes_modal'
                                    detail={elem.name}
                                    disabled={!elem.name}
                                    setComment={(comment, positions) => {
                                        elem.commentary = {
                                            comment,
                                            positions
                                        };
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div>
                                <DeleteOutlined
                                    className={Styles.deleteIcon}
                                    onClick={() => {
                                        this.setState({
                                            laborsDataSource: laborsDataSource.filter((labor, index) => index != key)
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
                <div className={Styles.listRow}>
                    <div>{laborsDataSource.length + 1}</div>
                    <div>
                        <Checkbox checked data-qa='checkbox_laborsDataSource_1_complexes_modal' disabled />
                    </div>
                    <div className={Styles.nameField}>
                        <Select
                            data-qa='select_laborDataSourse_push_complexes_modal'
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: '9999',
                                minWidth: 220
                            }}
                            filterOption={(input, option) => {
                                const parts = input.toLowerCase().split(' ');

                                return parts.every(part => String(option.children).toLowerCase().includes(part));
                            }}
                            onSelect={(value, { name, hours, price, laborUnitId }) => {
                                laborsDataSource.push({
                                    id: value,
                                    name,
                                    count: hours || 1,
                                    normHours: hours,
                                    price,
                                    checked: true,
                                    commentary: {
                                        comment: undefined,
                                        positions: []
                                    },
                                    laborUnitId
                                });
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.labor'
                            })}
                            showSearch
                            value={null}
                        >
                            {labors.map(({ id, name, laborPrice, laborUnitId }, key) => (
                                <Option
                                    key={key}
                                    hours={laborPrice.normHours}
                                    laborUnitId={laborUnitId}
                                    name={name}
                                    price={laborPrice.price}
                                    value={id}
                                >
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <InputNumber
                            data-qa='input_number_one_labor_complexes_modal'
                            decimalSeparator=','
                            disabled
                            placeholder={formatMessage({ id: 'clients-page.quantity' })}
                            step={0.1}
                            style={{ color: 'var(--text)' }}
                            value={1}
                        />
                    </div>
                    <div>
                        <InputNumber
                            // addonBefore={getCurrency()}
                            data-qa='input_number_price_labor_complexes_modal'
                            decimalSeparator=','
                            disabled
                            min={0.1}
                            // step={0.1}
                            style={{ color: 'var(--text)' }}
                        />
                    </div>
                    <div>
                        <CommentaryButton
                            commentary={{
                                comment: undefined,
                                positions: []
                            }}
                            data-qa='commentary_button_labor_complexes_modal'
                            disabled
                        />
                    </div>
                    <div>
                        <DeleteOutlined className={Styles.disabledIcon} />
                    </div>
                </div>
            </div>
        );
    }

    renderDetailsBlock(tabMode) {
        const { detailsDataSource, laborsDataSource, newSellingPrice, detailsTreeData } = this.state;
        const { formatMessage } = this.props.intl;

        const sellingPrice = newSellingPrice ? newSellingPrice.sellingPrice : 1;

        const allCheckedTrue = detailsDataSource.filter(elem => elem.checked === true);

        return (
            <div className={Styles.detailsList} style={tabMode && { width: '100%' }}>
                <div className={Styles.listTitle}>
                    <Checkbox
                        checked={
                            get(detailsDataSource, 'length') > 0 &&
                            get(detailsDataSource, 'length') === get(allCheckedTrue, 'length')
                        }
                        className={Styles.selectAll}
                        disabled={get(detailsDataSource, 'length') < 1}
                        onChange={({ target }) => {
                            detailsDataSource.forEach(row => {
                                row.checked = target.checked;
                            });
                            this.setState({});
                        }}
                    />
                    <FormattedMessage id='add_order_form.details' />
                </div>
                {detailsDataSource.map((elem, key) => {
                    return (
                        <div key={key} className={Styles.listRow}>
                            <div>{key + 1}</div>
                            <div>
                                <Checkbox
                                    checked={elem.checked}
                                    data-qa='checkbox_detailsDataSource_complexes_modal'
                                    onChange={({ target }) => {
                                        elem.checked = target.checked;
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div className={Styles.nameField}>
                                <Input
                                    data-qa='input_name_detail_complexes_modal'
                                    disabled
                                    style={{ color: 'var(--text)' }}
                                    value={elem.name || elem.productName}
                                />
                            </div>
                            <div>
                                <Select
                                    data-qa='select_laborDataSourse_push_complexes_modal'
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                        zIndex: '9999',
                                        minWidth: 150
                                    }}
                                    filterOption={(input, option) => {
                                        const brands = input.toLowerCase().split(' ');
                                        console.log(input, option);

                                        return brands.every(part =>
                                            String(option.children).toLowerCase().includes(part));
                                    }}
                                    onSelect={(value, value2) => {
                                        elem.brandId = value;
                                        this.setState({});
                                    }}
                                    placeholder={formatMessage({
                                        id: 'brands_page.title'
                                    })}
                                    showSearch
                                    style={{ width: 150 }}
                                    value={elem.brandId || elem.productBrandId}
                                    width='fit'
                                >
                                    {this.state.brands.map(({ brandId, brandName }) => (
                                        <Option key={brandId} value={brandId}>
                                            {brandName}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Input
                                    data-qa='input_number_price_labor_complexes_modal'
                                    onBlur={() => {
                                        this.changeDetailsDataSourceField({
                                            productPrice: sellingPrice
                                        });
                                    }}
                                    onChange={event => {
                                        elem.productCode = event.target.value;
                                        this.setState({});
                                    }}
                                    placeholder={formatMessage({ id: 'order_form_table.detail_code' })}
                                    style={{ color: 'var(--text)', minWidth: 90 }}
                                    value={elem.productCode}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    data-qa='input_number_count_detail_complexes_modal'
                                    onChange={value => {
                                        elem.productCount = value;
                                        this.setState({});
                                    }}
                                    placeholder={formatMessage({ id: 'clients-page.quantity' })}
                                    step={0.1}
                                    style={{ color: 'var(--text)' }}
                                    value={elem.productCount}
                                    decimalSeparator=','
                                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    min={0.01}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    // addonBefore={getCurrency()}
                                    data-qa='input_number_purchase_price_labor_complexes_modal'
                                    decimalSeparator=','
                                    min={0.01}
                                    onBlur={() => {
                                        this.changeDetailsDataSourceField({
                                            productPrice: sellingPrice
                                        });
                                    }}
                                    onChange={value => {
                                        elem.productPurchasePrice = value;
                                        this.setState({});

                                        console.log(key, 'key');
                                    }}
                                    placeholder={formatMessage({ id: 'order_form_table.productPurchasePrice' })}
                                    step={0.1}
                                    style={{ color: 'var(--text)' }}
                                    value={elem.productPurchasePrice}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    // addonBefore={getCurrency()}
                                    data-qa='input_number_price_labor_complexes_modal'
                                    decimalSeparator=','
                                    min={0.01}
                                    onChange={value => {
                                        elem.productPrice = value;
                                        this.setState({});
                                    }}
                                    placeholder={formatMessage({ id: 'price' })}
                                    step={0.1}
                                    style={{ color: 'var(--text)' }}
                                    value={elem.productPrice}
                                />
                            </div>
                            <div>
                                <CommentaryButton
                                    commentary={elem.commentary}
                                    data-qa='commentary_button_detail_complexes_modal'
                                    detail={elem.name}
                                    disabled={!elem.name}
                                    setComment={(comment, positions) => {
                                        elem.commentary = {
                                            comment,
                                            positions
                                        };
                                        this.setState({});
                                    }}
                                />
                            </div>
                            <div>
                                <DeleteOutlined
                                    className={Styles.deleteIcon}
                                    onClick={() => {
                                        this.setState({
                                            detailsDataSource: detailsDataSource.filter((labor, index) => index != key)
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
                <div className={Styles.listRow}>
                    <div>{detailsDataSource.length + 1}</div>
                    <div>
                        <Checkbox checked data-qa='checkbox_detailsDataSource_1_complexes_modal' disabled />
                    </div>
                    <div className={Styles.nameField}>
                        <TreeSelect
                            data-qa='tree_select_push_detail_complexes_modal'
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterTreeNode={filterTreeNodeByPart}
                            onSelect={(value, { title }) => {
                                detailsDataSource.push({
                                    id: value,
                                    name: title,
                                    count: 1,
                                    checked: true,
                                    commentary: {
                                        comment: undefined,
                                        positions: []
                                    }
                                });
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'services_table.complex'
                            })}
                            popupMatchSelectWidth={false}
                            showSearch
                            treeData={detailsTreeData}
                            value={null}
                        />
                    </div>
                    <div>
                        <Select
                            data-qa='select_laborDataSourse_push_complexes_modal'
                            disabled
                            placeholder={formatMessage({
                                id: 'brands_page.title'
                            })}
                            showSearch
                            style={{ width: 150 }}
                            value={null}
                            width='fit'
                        />
                    </div>
                    <div>
                        <InputNumber
                            data-qa='input_number_detail_1_complexes_modal'
                            disabled
                            placeholder={formatMessage({ id: 'order_form_table.detail_code' })}
                            style={{ color: 'var(--text)' }}
                        />
                    </div>
                    <div>
                        <InputNumber
                            data-qa='input_number_detail_1_complexes_modal'
                            decimalSeparator=','
                            disabled
                            placeholder={formatMessage({ id: 'clients-page.quantity' })}
                            style={{ color: 'var(--text)' }}
                            value={1}
                        />
                    </div>
                    <div>
                        <InputNumber
                            // addonBefore={getCurrency()}
                            data-qa='input_number_purchase_price_labor_complexes_modal'
                            decimalSeparator=','
                            disabled
                            min={0.1}
                            placeholder={formatMessage({ id: 'order_form_table.productPurchasePrice' })}
                            // step={0.1}
                            style={{ color: 'var(--text)' }}
                        />
                    </div>
                    <div>
                        <InputNumber
                            // addonBefore={getCurrency()}
                            data-qa='input_number_price_labor_complexes_modal'
                            decimalSeparator=','
                            disabled
                            placeholder={formatMessage({ id: 'price' })}
                            min={0.1}
                            // step={0.1}
                            style={{ color: 'var(--text)' }}
                        />
                    </div>
                    <div>
                        <CommentaryButton
                            commentary={{
                                comment: undefined,
                                positions: []
                            }}
                            data-qa='commentary_button_detail_1_complexes_modal'
                            disabled
                        />
                    </div>
                    <div>
                        <DeleteOutlined className={Styles.disabledIcon} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isMobile, id, source, code } = this.props;
        const {
            dataSource,
            selectedComplex,
            laborsDataSource,
            detailsDataSource,
            complexTree,
            complexName,
            complexId,
            brands,
            details,
            labors,
            createComplexOpen,
            assignProductId,
            complexes,
            loading,
            openReport
        } = this.state;

        const findComplex = complexes.find(({ id }) => Number(complexId) === id);
        const findAssignId = findComplex ? findComplex.storeProducts.find(({ productId }) => productId === id) : [];
        const reportType =
            source === 'PRODUCTION' ? 'productionReport' : source === 'DISASSEMBLY' ? 'disassemblyReport' : null;

        return (
            <div>
                <div>
                    {this.state.fetchedComplexes ? (
                        <div>
                            <div className={Styles.complexSelect}>
                                <TreeSelect
                                    allowClear
                                    data-qa='tree_select_modal_complexes_modal'
                                    dropdownStyle={{
                                        overflow: 'auto',
                                        zIndex: '9999',
                                        maxHeight: 500
                                    }}
                                    filterTreeNode={filterTreeByPart}
                                    onChange={async (value, label, { triggerNode }) => {
                                        console.log(triggerNode, 'triggerNode');
                                        const name = triggerNode.props.title;
                                        const { assignProductId } = triggerNode.props;
                                        const labors = triggerNode.props.labors.map(elem => {
                                            elem.checked = true;
                                            elem.count = elem.laborPrice ? elem.laborPrice.normHours : 1;
                                            elem.commentary = {
                                                comment: undefined,
                                                positions: []
                                            };
                                            elem.price = elem.laborPrice ? elem.laborPrice.price : null;
                                            elem.laborUnitId = elem.laborUnitId || null;

                                            return elem;
                                        });
                                        const details = triggerNode.props.details.map(elem => {
                                            elem.checked = true;
                                            elem.name = elem.singleName;
                                            elem.count = 1;
                                            elem.commentary = {
                                                comment: undefined,
                                                positions: []
                                            };

                                            return elem;
                                        });
                                        await this.setState({
                                            selectedComplex: value,
                                            laborsDataSource: labors,
                                            detailsDataSource: details,
                                            complexName: name,
                                            complexId: String(value),
                                            assignProductId
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'services_table.complex'
                                    })}
                                    showSearch
                                    style={{
                                        width: '100%'
                                    }}
                                    treeData={complexTree}
                                    value={selectedComplex}
                                />
                                <Tooltip
                                    getPopupContainer={trigger => trigger.parentNode}
                                    title={
                                        <div>
                                            <div>
                                                <FormattedMessage id='complexes.product_report_production' />/
                                            </div>
                                            <div>
                                                <FormattedMessage id='complexes.product_report__disassembly' />
                                            </div>
                                        </div>
                                    }
                                >
                                    <Button
                                        icon={<PrinterOutlined />}
                                        onClick={() =>
                                            this.setState({
                                                openReport: true
                                            })
                                        }
                                    />
                                </Tooltip>
                            </div>
                            {complexId && (
                                <div
                                    style={{
                                        width: '30%'
                                    }}
                                >
                                    <Input
                                        disabled={!complexId}
                                        onChange={e =>
                                            this.setState({
                                                complexName: e.target.value
                                            })
                                        }
                                        placeholder={this.props.intl.formatMessage({ id: 'complexes.complex_name' })}
                                        style={{ marginBottom: '16px' }}
                                        value={complexName}
                                    />
                                </div>
                            )}
                            {!isMobile ? (
                                <div>
                                    {complexId && (
                                        <div className={Styles.listsWrapper}>
                                            {this.renderLaborsBlock()}
                                            {this.renderDetailsBlock()}
                                        </div>
                                    )}

                                    <div className={Styles.actionBtns}>
                                        {!findAssignId && selectedComplex ? (
                                            <Button
                                                loading={loading}
                                                onClick={() => {
                                                    this.submitSave(true);
                                                    // this.setState({
                                                    //     selectedComplex: undefined,
                                                    //     complexId: undefined
                                                    // });
                                                }}
                                                style={{
                                                    marginRight: 8
                                                }}
                                            >
                                                <FormattedMessage id='complexes.asign_product' />
                                            </Button>
                                        ) : findAssignId && selectedComplex ? (
                                            <Button
                                                loading={loading}
                                                onClick={() => {
                                                    this.submitSave(false);
                                                    // this.setState({
                                                    //     selectedComplex: undefined,
                                                    //     complexId: undefined
                                                    // });
                                                }}
                                                style={{
                                                    marginRight: 8
                                                }}
                                            >
                                                <FormattedMessage id='complexes.unassign_product' />
                                            </Button>
                                        ) : undefined}
                                        <Button
                                            loading={loading}
                                            onClick={() =>
                                                this.setState({
                                                    createComplexOpen: true
                                                })
                                            }
                                            style={{
                                                marginRight: 8
                                            }}
                                        >
                                            <FormattedMessage id='complexes.create_template' />
                                        </Button>
                                        {complexId && (
                                            <Button
                                                disabled={!complexId}
                                                loading={loading}
                                                onClick={() => this.submitSave(undefined, true)}
                                                style={{
                                                    marginRight: 8
                                                }}
                                                type='primary'
                                            >
                                                <FormattedMessage id='save' />
                                            </Button>
                                        )}
                                        {complexId && (
                                            <Button
                                                danger
                                                disabled={!complexId}
                                                loading={loading}
                                                onClick={() => this.sumbitDelete()}
                                            >
                                                <FormattedMessage id='delete' />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <Tabs>
                                    <TabPane
                                        key='services'
                                        data-qa='tab_pane_services_complexes_modal'
                                        forceRender
                                        tab={this.props.intl.formatMessage({
                                            id: 'add_order_form.services',
                                            defaultMessage: 'Services'
                                        })}
                                    >
                                        {this.renderLaborsBlock(true)}
                                    </TabPane>
                                    <TabPane
                                        key='details'
                                        data-qa='tab_pane_details_complexes_modal'
                                        forceRender
                                        tab={this.props.intl.formatMessage({
                                            id: 'add_order_form.details',
                                            defaultMessage: 'Details'
                                        })}
                                    >
                                        {this.renderDetailsBlock(true)}
                                    </TabPane>
                                </Tabs>
                            )}
                        </div>
                    ) : (
                        <Spin indicator={spinIcon} />
                    )}
                </div>
                <CreateComplexModal
                    brands={brands}
                    code={code}
                    details={details}
                    fetchComplexes={this.fetchData}
                    hideModal={() => this.setState({ createComplexOpen: false })}
                    labors={labors}
                    open={createComplexOpen}
                    source={source}
                    storeProductId={id}
                />
                <DownloadProductReportModal
                    downloadProductReport={this.downloadProductReport}
                    hideModal={() => this.setState({ openReport: false })}
                    id={this.props.id}
                    open={openReport}
                    reportType={reportType}
                />
            </div>
        );
    }
}
