import { Tag } from 'antd';
import { Numeral } from 'commons';
import { DatetimeFormatter } from 'commons/_uikit';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';

// import { ProductTableData } from '../ProductTableData';

const getMinus = (docType, value) => {
    if (docType) {
        return '';
    }
    if (!docType && value === 0) {
        return '';
    }

    return '-';
};

const getDocumentType = props => {};

export const columnsConfig = props => {
    const code = {
        title: props.intl.formatMessage({
            id: 'storage.product_code'
        }),
        key: 'code',
        dataIndex: 'code',
        render: (code, { product }) =>
            isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD) ? (
                <Link to={`${book.product}/${product.id}`}>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--link)'
                        }}
                    >
                        {product.code}
                    </div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--text3)',
                            fontSize: 12
                        }}
                    >
                        {product.name}
                    </div>
                </Link>
            ) : (
                <div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--link)'
                        }}
                    >
                        {product.code}
                    </div>
                    <div
                        style={{
                            fontWeight: 500,
                            color: 'var(--text3)',
                            fontSize: 12
                        }}
                    >
                        {product.name}
                    </div>
                </div>
            )
    };

    const type = {
        title: props.intl.formatMessage({
            id: 'storage.operation_type'
        }),
        key: 'type',
        render: (key, data) => {
            const type = _.get(data, 'doc.type');

            return (
                <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                    {props.intl.formatMessage({
                        id: `product.${type === 'INCOME' ? 'order' : 'income_order'}`
                    })}
                </Tag>
            );
        }
    };

    const docNum = {
        title: props.intl.formatMessage({
            id: 'storage.document'
        }),
        key: 'docNum',

        render: (key, data) => {
            const docId = _.get(data, 'doc.id');
            const documentNumber = _.get(data, 'doc.documentNumber');

            return data.orderId ? (
                <a href={`${book.order}/${data.orderId}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                    {data.orderId}
                </a>
            ) : docId && isGrantAccessed(props.user, grants.WAREHOUSE_DOCUMENT) ? (
                <a href={`${book.storageDocument}/${docId}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                    {documentNumber}
                </a>
            ) : (
                documentNumber
            );
        }
    };

    const docNumAndType = {
        title: props.intl.formatMessage({
            id: 'storage.document'
        }),
        key: 'docNumAndType',
        render: (key, data) => {
            const docId = _.get(data, 'doc.id');
            const documentNumber = _.get(data, 'doc.documentNumber');
            const type = _.get(data, 'doc.type');

            return data.orderId ? (
                <div>
                    <a href={`${book.order}/${data.orderId}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                        {data.orderId}
                    </a>
                    <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                        {props.intl.formatMessage({
                            id: `storage.${type || 'EXPENSE'}`
                        })}
                    </Tag>
                </div>
            ) : docId && isGrantAccessed(props.user, grants.WAREHOUSE_DOCUMENT) ? (
                <div>
                    <a href={`${book.storageDocument}/${docId}`} style={{ color: 'var(--link)', fontWeight: 'bold' }}>
                        {documentNumber}
                    </a>
                    <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                        {props.intl.formatMessage({
                            id: `storage.${type || 'EXPENSE'}`
                        })}
                    </Tag>
                </div>
            ) : (
                <div>
                    <span>{documentNumber}</span>
                    <Tag color={type === 'INCOME' ? 'var(--green)' : 'var(--warning)'}>
                        {props.intl.formatMessage({
                            id: `storage.${type || 'EXPENSE'}`
                        })}
                    </Tag>
                </div>
            );
        }
    };

    const warehouse = {
        title: props.intl.formatMessage({
            id: 'navigation.storage'
        }),
        key: 'warehouse',
        render: row => {
            return <div>{row.doc ? row.doc.warehouseName : ''}</div>;
        }
    };

    const createdDatetime = {
        title: props.intl.formatMessage({
            id: 'storage.date'
        }),
        dataIndex: 'datetime',
        sorter: (a, b) => (dayjs(a.datetime).isAfter(b.datetime) ? 1 : dayjs(b.datetime).isAfter(a.datetime) ? -1 : 0),
        defaultSortOrder: 'descend',
        render: datetime => <DatetimeFormatter datetime={datetime} />
    };

    const counterparty = {
        title: props.intl.formatMessage({
            id: 'storage.counterparty'
        }),
        dataIndex: 'doc',
        render: (doc, data) => {
            const income = Boolean(doc);
            const isCounterpartyExists = _.get(data, 'order.client.id') || _.get(data, 'doc.businessSupplier.name');

            return isCounterpartyExists ? (
                <Link
                    to={
                        income
                            ? `${book.supplier}/${_.get(data, 'doc.businessSupplier.id')}`
                            : `${book.client}/${_.get(data, 'order.client.id')}`
                    }
                >
                    {income
                        ? `${_.get(data, 'doc.businessSupplier.name', '')}`
                        : `${_.get(data, 'order.client.name', '')} ${_.get(data, 'order.client.surname', '')}`}
                </Link>
            ) : null;
        }
    };

    const responsible = {
        title: props.intl.formatMessage({
            id: 'storage.responsible'
        }),
        dataIndex: 'order',
        render: (order, data) => {
            const expense = Boolean(order);
            const isManagerExists = _.get(data, 'order.manager.employeeId') || _.get(data, 'doc.manager.employeeId');

            return isManagerExists ? (
                <Link
                    to={`${book.employeesPage}/${
                        expense ? _.get(data, 'order.manager.employeeId') : _.get(data, 'doc.manager.employeeId')
                    }`}
                >
                    {expense
                        ? `${_.get(data, 'order.manager.name', '')} ${_.get(data, 'order.manager.surname', '')}`
                        : `${_.get(data, 'doc.manager.name', '')} ${_.get(data, 'doc.manager.surname', '')}`}
                </Link>
            ) : null;
        }
    };

    const quantity = {
        title: props.intl.formatMessage({
            id: 'storage.quantity'
        }),
        dataIndex: 'quantity',
        render: (quantity, data) => {
            return (
                <React.Fragment>
                    {getMinus(_.get(data, 'doc.type'), Number.parseInt(quantity, 0))}
                    {Number(quantity).toFixed(1)}
                </React.Fragment>
            );
        }
    };

    const purchasePrice = {
        title: props.intl.formatMessage({
            id: 'storage.purchase_price'
        }),
        dataIndex: 'purchasePrice',
        render: purchasePrice => (
            <Numeral currency={getCurrency()} mask='0,0.00'>
                {Number(purchasePrice)}
            </Numeral>
        )
    };

    const purchaseSum = {
        title: props.intl.formatMessage({
            id: 'storage.purchase_sum'
        }),
        dataIndex: 'purchaseSum',
        render: (purchaseSum, data) => {
            return (
                <React.Fragment>
                    {getMinus(_.get(data, 'doc.type'), purchaseSum)}
                    <Numeral currency={getCurrency()} mask='0,0.00'>
                        {Number(purchaseSum)}
                    </Numeral>
                </React.Fragment>
            );
        }
    };

    const sellingPrice = {
        title: props.intl.formatMessage({
            id: 'storage.selling_price'
        }),
        dataIndex: 'sellingPrice',
        render: sellingPrice => (
            <Numeral currency={getCurrency()} mask='0,0.00'>
                {Number(sellingPrice)}
            </Numeral>
        )
    };

    const sellingSum = {
        title: props.intl.formatMessage({
            id: 'storage.selling_sum'
        }),
        dataIndex: 'sellingSum',
        render: (sellingSum, data) => {
            return (
                <React.Fragment>
                    {getMinus(_.get(data, 'doc.type'), sellingSum)}
                    <Numeral currency={getCurrency()} mask='0,0.00'>
                        {Number(sellingSum)}
                    </Numeral>
                </React.Fragment>
            );
        }
    };

    const units = {
        title: props.intl.formatMessage({
            id: 'services_table.units'
        }),
        width: '5vh',
        align: 'center',
        render: (unit, data) => {
            const units = _.get(data, 'product.unit');

            return <React.Fragment>{units || undefined}</React.Fragment>;
        }
    };

    const columns = [
        code,
        warehouse,
        type,
        docNum,
        docNumAndType,
        createdDatetime,
        counterparty,
        responsible,
        quantity,
        units,
        purchasePrice,
        purchaseSum,
        sellingPrice,
        sellingSum
    ];

    if (props.rawData) {
        _.remove(columns, ({ key }) => key == 'type' || key == 'docNum');
    } else {
        _.remove(columns, ({ key }) => key == 'docNumAndType');
    }
    if (props.hideCode) {
        _.remove(columns, ({ key }) => key == 'code');
    }

    return columns;
};
