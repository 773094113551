import { QuestionCircleFilled } from '@ant-design/icons';
import { Modal, Table, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { Catcher } from 'commons';
import {
    clearVehicleNumberHistory,
    fetchVehicleNumberHistory
} from 'core/vehicleNumberHistory/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const cx = classNames.bind(Styles);

const mapStateToProps = state => {
    return {
        history: state.vehicleNumberHistory.history
    };
};

const mapDispatchToProps = {
    clearVehicleNumberHistory,
    fetchVehicleNumberHistory
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleNumberHistory extends Component {
    _tooltipClassName = disabled =>
        cx({
            tooltip: true,
            tooltipDisabled: disabled
        });

    render() {
        const { history, vehicleNumber } = this.props;

        const columns = [
            {
                title: <FormattedMessage id='dReg' />,
                width: '10%',
                dataIndex: 'dReg'
            },
            {
                title: <FormattedMessage id='makeYear' />,
                width: '10%',
                dataIndex: 'makeYear'
            },
            {
                title: <FormattedMessage id='brand' />,
                width: '10%',
                dataIndex: 'brand'
            },
            {
                title: <FormattedMessage id='model' />,
                width: '10%',
                dataIndex: 'model'
            },
            {
                title: <FormattedMessage id='fuel' />,
                width: '10%',
                dataIndex: 'fuel'
            },
            {
                title: <FormattedMessage id='body' />,
                width: '10%',
                dataIndex: 'body'
            },
            {
                title: <FormattedMessage id='nRegNew' />,
                width: '10%',
                dataIndex: 'nRegNew'
            },
            {
                title: <FormattedMessage id='operName' />,
                width: '30%',
                dataIndex: 'operName'
            }
        ];

        return (
            <Catcher>
                <Tooltip
                    overlayStyle={{ zIndex: 2110 }}
                    title={
                        !vehicleNumber ? (
                            <FormattedMessage id='vehicle_number_history.tooltip' />
                        ) : (
                            <FormattedMessage id='vehicle_number_history.title' />
                        )
                    }
                >
                    <QuestionCircleFilled
                        className={this._tooltipClassName(!vehicleNumber)}
                        disabled={!vehicleNumber}
                        onClick={() =>
                            vehicleNumber && this.props.fetchVehicleNumberHistory(vehicleNumber)
                        }
                    />
                </Tooltip>
                <Modal
                    cancelText={<FormattedMessage id='cancel' />}
                    footer={null}
                    maskClosable={false}
                    onCancel={() => this.props.clearVehicleNumberHistory()}
                    title={<FormattedMessage id='vehicle_number_history.title' />}
                    visible={Boolean(history)}
                    width='80%'
                >
                    <Table bordered columns={columns} dataSource={history} rowKey={v4} />
                </Modal>
            </Catcher>
        );
    }
}
