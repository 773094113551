import React from 'react';

export const Point = ({ children, key, top, left, onClick }) => {
    return (
        <div
            key={key}
            onClick={onClick}
            style={{
                position: 'absolute',
                left,
                top,
                transform: 'translate(-50%, -50%)',
                color: 'white',
                background: 'var(--primary)',
                border: '1px solid white',
                width: '21px',
                height: '21px',
                lineHeight: '18px',
                paddingBottom: '3px',
                cursor: 'pointer',
                borderRadius: '50%',
                textAlign: 'center'
            }}
        >
            {children}
        </div>
    );
};
