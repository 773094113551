import { Input, Table } from 'antd';
import _ from 'lodash';
import {
    selectVehicleNormHours,
    selectVehicleNormHoursFetching,
    selectVehicleNormHoursSort,
    selectVehicleNormHoursStats,
    setNormHoursSearchQuery,
    setPageNormHours
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    normHours: selectVehicleNormHours(state),
    stats: selectVehicleNormHoursStats(state),
    sort: selectVehicleNormHoursSort(state),
    fetching: selectVehicleNormHoursFetching(state)
});

const mapDispatchToProps = {
    setPageNormHours,
    setNormHoursSearchQuery
};

/**
 * Table of norm hours, it also has search filter
 * */
@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class VehicleNormHoursTable extends React.Component {
    constructor(props) {
        super(props);

        /** When user want to search just pass here its input, if no mere was provided in a second it will perform a search action */
        this.handleSearch = _.debounce(value => {
            this.props.setNormHoursSearchQuery({
                query: _.toLower(value.replace(/[+()]/g, ''))
            });
        }, 1000).bind(this);
    }

    onSearch = e => {
        const { value } = e.target;
        this.handleSearch(value);
    };

    render() {
        const {
            normHours,
            stats,
            sort,
            intl: { formatMessage },
            setPageNormHours,
            fetching
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / 25) * 25,
            current: sort.page,
            onChange: page => {
                setPageNormHours({ page });
            }
        };

        return (
            <div className={Styles.tableCont}>
                <div className={Styles.filtersCont}>
                    <div className={Styles.textCont}>
                        <FormattedMessage id='vehicles_page.search' />
                    </div>
                    <div className={Styles.inputCont}>
                        <Input allowClear onChange={this.onSearch} />
                    </div>
                </div>

                <Table
                    bordered
                    columns={columnsConfig({ formatMessage })}
                    dataSource={normHours}
                    loading={fetching}
                    pagination={pagination}
                    rowKey={() => v4()}
                    scroll={{ y: '60vh' }}
                    size='small'
                />
            </div>
        );
    }
}
