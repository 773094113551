import { Input, Table } from 'antd';
import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import {
    selectExpandedVehicleId,
    selectFetchingVehicles,
    selectSort,
    selectVehicles,
    selectVehiclesStats,
    setExpandedVehicleId,
    setPage,
    setSearchQuery
} from '../../redux/duck';
import { columnsConfig } from './config';
import { OrdersConfig } from './OrdersConfig';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    vehicles: selectVehicles(state),
    stats: selectVehiclesStats(state),
    sort: selectSort(state),
    expandedVehicleId: selectExpandedVehicleId(state),
    fetchingVehicles: selectFetchingVehicles(state)
});

const mapDispatchToProps = {
    setPage,
    setSearchQuery,
    setExpandedVehicleId
};

/**
 * This table contains vehicles, each vehicle row can be expanded and its orders will appear
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@withRouter
export default class AggregatesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allAggregatess: [],
            aggregatesOrders: [],
            page: 1,
            pageSize: 25,
            ordersPage: 1,
            ordersPageSize: 25,
            query: ''
        };

        /** When user want to search just pass here its input, if no mere was provided in a second it will perform a search action */
        this.handleSearch = _.debounce(value => {
            this.setState({
                query: _.toLower(value.replace(/[+()]/g, ''))
            });
            this.fetchAggregates();
        }, 1000).bind(this);
    }

    componentDidMount() {
        this.fetchAggregates();
    }

    onSearch = e => {
        const { value } = e.target;
        this.handleSearch(value);
    };

    fetchAggregates = async () => {
        const { page, pageSize, query } = this.state;
        this.setState({
            loading: true
        });
        const aggregates = await fetchAPI(
            'GET',
            '/clients/aggregates',
            {
                page,
                pageSize,
                query
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            allAggregatess: aggregates
        });
        this.setState({
            loading: false
        });
    };

    fetchAllAggregatesOrders = async ({ aggregateId }) => {
        const { page, pageSize, query } = this.state;
        this.setState({
            loading: true
        });
        const aggregatesOrders = await fetchAPI(
            'GET',
            `/orders/aggregate/${aggregateId}`,
            {
                page,
                pageSize,
                query
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            aggregatesOrders
        });
        this.setState({
            loading: false
        });
    };

    render() {
        const {
            user,
            fetchingVehicles,
            intl: { formatMessage }
        } = this.props;

        const { allAggregatess, page, pageSize, query, loading, aggregatesOrders, ordersPageSize, ordersPage } =
            this.state;

        const dataSource = _.get(allAggregatess, 'clientsAggregates', []);
        const ordersDataSource = _.get(aggregatesOrders, 'orders', []);

        const totalAmount = _.get(allAggregatess, 'clientsAggregatesStats.totalRowsCount', 0);
        const totalAmountOrder = _.get(aggregatesOrders, 'stats.countOrders', 0);

        const pagination = {
            pageSize,
            page,
            size: 'large',
            total: Math.ceil(totalAmount / pageSize) * pageSize,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchAggregates();
            }
        };

        return (
            <React.Fragment>
                <div>
                    <div className={Styles.filtersCont}>
                        <div className={Styles.inputCont}>
                            <Input.Search
                                allowClear
                                defaultValue={this.initialSearchQuery}
                                onChange={this.onSearch}
                                placeholder={formatMessage({ id: 'vehicles_page.search' })}
                            />
                        </div>
                    </div>
                    <Table
                        bordered
                        className={Styles.table}
                        columns={columnsConfig({ user })}
                        dataSource={dataSource}
                        expandable={{
                            expandedRowRender: record => (
                                <div className={Styles.tableCont}>
                                    <Table
                                        bordered
                                        className={Styles.table}
                                        columns={OrdersConfig({ user })}
                                        dataSource={ordersDataSource}
                                        pagination={{
                                            pageSize: ordersPageSize,
                                            page: ordersPage,
                                            size: 'large',
                                            total: Math.ceil(totalAmountOrder / ordersPageSize) * ordersPageSize,
                                            current: ordersPage,
                                            onChange: async (page, pageSize) => {
                                                await this.setState({ page, pageSize });
                                                this.fetchAllAggregatesOrders({
                                                    aggregateId: record.clientAggregateId
                                                });
                                            }
                                        }}
                                        rowClassName={() => Styles.tableRow}
                                        rowKey={order => order.id}
                                    />
                                </div>
                            ),
                            rowExpandable: record => record.clientAggregateId
                        }}
                        loading={loading}
                        onExpand={(expanded, aggregate) => {
                            if (expanded) {
                                this.fetchAllAggregatesOrders({
                                    aggregateId: aggregate.clientAggregateId
                                });
                            } else {
                                this.setState({
                                    aggregatesOrders: []
                                });
                            }
                        }}
                        pagination={pagination}
                        rowKey={aggregate => aggregate.clientAggregateId}
                    />
                </div>
            </React.Fragment>
        );
    }
}
