import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

export default props => {
    return (
        <CloseOutlined
            onClick={props.onClick}
            style={{
                fontSize: 24,
                cursor: 'pointer'
            }}
        />
    );
};
