/* Constants */
import { SET_TRACKING_FILTERS } from 'core/storage/tracking';
import { fetchBrands } from 'pages/DirectoryBrandsPage/redux/duck';

export const moduleName = 'brandsModal';
const prefix = `cpb/${moduleName}`;

export const CREATE_BRAND = `${prefix}/CREATE_BRAND`;
export const CREATE_BRAND_SUCCESS = `${prefix}/CREATE_BRAND_SUCCESS`;

export const FETCH_BRAND = `${prefix}/FETCH_BRAND`;
export const FETCH_BRAND_SUCCESS = `${prefix}/FETCH_BRAND_SUCCESS`;

export const UPDATE_BRAND = `${prefix}/UPDATE_BRAND`;
export const UPDATE_BRAND_SUCCESS = `${prefix}/UPDATE_BRAND_SUCCESS`;

export const SET_BRAND_FIELDS = `${prefix}/SET_BRAND_FIELDS`;

export const modes = Object.freeze({
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW'
});

/* Reducer */
const ReducerState = {
    fields: {
        brandId: undefined,
        brandName: undefined,
        tecdocSupplierId: undefined
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_BRAND_FIELDS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...payload
                }
            };
        default:
            return state;
    }
}
//
/* Selectors */
export const selectBrandField = state => state[moduleName].fields;

/* Actions */

export const createBrand = () => ({
    type: CREATE_BRAND
});

export const createBrandSuccess = () => {
    return function (dispatch) {
        dispatch({
            type: CREATE_BRAND_SUCCESS
        });

        return dispatch(fetchBrands());
    };
};

export const updateBrand = () => ({
    type: UPDATE_BRAND
});

export const updateBrandSuccess = () => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_BRAND_SUCCESS
        });

        return dispatch(fetchBrands());
    };
};

export const fetchBrand = brandId => ({
    type: FETCH_BRAND,
    payload: brandId
});

export const fetchBrandSuccess = fields => {
    return function (dispatch) {
        dispatch({
            type: FETCH_BRAND_SUCCESS
        });

        return dispatch(setBrandFields(fields));
    };
};

export const setBrandFields = fields => ({
    type: SET_BRAND_FIELDS,
    payload: fields
});
