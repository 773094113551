import { SaveOutlined } from '@ant-design/icons';
import { Button, DatePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
    fetchVehicle,
    selectClient,
    selectGeneralData,
    selectVehicle,
    selectVehicleData,
    setExpandedVehicleId
} from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, numeralFormatter, numeralParser } from 'utils';
import VehicleOdometrTable from './VehicleOdometrTable';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    vehicle: selectVehicle(state),
    vehicleData: selectVehicleData(state),
    client: selectClient(state),
    generalData: selectGeneralData(state)
});

const mapDispatchToProps = {
    fetchVehicle,
    setExpandedVehicleId
};

const dateFormat = 'YYYY-MM-DD';

/**
 * Supposed to show all the orders made for current vehicle.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleOdometrTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            odometrDate: undefined,
            odometrNumber: undefined
        };
    }

    render() {
        const { disabled} = this.props;
        const { odometrNumber, odometrDate } = this.state;

        return (
            <React.Fragment>
                <div className={Styles.inputBlockStyle}>
                    <div className={Styles.inputMargin}>
                        <div
                            style={{
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='vehicle_page.current_mileage' />
                        </div>
                        <div>
                            <InputNumber
                            disabled={disabled}
                                formatter={value => numeralFormatter(value)}
                                min={1}
                                onChange={odometrNumber => {
                                    this.setState({
                                        odometrNumber
                                    });
                                }}
                                parser={value => numeralParser(value)}
                                style={{
                                    width: 112
                                }}
                                value={
                                    Math.round(odometrNumber * 100) / 100 ||
                                    _.get(this.props.vehicleData, 'currentMileage')
                                }
                            />
                        </div>
                        {/* <Input
                            onChange={event => {
                                this.setState({
                                    odometrNumber: event.target.value
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'client_order_tab.odometer'
                            })}
                            style={{
                                maxWidth: 200
                            }}
                            value={odometrNumber}
                        /> */}
                    </div>
                    <div className={Styles.inputMargin}>
                        <div
                            style={{
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='date' />
                        </div>
                        <div>
                            <DatePicker
                            disabled={disabled}
                                allowClear={false}
                                format={dateFormat}
                                onChange={odometrDate =>
                                    this.setState({
                                        odometrDate
                                    })
                                }
                                style={{
                                    marginRight: 8
                                }}
                                value={odometrDate}
                                width={100}
                            />
                            <Button
                                disabled={!odometrDate || disabled}
                                icon={<SaveOutlined />}
                                onClick={async () => {
                                    await fetchAPI(
                                        'POST',
                                        '/mileage/history',
                                        null,
                                        {
                                            vehicleId: this.props.vehicleId,
                                            mileage: odometrNumber || 1,
                                            date: dayjs(odometrDate).format('YYYY-MM-DD') || undefined,
                                            custom: true
                                        },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    this.props.fetchVehicleOrdersUpdate();
                                }}
                            />
                        </div>
                    </div>
                </div>

                <VehicleOdometrTable
                    fetchVehicle={fetchVehicle}
                    fetchVehicleOrdersUpdate={this.props.fetchVehicleOrdersUpdate}
                    vehicleId={this.props.vehicleId}
                    disabled={disabled}
                />
            </React.Fragment>
        );
    }
}
