import { DeleteOutlined } from '@ant-design/icons';
import { Button, Flex, InputNumber, Spin, Table, notification } from 'antd';
import { get } from 'lodash';
import { CommentaryButton } from 'modals';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PistonIcon, WrenchIcon } from 'theme';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { fetchAPI } from 'utils';
import { Sphere } from './Sphere';
import './Tab3D.css';
import path from './models/car.glb';

const Tab3D = ({
    open,
    setOrderData,
    resetModal,
    orderId,
    reloadOrderForm,
    toOrderData,
    setToOrderData,
    setUnitComment,
    setUnitCommentPlaces,
    defaultEmployeeId,
    isActive,
    user,
    intl: { formatMessage }
}) => {
    const [systems, setSystems] = useState([]);
    const [units, setUnits] = useState([]);
    const [xx, setX] = useState(0);
    const [yy, setY] = useState(0);
    const [zz, setZ] = useState(0);
    const [loading, setLoading] = useState(true);
    const mountRef = useRef(null);
    const modalRef = useRef(null);
    const cameraRef = useRef(null);
    const animationId = useRef(null);
    let model;

    // Add state variables for the tooltip
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const systems = await fetchAPI('GET', '/graphic_systems', { twoDimensional: false, id: 5 }, null, {
                handleErrorInternally: true
            });
            const units = get(systems, '[0].units', []);
            setSystems(systems);
            setUnits(units);
        };
        if (open && !systems.length) {
            fetch();
        }
    }, [open, systems]);

    const addUnit = unit => {
        setUnitComment(unit.unitComment);
        setUnitCommentPlaces(unit.commentPointers);
        setOrderData({
            storeGroups: unit.storeGroups,
            labors: unit.labors
        });
    };

    const columns = useMemo(
        () => [
            {
                key: 'icon',
                render: record => (record.laborId ? <WrenchIcon /> : <PistonIcon />)
            },
            {
                title: <FormattedMessage id='storage_journal.name' />,
                key: 'name',
                render: record => (
                    <Flex vertical>
                        <div>{record.storeGroupName || record.laborName}</div>
                    </Flex>
                )
            },
            {
                key: 'comment',
                render: record => {
                    let name = record.storeGroupName || record.laborName;
                    if (name && name.indexOf(' - ') > -1) {
                        name = name.slice(0, name.indexOf(' - '));
                    }

                    return (
                        <CommentaryButton
                            commentary={
                                record.comment || {
                                    comment: undefined,
                                    positions: [],
                                    problems: []
                                }
                            }
                            detail={name}
                            setComment={(comment, positions, problems) => {
                                if (record.laborId) {
                                    setToOrderData(prev => ({
                                        labors: prev.labors.map(lbr =>
                                            record.laborId === lbr.laborId
                                                ? {
                                                      ...lbr,
                                                      laborName: comment || record.laborName,
                                                      comment: {
                                                          comment,
                                                          positions,
                                                          problems
                                                      }
                                                  }
                                                : lbr),
                                        storeGroups: prev.storeGroups
                                    }));
                                } else {
                                    setToOrderData(prev => ({
                                        labors: prev.labors,
                                        storeGroups: prev.storeGroups.map(grp =>
                                            record.storeGroupId === grp.storeGroupId
                                                ? {
                                                      ...grp,
                                                      storeGroupName: comment || record.storeGroupName,
                                                      comment: {
                                                          comment,
                                                          positions,
                                                          problems
                                                      }
                                                  }
                                                : grp)
                                    }));
                                }
                            }}
                        />
                    );
                }
            },
            {
                key: 'delete',
                render: record => (
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            if (record.laborId) {
                                setToOrderData(prev => ({
                                    labors: prev.labors.filter(({ laborId }) => laborId !== record.laborId),
                                    storeGroups: prev.storeGroups
                                }));
                            } else {
                                setToOrderData(prev => ({
                                    labors: prev.labors,
                                    storeGroups: prev.storeGroups.filter(
                                        ({ storeGroupId }) => storeGroupId !== record.storeGroupId
                                    )
                                }));
                            }
                        }}
                    />
                )
            }
        ],
        []
    );

    const handleCloseToOrderModal = useCallback(() => {
        setOrderData(undefined);
    }, []);

    const handleAddToOrder = useCallback(() => {
        const fetch = async () => {
            const payload = {
                insertMode: true,
                services: toOrderData.labors.map(
                    ({ laborId, laborName, laborHour, laborPrice, measureUnitId, comment }) => ({
                        serviceId: laborId,
                        serviceName: laborName,
                        count: laborHour,
                        servicePrice: laborPrice,
                        laborUnitId: measureUnitId,
                        employeeId: defaultEmployeeId,
                        comment
                    })
                ),
                details: toOrderData.storeGroups.map(({ storeGroupId, storeGroupName, measureUnitId, comment }) => ({
                    storeGroupId,
                    name: storeGroupName,
                    partUnitId: measureUnitId,
                    comment
                }))
            };

            if (!payload.services.length) {
                delete payload.services;
            }
            if (!payload.details.length) {
                delete payload.details;
            }

            await fetchAPI('PUT', `orders/${orderId}`, null, payload);
            notification.success({
                message: formatMessage({
                    id: 'barcode.success'
                })
            });
            resetModal();
            reloadOrderForm();
        };
        fetch();
        setToOrderData({ labors: [], storeGroups: [] });
        handleCloseToOrderModal();
    }, [handleCloseToOrderModal, toOrderData, orderId, formatMessage, resetModal, reloadOrderForm]);

    useEffect(() => {
        if (!open || !units.length) return;

        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;
        let intersects = [];
        const hovered = {};

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        cameraRef.current = camera;
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(width, height);
        renderer.setClearColor(0xffffff);
        mountRef.current.appendChild(renderer.domElement);

        // Add raycaster and mouse vector
        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        // Add lights
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffeedd, 1);
        directionalLight.position.set(9, 10, 10.5);
        scene.add(directionalLight);

        const directionalLight1 = new THREE.DirectionalLight(0xffeedd, 1);
        directionalLight1.position.set(-9, 10, 10.5);
        scene.add(directionalLight1);

        const directionalLight2 = new THREE.DirectionalLight(0xffeedd, 1);
        directionalLight2.position.set(0, 15, -13.5);
        scene.add(directionalLight2);

        const hemisphereLight = new THREE.HemisphereLight(0xddeeff, 0x202020, 1);
        scene.add(hemisphereLight);

        // Load model
        const loader = new GLTFLoader();

        loader.load(
            path,
            gltf => {
                model = gltf.scene;
                model.traverse(child => {
                    if (child.isMesh) {
                        child.material.metalness = 0.2;
                        child.material.roughness = 0.5;
                    }
                });

                scene.add(model);
                setLoading(false);
            },
            undefined,
            error => {
                console.error(error);
                setLoading(false);
            }
        );

        // Set camera position
        camera.position.set(0, 3, -7);

        // Add controls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableZoom = true;
        controls.enablePan = false;

        // Add spheres for units
        units.forEach(unit => {
            const sphere = new Sphere(unit, addUnit, { radius: 0.13 }, renderer, user.palette);
            sphere.position.set(unit.plusX, unit.plusY, unit.plusZ);
            sphere.userData.unit = unit; // Store unit data

            // Define onPointerOver and onPointerOut methods
            sphere.onPointerOver = hit => {
                // Change sphere appearance on hover (e.g., scale up)
                sphere.scale.set(1.2, 1.2, 1.2);
            };

            sphere.onPointerOut = hit => {
                // Reset sphere appearance when not hovered
                sphere.scale.set(1, 1, 1);
            };

            scene.add(sphere);
        });

        // Animation loop
        const animate = () => {
            animationId.current = requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };
        animate();

        // Handle resize
        const handleResize = () => {
            const width = mountRef.current.clientWidth;
            const height = mountRef.current.clientHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleResize);

        // Handle pointer move
        const handlePointerMove = e => {
            const rendererRect = renderer.domElement.getBoundingClientRect();
            const mouseX = e.clientX - rendererRect.left;
            const mouseY = e.clientY - rendererRect.top;
            mouse.set((mouseX / rendererRect.width) * 2 - 1, -(mouseY / rendererRect.height) * 2 + 1);
            raycaster.setFromCamera(mouse, camera);
            intersects = raycaster.intersectObjects(scene.children, true);

            // Hovering logic
            Object.keys(hovered).forEach(key => {
                const hit = intersects.find(hit => hit.object.uuid === key);
                if (hit === undefined) {
                    const hoveredItem = hovered[key];
                    if (hoveredItem.object.onPointerOut) hoveredItem.object.onPointerOut(hoveredItem);
                    delete hovered[key];
                }
            });

            [intersects[0]].forEach(hit => {
                if (hit) {
                    if (!hovered[hit.object.uuid]) {
                        hovered[hit.object.uuid] = hit;
                        if (hit.object.onPointerOver) hit.object.onPointerOver(hit);
                    }
                    // Call onPointerMove
                    if (hit.object.onPointerMove) hit.object.onPointerMove(hit);

                    if (hit.object.userData.unit) {
                        const unit = hit.object.userData.unit;
                        setTooltipContent(unit.name);
                        setTooltipPosition({ x: mouseX, y: mouseY });
                        setShowTooltip(true);
                    } else {
                        setShowTooltip(false);
                    }
                } else {
                    setShowTooltip(false);
                }
            });
        };

        // Handle click
        const handleClick = e => {
            if (intersects.length > 0) {
                const hit = intersects[0];
                if (hit.object.onClick) hit.object.onClick(hit);
            }
        };

        renderer.domElement.addEventListener('pointermove', handlePointerMove);
        renderer.domElement.addEventListener('click', handleClick);

        // Cleanup
        return () => {
            if (mountRef.current) {
                mountRef.current.removeChild(renderer.domElement);
            }
            window.removeEventListener('resize', handleResize);
            renderer.domElement.removeEventListener('pointermove', handlePointerMove);
            renderer.domElement.removeEventListener('click', handleClick);
            cancelAnimationFrame(animationId.current);
            setLoading(true);
        };
    }, [open, units, xx, yy, zz]);

    return (
        <Flex>
            <div style={{ display: 'none' }}>
                <InputNumber decimalSeparator=',' onChange={value => setX(value)} value={xx} />
                <InputNumber decimalSeparator=',' onChange={value => setY(value)} value={yy} />
                <InputNumber decimalSeparator=',' onChange={value => setZ(value)} value={zz} />
            </div>

            <div ref={modalRef} style={{ width: '100%', minWidth: 1080, height: 800, position: 'relative' }}>
                {loading && (
                    <div className='loading-indicator'>
                        <Spin size='large' />
                    </div>
                )}
                <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
                {showTooltip && (
                    <div
                        style={{
                            position: 'absolute',
                            left: tooltipPosition.x,
                            top: tooltipPosition.y,
                            pointerEvents: 'none',
                            backgroundColor: 'rgba(0,0,0,0.7)',
                            color: 'white',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            transform: 'translate(-50%, -100%)'
                        }}
                    >
                        {tooltipContent}
                    </div>
                )}
            </div>
            <div
                style={{
                    background: 'white',
                    minWidth: 360,
                    maxWidth: 660
                }}
            >
                <Table
                    bordered
                    columns={columns}
                    dataSource={[...get(toOrderData, 'labors', []), ...get(toOrderData, 'storeGroups', [])]}
                    footer={() => (
                        <Flex>
                            <Button
                                block
                                disabled={
                                    ![...get(toOrderData, 'labors', []), ...get(toOrderData, 'storeGroups', [])].length
                                }
                                onClick={handleAddToOrder}
                                type='primary'
                            >
                                <FormattedMessage id='ok' />
                            </Button>
                        </Flex>
                    )}
                    pagination={false}
                    rowKey={record => record.laborId || record.storeGroupId}
                    size='small'
                />
            </div>
        </Flex>
    );
};

export default injectIntl(Tab3D);
