import { Select } from 'antd';
import { OrdersStatusesMapper } from 'commons';
import _ from 'lodash';
import { ORDER_TYPES } from 'modals/DebtZeroingModal/constants';
import {
    selectSelectedOrderStatus,
    setSelectedOrderStatus
} from 'modals/DebtZeroingModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    selectedOrderStatus: selectSelectedOrderStatus(state)
});

const mapDispatchToProps = {
    setSelectedOrderStatus
};

/**
 * This component serves selecting cash order type
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrderStatusSelector extends React.Component {
    /**
     * When order status is changed
     * @param {*} type - new order status
     */
    onSelectedNewOrderStatus = type => {
        const { setSelectedOrderStatus } = this.props;

        setSelectedOrderStatus(type);
    };

    render() {
        const {
            selectedOrderStatus,

            intl: { formatMessage }
        } = this.props;

        return (
            <div title={formatMessage({ id: 'debt_zeroing_modal.hint_order_status' })}>
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={this.onSelectedNewOrderStatus}
                    value={selectedOrderStatus}
                >
                    {_.map(ORDER_TYPES, type => {
                        return (
                            <Option key={type} value={type}>
                                <OrdersStatusesMapper status={type} />
                            </Option>
                        );
                    })}
                </Select>
            </div>
        );
    }
}
