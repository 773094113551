import { CaretDownOutlined, CaretUpOutlined, DollarOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Tag, Tooltip } from 'antd';
import { DebtsPairComponent, FormattedDatetime } from 'components';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

export default function columnsConfig(props) {
    const { formatMessage, user } = props;
    const pairCol = {
        title: () => (
            <Button
                disabled
                // disabled={isForbidden(user, permissions.UPDATE_SUCCESS_ORDER)}
                onClick={() => {
                    props.unpairAllActionsBtns(true);
                }}
                type='primary'
            >
                <FormattedMessage id='navigation.unpair_all_button' />
            </Button>
        ),
        key: 'pairCol',
        width: '3%',
        render: row => (
            <div
                style={{
                    display: 'flex'
                }}
            >
                <DebtsPairComponent
                    createPair={props.createPair}
                    disabled={
                        !isGrantAccessed(
                            user,
                            grants.DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING,
                            accesses.ROWO
                        )
                    }
                    id={row.rowId}
                    onPair={props.onPair}
                    onUnpair={props.onUnpair}
                    pairSelectedIds={props.pairSelectedIds}
                    pairSign={props.pairSign}
                    remainSum={row.remaining}
                    singleType={props.singleType}
                    {...row}
                />
                <Tooltip title={<FormattedMessage id='navigation.paired_documents' />}>
                    <Button
                        icon={<InfoOutlined />}
                        onClick={() => {
                            props.showDebtPairResultsModal(row);
                        }}
                        size='large'
                        style={{
                            marginLeft: 8
                        }}
                    />
                </Tooltip>
            </div>
        )
    };

    const documentCol = {
        title: <FormattedMessage id='storage_document.document' />,
        key: 'documentNum',
        dataIndex: 'documentNum',
        render: (documentNum, data) => {
            return data.orderId ? (
                <Link style={{ color: 'var(--link)', fontWeight: 'bold' }} to={`${book.order}/${data.orderId}`}>
                    {documentNum}
                </Link>
            ) : data.storeDocId && isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT) ? (
                <Link
                    style={{ color: 'var(--link)', fontWeight: 'bold' }}
                    to={`${book.storageDocument}/${data.storeDocId}`}
                >
                    {documentNum}
                </Link>
            ) : data.storeDocId ? (
                documentNum
            ) : (
                <div>
                    <Link
                        style={{ color: 'var(--link)', fontWeight: 'bold' }}
                        to={{
                            pathname: book.cashFlowPage,
                            state: { cashOrderId: documentNum }
                        }}
                    >
                        {documentNum}
                    </Link>
                </div>
            );
        }
    };

    const documentTypeCol = {
        title: <FormattedMessage id='client_container.document_type' />,
        key: 'documentType',
        width: 150,
        align: 'center',
        dataIndex: 'documentType',
        ...props.getColumnSearchProps('filterDocumentType'),
        render: (documentType, data) => {
            return data.orderId ? (
                <Tooltip
                    title={
                        <div>
                            {documentType} - <FormattedMessage id={`storage_document.docType.${documentType}`} />
                        </div>
                    }
                >
                    <div>{documentType}</div>
                </Tooltip>
            ) : data.storeDocId ? (
                <Tooltip
                    title={
                        <div>
                            {documentType} - <FormattedMessage id={`storage_document.docType.${documentType}`} />
                        </div>
                    }
                >
                    <div>{documentType}</div>
                </Tooltip>
            ) : (
                <div>
                    <Tooltip
                        title={
                            <div>
                                {documentType} - <FormattedMessage id={`storage_document.docType.${documentType}`} />
                            </div>
                        }
                    >
                        <div>{documentType}</div>
                    </Tooltip>
                </div>
            );
        }
    };

    const businessRequisite = {
        title: <FormattedMessage id='storage_document.business_requisites' />,
        key: 'businessRequisiteName',
        width: 150,
        align: 'center',
        ...props.getColumnSearchProps('filterBusinessRequisiteId'),
        render: (key, data) => {
            const businessRequisiteName = _.get(data, 'businessRequisiteName');

            return <div>{businessRequisiteName}</div>;
        }
    };

    const counterpartRequisite = {
        title: <FormattedMessage id='storage.business_supplier_requisites' />,
        key: 'businessSupplierRequisiteName',
        width: 150,
        align: 'center',
        ...props.getColumnSearchProps('filterCounterpartRequisiteId'),
        render: (key, data) => {
            const businessSupplierRequisiteName = _.get(data, 'businessSupplierRequisiteName');

            return <div>{businessSupplierRequisiteName}</div>;
        }
    };

    const operationType = {
        title: <FormattedMessage id='storage_journal.operation_type' />,
        key: 'sign',
        width: 150,
        align: 'center',
        ...props.getColumnSearchProps('filterSign'),
        render: (key, data) => {
            const sign = _.get(data, 'sign');

            return (
                <div>
                    <Tag color={sign === '+' ? 'var(--green)' : 'var(--warning)'}>
                        {formatMessage({
                            id: `storage.${sign === '+' ? 'sign_+' : 'sign_-'}`
                        })}
                    </Tag>
                </div>
            );
        }
    };

    const dateTimeCol = {
        title: <FormattedMessage id='client-mrds-table.date' />,
        key: 'dateTime',
        dataIndex: 'dateTime',
        ...props.getColumnSearchProps('datetimeFrom'),
        render: date => <FormattedDatetime datetime={date} />
    };

    const sumCol = {
        title: <FormattedMessage id='client-mrds-table.amount' />,
        key: 'sum',
        align: 'right',
        render: ({ sum, sign }) => {
            const strVal = Number(sum).toFixed(2);

            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    {sign === '+' ? (
                        <CaretUpOutlined style={{ color: 'var(--enabled)' }} />
                    ) : (
                        <CaretDownOutlined style={{ color: 'var(--disabled)' }} />
                    )}

                    <div>
                        {sign}
                        {sum ? `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}
                    </div>
                </div>
            );
        }
    };

    const oweAmountCol = {
        title: <FormattedMessage id='client-mrds-table.due_amount' />,
        key: 'remaining',
        dataIndex: 'remaining',
        align: 'right',
        render: (oweAmount, row) => {
            const sign = _.get(row, 'sign');

            const strVal = Number(oweAmount).toFixed(2);

            const split = String(row.documentNum).split('-');
            const orderId = row.documentNum ? split[2] : null;

            return (
                <React.Fragment>
                    {Boolean(oweAmount) &&
                        oweAmount > 0 &&
                        isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAYMENT) && (
                            <DollarOutlined
                                className='dollar-icon'
                                onClick={() => {
                                    props.showCashOrderModal({ ...row, orderId });
                                }}
                            />
                        )}
                    {oweAmount > 0 ? sign : undefined}
                    <span>{oweAmount ? `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}</span>
                </React.Fragment>
            );
        }
    };

    return [
        pairCol,
        documentCol,
        businessRequisite,
        counterpartRequisite,
        documentTypeCol,
        operationType,
        dateTimeCol,
        sumCol,
        oweAmountCol
    ];
}
