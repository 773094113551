import { DeleteOutlined } from '@ant-design/icons';
import { Button, Flex, Input, InputNumber, Modal, Select, Table, notification } from 'antd';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, numeralFormatter, numeralParser } from 'utils';

const { Option } = Select;
const { confirm } = Modal;
const format = 'HH:mm';

const newRowTemplate = {
    lowerLimit: 0,
    markup: 1.5
};

const ScheduleModal = ({
    closeModal,
    intl: { formatMessage },
    open,
    fetchMarkups,
    markupsGroups,
    edit,
    fetchMarkupsGroups
}) => {
    const [dataSource, setDataSource] = useState([]);
    const [group, setGroup] = useState();
    const [name, setName] = useState('');
    const [lowerLimit, setLowerLimit] = useState('');
    const [using, setUsing] = useState(undefined);
    const [addResponse, setResponse] = useState(undefined);
    const [addResponseDelete, setDeleteResponse] = useState(undefined);

    useEffect(() => {
        if (edit) {
            setGroup(edit.id);
            setDataSource(edit.markups);
            setName(edit.name);
        }
        if (_.get(addResponse, 'error', undefined)) {
            notification.error({
                message: formatMessage({
                    id: 'storage.markup.already_exist'
                })
            });
            setResponse(undefined);
        }
    }, [edit, addResponse]);

    // const normalizePatterns = useCallback(() => {
    //     const filler = new Array(5).fill(0);
    //     const mapRes = filler.map((_, index) => {
    //         const ds = dataSource[index];

    //         return [
    //             `pattern${index + 1}`,
    //             ds
    //                 ? {
    //                       dayOff: ds.dayOff,
    //                       countOfDay: ds.countOfDay,
    //                       ...(ds.dayOff
    //                           ? { startTime: null, endTime: null }
    //                           : { startTime: ds.startTime.format(format), endTime: ds.endTime.format(format) })
    //                   }
    //                 : null
    //         ];
    //     });

    //     return Object.fromEntries(mapRes);
    // }, [dataSource]);

    const postMarkup = async body => {
        const response = await fetchAPI('POST', '/markups', null, body, {
            handleErrorInternally: true
        });
        setResponse(response);
    };

    const editMarkup = async (body, id) => {
        await fetchAPI('PUT', 'markups', null, body, {
            handleErrorInternally: true
        });
    };

    const editMarkupName = async (body, id) => {
        await fetchAPI('PUT', `markups_group/${id}`, null, body, {
            handleErrorInternally: true
        });
    };

    const columns = useMemo(
        () => [
            { title: '№', render: (data, row, index) => index + 1 },
            {
                title: formatMessage({ id: 'storage.purchase_price_from' }),
                render: (data, row, i) => (
                    <InputNumber
                        min={0}
                        onChange={value => {
                            setDataSource(prev =>
                                prev.map((record, index) => (index === i ? { ...record, lowerLimit: value } : record))
                            );
                        }}
                        onStep={{}}
                        precision={0}
                        value={data.lowerLimit}
                    />
                )
            },
            {
                title: formatMessage({ id: 'storage.markup' }),
                render: (data, row, i) => (
                    <InputNumber
                        decimalSeparator=','
                        formatter={value => numeralFormatter(value)}
                        min={1}
                        onChange={value => {
                            setDataSource(prev =>
                                prev.map((record, index) => (index === i ? { ...record, markup: value } : record)));
                        }}
                        onStep={{}}
                        parser={value => numeralParser(value)}
                        step={0.1}
                        value={data.markup}
                    />
                )
            },
            {
                title: formatMessage({ id: 'delete' }),
                render: (row, data, i) => (
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => setDataSource(dataSource.filter((record, index) => index !== i))}
                        type='text'
                    />
                )
            }
        ],
        [formatMessage, dataSource]
    );

    // const okDisabled =
    //     dataSource.map(record => record.dayOff || (!!record.lowerLimit && !!record.markup)).includes(false) ||
    //     !dataSource.length;

    const onCloseModal = async () => {
        setDataSource([]);
        setGroup(undefined);
        setName(undefined);
        setUsing(undefined);
        setResponse(undefined);
        await closeModal();
    };

    const handleOk = async () => {
        const scheme = dataSource.map(el => ({
            lowerLimit: el.lowerLimit || 0,
            markup: el.markup || 1.5
        }));
        const editScheme = dataSource.map(el => ({
            lowerLimit: el.lowerLimit || 0,
            markup: el.markup || 1.5,
            id: el.id || undefined,
            groupId: edit ? edit.id : undefined
        }));
        const body = {
            name,
            using,
            groupId: group,
            markups: scheme
        };

        const newName = {
            name
        };

        const editBody = {
            markups: editScheme
        };

        if (!edit) {
            postMarkup(body);
        } else {
            editMarkup(editBody);
            setTimeout(() => editMarkupName(newName, edit.id), 200);
        }

        setTimeout(() => {
            fetchMarkups();
            fetchMarkupsGroups();
        }, 200);
        await onCloseModal();
    };

    const modalFooter = (
        <div>
            <Button onClick={onCloseModal}>
                <FormattedMessage id='cancel' />
            </Button>
            <Button
                disabled={
                    edit ? !_.get(dataSource, 'length') || !name : !name || !using || !_.get(dataSource, 'length')
                }
                onClick={handleOk}
                type='primary'
            >
                <FormattedMessage id='ok' />
            </Button>
        </div>
    );

    return (
        <Modal
            footer={modalFooter}
            maskClosable={false}
            onCancel={onCloseModal}
            open={open}
            title={
                !edit ? (
                    <FormattedMessage id='product_markups.add_markup' />
                ) : (
                    <FormattedMessage id='product_markups.edit_markup' />
                )
            }
            width='50%'
        >
            <div>
                {!edit && (
                    /* <div
                    style={{
                        padding: 8,
                        fontWeight: 700,
                        fontSize: 18
                    }}
                >
                    <FormattedMessage id='product_markups.add_markup_group' />
                </div> */
                    <Flex
                        flex
                        justify='space-around'
                        style={{
                            borderBottom: 'solid 1px lightgrey',
                            marginBottom: 14
                        }}
                    >
                        <div
                            style={{
                                marginRight: 12
                            }}
                        >
                            <FormattedMessage id='description' />
                        </div>
                        <div style={{ width: 240, marginRight: 8 }}>
                            <Input
                                onChange={event => {
                                    setName(event.target.value);
                                }}
                                placeholder={formatMessage({ id: 'description' })}
                                style={{
                                    marginBottom: 8
                                }}
                                value={name}
                            />
                        </div>
                        <div
                            style={{
                                marginRight: 12
                            }}
                        >
                            <FormattedMessage id='product.purpose' />
                        </div>
                        <div style={{ width: 240 }}>
                            <Select
                                onSelect={value => setUsing(value)}
                                placeholder={formatMessage({
                                    id: 'product.purpose'
                                })}
                                value={using}
                            >
                                <Option key='GENERAL' value='GENERAL'>
                                    <FormattedMessage id='product_markups.GENERAL' />
                                </Option>
                                <Option key='PRODUCT_GROUP' value='PRODUCT_GROUP'>
                                    <FormattedMessage id='product_markups.PRODUCT_GROUP' />
                                </Option>
                                <Option key='CUSTOMER_GROUP' value='CUSTOMER_GROUP'>
                                    <FormattedMessage id='product_markups.CUSTOMER_GROUP' />
                                </Option>
                            </Select>
                        </div>
                    </Flex>
                )}
                {edit && (
                    <Flex flex>
                        <div
                            style={{
                                marginRight: 12
                            }}
                        >
                            <FormattedMessage id='description' />
                        </div>
                        <div>
                            <Input
                                onChange={event => {
                                    setName(event.target.value);
                                }}
                                placeholder={formatMessage({ id: 'description' })}
                                style={{
                                    marginBottom: 8
                                }}
                                value={name}
                            />
                        </div>
                    </Flex>
                )}
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    footer={() => (
                        <Button
                            onClick={() =>
                                setDataSource(prev =>
                                    prev.length < 25
                                        ? prev.concat({
                                              ...structuredClone(newRowTemplate)
                                          })
                                        : prev)
                            }
                            type='primary'
                        >
                            <FormattedMessage id='regulations.add_row' />
                        </Button>
                    )}
                    pagination={false}
                    rowKey='id'
                    size='small'
                    style={{
                        marginTop: 12
                    }}
                />
            </div>
        </Modal>
    );
};

export default injectIntl(ScheduleModal);
