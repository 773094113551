import { Form } from '@ant-design/compatible';
import { List } from 'antd';
import {
    onChangeSwitchBusinessForm,
    setBusiness,
    setSearchQuery,
    setSearchQueryById
} from 'core/forms/switchBusinessForm/duck';
import { DecoratedInput, DecoratedInputNumber } from 'forms/DecoratedFields';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withReduxForm } from 'utils';
import Styles from './styles.m.css';

@injectIntl
@withReduxForm({
    name: 'switchBusinessForm',
    actions: {
        change: onChangeSwitchBusinessForm,
        setSearchQuery,
        setSearchQueryById,
        setBusiness
    }
})
export class SwitchBusinessForm extends Component {
    constructor(props) {
        super(props);
        this.searchInputRef = React.createRef();
    }

    componentDidMount() {
        if (this.searchInputRef) this.searchInputRef.current.focus();
    }

    componentDidUpdate(prevProps) {
        const { searchById } = this.props;
        if (prevProps.searchById !== this.props.searchById) {
            this.props.form.resetFields();
        }
    }

    _handleClick = business => {
        this.props.setBusiness(business.businessId);
        this.props.form.resetFields();
        this.props.resetModal();
    };

    _handleEnter = () => {
        if (this.props.businesses.length === 1) {
            this.props.setBusiness(this.props.businesses[0].businessId);
            this.props.form.resetFields();
            this.props.resetModal();
        }
    };

    render() {
        const {
            setBusiness,
            setSearchQuery,
            setSearchQueryById,
            businesses,
            form: { getFieldDecorator },
            intl: { formatMessage },
            loading,
            searchById
        } = this.props;

        return (
            <Form layout='vertical'>
                {searchById ? (
                    <DecoratedInputNumber
                        ref={this.searchInputRef}
                        field='searchById'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        onChange={value => setSearchQueryById(value)}
                        onPressEnter={() => this._handleEnter()}
                        placeholder={formatMessage({ id: 'search_business' })}
                        style={{
                            width: '100%'
                        }}
                    />
                ) : (
                    <DecoratedInput
                        ref={this.searchInputRef}
                        field='searchQuery'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        onChange={event => setSearchQuery(event.target.value)}
                        onPressEnter={() => this._handleEnter()}
                        placeholder={formatMessage({ id: 'search_business' })}
                    />
                )}
                <List
                    bordered
                    className={Styles.switchBusinessList}
                    dataSource={businesses}
                    loading={loading}
                    locale={{ emptyText: formatMessage({ id: 'no_data' }) }}
                    renderItem={business => (
                        <List.Item onClick={() => this._handleClick(business)}>
                            <List.Item.Meta
                                className={Styles.switchBusinessListItem}
                                description={business.address}
                                title={`${business.name} (#${business.businessId})`}
                            />
                        </List.Item>
                    )}
                />
            </Form>
        );
    }
}
