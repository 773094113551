import { Form } from '@ant-design/compatible';
import { Checkbox, Col, Input, InputNumber, Modal, Row, Select } from 'antd';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, orderTypesForRules } from 'utils';

import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    brands: selectBrands(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchBrands
};

const { Option } = Select;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AddRuleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            positions: [],
            presets: [],
            marks: [],
            specifications: [],
            discount: false
        };
    }

    componentDidMount() {
        this.getPositions();
        this.getPresets();
    }

    async componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            if (this.props.addRuleModalRow) {
                await this.getSpecifications(this.props.addRuleModalRow.presetId);
                await this.getMarks(this.props.addRuleModalRow.presetId);
                this.setState({
                    ...this.props.addRuleModalRow
                });
            }
        }
    }

    getPositions = async () => {
        const positions = await fetchAPI('GET', 'employee/posts', null, null);
        this.setState({
            positions
        });
    };

    getPresets = async () => {
        const presets = await fetchAPI('GET', 'salary/presets', null, null);
        this.setState({
            presets
        });
    };

    getMarks = async presetId => {
        const marks = await fetchAPI('GET', 'salary/marks', { presetId }, null);
        this.setState({
            marks
        });
    };

    getSpecifications = async presetId => {
        const specifications = await fetchAPI('GET', 'salary/specifications', { presetId }, null);

        this.setState({
            specifications
        });
    };

    onOk = async () => {
        const { addRuleModalRow } = this.props;
        if (addRuleModalRow === undefined) {
            await this.addSalaryRule();
        } else {
            await this.updateSalaryRule();
        }

        this.props.getBussinessRules();

        this.onCancel();
    };

    updateSalaryRule = async () => {
        const { addRuleModalRow } = this.props;
        const { ruleName, postId, orderType, comment, presetId, discount, sum, precent, specificationId, markId } =
            this.state;

        await fetchAPI(
            'PUT',
            `salary/rule/${addRuleModalRow.ruleId}`,
            null,
            {
                ruleName,
                postId,
                orderType: orderType || null,
                comment,
                presetId,
                discount,
                sum,
                precent,
                specificationId: specificationId || null,
                markId
            },
            {
                handleErrorInternally: true
            }
        );
    };

    addSalaryRule = async () => {
        const { ruleName, postId, orderType, comment, presetId, discount, sum, precent, specificationId, markId } =
            this.state;

        await fetchAPI(
            'POST',
            'salary/rule',
            null,
            {
                ruleName,
                postId,
                orderType,
                comment,
                presetId,
                discount,
                sum,
                precent,
                specificationId,
                markId
            },
            {
                handleErrorInternally: true
            }
        );
    };

    onCancel = async () => {
        const { hideModal } = this.props;

        this.setState({
            ruleName: undefined,
            postId: undefined,
            orderType: undefined,
            comment: undefined,
            presetId: undefined,
            discount: undefined,
            sum: undefined,
            precent: undefined,
            specificationId: undefined,
            markId: undefined
        });

        hideModal();
    };

    render() {
        const {
            visible,
            addRuleModalRow,
            intl: { formatMessage },
            isMobile
        } = this.props;

        const {
            ruleName,
            postId,
            orderType,
            positions,
            comment,
            presetId,
            presets,
            specifications,
            marks,
            discount,
            sum,
            precent,
            specificationId,
            markId,
            markName,
            presetName,
            postName,
            specificationName
        } = this.state;

        const disableIfOrderLabors = presetName == 'ORDER_LABORS' && !specificationId;
        const disableIfNoCount = markName !== 'COUNT' && !precent;
        const disableIfCount = markName == 'COUNT' && !sum;

        return (
            <div>
                <Modal
                    okButtonProps={{
                        disabled:
                            !ruleName ||
                            !postId ||
                            !presetId ||
                            !markId ||
                            disableIfOrderLabors ||
                            disableIfNoCount ||
                            disableIfCount
                    }}
                    onCancel={this.onCancel}
                    maskClosable={false}
                    onOk={this.onOk}
                    title={
                        addRuleModalRow ? (
                            <React.Fragment>
                                <FormattedMessage id='employee_salary_rules.edit_rule' />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <FormattedMessage id='employee_salary_rules.add_rule' />
                            </React.Fragment>
                        )
                    }
                    visible={visible}
                >
                    <Form>
                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_salary_rules.rule_name' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input
                                    className={isMobile ? 'selectMobile' : 'select'}
                                    onChange={event => {
                                        this.setState({
                                            ruleName: event.target.value
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'employee_salary_rules.rule_name'
                                    })}
                                    value={ruleName}
                                />
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_salary_rules.position' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    className={isMobile ? 'selectMobile' : 'select'}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    initialValue={postId}
                                    onChange={(value, option) => {
                                        this.setState({
                                            postId: value,
                                            postName: option.postName
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    value={postId}
                                >
                                    {positions.map(({ postId, postName }) => (
                                        <Option key={postId} postName={postName} value={postId}>
                                            <FormattedMessage id={`employee_salary_rules.${postName}`} />
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_salary_rules.basis_of_calculation' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    className={isMobile ? 'selectMobile' : 'select'}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={async (value, option) => {
                                        this.setState({
                                            presetId: value,
                                            presetName: option.presetName,
                                            specificationId: null,
                                            orderType: null,
                                            markId: null,
                                            sum: null,
                                            precent: null
                                        });
                                        await this.getSpecifications(value);
                                        await this.getMarks(value);
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    value={presetId}
                                >
                                    {presets
                                        .filter(
                                            ({ presetName }) =>
                                                postName !== 'MECHANIC' ||
                                                ['ORDER_LABORS', 'ORDER_PARTS', 'LABORS_ORDER'].includes(presetName)
                                        )
                                        .map(({ presetId, presetName }) => (
                                            <Option
                                                key={presetId}
                                                disabled={
                                                    presetName !== 'ORDER_PARTS' &&
                                                    presetName !== 'ORDER_LABORS' &&
                                                    presetName !== 'LABORS_ORDER' &&
                                                    presetName !== 'ORDER_TOTAL' &&
                                                    presetName !== 'PARTS_ORDER'
                                                }
                                                presetName={presetName}
                                                value={presetId}
                                            >
                                                <FormattedMessage id={`employee_salary_rules.${presetName}`} />
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_salary_rules.base_details' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    className={isMobile ? 'selectMobile' : 'select'}
                                    disabled={!presetId}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(value, option) => {
                                        this.setState({
                                            specificationId: value,
                                            specificationName: option.specificationName
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    value={specificationId}
                                >
                                    {specifications
                                        .filter(
                                            ({ presetId, specificationName }) =>
                                                presetId &&
                                                (presetName !== 'ORDER_LABORS' ||
                                                    ['OWN_LABORS', 'SUBCONTRACTING'].includes(specificationName))
                                        )
                                        .map(({ specificationId, specificationName }) => (
                                            <Option
                                                key={specificationId}
                                                disabled={specificationId === null}
                                                specificationName={specificationName}
                                                value={specificationId}
                                            >
                                                {specificationId ? (
                                                    <FormattedMessage
                                                        id={`employee_salary_rules.${specificationName}`}
                                                    />
                                                ) : undefined}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='storage_funds.order_type' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    className={isMobile ? 'selectMobile' : 'select'}
                                    disabled={presetName === 'ORDER_TOTAL' || presetName === 'ORDER_PARTS'}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    initialValue={orderType}
                                    onChange={(value, option) => {
                                        this.setState({
                                            orderType: value
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    value={orderType}
                                >
                                    {orderTypesForRules.map(type => (
                                        <Option key={`${type}-aggr`} value={type}>
                                            <FormattedMessage id={`aggregate.${type}`} />
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_salary_rules.indicator_for_calculation' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    className={isMobile ? 'selectMobile' : 'select'}
                                    disabled={!presetId}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(value, option) => {
                                        this.setState({
                                            markId: value,
                                            sum: null,
                                            precent: null,
                                            markName: option.markName
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    value={markId}
                                >
                                    {marks
                                        .filter(
                                            ({ markName }) =>
                                                specificationName !== 'SUBCONTRACTING' ||
                                                ['SUM', 'MARGIN'].includes(markName)
                                        )
                                        .map(({ markId, markName }) => (
                                            <Option
                                                key={markId}
                                                disabled={markName == 'INCOME'}
                                                markName={markName}
                                                specificationName={markName}
                                                value={markId}
                                            >
                                                <FormattedMessage id={`employee_salary_rules.${markName}`} />
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='locations.percent' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={sum || markName === 'COUNT'}
                                    formatter={precent => `${precent}%`}
                                    max={100}
                                    min={0}
                                    onChange={precent => {
                                        this.setState({
                                            precent,
                                            sum: null
                                        });
                                    }}
                                    parser={value => value.replace('%', '').replace(',', '.')}
                                    precision={2}
                                    value={precent}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='sum' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={
                                        !markId ||
                                        marks.find(({ markId: mkId }) => mkId === markId).markName !== 'COUNT' ||
                                        precent >= 1
                                    }
                                    min={0}
                                    onChange={sum => {
                                        this.setState({
                                            sum,
                                            precent: null
                                        });
                                    }}
                                    value={sum}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee_salary_rules.take_into_account_the_discount' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={discount}
                                    onChange={() => {
                                        this.setState({ discount: !discount });
                                    }}
                                    style={{
                                        marginTop: 6
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={10}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='comment' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input.TextArea
                                    className={isMobile ? 'selectMobile' : 'select'}
                                    onChange={event => {
                                        this.setState({
                                            comment: event.target.value
                                        });
                                    }}
                                    placeholder={formatMessage({
                                        id: 'comment'
                                    })}
                                    value={comment}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}
