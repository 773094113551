import { Modal } from 'antd';
import { QuickRequestsContainer } from 'containers';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

@injectIntl
export default class AddQuickRequestsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            selectedRowKeys: []
        };
    }

    handleOk = async () => {
        await this.addRegulationRowsToOrder();

        await this.handleCancel();

        setTimeout(() => this.props.fetchVehicleOrderRegulations(), 500);
    };

    handleCancel() {
        this.setState({
            selectedRows: [],
            selectedRowKeys: []
        });
        this.props.hideModal();
    }

    render() {
        return (
            <Modal
                destroyOnClose
                footer={null}
                maskClosable={false}
                onCancel={() => {
                    this.handleCancel();
                }}
                onOk={() => {
                    this.handleOk();
                }}
                title={<FormattedMessage id='navigation.add_quick_requests' />}
                visible={this.props.visible}
                width='fit-content'
                zIndex={200}
            >
                <QuickRequestsContainer
                    defaultEmployeeId={this.props.defaultEmployeeId}
                    hideModal={this.props.hideModal}
                    labors={this.props.labors}
                    orderId={this.props.orderId}
                    updateDataSource={this.props.updateDataSource}
                />
            </Modal>
        );
    }
}
