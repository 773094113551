import { Input } from 'antd';
import { Catcher } from 'commons';
import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isForbidden, permissions } from 'utils';

const { Search } = Input;

const SearchWrapper = styled.div`
    width: ${props => (props.width ? props.width : 'auto')};
`;

const SearchField = props => {
    const handleSearch = _.debounce(value => {
        props.setFilters({ query: value });
        // props.fetchData({ sort: { page: 1 }, ...props.filter });
    }, 1000);

    return (
        <Catcher>
            <SearchWrapper width={props.width}>
                <Search
                    disabled={isForbidden(props.user, permissions.SEARCH_STORE_PRODUCT)}
                    onChange={({ target: { value } }) => handleSearch(value)}
                    placeholder={props.intl.formatMessage({
                        id: 'storage.search'
                    })}
                />
            </SearchWrapper>
        </Catcher>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default injectIntl(connect(mapStateToProps)(SearchField));
