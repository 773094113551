exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__diagnosticTable---mdPEc{overflow:scroll}.styles-m__diagnosticTableDisabledRow---24Da6{color:grey}.styles-m__actionBlock---uCaWM{display:flex}.styles-m__actionBlock---uCaWM>*{margin:4px 8px}.styles-m__statisticsWrapper---39ilO{display:flex;flex-direction:column;gap:8px;padding-bottom:8px}.styles-m__actionButtonAlign---2SH7j{display:flex;justify-content:center}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,mCACI,eACJ,CAEA,8CACI,UACJ,CAEA,+BACI,YAIF,CAHE,iCACE,cACF,CAGJ,qCACE,YAAa,CACb,qBAAsB,CACtB,OAAQ,CACR,kBACF,CAEA,qCACE,YAAa,CACb,sBACF","file":"styles.m.css","sourcesContent":[".diagnosticTable {\n    overflow: scroll;\n}\n\n.diagnosticTableDisabledRow {\n    color: gray;\n}\n\n.actionBlock {\n    display: flex;\n    & > * {\n      margin: 4px 8px;\n    }\n  }\n\n.statisticsWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding-bottom: 8px;\n}\n\n.actionButtonAlign {\n  display: flex;\n  justify-content: center;\n}"]}]);

// Exports
exports.locals = {
	"diagnosticTable": "styles-m__diagnosticTable---mdPEc",
	"diagnosticTableDisabledRow": "styles-m__diagnosticTableDisabledRow---24Da6",
	"actionBlock": "styles-m__actionBlock---uCaWM",
	"statisticsWrapper": "styles-m__statisticsWrapper---39ilO",
	"actionButtonAlign": "styles-m__actionButtonAlign---2SH7j"
};