import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Table } from 'antd';
import { Catcher } from 'commons';
import {
    createPackage,
    deletePackage,
    handleError,
    hideForms,
    setCreatePackage,
    setEditPackageId,
    updatePackage
} from 'core/package/duck';
import { AddPackageForm, PackageForm } from 'forms';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapDispatchToProps = {
    setCreatePackage,
    setEditPackageId,
    createPackage,
    updatePackage,
    deletePackage,
    hideForms,
    handleError
};

const mapStateToProps = state => ({
    editPackageId: state.packages.editPackageId,
    createPackageForm: state.packages.createPackageForm,
    packages: state.packages.packages,
    errors: state.packages.errors,
    roles: state.packages.roles
});

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description
    });
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class PackageContainer extends Component {
    constructor(props) {
        super(props);

        this.apiErrorsMap = {
            REFERENCE_VIOLATION: props.intl.formatMessage({
                id: 'package-container.roles_businesses_restriction'
            }),
            UNIQUE_CONSTRAINT_VIOLATION: props.intl.formatMessage({
                id: 'package-container.unique_name_error'
            })
        };

        this.columns = [
            {
                title: <FormattedMessage id='package-container.index' />,
                dataIndex: 'index',
                width: 'auto',
                render: field => field + 1
            },
            {
                title: <FormattedMessage id='package-container.name' />,
                dataIndex: 'name',
                width: '30%',
                render: (name, record) => record.name
            },
            {
                title: <FormattedMessage id='package-container.roles' />,
                dataIndex: 'roles',
                width: '30%',
                render: field => field.map(({ name }) => name).join(',')
            },
            // {
            //     title:  <FormattedMessage id='package-container.view' />,
            //     width:  '12%',
            //     render: record => (
            //         <Link to={ `${book.packagePage}/${record.id}` }>
            //             <Icon className={ Styles.viewPackageIcon } type='table' />
            //         </Link>
            //     ),
            // },
            {
                // title:  <FormattedMessage id='package-container.edit' />,
                width: '15%',
                render: record => (
                    <EditOutlined
                        className={Styles.editPackageIcon}
                        onClick={() => this.props.setEditPackageId(record.packageId)}
                    />
                )
            },
            {
                // title:  <FormattedMessage id='package-container.delete' />,
                width: '15%',
                render: record => (
                    <DeleteOutlined
                        className={Styles.deletePackageIcon}
                        onClick={() => this.props.deletePackage(record.packageId)}
                    />
                )
            }
        ];
    }

    render() {
        const {
            packages,
            createPackageForm,
            editPackageId,
            updatePackage,
            createPackage,
            errors,
            roles,
            setCreatePackage
        } = this.props;

        if (errors.length) {
            const currentComponentErrors = errors.filter(({ response }) =>
                _.keys(this.apiErrorsMap).includes(_.get(response, 'message'))
            );

            currentComponentErrors.forEach(componentError => {
                const description = this.apiErrorsMap[componentError.response.message];

                openNotificationWithIcon(
                    'error',
                    this.props.intl.formatMessage({
                        id: 'package-container.error'
                    }),
                    description
                );
                this.props.handleError(componentError.id);
            });
        }

        const packageRows = packages.map((packageEntity, index) => ({
            ...packageEntity,
            index,
            key: packageEntity.packageId
        }));

        const initPackage = editPackageId && _.find(packages, { packageId: editPackageId });
        const initPackageName = _.get(initPackage, 'name');

        return (
            <Catcher>
                <Button
                    className={Styles.addPackageButton}
                    onClick={() => setCreatePackage(true)}
                    type='primary'
                >
                    <FormattedMessage id='package-container.create' />
                </Button>
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={packageRows}
                    pagination={{
                        hideOnSinglePage: true,
                        size: 'large'
                    }}
                    size='small'
                />
                <Modal
                    destroyOnClose
                    footer={null}
                    maskClosable={false}
                    onCancel={() => this.props.hideForms()}
                    title={
                        editPackageId ? (
                            <FormattedMessage id='package-container.edit_title' />
                        ) : (
                            <FormattedMessage id='package-container.create_title' />
                        )
                    }
                    visible={editPackageId || createPackageForm}
                >
                    {(editPackageId && (
                        <PackageForm
                            editPackageId={editPackageId}
                            initPackage={initPackage}
                            initPackageName={initPackageName}
                            roles={roles}
                            updatePackage={updatePackage}
                        />
                    )) ||
                        (createPackageForm && (
                            <AddPackageForm createPackage={createPackage} roles={roles} />
                        ))}
                </Modal>
            </Catcher>
        );
    }
}
