import { Button, Checkbox, Col, Form, Modal, Row, Select, TreeSelect } from 'antd';
import { Spinner } from 'commons';
import { updateUser } from 'core/auth/duck';
import { onChangeProfileForm, submitProfileFormOther } from 'core/forms/profileForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.less';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 6 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 12 },
        xl: { span: 12 },
        xxl: { span: 12 }
    },
    colon: false
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 16 },
        xl: { span: 16 },
        xxl: { span: 16 }
    },
    colon: false
};

const mapDispatchToProps = {
    onChangeProfileForm,
    submitProfileFormOther,
    updateUser
};

const mapStateToProps = state => {
    return {
        user: state.auth,
        profileUpdating: state.ui.profileUpdating
    };
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class AccountingTabSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tree: [],
            loading: false
        };
    }

    _submitProfile = values => {
        const { submitProfileFormOther, onChangeProfileForm } = this.props;
        submitProfileFormOther(values);
        // onChangeProfileForm();
    };

    fetchAdditionalData = async () => {
        // await fetchAPI('GET', 'businesses');
    };

    filterTreeByActive = nodes => {
        const filteredNodes = nodes.reduce((accumulator, node) => {
            if (node.active === true) {
                const filteredNode = {
                    value: node.id,
                    title: `(#${node.id}) ${node.name} `,
                    level: node.level,
                    disabled: node.level !== 4
                };

                if (node.children) {
                    const filteredChildren = this.filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNode.children = filteredChildren;
                    }
                }

                accumulator.push(filteredNode);
            }

            return accumulator;
        }, []);

        // Update the state outside of the reduce function
        this.setState({
            tree: filteredNodes
        });

        return filteredNodes;
    };

    componentDidMount() {
        const { user } = this.props;
        if (!_.get(this.props.accounts, 'length')) {
            this.props.getAccountsList();
        }
        this.setState({
            clientAccountId: user.clientAccountId,
            supplierAccountId: user.supplierAccountId,
            employeeAccountId: user.employeeAccountId,
            warehouseAccountId: user.warehouseAccountId,
            cashAccountId: user.cashBoxAccountId
        });
    }

    componentDidUpdate() {
        const { tree } = this.state;
        if (!_.get(tree, 'length') && _.get(this.props.accounts, 'length')) {
            this.filterTreeByActive(this.props.accounts);
        }
    }

    confirmActionFetch = async (accountId, type, forEmpty, forDefault, forDirectories) => {
        const { updateUser, user } = this.props;
        const { clientAccountId, supplierAccountId, employeeAccountId, warehouseAccountId, cashAccountId } = this.state;
        this.setState({
            loading: true
        });
        await fetchAPI(
            'PUT',
            '/general_ledger/accounts_in_directories',
            undefined,
            {
                accountId: String(accountId) || undefined,
                type: type || undefined,
                forEmpty: forEmpty || undefined,
                forDefault: forDefault || undefined,
                forDirectories: forDirectories || undefined
            },
            {
                handleErrorInternally: true
            }
        );

        updateUser({
            ...user,
            clientAccountId: clientAccountId || user.clientAccountId,
            supplierAccountId: supplierAccountId || user.supplierAccountId,
            employeeAccountId: employeeAccountId || user.employeeAccountId,
            warehouseAccountId: warehouseAccountId || user.warehouseAccountId,
            cashBoxAccountId: cashAccountId || user.cashBoxAccountId
        });

        this.setState({
            loading: false
        });
    };

    selectConfirmAction = (accountId, type) => {
        const {
            user,
            isDisabledCRUD,
            intl: { formatMessage }
        } = this.props;
        const { loading } = this.state;

        Modal.confirm({
            title: formatMessage({
                id: 'settings_page.choose_option'
            }),
            width: '35%',
            content: (
                <div style={{}}>
                    <div
                        style={{
                            marginBottom: 8
                        }}
                    >
                        <Button
                            disabled={loading || isDisabledCRUD}
                            onClick={async () => {
                                await this.confirmActionFetch(accountId, type, true, true, true);

                                Modal.destroyAll();
                            }}
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'var(--primary)'
                            }}
                            type='primary'
                        >
                            <span
                                style={{
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                {formatMessage({
                                    id: 'settings_page.use_default_for_empty'
                                })}
                            </span>
                        </Button>
                    </div>
                    <div
                        style={{
                            marginBottom: 8
                        }}
                    >
                        <Button
                            disabled={loading || isDisabledCRUD}
                            onClick={async () => {
                                await this.confirmActionFetch(accountId, type, undefined, true, undefined);

                                Modal.destroyAll();
                            }}
                            style={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'var(--primary)'
                            }}
                            type='primary'
                        >
                            <span
                                style={{
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                {formatMessage({
                                    id: 'settings_page.use_default'
                                })}
                            </span>
                        </Button>
                    </div>
                    <div
                        style={{
                            marginBottom: 8
                        }}
                    >
                        <Button
                            disabled={loading || isDisabledCRUD}
                            onClick={async () => {
                                await this.confirmActionFetch(accountId, type, undefined, true, true);

                                Modal.destroyAll();
                            }}
                            style={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'var(--primary)'
                            }}
                            type='primary'
                        >
                            <span
                                style={{
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                {formatMessage({
                                    id: 'settings_page.use_default_and_update'
                                })}
                            </span>
                        </Button>
                    </div>
                    <div
                        style={{
                            marginBottom: 8
                        }}
                    >
                        <Button
                            danger
                            disabled={isDisabledCRUD}
                            onClick={() => {
                                Modal.destroyAll();
                            }}
                            style={{
                                width: '100%'
                            }}
                            type='primary'
                        >
                            {formatMessage({
                                id: 'cancel'
                            })}
                        </Button>
                    </div>
                </div>
            ),
            footer: null,
            okType: 'default'
        });
    };

    render() {
        const {
            user,
            isDisabledCRUD,
            intl: { formatMessage }
        } = this.props;

        const {
            tree,
            clientAccountId,
            supplierAccountId,
            employeeAccountId,
            warehouseAccountId,
            cashAccountId,
            loading
        } = this.state;

        return loading ? (
            <Spinner spin={loading} zIndex={1000} />
        ) : (
            <Row
                style={{
                    padding: '0 24px'
                }}
            >
                <Col span={12}>
                    <Form
                        className={Styles.profileForm}
                        disabled={isDisabledCRUD}
                        initialValues={{
                            generalLedgerWarehouse: _.get(user, 'generalLedgerWarehouse'),
                            generalLedgerDebt: _.get(user, 'generalLedgerDebt'),
                            generalLedgerEntries: _.get(user, 'generalLedgerEntries'),
                            generalLedgerVat: _.get(user, 'generalLedgerVat'),
                            generalLedgerSalaries: _.get(user, 'generalLedgerSalaries')
                        }}
                        labelCol={formItemLayout.labelCol}
                        name='tablesForm'
                        onFinish={this._submitProfile}
                        wrapperCol={formItemLayout.wrapperCol}
                    >
                        <span>
                            <FormattedMessage id='settings_page.accounting_by' />:
                        </span>
                        <Form.Item
                            label={<FormattedMessage id='order_form_table.store' />}
                            name='generalLedgerWarehouse'
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id='navigation.receivables_and_payables' />}
                            name='generalLedgerDebt'
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id='order_docs.entry_section' />}
                            name='generalLedgerEntries'
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id='VAT' />}
                            name='generalLedgerVat'
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id='order_docs.salaries_section' />}
                            name='generalLedgerSalaries'
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>

                        <Form.Item
                            className={Styles.itemBtn}
                            wrapperCol={{
                                offset: 6,
                                span: 6
                            }}
                        >
                            <Button className={Styles.saveBtn} htmlType='submit' type='primary'>
                                <FormattedMessage id='save' />
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={12}>
                    <span>
                        <FormattedMessage id='settings_page.accounts_by_default' />:
                    </span>
                    <Form labelCol={formItemLayout2.labelCol} wrapperCol={formItemLayout2.wrapperCol}>
                        <Form.Item label={<FormattedMessage id='vehicle_page.clients' />}>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <TreeSelect
                                    filterTreeNode={(input, node) => {
                                        return (
                                            node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    listHeight={280}
                                    onChange={value => {
                                        this.setState({
                                            clientAccountId: value
                                        });
                                    }}
                                    placeholder={
                                        <React.Fragment>
                                            <FormattedMessage id='receipt_document_modal.bill' />
                                        </React.Fragment>
                                    }
                                    showSearch
                                    style={{
                                        width: '50%',
                                        marginRight: 8
                                    }}
                                    treeData={tree}
                                    value={clientAccountId}
                                    // style={{ color: 'var(--text)', width: '100%' }}
                                />
                                <Button
                                    disabled={!clientAccountId}
                                    onClick={() => {
                                        this.selectConfirmAction(clientAccountId, 'CLIENTS');
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='update' />
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='navigation.suppliers' />}>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <TreeSelect
                                    filterTreeNode={(input, node) => {
                                        return (
                                            node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    listHeight={280}
                                    onChange={value => {
                                        this.setState({
                                            supplierAccountId: value
                                        });
                                    }}
                                    placeholder={
                                        <React.Fragment>
                                            <FormattedMessage id='receipt_document_modal.bill' />
                                        </React.Fragment>
                                    }
                                    showSearch
                                    style={{
                                        width: '50%',
                                        marginRight: 8
                                    }}
                                    treeData={tree}
                                    value={supplierAccountId}
                                    // style={{ color: 'var(--text)', width: '100%' }}
                                />
                                <Button
                                    disabled={!supplierAccountId}
                                    onClick={() => {
                                        this.selectConfirmAction(supplierAccountId, 'SUPPLIERS');
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='update' />
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='employee-table.employee' />}>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <TreeSelect
                                    filterTreeNode={(input, node) => {
                                        return (
                                            node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    listHeight={280}
                                    onChange={value => {
                                        this.setState({
                                            employeeAccountId: value
                                        });
                                    }}
                                    placeholder={
                                        <React.Fragment>
                                            <FormattedMessage id='receipt_document_modal.bill' />
                                        </React.Fragment>
                                    }
                                    showSearch
                                    style={{
                                        width: '50%',
                                        marginRight: 8
                                    }}
                                    treeData={tree}
                                    value={employeeAccountId}
                                    // style={{ color: 'var(--text)', width: '100%' }}
                                />
                                <Button
                                    disabled={!employeeAccountId}
                                    onClick={() => {
                                        this.selectConfirmAction(employeeAccountId, 'EMPLOYEES');
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='update' />
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='navigation.warehouses' />}>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <TreeSelect
                                    filterTreeNode={(input, node) => {
                                        return (
                                            node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    listHeight={280}
                                    onChange={value => {
                                        this.setState({
                                            warehouseAccountId: value
                                        });
                                    }}
                                    placeholder={
                                        <React.Fragment>
                                            <FormattedMessage id='receipt_document_modal.bill' />
                                        </React.Fragment>
                                    }
                                    showSearch
                                    style={{
                                        width: '50%',
                                        marginRight: 8
                                    }}
                                    treeData={tree}
                                    value={warehouseAccountId}
                                    // style={{ color: 'var(--text)', width: '100%' }}
                                />
                                <Button
                                    disabled={!warehouseAccountId}
                                    onClick={() => {
                                        this.selectConfirmAction(warehouseAccountId, 'STOCKS');
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='update' />
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='navigation.cash_settings' />}>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <TreeSelect
                                    filterTreeNode={(input, node) => {
                                        return (
                                            node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    listHeight={280}
                                    onChange={value => {
                                        this.setState({
                                            cashAccountId: value
                                        });
                                    }}
                                    placeholder={
                                        <React.Fragment>
                                            <FormattedMessage id='receipt_document_modal.bill' />
                                        </React.Fragment>
                                    }
                                    showSearch
                                    style={{
                                        width: '50%',
                                        marginRight: 8
                                    }}
                                    treeData={tree}
                                    value={cashAccountId}
                                    // style={{ color: 'var(--text)', width: '100%' }}
                                />

                                <Button
                                    disabled={!cashAccountId}
                                    onClick={() => {
                                        this.selectConfirmAction(cashAccountId, 'CASHBOXES');
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='update' />
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        );
    }
}
