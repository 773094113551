import { Catcher } from 'commons';
import { DatePickerField, SearchField, StatusRadioButtons } from 'forms/_formkit';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

const statuses = ['NEW', 'DONE'];

export const StorageFilters = injectIntl(props => {
    const { filters } = props;

    return (
        <Catcher>
            <Filters>
                {props.type !== 'expenses' ? (
                    <FilterRow>
                        <DatePickerField
                            date={filters.createdDatetime}
                            onChange={date =>
                                props.setFilters({
                                    createdDatetime: date,
                                    recordDatetime: void 0,
                                    doneDatetime: void 0
                                })
                            }
                            placeholder={props.intl.formatMessage({
                                id: 'storage.created_datetime'
                            })}
                        />
                        <DatePickerField
                            date={filters.recordDatetime}
                            onChange={date =>
                                props.setFilters({
                                    createdDatetime: void 0,
                                    recordDatetime: date,
                                    doneDatetime: void 0,
                                    paidDatetime: void 0
                                })
                            }
                            placeholder={props.intl.formatMessage({
                                id: 'storage.record_date'
                            })}
                        />
                        <DatePickerField
                            date={filters.doneDatetime}
                            onChange={date =>
                                props.setFilters({
                                    createdDatetime: void 0,
                                    recordDatetime: void 0,
                                    doneDatetime: date,
                                    paidDatetime: void 0
                                })
                            }
                            placeholder={props.intl.formatMessage({
                                id: 'storage.done_date'
                            })}
                        />
                        <DatePickerField
                            date={filters.paidDatetime}
                            onChange={date =>
                                props.setFilters({
                                    createdDatetime: void 0,
                                    recordDatetime: void 0,
                                    doneDatetime: void 0,
                                    paidDatetime: date
                                })
                            }
                            placeholder={props.intl.formatMessage({
                                id: 'storage.payment_date'
                            })}
                        />
                    </FilterRow>
                ) : null}
                <FilterRow>
                    <SearchField setFilters={props.setFilters} width='30%' />
                    <StatusRadioButtons
                        setFilters={props.setFilters}
                        status={filters.status}
                        statuses={statuses}
                        type={props.type}
                    />
                </FilterRow>
            </Filters>
        </Catcher>
    );
});

const Filters = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
`;

const FilterRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
        margin-bottom: 16px;
    }
`;
