import { Radio } from 'antd';
import _ from 'lodash';
import { MODES, SERVICE_OPERATION_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectMode,
    selectServiceOperationType,
    setServiceOperationType
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    serviceOperationType: selectServiceOperationType(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    setServiceOperationType
};

/**
 * Types of operations that cash order modal has to perform.
 * Different types of cash orders will be generated depending on it.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CashOrderServiceOperationTypeSelector extends React.Component {
    render() {
        const { serviceOperationType, setServiceOperationType, mode } = this.props;

        const disabled = Boolean(mode != MODES.ADD);

        return (
            <span className={Styles.mainCont}>
                <Radio.Group
                    disabled={disabled}
                    onChange={e => setServiceOperationType(e.target.value)}
                    value={serviceOperationType}
                >
                    {_.map(SERVICE_OPERATION_TYPES, (type, index) => {
                        return (
                            <Radio.Button key={index} value={type}>
                                <FormattedMessage id={`refactored_cash_order_modal.${type}`} />
                            </Radio.Button>
                        );
                    })}
                </Radio.Group>
            </span>
        );
    }
}
