import { emitError, setOrdersFetchingState } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import nprogress from 'nprogress';
import { spreadProp } from 'ramda-adjunct';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    CREATE_INVITE_ORDERS,
    FETCH_HEADER_STATS,
    FETCH_ORDERS,
    FETCH_ORDERS_STATS,
    SET_UNIVERSAL_FILTERS,
    createInviteOrdersSuccess,
    fetchHeaderStatsSuccess,
    fetchOrders,
    fetchOrdersStats,
    fetchOrdersStatsSuccess,
    fetchOrdersSuccess
} from './duck';

const selectFilter = ({ orders: { filter, sort, universalFilter } }) => ({
    sort,
    filter,
    universalFilter
});

function mergeFilters(filter, universalFilters) {
    const modelsTransformQuery =
        universalFilters.models && universalFilters.models.length
            ? {
                  models: _(universalFilters.models)
                      .map(model => model.split(','))
                      .flatten()
                      .value()
              }
            : {};

    const [startDate, endDate] = universalFilters.beginDate || [];
    const [createStartDate, createEndDate] = universalFilters.createDate || [];

    const momentFields = _({
        startDate,
        endDate,
        createEndDate,
        createStartDate
    })
        .pickBy(dayjs.isDayjs)
        .mapValues(momentDate => momentDate.format('YYYY-MM-DD'))
        .value();

    return _.omit(
        {
            ...universalFilters,
            ...modelsTransformQuery,
            ...momentFields,
            ...filter
        },
        ['beginDate', 'createDate']
    );
}

export function* fetchOrdersSaga() {
    while (true) {
        try {
            const { payload } = yield take(FETCH_ORDERS);
            yield nprogress.start();

            const {
                filter,
                universalFilter,
                sort: { field: sortField, order: sortOrder }
            } = yield select(selectFilter);

            // const activeStatuses = yield select(state =>
            //     _.get(state, 'router.location.state.status'));

            const ordersFilters = spreadProp('daterange', {
                ...filter,
                sortField,
                sortOrder
            });

            const filters = mergeFilters(
                {
                    ...ordersFilters
                    // ...activeStatuses && { status: activeStatuses },
                },
                universalFilter
            );

            yield put(setOrdersFetchingState(true));
            const data = yield call(fetchAPI, 'GET', 'orders', filters, null, { handleErrorInternally: true });

            yield put(fetchOrdersSuccess(data));
            yield put(fetchOrdersStats(_.omit(filters, ['page', 'status'])));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setOrdersFetchingState(false));
            yield nprogress.done();
        }
    }
}

export function* setUniversalFilter() {
    while (true) {
        yield take(SET_UNIVERSAL_FILTERS);
        yield put(fetchOrders());
    }
}

export function* fetchOrdersStatsSaga({ payload: filters = {} }) {
    try {
        yield nprogress.start();
        const statsFilters = _.omit(spreadProp('daterange', filters), ['page', 'pageSize', 'status']);
        const data = yield call(fetchAPI, 'GET', 'orders/stats', statsFilters, null, { handleErrorInternally: true });

        yield put(fetchOrdersStatsSuccess(data));
    } catch (error) {
        yield put(emitError(error));
    } finally {
        yield nprogress.done();
    }
}

export function* fetchHeaderStatsSaga() {
    try {
        // yield nprogress.start();
        const data = yield call(fetchAPI, 'GET', 'orders/stats', { statuses: ['reserve'] }, null, {
            handleErrorInternally: true
        });

        yield put(fetchHeaderStatsSuccess(data));
    } catch (error) {
        yield put(emitError(error));
    } finally {
        // yield nprogress.done();
    }
}

export function* createInviteOrders({ payload: { invites, filters } }) {
    try {
        yield nprogress.start();
        const data = yield call(fetchAPI, 'POST', 'orders', null, invites);

        yield put(createInviteOrdersSuccess(data));
        yield nprogress.done();
    } catch (error) {
        yield put(emitError(error));
    } finally {
        yield put(fetchOrders(filters));
    }
}

/* eslint-disable array-element-newline */
export function* saga() {
    yield all([
        call(fetchOrdersSaga),
        call(setUniversalFilter),
        takeEvery(FETCH_ORDERS_STATS, fetchOrdersStatsSaga),
        takeEvery(FETCH_HEADER_STATS, fetchHeaderStatsSaga),
        takeEvery(CREATE_INVITE_ORDERS, createInviteOrders)
    ]);
}
/* eslint-enable array-element-newline */
