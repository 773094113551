const ENTER_DATA = 'ENTER_DATA';
const SEARCH = 'SEARCH';
const CHECK = 'CHECK';
const CONFIRM = 'CONFIRM';
const ORDER = 'ORDER';
const SEND = 'SEND';
const INTAKE = 'INTAKE';
const RESERVE = 'RESERVE';
const READY = 'READY';
const MOUNT = 'MOUNT';
const MOUNTED = 'MOUNTED';
const ORDER_2_STOCK = 'ORDER_2_STOCK';
const BACK_2_SUP = 'BACK_2_SUP';
const OK = 'OK';
const CANCEL = 'CANCEL';
const UNDEFINED = 'UNDEFINED';
const AGREED = 'AGREED';
const REJECTED = 'REJECTED';

const isDisabledByDetailStatuses = (type, item, additionalRules = false) => {
    switch (type) {
        case 'edit':
            return (
                item.status === ORDER_2_STOCK ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'edit_catalogue':
            return (
                item.status === MOUNT ||
                item.status === MOUNTED ||
                item.status === ORDER_2_STOCK ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === REJECTED ||
                item.agreement === AGREED ||
                additionalRules
            );
        case 'delete':
            return (
                item.status === READY ||
                item.status === MOUNTED ||
                item.status === OK ||
                item.agreement === AGREED ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'copy':
            return item.status === MOUNTED || item.status === OK || additionalRules;
        case 'supplier':
            return (
                item.status === SEND ||
                item.status === INTAKE ||
                item.status === RESERVE ||
                item.status === READY ||
                item.status === MOUNT ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === AGREED ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'check':
            return (
                item.status === MOUNT ||
                item.status === MOUNTED ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === AGREED ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'order':
            return (
                item.status === ENTER_DATA ||
                item.status === SEARCH ||
                item.status === CHECK ||
                item.status === SEND ||
                item.status === INTAKE ||
                item.status === RESERVE ||
                item.status === READY ||
                item.status === MOUNT ||
                item.status === MOUNTED ||
                item.status === BACK_2_SUP ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === UNDEFINED ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'intake':
            return (
                item.status === ENTER_DATA ||
                item.status === SEARCH ||
                item.status === CHECK ||
                item.status === RESERVE ||
                item.status === READY ||
                item.status === MOUNT ||
                item.status === MOUNTED ||
                item.status === ORDER_2_STOCK ||
                item.status === BACK_2_SUP ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === UNDEFINED ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'res':
            return (
                item.status === ENTER_DATA ||
                item.status === READY ||
                item.status === MOUNT ||
                item.status === MOUNTED ||
                item.status === ORDER_2_STOCK ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'unres':
            return (
                item.status === ENTER_DATA ||
                item.status === READY ||
                item.status === MOUNT ||
                item.status === MOUNTED ||
                item.status === ORDER_2_STOCK ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'give':
            return (
                item.status === ENTER_DATA ||
                item.status === SEARCH ||
                item.status === CHECK ||
                item.status === CONFIRM ||
                item.status === ORDER ||
                item.status === SEND ||
                item.status === INTAKE ||
                item.status === RESERVE ||
                item.status === MOUNT ||
                item.status === MOUNTED ||
                item.status === ORDER_2_STOCK ||
                item.status === BACK_2_SUP ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === UNDEFINED ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'mount':
            return (
                item.status === ENTER_DATA ||
                item.status === SEARCH ||
                item.status === CHECK ||
                item.status === CONFIRM ||
                item.status === ORDER ||
                item.status === SEND ||
                item.status === INTAKE ||
                item.status === RESERVE ||
                item.status === MOUNTED ||
                item.status === ORDER_2_STOCK ||
                item.status === BACK_2_SUP ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === UNDEFINED ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'ret_1':
            return (
                item.status === ENTER_DATA ||
                item.status === SEARCH ||
                item.status === CHECK ||
                item.status === CONFIRM ||
                item.status === ORDER ||
                item.status === SEND ||
                item.status === INTAKE ||
                item.status === RESERVE ||
                item.status === READY ||
                item.status === MOUNTED ||
                item.status === ORDER_2_STOCK ||
                item.status === BACK_2_SUP ||
                item.status === OK ||
                item.status === CANCEL ||
                item.agreement === UNDEFINED ||
                item.agreement === REJECTED ||
                additionalRules
            );
        case 'ret_2':
            return (
                item.status === ENTER_DATA ||
                item.status === SEARCH ||
                item.status === CHECK ||
                item.status === CONFIRM ||
                item.status === ORDER ||
                item.status === SEND ||
                item.status === INTAKE ||
                item.status === RESERVE ||
                item.status === READY ||
                item.status === MOUNT ||
                item.status === MOUNTED ||
                item.status === ORDER_2_STOCK ||
                item.status === OK ||
                item.status === CANCEL ||
                additionalRules
            );
        default:
            return additionalRules;
    }
};

export default isDisabledByDetailStatuses;
