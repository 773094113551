import { Table } from 'antd';
import { fetchReport, setReportPage } from 'containers/ClientsDebtsContainer/redux/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { RefactoredCashOrderModal } from 'modals';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    currentPage: state.clientsDebtsContainer.filter.page,
    user: state.auth
});

const mapDispatchToProps = {
    fetchReport,
    setModal,
    setReportPage
};

@connect(mapStateToProps, mapDispatchToProps)
export default class CashOrdersTable extends Component {
    constructor(props) {
        super(props);
    }

    showCashOrderModal = row => {
        const { employeeId, client, setModal } = this.props;
        const { currentDebt, orderId, documentType, storeDocId, sign, id } = row;

        setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                type: CASH_ORDER_TYPES.INCOME,
                counterpartyType: COUNTERPARTY_TYPES.CLIENT,
                clientId: id,
                sum: currentDebt
            },
            onCashOrderCreatedCallback: () => this.props.fetchReport()
        });
    };

    render() {
        const { tableData, reportFetching, fetchReport, setReportPage, stats, currentPage, paymentDisabled, user } =
            this.props;

        const totalReportsCount = !stats || !stats.totalReportsCount ? 25 : stats.totalReportsCount;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: totalReportsCount,
            current: currentPage,
            onChange: page => {
                setReportPage(page);
                fetchReport();
            }
        };

        this.columns = columnsConfig({
            showCashOrderModal: this.showCashOrderModal,
            paymentDisabled,
            user
        });

        return (
            <div>
                <Table
                    bordered
                    className={Styles.table}
                    columns={this.columns}
                    dataSource={tableData}
                    loading={reportFetching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={pagination}
                    rowKey={record => record.id}
                    scroll={{ x: 1000 }}
                    size='small'
                />
                <RefactoredCashOrderModal />
            </div>
        );
    }
}
