import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { MODALS, setModal } from 'core/modals/duck';
import { selectLinks } from 'core/storage/products';
import _ from 'lodash';
import { VehicleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import VehiclesTable from './components/VehiclesTable';
import { fetchVehicles } from './redux/duck';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    allLinks: selectLinks(state)
});
const mapDispatchToProps = {
    fetchVehicles,
    setModal
};

/**
 * This page was created to maintain all vehicles of the station(show tables, subtables, controls and other).
 * There is used "feature first" files structure(reffer to react documentaion), it means
 * that each one-time used component can be found deeper in a tree structure
 *
 * Each subcomponent is self-sufficient and requires only necessary data from parrent,
 * other data can be fetched and stored by redux saga/duck.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehiclesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    onAddVehicle = () => {
        this.props.setModal(MODALS.VEHICLE, {
            mode: 'ADD',
            onClose: () => this.props.fetchVehicles()
        });
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_vehicles' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { user } = this.props;
        const { helperDrawerOpen, allLinks } = this.state;

        return (
            <Layout
                controls={
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Button
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST, accesses.ROWO)}
                            onClick={() => {
                                this.onAddVehicle();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='vehicles_page.add_vehicle' />
                        </Button>
                        <div
                            style={{
                                marginLeft: 8,
                                display: 'flex'
                            }}
                        >
                            <Button
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST, accesses.ROWO)}
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    const links = await fetchAPI(
                                        'GET',
                                        '/helps',
                                        { helpId: 'directories_and_settings_vehicles' },
                                        undefined,
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    this.setState({
                                        allLinks: links,
                                        helperDrawerOpen: true
                                    });
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </div>
                    </div>
                }
                description={<FormattedMessage id='vehicles_page.description' />}
                title={<FormattedMessage id='vehicles_page.title' />}
            >
                <VehiclesTable fetchHelperLinks={this.fetchHelperLinks} />

                <VehicleModal />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
