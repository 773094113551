/* eslint-disable complexity */
import { RepairMapIndicator } from 'components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

class TooltipBox extends React.PureComponent {
    render() {
        const {
            id,
            num,
            clientName,
            clientSurname,
            clientMiddleName,
            clientPhone,
            vehicleMakeName,
            vehicleModelName,
            vehicleYear,
            comment,
            systemComment,
            className,
            position,
            managerName,
            managerSurname,
            employeeName,
            employeeSurname,
            subStatusIndications,
            laborName,
            mode
        } = this.props;

        return position ? (
            <div
                key={id}
                className={className}
                data-order={id}
                style={{
                    display: position ? 'flex' : 'flex',
                    top: position.top,
                    left: position.left
                }}
                // style={ this.state.popupStyles }
            >
                {clientName || clientPhone || vehicleMakeName || vehicleModelName || comment || systemComment ? (
                    <React.Fragment>
                        {num}
                        {mode !== 'employees' && (
                            <React.Fragment>
                                <DashboardTooltipClient>
                                    {clientSurname || ''} {clientName || ''} {clientMiddleName || ''}
                                </DashboardTooltipClient>
                                {clientName !== clientPhone && <div>{clientPhone}</div>}
                            </React.Fragment>
                        )}
                        <DashboardTooltipVehicle>
                            {vehicleMakeName} {vehicleModelName} {vehicleYear}
                        </DashboardTooltipVehicle>
                        {managerSurname && (
                            <span>
                                {managerSurname} {managerName}
                            </span>
                        )}
                        {employeeSurname && (
                            <span>
                                {employeeSurname} {employeeName}
                            </span>
                        )}
                        {laborName && <span>{laborName}</span>}
                        {comment && (
                            <div>
                                <span style={{ color: 'red' }}>
                                    <FormattedMessage id='comment' />:
                                </span>
                                <DashboardTooltipComment>{comment}</DashboardTooltipComment>
                            </div>
                        )}
                        {systemComment && (
                            <div>
                                <span style={{ color: 'red' }}>
                                    <FormattedMessage id='add_order_form.system_comments' />:
                                </span>
                                <DashboardTooltipComment>{systemComment}</DashboardTooltipComment>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <FormattedMessage id='no_data' />
                )}

                <RepairMapIndicator data={subStatusIndications} />
            </div>
        ) : null;
    }
}

const DashboardTooltipClient = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DashboardTooltipVehicle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DashboardTooltipComment = styled.div`
    border: 1px var(--link) solid;
    padding: 2px;
    overflow: hidden;
    display: -webkit-box;
    word-break: break-all;
    white-space: pre-wrap;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`;

const DashboardTooltip = styled(TooltipBox)`
    background: var(--db-comment);
    border: 1px solid var(--link);
    box-sizing: border-box;
    color: black;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
    padding: 5px 5px 10px 5px;
    position: absolute;
    width: 200px;
    word-break: break-all;
    z-index: 3000;
    transition: 0.3s all ease-in-out;
    transform: translateX(35%) translateY(calc(-20% - 0.5px)) translateZ(0);
    translate3d: (0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    /* font-weight: bold; */
    -webkit-font-smoothing: antialiased;

    ${
        '' /* ${DashboardOrder}:hover & {
        display: flex;
    } ; */
    };
`;

export default DashboardTooltip;
