exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__scheduleIcon---ZDpAJ{font-size:20px;cursor:pointer}.styles-m__newSchedule---uO6Op{display:flex;align-items:center;margin:24px auto}.styles-m__scheduleFormItem---1aggB{margin-bottom:0}.styles-m__newScheduleRow---1F_Ug{background-color:rgba(11,127,0,.28)}.styles-m__editedScheduleRow---1T6r0{background-color:rgba(255,45,45,.28)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACI,cAAe,CACf,cACJ,CAEA,+BACI,YAAa,CACb,kBAAmB,CACnB,gBACJ,CAEA,oCACI,eACJ,CAEA,kCACI,mCACJ,CAEA,qCACI,oCACJ","file":"styles.m.css","sourcesContent":[".scheduleIcon {\n    font-size: 20px;\n    cursor: pointer;\n}\n\n.newSchedule {\n    display: flex;\n    align-items: center;\n    margin: 24px auto;\n}\n\n.scheduleFormItem {\n    margin-bottom: 0;\n}\n\n.newScheduleRow {\n    background-color: rgba(11, 127, 0, 0.28);\n}\n\n.editedScheduleRow {\n    background-color: rgba(255, 45, 45, 0.28);\n}\n"]}]);

// Exports
exports.locals = {
	"scheduleIcon": "styles-m__scheduleIcon---ZDpAJ",
	"newSchedule": "styles-m__newSchedule---uO6Op",
	"scheduleFormItem": "styles-m__scheduleFormItem---1aggB",
	"newScheduleRow": "styles-m__newScheduleRow---1F_Ug",
	"editedScheduleRow": "styles-m__editedScheduleRow---1T6r0"
};