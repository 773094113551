exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__inviteButton---28FRF{border-radius:6}.styles-m__stopRedirectMenu---WEUMD{position:fixed;width:40%;height:54px;top:0;left:0;z-index:9999}.styles-m__ogTitle---cunc5{font-size:16px;font-weight:700}.styles-m__ogDesc---1RWCG{margin-bottom:8px;margin-top:8px;opacity:.7}.styles-m__ogImg---1xPHE{margin-bottom:4px;margin-top:4px}.styles-m__linkBlock---3hmOQ{margin-bottom:12px;background-color:#f5f5f5;border:1px solid #8a8d8a;padding:4px;border-radius:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACI,eACJ,CAEA,oCACI,cAAe,CACf,SAAU,CACV,WAAY,CACZ,KAAM,CACN,MAAO,CACP,YACJ,CAEA,2BACI,cAAe,CACf,eACJ,CAEA,0BACI,iBAAkB,CAClB,cAAe,CACf,UACJ,CAEA,yBACI,iBAAkB,CAClB,cACJ,CAEA,6BACI,kBAAmB,CACnB,wBAA4B,CAC5B,wBAAyB,CACzB,WAAY,CACZ,iBACJ","file":"styles.m.css","sourcesContent":[".inviteButton {\n    border-radius: 6;\n}\n\n.stopRedirectMenu {\n    position: fixed;\n    width: 40%;\n    height: 54px;\n    top: 0;\n    left: 0;\n    z-index: 9999;\n}\n\n.ogTitle {\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.ogDesc {\n    margin-bottom: 8px;\n    margin-top: 8px;\n    opacity: 0.7;\n}\n\n.ogImg {\n    margin-bottom: 4px;\n    margin-top: 4px;\n}\n\n.linkBlock {\n    margin-bottom: 12px;\n    background-color: whitesmoke;\n    border: 1px solid #8a8d8a;\n    padding: 4px;\n    border-radius: 8px; \n}"]}]);

// Exports
exports.locals = {
	"inviteButton": "styles-m__inviteButton---28FRF",
	"stopRedirectMenu": "styles-m__stopRedirectMenu---WEUMD",
	"ogTitle": "styles-m__ogTitle---cunc5",
	"ogDesc": "styles-m__ogDesc---1RWCG",
	"ogImg": "styles-m__ogImg---1xPHE",
	"linkBlock": "styles-m__linkBlock---3hmOQ"
};