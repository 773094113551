/* eslint-disable func-names */
import { DeleteOutlined } from '@ant-design/icons';
import { InputNumber, Popconfirm, Select, Table } from 'antd';
import { Layout } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { buildSupplierOptions, fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AvailabilitiesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            availOptions: [],
            supplierOptions: [],
            searchInput: undefined
        };
        this.availOptions = [];
        this.columns = [
            {
                title: <FormattedMessage id='availabilities-page.supplier' />,
                dataIndex: 'businessSupplierId',
                key: 'businessSupplierId',
                width: '15%',
                render: (data, elem) => {
                    const noSupplier = this.state.supplierOptions.find(({ id }) => id === elem.businessSupplierId);

                    return (
                        <Select
                            disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_SUPPLIER_PRICES, accesses.ROWO)}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterOption={(input, option) => {
                                return (
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onChange={value => {
                                elem.businessSupplierId = value;
                                this.addOrEditAvail(elem.key);
                            }}
                            onSearch={input => {
                                this.handleSearchSuppliers(input);
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'availabilities-page.supplier'
                            })}
                            showSearch
                            value={data}
                        >
                            {elem.businessSupplierId
                                ? buildSupplierOptions(this.state.supplierOptions, {
                                      id: elem.businessSupplierId,
                                      name: elem.businessSupplierName
                                  }).map(({ id, name }) => (
                                      <Option key={id} value={id}>
                                          {name}
                                      </Option>
                                  ))
                                : this.state.supplierOptions.map(({ id, name }) => (
                                      <Option key={id} value={id}>
                                          {name}
                                      </Option>
                                  ))}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='availabilities-page.discount' />,
                dataIndex: 'discount',
                key: 'discount',
                width: '10%',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            defaultValue={0}
                            disabled={
                                !elem.businessSupplierId ||
                                !isGrantAccessed(this.props.user, grants.SETTINGS_SUPPLIER_PRICES, accesses.ROWO)
                            }
                            formatter={value => `${value}%`}
                            max={100}
                            min={0}
                            onChange={value => {
                                elem.discount = value / 100;
                                this.addOrEditAvail(elem.key);
                            }}
                            parser={value => value.replace('%', '')}
                            step={5}
                            value={Math.round(data * 1000) / 10 || 0}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='availabilities-page.avail_0' />,
                dataIndex: 'availableIn0LocationId',
                key: 'availableIn0LocationId',
                width: '15%',
                render: (data, elem) => {
                    return (
                        <Select
                            disabled={
                                !elem.businessSupplierId ||
                                !isGrantAccessed(this.props.user, grants.SETTINGS_SUPPLIER_PRICES, accesses.ROWO)
                            }
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterOption={(input, option) => {
                                return (
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(option.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onChange={value => {
                                elem.availableIn0LocationId = value;
                                this.addOrEditAvail(elem.key);
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'availabilities-page.avail_0'
                            })}
                            showSearch
                            value={data}
                        >
                            {this.state.availOptions}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='availabilities-page.avail_1' />,
                dataIndex: 'availableIn1LocationId',
                key: 'availableIn1LocationId',
                width: '15%',
                render: (data, elem) => {
                    return (
                        <Select
                            disabled={
                                !elem.businessSupplierId ||
                                !isGrantAccessed(this.props.user, grants.SETTINGS_SUPPLIER_PRICES, accesses.ROWO)
                            }
                            onChange={value => {
                                elem.availableIn1LocationId = value;
                                this.addOrEditAvail(elem.key);
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'availabilities-page.avail_1'
                            })}
                            showSearch
                            value={data}
                        >
                            {this.state.availOptions}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='availabilities-page.avail_2' />,
                dataIndex: 'availableIn2LocationId',
                key: 'availableIn2LocationId',
                width: '15%',
                render: (data, elem) => {
                    return (
                        <Select
                            disabled={
                                !elem.businessSupplierId ||
                                !isGrantAccessed(this.props.user, grants.SETTINGS_SUPPLIER_PRICES, accesses.ROWO)
                            }
                            onChange={value => {
                                elem.availableIn2LocationId = value;
                                this.addOrEditAvail(elem.key);
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'availabilities-page.avail_2'
                            })}
                            showSearch
                            value={data}
                        >
                            {this.state.availOptions}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='availabilities-page.avail_x' />,
                dataIndex: 'availableInxLocationId',
                key: 'availableInxLocationId',
                width: '15%',
                render: (data, elem) => {
                    return (
                        <Select
                            disabled={
                                !elem.businessSupplierId ||
                                !isGrantAccessed(this.props.user, grants.SETTINGS_SUPPLIER_PRICES, accesses.ROWO)
                            }
                            onChange={value => {
                                elem.availableInxLocationId = value;
                                this.addOrEditAvail(elem.key);
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'availabilities-page.avail_x'
                            })}
                            showSearch
                            value={data}
                        >
                            {this.state.availOptions}
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='availabilities-page.limit' />,
                dataIndex: 'limit',
                key: 'limit',
                width: '10%',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            defaultValue={0}
                            disabled={
                                !elem.businessSupplierId ||
                                !isGrantAccessed(this.props.user, grants.SETTINGS_SUPPLIER_PRICES, accesses.ROWO)
                            }
                            onChange={value => {
                                elem.limit = value;
                                this.addOrEditAvail(elem.key);
                            }}
                            value={data || 0}
                        />
                    );
                }
            },
            {
                width: '3%',
                key: 'delete',
                render: elem => {
                    return (
                        <Popconfirm
                            disabled={
                                elem.id == undefined ||
                                !isGrantAccessed(this.props.user, grants.SETTINGS_SUPPLIER_PRICES, accesses.ROWO)
                            }
                            onConfirm={async () => {
                                const token = localStorage.getItem('_my.carbook.pro_token');
                                let url = __API_URL__;
                                const params = `/availabilities/settings?id=${elem.id}`;
                                url += params;
                                try {
                                    const response = await fetch(url, {
                                        method: 'DELETE',
                                        headers: {
                                            Authorization: token,
                                            'Content-Type': 'application/json'
                                        }
                                    });
                                    const result = await response.json();
                                    if (result.success) {
                                        this.updateDataSource();
                                    } else {
                                        console.log('BAD', result);
                                    }
                                } catch (error) {
                                    console.error('ERROR:', error);
                                }
                            }}
                            title={<FormattedMessage id='add_order_form.delete_confirm' />}
                        >
                            <DeleteOutlined
                                className={elem.id == undefined ? Styles.disabledIcon : Styles.deleteIcon}
                            />
                        </Popconfirm>
                    );
                }
            }
        ];
    }

    addOrEditAvail(index) {
        const { dataSource } = this.state;
        const targetElem = dataSource[index];
        let data = {};
        let type = 'POST';
        if (targetElem.id == undefined) {
            data = {
                businessSupplierId: targetElem.businessSupplierId,
                discount: targetElem.discount,
                availableIn0LocationId: targetElem.availableIn0LocationId,
                availableIn1LocationId: targetElem.availableIn1LocationId,
                availableIn2LocationId: targetElem.availableIn2LocationId,
                availableInxLocationId: targetElem.availableInxLocationId,
                limit: targetElem.limit
            };
        } else {
            data = {
                id: targetElem.id,
                businessSupplierId: targetElem.businessSupplierId,
                discount: targetElem.discount,
                availableIn0LocationId: targetElem.availableIn0LocationId,
                availableIn1LocationId: targetElem.availableIn1LocationId,
                availableIn2LocationId: targetElem.availableIn2LocationId,
                availableInxLocationId: targetElem.availableInxLocationId,
                limit: targetElem.limit
            };
            type = 'PUT';
        }

        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = '/availabilities/settings';
        url += params;

        fetch(url, {
            method: type,
            headers: {
                Authorization: token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.updateDataSource();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    updateDataSource() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = '/availabilities/settings';
        url += params;

        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token,
                'content-type': 'application/json'
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((elem, index) => (elem.key = index));
                that.setState({
                    dataSource: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    fetchSuppliersOptions = async (query, all = false) => {
        const suppliers = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                showHidden: false,
                query,
                all
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            supplierOptions: suppliers
        });
    };

    handleSearchSuppliers = _.debounce(value => {
        this.fetchSuppliersOptions(value, true);
    }, 1000);

    componentDidMount() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = '/availabilities/locations';
        url += params;

        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const availOptions = data.map((elem, i) => (
                    <Option key={i} value={elem.id}>
                        {`${elem.code} (${elem.name})`}
                    </Option>
                ));
                that.setState({ availOptions });
            })
            .catch(function (error) {
                console.log('error', error);
            });

        this.fetchSuppliersOptions();

        this.updateDataSource();
    }

    render() {
        const { dataSource } = this.state;
        if (!dataSource.length || dataSource[dataSource.length - 1].id != undefined) {
            dataSource.push({
                key: dataSource.length,
                id: undefined
            });
        }

        return (
            <Layout
                controls={<React.Fragment></React.Fragment>}
                title={<FormattedMessage id='navigation.availabilities' />}
            >
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={dataSource}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    scroll={{ y: 680 }}
                    size='small'
                />
            </Layout>
        );
    }
}

export default AvailabilitiesPage;
