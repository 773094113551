import { Table } from 'antd';
import {
    selectFetchingVehicleOrders,
    selectVehicleOrders,
    selectVehicleOrdersSort,
    selectVehicleOrdersStats,
    setPageOrders
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { columnsConfig } from './config';

const mapStateToProps = state => ({
    user: state.auth,
    orders: selectVehicleOrders(state),
    stats: selectVehicleOrdersStats(state),
    sort: selectVehicleOrdersSort(state),
    fetchingVehicleOrders: selectFetchingVehicleOrders(state)
});

const mapDispatchToProps = {
    setPageOrders
};

/**
 * Table of vehicle orders
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AggregateOrdersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            stats: 0
        };

        const { user } = props;
    }

    componentDidMount() {
        this.fetchAllAggregatesOrders();
    }

    fetchAllAggregatesOrders = async () => {
        const { id } = this.props;
        const { page, pageSize, query } = this.state;

        const aggregatesOrders = await fetchAPI(
            'GET',
            `/orders/aggregate/${id}`,
            {
                page,
                pageSize,
                query
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            orders: aggregatesOrders.orders,
            stats: aggregatesOrders.countOrders
        });
    };

    render() {
        const {
            intl: { formatMessage },
            disabled
        } = this.props;

        const { page, pageSize, stats, orders } = this.state;

        const pagination = {
            pageSize,
            page,
            size: 'large',
            total: Math.ceil(stats / pageSize) * pageSize,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchAllAggregatesOrders();
            }
        };

        return (
            <Table
                bordered
                columns={columnsConfig({ formatMessage, disabled })}
                dataSource={orders}
                pagination={pagination}
                scroll={{ y: '60vh' }}
                size='small'
            />
        );
    }
}
