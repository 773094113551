// Core
import { Button, Input, Tabs } from 'antd';
import { Spinner } from 'commons';
import { ProfileForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Layout } from 'tireFitting';
import { fetchAPI } from 'utils';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    fetchBusiness = async () => {
        const business = await fetchAPI('GET', '/business');
        const { supplierNetName, supplierNetPhone, tireShopUrl, accessoriesShopUrl } = business;
        this.setState({
            supplierNetName,
            supplierNetPhone,
            tireShopUrl,
            accessoriesShopUrl
        });
    };

    componentDidMount() {
        this.fetchBusiness();
    }

    render() {
        const { profileUpdating } = this.props;
        const { supplierNetName, supplierNetPhone, tireShopUrl, accessoriesShopUrl } = this.state;

        return profileUpdating ? (
            <Spinner spin={profileUpdating} />
        ) : (
            <Layout
                description={<FormattedMessage id='profile.description' />}
                title={<FormattedMessage id='profile.title' />}
            >
                <Tabs>
                    <TabPane key='settings' tab={<FormattedMessage id='profile.settings' />}>
                        <ProfileForm />
                    </TabPane>
                    <TabPane key='icons' tab='Шиномонтаж'>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <div>
                                <FormattedMessage id='profile.supplierNetName' />
                                <div>
                                    <Input
                                        onChange={e => {
                                            this.setState({
                                                supplierNetName: e.target.value
                                            });
                                        }}
                                        style={{
                                            width: 360
                                        }}
                                        value={supplierNetName}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 14 }}>
                                <FormattedMessage id='profile.supplierNetPhone' />
                                <div>
                                    <Input
                                        onChange={e => {
                                            this.setState({
                                                supplierNetPhone: e.target.value
                                            });
                                        }}
                                        style={{
                                            width: 360
                                        }}
                                        value={supplierNetPhone}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 14 }}>
                                <FormattedMessage id='profile.tireShopUrl' />
                                <div>
                                    <Input
                                        onChange={e => {
                                            this.setState({
                                                tireShopUrl: e.target.value
                                            });
                                        }}
                                        style={{
                                            width: 360
                                        }}
                                        value={tireShopUrl}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 14 }}>
                                <FormattedMessage id='profile.accessoriesShopUrl' />
                                <div>
                                    <Input
                                        onChange={e => {
                                            this.setState({
                                                accessoriesShopUrl: e.target.value
                                            });
                                        }}
                                        style={{
                                            width: 360
                                        }}
                                        value={accessoriesShopUrl}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: 14
                                }}
                            >
                                <Button
                                    onClick={async () => {
                                        await fetchAPI(
                                            'PUT',
                                            'businesses',
                                            undefined,
                                            {
                                                supplierNetName,
                                                supplierNetPhone,
                                                tireShopUrl,
                                                accessoriesShopUrl
                                            },
                                            { handleErrorInternally: true }
                                        );
                                        await window.location.reload();
                                    }}
                                    style={{
                                        width: 240
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </Layout>
        );
    }
}
