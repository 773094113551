import { Form } from '@ant-design/compatible';
import { CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import { onChangeOrderTasksForm } from 'core/forms/orderTaskForm/duck';
import dayjs from 'dayjs';
import {
    DecoratedDatePicker,
    DecoratedSelect,
    DecoratedTextArea,
    DecoratedTimePicker
} from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
@withReduxForm({
    name: 'orderTaskForm',
    actions: {
        change: onChangeOrderTasksForm
    }
})
export class OrderTaskForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleDirectory: false
        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { formatMessage } = this.props.intl;
        const {
            num,
            progressStatusOptions,
            priorityOptions,
            stations,
            managers,
            initialOrderTask,
            activeVehicle
        } = this.props;
        const { toggleDirectory } = this.state;

        const textDirectory = (
            <div className={Styles.directory}>
                <CloseCircleOutlined onClick={() => this.setState({ toggleDirectory: false })} />
                <div className={Styles.info}>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='calculation' />
                        </div>
                        <div>
                            <FormattedMessage id='manager_prepares_cost_estimate' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='harmonization' />
                        </div>
                        <div>
                            <FormattedMessage id='communication_with_the_client' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='waiting_for_arrival' />:
                        </div>
                        <div>
                            <FormattedMessage id='all_agreed' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='acceptance' />:
                        </div>
                        <div>
                            <FormattedMessage id='manager_accepts_cars' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='diagnostics' />:
                        </div>
                        <div>
                            <FormattedMessage id='passes_car_diagnostics' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='waiting_for_repair' />:
                        </div>
                        <div>
                            <FormattedMessage id='auto_waiting_for_repair' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='waiting_for_spare_parts' />:
                        </div>
                        <div>
                            <FormattedMessage id='auto_expects_spare_parts' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='order_task.repairs' />:
                        </div>
                        <div>
                            <FormattedMessage id='there_is_a_repair' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='extradition' />:
                        </div>
                        <div>
                            <FormattedMessage id='repair_is_completed' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='closed' />:
                        </div>
                        <div>
                            <FormattedMessage id='repair_is_closed' />
                        </div>
                    </div>
                    <div className={Styles.infoDirectory}>
                        <div className={Styles.infoDirectoryName}>
                            <FormattedMessage id='other' />:
                        </div>
                        <div>
                            <FormattedMessage id='custom_tasks' />
                        </div>
                    </div>
                </div>
            </div>
        );

        const popup = (
            <span
                className={Styles.statusTooltip}
                onClick={() => this.setState({ toggleDirectory: !toggleDirectory })}
            >
                <FormattedMessage id='order-task-modal.open_status_helper' />
            </span>
        );

        return (
            <Form layout='horizontal'>
                <div className={Styles.orderDescription}>
                    {num ? (
                        <div>
                            <FormattedMessage id='order-task-modal.order_number' />:{num}
                        </div>
                    ) : null}
                    {activeVehicle ? (
                        <div>
                            <FormattedMessage id='order-task-modal.vehicle' />:{activeVehicle}
                        </div>
                    ) : null}
                </div>
                <div className={Styles.statusPanel}>
                    <DecoratedSelect
                        className={Styles.statusSelect}
                        field='status'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        hasFeedback
                        initialValue={_.get(initialOrderTask, 'status')}
                        label={<FormattedMessage id='status' />}
                        placeholder={
                            <FormattedMessage id='order_task_modal.progressStatus_placeholder' />
                        }
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                        showSearch
                    >
                        {progressStatusOptions.map(({ id }) => {
                            return (
                                <Option key={String(id)} value={id}>
                                    <FormattedMessage id={id} />
                                </Option>
                            );
                        })}
                    </DecoratedSelect>
                    <Tooltip
                        getPopupContainer={trigger => trigger.parentNode}
                        placement='top'
                        title={popup}
                    >
                        <QuestionCircleOutlined style={{ marginBottom: 8 }} />
                    </Tooltip>
                </div>
                {toggleDirectory ? (
                    <div>{textDirectory}</div>
                ) : (
                    <div>
                        <DecoratedSelect
                            className={Styles.selectMargin}
                            field='priority'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            hasFeedback
                            initialValue={_.get(initialOrderTask, 'priority')}
                            label={<FormattedMessage id='priority' />}
                            placeholder={
                                <FormattedMessage id='order_task_modal.priority_placeholder' />
                            }
                            showSearch
                        >
                            {priorityOptions.map(({ id }) => {
                                return (
                                    <Option key={String(id)} value={id}>
                                        <FormattedMessage id={id} />
                                    </Option>
                                );
                            })}
                        </DecoratedSelect>
                        <DecoratedSelect
                            className={Styles.selectMargin}
                            field='stationName'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            hasFeedback
                            initialValue={_.get(initialOrderTask, 'stationNum')}
                            label={<FormattedMessage id='stationName' />}
                            placeholder={
                                <FormattedMessage id='order_task_modal.post_placeholder' />
                            }
                            // optionFilterProp='children'
                            showSearch
                        >
                            {stations.map(({ name, num }) => {
                                return (
                                    <Option key={String(num)} value={num}>
                                        {name || num}
                                    </Option>
                                );
                            })}
                        </DecoratedSelect>
                        <DecoratedSelect
                            className={Styles.selectMargin}
                            field='responsible'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            hasFeedback
                            initialValue={_.get(initialOrderTask, 'responsibleId')}
                            label={<FormattedMessage id='responsible' />}
                            placeholder={
                                <FormattedMessage id='order_task_modal.responsible_placeholder' />
                            }
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                            // optionFilterProp='children'
                            showSearch
                        >
                            {managers.map(({ managerName, managerSurname, id }) => {
                                return (
                                    <Option key={String(id)} value={id}>
                                        {`${managerSurname} ${managerName}`}
                                    </Option>
                                );
                            })}
                        </DecoratedSelect>
                        <div className={Styles.dateTimePickerBlock}>
                            <DecoratedDatePicker
                                className={Styles.selectMargin}
                                field='deadlineDate'
                                format='YYYY-MM-DD'
                                formatMessage={formatMessage}
                                formItem
                                getCalendarContainer={trigger => trigger.parentNode}
                                getFieldDecorator={getFieldDecorator}
                                initialValue={
                                    _.get(initialOrderTask, 'deadlineDate')
                                        ? dayjs(_.get(initialOrderTask, 'deadlineDate'))
                                        : void 0
                                }
                                label={<FormattedMessage id='deadlineDate' />}
                                placeholder={
                                    <FormattedMessage id='order_task_modal.deadlineDate_placeholder' />
                                }
                                value={null}
                            />
                            <DecoratedTimePicker
                                allowClear={false}
                                className={Styles.selectMargin}
                                field='deadlineTime'
                                format='HH:mm'
                                formatMessage={formatMessage}
                                formItem
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                initialValue={
                                    _.get(initialOrderTask, 'deadlineDate')
                                        ? dayjs(_.get(initialOrderTask, 'deadlineDate'))
                                        : void 0
                                }
                                label={<FormattedMessage id='deadlineTime' />}
                                minuteStep={30}
                                placeholder={formatMessage({
                                    id: 'order_task_modal.deadlineTime_placeholder'
                                })}
                                value={null}
                            />
                        </div>
                        <DecoratedTextArea
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            className={Styles.selectMargin}
                            field='comment'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(initialOrderTask, 'comment')}
                            label={<FormattedMessage id='comment' />}
                            placeholder={formatMessage({
                                id: 'order_task_modal.comment_placeholder'
                            })}
                            rules={[
                                {
                                    max: 2000,
                                    message: formatMessage({
                                        id: 'field_should_be_below_2000_chars'
                                    })
                                }
                            ]}
                        />
                    </div>
                )}
            </Form>
        );
    }
}
