import { EditOutlined, UserDeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Like, NPS, ReviewRating } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

export function columnsConfig() {
    const client = {
        title: <FormattedMessage id='reviews-table.client' />,
        width: 200,
        dataIndex: 'clientFullname',
        key: 'clientFullname',
        render: (data, review) => (
            <div className={Styles.client}>
                <div>{dayjs(review.datetime).format('YYYY-MM-DD HH:mm')}</div>
                {review.anonymous ? (
                    <div>
                        <UserDeleteOutlined className={Styles.anonIcon} />
                        <div className={Styles.anon}>
                            <FormattedMessage id='reviews-table.anon' />
                        </div>
                    </div>
                ) : (
                    <div>
                        <UserOutlined className={Styles.userIcon} />
                        <div className={Styles.user}>
                            {review.clientName} {review.clientSurname}
                        </div>
                    </div>
                )}
            </div>
        )
    };

    const feedback = {
        title: <FormattedMessage id='reviews-table.feedback' />,
        width: 400,
        dataIndex: 'text',
        key: 'text',
        render: (data, review) => (
            <div>
                {review.orderNum && (
                    <Link className={Styles.orderLink} to={`${book.order}/${review.orderId}`}>
                        {review.orderNum}
                    </Link>
                )}
                {review.visitDatetime && (
                    <div>
                        <span className={Styles.label}>
                            <FormattedMessage id='reviews-table.visit_date' />:{' '}
                        </span>
                        {dayjs(review.visitDatetime).format('YYYY-MM-DD')}
                    </div>
                )}
                {(review.vehicleMakeName || review.vehicleModelName) && (
                    <div>
                        <span className={Styles.label}>
                            <FormattedMessage id='reviews-table.vehicle' />:{' '}
                        </span>
                        <span className={Styles.vehicle}>
                            {`${review.vehicleMakeName} ${review.vehicleModelName} (${review.vehicleYear})`}
                        </span>
                    </div>
                )}
                <div className={Styles.comment}>{review.text}</div>
                <Like like={review.recommended} text />
            </div>
        )
    };

    const nps = {
        title: <FormattedMessage id='reviews-table.nps' />,
        width: 100,
        dataIndex: 'nps',
        key: `nps${v4()}`,
        render: (nps, review) => {
            if (nps) {
                return (
                    <NPS mode='block' nps={nps} reviewId={review.id}>
                        {nps}
                    </NPS>
                );
            }

            return null;
        }
    };

    const rating = {
        title: <FormattedMessage id='reviews-table.rating' />,
        width: 300,
        dataIndex: 'repairQuality',
        key: `rating${v4()}`,
        render: (data, review) => (
            <ReviewRating
                comfort={review.comfort}
                repairDuration={review.repairDuration}
                repairQuality={review.repairQuality}
                serviceQuality={review.serviceQuality}
            />
        )
    };

    const link = {
        title: '',
        key: 'link',
        // fixed:  'right',
        width: 'auto',
        render: (data, review) => (
            <Link to={`${book.feedback}/${review.id}`}>
                <EditOutlined className={Styles.reviewLinkIcon} />
            </Link>
        )
    };

    return [client, feedback, nps, rating, link];
}
