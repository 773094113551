import { Button, Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    modalProps: state.modals.modalProps
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
export default class CancelReasonModal extends Component {
    render() {
        const {
            visible,
            resetModal,
            saveOrder,
            redirect,
            modalProps: { onRefuse, onConfirm }
        } = this.props;

        return (
            <Modal
                cancelText={<FormattedMessage id='order_form_table.confirm_exit_without_changes' />}
                footer={
                    <div>
                        <Button onClick={() => (onRefuse ? onRefuse() : redirect())}>
                            <FormattedMessage id='no' />
                        </Button>
                        <Button
                            onClick={() => {
                                onConfirm ? onConfirm() : saveOrder();
                                resetModal();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='yes' />
                        </Button>
                    </div>
                }
                maskClosable={false}
                onCancel={() => resetModal()}
                open={visible === MODALS.CONFIRM_EXIT}
            >
                <FormattedMessage id='order_form_table.confirm_exit' />
            </Modal>
        );
    }
}
