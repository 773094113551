import { Col, Row } from 'antd';
import { selectSelectedClientId, setClientsFilters } from 'modals/DebtZeroingModal/redux/clients/duck';
import {
    selectClientSearchType,
    selectSelectedOrderId,
    selectSelectedStoreDocId,
    setClientSearchType,
    setStoreDocsFilters
} from 'modals/DebtZeroingModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    ClientCounterpartySearchInput,
    ClientCounterpartySearchTypeSelector,
    ClientVisualizer,
    ClientsTable,
    OrderVisualizer,
    OrdersTable,
    StoreDocVisualizer,
    StoreDocsTable
} from './components';
import { SEARCH_TYPES } from './constants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    clientSearchType: selectClientSearchType(state),
    selectedClientId: selectSelectedClientId(state),
    selectedOrderId: selectSelectedOrderId(state),
    selectedStoreDocId: selectSelectedStoreDocId(state)
});

const mapDispatchToProps = {
    setClientSearchType,
    setClientsFilters,
    setStoreDocsFilters
};

/**
 * When counterparty is a CLIENT this component will render all additional components and
 * handle logic for data processing.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientCounterpartyContainer extends React.Component {
    render() {
        const { clientSearchType, selectedClientId, selectedOrderId, selectedStoreDocId } = this.props;

        let counterpartyTable = null;
        switch (clientSearchType) {
            case SEARCH_TYPES.FOR_CLIENT:
                if (selectedOrderId) {
                    counterpartyTable = <OrderVisualizer />;
                } else if (selectedClientId) {
                    counterpartyTable = (
                        <div>
                            <ClientVisualizer />
                            <OrdersTable />
                        </div>
                    );
                } else {
                    counterpartyTable = <ClientsTable />;
                }
                break;
            case SEARCH_TYPES.FOR_ORDER:
                counterpartyTable = selectedOrderId ? <OrderVisualizer /> : <OrdersTable />;
                break;
            case SEARCH_TYPES.FOR_DOCUMENT:
                counterpartyTable = selectedStoreDocId ? (
                    <StoreDocVisualizer />
                ) : (
                    <StoreDocsTable counterpartyType='client' />
                );
                break;
        }

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col className={Styles.col} span={12}>
                        <ClientCounterpartySearchInput />
                    </Col>
                    <Col className={Styles.col} span={12}>
                        <ClientCounterpartySearchTypeSelector />
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col className={Styles.col} span={24}>
                        <div className={Styles.counterpartyTables}>{counterpartyTable}</div>
                    </Col>
                </Row>
            </div>
        );
    }
}
