import { DatePicker, Modal, Select } from 'antd';
import { DateRangePicker } from 'components';
import { fetchCashboxes } from 'core/cash/duck';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import { fetchReportAnalytics } from 'core/reports/reportAnalytics/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    user: state.auth,
    cashboxes: state.cash.cashboxes,
    analytics: state.reportAnalytics.analytics,
    clients: state.clients.clients
});

const mapDispatchToProps = {
    resetModal,
    fetchCashboxes,
    fetchReportAnalytics,
    fetchClients,
    setClientsSearchFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class UniversalCashReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: dayjs(),
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month')
        };

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    handleOk = async () => {
        const { resetModal } = this.props;
        const { cashBoxId, fromDate, toDate, analyticsId, clientId } = this.state;

        const response = await fetchAPI(
            'GET',
            'report/excel/balance/cashOrders',
            {
                cashBoxId,
                analyticsId,
                fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                toDate: dayjs(toDate).format('YYYY-MM-DD'),
                clientId
            },
            null,
            { rawResponse: true }
        );

        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
        this.handleCancel();
    };

    componentDidMount() {
        this.props.fetchCashboxes();
        this.props.fetchReportAnalytics();
        this.props.fetchClients();
    }

    handleCancel = () => {
        this.setState({
            cashBoxId: undefined,
            analyticsId: undefined,
            clientId: undefined
        });
        this.props.resetModal();
    };

    render() {
        const { resetModal, visible, cashboxes, analytics, clients } = this.props;

        const { cashBoxId, fromDate, toDate, analyticsId, clientId } = this.state;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    maskClosable={false}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='reports_page.universal_by_payments' />}
                    visible={visible === MODALS.UNIVERSAL_CASH_REPORT_MODAL}
                    width='fit-content'
                >
                    <Select
                        allowClear
                        dropdownStyle={{ zIndex: '9999' }}
                        filterOption={false}
                        onChange={clientId => {
                            this.setState({ clientId });
                        }}
                        onSearch={this.handleSearchClients}
                        placeholder={this.props.intl.formatMessage({ id: 'add_order_form.client' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={clientId}
                    >
                        {clients.map(client => (
                            <Option key={client.clientId} phone={_.get(client, 'phones[0]')} value={client.clientId}>
                                {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                    client,
                                    'phones[0]',
                                    ''
                                )}`}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={cashBoxId => {
                            this.setState({ cashBoxId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'cash-order-form.cashbox'
                        })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={cashBoxId}
                    >
                        {cashboxes
                            ? cashboxes
                                  .filter(({ active }) => active)
                                  .map(({ name, id }) => (
                                      <Option key={id} id={id}>
                                          {name}
                                      </Option>
                                  ))
                            : undefined}
                    </Select>
                    <Select
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={analyticsId => {
                            this.setState({ analyticsId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'store_doc_service.analytics'
                        })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={analyticsId}
                    >
                        {analytics.map(({ analyticsUniqueId, analyticsName }) => (
                            <Option key={analyticsUniqueId} value={analyticsUniqueId}>
                                {analyticsName}
                            </Option>
                        ))}
                    </Select>
                    <DateRangePicker
                        dateRange={[fromDate, toDate]}
                        onDateChange={arr => {
                            this.setState({ fromDate: arr[0] });
                            this.setState({ toDate: arr[1] });
                        }}
                    />
                </Modal>
            </div>
        );
    }
}
