import { Layout } from 'commons';
import { BarcodeContainer } from 'containers';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BarcodePage extends Component {
    render() {
        const { user } = this.props;

        return (
            <Layout
                title={<FormattedMessage id='navigation.barcode' />}
                /* controls={
                    <Barcode
                        value={"123456789012"}
                        iconStyle={{
                            fontSize: 24,
                        }}
                        options={{
                            height: 90,
                            fontSize: 18,
                            width: 3,
                            margin: 0,
                        }}
                    />
                } */
            >
                <BarcodeContainer />
            </Layout>
        );
    }
}
