/* eslint-disable max-classes-per-file */
/* eslint-disable func-names */
import {
    LockOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
    RedditOutlined,
    UnlockOutlined
} from '@ant-design/icons';
import { Button, DatePicker, Drawer, Image, Input, Modal, Select, Tooltip, TreeSelect, notification } from 'antd';
import { Layout } from 'commons';
import { StorageBalanceTotals, StoreBalanceTable, WarehouseSelect } from 'components';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { get, isArray } from 'lodash';
import { ProductsMovementModal } from 'modals';
import React, { Component, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth
});

export const StorageBalancePage = connect(mapStateToProps)(
    injectIntl(({ intl, user }) => {
        const [helperDrawerOpen, sethelperDrawerOpen] = useState(false);
        const [allLinks, setAllLinks] = useState([]);

        const fetchHelperLinks = async () => {
            const links = await fetchAPI('GET', '/helps', { helpId: 'storage_balance' }, undefined, {
                handleErrorInternally: true
            });
            setAllLinks(links);
        };

        const canReserve = isGrantAccessed(user, grants.WAREHOUSE_PRODUCTS_IN_STOCK_REMOVE_ALL_RESERVED, accesses.ROWO);
        const canUnreserve = isGrantAccessed(user, grants.WAREHOUSE_PRODUCTS_IN_STOCK_RESERVE_ALL, accesses.ROWO);

        return (
            <Layout
                controls={[
                    // <SwagButton />,
                    canReserve && <PrintModalButton />,
                    canUnreserve && <UnreserveButton />,
                    <React.Fragment>
                        <Tooltip placement='bottom' title={<FormattedMessage id='storage_document.reserve_all' />}>
                            <Button
                                icon={<LockOutlined style={{ fontSize: 24 }} />}
                                onClick={async () => {
                                    const { created } = await fetchAPI('POST', 'store_docs/reserve_all_possible');
                                    if (created) {
                                        notification.success({
                                            message: intl.formatMessage({
                                                id: 'storage_document.reserve_all_success'
                                            })
                                        });
                                        window.location.reload();
                                    } else {
                                        notification.warning({
                                            message: intl.formatMessage({
                                                id: 'storage_document.error.confirm_all'
                                            })
                                        });
                                    }
                                }}
                                size='large'
                                style={{ marginLeft: 8 }}
                                type='text'
                            />
                        </Tooltip>
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                sethelperDrawerOpen(true);
                                await fetchHelperLinks();
                            }}
                            style={{
                                fontSize: 22,
                                marginLeft: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </React.Fragment>
                ]}
                title={<FormattedMessage id='navigation.storage_balance' />}
            >
                <StoreBalanceTableWrapper>
                    <StorageBalanceTotals />
                    <StoreBalanceTable />
                </StoreBalanceTableWrapper>
                <Drawer
                    onClose={() => {
                        sethelperDrawerOpen(false);
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={isArray(ogImage) ? get(ogImage, '[0].url', []) : get(ogImage, 'url', [])}
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    })
);

const StoreBalanceTableWrapper = styled.section``;

const mapDispatchToProps = {
    resetModal,
    setModal
};

@connect(mapStateToProps, mapDispatchToProps)
class UnreserveButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { user } = this.props;

        return (
            <Tooltip placement='bottom' title={<FormattedMessage id='wms.delete-all-reserves' />}>
                <Button
                    icon={<UnlockOutlined style={{ fontSize: 24 }} />}
                    onClick={async () => {
                        await fetchAPI('DELETE', 'store_products/reserves');
                    }}
                    size='large'
                    style={{ marginLeft: 8 }}
                    type='text'
                />
            </Tooltip>
        );
    }
}

@injectIntl
@withRouter
class PrintModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataSource: [],
            brands: [],
            fetched: false,
            warehouseId: undefined,
            startDate: undefined,
            endDate: undefined,
            detailsTreeData: []
        };
    }

    buildStoreGroupsTree = async () => {
        const storeGroups = await fetchAPI('GET', 'store_groups');
        const treeData = [];

        for (let i = 0; i < storeGroups.length; i++) {
            const parentGroup = storeGroups[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id})`,
                name: parentGroup.singleName,
                value: parentGroup.id,
                key: `${i}`,
                children: [],
                multiplier: parentGroup.priceGroupMultiplier
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id})`,
                    name: childGroup.singleName,
                    value: childGroup.id,
                    key: `${i}-${j}`,
                    children: [],
                    multiplier: childGroup.priceGroupMultiplier
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id})`,
                        name: lastNode.singleName,
                        value: lastNode.id,
                        key: `${i}-${j}-${k}`,
                        children: [],
                        multiplier: lastNode.priceGroupMultiplier
                    });
                    for (let l = 0; l < lastNode.childGroups.length; l++) {
                        const elem = lastNode.childGroups[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id})`,
                            name: elem.singleName,
                            value: elem.id,
                            key: `${i}-${j}-${k}-${l}`,
                            multiplier: elem.priceGroupMultiplier
                        });
                    }
                }
            }
        }
        this.setState({
            detailsTreeData: treeData
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            dataSource: [],
            fetched: false
        });
    };

    handleOk = async () => {
        const { warehouseId, startDate, endDate, brandsId, storeGroupId, productCode } = this.state;
        const response = await fetchAPI(
            'GET',
            'store_doc_products/movement_report',
            {
                warehouseId,
                startDate: dayjs(startDate).format('YYYY-MM-DD'),
                endDate: dayjs(endDate).format('YYYY-MM-DD'),
                date: dayjs(startDate).add(-1, 'day').format('YYYY-MM-DD'),
                brandsId,
                storeGroupId,
                productCode
            },
            null,
            { rawResponse: true, handleErrorInternally: true }
        );

        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
    };

    componentDidMount = async () => {
        this.buildStoreGroupsTree();
        const brands = await fetchAPI('GET', 'brands');
        this.setState({
            brands
        });

        if (this.props.location.state && this.props.location.state.showModal) {
            // this.props.setModal(this.props.location.state.showModal)
            this.setState({
                visible: true
            });
        }
    };

    render() {
        const { visible, brands, warehouseId, detailsTreeData } = this.state;

        return (
            <React.Fragment>
                <Tooltip placement='bottom' title={<FormattedMessage id='print' />} zIndex={2001}>
                    <Button
                        onClick={() => {
                            this.setState({ visible: true });
                        }}
                    >
                        <PrinterOutlined />
                    </Button>
                </Tooltip>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    okButtonProps={{
                        disabled: !warehouseId
                    }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='navigation.print' />}
                    visible={visible}
                    width='460px'
                    zIndex={230}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <DatePicker onChange={startDate => this.setState({ startDate })} />
                        <DatePicker onChange={endDate => this.setState({ endDate })} />
                    </div>
                    <WarehouseSelect
                        onChange={warehouseId => this.setState({ warehouseId })}
                        style={{ width: '100%', marginTop: 8 }}
                    />
                    <TreeSelect
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        onSelect={storeGroupId => {
                            this.setState({ storeGroupId });
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.store_group'
                        })}
                        showSearch
                        style={{ marginTop: 8 }}
                        treeData={detailsTreeData}
                    />
                    <Select
                        allowClear
                        onChange={brandsId => this.setState({ brandsId })}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.brand'
                        })}
                        showSearch
                        style={{ marginTop: 8 }}
                    >
                        {brands.map(({ brandId, brandName }) => (
                            <Option key={brandId} value={brandId}>
                                {brandName}
                            </Option>
                        ))}
                    </Select>
                    <Input
                        allowClear
                        onChange={({ target }) => this.setState({ productCode: target.value })}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.detail_code'
                        })}
                        style={{ marginTop: 8 }}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

@connect(mapStateToProps, mapDispatchToProps)
class PrintModalButton extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Tooltip placement='bottom' title={<FormattedMessage id='print' />} zIndex={2001}>
                    <Button
                        icon={<PrinterOutlined style={{ fontSize: 24 }} />}
                        onClick={() => {
                            this.props.setModal(MODALS.PRODUCTS_MOVEMENT_MODAL);
                        }}
                        size='large'
                        style={{ marginLeft: 8 }}
                        type='text'
                    />
                </Tooltip>
                <ProductsMovementModal />
            </React.Fragment>
        );
    }
}
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class SwagButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Tooltip placement='bottom' title={this.props.intl.formatMessage({ id: 'write_off_goods' })}>
                <Button
                    icon={<RedditOutlined style={{ fontSize: 24 }} />}
                    onClick={async () => {
                        await fetchAPI('GET', 'orders/recomplete/reserved');
                    }}
                    size='large'
                    style={{ marginLeft: 8 }}
                    type='text'
                />
            </Tooltip>
        );
    }
}
