import { Layout } from 'commons';
import { SubscriptionProductsContainer } from 'containers';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class SubscriptionPackagesPage extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <Layout title={<FormattedMessage id='navigation.subscription_packages' />}>
                    <SubscriptionProductsContainer />
                </Layout>
            </React.Fragment>
        );
    }
}
