import { fetchClient } from 'core/client/duck';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { updateClientSuccess, UPDATE_CLIENT } from './duck';

export function* updateClientSaga() {
    while (true) {
        const {
            payload: { clientId, client }
        } = yield take(UPDATE_CLIENT);

        yield call(fetchAPI, 'PUT', `clients/${clientId}`, null, client, {
            handleErrorInternally: true
        });
        yield put(fetchClient(clientId));
        yield put(updateClientSuccess());
    }
}

export function* saga() {
    yield all([call(updateClientSaga)]);
}
