import { Select, Tooltip } from 'antd';
import _ from 'lodash';
import { COUNTERPARTY_TYPES, MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectMode,
    selectSelectedCashbox,
    selectSelectedCounterpartyType,
    setCashOrderSum,
    setSelectedCounterpartyType
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    selectedCounterpartyType: selectSelectedCounterpartyType(state),
    selectedCashbox: selectSelectedCashbox(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    setSelectedCounterpartyType,
    setCashOrderSum
};

/**
 * When we want ot create an order we have to provide counterparty.
 * Each counterparty has its on logic for data processing.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CounterPartySelector extends React.Component {
    /**
     * When counterparty type is changed
     * @param {*} type - new counterparty type
     */
    onSelectedNewCashOrderType = type => {
        const { setSelectedCounterpartyType, setCashOrderSum } = this.props;

        setSelectedCounterpartyType(type);
        setCashOrderSum(0); // Reset cash order sum
    };

    render() {
        const {
            selectedCounterpartyType,
            selectedCashbox,
            mode,
            editable,

            intl: { formatMessage }
        } = this.props;

        const disabled = Boolean(mode != MODES.ADD);

        return (
            <div>
                <Tooltip
                    placement='top'
                    title={<FormattedMessage id='refactored_cash_order_modal.hint_counterparty' />}
                    zIndex={2001}
                >
                    <Select
                        data-qa='select_counterparty'
                        disabled={!editable && disabled}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={counterpartyType =>
                            this.onSelectedNewCashOrderType(counterpartyType)
                        }
                        value={selectedCounterpartyType}
                    >
                        {_.map(COUNTERPARTY_TYPES, (type, index) => {
                            return (
                                <Option key={index} value={type}>
                                    <FormattedMessage id={`refactored_cash_order_modal.${type}`} />
                                </Option>
                            );
                        })}
                    </Select>
                </Tooltip>
            </div>
        );
    }
}
