import { Form } from '@ant-design/compatible';
import { Modal } from 'antd';
import { MODALS, resetModal, selectModal } from 'core/modals/duck';
import { createSupplier } from 'core/suppliers/duck';
import { DecoratedInput } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    user: state.auth,
    modal: selectModal(state)
});

const mapDispatchToProps = {
    createSupplier,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@Form.create()
export default class SupplierModal extends Component {
    _submit = () => {
        const { form, createSupplier, resetModal, modalProps, user } = this.props;

        const supplierId = _.get(modalProps, 'id');

        const func = _.get(this.props, 'func');

        const fields = form.getFieldsValue();

        form.validateFields((err, values) => {
            if (!err) {
                const concatValues = { ...values };
                supplierId
                    ? createSupplier({ ...concatValues, id: supplierId })
                    : func
                    ? createSupplier(concatValues, func)
                    : createSupplier(concatValues);
                form.resetFields();
                resetModal();
            }
        });

        _.get(this.props, 'onSubmit') && this.props.onSubmit(fields);
    };

    render() {
        const {
            visible,
            modal,
            resetModal,
            modalProps,
            onSubmit,
            form: { getFieldDecorator },
            intl: { formatMessage }
        } = this.props;

        const supplierName = _.get(modalProps, 'name');

        return (
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                maskClosable={false}
                okText={<FormattedMessage id='save' />}
                onCancel={() => resetModal()}
                onOk={() => {
                    this._submit();
                }}
                title={
                    <FormattedMessage
                        id={supplierName ? 'supplier-modal.edit_supplier' : 'supplier-modal.add_supplier'}
                    />
                }
                visible={modal === MODALS.SUPPLIER}
            >
                <Form onSubmit={this._submit}>
                    <DecoratedInput
                        field='name'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        hasFeedback
                        initialValue={supplierName || null}
                        label={
                            <FormattedMessage
                                id={supplierName ? 'supplier-modal.edit_supplier' : 'supplier-modal.add_supplier'}
                            />
                        }
                        placeholder={formatMessage({
                            id: 'supplier-modal.supplier_name_placeholder'
                        })}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'supplier-modal.name_validation'
                                })
                            }
                        ]}
                    />
                </Form>
            </Modal>
        );
    }
}
