import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Collapse, Flex, Input, Modal, Select, TreeSelect, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const VARIABLES = {
    Total_Selling: 'Total_Selling',
    Total_Selling_VAT: 'Total_Selling_VAT',
    Services_Selling: 'Services_Selling',
    Services_Selling_VAT: 'Services_Selling_VAT',
    Goods_Selling: 'Goods_Selling',
    Goods_Selling_VAT: 'Goods_Selling_VAT',
    Services_Purchase: 'Services_Purchase',
    Services_Purchase_VAT: 'Services_Purchase_VAT',
    Goods_Purchase: 'Goods_Purchase',
    Goods_Purchase_VAT: 'Goods_Purchase_VAT',
    Total_Salaries: 'Total_Salaries',
    Total_Taxes: 'Total_Taxes',
    Total: 'Total'
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const EntriesDirectoryAddRowsModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        editMode,
        analytics,
        directoryId,
        tree,
        fetchEntryRows,
        edit,
        fetchEntries,
        isMobile
    } = props;

    const [accountId, setAccountId] = useState(undefined);
    const [analyticsId, setAnalyticsId] = useState(undefined);
    const [amountFormula, setFormula] = useState('');
    const [amountSignature, setAmountSignature] = useState(undefined);
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState(undefined);
    const [rowId, setRowId] = useState(undefined);
    const [operationId, setOperationId] = useState(undefined);
    const [accountType, setAccType] = useState(undefined);

    useEffect(() => {
        if (edit) {
            setAccountId(edit.accountId);
            setAnalyticsId(edit.analyticsId);
            setFormula(edit.amountFormula);
            setAmountSignature(edit.amountSignature);
            setComment(edit.comment);
            setOperationType(edit.operationType);
            setRowId(edit.id);
            setOperationId(edit.operationId);
            setAccType(edit.accountType);
        }
    }, [edit]);

    const addEntriesRow = async () => {
        try {
            await fetchAPI(
                'POST',
                'general_ledger/entries_directory_lines',
                undefined,
                {
                    directoryId,
                    accountId,
                    analyticsId,
                    amountFormula,
                    amountSignature,
                    operationType,
                    comment,
                    operationId,
                    accountType
                },
                { handleErrorInternally: true }
            );
            await fetchEntryRows();
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const editEntriesRow = async id => {
        await fetchAPI(
            'PUT',
            'general_ledger/entries_directory_lines',
            undefined,
            {
                id: rowId,
                directoryId,
                accountId,
                analyticsId,
                amountFormula,
                amountSignature,
                operationType,
                comment,
                operationId,
                accountType
            },
            { handleErrorInternally: true }
        );

        await fetchEntryRows();
    };

    const handleAddToFormula = key => () => {
        const str = typeof key === 'number' ? key : ` ${key}`;
        setFormula(`${amountFormula || ''}${str}`);
    };

    const removeFromFormula = () => {
        const index = amountFormula.lastIndexOf(' ');

        setFormula(amountFormula.substring(0, index));
    };

    // useEffect(() => {
    //     if (editMode) {
    //         setName(editMode.name);
    //         setDocType(editMode.docType);
    //         setOperationType(editMode.operationType);
    //         setComment(editMode.comment);
    //     }
    // }, [editMode]);

    const onClose = () => {
        setAccountId(undefined);
        setAnalyticsId(undefined);
        setAmountSignature(undefined);
        setFormula(undefined);
        setOperationType(undefined);
        setComment(undefined);
        setOperationId(undefined);
        setAccType(undefined);

        hideModal();
        setTimeout(() => fetchEntries(), 100);
    };

    const onFinish = async () => {
        if (!edit) {
            addEntriesRow();
        } else {
            editEntriesRow();
        }

        await onClose();
    };

    const disabled =
        accountId && !accountType ? !accountId : !accountId && accountType ? !accountType : !accountId || !accountType;

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: !operationType || !amountFormula || !amountSignature || !operationId || disabled
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={
                !editMode ? <FormattedMessage id='entries_tab.add_rows' /> : <FormattedMessage id='entries_tab.edit' />
            }
            width={isMobile ? '95%' : '30%'}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='requisite-setting.account' />
                        {!accountType && <span className={Styles.rules}>*</span>}
                    </div>

                    <TreeSelect
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        listHeight={440}
                        onSelect={value => {
                            setAccountId(value);
                            setAccType(undefined);
                        }}
                        placeholder={
                            <React.Fragment>
                                <FormattedMessage id='requisite-setting.account' />
                            </React.Fragment>
                        }
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        treeData={tree}
                        value={accountId}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='entries_tab.acc_type' />
                        {!accountId && <span className={Styles.rules}>*</span>}
                    </div>
                    <Select
                        onChange={value => {
                            setAccType(value);
                            setAccountId(undefined);
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'entries_tab.acc_type'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={accountType}
                    >
                        <Option key='CONTRAGENT' value='CONTRAGENT'>
                            <FormattedMessage id='storage.counterparty' />
                        </Option>
                        <Option key='CASHDESK' value='CASHDESK'>
                            <FormattedMessage id='cash-order-form.cashbox' />
                        </Option>
                        <Option key='STOCK' value='STOCK'>
                            <FormattedMessage id='order_tabs.stock' />
                        </Option>
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='entries_tab.operation_id' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Input
                        onChange={event => {
                            setOperationId(event.target.value);
                        }}
                        placeholder={formatMessage({
                            id: 'entries_tab.operation_id'
                        })}
                        style={{ width: '100%' }}
                        value={operationId}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='navigation.report_analytics' />
                    </div>
                    <Select
                        onSelect={value => setAnalyticsId(value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'navigation.report_analytics'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={analyticsId}
                    >
                        {analytics
                            ? analytics.map(({ analyticsId, analyticsName }) => (
                                  <Option key={analyticsId} value={analyticsId}>
                                      {analyticsName}
                                  </Option>
                              ))
                            : undefined}
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='storage.operation_type' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Select
                        onSelect={value => setOperationType(value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'storage.operation_type'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={operationType}
                    >
                        <Option key='DIRECT' value='DIRECT'>
                            <FormattedMessage id='entries_tab.DIRECT' />
                        </Option>
                        <Option key='STORNO' value='STORNO'>
                            <FormattedMessage id='entries_tab.STORNO' />
                        </Option>
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='entries_tab.rows.formula' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Input
                        onChange={e => {
                            setFormula(e.target.value);
                        }}
                        placeholder={formatMessage({
                            id: 'description'
                        })}
                        readOnly
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={amountFormula}
                    />
                </div>
                <Collapse>
                    <Panel key='calculator' header={<FormattedMessage id='entries_tab.edit_formula' />}>
                        <div className={Styles.divGroup}>
                            <Flex gap={8} justify='space-between' wrap='wrap'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '+', '-', '*', '/', '(', ')', '.'].map(elem => (
                                    <Button
                                        onClick={handleAddToFormula(elem)}
                                        style={{
                                            flex: '1 1 auto'
                                        }}
                                    >
                                        {elem}
                                    </Button>
                                ))}

                                {Object.values(VARIABLES).map(elem => (
                                    <Button
                                        onClick={handleAddToFormula(elem)}
                                        style={{
                                            flex: '1 1 auto'
                                        }}
                                    >
                                        {elem}
                                    </Button>
                                ))}
                                <Button icon={<ArrowLeftOutlined />} onClick={removeFromFormula} />
                            </Flex>
                        </div>
                    </Panel>
                </Collapse>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='entry_table.operation_side' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Select
                        onSelect={value => setAmountSignature(value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'storage.operation_type'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={amountSignature}
                    >
                        <Option key='+' value='+'>
                            <FormattedMessage id='storage.sign_+' />
                        </Option>
                        <Option key='-' value='-'>
                            <FormattedMessage id='storage.sign_-' />
                        </Option>
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='comment' />
                    </div>
                    <Input.TextArea
                        autoFocus
                        onChange={event => {
                            setComment(event.target.value);
                        }}
                        style={{ width: '100%', minHeight: '150px' }}
                        value={comment}
                    />
                </div>
            </div>
        </Modal>
    );
};

const EntriesDirectoryAddRows = injectIntl(connect(mapStateToProps, mapDispatchToProps)(EntriesDirectoryAddRowsModal));

export default EntriesDirectoryAddRows;
