/* eslint-disable no-underscore-dangle */
/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { Form } from '@ant-design/compatible';
import {
    DollarOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    InfoOutlined,
    PlusOutlined,
    VideoCameraAddOutlined
} from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Input, Modal, Select, Tabs, Tooltip, notification } from 'antd';
import { Numeral } from 'commons';
import { MODALS, loadModal, resetModal, saveModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import StoreDocServicesTable from 'forms/StorageForms/StorageDocumentForm/components/StoreDocServicesTable';
import _, { get } from 'lodash';
import {
    AddClientModal,
    AddOrderFromDocumentModal,
    SetBarcodeModal,
    StoreDocServiceModal,
    SupplierModal
} from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import book from 'routes/book';
import {
    buildSupplierOptions,
    fetchAPI,
    getCurrency,
    goTo,
    showStorageWarehouses,
    storageDocumentMapper,
    withReduxForm
} from 'utils';

import * as constants from 'pages/Storage/constants';
import { CORRECTIVE_SERVICE } from 'pages/Storage/constants';
import { DocumentsIcon, LogsIcon, RepairIcon, SparePartsIcon, TasksIcon } from 'theme';
import { grants, isGrantAccessed } from 'utils/grants';
import DocProductsTable from './components/DocProductsTable';

import AddProductModal from './components/AddProductModal';
import AdditionalSpendingsTab from './components/tabs/AdditionalSpendingsTab';
import DocumentsTab from './components/tabs/DocumentsTab';
import LogsTab from './components/tabs/LogsTab';
import StoreDocMediaTab from './components/tabs/StoreDocMediaTab';
import TasksTab from './components/tabs/TasksTab';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 }
};
const requiredField = () => <b style={{ color: 'var(--required)' }}> *</b>;

const disabledSelectText = { color: 'var(--text)' };
const mask = '0,0.00';
const {
    INCOME,
    EXPENSE,
    RESERVE,
    SUPPLIER,
    SERVICE,
    CLIENT,
    INVENTORY,
    OWN_CONSUMPTION,
    TRANSFER,
    ADJUSTMENT,
    ORDERINCOME,
    ORDER,
    NEW,
    DONE,
    INTER_CO_DONE,
    TOOL,
    REPAIR_AREA,
    PRESERVATION,
    PRODUCTION,
    DISASSEMBLY,
    CLIENT_CTP,
    SUPPLIER_CTP
} = constants;

@withRouter
@withReduxForm({
    name: 'storageDocumentForm',
    actions: {
        setModal,
        resetModal,
        saveModal,
        loadModal
    },
    mapStateToProps: state => ({
        modal: state.modals.modal,
        isMobile: state.ui.views.isMobile
        // user: state.auth,
    })
})
@injectIntl
class StorageDocumentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            editKey: undefined,
            clientSearchValue: '',
            employeeSearchValue: '',
            supplierSearchValue: '',
            counterpartOptionInfo: {
                value: undefined,
                children: ''
            },
            warning: false,
            productBarcode: undefined,
            products: [],
            units: [],
            allNetworks: []
        };
        this.setActiveTab = this.setActiveTab.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;    
        if (location.productId) {
            this.addProductById(location.productId);
        }
        this.getClientOption();
        this.getMinDate();
        this.fetchUnits();
    }

    setActiveTab(tab, action) {
        this.setState({
            activeKey: tab,
            action
        });
    }

    componentDidUpdate(prevProps) {
        const { type, documentType, operationCode } = this.props;
        const isServiceType = documentType === SERVICE && (type === INCOME || type === EXPENSE);

        this.getClientOption();

        if (this.props.formData.documentType === PRESERVATION && !this.props.formData.ctpType) {
            this.props.updateFormData({
                ctpType: CLIENT_CTP
            });
        }

        if (prevProps.isMobile !== this.props.isMobile) {
            this.setState({
                activeKey: this.props.isMobile ? 'info' : (isServiceType ? 'services' : 'spare-parts'),
            });
        }
    }

    addProductById = async productId => {
        try {
            const detail = await fetchAPI('GET', `store_products/${productId}`);
            if (detail) {
                this.addProductDetailToState(detail);
            }
        } catch (error) {
            console.error('addProductById', error);
            notification.error({
                message: 'Failed to fetch product details'
            });
        }
    };

    addProductByIdBarcode = async productId => {
        try {
            const detail = await fetchAPI('GET', `store_products/${productId}`);
            if (detail) {
                this.addProductDetailToState(detail);
            }
        } catch (error) {
            console.error('addProductByIdBarcode', error);
            notification.error({
                message: 'Failed to fetch product details'
            });
        }
    };

    addProductDetailToState = detail => {
        const { cells } = this.props;
        const { type, documentType } = this.props.formData;
        const {
            id,
            brand,
            code,
            name,
            uktz,
            sellingPrice,
            quantity,
            tradeCode,
            purchasePrice,
            cellAddresses,
            orderId,
            productUnitId
        } = detail;

        let addToAddress;
        let getFromAddress;
        const cell = cells.find(cell => cell.address === _.get(cellAddresses, '[0]') && cell.enabled);
        const preferAddress = cellAddresses ? cell.address : undefined;

        if (type === constants.INCOME || documentType === constants.ORDERINCOME || type === constants.TRANSFER) {
            addToAddress = preferAddress;
        } else if (type === constants.EXPENSE) {
            getFromAddress = preferAddress;
        }

        this.setState(prevState => ({
            products: [
                ...prevState.products,
                {
                    productId: id,
                    detailCode: code,
                    brandName: brand.name,
                    brandId: brand.id,
                    tradeCode,
                    detailName: name,
                    uktz,
                    stockPrice: Number(purchasePrice || 0),
                    sellingPrice: Number(sellingPrice || 0),
                    quantity: quantity || 1,
                    addToAddress,
                    getFromAddress,
                    orderId,
                    docProductUnitId: productUnitId
                }
            ]
        }));
    };

    addByBarcode = async barcode => {
        try {
            const barcodeData = await fetchAPI('GET', 'barcodes', { barcode });
            const productBarcode = barcodeData.find(({ table }) => table === 'STORE_PRODUCTS');

            if (productBarcode) {
                if (productBarcode.existence) {
                    confirm({
                        title: 'Add product by barcode?',
                        icon: <ExclamationCircleOutlined />,
                        onOk: () => this.addProductById(productBarcode.referenceId),
                        onCancel: () =>
                            fetchAPI('DELETE', 'barcodes', null, { referenceId: productBarcode.referenceId })
                    });
                } else {
                    this.addProductById(productBarcode.referenceId);
                }
            } else {
                this.setState({ productBarcode: barcode });
                notification.warning({
                    message: 'Barcode not found'
                });
            }
        } catch (error) {
            notification.error({
                message: 'Failed to fetch barcode details'
            });
        }
    };

    editProduct = (key, warning = false) => {
        this.setState({
            editKey: key,
            warning,
            modalVisible: true
        });
    };

    showModal = () => {
        this.setState({
            modalVisible: true
        });
    };

    hideModal = () => {
        this.setState({
            modalVisible: false,
            warning: false,
            editKey: undefined
        });
    };

    getClientOption = () => {
        const { formData, clientList } = this.props;
        if (
            (formData.documentType === constants.CLIENT ||
                (formData.documentType === constants.PRESERVATION && formData.ctpType === constants.CLIENT_CTP)) &&
            formData.counterpartId &&
            !this.state.counterpartOptionInfo.value
        ) {
            const client = clientList.find(client => client.clientId === formData.counterpartId);
            if (client) {
                this.setState({
                    counterpartOptionInfo: {
                        value: formData.counterpartId,
                        children: `${client.surname || ''} ${client.name} ${client.middleName || ''} ${
                            client.phones[0]
                        }`
                    }
                });
            }
        }
    };

    _redirectToCashFlow = () => {
        if (isGrantAccessed(this.props.user, grants.ACCOUNTING_SETTLEMENT_JOURNAL)) {
            goTo(book.storageCalculations, {
                documentNumber: _.get(this.props, 'formData.documentNumber')
            });
        }
    };

    fetchUnits = async () => {
        const units = await fetchAPI('GET', 'business/measurement/units', undefined, undefined);
        this.setState({
            units
        });
    };

    getMinDate = async () => {
        const { datetime: minDate } = await fetchAPI('GET', 'store_docs/min_date');
        this.setState({ minDate });
    };

    handleSearchNetworks = _.debounce(value => {
        this.getAllNetworks(value);
    }, 1000);

    getAllNetworks = async value => {
        const response = await fetchAPI('GET', '/businesses/search', { search: value }, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            allNetworks: response
        });
    };

    render() {
        const {
            editKey,
            modalVisible,
            clientSearchValue,
            supplierSearchValue,
            employeeSearchValue,
            counterpartOptionInfo,
            warning,
            productBarcode,
            minDate,
            loading,
            units,
            activeKey,
            allNetworks
        } = this.state;

        const {
            id,
            addDocProduct,
            typeToDocumentType,
            warehouses,
            counterpartSupplier,
            employees,
            brands,
            deleteDocProduct,
            editDocProduct,
            clientList,
            user,
            mainWarehouseId,
            mainAttributeWarehouseId,
            storageWarehouseId,
            reserveWarehouseId,
            toolWarehouseId,
            repairAreaWarehouseId,
            setModal,
            cells,
            businessRequisites,
            fetchSupplierRequisites,
            businessSupplierRequisites,
            fetchClientRequisites,
            clientRequisites,
            getCounterpartSupplier,
            handleSearchCounterpart,
            handleSearchEmployees,
            fetchStorageDocument,
            updateDocument,
            visibleAddOrderFromDocumentModal,
            hideAddOrderModal,
            getClientList,
            handleSearchClient,
            addPartsToOrder,
            supplierNameReq,
            isMobile
        } = this.props;

        const updateFormData = (data, saveMode = false) => {
            this.props.updateFormData(data, saveMode);
            this.setState({});
        };

        const {
            type,
            documentType,
            supplierDocNumber,
            counterpartId,
            docProducts,
            docServices,
            status,
            sum,
            sellingSum,
            sellingSumTax,
            payUntilDatetime,
            incomeWarehouseId,
            businessRequisiteId,
            counterpartRequisiteId,
            businessSupplierRequisiteId,

            clientRequisiteId,
            expenseWarehouseId,
            remainSum,
            sumTax,
            showTax,
            warehouseId,
            comment,
            doneDatetime,
            externalApiOrderStatus,
            createApiOrder,
            externalApiDocId,
            operationCode,
            counterpartClientId,
            counterpartBusinessSupplierId,
            counterpartEmployeeId,
            ctpType,
            orderId,
            docProductUnitId,
            ordNum,
            clientName,
            clientPhone,
            counterpartBusinessSupplierName,
            counterpartEmployeeName,
            counterpartEmployeePhone,
            counterpartBusinessId,
            counterpartWarehouseId
        } = this.props.formData;

        const mapperData = storageDocumentMapper(operationCode);
        const dateFormat = 'DD.MM.YYYY';
        const disabled = status === DONE || status === INTER_CO_DONE;

        const handleWarehouseMapping = (type, value) => {
            const warehouseMapping = {
                INCOME: {
                    incomeWarehouseId: mainWarehouseId,
                    expenseWarehouseId: operationCode === constants.ICI ? counterpartWarehouseId : undefined
                },
                EXPENSE: {
                    incomeWarehouseId: operationCode === constants.ICO ? counterpartWarehouseId : undefined,
                    expenseWarehouseId: mainWarehouseId
                },
                TRANSFER: {
                    incomeWarehouseId: mainAttributeWarehouseId || mainWarehouseId,
                    expenseWarehouseId: mainAttributeWarehouseId || mainWarehouseId
                },
                RESERVE: {
                    incomeWarehouseId: reserveWarehouseId,
                    expenseWarehouseId: mainWarehouseId
                },
                TOOL: {
                    incomeWarehouseId: toolWarehouseId,
                    expenseWarehouseId: repairAreaWarehouseId
                },
                REPAIR_AREA: {
                    incomeWarehouseId: repairAreaWarehouseId,
                    expenseWarehouseId: toolWarehouseId
                },
                PRESERVATION: {
                    incomeWarehouseId: type === INCOME ? storageWarehouseId : undefined,
                    expenseWarehouseId: type === EXPENSE ? storageWarehouseId : undefined
                }
            };

            return warehouseMapping[value] || {};
        };

        const documentId = _.get(this.props, 'formData.id');
        const documentNumber = _.get(this.props, 'formData.documentNumber');

        const counterpartType =
            [ADJUSTMENT, ORDERINCOME].includes(documentType) || ctpType === SUPPLIER_CTP
                ? 'supplier'
                : [TOOL, REPAIR_AREA, OWN_CONSUMPTION].includes(documentType)
                ? 'employee'
                : documentType === CLIENT || ctpType === CLIENT_CTP
                ? 'client'
                : 'supplier' || documentType.toLowerCase();

        const isServiceType = documentType === SERVICE && (type === INCOME || type === EXPENSE);

        const formContent = (
            <Form
                {...formItemLayout}
                style={{
                    margin: '14px 0',
                    padding: '0 0 16px',
                    borderBottom: '1px solid var(--lightGray)'
                }}
            >
                <div
                    style={{
                        margin: isMobile ? '' : '15px 0',
                        padding: '0 0 15px',
                        display: isMobile ? 'block' : 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{ width: isMobile ? '95%' : '20%', marginLeft: isMobile && '2%' }}>
                        <div>
                            <FormattedMessage id='storage.type' />
                            {requiredField()}
                            <Select
                                data-qa='select_type_storage_document_form'
                                disabled={disabled || status === NEW}
                                onChange={value => {
                                    updateFormData({
                                        ...handleWarehouseMapping(type, value),
                                        type: value,
                                        documentType: typeToDocumentType[value.toLowerCase()].documentType[0],
                                        counterpartId: undefined
                                    });
                                }}
                                style={disabledSelectText}
                                value={type}
                            >
                                {[INCOME, EXPENSE, TRANSFER, ORDER].map(type => (
                                    <Option key={type} value={type}>
                                        <FormattedMessage id={`storage.${type}`} />
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <FormattedMessage id='storage_document.document_type' />
                            {requiredField()}
                            <Select
                                data-qa='select_document_type_storage_document_form'
                                disabled={disabled || status === NEW}
                                onChange={value => {
                                    updateFormData({
                                        ...handleWarehouseMapping(type, value),
                                        documentType: value,
                                        counterpartId: undefined,
                                        ctpType: undefined
                                    });
                                }}
                                style={disabledSelectText}
                                value={documentType}
                            >
                                {type &&
                                    typeToDocumentType[type.toLowerCase()].documentType.map(documentType => (
                                        <Option key={documentType} value={documentType}>
                                            <FormattedMessage
                                                id={`storage_document.docType.${type}.${documentType}`}
                                            />
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                        {(documentType === PRESERVATION || documentType === CORRECTIVE_SERVICE) && (
                            <div>
                                <FormattedMessage id='storage_document.counterparty_type' />
                                <Select
                                    disabled={disabled || status === NEW}
                                    onChange={value => updateFormData({ ctpType: value })}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'storage.choose_counterparty'
                                    })}
                                    value={ctpType}
                                >
                                    <Option value={CLIENT_CTP}>
                                        <FormattedMessage id='storage_document.client' />
                                    </Option>
                                    <Option value={SUPPLIER_CTP}>
                                        <FormattedMessage id='storage_document.supplier' />
                                    </Option>
                                </Select>
                            </div>
                        )}
                        {[
                            CLIENT,
                            SUPPLIER,
                            SERVICE,
                            ADJUSTMENT,
                            ORDERINCOME,
                            TOOL,
                            REPAIR_AREA,
                            OWN_CONSUMPTION,
                            CORRECTIVE_SERVICE,
                            PRESERVATION
                        ].includes(documentType) && (
                            <div style={{ position: 'relative' }}>
                                <FormattedMessage id={`storage.${counterpartType}`} />
                                {requiredField()}
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Select
                                        data-qa='select_client_storage_document_form'
                                        disabled={disabled || status === DONE}
                                        filterOption={(input, option) => {
                                            if (
                                                clientSearchValue.length > 2 ||
                                                employeeSearchValue ||
                                                supplierSearchValue
                                            ) {
                                                if (
                                                    [SUPPLIER, SERVICE, ADJUSTMENT, ORDERINCOME].includes(
                                                        documentType
                                                    )
                                                ) {
                                                    return true;
                                                }
                                                const searchValue = option.children
                                                    .toLowerCase()
                                                    .replace(/[+()-\s]/g, '');
        
                                                return searchValue.indexOf(input.toLowerCase()) >= 0;
                                            }
                                        }}
                                        onBlur={() =>
                                            this.setState({
                                                clientSearchValue: '',
                                                employeeSearchValue: '',
                                                supplierSearchValue: ''
                                            })
                                        }
                                        onChange={(value, option) => {
                                            updateFormData({
                                                counterpartId: value
                                            });
                                            this.setState({
                                                counterpartOptionInfo: {
                                                    value,
                                                    children: String(option.children)
                                                }
                                            });
        
                                            if (
                                                [SUPPLIER, SERVICE, ADJUSTMENT, ORDERINCOME].includes(
                                                    documentType
                                                ) ||
                                                ctpType === SUPPLIER_CTP
                                            ) {
                                                fetchSupplierRequisites(value);
                                                getCounterpartSupplier();
                                            }
                                            if (documentType === CLIENT || ctpType === CLIENT_CTP) {
                                                fetchClientRequisites(value);
                                            }
                                            if (status === NEW) {
                                                updateDocument();
                                            }
                                        }}
                                        onFocus={() =>
                                            this.setState({
                                                clientSearchValue: '',
                                                employeeSearchValue: '',
                                                supplierSearchValue: ''
                                            })
                                        }
                                        onSearch={input => {
                                            if (
                                                [SUPPLIER, SERVICE, ADJUSTMENT, ORDERINCOME].includes(documentType)
                                            ) {
                                                this.setState({ supplierSearchValue: input });
                                                handleSearchCounterpart(input);
                                            } else if (
                                                [TOOL, REPAIR_AREA, OWN_CONSUMPTION].includes(documentType)
                                            ) {
                                                this.setState({ employeeSearchValue: input });
                                                handleSearchEmployees(input);
                                            } else {
                                                this.setState({ clientSearchValue: input });
                                                if (input.length > 2) handleSearchClient(input);
                                            }
                                        }}
                                        showSearch
                                        style={disabledSelectText}
                                        value={counterpartId}
                                    >
                                        {[SUPPLIER, SERVICE, ADJUSTMENT, ORDERINCOME].includes(documentType) ||
                                        ctpType === SUPPLIER_CTP
                                            ? counterpartId
                                                ? buildSupplierOptions(counterpartSupplier, {
                                                      id: counterpartId,
                                                      name: counterpartBusinessSupplierName || supplierNameReq
                                                  }).map(elem => (
                                                      <Option key={elem.id} value={elem.id}>
                                                          {elem.name}
                                                      </Option>
                                                  ))
                                                : counterpartSupplier.map(elem => (
                                                      <Option key={elem.id} value={elem.id}>
                                                          {elem.name}
                                                      </Option>
                                                  ))
                                            : null}
                                        {[TOOL, REPAIR_AREA, OWN_CONSUMPTION].includes(documentType)
                                            ? counterpartId
                                                ? buildSupplierOptions(employees, {
                                                      id: counterpartId,
                                                      name: `${counterpartEmployeeName} ${counterpartEmployeePhone}`
                                                  }).map(employee => (
                                                      <Option key={employee.id} value={employee.id}>
                                                          {`${employee.surname || ''} ${employee.name || ''} ${
                                                              employee.phone
                                                          }`}
                                                      </Option>
                                                  ))
                                                : employees.map(employee => (
                                                      <Option key={employee.id} value={employee.id}>
                                                          {`${employee.surname || ''} ${employee.name || ''} ${
                                                              employee.phone
                                                          }`}
                                                      </Option>
                                                  ))
                                            : null}
                                        {documentType === CLIENT || ctpType === CLIENT_CTP ? (
                                            clientSearchValue.length > 2 ? (
                                                clientList
                                                    .filter(
                                                        (client, index, self) =>
                                                            self.findIndex(c => c.clientId === client.clientId) ===
                                                            index
                                                    )
                                                    .map(client => (
                                                        <Option key={client.clientId} value={client.clientId}>
                                                            {`${client.surname || ''} ${client.name} ${
                                                                client.middleName || ''
                                                            } ${client.phones[0]}`}
                                                        </Option>
                                                    ))
                                            ) : clientName ? (
                                                <Option value={counterpartId}>
                                                    {clientName} {_.get(clientPhone, '0')}
                                                </Option>
                                            ) : null
                                        ) : null}
                                    </Select>
        
                                    <Tooltip title={<FormattedMessage id='edit' />}>
                                        <Button
                                            className={Styles.editIcon}
                                            disabled={
                                                !counterpartClientId &&
                                                !counterpartBusinessSupplierId &&
                                                !counterpartEmployeeId
                                            }
                                            icon={<EditOutlined />}
                                            onClick={() => {
                                                goTo(
                                                    counterpartClientId
                                                        ? `${book.client}/${counterpartClientId}`
                                                        : counterpartBusinessSupplierId
                                                        ? `${book.supplier}/${counterpartBusinessSupplierId}`
                                                        : `${book.employeesPage}/${counterpartEmployeeId}`
                                                );
                                            }}
                                        />
                                    </Tooltip>
        
                                    {[SUPPLIER, SERVICE, ADJUSTMENT, ORDERINCOME].includes(documentType) &&
                                        !(disabled || status === NEW) && (
                                            <PlusOutlined
                                                className={Styles.addIcon}
                                                onClick={() => this.props.setModal(MODALS.SUPPLIER)}
                                            />
                                        )}
        
                                    {[PRESERVATION, CLIENT].includes(documentType) &&
                                        !(disabled || status === NEW || ctpType === SUPPLIER_CTP) && (
                                            <PlusOutlined
                                                className={Styles.addIcon}
                                                data-qa='add_client_storage_document_form'
                                                onClick={() => this.props.setModal(MODALS.ADD_CLIENT)}
                                            />
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
        
                    <div
                        style={{
                            width: isMobile ? '95%' : '20%',
                            marginLeft: isMobile && '2%'
                        }}
                    >
                        {type === TRANSFER && (
                            <div>
                                <FormattedMessage id='refactored_cash_order_modal.sender' />
                                <Select
                                    allowClear
                                    disabled
                                    filterOption={false}
                                    onSearch={input => this.handleSearchNetworks(input)}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'refactored_cash_order_modal.sender'
                                    })}
                                    showSearch
                                    style={{
                                        width: '100%',
                                        marginRight: 8
                                    }}
                                    value={user.businessId}
                                >
                                    <Option key={user.businessId} value={user.businessId}>
                                        {user && user.businessName} - {user && user.businessId}
                                    </Option>
                                </Select>
                            </div>
                        )}
                        <div>
                            {type === TRANSFER ? (
                                <FormattedMessage id='storage' />
                            ) : (
                                <FormattedMessage id='storage_document.storage_expenses' />
                            )}
                            {(type == EXPENSE || type == TRANSFER) && requiredField()}
                            <Select
                                data-qa='select_warehouse_expenses_storage_document_form'
                                disabled={
                                    type == INCOME ||
                                    type == ORDER ||
                                    documentType == TOOL ||
                                    documentType == REPAIR_AREA ||
                                    disabled
                                }
                                onSelect={(value, option) => {
                                    updateFormData({
                                        expenseWarehouseId: value
                                    });
                                }}
                                style={disabledSelectText}
                                value={expenseWarehouseId}
                            >
                                {warehouses.map(elem => {
                                    return (
                                        <Option
                                            key={elem.id}
                                            disabled={
                                                type !== TRANSFER &&
                                                (elem.attribute === 'RESERVE' ||
                                                    (elem.attribute === 'REPAIR_AREA' && type !== 'TRANSFER') ||
                                                    (operationCode &&
                                                        elem.attribute === 'STORAGE' &&
                                                        !showStorageWarehouses.includes(operationCode)) ||
                                                    (operationCode &&
                                                        showStorageWarehouses.includes(operationCode) &&
                                                        elem.attribute !== 'STORAGE') ||
                                                    (documentType === PRESERVATION &&
                                                        elem.attribute !== 'STORAGE') ||
                                                    (elem.businessRequisiteId &&
                                                        businessRequisiteId &&
                                                        elem.businessRequisiteId != businessRequisiteId))
                                            }
                                            value={elem.id}
                                        >
                                            {elem.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {type === TRANSFER && (
                                <div>
                                    <FormattedMessage id='storage_document.business_requisites' />
                                    <Select
                                        allowClear
                                        data-qa='select_business_requisites_storage_document_form'
                                        disabled={disabled}
                                        // onChange={value => {
                                        //     if (!value) {
                                        //         updateFormData({
                                        //             businessRequisiteId: null
                                        //         });
                                        //     }
                                        // }}
                                        onChange={async value => {
                                            if (!value) {
                                                updateFormData({
                                                    businessRequisiteId: null
                                                });
                                            }
                                            await updateFormData({
                                                businessRequisiteId: value
                                            });
                                            if (status == NEW) {
                                                await updateDocument();
                                            }
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'storage_document.business_requisites'
                                        })}
                                        style={disabledSelectText}
                                        value={businessRequisiteId}
                                    >
                                        {businessRequisites
                                            .filter(({ enabled }) => enabled)
                                            .map(elem => {
                                                return (
                                                    <Option key={elem.id} value={elem.id}>
                                                        {elem.name}
                                                        {elem.isTaxPayer && (
                                                            <span
                                                                style={{
                                                                    marginLeft: 8,
                                                                    color: 'var(--text2)'
                                                                }}
                                                            >
                                                                (<FormattedMessage id='with_VAT' />)
                                                            </span>
                                                        )}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </div>
                            )}
                        </div>
                        {type !== TRANSFER && (
                            <div>
                                <FormattedMessage id='storage_document.storage_income' />
                                {(type == INCOME || type == TRANSFER) && requiredField()}
                                <Select
                                    data-qa='select_warehouse_income_storage_document_form'
                                    disabled={
                                        type == EXPENSE ||
                                        (type == ORDER && documentType != ORDERINCOME) ||
                                        [RESERVE, TOOL, REPAIR_AREA].includes(documentType) ||
                                        disabled
                                    }
                                    onSelect={value => {
                                        updateFormData({
                                            incomeWarehouseId: value
                                        });
                                    }}
                                    style={disabledSelectText}
                                    value={incomeWarehouseId}
                                >
                                    {warehouses.map(elem => {
                                        return (
                                            <Option
                                                key={elem.id}
                                                disabled={
                                                    elem.attribute === 'RESERVE' ||
                                                    (elem.attribute === 'REPAIR_AREA' && type !== 'TRANSFER') ||
                                                    (operationCode &&
                                                        elem.attribute === 'STORAGE' &&
                                                        !showStorageWarehouses.includes(operationCode)) ||
                                                    (operationCode &&
                                                        showStorageWarehouses.includes(operationCode) &&
                                                        elem.attribute !== 'STORAGE') ||
                                                    (documentType === PRESERVATION &&
                                                        elem.attribute !== 'STORAGE') ||
                                                    (elem.businessRequisiteId &&
                                                        businessRequisiteId &&
                                                        elem.businessRequisiteId !== businessRequisiteId)
                                                }
                                                value={elem.id}
                                            >
                                                {elem.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        )}
                        {(type == INCOME || type == EXPENSE || type == ORDER || type == TRANSFER) && (
                            <div>
                                <FormattedMessage id='storage.document_num' />
                                <Input
                                    data-qa='enter_document_num_storage_document_form'
                                    onChange={event => {
                                        updateFormData({
                                            supplierDocNumber: event.target.value
                                        });
                                        this.setState({
                                            update: true
                                        });
                                    }}
                                    style={{ color: 'var(--text3)' }}
                                    value={supplierDocNumber}
                                />
                            </div>
                        )}
                    </div>
        
                    <div
                        style={{
                            width: isMobile ? '95%' : '20%',
                            marginLeft: isMobile && '2%'
                        }}
                    >
                        {type === TRANSFER && (
                            <div>
                                <div>
                                    <FormattedMessage id='refactored_cash_order_modal.receiver' />
                                    <Select
                                        allowClear
                                        disabled
                                        filterOption={false}
                                        onChange={async value => {
                                            if (!value) {
                                                await updateFormData({
                                                    counterpartBusinessId: undefined
                                                });
                                            }
                                        }}
                                        onSearch={input => this.handleSearchNetworks(input)}
                                        onSelect={async value => {
                                            await updateFormData({
                                                counterpartBusinessId: value
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'refactored_cash_order_modal.receiver'
                                        })}
                                        showSearch
                                        style={{
                                            width: '100%',
                                            marginRight: 8
                                        }}
                                        value={user.businessId || counterpartBusinessId}
                                    >
                                        {get(allNetworks, 'length') && user.businessesAccess ? (
                                            allNetworks.map(elem => (
                                                <Option
                                                    key={elem.businessId || elem.id}
                                                    value={elem.businessId || elem.id}
                                                >
                                                    {`${elem.name} - ${elem.businessId || elem.id}`}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option key={user.businessId} value={user.businessId}>
                                                {user && user.businessName} - {user && user.businessId}
                                            </Option>
                                        )}
                                    </Select>
                                </div>
                                <div>
                                    <FormattedMessage id='storage' />
                                    {type == TRANSFER && requiredField()}
                                    <Select
                                        data-qa='select_warehouse_income_storage_document_form'
                                        disabled={
                                            type == EXPENSE ||
                                            (type == ORDER && documentType != ORDERINCOME) ||
                                            [RESERVE, TOOL, REPAIR_AREA].includes(documentType) ||
                                            disabled
                                        }
                                        onSelect={value => {
                                            updateFormData({
                                                incomeWarehouseId: value
                                            });
                                        }}
                                        style={disabledSelectText}
                                        value={incomeWarehouseId}
                                    >
                                        {warehouses.map(elem => {
                                            return (
                                                <Option
                                                    key={elem.id}
                                                    disabled={
                                                        type !== TRANSFER &&
                                                        (elem.attribute === 'RESERVE' ||
                                                            (elem.attribute === 'REPAIR_AREA' &&
                                                                type !== 'TRANSFER') ||
                                                            (operationCode &&
                                                                elem.attribute === 'STORAGE' &&
                                                                !showStorageWarehouses.includes(operationCode)) ||
                                                            (operationCode &&
                                                                showStorageWarehouses.includes(operationCode) &&
                                                                elem.attribute !== 'STORAGE') ||
                                                            (documentType === PRESERVATION &&
                                                                elem.attribute !== 'STORAGE') ||
                                                            (elem.businessRequisiteId &&
                                                                businessRequisiteId &&
                                                                elem.businessRequisiteId !== businessRequisiteId))
                                                    }
                                                    value={elem.id}
                                                >
                                                    {elem.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        )}
        
                        <div>
                            <FormattedMessage id='storage_document.business_requisites' />
                            <Select
                                allowClear
                                data-qa='select_business_requisites_storage_document_form'
                                disabled={disabled}
                                // onChange={value => {
                                //     if (!value) {
                                //         updateFormData({
                                //             counterpartRequisiteId: null
                                //         });
                                //     }
                                // }}
                                onChange={async value => {
                                    if (!value) {
                                        updateFormData({
                                            counterpartRequisiteId: null
                                        });
                                    }
                                    
                                    if (type === TRANSFER) {
                                        await updateFormData({
                                            counterpartRequisiteId: value
                                        });
                                        if (status == NEW) {
                                            await updateDocument();
                                        }
                                    } else {
                                        await updateFormData({
                                            businessRequisiteId: value,
                                            incomeWarehouseId: undefined,
                                            expenseWarehouseId: undefined
                                        });
                                        if (status == NEW) {
                                            await updateDocument();
                                        }
                                    }
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'storage_document.business_requisites'
                                })}
                                style={disabledSelectText}
                                value={
                                    type === TRANSFER || operationCode == constants.ICI
                                        ? counterpartRequisiteId
                                        : businessRequisiteId
                                }
                            >
                                {businessRequisites
                                    .filter(({ enabled }) => enabled)
                                    .map(elem => {
                                        return (
                                            <Option key={elem.id} value={elem.id}>
                                                {elem.name}
                                                {elem.isTaxPayer && (
                                                    <span
                                                        style={{
                                                            marginLeft: 8,
                                                            color: 'var(--text2)'
                                                        }}
                                                    >
                                                        (<FormattedMessage id='with_VAT' />)
                                                    </span>
                                                )}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
        
                        {([SUPPLIER, SERVICE, ADJUSTMENT, ORDERINCOME].includes(documentType) ||
                            (documentType === PRESERVATION && ctpType === SUPPLIER_CTP)) && (
                            <div>
                                <FormattedMessage id='storage_document.business_supplier_requisites' />
                                <Select
                                    allowClear
                                    data-qa='select_business_supplier_requisites_storage_document_form'
                                    disabled={disabled}
                                    onChange={async value => {
                                        await updateFormData({
                                            businessSupplierRequisiteId: value
                                        });
                                        if (status == NEW) {
                                            await updateDocument();
                                        }
                                    }}
                                    style={disabledSelectText}
                                    value={
                                        operationCode == constants.ICI
                                            ? businessRequisiteId
                                            : businessSupplierRequisiteId
                                    }
                                >
                                    {operationCode == constants.ICI
                                        ? businessRequisites
                                              .filter(({ enabled }) => enabled)
                                              .map(elem => {
                                                  return (
                                                      <Option key={elem.id} value={elem.id}>
                                                          {elem.name}
                                                          {elem.isTaxPayer && (
                                                              <span
                                                                  style={{
                                                                      marginLeft: 8,
                                                                      color: 'var(--text2)'
                                                                  }}
                                                              >
                                                                  (<FormattedMessage id='with_VAT' />)
                                                              </span>
                                                          )}
                                                      </Option>
                                                  );
                                              })
                                        : businessSupplierRequisites
                                              .filter(({ enabled }) => enabled)
                                              .map((elem, i) => {
                                                  return (
                                                      <Option key={i} value={elem.id}>
                                                          {elem.name}
                                                          {elem.isTaxPayer && (
                                                              <span
                                                                  style={{
                                                                      marginLeft: 8,
                                                                      color: 'var(--text2)'
                                                                  }}
                                                              >
                                                                  (<FormattedMessage id='with_VAT' />)
                                                              </span>
                                                          )}
                                                      </Option>
                                                  );
                                              })}
                                </Select>
                            </div>
                        )}
        
                        {(documentType == CLIENT || (documentType == PRESERVATION && ctpType == CLIENT_CTP)) && (
                            <div>
                                <FormattedMessage id='storage_document.clients_requisites' />
                                <Select
                                    allowClear
                                    data-qa='select_clients_ruquisitites_storage_document_form'
                                    disabled={disabled}
                                    onChange={async value => {
                                        await updateFormData({
                                            clientRequisiteId: value
                                        });
                                        if (status == NEW) {
                                            await updateDocument();
                                        }
                                    }}
                                    style={disabledSelectText}
                                    value={
                                        operationCode == constants.ICO ? counterpartRequisiteId : clientRequisiteId
                                    }
                                >
                                    {operationCode == constants.ICO
                                        ? businessRequisites
                                              .filter(({ enabled }) => enabled)
                                              .map(elem => {
                                                  return (
                                                      <Option key={elem.id} value={elem.id}>
                                                          {elem.name}
                                                          {elem.isTaxPayer && (
                                                              <span
                                                                  style={{
                                                                      marginLeft: 8,
                                                                      color: 'var(--text2)'
                                                                  }}
                                                              >
                                                                  (<FormattedMessage id='with_VAT' />)
                                                              </span>
                                                          )}
                                                      </Option>
                                                  );
                                              })
                                        : clientRequisites.map((elem, i) => {
                                              return (
                                                  <Option key={i} value={elem.id}>
                                                      {elem.name}
                                                      {elem.isTaxPayer && (
                                                          <span
                                                              style={{
                                                                  marginLeft: 8,
                                                                  color: 'var(--text2)'
                                                              }}
                                                          >
                                                              (<FormattedMessage id='with_VAT' />)
                                                          </span>
                                                      )}
                                                  </Option>
                                              );
                                          })}
                                </Select>
                            </div>
                        )}
                        {documentType == SUPPLIER && type == ORDER && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end',
                                    height: externalApiDocId ? 53 : 48
                                }}
                            >
                                {externalApiDocId ? (
                                    <Button
                                        data-qa='recevce_api_order_storage_document_form'
                                        onClick={async () => {
                                            await fetchAPI(
                                                'POST',
                                                'store_docs/receive_external_api_order',
                                                undefined,
                                                { externalApiDocId },
                                                { handleErrorInternally: true }
                                            );
                                            await notification.success({
                                                message: this.props.intl.formatMessage({
                                                    id: 'barcode.success'
                                                })
                                            });
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <FormattedMessage id='storage_document.recicve_api_order' />
                                    </Button>
                                ) : (
                                    <React.Fragment>
                                        <FormattedMessage id='storage_document.order_thought_api' />
                                        <Checkbox
                                            checked={createApiOrder || externalApiOrderStatus == 'SENT'}
                                            data-qa='order_thought_api_storage_document_form'
                                            disabled={externalApiOrderStatus}
                                            onChange={value => {
                                                updateFormData({
                                                    createApiOrder: !createApiOrder
                                                });
                                            }}
                                            style={{ padding: '0 0 0 8px' }}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </div>
        
                    <div
                        style={{
                            width: isMobile ? '95%' : '30%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginTop: isMobile && '2%'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    paddingRight: 8,
                                    marginLeft: isMobile && '2%'
                                }}
                            >
                                <div className={Styles.sumWrapper} data-qa='purchase_sum_storage_document_form'>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='storage_gocument.purch_sum_without_VAT' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask={mask}
                                        nullText='0'
                                    >
                                        {parseFloat(Number(sum).toFixed(2))}
                                    </Numeral>
                                </div>
        
                                <div className={Styles.sumWrapper} data-qa='selling_sum_storage_document_form'>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='storage_gocument.purch_sum_with_VAT' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask={mask}
                                        nullText='0'
                                    >
                                        {parseFloat(Number(sumTax).toFixed(2))}
                                    </Numeral>
                                </div>
        
                                <div className={Styles.sumWrapper} data-qa='sum_tax_storage_document_form'>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='storage_gocument.sell_sum_without_VAT' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask={mask}
                                        nullText='0'
                                    >
                                        {parseFloat(Number(sellingSum).toFixed(2))}
                                    </Numeral>
                                </div>
        
                                <div className={Styles.sumWrapper} data-qa='paid_storage_document_form'>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <FormattedMessage id='storage_gocument.sell_sum_with_VAT' />
                                    </span>
                                    <Numeral
                                        className={Styles.sumNumeral}
                                        currency={getCurrency()}
                                        mask={mask}
                                        nullText='0'
                                    >
                                        {parseFloat(Number(sellingSumTax).toFixed(2))}
                                    </Numeral>
                                </div>
                            </div>
                            <div
                                className={Styles.sumWrapper}
                                data-qa='remain_storage_document_form'
                                onClick={() => this._redirectToCashFlow()}
                                style={{
                                    background: 'var(--static)',
                                    fontSize: 16,
                                    height: 'auto',
                                    width: '65%',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    padding: '0 12px',
                                    cursor: 'pointer'
                                }}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        color: null
                                    }}
                                >
                                    <FormattedMessage id='remain' />
                                    <span className={Styles.remainSum} onClick={() => this._redirectToCashFlow()}>
                                        <Numeral
                                            className={Styles.totalSum}
                                            currency={getCurrency()}
                                            mask={mask}
                                            nullText='0'
                                        >
                                            {remainSum}
                                        </Numeral>
                                    </span>
                                </div>
                                <div
                                    data-qa='paid_storage_document_form'
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    <FormattedMessage id='paid' />
                                    <span className={Styles.remainSum} onClick={() => this._redirectToCashFlow()}>
                                        <Numeral
                                            className={Styles.totalSum}
                                            currency={getCurrency()}
                                            mask={mask}
                                            nullText='0'
                                        >
                                            {parseFloat(Number(sellingSumTax - remainSum).toFixed(2))}
                                        </Numeral>
                                    </span>
                                </div>
                            </div>
                        </div>
        
                        {(type === INCOME || type === EXPENSE || type === ORDER) && (
                            <div
                                data-qa='date_pay_until_storage_document_form'
                                style={{ marginTop: 16, marginLeft: isMobile && '2%', width: '100%' }}
                            >
                                <FormattedMessage id='storage_document.pay_until' />
                                <DatePicker
                                    defaultValue={payUntilDatetime}
                                    disabled={disabled}
                                    format={dateFormat}
                                    onChange={(date, stringDate) => {
                                        updateFormData({
                                            payUntilDatetime: date
                                        });
                                    }}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        padding: '0 10px 15px 10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: isMobile ? 'column' : 'row'
                    }}
                >
                    <div
                        data-qa='comment_storage_document_form'
                        style={{
                            width: isMobile ? '100%' : '67.5%',
                            order: isMobile ? 1 : 0
                        }}
                    >
                        <FormattedMessage id='comment' />
                        <Input.TextArea
                            disabled={
                                disabled &&
                                !isGrantAccessed(
                                    user,
                                    grants.WAREHOUSE_DOCUMENT_HEADER_CLOSED_DOCUMENT_EDITING_DATE_COMMENT
                                )
                            }
                            onChange={({ target }) => {
                                updateFormData({
                                    comment: target.value
                                });
                                this.setState({
                                    update: true
                                });
                            }}
                            type='textarea'
                            value={comment}
                        />
                    </div>
                    <div data-qa='choose_date_storage_document_form' style={{ width: isMobile ? '100%' : '30%' }}>
                        <FormattedMessage id='date' />
                        <DatePicker
                            defaultValue={doneDatetime || dayjs()}
                            disabled={
                                disabled &&
                                !isGrantAccessed(
                                    user,
                                    grants.WAREHOUSE_DOCUMENT_HEADER_CLOSED_DOCUMENT_EDITING_DATE_COMMENT
                                )
                            }
                            disabledDate={date => {
                                if (status == NEW) return false;
                                if (date > dayjs() || date < dayjs(minDate)) {
                                    return true;
                                }
                            }}
                            format={dateFormat}
                            onChange={(date, stringDate) => {
                                updateFormData({
                                    doneDatetime: date
                                });
                            }}
                            style={{
                                width: '100%'
                            }}
                        />
                    </div>
                </div>
            </Form>
        )

        return (
            <div>
                {(!isMobile || !documentId) && formContent}
                
                {documentId && (
                    <div
                        id='OrderTabs'
                        // className={Styles.tabsContainer}
                        style={{
                            background: 'white'
                        }}
                    >
                        <Tabs
                            className={Styles.orderFormsTabs}
                            activeKey={this.state.activeKey}
                            onTabClick={key => {
                                this.setState({
                                    activeKey: key,
                                    action: undefined
                                });
                            }}
                            size='small'
                            type='card'
                        >
                            {isMobile && (
                                <TabPane
                                    key='info'
                                    tab={
                                        <div className={Styles.tabStyle}>
                                            <InfoOutlined className={Styles.iconsStyle} />
                                            <div>
                                                {`${this.props.intl.formatMessage({
                                                    id: 'add_order_form.general'
                                                })}`}
                                            </div>
                                        </div>
                                    }
                                >
                                    {formContent}
                                </TabPane> 
                            )}

                            {isServiceType || operationCode === 'MAN' ? (
                                <TabPane
                                    key='services'
                                    tab={
                                        <div className={Styles.tabStyle}>
                                            <RepairIcon className={Styles.iconsStyle} />
                                            <div>
                                                {`${this.props.intl.formatMessage({
                                                    id: 'add_order_form.services'
                                                })}`}
                                            </div>
                                        </div>
                                    }
                                >
                                    <StoreDocServicesTable
                                        disabled={disabled || !status}
                                        docServices={docServices}
                                        handleStoreDocServices={this.props.handleStoreDocServices}
                                        // getStorageDocument={this.props.getStorageDocument}
                                        mapperData={mapperData}
                                        storeDocId={this.props.formData.id}
                                    />
                                </TabPane>
                            ) : (
                                isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT_LINES) && ( // show if storeDocId
                                    <TabPane
                                        key='spare-parts'
                                        tab={
                                            <div className={Styles.tabStyle}>
                                                <SparePartsIcon className={Styles.iconsStyle} />
                                                <div>
                                                    {this.props.intl.formatMessage({
                                                        id: 'add_order_form.details'
                                                    })}
                                                </div>
                                            </div>
                                        }
                                    >
                                        <DocProductsTable
                                            addByBarcode={code => this.addByBarcode(code)}
                                            addPartsToOrder={addPartsToOrder}
                                            barcodeFinish={() => {
                                                addDocProduct(this.state.products, true);
                                                // setTimeout(() => this.setState({
                                                //     products: []
                                                // }), 500);

                                                this.setState({
                                                    products: []
                                                });
                                            }}
                                            deleteDocProduct={deleteDocProduct}
                                            disabled={disabled || !status}
                                            docProducts={docProducts}
                                            documentType={documentType}
                                            editProduct={this.editProduct}
                                            fetchStorageDocument={fetchStorageDocument}
                                            id={id}
                                            loading={loading}
                                            operationCode={operationCode}
                                            orderId={orderId}
                                            showModal={this.showModal}
                                            type={type}
                                            updateFormData={updateFormData}
                                        />
                                    </TabPane>
                                )
                            )}
                            {type === INCOME && (
                                <TabPane
                                    key='extra-spendings'
                                    tab={
                                        <div className={Styles.tabStyle}>
                                            <DollarOutlined className={Styles.iconsStyle} />
                                            <div>
                                                {this.props.intl.formatMessage({
                                                    id: 'extra_spendings'
                                                })}
                                            </div>
                                        </div>
                                    }
                                >
                                    <AdditionalSpendingsTab
                                        disabled={disabled}
                                        fetchStorageDocument={fetchStorageDocument}
                                        storeDocId={documentId}
                                    />
                                </TabPane>
                            )}

                            <TabPane
                                key='tasks'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <TasksIcon className={Styles.iconsStyle} />
                                        <div>
                                            {this.props.intl.formatMessage({
                                                id: 'order_form_table.order_tasks'
                                            })}
                                        </div>
                                    </div>
                                }
                            >
                                <TasksTab
                                    activeKey={activeKey}
                                    counterpartBusinessSupplierId={counterpartBusinessSupplierId}
                                    counterpartBusinessSupplierName={counterpartBusinessSupplierName}
                                    counterparts={{
                                        counterpartBusinessSupplierId,
                                        counterpartClientId,
                                        counterpartEmployeeId
                                    }}
                                    counterpartType={counterpartType}
                                    storeDocId={documentId}
                                />
                            </TabPane>
                            <TabPane
                                key='logs'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <LogsIcon className={Styles.iconsStyle} />
                                        <div>
                                            {this.props.intl.formatMessage({
                                                id: 'storage_logs'
                                            })}
                                        </div>
                                    </div>
                                }
                            >
                                <LogsTab
                                    activeKey={activeKey}
                                    documentId={documentId}
                                    employees={employees}
                                    isServiceType={isServiceType}
                                />
                            </TabPane>
                            <TabPane
                                key='media'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <VideoCameraAddOutlined className={Styles.iconsStyle} />
                                        {/* // add icon to icons.js */}
                                        <div>
                                            {this.props.intl.formatMessage({
                                                id: 'settings_page.media'
                                            })}{' '}
                                        </div>
                                    </div>
                                }
                            >
                                <StoreDocMediaTab
                                    activeKey={activeKey}
                                    documentId={documentId}
                                    documentNumber={documentNumber}
                                />
                            </TabPane>
                            <TabPane
                                key='documents'
                                tab={
                                    <div className={Styles.tabStyle}>
                                        <DocumentsIcon className={Styles.iconsStyle} />
                                        <div>
                                            {this.props.intl.formatMessage({
                                                id: 'add_order_form.docs'
                                            })}{' '}
                                        </div>
                                    </div>
                                }
                            >
                                <DocumentsTab activeKey={activeKey} documentId={documentId} />
                            </TabPane>
                        </Tabs>

                        {!disabled && <StoreDocServiceModal mapperData={mapperData} units={units} />}

                        {!disabled && (
                            <AddProductModal
                                addDocProduct={addDocProduct}
                                brands={brands}
                                businessSupplierId={type === ORDER || type === INCOME ? counterpartId : null}
                                cells={cells}
                                counterpartId={counterpartId}
                                documentType={documentType}
                                editDocProduct={editDocProduct}
                                hideModal={this.hideModal}
                                id={id}
                                incomeWarehouseId={incomeWarehouseId}
                                isMobile={this.props.isMobile}
                                mapperData={mapperData}
                                maxOrdered={type === ORDER && documentType === ADJUSTMENT}
                                operationCode={operationCode}
                                priceDisabled={
                                    type === TRANSFER ||
                                    documentType === OWN_CONSUMPTION ||
                                    documentType === INVENTORY ||
                                    documentType === PRODUCTION ||
                                    documentType === DISASSEMBLY
                                }
                                product={editKey !== undefined ? docProducts[editKey] : undefined}
                                saveModal={saveModal}
                                setModal={setModal}
                                type={type}
                                units={units}
                                user={user}
                                visible={modalVisible}
                                warehouseId={warehouseId}
                                warning={warning}
                            />
                        )}
                    </div>
                )}
                <SetBarcodeModal
                    barcode={productBarcode}
                    confirmAction={productId => this.addProductById(productId)}
                    hideModal={() => {
                        this.setState({
                            productBarcode: undefined
                        });
                    }}
                    visible={Boolean(productBarcode)}
                />
                <SupplierModal
                    func={counterpartId => {
                        this.props.updateCounterpartData(counterpartId);
                    }}
                />

                <AddClientModal
                    func={clientId => {
                        this.props.updateCounterpartClientData(clientId);
                    }}
                    resetModal={this.props.resetModal}
                    visible={this.props.modal}
                />
                <AddOrderFromDocumentModal
                    clientName={clientName}
                    clientPhone={clientPhone}
                    counterpartId={counterpartId}
                    docProducts={docProducts}
                    hideModal={hideAddOrderModal}
                    id={id}
                    products={this.state.products}
                    updateDocument={updateDocument}
                    visible={visibleAddOrderFromDocumentModal}
                />
            </div>
        );
    }
}

export default StorageDocumentForm;
