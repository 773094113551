import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchServicesSuggestionsSuccess, FETCH_SERVICES_SUGGESTIONS, selectFilters } from './duck';

export function* fetchServicesSaga() {
    while (true) {
        try {
            yield take(FETCH_SERVICES_SUGGESTIONS);
            yield nprogress.start();

            const filters = yield select(selectFilters);

            const data = yield call(fetchAPI, 'GET', 'services/parts/suggestions', filters);
            yield put(fetchServicesSuggestionsSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([call(fetchServicesSaga)]);
}
