exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__foundEntry---g0uLc{color:#1abb1a}.styles-m__bold---3vuLq{font-weight:700}.styles-m__italic---3nv4_{font-style:italic}.styles-m__iconOk---12BwI{color:green;font-size:20px}.styles-m__iconCancle---1PGP0{color:red;font-size:20px}.styles-m__optionsDangerIconStyle---1EUTF{font-size:14px;color:#eb8c86}.styles-m__optionsWarningIconStyle---1s56H{font-size:14px;color:#f0a871}.styles-m__optionsSuccessIconStyle---2U8Lc{font-size:14px;color:#83c468}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACI,aACJ,CAEA,wBACI,eACJ,CAEA,0BACI,iBACJ,CAEA,0BACI,WAAY,CACZ,cACJ,CAEA,8BACI,SAAU,CACV,cACJ,CAEA,0CACI,cAAe,CACf,aACJ,CAEA,2CACI,cAAe,CACf,aACJ,CAEA,2CACI,cAAe,CACf,aACJ","file":"styles.m.css","sourcesContent":[".foundEntry {\n    color: rgb(26, 187, 26);\n}\n\n.bold {\n    font-weight: 700;\n}\n\n.italic {\n    font-style: italic\n}\n\n.iconOk {\n    color: green;\n    font-size: 20px;\n}\n\n.iconCancle {\n    color: red;\n    font-size: 20px;\n}\n\n.optionsDangerIconStyle {\n    font-size: 14px;\n    color: #eb8c86\n}\n\n.optionsWarningIconStyle {\n    font-size: 14px;\n    color: #f0a871\n}\n\n.optionsSuccessIconStyle {\n    font-size: 14px;\n    color: #83c468\n}"]}]);

// Exports
exports.locals = {
	"foundEntry": "styles-m__foundEntry---g0uLc",
	"bold": "styles-m__bold---3vuLq",
	"italic": "styles-m__italic---3nv4_",
	"iconOk": "styles-m__iconOk---12BwI",
	"iconCancle": "styles-m__iconCancle---1PGP0",
	"optionsDangerIconStyle": "styles-m__optionsDangerIconStyle---1EUTF",
	"optionsWarningIconStyle": "styles-m__optionsWarningIconStyle---1s56H",
	"optionsSuccessIconStyle": "styles-m__optionsSuccessIconStyle---2U8Lc"
};