import { Button, Input, Popover } from 'antd';
import { Numeral } from 'commons';
import { FormattedDatetime } from 'components';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PistonIcon } from 'theme';
import { foundStringParser } from 'utils';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    order: '12%',
    datetime: '7%',
    odometer: '7%',
    name: '10%',
    brandName: '10%',
    code: '10%',
    supplierName: '7%',
    purchasePrice: '6%',
    price: '6%',
    count: '4%',
    sum: '4%',
    units: '4%',
    actions: '4%'
};

export function columnsConfig(props) {
    const { onAddDetailToOrder, onCodeSearch, onBrandSearch, onNameSearch, onSupplierSearch, disabled } = props;

    const orderCol = {
        title: <FormattedMessage id='orders.order' />,
        width: defWidth.order,
        dataIndex: 'orderNum',
        key: 'orderId',
        sorter: true,
        ender: orderNum => <div>{orderNum}</div>
    };

    const datetimeCol = {
        title: <FormattedMessage id='orders.record_date' />,
        width: defWidth.datetime,
        dataIndex: 'orderDatetime',
        key: 'datetime',
        sorter: true,
        render: orderDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={orderDatetime} format={DEFAULT_DATETIME} />
            </React.Fragment>
        )
    };

    const odometerCol = {
        title: <FormattedMessage id='orders.odometer' />,
        width: defWidth.odometer,
        dataIndex: 'odometer',
        key: 'odometer',
        render: odometer => <div>{odometer || <FormattedMessage id='long_dash' />}</div>
    };

    const codeCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.product_code' />
                    <Input
                        onChange={e => onCodeSearch(foundStringParser(_.get(e, 'target.value')))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.code,
        dataIndex: 'code',
        key: 'code',
        sorter: true
    };

    const brandNameCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.brand' />
                    <Input
                        onChange={e => onBrandSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.brandName,
        dataIndex: 'supplierBrandName',
        key: 'supplierBrandName',
        sorter: true
    };

    const nameCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='storage.product_name' />
                    <Input
                        onChange={e => onNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.name,
        dataIndex: 'name',
        key: 'name',
        sorter: true
    };

    const supplierNameCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='storage.business_supplier' />
                    <Input
                        onChange={e => onSupplierSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.supplierName,
        dataIndex: 'supplierName',
        key: 'supplierName',
        sorter: true
    };

    const purchasePriceCol = {
        title: <FormattedMessage id='storage.purchase_price' />,
        width: defWidth.purchasePrice,
        align: 'right',
        dataIndex: 'purchasePrice',
        key: 'purchasePrice',
        sorter: true,
        render: purchasePrice => {
            return <Numeral mask='0,00.00'>{purchasePrice}</Numeral>;
        }
    };

    const priceCol = {
        title: <FormattedMessage id='storage.selling_price' />,
        width: defWidth.price,
        align: 'right',
        dataIndex: 'price',
        key: 'price',
        sorter: true,
        render: price => {
            return <Numeral mask='0,00.00'>{price}</Numeral>;
        }
    };

    const countCol = {
        title: <FormattedMessage id='order_form_table.count' />,
        width: defWidth.count,
        align: 'right',
        dataIndex: 'count',
        key: 'count',
        sorter: true
    };

    const sumCol = {
        title: <FormattedMessage id='order_form_table.sum' />,
        width: defWidth.sum,
        align: 'right',
        dataIndex: 'sum',
        key: 'sum',
        sorter: true,
        render: sum => {
            return <Numeral mask='0,00.00'>{sum}</Numeral>;
        }
    };

    const actionsCol = {
        width: defWidth.actions,
        key: 'actions',
        align: 'center',
        render: detail => (
            <Popover content={<FormattedMessage id='vehicle_page.hint_add_detail_to_order' />}>
                <Button disabled={disabled} icon={<PistonIcon />} onClick={() => onAddDetailToOrder({ detail })} />
            </Popover>
        )
    };

    const units = {
        title: <FormattedMessage id='services_table.units' />,
        key: 'unit',
        width: defWidth.units,
        dataIndex: 'unit',
        align: 'right',
        render: data => {
            return data ? <span style={{ whiteSpace: 'nowrap' }}>{data}</span> : <FormattedMessage id='long_dash' />;
        }
    };

    return [
        orderCol,
        datetimeCol,
        odometerCol,
        codeCol,
        brandNameCol,
        nameCol,
        supplierNameCol,
        purchasePriceCol,
        priceCol,
        countCol,
        units,
        sumCol,
        actionsCol
    ];
}
