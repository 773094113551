import { Col, Input, Row } from 'antd';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectMode,
    selectOtherCounterpartyName,
    setOtherCounterpartyName
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    otherCounterpartyName: selectOtherCounterpartyName(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    setOtherCounterpartyName
};

/**
 * Handle selection of other counterpart
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OtherCounterpartyContainer extends React.Component {
    render() {
        const {
            mode,
            otherCounterpartyName,
            setOtherCounterpartyName,
            editable,

            intl: { formatMessage }
        } = this.props;

        const disabled = Boolean(mode != MODES.ADD);

        return (
            <div className={Styles.mainContainer}>
                <Row>
                    <Col span={24}>
                        <div>
                            <Input
                                data-qa='input_search'
                                disabled={!editable && disabled}
                                onChange={e => setOtherCounterpartyName(e.target.value)}
                                placeholder={formatMessage({
                                    id: 'refactored_cash_order_modal.placeholder_other'
                                })}
                                value={otherCounterpartyName}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
