import { QuestionCircleOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Slider } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { Point } from './components';
import { ActionModal, AddPhotoSchemeModal, DamageComment } from './modals';
import Styles from './styles.m.css';

@injectIntl
export class IntakeTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pointsList: [],
            list: [],
            loading: true,
            carPhoto: [],
            multiplier: 1,
            helperDrawerOpen: false,
            allLinks: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_overview_vehicle' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    handleButtonPress = event => {
        const { pointsList } = this.state;
        this.buttonPressTimer = setTimeout(() => {
            if (!this.props.disabled) {
                const imgRect = document.getElementById('img').getBoundingClientRect();
                const x = event.targetTouches[0].clientX - event.target.offsetLeft - imgRect.left;
                const y = event.targetTouches[0].clientY - event.target.offsetTop - imgRect.top;
                pointsList.push({
                    x: (x / imgRect.width) * 100,
                    y: (y / imgRect.height) * 100
                });

                this.setState({
                    x: (x / imgRect.width) * 100,
                    y: (y / imgRect.height) * 100,
                    pointsList,
                    visibleDamageComment: true
                    // visibleActionModal: true
                });
                this.getDamagePoint();
            }
        }, 1500);
    };

    handleButtonRelease = () => {
        clearTimeout(this.buttonPressTimer);
    };

    fetchData = async () => {
        this.getVehicleImage();
        await this.getPhotoList();
        this.getDamagePoint();
    };

    getDamagePoint = async () => {
        const { orderId } = this.props;

        const pointsList = await fetchAPI(
            'GET',
            'orders/vehicle/damage/info',
            {
                orderId
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            pointsList
        });
    };

    deleteDamagePoint = async id => {
        await fetchAPI(
            'DELETE',
            'orders/vehicle/damage/info',
            null,
            {
                id
            },
            { handleErrorInternally: true }
        );
        this.getDamagePoint();
    };

    getPhotoList = async imgId => {
        const { orderImgId } = this.props;
        // const { vehicleImgId } = this.state;

        const list = await fetchAPI(
            'GET',
            'orders/vehicles/photo/list',
            {
                query: {}
            },
            null,
            { handleErrorInternally: true }
        );

        const index = imgId
            ? list.findIndex(({ id }) => id === imgId)
            : orderImgId
            ? list.findIndex(({ id }) => id === orderImgId)
            : this.state.vehicleImgId
            ? list.findIndex(({ id }) => id === this.state.vehicleImgId)
            : list.findIndex(({ id }) => id === imgId);

        await this.setState({
            list,
            hash: list[index !== -1 ? index : 0].imgHash,
            typeId: list[index !== -1 ? index : 0].id
        });

        this.getPhoto();
    };

    getPhoto = async () => {
        const { hash, typeId } = this.state;
        const photo = await fetchAPI('GET', 'orders/vehicle/photo', { hash, typeId }, null, {
            handleErrorInternally: true,
            rawResponse: true
        });
        const reportFile = await photo.blob();
        const url = URL.createObjectURL(reportFile);

        const file = {
            key: hash,
            uid: hash,
            status: 'done',
            url,
            thumbUrl: url
        };

        this.setState({
            carPhoto: file,
            loading: false
        });
    };

    getVehicleImage = async () => {
        const { vehicleId } = this.props;
        const vehicleImg = await fetchAPI('GET', '/vehicle/image', { vehicleId }, null, {
            handleErrorInternally: true
        });
        this.setState({
            vehicleImgId: vehicleImg.imageId
        });
    };

    showPhotoModal = () => {
        this.setState({
            visibleAddPhotoSchemeModal: true
        });
    };

    postProblems = async (ids, commentDmg) => {
        const { orderId } = this.props;
        const { x, y } = this.state;

        // const index = pointsList.findIndex(item => item.x && item.y);

        await fetchAPI('POST', 'orders/vehicle/damage/points', null, {
            orderId,
            damagePoints: [
                {
                    damageIds: ids,
                    x: x.toFixed(3),
                    y: y.toFixed(3),
                    comment: commentDmg
                }
            ]
        });

        this.getDamagePoint();
    };

    putProblems = async (ids, commentDmg, id) => {
        const { orderId } = this.props;

        await fetchAPI('PUT', '/orders/vehicle/damage', null, {
            orderId,
            comment: commentDmg,
            id,
            damageIds: ids
        });
    };

    render() {
        const {
            intl: { formatMessage },
            orderId,
            isMobile
        } = this.props;

        const {
            visibleActionModal,
            visibleAddPhotoSchemeModal,
            carPhoto,
            pointsList,
            visibleDamageComment,
            list,
            fileList,
            hash,
            typeId,
            loading,
            multiplier,
            helperDrawerOpen,
            allLinks
        } = this.state;

        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0 0 8px 0'
                    }}
                >
                    <span
                        style={{
                            fontSize: 18
                        }}
                    >
                        <FormattedMessage id='orders.vehicle' />
                    </span>

                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        {!isMobile && (
                            <span
                                style={{
                                    marginRight: 28,
                                    color: 'var(--primary)'
                                }}
                            >
                                <FormattedMessage id='order_form_table_intake_tab.hint_for_doublclick' />
                            </span>
                        )}
                        <Button
                            onClick={() => {
                                this.showPhotoModal();
                            }}
                            style={{
                                marginRight: 8
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='order_form_table_intake_tab.change_car' />
                        </Button>
                        <div>
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    this.setState({
                                        helperDrawerOpen: true
                                    });
                                    await this.fetchHelperLinks();
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    padding: 4
                                }}
                                type='text'
                            />
                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            marginRight: 200,
                            marginBottom: 8,
                            color: 'var(--primary)'
                        }}
                    >
                        <FormattedMessage id='order_form_table_intake_tab.hint_for_click' />
                    </span>
                )}
                {isMobile && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '8px 0px 4px'
                        }}
                    >
                        <ZoomOutOutlined
                            style={{
                                marginRight: 4
                            }}
                        />
                        <Slider
                            max={2}
                            min={1}
                            onChange={multiplier => this.setState({ multiplier })}
                            step={0.1}
                            style={{
                                width: '100%'
                            }}
                            value={multiplier}
                        />
                        <ZoomInOutlined
                            style={{
                                marginLeft: 4
                            }}
                        />
                    </div>
                )}
                {carPhoto && !loading && (
                    <div
                        style={{
                            position: 'relative',
                            border: '1px solid var(--lightGray)',
                            overflow: isMobile ? 'scroll' : 'initial'
                        }}
                    >
                        <Image
                            id='img'
                            onDoubleClick={event => {
                                if (!this.props.disabled) {
                                    const imgRect = document.getElementById('img').getBoundingClientRect();
                                    const x = event.clientX - event.target.offsetLeft - imgRect.left;
                                    const y = event.clientY - event.target.offsetTop - imgRect.top;
                                    pointsList.push({
                                        x: (x / imgRect.width) * 100,
                                        y: (y / imgRect.height) * 100
                                    });

                                    this.setState({
                                        x: (x / imgRect.width) * 100,
                                        y: (y / imgRect.height) * 100,
                                        pointsList,
                                        visibleDamageComment: true
                                        // visibleActionModal: true
                                    });
                                    this.getDamagePoint();
                                }
                            }}
                            onMouseDown={this.handleButtonPress}
                            onMouseLeave={this.handleButtonRelease}
                            onMouseUp={this.handleButtonRelease}
                            onTouchEnd={this.handleButtonRelease}
                            onTouchStart={this.handleButtonPress}
                            preview={false}
                            src={carPhoto.url}
                            width={`${100 * multiplier}%`}
                        />
                        {pointsList.map(({ x, y, id, comment, damageIds }, index) => (
                            <Point
                                key={`point-${id}`}
                                index={index}
                                left={`${x * multiplier}%`}
                                onClick={() => {
                                    if (!this.props.disabled) {
                                        this.setState({
                                            visibleDamageComment: true,
                                            id,
                                            damageIds,
                                            comment
                                        });
                                    }
                                }}
                                top={`${y}%`}
                            >
                                {index + 1}
                            </Point>
                        ))}
                    </div>
                )}
                <ActionModal
                    hideModal={() => {
                        this.setState({ visibleActionModal: false });
                    }}
                    list={list}
                    visible={visibleActionModal}
                    visibleModal={() => {
                        this.setState({ visibleDamageComment: true });
                    }}
                />
                <AddPhotoSchemeModal
                    fileList={fileList}
                    getPhoto={this.getPhoto}
                    getPhotoList={this.getPhotoList}
                    hash={hash}
                    hideModal={() => {
                        this.setState({ visibleAddPhotoSchemeModal: false });
                    }}
                    list={list}
                    orderId={orderId}
                    typeId={typeId}
                    visible={visibleAddPhotoSchemeModal}
                    visibleFunction={this.visible}
                />
                <DamageComment
                    comment={this.state.comment}
                    damageIds={this.state.damageIds}
                    deleteDamagePoint={this.deleteDamagePoint}
                    getDamagePoint={this.getDamagePoint}
                    hideModal={() => {
                        this.setState({
                            visibleDamageComment: false,
                            id: undefined,
                            damageIds: undefined,
                            comment: undefined
                        });
                        this.getDamagePoint();
                    }}
                    id={this.state.id}
                    orderId={orderId}
                    pointsList={pointsList}
                    postProblems={this.postProblems}
                    putProblems={this.putProblems}
                    visible={visibleDamageComment}
                    visibleModal={this.visible}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default IntakeTab;
