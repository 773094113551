import { Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import { OrderTaskForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class OrderTaskModal extends Component {
    render() {
        const {
            visible,
            resetModal,
            num,
            resetOrderTasksForm,
            progressStatusOptions,
            priorityOptions,
            wrappedComponentRef,
            stations,
            managers,
            saveNewOrderTask,
            orderTasks,
            activeVehicle,
            initialOrderTask
        } = this.props;

        return (
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                className={Styles.orderTaskModal}
                maskClosable={false}
                okText={<FormattedMessage id='save' />}
                onCancel={() => {
                    resetModal();
                    resetOrderTasksForm();
                }}
                onOk={() => saveNewOrderTask()}
                title={<FormattedMessage id='order-task-modal.transfer_change_task' />}
                visible={visible === MODALS.ORDER_TASK}
                width='80%'
                wrapClassName={Styles.orderTaskModal}
            >
                <OrderTaskForm
                    activeVehicle={activeVehicle}
                    initialOrderTask={initialOrderTask}
                    managers={managers}
                    num={num}
                    orderTasks={orderTasks}
                    priorityOptions={priorityOptions}
                    progressStatusOptions={progressStatusOptions}
                    stations={stations}
                    wrappedComponentRef={wrappedComponentRef}
                />
            </Modal>
        );
    }
}
