import { Table } from 'antd';
import {
    selectFetchingVehicleOrders,
    selectVehicleOrders,
    selectVehicleOrdersSort,
    selectVehicleOrdersStats,
    setPageOrders
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config';

const mapStateToProps = state => ({
    user: state.auth,
    orders: selectVehicleOrders(state),
    stats: selectVehicleOrdersStats(state),
    sort: selectVehicleOrdersSort(state),
    fetchingVehicleOrders: selectFetchingVehicleOrders(state)
});

const mapDispatchToProps = {
    setPageOrders
};

/**
 * Table of vehicle orders
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleOrdersTable extends React.Component {
    render() {
        const {
            orders,
            stats,
            sort,
            intl: { formatMessage },
            setPageOrders,
            fetchingVehicleOrders
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.countOrders / 25) * 25,
            current: sort.page,
            onChange: page => {
                setPageOrders({ page });
            }
        };

        return (
            <Table
                bordered
                columns={columnsConfig({ formatMessage })}
                dataSource={orders}
                loading={fetchingVehicleOrders}
                pagination={pagination}
                rowKey={() => v4()}
                scroll={{ y: '60vh' }}
                size='small'
            />
        );
    }
}
