import { Button, Input, Popover } from 'antd';
import { Numeral } from 'commons';
import { FormattedDatetime } from 'components';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WrenchIcon } from 'theme';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    order: '10%',
    datetime: '10%',
    odometer: '8%',
    labor: '10%',
    type: '10%',
    storeGroupName: '10%',
    mechanic: '10%',
    normHours: '5%',
    price: '7%',
    count: '5%',
    sum: '10%',
    actions: '5%'
};

export function columnsConfig(props) {
    const {
        // onAddDetailToOrder,
        onServiceNameSearch,
        onDefaultNameSearch,
        onStoreGroupNameSearch,
        onEmployeeFullNameSearch,
        fetchAllAggregatesLabors,
        disabled
    } = props;

    const { onAddLaborToOrder } = props;

    const orderCol = {
        title: <FormattedMessage id='orders.order' />,
        width: defWidth.order,
        dataIndex: 'orderNum',
        key: 'orderId',
        sorter: true,
        render: orderNum => <React.Fragment>{orderNum}</React.Fragment>
    };

    const datetimeCol = {
        title: <FormattedMessage id='orders.record_date' />,
        width: defWidth.datetime,
        dataIndex: 'orderDatetime',
        key: 'datetime',
        sorter: true,
        render: orderDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={orderDatetime} format={DEFAULT_DATETIME} />
            </React.Fragment>
        )
    };

    const odometerCol = {
        title: <FormattedMessage id='orders.odometer' />,
        width: defWidth.odometer,
        dataIndex: 'odometer',
        key: 'odometer',
        render: odometer => <div>{odometer || <FormattedMessage id='long_dash' />}</div>
    };

    const laborCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.detail_name' />
                    <Input
                        onChange={async e => {
                            await onServiceNameSearch(_.get(e, 'target.value'));
                        }} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.labor,
        dataIndex: 'serviceName',
        key: 'serviceName',
        sorter: true
    };

    const typeCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.service_type' />
                    <Input
                        onChange={e => onDefaultNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.type,
        dataIndex: 'defaultName',
        key: 'defaultName',
        sorter: true
    };

    const storeGroupNameCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.store_group' />
                    <Input
                        onChange={e => onStoreGroupNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.begin_datetime,
        dataIndex: 'storeGroupName',
        key: 'storeGroupName',
        sorter: true
    };

    const mechanicCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='employee.is_mechanic' />
                    <Input
                        onChange={e => onEmployeeFullNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.mechanic,
        dataIndex: 'employeeFullName',
        key: 'employeeFullName',
        sorter: true
    };

    const normHoursCol = {
        title: <FormattedMessage id='services_table.norm_hours' />,
        width: defWidth.normHours,
        align: 'right',
        dataIndex: 'hours',
        key: 'hours',
        sorter: true
    };

    const priceCol = {
        title: <FormattedMessage id='order_form_table.price' />,
        width: defWidth.price,
        align: 'right',
        dataIndex: 'price',
        key: 'price',
        sorter: true,
        render: price => {
            return <Numeral mask='0,00.00'>{price}</Numeral>;
        }
    };

    const countCol = {
        title: <FormattedMessage id='order_form_table.count' />,
        width: defWidth.count,
        align: 'right',
        dataIndex: 'count',
        key: 'count',
        sorter: true
    };

    const sumCol = {
        title: <FormattedMessage id='order_form_table.sum' />,
        width: defWidth.sum,
        align: 'right',
        dataIndex: 'sum',
        key: 'sum',
        sorter: true,
        render: sum => {
            return <Numeral mask='0,00.00'>{sum}</Numeral>;
        }
    };

    const actionsCol = {
        width: defWidth.actions,
        key: 'actions',
        render: labor => (
            <div>
                <Popover content={<FormattedMessage id='vehicle_page.hint_add_labor_to_order' />}>
                    <Button disabled={disabled} icon={<WrenchIcon />} onClick={() => onAddLaborToOrder({ labor })} />
                </Popover>
            </div>
        )
    };

    const units = {
        title: <FormattedMessage id='services_table.units' />,
        key: 'units',
        dataIndex: 'unit',
        align: 'right',
        render: data => {
            return data ? <span style={{ whiteSpace: 'nowrap' }}>{data}</span> : <FormattedMessage id='long_dash' />;
        }
    };

    return [
        orderCol,
        datetimeCol,
        odometerCol,
        laborCol,
        typeCol,
        storeGroupNameCol,
        mechanicCol,
        normHoursCol,
        priceCol,
        countCol,
        units,
        sumCol,
        actionsCol
    ];
}
