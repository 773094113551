import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    FETCH_ANALYTICS,
    fetchAnalyticsSuccess,
    selectAnalyticsFilters,
    setDefaultAnalyticsUniqueId,
    setFetchingAnalytics
} from './duck';

export function* fetchAnalyticsSaga() {
    while (true) {
        try {
            yield take(FETCH_ANALYTICS);

            yield put(setFetchingAnalytics(true));
            const filters = yield select(selectAnalyticsFilters);

            const response = yield call(
                fetchAPI,
                'GET',
                'report/analytics',
                {
                    filters
                },
                null,
                { handleErrorInternally: true }
            );
            const { analytics, stats: analyticsStats } = response;

            yield put(fetchAnalyticsSuccess({ analytics, analyticsStats }));

            yield put(
                setDefaultAnalyticsUniqueId({
                    selectedIncomeAnalyticsUniqueId: analyticsStats.defaultIncomeAnalyticsUniqueId,
                    selectedExpenseAnalyticsUniqueId: analyticsStats.defaultExpenseAnalyticsUniqueId
                })
            );
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingAnalytics(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchAnalyticsSaga)]);
}
