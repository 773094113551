exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__formWrapper---1AHWD{display:inline;flex-direction:column;gap:32px}.styles-m__formItem---3mUaA{display:flex;gap:8px;flex:1}.styles-m__formWrapperMobile---3hjoK{display:inline;flex-direction:column;gap:32px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,+BACI,cAAe,CACf,qBAAsB,CACtB,QACJ,CAEA,4BACI,YAAa,CACb,OAAQ,CACR,MACJ,CAQA,qCACI,cAAe,CACf,qBAAsB,CACtB,QACJ","file":"styles.m.css","sourcesContent":[".formWrapper {\n    display: inline;\n    flex-direction: column;\n    gap: 32px;\n}\n\n.formItem {\n    display: flex;\n    gap: 8px;\n    flex: 1;\n}\n\n.formRow  {\n    /* & > div {\n        width: 240px;\n    } */\n}\n\n.formWrapperMobile {\n    display: inline;\n    flex-direction: column;\n    gap: 32px;\n}"]}]);

// Exports
exports.locals = {
	"formWrapper": "styles-m__formWrapper---1AHWD",
	"formItem": "styles-m__formItem---3mUaA",
	"formRow": "styles-m__formRow---1Ub07",
	"formWrapperMobile": "styles-m__formWrapperMobile---3hjoK"
};