import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Spin, Table, Tabs, TreeSelect } from 'antd';
import { API_URL } from 'core/forms/orderDiagnosticForm/saga';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const { TreeNode } = TreeSelect;
const { TabPane } = Tabs;
const { Option } = Select;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

@injectIntl
class TecDocInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataSource0: [],
            dataSource1: [],
            dataSource2: [],
            dataSource3: [],
            dataSource4: [],
            dataSource5: [],
            dataSource6: [],
            dataSource7: [],
            dataSource8: [],
            dataSource9: [],
            fetched: false,

            parameterFilter: undefined,
            valueFilter: undefined,
            unitsFilter: undefined,
            firstCommentFilter: undefined,
            secondCommentFilter: undefined
        };

        this.columnsTypeB = [
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='info_modal.parameter' />
                            <Input
                                allowClear
                                onChange={event => {
                                    const { value } = event.target;
                                    this.setState({
                                        parameterFilter: value
                                    });
                                }}
                                value={this.state.parameterFilter}
                            />
                        </div>
                    );
                },
                key: 'itemmptext',
                dataIndex: 'itemmptext'
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='info_modal.value' />
                            <Input
                                allowClear
                                onChange={event => {
                                    const { value } = event.target;
                                    this.setState({
                                        valueFilter: value
                                    });
                                }}
                                value={this.state.valueFilter}
                            />
                        </div>
                    );
                },
                key: 'valuetext',
                dataIndex: 'valuetext'
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='info_modal.units' />
                            <Input
                                allowClear
                                onChange={event => {
                                    const { value } = event.target;
                                    this.setState({
                                        unitsFilter: value
                                    });
                                }}
                                value={this.state.unitsFilter}
                            />
                        </div>
                    );
                },
                key: 'quantitytext',
                dataIndex: 'quantitytext'
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='comment' /> 1
                            <Input
                                allowClear
                                onChange={event => {
                                    const { value } = event.target;
                                    this.setState({
                                        firstCommentFilter: value
                                    });
                                }}
                                value={this.state.firstCommentFilter}
                            />
                        </div>
                    );
                },
                key: 'qualcoltext',
                dataIndex: 'qualcoltext'
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='comment' /> 2
                            <Input
                                allowClear
                                onChange={event => {
                                    const { value } = event.target;
                                    this.setState({
                                        secondCommentFilter: value
                                    });
                                }}
                                value={this.state.secondCommentFilter}
                            />
                        </div>
                    );
                },
                key: 'addtext',
                dataIndex: 'addtext'
            }
        ];

        this.columnsTypeA = [
            ...this.columnsTypeB,
            {
                key: 'select',
                width: 'auto',
                render: elem => {
                    return (
                        <Button
                            onClick={() => {
                                if (this.props.showOilModal) {
                                    this.props.showOilModal(elem.oem[0], elem.oeCode, elem.acea, elem.api, elem.sae);
                                }
                                this.handleCancel();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='add' />
                        </Button>
                    );
                }
            }
        ];

        this.tabsData = [
            {
                name: '',
                type: 'A',
                title: ''
            }
        ];
    }

    handleOk = () => {};

    handleCancel = () => {
        this.setState({
            visible: false,
            fetched: false,
            parameterFilter: undefined,
            valueFilter: undefined,
            unitsFilter: undefined,
            firstCommentFilter: undefined,
            secondCommentFilter: undefined
        });
    };

    fetchData() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = API_URL;
        const params = `/tecdoc/autodata?modificationId=${this.props.modificationId}`;
        url += params;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.setState({
                    fetched: true,
                    dataSource0: data.specifications.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource1: data.capacity.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource2: data.intervals.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource3: data.lightBulbs.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource4: data.brakes.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource5: data.electric.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource6: data.tirePressure.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource7: data.tighteningTorques.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource8: data.camberAngles.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    }),
                    dataSource9: data.others.map((elem, i) => {
                        elem.key = i;
                        elem.count = 1;

                        return elem;
                    })
                });
            })
            .catch(function (error) {
                that.setState({
                    fetched: true
                });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.visible && this.state.visible) {
            this.fetchData();
        }
    }

    render() {
        const {
            visible,
            parameterFilter,
            valueFilter,
            unitsFilter,
            firstCommentFilter,
            secondCommentFilter,

            dataSource0,
            dataSource1,
            dataSource2,
            dataSource3,
            dataSource4,
            dataSource5,
            dataSource6,
            dataSource7,
            dataSource8,
            dataSource9
        } = this.state;

        let dt0 = [...dataSource0];
        let dt1 = [...dataSource1];
        let dt2 = [...dataSource2];
        let dt3 = [...dataSource3];
        let dt4 = [...dataSource4];
        let dt5 = [...dataSource5];
        let dt6 = [...dataSource6];
        let dt7 = [...dataSource7];
        let dt8 = [...dataSource8];
        let dt9 = [...dataSource9];

        if (parameterFilter) {
            dt0 = dt0.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt1 = dt1.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt2 = dt2.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt3 = dt3.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt4 = dt4.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt5 = dt5.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt6 = dt6.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt7 = dt7.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt8 = dt8.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
            dt9 = dt9.filter(
                elem => elem.itemmptext && elem.itemmptext.toLowerCase().indexOf(parameterFilter.toLowerCase()) >= 0
            );
        }
        if (valueFilter) {
            dt0 = dt0.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt1 = dt1.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt2 = dt2.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt3 = dt3.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt4 = dt4.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt5 = dt5.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt6 = dt6.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt7 = dt7.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt8 = dt8.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
            dt9 = dt9.filter(
                elem => elem.valuetext && elem.valuetext.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            );
        }
        if (unitsFilter) {
            dt0 = dt0.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt1 = dt1.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt2 = dt2.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt3 = dt3.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt4 = dt4.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt5 = dt5.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt6 = dt6.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt7 = dt7.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt8 = dt8.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
            dt9 = dt9.filter(
                elem => elem.quantitytext && elem.quantitytext.toLowerCase().indexOf(unitsFilter.toLowerCase()) >= 0
            );
        }
        if (firstCommentFilter) {
            dt0 = dt0.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt1 = dt1.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt2 = dt2.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt3 = dt3.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt4 = dt4.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt5 = dt5.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt6 = dt6.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt7 = dt7.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt8 = dt8.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
            dt9 = dt9.filter(
                elem =>
                    elem.qualcoltext && elem.qualcoltext.toLowerCase().indexOf(firstCommentFilter.toLowerCase()) >= 0
            );
        }
        if (secondCommentFilter) {
            dt0 = dt0.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt1 = dt1.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt2 = dt2.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt3 = dt3.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt4 = dt4.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt5 = dt5.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt6 = dt6.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt7 = dt7.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt8 = dt8.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
            dt9 = dt9.filter(
                elem => elem.addtext && elem.addtext.toLowerCase().indexOf(secondCommentFilter.toLowerCase()) >= 0
            );
        }

        return (
            <React.Fragment>
                <Button
                    icon={<ExclamationCircleOutlined style={{ fontSize: this.props.isMobile ? 14 : 24 }} />}
                    onClick={() => {
                        this.setState({
                            visible: true
                        });
                    }}
                    size='large'
                    type='text'
                />

                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={null}
                    visible={visible}
                    width='95%'
                >
                    {this.state.fetched ? (
                        <Tabs tabPosition='left'>
                            <TabPane key='1' tab={<FormattedMessage id='details_table.oils_and_liquids'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='specifications_of_oils_and_technical_fluids'/></div>
                                <Table bordered columns={this.columnsTypeA} dataSource={dt0} />
                            </TabPane>
                            <TabPane key='2' tab={<FormattedMessage id='liters'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='volumes_of_oils_and_technical_fluids'/></div>
                                <Table bordered columns={this.columnsTypeB} dataSource={dt1} />
                            </TabPane>
                            <TabPane key='3' tab={<FormattedMessage id='online_service_book_page.inspection_intervals'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='replacement_intervals_for_spare_parts_oils_and_fluids'/></div>
                                <Table bordered columns={this.columnsTypeA} dataSource={dt2} />
                            </TabPane>
                            <TabPane key='4' tab={<FormattedMessage id='light_bulbs'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='car_bulb_specifications'/></div>
                                <Table bordered columns={this.columnsTypeA} dataSource={dt3} />
                            </TabPane>
                            <TabPane key='5' tab={<FormattedMessage id='brakes'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='brake_parameters'/></div>
                                <Table bordered columns={this.columnsTypeA} dataSource={dt4} />
                            </TabPane>
                            <TabPane key='6' tab={<FormattedMessage id='electricity'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='vehicle_electrical_data'/></div>
                                <Table bordered columns={this.columnsTypeA} dataSource={dt5} />
                            </TabPane>
                            <TabPane key='7' tab={<FormattedMessage id='tire_pressure'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='tire_pressure'/></div>
                                <Table bordered columns={this.columnsTypeB} dataSource={dt6} />
                            </TabPane>
                            <TabPane key='8' tab={<FormattedMessage id='tightening_torques'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='tightening_torques_for_fastenings_of_units_and_parts'/></div>
                                <Table bordered columns={this.columnsTypeB} dataSource={dt7} />
                            </TabPane>
                            <TabPane key='9' tab={<FormattedMessage id='camber_angles'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='wheel_alignment_angles'/></div>
                                <Table bordered columns={this.columnsTypeB} dataSource={dt8} />
                            </TabPane>
                            <TabPane key='10' tab={<FormattedMessage id='requisite-setting.form.other'/>}>
                                <div className={Styles.tableHeader}><FormattedMessage id='other_parameters'/></div>
                                <Table bordered columns={this.columnsTypeB} dataSource={dt9} />
                            </TabPane>
                        </Tabs>
                    ) : (
                        <Spin indicator={spinIcon} />
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}
export default TecDocInfoModal;
