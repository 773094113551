import { Table } from 'antd';
import { setSelectedClientId } from 'modals/DebtZeroingModal/redux/duck';
import {
    fetchOrders,
    selectFetchingOrders,
    selectOrders,
    selectOrdersFilters,
    selectOrdersStats,
    selectSelectedOrderId,
    setOrdersFilters,
    setSelectedOrderId
} from 'modals/DebtZeroingModal/redux/orders/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    orders: selectOrders(state),
    ordersStats: selectOrdersStats(state),
    ordersFilters: selectOrdersFilters(state),
    fetchingOrders: selectFetchingOrders(state),
    selectedOrderId: selectSelectedOrderId(state)
});

const mapDispatchToProps = {
    fetchOrders,
    setOrdersFilters,
    setSelectedOrderId,
    setSelectedClientId
};

/**
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrdersTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchOrders();
    }

    render() {
        const {
            isMobile,
            orders,
            fetchingOrders,

            ordersStats,
            ordersFilters,
            selectedOrderId,
            setOrdersFilters,
            setSelectedOrderId,
            setSelectedClientId
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(ordersStats.countOrders / 25) * 25,
            current: ordersFilters.page,
            onChange: page => {
                setOrdersFilters({ page });
            }
        };

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig()}
                    dataSource={orders}
                    loading={fetchingOrders}
                    onRow={record => ({
                        onClick: () => {
                            setSelectedClientId(record.clientId);
                            setSelectedOrderId(record.id);
                        }
                    })}
                    pagination={pagination}
                    rowClassName={record =>
                        record.id == selectedOrderId ? Styles.selectedRow : undefined
                    }
                    rowKey={() => v4()}
                    scroll={{ x: isMobile ? '100vw' : '40vw', y: '25vh' }}
                />
            </div>
        );
    }
}
