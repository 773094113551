import { ArrayBreakScheduleInput } from 'components';
import { onChangeBreakScheduleForm, resetFields } from 'core/forms/breakScheduleForm/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';

@injectIntl
@withReduxForm2({
    name: 'breakScheduleForm',
    actions: {
        change: onChangeBreakScheduleForm,
        resetFields
    }
})
export class BreakScheduleForm extends Component {
    render() {
        const { initialBreakSchedule, form, intl, fields, loading } = this.props;
        const {
            updateBreakSchedule,
            createBreakSchedule,
            deleteBreakSchedule,
            resetFields,
            forbiddenUpdate
        } = this.props;

        return (
            <ArrayBreakScheduleInput
                createBreakSchedule={createBreakSchedule}
                deleteBreakSchedule={deleteBreakSchedule}
                fields={fields}
                forbiddenUpdate={forbiddenUpdate}
                form={form}
                initialBreakSchedule={initialBreakSchedule}
                intl={intl}
                loading={loading}
                resetFields={resetFields}
                updateBreakSchedule={updateBreakSchedule}
            />
        );
    }
}
