exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__table---1SzFt{min-width:0!important;width:100%}.styles-m__table---1SzFt .ant-table-header{text-transform:uppercase;min-width:0!important;padding:0}.styles-m__table---1SzFt .ant-pagination{float:none;justify-content:center;display:flex}.styles-m__table---1SzFt th{padding:6px 4px!important}.styles-m__tableContainer---32i_Q{background-color:initial;margin:.1em .2em 2em}.styles-m__expenseCashOrder---X_7mk,.styles-m__incomeCashOrder---iuUCd{margin:0 2px 2px 0;border-radius:10em}.styles-m__incomeCashOrder---iuUCd{background-color:rgba(255,0,0,.2)}.styles-m__expenseCashOrder---X_7mk{background-color:rgba(0,255,0,.3)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AACA,yBACI,qBAAuB,CACvB,UACJ,CAEA,2CACI,wBAAyB,CACzB,qBAAuB,CACvB,SACJ,CAEA,yCACI,UAAW,CACX,sBAAuB,CACvB,YACJ,CAEA,4BACI,yBACJ,CAEA,kCACI,wBAA6B,CAC7B,oBACJ,CAEA,uEACI,kBAAmB,CACnB,kBACJ,CAEA,mCACI,iCACJ,CAEA,oCACI,iCACJ","file":"styles.m.css","sourcesContent":["    \n.table {\n    min-width: 0 !important;\n    width: 100%;\n}\n\n.table :global(.ant-table-header) {\n    text-transform: uppercase;\n    min-width: 0 !important; /*IMPORTANT it solves antd table issue when scrolling is added*/\n    padding: 0;\n}\n\n.table :global(.ant-pagination) {\n    float: none;\n    justify-content: center;\n    display: flex;\n}\n\n.table :global(th) {\n    padding: 6px 4px 6px 4px !important; /*Remove bug: when text overflows, header shifts left*/\n}\n\n.tableContainer {\n    background-color: transparent;\n    margin: 0.1em 0.2em 2em 0.2em;\n}\n\n.incomeCashOrder, .expenseCashOrder {\n    margin: 0 2px 2px 0;\n    border-radius: 10em;\n} \n\n.incomeCashOrder {\n    background-color: rgba(255, 0, 0, 0.2);\n}\n\n.expenseCashOrder {\n    background-color: rgba(0, 255, 0, 0.3);\n}"]}]);

// Exports
exports.locals = {
	"table": "styles-m__table---1SzFt",
	"tableContainer": "styles-m__tableContainer---32i_Q",
	"incomeCashOrder": "styles-m__incomeCashOrder---iuUCd",
	"expenseCashOrder": "styles-m__expenseCashOrder---X_7mk"
};