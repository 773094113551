import { Input, Select, Table } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import _, { escapeRegExp } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

import Styles from './styles.m.css';

const { Option } = Select;

const actions = ['ADD', 'UPDATE', 'DELETE'];

const fieldMap = {
    detailsDiscount: 'discount_details',
    businessComment: 'add_order_form.client_comments',
    clientPhone: 'add_client_form.phone_number_responsible',
    client: 'orders.client',
    comment: 'cash-table.comment',
    clientRequisite: 'add_order_form.client_requisites',
    clientVehicle: 'orders.vehicle',
    clientAggregate: 'order_form.aggregate',
    duration: 'duration',
    employee: 'order_form_table.master',
    manager: 'responsible',
    appurtenanciesResponsible: 'report-orders-form.appurtenancies_responsible',
    odometerValue: 'orders.odometr',
    paymentMethod: 'add_order_form.payment_method',
    recommendation: 'order_form_table.recommendation',
    businessRequisite: 'add_order_form.service_requisites',
    servicesDiscount: 'discount_labors',
    status: 'orders.status',
    datetime: 'report-orders-table.date',
    deliveryDatetime: 'add_order_form.delivery_time',
    successDatetime: 'report_orders_export_modal.done_date',
    vehicleCondition: 'add_order_form.vehicle_condition',
    orderBloke: 'storage_funds.order_type',
    discount: 'total_discount_or_markup',
    laborTimeMultiplier: 'labor_multiplier',
    clientVehicleVin: 'vehicle_page.vin',
    normHourPrice: 'norm_hour_price',
    aggregateCode: 'add_client_form.aggregate_code',
    aggregateName: 'add_client_form.aggregate_name',
    aggregateBrandName: 'add_client_form.aggregate_brand',
    aggregateNumber: 'add_client_form.aggregate_number',
    aggregateComment: 'add_client_form.aggregate_comment',
    details: 'details',
    services: 'services'
};

const LogsTable = ({ orderId, activeKey, employees, intl }) => {
    const [date, setDate] = useState({});
    const [logsInfo, setLogsInfo] = useState();
    const [fieldFilter, setFieldFilter] = useState();
    const [actionFilter, setActionFilter] = useState();
    const [responsibleFilter, setResponsibleFilter] = useState();
    const [searchFilter, setSearchFilter] = useState();

    const handleDateChange = daterange => {
        const [startDate, endDate] = daterange;
        setDate(prev => ({ ...prev, dateFrom: startDate, dateTo: endDate }));
    };

    const columns = useMemo(
        () => [
            {
                title: '№',
                key: 'index',
                render: (r, r2, index) => `${index + 1}.`
            },
            {
                title: <FormattedMessage id='logs-table.date' />,
                key: 'date',
                render: record => dayjs(record.date).format('DD.MM.YYYY HH:mm'),
                sorter: (a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()
            },
            {
                title: <FormattedMessage id='calls-table.record' />,
                dataIndex: 'log',
                key: 'log',
                render: data => {
                    const reg = new RegExp(escapeRegExp(searchFilter), 'gi');
                    const html = searchFilter
                        ? data.replace(reg, `<span style="background: yellow;">${String(searchFilter)}</span>`)
                        : data;

                    return <div dangerouslySetInnerHTML={{ __html: html }} />;
                }
            },
            {
                title: <FormattedMessage id='diagnostic-page.action' />,
                dataIndex: 'action',
                key: 'action',
                render: text => intl.formatMessage({ id: `logs-table.${text}` })
            },
            {
                title: <FormattedMessage id='orders.responsible' />,
                dataIndex: 'responsible',
                key: 'responsible'
            }
        ],
        [searchFilter]
    );

    const filterDataSource = data => {
        return responsibleFilter ? data.filter(({ responsible }) => responsible === responsibleFilter) : data;
    };

    const call = async () => {
        const query = {
            orderId,
            ...(date.dateFrom && { dateFrom: date.dateFrom.format('YYYY-MM-DD') }),
            ...(date.dateTo && { dateTo: date.dateTo.format('YYYY-MM-DD') }),
            fieldFilter,
            actionFilter,
            searchFilter
        };
        const logs = await fetchAPI('GET', 'orders/logs', query, null, {
            handleErrorInternally: true
        });
        setLogsInfo(logs);
    };

    useEffect(() => {
        orderId && activeKey === 'orders_logs' && call();
    }, [searchFilter, date, fieldFilter, actionFilter, activeKey]);

    return (
        <div>
            <div className={Styles.inputWrap}>
                <DateRangePicker
                    dateRange={[date.dateFrom, date.dateTo]}
                    minimize // prevent default space
                    onDateChange={handleDateChange}
                    // style={{ margin: 0 }}
                />
                <Input
                    allowClear
                    onChange={e => setSearchFilter(e.target.value)}
                    placeholder={intl.formatMessage({ id: 'search' })}
                    value={searchFilter}
                />
                <Select
                    allowClear
                    onChange={value => setFieldFilter(value)}
                    optionFilterProp='children'
                    placeholder={intl.formatMessage({ id: 'logs-table.field' })}
                    showSearch
                    value={fieldFilter}
                >
                    {_.get(logsInfo, 'filterMap', []).map(el => (
                        <Option key={el} value={el}>
                            {intl.formatMessage({ id: fieldMap[el] || 'Translation not found' })}
                        </Option>
                    ))}
                </Select>
                <Select
                    allowClear
                    onChange={value => setActionFilter(value)}
                    placeholder={intl.formatMessage({ id: 'diagnostic-page.action' })}
                    value={actionFilter}
                >
                    {actions.map(el => (
                        <Option key={el} value={el}>
                            <FormattedMessage id={`logs-table.${el}`} />
                        </Option>
                    ))}
                </Select>
                <Select
                    allowClear
                    onChange={value => setResponsibleFilter(value)}
                    value={responsibleFilter}
                    placeholder={intl.formatMessage({ id: 'orders.responsible' })}
                    // filterOption={filterByPart}
                    // mode='multiple'
                >
                    {employees
                        .filter(employee =>
                            _.get(logsInfo, 'orderLogs', []).find(
                                ({ responsible }) => responsible === `${employee.surname} ${employee.name}`
                            )
                        )
                        .map(employee => (
                            <Option
                                key={`employee-${employee.id}`}
                                disabled={employee.disabled}
                                post={_.get(employee, 'postref[0].postNum')}
                                value={`${employee.surname} ${employee.name}`}
                            >
                                {`${employee.surname} ${employee.name}`}
                            </Option>
                        ))}
                </Select>
            </div>
            <Table
                bordered
                columns={columns}
                dataSource={filterDataSource(_.get(logsInfo, 'orderLogs', []))}
                // pagination={pagination}
                rowKey='id'
                size='small'
            />
        </div>
    );
};

export default injectIntl(LogsTable);
