import { Button } from 'antd';
import { Layout } from 'commons';
import { setModal } from 'core/modals/duck';
import { selectLinks } from 'core/storage/products';
import { AddAggregateModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AggregatesTable from './components/AggregatesTable';
import { fetchVehicles } from './redux/duck';

const mapStateToProps = state => ({
    user: state.auth,
    allLinks: selectLinks(state)
});
const mapDispatchToProps = {
    fetchVehicles,
    setModal
};

/**
 * This page was created to maintain all vehicles of the station(show tables, subtables, controls and other).
 * There is used "feature first" files structure(reffer to react documentaion), it means
 * that each one-time used component can be found deeper in a tree structure
 *
 * Each subcomponent is self-sufficient and requires only necessary data from parrent,
 * other data can be fetched and stored by redux saga/duck.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AggregatesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false,
            visibleAddAggregateModal: false,
            withoutClient: true
        };
    }

    // fetchHelperLinks = async () => {
    //     const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_vehicles' }, undefined, {
    //         handleErrorInternally: true
    //     });
    //     this.setState({
    //         allLinks: links
    //     });
    // };

    // getClientsAggregates = async () => {
    //     const { clientId } = this.props;

    //     const clientsAggregates = await fetchAPI('GET', '/client/aggregates', { clientId }, null, {
    //         handleErrorInternally: true
    //     });

    //     await this.setState({
    //         clientsAggregates
    //     });
    // };

    render() {
        const { user } = this.props;
        const { helperDrawerOpen, allLinks, visibleAddAggregateModal, withoutClient } = this.state;

        return (
            <Layout
                controls={
                    <Button
                        onClick={() => {
                            this.setState({
                                visibleAddAggregateModal: true
                            });
                        }}
                        type='primary'

                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_AGGREGATES_LIST, accesses.ROWO)}
                    >
                        <FormattedMessage id='clients-page.add_aggregate' />
                    </Button>
                }
                title={<FormattedMessage id='navigation.aggregates' />}
            >
                <AggregatesTable />
                <AddAggregateModal
                    hideModal={() => {
                        this.setState({
                            visibleAddAggregateModal: false
                        });
                    }}
                    visible={visibleAddAggregateModal}
                    withoutClient={withoutClient}
                />
            </Layout>
        );
    }
}
