exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__content---1T17t,.styles-m__label---1ycLl,.styles-m__row---11zd3{display:flex;align-items:center}.styles-m__content---1T17t{height:4em}.styles-m__formItemStyle---21G7l{margin:0!important;width:100%}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAUA,2EAJC,YAAa,CACb,kBAOD,CAJA,2BAGC,UACD,CAEA,iCACC,kBAAsB,CACtB,UACD","file":"styles.m.css","sourcesContent":[".row {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.label {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.content {\n\tdisplay: flex;\n\talign-items: center;\n\theight: 4em;\n}\n\n.formItemStyle {\n\tmargin: 0px !important;\n\twidth: 100%;\n}"]}]);

// Exports
exports.locals = {
	"row": "styles-m__row---11zd3",
	"label": "styles-m__label---1ycLl",
	"content": "styles-m__content---1T17t",
	"formItemStyle": "styles-m__formItemStyle---21G7l"
};