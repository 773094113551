import { Collapse, Input, Modal, Select, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const VARIABLES = {
    Total_Selling: 'Total_Selling',
    Total_Selling_VAT: 'Total_Selling_VAT',
    Services_Selling: 'Services_Selling',
    Services_Selling_VAT: 'Services_Selling_VAT',
    Goods_Selling: 'Goods_Selling',
    Goods_Selling_VAT: 'Goods_Selling_VAT',
    Services_Purchase: 'Services_Purchase',
    Services_Purchase_VAT: 'Services_Purchase_VAT',
    Goods_Purchase: 'Goods_Purchase',
    Goods_Purchase_VAT: 'Goods_Purchase_VAT',
    Total_Salaries: 'Total_Salaries',
    Total_Taxes: 'Total_Taxes',
    Total: 'Total'
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const AddRolesModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        allRoles,
        edit,
        fetchAdditionalRoles
    } = props;

    const [roleName, setPackageName] = useState(undefined);
    const [defaultRoleId, setDefaultPackageId] = useState(undefined);
    const [route, setRoute] = useState('/dashboard');

    useEffect(() => {
        if (edit) {
            setPackageName(edit.name);
            setRoute(edit.defaultRoute);
        }
    }, [edit]);

    const addAdditionalRole = async () => {
        try {
            await fetchAPI(
                'POST',
                '/v2/roles_and_accesses/business_roles',
                undefined,
                {
                    fromRoleId: defaultRoleId,
                    name: roleName,
                    defaultRoute: route
                },
                { handleErrorInternally: true }
            );
            await fetchAdditionalRoles();
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const editAdditionalRole = async () => {
        try {
            if (edit) {
                await fetchAPI(
                    'PUT',
                    '/v2/roles_and_accesses/business_roles',
                    undefined,
                    {
                        roleId: edit.id,
                        name: roleName,
                        defaultRoute: route
                    },
                    { handleErrorInternally: true }
                );

                notification.success({
                    message: formatMessage({ id: 'barcode.success' })
                });

                await fetchAdditionalRoles();
            }
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const onClose = () => {
        setPackageName(undefined);
        setDefaultPackageId(undefined);
        setRoute(undefined);
        hideModal();
    };

    // useEffect(() => {
    //     if (open) {
    //         fetchAdditionalRoles();
    //     }
    // }, [open, fetchAdditionalRoles]);

    const onFinish = async () => {
        if (!edit) {
            addAdditionalRole();
        } else {
            editAdditionalRole();
        }

        await onClose();
    };

    const disabled = !edit ? !roleName || !defaultRoleId : !roleName;

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: disabled || !route
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={
                !edit ? (
                    <FormattedMessage id='packages.create_additional_package' />
                ) : (
                    <FormattedMessage id='packages.edit_additional_package' />
                )
            }
            width='30%'
        >
            <div className={Styles.blockStyle}>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='packages.role_name' />
                    </div>
                    <Input
                        onChange={event => setPackageName(event.target.value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'packages.role_name'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={roleName}
                    />
                </div>
                {!edit && (
                    <div className={Styles.divGroup}>
                        <div>
                            <FormattedMessage id='packages.default_role' />
                        </div>
                        <Select
                            onSelect={value => setDefaultPackageId(value)}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'packages.default_role'
                            })}
                            showSearch
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={defaultRoleId}
                        >
                            {allRoles
                                ? allRoles
                                      .filter(({ businessId }) => !businessId)
                                      .map(({ id, name }) => (
                                          <Option key={id} value={id}>
                                              {name}
                                          </Option>
                                      ))
                                : undefined}
                        </Select>
                    </div>
                )}
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='packages.default_route' />
                    </div>
                    <Input
                        onChange={event => setRoute(event.target.value)}
                        placeholder={formatMessage({ id: 'packages.default_route' })}
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={route}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(AddRolesModal);
