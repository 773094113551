import { Table } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

@injectIntl
export class ReportOrdersTable extends Component {
    constructor(props) {
        super(props);
    }

    _setCashOrderEntity = cashOrderEntity => this.setState({ cashOrderEntity });

    render() {
        const {
            setIncludeServicesDiscount,
            includeServicesDiscount,
            tableData,
            stats,
            filter,
            filterControls,
            loading
        } = this.props;

        // We need to upade props (needed for child components)
        this.columns = columnsConfig({
            setIncludeServicesDiscount,
            includeServicesDiscount,
            filterControls,
            filter,
            formatMessage: this.props.intl.formatMessage
        });

        const pagination = {
            pageSize: filter.pageSize,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / filter.pageSize) * filter.pageSize,
            hideOnSinglePage: true,
            current: filter.page,
            onChange: (page, pageSize) => {
                filterControls.setReportOrdersPage({ page, pageSize });
                filterControls.fetchReportOrders();
            }
        };

        return (
            <Table
                bordered
                className={Styles.table}
                columns={this.columns}
                dataSource={tableData}
                loading={loading}
                locale={{
                    emptyText: <FormattedMessage id='no_data' />
                }}
                pagination={pagination}
                rowKey={record => record.orderId}
                scroll={{ x: 1800, y: '50vh' }}
                size='middle'
            />
        );
    }
}
