import { Form } from '@ant-design/compatible';
import { Slider } from 'antd';
import React from 'react';

const FormItem = Form.Item;

export class DecoratedSlider extends React.PureComponent {
    render() {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            cnStyles,
            width,
            hasFeedback,
            formItemLayout,

            getFieldDecorator,
            disabled,
            rules,
            field,
            initialValue,

            onChange,
            onAfterChange,
            step,
            min,
            max,
            marks
        } = this.props;

        const slider = getFieldDecorator(field, {
            ...(initialValue ? { initialValue } : { initialValue: void 0 }),
            rules
        })(
            <Slider
                className={cnStyles}
                disabled={disabled}
                marks={marks}
                max={max}
                min={min}
                onAfterChange={onAfterChange}
                onChange={onChange}
                step={step}
                style={{ width: width || '100%' }}
            />
        );

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                label={label}
                {...formItemLayout}
            >
                {slider}
            </FormItem>
        ) : (
            slider
        );
    }
}
