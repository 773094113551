import { fetchVehicleInspectionIntervals } from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import VehicleInspectionIntervalsTable from './VehicleInspectionIntervalsTable';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    fetchVehicleInspectionIntervals
};

/**
 * Show vehicle inspections intervals to know when change old details and make service labors.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleInspectionIntervalsTab extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchVehicleInspectionIntervals();
    }

    render() {
        return <VehicleInspectionIntervalsTable />;
    }
}
