import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Styles from './styles.m.css';

@injectIntl
class ArrowsDatePicker extends Component {
    render() {
        const {
            nextDay,
            prevDay,
            onDayChange,
            date,
            loading,
            intl: { formatMessage }
        } = this.props;

        if (loading) return null;

        return (
            <div className={Styles.container}>
                {prevDay && (
                    <Button
                        disabled={loading}
                        icon={<LeftOutlined />}
                        onClick={() => prevDay()}
                        type='text'
                    />
                )}
                <DatePicker
                    allowClear={false}
                    className={Styles.datePicker}
                    disabled={loading}
                    format='dddd, DD MMM YYYY'
                    onChange={value => onDayChange(value)}
                    placeholder={formatMessage({
                        id: 'select_date'
                    })}
                    value={date}
                />
                {nextDay && (
                    <Button
                        disabled={loading}
                        icon={<RightOutlined />}
                        onClick={() => nextDay()}
                        type='text'
                    />
                )}
            </div>
        );
    }
}

export default ArrowsDatePicker;
