import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, InputNumber, Modal, Table, Tooltip } from 'antd';
import _ from 'lodash';
import { ADJUSTMENT, ORDERINCOME, SUPPLIER } from 'pages/Storage/constants';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const headerIconStyle = {
    fontSize: 24,
    cursor: 'pointer',
    margin: '0 0 0 18px'
};
const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

@injectIntl
@connect(mapStateToProps)
export default class AutomaticOrderCreationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            visible: false,
            loading: true,
            selectedRowKeys: [],
            selectedRows: []
        };

        const { formatMessage } = props.intl;

        this.orderColumns = () => [
            {
                title: '№',
                width: '3%',
                key: 'key',
                dataIndex: 'key',
                render: data => {
                    return data + 1;
                }
            },
            {
                title: textToColumn(
                    formatMessage({ id: 'order_form_table.detail_code' }),
                    formatMessage({ id: 'order_form_table.brand' })
                ),
                width: 'auto',
                key: 'codeAndBrand',
                render: elem => {
                    return textToColumn(elem.detailCode, elem.brandName);
                }
            },
            {
                title: textToColumn(
                    `${formatMessage({ id: 'order_form_table.detail_code' })} (${formatMessage({
                        id: 'storage.supplier'
                    })})`,
                    formatMessage({ id: 'order_form_table.detail_name' })
                ),
                width: 'auto',
                key: 'SupplierCodeAndName',
                render: elem => {
                    return textToColumn(elem.tradeCode, elem.detailName);
                }
            },
            {
                title: <FormattedMessage id='order_form_table.price' />,
                key: 'stockPrice',
                dataIndex: 'stockPrice',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={this.props.documentType == ADJUSTMENT}
                            min={0}
                            onChange={value => {
                                elem.stockPrice = value;
                                elem.sum = elem.quantity * value;
                                this.setState({ update: true });
                            }}
                            precision={2}
                            style={{ color: 'var(--text)' }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='storage.in_orders' />,
                key: 'countInOrders',
                dataIndex: 'countInOrders',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled
                            step={0.1}
                            style={{
                                color: 'black'
                            }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='storage.in_stock' />,
                key: 'countInWarehouses',
                dataIndex: 'countInWarehouses',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled
                            step={0.1}
                            style={{
                                color: 'black'
                            }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: textToColumn(formatMessage({ id: 'storage.reserve' }), formatMessage({ id: 'storage.min' })),
                width: 'auto',
                key: 'storageBalanceReserveAndMin',
                render: elem => {
                    return (
                        <React.Fragment>
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    style={{
                                        color: 'black'
                                    }}
                                    value={elem.reservedCount}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    style={{
                                        color: 'black'
                                    }}
                                    value={elem.min}
                                />
                            </div>
                        </React.Fragment>
                    );
                }
            },
            {
                title: textToColumn(formatMessage({ id: 'storage.ordered' }), formatMessage({ id: 'storage.max' })),
                width: 'auto',
                key: 'storageBalanceOrderedAndMax',
                render: elem => {
                    return (
                        <React.Fragment>
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    step={0.1}
                                    style={{
                                        color: 'black'
                                    }}
                                    value={elem.countInStoreOrders}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    step={0.1}
                                    style={{
                                        color: 'black'
                                    }}
                                    value={elem.max}
                                />
                            </div>
                        </React.Fragment>
                    );
                }
            },
            {
                title: textToColumn(
                    formatMessage({ id: 'storage.deficit' }),
                    formatMessage({ id: 'storage.to_order' })
                ),
                width: 'auto',
                key: 'storageBalanceDeficitAndToOrder',
                render: elem => {
                    return (
                        <React.Fragment>
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    step={0.1}
                                    style={{
                                        color: 'black'
                                    }}
                                    value={elem.lack}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    step={0.1}
                                    style={{
                                        color: 'black'
                                    }}
                                    value={elem.toOrder}
                                />
                            </div>
                        </React.Fragment>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'quantity',
                dataIndex: 'quantity',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            max={this.props.documentType == ADJUSTMENT ? elem.toOrder : undefined}
                            min={0}
                            onChange={value => {
                                elem.quantity = value;
                                elem.sum = value * elem.stockPrice;
                                this.setState({ update: true });
                            }}
                            step={0.1}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.sum' />,
                key: 'sum',
                width: 'auto',
                render: elem => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled
                            style={{
                                color: 'black'
                            }}
                            value={Math.round(elem.sum * 10) / 10}
                        />
                    );
                }
            },
            Table.SELECTION_COLUMN
            // {
            //     key: 'switch',
            //     width: 'auto',
            //     render: elem => {
            //         const { checked } = elem;

            //         return (
            //             <Checkbox
            //                 checked={checked}
            //                 onChange={event => {
            //                     elem.checked = event.target.checked;
            //                     this.setState({ update: true });
            //                 }}
            //             />
            //         );
            //     }
            // }
        ];

        this.incomeColumns = () => [
            {
                title: '№',
                width: '3%',
                key: 'key',
                dataIndex: 'key',
                render: data => {
                    return data + 1;
                }
            },
            {
                title: textToColumn(
                    formatMessage({ id: 'order_form_table.detail_code' }),
                    formatMessage({ id: 'order_form_table.brand' })
                ),
                width: 'auto',
                key: 'codeAndBrand',
                render: elem => {
                    return textToColumn(elem.detailCode, elem.brandName);
                }
            },
            {
                title: textToColumn(
                    `${formatMessage({ id: 'order_form_table.detail_code' })} (${formatMessage({
                        id: 'storage.supplier'
                    })})`,
                    formatMessage({ id: 'order_form_table.detail_name' })
                ),
                width: 'auto',
                key: 'SupplierCodeAndName',
                render: elem => {
                    return textToColumn(elem.tradeCode, elem.detailName);
                }
            },
            {
                title: <FormattedMessage id='storage.ordered' />,
                key: 'ordered',
                width: 'auto',
                children: [
                    {
                        title: <FormattedMessage id='order_form_table.price' />,
                        key: 'orderedStockPrice',
                        dataIndex: 'orderedStockPrice',
                        width: 'auto',
                        render: (data, elem) => {
                            return (
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    style={{
                                        color: 'black'
                                    }}
                                    value={data}
                                />
                            );
                        }
                    },
                    {
                        title: <FormattedMessage id='order_form_table.count' />,
                        key: 'orderedQuantity',
                        dataIndex: 'orderedQuantity',
                        width: 'auto',
                        render: (data, elem) => {
                            return (
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    style={{
                                        color: 'black'
                                    }}
                                    value={data}
                                />
                            );
                        }
                    },
                    {
                        title: <FormattedMessage id='order_form_table.sum' />,
                        key: 'orderedSum',
                        width: 'auto',
                        render: elem => {
                            return (
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    style={{
                                        color: 'black'
                                    }}
                                    value={elem.orderedSum || 0}
                                />
                            );
                        }
                    }
                ]
            },
            {
                title: <FormattedMessage id='came_in' />,
                key: 'income',
                width: 'auto',
                children: [
                    {
                        title: <FormattedMessage id='order_form_table.price' />,
                        key: 'stockPrice',
                        dataIndex: 'stockPrice',
                        width: 'auto',
                        render: (data, elem) => {
                            return (
                                <InputNumber
                                    decimalSeparator=','
                                    min={0}
                                    onChange={value => {
                                        elem.stockPrice = value;
                                        elem.sum = value * elem.quantity;
                                        this.setState({ update: true });
                                    }}
                                    value={data}
                                />
                            );
                        }
                    },
                    {
                        title: <FormattedMessage id='order_form_table.count' />,
                        key: 'quantity',
                        dataIndex: 'quantity',
                        width: 'auto',
                        render: (data, elem) => {
                            return (
                                <InputNumber
                                    decimalSeparator=','
                                    min={0}
                                    onChange={value => {
                                        elem.quantity = value;
                                        elem.sum = value * elem.stockPrice;
                                        this.setState({ update: true });
                                    }}
                                    value={data}
                                />
                            );
                        }
                    },
                    {
                        title: <FormattedMessage id='order_form_table.sum' />,
                        key: 'sum',
                        width: 'auto',
                        render: elem => {
                            return (
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    style={{
                                        color: 'black'
                                    }}
                                    value={elem.sum || 0}
                                />
                            );
                        }
                    }
                ]
            },
            Table.SELECTION_COLUMN
            // {
            //     key: 'switch',
            //     width: 'auto',
            //     render: elem => {
            //         const { checked } = elem;

            //         return (
            //             <Checkbox
            //                 checked={checked}
            //                 onChange={event => {
            //                     elem.checked = event.target.checked;
            //                     this.setState({ update: true });
            //                 }}
            //             />
            //         );
            //     }
            // }
        ];
    }

    handleOk() {
        const { selectedRowKeys, selectedRows } = this.state;

        const result = [];

        this.setState({
            dataSource: selectedRows
        });

        if (selectedRows) {
            result.push(...selectedRows);
        }

        this.props.addDocProduct(result, true);
        this.handleCancel();
    }

    handleCancel() {
        this.setState({
            dataSource: [],
            visible: false,
            loading: true,
            selectedRowKeys: [],
            selectedRows: []
        });
    }

    fetchData() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        if (this.props.documentType == SUPPLIER) {
            const url = `${__API_URL__}/store_orders/recommended_products?businessSupplierId=${this.props.supplierId}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        return Promise.reject(new Error(response.statusText));
                    }

                    return Promise.resolve(response);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    data.map((elem, i) => {
                        elem.quantity = elem.quantity || 1;
                        elem.toOrder = elem.quantity;
                        elem.key = i;
                        elem.detailName = elem.name;
                        elem.stockPrice = elem.stockPrice || 0;
                        elem.detailCode = elem.code;
                        elem.sum = Math.round((elem.quantity * elem.stockPrice || 0) * 10) / 10;
                        elem.groupId = elem.storeGroupId;
                        elem.tradeCode = elem.supplierPartNumber;
                        elem.checked = that.props.documentType == SUPPLIER;
                    });
                    that.setState({
                        dataSource: data,
                        loading: false
                    });
                })
                .catch(function (error) {
                    console.log('error', error);
                });
        } else if (this.props.documentType == ORDERINCOME || this.props.documentType == ADJUSTMENT) {
            const url = `${__API_URL__}/store_orders/ordered_products?businessSupplierId=${this.props.supplierId}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        return Promise.reject(new Error(response.statusText));
                    }

                    return Promise.resolve(response);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    data.map((elem, i) => {
                        elem.quantity = elem.quantity || 1;
                        elem.orderedSum = elem.sum;
                        elem.orderedStockPrice = elem.stockPrice;
                        elem.orderedQuantity = elem.quantity;
                        elem.productId = elem.id;
                        elem.toOrder = elem.quantity;
                        elem.brandName = elem.brand && elem.brand.name;
                        elem.key = i;
                        elem.detailName = elem.name;
                        elem.detailCode = elem.code;
                        elem.stockPrice = elem.stockPrice || 0;
                        elem.sum = Math.round((elem.quantity * elem.stockPrice || 0) * 10) / 10;
                        elem.orderedSum = elem.sum;
                        elem.groupId = elem.storeGroupId;
                        elem.tradeCode = elem.supplierPartNumber;
                        elem.checked = true;
                        elem.addToAddress = _.get(elem, 'cellAddresses[0]');
                    });
                    that.setState({
                        dataSource: data,
                        loading: false
                    });
                })
                .catch(function (error) {
                    console.log('error', error);
                });
        }
    }

    render() {
        const { disabled, documentType, operationCode, isMobile } = this.props;
        const { visible, dataSource, loading, selectedRowKeys, selectedRows } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            }
        };

        const data = dataSource.filter((elem, index) => dataSource.findIndex(item => item.id === elem.id) === index);

        return (
            <div>
                <Tooltip title={<FormattedMessage id='storage_document.order_to_supplier' />}>
                    <Button
                        disabled={disabled}
                        icon={<CheckCircleOutlined style={{ fontSize: 24 }} />}
                        onClick={() => {
                            this.fetchData();
                            this.setState({
                                visible: true
                            });
                        }}
                        size='large'
                        style={{ marginLeft: 8 }}
                        type='text'
                    />
                </Tooltip>
                <Modal
                    maskClosable={false}
                    onCancel={() => {
                        this.handleCancel();
                    }}
                    onOk={() => {
                        this.handleOk();
                    }}
                    title={<FormattedMessage id='storage_document.order_to_supplier' />}
                    visible={visible}
                    width='fit-content'
                >
                    <Table
                        bordered
                        columns={this.props.documentType === ORDERINCOME ? this.incomeColumns() : this.orderColumns()}
                        dataSource={operationCode == 'COM' ? data : dataSource}
                        loading={loading}
                        pagination={{ pageSize: 6 }}
                        rowSelection={rowSelection}
                        scroll={isMobile && { x: 1080 }}
                        size='small'
                    />
                </Modal>
            </div>
        );
    }
}

function textToColumn(textFirst, textSecond) {
    return (
        <div>
            <p>{textFirst}</p>
            <p>{textSecond}</p>
        </div>
    );
}
