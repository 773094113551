import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Switch, Table, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency } from 'utils';
import AddTaxModal from './addTaxModal';

const TaxesTab = ({ intl: { formatMessage }, initialEmployee, fetchEmployee, employeeId, ...props }) => {
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addTaxModalOpen, setAddTaxModalOpen] = useState(false);
    const [editObject, setEditObject] = useState(null);

    const openAddTaxModal = eObj => {
        setAddTaxModalOpen(true);
        setEditObject(eObj);
    };
    const closeAddTaxModal = () => {
        setAddTaxModalOpen(false);
        setEditObject(null);
    };

    const fetchSalaryTaxes = async () => {
        const response = await fetchAPI('GET', '/salary/taxes', null, null, { handleErrorInternally: true });
        setDataSource(response.salaryTaxes);
    };

    const deleteTaxRows = async ids => {
        await fetchAPI('DELETE', '/salary/taxes', null, { ids }, { handleErrorInternally: true });
        fetchSalaryTaxes();
    };
    useEffect(() => {
        fetchSalaryTaxes();
    }, []);

    const columns = useMemo(() => {
        return [
            {
                title: formatMessage({ id: 'orders.actions' }),
                render: (text, record) => {
                    return (
                        <Space>
                            <Button icon={<EditOutlined />} onClick={() => openAddTaxModal(record)} />
                            <Popconfirm
                                onConfirm={() => deleteTaxRows([record.id])}
                                title={`${formatMessage({ id: 'delete' })}?`}
                            >
                                <Button disabled={record.isDefault} icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    );
                }
            },
            {
                title: formatMessage({ id: 'locations.name' }),
                dataIndex: 'taxName'
            },
            {
                title: formatMessage({ id: 'info_modal.value' }),
                dataIndex: 'taxRate'
            },
            {
                title: formatMessage({ id: 'finance_measure_units' }),
                dataIndex: 'measureUnit',
                render: (unit, { isPercent, measureUnit }) => (isPercent ? measureUnit : getCurrency())
            },
            {
                title: formatMessage({ id: 'comment' }),
                dataIndex: 'comment'
            },
            {
                title: formatMessage({ id: 'requisites_for_tax' }),
                children: [
                    {
                        title: formatMessage({ id: 'recipient' }),
                        dataIndex: 'recipient'
                    },
                    {
                        title: formatMessage({ id: 'EOU_recipient_code' }),
                        dataIndex: 'code'
                    },
                    {
                        title: formatMessage({ id: 'recipient_bank' }),
                        dataIndex: 'bank'
                    },
                    {
                        title: formatMessage({ id: 'IBAN_code' }),
                        dataIndex: 'iban'
                    }
                ]
            }
        ];
    }, [formatMessage]);

    return (
        <React.Fragment>
            {initialEmployee && (
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 8
                    }}
                >
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <span
                            style={{
                                marginRight: 8
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.gross' />
                        </span>
                        <Switch
                            checked={!initialEmployee.isNettoSalary}
                            onChange={async () => {
                                await fetchAPI('PUT', `/employees/${employeeId}`, undefined, {
                                    isNettoSalary: !initialEmployee.isNettoSalary
                                });
                                await setTimeout(() => fetchEmployee(), 100);
                            }}
                            style={{
                                marginRight: 8
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <span
                            style={{
                                marginRight: 8
                            }}
                        >
                            <FormattedMessage id='employee_salary_rules.net' />
                        </span>
                        <Switch
                            checked={initialEmployee.isNettoSalary}
                            onChange={async () => {
                                await fetchAPI('PUT', `/employees/${employeeId}`, undefined, {
                                    isNettoSalary: !initialEmployee.isNettoSalary
                                });
                                await setTimeout(() => fetchEmployee(), 100);
                            }}
                        />
                    </div>
                </div>
            )}

            <div
                style={{
                    display: ' flex',
                    justifyContent: 'end',
                    marginBottom: 18
                }}
            >
                <Tooltip title={<FormattedMessage id='add_tax' />}>
                    <Button icon={<PlusOutlined />} onClick={() => openAddTaxModal(null)} />
                </Tooltip>
            </div>

            <Table
                bordered
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                // onRow={record => openAddTaxModal(record)}
                pagination={false}
                rowKey='id'
                size='small'
            />
            <AddTaxModal
                editObject={editObject}
                fetchSalaryTaxes={fetchSalaryTaxes}
                onCancel={closeAddTaxModal}
                open={addTaxModalOpen}
            />
        </React.Fragment>
    );
};
export default injectIntl(TaxesTab);
