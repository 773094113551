import { Table } from 'antd';
import { Catcher } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { columnsConfig } from './settingSalaryTableConfig';
import Styles from './styles.m.css';

@injectIntl
export default class SettingSalaryTable extends Component {
    constructor(props) {
        super(props);

        const { initialSettingSalaries } = props;
        this.uuid = _.isArray(initialSettingSalaries) ? initialSettingSalaries.length : 0;
        const keys = _.isArray(initialSettingSalaries) ? _.keys(initialSettingSalaries) : [];

        this.state = { keys: [...keys, this.uuid++] };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.initialSettingSalaries, prevProps.initialSettingSalaries)) {
            const { initialSettingSalaries } = this.props;
            this.uuid = _.isArray(initialSettingSalaries) ? initialSettingSalaries.length : 0;
            const keys = _.isArray(initialSettingSalaries) ? _.keys(initialSettingSalaries) : [];

            this.setState({ keys: [...keys, this.uuid++] });
        }
    }

    remove = key => {
        const { keys } = this.state;
        this.setState({ keys: keys.filter(value => value !== key) });
    };

    add = () => {
        const { keys } = this.state;
        this.setState({ keys: [...keys, this.uuid++] });
    };

    render() {
        const {
            initialSettingSalaries,
            form,
            user,
            createSalary,
            updateSalary,
            deleteSalary,
            loading,
            isGrantedAccess
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { keys } = this.state;

        const columns = columnsConfig(
            formatMessage,
            form,
            initialSettingSalaries,
            createSalary,
            updateSalary,
            deleteSalary,
            user,
            isGrantedAccess
        );

        return (
            <Catcher>
                <Table
                    bordered
                    className={Styles.settingSalaryTable}
                    columns={columns}
                    dataSource={keys.map(key => ({ key }))}
                    loading={loading}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    rowClassName={({ key }) => {
                        const wasEdited = _.get(this.props.fields, ['settingSalaries', key]);
                        const exists = _.get(initialSettingSalaries, [key]);

                        if (!exists) {
                            return Styles.newSalaryRow;
                        }
                        if (wasEdited) {
                            return Styles.editedSalaryRow;
                        }
                    }}
                    scroll={{ x: 1000 }}
                    size='small'
                />
            </Catcher>
        );
    }
}
