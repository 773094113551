import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import styled from 'styled-components';
import { ROW_HEIGHT } from '../dashboardConfig';

const AddOrderLink = ({
    className,
    time,
    stationNum,
    employeeId,
    onStationSelect,
    alternativeAction,
    day
}) =>
    !onStationSelect ? (
        alternativeAction ? (
            <span
                onClick={() => alternativeAction({ day, time, stationNum })}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}
            >
                <PlusOutlined
                    style={{
                        fontSize: 12
                    }}
                />
            </span>
        ) : (
            <Link
                className={className}
                to={{
                    pathname: book.addOrder,
                    state: {
                        beginDatetime: time.format('YYYY-MM-DD HH:mm'),
                        stationNum,
                        employeeId,
                        fromDashboard: true
                    }
                }}
            >
                <PlusOutlined
                    style={{
                        fontSize: 12
                    }}
                />
            </Link>
        )
    ) : (
        <a
            className={className}
            onClick={() => {
                onStationSelect(stationNum, time, employeeId);
            }}
        >
            <PlusOutlined
                style={{
                    fontSize: 12
                }}
            />
        </a>
    );

export const DashboardAddOrderCell = styled.div`
    height: ${ROW_HEIGHT}px;
    position: relative;
`;

export const DashboardAddOrderLink = styled(AddOrderLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text2);
    background: var(--lightGray);
    height: ${ROW_HEIGHT}px;
    border-bottom: 1px solid var(--text4);

    ${DashboardAddOrderCell}:hover & {
        background: var(--text2);
        color: white;
    }
`;
