import { Rate, Table } from 'antd';
import { Loader, Numeral } from 'commons';
import { FormattedDatetime, OrderStatusIcon } from 'components';
import { fetchClientOrders, setClientOrdersPageFilter } from 'core/clientOrders/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isFetching: state.ui.clientOrdersFetching,
    ordersData: state.clientOrders.ordersData,
    filter: state.clientOrders.filter,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchClientOrders,
    setClientOrdersPageFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientOrdersTab extends Component {
    constructor(props) {
        super(props);
        const { formatMessage } = this.props.intl;

        this.columns = [
            {
                title: <FormattedMessage id='client_order_tab.date' />,
                key: 'beginDatetime',
                dataIndex: 'beginDatetime',
                render: record => <FormattedDatetime datetime={record} />
            },
            {
                title: <FormattedMessage id='client_order_tab.odometer' />,
                key: 'odometer',
                align: 'right',
                render: order => <div>{order.odometer || <FormattedMessage id='long_dash' />}</div>
            },
            {
                title: <FormattedMessage id='client_order_tab.order' />,
                key: 'order',
                render: order => (
                    <React.Fragment>
                        <Link className={Styles.ordernLink} to={`${book.order}/${order.id}`}>
                            {order.num}
                        </Link>
                        <OrderStatusIcon status={order.status} />
                        {order.serviceNames && (
                            <div className={Styles.serviceNames}>
                                {[...new Set(order.serviceNames)].join(', ')}
                            </div>
                        )}
                        {order.recommendation && (
                            <div className={Styles.recommendation}>{order.recommendation}</div>
                        )}
                        {(order.cancelReason ||
                            order.cancelStatusReason ||
                            order.cancelStatusOwnReason) && (
                            <div className={Styles.cancelReason}>
                                {/* <div>{ order.cancelReason }</div> */}
                                <div>{order.cancelStatusReason}</div>
                                <div>{order.cancelStatusOwnReason}</div>
                            </div>
                        )}
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='client_order_tab.car' />,
                key: 'car',
                render: order => (
                    <React.Fragment>
                        {order.vehicleNumber && (
                            <React.Fragment>
                                <span>{order.vehicleNumber}</span>
                                <br />
                            </React.Fragment>
                        )}
                        <div className={Styles.clientVehicle}>
                            {`${order.vehicleMakeName || '-'} ${order.vehicleModelName || '-'} ${
                                order.vehicleYear || '-'
                            }`}
                        </div>
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='orders.sum_without_VAT' />,
                key: 'sum_without_VAT',
                render: order => (
                    <Numeral currency={getCurrency()} nullText='0'>
                        {order.servicesTotalSum + order.detailsTotalSum}
                    </Numeral>
                )
            },
            {
                title: <FormattedMessage id='client_order_tab.raiting' />,
                dataIndex: 'nps',
                key: 'nps',
                render: record => this._renderRatingStars(record)
            }
        ];

        this.mobileColumns = [
            {
                title: <FormattedMessage id='client_order_tab.order' />,
                key: 'order',
                render: record => (
                    <div>
                        <Link className={Styles.ordernLink} to={`${book.order}/${record.id}`}>
                            {record.num}
                        </Link>
                        <OrderStatusIcon status={record.status} />
                        <p>
                            <FormattedDatetime datetime={record.beginDatetime} />
                        </p>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='client_order_tab.car' />,
                key: 'car',
                render: order => (
                    <React.Fragment>
                        {order.vehicleNumber && (
                            <React.Fragment>
                                <span>{order.vehicleNumber}</span>
                                <br />
                            </React.Fragment>
                        )}
                        <div className={Styles.clientVehicle}>
                            {`${order.vehicleMakeName || '-'} ${order.vehicleModelName || '-'} ${
                                order.vehicleYear || '-'
                            }`}
                        </div>
                        <div>{order.odometer || <FormattedMessage id='long_dash' />}</div>
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='orders.sum_without_VAT' />,
                key: 'sum_without_VAT',
                render: order => (
                    <Numeral currency={getCurrency()} nullText='0'>
                        {order.servicesTotalSum + order.detailsTotalSum}
                    </Numeral>
                )
            }
        ];
    }

    componentDidMount() {
        const { clientId, filter } = this.props;
        this.props.fetchClientOrders({ clientId, filter });
    }

    _renderRatingStars = rating => {
        const value = rating / 2;
        const ratingStarts = (
            <Rate allowHalf className={Styles.ratingStars} defaultValue={value} disabled />
        );

        return ratingStarts;
    };

    render() {
        const {
            isFetching,
            isMobile,
            ordersData: { stats, orders, count, statusStats }
        } = this.props;

        if (isFetching || !orders) {
            return <Loader loading={isFetching} />;
        }

        const { clientId, filter } = this.props;

        const ordersRows = orders.map((item, index) => ({
            ...item,
            index,
            key: item.id
        }));

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.countOrders / 25) * 25,
            hideOnSinglePage: true,
            current: filter.page,
            onChange: page => {
                this.props.setClientOrdersPageFilter(page);
                this.props.fetchClientOrders({ clientId, filter });
            }
        };

        return isMobile ? (
            <React.Fragment>
                <div>
                    <FormattedMessage id='client_order_tab.count_of_orders' /> {count}
                </div>
                <div>
                    <FormattedMessage id='client_order_tab.completed_orders' />{' '}
                    {statusStats.success}
                </div>
                <Table
                    bordered
                    columns={this.mobileColumns}
                    dataSource={ordersRows}
                    pagination={pagination}
                    size='small'
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className={Styles.countsContainer}>
                    <h2 className={Styles.title}>
                        <FormattedMessage id='client_order_tab.count_of_orders' />
                        <span className={Styles.countNumber}>{count}</span>
                    </h2>
                    <h2 className={Styles.title}>
                        <FormattedMessage id='client_order_tab.completed_orders' />
                        <span className={Styles.countNumber}>{statusStats.success}</span>
                    </h2>
                </div>

                <Table
                    bordered
                    columns={this.columns}
                    dataSource={ordersRows}
                    pagination={pagination}
                    size='small'
                />
            </React.Fragment>
        );
    }
}
