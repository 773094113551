exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__mainCont---3GV8R{display:flex;justify-content:space-between}.styles-m__selectCont---3Iz8q{width:100%}.styles-m__analyticsBtnCont---1r9cM{display:inline-flex;justify-content:flex-end;margin-left:.2em}.styles-m__analyticsBtn---aSMk2{background-color:var(--secondary);color:#fff;padding:0 .5em}.styles-m__addAnalyticsIcon---302fz{font-size:16px;vertical-align:sub}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,4BACI,YAAa,CACb,6BACJ,CAEA,8BACI,UACJ,CAEA,oCACI,mBAAoB,CACpB,wBAAyB,CACzB,gBACJ,CAEA,gCACI,iCAAkC,CAClC,UAAY,CACZ,cACJ,CAEA,oCACI,cAAe,CACf,kBACJ","file":"styles.m.css","sourcesContent":[".mainCont {\n    display: flex;\n    justify-content: space-between;\n}\n\n.selectCont {\n    width: 100%;\n}\n\n.analyticsBtnCont {\n    display: inline-flex;\n    justify-content: flex-end;\n    margin-left: 0.2em;\n}\n\n.analyticsBtn {\n    background-color: var(--secondary);\n    color: white;\n    padding: 0 0.5em 0 0.5em;\n}\n\n.addAnalyticsIcon {\n    font-size: 16px;\n    vertical-align: sub;\n}\n"]}]);

// Exports
exports.locals = {
	"mainCont": "styles-m__mainCont---3GV8R",
	"selectCont": "styles-m__selectCont---3Iz8q",
	"analyticsBtnCont": "styles-m__analyticsBtnCont---1r9cM",
	"analyticsBtn": "styles-m__analyticsBtn---aSMk2",
	"addAnalyticsIcon": "styles-m__addAnalyticsIcon---302fz"
};