import { Form } from '@ant-design/compatible';
import { Checkbox } from 'antd';
import React from 'react';
import styled from 'styled-components';

const FormItem = Form.Item;
const StyledCheckbox = styled(Checkbox)`
    display: ${props => props.hidden && 'none'};
`;

export class DecoratedCheckbox extends React.PureComponent {
    render() {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            getFieldDecorator,
            disabled,
            rules,
            field,
            initialValue,
            children,
            onChange,
            hidden
        } = this.props;

        const checkbox = getFieldDecorator(field, {
            valuePropName: 'checked',
            initialValue: Boolean(initialValue),
            rules
        })(
            <StyledCheckbox disabled={disabled} hidden={hidden} onChange={onChange}>
                {children}
            </StyledCheckbox>
        );

        return formItem ? (
            <FormItem className={className} colon={colon} hasFeedback={hasFeedback} label={label} {...formItemLayout}>
                {checkbox}
            </FormItem>
        ) : (
            checkbox
        );
    }
}
