exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__weekPicker---6LKS8{display:flex;align-items:center}.styles-m__mobileWeekPicker---3CNzI{display:flex;align-items:center;justify-content:space-between}.styles-m__mobileWeekPicker---3CNzI>.ant-calendar-picker{width:100%}.styles-m__icon---3x8WF{cursor:pointer;padding:0 10px;border:none}.styles-m__icon---3x8WF:hover{color:var(--primary)}.styles-m__weekDays---2z8PF{margin-left:10px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACI,YAAa,CACb,kBACJ,CAEA,oCACI,YAAa,CACb,kBAAmB,CACnB,6BAKJ,CAHI,yDACI,UACJ,CAGJ,wBACI,cAAe,CACf,cAAe,CACf,WAKJ,CAHI,8BACI,oBACJ,CAGJ,4BAGI,gBACJ","file":"styles.m.css","sourcesContent":[".weekPicker {\n    display: flex;\n    align-items: center;\n}\n\n.mobileWeekPicker {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    & > :global(.ant-calendar-picker) {\n        width: 100%;\n    }\n}\n\n.icon {\n    cursor: pointer;\n    padding: 0 10px;\n    border: none;\n\n    &:hover {\n        color: var(--primary);\n    }\n}\n\n.weekDays {\n    /* background: white;\n    border: 1px var(--text3) solid; */\n    margin-left: 10px;\n}\n"]}]);

// Exports
exports.locals = {
	"weekPicker": "styles-m__weekPicker---6LKS8",
	"mobileWeekPicker": "styles-m__mobileWeekPicker---3CNzI",
	"icon": "styles-m__icon---3x8WF",
	"weekDays": "styles-m__weekDays---2z8PF"
};