import { FilterFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { Select } from 'antd/lib';
import { DateRangePicker } from 'components';
import { InteractionDrawerContainer } from 'containers';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const dateFormat = 'DD.MM.YYYY';
const { Option } = Select;

const InteractionsTable = ({ intl, isMobile, query, reqfilters = {}, reset, shouldReset, responsibleFilter }) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [interactions, setInteractions] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [filters, setFilters] = useState({ responsibleId: responsibleFilter });
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [rowId, setRowId] = useState();

    const fetchInteractions = useCallback(
        async (filt = filters) => {
            setLoading(true);
            const response = await fetchAPI(
                'GET',
                '/crm/interactions_docs',
                { page, pageSize, search: query, ...filt, ...reqfilters },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setInteractions(response.docs);
            setTotalCount(response.totalCount);
            setLoading(false);
        },
        [page, pageSize, query, filters]
    );

    const fetchStatuses = useCallback(async () => {
        const statuses = await fetchAPI('GET', '/crm/substatus', null, null, {
            handleErrorInternally: true
        });
        setStatuses(statuses);
    }, []);

    const fetchEmployees = useCallback(async () => {
        const responseEmps = await fetchAPI('GET', 'employees', null, null, {
            handleErrorInternally: true
        });
        setEmployees(responseEmps);
    }, []);

    useEffect(() => {
        fetchInteractions();
        fetchStatuses();
        fetchEmployees();
    }, []);

    useEffect(() => {
        fetchInteractions();
    }, [page, pageSize, filters, fetchInteractions]);

    useEffect(() => {
        if (responsibleFilter) setFilters({ responsibleId: responsibleFilter });
    }, [responsibleFilter]);

    useEffect(() => {
        const func = async () => {
            await setPage(1);
            fetchInteractions();
        };
        func();
    }, [query]);

    const handleSearch = async confirm => {
        confirm();
        await setPage(1);
        fetchInteractions();
    };

    const handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        const tmp = filters;
        if (dataIndex == 'startDate') {
            delete tmp.creationStartDate;
            delete tmp.creationEndDate;
        } else if (dataIndex == 'doneDate') {
            delete tmp.lastStartDate;
            delete tmp.lastEndDate;
        } else if (dataIndex == 'nextDate') {
            delete tmp.nextStartDate;
            delete tmp.nextEndDate;
        } else {
            delete tmp[dataIndex];
        }
        await setFilters(tmp);
        await setPage(1);
        fetchInteractions();
    };
    useEffect(() => {
        if (shouldReset === 'reset') {
            reset();
            setFilters({});
            fetchInteractions({});
        }
    }, [shouldReset, reset, fetchInteractions]);

    const getColumnSearchProps = useCallback(
        dataIndex => {
            let filterComponent = (confirm, clearFilters) => (
                <Input
                    onChange={e => {}}
                    onPressEnter={() => handleSearch(confirm, dataIndex)}
                    placeholder={intl.formatMessage({
                        id: 'search'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                />
            );

            if (dataIndex === 'startDate') {
                filterComponent = (confirm, clearFilters) => (
                    <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                        <DateRangePicker
                            allowClear
                            dateRange={
                                filters.creationStartDate
                                    ? [dayjs(filters.creationStartDate), dayjs(filters.creationEndDate)]
                                    : undefined
                            }
                            format={dateFormat}
                            getPopupContainer={trigger => trigger.parentNode}
                            onDateChange={async dateRange => {
                                await setFilters({
                                    ...filters,
                                    creationStartDate: dateRange[0].format('YYYY-MM-DD'),
                                    creationEndDate: dateRange[1].format('YYYY-MM-DD')
                                });
                            }}
                            placement='bottomRight'
                            popupStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: 9999,
                                minWidth: 220
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                );
            }

            if (dataIndex === 'nextDate') {
                filterComponent = (confirm, clearFilters) => (
                    <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                        <DateRangePicker
                            allowClear
                            dateRange={
                                filters.nextStartDate
                                    ? [dayjs(filters.nextStartDate), dayjs(filters.nextEndDate)]
                                    : undefined
                            }
                            format={dateFormat}
                            getPopupContainer={trigger => trigger.parentNode}
                            onDateChange={async dateRange => {
                                await setFilters({
                                    ...filters,
                                    nextStartDate: dateRange[0].format('YYYY-MM-DD'),
                                    nextEndDate: dateRange[1].format('YYYY-MM-DD')
                                });
                            }}
                            popupStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: 9999,
                                minWidth: 220
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                );
            }

            if (dataIndex === 'doneDate') {
                filterComponent = (confirm, clearFilters) => (
                    <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                        <DateRangePicker
                            allowClear
                            dateRange={
                                filters.lastStartDate
                                    ? [dayjs(filters.lastStartDate), dayjs(filters.lastEndDate)]
                                    : undefined
                            }
                            format={dateFormat}
                            getPopupContainer={trigger => trigger.parentNode}
                            onDateChange={async dateRange => {
                                await setFilters({
                                    ...filters,
                                    lastStartDate: dateRange[0].format('YYYY-MM-DD'),
                                    lastEndDate: dateRange[1].format('YYYY-MM-DD')
                                });
                            }}
                            popupStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: 9999,
                                minWidth: 220
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                );
            }

            if (dataIndex === 'statusId') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        mode='multiple'
                        onChange={value => {
                            setFilters({ ...filters, [dataIndex]: value });
                        }}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={filters[dataIndex]}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        {statuses.map(({ id, name }) => (
                            <Option key={id} value={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                );
            }

            if (dataIndex === 'counterpartyType') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={value => {
                            setFilters({ ...filters, [dataIndex]: value });
                        }}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={filters[dataIndex]}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        <Option key='CLIENT' value='CLIENT'>
                            <FormattedMessage id='task_page.client' />
                        </Option>
                        <Option key='EMPLOYEE' value='EMPLOYEE'>
                            <FormattedMessage id='employee' />
                        </Option>
                        <Option key='SUPPLIER' value='SUPPLIER'>
                            <FormattedMessage id='suppliers_page.supplier' />
                        </Option>
                    </Select>
                );
            }

            if (dataIndex === 'responsibleId') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={value => {
                            setFilters({ ...filters, [dataIndex]: value });
                        }}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={filters[dataIndex]}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        {employees
                            .filter(({ disabled, isManager }) => !disabled && isManager)
                            .map(({ managerId, name, surname }) => (
                                <Option key={managerId} value={managerId}>
                                    {surname} {name}
                                </Option>
                            ))}
                    </Select>
                );
            }

            return {
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        {filterComponent(confirm, clearFilters)}
                        {dataIndex !== '11' && dataIndex !== 'filterCreatedDate' && dataIndex !== 'filtertDoneDate' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Button onClick={() => handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                    </div>
                ),
                filterIcon: () => {
                    let colorFilter;
                    if (filters[dataIndex]) {
                        colorFilter = 'var(--primary)';
                    }
                    if (dataIndex == 'startDate' && filters.creationStartDate) {
                        colorFilter = 'var(--primary)';
                    }

                    if (dataIndex == 'doneDate' && filters.lastStartDate) {
                        colorFilter = 'var(--primary)';
                    }

                    if (dataIndex == 'nextDate' && filters.nextStartDate) {
                        colorFilter = 'var(--primary)';
                    }

                    return (
                        <FilterFilled
                            style={{
                                fontSize: 14,
                                color: colorFilter
                            }}
                        />
                    );
                }
                // onFilterDropdownVisibleChange: visible => {
                //     if (visible) {
                //         setTimeout(() => this.searchInput.select(), 100);
                //     }
                // }
            };
        },
        [handleReset, handleSearch, intl, filters, statuses]
    );

    const openInteractionDrawer = id => {
        setRowId(id);
    };

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='№' />,
                key: 'num',
                render: (row, number, index) => {
                    return index + 1;
                }
            },
            {
                title: <FormattedMessage id='suppliers_page.contact' />,
                key: 'contact',
                width: '10%',
                render: row => {
                    return row.document ? (
                        <div>
                            <Link to={`${book.interactions}/${row.id}`}>{row.document}</Link>
                            <span
                                style={{
                                    marginLeft: 8,
                                    fontSize: 16
                                }}
                            >
                                <InfoCircleOutlined onClick={() => openInteractionDrawer(row.id)} />
                            </span>
                        </div>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='universal_filters_tags.beginDate' />,
                key: 'crDate',
                ...getColumnSearchProps('startDate'),
                render: row => {
                    return row.creationDate ? (
                        dayjs(row.creationDate).format('DD.MM.YYYY ')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='interactions.next_date' />,
                key: 'nextDate',
                ...getColumnSearchProps('nextDate'),
                render: row => {
                    return row.nextDate ? (
                        dayjs(row.nextDate).format('DD.MM.YYYY ')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='interactions.done_date' />,
                key: 'lastContact',
                ...getColumnSearchProps('doneDate'),
                render: row => {
                    return row.lastContact ? (
                        dayjs(row.lastContact).format('DD.MM.YYYY ')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='interactions.counterparty_type' />,
                key: 'counterparty_type',
                ...getColumnSearchProps('counterpartyType'),
                render: row => {
                    return row.counterpartyType ? (
                        <FormattedMessage id={`interactions.counterparty_type.${row.counterpartyType}`} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_docs.counterparty' />,
                key: 'counterparty',
                render: row => {
                    return (
                        <React.Fragment>
                            {row.counterpartyType === 'CLIENT' ? (
                                <Link to={`${book.client}/${row.counterpartyId}`}>{row.client}</Link>
                            ) : row.counterpartyType === 'SUPPLIER' ? (
                                <Link to={`${book.supplier}/${row.counterpartyId}`}>{row.client}</Link>
                            ) : row.counterpartyType === 'EMPLOYEE' ? (
                                <Link to={`${book.employeesPage}/${row.counterpartyId}`}>{row.client}</Link>
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                            <div>
                                <a href={`tel:${row.phone}`}>{row.phone}</a>
                            </div>
                        </React.Fragment>
                    );
                }
            },
            {
                title: <FormattedMessage id='interactions.category' />,
                key: 'category',
                render: row => {
                    return row.interactionCategory ? row.interactionCategory : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='storage.type' />,
                key: 'type',
                render: row => {
                    return row.interactionType ? row.interactionType : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='status' />,
                key: 'status',
                ...getColumnSearchProps('statusId'),
                render: row => {
                    return row.status ? row.status : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='orders.source' />,
                key: 'source',
                render: row => {
                    return row.source ? row.source : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'lastComment',
                render: row => {
                    return row.lastComment ? row.lastComment : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='responsible' />,
                key: 'responsible',
                ...getColumnSearchProps('responsibleId'),
                render: row => {
                    return row.responsible ? row.responsible : <FormattedMessage id='long_dash' />;
                }
            }
        ];
    }, [getColumnSearchProps]);

    const pagination = {
        pageSize,
        total: Math.ceil(totalCount / pageSize) * pageSize,
        hideOnSinglePage: true,
        current: page,
        onChange: async (page, pageSize) => {
            await setPage(page);
            await setPageSize(pageSize);
        }
    };

    return (
        <div>
            {' '}
            <InteractionDrawerContainer
                fetchInteractions={fetchInteractions}
                onClose={() => setRowId(undefined)}
                open={rowId}
            />{' '}
            <Table
                columns={columns}
                dataSource={interactions}
                loading={loading}
                pagination={pagination}
                scroll={isMobile ? { x: 1000 } : {}}
                size='small'
            />
        </div>
    );
};

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

export default connect(mapStateToProps)(injectIntl(InteractionsTable));
