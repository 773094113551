import { notification } from 'antd';
import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import nprogress from 'nprogress';
import { generateOnlineServiceBookLink } from 'pages/VehiclePage/utils';
import { all, call, put, select, take } from 'redux-saga/effects';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI, getLocale } from 'utils';
import {
    CREATE_ORDER,
    DELETE_VEHICLE,
    fetchVehicleAppurtenancesSuccess,
    fetchVehicleAttributesSuccess,
    fetchVehicleInspectionIntervalsSuccess,
    fetchVehicleLaborsSuccess,
    fetchVehicleNormHoursSuccess,
    fetchVehicleOrdersLatestSuccess,
    fetchVehicleOrdersSuccess,
    fetchVehicleRecommendationsSuccess,
    fetchVehicleSuccess,
    FETCH_VEHICLE,
    FETCH_VEHICLE_APPURTENANCES,
    FETCH_VEHICLE_ATTRIBUTES,
    FETCH_VEHICLE_INSPECTION_INTERVALS,
    FETCH_VEHICLE_LABORS,
    FETCH_VEHICLE_NORM_HOURS,
    FETCH_VEHICLE_ORDERS,
    FETCH_VEHICLE_ORDERS_LATEST,
    FETCH_VEHICLE_RECOMMENDATIONS,
    selectClient,
    selectExpandedVehicleId,
    selectVehicle,
    selectVehicleAppurtenancesFilters,
    selectVehicleAppurtenancesSort,
    selectVehicleLaborsFilters,
    selectVehicleLaborsSort,
    selectVehicleNormHoursFilters,
    selectVehicleNormHoursSort,
    selectVehicleOrdersSort,
    SEND_EMAIL_WITH_SERVICE_BOOK_LINK,
    SEND_SMS_WITH_SERVICE_BOOK_LINK,
    setFetchingOrdersLatest,
    setFetchingVehicle,
    setFetchingVehicleAppurtenances,
    setFetchingVehicleAttributes,
    setFetchingVehicleClient,
    setFetchingVehicleInspectionIntervals,
    setFetchingVehicleLabors,
    setFetchingVehicleNormHours,
    setFetchingVehicleOrders,
    setFetchingVehicleRecommendations
} from './duck';

export function* fetchVehicleSaga() {
    while (true) {
        try {
            const {
                payload: { vehicleId }
            } = yield take(FETCH_VEHICLE);

            yield nprogress.start();

            yield put(setFetchingVehicle(true));
            yield put(setFetchingVehicleClient(true));

            const vehicle = yield call(fetchAPI, 'GET', `clients/vehicles/${vehicleId}`);

            yield put(setFetchingVehicle(false));
            yield put(fetchVehicleSuccess({ vehicle }));

            if (vehicle.clientId) {
                const { clientId } = vehicle;

                const client = yield call(fetchAPI, 'GET', `clients/${clientId}`);

                yield put(setFetchingVehicleClient(false));
                yield put(fetchVehicleSuccess({ client }));
            }
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();

            yield put(setFetchingVehicle(false));
            yield put(setFetchingVehicleClient(false));
        }
    }
}

export function* fetchVehicleAttributesSaga() {
    while (true) {
        try {
            const {
                payload: { vehicleId }
            } = yield take(FETCH_VEHICLE_ATTRIBUTES);

            yield nprogress.start();

            yield put(setFetchingVehicleAttributes(true));

            const vehicleAttributes = yield call(fetchAPI, 'GET', 'tecdoc/vehicle/attributes', {
                vehicleId
            });

            yield put(setFetchingVehicleAttributes(false));

            yield put(fetchVehicleAttributesSuccess({ vehicleAttributes }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();

            yield put(setFetchingVehicleAttributes(false));
        }
    }
}

export function* fetchVehicleOrdersLatestSaga() {
    while (true) {
        try {
            const {
                payload: { vehicleId }
            } = yield take(FETCH_VEHICLE_ORDERS_LATEST);

            yield nprogress.start();

            yield put(setFetchingOrdersLatest(true));

            const generalData = yield call(fetchAPI, 'GET', 'order_latest_info', {
                vehicleId
            });
            yield put(setFetchingOrdersLatest(false));

            yield put(fetchVehicleOrdersLatestSuccess({ generalData }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();

            yield put(setFetchingOrdersLatest(false));
        }
    }
}

export function* fetchVehicleOrdersSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_ORDERS);

            yield put(setFetchingVehicleOrders(true));

            const vehicleId = yield select(selectExpandedVehicleId);

            const sort = yield select(selectVehicleOrdersSort);

            yield nprogress.start();

            const { orders, stats, vehicleData } = yield call(
                fetchAPI,
                'GET',
                `orders/vehicle/${vehicleId}`,
                {
                    page: sort.page
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchVehicleOrdersSuccess({ orders, stats, vehicleData }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingVehicleOrders(false));
        }
    }
}

export function* fetchVehicleNormHoursSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_NORM_HOURS);

            yield put(setFetchingVehicleNormHours(true));

            const sort = yield select(selectVehicleNormHoursSort);

            const filters = yield select(selectVehicleNormHoursFilters);

            const vehicleId = yield select(selectExpandedVehicleId);

            yield nprogress.start();

            const { standardHours: normHours, statsStandardHours: normHoursStats } = yield call(
                fetchAPI,
                'GET',
                'standard_hours',
                { vehicleId, page: sort.page, query: filters.query }
            );

            yield put(fetchVehicleNormHoursSuccess({ normHours, stats: normHoursStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingVehicleNormHours(false));
        }
    }
}

export function* fetchVehicleLaborsSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_LABORS);

            yield put(setFetchingVehicleLabors(true));

            const vehicleId = yield select(selectExpandedVehicleId);

            const sort = yield select(selectVehicleLaborsSort);
            const filters = yield select(selectVehicleLaborsFilters);

            yield nprogress.start();

            const { labors, laborsStats } = yield call(fetchAPI, 'GET', `orders/labors/${vehicleId}`, {
                ...sort,
                ...filters
            });

            yield put(fetchVehicleLaborsSuccess({ labors, stats: laborsStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingVehicleLabors(false));
        }
    }
}

export function* fetchVehicleAppurtenancesSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_APPURTENANCES);

            yield put(setFetchingVehicleAppurtenances(true));

            const vehicleId = yield select(selectExpandedVehicleId);

            const sort = yield select(selectVehicleAppurtenancesSort);
            const filters = yield select(selectVehicleAppurtenancesFilters);

            yield nprogress.start();

            const { appurtenances, appurtenancesStats } = yield call(
                fetchAPI,
                'GET',
                `orders/appurtenances/${vehicleId}`,
                { ...sort, ...filters }
            );

            yield put(fetchVehicleAppurtenancesSuccess({ appurtenances, stats: appurtenancesStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingVehicleAppurtenances(false));
        }
    }
}

export function* fetchVehicleRecommendationsSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_RECOMMENDATIONS);

            yield put(setFetchingVehicleRecommendations(true));

            const vehicleId = yield select(selectExpandedVehicleId);

            yield nprogress.start();

            const recommendations = yield call(fetchAPI, 'GET', 'recommendations/labors', {
                vehicleId
            });

            yield put(fetchVehicleRecommendationsSuccess({ recommendations }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingVehicleRecommendations(false));
        }
    }
}

export function* fetchVehicleInspectionIntervalsSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_INSPECTION_INTERVALS);

            yield put(setFetchingVehicleInspectionIntervals(true));

            const vehicleId = yield select(selectExpandedVehicleId);

            const vehicle = yield select(selectVehicle);

            yield nprogress.start();

            const { intervals } = yield call(
                fetchAPI,
                'GET',
                'tecdoc/intervals',
                { vehicleId, tecdocId: vehicle.tecdocId },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchVehicleInspectionIntervalsSuccess({ inspectionIntervals: intervals }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingVehicleInspectionIntervals(false));
        }
    }
}

export function* createOrderSaga() {
    while (true) {
        const {
            payload: { clientId, managerId, vehicleId }
        } = yield take(CREATE_ORDER);

        if (!clientId) {
            continue;
        }

        const client = yield call(fetchAPI, 'GET', `clients/${clientId}`); // Get client

        try {
            // Create new order
            const response = yield call(
                fetchAPI,
                'POST',
                'orders',
                null,
                {
                    clientId: client.clientId,
                    clientVehicleId: vehicleId || void 0,
                    duration: 0.5,
                    clientPhone: client.phones[0],
                    stationLoads: [
                        {
                            beginDatetime: dayjs().startOf('hour').toISOString(),
                            duration: 0.5,
                            status: 'TO_DO'
                        }
                    ],
                    status: 'not_complete',
                    managerId,
                    beginDatetime: dayjs().startOf('hour').toISOString()
                },
                { handleErrorInternally: true }
            );

            if (response && response.created) {
                // If successfully created new order redirect on its page
                history.push({
                    pathname: `${book.order}/${response.created[0].id}`
                });
            }
        } catch (err) {
            const { response } = err;
            console.error(err);
            response &&
                notification.error({
                    message: response.message
                });
        }
    }
}

export function* deleteVehicleSaga() {
    while (true) {
        const {
            payload: { vehicleId }
        } = yield take(DELETE_VEHICLE);

        try {
            yield call(fetchAPI, 'DELETE', `clients/vehicles/${vehicleId}`, null, null, {
                handleErrorInternally: true
            });
        } catch ({ response, status }) {
            yield put(emitError({ response, status }));
        }
    }
}

export function* sendMailWithServiceBookLinkSaga() {
    while (true) {
        try {
            yield take(SEND_EMAIL_WITH_SERVICE_BOOK_LINK);

            const client = yield select(selectClient);

            if (!client || _.isEmpty(client)) {
                return;
            }

            const emails = _.get(client, 'emails');
            const user = yield select(state => state.auth); // Select current user
            const vehicle = yield select(selectVehicle);

            if (!emails || _.isEmpty(emails) || !vehicle || !vehicle.id) {
                return;
            }

            const link = yield call(async () =>
                generateOnlineServiceBookLink({
                    vehicleId: _.get(vehicle, 'id'),
                    businessId: _.get(user, 'businessId')
                }));

            yield call(
                fetchAPI,
                'POST',
                'send-mail',
                null,
                {
                    receivers: emails,
                    lang: getLocale() === 'uk' ? 'ua' : getLocale(),
                    data: {
                        link
                    },
                    templateName: 'mail-online-service-book'
                },
                { handleErrorInternally: true, rawResponse: true }
            );

            notification.success();
        } catch (error) {
            yield put(emitError(error));
            notification.error();
        }
    }
}

export function* sendSmsWithServiceBookLinkSaga() {
    while (true) {
        try {
            yield take(SEND_SMS_WITH_SERVICE_BOOK_LINK);

            const client = yield select(selectClient);
            const user = yield select(state => state.auth); // Select current user

            if (!client || _.isEmpty(client)) {
                return;
            }
            const phones = _.get(client, 'phones');
            const vehicle = yield select(selectVehicle);

            if (!phones || _.isEmpty(phones) || !vehicle || !vehicle.id) {
                return;
            }

            const link = yield call(async () =>
                generateOnlineServiceBookLink({
                    vehicleId: _.get(vehicle, 'id'),
                    businessId: _.get(user, 'businessId')
                }));

            yield call(
                fetchAPI,
                'POST',
                'send-sms',
                null,
                {
                    receivers: phones,
                    locale: getLocale() == 'uk' ? 'ua' : getLocale(),
                    data: {
                        link,
                        clientId: _.get(client, 'clientId'),
                        orderId: _.get(client, 'lastOrderId')
                    },
                    templateName: 'sms-online-service-book'
                },
                { handleErrorInternally: true, rawResponse: true }
            );

            notification.success();
        } catch (error) {
            yield put(emitError(error));
            notification.error();
        }
    }
}

export function* saga() {
    yield all([
        call(fetchVehicleSaga),
        call(fetchVehicleOrdersLatestSaga),
        call(fetchVehicleAttributesSaga),
        call(fetchVehicleOrdersSaga),
        call(fetchVehicleNormHoursSaga),
        call(fetchVehicleLaborsSaga),
        call(fetchVehicleAppurtenancesSaga),
        call(fetchVehicleRecommendationsSaga),
        call(fetchVehicleInspectionIntervalsSaga),
        call(createOrderSaga),
        call(deleteVehicleSaga),
        call(sendMailWithServiceBookLinkSaga),
        call(sendSmsWithServiceBookLinkSaga)
    ]);
}
