import { Input, Modal, notification, Select } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@injectIntl
export default class AddCrossLocationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            if (this.props.locationsModalRow) {
                this.setState({
                    ...this.props.locationsModalRow,
                    supplierId: this.props.locationsModalRow.supplierId,
                    excelName: this.props.locationsModalRow.excelName,
                    code: this.props.locationsModalRow.code
                });
            } else {
                this.setState({
                    supplierId: undefined,
                    excelName: undefined,
                    condition: undefined,
                    code: undefined
                });
            }
        }
    }

    handleOk = async () => {
        if (this.props.locationsModalRow) {
            await this.editLocationsRow();
            await setTimeout(() => this.props.fetchData(), 500);
            this.handleCancel();
        } else {
            await this.addLocationsRow();
            await setTimeout(() => this.props.fetchData(), 500);
            this.handleCancel();
        }
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'barcode.success' })
        });
    };

    handleCancel() {
        this.setState({
            supplierId: undefined,
            excelName: undefined,
            condition: undefined,
            code: undefined
        });
        this.props.hideModal();
    }

    addLocationsRow = async () => {
        const { supplierId, excelName, condition, code } = this.state;
        await fetchAPI('POST', 'pricelists/cross', undefined, {
            supplierId,
            excelName,
            code
        });
    };

    editLocationsRow = async () => {
        const { supplierId, excelName, condition, code } = this.state;
        const { locationsModalRow } = this.props;
        await fetchAPI('PUT', `pricelists/cross/${locationsModalRow.id}`, undefined, {
            supplierId,
            excelName,
            code
        });
    };

    render() {
        const { detailsTreeData, locationsModalRow, suppliers, allLocations, handleSearchSuppliers } = this.props;
        const { supplierId, excelName, condition, code } = this.state;

        return (
            <Modal
                destroyOnClose
                maskClosable={false}
                okButtonProps={{
                    disabled: !code || !supplierId || !excelName
                }}
                onCancel={() => {
                    this.handleCancel();
                }}
                onOk={() => {
                    this.handleOk();
                }}
                title={
                    locationsModalRow ? (
                        <FormattedMessage id='regulations.edit_row' />
                    ) : (
                        <FormattedMessage id='regulations.add_row' />
                    )
                }
                visible={this.props.visible}
                width='fit-content'
                zIndex={200}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        margin: '24px 0 0 0'
                    }}
                >
                    <div className={Styles.addProductItemWrap}>
                        <FormattedMessage id='storage_document.supplier' /> <span style={{ color: 'red' }}>*</span>
                        <Select
                            allowClear
                            dropdownMatchSelectWidth={420}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={(value, option) => {
                                this.setState({
                                    supplierId: value
                                });
                            }}
                            onSearch={input => handleSearchSuppliers(input)}
                            optionFilterProp='children'
                            placeholder={this.props.intl.formatMessage({
                                id: 'storage_document.supplier_select'
                            })}
                            onSearch={input => handleSearchSuppliers(input)}
                            showAction={['focus', 'click']}
                            showSearch
                            style={{ minWidth: 150, color: 'var(--text)' }}
                            value={supplierId}
                        >
                            {suppliers.map(elem => (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div className={Styles.addProductItemWrap} style={{ minWidth: 200 }}>
                        <FormattedMessage id='pricelists.supplier_location_name' />{' '}
                        <span style={{ color: 'red' }}>*</span>
                        <Input
                            onChange={event => {
                                this.setState({
                                    excelName: event.target.value
                                });
                            }}
                            style={{ minWidth: 200, color: 'var(--text)' }}
                            value={excelName}
                        />
                    </div>
                    <div className={Styles.addProductItemWrap} style={{ minWidth: 200 }}>
                        <FormattedMessage id='pricelists.carbook_location_name' />{' '}
                        <span style={{ color: 'red' }}>*</span>
                        <Select
                            allowClear
                            dropdownMatchSelectWidth={420}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={(value, option) => {
                                this.setState({
                                    code: value
                                });
                            }}
                            optionFilterProp='children'
                            placeholder={this.props.intl.formatMessage({
                                id: 'storage_document.supplier_select'
                            })}
                            showAction={['focus', 'click']}
                            showSearch
                            style={{ minWidth: 200, color: 'var(--text)' }}
                            value={code}
                        >
                            {allLocations.map(elem => (
                                <Option key={elem.code} value={elem.code}>
                                    {`${elem.name}, ${elem.code}`}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    {/* <div className={Styles.addProductItemWrap}>
                        <FormattedMessage id='services_table.store_group' />
                        <Input
                            onChange={event => {
                                this.setState({
                                    condition: event.target.value
                                });
                            }}
                            value={condition}
                        />
                    </div> */}
                </div>
            </Modal>
        );
    }
}
