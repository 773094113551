import { Table } from 'antd';
import { DataItem, Numeral } from 'commons';
import { setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const DATETIME_FORMAT = 'YYYY-MM-DD';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

/**
 * Table of inspection intervals
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BookRegulationsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            selectedRows: []
        };

        this.columns = (withTitle = true, titleForEachTable) => [
            {
                title: titleForEachTable,
                key: 'name',
                dataIndex: 'regulationName',
                width: '40%',
                render: (data, row) => {
                    return (
                        <div
                            className={
                                row.color == 'GREEN' && row.nextChangeDate
                                    ? Styles.underlineStatusColorGreen
                                    : row.color == 'YELLOW'
                                    ? Styles.underlineStatusColorYellow
                                    : row.color == 'RED'
                                    ? Styles.underlineStatusColorRed
                                    : undefined
                            }
                        >
                            {data}
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.regulation' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.months' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'typeRegulationMonth',
                dataIndex: 'months',
                align: 'center',
                width: '10%'
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.regulation' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'typeRegulationKm',
                dataIndex: 'km',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.last_change' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'lastChangeKm',
                dataIndex: 'lastKm',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.last_change' />
                            </div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'lastChangeDate',
                dataIndex: 'lastDate',
                align: 'center',
                width: '10%',
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY') : undefined;
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.next_change' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'nextChangeKm',
                dataIndex: 'nextChangeKm',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.next_change' />
                            </div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'nextChangeDate',
                dataIndex: 'nextChangeDate',
                align: 'center',
                width: '10%',
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY') : undefined;
                }
            }
        ];
    }

    render() {
        const { data, allVehicleRegulations, isMobile } = this.props;

        return !isMobile ? (
            <div className={Styles.tableCont}>
                <span className={Styles.odometrAndDateInfo}>
                    <FormattedMessage id='regulations.current_odometr_and_date' />
                    {': '}
                    <span
                        style={{
                            fontWeight: 700
                        }}
                    >
                        {_.get(data, 'currentVehicleInfo.maxMileage') ? (
                            <Numeral mask='0,0'>{Number(data.currentVehicleInfo.maxMileage)}</Numeral>
                        ) : (
                            0
                        )}{' '}
                        <FormattedMessage id='online_service_book_page.km' />
                        {', '}
                        {_.get(data, 'currentVehicleInfo.maxDate')
                            ? dayjs(data.currentVehicleInfo.maxDate).format('YYYY.MM.DD')
                            : '-'}
                    </span>
                </span>
                {allVehicleRegulations.map(({ groupId, groupName, regulations }, index) => (
                    <Table
                        key={groupId}
                        bordered
                        columns={this.columns(!index, groupName)}
                        dataSource={regulations}
                        pagination={false}
                        rowKey='id'
                        scroll={{ x: 'auto', y: '45vh' }}
                        size='small'
                    />
                ))}
            </div>
        ) : (
            <div>
                {allVehicleRegulations.map(({ groupId, groupName, regulations }) => (
                    <div className={Styles.firtBlockInfoStyle}>
                        <div className={Styles.titleForMobile}>{groupName}</div>
                        {regulations.map(
                            ({ regulationName, lastKm, lastDate, nextChangeKm, nextChangeDate }, index) => (
                                <div className={Styles.regBlock}>
                                    <div className={Styles.dataItemBlock}>
                                        <DataItem
                                            className={Styles.dataItemMobile}
                                            label={<FormattedMessage id='regulations.regulation' />}
                                        >
                                            {`${index + 1}.${regulationName}`}
                                        </DataItem>
                                        <DataItem
                                            className={Styles.dataItemMobile}
                                            label={<FormattedMessage id='regulations.last_change_by' />}
                                        >
                                            <Numeral mask='0,0'>{Number(lastKm) || '-'}</Numeral>{' '}
                                            <FormattedMessage id='online_service_book_page.km' />{' '}
                                            {lastDate ? dayjs(lastDate).format(DATETIME_FORMAT) : '-'}
                                        </DataItem>
                                        <DataItem
                                            className={Styles.dataItemMobile}
                                            label={<FormattedMessage id='regulations.next_change_by' />}
                                        >
                                            <Numeral mask='0,0'>{Number(nextChangeKm) || '-'}</Numeral>{' '}
                                            <FormattedMessage id='online_service_book_page.km' />{' '}
                                            {nextChangeDate ? dayjs(nextChangeDate).format(DATETIME_FORMAT) : '-'}
                                        </DataItem>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                ))}
            </div>
        );
    }
}
