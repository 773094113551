import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Recommendation = ({ like, text, className, iconClassName, textClassName }) => (
    <React.Fragment>
        <div className={className}>
            {like ? (
                <LikeOutlined className={iconClassName} />
            ) : (
                <DislikeOutlined className={iconClassName} />
            )}
            {text && (
                <Text className={textClassName}>
                    {like ? (
                        <FormattedMessage id='recommend' />
                    ) : (
                        <FormattedMessage id='not_recommend' />
                    )}
                </Text>
            )}
        </div>
    </React.Fragment>
);

const Text = styled.div`
    font-size: 18px;
    display: flex;
    align-items: center;
`;

export const Like = styled(Recommendation)`
    display: flex;
    align-items: center;
    font-size: 28px;
    color: ${props => (props.like ? 'var(--enabled)' : 'var(--disabled)')};
`;
