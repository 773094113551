import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchSubtasksSuccess, FETCH_SUBTASKS, selectFilters, setFetching } from './duck';

export function* fetchSubtasksSaga() {
    while (true) {
        try {
            const { payload } = yield take(FETCH_SUBTASKS);

            yield put(setFetching(true));

            const filters = yield select(selectFilters);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', `employee_subtasks/${payload}`, { filters });

            yield put(fetchSubtasksSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetching(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchSubtasksSaga)]);
}
