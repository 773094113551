import { Table } from 'antd';
import { selectBrandsByQuery, setBrandsSearchQuery } from 'core/search/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import { selectStoreProductsExcelLoading } from 'core/storage/products';
import { fetchStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const StyledTable = styled(Table)`
    /* &
        .ant-table-small
        > .ant-table-content
        > .ant-table-body
        > table
        > .ant-table-tbody
        > tr
        > td {
        padding: 0;
    } */

    &
        .ant-table-small
        > .ant-table-content
        > .ant-table-scroll
        > .ant-table-body
        > table
        > .ant-table-tbody
        > tr
        > td {
        padding: 0;
    }

    & .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0;
    }

    & .ant-select-selection,
    .ant-input,
    .ant-input-number,
    .ant-input-number-input {
        border-radius: 0;
    }
`;

const ProductsExcelTableComponent = props => {
    const {
        invalidProductsExcel,
        form,
        storeGroups,
        priceGroups,
        brands,
        setBrandsSearchQuery,
        intl: { formatMessage }
    } = props;

    useEffect(() => {
        props.fetchPriceGroups();
        props.fetchStoreGroups();
    }, []);

    const columns = columnsConfig(
        invalidProductsExcel,
        form,
        formatMessage,
        storeGroups,
        priceGroups,
        brands,
        setBrandsSearchQuery
    );

    return (
        <StyledTable
            bordered
            className={Styles.importTable}
            columns={columns}
            dataSource={props.invalidProductsExcel}
            loading={props.loading}
            locale={{
                emptyText: props.intl.formatMessage({ id: 'no_data' })
            }}
            pagination={false}
            rowClassName={record => record.alreadyExists && Styles.duplicateRow}
            rowKey={(record, index) => `${index}-${record.code}-${record.productName}`}
            scroll={{ x: 1600 }}
            size='small'
        />
    );
};

const mapStateToProps = state => ({
    brands: selectBrandsByQuery(state),
    priceGroups: selectPriceGroups(state),
    storeGroups: selectStoreGroups(state),
    loading: selectStoreProductsExcelLoading(state)
});

const mapDispatchToProps = {
    setBrandsSearchQuery,
    fetchStoreGroups,
    fetchPriceGroups
};

export const ProductsExcelTable = injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ProductsExcelTableComponent)
);
