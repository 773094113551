import { Button, Radio, Select } from 'antd';
import classNames from 'classnames/bind';
import { DateRangePicker } from 'components';
import { FunelContainer, OrdersFilterContainer } from 'containers';
import { fetchUniversalFiltersForm } from 'core/forms/universalFiltersForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import {
    fetchOrders,
    setOrdersDaterangeFilter,
    setOrdersStatusFilter,
    setUniversalFilter
} from 'core/orders/duck';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { Layout, OrdersContainer } from 'tireFitting';
import { AddOrderModal } from 'tireFitting/modals';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;

const cx = classNames.bind(Styles);

const filterSelectOptions = [
    {
        message: 'all',
        value: 'not_complete,required,call,reserve,approve,progress,success'
    },
    {
        message: 'records',
        value: 'approve'
    },
    {
        message: 'not_complete',
        value: 'not_complete,required,call,reserve'
    },
    {
        message: 'repairs',
        value: 'progress'
    },
    {
        message: 'done',
        value: 'success'
    },
    {
        message: 'required',
        value: 'required'
    },
    {
        message: 'reserve',
        value: 'reserve'
    },
    {
        message: 'call',
        value: 'call'
    },
    {
        message: 'cancels',
        value: 'cancel'
    }
];

const mapState = state => ({
    ordersDaterangeFilter: state.orders.filter.daterange,
    filter: state.orders.filter,
    universalStats: state.orders.universalStats,
    universalFilter: state.orders.universalFilter,
    collapsed: state.ui.collapsed,
    user: state.auth,
    daterange: state.orders.daterange,
    isMobile: state.ui.views.isMobile
});

const mapDispatch = {
    fetchOrders,
    setOrdersStatusFilter,
    setOrdersDaterangeFilter,
    setModal,
    fetchUniversalFiltersForm,
    setUniversalFilter
};

@withRouter
@connect(mapState, mapDispatch)
export default class OrdersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addOrderModalVisible: false
        };
    }

    _getPageTitle() {
        const status = this.props.match.params.ordersStatuses;
        switch (status) {
            case 'appointments':
                return <FormattedMessage id='appointments' />;
            case 'approve':
                return <FormattedMessage id='records' />;
            case 'progress':
                return <FormattedMessage id='repairs' />;
            case 'success':
                return <FormattedMessage id='done' />;
            case 'reviews':
                return <FormattedMessage id='reviews' />;
            case 'invitations':
                return <FormattedMessage id='invitations' />;
            case 'cancel':
                return <FormattedMessage id='cancels' />;

            default:
                return <FormattedMessage id='orders-page.title' />;
        }
    }

    _setFilterStatus = status => {
        this.props.setOrdersStatusFilter(status);
        this.props.fetchOrders();
    };

    _setOrdersDaterange = daterange => {
        const { setOrdersDaterangeFilter, fetchOrders } = this.props;

        setOrdersDaterangeFilter({
            daterange,
            startDate: dayjs(daterange[0]).format('YYYY-MM-DD'),
            endDate: dayjs(daterange[1]).format('YYYY-MM-DD')
        });
        fetchOrders();
    };
    // eslint-disable-next-line
    render() {
        const { collapsed, isMobile, user, filter, setModal } = this.props;
        const { addOrderModalVisible } = this.state;

        const headerControls = this._renderHeaderContorls();

        const status = this.props.match.params.ordersStatuses;

        const filterStatus = filter.status;

        const funelSectionStyles = cx({
            funelWithFilters: true,
            funelWithFiltersCollapsed: collapsed,
            funelWithFiltersShadow: ['success', 'cancel'].indexOf(status) < 0,
            funelMobile: isMobile
        });

        return (
            <Layout
                controls={
                    <div className={Styles.controls}>
                        {!isMobile &&
                            /* ([ 'success', 'cancel', 'reviews' ].indexOf(status) <
                                0 && */
                            headerControls}
                        <div className={Styles.buttonGroup}>
                            {(status === 'cancel' || status === 'success') && !isMobile && (
                                <Button
                                    disabled={
                                        isForbidden(user, permissions.CREATE_ORDER) ||
                                        isForbidden(user, permissions.CREATE_INVITE_ORDER)
                                    }
                                    onClick={() => this.props.setModal(MODALS.INVITE)}
                                    type='primary'
                                >
                                    <FormattedMessage id='orders-page.invite_to_service' />
                                </Button>
                            )}
                            {isMobile ? (
                                <Button
                                    disabled={isForbidden(user, permissions.CREATE_ORDER)}
                                    onClick={() => {
                                        this.setState({
                                            addOrderModalVisible: true
                                        });
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='orders-page.add_appointment' />
                                </Button>
                            ) : (
                                <Link
                                    to={{
                                        pathname: book.addOrder,
                                        state: {
                                            beginDatetime: dayjs()
                                                .add(30 - (dayjs().minute() % 30), 'minutes')
                                                .format('YYYY-MM-DD HH:00')
                                        }
                                    }}
                                >
                                    <Button
                                        disabled={isForbidden(user, permissions.CREATE_ORDER)}
                                        type='primary'
                                    >
                                        <FormattedMessage id='orders-page.add_appointment' />
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                }
                description={<FormattedMessage id='orders-page.description' />}
                title={this._getPageTitle()}
            >
                {!isMobile ? (
                    <React.Fragment>
                        <section
                            className={
                                ['success', 'cancel'].indexOf(status) > -1
                                    ? `${Styles.ordersWrrapper} ${Styles.ordersWrrapperUF}`
                                    : Styles.ordersWrrapper
                            }
                        >
                            <OrdersContainer>
                                <div>
                                    <FunelContainer />
                                    <OrdersFilterContainer status={status} />
                                    {/* <UniversalFilters
                                        areFiltersDisabled={ isForbidden(
                                            this.props.user,
                                            permissions.SHOW_FILTERS,
                                        ) }
                                        stats={ this.props.universalStats }
                                        universalFilter={ this.props.universalFilter }
                                        setUniversalFilter={ this.props.setUniversalFilter }
                                    /> */}
                                </div>
                            </OrdersContainer>
                        </section>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <section className={Styles.ordersWrapperMobile}>
                            <OrdersContainer isMobile={isMobile}>
                                <Select
                                    onChange={value => {
                                        this._setFilterStatus(value);
                                    }}
                                    style={{ marginBottom: 8 }}
                                    value={filterStatus}
                                >
                                    {filterSelectOptions.map(({ value, message }) => (
                                        <Option key={message} value={value}>
                                            <FormattedMessage id={message} />
                                        </Option>
                                    ))}
                                </Select>
                                <OrdersFilterContainer onlySearch status={status} />
                            </OrdersContainer>
                        </section>
                        <AddOrderModal
                            hideModal={() => {
                                this.setState({
                                    addOrderModalVisible: false
                                });
                            }}
                            visible={addOrderModalVisible}
                        />
                    </React.Fragment>
                )}
            </Layout>
        );
    }

    _renderHeaderContorls = () => {
        // const {
        //     filter: { daterange },
        // } = this.props;

        return (
            <DateRangePicker
                dateRange={this.props.daterange}
                minimize
                onDateChange={this._setOrdersDaterange}
            />
        );
    };
}
