import { Form } from '@ant-design/compatible';
import { Col, Row } from 'antd';
import { DecoratedInput } from 'forms/DecoratedFields';
import { setBrandCrossFields } from 'modals/BrandsCrossModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const FItem = Form.Item;

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setBrandCrossFields
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@Form.create({ name: 'brands_cross_add_form' })
export default class AddFormBrandCross extends React.Component {
    setField = (key, value) => {
        this.props.setBrandCrossFields({ [key]: value });
    };

    render() {
        const {
            form,
            intl: { formatMessage },
            user
        } = this.props;

        const { getFieldDecorator, resetFields, isFieldTouched } = form;

        return (
            <Form>
                <Row className={Styles.row}>
                    <Col span={6}>
                        <div className={Styles.colText}>
                            <FormattedMessage id='brand_cross_name' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <DecoratedInput
                            field='businessSupplierBrandName'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            onChange={e => {
                                this.setField('businessSupplierBrandName', e.target.value);
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        );
    }
}
