import { Input, Radio } from 'antd';
import { Layout, Spinner } from 'commons';
import { MyTasksContainer } from 'containers';
import {
    changeModalStatus,
    resetOrderTasksForm,
    saveOrderTask
} from 'core/forms/orderTaskForm/duck';
import { resetModal, setModal } from 'core/modals/duck';
import {
    fetchMyTasks,
    resetData,
    setMyTasksDaterangeFilter,
    setMyTasksSearchFilter,
    setMyTasksSortFieldFilter,
    setMyTasksSortOrderFilter,
    setMyTasksStatusFilter
} from 'core/myTasks/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { OrderTaskModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDaterange } from 'utils';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Search } = Input;

const compareOrderTasks = (initialOrderTask, orderTask) => {
    if (!initialOrderTask) {
        return true;
    }

    const initialOrderTaskEntity = {
        responsibleId: initialOrderTask.responsibleId,
        priority: initialOrderTask.priority,
        status: initialOrderTask.status,
        comment: initialOrderTask.comment,
        time: initialOrderTask.deadlineDate
            ? dayjs(initialOrderTask.deadlineDate).format('HH:mm')
            : void 0,
        date: initialOrderTask.deadlineDate
            ? dayjs(initialOrderTask.deadlineDate).format('YYYY-MM-DD')
            : void 0,
        stationNum: initialOrderTask.stationNum
    };

    const orderTaskEntity = {
        responsibleId: orderTask.responsible,
        priority: orderTask.priority,
        status: orderTask.status,
        comment: orderTask.comment,
        time: orderTask.deadlineTime ? dayjs(orderTask.deadlineTime).format('HH:mm') : void 0,
        date: orderTask.deadlineDate ? dayjs(orderTask.deadlineDate).format('YYYY-MM-DD') : void 0,
        stationNum: orderTask.stationName
    };

    return !_.isEqual(
        _.omitBy(orderTaskEntity, _.isNil),
        _.omitBy(initialOrderTaskEntity, _.isNil)
    );
};

const mapStateToProps = state => ({
    myTasks: state.myTasks.myTasks,
    page: state.myTasks.page,
    activeOrder: state.myTasks.activeOrder,
    activeVehicle: state.myTasks.vehicle,
    filter: state.myTasks.filters,
    modal: state.modals.modal,
    initialOrderTask: state.forms.orderTaskForm.initialOrderTask,
    orderTaskId: state.forms.orderTaskForm.taskId,
    spinner: state.ui.myTasksFetching,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setModal,
    resetModal,
    resetOrderTasksForm,
    saveOrderTask,
    changeModalStatus,
    fetchMyTasks,
    resetData,
    setMyTasksDaterangeFilter,
    setMyTasksSearchFilter,
    setMyTasksStatusFilter,
    setMyTasksSortFieldFilter,
    setMyTasksSortOrderFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class MyTasksPage extends Component {
    constructor(props) {
        super(props);

        this.handleOrdersSearch = _.debounce(value => {
            const { setMyTasksSearchFilter, fetchMyTasks, filter } = this.props;
            setMyTasksSearchFilter(value);
            fetchMyTasks(filter);
        }, 1000);

        this.state = {
            button: 'all'
        };
    }

    componentDidMount() {
        const { filter, fetchMyTasks } = this.props;
        fetchMyTasks(filter, true);
    }

    _selectStatus(ev) {
        const { setMyTasksStatusFilter, fetchMyTasks, filter } = this.props;

        setMyTasksStatusFilter(ev.target.value);
        fetchMyTasks(filter);
    }

    _saveOrderTaskFormRef = formRef => {
        this.orderTaskFormRef = formRef;
    };

    _saveOrderTask = () => {
        const { orderTaskId, initialOrderTask } = this.props;
        const { form } = this.orderTaskFormRef.props;
        const myTasks = 'mytasks';
        form.validateFields((err, values) => {
            if (!err) {
                if (compareOrderTasks(initialOrderTask, values)) {
                    this.props.saveOrderTask(values, this.props.activeOrder, orderTaskId, myTasks);
                    this.props.resetModal();
                    this.props.resetOrderTasksForm();
                } else {
                    this.props.resetModal();
                    this.props.resetOrderTasksForm();
                }
            }
        });
    };

    _handleRadioDaterange = event => {
        const { fetchMyTasks, filter, setMyTasksDaterangeFilter } = this.props;
        const daterange = event.target.value;
        this.setState({ button: daterange });
        if (daterange === 'all') {
            setMyTasksDaterangeFilter({});
        } else if (daterange !== 'all') {
            const daterangeFilter = getDaterange(daterange);
            setMyTasksDaterangeFilter({ ...daterangeFilter });
        }
        fetchMyTasks(filter);
    };

    _renderHeaderContorls = () => {
        const { button } = this.state;

        return (
            <RadioGroup
                className={Styles.filters}
                // defaultValue={ ordersDaterangeFilter }
                defaultValue={button}
                onChange={this._handleRadioDaterange}
            >
                <RadioButton value='all'>
                    <FormattedMessage id='orders-page.all' />
                </RadioButton>
                <RadioButton value='today'>
                    <FormattedMessage id='orders-page.today' />
                </RadioButton>
                <RadioButton value='tomorrow'>
                    <FormattedMessage id='orders-page.tomorrow' />
                </RadioButton>
                <RadioButton value='nextWeek'>
                    <FormattedMessage id='orders-page.week' />
                </RadioButton>
                <RadioButton value='nextMonth'>
                    <FormattedMessage id='orders-page.month' />
                </RadioButton>
            </RadioGroup>
        );
    };

    /* eslint-disable complexity */
    render() {
        const headerControls = this._renderHeaderContorls();
        const {
            myTasks,
            resetModal,
            modal,
            activeOrder,
            activeVehicle,
            orderTaskId,
            progressStatusOptions,
            priorityOptions,
            spinner,
            filter,
            isMobile,
            intl,
            setMyTasksSortOrderFilter,
            setMyTasksSortFieldFilter,
            fetchMyTasks,
            initialOrderTask
        } = this.props;

        return spinner ? (
            <Spinner spin={spinner} />
        ) : (
            <Layout
                controls={<div className={Styles.controls}>{!isMobile && headerControls}</div>}
                description={<FormattedMessage id='order-task.description' />}
                paper
                title={<FormattedMessage id='navigation.mytasks' />}
            >
                <div className={Styles.filter}>
                    <Search
                        className={Styles.search}
                        defaultValue={filter.query}
                        onChange={({ target: { value } }) => this.handleOrdersSearch(value)}
                        placeholder={intl.formatMessage({
                            id: 'orders-filter.search_placeholder'
                        })}
                    />
                    <RadioGroup
                        className={Styles.buttonFilterGroup}
                        defaultValue={filter.status}
                        onChange={ev => this._selectStatus(ev)}
                    >
                        <RadioButton value='all'>
                            <FormattedMessage id='all' />
                        </RadioButton>
                        <RadioButton value='active'>
                            <FormattedMessage id='active' />
                        </RadioButton>
                    </RadioGroup>
                </div>
                <div className={Styles.myTasksWrapper}>
                    <MyTasksContainer
                        fetchMyTasks={fetchMyTasks}
                        filter={filter}
                        myTasks={myTasks}
                        page={filter.page}
                        setMyTasksSortFieldFilter={setMyTasksSortFieldFilter}
                        setMyTasksSortOrderFilter={setMyTasksSortOrderFilter}
                    />
                </div>
                <OrderTaskModal
                    activeVehicle={activeVehicle}
                    initialOrderTask={initialOrderTask}
                    managers={(myTasks && myTasks.managers) || []}
                    num={activeOrder}
                    orderId={activeOrder}
                    orderTaskId={orderTaskId}
                    orderTasks={(myTasks && myTasks.orderTasks) || []}
                    priorityOptions={priorityOptions}
                    progressStatusOptions={progressStatusOptions}
                    resetModal={resetModal}
                    resetOrderTasksForm={this.props.resetOrderTasksForm}
                    saveNewOrderTask={this._saveOrderTask}
                    stations={(myTasks && myTasks.stations) || []}
                    visible={modal}
                    wrappedComponentRef={this._saveOrderTaskFormRef}
                />
            </Layout>
        );
    }
}

export default MyTasksPage;
