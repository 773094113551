import { Button, Card, Form, message } from 'antd';
import { Result } from 'components';
import { DecoratedInput } from 'forms/DecoratedFields';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book.js';
import { fetchAPI } from 'utils';
import Styles from '../LoginForm/loginForm.m.css';

@withRouter
@injectIntl
export class NewPassword extends Component {
    state = {
        confirmDirty: false,
        passwordChanged: false,
        handleError: false
    };

    componentDidMount() {
        this._checkRecoverySession();
    }

    _checkRecoverySession = async () => {
        const passwordResetId = this.props.location.search.split('=').pop();

        const checkExpiration = () => {
            const hide = message.loading('Session validation...', 0);
            setTimeout(hide, 2500);
        };

        const response = await fetchAPI(
            'GET',
            `password/reset/verify?passwordResetId=${passwordResetId}`,
            null,
            { handleErrorInternally: true }
        );

        if (response.status === 'valid') checkExpiration();
        if (response.status !== 'valid') this.setState({ handleError: true });
    };

    _handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    _compareToFirstPassword = (rule, value, callback) => {
        /* eslint-disable */
        const form = this.props.form;
        if (value && value !== form.getFieldValue("password")) {
            callback(
                this.props.intl.formatMessage({
                    id: "login_form.password_confirmation"
                })
            );
        } else {
            callback();
        }
        /* eslint-enable */
    };

    _validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    _submit = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(async err => {
            if (!err) {
                this._checkRecoverySession();

                const passwordResetId = this.props.location.search.split('=').pop();

                const response = await fetchAPI(
                    'POST',
                    '/password/reset',
                    null,
                    {
                        passwordResetId,
                        password: this.props.form.getFieldValue('password')
                    },
                    { rawResponse: true, handleErrorInternally: true }
                );

                if (response.status === 200) {
                    this.setState({ passwordChanged: true });
                }
                if (response.status !== 200) {
                    this.setState({ handleError: true });
                }
            }
        });
    };

    _backToLogin = () => this.setState({ handleError: false, passwordChanged: false });

    render() {
        const { formatMessage } = this.props.intl;
        const { passwordChanged, handleError } = this.state;

        return (
            <Form className={Styles.loginForm} onSubmit={this._submit}>
                {!(passwordChanged || handleError) ? (
                    <React.Fragment>
                        <DecoratedInput
                            field='password'
                            formItem
                            label={<FormattedMessage id='login_form.password' />}
                            placeholder={formatMessage({
                                id: 'login_form.enter_password'
                            })}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'login_form.password_is_required_min'
                                    }),
                                    min: 6
                                },
                                {
                                    validator: this._validateToNextPassword
                                }
                            ]}
                            type='password'
                        />
                        <DecoratedInput
                            field='confirm'
                            formItem
                            label={<FormattedMessage id='login_form.confirm_password' />}
                            onBlur={this._handleConfirmBlur}
                            placeholder={formatMessage({
                                id: 'login_form.enter_password'
                            })}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'login_form.password_is_required_min'
                                    }),
                                    min: 6
                                },
                                {
                                    validator: this._compareToFirstPassword
                                }
                            ]}
                            type='password'
                        />
                        <Button htmlType='submit' type='primary'>
                            <FormattedMessage id='submit' />
                        </Button>
                    </React.Fragment>
                ) : null}
                {handleError && (
                    <Card bordered={false}>
                        <Result
                            actions={
                                <Link to={book.login}>
                                    <Button onClick={this._backToLogin}>
                                        <FormattedMessage id='login_form.back_to_login' />
                                    </Button>
                                </Link>
                            }
                            description={<FormattedMessage id='login_form.password_expired' />}
                            style={{ marginTop: 48, marginBottom: 16 }}
                            // extra={ extra }
                            title={<FormattedMessage id='login_form.error' />}
                            type='error'
                        />
                    </Card>
                )}
                {passwordChanged && (
                    <Card bordered={false}>
                        <Result
                            actions={
                                <Link to={book.login}>
                                    <Button onClick={this._backToLogin}>
                                        <FormattedMessage id='login_form.back_to_login' />
                                    </Button>
                                </Link>
                            }
                            description={<FormattedMessage id='login_form.password_changed' />}
                            style={{ marginTop: 48, marginBottom: 16 }}
                            title={<FormattedMessage id='login_form.congratulations' />}
                            type='success'
                        />
                    </Card>
                )}
            </Form>
        );
    }
}

export const NewPasswordForm = NewPassword;
