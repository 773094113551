import { Form } from '@ant-design/compatible';
import { Button } from 'antd';
import { onChangeClientRequisiteForm } from 'core/forms/editClientRequisiteForm/duck';
import { DecoratedCheckbox, DecoratedInput } from 'forms/DecoratedFields';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';
import Styles from './styles.m.css';

@injectIntl
@withReduxForm2({
    name: 'clientRequisiteForm',
    actions: {
        change: onChangeClientRequisiteForm
    }
})
export class RequisiteForm extends Component {
    render() {
        const { requisite, updateRequisite } = this.props;
        const { getFieldDecorator, validateFields } = this.props.form;

        return (
            <Form className={Styles.requsitesForm}>
                <DecoratedInput
                    colon={false}
                    field='name'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={requisite.name}
                    label={<FormattedMessage id='client_requisites_container.name' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedInput
                    colon={false}
                    field='address'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={requisite.address}
                    label={<FormattedMessage id='client_requisites_container.address' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedInput
                    colon={false}
                    field='bank'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={requisite.bank}
                    label={<FormattedMessage id='client_requisites_container.bank' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedInput
                    colon={false}
                    field='ifi'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={requisite.ifi}
                    label={<FormattedMessage id='client_requisites_container.ifi' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedInput
                    colon={false}
                    field='ca'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={requisite.ca}
                    label={<FormattedMessage id='client_requisites_container.ca' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedInput
                    colon={false}
                    field='itn'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    hasFeedback
                    initialValue={requisite.itn}
                    label={<FormattedMessage id='client_requisites_container.itn' />}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedCheckbox
                    colon={false}
                    field='enabled'
                    formItem
                    formItemLayout={{
                        labelCol: { span: 14 },
                        wrapperCol: { span: 6 }
                    }}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={!!requisite.enabled}
                    label={<FormattedMessage id='client_requisites_container.enabled' />}
                />

                <Button
                    onClick={() =>
                        validateFields(
                            (err, values) => !err && updateRequisite(requisite.id, values)
                        )
                    }
                    style={{ width: '100%' }}
                    type='primary'
                >
                    <FormattedMessage id='save' />
                </Button>
            </Form>
        );
    }
}
