import { Table } from 'antd';
import {
    fetchCashOrders,
    selectCashOrders,
    selectCashOrdersFilters,
    selectCashOrdersStats,
    selectFetchingCashOrders,
    setCashOrdersFilters
} from 'pages/SupplierPage/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    cashOrders: selectCashOrders(state),
    cashOrdersStats: selectCashOrdersStats(state),
    cashOrdersFilters: selectCashOrdersFilters(state),
    fetchingCashOrders: selectFetchingCashOrders(state)
});

const mapDispatchToProps = {
    fetchCashOrders,
    setCashOrdersFilters
};

/**
 * This table is created to show cash orders that are not related with any store docs,
 * but still have connection to a supplier
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CashOrdersTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            cashOrders,
            fetchingCashOrders,

            cashOrdersStats,
            cashOrdersFilters,
            setCashOrdersFilters
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(cashOrdersStats.totalRowsCount / 25) * 25,
            current: cashOrdersFilters.page,
            onChange: page => {
                setCashOrdersFilters({ page });
            }
        };

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig()}
                    dataSource={cashOrders}
                    loading={fetchingCashOrders}
                    pagination={pagination}
                    rowClassName={record => {
                        return record.type == 'INCOME'
                            ? Styles.incomeCashOrder
                            : Styles.expenseCashOrder;
                    }}
                    rowKey={() => v4()}
                />
            </div>
        );
    }
}
