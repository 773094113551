import React from 'react';

const PaddedWrapper = ({ children, wrapperProps }) => {
    return (
        <div {...wrapperProps} style={{ padding: 8, boxShadow: '0px 0px 4px 1px rgba(0,0,0,.1)', borderRadius: 6 }}>
            {children}
        </div>
    );
};

export default PaddedWrapper;
