exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__sider---2u6i9.ant-layout-sider{overflow:auto;z-index:110;left:0;height:100%;box-shadow:2px 0 6px rgba(0,21,41,.35)}.styles-m__siderMobile---1ENgm.ant-layout-sider{height:100%}.styles-m__sider---2u6i9::-webkit-scrollbar{width:5px}.styles-m__sider---2u6i9::-webkit-scrollbar-thumb{background-color:var(--primary)}.styles-m__logo---1mu8a{color:#fff;padding:22px;width:256px;position:fixed;z-index:1000;background-color:var(--layout)}.styles-m__logoCollapsed---1FnxB{text-align:center;width:80px}.styles-m__navMenu---i_Tnp{margin-top:60px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0CACE,aAAc,CACd,WAAY,CACZ,MAAO,CACP,WAAY,CACZ,sCACF,CAEA,gDAGE,WACF,CAEA,4CACE,SACF,CAEA,kDACE,+BACF,CAEA,wBACE,UAAY,CACZ,YAAa,CACb,WAAY,CACZ,cAAe,CACf,YAAa,CACb,8BACF,CAEA,iCACE,iBAAkB,CAClB,UACF,CAEA,2BACE,eACF","file":"styles.m.css","sourcesContent":[".sider:global(.ant-layout-sider) {\n  overflow: auto;\n  z-index: 110;\n  left: 0;\n  height: 100%;\n  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);\n}\n\n.siderMobile:global(.ant-layout-sider) {\n  /* overflow: scroll;\n    -webkit-overflow-scrolling: touch; */\n  height: 100%;\n}\n\n.sider::-webkit-scrollbar {\n  width: 5px;\n}\n\n.sider::-webkit-scrollbar-thumb {\n  background-color: var(--primary);\n}\n\n.logo {\n  color: white;\n  padding: 22px;\n  width: 256px;\n  position: fixed;\n  z-index: 1000;\n  background-color: var(--layout);\n}\n\n.logoCollapsed {\n  text-align: center;\n  width: 80px;\n}\n\n.navMenu {\n  margin-top: 60px; /* header height - 4px (nav menu item margin)*/\n}\n"]}]);

// Exports
exports.locals = {
	"sider": "styles-m__sider---2u6i9",
	"siderMobile": "styles-m__siderMobile---1ENgm",
	"logo": "styles-m__logo---1mu8a",
	"logoCollapsed": "styles-m__logoCollapsed---1FnxB",
	"navMenu": "styles-m__navMenu---i_Tnp"
};