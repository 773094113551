import { setDocumentTitle, setPrint } from 'core/ui/duck';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import Styles from './styles.m.css';

const PrintableHOC = ({ print, documentTitle, setPrint }) => {
    const contentToPrint = useRef(null);
    const documentTitleRef = useRef(null);
    const footerData = useMemo(() => get(print, 'footerData', {}), [print]);
    const tempTitle = document.title;
    const [isFirstPrint, setIsFirstPrint] = useState(true);

    const handlePrint = useReactToPrint({
        content: () => contentToPrint.current,
        // documentTitle:
        //     get(documentTitleRef, 'current.innerHTML') ||
        //     (footerData.num ? `${footerData.num}/${footerData.printNumber}` : void 0),
        onBeforeGetContent() {},
        onBeforePrint() {
            console.log('documentTitleRef.current.innerHTML', documentTitleRef.current.innerHTML);
            document.title =
                get(documentTitleRef, 'current.innerHTML') ||
                (footerData.num ? `${footerData.num}/${footerData.printNumber}` : void 0);
            // Ensure the ref is pointing to a valid DOM node
            setInnerHTML(contentToPrint.current, get(print, 'data', print));
        },
        onAfterPrint() {
            setInnerHTML(contentToPrint.current, '');
            setPrint(undefined);
            if (!isFirstPrint) document.title = tempTitle;
        }
    });

    const setInnerHTML = useCallback((elm, html) => {
        elm.innerHTML = html;

        Array.from(elm.querySelectorAll('script')).forEach(oldScriptEl => {
            const newScriptEl = document.createElement('script');

            Array.from(oldScriptEl.attributes).forEach(attr => {
                newScriptEl.setAttribute(attr.name, attr.value);
            });

            const scriptText = document.createTextNode(oldScriptEl.innerHTML);
            newScriptEl.appendChild(scriptText);

            oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
        });

        if (html) handlePrint();
    }, []);

    useEffect(() => {
        if (print) {
            // handlePrint();
            setInnerHTML(contentToPrint.current, get(print, 'data', print));
        }

        // setTimeout(() => setPrint(undefined), 60000);
    }, [handlePrint, print]);

    return (
        <React.Fragment>
            <div ref={contentToPrint} className={Styles.printable} id='printHOC'>
                {/* <div dangerouslySetInnerHTML={{ __html: get(print, 'data', print) }} className={Styles.content} /> */}
            </div>
            <div ref={documentTitleRef} style={{ display: 'none' }}>
                {documentTitle}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    print: state.ui.print,
    documentTitle: state.ui.documentTitle
});

const mapDispatchToProps = {
    setPrint,
    setDocumentTitle
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintableHOC);
