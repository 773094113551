exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__mainContainer---3JwBA{width:100%}.styles-m__row---28u1b{margin-top:1em}.styles-m__col---2nyQ9{padding:0 .2em}.styles-m__counterpartyTables---1RmXk{border:.1em solid rgba(0,0,0,.2);border-radius:.2em}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,iCACI,UACJ,CAEA,uBACI,cACJ,CAEA,uBACI,cACJ,CAEA,sCACI,gCAAsC,CACtC,kBACJ","file":"styles.m.css","sourcesContent":[".mainContainer {\n    width: 100%;\n}\n\n.row {\n    margin-top: 1em;\n}\n\n.col {\n    padding: 0 0.2em 0 0.2em;\n}\n\n.counterpartyTables {\n    border: 0.1em solid rgba(0, 0, 0, 0.2);\n    border-radius: 0.2em;\n}"]}]);

// Exports
exports.locals = {
	"mainContainer": "styles-m__mainContainer---3JwBA",
	"row": "styles-m__row---28u1b",
	"col": "styles-m__col---2nyQ9",
	"counterpartyTables": "styles-m__counterpartyTables---1RmXk"
};