import { Checkbox, DatePicker, Skeleton } from 'antd';
import { BrandSelect, WarehouseSelect } from 'components';
import {
    selectStoreBalanceFilters,
    selectStoreBalanceTotal,
    setStoreBalanceFilters
} from 'core/storage/storeBalance';
import dayjs from 'dayjs';
import { StoreProductsSelect } from 'forms/_formkit';
import _ from 'lodash';
import { StockProductsModal } from 'modals';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { numeralFormatter } from 'utils';

const mapStateToProps = state => ({
    collapsed: state.ui.collapsed,
    total: selectStoreBalanceTotal(state),
    filters: selectStoreBalanceFilters(state),
    user: state.auth
});

export const StorageBalanceTotals = connect(mapStateToProps, {
    setStoreBalanceFilters
})(props => {
    // const total = _.get(props, 'balance.total[0]');
    const { filters, total, collapsed } = props;
    const onPickDate = date => date && props.setStoreBalanceFilters({ date });

    const SkeletonLoader = <Skeleton active paragraph={{ rows: 1, width: 100 }} title={false} />;

    const renderTotalData = (label, data) => (
        <div>
            <FormattedMessage id={`storage.${label}`} />
            :&nbsp;<Highlighted>{numeralFormatter(data)}</Highlighted>
        </div>
    );

    return (
        <BalanceTotal collapsed={collapsed}>
            <DataRow>
                <DataWrapper>
                    {!_.isEmpty(total)
                        ? renderTotalData('in_stock', Number(total.remaining).toFixed(1))
                        : SkeletonLoader}
                </DataWrapper>
                <DataWrapper>
                    {!_.isEmpty(total)
                        ? renderTotalData('reserve', Number(total.reserved).toFixed(1))
                        : SkeletonLoader}
                </DataWrapper>
                <DataWrapper>
                    {!_.isEmpty(total)
                        ? renderTotalData(
                              'available',
                              Math.abs(total.remaining - total.reserved).toFixed(1)
                          )
                        : SkeletonLoader}
                </DataWrapper>
                <DataWrapper>
                    {!_.isEmpty(total)
                        ? renderTotalData('ordered', Math.abs(total.countInStoreOrders).toFixed(1))
                        : SkeletonLoader}
                </DataWrapper>
                <DataWrapper
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                    }}
                >
                    <div
                        style={{
                            fontSize: 12
                        }}
                    >
                        <FormattedMessage id='in_stock' />
                        <Checkbox
                            defaultChecked
                            onChange={event => {
                                props.setStoreBalanceFilters({
                                    inStock: event.target.checked,
                                    page: 1
                                });
                            }}
                            style={{ marginLeft: 5 }}
                        />
                    </div>
                    {!_.isEmpty(total)
                        ? renderTotalData('sum', Math.round(total.sum * 10) / 10)
                        : SkeletonLoader}
                </DataWrapper>
            </DataRow>
            <FiltersRow>
                <DatePicker
                    allowClear={false}
                    defaultValue={dayjs(filters.date)}
                    onChange={onPickDate}
                />
                <WarehouseSelect
                    onChange={warehouseId => props.setStoreBalanceFilters({ warehouseId })}
                    style={{ margin: '0 0 0 8px' }}
                />
                <BrandSelect
                    onSelect={brandId => props.setStoreBalanceFilters({ brandId })}
                    style={{ margin: '0 0 0 8px' }}
                />
                <FilterSpace>
                    <StoreProductsSelect
                        filters={props.filters}
                        setFilters={props.setStoreBalanceFilters}
                    />
                </FilterSpace>
                <div style={{ margin: '0 0 0 8px' }}>
                    <StockProductsModal
                        selectProduct={({ productId }) => {
                            props.setStoreBalanceFilters({
                                productId,
                                page: 1
                            });
                        }}
                        user={props.user}
                    />
                </div>
            </FiltersRow>
        </BalanceTotal>
    );
});

const BalanceTotal = styled.div`
    display: flex;
    flex-direction: column;
    overflow: initial;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    padding: 14px 0px;
    z-index: 210;
`;

const FiltersRow = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DataRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 14px;
    padding: 14px 0;

    border-bottom: 1px solid;
    border-top: 1px solid;
`;

const DataWrapper = styled.div`
    display: flex;
    font-size: 20px;
`;

const Highlighted = styled.span`
    color: var(--secondary);
    font-weight: 700;
    font-size: 20px;
`;

const FilterSpace = styled.div`
    margin-left: 8px;
`;
