import { FilterFilled } from '@ant-design/icons';
import { Button, Popconfirm, Select, Table } from 'antd';
import PaddedWrapper from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/PaddedWrapper';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { fetchAPI, numeralFormatter2, orderTypes } from 'utils';
import DateFilterPopup from '../../DateFilterPopup';
import Styles from './styles.m.css';

// query: Joi.object({
//     successStartDate: Joi.date().format('YYYY-MM-DD').optional(),
//     successEndDate: Joi.date().format('YYYY-MM-DD').optional(),
//     creationStartDate: Joi.date().format('YYYY-MM-DD').optional(),
//     creationEndDate: Joi.date().format('YYYY-MM-DD').optional(),
//     recordStartDate: Joi.date().format('YYYY-MM-DD').optional(),
//     recordEndDate: Joi.date().format('YYYY-MM-DD').optional(),
//     deliveryStartDate: Joi.date().format('YYYY-MM-DD').optional(),
//     deliveryEndDate: Joi.date().format('YYYY-MM-DD').optional(),
//     stationIds: Joi.array().items(Joi.number().integer().optional()).optional().allow(null),
//     page: Joi.number().integer().optional().default(1),
//     pageSize: Joi.number().integer().optional().default(25),
//     filters: Joi.object({
//       clientId: Joi.number().integer().optional().allow(null),
//       orderType: Joi.string().optional(),
//       managerId: Joi.number().integer().optional().allow(null),
//       employeeId: Joi.number().integer().optional().allow(null),
//       appurtenanciesResponsibleId: Joi.number().integer().optional().allow(null),
//       stationNum: Joi.number().integer().optional().allow(null),
//       vehicleId: Joi.number().integer().optional().allow(null),
//       businessRequisiteId: Joi.number().integer().optional().allow(null)
//     }).default({}).optional().allow(null)

const AnalyticsTab = ({ intl: { formatMessage }, filters }) => {
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({ pageSize: 10, page: 1 });
    const [queryFilters, setQueryFilters] = useState({ filters: {} });
    const [total, setTotal] = useState(0);

    const fetchAnalytics = useCallback(async () => {
        const response = await fetchAPI(
            'GET',
            '/manager/report/analytics',
            // {
            //     ...settings,
            {
                ...pagination,
                ...queryFilters,
                operationCodeAnalytics: filters.operationCodeAnalytics,
                filters: { ...filters, ...queryFilters.filters, operationCodeAnalytics: undefined }
            },
            //     startDate: dayjs(settings.startDate).format('YYYY-MM-DD'),
            //     endDate: dayjs(settings.endDate).format('YYYY-MM-DD')
            // },
            null,
            {
                handleErrorInternally: true
            }
        );
        setTotal(response.count);
        setDataSource(response.orderAnalytics);
    }, [filters, pagination, queryFilters]);

    const queryFiltersChange = obj => {
        setQueryFilters(prev => ({ ...prev, ...obj }));
    };

    const queryNestedFiltersChange = obj => {
        setQueryFilters(prev => ({ ...prev, filters: { ...prev.filters, ...obj } }));
    };

    const debouncedNestedFiltersChange = debounce(queryNestedFiltersChange);

    const clearDateFilter = ([startDate, endDate]) => {
        queryFiltersChange({ [startDate]: undefined, [endDate]: undefined });
    };

    const analyticsColumns = useMemo(() => {
        return [
            {
                title: () => (
                    <div>
                        <p className={Styles.justifySpace}>
                            {/* <FormattedMessage id='order_shr' /> */}
                            {/* <Popconfirm icon={null} description={<PaddedWrapper>
                                <Input
                                    onChange={e => debouncedNestedFiltersChange({ clientPhone: e.target.value })}
                                    placeholder={formatMessage({ id: 'client_number' })}
                                    style={{ width: 200 }}
                                    value={queryFilters.filters.order} ? be doesn't have order filter field
                                />
                            </PaddedWrapper>}><Button
                                    icon={
                                        <FilterFilled
                                            style={{
                                                color: queryFilters.filters.order
                                                    ? 'var(--primary)'
                                                    : 'rgba(0, 0, 0, 0.29)'
                                            }}
                                        />
                                    }
                                    size='small'
                                    type='text'
                                />

                            </Popconfirm> */}
                        </p>
                        <p className={Styles.justifySpace}>
                            <Popconfirm
                                description={
                                    <PaddedWrapper>
                                        <Select
                                            allowClear
                                            onClear={() => debouncedNestedFiltersChange({ orderType: null })}
                                            onSelect={val => debouncedNestedFiltersChange({ orderType: val })}
                                            options={orderTypes.map(type => ({
                                                label: formatMessage({ id: `aggregate.${type}` }),
                                                value: type
                                            }))}
                                            placeholder={formatMessage({ id: 'repair_type' })}
                                            popupMatchSelectWidth={false}
                                            style={{ width: 200 }}
                                            value={queryFilters.filters.orderType}
                                        />
                                    </PaddedWrapper>
                                }
                                icon={null}
                                onCancel={() => debouncedNestedFiltersChange({ orderType: null })}
                            >
                                <Button
                                    icon={
                                        <FilterFilled
                                            style={{
                                                color: queryFilters.filters.orderType
                                                    ? 'var(--primary)'
                                                    : 'rgba(0, 0, 0, 0.29)'
                                            }}
                                        />
                                    }
                                    size='small'
                                    type='text'
                                />
                            </Popconfirm>
                            <FormattedMessage id='repair_type' />
                        </p>
                    </div>
                ),
                // filterDropdown: () => (
                //     <PaddedWrapper>
                //         <Select
                //             onSelect={val => queryFiltersChange({ orderType: val })}
                //             options={orderTypes.map(type => ({
                //                 label: formatMessage({ id: `aggregate.${type}` }),
                //                 value: type
                //             }))}
                //             placeholder={formatMessage({ id: 'repair_type' })}
                //             popupMatchSelectWidth={false}
                //             style={{ width: 200 }}
                //             value={queryFilters.orderType}
                //         />
                //     </PaddedWrapper>
                // ),
                render: ({ order, orderType, id }) => (
                    <div>
                        <p style={{ fontWeight: 700 }}>
                            <Link to={`${book.order}/${id}`}>{order}</Link>
                        </p>
                        <p>{formatMessage({ id: `aggregate.${orderType}` })}</p>
                    </div>
                )
            },
            {
                title: formatMessage({ id: 'date' }),
                children: [
                    {
                        title: () => (
                            <div>
                                <p className={Styles.justifySpace}>
                                    <DateFilterPopup
                                        fieldNames={['creationStartDate', 'creationEndDate']}
                                        onCancel={clearDateFilter}
                                        queryFilters={queryFilters}
                                        queryFiltersChange={queryFiltersChange}
                                    />
                                    <FormattedMessage id='of_creation' />
                                </p>
                                <p className={Styles.justifySpace}>
                                    <DateFilterPopup
                                        fieldNames={['recordStartDate', 'recordEndDate']}
                                        onCancel={clearDateFilter}
                                        queryFilters={queryFilters}
                                        queryFiltersChange={queryFiltersChange}
                                    />
                                    <FormattedMessage id='of_record' />
                                </p>
                            </div>
                        ),
                        render: ({ creationDate, recordDate }) => (
                            <div>
                                <p className={Styles.bold}>{creationDate}</p>
                                <p>{recordDate}</p>
                            </div>
                        )
                    },
                    {
                        title: () => (
                            <div>
                                <p className={Styles.justifySpace}>
                                    <DateFilterPopup
                                        fieldNames={['successStartDate', 'successEndDate']}
                                        onCancel={clearDateFilter}
                                        queryFilters={queryFilters}
                                        queryFiltersChange={queryFiltersChange}
                                    />
                                    <FormattedMessage id='of_success' />
                                </p>
                                <p className={Styles.justifySpace}>
                                    <DateFilterPopup
                                        fieldNames={['deliveryStartDate', 'deliveryEndDate']}
                                        onCancel={clearDateFilter}
                                        queryFilters={queryFilters}
                                        queryFiltersChange={queryFiltersChange}
                                    />
                                    <FormattedMessage id='of_delivery' />
                                </p>
                            </div>
                        ),
                        render: ({ successDate, deliveryDate }) => (
                            <div>
                                <p className={Styles.bold}>{successDate}</p>
                                <p>{deliveryDate}</p>
                            </div>
                        )
                    }
                ],
                render: ({ order }) => (
                    <div>
                        <p className={Styles.bold}>{order}</p>
                    </div>
                )
            },
            {
                title: `${formatMessage({ id: 'client' })} / ${formatMessage({ id: 'vehicle' })}`,
                // filterDropdown: () => (
                //     <PaddedWrapper>
                //         <Input
                //             onChange={e => debouncedFilterChange({ clientPhone: e.target.value })}
                //             placeholder={formatMessage({ id: 'client_number' })}
                //             style={{ width: 200 }}
                //             // value={queryFilters.orderType}
                //         />
                //     </PaddedWrapper>
                // ),
                render: ({ client, vehicle, vehicleData }) => (
                    <div>
                        <p className={Styles.bold}>{client}</p>
                        <p>{vehicle}</p>
                        <p>{vehicleData}</p>
                    </div>
                )
            },
            {
                title: () => (
                    <div>
                        <p>
                            <FormattedMessage id='responsible' />
                        </p>
                        <p>
                            <FormattedMessage id='mechanic' />
                        </p>
                        <p>
                            <FormattedMessage id='spare_part_specialist' />
                        </p>
                    </div>
                ),
                render: ({ managerName, mechanicName, sparePartName }) => (
                    <div>
                        <p className={Styles.bold}>{managerName}</p>
                        <p>{mechanicName}</p>
                        <p>{sparePartName}</p>
                    </div>
                )
            },
            {
                render: () => (
                    <div>
                        <p className={Styles.bold}>
                            <FormattedMessage id='sub_total' />
                        </p>
                        <p>
                            <FormattedMessage id='labors' />
                        </p>
                        <p>
                            <FormattedMessage id='subcontract' />
                        </p>
                        <p>
                            <FormattedMessage id='parts' />
                        </p>
                    </div>
                )
            },
            {
                title: <p>{formatMessage({ id: 'order_sum' })}</p>,
                render: ({ totalSum, servicesSum, servicesSubSum, appurtenanciesSum }) => (
                    <div>
                        <p className={`${Styles.bold} ${Styles.numberAlign}`}>{numeralFormatter2(totalSum)}</p>
                        <p className={Styles.numberAlign}>{numeralFormatter2(servicesSum)}</p>
                        <p className={Styles.numberAlign}>{numeralFormatter2(servicesSubSum)}</p>
                        <p className={Styles.numberAlign}>{numeralFormatter2(appurtenanciesSum)}</p>
                    </div>
                )
            },
            {
                title: formatMessage({ id: 'cost_value' }),
                render: ({
                    totalPurchaseSum,
                    servicesPurchaseSum,
                    servicesSubPurchaseSum,
                    appurtenanciesPurchaseSum
                }) => (
                    <div>
                        <p className={`${Styles.bold} ${Styles.numberAlign}`}>{numeralFormatter2(totalPurchaseSum)}</p>
                        <p className={Styles.numberAlign}>{numeralFormatter2(servicesPurchaseSum)}</p>
                        <p className={Styles.numberAlign}>{numeralFormatter2(servicesSubPurchaseSum)}</p>
                        <p className={Styles.numberAlign}>{numeralFormatter2(appurtenanciesPurchaseSum)}</p>
                    </div>
                )
            },
            {
                title: formatMessage({ id: 'margin' }),
                children: [
                    {
                        title: '₴',
                        render: ({ totalMargin, servicesMargin, servicesSubMargin, appurtenanciesMargin }) => (
                            <div>
                                <p className={`${Styles.bold} ${Styles.numberAlign}`}>
                                    {numeralFormatter2(totalMargin)}
                                </p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(servicesMargin)}</p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(servicesSubMargin)}</p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(appurtenanciesMargin)}</p>
                            </div>
                        )
                    },
                    {
                        title: '%',
                        render: ({
                            totalMarginPercent,
                            servicesMarginPercent,
                            servicesSubMarginPercent,
                            appurtenanciesMarginPercent
                        }) => (
                            <div>
                                <p className={`${Styles.bold} ${Styles.numberAlign}`}>{totalMarginPercent}</p>
                                <p className={Styles.numberAlign}>{servicesMarginPercent}</p>
                                <p className={Styles.numberAlign}>{servicesSubMarginPercent}</p>
                                <p className={Styles.numberAlign}>{appurtenanciesMarginPercent}</p>
                            </div>
                        )
                    }
                ]
            },
            {
                title: `${formatMessage({ id: 'markup' })} (%)`,
                render: ({
                    totalMarkupPercent,
                    servicesMarkupPercent,
                    servicesSubMarkupPercent,
                    appurtenanciesMarkupPercent
                }) => (
                    <div>
                        <p className={`${Styles.bold} ${Styles.numberAlign}`}>{totalMarkupPercent}</p>
                        <p className={Styles.numberAlign}>{servicesMarkupPercent}</p>
                        <p className={Styles.numberAlign}>{servicesSubMarkupPercent}</p>
                        <p className={Styles.numberAlign}>{appurtenanciesMarkupPercent}</p>
                    </div>
                )
            },
            {
                title: formatMessage({ id: 'fraction' }),
                render: ({
                    totalFractionPercent,
                    servicesFractionPercent,
                    servicesSubFractionPercent,
                    appurtenanciesFractionPercent
                }) => (
                    <div>
                        <p className={`${Styles.bold} ${Styles.numberAlign}`}>{totalFractionPercent}</p>
                        <p className={Styles.numberAlign}>{servicesFractionPercent}</p>
                        <p className={Styles.numberAlign}>{servicesSubFractionPercent}</p>
                        <p className={Styles.numberAlign}>{appurtenanciesFractionPercent}</p>
                    </div>
                )
            },
            {
                title: formatMessage({ id: 'salary' }),
                children: [
                    {
                        title: '₴',
                        render: ({ totalSalary, servicesSalary, subcontractSalary, partsSalary }) => (
                            <div>
                                <p className={`${Styles.bold} ${Styles.numberAlign}`}>
                                    {numeralFormatter2(totalSalary)}
                                </p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(servicesSalary)}</p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(subcontractSalary)}</p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(partsSalary)}</p>
                            </div>
                        )
                    },
                    {
                        title: '%',
                        render: ({
                            totalSalaryPercent,
                            servicesSalaryPercent,
                            subcontractSalaryPercent,
                            partsSalaryPercent
                        }) => (
                            <div>
                                <p className={`${Styles.bold} ${Styles.numberAlign}`}>{totalSalaryPercent}</p>
                                <p className={Styles.numberAlign}>{servicesSalaryPercent}</p>
                                <p className={Styles.numberAlign}>{subcontractSalaryPercent}</p>
                                <p className={Styles.numberAlign}>{partsSalaryPercent}</p>
                            </div>
                        )
                    }
                ]
            },
            {
                title: formatMessage({ id: 'gross_profit' }),
                children: [
                    {
                        title: '₴',
                        render: ({
                            totalGrossProfit,
                            grossProfitServices,
                            grossProfitSubcontract,
                            grossProfitParts
                        }) => (
                            <div>
                                <p className={`${Styles.bold} ${Styles.numberAlign}`}>
                                    {numeralFormatter2(totalGrossProfit)}
                                </p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(grossProfitServices)}</p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(grossProfitSubcontract)}</p>
                                <p className={Styles.numberAlign}>{numeralFormatter2(grossProfitParts)}</p>
                            </div>
                        )
                    },
                    {
                        title: '%',
                        render: ({
                            totalGrossProfitPercent,
                            grossProfitServicesPercent,
                            sgrossProfitSubcontractPercent,
                            grossProfitPartsPercent
                        }) => (
                            <div>
                                <p className={`${Styles.bold} ${Styles.numberAlign}`}>{totalGrossProfitPercent}</p>
                                <p className={Styles.numberAlign}>{grossProfitServicesPercent}</p>
                                <p className={Styles.numberAlign}>{sgrossProfitSubcontractPercent}</p>
                                <p className={Styles.numberAlign}>{grossProfitPartsPercent}</p>
                            </div>
                        )
                    }
                ]
            }
        ];
    }, [debouncedNestedFiltersChange, formatMessage, queryFilters]);

    useEffect(() => {
        fetchAnalytics();
    }, [fetchAnalytics]);

    return (
        <Table
            bordered
            className={Styles.table}
            columns={analyticsColumns}
            dataSource={dataSource}
            pagination={{
                ...pagination,
                total,
                showSizeChanger: true,
                onChange: (page, pageSize) => setPagination(prev => ({ ...prev, pageSize, page }))
            }}
            rowKey='id'
            scroll={{
                x: 'max-content',
                y: '500px'
            }}
            size='small'
            sticky
            style={{ marginTop: 14 }}
        />
    );
};

// export default connect(mapStateToProps)(injectIntl(AnalyticsTab));
export default injectIntl(AnalyticsTab);
