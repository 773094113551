import { InputNumber, Tooltip } from 'antd';
import { selectCashRemaining, selectMode, setCashRemaining } from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    cashRemaining: selectCashRemaining(state),
    mode: selectMode(state),
    user: state.auth
});

const mapDispatchToProps = {
    setCashRemaining
};

/**
 * Has built in validators for sum
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RemainingCashInput extends React.Component {
    render() {
        const {
            cashRemaining,
            setCashRemaining,
            mode,
            user,
            editable,

            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Tooltip placement='bottom' title={<FormattedMessage id='cash-order-form.remaining' />} zIndex={2001}>
                    <InputNumber
                        addonAfter={<FormattedMessage id='cash-order-form.remaining' />}
                        className={Styles.inputNumber}
                        data-qa='remaining_cash'
                        decimalSeparator=','
                        readOnly
                        value={cashRemaining}
                        defaultValue={0}
                        // onChange={value => setCashRemaining(value)}
                        precision={2}
                    />
                </Tooltip>
            </div>
        );
    }
}
