import { Form } from '@ant-design/compatible';
import { Select, Tabs } from 'antd';
import { onChangeOrderForm, selectCashSum, setClientSelection } from 'core/forms/orderForm/duck';
import { resetModal, setModal } from 'core/modals/duck';
import { HistoryTable } from 'forms/OrderForm/OrderFormTables';
import _ from 'lodash';
import { ToSuccessModal, VehicleModal } from 'modals';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { buildStoreGroupsTree, fetchAPI, isForbidden, permissions, withReduxForm2 } from 'utils';
import { DetailsTable, DiscountPanel, ServicesTable } from '../OrderForm/OrderFormTables';
import { OrderMobileFormFields } from './OrderMobileFormFields';

const { Option } = Select;
const { TabPane } = Tabs;

@injectIntl
@withReduxForm2({
    name: 'orderForm',
    /* debouncedFields: [
        "comment",
        "recommendation",
        "vehicleCondition",
        "businessComment",
    ], */
    actions: {
        change: onChangeOrderForm,
        setClientSelection,
        resetModal,
        setModal
    },
    mapStateToProps: state => ({
        modal: state.modals.modal,
        user: state.auth,
        cashSum: selectCashSum(state),
        schedule: state.forms.orderForm.schedule,
        stationLoads: state.forms.orderForm.stationLoads,
        addClientFormData: state.forms.addClientForm.data,
        isMobile: state.ui.views.isMobile
    })
})
export class MobileRecordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formValues: {},
            labors: [],
            detailsTreeData: undefined,
            fetchedOrder: undefined,
            details: undefined
        };
    }

    componentDidMount() {
        document.querySelector('.ant-tabs-nav').scrollIntoView({ behavior: 'smooth', block: 'end' });
        this._fetchLaborsAndDetails();
        this._reloadOrderForm();
    }

    _fetchLaborsAndDetails = async () => {
        const details = await fetchAPI('GET', '/store_groups', undefined, undefined, {
            handleErrorInternally: true
        });
        const { labors } = await fetchAPI('GET', 'labors');
        await this.setState({ details, labors, detailsTreeData: buildStoreGroupsTree(details) });
    };

    _updateOrderField = async field => {
        const fetchedOrder = this.state.fetchedOrder || this.props.fetchedOrder;
        if (field == 'duration') {
            const orderServices = _.get(fetchedOrder, 'orderServices', []);
            let hours = 0;
            orderServices.map(elem => {
                if (elem.agreement != 'REJECTED') hours += elem.count;
            });

            if (hours > 8) {
                message.warning('Кількість годин більше 8. ');
                hours = 8;
            }

            field = { duration: Math.round(hours * 10) / 10 };
        }

        try {
            await fetchAPI('PUT', `orders/${this.props.orderId}`, null, field, {
                handleErrorInternally: true
            });
            this._reloadOrderForm();
            // this.props.fetchOrderForm(this.props.orderId);
        } catch (e) {}
    };

    _reloadOrderForm = async callback => {
        const fetchedOrder = await fetchAPI(
            'GET',
            `/orders/${this.props.orderId}`,
            {
                onlyLabors: true,
                onlyDetails: true
            },
            undefined,
            { handleErrorInternally: true }
        );
        await this.setState({ fetchedOrder });
        if (callback) callback(fetchedOrder);
    };

    render() {
        const {
            orderFetching,
            isMobile,
            orderStatus,
            selectedClient,
            orderFormRef,
            onStatusChange,
            user,
            orderTasks,
            orderHistory,
            orderId,
            allDetails,
            allServices,
            onClose,
            employees,
            fetchOrderForm,
            setAddClientModal,
            form,
            schedule,
            modal,
            resetModal,
            setModal,
            addClientFormData,
            stationLoads,
            searchClientsResult,
            managers,
            stations,
            cashSum,
            setClientSelection,
            vehicleTypes,
            client,
            fields
        } = this.props;
        const { formatMessage } = this.props.intl;

        const { fetchedOrder, labors } = this.state;
        const order = _.get(fetchedOrder, 'order', {});

        const clientVehicleTypeId = _.get(fetchedOrder, 'order.clientVehicleTypeId');
        const clientVehicleRadius = _.get(fetchedOrder, 'order.clientVehicleRadius');

        const isHistoryForbidden = isForbidden(user, permissions.ACCESS_ORDER_HISTORY);

        const {
            totalSum,
            totalSumWithTax,
            isTaxPayer,
            servicesDiscount,
            servicesSum,
            servicesSumDiscount,
            servicesTotalSum,
            detailsDiscount,
            detailsSum,
            detailsSumDiscount,
            detailsTotalSum
        } = order;

        const remainPrice = isTaxPayer
            ? Math.round((totalSumWithTax - cashSum) * 100) / 100
            : Math.round((totalSum - cashSum) * 100) / 100;

        const orderServices = _.get(fetchedOrder, 'orderServices', []);
        const orderDetails = _.get(fetchedOrder, 'orderDetails', []);

        const formFieldsValues = form.getFieldsValue();

        orderFormRef(form);

        return (
            <Form id='orderForm' layout='horizontal'>
                <Tabs size='default' type='line'>
                    <TabPane
                        key='general'
                        tab={formatMessage({
                            id: 'add_order_form.general',
                            defaultMessage: 'General'
                        })}
                    >
                        <OrderMobileFormFields
                            addClientFormData={addClientFormData}
                            allDetails={allDetails}
                            cashSum={cashSum}
                            client={client}
                            employees={employees}
                            fetchedOrder={fetchedOrder}
                            fetchOrderForm={fetchOrderForm}
                            fields={formFieldsValues}
                            form={form}
                            isMobile={isMobile}
                            isTaxPayer={isTaxPayer}
                            managers={managers}
                            modal={modal}
                            onClose={onClose}
                            onStatusChange={onStatusChange}
                            order={order}
                            orderHistory={orderHistory}
                            orderId={orderId}
                            orderStatus={orderStatus}
                            orderTasks={orderTasks}
                            resetModal={resetModal}
                            schedule={schedule}
                            searchClientsResult={searchClientsResult}
                            selectedClient={selectedClient}
                            setAddClientModal={setAddClientModal}
                            setClientSelection={setClientSelection}
                            setModal={setModal}
                            stationLoads={stationLoads}
                            stations={stations}
                            totalSum={totalSum}
                            totalSumWithTax={totalSumWithTax}
                            updateOrderField={fields => this._updateOrderField(fields)}
                            user={user}
                            vehicleTypes={vehicleTypes}
                            wrappedComponentRef={orderFormRef}
                        />
                        <VehicleModal isMobile={isMobile} />
                    </TabPane>
                    <TabPane
                        key='services'
                        forceRender
                        tab={`${formatMessage({
                            id: 'add_order_form.services',
                            defaultMessage: 'Services'
                        })} (${orderServices.length})`}
                    >
                        <ServicesTable
                            clientVehicleRadius={clientVehicleRadius}
                            clientVehicleTypeId={clientVehicleTypeId}
                            defaultEmployeeId={this.props.order.employeeId}
                            details={this.details}
                            employees={employees}
                            fetchedOrder={fetchedOrder}
                            isMobile={isMobile}
                            labors={labors}
                            orderFetching={orderFetching}
                            orderId={orderId}
                            orderServices={orderServices}
                            reloadOrderForm={() => {
                                this._reloadOrderForm();
                            }}
                            user={user}
                        />
                        <DiscountPanel
                            discountFieldName='servicesDiscount'
                            fetchedOrder={fetchedOrder}
                            forbidden={isForbidden(user, permissions.ACCESS_ORDER_LABORS_DISCOUNTS)}
                            form={form}
                            isMobile={isMobile}
                            orderId={orderId}
                            price={servicesSum}
                            reloadOrderForm={() => {
                                this._reloadOrderForm();
                            }}
                            servicesMode
                            totalServicesProfit={servicesSumDiscount}
                        />
                    </TabPane>
                    <TabPane
                        key='details'
                        forceRender
                        tab={`${formatMessage({
                            id: 'add_order_form.details',
                            defaultMessage: 'Details'
                        })} (${orderDetails.length})`}
                    >
                        <DetailsTable
                            allDetails={allDetails}
                            clientVehicleRadius={clientVehicleRadius}
                            clientVehicleTypeId={clientVehicleTypeId}
                            details={this.details}
                            detailsTreeData={this.state.detailsTreeData}
                            isMobile={isMobile}
                            labors={labors}
                            orderDetails={orderDetails}
                            orderFetching={orderFetching}
                            orderId={orderId}
                            reloadOrderForm={() => {
                                this._reloadOrderForm();
                            }}
                            user={user}
                        />
                        <DiscountPanel
                            detailsMode
                            discountFieldName='detailsDiscount'
                            fetchedOrder={fetchedOrder}
                            forbidden={isForbidden(user, permissions.ACCESS_ORDER_DETAILS_DISCOUNTS)}
                            form={form}
                            isMobile={isMobile}
                            orderId={orderId}
                            price={detailsSum}
                            reloadOrderForm={() => {
                                this._reloadOrderForm();
                            }}
                            totalServicesProfit={detailsSumDiscount}
                        />
                    </TabPane>
                    <TabPane
                        key='history'
                        disabled={isHistoryForbidden}
                        forceRender
                        tab={`${formatMessage({
                            id: 'order_form_table.history'
                        })}(${this.state.historyCount || '...'})`}
                    >
                        <HistoryTable
                            clientVehicleId={_.get(fetchedOrder, 'order.clientVehicleId', undefined)}
                            fetchOrderForm={fetchOrderForm}
                            setHistoryCount={historyCount => {
                                this.setState({
                                    historyCount
                                });
                            }}
                            user={user}
                        />
                    </TabPane>
                </Tabs>
                <ToSuccessModal
                    clientId={selectedClient.clientId}
                    onStatusChange={this.props.onStatusChange}
                    orderId={orderId}
                    remainPrice={remainPrice}
                    resetModal={resetModal}
                    visible={modal}
                    wrappedComponentRef={this._saveFormRef}
                />
            </Form>
        );
    }
}
