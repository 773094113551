import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchTasksSuccess, FETCH_TASKS, selectFilters } from './duck';

export function* fetchTasksSaga() {
    while (true) {
        try {
            yield take(FETCH_TASKS);

            const payloadFilters = yield select(selectFilters);

            const filters = {
                ...payloadFilters,
                scheduledDatetimeFrom: payloadFilters.scheduledDatetimeFrom
                    ? dayjs(payloadFilters.scheduledDatetimeFrom).format('YYYY-MM-DD')
                    : undefined,
                scheduledDatetimeTo: payloadFilters.scheduledDatetimeTo
                    ? dayjs(payloadFilters.scheduledDatetimeTo).format('YYYY-MM-DD')
                    : undefined
            };

            yield nprogress.start();

            const { employeeTasks, stats } = yield call(
                fetchAPI,
                'GET',
                'employee_tasks',
                { filters },
                undefined,
                { handleErrorInternally: true }
            );

            yield put(fetchTasksSuccess({ tasks: employeeTasks, stats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            // yield put(setFetchingVehicles(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchTasksSaga)]);
}
