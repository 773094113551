exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__row---2Wd4Y{padding:1em}.styles-m__colMobile---3YJDz{padding-top:8px}.styles-m__rowComment---os3PB{height:4em;padding:1em}.styles-m__radioButtons---3EXQT{width:100%}.styles-m__radioRow---2AVOd{margin-top:1em;height:1em}.styles-m__paddingCol---18JVj{padding-left:1em}.styles-m__datepickerContainer---3teNG .ant-calendar-picker-input,.styles-m__timepickerContainer---1-l9O .ant-time-picker-input{color:var(--text)!important;font-weight:400!important}@media screen and (max-width:1199px){.styles-m__row---2Wd4Y{padding:8px;justify-content:space-between}}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,uBAEI,WACJ,CACA,6BACI,eACJ,CAEA,8BACI,UAAW,CACX,WACJ,CAGA,gCACI,UACJ,CAEA,4BACI,cAAe,CACf,UACJ,CACA,8BACI,gBACJ,CAOA,gIACI,2BAA6B,CAC7B,yBACJ,CAEA,qCACI,uBACI,WAAY,CACZ,6BACJ,CACJ","file":"styles.m.css","sourcesContent":[".row {\r\n    /* height: 3em; */\r\n    padding: 1em;\r\n}\r\n.colMobile {\r\n    padding-top: 8px;\r\n}\r\n\r\n.rowComment {\r\n    height: 4em;\r\n    padding: 1em;\r\n}\r\n\r\n\r\n.radioButtons {\r\n    width: 100%;\r\n}\r\n\r\n.radioRow {\r\n    margin-top: 1em;\r\n    height: 1em;\r\n}\r\n.paddingCol{\r\n    padding-left: 1em;\r\n}\r\n\r\n.timepickerContainer :global(.ant-time-picker-input) {\r\n    color: var(--text) !important;\r\n    font-weight: 400 !important;\r\n}\r\n\r\n.datepickerContainer :global(.ant-calendar-picker-input) {\r\n    color: var(--text) !important;\r\n    font-weight: 400 !important;\r\n}\r\n\r\n@media screen and (max-width: 1199px) {\r\n    .row {\r\n        padding: 8px;\r\n        justify-content: space-between;\r\n    }\r\n}"]}]);

// Exports
exports.locals = {
	"row": "styles-m__row---2Wd4Y",
	"colMobile": "styles-m__colMobile---3YJDz",
	"rowComment": "styles-m__rowComment---os3PB",
	"radioButtons": "styles-m__radioButtons---3EXQT",
	"radioRow": "styles-m__radioRow---2AVOd",
	"paddingCol": "styles-m__paddingCol---18JVj",
	"timepickerContainer": "styles-m__timepickerContainer---1-l9O",
	"datepickerContainer": "styles-m__datepickerContainer---3teNG"
};