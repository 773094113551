import { ExclamationCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { v4 } from 'uuid';

const { confirm } = Modal;

function HamburgerMenu(props) {
    const {
        actionsMenu,
        optionsProp = [],
        trigger,
        children,
        disabled,
        destroyPopupOnHide = false,
        intl: { formatMessage },
        dropdownProps
    } = props;

    const transformToMenu = optionsProp => (
        <Menu type='inline'>
            {optionsProp.map(opt => (
                <Menu.Item
                    key={v4()}
                    disabled={opt.disabled}
                    onClick={() => {
                        if (opt.confirmTitle) {
                            confirm({
                                title: `${formatMessage({ id: opt.confirmTitle })}?`,
                                icon: <ExclamationCircleOutlined />,
                                onOk() {
                                    opt.onConfirm();
                                }
                            });
                        } else {
                            opt.onConfirm();
                        }
                    }}
                >
                    <div>
                        {opt.icon} {formatMessage({ id: opt.title })}
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown
            destroyPopupOnHide={destroyPopupOnHide}
            disabled={disabled}
            getPopupContainer={() => document.body}
            overlay={actionsMenu || transformToMenu(optionsProp)}
            placement='topLeft'
            trigger='click'
            {...dropdownProps}
        >
            {children || <Button data-qa='btn_hamburger' disabled={disabled} icon={<MenuOutlined />} />}
        </Dropdown>
    );
}

export default injectIntl(HamburgerMenu);

/*
if(opt.confirmTitle) {
    confirm({
        title: `${formatMessage({id: opt.confirmTitle})}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
            opt.onConfirm();
        }
    });
} else {
    opt.onConfirm();
}
*/
