import { StorageDocumentsContainer } from 'containers';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { grants, isGrantAccessed } from 'utils/grants';

const mapStateToProps = state => ({
    user: state.auth
});

@connect(mapStateToProps, void 0)
class OrdersExpensesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentFilters: undefined
        };
    }

    render() {
        const { user } = this.props;
        const { documentFilters } = this.state;
        const isCRUDForbidden = !isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT);

        return (
            <StorageDocumentsContainer
                isCRUDForbidden={isCRUDForbidden}
                listType='STORAGE_ORDERS_EXPENSE'
                newDocType='EXPENSE'
            />
        );
    }
}

export default OrdersExpensesPage;
