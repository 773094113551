import { Button, Input, Modal, notification, Popover, Select, Table } from 'antd';
import { Catcher } from 'commons';
import { StoreProductTrackingModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { confirm, warning } = Modal;
const { Option } = Select;

const INACTIVE = 'INACTIVE';
const AGREED = 'AGREED';
const ORDERED = 'ORDERED';
const ACCEPTED = 'ACCEPTED';
const RESERVED = 'RESERVED';
const GIVEN = 'GIVEN';
const INSTALLED = 'INSTALLED';
const NO_SPARE_PART = 'NO_SPARE_PART';
const RETURNED = 'RETURNED';
const CANCELED = 'CANCELED';
const ALL = 'ALL';
const stageArr = [
    INACTIVE,
    AGREED,
    ORDERED,
    ACCEPTED,
    RESERVED,
    GIVEN,
    INSTALLED,
    NO_SPARE_PART,
    RETURNED,
    CANCELED
];

@injectIntl
export default class StockTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dataSource: [],
            stageFilter: undefined,
            fieldsFilter: undefined,
            selectedRows: [],
            reserveWarehouseId: undefined,
            mainWarehouseId: undefined
        };

        this.updateDetail = this.updateDetail.bind(this);
        this.updateDataSource = this.updateDataSource.bind(this);
        this.orderOrAcceptDetails = this.orderOrAcceptDetails.bind(this);

        this.columns = [
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'detailName',
                dataIndex: 'detailName'
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brandName',
                dataIndex: 'brandName',
                render: data => {
                    return data || <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'detailCode',
                dataIndex: 'detailCode',
                render: data => {
                    return data || <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.supplier' />,
                key: 'supplierName',
                dataIndex: 'supplierName',
                render: data => {
                    return data || <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.purchasePrice' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                render: data => {
                    const strVal = Number(data).toFixed(2);

                    return (
                        <span>
                            {data ? (
                                `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </span>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'price',
                dataIndex: 'price',
                render: data => {
                    const strVal = Number(data).toFixed(2);

                    return (
                        <span>
                            {data ? (
                                `${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </span>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='order_form_table.count' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'count',
                dataIndex: 'count',
                render: data => {
                    return (
                        <span>
                            {data ? `${data}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}{' '}
                            <FormattedMessage id='pc' />
                        </span>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='storage.RESERVE' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'reserve',
                render: elem => {
                    return (
                        <span>
                            {elem.reservedCount || 0} <FormattedMessage id='pc' />
                        </span>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.status' />,
                key: 'agreement',
                dataIndex: 'agreement',
                render: (data, elem) => {
                    const { key } = elem;
                    const confirmed = data.toLowerCase();
                    let color;
                    switch (confirmed) {
                        case 'rejected':
                            color = 'rgb(255, 126, 126)';
                            break;
                        case 'agreed':
                            color = 'var(--green)';
                            break;
                        default:
                            color = null;
                    }

                    return (
                        <Select
                            data-qa='select_aggrement_stock_table_order_page'
                            disabled
                            onChange={value => {
                                elem.agreement = value.toUpperCase();
                                this.updateDetail(key, elem);
                            }}
                            style={{ color }}
                            value={confirmed}
                        >
                            <Option key={0} value='undefined'>
                                <FormattedMessage id='status.undefined' />
                            </Option>
                            <Option key={1} style={{ color: 'var(--green)' }} value='agreed'>
                                <FormattedMessage id='status.agreed' />
                            </Option>
                            <Option
                                key={2}
                                style={{ color: 'rgb(255, 126, 126)' }}
                                value='rejected'
                            >
                                <FormattedMessage id='status.rejected' />
                            </Option>
                        </Select>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.stage' />,
                key: 'stage',
                dataIndex: 'stage',
                render: data => {
                    return <FormattedMessage id={`stock_table.${data}`} />;
                }
            },
            {
                title: (
                    <Popover
                        content={
                            <DetailsStageButtonsGroup
                                data-qa='button_group_other_stock_table_order_page'
                                onClick={stage => {
                                    this.multipleChangeState(stage);
                                }}
                                stage={ALL}
                                user={this.props.user}
                            />
                        }
                        overlayStyle={{ zIndex: 9999 }}
                        trigger='click'
                    >
                        <Button
                            data-qa='button_other_stock_table_order_page'
                            style={{ width: '100%', margin: 1 }}
                            type='primary'
                        >
                            <FormattedMessage id='order_form_table.other' />
                        </Button>
                    </Popover>
                ),
                key: 'actions',
                dataIndex: 'stage',
                render: (stage, elem) => {
                    return (
                        <DetailsStageButtonsGroup
                            agreedAction={value => {
                                (elem.agreement = value), this.updateDetail(elem.key, elem);
                            }}
                            data-qa='button_details_group_actions_stock_table_order_page'
                            detail={elem}
                            mainWarehouseId={this.state.mainWarehouseId}
                            onClick={value => {
                                elem.stage = value;
                                this.updateDetail(elem.key, elem);
                            }}
                            orderOrAcceptDetails={this.orderOrAcceptDetails}
                            reserveWarehouseId={this.state.reserveWarehouseId}
                            showReserveModal={productId => {
                                this.setState({
                                    reserveModalVisible: true,
                                    reserveModalData: productId
                                });
                            }}
                            stage={stage}
                            updateDetail={this.updateDetail}
                            user={this.props.user}
                        />
                    );
                }
            }
        ];

        this.mobileColumns = [
            {
                title: (
                    <div>
                        <p>
                            <FormattedMessage id='order_form_table.detail_name' />
                        </p>
                        <p>
                            <FormattedMessage id='order_form_table.detail_code' />
                        </p>
                    </div>
                ),
                key: 'detailName',
                dataIndex: 'detailName',
                render: (data, row) => {
                    return (
                        <div>
                            <p>{data}</p>
                            <p>{row.detailCode}</p>
                        </div>
                    );
                }
            },
            {
                title: (
                    <div>
                        <p>
                            <FormattedMessage id='order_form_table.brand' />
                        </p>
                        <p>
                            <FormattedMessage id='order_form_table.stage' />
                        </p>
                    </div>
                ),
                key: 'brandName',
                dataIndex: 'brandName',
                render: (data, row) => {
                    return (
                        <div>
                            <p>{data || <FormattedMessage id='long_dash' />}</p>
                            <p>
                                <FormattedMessage id={`stock_table.${row.stage}`} />
                            </p>
                        </div>
                    );
                }
            },
            {
                key: 'actions',
                dataIndex: 'stage',
                render: (stage, elem) => {
                    return (
                        <DetailsStageButtonsGroup
                            detail={elem}
                            isMobile
                            mainWarehouseId={this.state.mainWarehouseId}
                            onClick={value => {
                                elem.stage = value;
                                this.updateDetail(elem.key, elem);
                            }}
                            reserveWarehouseId={this.state.reserveWarehouseId}
                            stage={stage}
                            updateDetail={this.updateDetail}
                            user={this.props.user}
                        />
                    );
                }
            }
        ];
    }

    async multipleChangeState(stage) {
        const { selectedRows, dataSource } = this.state;
        const data = {
            updateMode: true,
            details: []
        };

        const toReserve = [];
        const toUnreserve = [];

        selectedRows.map(key => {
            dataSource[key].stage == stage || dataSource[key].stage;
            data.details.push({
                id: dataSource[key].id,
                stage
            });
            if (stage == RESERVED || stage == GIVEN) {
                toReserve.push(dataSource[key].id);
            }
            if (stage == CANCELED || stage == RETURNED) {
                toUnreserve.push(dataSource[key].id);
            }
        });
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = `${__API_URL__}/orders/${this.props.orderId}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (result.success) {
            } else {
                console.log('BAD', result);
            }
            this.updateDataSource();
        } catch (error) {
            console.error('ERROR:', error);
            this.updateDataSource();
        }

        if (toReserve.length) {
            url = `${__API_URL__}/store_docs/reserve_all_possible?ordersAppurtenanciesIds=[${toReserve}]`;
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                const result = await response.json();
                this.updateDataSource();
            } catch (error) {
                console.error('ERROR:', error);
            }
        }
        if (toUnreserve.length) {
            url = `${__API_URL__}/store_docs/unreserve_all_possible?ordersAppurtenanciesIds=[${toUnreserve}]`;
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                const result = await response.json();
                this.updateDataSource();
            } catch (error) {
                console.error('ERROR:', error);
            }
        }
    }

    async updateDataSource() {
        const callback = data => {
            data.orderDetails.map((elem, index) => {
                elem.key = index;
            });
            this.setState({
                dataSource: data.orderDetails,
                fetched: true
            });
        };

        if (this.props.reloadOrderForm) this.props.reloadOrderForm(callback, 'details', true);
        else {
            const token = localStorage.getItem('_my.carbook.pro_token');
            const url = `${__API_URL__}/orders/${this.props.orderId}/details`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json'
                    }
                });
                const result = await response.json();
                this.setState({
                    dataSource: result.details
                });
            } catch (error) {
                console.error('ERROR:', error);
            }
        }
    }

    orderOrAcceptDetails(supplierId, supplierName, stage, operation) {
        const {
            orderId,
            intl: { formatMessage }
        } = this.props;
        const { dataSource, mainWarehouseId } = this.state;
        const { updateDataSource } = this;
        const resultData = {
            updateMode: true,
            details: []
        };

        const orderData = {
            status: 'DONE',
            context: 'ORDER',
            type: operation == 'ORDER' ? 'INCOME' : 'EXPENSE',
            documentType: 'SUPPLIER',
            supplierDocNumber: orderId,
            payUntilDatetime: null,
            docProducts: [],
            orderId,
            warehouseId: mainWarehouseId,
            counterpartBusinessSupplierId: supplierId
        };

        dataSource.map(elem => {
            if (elem.supplierId == supplierId) {
                resultData.details.push({
                    id: elem.id,
                    stage
                });
                if (elem.productId) {
                    orderData.docProducts.push({
                        productId: elem.productId,
                        quantity: elem.count,
                        stockPrice: elem.purchasePrice
                    });
                } else {
                    orderData.docProducts.push({
                        addToStore: true,
                        groupId: elem.storeGroupId,
                        code: elem.detailCode,
                        name: elem.detailName || elem.detailCode,
                        brandId: elem.supplierBrandId,
                        quantity: elem.quantity || 1,
                        stockPrice: elem.purchasePrice
                    });
                }
            }
        });

        confirm({
            title:
                operation == 'ORDER'
                    ? `${formatMessage(
                          { id: 'stock_table.create_order_to_supplier' },
                          { name: supplierName }
                      )}`
                    : `${formatMessage(
                          { id: 'stock_table.create_order_income' },
                          { name: supplierName }
                      )}`,
            content: '',
            async onOk() {
                const token = localStorage.getItem('_my.carbook.pro_token');
                let url = `${__API_URL__}/orders/${orderId}`;
                try {
                    const response = await fetch(url, {
                        method: 'PUT',
                        headers: {
                            Authorization: token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(resultData)
                    });
                    const result = await response.json();
                    if (result.success) {
                    } else {
                        console.log('BAD', result);
                    }
                    updateDataSource();
                } catch (error) {
                    console.error('ERROR:', error);
                    updateDataSource();
                }

                url = `${__API_URL__}/store_docs`;
                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            Authorization: token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(orderData)
                    });
                    const result = await response.json();
                } catch (error) {
                    console.error('ERROR:', error);
                }
            },
            onCancel() {}
        });
    }

    async updateDetail(key, detail) {
        this.state.dataSource[key] = detail;
        const data = {
            updateMode: true,
            details: [
                {
                    id: detail.id,
                    stage: detail.stage,
                    reservedCount: detail.reservedCount
                }
            ]
        };

        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/orders/${this.props.orderId}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (result.success) {
            } else {
                console.log('BAD', result);
            }
            this.updateDataSource();
        } catch (error) {
            console.error('ERROR:', error);
            this.updateDataSource();
        }
    }

    fetchData() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/warehouses`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const warehousesData = {};
                data.map(warehouse => {
                    if (warehouse.attribute == 'MAIN') {
                        warehousesData.main = warehouse.id;
                    }
                    if (warehouse.attribute == 'RESERVE') {
                        warehousesData.reserve = warehouse.id;
                    }
                });
                that.setState({
                    mainWarehouseId: warehousesData.main,
                    reserveWarehouseId: warehousesData.reserve,
                    fetched: true
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    componentDidMount() {
        this.fetchData();
        const tmp = [...this.props.orderDetails];
        tmp.map((elem, i) => (elem.key = i));
        this.setState({
            dataSource: tmp
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeKey != 'stock' && this.props.activeKey == 'stock') {
            let tmp = [...this.props.orderDetails];
            tmp = tmp.filter(elem => elem.id);
            tmp.map((elem, i) => (elem.key = i));
            this.setState({
                dataSource: tmp,
                stageFilter: undefined,
                fieldsFilter: undefined,
                selectedRows: []
            });
        }
    }

    render() {
        const {
            dataSource,
            loading,
            fieldsFilter,
            stageFilter,
            reserveModalVisible,
            reserveModalData
        } = this.state;
        const { isMobile } = this.props;
        let filteredData = [...dataSource];
        if (fieldsFilter) {
            filteredData = dataSource.filter(
                elem =>
                    String(elem.detailName).toLowerCase().includes(fieldsFilter.toLowerCase()) ||
                    String(elem.brandName).toLowerCase().includes(fieldsFilter.toLowerCase()) ||
                    String(elem.detailCode).toLowerCase().includes(fieldsFilter.toLowerCase()) ||
                    String(elem.supplierName).toLowerCase().includes(fieldsFilter.toLowerCase())
            );
        }

        if (stageFilter) {
            filteredData = dataSource.filter(elem => elem.stage == stageFilter);
        }

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRows: selectedRowKeys
                });
            }
        };

        return (
            <Catcher>
                {!isMobile && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '12px 0'
                        }}
                    >
                        <div style={{ width: '74%' }}>
                            <Input
                                allowClear
                                onChange={({ target: { value } }) => {
                                    this.setState({
                                        fieldsFilter: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.fields_filter'
                                })}
                            />
                        </div>
                        <div style={{ width: '25%' }}>
                            <Select
                                allowClear
                                onChange={value => {
                                    this.setState({
                                        stageFilter: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.stage'
                                })}
                                showSearch
                            >
                                {stageArr.map((value, key) => {
                                    return (
                                        <Option key={key} value={value}>
                                            <FormattedMessage id={`stock_table.${value}`} />
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                )}
                <Table
                    bordered
                    columns={isMobile ? this.mobileColumns : this.columns}
                    dataSource={filteredData}
                    loading={loading}
                    pagination={false}
                    rowClassName={record => {
                        const { stage } = record;

                        return Styles[stage];
                    }}
                    rowSelection={isMobile ? null : rowSelection}
                    size='small'
                    style={isMobile ? {} : { overflowX: 'scroll' }}
                />
                <StoreProductTrackingModal
                    hideModal={() => {
                        this.setState({
                            reserveModalVisible: false,
                            reserveModalData: undefined
                        });
                    }}
                    productId={reserveModalData}
                    visible={reserveModalVisible}
                />
            </Catcher>
        );
    }
}

@injectIntl
class DetailsStageButtonsGroup extends Component {
    getStoreProduct = (detailCode, brandId) => {
        const { detail, updateDetail } = this.props;
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/store_products?all=true`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const product = data.list.find(
                    product => product.code == detailCode && product.brandId == brandId
                );
                if (product) {
                    detail.productId = product.id;
                    detail.defaultWarehouseId = product.defaultWarehouseId;
                    updateDetail(detail.key, detail);
                }
            })
            .catch(function (error) {
                console.log('error', error);
                that.setState({
                    fetched: true,
                    codeFilter: that.props.codeFilter
                });
            });
    };

    reserveProduct = stage => {
        const {
            detail,
            updateDetail,
            orderId,
            reserveWarehouseId,
            mainWarehouseId,
            showReserveModal,
            intl: { formatMessage }
        } = this.props;
        if (detail.reserved) {
            detail.stage = stage;
            updateDetail(detail.key, detail);

            return;
        }
        const data = {
            status: 'DONE',
            documentType: 'TRANSFER',
            type: 'EXPENSE',
            supplierDocNumber: orderId,
            payUntilDatetime: null,
            docProducts: [
                {
                    productId: detail.productId,
                    quantity: detail.count,
                    stockPrice: detail.purchasePrice || 0
                }
            ],
            counterpartWarehouseId: reserveWarehouseId,
            orderId
        };
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/store_docs`;
        fetch(url, {
            method: 'POST',
            headers: {
                Authorization: token
            },
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                if (response.created) {
                    notification.success({
                        message: formatMessage(
                            { id: 'storage_document.notification.reserved' },
                            { count: data.docProducts[0].quantity }
                        ),
                        description: `${formatMessage({ id: 'storage' })} ${
                            detail.reservedFromWarehouseName
                        }`
                    });
                    detail.reservedCount = detail.count;
                    detail.reserved = true;
                    detail.stage = stage;
                    updateDetail(detail.key, detail);
                } else {
                    const { productId } = response.notAvailableProducts[0].productId;
                    const availableCount = response.notAvailableProducts[0].available;
                    const { reservedCount } = response.notAvailableProducts[0];
                    confirm({
                        title: formatMessage({ id: 'storage_document.not_enought_for_reserve' }),
                        content: (
                            <div>
                                <p>
                                    {formatMessage({ id: 'storage_document.in_stock' })} -{' '}
                                    {availableCount}.
                                </p>
                                <p>
                                    {formatMessage({ id: 'storage_document.available' })} -{' '}
                                    {availableCount - reservedCount}.
                                </p>
                                <span
                                    onClick={() => showReserveModal(productId)}
                                    style={{
                                        color: 'var(--link)',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {formatMessage({ id: 'storage_document.more_details' })}...
                                </span>
                            </div>
                        ),
                        zIndex: 1000,
                        okButtonProps: { disabled: availableCount - reservedCount < 1 },
                        onOk() {
                            data.docProducts[0].quantity = availableCount - reservedCount;
                            fetch(url, {
                                method: 'POST',
                                headers: {
                                    Authorization: token
                                },
                                body: JSON.stringify(data)
                            })
                                .then(function (response) {
                                    if (response.status !== 200) {
                                        return Promise.reject(new Error(response.statusText));
                                    }

                                    return Promise.resolve(response);
                                })
                                .then(function (response) {
                                    return response.json();
                                })
                                .then(function (response) {
                                    if (response.created) {
                                        detail.reservedCount = availableCount - reservedCount;
                                        detail.reserved = true;
                                        updateDetail(detail.key, detail);
                                        notification.success({
                                            message: `Зарезервировано ${data.docProducts[0].quantity} товаров со склада ${detail.reservedFromWarehouseName}`
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log('error', error);
                                });
                        }
                    });
                }
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    unreserveProduct = stage => {
        const {
            detail,
            updateDetail,
            orderId,
            reserveWarehouseId,
            mainWarehouseId,
            intl: { formatMessage }
        } = this.props;
        if (!detail.reserved) {
            detail.stage = stage;
            updateDetail(detail.key, detail);

            return;
        }
        const data = {
            status: 'DONE',
            documentType: 'TRANSFER',
            type: 'EXPENSE',
            supplierDocNumber: orderId,
            payUntilDatetime: null,
            docProducts: [
                {
                    productId: detail.productId,
                    quantity: detail.reservedCount,
                    stockPrice: detail.purchasePrice || 0
                }
            ],
            warehouseId: reserveWarehouseId,
            orderId
        };
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/store_docs`;
        fetch(url, {
            method: 'POST',
            headers: {
                Authorization: token
            },
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                if (response.created) {
                    notification.success({
                        message: formatMessage({
                            id: 'storage_document.notification.reserve_canceled'
                        }),
                        description: `${formatMessage({ id: 'storage' })} ${
                            detail.reservedFromWarehouseName
                        }`
                    });
                    detail.reservedCount = 0;
                    detail.reserved = false;
                    detail.stage = stage;
                    updateDetail(detail.key, detail);
                }
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    addProduct = () => {
        const {
            detail,
            updateDetail,
            orderId,
            reserveWarehouseId,
            mainWarehouseId,
            intl: { formatMessage }
        } = this.props;
        const that = this;
        confirm({
            title: formatMessage({ id: 'storage_document.error.product_not_found' }),
            onOk() {
                const postData = {
                    name: detail.detailName,
                    groupId: detail.storeGroupId,
                    code: detail.detailCode,
                    brandId: detail.brandId || detail.supplierBrandId,
                    measureUnit: 'PIECE',
                    defaultWarehouseId: mainWarehouseId
                };
                const token = localStorage.getItem('_my.carbook.pro_token');
                const url = `${__API_URL__}/store_products`;
                fetch(url, {
                    method: 'POST',
                    headers: {
                        Authorization: token
                    },
                    body: JSON.stringify(postData)
                })
                    .then(function (response) {
                        if (response.status !== 200) {
                            return Promise.reject(new Error(response.statusText));
                        }

                        return Promise.resolve(response);
                    })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (data) {
                        if (data.created) {
                            updateDetail(detail.key, { ...detail, productId: data.id });
                        }
                    })
                    .catch(function (error) {
                        console.log('error', error);
                        that.getStoreProduct(detail.detailCode, detail.brandId);
                    });
            }
        });
    };

    render() {
        const {
            stage,
            onClick,
            agreedAction,
            detail,
            updateDetail,
            orderOrAcceptDetails,
            isMobile,
            user
        } = this.props;

        return !isMobile ? (
            <div className={Styles.detailStageButtonsGroup}>
                <div className={Styles.buttonsRow}>
                    <Button
                        className={Styles.greenButton}
                        disabled={stage != ALL && !(stage == INACTIVE || stage == NO_SPARE_PART)}
                        onClick={() => {
                            if (stage != ALL) agreedAction('AGREED');
                            else onClick(undefined, 'AGREED');
                        }}
                    >
                        <FormattedMessage id='stock_table.button.agree' />
                    </Button>
                    <Button
                        className={Styles.greenButton}
                        disabled={
                            stage != ALL &&
                            !(
                                stage == INACTIVE ||
                                (detail.agreement == AGREED && stage == INACTIVE) ||
                                stage == ORDERED ||
                                stage == ACCEPTED ||
                                stage == GIVEN ||
                                stage == RESERVED
                            )
                        }
                        onClick={() => {
                            if (stage != ALL) {
                                detail.stage = INSTALLED;
                                updateDetail(detail.key, detail);
                            } else {
                                onClick(INSTALLED);
                            }
                        }}
                    >
                        <FormattedMessage id='stock_table.button.install' />
                    </Button>
                </div>
                <div className={Styles.buttonsRow}>
                    <Button
                        className={Styles.greenButton}
                        disabled={
                            (detail && detail.reservedCount) ||
                            (stage != ALL &&
                                !(
                                    (detail.agreement == AGREED && stage == INACTIVE) ||
                                    stage == NO_SPARE_PART ||
                                    stage == ORDERED ||
                                    stage == ACCEPTED
                                ))
                        }
                        onClick={() => {
                            if (stage != ALL) {
                                if (detail.productId) {
                                    this.reserveProduct(RESERVED);
                                } else {
                                    this.addProduct();
                                }
                            } else {
                                onClick(RESERVED);
                            }
                        }}
                    >
                        <FormattedMessage id='stock_table.button.reserve' />
                    </Button>
                    <Button
                        className={Styles.redButton}
                        disabled={
                            stage != ALL &&
                            !(
                                stage == INACTIVE ||
                                (detail.agreement == AGREED && stage == INACTIVE) ||
                                stage == ORDERED ||
                                stage == ACCEPTED ||
                                stage == GIVEN ||
                                stage == RESERVED
                            )
                        }
                        onClick={() => {
                            if (stage != ALL) {
                                detail.stage = NO_SPARE_PART;
                                updateDetail(detail.key, detail);
                            } else {
                                onClick(NO_SPARE_PART);
                            }
                        }}
                    >
                        <FormattedMessage id='stock_table.button.no_spare_part' />
                    </Button>
                </div>
                <div className={Styles.buttonsRow}>
                    {isForbidden(user, permissions.ACCESS_ORDER_TABS_STOCK_BUTTONS_STOCK) ? (
                        <Button disabled type='primary'>
                            <FormattedMessage id='order_tabs.stock' />
                        </Button>
                    ) : (
                        <Popover
                            content={
                                <div className={Styles.popoverBlock}>
                                    <Button
                                        className={Styles.greenButton}
                                        disabled={
                                            stage == ALL ||
                                            (detail && detail.supplierId == 0) ||
                                            !(
                                                (detail.agreement == AGREED && stage == INACTIVE) ||
                                                stage == NO_SPARE_PART
                                            )
                                        }
                                        onClick={() => {
                                            orderOrAcceptDetails(
                                                detail.supplierId,
                                                detail.supplierName,
                                                ORDERED,
                                                'ORDER'
                                            );
                                        }}
                                    >
                                        <FormattedMessage id='stock_table.button.order' />
                                    </Button>
                                    <Button
                                        className={Styles.greenButton}
                                        disabled={
                                            stage == ALL ||
                                            (detail && detail.supplierId == 0) ||
                                            !(
                                                (detail.agreement == AGREED && stage == INACTIVE) ||
                                                stage == NO_SPARE_PART ||
                                                stage == ORDERED
                                            )
                                        }
                                        onClick={() => {
                                            orderOrAcceptDetails(
                                                detail.supplierId,
                                                detail.supplierName,
                                                ACCEPTED,
                                                'ACCEPT'
                                            );
                                        }}
                                    >
                                        <FormattedMessage id='stock_table.button.accept' />
                                    </Button>
                                </div>
                            }
                            disabled={
                                !isForbidden(
                                    user,
                                    permissions.ACCESS_ORDER_TABS_STOCK_BUTTONS_STOCK
                                )
                            }
                            overlayStyle={{ zIndex: 9999 }}
                            trigger='click'
                        >
                            <Button type='primary'>
                                <FormattedMessage id='order_tabs.stock' />
                            </Button>
                        </Popover>
                    )}
                    {isForbidden(user, permissions.ACCESS_ORDER_TABS_STOCK_BUTTONS_WORKSHOP) ? (
                        <Button disabled type='primary'>
                            <FormattedMessage id='order_tabs.workshop' />
                        </Button>
                    ) : (
                        <Popover
                            content={
                                <div className={Styles.popoverBlock}>
                                    <Button
                                        className={Styles.greenButton}
                                        disabled={
                                            stage != ALL &&
                                            !(
                                                stage == INACTIVE ||
                                                (detail.agreement == AGREED && stage == INACTIVE) ||
                                                stage == NO_SPARE_PART ||
                                                stage == RESERVED ||
                                                stage == ORDERED ||
                                                stage == ACCEPTED
                                            )
                                        }
                                        onClick={() => {
                                            if (stage != ALL) {
                                                this.reserveProduct(GIVEN);
                                            } else {
                                                onClick(GIVEN);
                                            }
                                        }}
                                    >
                                        <FormattedMessage id='stock_table.button.get' />
                                    </Button>
                                    <Button
                                        className={Styles.greenButton}
                                        disabled={
                                            stage != ALL && !(stage == GIVEN || stage == CANCELED)
                                        }
                                        onClick={() => {
                                            if (stage != ALL) {
                                                this.unreserveProduct(RETURNED);
                                            } else {
                                                onClick(RETURNED);
                                            }
                                        }}
                                    >
                                        <FormattedMessage id='stock_table.button.return' />
                                    </Button>
                                    <Button
                                        className={Styles.yellowButton}
                                        onClick={() => {
                                            if (stage != ALL) {
                                                this.unreserveProduct(CANCELED);
                                            } else {
                                                onClick(CANCELED);
                                            }
                                        }}
                                    >
                                        <FormattedMessage id='stock_table.button.cancel' />
                                    </Button>
                                </div>
                            }
                            overlayStyle={{ zIndex: 9999 }}
                            trigger='click'
                        >
                            <Button type='primary'>
                                <FormattedMessage id='order_tabs.workshop' />
                            </Button>
                        </Popover>
                    )}
                </div>
            </div> // MOBILE
        ) : (
            <div className={Styles.detailStageButtonsGroup}>
                <div>
                    <Button
                        className={Styles.greenButton}
                        disabled={
                            stage != ALL &&
                            !(
                                stage == INACTIVE ||
                                (detail.agreement == AGREED && stage == INACTIVE) ||
                                stage == ORDERED ||
                                stage == ACCEPTED ||
                                stage == GIVEN ||
                                stage == RESERVED
                            )
                        }
                        onClick={() => {
                            detail.stage = INSTALLED;
                            updateDetail(detail.key, detail);
                        }}
                        style={{ width: '100%' }}
                    >
                        <FormattedMessage id='stock_table.button.install' />
                    </Button>
                </div>
                <div>
                    <Button
                        className={Styles.redButton}
                        disabled={
                            stage != ALL &&
                            !(
                                stage == INACTIVE ||
                                (detail.agreement == AGREED && stage == INACTIVE) ||
                                stage == ORDERED ||
                                stage == ACCEPTED ||
                                stage == GIVEN ||
                                stage == RESERVED
                            )
                        }
                        onClick={() => {
                            detail.stage = NO_SPARE_PART;
                            updateDetail(detail.key, detail);
                        }}
                        style={{ width: '100%' }}
                    >
                        <FormattedMessage id='stock_table.button.no_spare_part' />
                    </Button>
                </div>
                <div>
                    {isForbidden(user, permissions.ACCESS_ORDER_TABS_STOCK_BUTTONS_WORKSHOP) ? (
                        <Button disabled style={{ width: '100%' }} type='primary'>
                            <FormattedMessage id='order_tabs.workshop' />
                        </Button>
                    ) : (
                        <Popover
                            content={
                                <div className={Styles.popoverBlock}>
                                    <Button
                                        className={Styles.greenButton}
                                        disabled={
                                            stage != ALL &&
                                            !(
                                                stage == INACTIVE ||
                                                (detail.agreement == AGREED && stage == INACTIVE) ||
                                                stage == NO_SPARE_PART ||
                                                stage == RESERVED ||
                                                stage == ORDERED ||
                                                stage == ACCEPTED
                                            )
                                        }
                                        onClick={() => {
                                            this.reserveProduct(GIVEN);
                                        }}
                                    >
                                        <FormattedMessage id='stock_table.button.get' />
                                    </Button>
                                    <Button
                                        className={Styles.greenButton}
                                        disabled={
                                            stage != ALL && !(stage == GIVEN || stage == CANCELED)
                                        }
                                        onClick={() => {
                                            this.unreserveProduct(RETURNED);
                                        }}
                                    >
                                        <FormattedMessage id='stock_table.button.return' />
                                    </Button>
                                    <Button
                                        className={Styles.yellowButton}
                                        onClick={() => {
                                            this.unreserveProduct(CANCELED);
                                        }}
                                    >
                                        <FormattedMessage id='stock_table.button.cancel' />
                                    </Button>
                                </div>
                            }
                            overlayStyle={{ zIndex: 9999 }}
                            trigger='click'
                        >
                            <Button style={{ width: '100%' }} type='primary'>
                                <FormattedMessage id='order_tabs.workshop' />
                            </Button>
                        </Popover>
                    )}
                </div>
            </div>
        );
    }
}
