import { Tabs } from 'antd';
import { Layout } from 'commons';
import { deleteEmployee, fetchEmployees, setEmployeesStatus } from 'core/employees/duck';
import { initEmployeeForm } from 'core/forms/employeeForm/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { CategoriesTable, RowTypesTable, StatusesTable, SubStatusesTable, TypesTable } from './Tables';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    salaries: state.forms.settingSalary.salaries,
    entity: state.forms.settingSalary.fields,
    employees: state.employees.employees,
    status: state.employees.status,
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setEmployeesStatus,
    fetchEmployees,
    initEmployeeForm,
    deleteEmployee
};
@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class InteractionsDirectoriesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allCategories: [],
            allTypes: [],
            allRowTypes: [],
            allStatuses: [],
            allSubStatuses: []
        };
    }

    componentDidMount() {
        this.fetchCategories();
        this.fetchRowTypes();
        this.fetchTypes();
        this.fetchStatuses();
        this.fetchSubStatuses();
    }

    fetchCategories = async () => {
        const response = await fetchAPI('GET', '/crm/interations_categories', null, null, {
            handleErrorInternally: true
        });
        await this.setState({
            allCategories: response
        });
    };

    fetchRowTypes = async () => {
        const response = await fetchAPI('GET', '/crm/interations_row_type', null, null, {
            handleErrorInternally: true
        });
        await this.setState({
            allRowTypes: response
        });
    };

    fetchTypes = async () => {
        const response = await fetchAPI('GET', '/crm/interations_type', null, null, {
            handleErrorInternally: true
        });
        await this.setState({
            allTypes: response
        });
    };

    fetchStatuses = async () => {
        const response = await fetchAPI('GET', '/crm/status', null, null, {
            handleErrorInternally: true
        });
        await this.setState({
            allStatuses: response
        });
    };

    fetchSubStatuses = async () => {
        const response = await fetchAPI('GET', '/crm/substatus', null, null, {
            handleErrorInternally: true
        });
        await this.setState({
            allSubStatuses: response
        });
    };

    render() {
        const {user} = this.props;

        const { allCategories, allTypes, allRowTypes, allStatuses, allSubStatuses } = this.state;
        const isCRUDDisabled = !isGrantAccessed(user, grants.SETTINGS_CRM, accesses.ROWO);

        return (
            <Layout title={<FormattedMessage id='interactions.interactions_directories' />}>
                <Tabs defaultActiveKey={1}>
                    <TabPane
                        key='1'
                        tab={this.props.intl.formatMessage({
                            id: 'interactions.category_directory'
                        })}
                    >
                        <CategoriesTable disabled={isCRUDDisabled} data={allCategories} fetchData={this.fetchCategories} />
                    </TabPane>
                    <TabPane
                        key='2'
                        tab={this.props.intl.formatMessage({
                            id: 'interactions.types_directory'
                        })}
                    >
                        <TypesTable disabled={isCRUDDisabled} data={allTypes} fetchData={this.fetchTypes} />
                    </TabPane>
                    <TabPane
                        key='3'
                        tab={this.props.intl.formatMessage({
                            id: 'interactions.row_types_directory'
                        })}
                    >
                        <RowTypesTable disabled={isCRUDDisabled} data={allRowTypes} fetchData={this.fetchRowTypes} />
                    </TabPane>
                    <TabPane
                        key='4'
                        tab={this.props.intl.formatMessage({
                            id: 'interactions.statuses_directory'
                        })}
                    >
                        <StatusesTable disabled={isCRUDDisabled} data={allStatuses} fetchData={this.fetchStatuses} />
                    </TabPane>
                    <TabPane
                        key='5'
                        tab={this.props.intl.formatMessage({
                            id: 'interactions.substatuses_directory'
                        })}
                    >
                        <SubStatusesTable
                            disabled={isCRUDDisabled}
                            allStatuses={allStatuses}
                            data={allSubStatuses}
                            fetchData={this.fetchSubStatuses}
                        />
                    </TabPane>
                </Tabs>
            </Layout>
        );
    }
}
