import { Col, Row } from 'antd';
import {
    AnalyticsSelector,
    CashboxSelector,
    DateRangeSelector,
    SupplierCounterpartyContainer
} from 'modals/DebtZeroingModal/components';
import { selectServiceOperationType } from 'modals/DebtZeroingModal/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    serviceOperationType: selectServiceOperationType(state)
});

const mapDispatchToProps = {};

/**
 * This form is used to create ordinary cash order.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class PayablesForm extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={12}>
                            <AnalyticsSelector type='INCOME' />
                        </Col>
                        <Col className={Styles.col} span={12}>
                            <AnalyticsSelector type='EXPENSE' />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={12}>
                            <DateRangeSelector />
                        </Col>
                        <Col className={Styles.col} span={12}>
                            <CashboxSelector />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <SupplierCounterpartyContainer />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
