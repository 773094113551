import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover, Spin, notification } from 'antd';
import { Block, DataItem } from 'commons';
import { Barcode } from 'components';
import { setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { AddAggregateModal, AggregateTransferModal } from 'modals';
import {
    createOrder,
    deleteVehicle,
    fetchVehicle,
    fetchVehicleAttributes,
    fetchVehicleOrdersLatest,
    selectClient,
    selectFetchingOrdersLatest,
    selectFetchingVehicle,
    selectFetchingVehicleAttributes,
    selectFetchingVehicleClient,
    selectGeneralData,
    /* -------Selectors----*/
    selectVehicle,
    selectVehicleAttributes,
    sendEmailWithServiceBookLink,
    sendSmsWithServiceBookLink
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';

import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    vehicle: selectVehicle(state),
    client: selectClient(state),
    generalData: selectGeneralData(state),
    vehicleAttributes: selectVehicleAttributes(state),
    fetchingVehicle: selectFetchingVehicle(state),
    fetchingVehicleClient: selectFetchingVehicleClient(state),
    fetchingOrdersLatest: selectFetchingOrdersLatest(state),
    fetchingVehicleAttributes: selectFetchingVehicleAttributes(state)
});

const mapDispatchToProps = {
    setModal,
    fetchVehicle,
    fetchVehicleAttributes,
    fetchVehicleOrdersLatest,
    sendEmailWithServiceBookLink,
    sendSmsWithServiceBookLink,

    createOrder,
    deleteVehicle
};

/**
 * Supposed to represent general vehicle informations and its specific parameters.
 */
@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AggregateGeneralInfoTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleAddAggregateModal: false,
            clientsAggregates: [],
            loading: false,
            generalInfoTab: true
        };
    }

    componentDidMount() {
        this.getClientsAggregates();
    }

    // componentDidUpdate(prevProps) {
    //     if (!this.state.clientsAggregates.length) {
    //         this.getClientsAggregates();
    //     }
    // }

    getClientsAggregates = async () => {
        const { clientId } = this.props;

        this.setState({
            loading: true
        });

        const aggregates = await fetchAPI('GET', '/clients/aggregates', null, null, {
            handleErrorInternally: true
        });

        this.setState({
            clientsAggregates: aggregates.clientsAggregates,
            loading: false
        });
    };

    onCreateOrder = async id => {
        const { user, clientId } = this.props;

        if (!clientId) {
            notification.error({
                message: this.props.intl.formatMessage({
                    id: 'vehicle_page.add_owner_vehicle'
                })
            });
        } else {
            const orders = await fetchAPI(
                'POST',
                '/orders',
                null,
                {
                    clientAggregateId: id,
                    clientId,
                    managerId: user.id,
                    duration: 0.5,
                    stationLoads: [
                        {
                            beginDatetime: dayjs().startOf('hour').toISOString(),
                            duration: 0.5,
                            status: 'TO_DO'
                        }
                    ],
                    status: 'not_complete',
                    beginDatetime: dayjs().startOf('hour').toISOString(),
                    orderBloke: 'UNIT_REPAIR'
                },
                {
                    handleErrorInternally: true
                }
            );

            if (orders && orders.created) {
                goTo(`${book.order}/${orders.created[0].id}`);
            }
        }
    };

    editArr = row => {
        this.setState({
            visibleAddAggregateModal: true,
            addAggregateModalRow: row
        });
    };

    render() {
        const {
            user: { businessId },

            clientId,
            id,
            disabled,
            intl: { formatMessage },
            user
        } = this.props;

        const { visibleAddAggregateModal, addAggregateModalRow, clientsAggregates, loading } = this.state;

        return (
            <div className={Styles.tabContent}>
                {clientsAggregates && !loading ? (
                    clientsAggregates
                        .filter(({ clientAggregateId }) => clientAggregateId == id)
                        .map(
                            ({
                                aggregateNumber,
                                brandName,
                                brandId,
                                code,
                                comment,
                                count,
                                level,
                                name,
                                clientAggregateId,
                                aggregateId,
                                vin,
                                barcode,
                                clientName,
                                clientSurname,
                                clientId
                            }) => (
                                <div>
                                    <Block
                                        className={Styles.block}
                                        controls={
                                            !disabled && <div>
                                                <Popover
                                                    content={<FormattedMessage id='vehicle_page.hint_barcode_modal' />}
                                                >
                                                    <span style={{ width: '2em' }}>
                                                        <Barcode
                                                            barcodeClassName={Styles.barcodeIcon}
                                                            iconStyle={{
                                                                fontSize: 24,
                                                                marginLeft: 4
                                                            }}
                                                            onConfirm={() => {
                                                                window.location.reload();
                                                            }}
                                                            prefix='CVH'
                                                            provideHint={false}
                                                            referenceId={clientAggregateId}
                                                            table='CLIENTS_AGGREGATES'
                                                            value={barcode}
                                                        />
                                                    </span>
                                                </Popover>

                                                <Popover
                                                    content={
                                                        <FormattedMessage id='add_client_form.aggregate_hint_edit' />
                                                    }
                                                >
                                                    <EditOutlined
                                                        className={Styles.editIcon}
                                                        onClick={() => {
                                                            this.editArr({
                                                                aggregateId,
                                                                clientAggregateId,
                                                                name: name.ua,
                                                                brandId,
                                                                aggregateNumber,
                                                                count,
                                                                comment,
                                                                vin
                                                            });
                                                        }}
                                                    />
                                                </Popover>

                                                <Popover
                                                    content={
                                                        <FormattedMessage id='add_client_form.aggregate_hint_delete' />
                                                    }
                                                >
                                                    <Popconfirm
                                                        onConfirm={async () => {
                                                            try {
                                                                await fetchAPI(
                                                                    'DELETE',
                                                                    `/client/aggregates/${clientAggregateId}`,
                                                                    null,
                                                                    null,
                                                                    {
                                                                        handleErrorInternally: true
                                                                    }
                                                                );
                                                                await goTo(`${book.aggregatesPage}`);
                                                            } catch (error) {
                                                                notification.error({
                                                                    message: this.props.intl.formatMessage({
                                                                        id: 'AGGREGATE_ARE_IN_USE'
                                                                    })
                                                                });
                                                            }
                                                        }}
                                                        title={`${formatMessage({ id: 'delete' })} ?`}
                                                    >
                                                        <DeleteOutlined className={Styles.deleteIcon} />
                                                    </Popconfirm>
                                                </Popover>

                                                {isGrantAccessed(
                                                    user,
                                                    grants.DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE
                                                ) && (
                                                    <Popover
                                                        content={
                                                            <FormattedMessage id='vehicle_page.hint_change_aggregate_owner' />
                                                        }
                                                    >
                                                        {loading ? (
                                                            <Spin />
                                                        ) : (
                                                            <span className={Styles.changeVehicleOwnerIcon}>
                                                                <AggregateTransferModal
                                                                    aggregateId={aggregateId}
                                                                    brandId={brandId}
                                                                    id={id}
                                                                />
                                                            </span>
                                                        )}
                                                    </Popover>
                                                )}

                                                <Popover
                                                    content={
                                                        <FormattedMessage id='vehicle_page.hint_create_order_for_this_vehicle' />
                                                    }
                                                >
                                                    <Button
                                                        className={Styles.iconButton}
                                                        onClick={() => this.onCreateOrder(clientAggregateId)}
                                                        type='primary'
                                                    >
                                                        <PlusOutlined className={Styles.plusIcon} />
                                                    </Button>
                                                </Popover>
                                            </div>
                                        }
                                        title={<FormattedMessage id='cleint_container_aggregate' />}
                                    >
                                        <div>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='add_client_form.aggregate_number' />}
                                            >
                                                {aggregateNumber}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='add_client_form.aggregate_brand' />}
                                            >
                                                {brandName}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='add_client_form.aggregate_code' />}
                                            >
                                                {code}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='add_client_form.aggregate_name' />}
                                            >
                                                {name.ua}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='add_client_form.aggregate_count' />}
                                            >
                                                {count}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='add_order_form.vin' />}
                                            >
                                                {vin}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='add_client_form.aggregate_level' />}
                                            >
                                                {level}
                                            </DataItem>
                                            <DataItem
                                                className={Styles.dataItem}
                                                label={<FormattedMessage id='comment' />}
                                            >
                                                {comment}
                                            </DataItem>
                                        </div>
                                    </Block>
                                    {clientId && (
                                        <Block
                                            className={Styles.block}
                                            // controls={
                                            //     <div>
                                            //         <Popover
                                            //             content={
                                            //                 <FormattedMessage id='vehicle_page.hint_change_vehicle_owner' />
                                            //             }
                                            //         >
                                            //             <span className={Styles.changeVehicleOwnerIcon}>
                                            //                 <ClientVehicleTransfer
                                            //                     clientId={client.clientId}
                                            //                     vehicleId={vehicle.id}
                                            //                     vehicles={[vehicle]}
                                            //                 />
                                            //             </span>
                                            //         </Popover>
                                            //     </div>
                                            // }
                                            loading={loading}
                                            title={<FormattedMessage id='vehicle_page.clients' />}
                                        >
                                            <div>
                                                <DataItem
                                                    className={Styles.dataItem}
                                                    label={<FormattedMessage id='name' />}
                                                >
                                                    <Link to={`${book.client}/${clientId}`}>
                                                        {clientName || ''} {clientSurname || ''}
                                                    </Link>
                                                </DataItem>
                                            </div>
                                        </Block>
                                    )}
                                </div>
                            )
                        )
                ) : (
                    <Spin />
                )}

                <AddAggregateModal
                    addAggregateModalRow={addAggregateModalRow}
                    clientId={clientId}
                    generalInfoTab={this.state.generalInfoTab}
                    getClientsAggregates={this.getClientsAggregates}
                    hideModal={() => {
                        this.setState({
                            visibleAddAggregateModal: false,
                            addAggregateModalRow: undefined
                        });
                    }}
                    visible={visibleAddAggregateModal}
                />
            </div>
        );
    }
}
