/**
 * Available values used to define priority
 */
export const filterValues = {
    NEW: 'NEW',
    HIGH: 'HIGH',
    OVERDUE: 'OVERDUE',
    ALL: 'ALL'
};

export const priorityArrayValues = ['LOW', 'MIDDLE', 'HIGH'];

export const DEF_DATE_FORMAT = 'YYYY-MM-DD';
export const DEF_TIME_FORMAT = 'HH:mm';

/**
 * Available values used to define priority
 */
export const statusValues = {
    NEW: 'NEW',
    INWORK: 'INWORK',
    COMPLETED: 'COMPLETED',
    POSTPONED: 'POSTPONED',
    DELEGATED: 'DELEGATED',
    CANCELED: 'CANCELED'
};
