/* eslint-disable react/sort-comp */
import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, Table } from 'antd';
import _ from 'lodash';
import { WMSCellsModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

@withRouter
@injectIntl
export default class WMSStoragePlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: undefined,
            type: 'CELLS',
            selectedCell: undefined
        };

        const { isDsabledCRUD } = this.props;

        const cell = {
            title: <FormattedMessage id='wms.cell' />,
            key: 'address',
            dataIndex: 'address',
            sorter: (a, b) => String(a.address).localeCompare(String(b.address)),
            render: (data, row) => {
                return (
                    <div
                        onClick={() => {
                            this.props.fetchMovement(data);
                        }}
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                    >
                        {data}
                    </div>
                );
            }
        };

        const code = {
            title: <FormattedMessage id='order_form_table.product_code' />,
            key: 'code',
            dataIndex: 'code',
            sorter: (a, b) => String(a.code).localeCompare(String(b.code)),
            render: (data, row) => {
                return (
                    <div
                        onClick={() => {
                            this.props.fetchMovement(undefined, row.id);
                        }}
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                    >
                        {data}
                    </div>
                );
            }
        };

        const brand = {
            title: <FormattedMessage id='brand' />,
            key: 'brandName',
            dataIndex: 'brandName',
            sorter: (a, b) => String(a.brandName).localeCompare(String(b.brandName))
        };

        const name = {
            title: <FormattedMessage id='order_form_table.detail_name' />,
            key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => String(a.name).localeCompare(String(b.name))
        };

        const count = {
            title: <FormattedMessage id='count' />,
            key: 'sum',
            dataIndex: 'sum',
            sorter: (a, b) => a.sum - b.sum,
            render: data => {
                return Number(data).toFixed(1);
            }
        };

        const fullness = {
            title: <FormattedMessage id='wms.fullness' />,
            key: 'fullness',
            dataIndex: 'fullness',
            sorter: (a, b) => a.fullness - b.fullness
        };

        const action = {
            key: 'action',
            width: '3%',
            render: row => {
                return (
                    <Button
                        disabled={!row.address || isDsabledCRUD}
                        onClick={() => {
                            this.setState({
                                selectedCell: row
                            });
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='wms.transfer' />
                    </Button>
                );
            }
        };

        this.cellsColumns = [cell, code, brand, name, count, fullness, action];

        this.productsColumns = [code, brand, cell, name, count, fullness, action];
    }

    fetchData = async type => {
        const { warehouseId } = this.props;
        const { filterQuery } = this.state;
        const { list, stats } = await fetchAPI('GET', 'wms/cells/statuses', {
            warehouseId,
            orderBy: type === 'PRODUCTS' ? 'STORE_PRODUCT' : undefined,
            count: 999,
            filterQuery
        });
        list.forEach((elem, key) => {
            list[key] = {
                ...elem,
                ...elem.storeProduct,
                ...elem.wmsCellOptions,
                brandName: elem.brand.name,
                key
            };
        });
        this.setState({
            dataSource: list,
            stats,
            type
        });
    };

    componentDidMount() {
        if (this.props.tableFilter) {
            this.setState({
                filterQuery: this.props.tableFilter
            });
        }
        this.fetchData('CELLS');
    }

    render() {
        const { warehouseId } = this.props;
        const { dataSource, type, selectedCell, filterQuery, pageSize, stats, page } = this.state;

        const menu = (
            <Menu>
                {type !== 'CELLS' && (
                    <Menu.Item>
                        <div onClick={() => this.fetchData('CELLS')}>
                            <FormattedMessage id='wms.storage_plan_by_cell' />
                        </div>
                    </Menu.Item>
                )}
                {type !== 'PRODUCTS' && (
                    <Menu.Item>
                        <div onClick={() => this.fetchData('PRODUCTS')}>
                            <FormattedMessage id='wms.storage_plan_by_product' />
                        </div>
                    </Menu.Item>
                )}
            </Menu>
        );

        const pagination = {
            pageSize,
            size: 'small',
            total: _.get(stats, 'count'),
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });

                this.fetchData(type);
            }
        };

        return (
            <div>
                <div className={Styles.tabTitle}>
                    <FormattedMessage
                        id={type === 'CELLS' ? 'wms.storage_plan_by_cell' : 'wms.storage_plan_by_product'}
                    />
                    <Dropdown overlay={menu}>
                        <MenuOutlined className={Styles.menuIcon} />
                    </Dropdown>
                </div>
                <Input.Search
                    allowClear
                    onChange={async ({ target }) => {
                        await this.setState({
                            filterQuery: target.value
                        });
                        this.fetchData(type);
                    }}
                    placeholder={this.props.intl.formatMessage({ id: 'barcode.search' })}
                    style={{
                        marginBottom: 8
                    }}
                    value={filterQuery}
                />
                <Table
                    bordered
                    columns={type === 'CELLS' ? this.cellsColumns : this.productsColumns}
                    dataSource={dataSource || []}
                    loading={!dataSource}
                    pagination={pagination}
                    size='small'
                />
                <WMSCellsModal
                    confirmAction={async (address, modalWarehouseId, count) => {
                        await fetchAPI('POST', 'wms/cells/products', null, {
                            fromWarehouseId: warehouseId,
                            productId: selectedCell.id,
                            fromCell: selectedCell.address,
                            toCell: address,
                            count,
                            toWarehouseId: modalWarehouseId
                        });
                        await this.fetchData(type);
                    }}
                    hideModal={() => {
                        this.setState({
                            selectedCell: undefined
                        });
                    }}
                    selectedCell={selectedCell}
                    visible={Boolean(selectedCell)}
                    warehouseId={warehouseId}
                />
            </div>
        );
    }
}
