// Core
import { Catcher, Spinner } from 'commons';
import { NewPasswordForm } from 'forms';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Styles from '../LoginPage/styles.m.css';

const mapStateToProps = state => ({
    spinner: state.ui.authFetching
});

@connect(mapStateToProps)
export default class NewPasswordPage extends Component {
    render() {
        return !this.props.spinner ? (
            <Catcher>
                <section className={Styles.loginPage}>
                    <NewPasswordForm />
                </section>
            </Catcher>
        ) : (
            <Spinner spin={this.props.spinner} />
        );
    }
}
