import { ArrayScheduleInput } from 'components';
import { onChangeScheduleForm, resetFields } from 'core/forms/scheduleForm/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';

@injectIntl
@withReduxForm2({
    name: 'scheduleForm',
    actions: {
        change: onChangeScheduleForm,
        resetFields
    }
})
export class ScheduleForm extends Component {
    render() {
        const { initialSchedule, form, intl, fields, forbiddenUpdate, loading } = this.props;
        const { updateSchedule, createSchedule, deleteSchedule, resetFields } = this.props;

        return (
            <React.Fragment>
                <ArrayScheduleInput
                    createSchedule={createSchedule}
                    deleteSchedule={deleteSchedule}
                    fields={fields}
                    forbiddenUpdate={forbiddenUpdate}
                    form={form}
                    initialSchedule={initialSchedule}
                    intl={intl}
                    loading={loading}
                    resetFields={resetFields}
                    updateSchedule={updateSchedule}
                />
            </React.Fragment>
        );
    }
}
