import {
    CheckCircleOutlined,
    CheckOutlined,
    FileImageOutlined,
    FileSearchOutlined,
    FilterFilled,
    SearchOutlined,
    VideoCameraAddOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Flex, Select, Space, Spin, Table, Tooltip, Tree, TreeSelect } from 'antd';
import { AvailabilityIndicator } from 'components';
import { loadModal, MODALS, resetModal, saveModal, setModal } from 'core/modals/duck';
import _, { debounce, get } from 'lodash';
import { DetailSupplierModal, DetailWarehousesCountModal, PreviewMediaFilesModal, VehicleModal } from 'modals';
import { formatDetailArray } from 'modals/OrderDetailModal/DetailCatalogueModal';
import { PhotoModal } from 'modals/OrderDetailModal/modals';
import { fetchVehicles, selectVehicles, setSearchQuery } from 'pages/VehiclesPage/redux/duck';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import book from 'routes/book';
import { buildStoreGroupsTree, fetchAPI, fetchTecdocAPI, filterTreeNodeByPart, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import './styles.less';

const { Option } = Select;

const SearchByVehicle = ({
    initialVehicleData,
    openCatalogue,
    setCatalogueData,
    user,
    setModal,
    warehouses,
    location,
    intl: { formatMessage }
}) => {
    const [storeGroups, setStoreGroups] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [storeGroupId, setStoreGroupId] = useState();
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [vehicleId, setVehicleId] = useState();
    const [clientId, setClientId] = useState();
    const [filterBrand, setFilterBrand] = useState();
    const [vehicleSearchQuery, setVehicleSearchQuery] = useState();
    const [warehouseId, setWarehouseId] = useState();
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAvailable, setIsAvailable] = useState(true);
    const [options, setOptions] = useState([]);
    const [listBrands, setListBrands] = useState([]);
    const [mediaFilesRow, setMediaFilesRow] = useState(false);
    const [photoModalRow, setPhotoModalRow] = useState(false);
    const [warehousesModalSelectedRow, setWarehousesModalSelectedRow] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [pagination, setPagination] = useState(() => ({
        pageSize: 15,
        total: 0,
        current: 1,
        onChange: (page, pageSize) => {
            setPagination(prev => ({ ...prev, current: page, pageSize }));
        }
    }));
    const [treeProps, setTreeProps] = useState({
        autoExpandParent: true,
        expandedKeys: []
    });
    const fetchRef = useRef(0);

    useEffect(() => {
        const fetchStoreGrouops = async () => {
            const storeGroups = await fetchAPI('GET', 'store_groups');
            setStoreGroups(buildStoreGroupsTree(storeGroups));
        };
        fetchStoreGrouops();
    }, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: !record.code,
            style: !record.code ? { display: 'none' } : {}
        })
    };

    const debounceFetcher = useMemo(() => {
        const loadOptions = query => {
            if (query.length < 3) return;
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setVehicleSearchQuery(query);
            setFetching(true);
            fetchAPI('GET', '/vehicles', { filters: { query }, sort: { pageSize: 15 } }).then(({ clientsVehicles }) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setVehicles(clientsVehicles);
                setOptions(
                    clientsVehicles.map(vehicle => ({
                        value: vehicle.clientVehicleId,
                        label: `${vehicle.make || '-'}, ${vehicle.model || '-'}${vehicle.modification ? ',' : ''} ${
                            vehicle.modification || ''
                        } ${vehicle.year || '-'} (${vehicle.number || '-'} ${vehicle.vin || ''})`
                    }))
                );
                setFetching(false);
            });
        };

        return debounce(loadOptions, 300);
    }, []);

    const getDataSource = products => {
        if (!_.isArray(products)) {
            return [];
        }

        return products.map(item => {
            const elem = { ...item };
            if (elem.businessSupplierId !== 0 || elem.brandId === 0) {
                delete elem.productId;
            }
            elem.attributes = elem.attributes || [];

            if (elem.defaultWarehouseName) {
                elem.productId = elem.id;
            }

            if (elem.price) {
                if (elem.price.businessSupplierId === 0) {
                    elem.productId = elem.price.id;
                }
                elem.store = elem.price.store;
                elem.purchasePrice = elem.price.purchasePrice;
                elem.businessSupplierId = elem.price.businessSupplierId;
                elem.businessSupplierName = elem.price.businessSupplierName;
                elem.salePrice =
                    elem.sellingPrice || elem.price.purchasePrice * (elem.price.markup ? elem.price.markup : 1.4);
                elem.supplierOriginalCode = elem.price.supplierOriginalCode;
                elem.supplierPartNumber = elem.price.supplierPartNumber;
            }

            return elem;
        });
    };

    const fetchByModificationId = useCallback(async () => {
        setDataSource([]);
        setLoading(true);
        try {
            const bodyId = get(selectedVehicle, 'bodyType', get(selectedVehicle, 'bodyId', 53));
            const modificationId = get(selectedVehicle, 'tecdocModifId');
            if (!modificationId) {
                const { list, stats, listBrands } = await fetchAPI(
                    'GET',
                    'store_products',
                    {
                        page: pagination.current,
                        pageSize: pagination.pageSize - 1,
                        onlyAvailable: isAvailable,
                        warehouseId,
                        groupId: storeGroupId || 1000000,
                        filterBrand,
                        filterByGroupChild: true
                        // filterWarehouse: this.getDetailWarehouses()
                    },
                    null,
                    {
                        handleErrorInternally: true
                    }
                );
                const dataSource = getDataSource(list);
                setListBrands(listBrands);
                setDataSource(dataSource);
                // pagination.current !== 1 && setPagination(prev => ({ ...prev, current: 1 }));
                setPagination(prev => ({ ...prev, total: get(stats, 'count', 0) }));
                setLoading(false);

                return;
            }
            const { result, stats, brandsList } = await fetchTecdocAPI(
                'GET',
                '/tecdoc/products/parts',
                {
                    modificationId,
                    bodyId,
                    isAvailable,
                    filterBrand,
                    warehouseId,
                    storeGroupId: storeGroupId || 1000000,
                    pageSize: pagination.pageSize,
                    page: pagination.current,
                    filterByGroupChild: Boolean(storeGroupId) && Number(String(storeGroupId)[2]) > 0
                },
                null,
                { handleErrorInternally: true }
            );

            let productPriceIndex = 0; // Use to retrieve correct selling price
            result.forEach(product => {
                product.productPriceIndex = productPriceIndex++;
            });

            // Assign selling prices
            const sellingPrices = await fetchAPI(
                'POST',
                '/store_products/selling_prices',
                null,
                {
                    clientId,
                    products: result.map(product => ({
                        index: product.productPriceIndex,
                        productId: product.storeProductId || null,
                        code: product.code,
                        storeGroupId: product.storeGroupId || null,
                        businessSupplierId: product.businessSupplierId || null,
                        purchasePrice: product.purchasePrice || null
                    }))
                },
                { handleErrorInternally: true }
            );

            result.forEach(product => {
                product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
            });

            const dataSource = formatDetailArray(result);
            setDataSource(dataSource);
            setLoading(false);
            setListBrands(brandsList || []);
            // pagination.current !== 1 && setPagination(prev => ({ ...prev, current: 1 }));
            setPagination(prev => ({ ...prev, total: get(stats, 'count', 0) }));
        } catch (e) {
            setLoading(false);
        }
    }, [filterBrand, isAvailable, pagination.current, pagination.pageSize, selectedVehicle, storeGroupId, warehouseId]);

    useEffect(() => {
        fetchByModificationId();
    }, [clientId, fetchByModificationId]);

    const handleSearch = confirm => {
        confirm();
        setPagination(prev => ({ ...prev, page: 1 }));
        fetchByModificationId();
    };

    const columns = useMemo(
        () => [
            {
                key: 'actions',
                align: 'center',
                title: () => (
                    <Space.Compact>
                        <Button
                            disabled={!selectedRows.length}
                            onClick={() => {
                                setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                    details: selectedRows.map(detail => ({
                                        storeGroupId: detail.groupId,
                                        name: detail.name,
                                        productId: detail.id,
                                        productCode: detail.code || null,
                                        purchasePrice: detail.purchasePrice || 0,
                                        count: 1,
                                        price: detail.sellingPrice || 0,
                                        supplierBrandId: detail.brandId,
                                        partUnitId: detail.productUnitId,
                                        warehouseId: detail.defaultWarehouseId
                                    })),
                                    mode: 'ADD_DETAIL'
                                });
                            }}
                        >
                            <FormattedMessage id='order_shr' />
                        </Button>
                        <Button
                            // disabled={!(selectedRows.length && selectedSearchMode === STORAGE)}
                            disabled={!selectedRows.length}
                            onClick={() => {
                                setModal(MODALS.ADD_PRODUCT_TO_STORE_DOC, {
                                    products: selectedRows.map(elem => ({
                                        productId: elem.productId,
                                        quantity: 1,
                                        stockPrice: elem.stockPrice || 0,
                                        sellingPrice: elem.sellingPrice,
                                        addToAddress: null,
                                        getFromAddress: null,
                                        uktz: elem.tradeCode,
                                        docProductUnitId: elem.productUnitId || 1
                                    }))
                                });
                            }}
                        >
                            <FormattedMessage id='store_srt' />
                        </Button>
                        <Button
                            disabled={!selectedRows.length}
                            onClick={async () => {
                                const res = await fetchAPI('POST', '/retails', undefined, undefined, {
                                    handleErrorInternally: true
                                });

                                if (res && res.id) {
                                    const payload = {
                                        insertMode: true,
                                        details: selectedRows.map(detail => ({
                                            storeGroupId: detail.groupId,
                                            name: detail.name,
                                            productId: detail.id,
                                            productCode: detail.code || null,
                                            purchasePrice: detail.purchasePrice || 0,
                                            count: 1,
                                            price: detail.sellingPrice || 0,
                                            supplierBrandId: detail.brandId,
                                            partUnitId: detail.productUnitId,
                                            warehouseId: detail.defaultWarehouseId
                                        })),
                                        services: []
                                    };

                                    await fetchAPI('PUT', `retail/${res.id}`, null, payload, {
                                        handleErrorInternally: true
                                    });
                                    window.open(`${book.rtl}/${res.id}`, '_blank');
                                }
                            }}
                        >
                            RTL+
                        </Button>
                    </Space.Compact>
                ),
                render: row => {
                    const { partNumber, brandId, code, emptyRow } = row;

                    return (
                        !emptyRow && (
                            <div className='actions'>
                                <Tooltip title={<FormattedMessage id='settings_page.media' />} zIndex={2001}>
                                    <Button
                                        icon={<VideoCameraAddOutlined />}
                                        onClick={() => {
                                            setMediaFilesRow(row);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={<FormattedMessage id='order_form_table.open_photo_tooltip' />}
                                    zIndex={2001}
                                >
                                    <Button
                                        data-qa='button_open_photo_modal_order_detail_modal'
                                        // disabled={!get(row, 'hasAttributes') && !get(row, 'hasImages')}
                                        icon={<FileImageOutlined />}
                                        onClick={() => {
                                            setPhotoModalRow(row);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={<FormattedMessage id='order_form_table.add_to_order_tooltip' />}
                                    zIndex={2001}
                                >
                                    <Button
                                        data-qa='button_handle_ok_select_order_detail_modal'
                                        disabled={loading}
                                        icon={<CheckOutlined />}
                                        onClick={() => {
                                            setLoading(true);
                                            setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                                details: [
                                                    {
                                                        storeGroupId: row.groupId,
                                                        name: row.name,
                                                        productId: row.id,
                                                        productCode: row.code || null,
                                                        purchasePrice: row.purchasePrice || 0,
                                                        count: 1,
                                                        price: row.sellingPrice || 0,
                                                        supplierBrandId: row.brandId,
                                                        partUnitId: row.productUnitId,
                                                        warehouseId: row.defaultWarehouseId
                                                    }
                                                ],
                                                mode: 'ADD_DETAIL'
                                            });

                                            setLoading(false);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id='order_form_table.apply_btn' />} zIndex={2001}>
                                    <Button
                                        data-qa='btn_fetch_storage_oils_order_detail_modal'
                                        disabled={!partNumber && !code}
                                        icon={<SearchOutlined />}
                                        onClick={() => {
                                            setCatalogueData({
                                                brandId,
                                                code: partNumber || code
                                            });
                                            openCatalogue();
                                        }}
                                    />
                                </Tooltip>
                                {/* <Tooltip title={<FormattedMessage id='order_form_table.apply_btn' />} zIndex={2001}>
                                <Button
                                    data-qa='btn_fetch_storage_oils_order_detail_modal'
                                    disabled={!code}
                                    icon={<SearchOutlined />}
                                    onClick={async () => {
                                        await this.setState({
                                            code,
                                            brandId: row.brandId,
                                            page: 1
                                        });
                                        setTimeout(() => this.fetchFinalCrosses(), 0);
                                    }}
                                />
                            </Tooltip> */}
                            </div>
                        )
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'code',
                width: '15%',
                render: row => {
                    const code = row.code || row.partNumber;

                    const name = row.name && get(row.name, 'length') > 55 ? `${row.name.slice(0, 55)}...` : row.name;
                    const description = row.description > 55 ? `${row.description.slice(0, 55)}...` : row.description;

                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700,
                                    textDecoration: code && 'underline',
                                    cursor: 'pointer'
                                }}
                            >
                                {row.code ? (
                                    <Link target='_blank' to={`${book.product}/${row.id}`}>
                                        {code}
                                    </Link>
                                ) : (
                                    code
                                )}
                            </div>
                            <Tooltip title={row.name || row.description}>
                                <div style={{ fontSize: 12 }}>{name || description}</div>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brand',
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Select
                            allowClear
                            onChange={value => {
                                setFilterBrand(value);
                            }}
                            optionFilterProp='children'
                            // mode='multiple'
                            placeholder={formatMessage({
                                id: 'search'
                            })}
                            showSearch
                            style={{ marginBottom: 8, display: 'block', width: 180 }}
                            value={filterBrand}
                        >
                            {listBrands.map(({ brandId, brandName }) => (
                                <Option key={brandId} value={brandId}>
                                    {brandName}
                                </Option>
                            ))}
                        </Select>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => handleSearch(confirm)}
                                size='small'
                                type='secondary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => setFilterBrand(null)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    </div>
                ),
                filterIcon: () => (
                    <FilterFilled
                        style={{
                            fontSize: 14,
                            color: filterBrand ? 'var(--primary)' : undefined
                        }}
                    />
                ),
                // onFilterDropdownVisibleChange: visible => {
                //     if (visible) {
                //         setTimeout(() => this.searchInput.select(), 100);
                //     }
                // },
                render: row => {
                    const code = row.code || row.partNumber;

                    return (
                        <div>
                            <Tooltip
                                title={<FormattedMessage id='order_form_table.find_in_catalog_tooltip' />}
                                zIndex={2001}
                            >
                                <div
                                    onClick={() => {
                                        setCatalogueData({
                                            brandId: row.brandId,
                                            code: row.partNumber || row.code
                                        });
                                        openCatalogue();
                                    }}
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: code && 'underline',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {row.brandName}
                                </div>
                            </Tooltip>
                        </div>
                    );
                }
            },

            {
                title: <FormattedMessage id='order_form_table.store_supplier' />,
                key: 'businessSupplierName',
                align: 'center',
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Select
                            allowClear
                            onChange={value => {
                                setWarehouseId(value);
                            }}
                            optionFilterProp='children'
                            // mode='multiple'
                            placeholder={formatMessage({
                                id: 'search'
                            })}
                            showSearch
                            style={{ marginBottom: 8, display: 'block', width: 180 }}
                            value={warehouseId}
                        >
                            {warehouses
                                .filter(({ attribute }) => attribute !== 'RESERVE' && attribute !== 'STORAGE')
                                .map(({ name, id }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                        </Select>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => handleSearch(confirm)}
                                size='small'
                                type='secondary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => setWarehouseId(null)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    </div>
                ),
                filterIcon: () => (
                    <FilterFilled
                        style={{
                            fontSize: 14,
                            color: warehouseId ? 'var(--primary)' : undefined
                        }}
                    />
                ),
                render: (row, _, key) => {
                    if (row.emptyRow) return <React.Fragment></React.Fragment>;

                    return (
                        <div>
                            <Tooltip
                                title={<FormattedMessage id='order_form_table.availability_store' />}
                                zIndex={2001}
                            >
                                <span
                                    onClick={() => {
                                        if (
                                            (row.businessSupplierId === 0 &&
                                                isGrantAccessed(
                                                    user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                                )) ||
                                            isGrantAccessed(
                                                user,
                                                grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                            )
                                        ) {
                                            setWarehousesModalSelectedRow(row);
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {row.supplierId ? (
                                        <FormattedMessage id='long_dash' />
                                    ) : (
                                        row.businessSupplierName || row.defaultWarehouseName
                                    )}
                                </span>
                            </Tooltip>
                            {' / '}
                            {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS, accesses.ROWO) ? (
                                <DetailSupplierModal
                                    brandId={row.brandId}
                                    clientId={clientId}
                                    detailCode={row.code || row.partNumber}
                                    detailSearchSelectButtons
                                    disabled={!row.storeGroupId || !row.code || !row.brandId}
                                    hideButton
                                    orderSelectFunc={el => {
                                        setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                                            details: [
                                                {
                                                    storeGroupId: row.groupId,
                                                    name: row.name,
                                                    productId: row.id,
                                                    productCode: row.code || null,
                                                    purchasePrice: el.purchasePrice || row.purchasePrice || 0,
                                                    count: 1,
                                                    price: el.sellingPrice || row.sellingPrice || 0,
                                                    supplierBrandId: row.brandId,
                                                    partUnitId: row.productUnitId,
                                                    warehouseId: row.defaultWarehouseId,
                                                    supplierId: el.businessSupplierId,
                                                    supplierOriginalCode: el.supplierOriginalCode,
                                                    supplierProductNumber: el.supplierProductNumber,
                                                    supplierPartNumber: el.supplierPartNumber
                                                }
                                            ],
                                            mode: 'ADD_DETAIL'
                                        });
                                    }}
                                    rtlCreateFunc={async el => {
                                        const res = await fetchAPI('POST', '/retails', undefined, undefined, {
                                            handleErrorInternally: true
                                        });

                                        if (res && res.id) {
                                            const payload = {
                                                insertMode: true,
                                                details: [
                                                    {
                                                        storeGroupId: row.groupId,
                                                        name: row.name,
                                                        productId: row.id,
                                                        productCode: row.code || null,
                                                        purchasePrice: el.purchasePrice || row.purchasePrice || 0,
                                                        count: 1,
                                                        price: el.sellingPrice || row.sellingPrice || 0,
                                                        supplierBrandId: row.brandId,
                                                        partUnitId: row.productUnitId,
                                                        warehouseId: row.defaultWarehouseId,
                                                        supplierId: el.businessSupplierId,
                                                        supplierOriginalCode: el.supplierOriginalCode,
                                                        supplierProductNumber: el.supplierProductNumber,
                                                        supplierPartNumber: el.supplierPartNumber
                                                    }
                                                ],
                                                services: []
                                            };

                                            await fetchAPI('PUT', `retail/${res.id}`, null, payload, {
                                                handleErrorInternally: true
                                            });
                                            window.open(`${book.rtl}/${res.id}`, '_blank');
                                        }
                                    }}
                                    storeDocSelectFunc={el => {
                                        setModal(MODALS.ADD_PRODUCT_TO_STORE_DOC, {
                                            products: [
                                                {
                                                    productId: row.productId,
                                                    quantity: 1,
                                                    stockPrice: el.sellingPrice || row.stockPrice || 0,
                                                    sellingPrice: el.sellingPrice || row.sellingPrice,
                                                    addToAddress: null,
                                                    getFromAddress: null,
                                                    uktz: row.tradeCode,
                                                    docProductUnitId: row.productUnitId || 1
                                                }
                                            ]
                                        });
                                    }}
                                    storeGroupId={row.storeGroupId}
                                    tableKey={key}
                                    user={user}
                                >
                                    {row.supplierId ? row.businessSupplierName : <FormattedMessage id='long_dash' />}
                                </DetailSupplierModal>
                            ) : row.supplierId ? (
                                row.businessSupplierName
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='navigation.availability' />,
                key: 'store',
                align: 'center',
                render: ({ id, store, available, reservedCount, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    return id ? (
                        `${Number(available + reservedCount).toFixed(2)} / ${Number(available).toFixed(2)}`
                    ) : (
                        <AvailabilityIndicator indexArray={store} />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='services_table.units' />
                    </div>
                ),
                key: 'unit',
                align: 'right',
                render: (row, unit) => {
                    if (row.emptyRow) {
                        return <p></p>;
                    }

                    return <span style={{ whiteSpace: 'nowrap' }}>{row.unit || <FormattedMessage id='pc' />}</span>;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                align: 'right',
                render: ({ purchasePrice, stockPrice, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    const price = purchasePrice || stockPrice;
                    const strVal = ((price * 10) / 10).toFixed(2);

                    return price ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'salePrice',
                align: 'right',
                render: ({ salePrice, sellingPrice, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    const price = salePrice || sellingPrice;
                    const strVal = ((price * 10) / 10).toFixed(2);

                    return price ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            }
        ],
        [
            clientId,
            filterBrand,
            formatMessage,
            handleSearch,
            listBrands,
            openCatalogue,
            setCatalogueData,
            setModal,
            user
        ]
    );

    useEffect(() => {
        const vehicle = vehicles.find(({ clientVehicleId }) => clientVehicleId === get(vehicleId, 'value', vehicleId));
        setSelectedVehicle(vehicle);
        setClientId(get(vehicle, 'client.clientId'));
        setCatalogueData({ vin: get(vehicle, 'vin') });
    }, [vehicleId, vehicles]);

    useEffect(() => {
        if (initialVehicleData) {
            const {
                tecdocData: { modificationId, bodyId },
                makeModel
            } = initialVehicleData;
            setVehicles([
                {
                    tecdocModifId: modificationId,
                    clientVehicleId: modificationId,
                    modelName: makeModel,
                    modificationId,
                    bodyId
                }
            ]);
            setOptions([
                {
                    label: makeModel,
                    value: modificationId
                }
            ]);
            setVehicleId(modificationId);
        }
    }, [initialVehicleData]);

    const onExpand = expandedKeys => {
        setTreeProps(props => ({
            ...props,
            expandedKeys,
            autoExpandParent: false
        }));
    };

    const saveSearchHistoryVin = async (makeModel, vin, tecdocData) => {
        const VinValidation = /^[A-Za-z0-9]+$/;
        await fetchAPI(
            'POST',
            '/search_history/vin',
            undefined,
            {
                makeModel,
                vin: get(vin, 'length') === 17 && VinValidation.test(vin) ? vin : null,
                tecdocData
                // date: dayjs().format('YYYY-MM-DD'),
                // time: dayjs().format('HH:mm')
            },
            { handleErrorInternally: true }
        );
    };

    const handleAddVehicle = () => {
        setModal(MODALS.VEHICLE, {
            mode: 'SEARCH',
            onSubmit: ({ vehicle }) => {
                const { modificationId, modelName, bodyId, vin } = vehicle;
                setVehicles([
                    {
                        tecdocModifId: modificationId,
                        clientVehicleId: modificationId,
                        ...vehicle
                    }
                ]);
                setOptions([
                    {
                        label: modelName,
                        value: modificationId
                    }
                ]);
                setVehicleId(modificationId);
                saveSearchHistoryVin(modelName, vin, { bodyId, modificationId });
            }
        });
    };

    return (
        <Flex className='searchByDetailsTab' gap={16}>
            <Flex gap={8} style={{ width: '25%' }} vertical>
                <TreeSelect
                    allowClear
                    filterTreeNode={filterTreeNodeByPart}
                    onChange={storeGroupId => {
                        setPagination(prev => ({ ...prev, current: 1 }));
                        setStoreGroupId(storeGroupId);
                        setTreeProps(props => ({
                            ...props,
                            autoExpandParent: true,
                            expandedKeys: [String(storeGroupId)]
                        }));
                    }}
                    placeholder={formatMessage({ id: 'services_table.store_group' })}
                    showSearch
                    treeData={storeGroups}
                    value={storeGroupId}
                />
                <Tree
                    onExpand={onExpand}
                    onSelect={async selectedKeys => {
                        const elementGroupId = selectedKeys.length ? Number(selectedKeys[0]) : storeGroupId;
                        setPagination(prev => ({ ...prev, current: 1 }));

                        setStoreGroupId(elementGroupId);
                    }}
                    selectedKeys={[String(storeGroupId)]}
                    showLine
                    treeData={storeGroups}
                    {...treeProps}
                />
            </Flex>
            <Flex gap={8} style={{ width: '75%' }} vertical>
                <Space block className='searchByDetailsTab_vehicleBlock' size='middle'>
                    <Space.Compact
                        block
                        style={{
                            gap: 2
                        }}
                    >
                        <Select
                            filterOption={false}
                            labelInValue
                            notFoundContent={fetching ? <Spin size='small' /> : null}
                            onChange={val => {
                                setPagination(prev => ({ ...prev, current: 1 }));
                                setVehicleId(val);
                            }}
                            onSearch={debounceFetcher}
                            options={options}
                            placeholder={formatMessage({ id: 'order_form_table_search_by_car' })}
                            showSearch
                            style={{ width: '100%' }}
                            value={vehicleId}
                        />
                        <Tooltip title={<FormattedMessage id='add' />}>
                            <Button icon={<CheckCircleOutlined />} onClick={handleAddVehicle} />
                        </Tooltip>
                    </Space.Compact>
                    <Checkbox
                        checked={isAvailable}
                        onChange={() => {
                            setIsAvailable(!isAvailable);
                            setPagination(prev => ({ ...prev, page: 1 }));
                        }}
                    >
                        <FormattedMessage id='order_form_table.in_stock' />
                    </Checkbox>
                    <Tooltip title={<FormattedMessage id='search_history' />}>
                        <Button
                            icon={<FileSearchOutlined style={{ fontSize: 18 }} />}
                            onClick={() => goTo(book.historyTrackingPage, { activeTab: '3' })}
                        />
                    </Tooltip>
                </Space>
                <Table
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={pagination}
                    rowKey='id'
                    rowSelection={rowSelection}
                    size='small'
                />
            </Flex>

            <PhotoModal
                data={photoModalRow}
                hideModal={() => setPhotoModalRow(false)}
                visible={Boolean(photoModalRow)}
            />
            <PreviewMediaFilesModal
                hideModal={() => setMediaFilesRow(false)}
                open={Boolean(mediaFilesRow)}
                prdId={mediaFilesRow ? mediaFilesRow.id || mediaFilesRow.storeProductId : mediaFilesRow}
            />
            <DetailWarehousesCountModal
                count={get(warehousesModalSelectedRow, 'count')}
                detailSearchSelectButtons
                hideButton
                hideModal={() => {
                    setWarehousesModalSelectedRow(undefined);
                }}
                orderSelectFunc={el => {
                    const row = warehousesModalSelectedRow;
                    setModal(MODALS.ADD_LABOR_OR_DETAIL_TO_ORDER, {
                        details: [
                            {
                                storeGroupId: row.groupId,
                                name: row.name,
                                productId: row.id,
                                productCode: row.code || null,
                                purchasePrice: el.purchasePrice || row.purchasePrice || 0,
                                count: 1,
                                price: el.price || row.sellingPrice || 0,
                                supplierBrandId: row.brandId,
                                partUnitId: row.productUnitId,
                                warehouseId: el.warehouseId,
                                cellAddress: el.cellAddress,
                                supplierId: 0
                            }
                        ],
                        mode: 'ADD_DETAIL'
                    });
                }}
                productId={_.get(warehousesModalSelectedRow, 'productId')}
                storeDocSelectFunc={el => {
                    const row = warehousesModalSelectedRow;
                    setModal(MODALS.ADD_PRODUCT_TO_STORE_DOC, {
                        products: [
                            {
                                productId: row.productId,
                                quantity: 1,
                                stockPrice: el.purchasePrice || row.stockPrice || 0,
                                sellingPrice: el.price || row.sellingPrice,
                                addToAddress: el.cellAddress,
                                getFromAddress: null,
                                uktz: row.tradeCode,
                                docProductUnitId: row.productUnitId || 1,
                                supplierId: 0
                            }
                        ]
                    });
                }}
                // onSelect={(address, warehouseId) => {
                //     if (this.state.activeKey === 'storageOrders') {
                //         updateSparePartsWorkplaceData([
                //             {
                //                 id: warehousesModalSelectedRow.id,
                //                 cellAddress: address,
                //                 warehouseId,
                //                 supplierId: 0
                //             }
                //         ]);
                //     } else {
                //         this.updateOrdersSurpluses([
                //             {
                //                 id: warehousesModalSelectedRow.id,
                //                 cellAddress: address,
                //                 warehouseId,
                //                 supplierId: 0
                //             }
                //         ]);
                //     }
                // }}
                productUnit={_.get(warehousesModalSelectedRow, 'measureUnit')}
                rtlCreateFunc={async el => {
                    const row = warehousesModalSelectedRow;
                    const res = await fetchAPI('POST', '/retails', undefined, undefined, {
                        handleErrorInternally: true
                    });

                    if (res && res.id) {
                        const payload = {
                            insertMode: true,
                            details: [
                                {
                                    storeGroupId: row.groupId,
                                    name: row.name,
                                    productId: row.id,
                                    productCode: row.code || null,
                                    purchasePrice: el.purchasePrice || row.purchasePrice || 0,
                                    count: 1,
                                    price: el.price || row.sellingPrice || 0,
                                    supplierBrandId: row.brandId,
                                    partUnitId: row.productUnitId,
                                    warehouseId: el.warehouseId,
                                    cellAddress: el.cellAddress,
                                    supplierId: 0
                                }
                            ],
                            services: []
                        };

                        await fetchAPI('PUT', `retail/${res.id}`, null, payload, {
                            handleErrorInternally: true
                        });
                        window.open(`${book.rtl}/${res.id}`, '_blank');
                    }
                }}
                visible={Boolean(warehousesModalSelectedRow)}
            />

            <VehicleModal />
        </Flex>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    isMobile: state.ui.views.isMobile,
    modal: state.modals.modal,
    vehicles: selectVehicles(state)
});

const mapDispatchToProps = {
    setModal,
    saveModal,
    loadModal,
    resetModal,
    setSearchQuery,
    fetchVehicles
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchByVehicle));
