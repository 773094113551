import { Tabs } from 'antd';
import { ResponsiveView } from 'commons';
import { CashActivityTable, CashBalanceTable } from 'components';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { BREAKPOINTS } from 'utils';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

export default class CashAccountingContainer extends Component {
    render() {
        return (
            <div className={Styles.tables}>
                <ResponsiveView view={{ min: null, max: BREAKPOINTS.xl.max }}>
                    <Tabs defaultActiveKey='1'>
                        <TabPane key='1' tab={<FormattedMessage id='cash-table.leftovers' />}>
                            <CashBalanceTable />
                        </TabPane>
                        <TabPane key='2' tab={<FormattedMessage id='cash-table.trace' />}>
                            <CashActivityTable />
                        </TabPane>
                    </Tabs>
                </ResponsiveView>
                <ResponsiveView view={{ min: BREAKPOINTS.xxl.min, max: null }}>
                    <CashBalanceTable />
                    <CashActivityTable />
                </ResponsiveView>
            </div>
        );
    }
}
