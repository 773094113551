import { MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { images } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

@withRouter
@connect(mapStateToProps)
export default class ModuleHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showControls: false
        };
    }

    render() {
        const { user, title, description, controls, collapsed, isMobile, onCollapse, menuControls } = this.props;

        const { showControls } = this.state;

        return isMobile ? (
            <div className={Styles.titleMobile}>
                <div
                    className={Styles.carBooktitleBlock}
                    onClick={() => {
                        onCollapse(!collapsed);
                    }}
                >
                    <MenuUnfoldOutlined style={{ color: 'black' }} />
                    <img alt='logo' className={Styles.logo} src={user.primaryLogoUrl || images.carbookLogo2} />
                </div>
                <div className={Styles.titleMobileTitleBlock}>
                    <div className={Styles.titleInfo}>
                        <h1 className={Styles.title}>{title}</h1>
                        {description && <span className={Styles.description}>{description}</span>}
                    </div>
                    {!menuControls && <div className={Styles.titleContorls}>{controls}</div>}
                    {menuControls && (
                        <div className={Styles.titleContorlsShowIcon}>
                            <MenuOutlined
                                onClick={() =>
                                    this.setState({
                                        showControls: !showControls
                                    })
                                }
                            />
                        </div>
                    )}
                </div>
                {menuControls && (
                    <div
                        className={Styles.hiddentitleContorls}
                        style={{
                            display: showControls ? 'flex' : 'none'
                        }}
                    >
                        {controls}
                    </div>
                )}
            </div>
        ) : (
            <div className={`${Styles.title} ${collapsed && Styles.titleCollapsed}`}>
                <div className={Styles.titleInfo}>
                    <h1 className={Styles.title}>{title}</h1>
                    {description && <span className={Styles.descriptionDesk}>{description}</span>}
                </div>
                <div className={Styles.titleContorls}>{controls}</div>
            </div>
        );
    }
}
