import { Checkbox, DatePicker, Input, Modal, Select } from 'antd';
import { DateRangePicker } from 'components';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { posibleDocCodes } from 'pages/Storage/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    brands: selectBrands(state),
    clients: state.clients.clients,
    warehouses: state.warehouses.warehouses,
    user: state.auth
});

const mapDispatchToProps = {
    resetModal,
    fetchBrands,
    fetchWarehouses,
    fetchClients,
    setClientsSearchFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class UniversalProductReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: dayjs(),
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            suppliers: []
        };

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            this.props.fetchWarehouses();
            this.fetchSuppliers();
            this.props.fetchClients();
            this.props.fetchBrands();
        }
    }

    handleOk = async () => {
        const { resetModal, modalProps } = this.props;

        const {
            warehouseId,
            fromDate,
            toDate,
            supplierId,
            operationCodes,
            clientId,
            excludeOperationCodes,
            brandId,
            detailCode
        } = this.state;

        const response = await fetchAPI(
            'GET',
            'report/excel/balance/storeDocs',
            {
                warehouseId,
                businessSupplierId: supplierId,
                fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                toDate: dayjs(toDate).format('YYYY-MM-DD'),
                operationCodes: operationCodes ? [`${operationCodes}`] : null,
                clientId,
                excludeOperationCodes: excludeOperationCodes ? '[]' : '["RES"]',
                brandId,
                detailCode
            },
            null,
            { rawResponse: true }
        );
        const reportFile = await response.blob();
        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
        resetModal();
    };

    handleSearchSuppliers = _.debounce(value => {
        this.fetchSuppliers(value, true);
    }, 1000);

    fetchSuppliers = async (query, all = true) => {
        const suppliers = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                all,
                cut: true,
                query
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            suppliers
        });
    };

    render() {
        const { resetModal, warehouses, visible, fetchClients, clients, setClientsSearchFilter, brands } = this.props;

        const {
            warehouseId,
            fromDate,
            toDate,
            supplierId,
            operationCodes,
            clientId,
            excludeOperationCodes,

            brandId,
            detailCode,
            suppliers
        } = this.state;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    maskClosable={false}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={() => resetModal()}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='universal_report.by_warehouses' />}
                    visible={visible === MODALS.UNIVERSAL_PRODUCT_REPORT_MODAL}
                    width='fit-content'
                >
                    <Select
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={warehouseId => {
                            this.setState({ warehouseId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({ id: 'storage' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={warehouseId}
                    >
                        {warehouses.map(({ name, id }) => (
                            <Option key={id} id={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        dropdownStyle={{ zIndex: '9999' }}
                        onChange={operationCodes => {
                            this.setState({ operationCodes });
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'storage_document.document_type'
                        })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={operationCodes}
                    >
                        {posibleDocCodes.map(code => (
                            <Option key={code} value={code}>
                                {code} - <FormattedMessage id={`storage_document.docType.${code}`} />
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={brandId => {
                            this.setState({ brandId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.brand'
                        })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={brandId}
                    >
                        {_.uniqBy(brands, 'brandName').map(({ brandId, brandName }) => (
                            <Option key={brandId} value={brandId}>
                                {brandName}
                            </Option>
                        ))}
                    </Select>
                    <Input
                        onChange={({ target: { value } }) => {
                            this.setState({
                                detailCode: value
                            });
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.detail_code'
                        })}
                        style={{
                            marginBottom: 8
                        }}
                        value={detailCode}
                    />
                    <Select
                        allowClear
                        dropdownStyle={{ zIndex: '9999' }}
                        filterOption={false}
                        onChange={clientId => {
                            this.setState({ clientId });
                        }}
                        onSearch={this.handleSearchClients}
                        placeholder={this.props.intl.formatMessage({ id: 'add_order_form.client' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={clientId}
                    >
                        {clients.map(client => (
                            <Option key={client.clientId} phone={_.get(client, 'phones[0]')} value={client.clientId}>
                                {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                    client,
                                    'phones[0]',
                                    ''
                                )}`}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={supplierId => {
                            this.setState({ supplierId });
                        }}
                        onSearch={input => this.handleSearchSuppliers(input)}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'remains_report.suppliers'
                        })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={supplierId}
                    >
                        {suppliers.map(({ name, id }) => (
                            <Option key={id} id={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 8
                        }}
                    >
                        <FormattedMessage id='reports_page.show_RES' />
                        <Checkbox
                            checked={excludeOperationCodes}
                            onChange={e => {
                                this.setState({
                                    excludeOperationCodes: !excludeOperationCodes
                                });
                            }}
                        />
                    </div>

                    <DateRangePicker
                        dateRange={[fromDate, toDate]}
                        onDateChange={arr => {
                            this.setState({ fromDate: arr[0] });
                            this.setState({ toDate: arr[1] });
                        }}
                    />
                </Modal>
            </div>
        );
    }
}
