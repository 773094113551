import { onChangeOrderForm } from 'core/forms/orderForm/duck';
import { resetModal } from 'core/modals/duck';
import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    addError,
    createClientSuccess,
    CREATE_CLIENT,
    fetchAddClientFormSuccess,
    FETCH_ADD_CLIENT_FORM
} from './duck';

export function* fetchAddClientFormSaga() {
    while (true) {
        try {
            yield take(FETCH_ADD_CLIENT_FORM);
            const data = yield call(fetchAPI, 'GET', 'vehicles_info');

            yield put(fetchAddClientFormSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* createClientSaga() {
    while (true) {
        const {
            payload: { clientEntity, func }
        } = yield take(CREATE_CLIENT);
        try {
            const response = yield call(fetchAPI, 'POST', 'clients', null, clientEntity, {
                handleErrorInternally: true
            });

            _.get(response, 'clientId') && func && func(response.clientId);
        } catch ({ response, status }) {
            yield put(addError({ response, status }));

            continue;
        }

        yield put(createClientSuccess());
        const actionData = {
            fields: {
                searchClientQuery: {
                    touched: true,
                    dirty: false,
                    value: _.first(clientEntity.phones),
                    name: 'searchClientQuery'
                }
            },
            meta: { form: 'orderForm', field: 'searchClientQuery' }
        };
        yield put(onChangeOrderForm(actionData.fields, actionData.meta));
        yield put(resetModal());
    }
}
/* eslint-disable array-element-newline */
export function* saga() {
    yield all([call(fetchAddClientFormSaga), call(createClientSaga)]);
}
/* eslint-enable array-element-newline */
