import dayjs from 'dayjs';

const getBeginDatetime = (day, time, beginHour) => {
    const orderHour = time + beginHour * 2;

    const timeString = orderHour % 2 ? `${Math.floor(orderHour / 2)}:30` : `${orderHour / 2}:00`;

    return dayjs(`${day} ${timeString}`);
};

export default getBeginDatetime;
