import dayjs from 'dayjs';
import React from 'react';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    order: 'auto',
    order_status: '10%',
    begin_datetime: '10%',
    sum: '10%',
    remaining_sum: '10%',
    client_vehicle: '25%',
    responsible: '15%'
};

const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';

export function columnsConfig(props) {
    const { formatMessage } = props;

    const numberCol = {
        width: defWidth.order_status,
        key: 'mrds',
        render: (elem, obj) => {
            return (
                <div>{`${obj.num}\n${dayjs(obj.createdDatetime).format('DD.MM.YYYY HH:mm')}`}</div>
            );
        }
    };

    const nameCol = {
        width: defWidth.order_status,
        key: 'client',
        render: (elem, obj) => {
            return (
                <div>
                    {` ${obj.clientName || ''} ${obj.clientSurname || ''}\n${
                        obj.clientPhone || ''
                    } `}
                </div>
            );
        }
    };

    const vehicleCol = {
        width: defWidth.order_status,
        key: 'vehicle',
        render: (elem, obj) => {
            return (
                <div>
                    {`${obj.vehicleMakeName || ''} ${obj.vehicleModelName || ''}\n${
                        obj.vehicleNumber || ''
                    }`}
                </div>
            );
        }
    };

    const statusCol = {
        width: defWidth.order_status,
        key: 'status',
        render: (elem, obj) => {
            return <div>{formatMessage({ id: `order-status.${elem.status}` })}</div>;
        }
    };

    return [numberCol, nameCol, vehicleCol, statusCol];
}
