import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { ManagerRoleContainer } from 'containers';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

export default class ManagerRolePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    componentDidMount() {
        this.fetchHelperLinks();
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_employees' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { helperDrawerOpen, allLinks } = this.state;

        return (
            <Layout
                controls={
                    <React.Fragment>
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={() => {
                                this.setState({
                                    helperDrawerOpen: true
                                });
                            }}
                            style={{
                                fontSize: 22,
                                marginLeft: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </React.Fragment>
                }
                title={<FormattedMessage id='manager-role-page.title' />}
            >
                <ManagerRoleContainer />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks
                            .filter(({ ogTitle }) => ogTitle == 'Додавання нового працівника - help.carbook.pro')
                            .map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                                <div className={Styles.linkBlock}>
                                    <div className={Styles.ogTitle}>
                                        {index + 1}. {ogTitle}
                                    </div>
                                    <div className={Styles.ogDesc}>{ogDescription}</div>
                                    <div className={Styles.ogImg}>
                                        <Image
                                            src={
                                                _.isArray(ogImage)
                                                    ? _.get(ogImage, '[0].url', [])
                                                    : _.get(ogImage, 'url', [])
                                            }
                                        />
                                    </div>
                                    <a href={ogUrl} rel='noreferrer' target='_blank'>
                                        <Button
                                            style={{
                                                width: '100%'
                                            }}
                                            type='primary'
                                        >
                                            <FormattedMessage id='repair_map_table.goto' />
                                        </Button>
                                    </a>
                                </div>
                            ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
