import { Tabs } from 'antd';
import { HistoryTable } from 'forms/OrderForm/OrderFormTables';
import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI, isForbidden, permissions } from 'utils';
import { DetailsTable, DiscountPanel, ServicesTable } from '../OrderFormTables';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

function hideTasks(orderTasks, managerId) {
    const newOrderTasks = _.cloneDeep(orderTasks);
    _.each(_.get(newOrderTasks, 'orderTasks'), newOrderTask => {
        newOrderTask.history = _.filter(newOrderTask.history, {
            responsibleId: managerId
        });
    });

    return newOrderTasks;
}

@injectIntl
export default class OrderFormTabs extends React.PureComponent {
    /* eslint-disable complexity */

    constructor(props) {
        super(props);
        this.state = {
            activeKey: 'services',
            action: undefined,
            detailsTreeData: undefined,
            labors: []
        };
        this._localizationMap = {};
        this.commentsRules = [
            {
                max: 2000,
                message: this.props.intl.formatMessage({
                    id: 'field_should_be_below_2000_chars'
                })
            }
        ];
        this.commentsAutoSize = { minRows: 2, maxRows: 6 };
        this._setActiveTab = this._setActiveTab.bind(this);
    }

    buildStoreGroupsTree() {
        const treeData = [];
        for (let i = 0; i < this.props.details.length; i++) {
            const parentGroup = this.props.details[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id})`,
                name: parentGroup.singleName,
                value: parentGroup.id,
                className: Styles.groupTreeOption,
                key: `${i}`,
                children: [],
                multiplier: parentGroup.priceGroupMultiplier
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id})`,
                    name: childGroup.singleName,
                    value: childGroup.id,
                    className: Styles.groupTreeOption,
                    key: `${i}-${j}`,
                    children: [],
                    multiplier: childGroup.priceGroupMultiplier
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id})`,
                        name: lastNode.singleName,
                        value: lastNode.id,
                        className: Styles.groupTreeOption,
                        key: `${i}-${j}-${k}`,
                        children: [],
                        multiplier: lastNode.priceGroupMultiplier
                    });
                    for (let l = 0; l < lastNode.childGroups.length; l++) {
                        const elem = lastNode.childGroups[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id})`,
                            name: elem.singleName,
                            value: elem.id,
                            className: Styles.groupTreeOption,
                            key: `${i}-${j}-${k}-${l}`,
                            multiplier: elem.priceGroupMultiplier
                        });
                    }
                }
            }
        }
        this.setState({
            detailsTreeData: treeData
        });
    }

    // TODO: move into utils
    _getLocalization(key) {
        if (!this._localizationMap[key]) {
            this._localizationMap[key] = this.props.intl.formatMessage({
                id: key
            });
        }

        return this._localizationMap[key];
    }

    _setActiveTab(tab, action) {
        this.setState({
            activeKey: tab,
            action
        });
    }

    fetchLabors = async () => {
        const { labors } = await fetchAPI('GET', 'labors');
        this.setState({ labors });
    };

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.showOilModal && this.props.showOilModal) {
            this.setState({
                activeKey: 'services'
            });
        }
        if (!this.state.detailsTreeData && this.props.details && this.props.details.length) {
            this.buildStoreGroupsTree();
            this.fetchLabors();
        }
        if (prevState.action) {
            this.setState({ action: undefined });
        }
    }

    render() {
        const {
            setModal,
            fetchOrderForm,
            // fetchOrderTask,
            fetchTecdocSuggestions,
            fetchTecdocDetailsSuggestions,
            clearTecdocDetailsSuggestions,
            clearTecdocSuggestions,

            addOrderForm,
            detailsSuggestionsFetching,
            suggestionsFetching,

            orderTasks,
            orderCalls,
            orderHistory,
            orderServices,
            orderDetails,
            orderDiagnostic,
            orderId,
            allServices,
            allDetails,
            details,
            employees,
            selectedClient,
            detailsSuggestions,
            suggestions,
            fetchedOrder,
            user,
            schedule,
            stations,
            availableHours,
            stationLoads,
            tecdocId,
            clientVehicleId,

            // fields
            initialStation,
            initialBeginDatetime,

            // stats
            priceDetails,
            priceServices,
            countDetails,
            countServices,
            commentsCount,
            stationsCount,
            totalDetailsProfit,
            totalServicesProfit,

            intl: { formatMessage },
            form: { getFieldDecorator },
            form,

            initOrderTasksForm,
            changeModalStatus,

            fields,
            errors,

            normHourPrice,

            showOilModal,
            oilModalData,
            clearOilData,
            modals,
            download,
            focusOnRef,
            showCahOrderModal,
            reloadOrderForm
        } = this.props;

        const { labors } = this.state;

        let orderServicesSize = 0;
        let orderDetailsSize = 0;

        orderServices.map(x => {
            if (x.id) orderServicesSize++;
        });
        orderDetails.map(x => {
            if (x.id) orderDetailsSize++;
        });

        const {
            ACCESS_ORDER_HISTORY,
            ACCESS_ORDER_CALLS,
            ACCESS_ORDER_COMMENTS,
            ACCESS_ORDER_SERVICES,
            ACCESS_ORDER_DETAILS,
            ACCESS_ORDER_DIAGNOSTICS,
            GET_TASKS,
            GET_ALL_TASKS,
            UPDATE_SUCCESS_ORDER,
            ACCESS_ORDER_REPAIR_MAP,
            ACCESS_ORDER_WORKSHOP,
            ACCESS_ORDER_STOCK,
            ACCESS_ORDER_TABS_POSTS_CRUD,
            ACCESS_ORDER_TABS_COMMENTS_CRUD
        } = permissions;

        const isHistoryForbidden = isForbidden(user, ACCESS_ORDER_HISTORY);
        const areServicesForbidden = isForbidden(user, ACCESS_ORDER_SERVICES);
        const areDetailsForbidden = isForbidden(user, ACCESS_ORDER_DETAILS);
        const closedEditing =
            (this.props.orderStatus == 'success' || this.props.orderStatus == 'cancel') &&
            isForbidden(user, UPDATE_SUCCESS_ORDER);

        if (this.state.activeKey == 'services' && areServicesForbidden) {
            if (!areDetailsForbidden) {
                this.setState({
                    activeKey: 'details'
                });
            } else {
                this.setState({
                    activeKey: 'history'
                });
            }
        }

        const viewTasks = !isForbidden(user, GET_TASKS);
        const viewAllTasks = !isForbidden(user, GET_ALL_TASKS);
        const canCreateTask = viewAllTasks && orderTasks.orderTasks && orderTasks.orderTasks.length < 1;

        const tasks = viewAllTasks ? orderTasks : hideTasks(orderTasks, user.id);

        const servicesTableFieldsProps = _.pick(this.props.fields, ['services', 'clientVehicle', 'employee']);
        const detailsTableFieldsProps = _.pick(this.props.fields, ['details']);
        const discountTabFieldsProps = _.pick(this.props.fields, ['servicesDiscount', 'detailsDiscount']);
        const stationLoadsFieldsProps = _.pick(this.props.fields, ['stationLoads']);

        const clientVehicleTypeId = _.get(fetchedOrder, 'order.clientVehicleTypeId');
        const clientVehicleRadius = _.get(fetchedOrder, 'order.clientVehicleRadius');

        return (
            <Tabs
                activeKey={this.state.activeKey}
                className={Styles.orderFormsTabs}
                onTabClick={key => {
                    this.setState({
                        activeKey: key,
                        action: undefined
                    });
                }}
            >
                {!addOrderForm && (
                    <TabPane
                        key='services'
                        forceRender
                        tab={`${formatMessage({
                            id: 'add_order_form.services',
                            defaultMessage: 'Services'
                        })} (${orderServicesSize})`}
                    >
                        <ServicesTable
                            activeKey={this.state.activeKey}
                            agreementCompleted={_.get(fetchedOrder, 'order.agreementCompleted')}
                            allServices={allServices}
                            clientVehicleRadius={clientVehicleRadius}
                            clientVehicleTypeId={clientVehicleTypeId}
                            completedDiagnostic={orderDiagnostic ? orderDiagnostic.completed : null}
                            defaultEmployeeId={this.props.defaultEmployeeId}
                            details={details}
                            detailsTreeData={this.state.detailsTreeData}
                            disabled={closedEditing || isForbidden(user, permissions.ACCESS_ORDER_LABORS_CRUD)}
                            employees={employees}
                            errors={errors}
                            fetchedOrder={fetchedOrder}
                            fetchTecdocSuggestions={fetchTecdocSuggestions}
                            fields={servicesTableFieldsProps}
                            form={form}
                            labors={labors}
                            laborTimeMultiplier={this.props.laborTimeMultiplier}
                            normHourPrice={normHourPrice}
                            orderId={orderId}
                            orderServices={orderServices}
                            reloadOrderForm={reloadOrderForm}
                            selectedClient={selectedClient}
                            tecdocId={tecdocId}
                            user={user}
                        />
                        <DiscountPanel
                            discountFieldName='servicesDiscount'
                            fetchedOrder={fetchedOrder}
                            fields={discountTabFieldsProps}
                            forbidden={
                                areServicesForbidden || isForbidden(user, permissions.ACCESS_ORDER_LABORS_DISCOUNTS)
                            }
                            form={form}
                            isServiceMarkupForbidden={
                                areServicesForbidden || isForbidden(user, permissions.ACCESS_NORM_HOURS_MODAL_WINDOW)
                            }
                            laborTimeMultiplier={this.props.laborTimeMultiplier}
                            orderId={orderId}
                            price={priceServices}
                            reloadOrderForm={reloadOrderForm}
                            servicesMode
                            totalServicesProfit={totalServicesProfit}
                        />
                    </TabPane>
                )}
                {!addOrderForm && (
                    <TabPane
                        key='details'
                        forceRender
                        tab={`${formatMessage({
                            id: 'add_order_form.details',
                            defaultMessage: 'Details'
                        })} (${orderDetailsSize})`}
                    >
                        <DetailsTable
                            activeKey={this.state.activeKey}
                            agreementCompleted={_.get(fetchedOrder, 'order.agreementCompleted')}
                            allDetails={allDetails}
                            clearOilData={clearOilData}
                            clearTecdocDetailsSuggestions={clearTecdocDetailsSuggestions}
                            clearTecdocSuggestions={clearTecdocSuggestions}
                            clientVehicleId={clientVehicleId}
                            clientVehicleVin={this.props.clientVehicleVin}
                            completedDiagnostic={orderDiagnostic ? orderDiagnostic.completed : null}
                            details={details}
                            detailsSuggestions={detailsSuggestions}
                            detailsSuggestionsFetching={detailsSuggestionsFetching}
                            detailsTreeData={this.state.detailsTreeData}
                            disabled={closedEditing || isForbidden(user, permissions.ACCESS_ORDER_DETAILS_CRUD)}
                            errors={errors}
                            fetchRecommendedPrice={this.props.fetchRecommendedPrice}
                            fetchTecdocDetailsSuggestions={fetchTecdocDetailsSuggestions}
                            fields={detailsTableFieldsProps}
                            form={form}
                            labors={labors}
                            oilModalData={oilModalData}
                            orderDetails={orderDetails}
                            orderId={orderId}
                            recommendedPrice={this.props.recommendedPrice}
                            recommendedPriceLoading={this.props.recommendedPriceLoading}
                            reloadOrderForm={reloadOrderForm}
                            setModal={setModal}
                            setStoreProductsSearchQuery={this.props.setStoreProductsSearchQuery}
                            showOilModal={showOilModal}
                            storeProducts={this.props.storeProducts}
                            suggestions={suggestions}
                            suggestionsFetching={suggestionsFetching}
                            tecdocId={tecdocId}
                            user={user}
                        />
                        <DiscountPanel
                            detailsMode
                            discountFieldName='detailsDiscount'
                            fetchedOrder={fetchedOrder}
                            fields={discountTabFieldsProps}
                            forbidden={
                                areDetailsForbidden || isForbidden(user, permissions.ACCESS_ORDER_DETAILS_DISCOUNTS)
                            }
                            form={form}
                            orderDetails={orderDetails}
                            orderId={orderId}
                            price={priceDetails}
                            reloadOrderForm={reloadOrderForm}
                            totalDetailsProfit={totalDetailsProfit}
                        />
                    </TabPane>
                )}
                {!addOrderForm && (
                    <TabPane
                        key='history'
                        disabled={isHistoryForbidden}
                        forceRender
                        tab={`${formatMessage({
                            id: 'order_form_table.history'
                        })}(${this.state.historyCount || '...'})`}
                    >
                        <HistoryTable
                            clientVehicleId={_.get(fetchedOrder, 'order.clientVehicleId', undefined)}
                            fetchOrderForm={fetchOrderForm}
                            setHistoryCount={historyCount => {
                                this.setState({
                                    historyCount
                                });
                            }}
                            user={user}
                        />
                    </TabPane>
                )}
            </Tabs>
        );
    }
}
