/* eslint-disable max-classes-per-file */
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DeleteOutlined, ReconciliationOutlined, SettingOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Checkbox, InputNumber, Modal, Select, Tabs, Upload, notification } from 'antd';
import { API_URL, confirmDiagnostic, lockDiagnostic, sendMessage } from 'core/forms/orderDiagnosticForm/saga';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

@injectIntl
class ConfirmDiagnosticModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataSource: props.dataSource,
            diagnosticList: [],
            labors: null,
            allDetails: null,
            servicesList: [],
            detailsList: [],
            autoConfirmed: false
        };
        this.tmp = {};
        this.servicesOptions = null;
        this.detailsOptions = null;
        this.lastServiceInput = React.createRef();
        this.lastDetailInput = React.createRef();
        this.diagnosticKey = 1;
        this._isMounted = false;

        this.setServicesComment = this.setServicesComment.bind(this);
        this.setDetailsComment = this.setDetailsComment.bind(this);
    }

    async endСonfirmation(orderId, data) {
        await confirmDiagnostic(orderId, data);
        await lockDiagnostic(orderId);
        window.location.reload();
    }

    showModal = () => {
        this.setState({
            visible: true
        });
        // this.getCurrentOrderDetailsAndServices();
    };

    handleOk = async () => {
        const { status } = await fetchAPI('GET', 'orders/status', { orderId: this.props.orderId }, null);
        if (status === 'success') {
            window.location.reload();

            return;
        }
        const data = {
            services: [],
            details: [],
            insertMode: true
        };
        this.setState({ visible: false });

        if (this.props.tecdocId) {
            data.modificationId = this.props.tecdocId;
        }
        this.state.servicesList.map(element => {
            if (element.checked && element.id) {
                data.services.push({
                    serviceName:
                        element.commentary && element.commentary.positions.length
                            ? `${element.name} - ${element.commentary.positions.map(
                                  data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
                              )}`
                            : element.name,
                    serviceId: element.id,
                    count: Number(element.count * this.props.laborTimeMultiplier) || this.props.laborTimeMultiplier,
                    serviceHours: 0,
                    servicePrice: element.price ? element.price : Number(this.props.normHourPrice),
                    employeeId: this.props.defaultEmployeeId,
                    comment: {
                        comment: element.commentary && element.commentary.comment,
                        positions: element.commentary && element.commentary.positions,
                        problems: element.commentary && element.commentary.problems
                    },
                    isCritical: element.status == 3,
                    photoHashes: element.photoHashes
                });
            }
        });
        this.state.detailsList.map(element => {
            if (element.checked && element.id) {
                data.details.push({
                    name:
                        element.commentary && element.commentary.positions.length
                            ? `${element.name} - ${element.commentary.positions.map(
                                  data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
                              )}`
                            : element.name,
                    storeGroupId: element.id,
                    count: element.count,
                    comment: {
                        comment: element.commentary && element.commentary.comment,
                        positions: element.commentary && element.commentary.positions
                    },
                    isCritical: element.status == 3,
                    productCode: element.oeCode,
                    supplierBrandId: element.oeCode ? 8000 : undefined,
                    oeCode: element.oeCode,
                    photoHashes: element.photoHashes,
                    responsibleId: this.props.defaultResponsibleId || undefined
                });
            }
        });
        this.endСonfirmation(this.props.orderId, data);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            dataSource: this.props.dataSource,
            diagnosticList: [],
            servicesList: [],
            detailsList: [],
            autoConfirmed: false
        });
    };

    updateState() {
        this.state.dataSource = this.props.dataSource;
        this.state.diagnosticList = [];
        this.diagnosticKey = 1;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.orderId && this._isMounted) {
            this.fetchOptionsSourceData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate = async (prevProps, prevState) => {
        // await this.updateState();
        if (this.props.labors && this.props.labors.length && !this.servicesOptions) {
            this.servicesOptions = this.getServicesOptions();
        }
        if (this.state.allDetails != null && this.detailsOptions == null) {
            this.detailsOptions = this.getDetailsOptions();
        }
        if (prevProps.action != 'DIAGNOSTICS_COMPLETE' && this.props.action == 'DIAGNOSTICS_COMPLETE') {
            this.setState({
                visible: true
            });
        }
    };

    fetchOptionsSourceData() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/store_groups?keepFlat=true`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.setState({
                    allDetails: data,
                    labors: that.props.labors
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    getLaborByPartId(id, commentary, status, photoHashes) {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = API_URL;
        const params = `/diagnostics/labor_id/${id}`;
        url += params;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.addServicesByLaborId(data.laborId, -1, commentary, status, photoHashes);
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    addServicesByLaborId(id, index = -1, commentary, status, photoHashes) {
        const service = this.props.labors.find(x => x.id == id);
        if (service == undefined) {
            return;
        }

        if (index == -1) {
            this.state.servicesList[this.state.servicesList.length - 1] = {
                key: this.state.servicesList.length,
                id,
                storeGroupId: service.storeGroupId,
                name: service.name,
                count: service.laborPrice.normHours,
                price: service.laborPrice.price,
                checked: true,
                commentary,
                status,
                automaticly: true,
                photoHashes
            };
        } else {
            this.state.servicesList[index].id = id;
            this.state.servicesList[index].status = status;
            this.state.servicesList[index].name = service.name;
            this.state.servicesList[index].storeGroupId = service.storeGroupId;
            this.state.servicesList[index].count = service.laborPrice.normHours;
            this.state.servicesList[index].price = service.laborPrice.price;
            this.state.servicesList[index].photoHashes = photoHashes;
        }
        this.setState({
            update: true
        });
    }

    getGroupByPartId = async (id, commentary, status, oeCode, photoHashes) => {
        const resp = await fetchAPI('GET', `diagnostics/store_group_id/${id}`);
        this.addDetailsByGroupId(resp.storeGroupId, -1, commentary, status, oeCode, photoHashes);
    };

    addDetailsByGroupId(id, index = -1, commentary, status, oeCode, photoHashes) {
        const detail = this.state.allDetails.find(x => x.id == id);
        if (!detail) {
            return;
        }

        if (index == -1) {
            this.state.detailsList[this.state.detailsList.length - 1] = {
                key: this.state.detailsList.length,
                id: detail.id,
                name: detail.name,
                count: 1,
                checked: true,
                commentary,
                status,
                oeCode,
                photoHashes
            };
        } else {
            this.state.detailsList[index].id = id;
            this.state.detailsList[index].status = status;
            this.state.detailsList[index].count = 1;
            this.state.detailsList[index].name = detail.name;
            this.state.detailsList[index].oeCode = oeCode;
            this.state.detailsList[index].photoHashes = photoHashes;
        }
        this.setState({
            update: true
        });
    }

    changeResolved(index, type) {
        this.state.diagnosticList[index].resolved =
            type == 'disabled' ? true : !this.state.diagnosticList[index].resolved;
        this.state.diagnosticList[index].type = type;
        this.setState({
            update: true
        });
        if (type == 'manually') {
            this.state.servicesList[this.state.servicesList.length - 1].commentary = {
                ...this.state.diagnosticList[index].commentary
            };
            this.lastServiceInput.focus();
        }
    }

    disableDiagnosticRow(index) {
        this.state.diagnosticList[index].checked = !this.state.diagnosticList[index].checked;
        this.state.diagnosticList[index].disabled = !this.state.diagnosticList[index].disabled;
        if (!event.target.checked) {
            {
                this.changeResolved(index, 'disabled');
            }
        } else {
            {
                this.changeResolved(index, '');
            }
        }
    }

    automaticlyConfirmDiagnostic() {
        const partIds = [];
        const oeCodes = [];
        const photoHashes = [];
        this.state.diagnosticList.map((data, index) => {
            if (!data.resolved) {
                this.changeResolved(index, 'automaticly');
                partIds.push({
                    id: data.id,
                    isCritical: data.status == 3,
                    comment: data.commentary
                });
                oeCodes.push({
                    id: data.id,
                    oeCode: data.oeCode
                });
                photoHashes.push({
                    id: data.id,
                    photoHashes: data.photoHashes
                });
            }
        });
        this.getDataByPartIds(partIds, oeCodes, photoHashes);
    }

    async getDataByPartIds(partIds, oeCodes, photoHashes) {
        const data = await fetchAPI(
            'PUT',
            'diagnostics/calculation_data',
            null,
            { partIds },
            { handleErrorInternally: true }
        );
        this.state.servicesList.pop();
        this.state.detailsList.pop();

        const serviceArray = [...this.state.servicesList];
        const detailArrat = [...this.state.detailsList];

        data.map((elem, index) => {
            const laborObjCopy = {
                key: this.state.servicesList.length + index + 1,
                id: elem.labor.id,
                storeGroupId: elem.labor.storeGroupId,
                name: elem.labor.name,
                count: elem.labor.laborPrice.normHours,
                price: elem.labor.laborPrice.price,
                checked: true,
                commentary: elem.comment,
                status: elem.isCritical ? 3 : 2,
                automaticly: true,
                photoHashes: photoHashes[index].photoHashes
            };
            serviceArray.push(laborObjCopy);

            const detailObjCopy = {
                key: this.state.detailsList.length + index + 1,
                id: elem.storeGroup.storeGroup.id,
                name: elem.storeGroup.storeGroup.singleName,
                count: 1,
                checked: true,
                commentary: elem.comment,
                status: elem.isCritical ? 3 : 2,
                oeCode: oeCodes[index].oeCode,
                photoHashes: photoHashes[index].photoHashes
            };

            detailArrat.push(detailObjCopy);
        });
        this.setState({
            autoConfirmed: true,
            servicesList: serviceArray,
            detailsList: detailArrat
        });
    }

    getDiagnostics(stage) {
        const { dataSource } = this.props;
        const diagnosticList = this.state.diagnosticList.slice(0);
        const tmpSource = [];
        for (let i = 0; i < dataSource.length; i++) {
            if (dataSource[i].stage == stage && Number(dataSource[i].status) > 1 && !dataSource[i].disabled) {
                tmpSource.push(dataSource[i]);
                const commentary = { ...dataSource[i].commentary };
                if (this.state.diagnosticList.findIndex(x => x.id == dataSource[i].partId) == -1) {
                    const diagnosticObjCopy = {
                        key: this.diagnosticKey,
                        id: dataSource[i].partId,
                        commentary: commentary || { comment: '', positions: [], problems: [] },
                        resolved: false,
                        type: '',
                        disabled: false,
                        checked: true,
                        status: Number(dataSource[i].status),
                        templateIndex: dataSource[i].templateIndex,
                        oeCode: dataSource[i].oeCode,
                        photoHashes: dataSource[i].photoHashes
                    };

                    diagnosticList.push(diagnosticObjCopy);
                    this.diagnosticKey++;
                }
            }
        }
        this.state.diagnosticList = diagnosticList;

        return tmpSource.map((data, divKey) => {
            const index = this.state.diagnosticList.findIndex(
                x => x.id == data.partId && x.templateIndex == data.templateIndex
            );
            const key = index !== -1 ? this.state.diagnosticList[index].key : -1;

            if (index === -1) return;

            let bgColor = this.state.diagnosticList[index].disabled ? '#d9d9d9' : '';
            const txtColor = this.state.diagnosticList[index].disabled ? 'gray' : '';

            if (!this.state.diagnosticList[index].resolved) {
                if (data.status == 1) {
                    bgColor = 'rgb(200,225,180)';
                } else if (data.status == 2) {
                    bgColor = 'rgb(255,240,180)';
                } else if (data.status == 3) {
                    bgColor = 'rgb(250,175,175)';
                }
            } else if (!this.state.diagnosticList[index].disabled) {
                bgColor = 'rgb(200,225,180)';
            }

            return (
                <div
                    key={divKey}
                    className={Styles.confirm_diagnostic_modal_row}
                    style={{ backgroundColor: bgColor, color: txtColor }}
                >
                    <div style={{ width: '10%' }}>
                        {key}{' '}
                        <Checkbox
                            checked={this.state.diagnosticList[index].checked}
                            onChange={() => this.disableDiagnosticRow(index)}
                        />
                    </div>
                    <div style={{ width: '50%', padding: '0 5px' }}>{data.detail}</div>
                    {!this.state.diagnosticList[index].resolved ? (
                        <div className={Styles.confirm_diagnostic_modal_row_button} style={{ width: '40%' }}>
                            <Button
                                onClick={async () => {
                                    const servicesComment = JSON.parse(JSON.stringify(data.commentary));
                                    const detailComment = JSON.parse(JSON.stringify(data.commentary));
                                    await this.changeResolved(index, 'automaticly');
                                    await this.getLaborByPartId(
                                        data.partId,
                                        servicesComment,
                                        data.status,
                                        data.photoHashes
                                    );
                                    await this.getGroupByPartId(
                                        data.partId,
                                        detailComment,
                                        data.status,
                                        data.oeCode,
                                        data.photoHashes
                                    );
                                }}
                                style={{ width: '49%', padding: '5px' }}
                                title={this.props.intl.formatMessage({
                                    id: 'confirm_diagnostic_modal.auto'
                                })}
                            >
                                <FormattedMessage id='order_form_table.diagnostic.automaticly' />
                            </Button>
                            <Button
                                onClick={() => {
                                    this.changeResolved(index, 'manually');
                                }}
                                style={{ width: '49%', padding: '5px' }}
                                title={this.props.intl.formatMessage({
                                    id: 'confirm_diagnostic_modal.manual'
                                })}
                            >
                                <FormattedMessage id='order_form_table.diagnostic.manually' />
                            </Button>
                        </div>
                    ) : (
                        <div className={Styles.confirm_diagnostic_modal_row_button} style={{ width: '40%' }}>
                            <Button
                                disabled={this.state.diagnosticList[index].disabled}
                                onClick={() => {
                                    this.changeResolved(index, '');
                                }}
                                style={{ width: '98%' }}
                            >
                                <FormattedMessage
                                    id={`order_form_table.diagnostic.${this.state.diagnosticList[index].type}`}
                                />
                            </Button>
                        </div>
                    )}
                </div>
            );
        });
    }

    getDiagnosticContent() {
        const { dataSource } = this.props;
        const stageList = [];
        for (let i = 0; i < dataSource.length; i++) {
            if (
                dataSource[i].stage != '' &&
                stageList.indexOf(dataSource[i].stage) == -1 &&
                Number(dataSource[i].status) > 1
            ) {
                stageList.push(dataSource[i].stage);
            }
        }

        return stageList.map((stage, key) => (
            <div key={key}>
                <div className={Styles.confirm_diagnostic_modal_row_title}>{stage}</div>
                {this.getDiagnostics(stage)}
            </div>
        ));
    }

    servicesCheckboxClick(index) {
        this.state.servicesList[index].checked = !this.state.servicesList[index].checked;
        this.setState({
            update: true
        });
    }

    addNewServicesRow() {
        if (this.state.servicesList.length == 0) {
            this.state.servicesList.push({
                key: 1,
                id: null,
                name: null,
                count: 1,
                commentary: { commentary: '', positions: [] },
                checked: true
            });
        } else if (this.state.servicesList[this.state.servicesList.length - 1].name != null) {
            this.state.servicesList.push({
                key: this.state.servicesList[this.state.servicesList.length - 1].key + 1,
                id: null,
                name: null,
                count: 1,
                commentary: { commentary: '', positions: [], problems: [] },
                checked: true
            });
        }
    }

    getServicesOptions() {
        const { Option } = Select;

        return this.props.labors.map((data, index) => (
            <Option
                key={index}
                default_hours={data.normHours}
                labor_id={data.id}
                master_labor_id={data.masterLaborId}
                price={data.price}
                product_id={data.storeGroupId}
                value={String(data.id)}
            >
                {data.name}
            </Option>
        ));
    }

    deleteServiceRow(index) {
        const array = [...this.state.servicesList];
        if (array.length == 1) {
            return 0;
        }
        array.splice(index, 1);
        for (let i = 0; i < array.length; i++) {
            array[i].key = i + 1;
        }
        this.setState({ servicesList: array });
    }

    getServicesContent() {
        let tmpServicesArray = [...this.state.servicesList];
        tmpServicesArray = tmpServicesArray.map((data, index) => (
            <div
                key={index}
                className={Styles.confirm_diagnostic_modal_row}
                style={data.status == 3 ? { backgroundColor: 'rgb(250,175,175)' } : null}
            >
                <div style={{ width: '10%' }}>
                    {data.key} <Checkbox checked={data.checked} onClick={() => this.servicesCheckboxClick(index)} />
                </div>
                <div style={{ width: '60%', padding: '0 5px' }}>
                    <Select
                        ref={node => {
                            this.lastServiceInput = node;
                        }}
                        className='service_input'
                        disabled={!data.checked}
                        dropdownStyle={{ minWidth: 380 }}
                        filterOption={false}
                        getPopupContainer={() => document.getElementById(`${Styles.diagnosticModalServices}`)}
                        onSearch={input => this.props.handleSearchLabors(input)}
                        onSelect={(value, option) => {
                            const val = value && value.replace(/-/g, '');
                            this.addServicesByLaborId(val, index, data.commentary, data.status);
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.service.placeholder'
                        })}
                        showSearch
                        style={{ width: '100%' }}
                        value={data ? data.name : undefined}
                    >
                        {data.automaticly
                            ? this.servicesOptions.filter(elem => elem.props.product_id == data.storeGroupId)
                            : this.servicesOptions}
                    </Select>
                </div>
                <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                    <InputNumber
                        decimalSeparator=','
                        disabled={!data.checked}
                        min={0.1}
                        onChange={value => {
                            data.count = value;
                            this.setState({ update: true });
                        }}
                        step={0.1}
                        style={{ width: '55%' }}
                        value={data.count || 1}
                    />
                    <div
                        className={Styles.delete_diagnostic_button_wrap}
                        style={{ width: '15%', display: 'inline-block' }}
                    >
                        <Button
                            icon={
                                <DeleteOutlined
                                    className={Styles.delete_diagnostic_button}
                                    style={!data.name ? { color: 'var(--text5)', pointerEvents: 'none' } : {}}
                                />
                            }
                            onClick={() => this.deleteServiceRow(index)}
                            type='text'
                        />
                    </div>
                </div>
            </div>
        ));

        return tmpServicesArray;
    }

    setServicesComment(comment, positions, index, problems) {
        this.state.servicesList[index].commentary = {
            comment,
            positions,
            problems
        };
        this.setState({
            update: true
        });
    }

    detailsCheckboxClick(index) {
        this.state.detailsList[index].checked = !this.state.detailsList[index].checked;
        this.setState({
            update: true
        });
    }

    addNewDetailsRow() {
        if (this.state.detailsList.length == 0) {
            this.state.detailsList.push({
                key: 1,
                id: null,
                name: null,
                count: 1,
                checked: true,
                commentary: { commentary: '', positions: [] }
            });
        } else if (this.state.detailsList[this.state.detailsList.length - 1].name != null) {
            this.state.detailsList.push({
                key: this.state.detailsList[this.state.detailsList.length - 1].key + 1,
                id: null,
                name: null,
                count: 1,
                checked: true,
                commentary: { commentary: '', positions: [] }
            });
        }
    }

    getDetailsOptions() {
        const { Option } = Select;
        const { allDetails } = this.state;

        return allDetails.map((data, index) => (
            <Option key={index} detail_id={data.id} detail_name={data.singleName} value={String(data.id)}>
                {data.singleName}
            </Option>
        ));
    }

    deleteDetailRow(index) {
        const array = [...this.state.detailsList];
        if (array.length == 1) {
            return 0;
        }
        array.splice(index, 1);
        for (let i = 0; i < array.length; i++) {
            array[i].key = i + 1;
        }
        this.setState({ detailsList: array });
    }

    getDetailsContent() {
        let tmpDetailsArray = [...this.state.detailsList];
        tmpDetailsArray = tmpDetailsArray.map((data, index) => (
            <div
                key={index}
                className={Styles.confirm_diagnostic_modal_row}
                style={data.status == 3 ? { backgroundColor: 'rgb(250,175,175)' } : null}
            >
                <div style={{ width: '10%' }}>
                    {data.key} <Checkbox checked={data.checked} onClick={() => this.detailsCheckboxClick(index)} />
                </div>
                <div style={{ width: '60%', padding: '0 5px' }}>
                    <Select
                        ref={node => {
                            this.lastDetailInput = node;
                        }}
                        className='service_input'
                        disabled={!data.checked}
                        dropdownStyle={{ minWidth: 380 }}
                        filterOption={(input, option) => {
                            const parts = input.toLowerCase().split(' ');

                            return parts.every(part => String(option.children).toLowerCase().includes(part));
                        }}
                        getPopupContainer={() => document.getElementById(`${Styles.diagnosticModalDetails}`)}
                        onChange={(inputValue, option) => {
                            data.name = option.props.detail_name;
                            this.setState({ update: true });
                        }}
                        onSelect={(value, option) => {
                            this.addDetailsByGroupId(value, index);
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.service.placeholder'
                        })}
                        showSearch
                        style={{ width: '100%' }}
                        value={data ? data.name : undefined}
                    >
                        {this.detailsOptions}
                    </Select>
                </div>
                <div style={{ width: '30%' }}>
                    <InputNumber
                        decimalSeparator=','
                        disabled={!data.checked}
                        max={50}
                        min={1}
                        onChange={value => {
                            data.count = value;
                            this.setState({ update: true });
                        }}
                        style={{ width: '55%' }}
                        value={data.count ? data.count : 1}
                    />
                    <div
                        className={Styles.delete_diagnostic_button_wrap}
                        style={{ width: '15%', display: 'inline-block' }}
                    >
                        <DeleteOutlined
                            className={Styles.delete_diagnostic_button}
                            onClick={() => this.deleteDetailRow(index)}
                            style={!data.name ? { color: 'var(--text5)', pointerEvents: 'none' } : {}}
                        />
                    </div>
                </div>
            </div>
        ));

        return tmpDetailsArray;
    }

    setDetailsComment(comment, positions, index) {
        this.state.detailsList[index].commentary = {
            comment,
            positions
        };
        this.setState({
            update: true
        });
    }

    render() {
        const { visible, autoConfirmed } = this.state;
        const {
            isMobile,
            disabled,
            hideEndButton,
            intl: { formatMessage }
        } = this.props;
        this.addNewServicesRow();
        this.addNewDetailsRow();

        const diagnosticComponents = this.getDiagnosticContent();
        const servicesComponents = this.getServicesContent();
        const detailsComponents = this.getDetailsContent();

        return (
            <div>
                <React.Fragment>
                    {isMobile ? (
                        <Button
                            disabled={isForbidden(this.props.user, permissions.ACCESS_DIAGNOSTICS_COMPLETE) || disabled}
                            onClick={() => {
                                notification.success({
                                    message: formatMessage({
                                        id: 'message_sent'
                                    })
                                });
                                sendMessage(this.props.orderId);
                            }}
                            style={{ width: hideEndButton ? '100%' : '80%' }}
                            type='primary'
                        >
                            <FormattedMessage id='end' />
                        </Button>
                    ) : (
                        <React.Fragment>
                            <Button
                                disabled={
                                    isForbidden(
                                        this.props.user,
                                        permissions.ACCESS_ORDER_CREATIONG_OF_DIAGNOSTICS_MODAL_WINDOW
                                    ) || disabled
                                }
                                onClick={this.showModal}
                                style={{
                                    width: '40%',
                                    marginRight: 8,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                <FormattedMessage id='order_form_table.diagnostic.create_order' />
                            </Button>
                            {!hideEndButton && (
                                <Button
                                    disabled={
                                        isForbidden(this.props.user, permissions.ACCESS_DIAGNOSTICS_COMPLETE) ||
                                        disabled
                                    }
                                    onClick={() => {
                                        notification.success({
                                            message: formatMessage({
                                                id: 'message_sent'
                                            })
                                        });
                                        sendMessage(this.props.orderId);
                                    }}
                                    style={{ width: '40%' }}
                                >
                                    <FormattedMessage id='end' />
                                </Button>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
                <Modal
                    footer={[
                        <Button key='back' onClick={this.handleCancel}>
                            <FormattedMessage id='cancel' />
                        </Button>,
                        <Button key='submit' onClick={this.handleOk} type='primary'>
                            <FormattedMessage id='order_form_table.diagnostic.confirm' />
                        </Button>
                    ]}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='order_form_table.diagnostic.create_order' />}
                    visible={visible}
                    width='95%'
                >
                    {!isMobile ? (
                        <div className={Styles.confirm_diagnostic_modal_wrap}>
                            <div className={Styles.confirm_diagnostic_modal_element}>
                                <div
                                    className={Styles.confirm_diagnostic_modal_element_title}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FormattedMessage id='order_form_table.diagnostic.results' />
                                    <Button
                                        disabled={autoConfirmed}
                                        onClick={() => {
                                            this.automaticlyConfirmDiagnostic();
                                        }}
                                        title={formatMessage({
                                            id: 'confirm_diagnostic_modal.auto'
                                        })}
                                        type='primary'
                                    >
                                        <FormattedMessage id='order_form_table.diagnostic.automaticly' />
                                    </Button>
                                </div>
                                <div className={Styles.confirm_diagnostic_modal_element_content}>
                                    {diagnosticComponents}
                                </div>
                            </div>
                            <div
                                className={Styles.confirm_diagnostic_modal_element}
                                id={Styles.diagnosticModalServices}
                            >
                                <div className={Styles.confirm_diagnostic_modal_element_title}>
                                    <FormattedMessage id='add_order_form.services' />
                                </div>
                                <div className={Styles.confirm_diagnostic_modal_element_content}>
                                    {servicesComponents}
                                </div>
                            </div>
                            <div className={Styles.confirm_diagnostic_modal_element} id={Styles.diagnosticModalDetails}>
                                <div className={Styles.confirm_diagnostic_modal_element_title}>
                                    <FormattedMessage id='add_order_form.details' />
                                </div>
                                <div className={Styles.confirm_diagnostic_modal_element_content}>
                                    {detailsComponents}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={Styles.confirm_diagnostic_modal_wrap}>
                            <Tabs defaultActiveKey='1'>
                                <TabPane key='1' tab={<ReconciliationOutlined className={Styles.modal_tab_icon} />}>
                                    <div className={Styles.confirm_diagnostic_modal_element_mobile}>
                                        <div className={Styles.confirm_diagnostic_modal_element_title}>
                                            <FormattedMessage id='order_form_table.diagnostic.results' />
                                        </div>
                                        <div className={Styles.confirm_diagnostic_modal_element_content}>
                                            {diagnosticComponents}
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane key='2' tab={<ToolOutlined className={Styles.modal_tab_icon} />}>
                                    <div
                                        className={Styles.confirm_diagnostic_modal_element_mobile}
                                        id={Styles.diagnosticModalServices}
                                    >
                                        <div className={Styles.confirm_diagnostic_modal_element_title}>
                                            <FormattedMessage id='add_order_form.services' />
                                        </div>
                                        <div className={Styles.confirm_diagnostic_modal_element_content}>
                                            {servicesComponents}
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane key='3' tab={<SettingOutlined className={Styles.modal_tab_icon} />}>
                                    <div
                                        className={Styles.confirm_diagnostic_modal_element_mobile}
                                        id={Styles.diagnosticModalDetails}
                                    >
                                        <div className={Styles.confirm_diagnostic_modal_element_title}>
                                            <FormattedMessage id='add_order_form.details' />
                                        </div>
                                        <div className={Styles.confirm_diagnostic_modal_element_content}>
                                            {detailsComponents}
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}
export default ConfirmDiagnosticModal;

@injectIntl
class PhotoButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fileList: []
        };
    }

    getPhoto = async hash => {
        const photo = await fetchAPI('GET', 'orders/photo', { hash }, null, {
            handleErrorInternally: true,
            rawResponse: true
        });
        const reportFile = await photo.blob();
        const url = URL.createObjectURL(reportFile);
        const file = {
            key: hash,
            uid: hash,
            status: 'done',
            url,
            thumbUrl: url
        };

        return file;
    };

    showModal = async () => {
        const photoHashes = _.get(this.props.rowProp, 'photoHashes', []);
        const fileList = await Promise.all(photoHashes.map(hash => this.getPhoto(hash)));

        this.setState({
            visible: true,
            fileList
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    componentDidUpdate = async prevProps => {
        if (prevProps.rowProp.photoHashes !== this.props.rowProp.photoHashes) {
            const photoHashes = _.get(this.props.rowProp, 'photoHashes', []);
            const fileList = await Promise.all(photoHashes.map(hash => this.getPhoto(hash)));
            this.setState({
                fileList
            });
        }
    };

    render() {
        const { disabled, rowProp } = this.props;
        const { visible, fileList } = this.state;

        const photoHashes = _.get(rowProp, 'photoHashes', []);

        return (
            <div>
                <LegacyIcon
                    onClick={this.showModal}
                    style={disabled ? { color: 'var(--text5)', pointerEvents: 'none' } : { color: 'var(--text3)' }}
                    title={this.props.intl.formatMessage({
                        id: 'order_form_table.diagnostic.photo'
                    })}
                    type={photoHashes.length ? 'file-image' : 'camera'}
                />
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    footer={null}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='order_form_table.diagnostic.photo' />}
                    visible={visible}
                >
                    <Upload
                        fileList={fileList}
                        listType='picture-card'
                        showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: false
                        }}
                    ></Upload>
                </Modal>
            </div>
        );
    }
}
