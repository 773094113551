import { Checkbox, DatePicker, Input, Modal, Radio, Select, TreeSelect } from 'antd';
import { DateRangePicker } from 'components';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { StockProductsModal, WMSCellsModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    brands: selectBrands(state),
    warehouses: state.warehouses.warehouses,
    user: state.auth,
    suppliers: state.suppliers.suppliers
});

const mapDispatchToProps = {
    resetModal,
    fetchBrands,
    fetchWarehouses
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RemainsReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: dayjs(),
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            detailing: 'without',
            blind: true,
            detailsTreeData: [],
            suppliers: []
        };
        this.modalNames = {
            byProducts: 'reports_page.remains_products',
            byWarehouses: 'reports_page.remains_by_warehouses',
            byCells: 'reports_page.remains_by_cell',
            stocktaking: 'reports_page.stocktacking',
            purchases_by_supplier: 'reports_page.purchases_by_supplier'
        };
    }

    componentDidUpdate(prevProps) {
        const { modalProps } = this.props;
        const { type } = modalProps;
        if (!prevProps.visible && this.props.visible) {
            this.buildStoreGroupsTree();
            this.props.fetchBrands();
            this.props.fetchWarehouses();
            if (type == 'purchases_by_supplier') {
                this.fetchSuppliers();
            }
        }
    }

    handleSearchSuppliers = _.debounce(value => {
        this.fetchSuppliers(value);
    }, 1000);

    fetchSuppliers = async query => {
        const suppliers = await fetchAPI('GET', 'business_suppliers', {
            query
        });
        this.setState({
            suppliers
        });
    };

    handleOk = async () => {
        const { resetModal, modalProps } = this.props;
        const { type } = modalProps;
        const {
            warehouseId,
            groupId,
            brandId,
            productCode,
            date,
            detailing,
            cellAddress,
            blind,
            fromDate,
            toDate,
            supplierId
        } = this.state;

        const response = await fetchAPI(
            'GET',
            `report/excel/balance/${type}`,
            {
                warehouseId,
                ...(type == 'purchases_by_supplier'
                    ? {
                          date: {
                              from: dayjs(fromDate).format('YYYY-MM-DD'),
                              to: dayjs(toDate).format('YYYY-MM-DD')
                          },
                          supplierId
                      }
                    : { date: dayjs(date).format('YYYY-MM-DD') }),
                brandId,
                groupId,
                productCode,
                cellAddress,
                // blind: false,
                ...(type == 'stocktaking' ? { blind } : { detailing })
            },
            null,
            { rawResponse: true }
        );

        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
        await resetModal();
    };

    buildStoreGroupsTree = async () => {
        const storeGroups = await fetchAPI('GET', 'store_groups');
        const treeData = [];
        for (let i = 0; i < storeGroups.length; i++) {
            const parentGroup = storeGroups[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id})`,
                name: parentGroup.singleName,
                value: parentGroup.id,
                key: `${i}`,
                children: [],
                multiplier: parentGroup.priceGroupMultiplier
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id})`,
                    name: childGroup.singleName,
                    value: childGroup.id,
                    key: `${i}-${j}`,
                    children: [],
                    multiplier: childGroup.priceGroupMultiplier
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id})`,
                        name: lastNode.singleName,
                        value: lastNode.id,
                        key: `${i}-${j}-${k}`,
                        children: [],
                        multiplier: lastNode.priceGroupMultiplier
                    });
                    for (let l = 0; l < lastNode.childGroups.length; l++) {
                        const elem = lastNode.childGroups[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id})`,
                            name: elem.singleName,
                            value: elem.id,
                            key: `${i}-${j}-${k}-${l}`,
                            multiplier: elem.priceGroupMultiplier
                        });
                    }
                }
            }
        }
        this.setState({
            detailsTreeData: treeData
        });
    };

    render() {
        const { resetModal, modalProps, warehouses, visible, user, brands } = this.props;

        const { type } = modalProps;

        const {
            warehouseId,
            groupId,
            brandId,
            productCode,
            date,
            detailing,
            detailsTreeData,
            cellAddress,
            showFromCellModal,
            blind,
            fromDate,
            toDate,
            supplierId,
            suppliers
        } = this.state;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    maskClosable={false}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={() => resetModal()}
                    onOk={this.handleOk}
                    title={
                        <div className={Styles.title}>
                            {}
                            <FormattedMessage id={this.modalNames[type] || 'reports_page.remains_products'} />
                        </div>
                    }
                    visible={visible === MODALS.REMAINS_REPORT_MODAL}
                    width='40%'
                >
                    {type == 'purchases_by_supplier' ? (
                        <React.Fragment>
                            <DateRangePicker
                                dateRange={[fromDate, toDate]}
                                onDateChange={arr => {
                                    this.setState({ fromDate: arr[0] });
                                    this.setState({ toDate: arr[1] });
                                }}
                                style={{
                                    width: '100%',
                                    marginBottom: 8
                                }}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <DatePicker
                                onChange={date => this.setState({ date })}
                                popupStyle={{ overflow: 'auto', zIndex: '9999' }}
                                style={{
                                    width: '100%',
                                    marginBottom: 8
                                }}
                                value={date}
                            />
                        </React.Fragment>
                    )}
                    {type == 'purchases_by_supplier' ? (
                        <Select
                            allowClear
                            dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                            onChange={supplierId => {
                                this.setState({ supplierId });
                            }}
                            onSearch={input => this.handleSearchSuppliers(input)}
                            optionFilterProp='children'
                            placeholder={this.props.intl.formatMessage({
                                id: 'remains_report.suppliers'
                            })}
                            showSearch
                            value={supplierId}
                        >
                            {suppliers.map(({ name, id }) => (
                                <Option key={id} id={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <Select
                            allowClear
                            dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                            onChange={warehouseId => {
                                this.setState({ warehouseId });
                            }}
                            optionFilterProp='children'
                            placeholder={this.props.intl.formatMessage({ id: 'storage' })}
                            showSearch
                            value={warehouseId}
                        >
                            {warehouses.map(({ name, id }) => (
                                <Option key={id} id={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    )}
                    {['byCells', 'stocktaking'].includes(type) && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                allowClear
                                onChange={({ target }) => {
                                    if (!target.value) this.setState({ cellAddress: undefined });
                                }}
                                onClick={() => {
                                    this.setState({
                                        showFromCellModal: true
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({ id: 'wms.cell' })}
                                style={{ marginTop: 8 }}
                                value={cellAddress}
                            />
                            <WMSCellsModal
                                confirmAction={cellAddress => {
                                    this.setState({
                                        cellAddress
                                    });
                                }}
                                hideModal={() => {
                                    this.setState({ showFromCellModal: false });
                                }}
                                visible={Boolean(showFromCellModal)}
                            />
                        </div>
                    )}
                    {type != 'byCells' && (
                        <TreeSelect
                            allowClear
                            dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                            filterTreeNode={(input, node) => {
                                return (
                                    node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onSelect={groupId => {
                                this.setState({ groupId });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.store_group'
                            })}
                            showSearch
                            style={{ marginTop: 8 }}
                            treeData={detailsTreeData}
                            value={groupId}
                        />
                    )}
                    <Select
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={brandId => this.setState({ brandId })}
                        placeholder={this.props.intl.formatMessage({
                            id: 'order_form_table.brand'
                        })}
                        showSearch
                        style={{ marginTop: 8 }}
                        value={brandId}
                    >
                        {brands.map(({ brandId, brandName }) => (
                            <Option key={brandId} value={brandId}>
                                {brandName}
                            </Option>
                        ))}
                    </Select>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Input
                            allowClear
                            onChange={({ target }) => this.setState({ productCode: target.value })}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.detail_code'
                            })}
                            style={{ width: '100%' }}
                            style={{ marginTop: 8 }}
                            value={productCode}
                        />
                        <div style={{ marginTop: 8 }}>
                            <StockProductsModal
                                selectProduct={({ code, brandId }) => {
                                    this.setState({ productCode: code, brandId });
                                }}
                                stockMode
                                user={user}
                            />
                        </div>
                    </div>
                    {type == 'stocktaking' ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingTop: 8
                            }}
                        >
                            <div>
                                <FormattedMessage id='remains_report.blind' />
                            </div>
                            <div>
                                <Checkbox checked={blind} onChange={() => this.setState({ blind: !blind })} />
                            </div>
                            <div />
                        </div>
                    ) : (
                        <div style={{ paddingTop: 8 }}>
                            <div>
                                <FormattedMessage id='date_detailing' />
                            </div>
                            <Radio.Group onChange={e => this.setState({ detailing: e.target.value })} value={detailing}>
                                <Radio value='without'>
                                    <FormattedMessage id='date_without_detailing' />
                                </Radio>
                                {type == 'byProducts' && (
                                    <React.Fragment>
                                        <Radio value='warehouses'>
                                            <FormattedMessage id='by_warehouses' />
                                        </Radio>
                                        <Radio value='cells'>
                                            <FormattedMessage id='by_cells' />
                                        </Radio>
                                    </React.Fragment>
                                )}
                                {['purchases_by_supplier', 'byWarehouses'].includes(type) && (
                                    <React.Fragment>
                                        <Radio value='groups'>
                                            <FormattedMessage id='by_groups' />
                                        </Radio>
                                        <Radio value='products'>
                                            <FormattedMessage id='by_products' />
                                        </Radio>
                                    </React.Fragment>
                                )}
                                {type == 'byCells' && (
                                    <React.Fragment>
                                        <Radio value='products'>
                                            <FormattedMessage id='by_products' />
                                        </Radio>
                                    </React.Fragment>
                                )}
                            </Radio.Group>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}
