import { Table } from 'antd';
import { Catcher, Numeral } from 'commons';
import { OrderStatusIcon } from 'components';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, getCurrency } from 'utils';
import { v4 } from 'uuid';

@injectIntl
export default class HistoryTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            stats: {
                countOrders: 0
            }
        };

        // const viewTasks = !isForbidden(props.user, permissions.GET_TASKS);

        this.columns = [
            {
                title: <FormattedMessage id='date' />,
                dateIndex: 'datetime',
                key: 'history-date',
                render: (text, record) => (
                    <div style={{ wordBreak: 'normal' }}>
                        {record.beginDatetime
                            ? dayjs(record.beginDatetime).format('DD.MM.YYYY HH:mm')
                            : null}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='add_order_form.odometr' />,
                // dataIndex: 'vehicleMakeName',
                key: 'odometr',
                width: 100,
                render: (text, record) => <Numeral>{Number(record.odometer)}</Numeral>
            },
            {
                title: <FormattedMessage id='order' />,
                dataIndex: 'num',
                key: 'history-num',
                render: (text, record) => (
                    <React.Fragment>
                        <Link
                            to={`${book.order}/${record.id}`}
                            // onClick={() => {
                            //     props.fetchOrderForm(record.id);
                            //     if (viewTasks) {
                            //         props.fetchOrderTask(record.id);
                            //     }
                            // }}
                        >
                            <span style={{ paddingRight: 4 }}>{text}</span>
                            <OrderStatusIcon status={record.status} />
                        </Link>
                        <div>{record.serviceNames.map(serviceName => serviceName).join(', ')}</div>
                    </React.Fragment>
                )
            },

            {
                title: <FormattedMessage id='orders.sum_without_VAT' />,
                // dataIndex: 'servicesTotalSum',
                key: 'history-sum',
                render: (text, record) => (
                    <Numeral
                        currency={getCurrency()}
                        data-qa='numeral_details_services_total_sum_nistory_table_order_page'
                    >
                        {record.detailsTotalSum + record.servicesTotalSum}
                    </Numeral>
                )
            },
            {
                title: <FormattedMessage id='order_form_table.recommendation_n_reasons' />,
                // dataIndex: 'servicesTotalSum',
                key: 'history-recommendation',
                render: (text, record) => (
                    <div>{record.recommendation || record.cancelStatusReason}</div>
                )
            }
            // {
            //     title: <FormattedMessage id='order_form_table.cancel_reason' />,
            //     // dataIndex: 'servicesTotalSum',
            //     key: 'history-cancel-reason',
            //     render: (text, record) => <div>{record.cancelStatusReason}</div>
            // }
        ];
    }

    componentDidMount() {
        setTimeout(this.fetchOrderHistory, 0);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.clientVehicleId && this.props.clientVehicleId) {
            this.fetchOrderHistory();
        }
    }

    fetchOrderHistory = async () => {
        const { clientVehicleId, setHistoryCount } = this.props;
        const { page } = this.state;
        if (clientVehicleId) {
            const { orders: orderHistory, stats } = await fetchAPI(
                'GET',
                `vehicle/${clientVehicleId}/history`,
                {
                    page
                }
            );
            await this.setState(
                {
                    orderHistory,
                    stats
                },
                () => {
                    setHistoryCount(stats.countOrders);
                }
            );
        }
    };

    render() {
        const { orderHistory, stats, page } = this.state;
        const dataSource = orderHistory || [];
        const { columns } = this;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.countOrders / 25) * 25,
            hideOnSinglePage: true,
            current: page,
            onChange: async page => {
                await this.setState({ page, orderHistory: null });
                this.fetchOrderHistory();
            }
        };

        return (
            <Catcher>
                <Table
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={pagination}
                    rowKey={() => {
                        return v4();
                    }}
                    size='small'
                />
            </Catcher>
        );
    }
}
