import { Select } from 'antd';
import _ from 'lodash';
import { STORAGE_DOCUMENT_STATUSES } from 'modals/DebtZeroingModal/constants';
import {
    selectSuppliersStoreDocsFilters,
    setSupplierStoreDocStatus
} from 'modals/DebtZeroingModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    suppliersStoreDocsFilters: selectSuppliersStoreDocsFilters(state)
});

const mapDispatchToProps = {
    setSupplierStoreDocStatus
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocStatusSelector extends React.Component {
    render() {
        const {
            suppliersStoreDocsFilters,

            setSupplierStoreDocStatus,

            intl: { formatMessage }
        } = this.props;

        const selectedStatus = _.get(suppliersStoreDocsFilters, 'statuses')
            ? undefined // Default statuses are set
            : _.get(suppliersStoreDocsFilters, 'status'); // Then select only one status

        return (
            <div title={formatMessage({ id: 'debt_zeroing_modal.store_doc_status' })}>
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={status => setSupplierStoreDocStatus(status)}
                    placeholder={formatMessage({ id: 'debt_zeroing_modal.store_doc_status' })}
                    value={selectedStatus}
                >
                    {
                        // Map only for available statuses
                        _.map(STORAGE_DOCUMENT_STATUSES, status => {
                            return (
                                <Option key={status} value={status}>
                                    <FormattedMessage id={`debt_zeroing_modal.${status}`} />
                                </Option>
                            );
                        })
                    }
                </Select>
            </div>
        );
    }
}
