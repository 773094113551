/**
 * Constants
 * */
export const moduleName = 'employees';
const prefix = `cpb/${moduleName}`;

export const FETCH_EMPLOYEES = `${prefix}/FETCH_EMPLOYEES`;
export const FETCH_EMPLOYEES_SUCCESS = `${prefix}/FETCH_EMPLOYEES_SUCCESS`;

export const FETCH_EMPLOYEE_TASK_COUNT = `${prefix}/FETCH_EMPLOYEE_TASK_COUNT`;
export const FETCH_EMPLOYEE_TASK_COUNT_SUCCESS = `${prefix}/FETCH_EMPLOYEE_TASK_COUNT_SUCCESS`;

export const FETCH_EMPLOYEE_ACCEPT_TASK_COUNT = `${prefix}/FETCH_EMPLOYEE_ACCEPT_TASK_COUNT`;
export const FETCH_EMPLOYEE_ACCEPT_TASK_COUNT_SUCCESS = `${prefix}/FETCH_EMPLOYEE_ACCEPT_TASK_COUNT_SUCCESS`;

export const DELETE_EMPLOYEE = `${prefix}/DELETE_EMPLOYEE`;
export const DELETE_EMPLOYEE_SUCCESS = `${prefix}/DELETE_EMPLOYEE_SUCCESS`;

export const SET_EMPLOYEES_STATUS = `${prefix}/SET_EMPLOYEES_STATUS`;
export const SET_EMPLOYEES_DISABLED = `${prefix}/SET_EMPLOYEES_DISABLED`;

export const SET_TASKS_COUNT = `${prefix}/SET_TASKS_COUNT`;
export const SET_TASKS_ACCEPT_COUNT = `${prefix}/SET_TASKS_ACCEPT_COUNT`;
/**
 * Reducer
 * */

const ReducerState = {
    fields: {},
    filter: {
        disabled: null
    },
    employees: null,
    status: 'working',
    activeOrder: null,
    taskCount: 0,
    taskAcceptCount: 0
};

// eslint-disable-next-line
export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: payload
            };

        case SET_EMPLOYEES_STATUS:
            return {
                ...state,
                status: payload.status,
                filter: {
                    disabled: payload.disabled
                }
            };

        case SET_TASKS_COUNT:
            return {
                ...state,
                taskCount: payload.totalRowsCount
            };

        case SET_TASKS_ACCEPT_COUNT:
            return {
                ...state,
                taskAcceptCount: payload.totalRowsCount
            };

        case FETCH_EMPLOYEE_TASK_COUNT_SUCCESS:
            return {
                ...state,
                taskCount: payload.totalRowsCount
            };

        case FETCH_EMPLOYEE_ACCEPT_TASK_COUNT_SUCCESS:
            return {
                ...state,
                taskAcceptCount: payload.totalRowsCount
            };

        default:
            return state;
    }
}

/**
 * Selectors
 * */

export const stateSelector = state => state[moduleName];
export const selectTaskCount = state => state[moduleName].taskCount;
export const selectAcceptTaskCount = state => state[moduleName].taskAcceptCount;

/**
 * Action Creators
 * */

export const fetchEmployees = () => ({
    type: FETCH_EMPLOYEES
});

export const fetchEmployeesSuccess = data => ({
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: data
});

export const fetchEmployeeTaskCount = () => ({
    type: FETCH_EMPLOYEE_TASK_COUNT
});

export const fetchEmployeeTaskCountSuccess = data => ({
    type: FETCH_EMPLOYEE_TASK_COUNT_SUCCESS,
    payload: data
});

export const fetchEmployeeAcceptTaskCount = () => ({
    type: FETCH_EMPLOYEE_ACCEPT_TASK_COUNT
});

export const fetchEmployeeAcceptTaskCountSuccess = data => ({
    type: FETCH_EMPLOYEE_ACCEPT_TASK_COUNT_SUCCESS,
    payload: data
});

export const setEmployeesStatus = ({ status, disabled }) => ({
    type: SET_EMPLOYEES_STATUS,
    payload: { status, disabled }
});

export const deleteEmployee = id => ({
    type: DELETE_EMPLOYEE,
    payload: id
});

export const deleteEmployeeSuccess = data => ({
    type: DELETE_EMPLOYEE_SUCCESS,
    payload: data
});

export const setTaskCount = data => ({
    type: SET_TASKS_COUNT,
    payload: data
});

export const setTaskAcceptCount = data => ({
    type: SET_TASKS_ACCEPT_COUNT,
    payload: data
});
