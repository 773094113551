import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { FETCH_WAREHOUSES, fetchWarehousesSuccess } from './duck';

export function* fetchWarehousesSaga() {
    while (true) {
        try {
            yield take(FETCH_WAREHOUSES);
            yield nprogress.start();
            const user = yield select(state => state.auth);

            const warehouses = yield call(
                fetchAPI,
                'GET',
                'warehouses',
                {
                    disabled: isGrantAccessed(user, grants.SETTINGS_STORES_HIDDEN_WAREHOUSE, accesses.ROWO)
                        ? true
                        : undefined
                },
                undefined,
                {
                    handleErrorInternally: true
                }
            );

            yield put(fetchWarehousesSuccess(warehouses));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([call(fetchWarehousesSaga)]);
}
