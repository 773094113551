// import { FormattedMessage } from 'react-intl';
import { DeleteOutlined } from '@ant-design/icons';

import { Popconfirm } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { cellType } from './EditableCell/cellConfig.js';

/* eslint-disable complexity */
export function columnsConfig(props) {
    const serviceNameCol = {
        title: <FormattedMessage id='services_table.labor' />,
        dataIndex: 'serviceName',
        width: '15%'
    };

    const detailNameCol = {
        title: <FormattedMessage id='report-orders-table.name_spare_parts' />,
        dataIndex: 'detailId',
        width: '20%',
        editable: true,
        cellType: cellType.LIMITED_SELECT,
        details: props.details,
        render: (datailId, { detailName }) => detailName
    };

    const quantityCol = {
        title: <FormattedMessage id='order_form_table.count' />,
        dataIndex: 'quantity',
        width: '10%',
        editable: true,
        cellType: cellType.NUMERAL
    };

    const deleteCol = {
        width: '15%',
        dataIndex: 'delete',
        render: (text, { serviceId, suggestionId }) =>
            !serviceId && (
                <Popconfirm onConfirm={() => props.deleteService(suggestionId)} title='Sure to delete?'>
                    <DeleteOutlined
                        style={{
                            fontSize: '18px',
                            color: 'var(--warning)',
                            cursor: 'pointer'
                        }}
                    />
                </Popconfirm>
            )
    };

    return [serviceNameCol, detailNameCol, quantityCol, deleteCol];
}
