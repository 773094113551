import { notification } from 'antd';
import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { DOWNLOAD_SALES_REPORT, selectFilters } from './duck';

export function* downloadSalesReportSaga() {
    while (true) {
        yield take(DOWNLOAD_SALES_REPORT);

        const {
            type,
            creationFromDate,
            creationToDate,
            vehicleId,
            clientId,
            recommendationId,
            statusOption,

            appointmentFromDate,
            appointmentToDate,

            doneFromDate,
            doneToDate,

            detailing,

            orderId,
            managerId,
            mechanicId,
            sparePartEmployeeId,
            requisiteId,
            mechanicSumLaborsPercent,
            sparePartEmployeeMarginPartsPercent,
            managerSumLaborsPercent,
            managerMarginSupplierPercent,
            managerMarginPartsPercent
        } = yield select(selectFilters);

        try {
            const response = yield call(
                fetchAPI,
                'GET',
                `/report/orders/excel/${type}`,
                {
                    date: {
                        ...(['byEmployees', 'byClients'].includes(type)
                            ? {
                                  creationFrom: creationFromDate,
                                  creationTo: creationToDate,
                                  appointmentFrom: appointmentFromDate,
                                  appointmentTo: appointmentToDate
                              }
                            : {}),
                        doneFrom: doneFromDate,
                        doneTo: doneToDate
                    },
                    ...(!['byBusinessIncome'].includes(type) ? { detailing } : {}),
                    vehicleId,
                    clientId,

                    ...(['byBusinessIncome'].includes(type)
                        ? {
                              recommendationId,
                              statusOption,
                              orderId,
                              managerId,
                              mechanicId,
                              sparePartEmployeeId,
                              requisiteId,
                              mechanicSumLaborsPercent,
                              sparePartEmployeeMarginPartsPercent,
                              managerSumLaborsPercent,
                              managerMarginSupplierPercent,
                              managerMarginPartsPercent
                          }
                        : {})
                },
                null,
                { handleErrorInternally: true, rawResponse: true }
            );

            const reportFile = yield response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];

            yield saveAs(reportFile, fileName);
        } catch (err) {
            yield put(emitError(err));
            notification.error({ message: 'Error' });
        }
    }
}

export function* saga() {
    yield all([call(downloadSalesReportSaga)]);
}
