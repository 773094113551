import { Table } from 'antd';
import {
    fetchStoreDocs,
    selectFetchingStoreDocs,
    selectSelectedStoreDocId,
    selectStoreDocs,
    selectStoreDocsFilters,
    selectStoreDocsStats,
    setSelectedStoreDocId,
    setStoreDocsFilters
} from 'modals/DebtZeroingModal/redux/storeDocs/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    storeDocs: selectStoreDocs(state),
    storeDocsStats: selectStoreDocsStats(state),
    storeDocsFilters: selectStoreDocsFilters(state),
    fetchingStoreDocs: selectFetchingStoreDocs(state),

    selectedStoreDocId: selectSelectedStoreDocId(state)
});

const mapDispatchToProps = {
    fetchStoreDocs,
    setStoreDocsFilters,

    setSelectedStoreDocId
};

/**
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocsTable extends Component {
    componentDidMount() {
        this.props.fetchStoreDocs();
    }

    render() {
        const {
            isMobile,
            storeDocs,
            fetchingStoreDocs,

            storeDocsStats,
            storeDocsFilters,
            setStoreDocsFilters,
            setSelectedStoreDocId,

            selectedStoreDocId
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(storeDocsStats.count / 25) * 25,
            current: storeDocsFilters.page,
            onChange: page => {
                setStoreDocsFilters({ page });
            }
        };

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig()}
                    dataSource={storeDocs}
                    loading={fetchingStoreDocs}
                    onRow={record => ({
                        onClick: () => {
                            setSelectedStoreDocId(record.id);
                        }
                    })}
                    pagination={pagination}
                    rowClassName={record =>
                        record.id == selectedStoreDocId ? Styles.selectedRow : undefined
                    }
                    rowKey={record => record.id}
                    scroll={{ x: isMobile ? '100vw' : '40vw', y: '25vh' }}
                />
            </div>
        );
    }
}
