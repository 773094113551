import { DownSquareOutlined, EditOutlined, UndoOutlined, UpSquareOutlined } from '@ant-design/icons';
import { Button, Checkbox, ColorPicker, Input, Popover, Space, Table, Tooltip } from 'antd';
import { get, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const StatusTab = ({ intl, disabled }) => {
    const [subStatuses, setSubStatuses] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const substatuses = await fetchAPI('GET', 'substatuses', { filterByPackage: true }, null, {
            handleErrorInternally: true
        });
        setSubStatuses(substatuses);
        setLoading(false);
    };

    const swapStatuses = useCallback(async (subStatus1, subStatus2) => {
        await fetchAPI(
            'PUT',
            'swap_business_substatuses',
            null,
            {
                subStatus1: { ...subStatus1, subStatusPosition: subStatus2.subStatusPosition },
                subStatus2: { ...subStatus2, subStatusPosition: subStatus1.subStatusPosition }
            },
            {
                handleErrorInternally: true
            }
        );
        fetchData();
    }, []);

    const updateStatus = useCallback(async status => {
        await fetchAPI(
            'PUT',
            'business_substatuses',
            null,
            {
                ...pick(status, [
                    'subStatusId',
                    'subStatusName',
                    'subStatusOnPlanner',
                    'subStatusColor',
                    'subStatusIsActive'
                ])
            },
            {
                handleErrorInternally: true
            }
        );
        fetchData();
    }, []);

    const columns = useMemo(
        () => [
            {
                title: <FormattedMessage id='repair_map_setting.position' />,
                key: 'subStatusPosition',
                width: 'min-content',
                dataIndex: 'subStatusPosition',
                render: (subStatusPosition, row, index) => {
                    const prev = get(subStatuses, `[${index - 1}]`);
                    const next = get(subStatuses, `[${index + 1}]`);

                    const getFields = obj =>
                        pick(obj, [
                            'subStatusId',
                            'subStatusPosition',
                            'subStatusName',
                            'subStatusOnPlanner',
                            'subStatusColor',
                            'subStatusIsActive'
                        ]);

                    return (
                        <div>
                            <Button
                                disabled={!prev || prev.statusId !== row.statusId || disabled}
                                icon={<UpSquareOutlined />}
                                onClick={() => swapStatuses(getFields(row), getFields(prev))}
                                type='text'
                            />
                            <Button
                                disabled={!next || next.statusId !== row.statusId || disabled}
                                icon={<DownSquareOutlined />}
                                onClick={() => swapStatuses(getFields(row), getFields(next))}
                                type='text'
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='repair_map_setting.name' />,
                key: 'subStatusName',
                render: row => {
                    return (
                        <React.Fragment>
                            {row.subStatusName || (
                                <FormattedMessage id={`status_directory.${row.subStatusDefaultName}`} />
                            )}{' '}
                            <Popover
                                content={
                                    <Space>
                                        <Input
                                            onChange={e => {
                                                row.subStatusName = e.target.value;
                                                setSubStatuses(
                                                    subStatuses.map(el => {
                                                        return el.subStatusId === row.subStatusId ? row : el;
                                                    })
                                                );
                                            }}
                                            placeholder={intl.formatMessage({ id: 'repair_map_setting.name' })}
                                            value={row.subStatusName}
                                        />
                                        <Tooltip title={intl.formatMessage({ id: 'datepicker.clear' })}>
                                            <Button
                                                disabled={disabled}
                                                icon={<UndoOutlined />}
                                                onClick={() => {
                                                    updateStatus({ ...row, subStatusName: null });
                                                }}
                                                type='text'
                                            />
                                        </Tooltip>
                                    </Space>
                                }
                                onOpenChange={open => {
                                    if (open) return;

                                    updateStatus(row);
                                }}
                                trigger='click'
                            >
                                <Button disabled={disabled} icon={<EditOutlined />} type='text' />
                            </Popover>
                        </React.Fragment>
                    );
                }
            },
            {
                title: <FormattedMessage id='status_directory.shortcut' />,
                key: 'subStatusShortCut',
                dataIndex: 'subStatusShortCut'
            },
            {
                title: <FormattedMessage id='repair_map_setting.color' />,
                key: 'subStatusColor',
                render: row => {
                    return (
                        <Space>
                            <ColorPicker
                                disabled={disabled}
                                onChange={color => {
                                    row.subStatusColor = color.toHexString();
                                    setSubStatuses(
                                        subStatuses.map(el => {
                                            return el.subStatusId === row.subStatusId ? row : el;
                                        })
                                    );
                                }}
                                onChangeComplete={() => {
                                    updateStatus(row);
                                }}
                                showText
                                value={row.subStatusColor}
                            />
                            <Button
                                disabled={disabled}
                                icon={<UndoOutlined />}
                                onClick={() => {
                                    row.subStatusColor = null;
                                    setSubStatuses(
                                        subStatuses.map(el => {
                                            return el.subStatusId === row.subStatusId ? row : el;
                                        })
                                    );
                                    updateStatus(row);
                                }}
                                type='text'
                            />
                        </Space>
                    );
                }
                // render: color => {
                //     return {
                //         props: {
                //             style: { backgroundColor: color }
                //         }
                //     };
                // }
            },
            {
                title: <FormattedMessage id='status_directory.planner' />,
                key: 'subStatusOnPlanner',
                render: row => (
                    <Checkbox
                        checked={row.subStatusOnPlanner}
                        disabled={!row.subStatusIsActive || disabled}
                        onChange={() => updateStatus({ ...row, subStatusOnPlanner: !row.subStatusOnPlanner })}
                    />
                )
            },
            {
                title: <FormattedMessage id='status_directory.active' />,
                key: 'subStatusIsActive',
                render: row => (
                    <Checkbox
                        checked={row.subStatusIsActive}
                        disabled={!row.subStatusTurnOff || disabled}
                        onChange={() =>
                            updateStatus({
                                ...row,
                                subStatusIsActive: !row.subStatusIsActive
                            })
                        }
                    />
                )
            }
        ],
        [intl, subStatuses, swapStatuses, updateStatus, disabled]
    );

    useEffect(() => {
        const setupFetch = async () => {
            await fetchData();
        };
        setupFetch();
    }, []);

    return (
        <Table
            bordered
            columns={columns}
            dataSource={subStatuses}
            loading={loading}
            pagination={false}
            rowKey='subStatusId'
            size='small'
        />
    );
};

export default injectIntl(StatusTab);
