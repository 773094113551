import {
    fetchVehicle,
    fetchVehicleNormHours,
    selectClient,
    selectGeneralData,
    selectVehicle,
    setExpandedVehicleId
} from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import VehicleNormHoursTable from './VehicleNormHoursTable';

const mapStateToProps = state => ({
    vehicle: selectVehicle(state),
    client: selectClient(state),
    generalData: selectGeneralData(state)
});

const mapDispatchToProps = {
    fetchVehicle,
    setExpandedVehicleId,
    fetchVehicleNormHours
};

/**
 * This tab is supposed to show vehicle norm hours data.
 * Each vehicle has its own norm hours parameters and price for them.
 * */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class GeneralInfoTab extends Component {
    render() {
        return <VehicleNormHoursTable />;
    }
}
