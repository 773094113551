import { Button, Card, Form, Input } from 'antd';
import { Result } from 'components';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
// import { withReduxForm2 } from 'utils';
import book from 'routes/book.js';
import { fetchAPI } from 'utils';
import Styles from '../LoginForm/loginForm.m.css';

@injectIntl
export class ForgotPassword extends Component {
    state = {
        noLogin: false,
        goToMail: false
    };

    _submit = async ({ login }) => {
        const response = await fetchAPI(
            'POST',
            '/password/reset/request',
            null,
            { login },
            {
                rawResponse: true,
                handleErrorInternally: true
            }
        );

        if (response.status === 200) this.setState({ goToMail: true });
        if (response.status !== 200) this.setState({ noLogin: true });
    };

    _backToForm = () => this.setState({ noLogin: false });

    _backToLogin = () => this.setState({ goToMail: false });

    render() {
        const { formatMessage } = this.props.intl;

        const { noLogin, goToMail } = this.state;

        return (
            <Form className={Styles.loginForm} layout='vertical' onFinish={this._submit}>
                {!(noLogin || goToMail) ? (
                    <React.Fragment>
                        <Form.Item
                            label={
                                <FormattedMessage id='login_form.enter_your_registration_login' />
                            }
                            name='login'
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'login_form.login_is_required'
                                    })
                                }
                            ]}
                        >
                            <Input
                                placeholder={formatMessage({
                                    id: 'login_form.enter_login'
                                })}
                            />
                        </Form.Item>
                        <Button htmlType='submit' type='primary'>
                            <FormattedMessage id='submit' />
                        </Button>
                    </React.Fragment>
                ) : null}
                {noLogin && (
                    <Card bordered={false}>
                        <Result
                            actions={
                                <Button onClick={this._backToForm}>
                                    <FormattedMessage id='login_form.back_to_form' />
                                </Button>
                            }
                            description={<FormattedMessage id='login_form.no_login_found' />}
                            style={{ marginTop: 48, marginBottom: 16 }}
                            // extra={ extra }
                            title={<FormattedMessage id='login_form.error' />}
                            type='error'
                        />
                    </Card>
                )}
                {goToMail && (
                    <Card bordered={false}>
                        <Result
                            actions={
                                <Link to={book.login}>
                                    <Button onClick={this._backToLogin}>
                                        <FormattedMessage id='login_form.back_to_login' />
                                    </Button>
                                </Link>
                            }
                            description={
                                <FormattedMessage id='login_form.go_to_mail_description' />
                            }
                            style={{ marginTop: 48, marginBottom: 16 }}
                            title={<FormattedMessage id='login_form.go_to_mail' />}
                            type='success'
                        />
                    </Card>
                )}
            </Form>
        );
    }
}

export const ForgotPasswordForm = ForgotPassword;
