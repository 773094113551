exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__table---2WyYP td{vertical-align:initial!important}.styles-m__table---2WyYP p{white-space:nowrap}.styles-m__bold---2VWAF{font-weight:700}.styles-m__numberAlign---2Smmx{text-align:right}.styles-m__justifySpace---3TNli{display:flex;justify-content:space-between}.styles-m__table---2WyYP th{white-space:nowrap}.styles-m__table---2WyYP td{white-space:nowrap;min-width:100px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,4BACI,gCACJ,CAEA,2BACI,kBACJ,CAEA,wBACI,eACJ,CAEA,+BACI,gBACJ,CAEA,gCACI,YAAa,CACb,6BACJ,CAEA,4BACI,kBACJ,CAEA,4BACI,kBAAmB,CACnB,eACJ","file":"styles.m.css","sourcesContent":[".table td {\n    vertical-align: baseline !important;\n}\n\n.table p {\n    white-space: nowrap;\n}\n\n.bold {\n    font-weight: 700;\n}\n\n.numberAlign {\n    text-align: right;\n}\n\n.justifySpace {\n    display: flex;\n    justify-content: space-between;\n}\n\n.table th {\n    white-space: nowrap;\n}\n\n.table td {\n    white-space: nowrap;\n    min-width: 100px;\n}\n"]}]);

// Exports
exports.locals = {
	"table": "styles-m__table---2WyYP",
	"bold": "styles-m__bold---2VWAF",
	"numberAlign": "styles-m__numberAlign---2Smmx",
	"justifySpace": "styles-m__justifySpace---3TNli"
};