import { Table } from 'antd';
import {
    fetchSearchOrder,
    fetchSearchStoreDoc,
    fetchSelectedClientOrders,
    onOrderSelect,
    setOrderSearchFilters,
    setSelectedClientOrdersFilters,
    setStoreDocSearchFilters
} from 'core/forms/cashOrderForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setSelectedClientOrdersFilters,
    fetchSelectedClientOrders,
    onOrderSelect,
    setOrderSearchFilters,
    setStoreDocSearchFilters,
    fetchSearchOrder,
    fetchSearchStoreDoc
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export class CashSelectedClientOrdersTable extends Component {
    constructor(props) {
        super(props);

        this.columns = columnsConfig({
            formatMessage: props.intl.formatMessage
        });
    }

    componentDidMount() {
        this.props.fetchSelectedClientOrders();
        if (!isForbidden(this.props.user, permissions.ACCESS_STOCK)) {
            this.props.fetchSearchStoreDoc();
        }
    }

    _onRowClick = row => {
        if (this.props.type === 'order' || this.props.type === 'client') {
            this.props.selectOrder(row);
        } else if (this.props.type === 'storeDoc') {
            this.props.selectStoreDoc(row);
        }
    };

    _setPage = page => {
        if (this.props.type === 'client') {
            this.props.setSelectedClientOrdersFilters({ page });
            this.props.fetchSelectedClientOrders();
        }

        if (this.props.type === 'order') {
            this.props.setOrderSearchFilters({ page });
            this.props.fetchSearchOrder();
        }

        if (this.props.type === 'storeDoc') {
            this.props.setStoreDocSearchFilters({ page });
            this.props.fetchSearchStoreDoc();
        }
    };

    render() {
        const {
            searching,
            selectedClient,
            clientFilteredOrders,
            searchOrdersResult,
            searchStoreDocsResult,
            type,
            intl: { formatMessage }
        } = this.props;

        const columns = columnsConfig({
            formatMessage,
            type
        });

        const pagination = {
            pageSize: 25,
            size: 'large',
            total:
                Math.ceil(
                    this.props.type === 'client'
                        ? _.get(selectedClient, 'clientOrders.count', 1) / 25
                        : this.props.type === 'order'
                        ? _.get(searchOrdersResult, 'count', 1) / 25
                        : _.get(searchStoreDocsResult, 'count', 1) / 25
                ) * 25,
            hideOnSinglePage: true,
            current:
                this.props.type === 'client'
                    ? _.get(selectedClient, 'filters.page', 1)
                    : this.props.type === 'order'
                    ? _.get(searchOrdersResult, 'filters.page', 1)
                    : _.get(searchStoreDocsResult, 'filters.page', 1),
            onChange: page => this._setPage(page)
        };

        return (
            <Table
                bordered
                columns={columns}
                dataSource={
                    this.props.type === 'client'
                        ? _.get(selectedClient, 'clientOrders.orders', [])
                        : this.props.type === 'order'
                        ? _.get(searchOrdersResult, 'orders', [])
                        : _.get(searchStoreDocsResult, 'storeDocs', [])
                }
                loading={searching}
                locale={{
                    emptyText: <FormattedMessage id='no_data' />
                }}
                onRow={row => ({
                    onClick: () => this._onRowClick(row)
                })}
                pagination={pagination}
                rowClassName={() => Styles.linkRow}
                scroll={{ x: 720 }}
                size='small'
            />
        );
    }
}
