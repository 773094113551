import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

const cx = classNames.bind(Styles);

export const Subscriptions = props => {
    const { packages, suggestions } = props;

    const expirationDiff = subscriptionType => {
        if (subscriptionType) {
            const date = subscriptionType.expirationDatetime || subscriptionType.endDate;
            if (date) {
                return -dayjs().diff(date, 'days');
            }
        }

        return 1000;
    };

    const backgroundColor = subscriptionType => {
        const expiration = expirationDiff(subscriptionType);
        if (Number.isInteger(expiration)) {
            return cx({
                normalRow: expiration > 10,
                warningRow: expiration <= 10 && expiration >= -10,
                criticalRow: expiration < -10
            });
        }
    };

    return (
        <div className={Styles.headerInfo}>
            <Link className={Styles.packages} to={book.subscriptionHistoryPage}>
                <span className={`${backgroundColor(packages)} ${Styles.leftRow} `}>PRO:&nbsp;</span>
                {packages ? (
                    <React.Fragment>
                        <span className={backgroundColor(packages)}>{packages.name}&nbsp;</span>
                        <span className={`${backgroundColor(packages)} ${Styles.rightRow}`}>
                            <FormattedMessage id='header.until' />
                            &nbsp;
                            {dayjs(packages.expirationDatetime).format('YYYY-MM-DD')}
                        </span>
                    </React.Fragment>
                ) : (
                    <span className={`${backgroundColor(packages)} ${Styles.rightRow}`}>
                        <FormattedMessage id='header.not_active' />
                    </span>
                )}
            </Link>
            <Link className={Styles.suggestions} to={book.subscriptionHistoryPage}>
                <span className={`${backgroundColor(suggestions)} ${Styles.leftRow}`}>
                    <FormattedMessage id='header.advertisement' />
                    :&nbsp;
                </span>
                <span className={`${backgroundColor(suggestions)} ${!suggestions && Styles.rightRow}`}>
                    {suggestions && suggestions.name ? (
                        suggestions.name.split(' ')[0]
                    ) : (
                        <FormattedMessage id='header.not_active' />
                    )}
                </span>
                {suggestions && (
                    <span className={`${backgroundColor(suggestions)} ${Styles.rightRow}`}>
                        {suggestions.endDate ? (
                            <React.Fragment>
                                <FormattedMessage id='header.until' />
                                &nbsp;
                                {dayjs(suggestions.endDate).format('YYYY-MM-DD')}
                            </React.Fragment>
                        ) : (
                            <FormattedMessage id='header.unlimited' />
                        )}
                    </span>
                )}
            </Link>
        </div>
    );
};
