import { Button, Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class ConfirmRescheduleModal extends Component {
    render() {
        const { visible, reset, confirm } = this.props;

        return (
            <Modal
                cancelText={<FormattedMessage id='order_form_table.confirm_exit_without_changes' />}
                footer={
                    <div className={Styles.footer}>
                        <Button onClick={() => reset()}>
                            <FormattedMessage id='no' />
                        </Button>
                        <Button
                            onClick={() => {
                                confirm();
                                reset();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='yes' />
                        </Button>
                    </div>
                }
                maskClosable={false}
                onCancel={() => reset()}
                visible={visible === MODALS.CONFIRM_RESCHEDULE}
            >
                <FormattedMessage id='confirm_reschedule_repairs' />
            </Modal>
        );
    }
}
