exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__editOrderTaskIcon---3GjGp{cursor:pointer;font-size:25px}.styles-m__searchRow---2nXK7{display:flex;justify-content:flex-end;padding-bottom:15px}.styles-m__commentDiv---ycbab{white-space:nowrap;word-break:break-all;width:130px;text-overflow:ellipsis;overflow:hidden;margin-right:0;padding-right:0}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAIA,qCACI,cAAe,CACf,cACJ,CAEA,6BACI,YAAa,CACb,wBAAyB,CACzB,mBACJ,CAEA,8BACI,kBAAmB,CACnB,oBAAqB,CACrB,WAAY,CACZ,sBAAuB,CACvB,eAAgB,CAChB,cAAe,CACf,eACJ","file":"styles.m.css","sourcesContent":[".myTasks {\n   \n}\n\n.editOrderTaskIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.searchRow {\n    display: flex;\n    justify-content: flex-end;\n    padding-bottom: 15px;\n}\n\n.commentDiv {\n    white-space: nowrap;\n    word-break: break-all;\n    width: 130px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    margin-right: 0;\n    padding-right: 0;\n}\n"]}]);

// Exports
exports.locals = {
	"myTasks": "styles-m__myTasks---xIYiF",
	"editOrderTaskIcon": "styles-m__editOrderTaskIcon---3GjGp",
	"searchRow": "styles-m__searchRow---2nXK7",
	"commentDiv": "styles-m__commentDiv---ycbab"
};