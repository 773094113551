import { CheckCircleFilled, ClockCircleFilled } from '@ant-design/icons';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';

// Choose width for each col, (100% in total or less than 100% if 'auto' is present)
const defWidth = {
    storeDocIdCol: '12%',
    typeCol: '10%',
    datetimeCol: '12%',
    payUntilDatetimeCol: '12%',
    docNumberCol: 'auto',
    sumCol: '12%',
    sumWithCashOrdersCol: '12%',
    actionsCol: '5%'
};

const mask = '0,0.00';

const INCOME = 'INCOME';
const EXPENSE = 'EXPENSE';
const RESERVE = 'RESERVE';
const SUPPLIER = 'SUPPLIER';
const CLIENT = 'CLIENT';
const INVENTORY = 'INVENTORY';
const OWN_CONSUMPTION = 'OWN_CONSUMPTION';
const TRANSFER = 'TRANSFER';
const ADJUSTMENT = 'ADJUSTMENT';
const ORDERINCOME = 'ORDERINCOME';
const ORDER = 'ORDER';
const NEW = 'NEW';
const DONE = 'DONE';
const INTER_CO_DONE = 'INTER_CO_DONE';

export function columnsConfig(props) {
    const { formatMessage, storeDocsDeliveryStats, page } = props;

    const indexCol = {
        title: '№',
        width: 20,
        dataIndex: 'key',
        key: 'key',
        render: (key, obj, index) => {
            return <div>{index + 1 + (page - 1) * 25}</div>;
        }
    };

    const orderCol = {
        title: <FormattedMessage id='storage_document.document' />,
        width: 100,
        dataIndex: 'supplierDocNumber',
        key: 'supplierDocNumber',
        render: (_, document) =>
            isGrantAccessed(props.user, grants.WAREHOUSE_DOCUMENT) ? (
                <React.Fragment>
                    <Link to={`${book.storageDocument}/${document.id}`}>{document.documentNumber}</Link>
                </React.Fragment>
            ) : (
                <React.Fragment>{document.documentNumber}</React.Fragment>
            )
    };

    const datetimeCol = {
        title: <FormattedMessage id='orders.creation_date' />,
        dataIndex: 'createdDatetime',
        key: 'createdDatetime',
        sorter: (a, b) =>
            dayjs(a.createdDatetime).isAfter(b.createdDatetime)
                ? 1
                : dayjs(b.createdDatetime).isAfter(a.createdDatetime)
                ? -1
                : 0,
        width: 80,
        render: (_, document) => (
            <div>
                {document.createdDatetime ? (
                    dayjs(document.createdDatetime).format('DD.MM.YYYY HH:mm')
                ) : (
                    <FormattedMessage id='long_dash' />
                )}
            </div>
        )
    };

    const counterpartyCol = {
        title: <FormattedMessage id='storage_document.counterparty' />,
        key: 'businessSupplier',
        width: 80,
        render: (_, document) => (
            <div>
                {document.counterpartEmployeeName ||
                    document.counterpartBusinessSupplierName ||
                    document.counterpartClientName || <FormattedMessage id='long_dash' />}
            </div>
        )
    };

    const counterpartyTypeCol = {
        title: <FormattedMessage id='storage_document.counterparty_type' />,
        dataIndex: 'counterpartBusinessSupplierId',
        key: 'counterpartBusinessSupplierId',
        width: 80,
        render: (_, document) => (
            <div>
                {document.counterpartBusinessSupplierId ? (
                    <FormattedMessage id='storage_document.supplier' />
                ) : document.counterpartClientId || document.clientId ? (
                    <FormattedMessage id='storage_document.client' />
                ) : document.counterpartEmployeeId ? (
                    <FormattedMessage id='storage_document.own_consumpty' />
                ) : document.counterpartWarehouseId && document.type == EXPENSE ? (
                    <FormattedMessage id='storage_document.inventory' />
                ) : (
                    <FormattedMessage id='long_dash' />
                )}
            </div>
        )
    };

    const sumCol = {
        title: <FormattedMessage id='orders.sum' />,
        dataIndex: 'sum',
        key: 'sum',
        sorter: (a, b) => a.sum - b.sum,
        width: 60,
        render: (_, document) => (
            <Numeral currency={getCurrency()} mask='0,0.00' nullText='0'>
                {document.sum}
            </Numeral>
        )
    };

    const documentTypeCol = {
        title: <FormattedMessage id='storage_document.document_type' />,
        dataIndex: 'type',
        key: 'type',
        width: 80,
        render: (_, document) => {
            return (
                <div>
                    <FormattedMessage id={`storage_document.docType.${document.type}.${document.documentType}`} />
                </div>
            );
        }
    };

    const documentStatusCol = {
        title: <FormattedMessage id='storage_document.document_status' />,
        dataIndex: 'status',
        key: 'status',
        width: 80,
        render: (_, document) => (
            <div>
                {document.status == DONE || document.status == INTER_CO_DONE ? (
                    <React.Fragment>
                        <FormattedMessage id='storage_document.status_confirmed' />{' '}
                        <CheckCircleFilled style={{ color: 'var(--green)' }} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <FormattedMessage id='storage_document.status_created' />{' '}
                        <ClockCircleFilled style={{ color: 'var(--orange)' }} />
                    </React.Fragment>
                )}
            </div>
        )
    };

    return [
        indexCol,
        orderCol,
        datetimeCol,
        counterpartyCol,
        counterpartyTypeCol,
        sumCol,
        documentTypeCol,
        documentStatusCol
    ];
}
