/* eslint-disable complexity */
import { counterpartValues, documentTypeValues } from 'modals/EmployeeTaskModal/constants';
import _ from 'lodash';

export function getEmployeeTaskEntityByType(employeeTaskDataEntity) {
    // Can be ORDER(with client), STORE_DOC(with client or supplier), CASH_ORDER(with client, employee, supplier)

    // TODO omit client, supplier, employee
    switch (employeeTaskDataEntity.documentType) {
        case documentTypeValues.ORDER:
            return {
                ..._.omit(employeeTaskDataEntity, [
                    'counterpartyType',
                    'documentType',
                    'cashOrderId',
                    'storeDocId'
                ])
            };
        case documentTypeValues.STORE_DOC:
            return {
                ..._.omit(employeeTaskDataEntity, [
                    'counterpartyType',
                    'documentType',
                    'cashOrderId',
                    'orderId'
                ])
            };
        case documentTypeValues.CASH_ORDER:
            if (employeeTaskDataEntity.counterpartyType === counterpartValues.CLIENT) {
                return {
                    ..._.omit(employeeTaskDataEntity, [
                        'counterpartyType',
                        'documentType',
                        'orderId',
                        'storeDocId'
                    ])
                };
            }
            if (employeeTaskDataEntity.counterpartyType === counterpartValues.EMPLOYEE) {
                return {
                    ..._.omit(employeeTaskDataEntity, [
                        'counterpartyType',
                        'documentType',
                        'orderId',
                        'storeDocId'
                    ])
                };
            }
            if (employeeTaskDataEntity.counterpartyType === counterpartValues.BUSINESS_SUPPLIER) {
                return {
                    ..._.omit(employeeTaskDataEntity, [
                        'counterpartyType',
                        'documentType',
                        'orderId',
                        'storeDocId'
                    ])
                };
            }

            return {};
        default:
            break;
    }

    const {
        counterpartyType,
        counterpartEmployeeId,
        counterpartClientId,
        counterpartBusinessSupplierId
    } = employeeTaskDataEntity;

    if (counterpartyType === counterpartValues.CLIENT && counterpartClientId) {
        // client

        return {
            ..._.omit(employeeTaskDataEntity, [
                'counterpartyType',
                'documentType',
                'counterpartEmployeeId',
                'counterpartBusinessSupplierId'
            ])
        };
    }
    if (counterpartyType === counterpartValues.EMPLOYEE && counterpartEmployeeId) {
        // employee

        return {
            ..._.omit(employeeTaskDataEntity, [
                'counterpartyType',
                'documentType',
                'counterpartClientId',
                'counterpartBusinessSupplierId'
            ])
        };
    }
    if (counterpartyType === counterpartValues.BUSINESS_SUPPLIER && counterpartBusinessSupplierId) {
        // supplier

        return {
            ..._.omit(employeeTaskDataEntity, [
                'counterpartyType',
                'documentType',
                'counterpartClientId',
                'counterpartEmployeeId'
            ])
        };
    }
    // nothing selected

    return {
        ..._.omit(employeeTaskDataEntity, [
            'counterpartyType',
            'documentType',
            'counterpartClientId',
            'counterpartEmployeeId',
            'counterpartBusinessSupplierId'
        ])
    };
}
