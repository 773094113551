import { SyncOutlined } from '@ant-design/icons';
import { Modal, notification, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description
    });
};

/**
 * This modal is used to transfer car between clients
 */

@injectIntl
export default class AggregateTransferModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            clients: [],
            clientId: undefined,
            newOwnerId: undefined,
            vehicles: [],
            vehicleId: undefined,
            searchValue: '',
            clientList: [],
            currentClient: {}
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            clients: [],
            newOwnerId: undefined,
            vehicles: [],
            vehicleId: undefined
        });
    };

    handleOk = async () => {
        const { id, brandId, aggregateId } = this.props;
        try {
            await fetchAPI(
                'PUT',
                `/client/aggregates/${id}`,
                null,
                { aggregateId, brandId, clientId: this.state.newOwnerId },
                { handleErrorInternally: true }
            );
            window.location.reload();
            this.handleCancel();
        } catch (error) {
            notification.error({
                message: this.props.intl.formatMessage({
                    id: 'AGGREGATE_ARE_IN_USE'
                })
            });
        }
    };

    getClientList = async value => {
        const data = await fetchAPI('GET', '/clients', { filters: { query: value } }, null, {
            handleErrorInternally: true
        });
        this.setState({
            clientList: data.clients
        });
    };

    // getClientList = value => {
    //     const that = this;
    //     const token = localStorage.getItem('_my.carbook.pro_token');
    //     const url = `${__API_URL__}/clients?filters&query=${value}`;
    //     fetch(url, {
    //         method: 'GET',
    //         headers: {
    //             Authorization: token
    //         }
    //     })
    //         .then(function (response) {
    //             if (response.status !== 200) {
    //                 return Promise.reject(new Error(response.statusText));
    //             }

    //             return Promise.resolve(response);
    //         })
    //         .then(function (response) {
    //             return response.json();
    //         })
    //         .then(function (data) {
    //             that.setState({
    //                 clientList: data
    //             });
    //         })
    //         .catch(function (error) {
    //             console.log('error', error);
    //         });
    // };

    // fetchData() {
    //     const that = this;
    //     const token = localStorage.getItem('_my.carbook.pro_token');
    //     const url = `${__API_URL__}/clients`;
    //     fetch(url, {
    //         method: 'GET',
    //         headers: {
    //             Authorization: token
    //         }
    //     })
    //         .then(function (response) {
    //             if (response.status !== 200) {
    //                 return Promise.reject(new Error(response.statusText));
    //             }

    //             return Promise.resolve(response);
    //         })
    //         .then(function (response) {
    //             return response.json();
    //         })
    //         .then(function (data) {
    //             that.setState({
    //                 clients: data.clients
    //             });
    //         })
    //         .catch(function (error) {
    //             console.log('error', error);
    //         });
    // }

    render() {
        const { visible, clients, vehicles, newOwnerId, clientId, vehicleId, searchValue, clientList, currentClient } =
            this.state;

        return (
            <React.Fragment>
                <SyncOutlined
                    className={Styles.carTransferIcon}
                    onClick={() => {
                        this.setState({
                            visible: true,
                            clientId: Number(this.props.clientId)
                        });
                    }}
                />
                <Modal
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    visible={visible}
                    width='30%'
                >
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <div style={{ width: '100%' }}>
                            <span style={{ fontWeight: 500 }}>
                                <FormattedMessage id='vehicle_transfer.to' />
                            </span>
                            <Select
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                filterOption={(input, option) => {
                                    const searchValue = String(option.props.children)
                                        .toLowerCase()
                                        .replace(/[+\-()., ]/g, '');
                                    const inputValue = input.toLowerCase();

                                    return searchValue.indexOf(inputValue) >= 0;
                                }}
                                onBlur={() => {
                                    this.setState({
                                        searchValue: ''
                                    });
                                }}
                                onChange={(value, option) => {
                                    this.setState({
                                        newOwnerId: value
                                    });
                                }}
                                onSearch={input => {
                                    this.setState({
                                        searchValue: input
                                    });
                                    if (input.length > 2) this.getClientList(input);
                                }}
                                showSearch
                                value={newOwnerId}
                            >
                                {searchValue.length > 2
                                    ? clientList
                                          .filter(({ clientId }) => clientId)
                                          .map(({ clientId, name, surname, phones }, key) => (
                                              <Option key={key} value={clientId}>
                                                  {name} {surname} {phones ? phones[0] : undefined}
                                              </Option>
                                          ))
                                    : []}
                            </Select>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
