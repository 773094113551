import {
    FilterOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SearchOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Input,
    Modal,
    notification,
    Radio,
    Table,
    Tooltip,
    Tree,
    TreeSelect
} from 'antd';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import { get, isFunction } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, filterTreeNodeByPart } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modalProps: state.modals.modalProps
});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class LaborsNormHourModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            autoExpandParent: true,
            page: 1,
            tecdoc: true
        };

        this.columns = [
            {
                title: <FormattedMessage id='my_normative_page.name' />,
                key: 'laborName',
                dataIndex: 'laborName',
                sorter: (a, b) =>
                    String(a.nameWithStoreGroup).localeCompare(String(b.nameWithStoreGroup))
            },
            {
                title: <FormattedMessage id='add_client_form.modification' />,
                key: 'modification',
                sorter: (a, b) => String(a.modification).localeCompare(String(b.modification)),
                render: ({ modification, year }) =>
                    `${modification} (${year} ${props.intl.formatMessage({ id: 'yr.' })}) `
            },
            {
                title: <FormattedMessage id='locations.region' />,
                key: 'regionIdName',
                sorter: (a, b) => String(a.regionName).localeCompare(String(b.regionName)),
                render: ({ regionName, regionId }) => `${regionName} (#${regionId})`
            },
            {
                title: <FormattedMessage id='my_normative_page.norm' />,
                key: 'hours',
                dataIndex: 'hours',
                className: 'columnAlignedRight',
                sorter: (a, b) => b.hours - a.hours,
                render: data => (
                    <React.Fragment>
                        {data} <FormattedMessage id='order_form_table.hours_short' />
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='my_normative_page.price' />,
                key: 'price',
                dataIndex: 'price',
                className: 'columnAlignedRight',
                sorter: (a, b) => b.price - a.price
            },
            {
                title: <FormattedMessage id='my_normative_page.sum' />,
                key: 'sum',
                className: 'columnAlignedRight',
                sorter: (a, b) => b.sum - a.sum,
                render: laborsStandart =>
                    (Number(laborsStandart.price) * Number(laborsStandart.hours)).toFixed(2)
            },
            {
                key: 'select',
                className: 'columnAlignedRight',
                render: row => {
                    return (
                        <Button
                            data-qa='button_columnt_select_labors_norm_hours_modal'
                            onClick={() =>
                                this.handleOk({
                                    price: row.price,
                                    hours: row.hours,
                                    storeGroupId: row.storeGroupId
                                })
                            }
                            type='primary'
                        >
                            <FormattedMessage id='select' />
                        </Button>
                    );
                }
            }
        ];

        this.tecdocColumns = [
            {
                title: <FormattedMessage id='order_form_table.service_type' />,
                key: 'kortext',
                dataIndex: 'kortext',
                render: (data, elem) => {
                    return <span style={{ textTransform: 'capitalize' }}>{data}</span>;
                }
            },
            {
                title: <FormattedMessage id='services_table.service_type' />,
                key: 'itemmptext',
                dataIndex: 'itemmptext',
                render: (data, elem) => {
                    return <span style={{ textTransform: 'capitalize' }}>{data}</span>;
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'qualcoltext',
                dataIndex: 'qualcoltext',
                render: (data, elem) => {
                    return <span style={{ textTransform: 'capitalize' }}>{data}</span>;
                }
            },
            {
                title: <FormattedMessage id='add_client_form.modification' />,
                key: 'modification',
                ...this.getColumnSearchProps('modificationQuery'),
                render: ({ modification, madeFrom, madeUntil }) =>
                    `${modification} (${dayjs(madeFrom).format('YYYY')}-${
                        madeUntil ? dayjs(madeUntil).format('YYYY') : dayjs().format('YYYY')
                    } ${props.intl.formatMessage({ id: 'yr.' })}) `
            },
            {
                title: <FormattedMessage id='services_table.norm_hours' />,
                key: 'worktime',
                dataIndex: 'worktime',
                className: 'columnAlignedRight',
                render: data => (
                    <React.Fragment>
                        {Number(data).toFixed(2)}{' '}
                        <FormattedMessage id='order_form_table.hours_short' />
                    </React.Fragment>
                )
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                dataIndex: 'price',
                className: 'columnAlignedRight',
                render: data => Number(data).toFixed(2)
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'sum',
                className: 'columnAlignedRight',
                render: row => Number(row.price * row.worktime).toFixed(2)
            },
            {
                key: 'select',
                className: 'columnAlignedRight',
                render: (data, row) => {
                    return (
                        <Button
                            data-qa='button_tecdoc_columns_select_labors_norm_hours_modal'
                            onClick={() =>
                                this.handleOk({
                                    hours: row.worktime,
                                    storeGroupId: row.storeGroupId
                                })
                            }
                            type='primary'
                        >
                            <FormattedMessage id='select' />
                        </Button>
                    );
                }
            }
        ];
    }

    getColumnSearchProps = dataIndexes => {
        return {
            filtered: Boolean(get(this.state, dataIndexes, false)),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        data-qa='input_filter_labors_norm_hours_modal'
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        placeholder={this.props.intl.formatMessage({ id: 'search' })}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        value={selectedKeys[0]}
                    />
                    <Button
                        data-qa='button_search_filter_labors_norm_hours_modal'
                        icon={<SearchOutlined />}
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndexes)}
                        size='small'
                        style={{ width: 90, marginRight: 8 }}
                        type='primary'
                    >
                        <FormattedMessage id='search' />
                    </Button>
                    <Button
                        data-qa='button_reset_filter_labors_norm_hours_modal'
                        onClick={() => this.handleReset(dataIndexes, clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        <FormattedMessage id='reset' />
                    </Button>
                </div>
            ),
            filterIcon: filtered => (
                <FilterOutlined style={{ color: filtered ? 'var(--primary)' : undefined }} />
            ),

            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            }
        };
    };

    handleSearch = async (selectedKeys, confirm, dataIndexes) => {
        this.state[`${dataIndexes}`] = selectedKeys[0];
        await this.setState({});
        this.fetchData();
    };

    handleReset = async (dataIndexes, clearFilters) => {
        clearFilters();
        delete this.state[`${dataIndexes}`];
        await this.setState({});
        this.fetchData();
    };

    fetchData = () => {
        const { tecdoc } = this.state;
        if (tecdoc) {
            this.fetchTecdocNormHours();
        } else {
            this.fetchNormHours();
        }
    };

    fetchNormHours = async () => {
        this.setState({
            laborTimes: undefined
        });

        const {
            selectedVehicle,
            modalProps: { laborId }
        } = this.props;
        const { modificationQuery, otherStations, otherModifications, storeGroupId } = this.state;

        const selectedVehicleFields = otherModifications
            ? {
                  modelIds: `[ ${get(selectedVehicle, 'modelId')} ]`
              }
            : {
                  modificationIds: `[ ${get(selectedVehicle, 'modificationId')} ]`,
                  bodyId: get(selectedVehicle, 'bodyId')
              };

        const { data: laborTimes } = await fetchAPI(
            'GET',
            'labor_standarts',
            {
                ...selectedVehicleFields,
                storeGroupId,
                laborId,
                // modificationQuery,
                otherStations
            },
            null,
            { handleErrorInternally: true }
        );

        // if(!laborTimes.length) {
        //     this.fetchTecdocNormHours();
        // } else {
        //     this.setState({ laborTimes })
        // }

        this.setState({
            laborTimes,
            tecdoc: false
        });
        console.log(selectedVehicleFields);
    };

    fetchTecdocNormHours = async () => {
        try {
            this.setState({
                laborTimes: undefined,
                otherStations: false
            });

            const { selectedVehicle, tecdocId } = this.props;
            const { storeGroupId, page, otherModifications, modificationQuery } = this.state;

            const selectedVehicleFields = otherModifications
                ? {
                      modelIds: `[ ${get(selectedVehicle, 'modelId')} ]`
                  }
                : {
                      tecdocId
                  };

            const {
                laborTimes,
                priceOfNormHour,
                stats: { count }
            } = await fetchAPI(
                'GET',
                'tecdoc/labor_times',
                {
                    ...selectedVehicleFields,

                    includeChildStoreGroups: true,
                    storeGroupId,
                    modificationQuery,

                    page,
                    pageSize: 12
                },
                null,
                { handleErrorInternally: true }
            );

            laborTimes.map((elem, key) => {
                elem.price = Number(priceOfNormHour);
                elem.worktime = Number(elem.worktime);
                elem.id = key;
            });

            this.setState({
                laborTimes,
                count
            });
        } catch (e) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'error' })
            });
            this.setState({
                laborTimes: [],
                count: 0,
                page: 1
            });
        }
    };

    handleOk = data => {
        const {
            modalProps: { onSelect }
        } = this.props;
        const { normHourPrice } = this.state;
        isFunction(onSelect)
            ? onSelect({
                  ...data,
                  normHourPrice
              })
            : void 0;
        this.handleCancel();
    };

    handleCancel = () => {
        const { resetModal } = this.props;
        this.setState({
            laborTimes: undefined,
            otherStations: false,
            otherModifications: false,
            autoExpandParent: true,
            tecdoc: true,
            page: 1,
            count: 0
        });
        resetModal();
    };

    componentDidMount = async () => {
        const { region, normHourPrice } = await fetchAPI('GET', 'business');
        this.setState({
            normHourPrice: Number(normHourPrice),
            regionId: region
        });
    };

    componentDidUpdate = async prevProps => {
        const {
            visible,
            modalProps: { storeGroupId }
        } = this.props;
        if (visible === MODALS.NORM_HOURS_MODAL && prevProps.visible !== MODALS.NORM_HOURS_MODAL) {
            await this.setState({
                storeGroupId: storeGroupId || 1000000,
                expandedKeys: [String(storeGroupId)]
            });
            this.fetchData();
            const storeGroups = await fetchAPI('GET', 'store_groups', {
                tecdocId: this.props.tecdocId
            });
            this.setState({
                storeGroups: this.buildStoreGroupsTree(storeGroups)
            });
        }
    };

    buildStoreGroupsTree(storeGroups) {
        const {
            intl: { formatMessage }
        } = this.props;
        const pc = formatMessage({ id: 'pc' });
        const treeData = [];
        for (let i = 0; i < storeGroups.length; i++) {
            const parentGroup = storeGroups[i];
            treeData.push({
                title: `${parentGroup.name} (#${parentGroup.id}) ${
                    parentGroup.laborStandartsCount
                        ? `/ ${parentGroup.laborStandartsCount} ${pc}`
                        : ''
                }`,
                name: parentGroup.singleName,
                value: parentGroup.id,
                className: Styles.groupTreeOption,
                key: parentGroup.id,
                // selectable: false,
                children: [],
                multiplier: parentGroup.priceGroupMultiplier
            });
            for (let j = 0; j < parentGroup.childGroups.length; j++) {
                const childGroup = parentGroup.childGroups[j];
                treeData[i].children.push({
                    title: `${childGroup.name} (#${childGroup.id}) ${
                        childGroup.laborStandartsCount
                            ? `/ ${childGroup.laborStandartsCount} ${pc}`
                            : ''
                    }`,
                    name: childGroup.singleName,
                    value: childGroup.id,
                    className: Styles.groupTreeOption,
                    key: childGroup.id,
                    // selectable: false,
                    children: [],
                    multiplier: childGroup.priceGroupMultiplier
                });
                for (let k = 0; k < childGroup.childGroups.length; k++) {
                    const lastNode = childGroup.childGroups[k];
                    treeData[i].children[j].children.push({
                        title: `${lastNode.name} (#${lastNode.id}) ${
                            lastNode.laborStandartsCount
                                ? `/ ${lastNode.laborStandartsCount} ${pc}`
                                : ''
                        }`,
                        name: lastNode.singleName,
                        value: lastNode.id,
                        className: Styles.groupTreeOption,
                        key: lastNode.id,
                        children: [],
                        multiplier: lastNode.priceGroupMultiplier
                    });
                    for (let l = 0; l < lastNode.childGroups.length; l++) {
                        const elem = lastNode.childGroups[l];
                        treeData[i].children[j].children[k].children.push({
                            title: `${elem.name} (#${elem.id}) ${
                                elem.laborStandartsCount
                                    ? `/ ${elem.laborStandartsCount} ${pc}`
                                    : ''
                            }`,
                            name: elem.singleName,
                            value: elem.id,
                            className: Styles.groupTreeOption,
                            key: elem.id,
                            multiplier: elem.priceGroupMultiplier
                        });
                    }
                }
            }
        }

        return treeData;
    }

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false
        });
    };

    render() {
        const {
            visible,
            detailsTreeData,
            selectedVehicle,
            intl: { formatMessage }
        } = this.props;
        const {
            laborTimes,
            otherStations,
            otherModifications,
            storeGroupId,
            regionId,
            expandedKeys,
            autoExpandParent,
            folded,
            page,
            count,
            tecdoc,
            storeGroups
        } = this.state;

        const foldedClassname = folded ? ' folded' : '';

        return (
            <Modal
                footer={null}
                maskClosable={false}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='services_table.norm_hour' />}
                visible={visible === MODALS.NORM_HOURS_MODAL}
                width='95%'
            >
                <div className={Styles.settingsBlock}>
                    <div className={Styles.treeSelectBlock + foldedClassname}>
                        <TreeSelect
                            className={Styles.treeSelect + foldedClassname}
                            data-qa='tree_select_horm_hour_labors_norm_hours_modal'
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999' }}
                            filterTreeNode={filterTreeNodeByPart}
                            onChange={async storeGroupId => {
                                await this.setState({
                                    storeGroupId,
                                    expandedKeys: [String(storeGroupId)],
                                    autoExpandParent: true,
                                    page: 1
                                });
                                this.fetchData();
                            }}
                            placeholder={formatMessage({ id: 'services_table.store_group' })}
                            showSearch
                            treeData={storeGroups || detailsTreeData}
                            value={storeGroupId}
                        />
                        <Button
                            className={Styles.treeSelectButton}
                            data-qa='button_tree_select_folded_labors_norm_hours_modal'
                            onClick={() => this.setState({ folded: !folded })}
                            type={folded ? 'primary' : null}
                        >
                            {folded ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button>
                    </div>
                    <div className={Styles.checkboxBlock + foldedClassname}>
                        <Radio.Group
                            buttonStyle='solid'
                            className={Styles.tecdocRadio}
                            data-qa='radio_group_tecdoc_labors_norm_hours_modal'
                            onChange={async e => {
                                await this.setState({
                                    tecdoc: Boolean(e.target.value),
                                    page: 1
                                });
                                this.fetchData();
                            }}
                            value={tecdoc}
                        >
                            <Radio.Button value={false}>
                                <FormattedMessage id='norm_hours_modal.ourselfe' />
                            </Radio.Button>
                            <Radio.Button value>
                                <FormattedMessage id='norm_hours_modal.fromTheManufacturer' />
                            </Radio.Button>
                        </Radio.Group>
                        <div>
                            {!tecdoc && (
                                <Tooltip
                                    className={Styles.padCheckbox}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    title={
                                        regionId
                                            ? `${formatMessage({
                                                  id: 'locations.region'
                                              })}: ${regionId}`
                                            : null
                                    }
                                >
                                    <FormattedMessage id='norm_hours_modal.otherStations' />
                                    <Checkbox
                                        checked={otherStations}
                                        className={Styles.checkbox}
                                        data-qa='checkbox_other_stations_labors_norm_hours_modal'
                                        onChange={async () => {
                                            await this.setState({
                                                otherStations: !otherStations,
                                                page: 1
                                            });
                                            this.fetchData();
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip
                                className={Styles.padCheckbox}
                                getPopupContainer={trigger => trigger.parentNode}
                                title={`${formatMessage({ id: 'datepicker.now' })}: ${get(
                                    selectedVehicle,
                                    'make'
                                )} ${get(selectedVehicle, 'model')} ${get(
                                    selectedVehicle,
                                    'modification'
                                )}`}
                            >
                                <FormattedMessage id='norm_hours_modal.otherModifications' />
                                <Checkbox
                                    checked={otherModifications}
                                    className={Styles.checkbox}
                                    data-qa='checkbox_other_modifications_labors_norm_hours_modal'
                                    onChange={async () => {
                                        await this.setState({
                                            otherModifications: !otherModifications,
                                            folded: !otherModifications,
                                            page: 1
                                        });
                                        this.fetchData();
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={Styles.content}>
                    <Tree
                        autoExpandParent={autoExpandParent}
                        className={Styles.tree + foldedClassname}
                        data-qa='tree_element_group_id_labors_norm_hours_modal'
                        expandedKeys={expandedKeys}
                        onExpand={this.onExpand}
                        onSelect={async selectedKeys => {
                            const elementGroupId = selectedKeys.length
                                ? Number(selectedKeys[0])
                                : storeGroupId;
                            await this.setState({
                                storeGroupId: elementGroupId,
                                page: 1
                            });
                            this.fetchData();
                        }}
                        selectedKeys={[String(storeGroupId)]}
                        showLine
                        treeData={storeGroups || detailsTreeData}
                    />
                    <Table
                        bordered
                        className={Styles.table + foldedClassname}
                        columns={tecdoc ? this.tecdocColumns : this.columns}
                        dataSource={laborTimes || []}
                        getPopupContainer={trigger => trigger.parentNode}
                        loading={!laborTimes}
                        pagination={
                            tecdoc
                                ? {
                                      pageSize: 15,
                                      size: 'small',
                                      total: Math.ceil(count / 12) * 12,
                                      hideOnSinglePage: true,
                                      current: page,
                                      onChange: async page => {
                                          await this.setState({ page });
                                          this.fetchData();
                                      }
                                  }
                                : {
                                      pageSize: 15,
                                      size: 'small',
                                      hideOnSinglePage: true,
                                      total: Math.ceil(get(laborTimes, 'length', 0) / 15) * 15
                                  }
                        }
                        rowKey='id'
                        size='small'
                    />
                </div>
            </Modal>
        );
    }
}

export default LaborsNormHourModal;
