import { Catcher } from 'commons';
import { EditableTable } from 'components';
import { createService, deleteService, updateService } from 'core/forms/servicesForm/duck';
import { setFilters, stateSelector } from 'core/servicesSuggestions/duck';
import { ServicesForm } from 'forms';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapDispatchToProps = {
    setFilters,
    createService,
    updateService,
    deleteService
};

const mapStateToProps = state => ({
    loading: state.ui.suggestionsLoading,
    ...stateSelector(state)
});

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ServiceContainer extends Component {
    render() {
        const {
            loading,
            createService,
            updateService,
            deleteService,
            setFilters,
            details,
            filters,
            servicesPartsSuggestions: {
                stats: { count },
                list
            }
        } = this.props;

        return (
            <Catcher>
                <div style={{ paddingBottom: 18 }}>
                    <ServicesForm />
                </div>
                {list ? (
                    <EditableTable
                        count={count}
                        createService={createService}
                        data={list}
                        deleteService={deleteService}
                        details={details}
                        filters={filters}
                        loading={loading}
                        setFilters={setFilters}
                        updateService={updateService}
                    />
                ) : null}
            </Catcher>
        );
    }
}
