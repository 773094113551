import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    brand_id: '20%',
    brand_name: '40%',
    supplier_id: '15%',
    add: '9%',
    edit: '8%',
    delete: '8%'
};

export function columnsConfig(props) {
    const { onEditBrand, onDeleteBrand, onAddBrandCross } = props;

    const brandIdCol = {
        title: <FormattedMessage id='brand.id' />,
        width: defWidth.brand_id,
        key: v4(),
        align: 'right',
        render: val => {
            return <div>{val.brandId}</div>;
        }
    };

    const brandNameCol = {
        title: <FormattedMessage id='brand.name' />,
        width: defWidth.brand_name,
        align: 'left',
        render: val => {
            return <div>{val.brandName}</div>;
        }
    };

    const supplierCol = {
        title: <FormattedMessage id='brand.supplier_id' />,
        width: defWidth.supplier_id,
        align: 'right',
        render: val => {
            return <div>{val.tecdocSupplierId || <FormattedMessage id='long_dash' />}</div>;
        }
    };

    const addCrossCol = {
        width: defWidth.add,
        align: 'center',
        render: val => {
            return (
                <div>
                    <PlusOutlined
                        onClick={() => {
                            onAddBrandCross(val.brandId);
                        }}
                    />
                </div>
            );
        }
    };

    const editCol = {
        width: defWidth.edit,
        align: 'center',
        render: val => {
            return (
                <div>
                    <EditOutlined
                        onClick={() => {
                            onEditBrand(val.brandId);
                        }}
                    />
                </div>
            );
        }
    };

    const deleteCol = {
        width: defWidth.delete,
        align: 'center',
        render: val => {
            return (
                <div>
                    <Popconfirm
                        onConfirm={() => onDeleteBrand(val.brandId)}
                        title={<FormattedMessage id='add_order_form.delete_confirm' />}
                    >
                        <DeleteOutlined />
                    </Popconfirm>
                </div>
            );
        }
    };

    return [brandIdCol, brandNameCol, supplierCol, addCrossCol, editCol, deleteCol];
}
