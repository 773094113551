/**
 * When counterparty is set to CLIENT and we have to select one those options
 * are used to determine the way a client will be found.
 */
const SEARCH_TYPES = Object.freeze({
    FOR_CLIENT: 'FOR_CLIENT',
    FOR_ORDER: 'FOR_ORDER',
    FOR_DOCUMENT: 'FOR_DOCUMENT'
});

export { SEARCH_TYPES };
