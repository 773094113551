import { GoBack, Layout, Spinner } from 'commons';
import { Like, NPS, ReviewRating, ReviewResponse } from 'components';
import { fetchReview, postReviewComplain, postReviewReply } from 'core/review/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    review: state.review,
    isFetching: state.ui.reviewFetching,
    reviewReplyLoading: state.ui.reviewReplyLoading,
    reviewComplaintLoading: state.ui.reviewComplaintLoading
});

const mapDispatchToProps = {
    fetchReview,
    postReviewReply,
    postReviewComplain
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ReviewPage extends Component {
    componentDidMount() {
        this.props.fetchReview(this.props.match.params.id);
    }

    render() {
        const {
            isFetching,
            intl: { formatMessage }
        } = this.props;

        const {
            clientName,
            clientSurname,
            anonymous,
            datetime,
            nps,
            recommended,
            comfort,
            repairDuration,
            repairQuality,
            serviceQuality,
            text,
            vehicleMakeName,
            vehicleModelName,
            vehicleYear,
            visitDatetime,
            orderId,
            orderNum,
            employeeName,
            employeeSurname,
            employeeId,
            clientPhones,
            reply,
            replyText,
            complaint,
            complaintText,
            user
        } = this.props.review;

        return isFetching ? (
            <Spinner spin={isFetching} />
        ) : (
            <Layout
                controls={<GoBack link={book.feedback} />}
                description={
                    <React.Fragment>
                        <FormattedMessage id='order-page.creation_date' />
                        {`: ${dayjs(datetime).format('DD MMMM YYYY, HH:mm')}`}
                    </React.Fragment>
                }
                title={`${formatMessage({ id: 'review-page.title' })}: ${
                    anonymous
                        ? formatMessage({ id: 'reviews-table.anon' })
                        : `${!_.isNil(clientName) ? clientName : ''} ${!_.isNil(clientSurname) ? clientSurname : ''}`
                }`}
            >
                <section className={Styles.reviewHead}>
                    <div className={Styles.npsColumn}>
                        <div className={Styles.dataRow}>
                            <span className={Styles.label}>
                                <FormattedMessage id='reviews-table.visit_date' />:{' '}
                            </span>
                            {dayjs(visitDatetime).format('DD MMMM YYYY')}
                        </div>
                        <div className={Styles.dataRow}>
                            <span className={Styles.label}>
                                <FormattedMessage id='reviews-table.vehicle' />:{' '}
                            </span>
                            {`${!_.isNil(vehicleMakeName) ? vehicleMakeName : ''} ${
                                !_.isNil(vehicleModelName) ? vehicleModelName : ''
                            } (${!_.isNil(vehicleYear) ? vehicleYear : ''})`}
                        </div>
                        <div className={Styles.dataRow}>
                            <span className={Styles.label}>
                                <FormattedMessage id='phone' />:{' '}
                            </span>
                            <a href={`tel:${_.first(clientPhones)}`}>{_.first(clientPhones)}</a>
                        </div>
                        <div className={Styles.dataRow}>
                            <NPS label mode='scale' nps={nps} />
                        </div>
                    </div>
                    <div className={Styles.orderColumn}>
                        <span>
                            <FormattedMessage id='review.order' />:{' '}
                            <Link to={`${book.order}/${orderId}`}>{orderNum}</Link>
                        </span>
                        <span>
                            <FormattedMessage id='review.master' />:{' '}
                            {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST) ? (
                                <Link to={`${book.employeesPage}/${employeeId}`}>
                                    {employeeName} {employeeSurname}
                                </Link>
                            ) : (
                                <div>
                                    {employeeName} {employeeSurname}
                                </div>
                            )}
                        </span>
                    </div>
                </section>
                <section className={Styles.rating}>
                    <ReviewRating
                        comfort={comfort}
                        repairDuration={repairDuration}
                        repairQuality={repairQuality}
                        serviceQuality={serviceQuality}
                    />
                    <Like like={recommended} text />
                </section>
                <section className={Styles.response}>
                    <ReviewResponse
                        complaint={complaint}
                        complaintText={complaintText}
                        id={this.props.match.params.id}
                        postReviewComplain={this.props.postReviewComplain}
                        postReviewReply={this.props.postReviewReply}
                        reply={reply}
                        replyText={replyText}
                        reviewComplaintLoading={this.props.reviewComplaintLoading}
                        reviewReplyLoading={this.props.reviewReplyLoading}
                        text={text}
                    />
                </section>
            </Layout>
        );
    }
}
