/**
 * Available values used to define priority
 */
export const filterValues = {
    NEW: 'NEW',
    INWORK: 'INWORK',
    POSTPONED: 'POSTPONED',
    DELEGATED: 'DELEGATED',
    COMPLETED: 'COMPLETED',
    CANCELED: 'CANCELED',
    ACCEPT: 'ACCEPT',
    HIGH: 'HIGH',
    OVERDUE: 'OVERDUE',
    ALL: 'ALL',
    ONLYBYME: 'ONLYBYME',
    ONLYMINE: 'ONLYMINE'
};
