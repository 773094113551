import dayjs from 'dayjs';
import { mainModuleName } from 'pages/OnlineServiceBookPage/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'changeMileageModal';
const prefix = `cpb/onlineServiceBook/${moduleName}`;

export const SET_MILEAGE_DATE_TIME = `${prefix}/SET_MILEAGE_DATE_TIME`;
export const SET_MILEAGE = `${prefix}/SET_MILEAGE`;
export const SAVE_MILEAGE = `${prefix}/SAVE_MILEAGE`;
export const RESET_FIELDS = `${prefix}/RESET_FIELDS`;

export const SET_CHANGE_MILEAGE_MODAL_VISIBILITY = `${prefix}/SET_CHANGE_MILEAGE_MODAL_VISIBILITY`;

export const DEF_BACK_DATE_FORMAT = 'YYYY.MM.DD';
export const DEF_UI_DATE_FORMAT = 'DD.MM.YYYY';

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    mileageDateTime: dayjs().format(DEF_BACK_DATE_FORMAT),
    mileage: 0,
    changeMileageModalVisibility: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_MILEAGE_DATE_TIME:
            return {
                ...state,
                mileageDateTime: payload
            };

        case SET_MILEAGE:
            return {
                ...state,
                mileage: payload
            };

        case SET_CHANGE_MILEAGE_MODAL_VISIBILITY:
            return {
                ...state,
                changeMileageModalVisibility: payload
            };

        case RESET_FIELDS:
            return {
                ...ReducerState // Drop to default
            };

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectMileageDateTime = state => state[mainModuleName][moduleName].mileageDateTime;
export const selectMileage = state => state[mainModuleName][moduleName].mileage;
export const selectChangeMileageModalVisibility = state =>
    state[mainModuleName][moduleName].changeMileageModalVisibility;

/* ------------------------------------- Action Creators -------------------------- */
export const setMileageDateTime = value => ({
    type: SET_MILEAGE_DATE_TIME,
    payload: value
});
export const setMileage = value => ({
    type: SET_MILEAGE,
    payload: value
});

export const saveMileage = ({ vehicleId }) => ({
    type: SAVE_MILEAGE,
    payload: { vehicleId }
});

/** Reset redux fields */
export const resetFields = () => ({
    type: RESET_FIELDS
});

export const setChangeMileageModalVisibility = value => ({
    type: SET_CHANGE_MILEAGE_MODAL_VISIBILITY,
    payload: value
});
