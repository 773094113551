import { Radio } from 'antd';
import { Catcher } from 'commons';
import { DatePickerField, RangePickerField } from 'forms/_formkit';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export default class DatePickerGroup extends Component {
    static defaultProps = {
        periodGroup: true
    };

    render() {
        const {
            date,
            loading,
            period,
            className,
            onDateChange,
            onDaterangeChange,
            onPeriodChange,
            startDate,
            endDate,
            periodGroup
        } = this.props;

        return (
            <Catcher>
                {!date ? (
                    <RangePickerField
                        endDate={endDate}
                        loading={loading}
                        onChange={onDaterangeChange}
                        startDate={startDate}
                    />
                ) : (
                    <DatePickerField date={date} loading={loading} onChange={onDateChange} />
                )}
                {periodGroup && (
                    <RadioGroup className={className} value={period}>
                        <RadioButton onClick={() => onPeriodChange('day')} value='day'>
                            <FormattedMessage id='day' />
                        </RadioButton>
                        <RadioButton onClick={() => onPeriodChange('week')} value='week'>
                            <FormattedMessage id='week' />
                        </RadioButton>
                        <RadioButton onClick={() => onPeriodChange('month')} value='month'>
                            <FormattedMessage id='month' />
                        </RadioButton>
                    </RadioGroup>
                )}
            </Catcher>
        );
    }
}
