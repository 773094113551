exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__foundEntry---2wXJY{color:#1abb1a}.styles-m__bold---30Nt8{font-weight:700}.styles-m__italic---2GDhW{font-style:italic}.styles-m__iconOk---3FAcE{color:green;font-size:20px}.styles-m__iconCancle---2_JLt{color:red;font-size:20px}.styles-m__optionsDangerIconStyle---3nqYJ{font-size:14px;color:#eb8c86}.styles-m__optionsWarningIconStyle---20iAG{font-size:14px;color:#f0a871}.styles-m__optionsSuccessIconStyle---1HASm{font-size:14px;color:#83c468}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACI,aACJ,CAEA,wBACI,eACJ,CAEA,0BACI,iBACJ,CAEA,0BACI,WAAY,CACZ,cACJ,CAEA,8BACI,SAAU,CACV,cACJ,CAEA,0CACI,cAAe,CACf,aACJ,CAEA,2CACI,cAAe,CACf,aACJ,CAEA,2CACI,cAAe,CACf,aACJ","file":"styles.m.css","sourcesContent":[".foundEntry {\n    color: rgb(26, 187, 26);\n}\n\n.bold {\n    font-weight: 700;\n}\n\n.italic {\n    font-style: italic\n}\n\n.iconOk {\n    color: green;\n    font-size: 20px;\n}\n\n.iconCancle {\n    color: red;\n    font-size: 20px;\n}\n\n.optionsDangerIconStyle {\n    font-size: 14px;\n    color: #eb8c86\n}\n\n.optionsWarningIconStyle {\n    font-size: 14px;\n    color: #f0a871\n}\n\n.optionsSuccessIconStyle {\n    font-size: 14px;\n    color: #83c468\n}"]}]);

// Exports
exports.locals = {
	"foundEntry": "styles-m__foundEntry---2wXJY",
	"bold": "styles-m__bold---30Nt8",
	"italic": "styles-m__italic---2GDhW",
	"iconOk": "styles-m__iconOk---3FAcE",
	"iconCancle": "styles-m__iconCancle---2_JLt",
	"optionsDangerIconStyle": "styles-m__optionsDangerIconStyle---3nqYJ",
	"optionsWarningIconStyle": "styles-m__optionsWarningIconStyle---20iAG",
	"optionsSuccessIconStyle": "styles-m__optionsSuccessIconStyle---1HASm"
};