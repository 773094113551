exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__deleteClientRequisiteIcon---1ZFGN,.styles-m__editClientRequisiteIcon---V52rO,.styles-m__viewClientRequisiteIcon---c-oRH{cursor:pointer;font-size:25px}.styles-m__title---LnGld{margin-left:5px;font-size:22px;white-space:nowrap}.styles-m__header---237KZ{margin-bottom:15px}.styles-m__addClientRequisiteButton---mh-ZP{width:100%}.styles-m__enabledIcon---39vvV{color:var(--enabled);margin:0 auto}.styles-m__disabledIcon---32RB5{color:var(--disabled);margin:0 auto}.styles-m__statusIconContainer---3FGov{text-align:center}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAUA,mIACI,cAAe,CACf,cACJ,CAEA,yBACI,eAAgB,CAChB,cAAe,CACf,kBACJ,CAEA,0BACI,kBACJ,CAEA,4CACI,UACJ,CAEA,+BACI,oBAAqB,CACrB,aACJ,CAEA,gCACI,qBAAsB,CACtB,aACJ,CAEA,uCACI,iBACJ","file":"styles.m.css","sourcesContent":[".editClientRequisiteIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.deleteClientRequisiteIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.viewClientRequisiteIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.title {\n    margin-left: 5px;\n    font-size: 22px;\n    white-space: nowrap;\n}\n\n.header {\n    margin-bottom: 15px;\n}\n\n.addClientRequisiteButton {\n    width: 100%;\n}\n\n.enabledIcon {\n    color: var(--enabled);\n    margin: 0 auto;\n}\n\n.disabledIcon {\n    color: var(--disabled);\n    margin: 0 auto;\n}\n\n.statusIconContainer {\n    text-align: center;\n}\n"]}]);

// Exports
exports.locals = {
	"editClientRequisiteIcon": "styles-m__editClientRequisiteIcon---V52rO",
	"deleteClientRequisiteIcon": "styles-m__deleteClientRequisiteIcon---1ZFGN",
	"viewClientRequisiteIcon": "styles-m__viewClientRequisiteIcon---c-oRH",
	"title": "styles-m__title---LnGld",
	"header": "styles-m__header---237KZ",
	"addClientRequisiteButton": "styles-m__addClientRequisiteButton---mh-ZP",
	"enabledIcon": "styles-m__enabledIcon---39vvV",
	"disabledIcon": "styles-m__disabledIcon---32RB5",
	"statusIconContainer": "styles-m__statusIconContainer---3FGov"
};