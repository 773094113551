import { Modal, Select } from 'antd';
import { TrackingTable } from 'components';
import {
    selectTrackingFilters,
    selectTrackingLoading,
    setTrackingFilters
} from 'core/storage/tracking';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    collapsed: state.ui.collapsed,
    filters: selectTrackingFilters(state),
    loading: selectTrackingLoading(state),
    user: state.auth
});

const mapDispatchToProps = {
    setTrackingFilters
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class StoreProductTrackingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleCancel = () => {
        this.props.hideModal();
    };

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        const { visible, setTrackingFilters, productId } = this.props;
        if (!prevProps.visible && this.props.visible) {
            setTrackingFilters({
                productId,
                showOnlyReserves: true,
                page: 1
            });
        }
    }

    render() {
        const { children, visible, filters, loading } = this.props;

        return (
            <Modal
                footer={null}
                maskClosable={false}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='navigation.products_reserves' />}
                visible={visible}
                width='80%'
                zIndex={9999}
            >
                <TrackingTable filters={filters} loading={loading} type='reserves' />
            </Modal>
        );
    }
}
