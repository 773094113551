import { Form } from '@ant-design/compatible';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Flex, Modal, Select } from 'antd';
import { Barcode, PhoneInput, PhoneNumberInput } from 'components';
import { onChangeEmployeeForm } from 'core/forms/employeeForm/duck';
import dayjs from 'dayjs';
import {
    DecoratedCheckbox,
    DecoratedDatePicker,
    DecoratedInput,
    DecoratedSelect,
    DecoratedTreeSelect
} from 'forms/DecoratedFields';
import _ from 'lodash';
import { filterTreeByActive } from 'pages/AccountPlanPage/utils/filterTreeByActive';
import { getAccountsList } from 'pages/AccountPlanPage/utils/getAccountsList';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withReduxForm2 } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const FormItem = Form.Item;
const { Option } = Select;
const formCheckboxLayout = {
    labelCol: { span: 18 },
    wrapperCol: { span: 6 },
    colon: false
};
const formItemLayout = {
    labelCol: {
        span: 6
        // xs: { span: 24 },
        // sm: { span: 24 },
        // md: { span: 24 },
        // lg: { span: 8 },
        // xl: { span: 6 },
        // xxl: { span: 4 },
    },
    wrapperCol: {
        span: 18
        // xs: { span: 24 },
        // sm: { span: 24 },
        // md: { span: 24 },
        // lg: { span: 16 },
        // xl: { span: 18 },
        // xxl: { span: 20 },
    },
    colon: false
};
const { confirm } = Modal;

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@injectIntl
@withReduxForm2({
    name: 'employeeForm',
    actions: {
        change: onChangeEmployeeForm
    }
})
@connect(mapStateToProps)
export class EmployeeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordField: false,
            tree: [],
            accounts: [],
            done: false
        };
    }

    componentDidMount() {
        this.fetchAccounts();
        const initialAccess = _.get(this.props.initialEmployee, 'managerEnabled');
        this.props.form.setFieldsValue({
            managerEnabled: initialAccess
        });
    }

    componentDidUpdate() {
        const { tree, accounts, done } = this.state;
        if (!_.get(tree, 'length') && _.get(accounts, 'length') && !done) {
            this.filterTree(accounts);
        }
    }

    filterTree = nodes => {
        const filteredNodes = filterTreeByActive(nodes);

        this.setState({
            tree: filteredNodes,
            done: true
        });
    };

    fetchAccounts = async query => {
        const accounts = await getAccountsList(query);
        this.setState({ accounts });
    };

    _setPasswordField = () =>
        this.setState(state => ({
            passwordField: !state.passwordField
        }));

    _renderPasswordField = () => {
        const { adding, initialEmployee, user } = this.props;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { formatMessage } = this.props.intl;
        const managerEnabled = Boolean(getFieldValue('managerEnabled'));

        const isGrantedAccess = !isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA, accesses.ROWO);

        return (
            getFieldValue('managerEnabled') && (
                <div className={this.state.passwordField && Styles.passwordFieldEditing}>
                    {initialEmployee &&
                        (this.state.passwordField ? (
                            <div className={Styles.cancelPasswordControl} onClick={() => this._setPasswordField()}>
                                <CloseOutlined />
                                <FormattedMessage id='cancel' />
                            </div>
                        ) : (
                            <Button
                                className={Styles.changePasswordControl}
                                disabled={isGrantedAccess}
                                onClick={() => this._setPasswordField()}
                            >
                                <FormattedMessage id='employee.change_password' />
                            </Button>
                        ))}
                    {this.state.passwordField && (
                        <DecoratedInput
                            disabled={isGrantedAccess}
                            field='password'
                            fields={{}}
                            formItem
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                            label={
                                adding ? (
                                    <FormattedMessage id='employee.password' />
                                ) : (
                                    <FormattedMessage id='employee.change_password' />
                                )
                            }
                            placeholder={formatMessage({
                                id: 'employee.password.placeholder'
                            })}
                            rules={[
                                {
                                    required: managerEnabled,
                                    min: 6,
                                    message: formatMessage({
                                        id: 'employee.password.lenght'
                                    })
                                }
                            ]}
                            type='password'
                        />
                    )}
                </div>
            )
        );
    };

    render() {
        const { adding, initialEmployee, saveEmployee, fireEmployee, user } = this.props;
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const { formatMessage } = this.props.intl;
        const managerEnabled = Boolean(getFieldValue('managerEnabled'));
        const passwordField = this._renderPasswordField();
        const ppo = getFieldValue('taxService');
        const isGrantedAccess = !isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA, accesses.ROWO);

        return (
            <Form disabled={isGrantedAccess} layout='horizontal'>
                <DecoratedCheckbox
                    disabled={isGrantedAccess}
                    field='managerEnabled'
                    fields={{}}
                    formItem
                    formItemLayout={formCheckboxLayout}
                    getFieldDecorator={getFieldDecorator}
                    hidden
                    initialValue={Boolean(_.get(initialEmployee, 'managerEnabled'))}
                    onChange={() => {
                        setFieldsValue({ managerEnabled: !managerEnabled });
                        this._setPasswordField();
                    }}
                />
                <FormItem
                    disabled={isGrantedAccess}
                    label={<FormattedMessage id='employee.manager_access' />}
                    {...formItemLayout}
                    className={Styles.selectMargin}
                >
                    <Checkbox
                        checked={managerEnabled}
                        disabled={isGrantedAccess}
                        onChange={() => {
                            setFieldsValue({ managerEnabled: !managerEnabled });
                            this._setPasswordField();
                        }}
                    />
                </FormItem>

                {!adding && (
                    <FormItem
                        disabled={isGrantedAccess}
                        label={<FormattedMessage id='navigation.barcode' />}
                        {...formItemLayout}
                        className={Styles.selectMargin}
                    >
                        <DecoratedInput
                            addonAfter={
                                <Barcode
                                    empData={initialEmployee}
                                    iconStyle={{ cursor: 'pointer' }}
                                    onConfirm={saveEmployee}
                                    prefix='EML'
                                    referenceId={_.get(initialEmployee, 'id')}
                                    table='EMPLOYEES'
                                    value={_.get(initialEmployee, 'barcode')}
                                />
                            }
                            disabled={isGrantedAccess}
                            disabled
                            field='barcode'
                            formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(initialEmployee, 'barcode')}
                            placeholder={formatMessage({
                                id: 'navigation.barcode'
                            })}
                            style={{
                                minWidth: 240,
                                color: 'var(--text)'
                            }}
                        />
                    </FormItem>
                )}
                <DecoratedSelect
                    allowClear
                    disabled={isGrantedAccess}
                    field='taxService'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={_.get(initialEmployee, 'taxService')}
                    label={<FormattedMessage id='PPO' />}
                    placeholder={formatMessage({
                        id: 'PPO'
                    })}
                >
                    <Option key='CASHDESK' value='CASHDESK'>
                        Cashdesk
                    </Option>
                    <Option key='CHECKBOX' value='CHECKBOX'>
                        Checkbox
                    </Option>
                </DecoratedSelect>
                {/* {ppo == 'CHECKBOX' && (
                    <DecoratedInput
                        disabled={isForbidden(this.props.user, permissions.ACCESS_CASHIER_CRUD)}
                        field='cashierPincode'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        initialValue={_.get(initialEmployee, 'cashierPincode')}
                        label={<FormattedMessage id='PIN-code' />}
                    />
                )} */}
                <DecoratedInput
                    disabled={isGrantedAccess}
                    field='cashierApiToken'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={_.get(initialEmployee, 'cashierApiToken')}
                    label={
                        ppo !== 'CHECKBOX' ? (
                            <FormattedMessage id='employee.cashier_api_token' />
                        ) : (
                            <FormattedMessage id='PIN-code' />
                        )
                    }
                    placeholder='*****************************'
                />
                <Flex>
                    <div style={{ flex: '0 0 25%', textAlign: 'right' }}>
                        <span style={{ margin: '8px 18px 0 2px', top: '-0.5px', display: 'inline-block' }}>
                            <FormattedMessage id='medoc' />
                        </span>
                    </div>
                    <Flex justify='space-between' style={{ flex: '0 0 75%' }}>
                        <DecoratedInput
                            disabled={isGrantedAccess}
                            field='medocUrl'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(initialEmployee, 'medoc.url')}
                            placeholder={formatMessage({
                                id: 'localhost_example'
                            })}
                        />
                        <DecoratedInput
                            disabled={isGrantedAccess}
                            field='medocLogin'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(initialEmployee, 'medoc.login')}
                            placeholder={formatMessage({
                                id: 'login'
                            })}
                        />
                        <DecoratedInput
                            disabled={isGrantedAccess}
                            field='medocEdrpou'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(initialEmployee, 'medoc.edrpou')}
                            placeholder={formatMessage({
                                id: 'USREOU'
                            })}
                        />
                        <DecoratedInput
                            disabled={isGrantedAccess}
                            field='medocDept'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            getPopupContainer={trigger => trigger.parentNode}
                            initialValue={_.get(initialEmployee, 'medoc.dept')}
                            placeholder={formatMessage({
                                id: 'dept_number'
                            })}
                        />
                    </Flex>
                </Flex>
                <DecoratedDatePicker
                    disabled={isGrantedAccess}
                    field='hireDate'
                    format='YYYY-MM-DD'
                    formatMessage={formatMessage}
                    formItem
                    formItemLayout={formItemLayout}
                    // className={ Styles.selectMargin }
                    getCalendarContainer={trigger => trigger.parentNode}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={initialEmployee && dayjs(initialEmployee.hireDate)}
                    label={<FormattedMessage id='employee.hireDate' />}
                    placeholder={<FormattedMessage id='order_task_modal.deadlineDate_placeholder' />}
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedInput
                    className={Styles.selectMargin}
                    disabled={isGrantedAccess}
                    field='name'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={_.get(initialEmployee, 'name')}
                    label={<FormattedMessage id='employee.name' />}
                    placeholder={formatMessage({
                        id: 'employee.name_placeholder'
                    })}
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <DecoratedInput
                    className={Styles.selectMargin}
                    disabled={isGrantedAccess}
                    field='surname'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={_.get(initialEmployee, 'surname')}
                    label={<FormattedMessage id='employee.surname' />}
                    placeholder={formatMessage({
                        id: 'employee.surname_placeholder'
                    })}
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <FormItem
                    disabled={isGrantedAccess}
                    label={<FormattedMessage id='employee.phone' />}
                    {...formItemLayout}
                    className={Styles.selectMargin}
                >
                    <div style={{ display: 'none' }}>
                        <PhoneNumberInput
                            disabled={isGrantedAccess}
                            fieldName='phone'
                            fieldTitle={<FormattedMessage id='employee.phone' />}
                            form={this.props.form}
                            initialPhoneNumber={_.get(initialEmployee, 'phone')}
                            intl={this.props.intl}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />
                    </div>
                    <PhoneInput
                        disabled={isGrantedAccess}
                        onChange={value => {
                            this.props.form.setFieldsValue({
                                phone: Number(value)
                            });
                        }}
                        value={this.props.form.getFieldValue('phone') || null}
                    />
                </FormItem>
                <DecoratedInput
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    className={Styles.selectMargin}
                    disabled={isGrantedAccess}
                    field='email'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={_.get(initialEmployee, 'email')}
                    label={<FormattedMessage id='employee.email' />}
                    placeholder={formatMessage({
                        id: 'employee.email_placeholder'
                    })}
                    rules={[
                        {
                            required: managerEnabled,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        },
                        managerEnabled && {
                            validator: (rule, value, callback) => {
                                const re =
                                    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                                if (re.test(value)) {
                                    callback();
                                } else {
                                    callback(
                                        new Error(
                                            formatMessage({
                                                id: 'employee.enter_correct_email'
                                            })
                                        )
                                    );
                                }

                                return true;
                            },
                            message: ''
                        }
                    ]}
                />
                {passwordField}
                <DecoratedTreeSelect
                    className={Styles.selectMargin}
                    disabled={isGrantedAccess}
                    field='accountId'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={
                        _.get(initialEmployee, 'accountId') || String(_.get(user, String('employeeAccountId')))
                    }
                    label={<FormattedMessage id='new-document-page.item.account' />}
                    // optionValue='id'
                    // hasFeedback
                    optionLabel='title'
                    treeData={this.state.tree}
                />
                <DecoratedInput
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    className={Styles.selectMargin}
                    disabled={isGrantedAccess}
                    field='jobTitle'
                    formItem
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    initialValue={_.get(initialEmployee, 'jobTitle')}
                    label={<FormattedMessage id='employee.jobTitle' />}
                    placeholder={formatMessage({
                        id: 'employee.jobTitle_placeholder'
                    })}
                    rules={[
                        {
                            required: true,
                            max: 2000,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                />
                <div className={Styles.ButtonGroup}>
                    {initialEmployee && !initialEmployee.fireDate ? (
                        <Button
                            disabled={
                                !isGrantAccessed(
                                    this.props.user,
                                    grants.DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA,
                                    accesses.ROWO
                                )
                            }
                            onClick={() => {
                                confirm({
                                    title: `${formatMessage({ id: 'employee.fire_confirm' })}`,
                                    onOk() {
                                        fireEmployee();
                                    }
                                });
                            }}
                            type='danger'
                        >
                            <FormattedMessage id='employee.fire_employee' />
                        </Button>
                    ) : null}
                    {initialEmployee && initialEmployee.fireDate ? (
                        <Button
                            disabled={
                                !isGrantAccessed(
                                    this.props.user,
                                    grants.DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA,
                                    accesses.ROWO
                                )
                            }
                            onClick={() => {
                                confirm({
                                    title: `${formatMessage({ id: 'employee.return_confirm' })}`,
                                    onOk() {
                                        fireEmployee();
                                    }
                                });
                            }}
                            type='danger'
                        >
                            <FormattedMessage id='stock_table.button.return' />
                        </Button>
                    ) : null}
                    <Button
                        disabled={
                            !isGrantAccessed(
                                this.props.user,
                                grants.DIRECTORIES_EMPLOYEE_CARD_GENERAL_DATA,
                                accesses.ROWO
                            )
                        }
                        onClick={() => saveEmployee()}
                        type='primary'
                    >
                        <FormattedMessage id='save' />
                    </Button>
                </div>
            </Form>
        );
    }
}
