import CallsTable from './CallsTable';
import ClientsSearchTable from './ClientsSearchTable';
import ClientsVehiclesTable from './ClientsVehiclesTable';
import DetailsTable from './DetailsTable';
import DiagnosticTable from './DiagnosticTable';
import DiscountPanel from './DiscountPanel';
import HistoryTable from './HistoryTable';
import IntakeTab from './IntakeTab';
import LogsTable from './LogsTable';
import OrderDocumentsTable from './OrderDocumentsTable';
import OrderMediaTab from './OrderMediaTab';
import OrderRegulationsTable from './OrderRegulationsTable';
import OrderTasksTable from './OrderTasksTable';
import OverLookTab from './OverLookTab';
import RecommendationsTable from './RecommendationsTable';
import RequestsTable from './RequestsTable';
import ServicesTable from './ServicesTable';
import StationsTable from './StationsTable';
import StatisticsTab from './StatisticsTab';
import StockTable from './StockTable';
import TasksTable from './TasksTable';
import WorkshopTable from './WorkshopTable';

export {
    CallsTable,
    ClientsSearchTable,
    ClientsVehiclesTable,
    DetailsTable,
    DiagnosticTable,
    DiscountPanel,
    HistoryTable,
    IntakeTab,
    LogsTable,
    OrderDocumentsTable,
    OrderMediaTab,
    OrderRegulationsTable,
    OrderTasksTable,
    OverLookTab,
    RecommendationsTable,
    RequestsTable,
    ServicesTable,
    StationsTable,
    StatisticsTab,
    StockTable,
    TasksTable,
    WorkshopTable
};
