import { Input, Table } from 'antd';
import _ from 'lodash';
import { documentTypeValues } from 'modals/EmployeeTaskModal/constants';
import {
    fetchDocuments,
    selectDocumentFilters,
    selectDocuments,
    selectDocumentsStats,
    selectFetchingDocuments,
    selectFields,
    setDocumentFilters,
    setFields
} from 'modals/EmployeeTaskModal/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    fields: selectFields(state),
    documents: selectDocuments(state),
    stats: selectDocumentsStats(state),
    filters: selectDocumentFilters(state),
    fetchingDocuments: selectFetchingDocuments(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setFields,
    fetchDocuments,
    setDocumentFilters
};

/**
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class DocumentsTable extends Component {
    componentDidMount() {
        this.props.fetchDocuments();
    }

    handleSearch = _.debounce(value => {
        this.props.setDocumentFilters({
            query: _.toLower(value.replace(/[+()]/g, ''))
        });
    }, 1000);

    render() {
        const {
            fields,
            isMobile,
            documents,
            stats,
            filters,
            setDocumentFilters,
            fetchingDocuments,

            intl: { formatMessage }
        } = this.props;

        const pagination = {
            pageSize: filters.pageSize,
            size: 'small',
            total: Math.ceil(stats.count / filters.pageSize) * filters.pageSize,
            current: filters.page,
            onChange: (page, pageSize) => {
                this.props.setDocumentFilters({ page, pageSize });
            }
        };

        const handleSelectCounterPartId = documentId => {
            switch (fields.documentType) {
                case documentTypeValues.ORDER:
                    this.props.setFields({ orderId: documentId });
                    break;
                case documentTypeValues.CASH_ORDER:
                    this.props.setFields({ cashOrderId: documentId });
                    break;
                case documentTypeValues.STORE_DOC:
                    this.props.setFields({ storeDocId: documentId });
                    break;
            }
        };

        const getCounterPartId = () => {
            switch (fields.documentType) {
                case documentTypeValues.ORDER:
                    return fields.orderId;
                case documentTypeValues.CASH_ORDER:
                    return fields.cashOrderId;
                case documentTypeValues.STORE_DOC:
                    return fields.storeDocId;
            }
        };

        return (
            <div className={Styles.tableContainer}>
                <div style={{ marginBottom: 4 }}>
                    <Input
                        allowClear
                        onChange={e => {
                            this.handleSearch(e.target.value);
                        }}
                        placeholder={formatMessage({ id: 'employee_task_modal.search' })}
                    />
                </div>
                <div style={isMobile ? { overflowX: 'auto' } : {}}>
                    <Table
                        bordered
                        className={Styles.table}
                        columns={columnsConfig({ fields })}
                        dataSource={documents}
                        loading={fetchingDocuments}
                        onRow={record => ({
                            onClick: () => {
                                handleSelectCounterPartId(record.id);
                            }
                        })}
                        pagination={pagination}
                        rowClassName={record => (record.id == getCounterPartId() ? Styles.selectedRow : undefined)}
                        rowKey='id'
                        scroll={isMobile && { x: 'auto' }}
                        size='small'
                    />
                </div>
            </div>
        );
    }
}
