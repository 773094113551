import store from 'store/store';
import { fetchAPI } from 'utils';
import { selectToken } from './duck';

/**
 * ***This method is just a wrapper of fetchAPI method from utils. It is customized for OnlineServiceBookPage as it has specific functionality.***
 * \
 * Custom fetchApi method to handle own auth method for each request. Make sure to use this function
 * when fetching data for OnlineServiceBookPage or else you can have a headache.
 * @param {*} method
 * @param {*} endpoint
 * @param {*} query
 * @param {*} body
 * @param {*} otherProps
 * @returns Result of request
 */
export async function fetchRequest(method, endpoint, query, body, otherProps) {
    try {
        const state = store.getState();
        const token = selectToken(state);

        return await fetchAPI(method, endpoint, query, body, {
            ...otherProps,
            handleErrorInternally: true,
            noAuth: true,
            headers: {
                'content-type': 'application/json',
                'Cache-Control': 'no-cache',
                'Access-Control-Request-Headers': '*',
                Authorization: `${token}`
            }
        });
    } catch (error) {
        console.error(error);
    }
}
