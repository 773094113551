import { Form } from '@ant-design/compatible';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { getLocale } from 'utils';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

export class DecoratedDatePicker extends React.PureComponent {
    render() {
        const {
            // formItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            style,

            // formatMessage,
            ranges,
            getFieldDecorator,
            rules,
            field,
            format,
            showTime,
            disabled,
            disabledDate,
            disabledTime,
            getCalendarContainer,
            // placeholder,
            initialValue: initialInitialValue,
            allowClear,

            onChange,
            cnStyles
        } = this.props;
        const initialValue = _.isString(initialInitialValue)
            ? dayjs(initialInitialValue)
            : initialInitialValue;

        const renderRangePicker = (
            <RangePicker
                allowClear={allowClear}
                className={cnStyles}
                format={format}
                getPopupContainer={getCalendarContainer}
                locale={getLocale()}
                onChange={onChange}
                ranges={ranges}
                showTime={showTime}
            />
        );

        const renderDatePicker = (
            <DatePicker
                allowClear={allowClear}
                className={cnStyles}
                disabled={disabled}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                format={format}
                getPopupContainer={getCalendarContainer}
                locale={getLocale()}
                onChange={onChange}
                showTime={showTime}
            />
        );

        let datePicker = void 0;

        if (getFieldDecorator) {
            datePicker = getFieldDecorator(field, {
                ...(initialValue ? { initialValue } : { initialValue: void 0 }),
                rules
            })(ranges ? renderRangePicker : renderDatePicker);
        } else {
            datePicker = ranges ? renderRangePicker : renderDatePicker;
        }

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                label={label}
                style={style}
                {...formItemLayout}
            >
                {datePicker}
            </FormItem>
        ) : (
            datePicker
        );
    }
}
