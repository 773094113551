import { DatePicker } from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

@injectIntl
export default class DatePickerField extends Component {
    state = {
        date: this.props.date
    };

    static getDerivedStateFromProps(props, state) {
        if (props.date !== state.date) {
            return {
                date: props.date
            };
        }

        return null;
    }

    render() {
        const {
            onChange,
            date,
            loading,
            className,
            style,
            allowClear,
            placeholder,
            intl: { formatMessage }
        } = this.props;

        return (
            <DatePicker
                allowClear={allowClear}
                className={className}
                disabled={loading}
                format='dddd, DD MMM YYYY'
                onChange={value => onChange(value)}
                placeholder={
                    placeholder ||
                    formatMessage({
                        id: 'select_date'
                    })
                }
                style={style}
                value={date}
            />
        );
    }
}
