import React, { memo } from 'react';

export const Banner = memo(({ banners }) => {
    return (
        <img
            src={banners[Math.floor(Math.random() * banners.length)].url}
            style={{ margin: '0 24px' }}
        />
    );
});
