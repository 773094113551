import React from 'react';
import { useLocation } from 'react-router-dom';
import { TireFittingRoutes } from 'tireFitting';
import { businessTypes, getBusinessType, getToken } from 'utils';
import Private from './Private';
import Public from './Public';

export default () => {
    const location = useLocation();
    const businessType = getBusinessType();
    const token = getToken();

    const routes = !token ? (
        <Public />
    ) : businessType === businessTypes.TIRE_STATION ? (
        <TireFittingRoutes />
    ) : (
        <Private />
    );

    return routes;
};
