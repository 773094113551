import { notification } from 'antd';
import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    DATE_FORMAT,
    DOWNLOAD_REPORT,
    fetchBusinessRequisitesSuccess,
    fetchClientsSuccess,
    FETCH_BUSINESS_REQUISITES,
    FETCH_CLIENTS,
    selectReportFilters
} from './duck';

export function* downloadReportSaga() {
    while (true) {
        yield take(DOWNLOAD_REPORT);

        const filters = yield select(selectReportFilters);
        filters.date = _.mapValues(filters.date, date => dayjs(date).format(DATE_FORMAT));

        try {
            const response = yield call(
                fetchAPI,
                'GET',
                'report/excel/accounts_receivable',
                filters,
                null,
                { handleErrorInternally: true, rawResponse: true }
            );

            const reportFile = yield response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];

            yield saveAs(reportFile, fileName);
        } catch (err) {
            yield put(emitError(err));
            notification.error({ message: 'Error' });
        }
    }
}

export function* fetchClientsSaga() {
    while (true) {
        try {
            yield take(FETCH_CLIENTS);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'clients', {
                options: {
                    omitStats: true,
                    fields: ['clientId', 'name', 'surname', 'middleName', 'requisites']
                }
            });

            yield put(fetchClientsSuccess(data.clients));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchBusinessRequisitesSaga() {
    while (true) {
        try {
            yield take(FETCH_BUSINESS_REQUISITES);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'businesses/requisites');

            yield put(fetchBusinessRequisitesSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([
        call(downloadReportSaga),
        call(fetchClientsSaga),
        call(fetchBusinessRequisitesSaga)
    ]);
}
