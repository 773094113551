import { Form } from '@ant-design/compatible';
import { Col, Row } from 'antd';
import { DecoratedInput } from 'forms/DecoratedFields';
import { setBrandFields } from 'modals/BrandsModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const FItem = Form.Item;

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setBrandFields
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@Form.create({ name: 'brands_add_form' })
export default class AddVehicleFormClass extends React.Component {
    constructor(props) {
        super(props);

        const { getFormRefCB } = this.props;
        getFormRefCB && getFormRefCB(this.props.form); // Callback to get form instance (warppedComponentRef does not work)
    }

    setField = (key, value) => {
        this.props.setBrandFields({ [key]: value });
    };

    componentDidMount() {
        this.setField('tecdocSupplierId', undefined); // reset tecdoc id for new created brand because it's optional
    }

    render() {
        const {
            form,
            setBrandFields,
            intl: { formatMessage },
            user
        } = this.props;

        const { getFieldDecorator, resetFields, isFieldTouched } = form;

        return (
            <Form>
                <Row className={Styles.row}>
                    <Col span={6}>
                        <div className={Styles.colText}>
                            <FormattedMessage id='add_form_brand_id' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <DecoratedInput
                            field='brandId'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            onChange={e => {
                                this.setField('brandId', e.target.value);
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                },
                                {
                                    validator: (rule, value) => value.match(/^[0-9]+$/) != null,
                                    message: formatMessage({
                                        id: 'must_be_number'
                                    })
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col span={6}>
                        <div className={Styles.colText}>
                            <FormattedMessage id='add_form_brand_name' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <DecoratedInput
                            field='brandName'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            onChange={e => {
                                this.setField('brandName', e.target.value);
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col span={6}>
                        <div className={Styles.colText}>
                            <FormattedMessage id='add_form_brand_supplier_id' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <DecoratedInput
                            field='tecdocSupplierId'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            onChange={e => {
                                this.setField('tecdocSupplierId', e.target.value);
                            }}
                            rules={[
                                {
                                    validator: (rule, value) => value.match(/^[0-9]+$/) != null,
                                    message: formatMessage({
                                        id: 'must_be_number'
                                    })
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        );
    }
}
