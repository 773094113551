import { CheckCircleOutlined, CloseCircleOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Radio, Select, Table } from 'antd';
import { Layout } from 'commons';
import { DateRangePicker } from 'components';
import { answered } from 'core/calls/config';
import { fetchRecordingLink, selectCallsLinksCache } from 'core/calls/duck';
import { fetchAddClientForm } from 'core/forms/addClientForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { AddClientModal } from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const dateFormat = 'YYYY-MM-DD';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const orderStatuses = [
    {
        status: 'not_complete',
        key: 'not_complete'
    },
    {
        status: 'processing',
        key: 'processing'
    },
    {
        status: 'approve',
        key: 'approve'
    },
    {
        status: 'progress',
        key: 'progress'
    },
    {
        status: 'success',
        key: 'success'
    },
    {
        status: 'cancel',
        key: 'cancel'
    },
    {
        status: 'required',
        key: 'required'
    },
    {
        status: 'reserve',
        key: 'reserve'
    },
    {
        status: 'call',
        key: 'call'
    },
    {
        status: 'stop',
        key: 'stop'
    }
];

const { Option } = Select;

const defWidth = {
    date: '5%',
    status: '5%',
    order: '10%',
    comment: '8%',
    orderStatus: '8%',
    caller: '10%',
    recipient: '10%',
    client: '15%',
    waiting: '7%',
    duration: '7%',
    innerRecipient: '7%',
    record: '10%'
};

const mapStateToProps = state => ({
    user: state.auth,
    modal: state.modals.modal,
    callsLinksCache: selectCallsLinksCache(state)
});

const mapDispatchToProps = {
    resetModal,
    setModal,
    fetchRecordingLink,
    fetchAddClientForm
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CallsJournalPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            channels: [],
            dateRange: [],
            channelId: 'ALL',
            statusIn: 'all',
            page: 1,
            pageSize: 25,
            statsTotal: 0,
            statsAnswered: 0,
            statsNotAnswered: 0,
            startDate: dayjs().format(dateFormat),
            endDate: dayjs().format(dateFormat)
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='calls-table.order' />,
                width: defWidth.order,
                dataIndex: 'orderId',
                key: 'orderId',
                ...this.getColumnSearchProps('orderId'),
                render: orderId => (
                    <Link style={{ color: 'var(--link)' }} to={`${book.order}/${orderId}`}>
                        {orderId}
                    </Link>
                )
            },
            {
                title: <FormattedMessage id='order_form_table.repair_status' />,
                width: defWidth.orderStatus,
                dataIndex: 'orderStatus',
                key: 'orderStatus',
                ...this.getColumnSearchProps('orderStatus'),
                render: orderStatus => (
                    <div className={Styles.datetime}>
                        {orderStatus ? (
                            <FormattedMessage id={`order-status.${orderStatus}`} />
                        ) : (
                            <FormattedMessage id='long_dash' />
                        )}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='add_order_form.system_comments' />,
                width: defWidth.comment,
                dataIndex: 'comment',
                key: 'comment',
                render: comment => <div className={Styles.datetime}>{comment}</div>
            },
            {
                title: <FormattedMessage id='calls-table.date' />,
                width: defWidth.date,
                dataIndex: 'datetime',
                key: 'date',
                render: date => <div className={Styles.datetime}>{dayjs(date).format('YYYY-MM-DD HH:mm')}</div>
            },

            {
                title: <FormattedMessage id='calls-table.status' />,
                width: defWidth.status,
                dataIndex: 'status',
                align: 'center',
                key: 'status',
                render: status => (
                    <React.Fragment>
                        {answered.includes(status) ? (
                            <CheckCircleOutlined
                                style={{
                                    color: 'var(--secondary)',
                                    fontSize: 24
                                }}
                            />
                        ) : (
                            <CloseCircleOutlined
                                style={{
                                    color: 'var(--warning)',
                                    fontSize: 24
                                }}
                            />
                        )}
                    </React.Fragment>
                )
            },
            {
                title: <FormattedMessage id='calls-table.caller' />,
                width: defWidth.caller,
                dataIndex: 'caller',
                key: 'caller',
                ...this.getColumnSearchProps('clientPhone'),
                render: caller => (
                    <a className={Styles.orderLink} href={`tel:${caller}`}>
                        {caller}
                    </a>
                )
            },
            {
                title: <FormattedMessage id='calls-table.recipient' />,
                width: defWidth.recipient,
                dataIndex: 'recipient',
                key: 'recipient',
                ...this.getColumnSearchProps('operatorPhone'),
                render: recipient => (
                    <a className={Styles.orderLink} href={`tel:${recipient}`}>
                        {recipient}
                    </a>
                )
            },
            {
                title: <FormattedMessage id='calls-table.client' />,
                width: defWidth.client,
                dataIndex: 'clients',
                key: 'clients',
                ...this.getColumnSearchProps('clientName'),
                render: (clients, call) => {
                    return !clients ? (
                        <Button
                            className={Styles.createClient}
                            onClick={async () => {
                                await this.onAddClientModal(call);
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='calls-table.create_new_client' />
                        </Button>
                    ) : (
                        clients.map(client => {
                            return (
                                <Link to={`${book.client}/${client.clientId}`}>
                                    {client.fullName}
                                    {';'}
                                </Link>
                            );
                        })
                    );
                }
            },
            {
                title: <FormattedMessage id='calls-table.waiting' />,
                width: defWidth.waiting,
                dataIndex: 'waiting',
                key: 'waiting',
                render: waiting => <div>{waiting}</div>
            },
            {
                title: <FormattedMessage id='calls-table.duration' />,
                width: defWidth.duration,
                dataIndex: 'duration',
                key: 'duration',
                render: (duration, data) => <div>{duration - data.waiting}</div>
            },
            {
                title: <FormattedMessage id='calls-table.innerRecipient' />,
                width: defWidth.innerRecipient,
                dataIndex: 'innerRecipient',
                key: 'innerRecipient',
                render: innerRecipient => (
                    <a className={Styles.orderLink} href={`tel:${innerRecipient}`}>
                        {innerRecipient}
                    </a>
                )
            },
            {
                title: <FormattedMessage id='calls-table.record' />,
                width: defWidth.record,
                dataIndex: 'recordingLink',
                render: (val, call) => {
                    return String(call.id) in this.props.callsLinksCache ? ( // Check if that key exists in cash memory
                        this.props.callsLinksCache[call.id] ? ( // False for empty rows where key exists in the cash memory or if call was not accepted
                            <audio controls>
                                <source src={this.props.callsLinksCache[call.id]} />
                            </audio>
                        ) : (
                            <FormattedMessage id='calls-table.no_record' />
                        )
                    ) : answered.includes(call.status) ? (
                        <div>
                            <Button onClick={() => this.props.fetchRecordingLink({ callId: call.id })} type='primary'>
                                <FormattedMessage id='calls-table.show_record' />
                            </Button>
                        </div>
                    ) : (
                        <FormattedMessage id='calls-table.no_record' />
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.getCalls();
    }

    getCalls = async (sDate, eDate) => {
        const {
            dateRange,
            page,
            pageSize,
            startDate,
            endDate,
            statusIn,
            channelId,
            orderId,
            orderStatus,
            clientPhone,
            operatorPhone,
            clientName
        } = this.state;
        const all = ['ANSWERED', 'PROPER', 'REPEATED', 'NO ANSWER', 'BUSY'];
        const answeredCalls = ['ANSWERED', 'PROPER', 'REPEATED'];
        const missed = ['NO ANSWER', 'BUSY'];
        const data = await fetchAPI(
            'GET',
            'calls',
            {
                page,
                pageSize,
                orderId,
                orderStatus,
                clientPhone,
                operatorPhone,
                clientName,
                statusIn: statusIn == 'all' ? all : statusIn == 'answered' ? answeredCalls : missed,
                startDate: startDate || sDate,
                endDate: endDate || eDate,
                channelId: channelId == 'ALL' ? null : channelId
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: data.calls,
            channels: data.channels,
            statsTotal: data.stats.total,
            statsAnswered: data.stats.answered,
            statsNotAnswered: data.stats.notAnswered
        });
    };

    onAddClientModal = call => {
        // this.props.fetchAddClientForm();
        this.props.setModal(MODALS.ADD_CLIENT, {
            initialPhoneNumber: call.caller
        });
    };

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'orderStatus') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <Select
                        allowClear
                        onChange={value => {
                            this.setState({
                                orderStatus: value
                            });
                        }}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={this.props.intl.formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={this.state.orderStatus}
                    >
                        {orderStatuses.map(({ status, key }) => (
                            <Option key={key} value={status}>
                                <FormattedMessage id={`order_statuses_mapper.${status}`} />
                            </Option>
                        ))}
                    </Select>
                </div>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'dateRange' &&
                        dataIndex !== 'filterCreatedDate' &&
                        dataIndex !== 'filtertDoneDate' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Button
                                    icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                    onClick={() => this.handleSearch(confirm, dataIndex)}
                                    size='small'
                                    type='primary'
                                >
                                    <FormattedMessage id='search' />
                                </Button>
                                <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.getCalls();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({
            [dataIndex]: undefined,
            page: 1
        });
        this.getCalls();
    };

    render() {
        const { user } = this.props;
        const {
            dataSource,
            page,
            pageSize,
            statusIn,
            channels,
            channelId,
            startDate,
            endDate,
            statsTotal,
            statsAnswered,
            statsNotAnswered
        } = this.state;

        const stats = statusIn == 'all' ? statsTotal : statusIn == 'answered' ? statsAnswered : statsNotAnswered;

        const pagination = {
            pageSize,
            total: Math.ceil(stats / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.getCalls();
            }
        };

        return (
            <React.Fragment>
                <Layout
                    controls={
                        <React.Fragment>
                            {
                                <DateRangePicker
                                    allowClear
                                    dateRange={[
                                        startDate ? dayjs(startDate) : undefined,
                                        endDate ? dayjs(endDate) : undefined
                                    ]}
                                    minimize
                                    onDateChange={async dateRange => {
                                        await this.setState({
                                            startDate: dayjs(dateRange[0]).format(dateFormat),
                                            endDate: dayjs(dateRange[1]).format(dateFormat)
                                        });
                                        this.getCalls();
                                    }}
                                    popupStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                        zIndex: '9999',
                                        minWidth: 200
                                    }}
                                    style={{ margin: '0 0 0 8px' }}
                                />
                            }

                            {channels && (
                                <Select
                                    className={Styles.channels}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                        zIndex: '9999',
                                        minWidth: 220
                                    }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onSelect={async value => {
                                        await this.setState({
                                            channelId: value
                                        });

                                        this.getCalls();
                                    }}
                                    optionFilterProp='children'
                                    showSearch
                                    value={channelId}
                                >
                                    <Option value='ALL'>
                                        <FormattedMessage id='all' />
                                    </Option>
                                    {channels.map(({ id, name }) => (
                                        <Option key={id} value={id}>
                                            {name}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </React.Fragment>
                    }
                    paper
                    title={<FormattedMessage id='navigation.calls_journal' />}
                >
                    <RadioGroup
                        defaultValue='all'
                        onChange={async e => {
                            await this.setState({
                                statusIn: e.target.value
                            });
                            this.getCalls();
                        }}
                        style={{ marginBottom: 8 }}
                        value={statusIn}
                    >
                        <RadioButton value='all'>
                            <FormattedMessage id='calls-table.all' />
                        </RadioButton>

                        <RadioButton value='answered'>
                            <FormattedMessage id='calls-table.answered' />
                        </RadioButton>

                        <RadioButton value='missed'>
                            <FormattedMessage id='calls-table.missed' />
                        </RadioButton>
                    </RadioGroup>
                    <Table
                        className={Styles.table}
                        columns={this.columns()}
                        dataSource={dataSource}
                        page={page}
                        pageSize={pageSize}
                        pagination={pagination}
                        scroll={{
                            x: 800,
                            y: '60vh'
                        }}
                    />
                    <AddClientModal
                        endDate={endDate}
                        getCalls={this.getCalls}
                        resetModal={this.props.resetModal}
                        startDate={startDate}
                        visible={this.props.modal}
                    />
                </Layout>
            </React.Fragment>
        );
    }
}
