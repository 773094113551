exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__form---2N248 .ant-form-item{margin-bottom:2px}.styles-m__addButtonWrapper---3f3n3{display:flex;justify-content:center}.styles-m__editableVehicle---1V2QM{display:flex;margin:0 48px 8px 15%;background:var(--static);padding:8px;align-items:center}.styles-m__carIcon---3ikbS{font-size:64px;color:var(--primary);margin-right:24px}.styles-m__listItem---2nwLT>span{font-weight:700}.styles-m__numWrapper---3XGHV{display:flex}.styles-m__numWrapper---3XGHV .ant-row{width:100%}.styles-m__numWrapper---3XGHV .ant-form-item-control-wrapper{padding:0 5px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,uCACI,iBACJ,CAEA,oCACI,YAAa,CACb,sBACJ,CAEA,mCACI,YAAa,CACb,qBAAsB,CACtB,wBAAyB,CACzB,WAAY,CACZ,kBACJ,CAEA,2BACI,cAAe,CACf,oBAAqB,CACrB,iBACJ,CAEA,iCACI,eACJ,CAEA,8BACI,YACJ,CAEA,uCACI,UACJ,CAEA,6DACI,aACJ","file":"styles.m.css","sourcesContent":[".form :global(.ant-form-item) {\n    margin-bottom: 2px;\n}\n\n.addButtonWrapper {\n    display: flex;\n    justify-content: center;\n}\n\n.editableVehicle {\n    display: flex;\n    margin: 0 48px 8px 15%;\n    background: var(--static);\n    padding: 8px;\n    align-items: center;\n}\n\n.carIcon {\n    font-size: 64px;\n    color: var(--primary);\n    margin-right: 24px;\n}\n\n.listItem > span {\n    font-weight: bold;\n}\n\n.numWrapper {\n    display: flex;\n}\n\n.numWrapper :global(.ant-row) {\n    width: 100%;\n}\n\n.numWrapper :global(.ant-form-item-control-wrapper) {\n    padding: 0 5px;\n}\n"]}]);

// Exports
exports.locals = {
	"form": "styles-m__form---2N248",
	"addButtonWrapper": "styles-m__addButtonWrapper---3f3n3",
	"editableVehicle": "styles-m__editableVehicle---1V2QM",
	"carIcon": "styles-m__carIcon---3ikbS",
	"listItem": "styles-m__listItem---2nwLT",
	"numWrapper": "styles-m__numWrapper---3XGHV"
};