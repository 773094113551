import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import {
    createBrandCrossSuccess,
    CREATE_BRAND_CROSS,
    selectBrandCrossField,
    setBrandCrossFields,
    updateBrandCrossSuccess,
    UPDATE_BRAND_CROSS
} from 'modals/BrandsCrossModal/redux/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';

export function* createBrandCrossSaga() {
    while (true) {
        try {
            const { payload } = yield take(CREATE_BRAND_CROSS);

            yield put(setBrandCrossFields({ brandId: payload }));

            const brandCrossEntity = yield select(selectBrandCrossField);

            yield call(
                fetchAPI,
                'POST',
                'clients/brands/cross',
                null,
                _.omit(brandCrossEntity, ['id'])
            );

            yield put(createBrandCrossSuccess());
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* updateBrandCrossSaga() {
    while (true) {
        try {
            yield take(UPDATE_BRAND_CROSS);

            const brandCrossEntity = yield select(selectBrandCrossField);

            yield call(
                fetchAPI,
                'PUT',
                `clients/brands/cross/${brandCrossEntity.id}`,
                null,
                _.omit(brandCrossEntity, ['id'])
            );

            yield put(updateBrandCrossSuccess());
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([call(createBrandCrossSaga), call(updateBrandCrossSaga)]);
}
