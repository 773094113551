import { Numeral } from 'commons';
import { FormattedDatetime } from 'components';
import _ from 'lodash';
import { documentTypeValues } from 'modals/EmployeeTaskModal/constants';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

// import {DEF_UI_DATETIME} from "modals/RefactoredCashOrderModal/constants";

// Choose width for each col, (100% in total or less than 100% if 'auto' is present)
const defWidthOrders = {
    orderCol: 'auto',
    clientCol: '25%',
    appointmentCol: '15%',
    vehicleCol: '25%',
    sumCol: '10%',
    remainingSumCol: '10%'
};

const defWidthCashOrder = {
    idCol: '35%',
    cashboxIdCol: '20%',
    datetimeCol: 'auto'
};

const defWidthStoreDoc = {
    storeDocCol: '35%',
    creationDateCol: '20%',
    counterpartyCol: 'auto',
    sumCol: '20%'
};

export function columnsConfig(props) {
    const { fields } = props;

    const orderCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.order' />,
        width: defWidthOrders.orderCol,
        align: 'left',
        dataIndex: 'num',
        key: 'orderCol'
    };
    const appointmentCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.appointment' />,
        width: defWidthOrders.appointmentCol,
        align: 'left',
        dataIndex: 'beginDatetime',
        key: 'appointmentCol',
        render: orderDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={orderDatetime} format='DD.MM.YYYY HH:mm' />
            </React.Fragment>
        )
    };
    const clientCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.client' />,
        width: defWidthOrders.clientCol,
        align: 'left',
        key: 'clientCol',
        render: order => {
            const { clientPhones } = order;

            return (
                <div>
                    {/* <Link */}
                    {/*    to={ `${book.client}/${order.clientId}` } */}
                    {/* > */}
                    <div>{`${order.clientName || ''} ${order.clientSurname || ''}`}</div>
                    {/* </Link> */}
                    {clientPhones &&
                        _.map(clientPhones, phone => (
                            <div>
                                <a href={`tel:${phone}`}>{phone}</a>
                            </div>
                        ))}
                </div>
            );
        }
    };
    const vehicleCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.vehicle' />,
        width: defWidthOrders.vehicleCol,
        align: 'left',
        key: 'vehicleCol',
        render: order => {
            return (
                <div>
                    {/* <Link */}
                    {/*    to={ `${book.vehicle}/${order.vehicleId}` } */}
                    {/* > */}
                    {`${_.get(order, 'vehicleMakeName', '')} ${_.get(
                        order,
                        'vehicleModelName',
                        ''
                    )} ${_.get(order, 'vehicleYear', '')}`}
                    {/* </Link> */}
                </div>
            );
        }
    };

    const sumCol = {
        title: <FormattedMessage id='sum' />,
        dataIndex: 'totalSum',
        key: 'sumCol',
        width: defWidthOrders.sumCol,
        render: (_, order) => {
            const sum = order.isTaxPayer
                ? order.totalSumWithTax
                : order.servicesTotalSum + order.detailsTotalSum;

            return <Numeral nullText='0'>{sum}</Numeral>;
        }
    };

    const remainingSumCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.remaining_sum' />,
        dataIndex: 'remainingSum',
        key: 'remainingSumCol',
        width: defWidthOrders.remainingSumCol,
        render: remainingSum => <Numeral nullText='0'>{remainingSum}</Numeral>
    };

    const storeDocCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.store_doc' />,
        width: defWidthStoreDoc.storeDocCol,
        align: 'left',
        dataIndex: 'documentNumber',
        key: 'storeDocCol'
    };
    const creationDateCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.creation_date' />,
        width: defWidthStoreDoc.creationDateCol,
        align: 'left',
        dataIndex: 'createdDatetime',
        key: 'createdDatetime',
        render: orderDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={orderDatetime} format='DD.MM.YYYY HH:mm' />
            </React.Fragment>
        )
    };
    const counterpartyCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.counterparty' />,
        width: defWidthStoreDoc.counterpartyCol,
        align: 'left',
        dataIndex: 'counterpartClientName',
        key: 'counterpartyCol'
    };
    const sumStoreDocCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.sum' />,
        width: defWidthStoreDoc.sumCol,
        align: 'right',
        dataIndex: 'sellingSum',
        key: 'sumCol',
        render: sellingSum => (
            <Numeral mask='0,0.00' nullText='0'>
                {sellingSum}
            </Numeral>
        )
    };

    const numberCol = {
        title: <FormattedMessage id='cash-table.cashbox_num' />,
        dataIndex: 'cashBoxId',
        width: defWidthCashOrder.cashboxIdCol,
        render: (cashBoxId, { cashBoxName }) => (
            <div className={Styles.breakWord}>
                <div>{cashBoxId}</div>
                <div>{cashBoxName}</div>
            </div>
        )
    };

    const cashOrderCol = {
        title: <FormattedMessage id='cash-table.order_num' />,
        dataIndex: 'id',
        width: defWidthCashOrder.idCol
    };

    const dateCol = {
        title: <FormattedMessage id='cash-table.date' />,
        dataIndex: 'datetime',
        width: defWidthCashOrder.datetimeCol,
        render: date => <FormattedDatetime datetime={date} format='DD.MM.YYYY' />
    };

    switch (fields.documentType) {
        case documentTypeValues.ORDER:
            return [orderCol, appointmentCol, clientCol, vehicleCol, sumCol, remainingSumCol];
        case documentTypeValues.CASH_ORDER:
            return [numberCol, cashOrderCol, dateCol];
        case documentTypeValues.STORE_DOC:
            return [
                storeDocCol,
                creationDateCol,
                // counterpartyCol,
                sumStoreDocCol
            ];
    }
}
