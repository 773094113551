import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export const GoBack = ({ link }) => (
    <Link to={link}>
        <Button type='default'>
            <ArrowLeftOutlined />
            <FormattedMessage id='go_back' />
        </Button>
    </Link>
);
