export const PRD = {
    order: {
        num: 'номер документа',
        roundedFiscalSum: 'округлена фіскальна сума',
        tax: ' ПДВ',
        detailsTotalSum: 'загальна сума деталей',
        servicesTotalSum: 'загальна сума послуг',
        totalSumWithDiscount: 'загальна сума зі знижкою',
        totalSumWithTax: 'загальна сума з податком ПДВ',
        totalSum: 'загальна сума',
        barcode: 'штрих-код'
    },
    business: {
        name: 'назва станції',
        address: 'адреса',
        phones: 'телефони',
        logo: {
            original: {
                path: 'посилання',
                width: 'ширина',
                height: 'висота'
            }
        },
        primaryLogoUrl: 'URL посилання основного логотипу',
        secondaryLogoUrl: 'URL посилання додаткового логотипу',
        domain: 'домен',
        normHourPrice: 'ціна за нормо-годину',
        phoneCode: [
            {
                code: 'код',
                number: 'номер',
                country: 'країна'
            }
        ],
        packageStatus: 'статус пакета',
        currency: 'валюта',
        base64PrimaryLogo: 'основний логотип у форматі Base64',
        printCode: 'код друку',
        printBrand: 'друк бренду',
        printClientNameAndTel: 'друк імені клієнта та телефону',
        discountRow: 'рядок зі знижкою',
        quantityDecimalPlaces: 'кількість десяткових знаків',
        priceDecimalPlaces: 'десяткові знаки для цін',
        sumDecimalPlaces: 'десяткові знаки для сум'
    },
    doc: {
        id: 'id ідентифікатор',
        sellingPrice: 'ціна продажу',
        purchasePrice: 'ціна закупівлі',
        lastPurchasePrice: 'остання ціна закупівлі',
        minMarkup: 'мінімальна націнка',
        minMargin: 'мінімальний прибуток',
        markup: 'націнка',
        margin: 'прибуток',
        defaultWarehouseName: 'назва складу за замовчуванням',
        barcode: 'штрих-код',
        markupName: 'назва націнки',
        markupUsing: 'використання націнки',
        sellingCurrency: 'валюта продажу',
        purchaseCurrency: 'валюта закупівлі',
        groupId: 'id ідентифікатор групи',
        name: 'назва',
        code: 'код',
        brandId: 'id ідентифікатор бренду',
        brandName: 'назва бренду',
        unit: 'одиниця',
        measureUnit: 'одиниця виміру',
        tradeCode: 'торговий код',
        certificate: 'сертифікат',
        businessId: 'id ідентифікатор станції',
        defaultBusinessSupplierId: 'id ідентифікатор постачальника за замовчуванням',
        saveOnStock: 'зберігати на складі',
        min: 'мінімум',
        max: 'максимум',
        defaultWarehouseId: 'id ідентифікатор складу за замовчуванням',
        multiplicity: 'Кратність',
        externalId: 'зовнішній ідентифікатор',
        height: 'висота',
        width: 'ширина',
        depth: 'глибина',
        volume: 'обсяг',
        weight: 'вага',
        length: 'довжина',
        internalDiameter: 'внутрішній діаметр',
        externalDiameter: 'зовнішній діаметр',
        colorId: 'id ідентифікатор кольору',
        material: 'матеріал',
        comment: 'коментар',
        condition: 'стан',
        usedInVehicles: 'використовується у транспортних засобах',
        right: 'правий',
        left: 'лівий',
        front: 'передній',
        rear: 'задній',
        top: 'верхній',
        bottom: 'нижній',
        external: 'зовнішній',
        internal: 'внутрішній',
        central: 'центральний',
        cellAddresses: 'адреси комірок',
        priceGroupNumber: 'номер цінової групи',
        fixedSellingPrice: 'фіксована ціна продажу',
        minSellingPrice: 'мінімальна ціна продажу',
        fixedPurchasePrice: 'фіксована ціна закупівлі',
        getSellingPriceFrom: 'отримати ціну продажу від',
        getPurchasePriceFrom: 'отримати ціну закупівлі від',
        isRound: 'заокруглення',
        decimalPlaces: 'десяткові місця',
        productUnitId: 'ідентифікатор одиниці продукту',
        enabled: 'увімкнено',
        markupGroupId: 'ідентифікатор групи націнок',
        source: 'джерело',
        purchaseCurrencyId: 'id ідентифікатор валюти закупівлі',
        sellingCurrencyId: 'id ідентифікатор валюти продажу',
        purchasePriceInCurrency: 'ціна закупівлі у валюті',
        sellingPriceInCurrency: 'ціна продажу у валюті',
        storageHashes: 'хеші зберігання',
        brand: {
            id: 'ідентифікатор',
            name: 'назва бренду',
            supplierId: 'id ідентифікатор постачальника'
        },
        group: {
            id: 'id ідентифікатор',
            markupGroupId: 'id ідентифікатор групи націнок',
            parentGroupId: 'id ідентифікатор батьківської групи',
            priceGroupNumber: 'номер цінової групи',
            businessId: 'id ідентифікатор станції',
            fixedBrand: 'фіксований бренд'
        },
        priceGroup: {
            number: 'номер',
            businessId: 'id ідентифікатор станції',
            multiplier: 'множник'
        },
        businessSupplier: 'постачальник',
        uktz: 'УКТЗЕД',
        customCount: 'замовлена кількість',
        disablePrice: 'вимкнути ціну'
    }
};
