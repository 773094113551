export const CSH = {
    id: 'id ідентифікатор',
    type: 'тип CSH',
    cashBoxId: 'id ідентифікатор каси',
    datetime: 'дата та час',
    createdAt: 'створено CSH',
    updatedAt: 'оновлено CSH',
    orderId: 'id ідентифікатор замовлення',
    increase: 'збільшення',
    decrease: 'зменшення',
    createdBy: 'ким створено CSH',
    updatedBy: 'ким оновлено CSH',
    description: 'опис',
    clientId: 'id ідентифікатор клієнта',
    employeeId: 'id ідентифікатор співробітника',
    businessSupplierId: 'id ідентифікатор постачальника бізнесу',
    otherCounterparty: 'інший контрагент',
    tag: 'мітка',
    externalId: 'зовнішній ідентифікатор',
    storeDocId: 'ідентифікатор документа зберігання',
    salaryDocId: 'ідентифікатор документа зарплати',
    analyticsUniqueId: 'унікальний ідентифікатор аналітики',
    isRegisteredWithRst: 'зареєстровано в РРО',
    cashBoxName: 'назва каси',
    fiscalNumber: 'фіскальний номер',
    rst: 'каса РРО',
    clientName: "ім'я клієнта",
    clientSurname: 'прізвище клієнта',
    employeeName: "ім'я співробітника",
    employeeSurname: 'прізвище співробітника',
    businessSupplierName: 'назва станції',
    orderNum: 'номер замовлення',
    businessItn: 'ІПН бізнесу',
    clientItn: 'ІПН клієнта',
    responsible: 'відповідальний',
    documentNumber: 'номер документа',
    businessName: 'назва бізнесу',
    sum: 'сума',
    counterpartyName: 'назва контрагента'
};
