import { Button } from 'antd';
import { Catcher } from 'commons';
import { UniversalChart } from 'components';
import { fetchChart, setChartMode } from 'core/chart/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { UniversalChartModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// import Styles from './styles.m.css';

const mapStateToProps = state => ({
    chart: state.chart,
    // filter: state.chart.filter,
    modal: state.modals.modal
});

const mapDispatchToProps = {
    fetchChart,
    setChartMode,
    setModal,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ChartContainer extends Component {
    render() {
        const {
            chartData,
            filter,
            modal,
            setModal,
            resetModal,
            fetchChart,
            setChartMode,
            startDate,
            endDate
        } = this.props;

        return (
            <Catcher>
                <Button
                    onClick={() => setModal(MODALS.UNIVERSAL_CHART)}
                    style={{ margin: '12px auto' }}
                    type='primary'
                >
                    <FormattedMessage id='universal_chart.show' />:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id={`universal-chart.list.item.${filter.mode}`} />
                    </span>
                </Button>
                <UniversalChart data={chartData} mode={filter.mode} period={filter.period} />
                <UniversalChartModal
                    // wrappedComponentRef={ this.saveChartRef }
                    endDate={endDate}
                    fetchChart={fetchChart}
                    mode={filter.mode}
                    period={filter.period}
                    resetModal={resetModal}
                    setChartMode={setChartMode}
                    startDate={startDate}
                    visible={modal}
                />
            </Catcher>
        );
    }
}
