import { CloseSquareOutlined, PhoneOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class CallStatusIcon extends Component {
    getStatusIcon(status) {
        switch (status) {
            case 'NO ANSWER':
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='call_status_icon.no_answer' />}
                    >
                        <CloseSquareOutlined className={`${Styles.icon} ${Styles.noAnswer}`} />
                    </Tooltip>
                );

            default:
                return (
                    <Tooltip
                        overlayStyle={{ zIndex: 110 }}
                        placement='top'
                        title={<FormattedMessage id='call_status_icon.call' />}
                    >
                        <PhoneOutlined className={`${Styles.icon} ${Styles.phone}`} />
                    </Tooltip>
                );
        }
    }

    render() {
        return this.getStatusIcon(this.props.status);
    }
}
