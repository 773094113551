import { Button, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { fetchAPI } from 'utils';

const PrintReceiptModal = ({ open, onClose, onOk, onCashbox, cashOrder = {} }) => {
    const [receipt, setReceipt] = useState({});

    const fetchReceipt = useCallback(async () => {
        const response = await fetchAPI(
            'GET',
            '/cashdesk/get_receipt',
            { data: { cashOrderId: Number(cashOrder.id), taxType: 'json' } },
            null,
            {
                handleErrorInternally: true
            }
        );
        setReceipt(response);
    }, [cashOrder.id]);

    useEffect(() => {
        console.log(cashOrder);
        if (Object.keys(cashOrder).length) {
            fetchReceipt();
        }
    }, [cashOrder, fetchReceipt]);

    return (
        <Modal
            maskClosable={false}
            footer={
                <div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
                    <Button key='back' onClick={onClose}>
                        <FormattedMessage id='cancel' />
                    </Button>
                    {receipt.url && (
                        <Link target='_blank' to={{ pathname: receipt.url }}>
                            <Button key='cashbox'>
                                <FormattedMessage id='cash-table.checkbox' />
                            </Button>
                        </Link>
                    )}
                    <Button key='submit' onClick={onOk} type='primary'>
                        <FormattedMessage id='ok' />
                    </Button>
                </div>
            }
            onCancel={onClose}
            onClose={onClose}
            open={open}
            title={<FormattedMessage id='cash-order-form.print' />}
        >
            {receipt.textPrint && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        textAlign: 'justify',
                        fontFamily: 'monospace,monospace'
                    }}
                >
                    {receipt.textPrint}
                </div>
            )}
        </Modal>
    );
};

export default PrintReceiptModal;
