import { Numeral } from 'commons';
import { Pie } from 'components/Charts';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class MiniPie extends Component {
    static defaultProps = {
        height: 90,
        width: 90,
        margin: [0, 0, 0, 0],
        inner: 0.5,
        tooltip: false,
        color: 'rgb(81, 205, 102)'
    };

    render() {
        const { hasLegend, label, percent, height, width, margin, color, inner, tooltip } =
            this.props;

        return (
            <div className={Styles.miniPie}>
                <Pie
                    // hasLegend={ hasLegend }
                    // total={ total }
                    animate={false}
                    color={color}
                    forceFit={false}
                    height={height}
                    inner={inner}
                    margin={margin}
                    percent={percent}
                    tooltip={tooltip}
                    width={width}
                />
                <div>
                    <span className={Styles.label}>
                        <FormattedMessage id={label} />
                    </span>
                    <Numeral className={Styles.value}>{percent}</Numeral>
                </div>
            </div>
        );
    }
}
