import { Modal, Select, Table } from 'antd';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const dateFormat = 'DD.MM.YYYY';

const mapStateToProps = state => ({
    user: state.auth
});

const { Option } = Select;

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class DebtPairResultsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='storage_calculations.date' />,
                key: 'date',
                dataIndex: 'dateTime',
                align: 'center',
                width: 200,
                render: date => dayjs(date).format('DD.MM.YYYY HH:mm')
            },
            {
                title: <FormattedMessage id='storage_calculations.doc_type' />,
                key: 'doc_type',
                dataIndex: 'documentType',
                align: 'center',
                width: 200
            },
            {
                title: <FormattedMessage id='storage_journal.document_number' />,
                key: 'docNumber',
                width: 200,
                dataIndex: 'documentNumber'
            },
            {
                title: <FormattedMessage id='storage_journal.document_sum' />,
                key: 'docSum',
                align: 'right',
                dataIndex: 'docSum',
                width: 200,
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0.00'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='storage_journal.pair_sum' />,
                key: 'counterpartyName',
                width: 200,
                align: 'right',
                dataIndex: 'pairSum',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0.00'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            }
        ];
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.getJournalCalculationInfo();
        }
    }

    getJournalCalculationInfo = async () => {
        const { id, rowId, sign, pairingIds } = this.props.row;

        const data = await fetchAPI(
            'GET',
            '/journal_calculations_info',
            {
                page: 1,
                pageSize: 999,
                sign,
                documentIds: pairingIds || [],
                rowId: id || rowId
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: _.get(data, 'result', [])
        });
    };

    render() {
        const { dataSource } = this.state;

        const { visible, hideModal } = this.props;

        return (
            <Modal
                destroyOnClose
                footer={null}
                maskClosable={false}
                onCancel={() => {
                    hideModal();
                    this.setState({ dataSource: undefined });
                }}
                title={<FormattedMessage id='navigation.paired_documents' />}
                visible={visible}
                width='75%'
            >
                <Table
                    bordered
                    columns={this.columns()}
                    dataSource={dataSource}
                    pagination={false}
                />
            </Modal>
        );
    }
}
