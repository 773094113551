exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__modalFooter---gejc2{text-align:left}.styles-m__portmoneLogos---2JTlo{width:240px;margin-right:24px}.styles-m__terms---2-EJa{display:flex}.styles-m__support---3S2qx{margin:12px 0;text-align:center}.styles-m__support---3S2qx a{color:var(--link);cursor:pointer}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,+BACI,eACJ,CAEA,iCACI,WAAY,CACZ,iBACJ,CAEA,yBACI,YACJ,CAEA,2BACI,aAAc,CACd,iBACJ,CAEA,6BACI,iBAAkB,CAClB,cACJ","file":"styles.m.css","sourcesContent":[".modalFooter {\n    text-align: left;\n}\n\n.portmoneLogos {\n    width: 240px;\n    margin-right: 24px;\n}\n\n.terms {\n    display: flex;\n}\n\n.support {\n    margin: 12px 0;\n    text-align: center;\n}\n\n.support a {\n    color: var(--link);\n    cursor: pointer;\n}\n"]}]);

// Exports
exports.locals = {
	"modalFooter": "styles-m__modalFooter---gejc2",
	"portmoneLogos": "styles-m__portmoneLogos---2JTlo",
	"terms": "styles-m__terms---2-EJa",
	"support": "styles-m__support---3S2qx"
};