import { CopyOutlined, DeleteOutlined, SaveOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Col, Modal, notification, Row, Select, Table } from 'antd';
import { Catcher } from 'commons';
import {
    createPriorityBrand,
    deletePriorityBrand,
    fetchPriorityBrands,
    onChangeBrandsForm,
    setFilter,
    setSearchBusinesses,
    setSearchProducts,
    setSearchSuppliers,
    setSort,
    updatePriorityBrand
} from 'core/forms/brandsForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { handleError } from 'core/ui/duck';
import { SetDetailProductForm, SpreadBusinessBrandsForm } from 'forms';
import { DecoratedInputNumber, DecoratedSelect } from 'forms/DecoratedFields';
import { BusinessSearchField, ProductSearchField, SupplierSearchField } from 'forms/_formkit';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getCurrentDuckErrors, handleCurrentDuckErrors, withReduxForm } from 'utils';
import getErrorConfigs from './error_configs';
import Styles from './styles.m.css';

const { Option } = Select;

const sortOptions = {
    asc: 'ascend',
    desc: 'descend'
};

@injectIntl
@withReduxForm({
    name: 'brandsForm',
    actions: {
        change: onChangeBrandsForm,
        fetchPriorityBrands,
        setSort,
        setSearchSuppliers,
        setSearchBusinesses,
        setSearchProducts,
        updatePriorityBrand,
        deletePriorityBrand,
        createPriorityBrand,
        setModal,
        resetModal,
        setFilter,
        handleError
    },
    mapStateToProps: state => ({
        errors: state.ui.errors,
        modal: state.modals.modal
    })
})
export class BrandsForm extends Component {
    constructor(props) {
        super(props);
        this._source = 'brandsForm';
        this._errorConfigs = getErrorConfigs(props.intl);
    }

    componentDidMount() {
        this.props.fetchPriorityBrands();
    }

    _getColumnOrder = (sort, fieldName) =>
        sort.field === fieldName ? sortOptions[sort.order] : void 0;

    _handleTableChange = (pagination, filters, sorter) => {
        if (!sorter) {
            return;
        }
        const sort = {
            field: sorter.columnKey,
            order: sorter.order === 'ascend' ? 'asc' : 'desc'
        };

        this.props.setSort(sort);
    };

    render() {
        const {
            form: { getFieldDecorator, validateFields },
            errors,
            intl,
            handleError
        } = this.props;

        const duckErrors = getCurrentDuckErrors(errors, this._errorConfigs, this._source);
        handleCurrentDuckErrors(notification, duckErrors, intl, handleError);

        const { priorityBrands } = this.props;
        const count = _.get(priorityBrands, ['stats', 'count'], 0);
        const list = _.get(priorityBrands, ['list'], []);
        const page = _.get(this.props, ['sort', 'page'], 1);
        const sort = _.get(this.props, 'sort', {});
        const { formatMessage } = this.props.intl;

        const pagination = {
            pageSize: 11,
            size: 'large',
            total: Math.ceil(count / 10) * 11,
            hideOnSinglePage: true,
            current: page,
            onChange: page => {
                this.props.setSort({ page });
            }
        };

        const getSearchSelect = (
            item,
            searchAction,
            idFieldName,
            valueFieldName,
            searchResults,
            idField = 'id'
        ) => {
            return (
                <DecoratedSelect
                    key={`${item.id}-${idFieldName}`}
                    className={Styles.brandsFormItem}
                    cnStyles={Styles.brandsSelect}
                    field={`${item.id}.${idFieldName}`}
                    filterOption={false}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={item[idFieldName] || void 0}
                    onFocus={() =>
                        _.isNil(_.get(searchResults, [item.id])) && searchAction(item.id, '')
                    }
                    onSearch={query => searchAction(item.id, query)}
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                    showSearch
                >
                    {_.uniqBy(
                        [
                            ..._.get(searchResults, [item.id], []),
                            ...(item[idFieldName]
                                ? [
                                      {
                                          [idField]: item[idFieldName],
                                          name: item[valueFieldName]
                                      }
                                  ]
                                : [])
                        ],
                        value => value[idField]
                    ).map(({ [idField]: id, name }, index) => (
                        <Option key={`${id}-${index}`} value={id}>
                            {name}
                        </Option>
                    ))}
                </DecoratedSelect>
            );
        };

        const columns = [
            {
                title: formatMessage({ id: 'brands.business_name' }),
                width: '20%',
                key: 'businessName',
                render: item => {
                    return getSearchSelect(
                        item,
                        this.props.setSearchBusinesses,
                        'businessId',
                        'businessName',
                        this.props.searchBusinesses,
                        'businessId'
                    );
                }
            },
            {
                title: formatMessage({ id: 'brands.product_name' }),
                sorter: true,
                sortOrder: this._getColumnOrder(sort, 'productName'),
                width: '20%',
                key: 'productName',
                render: item => {
                    return getSearchSelect(
                        item,
                        this.props.setSearchProducts,
                        'productId',
                        'productName',
                        this.props.searchProducts,
                        'productId'
                    );
                }
            },
            {
                title: formatMessage({ id: 'brands.supplier_name' }),
                width: '20%',
                key: 'supplierName',
                render: item => {
                    return getSearchSelect(
                        item,
                        this.props.setSearchSuppliers,
                        'supplierId',
                        'supplierName',
                        this.props.searchSuppliers,
                        'supplierId'
                    );
                }
            },
            {
                title: formatMessage({ id: 'brands.priority' }),
                width: '20%',
                key: 'priority',
                render: item => {
                    return (
                        <DecoratedInputNumber
                            key={`${item.id}-priority`}
                            className={Styles.brandsFormItem}
                            field={`${item.id}.priority`}
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            initialValue={_.isNil(item.priority) ? void 0 : item.priority}
                            max={500}
                            min={0}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />
                    );
                }
            },
            {
                width: '20%',
                render: item => {
                    return item.id === -1 ? (
                        <div>
                            <SaveOutlined
                                className={Styles.brandSaveIcon}
                                onClick={() => {
                                    validateFields([String(item.id)], (err, values) => {
                                        if (!err) {
                                            this.props.createPriorityBrand(
                                                _.chain(values).values().first().value()
                                            );
                                        }
                                    });
                                }}
                            />
                        </div>
                    ) : (
                        <div>
                            <SaveOutlined
                                className={Styles.brandSaveIcon}
                                onClick={() => {
                                    validateFields([String(item.id)], (err, values) => {
                                        if (!err) {
                                            this.props.updatePriorityBrand(
                                                item.id,
                                                _.chain(values).values().first().value()
                                            );
                                        }
                                    });
                                }}
                            />
                            <DeleteOutlined
                                className={Styles.brandDeleteIcon}
                                onClick={() => this.props.deletePriorityBrand(item.id)}
                            />
                        </div>
                    );
                }
            }
        ];

        return (
            <Catcher>
                <Row gutter={24} type='flex'>
                    <Col span={6}>
                        <BusinessSearchField
                            businessId={this.props.filter.businessId}
                            onSelect={businessId => this.props.setFilter({ businessId })}
                            selectStyles={{ width: '100%' }}
                        />
                    </Col>
                    <Col span={6}>
                        <ProductSearchField
                            onSelect={productId => this.props.setFilter({ productId })}
                            productId={this.props.filter.productId}
                            selectStyles={{ width: '100%' }}
                        />
                    </Col>
                    <Col span={6}>
                        <SupplierSearchField
                            onSelect={supplierId => this.props.setFilter({ supplierId })}
                            selectStyles={{ width: '100%' }}
                            supplierId={this.props.filter.supplierId}
                        />
                    </Col>
                    <Col span={3}>
                        <Button
                            className={Styles.swapIcon}
                            icon={<SwapOutlined />}
                            onClick={() => this.props.setModal(MODALS.DETAIL_PRODUCT)}
                        >
                            TecDoc
                        </Button>
                    </Col>
                    <Col span={3}>
                        <Button
                            className={Styles.swapIcon}
                            icon={<CopyOutlined />}
                            onClick={() => this.props.setModal(MODALS.SPREAD_BUSINESS_BRANDS)}
                        >
                            <FormattedMessage id='copy' />
                        </Button>
                    </Col>
                </Row>
                <br />
                <Table
                    bordered
                    columns={columns}
                    dataSource={[{ id: -1 }, ...list]}
                    loading={this.props.brandsFetching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    onChange={this._handleTableChange}
                    pagination={pagination}
                    rowClassName={({ id }) => {
                        if (id === -1) {
                            return Styles.newBrandRow;
                        }
                        if (this.props.fields[id]) {
                            return Styles.editedBrandRow;
                        }
                    }}
                    rowKey={record => record.id}
                    size='small'
                    // onChange={ handleTableChange }
                />
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={() => this.props.resetModal()}
                    title='TecDoc: articles'
                    visible={MODALS.DETAIL_PRODUCT === this.props.modal}
                >
                    <SetDetailProductForm />
                </Modal>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={() => this.props.resetModal()}
                    title='TecDoc: suppliers'
                    visible={MODALS.SPREAD_BUSINESS_BRANDS === this.props.modal}
                >
                    <SpreadBusinessBrandsForm />
                </Modal>
            </Catcher>
        );
    }
}
