import { Col, Input, Row, Select } from 'antd';
import _ from 'lodash';
import {
    fetchSuppliers,
    selectFetchingSuppliers,
    selectSelectedSupplierId,
    selectSuppliers,
    setSelectedSupplierId
} from 'modals/DebtZeroingModal/redux/suppliers/duck';
import {
    fetchSuppliersStoreDocs,
    selectSelectedSupplierStoreDocId,
    selectSuppliersStoreDocsFilters,
    setSuppliersStoreDocsFilters,
    setSuppliersStoreDocsFiltersPoor
} from 'modals/DebtZeroingModal/redux/suppliersStoreDocs/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    StoreDocStatusSelector,
    StoreDocTypeSelector,
    SuppliersStoreDocsTable,
    SupplierStoreDocVisualizer
} from './components';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    fetchingSuppliers: selectFetchingSuppliers(state),
    suppliers: selectSuppliers(state),
    selectedSupplierId: selectSelectedSupplierId(state),
    selectedSupplierStoreDocId: selectSelectedSupplierStoreDocId(state),
    suppliersStoreDocsFilters: selectSuppliersStoreDocsFilters(state)
});

const mapDispatchToProps = {
    fetchSuppliers,
    setSelectedSupplierId,
    setSuppliersStoreDocsFilters,
    setSuppliersStoreDocsFiltersPoor,
    fetchSuppliersStoreDocs
};

/**
 * Handle selection of supplier and its store docs
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SupplierCounterpartyContainer extends React.Component {
    constructor(props) {
        super(props);

        // Fetch suppliers debounce
        this.handleSearch = _.debounce(() => {
            const { fetchSuppliersStoreDocs } = this.props;
            fetchSuppliersStoreDocs();
        }, 800);
    }

    componentDidMount() {
        this.props.fetchSuppliers();
    }

    render() {
        const {
            fetchingSuppliers,
            suppliers,
            selectedSupplierId,
            selectedSupplierStoreDocId,
            suppliersStoreDocsFilters,

            setSelectedSupplierId,
            setSuppliersStoreDocsFilters,
            setSuppliersStoreDocsFiltersPoor,

            intl: { formatMessage }
        } = this.props;

        let storeDocBody = null; // Which component to render
        if (selectedSupplierStoreDocId) {
            storeDocBody = <SupplierStoreDocVisualizer />;
        } else {
            storeDocBody = <SuppliersStoreDocsTable />;
        }

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col className={Styles.col} span={12}>
                        <StoreDocTypeSelector />
                    </Col>
                    <Col className={Styles.col} span={12}>
                        <StoreDocStatusSelector />
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col className={Styles.col} span={12}>
                        <div>
                            <Select
                                allowClear
                                disabled={fetchingSuppliers}
                                filterOption={(input, option) => {
                                    return (
                                        String(_.get(option, 'props.label', ''))
                                            .toLowerCase()
                                            .indexOf(String(input || '').toLowerCase()) >= 0
                                    );
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                                loading={fetchingSuppliers}
                                onChange={supplierId => {
                                    setSelectedSupplierId(supplierId);
                                    setSuppliersStoreDocsFilters({
                                        counterpartBusinessSupplierId: supplierId
                                    });
                                }}
                                placeholder={formatMessage({
                                    id: 'debt_zeroing_modal.placeholder_supplier'
                                })}
                                showSearch
                                value={selectedSupplierId}
                            >
                                {_.map(suppliers, supplier => {
                                    return (
                                        <Option
                                            key={supplier.id}
                                            label={`${supplier.name || ''}`}
                                            value={supplier.id}
                                        >
                                            {`${supplier.name || ''}`}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </Col>
                    <Col className={Styles.col} span={12}>
                        <div title='What are you looking for here?'>
                            <Input
                                onChange={e => {
                                    setSuppliersStoreDocsFiltersPoor({
                                        query: _.toLower(
                                            String(e.target.value || '').replace(/[+()]/g, '')
                                        )
                                    });
                                    this.handleSearch();
                                }}
                                placeholder={formatMessage({
                                    id: 'debt_zeroing_modal.placeholder_search'
                                })}
                                value={_.get(suppliersStoreDocsFilters, 'query')}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col span={24}>{storeDocBody}</Col>
                </Row>
            </div>
        );
    }
}
