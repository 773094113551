import numeral from 'numeral';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
    Area,
    AreaChart,
    CartesianGrid,
    Label,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { chartMode } from './chartConfig';

@injectIntl
export default class UniversalChart extends Component {
    _tooltip = (value, mode) => {
        const { formatMessage } = this.props.intl;

        return `${numeral(value).format('0,0[]00')}${
            chartMode[mode].type && ` (${formatMessage({ id: chartMode[mode].type })})`
        }`;
    };

    render() {
        const { data, mode, period } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <ResponsiveContainer height={500} width='100%'>
                <AreaChart data={data} margin={{ top: 20, right: 40, left: 50, bottom: 60 }}>
                    <XAxis dataKey='id' textAnchor='end' tick={{ angle: -35 }}>
                        <Label
                            offset={-55}
                            position='insideBottom'
                            style={{ textAnchor: 'middle' }}
                            value={formatMessage({
                                id: period
                            })}
                        />
                    </XAxis>
                    <YAxis tickFormatter={value => numeral(value).format('0,0[]00')}>
                        <Label
                            angle={-90}
                            offset={40}
                            position='left'
                            style={{ textAnchor: 'middle' }}
                            value={formatMessage({
                                id: `universal-chart.list.item.${mode}`
                            })}
                        />
                    </YAxis>
                    <CartesianGrid strokeDasharray='3 3' />
                    <Tooltip formatter={value => this._tooltip(value, mode)} />
                    <defs>
                        <linearGradient id='colorPv' x1='0' x2='0' y1='0' y2='1'>
                            <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8} />
                            <stop offset='95%' stopColor='#82ca9d' stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <Area
                        activeDot={{ r: 10 }}
                        dataKey='score'
                        fill='url(#colorPv)'
                        fillOpacity={1}
                        name={formatMessage({
                            id: `universal-chart.list.item.${mode}`
                        })}
                        stroke='#51cd66'
                        strokeWidth={4}
                        type='monotone' // eslint-disable-line
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    }
}
