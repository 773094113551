import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, notification, Row, Select, Switch, Tooltip, TreeSelect } from 'antd';
import { Barcode } from 'components';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import { setBrandsSearchQuery } from 'core/search/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import {
    createProduct,
    deleteProduct,
    fetchProduct,
    fetchProductEanData,
    fetchProducts,
    fetchUnits,
    resetProductEanData,
    selectProductLoading,
    selectStoreProduct,
    selectStoreProductEanData,
    selectUnits,
    updateProduct
} from 'core/storage/products';
import { fetchStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import { handleError } from 'core/ui/duck';
import _, { omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import styled from 'styled-components';
import {
    buildStoreGroupsTree,
    fetchAPI,
    filterTreeNodeByPart,
    getCurrentDuckErrors,
    handleCurrentDuckErrors
} from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import getErrorConfigs from './error_configs';

const { Option } = Select;
const inputPattern = /[+()-\s]/g;

const StyledForm = styled(Form)`
    display: flex;
    max-width: fit-content;
    margin: 0 auto;
    flex-direction: column;
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
`;

const SubmitButton = styled(Button)`
    margin: 0 8px;
`;
const DeleteButton = styled(Button)`
    margin: 0;
`;

const sourceType = {
    OWN: 'complexes.type.PURCHASE',
    PRODUCTION: 'complexes.type.PRODUCTION',
    DISASSEMBLY: 'complexes.type.DISASSEMBLY'
};

const ProductForm = props => {
    const {
        intl: { formatMessage },
        errors,
        intl,
        handleError,
        productEanData,
        modalProps,
        fetchBrands,
        fetchUnits,
        fetchStoreGroups,
        fetchPriceGroups,
        fetchProduct,
        product,
        storeGroups,
        disabled,
        isMobile
    } = props;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: props.editing ? 6 : 8 },
            xxl: { span: props.editing ? 6 : 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 12 },
            xxl: { span: 14 }
        },
        colon: false
    };

    const { brandId, code, groupId, name, barcode, productUnitId } = modalProps;

    const [form] = Form.useForm();

    const [storeGroupsTree, setStoreGroupsTree] = useState([]);
    const [loadings, setLoading] = useState(false);
    const [getSellingPriceFrom, setPriceFrom] = useState('FIXED');
    const [markups, setMarkups] = useState([]);

    const duckErrors = getCurrentDuckErrors(errors, getErrorConfigs(props.intl), 'storeProductForm');
    handleCurrentDuckErrors(notification, duckErrors, intl, handleError);

    const fetchMarkups = useCallback(async () => {
        const response = await fetchAPI('GET', '/markups', null, null, {
            handleErrorInternally: true
        });

        setMarkups(response);
    }, []);

    useEffect(() => {
        if (_.get(modalProps, 'id') && !_.get(product, 'id')) {
            fetchProduct(_.get(modalProps, 'id'));
        }
    }, [fetchProduct, modalProps, product]);

    useEffect(() => {
        fetchBrands();
        fetchStoreGroups();
        fetchPriceGroups();
        fetchUnits();
        fetchMarkups();
    }, []);

    useEffect(() => {
        if (product) {
            form.setFieldsValue({ ...product });
        }
    }, [product, form]);

    const defaultByProducts = markups ? markups.find(({ defaultByProducts }) => defaultByProducts) : undefined;

    useEffect(() => {
        if (_.get(props.units, 'length') && !props.editing) {
            form.setFieldsValue({
                productUnitId: _.get(props.units, '[0].id'),
                markupGroupId: _.get(defaultByProducts, 'id'),
                getSellingPriceFrom: 'PRICE_GROUP',
                source: 'OWN'
            });
        }
    }, [defaultByProducts, form, markups, product, props.editing, props.units]);

    useEffect(() => {
        form.setFieldsValue({
            brandId,
            code,
            groupId,
            name,
            barcode,
            productUnitId
        });
    }, [brandId, code, groupId, name, barcode, productUnitId, form]);

    useEffect(() => {
        if (!storeGroupsTree.length && storeGroups && storeGroups.length) {
            const tree = buildStoreGroupsTree(storeGroups);
            setStoreGroupsTree(tree);
        }
    }, [storeGroups, storeGroupsTree]);

    const findGroupNameById = (data, groupId) => {
        let groupName = null;

        data.forEach(item => {
            if (item.id === groupId) {
                groupName = item.singleName;
            }

            if (!_.isEmpty(item.childGroups)) {
                const result = findGroupNameById(item.childGroups, groupId);
                if (!groupName) {
                    groupName = result;
                }
            }
        });

        return groupName;
    };

    const findGroupMultiplierById = (data, groupId) => {
        let resultNumber = null;

        data.forEach(item => {
            if (item.id === groupId) {
                resultNumber = item.priceGroupNumber;
            }

            if (!_.isEmpty(item.childGroups)) {
                const result = findGroupMultiplierById(item.childGroups, groupId);
                if (!resultNumber) {
                    resultNumber = result;
                }
            }
        });

        return resultNumber;
    };

    const onSelectProductGroup = value => {
        const groupName = findGroupNameById(storeGroups, value);
        form.setFieldsValue({ name: groupName });

        const priceGroup = findGroupMultiplierById(storeGroups, value);
        form.setFieldsValue({ priceGroupNumber: priceGroup });

        return value;
    };

    const onFinish = () => {
        const isAccessedSource = isGrantAccessed(
            props.user,
            grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD_SOURCE,
            accesses.ROWO
        );

        form.validateFields().then(async values => {
            const omitedValues = !isAccessedSource ? omit(values, ['source']) : values;
            if (values.code.match(/[^A-Za-z0-9\u0400-\u04FF]/g)) {
                notification.warning({
                    message: intl.formatMessage({
                        id: 'storage.forbidden_symbols'
                    })
                });

                return;
            }
            if (values.brandName && values.brandId) {
                _.set(values, 'brandId', void 0);
            }
            if (props.editing) {
                await props.updateProduct({
                    id: _.get(modalProps, 'id'),
                    product: omitedValues
                });

                if (values.source) {
                    window.location.reload();
                }
            } else {
                await props.createProduct(
                    omitedValues,
                    _.get(modalProps, 'onSubmit', () => {})
                );
            }
            await fetchProduct(_.get(modalProps, 'id'));

            form.resetFields();
            props.resetProductEanData();
            if (props.resetModal) {
                props.resetModal();
            }
        });
    };

    const deleteProduct = id => {
        props.deleteProduct(id);
        props.form.resetFields();
        if (props.resetModal) {
            props.resetModal();
        } else {
            props.history.replace({
                pathname: `${book.products}`
            });
        }
    };

    return (
        <Form
            disabled={disabled}
            form={form}
            initialValues={{
                ...product
            }}
            layout={!props.editing ? 'horizontal' : undefined}
            name='StoreProductForm'
            {...formItemLayout}
        >
            <Row gutter={isMobile || !props.editing ? 24 : undefined}>
                <Col span={isMobile || props.editing ? 24 : 12}>
                    <Form.Item label={<FormattedMessage id='navigation.barcode' />} name='barcode'>
                        <Input
                            addonAfter={
                                <Barcode
                                    iconStyle={{ cursor: 'pointer' }}
                                    buttonStyle={{height: 'auto'}}
                                    maxLength={7}
                                    onConfirm={(code, prefix) => {
                                        form.setFieldsValue({ barcode: `${prefix}-${code}` });
                                        props.fetchProductEanData(code);
                                        _.get(modalProps, 'reload') && window.location.reload();
                                    }}
                                    prefix='STP'
                                    product={product}
                                    referenceId={_.get(props, 'product.id')}
                                    table='STORE_PRODUCTS'
                                    value={form.getFieldValue('barcode')}
                                />
                            }
                            disabled
                            placeholder={formatMessage({
                                id: 'navigation.barcode'
                            })}
                            style={{
                                minWidth: 240,
                                color: 'var(--text)'
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({ id: 'storage.product_code' })}
                        name='code'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({ id: 'required_field' })
                            }
                        ]}
                    >
                        <Input
                            disabled={props.editing}
                            onBlur={async () => {
                                if (brandId && code) {
                                    const products = await fetchAPI(
                                        'GET',
                                        '/store_products',
                                        { filterBrand: brandId, filterCode: code },
                                        undefined
                                    );
                                    if (products.stats) {
                                        notification.warning({
                                            message: intl.formatMessage({
                                                id: 'storage.notification_already_exist'
                                            })
                                        });
                                    }
                                }
                            }}
                            placeholder={formatMessage({
                                id: 'storage.product_code'
                            })}
                            style={{
                                minWidth: 240,
                                color: 'var(--text)'
                            }}
                            suffix={
                                <Tooltip title={<FormattedMessage id='storage.forbidden_symbols' />}>
                                    <InfoCircleOutlined
                                        style={{
                                            color: 'rgba(0,0,0,.45)'
                                        }}
                                    />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({ id: 'storage.brand' })}
                        name='brandId'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({ id: 'required_field' })
                            }
                        ]}
                    >
                        <Select
                            disabled={props.editing}
                            dropdownMatchSelectWidth={280}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={async (value, elem) => {
                                const products = await fetchAPI(
                                    'GET',
                                    '/store_products',
                                    { filterBrand: value, filterCode: code },
                                    undefined
                                );
                                if (products.stats) {
                                    notification.warning({
                                        message: intl.formatMessage({
                                            id: 'storage.notification_already_exist'
                                        })
                                    });
                                }
                            }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'storage.brand'
                            })}
                            showSearch
                            style={{
                                minWidth: 240,
                                color: 'var(--text)'
                            }}
                        >
                            {props.brands.map(({ brandId, brandName }) => (
                                <Option key={`${brandId}-${brandName}`} value={brandId}>
                                    {brandName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({ id: 'storage.product_group' })}
                        name='groupId'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({ id: 'required_field' })
                            }
                        ]}
                    >
                        <TreeSelect
                            dropdownMatchSelectWidth={280}
                            filterTreeNode={filterTreeNodeByPart}
                            onSelect={e => onSelectProductGroup(e)}
                            placeholder={formatMessage({
                                id: 'storage.product_group'
                            })}
                            showSearch
                            style={{
                                minWidth: 240,
                                color: 'var(--text)'
                            }}
                            treeData={storeGroupsTree}
                        />
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({ id: 'storage.product_name' })}
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({ id: 'required_field' })
                            }
                        ]}
                    >
                        <Input
                            maxLength={255}
                            placeholder={formatMessage({
                                id: 'storage.product_name'
                            })}
                            style={{
                                minWidth: 240,
                                color: 'var(--text)'
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({ id: 'services_table.units_placeholder' })}
                        name='productUnitId'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({ id: 'required_field' })
                            }
                        ]}
                    >
                        <Select
                            dropdownMatchSelectWidth={280}
                            getPopupContainer={trigger => trigger.parentNode}
                            // onChange={productUnitId => {
                            //     selectUnits(productUnitId);
                            // }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'services_table.units_placeholder'
                            })}
                            showSearch
                            style={{
                                minWidth: 240,
                                color: 'var(--text)'
                            }}
                        >
                            {props.units.map(({ shortcut, id }) => (
                                <Option key={`${id}-${shortcut}`} value={id}>
                                    {shortcut}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={formatMessage({ id: 'storage.trade_code' })} name='tradeCode'>
                        <Input
                            placeholder={formatMessage({
                                id: 'storage.trade_code'
                            })}
                            type='number'
                        />
                    </Form.Item>
                    <Form.Item label={formatMessage({ id: 'storage.certificate' })} name='certificate'>
                        <Input
                            placeholder={formatMessage({
                                id: 'storage.certificate'
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({ id: 'product.enable_product' })}
                        name='enabled'
                        valuePropName='checked'
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item label={formatMessage({ id: 'orders.source' })} name='source'>
                        <Select
                            disabled={
                                !isGrantAccessed(
                                    props.user,
                                    grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD_SOURCE,
                                    accesses.ROWO
                                )
                            }
                            dropdownMatchSelectWidth={280}
                            getPopupContainer={trigger => trigger.parentNode}
                            // onChange={productUnitId => {
                            //     selectUnits(productUnitId);
                            // }}
                            optionFilterProp='children'
                            placeholder={formatMessage({
                                id: 'orders.source'
                            })}
                            showSearch
                            style={{
                                minWidth: 240,
                                color: 'var(--text)'
                            }}
                        >
                            {Object.entries(sourceType).map(([key, value]) => (
                                <Option key={key} value={key}>
                                    <FormattedMessage id={value} />
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                {!props.editing && (
                    <Col span={isMobile ? 24 : 12}>
                        <Form.Item label={formatMessage({ id: 'Обрати ціну з' })} name='getSellingPriceFrom'>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder={formatMessage({
                                    id: 'new-document-page.item.markup-group'
                                })}
                                style={{
                                    width: '100%'
                                }}
                            >
                                <Option key='FIXED' value='FIXED'>
                                    <FormattedMessage id='storage.markup.PRICE_GROUP' />
                                </Option>
                                <Option key='PRICE_GROUP' value='PRICE_GROUP'>
                                    <FormattedMessage id='storage.markup.FIXED_MARKUP' />
                                </Option>
                                <Option key='STORE_GROUP' value='STORE_GROUP'>
                                    <FormattedMessage id='storage.markup.STORE_GROUP' />
                                </Option>
                                <Option key='CLIENT' value='CLIENT'>
                                    <FormattedMessage id='storage.markup.CLIENT' />
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={formatMessage({ id: 'product.fixed_sale_price' })} name='fixedSellingPrice'>
                            <InputNumber
                                decimalSeparator=','
                                onChange={value => {
                                    form.setFieldValue('fixedSellingPrice', value);
                                }}
                                placeholder={formatMessage({
                                    id: 'product.fixed_sale_price'
                                })}
                                precision={2}
                                style={{ color: 'var(--text)', width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='product.markup' />} name='markupGroupId'>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder={formatMessage({
                                    id: 'new-document-page.item.markup-group'
                                })}
                                style={{
                                    width: '100%'
                                }}
                            >
                                {markups
                                    ? markups
                                          .filter(({ enabled, using }) => enabled && using !== 'CUSTOMER_GROUP')
                                          .map(markup => {
                                              return (
                                                  <Option key={markup.using} value={markup.id}>
                                                      {markup.name}{' '}
                                                      {<FormattedMessage id={`product_markups.${markup.using}`} />} (
                                                      {markup.markups[0] ? markup.markups[0].lowerLimit : undefined} -{' '}
                                                      {markup.markups[0] ? markup.markups[0].markup : undefined};{' '}
                                                      {markup.markups[1] ? markup.markups[1].lowerLimit : undefined} -{' '}
                                                      {markup.markups[1] ? markup.markups[1].markup : undefined};
                                                      {markup.markups[2] ? markup.markups[2].lowerLimit : undefined} -{' '}
                                                      {markup.markups[2] ? markup.markups[2].markup : undefined};
                                                      {markup.markups[3] ? '...' : ''})
                                                  </Option>
                                              );
                                          })
                                    : []}
                            </Select>
                        </Form.Item>
                    </Col>
                )}
            </Row>

            <ButtonGroup>
                {props.editing ? (
                    <DeleteButton onClick={() => deleteProduct(_.get(props, 'modalProps.id'))} type='danger'>
                        {props.intl.formatMessage({ id: 'delete' })}
                    </DeleteButton>
                ) : null}
                <SubmitButton
                    loading={loadings}
                    onClick={async () => {
                        setLoading(true);
                        await onFinish();
                        setLoading(false);
                    }}
                    type='primary'
                >
                    {props.intl.formatMessage({ id: 'submit' })}
                </SubmitButton>
            </ButtonGroup>
        </Form>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    storeGroups: selectStoreGroups(state),
    priceGroups: selectPriceGroups(state),
    brands: selectBrands(state),
    loading: selectProductLoading(state),
    product: selectStoreProduct(state),
    productEanData: selectStoreProductEanData(state),
    errors: state.ui.errors,
    units: selectUnits(state),
    isMobile: state.ui.views.isMobile

});

const mapDispatchToProps = {
    fetchProducts,
    fetchProduct,
    fetchProductEanData,
    fetchStoreGroups,
    fetchPriceGroups,
    setBrandsSearchQuery,
    createProduct,
    updateProduct,
    deleteProduct,
    fetchBrands,
    handleError,
    resetProductEanData,
    fetchUnits
};

export const StoreProductForm = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductForm)));
