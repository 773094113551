import { get } from 'lodash';
import { accesses, grants } from '../../../shared/scope-utils/grants_v2';
import { routeToGrant } from './routeToGrant';
import { decompressAsync, hasAccess } from './scope_utils';

const genManagerScope = async user => {
    const managerScope = user.compressedScope ? await decompressAsync(user.compressedScope) : '';

    return {
        ...user,
        managerScope
    };
};

// eslint-disable-next-line require-await
const isGrantAccessed = (user = {}, grant = grants.ADMIN, access = accesses.RO) => {
    try {
        const { managerScope } = user;
        const scope = Buffer.isBuffer(managerScope) ? managerScope : Buffer.from(managerScope.data);
        const res = hasAccess({ scope, grant, access });

        return res;
    } catch (e) {
        console.error('removeToken', e);
        // removeToken();
    }
};

const isAdminAccess = user => isGrantAccessed(user);

const isRouteAccessable = (user, route) => {
    const grant = get(routeToGrant, `[${route}]`, false);

    return !grant ? true : isGrantAccessed(user, grant);
};

const getRouteGrant = route => {
    const grant = get(routeToGrant, `[${route}]`, false);

    return grant;
};

export { accesses, genManagerScope, getRouteGrant, grants, isAdminAccess, isGrantAccessed, isRouteAccessable };
