import { Select, Spin } from 'antd';
import { setSupplierSearchQuery } from 'core/search/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    suppliers: state.search.suppliers,
    isFetchingSuppliers: state.search.isFetchingSuppliers
});

const mapDispatchToProps = {
    setSupplierSearchQuery
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SupplierSearchField extends Component {
    render() {
        const { onSelect, setSupplierSearchQuery } = this.props;
        const { suppliers, isFetchingSuppliers, supplierId } = this.props;

        return (
            <Select
                allowClear
                filterOption={false}
                notFoundContent={
                    isFetchingSuppliers ? (
                        <Spin size='small' />
                    ) : (
                        <FormattedMessage id='not_found' />
                    )
                }
                onChange={supplierId => onSelect(supplierId)}
                onSearch={item => setSupplierSearchQuery(item)}
                placeholder={this.props.intl.formatMessage({
                    id: 'supplier_placeholder'
                })}
                showSearch
                style={this.props.selectStyles}
                value={supplierId}
            >
                {isFetchingSuppliers
                    ? []
                    : suppliers.map(({ supplierId, name }) => (
                          <Option key={supplierId} value={supplierId}>
                              {name}
                          </Option>
                      ))}
            </Select>
        );
    }
}
