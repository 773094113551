import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

const ActionsIcons = styled.div`
    display: flex;
    align-items: center;
`;

export default injectIntl(props => {
    return (
        <ActionsIcons>
            {props.edit && (
                <EditOutlined
                    onClick={props.edit}
                    style={{
                        fontSize: 24,
                        cursor: 'pointer'
                    }}
                />
            )}
            {props.delete && (
                <Popconfirm
                    onConfirm={props.delete}
                    title={`${props.intl.formatMessage({ id: 'delete' })} ?`}
                >
                    <DeleteOutlined
                        style={{
                            fontSize: 24,
                            cursor: 'pointer'
                        }}
                    />
                </Popconfirm>
            )}
        </ActionsIcons>
    );
});
