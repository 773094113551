/* eslint-disable id-denylist */
/* eslint-disable import/extensions */
import Icon from '@ant-design/icons';
import React from 'react';
// Icons
import { ReactComponent as BookIconSvg } from './images/icons/book.svg';
import { ReactComponent as BrakeIconSvg } from './images/icons/brake.svg';
import { ReactComponent as CallsSvg } from './images/icons/calls.svg';
import { ReactComponent as CarPartsIconSvg } from './images/icons/car-parts.svg';
import { ReactComponent as CashboxIconSvg } from './images/icons/cashbox.svg';
import { ReactComponent as CheckIconSvg } from './images/icons/check.svg';
import { ReactComponent as CommentSvg } from './images/icons/comment.svg';
import { ReactComponent as ComplexesIconSvg } from './images/icons/complexes.svg';
import { ReactComponent as CraneIconSvg } from './images/icons/crane.svg';
import { ReactComponent as CrossReferenceIconSvg } from './images/icons/cross-reference.svg';
import { ReactComponent as DeliveryTruckIconSvg } from './images/icons/delivery-truck.svg';
import { ReactComponent as DiagnosticSvg } from './images/icons/diagnostic.svg';
import { ReactComponent as DocumentsSvg } from './images/icons/documents.svg';
import FeaturesSvg from './images/icons/features.svg';
import { ReactComponent as GridIconSvg } from './images/icons/grid.svg';
import { ReactComponent as IntakeSvg } from './images/icons/intake.svg';
import { ReactComponent as ListIconSvg } from './images/icons/list.svg';
import { ReactComponent as ClosedLockIconSvg } from './images/icons/lock-closed.svg';
import { ReactComponent as OpenLockIconSvg } from './images/icons/lock-open.svg';
import { ReactComponent as LogsSvg } from './images/icons/logs.svg';
import { ReactComponent as MapSvg } from './images/icons/map.svg';
import { ReactComponent as MechanicIconSvg } from './images/icons/mechanic.svg';
import { ReactComponent as OilIconSvg } from './images/icons/oil.svg';
import { ReactComponent as PartsIconSvg } from './images/icons/parts.svg';
import { ReactComponent as PencilIconSvg } from './images/icons/pencil.svg';
import { ReactComponent as PistonIconSvg } from './images/icons/piston.svg';
import { ReactComponent as PriceTagIconSvg } from './images/icons/price-tag.svg';
import { ReactComponent as RecommendationsSvg } from './images/icons/recommendations.svg';
import { ReactComponent as RegulationsSvg } from './images/icons/regulations.svg';
import { ReactComponent as RepairIconSvg } from './images/icons/repair.svg';
import { ReactComponent as ReportIconSvg } from './images/icons/report.svg';
import { ReactComponent as RequestSvg } from './images/icons/request.svg';
import { ReactComponent as RespondSvg } from './images/icons/respond.svg';
import { ReactComponent as SparePartsSvg } from './images/icons/spare-parts.svg';
import { ReactComponent as StationsSvg } from './images/icons/stations.svg';
import { ReactComponent as StatisticsSvg } from './images/icons/statistics.svg';
import { ReactComponent as StockIconSvg } from './images/icons/stock.svg';
import { ReactComponent as TasksSvg } from './images/icons/tasks.svg';
import { ReactComponent as VehicleHistorySvg } from './images/icons/vehicle-history.svg';
import { ReactComponent as VinIconSvg } from './images/icons/vin.svg';
import { ReactComponent as WorkshopSvg } from './images/icons/workshop.svg';
import { ReactComponent as WrenchIconSvg } from './images/icons/wrench.svg';

const BookIcon = props => <Icon component={BookIconSvg} {...props} />;
const CallsIcon = props => <Icon component={CallsSvg} {...props} />;
const StatisticsIcon = props => <Icon component={StatisticsSvg} {...props} />;
const DocumentsIcon = props => <Icon component={DocumentsSvg} {...props} />;
const LogsIcon = props => <Icon component={LogsSvg} {...props} />;
const CommentIcon = props => <Icon component={CommentSvg} {...props} />;
const RespondIcon = props => <Icon component={RespondSvg} {...props} />;
const RecommendationsIcon = props => <Icon component={RecommendationsSvg} {...props} />;
const WorkshopIcon = props => <Icon component={WorkshopSvg} {...props} />;
const DiagnosticIcon = props => <Icon component={DiagnosticSvg} {...props} />;
const StationsIcon = props => <Icon component={StationsSvg} {...props} />;
const IntakeIcon = props => <Icon component={IntakeSvg} {...props} />;
const RequestIcon = props => <Icon component={RequestSvg} {...props} />;
const MapIcon = props => <Icon component={MapSvg} {...props} />;
const BrakeIcon = props => <Icon component={BrakeIconSvg} {...props} />;
const CarPartsIcon = props => <Icon component={CarPartsIconSvg} {...props} />;
const CashboxIcon = props => <Icon component={CashboxIconSvg} {...props} />;
const CheckIcon = props => <Icon component={CheckIconSvg} {...props} />;
const ComplexesIcon = props => <Icon component={ComplexesIconSvg} {...props} />;
const CraneIcon = props => <Icon component={CraneIconSvg} {...props} />;
const CrossReferenceIcon = props => <Icon component={CrossReferenceIconSvg} {...props} />;
const DeliveryTruckIcon = props => <Icon component={DeliveryTruckIconSvg} {...props} />;
const GridIcon = props => <Icon component={GridIconSvg} {...props} />;
const ListIcon = props => <Icon component={ListIconSvg} {...props} />;
const ClosedLockIcon = props => <Icon component={ClosedLockIconSvg} {...props} />;
const OpenLockIcon = props => <Icon component={OpenLockIconSvg} {...props} />;
const MechanicIcon = props => <Icon component={MechanicIconSvg} {...props} />;
const OilIcon = props => <Icon component={OilIconSvg} {...props} />;
const PartsIcon = props => <Icon component={PartsIconSvg} {...props} />;
const PencilIcon = props => <Icon component={PencilIconSvg} {...props} />;
const PistonIcon = props => <Icon component={PistonIconSvg} {...props} />;
const PriceTagIcon = props => <Icon component={PriceTagIconSvg} {...props} />;
const ReportIcon = props => <Icon component={ReportIconSvg} {...props} />;
const StockIcon = props => <Icon component={StockIconSvg} {...props} />;
const VinIcon = props => <Icon component={VinIconSvg} {...props} />;
const WrenchIcon = props => <Icon component={WrenchIconSvg} {...props} />;
const FeaturesIcons = ({ name, color, size }) => (
    <svg className={`icon icon-${name}`} fill={color} height={size} width={size}>
        <use xlinkHref={`${FeaturesSvg}#${name}`} xmlns='http://www.w3.org/2000/svg' />
    </svg>
);
const RepairIcon = props => <Icon component={RepairIconSvg} {...props} />;
const SparePartsIcon = props => <Icon component={SparePartsSvg} {...props} />;
const VehicleHistoryIcon = props => <Icon component={VehicleHistorySvg} {...props} />;
const TasksIcon = props => <Icon component={TasksSvg} {...props} />;
const RegulationsIcon = props => <Icon component={RegulationsSvg} {...props} />;

export {
    BookIcon,
    CallsIcon,
    LogsIcon,
    CommentIcon,
    WorkshopIcon,
    DocumentsIcon,
    RespondIcon,
    RecommendationsIcon,
    DiagnosticIcon,
    StationsIcon,
    RequestIcon,
    MapIcon,
    BrakeIcon,
    CarPartsIcon,
    CashboxIcon,
    CheckIcon,
    ComplexesIcon,
    CraneIcon,
    CrossReferenceIcon,
    DeliveryTruckIcon,
    GridIcon,
    ListIcon,
    ClosedLockIcon,
    OpenLockIcon,
    MechanicIcon,
    OilIcon,
    PartsIcon,
    PencilIcon,
    PistonIcon,
    PriceTagIcon,
    ReportIcon,
    StockIcon,
    VinIcon,
    WrenchIcon,
    FeaturesIcons,
    RepairIcon,
    SparePartsIcon,
    VehicleHistoryIcon,
    TasksIcon,
    IntakeIcon,
    StatisticsIcon,
    RegulationsIcon
};
