import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import _ from 'lodash';
import { AddVehicleRegulationsModal } from 'modals';
import { fetchVehicleInspectionIntervals } from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import VehicleRegulationsTable from './VehicleRegulationsTable';
import Styles from './styles.m.css';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    fetchVehicleInspectionIntervals
};

/**
 * Show vehicle inspections intervals to know when change old details and make service labors.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleRegulationsTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allVehicleRegulations: [],
            data: {
                currentVehicleInfo: {}
            },
            labors: [],
            unitDefault: [],
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    componentDidMount() {
        this.fetchVehicleRegulations();
        this.fetchLabors();
        this.fetchUnitsSettings();
    }

    fetchVehicleRegulations = async () => {
        const allVehicleRegulations = await fetchAPI(
            'GET',
            'vehicle/regulations',
            { vehicleId: this.props.vehicleId },
            undefined,
            { handleErrorInternally: true }
        );
        this.setState({
            allVehicleRegulations: allVehicleRegulations.result || [],
            data: {
                currentVehicleInfo: allVehicleRegulations.vehicleOptions
            }
        });
    };

    fetchLabors = async () => {
        const { labors } = await fetchAPI('GET', 'labors', null, null, {
            handleErrorInternally: true
        });
        this.setState({
            labors
        });
    };

    editRegulations = row => {
        this.setState({
            visibleAddVehicleRegulationsModal: true,
            regulationModalRow: row
        });
    };

    editRegulationRow = async (id, regulationName, regulationKm, regulationMonths, lastChangeKm, lastChangeMonth) => {
        await fetchAPI('PUT', 'vehicle/regulations', undefined, {
            id,
            regulationName,
            regulationKm,
            regulationMonths,
            lastKm: lastChangeKm,
            lastDate: lastChangeMonth,
            visibility: true
        });
        await setTimeout(() => this.fetchVehicleRegulations(), 500);
    };

    fetchUnitsSettings = async () => {
        const units = await fetchAPI('GET', 'business/measurement/units/settings', undefined, undefined);
        this.setState({
            unitDefault: units.map(({ measurementUnits }) => measurementUnits).flat()
        });
    };

    render() {
        const { disabled } = this.props;
        const { allLinks, helperDrawerOpen } = this.state;

        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    <Button
                        disabled={disabled}
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            const links = await fetchAPI(
                                'GET',
                                '/helps',
                                {
                                    helpId: 'order_mrd_add_regulations_data'
                                },
                                undefined,
                                {
                                    handleErrorInternally: true
                                }
                            );
                            this.setState({
                                allLinks: links,
                                helperDrawerOpen: true
                            });
                        }}
                        style={{
                            fontSize: 22,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                </div>
                <VehicleRegulationsTable
                    allVehicleRegulations={this.state.allVehicleRegulations}
                    data={this.state.data}
                    disabled={disabled}
                    editRegulations={this.editRegulations}
                    fetchVehicleRegulations={this.fetchVehicleRegulations}
                    labors={this.state.labors}
                    regulationModalRow={this.state.regulationModalRow}
                    unitDefault={this.state.unitDefault}
                    vehicleId={this.props.vehicleId}
                />
                <AddVehicleRegulationsModal
                    editRegulationRow={this.editRegulationRow}
                    fetchVehicleRegulations={this.fetchVehicleRegulations}
                    hideModal={() => {
                        this.setState({
                            visibleAddVehicleRegulationsModal: false
                        });
                    }}
                    regulationModalRow={this.state.regulationModalRow}
                    visible={this.state.visibleAddVehicleRegulationsModal}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}
