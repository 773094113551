import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchCashboxesSuccess,
    FETCH_CASHBOXES,
    selectSelectedCashboxId,
    setFetchingCashboxes,
    setSelectedCashboxId
} from './duck';

export function* fetchCashboxesSaga() {
    while (true) {
        try {
            yield take(FETCH_CASHBOXES);

            yield put(setFetchingCashboxes(true));
            const response = yield call(fetchAPI, 'GET', 'cash_boxes');
            const firstCashboxId = _.get(response, '[0].id');

            const selectedCashBoxId = yield select(selectSelectedCashboxId);
            if (!selectedCashBoxId) {
                yield put(setSelectedCashboxId(firstCashboxId)); // Set cashbox id if it is not already selected
            }

            yield put(fetchCashboxesSuccess({ cashboxes: response }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingCashboxes(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchCashboxesSaga)]);
}
