import { Radio } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export default props => {
    return (
        // <RadioGroup value={ filterStatus }>
        <RadioGroup value={props.status}>
            <RadioButton onClick={() => props.setFilters({ status: null })} value={null}>
                <FormattedMessage id='all' />
            </RadioButton>
            {props.type === 'expenses' ? (
                <React.Fragment>
                    <RadioButton
                        key='NEW'
                        onClick={() => props.setFilters({ status: 'NEW' })}
                        value='NEW'
                    >
                        <FormattedMessage id='storage.status.NEW' />
                    </RadioButton>
                    <RadioButton
                        key='DONE'
                        onClick={() => props.setFilters({ status: 'DONE' })}
                        value='DONE'
                    >
                        <FormattedMessage id='storage.status.OFF' />
                    </RadioButton>
                </React.Fragment>
            ) : (
                props.statuses.map(status => (
                    <RadioButton
                        key={status}
                        onClick={() => props.setFilters({ status })}
                        value={status}
                    >
                        <FormattedMessage id={`storage.status.${status}`} />
                    </RadioButton>
                ))
            )}
        </RadioGroup>
    );
};
