import dayjs from 'dayjs';

/* Constants */
export const moduleName = 'accountsReceivableReportModal';
const prefix = `cpb/${moduleName}`;

export const DOWNLOAD_REPORT = `${prefix}/DOWNLOAD_SALES_REPORT`;

export const SET_REPORT_FILTERS = `${prefix}/SET_REPORT_FILTERS`;
export const RESET_REPORT_FILTERS = `${prefix}/RESET_REPORT_FILTERS`;

export const FETCH_CLIENTS = `${prefix}/FETCH_CLIENTS`;
export const FETCH_CLIENTS_SUCCESS = `${prefix}/FETCH_CLIENTS_SUCCESS`;

export const SET_CLIENT_REQUISITES = `${prefix}/SET_CLIENT_REQUISITES`;

export const FETCH_BUSINESS_REQUISITES = `${prefix}/FETCH_BUSINESS_REQUISITES`;
export const FETCH_BUSINESS_REQUISITES_SUCCESS = `${prefix}/FETCH_BUSINESS_REQUISITES_SUCCESS`;

export const SET_ACT_BUTTON_DISABLED = `${prefix}/SET_ACT_BUTTON_DISABLED`;

/* Available detailing values for report */
export const detailingValues = {
    withoutDetainling: 'without',
    docsDetailing: 'docs',
    actDetailing: 'act'
};

/** Date format for backend */
export const DATE_FORMAT = 'YYYY-MM-DD';

/* Reducer */
const ReducerState = {
    filters: {
        clientId: undefined,
        clientRequisiteId: undefined,
        businessRequisiteId: undefined,
        date: {
            from: dayjs().startOf('month'),
            to: dayjs().endOf('month')
        },
        detailing: detailingValues.withoutDetainling,
        withoutReturning: false,
        filterByDeliveryDate: false
    },

    clients: [],
    clientRequisites: [],
    businessRequisites: [],

    actButtonDisabled: true
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_REPORT_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...payload }
            };

        case RESET_REPORT_FILTERS:
            return {
                ...state,
                filters: {
                    clientId: undefined,
                    clientRequisiteId: undefined,
                    businessRequisiteId: undefined,
                    date: {
                        from: dayjs().startOf('month'),
                        to: dayjs().endOf('month')
                    },
                    detailing: detailingValues.withoutDetainling
                },
                actButtonDisabled: true
            };
        case FETCH_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: payload
            };

        case SET_CLIENT_REQUISITES:
            return {
                ...state,
                clientRequisites: payload
            };

        case FETCH_BUSINESS_REQUISITES_SUCCESS:
            return {
                ...state,
                businessRequisites: payload
            };

        case SET_ACT_BUTTON_DISABLED:
            return {
                ...state,
                actButtonDisabled: payload,
                ...(payload
                    ? {
                          filters: {
                              ...state.filters,
                              detailing: detailingValues.withoutDetainling
                          }
                      }
                    : {})
            };

        default:
            return state;
    }
}

/* Selectors */

export const selectReportFilters = state => state[moduleName].filters;
export const selectClients = state => state[moduleName].clients;
export const selectClientRequisites = state => state[moduleName].clientRequisites;
export const selectBusinessRequisites = state => state[moduleName].businessRequisites;
export const selectActButtonDisabled = state => state[moduleName].actButtonDisabled;

/* Actions */

export const downloadReport = () => ({
    type: DOWNLOAD_REPORT
});

export const setReportFilters = filters => ({
    type: SET_REPORT_FILTERS,
    payload: filters
});

export const resetReportFilters = () => ({
    type: RESET_REPORT_FILTERS
});

export const fetchClients = () => ({
    type: FETCH_CLIENTS
});

export const fetchClientsSuccess = clients => ({
    type: FETCH_CLIENTS_SUCCESS,
    payload: clients
});

export const setClientRequisites = clientRequisites => ({
    type: SET_CLIENT_REQUISITES,
    payload: clientRequisites
});

export const fetchBusinessRequisites = () => ({
    type: FETCH_BUSINESS_REQUISITES
});

export const fetchBusinessRequisitesSuccess = requisites => ({
    type: FETCH_BUSINESS_REQUISITES_SUCCESS,
    payload: requisites
});

export const setActButtonDisabled = disabled => ({
    type: SET_ACT_BUTTON_DISABLED,
    payload: disabled
});
