exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__table---2Z07D{min-width:0!important;width:100%}.styles-m__table---2Z07D .ant-table-header{text-transform:uppercase;min-width:0!important;padding:0}.styles-m__table---2Z07D .ant-pagination{float:none;justify-content:center;display:flex}.styles-m__table---2Z07D th{padding:6px 4px!important}.styles-m__tableContainer---2D4Pr{background-color:initial;margin:.1em .2em .4em}.styles-m__vehicle---DZfjA{display:flex;justify-content:space-between;margin-bottom:.4em}.styles-m__phoneNumber---2podg{color:var(--link)}.styles-m__phoneNumber---2podg:hover{color:var(--primary)}.styles-m__selectedRow---28D1O{background-color:var(--db_reserve);color:var(--text);font-weight:400}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AACA,yBACI,qBAAuB,CACvB,UACJ,CAEA,2CACI,wBAAyB,CACzB,qBAAuB,CACvB,SACJ,CAEA,yCACI,UAAW,CACX,sBAAuB,CACvB,YACJ,CAEA,4BACI,yBACJ,CAEA,kCACI,wBAA6B,CAC7B,qBACJ,CAEA,2BACI,YAAa,CACb,6BAA8B,CAC9B,kBACJ,CAEA,+BACI,iBACJ,CAEA,qCACI,oBACJ,CAEA,+BACI,kCAAmC,CACnC,iBAAkB,CAClB,eACJ","file":"styles.m.css","sourcesContent":["    \n.table {\n    min-width: 0 !important;\n    width: 100%;\n}\n\n.table :global(.ant-table-header) {\n    text-transform: uppercase;\n    min-width: 0 !important; /*IMPORTANT it solves antd table issue when scrolling is added*/\n    padding: 0;\n}\n\n.table :global(.ant-pagination) {\n    float: none;\n    justify-content: center;\n    display: flex;\n}\n\n.table :global(th) {\n    padding: 6px 4px 6px 4px !important; /*Remove bug: when text overflows, header shifts left*/\n}\n\n.tableContainer {\n    background-color: transparent;\n    margin: 0.1em 0.2em 0.4em 0.2em;\n}\n\n.vehicle {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 0.4em;\n}\n\n.phoneNumber {\n    color: var(--link);\n}\n\n.phoneNumber:hover {\n    color: var(--primary);\n}\n\n.selectedRow {\n    background-color: var(--db_reserve);    \n    color: var(--text);\n    font-weight: 400;\n}"]}]);

// Exports
exports.locals = {
	"table": "styles-m__table---2Z07D",
	"tableContainer": "styles-m__tableContainer---2D4Pr",
	"vehicle": "styles-m__vehicle---DZfjA",
	"phoneNumber": "styles-m__phoneNumber---2podg",
	"selectedRow": "styles-m__selectedRow---28D1O"
};