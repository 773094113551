import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import AddBussinessModal from '../AddBussinessModal';

const BussinessTab = ({ intl, user }) => {
    const [bussinesses, setBussinesses] = useState([]);
    const [visible, setVisibleModal] = useState(false);
    const [edit, setEdit] = useState();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredBussinesses, setFilteredBussinesses] = useState([]);

    const fetchBussinesses = useMemo(
        () => async () => {
            const res = await fetchAPI('GET', '/businesses', null, null, {
                handleErrorInternally: true
            });

            setBussinesses(res.list);
        },
        []
    );

    const editRow = row => {
        setVisibleModal(true);
        setEdit(row);
    };

    // const deleteRow = async row => {
    //     await fetchAPI('DELETE', `/billing/suggestion_groups_businesses_ref/${row.id}`, null, null, {
    //         handleErrorInternally: true
    //     });

    //     fetchBussinesses();
    // };

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='Id' />,
                key: 'id',
                dataIndex: 'id',
                align: 'center',
                width: '5%'
            },
            {
                title: <FormattedMessage id='monetization.name' />,
                key: 'name',
                dataIndex: 'name',
                width: '10%'
            },
            {
                title: <FormattedMessage id='E-mail' />,
                key: 'email',
                dataIndex: 'email',
                width: '10%'
            },
            {
                title: <FormattedMessage id='monetization.phone_number' />,
                key: 'phone',
                width: '10%',
                render: row =>
                    row.phones ? row.phones.map(({ number }) => `${number}; `) : <FormattedMessage id='long_dash' />
            },
            {
                title: <FormattedMessage id='monetization.address' />,
                key: 'address',
                dataIndex: 'address',
                width: '10%'
            }
            // {
            //     key: 'actions',
            //     width: '5%',
            //     render: row => {
            //         return (
            //             <div
            //                 style={{
            //                     display: 'flex',
            //                     justifyContent: ' space-around'
            //                 }}
            //             >
            //                 <Button icon={<EditOutlined />} onClick={() => editRow(row)} />
            //                 {/* <Popconfirm
            //                     onConfirm={() => deleteRow(row)}
            //                     title={`${intl.formatMessage({ id: 'delete' })} ?`}
            //                 >
            //                     <Button icon={<DeleteOutlined />} />
            //                 </Popconfirm> */}
            //             </div>
            //         );
            //     }
            // }
        ];
    }, [intl]);

    const renderNestedTable = useCallback(
        roots => {
            const tableProps = {
                dataSource: roots,
                columns,
                rowKey: 'id'
            };

            return <Table {...tableProps} bordered rowKey='id' />;
        },
        [columns]
    );

    const handleSearch = event => {
        const query = event.target.value;
        setSearchQuery(query);

        // Filter the data based on the search query
        const filtered = bussinesses.filter(
            item =>
                (item.name && item.name.toLowerCase().includes(query.toLowerCase())) ||
                (item.id && String(item.id).toLowerCase().includes(query.toLowerCase()))
        );
        setFilteredBussinesses(filtered);
    };

    useEffect(() => {
        fetchBussinesses();
    }, [fetchBussinesses]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginBottom: 8
                }}
            >
                <Input
                    onChange={event => handleSearch(event)}
                    placeholder={intl.formatMessage({
                        id: 'search'
                    })}
                    style={{
                        width: '100%'
                    }}
                    value={searchQuery}
                />
                <Button icon={<PlusOutlined />} onClick={() => setVisibleModal(true)} />
            </div>
            {renderNestedTable(!searchQuery ? bussinesses : filteredBussinesses)}
            <AddBussinessModal
                businessId={user ? user.businessId : null}
                edit={edit}
                fetchBussinesses={fetchBussinesses}
                hideModal={() => {
                    setVisibleModal(false);
                    setEdit(undefined);
                }}
                open={visible}
            />
        </div>
    );
};

export default injectIntl(BussinessTab);
