import { Form } from '@ant-design/compatible';
import { AutoComplete } from 'antd';
import React, { forwardRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';

const { Option } = AutoComplete;
const FormItem = Form.Item;

/**
 * @property {Object} [autoCompleteLayout] - access AutoComplete component from antd
 * @property {Object} [formItemLayout] - access FormItem component from antd
 */
export const DecoratedAutoComplete = memo(
    forwardRef((props, ref) => {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            style,
            hasFeedback,
            formItemLayout,

            onPressEnter,

            // DecoratedField
            children,
            getFieldDecorator,
            getPopupContainer,
            field,
            rules,
            mode,
            disabled,
            showSearch,
            allowClear,
            placeholder,
            defaultOpen,
            // open,
            notFoundContent,
            onSearch,
            onChange,
            onSelect,
            options = [],
            optionValue,
            optionLabel,
            optionDisabled,
            filterOption,
            optionFilterProp,
            labelInValue,
            optionLabelProp,
            getItemValue,
            autoCompleteLayout,

            cnStyles,
            dropdownMatchSelectWidth,
            dropdownStyle,
            dropdownClassName,

            initialValue,
            onFocus
        } = props;

        const renderAutoComplete = (
            <AutoComplete
                ref={ref}
                allowClear={allowClear}
                className={cnStyles}
                defaultOpen={defaultOpen}
                disabled={disabled}
                dropdownClassName={dropdownClassName}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                dropdownStyle={dropdownStyle}
                filterOption={
                    filterOption ||
                    ((input, option) =>
                        option.props.children &&
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                }
                getItemValue={getItemValue}
                getPopupContainer={getPopupContainer}
                labelInValue={labelInValue}
                mode={mode}
                notFoundContent={notFoundContent || <FormattedMessage id='no_data' />}
                // open={ open }
                onChange={onChange}
                onFocus={onFocus}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onPressEnter();
                    }
                }}
                onSearch={onSearch}
                onSelect={onSelect}
                optionFilterProp={optionFilterProp || 'children'}
                optionLabelProp={optionLabelProp}
                placeholder={placeholder}
                showSearch={showSearch}
                {...autoCompleteLayout}
            >
                {children ||
                    options.map(option => (
                        <Option
                            key={v4()}
                            disabled={option[optionDisabled]}
                            value={option[optionValue]}
                        >
                            {option[optionLabel]}
                        </Option>
                    ))}
            </AutoComplete>
        );

        let autoComplete = null;
        if (getFieldDecorator) {
            autoComplete = getFieldDecorator(field, {
                ...(initialValue ? { initialValue } : { initialValue: void 0 }),
                rules
            })(renderAutoComplete);
        } else {
            autoComplete = renderAutoComplete;
        }

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                label={label}
                style={style}
                {...formItemLayout}
            >
                {autoComplete}
            </FormItem>
        ) : (
            autoComplete
        );
    })
);
