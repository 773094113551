import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Select, Switch, Table } from 'antd';
import { fetchVehicleTypes } from 'core/vehicleTypes/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Layout } from 'tireFitting';
import { fetchAPI } from 'utils';

const { Option } = Select;
const MAIN = 'MAIN';
const RESERVE = 'RESERVE';
const TOOL = 'TOOL';
const REPAIR_AREA = 'REPAIR_AREA';

const mapStateToProps = state => {
    return {
        user: state.auth,
        vehicleTypes: state.vehicleTypes.vehicleTypes
    };
};

const mapDispatchToProps = {
    fetchVehicleTypes
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TirePriceGroupsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            newGroup: {}
        };

        this.fetchData = this.fetchData.bind(this);

        this.columns = [
            {
                title: <FormattedMessage id='tire.name' />,
                key: 'name',
                dataIndex: 'name',
                width: 'auto'
            },
            {
                title: <FormattedMessage id='tire.vehicleType' />,
                key: 'vehicleTypeName',
                dataIndex: 'vehicleTypeName',
                width: 'auto'
            },
            {
                title: <FormattedMessage id='tire.minRadius' />,
                key: 'minRadius',
                dataIndex: 'minRadius',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            max={Number(elem.maxRadius)}
                            min={0}
                            onChange={value => {
                                elem.minRadius = value || 0;
                                this.updateGroups();
                            }}
                            value={Math.round(data)}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='tire.maxRadius' />,
                key: 'maxRadius',
                dataIndex: 'maxRadius',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            min={Number(elem.minRadius)}
                            onChange={value => {
                                elem.maxRadius = value || 0;
                                this.updateGroups();
                            }}
                            value={Math.round(data)}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='supplier.show' />,
                key: 'visible',
                dataIndex: 'visible',
                width: 'auto',
                render: (data, elem) => {
                    return (
                        <Switch
                            checked={data}
                            onChange={checked => {
                                elem.visible = checked;
                                this.updateGroups();
                            }}
                        />
                    );
                }
            },
            {
                key: 'edit',
                render: row => (
                    <EditOutlined
                        onClick={() => this.addPriceGroup(row.id)}
                        style={{
                            fontSize: 22
                        }}
                    />
                )
            },
            {
                key: 'delete',
                render: row => (
                    <DeleteOutlined
                        onClick={() => this.deleteGroup(row.id)}
                        style={{
                            fontSize: 22,
                            color: 'var(--disabled)'
                        }}
                    />
                )
            }
        ];
    }

    fetchData = async () => {
        if (this.modal) {
            this.modal.destroy();
            this.modal = undefined;
        }
        const dataSource = await fetchAPI('GET', 'tire_station_price_groups', null, null, {handleErrorInternally: true});
        this.setState({
            dataSource,
            newGroup: {}
        });
    };

    updateGroups = async () => {
        const { dataSource } = this.state;
        dataSource.map(elem => {
            delete elem.vehicleTypeName;
        });
        await fetchAPI('PUT', 'tire_station_price_groups', null, dataSource, {
            handleErrorInternally: true
        });
        this.fetchData();
    };

    deleteGroup = async id => {
        const { dataSource } = this.state;
        _.remove(dataSource, { id });
        await this.setState({ dataSource });
        this.updateGroups();
    };

    addGroup = async () => {
        const {
            newGroup: { name, minRadius, maxRadius, vehicleTypeId },
            dataSource
        } = this.state;
        const id = _.last(dataSource).id + 1;
        const newGroup = {
            id,
            visible: true,
            name: name || id,
            minRadius: _.min([minRadius || 0, maxRadius || 21]),
            maxRadius: _.max([minRadius || 0, maxRadius || 21]),
            vehicleTypeId
        };
        await this.setState({
            dataSource: [...dataSource, newGroup]
        });
        this.updateGroups();
    };

    importDefault = async () => {
        await fetchAPI('COPY', '/tire_station_price_groups/standard');
        this.fetchData();
    };

    componentDidMount() {
        this.fetchData();
        this.props.fetchVehicleTypes();
    }

    componentDidUpdate(prevProps, prevState) {}

    addPriceGroup = id => {
        const {
            vehicleTypes,
            intl: { formatMessage }
        } = this.props;
        const { newGroup, dataSource } = this.state;
        const index = _.findIndex(dataSource, { id });
        const priceGroup = dataSource[index] || {};

        if (id && _.isEmpty(newGroup)) {
            _.assign(newGroup, priceGroup);
        }

        const title = formatMessage({ id: 'add' });
        const content = (
            <React.Fragment>
                <Input
                    onChange={({ target: { value: name } }) => {
                        newGroup.name = name;
                        this.addPriceGroup(id);
                        this.setState({});
                    }}
                    placeholder={formatMessage({ id: 'tire.name' })}
                    value={_.get(newGroup, 'name')}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '8px 0'
                    }}
                >
                    <InputNumber
                        decimalSeparator=','
                        max={100}
                        min={0}
                        onChange={minRadius => {
                            newGroup.minRadius = minRadius;
                            this.addPriceGroup(id);
                            this.setState({});
                        }}
                        placeholder={formatMessage({ id: 'tire.minRadius' })}
                        value={_.get(newGroup, 'minRadius')}
                    />
                    <InputNumber
                        decimalSeparator=','
                        max={100}
                        min={0}
                        onChange={maxRadius => {
                            newGroup.maxRadius = maxRadius;
                            this.addPriceGroup(id);
                            this.setState({});
                        }}
                        placeholder={formatMessage({ id: 'tire.maxRadius' })}
                        value={_.get(newGroup, 'maxRadius')}
                    />
                </div>
                <Select
                    getPopupContainer={triger => triger.parentNode}
                    onChange={vehicleTypeId => {
                        newGroup.vehicleTypeId = vehicleTypeId;
                        this.addPriceGroup(id);
                        this.setState({});
                    }}
                    placeholder={formatMessage({ id: 'tire.vehicleType' })}
                    value={_.get(newGroup, 'vehicleTypeId')}
                >
                    {vehicleTypes.map(({ id, name, defaultRadius }) => (
                        <Option key={id} radius={defaultRadius} value={id}>
                            {name}
                        </Option>
                    ))}
                </Select>
            </React.Fragment>
        );

        const modalBody = {
            title,
            content,
            onOk: id
                ? () => {
                      _.assign(priceGroup, newGroup);
                      this.updateGroups();
                  }
                : () => {
                      this.addGroup();
                  },
            onCancel: this.fetchData,
            okButtonProps: {
                disabled: !_.get(newGroup, 'name')
            }
        };

        if (this.modal) {
            this.modal.update(modalBody);
        } else {
            this.modal = Modal.confirm(modalBody);
        }
    };

    render() {
        const { dataSource } = this.state;

        return (
            <Layout
                controls={
                    <div>
                        <Button onClick={this.importDefault} style={{ marginRight: 8 }}>
                            <FormattedMessage id='import_default' />
                        </Button>
                        <Button
                            onClick={() => {
                                this.addPriceGroup();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='add' />
                        </Button>
                    </div>
                }
                title={<FormattedMessage id='navigation.tire_price_groups' />}
            >
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={dataSource}
                    pagination={false}
                    rowKey='id'
                    size='small'
                />
            </Layout>
        );
    }
}
