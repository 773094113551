import dayjs from 'dayjs';
import { mainModuleName } from 'pages/OnlineServiceBookPage/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'registerForServiceModal';
const prefix = `cpb/onlineServiceBook/${moduleName}`;

export const CREATE_ORDER = `${prefix}/CREATE_ORDER`;

export const SET_BEGIN_DATE = `${prefix}/SET_BEGIN_DATE`;
export const SET_BEGIN_TIME = `${prefix}/SET_BEGIN_TIME`;
export const SET_COMMENT = `${prefix}/SET_COMMENT`;

export const SET_REGISTER_FOR_SERVICE_MODAL_VISIBILITY = `${prefix}/SET_REGISTER_FOR_SERVICE_MODAL_VISIBILITY`;

export const RESET_REGISTER_FOR_SERVICE_MODAL = `${prefix}/RESET_REGISTER_FOR_SERVICE_MODAL`;

export const DEF_DATE_FORMAT = 'YYYY-MM-DD';
export const DEF_TIME_FORMAT = 'HH:mm';

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    beginDate: dayjs().format(DEF_DATE_FORMAT),
    beginTime: dayjs().format('09:00'),
    comment: undefined,

    registerForServiceModalVisibility: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_BEGIN_DATE:
            return {
                ...state,
                beginDate: payload.beginDate
            };

        case SET_BEGIN_TIME:
            return {
                ...state,
                beginTime: payload.beginTime
            };

        case SET_COMMENT:
            return {
                ...state,
                comment: payload.comment
            };

        case SET_REGISTER_FOR_SERVICE_MODAL_VISIBILITY:
            return {
                ...state,
                registerForServiceModalVisibility: payload
            };

        case RESET_REGISTER_FOR_SERVICE_MODAL:
            return {
                ...state,
                ...ReducerState
            };

        default:
            return state;
    }
}

/** ------------------------------------- Selectors ------------------------------------- * */

export const selectBeginDate = state => state[mainModuleName][moduleName].beginDate;
export const selectBeginTime = state => state[mainModuleName][moduleName].beginTime;
export const selectComment = state => state[mainModuleName][moduleName].comment;
export const selectRegisterForServiceModalVisibility = state =>
    state[mainModuleName][moduleName].registerForServiceModalVisibility;
/* ------------------------------------- Action Creators ------------------------------------- */

export const createOrder = ({ clientId, managerId, vehicleId, businessId }) => ({
    type: CREATE_ORDER,
    payload: { clientId, managerId, vehicleId }
});

/**
 * @param {*} params.beginDate - dayjs Object
 */
export const setBeginDate = ({ beginDate }) => ({
    type: SET_BEGIN_DATE,
    payload: { beginDate }
});

/**
 * @param {*} params.beginTime - dayjs Object
 */
export const setBeginTime = ({ beginTime }) => ({
    type: SET_BEGIN_TIME,
    payload: { beginTime }
});

export const setComment = ({ comment }) => ({
    type: SET_COMMENT,
    payload: { comment }
});

export const setRegisterForServiceModalVisibility = value => ({
    type: SET_REGISTER_FOR_SERVICE_MODAL_VISIBILITY,
    payload: value
});

/**
 * Clear data inside redux storage, used to clear modal fields
 * @returns
 */
export const resetRegisterForServiceModal = () => ({
    type: RESET_REGISTER_FOR_SERVICE_MODAL
});
