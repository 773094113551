import { Input, Select, Table } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';

const { Option } = Select;

const dateFormat = 'YYYY-MM-DD';

const HistoryPartsTab = ({ intl }) => {
    const [partsHistory, setPartsHistory] = useState([]);
    const [dateFrom, setDateFrom] = useState(dayjs().subtract(14, 'days'));
    const [dateTo, setDateTo] = useState(dayjs());
    const [sortOrder, setSortOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [count, setCount] = useState();

    const fetchPartsHistory = useMemo(
        () => async query => {
            setLoading(true);
            const res = await fetchAPI(
                'GET',
                '/search_history/products',
                {
                    query,
                    page,
                    pageSize,
                    dateFrom: dateFrom ? dayjs(dateFrom).format(dateFormat) : undefined,
                    dateTo: dateTo ? dayjs(dateTo).format(dateFormat) : undefined,
                    sortOrder
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setPartsHistory(res.data);
            setCount(res.count);
            setLoading(false);
        },
        [dateFrom, dateTo, page, pageSize, sortOrder]
    );

    const handleSearchHistoryRow = _.debounce(value => {
        fetchPartsHistory(value);
    }, 1000);

    // const handleSearch = async confirm => {
    //     confirm();
    //     fetchPartsHistory();
    // };
    // const handleReset = async (confirm, clearFilters, dataIndex) => {
    //     confirm();
    //     clearFilters();
    //     const tmp = filters;

    //     delete tmp[dataIndex];

    //     await setFilters(tmp);
    //     fetchPartsHistory();
    // };

    // const getColumnSearchProps = useCallback(
    //     dataIndex => {
    //         let filterComponent = (confirm, clearFilters) => (
    //             <Input
    //                 onChange={e => {}}
    //                 onPressEnter={() => handleSearch(confirm, dataIndex)}
    //                 placeholder={intl.formatMessage({
    //                     id: 'search'
    //                 })}
    //                 style={{ marginBottom: 8, display: 'block', width: 180 }}
    //             />
    //         );

    //         if (dataIndex === 'dateFrom') {
    //             filterComponent = (confirm, clearFilters) => (
    //                 <div style={{ backgroundColor: 'white' }}>
    //                     <DateRangePicker
    //                         allowClear
    //                         dateRange={filters.dateFrom ? [dayjs(filters.dateFrom), dayjs(filters.dateTo)] : undefined}
    //                         format={dateFormat}
    //                         getPopupContainer={trigger => trigger.parentNode}
    //                         onDateChange={async dateRange => {
    //                             await setFilters({
    //                                 ...filters,
    //                                 dateFrom: dateRange[0].format('YYYY-MM-DD'),
    //                                 dateTo: dateRange[1].format('YYYY-MM-DD')
    //                             });
    //                         }}
    //                         placement='bottomRight'
    //                         popupStyle={{
    //                             maxHeight: 400,
    //                             overflow: 'auto',
    //                             zIndex: 9999,
    //                             minWidth: 220
    //                         }}
    //                         style={{ width: '100%' }}
    //                     />
    //                 </div>
    //             );
    //         }

    //         return {
    //             filterDropdown: ({ confirm, clearFilters }) => (
    //                 <div style={{ padding: 8 }}>
    //                     {filterComponent(confirm, clearFilters)}
    //                     {dataIndex !== '11' && dataIndex !== 'filterCreatedDate' && dataIndex !== 'filtertDoneDate' && (
    //                         <div
    //                             style={{
    //                                 display: 'flex',
    //                                 justifyContent: 'space-around'
    //                             }}
    //                         >
    //                             <Button onClick={() => handleReset(confirm, clearFilters, dataIndex)} size='small'>
    //                                 <FormattedMessage id='reset' />
    //                             </Button>
    //                         </div>
    //                     )}
    //                 </div>
    //             ),
    //             filterIcon: () => {
    //                 let colorFilter;
    //                 if (filters[dataIndex]) {
    //                     colorFilter = 'var(--primary)';
    //                 }

    //                 return (
    //                     <FilterFilled
    //                         style={{
    //                             fontSize: 14,
    //                             color: colorFilter
    //                         }}
    //                     />
    //                 );
    //             }
    //             // onFilterDropdownVisibleChange: visible => {
    //             //     if (visible) {
    //             //         setTimeout(() => this.searchInput.select(), 100);
    //             //     }
    //             // }
    //         };
    //     },
    //     [intl, handleSearch, filters, handleReset]
    // );

    const columns = useMemo(
        () => [
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'code',
                width: '15%',
                render: row => (
                    <Link
                        to={{
                            pathname: book.orderCatalogue,
                            state: {
                                code: row.code
                            }
                        }}
                    >
                        {row.code}
                    </Link>
                )
            },
            {
                title: <FormattedMessage id='brand' />,
                key: 'brand',
                width: '10%',
                dataIndex: 'brandName'
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '15%',
                dataIndex: 'name'
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.oe_code_title' />,
                key: 'oeCode',
                width: '10%',
                dataIndex: 'oeCode'
            },
            {
                title: <FormattedMessage id='supplier' />,
                key: 'businessSupplierName',
                width: '10%',
                dataIndex: 'businessSupplierName'
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'date',
                width: '10%',
                align: 'center',
                render: row => (row.date ? dayjs(row.date).format(dateFormat) : undefined)
            },
            {
                title: <FormattedMessage id='locations.time' />,
                key: 'time',
                width: '10%',
                align: 'center',
                render: row => (row.time ? row.time.split('.')[0] : undefined)
            }
        ],
        []
    );

    const pagination = {
        pageSize,
        size: 'large',
        total: Math.ceil(count / pageSize) * pageSize,
        current: page,
        onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
        }
    };

    const renderNestedTable = useCallback(
        history => {
            const tableProps = {
                dataSource: get(history, 'length') ? history : [],
                columns,
                rowKey: 'id',
                loading,
                pagination
            };

            return <Table {...tableProps} bordered rowKey='id' />;
        },
        [columns, loading]
    );

    useEffect(() => {
        fetchPartsHistory();
    }, [fetchPartsHistory]);

    return (
        <div>
            <div
                style={{
                    width: '100%',
                    marginBottom: 8,
                    display: 'flex'
                }}
            >
                <Input
                    onChange={event => handleSearchHistoryRow(event.target.value)}
                    placeholder={intl.formatMessage({
                        id: 'search'
                    })}
                />
                <DateRangePicker
                    allowClear
                    dateRange={dateFrom ? [dayjs(dateFrom), dayjs(dateTo)] : undefined}
                    format={dateFormat}
                    getPopupContainer={trigger => trigger.parentNode}
                    minimize
                    onDateChange={async dateRange => {
                        if (dateRange) {
                            setDateFrom(dateRange[0].format('YYYY-MM-DD'));
                            setDateTo(dateRange[1].format('YYYY-MM-DD'));
                        } else if (dateRange === null) {
                            setDateFrom(undefined);
                            setDateTo(undefined);
                        }
                    }}
                    placement='bottomRight'
                    popupStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                        zIndex: 9999,
                        minWidth: 220
                    }}
                />
            </div>
            {renderNestedTable(partsHistory)}
        </div>
    );
};

export default injectIntl(HistoryPartsTab);
