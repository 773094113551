import { Table } from 'antd';
import classNames from 'classnames/bind';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { columnsConfig } from './reviewsTableConfig';
import Styles from './styles.m.css';

const cx = classNames.bind(Styles);

@injectIntl
export default class ReviewsTable extends Component {
    render() {
        const {
            reviews,
            stats,
            filter,
            intl: { formatMessage },
            fetchReviews,
            reviewsFetching,
            setReviewsPageFilter
        } = this.props;

        const columns = columnsConfig(formatMessage);

        const pagination = {
            pageSize: filter.pageSize,
            size: 'large',
            total: Math.ceil(_.get(stats, 'total', 0) / filter.pageSize) * filter.pageSize,
            current: filter.page,
            onChange: async (page, pageSize) => {
                setReviewsPageFilter({ page, pageSize });
                await fetchReviews({ page });
            }
        };

        const _rowStyles = complaint =>
            cx({
                row: true,
                rowComplaint: complaint
            });

        return (
            <Table
                bordered
                className={Styles.table}
                columns={columns}
                dataSource={reviews}
                loading={reviewsFetching}
                locale={{
                    emptyText: <FormattedMessage id='no_data' />
                }}
                pagination={pagination}
                rowClassName={review => _rowStyles(review.complaint)}
                // onChange={ handleTableChange }
                scroll={{ x: 1080 }}
                size='small'
            />
        );
    }
}
