import { mainModuleName } from 'modals/RefactoredCashOrderModal/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'clients';
const prefix = `cpb/refactoredCashOrderModal/${moduleName}`;

export const FETCH_CLIENTS = `${prefix}/FETCH_CLIENTS`;
export const FETCH_CLIENTS_SUCCESS = `${prefix}/FETCH_CLIENTS_SUCCESS`;
export const SET_CLIENTS_FILTERS = `${prefix}/SET_CLIENTS_FILTERS`;
export const SET_CLIENTS_SORT = `${prefix}/SET_CLIENTS_SORT`;
export const SET_FETCHING_CLIENTS = `${prefix}/SET_FETCHING_CLIENTS`;

export const SET_SELECTED_CLIENT_ID = `${prefix}/SET_SELECTED_CLIENT_ID`;

export const FETCH_SELECTED_CLIENT = `${prefix}/FETCH_SELECTED_CLIENT`;
export const FETCH_SELECTED_CLIENT_SUCCESS = `${prefix}/FETCH_SELECTED_CLIENT_SUCCESS`;
export const SET_FETCHING_SELECTED_CLIENT = `${prefix}/SET_FETCHING_SELECTED_CLIENT`;

export const CLEAR_CLIENTS_STATE = `${prefix}/CLEAR_CLIENTS_STATE`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    clients: [],
    clientsFilters: {
        query: ''
    },
    clientsSort: {
        page: 1
    },
    clientsStats: {},
    fetchingClients: false,

    selectedClientId: undefined,

    selectedClient: {}, // When client is selected here will be loaded its object from remote, it can use used to visualize it later
    fetchingSelectedClient: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_CLIENTS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_CLIENTS_FILTERS:
            return {
                ...state,
                clientsFilters: {
                    ...state.clientsFilters,
                    ...payload
                }
            };
        case SET_CLIENTS_SORT:
            return {
                ...state,
                clientsSort: {
                    ...state.clientsSort,
                    ...payload
                }
            };
        case SET_FETCHING_CLIENTS:
            return {
                ...state,
                fetchingClients: payload
            };
        case SET_SELECTED_CLIENT_ID:
            return {
                ...state,
                selectedClientId: payload
            };
        case FETCH_SELECTED_CLIENT_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_FETCHING_SELECTED_CLIENT:
            return {
                ...state,
                fetchingSelectedClient: payload
            };
        case CLEAR_CLIENTS_STATE:
            return ReducerState;
        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectClients = state => state[mainModuleName][moduleName].clients;
export const selectClientsStats = state => state[mainModuleName][moduleName].clientsStats;
export const selectClientsFilters = state => state[mainModuleName][moduleName].clientsFilters;
export const selectClientsSort = state => state[mainModuleName][moduleName].clientsSort;
export const selectFetchingClients = state => state[mainModuleName][moduleName].fetchingClients;
export const selectSelectedClientId = state => state[mainModuleName][moduleName].selectedClientId;
/** Selected client was fetched by its ID (after selected client ID was changed) */
export const selectSelectedClient = state => state[mainModuleName][moduleName].selectedClient;
export const selectFetchingSelectedClient = state =>
    state[mainModuleName][moduleName].fetchingSelectedClient;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchClients = () => ({
    type: FETCH_CLIENTS
});

/*
 * @param {*} fetchedData.clients
 * @param {*} fetchedData.clientsStats
 */
export const fetchClientsSuccess = fetchedData => ({
    type: FETCH_CLIENTS_SUCCESS,
    payload: fetchedData
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setClientsFilters = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_CLIENTS_FILTERS,
            payload: filters
        });
        dispatch(fetchClients());
    };
};

/**
 * Set filters without refreshing data
 * @param {*} filters
 */
export const setClientsFiltersPoor = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_CLIENTS_FILTERS,
            payload: filters
        });
    };
};

/** Provide object with sort field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } sort - sort object, can contain any fields you want to override
 */
export const setClientsSort = sort => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_CLIENTS_SORT,
            payload: sort
        });
        dispatch(fetchClients());
    };
};

export const setFetchingClients = value => ({
    type: SET_FETCHING_CLIENTS,
    payload: value
});

export const fetchSelectedClient = () => ({
    type: FETCH_SELECTED_CLIENT
});

/*
 * @param {*} fetchedData.selectedClient
 */
export const fetchSelectedClientSuccess = fetchedData => ({
    type: FETCH_SELECTED_CLIENT_SUCCESS,
    payload: fetchedData
});

export const setFetchingSelectedClient = value => ({
    type: SET_FETCHING_SELECTED_CLIENT,
    payload: value
});

/**
 * Set selected client and fetch it automatically.
 * Fetching is required if you set client that is not loaded in clients table.
 * If clientID is undefined, then buffer will be cleared (empty object)
 * @param {*} value - client ID
 */
export const setSelectedClientId = value => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_SELECTED_CLIENT_ID,
            payload: value
        });

        if (value) {
            dispatch(fetchSelectedClient());
        } else {
            // Clear selected client buffer if id was undefined
            dispatch(fetchSelectedClientSuccess({ selectedClient: {} }));
        }
    };
};

export const clearClientsState = () => ({
    type: CLEAR_CLIENTS_STATE
});
