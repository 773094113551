exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__tableWrap---3I335{overflow-x:auto;overflow-y:hidden}.styles-m__serviceNumberInput---2ouCH input{text-align:right;padding-right:25px}.styles-m__commentaryButtonIcon---glkW0 svg *{color:rgba(0,0,0,.65)}.styles-m__commentaryContentWrap---2wu9e>div{margin-bottom:15px}.styles-m__commentarySectionHeader---iVSEK{margin-bottom:5px;font-weight:700}.styles-m__blockButtonsWrap---2va0-{display:flex;flex-wrap:wrap;justify-content:space-between}.styles-m__commentaryBlockButton---1Ygw0{width:30%;margin-bottom:5px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,6BACE,eAAgB,CAChB,iBACF,CAEA,4CACE,gBAAiB,CACjB,kBACF,CAGA,8CACE,qBACF,CAEA,6CACE,kBACF,CAEA,2CACE,iBAAkB,CAClB,eACF,CAEA,oCACE,YAAa,CACb,cAAe,CACf,6BACF,CAEA,yCACE,SAAU,CACV,iBACF","file":"styles.m.css","sourcesContent":[".tableWrap {\n  overflow-x: auto;\n  overflow-y: hidden;\n}\n\n.serviceNumberInput input {\n  text-align: right;\n  padding-right: 25px;\n}\n\n/* Commentary Button */\n.commentaryButtonIcon svg * {\n  color: rgba(0, 0, 0, 0.65);\n}\n\n.commentaryContentWrap > div {\n  margin-bottom: 15px;\n}\n\n.commentarySectionHeader {\n  margin-bottom: 5px;\n  font-weight: bold;\n}\n\n.blockButtonsWrap {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.commentaryBlockButton {\n  width: 30%;\n  margin-bottom: 5px;\n}\n"]}]);

// Exports
exports.locals = {
	"tableWrap": "styles-m__tableWrap---3I335",
	"serviceNumberInput": "styles-m__serviceNumberInput---2ouCH",
	"commentaryButtonIcon": "styles-m__commentaryButtonIcon---glkW0",
	"commentaryContentWrap": "styles-m__commentaryContentWrap---2wu9e",
	"commentarySectionHeader": "styles-m__commentarySectionHeader---iVSEK",
	"blockButtonsWrap": "styles-m__blockButtonsWrap---2va0-",
	"commentaryBlockButton": "styles-m__commentaryBlockButton---1Ygw0"
};