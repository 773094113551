/* eslint-disable id-length */
/* eslint-disable no-template-curly-in-string */
import { Button, Input, InputNumber, notification } from 'antd';
import _, { get } from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const ContactsSettings = ({ cb24data, saveData, isDisabledCRUD, initialValues }) => {
    const ref = useRef(null);

    const [map, setMap] = useState();
    const [searchBox, setSearchBox] = useState();
    const [markers, setMarkers] = React.useState([]);
    const [address, setAddress] = useState(_.get(initialValues, 'address'));
    const [zoom, setZoom] = React.useState(_.get(initialValues, 'mapZoom', 13));
    const [center, setCenter] = React.useState({
        lat: _.get(initialValues, 'mapLatitude', 0),
        lng: _.get(initialValues, 'mapLongitude', 0)
    });

    useEffect(() => {
        setAddress(_.get(initialValues, 'address'));
        setZoom(_.get(initialValues, 'mapZoom', 13));
        setCenter({
            lat: _.get(initialValues, 'mapLatitude', 0),
            lng: _.get(initialValues, 'mapLongitude', 0)
        });
    }, [initialValues]);

    useEffect(() => {
        if (ref.current && !map) {
            const map = new window.google.maps.Map(ref.current, {
                zoom,
                center: new window.google.maps.LatLng(center.lat, center.lng),
                mapTypeId: 'roadmap'
            });
            map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('pac-input'));
            setMap(map);
            setMarkers([{ position: { lat: center.lat, lng: center.lng } }]);
            // setSearchBox(
            //     new window.google.maps.places.SearchBox(document.getElementById('pac-input'))
            // );
        }
    }, [ref, map, zoom, center]);

    useEffect(() => {
        const onClick = e => {
            setMarkers([{ position: { lat: e.latLng.lat(), lng: e.latLng.lng() } }]);
        };

        const onIdle = m => {
            setZoom(m.getZoom());
            setCenter(m.getCenter().toJSON());

            if (markers.length === 0) {
                setMarkers([{ position: center }]);
            }
        };

        if (map) {
            ['click', 'idle'].forEach(e => window.google.maps.event.clearListeners(map, e));
            if (onClick) {
                map.addListener('click', onClick);
            }

            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
            }

            if (map && searchBox) {
                const listener = map.addListener('bounds_changed', () => {
                    searchBox.setBounds(map.getBounds());
                });

                return () => {
                    window.google.maps.event.removeListener(listener);
                };
            }

            map.addListener('zoom_changed', e => {
                // console.log(e);
            });
        }
    }, [map, searchBox, center, markers]);

    useEffect(() => {
        if (map && searchBox) {
            searchBox.addListener('places_changed', () => {
                setMarkers([]);
                const places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }

                const bounds = new window.google.maps.LatLngBounds();

                places.forEach(place => {
                    setAddress(place.formatted_address);
                    setCenter({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    });
                    if (!place.geometry || !place.geometry.location) {
                        return;
                    }

                    const icon = {
                        url: place.icon,
                        size: new window.google.maps.Size(71, 71),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(17, 34),
                        scaledSize: new window.google.maps.Size(25, 25)
                    };

                    setMarkers([
                        {
                            map,
                            icon,
                            title: place.name,
                            position: place.geometry.location
                        }
                    ]);

                    if (place.geometry.viewport) {
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
                setZoom(map.zoom);
            });
        }
    }, [searchBox, map, markers, center]);

    const onAddressChange = e => {
        const address = e.target.value;

        setAddress(address);
    };

    const sumbitCoordinates = e => {
        setMarkers([{ position: center }]);
        setZoom(_.get(initialValues, 'mapZoom', 13));
        map.setCenter(center);
    };

    const onFinish = () => {
        saveData({
            address,
            mapLatitude: get(markers, '[0].position.lat'),
            mapLongitude: get(markers, '[0].position.lng'),
            mapZoom: zoom
        });
        notification.success({
            message: '.'
        });
    };

    console.log(map, 'map');

    return (
        <div style={{ position: 'relative' }}>
            <Map
                ref={ref}
                address={address}
                isDisabledCRUD={isDisabledCRUD}
                map={map}
                onAddressChange={onAddressChange}
                style={{ width: '100%', height: 450, marginBottom: 16 }}
            >
                {markers.map(props => (
                    <Marker key={`marker-${props.position.lat}-${props.position.lng}`} {...props} />
                ))}
            </Map>
            <div
                style={{
                    marginBottom: 8
                }}
            >
                <FormattedMessage id='general_settings.GPS_latitude' />
                <InputNumber
                    onChange={value => {
                        setCenter({
                            lat: value,
                            lng: center.lng
                        });
                    }}
                    style={{
                        width: '100%'
                    }}
                    value={get(markers, '[0].position.lat')}
                />
                <FormattedMessage id='general_settings.GPS_longitude' />
                <InputNumber
                    onChange={value => {
                        setCenter({
                            lat: center.lat,
                            lng: value
                        });
                    }}
                    style={{
                        width: '100%'
                    }}
                    value={get(markers, '[0].position.lng')}
                />
                <Button
                    onClick={() => {
                        sumbitCoordinates();
                    }}
                    style={{
                        marginTop: 8
                    }}
                >
                    <FormattedMessage id='general_settings.apply_coord' />
                </Button>
            </div>

            <Button
                disabled={isDisabledCRUD}
                onClick={onFinish}
                style={{ width: '15%', float: 'right' }}
                type='primary'
            >
                <FormattedMessage id='general_settings.submit' />
            </Button>
        </div>
    );
};

const Map = forwardRef(({ children, style, map, address, onAddressChange, isDisabledCRUD }, ref) => {
    console.log(ref, 'ref');

    return (
        <div>
            <div ref={ref} id='map' style={style} />
            {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map });
                }
            })}
            <Input
                className='controls'
                disabled={isDisabledCRUD}
                id='pac-input'
                onChange={onAddressChange}
                size='large'
                style={{
                    width: '60%',
                    margin: 10
                }}
                type='text'
                value={address}
            />
        </div>
    );
});

const Marker = options => {
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker());
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};
