import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm } from 'antd';
import { selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from '../styles.m.css';

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    suppliers: state.suppliers.suppliers,
    clients: state.clients.clients
});

const mapDispatchToProps = {
    fetchSuppliers
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class DamageComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            problems: [],
            currentCommentaryProps: {
                problems: []
            }
        };
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this.params = [
            { name: 'mm', symbol: 'mm' },
            { name: 'percent', symbol: '%' },
            { name: 'deg', symbol: '°' }
        ];
    }

    componentDidMount() {
        this.getVehicleProblems();
    }

    getVehicleProblems = async () => {
        const { id } = this.props;
        const problems = await fetchAPI(
            'GET',
            'orders/vehicle/damage/list',
            {
                orderId: id
            },
            null,
            { handleErrorInternally: true }
        );
        await this.setState({
            problems
        });
    };

    getCommentary = () => {
        const { currentCommentaryProps, problems } = this.state;

        let currentCommentary = this.props.commentary || '';

        if (currentCommentaryProps.problems.length) {
            currentCommentary += `${currentCommentaryProps.problems.map(data => {
                return ` ${problems.find(problem => problem.damageId === data).damageType.toLowerCase()}`;
            })};`;
        }

        this.setState({
            currentCommentary
        });
    };

    handleCancel = () => {
        const { hideModal } = this.props;
        this.setState({
            currentCommentary: '',
            currentCommentaryProps: {
                problems: []
            }
        });

        hideModal();
    };

    onOk = async () => {
        const { problems, currentCommentary, currentCommentaryProps } = this.state;
        const { postProblems, id, putProblems, getDamagePoint } = this.props;

        const damageIds = currentCommentaryProps.problems.map(data => {
            return problems.find(problem => problem.damageId === data).damageId;
        });

        if (id) {
            await putProblems(damageIds, currentCommentary, id);
        } else await postProblems(damageIds, currentCommentary);

        this.handleCancel();
    };

    setCommentaryProblems = value => {
        const { currentCommentaryProps } = this.state;
        const problemIndex = currentCommentaryProps.problems.indexOf(value);
        if (problemIndex === -1) {
            currentCommentaryProps.problems.push(value);
        } else {
            currentCommentaryProps.problems = currentCommentaryProps.problems.filter(
                (value, index) => index !== problemIndex
            );
        }
        this.getCommentary();
    };

    componentDidUpdate(prevProps) {
        const { id, damageIds, comment } = this.props;
        if (!prevProps.visible && this.props.visible && id) {
            this.setState({
                currentCommentary: comment,
                currentCommentaryProps: {
                    problems: damageIds || []
                }
            });
        }
    }

    render() {
        const { TextArea } = Input;
        const { problems, currentCommentaryProps, currentCommentary } = this.state;
        const {
            disabled,
            visible,
            deleteDamagePoint,
            id,
            comment,
            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Modal
                    maskClosable={false}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            {id && (
                                <div>
                                    <Popconfirm
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onConfirm={() => {
                                            deleteDamagePoint(id);
                                            this.handleCancel();
                                        }}
                                        title={formatMessage({
                                            id: 'add_order_form.delete_confirm'
                                        })}
                                    >
                                        <Button icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: 8
                                    }}
                                >
                                    <Button onClick={this.handleCancel}>
                                        <FormattedMessage id='stock_table.button.cancel' />
                                    </Button>
                                </div>
                                <div>
                                    <Button disabled={!currentCommentary} onClick={this.onOk} type='primary'>
                                        <FormattedMessage id='navigation.unpair_all_btn_ok' />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={
                        currentCommentary ? (
                            <FormattedMessage id='order_form_table_intake_tab.edit_problem' />
                        ) : (
                            <FormattedMessage id='order_form_table_intake_tab.add_problem' />
                        )
                    }
                    visible={visible}
                >
                    <React.Fragment>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.what' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {problems.map(({ damageType, damageId }) => {
                                    return (
                                        <Button
                                            className={`${Styles.commentaryBlockButton}`}
                                            onClick={() => {
                                                this.setCommentaryProblems(damageId);
                                            }}
                                            type={
                                                currentCommentaryProps.problems.findIndex(elem => damageId === elem) ===
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <span>{damageType}</span>
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>

                        <div>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                className={disabled ? Styles.disabledCommentaryProp : ''}
                                disabled={disabled}
                                onChange={event => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{
                                    width: '100%',
                                    minHeight: '150px',
                                    resize: 'none'
                                }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}
