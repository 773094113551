import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { Catcher, Loader, Numeral } from 'commons';
import { StoreDocumentProductsTable } from 'components';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { selectBrandsByQuery } from 'core/search/duck';
import { createIncomeDoc, selectIncomeDocLoading, updateIncomeDoc } from 'core/storage/incomes';
import { fetchSuppliers, selectSuppliers } from 'core/suppliers/duck';
import dayjs from 'dayjs';
import { DecoratedDatePicker, DecoratedInput } from 'forms/DecoratedFields';
import { BusinessSuppliersSearch } from 'forms/_formkit';
import _ from 'lodash';
import { StoreProductModal, SupplierModal } from 'modals';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { getCurrency, isForbidden, permissions } from 'utils';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

const FormHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const FormBody = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SubmitButton = styled(Button)`
    margin-right: 24px;
`;

const TotalSum = styled.div`
    font-weight: bold;
    font-size: 18px;
    background: var(--static);
    padding: 16px;
`;

const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 45%;
`;

const SupplierFieldWrapper = styled.div`
    position: relative;
`;

const IncomeForm = props => {
    const {
        incomeDoc,
        form,
        intl: { formatMessage }
    } = props;

    const incomeDocId = props.match.params.id;

    useEffect(() => {
        props.fetchSuppliers();
    }, []);

    const _submit = complete => {
        props.form.validateFields((err, values) => {
            if (!err) {
                const docProducts = values.docProducts.filter(Boolean);
                let normalizedValues = {
                    ...values,
                    ...(_.isNil(values.sum) ? { sum: 0 } : {})
                };
                if (!_.last(docProducts).productId) {
                    normalizedValues = {
                        ...normalizedValues,
                        docProducts: docProducts
                            .slice(0, -1)
                            .map(product =>
                                _.omit(product, ['name', 'tradeCode', 'brandId', 'brandName'])
                            ),
                        status: complete || incomeDoc.status === 'DONE' ? 'DONE' : 'NEW'
                    };
                } else {
                    normalizedValues = {
                        ...normalizedValues,
                        docProducts: docProducts.map(product =>
                            _.omit(product, ['name', 'tradeCode', 'brandId', 'brandName'])
                        ),
                        status: complete || incomeDoc.status === 'DONE' ? 'DONE' : 'NEW'
                    };
                }

                incomeDocId
                    ? props.updateIncomeDoc(incomeDocId, normalizedValues)
                    : props.createIncomeDoc(normalizedValues);

                props.form.resetFields();
            }
        });
    };

    const isSuppliersDisabled = isForbidden(props.user, permissions.ACCESS_SUPPLIERS);

    return props.loading && _.isEmpty(incomeDoc) ? (
        <Loader loading={props.loading && _.isEmpty(incomeDoc)} />
    ) : (
        <Catcher>
            <Form>
                <FormHeader>
                    <div>
                        {incomeDocId ? (
                            <SubmitButton icon='save' onClick={() => _submit()}>
                                {formatMessage({ id: 'save' })}
                            </SubmitButton>
                        ) : (
                            <SubmitButton onClick={() => _submit()} type='primary'>
                                {formatMessage({ id: 'create' })}
                            </SubmitButton>
                        )}
                        {_.get(incomeDoc, 'status') === 'DONE' ? null : (
                            <Button onClick={() => _submit(true)} type='secondary'>
                                {formatMessage({ id: 'storage.complete' })}
                            </Button>
                        )}
                    </div>
                    <TotalSum>
                        {formatMessage({ id: 'storage.sum' })}&nbsp;
                        <Numeral currency={getCurrency()} nullText='0'>
                            {Number(props.form.getFieldValue('sum'))}
                        </Numeral>
                        <DecoratedInput
                            field='sum'
                            fields={{}}
                            hiddeninput='hiddeninput'
                            initialValue={!_.isEmpty(incomeDoc) ? incomeDoc.sum : 0}
                        />
                    </TotalSum>
                </FormHeader>
                <FormBody>
                    <FormColumn>
                        <DecoratedInput
                            field='supplierDocNumber'
                            // style={ { display: 'flex' } }
                            fields={{}}
                            formItem
                            formItemLayout={formItemLayout}
                            initialValue={incomeDoc.supplierDocNumber}
                            label={formatMessage({
                                id: 'storage.document_number'
                            })}
                            placeholder={formatMessage({
                                id: 'storage.supplier_document_number'
                            })}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />

                        <SupplierFieldWrapper>
                            <FormItem
                                label={formatMessage({
                                    id: 'cash-order-form.counterparty.BUSINESS_SUPPLIER'
                                })}
                                name='businessSupplierId'
                                rules={[{ initialValue: incomeDoc.businessSupplierId }]}
                                {...formItemLayout}
                            >
                                <BusinessSuppliersSearch
                                    disabled={isSuppliersDisabled}
                                    id={incomeDoc.businessSupplierId}
                                    onSelect={id =>
                                        form.setFieldsValue({
                                            businessSupplierId: id
                                        })
                                    }
                                    selectStyles={{ width: '100%' }}
                                />
                                ,
                            </FormItem>
                            {isSuppliersDisabled ? null : (
                                <PlusOutlined
                                    onClick={() => props.setModal(MODALS.SUPPLIER)}
                                    style={{
                                        position: 'absolute',
                                        right: '-60px',
                                        top: '-5px',
                                        color: 'var(--green)',
                                        fontSize: 24,
                                        margin: '10px 32px 0 10px'
                                    }}
                                />
                            )}
                        </SupplierFieldWrapper>
                    </FormColumn>
                    <FormColumn>
                        <DecoratedDatePicker
                            field='doneDatetime'
                            fields={{}}
                            formItem
                            formItemLayout={formItemLayout}
                            initialValue={incomeDoc.doneDatetime || dayjs()}
                            label={formatMessage({
                                id: 'storage.done_date'
                            })}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />
                        <DecoratedDatePicker
                            field='paidDatetime'
                            fields={{}}
                            formItem
                            formItemLayout={formItemLayout}
                            initialValue={incomeDoc.paidDatetime}
                            label={formatMessage({
                                id: 'storage.payment_date'
                            })}
                        />
                    </FormColumn>
                </FormBody>
                <StoreDocumentProductsTable
                    brands={props.brands}
                    form={props.form}
                    incomeDoc={incomeDoc}
                    setBrandsSearchQuery={props.setBrandsSearchQuery}
                />
                <Button
                    disabled={isForbidden(props.user, permissions.ACCESS_STORE_PRODUCTS)}
                    icon={<PlusOutlined />}
                    onClick={() => props.setModal(MODALS.STORE_PRODUCT)}
                >
                    {formatMessage({ id: 'storage.add_new_storage_product' })}
                </Button>
            </Form>
            <SupplierModal />
            <StoreProductModal />
        </Catcher>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    loading: selectIncomeDocLoading(state),
    brands: selectBrandsByQuery(state),
    suppliers: selectSuppliers(state)
});

const mapDispatchToProps = {
    // fetchIncomeDoc,
    fetchSuppliers,
    setModal,
    resetModal,
    createIncomeDoc,
    updateIncomeDoc
};

export const IncomeDocForm = withRouter(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(IncomeForm))
);
