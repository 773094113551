exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__mainCont---s75mt{display:flex;justify-content:space-between}.styles-m__selectCont---2YVOG{width:100%}.styles-m__analyticsBtnCont---3Dq89{display:inline-flex;justify-content:flex-end;margin-left:.2em}.styles-m__analyticsBtn---3rWPu{background-color:var(--secondary);color:#fff;padding:0 .5em}.styles-m__addAnalyticsIcon---o9T7W{font-size:16px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,4BACI,YAAa,CACb,6BACJ,CAEA,8BACI,UACJ,CAEA,oCACI,mBAAoB,CACpB,wBAAyB,CACzB,gBACJ,CAEA,gCACI,iCAAkC,CAClC,UAAY,CACZ,cACJ,CAEA,oCACI,cACJ","file":"styles.m.css","sourcesContent":[".mainCont {\n    display: flex;\n    justify-content: space-between;\n}\n\n.selectCont {\n    width: 100%;\n}\n\n.analyticsBtnCont {\n    display: inline-flex;\n    justify-content: flex-end;\n    margin-left: 0.2em;\n}\n\n.analyticsBtn {\n    background-color: var(--secondary);\n    color: white;\n    padding: 0 0.5em 0 0.5em;\n}\n\n.addAnalyticsIcon {\n    font-size: 16px;\n}\n"]}]);

// Exports
exports.locals = {
	"mainCont": "styles-m__mainCont---s75mt",
	"selectCont": "styles-m__selectCont---2YVOG",
	"analyticsBtnCont": "styles-m__analyticsBtnCont---3Dq89",
	"analyticsBtn": "styles-m__analyticsBtn---3rWPu",
	"addAnalyticsIcon": "styles-m__addAnalyticsIcon---o9T7W"
};