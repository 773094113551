import { Layout } from 'commons';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import MarksupTable from './components/MarksupTable';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    user: state.auth
});

const MarkupsPage = ({ intl, user }) => {
    const [query, setQuery] = useState('');
    const [shouldReset, setShouldReset] = useState();
    const [loading, setLoading] = useState(false);
    const [markups, setMarkups] = useState([]);
    const [markupsGroups, setMarkupGroups] = useState([]);
    const history = useHistory();

    const fetchMarkups = useCallback(async () => {
        setLoading(true);
        const response = await fetchAPI('GET', '/markups', null, null, {
            handleErrorInternally: true
        });

        setMarkups(response);
        setLoading(false);
    }, []);

    const fetchMarkupsGroups = useCallback(async () => {
        setLoading(true);
        const response = await fetchAPI('GET', '/markups_group', null, null, {
            handleErrorInternally: true
        });

        setMarkupGroups(response);
        setLoading(false);
    }, []);

    const reset = useCallback(() => setShouldReset(null), []);
    const startReset = () => setShouldReset('reset');

    const resetFilters = () => {
        setQuery(undefined);
        startReset();
    };

    const accessAddButton = isGrantAccessed(user, grants.SETTINGS_MARKUP_GROUPS, accesses.ROWO);

    return (
        <Layout className='markups_page' title={<FormattedMessage id='navigation.markups_directory' />}>
            {/* <div>
                <div className={Styles.searchEmployeeTitle}>
                    <FormattedMessage id='search' />
                </div>
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <Input.Search
                        allowClear
                        onChange={event => {
                            setQuery(event.target.value);
                        }}
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'interactions.search_by'
                        })}
                        style={{ marginBottom: 8, width: '25%' }}
                        value={query}
                    />

                    <Button icon={<UndoOutlined />} onClick={resetFilters} />
                </div>
            </div> */}
            <MarksupTable
                accessAddButton={accessAddButton}
                fetchMarkups={fetchMarkups}
                fetchMarkupsGroups={fetchMarkupsGroups}
                loading={loading}
                markups={markups}
                markupsGroups={markupsGroups}
                query={query}
                reset={reset}
                shouldReset={shouldReset}
            />

            {/* <AddInteractionRowModal closeModal={closeInteractionRowModal} open={addInteractionRowVisible} /> */}
        </Layout>
    );
};

export default injectIntl(connect(mapStateToProps)(MarkupsPage));
