exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__MBRDoc---3rlCm{margin-left:2%}.styles-m__payDocsContainer---1Rx50{height:100%;padding-left:4%}.styles-m__payDoc---3swLv{margin:0 2px 2px 0;display:flex;justify-content:flex-end;height:2em;border-radius:1em}.styles-m__payPDoc---1_i4s{background-color:#6f6}.styles-m__payMDoc---3B4RD{background-color:#f88}.styles-m__headerContainer---3WG5x{margin-bottom:12px;margin-right:12px;display:flex;justify-content:flex-end}.styles-m__summary---2Jams{font-weight:500;float:right}.styles-m__hidden---2VoWv{opacity:0}.styles-m__summaryWrap---3vZFM{border-left:1px solid #f0f0f0;background:var(--snow)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0BACI,cACJ,CAEA,oCACI,WAAY,CACZ,eACJ,CAEA,0BACI,kBAAmB,CACnB,YAAa,CACb,wBAAyB,CACzB,UAAW,CACX,iBACJ,CAEA,2BACI,qBACJ,CAEA,2BACI,qBACJ,CAEA,mCACI,kBAAmB,CACnB,iBAAkB,CAClB,YAAa,CACb,wBACJ,CAEA,2BACI,eAAgB,CAChB,WACJ,CAEA,0BACI,SACJ,CAEA,+BACI,6BAA8B,CAC9B,sBACJ","file":"styles.m.css","sourcesContent":[".MBRDoc {\n    margin-left: 2%;\n}\n\n.payDocsContainer {\n    height: 100%;\n    padding-left: 4%;\n}\n\n.payDoc {\n    margin: 0 2px 2px 0;\n    display: flex;\n    justify-content: flex-end;\n    height: 2em;\n    border-radius: 1em;\n} \n\n.payPDoc {\n    background-color: #6F6;\n}\n\n.payMDoc {\n    background-color: #F88;\n}\n\n.headerContainer {\n    margin-bottom: 12px;\n    margin-right: 12px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.summary {\n    font-weight: 500;\n    float: right;\n}\n\n.hidden {\n    opacity: 0;\n}\n\n.summaryWrap {\n    border-left: 1px solid #f0f0f0;\n    background: var(--snow);\n}"]}]);

// Exports
exports.locals = {
	"MBRDoc": "styles-m__MBRDoc---3rlCm",
	"payDocsContainer": "styles-m__payDocsContainer---1Rx50",
	"payDoc": "styles-m__payDoc---3swLv",
	"payPDoc": "styles-m__payPDoc---1_i4s",
	"payMDoc": "styles-m__payMDoc---3B4RD",
	"headerContainer": "styles-m__headerContainer---3WG5x",
	"summary": "styles-m__summary---2Jams",
	"hidden": "styles-m__hidden---2VoWv",
	"summaryWrap": "styles-m__summaryWrap---3vZFM"
};