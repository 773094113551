import { InputNumber, Modal, Radio } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const DownloadProductReportModal = props => {
    const { open, hideModal, intl, downloadProductReport, id, reportType } = props;

    const [disablePrice, setDisabledPrice] = useState(false);
    const [count, setCount] = useState(1);

    const onClose = () => {
        setCount(1);
        setDisabledPrice(false);
        hideModal();
    };

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            onCancel={() => onClose()}
            onOk={async () => {
                await downloadProductReport(id, reportType, count, disablePrice);
                onClose();
            }}
            open={open}
            title={<FormattedMessage id='cash-order-form.print' />}
            width='30%'
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                <div>
                    <RadioGroup
                        onChange={event => {
                            setDisabledPrice(event.target.value);
                        }}
                        style={{ marginBottom: 8 }}
                        value={disablePrice}
                    >
                        <Radio value={false}>
                            <FormattedMessage id='product.print.with_price' />
                        </Radio>

                        <Radio value>
                            <FormattedMessage id='product.print.without_price' />
                        </Radio>
                    </RadioGroup>
                </div>
                <div>
                    <div>
                        <FormattedMessage id='product.print.count_units' />
                    </div>
                    <InputNumber
                        min={1}
                        onChange={value => {
                            setCount(value);
                        }}
                        placeholder={intl.formatMessage({
                            id: 'product.print.count_units'
                        })}
                        style={{ width: '100%' }}
                        value={count}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(DownloadProductReportModal);
