import React from 'react';
import { withRouter } from 'react-router';
import { getCollapsedState } from 'utils';
import Styles from './styles.m.css';

function checkIsOrderSavedHelper(props) {
    return (
        <div
            className={Styles.stopRedirectMenu}
            id='stopRedirectMenu'
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                const collapsed = getCollapsedState();
                props.close(
                    () => props.setCollapsedState(!collapsed),
                    () => {
                        props.resetModal();
                        props.setCollapsedState(!collapsed);
                    },
                    () => {
                        props.resetModal();
                        props.onStatusChange(props.status);
                        props.setCollapsedState(!collapsed);
                    }
                );
            }}
        />
    );
}

export const CheckIsOrderSavedHelper = withRouter(checkIsOrderSavedHelper);
