import { replace } from 'connected-react-router';
import { selectUser, updateUser } from 'core/auth/duck';
import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import {
    FETCH_EMPLOYEE_BY_ID,
    FIRE_EMPLOYEE,
    SAVE_EMPLOYEE,
    fetchEmployeeByIdSuccess,
    fireEmployeeSuccess,
    saveEmployeeSuccess
} from './duck';

export function* saveEmployee() {
    while (true) {
        try {
            const { payload: employee, id } = yield take(SAVE_EMPLOYEE);

            const normalizedEmployee = {
                ...(employee.managerEnabled ? { password: employee.password } : {}),
                ...(!_.isNull(employee.managerEnabled) ? { managerEnabled: employee.managerEnabled } : {}),
                email: employee.email ? employee.email : ' ',
                phone: String(employee.phone),
                enabled: employee.enabled,
                accountId: employee.accountId,
                hireDate: dayjs(employee.hireDate).format('YYYY-MM-DD'),
                jobTitle: employee.jobTitle,
                name: employee.name,
                sendSmsCancelOrder: false,
                sendSmsManualOrder: false,
                sendSmsNewOrder: false,
                surname: employee.surname,
                isMechanic: employee.isMechanic,
                isCashier: employee.isCashier,
                cashierApiToken: employee.cashierApiToken,
                taxService: employee.taxService,
                cashierPincode: employee.cashierPincode,
                medocUrl: employee.medocUrl,
                medocEdrpou: employee.medocEdrpou,
                medocLogin: employee.medocLogin,
                medocDept: employee.medocDept
            };

            if (employee.medocEdrpou) {
                const user = yield select(selectUser);
                yield put(
                    updateUser({
                        ...user,
                        medoc: {
                            url: employee.medocUrl,
                            edrpou: employee.medocEdrpou,
                            login: employee.medocLogin,
                            dept: employee.medocDept
                        }
                    })
                );
            }

            const data = yield call(
                fetchAPI,
                id ? 'PUT' : 'POST',
                id ? `employees/${id}` : 'employees',
                null,
                normalizedEmployee
            );
            yield put(saveEmployeeSuccess(data));
            yield put(replace(book.employeesPage));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}
export function* fireEmployee() {
    while (true) {
        try {
            const { payload: employee, id, fireDate } = yield take(FIRE_EMPLOYEE);

            const normalizedEmployee = {
                ...(!_.isNull(employee.managerEnabled) ? { managerEnabled: false } : {}),
                email: employee.email,
                phone: String(employee.phone),
                enabled: employee.enabled,
                hireDate: dayjs(employee.hireDate).format('YYYY-MM-DD'),
                jobTitle: employee.jobTitle,
                name: employee.name,
                sendSmsCancelOrder: false,
                sendSmsManualOrder: false,
                sendSmsNewOrder: false,
                surname: employee.surname,
                fireDate: fireDate ? dayjs(fireDate).format('YYYY-MM-DD') : null
            };
            const data = yield call(fetchAPI, 'PUT', id ? `employees/${id}` : 'employees', null, normalizedEmployee);
            yield put(fireEmployeeSuccess(data));
            yield put(replace(book.employeesPage));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}
export function* fetchEmployee() {
    while (true) {
        try {
            const { payload: id } = yield take(FETCH_EMPLOYEE_BY_ID);
            const data = yield call(fetchAPI, 'GET', `employees/${id}`);
            yield put(fetchEmployeeByIdSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}
export function* saga() {
    yield all([call(saveEmployee), call(fireEmployee), call(fetchEmployee)]);
}
