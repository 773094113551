import { Modal } from 'antd';
import { fetchAnalytics } from 'core/cash/duck';
import { clearCashOrderForm } from 'core/forms/cashOrderForm/duck';
import { formModes } from 'core/forms/reportAnalyticsForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { CashOrderForm } from 'forms';
import _ from 'lodash';
import { ReportAnalyticsModal } from 'modals';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modal: state.modals.modal
});

const mapDispatchToProps = {
    setModal,
    resetModal,
    fetchAnalytics,
    clearCashOrderForm
};

@connect(mapStateToProps, mapDispatchToProps)
export default class CashOrderModal extends Component {
    constructor(props) {
        super(props);

        this.reopenCashOrderModal = this.reopenCashOrderModal.bind(this);
        this._onOpenReportAnalyticsModal = this._onOpenReportAnalyticsModal.bind(this);
        this._onCloseReportAnallyticsModalEventHandler =
            this._onCloseReportAnallyticsModalEventHandler.bind(this);
        this._onCloseModal = this._onCloseModal.bind(this);
    }

    /**
     * Reopens cash order modal, it passes saved values
     */
    reopenCashOrderModal() {
        const {
            editMode,
            printMode,
            cashBoxId,
            fromOrder,
            sumTypeStateVal,
            sumTypeRadioStateVal,
            cashOrderEntity
        } = (this.state && this.state.prevModalProps) || {};

        this.props.setModal(MODALS.CASH_ORDER, {
            cashBoxId,
            editMode,
            printMode,
            fromOrder,
            cashOrderEntity,
            sumTypeStateVal,
            sumTypeRadioStateVal
        });
    }

    /**
     * When we want to open analytics modal from the inside of a cash order modal
     * @param {*} param0 Values to passed agter analytics modal will be closed
     */
    _onOpenReportAnalyticsModal({
        editMode,
        printMode,
        cashBoxId,
        fromOrder,
        sumTypeStateVal,
        sumTypeRadioStateVal,
        cashOrderEntity
    }) {
        // Save previous modal's props
        this.setState({
            prevModalProps: {
                editMode,
                printMode,
                cashBoxId,
                fromOrder,
                sumTypeStateVal,
                sumTypeRadioStateVal,
                cashOrderEntity: _.omit(cashOrderEntity, ['type']) // Remove some fields to make cash order modal apper sa expected
            }
        });

        // Close current cash order modal
        this.props.resetModal();

        // Open analytics modal
        this.props.setModal(MODALS.REPORT_ANALYTICS, {
            mode: formModes.ADD
        });
    }

    // Reopen cash order modal after analytics modal was closed, and pass parameters from previous state
    _onCloseReportAnallyticsModalEventHandler = () => {
        const { fetchAnalytics } = this.props;

        this.reopenCashOrderModal(); // Open cash order modal with old params depending on mode
        fetchAnalytics(); // Reload analytics for this page because we added new one (this is used by filter)
    };

    _onCloseModal() {
        const { clearCashOrderForm, resetModal } = this.props;

        clearCashOrderForm();
        resetModal();
    }

    render() {
        const {
            visible,
            modalProps,
            cashOrderEntity,
            fetchOrder,
            modal,
            onCashOrderModalClose,
            onCashOrderCreated
        } = this.props;
        const printMode = _.get(modalProps, 'printMode');
        const editMode = _.get(modalProps, 'editMode');
        const fromOrder = _.get(modalProps, 'fromOrder'); // Used when we open this modal from order page
        const fromStoreDoc = _.get(modalProps, 'fromStoreDoc');
        const fromClient = _.get(modalProps, 'fromClient');

        const fromStoreDocIncome = _.get(modalProps, 'fromStoreDocIncome'); // from storage-incomes page
        const fromStoreDocExpense = _.get(modalProps, 'fromStoreDocExpense'); // from storage-expense page

        return (
            <React.Fragment>
                <Modal
                    className={Styles.modal}
                    destroyOnClose
                    footer={null}
                    maskClosable={false}
                    onCancel={this._onCloseModal}
                    visible={visible === MODALS.CASH_ORDER}
                    width={860}
                >
                    {visible === MODALS.CASH_ORDER && (
                        <CashOrderForm
                            cashOrderEntity={cashOrderEntity}
                            editMode={editMode}
                            fetchOrder={fetchOrder}
                            fromClient={fromClient}
                            fromOrder={fromOrder}
                            fromStoreDoc={fromStoreDoc}
                            fromStoreDocExpense={fromStoreDocExpense}
                            fromStoreDocIncome={fromStoreDocIncome}
                            onCashOrderCreated={onCashOrderCreated}
                            onCashOrderModalClose={onCashOrderModalClose}
                            onCloseModal={this._onCloseModal}
                            onOpenAnalyticsModal={this._onOpenReportAnalyticsModal}
                            printMode={printMode}
                        />
                    )}
                </Modal>
                {/* This modal is placed here to be able to be opened it from cash order modal(old cash order have to be saved and then reused afler analytics modal is closed) */}
                <ReportAnalyticsModal
                    onCancelTrigger={this._onCloseReportAnallyticsModalEventHandler}
                    onOkTrigger={this._onCloseReportAnallyticsModalEventHandler}
                    visible={modal}
                />
            </React.Fragment>
        );
    }
}
