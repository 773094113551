exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__generalInfo---3yVhl{margin:0 20%}.styles-m__generalInfo---3yVhl .styles-m__generalInfoItem---3Q0RY{margin:8px 0}.styles-m__submitButton---317Kn{text-align:center;margin:8px 0}.styles-m__addRequisiteButton---2taBg{text-align:right;margin:8px 0}.styles-m__filters---1XeOJ{padding:10px 0}@media screen and (max-width:1199px){.styles-m__generalInfo---3yVhl{margin:0 5%}.styles-m__generalInfo---3yVhl .styles-m__generalInfoItem---3Q0RY{margin:12px 0}.styles-m__submitButton---317Kn button{width:100%}}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,+BACE,YAKF,CAHE,kEACE,YACF,CAGF,gCACE,iBAAkB,CAClB,YACF,CAEA,sCACE,gBAAiB,CACjB,YACF,CAEA,2BACE,cACF,CAEA,qCACE,+BACE,WAKF,CAHE,kEACE,aACF,CAGF,uCACE,UACF,CACF","file":"styles.m.css","sourcesContent":[".generalInfo {\n  margin: 0 20%;\n\n  & .generalInfoItem {\n    margin: 8px 0;\n  }\n}\n\n.submitButton {\n  text-align: center;\n  margin: 8px 0;\n}\n\n.addRequisiteButton {\n  text-align: right;\n  margin: 8px 0;\n}\n\n.filters {\n  padding: 10px 0;\n}\n\n@media screen and (max-width: 1199px) {\n  .generalInfo {\n    margin: 0 5%;\n\n    & .generalInfoItem {\n      margin: 12px 0;\n    }\n  }\n\n  .submitButton button {\n    width: 100%;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"generalInfo": "styles-m__generalInfo---3yVhl",
	"generalInfoItem": "styles-m__generalInfoItem---3Q0RY",
	"submitButton": "styles-m__submitButton---317Kn",
	"addRequisiteButton": "styles-m__addRequisiteButton---2taBg",
	"filters": "styles-m__filters---1XeOJ"
};