import { combineReducers } from 'redux';
import changeMileageModalReducer, { moduleName as changeMileageModalModule } from './changeMileageModal/duck';
import inspectionIntervalsReducer, { moduleName as inspectionIntervalModule } from './inspectionIntervals/duck';
import ordersReducer, { moduleName as ordersModule } from './orders/duck';
import registerForServiceModalReducer, {
    moduleName as registerForServiceModalModule
} from './registerForServiceModal/duck';

// Reexports from other ducks(required to access their content):
export * from './changeMileageModal/duck';
export * from './inspectionIntervals/duck';
export * from './orders/duck';
export * from './registerForServiceModal/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const mainModuleName = 'onlineServiceBook';
export const moduleName = 'common';
const prefix = `cpb/${mainModuleName}/${moduleName}`;

export const FETCH_VEHICLE = `${prefix}/FETCH_VEHICLE`;
export const FETCH_VEHICLE_SUCCESS = `${prefix}/FETCH_VEHICLE_SUCCESS`;
export const SET_FETCHING_VEHICLE = `${prefix}/SET_FETCHING_VEHICLE`;

export const FETCH_RECOMMENDATIONS = `${prefix}/FETCH_RECOMMENDATIONS`;
export const FETCH_RECOMMENDATIONS_SUCCESS = `${prefix}/FETCH_RECOMMENDATIONS_SUCCESS`;
export const SET_FETCHING_RECOMMENDATIONS = `${prefix}/SET_FETCHING_RECOMMENDATIONS`;

export const FETCH_LABORS = `${prefix}/FETCH_LABORS`;
export const FETCH_LABORS_SUCCESS = `${prefix}/FETCH_LABORS_SUCCESS`;
export const SET_FETCHING_LABORS = `${prefix}/SET_FETCHING_LABORS`;

export const FETCH_APPURTENANCES = `${prefix}/FETCH_APPURTENANCES`;
export const FETCH_APPURTENANCES_SUCCESS = `${prefix}/FETCH_APPURTENANCES_SUCCESS`;
export const SET_FETCHING_APPURTENANCES = `${prefix}/SET_FETCHING_APPURTENANCES`;

export const FETCH_AGREEMENTS = `${prefix}/FETCH_AGREEMENTS`;
export const FETCH_AGREEMENTS_SUCCESS = `${prefix}/FETCH_AGREEMENTS_SUCCESS`;
export const SET_FETCHING_AGREEMENTS = `${prefix}/SET_FETCHING_AGREEMENTS`;

export const SET_VEHICLE_ID = `${prefix}/SET_VEHICLE_ID`;
export const SET_FETCHING_VEHICLE_CLIENT = `${prefix}/SET_FETCHING_VEHICLE_CLIENT`;
export const SET_PAGE_ORDERS = `${prefix}/SET_PAGE_ORDERS`;
export const SET_FETCHING_VEHICLE_ORDERS = `${prefix}/SET_FETCHING_VEHICLE_ORDERS`;
export const SET_CURRENT_TAB = `${prefix}/SET_CURRENT_TAB`;
export const SET_TOKEN = `${prefix}/SET_TOKEN`;
export const SET_MANAGER_ID = `${prefix}/SET_MANAGER_ID`;

/** List of all available tabs to visit */
export const onlineServiceBookTabs = {
    home: 'home',
    inspectionIntervals: 'inspectionIntervals',
    orders: 'orders'
};

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    vehicleId: undefined, // Id of a vhicle to load data for
    vehicle: {},
    fetchingVehicle: false,

    client: {}, // Client owner of the vehicle
    fetchingVehicleClient: false,

    currentTab: onlineServiceBookTabs.home,

    recommendations: [],
    labors: [],
    appurtenances: [],
    fetchingRecommendations: false,
    fetchingLabors: false,
    fetchingAppurtenances: false,

    agreements: [],
    fetchingAgreements: false,

    token: undefined,
    managerId: undefined
};

export function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_VEHICLE_SUCCESS:
            const { vehicle, client } = payload;

            return {
                ...state,
                vehicle,
                client
            };

        case FETCH_RECOMMENDATIONS_SUCCESS:
            const { recommendations } = payload;

            return {
                ...state,
                recommendations
            };

        case FETCH_LABORS_SUCCESS:
            const { labors } = payload;

            return {
                ...state,
                labors
            };

        case FETCH_APPURTENANCES_SUCCESS:
            const { appurtenances } = payload;

            return {
                ...state,
                appurtenances
            };

        case FETCH_AGREEMENTS_SUCCESS:
            const { agreements } = payload;

            return {
                ...state,
                agreements
            };

        case SET_PAGE_ORDERS:
            const { page: pageOrders } = payload;

            return {
                ...state,
                vehicleOrdersData: {
                    ...state.vehicleOrdersData,
                    sort: {
                        ...state.vehicleOrdersData.sort,
                        page: pageOrders
                    }
                }
            };

        case SET_FETCHING_VEHICLE:
            return {
                ...state,
                fetchingVehicle: payload
            };

        case SET_FETCHING_VEHICLE_CLIENT:
            return {
                ...state,
                fetchingVehicleClient: payload
            };

        case SET_FETCHING_RECOMMENDATIONS:
            return {
                ...state,
                fetchingRecommendations: payload
            };
        case SET_FETCHING_LABORS:
            return {
                ...state,
                fetchingLabors: payload
            };

        case SET_FETCHING_APPURTENANCES:
            return {
                ...state,
                fetchingAppurtenances: payload
            };

        case SET_VEHICLE_ID:
            return {
                ...state,
                vehicleId: payload
            };

        case SET_CURRENT_TAB:
            return {
                ...state,
                currentTab: payload
            };

        case SET_TOKEN:
            return {
                ...state,
                token: payload
            };

        case SET_MANAGER_ID:
            return {
                ...state,
                managerId: payload
            };

        case SET_FETCHING_AGREEMENTS:
            return {
                ...state,
                fetchingAgreements: payload
            };

        default:
            return state;
    }
}

// Export this reducer in the way we can connect other reducers
export default combineReducers({
    [moduleName]: reducer,
    [ordersModule]: ordersReducer,
    [inspectionIntervalModule]: inspectionIntervalsReducer,
    [changeMileageModalModule]: changeMileageModalReducer,
    [registerForServiceModalModule]: registerForServiceModalReducer
});

/** ------------------------------------- Selectors ------------------------------------- * */

export const stateSelector = state => state[mainModuleName][moduleName];
export const selectVehicle = state => state[mainModuleName][moduleName].vehicle;
export const selectVehicleId = state => state[mainModuleName][moduleName].vehicleId;
export const selectVehicleFetching = state => state[mainModuleName][moduleName].fetchingVehicle;

export const selectClient = state => state[mainModuleName][moduleName].client;
export const selectVehicleClientFetching = state => state[mainModuleName][moduleName].fetchingVehicleClient;

export const selectCurrentTab = state => state[mainModuleName][moduleName].currentTab;

export const selectRecommendations = state => state[mainModuleName][moduleName].recommendations;
export const selectRecommendationsFetching = state => state[mainModuleName][moduleName].fetchingRecommendations;

export const selectLabors = state => state[mainModuleName][moduleName].labors;
export const selectLaborsFetching = state => state[mainModuleName][moduleName].fetchingLabors;

export const selectAppurtenances = state => state[mainModuleName][moduleName].appurtenances;
export const selectAppurtenancesFetching = state => state[mainModuleName][moduleName].fetchingAppurtenances;

export const selectAgreements = state => state[mainModuleName][moduleName].agreements;
export const selectAgreementsFetching = state => state[mainModuleName][moduleName].fetchingAgreements;

export const selectToken = state => state[mainModuleName][moduleName].token;
export const selectManagerId = state => state[mainModuleName][moduleName].managerId;
/* ------------------------------------- Action Creators ------------------------------------- */

export const fetchVehicle = () => ({
    type: FETCH_VEHICLE
});

export const fetchVehicleSuccess = ({ vehicle, client }) => ({
    type: FETCH_VEHICLE_SUCCESS,
    payload: { vehicle, client }
});

export const fetchAgreements = () => ({
    type: FETCH_AGREEMENTS
});

export const fetchAgreementsSuccess = ({ agreements }) => ({
    type: FETCH_AGREEMENTS_SUCCESS,
    payload: { agreements }
});

export const fetchRecommendations = () => ({
    type: FETCH_RECOMMENDATIONS
});

export const fetchRecommendationsSuccess = ({ recommendations }) => ({
    type: FETCH_RECOMMENDATIONS_SUCCESS,
    payload: { recommendations }
});

export const setFetchingVehicle = value => ({
    type: SET_FETCHING_VEHICLE,
    payload: value
});

export const fetchLabors = () => ({
    type: FETCH_LABORS
});

export const fetchLaborsSuccess = ({ labors }) => ({
    type: FETCH_LABORS_SUCCESS,
    payload: { labors }
});

export const fetchAppurtenances = () => ({
    type: FETCH_APPURTENANCES
});

export const fetchAppurtenancesSuccess = ({ appurtenances }) => ({
    type: FETCH_APPURTENANCES_SUCCESS,
    payload: { appurtenances }
});

export const setFetchingVehicleClient = value => ({
    type: SET_FETCHING_VEHICLE_CLIENT,
    payload: value
});

export const setFetchingRecommendations = value => ({
    type: SET_FETCHING_RECOMMENDATIONS,
    payload: value
});

export const setFetchingLabors = value => ({
    type: SET_FETCHING_LABORS,
    payload: value
});

export const setFetchingAppurtenances = value => ({
    type: SET_FETCHING_LABORS,
    payload: value
});

/**
 * Automatically fetches vehicle by provided vehicleId
 * @param {*} vehicleId
 */
export const setVehicleId = vehicleId => {
    return function (dispatch) {
        dispatch({
            type: SET_VEHICLE_ID,
            payload: vehicleId
        });

        dispatch(fetchVehicle());
    };
};

export const setCurrentTab = value => ({
    type: SET_CURRENT_TAB,
    payload: value
});

export const setToken = value => ({
    type: SET_TOKEN,
    payload: value
});

export const setFetchingAgreements = value => ({
    type: SET_FETCHING_AGREEMENTS,
    payload: value
});

export const setManagerId = value => ({
    type: SET_MANAGER_ID,
    payload: value
});
