import React from 'react';
import { injectIntl } from 'react-intl';
import { types } from './constants';

/**
 * The purpose of this module is to map default document types and its translations,
 * to get translated constant value just pass "type" variable(constant value of a document)
 * and then this node object will automatically generate translation to current locale text node.
 *
 * @property {string} type - Document type constant(INCOME, EXPENSE, ADJUSTMENT)
 */
@injectIntl
export default class DocumentTypesMapper extends React.Component {
    constructor(props) {
        super(props);
    }

    /** Get corresponding type translation base on received constant value
     *
     * @param {string} type constant from order
     * @returns translated text representation
     */
    statusLangMapper = type => {
        const {
            intl: { formatMessage }
        } = this.props;

        switch (type.toUpperCase()) {
            case types.income:
                return formatMessage({ id: 'document_types_mapper.income' });
            case types.expense:
                return formatMessage({ id: 'document_types_mapper.expense' });
            case types.adjustment:
                return formatMessage({ id: 'document_types_mapper.adjustment' });
            default:
                return formatMessage({ id: 'document_types_mapper.unknown_status' });
        }
    };

    render() {
        const { type } = this.props;

        return <span>{this.statusLangMapper(type)}</span>;
    }
}
