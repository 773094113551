import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export default class PaymentPage extends Component {
    render() {
        return (
            <Layout
                title={<FormattedMessage id='navigation.payment' />}
                // description={ <FormattedMessage id='chart-page.description' /> }
            >
                'PaymentPage'
            </Layout>
        );
    }
}
