exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__formWrapper---1l671{display:flex;flex-direction:column;gap:32px}.styles-m__formItem---O8XCu{display:flex;gap:8px;flex:1}.styles-m__rules---3f-YD{color:red;margin-left:3px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,+BACI,YAAa,CACb,qBAAsB,CACtB,QACJ,CAEA,4BACI,YAAa,CACb,OAAQ,CACR,MACJ,CAEA,yBACI,SAAU,CACV,eACJ","file":"styles.m.css","sourcesContent":[".formWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n}\n\n.formItem {\n    display: flex;\n    gap: 8px;\n    flex: 1;\n}\n\n.rules {\n    color: red;\n    margin-left: 3px;\n}\n\n.formRow  {\n    /* & > div {\n        width: 240px;\n    } */\n}"]}]);

// Exports
exports.locals = {
	"formWrapper": "styles-m__formWrapper---1l671",
	"formItem": "styles-m__formItem---O8XCu",
	"rules": "styles-m__rules---3f-YD",
	"formRow": "styles-m__formRow---2nWtU"
};