import { Button, Image } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { ChangePhotoSchemeModal } from './modals';

@injectIntl
export class PreviewTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pointsList: [],
            list: [],
            loading: true,

            carPhoto: []
        };
    }

    componentDidMount() {
        this.getVehicleImage();
        this.fetchData();
    }

    fetchData = async () => {
        await this.getPhotoList();
    };

    getVehicleImage = async () => {
        const { vehicleId } = this.props;
        const vehicleImgId = await fetchAPI('GET', '/vehicle/image', { vehicleId }, null, {
            handleErrorInternally: true
        });
        this.setState({
            vehicleImgId: vehicleImgId.imageId
        });
    };

    getPhotoList = async imgId => {
        const { orderImgId } = this.props;

        const list = await fetchAPI(
            'GET',
            'orders/vehicles/photo/list',
            {
                query: {}
            },
            null,
            { handleErrorInternally: true }
        );

        const index = list.findIndex(({ id }) => id === this.state.vehicleImgId);

        await this.setState({
            list,
            hash: list[index !== -1 ? index : 0].imgHash,
            typeId: list[index !== -1 ? index : 0].id
        });

        this.getPhoto();
    };

    getPhoto = async () => {
        const { hash, typeId } = this.state;
        const photo = await fetchAPI('GET', 'orders/vehicle/photo', { hash, typeId }, null, {
            handleErrorInternally: true,
            rawResponse: true
        });
        const reportFile = await photo.blob();
        const url = URL.createObjectURL(reportFile);

        const file = {
            key: hash,
            uid: hash,
            status: 'done',
            url,
            thumbUrl: url
        };

        this.setState({
            carPhoto: file,
            loading: false
        });
    };

    showPhotoModal = () => {
        this.setState({
            visibleChangePhotoSchemeModal: true
        });
    };

    render() {
        const {
            intl: { formatMessage },
            disabled,
            vehicleId
        } = this.props;

        const {
            visibleChangePhotoSchemeModal,
            carPhoto,

            list,
            fileList,
            hash,
            typeId,
            loading,
            vehicleImgId
        } = this.state;

        // const id = _.get(this.state.vehicleImgId, 'imageId');

        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0 0 8px 0'
                    }}
                >
                    <span
                        style={{
                            fontSize: 18
                        }}
                    >
                        <FormattedMessage id='orders.vehicle' />
                    </span>

                    <div>
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                this.showPhotoModal();
                            }}
                            style={{
                                marginRight: 8
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='order_form_table_intake_tab.change_car' />
                        </Button>
                    </div>
                </div>

                {carPhoto && !loading && (
                    <div
                        style={{
                            position: 'relative',
                            border: '1px solid var(--lightGray)'
                        }}
                    >
                        <Image
                            id='img'
                            preview={false}
                            src={vehicleImgId === null ? undefined : carPhoto.url}
                            width='100%'
                        ></Image>
                    </div>
                )}

                <ChangePhotoSchemeModal
                    fileList={fileList}
                    getPhoto={this.getPhoto}
                    getPhotoList={this.getPhotoList}
                    getVehicleImage={this.getVehicleImage}
                    hash={hash}
                    hideModal={() => {
                        this.setState({ visibleChangePhotoSchemeModal: false });
                    }}
                    list={list}
                    typeId={typeId}
                    vehicleId={vehicleId}
                    visible={visibleChangePhotoSchemeModal}
                />
            </React.Fragment>
        );
    }
}

export default PreviewTab;
