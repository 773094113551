import { notification } from 'antd';
import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { SAVE_MILEAGE, selectMileage, selectMileageDateTime } from './duck';

export function* saveMileageModal() {
    while (true) {
        try {
            const {
                payload: { vehicleId }
            } = yield take(SAVE_MILEAGE);

            const lastMileageDatetime = yield select(selectMileageDateTime);
            const lastMileageValue = yield select(selectMileage);

            yield call(
                fetchAPI,
                'PUT',
                `clients/vehicles/${vehicleId}`,
                null,
                { lastMileageDatetime, lastMileageValue },
                { handleErrorInternally: true }
            );
            notification.success();
        } catch (err) {
            yield put(emitError(err));
            notification.error();
        }
    }
}

export function* saga() {
    yield all([call(saveMileageModal)]);
}
