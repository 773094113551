/* Constants */
export const moduleName = 'productPage';
const prefix = `cpb/${moduleName}`;

export const SET_STORE_DOCS_ORDERS = `${prefix}/SET_STORE_DOCS_ORDERS`;

export const FETCH_STORE_DOCS_ORDERS = `${prefix}/FETCH_STORE_DOCS_ORDERS`;
export const FETCH_STORE_DOCS_ORDERS_SUCCESS = `${prefix}/FETCH_STORE_DOCS_ORDERS_SUCCESS`;

/** Date format for backend */
export const DEF_DATE_FORMAT = 'YYYY/MM/DD';

/* Reducer */
const ReducerState = {
    ordersStoreDocs: {
        filters: {
            productId: undefined,
            startDate: undefined,
            endDate: undefined,
            page: 1,
            showOnlyOrders: true,
            showOnlyReserves: false,
            brandId: undefined,
            warehouseId: undefined
        },
        ordersStoreDocs: [],
        stats: {}
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_STORE_DOCS_ORDERS:
            return {
                ...state,
                ordersStoreDocs: {
                    ...state.ordersStoreDocs,
                    filters: { ...state.ordersStoreDocs.filters, ...payload }
                }
            };

        case FETCH_STORE_DOCS_ORDERS_SUCCESS:
            return {
                ...state,
                ordersStoreDocs: {
                    ...state.ordersStoreDocs,
                    ordersStoreDocs: payload.storeDocsOrders,
                    stats: payload.stats
                }
            };

        default:
            return state;
    }
}

/* Selectors */

export const selectStoreDocsOrdersFilters = state => state[moduleName].ordersStoreDocs.filters;
export const selectStoreDocsOrders = state => state[moduleName].ordersStoreDocs.ordersStoreDocs;
export const selectStoreDocsOrdersStats = state => state[moduleName].ordersStoreDocs.stats;

/* Actions */

export const setStoreDocsOrdersFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_STORE_DOCS_ORDERS,
            payload: filters
        });
        dispatch(fetchStoreDocsOrders());
    };
};

export const fetchStoreDocsOrders = () => ({
    type: FETCH_STORE_DOCS_ORDERS
});

export const fetchStoreDocsOrdersSuccess = ({ storeDocsOrders, stats }) => ({
    type: FETCH_STORE_DOCS_ORDERS_SUCCESS,
    payload: { storeDocsOrders, stats }
});
