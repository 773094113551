import { DatePicker, InputNumber, Modal, Select, Table } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';
import { EUR, USD } from '..';

const CurrencyRowModal = ({
    open,
    onClose,
    fetchJournal,
    currencyOptions,
    selectedCurrencies,
    changeSelectedCurrencies
}) => {
    const [dataSource, setDataSource] = useState([{ date: dayjs() }]);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (open && typeof open !== 'boolean') {
            setEdit(true);
            setDataSource([{ ...open, date: dayjs(open.date) }]);
        }
    }, [open]);

    const handleClose = () => {
        setDataSource([{ date: dayjs() }]);
        setEdit(false);
        onClose();
    };

    const columns = () => {
        const usdId = get(selectedCurrencies, '[0]');
        const eurId = get(selectedCurrencies, '[1]');
        const curr1 = get(selectedCurrencies, '[2]');
        const curr2 = get(selectedCurrencies, '[3]');
        const curr3 = get(selectedCurrencies, '[4]');

        return [
            {
                render: record => (
                    <DatePicker
                        format='DD.MM.YYYY'
                        onChange={date => setDataSource([{ ...record, date }])}
                        value={record.date}
                    />
                )
            },
            {
                title: USD,
                render: record => (
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={exchangeRate =>
                            setDataSource([{ ...record, [usdId]: { id: get(record, `[${usdId}].id`), exchangeRate } }])
                        }
                        precision={4}
                        value={get(record, `[${usdId}].exchangeRate`)}
                    />
                )
            },
            {
                title: EUR,
                render: record => (
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={exchangeRate =>
                            setDataSource([{ ...record, [eurId]: { id: get(record, `[${eurId}].id`), exchangeRate } }])
                        }
                        precision={4}
                        value={get(record, `[${eurId}].exchangeRate`)}
                    />
                )
            },
            {
                title: () => (
                    <Select
                        disabled
                        onChange={val => {
                            changeSelectedCurrencies([val, curr2, curr3]);
                        }}
                        options={currencyOptions}
                        showSearch
                        value={curr1}
                    />
                ),
                render: record => (
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={exchangeRate =>
                            setDataSource([{ ...record, [curr1]: { id: get(record, `[${curr1}].id`), exchangeRate } }])
                        }
                        precision={4}
                        value={get(record, `[${curr1}].exchangeRate`)}
                    />
                )
            },
            {
                title: () => (
                    <Select
                        disabled
                        onChange={val => {
                            changeSelectedCurrencies([curr1, val, curr3]);
                        }}
                        options={currencyOptions}
                        showSearch
                        value={curr2}
                    />
                ),
                render: record => (
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={exchangeRate =>
                            setDataSource([{ ...record, [curr2]: { id: get(record, `[${curr2}].id`), exchangeRate } }])
                        }
                        precision={4}
                        value={get(record, `[${curr2}].exchangeRate`)}
                    />
                )
            },
            {
                title: () => (
                    <Select
                        disabled
                        onChange={val => {
                            changeSelectedCurrencies([curr1, curr2, val]);
                        }}
                        options={currencyOptions}
                        showSearch
                        value={curr3}
                    />
                ),
                render: record => (
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={exchangeRate =>
                            setDataSource([{ ...record, [curr3]: { id: get(record, `[${curr3}].id`), exchangeRate } }])
                        }
                        precision={4}
                        value={get(record, `[${curr3}].exchangeRate`)}
                    />
                )
            }
        ];
    };

    const postCurrencyRow = async () => {
        if (!edit) {
            const payload = { ...dataSource[0] };
            delete payload.date;
            await fetchAPI(
                'POST',
                'business/currency/journal',
                null,
                Object.entries(payload)
                    .filter(([currencyId, { exchangeRate }]) => Boolean(currencyId) && exchangeRate)
                    .map(([currencyId, { exchangeRate }]) => ({
                        date: dataSource[0].date,
                        currencyId,
                        exchangeRate
                    })),
                { handleErrorInternally: true }
            );
        } else {
            const payload = { ...dataSource[0] };
            delete payload.date;
            await fetchAPI(
                'PUT',
                'business/currency/journal',
                null,
                Object.entries(payload)
                    .filter(([currencyId, { exchangeRate }]) => Boolean(currencyId) && exchangeRate)
                    .map(([currencyId, { exchangeRate, id }]) => ({
                        id,
                        exchangeRate
                    })),
                {
                    handleErrorInternally: true
                }
            );
        }
        fetchJournal();
    };

    const onOk = () => {
        try {
            postCurrencyRow();
        } catch (err) {
            return;
        }
        handleClose();
    };

    return (
        <Modal
            maskClosable={false}
            onCancel={handleClose}
            onOk={onOk}
            open={open}
            title={<FormattedMessage id='exchange_rate.add_currency' />}
            width='fit-content'
        >
            <Table columns={columns()} dataSource={dataSource} size='small' />
        </Modal>
    );
};

export default CurrencyRowModal;
