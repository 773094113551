import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import ManageExtraSpendings from '../../modals/ManageExtraSpendings';

const AdditionalSpendingsTab = ({ storeDocId, fetchStorageDocument, disabled, intl: { formatMessage } }) => {
    const [additionalCosts, setAdditionalCosts] = useState([]);
    const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
    const [total, setTotal] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);
    const [extraSpendingsOpen, setExtraSpendingsOpen] = useState(false);
    const [row, setRow] = useState({});
    const [shouldTableUpdate, setShouldTableUpdate] = useState(true);
    const [sum, setSum] = useState(0);
    const [allocateLoading, setAllocateLoading] = useState(false);

    const changePagination = newPagination => setPagination(prev => ({ ...prev, ...newPagination }));

    const openExtraSpendingsModal = (row = {}) => {
        setExtraSpendingsOpen(true);
        setRow(row);
    };

    const closeExtraSpendingsModal = () => {
        setExtraSpendingsOpen(false);
        setRow({});
    };

    const addSpendingsRow = async row => {
        await fetchAPI(
            'POST',
            '/store_doc_additional_costs',
            null,
            { storeDocId, ...row },
            {
                handleErrorInternally: true
            }
        );
        setShouldTableUpdate(true);
    };

    const editSpendingsRow = async rows => {
        await fetchAPI(
            'PUT',
            '/store_doc_additional_costs',
            null,
            { storeDocId, data: rows },
            {
                handleErrorInternally: true
            }
        );
        setShouldTableUpdate(true);
    };

    const deleteSpendingsRow = async id => {
        await fetchAPI(
            'DELETE',
            '/store_doc_additional_costs',
            null,
            { id },
            {
                handleErrorInternally: true
            }
        );
        setShouldTableUpdate(true);
    };

    const columns = useMemo(
        () => [
            {
                width: 'fit-content',
                render: (_, record) => (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 8 }}>
                        <Button
                            disabled={disabled}
                            icon={<EditOutlined />}
                            onClick={() => openExtraSpendingsModal(record)}
                        />
                        <Popconfirm
                            disabled={disabled}
                            onConfirm={() => deleteSpendingsRow(record.id)}
                            title={`${formatMessage({ id: 'delete' })}?`}
                        >
                            <Button disabled={disabled} icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </div>
                )
            },
            {
                title: formatMessage({ id: 'storage.type' }),
                dataIndex: 'type',
                render: type => formatMessage({ id: `extra_spendings.${type}` })
                // render: value => <Select options={typeOptions} value={value} />
            },
            {
                title: formatMessage({ id: 'name_2' }),
                dataIndex: 'name'
                // render: value => <Input value={value} />
            },
            {
                title: formatMessage({ id: 'comment' }),
                dataIndex: 'comment'
                // render: value => <TextArea value={value} />
            },
            {
                title: formatMessage({ id: 'sum' }),
                dataIndex: 'sum'
                // render: value => <InputNumber value={value} />
            }
        ],
        [formatMessage, disabled]
    );

    const allocateSpendings = async () => {
        try {
            setAllocateLoading(true);
            await fetchAPI(
                'POST',
                '/allocate_costs',
                null,
                { storeDocId },
                {
                    handleErrorInternally: true
                }
            );

            notification.success({ message: formatMessage({ id: 'barcode.success' }) });
        } catch {
            notification.error({ message: formatMessage({ id: 'error' }) });
        } finally {
            setAllocateLoading(false);
            fetchStorageDocument();
        }
    };

    useEffect(() => {
        if (shouldTableUpdate) {
            const fetchAdditionalCosts = async () => {
                setTableLoading(true);
                const { current, pageSize } = pagination;
                const { count, data, sum } = await fetchAPI(
                    'GET',
                    '/store_doc_additional_costs',
                    { storeDocId, page: current, pageSize },
                    null,
                    {
                        handleErrorInternally: true
                    }
                );
                setAdditionalCosts(data);
                setSum(sum);
                setTotal(count);
                setTableLoading(false);
            };
            fetchAdditionalCosts();
            setShouldTableUpdate(false);
        }
    }, [pagination, storeDocId, shouldTableUpdate, formatMessage]);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                {!disabled && (
                    <div style={{ display: 'flex', gap: 8 }}>
                        <Button onClick={() => openExtraSpendingsModal()}>
                            <FormattedMessage id='extra_spendings.add_additional_spendings' />
                        </Button>
                        <Button loading={allocateLoading} onClick={allocateSpendings} type='primary'>
                            <FormattedMessage id='extra_spendings.allocate_costs' />
                        </Button>
                    </div>
                )}
                <Table
                    bordered
                    columns={columns}
                    dataSource={additionalCosts}
                    loading={tableLoading}
                    pagination={{ ...pagination, total, onChange: current => changePagination({ current }) }}
                    rowKey='id'
                    size='small'
                    summary={() => (
                        <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <FormattedMessage id='extra_spendings.total' />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>{sum}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    )}
                />
            </div>
            <ManageExtraSpendings
                addSpendingsRow={addSpendingsRow}
                closeModal={closeExtraSpendingsModal}
                editSpendingsRow={editSpendingsRow}
                open={extraSpendingsOpen}
                row={row}
            />
        </div>
    );
};

export default injectIntl(AdditionalSpendingsTab);
