import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    DELETE_EMPLOYEE,
    FETCH_EMPLOYEES,
    FETCH_EMPLOYEE_ACCEPT_TASK_COUNT,
    FETCH_EMPLOYEE_TASK_COUNT,
    deleteEmployeeSuccess,
    fetchEmployeeAcceptTaskCountSuccess,
    fetchEmployeeTaskCountSuccess,
    fetchEmployees,
    fetchEmployeesSuccess
} from './duck';

export function* fetchEmployeesSaga() {
    while (true) {
        try {
            yield take(FETCH_EMPLOYEES);

            const filter = yield select(state => state.employees.filter);

            const data = yield call(fetchAPI, 'GET', 'employees', filter, null, {handleErrorInternally: true});

            yield put(fetchEmployeesSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        }
    }
}
export function* deleteEmployee() {
    while (true) {
        try {
            const { payload: id } = yield take(DELETE_EMPLOYEE);

            const data = yield call(fetchAPI, 'DELETE', `employees/${id}`);

            yield put(deleteEmployeeSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(fetchEmployees());
        }
    }
}

//
// export function* fetchTasksSaga() {
//     while (true) {
//         try {
//             let oldTaskCount = yield select(selectTaskCount);
//
//             try {
//                 const response = yield call(fetchAPI,'GET', 'employee_new_tasks_count');
//
//                 if (_.get(response, 'totalRowsCount') != oldTaskCount) {
//                     yield put(setTaskCount(response));
//                 }
//
//             } catch (err) {
//                 console.log("E: ", err);
//             }
//
//             yield delay(1000);
//
//         } catch (error) {
//             console.log("Error: ", error)
//             yield put(emitError(error));
//         }
//     }
// }
export function* fetchTasksSaga() {
    while (true) {
        try {
            yield take(FETCH_EMPLOYEE_TASK_COUNT);

            const response = yield call(fetchAPI, 'GET', 'employee_new_tasks_count', null, null, {handleErrorInternally: true});

            yield put(fetchEmployeeTaskCountSuccess(response));
            // let oldTaskCount = yield select(selectTaskCount);
            //
            // try {
            //     const response = yield call(fetchAPI,'GET', 'employee_new_tasks_count');
            //
            //     if (_.get(response, 'totalRowsCount') != oldTaskCount) {
            //         yield put(setTaskCount(response));
            //     }
            //
            // } catch (err) {
            //     console.log("E: ", err);
            // }
            //
            // yield delay(1000);
        } catch (error) {
            console.log('Error: ', error);
            yield put(emitError(error));
        }
    }
}

export function* fetchAcceptTasksSaga() {
    while (true) {
        try {
            yield take(FETCH_EMPLOYEE_ACCEPT_TASK_COUNT);

            const response = yield call(
                fetchAPI,
                'GET',
                'employee_tasks_count?statuses=["ACCEPT"]', null, null, {handleErrorInternally: true}
            );

            yield put(fetchEmployeeAcceptTaskCountSuccess(response));
        } catch (error) {
            console.log('Error: ', error);
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([
        call(fetchEmployeesSaga),
        call(deleteEmployee),
        call(fetchTasksSaga),
        call(fetchAcceptTasksSaga)
    ]);
}
