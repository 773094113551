/* Constants */

export const moduleName = 'storeDocServiceModal';
const prefix = `cpb/${moduleName}`;

export const FETCH_ANALYTICS = `${prefix}/FETCH_ANALYTICS`;
export const FETCH_ANALYTICS_SUCCESS = `${prefix}/FETCH_ANALYTICS_SUCCESS`;

export const SET_STORE_DOC_SERVICE_FIELDS = `${prefix}/SET_STORE_DOC_SERVICE_FIELDS`;
export const RESET_STORE_DOC_SERVICE_FIELDS = `${prefix}/RESET_STORE_DOC_SERVICE_FIELDS`;

export const CREATE_STORE_DOC_SERVICE = `${prefix}/CREATE_STORE_DOC_SERVICE`;
export const CREATE_STORE_DOC_SERVICE_SUCCESS = `${prefix}/CREATE_STORE_DOC_SERVICE_SUCCESS`;

export const UPDATE_STORE_DOC_SERVICE = `${prefix}/UPDATE_STORE_DOC_SERVICE`;
export const UPDATE_STORE_DOC_SERVICE_SUCCESS = `${prefix}/UPDATE_STORE_DOC_SERVICE_SUCCESS`;

/** Date format for backend */
export const DEF_DATE_FORMAT = 'YYYY/MM/DD';

/* Reducer */
const ReducerState = {
    analytics: [],
    analyticsFilters: {
        level: 2
    },

    fields: {
        name: undefined,
        storeDocId: undefined,
        analyticsId: undefined,
        orderId: undefined,
        price: 1,
        count: 1,
        sum: 1,
        docServiceUnitId: 44
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: payload
            };
        case SET_STORE_DOC_SERVICE_FIELDS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...payload
                }
            };
        case RESET_STORE_DOC_SERVICE_FIELDS:
            return {
                ...state,
                fields: {
                    name: undefined,
                    storeDocId: undefined,
                    analyticsId: undefined,
                    orderId: undefined,
                    docServiceUnitId: undefined,
                    price: 1,
                    count: 1,
                    sum: 1
                }
            };

        default:
            return state;
    }
}

/* Selectors */

export const selectAnalytics = state => state[moduleName].analytics;
export const selectFiltersAnalytics = state => state[moduleName].analyticsFilters;
export const selectStoreDocServiceFields = state => state[moduleName].fields;

/* Actions */

export const fetchAnalytics = () => ({
    type: FETCH_ANALYTICS
});

export const fetchAnalyticsSuccess = ({ analytics }) => ({
    type: FETCH_ANALYTICS_SUCCESS,
    payload: analytics
});

export const setStoreDocServiceFields = fields => ({
    type: SET_STORE_DOC_SERVICE_FIELDS,
    payload: fields
});

export const resetStoreDocServiceFields = () => ({
    type: RESET_STORE_DOC_SERVICE_FIELDS
});

export const createStoreDocService = () => ({
    type: CREATE_STORE_DOC_SERVICE
});

export const createStoreDocServiceSuccess = () => ({
    type: CREATE_STORE_DOC_SERVICE_SUCCESS
});

export const updateStoreDocService = () => ({
    type: UPDATE_STORE_DOC_SERVICE
});

export const updateStoreDocServiceSuccess = () => ({
    type: UPDATE_STORE_DOC_SERVICE_SUCCESS
});
