import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { FETCH_STORE_DOCS_ORDERS, fetchStoreDocsOrdersSuccess, selectStoreDocsOrdersFilters } from './duck';

export function* fetchStoreDocsOrdersSaga() {
    while (true) {
        try {
            yield take(FETCH_STORE_DOCS_ORDERS);

            const payloadFilters = yield select(selectStoreDocsOrdersFilters);

            const filters = {
                ...payloadFilters,
                startDate: _.get(payloadFilters, 'startDate')
                    ? payloadFilters.startDate.format('YYYY-MM-DD')
                    : undefined,
                endDate: _.get(payloadFilters, 'endDate') ? payloadFilters.endDate.format('YYYY-MM-DD') : undefined
            };

            yield nprogress.start();

            const result = yield call(fetchAPI, 'GET', 'store_doc_products', filters, null, {
                handleErrorInternally: true
            });

            yield put(fetchStoreDocsOrdersSuccess({ storeDocsOrders: result.list, stats: result.stats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([call(fetchStoreDocsOrdersSaga)]);
}
