import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

const Datetime = styled.div`
    display: flex;
    flex-direction: column;
    width: 75px;
`;

export default ({ datetime }) => <Datetime>{dayjs(datetime).format('DD.MM.YYYY HH:mm')}</Datetime>;
