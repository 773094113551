import { Button, Input, InputNumber, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from '../styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

@injectIntl
export default class SalaryEditRowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.visible && this.props.visible) {
            if (this.props.editRow) {
                this.setState({
                    ...this.props.editRow
                });
            }
        }
    }

    handleOk = async () => {
        const { id, fetchSalaryDocData } = this.props;
        const { orderSum, percent, salary } = this.state;
        await fetchAPI(
            'PUT',
            '/update/salary/rows',
            undefined,
            {
                id: this.props.editRow.id,
                percent,
                salary: _.get(this.props.editRow, 'type') === 'ORDER' ? orderSum : salary,
                creationType: 'MANUAL'
            },
            { handleErrorInternally: true }
        );

        this.props.hideModal();
        fetchSalaryDocData();
    };

    handleCancel() {
        this.setState({
            employeeId: undefined,
            type: undefined,
            salary: undefined
        });
        this.props.hideModal();
    }

    render() {
        const { allEmployees, isMobile } = this.props;
        const { percent, orderSum, salary } = this.state;

        return (
            <Modal
                destroyOnClose
                footer={
                    <div>
                        <Button
                            key='back'
                            onClick={() => {
                                this.handleCancel();
                            }}
                        >
                            <FormattedMessage id='stock_table.button.cancel' />
                        </Button>
                        <Button
                            key='submit'
                            onClick={async () => {
                                await this.handleOk();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='ok' />
                        </Button>
                    </div>
                }
                maskClosable={false}
                onCancel={() => {
                    this.handleCancel();
                }}
                title={<FormattedMessage id='regulations.edit_row' />}
                visible={this.props.visible}
                width= {isMobile ? '95%' : '25%'}
                zIndex={200}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'flex-end',
                        margin: '24px 0 0 0'
                    }}
                >
                    {_.get(this.props.editRow, 'type') === 'ORDER' ? (
                        <div className={Styles.addProductItemWrap}>
                            <div>
                                <FormattedMessage id='employee_salary_rules.sal' />
                            </div>
                            <span className={Styles.priceslNumberInput}>
                                    <InputNumber
                                        decimalSeparator=','
                                        min={0.01}
                                        onChange={orderSum => {
                                            this.setState({
                                                orderSum
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'info_modal.value'
                                        })}
                                        precision={2}
                                        step={0.01}
                                        style={
                                            {
                                                // marginLeft: 10,
                                            }
                                        }
                                        value={orderSum}
                                    />
                            </span>
                        </div>
                    ) : (
                        <div className={Styles.addProductItemWrap}>
                            <div>
                                <FormattedMessage id='employee_salary_rules.sal' />
                            </div>
                            <span className={Styles.priceslNumberInput}>
                                    <InputNumber
                                        decimalSeparator=','
                                        //max={_.get(this.props.editRow, 'type') == 'PENALTY' ? 0 : null}
                                        onChange={value => {
                                            let newSalary = value;
                                            if ((_.get(this.props.editRow, 'dataRow') === 'BONUS') && value < 0) {
                                                newSalary = -value;
                                            } else if ((_.get(this.props.editRow, 'dataRow') === 'PENALTY') && value > 0) {
                                                newSalary = -value;
                                            } else if (_.get(this.props.editRow, 'dataRow') === 'PAYOUT') {
                                                newSalary = value;
                                            } else if (value === null || value === undefined) {
                                                newSalary = 0.00;
                                            }
                                            this.setState({
                                                salary: newSalary
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'info_modal.value'
                                        })}
                                        precision={2}
                                        step={0.01}
                                        style={
                                            {
                                                // marginLeft: 10,
                                            }
                                        }
                                        value={salary}
                                    />
                            </span>
                        </div>
                    )}

                    <div className={Styles.addProductItemWrap}>
                        <div>
                            <FormattedMessage id='employee_salary_rules.sum_%' />
                        </div>
                        <span className={Styles.priceslNumberInput}>
                            <InputNumber
                                decimalSeparator=','
                                disabled
                                formatter={precent => `${precent}%`}
                                max={100}
                                min={0}
                                onChange={percent => {
                                    this.setState({
                                        percent
                                    });
                                }}
                                parser={value => value.replace('%', '').replace(',', '.')}
                                precision={2}
                                value={percent}
                            />
                        </span>
                    </div>
                </div>
            </Modal>
        );
    }
}
