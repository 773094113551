import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import {
    AccountsReceivableReportModal,
    CallsReportModal,
    CashBoxReportModal,
    ConsolidateOrderReportModal,
    ProductsMovementModal,
    RemainsReportModal,
    SMSReportModal,
    SalesReportModal,
    SuppliersReportModal,
    UniversalCashReportModal,
    UniversalOrderReportModal,
    UniversalProductReportModal
} from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

// import {
//     downloadSalesReport,
//     setAppointmentFromDate, setAppointmentToDate,
//     setCreationFromDate,
//     setCreationToDate,
//     setDetailing, setDoneFromDate, setDoneToDate
// } from "modals/SuppliersReportModal/redux/duck";

const struct = [
    {
        blockTitle: 'directories.popular',
        color: 'var(--db_approve)',
        visible: user => true,
        items: [
            {
                itemName: 'reports_page.remains_products',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_PRODUCTS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.REMAINS_REPORT_MODAL, { type: 'byProducts' });
                }
            },
            {
                itemName: 'reports_page.universal_order',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_TABULAR_REPORTS_ORDERS, accesses.ROWO),
                link: book.reportOrders,
                state: {
                    downloadReport: true
                }
            },
            {
                itemName: 'reports_page.by_days',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BY_DAYS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byDate' });
                }
            },
            {
                itemName: 'export_import_pages.cash_orders_cash_boxes',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTERS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.CASH_BOX_REPORT_MODAL);
                }
            }
        ]
    },
    {
        blockTitle: 'reports_page.CRM',
        color: 'var(--db_progress)',
        visible: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_CRM),
        items: [
            {
                itemName: 'client_container.calls',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_CRM_CALLS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.CALLS_REPORT_MODAL);
                }
            },
            {
                itemName: 'reports_page.sms',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_CRM_SMS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SMS_REPORT_MODAL);
                }
            },
            {
                itemName: 'reports_page.сonversions',
                disabled: user =>
                    true || !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_CRM_CONVERSIONS, accesses.ROWO),
                link: book.clientHotOperations
            },
            {
                itemName: 'reports_page.newsletters',
                disabled: user =>
                    true || !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_CRM_MAILINGS, accesses.ROWO),
                link: book.employeesPage
            },
            {
                itemName: 'order_statuses_mapper.invite',
                disabled: user =>
                    true || !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_CRM_INVITATIONS, accesses.ROWO),
                link: book.products
            },
            {
                itemName: 'navigation.tasks',
                disabled: user => true || !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_CRM_TASKS, accesses.ROWO),
                link: book.vehicles
            },
            {
                itemName: 'navigation.feedback',
                disabled: user =>
                    true || !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_CRM_REVIEWS, accesses.ROWO),
                link: book.clientHotOperations
            }
        ]
    },
    {
        blockTitle: 'navigation.products',
        visible: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS),
        color: 'var(--db_reserve)',
        items: [
            {
                itemName: 'reports_page.universal',
                link: book.products,
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_UNIVERSAL, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.UNIVERSAL_PRODUCT_REPORT_MODAL);
                }
            },
            {
                itemName: 'reports_page.remains_products',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_PRODUCTS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.REMAINS_REPORT_MODAL, { type: 'byProducts' });
                }
            },
            {
                itemName: 'reports_page.remains_by_warehouses',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_WAREHOUSES, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.REMAINS_REPORT_MODAL, { type: 'byWarehouses' });
                }
            },
            {
                itemName: 'reports_page.remains_by_cell',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_STOCKS_BY_CELLS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.REMAINS_REPORT_MODAL, { type: 'byCells' });
                }
            },
            {
                itemName: 'reports_page.movement_by_product',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_PRODUCT_MOVEMENT, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.PRODUCTS_MOVEMENT_MODAL);
                }
            },
            {
                itemName: 'navigation.inventory',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_INVENTORY, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.REMAINS_REPORT_MODAL, { type: 'stocktaking' });
                }
            },
            {
                itemName: 'reports_page.purchases_by_supplier',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_PURCHASES_BY_SUPPLIERS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.REMAINS_REPORT_MODAL, { type: 'purchases_by_supplier' });
                }
            },
            {
                itemName: 'reports_page.write_off',
                disabled: user =>
                    true || !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_PRODUCTS_WRITE_OFF, accesses.ROWO),
                link: book.employeesPage
            }
        ]
    },
    {
        blockTitle: 'reports_page.orders',
        visible: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS),
        color: 'var(--db_success)',
        items: [
            {
                itemName: 'reports_page.universal_by_orders',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_ORDER, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.UNIVERSAL_ORDER_REPORT_MODAL);
                }
            },
            {
                itemName: 'reports_page.universal_by_labors',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_WORKS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.UNIVERSAL_ORDER_REPORT_MODAL, { type: 'BY_LABORS' });
                }
            },
            {
                itemName: 'reports_page.universal_by_details',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_UNIVERSAL_BY_PARTS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.UNIVERSAL_ORDER_REPORT_MODAL, { type: 'BY_PRODUCTS' });
                }
            },
            {
                itemName: 'reports_page.consolidate_by_orders',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_SUMMARY_BY_PARTS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.CONSOLIDATE_ORDER_REPORT_MODAL);
                }
            },
            {
                itemName: 'navigation.by_business_income',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_PROFIT_BY_STO, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byBusinessIncome' });
                }
            },
            {
                itemName: 'reports_page.by_days',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BY_DAYS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byDate' });
                }
            },
            {
                itemName: 'reports_page.by_employees',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BY_EMPLOYEES, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byEmployees' });
                }
            },
            {
                itemName: 'reports_page.by_statuses',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BY_STATUSES, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byStatuses' });
                }
            },
            {
                itemName: 'navigation.by_recommendations',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BY_RECOMMENDATIONS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byRecommendations' });
                }
            },
            {
                itemName: 'reports_page.by_clients',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BY_CUSTOMERS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byClients' });
                }
            },
            {
                itemName: 'reports_page.by_vehicles',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BY_CARS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byVehicles' });
                }
            },
            {
                itemName: 'reports_page.by_posts',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BY_POSTS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byStations' });
                }
            },
            {
                itemName: 'navigation.labors_page',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_JOBS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byLabors' });
                }
            },
            {
                itemName: 'navigation.products_groups',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_PRODUCT_GROUPS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byProductGroups' });
                }
            },
            {
                itemName: 'reports_page.by_brands',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_BRANDS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byBrands' });
                }
            },
            {
                itemName: 'navigation.products',
                disabled: user => !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ORDERS_PRODUCTS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.SALES_REPORT_MODAL, { type: 'byProducts' });
                }
            }
        ]
    },
    {
        blockTitle: 'navigation.accounting',
        color: 'var(--approve)',
        visible: user => isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING),
        items: [
            {
                itemName: 'reports_page.universal_by_payments',
                link: book.products,
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_UNIVERSAL_BY_PAYMENTS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.UNIVERSAL_CASH_REPORT_MODAL);
                }
            },
            {
                itemName: 'directories.counterparties',
                disabled: user =>
                    true ||
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_COUNTERPARTIES, accesses.ROWO),
                link: book.vehicles
            },
            {
                itemName: 'reports_page.by_receivables',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_RECEIVABLES, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.ACCOUNTS_RECEIVABLE_REPORT_MODAL);
                }
            },
            {
                itemName: 'reports_page.by_creditor',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_PAYABLES, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.REPORT_SUPPLIERS_FILTER);
                }
            },
            {
                itemName: 'export_import_pages.cash_orders_cash_boxes',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTERS, accesses.ROWO),
                onClick: props => {
                    props.setModal(MODALS.CASH_BOX_REPORT_MODAL);
                }
            },
            {
                itemName: 'reports_page.by_rst',
                disabled: user =>
                    true ||
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_REGISTER_DEVICES, accesses.ROWO),
                link: book.vehicles
            },
            {
                itemName: 'reports_page.by_cash_flow',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_CASH_FLOW, accesses.ROWO),
                link: book.reportCashFlow,
                state: {
                    downloadReport: true
                }
            },
            {
                itemName: 'reports_page.by_salaries',
                disabled: user =>
                    !isGrantAccessed(user, grants.REPORTS_XLSX_REPORTS_ACCOUNTING_SALARIES, accesses.ROWO),
                link: book.employeesPage,
                state: {
                    downloadReport: true
                }
            }
        ]
    }
];

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

const mapDispatchToProps = {
    resetModal,
    setModal
};

@connect(mapStateToProps, mapDispatchToProps)
export default class ReportsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'reports' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    _renderBlock = ({ blockTitle, items, color, visible }, key) => {
        return visible(this.props.user) ? (
            <div key={key} className={Styles.block}>
                <div className={Styles.blockTitle}>
                    <FormattedMessage id={blockTitle} />
                </div>
                <div className={Styles.blockItems}>
                    {items.map((item, key) => this._renderItem(blockTitle, item, key, color))}
                </div>
            </div>
        ) : null;
    };

    _renderItem = (blockTitle, { onClick, itemName, link, disabled, state }, key, color) => {
        const { user } = this.props;
        const itemDisabled = _.isFunction(disabled) ? disabled(user) : disabled;

        return (
            <div key={key} className={itemDisabled ? `${Styles.disabledItem} ${Styles.item}` : Styles.item}>
                {onClick ? (
                    <Button
                        className={Styles.itemButton}
                        disabled={itemDisabled}
                        onClick={() => onClick(this.props)}
                        style={{ fontWeight: 500, border: `2px solid ${color}` }}
                    >
                        <FormattedMessage id={itemName} />
                    </Button>
                ) : (
                    <Link
                        className={Styles.buttonLink}
                        to={{
                            pathname: link,
                            state
                        }}
                    >
                        <Button
                            className={Styles.itemButton}
                            disabled={itemDisabled}
                            style={{ fontWeight: 500, border: `2px solid ${color}` }}
                        >
                            <FormattedMessage id={itemName} />
                        </Button>
                    </Link>
                )}
            </div>
        );
    };

    render() {
        const { helperDrawerOpen, allLinks } = this.state;

        return (
            <Layout
                controls={
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            const links = await fetchAPI('GET', '/helps', { helpId: 'reports' }, undefined, {
                                handleErrorInternally: true
                            });
                            this.setState({
                                allLinks: links,
                                helperDrawerOpen: true
                            });
                        }}
                        style={{
                            fontSize: 22,
                            marginLeft: 8,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                }
                description={<FormattedMessage id='reports_page.description' />}
                title={<FormattedMessage id='navigation.reports' />}
            >
                {struct.map((block, key) => this._renderBlock(block, key))}

                <SuppliersReportModal />
                <CallsReportModal />
                <SalesReportModal />
                <RemainsReportModal />
                <ProductsMovementModal />
                <AccountsReceivableReportModal />
                <UniversalProductReportModal />
                <UniversalCashReportModal />
                <UniversalOrderReportModal />
                <CashBoxReportModal />
                <ConsolidateOrderReportModal />
                <SMSReportModal />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
