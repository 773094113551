import { notification } from 'antd';
import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchRequest } from '../api';
import {
    CREATE_ORDER,
    resetRegisterForServiceModal,
    selectBeginDate,
    selectBeginTime,
    selectComment
} from './duck';

export function* createOrderSaga() {
    while (true) {
        const {
            payload: { clientId, managerId, businessId, vehicleId }
        } = yield take(CREATE_ORDER);
        if (!clientId || !managerId) {
            notification.error({
                message: 'Invalid input parameters, no client or manager ID'
            });
            continue;
        }

        // Get client
        const client = yield call(fetchRequest, 'GET', `clients/${clientId}`, null, null);
        const vehicle = yield call(fetchRequest, 'GET', `clients/vehicles/${vehicleId}`);

        const beginDate = yield select(selectBeginDate);
        const beginTime = yield select(selectBeginTime);
        const comment = yield select(selectComment);

        try {
            yield call(fetchRequest, 'POST', 'orders', null, {
                clientId: client.clientId,
                clientVehicleId: vehicleId || void 0,
                duration: 0.5,
                clientPhone: client.phones[0],
                stationLoads: [
                    {
                        beginDatetime: dayjs().startOf('hour').toISOString(),
                        duration: 0.5,
                        status: 'TO_DO'
                    }
                ],
                status: 'not_complete',
                managerId,
                beginDatetime: `${beginDate} ${beginTime}`,
                comment
            });

            // const { year, makeId, vehicleModelId } = vehicle;
            // const { name, middlename, surname } = client;

            // const phone = String(_.get(client, 'phones[0]', "")).replace(/[^0-9]/g, '');

            // const requestPayload = {
            //     "businessId": businessId,
            //     // "date":"2021-08-03T13:00:00.000Z",
            //     "date": dayjs.utc(`${beginDate} ${beginTime}`).toISOString(),
            //     "reminder":0,
            //     "comment": comment,
            //     "phoneNumber":{
            //         "code":"380",
            //         "phone":phone.startsWith("380") ? phone.substr(3): phone //If starts with 380 then remove it
            //     },
            //     "year":year,
            //     "makeId":makeId,
            //     "modelId":`${vehicleModelId}`,
            //     "name":`${name || ""} ${middlename || ""} ${surname || ""}`,
            //     "referral":"",
            //     "target":"/garages/sort-nearest",
            //     "filters":{
            //         "type":"garages"
            //     },
            //     "partnerData":null
            // };

            // //TODO set business
            // yield call(fetch, 'https://carbook.ua/api/orders/ru_ua', {
            //     method: 'POST',
            //     body: JSON.stringify(requestPayload),
            //     headers: {
            //         "content-type": "application/json",
            //         "accept": "*/*",
            //         "accept-encoding": "gzip, deflate, br",
            //         "mode": 'no-cors',
            //         "Access-Control-Allow-Origin": "*",
            //     }
            // });

            notification.success();
            yield put(resetRegisterForServiceModal());
        } catch (err) {
            yield put(emitError(err));

            notification.error({
                message: _.get(err, 'response.message')
            });
        }
    }
}

export function* saga() {
    yield all([call(createOrderSaga)]);
}
