import { combineReducers } from 'redux';
import expensesReducer, { moduleName as expenses } from './expenses';
import incomesReducer, { moduleName as incomes } from './incomes';
import priceGroupsReducer, { moduleName as priceGroups } from './priceGroups';
import productsReducer, { moduleName as products } from './products';
import storeBalanceReducer, { moduleName as storeBalance } from './storeBalance';
import storeGroupsReducer, { moduleName as storeGroups } from './storeGroups';
import storeMovementReducer, { moduleName as storeMovement } from './storeMovement';
import trackingReducer, { moduleName as tracking } from './tracking';

// combine all storage reducers to storage reducer fro global redux store
export const storageReducer = combineReducers({
    [storeGroups]: storeGroupsReducer,
    [priceGroups]: priceGroupsReducer,
    [products]: productsReducer,
    [storeBalance]: storeBalanceReducer,
    [storeMovement]: storeMovementReducer,
    [tracking]: trackingReducer,
    [incomes]: incomesReducer,
    [expenses]: expensesReducer
});
