import { Button, Select, Table } from 'antd';
import { Layout } from 'commons';
import { SingleDatePicker } from 'components';
import dayjs from 'dayjs';
import { VehicleLocationModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { isForbidden, permissions } from 'utils';

const { Option } = Select;

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@connect(mapStateToProps, void 0)
@injectIntl
export default class LocationsVehiclesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            locationId: undefined,
            dataSource: [],
            loading: false,
            currentPage: 1,
            paginationTotal: 0,
            modalVisible: false,
            modalVehicleId: undefined,
            modalClientId: undefined,
            modalCurrentLocation: undefined,
            toDatetime: dayjs()
        };

        this.columns = [
            {
                key: 'key',
                dataIndex: 'key',
                render: (data, row) => {
                    return data + 1;
                }
            },
            {
                title: <FormattedMessage id='locations.number' />,
                key: 'number',
                dataIndex: 'clientsVehicle',
                render: ({ number }, row) => {
                    return (
                        <Link to={`${book.vehicle}/${row.clientVehicleId}`}>
                            {number || <FormattedMessage id='long_dash' />}
                        </Link>
                    );
                }
            },
            {
                title: <FormattedMessage id='locations.vehicle' />,
                key: 'vehicle',
                dataIndex: 'clientsVehicle',
                render: ({ make, model, modification, year }, row) => {
                    return (
                        <Link to={`${book.vehicle}/${row.clientVehicleId}`}>
                            {`${make} ${model} ${modification} (${year})`}
                        </Link>
                    );
                }
            },
            {
                title: <FormattedMessage id='location' />,
                key: 'location',
                dataIndex: 'businessLocation',
                render: ({ name }, row) => {
                    return name;
                }
            },
            {
                title: <FormattedMessage id='locations.time' />,
                key: 'duration',
                dataIndex: 'duration',
                render: (data, row) => {
                    const days = Math.floor(data / 24);
                    const hours = Math.floor(data % 24);

                    return (
                        <div>
                            {days ? (
                                <span>
                                    {days} <FormattedMessage id='locations.days' />
                                </span>
                            ) : null}{' '}
                            {hours} <FormattedMessage id='locations.hours' />
                        </div>
                    );
                }
            },
            {
                key: 'action',
                width: 'min-content',
                render: data => {
                    return (
                        <Button
                            onClick={() => {
                                this.setState({
                                    modalVisible: true,
                                    modalVehicleId: data.clientsVehicle.id,
                                    modalClientId: data.client.id,
                                    modalCurrentLocation: data.businessLocation.id
                                });
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='locations.action' />
                        </Button>
                    );
                }
            },
            {
                key: 'history',
                width: 'min-content',
                render: row => {
                    return !isForbidden(
                        this.props.user,
                        permissions.ACCESS_LOCATIONS_VEHICLE_HISTORY
                    ) ? (
                        <Link
                            to={{
                                pathname: book.locationsMovement,
                                state: { vehicleId: row.clientVehicleId }
                            }}
                        >
                            <Button type='primary'>
                                <FormattedMessage id='locations.history' />
                            </Button>
                        </Link>
                    ) : null;
                }
            }
        ];
    }

    getLocations() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/business_locations`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((elem, key) => {
                    elem.key = key;
                });
                that.setState({
                    locations: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    fetchData(id, page = 1) {
        const { locationId, toDatetime } = this.state;
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = `${__API_URL__}/business_locations/movements/vehicles?toDatetime=${toDatetime.format(
            'YYYY-MM-DD'
        )}&page=${page}&pageSize=10`;
        if (id || locationId) url += `&businessLocationId=${id || locationId}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.list.map((elem, key) => {
                    elem.key = key;
                    elem.client = elem.movement.client;
                    elem.businessLocation = elem.movement.businessLocation;
                    elem.clientsVehicle = elem.movement.clientsVehicle;
                    elem.duration = elem.movement.duration;
                });
                that.setState({
                    paginationTotal: Number(data.stats.count),
                    dataSource: data.list
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    componentDidMount() {
        this.getLocations();
        if (this.props.location.state) {
            this.setState({
                locationId: this.props.location.state.locationId
            });
            this.fetchData(this.props.location.state.locationId);
        } else {
            this.fetchData();
        }
    }

    render() {
        const {
            loading,
            locations,
            locationId,
            dataSource,
            currentPage,
            paginationTotal,
            modalVisible,
            modalVehicleId,
            modalClientId,
            modalCurrentLocation,
            toDatetime
        } = this.state;

        const pagination = {
            total: paginationTotal,
            current: currentPage,
            onChange: page => {
                this.setState({
                    currentPage: page
                });
                this.fetchData(locationId, page);
            }
        };

        return (
            <Layout
                controls={
                    <div style={{ display: 'flex' }}>
                        <SingleDatePicker
                            date={toDatetime}
                            onDateChange={async date => {
                                await this.setState({
                                    toDatetime: date
                                });
                                this.fetchData();
                            }}
                            style={{ margin: '0 8px' }}
                        />
                        <Select
                            onChange={value => {
                                this.setState({
                                    locationId: value,
                                    currentPage: 1
                                });
                                this.fetchData(value);
                            }}
                            placeholder={this.props.intl.formatMessage({ id: 'location' })}
                            showSearch
                            style={{ width: 220 }}
                            value={locationId}
                        >
                            {locations.map(({ id, name }, key) => (
                                <Option key={key} value={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                }
                description={<div>{toDatetime.format('DD.MM.YY')}</div>}
                title={<FormattedMessage id='navigation.locations_vehicles' />}
            >
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={pagination}
                    size='small'
                />
                <VehicleLocationModal
                    clientId={modalClientId}
                    currentLocation={modalCurrentLocation}
                    hideModal={() => {
                        this.setState({
                            modalVisible: false,
                            modalVehicleId: undefined,
                            modalClientId: undefined,
                            modalCurrentLocation: undefined
                        });
                    }}
                    modalVisible={modalVisible}
                    onConfirm={() => this.fetchData()}
                    transferMode
                    vehicleId={modalVehicleId}
                />
            </Layout>
        );
    }
}
