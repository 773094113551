import { grants } from 'utils/grants';

export const detalizationOpt = [
    { label: 'short_day', value: 'DAY' },
    { label: 'short_week', value: 'WEEK' },
    { label: 'short_month', value: 'MONTH' },
    { label: 'short_year', value: 'YEAR' }
];

export const compareOpt = [
    { label: 'none', value: 'none' },
    { label: 'last_week', value: 'week' },
    { label: 'last_month', value: 'month' },
    { label: 'last_year', value: 'year' }
];

export const dateFormats = {
    day: 'YYYY.MM.DD',
    week: 'YYYY.MM.DD',
    month: 'YYYY.MM',
    year: 'YYYY'
};

const ORDERS = 'ORDERS';
const ANALYTICS = 'ANALYTICS';
const PROFITABILITY = 'PROFITABILITY';
const FOUNDS = 'FOUNDS';
const STORAGE = 'STORAGE';
const DEBTS = 'DEBTS';
const CRM = 'CRM';
const OTHER = 'OTHER';

export const listGroups = [
    { groupName: ORDERS, grant: grants.REPORTS_GRAPHICAL_REPORTS_SALES },
    { groupName: ANALYTICS, grant: grants.REPORTS_GRAPHICAL_REPORTS_SALES_ANALYTICS },
    { groupName: PROFITABILITY, grant: grants.REPORTS_GRAPHICAL_REPORTS_SALES_ANALYTICS },
    { groupName: FOUNDS, grant: grants.REPORTS_GRAPHICAL_REPORTS_FUNDS },
    { groupName: STORAGE, grant: grants.REPORTS_GRAPHICAL_REPORTS_WAREHOUSE },
    { groupName: DEBTS, grant: grants.REPORTS_GRAPHICAL_REPORTS_OUTSTANDING_DEBTS },
    { groupName: CRM, grant: grants.REPORTS_GRAPHICAL_REPORTS_OUTSTANDING_DEBTS }
];
export const allStationsGroups = [ORDERS, ANALYTICS];

const orderTypes = ['ORDERS_SALES', 'ORDERS_COUNT', 'ORDERS_SUCCESS_COUNT', 'ORDERS_AVERAGE_CHECK'];
const analyticsTypes = [
    'ORDERS_SALES_PER_POST',
    'ORDERS_MARGIN_PER_POST',
    'ORDERS_QUOTIENT_PARTS',
    'ORDERS_MARGIN_DETAILS',
    'ORDERS_MARGIN_SERVICES'
];
const profitabilityTypes = ['ORDERS_MARGIN', 'ORDERS_MARGIN_PERCENT', 'ORDERS_NET_PROFIT', 'ORDERS_NET_PROFIT_PERCENT'];
const foundTypes = ['FOUNDS_AMOUNT', 'FOUNDS_INCOME', 'FOUNDS_EXPENSE'];
const storageTypes = [
    'STORAGE_SUM',
    'STORAGE_AMOUNT',
    'STORAGE_ARRIVAL',
    'STORAGE_CONSUMPTION',
    'STORAGE_TURNOVERS',
    'STORAGE_INVETORY'
];
const debtTypes = [
    'DEBTS_CLIENT_SUM',
    'DEBTS_CLIENT_ORDER_SUM',
    'DEBTS_SUPPLIER_SUM',
    'DEBTS_SUPPLIER_STORAGE_SUM',
    'DEBTS_EMPLOYEE_SUM'
];
const crmTypes = [
    'CRM_CLIENT_INTERACTIONS',
    'CRM_INTERACTIONS',
    'CRM_CALLS',
    'CRM_ACCEPTED_CALLS',
    'CRM_QUOTIENT_ACCEPTED_CALLS',
    'CRM_NOTIFICATIONS'
];

export const typeToGroupMap = type => {
    if (orderTypes.includes(type)) return ORDERS;
    if (analyticsTypes.includes(type)) return ANALYTICS;
    if (profitabilityTypes.includes(type)) return PROFITABILITY;
    if (foundTypes.includes(type)) return FOUNDS;
    if (storageTypes.includes(type)) return STORAGE;
    if (debtTypes.includes(type)) return DEBTS;
    if (crmTypes.includes(type)) return CRM;

    return OTHER;
};
