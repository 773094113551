import { Col, Row } from 'antd';
import {
    AnalyticsSelector,
    CashboxSelector,
    ClientCounterpartyContainer,
    DateRangeSelector,
    OrderStatusSelector
} from 'modals/DebtZeroingModal/components';
import { fetchAnalytics, selectServiceOperationType } from 'modals/DebtZeroingModal/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    serviceOperationType: selectServiceOperationType(state)
});

const mapDispatchToProps = {
    fetchAnalytics
};

/**
 * This form is used to create ordinary cash order.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ReceivablesForm extends Component {
    constructor(props) {
        super(props);

        this.props.fetchAnalytics();
    }

    render() {
        const { serviceOperationType } = this.props;

        return (
            <React.Fragment>
                <div>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={12}>
                            <AnalyticsSelector type='INCOME' />
                        </Col>
                        <Col className={Styles.col} span={12}>
                            <AnalyticsSelector type='EXPENSE' />
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={12}>
                            <DateRangeSelector />
                        </Col>
                        <Col className={Styles.col} span={12}>
                            <CashboxSelector />
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={12}></Col>
                        <Col className={Styles.col} span={12}>
                            <OrderStatusSelector />
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.col} span={24}>
                            <ClientCounterpartyContainer />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
