import { Button, Drawer, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const FiltersDrawer = ({ filtersOpen, handleClose, handleFiltersSubmit, handleFiltersChange, filters, intl }) => {
    const [employees, setEmployees] = useState();
    const [stations, setStations] = useState([]);

    const fetchEmployees = async () => {
        const employees = await fetchAPI('GET', 'employees', { disabled: false }, undefined, {
            handleErrorInternally: true
        });
        const managers = employees.filter(
            ({ posts }) => posts.findIndex(({ postName }) => postName === 'MASTER_RECEIVER') !== -1
        );
        const mechanics = employees.filter(
            ({ posts }) => posts.findIndex(({ postName }) => postName === 'MECHANIC') !== -1
        );
        const partsMechanics = employees.filter(
            ({ posts }) => posts.findIndex(({ postName }) => postName === 'SPARE_PARTS_SPECIALIST') !== -1
        );
        setEmployees({ mechanics, partsMechanics, managers });
    };

    const fetchStations = async () => {
        const stations = await fetchAPI('GET', 'businesses/stations', undefined, undefined, {
            handleErrorInternally: true
        });

        setStations(stations);
    };

    // const debouncedSubmit = useCallback(
    //     debounce(filters => handleFiltersSubmit(filters), 1000),
    //     [handleFiltersSubmit]
    // );

    useEffect(() => {
        fetchEmployees();
        fetchStations();
    }, []);

    // const dates = {
    //     yesterday: dayjs().startOf('date').subtract(1, 'day'),
    //     today: dayjs().startOf('date'),
    //     tomorrow: dayjs().startOf('date').add(1, 'day'),
    //     afterTomorrow: dayjs().startOf('date').add(2, 'day'),
    //     twoAfterTomorrow: dayjs().startOf('date').add(3, 'day')
    // };

    return (
        <Drawer
            extra={
                <Button onClick={handleFiltersSubmit} type='primary'>
                    <FormattedMessage id='ok' />
                </Button>
            }
            onClose={handleClose}
            open={filtersOpen}
            title={<FormattedMessage id='filters' />}
            width={500}
        >
            <div className={Styles.container}>
                <div className={Styles.inputContainer} style={{ flexBasis: '100%' }}>
                    <p>
                        <FormattedMessage id='search' />
                    </p>
                    <Input.Search
                        allowClear
                        onChange={e => handleFiltersChange('query')(e.target.value)}
                        placeholder={intl.formatMessage({ id: 'orders.search.placeholder' })}
                        value={filters.query}
                    />
                </div>

                <div className={Styles.inputContainer}>
                    <p>
                        <FormattedMessage id='orders.post' />
                    </p>
                    <Select
                        allowClear
                        onChange={handleFiltersChange('stationNum')}
                        placeholder={intl.formatMessage({ id: 'orders.post' })}
                        value={filters.stationNum}
                    >
                        {stations.map(({ num, name }) => (
                            <Option key={num} value={num}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className={Styles.inputContainer}>
                    <p>
                        <FormattedMessage id='orders.responsible' />
                    </p>
                    <Select
                        allowClear
                        onChange={handleFiltersChange('manager')}
                        placeholder={intl.formatMessage({ id: 'orders.responsible' })}
                        value={filters.manager}
                    >
                        {employees &&
                            employees.managers.map(({ id, name, surname }) => (
                                <Option key={id} value={id}>
                                    {name} {surname}
                                </Option>
                            ))}
                    </Select>
                </div>
                <div className={Styles.inputContainer}>
                    <p>
                        <FormattedMessage id='order_form_table.master' />
                    </p>
                    <Select
                        allowClear
                        onChange={handleFiltersChange('employee')}
                        placeholder={intl.formatMessage({ id: 'order_form_table.master' })}
                        value={filters.employee}
                    >
                        {employees &&
                            employees.mechanics.map(({ id, name, surname }) => (
                                <Option key={id} value={id}>
                                    {name} {surname}
                                </Option>
                            ))}
                    </Select>
                </div>
                <div className={Styles.inputContainer}>
                    <p>
                        <FormattedMessage id='report-orders-form.appurtenancies_responsible' />
                    </p>
                    <Select
                        allowClear
                        onChange={handleFiltersChange('sparePart')}
                        placeholder={intl.formatMessage({ id: 'report-orders-form.appurtenancies_responsible' })}
                        value={filters.sparePart}
                    >
                        {employees &&
                            employees.partsMechanics.map(({ id, name, surname }) => (
                                <Option key={id} value={id}>
                                    {name} {surname}
                                </Option>
                            ))}
                    </Select>
                </div>
            </div>
        </Drawer>
    );
};

export default injectIntl(FiltersDrawer);
