import { Table } from 'antd';
import { selectStoreProduct } from 'core/storage/products';
import _ from 'lodash';
import {
    fetchStoreDocsOrders,
    selectStoreDocsOrders,
    selectStoreDocsOrdersFilters,
    selectStoreDocsOrdersStats,
    setStoreDocsOrdersFilters
} from 'pages/ProductPage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { v4 } from 'uuid';
import { columnsConfig } from './config';

const mapStateToProps = state => ({
    user: state.auth,
    product: selectStoreProduct(state),
    storeDocsOrders: selectStoreDocsOrders(state),
    filters: selectStoreDocsOrdersFilters(state),
    stats: selectStoreDocsOrdersStats(state)
});

const mapDispatchToProps = {
    fetchStoreDocsOrders,
    setStoreDocsOrdersFilters
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrdersTable extends React.Component {
    componentDidMount() {
        this.props.setStoreDocsOrdersFilters({ productId: this.props.productId || _.get(this.props, 'product.id') });

        this.props.fetchStoreDocsOrders();
    }

    render() {
        const { storeDocsOrders, filters, stats, intl, setStoreDocsOrdersFilters, user } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.count / 25) * 25,
            current: filters.page,
            hideOnSinglePage: true,
            onChange: page => {
                setStoreDocsOrdersFilters({ page });
            }
        };

        return (
            <div>
                <Table
                    bordered
                    columns={columnsConfig({ intl, user })}
                    dataSource={storeDocsOrders}
                    pagination={pagination}
                    rowKey={() => v4()}
                    size='small'
                />
            </div>
        );
    }
}
