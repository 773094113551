import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DragItemTypes, ROW_HEIGHT } from '../../dashboardConfig';

const orderTarget = {
    drop(props) {
        return {
            time: props.globalPosition,
            day: props.day,
            stationNum: props.stationNum,
            employeeId: props.employeeId
        };
    }
};

const collectTarget = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
});

@DropTarget(DragItemTypes.ORDER, orderTarget, collectTarget)
export default class DashboardOrderDropTarget extends Component {
    _getOrderDropTargetRef = dropTarget => {
        this.orderDropTargetRef = dropTarget;
        this.props.connectDropTarget(dropTarget);
    };

    render() {
        const { isOver, canDrop, label, options, mode } = this.props;

        return (
            <StyledDashboardOrderDropTarget
                ref={dropTarget => this._getOrderDropTargetRef(dropTarget)}
                overlayDrop={isOver && canDrop}
            >
                {label && (
                    <React.Fragment>
                        <StyledDashboardOrderDropTargetLabel>
                            {!label.clientSurname && !label.clientName
                                ? options.num || ''
                                : label.vehicleNumber || label.aggregateNumber}{' '}
                            {label.colorIcon}{' '}
                        </StyledDashboardOrderDropTargetLabel>
                        {mode != 'employees' && (
                            <StyledDashboardOrderDropTargetLabel>
                                {!label.clientSurname && !label.clientName
                                    ?  <FormattedMessage id='dashboard-container.without_client' /> 
                                    : `${label.clientSurname || ''} ${label.clientName || ''}`}
                            </StyledDashboardOrderDropTargetLabel>
                        )}
                        <StyledDashboardOrderDropTargetLabel>
                            {label.vehicleMakeName || label.aggregateName} {label.vehicleModelName}
                        </StyledDashboardOrderDropTargetLabel>
                        <StyledDashboardOrderDropTargetLabel>
                            {label.laborName || ''}
                        </StyledDashboardOrderDropTargetLabel>
                    </React.Fragment>
                )}
            </StyledDashboardOrderDropTarget>
        );
    }
}

const StyledDashboardOrderDropTarget = styled.div`
    height: ${ROW_HEIGHT}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: ${props => props.overlayDrop && 'var(--primary)'};
`;

const StyledDashboardOrderDropTargetLabel = styled.div`
    font-size: 11px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
