import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Tooltip } from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

@injectIntl
@connect(mapStateToProps)
class ArrowsWeekPicker extends Component {
    render() {
        const {
            onWeekChange,
            prevWeek,
            nextWeek,
            startDate,
            endDate,
            loading,
            intl: { formatMessage },
            isMobile
        } = this.props;

        if (loading) return null;

        return isMobile ? (
            <div className={Styles.mobileWeekPicker}>
                <Button
                    disabled={loading}
                    icon={<LeftOutlined />}
                    onClick={() => prevWeek()}
                    type='text'
                />
                <DatePicker
                    allowClear={false}
                    disabled={loading}
                    onChange={value => onWeekChange(value)}
                    picker='week'
                    placeholder={formatMessage({
                        id: 'select_week'
                    })}
                    value={startDate}
                />
                <Button
                    disabled={loading}
                    icon={<RightOutlined />}
                    onClick={() => nextWeek()}
                    type='text'
                />
            </div>
        ) : (
            <div className={Styles.weekPicker}>
                <Button
                    disabled={loading}
                    icon={<LeftOutlined />}
                    onClick={() => prevWeek()}
                    type='text'
                />
                <Tooltip title={`${startDate.format('DD/MM')} - ${endDate.format('DD/MM')}`}>
                    <DatePicker
                        allowClear={false}
                        disabled={loading}
                        format='YYYY-wo'
                        onChange={value => onWeekChange(value)}
                        picker='week'
                        placeholder={formatMessage({
                            id: 'select_week'
                        })}
                        value={startDate}
                    />
                </Tooltip>
                <Button
                    disabled={loading}
                    icon={<RightOutlined />}
                    onClick={() => nextWeek()}
                    type='text'
                />
            </div>
        );
    }
}

export default ArrowsWeekPicker;
