import { Modal } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './styles.less';

@injectIntl
class SourceSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sources: ['BUSINESS_PRODUCTS', 'TECDOC', 'PRICELIST', 'BUSINESS_CROSSES']
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            const sources = localStorage.getItem('_my.carbook.detail_sources');
            if (sources) {
                this.setState({
                    sources: JSON.parse(sources)
                });
            }
        }
    }

    handleSourceChange = sourceName => {
        const { sources } = this.state;

        if (sources.includes(sourceName)) {
            this.setState({
                sources: sources.filter(src => src !== sourceName)
            });
        } else {
            sources.push(sourceName);
            this.setState({});
        }
    };

    render() {
        const { visible, hideModal } = this.props;
        const { sources } = this.state;

        return (
            <Modal
                maskClosable={false}
                onCancel={() => {
                    hideModal();
                }}
                onOk={() => {
                    hideModal();
                    localStorage.setItem('_my.carbook.detail_sources', JSON.stringify(sources));
                }}
                title={<FormattedMessage id='order_form_table.source.placeholder' />}
                visible={visible}
            >
                <div className='checkboxWrap'>
                    <FormattedMessage id='order_form_table.spare_parts_directory' />
                    <Checkbox
                        checked={sources.includes('BUSINESS_PRODUCTS')}
                        onChange={() => this.handleSourceChange('BUSINESS_PRODUCTS')}
                    />
                </div>
                <div className='checkboxWrap'>
                    <FormattedMessage id='order_form_table.tecdoc_spare_parts' />
                    <Checkbox
                        checked={sources.includes('TECDOC')}
                        onChange={() => this.handleSourceChange('TECDOC')}
                    />
                </div>
                <div className='checkboxWrap'>
                    <FormattedMessage id='order_form_table.suppliers_prices' />
                    <Checkbox
                        checked={sources.includes('PRICELIST')}
                        onChange={() => this.handleSourceChange('PRICELIST')}
                    />
                </div>
                <div className='checkboxWrap'>
                    <FormattedMessage id='order_form_table.tecdoc_crosses' />
                    <Checkbox
                        /* checked={sources.includes('PRICELIST')} */
                        disabled
                        onChange={() => this.handleSourceChange('PRICELIST')}
                    />
                </div>
                <div className='checkboxWrap'>
                    <FormattedMessage id='order_form_table.own_crosses' />
                    <Checkbox
                        checked={sources.includes('BUSINESS_CROSSES')}
                        onChange={() => this.handleSourceChange('BUSINESS_CROSSES')}
                    />
                </div>
            </Modal>
        );
    }
}

export default SourceSelectModal;
