import { Input, Modal, Select } from 'antd';
import { selectModalProps } from 'core/modals/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import { createStoreGroup, deleteStoreGroup, updateStoreGroup } from 'core/storage/storeGroups';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    modalProps: selectModalProps(state),
    priceGroups: selectPriceGroups(state)
});

const mapDispatchToProps = {
    createStoreGroup,
    updateStoreGroup,
    deleteStoreGroup,
    fetchPriceGroups
};

const docTypes = {
    MRD: 'MRD',
    SAL: 'SAL',
    INC: 'INC',
    CRT: 'CRT',
    STP: 'STP',
    SRV: 'SRV',
    OUT: 'OUT',
    SRT: 'SRT',
    CST: 'CST',
    STM: 'STM',
    VRT: 'VRT',
    RD: 'RD',
    PRI: 'PRI',
    PRO: 'PRO',
    DSI: 'DSI',
    DSO: 'DSO'
};

const EntriesDirectoryModal = props => {
    const {
        open,
        hideModal,
        modalProps,
        intl: { formatMessage },
        editMode,
        fetchEntries,
        requisites,
        isMobile
    } = props;

    const [name, setName] = useState(undefined);
    const [docType, setDocType] = useState(undefined);
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState(undefined);
    const [counterpartType, setCounterpartyType] = useState(undefined);
    const [requisiteId, setRequisiteId] = useState(undefined);

    const addEntries = async () => {
        await fetchAPI(
            'POST',
            '/general_ledger/entries_directory',
            undefined,
            {
                docType,
                requisiteId: requisiteId || null,
                name,
                operationType,
                counterpartType: counterpartType || null,
                comment
            },
            { handleErrorInternally: true }
        );
    };

    const editEntries = async () => {
        await fetchAPI(
            'PUT',
            '/general_ledger/entries_directory',
            undefined,
            {
                id: editMode.id,
                docType,
                requisiteId: requisiteId || null,
                name,
                operationType,
                counterpartType: counterpartType || null,
                comment
            },
            { handleErrorInternally: true }
        );
    };

    useEffect(() => {
        if (editMode) {
            setName(editMode.name);
            setDocType(editMode.docType);
            setOperationType(editMode.operationType);
            setCounterpartyType(editMode.counterpartType);
            setComment(editMode.comment);
            setRequisiteId(editMode.requisiteId);
        }
    }, [editMode]);

    const onClose = async () => {
        setName(undefined);
        setDocType(undefined);
        setOperationType(undefined);
        setCounterpartyType(undefined);
        setComment(undefined);
        setDocType(undefined);

        setTimeout(() => fetchEntries(), 200);
        hideModal();
    };

    const onFinish = async () => {
        if (!editMode) {
            addEntries();
        } else {
            editEntries();
        }

        await onClose();
    };

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: !docType || !name || !operationType
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={!editMode ? <FormattedMessage id='entries_tab.add' /> : <FormattedMessage id='entries_tab.edit' />}
            width={isMobile ? '95%' : '30%'}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='description' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Input
                        allowClear
                        onChange={event => {
                            setName(event.target.value);
                        }}
                        placeholder={formatMessage({
                            id: 'description'
                        })}
                        value={name}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='directory_page.doc_type' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Select
                        allowClear
                        onChange={value => setDocType(value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'directory_page.doc_type'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={docType}
                    >
                        {Object.values(docTypes).map(elem => (
                            <Option key={elem} value={elem}>
                                {elem} - <FormattedMessage id={`storage_document.docType.${elem}`} />
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='storage.operation_type' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Select
                        allowClear
                        onChange={value => setOperationType(value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'storage.operation_type'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={operationType}
                    >
                        <Option key='DIRECT' value='DIRECT'>
                            <FormattedMessage id='entries_tab.DIRECT' />
                        </Option>
                        <Option key='STORNO' value='STORNO'>
                            <FormattedMessage id='entries_tab.STORNO' />
                        </Option>
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='storage_document.business_requisites' />
                    </div>
                    <Select
                        allowClear
                        onChange={value => setRequisiteId(value)}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={requisiteId}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={formatMessage({
                            id: 'storage_document.business_requisites'
                        })}
                    >
                        {requisites
                            .filter(({ enabled }) => enabled)
                            .map((elem, i) => {
                                return (
                                    <Option key={i} value={elem.id}>
                                        {elem.name}
                                        {elem.isTaxPayer && (
                                            <span
                                                style={{
                                                    marginLeft: 8,
                                                    color: 'var(--text2)'
                                                }}
                                            >
                                                (<FormattedMessage id='with_VAT' />)
                                            </span>
                                        )}
                                    </Option>
                                );
                            })}
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='storage_document.counterparty_type' />
                    </div>
                    <Select
                        allowClear
                        onChange={value => setCounterpartyType(value)}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={counterpartType}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                    >
                        <Option key='CUSTOMER' value='CUSTOMER'>
                            <FormattedMessage id='orders.client' />
                        </Option>
                        <Option key='EMPLOYEE' value='EMPLOYEE'>
                            <FormattedMessage id='employee' />
                        </Option>
                        <Option key='SUPPLIER' value='SUPPLIER'>
                            <FormattedMessage id='order_form_table.supplier' />
                        </Option>
                        <Option key='OTHER' value='OTHER'>
                            <FormattedMessage id='OTHER' />
                        </Option>
                    </Select>
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='comment' />
                    </div>
                    <Input.TextArea
                        allowClear
                        autoFocus
                        onChange={event => {
                            setComment(event.target.value);
                        }}
                        style={{ width: '100%', minHeight: '150px' }}
                        value={comment}
                    />
                </div>
            </div>
        </Modal>
    );
};

const EntriesDirectoryAddModal = injectIntl(connect(mapStateToProps, mapDispatchToProps)(EntriesDirectoryModal));

export default EntriesDirectoryAddModal;
