import { replace } from 'connected-react-router';
import { authenticate, updateUser } from 'core/auth/duck';
import { setErrorMessage } from 'core/errorMessage/duck';
import { setAuthFetchingState } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI, setCurrency, setOrderIcons, setRtlIcons } from 'utils';
import { genManagerScope } from 'utils/grants';
import { LOGIN, loginSuccess } from './duck';

export function* loginFormSaga() {
    while (true) {
        try {
            const {
                payload: { login, password }
            } = yield take(LOGIN);

            yield put(setAuthFetchingState(true));
            const data = yield call(
                fetchAPI,
                'POST',
                'login',
                null,
                { login: login.trim(), password: password.trim() },
                { handleErrorInternally: true }
            );

            const user = yield call(genManagerScope, data);

            yield put(authenticate(user));
            let countries = [];
            try {
                countries = yield call(fetchAPI, 'GET', 'countrysettings', null, null, {
                    handleErrorInternally: true,
                    allow403CheckForGET: true
                });
            } catch (e) {}

            yield setOrderIcons(user.ordersIcons);
            yield setRtlIcons(user.rtlIcons);
            const selected = countries.filter(({ businessId }) => businessId);
            if (_.get(selected, 'length')) {
                yield put(updateUser({ ...selected[selected.length - 1], ...user }));
                yield setCurrency(selected[selected.length - 1].curencyLocal);
            }

            // if (user.defaultRoute) {
                yield put(replace(`${user.defaultRoute}`));
            // }
            yield put(loginSuccess());
            // const sessionId = yield call(
            //     fetchAPI,
            //     'POST',
            //     'rest/v1/managers/signin',
            //     null,
            //     { identity: login.trim(), password: password.trim() },
            //     { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
            // );
            // yield setSessionId(sessionId);
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield put(setAuthFetchingState(false));
        }
    }
}

export function* saga() {
    yield all([call(loginFormSaga)]);
    // yield all([ takeLatest(LOGIN, loginFormSaga) ]);
}
