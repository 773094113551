exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".ant-select{display:list-item}.styles-m__detailNumberInput---1K2WY .ant-input-number .ant-input-number-input,.styles-m__detailNumberInputCheckbox---1-mG9 .ant-input-number .ant-input-number-input{text-align:right;padding-right:24px}.styles-m__radioComponent---15G7o{width:300px}@media only screen and (max-width:1290px){.styles-m__laborSelect---1XKru .ant-select-selection-item{max-width:180px}}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,YACE,iBACF,CAiBA,sKAGE,gBAAiB,CACjB,kBACF,CAGA,kCACE,WACF,CAxBA,0CACE,0DAEI,eACJ,CACF","file":"styles.m.css","sourcesContent":[":global(.ant-select) {\n  display: list-item;\n}\n\n\n@media only screen and (max-width: 1290px) {\n  .laborSelect \n  :global(.ant-select-selection-item) {\n      max-width: 180px;\n  }\n}\n\n.detailNumberInput\n:global(.ant-input-number)\n:global(.ant-input-number-input) {\n  text-align: right;\n  padding-right: 24px;\n}\n\n.detailNumberInputCheckbox\n:global(.ant-input-number)\n:global(.ant-input-number-input) {\n  text-align: right;\n  padding-right: 24px;\n}\n\n\n.radioComponent {\n  width: 300px;\n}\n\n"]}]);

// Exports
exports.locals = {
	"laborSelect": "styles-m__laborSelect---1XKru",
	"detailNumberInput": "styles-m__detailNumberInput---1K2WY",
	"detailNumberInputCheckbox": "styles-m__detailNumberInputCheckbox---1-mG9",
	"radioComponent": "styles-m__radioComponent---15G7o"
};