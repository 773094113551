import { CheckCircleOutlined, PartitionOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const DebtsPairComponent = ({
    remainSum,
    id,
    sign,
    pairSelectedIds,
    pairSign,
    onPair,
    onUnpair,
    orderId,
    cashOrderId,
    singleType,
    createPair,
    pairingIds,
    counterpartId,
    pairCounterpartId,
    disabled
}) => {
    const pairArray = pairSelectedIds || [];
    const pairingArray = pairingIds || [];

    return (
        <Popover
            content={<Button icon={<CheckCircleOutlined />} onClick={createPair} type='primary' />}
            placement='left'
            visible={pairArray.length > 1 && pairArray[pairArray.length - 1] === id}
        >
            <div style={{ display: 'flex' }}>
                <Tooltip title={<FormattedMessage id='debts_pair.pair' />}>
                    <Button
                        disabled={
                            !remainSum ||
                            (pairSign === sign && !pairArray.includes(id)) ||
                            (pairArray.length > 5 && !pairArray.includes(id)) ||
                            (pairCounterpartId && pairCounterpartId !== counterpartId) ||
                            (pairCounterpartId === null && counterpartId !== null) ||
                            disabled
                        }
                        icon={<PartitionOutlined style={{ transform: 'rotate(180deg)' }} />}
                        onClick={() => {
                            onPair &&
                                onPair({
                                    id,
                                    sign,
                                    cashOrderId,
                                    counterpartId
                                });
                        }}
                        size='large'
                        style={{ marginRight: 8 }}
                        type={pairArray.includes(id) ? 'primary' : 'default'}
                    />
                </Tooltip>
                <Popconfirm
                    disabled={!pairingArray.length}
                    onConfirm={() => {
                        onUnpair && onUnpair({ pairingIds, cashOrderSign: sign, cashOrderId: id });
                    }}
                    title={<FormattedMessage id='debts_pair.you_sure_question' />}
                >
                    <Tooltip title={<FormattedMessage id='debts_pair.unpair' />}>
                        <Button disabled={!pairingArray.length || disabled} icon={<PartitionOutlined />} size='large' />
                    </Tooltip>
                </Popconfirm>
            </div>
        </Popover>
    );
};

export default DebtsPairComponent;
