import { Form } from '@ant-design/compatible';
import { Radio } from 'antd';
import React from 'react';

const FormItem = Form.Item;

/**
 *
 * @property initialValue - will be used as default(auto cating to boolean)
 * @property defaultValue - will be used instead of initialValue if it was not provided
 */
export class DecoratedRadio extends React.PureComponent {
    render() {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            getFieldDecorator,
            disabled,
            rules,
            field,
            initialValue,
            defaultValue,
            value,
            children,
            onChange,
            buttonStyle,
            cnStyles,
            fieldValue
        } = this.props;

        const radio = getFieldDecorator(field, {
            valuePropName: 'checked',
            initialValue: defaultValue || Boolean(initialValue),
            rules
        })(
            // Bug fixing, filedValue must be ommited if it is undefine
            fieldValue ? (
                <Radio.Group
                    buttonStyle={buttonStyle}
                    cnStyles={cnStyles}
                    defaultValue={defaultValue || initialValue}
                    disabled={disabled}
                    onChange={onChange}
                    value={fieldValue}
                >
                    {children}
                </Radio.Group>
            ) : (
                <Radio.Group
                    buttonStyle={buttonStyle}
                    cnStyles={cnStyles}
                    defaultValue={defaultValue || initialValue}
                    disabled={disabled}
                    onChange={onChange}
                >
                    {children}
                </Radio.Group>
            )
        );

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                label={label}
                {...formItemLayout}
            >
                {radio}
            </FormItem>
        ) : (
            radio
        );
    }
}
