import { Collapse, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const mapDispatchToProps = {};

const ChangeMemoryFileModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        setDefaultPackageMB,
        setDefaultBussinessPackageMB,
        edit,
        packagesMB
    } = props;

    const [packageId, setPackageId] = useState();
    const [numOfBytes, setNumOfBytes] = useState(0);
    const [load, setLoading] = useState(false);

    const mbToBytes = mb => {
        return mb * 1024 * 1024;
    };

    useEffect(() => {
        if (edit) {
            setPackageId(edit.packageId);
            setNumOfBytes(edit.numOfBytes);
        }
    }, [edit]);

    const onClose = () => {
        hideModal();
        setPackageId(undefined);
        setNumOfBytes(undefined);
    };

    // useEffect(() => {
    //     if (open) {
    //         fetchAdditionalRoles();
    //     }
    // }, [open, fetchAdditionalRoles]);

    const onFinish = async () => {
        const formattedBites = mbToBytes(numOfBytes);
        const restPackages = packagesMB.filter(elem => elem.packageId !== packageId && elem.numOfBytes !== null);

        setLoading(true);
        if (setDefaultBussinessPackageMB) {
            setDefaultBussinessPackageMB(formattedBites);
        } else {
            setDefaultPackageMB([
                {
                    packageId,
                    numOfBytes: formattedBites
                },
                ...restPackages
            ]);
        }

        setLoading(false);

        await onClose();
    };

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{
                loading: load
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={<FormattedMessage id='packages.change_memory' />}
            width='20%'
        >
            <div className={Styles.divGroup}>
                <div>
                    <FormattedMessage id='packages.number_of_MB' />
                </div>

                <Input
                    className={Styles.selectStyle}
                    onChange={event => {
                        setNumOfBytes(event.target.value);
                    }}
                    placeholder={formatMessage({
                        id: 'packages.number_of_MB'
                    })}
                    style={{
                        width: '100%'
                    }}
                    value={numOfBytes}
                />
            </div>
        </Modal>
    );
};

export default injectIntl(ChangeMemoryFileModal);
