import { EnterOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { Catcher } from 'commons';
import { Like, Rating } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

export default class EmployeeFeedback extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: <FormattedMessage id='employee-feedback.feedback' />,
                dataIndex: 'datetime',
                width: '15%',
                render: (datetime, record) =>
                    isGrantAccessed(this.props.user, grants.DIRECTORIES_EMPLOYEE_CARD_REVIEWS, accesses.ROWO) ? (
                        <div>
                            <Link className={Styles.employeeName} to={`${book.feedback}/${record.id}`}>
                                <div>{dayjs(datetime).format('YYYY-MM-DD HH:mm')}</div>
                                <div className={Styles.link}>
                                    <FormattedMessage id='employee-feedback.go_to_feedback' />
                                    <EnterOutlined />
                                </div>
                            </Link>
                        </div>
                    ) : (
                        <div>
                            {' '}
                            <div>{dayjs(datetime).format('YYYY-MM-DD HH:mm')}</div>
                            <div className={Styles.link}>
                                <FormattedMessage id='employee-feedback.go_to_feedback' />
                                <EnterOutlined />
                            </div>
                        </div>
                    )
            },
            {
                title: <FormattedMessage id='employee-feedback.order' />,
                dataIndex: 'orderNum',
                width: '15%',
                render: (orderNum, orderId) =>
                    isGrantAccessed(this.props.user, grants.DIRECTORIES_EMPLOYEE_CARD_REVIEWS, accesses.ROWO) ? (
                        <Link to={`${book.orders}/${orderId}`}>
                            <div>{orderNum}</div>
                            <div className={Styles.link}>
                                <FormattedMessage id='employee-feedback.go_to_order' />
                                <EnterOutlined />
                            </div>
                        </Link>
                    ) : (
                        <div>
                            <div>{orderNum}</div>
                            <div className={Styles.link}>
                                <FormattedMessage id='employee-feedback.go_to_order' />
                                <EnterOutlined />
                            </div>
                        </div>
                    )
            },
            {
                title: <FormattedMessage id='employee-feedback.rating' />,
                dataIndex: 'rating',
                width: '20%',
                render: (rate, record) => (
                    <div>
                        <Rating rating={record.rating} />
                        <Like like={record.recommended} />
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee-feedback.comment' />,
                dataIndex: 'text',
                width: '50%',
                render: text => <div className={Styles.comment}>{text}</div>
            }
        ];
    }

    render() {
        const { reviews } = this.props;

        const avgRating = this._renderAvgRating();

        return (
            <Catcher>
                {avgRating}
                <Table
                    bordered
                    className={Styles.employeeFeedbackTable}
                    columns={this.columns}
                    dataSource={reviews}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    scroll={{ x: 1000 }}
                    size='small'
                />
            </Catcher>
        );
    }

    _renderAvgRating = () => {
        const { reviews } = this.props;
        const avgRating = _.meanBy(reviews, 'rating');

        return (
            <div className={Styles.avgRating}>
                <FormattedMessage id='employee-feedback.average_rating' />
                <Rating className={Styles.avgRatingStars} rating={avgRating} />
            </div>
        );
    };
}
