import { CloudFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';

@injectIntl
export default class AvailabilityIndicator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { indexArray, disabled } = this.props;

        let color = 'brown';
        let title = 'Постачальник не вказаний!';

        if (indexArray) {
            const in0 = indexArray[0];
            const in1 = indexArray[1];
            const in2 = indexArray[2];
            const inX = indexArray[3];

            title = (
                <div>
                    <p>{`${formatMessage({ id: 'availabilities-page.avail_0' })}: ${in0} ${formatMessage({
                        id: 'pc'
                    })}`}</p>
                    <p>{`${formatMessage({ id: 'availabilities-page.avail_1' })}: ${in1} ${formatMessage({
                        id: 'pc'
                    })}`}</p>
                    <p>{`${formatMessage({ id: 'availabilities-page.avail_2' })}: ${in2} ${formatMessage({
                        id: 'pc'
                    })}`}</p>
                    <p>{`${formatMessage({ id: 'availabilities-page.avail_x' })}: ${inX} ${formatMessage({
                        id: 'pc'
                    })}`}</p>
                </div>
            );
            if (in0 != 0) {
                color = 'var(--green)';
            } else if (in1 != 0) {
                color = 'yellow';
            } else if (in2 != 0) {
                color = 'orange';
            } else if (inX != 0) {
                color = 'red';
            }
        } else {
            color = 'grey';
        }

        return disabled ? (
            <Button
                icon={
                    <CloudFilled
                        disabled
                        style={{
                            color,
                            fontSize: 18
                        }}
                    />
                }
            />
        ) : (
            <Tooltip data-testid='tooltip ' title={title} zIndex={2001}>
                <Button
                    icon={
                        <CloudFilled
                            style={{
                                color,
                                fontSize: 18
                            }}
                        />
                    }
                />
            </Tooltip>
        );
    }
}
