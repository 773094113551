import { Button, Space, notification } from 'antd';
import { Layout } from 'commons';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    Area,
    Brush,
    CartesianGrid,
    ComposedChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { fetchAPI, numeralFormatter2 } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import ChartSettings from './components/ChartSettings';
import FiltersModal from './components/FiltersModal';
import TypeSelect from './components/TypeSelect';
import { dateFormats, typeToGroupMap } from './utils/constants';
import { combineCharts, normalizeChartQuery, normalizeCompareChartQuery } from './utils/normalizeChartQuery';

const GraphicReportsPage = ({ user, intl: { formatMessage }, ...props }) => {
    const [filtersList, setFiltersList] = useState([]);
    const [allStations, setAllStations] = useState(false);
    const [filters, setFilters] = useState({});
    const [selectedType, setSelectedType] = useState(localStorage.getItem('_my.carbook.report_graphic_type'));
    const [fetchChartAllowed, setFetchChartAllowed] = useState(false);
    const [chart, setChart] = useState([]);

    const currentStateDate = props.location && props.location.state ? props.location.state : undefined;
    const isToday = currentStateDate && currentStateDate.date && currentStateDate.date;

    const [chartSettings, setChartSettings] = useState({
        startDate: isToday ? dayjs().startOf('day') : dayjs().startOf('year'),
        endDate: isToday ? dayjs().startOf('day') : dayjs().endOf('year'),
        detalization: 'MONTH',
        stationIds: undefined
    });
    const [query, setQuery] = useState({ ...chartSettings });
    const [filtersModalOpen, setFiltersModalOpen] = useState(false);
    const [isFiltersForCompare, setIsFiltersForCompare] = useState(false);

    const [compareFilters, setCompareFilters] = useState({});
    const [compareChart, setCompareChart] = useState();
    const [comparePeriod, setComparePeriod] = useState('none');

    const handleTypeSelect = useCallback(value => {
        setFilters({});
        localStorage.setItem('_my.carbook.report_graphic_type', value);
        setSelectedType(value);
    }, []);

    const handleQueryChange = filterObj => {
        setQuery(prev => ({ ...prev, ...filterObj }));
    };

    const handleFiltersModalOpen = () => {
        setFiltersModalOpen(true);
    };

    const handleFiltersModalClose = () => {
        setFiltersModalOpen(false);
        setIsFiltersForCompare(false);
    };

    const handleFiltersModalOk = filters => {
        if (isFiltersForCompare) {
            setCompareFilters(filters);
        } else {
            setFilters(filters);
        }
        handleFiltersModalClose();
    };

    const fetchChart = useCallback(
        async query => {
            const chart = await fetchAPI('GET', '/charts', { ...query, filters }, null, {
                handleErrorInternally: true
            });
            setChart(chart);
        },
        [filters]
    );

    const fetchFilterList = useCallback(async () => {
        try {
            const filtersList = await fetchAPI('GET', '/charts/filters/list', null, null, {
                handleErrorInternally: true
            });
            const filterType = selectedType || 'ORDERS_SALES' || (filtersList[0] && filtersList[0].type);
            setFiltersList(filtersList.map(filter => ({ ...filter, grp: typeToGroupMap(filter.type) })));
            handleQueryChange({ type: filterType });
            handleTypeSelect(filterType);
            setFetchChartAllowed(true);
        } catch (e) {
            notification.error({ message: formatMessage({ id: 'error' }) });
        }
    }, [formatMessage, handleTypeSelect, selectedType]);

    const activeModalFilters = useMemo(() => {
        const foundFilter = filtersList.filter(({ type }) => type === selectedType);

        return foundFilter.length ? foundFilter[0].filters : [];
    }, [filtersList, selectedType]);

    useEffect(() => {
        fetchFilterList();
    }, []);

    useEffect(() => {
        setQuery(prev => ({ ...prev, ...chartSettings, type: selectedType }));
        setCompareFilters({});
        setComparePeriod('none');
    }, [chartSettings, selectedType]);

    useEffect(() => {
        fetchChartAllowed && fetchChart(normalizeChartQuery(query));
    }, [fetchChart, query, fetchChartAllowed]);

    useEffect(() => {
        setComparePeriod('month');
    }, []);

    useEffect(() => {
        const fetchCompareChart = async query => {
            const chart = await fetchAPI('GET', '/charts', { ...query, filters: compareFilters }, null, {
                handleErrorInternally: true
            });
            setCompareChart(chart);
        };
        if (comparePeriod !== 'none' || Object.values(compareFilters).some(val => val)) {
            fetchChartAllowed && fetchCompareChart(normalizeCompareChartQuery(query, comparePeriod));
        } else {
            setCompareChart(undefined);
        }
    }, [compareFilters, comparePeriod, fetchChartAllowed, query]);

    const isDisabledCRUD = !isGrantAccessed(user, grants.REPORTS_GRAPHICAL_REPORTS_FILTERS, accesses.ROWO);

    return (
        <Layout
            controls={
                <Space>
                    <Button
                        disabled={!chart || isDisabledCRUD}
                        onClick={handleFiltersModalOpen}
                        type={Object.values(filters).some(val => val) ? 'primary' : 'default'}
                    >
                        <FormattedMessage id='filters' />
                    </Button>
                    <TypeSelect
                        allStations={allStations}
                        disabled={isDisabledCRUD}
                        list={filtersList}
                        selectedType={selectedType}
                        setSelectedType={handleTypeSelect}
                        user={user}
                    />
                </Space>
            }
            title={<FormattedMessage id='navigation.graphic_reports' />}
        >
            <ChartSettings
                allStations={allStations}
                chartSettings={chartSettings}
                compareFilters={compareFilters}
                comparePeriod={comparePeriod}
                disabled={!chart || isDisabledCRUD}
                handleAllStationsChange={() => {
                    setAllStations(!allStations);
                    setChartSettings(prev => ({ ...prev, stationIds: undefined }));
                }}
                openFiltersModal={() => {
                    handleFiltersModalOpen();
                    setIsFiltersForCompare(true);
                }}
                setChartSettings={setChartSettings}
                setComparePeriod={period => {
                    setComparePeriod(period);
                    if (period !== 'none') setCompareFilters(filters);
                    else setCompareFilters({});
                }}
                user={user}
            />
            <ResponsiveContainer aspect={3} width='100%'>
                <ComposedChart data={combineCharts(chart, compareChart)} maxBarSize={120}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <Area
                        dataKey='comp'
                        fill='var(--secondary)'
                        legendType={compareChart ? 'line' : 'none'}
                        stroke='var(--secondary)'
                        type='monotone'
                    />
                    <Area dataKey='value' fill='var(--primary)' stroke='var(--primary)' type='monotone' />

                    {/* <Bar dataKey='value' fill='var(--secondary)' type='monotone' /> */}
                    {/* <Line
                        // connectNulls
                        dataKey='value'
                        stroke='var(--primary)'
                        strokeDasharray='5 5'
                        strokeWidth={2}
                        type='monotone'
                    /> */}
                    <Tooltip
                        formatter={value => [numeralFormatter2(value), formatMessage({ id: 'info_modal.value' })]}
                        labelFormatter={item =>
                            dayjs(item).format(dateFormats[chartSettings.detalization.toLowerCase()])
                        }
                    />
                    <XAxis
                        // angle={-30}
                        dataKey='date'
                        name='TEST'
                        tickFormatter={item =>
                            dayjs(item).format(dateFormats[chartSettings.detalization.toLowerCase()])
                        }
                    />
                    <YAxis tickFormatter={item => numeralFormatter2(item)} width={120} />
                    {chart.length && (
                        <Brush
                            dataKey='date'
                            height={30}
                            tickFormatter={item =>
                                dayjs(item).format(dateFormats[chartSettings.detalization.toLowerCase()])
                            }
                        />
                    )}
                    <Legend
                        formatter={value => {
                            if (value === 'comp') {
                                return formatMessage({ id: 'previous' });
                            }

                            return <FormattedMessage id={`graphic_reports.${selectedType}`} />;
                        }}
                        wrapperStyle={{ lineHeight: '40px' }}
                    />
                </ComposedChart>
            </ResponsiveContainer>
            <FiltersModal
                activeModalFilters={
                    allStations ? activeModalFilters.filter(fl => fl === 'clientId') : activeModalFilters
                }
                allStations={allStations}
                currentFilters={isFiltersForCompare ? compareFilters : filters}
                handleClose={handleFiltersModalClose}
                handleOk={handleFiltersModalOk}
                open={filtersModalOpen}
                setChartSettings={setChartSettings}
            />
        </Layout>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default connect(mapStateToProps)(injectIntl(GraphicReportsPage));
