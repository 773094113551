import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    brand_id: '20%',
    brand_name: '50%',
    edit: '15%',
    delete: '15%'
};

export function columnsConfig(props) {
    const { onEditBrandCross, onDeleteBrandCross } = props;

    const brandIdCol = {
        title: <FormattedMessage id='cross.id' />,
        width: defWidth.brand_id,
        key: v4(),
        align: 'right',
        render: val => {
            return <div>{val.businessSupplierBrandId || <FormattedMessage id='long_dash' />}</div>;
        }
    };

    const brandNameCol = {
        title: <FormattedMessage id='cross.name' />,
        width: defWidth.brand_name,
        align: 'center',
        render: val => {
            return <div>{val.name}</div>;
        }
    };

    const editCol = {
        width: defWidth.edit,
        align: 'center',
        render: val => {
            return (
                <div>
                    <EditOutlined
                        onClick={() => {
                            onEditBrandCross(val);
                        }}
                    />
                </div>
            );
        }
    };

    const deleteCol = {
        width: defWidth.delete,
        align: 'center',
        render: val => {
            return (
                <div>
                    <Popconfirm
                        onConfirm={() => {
                            onDeleteBrandCross(val.businessSupplierBrandId);
                        }}
                        title={<FormattedMessage id='add_order_form.delete_confirm' />}
                    >
                        <DeleteOutlined />
                    </Popconfirm>
                </div>
            );
        }
    };

    return [brandIdCol, brandNameCol, editCol, deleteCol];
}
