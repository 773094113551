exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__employeesTableIcon---3tY62{cursor:pointer;font-size:25px}.styles-m__employeeName---2QyjT:hover{color:var(--link)}.styles-m__jobTitle---2ehDz{color:var(--primary)}.styles-m__fired---1l3_v{color:var(--warning);font-weight:700}.styles-m__fireReason---FrJof{color:var(--text);font-weight:400;font-size:12px}.styles-m__working---23QE5{color:var(--secondary);font-weight:700}.styles-m__managerIcon---3KZIV,.styles-m__ratingStars---RIHYr{color:var(--secondary)}.styles-m__managerIcon---3KZIV{font-size:24px}.styles-m__managerIconDisabled---1j-JS{color:var(--cancel)}.styles-m__debt---3y61N{display:flex;justify-content:center;align-items:center;border:2px solid var(--lightGray);font-weight:700}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,sCACI,cAAe,CACf,cACJ,CAEA,sCACI,iBACJ,CAEA,4BACI,oBACJ,CAEA,yBACI,oBAAqB,CACrB,eACJ,CAEA,8BACI,iBAAkB,CAClB,eAAmB,CACnB,cACJ,CAEA,2BACI,sBAAuB,CACvB,eACJ,CAEA,8DAEI,sBACJ,CAEA,+BACI,cACJ,CAEA,uCACI,mBACJ,CAEA,wBACI,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,iCAAkC,CAClC,eACF","file":"styles.m.css","sourcesContent":[".employeesTableIcon {\n    cursor: pointer;\n    font-size: 25px;\n}\n\n.employeeName:hover {\n    color: var(--link);\n}\n\n.jobTitle {\n    color: var(--primary);\n}\n\n.fired {\n    color: var(--warning);\n    font-weight: bold;\n}\n\n.fireReason {\n    color: var(--text);\n    font-weight: normal;\n    font-size: 12px;\n}\n\n.working {\n    color: var(--secondary);\n    font-weight: bold;\n}\n\n.ratingStars,\n.managerIcon {\n    color: var(--secondary);\n}\n\n.managerIcon {\n    font-size: 24px;\n}\n\n.managerIconDisabled {\n    color: var(--cancel);\n}\n\n.debt {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 2px solid var(--lightGray);\n    font-weight: bold;\n  }\n"]}]);

// Exports
exports.locals = {
	"employeesTableIcon": "styles-m__employeesTableIcon---3tY62",
	"employeeName": "styles-m__employeeName---2QyjT",
	"jobTitle": "styles-m__jobTitle---2ehDz",
	"fired": "styles-m__fired---1l3_v",
	"fireReason": "styles-m__fireReason---FrJof",
	"working": "styles-m__working---23QE5",
	"ratingStars": "styles-m__ratingStars---RIHYr",
	"managerIcon": "styles-m__managerIcon---3KZIV",
	"managerIconDisabled": "styles-m__managerIconDisabled---1j-JS",
	"debt": "styles-m__debt---3y61N"
};