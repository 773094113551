
const { get } = require('lodash');

// filters: {pathToProperty, substring}[]
// const useFilterDataSource = (dataSource = [], initDiagnosticFilters = {}) => {
const useFilterDataSource = (dataSource = [], filters = {}) => {
    // const [filters, setFilters] = useState(initDiagnosticFilters);

    // const handleFiltersChange = filterObj => {
    //     setFilters({ ...filters, ...filterObj });
    // };
    // console.log(filters);

    const entries = Object.entries(filters);
    if (!entries.length) return dataSource;

    let filteredData = [...dataSource];

    entries.forEach(([key, value]) => {
        const conditions = [value].flat();
        const truthyConditions = conditions.filter(condition => condition && condition !== []).map(c => String(c));
        if (!truthyConditions.length) return;

        filteredData = filteredData.filter(row => {
            return truthyConditions
                .map(condition => String(get(row, key)).toLowerCase().includes(condition.toLowerCase()))
                .includes(true);
        });
    });

    // return { filteredData, filters, handleFiltersChange };
    return filteredData;
};

export default useFilterDataSource;
