exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__controls---wbJJT{display:flex}.styles-m__buttonGroup---3xgTn>button{margin:0 2px}.styles-m__filter---faLdQ{display:flex;justify-content:space-between;background:#fff;box-sizing:border-box;width:100%;padding:12px 0}.styles-m__buttonFilterGroup---1wymY{flex:1 1 60%;justify-content:flex-end;display:flex}.styles-m__review---1LL8-{flex:0 1 50%;justify-content:flex-end}.styles-m__cancelReasonSelect---y7Dff{flex:0 1 40%}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,4BACI,YACJ,CAGI,sCACI,YACJ,CAGJ,0BACI,YAAa,CACb,6BAA8B,CAC9B,eAAiB,CAEjB,qBAAsB,CACtB,UAAW,CACX,cACJ,CAGA,qCACI,YAAa,CACb,wBAAyB,CACzB,YACJ,CAEA,0BACI,YAAa,CACb,wBACJ,CAEA,sCACI,YACJ","file":"styles.m.css","sourcesContent":[".controls {\n    display: flex;\n}\n\n.buttonGroup {\n    & > button {\n        margin: 0 2px;\n    }\n}\n\n.filter {\n    display: flex;\n    justify-content: space-between;\n    background: white;\n    \n    box-sizing: border-box;\n    width: 100%;\n    padding: 12px 0px;\n}\n\n\n.buttonFilterGroup {\n    flex: 1 1 60%;\n    justify-content: flex-end;\n    display: flex;\n}\n\n.review {\n    flex: 0 1 50%;\n    justify-content: flex-end;\n}\n\n.cancelReasonSelect {\n    flex: 0 1 40%;\n}\n"]}]);

// Exports
exports.locals = {
	"controls": "styles-m__controls---wbJJT",
	"buttonGroup": "styles-m__buttonGroup---3xgTn",
	"filter": "styles-m__filter---faLdQ",
	"buttonFilterGroup": "styles-m__buttonFilterGroup---1wymY",
	"review": "styles-m__review---1LL8-",
	"cancelReasonSelect": "styles-m__cancelReasonSelect---y7Dff"
};