import {
    DeleteOutlined,
    DownSquareOutlined,
    EditOutlined,
    MenuOutlined,
    PlusOutlined,
    TransactionOutlined,
    UpSquareOutlined
} from '@ant-design/icons';
import { Button, Menu, Modal, Popconfirm, Tooltip } from 'antd';
import { Numeral } from 'commons';
import { Barcode, HamburgerMenu } from 'components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    addEdit: '5%',
    counter: '5%',
    name: 'auto',
    analytics: '25%',
    order: '8%',
    price: '7%',
    count: '8%',
    sum: '10%',
    delete: '5%'
};

const mask = '0,0.00';

export function columnsConfig(props) {
    const {
        deleteStoreDocService,
        handleStoreDocServices,
        onAddStoreDocService,
        onEditStoreDocService,
        selectedRowKeys,
        selectedRows,
        storeDocServices,
        showRecalcModal,
        mapperData,
        intl
    } = props;

    return [
        {
            title: () => {
                const actionsMenu = () => (
                    <Menu>
                        <Menu.Item disabled={!selectedRowKeys.length || true}>
                            <div
                                onClick={() => {
                                    Modal.confirm({
                                        title: intl.formatMessage({ id: 'add_order_form.delete_confirm' }),
                                        onOk: () => {
                                            deleteStoreDocService(selectedRowKeys);
                                        }
                                    });
                                }}
                            >
                                <DeleteOutlined
                                    className={Styles.actionMenuIcon}
                                    data-qa='btn_delete_store_doc_services_table'
                                />
                                {intl.formatMessage({ id: 'delete' })}
                            </div>
                        </Menu.Item>
                        <Menu.Item disabled={!selectedRowKeys.length} onClick={showRecalcModal}>
                            <div>
                                <TransactionOutlined
                                    className={Styles.actionMenuIcon}
                                    data-qa='btn_update_price_store_doc_services_table'
                                />
                                {intl.formatMessage({ id: 'update_price' })}
                            </div>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <div className={Styles.headerActions}>
                        <HamburgerMenu actionsMenu={actionsMenu}>
                            <Button data-qa='btn_menu_store_doc_services_table' icon={<MenuOutlined />} />
                        </HamburgerMenu>
                        <Tooltip title={<FormattedMessage id='add' />}>
                            <Button
                                data-qa='btn_add_store_doc_services_table'
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    onAddStoreDocService();
                                    // if(!accessForbidden) this.props.showModal();
                                }}
                                // disabled={ accessForbidden }
                                /*  style={{
                                    padding: '0px 8px',
                                    fontSize: 18
                                }} */
                            />
                        </Tooltip>
                        <Barcode button data-qa='btn_barcode_store_doc_services_table' disabled />
                    </div>
                );
            },
            width: '5%',
            align: 'center',
            dataIndex: 'orderNum',
            render: (elem, obj, index) => {
                const prewOrder = index - 1 >= 0 && storeDocServices[index - 1].order;
                const nextOrder = index + 1 < storeDocServices.length && storeDocServices[index + 1].order;

                return (
                    <div className={Styles.rowActions}>
                        <UpSquareOutlined
                            className={`${Styles.icon} ${!prewOrder && Styles.disabledIcon}`}
                            data-qa='btn_up_square_store_doc_services_table'
                            style={{ fontSize: 22 }}
                        />
                        <DownSquareOutlined
                            className={`${Styles.icon} ${!nextOrder && Styles.disabledIcon}`}
                            data-qa='btn_down_square_store_doc_services_table'
                            style={{ fontSize: 22 }}
                        />
                        <Tooltip title={<FormattedMessage id='edit' />}>
                            <Button
                                className={Styles.actionMenuIcon}
                                data-qa='btn_edit_store_doc_services_table'
                                disabled={props.disabled}
                                icon={<EditOutlined />} // accessForbidden check
                                onClick={() => {
                                    onEditStoreDocService(obj);
                                    // if(!accessForbidden) this.props.editProduct(elem.key);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id='delete' />}>
                            <Popconfirm
                                onConfirm={() => {
                                    deleteStoreDocService([obj.storeDocServiceId]);
                                    handleStoreDocServices();
                                    // this.props.deleteDocProduct(elem.key)
                                    // this.setState({
                                    //     update: true,
                                    // })
                                }}
                                placement='bottom'
                                title={<FormattedMessage id='add_order_form.delete_confirm' />}
                            >
                                <Button
                                    className={Styles.actionMenuIcon}
                                    data-qa='btn_delete_store_doc_services_table'
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </div>
                );
            }
        },
        {
            title: <FormattedMessage id='store_doc_service.name' />,
            align: 'left',
            dataIndex: 'name',
            key: 'nameCol'
        },

        {
            title: <FormattedMessage id='store_doc_service.analytics' />,
            align: 'left',
            dataIndex: 'analyticsName',
            key: 'analyticsNameCol',
            render: analyticsName => {
                return <div>{analyticsName || <FormattedMessage id='long_dash' />}</div>;
            }
        },

        {
            title: <FormattedMessage id='directories.orders' />,
            align: 'left',
            dataIndex: 'orderId',
            key: 'orderCol',
            render: orderId => {
                return <div>{orderId || <FormattedMessage id='long_dash' />}</div>;
            }
        },

        {
            title: <FormattedMessage id='order_form_table.purchasePrice' />,
            align: 'right',
            dataIndex: 'price',
            key: 'priceCol',
            render: (elem, obj) => <Numeral mask={mask}>{obj.price}</Numeral>
        },

        {
            title: <FormattedMessage id='storage_document.sell_price' />,
            align: 'right',
            dataIndex: 'price',
            key: 'priceCol',
            render: (elem, obj) => <Numeral mask={mask}>{obj.price}</Numeral>
        },

        {
            title: <FormattedMessage id='store_doc_service.count' />,
            align: 'right',
            dataIndex: 'count',
            key: 'countCol',
            render: (elem, obj) => <Numeral mask={mask}>{obj.count}</Numeral>
        },

        {
            title: <FormattedMessage id='services_table.units' />,
            align: 'right',
            dataIndex: 'measureUnit',
            key: 'measureUnit',
            render: measureUnit => {
                return <div>{measureUnit || <FormattedMessage id='long_dash' />}</div>;
            }
        },

        {
            title: <FormattedMessage id='store_doc_service.sum' />,
            align: 'right',
            dataIndex: 'sum',
            key: 'sumCol',
            render: (elem, obj) => <Numeral mask={mask}>{obj.sum}</Numeral>
        }
    ];
}
