// Core
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Image, Input, notification, Table } from 'antd';
import { Layout } from 'commons';
import { resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modal: state.modals.modal,
    user: state.auth
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class SMSSettingsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notificationSettings: [],
            allLinks: [],
            helperDrawerOpen: false
        };

        this.columns = [
            {
                title: <FormattedMessage id='sms_settings.type' />,
                key: 'type',
                render: row => {
                    return <FormattedMessage id={`sms_settings.type.${row.type}`} />;
                }
            },
            {
                title: <FormattedMessage id='sms_settings.set_default' />,
                key: 'set_default',
                render: row => {
                    return (
                        <LegacyIcon
                            disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_SMS, accesses.ROWO)}
                            onClick={async () => {
                                if (isGrantAccessed(this.props.user, grants.SETTINGS_SMS, accesses.ROWO)) {
                                    await fetchAPI('DELETE', 'notification_settings', undefined, {
                                        types: [row.type]
                                    });

                                    await notification.success({
                                        message: this.props.intl.formatMessage({
                                            id: 'sms_settings.saved'
                                        })
                                    });
                                    await this.fetchData();
                                }
                            }}
                            type='undo'
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='sms_settings.template' />,
                key: 'template',
                render: row => {
                    return (
                        <Input.TextArea
                            autoSize
                            disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_SMS, accesses.ROWO)}
                            onChange={({ target }) => {
                                row.template = target.value;
                                this.setState({});
                            }}
                            value={row.template}
                        />
                    );
                }
            }
        ];

        this.sendColumn = {
            title: <FormattedMessage id='sms_settings.send' />,
            key: 'send',
            render: row => {
                return (
                    <Checkbox
                        checked={row.send}
                        disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_SMS, accesses.ROWO)}
                        onChange={() => {
                            row.send = !row.send;
                            this.setState({});
                        }}
                    />
                );
            }
        };
    }

    fetchData = async () => {
        const notificationSettings = await fetchAPI('GET', 'notification_settings');
        const alphaName = await fetchAPI('GET', 'business', undefined, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            notificationSettings,
            alphaName: alphaName ? alphaName.alphaName : undefined
        });
    };

    saveChanges = async () => {
        await fetchAPI(
            'PUT',
            'notification_settings',
            undefined,
            this.state.notificationSettings.map(({ type, template, send }) => ({
                type,
                customTemplate: template,
                send
            }))
        );
        if (isGrantAccessed(this.props.user, grants.SETTINGS_SMS, accesses.WA)) {
            await fetchAPI(
                'PUT',
                'businesses',
                undefined,
                {
                    alphaName: this.state.alphaName || null
                },
                { handleErrorInternally: true }
            );
        }
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'sms_settings.saved' })
        });
        await this.fetchData();
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_sms-settings' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { user } = this.props;
        const { notificationSettings, helperDrawerOpen, allLinks } = this.state;

        const accessSaveButton = isGrantAccessed(user, grants.SETTINGS_SMS, accesses.ROWO);

        return (
            <Layout
                controls={
                    <React.Fragment>
                        <Button disabled={!accessSaveButton} onClick={this.saveChanges} type='primary'>
                            <FormattedMessage id='save' />
                        </Button>
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                this.setState({
                                    helperDrawerOpen: true
                                });
                                this.fetchHelperLinks();
                            }}
                            style={{
                                fontSize: 22,
                                marginLeft: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </React.Fragment>
                }
                description={<FormattedMessage id='sms_settings.description' />}
                title={<FormattedMessage id='navigation.sms_settings' />}
            >
                <Table
                    bordered
                    columns={
                        isGrantAccessed(user, grants.SETTINGS_SMS, accesses.WA)
                            ? [...this.columns, this.sendColumn]
                            : this.columns
                    }
                    dataSource={notificationSettings}
                    pagination={false}
                    rowKey='id'
                    size='small'
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
