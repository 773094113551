import { Button, Table } from 'antd';
import { ResponsiveView } from 'commons';
import {
    fetchSubscriptionPackages,
    selectSubscriptionPackages,
    setSubscriptionPackagesFilters,
    SUBSCRIPTION_TYPES
} from 'core/payments/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { BREAKPOINTS } from 'utils';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    packages: selectSubscriptionPackages(state)
});

const mapDispatchToProps = {
    fetchSubscriptionPackages,
    setSubscriptionPackagesFilters
};

@connect(mapStateToProps, mapDispatchToProps)
export class SubscriptionProTable extends Component {
    constructor(props) {
        super(props);

        this.columns = columnsConfig();
    }

    componentDidMount() {
        this.props.fetchSubscriptionPackages(SUBSCRIPTION_TYPES.ROLES_PACKAGE);
    }

    render() {
        const { packages } = this.props;

        const pagination = {
            pageSize: 10,
            size: 'large',
            total: Math.ceil(packages.stats.count / 10) * 10,
            hideOnSinglePage: true,
            current: packages.filters.page,
            onChange: page => {
                this.props.setSubscriptionPackagesFilters({ page });
                this.props.fetchSubscriptionPackages(SUBSCRIPTION_TYPES.ROLES_PACKAGES);
            }
        };

        return (
            <div className={Styles.tableWrapper}>
                <div className={Styles.tableHead}>
                    <ResponsiveView view={{ min: BREAKPOINTS.xxl.min, max: null }}>
                        <h3 className={Styles.tableHeadText}>
                            <FormattedMessage id='subscription-table.pro' />
                        </h3>
                    </ResponsiveView>
                </div>
                <Table
                    bordered
                    className={Styles.table}
                    columns={this.columns}
                    dataSource={_.get(packages, 'list', [])}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    // loading={cashboxesFetching}
                    pagination={pagination}
                    size='small'
                />
                <Link className={Styles.purchaseButton} to={book.subscriptionPackagesPage}>
                    <Button type='primary'>
                        <FormattedMessage id='subscription-table.buy_package' />
                    </Button>
                </Link>
            </div>
        );
    }
}
