import { Modal, Table } from 'antd';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import { recommendationsColumns } from 'forms/OrderForm/OrderFormTables/RecommendationsTable/columns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { fetchAPI } from 'utils';

const RecommendationsToServicesModal = ({
    modal,
    resetModal,
    orderId,
    vehicleId,
    reloadOrderForm,
    unitDefault,
    labors
}) => {
    const [recommendations, setRecommendations] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        const getRecommendations = async () => {
            if (vehicleId) {
                const recommendations = await fetchAPI(
                    'GET',
                    'recommendations/labors',
                    { vehicleId, onlyActive: true },
                    null,
                    {
                        handleErrorInternally: true
                    }
                );

                setRecommendations(
                    recommendations.list
                        .filter(({ recommendationStatus }) => recommendationStatus)
                        .map(rcm => ({
                            ...rcm,
                            recommendationLaborId: rcm.laborId,
                            recommendationName: rcm.laborName,
                            recommendationToOdometerValue: rcm.recommendationToOdometerValue || undefined,
                            recommendationComment: rcm.recommendationComment || undefined,
                            recommendationToDate: rcm.recommendationToDate ? dayjs(rcm.recommendationToDate) : undefined
                        }))
                );
            }
        };
        if (vehicleId && modal === MODALS.RECOM_TO_SERVICE) {
            getRecommendations();
        }
    }, [vehicleId, modal]);

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            onCancel={() => resetModal()}
            onOk={async () => {
                const { status } = await fetchAPI('GET', 'orders/status', { orderId }, null);
                if (status === 'success') {
                    window.location.reload();

                    return;
                }
                const fields = [
                    'recommendationId',
                    'recommendationName',
                    'recommendationStatus',
                    'recommendationComment',
                    'recommendationLaborId',
                    'recommendationToDate',
                    'recommendationToOdometerValue'
                ];

                const defaultUse = _.get(
                    unitDefault.filter(({ defaultUse }) => defaultUse),
                    '[0].id'
                );

                const payload = {
                    insertMode: true,
                    services: [
                        ...selectedRows.map(row => ({
                            serviceId: row.laborId,
                            comment: {
                                comment: row.recommendationComment,
                                positions: [],
                                problems: []
                            },
                            serviceName: row.laborName,
                            laborUnitId: row.laborUnitId || defaultUse
                        }))
                    ]
                };

                await fetchAPI(
                    'PUT',
                    'recommendations/labors',
                    null,
                    selectedRows.map(rcmd => ({
                        vehicleId,
                        orderId,
                        ..._.pick(rcmd, fields),
                        recommendationStatus: false
                    })),
                    { handleErrorInternally: true }
                );

                await fetchAPI('PUT', `orders/${orderId}`, undefined, payload, {
                    handleErrorInternally: true
                });
                resetModal();
                reloadOrderForm();
            }}
            open={modal === MODALS.RECOM_TO_SERVICE}
            width='100%'
        >
            <Table
                bordered
                columns={recommendationsColumns({}).slice(0, 6)}
                dataSource={recommendations || []}
                pagination={false}
                rowKey='recommendationId'
                rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                        setSelectedRows(selectedRows);
                    }
                }}
                size='small'
            />
        </Modal>
    );
};

export default RecommendationsToServicesModal;
