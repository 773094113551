import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    CREATE_SUPPLIER,
    DELETE_SUPPLIER,
    fetchSuppliers,
    fetchSuppliersSuccess,
    FETCH_SUPPLIERS
} from './duck';

export function* fetchSuppliersSaga() {
    while (true) {
        try {
            const { payload: query } = yield take(FETCH_SUPPLIERS);
            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'business_suppliers', {
                all: true,
                showHidden: true,
                query
            });

            yield put(fetchSuppliersSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* createSupplierSaga() {
    while (true) {
        try {
            const {
                payload: { supplier, func }
            } = yield take(CREATE_SUPPLIER);
            yield nprogress.start();

            if (supplier.id) {
                yield call(
                    fetchAPI,
                    'PUT',
                    `business_suppliers/${supplier.id}`,
                    null,
                    _.omit(supplier, 'id')
                );
            } else {
                const response = yield call(fetchAPI, 'POST', 'business_suppliers', null, supplier);

                _.get(response, 'id') && func(response.id);
            }

            yield put(fetchSuppliers());
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}
export function* deleteSupplierSaga() {
    while (true) {
        try {
            const { payload: id } = yield take(DELETE_SUPPLIER);
            yield nprogress.start();

            yield call(fetchAPI, 'DELETE', `business_suppliers/${id}`);

            yield put(fetchSuppliers());
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([call(fetchSuppliersSaga), call(createSupplierSaga), call(deleteSupplierSaga)]);
}
