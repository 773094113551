import { Input } from 'antd';
import { selectComment, selectMode, setComment } from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { TextArea } = Input;

const mapStateToProps = state => ({
    comment: selectComment(state),
    mode: selectMode(state),
    user: state.auth
});

const mapDispatchToProps = {
    setComment
};
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CommentInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: props.comment
        };

        this.textareaRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.comment !== prevProps.comment) {
            this.setState({
                comment: this.props.comment
            });
        }
    }

    handleInputChange(event) {
        const { value } = event.target;
        this.setState({
            comment: value
        });
        this.props.setComment(value);
    }

    componentDidMount() {
        this.textareaRef.current.focus();
    }

    render() {
        const {
            mode,
            editable,

            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <TextArea
                    ref={this.textareaRef}
                    data-qa='input_order_comments'
                    maxLength='255'
                    onChange={this.handleInputChange}
                    placeholder={formatMessage({
                        id: 'refactored_cash_order_modal.placeholder_comment'
                    })}
                    showCount
                    style={{
                        marginBottom: 8
                    }}
                    value={this.state.comment}
                />
            </div>
        );
    }
}
