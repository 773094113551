import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import { Catcher } from 'commons';
import {
    createRole,
    deleteRole,
    hideForms,
    setCreateRoleForm,
    setEditRoleId,
    updateRole
} from 'core/role/duck';
import { AddRoleForm, RoleForm } from 'forms';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { permissions } from 'utils';
import Styles from './styles.m.css';

const mapDispatchToProps = {
    setCreateRoleForm,
    setEditRoleId,
    createRole,
    updateRole,
    deleteRole,
    hideForms
};

const mapStateToProps = state => ({
    editRoleId: state.roles.editRoleId,
    createRoleForm: state.roles.createRoleForm,
    roles: state.roles.roles
});

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RoleContainer extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                title: <FormattedMessage id='role-container.index' />,
                dataIndex: 'index',
                width: 'auto',
                render: field => field + 1
            },
            {
                title: <FormattedMessage id='role-container.name' />,
                dataIndex: 'name',
                width: '20%'
            },
            {
                title: <FormattedMessage id='role-container.grants' />,
                dataIndex: 'grants',
                width: '30%',
                render: field =>
                    _.intersection(field, _.values(permissions))
                        .map(grant =>
                            this.props.intl.formatMessage({
                                id: `roles.${grant}`
                            })
                        )
                        .map(message => (
                            <React.Fragment>
                                {message} <br />
                            </React.Fragment>
                        ))
            },
            {
                title: <FormattedMessage id='role-container.grant_other' />,
                width: '12%',
                render: record =>
                    record.grantOther ? (
                        <CheckCircleOutlined className={Styles.editRoleIcon} />
                    ) : (
                        <CloseCircleOutlined className={Styles.editRoleIcon} />
                    )
            },
            {
                // title:  <FormattedMessage id='role-container.edit' />,
                width: '12%',
                render: record => (
                    <EditOutlined
                        className={Styles.editRoleIcon}
                        color='red'
                        onClick={() => this.props.setEditRoleId(record.roleId)}
                    />
                )
            },
            {
                // title:  <FormattedMessage id='role-container.delete' />,
                width: '12%',
                render: record => (
                    <DeleteOutlined
                        className={Styles.deleteRoleIcon}
                        onClick={() => this.props.deleteRole(record.roleId)}
                    />
                )
            }
        ];
    }

    render() {
        const { roles, createRoleForm, editRoleId, updateRole, createRole } = this.props;

        // TODO reselect
        const roleRows = roles.map((item, index) => ({
            ...item,
            index,
            key: item.roleId
        }));

        const initRole = editRoleId && _.find(roles, { roleId: editRoleId });

        return (
            <Catcher>
                <Button
                    className={Styles.addRoleButton}
                    onClick={() => {
                        this.props.setCreateRoleForm(true);
                    }}
                    type='primary'
                >
                    <FormattedMessage id='role-container.create' />
                </Button>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={() => {
                        this.props.hideForms();
                    }}
                    title={
                        editRoleId ? (
                            <FormattedMessage id='role-container.edit_title' />
                        ) : (
                            <FormattedMessage id='role-container.create_title' />
                        )
                    }
                    visible={Boolean(editRoleId || createRoleForm)}
                >
                    {(editRoleId && (
                        <RoleForm editRoleId={editRoleId} role={initRole} updateRole={updateRole} />
                    )) ||
                        (createRoleForm && <AddRoleForm createRole={createRole} />)}
                </Modal>
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={roleRows}
                    pagination={{
                        hideOnSinglePage: true,
                        size: 'large'
                    }}
                    size='small'
                />
            </Catcher>
        );
    }
}
