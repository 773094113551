import { Table } from 'antd';
import { recommendationsColumns } from 'forms/OrderForm/OrderFormTables/RecommendationsTable/columns';
import { pick } from 'lodash';
import {
    selectFetchingVehicleOrders,
    selectVehicleOrders,
    selectVehicleOrdersSort,
    selectVehicleOrdersStats,
    setPageOrders
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const mapStateToProps = state => ({
    user: state.auth,
    orders: selectVehicleOrders(state),
    stats: selectVehicleOrdersStats(state),
    sort: selectVehicleOrdersSort(state),
    fetchingVehicleOrders: selectFetchingVehicleOrders(state)
});

const mapDispatchToProps = {
    setPageOrders
};

/**
 * Table of vehicle orders
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AggregateRecomTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 25
        };

        const { user } = props;
    }

    updateRecommendation = async recommendation => {
        await fetchAPI(
            'PUT',
            'recommendations/labors',
            null,
            [
                {
                    aggregateId: this.props.id,
                    ...pick(recommendation, [
                        'recommendationId',
                        'recommendationName',
                        'recommendationStatus',
                        'recommendationComment',
                        'recommendationLaborId',
                        'recommendationToDate',
                        'recommendationToOdometerValue',
                        'orderId'
                    ]),
                    recommendationComment: recommendation.recommendationComment || undefined,
                    recommendationName: recommendation.laborName,
                    recommendationLaborId: recommendation.laborId
                }
            ],
            { handleErrorInternally: true }
        );
        this.props.fetchAllAggregatesRecomendations();
    };

    render() {
        const {
            intl: { formatMessage },
            recoms,
            statsRecom,
            disabled
        } = this.props;

        const { page, pageSize, stats, editableRcmId } = this.state;

        const pagination = {
            pageSize,
            page,
            size: 'large',
            total: Math.ceil(statsRecom / pageSize) * pageSize,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.props.fetchAllAggregatesRecomendations(page, pageSize);
            }
        };

        return (
            <div>
                <Table
                    bordered
                    columns={recommendationsColumns({
                        updateRecommendation: (rcm, orderId) => this.updateRecommendation(rcm, orderId),
                        onEdit: ({ recommendationId }) => this.setState({ editableRcmId: recommendationId }),
                        onEndEdit: () => {
                            this.setState({ editableRcmId: undefined });
                        },
                        editableRcmId,
                        disabled
                    })}
                    dataSource={recoms}
                    pagination={pagination}
                    scroll={{ y: '60vh' }}
                    size='small'
                />
            </div>
        );
    }
}
