import { Input, InputNumber, Modal, Select, notification } from 'antd';
import { fetchCashboxes } from 'core/cash/duck';
import { selectModalProps } from 'core/modals/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import { createStoreGroup, deleteStoreGroup, updateStoreGroup } from 'core/storage/storeGroups';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    modalProps: selectModalProps(state),
    priceGroups: selectPriceGroups(state),
    cashboxes: state.cash.cashboxes
});

const mapDispatchToProps = {
    fetchCashboxes,
    createStoreGroup,
    updateStoreGroup,
    deleteStoreGroup,
    fetchPriceGroups
};

const docTypes = {
    MRD: 'MRD',
    SAL: 'SAL',
    INC: 'INC',
    CRT: 'CRT',
    STP: 'STP',
    SRV: 'SRV',
    OUT: 'OUT',
    SRT: 'SRT',
    CST: 'CST',
    STM: 'STM',
    VRT: 'VRT',
    RD: 'RD',
    PRI: 'PRI',
    PRO: 'PRO',
    DSI: 'DSI',
    DSO: 'DSO'
};

const GroupSalCashOrderModal = props => {
    const {
        open,
        hideModal,
        modalProps,
        intl: { formatMessage },
        editMode,
        fetchSalariesList,
        cashboxes,
        fetchCashboxes,
        isMobile,
        selectedRows
    } = props;

    const [name, setName] = useState(undefined);
    const [docType, setDocType] = useState(undefined);
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState(undefined);
    const [counterpartType, setCounterpartyType] = useState(undefined);
    const [cashBoxId, setCashboxId] = useState();
    const [sum, setSum] = useState(undefined);
    const [mqInfo, setMqInfo] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const checkCashOrdersCreated = useCallback(async () => {
        const res = await fetchAPI(
            'GET',
            '/mq_processes',
            undefined,
            undefined,

            { handleErrorInternally: true }
        );

        setMqInfo(res && res.list.filter(elem => elem.state !== 'OK'));

        console.log(res, 'res');
    }, []);

    const createCashOrders = async () => {
        await fetchAPI(
            'POST',
            '/salary/cash_orders',
            undefined,
            [
                ...selectedRows.map(row => {
                    return {
                        type: 'EXPENSE',
                        salary: true,
                        salaryDocId: row.id,
                        salaryDocNum: row.document,
                        description: comment,
                        analyticsUniqueId: 31,
                        decrease: sum,
                        increase: null,
                        employeeId: row.employeeId,
                        cashBoxId,
                        externalId: null
                    };
                })
            ],
            { handleErrorInternally: true }
        );

        setLoading(true);

        await checkCashOrdersCreated();
    };

    const onClose = async () => {
        setTimeout(() => fetchSalariesList(), 200);
        await hideModal();
    };

    useEffect(() => {
        if (get(mqInfo, 'length') > 0) {
            const intervalId = setInterval(() => {
                checkCashOrdersCreated();
            }, 3000);

            return () => clearInterval(intervalId);
        }
        if (get(mqInfo, 'length') < 1) {
            setLoading(false);
            onClose();
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        }
    }, [checkCashOrdersCreated, mqInfo]);

    useEffect(() => {
        fetchCashboxes();
    }, [fetchCashboxes]);

    useEffect(() => {
        const sum =
            selectedRows &&
            selectedRows
                .map(data => {
                    const payout =
                        data.employeeIsNettoSalary && !data.isBrutto
                            ? data.nettoPayoutSum
                            : data.isBrutto || !data.employeeIsNettoSalary
                            ? data.bruttoPayoutSum
                            : 0;

                    const payoutData = payout + data.amountLeft;

                    return { ...data, payoutData }; // Attach the calculated payout
                })
                .filter(data => data.payoutData > 0)
                .reduce((accumulator, data) => accumulator + data.payoutData, 0);

        if (selectedRows && selectedRows.length) {
            setSum(Math.round(sum * 100) / 100);
        }
    }, [selectedRows]);

    const onFinish = () => {
        createCashOrders();
    };

    return (
        <Modal
            cancelButtonProps={{
                loading
            }}
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                loading
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={<FormattedMessage id='employee_salary_rules.create_cashorders_sal' />}
            width={isMobile ? '95%' : '30%'}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='employee_settings.cashbox' />
                    </div>
                    <Select
                        allowClear
                        onChange={value => setCashboxId(value)}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'employee_settings.cashbox'
                        })}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={cashBoxId}
                    >
                        {cashboxes.map(({ name, id }) => (
                            <Option key={id} value={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </div>

                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='storage_funds.analytics' />
                    </div>
                    <Select
                        allowClear
                        disabled
                        getPopupContainer={trigger => trigger.parentNode}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'storage_funds.analytics'
                        })}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: '100%' }}
                        value={31}
                    >
                        <Option key='salary' value={31}>
                            <FormattedMessage id='salaries' />
                        </Option>
                    </Select>
                </div>

                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='employee_salary_rules.payment' />
                    </div>
                    <InputNumber
                        allowClear
                        decimalSeparator=','
                        onChange={value => {
                            setSum(value);
                        }}
                        placeholder={formatMessage({
                            id: 'description'
                        })}
                        precision={2}
                        style={{ width: '100%' }}
                        value={sum}
                    />
                </div>

                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='comment' />
                    </div>
                    <Input.TextArea
                        allowClear
                        autoFocus
                        onChange={event => {
                            setComment(event.target.value);
                        }}
                        style={{ width: '100%', minHeight: '150px' }}
                        value={comment}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GroupSalCashOrderModal));
