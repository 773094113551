import { emitError, setBusinessPackageFetchingState } from 'core/ui/duck';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    CREATE_BUSINESS_PACKAGE,
    FETCH_BUSINESS_PACKAGES,
    SET_FILTERS,
    SET_PAGE,
    SET_SORT,
    UPDATE_BUSINESS_PACKAGE,
    fetchBusinessPackages,
    fetchBusinessPackagesError,
    fetchBusinessPackagesSuccess,
    hideForms
} from './duck';

export function* refetchBusinessPackagesSaga() {
    yield put(fetchBusinessPackages());
}

export function* fetchBusinessPackagesSaga() {
    while (true) {
        try {
            yield take(FETCH_BUSINESS_PACKAGES);
            const getParams = yield select(state => ({
                sort: {
                    ...state.businessPackage.sort,
                    page: state.businessPackage.page
                },
                filters: {
                    ...state.businessPackage.filters
                }
            }));

            yield put(setBusinessPackageFetchingState(true));

            const data = yield call(fetchAPI, 'GET', '/v2/managers/businesses/packages', getParams);

            yield put(fetchBusinessPackagesSuccess(data));
        } catch (error) {
            yield put(emitError(error));
            yield put(fetchBusinessPackagesError());
        } finally {
            yield put(setBusinessPackageFetchingState(false));
        }
    }
}

function* createBusinessPackageSaga({ payload: { businessId, packageId, fields } }) {
    const entity = {
        businessId,
        packageData: { packageId, ...fields }
    };

    yield call(fetchAPI, 'POST', '/v2/managers/packages/assign', null, entity);

    yield put(hideForms());
    yield put(fetchBusinessPackages());
}

function* updateBusinessPackageSaga({ payload: { businessPackageId, entity } }) {
    if (!entity.amount) {
        delete entity.amount;
    }
    if (!entity.comment) {
        delete entity.comment;
    }
    yield call(fetchAPI, 'PUT', `/managers/businesses/packages/${businessPackageId}`, null, entity);

    yield put(hideForms());
    yield put(fetchBusinessPackages());
}

export function* saga() {
    yield all([
        call(fetchBusinessPackagesSaga),
        takeEvery(CREATE_BUSINESS_PACKAGE, createBusinessPackageSaga),
        takeEvery(UPDATE_BUSINESS_PACKAGE, updateBusinessPackageSaga),
        takeEvery([SET_FILTERS, SET_SORT, SET_PAGE], refetchBusinessPackagesSaga)
    ]);
}
