import { emitError, setReportFetchingState } from 'core/ui/duck';
import { saveAs } from 'file-saver';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    FETCH_EXCEL_FILE_REPORT,
    FETCH_REPORT,
    fetchExcelFileReportSuccess,
    fetchReportSuccess,
    selectFilter
} from './duck';

export function* fetchReportSaga() {
    while (true) {
        try {
            yield take(FETCH_REPORT);
            yield put(setReportFetchingState(true));

            const filter = yield select(selectFilter);
            const filters = { ...filter };
            const data = yield call(fetchAPI, 'GET', '/report/client_debts', { filters }, null, {
                handleErrorInternally: true
            });
            yield put(fetchReportSuccess(data));
        } catch (e) {
        } finally {
            yield put(setReportFetchingState(false));
        }
    }
}

export function* fetchExcelFileReportSaga() {
    while (true) {
        try {
            yield take(FETCH_EXCEL_FILE_REPORT);
            yield nprogress.start();

            const filter = yield select(selectFilter);
            const filters = { ...filter };

            const response = yield call(fetchAPI, 'GET', '/report/client_debts_excel_file', { filters }, null, {
                rawResponse: true,
                handleErrorInternally: true
            });

            const reportFile = yield response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];

            yield saveAs(reportFile, fileName);
            yield put(fetchExcelFileReportSuccess());
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([call(fetchReportSaga), call(fetchExcelFileReportSaga)]);
}
