import { DatePicker, InputNumber, Modal } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
    resetFields,
    saveMileage,
    selectChangeMileageModalVisibility,
    selectMileage,
    selectMileageDateTime,
    setChangeMileageModalVisibility,
    setMileage,
    setMileageDateTime
} from 'pages/OnlineServiceBookPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, numeralFormatter, numeralParser } from 'utils';
import Styles from './styles.m.css';

const dateFormat = 'YYYY-MM-DD';

const mapStateToProps = state => ({
    visible: selectChangeMileageModalVisibility(state),
    mileageDateTime: selectMileageDateTime(state),
    mileage: selectMileage(state)
});

const mapDispatchToProps = {
    setMileage,
    setMileageDateTime,
    saveMileage,
    resetFields,
    setChangeMileageModalVisibility
};

/**
 * This modal is adapted copy of another modal for changing mileage value(ChangeVehicleMileageValue).
 * It is used to change vehicle mileage value.
 * @property { String | Number } modalProps.vehicleId - id of a vehicle to set mileage for
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ChangeMileageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            odometrDate: undefined,
            odometrNumber: undefined
        };
    }

    onSubmit = async () => {
        const { vehicleId, fetchVehicleOrders, token } = this.props;
        const { odometrDate, odometrNumber } = this.state;
        await fetchAPI(
            'POST',
            '/mileage/history',
            null,
            {
                vehicleId: this.props.vehicleId,
                mileage: odometrNumber || 1,
                date: dayjs(odometrDate).format('YYYY-MM-DD') || undefined,
                custom: true
            },
            {
                handleErrorInternally: true,
                headers: {
                    Authorization: token,
                    'content-type': 'application/json'
                }
            }
        );
        await fetchVehicleOrders(vehicleId);
        this.closeModal();
    };

    closeModal = async () => {
        const { setChangeMileageModalVisibility } = this.props;
        this.setState({
            odometrNumber: undefined,
            odometrDate: undefined
        });
        await setChangeMileageModalVisibility();
        resetFields();
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            this.setState({
                odometrNumber: _.get(this.props.vehicleData, 'currentMileage')
            });
        }
    }

    render() {
        const {
            visible,
            mileage,
            setMileage,
            setMileageDateTime,
            mileageDateTime,
            intl: { formatMessage }
        } = this.props;

        const { odometrDate, odometrNumber } = this.state;

        return (
            <div>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    onCancel={this.closeModal}
                    onOk={this.onSubmit}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='online_service_book_page.change_mileage' />
                        </div>
                    }
                    visible={visible}
                >
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                            <DatePicker
                                allowClear={false}
                                format={dateFormat}
                                inputReadOnly
                                onChange={odometrDate =>
                                    this.setState({
                                        odometrDate
                                    })
                                }
                                style={{
                                    marginRight: 8
                                }}
                                value={odometrDate}
                                width={100}
                            />
                        </div>

                        <div>
                            <div>
                                <FormattedMessage id='vehicle_page.odometr' />
                            </div>
                            <InputNumber
                                formatter={value => numeralFormatter(value)}
                                min={1}
                                onChange={odometrNumber => {
                                    this.setState({
                                        odometrNumber
                                    });
                                }}
                                parser={value => numeralParser(value)}
                                style={{
                                    width: 112
                                }}
                                value={Math.round(odometrNumber * 100) / 100}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
