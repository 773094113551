exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__repairMapIndicator---3Z8cO{display:flex;cursor:pointer}.styles-m__indicatorElement---bL4n3{padding:5px;font-size:12px;font-weight:700;border:1px solid #000;word-break:keep-all;height:28px;line-height:18px;width:30px;text-align:center;white-space:nowrap}.styles-m__BLANK---1_7Nr{background-color:#fff}.styles-m__RED---1YGLN{background-color:var(--db_not_complete)}.styles-m__YELLOW---1d7wr{background-color:var(--db_approve)}.styles-m__GREEN---3U69d{background-color:var(--db_progress)}@media screen and (max-width:1199px){.styles-m__indicatorElement---bL4n3{font-size:10px;font-weight:700}}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,sCACC,YAAa,CACb,cACD,CAEA,oCACC,WAAY,CACZ,cAAe,CACf,eAAgB,CAChB,qBAAuB,CACvB,mBAAoB,CACpB,WAAY,CACT,gBAAiB,CACpB,UAAW,CACX,iBAAkB,CAClB,kBACD,CASA,yBACC,qBACD,CAEA,uBACC,uCACD,CAEA,0BACC,kCACD,CAEA,yBACC,mCACD,CArBA,qCACC,oCACC,cAAe,CACf,eACD,CACD","file":"styles.m.css","sourcesContent":[".repairMapIndicator {\n\tdisplay: flex;\n\tcursor: pointer;\n}\n\n.indicatorElement {\n\tpadding: 5px;\n\tfont-size: 12px;\n\tfont-weight: 700;\n\tborder: 1px solid black;\n\tword-break: keep-all;\n\theight: 28px;\n    line-height: 18px;\n\twidth: 30px;\n\ttext-align: center;\n\twhite-space: nowrap;\n}\n\n@media screen and (max-width: 1199px) {\n\t.indicatorElement {\n\t\tfont-size: 10px;\n\t\tfont-weight: 700;\n\t}\n}\n\n.BLANK {\n\tbackground-color: white;\n}\n\n.RED {\n\tbackground-color: var(--db_not_complete);\n}\n\n.YELLOW {\n\tbackground-color: var(--db_approve);\n}\n\n.GREEN {\n\tbackground-color: var(--db_progress);\n}\n"]}]);

// Exports
exports.locals = {
	"repairMapIndicator": "styles-m__repairMapIndicator---3Z8cO",
	"indicatorElement": "styles-m__indicatorElement---bL4n3",
	"BLANK": "styles-m__BLANK---1_7Nr",
	"RED": "styles-m__RED---1YGLN",
	"YELLOW": "styles-m__YELLOW---1d7wr",
	"GREEN": "styles-m__GREEN---3U69d"
};