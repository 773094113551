import { mainModuleName } from 'pages/OnlineServiceBookPage/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'ordersTab';
const prefix = `cpb/onlineServiceBook/${moduleName}`; // Hardcode, "mainModuleName" must me here

export const FETCH_VEHICLE_ORDERS = `${prefix}/FETCH_VEHICLE_ORDERS`;
export const FETCH_VEHICLE_ORDERS_SUCCESS = `${prefix}/FETCH_VEHICLE_ORDERS_SUCCESS`;

export const SET_PAGE_ORDERS = `${prefix}/SET_PAGE_ORDERS`;
export const SET_FETCHING_VEHICLE_ORDERS = `${prefix}/SET_FETCHING_VEHICLE_ORDERS`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    fetchingVehicleOrders: false,
    orders: [], // Array of orders fetched for specific vehicle
    stats: {},
    sort: {
        page: 1,
        pageSize: 25,
        startDate: undefined,
        endDate: undefined,
        startSuccessDatetime: undefined,
        endSuccessDatetime: undefined,
        queryFilters: undefined,
        mileageFrom: undefined,
        mileageTo: undefined,
        statuses: undefined
    },
    filters: {
        query: undefined
    },
    vehicleData: {}
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_VEHICLE_ORDERS_SUCCESS:
            const { orders, stats: vehicleOrdersStats, vehicleData } = payload;

            return {
                ...state,
                orders,
                stats: vehicleOrdersStats,
                vehicleData
            };

        case SET_PAGE_ORDERS:
            const {
                page: pageOrders,
                pageSize,
                startDate,
                endDate,
                startSuccessDatetime,
                endSuccessDatetime,
                queryFilters,
                mileageFrom,
                mileageTo,
                statuses
            } = payload;

            return {
                ...state,
                sort: {
                    ...state.sort,
                    page: pageOrders,
                    pageSize,
                    startDate,
                    endDate,
                    startSuccessDatetime,
                    endSuccessDatetime,
                    queryFilters,
                    mileageFrom,
                    mileageTo,
                    statuses
                }
            };

        case SET_FETCHING_VEHICLE_ORDERS:
            return {
                ...state,
                fetchingVehicleOrders: payload
            };

        default:
            return state;
    }
}

/** ------------------------------------- Selectors ------------------------------------- * */

export const selectVehicleOrders = state => state[mainModuleName][moduleName].orders;
export const selectVehicleOrdersStats = state => state[mainModuleName][moduleName].stats;
export const selectVehicleOrdersSort = state => state[mainModuleName][moduleName].sort;
export const selectVehicleOrdersFilters = state => state[mainModuleName][moduleName].filters;
export const selectVehicleData = state => state[mainModuleName][moduleName].vehicleData;
export const selectFetchingVehicleOrders = state => state[mainModuleName][moduleName].fetchingVehicleOrders;

/* ------------------------------------- Action Creators ------------------------------------- */

/**
 * Fetches orders where vehicle was participating.
 * @param {*} params.vehicleId Vehicle to fetch data for
 */
export const fetchVehicleOrders = () => ({
    type: FETCH_VEHICLE_ORDERS
});

export const fetchVehicleOrdersSuccess = ({ orders, stats, vehicleData }) => ({
    type: FETCH_VEHICLE_ORDERS_SUCCESS,
    payload: { orders, stats, vehicleData }
});

export const setFetchingVehicleOrders = value => ({
    type: SET_FETCHING_VEHICLE_ORDERS,
    payload: value
});

/** Set filtering page, automatically fetches orders */
export const setPageOrders = ({
    page,
    pageSize,
    startDate,
    endDate,
    startSuccessDatetime,
    endSuccessDatetime,
    queryFilters,
    mileageFrom,
    mileageTo,
    statuses
}) => {
    return dispatch => {
        dispatch({
            type: SET_PAGE_ORDERS,
            payload: {
                page,
                pageSize,
                startDate,
                endDate,
                startSuccessDatetime,
                endSuccessDatetime,
                queryFilters,
                mileageFrom,
                mileageTo,
                statuses
            }
        });

        return dispatch(fetchVehicleOrders());
    };
};
