import {
    fetchVehicleRecommendations,
    selectClient,
    selectVehicle,
    selectVehicleRecommendations
} from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import VehicleRecommendationsTable from './VehicleRecommendationsTable';

const mapStateToProps = state => ({
    vehicle: selectVehicle(state),
    client: selectClient(state),
    recomendations: selectVehicleRecommendations(state)
});

const mapDispatchToProps = {
    fetchVehicleRecommendations
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class GeneralInfoTab extends Component {
    render() {
        const { disabled, user } = this.props;

        return <VehicleRecommendationsTable disabled={disabled} user={user} />;
    }
}
