exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__searchEmployeeTitle---16baq{padding:3px;margin-bottom:8px;font-size:16px;font-weight:700}.styles-m__actionMenuIcon---2_enO{padding:0 4px 0 0;font-size:18px;vertical-align:sub;display:inline-block}.styles-m__divGroup---1N3Bj{margin-bottom:6px}.styles-m__rules---ntQJj{color:red;margin-left:3px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,uCACC,WAAY,CACT,iBAAkB,CAClB,cAAe,CACf,eACJ,CAEA,kCACC,iBAAkB,CAClB,cAAe,CACf,kBAAmB,CACnB,oBACC,CAGA,4BACE,iBACJ,CAEA,yBACI,SAAU,CACV,eACJ","file":"styles.m.css","sourcesContent":[".searchEmployeeTitle {\n\tpadding: 3px;\n    margin-bottom: 8px;\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.actionMenuIcon {\n\tpadding: 0 4px 0 0;\n\tfont-size: 18px;\n\tvertical-align: sub;\n\tdisplay: inline-block;\n  }\n\n\n  .divGroup {\n    margin-bottom: 6px;\n}\n\n.rules {\n    color: red;\n    margin-left: 3px;\n}\n\n"]}]);

// Exports
exports.locals = {
	"searchEmployeeTitle": "styles-m__searchEmployeeTitle---16baq",
	"actionMenuIcon": "styles-m__actionMenuIcon---2_enO",
	"divGroup": "styles-m__divGroup---1N3Bj",
	"rules": "styles-m__rules---ntQJj"
};