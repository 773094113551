import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Row, Select, notification } from 'antd';
import dayjs from 'dayjs';
import { DecoratedCheckbox, DecoratedDatePicker, DecoratedInputNumber, DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

const { Option } = Select;

/* eslint-disable complexity */
export function columnsConfig(
    formatMessage,
    form,
    initialSettingSalaries,
    createSalary,
    updateSalary,
    deleteSalary,
    user,
    isGrantedAccess
) {
    const { getFieldDecorator } = form;
    const getDateTitle = (key, title) => {
        const date = _.get(initialSettingSalaries, [key, title]);

        return date ? dayjs(date) : date;
    };

    const period = {
        title: (
            <p>
                <FormattedMessage id='setting-salary.period' />
                <span style={{ color: 'red' }}> *</span>
            </p>
        ),
        dataIndex: 'period',
        width: '15%',
        render: (text, record) => (
            <DecoratedSelect
                disabled={isGrantedAccess}
                field={`settingSalaries[${record.key}][period]`}
                getFieldDecorator={getFieldDecorator}
                initialValue={_.get(initialSettingSalaries, [record.key, 'period'])}
                placeholder={formatMessage({ id: 'setting-salary.period' })}
            >
                {['DAY', 'WEEK', 'MONTH'].map((period, index) => (
                    <Option key={`${period}-${index}`} value={period}>
                        <FormattedMessage id={period} />
                    </Option>
                ))}
            </DecoratedSelect>
        )
    };

    const startDate = {
        title: <FormattedMessage id='setting-salary.start_date' />,
        dataIndex: 'startDate',
        width: '15%',
        render: (text, record) => (
            <DecoratedDatePicker
                disabled={isGrantedAccess}
                field={`settingSalaries[${record.key}][startDate]`}
                // initialValue={ dayjs(startDate) }
                fields={{}}
                format='YYYY-MM-DD'
                formatMessage={formatMessage}
                getFieldDecorator={getFieldDecorator}
                initialValue={getDateTitle(record.key, 'startDate')}
            />
        )
    };

    const endDate = {
        title: <FormattedMessage id='setting-salary.end_date' />,
        dataIndex: 'endDate',
        width: '15%',
        render: (text, record) => {
            return (
                <DecoratedDatePicker
                    disabled={isGrantedAccess}
                    field={`settingSalaries[${record.key}][endDate]`}
                    fields={{}}
                    format='YYYY-MM-DD'
                    // getCalendarContainer={ trigger => trigger.parentNode }
                    formatMessage={formatMessage}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={getDateTitle(record.key, 'endDate')}
                />
            );
        }
    };

    const ratePerPeriod = {
        title: <FormattedMessage id='setting-salary.ratePerPeriod' />,
        dataIndex: 'ratePerPeriod',
        width: '10%',
        render: (text, record) => (
            <DecoratedInputNumber
                disabled={isGrantedAccess}
                field={`settingSalaries[${record.key}][ratePerPeriod]`}
                fields={{}}
                getFieldDecorator={getFieldDecorator}
                initialValue={_.get(initialSettingSalaries, [record.key, 'ratePerPeriod'])}
                min={0}
                // onKeyPress={ this.handleChangeNew.bind(this, null) }
                // defaultValue={ text }
                placeholder={formatMessage({
                    id: 'setting-salary.ratePerPeriod'
                })}
            />
        )
    };

    const percentFrom = {
        title: <FormattedMessage id='setting-salary.percentFrom' />,
        dataIndex: 'percentFrom',
        width: '20%',
        render: (text, record) => (
            <DecoratedSelect
                disabled={isGrantedAccess}
                field={`settingSalaries[${record.key}][percentFrom]`}
                getFieldDecorator={getFieldDecorator}
                initialValue={_.get(initialSettingSalaries, [record.key, 'percentFrom'])}
                // onChange={ this.handleChangeNew.bind(this, 'percentFrom') }
                // value={ record.percentFrom }
                placeholder={formatMessage({
                    id: 'setting-salary.percentFrom'
                })}
            >
                {/* eslint-disable array-element-newline */}
                {['ORDER', 'ORDER_HOURS', 'ORDER_SERVICES', 'SPARE_PARTS', 'SPARE_PARTS_PROFIT', 'ORDER_PROFIT'].map(
                    percent => (
                        <Option key={percent} value={percent}>
                            <FormattedMessage id={`${percent}`} />
                        </Option>
                    )
                )}
                {/* eslint-enable array-element-newline */}
            </DecoratedSelect>
        )
    };

    const percent = {
        title: <FormattedMessage id='setting-salary.percent' />,
        dataIndex: 'percent',
        width: '10%',
        render: (data, record) => (
            <DecoratedInputNumber
                disabled={isGrantedAccess}
                field={`settingSalaries[${record.key}][percent]`}
                formatter={value => `${value}%`}
                // onKeyPress={ this.handleChangeNew.bind(this, null) }
                getFieldDecorator={getFieldDecorator}
                initialValue={_.get(initialSettingSalaries, [record.key, 'percent'])}
                min={0}
                parser={value => value.replace('%', '')}
                placeholder={formatMessage({ id: 'setting-salary.percent' })}
            />
        )
    };

    const considerDiscount = {
        title: <FormattedMessage id='setting-salary.considerDiscount' />,
        dataIndex: 'considerDiscount',
        width: '5%',
        render: (text, record) => (
            <Row align='center' type='flex'>
                <DecoratedCheckbox
                    disabled={isGrantedAccess}
                    field={`settingSalaries[${record.key}][considerDiscount]`}
                    fields={{}}
                    getFieldDecorator={getFieldDecorator}
                    initialValue={_.get(initialSettingSalaries, [record.key, 'considerDiscount'])}
                />
            </Row>
        )
    };

    const actions = {
        title: '',
        key: 'delete',
        width: 'auto',
        render: (text, record) => {
            return (
                !isGrantedAccess && (
                    <div>
                        <SaveOutlined
                            className={Styles.saveSalary}
                            disabled={isGrantedAccess}
                            onClick={() => {
                                const entity = _.get(form.getFieldValue('settingSalaries'), record.key);
                                const salaryId = _.get(initialSettingSalaries, [record.key, 'id']);

                                if (!entity.period) {
                                    notification.error({
                                        message: formatMessage({ id: 'choose_period' })
                                    });

                                    return;
                                }

                                if (!salaryId) {
                                    createSalary(entity);
                                } else {
                                    updateSalary(salaryId, entity);
                                }
                            }}
                        />
                        {_.get(initialSettingSalaries, [record.key, 'id']) && (
                            <DeleteOutlined
                                className={Styles.saveSalary}
                                disabled={isGrantedAccess}
                                onClick={() => {
                                    const salaryId = _.get(initialSettingSalaries, [record.key, 'id']);

                                    if (salaryId) {
                                        deleteSalary(salaryId);
                                    }
                                }}
                            />
                        )}
                    </div>
                )
            );
        }
    };

    /* eslint-disable array-element-newline */
    return [period, startDate, endDate, ratePerPeriod, percentFrom, percent, considerDiscount, actions];
    /* eslint-enable array-element-newline */
}
