import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Numeral } from 'commons';
import _ from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectMode,
    selectSelectedClient,
    setSelectedClientId
} from 'modals/RefactoredCashOrderModal/redux/duck';
import {
    selectFetchingSelectedOrder,
    selectOrders,
    selectSelectedOrder,
    selectSelectedOrderId,
    setOrdersFilters,
    setSelectedOrderId
} from 'modals/RefactoredCashOrderModal/redux/orders/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    selectedOrderId: selectSelectedOrderId(state),
    selectedOrder: selectSelectedOrder(state),
    fetchingSelectedOrder: selectFetchingSelectedOrder(state),
    orders: selectOrders(state),
    selectedClient: selectSelectedClient(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    setSelectedOrderId,
    setSelectedClientId,
    setOrdersFilters
};

/**
 * If order is selected this component generates block with its basic
 * information and ability to remove currently selected order(make no one selected);
 *
 * Note: this component uses object returned when order is fetched by id,
 * those objects can be different from others request responses that work with orders
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrderVisualizer extends React.Component {
    render() {
        const {
            mode,

            selectedClient,
            fetchingSelectedOrder,
            selectedOrder,
            setSelectedOrderId,
            isMobile,
            editable
        } = this.props;

        if (!selectedOrder) return 'No selected order';
        if (fetchingSelectedOrder) return <LoadingOutlined />;

        const order = _.get(selectedOrder, 'order');
        const vehicle = _.get(selectedOrder, 'vehicle');
        const phones = _.get(selectedClient, 'phones');
        const disabled = Boolean(mode != MODES.ADD);

        const tax = _.get(order, 'isTaxPayer') ? _.get(order, 'tax', 0) : 0;

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col span={isMobile ? 24 : 4}>{`${_.get(order, 'num')}`}</Col>
                    <Col className={Styles.col} span={isMobile ? 24 : 8}>
                        <Link to={`${book.client}/${selectedClient.clientId}`}>
                            <span>{`${selectedClient.name || ''} ${
                                selectedClient.middleName || ''
                            } ${selectedClient.surname || ''}`}</span>
                        </Link>
                        {phones &&
                            _.map(
                                _.map(phones, (phone, index) => {
                                    return (
                                        <div key={index}>
                                            <a className={Styles.phoneNumber} href={`tel:${phone}`}>
                                                {phone}
                                            </a>
                                        </div>
                                    );
                                })
                            )}
                    </Col>

                    <Col className={Styles.col} span={isMobile ? 24 : 6}>
                        <div className={Styles.vehicle}>
                            <Link to={`${book.vehicle}/${_.get(vehicle, 'vehicleId')}`}>
                                {`${_.get(vehicle, 'make', '')} ${_.get(
                                    vehicle,
                                    'model',
                                    ''
                                )} ${_.get(vehicle, 'year', '')}`}
                            </Link>
                        </div>
                    </Col>

                    <Col span={isMobile ? 10 : 2}>
                        <Numeral mask='0,0.00' nullText='0'>
                            {Number(_.get(order, 'totalSumWithTax', 0)) || 0}
                        </Numeral>
                    </Col>

                    <Col span={isMobile ? 10 : 2}>
                        <Numeral mask='0,0.00' nullText='0'>
                            {Number(_.get(order, 'remainingSum', 0) + tax) || 0}
                        </Numeral>
                    </Col>

                    <Col className={Styles.actionsCol} span={isMobile ? 4 : 2}>
                        {!disabled || editable ? (
                            <CloseOutlined
                                className={Styles.closeIcon}
                                // Remove currently selected order
                                onClick={() => {
                                    setSelectedOrderId(undefined);
                                }}
                            />
                        ) : null}
                    </Col>
                </Row>
            </div>
        );
    }
}
