import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { counterpartValues, documentTypeValues } from 'modals/EmployeeTaskModal/constants';
import { getEmployeeTaskEntityByType } from 'modals/EmployeeTaskModal/utils';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    createEmployeeTaskSuccess,
    CREATE_EMPLOYEE_TASK,
    fetchClientsSuccess,
    fetchDocumentsSuccess,
    fetchEmployeesSuccess,
    fetchManagersSuccess,
    fetchSuppliersSuccess,
    FETCH_CLIENTS,
    FETCH_DOCUMENTS,
    FETCH_EMPLOYEES,
    FETCH_MANAGERS,
    FETCH_SUPPLIERS,
    selectDocumentFilters,
    selectFields,
    setDocumentFetching
} from './duck';

export function* fetchEmployeesSaga() {
    while (true) {
        try {
            yield take(FETCH_EMPLOYEES);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'employees', { disabled: false });
            // const data = yield call(fetchAPI, 'GET', `managers/search`);

            yield put(fetchEmployeesSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchManagersSaga() {
    while (true) {
        try {
            yield take(FETCH_MANAGERS);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'managers/search', { notFired: true });

            yield put(fetchManagersSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchClientsSaga() {
    while (true) {
        try {
            yield take(FETCH_CLIENTS);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'clients');

            yield put(fetchClientsSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchSuppliersSaga() {
    while (true) {
        try {
            yield take(FETCH_SUPPLIERS);

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'business_suppliers', { all: true });

            yield put(fetchSuppliersSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* fetchDocumentsSaga() {
    while (true) {
        try {
            yield take(FETCH_DOCUMENTS);

            yield put(setDocumentFetching(true));

            yield nprogress.start();

            const filters = yield select(selectDocumentFilters);
            const employeeTaskDataEntity = yield select(selectFields);

            let data = null;

            switch (employeeTaskDataEntity.documentType) {
                case documentTypeValues.ORDER:
                    const response = yield call(fetchAPI, 'GET', 'orders', {
                        ...filters,
                        client: employeeTaskDataEntity.counterpartClientId
                    });

                    // Get clientId and send as client, page, query

                    const stats = { count: response.stats.countOrders };

                    data = { documents: response.orders, stats };
                    break;
                case documentTypeValues.STORE_DOC:
                    if (employeeTaskDataEntity.counterpartyType === counterpartValues.CLIENT) {
                        const response = yield call(fetchAPI, 'GET', 'store_docs', {
                            ...filters,
                            counterpartClientId: employeeTaskDataEntity.counterpartClientId
                        });

                        data = { documents: response.list, stats: response.stats };
                    } else if (
                        employeeTaskDataEntity.counterpartyType ===
                        counterpartValues.BUSINESS_SUPPLIER
                    ) {
                        const response = yield call(fetchAPI, 'GET', 'store_docs', {
                            ...filters,
                            counterpartBusinessSupplierId:
                                employeeTaskDataEntity.counterpartBusinessSupplierId
                        });

                        data = { documents: response.list, stats: response.stats };
                    }

                    break;
                case documentTypeValues.CASH_ORDER:
                    if (employeeTaskDataEntity.counterpartyType === counterpartValues.CLIENT) {
                        const response = yield call(fetchAPI, 'GET', 'cash_orders', {
                            ...filters,
                            clientId: employeeTaskDataEntity.counterpartClientId
                        });

                        const stats = { count: response.stats.totalCount };

                        data = { documents: response.list, stats };
                    } else if (
                        employeeTaskDataEntity.counterpartyType === counterpartValues.EMPLOYEE
                    ) {
                        console.log('Employee: ', employeeTaskDataEntity.counterpartEmployeeId);
                        const response = yield call(fetchAPI, 'GET', 'cash_orders', {
                            ...filters,
                            employeeId: employeeTaskDataEntity.counterpartEmployeeId
                        });

                        const stats = { count: response.stats.totalCount };

                        data = { documents: response.list, stats };
                    } else if (
                        employeeTaskDataEntity.counterpartyType ===
                        counterpartValues.BUSINESS_SUPPLIER
                    ) {
                        const response = yield call(fetchAPI, 'GET', 'cash_orders', {
                            ...filters,
                            businessSupplierId: employeeTaskDataEntity.counterpartBusinessSupplierId
                        });

                        const stats = { count: response.stats.totalCount };

                        data = { documents: response.list, stats };
                    }
                    break;
            }

            // check type ORDER, STORE_DOC, CASH_ORDER

            yield put(fetchDocumentsSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setDocumentFetching(false));
        }
    }
}

export function* createEmployeeTaskSaga() {
    while (true) {
        try {
            const {
                payload: { callback }
            } = yield take(CREATE_EMPLOYEE_TASK);

            const employeeTaskDataEntity = yield select(selectFields);

            employeeTaskDataEntity.scheduledDatetime = dayjs(
                `${dayjs(employeeTaskDataEntity.scheduledDate).format('YYYY-MM-DD')} ${dayjs(
                    employeeTaskDataEntity.scheduledTime
                ).format('HH:mm')}`
            ).format();

            yield call(
                fetchAPI,
                'POST',
                'employee_tasks',
                null,
                getEmployeeTaskEntityByType(
                    _.omit(employeeTaskDataEntity, ['scheduledDate', 'scheduledTime'])
                ),
                {
                    handleErrorInternally: true
                }
            );

            yield put(createEmployeeTaskSuccess());

            callback && callback(); // after creation was successfully completed
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([
        call(fetchEmployeesSaga),
        call(createEmployeeTaskSaga),
        call(fetchClientsSaga),
        call(fetchSuppliersSaga),
        call(fetchDocumentsSaga),
        call(fetchManagersSaga)
    ]);
}
