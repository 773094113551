import { PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { formKeys, formModes } from 'core/forms/reportAnalyticsForm/duck';
import { MODALS, saveModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import {
    selectAnalytics,
    selectAnalyticsStats,
    selectFetchingAnalytics,
    selectSelectedExpenseAnalyticsUniqueId,
    selectSelectedIncomeAnalyticsUniqueId,
    setDefaultAnalyticsUniqueId
} from 'modals/DebtZeroingModal/redux/analytics/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    analytics: selectAnalytics(state),
    analyticsStats: selectAnalyticsStats(state),
    fetchingAnalytics: selectFetchingAnalytics(state),
    incomeAnalyticsUniqueId: selectSelectedIncomeAnalyticsUniqueId(state),
    expenseAnalyticsUniqueId: selectSelectedExpenseAnalyticsUniqueId(state)
});

const mapDispatchToProps = {
    setDefaultAnalyticsUniqueId,
    saveModal,
    setModal
};

/**
 * @property type - one of: "INCOME", "EXPENSE"
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AnalyticsSelector extends React.Component {
    onOpenAnalyticsModal = () => {
        const { saveModal, setModal } = this.props;

        saveModal();
        setModal(MODALS.REPORT_ANALYTICS, {
            mode: formModes.ADD,
            initialTab: formKeys.analyticsForm
        });
    };

    render() {
        const {
            analytics,
            type,
            fetchingAnalytics,
            incomeAnalyticsUniqueId,
            expenseAnalyticsUniqueId,
            setDefaultAnalyticsUniqueId,

            intl: { formatMessage }
        } = this.props;

        if (!(type == 'INCOME' || type == 'EXPENSE')) return <div></div>;

        return (
            <div className={Styles.mainCont}>
                <div
                    className={Styles.selectCont}
                    title={formatMessage({
                        id:
                            type == 'INCOME'
                                ? 'debt_zeroing_modal.hint_analytics_income'
                                : 'debt_zeroing_modal.hint_analytics_expense'
                    })}
                >
                    <Select
                        disabled={fetchingAnalytics}
                        getPopupContainer={trigger => trigger.parentNode}
                        loading={fetchingAnalytics}
                        onChange={analyticsUniqueId => {
                            type == 'INCOME'
                                ? setDefaultAnalyticsUniqueId({
                                      selectedIncomeAnalyticsUniqueId: analyticsUniqueId
                                  })
                                : setDefaultAnalyticsUniqueId({
                                      selectedExpenseAnalyticsUniqueId: analyticsUniqueId
                                  });
                        }}
                        value={
                            type == 'INCOME' ? incomeAnalyticsUniqueId : expenseAnalyticsUniqueId
                        }
                    >
                        {_.map(
                            _.filter(
                                analytics,
                                ans => !ans.analyticsDisabled && ans.analyticsOrderType == type
                            ),
                            (item, index) => {
                                const { analyticsName, analyticsUniqueId } = item;

                                return (
                                    <Option key={index} value={analyticsUniqueId}>
                                        {analyticsName}
                                    </Option>
                                );
                            }
                        )}
                    </Select>
                </div>
                <div className={Styles.analyticsBtnCont} title='Create new analytics'>
                    <Button
                        className={Styles.analyticsBtn}
                        onClick={() => this.onOpenAnalyticsModal()}
                    >
                        <PlusOutlined className={Styles.addAnalyticsIcon} />
                    </Button>
                </div>
            </div>
        );
    }
}
