// Core
import { intlActions } from 'core/intl/actions';
import { put } from 'redux-saga/effects';
import { setLocale } from 'utils';
import warning from 'warning';

export function* updateIntlWorker({ payload: intl }) {
    try {
        yield setLocale(intl.locale);
        yield put(intlActions.updateIntlSuccess(intl));
    } catch (error) {
        warning(false, 'Error in updateIntlWorker', error.message);
        yield put(intlActions.updateIntlFail(error));
    }
}
