import { Numeral } from 'commons';
import React from 'react';
import { getCurrency, numeralFormatter } from 'utils';
import { ProductTableData } from '../ProductTableData';

export default props => {
    const name = {
        title: props.intl.formatMessage({
            id: 'storage.name'
        }),
        dataIndex: 'name',
        width: '10%',
        render: (name, data) => (
            <ProductTableData
                code={data.code}
                link
                name={name}
                onClick={() =>
                    props.redirectToTracking({
                        id: data.id,
                        name: data.name,
                        code: data.code
                    })
                }
            />
        )
    };

    const incomePrice = {
        title: props.intl.formatMessage({
            id: 'storage.income_price'
        }),
        dataIndex: 'incomePrice',
        width: '10%',
        className: 'income',
        render: incomePrice => <Numeral currency={getCurrency()}>{incomePrice}</Numeral>
    };

    const incomeQuantity = {
        title: props.intl.formatMessage({
            id: 'storage.quantity'
        }),
        dataIndex: 'incomeQuantity',
        width: '5%',
        className: 'income',
        render: incomeQuantity => numeralFormatter(incomeQuantity)
    };

    const incomeUnits = {
        title: props.intl.formatMessage({
            id: 'services_table.units'
        }),
        dataIndex: 'unit',
        width: '5%',
        className: 'income',
        render: unit => unit
    };

    const incomeSum = {
        title: props.intl.formatMessage({
            id: 'storage.income_sum'
        }),
        dataIndex: 'incomeSum',
        width: '10%',
        className: 'income',
        render: incomeSum => <Numeral currency={getCurrency()}>{incomeSum}</Numeral>
    };
    const expensePrice = {
        title: props.intl.formatMessage({
            id: 'storage.expense_price'
        }),
        dataIndex: 'expensePrice',
        width: '10%',
        className: 'expense',
        render: expensePrice => (
            <React.Fragment>
                -<Numeral currency={getCurrency()}>{expensePrice}</Numeral>
            </React.Fragment>
        )
    };

    const expenseQuantity = {
        title: props.intl.formatMessage({
            id: 'storage.quantity'
        }),
        dataIndex: 'expenseQuantity',
        width: '5%',
        className: 'expense',
        render: expenseQuantity => numeralFormatter(Number(expenseQuantity).toFixed(1))
    };

    const expenseSum = {
        title: props.intl.formatMessage({
            id: 'storage.expense_sum'
        }),
        dataIndex: 'expenseSum',
        width: '10%',
        className: 'expense',
        render: expenseSum => (
            <React.Fragment>
                -<Numeral currency={getCurrency()}>{expenseSum}</Numeral>
            </React.Fragment>
        )
    };

    return [name, incomePrice, incomeUnits, incomeQuantity, incomeSum, expensePrice, expenseQuantity, expenseSum];
};
