import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm, Table, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import AddRolesModal from './AddRolesModal';

const { TextArea } = Input;

const AditionalRolesModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        fetchAdditionalRoles,
        loadingRows,
        rows,
        selectedBusinessRole
    } = props;

    const [edit, setEdit] = useState(undefined);
    const [openRolesModal, setOpenRolesModal] = useState(false);
    const [roles, setRoles] = useState([]);

    // const setData = row => {
    //     setAccountId(row.accountId || undefined);
    //     setAnalyticsId(row.analyticsId || undefined);
    //     setAmountSignature(row.amountSignature || undefined);
    //     setFormula(row.amountFormula || undefined);
    //     setOperationType(row.operationType || undefined);
    //     setComment(row.comment || undefined);
    //     setId(row.id || undefined);

    //     setEdit(undefined);
    // };

    const columns = useMemo(() => {
        return [
            {
                title: () => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Tooltip title={<FormattedMessage id='entries_tab.add_rows' />}>
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={async () => {
                                        await setOpenRolesModal(true);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                },
                key: 'actions',
                width: '8%',
                render: (row, number, index) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Popconfirm
                                disabled={selectedBusinessRole == row.id}
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        '/v2/roles_and_accesses/business_roles',
                                        null,
                                        { roleId: row.id },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setTimeout(() => fetchAdditionalRoles(), 100);
                                }}
                                title={`${formatMessage({ id: 'delete' })}?`}
                            >
                                <Button disabled={selectedBusinessRole == row.id} icon={<DeleteOutlined />} />
                            </Popconfirm>

                            <Button
                                icon={<EditOutlined />}
                                onClick={async () => {
                                    await setOpenRolesModal(true);
                                    setEdit(row);
                                }}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.role_name' />,
                key: 'name',
                dataIndex: 'name',
                width: '15%'
            },
            {
                title: <FormattedMessage id='packages.default_role' />,
                key: 'def_package',
                width: '10%',
                render: row => {
                    return row.createdFromRoleName ? row.createdFromRoleName : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='packages.default_route' />,
                key: 'route',
                width: '15%',
                render: row => {
                    return row.defaultRoute ? row.defaultRoute : <FormattedMessage id='long_dash' />;
                }
            }
        ];
    }, [selectedBusinessRole, formatMessage, fetchAdditionalRoles]);

    // useEffect(() => {
    //     if (open) {
    //         fetchAdditionalRoles();
    //     }
    // }, [open, fetchAdditionalRoles]);

    const onClose = () => {
        hideModal();
    };

    const dataSource = rows ? rows.filter(({ createdFromRoleId }) => createdFromRoleId) : [];

    return (
        <div>
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                footer={null}
                maskClosable={false}
                onCancel={() => onClose()}
                open={open}
                title={<FormattedMessage id='packages.own_roles' />}
                width='60%'
            >
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    loading={loadingRows}
                    pagination={false}
                    scroll={{ x: 'auto' }}
                    size='small'
                />
            </Modal>
            <AddRolesModal
                allRoles={rows}
                edit={edit}
                fetchAdditionalRoles={fetchAdditionalRoles}
                hideModal={() => {
                    setOpenRolesModal(false);
                    setEdit(undefined);
                }}
                open={openRolesModal}
            />
        </div>
    );
};

export default injectIntl(AditionalRolesModal);
