import { Button } from 'antd';
import { Layout } from 'commons';
import { ClientsContainer, ClientsFilterContainer, UniversalFilters } from 'containers';
import { setSearchQuery, setUniversalFilters } from 'core/clients/duck';
import { fetchUniversalFiltersForm } from 'core/forms/universalFiltersForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { AddClientModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modal: state.modals.modal,
    addClientFormData: state.forms.addClientForm.data,
    collapsed: state.ui.collapsed,
    user: state.auth,
    stats: state.clients.stats,
    universalFilter: state.clients.universalFilter,
    searchQuery: state.clients.searchQuery
});

const mapDispatchToProps = {
    setModal,
    resetModal,
    fetchUniversalFiltersForm,
    setUniversalFilters,
    setSearchQuery
};

/**
 * This page contains table of clieents and its operations, it is an old page and it was replaced with clientHotOperations.
 * May be in future it can be deleted permanently.
 */
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientsPage extends Component {
    componentDidMount() {
        if (this.props.location.state && this.props.location.state.showForm) {
            this.props.setModal(MODALS.ADD_CLIENT);
        }
    }

    render() {
        const { modal, setModal, resetModal, addClientFormData, collapsed, stats, setSearchQuery } =
            this.props;

        return (
            <Layout
                controls={
                    <div className={Styles.buttonGroup}>
                        <Button
                            disabled={isForbidden(
                                this.props.user,
                                permissions.CREATE_EDIT_DELETE_CLIENTS
                            )}
                            onClick={() => setModal(MODALS.ADD_CLIENT)}
                            type='primary'
                        >
                            <FormattedMessage id='clients-page.add_client' />
                        </Button>
                    </div>
                }
                description={<FormattedMessage id='clients-page.description' />}
                paper={false}
                title={<FormattedMessage id='clients-page.title' />}
            >
                <section className={`${Styles.filters} ${collapsed && Styles.filtersCollapsed}`}>
                    <ClientsFilterContainer setSearchQuery={setSearchQuery} />
                    <UniversalFilters
                        areFiltersDisabled={isForbidden(
                            this.props.user,
                            permissions.FILTER_CLIENTS
                        )}
                        setUniversalFilter={this.props.setUniversalFilters}
                        stats={stats}
                        style={{
                            margin: '-12px 0 -8px'
                        }}
                        universalFilter={this.props.universalFilter}
                    />
                </section>
                <section className={Styles.table}>
                    <ClientsContainer />
                </section>
                <AddClientModal
                    addClientFormData={addClientFormData}
                    onSubmit={() => {
                        window.location.reload();
                    }}
                    resetModal={resetModal}
                    searchQuery={this.props.searchQuery}
                    visible={modal}
                    wrappedComponentRef={this.clientsPageRef}
                />
            </Layout>
        );
    }
}
