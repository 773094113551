import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout, Paper } from 'commons';
import { StoreProductsTable } from 'components';
import { MODALS, setModal } from 'core/modals/duck';
import {
    downloadExcelTemplate,
    fetchLinks,
    productsExcelImport,
    productsExcelImportValidate,
    selectLinks,
    selectProductsImporting,
    setStoreProductsFilters
} from 'core/storage/products';
import { ProductsExcelForm } from 'forms';
import _ from 'lodash';
import { StoreProductModal } from 'modals';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withErrorMessage } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
`;

const StoreProducts = withErrorMessage()(props => {
    if (props.location.state && props.location.state.showForm) {
        props.setModal(MODALS.STORE_PRODUCT);
    }
    if (props.location.state && props.location.state.searchValue) {
        props.setStoreProductsFilters({
            query: props.location.state.searchValue.replace(/[+()]/g, '')
        });
    }

    const [helperDrawerOpen, setDrawerOpen] = useState(false);

    console.log(helperDrawerOpen);

    return (
        <Layout
            controls={
                <React.Fragment>
                    {!props.importing ? (
                        <React.Fragment>
                            <Button
                                disabled={!isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST, accesses.ROWO)}
                                onClick={() => props.setModal(MODALS.STORE_PRODUCT)}
                                type='primary'
                            >
                                <FormattedMessage id='add' />
                            </Button>
                            <div
                                style={{
                                    fontSize: 24,
                                    marginLeft: 8
                                }}
                            >
                                <QuestionCircleOutlined
                                    onClick={async () => {
                                        await props.fetchLinks();
                                        setDrawerOpen(true);
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        <div>
                            <FormattedMessage id='storage.please_finish.import' />
                        </div>
                    )}
                </React.Fragment>
            }
            paper={false}
            title={<FormattedMessage id='navigation.products' />}
        >
            <Paper>
                {props.importing ? (
                    <ProductsExcelForm />
                ) : (
                    <StoreProductsTable
                    disabled={!isGrantAccessed(props.user, grants.DIRECTORIES_PRODUCTS_LIST, accesses.ROWO)}
                        lacationSearchValue={props.location.state && props.location.state.searchValue}
                    />
                )}
            </Paper>
            <StoreProductModal />
            <Drawer
                onClose={() => {
                    setDrawerOpen(false);
                }}
                open={helperDrawerOpen}
                title={<FormattedMessage id='navigation.helper' />}
                width={420}
            >
                <div>
                    {props.allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                        <div className={Styles.linkBlock}>
                            <div className={Styles.ogTitle}>
                                {index + 1}. {ogTitle}
                            </div>
                            <div className={Styles.ogDesc}>{ogDescription}</div>
                            <div className={Styles.ogImg}>
                                <Image
                                    src={_.isArray(ogImage) ? _.get(ogImage, '[0].url', []) : _.get(ogImage, 'url', [])}
                                />
                            </div>
                            <a href={ogUrl} rel='noreferrer' target='_blank'>
                                <Button
                                    style={{
                                        width: '100%'
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='repair_map_table.goto' />
                                </Button>
                            </a>
                        </div>
                    ))}
                </div>
            </Drawer>
        </Layout>
    );
});

const mapStateToProps = state => ({
    user: state.auth,
    importing: selectProductsImporting(state),
    allLinks: selectLinks(state)
});

const mapDispatchToProps = {
    productsExcelImport,
    productsExcelImportValidate,
    setModal,
    setStoreProductsFilters,
    downloadExcelTemplate,
    fetchLinks
};

export const ProductsPage = connect(mapStateToProps, mapDispatchToProps)(StoreProducts);
