import { CheckCircleOutlined } from '@ant-design/icons';
import { Radio, Rate, Table } from 'antd';
import classNames from 'classnames/bind';
import { Catcher, Numeral } from 'commons';
import dayjs from 'dayjs';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { isForbidden, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const cx = classNames.bind(Styles);

@withRouter
export default class EmployeesTable extends Component {
    constructor(props) {
        super(props);
        this.columns = () => [
            {
                title: <FormattedMessage id='employee-table.employee' />,
                dataIndex: 'name',
                width: 'auto',
                sort: (a, b) => a.surname.localeCompare(b.surname),
                render: (text, record) => (
                    <div>
                        {!isGrantAccessed(this.props.user, grants.DIRECTORIES_EMPLOYEE_CARD) ? (
                            <React.Fragment>
                                {`${record.surname} ${record.name}`}
                                <div className={Styles.jobTitle}>{record.jobTitle}</div>
                            </React.Fragment>
                        ) : (
                            <Link className={Styles.employeeName} to={book.editEmployee.replace(':id', record.id)}>
                                {`${record.surname} ${record.name}`}
                                <div className={Styles.jobTitle}>{record.jobTitle}</div>
                            </Link>
                        )}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee-table.status' />,
                dataIndex: 'fireDate',
                width: 'auto',
                render: (data, record) =>
                    record.fireDate ? (
                        <div className={Styles.fired}>
                            <FormattedMessage id='employee-table.fired' />
                            <div className={Styles.fireReason}>{record.fireReason}</div>
                        </div>
                    ) : (
                        <div className={Styles.working}>
                            <FormattedMessage id='employee-table.working' />
                        </div>
                    )
            },
            {
                title: <FormattedMessage id='employee.manager_access' />,
                dataIndex: 'isManager',
                width: 'auto',
                render: (isManager, { managerEnabled }) =>
                    isManager ? <CheckCircleOutlined className={this._managerIconClassName(managerEnabled)} /> : null
            },
            {
                title: <FormattedMessage id='suppliers_page.debt' />,
                dataIndex: 'debt',
                width: 'auto',
                render: (_, row) => {
                    const debt = (
                        <Numeral className={Styles.debt} mask='0,0.00' nullText='0'>
                            {Number(row.debt) || 0}
                        </Numeral>
                    );

                    return !isGrantAccessed(this.props.user, grants.DIRECTORIES_EMPLOYEE_CARD_OUTSTANDING_DEBTS) ? (
                        debt
                    ) : (
                        <Link
                            to={{
                                pathname: book.editEmployee.replace(':id', row.id),
                                state: {
                                    specificTab: 'empDebtContainer'
                                }
                            }}
                        >
                            {debt}
                        </Link>
                    );
                }
            },
            {
                title: <FormattedMessage id='employee-table.hire_date' />,
                dataIndex: 'hireDate',
                width: 'auto',
                render: (text, record) => (
                    <div>
                        {record.hireDate && dayjs(record.hireDate).format('DD.MM.YYYY')}
                        {record.fireDate && ` - ${dayjs(record.fireDate).format('DD.MM.YYYY')}`}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='employee-table.rating' />,
                dataIndex: 'rating',
                width: 'auto',
                render: value => value && this._renderRatingStars(value)
            }
        ];

        this.mobileColumns = () => [
            {
                title: <FormattedMessage id='employee-table.employee' />,
                dataIndex: 'name',
                width: 'auto',
                render: (text, record) => {
                    const status = record.fireDate ? (
                        <div className={Styles.fired}>
                            <FormattedMessage id='employee-table.fired' />
                            <div className={Styles.fireReason}>{record.fireReason}</div>
                        </div>
                    ) : (
                        <div className={Styles.working}>
                            <FormattedMessage id='employee-table.working' />
                        </div>
                    );

                    return (
                        <div>
                            {isForbidden(this.props.user, permissions.ACCESS_EMPLOYEE) ||
                            isForbidden(this.props.user, permissions.EMPLOYEES_SALARIES) ? (
                                <React.Fragment>
                                    {`${record.name} ${record.surname}`}
                                    <div className={Styles.jobTitle}>{record.jobTitle}</div>
                                    {status}
                                </React.Fragment>
                            ) : (
                                <Link className={Styles.employeeName} to={book.editEmployee.replace(':id', record.id)}>
                                    {`${record.name} ${record.surname}`}
                                    <div className={Styles.jobTitle}>{record.jobTitle}</div>
                                    {status}
                                </Link>
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='employee-table.manager' />,
                dataIndex: 'isManager',
                width: 'auto',
                render: (isManager, { managerEnabled }) =>
                    isManager ? <CheckCircleOutlined className={this._managerIconClassName(managerEnabled)} /> : null
            },
            {
                title: <FormattedMessage id='date' />,
                dataIndex: 'hireDate',
                width: 'auto',
                render: (text, record) => (
                    <div>
                        {record.hireDate && dayjs(record.hireDate).format('DD.MM.YYYY')}
                        {record.fireDate && ` - ${dayjs(record.fireDate).format('DD.MM.YYYY')}`}
                    </div>
                )
            }
        ];
    }

    _setEmployeesFilterStatus = ({ status, disabled }) => {
        this.props.setEmployeesStatus({ status, disabled });
        this.props.fetchEmployees();
    };

    _managerIconClassName = enabled =>
        cx({
            managerIconDisabled: !enabled,
            managerIcon: true
        });

    render() {
        const { employees, isMobile } = this.props;
        const { columns } = this;
        const { mobileColumns } = this;
        const statusFilter = this._renderEmployeeStatusFilter();

        return (
            <Catcher>
                {statusFilter}
                <Table
                    bordered
                    columns={!isMobile ? columns() : mobileColumns()}
                    dataSource={
                        employees && employees.length > 0
                            ? employees.map((task, index) => ({
                                  ...task,
                                  index,
                                  key: v4()
                              }))
                            : null
                    }
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    scroll={!isMobile && { x: 840 }}
                    size='small'
                    style={{ marginTop: 18 }}
                />
            </Catcher>
        );
    }

    _renderRatingStars = rating => {
        const value = rating / 2;
        const ratingStarts = <Rate allowHalf className={Styles.ratingStars} defaultValue={value} disabled />;

        return ratingStarts;
    };

    _renderEmployeeStatusFilter = () => {
        const { status } = this.props;

        return (
            <RadioGroup value={status}>
                <RadioButton
                    onClick={() =>
                        this._setEmployeesFilterStatus({
                            status: 'working',
                            disabled: false
                        })
                    }
                    value='working'
                >
                    <FormattedMessage id='employee-table.filter.working' />
                </RadioButton>
                <RadioButton
                    onClick={() =>
                        this._setEmployeesFilterStatus({
                            status: 'fired',
                            disabled: true
                        })
                    }
                    value='fired'
                >
                    <FormattedMessage id='employee-table.filter.fired' />
                </RadioButton>
                <RadioButton
                    onClick={() =>
                        this._setEmployeesFilterStatus({
                            status: 'all',
                            disabled: null
                        })
                    }
                    value='all'
                >
                    <FormattedMessage id='all' />
                </RadioButton>
            </RadioGroup>
        );
    };
}
