import { Catcher } from 'commons';
import { SettingSalaryTable } from 'components';
import {
    createSalary,
    deleteSalary,
    fetchSalary,
    fetchSalaryReport,
    onChangeSettingSalaryForm,
    resetFields,
    updateSalary
} from 'core/forms/settingSalaryForm/duck';
import { SalaryReportForm } from 'forms';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

@injectIntl
@withReduxForm2({
    name: 'settingSalary',
    actions: {
        change: onChangeSettingSalaryForm,
        resetFields,
        fetchSalary,
        fetchSalaryReport,

        createSalary,
        updateSalary,
        deleteSalary
    },
    mapStateToProps
})
export default class SettingSalaryForm extends Component {
    componentDidMount() {
        const { employeeId } = this.props;
        this.props.fetchSalary(employeeId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.employeeId !== prevProps.employeeId) {
            const { employeeId } = this.props;
            this.props.fetchSalary(employeeId);
        }
    }

    render() {
        const {
            createSalary,
            updateSalary,
            deleteSalary,

            employeeId,
            salaries,
            user,
            form,
            fields,
            loading,
            positions,
            initialEmployee,
            fetchEmployee
        } = this.props;

        const isGrantedAccess = !isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_SALARY_SETTINGS, accesses.ROWO);

        const table = (
            <SettingSalaryTable
                createSalary={createSalary.bind(this, employeeId)}
                deleteSalary={deleteSalary.bind(this, employeeId)}
                fields={fields}
                form={form}
                initialSettingSalaries={salaries}
                isGrantedAccess={isGrantedAccess}
                loading={loading}
                resetFields={resetFields}
                updateSalary={updateSalary.bind(this, employeeId)}
                user={user}
            />
        );

        return (
            <Catcher>
                <div className={Styles.salariesReportContainer}>
                    {!isGrantedAccess && (
                        <SalaryReportForm
                            employeesIds={[employeeId]}
                            fetchEmployee={fetchEmployee}
                            initialEmployee={initialEmployee}
                            positions={positions}
                            table={table}
                        />
                    )}
                </div>
            </Catcher>
        );
    }
}
