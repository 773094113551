import book from 'routes/book';
import { fetchAPI } from 'utils';

/**
 * Copy to clipboard
 * @param {*} url - Data to be copied into clipboard
 */
function handleCopyToClipboard(url) {
    url && navigator.clipboard.writeText(url).then();
}

/**
 * Generate link for online service book page that can be opened in another window, browser or device.
 * To make access to online service book, we have to generate special sessionId and send this data with generated link address,
 * this session Id will be used later for accessing routes.
 *
 * @param props.vehicleId - Vehicle to create online service book for
 * @param props.businessId - Business where this vehicle exists
 *
 * @returns Link to online service book or null if cannot generate
 */
async function generateOnlineServiceBookLink({ vehicleId, businessId, sessionBussiness }) {
    if (vehicleId) {
        const { sessionId } = await fetchAPI('POST', 'generate_session_id');
        const data = {
            vehicleId,
            sessionId,
            businessId,
            sessionBussiness
        };
        const base64JSON = window.btoa(JSON.stringify(data));

        return `${window.location.origin}${book.onlineServiceBook}/${base64JSON}`;
    }

    return null;
}

export { handleCopyToClipboard, generateOnlineServiceBookLink };

