import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchBusinessSuppliersSuccess,
    fetchStoreDocsSuccess,
    FETCH_BUSINESS_SUPPLIERS,
    FETCH_STORE_DOCS,
    selectBusinessSuppliersFilters,
    selectStoreDocsFilters,
    setFetchingBusinessSuppliers,
    setFetchingStoreDocs
} from './duck';

export function* fetchBusinessSuppliersSaga() {
    while (true) {
        try {
            yield take(FETCH_BUSINESS_SUPPLIERS);

            yield put(setFetchingBusinessSuppliers(true));
            const payloadFilters = yield select(selectBusinessSuppliersFilters);

            const filters = {
                ...payloadFilters,
                createdFromDate: _.get(payloadFilters, 'createdFromDate')
                    ? payloadFilters.createdFromDate.format('YYYY-MM-DD')
                    : undefined,
                createdToDate: _.get(payloadFilters, 'createdToDate')
                    ? payloadFilters.createdToDate.format('YYYY-MM-DD')
                    : undefined
            };

            const response = yield call(fetchAPI, 'GET', '/business_suppliers_dept', { filters });
            const { businessSuppliers, stats: businessSuppliersStats } = response;

            yield put(
                fetchBusinessSuppliersSuccess({
                    businessSuppliers,
                    businessSuppliersStats
                })
            );
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingBusinessSuppliers(false));
        }
    }
}

export function* fetchStoreDocsSaga() {
    while (true) {
        try {
            yield take(FETCH_STORE_DOCS);

            yield put(setFetchingStoreDocs(true));
            const filters = yield select(selectStoreDocsFilters);

            const response = yield call(fetchAPI, 'GET', '/business_supplier_dept_store_docs', {
                filters
            });
            const { storeDocs, stats: storeDocsStats } = response;

            yield put(fetchStoreDocsSuccess({ storeDocs, storeDocsStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingStoreDocs(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchBusinessSuppliersSaga), call(fetchStoreDocsSaga)]);
}
