import { Table } from 'antd';
import { fetchOrderTasks, selectOrderTasks, setOrderTasksFilters } from 'core/forms/orderForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI } from 'utils';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    orderTasks: selectOrderTasks(state),
    fetchedOrder: state.forms.orderForm.fetchedOrder
});

const mapDispatchToProps = {
    setModal,
    fetchOrderTasks,
    setOrderTasksFilters
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrderTasksTable extends React.Component {
    onEmployeeTask = () => {
        this.props.setModal(MODALS.EMPLOYEE_TASK_MODAL, {
            initialValues: {
                orderId: _.get(this.props, 'fetchedOrder.order.id'),
                counterpartClientId: _.get(this.props, 'fetchedOrder.order.clientId'),
                documentType: 'ORDER',
                counterpartyType: 'CLIENT'
            },
            afterCreateCallback: () => {
                // console.log("fetcher order: ", this.props.fetchedOrder.order.id)
                // this.props.setOrderTasksFilters({ orderId: this.props.fetchedOrder.order.id })
                this.props.fetchOrderTasks(this.props.fetchedOrder.order.id);
            }
        });
    };

    onEmployeeSubTasks = id => {
        this.props.setModal(MODALS.EMPLOYEE_SUBTASKS_MODAL, {
            taskId: id
        });
    };

    handleTaskRedirect = async task => {
        const { user } = this.props;
        // if in NEW status then should be change to INWORK and task is assigned to manager

        if (task.status === 'NEW' && task.performerIds.includes(user.id)) {
            await fetchAPI('PUT', `employee_tasks/${task.id}`, undefined, {
                status: 'INWORK'
            }).then(() => {
                history.push({
                    pathname: `${book.myTasksPage}/${task.id}`
                });
            });
        } else {
            history.push({
                pathname: `${book.myTasksPage}/${task.id}`
            });
        }
    };

    render() {
        const {
            orderTasks
            // appurtenances,
            // stats,
            // sort,
            // setPageAppurtenances,
            // fetching,
        } = this.props;

        const columns = columnsConfig({
            onEmployeeTask: this.onEmployeeTask,
            onEmployeeSubTasks: this.onEmployeeSubTasks,
            handleTaskRedirect: this.handleTaskRedirect
        });

        // const pagination = {
        //     pageSize: 25,
        //     size: "large",
        //     total: Math.ceil(stats.totalRowsCount / 25) * 25,
        //     current: sort.page,
        //     onChange: page => {
        //         setPageAppurtenances({page})
        //     },
        // }

        return (
            <div className={Styles.tableCont}>
                <Table
                    bordered
                    columns={columns}
                    dataSource={orderTasks}
                    pagination={false}
                    rowClassName={item => {
                        if (_.get(item, 'status') === 'NEW') return Styles.newTaskRow;
                        if (_.get(item, 'status') === 'COMPLETED') return Styles.completedTaskRow;
                    }}
                    rowKey={() => v4()}
                    scroll={{ x: 'auto', y: '60vh' }}
                    size='small'
                />
            </div>
        );
    }
}
