exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__reviewHead---14U2U{display:flex;justify-content:space-between}.styles-m__npsColumn---2Huny{flex:0 1 70%}.styles-m__orderColumn---X7ZGZ{background:var(--static);display:flex;flex-direction:column;flex:0 1 20%;justify-content:space-around;padding:14px}.styles-m__orderColumn---X7ZGZ a{color:var(--link)}.styles-m__orderColumn---X7ZGZ a:hover{color:var(--primary)}.styles-m__dataRow---uuJok{margin-bottom:10px}.styles-m__label---lcW8C{font-weight:700}.styles-m__rating---10YjX{display:flex;margin-bottom:24px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACI,YAAa,CACb,6BACJ,CAEA,6BACI,YACJ,CAEA,+BACI,wBAAyB,CACzB,YAAa,CACb,qBAAsB,CACtB,YAAa,CACb,4BAA6B,CAC7B,YACJ,CAEA,iCACI,iBAKJ,CAHI,uCACI,oBACJ,CAGJ,2BACI,kBACJ,CAEA,yBACI,eACJ,CAEA,0BACI,YAAa,CACb,kBACJ","file":"styles.m.css","sourcesContent":[".reviewHead {\n    display: flex;\n    justify-content: space-between;\n}\n\n.npsColumn {\n    flex: 0 1 70%;\n}\n\n.orderColumn {\n    background: var(--static);\n    display: flex;\n    flex-direction: column;\n    flex: 0 1 20%;\n    justify-content: space-around;\n    padding: 14px;\n}\n\n.orderColumn a {\n    color: var(--link);\n\n    &:hover {\n        color: var(--primary);\n    }\n}\n\n.dataRow {\n    margin-bottom: 10px;\n}\n\n.label {\n    font-weight: bold;\n}\n\n.rating {\n    display: flex;\n    margin-bottom: 24px;\n}\n"]}]);

// Exports
exports.locals = {
	"reviewHead": "styles-m__reviewHead---14U2U",
	"npsColumn": "styles-m__npsColumn---2Huny",
	"orderColumn": "styles-m__orderColumn---X7ZGZ",
	"dataRow": "styles-m__dataRow---uuJok",
	"label": "styles-m__label---lcW8C",
	"rating": "styles-m__rating---10YjX"
};