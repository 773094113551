import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

const DashboardTimeline = ({ schedule }) => {
    if (dayjs().format('H') >= schedule.beginHour && dayjs().format('H') <= schedule.endHour) {
        return <StyledDashboardTimeline time={schedule.beginHour} />;
    }

    return null;
};

const StyledDashboardTimeline = styled.hr`
    height: 0px;
    position: absolute;
    left: 0;
    right: 0;
    border: none;
    z-index: 1;
    margin: 0;
    border-bottom: 2px solid #526e82;
    top: ${props =>
        `${(dayjs().format('H') - props.time + dayjs().format('m') / 60) * 60 + 50}px`};
`;

export default DashboardTimeline;
