import _ from 'lodash';

/**
 * Convert number symbols to cyrillic for search
 * @param word - registration number of vehicle
 * @returns {string}
 */
export function convertLatinToCyrillic(word) {
    let ans = '';

    const A = new Array();

    A.A = 'А';
    A.B = 'В';
    A.E = 'Е';
    A.I = 'І';
    A.K = 'К';
    A.M = 'М';
    A.H = 'Н';
    A.O = 'О';
    A.P = 'Р';
    A.C = 'С';
    A.T = 'Т';
    A.X = 'Х';

    for (const i in word) {
        if (_.isUndefined(A[word[i]])) {
            ans += word[i];
        } else {
            ans += A[word[i]];
        }
    }

    return ans;
}

export function removeCyrillicLetters(text) {
    return text.replace(/[\u0400-\u04FF]+/g, '');
}
