import { InboxOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { MODALS } from 'core/modals/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const { Dragger } = Upload;

@injectIntl
class ImportDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: []
        };
    }

    handleOk = async () => {
        const formData = new FormData();
        formData.append('file', this.state.fileList[0]);
        // await fetchAPI('POST', `sync/import/orders/${this.props.orderId}/details`, undefined, formData);
        const url = `${__API_URL__}/sync/import/orders/${this.props.orderId}/details`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: localStorage.getItem('_my.carbook.pro_token')
                }
            });
            const result = await response.json();
            this.props.updateDataSource();
        } catch (error) {
            console.error('error:', error);
            this.props.updateDataSource();
        }
        this.handleCancel();
    };

    handleCancel = () => {
        this.props.resetModal();
        this.setState({
            fileList: []
        });
    };

    render() {
        const { modal } = this.props;
        const { fileList } = this.state;
        const uploadFileProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));

                return false;
            },
            fileList
        };

        return (
            <Modal
                maskClosable={false}
                okButtonProps={{
                    disabled: fileList.length < 1
                }}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                title={<FormattedMessage id='export_import_pages.import_data' />}
                visible={modal === MODALS.IMPORT_DETAIL_MODAL}
                width={480}
            >
                <Dragger {...uploadFileProps}>
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                </Dragger>
            </Modal>
        );
    }
}

export default ImportDetailsModal;
