import { CheckOutlined, QuestionOutlined, SwapOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Catcher } from 'commons';
import { TecDocModals } from 'components';
import {
    clearCrossParts,
    clearPartAttributes,
    clearSuggestionParts,
    fetchCrossParts,
    fetchPartAttributes,
    fetchSuggestionParts,
    setOperationIndex
} from 'core/tecDocActions/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapDispatchToProps = {
    fetchPartAttributes,
    fetchSuggestionParts,
    fetchCrossParts,
    clearPartAttributes,
    clearSuggestionParts,
    clearCrossParts,
    setOperationIndex
};

const mapStateToProps = state => ({
    suggestions: state.tecDocActions.suggestions,
    crosses: state.tecDocActions.crosses,
    attributes: state.tecDocActions.attributes,
    selectedAttributes: state.tecDocActions.selectedAttributes,
    selectedSuggestions: state.tecDocActions.selectedSuggestions,
    selectedCrosses: state.tecDocActions.selectedCrosses,
    operationIndex: state.tecDocActions.operationIndex
});

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TecDocActionsContainer extends Component {
    getSupplier() {
        const { selectedAttributes, brands, brandId } = this.props;
        const { supplierId } = selectedAttributes || {};

        return supplierId || brandId
            ? _.chain(brands)
                  .find(supplierId ? { supplierId } : { brandId })
                  .value()
            : null;
    }

    getProduct() {
        const { detailId, details } = this.props;

        return detailId ? _.chain(details).find({ detailId }).value() : null;
    }

    render() {
        const { detailCode, modificationId, index, operationIndex } = this.props;

        const product = this.getProduct();
        const supplier = this.getSupplier();
        const productId = _.get(product, 'productId');
        const supplierId = _.get(supplier, 'supplierId');

        const { fetchPartAttributes, fetchSuggestionParts, fetchCrossParts, setOperationIndex } =
            this.props;

        const areAttributesForbidden = !detailCode || !supplierId;
        const areSuggestionsForbidden = !productId || !modificationId;
        const areCrossesForbidden = !productId || !modificationId;

        return (
            <Catcher>
                {index === operationIndex && (
                    <TecDocModals product={product} supplier={supplier} {...this.props} />
                )}
                <div className={Styles.actionContainer}>
                    <Button
                        className={Styles.actionItem}
                        disabled={areAttributesForbidden}
                        icon={<CheckOutlined />}
                        onClick={() => {
                            setOperationIndex(index);
                            fetchPartAttributes(detailCode, supplierId);
                        }}
                    />
                    <Button
                        className={Styles.actionItem}
                        disabled={areSuggestionsForbidden}
                        icon={<QuestionOutlined />}
                        onClick={() => {
                            setOperationIndex(index);
                            fetchSuggestionParts(productId, modificationId);
                        }}
                        type='primary'
                    />
                    <Button
                        className={Styles.actionItem}
                        danger
                        disabled={areCrossesForbidden}
                        icon={<SwapOutlined />}
                        onClick={() => {
                            setOperationIndex(index);
                            fetchCrossParts(productId, modificationId);
                        }}
                        type='primary'
                    />
                </div>
            </Catcher>
        );
    }
}
