// Core
import { QuestionCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, ColorPicker, Drawer, Image, Input, InputNumber, Table, notification } from 'antd';
import { Layout } from 'commons';
import { resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modal: state.modals.modal,
    user: state.auth
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class DetailStatusSettingsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statusSettings: [],
            allLinks: [],
            helperDrawerOpen: false
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='order_form_table.status' />,
                key: 'status',
                render: row => {
                    return <FormattedMessage id={`status.${row.status}`} />;
                }
            },
            {
                title: <FormattedMessage id='detail_status_settings_page.custom_status_name' />,
                key: 'customStatusName',
                render: row => {
                    return (
                        <Input
                            allowClear
                            disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_PART_STATUSES, accesses.ROWO)}
                            onChange={({ target }) => {
                                row.customStatusName = target.value;
                                this.setState({});
                            }}
                            value={row.customStatusName}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='repair_map_setting.color' />,
                key: 'color',
                render: row => {
                    return (
                        <ColorPicker
                            disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_PART_STATUSES, accesses.ROWO)}
                            onChange={color => {
                                row.color = color.toHexString();

                                this.setState({});
                            }}
                            showText
                            value={row.color}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='time' />,
                key: 'seconds',
                render: row => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={
                                row.seconds === null ||
                                !isGrantAccessed(this.props.user, grants.SETTINGS_PART_STATUSES, accesses.ROWO)
                            }
                            onChange={value => {
                                row.seconds = Math.round(value * 60);
                                this.setState({});
                            }}
                            value={Math.round(row.seconds / 60)}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='sms_settings.set_default' />,
                key: 'set_default',
                render: row => {
                    return isGrantAccessed(this.props.user, grants.SETTINGS_PART_STATUSES, accesses.ROWO) ? (
                        <UndoOutlined
                            disabled={!isGrantAccessed(this.props.user, grants.SETTINGS_PART_STATUSES, accesses.ROWO)}
                            onClick={async () => {
                                await fetchAPI('DELETE', 'status_settings', undefined, {
                                    tables: ['DETAILS'],
                                    statuses: [row.status]
                                });

                                await notification.success({
                                    message: this.props.intl.formatMessage({
                                        id: 'sms_settings.saved'
                                    })
                                });
                                await this.fetchData();
                            }}
                            type='undo'
                        />
                    ) : undefined;
                }
            }
            /* {
                title: <FormattedMessage id='supplier.show' />,
                key: 'show',
                render: row => {
                    return (
                        <Checkbox
                            checked={row.show}
                            onChange={() => {
                                row.show = !row.show;
                                this.setState({});
                            }}
                        />
                    );
                }
            } */
        ];
    }

    fetchData = async () => {
        const statusSettings = await fetchAPI('GET', 'status_settings');
        this.setState({
            statusSettings
        });
    };

    saveChanges = async () => {
        await fetchAPI(
            'PUT',
            'status_settings',
            undefined,
            this.state.statusSettings.map(({ status, customStatusName, color, seconds, show, order }) => ({
                table: 'DETAILS',
                customStatusName: customStatusName || null,
                status,
                color,
                seconds,
                show,
                order
            }))
        );
        if (isGrantAccessed(this.props.user, grants.SETTINGS_PART_STATUSES, accesses.ROWO)) {
            await fetchAPI(
                'PUT',
                'businesses',
                undefined,
                {
                    alphaName: this.state.alphaName || null
                },
                { handleErrorInternally: true }
            );
        }
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'sms_settings.saved' })
        });
        await this.fetchData();
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI(
            'GET',
            '/helps',
            { helpId: 'directories_and_settings_detail-status-settings' },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            allLinks: links
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { user } = this.props;
        const { statusSettings, helperDrawerOpen, allLinks } = this.state;
        const columns = this.columns();

        const accessSaveButton = isGrantAccessed(user, grants.SETTINGS_PART_STATUSES, accesses.ROWO);

        return (
            <Layout
                controls={
                    <React.Fragment>
                        <Button disabled={!accessSaveButton} onClick={this.saveChanges} type='primary'>
                            <FormattedMessage id='save' />
                        </Button>
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                this.setState({
                                    helperDrawerOpen: true
                                });
                                await this.fetchHelperLinks();
                            }}
                            style={{
                                fontSize: 22,
                                marginLeft: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </React.Fragment>
                }
                title={<FormattedMessage id='detail_status_settings_page.title' />}
            >
                <Table
                    bordered
                    columns={columns}
                    dataSource={statusSettings}
                    pagination={false}
                    rowKey='id'
                    size='small'
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
