import { Modal, Radio, Space } from 'antd';
import { setDocumentTitle, setPrint } from 'core/ui/duck';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { decompressTemplate } from '../../../../../../../../shared/utils/utils';

// import Styles from './styles.m.css';

const printOptions = [
    { label: 'order_form_table.diagnostic.print.print_all', value: 'all' },
    { label: 'order_form_table.diagnostic.print.print_done', value: 'onlyDone' },
    { label: 'order_form_table.diagnostic.print.print_problems', value: 'onlyProblem' }
];

const DiagnosticPrintModal = ({ open, handleClose, orderId, isMobile, token, setPrint, setDocumentTitle }) => {
    const [loading, setLoading] = useState(false);

    const [radioValue, setRadioValue] = useState(printOptions[0].value);

    const handleCancel = () => {
        setLoading(false);
        handleClose();
    };

    const onRadioChange = e => {
        setRadioValue(e.target.value);
    };

    const okOk = async () => {
        const url = `/orders/reports/diagnosticsResultReport/${orderId}`;
        try {
            setLoading(true);
            const response = await fetchAPI(
                'GET',
                url,
                {
                    diagnosticV2type: radioValue
                },
                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true,
                    headers: {
                        Authorization: token
                    }
                }
            );
            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
            setDocumentTitle(fileName);
            const arrayBuffer = await response.arrayBuffer();
            const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
            const reportFile = await decompressTemplate(content);
            await setPrint(reportFile);
            setLoading(false);
            handleCancel();
        } catch (error) {
            setLoading(false);
            console.error('ERROR:', error);
        }
    };

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            okButtonProps={{ loading }}
            onCancel={handleCancel}
            onOk={okOk}
            open={open}
            // style={{ minWidth: 800 }}
            title={<FormattedMessage id='print' />}
            width={isMobile ? '100%' : '35%'}
        >
            <Radio.Group onChange={onRadioChange} value={radioValue}>
                <Space direction='vertical'>
                    {printOptions.map(({ label, value }) => (
                        <Radio key={value} value={value}>
                            <FormattedMessage id={label} />
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </Modal>
    );
};

const mapStateToProps = state => ({
    // print: state.ui.print
    // user: state.auth,
    // isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setPrint,
    setDocumentTitle
};
export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticPrintModal);
