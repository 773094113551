import { Table } from 'antd';
import _ from 'lodash';
import {
    selectFetchingVehicleOrders,
    selectVehicleData,
    selectVehicleOrders,
    selectVehicleOrdersSort,
    selectVehicleOrdersStats,
    setPageOrders
} from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import EditOdometrModal from './EditOdometrModal';
import { columnsConfig } from './config';

const mapStateToProps = state => ({
    user: state.auth,
    orders: selectVehicleOrders(state),
    vehicleData: selectVehicleData(state),
    stats: selectVehicleOrdersStats(state),
    sort: selectVehicleOrdersSort(state),
    fetchingVehicleOrders: selectFetchingVehicleOrders(state)
});

const mapDispatchToProps = {
    setPageOrders
};

const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';

/**
 * Table of vehicle orders
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleOdometrTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            odometrDate: undefined,
            odometrNumber: undefined,
            edit: {},
            openOdometrModal: false
        };
    }

    editOdometr = row => {
        this.setState({
            openOdometrModal: true,
            edit: row
        });
    };

    render() {
        const {
            orders,
            stats,
            sort,
            intl: { formatMessage },
            setPageOrders,
            fetchingVehicleOrders,
            vehicleData,
            fetchVehicleOrdersUpdate,
            fetchVehicle,
            disabled
        } = this.props;

        const { odometrDate, odometrNumber, openOdometrModal, edit } = this.state;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.countOrders / 25) * 25,
            current: sort.page,
            onChange: page => {
                setPageOrders({ page });
            }
        };

        const dataSourceOdometr = _.get(vehicleData, 'customData', []) || [];

        return (
            <React.Fragment>
                <Table
                    bordered
                    columns={columnsConfig({ formatMessage, editOdometr: this.editOdometr, disabled })}
                    dataSource={[...dataSourceOdometr, ...orders]}
                    loading={fetchingVehicleOrders}
                    pagination={false}
                    rowKey='id'
                    scroll={{ y: '60vh' }}
                    size='small'
                />
                <EditOdometrModal
                    edit={edit}
                    fetchVehicle={fetchVehicleOrdersUpdate}
                    hideModal={() =>
                        this.setState({
                            openOdometrModal: false,
                            edit: undefined
                        })
                    }
                    open={openOdometrModal}
                />
            </React.Fragment>
        );
    }
}
