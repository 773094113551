import { setHeaderFetchingState } from 'core/ui/duck';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI, getCookie, setCookie } from 'utils';
import { fetchHeaderDataSuccess, FETCH_HEADER_DATA } from './duck';

export function* headerDataSaga() {
    while (true) {
        try {
            const { payload: force } = yield take(FETCH_HEADER_DATA);
            if (!navigator.cookieEnabled) {
                console.info('Please, turn on your cookies for the proper application workflow!');
            }
            if (force) {
                yield put(setHeaderFetchingState(true));
                // 1h 3600 * 1000(ms)
                const expires = new Date(new Date().getTime() + 1800 * 1000).toUTCString();

                setCookie('_my.carbook.pro_header', 'subscribe', { expires });
                const response = yield call(fetchAPI, 'GET', '/header');
                yield put(fetchHeaderDataSuccess(response));
                yield put(setHeaderFetchingState(false));
            }
            if (!force) {
                if (!getCookie('_my.carbook.pro_header')) {
                    yield put(setHeaderFetchingState(true));
                    // 1h 3600 * 1000(ms)
                    const expires = new Date(new Date().getTime() + 1800 * 1000).toUTCString();

                    setCookie('_my.carbook.pro_header', 'subscribe', {
                        expires
                    });
                    const response = yield call(fetchAPI, 'GET', '/header');
                    yield put(fetchHeaderDataSuccess(response));
                    yield put(setHeaderFetchingState(false));
                }
            }
        } catch (error) {
            console.error('* headerDataSaga error', error);
            throw new Error(error);
        }
    }
}

export function* saga() {
    yield all([call(headerDataSaga)]);
}
