import { DollarCircleOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Select, Tooltip } from 'antd';
import { HamburgerMenu } from 'components';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, getCurrency, isForbidden, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

/* eslint-disable complexity */

const { Option } = Select;

export function tableConfig({
    getColumnSearchProps,
    statuses,
    setModal,
    fetchSalariesList,
    user,
    formatMessage,
    selectedRows,
    openGroupCashOrder
}) {
    const actions = {
        title: () => {
            // const firstValue = get(selectedRows, '[0].status'); // Get the value of the first object's field

            // const allSame = selectedRows && selectedRows.every(obj => obj.status === firstValue);

            // console.log(allSame, 'allSame');

            const actionsMenu = (
                <Menu className={Styles.actionMenuDropdown}>
                    <Menu.SubMenu
                        key='groupSalary.changeStatus'
                        data-qa='sub_menu_group_salary_menu_salary_table'
                        title={
                            <React.Fragment>
                                <EditOutlined className={Styles.actionMenuIcon} />
                                {formatMessage({ id: 'profile.spare_parts_workplace.change_status' })}
                            </React.Fragment>
                        }
                    >
                        <Menu.Item
                            key='salariesActionMenu.changeStatus.active'
                            disabled={
                                !isGrantAccessed(user, grants.ACCOUNTING_SALARIES_DOCUMENT_LIST_SALARY_UNPOSTING) ||
                                selectedRows.find(({ status }) => status === 'NEW')
                            }
                            onClick={async () => {
                                await fetchAPI(
                                    'PUT',
                                    '/salary_docs/',
                                    null,

                                    [
                                        ...selectedRows.map(row => {
                                            return {
                                                id: row.id,
                                                employeeId: row.employeeId,

                                                status: 'NEW'
                                            };
                                        })
                                    ],

                                    { handleErrorInternally: true }
                                );
                                fetchSalariesList();
                            }}
                        >
                            {formatMessage({ id: 'not_complete' })}
                        </Menu.Item>
                        <Menu.Item
                            key='salariesActionMenu.changeStatus.inactive'
                            disabled={selectedRows.find(({ status }) => status === 'DONE')}
                            onClick={async () => {
                                await fetchAPI(
                                    'PUT',
                                    '/salary_docs',
                                    null,
                                    [
                                        ...selectedRows.map(row => {
                                            return {
                                                id: row.id,
                                                employeeId: row.employeeId,
                                                status: 'DONE'
                                            };
                                        })
                                    ],

                                    { handleErrorInternally: true }
                                );
                                fetchSalariesList();
                            }}
                        >
                            {formatMessage({ id: 'order_docs.status_done' })}
                        </Menu.Item>

                        <Menu.Item
                            key='salariesActionMenu.changeStatus.cancel'
                            disabled={selectedRows.find(({ status }) => status === 'CANCEL')}
                            onClick={async () => {
                                await fetchAPI(
                                    'PUT',
                                    '/salary_docs',
                                    null,
                                    [
                                        ...selectedRows.map(row => {
                                            return {
                                                id: row.id,
                                                employeeId: row.employeeId,
                                                status: 'CANCEL'
                                            };
                                        })
                                    ],

                                    { handleErrorInternally: true }
                                );
                                fetchSalariesList();
                            }}
                        >
                            {formatMessage({ id: 'employee_salary_rules.status.CANCEL' })}
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item
                        key='salariesActionMenu.payment'
                        disabled={!isGrantAccessed(user, grants.ACCOUNTING_SALARIES_DOCUMENT_LIST_PAYMENT)}
                        onClick={() => {
                            openGroupCashOrder();
                        }}
                    >
                        <div>
                            <DollarCircleOutlined className={Styles.actionMenuIcon} />
                            {formatMessage({ id: 'navigation.payment' })}
                        </div>
                    </Menu.Item>
                </Menu>
            );

            return <HamburgerMenu actionsMenu={actionsMenu} disabled={!get(selectedRows, 'length')} />;
        },
        key: 'actions',
        align: 'center',
        width: '10%',
        render: row => {
            const payout =
                row.employeeIsNettoSalary && !row.isBrutto
                    ? row.nettoPayoutSum
                    : row.isBrutto || !row.employeeIsNettoSalary
                    ? row.bruttoPayoutSum
                    : 0;

            const actionsMenu = () => (
                <Menu>
                    <Menu.SubMenu
                        key='salariesActionMenu.changeStatus'
                        data-qa='sub_menu_group_salary_menu_salary_table'
                        disabled={row.status == 'CANCEL' || isForbidden(user, permissions.CREATE_EDIT_DELETE_EMPLOYEES)}
                        title={
                            <React.Fragment>
                                <EditOutlined className={Styles.actionMenuIcon} />
                                {formatMessage({ id: 'profile.spare_parts_workplace.change_status' })}
                            </React.Fragment>
                        }
                    >
                        <Menu.Item
                            key='salariesActionMenu.changeStatus.active'
                            disabled={!isGrantAccessed(user, grants.ACCOUNTING_SALARIES_DOCUMENT_LIST_SALARY_UNPOSTING)}
                            onClick={async () => {
                                await fetchAPI(
                                    'PUT',
                                    `/salary_doc/${row.id}`,
                                    null,
                                    {
                                        employeeId: row.employeeId,
                                        startDate: row.startDate,
                                        endDate: row.endDate,
                                        accountingDate: row.accountingDate || row.endDate,
                                        doneDate: row.creationDate,
                                        status: 'NEW',
                                        type: row.type,

                                        incomeTax: row.incomeTax || 0,
                                        insuranceTax: row.insuranceTax || 0,
                                        otherTaxes: row.otherTaxes || 0,
                                        comment: row.comment || ' '
                                    },
                                    { handleErrorInternally: true }
                                );
                                fetchSalariesList();
                            }}
                        >
                            {formatMessage({ id: 'not_complete' })}
                        </Menu.Item>
                        <Menu.Item
                            key='salariesActionMenu.changeStatus.inactive'
                            onClick={async () => {
                                await fetchAPI(
                                    'PUT',
                                    `/salary_doc/${row.id}`,
                                    null,
                                    {
                                        employeeId: row.employeeId,
                                        startDate: row.startDate,
                                        endDate: row.endDate,
                                        accountingDate: row.accountingDate || row.endDate,
                                        doneDate: row.creationDate,
                                        status: 'DONE',
                                        type: row.type,

                                        incomeTax: row.incomeTax || 0,
                                        insuranceTax: row.insuranceTax || 0,
                                        otherTaxes: row.otherTaxes || 0,
                                        comment: row.comment || ' '
                                    },
                                    { handleErrorInternally: true }
                                );
                                fetchSalariesList();
                            }}
                        >
                            {formatMessage({ id: 'order_docs.status_done' })}
                        </Menu.Item>
                        {row.status !== 'DONE' && (
                            <Menu.Item
                                key='salariesActionMenu.changeStatus.cancel'
                                onClick={async () => {
                                    await fetchAPI(
                                        'PUT',
                                        `/salary_doc/${row.id}`,
                                        null,
                                        {
                                            employeeId: row.employeeId,
                                            startDate: row.startDate,
                                            endDate: row.endDate,
                                            accountingDate: row.accountingDate || row.endDate,
                                            doneDate: row.creationDate,
                                            status: 'CANCEL',
                                            type: row.type,

                                            incomeTax: row.incomeTax || 0,
                                            insuranceTax: row.insuranceTax || 0,
                                            otherTaxes: row.otherTaxes || 0,
                                            comment: row.comment || ' '
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    fetchSalariesList();
                                }}
                            >
                                {formatMessage({ id: 'employee_salary_rules.status.CANCEL' })}
                            </Menu.Item>
                        )}
                    </Menu.SubMenu>

                    <Menu.Item
                        key='salariesActionMenu.payment'
                        disabled={!isGrantAccessed(user, grants.ACCOUNTING_SALARIES_DOCUMENT_LIST_PAYMENT)}
                        onClick={() => {
                            setModal(MODALS.REFACTORED_CASH_ORDER, {
                                initValues: {
                                    type: CASH_ORDER_TYPES.EXPENSE,
                                    counterpartyType: COUNTERPARTY_TYPES.EMPLOYEE,
                                    employeeId: row.employeeId,
                                    analyticsUniqueId: 31,
                                    comment: row.document,
                                    salary: true,
                                    salaryDocId: row.id,
                                    sum: payout + row.amountLeft < 0 ? 0 : payout + row.amountLeft
                                }
                            });
                            fetchSalariesList();
                        }}
                    >
                        <div>
                            <DollarCircleOutlined className={Styles.actionMenuIcon} />
                            {formatMessage({ id: 'navigation.payment' })}
                        </div>
                    </Menu.Item>
                </Menu>
            );

            return (
                <HamburgerMenu actionsMenu={actionsMenu}>
                    <Button data-qa='btn_show_hamburger_menu_modal_salaries_table' icon={<MenuOutlined />} />
                </HamburgerMenu>
            );
        }
    };

    const docNumber = {
        title: <FormattedMessage id='storage.document_number' />,
        width: '20%',
        dataIndex: 'document',
        key: 'supplierDocNumber',
        ...getColumnSearchProps('documentNum'),
        render: (data, document) => (
            <Link
                to={{
                    pathname: `${book.salariesPage}/${document.id}`,
                    state: {
                        docNameStatus: `${document.status}`
                    }
                }}
            >
                <div>
                    <div>{data}</div>
                    <div>{document.comment || '-'}</div>
                </div>
            </Link>
        )
    };

    const beginDate = {
        title: <FormattedMessage id='universal_filters_tags.beginDate' />,
        dataIndex: 'creationDate',
        key: 'creationDate',
        width: '8%',
        render: (data, document) => <div>{dayjs(data).format('DD.MM.YYYY') || <FormattedMessage id='long_dash' />}</div>
    };

    const docDate = {
        title: <FormattedMessage id='order_from_table_date_doc' />,
        dataIndex: 'accountingDate',
        key: 'accountingDate',
        width: '8%',
        ...getColumnSearchProps('accountingDate'),
        render: (data, document) => (
            <div>
                {data !== null
                    ? dayjs(data).format('DD.MM.YYYY')
                    : dayjs(document.creationDate).format('DD.MM.YYYY') || <FormattedMessage id='long_dash' />}
            </div>
        )
    };

    const employeePosition = {
        title: () => (
            <div>
                <FormattedMessage id='cash-order-form.counterparty.EMPLOYEE' />/
                <FormattedMessage id='position' />
            </div>
        ),
        key: 'employee',
        width: '15%',
        ...getColumnSearchProps('jobTitle'),
        render: (_, document) => {
            const positionsList = document.jobTitle ? document.jobTitle.split(', ') : null;
            const positions = positionsList !== null ? positionsList : [] || undefined;

            return (
                <div>
                    <div>
                        {isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEES_LIST)
                            ? <Link to={`${book.employeesPage}/${document.employeeId}`}>{document.employee}</Link> || (
                                  <FormattedMessage id='long_dash' />
                              )
                            : document.employee}
                        {positions ? ',' : ''}
                    </div>
                    <div>
                        {positions.map((position, index) => (
                            <span key={index}>
                                <FormattedMessage id={`employee_salary_rules.${position}`} />
                                {index < positions.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </div>
                </div>
            );
        }
    };

    const period = {
        title: <FormattedMessage id='setting-salary.period' />,
        key: 'sum',
        width: '10%',
        ...getColumnSearchProps('dateRange'),
        render: (data, document) => (
            <div>
                {`${dayjs(document.startDate).format('DD.MM.YYYY')} - ${dayjs(document.endDate).format('DD.MM.YYYY')}`}
            </div>
        )
    };

    const totalAccrued = {
        title: <FormattedMessage id='employee_salary_rules.total_accrued' />,
        key: 'totalAccrued',
        width: '5%',
        render: (data, document) => {
            const total = data.totalAccrualSum;

            const totalToFixed = Number(total || 0).toFixed(2);

            return (
                <div>
                    {totalToFixed} {getCurrency()}
                </div>
            );
        }
    };

    const penalty = {
        title: <FormattedMessage id='employee_salary_rules.PENALTY' />,

        key: 'penalty',
        width: '5%',
        render: (data, document) => {
            const sum = Number(data || 0).toFixed(2);

            const penalty =
                data.employeeIsNettoSalary && !data.isBrutto
                    ? data.nettoDeductionsTotalSum
                    : data.isBrutto || !data.employeeIsNettoSalary
                    ? data.bruttoDeductionsTotalSum
                    : 0;

            const penaltyToFixed = Number(penalty || 0).toFixed(2);

            return (
                <div>
                    {penaltyToFixed} {getCurrency()}
                </div>
            );
        }
    };

    const payment = {
        title: <FormattedMessage id='employee_salary_rules.payment' />,
        key: 'payment',
        width: '5%',
        render: (data, document) => {
            const payout =
                data.employeeIsNettoSalary && !data.isBrutto
                    ? data.nettoPayoutSum
                    : data.isBrutto || !data.employeeIsNettoSalary
                    ? data.bruttoPayoutSum
                    : 0;

            const payment = Number(payout || 0).toFixed(2);

            return (
                <div>
                    {payment} {getCurrency()}
                </div>
            );
        }
    };

    const remaining = {
        title: <FormattedMessage id='remain' />,
        key: 'remain',
        width: '5%',
        ...getColumnSearchProps('amountLeftAboveZero'),

        render: (data, row) => {
            const payout =
                data.employeeIsNettoSalary && !data.isBrutto
                    ? data.nettoPayoutSum
                    : data.isBrutto || !data.employeeIsNettoSalary
                    ? data.bruttoPayoutSum
                    : 0;

            const total = payout + data.amountLeft;

            return (
                <div>
                    {Number(total || 0).toFixed(2)} {getCurrency()}
                </div>
            );
        }
    };

    const taxes = {
        title: <FormattedMessage id='employee_salary_rules.taxes' />,
        key: 'sellingSum',
        width: '5%',
        render: (data, row) => {
            const taxes =
                data.employeeIsNettoSalary && !data.isBrutto
                    ? data.nettoTotalTaxSum
                    : data.isBrutto || !data.employeeIsNettoSalary
                    ? data.bruttoTotalTaxSum
                    : 0;

            const taxesToFixed = Number(taxes || 0).toFixed(2);

            return (
                <div>
                    {taxesToFixed} {getCurrency()}
                </div>
            );
        }
    };

    const updatedStatus = {
        title: <FormattedMessage id='order_form_table.status' />,
        key: 'status',
        dataIndex: 'status',
        ...getColumnSearchProps('status'),
        width: '10%',
        render: (data, row) => {
            const menu = (
                <Menu>
                    {row.status == 'DONE'
                        ? (isGrantAccessed(user, grants.ACCOUNTING_SALARIES_DOCUMENT_LIST_SALARY_UNPOSTING)
                              ? statuses
                              : []
                          )
                              .filter(({ status }) => status !== 'CANCEL')
                              .map(({ status, customStatusName }) => (
                                  <Menu.Item
                                      key={status}
                                      disabled={
                                          row.status == 'CANCEL' ||
                                          isForbidden(user, permissions.CREATE_EDIT_DELETE_EMPLOYEES)
                                      }
                                      onClick={async () => {
                                          await fetchAPI(
                                              'PUT',
                                              `/salary_doc/${row.id}`,
                                              null,
                                              {
                                                  employeeId: row.employeeId,
                                                  startDate: row.startDate,
                                                  endDate: row.endDate,
                                                  accountingDate: row.accountingDate || row.endDate,
                                                  doneDate: row.creationDate,
                                                  status,
                                                  type: row.type,
                                                  isBrutto: row.isBrutto || false,
                                                  incomeTax: row.incomeTax || 0,
                                                  insuranceTax: row.insuranceTax || 0,
                                                  otherTaxes: row.otherTaxes || 0,
                                                  comment: row.comment || ' '
                                              },
                                              { handleErrorInternally: true }
                                          );
                                          fetchSalariesList();
                                      }}
                                  >
                                      <FormattedMessage id={`employee_salary_rules.status.${status}`} />
                                  </Menu.Item>
                              ))
                        : statuses.map(({ status, customStatusName }) => (
                              <Menu.Item
                                  key={status}
                                  disabled={
                                      row.status == 'CANCEL' ||
                                      isForbidden(user, permissions.CREATE_EDIT_DELETE_EMPLOYEES)
                                  }
                                  onClick={async () => {
                                      await fetchAPI(
                                          'PUT',
                                          `/salary_doc/${row.id}`,
                                          null,
                                          {
                                              employeeId: row.employeeId,
                                              startDate: row.startDate,
                                              endDate: row.endDate,
                                              accountingDate: row.accountingDate || row.endDate,
                                              doneDate: row.creationDate,
                                              status,
                                              type: row.type,
                                              isBrutto: row.isBrutto || false,
                                              incomeTax: row.incomeTax || 0,
                                              insuranceTax: row.insuranceTax || 0,
                                              otherTaxes: row.otherTaxes || 0,
                                              comment: row.comment || ' '
                                          },
                                          { handleErrorInternally: true }
                                      );
                                      fetchSalariesList();
                                  }}
                              >
                                  <FormattedMessage id={`employee_salary_rules.status.${status}`} />
                              </Menu.Item>
                          ))}
                </Menu>
            );

            return (
                <Dropdown data-qa='dropdown_current_salries_table' overlay={menu} trigger={['click']}>
                    <Tooltip title={<FormattedMessage id={`employee_salary_rules.status.${data}`} />}>
                        <div
                            style={{
                                border: `2px solid ${data === 'NEW' ? 'orange' : data === 'DONE' ? 'green' : 'red'}`,
                                padding: '6px 2px',
                                textAlign: 'center',
                                fontWeight: 500,
                                borderRadius: 6
                            }}
                        >
                            <FormattedMessage id={`employee_salary_rules.status.${data}`} />
                        </div>
                    </Tooltip>
                </Dropdown>
            );
        }
    };

    return [
        actions,
        docNumber,
        beginDate,
        docDate,
        employeePosition,
        period,
        totalAccrued,
        taxes,
        penalty,
        payment,
        remaining,
        updatedStatus
    ];
}
