import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Collapse, Flex, Input, InputNumber, Modal, Select, TreeSelect, notification } from 'antd';
import _, { get } from 'lodash';
import { getAccountsList } from 'pages/AccountPlanPage/utils/getAccountsList';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const BalanceManagingModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        edit,
        getBalance,
        isMobile
    } = props;

    const recommendationAutoSize = { minRows: 2, maxRows: 6 };

    const [accountBookId, setAccountBookId] = useState(undefined);
    const [lastValue, setLastValue] = useState();
    const [rowCode, setRowCode] = useState();
    const [formula, setFormula] = useState([]);
    const [formulaString, setFormulaString] = useState('');
    const [amountSignature, setAmountSignature] = useState(undefined);
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState(undefined);
    const [rowId, setRowId] = useState(undefined);
    const [operationId, setOperationId] = useState(undefined);
    const [tree, setTree] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [operands, setOperands] = useState([]);
    const [accountType, setAccountType] = useState();
    const [expression, setExpression] = useState([]);

    const filterTreeByActive = useCallback(nodes => {
        return nodes.reduce((filteredNodes, node) => {
            if (node.active === true) {
                filteredNodes.push({
                    value: node.id,
                    name: node.name,
                    title: `(#${node.id}) ${node.name}`
                });

                if (node.children) {
                    const filteredChildren = filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNodes[filteredNodes.length - 1].children = filteredChildren;
                    }
                }
            }

            setTree(filteredNodes);

            return filteredNodes;
        }, []);
    });

    useEffect(() => {
        if (!get(tree, 'length') && get(accounts, 'length')) {
            filterTreeByActive(accounts);
        }
    }, [tree, accounts, filterTreeByActive]);

    useEffect(() => {
        const fetchAccounts = async query => {
            const accounts = await getAccountsList(query);
            setAccounts(accounts);
        };
        if (open) {
            fetchAccounts();
        }
    }, [open]);

    // useEffect(() => {
    //     if (edit) {
    //         setAccountBookId(edit.accountBookId);
    //         setAnalyticsId(edit.analyticsId);
    //         setFormula(edit.formula);
    //         setAmountSignature(edit.amountSignature);
    //         setComment(edit.comment);
    //         setOperationType(edit.operationType);
    //         setRowId(edit.id);
    //         setOperationId(edit.operationId);
    //     }
    // }, [edit]);

    const AddFormula = async () => {
        const exp = expression && expression.map(obj => obj.value).join('');

        try {
            await fetchAPI(
                'POST',
                'account/book/settings',
                undefined,
                {
                    accountBookId: open,
                    expression: exp,
                    rawExpression: formulaString,
                    operands
                },
                { handleErrorInternally: true }
            );
            await getBalance();
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const handleAddToFormula = useCallback(
        value => {
            const str = formula.length < 1 ? `${value}` : ` ${value}`;

            setFormula([...formula, { value: str }]);
        },
        [formula]
    );

    const handleAddToExpression = useCallback(
        value => {
            const str = expression.length < 1 ? `${value}` : ` ${value}`;

            setExpression([...expression, { value: str }]);
        },
        [expression]
    );

    useEffect(() => {
        setFormulaString(formula && formula.map(obj => obj.value).join(''));
    }, [formula]);

    useEffect(() => {
        setLastValue(formula && formula[get(formula, 'length') - 1]);
    }, [formula]);

    const removeFromFormula = useCallback(() => {
        const operators = [' +', ' -', ' *', ' /', ' (', ' )'];
        const lastValue = formula[get(formula, 'length') - 1].value;

        const isInclude = operators.some(op => lastValue === op);

        if (!isInclude) {
            operands.pop();
        }

        formula.pop();
        expression.pop();

        setFormula([...formula]);
        setExpression([...expression]);
        setOperands([...operands]);
    }, [expression, formula, operands]);

    // const removeFromFormula = () => {
    //     const index = formula.lastIndexOf(' ');

    //     setFormula(formula.substring(0, index));
    // };

    const handleAddCodeToFormula = useMemo(() => {
        const loadOptions = value => {
            if (value) {
                handleAddToFormula(`Код рядка ${value}`);
                handleAddToExpression(`B${value}`);
                setRowCode(undefined);
                setOperands([
                    ...operands,
                    {
                        operandKey: String(value),
                        operandType: 'CODE',
                        operandPrefixKey: `B${value}`,
                        operation: null
                    }
                ]);
            }
        };

        return _.debounce(loadOptions, 1000);
    }, [handleAddToExpression, handleAddToFormula, operands]);

    // useEffect(() => {
    //     if (edit) {
    //         setName(edit.name);
    //         setDocType(edit.docType);
    //         setOperationType(edit.operationType);
    //         setComment(edit.comment);
    //     }
    // }, [edit]);

    const onClose = () => {
        setAccountBookId(undefined);
        setRowCode(undefined);
        setFormula([]);
        setFormulaString(undefined);
        setOperands([]);
        setExpression([]);

        hideModal();
        setTimeout(() => getBalance(), 100);
    };

    const onFinish = async () => {
        AddFormula();

        await onClose();
    };

    const disabled =
        accountBookId && !accountType
            ? !accountBookId
            : !accountBookId && accountType
            ? !accountType
            : !accountBookId || !accountType;

    const operators = ['+', '-', '*', '/'];

    console.log(lastValue, 'last');

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={
                !edit ? (
                    <FormattedMessage id='account_plan.balance.create_formula' />
                ) : (
                    <FormattedMessage id='account_plan.balance.edit_formula' />
                )
            }
            width={isMobile ? '95%' : '50%'}
        >
            <div className={Styles.divGroup}>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='entries_tab.rows.formula' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Input.TextArea
                        autoSize={recommendationAutoSize}
                        onChange={e => {
                            setFormula(e.target.value);
                        }}
                        placeholder={formatMessage({
                            id: 'entries_tab.rows.formula'
                        })}
                        readOnly
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={formulaString}
                    />
                </div>
                <Flex gap={8} justify='space-between' wrap='wrap'>
                    <TreeSelect
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        listHeight={440}
                        onSelect={(value, option) => {
                            Modal.confirm({
                                title: formatMessage({
                                    id: 'account_plan.balance.account_type'
                                }),
                                width: '50%',
                                content: (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around'
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setAccountType('DEBET');
                                                handleAddToFormula(`Дебет рахунка ${option.title}`);
                                                setAccountBookId(value);
                                                setOperands([
                                                    ...operands,
                                                    {
                                                        operandKey: String(value),
                                                        operandType: 'ACCOUNT',
                                                        operandPrefixKey: `ADB${value}`,
                                                        operation: 'DEBET'
                                                    }
                                                ]);
                                                handleAddToExpression(`ADB${value}`);

                                                Modal.destroyAll();
                                            }}
                                        >
                                            {formatMessage({
                                                id: 'account_plan.debit'
                                            })}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setAccountType('CREDIT');
                                                handleAddToFormula(`Кредит рахунка ${option.title}`);
                                                setAccountBookId(value);
                                                setOperands([
                                                    ...operands,
                                                    {
                                                        operandKey: String(value),
                                                        operandType: 'ACCOUNT',
                                                        operandPrefixKey: `ACB${value}`,
                                                        operation: 'CREDIT'
                                                    }
                                                ]);
                                                handleAddToExpression(`ACB${value}`);

                                                Modal.destroyAll();
                                            }}
                                        >
                                            {' '}
                                            {formatMessage({
                                                id: 'account_plan.credit'
                                            })}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setAccountType('DEBET_BALANCE');
                                                handleAddToFormula(`Оборот дебету рахунка ${option.title}`);
                                                setAccountBookId(value);
                                                setOperands([
                                                    ...operands,
                                                    {
                                                        operandKey: String(value),
                                                        operandType: 'ACCOUNT',
                                                        operandPrefixKey: `AD${value}`,
                                                        operation: 'DEBET_BALANCE'
                                                    }
                                                ]);
                                                handleAddToExpression(`AD${value}`);

                                                Modal.destroyAll();
                                            }}
                                        >
                                            {' '}
                                            {formatMessage({
                                                id: 'account_plan.debit_balance'
                                            })}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setAccountType('CREDIT_BALANCE');
                                                handleAddToFormula(`Оборот кредиту рахунка ${option.title}`);
                                                setAccountBookId(value);
                                                setOperands([
                                                    ...operands,
                                                    {
                                                        operandKey: String(value),
                                                        operandType: 'ACCOUNT',
                                                        operandPrefixKey: `AC${value}`,
                                                        operation: 'CREDIT_BALANCE'
                                                    }
                                                ]);
                                                handleAddToExpression(`AC${value}`);

                                                Modal.destroyAll();
                                            }}
                                        >
                                            {' '}
                                            {formatMessage({
                                                id: 'account_plan.credit_balance'
                                            })}
                                        </Button>
                                    </div>
                                ),
                                footer: null,
                                okType: 'primary'
                            });
                        }}
                        placeholder={
                            <React.Fragment>
                                <FormattedMessage id='receipt_document_modal.bill' />
                            </React.Fragment>
                        }
                        showSearch
                        style={{ color: 'var(--text)', width: !isMobile ? 280 : '100%' }}
                        treeData={tree}
                        treeNodeFilterProp={(input, node) => {
                            return node.active;
                        }}
                        value={accountBookId}
                    />
                    <InputNumber
                        min={0}
                        onChange={async value => {
                            setRowCode(value);
                            await handleAddCodeToFormula(value);
                        }}
                        onStep={async value => {
                            setRowCode(value);
                            await handleAddCodeToFormula(value);
                        }}
                        parser={value => value.replace(/[^\d]/g, '')}
                        placeholder={formatMessage({ id: 'code_row' })}
                        step={1}
                        style={{
                            width: '50%'
                        }}
                        value={rowCode}
                    />
                    {['+', '-', '*', '/'].map(elem => (
                        <Button
                            disabled={
                                (lastValue && operators.some(op => lastValue.value === op)) ||
                                (lastValue && operators.some(op => lastValue.value === ` ${op}`))
                            }
                            onClick={() => {
                                handleAddToFormula(elem);
                                setAccountBookId(undefined);
                                handleAddToExpression(elem);
                            }}
                            style={{
                                flex: '1 1 auto'
                            }}
                        >
                            {elem}
                        </Button>
                    ))}
                    {['(', ')'].map(elem => (
                        <Button
                            onClick={() => {
                                handleAddToFormula(elem);
                                setAccountBookId(undefined);
                                handleAddToExpression(elem);
                            }}
                            style={{
                                flex: '1 1 auto'
                            }}
                        >
                            {elem}
                        </Button>
                    ))}

                    <Button icon={<ArrowLeftOutlined />} onClick={removeFromFormula} />
                </Flex>
            </div>
        </Modal>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BalanceManagingModal));
