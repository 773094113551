import { Layout } from 'commons';
import { BrandsForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export default class BrandsPage extends Component {
    render() {
        return (
            <Layout title={<FormattedMessage id='navigation.priority_brands' />}>
                <BrandsForm />
            </Layout>
        );
    }
}
