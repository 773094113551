import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Numeral } from 'commons';
import { OrderStatusIcon } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    order: 'auto',
    order_status: '10%',
    begin_datetime: '10%',
    odometer: '7%',
    sum: '10%',
    remaining_sum: '10%',
    client_vehicle: '20%',
    responsible: '15%'
};

const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';

export function columnsConfig({ editOdometr, disabled }) {
    const orderCol = {
        title: <FormattedMessage id='navigation.source' />,
        dataIndex: 'num',
        key: 'num',
        width: '30%',
        render: (val, order) => (
            <React.Fragment>
                {order.num ? (
                    <Link className={Styles.orderLink} to={`${book.order}/${order.id}`}>
                        {order.num}
                    </Link>
                ) : (
                    <FormattedMessage id='orders.manually_data' />
                )}

                {order.num && <OrderStatusIcon status={order.status} />}

                {order.serviceNames && (
                    <div className={Styles.serviceNames}>{[...new Set(order.serviceNames)].join(', ')}</div>
                )}

                {order.recommendation && <div className={Styles.recommendation}>{order.recommendation}</div>}

                {(order.cancelReason || order.cancelStatusReason || order.cancelStatusOwnReason) && (
                    <div className={Styles.cancelReason}>
                        <div>{order.cancelStatusReason}</div>
                        <div>{order.cancelStatusOwnReason}</div>
                    </div>
                )}
            </React.Fragment>
        )
    };

    const beginDatetimeCol = {
        title: <FormattedMessage id='date' />,
        dataIndex: 'beginDatetime',
        key: 'beginDatetime',
        width: '30%',
        render: (val, order) => (
            <div className={Styles.datetime}>
                {order.beginDatetime
                    ? dayjs(order.beginDatetime).format(DATETIME_FORMAT)
                    : dayjs(order.date).format(DATETIME_FORMAT)}
            </div>
        )
    };

    const odometerCol = {
        title: <FormattedMessage id='orders.odometer' />,
        dataIndex: 'odometer',
        key: 'odometer',
        width: '30%',
        render: (val, order) => {
            console.log(_.get(order, 'ordersMileage', []).join(','), 'milage');

            return (
                <div>
                    {order.mileage ? (
                        <Numeral mask='0,0'>{Number(order.mileage)}</Numeral>
                    ) : (
                        _.get(order, 'ordersMileage', [])
                            .map(item => `${item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`)
                            .join(', ')
                    )}
                </div>
            );
        }
    };

    const editCol = {
        key: 'edit',
        width: '5%',
        align: 'center',
        render: row => {
            return (
                !row.num && (
                    <div>
                        <Button
                            icon={<EditOutlined />}
                            disabled={disabled}
                            onClick={() => editOdometr(row)}
                            style={{
                                fontSize: 18
                            }}
                        />
                    </div>
                )
            );
        }
    };

    return [orderCol, beginDatetimeCol, odometerCol, editCol];
}
