/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import { DeleteOutlined, FieldTimeOutlined, GroupOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Popconfirm, Select, TimePicker, Tooltip } from 'antd';
import { OrderStatusIcon } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

/* eslint-disable complexity */
export function columnsConfig(
    props,
    state,
    formatMessage,
    onDelete,
    bodyUpdateIsForbidden,
    handleOpenModal,
    handleOpenModalGroupAdd
) {
    const _getDefaultValue = (key, fieldName) => {
        const orderStationLoads = (props.stationLoads || [])[key];
        if (!orderStationLoads) {
            return;
        }

        const fields = {
            status: orderStationLoads.status,
            beginDate: dayjs(orderStationLoads.beginDatetime),
            beginTime: dayjs(orderStationLoads.beginDatetime),
            stationNum: orderStationLoads.stationNum,
            duration: orderStationLoads.duration
        };

        return fields[fieldName];
    };

    const { initialStation } = props;
    const { initialBeginDatetime } = props;

    const statusIconCol = {
        title: () => (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around'
                }}
            >
                <Tooltip title={<FormattedMessage id='dashboard-page.title.stations_long_repair' />}>
                    <Button icon={<FieldTimeOutlined />} onClick={() => handleOpenModal()} />
                </Tooltip>
                <Tooltip title={<FormattedMessage id='dashboard-page.title.stations_group_add_repair' />}>
                    <Button icon={<GroupOutlined />} onClick={() => handleOpenModalGroupAdd()} />
                </Tooltip>
            </div>
        ),
        align: 'center',
        key: 'orderStationStatusIcon',
        render: ({ key }) => <OrderStatusIcon status={_getDefaultValue(key, 'status') || 'TO_DO'} />
    };

    const isAccessCRUD = isGrantAccessed(props.user, grants.OPERATIONS_ORDER_DOCUMENT_POSTS, accesses.ROWO);

    const statusCol = {
        title: <FormattedMessage id='status' />,
        key: 'orderStationStatus',
        render: ({ key }) => (
            <Form.Item
                disabled={!isAccessCRUD}
                initialValue={_getDefaultValue(key, 'status') || 'TO_DO'}
                name={['stationLoads', key, 'status']}
            >
                <Select data-qa='select_todo_stations_table_order_page' disabled={props.disabled || !isAccessCRUD}>
                    <Option key='TO_DO' value='TO_DO'>
                        <FormattedMessage id='order_form_table.TO_DO' />
                    </Option>
                    <Option key='COMPLETED' value='COMPLETED'>
                        <FormattedMessage id='order_form_table.COMPLETED' />
                    </Option>
                </Select>
            </Form.Item>
        )
    };

    const dateCol = {
        title: <FormattedMessage id='date' />,
        key: 'orderStationDate',
        render: ({ key }) => (
            <Form.Item
                disabled={!isAccessCRUD}
                initialValue={_getDefaultValue(key, 'beginDate') || (key === 0 ? initialBeginDatetime : void 0)}
                name={['stationLoads', key, 'beginDate']}
            >
                <DatePicker
                    allowClear={false}
                    data-qa='datepicker_stations_table_order_page'
                    disabled={props.disabled || !isAccessCRUD}
                    format='YYYY-MM-DD'
                    placeholder={formatMessage({
                        id: 'add_order_form.select_date'
                    })}
                    showTime={false}
                />
            </Form.Item>
        )
    };

    const stationCol = {
        title: <FormattedMessage id='orders.post' />,
        key: 'orderStationNum',
        render: ({ key }) => (
            <Form.Item
                disabled={!isAccessCRUD}
                initialValue={_getDefaultValue(key, 'stationNum') || (key === 0 ? initialStation : void 0)}
                name={['stationLoads', key, 'station']}
            >
                <Select
                    data-qa='select_stations_stations_table_order_page'
                    disabled={bodyUpdateIsForbidden() || props.disabled || !isAccessCRUD}
                    placeholder={formatMessage({
                        id: 'add_order_form.select_station'
                    })}
                >
                    {props.stations.map(({ name, num }) => {
                        return (
                            <Option key={String(num)} value={num}>
                                {name || String(num)}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        )
    };

    const timeCol = {
        title: <FormattedMessage id='time' />,
        key: 'orderStationTime',
        render: ({ key }) => (
            <Form.Item
                disabled={!isAccessCRUD}
                initialValue={_getDefaultValue(key, 'beginTime') || (key === 0 ? initialBeginDatetime : void 0)}
                name={['stationLoads', key, 'beginTime']}
            >
                <TimePicker
                    allowClear={false}
                    data-qa='timepicker_stations_table_order_page'
                    disabled={
                        bodyUpdateIsForbidden() ||
                        props.disabled ||
                        !_.get(props, ['fields', 'stationLoads', key, 'beginDate']) ||
                        !_.get(props, ['fields', 'stationLoads', key, 'station']) ||
                        !isAccessCRUD
                    }
                    format='HH:mm'
                    hideDisabledOptions
                    minuteStep={30}
                    placeholder={formatMessage({
                        id: 'add_order_form.provide_time'
                    })}
                />
            </Form.Item>
        )
    };

    const durationCol = {
        title: <FormattedMessage id='order_form_table.duration' />,
        key: 'orderStationDuration',
        render: ({ key }) => (
            <Form.Item
                disabled={!isAccessCRUD}
                initialValue={_getDefaultValue(key, 'duration') || 0.5}
                name={['stationLoads', key, 'duration']}
            >
                <Select
                    data-qa='select_orderStationDuration_stations_table_order_page'
                    disabled={bodyUpdateIsForbidden() || props.disabled || !isAccessCRUD}
                    options={_(Array.from(Array(13).keys()))
                        .map(option => [
                            {
                                value: option
                            },
                            {
                                value: option + 0.5
                            }
                        ])
                        .flatten()
                        .value()}
                />
            </Form.Item>
        )
    };

    const deleteCol = {
        title: '',
        key: 'delete',
        render: ({ key }) => {
            return (
                !props.disabled &&
                state.keys.length > 2 &&
                _.first(state.keys) !== key &&
                _.last(state.keys) !== key && (
                    <Popconfirm
                        disabled={!isAccessCRUD}
                        onConfirm={() => onDelete(key)}
                        title={<FormattedMessage id='add_order_form.delete_confirm' />}
                    >
                        <DeleteOutlined className={Styles.deleteIcon} disabled={!isAccessCRUD} />
                    </Popconfirm>
                )
            );
        }
    };

    return [statusIconCol, statusCol, dateCol, stationCol, timeCol, durationCol, deleteCol];
}
