import _ from 'lodash';
import { mainModuleName } from 'modals/RefactoredCashOrderModal/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'cashboxes';
const prefix = `cpb/refactoredCashOrderModal/${moduleName}`;

export const FETCH_CASHBOXES = `${prefix}/FETCH_CASHBOXES`;
export const FETCH_CASHBOXES_SUCCESS = `${prefix}/FETCH_CASHBOXES_SUCCESS`;
export const SET_CASHBOXES_FILTERS = `${prefix}/SET_CASHBOXES_FILTERS`;
export const SET_FETCHING_CASHBOXES = `${prefix}/SET_FETCHING_CASHBOXES`;

export const SET_SELECTED_CASHBOX_ID = `${prefix}/SET_SELECTED_CASHBOX_ID`;

export const CLEAR_CASHBOXES_STATE = `${prefix}/CLEAR_CASHBOXES_STATE`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    cashboxes: [],
    fetchingCashboxes: false,

    selectedCashboxId: undefined
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_CASHBOXES_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_FETCHING_CASHBOXES:
            return {
                ...state,
                fetchingCashboxes: payload
            };

        case SET_SELECTED_CASHBOX_ID:
            return {
                ...state,
                selectedCashboxId: payload
            };
        case CLEAR_CASHBOXES_STATE:
            return ReducerState;

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectCashboxes = state => state[mainModuleName][moduleName].cashboxes;
export const selectFetchingCashboxes = state => state[mainModuleName][moduleName].fetchingCashboxes;
export const selectSelectedCashboxId = state => state[mainModuleName][moduleName].selectedCashboxId;

/**
 * If cash box is selected, then it will return its object
 * @param {*} currently selected cashbox object or undefined
 */
export const selectSelectedCashbox = state => {
    const selectedCashBoxId = state[mainModuleName][moduleName].selectedCashboxId;
    const { cashboxes } = state[mainModuleName][moduleName];

    return _.get(
        _.filter(cashboxes, cashbox => cashbox.id == selectedCashBoxId),
        '[0]'
    );
};

/* ------------------------------------- Action Creators -------------------------- */
export const fetchCashboxes = () => ({
    type: FETCH_CASHBOXES
});

/*
 * @param {*} fetchedData.cashboxes
 */
export const fetchCashboxesSuccess = fetchedData => ({
    type: FETCH_CASHBOXES_SUCCESS,
    payload: fetchedData
});

export const setFetchingCashboxes = value => ({
    type: SET_FETCHING_CASHBOXES,
    payload: value
});

export const setSelectedCashboxId = value => ({
    type: SET_SELECTED_CASHBOX_ID,
    payload: value
});

export const clearCashboxesState = () => ({
    type: CLEAR_CASHBOXES_STATE
});
