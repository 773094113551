/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import {
    AppstoreFilled,
    BellFilled,
    CreditCardOutlined,
    GlobalOutlined,
    HomeOutlined,
    IdcardOutlined,
    PoweroffOutlined,
    ScheduleOutlined,
    SearchOutlined,
    ToolOutlined,
    UserOutlined
} from '@ant-design/icons';
import { AutoComplete, Avatar, Dropdown, Input, Menu, Tooltip } from 'antd';
import { Loader } from 'commons';
import { selectAdmin, selectState } from 'core/auth/duck';
import { fetchEmployeeTaskCount, selectTaskCount, setTaskCount } from 'core/employees/duck';
import { setBusiness } from 'core/forms/switchBusinessForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { setCollapsedState } from 'core/ui/duck';
import { SwitchBusinessModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import * as io from 'socket.io-client';
import history from 'store/history';
import { fetchAPI, images, isForbidden, permissions } from 'utils';
import SiderMenu from '../Navigation/SiderMenu';
import menuConfig from '../Navigation/menuConfig';
import { Banner } from './Banner';
import { Subscriptions } from './Subscriptions';
import Styles from './styles.m.css';

const { Option, OptGroup } = AutoComplete;

// const socket = io.connect('http://127.0.0.1:14281') //connect socket to server
const socket = io.connect(__API_URL__, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax : 5000,
    reconnectionAttempts: 5,
    transports: ['websocket', 'polling', 'flashsocket']
}); // connect socket to server

const headerIcons = [
    {
        icon: <ScheduleOutlined />,
        link: book.dashboard,
        tooltip: 'navigation.planner',
        enabled: user => !isForbidden(user, permissions.ACCESS_DASHBOARD),
        key: 'SCHEDULE'
    },
    {
        icon: <ToolOutlined />,
        link: book.ordersAppointments,
        tooltip: 'navigation.workflow',
        enabled: user => !isForbidden(user, permissions.SHOW_ORDERS),
        key: 'ORDERS'
    },
    {
        icon: <IdcardOutlined />,
        link: book.clientHotOperations,
        tooltip: 'navigation.clients',
        enabled: user => !isForbidden(user, permissions.GET_CLIENTS),
        key: 'IDCARD'
    },
    {
        icon: <CreditCardOutlined />,
        link: book.cashBankPage,
        tooltip: 'navigation.cash_bank',
        enabled: user => !isForbidden(user, permissions.ACCESS_FINANCE_CASH),
        key: 'CREDIT-CARD'
    }
];

const mapStateToProps = state => {
    return {
        user: state.auth,
        isAdmin: selectAdmin(state),
        businessSynonym: selectState(state).businessSynonym,
        headerFetching: state.ui.headerFetching,
        taskCount: selectTaskCount(state)
    };
};

const mapDispatch = {
    setBusiness,
    setModal,
    fetchEmployeeTaskCount,
    setTaskCount,
    setCollapsedState
};

@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatch)
export default class HeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: {}
        };
    }
    // _fetchTasks = async () => {
    //     const response = await fetchAPI('GET', 'employee_new_tasks_count');
    //     // await this.setState({
    //     //     totalTaskCount: _.get(response, 'totalRowsCount') ? response.totalRowsCount : 0
    //     // });
    //     await setTimeout(this._fetchTasks, 5000);
    // }
    //
    //
    // componentDidMount = async () => {
    //     // await this._fetchTasks();
    // }

    componentDidMount() {
        // fetch base count then
        this.props.fetchEmployeeTaskCount();
        this._connectSocket();
    }

    _connectSocket() {
        socket.once('connect_error', () => {
            console.log('Front failed to connect');
        });

        socket.on('employeeTaskCount', data => {
            this._updateEmployeeTaskCount(data);
        });
    }

    _updateEmployeeTaskCount(data) {
        // 1. parse data then check user

        const jsonData = JSON.parse(data);

        const { user, setTaskCount } = this.props;

        if (user.id == _.get(jsonData, 'managerId')) {
            _.get(jsonData, 'count') && setTaskCount({ totalRowsCount: jsonData.count });
        }
    }

    render() {
        const {
            isMobile,
            collapsed,
            user,
            intl: { formatMessage }
        } = this.props;
        const { searchResults, timeoutHandler, searchValue } = this.state;
        const headerPanel = this._renderHeaderPanel();
        // const tasksStats = this._renderBellTasksStatsInfo();
        // const openYourSite = this._renderOpenYourSite();
        // const headerInfo = this._renderHeaderInfo();
        const searchOptions = [];
        for (const [key, value] of Object.entries(searchResults)) {
            switch (key) {
                case 'clients':
                    if (Number(value.found)) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.clients' })}: ${Number(value.found)}`,
                            link: book.clientHotOperations
                        });
                    }
                    break;
                case 'vehicles':
                    if (Number(value.found)) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.vehicles' })}: ${Number(value.found)}`,
                            link: book.vehicles
                        });
                    }
                    break;
                case 'orders':
                    const { call, not_complete, reserve, required } = searchResults.orders.found;
                    const newOrdersCount = Number(call) + Number(not_complete) + Number(reserve) + Number(required);
                    for (const [key, value] of Object.entries(searchResults.orders.found)) {
                        switch (key) {
                            case 'approve':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/approve`
                                    });
                                }
                                break;
                            case 'cancel':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/cancel`
                                    });
                                }
                                break;
                            case 'invite':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/invitations`
                                    });
                                }
                                break;
                            case 'not_complete':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(newOrdersCount)}`,
                                        link: book.ordersAppointments
                                    });
                                }
                                break;
                            case 'progress':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/progress`
                                    });
                                }
                                break;
                            case 'success':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({
                                            id: `order_statuses_mapper.${key}`
                                        })}: ${Number(value)}`,
                                        link: `${book.orders}/success`
                                    });
                                }
                                break;
                            case 'review':
                                if (Number(value)) {
                                    searchOptions.push({
                                        title: `${formatMessage({ id: 'reviews' })}: ${Number(value)}`,
                                        link: `${book.orders}/reviews`
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                case 'storeProducts':
                    if (Number(value.found)) {
                        searchOptions.push({
                            title: `${formatMessage({ id: 'navigation.products' })}: ${Number(value.found)}`,
                            link: book.products
                        });
                    }
                    break;
                default:
                    break;
            }
        }
        menuConfig.sections.map(section => {
            if (section.items) {
                section.items
                    .filter(item =>
                        String(formatMessage({ id: item.name }))
                            .toLocaleLowerCase()
                            .includes(String(searchValue).toLocaleLowerCase()))
                    .map(item => {
                        searchOptions.push({
                            title: formatMessage({ id: item.name }),
                            link: item.link,
                            fromMenu: true
                        });
                    });
            }
        });
        const options = searchOptions.map(({ link, title, menuItem }) => ({
            link,
            label: title,
            value: title,
            menu_item: menuItem
        }));

        return (
            <div className={Styles.headerMenu}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 64px 0px 0px',
                        width: '25%'
                    }}
                >
                    {!isMobile && <SiderMenu {...this.props} />}
                    <Tooltip title={<FormattedMessage id='header.sidebar' />}>
                        <img
                            alt='logo'
                            className={Styles.logo}
                            onClick={() => {
                                this.props.setCollapsedState(!collapsed);
                            }}
                            src={user.secondaryLogoUrl || images.cb}
                            style={{ display: 'block' }}
                        />
                    </Tooltip>
                    <AutoComplete
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ maxWidth: 300, height: 'auto' }}
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={async value => {
                            if (String(value).length > 30) {
                                return;
                            }
                            if (value) {
                                const fetch = async () => {
                                    const searchResults = await fetchAPI('GET', 'search', {
                                        query: value
                                    });
                                    this.setState({
                                        searchResults,
                                        isTyping: false
                                    });
                                };
                                clearTimeout(timeoutHandler);
                                this.state.timeoutHandler = setTimeout(fetch, 1000);
                                this.setState({
                                    searchValue: value
                                });
                            } else {
                                this.setState({
                                    searchResults: {},
                                    searchValue: value
                                });
                            }
                        }}
                        onSelect={(value, option) => {
                            if (option.props.fromMenu) {
                                this.props.history.push(option.props.link);
                            } else {
                                this.props.history.push({
                                    pathname: option.props.link,
                                    state: { searchValue }
                                });
                                window.location.reload();
                            }
                        }}
                        options={options}
                        placeholder={formatMessage({ id: 'search' })}
                        style={{ marginLeft: 24 }}
                    >
                        <Input suffix={<SearchOutlined />} />
                    </AutoComplete>
                </div>
                <div
                    className={Styles.headerIcons}
                    onClick={() => {
                        this.props.setCollapsedState(true);
                    }}
                >
                    {headerIcons.map(({ icon, key, link, tooltip, enabled }) => {
                        if (enabled(user)) {
                            return (
                                <Tooltip key={`${key}`} title={<FormattedMessage id={tooltip} />}>
                                    <Link
                                        className={Styles.appstore}
                                        style={{ height: '100%' }}
                                        style={this._getHeaderElementStyle(link)}
                                        to={link}
                                    >
                                        {icon}
                                    </Link>
                                </Tooltip>
                            );
                        }
                    })}
                </div>
                {/*! isMobile && openYourSite */}
                {/* {!isMobile && headerInfo} */}
                {headerPanel}
            </div>
        );
    }

    _getHeaderElementStyle = link => {
        return link == this.props.location.pathname
            ? {
                  boxShadow: 'inset 0 -5px 2px -2px var(--mainPurple)'
              }
            : null;
    };

    _renderBellTasksStatsInfo = () => {
        return (
            <div className={Styles.taskCount}>
                {_.get(this.props, 'taskCount') != 0 ? (
                    <div>
                        <BellFilled
                            onClick={() => {
                                history.push(`${book.myTasksPage}`);
                            }}
                            style={{ color: 'red', fontSize: '24px' }}
                        />
                        <span style={{ color: 'white', fontSize: '18px' }}>({this.props.taskCount})</span>
                    </div>
                ) : null}
            </div>
        );
    };

    _renderHeaderInfo = () => {
        const { header, headerFetching } = this.props;

        return headerFetching ? (
            <Loader background='var(--blocks)' loading={headerFetching} />
        ) : header ? (
            <React.Fragment>
                {!_.isEmpty(header.proBanners) && <Banner banners={header.proBanners} />}
                <Subscriptions packages={header.rolePackage} suggestions={header.suggestionGroup} />
            </React.Fragment>
        ) : null;
    };

    _renderHeaderPanel = () => {
        const { logout, isMobile, user, isAdmin, setModal, businessSynonym } = this.props;

        const tasksStats = this._renderBellTasksStatsInfo();

        return (
            <div className={`${Styles.headerPanel} ${isMobile && Styles.headerPanelMobile} `}>
                {isAdmin && (
                    <div className={Styles.bussinesInfo}>
                        {user.businessName} ({user.businessId})
                    </div>
                )}
                <Tooltip placement='topLeft' title={<FormattedMessage id='header.profile' />}>
                    <Link className={Styles.user} to={book.profile}>
                        <Avatar className={Styles.avatar} icon={<UserOutlined />} />
                        <span className={Styles.userName}>
                            {user.name} {user.surname}
                        </span>
                    </Link>
                </Tooltip>
                <Dropdown
                    overlay={
                        <Menu className={Styles.appstoreMenu}>
                            {(isAdmin || user.businessesAccess) && (
                                <Menu.Item
                                    className={Styles.appstoreMenuItem}
                                    onClick={() => setModal(MODALS.SWITCH_BUSINESS)}
                                >
                                    <HomeOutlined className={Styles.appstoreMenuItemIcon} />
                                    <FormattedMessage id='header.business' />
                                </Menu.Item>
                            )}
                            <Menu.Item className={Styles.appstoreMenuItem}>
                                <a
                                    className={Styles.headerWebLink}
                                    href={`https://${businessSynonym}.cb24.eu`}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <GlobalOutlined className={Styles.appstoreMenuItemIcon} />
                                    <FormattedMessage id='header.site' />
                                </a>
                            </Menu.Item>
                            <Menu.Item className={Styles.appstoreMenuItem} onClick={logout}>
                                <PoweroffOutlined className={Styles.appstoreMenuItemIcon} />
                                <FormattedMessage id='header.logout' />
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Tooltip title={<FormattedMessage id='navigation.operations' />}>
                        <AppstoreFilled className={Styles.logout} />
                    </Tooltip>
                </Dropdown>
                <SwitchBusinessModal setBusiness={this.props.setBusiness} />
            </div>
        );
    };

    _renderOpenYourSite = () => {
        const {
            isAdmin,
            setModal,
            businessSynonym,
            user: { businessesAccess }
        } = this.props;

        return (
            <div className={Styles.headerWeb}>
                {(isAdmin || businessesAccess) && (
                    <Tooltip placement='topLeft' title={<FormattedMessage id='header.switch_business' />}>
                        <HomeOutlined className={Styles.homeIcon} onClick={() => setModal(MODALS.SWITCH_BUSINESS)} />
                    </Tooltip>
                )}
                <Tooltip placement='topLeft' title={<FormattedMessage id='header.open_your_site' />}>
                    <a
                        className={Styles.headerWebLink}
                        href={`https://${businessSynonym}.cb24.eu`}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <GlobalOutlined className={Styles.siteIcon} />
                    </a>
                </Tooltip>
            </div>
        );
    };
}
