import { Layout, Spinner } from 'commons';
import { DatePickerGroup } from 'components';
import { ChartContainer } from 'containers';
import { fetchChart, setChartDate, setChartMode, setChartPeriod } from 'core/chart/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    chartData: state.chart.chartData,
    filter: state.chart.filter,
    isFetching: state.ui.chartFetching
});

const mapDispatchToProps = {
    fetchChart,
    setChartPeriod,
    setChartDate,
    setChartMode
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ChartPage extends Component {
    componentDidMount() {
        this.props.fetchChart();
    }

    _setChartDate = date => {
        this.props.setChartDate(date);
        this.props.fetchChart();
    };

    _setChartPeriod = period => {
        this.props.setChartPeriod(period);
        this.props.fetchChart();
    };

    render() {
        const {
            chartData,
            filter,
            isFetching,
            startDate,
            endDate,
            filter: { date, period }
        } = this.props;

        return isFetching ? (
            <Spinner spin={isFetching} />
        ) : (
            <Layout
                controls={
                    <DatePickerGroup
                        date={date}
                        loading={isFetching}
                        onDateChange={this._setChartDate}
                        onPeriodChange={this._setChartPeriod}
                        period={period}
                    />
                }
                description={<FormattedMessage id='chart-page.description' />}
                title={<FormattedMessage id='chart-page.title' />}
            >
                <ChartContainer
                    chartData={chartData}
                    endDate={endDate}
                    filter={filter}
                    startDate={startDate}
                />
            </Layout>
        );
    }
}
