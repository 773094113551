import { AuditOutlined, ContactsOutlined, DashboardOutlined, DatabaseOutlined, LineChartOutlined, SettingOutlined } from '@ant-design/icons';
import React from 'react';
import book from 'routes/book';
import { isAdmin, isForbidden, permissions } from 'utils';

export default {
    sections: [
        /*  Operations submenu */
        {
            key: 'operations',
            iconType: 'dashboard',
            icon: <DashboardOutlined 
                style={{
                    paddingRight: 8,
                    fontSize: 16,
                }} 
            />,
            name: 'navigation.operations',
            items: [
                {
                    key: '/dashboard',
                    link: book.dashboard,
                    disabled: user => isForbidden(user, permissions.ACCESS_DASHBOARD),
                    name: 'navigation.planner'
                },
                {
                    key: '/orders',
                    link: book.ordersAppointments,
                    disabled: user => isForbidden(user, permissions.SHOW_ORDERS),
                    name: 'navigation.workflow'
                }
            ]
        },
        /* Reference Book submenu */
        {
            key: 'catalog',
            iconType: 'contacts',
            icon: <ContactsOutlined 
                style={{
                    paddingRight: 8,
                    fontSize: 16,
                }}
             />,
            name: 'navigation.catalog',
            items: [
                {
                    key: '/client-hot-operations',
                    link: book.clientHotOperations,
                    disabled: user => isForbidden(user, permissions.GET_CLIENTS),
                    name: 'navigation.clients'
                },
                {
                    key: '/employees',
                    link: book.employeesPage,
                    disabled: user => isForbidden(user, permissions.GET_EMPLOYEES),
                    name: 'navigation.employees'
                },
                {
                    key: '/labors',
                    link: book.laborsPage,
                    disabled: user => isForbidden(user, permissions.ACCESS_LABOR_CATALOGUE),
                    name: 'navigation.labors_page'
                },
                {
                    key: '/vehicle-types',
                    link: book.vehicleTypes,
                    disabled: user => isForbidden(user, permissions.ACCESS_LABOR_CATALOGUE),
                    name: 'navigation.vehicle_types'
                },
                {
                    key: '/tire-prices',
                    link: book.tirePriceGroups,
                    disabled: user => isForbidden(user, permissions.ACCESS_LABOR_CATALOGUE),
                    name: 'navigation.tire_price_groups'
                },
                {
                    key: '/cash/settings',
                    disabled: user => isForbidden(user, permissions.ACCESS_CATALOGUE_CASH),
                    link: book.cashSettingsPage,
                    name: 'navigation.cash_settings'
                }
            ]
        },
        /* Accounting */
        {
            key: 'accounting',
            iconType: 'audit',
            icon: <AuditOutlined
                style={{
                    paddingRight: 8,
                    fontSize: 16,
                }}
             />,
            name: 'navigation.accounting',
            items: [
                {
                    key: '/cash/bank',
                    disabled: user => isForbidden(user, permissions.ACCESS_FINANCE_CASH),
                    link: book.cashBankPage,
                    name: 'navigation.cash_bank'
                },
                {
                    key: '/cash/flow',
                    disabled: user => isForbidden(user, permissions.ACCESS_ACCOUNTING),
                    link: book.cashFlowPage,
                    name: 'navigation.flow_of_money'
                }
            ]
        },
        /* Statistics and reports submenu */
        {
            key: 'reports',
            iconType: 'line-chart',
            icon: <LineChartOutlined
                style={{
                    paddingRight: 8,
                    fontSize: 16,
                }} 
             />,
            name: 'navigation.reports',
            items: [
                {
                    key: '/chart',
                    disabled: user => isForbidden(user, permissions.ACCESS_KPI) && !isAdmin(user),
                    link: book.chart,
                    name: 'navigation.service_indicators'
                },
                {
                    key: '/report/orders',
                    disabled: user =>
                        isForbidden(user, permissions.ACCESS_REPORT_PROFIT_FROM_ORDERS) &&
                        !isAdmin(user),
                    link: book.reportOrders,
                    name: 'navigation.report_orders'
                }
            ]
        },
        /* Settings submenu */
        {
            key: 'settings',
            iconType: 'setting',
            icon: <SettingOutlined
                style={{
                    paddingRight: 8,
                    fontSize: 16,
                }} 
            />,
            name: 'navigation.settings',
            disabled: user => isForbidden(user, permissions.ACCESS_SETTINGS),
            items: [
                {
                    key: '/settings',
                    disabled: user => isForbidden(user, permissions.ACCESS_SETTINGS_WEB),
                    link: book.oldApp.settings,
                    name: 'navigation.main_settings'
                }
            ]
        },
        /* Administration submenu */
        {
            key: 'administration',
            iconType: 'database',
            icon: <DatabaseOutlined
                style={{
                    paddingRight: 8,
                    fontSize: 16,
                }} 
             />,
            disabled: user => isForbidden(user, permissions.ACCESS_ADMINISTRATION),
            name: 'navigation.administration',
            items: [
                {
                    key: '/managers/roles',
                    link: book.managerRolePage,
                    visible: user => !isForbidden(user, permissions.GRANT),
                    name: 'navigation.manager_roles'
                }
            ]
        }
    ],

    getSelectedByRoute: function getSelectedByRoute(currentPath) {
        const result = {
            sectionKey: '',
            itemKey: ''
        };
        // TODO
        for (const section of this.sections) {
            if (section.items) {
                for (const item of section.items) {
                    if (
                        currentPath.startsWith(item.key) &&
                        item.key.length > result.itemKey.length
                    ) {
                        Object.assign(result, {
                            sectionKey: section.key,
                            itemKey: item.key
                        });
                    }
                }
            } else if (
                currentPath.startsWith(section.key) &&
                section.key.length > result.itemKey.length
            ) {
                Object.assign(result, {
                    itemKey: section.key
                });
            }
        }

        return result;
    }
};
