import { EditOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import { Catcher } from 'commons';
import { MODALS } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { isForbidden, permissions } from 'utils';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

class TasksTable extends Component {
    constructor(props) {
        super(props);
        const { setModal, initOrderTasksForm, changeModalStatus, user } = this.props;
        const viewAllTasks = !isForbidden(user, permissions.GET_ALL_TASKS);

        this.columns = [
            {
                title: '',
                dataIndex: 'review',
                key: 'review',
                width: '4%',
                render: (text, record) => {
                    if (record.orderNum && (viewAllTasks || user.id === record.responsibleId)) {
                        return (
                            <EditOutlined
                                className={Styles.editOrderTaskIcon}
                                onClick={() => {
                                    initOrderTasksForm(record);
                                    setModal(MODALS.ORDER_TASK);
                                    changeModalStatus('editing');
                                }}
                            />
                        );
                    }
                }
            },
            {
                title: <FormattedMessage id='status' />,
                dataIndex: 'status',
                key: 'status',
                width: '8%',
                render: text => {
                    return text ? <FormattedMessage id={text} /> : '';
                }
            },
            {
                title: <FormattedMessage id='priority' />,
                dataIndex: 'priority',
                key: 'priority',
                width: '8%',
                render: text => {
                    return text ? <FormattedMessage id={text} /> : null;
                }
            },
            {
                title: <FormattedMessage id='urgency' />,
                dataIndex: 'urgency',
                key: 'urgency',
                width: '8%',
                render: text => {
                    return text ? <FormattedMessage id={text} /> : null;
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                dataIndex: 'comment',
                key: 'comment',
                width: '8%',
                render: text => (
                    <div>
                        <Tooltip
                            getPopupContainer={trigger => trigger.parentNode}
                            placement='bottomLeft'
                            title={<span>{text}</span>}
                        >
                            <div className={Styles.commentDiv}>{text}</div>
                        </Tooltip>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='responsible' />,
                dataIndex: 'responsibleName',
                key: 'responsibleName',
                width: '8%',
                render: (text, record) => {
                    return (
                        <div
                            style={{ wordBreak: 'normal' }}
                        >{`${text} ${record.responsibleSurname}`}</div>
                    );
                }
            },
            {
                title: <FormattedMessage id='position' />,
                dataIndex: 'position',
                key: 'position',
                width: '8%'
            },
            {
                title: <FormattedMessage id='stationName' />,
                dataIndex: 'stationName',
                key: 'stationName',
                width: '8%'
            },
            {
                title: <FormattedMessage id='startDate' />,
                dataIndex: 'startDate',
                key: 'startDate',
                width: '8%',
                render: text => <div>{text ? dayjs(text).format('DD.MM.YYYY HH:mm') : null}</div>
            },
            {
                title: <FormattedMessage id='deadlineDate' />,
                dataIndex: 'deadlineDate',
                key: 'deadlineDate',
                width: '8%',
                render: text => <div> {text ? dayjs(text).format('DD.MM.YYYY HH:mm') : null}</div>
            },
            {
                title: <FormattedMessage id='duration' />,
                dataIndex: 'duration',
                key: 'duration',
                width: '9%',
                render: text => {
                    const durationText = dayjs.duration(text, 'seconds');
                    const duration = dayjs.utc(durationText.asMilliseconds()).format('HH:mm');

                    return <div>{text ? duration : null}</div>;
                }
            },
            {
                title: <FormattedMessage id='endDate' />,
                dataIndex: 'endDate',
                key: 'endDate',
                width: '8%',
                render: text => <div>{text ? dayjs(text).format('DD.MM.YYYY HH:mm') : null}</div>
            },

            {
                title: <FormattedMessage id='author' />,
                dataIndex: 'author',
                key: 'author',
                width: '8%',
                render: (text, record) => (
                    <div>
                        {record.authorName} {record.authorSurname}
                    </div>
                )
            }
        ];
    }

    sortHistory = (a, b) => {
        if (dayjs(a.startDate).isAfter(b.startDate)) {
            return -1;
        }
        if (dayjs(b.startDate).isAfter(a.startDate)) {
            return 1;
        }

        return 0;
    };

    shouldComponentUpdate(nextProps) {
        return !_.isEqual(nextProps, this.props);
    }

    render() {
        const { orderTasks } = this.props;
        const { columns } = this;

        return (
            <Catcher>
                <div className={Styles.table}>
                    <Table
                        bordered
                        className={Styles.ExpandedTable}
                        columns={columns}
                        dataSource={
                            orderTasks.orderTasks
                                ? [
                                      ...orderTasks.orderTasks.map((task, index) => ({
                                          ...task,
                                          index,
                                          key: v4()
                                      }))
                                  ]
                                : []
                        }
                        expandedRowClassName={() => Styles.ExpandedRow}
                        expandedRowRender={(record, index) => {
                            return (
                                <Table
                                    bordered
                                    columns={columns}
                                    dataSource={[
                                        ..._.get(orderTasks, ['orderTasks', index, 'history'], [])
                                            .sort(this.sortHistory)
                                            .map((task, index) => ({
                                                ...task,
                                                index,
                                                key: v4()
                                            }))
                                    ]}
                                    pagination={false}
                                    showHeader={false}
                                />
                            );
                        }}
                        locale={{
                            emptyText: <FormattedMessage id='no_data' />
                        }}
                        pagination={false}
                        scroll={{ x: 2000 }}
                        size='small'
                    />
                </div>
            </Catcher>
        );
    }
}

export default TasksTable;
