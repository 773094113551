exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__titleStyle---v7ul7{font-weight:700;font-size:21px;margin-bottom:8px;margin-top:8px}.styles-m__inputBlockStyle---27RNB{display:flex;margin-bottom:8px}.styles-m__inputMargin---UzyIB{margin-right:12px}.styles-m__statusesModal---2K2Kz{display:flex;justify-content:space-between}.styles-m__statusesDivModal---1wyxa{margin-bottom:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACI,eAAgB,CAChB,cAAe,CACf,iBAAkB,CAClB,cACJ,CAEA,mCACI,YAAa,CACb,iBACJ,CAEA,+BACI,iBACJ,CAEA,iCACI,YAAa,CACb,6BACJ,CAEA,oCACI,iBACJ","file":"styles.m.css","sourcesContent":[".titleStyle {\n    font-weight: 700;\n    font-size: 21px;\n    margin-bottom: 8px;\n    margin-top: 8px;         \n}\n\n.inputBlockStyle {\n    display: flex;\n    margin-bottom: 8px;\n}\n\n.inputMargin {\n    margin-right: 12px;\n}\n\n.statusesModal {\n    display: flex;\n    justify-content: space-between;\n}\n\n.statusesDivModal {\n    margin-bottom: 8px;\n}"]}]);

// Exports
exports.locals = {
	"titleStyle": "styles-m__titleStyle---v7ul7",
	"inputBlockStyle": "styles-m__inputBlockStyle---27RNB",
	"inputMargin": "styles-m__inputMargin---UzyIB",
	"statusesModal": "styles-m__statusesModal---2K2Kz",
	"statusesDivModal": "styles-m__statusesDivModal---1wyxa"
};