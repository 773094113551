import { mainModuleName } from 'modals/RefactoredCashOrderModal/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'suppliers';
const prefix = `cpb/refactoredCashOrderModal/${moduleName}`;

export const FETCH_SUPPLIERS = `${prefix}/FETCH_SUPPLIERS`;
export const FETCH_SUPPLIERS_SUCCESS = `${prefix}/FETCH_SUPPLIERS_SUCCESS`;
export const SET_SUPPLIERS_FILTERS = `${prefix}/SET_SUPPLIERS_FILTERS`;
export const SET_FETCHING_SUPPLIERS = `${prefix}/SET_FETCHING_SUPPLIERS`;

export const SET_SELECTED_SUPPLIER_ID = `${prefix}/SET_SELECTED_SUPPLIER_ID`;

export const CLEAR_SUPPLIERS_STATE = `${prefix}/CLEAR_SUPPLIERS_STATE`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    suppliers: [],
    suppliersFilters: {
        all: true
    },
    fetchingSuppliers: false,
    selectedSupplierId: undefined
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_SUPPLIERS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_SUPPLIERS_FILTERS:
            return {
                ...state,
                suppliersFilters: {
                    ...state.suppliersFilters,
                    ...payload
                }
            };
        case SET_FETCHING_SUPPLIERS:
            return {
                ...state,
                fetchingSuppliers: payload
            };

        case SET_SELECTED_SUPPLIER_ID:
            return {
                ...state,
                selectedSupplierId: payload
            };
        case CLEAR_SUPPLIERS_STATE:
            return ReducerState;

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectSuppliers = state => state[mainModuleName][moduleName].suppliers;
export const selectSuppliersFilters = state => state[mainModuleName][moduleName].suppliersFilters;
export const selectFetchingSuppliers = state => state[mainModuleName][moduleName].fetchingSuppliers;

export const selectSelectedSupplierId = state => state[mainModuleName][moduleName].selectedSupplierId;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchSuppliers = () => ({
    type: FETCH_SUPPLIERS
});

/*
 * @param {*} fetchedData.suppliers
 */
export const fetchSuppliersSuccess = fetchedData => ({
    type: FETCH_SUPPLIERS_SUCCESS,
    payload: fetchedData
});

/** Provide object with filters field you want to change, if you will not provide some filed, they will not be changed.
 * If you provide 'null' or 'undefined', then field will be replaced with appropriate value.
 * Automatically triggers data refreshing(action for fetching).
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setSuppliersFilters = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_SUPPLIERS_FILTERS,
            payload: filters
        });
        dispatch(fetchSuppliers());
    };
};

export const setFetchingSuppliers = value => ({
    type: SET_FETCHING_SUPPLIERS,
    payload: value
});

export const setSelectedSupplierId = value => ({
    type: SET_SELECTED_SUPPLIER_ID,
    payload: value
});

export const clearSuppliersState = () => ({
    type: CLEAR_SUPPLIERS_STATE
});
