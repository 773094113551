// antd breakpoints
export const antdBreakpoints = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
};

export const BREAKPOINTS = {
    xs: {
        min: null,
        max: 575
    },
    sm: {
        min: 576,
        max: 767
    },
    md: {
        min: 768,
        max: 991
    },
    lg: {
        min: 992,
        max: 1199
    },
    xl: {
        min: 1200,
        max: 1599
    },
    xxl: {
        min: 1600,
        max: null
    }
};

// custom breakpoints
export const _breakpoints = {
    mobile: {
        min: null,
        max: 767
    },
    tablet: {
        min: 768,
        max: 1199
    },
    desktop: {
        min: 1200,
        max: null
    }
};
