import { Button, Form, Input, InputNumber, Modal, notification, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const ManageExtraSpendings = ({
    open,
    closeModal,
    addSpendingsRow,
    editSpendingsRow,
    row = {},
    intl: { formatMessage }
}) => {
    const isEdit = !!row.id;
    const [form] = Form.useForm();
    const handleClose = () => {
        form.resetFields();
        closeModal();
    };

    const typeOptions = useMemo(
        () => [
            { value: 'transportation', label: formatMessage({ id: 'extra_spendings.transportation' }) },
            { value: 'other', label: formatMessage({ id: 'extra_spendings.other' }) },
            { value: 'duty', label: formatMessage({ id: 'extra_spendings.duty' }) }
        ],
        [formatMessage]
    );

    const handleOk = async () => {
        try {
            await form.validateFields();
            const formValues = form.getFieldsValue();
            isEdit ? editSpendingsRow([{ ...formValues, id: row.id }]) : addSpendingsRow(formValues);
            handleClose();
        } catch (e) {
            notification.warning({ message: formatMessage({ id: 'storage_document.error.required_fields' }) });
        }
    };

    useEffect(() => {
        if (open) {
            form.setFieldsValue(row);
        }
    }, [form, open, row]);

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            footer={[
                <Button key='cancel' onClick={handleClose}>
                    <FormattedMessage id='cancel' />
                </Button>,
                <Button key='submit' onClick={handleOk} type='primary'>
                    <FormattedMessage id='ok' />
                </Button>
            ]}
            onCancel={handleClose}
            open={open}
            title={formatMessage({ id: 'extra_spendings.add_additional_spendings' })}
        >
            <Form form={form} labelCol={{ span: 4 }} layout='horizontal' preserve={false}>
                <Form.Item name='type' rules={[{ required: true, message: 'type required' }]}>
                    <Select options={typeOptions} placeholder={formatMessage({ id: 'storage.type' })} />
                </Form.Item>
                <Form.Item name='name'>
                    <Input placeholder={formatMessage({ id: 'name_2' })} />
                </Form.Item>
                <Form.Item name='sum' rules={[{ required: true, message: 'sum required' }]}>
                    <InputNumber decimalSeparator=',' min={0} placeholder={formatMessage({ id: 'sum' })} />
                </Form.Item>
                <Form.Item name='comment'>
                    <Input.TextArea placeholder={formatMessage({ id: 'comment' })} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default injectIntl(ManageExtraSpendings);
