import React, { useEffect, useState } from 'react';

import Board, { moveCard } from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';

const initBoard = {
    columns: []
};

export const ControlledKanbanBoard = ({ board, ...props }) => {
    // You need to control the state yourself.
    const [controlledBoard, setBoard] = useState(board || initBoard);
    useEffect(() => {
        setBoard(board);
    }, [board]);

    const handleCardMove = (_card, source, destination) => {
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
    };

    return (
        <Board onCardDragEnd={handleCardMove} {...props}>
            {controlledBoard}
        </Board>
    );
};

export const UncontrolledKanbanBoard = ({ board, ...props }) => {
    return (
        <Board
            // allowAddCard={{ on: 'top' }}
            // allowRemoveCard
            // allowRemoveLane
            // allowRenameColumn
            // initialBoard={board}
            // onCardNew={console.log}
            // onCardRemove={console.log}
            // onLaneRemove={console.log}
            // onLaneRename={console.log}
            // onNewCardConfirm={draftCard => ({
            //     id: new Date().getTime(),
            //     ...draftCard
            // })}
            initialBoard={board}
            {...props}
        />
    );
};
