import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchClientsSuccess,
    fetchSelectedClientSuccess,
    FETCH_CLIENTS,
    FETCH_SELECTED_CLIENT,
    selectClientsFilters,
    selectClientsSort,
    selectSelectedClientId,
    setFetchingClients,
    setFetchingSelectedClient
} from './duck';

export function* fetchClientsSaga() {
    while (true) {
        try {
            yield take(FETCH_CLIENTS);

            yield put(setFetchingClients(true));
            const filters = yield select(selectClientsFilters);
            const sort = yield select(selectClientsSort);

            const response = yield call(fetchAPI, 'GET', 'clients', {
                filters,
                sort
            });
            const { clients, stats: clientsStats } = response;

            yield put(fetchClientsSuccess({ clients, clientsStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingClients(false));
        }
    }
}

export function* fetchSelectedClientSaga() {
    while (true) {
        try {
            yield take(FETCH_SELECTED_CLIENT);

            const selectedClientId = yield select(selectSelectedClientId);

            yield put(setFetchingSelectedClient(true));

            const response = yield call(fetchAPI, 'GET', `clients/${selectedClientId}`);

            yield put(fetchSelectedClientSuccess({ selectedClient: response }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingSelectedClient(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchClientsSaga), call(fetchSelectedClientSaga)]);
}
