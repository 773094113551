import { emitError } from 'core/ui/duck';
import { selectDateRange } from 'modals/DebtZeroingModal/redux/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchSelectedSupplierStoreDocSuccess,
    fetchSuppliersStoreDocsSuccess,
    FETCH_SELECTED_SUPPLIER_STORE_DOC,
    FETCH_SUPPLIERS_STORE_DOCS,
    selectSelectedSupplierStoreDocId,
    selectSuppliersStoreDocsFilters,
    setFetchingSelectedSupplierStoreDoc,
    setFetchingSuppliersStoreDocs
} from './duck';

export function* fetchSuppliersStoreDocsSaga() {
    while (true) {
        try {
            yield take(FETCH_SUPPLIERS_STORE_DOCS);

            yield put(setFetchingSuppliersStoreDocs(true));
            const filters = yield select(selectSuppliersStoreDocsFilters);
            const dataRange = yield select(selectDateRange);

            const response = yield call(fetchAPI, 'GET', 'store_docs', {
                ...filters,
                startDate: dataRange.startDate,
                endDate: dataRange.endDate
            });

            const { list: suppliersStoreDocs, stats: suppliersStoreDocsStats } = response;

            yield put(
                fetchSuppliersStoreDocsSuccess({
                    suppliersStoreDocs,
                    suppliersStoreDocsStats
                })
            );
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingSuppliersStoreDocs(false));
        }
    }
}

/**
 * Fetch store doc that was selected from table. This is required to load data even if
 * store doc id was provided from modal API without loading all suppliers.
 */
export function* fetchSelectedSupplierStoreDocSaga() {
    while (true) {
        try {
            yield take(FETCH_SELECTED_SUPPLIER_STORE_DOC);

            yield put(setFetchingSelectedSupplierStoreDoc(true));
            const selectedSupplierStoreDocId = yield select(selectSelectedSupplierStoreDocId);

            const response = yield call(
                fetchAPI,
                'GET',
                `store_docs/${selectedSupplierStoreDocId}`
            );

            yield put(
                fetchSelectedSupplierStoreDocSuccess({
                    selectedSupplierStoreDoc: response
                })
            );
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingSelectedSupplierStoreDoc(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchSuppliersStoreDocsSaga), call(fetchSelectedSupplierStoreDocSaga)]);
}
