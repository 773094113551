import { Table } from 'antd';
import _ from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import { selectMode, setCashOrderSum } from 'modals/RefactoredCashOrderModal/redux/duck';
import {
    fetchSuppliersStoreDocs,
    selectFetchingSuppliersStoreDocs,
    selectSelectedSupplierStoreDocId,
    selectSuppliersStoreDocs,
    selectSuppliersStoreDocsFilters,
    selectSuppliersStoreDocsStats,
    setSelectedSupplierStoreDocId,
    setSuppliersStoreDocsFilters
} from 'modals/RefactoredCashOrderModal/redux/suppliersStoreDocs/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    supplierStoreDocs: selectSuppliersStoreDocs(state),
    supplierStoreDocsStats: selectSuppliersStoreDocsStats(state),
    supplierStoreDocsFilters: selectSuppliersStoreDocsFilters(state),
    fetchingSupplierStoreDocs: selectFetchingSuppliersStoreDocs(state),

    selectedSupplierStoreDocId: selectSelectedSupplierStoreDocId(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    fetchSuppliersStoreDocs,
    setSuppliersStoreDocsFilters,

    setSelectedSupplierStoreDocId,
    setCashOrderSum
};

/**
 * This module is supposed to show all supplier's store docs.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocsTable extends Component {
    componentDidMount() {
        this.props.fetchSuppliersStoreDocs();
    }

    render() {
        const {
            isMobile,
            mode,
            supplierStoreDocs,
            fetchingSupplierStoreDocs,

            supplierStoreDocsStats,
            supplierStoreDocsFilters,
            setSuppliersStoreDocsFilters,
            setSelectedSupplierStoreDocId,

            selectedSupplierStoreDocId,
            setCashOrderSum
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(supplierStoreDocsStats.count / 25) * 25,
            current: supplierStoreDocsFilters.page,
            onChange: page => {
                setSuppliersStoreDocsFilters({ page });
            }
        };

        const disabled = Boolean(mode != MODES.ADD);

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig()}
                    dataSource={supplierStoreDocs}
                    loading={fetchingSupplierStoreDocs}
                    onRow={record => ({
                        onClick: () => {
                            if (!disabled) {
                                // Disable ability to change something in edit/view mode for sure
                                setSelectedSupplierStoreDocId(record.id);
                                setCashOrderSum(_.get(record, 'remainingSum'));
                            }
                        }
                    })}
                    pagination={pagination}
                    rowClassName={record => (record.id == selectedSupplierStoreDocId ? Styles.selectedRow : undefined)}
                    rowKey={record => record.id}
                    scroll={{ x: isMobile ? '100vw' : '40vw', y: '25vh' }}
                />
            </div>
        );
    }
}
