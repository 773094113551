import { Form } from '@ant-design/compatible';
import { Select } from 'antd';
import {
    DecoratedInput,
    DecoratedInputNumber,
    DecoratedSelect,
    LimitedDecoratedSelect
} from 'forms/DecoratedFields';
import React, { Component } from 'react';
import Styles from '../styles.m.css';
import { cellType } from './cellConfig';

const { Option } = Select;

export const EditableContext = React.createContext();

export const EditableRowCtx = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

export const EditableRow = Form.create()(EditableRowCtx);

export class EditableCell extends Component {
    state = {
        editing: false
    };

    componentDidMount() {
        if (this.props.editable) {
            document.addEventListener('click', this._handleClickOutside, true);
        }
    }

    componentWillUnmount() {
        if (this.props.editable) {
            document.removeEventListener('click', this._handleClickOutside, true);
        }
    }

    _toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    _handleClickOutside = e => {
        const { editing } = this.state;
        if (editing && this.cell !== e.target && !this.cell.contains(e.target)) {
            this._save();
        }
    };

    _save = () => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error) {
                return;
            }
            this._toggleEdit();
            if (Number(values.detailId)) {
                handleSave({ ...record, ...values });
            } else if (values.quantity) {
                handleSave({ ...record, ...values });
            } else {
                handleSave({ ...record });
            }
        });
    };

    render() {
        const { editing } = this.state;
        const { editable, dataIndex, title, record, index, handleSave, ...restProps } = this.props;

        return (
            // eslint-disable-next-line
            <td ref={(node) => (this.cell = node)} {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>
                        {form => {
                            this.form = form;

                            return editing ? (
                                this._renderCell(form)
                            ) : (
                                <div
                                    className={Styles.editableCellValueWrap}
                                    onClick={this._toggleEdit}
                                    style={{ paddingRight: 24 }}
                                >
                                    {restProps.children}
                                </div>
                            );
                        }}
                    </EditableContext.Consumer>
                ) : (
                    restProps.children
                )}
            </td>
        );
    }

    _renderCell = form => {
        const { title, record, dataIndex, details } = this.props;
        const { getFieldDecorator } = form;
        switch (this.props.cellType) {
            case cellType.NUMERAL:
                return (
                    <DecoratedInputNumber
                        ref={node => (this.input = node)}
                        field={dataIndex}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={record[dataIndex]}
                        onPressEnter={this._save} // eslint-disable-line
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`
                            }
                        ]}
                    />
                );
            case cellType.SELECT:
                return (
                    <DecoratedSelect
                        ref={node => (this.input = node)}
                        field={dataIndex}
                        formItem
                        getFieldDecorator={getFieldDecorator} // eslint-disable-line
                    />
                );
            case cellType.LIMITED_SELECT:
                return (
                    <LimitedDecoratedSelect
                        ref={node => (this.input = node)}
                        allowClear
                        cnStyles={Styles.detailsSelect}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ width: '50%' }}
                        field={dataIndex}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={node => (this.input = node)}
                        initialValue={record.detailName || record[dataIndex]}
                        onPressEnter={() => this._save()}
                        optionLabelProp={"children"} // eslint-disable-line
                        placeholder={"Выберете деталь"} // eslint-disable-line
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`
                            }
                        ]}
                        showSearch
                    >
                        {details.map(({ detailId, detailName }) => (
                            <Option key={detailId} value={String(detailId)}>
                                {detailName}
                            </Option>
                        ))}
                    </LimitedDecoratedSelect>
                );
            default:
                return (
                    <DecoratedInput
                        ref={node => (this.input = node)}
                        field={dataIndex}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={record[dataIndex]}
                        onPressEnter={this._save} // eslint-disable-line
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`
                            }
                        ]}
                    />
                );
        }
    };
}
