const INC = 'INC';
const CRT = 'CRT';
const STP = 'STP';
const SRV = 'SRV';
const OUT = 'OUT';
const ICO = 'ICO';
const SRT = 'SRT';
const AUT = 'AUT';
const CST = 'CST';
const STM = 'STM';
const TSF = 'TSF';
const TIC = 'TIC';
const RES = 'RES';
const ORD = 'ORD';
const BOR = 'BOR';
const COM = 'COM';
const TOL = 'TOL';
const TOR = 'TOR';
const VRT = 'VRT';
const KPP = 'KPP';
const KPM = 'KPM';
const CRA = 'CRA';
const CSH = 'CSH';
const MRD = 'MRD';

export const journalTypes = [SRT, CSH, INC, SRV, OUT, ICO, MRD];

export const showStorageWarehouses = [KPP, KPM, STP, STM, TSF, TIC];

const storageDocumentMapper = type => {
    switch (type) {
        case INC:
            return {
                copyTypes: [INC, SRT],
                sellingPriceDisabled: true,
                sellingPriceEqualsPurchasePrice: true,
                ignoreSellingPriceDisabled: true,
                cashOrderType: 'EXPENSE'
            };
        case CRT:
            return {
                copyTypes: [OUT, CRT],
                sellingPriceDisabled: false,
                cashOrderType: 'EXPENSE'
            };
        case STP:
            return {
                copyTypes: [STP, STM],
                sellingPriceDisabled: true
            };
        case SRV:
            return {
                copyTypes: [SRV, VRT],
                sellingPriceDisabled: true,
                sellingPriceEqualsPurchasePrice: true,
                cashOrderType: 'EXPENSE'
            };
        case CRA:
            return {
                copyTypes: [OUT, CRT],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true
            };
        case OUT:
            return {
                copyTypes: [OUT, CRT],
                purchasePriceDisabled: true,
                sellingPriceDisabled: false,
                cashOrderType: 'INCOME'
            };
        case SRT:
            return {
                copyTypes: [INC, SRT],
                purchasePriceDisabled: true,
                sellingPriceDisabled: false,
                cashOrderType: 'INCOME'
            };
        case CST:
            return {
                copyTypes: [CST],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true
            };
        case STM:
            return {
                copyTypes: [STP, STM],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true
            };
        case AUT:
            return {
                copyTypes: [OUT, CRT],
                purchasePriceDisabled: true,
                sellingPriceDisabled: false
            };
        case VRT:
            return {
                copyTypes: [SRV, VRT],
                purchasePriceDisabled: true,
                sellingPriceDisabled: false,
                cashOrderType: 'INCOME'
            };
        case TSF:
            return {
                copyTypes: [TSF],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true
            };
        case TIC:
            return {
                copyTypes: [TIC],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true
            };
        case TOL:
            return {
                copyTypes: [TOL, TOR],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true
            };
        case TOR:
            return {
                copyTypes: [TOL, TOR],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true
            };
        case KPP:
            return {
                copyTypes: [KPP, KPM, INC],
                sellingPriceDisabled: true,
                allowProductComment: true
            };
        case KPM:
            return {
                copyTypes: [KPP, KPM, INC],
                purchasePriceDisabled: true,
                sellingPriceDisabled: false,
                allowProductComment: true
            };
        case ORD:
            return {
                copyTypes: [ORD, BOR, COM],
                sellingPriceDisabled: true,
                sellingPriceEqualsPurchasePrice: true
            };
        case BOR:
            return {
                copyTypes: [ORD, BOR, COM],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true,
                sellingPriceEqualsPurchasePrice: true
            };
        case COM:
            return {
                copyTypes: [ORD, BOR, COM],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true,
                sellingPriceEqualsPurchasePrice: true
            };
        case RES:
            return {
                copyTypes: [],
                purchasePriceDisabled: true,
                sellingPriceDisabled: true
            };
        default:
            return {
                copyTypes: []
            };
    }
};

export default storageDocumentMapper;
