import { Layout } from 'antd';
import { Footer } from 'commons';
import { logout } from 'core/auth/duck';
import { fetchHeaderData } from 'core/subscription/duck';
import { setCollapsedState, setView } from 'core/ui/duck';
import { throttle } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Header, ModuleHeader, Navigation } from 'tireFitting';
import { getCollapsedState, _breakpoints } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    authFetching: state.ui.authFetching,
    collapsed: state.ui.collapsed,
    header: state.subscription.header,
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    logout,
    setCollapsedState,
    fetchHeaderData,
    setView
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export class LayoutComponent extends Component {
    static defaultProps = {
        paper: true
    };

    _setView = throttle(() => {
        this.props.setView({
            isMobile: window.innerWidth <= _breakpoints.mobile.max,
            isTablet:
                _breakpoints.tablet.min <= window.innerWidth &&
                _breakpoints.tablet.max >= window.innerWidth,
            isDesktop: _breakpoints.desktop.min <= window.innerWidth
        });
    }, 500);

    componentDidMount() {
        document.title = this._getPageTitle();
        const collapsed = getCollapsedState();
        window.addEventListener('resize', this._setView);

        this._setView();
        this.props.setCollapsedState(collapsed);
        this.props.fetchHeaderData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._setView);
    }

    _toggleNavigation = () => {
        const collapsed = getCollapsedState();
        this.props.setCollapsedState(!collapsed);
    };

    _logout = () => this.props.logout();

    _getPageTitle = () => {
        const { history, location } = this.props;
        let title = 'Carbook.Pro';

        if (history.location.pathname && location.pathname) {
            const path = location.pathname.split('/')[1];
            title = `Carbook.Pro - ${path.charAt(0).toUpperCase() + path.slice(1)}`;
        }

        return title;
    };

    render() {
        const {
            title,
            description,
            controls,
            paper,
            collapsed,
            isMobile,
            header,
            user,
            className,
            setCollapsedState
        } = this.props;

        return (
            <Layout style={{ height: '100%' }}>
                {!collapsed && !isMobile && (
                    <div
                        onClick={e => {
                            if (!collapsed) {
                                setCollapsedState(true);
                            }
                        }}
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            background: '#44444494',
                            zIndex: 124
                        }}
                    />
                )}
                <Layout className={`${Styles.layout} ${className}`}>
                    {isMobile && (
                        <Navigation
                            collapsed={collapsed}
                            isMobile={isMobile}
                            logout={this._logout}
                            onCollapse={this._toggleNavigation}
                            user={user}
                        />
                    )}
                    {!isMobile && (
                        <Layout.Header className={Styles.header}>
                            <Header
                                collapsed={collapsed}
                                header={header}
                                logout={this._logout}
                                toggleNavigation={this._toggleNavigation}
                                user={user}
                            />
                        </Layout.Header>
                    )}
                    {title && (
                        <ModuleHeader
                            collapsed={collapsed}
                            controls={controls}
                            description={description}
                            isMobile={isMobile}
                            onCollapse={this._toggleNavigation}
                            title={title}
                            user={user}
                        />
                    )}
                    <main
                        className={`${Styles.content} ${collapsed && Styles.contentCollapsed} ${
                            title && Styles.contentModuleHeader
                        }`}
                    >
                        <Layout.Content className={`${paper ? Styles.paper : Styles.pure}`}>
                            {this.props.children}
                        </Layout.Content>
                    </main>
                    {!isMobile && (
                        <Layout.Footer>
                            <Footer collapsed={collapsed} />
                        </Layout.Footer>
                    )}
                </Layout>
            </Layout>
        );
    }
}
