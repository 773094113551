import { Select, Tooltip } from 'antd';
import _ from 'lodash';
import { CASH_ORDER_TYPES, MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectCashOrderType,
    selectMode,
    selectSelectedCashbox,
    setCashOrderType
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const { INCOME, EXPENSE, ADJUSTMENT_INCOME } = CASH_ORDER_TYPES;

const mapStateToProps = state => ({
    cashOrderType: selectCashOrderType(state),
    selectedCashbox: selectSelectedCashbox(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    setCashOrderType
};

/**
 * This component serves selecting cash order type
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CashOrderTypeSelector extends React.Component {
    /**
     * Validates option if it can be selected, some options are not available with rst
     * @param {*} optionValue type of cashorder from dropdown
     * @returns { disabled: true/false }
     */
    validateCashOrderType = optionValue => {
        const { selectedCashbox } = this.props;

        if (selectedCashbox && selectedCashbox.rst && optionValue == ADJUSTMENT_INCOME) {
            return { disabled: true };
        }

        return { disabled: false };
    };

    /**
     * When cash order type is changed
     * @param {*} type - new cash order type
     */
    onSelectedNewCashOrderType = type => {
        const { setCashOrderType } = this.props;

        setCashOrderType(type);
    };

    render() {
        const {
            cashOrderType,
            mode,
            editable,

            intl: { formatMessage }
        } = this.props;

        const disabled = Boolean(mode != MODES.ADD);

        return (
            <Tooltip
                placement='top'
                title={<FormattedMessage id='refactored_cash_order_modal.hint_type' />}
                zIndex={2001}
            >
                <Select
                    data-qa='select_type_order'
                    disabled={!editable && disabled}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={this.onSelectedNewCashOrderType}
                    value={cashOrderType}
                >
                    {_.map(CASH_ORDER_TYPES, type => {
                        const { disabled } = this.validateCashOrderType(type);

                        // Change currently selected cash order type if it has to be disabled
                        if (disabled && cashOrderType == type) {
                            this.onSelectedNewCashOrderType(INCOME);
                        }

                        return (
                            <Option key={type} disabled={disabled} value={type}>
                                <FormattedMessage id={`refactored_cash_order_modal.${type}`} />
                            </Option>
                        );
                    })}
                </Select>
            </Tooltip>
        );
    }
}
