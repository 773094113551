import { Tabs } from 'antd';
import { ResponsiveView } from 'commons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BREAKPOINTS } from 'utils';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

/**
 * Makes very useful and view responsive component of two tabs, the firts and the second
 * with its own title and content. If we have anough amount space we place them one near another,
 * else we create tabs for each component.
 * @param {*} props.primary First tab element
 * @param {*} props.primary.title Title of the tab of this element
 * @param {*} props.primary.content Content of the tab of this element
 *
 * @param {*} props.secondary Second tab element
 * @param {*} props.secondary.title Title of the tab of this element
 * @param {*} props.secondary.content Content of the tab of this element
 * @returns node
 */
export const TabsTwins = props => {
    const { primary, secondary } = props;

    return (
        <div className={Styles.tables}>
            <ResponsiveView view={{ min: null, max: BREAKPOINTS.xl.max }}>
                <Tabs defaultActiveKey='1'>
                    <TabPane key='1' tab={<FormattedMessage id={primary.title} />}>
                        {primary.content}
                    </TabPane>
                    <TabPane key='2' tab={<FormattedMessage id={secondary.title} />}>
                        {secondary.content}
                    </TabPane>
                </Tabs>
            </ResponsiveView>
            <ResponsiveView view={{ min: BREAKPOINTS.xxl.min, max: null }}>
                {primary.content}
                {secondary.content}
            </ResponsiveView>
        </div>
    );
};
