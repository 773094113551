import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Input } from 'antd';
import { Catcher, Layout } from 'commons';
import { DateRangePicker } from 'components';
import { MODALS, selectModalProps, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { SupplierModal } from 'modals';
import {
    fetchBusinessSuppliers,
    selectBusinessSuppliers,
    selectBusinessSuppliersFilters,
    selectFetchingBusinessSuppliers,
    setBusinessSuppliersFilters
} from 'pages/SuppliersPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { SuppliersTable } from './components/tables';
import Styles from './styles.m.css';

const { Search } = Input;

const mapStateToProps = state => ({
    businessSuppliers: selectBusinessSuppliers(state),
    fetchingBusinessSuppliers: selectFetchingBusinessSuppliers(state),
    modalProps: selectModalProps(state),
    filters: selectBusinessSuppliersFilters(state),
    user: state.auth
});

const mapDispatchToProps = {
    fetchBusinessSuppliers,
    setModal,
    setBusinessSuppliersFilters
};

/**
 * New suppliers page. Contains basic supplier actions
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SuppliersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    handleSearch = _.debounce(value => {
        this.props.setBusinessSuppliersFilters({
            query: _.toLower(value.replace(/[+()]/g, ''))
        });
    }, 1000);

    componentDidMount() {
        this.props.fetchBusinessSuppliers();
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_suppliers' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { setModal, filters, modalProps, user } = this.props;
        const isSuppliersCRUDForbidden = !isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST, accesses.ROWO);
        const { helperDrawerOpen, allLinks } = this.state;

        return (
            <div>
                <Layout
                    controls={
                        <React.Fragment>
                            <DateRangePicker
                                dateRange={[filters.createdFromDate, filters.createdToDate]}
                                minimize
                                onDateChange={daterange => {
                                    const [startDate, endDate] = daterange;

                                    this.props.setBusinessSuppliersFilters({
                                        createdFromDate: startDate,
                                        createdToDate: endDate
                                    });
                                }}
                                overlayStyle={{ zIndex: '5000' }}
                            />
                            <div className={Styles.buttonGroup}>
                                <Button
                                    disabled={isSuppliersCRUDForbidden}
                                    onClick={() => setModal(MODALS.SUPPLIER)}
                                    type='primary'
                                >
                                    <FormattedMessage id='supplier-modal.add_supplier' />
                                </Button>
                            </div>

                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    this.setState({
                                        helperDrawerOpen: true
                                    });
                                    await this.fetchHelperLinks();
                                }}
                                style={{
                                    fontSize: 22,
                                    marginLeft: 8,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </React.Fragment>
                    }
                    title={<FormattedMessage id='suppliers_page.suppliers' />}
                >
                    <Catcher>
                        <Search
                            className={Styles.search}
                            onChange={({ target: { value } }) => this.handleSearch(value)}
                            placeholder={this.props.intl.formatMessage({
                                id: 'suppliers_page.filter_placeholder'
                            })}
                        />
                    </Catcher>
                    <SuppliersTable />
                    <SupplierModal
                        modalProps={modalProps}
                        onSubmit={() => {
                            this.props.fetchBusinessSuppliers();
                        }}
                    />
                    <Drawer
                        onClose={() => {
                            this.setState({
                                helperDrawerOpen: false
                            });
                        }}
                        open={helperDrawerOpen}
                        title={<FormattedMessage id='navigation.helper' />}
                        width={420}
                    >
                        <div>
                            {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                                <div className={Styles.linkBlock}>
                                    <div className={Styles.ogTitle}>
                                        {index + 1}. {ogTitle}
                                    </div>
                                    <div className={Styles.ogDesc}>{ogDescription}</div>
                                    <div className={Styles.ogImg}>
                                        <Image
                                            src={
                                                _.isArray(ogImage)
                                                    ? _.get(ogImage, '[0].url', [])
                                                    : _.get(ogImage, 'url', [])
                                            }
                                        />
                                    </div>
                                    <a href={ogUrl} rel='noreferrer' target='_blank'>
                                        <Button
                                            style={{
                                                width: '100%'
                                            }}
                                            type='primary'
                                        >
                                            <FormattedMessage id='repair_map_table.goto' />
                                        </Button>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </Drawer>
                </Layout>
            </div>
        );
    }
}
