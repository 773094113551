import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI } from 'utils';
import {
    ADD_DETAILS_TO_ORDER,
    ADD_LABORS_TO_ORDER,
    FETCH_ORDERS,
    fetchOrdersSuccess,
    selectDetails,
    selectLabors,
    selectOrdersQuery,
    selectSelectedOrderId,
    selectSelectedOrderNum,
    setOrdersFetching
} from './duck';

export function* fetchOrdersSaga(showRTLToo = true) {
    while (true) {
        try {
            yield take(FETCH_ORDERS);
            yield put(setOrdersFetching(true));

            const query = yield select(selectOrdersQuery);

            const { orders, stats } = yield call(fetchAPI, 'GET', 'orders', { ...query, showRTLToo }, null, {
                handleErrorInternally: true
            });

            yield put(fetchOrdersSuccess({ orders, stats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setOrdersFetching(false));
        }
    }
}

export function* addLaborsToOrderSaga() {
    while (true) {
        try {
            yield take(ADD_LABORS_TO_ORDER);

            const selectedOrderId = yield select(selectSelectedOrderId);
            const labors = yield select(selectLabors);

            const payload = {
                insertMode: true,
                details: [],
                services: []
            };

            _.each(labors, labor => {
                payload.services.push({
                    serviceId: labor.laborId,
                    serviceName: labor.serviceName || labor.defaultName,
                    employeeId: labor.employeeId,
                    serviceHours: 0,
                    purchasePrice: labor.purchasePrice,
                    count: Number(labor.count) || 0,
                    servicePrice: Number(labor.servicePrice) || Number(labor.price),
                    laborUnitId: labor.laborUnitId,
                    serviceRowDiscount: labor.serviceRowDiscount
                });
            });

            yield call(fetchAPI, 'PUT', `orders/${selectedOrderId}`, null, payload, {
                handleErrorInternally: true
            });

            history.push({
                pathname: `${book.order}/${selectedOrderId}`
            });
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* addDetailsToOrderSaga() {
    while (true) {
        try {
            yield take(ADD_DETAILS_TO_ORDER);

            const selectedOrderId = yield select(selectSelectedOrderId);
            const selectedOrderNum = yield select(selectSelectedOrderNum);
            const details = yield select(selectDetails);

            const payload = {
                insertMode: true,
                details: [],
                services: []
            };

            _.each(details, product => {
                payload.details.push({
                    storeGroupId: product.storeGroupId,
                    name: product.name,
                    productCode: product.productCode,
                    supplierBrandId: product.supplierBrandId,
                    count: Number(product.count) || 1,
                    price: Number(product.price) || 0,
                    purchasePrice: Number(product.purchasePrice) || 0
                });
            });

            if (selectedOrderNum && selectedOrderNum.includes('RTL')) {
                yield call(fetchAPI, 'PUT', `retail/${selectedOrderId}`, null, payload, {
                    handleErrorInternally: true
                });
            } else {
                yield call(fetchAPI, 'PUT', `orders/${selectedOrderId}`, null, payload, {
                    handleErrorInternally: true
                });
            }
            // history.push({
            //     pathname: `${book.order}/${selectedOrderId}`
            // });
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([call(fetchOrdersSaga), call(addLaborsToOrderSaga), call(addDetailsToOrderSaga)]);
}
