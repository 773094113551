import { Select, Spin } from 'antd';
import { setManagerSearchQuery } from 'core/search/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    managers: state.search.managers,
    isFetchingManagers: state.search.isFetchingManagers,
    user: state.auth
});

const mapDispatchToProps = {
    setManagerSearchQuery
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerSearchField extends Component {
    componentDidMount() {
        this.props.setManagerSearchQuery();
    }

    render() {
        const {
            onSelect,
            setManagerSearchQuery,
            user: { name, surname, id }
        } = this.props;
        const { managers: initManagers, isFetchingManagers, managerId } = this.props;
        const managers = !_.find(initManagers, { managerId: id })
            ? [...initManagers, { managerId: id, managerName: name, managerSurname: surname }]
            : initManagers;

        return (
            <Select
                allowClear
                filterOption={false}
                notFoundContent={
                    isFetchingManagers ? <Spin size='small' /> : <FormattedMessage id='not_found' />
                }
                onChange={managerId => onSelect(managerId)}
                onSearch={item => setManagerSearchQuery(item)}
                placeholder={<FormattedMessage id='select_manager' />}
                showSearch
                style={{ width: '60%' }}
                value={managerId}
            >
                {isFetchingManagers
                    ? []
                    : managers.map(({ managerId, managerName, managerSurname }) => (
                          <Option key={managerId} value={managerId}>
                              {managerSurname} {managerName}
                          </Option>
                      ))}
            </Select>
        );
    }
}
