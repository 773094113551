import { Input } from 'antd';
import _ from 'lodash';
import { SEARCH_TYPES } from 'modals/DebtZeroingModal/components/containers/ClientCounterpartyContainer/constants';
import {
    fetchClients,
    fetchOrders,
    fetchStoreDocs,
    selectClientSearchType,
    selectClientsFilters,
    selectOrdersFilters,
    selectSelectedClientId,
    selectStoreDocsFilters,
    setClientsFiltersPoor,
    setOrdersFiltersPoor,
    setStoreDocsFiltersPoor
} from 'modals/DebtZeroingModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    clientSearchType: selectClientSearchType(state),
    ordersFilters: selectOrdersFilters(state),
    storeDocsFilters: selectStoreDocsFilters(state),
    clientFilters: selectClientsFilters(state),
    selectedClientId: selectSelectedClientId(state)
});

const mapDispatchToProps = {
    setClientsFiltersPoor,
    setOrdersFiltersPoor,
    setStoreDocsFiltersPoor,

    fetchClients,
    fetchOrders,
    fetchStoreDocs
};

/**
 * This module is used to set search for each table depending on a selected search type.
 * This shows search of each query of a table independently(different search queries when changing search type).
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientCounterpartySearchInput extends React.Component {
    constructor(props) {
        super(props);

        // Fetch debounce fetching
        this.handleSearch = _.debounce(() => {
            const {
                clientSearchType,

                fetchClients,
                fetchOrders,
                fetchStoreDocs,

                selectedClientId
            } = this.props;

            switch (clientSearchType) {
                case SEARCH_TYPES.FOR_CLIENT:
                    if (!selectedClientId) fetchClients();
                    else fetchOrders();
                    break;
                case SEARCH_TYPES.FOR_ORDER:
                    fetchOrders();
                    break;
                case SEARCH_TYPES.FOR_DOCUMENT:
                    fetchStoreDocs();
                    break;
            }
        }, 800);
    }

    render() {
        const {
            setClientsFiltersPoor,
            setOrdersFiltersPoor,
            setStoreDocsFiltersPoor,

            clientSearchType,
            ordersFilters,
            storeDocsFilters,
            clientFilters,
            selectedClientId,

            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Input
                    onChange={e => {
                        // Set "query" filter depending on a current search mode
                        switch (clientSearchType) {
                            case SEARCH_TYPES.FOR_CLIENT:
                                if (!selectedClientId) {
                                    setClientsFiltersPoor({ query: e.target.value });
                                } else {
                                    setOrdersFiltersPoor({ query: e.target.value });
                                }
                                break;
                            case SEARCH_TYPES.FOR_ORDER:
                                setOrdersFiltersPoor({ query: e.target.value });
                                break;
                            case SEARCH_TYPES.FOR_DOCUMENT:
                                setStoreDocsFiltersPoor({ query: e.target.value });
                                break;
                        }
                        this.handleSearch();
                    }}
                    placeholder={formatMessage({ id: 'debt_zeroing_modal.placeholder_search' })}
                    value={
                        // Place current search "query" depending on a search type
                        (function () {
                            switch (clientSearchType) {
                                case SEARCH_TYPES.FOR_CLIENT:
                                    if (!selectedClientId) {
                                        return clientFilters.query;
                                    }

                                    return ordersFilters.query;

                                case SEARCH_TYPES.FOR_ORDER:
                                    return ordersFilters.query;
                                case SEARCH_TYPES.FOR_DOCUMENT:
                                    return storeDocsFilters.query;
                            }
                        }())
                    }
                />
            </div>
        );
    }
}
