import { CloseOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';
import { MobileView, ResponsiveView, Spinner } from 'commons';
import { fetchAddClientForm } from 'core/forms/addClientForm/duck';
import {
    createOrder,
    fetchAddOrderForm,
    returnToOrdersPage,
    setCreateStatus
} from 'core/forms/orderForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { fetchVehicleTypes } from 'core/vehicleTypes/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { Layout, MobileAddOrderForm, OrderForm } from 'tireFitting';
import {
    convertFieldsValuesToDbEntity,
    requiredFieldsOnStatuses
} from 'tireFitting/forms/OrderForm/extractOrderEntity';
import { BREAKPOINTS, withErrorMessage } from 'utils';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const mapStateToProps = state => {
    return {
        // addClientFormData: state.forms.addClientForm.data,
        allDetails: state.forms.orderForm.allDetails,
        allServices: state.forms.orderForm.allServices,
        createStatus: state.forms.orderForm.createStatus,
        employees: state.forms.orderForm.employees,
        managers: state.forms.orderForm.managers,
        modal: state.modals.modal,
        orderHistory: state.forms.orderForm.history,
        orderStationLoads: state.forms.orderForm.stationLoads,
        requisites: state.forms.orderForm.requisites,
        selectedClient: state.forms.orderForm.selectedClient,
        spinner: state.ui.orderFetching,
        stations: state.forms.orderForm.stations,
        user: state.auth,
        vehicles: state.forms.orderForm.vehicles,
        vehicleTypes: state.vehicleTypes.vehicleTypes
    };
};

const mapDispatch = {
    fetchAddOrderForm,
    fetchAddClientForm,
    setModal,
    resetModal,
    createOrder,
    setCreateStatus,
    returnToOrdersPage,
    fetchVehicleTypes
};

@withRouter
@connect(mapStateToProps, mapDispatch)
@withErrorMessage()
class AddOrderPage extends Component {
    state = {
        errors: void 0
    };

    componentDidMount() {
        this.props.fetchAddOrderForm();
        this.props.fetchVehicleTypes();
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    saveOrderFormRef = formRef => {
        this.orderFormRef = formRef;
    };

    _createOrder = (redirectStatus, redirectTo) => {
        const form = this.orderFormRef;
        const { allServices, allDetails, selectedClient, createStatus, user } = this.props;
        const requiredFields = requiredFieldsOnStatuses(form.getFieldsValue())[
            this.props.createStatus
        ];

        const fm = form.props ? form.props.form : form;

        fm.validateFields().then(values => {
            const redirectToDashboard = _.get(history, 'location.state.fromDashboard');
            this.props.createOrder({
                order: convertFieldsValuesToDbEntity(
                    { ...values, selectedClient },
                    allServices,
                    allDetails,
                    createStatus,
                    user
                ),
                redirectStatus,
                redirectToDashboard: false,
                redirectTo
            });
        });
    };

    _redirect = () => {
        const { returnToOrdersPage, history, createStatus } = this.props;

        _.get(history, 'location.state.fromDashboard')
            ? history.push(`${book.dashboard}`)
            : returnToOrdersPage(createStatus);
    };

    _setAddClientModal = () => {
        this.props.fetchAddClientForm();
        this.props.setModal(MODALS.ADD_CLIENT);
    };

    _setCreateStatus = status => this.props.setCreateStatus(status);

    render() {
        const { modal, user, createStatus, spinner, vehicleTypes } = this.props;

        return spinner ? (
            <Spinner spin={spinner} />
        ) : (
            <Layout
                controls={
                    <React.Fragment>
                        <div>
                            {window.innerWidth >= 1200 ? (
                                <React.Fragment>
                                    <RadioGroup value={createStatus}>
                                        <RadioButton
                                            onClick={() => this._setCreateStatus('reserve')}
                                            value='reserve'
                                        >
                                            <FormattedMessage id='reserve' />
                                        </RadioButton>
                                        <RadioButton
                                            onClick={() => this._setCreateStatus('not_complete')}
                                            value='not_complete'
                                        >
                                            <FormattedMessage id='not_complete' />
                                        </RadioButton>
                                        <RadioButton
                                            onClick={() => this._setCreateStatus('required')}
                                            value='required'
                                        >
                                            <FormattedMessage id='required' />
                                        </RadioButton>
                                        <RadioButton
                                            onClick={() => this._setCreateStatus('approve')}
                                            value='approve'
                                        >
                                            <FormattedMessage id='approve' />
                                        </RadioButton>
                                    </RadioGroup>
                                    <Button
                                        className={Styles.submit}
                                        htmlType='submit'
                                        onClick={() => {
                                            this._createOrder(createStatus);
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='add' />
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <Button
                                    className={Styles.submit}
                                    htmlType='submit'
                                    onClick={() => {
                                        this._setCreateStatus('reserve');
                                        this._createOrder(createStatus);
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='add' />
                                </Button>
                            )}
                        </div>
                        <CloseOutlined
                            onClick={this._redirect}
                            style={{
                                fontSize: 24,
                                cursor: 'pointer'
                            }}
                        />
                    </React.Fragment>
                }
                title={<FormattedMessage id='orders-page.add_appointment' />}
            >
                <MobileView>
                    <MobileAddOrderForm
                        addOrderForm
                        allDetails={this.props.allDetails}
                        allService={this.props.allServices}
                        errors={this.state.errors}
                        location={this.props.history.location}
                        modal={modal}
                        orderHistory={this.props.orderHistory}
                        orderStationLoads={this.props.orderStationLoads}
                        requisites={[]}
                        selectedClient={{
                            requisites: [],
                            phones: [],
                            emails: [],
                            vehicles: []
                        }}
                        setAddClientModal={this._setAddClientModal}
                        user={user}
                        wrappedComponentRef={this.saveOrderFormRef}
                    />
                </MobileView>
                <ResponsiveView view={{ min: BREAKPOINTS.sm.max, max: BREAKPOINTS.xxl.max }}>
                    <OrderForm
                        addOrderForm
                        allDetails={this.props.allDetails}
                        allService={this.props.allServices}
                        createOrder={this._createOrder}
                        createStatus={createStatus}
                        errors={this.state.errors}
                        location={this.props.history.location}
                        modal={modal}
                        orderHistory={this.props.orderHistory}
                        orderStationLoads={this.props.orderStationLoads}
                        requisites={[]}
                        selectedClient={{
                            requisites: [],
                            phones: [],
                            emails: [],
                            vehicles: []
                        }}
                        setAddClientModal={this._setAddClientModal}
                        user={user}
                        vehicleTypes={vehicleTypes}
                        wrappedComponentRef={this.saveOrderFormRef}
                    />
                </ResponsiveView>
            </Layout>
        );
    }
}

export default AddOrderPage;
