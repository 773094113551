import { ArrowLeftOutlined, CloseOutlined, PhoneFilled, UserOutlined } from '@ant-design/icons';
import { Avatar, Modal } from 'antd';
import { logout } from 'core/auth/duck';
import { setModal } from 'core/modals/duck';
import { setView } from 'core/ui/duck';
import { ProfileForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { AddOrderModal } from 'tireFitting/modals';
import { fetchAPI, images, linkBack } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    logout,
    setModal,
    setView
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class MainMenuPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addOrderModalVisible: false
        };
    }

    fetchBusiness = async () => {
        const business = await fetchAPI('GET', '/business');
        const { supplierNetName, supplierNetPhone, tireShopUrl, accessoriesShopUrl } = business;
        this.setState({
            supplierNetName,
            supplierNetPhone,
            tireShopUrl,
            accessoriesShopUrl
        });
    };

    componentDidMount() {
        this.props.setView({ isMobile: true });
        const { history } = this.props;
        this.fetchBusiness();
        if (window.screen.width >= 768) {
            history.push(book.dashboard);
        }
    }

    render() {
        const { user, logout, setModal } = this.props;
        const { addOrderModalVisible } = this.state;
        const { supplierNetName, supplierNetPhone, tireShopUrl, accessoriesShopUrl, profileModalVisible } = this.state;

        return (
            <div>
                <div className={Styles.menuHeader}>
                    <ArrowLeftOutlined className={Styles.backIcon} onClick={linkBack} />
                    <img alt='logo' className={Styles.logo} src={user.primaryLogoUrl || images.carbookLogo2} />
                    <CloseOutlined className={Styles.closeIcon} onClick={logout} />
                </div>
                <div
                    className={Styles.user}
                    onClick={() => {
                        this.setState({
                            profileModalVisible: true
                        });
                    }}
                >
                    <Avatar className={Styles.avatar} icon={<UserOutlined />} size={64} />
                    <div className={Styles.userName}>
                        {user.name} {user.surname}
                    </div>
                </div>
                <div className={Styles.blocksWrapp}>
                    <div
                        className={Styles.block}
                        onClick={() => {
                            this.setState({
                                addOrderModalVisible: true
                            });
                        }}
                    >
                        <img alt='airFilterIconColored' className={Styles.blockImg} src={images.airFilterIconColored} />
                        <FormattedMessage id='add_order' />
                    </div>
                    <Link className={Styles.block} to={book.dashboard}>
                        <img alt='calendarIconColored' className={Styles.blockImg} src={images.calendarIconColored} />
                        <FormattedMessage id='navigation.planner' />
                    </Link>
                    <Link className={Styles.block} to={book.ordersAppointments}>
                        <img alt='toolsIconColored' className={Styles.blockImg} src={images.toolsIconColored} />
                        <FormattedMessage id='navigation.workflow' />
                    </Link>
                    <Link className={Styles.block} to={book.clientHotOperations}>
                        <img alt='userIconColored' className={Styles.blockImg} src={images.userIconColored} />
                        <FormattedMessage id='navigation.clients' />
                    </Link>
                    <a className={Styles.block} href={tireShopUrl} rel='noreferrer' target='_blank'>
                        <img alt='userIconColored' className={Styles.blockImg} src={images.rimsIconColored} />
                        <FormattedMessage id='tire.to_order_tires' />
                    </a>
                    <a className={Styles.block} href={accessoriesShopUrl} rel='noreferrer' target='_blank'>
                        <img alt='userIconColored' className={Styles.blockImg} src={images.oilIconColored} />
                        <FormattedMessage id='tire.to_order_consumables' />
                    </a>
                    <Link className={Styles.block} to={book.cashFlowPage}>
                        <img alt='paymentIconColored' className={Styles.blockImg} src={images.paymentIconColored} />
                        <FormattedMessage id='navigation.payment' />
                    </Link>
                    <a className={Styles.block} href={`tel:${supplierNetPhone}`}>
                        <PhoneFilled className={Styles.blockImg} style={{ fontSize: 58, color: '#6a717a' }} />
                        <FormattedMessage id='tire.call_center' />
                    </a>
                </div>
                <AddOrderModal
                    hideModal={() => {
                        this.setState({
                            addOrderModalVisible: false
                        });
                    }}
                    isMobile
                    visible={addOrderModalVisible}
                />
                <ProfileFormModal
                    onCancel={() => {
                        this.setState({
                            profileModalVisible: undefined
                        });
                    }}
                    visible={profileModalVisible}
                />
            </div>
        );
    }
}

function ProfileFormModal(props) {
    return (
        <Modal footer={null} onCancel={props.onCancel} visible={props.visible} maskClosable={false}>
            <ProfileForm />
        </Modal>
    );
}
