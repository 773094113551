import { Form } from '@ant-design/compatible';
import { EditOutlined } from '@ant-design/icons';
import { Input, Modal, Table } from 'antd';
import { Catcher } from 'commons';
import {
    fetchManagerRoles,
    hideForms,
    setFilters,
    setPage,
    setSearchQuery,
    setShowUpdateManagerRoleForm,
    setSort,
    updateManagerRole
} from 'core/managerRole/duck';
import { ManagerRoleForm } from 'forms';
import { BusinessSearchField } from 'forms/_formkit';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const FormItem = Form.Item;

const mapDispatchToProps = {
    updateManagerRole,
    fetchManagerRoles,
    setSort,
    setPage,
    setFilters,
    hideForms,
    setShowUpdateManagerRoleForm,
    setSearchQuery
};

const mapStateToProps = state => ({
    managerRole: state.managerRole.managerRole,
    managerRoles: state.managerRole.managerRoles,
    errors: state.managerRole.errors,
    sort: state.managerRole.sort,
    filters: state.managerRole.filters,
    page: state.managerRole.page,
    count: state.managerRole.count,
    searchQuery: state.managerRole.searchQuery,
    isFetching: state.ui.managerRoleFetching,
    user: state.auth
});

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
};

const sortOptions = {
    asc: 'ascend',
    desc: 'descend'
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ManagerRoleContainer extends Component {
    constructor(props) {
        super(props);
        const { sort, setShowUpdateManagerRoleForm } = props;
        const { formatMessage } = props.intl;

        this.columns = [
            {
                title: formatMessage({
                    id: 'manager-role-container.business_name'
                }),
                dataIndex: 'businessName',
                sorter: true,
                sortOrder: this._handleColumnOrder(sort, 'businessName'),
                width: '25%'
            },
            {
                title: formatMessage({
                    id: 'manager-role-container.manager_name'
                }),
                dataIndex: 'managerName',
                sorter: true,
                sortOrder: this._handleColumnOrder(sort, 'managerName'),
                width: '20%'
            },
            {
                title: formatMessage({
                    id: 'manager-role-container.manager_surname'
                }),
                dataIndex: 'managerSurname',
                sorter: true,
                sortOrder: this._handleColumnOrder(sort, 'managerSurname'),
                width: '20%'
            },
            {
                title: formatMessage({
                    id: 'manager-role-container.roles'
                }),
                dataIndex: 'roles',
                render: name => name.map(({ roleName }) => roleName).join(','),
                width: '20%'
            },

            {
                width: '15%',
                render: record => (
                    <EditOutlined
                        className={Styles.businessEditIcon}
                        onClick={() => setShowUpdateManagerRoleForm(record)}
                    />
                )
            }
        ];
    }

    componentDidMount() {
        this.props.fetchManagerRoles();
    }

    _handleColumnOrder = (sort, fieldName) =>
        sort.field === fieldName ? sortOptions[sort.order] : false;

    _handleTableChange = (pagination, filters, sorter) => {
        if (!sorter) {
            return;
        }
        const sort = {
            field: sorter.field,
            order: sorter.order === 'ascend' ? 'asc' : 'desc'
        };

        if (!_.isEqual(sort, this.props.sort)) {
            this.props.setSort(sort);
        }
    };

    render() {
        const {
            managerRole,
            managerRoles,
            filters,
            searchQuery,

            hideForms,
            setSearchQuery,
            updateManagerRole,
            setFilters
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(this.props.count / 25) * 25,
            hideOnSinglePage: true,
            current: this.props.page,
            onChange: page => this.props.setPage(page)
        };

        return (
            <Catcher>
                <Form className={Styles.businessPackageFilters} layout='inline'>
                    <FormItem
                        {...formItemLayout}
                        className={Styles.formItemSelectFilter}
                        colon={false}
                        label={<FormattedMessage id='manager-role-container.search' />}
                    >
                        <Input
                            onChange={({ target: { value } }) => setSearchQuery(value)}
                            value={searchQuery}
                        />
                    </FormItem>
                        <FormItem
                            {...formItemLayout}
                            className={Styles.formItemSelectFilter}
                            colon={false}
                            label={<FormattedMessage id='business-package-container.business' />}
                        >
                            <BusinessSearchField
                                businessId={filters.businessId}
                                onSelect={businessId => setFilters({ businessId })}
                            />
                        </FormItem>
                </Form>
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={managerRoles}
                    loading={this.props.isFetching}
                    onChange={this._handleTableChange}
                    pagination={pagination}
                    rowKey={record => record.managerId}
                    size='small'
                />
                <Modal
                    destroyOnClose
                    footer={null}
                    maskClosable={false}
                    onCancel={() => hideForms()}
                    title={<FormattedMessage id='manager-role-container.edit_form_title' />}
                    visible={Boolean(managerRole)}
                >
                    {managerRole && (
                        <ManagerRoleForm
                            managerRole={managerRole}
                            updateManagerRole={updateManagerRole}
                        />
                    )}
                </Modal>
            </Catcher>
        );
    }
}
