import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Numeral extends Component {
    static propTypes = {
        children: PropTypes.number,
        mask: PropTypes.string,
        currency: PropTypes.string,
        url: PropTypes.string,
        urlBlank: PropTypes.bool,
        nullText: PropTypes.string,
        styler: PropTypes.func
    };

    static defaultProps = {
        children: 0,
        mask: '0,0',
        currency: '',
        url: null,
        urlBlank: false,
        nullText: '0'
    };

    numFormatter() {
        const { children, url, mask, currency, nullText } = this.props;
        const displayText = `${numeral(children).format(mask) || nullText}${currency && ` ${currency}`}`;
        if (children) {
            if (url) {
                return (
                    <Link to={url}>
                        <nobr>{displayText}</nobr>
                    </Link>
                );
            }

            return <nobr>{displayText}</nobr>;
        }

        return <span>{displayText}</span>;
    }

    render() {
        return <span className={this.props.className}>{this.numFormatter()}</span>;
    }
}
