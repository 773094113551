import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectClients,
    selectFetchingSelectedClient,
    selectSelectedClient,
    selectSelectedClientId,
    setClientsFilters
} from 'modals/RefactoredCashOrderModal/redux/clients/duck';
import {
    selectClientSearchType,
    selectMode,
    setClientSearchType,
    setOrdersFilters,
    setSelectedClientId,
    setStoreDocsFilters
} from 'modals/RefactoredCashOrderModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    mode: selectMode(state),
    clientSearchType: selectClientSearchType(state),
    selectedClientId: selectSelectedClientId(state),
    selectedClient: selectSelectedClient(state),
    clients: selectClients(state),
    fetchingSelectedClient: selectFetchingSelectedClient(state)
});

const mapDispatchToProps = {
    setClientSearchType,
    setClientsFilters,
    setOrdersFilters,
    setStoreDocsFilters,
    setSelectedClientId
};

/**
 * If client is selected this component generates block with its basic
 * information and ability to remove currently selected client(make no one selected)
 *
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientVisualizer extends React.Component {
    render() {
        const {
            mode,
            setOrdersFilters,

            selectedClient,
            fetchingSelectedClient,
            setSelectedClientId,
            editable
        } = this.props;

        const vehicles = _.get(selectedClient, 'vehicles');
        const phones = _.get(selectedClient, 'phones');
        const disabled = Boolean(mode != MODES.ADD);

        if (!selectedClient) return 'No selected client data';
        if (fetchingSelectedClient) return <LoadingOutlined />;

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col span={4}></Col>
                    <Col className={Styles.col} span={8}>
                        <Link to={`${book.client}/${selectedClient.clientId}`}>
                            <span>{`${selectedClient.name || ''} ${
                                selectedClient.middleName || ''
                            } ${selectedClient.surname || ''}`}</span>
                        </Link>
                        {phones &&
                            _.map(
                                _.map(phones, (phone, index) => {
                                    return (
                                        <div key={index}>
                                            <a className={Styles.phoneNumber} href={`tel:${phone}`}>
                                                {phone}
                                            </a>
                                        </div>
                                    );
                                })
                            )}
                    </Col>
                    <Col className={Styles.col} span={8}>
                        {vehicles
                            ? _.map(vehicles, (vehicle, index) => {
                                  return (
                                      <div key={index} className={Styles.vehicle}>
                                          <Link to={`${book.vehicle}/${vehicle.id}`}>
                                              {`${_.get(vehicle, 'make', '')} ${_.get(
                                                  vehicle,
                                                  'model',
                                                  ''
                                              )} ${_.get(vehicle, 'year', '')}`}
                                          </Link>
                                      </div>
                                  );
                              })
                            : null}
                    </Col>
                    <Col span={2}></Col>
                    <Col className={Styles.actionsCol} span={2}>
                        {!disabled || editable ? (
                            <CloseOutlined
                                className={Styles.closeIcon}
                                onClick={() => {
                                    setSelectedClientId(undefined); // Remove currently selected client
                                    setOrdersFilters({ client: undefined }); // Remove filter from orders
                                }}
                            />
                        ) : null}
                    </Col>
                </Row>
            </div>
        );
    }
}
