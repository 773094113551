import { Col, Row } from 'antd';
import { Catcher, Numeral } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const MASK = '0,0.00';

export default class StatsOrderCountsPanel extends Component {
    isValidValue(value) {
        return !(
            value == Number.POSITIVE_INFINITY ||
            value == Number.NEGATIVE_INFINITY ||
            isNaN(value)
        );
    }

    render() {
        const {
            stats = [] // Array of objects composed of { key, value, label } properties
        } = this.props;

        return stats ? (
            <Catcher>
                <div>
                    {_.map(stats, obj => (
                        <div key={obj.key || v4()} className={Styles.orderStatsWrapper}>
                            <Row className={Styles.orderStatsRow}>
                                <Col span={6}>
                                    <FormattedMessage id={`order_stats_modal.${obj.label}`} />
                                </Col>
                                <Col className={Styles.valueOrderStats} span={6}>
                                    {obj.laborValue ? (
                                        <Numeral mask={MASK}>{Number(obj.laborValue)}</Numeral>
                                    ) : (
                                        <FormattedMessage id='long_dash' />
                                    )}
                                </Col>
                                <Col className={Styles.valueOrderStats} span={6}>
                                    {obj.appurtenanceValue ? (
                                        <Numeral mask={MASK}>
                                            {Number(obj.appurtenanceValue)}
                                        </Numeral>
                                    ) : (
                                        <FormattedMessage id='long_dash' />
                                    )}
                                </Col>
                                <Col className={Styles.valueOrderStats} span={6}>
                                    {obj.totalValue ? (
                                        <Numeral mask={MASK}>{Number(obj.totalValue)}</Numeral>
                                    ) : (
                                        <FormattedMessage id='long_dash' />
                                    )}
                                </Col>
                            </Row>
                            {obj.withPercent ? (
                                <Row className={Styles.orderStatsRow}>
                                    <Col span={6}></Col>
                                    <Col className={Styles.valueOrderStats} span={6}>
                                        {obj.laborDiscount &&
                                        this.isValidValue(obj.laborDiscount) ? (
                                            `${obj.laborDiscount}%`
                                        ) : (
                                            <FormattedMessage id='long_dash' />
                                        )}
                                    </Col>
                                    <Col className={Styles.valueOrderStats} span={6}>
                                        {obj.appurtenanceDiscount &&
                                        this.isValidValue(obj.appurtenanceDiscount) ? (
                                            `${obj.appurtenanceDiscount}%`
                                        ) : (
                                            <FormattedMessage id='long_dash' />
                                        )}
                                    </Col>
                                    <Col className={Styles.valueOrderStats} span={6}>
                                        {obj.totalDiscount &&
                                        this.isValidValue(obj.totalDiscount) ? (
                                            `${obj.totalDiscount}%`
                                        ) : (
                                            <FormattedMessage id='long_dash' />
                                        )}
                                    </Col>
                                </Row>
                            ) : null}
                        </div>
                    ))}
                </div>
            </Catcher>
        ) : null;
    }
}
