exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__scheduleBreakIcon---3VNRl{font-size:20px;cursor:pointer}.styles-m__newScheduleBreak---_Qr9K{display:flex;align-items:center;margin:24px auto}.styles-m__scheduleType---ttZUV{width:100%}.styles-m__breakScheduleFormItem---26lmt{margin-bottom:0}.styles-m__newBreakScheduleRow---h5AwL{background-color:rgba(11,127,0,.28)}.styles-m__editedBreakScheduleRow---2QIpW{background-color:rgba(255,45,45,.28)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,qCACI,cAAe,CACf,cACJ,CAEA,oCACI,YAAa,CACb,kBAAmB,CACnB,gBACJ,CAEA,gCACI,UACJ,CAEA,yCACI,eACJ,CAEA,uCACI,mCACJ,CAEA,0CACI,oCACJ","file":"styles.m.css","sourcesContent":[".scheduleBreakIcon {\n    font-size: 20px;\n    cursor: pointer;\n}\n\n.newScheduleBreak {\n    display: flex;\n    align-items: center;\n    margin: 24px auto;\n}\n\n.scheduleType {\n    width: 100%;\n}\n\n.breakScheduleFormItem {\n    margin-bottom: 0;\n}\n\n.newBreakScheduleRow {\n    background-color: rgba(11, 127, 0, 0.28);\n}\n\n.editedBreakScheduleRow {\n    background-color: rgba(255, 45, 45, 0.28);\n}\n"]}]);

// Exports
exports.locals = {
	"scheduleBreakIcon": "styles-m__scheduleBreakIcon---3VNRl",
	"newScheduleBreak": "styles-m__newScheduleBreak---_Qr9K",
	"scheduleType": "styles-m__scheduleType---ttZUV",
	"breakScheduleFormItem": "styles-m__breakScheduleFormItem---26lmt",
	"newBreakScheduleRow": "styles-m__newBreakScheduleRow---h5AwL",
	"editedBreakScheduleRow": "styles-m__editedBreakScheduleRow---2QIpW"
};