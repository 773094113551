exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__stationLoadsTable---2DNOr .table-small{padding:0}.styles-m__status---3Gu2y{display:flex;align-items:center}.styles-m__deleteIcon---bij5P{color:var(--warning);font-size:24px}.styles-m__staticStationLoadsRow---3jtuL{background:var(--static)}.styles-m__newStationLoadsRow---2lB_6{background-color:rgba(11,127,0,.28)}.styles-m__editedStationLoadsRow---3Y02v{background-color:rgba(255,45,45,.28)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,kDACI,SACJ,CAEA,0BACI,YAAa,CACb,kBACJ,CAEA,8BACI,oBAAqB,CACrB,cACJ,CAEA,yCACI,wBACJ,CAEA,sCACI,mCACJ,CAEA,yCACI,oCACJ","file":"styles.m.css","sourcesContent":[".stationLoadsTable :global(.table-small) {\n    padding: 0;\n}\n\n.status {\n    display: flex;\n    align-items: center;\n}\n\n.deleteIcon {\n    color: var(--warning);\n    font-size: 24px;\n}\n\n.staticStationLoadsRow {\n    background: var(--static);\n}\n\n.newStationLoadsRow {\n    background-color: rgba(11, 127, 0, 0.28);\n}\n\n.editedStationLoadsRow {\n    background-color: rgba(255, 45, 45, 0.28);\n}\n"]}]);

// Exports
exports.locals = {
	"stationLoadsTable": "styles-m__stationLoadsTable---2DNOr",
	"status": "styles-m__status---3Gu2y",
	"deleteIcon": "styles-m__deleteIcon---bij5P",
	"staticStationLoadsRow": "styles-m__staticStationLoadsRow---3jtuL",
	"newStationLoadsRow": "styles-m__newStationLoadsRow---2lB_6",
	"editedStationLoadsRow": "styles-m__editedStationLoadsRow---3Y02v"
};