import React from 'react';
import { FormattedMessage } from 'react-intl';

// Choose width for each column
const defWidth = {
    korText: '20%',
    itemmpText: '25%',
    qualColText: '10%',
    storeGroupName: '18%',
    price: '10%',
    workTime: '7%',
    sum: '10%'
};

export function columnsConfig() {
    const korTextCol = {
        title: <FormattedMessage id='order_form_table.service_type' />,
        dataIndex: 'korText',
        key: 'korText'
    };

    const itemmpTextCol = {
        title: <FormattedMessage id='services_table.service_type' />,
        dataIndex: 'itemmpText',
        key: 'itemmpText'
    };
    const qualColTextCol = {
        title: <FormattedMessage id='array-break-schedule.comment' />,
        dataIndex: 'qualColText',
        key: 'qualColText'
    };

    const storeGroupNameCol = {
        title: <FormattedMessage id='array-break-schedule.store_group' />,
        dataIndex: 'storeGroupName',
        key: 'storeGroupName'
    };

    const priceCol = {
        title: <FormattedMessage id='order_form_table.price' />,
        align: 'right',
        dataIndex: 'price',
        key: 'price'
    };

    const workTimCol = {
        title: <FormattedMessage id='services_table.norm_hours' />,
        align: 'right',
        dataIndex: 'workTime',
        key: 'workTime'
    };

    const sumCol = {
        title: <FormattedMessage id='orders.sum' />,
        align: 'right',
        dataIndex: 'sum',
        key: 'sum'
    };

    return [
        korTextCol,
        itemmpTextCol,
        qualColTextCol,
        storeGroupNameCol,
        priceCol,
        workTimCol,
        sumCol
    ];
}
