exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__tableWrapper---1-aW3{display:flex;flex-direction:column}.styles-m__tableHead---vpaKa{display:flex;justify-content:space-between;margin-bottom:16px}.styles-m__tableHeadText---X4CQo{color:var(--primary)}.styles-m__purchaseButton---lkTfX{text-align:center;margin:24px}.styles-m__datePickerField---wSrX5{width:320px}.styles-m__inProgress---2B0Jd{color:var(--orange);white-space:nowrap}.styles-m__paid---KXGmY{color:var(--green);white-space:nowrap}@media only screen and (min-width:1600px){.styles-m__tableWrapper---1-aW3{flex:0 1 47.5%}}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACI,YAAa,CACb,qBAKJ,CAEA,6BACI,YAAa,CACb,6BAA8B,CAC9B,kBACJ,CAEA,iCACI,oBACJ,CAEA,kCACI,iBAAkB,CAClB,WACJ,CAEA,mCACI,WACJ,CAEA,8BACI,mBAAoB,CACpB,kBACJ,CAEA,wBACI,kBAAmB,CACnB,kBACJ,CAhCI,0CAJJ,gCAKQ,cAER,CADI","file":"styles.m.css","sourcesContent":[".tableWrapper {\n    display: flex;\n    flex-direction: column;\n\n    @media only screen and (min-width: 1600px) {\n        flex: 0 1 47.5%;\n    }\n}\n\n.tableHead {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 16px;\n}\n\n.tableHeadText {\n    color: var(--primary);\n}\n\n.purchaseButton {\n    text-align: center;\n    margin: 24px;\n}\n\n.datePickerField {\n    width: 320px;\n}\n\n.inProgress {\n    color: var(--orange);\n    white-space: nowrap;\n}\n\n.paid {\n    color: var(--green);\n    white-space: nowrap;\n}\n"]}]);

// Exports
exports.locals = {
	"tableWrapper": "styles-m__tableWrapper---1-aW3",
	"tableHead": "styles-m__tableHead---vpaKa",
	"tableHeadText": "styles-m__tableHeadText---X4CQo",
	"purchaseButton": "styles-m__purchaseButton---lkTfX",
	"datePickerField": "styles-m__datePickerField---wSrX5",
	"inProgress": "styles-m__inProgress---2B0Jd",
	"paid": "styles-m__paid---KXGmY"
};