import { emitError } from 'core/ui/duck';
import { selectVehicleId } from 'pages/OnlineServiceBookPage/redux/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchRequest } from '../api';
import {
    fetchVehicleOrdersSuccess,
    FETCH_VEHICLE_ORDERS,
    selectVehicleOrdersSort,
    setFetchingVehicleOrders
} from './duck';

export function* fetchVehicleOrdersSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_ORDERS);

            yield put(setFetchingVehicleOrders(true));

            const vehicleId = yield select(selectVehicleId);

            const sort = yield select(selectVehicleOrdersSort);

            const { orders, stats, vehicleData } = yield call(
                fetchRequest,
                'GET',
                `orders/vehicle/${vehicleId}`,
                {
                    page: sort.page,
                    pageSize: sort.pageSize,
                    startDate: sort.startDate,
                    endDate: sort.endDate,
                    startSuccessDatetime: sort.startSuccessDatetime,
                    endSuccessDatetime: sort.endSuccessDatetime,
                    queryFilters: sort.queryFilters,
                    mileageFrom: sort.mileageFrom,
                    mileageTo: sort.mileageTo,
                    statuses: sort.statuses
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchVehicleOrdersSuccess({ orders, stats, vehicleData }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingVehicleOrders(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchVehicleOrdersSaga)]);
}
