import { RollbackOutlined } from '@ant-design/icons';
import { Layout, Spinner } from 'commons';
import { RoleContainer } from 'containers';
import { fetchRoles } from 'core/role/duck';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';

const mapStateToProps = state => ({
    isFetching: state.ui.roleFetching,
    roles: state.roles.roles
});

const mapDispatchToProps = {
    fetchRoles
};

@connect(mapStateToProps, mapDispatchToProps)
export default class RolePage extends Component {
    componentDidMount() {
        this.props.fetchRoles();
    }

    render() {
        const { isFetching, roles } = this.props;

        return isFetching ? (
            <Spinner spin={isFetching} />
        ) : (
            <Layout
                controls={
                    <Link to={`${book.packagePage}`}>
                        <RollbackOutlined />
                        <FormattedMessage id='roles.back_to_packages' />
                    </Link>
                }
                title={<FormattedMessage id='roles' />}
            >
                <RoleContainer roles={roles} />
            </Layout>
        );
    }
}
