import { Switch, Table } from 'antd';
import { Select } from 'antd/lib';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const dateFormat = 'DD.MM.YYYY';
const { Option } = Select;

const SalaryAdditionTable = ({
    intl,
    isMobile,
    query,
    id,
    status,
    data,
    saveSalaryDocData,
    additions,
    fetchAddition,
    disabled
}) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    // const [additions, setAdditions] = useState();
    const [statuses, setStatuses] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const dataSourceAddition = [
        {
            title: <FormattedMessage id='employee_salary_rules.total_accrual_sum' />,
            value: 'totalAccrualSum'
        },
        {
            title: <FormattedMessage id='employee_salary_rules.salary' />,
            value: 'salary'
        },
        {
            title: <FormattedMessage id='employee_salary_rules.total_accrual_sum_without_tax' />,
            value: 'totalAccrualSumWithoutTax'
        },
        {
            title: <FormattedMessage id='employee_salary_rules.total_accrual_sum_with_tax' />,
            value: 'totalAccrualSumWithTax'
        }
    ];

    const dataSourceTaxes =
        additions && additions.taxes
            ? [
                  ...additions.taxes,
                  {
                      taxName: <FormattedMessage id='Всього податків' />,
                      bruttoTaxResult: additions.bruttoTotalTaxSum,
                      nettoTaxResult: additions.nettoTotalTaxSum
                  },
                  {
                      taxName: <FormattedMessage id='Сума ЗП без податків' />,
                      bruttoTaxResult: additions.bruttoSalNoTaxSum,
                      nettoTaxResult: additions.nettoSalNoTax
                  }
              ]
            : undefined;

    const groupedData = [
        {
            title: true,
            filterCondition: value =>
                ['totalAccrualSum', 'salary', 'totalAccrualSumWithoutTax', 'totalAccrualSumWithTax'].includes(value),
            mainTitle: <FormattedMessage id='employee_salary_rules.accrual' />
        },
        {
            title: false,
            condition: dataSourceTaxes,
            mainTitle: <FormattedMessage id='salary_tax' />
        },
        {
            title: false,
            condition: additions && additions.deductions,
            mainTitle: <FormattedMessage id='employee_salary_rules.PENALTY' />
        }
    ];

    // const fetchAddition = useCallback(async () => {
    //     setLoading(true);
    //     const response = await fetchAPI(
    //         'GET',
    //         '/salary/doc/addition',
    //         { docId: id, status: status || undefined },
    //         null,
    //         {
    //             handleErrorInternally: true
    //         }
    //     );

    //     setAdditions(response);

    //     setLoading(false);
    // }, [id, status]);

    // useEffect(() => {
    //     fetchAddition();
    // }, [fetchAddition]);

    const findField = name =>
        ({
            totalAccrualSum: {
                brutto: additions.accrualSum,
                netto: additions.accrualSum,
                percent: <FormattedMessage id='long_dash' />
            },
            salary: {
                brutto: data.rateSum,
                netto: data.rateSum,
                percent: <FormattedMessage id='long_dash' />
            },
            totalAccrualSumWithoutTax: {
                brutto: additions.totalAccrualSum,
                netto: additions.totalAccrualSum,
                percent: <FormattedMessage id='long_dash' />
            },
            totalAccrualSumWithTax: {
                brutto: null,
                netto: additions.nettoTotalSum,
                percent: <FormattedMessage id='long_dash' />
            }
        }[name]);

    const columns = (withTitle, mainTitle) => [
        {
            title: mainTitle || undefined,
            key: 'accrual',
            width: '200px',
            render: row => row.title || row.taxName || row.deductionName
        },
        {
            title: () => (withTitle ? `% / ${intl.formatMessage({ id: 'cur' })}` : undefined),
            key: 'cur',
            width: '100px',
            align: 'center',
            render: row => {
                const data = findField(row.value);

                return (data && data.percent) || row.taxValue || row.deductionValue;
            }
        },
        {
            title: withTitle ? <FormattedMessage id='employee_salary_rules.gross' /> : undefined,
            key: 'gross',
            align: 'center',
            width: '100px',
            render: row => {
                const data = findField(row.value);

                return (
                    (data && Number(data.brutto || 0).toFixed(2)) ||
                    (row.bruttoTaxResult && Number(row.bruttoTaxResult).toFixed(2)) ||
                    (row.bruttoDeductionResult && Number(row.bruttoDeductionResult).toFixed(2)) || (
                        <FormattedMessage id='long_dash' />
                    )
                );
            }
        },
        {
            title: withTitle ? <FormattedMessage id='employee_salary_rules.net' /> : undefined,
            key: 'net',
            align: 'center',
            width: '100px',
            render: row => {
                const data = findField(row.value);

                return (
                    (data && Number(data.netto || 0).toFixed(2)) ||
                    (row.nettoTaxResult && Number(row.nettoTaxResult).toFixed(2)) ||
                    (row.nettoDeductionResult && Number(row.nettoDeductionResult).toFixed(2)) || (
                        <FormattedMessage id='long_dash' />
                    )
                );
            }
        }
    ];

    return (
        additions && (
            <div>
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <span
                        style={{
                            marginRight: 8
                        }}
                    >
                        <FormattedMessage id='employee_salary_rules.gross_accrual' />
                    </span>
                    <Switch
                        checked={!data.employeeIsNettoSalary}
                        disabled
                        onChange={async () => {
                            await saveSalaryDocData(!additions.isBrutto);
                            fetchAddition();
                        }}
                        style={{
                            marginBottom: 8,
                            marginLeft: 8
                        }}
                    />
                </div>
                <div>
                    {additions &&
                        groupedData.map(({ title, filterCondition, condition, mainTitle }, index) => {
                            const data = filterCondition
                                ? dataSourceAddition.filter(({ value }) => filterCondition(value))
                                : condition;

                            return (
                                <Table
                                    columns={columns(title, mainTitle)}
                                    dataSource={data}
                                    loading={loading}
                                    pagination={false}
                                    scroll={isMobile ? { x: 1000 } : {}}
                                    size='small'
                                />
                            );
                        })}
                </div>
            </div>
        )
    );
};

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

export default connect(mapStateToProps)(injectIntl(SalaryAdditionTable));
