import { CloseOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Select, Table, TreeSelect } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;
const { TreeNode } = TreeSelect;
const { confirm } = Modal;
const { Option } = Select;

@injectIntl
class DetailProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            mainTableSource: [],
            brandSearchValue: '',
            defaultBrandName: undefined
        };
        this.labors = [];
        this.storeGroups = [];
        this.suppliers = [];
        this.treeData = [];
        this.servicesOptions = [];
        this.suppliersOptions = [];

        this.mainTableColumns = [
            {
                title: <FormattedMessage id='order_form_table.store_group' />,
                key: 'storeGroupId',
                dataIndex: 'storeGroupId',
                width: '12%',
                render: (data, elem) => {
                    return (
                        <TreeSelect
                            className={Styles.groupsTreeSelect}
                            disabled={this.state.editing || elem.related}
                            dropdownMatchSelectWidth={this.props.isMobile}
                            dropdownStyle={{
                                maxHeight: 400,
                                maxWidth: '95%',
                                overflow: 'auto',
                                zIndex: '9999'
                            }}
                            filterTreeNode={(input, node) => {
                                return (
                                    node.props.title.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0 ||
                                    String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onSelect={(value, option) => {
                                elem.storeGroupId = value;
                                elem.detailName = option.props.name;
                                elem.multiplier = option.props.multiplier;
                                this.filterOptions(value);
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.store_group'
                            })}
                            showSearch
                            style={{ minWidth: 240, color: 'var(--text)' }}
                            treeData={this.props.treeData}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'detailName',
                dataIndex: 'detailName',
                render: (data, elem) => {
                    return (
                        <Input
                            disabled={elem.storeGroupId == null}
                            onChange={event => {
                                elem.detailName = event.target.value;
                                this.setState({});
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'order_form_table.detail_name'
                            })}
                            style={{ minWidth: 240 }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                dataIndex: 'price',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.detailNumberInput}
                            decimalSeparator=','
                            disabled={elem.storeGroupId == null}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={1}
                            onChange={value => {
                                elem.price = value;
                                elem.sum = value * elem.count;
                                this.setState({});
                            }}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            value={Math.round(data * 10) / 10 || 1}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'count',
                dataIndex: 'count',
                render: (data, elem) => {
                    return (
                        <InputNumber
                            className={Styles.detailNumberInput}
                            decimalSeparator=','
                            disabled={elem.storeGroupId == null}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={0.1}
                            onChange={value => {
                                elem.count = value;
                                elem.sum = value * elem.price;
                                this.setState({});
                            }}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            value={Math.round(data * 10) / 10 || 1}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'sum',
                render: elem => {
                    const sum = elem.price * (elem.count || 1);

                    return (
                        <InputNumber
                            className={Styles.detailNumberInput}
                            decimalSeparator=','
                            disabled
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            style={{ color: 'black' }}
                            value={sum ? Math.round(sum * 10) / 10 : 1}
                        />
                    );
                }
            },
            {
                key: 'delete',
                render: elem => {
                    return (
                        <CloseOutlined
                            onClick={() => {
                                elem.storeGroupId =
                                    this.state.editing || elem.related
                                        ? elem.storeGroupId
                                        : undefined;
                                elem.detailName = undefined;
                                elem.price = 1;
                                elem.count = 1;
                                elem.sum = undefined;
                                this.setState({});
                            }}
                        />
                    );
                }
            }
        ];
    }

    handleOk = () => {
        const { editing, mainTableSource } = this.state;
        if (editing) {
            this.props.updateDetail(this.props.tableKey, { ...mainTableSource[0] });
        } else {
            const data = {
                insertMode: true,
                details: [],
                services: []
            };
            mainTableSource.map(element => {
                data.details.push({
                    storeGroupId: element.storeGroupId,
                    name: element.detailName,
                    count: element.count ? element.count : 1,
                    price: element.price ? Math.round(element.price * 10) / 10 : 1
                });
            });
            this.addDetailsAndLabors(data);
        }
        this.props.hideModal();
    };

    handleCancel = () => {
        this.setState({
            mainTableSource: []
        });
        this.props.hideModal();
    };

    async addDetailsAndLabors(data) {
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (result.success) {
                this.props.updateDataSource();
            } else {
                console.log('BAD', result);
            }
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    fetchData() {}

    getOptions() {
        this.servicesOptions = this.props.labors.map((elem, index) => (
            <Option
                key={index}
                norm_hours={elem.normHours}
                price={elem.price}
                product_id={elem.productId}
                value={elem.laborId}
            >
                {elem.name ? elem.name : elem.defaultName}
            </Option>
        ));

        this.suppliersOptions = this.suppliers.map((elem, index) => (
            <Option key={index} value={elem.id}>
                {elem.name}
            </Option>
        ));
    }

    filterOptions(id) {
        const servicesOptions = [];
        this.labors.map((elem, index) => {
            if (elem.productId == id) {
                servicesOptions.push(
                    <Option
                        key={index}
                        norm_hours={elem.normHours}
                        price={elem.price}
                        product_id={elem.productId}
                        value={elem.laborId}
                    >
                        {elem.name ? elem.name : elem.defaultName}
                    </Option>
                );
            } else {
            }
        });

        this.servicesOptions = [...servicesOptions];
    }

    deleteDetail = async () => {
        await fetchAPI('DELETE', 'orders/details', undefined, { ids: [this.props.detail.id] });
        this.props.updateDataSource();
        this.handleCancel();
    };

    confirmDelete = () => {
        const { formatMessage } = this.props.intl;
        const that = this;
        confirm({
            title: formatMessage({ id: 'add_order_form.delete_confirm' }),
            onOk() {
                that.deleteDetail();
            },
            okType: 'danger'
        });
    };

    getMobileForm() {
        const { mainTableSource } = this.state;
        const dataSource = mainTableSource[0] || {};
        const columns = [...this.mainTableColumns];
        columns.pop();

        return columns.map(({ title, key, render, dataIndex }) => {
            return (
                <div
                    key={key}
                    className={`${Styles.mobileTable} ${
                        (key == 'price' || key == 'count') && Styles.mobileTableNumber
                    } ${key == 'sum' && Styles.mobileTableSum}`}
                >
                    {title}
                    <div>
                        {dataIndex ? render(dataSource[dataIndex], dataSource) : render(dataSource)}
                    </div>
                </div>
            );
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible == false && this.props.visible) {
            const editing = Boolean(this.props.detail && this.props.detail.storeGroupId);
            this.setState({
                editing,
                mainTableSource: [{ ...this.props.detail, key: 0 }]
            });
        }
    }

    render() {
        const { visible, tableMode, isMobile } = this.props;
        const { editing } = this.state;

        return (
            <div>
                <Modal
                    footer={
                        isMobile && editing ? (
                            <div>
                                <Button
                                    onClick={() => this.confirmDelete()}
                                    style={{
                                        float: 'left'
                                    }}
                                    type='danger'
                                >
                                    <DeleteOutlined />
                                </Button>
                                <Button onClick={() => this.handleCancel()}>
                                    <FormattedMessage id='cancel' />
                                </Button>
                                <Button onClick={() => this.handleOk()} type='primary'>
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        ) : (
                            void 0
                        )
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    style={
                        !isMobile
                            ? {
                                  minWidth: 560
                              }
                            : {
                                  minWidth: '95%'
                              }
                    }
                    title={null}
                    visible={visible}
                    width='min-content'
                >
                    <div className={Styles.tableWrap}>
                        <div className={Styles.modalSectionTitle}>
                            <div style={{ display: 'block' }}>
                                <FormattedMessage id='order_form_table.diagnostic.detail' />
                            </div>
                        </div>
                        {!isMobile ? (
                            <Table
                                bordered
                                columns={this.mainTableColumns}
                                dataSource={this.state.mainTableSource}
                                pagination={false}
                                size='small'
                            />
                        ) : (
                            this.getMobileForm()
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
}
export default DetailProductModal;
