import { Form } from '@ant-design/compatible';
import { InputNumber } from 'antd';
import { Catcher } from 'commons';
import { DecoratedInputNumber } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const FormItem = Form.Item;

@injectIntl
class DiscountPanel extends Component {
    shouldComponentUpdate(nextProps) {
        return !_.isEqual(nextProps, this.props);
    }

    render() {
        const {
            form: { getFieldDecorator },
            price,
            totalDetailsProfit,
            totalServicesProfit,
            discountFieldName,
            fetchedOrder,
            forbidden,
            detailsMode,
            servicesMode
        } = this.props;

        const discount = this.props.form.getFieldValue(discountFieldName);

        const total = (price - price * (discount / 100)).toFixed(2);

        const profit = servicesMode ? totalServicesProfit : totalDetailsProfit;

        return (
            <Catcher>
                <div className={Styles.discountPanel}>
                    <DecoratedInputNumber
                        className={Styles.formItem}
                        colon={false}
                        disabled={forbidden}
                        field={discountFieldName}
                        formatter={value => `${Math.round(value)}%`}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={_.get(fetchedOrder, `order.${discountFieldName}`) || 0}
                        label={<FormattedMessage id='order_form_table.discount' />}
                        max={100}
                        min={-100}
                        onChange={value => {
                            this.props.reloadOrderForm();
                        }}
                        parser={value => value.replace('%', '')}
                        step={1}
                    />
                    <FormItem
                        className={Styles.formItem}
                        colon={false}
                        label={<FormattedMessage id='order_form_table.sum_without_discount' />}
                    >
                        <InputNumber
                            decimalSeparator=','
                            disabled
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={0}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            style={{ color: 'black' }}
                            value={price}
                        />
                    </FormItem>
                    <FormItem
                        className={Styles.formItem}
                        colon={false}
                        label={<FormattedMessage id='order_form_table.total_sum' />}
                    >
                        <InputNumber
                            decimalSeparator=','
                            disabled
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            min={0}
                            parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                            style={{ color: 'black' }}
                            value={total}
                        />
                    </FormItem>
                    {(detailsMode || servicesMode) && (
                        <FormItem
                            className={Styles.formItem}
                            colon={false}
                            label={
                                <FormattedMessage
                                    id={`${
                                        servicesMode
                                            ? 'order_form_table.services_profit'
                                            : 'order_form_table.details_profit'
                                    }`}
                                />
                            }
                        >
                            <InputNumber
                                decimalSeparator=','
                                disabled
                                formatter={value =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                }
                                parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                                style={{ color: profit < 0 ? 'red' : 'black' }}
                                value={servicesMode ? totalServicesProfit : totalDetailsProfit}
                            />
                        </FormItem>
                    )}
                </div>
            </Catcher>
        );
    }
}

export default DiscountPanel;
