import { Button } from 'antd';
import { EmployeesContainer } from 'containers';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { Layout } from 'tireFitting';
import { isForbidden, permissions } from 'utils';
import { accesses } from 'utils/grants';

const mapStateToProps = state => ({
    user: state.auth
});

@injectIntl
@connect(mapStateToProps)
export default class EmployeesPage extends Component {
    render() {
        return (
            <Layout
                controls={
                    <Link to={book.addEmployee}>
                        <Button
                            disabled={isForbidden(
                                this.props.user,
                                permissions.CREATE_EDIT_DELETE_EMPLOYEES,
                                accesses.ROWO
                            )}
                            type='primary'
                        >
                            <FormattedMessage id='employee-page.add_employee' />
                        </Button>
                    </Link>
                }
                description={<FormattedMessage id='employee-page.description' />}
                title={<FormattedMessage id='navigation.employees' />}
            >
                <EmployeesContainer />
            </Layout>
        );
    }
}
