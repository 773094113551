import { Input, Tree } from 'antd';
import { resetModal, setModal } from 'core/modals/duck';
import { fetchStoreGroups, selectFlattenStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import _ from 'lodash';
import { StoreGroupModal } from 'modals';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fetchAPI } from 'utils';
import { StoreProductsSetting } from './StoreProductsSetting';

const { TreeNode } = Tree;
const { Search } = Input;

const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.childGroups) {
            if (
                node.childGroups.some(
                    item => item.id === key
                    // item => item.id === key || item.parentGroupId === key,
                )
            ) {
                parentKey = node.id;
            } else if (getParentKey(key, node.childGroups)) {
                parentKey = getParentKey(key, node.childGroups);
            }
        }
    }

    return Number.isInteger(parentKey) ? String(parentKey) : parentKey;
};

const mapStateToProps = state => ({
    modal: state.modals.modal,
    storeGroups: selectStoreGroups(state),
    flattenStoreGroups: selectFlattenStoreGroups(state)
});

const mapDispatchToProps = {
    fetchStoreGroups,
    setModal,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreProductsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedKeys: [],
            searchValue: '',
            autoExpandParent: true,
            brands: [],
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    componentDidMount() {
        this.props.fetchStoreGroups();
        this.fetchDetails();
    }

    fetchDetails = async () => {
        const brands = await fetchAPI('GET', '/brands', null, null, { handleErrorInternally: true });
        const suppliers = await fetchAPI('GET', '/business_suppliers', { all: true, cut: true }, null, {
            handleErrorInternally: true
        });
        this.setState({
            brands,
            suppliers
        });
    };

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false
        });
    };

    onChange = e => {
        const { value } = e.target;

        const expandedKeys = this.props.flattenStoreGroups
            .map(item => {
                if (String(item.name).toLowerCase().indexOf(String(value).toLowerCase()) > -1) {
                    return getParentKey(item.id, this.props.storeGroups);
                }

                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);

        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true
        });
    };

    render() {
        const { searchValue, expandedKeys, autoExpandParent } = this.state;

        const loop = data =>
            data.map(item => {
                const index = String(item.name).toLowerCase().indexOf(String(searchValue).toLowerCase());
                const beforeStr = item.name.substr(0, index);
                const afterStr = item.name.substr(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span style={{ color: '#f50' }}>{searchValue}</span>
                            {afterStr} (#{item.id}) {item.brandId ? `/ ${item.brandName}` : ''}{' '}
                            {item.markupGroupId && item.markupName
                                ? `/ ${item.markupName} ${this.props.intl.formatMessage({
                                      id: `product_markups.${item.markupUsing}`
                                  })}`
                                : ''}
                        </span>
                    ) : (
                        <span>
                            {item.name} (#{item.id}) {item.brandId ? `/ ${item.brandName}` : ''}{' '}
                            {item.markupGroupId && item.markupName
                                ? `/ ${item.markupName} ${this.props.intl.formatMessage({
                                      id: `product_markups.${item.markupUsing}`
                                  })}`
                                : ''}
                        </span>
                    );
                if (!_.isEmpty(item.childGroups)) {
                    return (
                        <Leaf
                            key={String(item.id)}
                            icon={({ selected }) =>
                                selected ? (
                                    <StoreProductsSetting
                                        setModal={this.props.setModal}
                                        storeGroup={{
                                            id: item.id,
                                            name: item.name,
                                            systemWide: Boolean(!item.businessId),
                                            priceGroupNumber: item.priceGroupNumber,
                                            brandId: item.brandId,
                                            fixedBrand: item.fixedBrand,
                                            businessSupplierId: item.businessSupplierId,
                                            isRound: item.isRound,
                                            decimalPlaces: item.decimalPlaces,
                                            roundAll: item.roundAll,
                                            markupGroupId: item.markupGroupId
                                        }}
                                    />
                                ) : null
                            }
                            title={title}
                        >
                            {loop(item.childGroups)}
                        </Leaf>
                    );
                }

                return (
                    <Leaf
                        key={String(item.id)}
                        icon={leaf =>
                            leaf.selected ? (
                                <StoreProductsSetting
                                    setModal={this.props.setModal}
                                    storeGroup={{
                                        id: item.id,
                                        name: item.name,
                                        systemWide: Boolean(!item.businessId),
                                        priceGroupNumber: item.priceGroupNumber,
                                        brandId: item.brandId,
                                        fixedBrand: item.fixedBrand,
                                        businessSupplierId: item.businessSupplierId,
                                        isRound: item.isRound,
                                        decimalPlaces: item.decimalPlaces,
                                        roundAll: item.roundAll,
                                        markupGroupId: item.markupGroupId
                                    }}
                                />
                            ) : null
                        }
                        title={title}
                    />
                );
            });

        return (
            <div>
                <Search
                    onChange={this.onChange}
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage.search_for_product_group'
                    })}
                    style={{ marginBottom: 8 }}
                />
                {/* <Button
                    icon="plus"
                    type="dashed"
                    onClick={() =>
                        this.props.setModal(MODALS.STORE_GROUP, {
                            create: true,
                            root: true,
                        })
                    }
                >
                    {this.props.intl.formatMessage({
                        id: "storage.add_root_product_group",
                    })}
                </Button> */}
                <StyledTree
                    autoExpandParent={autoExpandParent}
                    expandedKeys={expandedKeys}
                    onExpand={this.onExpand}
                    showIcon
                >
                    {/* {loop(gData)} */}
                    {loop(this.props.storeGroups)}
                </StyledTree>
                <StoreGroupModal
                    brands={this.state.brands}
                    resetModal={this.props.resetModal}
                    suppliers={this.state.suppliers}
                    visible={this.props.modal}
                />
            </div>
        );
    }
}

const StyledTree = styled(Tree)`
    &.ant-tree {
        overflow: scroll;

        .ant-tree-treenode-selected .ant-tree-title {
            margin-left: 24px;
        }
    }

    &.ant-tree li.ant-tree-treenode-selected .ant-tree-node-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;

        & .ant-tree-iconEle.ant-tree-icon__customize {
            position: absolute;
            right: -80px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: rgba(var(--secondaryRGB), 0.2);
        }
    }
`;

const Leaf = styled(TreeNode)`
    /* .ant-tree-node-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;
    } */

    /* .ant-tree-treenode-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;
    }

    .ant-tree-treenode-selected .ant-tree-iconEle.ant-tree-icon__customize {
        position: absolute;
        right: -80px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: rgba(var(--secondaryRGB), 0.2);
    }

    .ant-tree-treenode-selected .ant-tree-title {
        margin-left: 24px;
    } */
`;
