import { UnorderedListOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormattedDatetime } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    subtasks: '5%',
    index: '5%',
    name: 'auto',
    document: '12%',
    status: '8%',
    priority: '10%',
    giver: '10%',
    performerFullName: '10%',
    scheduledDatetime: '10%',
    dueDatetime: '10%'
};

const statuses = ['NEW', 'INWORK', 'DELEGATED', 'POSTPONED', 'COMPLETED', 'ACCEPT', 'CANCELED'];

export function columnsConfig(props) {
    const {
        onEmployeeSubTasks,
        handleTaskRedirect,
        getColumnSearchProps,
        getColumnDropdownProps,
        getColumnDaterangeProps,
        formatMessage
    } = props;

    const subtasksCol = {
        title: '',
        width: defWidth.subtasks,
        dataIndex: 'key',
        key: 'subtask',
        render: (key, obj) => {
            return (
                <div>
                    <Tooltip title={<FormattedMessage id='tasks_page.tasks_chain' />} zIndex={2001}>
                        <UnorderedListOutlined
                            onClick={() => onEmployeeSubTasks(obj.id)}
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }
    };

    const indexCol = {
        title: '№',
        width: defWidth.index,
        dataIndex: 'id',
        key: 'key',
        sorter: (a, b) => b.id - a.id,
        render: id => id
    };

    const nameCol = {
        title: <FormattedMessage id='order_tasks.name' />,
        width: defWidth.name,
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...getColumnSearchProps('name'),
        render: (name, obj) => {
            return (
                <div
                    className={Styles.taskRow}
                    onClick={async () => {
                        await handleTaskRedirect(obj);
                    }}
                >
                    {name}
                </div>
            );
        }
    };

    const documentCol = {
        title: <FormattedMessage id='order_tasks.document' />,
        width: defWidth.document,
        sorter: (a, b) => String(a.document).localeCompare(String(b.document)),
        ...getColumnSearchProps('document'),
        render: (status, obj) => {
            return (
                <div>
                    {(obj.orderNum && (
                        <Link to={`${book.order}/${obj.orderId}`}>{obj.orderNum}</Link>
                    )) ||
                        (obj.storeDocNum && (
                            <Link to={`${book.storageDocument}/${obj.storeDocId}`}>
                                {obj.storeDocNum}
                            </Link>
                        )) ||
                        obj.cashOrderId}
                </div>
            );
        }
    };

    const statusCol = {
        title: <FormattedMessage id='order_tasks.status' />,
        width: defWidth.status,
        dataIndex: 'status',
        sorter: (a, b) => statuses.indexOf(a.status) - statuses.indexOf(b.status),
        ...getColumnDropdownProps('statuses'),
        render: status => {
            return (
                <div>
                    <FormattedMessage id={`order_tasks.status_${String(status).toLowerCase()}`} />
                </div>
            );
        }
    };

    const highRed = (
        <div
            style={{
                color: 'red',
                fontWeight: 'bold'
            }}
        >
            !!!
        </div>
    );

    const priorityCol = {
        title: <FormattedMessage id='order_tasks.priority' />,
        width: defWidth.priority,
        dataIndex: 'priority',
        sorter: (a, b) => b.priority.localeCompare(a.priority),
        ...getColumnDropdownProps('priorities'),
        render: priority => {
            return (
                <div style={{ textAlign: 'center' }}>
                    {priority === 'HIGH'
                        ? highRed
                        : priority === 'MIDDLE'
                        ? '!!'
                        : priority === 'LOW'
                        ? '!'
                        : ''}
                </div>
            );
        }
    };

    const giverCol = {
        title: <FormattedMessage id='order_tasks.created' />,
        width: defWidth.giver,
        dataIndex: 'giverFullName',
        sorter: (a, b) => a.giverFullName.localeCompare(b.giverFullName),
        ...getColumnSearchProps('giverName')
    };

    const performerFullNameCol = {
        title: <FormattedMessage id='order_tasks.performer' />,
        width: defWidth.performerFullName,
        dataIndex: 'performerFullName',
        sorter: (a, b) => a.performerFullName.localeCompare(b.performerFullName),
        ...getColumnSearchProps('performerName')
    };

    const scheduledDatetimeCol = {
        title: <FormattedMessage id='order_tasks.scheduled_datetime' />,
        width: defWidth.scheduledDatetime,
        dataIndex: 'scheduledDatetime',
        sorter: (a, b) => b.scheduledDatetime.localeCompare(a.scheduledDatetime),
        ...getColumnDaterangeProps(),
        render: scheduledDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={scheduledDatetime} format={DEFAULT_DATETIME} />
            </React.Fragment>
        )
    };

    const dueDatetimeCol = {
        title: <FormattedMessage id='order_tasks.due_datetime' />,
        width: defWidth.dueDatetime,
        sorter: (a, b) => b.dueDatetime.localeCompare(a.dueDatetime),
        render: (elem, obj) => {
            const isCompleted = obj.status === 'COMPLETED';

            let background = null;
            let color = null;

            const duration = dayjs(obj.scheduledDatetime) - dayjs();

            if (obj.status === 'NEW') {
                background = 'var(--db_progress)';
            } else if (duration > 0 && duration <= 600000) {
                // 10 minutes left
                background = 'var(--db_approve)';
            } else if (dayjs(obj.scheduledDatetime) < dayjs()) {
                background = 'var(--disabled)';
                color = 'white';
            }

            return isCompleted ? (
                <div
                    style={{
                        padding: '6px 8px',
                        textAlign: 'center',
                        fontWeight: 500,
                        cursor: 'pointer'
                    }}
                >
                    <FormattedMessage id='long_dash' />
                </div>
            ) : (
                <div
                    style={{
                        color,
                        background,
                        padding: '6px 8px',
                        textAlign: 'center',
                        fontWeight: 500,
                        cursor: 'pointer'
                    }}
                >
                    {obj.toDueDatetimeYear !== 0 && `${obj.toDueDatetimeYear}y `}
                    {obj.toDueDatetimeMonth !== 0 && `${obj.toDueDatetimeMonth}m `}
                    {obj.toDueDatetimeDay !== 0 && `${obj.toDueDatetimeDay}d `}

                    {dayjs(`${obj.toDueDatetimeHour}:${obj.toDueDatetimeMinute}`, 'HH:mm').format(
                        'H:mm'
                    )}
                </div>
            );
        }
    };

    return [
        subtasksCol,
        indexCol,
        nameCol,
        documentCol,
        statusCol,
        priorityCol,
        giverCol,
        performerFullNameCol,
        scheduledDatetimeCol,
        dueDatetimeCol
    ];
}
