import { fetchBrandCrosses } from 'pages/DirectoryBrandsPage/redux/duck';

export const moduleName = 'brandsCrossModal';
const prefix = `cpb/${moduleName}`;

export const CREATE_BRAND_CROSS = `${prefix}/CREATE_BRAND_CROSS`;
export const CREATE_BRAND_CROSS_SUCCESS = `${prefix}/CREATE_BRAND_CROSS_SUCCESS`;
//
// export const FETCH_BRAND = `${prefix}/FETCH_BRAND`;
// export const FETCH_BRAND_SUCCESS = `${prefix}/FETCH_BRAND_SUCCESS`;

export const UPDATE_BRAND_CROSS = `${prefix}/UPDATE_BRAND_CROSS`;
export const UPDATE_BRAND_CROSS_SUCCESS = `${prefix}/UPDATE_BRAND_CROSS_SUCCESS`;

export const SET_BRAND_CROSS_FIELDS = `${prefix}/SET_BRAND_CROSS_FIELDS`;

export const modes = Object.freeze({
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW'
});

/* Reducer */
const ReducerState = {
    fields: {
        id: undefined, // id of the brand cross
        brandId: undefined,
        businessSupplierBrandName: undefined
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_BRAND_CROSS_FIELDS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...payload
                }
            };
        default:
            return state;
    }
}
//
/* Selectors */
export const selectBrandCrossField = state => state[moduleName].fields;

/* Actions */

export const createBrandCross = crossId => ({
    type: CREATE_BRAND_CROSS,
    payload: crossId
});

export const createBrandCrossSuccess = () => {
    return function (dispatch) {
        dispatch({
            type: CREATE_BRAND_CROSS_SUCCESS
        });

        return dispatch(fetchBrandCrosses());
    };
};

export const updateBrandCross = () => ({
    type: UPDATE_BRAND_CROSS
});

export const updateBrandCrossSuccess = () => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_BRAND_CROSS_SUCCESS
        });

        return dispatch(fetchBrandCrosses());
    };
};
//
// export const fetchBrand = (brandId) => ({
//     type: FETCH_BRAND,
//     payload: brandId
// });
//
// export const fetchBrandSuccess = (fields) => {
//     return function(dispatch) {
//
//         dispatch({
//             type: FETCH_BRAND_SUCCESS
//         });
//
//         return dispatch(setBrandFields(fields))
//     }
// };
//
export const setBrandCrossFields = fields => ({
    type: SET_BRAND_CROSS_FIELDS,
    payload: fields
});
