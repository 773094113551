import { Numeral } from 'commons';
import { FormattedDatetime } from 'components';
import _ from 'lodash';
import { DEF_UI_DATETIME } from 'modals/DebtZeroingModal/constants';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Choose width for each col, (100% in total or less than 100% if 'auto' is present)
const defWidth = {
    storeDocCol: '35%',
    creationDateCol: '20%',
    counterpartyCol: 'auto',
    sumCol: '15%',
    remainingSumCol: '15%'
};

export function columnsConfig() {
    const storeDocCol = {
        title: <FormattedMessage id='debt_zeroing_modal.store_doc' />,
        width: defWidth.storeDocCol,
        align: 'left',
        dataIndex: 'documentNumber',
        key: 'storeDocCol'
    };
    const creationDateCol = {
        title: <FormattedMessage id='debt_zeroing_modal.creation_date' />,
        width: defWidth.creationDateCol,
        align: 'left',
        dataIndex: 'createdDatetime',
        key: 'createdDatetime',
        render: orderDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={orderDatetime} format={DEF_UI_DATETIME} />
            </React.Fragment>
        )
    };
    const counterpartyCol = {
        title: <FormattedMessage id='debt_zeroing_modal.counterparty' />,
        width: defWidth.counterpartyCol,
        align: 'left',
        key: 'counterpartyCol',
        render: doc => {
            // There can be many types of counterparts of a document, one of them has to exist
            return (
                <div>
                    {_.get(doc, 'counterpartBusinessSupplierName', '')}
                    {_.get(doc, 'counterpartClientName', '')}
                    {_.get(doc, 'counterpartWarehouseName', '')}
                    {_.get(doc, 'counterpartEmployeeName', '')}
                </div>
            );
        }
    };
    const sumCol = {
        title: <FormattedMessage id='debt_zeroing_modal.sum' />,
        width: defWidth.sumCol,
        align: 'right',
        dataIndex: 'sellingSum',
        key: 'sumCol',
        render: sellingSum => (
            <Numeral mask='0,0.00' nullText='0'>
                {sellingSum}
            </Numeral>
        )
    };
    const remainingSumCol = {
        title: <FormattedMessage id='debt_zeroing_modal.remaining_sum' />,
        width: defWidth.remainingSumCol,
        align: 'right',
        dataIndex: 'remainingSum',
        key: 'remainingSumCol',
        render: remainingSum => (
            <Numeral mask='0,0.00' nullText='0'>
                {remainingSum}
            </Numeral>
        )
    };

    return [storeDocCol, creationDateCol, counterpartyCol, sumCol, remainingSumCol];
}
