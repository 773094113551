import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, notification } from 'antd';
import { PhoneInput } from 'components';
import { get } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {};

const AddBussinessModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        edit,

        fetchBussinesses
    } = props;

    const [bussinessName, setBussinessName] = useState();
    const [email, setEmail] = useState();
    const [phones, setPhones] = useState([{ code: '', number: '', country: 'ua' }]);
    const [address, setAddress] = useState();

    const handlePhoneNumberChange = (index, number, code, country) => {
        const newPhoneNumbers = [...phones];
        newPhoneNumbers[index].number = number.toString();
        newPhoneNumbers[index].code = code.toString();
        newPhoneNumbers[index].country = country.toString();

        setPhones(newPhoneNumbers);
    };

    const handleAddPhoneNumber = () => {
        setPhones([...phones, { code: '', number: '', country: 'ua' }]);
    };

    const handleDeleteObject = index => {
        const updatedArray = phones.filter((item, idx) => idx !== index);
        setPhones([...updatedArray]);
    };

    const onClose = () => {
        setBussinessName(undefined);
        setEmail(undefined);
        setPhones([]);
        setAddress(undefined);
        hideModal();
    };

    const validateEmail = email => {
        const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        return regexEmail.test(email);
    };

    const addBussiness = async () => {
        try {
            if (!validateEmail(email)) {
                notification.error({
                    message: formatMessage({ id: 'monetization.invalid_email' })
                });

                return;
            }

            await fetchAPI(
                'POST',
                '/business',
                undefined,
                {
                    name: bussinessName,
                    email,
                    phones,
                    address
                },
                { handleErrorInternally: true }
            );

            await fetchBussinesses();
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
            await onClose();
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    const editBussiness = async () => {
        try {
            if (edit) {
                await fetchAPI(
                    'PUT',
                    `/business/${edit.id}`,
                    undefined,
                    {
                        name: bussinessName,
                        email,
                        phones,
                        address
                    },
                    { handleErrorInternally: true }
                );

                notification.success({
                    message: formatMessage({ id: 'barcode.success' })
                });

                await fetchBussinesses();
            }
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    // useEffect(() => {
    //     if (open) {
    //         setPhones([]);
    //         setPhones([{ code: '', number: '', country: 'ua' }]);
    //     }
    // }, [open]);

    const onFinish = async () => {
        if (!edit) {
            addBussiness();
        } else {
            editBussiness();
        }
    };

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: !bussinessName || get(phones, '[0].number', 'length') < 1 || !address || !email
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={
                !edit ? (
                    <FormattedMessage id='monetization.create_bs' />
                ) : (
                    <FormattedMessage id='monetization.edit_group' />
                )
            }
            width='30%'
        >
            <div>
                <div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.name' /> <span className={Styles.red}>*</span>
                        </div>
                        <Input
                            onChange={event => setBussinessName(event.target.value)}
                            placeholder={formatMessage({
                                id: 'monetization.name'
                            })}
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={bussinessName}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='E-mail' /> <span className={Styles.red}>*</span>
                        </div>
                        <Input
                            onChange={event => {
                                setEmail(event.target.value);
                            }}
                            placeholder={formatMessage({
                                id: 'E-mail'
                            })}
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={email}
                        />
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.phone_number' /> <span className={Styles.red}>*</span>
                        </div>
                        {phones && open
                            ? phones.map((elem, index) => {
                                  console.log(elem.number, 'number');

                                  return (
                                      <div
                                          key={`bussiness_from_elem_${index}`}
                                          style={{
                                              marginBottom: 8,
                                              display: 'flex'
                                          }}
                                      >
                                          <PhoneInput
                                              handlePhoneNumberChange={handlePhoneNumberChange}
                                              index={index}
                                              onChange={value => {}}
                                              
                                              open={open}
                                              style={{ width: '100%' }}
                                              placeholder={formatMessage({
                                                  id: 'nRegNew'
                                              })}
                                              // formatter={value => phoneNumberFormatter(value, user.country)}
                                              value={String(elem.code + elem.number)}
                                          />
                                          <Button
                                              icon={<DeleteOutlined />}
                                              onClick={() => handleDeleteObject(index)}
                                              type='text'
                                          />
                                      </div>
                                  );
                              })
                            : undefined}
                        <Button
                            icon={
                                <PlusOutlined
                                    style={{
                                        marginRight: 8
                                    }}
                                />
                            }
                            onClick={handleAddPhoneNumber}
                        >
                            <FormattedMessage id='add_client_form.add_phone' />
                        </Button>
                    </div>
                    <div className={Styles.margin}>
                        <div>
                            <FormattedMessage id='monetization.address' /> <span className={Styles.red}>*</span>
                        </div>
                        <Input
                            onChange={event => setAddress(event.target.value)}
                            placeholder={formatMessage({
                                id: 'monetization.name'
                            })}
                            style={{ color: 'var(--text)', width: '100%' }}
                            value={address}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(AddBussinessModal);
