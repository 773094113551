import { Form } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Input, InputNumber, Modal, Row, Select, Tooltip, TreeSelect } from 'antd';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, getLocale, goTo } from 'utils';

import { removeCyrillicLetters } from 'modals/VehicleModal/number_utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    brands: selectBrands(state)
});

const mapDispatchToProps = {
    fetchBrands
};

const { Option } = Select;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AddAggregateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aggregates: []
        };
    }

    componentDidMount() {
        this.fetchAggregates();
        this.props.fetchBrands();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            if (this.props.addAggregateModalRow) {
                this.setState({
                    ...this.props.addAggregateModalRow
                });
            }
        }
    }

    fetchAggregates = async () => {
        const aggregates = await fetchAPI('GET', '/aggregates', null, null, {
            handleErrorInternally: true
        });

        await this.setState({
            aggregates
        });

        this.buildAggregatesTreeData();
    };

    onOk = () => {
        const { addAggregate, clientId, addAggregateModalRow, editAggregate, withoutClient, generalInfoTab } =
            this.props;
        const { aggregateId, brandId, aggregateNumber, comment, count, aggregateName, brandName, vin } = this.state;

        if (!clientId && addAggregateModalRow === undefined && !withoutClient) {
            addAggregate({
                aggregateId,
                brandId,
                aggregateNumber,
                comment,
                count,
                brandName,
                aggregateName,
                vin
            });
        } else if (!clientId && addAggregateModalRow && !generalInfoTab) {
            editAggregate({
                index: addAggregateModalRow.index,
                aggregateId,
                brandId,
                aggregateNumber,
                comment,
                count,
                brandName,
                aggregateName,
                vin
            });
        } else if (addAggregateModalRow === undefined) {
            this.addClientAggregate();
        } else {
            this.editClientAggregate();
        }

        this.onCancel();
    };

    editClientAggregate = async () => {
        const { clientId, getClientsAggregates, addAggregateModalRow } = this.props;
        const { aggregateId, brandId, aggregateNumber, comment, count, vin } = this.state;

        await fetchAPI(
            'PUT',
            `/client/aggregates/${addAggregateModalRow.clientAggregateId}`,
            null,
            { aggregateId, brandId, aggregateNumber, comment, count, vin: vin || null },
            {
                handleErrorInternally: true
            }
        );
        setTimeout(getClientsAggregates(), 100);
    };

    addClientAggregate = async () => {
        const { clientId, getClientsAggregates } = this.props;
        const { aggregateId, brandId, aggregateNumber, comment, count, vin } = this.state;

        if (clientId) {
            await fetchAPI(
                'POST',
                `/clients/${clientId}/aggregate`,
                null,
                { id: aggregateId, brandId, aggregateNumber, comment, count, vin: vin || null },
                {
                    handleErrorInternally: true
                }
            );
            setTimeout(getClientsAggregates(), 100);
            window.location.reload();
        } else if (!clientId) {
            const aggregate = await fetchAPI(
                'POST',
                '/clients/aggregate',
                null,
                { aggregateId, brandId, aggregateNumber, comment, count, vin: vin || null },
                {
                    handleErrorInternally: true
                }
            );

            await goTo(`${book.aggregate}/${_.get(aggregate, 'inserted')}`);
        }
    };

    onCancel = async () => {
        const { hideModal } = this.props;

        await this.setState({
            aggregateId: undefined,
            brandId: undefined,
            aggregateNumber: undefined,
            comment: undefined,
            count: undefined,
            vin: undefined
        });

        hideModal();
    };

    buildAggregatesTreeData() {
        const language = getLocale();
        const treeData = [];
        for (let i = 0; i < this.state.aggregates.length; i++) {
            const parentGroup = this.state.aggregates[i];
            treeData.push({
                title: `${language == 'en' ? parentGroup.name.en : parentGroup.name.ua}`,
                code: parentGroup.code,
                value: parentGroup.id,
                level: parentGroup.level,
                id: parentGroup.id,
                className: Styles.groupTreeOption,
                key: parentGroup.id,
                // selectable: false,
                children: []
            });
            for (let j = 0; j < parentGroup.children.length; j++) {
                const children = parentGroup.children[j];
                treeData[i].children.push({
                    title: `${language == 'en' ? children.name.en : children.name.ua}`,
                    code: children.code,
                    level: children.level,
                    value: children.id,
                    id: children.id,
                    className: Styles.groupTreeOption,
                    key: children.id
                    // selectable: false
                });
            }
        }
        this.setState({
            aggregatesTreeData: treeData
        });
    }

    render() {
        const {
            visible,
            hideModal,
            clientId,
            addClientAggregate,
            intl: { formatMessage }
        } = this.props;

        const { aggregateId, brandId, aggregateNumber, comment, count, brandName, vin } = this.state;

        return (
            <div>
                <Modal
                    okButtonProps={{
                        disabled:
                            !aggregateId ||
                            !brandId ||
                            !aggregateNumber ||
                            count < 1 ||
                            !count ||
                            (_.get(vin, 'length') < 17 && _.get(vin, 'length') !== 0)
                    }}
                    onCancel={this.onCancel}
                    maskClosable={false}
                    onOk={this.onOk}
                    title={
                        this.props.addAggregateModalRow ? (
                            <React.Fragment>
                                <FormattedMessage id='clients-page.edit_aggregate' />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <FormattedMessage id='clients-page.add_aggregate' />
                            </React.Fragment>
                        )
                    }
                    visible={visible}
                >
                    <Form>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='clients-page.aggregate' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <TreeSelect
                                    dropdownMatchSelectWidth={280}
                                    filterTreeNode={(input, node) =>
                                        node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                    initialValue={aggregateId}
                                    onSelect={(value, option) => {
                                        this.setState({
                                            aggregateId: value,
                                            aggregateName: option.title
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'clients-page.aggregate'
                                    })}
                                    showSearch
                                    style={{
                                        maxWidth: 220
                                    }}
                                    treeData={this.state.aggregatesTreeData}
                                    value={aggregateId}
                                />
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='brand' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    initialValue={brandId}
                                    onChange={(value, option) => {
                                        this.setState({
                                            brandId: value,
                                            brandName: option.brandName
                                        });
                                    }}
                                    optionFilterProp='children'
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'search'
                                    })}
                                    showSearch
                                    style={{ marginBottom: 8, display: 'block', width: 220 }}
                                    value={brandId}
                                >
                                    {this.props.brands
                                        .filter(({ brandName }) => brandName)
                                        .map(({ brandId, brandName }) => (
                                            <Option key={brandId} brandName={brandName} value={brandId}>
                                                {brandName}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='clients-page.aggregate_number' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input
                                    initialValue={aggregateNumber}
                                    onChange={event => {
                                        this.setState({
                                            aggregateNumber: event.target.value
                                        });
                                    }}
                                    placeholder={formatMessage({
                                        id: 'clients-page.aggregate_number'
                                    })}
                                    style={{ width: 220 }}
                                    value={aggregateNumber}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_order_form.vin' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input
                                    initialValue={vin}
                                    maxLength={17}
                                    onChange={event => {
                                        this.setState({
                                            vin: removeCyrillicLetters(String(event.target.value).toUpperCase())
                                        });
                                    }}
                                    placeholder={formatMessage({
                                        id: 'add_order_form.vin'
                                    })}
                                    style={{ width: 220 }}
                                    suffix={
                                        <Tooltip title={<FormattedMessage id='vin.forbidden_symbols' />}>
                                            <InfoCircleOutlined
                                                style={{
                                                    color: 'var(--primary)'
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                    value={vin}
                                />
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='clients-page.quantity' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    decimalSeparator=','
                                    onChange={count => {
                                        this.setState({
                                            count
                                        });
                                    }}
                                    style={{ width: 220 }}
                                    value={count}
                                />
                            </Col>
                            <Col span={6}></Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='comment' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input.TextArea
                                    onChange={event => {
                                        this.setState({
                                            comment: event.target.value
                                        });
                                    }}
                                    placeholder={formatMessage({
                                        id: 'comment'
                                    })}
                                    style={{ width: 220 }}
                                    value={comment}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}
