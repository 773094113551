import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    deleteBrandCrossSuccess,
    deleteBrandSuccess,
    DELETE_BRAND,
    DELETE_BRAND_CROSS,
    fetchBrandsCrossesSuccess,
    fetchBrandsSuccess,
    FETCH_BRANDS,
    FETCH_BRANDS_CROSSES,
    selectBrandCrossData,
    selectExpandedBrandRow,
    selectFilters
} from './duck';

export function* fetchBrandsSaga() {
    while (true) {
        try {
            yield take(FETCH_BRANDS);

            // yield put(setFetchingVehicles(true));

            const filters = yield select(selectFilters);

            yield nprogress.start();

            const { brands, stats } = yield call(fetchAPI, 'GET', 'clients/brands', { filters });

            yield put(fetchBrandsSuccess({ brands, stats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            // yield put(setFetchingVehicles(false));
        }
    }
}

export function* deleteBrandSaga() {
    while (true) {
        try {
            const { payload } = yield take(DELETE_BRAND);

            yield call(fetchAPI, 'DELETE', `clients/brands/${payload}`);

            yield put(deleteBrandSuccess());
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* fetchBrandsCrossesSaga() {
    while (true) {
        try {
            yield take(FETCH_BRANDS_CROSSES);

            // yield put(setFetchingVehicles(true));

            const { filters } = yield select(selectBrandCrossData);
            const brandId = yield select(selectExpandedBrandRow);

            yield nprogress.start();

            const { brandCrosses, stats } = yield call(fetchAPI, 'GET', 'clients/brands/crosses', {
                filters
            });

            yield put(fetchBrandsCrossesSuccess({ brandCrosses, brandCrossesStats: stats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            // yield put(setFetchingVehicles(false));
        }
    }
}

export function* deleteBrandCrossSaga() {
    while (true) {
        try {
            const { payload } = yield take(DELETE_BRAND_CROSS);

            yield call(fetchAPI, 'DELETE', `clients/brands/cross/${payload}`);

            yield put(deleteBrandCrossSuccess());
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([
        call(fetchBrandsSaga),
        call(deleteBrandSaga),
        call(fetchBrandsCrossesSaga),
        call(deleteBrandCrossSaga)
    ]);
}
