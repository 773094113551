import { ForwardOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Radio, Select, Space, Tooltip } from 'antd';
import { Layout, Loader, Spinner } from 'commons';
import { ArrowsDatePicker, ArrowsWeekPicker } from 'components';
import { DashboardContainer } from 'containers';
import {
    dropDashboardOrder,
    initDashboard,
    linkToDashboardEmployees,
    linkToDashboardStations,
    selectDasboardData,
    selectDashboardFilters,
    setDashboarFilter,
    setDashboardEmployeesDate,
    setDashboardMode,
    setDashboardStationsDate,
    setDashboardWeekDates,
    transferOutdateRepairs,
    updateDashboardOrder,
    updateDashboardService
} from 'core/dashboard/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ConfirmRescheduleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getDefaultDashbordMode } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import NetworkModal from './components/NetworkModal';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    orders: state.dashboard.orders,
    mode: state.dashboard.mode,
    stations: state.dashboard.stations,
    employees: state.dashboard.employees,
    date: state.dashboard.date,
    startDate: state.dashboard.startDate,
    endDate: state.dashboard.endDate,
    schedule: state.dashboard.schedule,
    days: state.dashboard.days,
    load: state.dashboard.load,
    daysWithConflicts: state.dashboard.daysWithConflicts,
    stationsWithConflicts: state.dashboard.stationsWithConflicts,
    employeesWithConflicts: state.dashboard.employeesWithConflicts,
    modal: state.modals.modal,
    filters: selectDashboardFilters(state),
    employeesForFilter: state.dashboard.employeesForFilter,
    managersForFilter: state.dashboard.managersForFilter,
    clientsForFilter: state.dashboard.clientsForFilter,

    spinner: state.ui.dashboardInitializing,
    loading: state.ui.dashboardFetching,
    user: state.auth,
    isMobile: state.ui.views.isMobile,

    ...selectDasboardData(state)
});

const mapDispatchToProps = {
    initDashboard,
    dropDashboardOrder,
    setDashboardStationsDate,
    setDashboardEmployeesDate,
    setDashboardWeekDates,
    setDashboardMode,
    linkToDashboardStations,
    linkToDashboardEmployees,
    updateDashboardOrder,
    updateDashboardService,
    transferOutdateRepairs,
    setDashboarFilter,
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false,
            networkModalOpen: false
        };
    }

    async componentDidMount() {
        const { initDashboard, user, mode } = this.props;

        await initDashboard();
        this.props.setDashboardMode(getDefaultDashbordMode(user));
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'dashboard' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    _onDayChange = date => {
        if (this.props.mode === 'employees') {
            this.props.setDashboardEmployeesDate(date);
        } else {
            this.props.setDashboardStationsDate(date);
        }
    };

    _prevDay = () => {
        const { setDashboardStationsDate, setDashboardEmployeesDate, date } = this.props;
        if (this.props.mode === 'employees') {
            setDashboardEmployeesDate(date.subtract(1, 'day'));
        } else {
            setDashboardStationsDate(date.subtract(1, 'day'));
        }
        this.setState({});
    };

    _nextDay = () => {
        const { setDashboardStationsDate, setDashboardEmployeesDate, date } = this.props;
        if (this.props.mode === 'employees') {
            setDashboardEmployeesDate(date.add(1, 'day'));
        } else {
            setDashboardStationsDate(date.add(1, 'day'));
        }
        this.setState({});
    };

    _onWeekChange = date =>
        this.props.setDashboardWeekDates({
            startDate: dayjs(date).startOf('isoWeek'),
            endDate: dayjs(date).endOf('isoWeek')
        });

    _prevWeek = () => {
        const { setDashboardWeekDates, startDate, endDate } = this.props;
        setDashboardWeekDates({
            startDate: startDate.subtract(1, 'weeks'),
            endDate: endDate.subtract(1, 'weeks')
        });
    };

    _nextWeek = () => {
        const { setDashboardWeekDates, startDate, endDate } = this.props;

        setDashboardWeekDates({
            startDate: startDate.add(1, 'weeks'),
            endDate: endDate.add(1, 'weeks')
        });
    };

    openNetworkModal = () => {
        this.setState({ networkModalOpen: true });
    };

    closeNetworkModal = () => {
        this.setState({ networkModalOpen: false });
    };

    _setDashboardMode = mode => this.props.setDashboardMode(mode);

    _transferOutdateRepairs = () => this.props.transferOutdateRepairs();

    _renderDashboardContainer = () => {
        const {
            loading,
            orders,
            mode,
            load,
            days,
            stations,
            employees,
            schedule,
            time,
            dashboard,
            linkToDashboardStations,
            linkToDashboardEmployees,
            updateDashboardOrder,
            updateDashboardService,
            date,
            user,
            daysWithConflicts,
            stationsWithConflicts,
            employeesWithConflicts
        } = this.props;

        return loading ? (
            <Loader loading={loading} />
        ) : (
            <React.Fragment>
                <DashboardContainer
                    dashboard={dashboard}
                    date={date}
                    days={days}
                    daysWithConflicts={daysWithConflicts}
                    employees={employees}
                    employeesWithConflicts={employeesWithConflicts}
                    linkToDashboardEmployees={linkToDashboardEmployees}
                    linkToDashboardStations={linkToDashboardStations}
                    load={load}
                    mode={mode}
                    orders={orders}
                    schedule={schedule}
                    stations={stations}
                    stationsWithConflicts={stationsWithConflicts}
                    time={time}
                    updateDashboardOrder={updateDashboardOrder}
                    updateDashboardService={updateDashboardService}
                    user={user}
                />
                <ConfirmRescheduleModal
                    // wrappedComponentRef={ this.saveFormRef }
                    confirm={this._transferOutdateRepairs}
                    reset={this.props.resetModal}
                    visible={this.props.modal}
                />
            </React.Fragment>
        );
    };

    getTitle = mode => {
        switch (mode) {
            case 'stations':
                return <FormattedMessage id='dashboard-page.title.stations' />;
            case 'calendar':
                return <FormattedMessage id='dashboard-page.title.calendar' />;
            case 'employees':
                return <FormattedMessage id='dashboard-page.title.employees' />;
            default:
                return <FormattedMessage id='dashboard-page.title' />;
        }
    };

    render() {
        const {
            startDate,
            endDate,
            date,
            mode,
            spinner,
            loading,
            user,
            isMobile,
            filters,
            managersForFilter,
            employeesForFilter,
            clientsForFilter,
            setDashboarFilter
        } = this.props;

        const { helperDrawerOpen, allLinks, networkModalOpen } = this.state;

        const dashboardContainer = this._renderDashboardContainer();
        const rescheduleOrdersAllowed =
            isGrantAccessed(user, grants.OPERATIONS_PLANNER_1D_AND_7D_PLANNER_OVERDUE_REPAIRS_TRANSFER) ||
            isGrantAccessed(user, grants.OPERATIONS_PLANNER_MECHANIC_PLANNER_OVERDUE_REPAIRS_TRANSFER);
        const networkBtnAllowed =
            isGrantAccessed(user, grants.OPERATIONS_PLANNER_1D_AND_7D_PLANNER_BUSINESS_NETWORK) ||
            isGrantAccessed(user, grants.OPERATIONS_PLANNER_MECHANIC_PLANNER_BUSINESS_NETWORK);

        return spinner ? (
            <Spinner spin={spinner} />
        ) : (
            <Layout
                controls={
                    <Space {...(isMobile ? { direction: 'vertical', align: 'center' } : {})} size='small'>
                        {!isMobile && networkBtnAllowed && (
                            <Button onClick={this.openNetworkModal}>
                                <FormattedMessage id='dashboard-page.sto_network' />
                            </Button>
                        )}
                        <Radio.Group
                            onChange={e => {
                                this._setDashboardMode(e.target.value);
                            }}
                            style={{ fontWeight: 700 }}
                            value={mode}
                        >
                            <Radio.Button
                                disabled={!isGrantAccessed(user, grants.OPERATIONS_PLANNER_1D_AND_7D_PLANNER)}
                                value='stations'
                            >
                                1D
                            </Radio.Button>
                            <Radio.Button
                                disabled={!isGrantAccessed(user, grants.OPERATIONS_PLANNER_1D_AND_7D_PLANNER)}
                                value='calendar'
                            >
                                7D
                            </Radio.Button>
                            <Radio.Button
                                disabled={!isGrantAccessed(user, grants.OPERATIONS_PLANNER_MECHANIC_PLANNER)}
                                value='employees'
                            >
                                <FormattedMessage id='dashboard-page.mech' />
                            </Radio.Button>
                        </Radio.Group>

                        {!isMobile && (
                            <React.Fragment>
                                <Select
                                    allowClear
                                    dropdownMatchSelectWidth={false}
                                    onChange={filterManager => {
                                        setDashboarFilter({ filterManager });
                                        this._setDashboardMode(mode);
                                    }}
                                    optionFilterProp='children'
                                    placeholder={<FormattedMessage id='orders.responsible' />}
                                    showSearch
                                    style={{ maxWidth: 130 }}
                                    value={filters.filterManager}
                                >
                                    {managersForFilter.map(({ id, managerName, managerSurname }) => (
                                        <Option key={id} value={id}>
                                            {managerName} {managerSurname}
                                        </Option>
                                    ))}
                                </Select>

                                <Select
                                    allowClear
                                    dropdownMatchSelectWidth={false}
                                    onChange={filterEmployee => {
                                        setDashboarFilter({ filterEmployee });
                                        this._setDashboardMode(mode);
                                    }}
                                    optionFilterProp='children'
                                    placeholder={<FormattedMessage id='employee.is_mechanic' />}
                                    showSearch
                                    style={{ maxWidth: 130 }}
                                    value={filters.filterEmployee}
                                >
                                    {employeesForFilter.map(({ id, name, surname }) => (
                                        <Option key={id} value={id}>
                                            {name} {surname}
                                        </Option>
                                    ))}
                                </Select>

                                <Select
                                    allowClear
                                    dropdownMatchSelectWidth={false}
                                    onChange={filterClient => {
                                        setDashboarFilter({ filterClient });
                                        this._setDashboardMode(mode);
                                    }}
                                    optionFilterProp='children'
                                    placeholder={<FormattedMessage id='orders.client' />}
                                    showSearch
                                    style={{ maxWidth: 130 }}
                                    value={filters.filterClient}
                                >
                                    {clientsForFilter.map(({ clientId, clientName, clientSurname, clientPhones }) => (
                                        <Option key={clientId} value={clientId}>
                                            {clientName} {clientSurname} ({_.get(clientPhones, '0')})
                                        </Option>
                                    ))}
                                </Select>

                                {rescheduleOrdersAllowed && (
                                    <Tooltip title={<FormattedMessage id='dashboard-page.transfer_outdated_repairs' />}>
                                        <Button
                                            disabled
                                            icon={<ForwardOutlined style={{ fontSize: 22 }} />}
                                            onClick={() => this.props.setModal(MODALS.CONFIRM_RESCHEDULE)}
                                            // onClick={ () => this._transferOutdateRepairs() }
                                        />
                                    </Tooltip>
                                )}
                            </React.Fragment>
                        )}

                        <div>
                            {mode === 'calendar' ? (
                                <ArrowsWeekPicker
                                    endDate={endDate}
                                    loading={loading}
                                    nextWeek={this._nextWeek}
                                    onWeekChange={this._onWeekChange}
                                    prevWeek={this._prevWeek}
                                    startDate={startDate}
                                />
                            ) : (
                                <ArrowsDatePicker
                                    date={date}
                                    endDate={endDate}
                                    loading={loading}
                                    nextDay={this._nextDay}
                                    onDayChange={this._onDayChange}
                                    onWeekChange={this._onWeekChange}
                                    prevDay={this._prevDay}
                                    startDate={startDate}
                                />
                            )}
                        </div>
                        {!isMobile && (
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    const links = await fetchAPI('GET', '/helps', { helpId: 'dashboard' }, undefined, {
                                        handleErrorInternally: true
                                    });
                                    this.setState({
                                        allLinks: links,
                                        helperDrawerOpen: true
                                    });
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        )}
                    </Space>
                }
                description={<FormattedMessage id='dashboard-page.description' />}
                menuControls
                title={this.getTitle(mode)}
            >
                <section>
                    {dashboardContainer}
                    {/* <Tabs
                        activeKey={mode}
                        className={isMobile ? Styles.dashboard_mobile_tabs : null}
                        onChange={mode => {
                            this._setDashboardMode(mode);
                        }}
                    >
                        <TabPane
                            key='stations'
                            disabled={
                                loading || isForbidden(user, permissions.ACCESS_DASHBOARD_MECHANICS)
                            }
                            tab={<FormattedMessage id='dashboard-page.stations_load' />}
                        >
                            {mode === 'stations' && dashboardContainer}
                        </TabPane>
                        <TabPane
                            key='calendar'
                            disabled={
                                loading || isForbidden(user, permissions.ACCESS_DASHBOARD_POSTS)
                            }
                            tab={<FormattedMessage id='dashboard-page.calendar' />}
                        >
                            {mode === 'calendar' && dashboardContainer}
                        </TabPane>
                        <TabPane
                            key='employees'
                            disabled={loading}
                            tab={<FormattedMessage id='dashboard-page.employees' />}
                        >
                            {mode === 'employees' && dashboardContainer}
                        </TabPane>
                    </Tabs> */}
                </section>
                <NetworkModal onClose={this.closeNetworkModal} open={networkModalOpen} />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}

export default DashboardPage;
