exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__styledButton---1HhM2{width:100%}.styles-m__col---2uigZ{padding:5px}.styles-m__ogTitle---1o18r{font-size:16px;font-weight:700}.styles-m__ogDesc---2MX1C{margin-bottom:8px;margin-top:8px;opacity:.7}.styles-m__ogImg---2BumL{margin-bottom:4px;margin-top:4px}.styles-m__linkBlock---3hGsT{margin-bottom:12px;background-color:#f5f5f5;border:1px solid #8a8d8a;padding:4px;border-radius:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACI,UACJ,CAEA,uBACI,WACJ,CAEA,2BACI,cAAe,CACf,eACJ,CAEA,0BACI,iBAAkB,CAClB,cAAe,CACf,UACJ,CAEA,yBACI,iBAAkB,CAClB,cACJ,CAEA,6BACI,kBAAmB,CACnB,wBAA4B,CAC5B,wBAAyB,CACzB,WAAY,CACZ,iBACJ","file":"styles.m.css","sourcesContent":[".styledButton {\n    width: 100%;\n}\n\n.col {\n    padding: 5px;\n}\n\n.ogTitle {\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.ogDesc {\n    margin-bottom: 8px;\n    margin-top: 8px;\n    opacity: 0.7;\n}\n\n.ogImg {\n    margin-bottom: 4px;\n    margin-top: 4px;\n}\n\n.linkBlock {\n    margin-bottom: 12px;\n    background-color: whitesmoke;\n    border: 1px solid #8a8d8a;\n    padding: 4px;\n    border-radius: 8px;\n}"]}]);

// Exports
exports.locals = {
	"styledButton": "styles-m__styledButton---1HhM2",
	"col": "styles-m__col---2uigZ",
	"ogTitle": "styles-m__ogTitle---1o18r",
	"ogDesc": "styles-m__ogDesc---2MX1C",
	"ogImg": "styles-m__ogImg---2BumL",
	"linkBlock": "styles-m__linkBlock---3hGsT"
};