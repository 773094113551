/* eslint-disable max-classes-per-file */
import { CalendarOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Menu, Popover, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { isArray } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const { RangePicker } = DatePicker;

@injectIntl
export class DateRangePicker extends React.Component {
    constructor(props) {
        super(props);
    }

    verifyDate(dateRange) {
        // if (dateRange && dateRange.length != 2) {
        //     const thisYear = dayjs().startOf('year');
        //     const defaultDateRange = [ dayjs(thisYear, this.props.dateFormat), dayjs(new Date(), this.props.dateFormat) ];

        //     return defaultDateRange;
        // }

        return isArray(dateRange) ? dateRange : [];
    }

    updateDimensions = () => {
        this.setState({});
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const {
            dateRange,
            onDateChange,
            minimize,
            autoMinimize,
            style,
            overlayStyle,
            type,
            allowClear,
            disabled,
            buttonProps = {},
            getPopupContainer,
            placement = 'bottomLeft',
            intl: { formatMessage }
        } = this.props;

        const maxWidth = typeof autoMinimize === 'number' ? autoMinimize : 1440;
        const minimizeMode = autoMinimize ? window.innerWidth < maxWidth : minimize;

        const dateFormat = this.props.dateFormat || 'DD.MM.YYYY';
        const currentYear = new Date().getFullYear();
        const yearOptions = [];

        for (let year = currentYear - 1; year > currentYear - 4; year--) {
            yearOptions.push(year);
        }

        const datePicker = (
            <div className={Styles.filterDatePicker} style={(style, { backgroundColor: 'white' })}>
                <RangePicker
                    allowClear={allowClear || false}
                    disabled={disabled}
                    format={dateFormat}
                    onChange={newDate => {
                        onDateChange(newDate);
                    }}
                    placement={placement}
                    value={this.verifyDate(dateRange)}
                    getPopupContainer={getPopupContainer}
                    // popupStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999', minWidth: 220 }}
                    style={{ width: '100%' }}
                />
                <div className={Styles.filterDateButtons}>
                    <Dropdown
                        className={Styles.datePickerButton}
                        disabled={disabled}
                        getPopupContainer={getPopupContainer}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([dayjs(new Date()), dayjs(new Date())]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.today' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([dayjs().add(-1, 'day'), dayjs().add(-1, 'day')]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.yesterday' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([dayjs().add(1, 'day'), dayjs().add(1, 'day')]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.tomorrow' />
                                </Menu.Item>
                            </Menu>
                        }
                        overlayStyle={{ zIndex: 9999 }}
                    >
                        <Button>
                            <FormattedMessage id='datepicker.day' />
                        </Button>
                    </Dropdown>
                    <Dropdown
                        className={Styles.datePickerButton}
                        disabled={disabled}
                        getPopupContainer={getPopupContainer}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([dayjs().startOf('week'), dayjs().endOf('week')]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.current_week' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([
                                            dayjs().startOf('week').add(-1, 'week'),
                                            dayjs().endOf('week').add(-1, 'week')
                                        ]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.previous_week' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([
                                            dayjs().startOf('week').add(1, 'week'),
                                            dayjs().endOf('week').add(1, 'week')
                                        ]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.next_week' />
                                </Menu.Item>
                            </Menu>
                        }
                        overlayStyle={{ zIndex: 9999 }}
                    >
                        <Button disabled={disabled}>
                            <FormattedMessage id='datepicker.week' />
                        </Button>
                    </Dropdown>
                    <Dropdown
                        className={Styles.datePickerButton}
                        disabled={disabled}
                        getPopupContainer={getPopupContainer}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([dayjs().startOf('month'), dayjs().endOf('month')]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.current' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([
                                            dayjs().add(-1, 'month').startOf('month'),
                                            dayjs().add(-1, 'month').endOf('month')
                                        ]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.previous' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([
                                            dayjs().add(-2, 'month').startOf('month'),
                                            dayjs().add(-2, 'month').endOf('month')
                                        ]);
                                    }}
                                >
                                    2 <FormattedMessage id='datepicker.month_before' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([
                                            dayjs().add(-3, 'month').startOf('month'),
                                            dayjs().add(-3, 'month').endOf('month')
                                        ]);
                                    }}
                                >
                                    3 <FormattedMessage id='datepicker.month_before' />
                                </Menu.Item>
                            </Menu>
                        }
                        overlayStyle={{ zIndex: 9999 }}
                    >
                        <Button disabled={disabled}>
                            <FormattedMessage id='datepicker.month' />
                        </Button>
                    </Dropdown>
                    <Dropdown
                        className={Styles.datePickerButton}
                        disabled={disabled}
                        getPopupContainer={getPopupContainer}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([dayjs().startOf('quarter'), dayjs().endOf('quarter')]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.current' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([
                                            dayjs().add(-1, 'quarter').startOf('quarter'),
                                            dayjs().add(-1, 'quarter').endOf('quarter')
                                        ]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.previous' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([
                                            dayjs().add(-2, 'quarter').startOf('quarter'),
                                            dayjs().add(-2, 'quarter').endOf('quarter')
                                        ]);
                                    }}
                                >
                                    2 <FormattedMessage id='datepicker.quarters_before' />
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([
                                            dayjs().add(-3, 'quarter').startOf('quarter'),
                                            dayjs().add(-3, 'quarter').endOf('quarter')
                                        ]);
                                    }}
                                >
                                    3 <FormattedMessage id='datepicker.quarters_before' />
                                </Menu.Item>
                            </Menu>
                        }
                        overlayStyle={{ zIndex: 9999 }}
                    >
                        <Button disabled={disabled}>
                            <FormattedMessage id='datepicker.quarter' />
                        </Button>
                    </Dropdown>
                    <Dropdown
                        className={Styles.datePickerButton}
                        disabled={disabled}
                        getPopupContainer={getPopupContainer}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => {
                                        onDateChange([dayjs().startOf('year'), dayjs().endOf('year')]);
                                    }}
                                >
                                    <FormattedMessage id='datepicker.current' />
                                </Menu.Item>
                                {yearOptions.map((year, key) => {
                                    return (
                                        <Menu.Item
                                            key={key}
                                            onClick={() => {
                                                onDateChange([
                                                    dayjs(new Date(`1/1/${year}`)),
                                                    dayjs(new Date(`1/1/${year}`)).endOf('year')
                                                ]);
                                            }}
                                            style={{
                                                textDecoration: 'lowercase'
                                            }}
                                        >
                                            {year} <FormattedMessage id='datepicker.year' />
                                        </Menu.Item>
                                    );
                                })}
                            </Menu>
                        }
                        overlayStyle={{ zIndex: 9999 }}
                    >
                        <Button disabled={disabled}>
                            <FormattedMessage id='datepicker.year' />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        );

        return minimizeMode ? (
            <div className={Styles.minimized} style={style}>
                <Popover content={datePicker} overlayStyle={overlayStyle} trigger='click'>
                    <Tooltip placement='bottom' title={<FormattedMessage id='date' />} zIndex={2001}>
                        <Button type={type || 'text'} {...buttonProps}>
                            <CalendarOutlined style={{ fontSize: 16 }} />
                        </Button>
                    </Tooltip>
                </Popover>
            </div>
        ) : (
            datePicker
        );
    }
}

@injectIntl
export class SingleDatePicker extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            date,
            onDateChange,
            style,
            intl: { formatMessage },
            minimize
        } = this.props;

        const dateFormat = this.props.dateFormat || 'DD.MM.YYYY';

        const datePicker = (
            <div className={Styles.filterDatePicker}>
                <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    onChange={newDate => {
                        onDateChange(newDate);
                    }}
                    style={{ width: '100%' }}
                    value={date}
                />
            </div>
        );

        return minimize ? (
            <div className={Styles.minimized} style={style} title={formatMessage({ id: 'date' })}>
                <Popover content={datePicker} trigger='click'>
                    <Button>
                        <CalendarOutlined style={{ fontSize: 16 }} />
                    </Button>
                </Popover>
            </div>
        ) : (
            <div style={style}>{datePicker}</div>
        );
    }
}
