import { Table } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import book from 'routes/book';

const OrderPanel = ({ orders, intl: { formatMessage } }) => {
    const cashColumns = useMemo(
        () => [
            {
                title: formatMessage({ id: 'order_docs.number' }),
                key: 'num',
                dataIndex: 'num',
                render: num => (
                    <div>
                        <a
                            href={`${book.order}/${num.split('-')[2]}`}
                            style={{
                                color: 'var(--link)',
                                fontWeight: 'bold'
                            }}
                        >
                            {num}
                        </a>
                    </div>
                )
            },
            {
                title: formatMessage({ id: 'date' }),
                key: 'dateTime',
                dataIndex: 'dateTime',
                render: date => dayjs(date).format('DD.MM.YYYY')
            },
            {
                title: formatMessage({ id: 'barcode.product_code' }),
                key: 'code',
                render: row => get(row, 'products[0].code')
            },
            {
                title: formatMessage({ id: 'brand' }),
                key: 'brand',
                render: row => get(row, 'products[0].brandName')
            },
            {
                title: formatMessage({ id: 'status' }),
                key: 'status',
                dataIndex: 'status',
                render: status => <FormattedMessage id={status} />
            }
        ],
        [formatMessage]
    );

    return <Table bordered columns={cashColumns} dataSource={orders} rowKey='id' size='small' />;
};

export default injectIntl(OrderPanel);
