import { emitError } from 'core/ui/duck';
import { selectVehicleId } from 'pages/OnlineServiceBookPage/redux/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchRequest } from '../api';
import {
    fetchVehicleIntervalsSuccess,
    FETCH_VEHICLE_INTERVALS,
    setFetchingVehicleIntervals
} from './duck';

export function* fetchVehicleIntervalsSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_INTERVALS);

            const vehicleId = yield select(selectVehicleId);

            yield put(setFetchingVehicleIntervals(true));

            const { intervals } = yield call(fetchRequest, 'GET', 'tecdoc/intervals', {
                vehicleId
            }); // , tecdocId: vehicle.tecdocId

            yield put(fetchVehicleIntervalsSuccess({ intervals }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingVehicleIntervals(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchVehicleIntervalsSaga)]);
}
