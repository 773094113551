import { mainModuleName } from 'modals/DebtZeroingModal/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'analytics';
const prefix = `cpb/DebtZeroingModal/${moduleName}`;

export const FETCH_ANALYTICS = `${prefix}/FETCH_ANALYTICS`;
export const FETCH_ANALYTICS_SUCCESS = `${prefix}/FETCH_ANALYTICS_SUCCESS`;
export const SET_ANALYTICS_FILTERS = `${prefix}/SET_ANALYTICS_FILTERS`;
export const SET_FETCHING_ANALYTICS = `${prefix}/SET_FETCHING_ANALYTICS`;

export const SET_DEFAULT_ANALYTICS_UNIQUE_ID = `${prefix}/SET_DEFAULT_ANALYTICS_UNIQUE_ID`;

export const CLEAR_ANALYTICS_STATE = `${prefix}/CLEAR_ANALYTICS_STATE`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    analytics: [],
    analyticsFilters: {
        level: 2
        // cashOrderType: CASH_ORDER_TYPES.INCOME, //Default analytics type INCOME because default cash order type is income too
    },
    analyticsStats: {},
    fetchingAnalytics: false,

    selectedIncomeAnalyticsUniqueId: undefined,
    selectedExpenseAnalyticsUniqueId: undefined
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ANALYTICS_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_ANALYTICS_FILTERS:
            return {
                ...state,
                analyticsFilters: {
                    ...state.analyticsFilters,
                    ...payload
                }
            };
        case SET_FETCHING_ANALYTICS:
            return {
                ...state,
                fetchingAnalytics: payload
            };

        case SET_DEFAULT_ANALYTICS_UNIQUE_ID:
            return {
                ...state,
                ...payload
            };
        case CLEAR_ANALYTICS_STATE:
            return ReducerState;

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectAnalytics = state => state[mainModuleName][moduleName].analytics;
export const selectAnalyticsStats = state => state[mainModuleName][moduleName].analyticsStats;
export const selectAnalyticsFilters = state => state[mainModuleName][moduleName].analyticsFilters;
export const selectFetchingAnalytics = state => state[mainModuleName][moduleName].fetchingAnalytics;
export const selectSelectedIncomeAnalyticsUniqueId = state =>
    state[mainModuleName][moduleName].selectedIncomeAnalyticsUniqueId;
export const selectSelectedExpenseAnalyticsUniqueId = state =>
    state[mainModuleName][moduleName].selectedExpenseAnalyticsUniqueId;

/* ------------------------------------- Action Creators -------------------------- */

// eslint-disable-next-line valid-jsdoc
/**
 * After fetching selected analytics will be updated to default
 */
export const fetchAnalytics = () => ({
    type: FETCH_ANALYTICS
});

/*
 * @param {*} fetchedData.analytics
 * @param {*} fetchedData.analyticsStats
 */
export const fetchAnalyticsSuccess = fetchedData => ({
    type: FETCH_ANALYTICS_SUCCESS,
    payload: fetchedData
});

// eslint-disable-next-line valid-jsdoc
/** Provide filters for fetching analytics.
 * Automatically triggers analytics refreshing(action for fetching), default analytics will be selected too.
 * @param { Object } filters - filters object, can contain any fields you want to override
 */
export const setAnalyticsFilters = filters => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_ANALYTICS_FILTERS,
            payload: filters
        });

        dispatch(fetchAnalytics());
    };
};

export const setFetchingAnalytics = value => ({
    type: SET_FETCHING_ANALYTICS,
    payload: value
});

/**
 *
 * @param {Object} value.selectedIncomeAnalyticsUniqueId
 * @param {Object} value.selectedExpenseAnalyticsUniqueId
 */
export const setDefaultAnalyticsUniqueId = value => ({
    type: SET_DEFAULT_ANALYTICS_UNIQUE_ID,
    payload: value
});

/**
 * Clear all analytics cashed data(reset state)
 */
export const clearAnalyticsState = () => ({
    type: CLEAR_ANALYTICS_STATE
});
