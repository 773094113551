exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__form---2lMZt .ant-form-item{margin-bottom:2px}.styles-m__datePicker---1lQeB{width:100%}.styles-m__phoneIcon---3kXvK{font-size:16px;margin:2px}.styles-m__phoneRequired---BrmYZ{color:red;margin-right:4px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,uCACI,iBACJ,CAEA,8BACI,UACJ,CAEA,6BACI,cAAe,CACf,UACJ,CAEA,iCACI,SAAU,CACV,gBACJ","file":"styles.m.css","sourcesContent":[".form :global(.ant-form-item) {\n    margin-bottom: 2px;\n}\n\n.datePicker {\n    width: 100%;\n}\n\n.phoneIcon {\n    font-size: 16px;\n    margin: 2px;\n}\n\n.phoneRequired {\n    color: red;\n    margin-right: 4px;\n}"]}]);

// Exports
exports.locals = {
	"form": "styles-m__form---2lMZt",
	"datePicker": "styles-m__datePicker---1lQeB",
	"phoneIcon": "styles-m__phoneIcon---3kXvK",
	"phoneRequired": "styles-m__phoneRequired---BrmYZ"
};