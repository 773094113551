exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__tableContainer---1yIDR{width:100%}.styles-m__overdueMRD---187ld{background-color:rgba(255,0,0,.3);color:#000}.styles-m__hidden---KQnA6{opacity:0}.styles-m__summaryWrap---3XVkj{border-left:1px solid #f0f0f0;background:var(--snow)}.styles-m__summary---27XP7{font-weight:500;float:right}.dollar-icon{font-size:18px;vertical-align:sub;margin-right:4px}.dollar-icon:hover{color:var(--antd-wave-shadow-color)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,kCACI,UACJ,CAEA,8BACI,iCAAsC,CACtC,UACJ,CAEA,0BACI,SACJ,CAEA,+BACI,6BAA8B,CAC9B,sBACJ,CAEA,2BACI,eAAgB,CAChB,WACJ,CAEA,aACI,cAAe,CACf,kBAAmB,CACnB,gBAKJ,CAHI,mBACI,mCACJ","file":"styles.m.css","sourcesContent":[".tableContainer {\n    width: 100%;\n}\n\n.overdueMRD {\n    background-color: rgba(255, 0, 0, 0.3);\n    color: black;\n}\n\n.hidden {\n    opacity: 0;\n}\n\n.summaryWrap {\n    border-left: 1px solid #f0f0f0;\n    background: var(--snow);\n}\n\n.summary {\n    font-weight: 500;\n    float: right;\n}\n\n:global(.dollar-icon) {\n    font-size: 18px;\n    vertical-align: sub;\n    margin-right: 4px;\n\n    &:hover {\n        color: var(--antd-wave-shadow-color);\n    }\n}"]}]);

// Exports
exports.locals = {
	"tableContainer": "styles-m__tableContainer---1yIDR",
	"overdueMRD": "styles-m__overdueMRD---187ld",
	"hidden": "styles-m__hidden---KQnA6",
	"summaryWrap": "styles-m__summaryWrap---3XVkj",
	"summary": "styles-m__summary---27XP7"
};