exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__table---SRGer .ant-table-thead>tr>th>span>span{white-space:nowrap}.styles-m__orderLink---2f_RI{color:var(--link)}.styles-m__orderLink---2f_RI:hover{color:var(--primary)}.styles-m__datetime---3zcMW{display:flex;flex-direction:column;width:75px}.styles-m__client---2MJyk{width:100%;margin:2px 0}.styles-m__createClient---2EMVx{width:100%}.styles-m__channels---36-jV{width:200px;margin-left:10px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0DACI,kBACJ,CAGA,6BACI,iBACJ,CAEA,mCACI,oBACJ,CAEA,4BACI,YAAa,CACb,qBAAsB,CACtB,UACJ,CAEA,0BACI,UAAW,CACX,YACJ,CAEA,gCACI,UACJ,CAEA,4BACI,WAAY,CACZ,gBACJ","file":"styles.m.css","sourcesContent":[".table :global(.ant-table-thead > tr > th > span > span) {\n    white-space: nowrap;\n}\n\n\n.orderLink {\n    color: var(--link);\n}\n\n.orderLink:hover {\n    color: var(--primary);\n}\n\n.datetime {\n    display: flex;\n    flex-direction: column;\n    width: 75px;\n}\n\n.client {\n    width: 100%;\n    margin: 2px 0 2px 0;\n}\n\n.createClient {\n    width: 100%;\n}\n\n.channels {\n    width: 200px;\n    margin-left: 10px;\n}"]}]);

// Exports
exports.locals = {
	"table": "styles-m__table---SRGer",
	"orderLink": "styles-m__orderLink---2f_RI",
	"datetime": "styles-m__datetime---3zcMW",
	"client": "styles-m__client---2MJyk",
	"createClient": "styles-m__createClient---2EMVx",
	"channels": "styles-m__channels---36-jV"
};