import { Form } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Select, Tooltip } from 'antd';
import { DecoratedInput, DecoratedSelect, DecoratedTreeSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import { selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';
import { cashboxTypes } from './config';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => {
    return {
        user: state.auth,
        businessRequisites: selectBusinessRequisites(state)
    };
};

@injectIntl
@Form.create()
@connect(mapStateToProps, void 0)
export class CashCreationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tree: []
        };

        props.getFormRefCB && props.getFormRefCB(this.props.form); // Callback to get form's refference
    }

    /**
     * This method generates styled and structured row
     * @param {*} label     The component which represents label(like any div, h1, h2, h3 and other tags)
     * @param {*} component The component to be placed as a main input or select(pass Form item in it)
     * @returns
     */
    generateStyledContentRow(label, component) {
        return (
            <Row className={Styles.row}>
                <Col className={Styles.label} span={8}>
                    {label}
                </Col>

                <Col className={Styles.content} span={16}>
                    {component}
                </Col>
            </Row>
        );
    }

    filterTreeByActive = nodes => {
        const filteredNodes = nodes.reduce((accumulator, node) => {
            if (node.active === true) {
                const filteredNode = {
                    value: node.id,
                    title: `(#${node.id}) ${node.name} `,
                    level: node.level,
                    disabled: node.level !== 4
                };

                if (node.children) {
                    const filteredChildren = this.filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNode.children = filteredChildren;
                    }
                }

                accumulator.push(filteredNode);
            }

            return accumulator;
        }, []);

        // Update the state outside of the reduce function
        this.setState({
            tree: filteredNodes
        });

        return filteredNodes;
    };

    componentDidUpdate() {
        const { tree } = this.state;
        if (!_.get(tree, 'length') && _.get(this.props.accounts, 'length')) {
            this.filterTreeByActive(this.props.accounts);
        }
    }

    render() {
        const {
            user,
            form,
            editMode,
            businessRequisites,
            intl: { formatMessage },
            accounts
        } = this.props;

        const { tree } = this.state;

        const { getFieldDecorator, getFieldsError } = form;

        const errors = getFieldsError();
        const formFieldsValues = form.getFieldsValue();

        return (
            <Form id='cash-creation-form'>
                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.name' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        field='name'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({ id: 'cash-creation-form.name.validation' })
                            }
                        ]}
                    />
                )}
                {/* ------------------------------------------------------------- */}

                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.type' />:
                    </span>,
                    <DecoratedSelect
                        className={Styles.formItemStyle}
                        disabled={editMode}
                        field='type'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={() => document.getElementById('cash-creation-form')}
                        initialValue={cashboxTypes.CASH}
                    >
                        {Object.values(cashboxTypes).map(item => (
                            <Option key={item} value={item}>
                                {formatMessage({ id: `cash-creation-form.type-${item}` })}
                            </Option>
                        ))}
                    </DecoratedSelect>
                )}

                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='directory_page.requisites' />:
                    </span>,
                    <DecoratedSelect
                        allowClear
                        className={Styles.formItemStyle}
                        // disabled={editMode}
                        field='businessRequisiteId'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={() => document.getElementById('cash-creation-form')}
                        initialValue={user.businessRequisitesId}
                    >
                        {businessRequisites
                            .filter(({ enabled }) => enabled)
                            .map(({ id, name }) => (
                                <Option key={id} value={id}>
                                    {name}
                                </Option>
                            ))}
                    </DecoratedSelect>
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='new-document-page.item.account' />:
                    </span>,
                    <DecoratedTreeSelect
                        className={Styles.formItemStyle}
                        field='accountId'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        optionLabel='title'
                        treeData={tree}

                        // style={{ color: 'var(--text)', width: '100%' }}
                    />
                )}
                {/* ------------------------------------------------------------- */}

                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.fiscal_number' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={isForbidden(user, permissions.ACCESS_CASHBOX_CRUD) || editMode}
                        field='fiscalNumber'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        rules={
                            [
                                // {
                                //     len: 10,
                                //     message: formatMessage({
                                //         id: 'cash-creation-form.fiscal_number_too_short'
                                //     })
                                // },
                                // {
                                //     pattern: /^\d+$/,
                                //     message: formatMessage({
                                //         id: 'cash-creation-form.fiscal_number_digits_only'
                                //     })
                                // }
                            ]
                        }
                    />
                )}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='Ключ ліцензії' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={isForbidden(user, permissions.ACCESS_CASHBOX_CRUD) || editMode}
                        field='licenseKey'
                        formItem // Disable to prevent prod from coccupting(temp)
                        getFieldDecorator={getFieldDecorator}
                        rules={
                            [
                                // {
                                //     len: 10,
                                //     message: formatMessage({
                                //         id: 'cash-creation-form.fiscal_number_too_short'
                                //     })
                                // },
                                // {
                                //     pattern: /^\d+$/,
                                //     message: formatMessage({
                                //         id: 'cash-creation-form.fiscal_number_digits_only'
                                //     })
                                // }
                            ]
                        }
                    />
                )}
                {/* ------------------------------------------------------------- */}

                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.description' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={editMode}
                        field='description'
                        formItem
                        getFieldDecorator={getFieldDecorator} // If you will not provide this you won't be aple to enter text into this input
                        rules={[]}
                    />
                )}
                {/* ------------------------------------------------------------- */}

                {/* ------------------------------------------------------------- */}
                {this.generateStyledContentRow(
                    <span>
                        <FormattedMessage id='cash-creation-form.autoClosePeriod' />:
                    </span>,
                    <DecoratedInput
                        className={Styles.formItemStyle}
                        disabled={
                            !_.get(formFieldsValues, 'fiscalNumber') ||
                            Boolean(_.get(errors, 'fiscalNumber')) ||
                            isForbidden(user, permissions.ACCESS_CASHBOX_CRUD)
                        }
                        field='autoClosePeriod'
                        formItem
                        getFieldDecorator={getFieldDecorator} // If you will not provide this you won't be aple to enter text into this input
                        rules={[]}
                        suffix={
                            <Tooltip getPopupContainer={trigger => trigger.parentNode} title='Формат Cron'>
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                        }
                    />
                )}
                {/* ------------------------------------------------------------- */}
            </Form>
        );
    }
}
