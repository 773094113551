exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__select---24QAS{width:100%}.styles-m__saveIcon---3Pjjc{font-size:20px;cursor:pointer;color:var(--secondary)}.styles-m__newScheduleBreak---ru85b{display:flex;align-items:center;margin:24px auto}.styles-m__scheduleType---1BRu2{width:100%}.styles-m__breakScheduleFormItem---1tFmQ{margin-bottom:0}.styles-m__newBreakScheduleRow---3J-hT{background-color:rgba(11,127,0,.28)}.styles-m__editedBreakScheduleRow---1MgKM{background-color:rgba(255,45,45,.28)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0BACI,UACJ,CAEA,4BACI,cAAe,CACf,cAAe,CACf,sBACJ,CAEA,oCACI,YAAa,CACb,kBAAmB,CACnB,gBACJ,CAEA,gCACI,UACJ,CAEA,yCACI,eACJ,CAEA,uCACI,mCACJ,CAEA,0CACI,oCACJ","file":"styles.m.css","sourcesContent":[".select {\n    width: 100%;\n}\n\n.saveIcon {\n    font-size: 20px;\n    cursor: pointer;\n    color: var(--secondary);\n}\n\n.newScheduleBreak {\n    display: flex;\n    align-items: center;\n    margin: 24px auto;\n}\n\n.scheduleType {\n    width: 100%;\n}\n\n.breakScheduleFormItem {\n    margin-bottom: 0;\n}\n\n.newBreakScheduleRow {\n    background-color: rgba(11, 127, 0, 0.28);\n}\n\n.editedBreakScheduleRow {\n    background-color: rgba(255, 45, 45, 0.28);\n}\n"]}]);

// Exports
exports.locals = {
	"select": "styles-m__select---24QAS",
	"saveIcon": "styles-m__saveIcon---3Pjjc",
	"newScheduleBreak": "styles-m__newScheduleBreak---ru85b",
	"scheduleType": "styles-m__scheduleType---1BRu2",
	"breakScheduleFormItem": "styles-m__breakScheduleFormItem---1tFmQ",
	"newBreakScheduleRow": "styles-m__newBreakScheduleRow---3J-hT",
	"editedBreakScheduleRow": "styles-m__editedBreakScheduleRow---1MgKM"
};