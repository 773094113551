import { Modal } from 'antd';
import { StatsCountsPanel } from 'components';
import { MODALS } from 'core/modals/duck';
import { UniversalFiltersForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class UniversalFiltersModal extends Component {
    state = {
        // Whether to apply loading visual effect for OK button or not
        confirmLoading: false
    };

    render() {
        const {
            visible,

            // Actions
            resetModal,

            // Filters
            vehicleMakes,
            vehicleModels,
            managers,
            employees,
            creationReasons,
            orderComments,
            services,

            // Modal stats
            stats,

            form
        } = this.props;

        // Parent Node which the selector should be rendered to.
        // Default to body. When position issues happen,
        // try to modify it into scrollable content and position it relative.
        // Example:
        // offical doc: https://codesandbox.io/s/4j168r7jw0
        // git issue: https://github.com/ant-design/ant-design/issues/8461
        // let modalContentDivWrapper = null;

        return (
            <Modal
                cancelText={<FormattedMessage id='universal_filters.cancel' />}
                className={Styles.universalFiltersModal}
                maskClosable={false}
                okText={<FormattedMessage id='universal_filters.submit' />}
                onCancel={() => resetModal()}
                onOk={() => {
                    this.props.setUniversalFilter(this.props.form.getFieldsValue());
                    // hideModal();
                }}
                title={<FormattedMessage id='universal_filters' />}
                visible={visible === MODALS.UNIVERSAL_FILTERS}
                width='80%'
                wrapClassName={Styles.ufmoldal}
            >
                <StatsCountsPanel stats={stats} />
                <UniversalFiltersForm
                    creationReasons={creationReasons}
                    employees={employees}
                    form={form}
                    managers={managers}
                    orderComments={orderComments}
                    services={services}
                    vehicleMakes={vehicleMakes}
                    vehicleModels={vehicleModels}
                    // onSubmit={ () => handleUniversalFiltersModalSubmit() }
                />
            </Modal>
        );
    }
}
