import { Table } from 'antd';
import { DateRangePicker } from 'components';
import {
    fetchCashboxesActivity,
    selectCashAccountingFilters,
    setCashAccountingFilters,
    setCashOrdersFilters
} from 'core/cash/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { linkTo } from 'utils';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    data: state.cash.activity,
    filters: selectCashAccountingFilters(state)
});

const mapDispatchToProps = {
    fetchCashboxesActivity,
    setCashAccountingFilters,
    setCashOrdersFilters
};

@connect(mapStateToProps, mapDispatchToProps)
export class CashActivityTable extends Component {
    constructor(props) {
        super(props);

        this.columns = columnsConfig();
    }

    componentDidMount() {
        this.props.fetchCashboxesActivity();
    }

    _onDateRangeChange = value => {
        const normalizedValue = value.map(date => date.format('YYYY-MM-DD'));
        const daterange = {
            startDate: normalizedValue[0],
            endDate: normalizedValue[1]
        };
        this.props.setCashAccountingFilters(daterange);
        this.props.fetchCashboxesActivity();
    };

    _onRowClick = data => {
        const { filters, setCashOrdersFilters } = this.props;
        linkTo(book.cashFlowPage);
        setCashOrdersFilters({
            cashBoxId: data.id,
            startDate: filters.startDate,
            endDate: filters.endDate
        });
    };

    render() {
        const { cashboxesFetching, data, filters } = this.props;

        return (
            <div className={Styles.tableWrapper}>
                <div className={Styles.tableHead}>
                    <DateRangePicker
                        dateRange={[
                            dayjs(_.get(filters, 'startDate')),
                            dayjs(_.get(filters, 'endDate'))
                        ]}
                        minimize
                        onDateChange={this._onDateRangeChange}
                    />
                </div>
                <Table
                    bordered
                    className={Styles.table}
                    columns={this.columns}
                    dataSource={data}
                    loading={cashboxesFetching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    onRow={record => ({
                        onClick: () => this._onRowClick(record)
                    })}
                    pagination={false}
                    rowKey='id'
                    size='small'
                />
            </div>
        );
    }
}
