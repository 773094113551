import { Col, Row, Select } from 'antd';
import { CashboxRSTIcon } from 'commons';
import _ from 'lodash';
import {
    fetchCashboxes,
    selectCashboxes,
    selectFetchingCashboxes,
    selectSelectedCashboxId,
    setSelectedCashboxId
} from 'modals/RefactoredCashOrderModal/redux/cashboxes/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    selectedCashboxId: selectSelectedCashboxId(state),
    cashboxes: selectCashboxes(state),
    fetchingCashboxes: selectFetchingCashboxes(state)
});

const mapDispatchToProps = {
    fetchCashboxes,
    setSelectedCashboxId
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CashboxSelector extends React.Component {
    componentDidMount() {
        this.props.fetchCashboxes();
    }

    render() {
        const {
            cashboxes,
            selectedCashboxId,
            fetchingCashboxes,

            setSelectedCashboxId,

            intl: { formatMessage }
        } = this.props;

        return (
            <div title={formatMessage({ id: 'debt_zeroing_modal.hint_cashbox' })}>
                <Select
                    className={Styles.select}
                    disabled={fetchingCashboxes}
                    getPopupContainer={trigger => trigger.parentNode}
                    loading={fetchingCashboxes}
                    onSelect={cashboxId => setSelectedCashboxId(cashboxId)}
                    value={selectedCashboxId}
                >
                    {cashboxes &&
                        _.map(cashboxes, (cashbox, index) => {
                            const { id, name, type, rst, isShiftOpen, onlineCashdeskState } = cashbox;

                            return (
                                <Option key={index} value={id}>
                                    <Row>
                                        <Col span={12}>{name}</Col>
                                        <Col className={Styles.cashboxType} span={6}>
                                            {type}
                                        </Col>
                                        <Col span={6}>
                                            <div className={Styles.cashboxRST}>
                                                {rst ? (
                                                    <CashboxRSTIcon
                                                        isShiftOpen={isShiftOpen}
                                                        onlineCashdeskState={onlineCashdeskState}
                                                    />
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                </Option>
                            );
                        })}
                </Select>
            </div>
        );
    }
}
