import { Button, Col, Input, Radio, Row, Select } from 'antd';
import { Numeral } from 'commons';
import { fetchEmployees } from 'core/employees/duck';
import { resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import StatsOrderCountsPanel from './components/StatsPanel';
import { fetchOrderStats, selectOrderStats } from './redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    employees: state.employees.employees,
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    orderStats: selectOrderStats(state)
});

const mapDispatchToProps = {
    resetModal,
    fetchOrderStats,
    fetchEmployees
    // resetFields,
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StatisticsTab extends Component {
    handleSubmit = e => {
        e.preventDefault();
    };

    componentDidMount() {
        const { orderId, fetchOrderStats, fetchEmployees } = this.props;

        fetchOrderStats(orderId);
        fetchEmployees();
    }

    postOrderStats = async () => {
        const { orderId, fetchedOrder, employees } = this.props;
        const allWorkersIds = [];

        const allServices = _.get(fetchedOrder, 'orderServices');
        const allProducts = _.get(fetchedOrder, 'orderDetails');

        const rightManagerId = employees.filter(({ managerId }) => managerId == _.get(fetchedOrder, 'order.managerId'));

        allWorkersIds.push(...allServices.map(({ employeeId }) => employeeId));
        allWorkersIds.push(...allProducts.map(({ responsibleId }) => responsibleId));
        allWorkersIds.push(
            _.get(fetchedOrder, 'order.appurtenanciesResponsibleId'),
            _.get(fetchedOrder, 'order.employeeId'),
            _.get(rightManagerId, '[0].id')
        );

        await fetchAPI('POST', 'calculate/employee/salary', undefined, {
            orderId: Number(orderId),
            employeesIds: [...new Set(allWorkersIds.filter(elem => elem !== null))],
            status: 'ACCRUED',
            deliveryDateTime: _.get(fetchedOrder, 'order.deliveryDatetime') || dayjs().toISOString(),
            doneDateTime: _.get(fetchedOrder, 'order.successDatetime') || undefined
        });
    };

    render() {
        const { orderStats, fetchedOrder } = this.props;

        const stats = [
            {
                label: 'sum_without_discount',
                laborValue: orderStats.laborsSum,
                appurtenanceValue: orderStats.appurtenanciesSum,
                totalValue: orderStats.totalSum
            },
            {
                label: 'discount',
                laborValue: orderStats.laborsSumDiscount,
                appurtenanceValue: orderStats.appurtenanciesSumDiscount,
                totalValue: orderStats.totalSumDiscount,

                withPercent: true,
                laborDiscount: orderStats.laborsDiscount,
                appurtenanceDiscount: orderStats.appurtenanciesDiscount,
                totalDiscount: orderStats.totalDiscount
            },
            {
                label: 'sum_with_discount',
                laborValue: orderStats.laborsSumWithDiscount,
                appurtenanceValue: orderStats.appurtenancesSumWithDiscount,
                totalValue: orderStats.totalSumWithDiscount
            },
            {
                label: 'cost_price',
                laborValue: orderStats.laborsCostPrice,
                appurtenanceValue: orderStats.appurtenancesCostPrice,
                totalValue: orderStats.totalCostPrice
            },
            {
                label: 'sum_profit',
                laborValue: orderStats.laborsSumProfit,
                appurtenanceValue: orderStats.appurtenanciesSumProfit,
                totalValue: orderStats.totalSumProfit,

                withPercent: true,
                laborDiscount: (
                    (Number(orderStats.laborsSumProfit) / Number(orderStats.laborsSumWithDiscount)) *
                    100.0
                ).toFixed(),
                appurtenanceDiscount: (
                    (Number(orderStats.appurtenanciesSumProfit) / Number(orderStats.appurtenancesSumWithDiscount)) *
                    100.0
                ).toFixed(),
                totalDiscount: (
                    (Number(orderStats.totalSumProfit) / Number(orderStats.totalSumWithDiscount)) *
                    100.0
                ).toFixed()
            },
            {
                label: 'salaries',
                laborValue: orderStats.laborsSalary,
                appurtenanceValue: orderStats.partsSalary,
                totalValue: orderStats.salary
            },
            {
                label: 'operating_profit',
                laborValue: orderStats.laborsOperatingProfit,
                appurtenanceValue: null,
                totalValue: orderStats.totalOperatingProfit,

                withPercent: true,
                laborDiscount: (
                    (Number(orderStats.laborsOperatingProfit) / Number(orderStats.laborsSumProfit)) *
                    100
                ).toFixed(),
                appurtenanceDiscount: null,
                totalDiscount: (
                    (Number(orderStats.totalOperatingProfit) / Number(orderStats.totalSumProfit)) *
                    100
                ).toFixed()
            }
        ];

        const {
            resetModal,
            visible,
            orderId,
            fetchOrderStats,
            isMobile,

            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <div className={Styles.mainOrderInfoContainer}>
                    <Row className={Styles.row}>
                        <Col span={6}></Col>
                        <Col className={Styles.headerOrderInfo} span={6}>
                            <FormattedMessage id='order_stats_info_modal.creation' />
                        </Col>
                        <Col className={Styles.headerOrderInfo} span={6}>
                            <FormattedMessage id='order_stats_info_modal.record' />
                        </Col>
                        <Col className={Styles.headerOrderInfo} span={6}>
                            <FormattedMessage id='order_stats_info_modal.success_datetime' />
                        </Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6} style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id='order_stats_info_modal.datetime' />
                        </Col>
                        <Col className={Styles.orderInfo} span={6}>
                            {orderStats.datetime ? orderStats.datetime : <FormattedMessage id='long_dash' />}
                        </Col>
                        <Col className={Styles.orderInfo} span={6}>
                            {orderStats.datetime ? orderStats.datetime : <FormattedMessage id='long_dash' />}
                        </Col>
                        <Col className={Styles.orderInfo} span={6}>
                            {orderStats.datetimeSuccess ? (
                                orderStats.datetimeSuccess
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </Col>
                    </Row>
                </div>
                <Row className={Styles.row} style={{ marginTop: '1em', marginBottom: '-2em' }}>
                    <Col span={6}></Col>
                    <Col className={Styles.headerOrderInfo} span={6}>
                        <FormattedMessage id='order_stats_info_modal.labors' />
                    </Col>
                    <Col className={Styles.headerOrderInfo} span={6}>
                        <FormattedMessage id='order_stats_info_modal.appurtenances' />
                    </Col>
                    <Col className={Styles.headerOrderInfo} span={6}>
                        <FormattedMessage id='order_stats_info_modal.total' />
                    </Col>
                </Row>
                <StatsOrderCountsPanel stats={stats} />
                <Row className={Styles.row} style={{ fontSize: '1.2em', marginTop: '1em' }}>
                    <Col span={6} style={{ textAlign: 'right' }}>
                        <FormattedMessage id='order_stats_info_modal.paid' />
                        :&nbsp;
                    </Col>
                    <Col span={6}>
                        <Numeral mask='0,0.00'>{Number(orderStats.totalSumCashOrders)}</Numeral>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                        <FormattedMessage id='order_stats_info_modal.remainder' />
                        :&nbsp;
                    </Col>
                    <Col span={6}>
                        <Numeral mask='0,0.00'>
                            {Number(orderStats.totalSumWithDiscount) - Number(orderStats.totalSumCashOrders)}
                        </Numeral>
                    </Col>
                </Row>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        key='submit'
                        onClick={async () => {
                            await this.postOrderStats();
                            fetchOrderStats(orderId, true);
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='order_stats_info_modal.recalculate' />
                    </Button>
                </div>
            </div>
        );
    }
}
