exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__mainContainer---6dPt6{width:100%;padding:0 .2em 1em;border:.2em solid rgba(0,0,0,.2)}.styles-m__row---1npE8{margin-top:1em}.styles-m__actionsCol---1IzuW,.styles-m__col---E8ckj{padding:0 .2em}.styles-m__vehicle---2K24Z{display:flex;justify-content:space-between;margin-bottom:.4em}.styles-m__phoneNumber---37Y27{color:var(--link)}.styles-m__phoneNumber---37Y27:hover{color:var(--primary)}.styles-m__actionsCol---1IzuW{display:flex;justify-content:center}.styles-m__closeIcon---24UQn{color:red;font-size:16px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,iCACI,UAAW,CACX,kBAA0B,CAC1B,gCACJ,CAEA,uBACI,cACJ,CAEA,qDACI,cACJ,CAEA,2BACI,YAAa,CACb,6BAA8B,CAC9B,kBACJ,CAEA,+BACI,iBACJ,CAEA,qCACI,oBACJ,CAEA,8BAEI,YAAa,CACb,sBACJ,CAEA,6BACI,SAAU,CACV,cACJ","file":"styles.m.css","sourcesContent":[".mainContainer {\n    width: 100%;\n    padding: 0 0.2em 1em 0.2em;\n    border: 0.2em solid rgba(0, 0, 0, 0.2);\n}\n\n.row {\n    margin-top: 1em;\n}\n\n.col, .actionsCol {\n    padding: 0 0.2em 0 0.2em;\n}\n\n.vehicle {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 0.4em;\n}\n\n.phoneNumber {\n    color: var(--link);\n}\n\n.phoneNumber:hover {\n    color: var(--primary);\n}\n\n.actionsCol {\n    background-color: ;\n    display: flex;\n    justify-content: center;\n}\n\n.closeIcon {\n    color: red;\n    font-size: 16px;\n}"]}]);

// Exports
exports.locals = {
	"mainContainer": "styles-m__mainContainer---6dPt6",
	"row": "styles-m__row---1npE8",
	"col": "styles-m__col---E8ckj",
	"actionsCol": "styles-m__actionsCol---1IzuW",
	"vehicle": "styles-m__vehicle---2K24Z",
	"phoneNumber": "styles-m__phoneNumber---37Y27",
	"closeIcon": "styles-m__closeIcon---24UQn"
};