/*
The purpose of this module is to provide report about all client debts.
Also it provides basic search and print button.
*/
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Tabs } from 'antd';
import { Catcher, Layout } from 'commons';
import { ClientsDebtsContainer, SuppliersDebtsContainer } from 'containers';
import { setModal } from 'core/modals/duck';
import _ from 'lodash';
import { DebtZeroingModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

/**
 * This page contains information about debts of different counterparts(clients and suppliers)
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ReceivablesAndPayablesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'accounting_cash_clients_debts' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { setModal, user } = this.props;
        const { helperDrawerOpen, allLinks } = this.state;

        return (
            <Layout
                controls={
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        {/* {isGrantAccessed(user, grants.ACCOUNTING_OUTSTANDING_DEBTS, accesses.ROWO) ? (
                            <Button onClick={() => setModal(MODALS.DEBT_ZEROING)} type='primary'>
                                <FormattedMessage id='receivables_and_payables_page.debt_zeroing' />
                            </Button>
                        ) : null} */}
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                this.setState({
                                    helperDrawerOpen: true
                                });
                                await this.fetchHelperLinks();
                            }}
                            style={{
                                fontSize: 22,
                                marginLeft: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </div>
                }
                title={<FormattedMessage id='navigation.receivables_and_payables' />}
            >
                <div className={Styles.mainCont}>
                    <Tabs>
                        {isGrantAccessed(user, grants.ACCOUNTING_OUTSTANDING_DEBTS_RECEIVABLES) && (
                            <TabPane
                                key='receivables'
                                tab={<FormattedMessage id='receivables_and_payables_page.receivables' />}
                            >
                                <ClientsDebtsContainer />
                            </TabPane>
                        )}

                        {isGrantAccessed(user, grants.ACCOUNTING_OUTSTANDING_DEBTS_PAYABLES) && (
                            <TabPane
                                key='payables'
                                tab={<FormattedMessage id='receivables_and_payables_page.payables' />}
                            >
                                <SuppliersDebtsContainer />
                            </TabPane>
                        )}
                    </Tabs>
                    <Catcher>
                        <DebtZeroingModal />
                    </Catcher>
                </div>
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
