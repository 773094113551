import { FilterFilled, SearchOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Input, Switch, Table, Tooltip } from 'antd';
import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};
@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class EditMakesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
            popular: true
        };
        this.columns = [
            {
                title: () => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <FormattedMessage id='edit_vehicle.makes' />
                        </div>
                    </div>
                ),
                width: '50%',
                dataIndex: 'carBrand',
                key: 'makes',
                ...this.getColumnSearchProps('filterCarBrand'),
                render: (data, row) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>{data}</div>
                            <div>
                                <Tooltip
                                    title={<FormattedMessage id='edit_vehicle.popular_makes' />}
                                >
                                    <Button
                                        checked={row.popular}
                                        icon={row.popular ? <StarFilled /> : <StarOutlined />}
                                        onClick={async () => {
                                            await fetchAPI('POST', 'vehicle/popular', undefined, {
                                                popularity: !row.popular,
                                                makeId: row.brandId
                                            });
                                            this.getAllVehicles();
                                        }}
                                        style={{
                                            fontSize: 16
                                        }}
                                        type='text'
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    );
                }
            },
            // {
            //     title: <FormattedMessage id='edit_vehicle.models' />,
            //     width: '25%',
            //     dataIndex: 'model',
            //     key: 'models'
            // },
            // {
            //     title: <FormattedMessage id='edit_vehicle.modification' />,
            //     width: '30%',
            //     key: 'modification',
            //     render: data => {
            //         return (
            //             <div>
            //                 <span>{data.modification}</span>, <span>{data.modelYear}</span>,{' '}
            //                 <span>{data.capacity}</span>
            //             </div>
            //         );
            //     }
            // },
            {
                title: () => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <FormattedMessage id='edit_vehicle.display' />
                        </div>
                    </div>
                ),
                width: '50%',
                dataIndex: 'carBrand',
                key: 'makes',
                render: (data, row) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>
                                <Tooltip title={<FormattedMessage id='edit_vehicle.display' />}>
                                    <Switch
                                        checked={row.view}
                                        onChange={async () => {
                                            await fetchAPI('POST', 'vehicle/view', undefined, {
                                                view: !row.view,
                                                makeId: row.brandId
                                            });
                                            this.getAllVehicles();
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.getAllVehicles();
    }

    getAllVehicles = async () => {
        const { page, pageSize, popular, onView, carBrand, filterCarBrand, brandId } = this.state;
        const data = await fetchAPI(
            'GET',
            '/all/vehicles',
            {
                page,
                pageSize,
                popular: Boolean(popular) || false,
                onView: Boolean(onView) || false,
                carBrand,
                filterCarBrand,
                brandId
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: data.result,
            stats: data.stats.count
        });
    };

    getColumnSearchProps = dataIndex => {
        const filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <Button
                            icon={<SearchOutlined style={{ marginRight: 4 }} />}
                            onClick={() => this.handleSearch(confirm, dataIndex)}
                            size='small'
                            type='primary'
                        >
                            <FormattedMessage id='search' />
                        </Button>
                        <Button
                            onClick={() => this.handleReset(confirm, clearFilters, dataIndex)}
                            size='small'
                        >
                            <FormattedMessage id='reset' />
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.getAllVehicles();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1 });
        this.getAllVehicles();
    };

    render() {
        const { dataSource, stats, pageSize, page } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(stats / pageSize) * pageSize,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.getAllVehicles();
            }
        };

        return (
            <Layout title={<FormattedMessage id='edit_vehicle.title' />}>
                <Table
                    columns={this.columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    size='small'
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    {/* <Button
                        onClick={() => {
                            this.getAllVehicles();
                        }}
                        type='primary'
                    >
                        Зберегти
                    </Button> */}
                </div>
            </Layout>
        );
    }
}

export default EditMakesPage;
