import { Button, DatePicker, Table, TimePicker, notification } from 'antd';
import { Catcher, Numeral } from 'commons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const dataSource = [
    {
        title: <FormattedMessage id='setting-salaty.start_date' />
    },
    {
        title: <FormattedMessage id='setting-salary.end_date' />
    },
    {
        title: <FormattedMessage id='beginBreakHours' />
    },
    {
        title: <FormattedMessage id='endBreakHours' />
    },
    {
        title: <FormattedMessage id='setting-salary.working_time' />
    }
];

const HOURS_REGEX = 'HH:mm';

// eslint-disable-next-line react/prefer-stateless-function
@injectIntl
export default class EmployeeStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.columns = () => {
            return [
                {
                    title: () => (
                        <DatePicker
                            disabled={
                                !isGrantAccessed(this.props.user, grants.DIRECTORIES_EMPLOYEE_CARD, accesses.ROWO)
                            }
                            onChange={async date => {
                                await this.setState({ scheduleDate: date });
                                this.props.fetchFactSchedule(
                                    this.props.employeeId,
                                    this.state.scheduleDate.format('YYYY-MM-DD')
                                );
                            }}
                            value={dayjs(this.state.scheduleDate) || dayjs()}
                        />
                    ),
                    dataIndex: 'title'
                },
                {
                    title: <FormattedMessage id='setting-salaty.start_date' />,
                    align: 'center',
                    render: row => {
                        const { scheduleDate, registredStartTime, editregistredStartTime } = this.state;

                        const plannedStartTimeplanned = _.get(
                            this.props.factSchedule,
                            '[0].plannedStartTime',
                            undefined
                        );
                        const registredStartTimeField = _.get(
                            this.props.factSchedule,
                            '[0].registredStartTime',
                            undefined
                        );

                        return row.value === 'planned' ? (
                            <div className={Styles.timePickerPosition}>
                                {plannedStartTimeplanned ? plannedStartTimeplanned.slice(0, 5) : undefined}
                            </div>
                        ) : _.get(this.props.factSchedule, '[0].registredStartTime') && !editregistredStartTime ? (
                            <div>
                                {registredStartTimeField ? registredStartTimeField.slice(0, 5) : undefined}
                                <div>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={() => {
                                            this.setState({
                                                editregistredStartTime: true
                                            });
                                        }}
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <FormattedMessage id='edit' />
                                    </Button>
                                </div>
                            </div>
                        ) : !_.get(this.props.factSchedule, '[0].registredStartTime') || editregistredStartTime ? (
                            <div>
                                <div className={Styles.timePickerPosition}>
                                    <TimePicker
                                        allowClear={false}
                                        disabled={
                                            row.value == 'planned' ||
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        format='HH:mm'
                                        getPopupContainer={trigger => trigger.parentNode}
                                        minuteStep={30}
                                        onChange={time => {
                                            this.setState({ registredStartTime: time });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'employee_task_modal.deadline_time_placeholder'
                                        })}
                                        value={dayjs(registredStartTime)}
                                    />
                                </div>
                                <div className={Styles.actionBtn}>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={async () => {
                                            await fetchAPI(
                                                'PUT',
                                                'registred_hours',
                                                null,
                                                {
                                                    date: scheduleDate
                                                        ? scheduleDate.format('YYYY-MM-DD')
                                                        : dayjs().format('YYYY-MM-DD'),
                                                    employeeId: Number(this.props.employeeId),
                                                    registredStartTime: registredStartTime
                                                        ? registredStartTime.format(HOURS_REGEX)
                                                        : dayjs().format(HOURS_REGEX),
                                                    presetEndTime:
                                                        _.get(this.props.factSchedule, '[0]presetEndTime') || null,
                                                    registredEndTime:
                                                        _.get(this.props.factSchedule, '[0]registredEndTime') || null,
                                                    registredBreakStartTime:
                                                        _.get(this.props.factSchedule, '[0]registredBreakStartTime') ||
                                                        null,
                                                    registredBreakEndTime:
                                                        _.get(this.props.factSchedule, '[0]registredBreakEndTime') ||
                                                        null
                                                },
                                                { handleErrorInternally: true }
                                            );
                                            await this.props.fetchFactSchedule(
                                                this.props.employeeId,
                                                scheduleDate
                                                    ? scheduleDate.format('YYYY-MM-DD')
                                                    : dayjs().format('YYYY-MM-DD')
                                            );
                                            this.setState({
                                                editregistredStartTime: false,
                                                presetEndTime: undefined,
                                                registredStartTime: undefined,
                                                registredEndTime: undefined,
                                                registredBreakStartTime: undefined,
                                                registredBreakEndTime: undefined
                                            });
                                        }}
                                        style={{
                                            marginLeft: 4,
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='submit' />
                                    </Button>
                                </div>
                            </div>
                        ) : null;
                    }
                },
                {
                    title: <FormattedMessage id='setting-salary.end_date' />,
                    align: 'center',
                    render: row => {
                        const {
                            scheduleDate,
                            presetEndTime,
                            registredStartTime,
                            registredEndTime,
                            registredBreakStartTime,
                            registredBreakEndTime,
                            editregistredEndTime
                        } = this.state;

                        const plannedEndTime = _.get(this.props.factSchedule, '[0].plannedEndTime', undefined);
                        const registredEndTimeField = _.get(this.props.factSchedule, '[0].registredEndTime', undefined);
                        const registredStartTimeField = _.get(
                            this.props.factSchedule,
                            '[0].registredStartTime',
                            undefined
                        );

                        return row.value === 'planned' ? (
                            <div className={Styles.timePickerPosition}>
                                {plannedEndTime ? plannedEndTime.slice(0, 5) : undefined}
                            </div>
                        ) : _.get(this.props.factSchedule, '[0].registredEndTime') && !editregistredEndTime ? (
                            <div>
                                {registredEndTimeField ? registredEndTimeField.slice(0, 5) : undefined}
                                <div>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={() => {
                                            this.setState({
                                                editregistredEndTime: true
                                            });
                                        }}
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <FormattedMessage id='edit' />
                                    </Button>
                                </div>
                            </div>
                        ) : !_.get(this.props.factSchedule, '[0].registredEndTime') || editregistredEndTime ? (
                            <div>
                                <div className={Styles.timePickerPosition}>
                                    <TimePicker
                                        allowClear={false}
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        format='HH:mm'
                                        getPopupContainer={trigger => trigger.parentNode}
                                        minuteStep={30}
                                        onChange={time => {
                                            this.setState({
                                                registredEndTime: time
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'employee_task_modal.deadline_time_placeholder'
                                        })}
                                        value={dayjs(registredEndTime)}
                                    />
                                </div>
                                <div className={Styles.actionBtn}>
                                    <Button
                                        disabled={
                                            !_.get(this.props.factSchedule, '[0].registredStartTime') ||
                                            dayjs(registredEndTime) < dayjs(registredStartTimeField, 'HH:mm:ss') ||
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={async () => {
                                            await fetchAPI(
                                                'PUT',
                                                'registred_hours',
                                                null,
                                                {
                                                    date: scheduleDate
                                                        ? scheduleDate.format('YYYY-MM-DD')
                                                        : dayjs().format('YYYY-MM-DD'),
                                                    employeeId: this.props.employeeId,
                                                    registredEndTime: registredEndTime
                                                        ? registredEndTime.format(HOURS_REGEX)
                                                        : dayjs().format(HOURS_REGEX),
                                                    presetEndTime:
                                                        _.get(this.props.factSchedule, '[0]presetEndTime') || null,
                                                    registredStartTime:
                                                        _.get(this.props.factSchedule, '[0]registredStartTime') || null,
                                                    registredBreakStartTime:
                                                        _.get(this.props.factSchedule, '[0]registredBreakStartTime') ||
                                                        null,
                                                    registredBreakEndTime:
                                                        _.get(this.props.factSchedule, '[0]registredBreakEndTime') ||
                                                        null
                                                },
                                                { handleErrorInternally: true }
                                            );
                                            await this.props.fetchFactSchedule(
                                                this.props.employeeId,
                                                scheduleDate
                                                    ? scheduleDate.format('YYYY-MM-DD')
                                                    : dayjs().format('YYYY-MM-DD')
                                            );
                                            this.setState({
                                                editregistredEndTime: false,
                                                presetEndTime: undefined,
                                                registredStartTime: undefined,
                                                registredEndTime: undefined,
                                                registredBreakStartTime: undefined,
                                                registredBreakEndTime: undefined
                                            });
                                        }}
                                        style={{
                                            marginLeft: 4,
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='submit' />
                                    </Button>
                                </div>
                            </div>
                        ) : null;
                    }
                },
                {
                    title: <FormattedMessage id='beginBreakHours' />,
                    align: 'center',
                    render: row => {
                        const {
                            scheduleDate,
                            presetEndTime,
                            registredStartTime,
                            registredEndTime,
                            registredBreakStartTime,
                            registredBreakEndTime,
                            editregistredBreakStartTime
                        } = this.state;

                        const registredBreakStartTimeField = _.get(
                            this.props.factSchedule,
                            '[0].registredBreakStartTime',
                            undefined
                        );

                        return row.value === 'planned' ? (
                            <div className={Styles.timePickerPosition}>
                                <FormattedMessage id='client-mrds-table.data_missing' />
                            </div>
                        ) : _.get(this.props.factSchedule, '[0].registredBreakStartTime') &&
                          !editregistredBreakStartTime ? (
                            <div>
                                {registredBreakStartTimeField ? registredBreakStartTimeField.slice(0, 5) : undefined}
                                <div>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={() => {
                                            this.setState({
                                                editregistredBreakStartTime: true
                                            });
                                        }}
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <FormattedMessage id='edit' />
                                    </Button>
                                </div>
                            </div>
                        ) : !_.get(this.props.factSchedule, '[0].registredBreakStartTime') ||
                          editregistredBreakStartTime ? (
                            <div>
                                <div className={Styles.timePickerPosition}>
                                    <TimePicker
                                        allowClear={false}
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        format='HH:mm'
                                        getPopupContainer={trigger => trigger.parentNode}
                                        minuteStep={30}
                                        onChange={time => {
                                            this.setState({
                                                registredBreakStartTime: time
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'employee_task_modal.deadline_time_placeholder'
                                        })}
                                        value={dayjs(registredBreakStartTime)}
                                    />
                                </div>
                                <div className={Styles.actionBtn}>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={async () => {
                                            await fetchAPI(
                                                'PUT',
                                                'registred_hours',
                                                null,
                                                {
                                                    date: scheduleDate
                                                        ? scheduleDate.format('YYYY-MM-DD')
                                                        : dayjs().format('YYYY-MM-DD'),
                                                    employeeId: this.props.employeeId,
                                                    registredBreakStartTime: registredBreakStartTime
                                                        ? registredBreakStartTime.format(HOURS_REGEX)
                                                        : dayjs().format(HOURS_REGEX),
                                                    presetEndTime:
                                                        _.get(this.props.factSchedule, '[0]presetEndTime') || null,
                                                    registredStartTime:
                                                        _.get(this.props.factSchedule, '[0]registredStartTime') || null,
                                                    registredEndTime:
                                                        _.get(this.props.factSchedule, '[0]registredEndTime') || null,
                                                    registredBreakEndTime:
                                                        _.get(this.props.factSchedule, '[0]registredBreakEndTime') ||
                                                        null
                                                },
                                                { handleErrorInternally: true }
                                            );
                                            await this.props.fetchFactSchedule(
                                                this.props.employeeId,
                                                scheduleDate
                                                    ? scheduleDate.format('YYYY-MM-DD')
                                                    : dayjs().format('YYYY-MM-DD')
                                            );
                                            this.setState({
                                                editregistredBreakStartTime: false,
                                                presetEndTime: undefined,
                                                registredStartTime: undefined,
                                                registredEndTime: undefined,
                                                registredBreakStartTime: undefined,
                                                registredBreakEndTime: undefined,
                                                editregistredEndTime: undefined
                                            });
                                        }}
                                        style={{
                                            marginLeft: 4,
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='submit' />
                                    </Button>
                                </div>
                            </div>
                        ) : null;
                    }
                },
                {
                    title: <FormattedMessage id='endBreakHours' />,
                    align: 'center',
                    render: row => {
                        const {
                            scheduleDate,
                            presetEndTime,
                            registredStartTime,
                            registredEndTime,
                            registredBreakStartTime,
                            registredBreakEndTime,
                            editregistredBreakEndTime
                        } = this.state;

                        const registredBreakEndTimeField = _.get(this.props.factSchedule, '[0].registredBreakEndTime');

                        return row.value === 'planned' ? (
                            <div className={Styles.timePickerPosition}>
                                <FormattedMessage id='client-mrds-table.data_missing' />
                            </div>
                        ) : _.get(this.props.factSchedule, '[0].registredBreakEndTime') &&
                          !editregistredBreakEndTime ? (
                            <div>
                                {registredBreakEndTimeField ? registredBreakEndTimeField.slice(0, 5) : undefined}
                                <div>
                                    <Button
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={() => {
                                            this.setState({
                                                editregistredBreakEndTime: true
                                            });
                                        }}
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <FormattedMessage id='edit' />
                                    </Button>
                                </div>
                            </div>
                        ) : !_.get(this.props.factSchedule, '[0].registredBreakEndTime') ||
                          editregistredBreakEndTime ? (
                            <div>
                                <div className={Styles.timePickerPosition}>
                                    <TimePicker
                                        allowClear={false}
                                        disabled={
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        format='HH:mm'
                                        getPopupContainer={trigger => trigger.parentNode}
                                        minuteStep={30}
                                        onChange={time => {
                                            this.setState({
                                                registredBreakEndTime: time
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'employee_task_modal.deadline_time_placeholder'
                                        })}
                                        value={dayjs(registredBreakEndTime)}
                                    />
                                </div>
                                <div className={Styles.actionBtn}>
                                    <Button
                                        disabled={
                                            !_.get(this.props.factSchedule, '[0].registredBreakStartTime', undefined) ||
                                            !isGrantAccessed(
                                                this.props.user,
                                                grants.DIRECTORIES_EMPLOYEE_CARD,
                                                accesses.ROWO
                                            )
                                        }
                                        onClick={async () => {
                                            await fetchAPI(
                                                'PUT',
                                                'registred_hours',
                                                null,
                                                {
                                                    date: scheduleDate
                                                        ? scheduleDate.format('YYYY-MM-DD')
                                                        : dayjs().format('YYYY-MM-DD'),
                                                    employeeId: this.props.employeeId,
                                                    registredBreakEndTime: registredBreakEndTime
                                                        ? registredBreakEndTime.format(HOURS_REGEX)
                                                        : dayjs().format(HOURS_REGEX),
                                                    presetEndTime:
                                                        _.get(this.props.factSchedule, '[0]presetEndTime') || null,
                                                    registredStartTime:
                                                        _.get(this.props.factSchedule, '[0]registredStartTime') || null,
                                                    registredEndTime:
                                                        _.get(this.props.factSchedule, '[0]registredEndTime') || null,
                                                    registredBreakStartTime:
                                                        _.get(this.props.factSchedule, '[0]registredBreakStartTime') ||
                                                        null
                                                },
                                                { handleErrorInternally: true }
                                            );

                                            await this.props.fetchFactSchedule(
                                                this.props.employeeId,
                                                scheduleDate
                                                    ? scheduleDate.format('YYYY-MM-DD')
                                                    : dayjs().format('YYYY-MM-DD')
                                            );
                                            this.setState({
                                                editregistredBreakEndTime: false,
                                                presetEndTime: undefined,
                                                registredStartTime: undefined,
                                                registredEndTime: undefined,
                                                registredBreakStartTime: undefined,
                                                registredBreakEndTime: undefined,
                                                editregistredEndTime: undefined
                                            });
                                        }}
                                        style={{
                                            marginLeft: 4,
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='submit' />
                                    </Button>
                                </div>
                            </div>
                        ) : null;
                    }
                },
                {
                    title: <FormattedMessage id='setting-salary.working_time' />,
                    align: 'center',
                    render: row => {
                        return row.value === 'planned' ? (
                            <div className={Styles.timePickerPosition}>
                                {_.get(this.props.factSchedule, '[0].plannedWorkingHours')}
                            </div>
                        ) : _.get(this.props.factSchedule, '[0].totalWorkingTime') ? (
                            <div>{_.get(this.props.factSchedule, '[0].totalWorkingTime')}</div>
                        ) : (
                            <div className={Styles.timePickerPosition}>
                                <FormattedMessage id='client-mrds-table.data_missing' />
                            </div>
                        );
                    }
                }
                // {
                //     title: <FormattedMessage id='diagnostic-page.action' />,
                //     key: 'action',
                //     render: row => {
                //         const {
                //             scheduleDate,
                //             presetEndTime,
                //             registredStartTime,
                //             registredEndTime,
                //             registredBreakStartTime,
                //             registredBreakEndTime
                //         } = this.state;

                //         return (
                //             <div>
                //                 <Button
                //                     disabled={row.value == 'planned'}
                //                     onClick={async () => {
                //                         await fetchAPI(
                //                             'PUT',
                //                             'registred_hours',
                //                             null,
                //                             {
                //                                 date: scheduleDate
                //                                     ? scheduleDate.format('YYYY-MM-DD')
                //                                     : dayjs().format('YYYY-MM-DD'),
                //                                 employeeId: this.props.employeeId,

                //                                 presetEndTime: presetEndTime
                //                                     ? presetEndTime.format(HOURS_REGEX)
                //                                     : dayjs().format(HOURS_REGEX),
                //                                 registredStartTime: registredStartTime
                //                                     ? registredStartTime.format(HOURS_REGEX)
                //                                     : dayjs().format(HOURS_REGEX),
                //                                 registredEndTime: registredEndTime
                //                                     ? registredEndTime.format(HOURS_REGEX)
                //                                     : dayjs().format(HOURS_REGEX),
                //                                 registredBreakStartTime: registredBreakStartTime
                //                                     ? registredBreakStartTime.format(HOURS_REGEX)
                //                                     : dayjs().format(HOURS_REGEX),
                //                                 registredBreakEndTime: registredBreakEndTime
                //                                     ? registredBreakEndTime.format(HOURS_REGEX)
                //                                     : dayjs().format(HOURS_REGEX)
                //                             },
                //                             { handleErrorInternally: true }
                //                         );
                //                     }}
                //                     style={{
                //                         marginRight: 8
                //                     }}
                //                     type='primary'
                //                 >
                //                     <FormattedMessage id='submit' />
                //                 </Button>
                //                 <Button disabled={row.value == 'planned'}>
                //                     <FormattedMessage id='edit' />
                //                 </Button>
                //             </div>
                //         );
                //     }
                // }
            ];
        };

        this.dataSource = () => {
            return [
                {
                    title: <FormattedMessage id='setting-salary.planned_time' />,
                    value: 'planned'
                },
                {
                    title: <FormattedMessage id='setting-salary.real_time' />,
                    value: 'real'
                }
            ];
        };
    }

    render() {
        const { ordersCount, labourHours, factSchedule } = this.props;

        const isGrantedAccess = !isGrantAccessed(this.props.user, grants.DIRECTORIES_EMPLOYEE_CARD, accesses.ROWO);

        return (
            <Catcher>
                <div>
                    <span className={Styles.title}>
                        <FormattedMessage id='employee-statistics.orders' />
                    </span>
                    <Numeral className={Styles.count}>{ordersCount}</Numeral>
                </div>
                <div>
                    <span className={Styles.title}>
                        <FormattedMessage id='employee-statistics.labour_hours' />
                    </span>
                    <Numeral className={Styles.count}>{Math.round(labourHours)}</Numeral>
                </div>

                {!_.get(factSchedule, 'length') ? (
                    <div>
                        {notification.error({
                            message: this.props.intl.formatMessage({
                                id: 'setting-salary.no_fact_schedule'
                            }),
                            duration: 6
                        })}
                    </div>
                ) : (
                    <div
                        style={{
                            marginTop: 22
                        }}
                    >
                        <span
                            style={{
                                fontSize: 24,
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='setting-salary.fact_schedule' />
                        </span>
                        <Table columns={this.columns()} dataSource={this.dataSource()} pagination={false} />
                    </div>
                )}
            </Catcher>
        );
    }
}
