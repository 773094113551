import { Table } from 'antd';
import {
    fetchStoreDocsBySupplier,
    selectStoreDocsBySupplier,
    selectStoreDocsBySupplierFilters,
    selectStoreDocsBySupplierLoading,
    selectStoreDocsBySupplierStats,
    setStoreDocsBySupplierFilters
} from 'core/forms/cashOrderForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    storeDocs: selectStoreDocsBySupplier(state),
    stats: selectStoreDocsBySupplierStats(state),
    filters: selectStoreDocsBySupplierFilters(state),
    loading: selectStoreDocsBySupplierLoading(state)
});

const mapDispatchToProps = {
    fetchStoreDocsBySupplier,
    setStoreDocsBySupplierFilters
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export class StoreDocsBySupplierTable extends Component {
    constructor(props) {
        super(props);

        this.columns = columnsConfig({
            formatMessage: props.intl.formatMessage
        });
    }

    _onRowClick = row => {
        this.props.selectStoreDoc(row);
    };

    _setPage = page => {
        this.props.setStoreDocsBySupplierFilters({ page });
    };

    render() {
        const {
            storeDocs,
            stats,
            filters,
            loading,

            intl: { formatMessage }
        } = this.props;

        const columns = columnsConfig({
            formatMessage
        });

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(_.get(stats, 'count') / 25) * 25,
            hideOnSinglePage: true,
            current: filters.page,
            onChange: page => this._setPage(page)
        };

        return (
            <Table
                bordered
                columns={columns}
                dataSource={storeDocs}
                loading={loading}
                locale={{
                    emptyText: <FormattedMessage id='no_data' />
                }}
                onRow={row => ({
                    onClick: () => this._onRowClick(row)
                })}
                pagination={pagination}
                rowClassName={() => Styles.linkRow}
                scroll={{ x: 720 }}
                size='small'
            />
        );
    }
}
