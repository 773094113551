import { CheckSquareOutlined, CloseSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const NOT_AVAILABLE = 'NOT_AVAILABLE';
const OPEN = 'OPEN';
const CLOSED = 'CLOSED';

/**
 * Creates Styled icon for cashbox with rst(red or green icon) depending on cash box state(opened or closed).
 * It also has popup with translated hints.
 *
 * @property { Boolean } isShiftOpen - Cashbox shift can be opened or closed
 * @property { String } onlineCashdeskState - Online Cashdesk state can be NOT_AVAILABLE, OPEN or CLOSED
 */
@injectIntl
export default class CashboxRSTIcon extends React.Component {
    render() {
        const { isShiftOpen, onlineCashdeskState } = this.props;

        const isOpen = onlineCashdeskState ? onlineCashdeskState === OPEN : isShiftOpen;
        const isClosed = onlineCashdeskState ? onlineCashdeskState === CLOSED : isShiftOpen;
        const isNotAvailable = onlineCashdeskState === NOT_AVAILABLE;

        const openIcon = (
            <CheckSquareOutlined
                className={[Styles.cashboxStatusIcon, Styles.openCashboxIcon].join(', ')}
            />
        );
        const closeIcon = (
            <CloseSquareOutlined
                className={[Styles.cashboxStatusIcon, Styles.closedCashboxIcon].join(', ')}
            />
        );
        const unavailableIcon = (
            <MinusSquareOutlined
                className={[Styles.cashboxStatusIcon, Styles.notAvailableCashboxIcon].join(', ')}
            />
        );

        return (
            <Tooltip
                overlayClassName={Styles.tooltip}
                title={
                    <FormattedMessage
                        id={
                            isOpen
                                ? 'cashbox_rst_icon.hint_open_cash_box_with_rst'
                                : isClosed
                                ? 'cashbox_rst_icon.hint_closed_cash_box_with_rst'
                                : 'cashbox_rst_icon.hint_not_available_cash_box_with_rst'
                        }
                    />
                }
            >
                {isOpen ? openIcon : isClosed ? closeIcon : unavailableIcon}
            </Tooltip>
        );
    }
}
