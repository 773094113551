import { Modal, Table } from 'antd';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

@injectIntl
export default class AddRegulationsOrderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            selectedRowKeys: []
        };

        this.columns = (withTitle = true, titleForEachTable) => [
            {
                title: titleForEachTable,
                key: 'name',
                dataIndex: 'regulationName',
                width: '25%',
                render: (data, row) => {
                    return (
                        <div
                            className={
                                row.color == 'GREEN'
                                    ? Styles.underlineStatusColorGreen
                                    : row.color == 'YELLOW'
                                    ? Styles.underlineStatusColorYellow
                                    : row.color == 'RED'
                                    ? Styles.underlineStatusColorRed
                                    : undefined
                            }
                        >
                            {data}
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.regulation' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.months' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'typeRegulationMonth',
                dataIndex: 'months',
                align: 'center',
                width: '10%'
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.regulation' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'typeRegulationKm',
                dataIndex: 'km',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.last_change' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'lastChangeKm',
                dataIndex: 'lastKm',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.last_change' />
                            </div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'lastChangeDate',
                dataIndex: 'lastDate',
                align: 'center',
                width: '10%',
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY') : undefined;
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.next_change' />
                            </div>
                            <div>
                                <FormattedMessage id='regulations.km' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'nextChangeKm',
                dataIndex: 'nextChangeKm',
                align: 'center',
                width: '10%',
                render: data => {
                    return (
                        <div>
                            <Numeral mask='0,0'>{Number(data)}</Numeral>
                        </div>
                    );
                }
            },
            {
                title: () =>
                    withTitle ? (
                        <div>
                            <div className={Styles.titleStyle}>
                                <FormattedMessage id='regulations.next_change' />
                            </div>
                            <div>
                                <FormattedMessage id='date' />
                            </div>
                        </div>
                    ) : undefined,
                key: 'nextChangeDate',
                dataIndex: 'nextChangeDate',
                align: 'center',
                width: '10%',
                render: data => {
                    return data ? dayjs(data).format('DD.MM.YYYY') : undefined;
                }
            }
        ];
    }

    handleOk = async () => {
        await this.addRegulationRowsToOrder();

        await this.handleCancel();

        setTimeout(() => this.props.fetchVehicleOrderRegulations(), 500);
    };

    handleCancel() {
        this.setState({
            selectedRows: [],
            selectedRowKeys: []
        });
        this.props.hideModal();
    }

    addRegulationRowsToOrder = async () => {
        const { selectedRows } = this.state;
        const { orderId, vehicleId, allVehicleRegulations } = this.props;

        const regulations = [];

        selectedRows.map(elem => {
            regulations.push({
                orderId: Number(orderId),
                vehicleId,
                id: elem.id,
                groupId: elem.groupId,
                regulationName: elem.regulationName,
                regulationKm: elem.km,
                regulationMonths: elem.months,
                lastKm: elem.lastKm || 1,
                lastDate: elem.lastDate || '01-01-2023',
                laborId: elem.laborId,
                laborGroupId: elem.laborGroupId,
                visibility: true
            });
        });

        await fetchAPI('POST', 'orders/vehicle/regulations', undefined, {
            regulations
        });
    };

    render() {
        const { detailsTreeData, allVehicleRegulations } = this.props;
        const { selectedRowKeys, selectedRows } = this.state;

        const rowSelection = (hideSelectAll = true) => ({
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            hideSelectAll,
            onSelectAll: (selected, selectedRows, changeRows) => {
                if (selected) {
                    const allData = allVehicleRegulations.map(({ regulations }) => regulations).flat();

                    const selectedRowKeys = allData.map(({ id }) => id);

                    this.setState({
                        selectedRowKeys,
                        selectedRows: allData
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: [],
                    selectedRows: []
                });
            },
            onSelect: (record, selected) => {
                if (selected) {
                    this.setState({
                        selectedRowKeys: [...selectedRows, record].map(({ id }) => id),
                        selectedRows: [...selectedRows, record]
                    });

                    return;
                }

                this.setState({
                    selectedRowKeys: selectedRowKeys.filter(id => id !== record.id),
                    selectedRows: selectedRows.filter(({ id }) => id !== record.id)
                });
            }
        });

        return (
            <Modal
                destroyOnClose
                maskClosable={false}
                okButtonProps={{
                    disabled: !_.get(allVehicleRegulations, 'length')
                }}
                onCancel={() => {
                    this.handleCancel();
                }}
                onOk={() => {
                    this.handleOk();
                }}
                title={<FormattedMessage id='regulations.add_rows_to_order' />}
                visible={this.props.visible}
                width='80%'
                zIndex={200}
            >
                {_.get(allVehicleRegulations, 'length') ? (
                    <div>
                        {allVehicleRegulations.map(({ groupId, groupName, regulations }, index) => (
                            <Table
                                key={groupId}
                                bordered
                                columns={this.columns(!index, groupName)}
                                dataSource={regulations}
                                pagination={false}
                                rowKey='id'
                                rowSelection={rowSelection(!!index)}
                                size='small'
                            />
                        ))}
                    </div>
                ) : (
                    <Table dataSource={[]} pagination={false} />
                )}
            </Modal>
        );
    }
}
