import { Form } from '@ant-design/compatible';
import { Col, Row, Select } from 'antd';
import { fetchUniversalFiltersForm } from 'core/forms/universalFiltersForm/duck';
import { DecoratedDatePicker, DecoratedInputNumber, DecoratedSelect } from 'forms/DecoratedFields';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDaterange } from 'utils';
import { v4 } from 'uuid';

const FormItem = Form.Item;
const { Option } = Select;

@injectIntl
@connect(null, { fetchUniversalFiltersForm })
export class UniversalFiltersForm extends Component {
    componentDidMount() {
        this.props.fetchUniversalFiltersForm();
    }

    render() {
        const {
            vehicleMakes,
            vehicleModels,
            managers,
            employees,
            services,
            handleUniversalFiltersModalSubmit
        } = this.props;

        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { formatMessage } = this.props.intl;

        const makeId = getFieldValue('make');
        const vehiclesYears = Array(new Date().getFullYear() - 1900 + 1)
            .fill(1900)
            .map((val, index) => val + index)
            .reverse();

        return (
            <Form layout='vertical' onSubmit={() => handleUniversalFiltersModalSubmit()}>
                <Row gutter={8}>
                    <Col span={12}>
                        <FormItem
                            label={<FormattedMessage id='universal_filters_form.enrollment_date' />}
                        >
                            <DecoratedDatePicker
                                field='beginDate'
                                format='YYYY-MM-DD HH:mm'
                                formatMessage={formatMessage}
                                getCalendarContainer={trigger => trigger.parentNode}
                                getFieldDecorator={getFieldDecorator}
                                // showTime
                                ranges={{
                                    // this day
                                    [formatMessage({
                                        id: 'datepicker.today'
                                    })]: getDaterange('today', 'ant'),
                                    // prev month
                                    [formatMessage({
                                        id: 'datepicker.prev_month'
                                    })]: getDaterange('prevMonth', 'ant'),
                                    // prev year
                                    [formatMessage({
                                        id: 'datepicker.prev_year'
                                    })]: getDaterange('prevYear', 'ant')
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={<FormattedMessage id='universal_filters_form.create_date' />}
                        >
                            <DecoratedDatePicker
                                field='createDate'
                                format='YYYY-MM-DD HH:mm:ss'
                                formatMessage={formatMessage}
                                getCalendarContainer={trigger => trigger.parentNode}
                                getFieldDecorator={getFieldDecorator}
                                // showTime
                                ranges={{
                                    // this day
                                    [formatMessage({
                                        id: 'datepicker.today'
                                    })]: getDaterange('today', 'ant'),
                                    // prev month
                                    [formatMessage({
                                        id: 'datepicker.prev_month'
                                    })]: getDaterange('prevMonth', 'ant'),
                                    // prev year
                                    [formatMessage({
                                        id: 'datepicker.prev_year'
                                    })]: getDaterange('prevYear', 'ant')
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <FormItem label={<FormattedMessage id='universal_filters_form.make' />}>
                            <DecoratedSelect
                                field='make'
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                // style={ { width: 200 } }
                                optionLabel='makeName'
                                // optionFilterProp='children'
                                options={vehicleMakes}
                                optionValue='makeId'
                                placeholder={<FormattedMessage id='universal_filters_form.all' />}
                                showSearch
                            />
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={<FormattedMessage id='universal_filters_form.models' />}>
                            <DecoratedSelect
                                disabled={!makeId}
                                field='models'
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                mode='multiple'
                                // style={ { width: 200 } }
                                optionLabel='name'
                                // optionFilterProp='children'
                                options={
                                    makeId
                                        ? vehicleModels.filter(
                                              ({ makeId: modelMakeId }) => makeId === modelMakeId
                                          )
                                        : vehicleModels
                                }
                                optionValue='id'
                                placeholder={<FormattedMessage id='universal_filters_form.all' />}
                                showSearch
                            />
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={<FormattedMessage id='universal_filters_form.year' />}>
                            <DecoratedSelect
                                field='year'
                                getFieldDecorator={getFieldDecorator}
                                // style={ { width: 200 } }
                                getPopupContainer={trigger => trigger.parentNode}
                                // optionFilterProp='children'
                                placeholder={<FormattedMessage id='universal_filters_form.year' />}
                            >
                                {vehiclesYears
                                    .sort((a, b) => b - a)
                                    .map(year => (
                                        <Option key={v4()} value={year}>
                                            {String(year)}
                                        </Option>
                                    ))}
                            </DecoratedSelect>
                        </FormItem>
                    </Col>
                    <Col span={3}>
                        <FormItem
                            label={<FormattedMessage id='universal_filters_form.mileage_from' />}
                        >
                            <DecoratedInputNumber
                                field='odometerGreater'
                                fields={{}}
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                // style={ { width: 200 } }
                                optionLabel='serviceName'
                                // optionFilterProp='children'
                                options={services}
                                optionValue='id'
                                placeholder={formatMessage({
                                    id: 'universal_filters_form.mileage_from'
                                })}
                                showSearch
                            />
                        </FormItem>
                    </Col>
                    <Col span={3}>
                        <FormItem
                            label={<FormattedMessage id='universal_filters_form.mileage_to' />}
                        >
                            <DecoratedInputNumber
                                field='odometerLower'
                                fields={{}}
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                placeholder={formatMessage({
                                    id: 'universal_filters_form.mileage_to'
                                })}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <FormItem label={<FormattedMessage id='universal_filters_form.service' />}>
                            <DecoratedSelect
                                field='service'
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                // style={ { width: 200 } }
                                optionLabel='serviceName'
                                // optionFilterProp='children'
                                options={services}
                                optionValue='id'
                                placeholder={<FormattedMessage id='universal_filters_form.all' />}
                                showSearch
                            />
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={<FormattedMessage id='universal_filters_form.managers' />}>
                            <DecoratedSelect
                                field='managers'
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                mode='multiple'
                                // style={ { width: 200 } }
                                placeholder={<FormattedMessage id='universal_filters_form.all' />}
                                // optionFilterProp='children'
                                showSearch
                            >
                                {managers.map(({ id, managerName, managerSurname }, key) => {
                                    return (
                                        <Option key={key} value={id}>
                                            {managerSurname} {managerName}
                                        </Option>
                                    );
                                })}
                            </DecoratedSelect>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            label={<FormattedMessage id='universal_filters_form.served_employee' />}
                        >
                            <DecoratedSelect
                                field='employee'
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                // style={ { width: 200 } }
                                placeholder={<FormattedMessage id='universal_filters_form.all' />}
                                // optionFilterProp='children'
                                showSearch
                            >
                                {employees.map(({ id, name, surname }, key) => {
                                    return (
                                        <Option key={key} value={id}>
                                            {name} {surname}
                                        </Option>
                                    );
                                })}
                            </DecoratedSelect>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={6}>
                        <FormItem
                            label={
                                <FormattedMessage id='universal_filters_form.number_of_visits_from' />
                            }
                        >
                            <DecoratedInputNumber
                                field='ordersGreater'
                                fields={{}}
                                getFieldDecorator={getFieldDecorator}
                                // style={ { width: 200 } }
                                getPopupContainer={trigger => trigger.parentNode}
                                // optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'universal_filters_form.number_of_visits_from'
                                })}
                            />
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            label={
                                <FormattedMessage id='universal_filters_form.number_of_visits_to' />
                            }
                        >
                            <DecoratedInputNumber
                                field='ordersLower'
                                fields={{}}
                                getFieldDecorator={getFieldDecorator}
                                // style={ { width: 200 } }
                                getPopupContainer={trigger => trigger.parentNode}
                                // optionFilterProp='children'
                                placeholder={formatMessage({
                                    id: 'universal_filters_form.number_of_visits_to'
                                })}
                            />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <FormItem label={<FormattedMessage id='universal_filters_form.visited' />}>
                            <DecoratedSelect
                                field='notVisit'
                                getFieldDecorator={getFieldDecorator}
                                // style={ { width: 200 } }
                                getPopupContainer={trigger => trigger.parentNode}
                                // optionFilterProp='children'
                                placeholder={
                                    <FormattedMessage id='universal_filters_form.not_selected' />
                                }
                            >
                                <Option
                                    key='visited'
                                    name={<FormattedMessage id='universal_filters_form.visited' />}
                                    value={false}
                                >
                                    <FormattedMessage id='universal_filters_form.visited' />
                                </Option>
                                <Option
                                    key='not_visited'
                                    name={
                                        <FormattedMessage id='universal_filters_form.not_visited' />
                                    }
                                    value
                                >
                                    <FormattedMessage id='universal_filters_form.not_visited' />
                                </Option>
                            </DecoratedSelect>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <FormItem label={<FormattedMessage id='universal_filters_form.last' />}>
                            <DecoratedInputNumber
                                field='notVisitDays'
                                fields={{}}
                                getFieldDecorator={getFieldDecorator}
                                getPopupContainer={trigger => trigger.parentNode}
                                // style={ { width: 200 } }
                                placeholder={formatMessage({
                                    id: 'universal_filters_form.last'
                                })}
                                // optionFilterProp='children'
                                showSearch
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    }
}
