import { mainModuleName } from 'modals/RefactoredCashOrderModal/redux/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'employees';
const prefix = `cpb/refactoredCashOrderModal/${moduleName}`;

export const FETCH_EMPLOYEES = `${prefix}/FETCH_EMPLOYEES`;
export const FETCH_EMPLOYEES_SUCCESS = `${prefix}/FETCH_EMPLOYEES_SUCCESS`;
export const SET_FETCHING_EMPLOYEES = `${prefix}/SET_FETCHING_EMPLOYEES`;

export const SET_SELECTED_EMPLOYEE_ID = `${prefix}/SET_SELECTED_EMPLOYEE_ID`;

export const CLEAR_EMPLOYEES_STATE = `${prefix}/CLEAR_EMPLOYEES_STATE`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    employees: [],
    fetchingEmployees: false,
    selectedEmployeeId: undefined
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_EMPLOYEES_SUCCESS:
            return {
                ...state,
                ...payload
            };
        case SET_FETCHING_EMPLOYEES:
            return {
                ...state,
                fetchingEmployees: payload
            };
        case SET_SELECTED_EMPLOYEE_ID:
            return {
                ...state,
                selectedEmployeeId: payload
            };
        case CLEAR_EMPLOYEES_STATE:
            return ReducerState;

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectEmployees = state => state[mainModuleName][moduleName].employees;
export const selectFetchingEmployees = state => state[mainModuleName][moduleName].fetchingEmployees;

export const selectSelectedEmployeeId = state =>
    state[mainModuleName][moduleName].selectedEmployeeId;

/* ------------------------------------- Action Creators -------------------------- */
export const fetchEmployees = () => ({
    type: FETCH_EMPLOYEES
});

/*
 * @param {*} fetchedData.employees
 * @param {*} fetchedData.employeesStats
 */
export const fetchEmployeesSuccess = fetchedData => ({
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: fetchedData
});

export const setFetchingEmployees = value => ({
    type: SET_FETCHING_EMPLOYEES,
    payload: value
});

export const setSelectedEmployeeId = value => ({
    type: SET_SELECTED_EMPLOYEE_ID,
    payload: value
});

export const clearEmployeesState = () => ({
    type: CLEAR_EMPLOYEES_STATE
});
