import { Form } from '@ant-design/compatible';
import { Button, Col, Modal, Row, Select, Switch } from 'antd';
import { DateRangePicker } from 'components';
import { fetchBrands, selectBrands } from 'core/brands/duck';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PayOutSalaryModal from '../PayOutSalaryModal';

import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    brands: selectBrands(state)
});

const mapDispatchToProps = {
    fetchBrands
};

const { Option } = Select;
const dateFormat = 'DD.MM.YYYY';

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CalcSalaryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: undefined,
            endDate: undefined,
            loading: false
        };
    }

    componentDidUpdate(prevProps) {
        const { calculatedSalary, onlyForCurrentEmployee, empId } = this.props;
        const { employeeId } = this.state;
        if (!prevProps.visible && this.props.visible) {
            if (onlyForCurrentEmployee) {
                this.setState({
                    employeeId: Number(empId)
                });
            }
        }
    }

    onOk = async () => {
        const { calcSalary, calcSalaryByAllEmps, allEmployees } = this.props;
        const { employeeId, startDate, endDate, allEmps, loading } = this.state;

        this.setState({
            loading: true
        });

        const allEmpIds = allEmployees.filter(({ disabled }) => !disabled).map(({ id }) => id);

        if (employeeId && !allEmps) {
            await calcSalary([employeeId], startDate, endDate);
        } else {
            await calcSalary(allEmpIds, startDate, endDate);
        }

        this.onCancel();
    };

    // calcSalary = async (id, sDate, eDate) => {
    //     const calculatedSalary = await fetchAPI(
    //         'POST',
    //         '/recalculate/employee/salary',
    //         undefined,
    //         {
    //             employeeId: id,
    //             startDate: sDate,
    //             endDate: eDate,
    //             status: 'ACCRUED'
    //         },
    //         { handleErrorInternally: true }
    //     );
    //     this.setState({
    //         calculatedSalary
    //     });
    // };

    onCancel = async () => {
        const { hideModal, fetchSalaryDocData } = this.props;

        await this.setState({
            loading: false,
            employeeId: undefined,
            startDate: undefined,
            endDate: undefined
        });

        if (fetchSalaryDocData) {
            fetchSalaryDocData();
        }

        hideModal();
    };

    render() {
        const {
            visible,
            intl: { formatMessage },
            allEmployees,
            onlyForCurrentEmployee,
            empId
        } = this.props;

        const { employeeId, startDate, endDate, calculatedSalary, visiblePayOutSalaryModal, allEmps, loading } =
            this.state;

        return (
            <div>
                <Modal
                    maskClosable={false}
                    
                    title={
                        <React.Fragment>
                            <FormattedMessage id='setting-salary.to_calc_salary' />
                        </React.Fragment>
                    }
                    visible={visible}
                    width='fit-content'
                    onCancel={this.onCancel}
                    // onOk={this.onOk}
                    footer={
                        <div>
                            <Button 
                                onClick={() => {
                                    this.onCancel()
                                    }} 
                                key='back' 
                            >
                                <FormattedMessage id='stock_table.button.cancel' />
                            </Button>
                            <Button 
                                disabled={!startDate}  
                                loading={loading} 
                                onClick={async() => {
                                    await this.onOk()
                                }} 
                                key='submit'  
                                type='primary'
                            >
                                <FormattedMessage id='ok'/>
                            </Button>
                        </div>
                        
                    }
                >
                    <Form>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='employee' />
                                </div>
                            </Col>
                            <Col span={12}>
                                {!onlyForCurrentEmployee ? (
                                    <Select
                                        allowClear
                                        disabled={allEmps}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => {
                                            this.setState({
                                                employeeId: value
                                            });
                                        }}
                                        optionFilterProp='children'
                                        // mode='multiple'
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 8, display: 'block', width: '100%' }}
                                        value={employeeId}
                                    >
                                        {allEmployees
                                            .filter(({ disabled }) => !disabled)
                                            .map(({ id, name, surname }) => (
                                                <Option key={id} value={id}>
                                                    {`${name} ${surname}`}
                                                </Option>
                                            ))}
                                    </Select>
                                ) : (
                                    <Select
                                        allowClear
                                        disabled
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => {
                                            this.setState({
                                                employeeId: value
                                            });
                                        }}
                                        optionFilterProp='children'
                                        // mode='multiple'
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 8, display: 'block', width: '100%' }}
                                        value={employeeId}
                                    >
                                        {allEmployees
                                            .filter(({ id }) => id === Number(empId))
                                            .map(({ id, name, surname }) => (
                                                <Option key={id} value={id}>
                                                    {`${name} ${surname}`}
                                                </Option>
                                            ))}
                                    </Select>
                                )}
                            </Col>
                            <Col span={6}></Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='date' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <DateRangePicker
                                    allowClear
                                    dateRange={[
                                        startDate ? dayjs(startDate) : undefined,
                                        endDate ? dayjs(endDate) : undefined
                                    ]}
                                    onDateChange={async dateRange => {
                                        await this.setState({
                                            startDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                                            endDate: dayjs(dateRange[1]).format('YYYY-MM-DD')
                                        });
                                    }}
                                    popupStyle={{
                                        maxHeight: 250,
                                        overflow: 'auto',
                                        zIndex: '9999',
                                        maxWidth: 150
                                    }}
                                    type='default'
                                />
                            </Col>
                        </Row>
                    </Form>
                    {!onlyForCurrentEmployee && (
                        <div className={Styles.allEmpsBtn}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='setting-salary.to_calc_salary_by_all_emps' />
                            </div>

                            <div>
                                <Switch
                                    checked={allEmps}
                                    onChange={() => {
                                        this.setState({
                                            allEmps: !allEmps,
                                            employeeId: undefined
                                        });
                                        if (allEmps) {
                                            this.setState({
                                                employeeId: undefined
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </Modal>
                <PayOutSalaryModal
                    allEmployees={allEmployees}
                    calculatedSalary={calculatedSalary}
                    closeAllModals={this.onCancel}
                    employeeId={employeeId}
                    endDate={endDate}
                    hideModal={() => {
                        this.setState({
                            visiblePayOutSalaryModal: false
                        });
                    }}
                    startDate={startDate}
                    visible={visiblePayOutSalaryModal}
                />
            </div>
        );
    }
}
