exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__blockStyle---3uzPj,.styles-m__blockStyleMobile---1YHms{display:flex;justify-content:space-between}.styles-m__blockStyleMobile---1YHms{margin-top:54px;margin-right:54px;width:100%;background:#f5f5f5;position:absolute;left:0;top:42px}.styles-m__blockStyleMobileNone---1pHgo{display:none}.styles-m__mobileNewLine---3dqne{display:block}.styles-m__fixedMenuButton---3LGXv{position:fixed;top:54px;right:10px;z-index:1000;padding:5px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAKA,kEAJC,YAAa,CACb,6BAaD,CAVA,oCAGC,eAAgB,CAChB,iBAAkB,CAClB,UAAW,CACX,kBAA8B,CAC9B,iBAAkB,CAClB,MAAS,CACT,QACD,CAEA,wCACC,YACD,CAEA,iCACC,aACD,CAEA,mCACC,cAAe,CACf,QAAS,CACT,UAAW,CACX,YAAa,CACb,WACD","file":"styles.m.css","sourcesContent":[".blockStyle {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.blockStyleMobile {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin-top: 54px;\n\tmargin-right: 54px;\n\twidth: 100%;\n\tbackground: rgb(245, 245, 245);\n\tposition: absolute;\n\tleft: 0px;\n\ttop: 42px;\n}\n\n.blockStyleMobileNone {\n\tdisplay: none;\n}\n\n.mobileNewLine {\n\tdisplay: block;\n}\n\n.fixedMenuButton {\n\tposition: fixed;\n\ttop: 54px;\n\tright: 10px;\n\tz-index: 1000;\n\tpadding: 5px;\n}"]}]);

// Exports
exports.locals = {
	"blockStyle": "styles-m__blockStyle---3uzPj",
	"blockStyleMobile": "styles-m__blockStyleMobile---1YHms",
	"blockStyleMobileNone": "styles-m__blockStyleMobileNone---1pHgo",
	"mobileNewLine": "styles-m__mobileNewLine---3dqne",
	"fixedMenuButton": "styles-m__fixedMenuButton---3LGXv"
};