import { push } from 'connected-react-router';
import { authenticate, selectToken } from 'core/auth/duck';
import { refreshDashboard } from 'core/dashboard/duck';
import { MODALS, SET_MODAL } from 'core/modals/duck';
import { emitError, setSearchBusinessesFetchingState } from 'core/ui/duck';
import { all, call, delay, put, putResolve, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import {
    SET_BUSINESS,
    SET_BUSINESS_TRANSFER,
    SET_ORDER_ID_TRANSFER,
    SET_SEARCH_QUERY,
    SET_SEARCH_QUERY_BY_ID,
    fetchBusinessesSuccess
} from './duck';

function* handleBusinessesSearchSaga({ payload: { query } }) {
    yield delay(1000);

    if (query && query.length > 2) {
        yield put(setSearchBusinessesFetchingState(true));

        const businesses = yield call(
            fetchAPI,
            'GET',
            'businesses/search',
            {
                search: query
            },
            null,
            { handleErrorInternally: true }
        );
        yield put(fetchBusinessesSuccess(businesses));
        yield put(setSearchBusinessesFetchingState(false));
    }
}

function* handleBusinessesSearcByIdhSaga({ payload: { query } }) {
    yield delay(1000);

    if (query) {
        yield put(setSearchBusinessesFetchingState(true));

        const businesses = yield call(
            fetchAPI,
            'GET',
            'businesses/search',
            {
                searchById: query
            },
            null,
            { handleErrorInternally: true }
        );
        yield put(fetchBusinessesSuccess(businesses));
        yield put(setSearchBusinessesFetchingState(false));
    }
}

function* onSetModalSaga({ payload }) {
    if (payload === MODALS.SWITCH_BUSINESS) {
        yield put(setSearchBusinessesFetchingState(true));

        const businesses = yield call(fetchAPI, 'GET', 'businesses/search');
        yield put(fetchBusinessesSuccess(businesses));
        yield put(setSearchBusinessesFetchingState(false));
    }
}

function* setBusinessSaga() {
    while (true) {
        try {
            const { payload: businessId } = yield take(SET_BUSINESS);

            const user = yield call(fetchAPI, 'POST', 'managers/businesses/set', void 0, {
                businessId
            });
            const token = yield select(selectToken);
            yield putResolve(authenticate({ ...user, token }));
            const location = yield select(state => state.router.location.pathname);
            yield put(push(book.dashboard));

            if (location === book.dashboard) {
                yield put(refreshDashboard());
            }
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

function* setBusinessTransferSaga() {
    while (true) {
        try {
            const { payload: businessId } = yield take(SET_BUSINESS_TRANSFER);

            const user = yield call(fetchAPI, 'POST', 'managers/businesses/set', void 0, {
                businessId
            });
            const token = yield select(selectToken);
            yield putResolve(authenticate({ ...user, token }));
            const location = yield select(state => state.router.location.pathname);
            const { payload: orderId } = yield take(SET_ORDER_ID_TRANSFER);
            if (Number(orderId)) {
                yield put(push(`${book.order}/${orderId}`));
            }
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    /* eslint-disable array-element-newline */
    yield all([
        takeLatest(SET_SEARCH_QUERY, handleBusinessesSearchSaga),
        takeLatest(SET_SEARCH_QUERY_BY_ID, handleBusinessesSearcByIdhSaga),
        takeEvery(SET_MODAL, onSetModalSaga),
        call(setBusinessSaga),
        call(setBusinessTransferSaga)
    ]);
    /* eslint-enable array-element-newline */
}
