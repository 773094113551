import { Layout, ResponsiveView } from 'commons';
import { BrandSelect, DateRangePicker, TrackingTable, WarehouseSelect } from 'components';
import {
    selectTrackingFilters,
    selectTrackingLoading,
    setTrackingFilters
} from 'core/storage/tracking';
import dayjs from 'dayjs';
import { StoreProductsSelect } from 'forms/_formkit';
import { StockProductsModal, StoreProductModal } from 'modals';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { media } from 'theme/media';
import { BREAKPOINTS } from 'utils';

const getWarehouses = () => {
    const that = this;
    const token = localStorage.getItem('_my.carbook.pro_token');
    const url = `${__API_URL__}/warehouses`;
    fetch(url, {
        method: 'GET',
        headers: {
            Authorization: token
        }
    })
        .then(function (response) {
            if (response.status !== 200) {
                return Promise.reject(new Error(response.statusText));
            }

            return Promise.resolve(response);
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            that.setState({
                warehouses: data
            });
        })
        .catch(function (error) {
            console.log('error', error);
        });
};

const mapStateToProps = state => ({
    collapsed: state.ui.collapsed,
    filters: selectTrackingFilters(state),
    loading: selectTrackingLoading(state),
    user: state.auth
});

export const ProductsTrackingPage = withRouter(
    connect(mapStateToProps, { setTrackingFilters })(props => {
        const type = props.type || 'tracking';
        const { filters } = props;
        const [period, setPeriod] = useState('month');

        const setDaterange = daterange => {
            const [startDate, endDate] = daterange;
            props.setTrackingFilters({ startDate, endDate });
        };

        const handlePeriod = period => {
            setPeriod(period);
            period === 'month'
                ? setDaterange([
                      dayjs(filters.endDate).subtract(30, 'days'),
                      dayjs(filters.endDate)
                  ])
                : setDaterange([
                      dayjs(filters.endDate).subtract(1, period),
                      dayjs(filters.endDate)
                  ]);
        };

        const renderFilters = (
            <Filters>
                <ResponsiveView view={{ min: BREAKPOINTS.xxl.min, max: null }}>
                    <DateRangePicker
                        dateRange={[dayjs(filters.startDate), dayjs(filters.endDate)]}
                        minimize
                        onDateChange={setDaterange}
                        style={{ margin: '0 8px 0 0' }}
                    />
                    <BrandSelect
                        onSelect={brandId => props.setTrackingFilters({ brandId })}
                        style={{ margin: '0 8px 0 8px' }}
                    />
                    <WarehouseSelect
                        onChange={warehouseId => props.setTrackingFilters({ warehouseId })}
                        style={{ margin: '0 8px 0 8px' }}
                    />
                    <StoreProductsSelect
                        filters={props.filters}
                        setFilters={props.setTrackingFilters}
                    />
                    <div style={{ margin: '0 0 0 8px' }}>
                        <StockProductsModal
                            selectProduct={productId => {
                                props.setTrackingFilters({
                                    productId,
                                    page: 1
                                });
                            }}
                            stockMode
                            user={props.user}
                        />
                    </div>
                </ResponsiveView>
            </Filters>
        );

        return (
            <Layout
                controls={renderFilters}
                paper={false}
                title={<FormattedMessage id={`navigation.products_${type}`} />}
            >
                <ResponsiveView view={{ min: null, max: BREAKPOINTS.xl.max }}>
                    <FiltersSubPanel collapsed={props.collapsed}>
                        <DateRangePicker
                            dateRange={[dayjs(filters.startDate), dayjs(filters.endDate)]}
                            minimize
                            onDateChange={setDaterange}
                            style={{ margin: '0 8px 0 0' }}
                        />
                        <BrandSelect
                            onSelect={brandId => props.setTrackingFilters({ brandId })}
                            style={{ margin: '0 8px 0 8px' }}
                        />
                        <WarehouseSelect
                            onChange={warehouseId => props.setTrackingFilters({ warehouseId })}
                            style={{ margin: '0 8px 0 8px' }}
                        />
                        <StoreProductsSelect
                            filters={props.filters}
                            setFilters={props.setTrackingFilters}
                        />
                        <div style={{ margin: '0 0 0 8px' }}>
                            <StockProductsModal
                                selectProduct={productId => {
                                    props.setTrackingFilters({
                                        productId,
                                        page: 1
                                    });
                                }}
                                stockMode
                                user={props.user}
                            />
                        </div>
                    </FiltersSubPanel>
                </ResponsiveView>
                <TrackingTableWrapper>
                    <TrackingTable filters={props.filters} loading={props.loading} type={type} />
                </TrackingTableWrapper>
                <StoreProductModal visible={props.modal} />
            </Layout>
        );
    })
);

const Filters = styled.div`
    display: flex;
    align-items: center;
`;

const TrackingTableWrapper = styled.div`
    margin: 0 16px;
    padding-top: 40px;
    ${media.xs`
        margin-top: 60px;
     `};
    ${media.sm`
        margin-top: 60px;
     `};
    ${media.md`
        margin-top: 60px;
     `};
    ${media.lg`
        margin-top: 60px;
     `};
    ${media.xl`
        margin-top: 60px;
     `};
`;

const FiltersSubPanel = styled.div`
    display: flex;
    overflow: initial;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    padding: 16px;
    margin-bottom: 24px;
    z-index: 210;
    border-top: 1px dashed var(--primary);
    border-bottom: 1px dashed var(--primary);
    position: fixed;
    top: 128px;
    left: ${props => (props.collapsed ? '80px' : '256px')};
    width: ${props => (props.collapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)')};
`;
