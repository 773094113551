import { Form } from '@ant-design/compatible';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

const FormItem = Form.Item;

export class DecoratedTimePicker extends React.PureComponent {
    render() {
        const {
            // formItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            getPopupContainer,
            getFieldDecorator,
            rules,
            field,
            format,
            placeholder,
            disabled,
            formatMessage,
            popupClassName,
            minuteStep,
            disabledHours,
            disabledMinutes,
            disabledSeconds,
            inputReadOnly,
            allowClear,
            initialValue: initialInitialValue,
            defaultOpenValue: initialDefaultOpenValue,
            onChange,
            hideDisabledOptions
        } = this.props;
        const defaultOpenValue = _.isString(initialDefaultOpenValue)
            ? dayjs(initialDefaultOpenValue)
            : initialDefaultOpenValue;
        const initialValue = _.isString(initialInitialValue)
            ? dayjs(initialInitialValue)
            : initialInitialValue;

        const timePicker = getFieldDecorator(field, {
            ...(initialValue ? { initialValue } : { initialValue: void 0 }),
            rules
        })(
            <TimePicker
                allowClear={allowClear}
                defaultOpenValue={defaultOpenValue}
                disabled={disabled}
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                disabledSeconds={disabledSeconds}
                format={format || 'HH:mm'}
                getPopupContainer={getPopupContainer}
                hideDisabledOptions={hideDisabledOptions}
                inputReadOnly={inputReadOnly}
                minuteStep={minuteStep}
                onChange={onChange}
                placeholder={
                    placeholder || formatMessage({ id: 'datepicker.timepicker.placeholder' })
                }
                popupClassName={popupClassName}
            />
        );

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                label={label}
                {...formItemLayout}
            >
                {timePicker}
            </FormItem>
        ) : (
            timePicker
        );
    }
}
