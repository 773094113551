exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__techInfoWrapper---1jr4v{display:grid;grid-template-columns:1fr 1fr}.styles-m__attributeItem---1hIZ4{border:1px solid;padding:5px;font-size:1.1em}.styles-m__margin---cLT-2{margin-bottom:6px}.styles-m__inputNumberBlock---yNvu-{display:flex;justify-content:space-between;flex-wrap:wrap}.styles-m__buttonWrap---1UdX8{display:flex;flex-wrap:wrap;gap:8px;justify-content:center;margin-bottom:12px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,mCACI,YAAa,CACb,6BACJ,CAEA,iCACI,gBAAiB,CACjB,WAAY,CACZ,eACJ,CAEA,0BACI,iBACJ,CAEA,oCACI,YAAa,CACb,6BAA8B,CAC9B,cACJ,CAEA,8BACI,YAAa,CACb,cAAe,CACf,OAAQ,CACR,sBAAuB,CACvB,kBACJ","file":"styles.m.css","sourcesContent":[".techInfoWrapper {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr;\r\n}\r\n\r\n.attributeItem {\r\n    border: 1px solid;\r\n    padding: 5px;\r\n    font-size: 1.1em;\r\n}\r\n\r\n.margin {\r\n    margin-bottom: 6px;\r\n}\r\n\r\n.inputNumberBlock {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.buttonWrap {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    gap: 8px;\r\n    justify-content: center;\r\n    margin-bottom: 12px;\r\n}"]}]);

// Exports
exports.locals = {
	"techInfoWrapper": "styles-m__techInfoWrapper---1jr4v",
	"attributeItem": "styles-m__attributeItem---1hIZ4",
	"margin": "styles-m__margin---cLT-2",
	"inputNumberBlock": "styles-m__inputNumberBlock---yNvu-",
	"buttonWrap": "styles-m__buttonWrap---1UdX8"
};