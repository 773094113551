exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__form---46WB8{display:flex;align-items:center}.styles-m__detailsSelect---3W7sN,.styles-m__servicesSelect---2X7Eu{flex:0 1 300px;margin-right:8px}.styles-m__quantity---2ggrU{margin-right:8px}.styles-m__saveIcon---3FfUf{cursor:pointer;font-size:24px;color:var(--secondary)}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,wBACI,YAAa,CACb,kBACJ,CAOA,mEACI,cAAe,CACf,gBACJ,CAEA,4BACI,gBACJ,CAEA,4BACI,cAAe,CACf,cAAe,CACf,sBACJ","file":"styles.m.css","sourcesContent":[".form {\n    display: flex;\n    align-items: center;\n}\n\n.servicesSelect {\n    flex: 0 1 300px;\n    margin-right: 8px;\n}\n\n.detailsSelect {\n    flex: 0 1 300px;\n    margin-right: 8px;\n}\n\n.quantity {\n    margin-right: 8px;\n}\n\n.saveIcon {\n    cursor: pointer;\n    font-size: 24px;\n    color: var(--secondary);\n}\n"]}]);

// Exports
exports.locals = {
	"form": "styles-m__form---46WB8",
	"servicesSelect": "styles-m__servicesSelect---2X7Eu",
	"detailsSelect": "styles-m__detailsSelect---3W7sN",
	"quantity": "styles-m__quantity---2ggrU",
	"saveIcon": "styles-m__saveIcon---3FfUf"
};