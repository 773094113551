import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Numeral } from 'commons';
import _, { get } from 'lodash';
import {
    selectFetchingSelectedSupplierStoreDoc,
    selectSelectedSupplierStoreDoc,
    selectSelectedSupplierStoreDocId,
    setSelectedSupplierStoreDocId
} from 'modals/DebtZeroingModal/redux/suppliersStoreDocs/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { businessTypes, getBusinessType } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    selectedSupplierStoreDocId: selectSelectedSupplierStoreDocId(state),
    selectedSupplierStoreDoc: selectSelectedSupplierStoreDoc(state),
    fetchingSelectedSupplierStoreDoc: selectFetchingSelectedSupplierStoreDoc(state)
});

const mapDispatchToProps = {
    setSelectedSupplierStoreDocId
};

/**
 * If supplier store doc is selected this component generates block with its basic
 * information and ability to remove currently selected supplier store doc(make no one selected)
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SupplierStoreDocVisualizer extends React.Component {
    render() {
        const {
            selectedSupplierStoreDocId,
            selectedSupplierStoreDoc,
            fetchingSelectedSupplierStoreDoc,

            setSelectedSupplierStoreDocId
        } = this.props;

        const businessType = getBusinessType();

        if (!selectedSupplierStoreDocId) return 'No selected store doc data';
        if (fetchingSelectedSupplierStoreDoc) return <LoadingOutlined />;

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col span={4}>{get(selectedSupplierStoreDoc, 'documentNumber', '')}</Col>

                    <Col className={Styles.col} span={8}>
                        {
                            // Disable link when we are in tire fitting
                            businessType === businessTypes.TIRE_STATION ? (
                                <span>{`${selectedSupplierStoreDoc.counterpartBusinessSupplierName || '-'}`}</span>
                            ) : (
                                <Link to={`${book.supplier}/${selectedSupplierStoreDoc.id}`}>
                                    <span>{`${selectedSupplierStoreDoc.counterpartBusinessSupplierName || '-'}`}</span>
                                </Link>
                            )
                        }
                    </Col>

                    <Col className={Styles.col} span={6}></Col>

                    <Col span={2}>
                        <Numeral mask='0,0.00' nullText='0'>
                            {Number(_.get(selectedSupplierStoreDoc, 'sellingSum', 0)) || 0}
                        </Numeral>
                    </Col>

                    <Col span={2}>
                        <Numeral mask='0,0.00' nullText='0'>
                            {Number(_.get(selectedSupplierStoreDoc, 'remainSum', 0)) || 0}
                        </Numeral>
                    </Col>

                    <Col className={Styles.actionsCol} span={2}>
                        <CloseOutlined
                            className={Styles.closeIcon}
                            // Remove currently selected store doc
                            onClick={() => {
                                setSelectedSupplierStoreDocId(undefined);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
