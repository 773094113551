import { ManagerRoleContainer } from 'containers';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout } from 'tireFitting';

export default class ManagerRolePage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Layout title={<FormattedMessage id='manager-role-page.title' />}>
                <ManagerRoleContainer />
            </Layout>
        );
    }
}
