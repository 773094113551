exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__table---27_0p{margin-top:24px}.styles-m__table---27_0p th{word-break:keep-all}.styles-m__downloadIcon---3PJi7,.styles-m__editIcon---1E1XW,.styles-m__printIcon---3Gc5_,.styles-m__sendMailIcon---_L4M7,.styles-m__sendSMS---392Nv,.styles-m__unregisteredIcon---32SKm{display:inline-block;font-size:18px;color:#000}.styles-m__printIcon---3Gc5_{color:var(--primary)}.styles-m__editIcon---1E1XW{color:var(--secondary)}.styles-m__unregisteredIcon---32SKm{color:red}.styles-m__sendMailIcon---_L4M7{color:#00f}.styles-m__downloadIcon---3PJi7{color:orange}.styles-m__unregisteredCashOrder---1mKXo{background-color:hsla(0,100%,70%,.4)}.styles-m__breakWord---2xjja{word-break:break-word}.styles-m__noBreak---2sDBl{word-break:keep-all}.styles-m__disabled---255kP{pointer-events:none;color:#acacac}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACI,eACJ,CAEA,4BACI,mBACJ,CAEA,wLACI,oBAAqB,CAErB,cAAe,CACf,UACJ,CAEA,6BACI,oBACJ,CAEA,4BACI,sBACJ,CAEA,oCACI,SACJ,CAEA,gCACI,UACJ,CAEA,gCACI,YACJ,CAEA,yCACI,oCACJ,CAEA,6BACI,qBACJ,CAEA,2BACI,mBACJ,CAGA,4BACI,mBAAoB,CACpB,aACJ","file":"styles.m.css","sourcesContent":[".table {\n    margin-top: 24px;\n}\n\n.table th {\n    word-break: keep-all;\n}\n\n.unregisteredIcon, .sendSMS, .sendMailIcon, .printIcon, .editIcon, .downloadIcon {\n    display: inline-block;\n    /* margin-left: 8px; */\n    font-size: 18px;\n    color: black;\n}\n\n.printIcon {\n    color: var(--primary);\n}\n\n.editIcon {\n    color: var(--secondary);\n}\n\n.unregisteredIcon {\n    color: red;\n}\n\n.sendMailIcon {\n    color: blue;\n}\n\n.downloadIcon {\n    color: orange;\n}\n\n.unregisteredCashOrder {\n    background-color: rgba(255, 102, 102, 0.4);\n}\n\n.breakWord {\n    word-break: break-word;\n}\n\n.noBreak {\n    word-break: keep-all;\n}\n\n/* If component has this class it will be unclickable */\n.disabled{\n    pointer-events: none;\n    color: rgb(172, 172, 172);\n}"]}]);

// Exports
exports.locals = {
	"table": "styles-m__table---27_0p",
	"unregisteredIcon": "styles-m__unregisteredIcon---32SKm",
	"sendSMS": "styles-m__sendSMS---392Nv",
	"sendMailIcon": "styles-m__sendMailIcon---_L4M7",
	"printIcon": "styles-m__printIcon---3Gc5_",
	"editIcon": "styles-m__editIcon---1E1XW",
	"downloadIcon": "styles-m__downloadIcon---3PJi7",
	"unregisteredCashOrder": "styles-m__unregisteredCashOrder---1mKXo",
	"breakWord": "styles-m__breakWord---2xjja",
	"noBreak": "styles-m__noBreak---2sDBl",
	"disabled": "styles-m__disabled---255kP"
};