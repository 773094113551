exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__block---27X0W{display:flex;justify-content:space-between}.styles-m__block---27X0W,.styles-m__blockTable---1tOji{padding:4px;margin-bottom:10px;border-bottom:2px solid var(--db_reserve)}.styles-m__rightContent---2Zdgr{display:flex;justify-content:end;margin-top:8px}.styles-m__finalBlock---1Efp1{padding:4px;margin-bottom:10px}.styles-m__title---1WNh-{font-size:16px;font-weight:700}.styles-m__margin---1YWMy{margin-top:8px}.styles-m__icon---8-_rc{font-size:12px;padding:0 4px;cursor:pointer}.styles-m__okBtn---1RvXv{display:flex;justify-content:end;margin-bottom:10px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBAEE,YAAa,CACb,6BAGF,CAEA,uDAPE,WAAY,CAGZ,kBAAmB,CACnB,yCAOF,CAEA,gCACE,YAAa,CACb,mBAAoB,CACpB,cAEF,CAEA,8BACE,WAAY,CACZ,kBACF,CAEA,yBACE,cAAe,CACf,eACF,CAEA,0BACE,cACF,CAEA,wBACE,cAAe,CACf,aAAc,CACd,cACF,CAEA,yBACE,YAAa,CACb,mBAAoB,CACpB,kBACF","file":"styles.m.css","sourcesContent":[".block {\n  padding: 4px;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  border-bottom: 2px solid var(--db_reserve);\n}\n\n.blockTable {\n  padding: 4px;\n  margin-bottom: 10px;\n  border-bottom: 2px solid var(--db_reserve);\n}\n\n.rightContent {\n  display: flex;\n  justify-content: end;\n  margin-top: 8px;\n\n}\n\n.finalBlock {\n  padding: 4px;\n  margin-bottom: 10px;\n}\n\n.title {\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.margin {\n  margin-top: 8px;\n}\n\n.icon {\n  font-size: 12px;\n  padding: 0 4px;\n  cursor: pointer;\n}\n\n.okBtn {\n  display: flex;\n  justify-content: end;\n  margin-bottom: 10px;\n}"]}]);

// Exports
exports.locals = {
	"block": "styles-m__block---27X0W",
	"blockTable": "styles-m__blockTable---1tOji",
	"rightContent": "styles-m__rightContent---2Zdgr",
	"finalBlock": "styles-m__finalBlock---1Efp1",
	"title": "styles-m__title---1WNh-",
	"margin": "styles-m__margin---1YWMy",
	"icon": "styles-m__icon---8-_rc",
	"okBtn": "styles-m__okBtn---1RvXv"
};