/* eslint-disable complexity */
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { all, call, put, take } from 'redux-saga/effects';
import book from 'routes/book';
import history from 'store/history';
import { fetchAPI } from 'utils';
import { addError } from '../forms/editClientVehicleForm/duck';
import {
    CREATE_CLIENT_VEHICLE,
    CREATE_ORDER_FOR_CLIENT,
    DELETE_CLIENT_VEHICLE,
    FETCH_CLIENT,
    UPDATE_CLIENT_VEHICLE,
    fetchClient,
    fetchClientSuccess,
    setClientFetching
} from './duck';

export function* fetchClientSaga() {
    while (true) {
        try {
            const {
                payload: { id }
            } = yield take(FETCH_CLIENT);
            yield put(setClientFetching(true));

            const data = yield call(fetchAPI, 'GET', `clients/${id}`);
            yield put(fetchClientSuccess(data));
        } finally {
            yield put(setClientFetching(false));
        }
    }
}

export function* updateClientVehicleSaga() {
    while (true) {
        const {
            payload: { clientVehicleId, clientId, clientVehicle }
        } = yield take(UPDATE_CLIENT_VEHICLE);

        yield call(fetchAPI, 'PUT', `clients/vehicles/${clientVehicleId}`, null, _.omit(clientVehicle, 'barcode'));

        yield put(fetchClient(clientId));
    }
}

export function* createClientVehicleSaga() {
    while (true) {
        const {
            payload: { clientId, clientVehicle }
        } = yield take(CREATE_CLIENT_VEHICLE);
        const {
            modificationId: vehicleModificationId,
            number: vehicleNumber,
            vin: vehicleVin,
            modelId: vehicleModelId,
            year: vehicleYear,
            vehicleTypeId,
            wheelRadius,
            type,
            bodyId,
            colorId
        } = clientVehicle;

        const payload = {
            vehicleModelId,
            vehicleModificationId,
            vehicleVin,
            vehicleNumber,
            vehicleYear,
            vehicleTypeId,
            wheelRadius,
            type,
            bodyId,
            colorId
        };

        yield call(fetchAPI, 'POST', `clients/${clientId}/vehicles`, null, payload);
        yield put(fetchClient(clientId));
    }
}

export function* deleteClientVehicleSaga() {
    while (true) {
        const {
            payload: { clientId, clientVehicleId }
        } = yield take(DELETE_CLIENT_VEHICLE);

        try {
            yield call(fetchAPI, 'DELETE', `clients/vehicles/${clientVehicleId}`, null, null, {
                handleErrorInternally: true
            });
        } catch ({ response, status }) {
            yield put(addError({ response, status }));

            continue;
        }
        yield put(fetchClient(clientId));
    }
}

export function* createOrderForClientSaga() {
    while (true) {
        const {
            payload: { clientId, managerId, vehicleId, vehicleTypeId, wheelRadius }
        } = yield take(CREATE_ORDER_FOR_CLIENT);
        if (!clientId) {
            continue;
        }

        // Get client
        const client = yield call(fetchAPI, 'GET', `clients/${clientId}`);
        const vehicle = vehicleId
            ? yield call(fetchAPI, 'GET', `clients/vehicles/${vehicleId}`)
            : _.get(client, 'vehicles[0]');

        try {
            const response = yield call(
                fetchAPI,
                'POST',
                'orders',
                null,
                {
                    clientId: client.clientId,
                    clientVehicleId: _.get(vehicle, 'id'),
                    clientVehicleTypeId: vehicleTypeId || _.get(vehicle, 'vehicleTypeId'),
                    clientVehicleRadius: wheelRadius || _.get(vehicle, 'wheelRadius'),
                    duration: 0.5,
                    clientPhone: client.phones[0],
                    stationLoads: [
                        {
                            beginDatetime: dayjs().startOf('hour').toISOString(),
                            duration: 0.5,
                            status: 'TO_DO'
                        }
                    ],
                    status: 'not_complete',
                    managerId,
                    beginDatetime: dayjs().startOf('hour').toISOString()
                },
                { handleErrorInternally: true }
            );

            if (response && response.created) {
                // If successfully created new order redirect on its page
                history.push({
                    pathname: `${book.order}/${response.created[0].id}`
                });
            }
        } catch (err) {
            const { response } = err;
            response &&
                notification.error({
                    message: response.message
                });
        }
    }
}

export function* saga() {
    yield all([
        call(fetchClientSaga),
        call(createClientVehicleSaga),
        call(updateClientVehicleSaga),
        call(deleteClientVehicleSaga),
        call(createOrderForClientSaga)
    ]);
}
