import { CameraOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Table, Tooltip, Upload, notification } from 'antd';
import PaddedWrapper from 'forms/OrderForm/OrderFormTables/DiagnosticTable/components/PaddedWrapper';
import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { filterByPartGeneral } from 'utils';
import StatusButtons, { statusDisplayMap } from '../StatusButtons';

const ChassisDiagnosticTable = ({
    orderUnits,
    putOrderScheme,
    deleteOrderUnits,
    downloadPhoto,
    disabledPhoto,
    intl: { formatMessage }
}) => {
    const [nameSearch, setNameSearch] = useState('');
    const handleNameSearch = e => {
        setNameSearch(e.target.value);
    };
    const columns = useMemo(
        () => [
            { title: formatMessage({ id: 'index' }), key: 'unitId', dataIndex: 'unitId' },
            {
                title: formatMessage({ id: 'name_2' }),
                key: 'unitName',
                dataIndex: 'unitName',
                filteredValue: nameSearch ? [nameSearch] : [],
                onFilter: (value, record) => {
                    if (nameSearch) {
                        return filterByPartGeneral(nameSearch, record.unitName);
                    }

                    return true;
                },
                // filtered: false,
                filterDropdown: () => (
                    <PaddedWrapper>
                        <Input
                            allowClear
                            onChange={handleNameSearch}
                            placeholder={formatMessage({ id: 'search' })}
                            value={nameSearch}
                        />
                    </PaddedWrapper>
                )
            },
            {
                width: 30,
                title: formatMessage({ id: 'status' }),
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                render: (status, orderUnit) => {
                    const Icon = statusDisplayMap[status].icon;

                    return orderUnit.blocked ? (
                        <Icon
                            style={{
                                fontSize: 20,
                                color: statusDisplayMap[status].color,
                                cursor: 'pointer'
                            }}
                        />
                    ) : (
                        <Tooltip
                            color='white'
                            placement='top'
                            title={<StatusButtons isSelected orderUnit={orderUnit} putOrderScheme={putOrderScheme} />}
                        >
                            <Icon
                                style={{
                                    fontSize: 20,
                                    color: statusDisplayMap[status].color,
                                    cursor: 'pointer'
                                }}
                            />
                        </Tooltip>
                    );
                }
            },
            {
                width: 30,
                key: 'status',
                align: 'center',
                render: orderUnit => {
                    return (
                        <Button
                            disabled={orderUnit.blocked}
                            icon={<DeleteOutlined />}
                            onClick={() => deleteOrderUnits([orderUnit.id])}
                        />
                    );
                }
            },
            {
                width: 30,
                key: 'photo',
                align: 'center',
                render: unit => {
                    console.log(unit, 'unit');

                    return (
                        <Upload
                            action={elem => downloadPhoto(elem, unit.id, unit.unitName)}
                            beforeUpload={file => {
                                const isPNG = file.type === 'image/png';
                                const isJPG = file.type === 'image/jpeg';
                                if (!isPNG && !isJPG) {
                                    notification.error({
                                        message: `${file.name} ${formatMessage({
                                            id: 'photo_modal.is_not_an_image'
                                        })}!`
                                    });
                                }

                                return isPNG || isJPG;
                            }}
                            disabled={disabledPhoto}
                            showUploadList={false}
                        >
                            <Button disabled={disabledPhoto} icon={<CameraOutlined />} />
                        </Upload>
                    );
                }
            }
        ],
        [deleteOrderUnits, disabledPhoto, downloadPhoto, formatMessage, nameSearch, putOrderScheme]
    );

    return <Table bordered columns={columns} dataSource={orderUnits} rowKey='id' size='small' />;
};

export default injectIntl(ChassisDiagnosticTable);
