import { AgreementPage } from 'pages';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import book from 'routes/book';
import {
    AddEmployeePage,
    AddOrderPage,
    CashBankPage,
    CashFlowPage,
    CashSettingsPage,
    ChartPage,
    ClientHotOperationsPage,
    ClientPage,
    ClientsPage,
    DashboardPage,
    EditEmployeePage,
    EmployeesPage,
    ExceptionPage,
    LaborsPage,
    MainMenuPage,
    ManagerRolePage,
    OrderPage,
    OrdersPage,
    ProfilePage,
    ReportOrdersPage,
    TirePriceGroupsPage,
    VehicleTypesPage
} from 'tireFitting';

export default class TireFittingRoutes extends React.Component {
    render() {
        return (
            <Switch>
                {/* Operations */}
                <Route exact path={book.dashboard} render={props => <DashboardPage {...props} />} />
                <Route
                    exact
                    path={book.mainMenuPage}
                    render={props => <MainMenuPage {...props} />}
                />
                <Route
                    exact
                    path={book.ordersByStatuses}
                    render={props => <OrdersPage {...props} />}
                />
                <Route
                    exact
                    path={book.orderId}
                    render={props => <OrderPage key={props.match.params.id} {...props} />}
                />
                <Route exact path={book.addOrder} render={props => <AddOrderPage {...props} />} />
                <Route exact path={book.agreement} render={props => <AgreementPage {...props} />} />
                {/* Reference book */}
                <Route
                    exact
                    path={book.clientHotOperations}
                    render={props => <ClientHotOperationsPage {...props} />}
                />
                <Route exact path={book.clients} render={props => <ClientsPage {...props} />} />
                <Route exact path={book.clientId} render={props => <ClientPage {...props} />} />
                <Route
                    exact
                    path={book.employeesPage}
                    render={props => <EmployeesPage {...props} />}
                />
                <Route
                    exact
                    path={book.addEmployee}
                    render={props => <AddEmployeePage {...props} />}
                />
                <Route
                    exact
                    path={book.editEmployee}
                    render={props => <EditEmployeePage {...props} />}
                />
                <Route exact path={book.laborsPage} render={props => <LaborsPage {...props} />} />
                <Route
                    exact
                    path={book.vehicleTypes}
                    render={props => <VehicleTypesPage {...props} />}
                />
                <Route
                    exact
                    path={book.tirePriceGroups}
                    render={props => <TirePriceGroupsPage {...props} />}
                />
                {/* Cash */}
                <Route
                    exact
                    path={book.cashBankPage}
                    render={props => <CashBankPage {...props} />}
                />
                <Route
                    exact
                    path={book.cashSettingsPage}
                    render={props => <CashSettingsPage {...props} />}
                />
                <Route
                    exact
                    path={book.cashFlowPage}
                    render={props => <CashFlowPage {...props} />}
                />
                {/* Statistics and reports */}
                <Route exact path={book.chart} render={props => <ChartPage {...props} />} />
                <Route
                    exact
                    path={book.reportOrders}
                    render={props => <ReportOrdersPage {...props} />}
                />
                {/* Roles */}
                <Route
                    exact
                    path={book.managerRolePage}
                    render={props => <ManagerRolePage {...props} />}
                />
                {/* Global */}
                <Route exact path={book.profile} render={props => <ProfilePage {...props} />} />
                <Route
                    path={book.exceptionStatusCode}
                    render={props => <ExceptionPage {...props} />}
                />
                <Redirect exact from='/' to={book.mainMenuPage} />
                <Route
                    path={`${book.exception}/404`}
                    render={() => <Redirect to={`${book.exception}/404`} />}
                />
                <Route exact path='*' render={() => <Redirect to={book.dashboard} />} />
            </Switch>
        );
    }
}
