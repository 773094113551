import { emitError } from 'core/ui/duck';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchEmployeesSuccess, FETCH_EMPLOYEES } from './duck';

export function* fetchEmployeesSaga() {
    try {
        // const filter = yield select(state => state.employees.filter);

        const employees = yield call(fetchAPI, 'GET', 'employees');

        yield put(fetchEmployeesSuccess({ employees }));
    } catch (error) {
        yield put(emitError(error));
    }
}

export function* saga() {
    yield all([takeEvery(FETCH_EMPLOYEES, fetchEmployeesSaga)]);
}
