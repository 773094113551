import dayjs from 'dayjs';
import { SEARCH_TYPES } from 'modals/DebtZeroingModal/components/containers/ClientCounterpartyContainer/constants';
import { DEF_BACK_DATE, OPERATION_TYPES } from 'modals/DebtZeroingModal/constants';
import { combineReducers } from 'redux';
import analyticsReducer, {
    clearAnalyticsState,
    moduleName as analyticsModule
} from './analytics/duck';
import cashboxesReducer, {
    clearCashboxesState,
    moduleName as cashboxesModule
} from './cashboxes/duck';
import clientsReducer, { clearClientsState, moduleName as clientsModule } from './clients/duck';
import ordersReducer, {
    clearOrdersState,
    fetchOrders,
    moduleName as ordersModule
} from './orders/duck';
import storeDocsReducer, {
    clearStoreDocsState,
    fetchStoreDocs,
    moduleName as storeDocsModule
} from './storeDocs/duck';
import suppliersReducer, {
    clearSuppliersState,
    moduleName as suppliersModule
} from './suppliers/duck';
import suppliersStoreDocsReducer, {
    clearSuppliersStoreDocsState,
    fetchSuppliersStoreDocs,
    moduleName as suppliersStoreDocsModule
} from './suppliersStoreDocs/duck';

// Reexports from other ducks(required to access their content):
export * from './analytics/duck';
export * from './cashboxes/duck';
export * from './clients/duck';
export * from './orders/duck';
export * from './storeDocs/duck';
export * from './suppliers/duck';
export * from './suppliersStoreDocs/duck';

/** ------------------------------------- Constants ------------------------------------- * */
export const mainModuleName = 'debtZeroingModal';
export const moduleName = 'common';
const prefix = `cpb/${mainModuleName}/${moduleName}`;

export const SET_CLIENT_SEARCH_TYPE = `${prefix}/SET_CLIENT_SEARCH_TYPE`;

export const SET_DATE_RANGE = `${prefix}/SET_DATE_RANGE`;

export const SET_SERVICE_OPERATION_TYPE = `${prefix}/SET_SERVICE_OPERATION_TYPE`;
export const SET_INITIALIZED = `${prefix}/SET_INITIALIZED`;

export const CLEAR_MODAL_STATE = `${prefix}/CLEAR_MODAL_STATE`;
export const COVER_DEBTS = `${prefix}/COVER_DEBTS`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    dateRange: {
        startDate: dayjs().startOf('month').format(DEF_BACK_DATE),
        endDate: dayjs().endOf('month').format(DEF_BACK_DATE)
    },

    initialized: false, // Defines if modal was initialized or not
    serviceOperationType: OPERATION_TYPES.RECEIVABLES,
    clientSearchType: SEARCH_TYPES.FOR_CLIENT
};

// eslint-disable-next-line complexity
export function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_DATE_RANGE:
            return {
                ...state,
                dateRange: payload
            };
        case SET_CLIENT_SEARCH_TYPE:
            return {
                ...state,
                clientSearchType: payload
            };
        case SET_INITIALIZED:
            return {
                ...state,
                initialized: payload
            };
        case CLEAR_MODAL_STATE:
            return ReducerState;
        case SET_SERVICE_OPERATION_TYPE:
            return {
                ...state,
                serviceOperationType: payload
            };

        default:
            return state;
    }
}

// Export this reducer in the way we can connect other reducers
export default combineReducers({
    [moduleName]: reducer,
    [clientsModule]: clientsReducer,
    [ordersModule]: ordersReducer,
    [storeDocsModule]: storeDocsReducer,
    [suppliersModule]: suppliersReducer,
    [suppliersStoreDocsModule]: suppliersStoreDocsReducer,
    [analyticsModule]: analyticsReducer,
    [cashboxesModule]: cashboxesReducer
});

/** ------------------------------------- Selectors ------------------------------------- * */

export const selectClientSearchType = state => state[mainModuleName][moduleName].clientSearchType;
export const selectServiceOperationType = state =>
    state[mainModuleName][moduleName].serviceOperationType;
export const selectCashOrder = state => state[mainModuleName][moduleName].cashOrder;
export const selectInitialized = state => state[mainModuleName][moduleName].initialized;
export const selectDateRange = state => state[mainModuleName][moduleName].dateRange;

/* ------------------------------------- Action Creators ------------------------------------- */

/**
 * Set data range and fetch data depending on it
 */
export const setDateRange = value => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: SET_DATE_RANGE,
            payload: value
        });

        dispatch(fetchOrders());
        dispatch(fetchStoreDocs());
        dispatch(fetchSuppliersStoreDocs());
    };
};
export const setClientSearchType = value => ({
    type: SET_CLIENT_SEARCH_TYPE,
    payload: value
});

/**
 * Set value which define if modal is initialized or it isn't.
 * When redux is cleared(modal is closed and state is set ot default) default value is set to false.
 * @param {*} value - is modal initialized
 */
export const setInitialized = value => ({
    type: SET_INITIALIZED,
    payload: value
});

/**
 * Clear redux state of all components
 */
export const clearModalState = () => {
    // eslint-disable-next-line func-names
    return function (dispatch) {
        dispatch({
            type: CLEAR_MODAL_STATE
        });

        dispatch(clearClientsState());
        dispatch(clearOrdersState());
        dispatch(clearSuppliersState());
        dispatch(clearSuppliersStoreDocsState());
        dispatch(clearStoreDocsState());
        dispatch(clearAnalyticsState());
        dispatch(clearCashboxesState());
    };
};

export const setServiceOperationType = value => ({
    type: SET_SERVICE_OPERATION_TYPE,
    payload: value
});

/**
 * Make all orders from range(determined with filters) fully paid
 */
export const coverDebts = () => ({
    type: COVER_DEBTS
});
