// Side effects Services
/* eslint-disable camelcase */
import en from 'antd/es/locale/en_GB';
import bg from 'antd/locale/bg_BG';
import cs from 'antd/locale/cs_CZ';
import uk from 'antd/locale/uk_UA';
import _ from 'lodash';
//
// localStorage
//
// token
export const setToken = token => localStorage.setItem('_my.carbook.pro_token', token);

export const setStorageToken = token => localStorage.setItem('_my.carbook.pro_token_storage', token);

export const getToken = () => localStorage.getItem('_my.carbook.pro_token');

export const getStorageToken = () => localStorage.getItem('_my.carbook.pro_token_storage');

export const removeToken = () => localStorage.removeItem('_my.carbook.pro_token');

export const setSessionId = sessionId => localStorage.setItem('_my.carbook.pro_sessionId', sessionId);

export const getSessionId = () => localStorage.getItem('_my.carbook.pro_sessionId');

export const removeSessionId = () => localStorage.removeItem('_my.carbook.pro_sessionId');

// business types
export const setBusinessTypes = types => localStorage.setItem('_my.carbook.business_types', JSON.stringify(types));

export const getBusinessType = () => {
    const businessType = JSON.parse(localStorage.getItem('_my.carbook.business_types'));

    return _.isArray(businessType) ? _.get(businessType, '[0]') : businessType;
};

export const removeBusinessTypes = () => localStorage.removeItem('_my.carbook.business_types');

export const TIRE_STATION = 'TIRE_STATION';

export const businessTypes = {
    TIRE_STATION
};

// header icons
export const setHeaderIcons = icons => localStorage.setItem('_my.carbook.header_icons', JSON.stringify(icons || []));

export const getHeaderIcons = () => JSON.parse(localStorage.getItem('_my.carbook.header_icons')) || [];

export const removeHeaderIcons = () => localStorage.removeItem('_my.carbook.header_icons');

// order icons
export const setOrderIcons = tabs => localStorage.setItem('_my.carbook.order_icons', JSON.stringify(tabs || []));

export const getOrderIcons = () => JSON.parse(localStorage.getItem('_my.carbook.order_icons')) || ['LABORS', 'PARTS'];

export const setRtlIcons = tabs => localStorage.setItem('_my.carbook.rtl_icons', JSON.stringify(tabs || []));

export const getRtlIcons = () => JSON.parse(localStorage.getItem('_my.carbook.rtl_icons')) || ['PARTS'];

export const removeOrderIcons = () => localStorage.removeItem('_my.carbook.order_icons');

// locale
const fallbackLocale = window.navigator.language || window.navigator.userLanguage || 'uk';

export const setLocale = locale => {
    if (locale === 'ua') {
        return localStorage.setItem('_my.carbook.pro_locale', 'uk');
    }

    return localStorage.setItem('_my.carbook.pro_locale', locale);
};

export const getLocale = () => {
    // console.log('local', localStorage.getItem('_my.carbook.pro_locale'), fallbackLocale);

    return localStorage.getItem('_my.carbook.pro_locale') || fallbackLocale;
};

export const removeLocale = () => localStorage.removeItem('_my.carbook.pro_locale');

export const setLocaleProvider = () => {
    const language = getLocale();

    // return language == 'en' ? en : uk;

    switch (language) {
        case 'en-GB':
            return en;
        case 'en':
            return en;
        case 'ru':
            return uk;
        case 'uk':
            return uk;
        case 'uk-UA':
            return uk;
        case 'bg_BG':
            return bg;
        case 'bg':
            return bg;
        case 'cs_CZ':
            return cs;
        case 'cs':
            return cs;
        case 'cz':
            return cs;
        default:
            return uk;
    }
};

export const setCurrency = currency => {
    if (!currency) {
        const language = getLocale();
        switch (language) {
            case 'en':
                return localStorage.setItem('_my.carbook.pro_currency', currency);
            case 'ru':
                return localStorage.setItem('_my.carbook.pro_currency', currency);
            case 'uk':
                return localStorage.setItem('_my.carbook.pro_currency', '₴');
            default:
                return localStorage.setItem('_my.carbook.pro_currency', '₴');
        }
    }

    return localStorage.setItem('_my.carbook.pro_currency', currency);
};

export const getCurrency = () => localStorage.getItem('_my.carbook.pro_currency') || '';

// ui
export const setCollapsedState = collapsed =>
    localStorage.setItem('_my.carbook.pro_layout_collapsed', JSON.stringify(collapsed));

export const getCollapsedState = () => JSON.parse(localStorage.getItem('_my.carbook.pro_layout_collapsed'));

export const removeCollapsedState = () => localStorage.removeItem('_my.carbook.pro_layout_collapsed');

//
// dayjs
//

// dayjs.locale(getLocale());

//
// cookies
//

export const getCookie = name => {
    const matches = document.cookie.match(
        new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`)
    );

    return matches ? decodeURIComponent(matches[1]) : void 0;
};

export const setCookie = (name, value, props = {}) => {
    let { expires } = props;

    if (typeof expires === 'number' && expires) {
        const date = new Date();

        date.setTime(date.getTime() + expires * 1000);

        expires = props.expires = date;
    }

    if (expires && expires.toUTCString) {
        props.expires = expires.toUTCString();
    }

    let updatedCookie = `${name}=${encodeURIComponent(value)}`;

    /* eslint-disable guard-for-in */
    for (const propName in props) {
        updatedCookie += `; ${propName}`;

        const propValue = props[propName];

        if (propValue !== true) {
            updatedCookie += `=${propValue}`;
        }
    }

    document.cookie = updatedCookie;
};

export const deleteCookie = name => {
    setCookie(name, null, { expires: -1 });
};

export const setMechanicSumLaborsPercentStorage = (name, mechanicSumLaborsPercent) =>
    localStorage.setItem(name, mechanicSumLaborsPercent);

export const getMechanicSumLaborsPercentStorage = name => localStorage.getItem(name);
