import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

export default class InviteModal extends Component {
    render() {
        const { visible, confirmInviteModal, resetModal, count } = this.props;

        const modalOkOptions = count
            ? {
                  okText: <FormattedMessage id='invite-modal.invite' />,
                  onOk: () => confirmInviteModal()
              }
            : { onOk: () => resetModal() };

        return (
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                visible={visible === MODALS.INVITE}
                {...modalOkOptions}
                maskClosable={false}
                onCancel={() => resetModal()}
            >
                {count ? (
                    <div>
                        <QuestionCircleOutlined className={Styles.icon} />
                        <FormattedMessage id='invite-modal.text1' />
                        <span className={Styles.count}>{count}</span>
                        <FormattedMessage id='invite-modal.text2' />
                    </div>
                ) : (
                    <div>
                        <QuestionCircleOutlined className={Styles.icon} />
                        <FormattedMessage id='invite-modal.select_cars' />
                    </div>
                )}
            </Modal>
        );
    }
}
