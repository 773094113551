import { Form } from '@ant-design/compatible';
import { Button, Select } from 'antd';
import {
    onChangeSpreadBusinessBrandsForm,
    searchBusinesses,
    submitSpreadBusinessBrands
} from 'core/forms/spreadBusinessBrands/duck';
import { DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm } from 'utils';

const { Option } = Select;

@injectIntl
@withReduxForm({
    name: 'spreadBusinessBrandsForm',
    actions: {
        change: onChangeSpreadBusinessBrandsForm,
        searchBusinesses,
        submitSpreadBusinessBrands
    }
})
export class SpreadBusinessBrandsForm extends Component {
    getSelect(getFieldDecorator, search, name, options) {
        return (
            <DecoratedSelect
                {...options}
                key={name}
                field={name}
                filterOption={false}
                formItem
                getFieldDecorator={getFieldDecorator}
                getPopupContainer={trigger => trigger.parentNode}
                onFocus={() =>
                    _.isNil(_.get(search, name)) && this.props.searchBusinesses(name, '')
                }
                onSearch={query => this.props.searchBusinesses(name, query)}
                showSearch
            >
                {_.get(search, [name], []).map(({ businessId, name }) => (
                    <Option key={String(businessId)} value={businessId}>
                        {name}
                    </Option>
                ))}
            </DecoratedSelect>
        );
    }

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { search } = this.props;
        const fields = getFieldsValue();

        const { businessId, businessIds } = fields;

        return (
            <Form>
                {this.getSelect(getFieldDecorator, search, 'businessId', {
                    label: <FormattedMessage id='spread_business_brands.source' />
                })}
                {this.getSelect(getFieldDecorator, search, 'businessIds', {
                    label: <FormattedMessage id='spread_business_brands.target' />,
                    mode: 'multiple'
                })}
                <Button
                    disabled={!businessId || !businessIds}
                    onClick={() => this.props.submitSpreadBusinessBrands(businessId, businessIds)}
                    style={{ width: '100%' }}
                    type='primary'
                >
                    <FormattedMessage id='submit' />
                </Button>
            </Form>
        );
    }
}
