import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Select } from 'antd';
import { OrderStatusIcon } from 'components';
import dayjs from 'dayjs';
import { DecoratedDatePicker, DecoratedSelect, DecoratedTimePicker } from 'forms/DecoratedFields';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getDateTimeConfig } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

/* eslint-disable complexity */
export function columnsConfig(
    props,
    state,
    formatMessage,
    onDelete,
    bodyUpdateIsForbidden,
    fetchedOrder
) {
    const _getDefaultValue = (key, fieldName) => {
        const orderStationLoads = (props.stationLoads || [])[key];
        if (!orderStationLoads) {
            return;
        }

        const fields = {
            status: orderStationLoads.status,
            beginDate: dayjs(orderStationLoads.beginDatetime),
            beginTime: dayjs(orderStationLoads.beginDatetime),
            stationNum: orderStationLoads.stationNum,
            duration: orderStationLoads.duration
        };

        return fields[fieldName];
    };

    const { initialStation } = props;
    const { initialBeginDatetime } = props;

    const { disabledDate, beginTime } = getDateTimeConfig(dayjs(), props.schedule);

    // const beginDatetime =
    //     _.get(props.fetchedOrder, 'order.beginDatetime') ||
    //     (bodyUpdateIsForbidden()
    //         ? void 0
    //         : _.get(location, 'state.beginDatetime'));
    //
    // const momentBeginDatetime = beginDatetime ? dayjs(beginDatetime) : void 0;

    const statusCol = {
        title: <FormattedMessage id='status' />,
        key: 'orderStationStatus',
        width: '15%',
        render: ({ key }) => (
            <div className={Styles.status}>
                <OrderStatusIcon status={_getDefaultValue(key, 'status') || 'TO_DO'} />
                <DecoratedSelect
                    field={`stationLoads[${key}].status`}
                    fields={props.fields}
                    getFieldDecorator={props.form.getFieldDecorator}
                    initialValue={_getDefaultValue(key, 'status') || 'TO_DO'}
                >
                    <Option key='TO_DO' value='TO_DO'>
                        <FormattedMessage id='order_form_table.TO_DO' />
                    </Option>
                    <Option key='COMPLETED' value='COMPLETED'>
                        <FormattedMessage id='order_form_table.COMPLETED' />
                    </Option>
                </DecoratedSelect>
            </div>
        )
    };

    const dateCol = {
        title: <FormattedMessage id='date' />,
        key: 'orderStationDate',
        width: '15%',
        render: ({ key }) => (
            <DecoratedDatePicker
                allowClear={false}
                disabledDate={disabledDate}
                field={`stationLoads[${key}].beginDate`}
                fields={props.fields}
                format='YYYY-MM-DD'
                formatMessage={formatMessage}
                getFieldDecorator={props.form.getFieldDecorator} // HH:mm
                initialValue={
                    _getDefaultValue(key, 'beginDate') ||
                    (key === 0 ? initialBeginDatetime : void 0)
                }
                placeholder={formatMessage({
                    id: 'add_order_form.select_date'
                })}
                showTime={false}
            />
        )
    };

    const stationCol = {
        title: <FormattedMessage id='order_form_table.station' />,
        key: 'orderStationNum',
        width: '15%',
        render: ({ key }) => (
            <DecoratedSelect
                disabled={bodyUpdateIsForbidden()}
                field={`stationLoads[${key}].station`}
                fields={props.fields}
                getFieldDecorator={props.form.getFieldDecorator}
                initialValue={
                    _getDefaultValue(key, 'stationNum') || (key === 0 ? initialStation : void 0)
                }
                placeholder={formatMessage({
                    id: 'add_order_form.select_station'
                })}
                rules={[
                    {
                        required: true,
                        message: formatMessage({
                            id: 'required_field'
                        })
                    }
                ]}
            >
                {props.stations.map(({ name, num }) => {
                    return (
                        <Option key={String(num)} value={num}>
                            {name || String(num)}
                        </Option>
                    );
                })}
            </DecoratedSelect>
        )
    };

    const timeCol = {
        title: <FormattedMessage id='time' />,
        key: 'orderStationTime',
        width: '10%',
        render: ({ key }) => (
            <DecoratedTimePicker
                additionalData={_.get(props.availableHours, String(key), [])}
                defaultOpenValue={dayjs(`${beginTime}:00`, 'HH:mm:ss')}
                disabled={
                    bodyUpdateIsForbidden() ||
                    !_.get(props, ['fields', 'stationLoads', key, 'beginDate']) ||
                    !_.get(props, ['fields', 'stationLoads', key, 'station'])
                }
                disabledHours={() => {
                    const availableHours = _.get(props.availableHours, String(key), []);

                    return _.difference(
                        Array(24)
                            .fill(1)
                            .map((value, index) => index),
                        availableHours.map(availableHour =>
                            Number(dayjs(availableHour).format('HH')))
                    );
                }}
                disabledMinutes={hour => {
                    const availableHours = _.get(props.availableHours, String(key), []);

                    const availableMinutes = availableHours
                        .map(availableHour => dayjs(availableHour))
                        .filter(availableHour => Number(availableHour.format('HH')) === hour)
                        .map(availableHour => Number(availableHour.format('mm')));

                    return _.difference([0, 30], availableMinutes);
                }}
                field={`stationLoads[${key}].beginTime`}
                fields={props.fields}
                formatMessage={formatMessage}
                getFieldDecorator={props.form.getFieldDecorator}
                initialValue={
                    _getDefaultValue(key, 'beginTime') ||
                    (key === 0 ? initialBeginDatetime : void 0)
                }
                minuteStep={30}
                placeholder={formatMessage({
                    id: 'add_order_form.provide_time'
                })}
                rules={[
                    {
                        required: true,
                        message: formatMessage({
                            id: 'add_order_form.please_provide_time'
                        })
                    }
                ]}
            />
        )
    };

    const durationCol = {
        title: <FormattedMessage id='order_form_table.duration' />,
        key: 'orderStationDuration',
        width: '15%',
        render: ({ key }) => (
            <DecoratedSelect
                field={`stationLoads[${key}].duration`}
                fields={props.fields}
                getFieldDecorator={props.form.getFieldDecorator}
                initialValue={_getDefaultValue(key, 'duration') || 0.5}
                optionLabel='value'
                options={_(Array.from(Array(9).keys()))
                    .map(option => [
                        {
                            value: option
                        },
                        {
                            value: option + 0.5
                        }
                    ])
                    .flatten()
                    .slice(1, length - 1)
                    .value()}
                optionValue='value'
            />
        )
    };

    const deleteCol = {
        title: '',
        key: 'delete',
        width: 'auto',
        render: ({ key }) => {
            return (
                state.keys.length > 2 &&
                _.first(state.keys) !== key &&
                _.last(state.keys) !== key && (
                    <Popconfirm
                        onConfirm={() => onDelete(key)}
                        title={<FormattedMessage id='add_order_form.delete_confirm' />}
                    >
                        <DeleteOutlined className={Styles.deleteIcon} />
                    </Popconfirm>
                )
            );
        }
    };

    return [statusCol, dateCol, stationCol, timeCol, durationCol, deleteCol];
}
