import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { StoreProductsContainer } from 'containers';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

export default class ProductsGroupsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI(
            'GET',
            '/helps',
            { helpId: 'directories_and_settings_products-groups' },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { helperDrawerOpen, allLinks } = this.state;

        return (
            <Layout
                controls={
                    <div>
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={async () => {
                                this.setState({
                                    helperDrawerOpen: true
                                });
                                await this.fetchHelperLinks();
                            }}
                            style={{
                                fontSize: 22,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </div>
                }
                title={<FormattedMessage id='navigation.products_groups' />}
            >
                <StoreProductsContainer />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
