import { emitError } from 'core/ui/duck';
import { selectDateRange } from 'modals/DebtZeroingModal/redux/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchOrdersSuccess,
    fetchSelectedOrderSuccess,
    FETCH_ORDERS,
    FETCH_SELECTED_ORDER,
    selectOrdersFilters,
    selectSelectedOrderId,
    selectSelectedOrderStatus,
    setFetchingOrders,
    setFetchingSelectedOrder
} from './duck';

export function* fetchOrdersSaga() {
    while (true) {
        try {
            yield take(FETCH_ORDERS);

            yield put(setFetchingOrders(true));
            const filters = yield select(selectOrdersFilters);
            const dataRange = yield select(selectDateRange);
            const orderStatus = yield select(selectSelectedOrderStatus);

            const response = yield call(fetchAPI, 'GET', 'debt_zeroing_orders', {
                filters: {
                    ...filters,
                    startDate: dataRange.startDate,
                    endDate: dataRange.endDate,
                    status: orderStatus
                }
            });

            const { orders, stats: ordersStats } = response;

            yield put(fetchOrdersSuccess({ orders, ordersStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingOrders(false));
        }
    }
}

export function* fetchSelectedOrderSaga() {
    while (true) {
        try {
            yield take(FETCH_SELECTED_ORDER);

            yield put(setFetchingSelectedOrder(true));
            const selectedOrderId = yield select(selectSelectedOrderId);

            const response = yield call(fetchAPI, 'GET', `orders/${selectedOrderId}`);

            yield put(fetchSelectedOrderSuccess({ selectedOrder: response }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingSelectedOrder(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchOrdersSaga), call(fetchSelectedOrderSaga)]);
}
