import { Col, Row } from 'antd';
import _ from 'lodash';
import { selectOrderStats } from 'modals/OrderStatsInfoModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    orderStats: selectOrderStats(state)

    // mode: selectMode(state),
    // clientSearchType: selectClientSearchType(state),
    // selectedClientId: selectSelectedClientId(state),
    // selectedClient: selectSelectedClient(state),
    // clients: selectClients(state),
    // fetchingSelectedClient: selectFetchingSelectedClient(state),
});

const mapDispatchToProps = {
    // setClientSearchType,
    // setClientsFilters,
    // setOrdersFilters,
    // setStoreDocsFilters,
    // setSelectedClientId,
};

/**
 * If client is selected this component generates block with its basic
 * information and ability to remove currently selected client(make no one selected)
 *
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientVehicleVisualizer extends React.Component {
    render() {
        const { orderStats } = this.props;

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col span={4}></Col>
                    <Col className={Styles.col} span={8}>
                        <Link to={`${book.client}/${orderStats.clientId}`}>
                            <span>{`${_.get(orderStats, 'clientFullName', '')}`}</span>
                        </Link>
                        {orderStats.phones &&
                            _.map(orderStats.phones, (phone, index) => {
                                return (
                                    <div key={index}>
                                        <a className={Styles.phoneNumber} href={`tel:${phone}`}>
                                            {phone}
                                        </a>
                                    </div>
                                );
                            })}
                    </Col>
                    <Col className={Styles.col} span={8}>
                        <div className={Styles.vehicle}>
                            <Link to={`${book.vehicle}/${orderStats.vehicleId}`}>
                                {`${orderStats.vehicleMake || ''} ${
                                    orderStats.vehicleModel || ''
                                } ${orderStats.vehicleYear || ''}`}
                                {/* { `${_.get(orderStats, 'vehicleMake', ' ')} ${_.get(orderStats, 'vehicleModel', ' ')} ${_.get(orderStats, 'vehicleYear', ' ')}` } */}
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
