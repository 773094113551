/* eslint-disable complexity */
import dayjs from 'dayjs';
import { get } from 'lodash';
import { createSelector } from 'reselect';
/**
 * Constants
 * */
export const moduleName = 'dashboard';
const prefix = `cpb/${moduleName}`;

export const INIT_DASHBOARD = `${prefix}/INIT_DASHBOARD`;
export const INIT_DASHBOARD_SUCCESS = `${prefix}/INIT_DASHBOARD_SUCCESS`;

export const REFRESH_DASHBOARD = `${prefix}/REFRESH_DASHBOARD`;
export const FETCH_DASHBOARD_SUCCESS = `${prefix}/FETCH_DASHBOARD_SUCCESS`;

export const FETCH_DASHBOARD_CALENDAR = `${prefix}/FETCH_DASHBOARD_CALENDAR`;
export const FETCH_DASHBOARD_CALENDAR_SUCCESS = `${prefix}/FETCH_DASHBOARD_CALENDAR_SUCCESS`;

export const FETCH_DASHBOARD_STATIONS = `${prefix}/FETCH_DASHBOARD_STATIONS`;
export const FETCH_DASHBOARD_STATIONS_SUCCESS = `${prefix}/FETCH_DASHBOARD_STATIONS_SUCCESS`;

export const FETCH_DASHBOARD_EMPLOYEES = `${prefix}/FETCH_DASHBOARD_EMPLOYEES`;
export const FETCH_DASHBOARD_EMPLOYEES_SUCCESS = `${prefix}/FETCH_DASHBOARD_EMPLOYEES_SUCCESS`;

export const DROP_DASHBOARD_ORDER = `${prefix}/DROP_DASHBOARD_ORDER}`;
export const DROP_DASHBOARD_ORDER_SUCCESS = `${prefix}/DROP_DASHBOARD_ORDER_SUCCESS}`;

export const SET_DASHBOARD_MODE = `${prefix}/SET_DASHBOARD_MODE`;
export const SET_DASHBOARD_STATIONS_DATE = `${prefix}/SET_DASHBOARD_STATIONS_DATE`;
export const SET_DASHBOARD_EMPLOYEES_DATE = `${prefix}/SET_DASHBOARD_EMPLOYEES_DATE`;
export const SET_DASHBOARD_BUSINESS_ID = `${prefix}/SET_DASHBOARD_BUSINESS_ID`;
export const SET_DASHBOARD_WEEK_DATES = `${prefix}/SET_DASHBOARD_WEEK_DATES`;

export const LINK_TO_DASHBOARD_STATIONS = `${prefix}/LINK_TO_DASHBOARD_STATIONS`;

export const LINK_TO_DASHBOARD_EMPLOYEES = `${prefix}/LINK_TO_DASHBOARD_EMPLOYEES`;

export const UPDATE_DASHBOARD_ORDER = `${prefix}/UPDATE_DASHBOARD_ORDER`;
export const UPDATE_DASHBOARD_ORDER_SUCCESS = `${prefix}/UPDATE_DASHBOARD_ORDER_SUCCESS`;

export const UPDATE_DASHBOARD_SERVICE = `${prefix}/UPDATE_DASHBOARD_SERVICE`;
export const UPDATE_DASHBOARD_SERVICE_SUCCESS = `${prefix}/UPDATE_DASHBOARD_SERVICE_SUCCESS`;

export const TRANSFER_OUTDATED_REPAIRS = `${prefix}/TRANSFER_OUTDATED_REPAIRS`;
export const TRANSFER_OUTDATED_REPAIRS_SUCCESS = `${prefix}/TRANSFER_OUTDATED_REPAIRS_SUCCESS`;

export const SET_DASHBOARD_FILTERS = `${prefix}/SET_DASHBOARD_FILTERS`;

/**
 * Reducer
 * */

const ReducerState = {
    beginTime: null,
    endTime: null,
    businessId: null,
    days: [],
    orders: [],
    stations: [],
    employees: [],
    mode: 'stations',
    schedule: {},
    date: dayjs(),
    startDate: dayjs().startOf('week').add(1, 'day'),
    endDate: dayjs().endOf('week').add(1, 'day'),
    load: [],
    daysWithConflicts: [],
    stationsWithConflicts: [],
    employeesWithConflicts: [],
    employeesForFilter: [],
    managersForFilter: [],
    clientsForFilter: [],
    filters: {
        filterManager: undefined,
        filterEmployee: undefined,
        filterClient: undefined
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_DASHBOARD_MODE:
            return {
                ...state,
                mode: payload,
                load: []
            };

        case REFRESH_DASHBOARD:
            return {
                ...state,
                load: [],
                days: [],
                orders: [],
                stations: [],
                employees: []
            };

        case SET_DASHBOARD_STATIONS_DATE:
            return {
                ...state,
                date: payload
            };

        case SET_DASHBOARD_EMPLOYEES_DATE:
            return {
                ...state,
                date: payload
            };

        case SET_DASHBOARD_BUSINESS_ID:
            return {
                ...state,
                businessId: payload
            };

        case SET_DASHBOARD_WEEK_DATES:
            return {
                ...state,
                startDate: payload.startDate,
                endDate: payload.endDate
            };

        case INIT_DASHBOARD:
            return {
                ...state,
                mode: 'stations',
                load: []
            };

        case INIT_DASHBOARD_SUCCESS:
            return {
                ...state,
                ...payload
            };

        case FETCH_DASHBOARD_CALENDAR_SUCCESS:
            return {
                ...state,
                ...payload
            };

        case FETCH_DASHBOARD_STATIONS_SUCCESS:
            return {
                ...state,
                ...payload
            };

        case FETCH_DASHBOARD_EMPLOYEES_SUCCESS:
            return {
                ...state,
                ...payload
            };

        case FETCH_DASHBOARD_SUCCESS:
            return {
                ...state,
                load: payload
            };

        case SET_DASHBOARD_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload
                }
            };

        default:
            return state;
    }
}

/**
 * Selectors
 * */

export const stateSelector = state => state[moduleName];

export const selectDashboardMode = state => state.dashboard.mode;
export const selectDashboardDate = state => state.dashboard.date;
export const selectDashboardStartDate = state => state.dashboard.startDate;
export const selectDashboardEndDate = state => state.dashboard.endDate;
export const selectDashboardFilters = state => state.dashboard.filters;
export const selectDashboardBusinessId = state => state.dashboard.businessId;

// const getDashboard = state => state.dashboard;

export const selectDasboardData = createSelector([stateSelector], ({ schedule, mode, stations, employees, days }) => {
    const time = [...Array(schedule.endHour).keys()]
        .map((_, index) => index + 1)
        .slice(schedule.beginHour - 1)
        .map(time => (time >= 10 ? `${time}:00` : `0${time}:00`));

    const rows = time.length * 2;
    const columns = mode === 'calendar' ? days.length : mode === 'employees' ? get(employees, 'length', 0) : get(stations, 'length', 0);

    const dashboard = { rows, columns };

    return {
        time,
        mode,
        dashboard
    };
});

/**
 * Action Creators
 * */

export const initDashboard = () => ({
    type: INIT_DASHBOARD
});

export const initDashboardSuccess = data => ({
    type: INIT_DASHBOARD_SUCCESS,
    payload: data
});

export const fetchDashboardCalendar = () => ({
    type: FETCH_DASHBOARD_CALENDAR
});

export const fetchDashboardStations = () => ({
    type: FETCH_DASHBOARD_STATIONS
});

export const fetchDashboardEmployees = () => ({
    type: FETCH_DASHBOARD_EMPLOYEES
});

export const fetchDashboardCalendarSuccess = data => ({
    type: FETCH_DASHBOARD_CALENDAR_SUCCESS,
    payload: data
});

export const fetchDashboardStationsSuccess = data => ({
    type: FETCH_DASHBOARD_STATIONS_SUCCESS,
    payload: data
});

export const fetchDashboardEmployeesSuccess = data => ({
    type: FETCH_DASHBOARD_EMPLOYEES_SUCCESS,
    payload: data
});

export const setDashboardMode = mode => ({
    type: SET_DASHBOARD_MODE,
    payload: mode
});

export const setDashboardStationsDate = date => ({
    type: SET_DASHBOARD_STATIONS_DATE,
    payload: date
});

export const setDashboardEmployeesDate = date => ({
    type: SET_DASHBOARD_EMPLOYEES_DATE,
    payload: date
});

export const setDashboardBusinessId = businessId => ({
    type: SET_DASHBOARD_BUSINESS_ID,
    payload: businessId
});

export const setDashboardWeekDates = ({ startDate, endDate }) => ({
    type: SET_DASHBOARD_WEEK_DATES,
    payload: { startDate, endDate }
});

export const dropDashboardOrder = () => ({
    type: DROP_DASHBOARD_ORDER
});

export const dropDashboardOrderSuccess = () => ({
    type: DROP_DASHBOARD_ORDER_SUCCESS
});

export const linkToDashboardStations = day => ({
    type: LINK_TO_DASHBOARD_STATIONS,
    payload: day
});

export const linkToDashboardEmployees = day => ({
    type: LINK_TO_DASHBOARD_EMPLOYEES,
    payload: day
});

export const updateDashboardOrder = order => ({
    type: UPDATE_DASHBOARD_ORDER,
    payload: order
});

export const updateDashboardService = order => ({
    type: UPDATE_DASHBOARD_SERVICE,
    payload: order
});

export const updateDashboardOrderSuccess = () => ({
    type: UPDATE_DASHBOARD_ORDER_SUCCESS
});

export const transferOutdateRepairs = () => ({
    type: TRANSFER_OUTDATED_REPAIRS
});

export const transferOutdateRepairsSuccess = () => ({
    type: TRANSFER_OUTDATED_REPAIRS_SUCCESS
});

export const refreshDashboard = () => ({
    type: REFRESH_DASHBOARD
});

export const fetchDashboardSuccess = data => ({
    type: FETCH_DASHBOARD_SUCCESS,
    payload: data
});

export const setDashboarFilter = filter => ({
    type: SET_DASHBOARD_FILTERS,
    payload: filter
});
