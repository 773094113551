/*
Container used to show clients and perform basic search of them.
*/
import { Input, notification, Table } from 'antd';
import { MODALS, setModal } from 'core/modals/duck';
import { handleError } from 'core/ui/duck';
import BrandsCrossesContainer from 'pages/DirectoryBrandsPage/BrandsCrossesContainer';
import {
    deleteBrand,
    fetchBrandCrosses,
    fetchBrands,
    selectBrands,
    selectBrandsStats,
    selectExpandedBrandRow,
    selectFilters,
    selectSort,
    setBrandRowKey,
    setBrandsCrossesFilters,
    setBrandsFilters
} from 'pages/DirectoryBrandsPage/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentDuckErrors, handleCurrentDuckErrors } from 'utils';
import getErrorConfigs from '../error_configs';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    brands: selectBrands(state),

    stats: selectBrandsStats(state),
    filters: selectFilters(state),
    sort: selectSort(state),
    expandedBrandRow: selectExpandedBrandRow(state),
    errors: state.ui.errors
});

const mapDispatchToProps = {
    setModal,
    fetchBrands,
    setBrandsFilters,
    deleteBrand,
    setBrandRowKey,
    fetchBrandCrosses,
    setBrandsCrossesFilters,
    handleError
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
@withRouter
export default class BrandsContainer extends React.Component {
    constructor(props) {
        super(props);

        this._source = 'brandsContainer';
        this._errorConfigs = getErrorConfigs(props.intl);

        this.handleSearch = _.debounce(value => {
            this.props.setBrandsFilters({ query: value.replace(/[+()]/g, ''), page: 1 });
        }, 1000).bind(this);
    }

    onSearch = e => {
        const { value } = e.target;
        this.handleSearch(value);
    };

    onEditBrand = brandId => {
        this.props.setModal(MODALS.BRANDS_MODAL, {
            mode: 'EDIT',
            brandId
        });
    };

    onAddBrandCross = brandId => {
        this.props.setBrandsCrossesFilters({ brandId });

        this.props.setModal(MODALS.BRANDS_CROSS_MODAL, {
            mode: 'ADD',
            brandId
        });
    };

    onDeleteBrand = brandId => {
        this.props.deleteBrand(brandId);
    };

    componentDidMount() {
        this.props.fetchBrands();
    }

    render() {
        const {
            brands,
            stats,
            filters,
            expandedBrandRow,
            setBrandRowKey,
            fetchBrandCrosses,
            setBrandsCrossesFilters,

            errors,
            intl,
            handleError
        } = this.props;

        const duckErrors = getCurrentDuckErrors(errors, this._errorConfigs, this._source);
        handleCurrentDuckErrors(notification, duckErrors, intl, handleError);

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / 25) * 25,
            hideOnSinglePage: true,
            current: filters.page,
            onChange: page => {
                this.props.setBrandsFilters({ page });
            }
        };

        return (
            <div>
                <div className={Styles.filtersCont}>
                    <div className={Styles.textCont}>
                        <FormattedMessage id='client_hot_operations_page.search' />:{' '}
                    </div>
                    <div className={Styles.inputCont}>
                        <Input
                            allowClear
                            defaultValue={this.initialSearchQuery}
                            onChange={e => this.onSearch(e)}
                        />
                    </div>
                </div>

                <div>
                    <Table
                        bordered
                        className={Styles.table}
                        columns={columnsConfig({
                            onEditBrand: this.onEditBrand,
                            onDeleteBrand: this.onDeleteBrand,
                            onAddBrandCross: this.onAddBrandCross
                        })}
                        dataSource={brands}
                        expandedRowKeys={[expandedBrandRow]}
                        expandedRowRender={() => <BrandsCrossesContainer />}
                        onExpand={(expanded, brand) => {
                            expanded &&
                                setBrandsCrossesFilters({ brandId: brand.brandId, page: 1 });
                            expanded && setBrandRowKey(`${brand.brandId}`);
                            !expanded && setBrandRowKey('');
                        }} // Only one row can be expanded at the time
                        pagination={pagination}
                        rowKey={brand => `${brand.brandId}`}
                        scroll={{ x: 800, y: '70vh' }}
                    />
                </div>
            </div>
        );
    }
}
