import { Skeleton, Spin } from 'antd';
import React from 'react';
import Styles from './styles.m.css';

/**
 * Component supposed to represent a block of data and controls, it has title,
 * controls bar(for buttons or icons) and place for content under the title.
 *
 * @property { React.Node | String } children - data that has to be shown, content
 * @property { React.Node | String } [ title ] - title of a content block
 * @property { React.Node | String } [ controls ] - custom component where controls are located
 * @property { Boolean } [ loading ] - show loading
 * @property {*} [ className ] - custom className for component's main container, used to override default styles
 */
export default class Block extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, title, controls, loading, className } = this.props;

        const blockContent = loading ? (
            <div>
                <div className={Styles.spin}>
                    <Spin />
                </div>
                <Skeleton active />
            </div>
        ) : (
            <div>
                <div className={Styles.header}>
                    <div className={Styles.title}>{title}</div>
                    <div className={Styles.controls}>{controls}</div>
                </div>

                <div className={Styles.content}>{children}</div>
            </div>
        );

        return <div className={[Styles.block, className].join(' ')}>{blockContent}</div>;
    }
}
