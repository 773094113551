import { Form } from '@ant-design/compatible';
import { InputNumber } from 'antd';
import { Catcher, Numeral } from 'commons';
import { DecoratedInputNumber } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const FormItem = Form.Item;

@injectIntl
class DiscountPanel extends Component {
    shouldComponentUpdate(nextProps) {
        return !_.isEqual(nextProps, this.props);
    }

    async updateTimeMultiplier(multiplier) {
        this.laborTimeMultiplier = multiplier;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = API_URL;
        const params = `/orders/${this.props.orderId}`;
        url += params;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ laborTimeMultiplier: multiplier })
            });
            const result = await response.json();
        } catch (error) {
            console.error('ERROR:', error);
        }
    }

    _updateOrderField = field => {
        const { reloadOrderForm, orderId } = this.props;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${orderId}`;
        url += params;
        fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(field)
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                reloadOrderForm(undefined, 'all');

                return response.json();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    render() {
        const {
            form: { getFieldDecorator, getFieldsValue, getFieldValue },
            price,
            totalDetailsProfit,
            totalServicesProfit,
            discountFieldName,
            fetchedOrder,
            forbidden,
            detailsMode,
            servicesMode,
            isServiceMarkupForbidden,
            laborTimeMultiplier,
            isMobile
        } = this.props;

        const discount =
            getFieldValue(discountFieldName) ||
            _.get(fetchedOrder, `order.${discountFieldName}`, 0);
        const total = Math.round((price - price * (discount / 100)) * 100) / 100;
        const profit = Math.round((price - total) * 100) / 100;
        const mask = '0,0.00';

        const field = getFieldsValue([discountFieldName]);

        return (
            <Catcher>
                {!isMobile ? (
                    <div className={Styles.discountPanel}>
                        <DecoratedInputNumber
                            className={Styles.formItem}
                            colon={false}
                            disabled={forbidden}
                            field={discountFieldName}
                            formatter={value => `${Math.round(value)}%`}
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            initialValue={_.get(fetchedOrder, `order.${discountFieldName}`) || 0}
                            label={<FormattedMessage id='order_form_table.discount' />}
                            max={100}
                            min={-100}
                            onChange={value => {
                                field[discountFieldName] = value;
                                this._updateOrderField(field);
                            }}
                            parser={value => value.replace('%', '')}
                            step={1}
                        />
                        <FormItem
                            className={Styles.formItem}
                            colon={false}
                            label={<FormattedMessage id='order_form_table.sum_without_discount' />}
                        >
                            <InputNumber
                                decimalSeparator=','
                                disabled
                                formatter={value =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                }
                                min={0}
                                parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                                style={{ color: 'black' }}
                                value={price}
                            />
                        </FormItem>
                        {(detailsMode || servicesMode) && (
                            <FormItem
                                className={Styles.formItem}
                                colon={false}
                                label={<FormattedMessage id='order_form_table.discount' />}
                            >
                                <InputNumber
                                    decimalSeparator=','
                                    disabled
                                    formatter={value =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                    }
                                    parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                                    style={{ color: total < 0 ? 'red' : 'black' }}
                                    value={profit}
                                />
                            </FormItem>
                        )}
                        <FormItem
                            className={Styles.formItem}
                            colon={false}
                            label={<FormattedMessage id='order_form_table.total_sum' />}
                        >
                            <InputNumber
                                decimalSeparator=','
                                disabled
                                formatter={value =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                }
                                min={0}
                                parser={value => `${value}`.replace(/\$\s?|(\s)/g, '')}
                                style={{ color: 'black' }}
                                value={total}
                            />
                        </FormItem>
                    </div>
                ) : (
                    <div className={Styles.mobileDiscountPanel}>
                        <div className={Styles.mobileDiscount}>
                            <FormattedMessage id='order_form_table.discount' />
                            <DecoratedInputNumber
                                className={Styles.mobileFormItem}
                                colon={false}
                                disabled={forbidden}
                                field={discountFieldName}
                                formatter={value => `${Math.round(value)}%`}
                                formItem
                                getFieldDecorator={getFieldDecorator}
                                initialValue={
                                    _.get(fetchedOrder, `order.${discountFieldName}`) || 0
                                }
                                max={100}
                                min={-100}
                                onChange={value => {
                                    field[discountFieldName] = value;
                                    this._updateOrderField(field);
                                }}
                                parser={value => value.replace('%', '')}
                                step={1}
                            />
                        </div>
                        <div className={Styles.mobileSumBlock}>
                            <div className={Styles.mobileSumRow}>
                                <FormattedMessage id='order_form_table.sum_without_discount' />
                                <Numeral
                                    className={Styles.sumNumeral}
                                    currency={this.props.intl.formatMessage({
                                        id: 'currency'
                                    })}
                                    mask={mask}
                                    nullText='0'
                                >
                                    {Number(price)}
                                </Numeral>
                            </div>
                            <div className={Styles.mobileSumRow}>
                                <FormattedMessage id='order_form_table.discount' />
                                <Numeral
                                    className={Styles.sumNumeral}
                                    currency={this.props.intl.formatMessage({
                                        id: 'currency'
                                    })}
                                    mask={mask}
                                    nullText='0'
                                >
                                    {Number(profit)}
                                </Numeral>
                            </div>
                            <div className={Styles.mobileSumRow}>
                                <FormattedMessage id='order_form_table.total_sum' />
                                <Numeral
                                    className={Styles.sumNumeral}
                                    currency={this.props.intl.formatMessage({
                                        id: 'currency'
                                    })}
                                    mask={mask}
                                    nullText='0'
                                >
                                    {Number(total)}
                                </Numeral>
                            </div>
                        </div>
                    </div>
                )}
            </Catcher>
        );
    }
}

export default DiscountPanel;
