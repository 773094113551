import { Col, Input, Row, Select } from 'antd';
import _ from 'lodash';
import { MODES } from 'modals/RefactoredCashOrderModal/constants';
import { selectMode } from 'modals/RefactoredCashOrderModal/redux/duck';
import {
    selectFetchingSuppliers,
    selectSelectedSupplierId,
    setSelectedSupplierId
} from 'modals/RefactoredCashOrderModal/redux/suppliers/duck';
import {
    fetchSuppliersStoreDocs,
    selectSelectedSupplierStoreDocId,
    selectSuppliersStoreDocsFilters,
    setSuppliersStoreDocsFilters,
    setSuppliersStoreDocsFiltersPoor
} from 'modals/RefactoredCashOrderModal/redux/suppliersStoreDocs/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { SupplierStoreDocVisualizer, SuppliersStoreDocsTable } from './components';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    fetchingSuppliers: selectFetchingSuppliers(state),
    selectedSupplierId: selectSelectedSupplierId(state),
    selectedSupplierStoreDocId: selectSelectedSupplierStoreDocId(state),
    suppliersStoreDocsFilters: selectSuppliersStoreDocsFilters(state),
    mode: selectMode(state)
});

const mapDispatchToProps = {
    setSelectedSupplierId,
    setSuppliersStoreDocsFilters,
    setSuppliersStoreDocsFiltersPoor,
    fetchSuppliersStoreDocs
};

/**
 * Handle selection of supplier and its store docs
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SupplierCounterpartyContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suppliers: []
        };

        // Fetch suppliers debounce
        this.handleSearch = _.debounce(() => {
            const { fetchSuppliersStoreDocs } = this.props;
            fetchSuppliersStoreDocs();
        }, 800);
    }

    componentDidMount() {
        this.fetchSuppliers();
    }

    handleSearchSuppliers = _.debounce(value => {
        this.fetchSuppliers(value, true);
    }, 1000);

    fetchSuppliers = async (query, all = true) => {
        const suppliers = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                query,
                all
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            suppliers
        });
    };

    render() {
        const {
            mode,
            fetchingSuppliers,

            selectedSupplierId,
            selectedSupplierStoreDocId,
            suppliersStoreDocsFilters,

            setSelectedSupplierId,
            setSuppliersStoreDocsFilters,
            setSuppliersStoreDocsFiltersPoor,
            editable,

            intl: { formatMessage }
        } = this.props;

        const { suppliers } = this.state;

        const disabled = Boolean(mode != MODES.ADD);

        let storeDocBody = null; // Which component to render
        if (selectedSupplierStoreDocId) {
            storeDocBody = <SupplierStoreDocVisualizer />;
        } else if (!disabled || editable) {
            storeDocBody = <SuppliersStoreDocsTable />;
        }

        return (
            <div className={Styles.mainContainer}>
                <Row>
                    <Col className={Styles.col} span={12}>
                        <div>
                            <Select
                                allowClear
                                disabled={!editable && (disabled || fetchingSuppliers)}
                                dropdownStyle={{ position: 'fixed', zIndex: '9999' }}
                                filterOption={(input, option) => {
                                    return (
                                        String(_.get(option, 'props.label', ''))
                                            .toLowerCase()
                                            .indexOf(String(input || '').toLowerCase()) >= 0
                                    );
                                }}
                                loading={fetchingSuppliers}
                                onChange={supplierId => {
                                    setSelectedSupplierId(supplierId);
                                    setSuppliersStoreDocsFilters({
                                        counterpartBusinessSupplierId: supplierId
                                    });
                                }}
                                onSearch={input => this.handleSearchSuppliers(input)}
                                placeholder={formatMessage({
                                    id: 'refactored_cash_order_modal.placeholder_supplier'
                                })}
                                showSearch
                                value={selectedSupplierId}
                            >
                                {_.map(suppliers, supplier => {
                                    return (
                                        <Option key={supplier.id} label={`${supplier.name || ''}`} value={supplier.id}>
                                            {`${supplier.name || ''}`}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </Col>
                    <Col className={Styles.col} span={12}>
                        <div title='What are you looking for here?'>
                            <Input
                                data-qa='input_search'
                                disabled={disabled}
                                onChange={e => {
                                    setSuppliersStoreDocsFiltersPoor({
                                        query: _.toLower(String(e.target.value || '').replace(/[+()]/g, ''))
                                    });
                                    this.handleSearch();
                                }}
                                placeholder={formatMessage({
                                    id: 'refactored_cash_order_modal.placeholder_search'
                                })}
                                value={_.get(suppliersStoreDocsFilters, 'query')}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>{storeDocBody}</Col>
                </Row>
            </div>
        );
    }
}
