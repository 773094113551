import breakScheduleFormReducer, {
    moduleName as breakScheduleFormModule
} from 'core/forms/breakScheduleForm/duck';
import cashOrderFormReducer, { moduleName as cashOrderForm } from 'core/forms/cashOrderForm/duck';
import cashOrdersFiltersReducer, {
    moduleName as cashOrdersFilters
} from 'core/forms/cashOrdersFiltersForm/duck';
import editClientReducer, { moduleName as editClient } from 'core/forms/editClientForm/duck';
import employeeFormReducer, {
    moduleName as employeeFormModule
} from 'core/forms/employeeForm/duck';
import profileReducer, { moduleName as profile } from 'core/forms/profileForm/duck';
import reportAnalyticsFormReducer, {
    moduleName as reportAnalyticsForm
} from 'core/forms/reportAnalyticsForm/duck';
import scheduleFormReducer, {
    moduleName as scheduleFormModule
} from 'core/forms/scheduleForm/duck';
import servicesReducer, { moduleName as services } from 'core/forms/servicesForm/duck';
import setDetailProductReducer, {
    moduleName as setDetailProduct
} from 'core/forms/setDetailProduct/duck';
import spreadBusinessBrandsReducer, {
    moduleName as spreadBusinessBrands
} from 'core/forms/spreadBusinessBrands/duck';
import { combineReducers } from 'redux';
import addBusinessPackageReducer, {
    moduleName as addBusinessPackage
} from './addBusinessPackageForm/duck';
import addClientReducer, { moduleName as addClient } from './addClientForm/duck';
import addClientRequisiteReducer, {
    moduleName as addClientRequisite
} from './addClientRequisiteForm/duck';
import addClientVehicleReducer, {
    moduleName as addClientVehicle
} from './addClientVehicleForm/duck';
import addPackageReducer, { moduleName as addPackage } from './addPackageForm/duck';
import addRoleReducer, { moduleName as addRole } from './addRoleForm/duck';
import brandsReducer, { moduleName as brands } from './brandsForm/duck';
import businessPackageReducer, { moduleName as businessPackage } from './businessPackageForm/duck';
import cancelReasonReducer, { moduleName as cancelReason } from './cancelReasonForm/duck';
import editClientRequisiteReducer, {
    moduleName as editClientRequisite
} from './editClientRequisiteForm/duck';
import editClientVehicleReducer, {
    moduleName as editClientVehicle
} from './editClientVehicleForm/duck';
import editPackageReducer, { moduleName as editPackage } from './editPackageForm/duck';
import editRoleReducer, { moduleName as editRole } from './editRoleForm/duck';
import loginReducer, { moduleName as login } from './loginForm/duck';
import managerRoleReducer, { moduleName as managerRole } from './managerRoleForm/duck';
import orderReducer, { moduleName as order } from './orderForm/duck';
import orderTaskReducer, { moduleName as orderTask } from './orderTaskForm/duck';
import salaryReportReducer, { moduleName as salaryReport } from './salaryReportForm/duck';
import settingSalaryReducer, { moduleName as settingSalary } from './settingSalaryForm/duck';
import switchBusinessReducer, { moduleName as switchBusiness } from './switchBusinessForm/duck';
import tecDocReducer, { moduleName as tecDoc } from './tecDocForm/duck';
import toSuccessReducer, { moduleName as toSuccess } from './toSuccessForm/duck';
import universalFiltersReducer, {
    moduleName as universalFilters
} from './universalFiltersForm/duck';

// combine all forms reducers to forms reducer in store
export const formsReducer = combineReducers({
    [addBusinessPackage]: addBusinessPackageReducer,
    [addClient]: addClientReducer,
    [addClientRequisite]: addClientRequisiteReducer,
    [addClientVehicle]: addClientVehicleReducer,
    [addPackage]: addPackageReducer,
    [addRole]: addRoleReducer,
    [brands]: brandsReducer,
    [breakScheduleFormModule]: breakScheduleFormReducer,
    [businessPackage]: businessPackageReducer,
    [cancelReason]: cancelReasonReducer,
    [cashOrderForm]: cashOrderFormReducer,
    [cashOrdersFilters]: cashOrdersFiltersReducer,
    [editClient]: editClientReducer,
    [editClientRequisite]: editClientRequisiteReducer,
    [editClientVehicle]: editClientVehicleReducer,
    [editPackage]: editPackageReducer,
    [editRole]: editRoleReducer,
    [employeeFormModule]: employeeFormReducer,
    [login]: loginReducer,
    [managerRole]: managerRoleReducer,
    [order]: orderReducer,
    [orderTask]: orderTaskReducer,
    [profile]: profileReducer,
    [salaryReport]: salaryReportReducer,
    [scheduleFormModule]: scheduleFormReducer,
    [services]: servicesReducer,
    [setDetailProduct]: setDetailProductReducer,
    [settingSalary]: settingSalaryReducer,
    [spreadBusinessBrands]: spreadBusinessBrandsReducer,
    [switchBusiness]: switchBusinessReducer,
    [tecDoc]: tecDocReducer,
    [toSuccess]: toSuccessReducer,
    [universalFilters]: universalFiltersReducer,
    [reportAnalyticsForm]: reportAnalyticsFormReducer
});
