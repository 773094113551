import { Button, Table } from 'antd';
import { Catcher } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Styles from './styles.m.css';

@injectIntl
class ClientsAggregatesTable extends Component {
    constructor(props) {
        super(props);

        const {
            intl: { formatMessage }
        } = this.props;

        this.columns = () => [
            {
                title: formatMessage({
                    id: 'clients-page.aggregate'
                }),
                dataIndex: 'aggregateName',
                key: 'aggregate'
            },
            {
                title: formatMessage({
                    id: 'brand'
                }),
                dataIndex: 'brandName',
                key: 'brand'
            },
            {
                title: formatMessage({
                    id: 'clients-page.aggregate_number'
                }),
                dataIndex: 'aggregateNumber',
                key: 'number'
            },
            {
                title: formatMessage({
                    id: 'add_order_form.vin'
                }),
                dataIndex: 'vin',
                key: 'vin'
            },
            {
                title: formatMessage({
                    id: 'clients-page.quantity'
                }),
                dataIndex: 'count',
                key: 'count'
            },
            {
                title: formatMessage({
                    id: 'comment'
                }),
                dataIndex: 'comment',
                key: 'comment'
            },
            {
                title: formatMessage({ id: 'edit' }),
                key: 'edit',
                render: row => (
                    <Button
                        onClick={() => {
                            this.props.editArr(row);
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='edit' />
                    </Button>
                )
            },
            {
                title: formatMessage({
                    id: 'add_client_form.delete_vehicle'
                }),
                key: 'delete',
                render: ({ index }) => (
                    <Button
                        onClick={() => {
                            this.props.deleteArr(index);
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='add_client_form.delete_vehicle' />
                    </Button>
                )
            }
        ];
    }

    render() {
        const { aggregates } = this.props;

        return (
            <Catcher>
                <Table
                    bordered
                    className={Styles.clientsVehiclesTable}
                    columns={this.columns()}
                    dataSource={aggregates.map((aggregate, index) => ({
                        ...aggregate,
                        index
                    }))}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    rowKey='aggregateId'
                    size='small'
                />
            </Catcher>
        );
    }
}

export default ClientsAggregatesTable;
