import { Table } from 'antd';
import { Catcher } from 'commons';
import {
    deleteExpenseDoc,
    fetchExpenses,
    selectExpenses,
    selectExpensesFilters,
    selectExpensesLoading,
    setExpensesPage
} from 'core/storage/expenses';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import columnsConfig from './columns';

const ExpensesTableComponent = props => {
    const { expenses } = props;

    useEffect(() => {
        props.fetchExpenses();
    }, []); // add expenses -> recursion

    const pagination = {
        pageSize: 25,
        size: 'large',
        total: Math.ceil(_.get(expenses, 'stats.count', 0) / 25) * 25,
        hideOnSinglePage: true,
        current: props.filters.page,
        onChange: page => {
            props.setExpensesPage(page);
            props.fetchExpenses();
        }
    };

    return (
        <Catcher>
            <StyledTable
                columns={columnsConfig(props)}
                dataSource={props.expenses.list}
                loading={props.loading}
                locale={{
                    emptyText: props.intl.formatMessage({ id: 'no_data' })
                }}
                pagination={pagination}
                rowKey={record => record.id}
                size='small'
            />
        </Catcher>
    );
};

const StyledTable = styled(Table)`
    background: white;
`;

const mapStateToProps = state => ({
    user: state.auth,
    expenses: selectExpenses(state),
    filters: selectExpensesFilters(state),
    loading: selectExpensesLoading(state)
});

const mapDispatchToProps = {
    fetchExpenses,
    deleteExpenseDoc,
    setExpensesPage
};

export const ExpensesTable = injectIntl(connect(mapStateToProps, mapDispatchToProps)(ExpensesTableComponent));
