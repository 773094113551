import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { all, call, put, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { fetchOrderStatsSuccess, FETCH_ORDER_STATS } from './duck';

export function* fetchOrderStatsSaga() {
    while (true) {
        try {
            const {
                payload: { id, recalculateStats }
            } = yield take(FETCH_ORDER_STATS);

            // yield put(setFetching(true));
            //
            // const filters = yield select(selectFilters)

            yield nprogress.start();

            const data = yield call(fetchAPI, 'GET', 'order_stats', {
                orderId: id,
                recalculateStats
            });

            yield put(fetchOrderStatsSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            // yield put(setFetching(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchOrderStatsSaga)]);
}
