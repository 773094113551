exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "*{box-sizing:border-box}.styles-m__mainCont---3jTPo{background-color:#fff}.styles-m__header---1gBGO{display:flex;flex-direction:row;justify-content:space-between;margin-bottom:25px}.styles-m__filterCont---3prfU{width:35%;background-color:initial}.styles-m__statsCont---3w7jN{background-color:#fff;padding-left:5px;width:65%;overflow:scroll}.styles-m__filterCont---3prfU,.styles-m__statsCont---3w7jN{display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,EACI,qBACJ,CAEA,4BACI,qBACJ,CAEA,0BACI,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAC9B,kBACJ,CAEA,8BACI,SAAU,CACV,wBACJ,CAEA,6BACI,qBAAuB,CACvB,gBAAiB,CACjB,SAAU,CACV,eACJ,CAEA,2DACI,YAAa,CACb,sBAAuB,CACvB,kBACJ","file":"styles.m.css","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\n.mainCont {\n    background-color: white;\n}\n\n.header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-bottom: 25px;\n}\n\n.filterCont {\n    width: 35%;\n    background-color: transparent;\n}\n\n.statsCont {\n    background-color: white;\n    padding-left: 5px;\n    width: 65%;\n    overflow: scroll;\n}\n\n.filterCont, .statsCont {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"]}]);

// Exports
exports.locals = {
	"mainCont": "styles-m__mainCont---3jTPo",
	"header": "styles-m__header---1gBGO",
	"filterCont": "styles-m__filterCont---3prfU",
	"statsCont": "styles-m__statsCont---3w7jN"
};