import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchCashOrdersSuccess,
    fetchStoreDocsDeliverySuccess,
    fetchStoreDocsSuccess,
    FETCH_CASH_ORDERS,
    FETCH_STORE_DOCS,
    FETCH_STORE_DOCS_DELIVERY,
    selectCashOrdersFilters,
    selectStoreDocsDeliveryFilters,
    selectStoreDocsFilters,
    setFetchingCashOrders,
    setFetchingStoreDocs,
    setFetchingStoreDocsDelivery
} from './duck';

export function* fetchStoreDocsSaga() {
    while (true) {
        try {
            yield take(FETCH_STORE_DOCS);

            yield put(setFetchingStoreDocs(true));
            const payloadFilters = yield select(selectStoreDocsFilters);

            const filters = {
                ...payloadFilters,
                createdFromDate: _.get(payloadFilters, 'createdFromDate')
                    ? payloadFilters.createdFromDate.format('YYYY-MM-DD')
                    : undefined,
                createdToDate: _.get(payloadFilters, 'createdToDate')
                    ? payloadFilters.createdToDate.format('YYYY-MM-DD')
                    : undefined
            };

            const response = yield call(fetchAPI, 'GET', '/business_supplier_dept_store_docs', {
                filters
            });
            const { storeDocs, stats: storeDocsStats } = response;

            yield put(fetchStoreDocsSuccess({ storeDocs, storeDocsStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingStoreDocs(false));
        }
    }
}

export function* fetchStoreDocsDeliverySaga() {
    while (true) {
        try {
            yield take(FETCH_STORE_DOCS_DELIVERY);

            yield put(setFetchingStoreDocsDelivery(true));
            const filters = yield select(selectStoreDocsDeliveryFilters);

            const response = yield call(fetchAPI, 'GET', '/store_docs', { ...filters });
            const { list: storeDocsDelivery, stats: storeDocsDeliveryStats } = response;

            yield put(fetchStoreDocsDeliverySuccess({ storeDocsDelivery, storeDocsDeliveryStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingStoreDocsDelivery(false));
        }
    }
}

export function* fetchCashOrdersSaga() {
    while (true) {
        try {
            yield take(FETCH_CASH_ORDERS);

            yield put(setFetchingCashOrders(true));
            const payloadFilters = yield select(selectCashOrdersFilters);

            const filters = {
                ...payloadFilters,
                createdFromDate: _.get(payloadFilters, 'createdFromDate')
                    ? payloadFilters.createdFromDate.format('YYYY-MM-DD')
                    : undefined,
                createdToDate: _.get(payloadFilters, 'createdToDate')
                    ? payloadFilters.createdToDate.format('YYYY-MM-DD')
                    : undefined
            };

            const response = yield call(fetchAPI, 'GET', '/business_supplier_dept_cash_orders', {
                filters
            });
            const { cashOrders, stats: cashOrdersStats } = response;

            yield put(fetchCashOrdersSuccess({ cashOrders, cashOrdersStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingCashOrders(false));
        }
    }
}

export function* saga() {
    yield all([
        call(fetchStoreDocsSaga),
        call(fetchCashOrdersSaga),
        call(fetchStoreDocsDeliverySaga)
    ]);
}
