exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__filtersBlock---2fYN0{border-top:1px solid #e8e8e8;margin:8px 0;padding:8px 0}.styles-m__filterElementWrap---1VJP9{display:flex;justify-content:space-between}.styles-m__radioGroup---2D0X0{width:65%}.styles-m__radioGroup---2D0X0>*{display:block;width:100%}.styles-m__optionWithInput---1gEKO{width:100%}.styles-m__optionWithInput---1gEKO>:nth-child(2){display:inline-flex;align-items:center;justify-content:space-between;width:100%}.styles-m__radioInput---2qmXk{display:inline-block;width:220px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACK,4BAA6B,CAC7B,YAAe,CACf,aACL,CAEA,qCACC,YAAa,CACV,6BACJ,CAEA,8BACC,SAKD,CAJC,gCACC,aAAc,CACd,UACD,CAGD,mCACC,UAQD,CANC,iDACI,mBAAoB,CACpB,kBAAmB,CACnB,6BAA8B,CACjC,UACD,CAGD,8BACC,oBAAqB,CACrB,WACD","file":"styles.m.css","sourcesContent":[".filtersBlock {\n\t    border-top: 1px solid #e8e8e8;\n\t    margin: 8px 0px;\n\t    padding: 8px 0px;\n}\n\n.filterElementWrap {\n\tdisplay: flex;\n    justify-content: space-between;\n}\n\n.radioGroup {\n\twidth: 65%;\n\t& > * {\n\t\tdisplay: block;\n\t\twidth: 100%;\n\t}\n}\n\n.optionWithInput {\n\twidth: 100%;\n\n\t& > *:nth-child(2) {\n\t    display: inline-flex;\n\t    align-items: center;\n\t    justify-content: space-between;\n\t\twidth: 100%;\n\t}\n}\n\n.radioInput {\n\tdisplay: inline-block;\n\twidth: 220px;\n}"]}]);

// Exports
exports.locals = {
	"filtersBlock": "styles-m__filtersBlock---2fYN0",
	"filterElementWrap": "styles-m__filterElementWrap---1VJP9",
	"radioGroup": "styles-m__radioGroup---2D0X0",
	"optionWithInput": "styles-m__optionWithInput---1gEKO",
	"radioInput": "styles-m__radioInput---2qmXk"
};