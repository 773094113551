import { Skeleton } from 'antd';
import { selectStoreMovementTotal, setStoreMovementFilters } from 'core/storage/storeMovement';
import { StoreProductsSelect } from 'forms/_formkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { numeralFormatter } from 'utils';

const mapStateToProps = state => ({
    collapsed: state.ui.collapsed,
    total: selectStoreMovementTotal(state)
});

export const StorageMovementTotals = connect(mapStateToProps, {
    setStoreMovementFilters
})(props => {
    const { total, collapsed } = props;

    const SkeletonLoader = <Skeleton active paragraph={{ rows: 1, width: 100 }} title={false} />;

    const renderTotalData = (label, data, income) => (
        <React.Fragment>
            <span>
                <FormattedMessage id={`storage.${label}`} />
                :&nbsp;
            </span>
            <Highlighted income={income}>
                {data ? numeralFormatter(Math.round(data * 100) / 100) : 0}
            </Highlighted>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <StoreProductsSelect
                filters={props.filters}
                setFilters={props.setStoreMovementFilters}
            />
            <MovementTotal collapsed={collapsed}>
                <DataGrid>
                    <DataWrapper>
                        {total
                            ? renderTotalData('income_price', total.incomePrice, true)
                            : SkeletonLoader}
                    </DataWrapper>
                    <DataWrapper>
                        {total
                            ? renderTotalData('quantity', total.incomeQuantity, true)
                            : SkeletonLoader}
                    </DataWrapper>
                    <DataWrapper>
                        {total
                            ? renderTotalData('income_sum', total.incomeSum, true)
                            : SkeletonLoader}
                    </DataWrapper>
                    <DataWrapper>
                        {total
                            ? renderTotalData('expense_price', total.expensePrice)
                            : SkeletonLoader}
                    </DataWrapper>
                    <DataWrapper>
                        {total
                            ? renderTotalData('quantity', total.expenseQuantity)
                            : SkeletonLoader}
                    </DataWrapper>
                    <DataWrapper>
                        {total ? renderTotalData('expense_sum', total.expenseSum) : SkeletonLoader}
                    </DataWrapper>
                </DataGrid>
            </MovementTotal>
        </React.Fragment>
    );
});

const MovementTotal = styled.div`
    display: flex;
    flex-direction: column;
    overflow: initial;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    margin-bottom: 24px;
    align-items: flex-end;
`;

const DataGrid = styled.div`
    width: 82%;
    margin-top: 12px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
`;

const DataWrapper = styled.div`
    display: flex;
    font-size: 20px;
    margin-right: 24px;
    justify-content: space-between;
`;

const Highlighted = styled.span`
    color: ${props => (props.income ? 'var(--secondary)' : 'var(--warning)')};
    font-weight: 700;
    font-size: 24px;
`;
