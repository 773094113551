import { Card, Skeleton } from 'antd';
import { SubscriptionProduct } from 'components';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import {
    asyncSubscribe,
    fetchSubscriptionProducts,
    selectSubscriptionProducts,
    verifyPromoCode
} from 'core/payments/duck';
import { PDFViewerModal, SubscribeModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

const GridCardSkeletonCSS = css`
    & > .ant-skeleton-header {
        padding: 0;

        & .ant-skeleton-avatar-lg {
            height: 350px;
            width: 100%;
        }
    }
`;

const mapStateToProps = state => ({
    products: selectSubscriptionProducts(state),
    user: state.auth,
    modal: state.modals.modal,
    modalProps: state.modals.modalProps,
    promoCode: state.payments.promoCode,
    subscribed: state.payments.subscribed
});

const mapDispatchToProps = {
    fetchSubscriptionProducts,
    verifyPromoCode,
    setModal,
    resetModal,
    asyncSubscribe
};

@connect(mapStateToProps, mapDispatchToProps)
export default class SubscriptionProductsContainer extends Component {
    componentDidMount() {
        this.props.fetchSubscriptionProducts();
    }

    _setModal = modalProps => this.props.setModal(MODALS.SUBSCRIBE, modalProps);

    _showDetails = () => this.props.setModal(MODALS.PDF_VIEWER);

    _renderBannersSkeleton = () => (
        <Skeleton
            active
            avatar={{ shape: 'square' }}
            css={GridCardSkeletonCSS}
            // loading={ this.props.loading }
            paragraph={0}
            title={false}
        />
    );

    _renderBannersGridSkeleton = () => (
        <Card bordered={false}>
            {this._renderBannersSkeleton()}
            {this._renderBannersSkeleton()}
            {this._renderBannersSkeleton()}
        </Card>
    );

    render() {
        return (
            <React.Fragment>
                <SubscriptionProductsGrid>
                    <Card title={<FormattedMessage id='subscription-table.pro' />}>
                        {this.props.products.rolePackages.map(
                            ({ id, name, price, description, rolesPackageId }) => (
                                <SubscriptionProduct
                                    key={id}
                                    description={description}
                                    name={name}
                                    price={price}
                                    rolesPackageId={rolesPackageId}
                                    setModal={() =>
                                        this._setModal({
                                            name,
                                            price,
                                            rolesPackageId,
                                            id
                                        })
                                    }
                                    showDetails={() => this._showDetails()}
                                />
                            )
                        )}
                    </Card>
                    <Card title={<FormattedMessage id='subscription-table.advertise' />}>
                        {this.props.products.suggestionGroup.map(
                            ({ id, name, price, description, suggestionGroupId }) => (
                                <SubscriptionProduct
                                    key={id}
                                    description={description}
                                    name={name}
                                    price={price}
                                    setModal={() =>
                                        this._setModal({
                                            name,
                                            price,
                                            suggestionGroupId,
                                            id
                                        })
                                    }
                                    showDetails={() => this._showDetails()}
                                    suggestionGroupId={suggestionGroupId}
                                />
                            )
                        )}
                    </Card>
                </SubscriptionProductsGrid>
                {this.props.modal === MODALS.SUBSCRIBE && (
                    <SubscribeModal
                        asyncSubscribe={this.props.asyncSubscribe}
                        modalProps={this.props.modalProps}
                        promoCode={this.props.promoCode}
                        resetModal={this.props.resetModal}
                        subscribed={this.props.subscribed}
                        user={this.props.user}
                        verifyPromoCode={this.props.verifyPromoCode}
                        visible={this.props.modal}
                    />
                )}
                {this.props.modal === MODALS.PDF_VIEWER && (
                    <PDFViewerModal resetModal={this.props.resetModal} visible={this.props.modal} />
                )}
            </React.Fragment>
        );
    }
}

const SubscriptionProductsGrid = styled.div`
    & .ant-card-head {
        font-size: 20px;
    }

    & .ant-card:first-child {
        margin-bottom: 32px;
    }

    & .ant-card-body {
        display: flex;
        padding: 0;
        justify-content: space-between;
    }
`;
