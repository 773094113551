// Core
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, Popconfirm, Table } from 'antd';
import { resetModal, setModal } from 'core/modals/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Panel } = Collapse;

const mapStateToProps = state => ({
    modal: state.modals.modal,
    user: state.auth
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class RowTypesTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageSize: 10,
            page: 1,
            edit: undefined
        };

        this.columns = () => [
            {
                key: 'rowNumber',
                width: '1%',
                align: 'center',
                render: (data, row, index) => {
                    return <div>{index + 1}.</div>;
                }
            },
            {
                key: 'edit',
                width: '3%',
                align: 'center',
                render: (data, row) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Popconfirm
disabled={this.props.disabled}
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        '/crm/interations_type',
                                        null,
                                        { id: Number(row.id) },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setTimeout(() => this.props.fetchData(), 100);
                                }}
                                title={`${props.intl.formatMessage({ id: 'delete' })}?`}
                            >
                                <Button
disabled={this.props.disabled} icon={<DeleteOutlined />} />
                            </Popconfirm>
                            <Button
disabled={this.props.disabled}
                                icon={<EditOutlined />}
                                onClick={() =>
                                    this.setState({
                                        edit: row.id
                                    })
                                }
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='interactions.type' />,
                key: 'name',
                width: '30%',
                render: row => {
                    const { edit, rowName } = this.state;

                    return (
                        <div>
                            {edit === row.id ? (
                                <div
                                    style={{
                                        display: 'flex'
                                    }}
                                >
                                    <Input
                                        onChange={event => {
                                            this.setState({
                                                rowName: event.target.value
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'interactions.enter_type_name'
                                        })}
                                        value={rowName}
                                    />
                                    <Button
                                        disabled={!rowName}
                                        icon={<CheckOutlined />}
                                        onClick={async () => {
                                            await fetchAPI(
                                                'PUT',
                                                '/crm/interations_type',
                                                null,
                                                {
                                                    name: rowName,
                                                    id: row.id
                                                },
                                                {
                                                    handleErrorInternally: true
                                                }
                                            );
                                            this.setState({
                                                rowName: undefined,
                                                edit: undefined
                                            });
                                            await setTimeout(() => this.props.fetchData(), 200);
                                        }}
                                    />
                                    <Button
                                        icon={<CloseOutlined />}
                                        onClick={() =>
                                            this.setState({
                                                rowName: undefined,
                                                edit: undefined
                                            })
                                        }
                                    />
                                </div>
                            ) : (
                                <div>{row.name}</div>
                            )}
                        </div>
                    );
                }
            }
        ];
    }

    render() {
        const { user, data } = this.props;
        const { source, name, pageSize, page, stats } = this.state;

        // const pagination = {
        //     pageSize,
        //     total: Math.ceil(stats / pageSize) * pageSize,
        //     hideOnSinglePage: false,
        //     current: page,
        //     onChange: async (page, pageSize) => {
        //         await this.setState({ page, pageSize });
        //         this.fetchData();
        //     }
        // };

        return (
            <React.Fragment>
                <div className={Styles.inputBlockStyle}>
                    <div className={Styles.inputMargin}>
                        <div
                            style={{
                                fontWeight: 700,
                                marginBottom: 8
                            }}
                        >
                            <FormattedMessage id='interactions.enter_type_name' />
                        </div>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div>
                                <Input
disabled={this.props.disabled}
                                    onChange={event => {
                                        this.setState({
                                            name: event.target.value
                                        });
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'interactions.enter_type_name'
                                    })}
                                    value={name}
                                />
                            </div>
                            <div>
                                <Button
                                    disabled={!name}
                                    icon={<PlusOutlined />}
                                    onClick={async () => {
                                        await fetchAPI(
                                            'POST',
                                            '/crm/interations_type',
                                            null,
                                            {
                                                name
                                            },
                                            {
                                                handleErrorInternally: true
                                            }
                                        );
                                        this.setState({
                                            name: undefined
                                        });
                                        await setTimeout(() => this.props.fetchData(), 200);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Table bordered columns={this.columns()} dataSource={data} pagination={false} size='small' />
            </React.Fragment>
        );
    }
}
