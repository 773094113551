import { CloseOutlined, CopyOutlined, EditOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Popconfirm, Popover, Row, Select, Tooltip } from 'antd';
import { DebounceSelect } from 'components/DebouncedSelect';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const commentLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 20
    }
};

const counterpartyTypeMap = {
    CLIENT: 'interactions.counterparty_type.CLIENT',
    EMPLOYEE: 'interactions.counterparty_type.EMPLOYEE',
    SUPPLIER: 'interactions.counterparty_type.SUPPLIER'
};

const statusNames = {
    NEW: 'interactions.status.NEW',
    DONE: 'interactions.status.DONE',
    DELETED: 'interactions.status.DELETED'
};

const dateFormat = 'DD.MM.YYYY';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

const InteractionForm = ({
    id,
    docData,
    intl: { formatMessage },
    setData,
    employees,
    suppliers,
    clients,
    debouncedFilter,
    sources,
    types,
    form,
    categories,
    openAddClient,
    stations,
    stationsSearch,
    fetchStations,
    debounceStations,
    user,
    isMobile
}) => {
    const [responsibleId, setResponsible] = useState();
    const [fetched, setFetched] = useState();
    const [comment, setComment] = useState(' ');
    const [counterpartyOptions, setCounterpartyOptions] = useState([]);
    const { counterpartyType } = docData;

    const setValue = useCallback(
        () =>
            form.setFieldsValue({
                counterpartyId: docData.counterpartyId || undefined,
                counterpartyType: docData.counterpartyType || undefined,
                startDate: dayjs(docData.creationDate) || dayjs(),
                sourceId: docData.sourceId || undefined,
                comment: docData.comment || ' ',
                responsibleId: docData.responsibleId || undefined,
                counterpartyStatus: docData.responsibleStatus,
                nextDate: docData.nextDate ? dayjs(docData.nextDate) : undefined,
                interactionTypeId: docData.interactionTypeId || undefined,
                interactionCategoryId: docData.interactionCategoryId || undefined,
                stationId: docData.stationId || undefined
            }),
        [docData, form]
    );

    useEffect(() => {
        if (!id) return;
        setValue();
    }, [docData, setValue, id]);

    const normalizeFields = useCallback(
        allFields =>
            Object.fromEntries(allFields.filter(({ touched }) => touched).map(field => [field.name[0], field.value])),
        []
    );

    useEffect(() => {
        if (!id || counterpartyOptions.length || fetched || !docData.counterpartyId) return;
        setFetched(true);
        debouncedFilter(docData.client, docData.counterpartyType).then(value => {
            const arr = value.filter(
                ({ id, clientId }) => docData.counterpartyId === id || docData.counterpartyId === clientId
            );
            setCounterpartyOptions(
                arr.length
                    ? value
                    : [
                          {
                              label: docData.client,
                              value: docData.counterpartyId
                          }
                      ]
            );
        });
    }, [docData, counterpartyOptions, fetched, id, debouncedFilter, clients]);
    // const counterpartyOptions = useMemo(() => {
    //     return counterpartyType === 'EMPLOYEE'
    //         ? employees
    //               .filter(({ disabled }) => !disabled)
    //               .map(({ id, name, surname }) => ({ label: `${name} ${surname}`, value: id }))
    //         : counterpartyType === 'SUPPLIER'
    //         ? suppliers.map(({ businesssupplierid, businessSupplierName }) => ({
    //               label: businessSupplierName,
    //               value: businesssupplierid
    //           }))
    //         : clients.map(({ clientId, name, surname, phones }) => ({
    //               label: `${name} ${surname || ''} ${_.get(phones, '[0]', ' ')}`,
    //               value: clientId
    //           }));
    // }, [clients, counterpartyType, employees, suppliers, docData]);

    // if (id) return 'Editing interaction';

    // const feidls = form.getFieldsValue();

    return (
        <React.Fragment>
            <Form
                form={form}
                initialValues={{
                    startDate: dayjs(),
                    counterpartyType: docData.counterpartyType || 'CLIENT'
                }}
                labelAlign='left'
                labelWrap
                layout='vertical'
                name='addInteraction'
                onFieldsChange={(_, allFields) => {
                    setData({ ...docData, ...normalizeFields(allFields) });
                }}
            >
                <Row className={isMobile ? Styles.rowMobile : ''} gutter={isMobile ? '100%' : 24}>
                    <Col span={isMobile ? '80%' : 16}>
                        <Row className={isMobile ? Styles.rowMobile : ''} gutter={isMobile ? '95%' : 24}>
                            <Col span={isMobile ? '80%' : 8}>
                                <Form.Item
                                    label={formatMessage({ id: 'interactions.counterparty_type' })}
                                    name='counterpartyType'
                                >
                                    <Select
                                        disabled={id}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onSelect={counterpartyType => {
                                            form.setFieldsValue({
                                                counterpartyId: undefined,
                                                counterpartySearch: undefined
                                            });
                                            setData({
                                                ...docData,
                                                counterpartyType,
                                                counterpartyId: undefined,
                                                counterpartySearch: undefined
                                            });
                                        }}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                    >
                                        {Object.entries(counterpartyTypeMap).map(([key, value]) => (
                                            <Option key={key} value={key}>
                                                <FormattedMessage id={value} />
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={isMobile ? '80%' : 8}>
                                <Form.Item label={formatMessage({ id: 'responsible' })} name='responsibleId'>
                                    <Select
                                        disabled={!id}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 8, width: '100%' }}
                                    >
                                        {employees
                                            .filter(({ disabled }) => !disabled)
                                            .map(({ managerId, name, surname }) => (
                                                <Option key={managerId} value={managerId}>
                                                    {surname} {name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={isMobile ? '80%' : 8}>
                                <Form.Item
                                    field='interactionTypeId'
                                    label={formatMessage({ id: 'storage.type' })}
                                    name='interactionTypeId'
                                    rules={[
                                        {
                                            required: true,
                                            message: formatMessage({ id: 'add_interaction_required_type' })
                                        }
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        showSearch
                                        style={{ marginBottom: 8, width: '100%' }}
                                        optionFilterProp='children'
                                        // mode='multiple'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                    >
                                        {types.map(({ id, name }) => (
                                            <Option key={id} value={id}>
                                                {name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={isMobile ? Styles.rowMobile : ''} gutter={isMobile ? '95%' : 24}>
                            <Col span={isMobile ? '95%' : 8}>
                                <Form.Item label={formatMessage({ id: 'interactions.start_date' })} name='startDate'>
                                    <DatePicker
                                        allowClear
                                        disabled
                                        format={dateFormat}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        placeholder={formatMessage({
                                            id: 'select_date'
                                        })}
                                        style={{
                                            width: '100%'
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={isMobile ? '95%' : 8}>
                                <Form.Item
                                    field='sourceId'
                                    label={formatMessage({ id: 'orders.source' })}
                                    name='sourceId'
                                >
                                    <Select
                                        allowClear
                                        disabled={docData.status === 'DONE'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 8, width: '100%' }}
                                    >
                                        {sources.map(({ id, sourceName }) => (
                                            <Option key={id} value={id}>
                                                {sourceName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={isMobile ? '95%' : 8}>
                                <Form.Item
                                    field='interactionCategoryId'
                                    label={formatMessage({ id: 'interactions.category' })}
                                    name='interactionCategoryId'
                                >
                                    <Select
                                        allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        optionFilterProp='children'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 8, width: '100%' }}
                                    >
                                        {categories.map(({ id, name, surname }) => (
                                            <Option key={id} value={id}>
                                                {name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={isMobile ? Styles.rowMobile : ''} gutter={isMobile ? '95%' : 24}>
                            <Col span={isMobile ? '80%' : 8}>
                                <Form.Item
                                    disabled
                                    label={formatMessage({ id: 'interactions.next_date' })}
                                    name='nextDate'
                                >
                                    <DatePicker
                                        allowClear
                                        disabled
                                        format={dateFormat}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        placeholder={formatMessage({
                                            id: 'select_date'
                                        })}
                                        style={{
                                            width: '100%'
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={isMobile ? '80%' : 8}>
                                <Form.Item
                                    label={formatMessage({ id: 'interactions.counterparty_status' })}
                                    name='counterpartyStatus'
                                >
                                    <Input
                                        disabled
                                        placeholder={formatMessage({ id: 'interactions.counterparty_status' })}
                                    />
                                </Form.Item>
                            </Col>
                            {id && (
                                <Col span={isMobile ? '80%' : 8}>
                                    <Form.Item
                                        field='stationId'
                                        label={formatMessage({ id: 'interactions.station' })}
                                        name='stationId'
                                    >
                                        <Select
                                            allowClear
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onSearch={value => {
                                                if (get(user, 'businessId') === 3333) {
                                                    debounceStations(value);
                                                }
                                            }}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({
                                                id: 'search'
                                            })}
                                            showSearch
                                            style={{ marginBottom: 8, width: '100%' }}
                                        >
                                            {get(user, 'businessId') === 3333 && stationsSearch
                                                ? stationsSearch.map(({ businessId, name }) => (
                                                      <Option key={businessId} value={businessId}>
                                                          {name} {`(#${businessId})`}
                                                      </Option>
                                                  ))
                                                : stations
                                                ? stations.map(({ businessId, businessName }) => (
                                                      <Option key={businessId} value={businessId}>
                                                          {businessName}
                                                      </Option>
                                                  ))
                                                : []}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={isMobile ? '10%' : 4}>
                        <Row className={Styles.center}>
                            <span>
                                <FormattedMessage id='sum' />: {Number(docData.sum || 0).toFixed(2)}
                                {getCurrency()}
                            </span>
                        </Row>
                        <Row className={Styles.center}>
                            <span>
                                <FormattedMessage id='sum_tax' />: {Number(0).toFixed(2)}
                                {getCurrency()}
                            </span>
                        </Row>
                        <Row className={Styles.center}>
                            <span>
                                <FormattedMessage id='paid' />: {Number(0).toFixed(2)}
                                {getCurrency()}
                            </span>
                        </Row>
                    </Col>
                    <Col span={isMobile ? '10%' : 4}>
                        <Row className={Styles.totalSum}>
                            <FormattedMessage id='remain' />: {Number(docData.sum || 0).toFixed(2)}
                            {getCurrency()}
                        </Row>
                    </Col>
                </Row>
                <Row className={isMobile ? Styles.rowMobile : ''} gutter={isMobile ? '95%' : 24}>
                    <Col span={isMobile ? '80%' : 12}>
                        {!docData.counterpartyId && (
                            <Form.Item
                                field='counterpartySearch'
                                label={formatMessage({
                                    id: 'search'
                                })}
                                name='counterpartySearch'
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({ id: 'add_interaction_required_client' })
                                    }
                                ]}
                            >
                                <div style={{ display: 'flex', gap: 8 }}>
                                    <DebounceSelect
                                        allowClear
                                        disabled={!docData.counterpartyType}
                                        fetchOptions={query => debouncedFilter(query, docData.counterpartyType)}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onSelect={value => {
                                            const client =
                                                clients.find(({ clientId }) => clientId == value.value) || {};
                                            form.setFieldsValue({
                                                counterpartyId: value,
                                                sourceId: client.sourceId
                                            });
                                            setData({
                                                ...docData,
                                                counterpartyId: value,
                                                sourceId: client.sourceId
                                            });

                                            setFetched(false);
                                            setCounterpartyOptions([]);
                                        }}
                                        // optionFilterProp='label'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        showSearch
                                        style={{ marginBottom: 8, width: '100%' }}
                                    />
                                    <Tooltip placement='top' title={<FormattedMessage id='clients-page.add_client' />}>
                                        {docData.counterpartyType === 'CLIENT' && !id && (
                                            <PlusOutlined className={Styles.addIcon} onClick={openAddClient} />
                                        )}
                                    </Tooltip>
                                </div>
                            </Form.Item>
                        )}
                        {docData.counterpartyId && (
                            <Form.Item
                                field='counterpartyId'
                                label={formatMessage({
                                    id: `interactions.counterparty_type.${counterpartyType}`
                                })}
                                name='counterpartyId'
                            >
                                <div style={{ display: 'flex', gap: 8 }}>
                                    <React.Fragment>
                                        <Select
                                            allowClear={
                                                !id && {
                                                    clearIcon: (
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                form.setFieldsValue({
                                                                    counterpartyId: undefined,
                                                                    counterpartySearch: undefined
                                                                });
                                                                setData({
                                                                    ...docData,
                                                                    counterpartyId: undefined,
                                                                    counterpartySearch: undefined
                                                                });
                                                            }}
                                                        />
                                                    )
                                                }
                                            }
                                            disabled={id}
                                            filterOption={(input, option) => {
                                                const searchValue = option.children
                                                    .toLowerCase()
                                                    .replace(/[+()-\s]/g, '');
                                                const inputValue = input.toLowerCase();

                                                return searchValue.indexOf(inputValue) >= 0;
                                            }}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            options={
                                                counterpartyOptions.length
                                                    ? counterpartyOptions
                                                    : docData.counterpartyId
                                                    ? [
                                                          {
                                                              label: docData.client,
                                                              value: docData.counterpartyId
                                                          }
                                                      ]
                                                    : []
                                            }
                                            placeholder={formatMessage({
                                                id: 'search'
                                            })}
                                            style={{ marginBottom: 8, width: '100%' }}
                                            value={
                                                docData.phone
                                                    ? {
                                                          value: docData.counterpartyId,
                                                          label: `${docData.client} ${docData.phone}`
                                                      }
                                                    : docData.counterpartyId
                                            }
                                        />
                                        <Popover
                                            content={
                                                <div className={Styles.actionIconsWrap}>
                                                    {id && (
                                                        <Popconfirm
                                                            onConfirm={() => {}}
                                                            title={<FormattedMessage id='save_order_changes' />}
                                                        >
                                                            {docData.counterpartyId && (
                                                                <Link
                                                                    to={`${
                                                                        book[
                                                                            String(
                                                                                docData.counterpartyType || 'CLIENT'
                                                                            ).toLowerCase()
                                                                        ]
                                                                    }/${docData.counterpartyId}`}
                                                                >
                                                                    <EditOutlined className={Styles.icon} />
                                                                </Link>
                                                            )}
                                                        </Popconfirm>
                                                    )}
                                                    <CopyToClipboard
                                                        text={_.get(docData, 'phone', '').replace(/[^0-9]/g, '')}
                                                    >
                                                        <Tooltip
                                                            placement='bottom'
                                                            title={<FormattedMessage id='copy' />}
                                                        >
                                                            <CopyOutlined className={Styles.icon} />
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                </div>
                                            }
                                            placement='right'
                                            trigger='click'
                                        >
                                            <Tooltip
                                                placement='top'
                                                title={<FormattedMessage id='order_form_table.action' />}
                                            >
                                                <MenuOutlined className={Styles.actionIconsPopover} />
                                            </Tooltip>
                                        </Popover>
                                    </React.Fragment>
                                </div>
                            </Form.Item>
                        )}
                    </Col>
                    <Col span={isMobile ? '15%' : 12}>
                        <Form.Item label={formatMessage({ id: 'comment' })} name='comment'>
                            <TextArea
                                disabled={docData.status === 'DONE'}
                                onChange={event => {
                                    setComment(event.target.value);
                                }}
                                placeholder={`${formatMessage({ id: 'comment' })}...`}
                                value={comment}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};

export default connect(mapStateToProps)(injectIntl(InteractionForm));
