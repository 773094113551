import { CaretLeftFilled, CaretRightFilled, FilterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { moveCard } from '@asseinfo/react-kanban';
import { Button, Space, Spin, Tabs, Tooltip, notification } from 'antd';
import { Layout } from 'commons';
import { ControlledKanbanBoard, DateRangePicker, RepairMapIndicator } from 'components';
import dayjs from 'dayjs';
import { findIndex, findLastIndex, get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import FiltersDrawer from './components/FiltersDrawer';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

const OrdersKanbanPage = ({ intl, user }) => {
    const [loading, setLoading] = useState(false);
    const [board, setBoard] = useState();
    const [prevFilters, setPrevFilters] = useState();
    const [columnStatus, setColumnStatus] = useState('not_complete');
    const [activeKey, setActiveKey] = useState('all');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filters, setFilters] = useState({
        startDate: dayjs().subtract(2, 'week').format('YYYY-MM-DD'),
        endDate: dayjs().add(2, 'week').format('YYYY-MM-DD'),
        manager: user.employeeId
    });

    const fetchKanban = async () => {
        setLoading(true);
        const kanban = await fetchAPI('GET', 'orders_kanban', filters, undefined, {
            handleErrorInternally: true
        });
        setBoard({
            columns: kanban.ordersMap.map(({ status, subStatus, orders, color }, key) => ({
                id: key,
                title: <FormattedMessage id={`status_directory.${subStatus}`} />,
                subStatus,
                status,
                color,
                cards: (orders || []).map(
                    ({
                        id,
                        beginDatetime,
                        clientName,
                        clientSurname,
                        clientPhone,
                        vehicleNumber,
                        vehicleMakeName,
                        vehicleModelName,
                        totalSumWithTax,
                        remainingSum,
                        num,
                        stationName,
                        subStatusIndications,
                        vehicleVin
                    }) => ({
                        id,
                        title: vehicleNumber,
                        date: beginDatetime,
                        client: `${clientName || ''} ${clientSurname || ''}`,
                        clientPhone,
                        vehicleNumber,
                        vehicle: `${vehicleMakeName || ''} ${vehicleModelName || ''}`,
                        subStatus,
                        status,
                        totalSum: totalSumWithTax,
                        remainingSum,
                        num,
                        stationName,
                        vehicleVin,
                        subStatusIndications,
                        color
                    })
                )
            }))
        });
        setLoading(false);
    };

    useEffect(() => {
        fetchKanban();
    }, []);

    useEffect(() => {
        fetchKanban();
    }, [activeKey]);

    const onCardDragEnd = useCallback(
        async (card, source, destination) => {
            const updatedBoard = moveCard(board, source, destination);
            setBoard(updatedBoard);
            const { status, subStatus } = board.columns[destination.toColumnId];
            try {
                await fetchAPI(
                    'PUT',
                    `orders/${card.id}`,
                    null,
                    {
                        status,
                        subStatus
                    },
                    { handleErrorInternally: true }
                );
            } catch (e) {
                notification.error({ message: intl.formatMessage({ id: 'kanban-page.invalid_operation' }) });
            }
            fetchKanban();
        },
        [board]
    );

    const handleFiltersChange = key => value => {
        setFilters(prev => ({ ...prev, [key]: value }));
    };

    const handleFiltersSubmit = () => {
        setFiltersOpen(false);
        fetchKanban();
    };

    const handleDateChange = daterange => {
        const [startDate, endDate] = daterange;
        handleFiltersChange('startDate')(startDate.format('YYYY-MM-DD'));
        handleFiltersChange('endDate')(endDate.format('YYYY-MM-DD'));
    };

    const renderCard = ({
        id,
        date,
        vehicleNumber,
        vehicle,
        clientPhone,
        client,
        totalSum,
        remainingSum,
        num,
        stationName,
        vehicleVin,
        subStatusIndications,
        color
    }) => (
        <div className='react-kanban-card' style={{ borderColor: color }}>
            <div className='react-kanban-card__title' style={{ backgroundColor: color }}>
                <div>
                    <Link to={`${book.order}/${id}`}>
                        <b>{id}</b>
                    </Link>
                    , {dayjs(date).format('dd DD.MM, HH:mm')}
                </div>
                <Tooltip
                    title={
                        <div>
                            <p>{num}</p>
                            <p>{stationName}</p>
                            <p>{vehicleVin}</p>
                            <RepairMapIndicator data={subStatusIndications} />
                        </div>
                    }
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <div className='react-kanban-card__description'>
                <p>
                    <span style={{ fontWeight: 700 }}>{vehicleNumber ? `${vehicleNumber},` : ''}</span> {vehicle}
                </p>
                <p>
                    <span style={{ fontWeight: 700 }}>{clientPhone ? `${clientPhone},` : ''}</span> {client}
                </p>
                {!!remainingSum && (
                    <div>
                        {totalSum}₴ / <span style={{ color: 'red' }}>{remainingSum}₴</span>
                    </div>
                )}
            </div>
        </div>
    );

    const renderColumnHeader = ({ title, status, color, cards }) => (
        <div className={Styles.columnHader} style={{ backgroundColor: color }}>
            <div
                className={Styles.statusHeader}
                onClick={() => {
                    setColumnStatus(status);
                    setActiveKey('sub');
                }}
                style={{ cursor: 'pointer' }}
            >
                <FormattedMessage id={`order_statuses_mapper.${status}`} />
            </div>
            <div className='react-kanban-column-header'>
                {title} ({cards.length})
            </div>
        </div>
    );

    const renderMainColumnHeader = ({ title, status, color, cards }) => (
        <div className={Styles.columnHader} style={{ backgroundColor: color }}>
            <div
                className='react-kanban-column-header'
                onClick={() => {
                    setFilters(prev => ({ ...prev, isOldStatuses: false }));
                    setColumnStatus(status);
                    setActiveKey('sub');
                }}
                style={{ cursor: 'pointer' }}
            >
                <FormattedMessage id={`order_statuses_mapper.${status}`} /> ({cards.length})
            </div>
        </div>
    );

    const columnIndex = findIndex(get(board, 'columns', []), column => column.status === columnStatus);
    const columnLastIndex = findLastIndex(get(board, 'columns', []), column => column.status === columnStatus);
    const prevStatus = get(board, `columns[${columnIndex - 1}].status`);
    const nextStatus = get(board, `columns[${columnLastIndex + 1}].status`);

    return (
        <Layout
            className={Styles.kanbanPage}
            controls={
                <Space>
                    <DateRangePicker
                        buttonProps={{ size: 'large' }}
                        dateRange={[dayjs(filters.startDate), dayjs(filters.endDate)]}
                        minimize // prevent default space
                        onDateChange={handleDateChange}
                        style={{ margin: '0', display: 'flex', justifyContent: 'center', maxWidth: 'none' }}
                    />
                    <Button
                        icon={<FilterOutlined />}
                        onClick={() => {
                            setFiltersOpen(true);
                            setPrevFilters(filters);
                        }}
                        size='large'
                        type='text'
                    />
                </Space>
            }
            title={<FormattedMessage id='navigation.kanban' />}
        >
            <div style={{ position: 'relative' }}>
                {loading && (
                    <div className={Styles.loading}>
                        <Spin />
                    </div>
                )}
                <Tabs
                    activeKey={activeKey}
                    onChange={key => {
                        if (key === 'main') {
                            setFilters(prev => ({ ...prev, isOldStatuses: true }));
                        } else {
                            setFilters(prev => ({ ...prev, isOldStatuses: false }));
                        }
                        setActiveKey(key);
                    }}
                >
                    <Tabs.TabPane key='all' tab={<FormattedMessage id='orders.appointments.all_statuses' />}>
                        {board && (
                            <ControlledKanbanBoard
                                board={board}
                                disableColumnDrag
                                onCardDragEnd={onCardDragEnd}
                                renderCard={renderCard}
                                renderColumnHeader={renderColumnHeader}
                            />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane key='main' tab={<FormattedMessage id='kanban-page.main_statuses' />}>
                        {board && (
                            <ControlledKanbanBoard
                                board={board}
                                disableColumnDrag
                                onCardDragEnd={onCardDragEnd}
                                renderCard={renderCard}
                                renderColumnHeader={renderMainColumnHeader}
                            />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane key='sub' tab={<FormattedMessage id='kanban-page.substatuses' />}>
                        <Space align='start'>
                            {prevStatus && (
                                <Button
                                    icon={<CaretLeftFilled />}
                                    onClick={() => setColumnStatus(prevStatus)}
                                    style={{ marginTop: 12 }}
                                >
                                    <FormattedMessage id={`order_statuses_mapper.${prevStatus}`} />
                                </Button>
                            )}
                            {board && (
                                <ControlledKanbanBoard
                                    board={{ columns: board.columns.filter(column => column.status === columnStatus) }}
                                    disableColumnDrag
                                    onCardDragEnd={onCardDragEnd}
                                    renderCard={renderCard}
                                    renderColumnHeader={renderColumnHeader}
                                />
                            )}
                            {nextStatus && (
                                <Button
                                    icon={<CaretRightFilled />}
                                    onClick={() => setColumnStatus(nextStatus)}
                                    style={{ marginTop: 12 }}
                                >
                                    <FormattedMessage id={`order_statuses_mapper.${nextStatus}`} />
                                </Button>
                            )}
                        </Space>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <FiltersDrawer
                filters={filters}
                filtersOpen={filtersOpen}
                handleClose={() => {
                    setFiltersOpen(false);
                    setFilters(prevFilters);
                }}
                handleFiltersChange={handleFiltersChange}
                handleFiltersSubmit={handleFiltersSubmit}
            />
        </Layout>
    );
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrdersKanbanPage));
