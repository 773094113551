import { Button, Modal, Table } from 'antd';
import { Layout } from 'commons';
import { ImportExportTable } from 'components';
import { SyncConflictsModal, SyncImportExportModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@connect(mapStateToProps, void 0)
export default class SyncImportPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            tableData: [],
            conflictsId: undefined,
            errorsId: undefined,
            intervalId: undefined
        };
    }

    fetchTable = () => {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/sync/history?type=IMPORT`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.setState({
                    tableData: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    componentDidMount() {
        this.fetchTable();
        // const intervalId = setInterval(this.fetchTable, 5000);
        // this.setState({ intervalId });
    }

    componentWillUnmount() {
        // clearInterval(this.state.intervalId);
    }

    render() {
        const { user } = this.state;
        const { modalVisible, tableData, conflictsId, errorsId } = this.state;

        return (
            <Layout
                controls={
                    <Button
                        onClick={() => {
                            this.setState({
                                modalVisible: true
                            });
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='export_import_pages.import_data' />
                    </Button>
                }
                title={<FormattedMessage id='navigation.sync_import' />}
            >
                <ImportExportTable
                    fetchTable={this.fetchTable}
                    showConflictsModal={id => {
                        this.setState({
                            conflictsId: id
                        });
                    }}
                    showErrors={id => {
                        this.setState({
                            errorsId: id
                        });
                    }}
                    tableData={tableData}
                    type='IMPORT'
                />
                <SyncImportExportModal
                    hideModal={() => {
                        this.setState({
                            modalVisible: false
                        });
                        this.fetchTable();
                    }}
                    showConflictsModal={id => {
                        this.setState({
                            conflictsId: id
                        });
                    }}
                    tableData={tableData}
                    type='IMPORT'
                    visible={modalVisible}
                />
                <SyncConflictsModal
                    conflictsId={conflictsId}
                    hideModal={() => {
                        this.setState({
                            conflictsId: undefined
                        });
                    }}
                    visible={Boolean(conflictsId)}
                />
                <ErrorsModal
                    errorsId={errorsId}
                    hideModal={() => {
                        this.setState({
                            errorsId: undefined
                        });
                    }}
                    visible={Boolean(errorsId)}
                />
            </Layout>
        );
    }
}

@injectIntl
class ErrorsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };

        this.columns = [
            {
                dataIndex: 'key',
                key: 'key',
                render: (data, row) => {
                    return data + 1;
                }
            },
            {
                title: <FormattedMessage id='export_import_pages.data_base' />,
                dataIndex: 'table',
                key: 'data_base',
                render: (data, row) => {
                    return data ? <FormattedMessage id={`export_import_pages.${data.toLowerCase()}`} /> : null;
                }
            },
            {
                title: <FormattedMessage id='export_import_pages.row' />,
                dataIndex: 'row',
                key: 'row'
            },
            {
                title: <FormattedMessage id='field' />,
                dataIndex: 'field',
                key: 'field'
            },
            {
                title: <FormattedMessage id='export_import_pages.message' />,
                dataIndex: 'message',
                key: 'message'
            }
        ];
    }

    fetchErrors() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/sync/errors?syncId=${this.props.errorsId}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((elem, key) => {
                    elem.key = key;
                });
                that.setState({
                    errors: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            this.fetchErrors();
        }
    }

    render() {
        const {
            visible,
            intl: { formatMessage },
            hideModal
        } = this.props;
        const { errors } = this.state;

        return (
            <Modal
                destroyOnClose
                maskClosable={false}
                onCancel={hideModal}
                style={{ width: 'fit-content', minWidth: 840 }}
                title={<FormattedMessage id='export_import_pages.errors' />}
                visible={visible}
            >
                <Table bordered columns={this.columns} dataSource={errors} size='small' />
            </Modal>
        );
    }
}
