import { Catcher, Paper } from 'commons';
import { ReviewsStats, ReviewsTable } from 'components';
import { fetchReviews, setReviewsPageFilter } from 'core/reviews/duck';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    reviews: state.reviews.reviews,
    filter: state.reviews.filter,
    stats: state.reviews.stats
});

const mapDispatchToProps = {
    fetchReviews,
    setReviewsPageFilter
};

// @withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class ReviewsContainer extends Component {
    render() {
        const { reviews, filter, stats, setReviewsPageFilter, fetchReviews } = this.props;

        return (
            <Catcher>
                <Paper>
                    <ReviewsStats stats={stats} />
                </Paper>
                <Paper>
                    <ReviewsTable
                        fetchReviews={fetchReviews}
                        filter={filter}
                        reviews={reviews}
                        setReviewsPageFilter={setReviewsPageFilter}
                        stats={stats}
                    />
                </Paper>
            </Catcher>
        );
    }
}
