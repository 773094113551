import dayjs from 'dayjs';

/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'changeVehicleMileageModal';
const prefix = `cpb/${moduleName}`;

export const SET_MILEAGE_DATE_TIME = `${prefix}/SET_MILEAGE_DATE_TIME`;
export const SET_MILEAGE = `${prefix}/SET_MILEAGE`;
export const SAVE_MILEAGE = `${prefix}/SAVE_MILEAGE`;
export const RESET_FIELDS = `${prefix}/RESET_FIELDS`;

export const DEF_BACK_DATE_FORMAT = 'YYYY.MM.DD';
export const DEF_UI_DATE_FORMAT = 'DD.MM.YYYY';

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {
    mileageDateTime: dayjs().format(DEF_BACK_DATE_FORMAT),
    mileage: 0
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_MILEAGE_DATE_TIME:
            return {
                ...state,
                mileageDateTime: payload
            };

        case SET_MILEAGE:
            return {
                ...state,
                mileage: payload
            };

        case RESET_FIELDS:
            return {
                ...ReducerState // Drop to default
            };

        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */
export const selectMileageDateTime = state => state[moduleName].mileageDateTime;
export const selectMileage = state => state[moduleName].mileage;

/* ------------------------------------- Action Creators -------------------------- */
export const setMileageDateTime = value => ({
    type: SET_MILEAGE_DATE_TIME,
    payload: value
});
export const setMileage = value => ({
    type: SET_MILEAGE,
    payload: value
});

export const saveMileage = ({ vehicleId }) => ({
    type: SAVE_MILEAGE,
    payload: { vehicleId }
});

/** Reset redux fields */
export const resetFields = () => ({
    type: RESET_FIELDS
});
