import { Form } from '@ant-design/compatible';
import { Col, Row } from 'antd';
import { DecoratedInput } from 'forms/DecoratedFields';
import { selectBrandCrossField, setBrandCrossFields } from 'modals/BrandsCrossModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const FItem = Form.Item;

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    fields: selectBrandCrossField(state)
});

const mapDispatchToProps = {
    setBrandCrossFields
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@Form.create({ name: 'brands_edit_cross_form' })
export default class EditFormBrandCross extends React.Component {
    componentDidMount() {
        const { cross } = this.props.modalProps;

        this.props.setBrandCrossFields({
            id: cross.businessSupplierBrandId,
            brandId: cross.brandId,
            businessSupplierBrandName: cross.name
        });
    }

    setField = (key, value) => {
        this.props.setBrandCrossFields({ [key]: value });
    };

    render() {
        const {
            fields,
            form,
            intl: { formatMessage },
            user
        } = this.props;

        const { getFieldDecorator, resetFields, isFieldTouched } = form;

        return (
            <Form>
                <Row className={Styles.row}>
                    <Col span={6}>
                        <div className={Styles.colText}>
                            <FormattedMessage id='brand_cross_name' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <DecoratedInput
                            field='businessSupplierBrandName'
                            formItem // businessSupplierBrandName
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            initialValue={fields.businessSupplierBrandName}
                            onChange={e => {
                                this.setField('businessSupplierBrandName', e.target.value);
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        );
    }
}
