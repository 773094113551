import { emitError } from 'core/ui/duck';
import { CASH_ORDER_TYPES, MODES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectCashOrderType,
    selectMode,
    selectSelectedCounterpartyType
} from 'modals/RefactoredCashOrderModal/redux/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    FETCH_ANALYTICS,
    fetchAnalyticsSuccess,
    selectAnalyticsFilters,
    setFetchingAnalytics,
    setSelectedAnalyticsUniqueId
} from './duck';

export function* fetchAnalyticsSaga() {
    while (true) {
        try {
            yield take(FETCH_ANALYTICS);

            yield put(setFetchingAnalytics(true));
            const filters = yield select(selectAnalyticsFilters);

            const response = yield call(
                fetchAPI,
                'GET',
                'report/analytics',
                {
                    filters
                },
                null,
                { handleErrorInternally: true }
            );
            const { analytics, stats: analyticsStats } = response;

            yield put(fetchAnalyticsSuccess({ analytics, analyticsStats }));

            const mode = yield select(selectMode);

            if (mode === MODES.ADD) {
                // Update default analytics for current cash order type if we are in add mode(no cations for mode where i is not needed)
                const currentCashOrderType = yield select(selectCashOrderType);
                const counterpartyType = yield select(selectSelectedCounterpartyType);
                const newAnalyticsUniqueIdForEmployee =
                    counterpartyType === 'EMPLOYEE' && currentCashOrderType === CASH_ORDER_TYPES.INCOME
                        ? 87
                        : counterpartyType === 'EMPLOYEE' && currentCashOrderType === CASH_ORDER_TYPES.EXPENSE
                        ? 31
                        : undefined;
                const newAnalyticsUniqueId =
                    currentCashOrderType === CASH_ORDER_TYPES.INCOME ||
                    currentCashOrderType === CASH_ORDER_TYPES.ADJUSTMENT_INCOME
                        ? analyticsStats.defaultIncomeAnalyticsUniqueId
                        : analyticsStats.defaultExpenseAnalyticsUniqueId;

                yield put(
                    setSelectedAnalyticsUniqueId(
                        counterpartyType === 'EMPLOYEE' ? newAnalyticsUniqueIdForEmployee : newAnalyticsUniqueId
                    )
                );
            }
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingAnalytics(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchAnalyticsSaga)]);
}
