import { Select } from 'antd';
import { DecoratedSelect } from 'forms/DecoratedFields';
import React from 'react';

const { Option } = Select;

export default props => {
    const {
        field,
        label,
        formItem,
        formItemLayout,
        formatMessage,
        getFieldDecorator,
        initialValue,
        priceGroups,
        cnStyles,
        rules,
        disabled
    } = props;

    return (
        <DecoratedSelect
            cnStyles={cnStyles}
            disabled={disabled}
            field={field}
            fields={{}}
            formItem={formItem}
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
            getPopupContainer={trigger => trigger.parentNode}
            initialValue={initialValue}
            label={label}
            rules={rules}
        >
            {priceGroups.map(({ number, multiplier }) => (
                <Option key={number} value={number}>
                    <span>
                        {formatMessage({ id: 'storage.price_group' })} - {number}{' '}
                    </span>
                    <span>
                        ({formatMessage({ id: 'storage.markup' })} - {multiplier})
                    </span>
                </Option>
            ))}
        </DecoratedSelect>
    );
};
