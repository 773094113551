import { PlusOutlined, PlusSquareOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Select, Space, Table, Tooltip, notification } from 'antd';
import { Layout } from 'commons';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AddCurrencyModal from './AddCurrencyModal';
import CurrencyRowModal from './CurrencyRowModal';

export const USD = 'USD';
export const EUR = 'EUR';

const mapStateToProps = state => ({
    user: state.auth
});

const ExchangeRatePage = ({ intl, user }) => {
    const [currencyList, setCurrencyList] = useState([]);
    const [currencyJournal, setCurrencyJournal] = useState({ list: [] });
    const [addCurrencyModalOpen, setAddCurrencyModalOpen] = useState(false);
    const [currencyRowModalOpen, setCurrencyRowModalOpen] = useState(false);
    const [eurId, setEurId] = useState();
    const [usdId, setUsdId] = useState();
    const [selectedCurrencies, setSelectedCurrencies] = useState([]);

    const accessRecalculateButton = isGrantAccessed(user, grants.DIRECTORIES_CURRENCIES, accesses.ROWO);

    const currencyOptions = useMemo(
        () => currencyList.map(({ id, currencyCode }) => ({ label: currencyCode, value: id })),
        [currencyList]
    );

    const formatData = data => {
        const res = { count: data.count, list: [] };
        data.list.forEach(({ date, currencies }) => {
            const tmp = { date };
            currencies.forEach(({ id, currencyId, exchangeRate }) => {
                tmp[currencyId] = { id, exchangeRate };
            });

            res.list.push(tmp);
        });

        return res;
    };

    const fetchJournal = async () => {
        const journal = await fetchAPI('GET', 'business/currency/journal', null, null, {
            handleErrorInternally: true
        });
        setCurrencyJournal(formatData(journal));
    };

    const putSelectedCurrencies = async currencyOrder => {
        await fetchAPI(
            'PUT',
            '/businesses',
            null,
            { currencyOrder },
            {
                handleErrorInternally: true
            }
        );
        const business = await fetchAPI('GET', 'business', null, null, { handleErrorInternally: true });
        setSelectedCurrencies(business.currencyOrder);
    };

    useEffect(() => {
        const fetchInfo = async () => {
            const list = fetchAPI('GET', 'business/currency/list', null, null, { handleErrorInternally: true });
            const journal = fetchAPI('GET', 'business/currency/journal', null, null, { handleErrorInternally: true });
            const business = fetchAPI('GET', 'business', null, null, { handleErrorInternally: true });
            const res = await Promise.all([list, journal, business]);
            res[0].forEach(({ currencyCode, id }) => {
                if (currencyCode === USD) {
                    setUsdId(id);
                } else if (currencyCode === EUR) {
                    setEurId(id);
                }
            });
            setCurrencyList(res[0]);
            setCurrencyJournal(formatData(res[1]));
            setSelectedCurrencies(res[2].currencyOrder);
        };
        fetchInfo();
    }, []);

    const reculculatePrices = async () => {
        await fetchAPI('PUT', 'store_products/currency/price', null, null, { handleErrorInternally: true });
        fetchJournal();
        notification.success({
            message: intl.formatMessage({
                id: 'barcode.success'
            })
        });
    };

    const handleCurrencyModalOpen = record => {
        setAddCurrencyModalOpen(record || true);
    };
    const handleCurrencyModalClose = () => {
        setAddCurrencyModalOpen(false);
    };

    const handleCurrencyRowModalOpen = record => {
        setCurrencyRowModalOpen(record || true);
    };
    const handleCurrencyRowModalClose = () => {
        setCurrencyRowModalOpen(false);
    };

    const changeSelectedCurrencies = currencies => {
        setSelectedCurrencies([usdId, eurId, ...currencies]);
        putSelectedCurrencies([usdId, eurId, ...currencies].filter(cur => Boolean(cur)));
    };

    const handleFilterOption = (inputValue, option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

    const columns = () => {
        const curr1 = get(selectedCurrencies, '[2]');
        const curr2 = get(selectedCurrencies, '[3]');
        const curr3 = get(selectedCurrencies, '[4]');

        return [
            {
                render: record => (
                    <Tooltip overlayStyle={{ zIndex: 110 }} placement='right' title={<FormattedMessage id='edit' />}>
                        <Button
                            disabled={!accessRecalculateButton}
                            onClick={() => handleCurrencyRowModalOpen(record)}
                            type='text'
                        >
                            {dayjs(record.date).format('DD.MM.YYYY')}
                        </Button>
                    </Tooltip>
                )
            },
            {
                title: USD,
                render: record => (
                    <Tooltip overlayStyle={{ zIndex: 110 }} placement='right' title={<FormattedMessage id='edit' />}>
                        <Button
                            disabled={!accessRecalculateButton}
                            onClick={() => handleCurrencyModalOpen({ ...record, ...record[usdId], currencyId: usdId })}
                            type='text'
                        >
                            {get(record, `[${usdId}].exchangeRate`) &&
                                get(record, `[${usdId}].exchangeRate`).toFixed(4)}
                        </Button>
                    </Tooltip>
                )
            },
            {
                title: EUR,
                render: record => (
                    <Button
                        disabled={!accessRecalculateButton}
                        onClick={() => handleCurrencyModalOpen({ ...record, ...record[eurId], currencyId: eurId })}
                        type='text'
                    >
                        {get(record, `[${eurId}].exchangeRate`) && get(record, `[${eurId}].exchangeRate`).toFixed(4)}
                    </Button>
                )
            },
            {
                title: () => (
                    <Select
                        disabled={!accessRecalculateButton}
                        filterOption={handleFilterOption}
                        onChange={val => {
                            changeSelectedCurrencies([val, curr2, curr3]);
                        }}
                        options={currencyOptions}
                        showSearch
                        value={curr1}
                    />
                ),
                render: record => (
                    <Button
                        disabled={!accessRecalculateButton}
                        onClick={() => handleCurrencyModalOpen({ ...record, ...record[curr1], currencyId: curr1 })}
                        type='text'
                    >
                        {get(record, `[${curr1}].exchangeRate`) && get(record, `[${curr1}].exchangeRate`).toFixed(4)}
                    </Button>
                )
            },
            {
                title: () => (
                    <Select
                        disabled={!accessRecalculateButton}
                        filterOption={handleFilterOption}
                        onChange={val => {
                            changeSelectedCurrencies([curr1, val, curr3]);
                        }}
                        options={currencyOptions}
                        showSearch
                        value={curr2}
                    />
                ),
                render: record => (
                    <Button
                        disabled={!accessRecalculateButton}
                        onClick={() => handleCurrencyModalOpen({ ...record, ...record[curr2], currencyId: curr2 })}
                        type='text'
                    >
                        {get(record, `[${curr2}].exchangeRate`) && get(record, `[${curr2}].exchangeRate`).toFixed(4)}
                    </Button>
                )
            },
            {
                title: () => (
                    <Select
                        disabled={!accessRecalculateButton}
                        filterOption={handleFilterOption}
                        onChange={val => {
                            changeSelectedCurrencies([curr1, curr2, val]);
                        }}
                        options={currencyOptions}
                        showSearch
                        value={curr3}
                    />
                ),
                render: record => (
                    <Button
                        disabled={!accessRecalculateButton}
                        onClick={() => handleCurrencyModalOpen({ ...record, ...record[curr3], currencyId: curr3 })}
                        type='text'
                    >
                        {get(record, `[${curr3}].exchangeRate`) && get(record, `[${curr3}].exchangeRate`).toFixed(4)}
                    </Button>
                )
            }
        ];
    };

    return (
        <Layout
            controls={
                <Space>
                    <Tooltip title={<FormattedMessage id='order_stats_info_modal.recalculate' />}>
                        <Button
                            disabled={!accessRecalculateButton}
                            icon={<SendOutlined />}
                            onClick={reculculatePrices}
                            type='text'
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='exchange_rate.add_rows_currency' />}>
                        <Button
                            disabled={!accessRecalculateButton}
                            icon={<PlusSquareOutlined />}
                            onClick={() => handleCurrencyRowModalOpen()}
                            type='text'
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='exchange_rate.add_currency' />}>
                        <Button
                            disabled={!accessRecalculateButton}
                            icon={<PlusOutlined />}
                            onClick={() => handleCurrencyModalOpen()}
                            type='text'
                        />
                    </Tooltip>
                </Space>
            }
            title={<FormattedMessage id='navigation.exchange_rate' />}
        >
            <Table columns={columns()} dataSource={currencyJournal.list} size='small' />
            <AddCurrencyModal
                currencyOptions={currencyOptions}
                fetchJournal={fetchJournal}
                onClose={handleCurrencyModalClose}
                open={addCurrencyModalOpen}
                usdId={usdId}
            />
            <CurrencyRowModal
                changeSelectedCurrencies={changeSelectedCurrencies}
                currencyOptions={currencyOptions}
                fetchJournal={fetchJournal}
                onClose={handleCurrencyRowModalClose}
                open={currencyRowModalOpen}
                selectedCurrencies={selectedCurrencies}
            />
        </Layout>
    );
};

export default injectIntl(connect(mapStateToProps)(ExchangeRatePage));
