import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Radio, Select } from 'antd';
import classNames from 'classnames/bind';
import { Layout } from 'commons';
import { DateRangePicker } from 'components';
import { FunelContainer, OrdersContainer, OrdersFilterContainer } from 'containers';
import { fetchUniversalFiltersForm } from 'core/forms/universalFiltersForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { fetchOrders, setOrdersDaterangeFilter, setOrdersStatusFilter, setUniversalFilter } from 'core/orders/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;

const cx = classNames.bind(Styles);

const filterSelectOptions = [
    {
        message: 'all',
        value: 'not_complete,required,call,reserve,processing,approve,progress,success'
    },
    {
        message: 'records',
        value: 'approve'
    },
    {
        message: 'not_complete',
        value: 'not_complete,required,call,reserve'
    },
    {
        message: 'repairs',
        value: 'progress'
    },
    {
        message: 'done',
        value: 'success'
    },
    {
        message: 'required',
        value: 'required'
    },
    {
        message: 'reserve',
        value: 'reserve'
    },
    {
        message: 'call',
        value: 'call'
    },
    {
        message: 'cancels',
        value: 'cancel'
    },
    {
        message: 'stop',
        value: 'stop'
    },
    {
        message: 'processing',
        value: 'processing'
    }
];

const mapStateToProps = state => ({
    ordersDaterangeFilter: state.orders.filter.daterange,
    filter: state.orders.filter,
    universalStats: state.orders.universalStats,
    universalFilter: state.orders.universalFilter,
    collapsed: state.ui.collapsed,
    user: state.auth,
    daterange: state.orders.daterange,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchOrders,
    setOrdersDaterangeFilter,
    setModal,
    fetchUniversalFiltersForm,
    setUniversalFilter,
    setOrdersStatusFilter
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class OrdersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    getPageTitle() {
        const status = this.props.match.params.ordersStatuses;
        switch (status) {
            case 'appointments':
                return <FormattedMessage id='appointments' />;
            case 'approve':
                return <FormattedMessage id='records' />;
            case 'progress':
                return <FormattedMessage id='repairs' />;
            case 'success':
                return <FormattedMessage id='done' />;
            case 'reviews':
                return <FormattedMessage id='reviews' />;
            case 'invitations':
                return <FormattedMessage id='invitations' />;
            case 'cancel':
                return <FormattedMessage id='cancels' />;
            case 'stop':
                return <FormattedMessage id='transfer_stop' />;
            case 'processing':
                return <FormattedMessage id='order_statuses_mapper.processing' />;

            default:
                return <FormattedMessage id='orders-page.title' />;
        }
    }

    _setFilterStatus = status => {
        this.props.setOrdersStatusFilter(status);
        this.props.fetchOrders();
    };

    _setOrdersDaterange = daterange => {
        const { setOrdersDaterangeFilter, fetchOrders } = this.props;

        setOrdersDaterangeFilter({
            daterange,
            startDate: dayjs(daterange[0]).format('YYYY-MM-DD'),
            endDate: dayjs(daterange[1]).format('YYYY-MM-DD')
        });
        fetchOrders();
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'orders_appointments' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };
    // eslint-disable-next-line
    render() {
        const { collapsed, isMobile, user, filter } = this.props;
        const { query, helperDrawerOpen, allLinks } = this.state;

        const headerControls = this._renderHeaderContorls();

        const status = this.props.match.params.ordersStatuses;

        const filterStatus = filter.status;

        const funelSectionStyles = cx({
            funelWithFilters: true,
            funelWithFiltersCollapsed: collapsed,
            funelWithFiltersShadow: ['success', 'cancel'].indexOf(status) < 0,
            funelMobile: isMobile
        });

        return (
            <Layout
                controls={
                    <div className={Styles.controls}>
                        {!isMobile &&
                            /* ([ 'success', 'cancel', 'reviews' ].indexOf(status) <
                                0 && */
                            headerControls}
                        <div className={Styles.buttonGroup}>
                            {(status === 'cancel' || status === 'success') && (
                                <Button
                                    disabled={!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT, accesses.ROWO)}
                                    onClick={() => this.props.setModal(MODALS.INVITE)}
                                    type='primary'
                                >
                                    <FormattedMessage id='orders-page.invite_to_service' />
                                </Button>
                            )}
                            <Link
                                to={{
                                    pathname: book.addOrder,
                                    state: {
                                        beginDatetime: dayjs()
                                            .add(dayjs().hour() < 4 ? 9 - dayjs().hour() : 0, 'h')
                                            .add(30 - (dayjs().minute() % 30), 'minutes')
                                            .format('YYYY-MM-DD HH:00')
                                    }
                                }}
                            >
                                <Button
                                    disabled={!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT, accesses.ROWO)}
                                    type='primary'
                                >
                                    <FormattedMessage id='orders-page.add_appointment' />
                                </Button>
                            </Link>
                        </div>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    const links = await fetchAPI(
                                        'GET',
                                        '/helps',
                                        { helpId: 'orders_appointments' },
                                        undefined,
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    this.setState({
                                        allLinks: links,
                                        helperDrawerOpen: true
                                    });
                                }}
                                style={{
                                    fontSize: 22,
                                    marginLeft: 8,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </div>
                    </div>
                }
                description={<FormattedMessage id='orders-page.description' />}
                title={this.getPageTitle()}
            >
                {!isMobile ? (
                    <React.Fragment>
                        <section
                            className={
                                ['success', 'cancel'].indexOf(status) > -1
                                    ? `${Styles.ordersWrrapper} ${Styles.ordersWrrapperUF}`
                                    : Styles.ordersWrrapper
                            }
                        >
                            <OrdersContainer>
                                <div>
                                    <FunelContainer query={query} />
                                    <OrdersFilterContainer
                                        query={query}
                                        setQuery={query => this.setState({ query })}
                                        status={status}
                                    />
                                    {/* <UniversalFilters
                                        areFiltersDisabled={ isForbidden(
                                            this.props.user,
                                            permissions.SHOW_FILTERS,
                                        ) }
                                        stats={ this.props.universalStats }
                                        universalFilter={ this.props.universalFilter }
                                        setUniversalFilter={ this.props.setUniversalFilter }
                                    /> */}
                                </div>
                            </OrdersContainer>
                        </section>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <section className={Styles.ordersWrapperMobile}>
                            <OrdersContainer isMobile={isMobile}>
                                <Select
                                    onChange={value => {
                                        this._setFilterStatus(value);
                                    }}
                                    style={{ marginBottom: 8 }}
                                    value={filterStatus}
                                >
                                    {filterSelectOptions.map(({ value, message }) => (
                                        <Option key={message} value={value}>
                                            <FormattedMessage id={message} />
                                        </Option>
                                    ))}
                                </Select>
                                <OrdersFilterContainer onlySearch status={status} />
                            </OrdersContainer>
                        </section>
                    </React.Fragment>
                )}
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }

    _renderHeaderContorls = () => {
        // const {
        //     filter: { daterange },
        // } = this.props;

        return <DateRangePicker dateRange={this.props.daterange} minimize onDateChange={this._setOrdersDaterange} />;
    };
}
