import { Table } from 'antd';
import { Catcher } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

export default class ClientSearchTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: <FormattedMessage id='name' />,
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: <FormattedMessage id='surname' />,
                dataIndex: 'surname',
                key: 'surname'
            },
            {
                title: <FormattedMessage id='add_order_form.phone' />,
                dataIndex: 'phones',
                key: 'phones'
            },
            {
                title: <FormattedMessage id='vehicle' />,
                key: 'vehicle',
                render: row => {
                    return row.model && `${row.make} ${row.model} (${row.year})`;
                }
            },
            {
                title: <FormattedMessage id='add_order_form.vin' />,
                key: 'vin',
                render: row => {
                    return row.number ? `${row.number} ${row.vin}` : row.vin;
                }
            }
        ];
    }

    render() {
        const { clients, visible, setClientSelection, clientsSearching } = this.props;
        const { columns } = this;

        return (
            <Catcher>
                <Table
                    bordered
                    className={
                        visible ? Styles.clientsSearchTable : Styles.clientsSearchTableHidden
                    }
                    columns={columns}
                    dataSource={clients}
                    loading={clientsSearching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    onRow={row => {
                        return {
                            onClick: async () => {
                                const { vehicles } = await fetchAPI(
                                    'GET',
                                    `clients/${row.clientId}`
                                );
                                setClientSelection({ ...row, vehicles });
                            }
                        };
                    }}
                    pagination={false}
                    // scroll={ { y: 200 } }
                />
            </Catcher>
        );
    }
}
