import {
    fetchVehicle,
    selectClient,
    selectGeneralData,
    selectVehicle,
    setExpandedVehicleId
} from 'pages/VehiclePage/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import VehicleOrdersTable from './VehicleOrdersTable';

const mapStateToProps = state => ({
    vehicle: selectVehicle(state),
    client: selectClient(state),
    generalData: selectGeneralData(state)
});

const mapDispatchToProps = {
    fetchVehicle,
    setExpandedVehicleId
};

/**
 * Supposed to show all the orders made for current vehicle.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class GeneralInfoTab extends Component {
    render() {
        return <VehicleOrdersTable />;
    }
}
