import { Button, Drawer, Input, Select, Spin, notification } from 'antd';
import { Numeral } from 'commons';
import { InteractionRowsTable } from 'components';
import { selectCashSum } from 'core/forms/orderForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { fetchAPI, getCurrency } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const InteractionDrawerContainer = ({
    fetchInteractions,
    onClose,
    open,
    intl: { formatMessage },
    cashSum,
    user,
    setModal,
    isMobile
}) => {
    const [interaction, setInteraction] = useState();
    const [interactionRows, setInteractionRows] = useState([]);
    const [details, setDetails] = useState([]);
    const [categories, setCategories] = useState([]);
    const [stations, setStations] = useState([]);
    const [managers, setManagers] = useState([]);
    const [sources, setSources] = useState([]);
    const [types, setTypes] = useState([]);

    const [employees, setEmployees] = useState([]);
    const [stationsSearch, setStationsSearch] = useState([]);

    const [loading, setLoading] = useState();

    const [submitValues, setSubmitValues] = useState();

    const fetchInteraction = useMemo(
        () => async query => {
            setLoading(true);
            const res = await fetchAPI('GET', `/crm/interactions_doc/${open}`, null, null, {
                handleErrorInternally: true
            });

            setInteraction(res);
            setInteractionRows(res && res.documentRows);
            setDetails(res && res.orderDetails);
            setManagers(res && res.managers);
            setEmployees(res && res.employees);

            setLoading(false);
        },
        [open]
    );

    const fetchStationsSearch = useCallback(async query => {
        const response = await fetchAPI('GET', '/businesses/search', { search: query }, undefined, {
            handleErrorInternally: true
        });
        setStationsSearch(response);
    }, []);

    const debounceStations = useMemo(() => {
        const loadOptions = value => {
            fetchStationsSearch(value);
        };

        return _.debounce(loadOptions, 500);
    }, []);

    const fetchTypes = useCallback(async () => {
        const responseTypes = await fetchAPI('GET', '/crm/interations_type', null, null, {
            handleErrorInternally: true
        });

        setTypes(responseTypes);
    }, []);

    const fetchSources = useCallback(async () => {
        const responseSorces = await fetchAPI('GET', 'business/acquiring/sources', { pageSize: 50 }, null, {
            handleErrorInternally: true
        });

        setSources(responseSorces.query);
    }, []);

    const fetchCategories = useCallback(async () => {
        const responseCategories = await fetchAPI('GET', '/crm/interations_categories', null, null, {
            handleErrorInternally: true
        });

        setCategories(responseCategories);
    }, []);

    const fetchStations = useCallback(async () => {
        const response = await fetchAPI('GET', 'managers/businesses/get', { pageSize: 100 }, undefined, {
            handleErrorInternally: true
        });
        setStations(response);
    }, []);

    const getAllEmployees = async query => {
        const employees = await fetchAPI('GET', 'employees', { query }, null, { handleErrorInternally: true });
        setEmployees(employees);
    };

    const handleSearchEmployee = useMemo(() => {
        const loadOptions = value => {
            getAllEmployees(value);
        };

        return _.debounce(loadOptions, 1000);
    }, [getAllEmployees]);

    const handleOk = useMemo(
        () => async () => {
            const values = {
                id: open,
                comment: submitValues.comment || undefined,
                ...submitValues
            };

            await fetchAPI(
                'PUT',
                '/crm/interactions_doc/',
                null,
                {
                    ...values
                },
                {
                    handleErrorInternally: true
                }
            );

            await fetchInteractions();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
            
            onClose()
        },

        [fetchInteractions, formatMessage, open, onClose, submitValues]
    );

    useEffect(() => {
        if (open && interaction) {
            setSubmitValues({
                comment: interaction.comment || undefined,
                interactionCategoryId: interaction.interactionCategoryId || undefined,
                stationId: interaction.stationId || undefined,
                responsibleId: interaction.responsibleId || undefined,
                sourceId: interaction.sourceId || undefined,
                interactionTypeId: interaction.interactionTypeId || undefined
            });
        }
    }, [open, interaction]);

    useEffect(() => {
        if (open) {
            fetchInteraction();
            if (!get(categories, 'length')) {
                getAllEmployees();
                fetchCategories();
                fetchTypes();
                fetchSources();
                fetchStations();
            }

            if (get(user, 'businessId') === 3333) {
                fetchStationsSearch();
            }
        }
    }, [
        categories,
        fetchCategories,
        fetchInteraction,
        fetchSources,
        fetchStations,
        fetchStationsSearch,
        fetchTypes,
        open,
        user
    ]);

    const mask = '0,0.00';

    const showCahOrderModal = () => {
        setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                orderId: open,
                clientId: interaction.clientId,
                sum: interaction.sum
            },
            onCashOrderCreatedCallback: () => fetchInteraction()
        });
    };

    const recommendationAutoSize = { minRows: 2, maxRows: 6 };

    // const disabledActiveFields =
    //     interaction.status === 'success' ||
    //     interaction.status === 'cancel' ||
    //     !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER);

    return (
        <React.Fragment>
            <Drawer onClose={onClose} open={open} title={formatMessage({ id: 'navigation.interaction' })} width= {!isMobile ? '50%' : '100%'}>
                {loading && !interaction ? (
                    <Spin />
                ) : (
                    interaction && (
                        <div>
                            <div className={Styles.block}>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='navigation.interaction' />
                                    </span>
                                    <div className={Styles.margin}>
                                        <a
                                            href={`${book.interactions}/${open}`}
                                            rel='noopener noreferrer'
                                            target='_blank'
                                        >
                                            {interaction && interaction.document}
                                        </a>
                                    </div>
                                </span>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='storage_journal.counterparty_type' />
                                    </span>
                                    <div className={Styles.rightContent}>
                                        <FormattedMessage
                                            id={`interactions.counterparty_type.${
                                                interaction && interaction.counterpartyType
                                            }`}
                                        />
                                    </div>
                                </span>
                            </div>
                            <div className={Styles.block}>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='storage.created_datetime' />
                                    </span>
                                    <div className={Styles.margin}>
                                        {interaction.creationDate ? (
                                            dayjs(interaction.creationDate).format('DD-MM-YYYY')
                                        ) : (
                                            <FormattedMessage id='long_dash' />
                                        )}{' '}
                                    </div>
                                </span>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='interactions.next_date' />
                                    </span>
                                    <div className={Styles.rightContent}>
                                        {interaction.nextDate ? (
                                            dayjs(interaction.nextDate).format('DD-MM-YYYY')
                                        ) : (
                                            <FormattedMessage id='long_dash' />
                                        )}
                                    </div>
                                </span>
                            </div>
                            <div className={Styles.block}>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage
                                            id={
                                                interaction.counterpartyType === 'CLIENT'
                                                    ? 'client'
                                                    : interaction.counterpartyType === 'SUPPLIER'
                                                    ? 'supplier'
                                                    : 'employee'
                                            }
                                        />
                                    </span>
                                    <div className={Styles.margin}>
                                        {interaction ? (
                                            <Link to={`${book.client}/${interaction.counterpartyId}`}>
                                                {`${interaction && interaction.client}`}
                                            </Link>
                                        ) : (
                                            ''
                                        )}
                                        <div>{interaction && interaction.phone}</div>
                                    </div>
                                </span>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='status' />
                                    </span>
                                    <div className={Styles.rightContent}>{interaction.status}</div>
                                </span>
                            </div>
                            <div className={Styles.block}>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='interactions.category' />
                                    </span>
                                    <div className={Styles.margin}>
                                        <Select
                                            allowClear
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onChange={value => {
                                                setSubmitValues({
                                                    ...submitValues,
                                                    interactionCategoryId: value
                                                });
                                            }}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({
                                                id: 'search'
                                            })}
                                            showSearch
                                            style={{ marginBottom: 8, minWidth: 162, width: '100%' }}
                                            value={submitValues && submitValues.interactionCategoryId}
                                        >
                                            {categories.map(({ id, name, surname }) => (
                                                <Option key={id} value={id}>
                                                    {name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </span>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='interactions.station' />
                                    </span>
                                    <div className={Styles.margin}>
                                        <Select
                                            allowClear
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onChange={value => {
                                                setSubmitValues({
                                                    ...submitValues,
                                                    stationId: value
                                                });
                                            }}
                                            onSearch={value => {
                                                if (get(user, 'businessId') === 3333) {
                                                    debounceStations(value);
                                                }
                                            }}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({
                                                id: 'search'
                                            })}
                                            showSearch
                                            style={{ marginBottom: 8, width: '100%', minWidth: 162 }}
                                            value={submitValues && submitValues.stationId}
                                        >
                                            {get(user, 'businessId') === 3333 && stationsSearch
                                                ? stationsSearch.map(({ businessId, name }) => (
                                                      <Option key={businessId} value={businessId}>
                                                          {name} {`(#${businessId})`}
                                                      </Option>
                                                  ))
                                                : stations
                                                ? stations.map(({ businessId, businessName }) => (
                                                      <Option key={businessId} value={businessId}>
                                                          {businessName}
                                                      </Option>
                                                  ))
                                                : []}
                                        </Select>
                                    </div>
                                </span>
                            </div>
                            <div className={Styles.block}>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='responsible' />
                                    </span>
                                    <div className={Styles.margin}>
                                        <Select
                                            onChange={value => {
                                                setSubmitValues({
                                                    ...submitValues,
                                                    responsibleId: value
                                                });
                                            }}
                                            onSearch={input => handleSearchEmployee(input)}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({ id: 'add_order_form.select_manager' })}
                                            showSearch
                                            style={{
                                                minWidth: 146,
                                                width: '100%',
                                                maxWidth: 134
                                            }}
                                            value={submitValues && submitValues.responsibleId}
                                        >
                                            {employees &&
                                                employees
                                                    .filter(({ disabled }) => !disabled)
                                                    .map(({ managerId, name, surname }) => (
                                                        <Option key={managerId} value={managerId}>
                                                            {surname} {name}
                                                        </Option>
                                                    ))}
                                        </Select>
                                    </div>
                                </span>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='orders.source' />
                                    </span>
                                    <div className={Styles.margin}>
                                        <Select
                                            allowClear
                                            disabled={interaction.status === 'DONE'}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onChange={value => {
                                                setSubmitValues({
                                                    ...submitValues,
                                                    sourceId: value
                                                });
                                            }}
                                            optionFilterProp='children'
                                            placeholder={formatMessage({
                                                id: 'search'
                                            })}
                                            showSearch
                                            style={{ marginBottom: 8, width: '100%', minWidth: 146 }}
                                            value={submitValues && submitValues.sourceId}
                                        >
                                            {sources.map(({ id, sourceName }) => (
                                                <Option key={id} value={id}>
                                                    {sourceName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </span>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='storage.type' />
                                    </span>
                                    <div className={Styles.margin}>
                                        <Select
                                            allowClear
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={(value) => {
                                            setSubmitValues({
                                                ...submitValues,
                                                interactionTypeId: value
                                            })
                                        }}
                                        showSearch
                                        style={{ marginBottom: 8, width: '100%', minWidth: 146 }}
                                        value={submitValues && submitValues.interactionTypeId}
                                        optionFilterProp='children'
                                        // mode='multiple'
                                        placeholder={formatMessage({
                                            id: 'search'
                                        })}
                                        >
                                            {types.map(({ id, name }) => (
                                                <Option key={id} value={id}>
                                                    {name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </span>
                            </div>
                            <div className={Styles.block}>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='sum' />
                                    </span>
                                    <div className={Styles.margin}>
                                        <Numeral
                                            className={Styles.totalSum}
                                            currency={getCurrency()}
                                            mask={mask}
                                            nullText='0'
                                        >
                                            {interaction.sum || 0}
                                        </Numeral>
                                    </div>
                                </span>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='storage.remain' />
                                    </span>
                                    <div className={Styles.rightContent}>
                                        <Numeral
                                            className={Styles.totalSum}
                                            currency={getCurrency()}
                                            mask={mask}
                                            nullText='0'
                                        >
                                            {interaction.sum || 0}
                                        </Numeral>
                                    </div>
                                </span>
                            </div>
                            <div className={Styles.finalBlock}>
                                <span>
                                    <span className={Styles.title}>
                                        <FormattedMessage id='comment' />
                                    </span>
                                    <div className={Styles.margin}>
                                        <Input.TextArea
                                            autoSize={recommendationAutoSize}
                                            onChange={event => {
                                                setSubmitValues({
                                                    ...submitValues,
                                                    comment: event.target.value
                                                });
                                            }}
                                            placeholder={formatMessage({ id: 'comment' })}
                                            value={submitValues && submitValues.comment}
                                        />
                                    </div>
                                </span>
                            </div>

                            <div className={Styles.blockTable}>
                                <span>
                                    <div className={Styles.margin}>
                                        {open && (
                                            <InteractionRowsTable
                                                disabled={interaction.status === 'DONE'}
                                                drawerOption
                                                fetchDocData={fetchInteraction}
                                                id={open}
                                                tableData={interaction.documentRows}
                                            />
                                        )}
                                    </div>
                                </span>
                            </div>
                            <div className={Styles.okBtn}>
                                <Button onClick={handleOk} type='primary'>
                                    <FormattedMessage id='save' />
                                </Button>
                            </div>
                        </div>
                    )
                )}
            </Drawer>
            <RefactoredCashOrderModal />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    cashSum: selectCashSum(state)
});

const mapDispatchToProps = {
    setModal
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InteractionDrawerContainer));
