export default intl => [
    {
        status: 404,
        response: {
            error: 'Not Found',
            message: 'VEHICLE_NOT_FOUND',
            statusCode: 404
        },
        description: intl.formatMessage({
            id: 'vehicle_modal.not_found'
        })
    }
];
