import { Flex, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const AddRTNModal = ({ open, onCancel, orderDetails, orderServices, onOk, ...props }) => {
    const [detailsSelection, setDetailsSelection] = useState({ selectedRowKeys: [], selectedRows: [] });
    const [servicesSelection, setServicesSelection] = useState({ selectedRowKeys: [], selectedRows: [] });
    const columns = key => [
        {
            title: <FormattedMessage id={`add_order_form.${key}`} />,
            render: record => record.detailName || record.serviceName
        }
    ];
    const handleCancel = () => {
        onCancel();
    };
    const handleOk = () => {
        onOk(
            detailsSelection.selectedRowKeys,
            servicesSelection.selectedRowKeys,
            detailsSelection.selectedRows,
            servicesSelection.selectedRows
        );
        handleCancel();
    };

    return (
        <Modal
            onCancel={handleCancel}
            onOk={handleOk}
            open={open}
            title={<FormattedMessage id='new-document-page.item.order' />}
            width='fit-content'
        >
            <Flex>
                <Table
                    columns={columns('services')}
                    dataSource={orderServices}
                    pagination={false}
                    rowKey='id'
                    rowSelection={{
                        selectedRowKeys: servicesSelection.selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setServicesSelection({ selectedRowKeys, selectedRows });
                        }
                    }}
                    style={{ minWidth: 400 }}
                />
                <Table
                    columns={columns('details')}
                    dataSource={orderDetails}
                    pagination={false}
                    rowKey='id'
                    rowSelection={{
                        selectedRowKeys: detailsSelection.selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setDetailsSelection({ selectedRowKeys, selectedRows });
                        }
                    }}
                    style={{ minWidth: 400 }}
                />
            </Flex>
        </Modal>
    );
};

export default AddRTNModal;
