exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__row---3Cq8C{height:3em;font-weight:700}.styles-m__radioButtons---2XaEz{width:100%}.styles-m__radioRow---CLsd9{margin-top:1em;height:1em}.styles-m__paddingCol---1EDQ7{padding-left:1em}.styles-m__boldCol---3ipOM{font-weight:700}.styles-m__headerOrderInfo---3xccO,.styles-m__orderInfo---D0t36{text-align:right;font-weight:700}.styles-m__mainOrderInfoContainer---3GhDA{width:100%;padding:2em .2em 1em;margin-top:1em;border:.2em solid rgba(0,0,0,.2)}.styles-m__mainModalContainer---1mioL{top:5vh}.styles-m__mainModalContainer---1mioL .ant-modal-body{height:auto;overflow:auto}@media only screen and (max-width:750px){.styles-m__mainModalContainer---1mioL{top:2vh}}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,uBACI,UAAW,CACX,eACJ,CAEA,gCACI,UACJ,CAEA,4BACI,cAAe,CACf,UACJ,CACA,8BACI,gBACJ,CACA,2BACI,eACJ,CAOA,gEAJI,gBAAiB,CACjB,eAMJ,CAEA,0CACI,UAAW,CACX,oBAA4B,CAC5B,cAAe,CACf,gCACJ,CAGA,sCACI,OACJ,CAEA,sDACI,WAAY,CACZ,aACJ,CAGA,yCACI,sCACI,OACJ,CACJ","file":"styles.m.css","sourcesContent":[".row {\n    height: 3em;\n    font-weight: bold;\n}\n\n.radioButtons {\n    width: 100%;\n}\n\n.radioRow {\n    margin-top: 1em;\n    height: 1em;\n}\n.paddingCol{\n    padding-left: 1em;\n}\n.boldCol{\n    font-weight: bold;\n}\n\n.headerOrderInfo {\n    text-align: right;\n    font-weight: bold;\n}\n\n.orderInfo {\n    text-align: right;\n    font-weight: bold;\n}\n\n.mainOrderInfoContainer {\n    width: 100%;\n    padding: 2em 0.2em 1em 0.2em;\n    margin-top: 1em;\n    border: 0.2em solid rgba(0, 0, 0, 0.2);\n}\n\n\n.mainModalContainer {\n    top: 5vh;\n}\n\n.mainModalContainer :global(.ant-modal-body) {\n    height: auto;\n    overflow: auto;\n}\n\n/* Change styles for devices with different viewport width(smartphones) */\n@media only screen and (max-width: 750px) {\n    .mainModalContainer {\n        top: 2vh;\n    }\n}\n\n"]}]);

// Exports
exports.locals = {
	"row": "styles-m__row---3Cq8C",
	"radioButtons": "styles-m__radioButtons---2XaEz",
	"radioRow": "styles-m__radioRow---CLsd9",
	"paddingCol": "styles-m__paddingCol---1EDQ7",
	"boldCol": "styles-m__boldCol---3ipOM",
	"headerOrderInfo": "styles-m__headerOrderInfo---3xccO",
	"orderInfo": "styles-m__orderInfo---D0t36",
	"mainOrderInfoContainer": "styles-m__mainOrderInfoContainer---3GhDA",
	"mainModalContainer": "styles-m__mainModalContainer---1mioL"
};