import { Form } from '@ant-design/compatible';
import { Button } from 'antd';
import { onChangeClientForm, updateClient } from 'core/forms/editClientForm/duck';
import { AbstractClientForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';
import Styles from './styles.m.css';

@withReduxForm2({
    name: 'editClientForm',
    actions: {
        change: onChangeClientForm,
        updateClient
    },
    mapStateToProps: state => ({
        user: state.auth,
        isMobile: state.ui.views.isMobile
    })
})
@injectIntl
export class EditClientForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { user, updateClient, clientId, callback, disabled } = this.props;

        return (
            <React.Fragment>
                <AbstractClientForm {...this.props} />
                <Form className={Styles.editClientForm} disabled={disabled}>
                    <Button
                        className={Styles.editClientButton}
                        disabled={disabled}
                        onClick={() => {
                            this.props.form.validateFields(async (err, values) => {
                                if (!err) {
                                    const clientFormData = values;

                                    const clientEntity = {
                                        birthday: clientFormData.birthday,
                                        emails: clientFormData.emails
                                            ? clientFormData.emails.filter(Boolean)
                                            : clientFormData.emails,
                                        middlename: clientFormData.patronymic,
                                        name: clientFormData.name,
                                        type: clientFormData.type,
                                        surname: clientFormData.surname,
                                        sex: clientFormData.sex,
                                        accountId: clientFormData.accountId,
                                        status: clientFormData.status,
                                        source: clientFormData.source,
                                        sourceId: clientFormData.sourceId,
                                        barcode: clientFormData.barcode,
                                        paymentRespite: clientFormData.paymentRespite,
                                        comment: clientFormData.comment,
                                        phones: clientFormData.phones
                                            .filter(phone => phone && phone.number)
                                            .map(({ number }) => String(number)),
                                        disableAutoSms: clientFormData.disableAutoSms,
                                        markupGroupId: clientFormData.markupGroupId
                                    };
                                    await updateClient(clientId, clientEntity);

                                    if (callback) {
                                        callback();
                                    }
                                }
                            });
                        }}
                        type='primary'
                    >
                        <FormattedMessage id='save' />
                    </Button>
                </Form>
            </React.Fragment>
        );
    }
}
