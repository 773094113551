import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Rate, Table } from 'antd';
import { FormattedDatetime } from 'components';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

@injectIntl
export default class ClientFeedbackTab extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                dataIndex: 'datetime',
                width: '20%',
                render: record => <FormattedDatetime datetime={record} />
            },
            {
                width: '20%',
                render: ({ orderId, orderNum }) => this._renderOrderLink({ orderId, orderNum })
            },
            {
                dataIndex: 'rating',
                width: '20%',
                render: record => this._renderRatingStars(record)
            },
            {
                dataIndex: 'recommended',
                width: '5%',
                render: record => this._renderRecommendationIcon(record)
            },
            {
                dataIndex: 'text',
                width: '35%'
            }
        ];
    }

    _renderOrderLink = ({ orderId, orderNum }) => {
        const link = <Link to={`${book.order}/${orderId}`}>{orderNum}</Link>;

        return link;
    };

    _renderRecommendationIcon = recommended => {
        const icon = recommended ? (
            <LikeOutlined className={Styles.recommendationIcon} />
        ) : (
            <DislikeOutlined className={Styles.recommendationIcon} />
        );

        return icon;
    };

    _renderRatingStars(rating) {
        const value = rating / 2;
        const ratingStarts = (
            <Rate allowHalf className={Styles.ratingStars} defaultValue={value} disabled />
        );

        return ratingStarts;
    }

    render() {
        const { feedback } = this.props;

        const feedbackRows = feedback.map((item, index) => ({
            ...item,
            index,
            key: item.id
        }));

        return (
            <React.Fragment>
                <h2 className={Styles.title}>
                    <FormattedMessage id='client_feedback.client_feedback_about_completed_orders' />
                </h2>

                <Table
                    bordered
                    columns={this.columns}
                    dataSource={feedbackRows}
                    pagination={{
                        hideOnSinglePage: true,
                        size: 'large'
                    }}
                    showHeader={false}
                    size='small'
                />
            </React.Fragment>
        );
    }
}
