import { DatePicker, Input, InputNumber, Modal, Select, notification } from 'antd';
import { selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from '../styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,

    suppliers: state.suppliers.suppliers,
    clients: state.clients.clients
});

const mapDispatchToProps = {
    fetchSuppliers
};
const requiredField = () => <b style={{ color: 'var(--required)' }}> *</b>;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class AddRowModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storageProducts: [],
            quantity: 1,
            documentType: 'SUPPLIER',
            type: 'INCOME',
            clients: [],
            date: dayjs(),
            name: this.props.intl.formatMessage({
                id: 'debt_adjustment'
            }),
            isInputNumberActive: true
        };
        this.inputNumber = React.createRef();

        this.handleClientsSearch = _.debounce(query => {
            this.fetchClientsList(query);
        }, 1000);
    }

    componentDidMount() {
        if (!this.props.clientId && !this.props.supplierId) {
            this.props.fetchSuppliers();
            // this.fetchClients();
        }

        this.focusInput();
    }

    componentDidUpdate(prevProps) {
        console.log(this.props.supplierId);
        this.focusInput();
        if (this.props.clientId && this.props.clientId !== this.state.counterpartClientId) {
            this.setState({
                counterpartClientId: this.props.clientId,
                documentType: 'CLIENT'
            });
        }
        if (this.props.supplierId && this.props.supplierId !== this.state.counterpartBusinessSupplierId) {
            this.setState({
                counterpartBusinessSupplierId: this.props.supplierId,
                documentType: 'SUPPLIER'
            });
        }
    }

    focusInput() {
        if (this.state.isInputNumberActive && this.inputNumber.current) {
            this.inputNumber.current.focus();
        }
    }

    fetchClientsList(query) {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/clients/simple/search?query=${query}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.setState({
                    clients: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    // fetchClients = async () => {
    //     const { clients } = await fetchAPI('GET', 'clients');
    //     this.setState({ clients });
    // };

    addStoreDocProducts = async () => {
        const {
            docNumber,
            productId,
            date,
            incomeWarehouseId,
            expenseWarehouseId,
            brandId,
            quantity,
            purchasePrice,
            sellingPrice,
            type,
            counterpartBusinessSupplierId,
            counterpartClientId,
            documentType,
            name
        } = this.state;

        try {
            await fetchAPI(
                'POST',
                '/corrective_store_doc',
                null,
                {
                    docNumber,
                    productId,
                    date: date ? date.format('YYYY-MM-DD HH:mm') : undefined,
                    incomeWarehouseId,
                    expenseWarehouseId,
                    brandId,
                    count: quantity || 1,
                    price: sellingPrice || 0,
                    type,
                    counterpartBusinessSupplierId,
                    counterpartClientId,
                    documentType: 'CORRECTIVE_SERVICE',
                    name,
                    sum: (quantity || 1) * (sellingPrice || 0)
                },
                { handleErrorInternally: true }
            );
            await notification.success({
                message: this.props.intl.formatMessage({
                    id: 'storage_document.notification.correction_service_man'
                })
            });
        } catch (error) {
            await notification.error({
                message: this.props.intl.formatMessage({
                    id: 'error'
                })
            });
        }
    };

    handleOk = async () => {
        await this.addStoreDocProducts();
        this.handleCancel();
        this.props.updateData();
    };

    handleCancel = () => {
        const { hideModal } = this.props;
        this.setState({
            docNumber: undefined,
            productId: undefined,
            date: dayjs(),
            incomeWarehouseId: undefined,
            expenseWarehouseId: undefined,
            brandId: undefined,
            quantity: 1,
            purchasePrice: undefined,
            sellingPrice: undefined,
            type: 'INCOME',
            counterpartBusinessSupplierId: undefined,
            counterpartClientId: undefined,
            documentType: 'SUPPLIER',
            brandName: undefined,
            code: undefined,
            name: this.props.intl.formatMessage({
                id: 'debt_adjustment'
            })
        });
        hideModal();
    };

    getDefaultWarehouseId(warehouses) {
        const defaultWarehouse = warehouses.find(warehouse => warehouse.attribute === 'MAIN');

        return defaultWarehouse ? defaultWarehouse.id : null;
    }

    getStorageProductsByQuery = async query => {
        const { list: storageProducts } = await fetchAPI('GET', 'store_products', { query, pageSize: 25 }, null, {
            handleErrorInternally: true
        });
        this.setState({ storageProducts });
    };

    selectProduct = product => {
        this.setState({
            productId: product.id,
            brandName: product.brandName,
            code: product.code,
            purchasePrice: product.purchasePrice,
            sellingPrice: product.sellingPrice
        });
    };

    render() {
        const { visible, user, warehouses, suppliers, clientId, client, supplierId, supplierName } = this.props;
        const {
            docNumber,
            code,
            date,
            incomeWarehouseId,
            expenseWarehouseId,
            brandName,
            quantity,
            purchasePrice,
            sellingPrice,
            storageProducts,
            type,
            counterpartBusinessSupplierId,
            counterpartClientId,
            documentType,
            clients,
            name,
            laborId,
            searchValue
        } = this.state;
        const counterpartDisabled = clientId || supplierId;

        return (
            <Modal
                okButtonProps={{
                    disabled: !date || !name || !quantity || !sellingPrice
                }}
                onCancel={this.handleCancel}
                maskClosable={false}
                onOk={this.handleOk}
                title={<FormattedMessage id='storage_journal.add_row' />}
                visible={visible}
                width='90%'
            >
                <div className={Styles.blockStyle}>
                    <div>
                        <div className={Styles.rowStyles}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: 12 }}>
                                    <FormattedMessage id='storage.document_number' />
                                    <Input
                                        onChange={event => {
                                            this.setState({ docNumber: event.target.value });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'storage.document_placeholder'
                                        })}
                                        style={{
                                            marginTop: 4
                                        }}
                                        value={docNumber}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage id='storage.document_date' />
                                    {requiredField()}
                                    <DatePicker
                                        allowClear
                                        format='DD.MM.YYYY'
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => {
                                            this.setState({
                                                date: value
                                            });
                                        }}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'select_date'
                                        })}
                                        style={{
                                            width: '100%',
                                            marginTop: 4
                                        }}
                                        value={date ? dayjs(date) : null}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='description' />
                            {requiredField()}
                            <Input
                                onChange={event => {
                                    this.setState({
                                        name: event.target.value
                                    });
                                }}
                                style={{
                                    marginTop: 4
                                }}
                                value={this.state.name}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='storage_journal.counterparty_type' />
                            <Select
                                disabled={counterpartDisabled}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={documentType => {
                                    this.setState({
                                        documentType
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'storage_journal.choose_type'
                                })}
                                style={{
                                    marginTop: 4
                                }}
                                value={documentType}
                            >
                                <Option value='SUPPLIER'>
                                    <FormattedMessage id='storage.business_supplier' />
                                </Option>
                                <Option value='CLIENT'>
                                    <FormattedMessage id='storage.client' />
                                </Option>
                            </Select>
                        </div>
                        {documentType === 'CLIENT' && (
                            <div className={Styles.rowStyles}>
                                <FormattedMessage id='storage.client' />
                                <Select
                                    disabled={documentType !== 'CLIENT' || counterpartDisabled}
                                    filterOption={(input, option) => {
                                        const searchValue = option.children.toLowerCase().replace(/[+()-\s]/g, '');
                                        const inputValue = input.toLowerCase();

                                        return searchValue.indexOf(inputValue) >= 0;
                                    }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={value => {
                                        this.setState({ counterpartClientId: value });
                                    }}
                                    onSearch={async input => {
                                        this.setState({
                                            searchValue: input
                                        });
                                        if (input.length >= 2) {
                                            await this.handleClientsSearch(input);
                                        }
                                    }}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'storage.choose_client_placeholder'
                                    })}
                                    showSearch
                                    style={{
                                        marginTop: 4
                                    }}
                                    value={counterpartClientId}
                                >
                                    {!clientId && !supplierId ? (
                                        clients.map(client => (
                                            <Option
                                                key={client.clientId}
                                                phones={client.phones}
                                                value={client.clientId}
                                            >
                                                {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${
                                                    client.phones[0]
                                                }`}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option key={clientId} value={clientId}>
                                            {client
                                                ? `${client.surname || ''} ${client.name} ${client.middleName || ''} ${
                                                      client.phones[0]
                                                  }`
                                                : undefined}
                                        </Option>
                                    )}
                                </Select>
                            </div>
                        )}
                        {documentType === 'SUPPLIER' && (
                            <div className={Styles.rowStyles}>
                                <FormattedMessage id='storage.business_supplier' />
                                <Select
                                    disabled={documentType !== 'SUPPLIER' || counterpartDisabled}
                                    filterOption={(input, option) => {
                                        const searchValue = option.children.toLowerCase().replace(/[+()-\s]/g, '');
                                        const inputValue = input.toLowerCase();

                                        return searchValue.indexOf(inputValue) >= 0;
                                    }}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={counterpartBusinessSupplierId =>
                                        this.setState({ counterpartBusinessSupplierId })
                                    }
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'storage.choose_supplier_placeholder'
                                    })}
                                    showSearch
                                    style={{
                                        marginTop: 4
                                    }}
                                    value={counterpartBusinessSupplierId}
                                >
                                    {!clientId && !supplierId ? (
                                        suppliers.map(supplier => (
                                            <Option key={supplier.id} value={supplier.id}>
                                                {supplier.name}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option key={supplierId} value={supplierId}>
                                            {supplierName}
                                        </Option>
                                    )}
                                </Select>
                            </div>
                        )}
                    </div>
                    <div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='storage.operation_type' />
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={type => {
                                    this.setState({
                                        type
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'storage_journal.choose_type'
                                })}
                                style={{
                                    marginTop: 4
                                }}
                                value={type}
                            >
                                <Option value='INCOME'>
                                    <FormattedMessage id='storage.sign_-' />
                                </Option>
                                <Option value='EXPENSE'>
                                    <FormattedMessage id='storage.sign_+' />
                                </Option>
                            </Select>
                        </div>
                        {type === 'INCOME' && (
                            <div className={Styles.rowStyles}>
                                <FormattedMessage id='storage_journal.storage_income' />
                                {incomeWarehouseId || expenseWarehouseId ? null : requiredField()}
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={incomeWarehouseId => {
                                        this.setState({
                                            incomeWarehouseId
                                        });
                                    }}
                                    style={{
                                        marginTop: 4
                                    }}
                                    value={incomeWarehouseId || this.getDefaultWarehouseId(warehouses)}
                                >
                                    {warehouses.map(elem => (
                                        <Option key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                        {type === 'EXPENSE' && (
                            <div className={Styles.rowStyles}>
                                <FormattedMessage id='storage_journal.storage_outcome' />
                                {incomeWarehouseId || expenseWarehouseId ? null : requiredField()}
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={expenseWarehouseId => {
                                        this.setState({
                                            expenseWarehouseId
                                        });
                                    }}
                                    style={{
                                        marginTop: 4
                                    }}
                                    value={expenseWarehouseId || this.getDefaultWarehouseId(warehouses)}
                                >
                                    {warehouses.map(elem => (
                                        <Option key={elem.id} value={elem.id}>
                                            {elem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            paddingTop: 22
                        }}
                    >
                        <div
                            style={{
                                marginBottom: 8
                            }}
                        >
                            <span
                                style={{
                                    marginRight: 22
                                }}
                            >
                                <FormattedMessage id='storage_journal.amount' />
                                {requiredField()}
                            </span>

                            <InputNumber
                                decimalSeparator=','
                                disabled
                                min={0}
                                onChange={quantity => this.setState({ quantity })}
                                precision={2}
                                value={quantity}
                            />
                        </div>

                        <div className={Styles.rowStyles}>
                            <span
                                style={{
                                    marginRight: 45
                                }}
                            >
                                <FormattedMessage id='sum' />
                                {requiredField()}
                            </span>
                            <InputNumber
                                ref={this.inputNumber}
                                decimalSeparator=','
                                min={0}
                                onBlur={() => this.setState({ isInputNumberActive: false })}
                                onChange={sellingPrice => this.setState({ sellingPrice })}
                                precision={2}
                                value={sellingPrice}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
