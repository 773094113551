/** ------------------------------------- Constants ------------------------------------- * */
export const moduleName = 'rst';
const prefix = `cpb/refactoredCashOrderModal/${moduleName}`;

export const SERVICE_INPUT = `${prefix}/SERVICE_INPUT`;
export const SERVICE_OUTPUT = `${prefix}/SERVICE_OUTPUT`;
export const TRANSFER = `${prefix}/TRANSFER`;

export const REGISTER_SERVICE_INPUT_CASH_ORDER_IN_CASHDESK = `${prefix}/REGISTER_SERVICE_INPUT_CASH_ORDER_IN_CASHDESK`;
export const REGISTER_SERVICE_OUTPUT_CASH_ORDER_IN_CASHDESK = `${prefix}/REGISTER_SERVICE_OUTPUT_CASH_ORDER_IN_CASHDESK`;

/** ------------------------------------- Reducer ------------------------------------- * */
const ReducerState = {};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        default:
            return state;
    }
}

/* ------------------------------------- Selectors ------------------------------------- */

/* ------------------------------------- Action Creators -------------------------- */

/**
 * Register cashOrder in cashdesk if it is service input(cashorder of type "INCOME" without clientId but with otherCounterparty)
 * @param {*} params.cashOrderId
 * @returns
 */
export const registerServiceInputCashOrderInCashdesk = ({ cashOrderId }) => ({
    type: REGISTER_SERVICE_INPUT_CASH_ORDER_IN_CASHDESK,
    payload: { cashOrderId }
});

/**
 * Register cashOrder in cashdesk if it is service output(cashorder of type "EXPENSE")
 * @param {*} params.cashOrderId
 */
export const registerServiceOutputCashOrderInCashdesk = ({ cashOrderId }) => ({
    type: REGISTER_SERVICE_OUTPUT_CASH_ORDER_IN_CASHDESK,
    payload: { cashOrderId }
});

/**
 * Внести гроші в касу
 * @returns
 */
export const serviceInput = () => ({
    type: SERVICE_INPUT
});

/**
 * Інкасація(видача) частини грошей з каси
 * @returns
 */
export const serviceOutput = () => ({
    type: SERVICE_OUTPUT
});

export const serviceTransfer = () => ({
    type: TRANSFER
});
