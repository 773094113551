import { FilterFilled, PlusOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Input, Modal, Select, Table, Tooltip, notification } from 'antd';
import { Numeral } from 'commons';
import { DateRangePicker } from 'components';
import { MODALS, setModal } from 'core/modals/duck';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DebtPairResultsModal, UnpairAllActionsBtnsModal } from 'modals';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import { CASH_ORDER_TYPES, COUNTERPARTY_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import { AddRowModal } from 'pages/Storage/StorageJournalPage/modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getCurrency, journalTypes } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import columnsConfig from './config';
import Styles from './styles.m.css';

const DEF_DATE_FORMAT = 'DD/MM/YYYY';

const { Option } = Select;

/**
 * This table contains store docks related to supplier,
 * each store doc has its own cash orders, they are available
 * via expanding row.
 */

const mapStateToProps = state => ({
    user: state.auth,
    businessRequisites: selectBusinessRequisites(state),
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    setModal,
    fetchBusinessRequisites,
    fetchWarehouses
};

const { confirm } = Modal;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            stats: {},
            datetime: dayjs(),
            pairSelectedIds: [],
            page: 1,
            pageSize: 25,
            supplierRequisites: [],
            labors: [],
            isSupplierDebt: true
        };
    }

    componentDidMount() {
        this.fetchDebts();
        this.props.fetchBusinessRequisites();
        this.fetchData();
        this.fetchLabors();
        this.props.fetchWarehouses();
    }

    getStoreDocProducts = async () => {
        const {
            dateRange,
            docTime,
            page,
            pageSize,
            filterDocumentNumber,
            filterDocumentType,
            filterSign,
            filterCounterpartId,
            filterCounterpartType,
            filterCounterpartName,
            filterRelatedDoc,
            filterBusinessRequisiteId,
            filterCounterpartRequisiteId
        } = this.state;
        const data = await fetchAPI(
            'GET',
            '/journal_calculations',
            {
                page,
                pageSize,
                // startDate: _.get(dateRange, '[0]') ? dateRange[0].format('YYYY-MM-DD') : undefined,
                // endDate: _.get(dateRange, '[1]') ? dateRange[1].format('YYYY-MM-DD') : undefined,
                filterDocumentNumber,
                filterDocumentType,
                filterSign,
                filterBusinessRequisiteId,
                filterCounterpartRequisiteId,
                filterCounterpartId,
                filterCounterpartType,
                filterStartDocDate: _.get(docTime, '[0]') ? docTime[0].format('YYYY-MM-DD') : undefined,
                filterEndDocDate: _.get(docTime, '[1]') ? docTime[1].format('YYYY-MM-DD') : undefined,
                filterCounterpartName,
                filterRelatedDoc,
                filterStartDate: _.get(dateRange, '[0]') ? dateRange[0].format('YYYY-MM-DD') : undefined,
                filterEndDate: _.get(dateRange, '[1]') ? dateRange[1].format('YYYY-MM-DD') : undefined
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            dataSource: data.res,
            stats: data.stats,
            sum: data.sum
        });
    };

    fetchLabors = async () => {
        const data = await fetchAPI('GET', '/labors', null, null, {
            handleErrorInternally: true
        });

        this.setState({ labors: data.labors });
    };

    createPair = async () => {
        const { user } = this.props;
        const { pairSelectedIds, pairSign: cashOrderSign, singleType } = this.state;

        await fetchAPI('GET', 'debts/pairing', {
            cashOrderSign: singleType === 'CASH' ? cashOrderSign : cashOrderSign === '+' ? '-' : '+',
            pairingIds: cashOrderSign === '+' ? pairSelectedIds.slice(1) : [pairSelectedIds[0]],
            cashOrderIds: cashOrderSign === '+' ? [pairSelectedIds[0]] : pairSelectedIds.slice(1),
            managerId: user.employeeId
        });
        this.setState({
            pairSelectedIds: [],
            pairSign: undefined
        });
        this.fetchDebts();
    };

    deletePair = async ({ pairingIds, cashOrderSign, cashOrderId }) => {
        try {
            await fetchAPI(
                'DELETE',
                'debts/pairing',
                {
                    pairingIds,
                    cashOrderSign,
                    cashOrderId
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            this.setState({
                pairSelectedIds: [],
                pairSign: undefined
            });
            this.fetchDebts();
        } catch (err) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'client-mrds-table.unpair_error' })
            });
        }
    };

    unpairAll = async () => {
        const { id } = this.props;

        // await fetchAPI('DELETE', 'debts/client/unpair', {
        //     clientId: id
        // });
        // this.fetchDebts();
    };

    fetchDebts = async () => {
        const { counterpartyType } = this.props;
        const {
            page,
            pageSize,
            filterSign,
            filterDocumentType,
            datetimeFrom,
            datetimeTo,
            nonZeroRows,
            filterBusinessRequisiteId,
            filterCounterpartRequisiteId
        } = this.state;
        const { stats, list } = await fetchAPI(
            'GET',
            'report/debts',
            {
                counterpartId: this.props.id,
                counterpartyType,
                datetime: this.state.datetime.toISOString(),
                page,
                pageSize,
                filterSign,
                filterDocumentType,
                datetimeFrom: datetimeFrom ? datetimeFrom[0].format('YYYY-MM-DD') : undefined,
                datetimeTo: datetimeTo ? datetimeTo[1].format('YYYY-MM-DD') : undefined,
                nonZeroRows,
                filterBusinessRequisiteId,
                filterCounterpartRequisiteId
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            stats: { ...stats, count: _.get(list, 'stats[0].count', 0) },
            list: _.get(list, 'debsObject', [])
        });
    };

    fetchData = async () => {
        const data = await fetchAPI('GET', `business_suppliers/${this.props.id}`, null, null, {
            handleErrorInternally: true
        });
        data.requisites.map((elem, key) => {
            elem.key = key;
        });
        this.setState({
            supplierRequisites: data.requisites
        });
    };

    _showCashOrderModal = row => {
        const { setModal, id } = this.props;
        const { remaining, storeDocId, documentType, sign } = row;
        const { generalInfo } = this.state;

        if (remaining > 0) {
            setModal(MODALS.REFACTORED_CASH_ORDER, {
                initValues: {
                    type: sign === '-' ? CASH_ORDER_TYPES.EXPENSE : CASH_ORDER_TYPES.INCOME,
                    counterpartyType: COUNTERPARTY_TYPES.BUSINESS_SUPPLIER,
                    sum: remaining,
                    supplierId: Number(id),
                    name: _.get(generalInfo, 'name'),
                    supplierStoreDocId: storeDocId
                },
                onCashOrderCreatedCallback: () => this.fetchDebts()
            });
        }

        if (remaining < 0) {
            setModal(MODALS.REFACTORED_CASH_ORDER, {
                initValues: {
                    type: CASH_ORDER_TYPES.INCOME,
                    counterpartyType: COUNTERPARTY_TYPES.BUSINESS_SUPPLIER,
                    sum: Math.abs(remaining), // Only positive numbers can be supplied here
                    supplierId: Number(id),
                    name: _.get(generalInfo, 'name'),
                    supplierStoreDocId: storeDocId
                },
                onCashOrderCreatedCallback: () => this.fetchDebts()
            });
        }
    };

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.setState({
                        [dataIndex]: e.target.value
                    });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.state[dataIndex]}
            />
        );

        if (dataIndex === 'datetimeFrom') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={this.state[dataIndex]}
                        format={DEF_DATE_FORMAT}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.setState({
                                [dataIndex]: dateRange
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.fetchDebts();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        if (dataIndex === 'filterDocumentType') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.setState({
                            filterDocumentType: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.state.filterDocumentType}
                    zIndex={9999}
                >
                    {journalTypes.map(type => (
                        <Option key={type} value={type}>
                            {type} - <FormattedMessage id={`storage_document.docType.${type}`} />
                        </Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'filterSign') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={value => {
                        this.setState({
                            filterSign: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.state.filterSign}
                >
                    <Option value='+'>
                        <FormattedMessage id='storage.sign_+' />
                    </Option>
                    <Option value='-'>
                        <FormattedMessage id='storage.sign_-' />
                    </Option>
                </Select>
            );
        }

        if (dataIndex === 'filterBusinessRequisiteId' || dataIndex === 'filterCounterpartRequisiteId') {
            filterComponent = (confirm, clearFilters) => (
                <div
                    onKeyDown={e => e.stopPropagation()}
                    style={{
                        padding: 8
                    }}
                >
                    <Select
                        allowClear
                        onChange={value => {
                            this.setState({
                                [dataIndex]: value
                            });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'storage.business_requisites'
                        })}
                        showSearch
                        style={{ width: 280 }}
                        value={this.state[dataIndex]}
                    >
                        {dataIndex === 'filterBusinessRequisiteId'
                            ? this.props.businessRequisites.map(elem => (
                                  <Select.Option key={elem.id} value={elem.id}>
                                      {elem.name}
                                  </Select.Option>
                              ))
                            : this.state.supplierRequisites.map(elem => (
                                  <Select.Option key={elem.id} value={elem.id}>
                                      {elem.name}
                                  </Select.Option>
                              ))}
                    </Select>
                </div>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'datetimeFrom' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.fetchDebts();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1 });
        this.fetchDebts();
    };

    render() {
        const {
            intl: { formatMessage },
            user,
            id,
            name,
            disabled
        } = this.props;

        const {
            list,
            stats,
            datetime,
            pairSelectedIds,
            pairSign,
            singleType,
            visibleDebtPairResultsModal,
            page,
            nonZeroRows,
            pageSize,
            visibleUnpairAllActionsBtnsModal,
            visibleAddRowModal,
            labors,
            isSupplierDebt
        } = this.state;

        const pagination = {
            pageSize,
            total: Math.ceil(_.get(stats, 'count', 0) / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchDebts();
            }
        };

        return (
            <React.Fragment>
                <div className={Styles.headerContainer}>
                    <Tooltip title={<FormattedMessage id='add' />}>
                        <Button
                            disabled={
                                disabled ||
                                !isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_CORRECTION)
                            }
                            icon={<PlusOutlined />}
                            onClick={() => {
                                this.setState({ visibleAddRowModal: true });
                            }}
                            style={{
                                marginRight: 8
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='storage.reset_all_filters' />}>
                        <Button
                            icon={<RedoOutlined />}
                            onClick={async () => {
                                await this.setState({
                                    dateRange: undefined,
                                    filterSign: undefined,
                                    page: 1,
                                    datetimeFrom: undefined,
                                    filterDocumentType: undefined,
                                    businessRequisiteName: undefined
                                });
                                this.fetchDebts();
                            }}
                            style={{
                                marginRight: 8
                            }}
                        />
                    </Tooltip>
                    <DatePicker
                        allowClear={false}
                        format={DEF_DATE_FORMAT}
                        onChange={async datetime => {
                            await this.setState({ datetime });
                            this.fetchDebts();
                        }}
                        value={datetime}
                    />
                    {isGrantAccessed(
                        this.props.user,
                        grants.DIRECTORIES_SUPPLIERS_LIST_OUTSTANDING_DEBTS_PAIRING_AND_UNPAIRING,
                        accesses.WA
                    ) && (
                        <div
                            style={{
                                marginLeft: 12,
                                paddingTop: 4
                            }}
                        >
                            <Button
                                disabled={_.get(stats, 'oweAmount.debt') === _.get(stats, 'oweAmount.sum')}
                                onClick={() => {
                                    confirm({
                                        title: formatMessage({
                                            id: 'order_stats_info_modal.sure_to_recalculate'
                                        }),
                                        content: (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            Modal.destroyAll();
                                                        }}
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'cancel'
                                                        })}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        onClick={async () => {
                                                            await fetchAPI(
                                                                'POST',
                                                                'recalculate/debts',
                                                                null,
                                                                {
                                                                    counterpartyId: this.props.id,
                                                                    counterpartyType: 'supplier'
                                                                },
                                                                {
                                                                    handleErrorInternally: true
                                                                }
                                                            );
                                                            this.fetchDebts();
                                                            Modal.destroyAll();
                                                        }}
                                                        type='primary'
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'general_settings.submit'
                                                        })}
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                        footer: null
                                    });
                                }}
                            >
                                <FormattedMessage id='order_stats_info_modal.recalculate' />
                            </Button>
                        </div>
                    )}

                    <div
                        style={{
                            marginLeft: 12,
                            paddingTop: 4
                        }}
                    >
                        <span
                            style={{
                                marginRight: 12
                            }}
                        >
                            {' '}
                            <FormattedMessage id='supplier_debts.non_zero_rows' />
                        </span>
                        <Checkbox
                            checked={nonZeroRows}
                            onChange={async () => {
                                await this.setState({ nonZeroRows: !nonZeroRows, page: 1 });
                                this.fetchDebts();
                            }}
                        />
                    </div>
                </div>
                <div className={Styles.tableContainer}>
                    <Table
                        bordered
                        className={Styles.table}
                        columns={columnsConfig({
                            disabled,
                            user,
                            showCashOrderModal: this._showCashOrderModal,
                            getColumnSearchProps: this.getColumnSearchProps,
                            handleSearch: this.handleSearch,
                            handleReset: this.handleReset,
                            nonZeroRowsCheckbox: this.nonZeroRowsCheckbox,
                            formatMessage,
                            pairSelectedIds,
                            pairSign,
                            nonZeroRows,
                            fetchDebts: this.fetchDebts,
                            singleType,
                            onPair: ({ id, sign, cashOrderId }) => {
                                if (pairSelectedIds.includes(id)) {
                                    this.setState({
                                        pairSelectedIds: pairSelectedIds.filter(el => el !== id),
                                        pairSign: pairSelectedIds.length > 1 ? pairSign : undefined,
                                        singleType: pairSelectedIds.length > 1 ? singleType : undefined
                                    });
                                } else {
                                    pairSelectedIds.push(id);
                                    this.setState({
                                        pairSelectedIds,
                                        pairSign: pairSign || sign,
                                        singleType: singleType || cashOrderId ? 'CASH' : 'ORDER'
                                    });
                                }
                            },
                            onUnpair: this.deletePair,
                            createPair: this.createPair,
                            showDebtPairResultsModal: row => this.setState({ visibleDebtPairResultsModal: row }),
                            unpairAllActionsBtns: row => this.setState({ visibleUnpairAllActionsBtnsModal: row })
                        })}
                        dataSource={list}
                        pagination={pagination}
                        rowKey='rowId'
                        size='small'
                        summary={() => {
                            return (
                                <React.Fragment>
                                    <Table.Summary.Row fixed>
                                        <Table.Summary.Cell className={Styles.hidden} index={0}></Table.Summary.Cell>
                                        <Table.Summary.Cell className={Styles.hidden} index={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell className={Styles.hidden} index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell className={Styles.hidden} index={3}></Table.Summary.Cell>
                                        <Table.Summary.Cell className={Styles.hidden} index={4}></Table.Summary.Cell>
                                        <Table.Summary.Cell className={Styles.hidden} index={5}></Table.Summary.Cell>
                                        <Table.Summary.Cell className={Styles.hidden} index={6}></Table.Summary.Cell>
                                        <Table.Summary.Cell className={Styles.summaryWrap} index={7}>
                                            <div className={Styles.summary}>
                                                <Numeral mask='0,0.00'>{_.get(stats, 'oweAmount.sum')}</Numeral>
                                                {getCurrency()}
                                            </div>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell className={Styles.summaryWrap} index={8}>
                                            <div className={Styles.summary}>
                                                <Numeral mask='0,0.00'>{_.get(stats, 'oweAmount.debt')}</Numeral>
                                                {getCurrency()}
                                            </div>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </React.Fragment>
                            );
                        }}
                    />
                </div>
                <DebtPairResultsModal
                    hideModal={() => {
                        this.setState({ visibleDebtPairResultsModal: false });
                    }}
                    row={visibleDebtPairResultsModal}
                    visible={Boolean(visibleDebtPairResultsModal)}
                />
                <UnpairAllActionsBtnsModal
                    hideModal={() => {
                        this.setState({ visibleUnpairAllActionsBtnsModal: false });
                    }}
                    row={visibleUnpairAllActionsBtnsModal}
                    unpairAll={this.unpairAll}
                    visible={Boolean(visibleUnpairAllActionsBtnsModal)}
                />
                <AddRowModal
                    hideModal={() => {
                        this.setState({ visibleAddRowModal: false });
                    }}
                    isSupplierDebt={isSupplierDebt}
                    labors={labors}
                    supplierId={id}
                    supplierName={name}
                    updateData={this.getStoreDocProducts}
                    visible={visibleAddRowModal}
                    warehouses={this.props.warehouses}
                />
            </React.Fragment>
        );
    }
}
