import { get } from 'lodash';

export const normalizeChartQuery = query => {
    const { startDate, endDate } = query;

    return { ...query, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };
};

export const normalizeCompareChartQuery = (query, period = 'none') => {
    const { startDate, endDate } = query;

    if (period === 'none') {
        return normalizeChartQuery(query);
    }

    return {
        ...query,
        startDate: startDate.subtract(1, period).format('YYYY-MM-DD'),
        endDate: endDate.subtract(1, period).format('YYYY-MM-DD')
    };
};

export const combineCharts = (chart1 = [], chart2 = []) => {
    // const array = [...chart1];
    // chart2.forEach(({ date, value }) => {
    //     const index = array.findIndex(el => el.date === date);
    //     if (index !== -1) {
    //         array[index].comp = value;
    //     }
    // });

    // return array;
    return chart1.map((dt, index) => ({ ...dt, comp: get(chart2, `[${index}].value`) }));
    // return uniqBy([...chart1, ...chart2.map(dt => ({ ...dt, comp: dt.value }))], 'date');
};
