import { Modal, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const initialData = {
    byCoefficient: 1,
    fixedPrice: 1,
    bySellCoefficient: 1,
    bySellFixedPrice: 1,
    fixedDiscount: 0,
    minMarkup: 10
};

const { Option } = Select;

const ChangeCounterpartyRowsModal = ({
    open,
    intl: { formatMessage },
    updateDataSource,
    orderId,
    selectedRows,
    type,
    hideModal,
    suppliers,
    crews,
    handleSearchSuppliers
}) => {
    const [supplierId, setSupplierId] = useState();
    const [crewId, setCrewId] = useState();

    const handleClose = useCallback(() => {
        setSupplierId(undefined);
        setCrewId(undefined);
        hideModal();
    }, [hideModal]);

    const handleOk = useCallback(async () => {
        const payload = {
            updateMode: true,
            services: [
                ...selectedRows.map(({ id, laborId }) => ({
                    id,
                    counterparty: type,
                    businessSupplierId: type === 'SUPPLIER' ? supplierId : null,
                    crewId: type === 'CREW' ? crewId : null,
                    employeeId: null,
                    serviceId: laborId
                }))
            ]
        };
        await fetchAPI('PUT', `orders/${orderId}`, undefined, payload);
        await updateDataSource();
        handleClose();
    }, [crewId, handleClose, orderId, selectedRows, supplierId, type, updateDataSource]);

    return (
        <Modal
            maskClosable={false}
            onCancel={() => handleClose()}
            onOk={() => handleOk()}
            open={open}
            title={
                type === 'SUPPLIER' ? (
                    <FormattedMessage id='order_form_table_requests.appoint_supplier' />
                ) : (
                    <FormattedMessage id='order_form_table_requests.appoint_crew' />
                )
            }
        >
            {type === 'SUPPLIER' ? (
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={(value, option) => {
                        setSupplierId(value);
                    }}
                    onSearch={input => handleSearchSuppliers(input)}
                    optionFilterProp='children'
                    placeholder={formatMessage({
                        id: 'storage_document.supplier_select'
                    })}
                    showAction={['focus', 'click']}
                    showSearch
                    style={{ minWidth: 150, color: 'var(--text)' }}
                    value={supplierId}
                >
                    {suppliers.map(elem => (
                        <Option key={elem.id} value={elem.id}>
                            {elem.name}
                        </Option>
                    ))}
                </Select>
            ) : (
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={(value, option) => {
                        setCrewId(value);
                    }}
                    optionFilterProp='children'
                    placeholder={formatMessage({
                        id: 'crews.title_crews'
                    })}
                    showAction={['focus', 'click']}
                    showSearch
                    style={{ minWidth: 150, color: 'var(--text)' }}
                    value={crewId}
                >
                    {crews.map(elem => (
                        <Option key={elem.id} value={elem.id}>
                            {elem.crewName}
                        </Option>
                    ))}
                </Select>
            )}
        </Modal>
    );
};

export default injectIntl(ChangeCounterpartyRowsModal);
