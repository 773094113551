import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Input } from 'antd';
import { Catcher, Spinner } from 'commons';
import { Like, NPS, ReviewRating } from 'components';
import { postReviewReply } from 'core/review/duck';
import { fetchReviews, setReviewsPageFilter } from 'core/reviews/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    reviews: state.reviews.reviews,
    filter: state.reviews.filter,
    stats: state.reviews.stats,
    isFetching: state.ui.reviewsFetching
});

const mapDispatchToProps = {
    fetchReviews,
    setReviewsPageFilter,
    postReviewReply
};

// @withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class OverLookTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reply: null,
            helperDrawerOpen: false,
            allLinks: []
        };
    }

    componentDidMount() {
        this.props.fetchReviews();
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'order_mrd_respond' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const {
            totalRating,
            npsRating,
            repairQualityRating,
            repairDurationRating,
            comfortRating,
            serviceQualityRating,
            total,
            recommended,
            notRecommended
        } = this.props.stats;

        const { reply, loading, helperDrawerOpen, allLinks } = this.state;

        const { idOfOrder, reviews, isFetching, postReviewReply } = this.props;

        const [currentComment] = reviews.filter(({ orderId }) => orderId == idOfOrder);

        return isFetching ? (
            <Spinner spin={isFetching} />
        ) : (
            <Catcher>
                <div
                    style={{
                        paddingTop: 8,
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            this.setState({
                                helperDrawerOpen: true
                            });
                            await this.fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22
                        }}
                        type='text'
                    />
                </div>
                <div>
                    <div className={Styles.column}>
                        <span className={Styles.title}>
                            <NPS label mode='scale' nps={currentComment ? currentComment.nps : 0} />
                        </span>
                        <section className={Styles.reviewRating}>
                            <ReviewRating
                                color='var(--primary)'
                                comfort={currentComment ? currentComment.comfort : 0}
                                repairDuration={currentComment ? currentComment.repairDuration : 0}
                                repairQuality={currentComment ? currentComment.repairQuality : 0}
                                serviceQuality={currentComment ? currentComment.serviceQuality : 0}
                            />
                            <Like like={currentComment ? currentComment.recommended : false} text />
                        </section>
                    </div>
                </div>
                <div className={Styles.blockStyle}>
                    <span>
                        <FormattedMessage id='orders.review' />:
                    </span>{' '}
                    <span
                        style={{
                            fontWeight: 700
                        }}
                    >
                        {currentComment ? currentComment.text : ''}
                    </span>
                </div>
                {currentComment && !currentComment.replyText ? (
                    <div className={Styles.blockStyle}>
                        <span>
                            <FormattedMessage id='orders.review_answer' />
                        </span>
                        <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            onChange={event => {
                                this.setState({
                                    reply: event.target.value
                                });
                            }}
                            rules={[
                                {
                                    max: 2000
                                }
                            ]}
                            value={reply}
                        />

                        <div>
                            <Button
                                disabled={loading}
                                onClick={() => {
                                    this.setState({ loading: true });
                                    postReviewReply(currentComment.id, reply);
                                }}
                                type='primary'
                            >
                                <FormattedMessage id='orders.review_answer_button' />
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <span>
                            <FormattedMessage id='review-response.respond_to_customer' />:
                        </span>{' '}
                        <span className={Styles.feedbackText}>{currentComment ? currentComment.replyText : ''}</span>
                    </div>
                )}
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Catcher>
        );
    }
}
