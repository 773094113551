/* eslint-disable max-classes-per-file */
import { Form } from '@ant-design/compatible';
import { Button, Checkbox, DatePicker, Input, InputNumber, Modal, Radio, Row, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, isForbidden, permissions } from 'utils';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

const formItemStyle = {
    labelAlign: 'left',
    style: {
        marginBottom: 4,
        display: 'flex',
        alignItems: 'center'
    }
};

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@injectIntl
@Form.create()
@connect(mapStateToProps, void 0)
export class RequisiteSettingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formType: 'ENTREPRENEUR',
            requisiteData: undefined,
            treeState: []
        };
        this.fields = [
            'formType',
            'name',
            'address',
            'ifi',
            'ca',
            'bank',
            'isTaxPayer',
            'taxRate',
            'calculationMethod',
            'enabled',
            'accountId'
        ];
    }

    handleSubmit = async e => {
        const id = this.props.requisiteData && this.props.requisiteData.id;
        const { updateRequisite, postRequisite, id: clientId } = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                console.log(values);
                values.itn = values.itn || values.ifi;
                values.contractDate = values.contractDate ? dayjs(values.contractDate).format('YYYY-MM-DD') : null; // needed `cause problems with back-end
                if (values.formName) values.formType = null;
                if (isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_REQUISITES_TAX_CRUD)) {
                    delete values.isTaxPayer;
                    delete values.taxRate;
                    delete values.calculationMethod;
                }
                if (id) {
                    await updateRequisite(id, values);
                } else {
                    await postRequisite(values, clientId);
                }
                this.props.hideModal(true);
            }
        });
    };

    componentDidMount() {
        const { requisiteData, user, clientReq } = this.props;
        const { treeState } = this.state;

        if (!_.get(this.props.accounts, 'length') && clientReq) {
            this.props.getAccountsList();
        }

        if (requisiteData) {
            this.props.form.setFieldsValue({
                formType: requisiteData.formType || 'OTHER',
                formName: requisiteData.formType ? undefined : requisiteData.formName,
                name: requisiteData.name,
                address: requisiteData.address,
                ifi: requisiteData.ifi,
                ca: requisiteData.ca,
                bank: requisiteData.bank,
                isTaxPayer: Boolean(requisiteData.isTaxPayer),
                taxRate: requisiteData.taxRate || 20,
                calculationMethod: requisiteData.calculationMethod,
                enabled: Boolean(requisiteData.enabled),
                ipn: requisiteData.ipn,
                certificateNumber: requisiteData.certificateNumber,
                contractNumber: requisiteData.contractNumber,
                contractDate: _.get(requisiteData, 'contractDate')
                    ? dayjs(requisiteData.contractDate, 'YYYY-MM-DD')
                    : undefined,
                director: requisiteData.director,
                accountId: requisiteData.accountId ? String(requisiteData.accountId) : undefined
            });

            this.setState({
                formType: requisiteData.formType || 'OTHER'
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { user, clientReq, supplierReq, requisiteData } = this.props;
        const { treeState } = this.state;

        if (!_.get(treeState, 'length') && _.get(this.props.accounts, 'length') && clientReq) {
            this.filterTreeByActive(this.props.accounts);
        }

        if (supplierReq && !this.props.form.getFieldValue('accountId') && user.supplierAccountId) {
            this.props.form.setFieldsValue({
                accountId: String(user.supplierAccountId)
            });
        }
        if (clientReq && !this.props.form.getFieldValue('accountId') && user.clientAccountId) {
            this.props.form.setFieldsValue({
                accountId: String(user.clientAccountId)
            });
        }

        if (Boolean(this.props.requisiteData) && !prevProps.requisiteData) {
            const { requisiteData } = this.props;
            this.props.form.setFieldsValue({
                formType: requisiteData.formType || 'OTHER',
                formName: requisiteData.formType ? undefined : requisiteData.formName,
                name: requisiteData.name,
                address: requisiteData.address,
                ifi: requisiteData.ifi,
                ca: requisiteData.ca,
                bank: requisiteData.bank,
                isTaxPayer: Boolean(requisiteData.isTaxPayer),
                taxRate: requisiteData.taxRate || 20,
                calculationMethod: requisiteData.calculationMethod,
                enabled: Boolean(requisiteData.enabled),
                ipn: requisiteData.ipn,
                certificateNumber: requisiteData.certificateNumber,
                contractNumber: requisiteData.contractNumber,
                contractDate: _.get(requisiteData, 'contractDate')
                    ? dayjs(requisiteData.contractDate, 'YYYY-MM-DD')
                    : undefined,
                director: requisiteData.director,
                accountId: requisiteData.accountId ? String(requisiteData.accountId) : undefined
            });

            this.setState({
                formType: requisiteData.formType || 'OTHER'
            });
        } else if (!this.props.requisiteData && Boolean(prevProps.requisiteData)) {
            this.props.form.resetFields();
            this.setState({
                requisiteData: undefined
            });
        }
    }

    filterTreeByActive = nodes => {
        const filteredNodes = nodes.reduce((accumulator, node) => {
            if (node.active === true) {
                const filteredNode = {
                    value: node.id,
                    title: `(#${node.id}) ${node.name} `,
                    level: node.level,
                    disabled: node.level !== 4
                };

                if (node.children) {
                    const filteredChildren = this.filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNode.children = filteredChildren;
                    }
                }

                accumulator.push(filteredNode);
            }

            return accumulator;
        }, []);

        // Update the state outside of the reduce function
        this.setState({
            treeState: filteredNodes
        });

        return filteredNodes;
    };

    render() {
        const {
            user,
            intl: { formatMessage },
            requisiteData,
            clientReq,
            supplierReq,
            disabled
        } = this.props;

        const { treeState } = this.state;

        const { getFieldDecorator, getFieldValue } = this.props.form;
        const isOtherForm = this.state.formType == 'OTHER';

        const isTaxCRUDForbidden = isForbidden(user, permissions.ACCESS_CATALOGUE_REQUISITES_TAX_CRUD);

        return (
            <Form disabled={disabled} layout='horizontal' onSubmit={this.handleSubmit}>
                <Form.Item
                    label={<FormattedMessage id='requisite-setting.form' />}
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('formType', {
                        rules: [
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'storage_document.error.required_fields'
                                })
                            }
                        ],
                        initialValue: 'ENTREPRENEUR'
                    })(
                        <Radio.Group
                            onChange={event => {
                                this.setState({
                                    formType: event.target.value
                                });
                            }}
                        >
                            <Radio value='ENTREPRENEUR'>
                                <FormattedMessage id='requisite-setting.form.ENTREPRENEUR' />
                            </Radio>
                            <Radio value='LEGAL_ENITITY'>
                                <FormattedMessage id='requisite-setting.form.LEGAL_ENITITY' />
                            </Radio>
                            <Radio value='OTHER'>
                                <FormattedMessage id='requisite-setting.form.other' />
                            </Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                {isOtherForm && (
                    <Form.Item
                        label={<FormattedMessage id='requisite-setting.form.other' />}
                        {...formItemStyle}
                        {...formItemLayout}
                    >
                        {getFieldDecorator('formName')(<Input />)}
                    </Form.Item>
                )}
                <Form.Item
                    label={<FormattedMessage id='requisite-setting.name' />}
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'storage_document.error.required_fields'
                                })
                            }
                        ]
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='requisite-setting.address' />}
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('address')(<Input />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            <FormattedMessage id='requisite-setting.code' /> <FormattedMessage id='USREOU' />
                        </span>
                    }
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('ifi')(<Input />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            <FormattedMessage id='requisite-setting.account' /> <FormattedMessage id='IBAN' />
                        </span>
                    }
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('ca')(<Input />)}
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='requisite-setting.bank' />}
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('bank')(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<FormattedMessage id='requisite-setting.contractNumber' />}
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('contractNumber', { initialValue: '____________' })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<FormattedMessage id='requisite-setting.contractDate' />}
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('contractDate')(
                        <DatePicker
                            format='YYYY-MM-DD'
                            onChange={(date, dateString) => {
                                console.log(dateString);
                            }}
                            // showTime
                            popupStyle={{ zIndex: 2000 }}
                        />
                    )}
                </Form.Item>

                <Form.Item
                    label={<FormattedMessage id='requisite-setting.director' />}
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    {getFieldDecorator('director')(<Input />)}
                </Form.Item>

                {!isTaxCRUDForbidden && (
                    <React.Fragment>
                        <Form.Item
                            label={
                                <span>
                                    <FormattedMessage id='requisite-setting.payer' /> <FormattedMessage id='VAT' />
                                </span>
                            }
                            {...formItemStyle}
                            {...formItemLayout}
                        >
                            {getFieldDecorator('isTaxPayer', {
                                rules: [
                                    {
                                        required: true,
                                        message: formatMessage({
                                            id: 'storage_document.error.required_fields'
                                        })
                                    }
                                ],
                                initialValue: false
                            })(
                                <Radio.Group disabled={isTaxCRUDForbidden}>
                                    <Radio value>
                                        <FormattedMessage id='yes' />
                                    </Radio>
                                    <Radio value={false}>
                                        <FormattedMessage id='no' />
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id='requisite-setting.ipn' />}
                            {...formItemStyle}
                            {...formItemLayout}
                        >
                            {getFieldDecorator('ipn')(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id='requisite-setting.сertificateNumber' />}
                            {...formItemStyle}
                            {...formItemLayout}
                        >
                            {getFieldDecorator('certificateNumber')(<Input />)}
                        </Form.Item>

                        <Form.Item
                            label={
                                <span>
                                    <FormattedMessage id='requisite-setting.rate' /> <FormattedMessage id='VAT' />
                                </span>
                            }
                            {...formItemStyle}
                            {...formItemLayout}
                        >
                            {getFieldDecorator('taxRate', { initialValue: 20 })(
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={isTaxCRUDForbidden}
                                    formatter={value => `${value}%`}
                                    max={100}
                                    min={0}
                                    parser={value => value.replace('%', '')}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id='requisite-setting.method_of_calculation' />}
                            {...formItemStyle}
                            {...formItemLayout}
                        >
                            {getFieldDecorator('calculationMethod', { initialValue: 'WITH_TAX' })(
                                <Radio.Group>
                                    <Radio value='WITH_TAX'>
                                        <FormattedMessage id='requisite-setting.VAT.plus' />
                                    </Radio>
                                    {/* <Radio disabled value='ADD_TAX'>
                                        <FormattedMessage id='requisite-setting.VAT.including' />
                                    </Radio> */}
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </React.Fragment>
                )}
                <Form.Item
                    label={<FormattedMessage id='requisite-setting.active' />}
                    {...formItemStyle}
                    {...formItemLayout}
                >
                    <Checkbox
                        checked={this.props.form.getFieldValue('enabled')}
                        onChange={() => {
                            this.props.form.setFieldsValue({
                                enabled: !this.props.form.getFieldValue('enabled')
                            });
                        }}
                    />
                    <div style={{ display: 'none' }}>
                        {getFieldDecorator('enabled', {
                            valuePropName: 'checked',
                            initialValue: true
                        })(<Checkbox />)}
                    </div>
                </Form.Item>
                {(clientReq || supplierReq) && (
                    <Form.Item
                        label={
                            <span>
                                <FormattedMessage id='new-document-page.item.account' />
                            </span>
                        }
                        {...formItemStyle}
                        {...formItemLayout}
                    >
                        {getFieldDecorator('accountId')(
                            <TreeSelect
                                filterTreeNode={(input, node) => {
                                    return (
                                        node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(node.props.value).indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                                listHeight={440}
                                placeholder={
                                    <React.Fragment>
                                        <FormattedMessage id='receipt_document_modal.bill' />
                                    </React.Fragment>
                                }
                                showSearch
                                treeData={clientReq ? treeState : this.props.tree}
                                value={String(this.props.accountId)}
                                // style={{ color: 'var(--text)', width: '100%' }}
                            />
                        )}
                    </Form.Item>
                )}
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 24
                    }}
                >
                    <Form.Item wrapperCol={{ span: 12 }}>
                        <Button onClick={() => this.props.hideModal()}>
                            <FormattedMessage id='cancel' />
                        </Button>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12 }}>
                        <Button disabled={disabled} htmlType='submit' type='primary'>
                            <FormattedMessage id='save' />
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        );
    }
}

// export const RequisiteSettingForm = Form.create()(RequisiteSetting);

export class RequisiteSettingFormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            accounts: []
        };
    }

    getAccountsList = async query => {
        const response = await fetchAPI('GET', '/general_ledger/accounts', { query }, null, {
            handleErrorInternally: true
        });
        this.setState({ accounts: response });
    };

    handleCancel = bl => {
        const { buttonMode, hideModal } = this.props;
        if (buttonMode) {
            this.setState({
                visible: false
            });
        } else {
            hideModal(bl);
        }
    };

    handleOk = () => {
        this.handleCancel(true);
    };

    render() {
        const {
            buttonMode,
            modalVisible,
            requisiteData,
            hideModal,
            postRequisite,
            updateRequisite,
            id,
            clientReq,
            supplierReq,
            tree,
            disabled
        } = this.props;
        const { visible, accounts } = this.state;

        const open = buttonMode ? visible : modalVisible;

        return (
            <div>
                {buttonMode && (
                    <Button
                        onClick={() => {
                            this.setState({
                                visible: true
                            });
                        }}
                    >
                        <FormattedMessage id='add' />
                    </Button>
                )}
                <Modal
                    destroyOnClose
                    footer={null}
                    forceRender
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={open}
                    style={{
                        minWidth: '40%',
                        maxWidth: '95%'
                    }}
                    title={null}
                >
                    {open && (
                        <RequisiteSettingForm
                            accounts={accounts}
                            clientReq={clientReq}
                            disabled={disabled}
                            getAccountsList={this.getAccountsList}
                            hideModal={hideModal}
                            id={id}
                            postRequisite={postRequisite}
                            requisiteData={requisiteData}
                            supplierReq={supplierReq}
                            tree={tree}
                            updateRequisite={updateRequisite}
                            visible={buttonMode ? visible : modalVisible}
                        />
                    )}
                </Modal>
            </div>
        );
    }
}
