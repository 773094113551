import { selectStoreProduct } from 'core/storage/products';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import CatalogTable from './CatalogTable';

const mapStateToProps = state => ({
    user: state.auth,
    product: selectStoreProduct(state)
});

const mapDispatchToProps = {};

/**
 * Catalog of product by code from different brands tab
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CatalogTab extends Component {
    render() {
        const { user, product } = this.props;

        return (
            <div>
                <CatalogTable
                    brandId={product.brandId}
                    codeFilter={product.code}
                    codeSearch
                    user={user}
                />
            </div>
        );
    }
}
