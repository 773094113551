import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { fetchEmployees } from 'core/employees/duck';
import { fetchEmployeeById, resetEmployeeForm, saveEmployee } from 'core/forms/employeeForm/duck';
import { EmployeeForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { Layout } from 'tireFitting';

const mapStateToProps = state => ({
    employeesData: state.forms.employeeForm.fields,
    user: state.auth
});

const mapDispatchToProps = {
    saveEmployee,
    fetchEmployees,
    fetchEmployeeById,
    resetEmployeeForm
};
@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class EditEmployeePage extends Component {
    componentDidMount() {
        this.props.resetEmployeeForm();
    }

    componentWillUnmount() {
        this.props.resetEmployeeForm();
    }

    saveEmployeeFormRef = formRef => {
        this.employeeFormRef = formRef;
    };

    saveScheduleEmployeeFormRef = formRef => {
        this.employeeScheduleFormRef = formRef;
    };

    saveEmployee = () => {
        const { form } = this.employeeFormRef.props;
        form.validateFields((err, values) => {
            if (!err) {
                this.props.saveEmployee(values);
            }
        });
    };

    /* eslint-disable complexity */
    render() {
        const { user } = this.props;

        return (
            <Layout
                controls={
                    <Link to={book.employeesPage}>
                        <Button type='default'>
                            <ArrowLeftOutlined />
                            <FormattedMessage id='back-to-list' />
                        </Button>
                    </Link>
                }
                title={<FormattedMessage id='employee-page.add_employee' />}
            >
                <EmployeeForm
                    adding
                    initialEmployee={null}
                    saveEmployee={this.saveEmployee}
                    user={user}
                    wrappedComponentRef={this.saveEmployeeFormRef}
                />
            </Layout>
        );
    }
}

export default EditEmployeePage;
