import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Select, notification } from 'antd';
import { PhoneInput } from 'components';
import { onChangeProfileForm, submitProfileFormOther } from 'core/forms/profileForm/duck';
import _, { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.less';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    colon: false
};

const mapDispatchToProps = {
    onChangeProfileForm,
    submitProfileFormOther
};

const mapStateToProps = state => {
    return {
        user: state.auth,
        profileUpdating: state.ui.profileUpdating
    };
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class AdminCarbookTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allNetworks: [],
            initialValues: {},
            allPhones: [],
            adminBusinessIdsNetwork: [],
            regions: [],
            cities: [],
            partnerShipNetworks: []
        };
    }

    componentDidMount() {
        this.getAdressData();
        this.getAllPartnershipNetworks();
        this.setState({
            allPhones: this.props.carbookValues ? this.props.carbookValues.phones : [],
            adminDisabledSms:
                _.get(this.props.carbookValues, 'adminDisabledSms') !== null
                    ? _.get(this.props.carbookValues, 'adminDisabledSms')
                    : true,
            adminDisableAllSms:
                _.get(this.props.carbookValues, 'adminDisableAllSms') !== null
                    ? _.get(this.props.carbookValues, 'adminDisableAllSms')
                    : true,
            adminBusinessIdsNetworkId: this.props.businessNetworks
                ? this.props.businessNetworks.list.map(({ id }) => id)
                : [],
            allNetworks: this.props.businessNetworks ? this.props.businessNetworks.list : []
        });
    }

    findPhoneInfo = async (code, country, phoneNumber) => {
        setPhoneCode(code);
        setCountry(country);
        setPhoneNumber(phoneNumber);
    };

    handlePhoneNumberChange = (index, number, code, country) => {
        const { carbookValues } = this.props;
        const { allPhones } = this.state;

        const newPhoneNumbers = [...allPhones];
        newPhoneNumbers[index].number = number.toString();
        newPhoneNumbers[index].code = code.toString();
        newPhoneNumbers[index].country = country.toString();
        this.setState({
            allPhones: newPhoneNumbers
        });
    };

    handleAddPhoneNumber = () => {
        const { allPhones } = this.state;
        this.setState({ allPhones: [...allPhones, { code: '', number: '', country: 'ua' }] });
    };

    handleDeleteObject = index => {
        const { allPhones } = this.state;
        const updatedArray = allPhones.filter((item, idx) => idx !== index);
        this.setState({ allPhones: [...updatedArray] });
    };

    getAllNetworks = async value => {
        const response = await fetchAPI('GET', '/businesses/search', { search: value }, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            allNetworks: response
        });
    };

    getAllPartnershipNetworks = async value => {
        const response = await fetchAPI('GET', '/business/network_partners', null, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            partnerShipNetworks: response
        });
    };

    handleSearchNetworks = _.debounce(value => {
        this.getAllNetworks(value);
    }, 1000);

    getAdressData = async () => {
        const responseCities = await fetchAPI('GET', '/business/cities', null, undefined, {
            handleErrorInternally: true
        });
        const responseRegions = await fetchAPI('GET', '/business/regions', null, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            regions: responseRegions,
            cities: responseCities
        });
    };

    submitProfileCarbook = async values => {
        const { adminBusinessIdsNetworkId, adminDisableAllSms, adminDisabledSms } = this.state;
        await fetchAPI(
            'PUT',
            '/admin/businesses',
            null,
            { ...values, adminBusinessIdsNetwork: adminBusinessIdsNetworkId, adminDisableAllSms, adminDisabledSms },
            {
                handleErrorInternally: true
            }
        );
    };

    submitProfileCarbookPhones = async phones => {
        await fetchAPI(
            'PUT',
            '/businesses',
            null,
            { phones },
            {
                handleErrorInternally: true
            }
        );
    };

    _submitProfile = values => {
        const phones = this.state.allPhones;
        this.submitProfileCarbook({ ...values, partnerNetworkId: values.partnerNetworkId || null });
        this.submitProfileCarbookPhones(phones);
        // onChangeProfileForm();
        notification.success({
            message: '.'
        });
    };

    render() {
        const {
            user,
            carbookValues,
            intl: { formatMessage },
            businessNetworks
        } = this.props;

        const {
            allNetworks,
            allPhones,
            adminBusinessIdsNetworkId,
            regions,
            cities,
            partnerShipNetworks,
            partnerShipNetworkId,
            adminDisabledSms,
            adminDisableAllSms
        } = this.state;

        console.log(partnerShipNetworks, 'partnerShipNetworks');

        return (
            <Form
                className={Styles.profileForm}
                initialValues={{
                    adminDisabledSms:
                        _.get(carbookValues, 'adminDisabledSms') !== null
                            ? _.get(carbookValues, 'adminDisabledSms')
                            : true,
                    adminDisableAllSms:
                        _.get(carbookValues, 'adminDisableAllSms') !== null
                            ? _.get(carbookValues, 'adminDisableAllSms')
                            : true,
                    adminRecommend:
                        _.get(carbookValues, 'adminRecommend') !== null
                            ? _.get(carbookValues, 'adminRecommend')
                            : false,
                    approved: _.get(carbookValues, 'approved') !== null ? _.get(carbookValues, 'approved') : false,
                    enableOnlineOrders:
                        _.get(carbookValues, 'enableOnlineOrders') !== null
                            ? _.get(carbookValues, 'enableOnlineOrders')
                            : false,
                    adminTestBusiness:
                        _.get(carbookValues, 'adminTestBusiness') !== null
                            ? _.get(carbookValues, 'adminTestBusiness')
                            : false,
                    regionId: _.get(carbookValues, 'regionId') !== null ? _.get(carbookValues, 'regionId') : undefined,
                    cityId: _.get(carbookValues, 'cityId') !== null ? _.get(carbookValues, 'cityId') : undefined,
                    partnerNetworkId:
                        _.get(carbookValues, 'partnerNetworkId') !== null
                            ? _.get(carbookValues, 'partnerNetworkId')
                            : undefined
                }}
                labelCol={formItemLayout.labelCol}
                name='profileForm'
                onFinish={this._submitProfile}
                wrapperCol={formItemLayout.wrapperCol}
            >
                <Form.Item
                    label={<FormattedMessage id='settings_page.disabled_mrd_sms' />}
                    // name='adminDisabledSms'
                    // valuePropName='checked'
                >
                    <Checkbox
                        checked={!adminDisabledSms}
                        onChange={() => {
                            this.setState({
                                adminDisabledSms: !adminDisabledSms
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='settings_page.disabled_all_sms' />}
                    // name='adminDisableAllSms'
                    // valuePropName='checked'
                >
                    <Checkbox
                        checked={!adminDisableAllSms}
                        onChange={() => {
                            this.setState({
                                adminDisableAllSms: !adminDisableAllSms
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='settings_page.recom' />}
                    name='adminRecommend'
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='settings_page.checked' />}
                    name='approved'
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='settings_page.online_orders' />}
                    name='enableOnlineOrders'
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item label={<FormattedMessage id='dashboard-page.sto_network' />}>
                    <Select
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_POSITIONS_BUSINESS_NETWORK)}
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        mode='multiple'
                        onChange={value => {
                            this.setState({
                                adminBusinessIdsNetworkId: value
                            });
                        }}
                        onSearch={input => this.handleSearchNetworks(input)}
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                        style={{
                            width: '100%'
                        }}
                        value={adminBusinessIdsNetworkId}
                    >
                        {get(allNetworks, 'length') &&
                            allNetworks.map(elem => (
                                <Option key={elem.businessId || elem.id} value={elem.businessId || elem.id}>
                                    {`${elem.name} - ${elem.businessId || elem.id}`}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item label={<FormattedMessage id='add_client_form.phone_number_responsible' />}>
                    {allPhones.map((elem, index) => (
                        <div
                            style={{
                                marginBottom: 8,
                                display: 'flex'
                            }}
                        >
                            <PhoneInput
                                handlePhoneNumberChange={this.handlePhoneNumberChange}
                                index={index}
                                onChange={value => {}}
                                style={{ width: '100%' }}
                                placeholder={formatMessage({
                                    id: 'nRegNew'
                                })}
                                // formatter={value => phoneNumberFormatter(value, user.country)}
                                value={String(elem.code + elem.number)}
                            />
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={() => this.handleDeleteObject(index)}
                                type='text'
                            />
                        </div>
                    ))}
                    <Button
                        icon={
                            <PlusOutlined
                                style={{
                                    marginRight: 8
                                }}
                            />
                        }
                        onClick={this.handleAddPhoneNumber}
                    >
                        <FormattedMessage id='add_client_form.add_phone' />
                    </Button>
                </Form.Item>
                <Form.Item label={<FormattedMessage id='settings_page.region' />} name='regionId'>
                    <Select
                        allowClear
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_POSITIONS_BUSINESS_NETWORK)}
                        getPopupContainer={trigger => trigger.parentNode}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{
                            width: '100%'
                        }}
                    >
                        {get(regions, 'length') &&
                            regions.map(elem => (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.synonym}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item label={<FormattedMessage id='settings_page.city' />} name='cityId'>
                    <Select
                        allowClear
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_POSITIONS_BUSINESS_NETWORK)}
                        getPopupContainer={trigger => trigger.parentNode}
                        optionFilterProp='children'
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                        showSearch
                        style={{
                            width: '100%'
                        }}
                    >
                        {get(cities, 'length') &&
                            cities.map(elem => (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.synonym}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='settings_page.test_service' />}
                    name='adminTestBusiness'
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item label={<FormattedMessage id='settings_page.partner_network' />} name='partnerNetworkId'>
                    <Select
                        allowClear
                        disabled={!isGrantAccessed(user, grants.DIRECTORIES_EMPLOYEE_CARD_POSITIONS_BUSINESS_NETWORK)}
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        // onChange={value => {
                        //     this.setState({
                        //         partnerShipNetworkId: value
                        //     });
                        // }}
                        onSearch={input => this.handleSearchNetworks(input)}
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                        style={{
                            width: '100%'
                        }}
                        // value={partnerShipNetworkId}
                    >
                        {get(partnerShipNetworks, 'length') &&
                            partnerShipNetworks.map(elem => (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.synonym}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    className={Styles.itemBtn}
                    wrapperCol={{
                        offset: 11,
                        span: 12
                    }}
                >
                    <Button className={Styles.saveBtn} htmlType='submit' type='primary'>
                        <FormattedMessage id='save' />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
