import { Form } from '@ant-design/compatible';
import { Col, Row } from 'antd';
import { DecoratedInput } from 'forms/DecoratedFields';
import { fetchBrand, selectBrandField, setBrandFields } from 'modals/BrandsModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const FItem = Form.Item;

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    fields: selectBrandField(state)
});

const mapDispatchToProps = {
    setBrandFields,
    fetchBrand
};

/**
 * This form is used to create vehicle, it contains all required actions to fetch data and create vehicles
 *
 * @param {Function} getFormRefCB -  callback, takes one argument(form reference)
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@Form.create({ name: 'brands_edit_form' })
export default class EditVehicleFormClass extends React.Component {
    constructor(props) {
        super(props);

        const { getFormRefCB } = this.props;
        getFormRefCB && getFormRefCB(this.props.form); // Callback to get form instance (wrappedComponentRef does not work)
    }

    componentDidMount() {
        const { brandId } = this.props.modalProps;

        this.props.fetchBrand(brandId);
    }

    setField = (key, value) => {
        this.props.setBrandFields({ [key]: value });
    };

    render() {
        const {
            fields,

            form,
            intl: { formatMessage },

            user
        } = this.props;

        const { getFieldDecorator, resetFields, isFieldTouched } = form;

        return (
            <Form>
                <Row className={Styles.row}>
                    <Col span={6}>
                        <div className={Styles.colText}>
                            <FormattedMessage id='add_form_brand_id' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <DecoratedInput
                            disabled
                            field='brandId'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            initialValue={fields.brandId}
                            onChange={e => {
                                this.setField('brandId', e.target.value);
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col span={6}>
                        <div className={Styles.colText}>
                            <FormattedMessage id='add_form_brand_name' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <DecoratedInput
                            field='brandName'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            initialValue={fields.brandName}
                            onChange={e => {
                                this.setField('brandName', e.target.value);
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'required_field'
                                    })
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Row className={Styles.row}>
                    <Col span={6}>
                        <div className={Styles.colText}>
                            <FormattedMessage id='add_form_brand_supplier_id' />
                        </div>
                    </Col>
                    <Col span={12}>
                        <DecoratedInput
                            field='tecdocSupplierId'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            hasFeedback
                            initialValue={fields.tecdocSupplierId}
                            onChange={e => {
                                this.setField('tecdocSupplierId', e.target.value);
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        );
    }
}
