import { Table, Tabs } from 'antd';
import {
    fetchCashOrdersLogsReceipt,
    setCashOrdersLogsPage
} from 'core/reports/reportCashOrdersLogs/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import Styles from './styles.m.css';
import mainTableColumnsConfig from './tableConfigs/mainTableConfig';
import paymentsTableColumnsConfig from './tableConfigs/paymetsTableConfig';
import productsTableColumnsConfig from './tableConfigs/productsTableConfig';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    cashdeskLogs: state.reportCashOrdersLogs.cashdeskLogs,
    stats: state.reportCashOrdersLogs.stats,
    page: state.reportCashOrdersLogs.filter.page
});

const mapDispatchToProps = {
    fetchCashOrdersLogsReceipt,
    setCashOrdersLogsPage
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export class ReportCashOrdersLogsTable extends Component {
    constructor(props) {
        super(props);
        const { fetchCashOrdersLogsReceipt } = props;
        this.mainTableColumns = mainTableColumnsConfig({ fetchCashOrdersLogsReceipt });
        this.paymentTableConfig = paymentsTableColumnsConfig();
        this.productsTableConfig = productsTableColumnsConfig();
    }

    paymentsTable(paymets) {
        return (
            <Table
                bordered
                columns={this.paymentTableConfig}
                dataSource={paymets} 
                pagination={false}
                rowKey={() => v4()}
            />
        );
    }

    productsTable(prducts) {
        return (
            <Table
                bordered
                columns={this.productsTableConfig}
                dataSource={prducts}
                pagination={false}
                rowKey={() => v4()}
            />
        );
    }

    render() {
        const { cashdeskLogs, setCashOrdersLogsPage, stats, page, fetchCashOrdersLogsReceipt } = this.props;
        const columns = mainTableColumnsConfig({ fetchCashOrdersLogsReceipt });
        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.totalRowsCount / 25) * 25,
            hideOnSinglePage: true,
            current: page,
            onChange: page => {
                setCashOrdersLogsPage({ page });
                this.setState({});
            } 
        };
        
        return (
            <div>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columns}
                    dataSource={cashdeskLogs}
                    expandable={{
                        expandedRowRender: record => {
                            return (
                                <div style={{ backgroundColor: 'rgb(240, 240,240)', padding: '8px' }}>
                                    <Tabs tabPosition='top'>
                                        <TabPane
                                            key='products'
                                            tab={
                                                <FormattedMessage id='report_cash_orders_logs_page.products' />
                                            }
                                        >
                                            {this.productsTable(record.products)}
                                        </TabPane>

                                        <TabPane
                                            key='payments'
                                            tab={
                                                <FormattedMessage id='report_cash_orders_logs_page.payments' />
                                            }
                                        >
                                            {this.paymentsTable(record.payments)}
                                        </TabPane>
                                    </Tabs>
                                </div>
                            );
                        }
                    }}

                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={pagination}
                    scroll={{ x: 1800, y: '70vh' }}
                    size='small'
                    //rowKey={() => v4()}
                    rowKey='logId'
                />
            </div>
        );
    }
}
