import { List, Modal } from 'antd';
import classNames from 'classnames/bind';
import { Catcher } from 'commons';
import { chartList } from 'components/UniversalChart/chartConfig.js';
import { MODALS } from 'core/modals/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const cx = classNames.bind(Styles);

@injectIntl
export default class UniversalChartModal extends Component {
    _setUniversalChartMode = mode => {
        const { setChartMode, fetchChart, resetModal } = this.props;

        setChartMode(mode);
        fetchChart();
        resetModal();
    };

    render() {
        const {
            visible,
            resetModal,
            mode,
            intl: { formatMessage }
        } = this.props;

        const _listItemStyles = activeMode => {
            return cx({
                listItem: true,
                listItemActive: mode === activeMode
            });
        };

        return (
            <Modal
                className={Styles.modal}
                footer={null}
                maskClosable={false}
                onCancel={() => resetModal()}
                title={<FormattedMessage id='universal_chart.modal.header' />}
                visible={visible === MODALS.UNIVERSAL_CHART}
            >
                <Catcher>
                    {Object.keys(chartList).map((key, index) => (
                        <div key={`${index}-list`}>
                            <div className={Styles.listTitle}>
                                <FormattedMessage id={`universal_chart.list.title.${key}`} />
                            </div>
                            <List
                                bordered
                                dataSource={chartList[key]}
                                locale={{
                                    emptyText: formatMessage({ id: 'no_data' })
                                }}
                                renderItem={item => (
                                    <List.Item
                                        className={_listItemStyles(item.mode)}
                                        onClick={() => this._setUniversalChartMode(item.mode)}
                                    >
                                        <List.Item.Meta
                                            className={Styles.chartListItem}
                                            title={
                                                <React.Fragment>
                                                    <FormattedMessage
                                                        id={`universal-chart.list.item.${item.mode}`}
                                                    />
                                                    {item.type &&
                                                        ` (${formatMessage({
                                                            id: item.type
                                                        })})`}
                                                </React.Fragment>
                                            }
                                            // description={  }
                                        />
                                    </List.Item>
                                )}
                                size='small'
                            />
                        </div>
                    ))}
                </Catcher>
            </Modal>
        );
    }
}
