import { Form } from '@ant-design/compatible';
import { Button, Select } from 'antd';
import {
    fetchDetails,
    fetchProductNames,
    onChangeSetDetailProductForm,
    submitDetailProduct
} from 'core/forms/setDetailProduct/duck';
import { DecoratedInput, DecoratedSelect, LimitedDecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withReduxForm2 } from 'utils';

const { Option } = Select;

@injectIntl
@withReduxForm2({
    name: 'setDetailProductForm',
    actions: {
        change: onChangeSetDetailProductForm,
        fetchProductNames,
        fetchDetails,
        submitDetailProduct
    }
})
export class SetDetailProductForm extends Component {
    componentDidMount() {
        this.props.fetchDetails();
    }

    _requiredDetails = () =>
        this.props.form.getFieldsValue().detailId
            ? [_.chain(this.props.details).find({ detailId }).get('detailName').value()].filter(
                  Boolean
              )
            : [];

    _requiredSuppliers = () =>
        this.props.form.getFieldsValue().supplierId
            ? [_.chain(this.props.brands).find({ supplierId }).get('brandName').value()].filter(
                  Boolean
              )
            : [];

    render() {
        const { details, brands, products } = this.props;
        const { getFieldDecorator, getFieldsValue } = this.props.form;

        const { detailId, articleNumber, supplierId, productId } = getFieldsValue();

        return (
            <Form>
                <DecoratedInput
                    field='articleNumber'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    hasFeedback
                    label={<FormattedMessage id='detail_product.article_number' />}
                />
                <LimitedDecoratedSelect
                    defaultValues={this._requiredSuppliers()}
                    field='supplierId'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    hasFeedback
                    label={<FormattedMessage id='detail_product.supplier_id' />}
                    showSearch
                >
                    {_.map(brands, ({ supplierId, brandName }, index) => {
                        return (
                            <Option key={`${supplierId}-${index}`} value={String(supplierId)}>
                                {brandName}
                            </Option>
                        );
                    })}
                </LimitedDecoratedSelect>
                <Button
                    disabled={!articleNumber || !supplierId}
                    onClick={() => this.props.fetchProductNames(articleNumber, supplierId)}
                    style={{ width: '100%' }}
                    type='primary'
                >
                    <FormattedMessage id='detail_product.fetch_product_names' />
                </Button>
                <LimitedDecoratedSelect
                    defaultValues={this._requiredDetails()}
                    disabled={!articleNumber || !supplierId}
                    field='detailId'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    hasFeedback
                    label={<FormattedMessage id='detail_product.detail_id' />}
                    showSearch
                >
                    {_.map(details, ({ detailId, detailName }, index) => (
                        <Option key={`${detailId}-${index}`} value={String(detailId)}>
                            {detailName}
                        </Option>
                    ))}
                </LimitedDecoratedSelect>
                <DecoratedSelect
                    disabled={!products}
                    field='productId'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    getPopupContainer={trigger => trigger.parentNode}
                    hasFeedback
                    label={<FormattedMessage id='detail_product.product_id' />}
                    showSearch
                >
                    {_.map(products, ({ id, productName }, index) => (
                        <Option key={`${id}-${index}`} value={id}>
                            {productName}
                        </Option>
                    ))}
                </DecoratedSelect>
                <Button
                    disabled={!detailId || !productId}
                    onClick={() => this.props.submitDetailProduct(detailId, productId)}
                    style={{ width: '100%' }}
                    type='primary'
                >
                    <FormattedMessage id='submit' />
                </Button>
            </Form>
        );
    }
}
