import { EditOutlined, MailOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { Numeral } from 'commons';
import { OrderStatusIcon } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

/* eslint-disable complexity */
export function columnsConfig(
    invited,
    action,
    isOrderInvitable,
    isAlreadyInvited,
    activeRoute,
    sort,
    user,
    formatMessage,
    isMobile
) {
    const sortOptions = {
        asc: 'ascend',
        desc: 'descend'
    };

    const indexCol = {
        title: '№',
        width: 80,
        dataIndex: 'index',
        key: 'index'
        // fixed:     'left',
    };

    const orderCol = {
        title: <FormattedMessage id='orders.order' />,
        width: 'auto',
        dataIndex: 'num',
        key: 'num',
        // fixed:     'left',
        render: (_, order) => (
            <React.Fragment>
                <Link className={Styles.ordernLink} to={`${book.order}/${order.id}`}>
                    {order.num}
                </Link>
                <OrderStatusIcon status={order.status} />
                {order.serviceNames && (
                    <div className={Styles.serviceNames}>
                        {[...new Set(order.serviceNames)].join(', ')}
                    </div>
                )}
                {order.recommendation && (
                    <div className={Styles.recommendation}>{order.recommendation}</div>
                )}
                {(order.cancelReason ||
                    order.cancelStatusReason ||
                    order.cancelStatusOwnReason) && (
                    <div className={Styles.cancelReason}>
                        {/* <div>{ order.cancelReason }</div> */}
                        <div>{order.cancelStatusReason}</div>
                        <div>{order.cancelStatusOwnReason}</div>
                    </div>
                )}
            </React.Fragment>
        )
    };

    const datetimeCol = {
        title: !isMobile ? (
            <FormattedMessage id='orders.creation_date' />
        ) : (
            <FormattedMessage id='date' />
        ),
        dataIndex: 'datetime',
        key: 'datetime',
        sorter: !isMobile,
        sortOrder: sort.field === 'datetime' && !isMobile ? sortOptions[sort.order] : false,
        width: 'auto',
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.datetime ? dayjs(order.datetime).format('DD.MM.YYYY HH:mm') : '-'}
                {isMobile && (
                    <div>
                        <FormattedMessage id={`order_statuses_mapper.${order.status}`} />
                    </div>
                )}
            </div>
        )
    };

    const beginDatetimeCol = {
        title: <FormattedMessage id='orders.begin_date' />,
        dataIndex: 'beginDatetime',
        key: 'beginDatetime',
        sortOrder: sort.field === 'beginDatetime' ? sortOptions[sort.order] : false,
        sorter: true,
        width: 'auto',
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.beginDatetime ? dayjs(order.beginDatetime).format('DD.MM.YYYY HH:mm') : '-'}
            </div>
        )
    };

    const deliveryDatetimeCol = {
        title: <FormattedMessage id='orders.delivery_date' />,
        dataIndex: 'deliveryDatetime',
        key: 'deliveryDatetime',
        sortOrder: sort.field === 'deliveryDatetime' ? sortOptions[sort.order] : false,
        sorter: true,
        width: 'auto',
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.deliveryDatetime
                    ? dayjs(order.deliveryDatetime).format('DD.MM.YYYY HH:mm')
                    : '-'}
            </div>
        )
    };

    const successDatetimeCol = {
        title: <FormattedMessage id='orders.success_date' />,
        dataIndex: 'successDatetime',
        key: 'successDatetime',
        width: 'auto',
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.successDatetime
                    ? dayjs(order.successDatetime).format('DD.MM.YYYY HH:mm')
                    : '-'}
            </div>
        )
    };

    const createDatetimeCol = {
        title: <FormattedMessage id='orders.creation_date' />,
        dataIndex: 'datetime',
        key: 'datetime',
        sorter: true,
        sortOrder: sort.field === 'datetime' ? sortOptions[sort.order] : false,
        width: 'auto',
        render: (_, order) => (
            <div className={Styles.datetime}>
                {order.datetime ? dayjs(order.datetime).format('DD.MM.YYYY HH:mm') : '-'}
            </div>
        )
    };

    const clientCol = {
        title: <FormattedMessage id='orders.client' />,
        dataIndex: 'clientFullName',
        key: 'clientFullName',
        width: 'auto',
        render: (_, order) => (
            <div className={Styles.client}>
                <span className={Styles.clientFullname}>
                    {`${order.clientName || '-'} ${order.clientSurname || ''}`}
                </span>
                <span className={Styles.clientVehicle}>
                    {`${order.vehicleMakeName || '-'} ${order.vehicleModelName || '-'} ${
                        order.vehicleYear || '-'
                    }`}
                </span>
                <a className={Styles.clientPhone} href={`tel:${order.clientPhone}`}>
                    {order.clientPhone || '-'}
                </a>
            </div>
        )
    };

    const orderAndClientCol = {
        title: (
            <div>
                <p>
                    <FormattedMessage id='orders.order' />
                </p>
                <p>
                    <FormattedMessage id='orders.client' />
                </p>
            </div>
        ),
        width: 'auto',
        dataIndex: 'num',
        key: 'orderAndClient',
        // fixed:     'left',
        render: (_, order) => (
            <React.Fragment>
                <Link className={Styles.ordernLink} to={`${book.order}/${order.id}`}>
                    {order.num}
                </Link>
                <OrderStatusIcon status={order.status} />
                <div className={Styles.client}>
                    <span className={Styles.clientFullname}>
                        {`${order.clientName || '-'} ${order.clientSurname || ''}`}
                    </span>
                    <span className={Styles.clientVehicle}>
                        {`${order.vehicleMakeName || '-'} ${order.vehicleModelName || '-'} ${
                            order.vehicleYear || '-'
                        }`}
                    </span>
                    <a className={Styles.clientPhone} href={`tel:${order.clientPhone}`}>
                        {order.clientPhone || '-'}
                    </a>
                </div>
            </React.Fragment>
        )
    };

    const sumCol = {
        title: !isMobile ? (
            <FormattedMessage id='orders.sum_without_VAT' />
        ) : (
            <FormattedMessage id='orders.sum' />
        ),
        dataIndex: 'totalSum',
        key: 'totalSum',
        sorter: !isMobile,
        sortOrder: sort.field === 'totalSum' && !isMobile ? sortOptions[sort.order] : false,
        width: 'auto',
        render: (_, order) => (
            <div style={{ whiteSpace: 'nowrap' }}>
                <Numeral
                    // TODO
                    currency={formatMessage({ id: 'currency' })}
                    mask='0,0.00'
                    nullText='0'
                >
                    {order.servicesTotalSum + order.detailsTotalSum}
                </Numeral>
            </div>
        )
    };

    const remainingSumCol = {
        title: <FormattedMessage id='orders.remaining_sum' />,
        dataIndex: 'remainingSum',
        key: 'remainingSum',
        width: 'auto',
        render: remainingSum => (
            <Numeral currency={formatMessage({ id: 'currency' })} nullText='0'>
                {remainingSum}
            </Numeral>
        )
    };

    const responsibleCol = {
        title: <FormattedMessage id='orders.responsible' />,
        dataIndex: 'managerName',
        key: 'managerName',
        width: 'auto',
        render: (_, order) => {
            if (order.managerName) {
                return (
                    <div>
                        {order.managerSurname} {order.managerName}
                    </div>
                );
            }

            return (
                <div>
                    <FormattedMessage id='orders.not_assigned' />
                </div>
            );
        }
    };

    const reviewCol = {
        title: <FormattedMessage id='orders.review' />,
        dataIndex: 'review',
        key: 'review',
        width: 'auto',
        render: (data, order) => {
            if (_.isNumber(order.nps)) {
                return (
                    <a href={`${book.feedback}/${order.reviewIds[0]}`}>
                        <div
                            className={classNames(Styles.nps, {
                                [Styles.npsMid]: order.nps === 7 || order.nps === 8,
                                [Styles.npsLow]: order.nps <= 6
                            })}
                        >
                            {Math.round(order.nps)}
                        </div>
                    </a>
                );
            }

            return (
                <Button>
                    <FormattedMessage id='orders.add_feedback' />
                </Button>
            );
        }
    };

    const isInviteButtonDisabled = order => {
        const missingRequiredField = !isOrderInvitable(order);
        const alreadyInvited = isAlreadyInvited(order);
        const forbidden =
            isForbidden(user, permissions.CREATE_ORDER) ||
            isForbidden(user, permissions.CREATE_INVITE_ORDER);

        return !!(missingRequiredField || alreadyInvited || forbidden);
    };

    const invitationCol = {
        title: <FormattedMessage id='orders.invitation' />,
        dataIndex: 'invite',
        key: 'invite',
        width: 'auto',
        render: (_void, order) => {
            if (!order.vehicleInviteExists) {
                return (
                    <Button
                        disabled={isInviteButtonDisabled(order)}
                        onClick={() => action([order])}
                        type='primary'
                    >
                        <FormattedMessage id='orders.invite' />
                    </Button>
                );
            }

            return (
                <Link
                    className={Styles.inviteLink}
                    to={`${book.order}/${order.vehicleInviteExists}`}
                >
                    {order.vehicleInviteExists}
                </Link>
            );
        }
    };

    // const reasonCol = {
    //     title:     <FormattedMessage id='orders.reason' />,
    //     dataIndex: 'reason',
    //     key:       'reason',
    //     width:     190,
    //     render:    (_, order) => {
    //         if (
    //             order.cancelReason ||
    //             order.cancelStatusReason ||
    //             order.cancelStatusOwnReason
    //         ) {
    //             return (
    //                 <Button>
    //                     <FormattedMessage id='orders.show_reason' />
    //                 </Button>
    //             );
    //         }
    //
    //         return <FormattedMessage id='orders.no_data' />;
    //     },
    // };

    const actionCol = {
        title: <FormattedMessage id='orders.actions' />,
        dataIndex: 'actions',
        key: 'actions',
        width: 'auto',
        render: () => (
            <div className={Styles.inviteActions}>
                <Tooltip placement='bottom' title={<FormattedMessage id='orders.send_sms' />}>
                    <MessageOutlined style={{ fontSize: 24, color: '#08c' }} />
                </Tooltip>
                <Tooltip placement='bottom' title={<FormattedMessage id='orders.call' />}>
                    <PhoneOutlined style={{ fontSize: 24, color: '#08c' }} />
                </Tooltip>
                <Tooltip placement='bottom' title={<FormattedMessage id='orders.send_email' />}>
                    <MailOutlined style={{ fontSize: 24, color: '#08c' }} />
                </Tooltip>
            </div>
        )
    };

    const editCol = {
        key: 'editAction',
        // fixed:  'right',
        width: 80,
        render: (_, order) => (
            <Link to={`${book.order}/${order.id}`}>
                <EditOutlined className={Styles.editOrderIcon} />
            </Link>
        )
    };

    if (isMobile) {
        return [datetimeCol, orderAndClientCol, sumCol];
    }

    switch (activeRoute) {
        case '/orders/appointments':
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                editCol
            ];

        case '/orders/approve':
        case '/orders/progress':
            return [
                indexCol,
                orderCol,
                beginDatetimeCol,
                deliveryDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                editCol
            ];

        case '/orders/success':
            return [
                indexCol,
                orderCol,
                beginDatetimeCol,
                successDatetimeCol,
                clientCol,
                sumCol,
                remainingSumCol,
                responsibleCol,
                reviewCol,
                invitationCol,
                editCol
            ];

        case '/orders/cancel':
            return [
                indexCol,
                orderCol,
                beginDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                invitationCol,
                editCol
            ];

        case '/orders/reviews':
            return [
                indexCol,
                orderCol,
                reviewCol,
                beginDatetimeCol,
                successDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                editCol
            ];

        case '/orders/invitations':
            return [
                indexCol,
                orderCol,
                createDatetimeCol,
                beginDatetimeCol,
                clientCol,
                actionCol,
                responsibleCol,
                editCol
            ];

        default:
            return [
                indexCol,
                orderCol,
                datetimeCol,
                beginDatetimeCol,
                clientCol,
                sumCol,
                responsibleCol,
                editCol
            ];
    }
}

export function rowsConfig(activeRoute, selectedRowKeys, onChange, getCheckboxProps) {
    if (activeRoute === '/orders/success' || activeRoute === '/orders/cancel') {
        return {
            selectedRowKeys,
            onChange,
            getCheckboxProps
        };
    }

    return null;
}

export function scrollConfig(activeRoute) {
    switch (activeRoute) {
        case '/orders/appointments':
            return { x: 1500, y: '50vh' }; // 1600 - 80 -
        case '/orders/approve':
            return { x: 1340, y: '50vh' };
        case '/orders/progress':
            return { x: 1340, y: '50vh' }; // 1440 - 80 - 20
        case '/orders/success':
            return { x: 1860, y: '50vh' }; // 1820
        case '/orders/reviews':
            return { x: 1520, y: '50vh' }; // 1620
        case '/orders/invitations':
            return { x: 1260, y: '50vh' }; // 1400
        case 'orders/cancel':
            return { x: 1400, y: '50vh' }; // 1640 // -160 second date
        default:
            return { x: 1540, y: '50vh' }; // 1640
    }
}
