import { EnterOutlined } from '@ant-design/icons';
import { Button, Modal, Popover, Table } from 'antd';
import { Catcher } from 'commons';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';
import { getSupplier } from './supplierConfig';

@injectIntl
export default class PartSuggestions extends Component {
    render() {
        const { suggestions, hideModal, showModal, fetchPartAttributes, onSelect } = this.props;

        this.columns = [
            {
                title: <FormattedMessage id='partsSuggestions.photo' />,
                key: 'photo',
                width: '10%',
                render: suggestion => {
                    const image = _.get(suggestion, 'images[0]');

                    return image ? (
                        <img
                            onClick={() =>
                                fetchPartAttributes(suggestion.partNumber, suggestion.supplierId)
                            }
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src = `${__TECDOC_IMAGES_URL__}/not_found.png`;
                            }}
                            src={`${__TECDOC_IMAGES_URL__}/${image.pictureName}`}
                            style={{ cursor: 'pointer' }}
                            width={75}
                        />
                    ) : (
                        <div
                            onClick={() =>
                                fetchPartAttributes(suggestion.partNumber, suggestion.supplierId)
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            <img src={`${__TECDOC_IMAGES_URL__}/not_found.png`} width={75} />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='partsSuggestions.detailCode' />,
                dataIndex: 'partNumber',
                width: '15%'
            },
            {
                title: <FormattedMessage id='partsSuggestions.supplierName' />,
                dataIndex: 'supplierName',
                width: '15%',
                onFilter: (value, record) => record.supplierName === value,
                filters: _(suggestions)
                    .map('supplierName')
                    .uniq()
                    .sort()
                    .map(name => ({
                        text: name,
                        value: name
                    }))
                    .value()
            },
            {
                title: <FormattedMessage id='partsSuggestions.description' />,
                dataIndex: 'description',
                width: '20%'
            },
            {
                key: 'attributes',
                width: '25%',
                render: suggestion => (
                    <div>
                        {_.get(suggestion, 'attributes') && (
                            <Popover
                                content={_.chain(suggestion.attributes)
                                    .filter('description')
                                    .map(({ value, description }, index) => (
                                        <div key={`${index}--attr`}>
                                            <p>
                                                <a>{description}</a>: {value}
                                            </p>
                                        </div>
                                    ))
                                    .value()}
                                getPopupContainer={trigger => trigger.parentNode}
                            >
                                <div
                                    key={`${suggestion.partNumber}-${suggestion.supplierName}`}
                                    className={Styles.attributesText}
                                >
                                    {_.upperFirst(
                                        _.map(suggestion.attributes, 'value')
                                            .map(str => str.toLocaleLowerCase())
                                            .join(', ')
                                    )}
                                </div>
                            </Popover>
                        )}
                    </div>
                )
            },
            {
                key: 'actions',
                width: '10%',
                render: suggestion => (
                    <div className={Styles.suggestionContainer}>
                        <Button
                            className={Styles.suggestionItem}
                            icon={<EnterOutlined />}
                            onClick={() => {
                                onSelect(suggestion.brandId, suggestion.partNumber);
                                hideModal();
                            }}
                        >
                            <FormattedMessage id='partsSuggestions.apply' />
                        </Button>
                    </div>
                )
            },
            {
                key: 'ecat',
                width: '10%',
                render: ({ supplierId, partNumber }) => {
                    return getSupplier(supplierId, partNumber);
                }
            }
        ];

        const pagination = {
            pageSize: 6,
            hideOnSinglePage: true,
            size: 'large'
            // total:            Math.ceil(this.props.count / 25) * 25,
            // current:          this.props.page,
            // onChange:         page => this.props.setPage(page),
        };

        return (
            <Catcher>
                <Modal
                    cancelText={<FormattedMessage id='cancel' />}
                    footer={null}
                    maskClosable={false}
                    onCancel={() => hideModal()}
                    title={<FormattedMessage id='partsSuggestions.title' />}
                    visible={showModal}
                    width='65%'
                >
                    {suggestions && (
                        <Table
                            bordered
                            // style={ { verticalAlign: 'top' } }
                            className={Styles.tecdocTable}
                            columns={this.columns}
                            dataSource={suggestions}
                            pagination={pagination}
                        />
                    )}
                </Modal>
            </Catcher>
        );
    }
}
