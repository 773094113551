import { DatePicker, Form, InputNumber, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

const AddCurrencyModal = ({ open, onClose, usdId, fetchJournal, currencyOptions }) => {
    const [form] = Form.useForm();
    const [edit, setEdit] = useState(false);

    const getRecordFields = record => {
        return {
            date: dayjs(record.date).add(2, 'h'),
            currencyId: record.currencyId,
            exchangeRate: record.exchangeRate
        };
    };

    useEffect(() => {
        if (open && typeof open !== 'boolean') {
            setEdit(true);
            form.setFieldsValue(getRecordFields(open));
        }
    }, [form, open]);

    const handleClose = () => {
        form.resetFields();
        setEdit(false);
        onClose();
    };

    const onOk = () => {
        try {
            form.submit();
        } catch (err) {}
    };

    const postCurrency = async values => {
        if (!edit || !get(open, 'id')) {
            await fetchAPI('POST', 'business/currency/journal', null, [values], { handleErrorInternally: true });
        } else {
            await fetchAPI(
                'PUT',
                'business/currency/journal',
                null,
                [{ id: open.id, exchangeRate: values.exchangeRate }],
                {
                    handleErrorInternally: true
                }
            );
        }
        fetchJournal();
        handleClose();
    };

    return (
        <Modal
            maskClosable={false}
            onCancel={handleClose}
            onOk={onOk}
            open={open}
            title={<FormattedMessage id='exchange_rate.add_currency' />}
        >
            <Form
                name='currency'
                {...layout}
                autoComplete='off'
                form={form}
                initialValues={
                    typeof open !== 'boolean' || edit
                        ? getRecordFields(open)
                        : {
                              date: dayjs(),
                              currencyId: usdId,
                              exchangeRate: 1
                          }
                }
                onFinish={postCurrency}
            >
                <Form.Item
                    label={<FormattedMessage id='date' />}
                    name='date'
                    rules={[
                        {
                            required: true
                            // message: 'Please input your username!',
                        }
                    ]}
                >
                    <DatePicker disabled={edit} format='DD.MM.YYYY' />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='exchange_rate.currency' />}
                    name='currencyId'
                    rules={[
                        {
                            required: true
                            // message: 'Please input your username!',
                        }
                    ]}
                >
                    <Select disabled={edit} optionFilterProp='label' options={currencyOptions} showSearch />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='exchange_rate.exchange_rate' />}
                    name='exchangeRate'
                    rules={[
                        {
                            required: true
                        }
                    ]}
                >
                    <InputNumber decimalSeparator=',' min={0} precision={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddCurrencyModal;
