import CashboxRSTIcon from './CashboxRSTIcon';
import Catcher from './Catcher';
import DocumentTypesMapper from './DocumentTypesMapper';
import Footer from './Footer';
import Header from './Header';
import { Subscriptions } from './Header/Subscriptions';
import Layout from './Layout';
import Loader from './Loader';
import ModuleHeader from './ModuleHeader';
import Navigation from './Navigation';
import Numeral from './Numeral';
import OrdersStatusesMapper from './OrderStatusesMapper';
import { DesktopView, MobileView, ResponsiveView, TabletView } from './ResponsiveViews';
import Spinner from './Spinner';

// re-exports (*) must be before ES6 other (default) exports
// webpack issue: https://github.com/webpack/webpack/issues/3509
export * from './_uikit';

export {
    CashboxRSTIcon,
    Catcher,
    DesktopView,
    DocumentTypesMapper,
    Footer,
    Header,
    Layout,
    Loader,
    MobileView,
    ModuleHeader,
    Navigation,
    Numeral,
    OrdersStatusesMapper,
    ResponsiveView,
    Spinner,
    Subscriptions,
    TabletView
};
