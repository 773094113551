import { Form } from '@ant-design/compatible';
import { Switch } from 'antd';
import React from 'react';
import styled from 'styled-components';

const FormItem = Form.Item;
const StyledSwitch = styled(Switch)`
    display: ${props => props.hidden && 'none'};
`;

export class DecoratedSwitch extends React.PureComponent {
    render() {
        const {
            // FormItem
            formItem,
            label,
            colon,
            className,
            hasFeedback,
            formItemLayout,
            getFieldDecorator,
            disabled,
            rules,
            field,
            initialValue,
            children,
            onChange,
            hidden
        } = this.props;

        const switchComponent = getFieldDecorator(field, {
            valuePropName: 'checked',
            initialValue: Boolean(initialValue),
            rules
        })(<StyledSwitch disabled={disabled} hidden={hidden} onChange={onChange} />);

        return formItem ? (
            <FormItem
                className={className}
                colon={colon}
                hasFeedback={hasFeedback}
                label={label}
                {...formItemLayout}
            >
                {switchComponent}
            </FormItem>
        ) : (
            switchComponent
        );
    }
}
