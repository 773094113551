import { ContactsOutlined, RightOutlined } from '@ant-design/icons';
import { Input, Pagination, Table } from 'antd';
import {
    createOrderForClient,
    fetchClientOrders,
    setClientRowKey,
    setFiltersSearchQuery,
    setSortPage
} from 'core/clientHotOperations/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import ClientOrdersContainer from './ClientOrdersContainer';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    clients: state.clientHotOperations.clients,
    stats: state.clientHotOperations.stats,
    clientsFetching: state.clientHotOperations.clientsFetching,
    sort: state.clientHotOperations.sort,
    expandedClientRow: state.clientHotOperations.expandedClientRow,
    searchQuery: state.clientHotOperations.filters.query,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setFiltersSearchQuery,
    setSortPage,
    fetchClientOrders,
    setClientRowKey,
    createOrderForClient
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
@withRouter
export default class ClientsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.handleSearch = _.debounce(value => {
            this.props.setFiltersSearchQuery(value.replace(/[+()]/g, ''));
        }, 1000).bind(this);

        this.urlParams = new URLSearchParams(location.search); // Get params from query sting
        this.initialSearchQuery = this.urlParams.get('initial_search_query'); // Get init search value, we can use it to initialize Inputs
        if (this.props.location.state && this.props.location.state.searchValue) {
            this.initialSearchQuery = this.props.location.state.searchValue;
        }
        this.initialSearchQuery &&
            this.props.setFiltersSearchQuery(this.initialSearchQuery.replace(/[+()]/g, '')); // Set filter and fetch data if needed
    }

    onSearch = e => {
        const { value } = e.target;
        this.handleSearch(value);
    };

    /**
     * This event handler is used to create an order which will contain specific client and may contain vehicle if id was provided
     * @param {*} param0 Contains clientId which is used to define client in order and vehicleId of this client
     */
    onCreateOrderForClient = ({ clientId, vehicleId }) => {
        const { user } = this.props;
        this.props.createOrderForClient({ clientId, managerId: user.id, vehicleId });
    };

    render() {
        const {
            clients,
            stats,
            clientsFetching,
            sort,
            setSortPage,
            fetchClientOrders,
            setClientRowKey,
            expandedClientRow,
            user,
            history,
            isMobile,
            intl: { formatMessage }
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(stats.countClients / 25) * 25,
            hideOnSinglePage: true,
            current: sort.page,
            onChange: page => {
                setSortPage(page);
            }
        };

        return (
            <div>
                <div className={Styles.filtersCont}>
                    <div className={Styles.textCont}>
                        <FormattedMessage id='client_hot_operations_page.search' />:{' '}
                    </div>
                    <div className={Styles.inputCont}>
                        <Input
                            allowClear
                            defaultValue={this.initialSearchQuery}
                            onChange={this.onSearch}
                        />
                    </div>
                </div>

                <div>
                    {isMobile ? (
                        <div>
                            {clients.map(({ clientId, name, surname, phones, vehicles }) => (
                                <div
                                    key={clientId}
                                    className={Styles.clientBlock}
                                    onClick={() => history.push(`${book.client}/${clientId}`)}
                                >
                                    <div>
                                        <div className={Styles.clientHeader}>
                                            <ContactsOutlined /> {name} {surname}
                                        </div>
                                        <div className={Styles.clientBlockPhone}>
                                            {phones && phones[0]}
                                        </div>
                                    </div>
                                    <div className={Styles.clientBlockVehicle}>
                                        <div>
                                            {vehicles && vehicles.length ? (
                                                vehicles[0].make
                                            ) : (
                                                <FormattedMessage id='long_dash' />
                                            )}
                                        </div>
                                        <div>
                                            {vehicles && vehicles.length ? vehicles[0].number : ''}
                                        </div>
                                    </div>
                                    <RightOutlined className={Styles.clientBlockArrow} />
                                </div>
                            ))}
                            <div className={Styles.pagination}>
                                <Pagination {...pagination} />
                            </div>
                        </div>
                    ) : (
                        <Table
                            bordered
                            className={Styles.table}
                            columns={columnsConfig({
                                user,
                                onCreateOrderForClient: this.onCreateOrderForClient
                            })}
                            dataSource={clients}
                            expandedRowKeys={[expandedClientRow]}
                            expandedRowRender={() => <ClientOrdersContainer />}
                            loading={clientsFetching}
                            onExpand={(expanded, client) => {
                                expanded && fetchClientOrders({ clientId: client.clientId });
                                expanded && setClientRowKey(`${client.clientId}`);
                                !expanded && setClientRowKey('');
                            }} // Only one row can be expanded at the time
                            pagination={pagination}
                            rowKey={client => `${client.clientId}`}
                            scroll={{ x: 1000, y: '70vh' }}
                        />
                    )}
                </div>
            </div>
        );
    }
}
