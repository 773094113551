exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__block---2jXNb{padding:4px;display:flex;justify-content:space-between;margin-bottom:10px;border-bottom:2px solid var(--db_reserve)}.styles-m__rightContent---3Qwvi{display:flex;justify-content:end;margin-top:8px}.styles-m__finalBlock---3bJL4{padding:4px;margin-bottom:10px}.styles-m__title---1C4Qp{font-size:16px;font-weight:700}.styles-m__margin---UOK3g{margin-top:8px}.styles-m__icon---fgLUr{font-size:12px;padding:0 4px;cursor:pointer}.styles-m__okBtn---1HNzS{display:flex;justify-content:end;margin-bottom:10px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACE,WAAY,CACZ,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,yCACF,CAEA,gCACE,YAAa,CACb,mBAAoB,CACpB,cAEF,CAEA,8BACE,WAAY,CACZ,kBACF,CAEA,yBACE,cAAe,CACf,eACF,CAEA,0BACE,cACF,CAEA,wBACE,cAAe,CACf,aAAc,CACd,cACF,CAEA,yBACE,YAAa,CACb,mBAAoB,CACpB,kBACF","file":"styles.m.css","sourcesContent":[".block {\n  padding: 4px;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  border-bottom: 2px solid var(--db_reserve);\n}\n\n.rightContent {\n  display: flex;\n  justify-content: end;\n  margin-top: 8px;\n\n}\n\n.finalBlock {\n  padding: 4px;\n  margin-bottom: 10px;\n}\n\n.title {\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.margin {\n  margin-top: 8px;\n}\n\n.icon {\n  font-size: 12px;\n  padding: 0 4px;\n  cursor: pointer;\n}\n\n.okBtn {\n  display: flex;\n  justify-content: end;\n  margin-bottom: 10px;\n}"]}]);

// Exports
exports.locals = {
	"block": "styles-m__block---2jXNb",
	"rightContent": "styles-m__rightContent---3Qwvi",
	"finalBlock": "styles-m__finalBlock---3bJL4",
	"title": "styles-m__title---1C4Qp",
	"margin": "styles-m__margin---UOK3g",
	"icon": "styles-m__icon---fgLUr",
	"okBtn": "styles-m__okBtn---1HNzS"
};