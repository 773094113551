/* eslint-disable complexity */
import { emitError } from 'core/ui/duck';
import _ from 'lodash';
import { SEARCH_TYPES } from 'modals/DebtZeroingModal/components/containers/ClientCounterpartyContainer/constants';
import { OPERATION_TYPES } from 'modals/DebtZeroingModal/constants';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { saga as analyticsSaga } from './analytics/saga';
import { saga as cashboxesSaga } from './cashboxes/saga';
import { saga as clientsSaga } from './clients/saga';
import {
    COVER_DEBTS,
    selectClientSearchType,
    selectDateRange,
    selectOrdersFilters,
    selectSelectedCashboxId,
    selectSelectedExpenseAnalyticsUniqueId,
    selectSelectedIncomeAnalyticsUniqueId,
    selectSelectedOrderId,
    selectSelectedOrderStatus,
    selectSelectedStoreDocId,
    selectSelectedSupplierStoreDocId,
    selectServiceOperationType,
    selectStoreDocsFilters,
    selectSuppliersStoreDocsFilters
} from './duck';
import { saga as ordersSaga } from './orders/saga';
import { saga as storeDocsSaga } from './storeDocs/saga';
import { saga as suppliersSaga } from './suppliers/saga';
import { saga as suppliersStoreDocsSaga } from './suppliersStoreDocs/saga';

export function* coverDebtsSaga() {
    while (true) {
        try {
            yield take(COVER_DEBTS);
            const dateRange = yield select(selectDateRange);
            const incomesAnalyticsUniqueId = yield select(selectSelectedIncomeAnalyticsUniqueId);
            const expensesAnalyticsUniqueId = yield select(selectSelectedExpenseAnalyticsUniqueId);
            const cashboxId = yield select(selectSelectedCashboxId);
            const orderStatus = yield select(selectSelectedOrderStatus);
            const orderId = yield select(selectSelectedOrderId);
            const ordersFilters = yield select(selectOrdersFilters); // To close the same orders as in the table we have to use the same filters
            const serviceOperationType = yield select(selectServiceOperationType);
            const clientSearchType = yield select(selectClientSearchType);
            const supplierStoreDocsFilters = yield select(selectSuppliersStoreDocsFilters); // To close the same store docs as in the table we have to use the same filters
            const selectedSupplierStoreDocId = yield select(selectSelectedSupplierStoreDocId);

            const storeDocsFilters = yield select(selectStoreDocsFilters);
            const selectedStoreDocId = yield select(selectSelectedStoreDocId);

            // eslint-disable-next-line init-declarations
            let path;
            const payload = {
                cashboxId,
                incomesAnalyticsUniqueId,
                expensesAnalyticsUniqueId
            };

            if (serviceOperationType === OPERATION_TYPES.RECEIVABLES) {
                if (clientSearchType === SEARCH_TYPES.FOR_DOCUMENT) {
                    path = '/debt_zeroing_store_docs';
                    payload.filters = {
                        ..._.omit(storeDocsFilters, ['page']),
                        startDate: dateRange.startDate,
                        endDate: dateRange.endDate,
                        storeDocId: selectedStoreDocId
                    };
                } else {
                    path = '/debt_zeroing_orders';
                    payload.filters = {
                        ..._.omit(ordersFilters, ['page']),
                        startDate: dateRange.startDate,
                        endDate: dateRange.endDate,
                        status: orderStatus,
                        orderId
                    };
                }
            } else if (serviceOperationType === OPERATION_TYPES.PAYABLES) {
                path = '/debt_zeroing_store_docs';
                payload.filters = {
                    ..._.omit(supplierStoreDocsFilters, ['page']),
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    storeDocId: selectedSupplierStoreDocId
                };
            }

            if (path) {
                console.log('Payload for covering debt: ', payload);
                // Send request is path selected
                yield call(fetchAPI, 'POST', path, null, payload);
            }
        } catch (error) {
            yield put(emitError(error));
        }
    }
}

export function* saga() {
    yield all([
        call(coverDebtsSaga),

        suppliersStoreDocsSaga(),
        suppliersSaga(),
        ordersSaga(),
        clientsSaga(),
        storeDocsSaga(),
        analyticsSaga(),
        cashboxesSaga()
    ]);
}
