/* eslint-disable react/prefer-stateless-function */
import { MailOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, notification } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

class SendAPIDropdown extends React.Component {
    render() {
        const menu = (
            <Menu>
                <Menu.Item
                    data-qa='menu_item__api_document_storage_document_page'
                    disabled={
                        this.props.formData.externalApiOrderStatus === 'SENT' ||
                        this.props.formData.externalApiOrderStatus === 'HANDLE_SENT'
                    }
                    onClick={() => {
                        if (this.props.formData.externalApiOrderStatus === 'SENT') {
                            return;
                        }
                        this.props.updateFormData(
                            { externalApiOrderStatus: 'SENT', createApiOrder: true },
                            true,
                            () => {
                                notification.success({
                                    message: this.props.intl.formatMessage({ id: 'sent' })
                                });
                            }
                        );
                    }}
                >
                    <FormattedMessage id='storage_document.send_by_API' />
                </Menu.Item>
                <Menu.Item
                    data-qa='menu_item_manually_storage_document_page'
                    disabled={
                        this.props.formData.externalApiOrderStatus === 'SENT' ||
                        this.props.formData.externalApiOrderStatus === 'HANDLE_SENT'
                    }
                    onClick={() => {
                        this.props.updateFormData({ externalApiOrderStatus: 'HANDLE_SENT' }, true, () => {
                            notification.success({
                                message: this.props.intl.formatMessage({ id: 'sent' })
                            });
                        });
                    }}
                >
                    <FormattedMessage id='storage_document.send_manually' />
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown
                data-qa='dropdown_file_document_storage_document_page'
                disabled={
                    this.props.formData.externalApiOrderStatus === 'SENT' ||
                    this.props.formData.externalApiOrderStatus === 'HANDLE_SENT'
                }
                overlay={menu}
            >
                <Button
                    disabled={this.props.formData.externalApiOrderStatus === 'SENT'}
                    icon={<MailOutlined style={{ fontSize: 24 }} />}
                    size='large'
                    style={{ marginLeft: 8 }}
                    type='text'
                />
            </Dropdown>
        );
    }
}

export default SendAPIDropdown;
