exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__row---1ijt8{height:3em}.styles-m__radioButtons---qazGu{width:100%}.styles-m__radioRow---12Zse{margin-top:1em;height:1em}.styles-m__paddingCol---_Mq1m{padding-left:1em}.styles-m__boldCol---1u1If{font-weight:700}.styles-m__detailingWrapper---1WVFQ,.styles-m__percentInputStyle---1HUsa{display:flex;justify-content:space-between}.styles-m__percentInputStyle---1HUsa{margin-bottom:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,uBACI,UACJ,CAEA,gCACI,UACJ,CAEA,4BACI,cAAe,CACf,UACJ,CACA,8BACI,gBACJ,CACA,2BACI,eACJ,CAOA,yEAJI,YAAa,CACb,6BAQJ,CALA,qCAEI,iBAGJ","file":"styles.m.css","sourcesContent":[".row {\n    height: 3em;\n}\n\n.radioButtons {\n    width: 100%;\n}\n\n.radioRow {\n    margin-top: 1em;\n    height: 1em;\n}\n.paddingCol{\n    padding-left: 1em;\n}\n.boldCol{\n    font-weight: bold;\n}\n\n.detailingWrapper {\n    display: flex;\n    justify-content: space-between;\n}\n\n.percentInputStyle {\n    display: flex;\n    margin-bottom: 8px;\n    justify-content: space-between;\n    \n}\n\n.percentTittleStyle {\n    \n}"]}]);

// Exports
exports.locals = {
	"row": "styles-m__row---1ijt8",
	"radioButtons": "styles-m__radioButtons---qazGu",
	"radioRow": "styles-m__radioRow---12Zse",
	"paddingCol": "styles-m__paddingCol---_Mq1m",
	"boldCol": "styles-m__boldCol---1u1If",
	"detailingWrapper": "styles-m__detailingWrapper---1WVFQ",
	"percentInputStyle": "styles-m__percentInputStyle---1HUsa",
	"percentTittleStyle": "styles-m__percentTittleStyle---1YeUl"
};