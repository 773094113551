/* Constants */
import {
    counterpartValues,
    documentTypeArrayValues,
    priorityValues,
    statusValues
} from 'modals/EmployeeTaskModal/constants';
import { fetchBrands, SET_BRANDS_FILTERS } from 'pages/DirectoryBrandsPage/redux/duck';

export const moduleName = 'employeeSubTasksModal';
const prefix = `cpb/${moduleName}`;

export const FETCH_SUBTASKS = `${prefix}/FETCH_SUBTASKS`;
export const FETCH_SUBTASKS_SUCCESS = `${prefix}/FETCH_SUBTASKS_SUCCESS`;

export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const RESET_FILTERS = `${prefix}/RESET_FILTERS`;

export const SET_FETCH_SUBTASKS_FETCHING = `${prefix}/SET_FETCH_SUBTASKS_FETCHING`;

/** Date format for backend */
export const DEF_DATE_FORMAT = 'YYYY/MM/DD';

/* Reducer */
const ReducerState = {
    filters: {
        onlyNew: true,
        page: 1
    },
    fetching: false,
    subtasks: [],
    stats: {}
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...payload }
            };

        case SET_FETCH_SUBTASKS_FETCHING:
            return {
                ...state,
                fetching: payload
            };

        case RESET_FILTERS:
            return {
                ...state,
                filters: {
                    page: 1
                }
            };

        case FETCH_SUBTASKS_SUCCESS:
            return {
                ...state,
                subtasks: payload.employeeTasks,
                stats: payload.stats
            };
        default:
            return state;
    }
}

/* Selectors */

export const selectFilters = state => state[moduleName].filters;
export const selectSubtasks = state => state[moduleName].subtasks;
export const selectStats = state => state[moduleName].stats;
export const selectFetching = state => state[moduleName].fetching;

/* Actions */

export const setFilters = filters => ({
    type: SET_FILTERS,
    payload: filters
});

// export const setFilters = (filters) => {
//
//     return (dispatch) => {
//         dispatch({
//             type: SET_FILTERS,
//             payload: filters
//         });
//
//         return dispatch(fetchSubtasks());
//     }
// }

export const fetchSubtasks = id => ({
    type: FETCH_SUBTASKS,
    payload: id
});

export const fetchSubtasksSuccess = data => ({
    type: FETCH_SUBTASKS_SUCCESS,
    payload: data
});

export const setFetching = value => ({
    type: SET_FETCH_SUBTASKS_FETCHING,
    payload: value
});

export const resetFields = () => ({
    type: RESET_FILTERS
});
