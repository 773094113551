import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

/** Width for each col, it must be 100% of width in total or less than 100% with auto keywords somewhere */
const defWidth = {
    vehicle: '20%',
    vehicleNumber: '10%',
    vehicleVin: '20%',
    client: 'auto'
};

export function columnsConfig() {
    const aggregateName = {
        title: <FormattedMessage id='order_form.aggregate' />,
        width: defWidth.vehicle,
        key: 'aggregateName',
        render: (el, vehicle) => {
            return (
                <div>
                    <Link to={`${book.aggregate}/${el.clientAggregateId}`}>{_.get(el, 'name.ua')}</Link>
                </div>
            );
        }
    };

    const aggregateBrandName = {
        title: <FormattedMessage id='brand' />,
        width: defWidth.vehicleNumber,
        align: 'center',
        dataIndex: 'brandName',
        key: 'brandName',
        render: brandName => {
            return <div>{brandName}</div>;
        }
    };

    const aggregateNumber = {
        title: <FormattedMessage id='add_client_form.aggregate_code' />,
        width: defWidth.vehicleVin,
        dataIndex: 'aggregateNumber',
        key: 'aggregateNumber',
        render: aggregateNumber => {
            return <div>{aggregateNumber}</div>;
        }
    };

    const aggregateBarcode = {
        title: <FormattedMessage id='VIN' />,
        width: defWidth.client,
        dataIndex: 'vin',
        key: 'vin',
        render: vin => {
            return <div>{vin || <FormattedMessage id='long_dash' />}</div>;
        }
    };

    const clientInfo = {
        title: <FormattedMessage id='calls-table.client' />,
        width: defWidth.client,
        key: 'clientInfo',
        render: (el, client) => {
            return (
                <div>
                    <div>
                        <Link to={`${book.client}/${_.get(client, 'clientId')}`}>
                            {`${_.get(el, 'clientName')} ${_.get(el, 'clientSurname')}`}
                        </Link>
                    </div>

                    <div>
                        {_.get(el, 'clientPhones', []).map(phone => (
                            <a key={v4()} className={Styles.clientPhone} href={`tel:${phone}`}>
                                {phone}
                            </a>
                        ))}
                    </div>
                </div>
            );
        }
    };

    return [aggregateName, aggregateBrandName, aggregateNumber, aggregateBarcode, clientInfo];
}
