import { emitError } from 'core/ui/duck';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchSuppliersSuccess,
    FETCH_SUPPLIERS,
    selectSuppliersFilters,
    setFetchingSuppliers
} from './duck';

export function* fetchSuppliersSaga() {
    while (true) {
        try {
            yield take(FETCH_SUPPLIERS);

            yield put(setFetchingSuppliers(true));
            const filters = yield select(selectSuppliersFilters);

            const response = yield call(fetchAPI, 'GET', 'business_suppliers', { ...filters });

            yield put(fetchSuppliersSuccess({ suppliers: response }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield put(setFetchingSuppliers(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchSuppliersSaga)]);
}
