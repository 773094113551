exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__header---yx5vk{display:flex;justify-content:space-between;margin-bottom:24px}.styles-m__headerBlock---3R4vi{border:1px solid var(--primary);flex:1 1 auto;text-align:center;padding:16px;line-height:24px}.styles-m__dateBlock---By_gH{display:flex;align-items:center;justify-content:center}.styles-m__disabledRow---1e1qO{color:var(--text2);background:var(--db_cancel)}.styles-m__numberColumn---2trPd{text-align:right}.styles-m__countLink---2CVz_{text-decoration:underline}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,0BACC,YAAa,CACb,6BAA8B,CAC9B,kBACD,CAEA,+BACC,+BAAgC,CAChC,aAAc,CACd,iBAAkB,CAClB,YAAa,CACb,gBACD,CAEA,6BACC,YAAa,CACV,kBAAmB,CACnB,sBACJ,CAEA,+BACC,kBAAmB,CACnB,2BACD,CAEA,gCACC,gBACD,CAEA,6BACC,yBACD","file":"styles.m.css","sourcesContent":[".header {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin-bottom: 24px;\n}\n\n.headerBlock {\n\tborder: 1px solid var(--primary);\n\tflex: 1 1 auto;\n\ttext-align: center;\n\tpadding: 16px;\n\tline-height: 24px;\n}\n\n.dateBlock {\n\tdisplay: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.disabledRow {\n\tcolor: var(--text2);\n\tbackground: var(--db_cancel);\n}\n\n.numberColumn {\n\ttext-align: right;\n}\n\n.countLink {\n\ttext-decoration: underline;\n}"]}]);

// Exports
exports.locals = {
	"header": "styles-m__header---yx5vk",
	"headerBlock": "styles-m__headerBlock---3R4vi",
	"dateBlock": "styles-m__dateBlock---By_gH",
	"disabledRow": "styles-m__disabledRow---1e1qO",
	"numberColumn": "styles-m__numberColumn---2trPd",
	"countLink": "styles-m__countLink---2CVz_"
};