exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__suggestionContainer---ptT5Q{display:flex;flex-wrap:wrap}.styles-m__suggestionItem---1MZmf{margin:0 2px}.styles-m__logo---l9ZtP{width:60px}.styles-m__attributesText---2RVrz{overflow:hidden;line-height:1.2em;max-height:3.6em;text-align:justify}.styles-m__tecdocTable---1gCUJ .ant-table tr td{vertical-align:top}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,uCACI,YAAa,CACb,cACJ,CAEA,kCACI,YACJ,CAEA,wBACI,UACJ,CAEA,kCACI,eAAgB,CAChB,iBAAkB,CAClB,gBAAiB,CACjB,kBACJ,CAEA,gDACI,kBACJ","file":"styles.m.css","sourcesContent":[".suggestionContainer {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.suggestionItem {\n    margin: 0 2px;\n}\n\n.logo {\n    width: 60px;\n}\n\n.attributesText {\n    overflow: hidden;\n    line-height: 1.2em;\n    max-height: 3.6em;\n    text-align: justify;\n}\n\n.tecdocTable :global(.ant-table tr td) {\n    vertical-align: top;\n}\n\n"]}]);

// Exports
exports.locals = {
	"suggestionContainer": "styles-m__suggestionContainer---ptT5Q",
	"suggestionItem": "styles-m__suggestionItem---1MZmf",
	"logo": "styles-m__logo---l9ZtP",
	"attributesText": "styles-m__attributesText---2RVrz",
	"tecdocTable": "styles-m__tecdocTable---1gCUJ"
};