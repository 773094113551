import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Table } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';

export class TemplatesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.columns = [
            {
                title: <FormattedMessage id='receipt_document_modal.template_name_title' />,
                dataIndex: 'templateName',
                key: 'name',
                align: 'center'
            },
            {
                key: 'ok_btn',
                align: 'center',
                render: row => {
                    return (
                        <Button
                            icon={<CheckCircleOutlined />}
                            onClick={() => {
                                this.props.setTemplate(row.id);
                                this.props.hideModal();
                            }}
                        />
                    );
                }
            },
            {
                key: 'delete',
                align: 'center',
                render: row => {
                    return (
                        <Popconfirm
                            autoAdjustOverflow={false}
                            disabled={!row.businessId}
                            onConfirm={() => this.deleteTemplate(row.id)}
                            title={<FormattedMessage id='general_settings_delete_question' />}
                            zIndex={2002}
                        >
                            <Button disabled={!row.businessId} icon={<DeleteOutlined />} />
                        </Popconfirm>
                    );
                }
            }
        ];
    }

    deleteTemplate = async id => {
        await fetchAPI('DELETE', `sync/template?id=${id}`, null, null, {
            handleErrorInternally: true
        });
        this.props.fetchTemplate();
    };

    handleCancel = () => {
        const { hideModal } = this.props;
        hideModal();
    };

    render() {
        const { visible, templates } = this.props;

        return (
            <Modal
                footer={null}
                onCancel={this.handleCancel}
                maskClosable={false}
                title={<FormattedMessage id='receipt_document_modal.choose_template' />}
                visible={visible}
                zIndex={2001}
            >
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={templates || []}
                    pagination={false}
                    rowKey='id'
                />
                {/* <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={id => this.setState({ id })}
                    optionFilterProp='children'
                    showSearch
                    value={id}
                >
                    {templates.map(({ templateName, id }) => (
                        <Option key={id} value={id}>
                            {templateName}
                        </Option>
                    ))}
                </Select> */}
            </Modal>
        );
    }
}
