import { CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './styles.less';

@injectIntl
class BrandSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.columns = [
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brandName',
                dataIndex: 'brandName'
            },
            {
                title: <FormattedMessage id='role-container.name' />,
                key: 'name',
                dataIndex: 'name',
                render: data =>
                    data ? (
                        String(data).length > 70 ? (
                            `${String(data).substring(0, 70)}...`
                        ) : (
                            data
                        )
                    ) : (
                        <FormattedMessage id='long_dash' />
                    )
            },
            /* {
                title: <FormattedMessage id='orders.source' />,
                key: 'source',
                dataIndex: 'source',
                render: data => data.map(elem => `${elem}, `)
            }, */
            {
                title: <FormattedMessage id='store_doc_service.count' />,
                key: 'count',
                dataIndex: 'count'
            },
            {
                key: 'action',
                render: row => (
                    <Button
                        icon={<CheckOutlined />}
                        onClick={() => {
                            this.props.onSelect(row);
                            this.props.hideModal();
                        }}
                    />
                )
            }
        ];
    }

    render() {
        const { visibleBrandSelectModal, brands, hideModal, onSelect } = this.props;

        return (
            <Modal
                footer={null}
                maskClosable={false}
                onCancel={hideModal}
                title={<FormattedMessage id='order_form_table.brand.placeholder' />}
                visible={visibleBrandSelectModal}
            >
                <Table
                    columns={this.columns}
                    dataSource={brands || []}
                    onRow={record => {
                        return {
                            onDoubleClick: () => {
                                onSelect(record);
                                hideModal();
                            }
                        };
                    }}
                    pagination={false}
                />
            </Modal>
        );
    }
}

export default BrandSelectModal;
