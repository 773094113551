/* eslint-disable comma-dangle */
/**
 * Constants
 * */
export const moduleName = 'loginForm';
const prefix = `cpb/${moduleName}`;

export const LOGIN = `${prefix}/LOGIN`;
export const LOGIN_SUCCESS = `${prefix}/LOGIN_SUCCESS`;

/**
 * Reducer
 * */
//

const ReducerState = {};

export default function reducer(state = ReducerState, action) {
    const { type } = action;

    switch (type) {
        default:
            return state;
    }
}

/**
 * Selectors
 * */

export const stateSelector = state => state[moduleName];

/**
 * Action Creators
 * */

export const login = credentials => ({
    type: LOGIN,
    payload: credentials
});

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS
});
