import { Flex, Input, InputNumber, Modal, Select, TreeSelect, notification } from 'antd';
import _, { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getAccountsList } from 'pages/AccountPlanPage/utils/getAccountsList';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { TextArea } = Input;
const { Option } = Select;

const counterpartyTypeMap = {
    CLIENTS: 'interactions.counterparty_type.CLIENT',
    EMPLOYEES: 'interactions.counterparty_type.EMPLOYEE',
    SUPPLIERS: 'interactions.counterparty_type.SUPPLIER',
    STOCKS: 'entries_tab.STOCK',
    CASHBOXES: 'entries_tab.CASHBOX'
};

const inputWidth = 180;

const AddAccRowModal = ({
    open = false,
    closeModal,
    intl,
    id,
    fetchDocData,
    rowData,
    docData,
    isMobile,
    setTemplateRows,
    fetchAccTemplates,
    fetchTemplate,
    templates,
    updateList,
    rows
}) => {
    const [newRows, setNewRows] = useState();
    const [accounts, setAccounts] = useState([]);
    const [flattenAccounts, setFlattenAccounts] = useState([]);
    const [tree, setTree] = useState([]);
    const [accountId, setAccountId] = useState();
    const [accountIdSecond, setAccountIdSecond] = useState();
    const [accountName, setAccountName] = useState();
    const [accountNameSecond, setAccountNameSecond] = useState();
    const [counterpartType, setCounterparty] = useState();
    const [counterpartTypeSecond, setCounterpartySecond] = useState();
    const [amount, setAmount] = useState();
    const [amountSecond, setAmountSecond] = useState();
    const [debit, setDebit] = useState('DEBET');
    const [credit, setCredit] = useState('CREDIT');

    const rowDataCredit = rowData ? rowData.find(({ type }) => type === 'CREDIT') : {};
    const rowDataDebet = rowData ? rowData.find(({ type }) => type === 'DEBET') : {};

    const onClose = async () => {
        setAccountId(undefined);
        setAccountName(undefined);
        setAccountNameSecond(undefined);
        setAccountIdSecond(undefined);
        setAmount(undefined);
        setAmountSecond(undefined);
        setCounterparty(undefined);
        setCounterpartySecond(undefined);
        await closeModal();
    };

    const generateUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 15) >> (c === 'x' ? 0 : 1);
            const v = c === 'x' ? r : (r & 0x3) | 0x8;

            return v.toString(16);
        });
    };

    const handleSave = async arr => {
        try {
            await fetchAPI(
                'PUT',
                '/general_ledger/acc_document_template',
                undefined,
                {
                    ..._.omit(docData, [
                        'value',
                        'businessId',
                        'managerId',
                        'warehouseName',
                        'managerName',
                        'cashBoxName',
                        'warehouseAccountId',
                        'cashBoxAccountId',
                        'counterpartAccountId',
                        'businessRequisiteName',
                        'counterpartName'
                    ]),
                    counterpartName: docData.counterpartName || null,
                    amount: docData.amount || 0,
                    rows: arr
                },
                { handleErrorInternally: true }
            );

            await fetchAccTemplates();

            notification.success({ message: intl.formatMessage({ id: 'barcode.success' }) });
        } catch (e) {
            notification.error({ message: intl.formatMessage({ id: 'error' }) });
        }
    };

    const handleFinish = async () => {
        const newOperationId = generateUUID();
        const submitValuesAdd = [
            {
                id: rowDataCredit ? rowDataCredit.id : undefined,
                accountId: accountIdSecond,
                accountName: accountNameSecond,
                counterpartType: counterpartTypeSecond,
                counterpartId: docData.counterpartId,
                amount: amountSecond,
                type: credit,
                operationId: newOperationId
            },
            {
                id: rowDataDebet ? rowDataDebet.id : undefined,
                accountId,
                accountName,
                counterpartType,
                counterpartId: docData.counterpartId,
                amount,
                type: debit,
                operationId: newOperationId
            }
        ];

        const newArray = [...rows, ...submitValuesAdd];

        await handleSave(newArray);

        await fetchTemplate(newArray);

        await onClose();
    };

    const handleFinishEdit = async () => {
        const updatedItem = rows.map(item => {
            if (item.operationId == rowDataCredit.operationId && item.type === 'CREDIT') {
                return {
                    id: rowDataCredit ? rowDataCredit.id : undefined,
                    accountId: accountIdSecond,
                    accountName: accountNameSecond,
                    counterpartType: counterpartTypeSecond,
                    counterpartId: docData.counterpartId,

                    amount: amountSecond,
                    type: credit,
                    operationId: rowDataCredit.operationId
                };
            }
            if (item.operationId == rowDataDebet.operationId && item.type === 'DEBET') {
                return {
                    id: rowDataDebet ? rowDataDebet.id : undefined,
                    accountId,
                    accountName,
                    counterpartType,

                    counterpartId: docData.counterpartId,
                    amount,
                    type: debit,
                    operationId: rowDataDebet.operationId
                };
            }

            return item;
        });

        const updatedArray = [...updatedItem];

        await handleSave(updatedArray);

        await fetchTemplate(updatedArray);

        await onClose();
    };

    const onFlatten = accounts => {
        const flattenTree = [];
        const flattenStoreGroups = data => {
            for (let item = 0; item < _.get(data, 'length'); item++) {
                const node = data[item];

                flattenTree.push({
                    id: node.id,
                    name: node.name,
                    level: node.level,
                    parentId: node.parentId
                });
                if (node.children) {
                    flattenStoreGroups(node.children);
                }
            }
        };
        flattenStoreGroups(accounts);

        setFlattenAccounts(flattenTree);

        return flattenTree;
    };

    const setRowData = () => {
        if (rowData) {
            setAccountId(rowDataDebet.accountId);
            setAccountName(rowDataDebet.accountName);
            setAccountNameSecond(rowDataCredit.accountName);
            setAccountIdSecond(rowDataCredit.accountId);
            setCounterparty(rowDataDebet.counterpartType);
            setCounterpartySecond(rowDataCredit.counterpartType);
            setAmount(rowDataDebet.amount);
            setAmountSecond(rowDataCredit.amount);
        }
    };

    const filterTreeByActive = useCallback(nodes => {
        return nodes.reduce((filteredNodes, node) => {
            if (node.active === true) {
                filteredNodes.push({
                    value: node.id,
                    name: node.name,
                    title: `(#${node.id}) ${node.name} `,
                    disabled: node.level < 4
                });

                if (node.children) {
                    const filteredChildren = filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNodes[filteredNodes.length - 1].children = filteredChildren;
                    }
                }
            }

            setTree(filteredNodes);

            return filteredNodes;
        }, []);
    });

    useEffect(() => {
        if (!_.get(tree, 'length') && _.get(accounts, 'length')) {
            filterTreeByActive(accounts);
        }
    }, [tree, accounts, filterTreeByActive]);

    useEffect(() => {
        const fetchAccounts = async query => {
            const accounts = await getAccountsList(query);
            setAccounts(accounts);
            onFlatten(accounts);
        };
        if (open === true) {
            fetchAccounts();
        }

        setRowData();
    }, [open]);

    const findAccountName = accountId => {
        if (get(flattenAccounts, 'length')) {
            const obj = flattenAccounts.find(({ id }) => id == accountId);

            return obj ? obj.name : null;
        }
    };

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{
                disabled: !accountId || !accountIdSecond || !amount || !amountSecond
            }}
            onCancel={onClose}
            onOk={() => {
                rowData ? handleFinishEdit() : handleFinish();
            }}
            open={open}
            style={{ minWidth: !isMobile ? 1024 : '95%' }}
        >
            {/* <div className={Styles.formWrapper}> */}

            <Flex
                className={Styles.formRow}
                {...(isMobile ? { vertical: true } : { horizontal: true })}
                justify='space-between'
            >
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='requisite-setting.account' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <TreeSelect
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        listHeight={440}
                        onSelect={(value, option) => {
                            setAccountIdSecond(value);
                            setAccountNameSecond(option.name);
                        }}
                        placeholder={
                            <React.Fragment>
                                <FormattedMessage id='receipt_document_modal.bill' />
                            </React.Fragment>
                        }
                        showSearch
                        style={{ color: 'var(--text)', width: !isMobile ? 280 : '100%' }}
                        treeData={tree}
                        treeNodeFilterProp={(input, node) => {
                            return node.active;
                        }}
                        value={accountIdSecond}
                    />
                </div>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='interactions.counterparty_type' />:
                    </div>
                    <Select
                        onChange={value => {
                            setCounterpartySecond(value);

                            if (value === 'CLIENTS' || value === 'EMPLOYEES' || value === 'SUPPLIERS') {
                                if (docData.counterpartAccountId) {
                                    const name = findAccountName(docData.counterpartAccountId);
                                    setAccountIdSecond(docData.counterpartAccountId);
                                    setAccountNameSecond(name);
                                }
                            } else if (value === 'STOCKS') {
                                if (docData.warehouseAccountId) {
                                    const name = findAccountName(docData.warehouseAccountId);
                                    setAccountIdSecond(docData.warehouseAccountId);
                                    setAccountNameSecond(name);
                                }
                            } else if (value === 'CASHBOXES') {
                                if (docData.cashBoxAccountId) {
                                    const name = findAccountName(docData.cashBoxAccountId);
                                    setAccountIdSecond(docData.cashBoxAccountId);
                                    setAccountNameSecond(name);
                                }
                            }
                        }}
                        placeholder={intl.formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        style={{ width: '100%' }}
                        value={counterpartTypeSecond}
                    >
                        {Object.entries(counterpartyTypeMap).map(([key, value]) => (
                            <Option key={key} value={key}>
                                <FormattedMessage id={value} />
                            </Option>
                        ))}
                    </Select>
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.type' />:
                    </div>
                    <Select
                        disabled
                        placeholder={intl.formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        style={{ width: '100%' }}
                        value={credit}
                    >
                        <Option value='CREDIT'>
                            <FormattedMessage id='entries_tab.sign.-' />
                        </Option>
                    </Select>
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.sum' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <InputNumber
                        decimalSeparator=','
                        onChange={value => {
                            if (docData.operationType !== 'DIRECT') {
                                setAmountSecond(value);
                                setAmount(-value);
                            } else {
                                setAmount(Math.abs(value));
                                setAmountSecond(Math.abs(value) * -1);
                            }
                        }}
                        placeholder={intl.formatMessage({
                            id: 'cash-table.sum'
                        })}
                        style={{ width: '100%' }}
                        value={amountSecond}
                    />
                </div>
            </Flex>
            <Flex
                className={Styles.formRow}
                {...(isMobile ? { vertical: true } : { horizontal: true })}
                justify='space-between'
            >
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='requisite-setting.account' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <TreeSelect
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        listHeight={440}
                        onSelect={(value, option) => {
                            setAccountId(value);
                            setAccountName(option.name);
                        }}
                        placeholder={
                            <React.Fragment>
                                <FormattedMessage id='receipt_document_modal.bill' />
                            </React.Fragment>
                        }
                        showSearch
                        style={{ color: 'var(--text)', width: !isMobile ? 280 : '100%' }}
                        treeData={tree}
                        treeNodeFilterProp={(input, node) => {
                            return node.active;
                        }}
                        value={accountId}
                    />
                </div>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='interactions.counterparty_type' />:
                    </div>
                    <Select
                        onChange={value => {
                            setCounterparty(value);

                            if (value === 'CLIENTS' || value === 'EMPLOYEES' || value === 'SUPPLIERS') {
                                if (docData.counterpartAccountId) {
                                    const name = findAccountName(docData.counterpartAccountId);
                                    setAccountId(docData.counterpartAccountId);
                                    setAccountName(name);
                                }
                            } else if (value === 'STOCKS') {
                                if (docData.warehouseAccountId) {
                                    const name = findAccountName(docData.warehouseAccountId);
                                    setAccountId(docData.warehouseAccountId);
                                    setAccountName(name);
                                }
                            } else if (value === 'CASHBOXES') {
                                if (docData.cashBoxAccountId) {
                                    const name = findAccountName(docData.cashBoxAccountId);
                                    setAccountId(docData.cashBoxAccountId);
                                    setAccountName(name);
                                }
                            }
                        }}
                        placeholder={intl.formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        style={{ width: '100%' }}
                        value={counterpartType}
                    >
                        {Object.entries(counterpartyTypeMap).map(([key, value]) => (
                            <Option key={key} value={key}>
                                <FormattedMessage id={value} />
                            </Option>
                        ))}
                    </Select>
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.type' />:
                    </div>
                    <Select
                        disabled
                        placeholder={intl.formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        style={{ width: '100%' }}
                        value={debit}
                    >
                        <Option value='DEBET'>
                            <FormattedMessage id='entries_tab.sign.+' />
                        </Option>
                    </Select>
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.sum' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <InputNumber
                        decimalSeparator=','
                        disabled
                        onChange={value => {}}
                        placeholder={intl.formatMessage({
                            id: 'cash-table.sum'
                        })}
                        style={{ width: '100%' }}
                        value={amount}
                    />
                </div>
            </Flex>
        </Modal>
    );
};

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile
});

export default connect(mapStateToProps)(injectIntl(AddAccRowModal));
