exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__blockStyle---2GNz5{display:flex;padding:6px;justify-content:space-around}.styles-m__optionsDangerIconStyle---3s790{font-size:14px;color:#eb8c86}.styles-m__optionsWarningIconStyle---2qMcs{font-size:14px;color:#f0a871}.styles-m__optionsSuccessIconStyle---2Ju90{font-size:14px;color:#83c468}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACC,YAAa,CACV,WAAY,CACf,4BACD,CAEA,0CACI,cAAe,CACf,aACJ,CAEA,2CACI,cAAe,CACf,aACJ,CAEA,2CACI,cAAe,CACf,aACJ","file":"styles.m.css","sourcesContent":[".blockStyle {\n\tdisplay: flex;\n    padding: 6px;\n\tjustify-content: space-around;\n}\n\n.optionsDangerIconStyle {\n    font-size: 14px;\n    color: #eb8c86\n}\n\n.optionsWarningIconStyle {\n    font-size: 14px;\n    color: #f0a871\n}\n\n.optionsSuccessIconStyle {\n    font-size: 14px;\n    color: #83c468\n}\n\n"]}]);

// Exports
exports.locals = {
	"blockStyle": "styles-m__blockStyle---2GNz5",
	"optionsDangerIconStyle": "styles-m__optionsDangerIconStyle---3s790",
	"optionsWarningIconStyle": "styles-m__optionsWarningIconStyle---2qMcs",
	"optionsSuccessIconStyle": "styles-m__optionsSuccessIconStyle---2Ju90"
};