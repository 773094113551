exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__buttonGroup---1eEQT>button{margin:0 4px}.styles-m__search---3YVlh{margin-bottom:8px}.styles-m__ogTitle---1NCIf{font-size:16px;font-weight:700}.styles-m__ogDesc---2tWJq{margin-bottom:8px;margin-top:8px;opacity:.7}.styles-m__ogImg---2bTDV{margin-bottom:4px;margin-top:4px}.styles-m__linkBlock---2s6g3{margin-bottom:12px;background-color:#f5f5f5;border:1px solid #8a8d8a;padding:4px;border-radius:8px}.styles-m__btnResetClasses---1onps{display:flex;justify-content:end;margin-bottom:8px}.styles-m__btnInterCo---10W4d{display:flex;justify-content:end;margin-bottom:14px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AACI,sCACI,YACJ,CAGJ,0BACI,iBACJ,CAEA,2BACI,cAAe,CACf,eACJ,CAEA,0BACI,iBAAkB,CAClB,cAAe,CACf,UACJ,CAEA,yBACI,iBAAkB,CAClB,cACJ,CAEA,6BACI,kBAAmB,CACnB,wBAA4B,CAC5B,wBAAyB,CACzB,WAAY,CACZ,iBACJ,CAEA,mCACI,YAAa,CACb,mBAAoB,CACpB,iBACJ,CAEA,8BACI,YAAa,CACb,mBAAoB,CACpB,kBACJ","file":"styles.m.css","sourcesContent":[".buttonGroup {\n    & > button {\n        margin: 0 4px;\n    }\n}\n\n.search {\n    margin-bottom: 8px;\n}\n\n.ogTitle {\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.ogDesc {\n    margin-bottom: 8px;\n    margin-top: 8px;\n    opacity: 0.7;\n}\n\n.ogImg {\n    margin-bottom: 4px;\n    margin-top: 4px;\n}\n\n.linkBlock {\n    margin-bottom: 12px;\n    background-color: whitesmoke;\n    border: 1px solid #8a8d8a;\n    padding: 4px;\n    border-radius: 8px; \n}\n\n.btnResetClasses {\n    display: flex;\n    justify-content: end;\n    margin-bottom: 8px;\n}\n\n.btnInterCo {\n    display: flex;\n    justify-content: end;\n    margin-bottom: 14px;\n}"]}]);

// Exports
exports.locals = {
	"buttonGroup": "styles-m__buttonGroup---1eEQT",
	"search": "styles-m__search---3YVlh",
	"ogTitle": "styles-m__ogTitle---1NCIf",
	"ogDesc": "styles-m__ogDesc---2tWJq",
	"ogImg": "styles-m__ogImg---2bTDV",
	"linkBlock": "styles-m__linkBlock---2s6g3",
	"btnResetClasses": "styles-m__btnResetClasses---1onps",
	"btnInterCo": "styles-m__btnInterCo---10W4d"
};