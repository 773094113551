const All = 'All';
const PC = 'PC';
const E_PC = 'E-PC';
const LCV = 'LCV';
const E_LCV = 'E-LCV';
const CV = 'CV';
const E_CV = 'E-CV';
const BUS = 'E-Bus';
const E_BUS = 'E-Bus';
const TRACTOR = 'Tractor';
const MOTORCYCLE = 'Motorcycle';
const E_MOTORCYCLE = 'E-Motorcycle';

const vehicleTypeMapper1 = type => {
    switch (type) {
        case All:
            return {};
        case PC:
            return {
                bodyId: [25, 26, 27, 28, 29, 30, 31, 32, 38, 39, 43, 53],
                fuelId: null
            };
        case E_PC:
            return {
                bodyId: [25, 26, 27, 28, 29, 30, 31, 32, 38, 39, 43, 53],
                fuelId: 11
            };
        case LCV:
            return {
                bodyId: [21, 34, 52, 54, 55, 56],
                fuelId: null,
                cvEngineId: 40
            };
        case E_LCV:
            return {
                bodyId: [21, 34, 52, 54, 55, 56],
                fuelId: 11,
                cvEngineId: 40
            };
        case CV:
            return {
                bodyId: [2, 3, 4, 5, 6, 7, 8, 10, 36, 37, 42, 46],
                fuelId: null
            };
        case E_CV:
            return {
                bodyId: [2, 3, 4, 5, 6, 7, 8, 10, 36, 37, 42, 46],
                fuelId: 11
            };
        case BUS:
            return {
                bodyId: [1, 33],
                fuelId: null
            };
        case E_BUS:
            return {
                bodyId: [1, 33],
                fuelId: 11
            };
        case TRACTOR:
            return {
                bodyId: 9
            };
        case MOTORCYCLE:
            return {
                bodyId: 51,
                fuelId: null
            };
        case E_MOTORCYCLE:
            return {
                bodyId: 51,
                fuelId: 11
            };

        default:
            return {
                type: []
            };
    }
};

const vehicleTypeMapper = {
    0: [PC, E_PC],
    25: [PC, E_PC],
    26: [PC, E_PC],
    27: [PC, E_PC],
    28: [PC, E_PC],
    29: [PC, E_PC],
    30: [PC, E_PC],
    31: [PC, E_PC],
    32: [PC, E_PC],
    38: [PC, E_PC],
    39: [PC, E_PC],
    40: [PC, E_PC],
    43: [PC, E_PC],
    53: [PC, E_PC],
    56: [PC, E_PC],

    21: [LCV, E_LCV],
    34: [LCV, E_LCV],
    52: [LCV, E_LCV],
    54: [LCV, E_LCV],
    55: [LCV, E_LCV],

    2: [CV, E_CV],
    3: [CV, E_CV],
    4: [CV, E_CV],
    5: [CV, E_CV],
    6: [CV, E_CV],
    7: [CV, E_CV],
    8: [CV, E_CV],
    10: [CV, E_CV],
    36: [CV, E_CV],
    37: [CV, E_CV],
    42: [CV, E_CV],
    46: [CV, E_CV],
    48: [CV, E_CV],

    1: [BUS, E_BUS],
    33: [BUS, E_BUS],

    51: [MOTORCYCLE, E_MOTORCYCLE],

    9: [TRACTOR, TRACTOR]
};

export default vehicleTypeMapper;
