import { Spin } from 'antd';
import React from 'react';
import Styles from './styles.m.css';

/**
 * Component supposed to represent data snippet, it has title and content.
 * Content will be represented under the title.
 * \
 * \
 * ***It has meadia styles so it will be adapted for mobile devises as well as for deskop.***
 *
 * @property {*} className - custom className for component's main container, used to override default styles
 * @property { React.Node | String } [label] - title of content
 * @property { React.Node | String } children - data that has to be shown
 * @property { React.Node | String } [ controls ] - custom component where controls are located
 * @property { Boolean } [ loading ] - indicates that data is loading
 * @property { React.Node | String } [ nullText = "–" ] - will be displayed if no data provided
 */
export default class DataItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, label, className, controls, loading, nullText = '–' } = this.props;

        return loading ? (
            <div className={[Styles.container, className].join(' ')}>
                <div className={Styles.header}>
                    <Spin size='small' />
                </div>
            </div>
        ) : (
            <div className={[Styles.container, className].join(' ')}>
                <div className={Styles.header}>
                    <div className={Styles.label}>{label}</div>
                    <div className={Styles.controls}>{controls}</div>
                </div>

                <div className={Styles.content}>
                    {children || children === 0 ? children : nullText}
                </div>
            </div>
        );
    }
}
