import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const PromoCodeToast = ({ promoCodeDiscount }) => {
    const iconStyle = {
        color: promoCodeDiscount === 'error' ? 'var(--warning)' : 'var(--secondary)',
        fontSize: 32
    };

    return (
        <Toast promocodediscount={promoCodeDiscount}>
            {promoCodeDiscount === 'error' ? (
                <React.Fragment>
                    <FrownOutlined style={iconStyle} />
                    <Text>
                        <FormattedMessage id='subscription.promo_code_invalid' />
                        &nbsp;
                        <br />
                        <FormattedMessage id='subscription.please_try_again' />
                    </Text>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <SmileOutlined style={iconStyle} />
                    <Text>
                        <FormattedMessage id='subscription.promo_code_verified' />
                        &nbsp;{promoCodeDiscount} %
                        <FormattedMessage id='subscription.promo_code_discount' />
                    </Text>
                </React.Fragment>
            )}
        </Toast>
    );
};

const Toast = styled.div`
    margin-bottom: 8px;
    padding: 12px;
    border: ${props =>
        props.promocodediscount === 'error'
            ? '1px solid var(--warning)'
            : '1px solid var(--secondary)'};
    border-radius: 2px;
    background-color: ${props =>
        props.promocodediscount === 'error'
            ? 'rgba(var(--warningRGB), 0.2)'
            : 'rgba(var(--secondaryRGB), 0.1)'};
    color: ${props =>
        props.promocodediscount === 'error' ? 'var(--warning)' : 'var(--secondary)'};
    text-align: center;
`;

const Text = styled.div`
    white-space: nowrap;
`;
