import { Modal, Table } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

@injectIntl
export default class ProductsToAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            selectedRowKeys: []
        };

        this.columns = () => [
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brandName',
                dataIndex: 'brandName'
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'detailCode',
                dataIndex: 'detailCode'
            }
        ];
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.products && this.props.products) {
            this.setState({
                dataSource: this.props.products
            });
        }
    }

    handleCancel = () => {
        this.setState({ dataSource: [], selectedRowKeys: [] });
        this.props.hideModal();
    };

    handleOk = () => {
        this.props.addPartsToOrder(this.state.selectedRowKeys);
        this.handleCancel();
    };

    render() {
        const { products } = this.props;
        const { dataSource, selectedRowKeys } = this.state;

        return (
            <div>
                <Modal
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={!!products}
                    title={<FormattedMessage id='this_product_has_already_been_added_to_n_a_add_again' />}
                >
                    <Table
                        bordered
                        columns={this.columns()}
                        dataSource={dataSource}
                        pagination={false}
                        rowKey='id'
                        rowSelection={{
                            selectedRowKeys,
                            onChange: selectedRowKeys => {
                                this.setState({
                                    selectedRowKeys
                                });
                            }
                        }}
                        size='small'
                    />
                </Modal>
            </div>
        );
    }
}
