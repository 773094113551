/**
 * Constants
 * */
export const moduleName = 'salaryReport';
const prefix = `cpb/${moduleName}`;

export const ON_CHANGE_SALARY_REPORT_FORM = `${prefix}/ON_CHANGE_SALARY_REPORT_FORM`;

export const FETCH_EMPLOYEES = `${prefix}/FETCH_EMPLOYEES`;
export const FETCH_EMPLOYEES_SUCCESS = `${prefix}/FETCH_EMPLOYEES_SUCCESS`;

/** Available types of report detailing */
export const salaryReportValues = {
    withoutDetailing: 'without_detailing',
    withOrders: 'with_orders',
    withLabors: 'with_labors',
    withRows: 'with_rows'
};

/**
 * Reducer
 * */

const ReducerState = {
    fields: {},
    employees: []
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case ON_CHANGE_SALARY_REPORT_FORM:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...payload
                }
            };

        case FETCH_EMPLOYEES_SUCCESS:
            const { employees } = payload;

            return {
                ...state,
                employees
            };

        default:
            return state;
    }
}

export const selectEmployees = state => state.forms[moduleName].employees;

export const onChangeSalaryReportForm = update => ({
    type: ON_CHANGE_SALARY_REPORT_FORM,
    payload: update
});

export const fetchEmployees = () => ({
    type: FETCH_EMPLOYEES
});

export const fetchEmployeesSuccess = ({ employees }) => ({
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: { employees }
});
