import _ from 'lodash';
import numeral from 'numeral';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
    CartesianGrid,
    Legend,
    Line as RechartLine,
    // Label,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';

@injectIntl
export default class Line extends Component {
    static defaultProps = {
        height: 300
    };

    render() {
        const { data, config, width, height, intlCtx } = this.props;
        const { formatMessage } = this.props.intl;

        return data ? (
            <ResponsiveContainer height={height} width={width}>
                <LineChart
                    data={data}
                    height={height}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    width={width}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='id' />
                    <YAxis tickFormatter={value => numeral(value).format('0,0[]00')} />
                    <Tooltip formatter={value => numeral(value).format('0,0[]00')} />
                    <Legend content={this._renderLegend} iconType='rect' />
                    {_(config.dataKeys)
                        .map(dataKey => (
                            <RechartLine
                                key={dataKey.name}
                                activeDot={{ r: 10 }}
                                dataKey={dataKey.name}
                                fill={dataKey.color}
                                name={formatMessage({
                                    id: `${intlCtx}.${_.snakeCase(dataKey.name)}`
                                })}
                                stroke={dataKey.color}
                                strokeWidth={4}
                                type='monotone' // eslint-disable-line
                            />
                        ))
                        .value()}
                </LineChart>
            </ResponsiveContainer>
        ) : null;
    }
}
