exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__content---2Lcyv{display:flex;flex-direction:column;gap:14px}.styles-m__table---2XyGT{flex:1 1 auto}.styles-m__actionsContainer---3KlSp{display:flex;gap:16px;align-items:center}.styles-m__filterSelect---3Ykbo{display:flex;flex:1 1 auto;flex-direction:row;gap:16px}.styles-m__deleteColumn---2v3id{width:46px}.styles-m__summaryRow---MRVUA>*{font-weight:600}.styles-m__diagnosticsFilter---23jl3{width:200px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,2BACI,YAAa,CACb,qBAAsB,CACtB,QACF,CAEF,yBACE,aACF,CAEA,oCACE,YAAa,CACb,QAAS,CACT,kBACF,CAEA,gCACE,YAAa,CACb,aAAc,CACd,kBAAmB,CACnB,QACF,CAEA,gCACE,UACF,CAEA,gCACE,eACF,CAEA,qCACE,WACF","file":"styles.m.css","sourcesContent":[".content {\n    display: flex;\n    flex-direction: column;\n    gap: 14px;\n  }\n  \n.table {\n  flex: 1 1 auto;\n}\n\n.actionsContainer {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n}\n\n.filterSelect {\n  display: flex;\n  flex: 1 1 auto;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.deleteColumn {\n  width: 46px;\n}\n\n.summaryRow > * {\n  font-weight: 600;\n}\n\n.diagnosticsFilter {\n  width: 200px;\n}"]}]);

// Exports
exports.locals = {
	"content": "styles-m__content---2Lcyv",
	"table": "styles-m__table---2XyGT",
	"actionsContainer": "styles-m__actionsContainer---3KlSp",
	"filterSelect": "styles-m__filterSelect---3Ykbo",
	"deleteColumn": "styles-m__deleteColumn---2v3id",
	"summaryRow": "styles-m__summaryRow---MRVUA",
	"diagnosticsFilter": "styles-m__diagnosticsFilter---23jl3"
};