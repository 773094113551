import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Checkbox, Col, Table } from 'antd';
import { Catcher } from 'commons';
import dayjs from 'dayjs';
import { DecoratedCheckbox, DecoratedTimePicker } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

@injectIntl
export default class ArrayScheduleInput extends Component {
    constructor(props) {
        super(props);

        const { initialSchedule } = props;
        this.uuid = _.isArray(initialSchedule) ? initialSchedule.length : 0;
        const keys = _.isArray(initialSchedule) ? _.keys(initialSchedule) : [];

        this.uuid += 1;
        this.state = { keys: [...keys, this.uuid] };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.initialSchedule, prevProps.initialSchedule)) {
            this.props.form.resetFields();

            // this.props.initialSchedule.forEach((schedule, key) => {
            //     ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
            //         this.props.form.setFieldsValue({
            //             [`schedule[${key}][${day}]`]: schedule.day
            //         });
            //     });
            // });

            const { initialSchedule } = this.props;
            this.uuid = _.isArray(initialSchedule) ? initialSchedule.length : 0;
            const keys = _.isArray(initialSchedule) ? _.keys(initialSchedule) : [];

            this.uuid += 1;
            this.setState({ keys: [...keys, this.uuid] });
        }
    }

    getScheduleData(key, callback) {
        this.props.form.validateFields([`schedule[${key}]`], err => {
            if (err) {
                return;
            }
            const schedule = this.props.form.getFieldValue(`schedule[${key}]`);
            const scheduleWithParsedHours = _.mapValues(schedule, value =>
                dayjs.isDayjs(value) ? value.format('HH:mm') : value);

            callback && callback(scheduleWithParsedHours);
        });
    }

    add = () => {
        const { keys } = this.state;

        this.uuid += 1;
        this.setState({ keys: [...keys, this.uuid] });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { initialSchedule, forbiddenUpdate, loading } = this.props;
        const { formatMessage } = this.props.intl;

        const getHourTitle = (key, title) => {
            const date = _.get(initialSchedule, [key, title]);

            return date ? dayjs(date, 'HH:mm') : date;
        };

        const { keys } = this.state;

        const days = [
            ...['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => ({
                title: <FormattedMessage id={day.substring(0, 3)} />,
                width: '7%',
                render: (text, record) => (
                    <Col span={12}>
                        <DecoratedCheckbox
                            field={`schedule[${record.key}][${day}]`}
                            getFieldDecorator={getFieldDecorator}
                            hidden
                            initialValue={_.get(initialSchedule, [record.key, day])}
                        />
                        <Checkbox
                            checked={
                                typeof this.props.form.getFieldValue(`schedule[${record.key}][${day}]`) === 'boolean'
                                    ? this.props.form.getFieldValue(`schedule[${record.key}][${day}]`)
                                    : _.get(initialSchedule, [record.key, day])
                            }
                            onChange={() => {
                                this.props.form.setFieldsValue({
                                    [`schedule[${record.key}][${day}]`]: !this.props.form.getFieldValue(
                                        `schedule[${record.key}][${day}]`
                                    )
                                });
                            }}
                        />
                    </Col>
                )
            }))
        ];

        const workingHours = [
            ...['beginWorkingHours', 'endWorkingHours'].map(name => ({
                title: <FormattedMessage id={name} />,
                width: '12%',
                render: (text, record) => (
                    <DecoratedTimePicker
                        className={Styles.scheduleFormItem}
                        field={`schedule[${record.key}][${name}]`}
                        formatMessage={formatMessage}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={getHourTitle(record.key, name)}
                        minuteStep={30}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />
                )
            }))
        ];

        const breakHours = [
            ...['beginBreakHours', 'endBreakHours'].map(name => ({
                title: <FormattedMessage id={name} />,
                width: '10%',
                render: (text, record) => (
                    <DecoratedTimePicker
                        field={`schedule[${record.key}][${name}]`}
                        fields={this.props.fields}
                        formatMessage={formatMessage}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={getHourTitle(record.key, name)}
                        minuteStep={30}
                    />
                )
            }))
        ];

        const actions = {
            title: '',
            width: '10%',
            render: (text, { key }) =>
                !forbiddenUpdate && (
                    <div>
                        <SaveOutlined
                            className={Styles.scheduleIcon}
                            onClick={() => {
                                const callback = entity => {
                                    const initialEntity = _.get(initialSchedule, [key]);
                                    if (initialEntity) {
                                        const { id } = initialEntity;
                                        this.props.updateSchedule(id, entity);
                                    } else {
                                        this.props.createSchedule(entity);
                                    }
                                    this.props.resetFields();
                                };
                                this.getScheduleData(key, callback);
                            }}
                        />{' '}
                        {_.get(initialSchedule, [key]) && (
                            <DeleteOutlined
                                className={Styles.scheduleIcon}
                                onClick={() => {
                                    const id = _.get(initialSchedule, [key, 'id']);
                                    if (id) {
                                        this.props.deleteSchedule(id);
                                    }
                                    this.props.resetFields();
                                }}
                            />
                        )}
                    </div>
                )
        };

        const columns = () => [...days, ...workingHours, ...breakHours, actions];

        return (
            <Catcher>
                <Table
                    bordered
                    columns={columns()}
                    dataSource={keys.map(key => ({ key }))}
                    loading={loading}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    rowClassName={({ key }) => {
                        const wasEdited = _.get(this.props.fields, ['schedule', key]);
                        const exists = _.get(initialSchedule, [key]);

                        if (!exists) {
                            return Styles.newScheduleRow;
                        }
                        if (wasEdited) {
                            return Styles.editedScheduleRow;
                        }
                    }}
                    scroll={{ x: 1000 }}
                    size='small'
                />
            </Catcher>
        );
    }
}
