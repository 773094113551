/* eslint-disable max-classes-per-file */
import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, InputNumber, Menu, Modal, Select, Switch, Table } from 'antd';
import { Barcode } from 'components';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

@withRouter
@injectIntl
export default class WMSAddressSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addressSettings: [],
            setAllModalVisible: false,
            selectedRows: [],
            page: 1,
            totalCount: 0,
            pageSize: 25
        };

        this.handleSearch = _.debounce(() => {
            this._fetchCellSettings();
        }, 1000);

        const { isDsabledCRUD } = this.props;

        this.columns = () => [
            {
                title: <FormattedMessage id='wms.address' />,
                key: 'address',
                dataIndex: 'address'
            },
            {
                title: () => (
                    <div>
                        <FormattedMessage id='wms.active' />
                        {this.state.selectedRows.length ? (
                            <div>
                                <Switch
                                    disabled={isDsabledCRUD}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.enabled = value;
                                            elem.changed = true;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ),
                key: 'enabled',
                dataIndex: 'enabled',
                render: (data, row) => {
                    return (
                        <Switch
                            checked={data}
                            disabled={isDsabledCRUD}
                            onChange={value => {
                                row.enabled = value;
                                row.changed = true;
                                this.setState({});
                            }}
                        />
                    );
                }
            },
            {
                title: () => (
                    <div>
                        <FormattedMessage id='wms.width' />
                        {this.state.selectedRows.length ? (
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={isDsabledCRUD}
                                    min={0}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.width = value;
                                            elem.changed = true;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ),
                key: 'width',
                dataIndex: 'width',
                render: (data, row) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={isDsabledCRUD}
                            min={0}
                            onChange={value => {
                                row.width = value;
                                row.changed = true;
                                this.setState({});
                            }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: () => (
                    <div>
                        <FormattedMessage id='wms.height' />
                        {this.state.selectedRows.length ? (
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={isDsabledCRUD}
                                    min={0}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.height = value;
                                            elem.changed = true;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ),
                key: 'height',
                dataIndex: 'height',
                render: (data, row) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={isDsabledCRUD}
                            min={0}
                            onChange={value => {
                                row.height = value;
                                row.changed = true;
                                this.setState({});
                            }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: () => (
                    <div>
                        <FormattedMessage id='wms.depth' />
                        {this.state.selectedRows.length ? (
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={isDsabledCRUD}
                                    min={0}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.depth = value;
                                            elem.changed = true;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ),
                key: 'depth',
                dataIndex: 'depth',
                render: (data, row) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={isDsabledCRUD}
                            min={0}
                            onChange={value => {
                                row.depth = value;
                                row.changed = true;
                                this.setState({});
                            }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: () => (
                    <div>
                        <FormattedMessage id='wms.volume' />
                        {this.state.selectedRows.length ? (
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={isDsabledCRUD}
                                    min={0}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.volume = value;
                                            elem.changed = true;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ),
                key: 'volume',
                dataIndex: 'volume',
                render: (data, row) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={isDsabledCRUD}
                            min={0}
                            onChange={value => {
                                row.volume = value;
                                row.changed = true;
                                this.setState({});
                            }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: () => (
                    <div>
                        <FormattedMessage id='wms.weight' />
                        {this.state.selectedRows.length ? (
                            <div>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={isDsabledCRUD}
                                    min={0}
                                    onChange={value => {
                                        this.state.selectedRows.map(elem => {
                                            elem.weight = value;
                                            elem.changed = true;
                                        });
                                        this.setState({});
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ),
                key: 'weight',
                dataIndex: 'weight',
                render: (data, row) => {
                    return (
                        <InputNumber
                            decimalSeparator=','
                            disabled={isDsabledCRUD}
                            min={0}
                            onChange={value => {
                                row.weight = value;
                                row.changed = true;
                                this.setState({});
                            }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: <FormattedMessage id='navigation.barcode' />,
                key: 'barcode',
                dataIndex: 'barcode',
                render: (data, row) => {
                    return (
                        <Barcode
                            enableScanIcon
                            iconStyle={{
                                margin: '0 8px 0 0',
                                fornSize: 18,
                                verticalAlign: 'sub'
                            }}
                            onConfirm={(code, pref, fullCode) => {
                                row.barcode = fullCode;
                                this.setState({});
                            }}
                            prefix='WMS'
                            referenceId={row.address}
                            table='CELLS'
                            value={data}
                        />
                    );
                }
            }
        ];
    }

    _saveCellsSettings = async () => {
        const { warehouseId, fetchCells } = this.props;
        const { addressSettings } = this.state;
        const payload = addressSettings.filter(elem => elem.changed);
        payload.map(elem => {
            delete elem.changed;
            delete elem.businessId;
            delete elem.barcode;
        });
        if (payload.length) {
            await fetchAPI('PUT', 'wms/cell_options', { warehouseId }, payload);
        }
        fetchCells();
    };

    _fetchCellSettings = async () => {
        const { page, filterQuery, pageSize } = this.state;
        const { warehouseId } = this.props;
        const cells = await fetchAPI('GET', 'wms/cells', {
            warehouseId,
            page,
            filterQuery,
            count: 10,
            pageSize
        });
        this.setState({
            addressSettings: cells.list,
            totalCount: cells.stats.count
        });
    };

    componentDidMount() {
        this._fetchCellSettings();
        this.setState({
            addressSettings: this.props.cells
        });
    }

    componentDidUpdate(prevProps) {}

    render() {
        const { warehouseId, fetchCells, isDsabledCRUD } = this.props;
        const { addressSettings, setAllModalVisible, filterQuery, page, totalCount, pageSize } = this.state;

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRows
                });
            },
            getCheckboxProps: record => ({
                name: record.name
            })
        };

        const pagination = {
            pageSize,
            total: Math.ceil(totalCount / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this._fetchCellSettings();
            }
        };
        const menu = (
            <Menu>
                <Menu.Item disabled={isDsabledCRUD}>
                    <div>
                        <FormattedMessage id='wms.import' />
                    </div>
                </Menu.Item>
                <Menu.Item disabled={isDsabledCRUD}>
                    <div onClick={() => this.setState({ setAllModalVisible: true })}>
                        <FormattedMessage id='wms.set_all' />
                    </div>
                </Menu.Item>
                <Menu.Item disabled={isDsabledCRUD}>
                    <div
                        onClick={() => {
                            addressSettings.map(({ height, width, depth }, key) => {
                                addressSettings[key].changed = true;
                                addressSettings[key].volume = height * width * depth;
                            });
                            this._saveCellsSettings();
                        }}
                    >
                        <FormattedMessage id='wms.calculete_volume' />
                    </div>
                </Menu.Item>
                <Menu.Item disabled={isDsabledCRUD}>
                    <div
                        onClick={() => {
                            addressSettings.map((elem, key) => {
                                elem.changed = true;
                                elem.height = null;
                                elem.width = null;
                                elem.depth = null;
                                elem.weight = null;
                                elem.volume = null;
                            });
                            this._saveCellsSettings();
                        }}
                    >
                        <FormattedMessage id='wms.delete_all' />
                    </div>
                </Menu.Item>
            </Menu>
        );

        return (
            <div>
                <div className={Styles.tabTitle}>
                    <FormattedMessage id='wms.address_cell_settings' />
                    <Dropdown disabled={isDsabledCRUD} overlay={menu}>
                        <MenuOutlined className={Styles.menuIcon} />
                    </Dropdown>
                </div>
                <Input.Search
                    allowClear
                    onChange={({ target }) => {
                        this.setState({
                            filterQuery: target.value,
                            page: 1
                        });
                        this.handleSearch();
                    }}
                    placeholder={this.props.intl.formatMessage({ id: 'barcode.search' })}
                    style={{
                        marginBottom: 8
                    }}
                    value={filterQuery}
                />
                <Table
                    bordered
                    columns={this.columns()}
                    dataSource={addressSettings}
                    pagination={pagination}
                    rowKey='address'
                    rowSelection={rowSelection}
                    size='small'
                />
                <div className={Styles.tabFooter}>
                    <Button disabled={isDsabledCRUD} onClick={this._saveCellsSettings} type='primary'>
                        <FormattedMessage id='save' />
                    </Button>
                </div>
                <SetAllModal
                    confirmAction={async (width, height, depth, volume, weight) => {
                        addressSettings.map((elem, key) => {
                            elem.changed = true;
                            elem.height = null;
                            elem.width = null;
                            elem.depth = null;
                            elem.weight = null;
                            elem.volume = null;
                        });
                        await this._saveCellsSettings();
                        await fetchAPI(
                            'PUT',
                            'wms/cell_options/all',
                            { warehouseId },
                            { width, height, depth, volume, weight }
                        );
                        await fetchCells();
                    }}
                    hideModal={() => {
                        this.setState({ setAllModalVisible: false });
                    }}
                    visible={setAllModalVisible}
                />
            </div>
        );
    }
}

@injectIntl
class SetAllModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { visible, hideModal, confirmAction } = this.props;
        const { width, height, depth, volume, weight } = this.state;

        return (
            <Modal
                destroyOnClose
                maskClosable={false}
                onCancel={hideModal}
                onOk={() => confirmAction(width, height, depth, volume, weight)}
                title={<FormattedMessage id='wms.set_all' />}
                visible={visible}
                width='fit-content'
            >
                <div className={Styles.setAllModalRow}>
                    <FormattedMessage id='wms.width' />
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={width => {
                            this.setState({ width });
                        }}
                    />
                </div>
                <div className={Styles.setAllModalRow}>
                    <FormattedMessage id='wms.height' />
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={height => {
                            this.setState({ height });
                        }}
                    />
                </div>
                <div className={Styles.setAllModalRow}>
                    <FormattedMessage id='wms.depth' />
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={depth => {
                            this.setState({ depth });
                        }}
                    />
                </div>
                <div className={Styles.setAllModalRow}>
                    <FormattedMessage id='wms.volume' />
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={volume => {
                            this.setState({ volume });
                        }}
                    />
                </div>
                <div className={Styles.setAllModalRow}>
                    <FormattedMessage id='wms.weight' />
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={weight => {
                            this.setState({ weight });
                        }}
                    />
                </div>
            </Modal>
        );
    }
}
