import { Table } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { grants, isGrantAccessed } from 'utils/grants';

const mapStateToProps = state => ({
    user: state.auth
});

const RelatedEntriesTable = ({ user, dataSource, handlePaginationChange, pagination, totalEntries }) => {
    const columns = useMemo(
        () => [
            {
                title: <FormattedMessage id='entry_table.operation_id' />,
                dataIndex: 'operationId',
                key: 'operationId'
            },
            {
                title: <FormattedMessage id='entry_table.operation_date' />,
                dataIndex: 'date',
                key: 'date',
                render: data => (data ? dayjs(data).format('DD.MM.YYYY') : <FormattedMessage id='long_dash' />)
            },
            {
                title: <FormattedMessage id='entry_table.closing_date' />,
                dataIndex: 'closingDate',
                key: 'closingDate',
                render: data => dayjs(data).format('DD.MM.YYYY')
            },
            {
                title: <FormattedMessage id='entry_table.requisite_id' />,
                dataIndex: 'requisiteName',
                key: 'requisiteName'
            },
            {
                title: <FormattedMessage id='entry_table.account_id' />,
                dataIndex: 'accountId',
                key: 'accountId'
            },
            {
                title: <FormattedMessage id='entry_table.analytics_id' />,
                dataIndex: 'analyticsId',
                key: 'analyticsId'
            },
            {
                title: <FormattedMessage id='entry_table.line_operation_id' />,
                dataIndex: 'lineOperationId',
                key: 'lineOperationId'
            },
            {
                title: <FormattedMessage id='entry_table.operation_side' />,
                dataIndex: 'operationSide',
                key: 'operationSide'
            },
            {
                title: <FormattedMessage id='entry_table.operation_type' />,
                dataIndex: 'operationType',
                key: 'operationType'
            },
            {
                title: <FormattedMessage id='entry_table.amount' />,
                dataIndex: 'amount',
                key: 'amount'
            },
            {
                title: <FormattedMessage id='entry_table.doc_number' />,
                key: 'docId',
                render: ({ docId, docType }) => {
                    switch (docType) {
                        case 'MRD':
                            return (
                                <Link to={`${book.order}/${docId}`}>{`${docType}-${user.businessId}-${docId}`}</Link>
                            );
                        case 'RD':
                            return (
                                <Link to={`${book.order}/${docId}`}>{`${docType}-${user.businessId}-${docId}`}</Link>
                            );
                        case 'SAL':
                            return (
                                <Link
                                    to={`${book.salariesPage}/${docId}`}
                                >{`${docType}-${user.businessId}-${docId}`}</Link>
                            );
                        case 'CSH':
                            return (
                                <Link
                                    to={{
                                        pathname: book.cashFlowPage,
                                        state: { cashOrderId: docId }
                                    }}
                                >{`${docType}-${user.businessId}-${docId}`}</Link>
                            );
                        default:
                            return isGrantAccessed(user, grants.WAREHOUSE_DOCUMENT) ? (
                                <Link
                                    to={`${book.storageDocument}/${docId}`}
                                >{`${docType}-${user.businessId}-${docId}`}</Link>
                            ) : (
                                <span>{`${docType}-${user.businessId}-${docId}`}</span>
                            );
                    }
                }
            },
            {
                title: <FormattedMessage id='entry_table.responsible_id' />,
                render: ({ responsibleId, responsibleName }) => responsibleName || responsibleId,
                key: 'responsible'
            },
            {
                title: <FormattedMessage id='entry_table.comment' />,
                dataIndex: 'comment',
                key: 'comment',
                width: 300
            }
        ],
        [user.businessId]
    );

    return (
        <Table
            bordered
            columns={columns}
            dataSource={dataSource}
            pagination={{
                pageSizeOptions: [10, 50, 100],
                showSizeChanger: true,
                onChange: (page, pageSize) => handlePaginationChange({ pageSize, page }),
                pageSize: pagination.pageSize,
                current: pagination.page,
                total: totalEntries
            }}
            rowKey='id'
            scroll={{ x: '100vw' }}
            size='small'
        />
    );
};

export default connect(mapStateToProps)(RelatedEntriesTable);
