
/** Constants * */

export const moduleName = 'storeDocServiceForm';
const prefix = `cpb/${moduleName}`;

export const FETCH_STORE_DOC_SERVICES = `${prefix}/FETCH_STORE_DOC_SERVICES`;
export const FETCH_STORE_DOC_SERVICES_SUCCESS = `${prefix}/FETCH_STORE_DOC_SERVICES_SUCCESS`;

export const DELETE_STORE_DOC_SERVICES = `${prefix}/DELETE_STORE_DOC_SERVICES`;

export const SET_STORE_DOC_FILTERS = `${prefix}/SET_STORE_DOC_FILTERS`;

/** Reducer * */
const ReducerState = {
    storeDocServices: [],
    stats: {},
    filters: {
        storeDocId: undefined,
        page: 1
    },
    fetching: false
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_STORE_DOC_SERVICES_SUCCESS:
            const { storeDocServices, stats: storeDocServicesStats } = payload;

            return {
                ...state,
                storeDocServices,
                stats: storeDocServicesStats
            };

        case SET_STORE_DOC_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...payload }
            };

        default:
            return state;
    }
}

/** Selectors * */
export const stateSelector = state => state[moduleName];
export const selectStoreDocServices = state => state[moduleName].storeDocServices;
export const selectFilters = state => state[moduleName].filters;
export const selectStats = state => state[moduleName].stats;

/* Action Creators */
export const fetchStoreDocServices = () => ({
    type: FETCH_STORE_DOC_SERVICES
});

export const fetchStoreDocServicesSuccess = ({ storeDocServices, stats }) => ({
    type: FETCH_STORE_DOC_SERVICES_SUCCESS,
    payload: { storeDocServices, stats }
});

export const setStoreDocServicesFilters = filters => {
    return function (dispatch) {
        dispatch({
            type: SET_STORE_DOC_FILTERS,
            payload: filters
        });

        return dispatch(fetchStoreDocServices());
    };
};

export const deleteStoreDocService = storeDocServiceId => {
    return function (dispatch) {
        dispatch({
            type: DELETE_STORE_DOC_SERVICES,
            payload: storeDocServiceId
        });

        return dispatch(fetchStoreDocServices());
    };
};
