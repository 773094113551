import { Table } from 'antd';
import _ from 'lodash';
import { CASH_ORDER_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import {
    selectCashOrderType,
    setCashOrderSum,
    setSelectedClientId
} from 'modals/RefactoredCashOrderModal/redux/duck';
import {
    fetchOrders,
    selectFetchingOrders,
    selectOrders,
    selectOrdersFilters,
    selectOrdersStats,
    selectSelectedOrderId,
    setOrdersFilters,
    setSelectedOrderId
} from 'modals/RefactoredCashOrderModal/redux/orders/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    isMobile: state.ui.views.isMobile,
    orders: selectOrders(state),
    ordersStats: selectOrdersStats(state),
    ordersFilters: selectOrdersFilters(state),
    fetchingOrders: selectFetchingOrders(state),
    selectedOrderId: selectSelectedOrderId(state),
    cashOrderType: selectCashOrderType(state)
});

const mapDispatchToProps = {
    fetchOrders,
    setOrdersFilters,
    setSelectedOrderId,
    setSelectedClientId,
    setCashOrderSum
};

/**
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrdersTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchOrders();
    }

    render() {
        const {
            isMobile,
            orders,
            fetchingOrders,

            ordersStats,
            ordersFilters,
            selectedOrderId,
            setOrdersFilters,
            setSelectedOrderId,
            setSelectedClientId,
            setCashOrderSum,
            cashOrderType,

            intl: { formatMessage }
        } = this.props;

        const pagination = {
            pageSize: ordersFilters.pageSize,
            size: 'large',
            total: Math.ceil(ordersStats.countOrders / ordersFilters.page) * ordersFilters.page,
            current: ordersFilters.page,
            onChange: (page, pageSize) => {
                setOrdersFilters({ page, pageSize });
            }
        };

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig()}
                    dataSource={orders}
                    loading={fetchingOrders}
                    onRow={record => ({
                        onClick: () => {
                            setSelectedClientId(record.clientId);
                            setSelectedOrderId(record.id);

                            if (cashOrderType === CASH_ORDER_TYPES.ADJUSTMENT_EXPENSE) {
                                setCashOrderSum(
                                    _.get(record, 'totalSumWithTax', 0) -
                                        _.get(record, 'remainingSum', 0)
                                );
                            } else {
                                const tax = _.get(record, 'isTaxPayer')
                                    ? _.get(record, 'tax', 0)
                                    : 0;
                                setCashOrderSum(_.get(record, 'remainingSum', 0) + tax);
                            }
                        }
                    })}
                    pagination={pagination}
                    rowClassName={record =>
                        record.id == selectedOrderId ? Styles.selectedRow : undefined
                    }
                    rowKey={() => v4()}
                    scroll={{ x: isMobile ? '100vw' : '40vw', y: '25vh' }}
                />
            </div>
        );
    }
}
