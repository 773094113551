import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { GridCard } from '../GridCard';

export const GridCardSkeleton = ({ active, title, avatar, loading, paragraph, className, css }) => {
    return (
        <GridCard>
            <StyledSkeleton
                active={active}
                avatar={avatar}
                className={className}
                css={css}
                loading={loading}
                paragraph={paragraph}
                title={title}
            />
        </GridCard>
    );
};

const StyledSkeleton = styled(Skeleton)`
    ${props => props.css}
`;
