import { Button, Form, Input, Select } from 'antd';
import { onChangePackageForm } from 'core/forms/addPackageForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getGroupsLabels, getPermissionsLabels, groupedPermissions } from 'utils';

const { OptGroup } = Select;
const { Option } = Select;

const mapDispatchToProps = {
    onChangePackageForm
};

@injectIntl
@connect(null, mapDispatchToProps)
export class AddPackageForm extends Component {
    _submit = values => {
        const { createPackage } = this.props;
        createPackage(values);
    };

    render() {
        const { roles = [] } = this.props;
        const groupsLabels = getGroupsLabels(this.props.intl);
        const permissionsLabels = getPermissionsLabels(this.props.intl);

        return (
            <Form layout='vertical' name='addPackageForm' onFinish={this._submit}>
                <Form.Item
                    label={<FormattedMessage id='add-package-form.name_field' />}
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='add-package-form.grants_field' />}
                    name='grants'
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                >
                    <Select getPopupContainer={trigger => trigger.parentNode} mode='multiple'>
                        {_.toPairs(groupedPermissions).map(([name, value]) => {
                            return (
                                <OptGroup key={groupsLabels[name]} label={groupsLabels[name]}>
                                    {value.map(value => (
                                        <Option key={value} value={value}>
                                            {permissionsLabels[value]}
                                        </Option>
                                    ))}
                                </OptGroup>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='add-package-form.roles_field' />}
                    name='roles'
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                >
                    <Select getPopupContainer={trigger => trigger.parentNode} mode='multiple'>
                        {roles.map(({ name, roleId }) => (
                            <Option key={roleId} value={roleId}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' style={{ width: '100%' }} type='primary'>
                        <FormattedMessage id='add-package-form.create' />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
