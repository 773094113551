import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    CREATE_INVITE,
    FETCH_CLIENTS,
    SET_UNIVERSAL_FILTERS,
    fetchClients,
    fetchClientsSuccess,
    selectFilter
} from './duck';

function mergeFilters(universalFilters) {
    const modelsTransformQuery =
        universalFilters.models && universalFilters.models.length
            ? {
                  models: _(universalFilters.models)
                      .map(model => model.split(','))
                      .flatten()
                      .value()
              }
            : {};

    const [startDate, endDate] = universalFilters.beginDate || [];
    const [createStartDate, createEndDate] = universalFilters.createDate || [];

    const momentFields = _({
        startDate,
        endDate,
        createEndDate,
        createStartDate
    })
        .pickBy(dayjs.isDayjs)
        .mapValues(momentDate => momentDate.format('YYYY-MM-DD'))
        .value();

    return _.omit(
        {
            ...universalFilters,
            ...modelsTransformQuery,
            ...momentFields
        },
        ['beginDate', 'createDate']
    );
}

export function* fetchClientsSaga() {
    while (true) {
        try {
            yield take(FETCH_CLIENTS);
            yield nprogress.start();

            const { filter, sort, universalFilter } = yield select(selectFilter);
            const data = yield call(
                fetchAPI,
                'GET',
                'clients',
                {
                    filters: { ...filter, ...mergeFilters(universalFilter) },
                    sort
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchClientsSuccess(data));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* inviteClients({ payload: invite }) {
    try {
        yield nprogress.start();
        yield call(fetchAPI, 'POST', 'orders', null, [invite]);
    } finally {
        yield nprogress.done();
        yield put(fetchClients());
    }
}

export function* setUniversalFilter() {
    while (true) {
        yield take(SET_UNIVERSAL_FILTERS);
        yield put(fetchClients());
    }
}

export function* saga() {
    /* eslint-disable array-element-newline */
    yield all([call(fetchClientsSaga), call(setUniversalFilter), takeEvery(CREATE_INVITE, inviteClients)]);
    /* eslint-enable array-element-newline */
}
