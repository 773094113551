import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Table } from 'antd';
import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI, isForbidden, permissions } from 'utils';

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@injectIntl
@withRouter
@connect(mapStateToProps, void 0)
class DiagnosticPatternsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            currentKey: null,
            diagnosticParts: [],
            masterDiagnosticParts: [],
            filterPlan: null,
            filterGroup: null,
            filterName: null,
            filterCode: null,
            currentPage: 1
        };
        this.columns = [
            {
                title: '#',
                key: 'key',
                width: '5%',
                render: (_, row, index) => index + 1
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='diagnostic-page.plan' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterPlan: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'diagnostic-page.plan'
                                })}
                                style={{ minWidth: '100px' }}
                                value={this.state.filterPlan}
                            />
                        </div>
                    );
                },
                dataIndex: 'diagnosticTemplateTitle',
                key: 'diagnosticTemplateTitle',
                width: '25%',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <Input
                            ref={input => {
                                this.planInput = input;
                            }}
                            disabled={isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)}
                            onChange={event => {
                                this.state.diagnosticParts[key].diagnosticTemplateTitle = event.target.value;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'diagnostic-page.plan'
                            })}
                            style={{ minWidth: '100px', color: 'var(--text)' }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='diagnostic-page.group' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterGroup: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'diagnostic-page.group'
                                })}
                                style={{ minWidth: '100px' }}
                                value={this.state.filterGroup}
                            />
                        </div>
                    );
                },
                dataIndex: 'groupTitle',
                key: 'groupTitle',
                width: '25%',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <Input
                            disabled={isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)}
                            onChange={event => {
                                this.state.diagnosticParts[key].groupTitle = event.target.value;
                                this.setState({
                                    update: true
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'diagnostic-page.group'
                            })}
                            style={{ minWidth: '100px', color: 'var(--text)' }}
                            value={data}
                        />
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='diagnostic-page.code' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterCode: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'diagnostic-page.code'
                                })}
                                value={this.state.filterCode}
                            />
                        </div>
                    );
                },
                dataIndex: 'partId',
                key: 'partId',
                width: '10%',
                render: (data, elem) => {
                    const { key } = elem;

                    return (
                        <Button
                            disabled={isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)}
                            onClick={() => {
                                this.setState({
                                    currentKey: key,
                                    visible: true
                                });
                            }}
                            style={
                                isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)
                                    ? {
                                          color: 'var(--tetx)'
                                      }
                                    : {
                                          width: '100%'
                                      }
                            }
                            type={!data ? 'primary' : 'default'}
                        >
                            {data || <FormattedMessage id='select' />}
                        </Button>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            <FormattedMessage id='diagnostic-page.name' />
                            <Input
                                allowClear
                                onChange={event => {
                                    this.setState({
                                        filterName: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'diagnostic-page.name'
                                })}
                                value={this.state.filterName}
                            />
                        </div>
                    );
                },
                dataIndex: 'partTitle',
                key: 'partTitle',
                width: '30%',
                render: data => {
                    return <span>{data || <FormattedMessage id='long_dash' />}</span>;
                }
            },
            {
                key: 'delete',
                width: '5%',
                render: elem => {
                    return (
                        <DeleteOutlined
                            onClick={() => {
                                if (!isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)) {
                                    elem.deleted = true;
                                    this.setState({
                                        update: true
                                    });
                                }
                            }}
                            style={
                                isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)
                                    ? {
                                          pointerEvents: 'none',
                                          color: 'var(--text2)'
                                      }
                                    : null
                            }
                        />
                    );
                }
            }
        ];

        this.modalColumns = [
            {
                title: 'ID',
                key: 'partId',
                dataIndex: 'partId',
                width: '20%'
            },
            {
                title: <FormattedMessage id='diagnostic-page.name' />,
                key: 'partTitle',
                dataIndex: 'partTitle',
                width: '40%'
            },
            {
                title: <FormattedMessage id='diagnostic-page.action' />,
                key: 'actionTitle',
                dataIndex: 'actionTitle',
                width: '30%'
            },
            {
                key: 'save',
                render: elem => {
                    const key = this.state.currentKey;

                    return (
                        <Button
                            disabled={isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)}
                            onClick={() => {
                                this.state.diagnosticParts[key].partId = elem.partId;
                                this.state.diagnosticParts[key].partTitle = elem.partTitle;
                                this.setState({
                                    visible: false
                                });
                            }}
                            type='primary'
                        >
                            <CheckOutlined />
                        </Button>
                    );
                }
            }
        ];
    }

    handleCancel() {
        this.setState({
            visible: false
        });
    }

    handleOk() {
        this.setState({
            visible: false
        });
    }

    saveDiagnostic() {
        const resultData = [];
        this.state.diagnosticParts.map(part => {
            if (part.diagnosticTemplateTitle && part.groupTitle && part.partId) {
                const templateIndex = resultData.findIndex(
                    elem => elem.diagnosticTemplateTitle == part.diagnosticTemplateTitle
                );
                if (templateIndex == -1) {
                    resultData.push({
                        diagnosticTemplateId: part.diagnosticTemplateId,
                        diagnosticTemplateTitle: part.diagnosticTemplateTitle,
                        groups: []
                    });
                }
            }
        });
        this.state.diagnosticParts.map(part => {
            if (part.diagnosticTemplateTitle && part.groupTitle && part.partId) {
                const templateIndex = resultData.findIndex(
                    elem => elem.diagnosticTemplateTitle == part.diagnosticTemplateTitle
                );
                const groupIndex = resultData[templateIndex].groups.findIndex(
                    elem => elem.groupTitle == part.groupTitle
                );
                if (groupIndex === -1) {
                    resultData[templateIndex].groups.push({
                        groupId: part.groupId,
                        groupTitle: part.groupTitle,
                        partIds: []
                    });
                }
            }
        });
        this.state.diagnosticParts.map(part => {
            if (part.diagnosticTemplateTitle && part.groupTitle && part.partId) {
                const templateIndex = resultData.findIndex(
                    elem => elem.diagnosticTemplateTitle == part.diagnosticTemplateTitle
                );
                const groupIndex = resultData[templateIndex].groups.findIndex(
                    elem => elem.groupTitle == part.groupTitle
                );
                if (!part.deleted) {
                    resultData[templateIndex].groups[groupIndex].partIds.push(part.partId);
                }
            }
        });

        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = '/diagnostics';
        url += params;
        fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: token
            },
            body: JSON.stringify(resultData)
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                window.location.reload();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    importDefaultDiagnostics() {
        const title = this.props.intl.formatMessage({ id: 'agreement.confirm_title' });
        const content = this.props.intl.formatMessage({ id: 'agreement.confirm_content' });
        const { confirm } = Modal;
        confirm({
            title,
            content,
            onOk: async () => {
                const token = localStorage.getItem('_my.carbook.pro_token');
                let url = __API_URL__;
                const params = '/diagnostics/standard';
                url += params;

                await fetch(url, {
                    method: 'COPY',
                    headers: {
                        Authorization: token
                    }
                });
                await window.location.reload();
            }
        });
    }

    fetchDiagnosticParts = async () => {
        const { diagnosticParts, page, pageSize, count } = await fetchAPI('GET', 'diagnostics?keepFlat=true');
        diagnosticParts.forEach((row, key) => {
            row.key = key;
        });
        this.setState({
            diagnosticParts,
            page,
            pageSize,
            count: 0
        });
        if (this.props.location.state && this.props.location.state.showForm) {
            this.planInput.focus();
        }

        const { diagnosticParts: masterDiagnosticParts } = await fetchAPI('GET', 'diagnostics/master?keepFlat=true');
        masterDiagnosticParts.forEach((row, key) => {
            row.key = key;
        });
        this.setState({
            masterDiagnosticParts
        });
    };

    componentDidMount() {
        this.fetchDiagnosticParts();
    }

    render() {
        const {
            diagnosticParts,
            masterDiagnosticParts,
            filterPlan,
            filterGroup,
            filterCode,
            filterName,
            currentPage,
            page,
            pageSize,
            count
        } = this.state;
        if (
            !isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD) &&
            diagnosticParts.length &&
            (diagnosticParts[diagnosticParts.length - 1].diagnosticTemplateTitle !== '' ||
                diagnosticParts[diagnosticParts.length - 1].groupTitle !== '')
        ) {
            diagnosticParts.push({
                key: diagnosticParts.length,
                diagnosticTemplateTitle: '',
                groupTitle: '',
                partTitle: '',
                partId: null
            });
        }
        const pagination = {
            pageSize,
            total: (Math.ceil.count / pageSize) * pageSize,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchDiagnosticParts();
            }
        };

        const { columns } = this;
        const { modalColumns } = this;

        let dataSource = [...diagnosticParts];
        const modalDataSource = [...masterDiagnosticParts];

        dataSource = dataSource.filter(data => !data.deleted);
        if (filterPlan) {
            dataSource = dataSource.filter(data =>
                data.diagnosticTemplateTitle.toLowerCase().includes(filterPlan.toLowerCase())
            );
        }
        if (filterGroup) {
            dataSource = dataSource.filter(data => data.groupTitle.toLowerCase().includes(filterGroup.toLowerCase()));
        }
        if (filterName) {
            dataSource = dataSource.filter(data => data.partTitle.toLowerCase().includes(filterName.toLowerCase()));
        }
        if (filterCode) {
            dataSource = dataSource.filter(data => String(data.partId).includes(filterCode));
        }

        return (
            <Layout
                controls={
                    <React.Fragment>
                        <Button
                            disabled={isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)}
                            onClick={() => this.importDefaultDiagnostics()}
                            style={{ marginRight: 10 }}
                        >
                            <FormattedMessage id='import_default' />
                        </Button>
                        <Button
                            disabled={isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_DIAGNOSTICS_CRUD)}
                            onClick={() => this.saveDiagnostic()}
                            type='primary'
                        >
                            <FormattedMessage id='save' />
                        </Button>
                    </React.Fragment>
                }
                title={<FormattedMessage id='diagnostic-page.title' />}
            >
                <Table
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={pagination}
                    // pagination={{
                    //     pageSize: 10,
                    //     hideOnSinglePage: true,
                    //     current: currentPage,
                    //     onChange: page => {
                    //         this.setState({
                    //             currentPage: page
                    //         });
                    //     }
                    // }}
                    scroll={{ y: 680 }}
                    size='small'
                />
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={() => {
                        this.handleCancel();
                    }}
                    title={<FormattedMessage id='diagnostic-page.change_code' />}
                    visible={this.state.visible}
                    width='80%'
                >
                    <Table
                        bordered
                        columns={modalColumns}
                        dataSource={modalDataSource}
                        locale={{
                            emptyText: <FormattedMessage id='no_data' />
                        }}
                        pagination={false}
                        scroll={{ y: 520 }}
                        size='small'
                    />
                </Modal>
            </Layout>
        );
    }
}

export default DiagnosticPatternsPage;
