exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__title---1oFr2{font-size:24px;margin-right:24px}.styles-m__count---1KAgY{color:var(--secondary);font-size:24px;font-weight:700}.styles-m__columnsStyle---1t3Gs{display:flex;flex-direction:row;border:1px solid #d3d3d3;padding:12px}.styles-m__separateColumn---2gHKu{margin-right:8px}.styles-m__actionBtn---3vv-5{display:flex;justify-content:space-around;margin-top:8px}.styles-m__notific---1flz4,.styles-m__timePickerPosition---3Djc0{display:flex;justify-content:center}.styles-m__notific---1flz4{font-size:16px;font-weight:700;color:#f08080}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACI,cAAe,CACf,iBACJ,CAEA,yBACI,sBAAuB,CACvB,cAAe,CACf,eACJ,CAEA,gCACI,YAAa,CACb,kBAAmB,CAEnB,wBAAuB,CACvB,YACJ,CAEA,kCACI,gBACJ,CAEA,6BACI,YAAa,CACb,4BAA6B,CAC7B,cACJ,CAOA,iEAJI,YAAa,CACb,sBASJ,CANA,2BACI,cAAe,CACf,eAAgB,CAGhB,aACJ","file":"styles.m.css","sourcesContent":[".title {\n    font-size: 24px;\n    margin-right: 24px;\n}\n\n.count {\n    color: var(--secondary);\n    font-size: 24px;\n    font-weight: bold;\n}\n\n.columnsStyle {\n    display: flex;\n    flex-direction: row;\n    border: 1px solid;\n    border-color: lightgray;\n    padding: 12px;\n}\n\n.separateColumn {\n    margin-right: 8px;\n}\n\n.actionBtn {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 8px;\n}\n\n.timePickerPosition {\n    display: flex;\n    justify-content: center;\n}\n\n.notific {\n    font-size: 16px;\n    font-weight: 700;\n    display: flex;\n    justify-content: center;\n    color: lightcoral;\n}\n"]}]);

// Exports
exports.locals = {
	"title": "styles-m__title---1oFr2",
	"count": "styles-m__count---1KAgY",
	"columnsStyle": "styles-m__columnsStyle---1t3Gs",
	"separateColumn": "styles-m__separateColumn---2gHKu",
	"actionBtn": "styles-m__actionBtn---3vv-5",
	"timePickerPosition": "styles-m__timePickerPosition---3Djc0",
	"notific": "styles-m__notific---1flz4"
};