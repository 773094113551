import { Layout, Spinner } from 'commons';
import { ServicesContainer } from 'containers';
import { fetchServicesSuggestions } from 'core/servicesSuggestions/duck';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    isFetching: state.ui.suggestionsFetching
});

const mapDispatchToProps = { fetchServicesSuggestions };

@connect(mapStateToProps, mapDispatchToProps)
export default class ServicesPage extends Component {
    componentDidMount() {
        this.props.fetchServicesSuggestions();
    }

    render() {
        const { isFetching } = this.props;

        return isFetching ? (
            <Spinner spin={isFetching} />
        ) : (
            <Layout paper title={<FormattedMessage id='navigation.services-spare_parts' />}>
                <ServicesContainer isFetching={isFetching} />
            </Layout>
        );
    }
}
