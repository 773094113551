import { PartAttributes, PartSuggestions } from 'components';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

@injectIntl
export default class TecDocModals extends Component {
    extractRelevantTecDocData() {
        const {
            crosses: allCrosses,
            suggestions: allSuggestions,
            attributes: allAttributes,
            selectedAttributes,
            selectedSuggestions,
            selectedCrosses
        } = this.props;

        const { supplierId: attributesSupplierId, partCode: attributesPartCode } =
            selectedAttributes || {};
        const { productId: suggestionsProductId, modificationId: suggestionsModificationId } =
            selectedSuggestions || {};
        const { productId: crossesProductId, modificationId: crossesModificationId } =
            selectedCrosses || {};

        const attributesEntity =
            attributesPartCode &&
            attributesSupplierId &&
            _.chain(allAttributes)
                .find({
                    partCode: attributesPartCode,
                    supplierId: attributesSupplierId
                })
                .value();

        const attributes = _.get(attributesEntity, 'attributes');
        const images = _.get(attributesEntity, 'images');

        const suggestions =
            suggestionsProductId &&
            suggestionsModificationId &&
            _.chain(allSuggestions)
                .find({
                    productId: suggestionsProductId,
                    modificationId: suggestionsModificationId
                })
                .get('suggestions')
                .value();

        const crosses =
            crossesProductId &&
            crossesModificationId &&
            _.chain(allCrosses)
                .find({
                    productId: crossesProductId,
                    modificationId: crossesModificationId
                })
                .get('crosses')
                .value();

        return { crosses, suggestions, attributes, images };
    }

    render() {
        const { selectedAttributes } = this.props;
        const { partCode: detailCode } = selectedAttributes || {};

        const {
            fetchPartAttributes,
            clearPartAttributes,
            clearSuggestionParts,
            clearCrossParts,
            onSelect,
            product,
            supplier
        } = this.props;

        const { attributes, images, crosses, suggestions } = this.extractRelevantTecDocData();

        return (
            <div>
                <PartAttributes
                    attributes={attributes}
                    detailCode={detailCode}
                    hideModal={clearPartAttributes}
                    images={images}
                    product={product}
                    showModal={Boolean(selectedAttributes)}
                    supplier={supplier}
                />
                <PartSuggestions
                    fetchPartAttributes={fetchPartAttributes}
                    hideModal={clearSuggestionParts}
                    onSelect={onSelect}
                    showModal={Boolean(suggestions) && !selectedAttributes}
                    suggestions={suggestions}
                />
                <PartSuggestions
                    fetchPartAttributes={fetchPartAttributes}
                    hideModal={clearCrossParts}
                    onSelect={onSelect}
                    showModal={Boolean(crosses) && !selectedAttributes}
                    suggestions={crosses}
                />
            </div>
        );
    }
}
