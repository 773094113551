import { Tabs } from 'antd';
import { Layout } from 'commons';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { grants, isGrantAccessed } from 'utils/grants';
import { BillingGroupsTab, BussinessTab, GroupsDirectoryTab } from './components';

const { TabPane } = Tabs;

const MonetizationPage = ({ user, intl: { formatMessage } }) => {
    return (
        <Layout title={<FormattedMessage id='navigation.monetization' />}>
            <Tabs>
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_NETWORK) && (
                    <TabPane
                        key='1'
                        tab={formatMessage({
                            id: 'monetization.issuances'
                        })}
                    >
                        <BillingGroupsTab user={user} />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_NETWORK) && (
                    <TabPane
                        key='2'
                        tab={formatMessage({
                            id: 'monetization.groups_directory'
                        })}
                    >
                        <GroupsDirectoryTab user={user} />
                    </TabPane>
                )}
                {isGrantAccessed(user, grants.SETTINGS_BUSINESS_SETTINGS_NETWORK) && (
                    <TabPane
                        key='3'
                        tab={formatMessage({
                            id: 'monetization.bussiness'
                        })}
                    >
                        <BussinessTab user={user} />
                    </TabPane>
                )}
            </Tabs>
        </Layout>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default injectIntl(connect(mapStateToProps)(MonetizationPage));
