import * as THREE from 'three';

export class Sphere extends THREE.Mesh {
    constructor(point, addUnit, geometry, renderer, palette) {
        super();
        this.primaryColor = new THREE.Color(palette.primary || 'rgb(155, 89, 182)');
        this.hoverColor = new THREE.Color(palette.secondary || 'rgb(81, 205, 102)');

        this.geometry = new THREE.SphereGeometry(geometry.radius, 16, 16);
        this.material = new THREE.MeshBasicMaterial({ color: this.primaryColor, wireframe: true });
        this.sphere = new THREE.Mesh(this.geometry, this.material);
        this.userData = { point, interactable: true };
        this.renderer = renderer;
        this.addUnit = addUnit;
    }

    onPointerOver(e) {
        this.material.color.set(this.hoverColor);
        this.renderer.domElement.style.cursor = 'pointer';
    }

    onPointerOut(e) {
        this.material.color.set(this.primaryColor);
        this.renderer.domElement.style.cursor = 'auto';
    }

    onClick(e) {
        this.addUnit(this.userData.point);
    }
}
