import { notification } from 'antd';
import { emitError } from 'core/ui/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import { CREATE_ORDER, selectBeginDate, selectBeginTime, selectComment } from './duck';

export function* createOrderSaga() {
    while (true) {
        const {
            payload: { clientId, managerId, vehicleId }
        } = yield take(CREATE_ORDER);
        if (!clientId || !managerId) {
            notification.error({
                message: 'Invalid input parameters, no client or manager ID'
            });
            continue;
        }

        // Get client
        const client = yield call(fetchAPI, 'GET', `clients/${clientId}`, null, null, {
            handleErrorInternally: true
        });

        const beginDate = yield select(selectBeginDate);
        const beginTime = yield select(selectBeginTime);
        const comment = yield select(selectComment);

        try {
            yield call(
                fetchAPI,
                'POST',
                'orders',
                null,
                {
                    clientId: client.clientId,
                    clientVehicleId: vehicleId || void 0,
                    duration: 0.5,
                    clientPhone: client.phones[0],
                    stationLoads: [
                        {
                            beginDatetime: dayjs().startOf('hour').toISOString(),
                            duration: 0.5,
                            status: 'TO_DO'
                        }
                    ],
                    status: 'not_complete',
                    managerId,
                    beginDatetime: `${beginDate} ${beginTime}`,
                    comment
                },
                { handleErrorInternally: true }
            );

            notification.success();
        } catch (err) {
            yield put(emitError(err));

            notification.error({
                message: _.get(err, 'response.message')
            });
        }
    }
}

export function* saga() {
    yield all([call(createOrderSaga)]);
}
