import { CloseCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { selectStoreProductsByQuery, setStoreProductsSearchQuery } from 'core/search/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    storeProducts: selectStoreProductsByQuery(state)
});

const StoreProductsSelect = injectIntl(
    connect(mapStateToProps, { setStoreProductsSearchQuery })(props => {
        const handleSearch = value =>
            props.setStoreProductsSearchQuery(
                value.replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g, '')
            );

        const handleSelect = productId => props.setFilters({ productId, page: 1 });

        if (!props.storeProducts.length) {
            props.setStoreProductsSearchQuery();
        }

        return (
            <Select
                // labelInValue
                allowClear
                autoClearSearchValue
                clearIcon={<CloseCircleOutlined onClick={() => handleSelect(void 0)} />}
                filterOption={(inputValue, option) => {
                    const code = option.props.children
                        .replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g, '')
                        .toLowerCase();
                    const barcode = String(option.props.barcode)
                        .replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g, '')
                        .toLowerCase();
                    const filter = inputValue
                        .replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g, '')
                        .toLowerCase();

                    return code.includes(filter) || barcode.includes(filter);
                }}
                initialValue={props.filters.productId}
                // notFoundContent={ fetching ? <Spin size='small' /> : null }
                onSearch={handleSearch}
                onSelect={handleSelect}
                optionFilterProp='children'
                placeholder={props.intl.formatMessage({
                    id: 'storage.select_product'
                })}
                showSearch
                style={{ width: '320px' }}
                value={props.filters.productId}
            >
                {props.storeProducts.map(product => (
                    <Option key={product.id} barcode={product.barcode} value={product.id}>
                        {product.code}
                    </Option>
                ))}
            </Select>
        );
    })
);

export default StoreProductsSelect;
