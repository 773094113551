import { DollarCircleOutlined } from '@ant-design/icons';
import { Numeral } from 'commons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { isForbidden, permissions } from 'utils';

// Choose width for each col, (100% in total or less than 100% if 'auto' is present)
const defWidth = {
    supplierNameCol: 'auto',
    contact: '10%',
    paymentRespite: '7%',
    totalSumWithCashOrdersCol: '20%',
    totalSumWithCashNotExpiredCol: '20%',
    totalSumWithCashExpiredCol: '20%'
};

const mask = '0,0.00';

export function columnsConfig(props) {
    const { user } = props;

    const supplierNameCol = {
        title: <FormattedMessage id='supplier_debts_container.supplier_name' />,
        width: defWidth.supplierNameCol,
        align: 'left',
        dataIndex: 'businessSupplierName',
        key: 'supplierNameCol',
        render: (name, { businesssupplierid }) => {
            return isForbidden(user, permissions.ACCESS_SUPPLIER) ? (
                name
            ) : (
                <Link
                    to={{
                        pathname: `${book.supplier}/${businesssupplierid}`,
                        state: { tab: 'debt' }
                    }}
                >
                    {name}
                </Link>
            );
        }
    };

    const contactCol = {
        title: <FormattedMessage id='supplier_debts_container.contact' />,
        width: defWidth.contact,
        align: 'right',
        dataIndex: 'phones',
        key: 'phonesCol',
        render: phones => {
            return (
                <div>{phones ? phones.map(phone => <span>{phone}</span>) : <FormattedMessage id='long_dash' />}</div>
            );
        }
    };

    const paymentRespiteCol = {
        title: <FormattedMessage id='supplier_debts_container.payment_respite' />,
        width: defWidth.contact,
        align: 'right',
        dataIndex: 'paymentRespite',
        key: 'paymentRespiteCol',
        render: paymentRespite => {
            return <div>{paymentRespite || 0}</div>;
        }
    };

    const totalSumWithCashOrdersCol = {
        title: <FormattedMessage id='supplier_debts_container.total_sum_with_cash_orders' />,
        width: defWidth.totalSumWithCashOrdersCol,
        align: 'right',
        dataIndex: 'totalSumWithCashOrders',
        key: 'totalSumWithCashOrdersCol',
        render: (key, { totalSumWithCashOrders }) => {
            return <Numeral mask={mask}>{totalSumWithCashOrders}</Numeral>;
        }
    };

    const totalSumWithCashNotExpiredCol = {
        title: <FormattedMessage id='supplier_debts_container.total_sum_with_cash_not_expired' />,
        width: defWidth.totalSumWithCashNotExpiredCol,
        align: 'right',
        dataIndex: 'totalSumWithCashOrdersNotExpired',
        key: 'totalSumWithCashNotExpiredCol',
        render: (key, { totalSumWithCashOrdersNotExpired }) => {
            return <Numeral mask={mask}>{totalSumWithCashOrdersNotExpired}</Numeral>;
        }
    };
    const totalSumWithCashExpiredCol = {
        title: <FormattedMessage id='supplier_debts_container.total_sum_with_cash_expired' />,
        width: defWidth.totalSumWithCashExpiredCol,
        align: 'right',
        dataIndex: 'totalSumWithCashOrdersExpired',
        key: 'totalSumWithCashExpiredCol',
        render: (key, { totalSumWithCashOrdersExpired }) => {
            return <Numeral mask={mask}>{totalSumWithCashOrdersExpired}</Numeral>;
        }
    };

    const createCashOrder = {
        key: 'createCO',
        width: '5%',
        align: 'center',
        render: (oweAmount, row) => {
            const split = String(row.documentNum).split('-');

            return (
                <React.Fragment>
                    <DollarCircleOutlined
                        className='dollar-icon'
                        onClick={async () => {
                            await props.showCashOrderModal({ ...row });
                        }}
                    />
                </React.Fragment>
            );
        }
    };

    const columns = [
        supplierNameCol,
        contactCol,
        paymentRespiteCol,
        totalSumWithCashOrdersCol,
        totalSumWithCashNotExpiredCol,
        totalSumWithCashExpiredCol
    ];

    if (!props.paymentDisabled) {
        columns.push(createCashOrder);
    }

    return columns;
}
