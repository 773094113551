import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Numeral } from 'commons';
import _ from 'lodash';
import { selectSelectedClient } from 'modals/DebtZeroingModal/redux/duck';
import {
    selectFetchingSelectedOrder,
    selectOrders,
    selectSelectedOrder,
    selectSelectedOrderId,
    setOrdersFilters,
    setSelectedOrderId
} from 'modals/DebtZeroingModal/redux/orders/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    selectedOrderId: selectSelectedOrderId(state),
    selectedOrder: selectSelectedOrder(state),
    fetchingSelectedOrder: selectFetchingSelectedOrder(state),
    orders: selectOrders(state),
    selectedClient: selectSelectedClient(state)
});

const mapDispatchToProps = {
    setSelectedOrderId,
    setOrdersFilters
};

/**
 * If order is selected this component generates block with its basic
 * information and ability to remove currently selected order(make no one selected);
 *
 * Note: this component uses object returned when order is fetched by id,
 * those objects can be different from others request responses that work with orders
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrderVisualizer extends React.Component {
    render() {
        const { selectedClient, fetchingSelectedOrder, selectedOrder, setSelectedOrderId } =
            this.props;

        if (!selectedOrder) return 'No selected order';
        if (fetchingSelectedOrder) return <LoadingOutlined />;

        const order = _.get(selectedOrder, 'order');
        const vehicle = _.get(selectedOrder, 'vehicle');
        const phones = _.get(selectedClient, 'phones');

        return (
            <div className={Styles.mainContainer}>
                <Row className={Styles.row}>
                    <Col span={4}>{`${_.get(order, 'num')}`}</Col>
                    <Col className={Styles.col} span={8}>
                        <Link to={`${book.client}/${selectedClient.clientId}`}>
                            <span>{`${selectedClient.name || ''} ${
                                selectedClient.middleName || ''
                            } ${selectedClient.surname || ''}`}</span>
                        </Link>
                        {phones &&
                            _.map(phones, (phone, index) => {
                                return (
                                    <div key={index}>
                                        <a className={Styles.phoneNumber} href={`tel:${phone}`}>
                                            {phone}
                                        </a>
                                    </div>
                                );
                            })}
                    </Col>

                    <Col className={Styles.col} span={6}>
                        <div className={Styles.vehicle}>
                            <Link to={`${book.vehicle}/${_.get(vehicle, 'vehicleId')}`}>
                                {`${_.get(vehicle, 'make', '')} ${_.get(
                                    vehicle,
                                    'model',
                                    ''
                                )} ${_.get(vehicle, 'year', '')}`}
                            </Link>
                        </div>
                    </Col>

                    <Col span={2}>
                        <Numeral mask='0,0.00' nullText='0'>
                            {Number(_.get(order, 'totalSumWithTax', 0)) || 0}
                        </Numeral>
                    </Col>

                    <Col span={2}>
                        <Numeral mask='0,0.00' nullText='0'>
                            {Number(_.get(order, 'remainingSum', 0)) || 0}
                        </Numeral>
                    </Col>

                    <Col className={Styles.actionsCol} span={2}>
                        <CloseOutlined
                            className={Styles.closeIcon}
                            // Remove currently selected order
                            onClick={() => setSelectedOrderId(undefined)}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
