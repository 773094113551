import { Select } from 'antd';
import ActionsIcons from 'commons/_uikit/ActionIcons';
import { DecoratedInput, DecoratedInputNumber, DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React from 'react';
import {
    // permissions,
    // isForbidden,
    // CachedInvoke,
    numeralFormatter,
    numeralParser
} from 'utils';
import { v4 } from 'uuid';

const { Option } = Select;

export default (props, state, table) => {
    const { formatMessage } = props.intl;
    const { getFieldDecorator } = props.form;
    const { fields } = props.form;

    const getRequiredRule = (rows, key, index) => {
        if (index === 0 || (rows > index && rows !== index + 1)) {
            return [
                {
                    required: true,
                    message: formatMessage({
                        id: 'required_field'
                    })
                }
            ];
        }

        return [];
    };

    const _isFieldDisabled = key => !props.form.getFieldValue(`docProducts[${key}].productId`);

    return [
        {
            title: formatMessage({ id: 'storage.product_code' }),
            width: '20%',
            key: 'code',
            render: ({ key }) => {
                return (
                    <DecoratedSelect
                        dropdownMatchSelectWidth={false}
                        field={`docProducts[${key}].productId`}
                        fields={{}}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        getPopupContainer={trigger => trigger.parentNode}
                        initialValue={_.get(props, `incomeDoc.docProducts[${key}].product.id`)}
                        onBlur={() => {}}
                        onSearch={value => {
                            props.setStoreProductsSearchQuery(value);
                        }}
                        onSelect={value => table.handleProductSelect(key, value)}
                        rules={getRequiredRule(state.keys.length, key)}
                        showSearch
                    >
                        {!_.isEmpty(props.searchStoreProducts) ? (
                            [...(props.searchStoreProducts || [])].map(({ id, code }) => (
                                <Option key={v4()} value={id}>
                                    {code}
                                </Option>
                            ))
                        ) : (
                            <Option
                                key={v4()}
                                value={_.get(props, `incomeDoc.docProducts[${key}].product.id`)}
                            >
                                {_.get(props, `incomeDoc.docProducts[${key}].product.code`)}
                            </Option>
                        )}
                    </DecoratedSelect>
                );
            }
        },
        {
            title: formatMessage({ id: 'storage.product_name' }),
            width: '20%',
            key: 'name',
            render: ({ key }) => (
                <DecoratedInput
                    disabled
                    // errors={ errors }
                    // defaultGetValueProps
                    field={`docProducts[${key}].name`}
                    fields={{}}
                    fieldValue={_.get(fields, `details[${key}].name`)}
                    getFieldDecorator={props.form.getFieldDecorator}
                    initialValue={_.get(props, `incomeDoc.docProducts[${key}].product.name`)}
                />
            )
        },
        {
            title: formatMessage({ id: 'storage.brand' }),
            width: '10%',
            key: 'brandId',
            render: ({ key }) => {
                return (
                    <React.Fragment>
                        <DecoratedInput
                            disabled
                            // defaultGetValueProps
                            field={`docProducts[${key}].brandName`}
                            fields={{}}
                            getFieldDecorator={props.form.getFieldDecorator}
                            initialValue={
                                _.get(props, `incomeDoc.docProducts[${key}].product.brand.name`) ||
                                _.get(props, `incomeDoc.docProducts[${key}].product.brandName`)
                            }
                        />
                        <DecoratedInput
                            field={`docProducts[${key}].brandId`}
                            fields={{}}
                            getFieldDecorator={props.form.getFieldDecorator}
                            hiddeninput='hiddeninput'
                            initialValue={_.get(
                                props,
                                `incomeDoc.docProducts[${key}].product.brandId`
                            )}
                        />
                    </React.Fragment>
                );
            }
        },
        {
            title: formatMessage({ id: 'storage.trade_code' }),
            width: '20%',
            key: 'tradeCode',
            render: ({ key }) => (
                <DecoratedInput
                    disabled
                    field={`docProducts[${key}].tradeCode`}
                    fields={{}}
                    getFieldDecorator={props.form.getFieldDecorator}
                    initialValue={_.get(props, `incomeDoc.docProducts[${key}].product.certificate`)}
                />
            )
        },
        {
            title: formatMessage({ id: 'order_form_table.purchasePrice' }),
            width: '15%',
            key: 'purchasePrice',
            render: ({ key, index }) => {
                return (
                    <DecoratedInputNumber
                        disabled={_isFieldDisabled(key)}
                        field={`docProducts[${key}].purchasePrice`}
                        fields={{}}
                        formatter={numeralFormatter}
                        formItem
                        getFieldDecorator={props.form.getFieldDecorator}
                        initialValue={_.get(props, `incomeDoc.docProducts[${key}].purchasePrice`)}
                        min={0}
                        onChange={value =>
                            table.handleSumCalculation(
                                key,
                                `docProducts[${key}].purchasePrice`,
                                value
                            )
                        }
                        parser={numeralParser}
                        rules={getRequiredRule(state.keys.length, key, index)}
                    />
                );
            }
        },
        {
            title: formatMessage({ id: 'storage.quantity' }),
            width: '7.5%',
            key: 'quantity',
            render: ({ key, index }) => (
                <DecoratedInputNumber
                    disabled={_isFieldDisabled(key)}
                    field={`docProducts[${key}].quantity`}
                    fields={{}}
                    formatter={numeralFormatter}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={
                        _isFieldDisabled(key)
                            ? void 0
                            : _.get(props, `incomeDoc.docProducts[${key}].quantity`, 1)
                    }
                    min={0.1}
                    onChange={value =>
                        table.handleSumCalculation(key, `docProducts[${key}].quantity`, value)
                    }
                    parser={numeralParser}
                    precision={2}
                    rules={getRequiredRule(state.keys.length, key, index)}
                    step={0.1}
                />
            )
        },
        {
            title: formatMessage({ id: 'storage.sum' }),
            width: '10%',
            key: 'sum',
            render: ({ key, index }) => {
                return (
                    <DecoratedInputNumber
                        disabled
                        field={`docProducts[${key}].purchaseSum`}
                        fields={{}}
                        formatter={numeralFormatter}
                        formItem
                        getFieldDecorator={props.form.getFieldDecorator}
                        initialValue={_.get(props, `incomeDoc.docProducts[${key}].purchaseSum`)}
                        parser={numeralParser}
                        rules={getRequiredRule(state.keys.length, key, index)}
                    />
                );
            }
        },
        {
            title: '',
            width: 'auto',
            key: 'delete',
            render: ({ key }) =>
                state.keys.length > 1 &&
                _.last(state.keys) !== key && (
                    <ActionsIcons delete={() => table.handleDelete(key)} />
                )
        }
    ];
};
