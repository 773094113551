import { DatePicker, Input, Modal, notification, Select } from 'antd';
import { fetchWarehouses } from 'core/warehouses/duck';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { fetchAnalytics, selectAnalytics } from '../StoreDocServiceModal/redux/duck';
import './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    analytics: selectAnalytics(state)
});

const mapDispatchToProps = {
    fetchAnalytics,
    fetchWarehouses
};
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CreateIncomeServiceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requisites: [],
            srv: []
        };
    }

    componentDidMount() {
        this.props.fetchAnalytics();
        this.props.fetchWarehouses();
    }

    componentDidUpdate(prevProps) {
        const { visible, row, selectedRows, user, warehouses } = this.props;
        const mainWarehouse = warehouses.filter(({ attribute }) => attribute == 'MAIN');

        if (!prevProps.visible && visible) {
            if (row) {
                this.fetchSupplierRequisites(row.businessSupplierId);
                this.setState({
                    supplierName: row.businessSupplierName,
                    toWarehouseId: mainWarehouse[0].id,
                    analyticsId: 14
                });
            } else {
                const groupedData = selectedRows
                    .filter(row => row.businessSupplierId)
                    .sort((a, b) => a.businessSupplierId - b.businessSupplierId);
                this.prepareGroupedData(groupedData);
                this.setState({
                    analyticsId: 14
                });
            }
        }
    }

    fetchSupplierRequisites = async id => {
        const { requisites } = await fetchAPI('GET', `business_suppliers/${id}`, null, null, {
            handleErrorInternally: true
        });
        this.setState({
            requisites
        });
    };

    prepareGroupedData = groupedData => {
        const mainWarehouse = this.props.warehouses.filter(({ attribute }) => attribute == 'MAIN');

        if (groupedData.length) {
            this.fetchSupplierRequisites(groupedData[0].businessSupplierId);
            this.setState({
                clicked: false,
                groupedData,
                supplierName: groupedData[0].businessSupplierName,
                supplierDocNumber: undefined,
                datetime: undefined,
                requisiteSupplierId: undefined,
                toWarehouseId: mainWarehouse[0].id || undefined
            });
        } else {
            notification.warning({
                message: this.props.intl.formatMessage({ id: 'not_found' })
            });
            this.handleCancel();
        }
    };

    handleOkForSingleOperation = async () => {
        const {
            row,
            updateDataSource,
            intl: { formatMessage }
        } = this.props;

        const { datetime, supplierDocNumber, toWarehouseId, requisiteSupplierId, analyticsId } = this.state;
        this.setState({ clicked: true });
        await fetchAPI('POST', 'store_docs/receiveServices', undefined, {
            orderServicesIds: [row.id],
            supplierDocNumber,
            datetime: dayjs(datetime).toISOString(),
            toWarehouseId: toWarehouseId || undefined,
            requisiteSupplierId,
            analyticsId
        });
        notification.success({
            message: this.props.intl.formatMessage({ id: 'order_docs.add_income_services_succes' })
        });
        updateDataSource();
        this.handleCancel();
    };

    handleOkForGroupOperation = async () => {
        const { updateDataSource, intl } = this.props;
        const { groupedData, datetime, supplierDocNumber, toWarehouseId, requisiteSupplierId, analyticsId } =
            this.state;

        const filtredData = groupedData.filter(
            el => el.businessSupplierId === get(groupedData, '[0].businessSupplierId')
        );
        const restData = groupedData.filter(el => el.businessSupplierId !== get(groupedData, '[0].businessSupplierId'));
        this.setState({ clicked: true });

        const response = await fetchAPI(
            'POST',
            'store_docs/receiveServices',
            undefined,
            {
                orderServicesIds: filtredData.map(({ id }) => id),
                supplierDocNumber,
                datetime: dayjs(datetime).toISOString(),
                toWarehouseId: toWarehouseId || undefined,
                requisiteSupplierId,
                analyticsId
            },
            { handleErrorInternally: true }
        );
        notification.success({
            message: this.props.intl.formatMessage({ id: 'order_docs.add_income_services_succes' })
        });
        updateDataSource();
        if (restData.length) {
            this.prepareGroupedData(restData);
        } else {
            this.handleCancel();
        }
    };

    handleCancel = () => {
        this.props.hideModal();
        this.setState({
            supplierDocNumber: undefined,
            datetime: undefined,
            toWarehouseId: undefined,
            analyticsId: undefined,
            requisiteSupplierId: undefined,
            requisites: [],
            clicked: false
        });
    };

    checkExisting = async id => {
        const {
            intl: { formatMessage }
        } = this.props;
        const { orders } = await fetchAPI(
            'GET',
            'orders',
            {
                vehicleId: id,
                status: 'not_complete,required,call,reserve,approve,progress,review,invite'
            },
            null,
            { handleErrorInternally: true }
        );

        if (orders) {
            const index = orders.findIndex(({ vehicleId }) => id === vehicleId);
            if (index >= 0) {
                Modal.confirm({
                    title: formatMessage({
                        id: 'add_client_form.already_exist_order'
                    }),
                    onOk() {
                        goTo(`${book.order}/${orders[index].id}`);
                    },
                    okType: 'default',
                    onCancel() {}
                });
            }
        }
    };

    render() {
        const {
            visible,
            intl: { formatMessage },
            loading,
            row,
            warehouses,
            analytics
        } = this.props;

        const {
            supplierName,
            supplierDocNumber,
            datetime,
            toWarehouseId,
            requisiteSupplierId,
            requisites,
            clicked,
            analyticsId
        } = this.state;

        return (
            <Modal
                maskClosable={false}
                okButtonProps={{ disabled: clicked }}
                onCancel={this.handleCancel}
                onOk={row ? this.handleOkForSingleOperation : this.handleOkForGroupOperation}
                open={visible}
                title={`${formatMessage({ id: 'order_docs.add_income_services' })} ${
                    supplierName && `(${supplierName})`
                }`}
            >
                <FormattedMessage id='order_from_table_number_doc' />
                <Input
                    onChange={event => {
                        this.setState({ supplierDocNumber: event.target.value });
                    }}
                    placeholder={formatMessage({ id: 'order_from_table_number_doc_enter' })}
                    style={{
                        marginTop: 8,
                        marginBottom: 8
                    }}
                    value={supplierDocNumber}
                />
                <FormattedMessage id='order_from_table_date_doc' />
                <DatePicker
                    disabled={loading}
                    format='dddd, DD MMM YYYY'
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={datetime => {
                        this.setState({ datetime });
                    }}
                    placeholder={formatMessage({
                        id: 'order_from_table_date_doc_enter'
                    })}
                    style={{
                        marginTop: 8,
                        marginBottom: 8,
                        width: '100%',
                        textTransform: 'capitalize'
                    }}
                    value={datetime}
                />
                <div>
                    <FormattedMessage id='storage_document.storage_income' />

                    <Select
                        disabled
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={toWarehouseId => {
                            this.setState({ toWarehouseId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({ id: 'storage' })}
                        showSearch
                        style={{
                            marginTop: 8,
                            marginBottom: 8,
                            width: '100%',
                            textTransform: 'capitalize'
                        }}
                        value={toWarehouseId}
                    >
                        {warehouses.map(elem => {
                            return (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.name}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
                <div>
                    <FormattedMessage id='orders-filter.analytics' />

                    <Select
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={value => {
                            this.setState({ analyticsId: value });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({ id: 'orders-filter.analytics' })}
                        showSearch
                        style={{
                            marginTop: 8,
                            marginBottom: 8,
                            width: '100%',
                            textTransform: 'capitalize'
                        }}
                        value={analyticsId}
                    >
                        {analytics.map(({ analyticsUniqueId, analyticsName }) => (
                            <Option key={analyticsUniqueId} value={analyticsUniqueId}>
                                {analyticsName}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <FormattedMessage id='storage_document.business_supplier_requisites' />
                    <Select
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={requisiteSupplierId => {
                            this.setState({ requisiteSupplierId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'storage_document.business_supplier_requisites'
                        })}
                        value={requisiteSupplierId}
                    >
                        {requisites.map(elem => {
                            return (
                                <Option key={elem.id} value={elem.id}>
                                    {elem.name}
                                    {elem.isTaxPayer && (
                                        <span
                                            style={{
                                                marginLeft: 8,
                                                color: 'var(--text2)'
                                            }}
                                        >
                                            (<FormattedMessage id='with_VAT' />)
                                        </span>
                                    )}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
            </Modal>
        );
    }
}
