import { AvailabilityIndicator } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    order: '10%',
    datetime: '10%',
    labor: '10%',
    type: '10%',
    storeGroupName: '10%',
    mechanic: '10%',
    normHours: '10%',
    price: '10%',
    count: '5%',
    sum: '10%',
    actions: '5%'
};

export function columnsConfig(props) {
    const codeCol = {
        title: <FormattedMessage id='order_form_table.detail_code' />,
        key: 'code',
        dataIndex: 'partNumber',
        width: '10%',
        sorter: (a, b) => a.partNumber.localeCompare(b.partNumber),
        sortDirections: ['descend', 'ascend']
    };

    const supplierCodeCol = {
        title: <FormattedMessage id='order_form_table.supplier_code' />,
        key: 'supplierCode',
        dataIndex: 'supplierPartNumber',
        width: '10%'
    };

    const itemNameCol = {
        title: <FormattedMessage id='order_form_table.detail_name' />,
        key: 'name',
        dataIndex: 'itemName',
        width: '15%'
    };

    const brandCol = {
        title: <FormattedMessage id='order_form_table.brand' />,
        key: 'brand',
        dataIndex: 'brandName',
        width: '10%',
        sorter: (a, b) => a.brandName.localeCompare(b.brandName),
        sortDirections: ['descend', 'ascend']
    };

    const supplierCol = {
        title: <FormattedMessage id='order_form_table.supplier' />,
        key: 'supplier',
        dataIndex: 'businessSupplierName',
        width: '10%'
    };

    const dateCol = {
        title: <FormattedMessage id='date' />,
        key: 'date',
        dataIndex: 'pricelistDate',
        width: '10%',
        render: date => `${dayjs(date).format('YYYY-MM-DD')}`
    };

    const purchasePriceCol = {
        title: <FormattedMessage id='order_form_table.purchasePrice' />,
        key: 'purchasePrice',
        dataIndex: 'purchasePrice',
        width: '10%',
        render: data => {
            const strVal = String(Math.round(data * 10) / 10);

            return data ? (
                <span style={{ whiteSpace: 'nowrap' }}>{`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
            ) : (
                <FormattedMessage id='long_dash' />
            );
        }
    };

    const priceCol = {
        title: (
            <div>
                <FormattedMessage id='order_form_table.price' />
                <p
                    style={{
                        color: 'var(--text2)',
                        fontSize: 12,
                        fontWeight: 400
                    }}
                >
                    <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                </p>
            </div>
        ),
        key: 'price',
        width: '10%',
        sorter: (a, b) => Number(a.purchasePrice) * Number(a.markup) - Number(b.purchasePrice) * Number(b.markup),
        sortDirections: ['descend', 'ascend'],
        render: elem => {
            const price = Number(elem.purchasePrice) * Number(elem.markup);
            const strVal = String(Math.round(price * 10) / 10);

            return price ? (
                <span style={{ whiteSpace: 'nowrap' }}>{`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
            ) : (
                <FormattedMessage id='long_dash' />
            );
        }
    };

    const storeCol = {
        title: <FormattedMessage id='order_form_table.AI' />,
        key: 'store',
        width: '10%',
        sorter: (a, b) => Number(a.availableIn0) - Number(b.availableIn0),
        sortDirections: ['descend', 'ascend'],
        render: elem => {
            return (
                <AvailabilityIndicator
                    disabled={props.supplierTabDisabled}
                    indexArray={[elem.availableIn0, elem.availableIn1, elem.availableIn2, elem.availableIn3]}
                />
            );
        }
    };

    return [
        codeCol,
        supplierCodeCol,
        itemNameCol,
        brandCol,
        supplierCol,
        dateCol,
        purchasePriceCol,
        priceCol,
        storeCol
    ];
}
