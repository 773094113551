import { Table } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    selectFetchingVehicleOrders,
    selectVehicleOrders,
    selectVehicleOrdersSort,
    selectVehicleOrdersStats,
    setPageOrders
} from '../../redux/duck';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    orders: selectVehicleOrders(state),
    stats: selectVehicleOrdersStats(state),
    sort: selectVehicleOrdersSort(state),
    fetchingVehicleOrders: selectFetchingVehicleOrders(state)
});

const mapDispatchToProps = {
    setPageOrders
};

/**
 * This table contains all vehicle's orders.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleOrdersTable extends React.Component {
    render() {
        const {
            orders,
            stats,
            setPageOrders,
            sort,
            fetchingVehicleOrders,
            intl: { formatMessage }
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'small',
            total: Math.ceil(stats.countOrders / 25) * 25,
            current: sort.page,
            onChange: page => {
                setPageOrders({ page });
            }
        };

        return (
            <div className={Styles.tableCont}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig({ formatMessage })}
                    dataSource={orders}
                    loading={fetchingVehicleOrders}
                    pagination={pagination}
                    rowClassName={() => Styles.tableRow}
                    // scroll={{ x: 1000, y:   }}
                    width='1200px'
                />
            </div>
        );
    }
}
