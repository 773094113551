import { Input } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

const DebouncedInput = ({ setState, placeholder = 'Your placeholder', ...restProps }) => {
    const [value, setValue] = useState('');

    const handleFilterChange = e => setValue(e.target.value);

    const debouncedValueChange = useMemo(
        () =>
            debounce(newValue => {
                setState(newValue);
            }, 300),
        [setState]
    );

    useEffect(() => debouncedValueChange(value), [debouncedValueChange, value]);

    return <Input {...restProps} onChange={handleFilterChange} placeholder={placeholder} value={value} />;
};

export default DebouncedInput;
