import { Menu, Select, Table, TreeSelect, notification } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import HamburgerMenu from 'components/HamburgerMenu';
import { setModal } from 'core/modals/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import {
    deleteProduct,
    fetchLinks,
    fetchProducts,
    fetchUnits,
    selectProductsLoading,
    selectStoreProducts,
    selectStoreProductsFilters,
    selectUnits,
    setStoreProductsFilters,
    setStoreProductsPage
} from 'core/storage/products';
import { fetchStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import { setDocumentTitle, setPrint } from 'core/ui/duck';
import _, { pick } from 'lodash';
import { PreviewMediaFilesModal } from 'modals';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildStoreGroupsTree, fetchAPI, filterTreeNodeByPart, usePrevious } from 'utils';
import { decompressTemplate } from '../../../../../shared/utils/utils';
import columnsConfig from './config';

const ProductsTable = props => {
    const {
        products,
        priceGroups,
        units,
        storeGroups,
        setPrint,
        setDocumentTitle,
        intl: { formatMessage },
        isMobile
    } = props;

    const prevValues = usePrevious(products);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [storeGroupsTree, setStoreGroupsTree] = useState([]);
    const [mediaFilesRow, setMediaFilesRow] = useState();

    const [treeState, setTreeState] = useState();

    useEffect(() => {
        !priceGroups.length && props.fetchPriceGroups();
        !units.length && props.fetchUnits();
        !storeGroups.length && props.fetchStoreGroups();
    }, []);

    useEffect(() => {
        if (!_.isEqual(products, prevValues)) {
            props.fetchProducts({ showAll: true });
        }
    }, [products]);

    useEffect(() => {
        if (!storeGroupsTree.length && storeGroups && storeGroups.length) {
            const tree = buildStoreGroupsTree(storeGroups);
            setStoreGroupsTree(tree);
        }
    }, [storeGroups, storeGroupsTree]);

    const downloadProductReport = async (id, tp) => {
        const url = `/reports/store_products/${id}`;
        try {
            const response = await fetchAPI(
                'GET',
                url,
                {
                    type: tp
                },
                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true
                }
            );
            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; fileName="(.*)"/)[1];
            setDocumentTitle(fileName);
            const arrayBuffer = await response.arrayBuffer();
            const content = Buffer.isBuffer(arrayBuffer) ? arrayBuffer : Buffer.from(arrayBuffer);
            const reportFile = await decompressTemplate(content);
            setPrint(reportFile);
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    const handleGroupChange = async (data, change) => {
        const dataToUpdate = data.map(row => ({ ...pick(row, ['id', 'groupId']), ...change }));
        try {
            await fetchAPI('PUT', 'grouped/store_products', undefined, dataToUpdate, { handleErrorInternally: true });
            notification.success({ message: formatMessage({ id: 'barcode.success' }) });
        } catch (err) {
            notification.error({ message: formatMessage({ id: 'error' }) });
        }
        await props.fetchProducts({ showAll: true });
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    const actionsMenu = useMemo(
        () => (
            <Menu>
                <SubMenu
                    key='product.markup'
                    disabled={!selectedRows.length}
                    title={<FormattedMessage id='product.markup' />}
                >
                    {priceGroups.map(({ number, multiplier }) => (
                        <Menu.Item
                            key={number}
                            onClick={() => handleGroupChange(selectedRows, { priceGroupNumber: number })}
                        >
                            {formatMessage({ id: 'storage.price_group' })} - {number} (
                            {formatMessage({ id: 'storage.markup' })} - {multiplier})
                        </Menu.Item>
                    ))}
                </SubMenu>
                <SubMenu
                    disabled={!selectedRows.length}
                    key='product.changeGroup'
                    // onClick={e => {
                    //     throw new Error();
                    // }}
                    title={<FormattedMessage id='product.change_product_group' />}
                    trigger='click'
                >
                    <Menu.Item key='product.change_product_group'>
                        <TreeSelect
                            filterTreeNode={filterTreeNodeByPart}
                            // onBlur={() => setTreeState(undefined)}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            onSelect={newValue => {
                                handleGroupChange(selectedRows, { groupId: newValue });
                            }}
                            placeholder={formatMessage({
                                id: 'storage.product_group'
                            })}
                            popupMatchSelectWidth={false}
                            showSearch
                            style={{
                                minWidth: 450,
                                color: 'var(--text)'
                            }}
                            treeData={storeGroupsTree}
                            // value={treeState}
                        />
                    </Menu.Item>
                </SubMenu>

                <Menu.Item
                    key='product.enable_product'
                    disabled={!selectedRows.length}
                    onClick={() => handleGroupChange(selectedRows, { enabled: true })}
                >
                    <FormattedMessage id='product.enable_product' />
                </Menu.Item>
                <Menu.Item
                    key='product.disable_product'
                    disabled={
                        !selectedRows.length ||
                        selectedRows
                            .map(({ countInWarehouses, available }) => countInWarehouses === 0 && available === 0)
                            .includes(false)
                    }
                    onClick={() => handleGroupChange(selectedRows, { enabled: false })}
                >
                    <FormattedMessage id='product.disable_product' />
                </Menu.Item>
                <SubMenu
                    disabled={!selectedRows.length}
                    key='product.change_measure_unit'
                    // onClick={e => {
                    //     throw new Error();
                    // }}
                    title={<FormattedMessage id='product.change_measure_unit' />}
                    trigger='click'
                >
                    <Menu.Item key='product.change_measure_unit_item'>
                        <Select
                            fieldNames={{ label: 'name', value: 'id' }}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            onSelect={newValue => handleGroupChange(selectedRows, { productUnitId: newValue })}
                            optionFilterProp='children'
                            options={units}
                            placeholder={formatMessage({
                                id: 'storage.product_group'
                            })}
                            showSearch
                            // showSearchField
                            style={{
                                minWidth: 120,
                                color: 'var(--text)'
                            }}
                        />
                    </Menu.Item>
                </SubMenu>
            </Menu>
        ),
        [priceGroups, formatMessage, storeGroupsTree, handleGroupChange, selectedRows, units]
    );

    const rowSelection = useMemo(() => {
        return {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
                setSelectedRowKeys(selectedRowKeys);
            },
            columnTitle: (
                <HamburgerMenu
                    actionsMenu={actionsMenu}
                    destroyPopupOnHide
                    // dropdownProps={{ getPopupContainer: trigger => trigger.parentNode }}
                />
            )
        };
    }, [actionsMenu, selectedRowKeys, selectedRows]);

    const pagination = {
        pageSize: props.filters.pageSize,
        size: 'large',
        total: Math.ceil(_.get(products, 'stats.count', 0) / props.filters.pageSize) * props.filters.pageSize,
        current: props.filters.page,
        onChange: async (page, pageSize) => {
            props.setStoreProductsPage({ page, pageSize });
            await props.fetchProducts({ page, pageSize, showAll: true });
        }
    };

    return (
        <React.Fragment>
            <Table
                bordered
                columns={columnsConfig({ ...props, handleGroupChange, downloadProductReport, setMediaFilesRow })}
                dataSource={props.products.list}
                loading={props.loading}
                locale={{
                    emptyText: props.intl.formatMessage({ id: 'no_data' })
                }}
                pagination={pagination}
                rowKey={record => record.id}
                rowSelection={rowSelection}
                scroll={isMobile ? { x: 1000 } : {}}
            />
            <PreviewMediaFilesModal
                hideModal={() => setMediaFilesRow(false)}
                open={Boolean(mediaFilesRow)}
                prdId={mediaFilesRow ? mediaFilesRow.id : undefined}
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    priceGroups: selectPriceGroups(state),
    storeGroups: selectStoreGroups(state),
    user: state.auth,
    products: selectStoreProducts(state),
    filters: selectStoreProductsFilters(state),
    loading: selectProductsLoading(state),
    units: selectUnits(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchProducts,
    setModal,
    fetchUnits,
    fetchStoreGroups,
    deleteProduct,
    setStoreProductsPage,
    setStoreProductsFilters,
    fetchLinks,
    fetchPriceGroups,
    setPrint,
    setDocumentTitle
};

export const StoreProductsTable = injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductsTable));
