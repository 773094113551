/**
 * This table component is used to show client calls, it takes data from existing redux-saga core(calls).
 */

import { Table } from 'antd';
import { Catcher, Loader } from 'commons';
import {
    fetchCalls,
    fetchRecordingLink,
    selectCallsData,
    selectCallsFilter,
    selectCallsLinksCache,
    selectCallsStats,
    setCallsPageFilter
} from 'core/calls/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config.js';
import Styles from './styles.m.css';

const mapStateToProps = state => {
    return {
        user: state.auth,
        calls: selectCallsData(state),
        stats: selectCallsStats(state),
        filter: { ...selectCallsFilter(state) },
        callsLinksCache: selectCallsLinksCache(state),
        callsFetching: state.ui.callsFetching
    };
};

const mapDispatchToProps = {
    fetchCalls,
    setCallsPageFilter,
    fetchRecordingLink
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CallsTable extends Component {
    render() {
        const {
            calls,
            stats,
            filter,
            intl: { formatMessage },
            callsFetching,
            fetchRecordingLink,
            callsLinksCache,
            user
        } = this.props;

        const columns = columnsConfig({
            formatMessage,
            fetchRecordingLink,
            callsLinksCache,
            user
        });

        const pagination = {
            pageSize: 25,
            size: 'small',
            total: Math.ceil(_.get(stats, 'total') / 25) * 25,
            current: filter.page,
            onChange: page => {
                this.props.setCallsPageFilter(page);
                this.props.fetchCalls();
            }
        };

        return callsFetching ? (
            <Loader loading={callsFetching} />
        ) : (
            <Catcher>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columns}
                    dataSource={calls}
                    loading={callsFetching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={pagination}
                    rowKey={() => v4()}
                    scroll={{ x: 1080 }}
                    size='small'
                />
            </Catcher>
        );
    }
}
