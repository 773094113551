exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__editableCell---25dPV{position:relative}.styles-m__editableCellValueWrap---1XvUK{padding:5px 12px;cursor:pointer}.styles-m__editableRow---FyC6R:hover .styles-m__editableCellValueWrap---1XvUK{border:1px solid #d9d9d9;border-radius:4px;padding:4px 11px}.styles-m__editableRow---FyC6R.ant-table-row-level-0 td:not(:first-child){visibility:hidden}.styles-m__detailsSelect---1gL9z{width:300px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,gCACI,iBACJ,CAEA,yCACI,gBAAiB,CACjB,cACJ,CAEA,8EACI,wBAAyB,CACzB,iBAAkB,CAClB,gBACJ,CAEA,0EACI,iBACJ,CAEA,iCACI,WACJ","file":"styles.m.css","sourcesContent":[".editableCell {\n    position: relative;\n}\n\n.editableCellValueWrap {\n    padding: 5px 12px;\n    cursor: pointer;\n}\n\n.editableRow:hover .editableCellValueWrap {\n    border: 1px solid #d9d9d9;\n    border-radius: 4px;\n    padding: 4px 11px;\n}\n\n.editableRow:global(.ant-table-row-level-0 td:not(:first-child)) {\n    visibility: hidden;\n}\n\n.detailsSelect {\n    width: 300px;\n}\n"]}]);

// Exports
exports.locals = {
	"editableCell": "styles-m__editableCell---25dPV",
	"editableCellValueWrap": "styles-m__editableCellValueWrap---1XvUK",
	"editableRow": "styles-m__editableRow---FyC6R",
	"detailsSelect": "styles-m__detailsSelect---1gL9z"
};