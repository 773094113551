import { Select } from 'antd';
import _ from 'lodash';
import { STORAGE_DOCUMENT_OPERATION_CODES } from 'modals/DebtZeroingModal/constants';
import { selectSuppliersStoreDocsFilters, setSupplierStoreDocOperationCode } from 'modals/DebtZeroingModal/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    suppliersStoreDocsFilters: selectSuppliersStoreDocsFilters(state)
});

const mapDispatchToProps = {
    setSupplierStoreDocOperationCode
};

const { OUT, ICO, CRT, SRT, INC, SRV } = STORAGE_DOCUMENT_OPERATION_CODES;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocTypeSelector extends React.Component {
    render() {
        const {
            suppliersStoreDocsFilters,

            setSupplierStoreDocOperationCode,

            intl: { formatMessage }
        } = this.props;

        const selectedOperationCode =
            suppliersStoreDocsFilters.operationCodes.length == 1
                ? _.get(suppliersStoreDocsFilters, 'operationCodes[0]') // Get one selected operation code
                : undefined;

        return (
            <div title={formatMessage({ id: 'debt_zeroing_modal.store_doc_type' })}>
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={code => setSupplierStoreDocOperationCode(code)}
                    placeholder={formatMessage({ id: 'debt_zeroing_modal.store_doc_type' })}
                    value={selectedOperationCode}
                >
                    {_.map([OUT, ICO, CRT, SRT, INC, SRV], type => {
                        return (
                            <Option key={type} value={type}>
                                {type}
                            </Option>
                        );
                    })}
                </Select>
            </div>
        );
    }
}
