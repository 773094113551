import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { RequisiteSettingFormModal } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});
@injectIntl
@connect(mapStateToProps)
export default class RequisiteSettingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            dataSource: []
        };

        this.columns = [
            {
                title: '№',
                key: 'key',
                dataIndex: 'key',
                render: (key, row, index) => {
                    return index + 1;
                }
            },
            {
                title: <FormattedMessage id='requisite-setting.form' />,
                key: 'formType',
                dataIndex: 'formType',
                render: (formType, elem) => {
                    return formType ? <FormattedMessage id={`requisite-setting.form.${formType}`} /> : elem.formName;
                }
            },
            {
                title: <FormattedMessage id='requisite-setting.name' />,
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: (
                    <p>
                        <FormattedMessage id='requisite-setting.code' /> <FormattedMessage id='USREOU' />
                    </p>
                ),
                key: 'ifi',
                dataIndex: 'ifi'
            },
            {
                title: (
                    <p>
                        <FormattedMessage id='requisite-setting.payer' /> <FormattedMessage id='VAT' />
                    </p>
                ),
                key: 'isTaxPayer',
                dataIndex: 'isTaxPayer',
                render: isTaxPayer => {
                    return isTaxPayer ? <CheckOutlined /> : <CloseOutlined />;
                }
            },
            {
                key: 'actions',
                render: elem => {
                    const enabledAccess =
                        isGrantAccessed(this.props.user, grants.DIRECTORIES_SUPPLIERS_LIST_REQUISITES, accesses.RA) ||
                        isGrantAccessed(this.props.user, grants.DIRECTORIES_CUSTOMERS_LIST_REQUISITES, accesses.RA) ||
                        isGrantAccessed(this.props.user, grants.SETTINGS_REQUISITES, accesses.RA);

                    return (
                        <div style={{ textAlign: 'end' }}>
                            <Button disabled={!enabledAccess} onClick={() => this.props.showModal(elem)}>
                                <EditOutlined />
                            </Button>
                            <Button
                                disabled={elem.used || this.props.disabled || !enabledAccess}
                                onClick={async () => {
                                    await this.props.deleteRequisite(elem.id);
                                    this.props.updateDataSource();
                                }}
                            >
                                <DeleteOutlined style={{ color: 'var(--danger)' }} />
                            </Button>
                        </div>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.setState({
            fetched: true
        });
    }

    render() {
        const {
            modalVisible,
            showModal,
            hideModal,
            requisiteData,
            dataSource,
            postRequisite,
            updateRequisite,
            id,
            loading,
            isMobile,
            disabled,
            clientReq,
            supplierReq,
            tree,
            user
        } = this.props;
        const { fetched } = this.state;

        const enabledAccess =
            isGrantAccessed(user, grants.DIRECTORIES_SUPPLIERS_LIST_REQUISITES, accesses.ROWO) ||
            isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_REQUISITES, accesses.ROWO) ||
            isGrantAccessed(user, grants.SETTINGS_REQUISITES, accesses.ROWO);

        return (
            <div>
                <Table
                    bordered
                    columns={isMobile ? this.columns.slice(1, -1) : this.columns}
                    dataSource={dataSource || []}
                    loading={!dataSource || loading}
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: event => {
                                if (!disabled) showModal(record);
                            }
                        };
                    }}
                    rowKey={record => `${record.name}-${record.ifi}-${record.formName}`}
                    size='small'
                />
                <RequisiteSettingFormModal
                    clientReq={clientReq}
                    disabled={disabled || !enabledAccess}
                    hideModal={hideModal}
                    id={id}
                    modalVisible={modalVisible}
                    postRequisite={postRequisite}
                    requisiteData={requisiteData}
                    supplierReq={supplierReq}
                    tree={tree}
                    updateRequisite={updateRequisite}
                />
            </div>
        );
    }
}
