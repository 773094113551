import { emitError, setReportOrdersFetching } from 'core/ui/duck';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    fetchExcelFileReportSuccess,
    fetchReportOrdersFilterOptionsSuccess,
    fetchReportOrdersSuccess,
    FETCH_EXCEL_FILE_REPORT,
    FETCH_REPORT_ORDERS,
    FETCH_REPORT_ORDERS_FILTER_OPTIONS
} from './duck';

const selectFilter = ({ reportOrders: { filter, options, exportOptions } }) => ({
    filter,
    options,
    exportOptions
});

export function* fetchReportOrdersSaga() {
    while (true) {
        try {
            yield take(FETCH_REPORT_ORDERS);
            yield put(setReportOrdersFetching(true));

            const { filter, options } = yield select(selectFilter);

            const data = yield call(
                fetchAPI,
                'GET',
                '/report/orders',
                {
                    filters: { ...filter },
                    options
                },
                null,
                { handleErrorInternally: true }
            );
            yield put(fetchReportOrdersSuccess(data));
        } finally {
            yield put(setReportOrdersFetching(false));
        }
    }
}

export function* fetchReportOrdersFilterOptionsSaga() {
    while (true) {
        try {
            yield take(FETCH_REPORT_ORDERS_FILTER_OPTIONS);

            const data = yield call(fetchAPI, 'GET', '/orders/form');
            yield put(fetchReportOrdersFilterOptionsSuccess(data));
        } finally {
            yield put(setReportOrdersFetching(false));
        }
    }
}

export function* fetchExcelFileReportSaga() {
    while (true) {
        try {
            yield take(FETCH_EXCEL_FILE_REPORT);

            yield nprogress.start();

            const { filter, options, exportOptions } = yield select(selectFilter);
            const filters = { ...filter };
            const finalOptions = { ...options, ...exportOptions };

            const response = yield call(
                fetchAPI,
                'GET',
                '/report/orders/file',
                { filters: _.omit(filters, ['pageSize']), options: { ...finalOptions } },
                null,
                {
                    rawResponse: true
                }
            );

            const reportFile = yield response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];

            yield saveAs(reportFile, fileName);
            yield put(fetchExcelFileReportSuccess());
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([
        call(fetchReportOrdersSaga),
        call(fetchReportOrdersFilterOptionsSaga),
        call(fetchExcelFileReportSaga)
    ]);
}
