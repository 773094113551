import { PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal, Select, TimePicker } from 'antd';
import { loadModal, MODALS, resetModal, saveModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { AddClientModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const getDisabledHours = (startTime = 0, endTime = 23) => {
    const availableHours = [];
    for (let i = Number(startTime); i <= Number(endTime); i++) {
        availableHours.push(i);
    }

    return _.difference(
        Array(24)
            .fill(1)
            .map((value, index) => index),
        availableHours
    );
};

const mapStateToProps = state => {
    return {
        modal: state.modals.modal,
        user: state.auth,
        modalProps: state.modals.modalProps
    };
};

const mapDispatch = {
    resetModal,
    setModal,
    saveModal,
    loadModal
};

@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatch)
export default class AddOrderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            clientSearchValue: '',
            stations: [],
            date: dayjs(),
            time: dayjs().startOf('hour')
        };
    }

    fetchData = async () => {
        const { clients } = await fetchAPI('GET', 'clients');
        const { schedule, stations } = await fetchAPI('GET', 'dashboard/orders', {
            beginDate: '2020-01-01',
            exclude: '["orders", "days", "daysWithConflicts", "load"]'
        });
        this.setState({
            clients,
            stations,
            schedule
        });
    };

    handleOk = async () => {
        const { clientId, clientPhone, station, date, time, vehicle } = this.state;
        const dayPart = dayjs(date).format('YYYY-MM-DD');
        const hourPart = dayjs(time).utc().format('HH:mm');
        const beginDatetime =
            dayPart && hourPart && dayjs(`${dayPart}T${hourPart}:00.000Z`).toISOString();
        const order = await fetchAPI(
            'POST',
            'orders',
            undefined,
            {
                managerId: this.props.user.id,
                clientId,
                clientPhone,
                clientVehicleId: _.get(vehicle, 'id'),
                clientVehicleTypeId: _.get(vehicle, 'vehicleTypeId'),
                clientVehicleRadius: _.get(vehicle, 'wheelRadius'),
                duration: 0.5,
                stationLoads: [
                    {
                        stationNum: station,
                        beginDatetime,
                        duration: 0.5,
                        status: 'TO_DO'
                    }
                ],
                stationNum: station,
                beginDatetime,
                status: 'not_complete'
            },
            { handleErrorInternally: true }
        );
        this.props.history.push(`${book.order}/${order.created[0].id}`);
        this.handleCancel();
    };

    handleCancel = () => {
        this.props.hideModal();
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const { visible } = this.props;
        if (typeof visible === 'object' && Boolean(prevProps.visible) != Boolean(visible)) {
            const { day, time, stationNum } = visible;
            this.setState({
                date: day ? dayjs(day) : dayjs(),
                time: time || dayjs().startOf('hour'),
                station: stationNum || undefined
            });
        }
    }

    render() {
        const {
            clients,
            clientSearchValue,
            schedule,
            stations,
            clientId,
            station,
            date,
            time,
            clientOption
        } = this.state;
        const { modal, resetModal, setModal, modalProps, isMobile, hideModal, visible } =
            this.props;

        return (
            <React.Fragment>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    footer={null}
                    onCancel={() => hideModal()}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='add_order' />}
                    visible={Boolean(visible)}
                >
                    <div className={Styles.fieldWrap}>
                        <FormattedMessage id='storage.client' />
                        <div style={{ display: 'flex' }}>
                            <Select
                                allowClear
                                filterOption={(input, option) => {
                                    const searchValue = String(option.props.children)
                                        .toLowerCase()
                                        .replace(/[+()-\s]/g, '');
                                    const inputValue = input.toLowerCase();

                                    return searchValue.indexOf(inputValue) >= 0;
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                                onBlur={() => {
                                    this.setState({
                                        clientSearchValue: ''
                                    });
                                }}
                                onChange={(clientId, option) => {
                                    if (clientId) {
                                        this.setState({
                                            clientId,
                                            clientPhone: option.props.phone,
                                            vehicle: option.props.vehicle
                                        });
                                    } else {
                                        this.setState({
                                            clientId: undefined,
                                            clientPhone: undefined,
                                            vehicle: undefined,
                                            clientOption: undefined
                                        });
                                    }
                                }}
                                onSearch={input => {
                                    this.setState({
                                        clientSearchValue: input
                                    });
                                }}
                                showSearch
                                style={{ width: '100%' }}
                                value={clientId}
                            >
                                {clientSearchValue.length > 2
                                    ? clients.map(client => {
                                          return (
                                              <Option
                                                  key={client.clientId}
                                                  phone={client.phones[0]}
                                                  value={client.clientId}
                                                  vehicle={_.get(client, 'vehicles[0]')}
                                              >
                                                  {`${client.surname || ''} ${client.name} ${
                                                      client.middleName || ''
                                                  } ${client.phones[0]}`}
                                              </Option>
                                          );
                                      })
                                    : clientOption || []}
                            </Select>
                            {!clientId && (
                                <Button
                                    onClick={() => {
                                        setModal(MODALS.ADD_CLIENT, {});
                                    }}
                                    style={{ marginLeft: 4 }}
                                    type='primary'
                                >
                                    <PlusOutlined />
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className={Styles.fieldWrap}>
                        <FormattedMessage id='orders.post' />
                        <div>
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={station => {
                                    this.setState({ station });
                                }}
                                optionFilterProp='children'
                                showSearch
                                style={{ width: '100%' }}
                                value={station}
                            >
                                {stations.map(st => {
                                    return (
                                        <Option key={st.num} value={st.num}>
                                            {st.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className={Styles.fieldWrap}>
                        <FormattedMessage id='date' />
                        <div>
                            <DatePicker
                                defaultValue={visible.day ? dayjs(visible.day) : dayjs()}
                                getCalendarContainer={trigger => trigger.parentNode}
                                onChange={date => {
                                    this.setState({ date });
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <div className={Styles.fieldWrap}>
                        <FormattedMessage id='time' />
                        <div>
                            <TimePicker
                                defaultOpenValue={dayjs('09:00', 'HH:mm')}
                                defaultValue={
                                    visible.time ? dayjs(visible.time) : dayjs().startOf('hour')
                                }
                                disabledHours={() => {
                                    getDisabledHours(schedule.beginHour, schedule.endHour);
                                }}
                                format='HH:mm'
                                getPopupContainer={trigger => trigger.parentNode}
                                hideDisabledOptions
                                inputReadOnly
                                minuteStep={30}
                                onChange={time => {
                                    this.setState({ time });
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <Button onClick={this.handleOk} style={{ width: '100%' }} type='primary'>
                        <FormattedMessage id='save' />
                    </Button>
                </Modal>
                <AddClientModal
                    func={async clientId => {
                        const { clients } = await fetchAPI('GET', 'clients');
                        const client = clients.find(client => client.clientId == clientId);
                        if (client) {
                            this.setState({
                                clients,
                                clientId,
                                clientPhone: client.phones[0],
                                vehicle: _.get(client, 'vehicles[0]'),
                                clientOption: (
                                    <Option
                                        key={client.clientId}
                                        phone={client.phones[0]}
                                        value={client.clientId}
                                        vehicle={_.get(client, 'vehicles[0]')}
                                    >
                                        {`${client.surname || ''} ${client.name} ${
                                            client.middleName || ''
                                        } ${client.phones[0]}`}
                                    </Option>
                                )
                            });
                        }
                    }}
                    isMobile={isMobile}
                    resetModal={() => {
                        resetModal();
                    }}
                    visible={modal}
                />
            </React.Fragment>
        );
    }
}
