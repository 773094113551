import { DeleteOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip, notification } from 'antd';
import { Select } from 'antd/lib';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { AccTemplateRowsTable, AddAccTemplateModal } from './modals';

const dateFormat = 'DD.MM.YYYY';
const { Option } = Select;

const AccTemplatesContainer = ({ intl, treeDataQuery, accounts, analytics, user }) => {
    const [templates, setTemplates] = useState([]);

    const [edit, setEdit] = useState(undefined);
    const [templateRows, setTemplateRows] = useState([]);
    const [open, openAddModal] = useState(false);
    const [openRowsTable, openRowsTableModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingRows, setLoadingRows] = useState(false);
    const [tree, setTree] = useState([]);
    const [filters, setFilters] = useState({ counterpartType: undefined });
    const [requisites, setBusinessesRequisites] = useState([]);

    const fetchAccTemplates = useCallback(async () => {
        setLoading(true);
        const response = await fetchAPI('GET', '/general_ledger/acc_document_templates', null, null, {
            handleErrorInternally: true
        });

        setTemplates(response);
        setLoading(false);
    }, []);

    const fetchBusinessesRequisites = useCallback(async () => {
        const response = await fetchAPI('GET', '/businesses/requisites', null, null, {
            handleErrorInternally: true
        });

        setBusinessesRequisites(response);
    }, []);

    const filterTreeByActive = useCallback(nodes => {
        return nodes.reduce((filteredNodes, node) => {
            if (node.active === true) {
                filteredNodes.push({
                    value: node.id,
                    title: `(#${node.id}) ${node.name} `,
                    disabled: node.templates === false
                });

                if (node.children) {
                    const filteredChildren = filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNodes[filteredNodes.length - 1].children = filteredChildren;
                    }
                }
            }

            setTree(filteredNodes);

            return filteredNodes;
        }, []);
    });

    useEffect(() => {
        filterTreeByActive(treeDataQuery);
    }, [treeDataQuery]);

    useEffect(() => {
        fetchAccTemplates();
    }, [fetchAccTemplates, fetchBusinessesRequisites]);

    const handleSearch = async confirm => {
        confirm();
        fetchAccTemplates();
    };
    const handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        const tmp = filters;

        delete tmp[dataIndex];

        await setFilters(tmp);
        fetchAccTemplates();
    };

    const handleSearchEnries = _.debounce((value, accountId) => {
        fetchAccTemplates(value, accountId);
    }, 500);

    const updateList = async rows => {
        await setTemplateRows(rows);
    };

    // const getColumnSearchProps = useCallback(
    //     dataIndex => {
    //         let filterComponent = (confirm, clearFilters) => (
    //             <Input
    //                 onChange={e => {}}
    //                 onPressEnter={() => handleSearch(confirm, dataIndex)}
    //                 placeholder={intl.formatMessage({
    //                     id: 'search'
    //                 })}
    //                 style={{ marginBottom: 8, display: 'block', width: 180 }}
    //             />
    //         );

    //         if (dataIndex === 'counterpartyType') {
    //             filterComponent = (confirm, clearFilters) => (
    //                 <Select
    //                     allowClear
    //                     onChange={value => {
    //                         setFilters({ ...filters, [dataIndex]: value });
    //                         handleSearchEnries(value)
    //                     }}
    //                     showSearch
    //                     style={{ marginBottom: 8, display: 'block', width: 180 }}
    //                     value={filters[dataIndex]}
    //                     optionFilterProp='children'
    //                     // mode='multiple'
    //                     placeholder={intl.formatMessage({
    //                         id: 'search'
    //                     })}
    //                 >
    //                     <Option key='CUSTOMER' value='CUSTOMER'>
    //                         <FormattedMessage id='task_page.client' />
    //                     </Option>
    //                     <Option key='EMPLOYEE' value='EMPLOYEE'>
    //                         <FormattedMessage id='employee' />
    //                     </Option>
    //                     <Option key='SUPPLIER' value='SUPPLIER'>
    //                         <FormattedMessage id='suppliers_page.supplier' />
    //                     </Option>
    //                     <Option key='OTHER' value='OTHER'>
    //                         <FormattedMessage id='OTHER' />
    //                     </Option>
    //                 </Select>
    //             );
    //         }

    //         return {
    //             filterDropdown: ({ confirm, clearFilters }) => (
    //                 <div style={{ padding: 8 }}>
    //                     {filterComponent(confirm, clearFilters)}
    //                     {dataIndex !== '11' && dataIndex !== 'filterCreatedDate' && dataIndex !== 'filtertDoneDate' && (
    //                         <div
    //                             style={{
    //                                 display: 'flex',
    //                                 justifyContent: 'space-around'
    //                             }}
    //                         >
    //                             <Button onClick={() => handleReset(confirm, clearFilters, dataIndex)} size='small'>
    //                                 <FormattedMessage id='reset' />
    //                             </Button>
    //                         </div>
    //                     )}
    //                 </div>
    //             ),
    //             filterIcon: () => {
    //                 let colorFilter;
    //                 if (filters[dataIndex]) {
    //                     colorFilter = 'var(--primary)';
    //                 }

    //                 return (
    //                     <FilterFilled
    //                         style={{
    //                             fontSize: 14,
    //                             color: colorFilter
    //                         }}
    //                     />
    //                 );
    //             }
    //             // onFilterDropdownVisibleChange: visible => {
    //             //     if (visible) {
    //             //         setTimeout(() => this.searchInput.select(), 100);
    //             //     }
    //             // }
    //         };
    //     },
    //     [handleReset, handleSearch, intl, filters]
    // );

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='№' />,
                key: 'num',
                width: '3%',
                render: (row, number, index) => {
                    return index + 1;
                }
            },
            {
                key: 'actions',
                width: '8%',
                render: row => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Popconfirm
                                onConfirm={async () => {
                                    try {
                                        await fetchAPI(
                                            'DELETE',
                                            '/general_ledger/acc_document_template',
                                            null,
                                            { ids: [row.id] },
                                            {
                                                handleErrorInternally: true
                                            }
                                        );
                                        await setTimeout(() => fetchAccTemplates(), 100);
                                    } catch (err) {
                                        notification.error({
                                            message: intl.formatMessage({ id: 'entries_tab.error_delete' })
                                        });
                                    }
                                }}
                                title={`${intl.formatMessage({ id: 'delete' })}?`}
                            >
                                <Button icon={<DeleteOutlined />} />
                            </Popconfirm>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    openAddModal(true);
                                    setEdit(row);
                                }}
                            />
                            <Tooltip
                                getPopupContainer={trigger => trigger.parentNode}
                                title={<FormattedMessage id='entries_tab.row_tooltip' />}
                            >
                                <Button
                                    icon={<InfoOutlined />}
                                    onClick={async () => {
                                        await openRowsTableModal(true);
                                        await setEdit(row);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '15%',
                render: row => {
                    return row.name ? row.name : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='storage_journal.counterparty_type' />,
                key: 'counterparty_type',
                width: '10%',
                render: row => {
                    return row.counterpartType === 'SUPPLIERS' ? (
                        <FormattedMessage id='interactions.counterparty_type.SUPPLIER' />
                    ) : row.counterpartType === 'CLIENTS' ? (
                        <FormattedMessage id='interactions.counterparty_type.CLIENT' />
                    ) : (
                        <FormattedMessage id='interactions.counterparty_type.EMPLOYEE' />
                    );
                }
            },
            {
                title: <FormattedMessage id='storage.counterparty' />,
                key: 'counterparty',
                dataIndex: 'counterpartName',
                width: '10%'
            },

            {
                title: <FormattedMessage id='storage' />,
                key: 'storage',
                dataIndex: 'warehouseName',
                width: '10%'
                // ...getColumnSearchProps('startDate'),
            },
            {
                title: <FormattedMessage id='storage_funds.cash_box' />,
                key: 'cashbox',
                dataIndex: 'cashBoxName',
                // ...getColumnSearchProps('startDate'),
                width: '10%'
            },
            {
                title: <FormattedMessage id='storage.operation_type' />,
                key: 'operationType',
                width: '10%',
                render: row => {
                    return row.operationType ? (
                        <FormattedMessage id={`entries_tab.${row.operationType}`} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='storage.responsible' />,
                key: 'responsible',
                // ...getColumnSearchProps('startDate'),
                width: '10%',
                render: row => {
                    return row.managerName ? row.managerName : <FormattedMessage id='long_dash' />;
                }
            }
        ];
    }, [intl, fetchAccTemplates]);

    // const pagination = {
    //     pageSize,
    //     total: Math.ceil(totalCount / pageSize) * pageSize,
    //     hideOnSinglePage: true,
    //     current: page,
    //     onChange: async (page, pageSize) => {
    //         await setPage(page);
    //         await setPageSize(pageSize);
    //
    //     }
    // };

    return (
        <div>
            <div>
                <Table
                    columns={columns}
                    dataSource={templates}
                    loading={loading}
                    pagination={false}
                    rowKey='id'
                    scroll={{ x: 'auto' }}
                    size='small'
                />
                <AddAccTemplateModal
                    edit={edit}
                    fetchAccTemplates={fetchAccTemplates}
                    hideModal={() => {
                        openAddModal(false);
                        setEdit(undefined);
                    }}
                    loading={loading}
                    open={open}
                />
                <AccTemplateRowsTable
                    docData={edit}
                    fetchAccTemplates={fetchAccTemplates}
                    hideModal={() => {
                        openRowsTableModal(false);
                        setTemplateRows([]);
                        fetchAccTemplates();
                    }}
                    loadingRows={loadingRows}
                    open={openRowsTable}
                    rows={templateRows}
                    setTemplateRows={setTemplateRows}
                    templates={templates}
                    tree={tree}
                    updateList={updateList}
                />
            </div>
        </div>
    );
};

export default injectIntl(AccTemplatesContainer);
