import { FolderOpenOutlined, InboxOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Input, InputNumber, Modal, Select, Tooltip, Upload, notification } from 'antd';
import { MODALS } from 'core/modals/duck';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { buildSupplierOptions, fetchAPI } from 'utils';
import { v4 } from 'uuid';
import { TemplatesModal } from './components';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const mapStateToProps = state => ({
    modalProps: state.modals.modalProps
});

const mapDispatchToProps = {};

const NEW = 'NEW';

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ImportReceiptDocumentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visibleLabors: false,
            templates: [],
            fileList: [],
            type: 'xlsx'
        };
        this.columns = [
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                dataIndex: 'brand',
                key: v4()
            },
            {
                title: <FormattedMessage id='partAttributes.detailCode' />,
                dataIndex: 'code',
                key: v4()
            }
        ];
    }

    deleteTemplate = async () => {
        const { templateId } = this.state;
        await fetchAPI('DELETE', `sync/template?id=${templateId}`, null, null, {
            handleErrorInternally: true
        });
        this.setState({
            templateName: undefined,
            documentType: undefined,
            firstRowNum: undefined,
            productCodeCol: undefined,
            brandNameCol: undefined,
            productNameCol: undefined,
            purchasePriceCol: undefined,
            productCountCol: undefined,
            supplierCol: undefined,
            groupIdCol: undefined,
            labourCodeCol: undefined,
            sellPriceCol: undefined,
            supplierId: undefined,
            fileList: [],
            newProducts: [],
            confirmed: undefined,
            templateId: undefined
        });
        this.fetchTemplate();
    };

    postTemplate = async () => {
        const {
            modalProps: { visibleLabors, visibleParts }
        } = this.props;
        const {
            templateName,
            type,
            encoding,
            delimiter,
            separator,
            firstRowNum,
            lastRowNum,

            docType,
            docNum,
            docDate,

            laborCodeCol,
            laborNameCol,
            mechanicCol,

            productCodeCol,
            groupIdCol,
            productSupplierCodeCol,
            brandNameCol,
            productNameCol,
            uktzCol,
            qrCodeCol,
            weightCol,

            countCol,
            purchasePriceCol,
            discountCol,
            priceCol,
            sumCol,
            currencyCol,
            templates,
            supplierId
        } = this.state;
        const template = templates.find(tmpl => tmpl.templateName === templateName);
        if (template && template.businessId) {
            await fetchAPI('DELETE', `sync/template?id=${template.id}`, null, null, {
                handleErrorInternally: true
            });
        }
        try {
            await fetchAPI(
                'POST',
                'sync/template',
                null,
                {
                    templateName,
                    documentType: visibleLabors
                        ? 'Working_Order_Labors'
                        : visibleParts
                        ? 'Working_Order_Parts'
                        : 'Stock_Products',
                    type: type == 'csv' ? 'csv' : 'xlsx',
                    encoding,
                    delimiter,
                    separator,
                    firstRowNum,
                    lastRowNum,

                    laborCodeCol,
                    laborNameCol,
                    mechanicCol: mechanicCol || undefined,

                    productCodeCol,
                    groupIdCol,
                    productSupplierCodeCol,
                    brandNameCol,
                    productNameCol,
                    uktzCol,
                    qrCodeCol,
                    weightCol,

                    countCol,
                    purchasePriceCol,
                    discountCol,
                    priceCol,
                    sumCol,
                    currencyCol,
                    supplierId
                },
                { handleErrorInternally: true }
            );
            notification.success({
                message: this.props.intl.formatMessage({ id: 'barcode.success' })
            });
        } catch (error) {
            console.log(error, 'err');
            if (error) {
                notification.error({
                    message: this.props.intl.formatMessage({
                        id: 'receipt_document_modal.templates_error_notif'
                    })
                });
            }
        }
        this.fetchTemplate();
    };

    // existParts = async () => {
    //     const {
    //         modalProps: { visibleLabors }
    //     } = this.props;
    //     const { brandNameCol, productCodeCol, productNameCol, fileList, firstRowNum } = this.state;

    //     let type = fileList[0].name.split('.');
    //     type = type[type.length - 1];

    //     if (visibleLabors || type === 'csv') {
    //         this.setState({
    //             confirmed: true
    //         });

    //         return true;
    //     }

    //     const workbook = new Workbook();
    //     const products = [];
    //     await workbook.xlsx.load(fileList[0]).then(function () {
    //         workbook.eachSheet((sheet, id) => {
    //             sheet.eachRow((row, rowIndex) => {
    //                 if (rowIndex >= firstRowNum) {
    //                     const brand = String(row.values[brandNameCol]);
    //                     const code = String(row.values[productCodeCol]);
    //                     const name = String(row.values[productNameCol]);
    //                     products.push({ brand, code, name });
    //                 }
    //             });
    //         });
    //     });
    //     const existing = await fetchAPI('POST', 'store_products/existing', null, products, {
    //         handleErrorInternally: true
    //     });
    //     const newProducts = existing.filter(({ product }) => !product).map(el => ({ ...el, groupId: '1000000' }));
    //     this.setState({
    //         newProducts,
    //         confirmed: !newProducts.length
    //     });

    //     return !newProducts.length;
    // };

    sendRequest = async () => {
        const token = localStorage.getItem('_my.carbook.pro_token');
        const {
            modalProps: { visibleLabors, visibleParts },
            orderId,
            storeDocId,
            onConfirm,
            updateDocument
        } = this.props;

        const {
            type,
            encoding,
            delimiter,
            separator,
            firstRowNum,
            lastRowNum,

            docType,
            docNum,
            docDate,

            laborCodeCol,
            laborNameCol,
            mechanicCol,

            productCodeCol,
            groupIdCol,
            productSupplierCodeCol,
            brandNameCol,
            productNameCol,
            uktzCol,
            qrCodeCol,
            weightCol,

            countCol,
            purchasePriceCol,
            discountCol,
            priceCol,
            sumCol,
            currencyCol,

            supplierId,
            fileList
        } = this.state;

        const table = visibleLabors ? 'Working_Order_Labors' : visibleParts ? 'Working_Order_Parts' : 'Stock_Products';

        const formData = new FormData();
        if (table) formData.append('table', table);
        if (orderId || storeDocId) formData.append('docId', orderId || storeDocId);
        if (fileList[0]) formData.append('file', fileList[0]);
        if (type) formData.append('type', type);
        if (encoding) formData.append('encoding', encoding);
        if (delimiter) formData.append('delimiter', delimiter);
        if (separator) formData.append('separator', separator);
        if (firstRowNum) formData.append('firstRowNum', firstRowNum);
        if (lastRowNum) formData.append('lastRowNum', lastRowNum);

        if (docType) formData.append('docType', docType);
        if (docNum) formData.append('docNum', docNum);
        if (docDate) formData.append('docDate', docDate);

        if (laborCodeCol) formData.append('laborCodeCol', laborCodeCol);
        if (laborNameCol) formData.append('laborNameCol', laborNameCol);
        if (mechanicCol) formData.append('mechanicCol', mechanicCol);

        if (productCodeCol) formData.append('productCodeCol', productCodeCol);
        if (groupIdCol) {
            formData.append('groupIdCol', groupIdCol);
        }
        if (productSupplierCodeCol) {
            formData.append('productSupplierCodeCol', productSupplierCodeCol);
        }
        if (brandNameCol) formData.append('brandNameCol', brandNameCol);
        if (productNameCol) formData.append('productNameCol', productNameCol);
        if (uktzCol) formData.append('uktzCol', uktzCol);
        if (qrCodeCol) formData.append('qrCodeCol', qrCodeCol);
        if (weightCol) formData.append('weightCol', weightCol);

        if (countCol) formData.append('countCol', countCol);
        if (purchasePriceCol) formData.append('purchasePriceCol', purchasePriceCol);
        if (discountCol) formData.append('discountCol', discountCol);
        if (priceCol) formData.append('priceCol', priceCol);
        if (sumCol) formData.append('sumCol', sumCol);
        if (currencyCol) formData.append('currencyCol', currencyCol);

        if (supplierId) formData.append('supplierId', supplierId);

        const url = `${__API_URL__}/sync/templates/import/xslx`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: token
                }
            });
            const result = await response.json();
        } catch (error) {
            this.setState({ loading: false });
            console.error('error:', error);
        } finally {
            console.log(formData);
            this.setState({ loading: false });
            this.handleCancel();
            onConfirm();
        }

        // if (updateDocument) {
        //     setTimeout(() => updateDocument(), 1000);
        // }
    };

    handleOk = async () => {
        const { updateDocument, orderId } = this.props;
        if (orderId) {
            const { status } = await fetchAPI('GET', 'orders/status', { orderId }, null);
            if (status === 'success') {
                window.location.reload();

                return;
            }
        }
        await this.setState({ loading: true });
        this.sendRequest();

        if (updateDocument) {
            await updateDocument();
        }
    };

    handleCancel = () => {
        this.setState({
            type: 'xlsx',
            encoding: undefined,
            delimiter: undefined,
            separator: undefined,
            firstRowNum: undefined,
            lastRowNum: undefined,

            docType: undefined,
            docNum: undefined,
            docDate: undefined,

            laborCodeCol: undefined,
            laborNameCol: undefined,
            mechanicCol: undefined,

            productCodeCol: undefined,
            groupIdCol: undefined,
            productSupplierCodeCol: undefined,
            brandNameCol: undefined,
            productNameCol: undefined,
            uktzCol: undefined,
            qrCodeCol: undefined,
            weightCol: undefined,

            countCol: undefined,
            purchasePriceCol: undefined,
            discountCol: undefined,
            priceCol: undefined,
            sumCol: undefined,
            currencyCol: undefined,

            supplierId: undefined,
            templateName: undefined,
            fileList: []
        });
        this.props.resetModal();
    };

    fetchTemplate = async () => {
        const templates = await fetchAPI('GET', 'sync/templates', null, null, {
            handleErrorInternally: true
        });

        this.setState({ templates: templates.sort((a, b) => a.id - b.id) });
    };

    componentDidUpdate = async prevProps => {
        if (
            prevProps.visible !== MODALS.IMPORT_RECEIPT_DOCUMENT_MODAL &&
            this.props.visible === MODALS.IMPORT_RECEIPT_DOCUMENT_MODAL
        ) {
            this.fetchTemplate();
            fetchAPI('GET', '/store_products');
        }
    };

    render() {
        const {
            visible,
            resetModal,
            suppliers,
            intl: { formatMessage },
            modalProps: { visibleLabors, visibleParts },
            handleSearchSuppliers
        } = this.props;

        const {
            type,
            encoding,
            delimiter,
            separator,
            firstRowNum,
            lastRowNum,

            docType,
            docNum,
            docDate,

            laborCodeCol,
            laborNameCol,
            mechanicCol,

            productCodeCol,
            groupIdCol,
            productSupplierCodeCol,
            brandNameCol,
            productNameCol,
            uktzCol,
            qrCodeCol,
            weightCol,

            countCol,
            purchasePriceCol,
            discountCol,
            priceCol,
            sumCol,
            currencyCol,

            supplierId,

            templates,
            templateName,
            supplierCol,
            sellPriceCol,
            fileList,
            confirmed,
            newProducts,
            templateId,
            visibleTemplatesModal,
            loading
        } = this.state;

        const uploadFileProps = {
            onRemove: file => {
                this.setState(state => {
                    const newFileList = state.fileList.filter(fl => fl !== file);

                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));

                return false;
            },
            fileList
        };

        const template = templates.find(tmpl => tmpl.templateName === templateName);

        return (
            <React.Fragment>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    okButtonProps={{
                        disabled: !fileList.length || !firstRowNum || loading,
                        loading
                        // !firstRowNum ||
                        // ((visibleParts || !visibleLabors) && !productCodeCol) ||
                        // ((visibleParts || !visibleLabors) && !brandNameCol)
                    }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={
                        visibleLabors ? (
                            <FormattedMessage id='receipt_document_modal.title_labors' />
                        ) : visibleParts ? (
                            <FormattedMessage id='receipt_document_modal.title_parts' />
                        ) : (
                            <FormattedMessage id='receipt_document_modal.title' />
                        )
                    }
                    visible={visible === MODALS.IMPORT_RECEIPT_DOCUMENT_MODAL}
                    width='85%'
                    zIndex={2000}
                >
                    <div className={Styles.categoryLine_title}>
                        <span
                            className={Styles.categoryLine_title_text}
                            style={{
                                fontSize: 15,
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='receipt_document_modal.file' />
                        </span>
                        <div className={Styles.categoryLine}>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.file_type' />
                                    <span className={Styles.starRed}>*</span>
                                </div>
                                <div className={Styles.selectLine}>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={type => {
                                            this.setState({
                                                type,
                                                delimiter: type === 'csv' ? ',' : undefined,
                                                separator: type === 'csv' ? ',' : undefined,
                                                encoding: type === 'csv' ? '65001' : undefined
                                            });
                                        }}
                                        value={type}
                                    >
                                        <Option value='xlsx'>.xlsx</Option>
                                        <Option value='csv'>.csv</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.coding' />
                                    {type === 'csv' && <span className={Styles.starRed}>*</span>}
                                </div>
                                <div className={Styles.selectLine}>
                                    <Select
                                        disabled={type !== 'csv'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={encoding => {
                                            this.setState({
                                                encoding
                                            });
                                        }}
                                        value={encoding}
                                    >
                                        <Option value='65001'>65001</Option>
                                        <Option value='1251'>1251</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.delimiter' />
                                    {type === 'csv' && <span className={Styles.starRed}>*</span>}
                                </div>
                                <div className={Styles.selectLine}>
                                    <Select
                                        disabled={type !== 'csv'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={delimiter => {
                                            this.setState({
                                                delimiter
                                            });
                                        }}
                                        value={delimiter}
                                    >
                                        <Option value=','>,</Option>
                                        <Option value=';'>;</Option>
                                        <Option value='.'>.</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.decimal_separator' />
                                    {type === 'csv' && <span className={Styles.starRed}>*</span>}
                                </div>
                                <div className={Styles.selectLine}>
                                    <Select
                                        disabled={type !== 'csv'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={separator => {
                                            this.setState({
                                                separator
                                            });
                                        }}
                                        value={separator}
                                    >
                                        <Option value=','>,</Option>
                                        <Option value='.'>.</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.first_line' />
                                    <span className={Styles.starRed}>*</span>
                                </div>
                                <div>
                                    <InputNumber
                                        min={0}
                                        onChange={firstRowNum => {
                                            this.setState({
                                                firstRowNum
                                            });
                                        }}
                                        value={firstRowNum}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.last_line' />
                                    {!visibleLabors && <Tooltip
                                        title={formatMessage({ id: 'receipt_document_modal.last_line_info' })}
                                        placement='topRight'
                                    >
                                        <QuestionCircleOutlined style={{ marginLeft: 4,  color: 'red' }} /> 
                                    </Tooltip>}
                                </div>
                                <div>
                                    <InputNumber
                                        min={0}
                                        onChange={lastRowNum => {
                                            this.setState({
                                                lastRowNum
                                            });
                                        }}
                                        value={lastRowNum}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.categoryLine_title}>
                        <span
                            className={Styles.categoryLine_title_text}
                            style={{
                                fontSize: 15,
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='receipt_document_modal.document' />
                        </span>
                        <div className={Styles.categoryLine}>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.document_type' />
                                </div>
                                <div className={Styles.selectLine}>
                                    <Select
                                        disabled
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={docType => {
                                            this.setState({
                                                docType
                                            });
                                        }}
                                        value={docType}
                                    >
                                        <Option value='export'>
                                            <FormattedMessage id='receipt_document_modal.export' />
                                        </Option>
                                        <Option value='bill'>
                                            <FormattedMessage id='receipt_document_modal.bill' />
                                        </Option>
                                        <Option value='invoice'>
                                            <FormattedMessage id='receipt_document_modal.invoice' />
                                        </Option>
                                        <Option value='other'>
                                            <FormattedMessage id='receipt_document_modal.other' />
                                        </Option>
                                    </Select>
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.document_number' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled
                                        min={0}
                                        onChange={docNum => {
                                            this.setState({
                                                docNum
                                            });
                                        }}
                                        value={docNum}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.document_date' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled
                                        min={0}
                                        onChange={docDate => {
                                            this.setState({
                                                docDate
                                            });
                                        }}
                                        value={docDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.categoryLine_title}>
                        <span
                            className={Styles.categoryLine_title_text}
                            style={{
                                fontSize: 15,
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='receipt_document_modal.works' />
                        </span>
                        <div className={Styles.categoryLine}>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.work_code' />
                                    {visibleLabors && <span className={Styles.starRed}>*</span>}
                                </div>
                                <div>
                                    <InputNumber
                                        disabled={!visibleLabors}
                                        min={0}
                                        onChange={laborCodeCol => {
                                            this.setState({
                                                laborCodeCol
                                            });
                                        }}
                                        value={laborCodeCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.work_name' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled={!visibleLabors}
                                        min={0}
                                        onChange={laborNameCol => {
                                            this.setState({
                                                laborNameCol
                                            });
                                        }}
                                        value={laborNameCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.mechanic' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled={!visibleLabors}
                                        min={0}
                                        onChange={mechanicCol => {
                                            this.setState({
                                                mechanicCol
                                            });
                                        }}
                                        value={mechanicCol}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.categoryLine_title}>
                        <span
                            className={Styles.categoryLine_title_text}
                            style={{
                                fontSize: 15,
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='receipt_document_modal.products' />
                        </span>
                        <div className={Styles.categoryLine}>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.product_code' />
                                    {!visibleLabors && <span className={Styles.starRed}>*</span>}
                                </div>
                                <div>
                                    <InputNumber
                                        disabled={visibleLabors}
                                        min={0}
                                        onChange={productCodeCol => {
                                            this.setState({
                                                productCodeCol
                                            });
                                        }}
                                        value={productCodeCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.product_group_code' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled={visibleLabors}
                                        min={0}
                                        onChange={groupIdCol => {
                                            this.setState({
                                                groupIdCol
                                            });
                                        }}
                                        value={groupIdCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.supplier_product_code' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled={visibleLabors}
                                        min={0}
                                        onChange={productSupplierCodeCol => {
                                            this.setState({
                                                productSupplierCodeCol
                                            });
                                        }}
                                        value={productSupplierCodeCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.brand' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled={visibleLabors}
                                        min={0}
                                        onChange={brandNameCol => {
                                            this.setState({
                                                brandNameCol
                                            });
                                        }}
                                        value={brandNameCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.product_name' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled={visibleLabors}
                                        min={0}
                                        onChange={productNameCol => {
                                            this.setState({
                                                productNameCol
                                            });
                                        }}
                                        value={productNameCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.uktzed' />
                                </div>
                                <div>
                                    <InputNumber
                                        min={0}
                                        onChange={uktzCol => {
                                            this.setState({
                                                uktzCol
                                            });
                                        }}
                                        value={uktzCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.bar_qr_code' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled
                                        min={0}
                                        onChange={qrCodeCol => {
                                            this.setState({
                                                qrCodeCol
                                            });
                                        }}
                                        value={qrCodeCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.product_weight' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled
                                        min={0}
                                        onChange={weightCol => {
                                            this.setState({
                                                weightCol
                                            });
                                        }}
                                        value={weightCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.unit' />
                                </div>
                                <div>
                                    <InputNumber disabled min={0} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.categoryLine_title}>
                        <span
                            className={Styles.categoryLine_title_text}
                            style={{
                                fontSize: 15,
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='receipt_document_modal.prices' />
                        </span>
                        <div className={Styles.categoryLine}>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.amount' />
                                </div>
                                <div>
                                    <InputNumber
                                        min={0}
                                        onChange={countCol => {
                                            this.setState({
                                                countCol
                                            });
                                        }}
                                        value={countCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.purchase_price' />
                                </div>
                                <div>
                                    <InputNumber
                                        min={0}
                                        onChange={purchasePriceCol => {
                                            this.setState({
                                                purchasePriceCol
                                            });
                                        }}
                                        value={purchasePriceCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.discount' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled
                                        min={0}
                                        onChange={discountCol => {
                                            this.setState({
                                                discountCol
                                            });
                                        }}
                                        value={discountCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.currency' />
                                </div>
                                <div>
                                    <InputNumber
                                        min={0}
                                        onChange={currencyCol => {
                                            this.setState({
                                                currencyCol
                                            });
                                        }}
                                        value={currencyCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.sales_price' />
                                </div>
                                <div>
                                    <InputNumber
                                        min={0}
                                        onChange={priceCol => {
                                            this.setState({
                                                priceCol
                                            });
                                        }}
                                        value={priceCol}
                                    />
                                </div>
                            </div>
                            <div className={Styles.categoryLine_part}>
                                <div className={Styles.categoryLine_text}>
                                    <FormattedMessage id='receipt_document_modal.sum' />
                                </div>
                                <div>
                                    <InputNumber
                                        disabled
                                        min={0}
                                        onChange={sumCol => {
                                            this.setState({
                                                sumCol
                                            });
                                        }}
                                        value={sumCol}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div className={Styles.dragger}>
                                <div>
                                    <FormattedMessage id='receipt_document_modal.supplier' />
                                </div>
                                <div>
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={supplierId => {
                                            this.setState({
                                                supplierId
                                            });
                                        }}
                                        onSearch={input => handleSearchSuppliers(input)}
                                        optionFilterProp='children'
                                        showSearch
                                        value={supplierId}
                                    >
                                        {template && get(template, 'supplierName', undefined)
                                            ? buildSupplierOptions(suppliers, {
                                                  id: get(template, 'supplierId', undefined),
                                                  name: get(template, 'supplierName', undefined)
                                              }).map(({ id, name }) => (
                                                  <Option key={id} value={id}>
                                                      {name}
                                                  </Option>
                                              ))
                                            : suppliers.map(({ id, name }) => (
                                                  <Option key={id} value={id}>
                                                      {name}
                                                  </Option>
                                              ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div className={Styles.dragger}>
                                <div>
                                    <FormattedMessage id='receipt_document_modal.template' />
                                    <span className={Styles.starRed}>*</span>
                                </div>
                                <div>
                                    <AutoComplete
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                            zIndex: '9999',
                                            minWidth: 220
                                        }}
                                        filterOption={(input, option) => {
                                            return (
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={value => this.setState({ templateName: value })}
                                        onSelect={id => {
                                            const template = templates.find(tmpl => tmpl.id === id);
                                            if (template) {
                                                this.setState(template);
                                            }
                                        }}
                                        showSearch
                                        value={templateName}
                                    >
                                        {templates.map(({ templateName, id }) => (
                                            <Option key={id} value={id}>
                                                {templateName}
                                            </Option>
                                        ))}
                                    </AutoComplete>
                                </div>
                            </div>
                            <div
                                style={{
                                    margin: '22px 0px 0px 8px'
                                }}
                            >
                                <Tooltip title={<FormattedMessage id='save' />} zIndex={2001}>
                                    <Button
                                        disabled={
                                            !firstRowNum ||
                                            !templateName ||
                                            (!visibleLabors ? !productCodeCol : null) ||
                                            (visibleLabors ? !laborCodeCol : null)
                                        }
                                        icon={<SaveOutlined />}
                                        onClick={this.postTemplate}
                                        style={{
                                            marginRight: 8
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={<FormattedMessage id='receipt_document_modal.templates' />}
                                    zIndex={2001}
                                >
                                    <Button
                                        icon={<FolderOpenOutlined />}
                                        onClick={() => {
                                            this.setState({
                                                visibleTemplatesModal: true
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <div
                                    style={{
                                        display: 'flex'
                                    }}
                                >
                                    <div className={Styles.dragger}>
                                        <Dragger {...uploadFileProps}>
                                            <p className='ant-upload-drag-icon'>
                                                <InboxOutlined />
                                            </p>
                                            <p className='ant-upload-text'>
                                                <FormattedMessage id='receipt_document_modal.drag_n_drop' />
                                            </p>
                                            <span
                                                style={{
                                                    opacity: 0.5
                                                }}
                                            >
                                                {' '}
                                                <FormattedMessage id='receipt_document_modal.file_type_tooltip' />
                                            </span>
                                        </Dragger>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <TemplatesModal
                    fetchTemplate={this.fetchTemplate}
                    hideModal={() => {
                        this.setState({ visibleTemplatesModal: false });
                    }}
                    setTemplate={id => {
                        const template = templates.find(tmpl => tmpl.id === id);
                        if (template) {
                            this.setState(template);
                        }
                    }}
                    templates={templates}
                    visible={visibleTemplatesModal}
                />
            </React.Fragment>
        );
    }
}
