import { Button, Checkbox, Flex, Segmented, Select } from 'antd';
import { DateRangePicker } from 'components';
import { compareOpt, detalizationOpt } from 'pages/GraphicReportsPage/utils/constants';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from '../../styles.m.css';

const { Option } = Select;

const ChartSettings = ({
    user,
    chartSettings,
    setChartSettings,
    openFiltersModal,
    compareFilters,
    comparePeriod,
    setComparePeriod,
    disabled,
    allStations,
    handleAllStationsChange
}) => {
    const { startDate, endDate, detalization } = chartSettings;

    const handleChartSettingsChange = settingsObj => {
        setChartSettings(prev => ({ ...prev, ...settingsObj }));
    };
    const handleDateChange = daterange => {
        const [startDate, endDate] = daterange;
        handleChartSettingsChange({ startDate, endDate });
    };
    const handleDetalizationChange = value => handleChartSettingsChange({ detalization: value });

    return (
        <Flex gap={48} id='width-trgt' justify='flex-end'>
            <Flex className={Styles.chartSettingsContainer} gap={12} vertical>
                <DateRangePicker
                    dateRange={[startDate, endDate]}
                    disabled={disabled}
                    onDateChange={handleDateChange}
                    type='default'
                />
                <Segmented
                    block
                    onChange={handleDetalizationChange}
                    options={detalizationOpt.map(({ label, value }) => ({
                        label: <FormattedMessage id={label} />,
                        value
                    }))}
                    value={detalization}
                />
            </Flex>
            <Flex className={Styles.chartSettingsContainer} gap={12} vertical>
                <Segmented
                    block
                    disabled={disabled}
                    onChange={setComparePeriod}
                    options={compareOpt.map(({ label, value }) => ({
                        label: <FormattedMessage id={label} />,
                        value
                    }))}
                    value={comparePeriod}
                />
                <Button
                    disabled={disabled || !isGrantAccessed(user, grants.REPORTS_GRAPHICAL_REPORTS_FILTERS)}
                    onClick={openFiltersModal}
                    type={Object.values(compareFilters).some(val => val) ? 'primary' : 'default'}
                >
                    <FormattedMessage id='filters_graphic' />
                </Button>
                <div>
                    <Checkbox
                        checked={allStations}
                        disabled={!isGrantAccessed(user, grants.REPORTS_GRAPHICAL_REPORTS_NETWORK_REPORTS)}
                        onChange={handleAllStationsChange}
                    >
                        <FormattedMessage id='graphic_reports.all_stations' />
                    </Checkbox>
                    <Checkbox disabled style={{ marginLeft: '15px', marginBottom: '10px' }}>
                        <FormattedMessage id='graphic_reports.budget' />
                    </Checkbox>
                </div>
            </Flex>
        </Flex>
    );
};

export default ChartSettings;
