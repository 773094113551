import { CheckOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Popover } from 'antd';
import { Numeral } from 'commons';
import { FormattedDatetime, HamburgerMenu } from 'components';
import _ from 'lodash';
import { laborsSortFields } from 'pages/VehiclePage/redux/duck';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    order: '10%',
    datetime: '10%',
    odometer: '8%',
    labor: '10%',
    type: '10%',
    storeGroupName: '10%',
    mechanic: '10%',
    normHours: '5%',
    price: '7%',
    count: '5%',
    sum: '10%',
    actions: '5%'
};

export function columnsConfig(props) {
    const {
        // onAddDetailToOrder,
        onServiceNameSearch,
        onDefaultNameSearch,
        onStoreGroupNameSearch,
        onEmployeeFullNameSearch
    } = props;

    const { onAddLaborToOrder, selectedRows } = props;

    const groupActionsCol = {
        title: () => {
            const actionsMenu = (
                <Menu>
                    <Menu.Item disabled={!selectedRows.length}>
                        <div
                            onClick={() => {
                                const labors = selectedRows.map(
                                    ({
                                        count,
                                        employeeId,
                                        laborUnitId,
                                        purchasePrice,
                                        serviceHours,
                                        id,
                                        serviceName,
                                        price
                                    }) => ({
                                        count: count || 1,
                                        employeeId,
                                        laborUnitId,
                                        purchasePrice,
                                        serviceHours,
                                        laborId: Number(id),
                                        serviceName,
                                        servicePrice: price
                                    })
                                );
                                onAddLaborToOrder(labors);
                            }}
                            style={{
                                display: 'flex',
                                justifyContent: ' space-between'
                            }}
                        >
                            <CheckOutlined />
                            <FormattedMessage id='vehicle_page.hint_add_labor_to_order' />
                        </div>
                    </Menu.Item>
                </Menu>
            );

            return <HamburgerMenu actionsMenu={actionsMenu} />;
        },
        key: 'actions',
        align: 'center',
        render: el => {
            const labor = {
                count: el.count || 1,
                employeeId: el.employeeId,
                laborUnitId: el.laborUnitId,
                purchasePrice: el.purchasePrice,
                serviceHours: el.serviceHours,
                laborId: Number(el.id),
                serviceName: el.serviceName,
                servicePrice: el.price
            };
            const actionsMenu = () => (
                <Menu>
                    <Menu.Item>
                        <div
                            onClick={() => onAddLaborToOrder({ labor })}
                            style={{
                                display: 'flex',
                                justifyContent: ' space-between'
                            }}
                        >
                            <CheckOutlined />
                            <FormattedMessage id='vehicle_page.hint_add_labor_to_order' />
                        </div>
                    </Menu.Item>
                </Menu>
            );

            return (
                <HamburgerMenu actionsMenu={actionsMenu}>
                    <Button data-qa='btn_show_hamburger_menu_modal_services_table_order_page' icon={<MenuOutlined />} />
                </HamburgerMenu>
            );
        }
    };

    const orderCol = {
        title: <FormattedMessage id='orders.order' />,
        width: defWidth.order,
        dataIndex: 'orderNum',
        key: laborsSortFields.orderId,
        sorter: true,
        render: orderNum => <React.Fragment>{orderNum}</React.Fragment>
    };

    const datetimeCol = {
        title: <FormattedMessage id='orders.record_date' />,
        width: defWidth.datetime,
        dataIndex: 'orderDatetime',
        key: laborsSortFields.datetime,
        sorter: true,
        render: orderDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={orderDatetime} format={DEFAULT_DATETIME} />
            </React.Fragment>
        )
    };

    const odometerCol = {
        title: <FormattedMessage id='orders.odometer' />,
        width: defWidth.odometer,
        dataIndex: 'odometer',
        key: 'odometer',
        render: odometer => <div>{odometer || <FormattedMessage id='long_dash' />}</div>
    };

    const laborCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.detail_name' />
                    <Input
                        onChange={e => onServiceNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.labor,
        dataIndex: 'serviceName',
        key: laborsSortFields.serviceName,
        sorter: true
    };

    const typeCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.service_type' />
                    <Input
                        onChange={e => onDefaultNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.type,
        dataIndex: 'defaultName',
        key: laborsSortFields.defaultName,
        sorter: true
    };

    const storeGroupNameCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='order_form_table.store_group' />
                    <Input
                        onChange={e => onStoreGroupNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.begin_datetime,
        dataIndex: 'storeGroupName',
        key: laborsSortFields.storeGroupName,
        sorter: true
    };

    const mechanicCol = {
        title: () => {
            return (
                <div>
                    <FormattedMessage id='employee.is_mechanic' />
                    <Input
                        onChange={e => onEmployeeFullNameSearch(_.get(e, 'target.value'))} // Stop trigering parent's onClick event
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            );
        },
        width: defWidth.mechanic,
        dataIndex: 'employeeFullName',
        key: laborsSortFields.employeeFullName,
        sorter: true
    };

    const normHoursCol = {
        title: <FormattedMessage id='services_table.norm_hours' />,
        width: defWidth.normHours,
        align: 'right',
        dataIndex: 'hours',
        key: laborsSortFields.hours,
        sorter: true
    };

    const priceCol = {
        title: <FormattedMessage id='order_form_table.price' />,
        width: defWidth.price,
        align: 'right',
        dataIndex: 'price',
        key: laborsSortFields.price,
        sorter: true,
        render: price => {
            return <Numeral mask='0,00.00'>{price}</Numeral>;
        }
    };

    const countCol = {
        title: <FormattedMessage id='order_form_table.count' />,
        width: defWidth.count,
        align: 'right',
        dataIndex: 'count',
        key: laborsSortFields.count,
        sorter: true
    };

    const sumCol = {
        title: <FormattedMessage id='order_form_table.sum' />,
        width: defWidth.sum,
        align: 'right',
        dataIndex: 'sum',
        key: laborsSortFields.sum,
        sorter: true,
        render: sum => {
            return <Numeral mask='0,00.00'>{sum}</Numeral>;
        }
    };

    const actionsCol = {
        width: defWidth.actions,
        key: v4(),
        render: labor => (
            <div>
                <Popover content={<FormattedMessage id='vehicle_page.hint_add_labor_to_order' />}>
                    <Button
                        disabled={props.disabled}
                        icon={<CheckOutlined />}
                        onClick={() => onAddLaborToOrder({ labor })}
                    />
                </Popover>
            </div>
        )
    };

    const units = {
        title: <FormattedMessage id='services_table.units' />,
        key: v4(),
        dataIndex: 'unit',
        align: 'right',
        render: data => {
            return data ? <span style={{ whiteSpace: 'nowrap' }}>{data}</span> : <FormattedMessage id='long_dash' />;
        }
    };

    return [
        groupActionsCol,
        orderCol,
        datetimeCol,
        odometerCol,
        laborCol,
        typeCol,
        storeGroupNameCol,
        mechanicCol,
        normHoursCol,
        priceCol,
        countCol,
        units,
        sumCol
    ];
}
