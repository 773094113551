import { Input, InputNumber, Modal, Select, Switch, TreeSelect, notification } from 'antd';
import { selectModalProps } from 'core/modals/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import { createStoreGroup, deleteStoreGroup, updateStoreGroup } from 'core/storage/storeGroups';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    modalProps: selectModalProps(state),
    priceGroups: selectPriceGroups(state)
});

const mapDispatchToProps = {
    createStoreGroup,
    updateStoreGroup,
    deleteStoreGroup,
    fetchPriceGroups
};

const AccountsGroup = props => {
    const {
        visible,
        hideModal,
        modalProps,
        intl: { formatMessage },
        treeDataNode,
        accountGroup,
        editMode,
        getAccountsList,
        isMobile
    } = props;

    const [tree, setTree] = useState([]);
    const [name, setName] = useState(undefined);
    const [parentId, setParentId] = useState(undefined);
    const [accountNumber, setAccountNumber] = useState(undefined);
    const [level, setLevel] = useState(1);
    const [entries, setEntries] = useState(false);
    const [active, setActive] = useState(false);
    const [type, setType] = useState(undefined);
    const [custom, setCustom] = useState(false);
    const [optionLevel, setOptionLevel] = useState(undefined);

    const filterTreeByActive = useCallback(nodes => {
        return nodes.reduce((filteredNodes, node) => {
            if (node.active === true) {
                filteredNodes.push({
                    value: node.id,
                    title: `(#${node.id}) ${node.name} `,
                    level: node.level,
                    disabled: node.level !== 3
                });

                if (node.children && node.level !== 3) {
                    const filteredChildren = filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNodes[filteredNodes.length - 1].children = filteredChildren;
                    }
                }
            }

            setTree(filteredNodes);

            return filteredNodes;
        }, []);
    });

    const addAccount = async () => {
        try {
            await fetchAPI(
                'POST',
                'general_ledger/accounts',
                undefined,
                {
                    id: `${parentId}${accountNumber}`,
                    parentId,
                    level,
                    name,
                    entries,
                    active,
                    type
                },
                { handleErrorInternally: true }
            );
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'accounts_tab.add_error' })
            });
        }
    };

    const editAccount = async () => {
        try {
            await fetchAPI(
                'PUT',
                'general_ledger/accounts',
                undefined,
                {
                    id: parentId ? `${parentId}${accountNumber}` : String(accountNumber),
                    parentId,
                    level,
                    name,
                    entries,
                    active,
                    type
                },
                { handleErrorInternally: true }
            );
            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        } catch (err) {
            notification.error({
                message: formatMessage({ id: 'error' })
            });
        }
    };

    useEffect(() => {
        if (treeDataNode) {
            filterTreeByActive(treeDataNode);
        }
    }, [treeDataNode]);

    useEffect(() => {
        if (accountGroup) {
            setName(accountGroup.name);
            setParentId(accountGroup.parentId);
            setAccountNumber(accountGroup.custom ? String(accountGroup.id).slice(-2) : accountGroup.id);
            setLevel(accountGroup.level);
            setEntries(accountGroup.entries);
            setActive(accountGroup.active);
            setType(accountGroup.type);
            setCustom(accountGroup.custom);
        }
    }, [accountGroup]);

    const onClose = () => {
        setName(undefined);
        setParentId(undefined);
        setAccountNumber(undefined);
        setLevel(undefined);
        setEntries(false);
        setActive(false);
        setType(undefined);

        setTimeout(() => getAccountsList(), 200);
        hideModal();
    };

    const onFinish = async () => {
        if (!editMode) {
            addAccount();
        } else {
            editAccount();
        }

        await onClose();
    };

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: !accountNumber || !level || !name || !type
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={visible}
            title={
                !editMode ? (
                    <FormattedMessage id='accounts_tab.type.add' />
                ) : (
                    <FormattedMessage id='accounts_tab.type.edit' />
                )
            }
            width={isMobile ? '95%' : '30%'}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='description' />
                    </div>
                    <Input
                        disabled={editMode && accountGroup ? !accountGroup.custom : undefined}
                        onChange={event => {
                            setName(event.target.value);
                        }}
                        placeholder={formatMessage({
                            id: 'description'
                        })}
                        value={name}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='requisite-setting.account' />
                    </div>
                    <TreeSelect
                        disabled={editMode && accountGroup ? !accountGroup.custom : undefined}
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        listHeight={440}
                        onSelect={(value, option) => {
                            setParentId(value);
                            setAccountNumber(undefined);
                            setLevel(option.level + 1);
                        }}
                        placeholder={
                            <React.Fragment>
                                <FormattedMessage id='receipt_document_modal.bill' />
                            </React.Fragment>
                        }
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        treeData={tree}
                        treeNodeFilterProp={(input, node) => {
                            return node.active;
                        }}
                        value={parentId}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='accounts_tab.type.acc_number' />
                    </div>
                    <Input
                        addonBefore={editMode && custom ? parentId : !editMode ? parentId : undefined}
                        disabled={!parentId || (editMode && accountGroup ? !accountGroup.custom : undefined)}
                        maxLength={parentId < 10 ? 4 : parentId < 100 ? 3 : parentId < 1000 ? 2 : 1}
                        onChange={e => setAccountNumber(e.target.value)}
                        placeholder={formatMessage({
                            id: 'accounts_tab.type.acc_number'
                        })}
                        style={{
                            width: '100%'
                        }}
                        type='number'
                        value={accountNumber}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='add_client_form.aggregate_level' />
                    </div>
                    <InputNumber
                        decimalSeparator=','
                        disabled
                        max={4}
                        min={0}
                        onChange={value => {
                            setLevel(value);
                        }}
                        placeholder={formatMessage({
                            id: 'add_client_form.aggregate_level'
                        })}
                        style={{
                            width: '100%'
                        }}
                        value={level}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='accounts_tab.operations' />
                    </div>
                    <Switch
                        checked={entries}
                        disabled={level < 4}
                        onChange={async () => {
                            await setEntries(!entries);
                        }}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='requisite-setting.active' />
                    </div>
                    <Switch
                        checked={active}
                        onChange={async () => {
                            await setActive(!active);
                        }}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='supplier.type' />
                    </div>
                    <Select
                        allowClear
                        disabled={editMode && accountGroup ? !accountGroup.custom : undefined}
                        onSelect={value => setType(value)}
                        showSearch
                        style={{ color: 'var(--text)', width: '100%' }}
                        value={type}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={formatMessage({
                            id: 'search'
                        })}
                    >
                        <Option key='ACTIVE' value='ACTIVE'>
                            <FormattedMessage id='accounts_tab.type.ACTIVE' />
                        </Option>
                        <Option key='PASSIVE' value='PASSIVE'>
                            <FormattedMessage id='accounts_tab.type.PASSIVE' />
                        </Option>
                        <Option key='ACTIVE/PASSIVE' value='ACTIVE/PASSIVE'>
                            <FormattedMessage id='accounts_tab.type.ACTIVE/PASSIVE' />
                        </Option>
                    </Select>
                </div>
            </div>
        </Modal>
    );
};

const AccountsGroupModal = injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsGroup));

export default AccountsGroupModal;
