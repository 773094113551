import { Select } from 'antd';
import { Layout, Spinner } from 'commons';
import { DateRangePicker } from 'components';
import { CallsContainer } from 'containers';
import {
    fetchCalls,
    fetchCallsChart,
    setCallsChannelId,
    setCallsDaterange,
    tabs
} from 'core/calls/duck';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    tab: state.calls.tab,
    calls: state.calls.calls,
    channels: state.calls.channels,
    filter: state.calls.filter,
    callsInitializing: state.ui.callsInitializing,
    callsFetching: state.ui.callsFetching
});

const mapDispatchToProps = {
    fetchCalls,
    fetchCallsChart,
    setCallsDaterange,
    setCallsChannelId
};

// @withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class CallsPage extends Component {
    componentDidMount() {
        this.props.fetchCallsChart('init');
        this.props.fetchCalls();
    }

    _setCallsDaterange = daterange => {
        const { tab, setCallsDaterange, fetchCallsChart, fetchCalls } = this.props;

        setCallsDaterange(daterange);

        tab === tabs.callsTable && fetchCalls();
        tab === tabs.callsChart && fetchCallsChart();
    };

    _setCallsChannelId = channelId => {
        const { tab, setCallsChannelId, fetchCalls, fetchCallsChart } = this.props;

        if (channelId === 'ALL') setCallsChannelId(null);
        else setCallsChannelId(channelId);

        tab === tabs.callsTable && fetchCalls();
        tab === tabs.callsChart && fetchCallsChart();
    };

    render() {
        const {
            channels,
            callsInitializing,
            callsFetching,
            filter: { startDate, endDate }
        } = this.props;

        return callsInitializing ? (
            <Spinner spin={callsInitializing} />
        ) : (
            <Layout
                controls={
                    <React.Fragment>
                        {callsFetching ? (
                            ''
                        ) : (
                            <DateRangePicker
                                dateRange={[dayjs(startDate), dayjs(endDate)]}
                                minimize
                                onDateChange={this._setCallsDaterange} // prevent default space
                                style={{ margin: '0 0 0 8px' }}
                            />
                        )}

                        {channels && (
                            <Select
                                className={Styles.channels}
                                defaultValue='ALL'
                                onChange={this._setCallsChannelId}
                            >
                                <Option value='ALL'>
                                    <FormattedMessage id='all' />
                                </Option>
                                {channels.map(({ id, name }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </React.Fragment>
                }
                description={<FormattedMessage id='calls-page.description' />}
                title={<FormattedMessage id='calls-page.title' />}
            >
                <CallsContainer callsFetching={callsFetching} />
            </Layout>
        );
    }
}
