import { Table } from 'antd';
import { setModal } from 'core/modals/duck';
import {
    fetchStoreDocsDelivery,
    selectFetchingStoreDocsDelivery,
    selectStoreDocsDelivery,
    selectStoreDocsDeliveryFilters,
    selectStoreDocsDeliveryStats,
    setStoreDocsDeliveryFilters
} from 'pages/SupplierPage/redux/duck';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    storeDocsDelivery: selectStoreDocsDelivery(state),
    storeDocsDeliveryStats: selectStoreDocsDeliveryStats(state),
    storeDocsDeliveryFilters: selectStoreDocsDeliveryFilters(state),
    fetchingStoreDocs: selectFetchingStoreDocsDelivery(state)
});

const mapDispatchToProps = {
    fetchStoreDocsDelivery,
    setStoreDocsDeliveryFilters,
    setModal
};

/**
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocsDeliveryTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            storeDocsDelivery,
            fetchingStoreDocs,

            storeDocsDeliveryStats,
            storeDocsDeliveryFilters,

            setStoreDocsDeliveryFilters,
            user
        } = this.props;

        const { formatMessage } = this.props.intl;

        const pagination = {
            pageSize: 25,
            size: 'large',
            total: Math.ceil(storeDocsDeliveryStats.count / 25) * 25,
            current: storeDocsDeliveryFilters.page,
            onChange: page => {
                setStoreDocsDeliveryFilters({ page });
            }
        };

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columnsConfig({
                        user,
                        formatMessage,
                        storeDocsDeliveryStats,
                        page: storeDocsDeliveryFilters.page
                    })}
                    dataSource={storeDocsDelivery}
                    loading={fetchingStoreDocs}
                    pagination={pagination}
                    rowKey={storeDoc => `${storeDoc.storeDocId}`}
                    scroll={{ y: '30vh' }}
                    size='small'
                />
            </div>
        );
    }
}
