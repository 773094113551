import {
    BellOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    DashboardOutlined,
    ExclamationCircleOutlined,
    FilterOutlined,
    FireOutlined,
    GlobalOutlined,
    IssuesCloseOutlined,
    LoginOutlined,
    LogoutOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Radio, Tooltip } from 'antd';
import { Layout } from 'commons';
import { DateRangePicker } from 'components';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { EmployeeSubTasksModal, EmployeeTaskModal } from 'modals';
import TasksTable from 'pages/TasksPage/components/TasksTable';
import { filterValues } from 'pages/TasksPage/constants';
import {
    fetchTasks,
    selectFilters,
    selectTasksStats,
    setTasksFilters
} from 'pages/TasksPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const CheckGroup = Checkbox.Group;

const mapStateToProps = state => ({
    user: state.auth,
    filters: selectFilters(state),
    stats: selectTasksStats(state),
    isMobile: state.ui.views.isMobile
});
const mapDispatchToProps = {
    setModal,
    fetchTasks,
    setTasksFilters
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class TasksPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconFilters: [],
            showMobileFilters: false,
        };
    }

    onEmployeeTask = () => {
        this.props.setModal(MODALS.EMPLOYEE_TASK_MODAL, {
            afterCreateCallback: () => {
                this.props.fetchTasks();
            }
        });
    };

    setFilter = filter => {
        const { iconFilters } = this.state;
        const index = iconFilters.indexOf(filter);
        if (index < 0) {
            iconFilters.push(filter);
        } else {
            iconFilters.splice(index, 1);
        }
        this.props.setTasksFilters({ statuses: iconFilters });
        this.setState({ iconFilters });
    };

    handleFiltersBarChangeMine = value => {
        switch (value) {
            case filterValues.ONLYMINE:
                this.props.setTasksFilters({ onlyMine: true, onlyByMe: false });
                break;
            case filterValues.ONLYBYME:
                this.props.setTasksFilters({ onlyMine: false, onlyByMe: true });
                break;
        }
    };
    
    renderFilterIcons = () => {
        const { iconFilters } = this.state;
        const { setTasksFilters, intl: { formatMessage } } = this.props;

        return (
            <>
                <div className={Styles.filterIconUrgency}>
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='tasks_page.all' />}
                    >
                        <GlobalOutlined
                            className={
                                iconFilters.length
                                    ? Styles.filterIcon
                                    : `${Styles.filterIconSelected} ${Styles.filterIconBorderF}`
                            }
                            onClick={() => {
                                setTasksFilters({ statuses: undefined });
                                this.setState({ iconFilters: [] });
                            }}
                        />
                    </Tooltip>
                </div>
                <div className={Styles.filterIconUrgency}>
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='tasks_page.new' />}
                    >
                        <BellOutlined
                            className={
                                !iconFilters.includes('NEW')
                                    ? Styles.filterIcon
                                    : `${Styles.filterIconSelected} ${Styles.filterIconBorder}`
                            }
                            onClick={() => this.setFilter('NEW')}
                        />
                    </Tooltip>
                </div>
                <div className={Styles.filterIconUrgency}>
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='tasks_page.inwork' />}
                    >
                        <DashboardOutlined
                            className={
                                !iconFilters.includes('INWORK')
                                    ? Styles.filterIcon
                                    : `${Styles.filterIconSelected} ${Styles.filterIconBorder}`
                            }
                            onClick={() => this.setFilter('INWORK')}
                        />
                    </Tooltip>
                </div>
                <div className={Styles.filterIconUrgency}>
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='tasks_page.accept' />}
                    >
                        <IssuesCloseOutlined
                            className={
                                !iconFilters.includes('ACCEPT')
                                    ? Styles.filterIcon
                                    : `${Styles.filterIconSelected} ${Styles.filterIconBorder}`
                            }
                            onClick={() => this.setFilter('ACCEPT')}
                        />
                    </Tooltip>
                </div>
                <div className={Styles.filterIconUrgency}>
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='tasks_page.postponed' />}
                    >
                        <PauseCircleOutlined
                            className={
                                !iconFilters.includes('POSTPONED')
                                    ? Styles.filterIcon
                                    : `${Styles.filterIconSelected} ${Styles.filterIconBorder}`
                            }
                            onClick={() => this.setFilter('POSTPONED')}
                        />
                    </Tooltip>
                </div>
                <div className={Styles.filterIconUrgency}>
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='tasks_page.delegated' />}
                    >
                        <ExclamationCircleOutlined
                            className={
                                !iconFilters.includes('DELEGATED')
                                    ? Styles.filterIcon
                                    : `${Styles.filterIconSelected} ${Styles.filterIconBorder}`
                            }
                            onClick={() => this.setFilter('DELEGATED')}
                            title={formatMessage({ id: 'tasks_page.delegated' })}
                        />
                    </Tooltip>
                </div>
                <div className={Styles.filterIconUrgency}>
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='tasks_page.completed' />}
                    >
                        <CheckCircleOutlined
                            className={
                                !iconFilters.includes('COMPLETED')
                                    ? Styles.filterIcon
                                    : `${Styles.filterIconSelected} ${Styles.filterIconBorder}`
                            }
                            onClick={() => this.setFilter('COMPLETED')}
                        />
                    </Tooltip>
                </div>
                <div>
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='tasks_page.canceled' />}
                    >
                        <CloseCircleOutlined
                            className={
                                !iconFilters.includes('CANCELED')
                                    ? Styles.filterIcon
                                    : `${Styles.filterIconSelected} ${Styles.filterIconBorderL}`
                            }
                            onClick={() => this.setFilter('CANCELED')}
                        />
                    </Tooltip>
                </div>
            </>
        );
    };

    toggleMobileFilters = () => {
        this.setState((prevState) => ({ showMobileFilters: !prevState.showMobileFilters }));
    };

    render() {
        const {
            user,
            filters,
            setTasksFilters,
            intl: { formatMessage },
            isMobile
        } = this.props;
        const { iconFilters, showMobileFilters } = this.state;
        
        return (
            <Layout
                controls={
                    <div className={Styles.controlsWrapper}>
                        <RadioGroup
                            className={Styles.buttonFilterGroup}
                            defaultValue={filters.isActive}
                            onChange={e => {
                                this.props.setTasksFilters({ isActive: e.target.value });
                            }}
                        >
                            <RadioButton value={false}>
                                <Tooltip
                                    placement='bottom'
                                    title={<FormattedMessage id='tasks_page.all' />}
                                >
                                    <GlobalOutlined
                                        style={{
                                            fontSize: 18,
                                            verticalAlign: 'middle'
                                        }}
                                    />
                                </Tooltip>
                            </RadioButton>
                            <RadioButton value>
                                <Tooltip
                                    placement='bottom'
                                    title={<FormattedMessage id='tasks_page.active' />}
                                >
                                    <PlayCircleOutlined
                                        style={{
                                            fontSize: 18,
                                            verticalAlign: 'middle'
                                        }}
                                    />
                                </Tooltip>
                            </RadioButton>
                        </RadioGroup>
                        <RadioGroup
                            className={Styles.buttonFilterGroup}
                            defaultValue={filterValues.ONLYMINE}
                            onChange={e => {
                                this.handleFiltersBarChangeMine(e.target.value);
                            }}
                        >
                            <RadioButton value={filterValues.ONLYBYME}>
                                <Tooltip
                                    placement='bottom'
                                    title={<FormattedMessage id='tasks_page.from_me' />}
                                >
                                    <LogoutOutlined
                                        style={{
                                            fontSize: 18,
                                            verticalAlign: 'middle'
                                        }}
                                    />
                                </Tooltip>
                            </RadioButton>
                            <RadioButton value={filterValues.ONLYMINE}>
                                <Tooltip
                                    placement='bottom'
                                    title={<FormattedMessage id='tasks_page.for_me' />}
                                >
                                    <LoginOutlined
                                        style={{
                                            fontSize: 18,
                                            verticalAlign: 'middle'
                                        }}
                                    />
                                </Tooltip>
                            </RadioButton>
                        </RadioGroup>
                        <div>
                            {isMobile ? (
                                <div className={Styles.fixedFilterButtonWrapper}>
                                    <FilterOutlined
                                        className={Styles.fixedFilterButton}
                                        onClick={this.toggleMobileFilters}
                                    />
                                    {showMobileFilters && (
                                        <div className={Styles.filtersDropdown}>
                                            {this.renderFilterIcons()}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className={Styles.filterIconsWrap}>
                                    {this.renderFilterIcons()}
                                </div>
                            )}
                        </div>

                        <div className={isMobile ? Styles.mobileFilterIconsWrap : Styles.filterIconsWrap}>
                            <div>
                                <Tooltip
                                    placement='bottom'
                                    title={<FormattedMessage id='tasks_page.critical' />}
                                >
                                    <FireOutlined
                                        className={
                                            filters.priorities != 'HIGH'
                                                ? Styles.filterIcon
                                                : `${Styles.filterIconSelected} ${Styles.filterIconBorderF}`
                                        }
                                        onClick={() =>
                                            setTasksFilters({
                                                priorities:
                                                    filters.priorities === 'HIGH'
                                                        ? undefined
                                                        : 'HIGH'
                                            })
                                        }
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip
                                    placement='bottom'
                                    title={<FormattedMessage id='tasks_page.overdue' />}
                                >
                                    <ClockCircleOutlined
                                        className={
                                            filters.overdue != true
                                                ? Styles.filterIcon
                                                : `${Styles.filterIconSelected} ${Styles.filterIconBorderL}`
                                        }
                                        onClick={() =>
                                            setTasksFilters({ overdue: !filters.overdue })
                                        }
                                    />
                                </Tooltip>
                            </div>
                        </div>

                        <DateRangePicker
                            allowClear
                            dateRange={[
                                this.props.filters.scheduledDatetimeFrom
                                    ? dayjs(this.props.filters.scheduledDatetimeFrom)
                                    : undefined,
                                this.props.filters.scheduledDatetimeTo
                                    ? dayjs(this.props.filters.scheduledDatetimeTo)
                                    : undefined
                            ]}
                            minimize
                            onDateChange={daterange => {
                                const [startDate, endDate] = daterange;
                                this.props.setTasksFilters({
                                    scheduledDatetimeFrom: startDate,
                                    scheduledDatetimeTo: endDate,
                                    page: 1
                                });
                            }}
                            style={{ float: 'right', margin: '0px 6px 0 0' }}
                        />
                        <Tooltip
                            placement='bottom'
                            title={<FormattedMessage id='tasks_page.add_task' />}
                        >
                            <Button
                                disabled={isForbidden(user, permissions.ACCESS_EMPLOYEE_TASKS)}
                                onClick={() => {
                                    this.onEmployeeTask();
                                }}
                                style={{
                                    margin: '0 15px 0 0'
                                }}
                                type='primary'
                            >
                                <PlusOutlined
                                    style={{
                                        fontSize: 18,
                                        verticalAlign: 'middle'
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    </div>
                }
                title={<FormattedMessage id='tasks_page.title' />}
            >
                <TasksTable />

                <EmployeeTaskModal />
                <EmployeeSubTasksModal />
            </Layout>
        );
    }
}
