import { Radio } from 'antd';
import _ from 'lodash';
import { OPERATION_TYPES } from 'modals/DebtZeroingModal/constants';
import {
    selectServiceOperationType,
    setServiceOperationType
} from 'modals/DebtZeroingModal/redux/duck';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    serviceOperationType: selectServiceOperationType(state)
});

const mapDispatchToProps = {
    setServiceOperationType
};

/**
 * Types of operations that cash order modal has to perform.
 * Different types of cash orders will be generated depending on it.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OperationTypeSelector extends React.Component {
    render() {
        const { serviceOperationType, setServiceOperationType } = this.props;

        return (
            <span className={Styles.mainCont}>
                <Radio.Group
                    buttonStyle='solid'
                    onChange={e => setServiceOperationType(e.target.value)}
                    size='small'
                    value={serviceOperationType}
                >
                    {_.map(OPERATION_TYPES, (type, index) => {
                        return (
                            <Radio.Button key={index} value={type}>
                                <FormattedMessage id={`debt_zeroing_modal.${type}`} />
                            </Radio.Button>
                        );
                    })}
                </Radio.Group>
            </span>
        );
    }
}
