/* Constants */
export const moduleName = 'selectOrderModal';
const prefix = `cpb/${moduleName}`;

export const FETCH_ORDERS = `${prefix}/FETCH_ORDERS`;
export const FETCH_ORDERS_SUCCESS = `${prefix}/FETCH_ORDERS_SUCCESS`;

export const SET_ORDERS_PAGE = `${prefix}/SET_ORDERS_PAGE`;
export const SET_ORDERS_FETCHING = `${prefix}/SET_ORDERS_FETCHING`;
export const SET_ORDERS_SEARCH_QUERY = `${prefix}/SET_ORDERS_SEARCH_QUERY`;
export const SET_SELECTED_ORDER_ID = `${prefix}/SET_SELECTED_ORDER_ID`;

/* Reducer */

const ReducerState = {
    selectedOrderId: undefined,
    ordersFetching: false,

    ordersData: {
        orders: [],
        stats: {},
        query: {
            // Filters
            page: 1,
            status: 'call,redundant,approve,not_complete,progress,reserve,required,invite,processing',
            query: undefined
        }
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_ORDERS_SUCCESS:
            const { orders, stats: ordersStats } = payload;

            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    orders,
                    stats: ordersStats
                }
            };

        case SET_ORDERS_PAGE:
            const { page } = payload;

            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    query: {
                        ...state.ordersData.query,
                        page
                    }
                }
            };

        case SET_ORDERS_SEARCH_QUERY:
            const { query } = payload;

            return {
                ...state,
                ordersData: {
                    ...state.ordersData,
                    query: {
                        ...state.ordersData.query,
                        query
                    }
                }
            };

        case SET_ORDERS_FETCHING:
            return {
                ...state,
                ordersFetching: payload
            };

        case SET_SELECTED_ORDER_ID:
            const { orderId } = payload;

            return {
                ...state,
                selectedOrderId: orderId
            };

        default:
            return state;
    }
}

/* Selectors */

// ----------------- Orders --------------------------------
export const selectOrders = state => state[moduleName].ordersData.orders;
export const selectOrdersStats = state => state[moduleName].ordersData.stats;
export const selectOrdersQuery = state => state[moduleName].ordersData.query;
export const selectOrdersFetching = state => state[moduleName].ordersFetching;

export const selectSelectedOrderId = state => state[moduleName].selectedOrderId;

/* Actions */

export const fetchOrders = () => ({
    type: FETCH_ORDERS
});

export const fetchOrdersSuccess = ({ orders, stats }) => ({
    type: FETCH_ORDERS_SUCCESS,
    payload: { orders, stats }
});

export const setOrdersPage = ({ page }) => {
    return function (dispatch) {
        dispatch({
            type: SET_ORDERS_PAGE,
            payload: { page }
        });
        dispatch(fetchOrders());
    };
};

export const setOrdersFetching = value => ({
    type: SET_ORDERS_FETCHING,
    payload: value
});

export const setOrdersSearchQuery = ({ query }) => {
    return function (dispatch) {
        dispatch({
            type: SET_ORDERS_SEARCH_QUERY,
            payload: { query }
        });
        dispatch(fetchOrders());
    };
};

export const setSelectedOrderId = ({ orderId }) => ({
    type: SET_SELECTED_ORDER_ID,
    payload: { orderId }
});
