import { Button, Checkbox, Select, Table } from 'antd';
import { Layout } from 'commons';
import { SingleDatePicker } from 'components';
import dayjs from 'dayjs';
import { LocationHistoryModal, VehicleLocationModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;
const WORK_POST = 'WORK_POST';
const INTERNAL_PARKING = 'INTERNAL_PARKING';
const EXTERNAL_PARKING = 'EXTERNAL_PARKING';
const OTHER = 'OTHER';
const TYPES = [WORK_POST, INTERNAL_PARKING, EXTERNAL_PARKING, OTHER];

const mapStateToProps = state => ({
    user: state.auth
});

@connect(mapStateToProps, void 0)
export default class LocationsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataSource: [],
            includeExternal: true,
            historyModalVisible: false,
            actionModalVisible: false,
            modalLocation: undefined,
            toDatetime: dayjs()
        };

        const {user} = this.props;
        const disabled = !isGrantAccessed(user, grants.OPERATIONS_LOCATIONS_LOCATION_LIST, accesses.ROWO)

        this.columns = [
            {
                key: 'key',
                dataIndex: 'key',
                render: (data, row) => {
                    return data + 1;
                }
            },
            {
                title: <FormattedMessage id='location' />,
                key: 'name',
                dataIndex: 'name',
                width: 'auto'
            },
            {
                title: <FormattedMessage id='locations.type' />,
                key: 'type',
                dataIndex: 'type',
                width: 'auto',
                render: (data, elem) => {
                    return data ? <FormattedMessage id={`locations.${data}`} /> : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='locations.count' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'usedCount',
                dataIndex: 'usedCount',
                width: 'auto',
                render: (data, row) => {
                    return !isGrantAccessed(this.props.user, grants.OPERATIONS_LOCATIONS_CARS_BY_LOCATIONS) ? (
                        data
                    ) : (
                        <Link
                            className={Styles.countLink}
                            to={{
                                pathname: book.locationsVehicles,
                                state: { locationId: row.id }
                            }}
                        >
                            {data || 0}
                        </Link>
                    );
                }
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='locations.volume' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'volume',
                dataIndex: 'volume',
                width: 'auto'
            },
            {
                title: (
                    <div className={Styles.numberColumn}>
                        <FormattedMessage id='locations.percent' />
                    </div>
                ),
                className: Styles.numberColumn,
                key: 'percent',
                width: 'auto',
                render: row => {
                    const percent = (row.usedCount || 0) / row.volume;

                    return `${Math.round(percent * 100)}%`;
                }
            },
            {
                key: 'accept',
                width: 'min-content',
                render: data => {
                    return (
                        <Button
                            onClick={() => {
                                this.setState({
                                    actionModalVisible: true,
                                    modalLocation: data.id
                                });
                            }}
                            type='primary'
                            disabled={disabled}
                        >
                            <FormattedMessage id='locations.accept' />
                        </Button>
                    );
                }
            },
            {
                key: 'history',
                width: 'min-content',
                render: data => {
                    return (
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                this.setState({
                                    historyModalVisible: true,
                                    modalLocation: data.id
                                });
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='locations.history' />
                        </Button>
                    );
                }
            }
        ];
    }

    getLocations() {
        this.setState({ loading: true });
        const { toDatetime } = this.state;
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        const url = `${__API_URL__}/business_locations?toDatetime=${toDatetime.format('YYYY-MM-DD')}`;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                data.map((elem, key) => {
                    elem.key = key;
                });
                that.setState({
                    dataSource: data,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log('error', error);
                that.setState({ loading: false });
            });
    }

    componentDidMount() {
        this.getLocations();
    }

    render() {
        const {user} = this.props;
        const {
            dataSource,
            loading,
            includeExternal,
            historyModalVisible,
            actionModalVisible,
            modalLocation,
            toDatetime,
        } = this.state;
        let totalCount = 0;
        let totalVolume = 0;
        dataSource.map(elem => {
            if (includeExternal || elem.type != EXTERNAL_PARKING) {
                totalCount += elem.usedCount || 0;
                totalVolume += elem.volume;
            }
        });

        return (
            <Layout
                controls={
                    <div>
                        <SingleDatePicker
                            disabled={!isGrantAccessed(user, grants.OPERATIONS_LOCATIONS_LOCATION_LIST, accesses.ROWO)}
                            date={toDatetime}
                            onDateChange={async date => {
                                await this.setState({
                                    toDatetime: date
                                });
                                this.getLocations();
                            }}
                            style={{ margin: '0 8px' }}
                        />
                    </div>
                }
                title={<FormattedMessage id='navigation.locations' />}
            >
                <div className={Styles.header}>
                    <div className={Styles.headerBlock}>
                        <FormattedMessage id='locations.include_external_locations' />
                        <div>
                            <Checkbox
                                checked={includeExternal}
                                onChange={({ target }) => {
                                    this.setState({
                                        includeExternal: target.checked
                                    });
                                }}
                                style={{ margin: '0 0 0 8px' }}
                            />
                        </div>
                    </div>
                    <div className={Styles.headerBlock}>
                        <FormattedMessage id='locations.total_count' />
                        <div>
                            {!isGrantAccessed(this.props.user, grants.OPERATIONS_LOCATIONS_CARS_BY_LOCATIONS) ? (
                                totalCount
                            ) : (
                                <Link
                                    className={Styles.countLink}
                                    to={{
                                        pathname: book.locationsVehicles
                                    }}
                                >
                                    {totalCount}
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className={Styles.headerBlock}>
                        <FormattedMessage id='locations.total_volume' />
                        <div>{totalVolume}</div>
                    </div>
                    <div className={Styles.headerBlock}>
                        <FormattedMessage id='locations.percent' />
                        <div>{Math.round((totalCount * 100) / totalVolume)}%</div>
                    </div>
                </div>
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={false}
                    rowClassName={row => (!includeExternal && row.type == EXTERNAL_PARKING ? Styles.disabledRow : null)}
                    size='small'
                    style={{ padding: 12 }}
                />
                <VehicleLocationModal
                    hideModal={() => {
                        this.setState({
                            actionModalVisible: false,
                            modalLocation: undefined
                        });
                    }}
                    modalVisible={actionModalVisible}
                    onConfirm={() => this.getLocations()}
                    receiveMode
                    selectedLocation={modalLocation}
                />
                <LocationHistoryModal
                    hideModal={() => {
                        this.setState({
                            historyModalVisible: false,
                            modalLocation: undefined
                        });
                    }}
                    selectedLocation={modalLocation}
                    visible={historyModalVisible}
                />
            </Layout>
        );
    }
}
