import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm, Select, Table, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import AddPackagesModal from './AddPackagesModal';

const { Option } = Select;
const { TextArea } = Input;

const AditionalPackagesModal = props => {
    const {
        open,
        hideModal,
        intl: { formatMessage },
        fetchEntries,
        fetchAdditionPackages,
        loadingRows,
        rows
    } = props;

    const [edit, setEdit] = useState(undefined);
    const [openPackagesModal, setOpenPackagesModal] = useState(false);
    const [defaultPackages, setDefaultPackages] = useState([]);

    // const setData = row => {
    //     setAccountId(row.accountId || undefined);
    //     setAnalyticsId(row.analyticsId || undefined);
    //     setAmountSignature(row.amountSignature || undefined);
    //     setFormula(row.amountFormula || undefined);
    //     setOperationType(row.operationType || undefined);
    //     setComment(row.comment || undefined);
    //     setId(row.id || undefined);

    //     setEdit(undefined);
    // };

    const columns = useMemo(() => {
        return [
            {
                title: () => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Tooltip title={<FormattedMessage id='entries_tab.add_rows' />}>
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={async () => {
                                        await setOpenPackagesModal(true);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                },
                key: 'actions',
                width: '8%',
                render: (row, number, index) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Popconfirm
                                onConfirm={async () => {
                                    await fetchAPI(
                                        'DELETE',
                                        '/v2/roles_and_accesses/standard_packages',
                                        null,
                                        { packageId: row.id },
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    await setTimeout(() => fetchAdditionPackages(), 100);
                                }}
                                title={`${formatMessage({ id: 'delete' })}?`}
                            >
                                <Button icon={<DeleteOutlined />} />
                            </Popconfirm>

                            <Button
                                icon={<EditOutlined />}
                                onClick={async () => {
                                    await setOpenPackagesModal(true);
                                    setEdit(row);
                                }}
                            />
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='packages.package_name' />,
                key: 'name',
                dataIndex: 'name',
                width: '15%'
            },
            {
                title: <FormattedMessage id='packages.default_package' />,
                key: 'def_package',
                width: '10%',
                render: row => {
                    return row.createdFromPackageName ? (
                        row.createdFromPackageName
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            }
        ];
    }, [formatMessage, fetchAdditionPackages]);

    // useEffect(() => {
    //     if (open) {
    //         fetchAdditionPackages();
    //     }
    // }, [open, fetchAdditionPackages]);

    const fetchDefaultPackages = useMemo(
        () => async () => {
            const res = await fetchAPI(
                'GET',
                '/v2/roles_and_accesses/standard_packages/simple_list',
                { isAdditional: false },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setDefaultPackages(res);
        },
        []
    );

    const onClose = () => {
        hideModal();
    };

    return (
        <div>
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                footer={null}
                maskClosable={false}
                onCancel={() => onClose()}
                open={open}
                title={<FormattedMessage id='packages.aditional_packages' />}
                width='50%'
            >
                <Table
                    columns={columns}
                    dataSource={rows}
                    loading={loadingRows}
                    pagination={false}
                    scroll={{ x: 'auto' }}
                    size='small'
                />
            </Modal>
            <AddPackagesModal
                defaultPackages={defaultPackages}
                edit={edit}
                fetchAdditionPackages={fetchAdditionPackages}
                fetchDefaultPackages={fetchDefaultPackages}
                hideModal={() => {
                    setOpenPackagesModal(false);
                    setEdit(undefined);
                }}
                open={openPackagesModal}
            />
        </div>
    );
};

export default injectIntl(AditionalPackagesModal);
