exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__error---tW0xv{display:flex;justify-content:center;transform:translateY(50%)}.styles-m__errorData---1dpGl{display:flex;flex-direction:column;justify-content:space-around;margin-left:100px}.styles-m__errorCode---6EF3M{color:var(--text3);font-size:72px;font-weight:600;line-height:72px}.styles-m__errorDesc---29UAq{color:var(--text2);text-transform:uppercase}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,sBAAuB,CACvB,yBACJ,CAEA,6BACI,YAAa,CACb,qBAAsB,CACtB,4BAA6B,CAC7B,iBACJ,CAEA,6BACI,kBAAmB,CACnB,cAAe,CACf,eAAgB,CAChB,gBACJ,CAEA,6BACI,kBAAmB,CACnB,wBACJ","file":"styles.m.css","sourcesContent":[".error {\n    display: flex;\n    justify-content: center;\n    transform: translate(0, 50%);\n}\n\n.errorData {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    margin-left: 100px;\n}\n\n.errorCode {\n    color: var(--text3);\n    font-size: 72px;\n    font-weight: 600;\n    line-height: 72px;\n}\n\n.errorDesc {\n    color: var(--text2);\n    text-transform: uppercase;\n}\n"]}]);

// Exports
exports.locals = {
	"error": "styles-m__error---tW0xv",
	"errorData": "styles-m__errorData---1dpGl",
	"errorCode": "styles-m__errorCode---6EF3M",
	"errorDesc": "styles-m__errorDesc---29UAq"
};