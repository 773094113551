'use strict';

// External
const util = require('util');
const zlib = require('zlib');

// Internal
const deflate = util.promisify(zlib.deflate);
const inflate = util.promisify(zlib.inflate);

async function compressTemplate(content) {
    try {
      const buffer = await deflate(content);
      return buffer;
    } catch (err) {
      console.log('COMPRESSION TEMPLATE ERROR: ', err);
      throw err;
    }
  }
  
  async function decompressTemplate(buffer) {
    try {
      const decompressedBuffer = await inflate(buffer);
      return decompressedBuffer.toString('utf-8');
    } catch (err) {
      console.log('DECOMPRESSION TEMPLATE ERROR: ', err);
      throw err;
    }
}

module.exports = {
    compressTemplate,
    decompressTemplate,
  };
  