import { Button, Modal, Table } from 'antd';
import { Catcher } from 'commons';
import { AddClientVehicleForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

@injectIntl
class ClientsVehiclesTable extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.make'
                }),
                dataIndex: 'makeName',
                key: 'makeName'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.model'
                }),
                dataIndex: 'modelName',
                key: 'modelName'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.year'
                }),
                dataIndex: 'year',
                key: 'year'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.modification'
                }),
                dataIndex: 'modificationName',
                key: 'modificationName'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.vin'
                }),
                dataIndex: 'vin',
                key: 'vin'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.number'
                }),
                dataIndex: 'number',
                key: 'number'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.number'
                }),
                dataIndex: 'managerName',
                key: 'manager'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.number'
                }),
                dataIndex: 'managerPhone',
                key: 'phone'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.comment'
                }),
                dataIndex: 'comment',
                key: 'comment'
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'edit'
                }),
                key: 'edit-vehicle',
                render: (text, record) => {
                    return (
                        <EditVheliceModal
                            addClientVehicle={this.props.addClientVehicle}
                            index={record.index}
                            removeClientVehicle={this.props.removeClientVehicle}
                            vehicle={this.props.vehicles[record.index]}
                            vehicleTypes={this.props.vehicleTypes}
                        />
                    );
                }
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'add_client_form.delete_vehicle'
                }),
                key: 'delete-vehicle',
                render: (text, record) => (
                    <Button
                        data-qa='btn_delete_vehicle_clients_vehicles_table_order_page'
                        onClick={() => this.props.removeClientVehicle(record.index)}
                        type='danger'
                    >
                        <FormattedMessage id='add_client_form.delete_vehicle' />
                    </Button>
                )
            }
        ];
    }

    render() {
        const { vehicles } = this.props;
        const { columns } = this;

        return (
            <Catcher>
                <Table
                    bordered
                    className={Styles.clientsVehiclesTable}
                    columns={columns}
                    dataSource={vehicles.map((vehicle, index) => ({
                        ...vehicle,
                        index,
                        key: v4()
                    }))}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                />
            </Catcher>
        );
    }
}

class EditVheliceModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    editClientVehicle = vehicle => {
        const { addClientVehicle, removeClientVehicle, index } = this.props;
        removeClientVehicle(index);
        addClientVehicle(vehicle);
        this.handleCancel;
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        const { vehicle, addClientVehicle, removeClientVehicle, index, vehicleTypes } = this.props;

        const { visible } = this.state;

        return (
            <React.Fragment>
                <Button
                    data-qa='btn_edit_vehicle_clients_vehicles_table_order_page'
                    onClick={this.showModal}
                    type='primary'
                >
                    <FormattedMessage id='edit' />
                </Button>
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='edit' />}
                    visible={visible}
                >
                    <AddClientVehicleForm
                        {...vehicle}
                        editClientVehicle={this.editClientVehicle}
                        editMode
                        vehicleTypes={vehicleTypes}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

export default ClientsVehiclesTable;
