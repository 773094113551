import { Form } from '@ant-design/compatible';
import { Button, Checkbox, Col, InputNumber, Modal, Row, Select } from 'antd';
import { resetModal, selectModal } from 'core/modals/duck';
import { createSupplier } from 'core/suppliers/duck';
import JsBarcode from 'jsbarcode'; // https://github.com/lindell/JsBarcode/wiki/Options
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { getCurrency } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modal: selectModal(state)
});

const mapDispatchToProps = {
    createSupplier,
    resetModal
};

const { Option } = Select;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class NewBarcodePrintModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resolution: '60x30',
            cf: 1,
            barcodeFontSize: 14
        };

        this.id = _.uniqueId('barcode-');

        this.componentRef = React.createRef();

        this.defaultModalBarcodeOptions = () => ({
            // format: "EAN13",
            lineColor: '#000',
            background: 'transparent',
            width: 2 * this.state.cf,
            height: 90 * this.state.cf,
            fontSize: 24,
            fontOptions: '',
            textAlign: 'center',
            textPosition: 'bottom',
            textMargin: 2,
            margin: 0,
            marginTop: undefined,
            marginBottom: undefined,
            marginLeft: undefined,
            marginRight: undefined,
            flat: true,
            displayValue: true
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible && this.props.visible && this.props.code) {
            this.updateBarcode();
        }
    }

    updateBarcode = () => {
        const { code } = this.props;
        const { id } = this;

        try {
            JsBarcode(`#${id}`, code, this.defaultModalBarcodeOptions());
        } catch (e) {}
    };

    render() {
        const {
            visible,
            hideModal,
            product: pr,
            intl: { formatMessage },
            empData
        } = this.props;
        const product = pr || {};
        const { resolution, code, name, price, surname, position, cf, fullSize, barcodeFontSize } = this.state;


        const width = fullSize ? '100%' : resolution === '40x25'
            ? 40 * cf
            : resolution === '122x60' || resolution === '122x30'
            ? 122 * cf
            : 60 * cf;
        const height = fullSize ? '100%' :  resolution === '60x20'
            ? 20 * cf
            : resolution === '60x30' || resolution === '122x30'
            ? 30 * cf
            : resolution === '60x40'
            ? 40 * cf
            : resolution === '60x50'
            ? 50 * cf
            : resolution === '40x25'
            ? 25 * cf
            : 60 * cf;

        return (
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                footer={
                    <ReactToPrint
                        content={() => this.componentRef.current}
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.

                            return (
                                <div>
                                    <Button onClick={hideModal} type='primary'>
                                        <FormattedMessage id='print' />
                                    </Button>
                                </div>
                            );
                        }}
                    />
                }
                maskClosable={false}
                onCancel={hideModal}
                open={visible}
                title={<FormattedMessage id='barcode.print_settings' />}
            >
                <Form>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='barcode.format' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Select onChange={resolution => this.setState({ resolution })} value={resolution}>
                                <Option value='40x25'>40x25</Option>
                                <Option value='60x20'>60x20</Option>
                                <Option value='60x30'>60x30</Option>
                                <Option value='60x40'>60x40</Option>
                                <Option value='60x50'>60x50</Option>
                                <Option value='60x60'>60x60</Option>
                                <Option value='122x30'>122x30</Option>
                                <Option value='122x60'>122x60</Option>
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='100%' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Checkbox checked={fullSize} onChange={() => this.setState({ fullSize:!fullSize })} />
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='barcode.multiplier' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <InputNumber 
                                disabled={fullSize} 
                                onChange={cf => {this.setState({ cf }); this.updateBarcode()}} value={cf} 
                                max={10}
                                min={1}
                            />
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='barcode.font_size' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <InputNumber onChange={barcodeFontSize => { this.setState({ barcodeFontSize }); this.updateBarcode() }} value={barcodeFontSize} />
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    {!empData && (
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.show' />
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.product_code' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={code} onChange={() => this.setState({ code: !code })} />
                            </Col>
                        </Row>
                    )}
                    {!empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.name' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={name} onChange={() => this.setState({ name: !name })} />
                            </Col>
                        </Row>
                    )}
                    {!empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.price' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={price} onChange={() => this.setState({ price: !price })} />
                            </Col>
                        </Row>
                    )}

                    {empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.name_surname' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={surname} onChange={() => this.setState({ surname: !surname })} />
                            </Col>
                        </Row>
                    )}
                    {empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.position' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={position} onChange={() => this.setState({ position: !position })} />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <div
                            ref={this.componentRef}
                            className={Styles.barcode}
                            style={{
                                overflow: 'hidden',
                                width,
                                maxWidth: width
                            }}
                        >
                            <div>
                                <canvas
                                    id={this.id}
                                    style={{
                                        width,
                                        height
                                    }}
                                ></canvas>
                            </div>

                            <Fragment>
                                {name && <div style={{ fontWeight: 700, fontSize: barcodeFontSize, whiteSpace: 'nowrap' }}>{product ? product.name : name}</div>}
                                {code && (
                                    <div style={{ fontWeight: 700, fontSize: barcodeFontSize, whiteSpace: 'nowrap' }}>{product ? product.code : code}</div>
                                )}
                                {price && (
                                    <div style={{ fontWeight: 400, fontSize: barcodeFontSize }}>
                                        {product ? _.get(product, 'sellingPrice', 0).toFixed(2) : price} {getCurrency()}
                                    </div>
                                )}
                                {surname && (
                                    <div style={{ fontWeight: 700, fontSize: barcodeFontSize }}>
                                        {empData.name} {empData.surname}
                                    </div>
                                )}
                                {position && (
                                    <div style={{ fontWeight: 400, fontSize: barcodeFontSize }}>
                                        {formatMessage({
                                            id: `employee_salary_rules.${_.get(empData.posts, '[0].postName')}`
                                        })}
                                    </div>
                                )}
                            </Fragment>
                        </div>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
