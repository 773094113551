import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table, notification } from 'antd';
import { Numeral } from 'commons';
import { DateRangePicker } from 'components/DatePicker';
import { getClientData } from 'core/requisiteSettings/saga';
import { DebtPairResultsModal, UnpairAllActionsBtnsModal } from 'modals';
import { fetchBusinessRequisites, selectBusinessRequisites } from 'modals/AccountsReceivablesReportModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getCurrency, journalTypes } from 'utils';
import Styles from './styles.m.css';
import columnsConfig from './tableConfig';

const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    businessRequisites: selectBusinessRequisites(state)
});

const mapDispatchToProps = {
    fetchBusinessRequisites,
    getClientData
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientMRDsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pairSelectedIds: [],
            clientRequisites: []
        };
    }

    createPair = async () => {
        const { user } = this.props;
        const { pairSelectedIds, pairSign: cashOrderSign, singleType } = this.state;

        await fetchAPI('GET', 'debts/pairing', {
            cashOrderSign: singleType === 'CASH' ? cashOrderSign : cashOrderSign === '+' ? '-' : '+',
            pairingIds: cashOrderSign === '+' ? pairSelectedIds.slice(1) : [pairSelectedIds[0]],
            cashOrderIds: cashOrderSign === '+' ? [pairSelectedIds[0]] : pairSelectedIds.slice(1),
            managerId: user.employeeId
        });
        this.setState({
            pairSelectedIds: [],
            pairSign: undefined
        });
        this.props.updateData();
    };

    deletePair = async ({ pairingIds, cashOrderSign, cashOrderId }) => {
        try {
            await fetchAPI(
                'DELETE',
                'debts/pairing',
                {
                    pairingIds,
                    cashOrderSign,
                    cashOrderId
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            this.setState({
                pairSelectedIds: [],
                pairSign: undefined
            });
            this.props.updateData();
        } catch (err) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'client-mrds-table.unpair_error' })
            });
        }
    };

    unpairAll = async () => {
        const { clientId } = this.props;

        // await fetchAPI('DELETE', 'debts/client/unpair', {
        //     clientId
        // });
        // this.props.updateData();
    };

    fetchClientRequisites = async () => {
        const { clientId } = this.props;
        const { requisites } = await fetchAPI('GET', `clients/${clientId}`, null, null, {
            handleErrorInternally: true
        });
        this.setState({ clientRequisites: requisites });
    };

    componentDidMount() {
        this.props.fetchBusinessRequisites();
        this.fetchClientRequisites();
    }

    getColumnSearchProps = dataIndex => {
        let filterComponent = (confirm, clearFilters) => (
            <Input
                ref={node => {
                    this.searchInput = node;
                }}
                onChange={e => {
                    this.props.setFilters({ filterDocumentType: e.target.value });
                }}
                onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                style={{ marginBottom: 8, display: 'block', width: 180 }}
                value={this.props.filterDocumentType}
            />
        );

        if (dataIndex === 'datetimeFrom') {
            filterComponent = (confirm, clearFilters) => (
                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                    <DateRangePicker
                        allowClear
                        dateRange={[this.props.datetimeFrom, this.props.datetimeTo]}
                        format={dateFormat}
                        getPopupContainer={trigger => trigger.parentNode}
                        onDateChange={async dateRange => {
                            await this.props.setFilters({
                                datetimeFrom: dateRange[0],
                                datetimeTo: dateRange[1]
                            });
                            this.handleSearch(confirm, dataIndex);
                            this.props.updateData();
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            );
        }

        if (dataIndex === 'filterDocumentType') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    onChange={value => {
                        this.props.setFilters({
                            filterDocumentType: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 275 }}
                    value={this.props.filterDocumentType}
                >
                    {journalTypes.map(type => (
                        <Option key={type} value={type}>
                            {type} - <FormattedMessage id={`storage_document.docType.${type}`} />
                        </Option>
                    ))}
                </Select>
            );
        }

        if (dataIndex === 'filterSign') {
            filterComponent = (confirm, clearFilters) => (
                <Select
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={value => {
                        this.props.setFilters({
                            filterSign: value
                        });
                    }}
                    // mode='multiple'
                    placeholder={this.props.intl.formatMessage({
                        id: 'storage_journal.choose_type'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                    value={this.props.filterSign}
                >
                    <Option value='+'>
                        <FormattedMessage id='storage.sign_+' />
                    </Option>
                    <Option value='-'>
                        <FormattedMessage id='storage.sign_-' />
                    </Option>
                </Select>
            );
        }

        if (dataIndex === 'filterBusinessRequisiteId' || dataIndex === 'filterCounterpartRequisiteId') {
            filterComponent = (confirm, clearFilters) => (
                <div
                    onKeyDown={e => e.stopPropagation()}
                    style={{
                        padding: 8
                    }}
                >
                    <Select
                        allowClear
                        onChange={value => {
                            this.props.setFilters({
                                [dataIndex]: value
                            });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'navigation.requisites'
                        })}
                        showSearch
                        style={{ width: 280 }}
                        value={this.props[dataIndex]}
                    >
                        {dataIndex === 'filterBusinessRequisiteId'
                            ? this.props.businessRequisites.map(elem => (
                                  <Select.Option key={elem.id} value={elem.id}>
                                      {elem.name}
                                  </Select.Option>
                              ))
                            : this.state.clientRequisites.map(elem => (
                                  <Select.Option key={elem.id} value={elem.id}>
                                      {elem.name}
                                  </Select.Option>
                              ))}
                    </Select>
                </div>
            );
        }

        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {filterComponent(confirm, clearFilters)}
                    {dataIndex !== 'datetimeFrom' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                icon={<SearchOutlined style={{ marginRight: 4 }} />}
                                onClick={() => this.handleSearch(confirm, dataIndex)}
                                size='small'
                                type='primary'
                            >
                                <FormattedMessage id='search' />
                            </Button>
                            <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                <FormattedMessage id='reset' />
                            </Button>
                        </div>
                    )}
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.props[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.props.setMRDsPage(1, this.props.pageSize);
        this.props.updateData();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.props.setFilters({ [dataIndex]: undefined, page: 1 });
        this.props.updateData();
    };

    render() {
        const {
            fetchMRDs,
            isFetching,
            mrds,
            stats,
            setMRDsPage,
            clientMRDsPage,
            pageSize,
            clientId,
            showCashOrderModal,
            intl: { formatMessage },
            user
        } = this.props;

        const {
            pairSelectedIds,
            pairSign,
            singleType,
            visibleDebtPairResultsModal,
            visibleUnpairAllActionsBtnsModal,
            id
        } = this.state;

        const pagination = {
            pageSize,
            pageSizeOptions: ['50', '75', '100'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
            total: stats && stats.count ? stats.count : 25,
            current: clientMRDsPage,
            hideOnSinglePage: true,
            onChange: (page, pageSize) => {
                setMRDsPage(page, pageSize);
                fetchMRDs({ clientId });
            }
        };

        // if (isFetching) {
        //     return <Loader loading={isFetching} />;
        // }

        return (
            <div className={Styles.tableContainer}>
                <Table
                    bordered
                    columns={columnsConfig({
                        user,
                        formatMessage,
                        showCashOrderModal,
                        pairSelectedIds,
                        id,
                        pairSign,
                        singleType,
                        onPair: ({ id, sign, cashOrderId }) => {
                            if (pairSelectedIds.includes(id)) {
                                this.setState({
                                    pairSelectedIds: pairSelectedIds.filter(el => el !== id),
                                    pairSign: pairSelectedIds.length > 1 ? pairSign : undefined,
                                    singleType: pairSelectedIds.length > 1 ? singleType : undefined
                                });
                            } else {
                                pairSelectedIds.push(id);
                                this.setState({
                                    pairSelectedIds,
                                    pairSign: pairSign || sign,
                                    singleType: singleType || cashOrderId ? 'CASH' : 'ORDER'
                                });
                            }
                        },
                        onUnpair: this.deletePair,
                        createPair: this.createPair,
                        showDebtPairResultsModal: row => this.setState({ visibleDebtPairResultsModal: row }),
                        unpairAllActionsBtns: row => this.setState({ visibleUnpairAllActionsBtnsModal: row }),
                        getColumnSearchProps: this.getColumnSearchProps,
                        handleSearch: this.handleSearch,
                        handleReset: this.handleReset
                    })}
                    dataSource={mrds}
                    loading={isFetching}
                    // expandedRowRender={record => {
                    //     return <NestedCashOrdersTable nestedCashOrders={record.cashOrders} />;
                    // }}
                    locale={{
                        emptyText: <FormattedMessage id='client-mrds-table.data_missing' />
                    }}
                    pagination={pagination}
                    rowClassName={record => {
                        if (record.isOverdue) {
                            return Styles.overdueMRD;
                        }
                    }}
                    rowKey='rowId'
                    scroll={{ x: 1000 }}
                    size='small'
                    summary={() => {
                        return (
                            <React.Fragment>
                                <Table.Summary.Row fixed>
                                    <Table.Summary.Cell className={Styles.hidden} index={0}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.hidden} index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.summaryWrap} index={7}>
                                        <div className={Styles.summary}>
                                            <Numeral mask='0,0.00'>{_.get(stats, 'oweAmount.sum', 0)}</Numeral>
                                            {getCurrency()}
                                        </div>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className={Styles.summaryWrap} index={8}>
                                        <div className={Styles.summary}>
                                            <Numeral mask='0,0.00'>{_.get(stats, 'oweAmount.debt', 0)}</Numeral>
                                            {getCurrency()}
                                        </div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </React.Fragment>
                        );
                    }}
                />
                <DebtPairResultsModal
                    hideModal={() => {
                        this.setState({ visibleDebtPairResultsModal: false });
                    }}
                    row={visibleDebtPairResultsModal}
                    visible={Boolean(visibleDebtPairResultsModal)}
                />
                <UnpairAllActionsBtnsModal
                    hideModal={() => {
                        this.setState({ visibleUnpairAllActionsBtnsModal: false });
                    }}
                    row={visibleUnpairAllActionsBtnsModal}
                    unpairAll={this.unpairAll}
                    visible={Boolean(visibleUnpairAllActionsBtnsModal)}
                />
            </div>
        );
    }
}
