import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { DateRangePicker, StorageMovementTotals, StoreMovementTable, WarehouseSelect } from 'components';
import {
    selectStoreMovementFilters,
    selectStoreMovementLoading,
    setStoreMovementFilters
} from 'core/storage/storeMovement';
import dayjs from 'dayjs';
import { get, isArray } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    filters: selectStoreMovementFilters(state),
    loading: selectStoreMovementLoading(state)
});

export const StorageMovementPage = connect(mapStateToProps, { setStoreMovementFilters })(props => {
    const { loading, filters } = props;

    const [period, setPeriod] = useState('month');

    const [helperDrawerOpen, sethelperDrawerOpen] = useState(false);
    const [allLinks, setAllLinks] = useState([]);

    const fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'storage_movement' }, undefined, {
            handleErrorInternally: true
        });
        setAllLinks(links);
    };

    const setDaterange = daterange => {
        const [startDate, endDate] = daterange;
        props.setStoreMovementFilters({ startDate, endDate });
    };

    const handlePeriod = period => {
        setPeriod(period);
        period === 'month'
            ? setDaterange([dayjs(filters.endDate).subtract(30, 'days'), dayjs(filters.endDate)])
            : setDaterange([dayjs(filters.endDate).subtract(1, period), dayjs(filters.endDate)]);
    };

    return (
        <Layout
            controls={
                <div style={{ display: 'flex' }}>
                    <WarehouseSelect
                        onChange={warehouseId => props.setStoreMovementFilters({ warehouseId })}
                        style={{ margin: '0 0 0 8px' }}
                    />
                    <DateRangePicker
                        dateRange={[dayjs(filters.startDate), dayjs(filters.endDate)]}
                        minimize
                        onDateChange={setDaterange}
                    />
                    <Button
                        icon={<QuestionCircleOutlined />}
                        onClick={async () => {
                            sethelperDrawerOpen(true);
                            await fetchHelperLinks();
                        }}
                        style={{
                            fontSize: 22,
                            marginLeft: 8,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        type='text'
                    />
                </div>
            }
            paper
            title={<FormattedMessage id='navigation.storage_movement' />}
        >
            <StorageMovementTotals filters={filters} />
            <StoreMovementTable />
            <Drawer
                onClose={() => {
                    sethelperDrawerOpen(false);
                }}
                open={helperDrawerOpen}
                title={<FormattedMessage id='navigation.helper' />}
                width={420}
            >
                <div>
                    {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                        <div className={Styles.linkBlock}>
                            <div className={Styles.ogTitle}>
                                {index + 1}. {ogTitle}
                            </div>
                            <div className={Styles.ogDesc}>{ogDescription}</div>
                            <div className={Styles.ogImg}>
                                <Image src={isArray(ogImage) ? get(ogImage, '[0].url', []) : get(ogImage, 'url', [])} />
                            </div>
                            <a href={ogUrl} rel='noreferrer' target='_blank'>
                                <Button
                                    style={{
                                        width: '100%'
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='repair_map_table.goto' />
                                </Button>
                            </a>
                        </div>
                    ))}
                </div>
            </Drawer>
        </Layout>
    );
});
