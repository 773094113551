import { Button, Form, Input } from 'antd';
import { login } from 'core/forms/loginForm/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import Styles from './loginForm.m.css';

const mapDispatchToProps = {
    login
};

@connect(null, mapDispatchToProps)
@injectIntl
export class LoginForm extends Component {
    constructor(props) {
        super(props);
    }

    _submit = values => {
        this.props.login(values);
    };

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <Form className={Styles.loginForm} layout='vertical' name='loginForm' onFinish={this._submit}>
                <Form.Item
                    label={<FormattedMessage id='login_form.login' />}
                    name='login'
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'login_form.login_is_required'
                            })
                        }
                    ]}
                    tooltip={{
                        title: (
                            <React.Fragment>
                                <i style={{ color: 'red' }}>* </i>
                                <FormattedMessage id='login_hint' />
                            </React.Fragment>
                        )
                    }}
                >
                    <Input
                        placeholder={formatMessage({
                            id: 'login_form.enter_login'
                        })}
                    />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='login_form.password' />}
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'login_form.password_is_required'
                            })
                        }
                    ]}
                >
                    <Input.Password
                        placeholder={formatMessage({
                            id: 'login_form.enter_password'
                        })}
                    />
                </Form.Item>
                <div className={Styles.footer}>
                    <Link to={book.forgotPassword}>
                        <FormattedMessage id='login_form.forgot_password' />
                    </Link>
                    <Form.Item noStyle>
                        <Button htmlType='submit' type='primary'>
                            <FormattedMessage id='enter' />
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        );
    }
}
