exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__title---3dQbU{font-size:1.5em;padding:0 0 20px}.styles-m__row---1IoKG{margin-top:.5em}.styles-m__dataNtimeBlock---abF3W{display:flex;justify-content:space-around;margin-bottom:14px}.styles-m__btnStyle---2pYn0{width:250px}.styles-m__btnStyleMobile---1hWT2{width:100%}.styles-m__blockButtonsWrap---1F9c_{display:flex;flex-wrap:wrap;justify-content:space-between}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,yBACI,eAAgB,CAChB,gBACJ,CAEA,uBACI,eACJ,CAEA,kCACI,YAAa,CACb,4BAA6B,CAC7B,kBACJ,CAEA,4BACI,WACJ,CAEA,kCACI,UACJ,CAEA,oCACI,YAAa,CACb,cAAe,CACf,6BACF","file":"styles.m.css","sourcesContent":[".title {\n    font-size: 1.5em;\n    padding: 0px 0 20px 0;\n}\n\n.row {\n    margin-top: 0.5em;\n}\n\n.dataNtimeBlock {\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 14px;\n}\n\n.btnStyle {\n    width: 250px\n}\n\n.btnStyleMobile {\n    width: 100%\n}\n\n.blockButtonsWrap {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n\n.blockButtonsWrapMobile {\n\n}"]}]);

// Exports
exports.locals = {
	"title": "styles-m__title---3dQbU",
	"row": "styles-m__row---1IoKG",
	"dataNtimeBlock": "styles-m__dataNtimeBlock---abF3W",
	"btnStyle": "styles-m__btnStyle---2pYn0",
	"btnStyleMobile": "styles-m__btnStyleMobile---1hWT2",
	"blockButtonsWrap": "styles-m__blockButtonsWrap---1F9c_",
	"blockButtonsWrapMobile": "styles-m__blockButtonsWrapMobile---1c1-B"
};