import { Select, Spin } from 'antd';
import {
    selectBusinessSuppliersByQuery,
    selectBusinessSuppliersFetching,
    setBusinessSuppliersSearchQuery
} from 'core/search/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    businessSuppliers: selectBusinessSuppliersByQuery(state),
    isFetching: selectBusinessSuppliersFetching(state)
});

const mapDispatchToProps = {
    setBusinessSuppliersSearchQuery
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BusinessSuppliersSearch extends Component {
    render() {
        const { onSelect, setBusinessSuppliersSearchQuery } = this.props;
        const { businessSuppliers, isFetching, id } = this.props;

        return (
            <Select
                allowClear
                disabled={this.props.disabled}
                filterOption={false}
                notFoundContent={
                    isFetching ? <Spin size='small' /> : <FormattedMessage id='not_found' />
                }
                onChange={id => onSelect(id)}
                onSearch={item => setBusinessSuppliersSearchQuery(item)}
                placeholder={this.props.intl.formatMessage({
                    id: 'supplier_placeholder'
                })}
                showSearch
                style={this.props.selectStyles}
                // value={null}
            >
                {isFetching
                    ? []
                    : businessSuppliers.map(({ id, name }) => (
                          <Option key={id} value={id}>
                              {name}
                          </Option>
                      ))}
            </Select>
        );
    }
}
