import { notification } from 'antd';
import { setErrorMessage } from 'core/errorMessage/duck';
import { addError } from 'core/ui/duck';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { fetchAPI, fetchTecdocAPI, toDuckError } from 'utils';

/**
 * Constants
 * */
export const moduleName = 'store_products';
const prefix = `cpb/${moduleName}`;

export const DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE = `${prefix}/DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE`;
export const DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE_SUCCESS = `${prefix}/DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE_SUCCESS`;

export const PRODUCTS_EXCEL_IMPORT_VALIDATE = `${prefix}/PRODUCTS_EXCEL_IMPORT_VALIDATE`;
export const PRODUCTS_EXCEL_IMPORT_VALIDATE_SUCCESS = `${prefix}/PRODUCTS_EXCEL_IMPORT_VALIDATE_SUCCESS`;
export const PRODUCTS_EXCEL_IMPORT = `${prefix}/PRODUCTS_EXCEL_IMPORT`;
export const PRODUCTS_EXCEL_IMPORT_SUCCESS = `${prefix}/PRODUCTS_EXCEL_IMPORT_SUCCESS`;
export const PRODUCTS_EXCEL_IMPORT_RESET = `${prefix}/PRODUCTS_EXCEL_IMPORT_RESET`;

export const FETCH_PRODUCTS = `${prefix}/FETCH_PRODUCTS`;
export const FETCH_PRODUCTS_SUCCESS = `${prefix}/FETCH_PRODUCTS_SUCCESS`;

export const FETCH_UNITS = `${prefix}/FETCH_UNITS`;
export const FETCH_UNITS_SUCCESS = `${prefix}/FETCH_UNITS_SUCCESS`;

export const FETCH_LINKS = `${prefix}/FETCH_LINKS`;
export const FETCH_LINKS_SUCCESS = `${prefix}/FETCH_LINKS_SUCCESS`;

export const FETCH_PRODUCT_SUPPLIERS = `${prefix}/FETCH_PRODUCT_SUPPLIERS`;
export const FETCH_PRODUCT_SUPPLIERS_SUCCESS = `${prefix}/FETCH_PRODUCT_SUPPLIERS_SUCCESS`;

export const FETCH_PRODUCT_ANALOGUES = `${prefix}/FETCH_PRODUCT_ANALOGUES`;
export const FETCH_PRODUCT_ANALOGUES_SUCCESS = `${prefix}/FETCH_PRODUCT_ANALOGUES_SUCCESS`;

export const FETCH_AVAILABLE_PRODUCTS = `${prefix}/FETCH_AVAILABLE_PRODUCTS`;
export const FETCH_AVAILABLE_PRODUCTS_SUCCESS = `${prefix}/FETCH_AVAILABLE_PRODUCTS_SUCCESS`;

export const FETCH_RECOMMENDED_PRICE = `${prefix}/FETCH_RECOMMENDED_PRICE`;
export const FETCH_RECOMMENDED_PRICE_SUCCESS = `${prefix}/FETCH_RECOMMENDED_PRICE_SUCCESS`;

export const FETCH_PRODUCT = `${prefix}/FETCH_PRODUCT`;
export const FETCH_PRODUCT_SUCCESS = `${prefix}/FETCH_PRODUCT_SUCCESS`;

export const FETCH_PRODUCT_EAN_DATA = `${prefix}/FETCH_PRODUCT_EAN_DATA`;
export const FETCH_PRODUCT_EAN_DATA_SUCCESS = `${prefix}/FETCH_PRODUCT_EAN_DATA_SUCCESS`;

export const CREATE_PRODUCT = `${prefix}/CREATE_PRODUCT`;
export const CREATE_PRODUCT_SUCCESS = `${prefix}/CREATE_PRODUCT_SUCCESS`;
export const UPDATE_PRODUCT = `${prefix}/UPDATE_PRODUCT`;
export const UPDATE_PRODUCT_SUCCESS = `${prefix}/UPDATE_PRODUCT_SUCCESS`;
export const DELETE_PRODUCT = `${prefix}/DELETE_PRODUCT`;
export const DELETE_PRODUCT_SUCCESS = `${prefix}/DELETE_PRODUCT_SUCCESS`;

export const SET_STORE_PRODUCTS_PAGE = `${prefix}/SET_STORE_PRODUCTS_PAGE`;
export const SET_STORE_PRODUCTS_FILTERS = `${prefix}/SET_STORE_PRODUCTS_FILTERS`;

export const SET_PRODUCT_LOADING = `${prefix}/SET_PRODUCT_LOADING`;
export const SET_PRODUCTS_LOADING = `${prefix}/SET_PRODUCTS_LOADING`;
export const SET_PRODUCT_SUPPLIERS_LOADING = `${prefix}/SET_PRODUCT_SUPPLIERS_LOADING`;
export const SET_PRODUCT_ANALOGUES_LOADING = `${prefix}/SET_PRODUCT_ANALOGUES_LOADING`;
export const SET_RECOMMENDED_PRICE_LOADING = `${prefix}/SET_RECOMMENDED_PRICE_LOADING`;
export const SET_PRODUCTS_EXCEL_IMPORT_LOADING = `${prefix}/SET_PRODUCTS_EXCEL_IMPORT_LOADING`;

export const RESET_PRODUCT_EAN_DATA = `${prefix}/RESET_PRODUCT_EAN_DATA`;

/**
 * Reducer
 * */

const ReducerState = {
    products: {
        stats: {
            count: '0'
        },
        list: []
    },

    units: [],
    allLinks: [],

    availableProducts: {},
    productsExcel: {
        validProducts: [],
        tooManyInvalidProducts: false,
        invalidProducts: []
    },
    validationError: false,
    importing: false,
    productsExcelLoading: false,
    productsLoading: false,
    recommendedPriceLoading: false,
    productSuppliersLoading: false,
    productAnaloguesLoading: false,

    product: {},
    productEanData: undefined,
    suppliers: [],
    productAnalogues: [],

    filters: {
        page: 1,
        pageSize: 25,
        productCode: undefined,
        productBrand: undefined
    }
};

/* eslint-disable complexity */
export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case PRODUCTS_EXCEL_IMPORT_VALIDATE_SUCCESS:
            return { ...state, importing: true, productsExcel: payload };

        case PRODUCTS_EXCEL_IMPORT_SUCCESS:
            return {
                ...state,
                // importing:       !_.isEmpty(payload.invalidProducts),
                productsExcel: payload,
                validationError: !_.isEmpty(payload.invalidProducts)
            };

        case PRODUCTS_EXCEL_IMPORT_RESET:
            return {
                ...state,
                importing: false,
                productsExcel: ReducerState.productsExcel
            };

        case FETCH_PRODUCT_SUCCESS:
            return { ...state, product: { ...payload } };

        case FETCH_PRODUCT_EAN_DATA_SUCCESS:
            return { ...state, productEanData: { ...payload } };

        case FETCH_PRODUCTS_SUCCESS:
            return { ...state, products: { ...payload } };

        case FETCH_PRODUCT_SUPPLIERS_SUCCESS:
            return { ...state, suppliers: payload };

        case FETCH_PRODUCT_ANALOGUES_SUCCESS:
            return { ...state, productAnalogues: payload };
        case FETCH_UNITS_SUCCESS:
            return { ...state, units: payload };
        case FETCH_LINKS_SUCCESS:
            return { ...state, allLinks: payload };

        // DEPRECATED
        // case FETCH_AVAILABLE_PRODUCTS_SUCCESS:
        //     return {
        //         ...state,
        //         availableProducts: {
        //             key:   payload.key,
        //             using: { ...payload.availableProducts },
        //         },
        //     };
        case FETCH_RECOMMENDED_PRICE_SUCCESS:
            return {
                ...state,
                recommendedPrice: { ...payload }
            };

        case SET_STORE_PRODUCTS_PAGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload,
                    page: payload.page.page,
                    pageSize: payload.page.pageSize
                }
            };

        case SET_STORE_PRODUCTS_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...payload, page: 1, pageSize: 25 }
            };

        case SET_PRODUCTS_EXCEL_IMPORT_LOADING:
            return { ...state, productsExcelLoading: payload };

        case SET_PRODUCT_LOADING:
            return { ...state, productLoading: payload };

        case SET_PRODUCTS_LOADING:
            return { ...state, productsLoading: payload };

        case SET_PRODUCT_SUPPLIERS_LOADING:
            return { ...state, productSuppliersLoading: payload };

        case SET_PRODUCT_ANALOGUES_LOADING:
            return { ...state, productAnaloguesLoading: payload };

        case SET_RECOMMENDED_PRICE_LOADING:
            return { ...state, recommendedPriceLoading: payload };

        case RESET_PRODUCT_EAN_DATA:
            return { ...state, productEanData: undefined };

        default:
            return state;
    }
}

/**
 * Selectors
 * */

export const stateSelector = state => state.storage[moduleName];
export const selectStoreProduct = state => stateSelector(state).product;
export const selectStoreProductEanData = state => stateSelector(state).productEanData;
export const selectStoreProductSuppliers = state => stateSelector(state).suppliers;
export const selectStoreProductAnalogues = state => stateSelector(state).productAnalogues;

export const selectStoreProducts = state => stateSelector(state).products;
export const selectRecommendedPrice = state => stateSelector(state).selectRecommendedPrice;
export const selectStoreProductsFilters = state => stateSelector(state).filters;
// export const selectStoreProductsExcel = state =>
//     stateSelector(state).productsExcel;
export const selectStoreProductsExcelLoading = state => stateSelector(state).productsExcelLoading;
export const selectProductLoading = state => stateSelector(state).productLoading;
export const selectProductsLoading = state => stateSelector(state).productsLoading;

export const selectProductAnaloguesLoading = state => stateSelector(state).productAnaloguesLoading;

export const selectProductSuppliersLoading = state => stateSelector(state).productSuppliersLoading;

export const selectRecommendedPriceLoading = state => stateSelector(state).selectRecommendedPriceLoading;

export const selectProductsImporting = state => stateSelector(state).importing;

export const selectImportValidProducts = state => stateSelector(state).productsExcel.validProducts;
export const selectImportValidationError = state => stateSelector(state).validationError;
export const selectImportInvalidProducts = state => stateSelector(state).productsExcel.invalidProducts;
export const selectImportTooManyInvalids = state => stateSelector(state).productsExcel.tooManyInvalidProducts;
export const selectUnits = state => stateSelector(state).units;
export const selectLinks = state => stateSelector(state).allLinks;

export const selectStoreProductsExcel = createSelector([stateSelector], ({ productsExcel }) => productsExcel);

/**
 * Action Creators
 * */

// products
export const fetchProducts = showAll => ({
    type: FETCH_PRODUCTS,
    payload: showAll
});

export const fetchUnits = () => ({
    type: FETCH_UNITS
});

export const fetchLinks = () => ({
    type: FETCH_LINKS
});

// suppliers
export const fetchProductSuppliers = () => ({
    type: FETCH_PRODUCT_SUPPLIERS
});

// analogues
export const fetchProductAnalogues = () => ({
    type: FETCH_PRODUCT_ANALOGUES
});

export const fetchProductsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products
});

export const fetchUnitsSuccess = units => ({
    type: FETCH_UNITS_SUCCESS,
    payload: units
});

export const fetchLinksSuccess = allLinks => ({
    type: FETCH_LINKS_SUCCESS,
    payload: allLinks
});

export const fetchProductSuppliersSuccess = products => ({
    type: FETCH_PRODUCT_SUPPLIERS_SUCCESS,
    payload: products
});

export const fetchProductAnaloguesSuccess = products => ({
    type: FETCH_PRODUCT_ANALOGUES_SUCCESS,
    payload: products
});

export const setStoreProductsPage = (page, pageSize) => ({
    type: SET_STORE_PRODUCTS_PAGE,
    payload: { page, pageSize }
});

// available products DEPRECATED!
export const fetchAvailableProducts = (key, id, count) => ({
    type: FETCH_AVAILABLE_PRODUCTS,
    payload: { key, id, count }
});

export const fetchAvailableProductsSuccess = (key, availableProducts) => ({
    type: FETCH_AVAILABLE_PRODUCTS_SUCCESS,
    payload: { key, availableProducts }
});

//
export const fetchRecommendedPrice = (key, id, func) => ({
    type: FETCH_RECOMMENDED_PRICE,
    payload: { key, id, func }
});

export const fetchRecommendedPriceSuccess = recommendedPrice => ({
    type: FETCH_RECOMMENDED_PRICE_SUCCESS,
    payload: recommendedPrice
});

// product
export const fetchProduct = id => ({
    type: FETCH_PRODUCT,
    payload: id
});

export const fetchProductSuccess = product => ({
    type: FETCH_PRODUCT_SUCCESS,
    payload: product
});

// product ean data
export const fetchProductEanData = ean => ({
    type: FETCH_PRODUCT_EAN_DATA,
    payload: ean
});

export const fetchProductEanDataSuccess = productEanData => ({
    type: FETCH_PRODUCT_EAN_DATA_SUCCESS,
    payload: productEanData
});

export const createProduct = (product, func) => ({
    type: CREATE_PRODUCT,
    payload: { product, func }
});

export const createProductSuccess = () => ({
    type: CREATE_PRODUCT_SUCCESS
});

export const updateProduct = (product, func) => ({
    type: UPDATE_PRODUCT,
    payload: { product, func }
});

export const updateProductSuccess = () => ({
    type: UPDATE_PRODUCT_SUCCESSunits
});

export const deleteProduct = (id, message) => ({
    type: DELETE_PRODUCT,
    payload: { id, message }
});

export const deleteProductSuccess = () => ({
    type: DELETE_PRODUCT_SUCCESS
});

export const setStoreProductsFilters = filters => ({
    type: SET_STORE_PRODUCTS_FILTERS,
    payload: filters
});

// productsExcel
export const downloadExcelTemplate = () => ({
    type: DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE
});

export const downloadExcelTemplateSuccess = doc => ({
    type: DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE_SUCCESS,
    payload: doc
});

export const productsExcelImportValidate = file => ({
    type: PRODUCTS_EXCEL_IMPORT_VALIDATE,
    payload: file
});

export const productsExcelImportValidateSuccess = payload => ({
    type: PRODUCTS_EXCEL_IMPORT_VALIDATE_SUCCESS,
    payload
});

export const productsExcelImport = file => ({
    type: PRODUCTS_EXCEL_IMPORT,
    payload: file
});

export const productsExcelImportSuccess = payload => ({
    type: PRODUCTS_EXCEL_IMPORT_SUCCESS,
    payload
});

export const productsExcelImportReset = () => ({
    type: PRODUCTS_EXCEL_IMPORT_RESET
});

// loaders
export const setProductsExcelImportLoading = isLoading => ({
    type: SET_PRODUCTS_EXCEL_IMPORT_LOADING,
    payload: isLoading
});

export const setProductLoading = isLoading => ({
    type: SET_PRODUCT_LOADING,
    payload: isLoading
});

export const setProductsLoading = isLoading => ({
    type: SET_PRODUCTS_LOADING,
    payload: isLoading
});

export const setProductSuppliersLoading = isLoading => ({
    type: SET_PRODUCT_SUPPLIERS_LOADING,
    payload: isLoading
});

export const setProductAnaloguesLoading = isLoading => ({
    type: SET_PRODUCT_ANALOGUES_LOADING,
    payload: isLoading
});

export const setRecommendedPriceLoading = isLoading => ({
    type: SET_RECOMMENDED_PRICE_LOADING,
    payload: isLoading
});

export const resetProductEanData = () => ({
    type: RESET_PRODUCT_EAN_DATA
});

/**
 * Sagas
 * */

const normalizeFile = file =>
    file.map(product => {
        return {
            code: product.code ? String(product.code) : void 0,
            name: product.name ? String(product.name) : void 0,
            groupId: product.groupId ? Number(product.groupId) : void 0,
            groupName: product.groupName ? String(product.groupName) : void 0,
            brandId: product.brandId ? Number(product.brandId) : void 0,
            // brandName:   product.brandName ? String(product.brandName) : void 0,
            measureUnit: product.measureUnit ? String(product.measureUnit) : void 0,
            tradeCode: product.tradeCode ? String(product.tradeCode) : void 0,
            certificate: product.certificate ? String(product.certificate) : void 0,
            priceGroupNumber: product.priceGroupNumber ? Number(product.priceGroupNumber) : void 0,
            price: product.price ? Number(product.price) : void 0,
            markup: product.markup ? product.markup : void 0,
            margin: product.margin ? product.margin : void 0,
            fixedSellingPrice: product.fixedSellingPrice ? product.fixedSellingPrice : void 0,
            markupGroupId: product.markupGroupId ? product.markupGroupId : void 0
        };
    });

export function* fetchProductsSaga() {
    while (true) {
        try {
            // console.log(action);
            const action = yield take([
                FETCH_PRODUCTS,
                SET_STORE_PRODUCTS_FILTERS,
                CREATE_PRODUCT_SUCCESS,
                UPDATE_PRODUCT_SUCCESS,
                DELETE_PRODUCT_SUCCESS
            ]);
            yield put(setProductsLoading(true));
            const filters = yield select(selectStoreProductsFilters);
            const showAll = { ...(action.payload && action.payload.showAll ? { showAll: true } : {}) };
            const response = yield call(fetchAPI, 'GET', '/store_products', { ...filters, ...showAll });

            yield put(fetchProductsSuccess(response));
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield put(setProductsLoading(false));
        }
    }
}

export function* fetchUnitsSaga() {
    while (true) {
        try {
            yield take(FETCH_UNITS);

            const response = yield call(fetchAPI, 'GET', '/business/measurement/units', null);

            yield put(fetchUnitsSuccess(response));
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield put(setProductsLoading(false));
        }
    }
}

export function* fetchLinksSaga() {
    while (true) {
        try {
            yield take(FETCH_LINKS);

            const response = yield call(
                fetchAPI,
                'GET',
                '/helps',
                { helpId: 'directories_and_settings_products' },
                null
            );

            yield put(fetchLinksSuccess(response));
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield put(setProductsLoading(false));
        }
    }
}

export function* fetchProductSuppliersSaga() {
    while (true) {
        try {
            yield take([FETCH_PRODUCT_SUPPLIERS]);

            yield put(setProductSuppliersLoading(true));

            const product = yield select(selectStoreProduct);

            const response = yield call(fetchAPI, 'GET', '/business_suppliers/pricelists', {
                partNumber: product.code,
                brandId: product.brandId,
                storeGroupId: product.groupId
            });

            yield put(fetchProductSuppliersSuccess(response));
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield put(setProductSuppliersLoading(false));
        }
    }
}

export function* fetchProductAnaloguesSaga() {
    while (true) {
        try {
            yield take([FETCH_PRODUCT_ANALOGUES]);

            yield put(setProductAnaloguesLoading(true));

            const product = yield select(selectStoreProduct);

            const response = yield call(
                fetchAPI,
                'GET',
                '/products/search/v2/m3',
                {
                    code: product.code,
                    brandId: product.brandId,
                    page: 1,
                    pageSize: 13
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchProductAnaloguesSuccess(_.get(response, 'listParts', [])));
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield put(setProductAnaloguesLoading(false));
        }
    }
}

export function* fetchProductSaga() {
    while (true) {
        try {
            const { payload } = yield take(FETCH_PRODUCT);
            yield put(setProductLoading(true));
            if (payload) {
                const response = yield call(fetchAPI, 'GET', `/store_products/${payload}`);
                yield put(fetchProductSuccess(response));
            } else {
                yield put(fetchProductSuccess({}));
            }
        } catch (error) {
            yield put(setErrorMessage(error));
            put(fetchProductSuccess({}));
        } finally {
            yield put(setProductLoading(false));
        }
    }
}

export function* fetchProductEanDataSaga() {
    while (true) {
        try {
            const { payload } = yield take(FETCH_PRODUCT_EAN_DATA);

            if (payload) {
                const response = yield call(fetchTecdocAPI, 'GET', '/tecdoc/products/gtin', { gtin: payload }, null, {
                    handleErrorInternally: true
                });

                const eanData = _.get(response, '[0]', {});

                yield put(fetchProductEanDataSuccess(eanData));
            } else {
                yield put(fetchProductEanDataSuccess({}));
            }
        } catch (error) {
            yield put(setErrorMessage(error));
            put(fetchProductEanDataSuccess({}));
        } finally {
            // yield put(setProductLoading(false));
        }
    }
}

// DEPRECATED
// export function* fetchAvailableProductsSaga() {
//     while (true) {
//         try {
//             const {
//                 payload: { key, id, count },
//             } = yield take(FETCH_AVAILABLE_PRODUCTS);
//             yield put(setAvailableProductsLoading(true));

//             const response = yield call(
//                 fetchAPI,
//                 'GET',
//                 '/store_doc_products/available/',
//                 {
//                     productId:      id,
//                     neededQuantity: count || 1,
//                 },
//             );

//             yield put(fetchAvailableProductsSuccess(key, response));
//         } catch (error) {
//             yield put(emitError(error));
//         } finally {
//             yield put(setAvailableProductsLoading(false));
//         }
//     }
// }
export function* fetchRecommendedPriceSaga() {
    while (true) {
        try {
            const {
                payload: { key, id, func }
            } = yield take(FETCH_RECOMMENDED_PRICE);
            yield put(setRecommendedPriceLoading(true));

            const response = yield call(fetchAPI, 'GET', `/store_doc_products/${id}/recommended_price`, null, null, {
                handleErrorInternally: true
            });

            yield func(response.recommendedPrice, response.purchasePrice);

            yield put(fetchRecommendedPriceSuccess({ key, ...response }));
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield put(setRecommendedPriceLoading(false));
        }
    }
}

export function* createProductSaga() {
    while (true) {
        try {
            const { payload } = yield take(CREATE_PRODUCT);
            const { product, func } = payload;
            yield put(setProductsLoading(true));
            const response = yield call(fetchAPI, 'POST', '/store_products', null, _.omit(product, 'barcode'), {
                handleErrorInternally: true
            });
            if (response.created && product.barcode) {
                const responseBarcode = yield call(
                    fetchAPI,
                    'POST',
                    '/barcodes',
                    null,
                    [
                        {
                            referenceId: String(response.id),
                            table: 'STORE_PRODUCTS',
                            customCode: product.barcode.includes('STP-')
                                ? product.barcode.substring(product.barcode.lastIndexOf('-') + 1)
                                : product.barcode
                        }
                    ],
                    {
                        handleErrorInternally: true
                    }
                );
            }

            if (func) {
                yield func(response.id, response.code);
            }

            yield put(fetchProductsSuccess(response));
        } catch (error) {
            const duckError = toDuckError(error, 'storeProductForm');
            yield put(addError(duckError));

            yield put(setErrorMessage(error));
        } finally {
            yield put(setProductsLoading(false));
        }
    }
}

export function* updateProductSaga() {
    while (true) {
        try {
            const { payload } = yield take(UPDATE_PRODUCT);
            const { product, func } = payload;
            yield call(fetchAPI, 'PUT', `/store_products/${product.id}`, null, _.omit(product.product, 'barcode'), {
                handleErrorInternally: true
            });
            if (func) {
                yield func();
            }
            yield put(updateProductSuccess());
        } catch (error) {
            yield put(setErrorMessage(error));
        }
    }
}
export function* deleteProductSaga() {
    while (true) {
        try {
            const {
                payload: { id, message }
            } = yield take(DELETE_PRODUCT);
            const response = yield call(fetchAPI, 'DELETE', `/store_products/${id}`, null, null, {
                handleErrorInternally: true
            });
            if (message) {
                notification.error({ message });
            }
            yield put(deleteProductSuccess());
        } catch (error) {
            yield put(setErrorMessage(error));
        }
    }
}

export function* downloadExcelTemplateSaga() {
    while (true) {
        try {
            yield take(DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE);
            yield nprogress.start();
            const response = yield call(fetchAPI, 'GET', '/store_products/import_template', {}, null, {
                rawResponse: true
            });

            const excelFile = yield response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
            yield saveAs(excelFile, fileName);
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* productsExcelImportValidateSaga() {
    while (true) {
        try {
            const { payload: file } = yield take(PRODUCTS_EXCEL_IMPORT_VALIDATE);
            yield nprogress.start();
            yield put(setProductsExcelImportLoading(true));
            const normalizedFile = normalizeFile(file);

            const response = yield call(fetchAPI, 'POST', '/store_products/import/validate', null, normalizedFile, {
                handleErrorInternally: true
            });

            yield put(productsExcelImportValidateSuccess(response));
            yield put(setProductsExcelImportLoading(false));
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* productsExcelImportSaga() {
    while (true) {
        try {
            // file = invalid
            const { payload: file } = yield take(PRODUCTS_EXCEL_IMPORT);

            yield nprogress.start();
            yield put(setProductsExcelImportLoading(true));
            const valid = yield select(selectImportValidProducts);

            const normalizedFile = normalizeFile(file);
            let validationResult = {};
            if (!_.isEmpty(normalizedFile)) {
                validationResult = yield call(
                    fetchAPI,
                    'POST',
                    '/store_products/import/validate',
                    null,
                    normalizedFile,
                    {
                        handleErrorInternally: true
                    }
                );
            }

            const response = yield call(
                fetchAPI,
                'POST',
                '/store_products/import',
                null,
                valid.concat(validationResult.validProducts || []),
                {
                    handleErrorInternally: true
                }
            );

            yield put(productsExcelImportSuccess(validationResult));
            yield put(setProductsExcelImportLoading(false));

            if (_.isEmpty(validationResult.invalidProducts)) {
                yield put(productsExcelImportReset());
            }
        } catch (error) {
            yield put(setErrorMessage(error));
        } finally {
            yield nprogress.done();
        }
    }
}

export function* saga() {
    yield all([
        call(fetchProductSaga),
        call(fetchLinksSaga),
        call(fetchProductEanDataSaga),
        call(fetchProductsSaga),
        call(fetchUnitsSaga),
        call(fetchProductSuppliersSaga),
        call(fetchProductAnaloguesSaga),
        // call(fetchAvailableProductsSaga),
        call(fetchRecommendedPriceSaga),
        call(createProductSaga),
        call(updateProductSaga),
        call(deleteProductSaga),
        call(downloadExcelTemplateSaga),
        call(productsExcelImportValidateSaga),
        call(productsExcelImportSaga)
    ]);
}
