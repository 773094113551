/* import { Form } from '@ant-design/compatible'; */
import { Button, Form, Select } from 'antd';
import { onChangeManagerRoleForm } from 'core/forms/managerRoleForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapDispatchToProps = {
    onChangeManagerRoleForm
};

@connect(null, mapDispatchToProps)
@injectIntl
export class ManagerRoleForm extends Component {
    _submit = values => {
        const { managerRole: { managerId, businessId } = {} } = this.props;
        this.props.updateManagerRole(managerId, values.roleIds || [], businessId);
    };

    render() {
        const { managerRole: { roles, availableRoles } = {} } = this.props;

        return (
            <Form
                initialValues={{
                    roleIds: _.map(roles, 'roleId')
                }}
                name='managerRoleForm'
                onFinish={this._submit}
            >
                <Form.Item
                    label={<FormattedMessage id='manager-role-form.roles_field' />}
                    name='roleIds'
                >
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        mode='multiple'
                    >
                        {availableRoles.map(({ roleId, roleName }) => (
                            <Option key={roleId} value={roleId}>
                                {roleName}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' style={{ width: '100%' }} type='primary'>
                        <FormattedMessage id='manager-role-form.edit' />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
