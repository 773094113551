/* eslint-disable no-underscore-dangle */
import {
    CopyOutlined,
    EditOutlined,
    MenuOutlined,
    PhoneTwoTone,
    PlusOutlined
} from '@ant-design/icons';
import { Form, Input, InputNumber, Popconfirm, Popover, Select } from 'antd';
import classNames from 'classnames/bind';
import _ from 'lodash';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { isForbidden, permissions } from 'utils';
import { v4 } from 'uuid';
import { formCommentLayout, fromExpandedCommentLayout } from '../layouts';
import { ClientsSearchTable } from '../OrderFormTables';
import Styles from './styles.m.css';

const { Option } = Select;

const cx = classNames.bind(Styles);

// TODO: @yan
// specific label name formating
function formatVehicleLabel(vehicle, formatMessage) {
    const modelPart = vehicle.model
        ? `${vehicle.make} ${vehicle.model}`
        : formatMessage({ id: 'add_order_form.no_model' });
    const horsePowerLabel = !vehicle.horsePower
        ? null
        : `(${vehicle.horsePower} ${formatMessage({
              id: 'horse_power'
          })})`;
    const modificationPart = [vehicle.modification, horsePowerLabel].filter(Boolean).join(' ');
    const parts = [modelPart, vehicle.year, modificationPart];

    return parts.filter(Boolean).map(String).map(_.trimEnd).join(', ');
}

@injectIntl
export default class OrderFormBody extends Component {
    constructor(props) {
        super(props);

        // Constant rules, styles, props
        this.requiredFieldRules = [
            {
                required: true,
                message: this.props.intl.formatMessage({
                    id: 'required_field'
                })
            }
        ];
        this.requiredNumberFieldRules = [
            {
                type: 'number',
                message: this.props.intl.formatMessage({
                    id: 'required_field'
                })
            }
        ];
        this.recommendationRules = [
            {
                max: 2000,
                message: this.props.intl.formatMessage({
                    id: 'field_should_be_below_2000_chars'
                })
            }
        ];
        this._prevRecommendationAutoSize = { minRows: 2, maxRows: 6 };
        this._recommendationAutoSize = { minRows: 2, maxRows: 6 };
        this._clientPhoneBorderStyle = { borderRadius: 0 };

        // In order to reduce <FormatMessage> invocation
        this._localizationMap = {};

        // Default select options
        const clientPhonesOptions = this._getClientPhonesOptions();
        const clientVehiclesOptions = this._getClientVehiclesOptions();

        // ClientEmail required copy button, so we need to regenerate the value
        const recommendationStyles = this._getRecommendationStyles();

        // Configure initial state
        this.state = {
            clientPhonesOptions,
            clientVehiclesOptions,
            recommendationStyles
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedClient !== this.props.selectedClient) {
            const clientPhonesOptions = this._getClientPhonesOptions();
            const clientVehiclesOptions = this._getClientVehiclesOptions();

            this.setState({
                clientPhonesOptions,
                clientVehiclesOptions
            });
        }
        if (
            prevProps.orderId !== this.props.orderId ||
            prevProps.orderHistory !== this.props.orderHistory
        ) {
            const recommendationStyles = this._getRecommendationStyles();
            this.setState({ recommendationStyles });
        }
    }

    _renderClientSearchTable = () => {
        const {
            searchClientsResult: { searching: clientsSearching, clients },
            setClientSelection,
            searchClientQuery,
            form
        } = this.props;

        return (
            <ClientsSearchTable
                clients={clients}
                clientsSearching={clientsSearching}
                setClientSelection={cl => {
                    form.setFieldsValue({ searchClientQuery: undefined });
                    setClientSelection(cl);
                }}
                visible={searchClientQuery && searchClientQuery.length > 2}
            />
        );
    };

    _renderClientSearch = () => {
        const { getFieldDecorator } = this.props.form;
        const { user, fields, errors } = this.props;
        const { CREATE_EDIT_DELETE_CLIENTS } = permissions;

        const disabledClientSearch =
            (!_.get(this.props, 'order.status') ||
                _.get(this.props, 'order.status') !== 'reserve') &&
            _.get(this.props, 'order.clientId');

        return !disabledClientSearch ? (
            <div className={Styles.client}>
                <Form.Item name='searchClientQuery'>
                    <Input
                        ref={this.clientRef}
                        className={Styles.clientSearchField}
                        disabled={Boolean(disabledClientSearch) || this.bodyUpdateIsForbidden()}
                        placeholder={this._getLocalization(
                            'add_order_form.search_client.placeholder'
                        )}
                    />
                </Form.Item>
                {!isForbidden(user, CREATE_EDIT_DELETE_CLIENTS) ? (
                    <React.Fragment>
                        <PlusOutlined
                            className={Styles.addClientIcon}
                            onClick={() => this.props.setAddClientModal()}
                        />
                    </React.Fragment>
                ) : null}
            </div>
        ) : null;
    };

    _renderClientColumn = () => {
        const {
            selectedClient,
            fetchedOrder,
            fields,
            clientPhone,
            createOrder,
            createStatus,
            setModal,
            modals
        } = this.props;
        const { formatMessage } = this.props.intl;
        const hasClient = clientPhone;

        return (
            <div className={Styles.bodyColumn}>
                <div className={Styles.bodyColumnContent}>
                    <div className={Styles.contentWrapper}>
                        <div className={Styles.comboFieldWrapper}>
                            <FormattedMessage id='add_order_form.client' />
                            <div className={Styles.comboField}>
                                {selectedClient.name || selectedClient.surname
                                    ? `${
                                          selectedClient.surname ? `${selectedClient.surname} ` : ''
                                      }${selectedClient.name}`
                                    : void 0}

                                {hasClient && (
                                    <Popover
                                        content={
                                            <div className={Styles.actionIconsWrap}>
                                                {createOrder ? (
                                                    <Popconfirm
                                                        onConfirm={() => {
                                                            createOrder(
                                                                createStatus,
                                                                `${book.client}/${selectedClient.clientId}`
                                                            );
                                                        }}
                                                        title={
                                                            <FormattedMessage id='save_order_changes' />
                                                        }
                                                    >
                                                        <Link
                                                            to={`${book.client}/${selectedClient.clientId}`}
                                                        >
                                                            <EditOutlined className={Styles.icon} />
                                                        </Link>
                                                    </Popconfirm>
                                                ) : (
                                                    <Link
                                                        to={`${book.client}/${selectedClient.clientId}`}
                                                    >
                                                        <EditOutlined
                                                            className={Styles.icon}
                                                            title={formatMessage({ id: 'edit' })}
                                                        />
                                                    </Link>
                                                )}
                                                <CopyToClipboard text={hasClient}>
                                                    <CopyOutlined
                                                        className={Styles.icon}
                                                        title={formatMessage({ id: 'copy' })}
                                                    />
                                                </CopyToClipboard>
                                                <a
                                                    className={Styles.callLink}
                                                    href={`tel:${_.get(fields, 'clientPhone')}`}
                                                >
                                                    <PhoneTwoTone
                                                        className={Styles.icon}
                                                        title={formatMessage({ id: 'call' })}
                                                    />
                                                </a>
                                            </div>
                                        }
                                        placement='right'
                                        trigger='click'
                                    >
                                        <MenuOutlined
                                            className={Styles.actionIconsPopover}
                                            title={formatMessage({ id: 'order_form_table.action' })}
                                        />
                                    </Popover>
                                )}
                            </div>
                        </div>
                        <Form.Item
                            initialValue={
                                _.get(fetchedOrder, 'order.clientPhone') ||
                                (this.bodyUpdateIsForbidden()
                                    ? void 0
                                    : _.get(selectedClient, 'phones[0]'))
                            }
                            name='clientPhone'
                        >
                            <Select
                                className={`${Styles.clientCol} ${Styles.comboFieldSelect}`}
                                disabled={this.bodyUpdateIsForbidden()}
                                dropdownStyle={this._clientPhoneBorderStyle}
                                placeholder={this._getLocalization(
                                    'add_order_form.search_client.placeholder'
                                )}
                            >
                                {this.state.clientPhonesOptions}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className={Styles.odometr}>
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.discount')}
                        label={this._getLocalization('total_discount_or_markup')}
                        name='discount'
                    >
                        <InputNumber
                            className={Styles.totalDiscount}
                            decimalSeparator=','
                            disabled={this.bodyUpdateIsForbidden()}
                            placeholder={this._getLocalization('total_discount_or_markup')}
                        />
                    </Form.Item>
                </div>
            </div>
        );
    };

    _renderVehicleColumn = () => {
        const {
            selectedClient,
            fetchedOrder,
            clientVehicle,
            createOrder,
            createStatus,
            updateOrderField,
            vehicleTypes,

            intl: { formatMessage }
        } = this.props;

        const selectedVehicleId = clientVehicle;

        const selectedVehicle =
            selectedClient &&
            selectedVehicleId &&
            _.find(selectedClient.vehicles, { id: selectedVehicleId });

        const radiuses = [...Array(41).keys()].map(i => i + 10);

        return (
            <div className={Styles.bodyColumn}>
                <div className={Styles.bodyColumnContent}>
                    <div className={Styles.contentWrapper}>
                        <div className={Styles.comboFieldWrapper}>
                            <FormattedMessage id='add_order_form.car' />
                            <div className={Styles.comboField__vehicle}>
                                {_.get(selectedVehicle, 'number') && (
                                    <span>
                                        <FormattedMessage id='add_client_form.number' />
                                        {": "}
                                        {_.get(selectedVehicle, 'number')}
                                    </span>
                                )}
                                {_.get(selectedVehicle, 'vin') && (
                                    <span style={{ paddingLeft: 8 }}>
                                        <FormattedMessage id='add_client_form.vin' />
                                        {": "}
                                        {_.get(selectedVehicle, 'vin')}
                                    </span>
                                )}
                                {selectedVehicle && (
                                    <Popover
                                        content={
                                            <div className={Styles.actionIconsWrap}>
                                                {createOrder ? (
                                                    <Popconfirm
                                                        onConfirm={() => {
                                                            createOrder(
                                                                createStatus,
                                                                `${book.client}/${selectedClient.clientId}`
                                                            );
                                                        }}
                                                        title={
                                                            <FormattedMessage id='save_order_changes' />
                                                        }
                                                    >
                                                        <Link
                                                            to={`${book.client}/${selectedClient.clientId}`}
                                                        >
                                                            <EditOutlined className={Styles.icon} />
                                                        </Link>
                                                    </Popconfirm>
                                                ) : (
                                                    <Link
                                                        to={`${book.client}/${selectedClient.clientId}`}
                                                    >
                                                        <EditOutlined
                                                            className={Styles.icon}
                                                            title={formatMessage({ id: 'edit' })}
                                                        />
                                                    </Link>
                                                )}
                                                <CopyToClipboard
                                                    text={`${selectedVehicle.make} ${selectedVehicle.model}`}
                                                >
                                                    <CopyOutlined
                                                        className={Styles.icon}
                                                        title={formatMessage({ id: 'copy' })}
                                                    />
                                                </CopyToClipboard>
                                            </div>
                                        }
                                        placement='right'
                                        trigger='click'
                                    >
                                        <MenuOutlined
                                            className={Styles.actionIconsPopover}
                                            title={formatMessage({ id: 'order_form_table.action' })}
                                        />
                                    </Popover>
                                )}
                            </div>
                        </div>
                        <Form.Item
                            initialValue={
                                _.get(fetchedOrder, 'order.clientVehicleId') ||
                                (this.bodyUpdateIsForbidden()
                                    ? void 0
                                    : _.get(selectedClient, 'vehicles[0].id'))
                            }
                            name='clientVehicle'
                        >
                            <Select
                                className={Styles.comboFieldSelect__vehicle}
                                disabled={this.bodyUpdateIsForbidden()}
                            >
                                {this.state.clientVehiclesOptions}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                {vehicleTypes && (
                    <div className={Styles.vehicleInfo}>
                        <Form.Item
                            initialValue={
                                _.get(fetchedOrder, 'order.clientVehicleTypeId') ||
                                (this.bodyUpdateIsForbidden()
                                    ? void 0
                                    : _.get(selectedClient, 'vehicles[0].vehicleTypeId')) ||
                                _.get(this.props, 'vehicleTypes[0].id')
                            }
                            name='clientVehicleTypeId'
                        >
                            <Select
                                disabled={this.bodyUpdateIsForbidden()}
                                onChange={(value, option) => {
                                    updateOrderField({
                                        clientVehicleTypeId: value,
                                        clientVehicleRadius: option.radius
                                    });
                                }}
                            >
                                {vehicleTypes.map(({ id, name, defaultRadius }) => (
                                    <Option key={v4()} radius={defaultRadius} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            initialValue={Math.round(
                                _.get(fetchedOrder, 'order.clientVehicleRadius', 0) ||
                                    (this.bodyUpdateIsForbidden()
                                        ? void 0
                                        : _.get(selectedClient, 'vehicles[0].wheelRadius', 14))
                            )}
                            name='clientVehicleRadius'
                        >
                            <Select
                                disabled={this.bodyUpdateIsForbidden()}
                                onChange={(value, option) => {
                                    updateOrderField({
                                        clientVehicleRadius: value
                                    });
                                }}
                                style={{
                                    width: '30%',
                                    marginRight: 32
                                }}
                            >
                                {radiuses.map(radius => (
                                    <Option key={radius} value={radius}>
                                        {`${radius}R`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                )}
            </div>
        );
    };

    _renderCommentsBlock = () => {
        const { fetchedOrder, user } = this.props;
        const { ACCESS_ORDER_COMMENTS } = permissions;

        return (
            <div className={Styles.commentsBlock}>
                <div className={Styles.commentExtended}>
                    <Form.Item
                        initialValue={_.get(fetchedOrder, 'order.comment')}
                        label={this._getLocalization('add_order_form.client_comments')}
                        name='comment'
                        rules={this.recommendationRules}
                        {...(this.state.recommendationStyles.prevRecommendation
                            ? formCommentLayout
                            : fromExpandedCommentLayout)}
                    >
                        <Input.TextArea
                            autoSize={this._recommendationAutoSize}
                            className={this.state.recommendationStyles.value}
                            disabled={isForbidden(user, ACCESS_ORDER_COMMENTS)}
                            placeholder={this._getLocalization('add_order_form.client_comments')}
                        />
                    </Form.Item>
                </div>
                {this.state.recommendationStyles.prevRecommendation && (
                    <Form.Item
                        initialValue={this.state.recommendationStyles.prevRecommendation}
                        label={this._getLocalization('add_order_form.prev_order_recommendations')}
                        name='prevRecommendation'
                        rules={this.recommendationRules}
                        {...fromExpandedCommentLayout}
                    >
                        <Input.TextArea
                            autoSize={this._prevRecommendationAutoSize}
                            className={Styles.comment}
                            disabled
                            placeholder={this._getLocalization('add_order_form.client_comments')}
                        />
                    </Form.Item>
                )}
            </div>
        );
    };

    _getRecommendationStyles() {
        const { orderId: id, orderHistory } = this.props;
        const orders = _.get(orderHistory, 'orders');
        const orderIndexInHistory = _.findIndex(orders, { id });
        const prevRecommendation =
            orderIndexInHistory !== -1
                ? _.get(orderHistory, ['orders', orderIndexInHistory + 1, 'recommendation'])
                : null;

        const value = cx({
            comment: true,
            commentExtended: !prevRecommendation
        });

        return { value, prevRecommendation };
    }

    _getClientPhonesOptions() {
        return _.get(this.props, 'selectedClient.phones', [])
            .filter(Boolean)
            .map(phone => (
                <Option key={v4()} value={phone}>
                    {phone}
                </Option>
            ));
    }

    _getClientVehiclesOptions() {
        return _.get(this.props, 'selectedClient.vehicles', []).map(vehicle => (
            <Option
                key={v4()}
                disabled={vehicle.disabled}
                radius={vehicle.wheelRadius}
                type={vehicle.vehicleTypeId}
                value={vehicle.id}
            >
                {formatVehicleLabel(vehicle, this.props.intl.formatMessage)}
            </Option>
        ));
    }

    _getLocalization(key) {
        if (!this._localizationMap[key]) {
            this._localizationMap[key] = this.props.intl.formatMessage({
                id: key
            });
        }

        return this._localizationMap[key];
    }

    bodyUpdateIsForbidden() {
        return isForbidden(this.props.user, permissions.ACCESS_ORDER_BODY);
    }

    render() {
        const clientSearch = this._renderClientSearch();
        const clientColumn = this._renderClientColumn();
        const vehicleColumn = this._renderVehicleColumn();
        const comments = this._renderCommentsBlock();
        const clientsSearchTable = this._renderClientSearchTable();

        return (
            <div className={Styles.clientBlock}>
                {clientSearch}
                {clientsSearchTable}
                <div className={Styles.clientData}>
                    {clientColumn}
                    {vehicleColumn}
                </div>
                {comments}
            </div>
        );
    }
}
