import dayjs from 'dayjs';
/* Constants */
export const moduleName = 'salesReportModal';
const prefix = `cpb/${moduleName}`;

export const DOWNLOAD_SALES_REPORT = `${prefix}/DOWNLOAD_SALES_REPORT`;

export const SET_TYPE = `${prefix}/SET_TYPE`;
export const SET_CREATION_FROM_DATE = `${prefix}/SET_CREATION_FROM_DATE`;
export const SET_CREATION_TO_DATE = `${prefix}/SET_CREATION_TO_DATE`;

export const SET_VEHICLE = `${prefix}/SET_VEHICLE`;
export const SET_CLIENT = `${prefix}/SET_CLIENT`;
export const SET_ORDER_ID = `${prefix}/SET_ORDER_ID`;
export const SET_RECOMMENDATIONS = `${prefix}/SET_RECOMMENDATIONS`;
export const SET_STATUS = `${prefix}/SET_STATUS`;

export const SET_APPOINTMENT_FROM_DATE = `${prefix}/SET_APPOINTMENT_FROM_DATE`;
export const SET_APPOINTMENT_TO_DATE = `${prefix}/SET_APPOINTMENT_TO_DATE`;

export const SET_DONE_FROM_DATE = `${prefix}/SET_DONE_FROM_DATE`;
export const SET_DONE_TO_DATE = `${prefix}/SET_DONE_TO_DATE`;

export const SET_DETAILING = `${prefix}/SET_DETAILING`;
export const SET_MANAGER_ID = `${prefix}/SET_MANAGER_ID`;
export const SET_MECHANIC_ID = `${prefix}/SET_MECHANIC_ID`;
export const SET_PART_EMPLOYEE = `${prefix}/SET_PART_EMPLOYEE`;
export const SET_REQUISITE_ID = `${prefix}/SET_REQUISITE_ID`;

export const SET_MECHANIC_SUM_LABORS = `${prefix}/SET_MECHANIC_SUM_LABORS`;
export const SET_SPARE_PART_EMPLOYEE_MARGIN_PARTS = `${prefix}/SET_SPARE_PART_EMPLOYEE_MARGIN_PARTS`;
export const SET_MANAGER_SUM_LABORS = `${prefix}/SET_MANAGER_SUM_LABORS`;
export const SET_MANAGER_MARGIN_SUPPLIER = `${prefix}/SET_MANAGER_MARGIN_SUPPLIER`;
export const SET_MANAGER_MARGIN_PARTS = `${prefix}/SET_MANAGER_MARGIN_PARTS`;

/**
 * Available values to send on server, used to define detailing of sales report
 */
export const detailings = {
    without: { title: 'without_detailing', value: 'without' },
    orders: { title: 'with_orders', value: 'orders' },
    requisites: { title: 'with_requisites', value: 'requisites' },
    stations: { title: 'with_posts', value: 'stations' },
    labors: { title: 'with_labors', value: 'labors' },
    parts: { title: 'with_parts', value: 'parts' },
    recommendations: { title: 'with_recommendations', value: 'recommendations' }
};

/** Date format for backend */
export const DEF_DATE_FORMAT = 'YYYY-MM-DD';

/* Reducer */
const ReducerState = {
    filters: {
        type: 'byDate',
        creationFromDate: undefined,
        creationToDate: undefined,
        vehicleId: undefined,
        clientId: undefined,
        recommendationId: undefined,
        statusOption: undefined,

        appointmentFromDate: undefined,
        appointmentToDate: undefined,

        doneFromDate: dayjs().startOf('month').format(DEF_DATE_FORMAT),
        doneToDate: dayjs().endOf('month').format(DEF_DATE_FORMAT),

        detailing: detailings.without.value,

        orderId: undefined,
        managerId: undefined,
        mechanicId: undefined,
        sparePartEmployeeId: undefined,
        requisiteId: undefined,
        mechanicSumLaborsPercent: undefined,
        sparePartEmployeeMarginPartsPercent: undefined,
        managerSumLaborsPercent: undefined,
        managerMarginSupplierPercent: undefined,
        managerMarginPartsPercent: undefined
    }
};

export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_TYPE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    type: payload
                }
            };

        case SET_CREATION_FROM_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    creationFromDate: payload
                }
            };

        case SET_CREATION_TO_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    creationToDate: payload
                }
            };

        case SET_VEHICLE:
            const { vehicleId } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    vehicleId
                }
            };

        case SET_CLIENT:
            const { clientId } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    clientId,
                    vehicleId: undefined
                }
            };

        case SET_ORDER_ID:
            const { orderId } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    orderId
                }
            };

        case SET_RECOMMENDATIONS:
            const { recommendationId } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    recommendationId
                }
            };

        case SET_STATUS:
            const { statusOption } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    statusOption
                }
            };

        case SET_APPOINTMENT_FROM_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    appointmentFromDate: payload
                }
            };

        case SET_APPOINTMENT_TO_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    appointmentToDate: payload
                }
            };

        case SET_DONE_FROM_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    doneFromDate: payload
                }
            };

        case SET_DONE_TO_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    doneToDate: payload
                }
            };

        case SET_DETAILING:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    detailing: payload
                }
            };

        case SET_MANAGER_ID:
            const { managerId } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    managerId
                }
            };

        case SET_MECHANIC_ID:
            const { mechanicId } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    mechanicId
                }
            };

        case SET_PART_EMPLOYEE:
            const { sparePartEmployeeId } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    sparePartEmployeeId
                }
            };

        case SET_REQUISITE_ID:
            const { requisiteId } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    requisiteId
                }
            };

        case SET_MECHANIC_SUM_LABORS:
            const { mechanicSumLaborsPercent } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    mechanicSumLaborsPercent
                }
            };

        case SET_SPARE_PART_EMPLOYEE_MARGIN_PARTS:
            const { sparePartEmployeeMarginPartsPercent } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    sparePartEmployeeMarginPartsPercent
                }
            };

        case SET_MANAGER_SUM_LABORS:
            const { managerSumLaborsPercent } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    managerSumLaborsPercent
                }
            };

        case SET_MANAGER_MARGIN_SUPPLIER:
            const { managerMarginSupplierPercent } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    managerMarginSupplierPercent
                }
            };

        case SET_MANAGER_MARGIN_PARTS:
            const { managerMarginPartsPercent } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    managerMarginPartsPercent
                }
            };

        default:
            return state;
    }
}

/* Selectors */

export const selectCreationFromDate = state => state[moduleName].filters.creationFromDate;
export const selectCreationToDate = state => state[moduleName].filters.creationToDate;

export const selectVehicle = state => state[moduleName].filters.vehicleId;
export const selectClient = state => state[moduleName].filters.clientId;
export const selectRecommendations = state => state[moduleName].filters.recommendationId;
export const selectStatus = state => state[moduleName].filters.statusOption;

export const selectAppointmentFromDate = state => state[moduleName].filters.appointmentFromDate;
export const selectAppointmentToDate = state => state[moduleName].filters.appointmentToDate;

export const selectDoneFromDate = state => state[moduleName].filters.doneFromDate;
export const selectDoneToDate = state => state[moduleName].filters.doneToDate;

export const selectDetailing = state => state[moduleName].filters.detailing;
export const selectFilters = state => state[moduleName].filters;

export const selectOrderId = state => state[moduleName].filters.orderId;

/* Actions */

export const downloadSalesReport = () => ({
    type: DOWNLOAD_SALES_REPORT
});

export const setType = type => ({
    type: SET_TYPE,
    payload: type
});

export const setCreationFromDate = creationFromDate => ({
    type: SET_CREATION_FROM_DATE,
    payload: creationFromDate
});

export const setCreationToDate = creationToDate => ({
    type: SET_CREATION_TO_DATE,
    payload: creationToDate
});

export const setVehicle = ({ vehicleId }) => ({
    type: SET_VEHICLE,
    payload: { vehicleId }
});

export const setClient = ({ clientId }) => ({
    type: SET_CLIENT,
    payload: { clientId }
});

export const setOrderId = ({ orderId }) => ({
    type: SET_ORDER_ID,
    payload: { orderId }
});

export const setRecommendations = ({ recommendationId }) => ({
    type: SET_RECOMMENDATIONS,
    payload: { recommendationId }
});

export const setStatus = ({ statusOption }) => ({
    type: SET_STATUS,
    payload: { statusOption }
});

export const setAppointmentFromDate = appointmentFromDate => ({
    type: SET_APPOINTMENT_FROM_DATE,
    payload: appointmentFromDate
});

export const setAppointmentToDate = appointmentToDate => ({
    type: SET_APPOINTMENT_TO_DATE,
    payload: appointmentToDate
});

export const setDoneFromDate = doneFromDate => ({
    type: SET_DONE_FROM_DATE,
    payload: doneFromDate
});

export const setDoneToDate = doneToDate => ({
    type: SET_DONE_TO_DATE,
    payload: doneToDate
});

/**
 * Set detailing property for report
 * @param {*} detailing - one of "without_detailing", "with_orders", "with_counterparties", "with_working_place"
 */
export const setDetailing = detailing => ({
    type: SET_DETAILING,
    payload: detailing
});

export const setManagerId = ({ managerId }) => ({
    type: SET_MANAGER_ID,
    payload: { managerId }
});

export const setMechanicId = ({ mechanicId }) => ({
    type: SET_MECHANIC_ID,
    payload: { mechanicId }
});

export const setSparePartEmployeeId = ({ sparePartEmployeeId }) => ({
    type: SET_PART_EMPLOYEE,
    payload: { sparePartEmployeeId }
});

export const setRequisiteId = ({ requisiteId }) => ({
    type: SET_REQUISITE_ID,
    payload: { requisiteId }
});

export const setMechanicSumLaborsPercent = ({ mechanicSumLaborsPercent }) => ({
    type: SET_MECHANIC_SUM_LABORS,
    payload: { mechanicSumLaborsPercent }
});

export const setSparePartEmployeeMarginPartsPercent = ({ sparePartEmployeeMarginPartsPercent }) => ({
    type: SET_SPARE_PART_EMPLOYEE_MARGIN_PARTS,
    payload: { sparePartEmployeeMarginPartsPercent }
});

export const setManagerSumLaborsPercent = ({ managerSumLaborsPercent }) => ({
    type: SET_MANAGER_SUM_LABORS,
    payload: { managerSumLaborsPercent }
});

export const setManagerMarginSupplierPercent = ({ managerMarginSupplierPercent }) => ({
    type: SET_MANAGER_MARGIN_SUPPLIER,
    payload: { managerMarginSupplierPercent }
});

export const setManagerMarginPartsPercent = ({ managerMarginPartsPercent }) => ({
    type: SET_MANAGER_MARGIN_PARTS,
    payload: { managerMarginPartsPercent }
});
