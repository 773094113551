import { Tabs } from 'antd';
import {
    createEmployeeBreakSchedule,
    createEmployeeSchedule,
    deleteEmployeeBreakSchedule,
    deleteEmployeeSchedule,
    fetchEmployeeSchedule,
    updateEmployeeBreakSchedule,
    updateEmployeeSchedule
} from 'core/employeeSchedule/duck';
import { BreakScheduleForm, ScheduleForm } from 'forms';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isForbidden, permissions } from 'utils';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    initialSchedule: state.employeeSchedule.schedule,
    initialBreakSchedule: state.employeeSchedule.nonWorkingDays,
    loading: state.employeeSchedule.loading,
    user: state.auth
});

const mapDispatchToProps = {
    createEmployeeSchedule,
    updateEmployeeSchedule,
    deleteEmployeeSchedule,

    createEmployeeBreakSchedule,
    updateEmployeeBreakSchedule,
    deleteEmployeeBreakSchedule,

    fetchEmployeeSchedule
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class EmployeeScheduleForm extends Component {
    componentDidMount() {
        const { employeeId } = this.props;
        this.props.fetchEmployeeSchedule(employeeId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.employeeId !== prevProps.employeeId) {
            const { employeeId } = this.props;
            this.props.fetchEmployeeSchedule(employeeId);
        }
    }

    render() {
        const { initialSchedule, initialBreakSchedule, employeeId, loading, user } = this.props;

        const {
            createEmployeeSchedule,
            updateEmployeeSchedule,
            deleteEmployeeSchedule,

            createEmployeeBreakSchedule,
            updateEmployeeBreakSchedule,
            deleteEmployeeBreakSchedule
        } = this.props;

        return (
            <div>
                <Tabs defaultActiveKey='1'>
                    <TabPane key='1' tab={<FormattedMessage id='working_days' />}>
                        <ScheduleForm
                            createSchedule={createEmployeeSchedule.bind(null, employeeId)}
                            deleteSchedule={deleteEmployeeSchedule.bind(null, employeeId)}
                            forbiddenUpdate={isForbidden(user, permissions.CREATE_EDIT_DELETE_EMPLOYEES)}
                            initialSchedule={initialSchedule}
                            loading={loading}
                            updateSchedule={updateEmployeeSchedule.bind(null, employeeId)}
                        />
                    </TabPane>
                    <TabPane key='2' tab={<FormattedMessage id='non_working_days' />}>
                        <BreakScheduleForm
                            createBreakSchedule={createEmployeeBreakSchedule.bind(null, employeeId)}
                            deleteBreakSchedule={deleteEmployeeBreakSchedule.bind(null, employeeId)}
                            forbiddenUpdate={isForbidden(user, permissions.CREATE_EDIT_DELETE_EMPLOYEES)}
                            initialBreakSchedule={initialBreakSchedule}
                            loading={loading}
                            updateBreakSchedule={updateEmployeeBreakSchedule.bind(null, employeeId)}
                        />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}
