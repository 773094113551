exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__formContent---27GNA{display:flex;flex-direction:row;justify-content:space-between;height:100%;width:100%}.styles-m__formContent---27GNA .ant-form-item-control-wrapper{display:block!important;width:100%!important}.styles-m__filterBlock---1voTL{padding:10px 0;min-width:30%}.styles-m__row---EIfCt{min-height:6em}.styles-m__col---3z0wR{padding:5px;display:block!important}.styles-m__colText---2tN76{display:flex;justify-content:flex-start;align-items:center;padding:5px}.styles-m__formItemStyle---JO67z{margin:0!important;display:block!important;width:100%!important}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,+BACI,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAC9B,WAAY,CACZ,UACJ,CAIA,8DACI,uBAAyB,CACzB,oBACJ,CAEA,+BACI,cAAsB,CACtB,aACJ,CAEA,uBACI,cAGJ,CAEA,uBACI,WAAY,CACZ,uBACJ,CAEA,2BACI,YAAa,CACb,0BAA2B,CAC3B,kBAAmB,CACnB,WACJ,CAEA,iCACI,kBAAoB,CACpB,uBAAyB,CACzB,oBACJ","file":"styles.m.css","sourcesContent":[".formContent {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    height: 100%;\n    width: 100%;\n}\n\n\n/* Fix \"DecoratedSelect\" positioning */\n.formContent :global(.ant-form-item-control-wrapper) {\n    display: block !important;\n    width: 100% !important;\n}\n\n.filterBlock {\n    padding: 10px 0 10px 0;\n    min-width: 30%;\n}\n\n.row {\n    min-height: 6em;\n    /* display: flex;\n    align-items: center; */\n}\n\n.col {\n    padding: 5px;\n    display: block !important;\n}\n\n.colText {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    padding: 5px;\n}\n\n.formItemStyle {\n    margin: 0 !important;\n    display: block !important;\n    width: 100% !important;\n}"]}]);

// Exports
exports.locals = {
	"formContent": "styles-m__formContent---27GNA",
	"filterBlock": "styles-m__filterBlock---1voTL",
	"row": "styles-m__row---EIfCt",
	"col": "styles-m__col---3z0wR",
	"colText": "styles-m__colText---2tN76",
	"formItemStyle": "styles-m__formItemStyle---JO67z"
};