import { Input, Table } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import {
    selectOrders,
    selectOrdersFetching,
    selectOrdersQuery,
    selectOrdersStats,
    selectSelectedOrderId,
    setOrdersPage,
    setOrdersSearchQuery,
    setSelectedOrderId
} from '../../redux/duck';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    orders: selectOrders(state),
    stats: selectOrdersStats(state),
    query: selectOrdersQuery(state),
    selectedOrderId: selectSelectedOrderId(state),
    ordersFetching: selectOrdersFetching(state)
});

const mapDispatchToProps = {
    setOrdersPage,
    setSelectedOrderId,
    setOrdersSearchQuery
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleOrdersTable extends React.Component {
    constructor(props) {
        super(props);

        this.handleSearch = _.debounce(value => {
            this.props.setOrdersSearchQuery({ query: value.replace(/[+()]/g, '') });
        }, 1000).bind(this);
    }

    onSearch = e => {
        const { value } = e.target;
        this.handleSearch(value);
    };

    render() {
        const {
            orders,
            stats,
            query,
            intl: { formatMessage },
            setOrdersPage,
            setSelectedOrderId,
            selectedOrderId,
            ordersFetching
        } = this.props;

        const pagination = {
            pageSize: 25,
            size: 'small',
            total: Math.ceil(stats.countOrders / 25) * 25,
            current: query.page,
            onChange: page => {
                setOrdersPage({ page });
            }
        };

        return (
            <div>
                <div>
                    <div>
                        <FormattedMessage id='client_hot_operations_page.search' />:{' '}
                    </div>
                    <div>
                        <Input allowClear onChange={this.onSearch} />
                    </div>
                </div>

                <Table
                    bordered
                    columns={columnsConfig({ formatMessage })}
                    dataSource={orders}
                    loading={ordersFetching}
                    onRow={order => {
                        return {
                            onClick: event => setSelectedOrderId({ orderId: order.id })
                        };
                    }}
                    pagination={pagination}
                    rowClassName={order => {
                        return order.id == selectedOrderId ? Styles.selectedRow : Styles.tableRow;
                    }}
                    rowKey={() => v4()}
                    scroll={{ x: 'auto', y: '50vh' }}
                    size='small'
                />
            </div>
        );
    }
}
