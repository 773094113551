import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from './styles.m.css';

// Choose width for each col, (100% in total or less than 100% if 'auto' is present)
const defWidth = {
    clientCol: 'auto',
    vehiclesCol: '40%',
    vinCol: '20%',
    numberCol: '20%'
};

export function columnsConfig() {
    const clientCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.client' />,
        width: defWidth.clientCol,
        align: 'left',
        key: 'clientCol',
        render: client => {
            const { phones } = client;

            return (
                <div>
                    <div>{`${client.name || ''} ${client.middleName || ''} ${
                        client.surname || ''
                    }`}</div>
                    {phones &&
                        _.map(phones, (phone, index) => {
                            return <div key={index}>{phone}</div>;
                        })}
                </div>
            );
        }
    };
    const vehiclesCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.vehicles' />,
        width: defWidth.vehiclesCol,
        align: 'left',
        key: 'vehiclesCol',
        render: client => {
            const vehicles = _.get(client, 'vehicles');

            return vehicles
                ? _.map(vehicles, (vehicle, index) => {
                      return (
                          <div key={index} className={Styles.vehicle}>
                              {`${_.get(vehicle, 'make', '')} ${_.get(
                                  vehicle,
                                  'model',
                                  ''
                              )} ${_.get(vehicle, 'year', '')}`}
                          </div>
                      );
                  })
                : null;
        }
    };
    const vinCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.vin' />,
        width: defWidth.vinCol,
        align: 'left',
        key: 'vinCol',
        render: client => {
            const vehicles = _.get(client, 'vehicles');

            return vehicles
                ? _.map(vehicles, (vehicle, index) => {
                      const vehicleVin = vehicle.vin || '';

                      return (
                          <div key={index}>
                              <div>{`${vehicleVin}`}</div>
                          </div>
                      );
                  })
                : null;
        }
    };
    const numberCol = {
        title: <FormattedMessage id='refactored_cash_order_modal.number' />,
        width: defWidth.numberCol,
        align: 'left',
        key: 'numberCol',
        render: client => {
            const vehicles = _.get(client, 'vehicles');

            return vehicles
                ? _.map(vehicles, (vehicle, index) => {
                      const vehicleNumber = vehicle.number || '';

                      return (
                          <div key={index}>
                              <div>{`${vehicleNumber}`}</div>
                          </div>
                      );
                  })
                : null;
        }
    };

    return [clientCol, vehiclesCol, vinCol, numberCol];
}
