exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__closedCashboxIcon---2trLR{color:var(--disabled)}.styles-m__openCashboxIcon---1X9sz{color:var(--green)}.styles-m__notAvailableCashboxIcon---2rVyB{color:var(--text2)}.styles-m__cashboxStatusIcon---2VMXV,.styles-m__closedCashboxIcon---2trLR,.styles-m__notAvailableCashboxIcon---2rVyB,.styles-m__openCashboxIcon---1X9sz{font-size:20px;vertical-align:sub}.styles-m__tooltip---3Q1Zr{z-index:9999999999}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,qCACI,qBACJ,CAEA,mCACI,kBACJ,CAEA,2CACI,kBACJ,CAEA,wJACI,cAAe,CACf,kBACJ,CAEA,2BACI,kBACJ","file":"styles.m.css","sourcesContent":[".closedCashboxIcon {\n    color: var(--disabled);\n}\n\n.openCashboxIcon {\n    color: var(--green);\n}\n\n.notAvailableCashboxIcon {\n    color: var(--text2);\n}\n\n.cashboxStatusIcon, .closedCashboxIcon, .openCashboxIcon, .notAvailableCashboxIcon {\n    font-size: 20px;\n    vertical-align: sub;\n}\n\n.tooltip {\n    z-index: 9999999999;\n}"]}]);

// Exports
exports.locals = {
	"closedCashboxIcon": "styles-m__closedCashboxIcon---2trLR",
	"openCashboxIcon": "styles-m__openCashboxIcon---1X9sz",
	"notAvailableCashboxIcon": "styles-m__notAvailableCashboxIcon---2rVyB",
	"cashboxStatusIcon": "styles-m__cashboxStatusIcon---2VMXV",
	"tooltip": "styles-m__tooltip---3Q1Zr"
};