// core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderMenu from './HeaderMenu';
import Styles from './styles.m.css';

const mapStateToProps = state => {
    return {
        user: state.auth
    };
};

@connect(mapStateToProps, void 0)
class Header extends Component {
    state = {
        sidebarTooltip: false
    };

    _setSidebarTooltip = (visibility, tooltipMode) => {
        if (!tooltipMode) {
            this.props.toggleNavigation();
        }
        this.setState(state => ({
            sidebarTooltip: !state.sidebarTooltip
        }));
    };

    render() {
        const { collapsed, user } = this.props;

        return (
            <div className={`${Styles.header} ${collapsed && Styles.headerCollapsed}`}>
                <div className={Styles.headerWrapper}>
                    <HeaderMenu {...this.props} />
                </div>
            </div>
        );
    }
}

export default Header;
