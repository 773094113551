import { Select } from 'antd';
import { DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React from 'react';

const { Option } = Select;

const measureUnits = Object.freeze({
    PIECE: 'PIECE',
    LITER: 'LITER'
});

const measureUnitsHumanization = {
    PIECE: ['шт', 'шт.', 'штука', 'штуки'],
    LITER: ['л', 'л.', 'литры', 'литр', 'літри', 'литер', 'літер', 'літр']
};

export default props => {
    const {
        field,
        label,
        formItem,
        formItemLayout,
        formatMessage,
        getFieldDecorator,
        getPopupContainer,
        initialValue,
        rules,
        disabled,
        cnStyles
    } = props;

    const value = _.findKey(measureUnitsHumanization, units => {
        return units.includes(initialValue);
    });

    return (
        <DecoratedSelect
            cnStyles={cnStyles}
            disabled={disabled}
            field={field}
            fields={{}}
            formItem={formItem}
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
            getPopupContainer={getPopupContainer}
            initialValue={initialValue || value || measureUnits.PIECE}
            label={label}
            rules={rules}
        >
            <Option key='piece' value={measureUnits.PIECE}>
                {formatMessage({ id: 'storage.measure.piece' })}
            </Option>
            <Option key='liter' value={measureUnits.LITER}>
                {formatMessage({ id: 'storage.measure.liter' })}
            </Option>
        </DecoratedSelect>
    );
};
