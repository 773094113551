import { Radio, Table } from 'antd';
import { Catcher, Loader } from 'commons';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import { AddClientModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './callsTableConfig.js';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const mapStateToProps = state => ({
    modal: state.modals.modal
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CallsTable extends Component {
    _setCallsTableFilterMode = mode => {
        this.props.setCallsTableMode(mode);
        this.props.fetchCalls();
    };

    /**
     * When "Create new client" button is pressed we have to open creating modal
     * @param params.initialPhoneNuber This phone number will be in modal's phone input
     */
    onAddClientModal = ({ initialPhoneNumber } = {}) => {
        this.props.setModal(MODALS.ADD_CLIENT, { initialPhoneNumber });
    };

    render() {
        const { calls, stats, filter, callsFetching, fetchRecordingLink, callsLinksCache } = this.props;

        const columns = columnsConfig({
            fetchRecordingLink,
            callsLinksCache,
            onAddClientModal: this.onAddClientModal
        });

        const pagination = {
            pageSize: 25,
            size: 'small',
            total: Math.ceil(_.get(stats, 'total') / 25) * 25,
            hideOnSinglePage: true,
            current: filter.page,
            onChange: page => {
                this.props.setCallsPageFilter(page);
                this.setState({ visiblePhones: [] });
                this.props.fetchCalls();
            }
        };

        const callsTableControls = this._renderCallsTableControls();

        return callsFetching ? (
            <Loader loading={callsFetching} />
        ) : (
            <Catcher>
                {callsTableControls}
                <Table
                    bordered
                    className={Styles.table}
                    columns={columns}
                    dataSource={calls}
                    loading={callsFetching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={pagination}
                    rowKey={() => v4()}
                    scroll={{ x: 1080 }}
                    size='small'
                />

                <AddClientModal resetModal={this.props.resetModal} visible={this.props.modal} />
            </Catcher>
        );
    }

    _renderCallsTableControls = () => {
        const { filter } = this.props;

        return (
            <RadioGroup style={{ marginBottom: 8 }} value={filter.mode}>
                <RadioButton onClick={() => this._setCallsTableFilterMode('all')} value='all'>
                    <FormattedMessage id='calls-table.all' />
                </RadioButton>

                <RadioButton onClick={() => this._setCallsTableFilterMode('answered')} value='answered'>
                    <FormattedMessage id='calls-table.answered' />
                </RadioButton>

                <RadioButton onClick={() => this._setCallsTableFilterMode('missed')} value='missed'>
                    <FormattedMessage id='calls-table.missed' />
                </RadioButton>
            </RadioGroup>
        );
    };
}
