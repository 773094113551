import { FormattedDatetime } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    index: '5%',
    name: 'auto',
    document: '15%',
    status: '10%',
    priority: '7%',
    performerFullName: '12%',
    scheduledDatetime: '10%',
    dueDatetime: '15%'
};

export function columnsConfig(props) {
    const { onEmployeeSubTasks, filters, handleTaskRedirect } = props;

    const indexCol = {
        title: '№',
        width: defWidth.index,
        dataIndex: 'id',
        key: 'key',
        render: (id, obj, index) => id
    };

    const nameCol = {
        title: <FormattedMessage id='order_tasks.name' />,
        width: defWidth.name,
        dataIndex: 'name',
        render: (name, obj) => {
            return (
                <div
                    className={Styles.taskRow}
                    onClick={async () => {
                        handleTaskRedirect(obj);
                    }}
                >
                    {name}
                </div>
            );
        }
    };

    const documentCol = {
        title: <FormattedMessage id='order_tasks.document' />,
        width: defWidth.document,
        render: (status, obj) => {
            return (
                <div>
                    {(obj.orderNum && <Link to={`${book.order}/${obj.orderId}`}>{obj.orderNum}</Link>) ||
                        (obj.storeDocNum && isGrantAccessed(props.user, grants.WAREHOUSE_DOCUMENT) ? (
                            <Link to={`${book.storageDocument}/${obj.storeDocId}`}>{obj.storeDocNum}</Link>
                        ) : (
                            obj.storeDocNum
                        )) ||
                        obj.cashOrderId}
                </div>
            );
        }
    };

    const statusCol = {
        title: <FormattedMessage id='order_tasks.status' />,
        width: defWidth.status,
        dataIndex: 'status',
        render: (status, obj) => {
            return (
                <div>
                    <FormattedMessage id={`order_tasks.status_${String(status).toLowerCase()}`} />
                </div>
            );
        }
    };

    const priorityCol = {
        title: <FormattedMessage id='order_tasks.priority' />,
        width: defWidth.priority,
        dataIndex: 'priority',
        render: (priority, obj) => {
            return (
                <div>{priority === 'HIGH' ? '!!!' : priority === 'MIDDLE' ? '!!' : priority === 'LOW' ? '!' : ''}</div>
            );
        }
    };

    const performerFullNameCol = {
        title: <FormattedMessage id='order_tasks.performer' />,
        width: defWidth.performerFullName,
        dataIndex: 'performerFullName'
    };

    const scheduledDatetimeCol = {
        title: <FormattedMessage id='order_tasks.scheduled_datetime' />,
        width: defWidth.scheduledDatetime,
        dataIndex: 'scheduledDatetime',
        render: scheduledDatetime => (
            <React.Fragment>
                <FormattedDatetime datetime={scheduledDatetime} format={DEFAULT_DATETIME} />
            </React.Fragment>
        )
    };

    const dueDatetimeCol = {
        title: <FormattedMessage id='order_tasks.due_datetime' />,
        width: defWidth.dueDatetime,
        render: (elem, obj) => {
            const isCompleted = obj.status == 'COMPLETED';

            let background = null;
            let color = null;

            const duration = dayjs(obj.scheduledDatetime) - dayjs();

            if (obj.status == 'NEW') {
                background = 'var(--db_progress)';
            } else if (duration > 0 && duration <= 600000) {
                // 10 minutes left
                background = 'var(--db_approve)';
            } else if (dayjs(obj.scheduledDatetime) < dayjs()) {
                background = 'var(--disabled)';
                color = 'white';
            }

            return isCompleted ? (
                <div
                    style={{
                        padding: '6px 8px',
                        textAlign: 'center',
                        fontWeight: 500,
                        cursor: 'pointer'
                    }}
                >
                    <FormattedMessage id='long_dash' />
                </div>
            ) : (
                <div
                    style={{
                        color,
                        background,
                        padding: '6px 8px',
                        textAlign: 'center',
                        fontWeight: 500,
                        cursor: 'pointer'
                    }}
                >
                    {obj.toDueDatetimeYear !== 0 && `${obj.toDueDatetimeYear}y `}
                    {obj.toDueDatetimeMonth !== 0 && `${obj.toDueDatetimeMonth}m `}
                    {obj.toDueDatetimeDay !== 0 && `${obj.toDueDatetimeDay}d `}

                    {dayjs(`${obj.toDueDatetimeHour}:${obj.toDueDatetimeMinute}`, 'HH:mm').format('H:mm')}
                </div>
            );
        }
    };

    return [
        indexCol,
        nameCol,
        documentCol,
        statusCol,
        priorityCol,
        performerFullNameCol,
        scheduledDatetimeCol,
        dueDatetimeCol
    ];
}
