import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image } from 'antd';
import { Layout } from 'commons';
import { EmployeesContainer } from 'containers';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI, isForbidden, permissions } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

@injectIntl
@connect(mapStateToProps)
export default class EmployeesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false
        };
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_employees' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const { helperDrawerOpen, allLinks } = this.state;

        return (
            <Layout
                controls={
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Link to={book.addEmployee}>
                            <Button
                                disabled={isForbidden(this.props.user, permissions.CREATE_EDIT_DELETE_EMPLOYEES)}
                                type='primary'
                            >
                                <FormattedMessage id='employee-page.add_employee' />
                            </Button>
                        </Link>
                        <div
                            style={{
                                marginLeft: 8,
                                display: 'flex'
                            }}
                        >
                            <Button
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    const links = await fetchAPI(
                                        'GET',
                                        '/helps',
                                        { helpId: 'directories_and_settings_employees' },
                                        undefined,
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    this.setState({
                                        allLinks: links,
                                        helperDrawerOpen: true
                                    });
                                }}
                                style={{
                                    fontSize: 22,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                type='text'
                            />
                        </div>
                    </div>
                }
                description={<FormattedMessage id='employee-page.description' />}
                title={<FormattedMessage id='navigation.employees' />}
            >
                <EmployeesContainer fetchHelperLinks={this.fetchHelperLinks} />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}
