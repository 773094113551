import { Modal, Spin } from 'antd';
import { resetModal, setModal } from 'core/modals/duck';
import { fetchStoreGroups, selectFlattenStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { confirm } = Modal;

const mapStateToProps = state => ({
    modal: state.modals.modal,
    storeGroups: selectStoreGroups(state),
    flattenStoreGroups: selectFlattenStoreGroups(state)
});

const mapDispatchToProps = {
    fetchStoreGroups,
    setModal,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class QuickRequestsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allrequests: [],
            loading: false
        };
    }

    componentDidMount() {
        this.fetchAllRequests();
    }

    fetchAllRequests = async () => {
        const requests = await fetchAPI('GET', 'business/requests', undefined, undefined);
        this.setState({
            allrequests: requests || []
        });
    };

    handleAddRequest = async (serviceId, storeGroupId, name) => {
        const { orderId, defaultEmployeeId } = this.props;

        await setTimeout(
            this.setState({
                loading: true
            }),
            100
        );

        const data = {
            insertMode: true,
            laborRequests: [
                {
                    employeeId: defaultEmployeeId,
                    employeeComment: { positions: [], problems: [] },
                    laborRequestName: { comment: `${name}`, positions: [] },
                    serviceId,
                    storeGroupId
                }
            ],
            details: [],
            services: []
        };
        await fetchAPI('PUT', `orders/${orderId}`, null, data, { handleErrorInternally: true });
        await setTimeout(
            this.setState({
                loading: false
            }),
            100
        );
        await this.props.hideModal();
        this.props.updateDataSource();
    };

    render() {
        const { allrequests, loading } = this.state;

        const {
            intl: { formatMessage },
            orderId
        } = this.props;

        return (
            <Spin spinning={loading}>
                <div className={Styles.containerContent}>
                    {allrequests.map(({ requests, groupId, groupName }) => (
                        <div className={Styles.groupStyle}>
                            <span className={Styles.titleStyle}>{groupName}</span>
                            <div className={Styles.dataRow}>
                                {requests.map(({ id, requestLaborId, requestName, requestNodeId }) => (
                                    <div
                                        className={
                                            groupId == 1
                                                ? Styles.blockStyleHover
                                                : groupId == 2
                                                ? Styles.blockStyleHoverTwo
                                                : groupId == 3
                                                ? Styles.blockStyleHoverThree
                                                : groupId == 4
                                                ? Styles.blockStyleHoverFour
                                                : groupId == 5
                                                ? Styles.blockStyleHoverFive
                                                : null
                                        }
                                        onClick={() => {
                                            if (orderId) {
                                                this.handleAddRequest(requestLaborId, requestNodeId, requestName);
                                            }
                                        }}
                                    >
                                        <div className={Styles.upperTextStyle}>{requestName}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </Spin>
        );
    }
}
