import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Radio, Table, Tree } from 'antd';
import { resetModal, setModal } from 'core/modals/duck';
import { fetchStoreGroups, selectFlattenStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import _ from 'lodash';
import AccountsGroupModal from 'modals/AccountsGroupModal';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fetchAPI, filterByPartGeneral } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { TreeNode } = Tree;
const { Search } = Input;

const radioOptions = Array.from(Array(4)).map((_, index) => index + 1);

const mapStateToProps = state => ({
    user: state.auth,
    modal: state.modals.modal,
    storeGroups: selectStoreGroups(state),
    flattenStoreGroups: selectFlattenStoreGroups(state)
});

const mapDispatchToProps = {
    fetchStoreGroups,
    setModal,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AccountsContainer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            expandedKeys: [],
            searchValue: '',
            autoExpandParent: true,
            editMode: false,
            accountsFlatten: [],
            expandedRowKeys: [],
            foundIds: [],
            visibleLevel: 3,
            pagination: {
                pageSize: 2
            }
        };

        this.handleSearchAccounts = _.debounce(value => {
            this.debouncedFilterChange(value);
        }, 500);

        this.columns = () => [
            {
                title: <FormattedMessage id='accounts_tab.nubmer' />,
                key: 'id',
                render: item => {
                    const { searchValue, foundIds } = this.state;
                    // const search1 = searchValue ? searchValue.substring(0, searchValue.indexOf(' ')) : '~';
                    // const search2 = searchValue ? searchValue.substring(searchValue.indexOf(' ') + 1) : '~';
                    // const color1 =
                    //     String(item.id).toLowerCase().includes(search1.toLowerCase()) &&
                    //     String(item.id).toLowerCase().includes(search2.toLowerCase())
                    //         ? 'green'
                    //         : 'black';

                    return <div className={`${foundIds.includes(item.id) && Styles.foundEntry}`}>{item.id}</div>;
                }
            },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                render: item => {
                    const { searchValue, foundIds } = this.state;
                    // const search1 = searchValue ? searchValue.substring(0, searchValue.indexOf(' ')) : '~';
                    // const search2 = searchValue ? searchValue.substring(searchValue.indexOf(' ') + 1) : '~';
                    // const color1 =
                    //     String(item.name).toLowerCase().includes(search1.toLowerCase()) &&
                    //     String(item.name).toLowerCase().includes(search2.toLowerCase())
                    //         ? 'green'
                    //         : 'black';

                    return <div className={`${foundIds.includes(item.name) && Styles.foundEntry}`}>{item.name}</div>;
                }
            },
            {
                key: 'btns',
                width: '8%',
                render: item => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Button
                                disabled={
                                    !isGrantAccessed(
                                        this.props.user,
                                        grants.SETTINGS_INVOICES_AND_ANALYTICS_INVOICES,
                                        accesses.ROWO
                                    )
                                }
                                icon={<EditOutlined />}
                                onClick={() => {
                                    this.setState({
                                        visibleAccountsModal: true,
                                        editMode: true,
                                        accountGroup: {
                                            id: item.id,
                                            businessId: item.businessId,
                                            name: item.name,
                                            parentId: item.parentId,
                                            type: item.type,
                                            level: item.level,
                                            active: item.active,
                                            custom: item.custom,
                                            entries: item.entries
                                        }
                                    });
                                }}
                            />
                            {isGrantAccessed(
                                        this.props.user,
                                        grants.SETTINGS_INVOICES_AND_ANALYTICS_INVOICES,
                                        accesses.ROWO
                                    ) ? (
                                <Button
                                    disabled={item.entries}
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: `${this.props.intl.formatMessage({
                                                id: 'delete'
                                            })} ?`,
                                            onOk: () => {
                                                this.deleteGroup(item.id);
                                            },
                                            okType: 'primary',
                                            onCancel() {}
                                        });
                                    }}
                                />
                            ) : null}
                        </div>
                    );
                }
            }
        ];
    }

    componentDidUpdate() {
        if (!_.get(this.state.accountsFlatten, 'length')) {
            this.onFlatten(this.props.treeDataQuery);
        }
    }

    onFlatten = accounts => {
        const flattenTree = [];
        const flattenStoreGroups = data => {
            for (let item = 0; item < _.get(data, 'length'); item++) {
                const node = data[item];

                flattenTree.push({
                    id: node.id,
                    name: node.name,
                    level: node.level,
                    parentId: node.parentId
                });
                if (node.children) {
                    flattenStoreGroups(node.children);
                }
            }
        };
        flattenStoreGroups(accounts);

        this.setState({
            accountsFlatten: flattenTree,
            expandedRowKeys: flattenTree.map(({ id }) => id)
        });

        return flattenTree;
    };

    onChange = e => {
        const { treeDataQuery } = this.props;
        const { accountsFlatten } = this.state;
        const { value } = e.target;

        const search1 = value ? value.substring(0, value.indexOf(' ')) : '';
        const search2 = value ? value.substring(value.indexOf(' ') + 1) : '';

        if (!_.get(accountsFlatten, 'length')) {
            this.onFlatten(treeDataQuery);
        }

        const expandedKeys = accountsFlatten
            .filter(
                ({ name }) =>
                    name.toLowerCase().includes(search1.toLowerCase()) &&
                    name.toLowerCase().includes(search2.toLowerCase())
            )
            .map(item => {
                if (
                    item.name.toLowerCase().includes(search1.toLowerCase()) &&
                    item.name.toLowerCase().includes(search2.toLowerCase())
                ) {
                    return item.id;
                }

                return null;
            });

        this.setState({
            expandedRowKeys: expandedKeys,
            searchValue: value,
            autoExpandParent: true
        });
    };

    deleteGroup = async id => {
        await fetchAPI('DELETE', 'general_ledger/accounts', null, { id });
        await this.props.getAccountsList();
    };

    handleLevelVisibility = value => {
        const { accountsFlatten } = this.state;

        const keys = accountsFlatten.filter(({ level }) => level < value).map(({ id }) => id);

        this.setState({
            expandedRowKeys: keys,
            visibleLevel: value
        });
    };

    debouncedFilterChange = filter => {
        const { accountsFlatten, foundIds, visibleLevel, pageSize } = this.state;
        const foundMap = {};
        const found = accountsFlatten.filter(
            accounts => filterByPartGeneral(filter, accounts.name) || filterByPartGeneral(filter, accounts.id)
        );

        const findParents = accounts => {
            if (!foundMap[accounts.id]) {
                foundMap[accounts.id] = accounts;
                const parent = accountsFlatten.find(genEntry => genEntry.id === accounts.parentId);
                if (parent) findParents(parent);
            }
        };

        found.forEach(foundEntry => {
            findParents(foundEntry);
        });

        const res = Object.values(foundMap);
        if (filter.length > 0) {
            const maxLevel = Math.max(...found.map(({ level }) => level));
            const newKeys = res.filter(({ level }) => level < maxLevel).map(({ id }) => id);
            const newfoundIds = found.map(({ id }) => id);
            this.setState({
                expandedRowKeys: newKeys,
                foundIds: newfoundIds
            });
        } else {
            const elseNewKeys = accountsFlatten.filter(({ level }) => level < visibleLevel).map(({ id }) => id);

            this.setState({
                foundIds: [],
                expandedRowKeys: elseNewKeys
            });
        }
        if (found.length < 30) this.setState({ pagination: undefined });
        else {
            this.setState({ pagination: { pageSize: 2 } });
        }
    };

    render() {
        const { treeDataQuery } = this.props;
        const {
            searchValue,
            expandedKeys,
            autoExpandParent,
            accountGroup,
            visibleAccountsModal,
            editMode,
            expandedRowKeys,
            visibleLevel,
            pagination,
            foundIds
        } = this.state;

        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Radio.Group
                        onChange={async e => {
                            await this.handleLevelVisibility(e.target.value);
                            if (e.target.value === 1) {
                                this.setState({ pagination: { pageSize: 9 } });
                            } else {
                                this.setState({ pagination: { pageSize: 2 } });
                            }
                        }}
                        options={radioOptions}
                        optionType='button'
                        value={visibleLevel}
                    />
                    <Input
                        onChange={async e => {
                            this.setState({
                                searchValue: e.target.value
                            });
                            await this.handleSearchAccounts(e.target.value);
                        }}
                        placeholder={this.props.intl.formatMessage({
                            id: 'accounts_tab.search'
                        })}
                        style={{ marginBottom: 8, width: '80%' }}
                    />

                    <Button
                        icon={<PlusOutlined />}
                        onClick={() =>
                            this.setState({
                                visibleAccountsModal: true
                            })
                        }
                    />
                </div>
                <Table
                    bordered
                    columns={this.columns()}
                    dataSource={treeDataQuery}
                    expandable={{
                        expandedRowKeys,
                        onExpandedRowsChange: expandedRowKeys => this.setState({ expandedRowKeys }),
                        rowExpandable: record => record.id,
                        childrenColumnName: 'children'
                    }}
                    expandedKeys={expandedRowKeys}
                    onRow={({ id, level }) => {
                        const classNames = `${foundIds.includes(id) ? Styles.foundEntry : ''}`.trim();
                        if (classNames.length) {
                            return {
                                className: classNames
                            };
                        }
                    }}
                    pagination={pagination}
                    rowKey='id'
                    size='small'
                />
                <AccountsGroupModal
                    accountGroup={accountGroup}
                    editMode={editMode}
                    getAccountsList={this.props.getAccountsList}
                    hideModal={() =>
                        this.setState({
                            visibleAccountsModal: false,
                            editMode: false,
                            accountGroup: undefined
                        })
                    }
                    treeDataNode={treeDataQuery}
                    visible={visibleAccountsModal}
                />
            </div>
        );
    }
}

const StyledTree = styled(Tree)`
    &.ant-tree {
        overflow: scroll;

        .ant-tree-treenode-selected .ant-tree-title {
            margin-left: 24px;
        }
    }

    &.ant-tree li.ant-tree-treenode-selected .ant-tree-node-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;

        & .ant-tree-iconEle.ant-tree-icon__customize {
            position: absolute;
            right: -80px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: rgba(var(--secondaryRGB), 0.2);
        }
    }
`;

const Leaf = styled(TreeNode)`
    /* .ant-tree-node-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;
    } */

    /* .ant-tree-treenode-selected {
        display: inline-flex;
        position: relative;
        margin-right: 80px;
        border-radius: 2px;
    }

    .ant-tree-treenode-selected .ant-tree-iconEle.ant-tree-icon__customize {
        position: absolute;
        right: -80px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: rgba(var(--secondaryRGB), 0.2);
    }

    .ant-tree-treenode-selected .ant-tree-title {
        margin-left: 24px;
    } */
`;
