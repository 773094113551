import { Flex, Segmented } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import { detalizationOpt } from 'pages/GraphicReportsPage/utils/constants';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Styles from '../../styles.m.css';

const ReportSettings = ({ settings, setSettings, disabled }) => {
    const { startDate, endDate, period } = settings;

    const handleChartSettingsChange = settingsObj => {
        setSettings(prev => ({ ...prev, ...settingsObj }));
    };
    const handleDateChange = daterange => {
        const [startDate, endDate] = daterange;
        handleChartSettingsChange({ startDate, endDate });
    };
    const handleDetalizationChange = value => handleChartSettingsChange({ period: value });

    return (
        <Flex gap={48} id='width-trgt' justify='flex-end'>
            <span>
                <span
                    style={{
                        fontWeight: 700
                    }}
                >
                    {startDate ? dayjs(startDate).format('DD.MM.YYYY') : undefined}
                </span>
                {' - '}
                <span
                    style={{
                        fontWeight: 700
                    }}
                >
                    {endDate ? dayjs(endDate).format('DD.MM.YYYY') : undefined}
                </span>{' '}
                <FormattedMessage id='including' />
            </span>
            <Flex className={Styles.chartSettingsContainer} gap={12}>
                <DateRangePicker
                    dateRange={[startDate, endDate]}
                    disabled={disabled}
                    minimize
                    onDateChange={handleDateChange}
                    type='default'
                />
                <Segmented
                    block
                    onChange={handleDetalizationChange}
                    options={detalizationOpt.map(({ label, value }) => ({
                        label: <FormattedMessage id={label} />,
                        value
                    }))}
                    style={{ flexGrow: 1 }}
                    value={period}
                />
            </Flex>
        </Flex>
    );
};

export default ReportSettings;
