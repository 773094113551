import { Button } from 'antd';
import { CashboxesTable } from 'components/Tables';
import { MODALS, setModal } from 'core/modals/duck';
import { AddCashboxModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Layout } from 'tireFitting';
import { isForbidden, permissions } from 'utils';
import { v4 } from 'uuid';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal
};

/**
 * Cash setting page is used to work with cashboxes, you can delere create and setting them up.
 * Currently there are different types of cash boxes, some of them are carbook internal features,
 * and others are connected to government servers(via cashdesk api service for example)
 */
@connect(mapStateToProps, mapDispatchToProps)
export default class CashSettingsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: []
        };
    }

    onAddCashboxModal = () => {
        this.props.setModal(MODALS.ADD_CASHBOX);
    };

    setTableRow = value => {
        this.setState({
            rows: value
        });
    };

    render() {
        const { rows } = this.state;

        return (
            <Layout
                controls={[
                    <Button
                        key={v4()}
                        disabled={isForbidden(this.props.user, permissions.ACCESS_CATALOGUE_CASH_CRUD)}
                        onClick={() => this.onAddCashboxModal()}
                        type='primary'
                    >
                        <FormattedMessage id='add' />
                    </Button>
                ]}
                title={<FormattedMessage id='navigation.cash_settings' />}
            >
                <CashboxesTable setTableRow={this.setTableRow} />
                <AddCashboxModal rows={rows} />
            </Layout>
        );
    }
}
