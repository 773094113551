import { Button, Collapse, Input, Switch, Table, Tooltip } from 'antd';
import { Catcher } from 'commons';
import { resetModal, setModal } from 'core/modals/duck';
import { fetchStoreGroups, selectFlattenStoreGroups, selectStoreGroups } from 'core/storage/storeGroups';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Search } = Input;
const { Panel } = Collapse;

const mapStateToProps = state => ({
    modal: state.modals.modal,
    storeGroups: selectStoreGroups(state),
    flattenStoreGroups: selectFlattenStoreGroups(state),
    user: state.auth
});

const mapDispatchToProps = {
    fetchStoreGroups,
    setModal,
    resetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class UnitsPageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            units: []
        };

        const isDisabledCRUD = !isGrantAccessed(this.props.user, grants.SETTINGS_UNITS_OF_MEASURE, accesses.ROWO);

        this.columns = () => [
            {
                title: <FormattedMessage id='Id' />,
                key: 'id',
                dataIndex: 'id',
                width: 200
            },
            {
                title: <FormattedMessage id='task_page.name' />,
                key: 'name',
                dataIndex: 'name',
                width: 200
            },
            {
                title: <FormattedMessage id='task_page.short_name' />,
                key: 'shortcut',
                dataIndex: 'shortcut',
                width: 200
            },
            {
                title: <FormattedMessage id='universal_chart.show' />,
                key: 'show',
                dataIndex: 'visibility',
                width: 200,
                render: (data, row) => {
                    return (
                        <div>
                            <Tooltip title={<FormattedMessage id='edit_vehicle.display' />}>
                                <Switch
                                    checked={row.visibility}
                                    disabled={isDisabledCRUD}
                                    onChange={async () => {
                                        await fetchAPI('PUT', 'business/measurement/units/settings', undefined, {
                                            id: row.id,
                                            visibility: !row.visibility
                                        });
                                        await setTimeout(() => this.fetchUnitsSettings(), 100);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='navigation.units_services_default' />,
                key: 'visibilityDefault',
                width: 200,
                render: row => {
                    const measurementUnits = this.state.units.map(({ measurementUnits }) => measurementUnits).flat();
                    const checked = measurementUnits.reduce((acc, cur) => acc || cur.defaultUse, false);

                    return (
                        <div>
                            <Tooltip title={<FormattedMessage id='edit_vehicle.display' />}>
                                <Switch
                                    checked={row.defaultUse}
                                    disabled={(checked && !row.defaultUse) || isDisabledCRUD}
                                    onChange={async () => {
                                        await fetchAPI('PUT', 'business/measurement/units/settings', undefined, {
                                            id: row.id,
                                            visibility: row.visibility || true,
                                            defaultUse: !row.defaultUse
                                        });
                                        await setTimeout(() => this.fetchUnitsSettings(), 100);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.fetchUnitsSettings();
    }

    fetchUnits = async () => {
        const units = await fetchAPI('GET', 'business/measurement/units', undefined, undefined, {handleErrorInternally: true});
    };

    fetchUnitsSettings = async () => {
        const units = await fetchAPI('GET', 'business/measurement/units/settings', undefined, undefined, {handleErrorInternally: true});
        this.setState({
            units: units || []
        });
    };

    render() {
        const { units } = this.state;

        const {
            user,
            intl: { formatMessage }
        } = this.props;

        const isDisabledCRUD = !isGrantAccessed(user, grants.SETTINGS_UNITS_OF_MEASURE, accesses.ROWO);

        return (
            <Catcher>
                <div className={Styles.defaultBtn}>
                    <Button
                        disabled={isDisabledCRUD}
                        onClick={async () => {
                            await fetchAPI('DELETE', 'business/measurement/units/settings', null, null, {
                                handleErrorInternally: true
                            });
                            await setTimeout(() => this.fetchUnitsSettings(), 100);
                        }}
                    >
                        <FormattedMessage id='navigation.units_directory_default_btn' />
                    </Button>
                </div>

                {units.map(({ groupId, groupName, measurementUnits }) => (
                    <Collapse>
                        <Panel key={groupId} header={<div>{groupName}</div>}>
                            <Table
                                bordered
                                className={Styles.callsTable}
                                columns={this.columns()}
                                dataSource={measurementUnits}
                                locale={{
                                    emptyText: <FormattedMessage id='no_data' />
                                }}
                                pagination={false}
                                size='small'
                            />
                        </Panel>
                    </Collapse>
                ))}
            </Catcher>
        );
    }
}
