import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { goTo } from 'utils';
import menuConfig from './menuConfig';
import Styles from './styles.m.css';

const SiderMenu = ({ history, collapsed, isMobile, onCollapse, user, hidden, setCollapsedState, logout }) => {
    const defaultOpenKeys = [];
    const selected = menuConfig.getSelectedByRoute(history.location.pathname);
    if (selected.sectionKey && !defaultOpenKeys.includes(selected.sectionKey)) {
        defaultOpenKeys.push(selected.sectionKey);
    }

    if (!defaultOpenKeys.length && !collapsed) {
        defaultOpenKeys.push('operations');
    }

    const menuItems = useMemo(() => {
        const items = menuConfig.sections
            .filter(({ items, visible }) => items && visible(user))
            .map(section => {
                const { key, icon, name, items, disabled } = section;

                return {
                    label: <FormattedMessage id={name} />,
                    key,
                    icon,
                    children: items
                        .filter(({ visible }) => !visible || visible(user))
                        .map(item => {
                            const { key, link, name, disabled, visible } = item;

                            return {
                                key,
                                label: (
                                    <span data-link={link}>
                                        <FormattedMessage id={name} />
                                    </span>
                                ),
                                link
                            };
                        })
                };
            });

        if (isMobile) {
            items.unshift({
                label: <FormattedMessage id='header.profile' />,
                key: 'PROFILE',
                icon: (
                    <UserOutlined
                        style={{
                            paddingRight: 4,
                            fontSize: 14,
                            color:'var(--primary)'
                        }}
                    />
                )
            });
        }

        if (isMobile) {
            items.push({
                label: <FormattedMessage id='header.logout' />,
                key: 'LOGOUT',
                icon: (
                    <LogoutOutlined
                        style={{
                            paddingRight: 4,
                            fontSize: 14,
                            color: '#ff4d4f'
                        }}
                    />
                )
            });
        }

        return items;
    }, [isMobile, user]);

    const mouseDownHandler = event => {
        if (event.button === 1) {
            const url = event.target.getAttribute('data-link') || event.target.getAttribute('link');
            console.log(event.target, url);
            window.open(url, '_blank').focus();
        }
    };

    return (
        <Layout.Sider
            collapsed={collapsed}
            collapsedWidth={64}
            style={{
                background: isMobile ? '#fff' : '#f5f5f5'
            }}
        >
            <Menu
                activeKey={selected.itemKey}
                className={`${Styles.navMenu} ${isMobile && Styles.navMenuMobile}`}
                // defaultOpenKeys={defaultOpenKeys}
                inlineCollapsed={collapsed}
                items={menuItems}
                mode={isMobile ? 'inline' : 'vertical'}
                onAuxClick={mouseDownHandler}
                onClick={({ key }) => {
                    if (key === 'LOGOUT') {
                        logout();
                        onCollapse();
                    } else if (key === 'PROFILE') {
                        goTo(book.profile);
                        if (isMobile) {
                            onCollapse(true);
                        }
                    } else {
                        !isMobile && setCollapsedState(true);
                        goTo(key);
                        if (isMobile) {
                            onCollapse(true);
                        }
                    }
                }}
                selectedKeys={[selected.itemKey]}
                style={{
                    // position: !isMobile && 'fixed',
                    minWidth: isMobile && 250,
                    maxWidth: 480,
                    height: !isMobile && 'calc(100%)',
                    zIndex: 125,
                    transition: '0.5s',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                }}
                theme='light'
            />
        </Layout.Sider>
    );
};

export default withRouter(SiderMenu);
