exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__tabTitle---3czWu{text-align:center;font-size:20px;font-weight:700;padding:14px 0 20px}.styles-m__menuIcon---2t307{float:right;padding:4px 0 0;cursor:pointer}.styles-m__menuIcon---2t307:hover{color:var(--primary)}.styles-m__tabFooter---310I8{text-align:end;margin:8px 0}.styles-m__setAllModalRow---ABHOM{display:flex;justify-content:space-between;width:220px;margin-bottom:8px}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,4BACI,iBAAkB,CAClB,cAAe,CACf,eAAgB,CAChB,mBAEJ,CAEA,4BACI,WAAY,CACZ,eAAgB,CAChB,cAIJ,CAHI,kCACI,oBACJ,CAGJ,6BACI,cAAe,CACf,YACJ,CAEA,kCACI,YAAa,CACb,6BAA8B,CAC9B,WAAY,CACZ,iBACJ","file":"styles.m.css","sourcesContent":[".tabTitle {\n    text-align: center;\n    font-size: 20px;\n    font-weight: 700;\n    padding: 14px 0 20px;\n    /*border-bottom: 1px solid lightgray;*/\n}\n\n.menuIcon {\n    float: right;\n    padding: 4px 0 0;\n    cursor: pointer;\n    &:hover {\n        color: var(--primary);\n    }\n}\n\n.tabFooter {\n    text-align: end;\n    margin: 8px 0;\n}\n\n.setAllModalRow {\n    display: flex;\n    justify-content: space-between;\n    width: 220px;\n    margin-bottom: 8px;\n}"]}]);

// Exports
exports.locals = {
	"tabTitle": "styles-m__tabTitle---3czWu",
	"menuIcon": "styles-m__menuIcon---2t307",
	"tabFooter": "styles-m__tabFooter---310I8",
	"setAllModalRow": "styles-m__setAllModalRow---ABHOM"
};