/*
Container used to show clients and perform basic search of them.
*/
import { Table } from 'antd';
import { MODALS, setModal } from 'core/modals/duck';
import {
    deleteBrandCross,
    selectBrandCrossData,
    selectExpandedBrandRow,
    setBrandsCrossesFilters
} from 'pages/DirectoryBrandsPage/redux/duck';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    brandsCrossData: selectBrandCrossData(state)
});

const mapDispatchToProps = {
    setModal,
    selectExpandedBrandRow,
    setBrandsCrossesFilters,
    deleteBrandCross
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
@withRouter
export default class BrandsCrossesContainer extends React.Component {
    onEditBrandCross = cross => {
        this.props.setBrandsCrossesFilters({ brandId: cross.brandId });

        // call modal
        this.props.setModal(MODALS.BRANDS_CROSS_MODAL, {
            mode: 'EDIT',
            cross
        });
    };

    onDeleteBrandCross = crossId => {
        this.props.deleteBrandCross(crossId);
    };

    render() {
        const { brandsCrossData } = this.props;

        const pagination = {
            pageSize: 5,
            size: 'small',
            total: Math.ceil(brandsCrossData.stats.totalRowsCount / 5) * 5,
            hideOnSinglePage: true,
            current: brandsCrossData.filters.page,
            onChange: page => {
                this.props.setBrandsCrossesFilters({ page });
            }
        };

        return (
            <div>
                <div>
                    <Table
                        bordered
                        className={Styles.table}
                        columns={columnsConfig({
                            onEditBrandCross: this.onEditBrandCross,
                            onDeleteBrandCross: this.onDeleteBrandCross
                        })}
                        dataSource={brandsCrossData.brandsCrosses}
                        pagination={pagination}
                        scroll={{ x: 800, y: '70vh' }}
                    />
                </div>
            </div>
        );
    }
}
