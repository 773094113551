import { DatePicker, InputNumber } from 'antd';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Choose width for each col
// It must be 100% of width in total!
const defWidth = {
    storeGroupCol: 'auto',
    intervalCol: '8%',
    commentCol: '15%',
    lastOrderDateCol: '8%',
    lastOrderOdometerCol: '8%',
    nextServiceDateCol: '8%',
    nextServiceOdometerCol: '8%',
    calculationCol: '20%'
};

const DATETIME_FORMAT = 'DD.MM.YYYY';

/** Date for calculationg a new odometer value */
// let selectedCalcMoment = dayjs();

export function columnsConfig(props) {
    const {
        setDateOdometerValues,
        getOdometerValue,
        getDateValue,
        calculateOdometer,
        calculateDate,
        setDateOdometerValuesDebounce,
        calculateDateInterpolate
    } = props;

    const storeGroupCol = {
        title: <FormattedMessage id='vehicle_page.store_group' />,
        width: defWidth.storeGroupCol,
        dataIndex: 'storeGroupName',
        key: 'storeGroupName'
    };

    const intervalCol = {
        title: <FormattedMessage id='vehicle_page.interval' />,
        width: defWidth.intervalCol,
        align: 'right',
        dataIndex: 'interval',
        key: 'interval',
        render: interval => (
            <div style={{ whiteSpace: 'nowrap' }}>
                <Numeral mask='0,0' nullText='-'>
                    {interval}
                </Numeral>
            </div>
        )
    };

    const commentCol = {
        title: <FormattedMessage id='vehicle_page.comment' />,
        width: defWidth.commentCol,
        align: 'right',
        dataIndex: 'comment',
        key: 'comment'
    };

    const lastServiceDataCol = {
        title: <FormattedMessage id='vehicle_page.last_service' />,
        children: [
            {
                width: defWidth.lastOrderOdometerCol,
                dataIndex: 'odometer',
                key: 'odometer',
                render: odometer => (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <Numeral mask='0,0' nullText='-'>
                            {odometer}
                        </Numeral>
                    </div>
                )
            },
            {
                width: defWidth.lastOrderDateCol,
                dataIndex: 'datetime',
                key: 'datetime',
                render: datetime => {
                    const date = dayjs(datetime);

                    return <div>{date.isValid() ? date.format(DATETIME_FORMAT) : '–'}</div>;
                }
            }
        ]
    };

    const nextServiceCol = {
        title: <FormattedMessage id='vehicle_page.next_service' />,
        children: [
            {
                width: defWidth.nextServiceOdometerCol,
                key: 'nextServiceOdometer',
                render: obj => {
                    let nextOdometer;
                    try {
                        if (!obj.odometer) {
                            throw 'No odometer value';
                        }
                        if (!obj.interval) {
                            throw 'No interval value';
                        }
                        nextOdometer = Number(obj.odometer) + Number(obj.interval);
                    } catch (err) {
                        nextOdometer = '-';
                    }

                    return (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            <Numeral mask='0,0' nullText='0'>
                                {nextOdometer}
                            </Numeral>
                        </div>
                    );
                }
            },
            {
                width: defWidth.nextServiceDateCol,
                key: 'nextServiceDate',
                render: obj => {
                    const beforePrevServiceOdometer = _.get(obj, 'odometer');
                    const beforePrevServiceMoment = dayjs(_.get(obj, 'datetime'));

                    let nextOdometer;
                    try {
                        if (!obj.odometer) {
                            throw 'No odometer value';
                        }
                        if (!obj.interval) {
                            throw 'No interval value';
                        }
                        nextOdometer = Number(obj.odometer) + Number(obj.interval);
                    } catch (err) {
                        nextOdometer = undefined;
                    }

                    // const prevServiceDate = _.get(obj, 'orders[0].datetime', undefined);
                    // const beforePrevServiceDate = _.get(obj, 'orders[1].datetime', undefined);

                    // const prevServiceMoment = dayjs(prevServiceDate);
                    // const beforePrevServiceMoment = dayjs(beforePrevServiceDate);

                    // let nextPossibleServiceDate = undefined;

                    // if(prevServiceDate && beforePrevServiceDate && prevServiceMoment.isValid() && beforePrevServiceMoment.isValid()) {
                    //     nextPossibleServiceDate = prevServiceMoment.add(((0 + prevServiceMoment) - (0 + beforePrevServiceMoment) ), "milliseconds");
                    // }

                    const nextServiceDate = calculateDateInterpolate({
                        beforePrevServiceOdometer,
                        beforePrevServiceMoment,
                        selectedCalcOdometer: nextOdometer || undefined
                    });

                    return (
                        <div>
                            {nextServiceDate && nextServiceDate.isValid()
                                ? nextServiceDate.format(DATETIME_FORMAT)
                                : '?'}
                        </div>
                    );
                }
            }
        ]
    };
    // const nextServiceCol = {
    //     title:     <FormattedMessage id='vehicle_page.next_service' />,
    //     children: [
    //         {
    //             width:     defWidth.nextServiceOdometerCol,
    //             key:       'nextServiceOdometer',
    //             render: (obj) => {
    //                 let nextOdometer = undefined;
    //                 try{
    //                     if(!obj.odometer) throw "No odometer value";
    //                     if(!obj.interval) throw "No interval value";
    //                     nextOdometer = Number(obj.odometer) + Number(obj.interval);
    //                 } catch(err) {
    //                     nextOdometer = '-'
    //                 }
    //                 return (
    //                     <div style={{whiteSpace: 'nowrap'}}>
    //                         <Numeral
    //                             nullText='0'
    //                             mask='0,0'
    //                         >
    //                             { nextOdometer }
    //                         </Numeral>
    //                     </div>
    //                 );
    //             }
    //         },
    //         {
    //             width:     defWidth.nextServiceDateCol,
    //             key:       'nextServiceDate',
    //             render: (obj) => {
    //                 const prevServiceDate = _.get(obj, 'orders[0].datetime', undefined);
    //                 const beforePrevServiceDate = _.get(obj, 'orders[1].datetime', undefined);

    //                 const prevServiceMoment = dayjs(prevServiceDate);
    //                 const beforePrevServiceMoment = dayjs(beforePrevServiceDate);

    //                 let nextPossibleServiceDate = undefined;

    //                 if(prevServiceDate && beforePrevServiceDate && prevServiceMoment.isValid() && beforePrevServiceMoment.isValid()) {
    //                     nextPossibleServiceDate = prevServiceMoment.add(((0 + prevServiceMoment) - (0 + beforePrevServiceMoment) ), "milliseconds");
    //                 }

    //                 return (
    //                     <div>
    //                         {
    //                             (nextPossibleServiceDate && nextPossibleServiceDate.isValid())
    //                                 ? nextPossibleServiceDate.format(DATETIME_FORMAT)
    //                                 : '?'
    //                         }
    //                     </div>
    //                 );
    //             }
    //         }
    //     ]
    // }

    const calculationCol = {
        children: [
            {
                width: defWidth.calculationCol,
                key: 'generatedDateCol',
                render: (obj, o2, index) => {
                    const convertToDate = momentValue =>
                        momentValue && momentValue.isValid()
                            ? dayjs(momentValue.format(DATETIME_FORMAT), DATETIME_FORMAT)
                            : momentValue;

                    const odometerValueToShow = getOdometerValue(index);

                    return (
                        <div>
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                {/* <Numeral
                                    nullText='?'
                                    mask='0,0'
                                >
                                    {
                                        (odometerValueToShow && odometerValueToShow > 0 )
                                            ? Number(odometerValueToShow)
                                            : null
                                    }
                                </Numeral> */}

                                <InputNumber
                                    decimalSeparator=','
                                    // max={10000}
                                    min={0}
                                    onChange={value => {
                                        const prevServiceDate = _.get(
                                            obj,
                                            'orders[0].datetime',
                                            undefined
                                        );
                                        const beforePrevServiceDate = _.get(
                                            obj,
                                            'orders[1].datetime',
                                            undefined
                                        );

                                        const prevServiceOdometer = _.get(
                                            obj,
                                            'orders[0].odometer',
                                            undefined
                                        );
                                        const beforePrevServiceOdometer = _.get(
                                            obj,
                                            'orders[1].odometer',
                                            undefined
                                        );

                                        const prevServiceMoment = convertToDate(
                                            dayjs(prevServiceDate)
                                        );
                                        const beforePrevServiceMoment = convertToDate(
                                            dayjs(beforePrevServiceDate)
                                        );

                                        const predicatedDateValue = calculateDate({
                                            prevServiceOdometer,
                                            beforePrevServiceOdometer,
                                            prevServiceMoment,
                                            beforePrevServiceMoment,
                                            selectedCalcOdometer: value
                                        });

                                        if (predicatedDateValue && predicatedDateValue.isValid()) {
                                            setDateOdometerValuesDebounce(
                                                index,
                                                value,
                                                predicatedDateValue
                                            );
                                        }
                                    }}
                                    value={
                                        odometerValueToShow && odometerValueToShow > 0
                                            ? Number(odometerValueToShow)
                                            : undefined
                                    }
                                />
                            </div>

                            <div style={{ marginTop: '1em' }}>
                                <DatePicker
                                    format={DATETIME_FORMAT}
                                    onChange={selectedCalcMoment => {
                                        // selectedCalcMoment = value;
                                        // console.log("begin2 -------------------------");
                                        const prevServiceDate = _.get(
                                            obj,
                                            'orders[0].datetime',
                                            undefined
                                        );
                                        const beforePrevServiceDate = _.get(
                                            obj,
                                            'orders[1].datetime',
                                            undefined
                                        );

                                        const prevServiceOdometer = _.get(
                                            obj,
                                            'orders[0].odometer',
                                            undefined
                                        );
                                        const beforePrevServiceOdometer = _.get(
                                            obj,
                                            'orders[1].odometer',
                                            undefined
                                        );

                                        const prevServiceMoment = convertToDate(
                                            dayjs(prevServiceDate)
                                        );
                                        const beforePrevServiceMoment = convertToDate(
                                            dayjs(beforePrevServiceDate)
                                        );

                                        // let nextOdometrWillBe = undefined;
                                        const predicatedOdometerValue = calculateOdometer({
                                            prevServiceOdometer,
                                            beforePrevServiceOdometer,
                                            prevServiceMoment,
                                            beforePrevServiceMoment,
                                            selectedCalcMoment: convertToDate(selectedCalcMoment)
                                        });

                                        // console.log("prevServiceDate: ", prevServiceMoment.format(DATETIME_FORMAT));
                                        // console.log("beforePrevServiceDate: ", beforePrevServiceMoment.format(DATETIME_FORMAT));

                                        setDateOdometerValues(
                                            index,
                                            predicatedOdometerValue,
                                            selectedCalcMoment
                                        );
                                    }}
                                    value={getDateValue(index)}
                                />
                            </div>
                        </div>
                    );
                }
            }
        ]
    };

    return [
        storeGroupCol,
        intervalCol,
        commentCol,
        lastServiceDataCol,
        nextServiceCol
        // calculationCol,
    ];
}
